<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="pageTitle"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="!params?.address?.address_name" [align]="'center'">
        <tidy-text
          [header]="'For '">
        </tidy-text>
        <tidy-text
          [header]="params?.address?.address1"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [header]="params?.address?.address2 !== null ? ' ' + params?.address?.address2 : ''"
          [translate]="false">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="params?.address?.address_name" [align]="'center'">
        <tidy-text
          [header]="'For '"
          [translate]="false">
        </tidy-text>
        <tidy-text
          [header]="params?.address?.address_name"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'How do you want to book?'">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="scenario == 'notHasCleaningPros' || scenario == 'notHasNonCleaningPros'">
        <tidy-card *ngIf="scenario == 'notHasNonCleaningPros'">
          <tidy-card-button (action)="goToRequestJob()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/searching.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Request '">
              </tidy-text>
              <tidy-text
                [header]="params?.categoryHeader">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="priorityListSentence">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card *ngIf="scenario !== 'notHasNonCleaningPros'">
          <tidy-card-button (action)="goToBookJobwithNewPro()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/book-job.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Book '">
              </tidy-text>
              <tidy-text
                [header]="params?.categoryHeader">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button (action)="goToBrowsePros()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/thumbtack_icon.png'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Browse Pros'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button (action)="goToAddPro()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/priority.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Add Pro'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="scenario == 'hasCleaningPros' || scenario == 'hasNonCleaningPros'">
        <tidy-card>
          <tidy-card-button (action)="goToRequestJob()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/searching.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Request '">
              </tidy-text>
              <tidy-text
                [header]="params?.categoryHeader">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="priorityListSentence">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-row>
          <tidy-text
            [body]="'Want a specific pro?'">
          </tidy-text>
        </tidy-row>
        <tidy-card *ngFor="let pro of shownPros">
          <tidy-card-button (action)="goToBookJobWithPro(pro)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/account.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="pro?.object?.first_name">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-row [align]="'center'" *ngIf="pros?.length > 2 && pros?.length !== shownPros?.length">
          <tidy-text
            [body]="'View More'"
            class="link"
            (action)="showAllPros()">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Want a new pro?'">
          </tidy-text>
        </tidy-row>
        <tidy-card *ngIf="scenario == 'hasCleaningPros'">
          <tidy-card-button (action)="goToBookJobwithNewPro()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/book-job.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Instantly Book a New Pro'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card>
          <tidy-card-button (action)="goToBrowsePros()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/browse-pros.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Browse Pros'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

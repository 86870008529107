import { Component, OnInit } from '@angular/core';
import { Util } from 'src/shared/util/util';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Homekeeper } from 'src/providers/homekeeper/homekeeper';
import { Invoices } from 'src/providers/invoices/invoices';
import { Logger } from 'src/providers/logger';
import { Proposals } from 'src/providers/proposals/proposals';
import { Pros } from 'src/providers/pros/pros';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage } from 'src/pages/confirm/confirm.component';

import { ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { ProposalModel } from 'src/shared/models/proposals.model';
import { deepClone } from 'src/tidy-ui-components/utils/deep-clone';
import { Communication } from 'src/providers/communication/communication';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Schedule } from 'src/providers/schedule/schedule';
import { DateTime as LuxonDateTime } from 'luxon';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { WindowService } from 'src/shared/providers/window.service';

import { BookWithProPage } from '../book-with-pro/book-with-pro';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { PendingProposalPage } from 'src/pages/proposals/pending-proposal/pending-proposal';
import { PaidInvoicePage } from 'src/pages/invoices/paid-invoice/paid-invoice';
import { PendingInvoicePage } from 'src/pages/invoices/pending-invoice/pending-invoice';
import { MyProsEditProPage } from 'src/pages/my-pros/edit-pro/edit-pro';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { ProMessagesPage } from 'src/pages/pro-messages/pro-messages';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { debounceTime } from 'rxjs/operators';

@Component({
  templateUrl: 'my-pro.html'
})

export class MyProPage implements OnInit {

  acceptedProposals: ProposalModel[] = [];
  address: any;
  available: boolean;
  allowsInvoiceAutopay: boolean;
  autoPayMaxAmount: number;
  creditBalance: any;
  customBackPage: string;
  pendingProposals: ProposalModel[] = [];
  pendingInvoices = [];
  pro: any;
  pastJobs: any;
  requestable = true;
  proId;
  prosData;
  privatePlanId: number;
  isCurrentProBlocked = false;
  errorMessage: string;
  userRole: string;
  form: UntypedFormGroup;
  maxJobsForm: UntypedFormGroup;
  messages: any;
  submitted: boolean;
  isRentalClient: boolean;
  isProLoaded: boolean;
  isPastJobsLoaded: boolean;
  isProsDataLoaded: boolean;
  isBillingsDataLoaded: boolean;
  isMessagesLoaded: boolean;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private bookJobService: BookJob,
    private client: Client,
    private homekeeper: Homekeeper,
    private invoices: Invoices,
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private pros: Pros,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private logger: Logger,
    private storage: TidyStorage,
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private schedule: Schedule,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
    this.maxJobsForm = this.fb.group({
      limitMaxJobs: [false],
      maxJobs: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadData();
  }

  watchMaxJobsFormChanges() {
    this.maxJobsForm.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe((value) => {
      if (value.limitMaxJobs) {
        this.updateMaxJobs(Number(value.maxJobs));
      }
    });
  }

  async updateMaxJobs(value: number): Promise<void> {
    try {
      const payload = {
        team_id: this.pro.team.id,
        key: 'max_scheduled_jobs_per_day',
        value,
      }
      await this.pros.updateMaxJobs(payload);
    } catch (error) {
      console.error(error);
      this.errorHandler(error);
    }
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.pendingInvoices = [];
    this.loaded = false;
    this.userRole = localStorage.getItem('user_role');
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    this.customBackPage = localStorage.getItem('myProBackPage') || (this.isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows');
    await this.loadProDetails();
    this.loadPastJobs();
    this.loadProsData();
    this.loadBillingsData();
    this.loadMessages();
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
    this.rightSidePanelService.setDialogPageTitle(this.pro?.homekeeper?.name);
  }

  async loadProDetails(): Promise<void> {
    try {
      this.isProLoaded = false;
      this.proId = this.route.snapshot.paramMap.get('proId') || this.dialogParams?.proId;
      this.pro = await this.pros.getProDetail(this.proId);
      this.rightSidePanelService.setDialogPageTitle(this.pro?.homekeeper?.name);
      this.available = this.pro?.availability?.includes('available to serve' );
      this.isProLoaded = true;
    } catch (error) {
      this.isProLoaded = true;
      this.errorHandler(error);
    }
  }

  async loadPastJobs(): Promise<void> {
    try {
      this.isPastJobsLoaded = false;
      this.pastJobs = await this.homekeeper.getHkHistory(this.proId);
      this.isPastJobsLoaded = true;
    } catch (error) {
      this.isPastJobsLoaded = true;
      this.errorHandler(error);
    }
  }

  async loadProsData(): Promise<void> {
    try {
      this.isProsDataLoaded = false;
      const serviceTypeId = 1;
      this.prosData = await this.pros.getPros(serviceTypeId);
      this.isCurrentProBlocked = this.prosData.blocked.some(pro => pro.object.id == this.proId);
      this.isProsDataLoaded = true;
    } catch (error) {
      this.isProsDataLoaded = true;
      this.errorHandler(error);
    }
  }

  async loadBillingsData(): Promise<void> {
    try {
      this.isBillingsDataLoaded = false;
      this.address = await this.getAddress();
      if (this.pro.is_private) {
        this.creditBalance = await this.pros.getCreditBalance(this.pro.team.id, this.address.id);
        this.pendingInvoices = await this.invoices.getInvoices({
          teamId: this.pro.team.id,
          status: 'pending',
          jobId: null
        });
        const proposals = await this.proposals.getProposalsByTeam(this.pro.team.id);
        this.parseProposals(proposals);
        await this.autopayConfig();
        this.watchMaxJobsFormChanges();
      }
      this.isBillingsDataLoaded = true;
    } catch (error) {
      this.isBillingsDataLoaded = true;
      this.errorHandler(error);
    }
  }

  async loadMessages(): Promise<void> {
    try {
      this.isMessagesLoaded = false;
      const clientId = localStorage.getItem('customer_id');
      const AISuggestionsPayload = {
        feature: 'chat_suggestion',
        data: {
          chat_room_key: `customer_account_id-${clientId},team_id-${this.pro.team.id}`
        }
      }
      await this.communication.generateAISuggestions(AISuggestionsPayload);
      const messages = await this.communication.getSharedInbox(this.pro.team.id);
      this.messages = messages.reverse();
      this.messages = this.messages.slice(0, 2);
      this.markMessagesAsRead();
      this.isMessagesLoaded = true;
    } catch (error) {
      this.isMessagesLoaded = true;
      this.errorHandler(error);
    }
  }

  errorHandler(error: any): void {
    console.log(error);
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message || error;
    this.util.showError(errorMessage);
  }

  async autopayConfig(){
    const autopaySettings = await this.pros.getAutopaySettings(this.pro.team.id);
    autopaySettings.map((settings) =>{
      if (settings?.key === 'max_scheduled_jobs_per_day') {
        this.maxJobsForm.patchValue({
          maxJobs: settings?.value,
          limitMaxJobs: settings?.value && settings?.value > -1 ? true : false
        });
      }
      if (settings?.key === 'payment.autopay_limit') {
        this.autoPayMaxAmount = (parseInt(settings?.value) / 100)
      } else if (settings?.key === 'payment.autopay') {
        this.allowsInvoiceAutopay = (settings?.value === "true");
      }
    });
  }

  parseProposals(proposals) {
    this.pendingProposals = [];
    this.acceptedProposals = [];
    proposals.map(proposal => {
      if (proposal.status === 'pending') {
        this.pendingProposals.push(proposal);
      }
    });
    proposals.map(proposal => {
      if (proposal.status === 'accepted') {
        this.acceptedProposals.push(proposal);
      }
    });
  }

  async goToBookJob() {
    this.bookJobService.clearBookJobStorage();
    const params = {
      address: this.address,
      bookingType: 'add_job',
      selectedPro: this.pro,
      categoryId: this.navCtrl.getParam('categoryId') || this.dialogParams?.categoryId
    }
    this.rightSidePanelService.setDialogPageTitle('Book Job(s)');
    this.rightSidePanelService.navigateTo('select-category', params, SelectCategoryPage);
  }

  contactPro(phone) {
    return window.location.href = `tel:${phone}`;
  }

  async favoriteProConfirmation() {
    const params: ConfirmPageParamsModel = {
      title: `Favorite ${this.pro.homekeeper.first_name}?`,
      body: `This will move them to the top of your favorites list.`,
      backText: 'Go Back',
      confirmText: 'Confirm Favorite',
      confirmAction: this.moveProToCollection.bind(this, this.prosData.favorited)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async blockProConfirmation() {
    const params: ConfirmPageParamsModel = {
      title: `Block ${this.pro.homekeeper.first_name}?`,
      body: `This will block them from your account, so they cannot see or accept any of your future job requests.`,
      backText: 'Go Back',
      confirmText: 'Confirm Block',
      confirmAction: this.moveProToCollection.bind(this, this.prosData.blocked)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async approveAndUnblockProConfirmation() {
    const params: ConfirmPageParamsModel = {
      title: `Approve & Unblock ${this.pro.homekeeper.first_name}`,
      body: `This will give your approval to them to see and accept future job requests.`,
      backText: 'Go Back',
      confirmText: `Approve & Unblock ${this.pro.homekeeper.first_name}`,
      confirmAction: this.moveProToCollection.bind(this, this.prosData.approved)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async moveProToCollection(collectionToAdd) {
    const approvedIndex = this.prosData.approved.findIndex(pro => pro.object.id == this.proId);
    const favoritedIndex = this.prosData.favorited.findIndex(pro => pro.object.id == this.proId);
    const blockedIndex = this.prosData.blocked.findIndex(pro => pro.object.id == this.proId);

    let removedElement: any[];

    if (approvedIndex >= 0) {
      removedElement = this.prosData.approved.splice(approvedIndex, 1);
    }

    if (favoritedIndex >= 0) {
      removedElement = this.prosData.favorited.splice(favoritedIndex, 1);
    }

    if (blockedIndex >= 0) {
      removedElement = this.prosData.blocked.splice(blockedIndex, 1);
    }

    if (removedElement) {
      const indexToAdd = 0;
      collectionToAdd.splice(indexToAdd, 0, ...removedElement);
    }

    const serviceTypeId = 1;
    try {
      await this.pros.updateProsPriorities(this.prosData, serviceTypeId);
      this.storage.save('shouldFetchPros', true);
      const url = this.isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
      this.navCtrl.navigateRoot(url);
    } catch (err) {
      this.logger.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  goToProposalPage(proposal) {
    this.storage.save('proposalUuId', proposal.uuid);
    this.rightSidePanelService.navigateTo(`proposal/${proposal.uuid}`, {}, PendingProposalPage);
  }

  async goToInvoicePage(invoice) {
    try {
      const settings = await this.client.getClientSettings();
      const params = {
        invoice: invoice,
        invoiceId: invoice.id,
        hasAccount: true,
        proName: this.pro.homekeeper.first_name,
        proEmail: this.pro.homekeeper.email,
        proPhone: this.pro.homekeeper.phone,
        proTeamId: this.pro.team.id,
        proId: this.pro.homekeeper.id,
        clientName: settings.profile.first_name,
        clientEmail: settings.profile.email,
        successRoute: `my-pro/${this.proId}`
      }
      const path = invoice.full_paid ? `paid-bill/${invoice.id}` : `pending-bill/${invoice.id}`;
      const component = invoice.full_paid ? PaidInvoicePage : PendingInvoicePage;
      this.rightSidePanelService.navigateTo(path, params, component);
    } catch (err) {
      this.logger.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBillsPage() {
    this.navCtrl.navigateRoot('bills');
  }

  checkIfHasPlanWithPro() {
    let hasPlan = false;
    if (this.address?.plans?.length) {
      if (this?.pro?.is_private) {
        this.address?.plans?.map((plan) => {
          if (plan?.is_private && plan?.team?.owner?.id == this.proId) {
            hasPlan = true;
            this.privatePlanId = plan?.id;
          }
        });
      } else {
        this.address?.plans?.map((plan) => {
          if (!plan?.is_private) {
            hasPlan = true;
          }
        });
      }
    }
    return hasPlan;
  }

  async getAddress() {
    const addressIdParam = localStorage.getItem('addressId');
    const addressId = parseFloat(addressIdParam);
    const addressResponse = await this.client.getMoreDetailAddresses();
    return addressResponse.find(ad => ad.id === addressId);
  }

  async goToSendPayment() {
    const params = {
      pro: this.pro,
      allowsInvoiceAutopay: this.allowsInvoiceAutopay,
      privatePlanId: this.privatePlanId,
      address: this.address,
      pendingInvoices: this.pendingInvoices
    }
    if (this.pendingInvoices.length) {
      this.rightSidePanelService.navigateTo('send-payment-or-pay-invoice', params);
    } else {
      this.rightSidePanelService.navigateTo('send-payment', params);
    }
  }

  /*goToInvoiceAutopayPage() {
    const params = {
      autoPayMaxAmount: this.autoPayMaxAmount,
      allowsInvoiceAutopay: this.allowsInvoiceAutopay,
      pro: this.pro
    };
    this.navCtrl.navigateForward('invoice-autopay', params);
  }*/

  goToProBillingHistoryPage() {
    const params = {
      billingData: this.creditBalance,
      proId: this.pro.homekeeper.id,
      proName: this.pro.homekeeper.first_name + ' ' + this.pro.homekeeper.last_name,
      cameFromMyProPage: true
    };
    this.rightSidePanelService.navigateTo('billing-history', params);
  }

  goToEditContactInfo() {
    const pro = deepClone(this.pro.homekeeper);
    this.rightSidePanelService.navigateTo(`edit-pro/${pro.id}`, {pro}, MyProsEditProPage);
  }

  goToPastJob(jobId, isPrivate) {
    const url = isPrivate ? `past-private-job/${jobId}` : `past-job/${jobId}`;
    const component = isPrivate ? PastPrivateJobPage : PastJobPage;
    localStorage.setItem('pastJobBackPage', `my-pro/${this.proId}`);
    this.rightSidePanelService.navigateTo(url, {jobId: jobId, backPage: `my-pro/${this.proId}`, proId: this.proId}, component);
  }

  goToAllMessagesPage(): void {
    this.rightSidePanelService.navigateTo(`pro-messages/${this.proId}`, {proId: this.proId, backPage: `my-pro/${this.proId}`}, ProMessagesPage);
  }

  goToConciergePage() {
    const params = {
      title: 'Turn On Auto Assign',
      type: 'support.other',
      question: 'Please turn on auto assign for my private pro ' + this.pro.homekeeper.first_name + ' (ID ' + this.pro.homekeeper.id + ').'
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message.is_read && message.from_member.type !== 'CustomerMember' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'account',
        data: {
          team_id: this.pro.team.id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachments
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.form.patchValue({
        message: ''
      });
      const messages = await this.communication.getSharedInbox(this.pro.team.id);
      this.messages = await messages.reverse();
      this.messages = this.messages.slice(0, 2);
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  getDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return LuxonDateTime.fromISO(date).setZone(timezone).toRelative();
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  goToProSettings() {
    if (!this.windowService.isDesktopRes) {
      return this.util.showSuccess('Please log into tidy.com on your desktop to edit autopay settings.');
    }
    this.navCtrl.navigateForward('pro-settings');
  }

  goToJobRequestWorkflows() {
    this.rightSidePanelService.navigateTo('job-request-workflows');
  }

  limitMaxJobs(): void {
    const limitMaxJobs = this.maxJobsForm.value.limitMaxJobs;
    if (!limitMaxJobs) {
      this.updateMaxJobs(-1);
    }
    this.maxJobsForm.controls.maxJobs.setValue(0);
  }
}

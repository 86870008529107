import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { AddressComponentsModel, AddressForm } from 'src/providers/address-form/address-form';
import { Addresses } from 'src/providers/customer/addresses';
import { Client } from 'src/providers/client/client';
import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';
import { Me } from 'src/providers/me/me';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';

import { AutoCompleteAddressModel } from 'src/models/autocomplete-address.model';

import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';

import { Loading } from 'src/shared/components/loading/loading';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ImportPropertiesCSVPage } from '../import-properties-csv/import-properties-csv';
import { IntegrationOptionsPage } from '../integrations/integration-options/integration-options';

@Component({
  templateUrl: 'add-address.html'
})

export class AddAddressPage implements OnInit {

  zipCodeMask = {
    mask: '00000' // US mask
  };
  autocompleteAddress: AutoCompleteAddressModel[];
  form: UntypedFormGroup;
  submitted: boolean;
  loaded: boolean;
  radioButtonAddress: RadioButtonModel[] = [];
  didChooseAddress = false;
  isRentalClient: boolean;
  typedOnAddress = false;
  selectCountry: string;
  countryList: RadioButtonModel[];
  errorMessage: string;
  isRightSideContent = true;

  constructor(
    private addressForm: AddressForm,
    private addresses: Addresses,
    private client: Client,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private me: Me,
    private multipleCountryService: MultipleCountryService,
    private googleGeocode: GoogleGeocode,
    private currentAddress: CurrentAddress,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      addressNickname: ['']
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Property');
    const me = await this.me.load();
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    this.loaded = true;
  }

  // TODO remove this to use tidy-auto-complete-address https://github.com/TIDYAPP/client-mobile-v2/pull/523/files
  updateSearch = async () => {
    this.errorMessage = '';

    try {
      this.autocompleteAddress = await this.addressForm.updateSearch(this.form.value.address, this.form.get('countryCode').value);
    } catch (err) {
      this.errorMessage = 'An error occurred when getting address information. Please type another address.';
    }

    this.radioButtonAddress = this.autocompleteAddress.map(address => {
      return {
        value: address.value,
        viewValue: address.display,
        placeId: address.placeId
      }
    });

    this.typedOnAddress = true;
    this.didChooseAddress = false;
  }

  // TODO remove this to use tidy-auto-complete-address https://github.com/TIDYAPP/client-mobile-v2/pull/523/files
  async chooseItem(item: { placeId: string, value: string | number, display: string}) {
    const zipcode = await this.addressForm.chooseItem(item);

    if (!zipcode && this.multipleCountryService.checkIfContryCodeIsUs(this.form.get('countryCode').value)) {
      this.errorMessage = `It looks like this address isn't detailed enough, please update it. If you still have issues please contact us in the Concierge section.`;
    }

    const address: AddressComponentsModel = await this.addressForm.getAdditonalAddressInfo(item.placeId);
    this.form.patchValue({ zipcode, ...address });
    const latLngData = await this.googleGeocode.getLatLong(item.placeId);
    this.form.patchValue(latLngData);
    this.autocompleteAddress = [];
    this.didChooseAddress = true;
    this.typedOnAddress = false;
  }

  setAddressFormControl(item: RadioButtonModel) {
    this.form.controls.address.setValue(item.value);
  }

  @Loading('', true)
  async addAddress() {
    this.submitted = true;
    this.errorMessage = '';

    if (!this.didChooseAddress && this.autocompleteAddress.length === 0) {
      return this.errorMessage = 'We could not validate your address with Google. Please enter an address that can be found with Google Maps.';
    }

    if (!this.form.valid || !this.didChooseAddress) {
      return;
    }

    const addressArray = this.form.value.address.split(',');

    if (!addressArray[2] && this.multipleCountryService.checkIfContryCodeIsUs(this.form.get('countryCode').value)) {
      this.errorMessage = `It looks like this address isn't detailed enough, please update it. If you still have issues please contact us in the Concierge section.`;
      return;
    }

    const addressStateFallback: string = addressArray[2]?.trim();

    const data: any = {
      country_code: this.form.value.countryCode,
      add_state: this.form.value.state || addressStateFallback,
      city: this.form.value.city,
      address1: addressArray[0],
      address2: this.form.value.address2,
      zip: this.form.value.zipcode,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    }

    if (this.form.value.addressNickname) {
      data['address_name'] = this.form.value.addressNickname;
    }

    try {
      const address = await this.addresses.createAddress(data);
      this.currentAddress.addressId = address.id;
      localStorage.setItem('region_id', address.region_id);
      const addressResponse = await this.client.getAddresses(true);
      const moreDetailAddressResponse = await this.client.getMoreDetailAddresses(true);
      const params = { address };
      this.navCtrl.navigateForward(`edit-property/${address.id}`, params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  changeCountrySelected(countryCodeSelected) {
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCodeSelected);
    this.form.get('zipcode').setValidators(validatorObject.validator);
    this.zipCodeMask = validatorObject.mask;

    setTimeout(() => {
      this.form.patchValue({
        address: null,
        address2: null,
        zipcode: null
      });
    });
  }

  goToAddDirectIntegration() {
    localStorage.setItem('integrationOptionsBackButton', 'add-property')
    this.rightSidePanelService.navigateTo('integration-options', {}, IntegrationOptionsPage);
  }

  goToImportCSV() {
    this.rightSidePanelService.navigateTo('import-properties-csv', {}, ImportPropertiesCSVPage);
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  templateUrl: 'edit-plan.html'
})

export class EditPlanPage implements OnInit {

  address;
  plan: any;
  text: any;
  type;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private storage: TidyStorage,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.address = this.navCtrl.getParam('address');
    this.plan = this.navCtrl.getParam('plan');
    this.type = this.navCtrl.getParam('type');
    this.text = this.getText(this.type);
  }

  getText(type) {
    const texts = {
      job: {
        title: 'Edit Job',
        buttonOne: 'Reschedule Job',
        buttonTwo: 'Cancel Job',
        cancelText: `Are you sure you want to cancel this job?`
      },
      plan: {
        title: 'Edit Plan',
        buttonOne: 'Reschedule Plan',
        buttonTwo: 'Cancel Plan',
        cancelText: `Are you sure you want to cancel this plan?`,
      }
    }
    return texts[type];
  }

  reschedule() {
    const bookingType = 'reschedule_plan'
    const { address } = this;
    const planId = this.plan.id;
    const isReschedulingOneTimePlan = this.type === 'job';
    this.navCtrl.navigateForward('book-job', { address, bookingType, planId, isReschedulingOneTimePlan});
  }

  async cancel() {
    const params: ConfirmPageParamsModel = {
      title: this.text.buttonTwo + '?',
      body: this.text.cancelText,
      backText: 'Go Back',
      confirmText: this.text.buttonTwo,
      confirmAction: await this.confirmCancel.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancel() {
    try {
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.storage.delete('addresses');
      await this.schedule.cancelPlan(this.plan.id);
      this.modalCtrl.dismiss();
      const params = {
        getAddressesFromBackend: true
      };
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list', params);
    } catch(err) {
      throw err;
    }
  }

}

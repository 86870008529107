import { Cleaning } from "src/providers/cleaning-cards/cleaning";

export const translations = {

    //Desktop Menu
    'Dashboard': {
        pt: 'Painel',
        es: 'Tablero',
        ru: 'Панель управления',
        fr: 'Tableau de bord',
        de: 'Armaturenbrett'
    },
    'Schedule': {
        pt: 'Agenda',
        es: 'Horario',
        ru: 'Расписание',
        fr: 'Calendrier',
        de: 'Zeitplan'
    },
    'Tasks': {
        pt: 'Tarefas',
        es: 'Tareas',
        ru: 'Задачи',
        fr: 'Tâches',
        de: 'Aufgaben'
    },
    'Messages': {
        pt: 'Mensagens',
        es: 'Mensajes',
        ru: 'Сообщения',
        fr: 'Messages',
        de: 'Nachrichten'
    },
    'To-Dos': {
        pt: 'Lista de Tarefas',
        es: 'Pendientes',
        ru: 'К исполнению',
        fr: 'À faire',
        de: 'To-Dos'
    },
    'My Pros': {
        pt: 'Meus Profissionais',
        es: 'Mis Profesionales',
        ru: 'Мои Профессионалы',
        fr: 'Mes Pros',
        de: 'Meine Profis'
    },
    'Inventory': {
        pt: 'Inventário',
        es: 'Inventario',
        ru: 'Инвентарь',
        fr: 'Inventaire',
        de: 'Inventar'
    },
    'Bills': {
        pt: 'Contas',
        es: 'Facturas',
        ru: 'Счета',
        fr: 'Factures',
        de: 'Rechnungen'
    },
    'Reports': {
        pt: 'Relatórios',
        es: 'Informes',
        ru: 'Отчеты',
        fr: 'Rapports',
        de: 'Berichte'
    },
    'Concierge': {
        pt: 'Concierge',
        es: 'Conserje',
        ru: 'Консьержка',
        fr: 'Concierge',
        de: 'Concierge'
    },
    'Automations': {
        pt: 'Automações',
        es: 'Automatizaciones',
        ru: 'Автоматизации',
        fr: 'Automatisations',
        de: 'Automatisierungen'
    },
    'Help': {
        pt: 'Ajuda',
        es: 'Ayuda',
        ru: 'Помощь',
        fr: 'Aide',
        de: 'Hilfe'
    },
    'Integrations': {
        pt: 'Integrações',
        es: 'Integraciones',
        ru: 'Интеграции',
        fr: 'Intégrations',
        de: 'Integrationen'
    },
    'More': {
        pt: 'Mais',
        es: 'Más',
        ru: 'Еще',
        fr: 'Plus',
        de: 'Mehr'
    },

    //DashboardPage
    'Jobs': {
        pt: 'Trabalhos',
        es: 'Trabajos',
        ru: 'Работы',
        fr: 'Emplois',
        de: 'Jobs'
    },
    'Automatic Booking: ON': {
        pt: 'Reserva Automática: LIGADA',
        es: 'Reserva Automática: ACTIVADA',
        ru: 'Автоматическое бронирование: ВКЛ',
        fr: 'Réservation Automatique: ACTIVÉE',
        de: 'Automatische Buchung: EIN'
    },
    'Automatic Booking: OFF': {
        pt: 'Reserva Automática: DESLIGADA',
        es: 'Reserva Automática: DESACTIVADA',
        ru: 'Автоматическое бронирование: ВЫКЛ',
        fr: 'Réservation Automatique: DÉSACTIVÉE',
        de: 'Automatische Buchung: AUS'
    },
    'Next Suggested Maintenance': {
        pt: 'Próxima Manutenção Sugerida',
        es: 'Próximo Mantenimiento Sugerido',
        ru: 'Следующее Рекомендуемое Техобслуживание',
        fr: 'Prochaine Maintenance Suggérée',
        de: 'Nächste Vorgeschlagene Wartung'
    },
    'No jobs scheduled': {
        pt: 'Nenhum trabalho agendado',
        es: 'No hay trabajos programados',
        ru: 'Работ не запланировано',
        fr: 'Aucun travail prévu',
        de: 'Keine Jobs geplant'
    },
    'When a reservation is synced, add a cleaning for your pros. Cancel anytime.': {
        pt: 'Quando uma reserva é sincronizada, adicione uma limpeza para seus profissionais. Cancele a qualquer momento.',
        es: 'Cuando se sincroniza una reserva, añade una limpieza para tus profesionales. Cancela en cualquier momento.',
        ru: 'Когда бронирование синхронизировано, добавьте уборку для ваших профессионалов. Отмена в любое время.',
        fr: 'Lorsqu\'une réservation est synchronisée, ajoutez un nettoyage pour vos pros. Annulez à tout moment.',
        de: 'Wenn eine Reservierung synchronisiert wird, fügen Sie eine Reinigung für Ihre Profis hinzu. Jederzeit kündbar.'
    },
    'No suggested maintenances': {
        pt: 'Nenhuma manutenção sugerida',
        es: 'No hay mantenimientos sugeridos',
        ru: 'Рекомендуемых техобслуживаний нет',
        fr: 'Aucune maintenance suggérée',
        de: 'Keine vorgeschlagenen Wartungen'
    },
    '0 unpaid': {
        pt: '0 não pagos',
        es: '0 no pagados',
        ru: '0 неоплаченных',
        fr: '0 non payés',
        de: '0 unbezahlt'
    },
    '0 recent': {
        pt: '0 recentes',
        es: '0 recientes',
        ru: '0 недавних',
        fr: '0 récents',
        de: '0 kürzlich'
    },
    '0 unresolved': {
        pt: '0 não resolvidos',
        es: '0 sin resolver',
        ru: '0 нерешенных',
        fr: '0 non résolus',
        de: '0 ungelöst'
    },
    'more unresolved': {
        pt: 'mais não resolvidos',
        es: 'más sin resolver',
        ru: 'больше нерешенных',
        fr: 'plus non résolus',
        de: 'mehr ungelöst'
    },
    'unpaid': {
        pt: 'não pago',
        es: 'no pagado',
        ru: 'неоплаченный',
        fr: 'non payé',
        de: 'unbezahlt'
    },
    '0 threads': {
        pt: '0 tópicos',
        es: '0 hilos',
        ru: '0 тем',
        fr: '0 fils',
        de: '0 Threads'
    },
    'threads': {
        pt: 'tópicos',
        es: 'hilos',
        ru: 'темы',
        fr: 'fils',
        de: 'Threads'
    },
    'Next:': {
        pt: 'Próximo:',
        es: 'Siguiente:',
        ru: 'Следующий:',
        fr: 'Suivant:',
        de: 'Nächster:'
    },
    'on:': {
        pt: 'em:',
        es: 'en:',
        ru: 'на:',
        fr: 'sur:',
        de: 'an:'
    },
    'Next Suggested Maintenances': {
        pt: 'Próximas Manutenções Sugeridas',
        es: 'Próximos Mantenimientos Sugeridos',
        ru: 'Следующие Рекомендуемые Техобслуживания',
        fr: 'Prochaines Maintenances Suggérées',
        de: 'Nächste Vorgeschlagene Wartungen'
    },
    'There are no results for this report.': {
        pt: 'Não há resultados para este relatório.',
        es: 'No hay resultados para este informe.',
        ru: 'По этому отчету нет результатов.',
        fr: 'Il n\'y a aucun résultat pour ce rapport.',
        de: 'Es gibt keine Ergebnisse für diesen Bericht.'
    },
    'Get Started Using TIDY:': {
        pt: 'Comece a Usar a TIDY:',
        es: 'Empieza a Usar TIDY:',
        ru: 'Начните Использовать TIDY:',
        fr: 'Commencez à Utiliser TIDY:',
        de: 'Beginnen Sie mit TIDY:'
    },
    'Other Ways to Use TIDY:': {
        pt: 'Outras Maneiras de Usar a TIDY:',
        es: 'Otras Maneras de Usar TIDY:',
        ru: 'Другие Способы Использования TIDY:',
        fr: 'Autres Moyens d\'Utiliser TIDY:',
        de: 'Andere Möglichkeiten, TIDY zu Nutzen:'
    },
    'Create a To-Do List': {
        pt: 'Criar uma Lista de Tarefas',
        es: 'Crear una Lista de Tareas',
        ru: 'Создать Список Дел',
        fr: 'Créer une Liste de Tâches',
        de: 'Eine To-Do-Liste Erstellen'
    },
    'Create a digital To-Do list to share with any Pro. (Free)': {
        pt: 'Crie uma lista de tarefas digital para compartilhar com qualquer profissional. (Grátis)',
        es: 'Crea una lista de tareas digital para compartir con cualquier profesional. (Gratis)',
        ru: 'Создайте цифровой список дел, чтобы делиться им с любым профессионалом. (Бесплатно)',
        fr: 'Créez une liste de tâches numérique à partager avec n\'importe quel pro. (Gratuit)',
        de: 'Erstellen Sie eine digitale To-Do-Liste, um sie mit jedem Profi zu teilen. (Kostenlos)'
    },
    'Add Existing Pro': {
        pt: 'Adicionar Profissional Existente',
        es: 'Agregar Profesional Existente',
        ru: 'Добавить Существующего Профессионала',
        fr: 'Ajouter un Pro Existant',
        de: 'Vorhandenen Profi Hinzufügen'
    },
    'Add an existing Pro you work with to automate requests with them. (Free)': {
        pt: 'Adicione um profissional existente com quem você trabalha para automatizar solicitações com eles. (Grátis)',
        es: 'Agrega un profesional existente con el que trabajas para automatizar solicitudes con ellos. (Gratis)',
        ru: 'Добавьте существующего профессионала, с которым вы работаете, чтобы автоматизировать запросы с ним. (Бесплатно)',
        fr: 'Ajoutez un pro existant avec qui vous travaillez pour automatiser les demandes avec eux. (Gratuit)',
        de: 'Fügen Sie einen bestehenden Profi hinzu, mit dem Sie arbeiten, um Anfragen mit ihnen zu automatisieren. (Kostenlos)'
    },
    'Get an Animated Map': {
        pt: 'Obtenha um Mapa Animado',
        es: 'Obtén un Mapa Animado',
        ru: 'Получите Анимированную Карту',
        fr: 'Obtenez une Carte Animée',
        de: 'Holen Sie sich eine animierte Karte'
    },
    'Get a free map of your property.': {
        pt: 'Obtenha um mapa gratuito da sua propriedade.',
        es: 'Obtén un mapa gratuito de tu propiedad.',
        ru: 'Получите бесплатную карту вашей собственности.',
        fr: 'Obtenez une carte gratuite de votre propriété.',
        de: 'Erhalten Sie einen kostenlosen Plan Ihres Eigentums.'
    },
    'Users': {
        pt: 'Usuários',
        es: 'Usuarios',
        ru: 'Пользователи',
        fr: 'Utilisateurs',
        de: 'Benutzer'
    },
    'Add a user so they can log into your account with their own credentials. As the team leader, you can revoke access at any time.': {
        pt: 'Adicione um usuário para que ele possa acessar sua conta com suas próprias credenciais. Como líder da equipe, você pode revogar o acesso a qualquer momento.',
        es: 'Añade un usuario para que pueda iniciar sesión en tu cuenta con sus propias credenciales. Como líder del equipo, puedes revocar el acceso en cualquier momento.',
        ru: 'Добавьте пользователя, чтобы он мог войти в ваш аккаунт с помощью своих учетных данных. Как руководитель команды, вы можете отозвать доступ в любое время.',
        fr: 'Ajoutez un utilisateur pour qu\'il puisse se connecter à votre compte avec ses propres identifiants. En tant que chef d\'équipe, vous pouvez révoquer l\'accès à tout moment.',
        de: 'Fügen Sie einen Benutzer hinzu, damit er sich mit seinen eigenen Anmeldeinformationen in Ihr Konto einloggen kann. Als Teamleiter können Sie den Zugang jederzeit widerrufen.'
    },
    'Get a Maintenance Plan': {
        pt: 'Obtenha um Plano de Manutenção',
        es: 'Obtén un Plan de Mantenimiento',
        ru: 'Получите План Обслуживания',
        fr: 'Obtenez un Plan de Maintenance',
        de: 'Holen Sie sich einen Wartungsplan'
    },
    'It\'s free! Just scan your home to get a personalized maintenance plan.': {
        pt: 'É grátis! Basta escanear sua casa para obter um plano de manutenção personalizado.',
        es: '¡Es gratis! Simplemente escanea tu casa para obtener un plan de mantenimiento personalizado.',
        ru: 'Это бесплатно! Просто просканируйте свой дом, чтобы получить персонализированный план обслуживания.',
        fr: 'C\'est gratuit! Il suffit de scanner votre maison pour obtenir un plan de maintenance personnalisé.',
        de: 'Es ist kostenlos! Scannen Sie einfach Ihr Zuhause, um einen personalisierten Wartungsplan zu erhalten.'
    },
    'Integrate': {
        pt: 'Integrar',
        es: 'Integrar',
        ru: 'Интегрировать',
        fr: 'Intégrer',
        de: 'Integrieren'
    },
    'Connect Airbnb & other channels to automatically book turnovers. (Free)': {
        pt: 'Conecte o Airbnb e outros canais para reservar automaticamente as trocas. (Grátis)',
        es: 'Conecta Airbnb y otros canales para reservar automáticamente los cambios. (Gratis)',
        ru: 'Подключите Airbnb и другие каналы для автоматического бронирования смен. (Бесплатно)',
        fr: 'Connectez Airbnb et d\'autres canaux pour réserver automatiquement les changements. (Gratuit)',
        de: 'Verbinden Sie Airbnb und andere Kanäle, um Umsätze automatisch zu buchen. (Kostenlos)'
    },
    'Schedule a cleaning with a certified Pro in 1 minute.': {
        pt: 'Agende uma limpeza com um profissional certificado em 1 minuto.',
        es: 'Programa una limpieza con un profesional certificado en 1 minuto.',
        ru: 'Запланируйте уборку с сертифицированным профессионалом за 1 минуту.',
        fr: 'Planifiez un nettoyage avec un professionnel certifié en 1 minute.',
        de: 'Planen Sie eine Reinigung mit einem zertifizierten Profi in 1 Minute.'
    },
    'Get a Demo': {
        pt: 'Obtenha uma Demonstração',
        es: 'Obtén una Demostración',
        ru: 'Получите Демонстрацию',
        fr: 'Obtenez une Démonstration',
        de: 'Holen Sie sich eine Demo'
    },
    'The TIDY Concierge can help make sure you have everything set up right.': {
        pt: 'O Concierge TIDY pode ajudar a garantir que você tenha tudo configurado corretamente.',
        es: 'El Conserje TIDY puede ayudar a asegurarse de que todo esté configurado correctamente.',
        ru: 'Консьерж TIDY может помочь убедиться, что все настроено правильно.',
        fr: 'Le Concierge TIDY peut vous aider à vous assurer que tout est bien configuré.',
        de: 'Der TIDY Concierge kann helfen sicherzustellen, dass alles richtig eingerichtet ist.'
    },

    //AddAddressPage
    'Add Property': {
        pt: "Adicionar Propriedade",
        es: "Agregar Propiedad",
        ru: "Добавить Собственность",
        fr: "Ajouter une Propriété",
        de: "Eigentum Hinzufügen"
    },
    'Integrate PMS': {
        pt: "Integrar PMS",
        es: "Integrar PMS",
        ru: "Интегрировать PMS",
        fr: "Intégrer PMS",
        de: "PMS Integrieren"
    },
    'If you use a PMS system you can integrate to import all properties.': {
        pt: "Se você usa um sistema PMS, pode integrá-lo para importar todas as propriedades.",
        es: "Si usas un sistema PMS, puedes integrarlo para importar todas las propiedades.",
        ru: "Если вы используете систему PMS, вы можете интегрировать ее для импорта всех свойств.",
        fr: "Si vous utilisez un système PMS, vous pouvez l'intégrer pour importer toutes les propriétés.",
        de: "Wenn Sie ein PMS-System verwenden, können Sie es integrieren, um alle Eigenschaften zu importieren."
    },
    'Import CSV': {
        pt: "Importar CSV",
        es: "Importar CSV",
        ru: "Импортировать CSV",
        fr: "Importer CSV",
        de: "CSV Importieren"
    },
    'Import all properties by importing a CSV.': {
        pt: "Importe todas as propriedades importando um CSV.",
        es: "Importa todas las propiedades importando un CSV.",
        ru: "Импортируйте все свойства, импортируя CSV.",
        fr: "Importez toutes les propriétés en important un CSV.",
        de: "Importieren Sie alle Eigenschaften, indem Sie eine CSV importieren."
    },
    'or': {
        pt: "ou",
        es: "o",
        ru: "или",
        fr: "ou",
        de: "oder"
    },
    'Add One Property': {
        pt: "Adicionar Uma Propriedade",
        es: "Agregar Una Propiedad",
        ru: "Добавить Одну Собственность",
        fr: "Ajouter Une Propriété",
        de: "Eine Eigenschaft Hinzufügen"
    },
    'Country': {
        pt: "País",
        es: "País",
        ru: "Страна",
        fr: "Pays",
        de: "Land"
    },
    'Address': {
        pt: "Endereço",
        es: "Dirección",
        ru: "Адрес",
        fr: "Adresse",
        de: "Adresse"
    },
    'Unit / Apartment': {
        pt: "Unidade / Apartamento",
        es: "Unidad / Apartamento",
        ru: "Юнит / Квартира",
        fr: "Unité / Appartement",
        de: "Einheit / Wohnung"
    },
    'Postal Code': {
        pt: "Código Postal",
        es: "Código Postal",
        ru: "Почтовый Индекс",
        fr: "Code Postal",
        de: "Postleitzahl"
    },
    'Property Nickname (optional)': {
        pt: "Apelido da Propriedade (opcional)",
        es: "Apodo de la Propiedad (opcional)",
        ru: "Прозвище Собственности (необязательно)",
        fr: "Surnom de la Propriété (facultatif)",
        de: "Spitzname der Eigenschaft (optional)"
    },
    'Please confirm your address:': {
        pt: "Por favor, confirme seu endereço:",
        es: "Por favor, confirma tu dirección:",
        ru: "Пожалуйста, подтвердите ваш адрес:",
        fr: "Veuillez confirmer votre adresse :",
        de: "Bitte bestätigen Sie Ihre Adresse:"
    },
    'If you do not see your address please retype it above': {
        pt: "Se você não vê seu endereço, por favor, redigite-o acima",
        es: "Si no ves tu dirección, por favor vuelve a escribirla arriba",
        ru: "Если вы не видите свой адрес, пожалуйста, перепечатайте его выше",
        fr: "Si vous ne voyez pas votre adresse, veuillez la retaper ci-dessus",
        de: "Wenn Sie Ihre Adresse nicht sehen, geben Sie sie bitte oben erneut ein"
    },
    'An error occurred when getting address information. Please type another address.': {
        pt: "Ocorreu um erro ao obter informações de endereço. Por favor, digite outro endereço.",
        es: "Ocurrió un error al obtener información de la dirección. Por favor, escribe otra dirección.",
        ru: "Произошла ошибка при получении информации об адресе. Пожалуйста, введите другой адрес.",
        fr: "Une erreur s'est produite lors de l'obtention des informations d'adresse. Veuillez saisir une autre adresse.",
        de: "Beim Abrufen der Adressinformationen ist ein Fehler aufgetreten. Bitte geben Sie eine andere Adresse ein."
    },
    'It looks like this address isn\'t detailed enough, please update it. If you still have issues please contact us in the Concierge section.': {
        pt: "Parece que este endereço não é detalhado o suficiente, por favor atualize-o. Se você ainda tiver problemas, entre em contato conosco na seção Concierge.",
        es: "Parece que esta dirección no es lo suficientemente detallada, por favor actualízala. Si todavía tienes problemas, contáctanos en la sección de Conserjería.",
        ru: "Похоже, что этот адрес недостаточно подробен, пожалуйста, обновите его. Если у вас все еще есть проблемы, свяжитесь с нами в разделе консьержей.",
        fr: "Il semble que cette adresse ne soit pas assez détaillée, veuillez la mettre à jour. Si vous rencontrez toujours des problèmes, veuillez nous contacter dans la section Concierge.",
        de: "Es scheint, dass diese Adresse nicht detailliert genug ist, bitte aktualisieren Sie sie. Wenn Sie immer noch Probleme haben, kontaktieren Sie uns bitte im Concierge-Bereich."
    },
    'We could not validate your address with Google. Please enter an address that can be found with Google Maps.': {
        pt: "Não conseguimos validar seu endereço com o Google. Por favor, insira um endereço que possa ser encontrado no Google Maps.",
        es: "No pudimos validar tu dirección con Google. Por favor, ingresa una dirección que se pueda encontrar en Google Maps.",
        ru: "Мы не смогли проверить ваш адрес с помощью Google. Пожалуйста, введите адрес, который можно найти в Google Картах.",
        fr: "Nous n'avons pas pu valider votre adresse avec Google. Veuillez entrer une adresse qui peut être trouvée sur Google Maps.",
        de: "Wir konnten Ihre Adresse mit Google nicht validieren. Bitte geben Sie eine Adresse ein, die mit Google Maps gefunden werden kann."
    },

    //AddReservationPage
    'Reservation': {
        pt: "Reserva",
        es: "Reservación",
        ru: "Бронирование",
        fr: "Réservation",
        de: "Reservierung"
    },
    'Property': {
        pt: "Propriedade",
        es: "Propiedad",
        ru: "Собственность",
        fr: "Propriété",
        de: "Eigentum"
    },
    'Check In Date': {
        pt: "Data de Entrada",
        es: "Fecha de Entrada",
        ru: "Дата заезда",
        fr: "Date d'arrivée",
        de: "Ankunftsdatum"
    },
    'Check In Time': {
        pt: "Hora de Entrada",
        es: "Hora de Entrada",
        ru: "Время заезда",
        fr: "Heure d'arrivée",
        de: "Ankunftszeit"
    },
    'Check Out Date': {
        pt: "Data de Saída",
        es: "Fecha de Salida",
        ru: "Дата выезда",
        fr: "Date de départ",
        de: "Abreisedatum"
    },
    'Check Out Time': {
        pt: "Hora de Saída",
        es: "Hora de Salida",
        ru: "Время выезда",
        fr: "Heure de départ",
        de: "Abreisezeit"
    },
    'Guests': {
        pt: "Hóspedes",
        es: "Huéspedes",
        ru: "Гости",
        fr: "Invités",
        de: "Gäste"
    },
    'Adults': {
        pt: "Adultos",
        es: "Adultos",
        ru: "Взрослые",
        fr: "Adultes",
        de: "Erwachsene"
    },
    'Children': {
        pt: "Crianças",
        es: "Niños",
        ru: "Дети",
        fr: "Enfants",
        de: "Kinder"
    },
    'Pets': {
        pt: "Animais de estimação",
        es: "Mascotas",
        ru: "Домашние животные",
        fr: "Animaux de compagnie",
        de: "Haustiere"
    },
    'Add Reservation': {
        pt: "Adicionar Reserva",
        es: "Agregar Reservación",
        ru: "Добавить бронирование",
        fr: "Ajouter une réservation",
        de: "Reservierung hinzufügen"
    },
    'Error creating reservation, please try again.': {
        pt: "Erro ao criar reserva, por favor tente novamente.",
        es: "Error al crear la reservación, por favor intente de nuevo.",
        ru: "Ошибка при создании бронирования, пожалуйста, попробуйте еще раз.",
        fr: "Erreur lors de la création de la réservation, veuillez réessayer.",
        de: "Fehler bei der Erstellung der Reservierung, bitte versuchen Sie es erneut."
    },
    'Reservation created successfully': {
        pt: "Reserva criada com sucesso",
        es: "Reservación creada con éxito",
        ru: "Бронирование успешно создано",
        fr: "Réservation créée avec succès",
        de: "Reservierung erfolgreich erstellt"
    },

    //AssignProPage
    'Change Pro': {
        pt: "Mudar Profissional",
        es: "Cambiar Profesional",
        ru: "Сменить профессионала",
        fr: "Changer de Pro",
        de: "Pro wechseln"
    },
    'Assign Pro': {
        pt: "Atribuir Profissional",
        es: "Asignar Profesional",
        ru: "Назначить профессионала",
        fr: "Assigner un Pro",
        de: "Pro zuweisen"
    },
    'What do you want to do?': {
        pt: "O que você deseja fazer?",
        es: "¿Qué quieres hacer?",
        ru: "Что вы хотите сделать?",
        fr: "Que voulez-vous faire ?",
        de: "Was möchten Sie tun?"
    },
    'You haven\'t added any private pros. Add pros to book jobs with them, collect payment and more.': {
        pt: "Você não adicionou nenhum profissional privado. Adicione profissionais para reservar trabalhos com eles, coletar pagamento e mais.",
        es: "No has añadido ningún profesional privado. Añade profesionales para reservar trabajos con ellos, cobrar pagos y más.",
        ru: "Вы не добавили никаких частных профессионалов. Добавьте профессионалов для бронирования работ с ними, сбора платежей и другого.",
        fr: "Vous n'avez ajouté aucun pro privé. Ajoutez des pros pour réserver des emplois avec eux, collecter des paiements et plus encore.",
        de: "Sie haben keine privaten Pros hinzugefügt. Fügen Sie Pros hinzu, um Jobs mit ihnen zu buchen, Zahlungen zu sammeln und mehr."
    },
    'Select the pro:': {
        pt: "Selecione o profissional:",
        es: "Selecciona el profesional:",
        ru: "Выберите профессионала:",
        fr: "Sélectionnez le pro :",
        de: "Wählen Sie den Pro aus:"
    },
    'Their Name*': {
        pt: "Nome deles*",
        es: "Su nombre*",
        ru: "Их имя*",
        fr: "Leur nom*",
        de: "Ihr Name*"
    },
    'Their Email*': {
        pt: "Email deles*",
        es: "Su correo electrónico*",
        ru: "Их электронная почта*",
        fr: "Leur e-mail*",
        de: "Ihre E-Mail*"
    },
    'Their Phone': {
        pt: "Telefone deles",
        es: "Su teléfono",
        ru: "Их телефон",
        fr: "Leur téléphone",
        de: "Ihr Telefon"
    },
    'Best Available Pro': {
        pt: "Melhor Profissional Disponível",
        es: "Mejor Profesional Disponible",
        ru: "Лучший доступный профессионал",
        fr: "Meilleur Pro disponible",
        de: "Bester verfügbarer Pro"
    },
    'Add & Change Pro': {
        pt: "Adicionar e Mudar Profissional",
        es: "Añadir y Cambiar Profesional",
        ru: "Добавить и сменить профессионала",
        fr: "Ajouter et Changer de Pro",
        de: "Pro hinzufügen und wechseln"
    },
    'Add & Assign Pro': {
        pt: "Adicionar e Atribuir Profissional",
        es: "Añadir y Asignar Profesional",
        ru: "Добавить и назначить профессионала",
        fr: "Ajouter et Assigner un Pro",
        de: "Pro hinzufügen und zuweisen"
    },
    'Change to an existing pro': {
        pt: "Mudar para um profissional existente",
        es: "Cambiar a un profesional existente",
        ru: "Сменить на существующего профессионала",
        fr: "Changer pour un pro existant",
        de: "Zu einem vorhandenen Pro wechseln"
    },
    'Assign to an existing pro': {
        pt: "Atribuir a um profissional existente",
        es: "Asignar a un profesional existente",
        ru: "Назначить существующего профессионала",
        fr: "Assigner à un pro existant",
        de: "Einen vorhandenen Pro zuweisen"
    },
    'Add & change to a new pro': {
        pt: "Adicionar e mudar para um novo profissional",
        es: "Añadir y cambiar a un nuevo profesional",
        ru: "Добавить и сменить на нового профессионала",
        fr: "Ajouter et changer pour un nouveau pro",
        de: "Einen neuen Pro hinzufügen und wechseln"
    },
    'Add & assign to a new pro': {
        pt: "Adicionar e atribuir a um novo profissional",
        es: "Añadir y asignar a un nuevo profesional",
        ru: "Добавить и назначить нового профессионала",
        fr: "Ajouter et assigner à un nouveau pro",
        de: "Einen neuen Pro hinzufügen und zuweisen"
    },

    //BillingHistoryPage
    'Added from job on ': {
        pt: "Adicionado de trabalho em ",
        es: "Adicionado de trabajo en ",
        ru: "Добавлено из работы на ",
        fr: "Ajouté depuis un travail le ",
        de: "Hinzugefügt von einem Job am "
    },
    'Billing History': {
        pt: "Histórico de Cobrança",
        es: "Historial de Facturación",
        ru: "История Биллинга",
        fr: "Historique de Facturation",
        de: "Abrechnungsverlauf"
    },
    'Note: Your payment settings may require credit to reserve appointments, check the Account section for details. ': {
        pt: "Nota: Suas configurações de pagamento podem exigir crédito para reservar compromissos, verifique a seção Conta para detalhes.",
        es: "Nota: Tus configuraciones de pago pueden requerir crédito para reservar citas, revisa la sección de Cuenta para más detalles.",
        ru: "Примечание: Ваши настройки платежей могут требовать кредита для бронирования встреч, проверьте раздел учетной записи для получения деталей.",
        fr: "Remarque : Vos paramètres de paiement peuvent nécessiter un crédit pour réserver des rendez-vous, consultez la section Compte pour plus de détails.",
        de: "Hinweis: Ihre Zahlungseinstellungen können Kredit erfordern, um Termine zu reservieren, überprüfen Sie den Kontobereich für Details."
    },
    'You have no refundable credit.': {
        pt: "Você não tem crédito reembolsável.",
        es: "No tienes crédito reembolsable.",
        ru: "У вас нет возвратного кредита.",
        fr: "Vous n'avez pas de crédit remboursable.",
        de: "Sie haben keinen erstattungsfähigen Kredit."
    },
    ' of your credit is refundable if you do not use it.': {
        pt: " do seu crédito é reembolsável se não for utilizado.",
        es: " de tu crédito es reembolsable si no lo usas.",
        ru: " вашего кредита возвращается, если вы его не используете.",
        fr: " de votre crédit est remboursable si vous ne l'utilisez pas.",
        de: " Ihres Kredits ist erstattungsfähig, wenn Sie ihn nicht nutzen."
    },
    'This is ': {
        pt: "Isto é ",
        es: "Esto es ",
        ru: "Это ",
        fr: "Ceci est ",
        de: "Das ist "
    },
    '\'s billing history with you. It only includes billing items that relate to them.': {
        pt: " histórico de cobrança com você. Inclui apenas itens de cobrança que se relacionam com eles.",
        es: " historial de facturación contigo. Solo incluye elementos de facturación que se relacionan con ellos.",
        ru: " история биллинга с вами. Включает только платежные позиции, относящиеся к ним.",
        fr: " historique de facturation avec vous. Il comprend uniquement les éléments de facturation qui les concernent.",
        de: " Abrechnungshistorie mit Ihnen. Es beinhaltet nur Abrechnungsposten, die sich auf sie beziehen."
    },
    'Current Credit: ': {
        pt: "Crédito Atual: ",
        es: "Crédito Actual: ",
        ru: "Текущий Кредит: ",
        fr: "Crédit Actuel: ",
        de: "Aktuelles Guthaben: "
    },
    'Added from ': {
        pt: "Adicionado de ",
        es: "Añadido desde ",
        ru: "Добавлено из ",
        fr: "Ajouté depuis ",
        de: "Hinzugefügt von "
    },
    ' job': {
        pt: " trabalho",
        es: " trabajo",
        ru: " работа",
        fr: " travail",
        de: " Job"
    },
    'Added by ': {
        pt: "Adicionado por ",
        es: "Añadido por ",
        ru: "Добавлено ",
        fr: "Ajouté par ",
        de: "Hinzugefügt von "
    },
    ' used': {
        pt: " usado",
        es: " usado",
        ru: " использовано",
        fr: " utilisé",
        de: " verwendet"
    },
    ' by a ': {
        pt: " por um ",
        es: " por un ",
        ru: " по ",
        fr: " par un ",
        de: " von einem "
    },
    ' cleaning on ': {
        pt: " limpeza em ",
        es: " limpieza en ",
        ru: " уборка на ",
        fr: " nettoyage le ",
        de: " Reinigung am "
    },
    ' by': {
        pt: " por",
        es: " por",
        ru: " по",
        fr: " par",
        de: " von"
    },
    ' for ': {
        pt: " para ",
        es: " para ",
        ru: " для ",
        fr: " pour ",
        de: " für "
    },
    'Waive Fee': {
        pt: "Isentar Taxa",
        es: "Eximir de Tarifa",
        ru: "Отменить Сбор",
        fr: "Annuler les Frais",
        de: "Gebühr erlassen"
    },
    'Where is my refund?': {
        pt: "Onde está o meu reembolso?",
        es: "¿Dónde está mi reembolso?",
        ru: "Где мой возврат?",
        fr: "Où est mon remboursement?",
        de: "Wo ist meine Rückerstattung?"
    },
    ' refunded. ': {
        pt: " reembolsado. ",
        es: " reembolsado. ",
        ru: " возвращено. ",
        fr: " remboursé. ",
        de: " erstattet. "
    },
    'Get Refund': {
        pt: "Obter Reembolso",
        es: "Obtener Reembolso",
        ru: "Получить возврат",
        fr: "Obtenir un remboursement",
        de: "Rückerstattung erhalten"
    },
    'Get Help': {
        pt: "Obter Ajuda",
        es: "Obtener Ayuda",
        ru: "Получить помощь",
        fr: "Obtenir de l'aide",
        de: "Hilfe bekommen"
    },
    'More history unavailable - Please contact us in the Concierge section for support.': {
        pt: "Mais histórico indisponível - Por favor, contate-nos na seção Concierge para suporte.",
        es: "Más historial no disponible - Por favor, contáctenos en la sección de Conserjería para soporte.",
        ru: "Больше истории недоступно - Пожалуйста, свяжитесь с нами в разделе консьержей для поддержки.",
        fr: "Plus d'historique disponible - Veuillez nous contacter dans la section Concierge pour obtenir de l'aide.",
        de: "Weitere Historie nicht verfügbar - Bitte kontaktieren Sie uns im Concierge-Bereich für Unterstützung."
    },
    'More history unavailable - Please contact your pro for support.': {
        pt: "Mais histórico indisponível - Por favor, contate seu profissional para suporte.",
        es: "Más historial no disponible - Por favor, contacta a tu profesional para soporte.",
        ru: "Больше истории недоступно - Пожалуйста, свяжитесь с вашим профессионалом для поддержки.",
        fr: "Plus d'historique disponible - Veuillez contacter votre professionnel pour obtenir de l'aide.",
        de: "Weitere Historie nicht verfügbar - Bitte kontaktieren Sie Ihren Profi für Unterstützung."
    },
    'All Properties': {
        pt: "Todas as Propriedades",
        es: "Todas las Propiedades",
        ru: "Все свойства",
        fr: "Toutes les propriétés",
        de: "Alle Eigenschaften"
    },
    'TIDY History': {
        pt: "Histórico TIDY",
        es: "Historial TIDY",
        ru: "История TIDY",
        fr: "Historique TIDY",
        de: "TIDY Verlauf"
    },
    'Unable to Refund': {
        pt: "Incapaz de Reembolsar",
        es: "Incapaz de Reembolsar",
        ru: "Невозможно вернуть",
        fr: "Impossible de rembourser",
        de: "Kann nicht erstatten"
    },
    'This charge has already been used by a job. Please contact us in the Concierge section for support.': {
        pt: "Esta cobrança já foi utilizada por um trabalho. Por favor, contate-nos na seção Concierge para suporte.",
        es: "Este cargo ya ha sido utilizado por un trabajo. Por favor, contáctenos en la sección de Conserjería para soporte.",
        ru: "Этот платеж уже был использован в работе. Пожалуйста, свяжитесь с нами в разделе консьержей для поддержки.",
        fr: "Cette charge a déjà été utilisée par un travail. Veuillez nous contacter dans la section Concierge pour obtenir de l'aide.",
        de: "Diese Gebühr wurde bereits von einem Job verwendet. Bitte kontaktieren Sie uns im Concierge-Bereich für Unterstützung."
    },
    'Ok': {
        pt: "Ok",
        es: "Ok",
        ru: "Ок",
        fr: "Ok",
        de: "Ok"
    },
    'You can waive up to 2 cancellation fees every 10 completed cleanings. Must be within 90 days of the fee. Please click "Get Help" on the charge if you have any questions.': {
        pt: "Você pode isentar até 2 taxas de cancelamento a cada 10 limpezas completas. Deve ser dentro de 90 dias da taxa. Por favor, clique em \"Obter Ajuda\" na cobrança se tiver alguma dúvida.",
        es: "Puedes eximir hasta 2 tarifas de cancelación cada 10 limpiezas completadas. Debe ser dentro de 90 días de la tarifa. Por favor, haz clic en \"Obtener Ayuda\" en el cargo si tienes alguna pregunta.",
        ru: "Вы можете отменить до 2 сборов за отмену каждые 10 выполненных уборок. Должно быть в течение 90 дней с момента взимания платы. Пожалуйста, нажмите \"Получить помощь\" по платежу, если у вас есть вопросы.",
        fr: "Vous pouvez renoncer à jusqu'à 2 frais d'annulation pour 10 nettoyages complétés. Doit être dans les 90 jours suivant les frais. Veuillez cliquer sur \"Obtenir de l'aide\" sur la charge si vous avez des questions.",
        de: "Sie können bis zu 2 Stornogebühren für jeweils 10 abgeschlossene Reinigungen erlassen. Muss innerhalb von 90 Tagen nach der Gebühr sein. Bitte klicken Sie auf \"Hilfe bekommen\" bei der Gebühr, wenn Sie Fragen haben."
    },
    'Billing Help': {
        pt: "Ajuda com Cobrança",
        es: "Ayuda con Facturación",
        ru: "Помощь с биллингом",
        fr: "Aide à la facturation",
        de: "Hilfe bei der Abrechnung"
    },
    'Fee Waived': {
        pt: "Taxa Isenta",
        es: "Tarifa Eximida",
        ru: "Сбор аннулирован",
        fr: "Frais annulés",
        de: "Gebühr erlassen"
    },
    'Unable to Waive Fee': {
        pt: "Incapaz de Isentar Taxa",
        es: "Incapaz de Eximir Tarifa",
        ru: "Невозможно аннулировать сбор",
        fr: "Impossible d'annuler les frais",
        de: "Gebühr kann nicht erlassen werden"
    },

    //GetRefundPage
    'Confirm Refund': {
        pt: "Confirmar Reembolso",
        es: "Confirmar Reembolso",
        ru: "Подтвердить возврат",
        fr: "Confirmer le remboursement",
        de: "Rückerstattung bestätigen"
    },
    'Why are you requesting a refund?': {
        pt: "Por que você está solicitando um reembolso?",
        es: "¿Por qué solicitas un reembolso?",
        ru: "Почему вы запрашиваете возврат?",
        fr: "Pourquoi demandez-vous un remboursement?",
        de: "Warum beantragen Sie eine Rückerstattung?"
    },
    'Submit': {
        pt: "Enviar",
        es: "Enviar",
        ru: "Отправить",
        fr: "Soumettre",
        de: "Einreichen"
    },
    'Your refund has been issued, and someone will reach out shortly to address your issue.  We would love to make things right.': {
        pt: "Seu reembolso foi emitido, e alguém entrará em contato em breve para resolver seu problema. Gostaríamos de corrigir as coisas.",
        es: "Se ha emitido tu reembolso y alguien se pondrá en contacto contigo pronto para resolver tu problema. Nos encantaría solucionar las cosas.",
        ru: "Ваш возврат был оформлен, и скоро с вами свяжутся, чтобы решить вашу проблему. Мы хотели бы исправить ситуацию.",
        fr: "Votre remboursement a été effectué, et quelqu'un vous contactera sous peu pour résoudre votre problème. Nous aimerions rectifier les choses.",
        de: "Ihre Rückerstattung wurde erteilt, und jemand wird sich in Kürze mit Ihnen in Verbindung setzen, um Ihr Problem zu lösen. Wir möchten die Dinge richtig stellen."
    },
    'Refund successful. A refund will appear on your original payment method in 2-10 business days.': {
        pt: "Reembolso bem-sucedido. Um reembolso aparecerá no seu método de pagamento original em 2-10 dias úteis.",
        es: "Reembolso exitoso. Un reembolso aparecerá en tu método de pago original en 2-10 días hábiles.",
        ru: "Возврат успешен. Возврат появится на вашем исходном способе оплаты в течение 2-10 рабочих дней.",
        fr: "Remboursement réussi. Un remboursement apparaîtra sur votre méthode de paiement originale dans 2 à 10 jours ouvrables.",
        de: "Rückerstattung erfolgreich. Eine Rückerstattung erscheint auf Ihrer ursprünglichen Zahlungsmethode innerhalb von 2-10 Werktagen."
    },
    'Charge Refunded': {
        pt: "Cobrança Reembolsada",
        es: "Cargo Reembolsado",
        ru: "Плата возвращена",
        fr: "Frais remboursés",
        de: "Gebühr erstattet"
    },
    'No availability I want': {
        pt: "Não há disponibilidade que eu deseje",
        es: "No hay disponibilidad que deseo",
        ru: "Нет нужной доступности",
        fr: "Pas de disponibilité que je souhaite",
        de: "Keine Verfügbarkeit, die ich möchte"
    },
    'Moving/traveling': {
        pt: "Mudança/viagem",
        es: "Mudanza/viaje",
        ru: "Переезд/путешествие",
        fr: "Déménagement/voyage",
        de: "Umzug/Reisen"
    },
    'Don’t need cleaning right now': {
        pt: "Não preciso de limpeza no momento",
        es: "No necesito limpieza ahora",
        ru: "Сейчас не нужна уборка",
        fr: "Je n'ai pas besoin de nettoyage pour le moment",
        de: "Brauche gerade keine Reinigung"
    },
    'Unhappy with cleaning': {
        pt: "Insatisfeito com a limpeza",
        es: "Insatisfecho con la limpieza",
        ru: "Недоволен уборкой",
        fr: "Mécontent de la propreté",
        de: "Unzufrieden mit der Reinigung"
    },
    'Pro canceled on me': {
        pt: "O profissional cancelou em mim",
        es: "El profesional canceló sobre mí",
        ru: "Профессионал отменил на мне",
        fr: "Le pro a annulé sur moi",
        de: "Pro hat bei mir abgesagt"
    },
    'I prefer to work directly with my Pro': {
        pt: "Prefiro trabalhar diretamente com meu profissional",
        es: "Prefiero trabajar directamente con mi profesional",
        ru: "Я предпочитаю работать напрямую с моим профессионалом",
        fr: "Je préfère travailler directement avec mon pro",
        de: "Ich arbeite lieber direkt mit meinem Pro"
    },

    //BillsPage
    'Status': {
        pt: 'Estado',
        es: 'Estado',
        ru: 'Статус',
        fr: 'Statut',
        de: 'Status'
    },
    'Pro': {
        pt: 'Profissional',
        es: 'Profesional',
        ru: 'Профессионал',
        fr: 'Pro',
        de: 'Profi'
    },
    'Date Range': {
        pt: 'Intervalo de datas',
        es: 'Rango de fechas',
        ru: 'Диапазон дат',
        fr: 'Plage de dates',
        de: 'Datumsbereich'
    },
    'No Results': {
        pt: 'Sem resultados',
        es: 'Sin resultados',
        ru: 'Нет результатов',
        fr: 'Aucun résultat',
        de: 'Keine Ergebnisse'
    },
    'Service': {
        pt: 'Serviço',
        es: 'Servicio',
        ru: 'Сервис',
        fr: 'Service',
        de: 'Dienst'
    },
    'Owed': {
        pt: 'Devido',
        es: 'Adeudado',
        ru: 'Должен',
        fr: 'Dû',
        de: 'Geschuldet'
    },
    'Total': {
        pt: 'Total',
        es: 'Total',
        ru: 'Итого',
        fr: 'Total',
        de: 'Gesamt'
    },
    'Credit Applied': {
        pt: 'Crédito aplicado',
        es: 'Crédito aplicado',
        ru: 'Примененный кредит',
        fr: 'Crédit appliqué',
        de: 'Angewandtes Guthaben'
    },
    'All': {
        pt: 'Todos',
        es: 'Todos',
        ru: 'Все',
        fr: 'Tous',
        de: 'Alle'
    },
    'Pending': {
        pt: 'Pendente',
        es: 'Pendiente',
        ru: 'В ожидании',
        fr: 'En attente',
        de: 'Ausstehend'
    },
    'Paid': {
        pt: 'Pago',
        es: 'Pagado',
        ru: 'Оплачено',
        fr: 'Payé',
        de: 'Bezahlt'
    },
    'Custom': {
        pt: 'Personalizado',
        es: 'Personalizado',
        ru: 'На заказ',
        fr: 'Personnalisé',
        de: 'Benutzerdefiniert'
    },
    'Today': {
        pt: 'Hoje',
        es: 'Hoy',
        ru: 'Сегодня',
        fr: 'Aujourd\'hui',
        de: 'Heute'
    },
    'Last Week': {
        pt: 'Semana passada',
        es: 'La semana pasada',
        ru: 'На прошлой неделе',
        fr: 'La semaine dernière',
        de: 'Letzte Woche'
    },
    'Last Month': {
        pt: 'Mês passado',
        es: 'El mes pasado',
        ru: 'В прошлом месяце',
        fr: 'Le mois dernier',
        de: 'Letzter Monat'
    },
    'All Time': {
        pt: 'Todo o tempo',
        es: 'Todo el tiempo',
        ru: 'Все время',
        fr: 'Tout le temps',
        de: 'Alle Zeit'
    },
    'Job': {
        pt: 'Trabalho',
        es: 'Trabajo',
        ru: 'Работа',
        fr: 'Emploi',
        de: 'Job'
    },
    'Job Date': {
        pt: 'Data do trabalho',
        es: 'Fecha del trabajo',
        ru: 'Дата работы',
        fr: 'Date du travail',
        de: 'Arbeitsdatum'
    },
    'Total Owed': {
        pt: 'Total devido',
        es: 'Total adeudado',
        ru: 'Общая задолженность',
        fr: 'Total dû',
        de: 'Gesamtschuld'
    },
    'Total Paid': {
        pt: 'Total pago',
        es: 'Total pagado',
        ru: 'Всего оплачено',
        fr: 'Total payé',
        de: 'Insgesamt bezahlt'
    },

    //BillSettingsPage
    'Bill Settings': {
        pt: 'Configurações de Conta',
        es: 'Configuraciones de Facturación',
        ru: 'Настройки счета',
        fr: 'Paramètres de Facturation',
        de: 'Rechnungseinstellungen'
    },
    'Email to send bills to': {
        pt: 'Email para enviar contas',
        es: 'Correo electrónico para enviar facturas',
        ru: 'Электронная почта для отправки счетов',
        fr: 'Email pour envoyer les factures',
        de: 'E-Mail zum Senden von Rechnungen'
    },
    'Save Changes': {
        pt: 'Salvar alterações',
        es: 'Guardar cambios',
        ru: 'Сохранить изменения',
        fr: 'Sauvegarder les modifications',
        de: 'Änderungen speichern'
    },
    'Please enter a valid email.': {
        pt: 'Por favor, insira um email válido.',
        es: 'Por favor, introduzca un correo electrónico válido.',
        ru: 'Пожалуйста, введите действительный адрес электронной почты.',
        fr: 'Veuillez entrer un email valide.',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
    },

    //BookOrRequestPage
    'How do you want to book?': {
        pt: 'Como você quer reservar?',
        es: '¿Cómo quieres reservar?',
        ru: 'Как вы хотите забронировать?',
        fr: 'Comment souhaitez-vous réserver?',
        de: 'Wie möchten Sie buchen?'
    },
    'Request': {
        pt: 'Solicitar',
        es: 'Solicitar',
        ru: 'Запрос',
        fr: 'Demander',
        de: 'Anfordern'
    },
    'Book': {
        pt: 'Reservar',
        es: 'Reservar',
        ru: 'Бронировать',
        fr: 'Réserver',
        de: 'Buchen'
    },
    'Browse Pros': {
        pt: 'Procurar Profissionais',
        es: 'Buscar Profesionales',
        ru: 'Просмотреть профессионалов',
        fr: 'Parcourir les Pros',
        de: 'Profis durchsuchen'
    },
    'Add Pro': {
        pt: 'Adicionar Profissional',
        es: 'Añadir Profesional',
        ru: 'Добавить профессионала',
        fr: 'Ajouter un Pro',
        de: 'Pro hinzufügen'
    },
    'Want a specific pro?': {
        pt: 'Quer um profissional específico?',
        es: '¿Quieres un profesional específico?',
        ru: 'Хотите определенного профессионала?',
        fr: 'Vous voulez un pro spécifique?',
        de: 'Möchten Sie einen bestimmten Profi?'
    },
    'View More': {
        pt: 'Ver mais',
        es: 'Ver más',
        ru: 'Посмотреть больше',
        fr: 'Voir plus',
        de: 'Mehr anzeigen'
    },
    'Want a new pro?': {
        pt: 'Quer um novo profissional?',
        es: '¿Quieres un nuevo profesional?',
        ru: 'Хотите нового профессионала?',
        fr: 'Vous voulez un nouveau pro?',
        de: 'Möchten Sie einen neuen Profi?'
    },
    'Instantly Book a New Pro': {
        pt: 'Reservar Instantaneamente um Novo Profissional',
        es: 'Reservar Instantáneamente un Nuevo Profesional',
        ru: 'Мгновенно забронировать нового профессионала',
        fr: 'Réserver instantanément un nouveau pro',
        de: 'Sofort einen neuen Profi buchen'
    },
    'Book Jobs': {
        pt: 'Reservar Trabalhos',
        es: 'Reservar Trabajos',
        ru: 'Бронировать работы',
        fr: 'Réserver des emplois',
        de: 'Jobs buchen'
    },
    'Add One Time Job': {
        pt: 'Adicionar Trabalho Único',
        es: 'Añadir Trabajo Único',
        ru: 'Добавить разовую работу',
        fr: 'Ajouter un travail ponctuel',
        de: 'Einmaligen Job hinzufügen'
    },
    'Reschedule Plan': {
        pt: 'Reagendar Plano',
        es: 'Reprogramar Plan',
        ru: 'Перепланировать план',
        fr: 'Replanifier le plan',
        de: 'Plan umplanen'
    },
    'Reschedule Job': {
        pt: 'Reagendar Trabalho',
        es: 'Reprogramar Trabajo',
        ru: 'Перенести работу',
        fr: 'Replanifier le travail',
        de: 'Job umplanen'
    },

    //AddFirstAddressPage
    'offers a': {
        pt: 'oferece um',
        es: 'ofrece un',
        ru: 'предлагает',
        fr: 'offre un',
        de: 'bietet ein'
    },
    '% discount on all cleanings.': {
        pt: '% de desconto em todas as limpezas.',
        es: '% de descuento en todas las limpiezas.',
        ru: '% скидка на все уборки.',
        fr: '% de réduction sur tous les nettoyages.',
        de: '% Rabatt auf alle Reinigungen.'
    },
    'Already have an account? ': {
        pt: 'Já tem uma conta? ',
        es: '¿Ya tienes una cuenta? ',
        ru: 'Уже есть аккаунт? ',
        fr: 'Vous avez déjà un compte? ',
        de: 'Haben Sie bereits ein Konto? '
    },
    'Log In': {
        pt: 'Entrar',
        es: 'Iniciar sesión',
        ru: 'Войти',
        fr: 'Connexion',
        de: 'Einloggen'
    },
    'You have': {
        pt: 'Você tem',
        es: 'Tienes',
        ru: 'У вас есть',
        fr: 'Vous avez',
        de: 'Sie haben'
    },
    'in credit': {
        pt: 'em crédito',
        es: 'en crédito',
        ru: 'в кредит',
        fr: 'en crédit',
        de: 'als Guthaben'
    },
    '. Add a property to use your credit to pay a pro or book a job.': {
        pt: '. Adicione uma propriedade para usar seu crédito para pagar um profissional ou reservar um trabalho.',
        es: '. Añade una propiedad para usar tu crédito para pagar a un profesional o reservar un trabajo.',
        ru: '. Добавьте недвижимость, чтобы использовать свой кредит для оплаты профессионала или бронирования работы.',
        fr: '. Ajoutez une propriété pour utiliser votre crédit pour payer un pro ou réserver un travail.',
        de: '. Fügen Sie eine Eigenschaft hinzu, um Ihr Guthaben zu verwenden, um einen Profi zu bezahlen oder einen Job zu buchen.'
    },
    'Enter the address for instant pricing & availability.': {
        pt: 'Digite o endereço para precificação instantânea e disponibilidade.',
        es: 'Introduce la dirección para obtener precios y disponibilidad instantáneos.',
        ru: 'Введите адрес для мгновенного определения цены и наличия.',
        fr: 'Entrez l\'adresse pour un tarif instantané et la disponibilité.',
        de: 'Geben Sie die Adresse für sofortige Preisgestaltung und Verfügbarkeit ein.'
    },
    'All TIDY features require a property. Add your first one today.': {
        pt: 'Todos os recursos TIDY requerem uma propriedade. Adicione a sua primeira hoje.',
        es: 'Todas las características de TIDY requieren una propiedad. Añade tu primera hoy.',
        ru: 'Все функции TIDY требуют наличия недвижимости. Добавьте свою первую сегодня.',
        fr: 'Toutes les fonctionnalités TIDY nécessitent une propriété. Ajoutez votre première aujourd\'hui.',
        de: 'Alle TIDY-Funktionen erfordern eine Eigenschaft. Fügen Sie heute Ihre erste hinzu.'
    },
    'All TIDY features require a property. Please add a property to view the app.': {
        pt: 'Todos os recursos TIDY requerem uma propriedade. Por favor, adicione uma propriedade para visualizar o aplicativo.',
        es: 'Todas las características de TIDY requieren una propiedad. Por favor, añade una propiedad para ver la aplicación.',
        ru: 'Все функции TIDY требуют наличия недвижимости. Пожалуйста, добавьте недвижимость, чтобы просмотреть приложение.',
        fr: 'Toutes les fonctionnalités TIDY nécessitent une propriété. Veuillez ajouter une propriété pour voir l\'application.',
        de: 'Alle TIDY-Funktionen erfordern eine Eigenschaft. Bitte fügen Sie eine Eigenschaft hinzu, um die App zu sehen.'
    },
    'Enter the address for instant pricing & availability for cleanings in your area.': {
        pt: 'Digite o endereço para precificação instantânea e disponibilidade para limpezas na sua área.',
        es: 'Introduce la dirección para obtener precios y disponibilidad instantáneos para limpiezas en tu área.',
        ru: 'Введите адрес для мгновенного определения цены и наличия уборки в вашем районе.',
        fr: 'Entrez l\'adresse pour un tarif instantané et la disponibilité pour les nettoyages dans votre région.',
        de: 'Geben Sie die Adresse für sofortige Preisgestaltung und Verfügbarkeit für Reinigungen in Ihrem Bereich ein.'
    },
    'Import All Properties': {
        pt: 'Importar Todas as Propriedades',
        es: 'Importar Todas las Propiedades',
        ru: 'Импортировать все свойства',
        fr: 'Importer toutes les propriétés',
        de: 'Alle Eigenschaften importieren'
    },
    'Phone': {
        pt: 'Telefone',
        es: 'Teléfono',
        ru: 'Телефон',
        fr: 'Téléphone',
        de: 'Telefon'
    },
    'Account Created': {
        pt: 'Conta Criada',
        es: 'Cuenta Creada',
        ru: 'Аккаунт Создан',
        fr: 'Compte Créé',
        de: 'Konto Erstellt'
    },
    'You\'re ready to start using TIDY Developer features.': {
        pt: 'Você está pronto para começar a usar os recursos de Desenvolvedor TIDY.',
        es: 'Estás listo para empezar a usar las características de Desarrollador TIDY.',
        ru: 'Вы готовы начать использовать функции разработчика TIDY.',
        fr: 'Vous êtes prêt à commencer à utiliser les fonctionnalités de Développeur TIDY.',
        de: 'Sie sind bereit, die TIDY-Entwicklerfunktionen zu nutzen.'
    },

    //BookJobPage
    'Reschedule': {
        pt: 'Reagendar',
        es: 'Reprogramar',
        ru: 'Перенести',
        fr: 'Replanifier',
        de: 'Umbuchen'
    },
    'Add Job': {
        pt: 'Adicionar Trabalho',
        es: 'Añadir Trabajo',
        ru: 'Добавить работу',
        fr: 'Ajouter un travail',
        de: 'Job hinzufügen'
    },
    'Review Booking': {
        pt: 'Revisar Reserva',
        es: 'Revisar Reserva',
        ru: 'Проверить бронирование',
        fr: 'Réviser la réservation',
        de: 'Buchung überprüfen'
    },
    'One Time': {
        pt: 'Uma vez',
        es: 'Una vez',
        ru: 'Один раз',
        fr: 'Une fois',
        de: 'Einmal'
    },
    'Unable to Book': {
        pt: 'Não é possível Reservar',
        es: 'No se puede Reservar',
        ru: 'Невозможно забронировать',
        fr: 'Impossible de réserver',
        de: 'Buchung nicht möglich'
    },
    'You don\'t allow new pros and haven\'t added any of your new pros. Please enable TIDY Find New Pros or add your own pros to book.': {
        pt: 'Você não permite novos profissionais e não adicionou nenhum dos seus novos profissionais. Por favor, habilite TIDY Encontrar Novos Profissionais ou adicione seus próprios profissionais para reservar.',
        es: 'No permites nuevos profesionales y no has añadido ninguno de tus nuevos profesionales. Por favor, habilita TIDY Encontrar Nuevos Profesionales o añade tus propios profesionales para reservar.',
        ru: 'Вы не разрешаете новых профессионалов и не добавили ни одного из ваших новых профессионалов. Пожалуйста, включите TIDY Найти Новых Профессионалов или добавьте своих собственных профессионалов для бронирования.',
        fr: 'Vous n\'autorisez pas de nouveaux professionnels et n\'avez ajouté aucun de vos nouveaux professionnels. Veuillez activer TIDY Trouver de Nouveaux Pros ou ajoutez vos propres professionnels pour réserver.',
        de: 'Sie erlauben keine neuen Profis und haben keine Ihrer neuen Profis hinzugefügt. Bitte aktivieren Sie TIDY Neue Profis finden oder fügen Sie Ihre eigenen Profis hinzu, um zu buchen.'
    },
    'Allow TIDY Find New Pros': {
        pt: 'Permitir TIDY Encontrar Novos Profissionais',
        es: 'Permitir TIDY Encontrar Nuevos Profesionales',
        ru: 'Разрешить TIDY Найти Новых Профессионалов',
        fr: 'Permettre à TIDY de Trouver de Nouveaux Pros',
        de: 'TIDY erlauben, neue Profis zu finden'
    },
    'Add Pros': {
        pt: 'Adicionar Profissionais',
        es: 'Añadir Profesionales',
        ru: 'Добавить профессионалов',
        fr: 'Ajouter des Pros',
        de: 'Profis hinzufügen'
    },
    'You don\'t allow new pros. Please enable TIDY Find New Pros to book a new pro.': {
        pt: 'Você não permite novos profissionais. Por favor, habilite TIDY Encontrar Novos Profissionais para reservar um novo profissional.',
        es: 'No permites nuevos profesionales. Por favor, habilita TIDY Encontrar Nuevos Profesionales para reservar un nuevo profesional.',
        ru: 'Вы не разрешаете новых профессионалов. Пожалуйста, включите TIDY Найти Новых Профессионалов, чтобы забронировать нового профессионала.',
        fr: 'Vous n\'autorisez pas de nouveaux professionnels. Veuillez activer TIDY Trouver de Nouveaux Pros pour réserver un nouveau pro.',
        de: 'Sie erlauben keine neuen Profis. Bitte aktivieren Sie TIDY Neue Profis finden, um einen neuen Profi zu buchen.'
    },
    'Select Service': {
        pt: 'Selecionar Serviço',
        es: 'Seleccionar Servicio',
        ru: 'Выбрать услугу',
        fr: 'Sélectionner le service',
        de: 'Dienst auswählen'
    },
    'For': {
        pt: 'Para',
        es: 'Para',
        ru: 'Для',
        fr: 'Pour',
        de: 'Für'
    },
    ' with ': {
        pt: ' com ',
        es: ' con ',
        ru: ' с ',
        fr: ' avec ',
        de: ' mit '
    },
    ' from ': {
        pt: ' de ',
        es: ' desde ',
        ru: ' из ',
        fr: ' de ',
        de: ' von '
    },
    'Coupon: checkout today to save $10!': {
        pt: 'Cupom: finalize hoje para economizar $10!',
        es: 'Cupón: paga hoy para ahorrar $10!',
        ru: 'Купон: оформите заказ сегодня, чтобы сэкономить $10!',
        fr: 'Coupon : payez aujourd\'hui pour économiser 10 $!',
        de: 'Gutschein: Bezahlen Sie heute, um 10 $ zu sparen!'
    },
    'Remove Subscription': {
        pt: 'Remover Assinatura',
        es: 'Eliminar Suscripción',
        ru: 'Удалить подписку',
        fr: 'Supprimer l\'abonnement',
        de: 'Abonnement entfernen'
    },
    'Upgrade to TIDY+ & Save 20%': {
        pt: 'Atualizar para TIDY+ e Economizar 20%',
        es: 'Actualizar a TIDY+ y Ahorrar 20%',
        ru: 'Перейти на TIDY+ и сэкономить 20%',
        fr: 'Passer à TIDY+ et économiser 20%',
        de: 'Auf TIDY+ upgraden & 20% sparen'
    },
    'TIDY+ lets you save 20% booking per property per year, cancel anytime.': {
        pt: 'TIDY+ permite economizar 20% em reservas por propriedade por ano, cancele a qualquer momento.',
        es: 'TIDY+ te permite ahorrar 20% en reservas por propiedad por año, cancela en cualquier momento.',
        ru: 'TIDY+ позволяет экономить 20% на бронировании за каждую недвижимость в год, отмена в любое время.',
        fr: 'TIDY+ vous permet d\'économiser 20% sur les réservations par propriété par an, annulez à tout moment.',
        de: 'TIDY+ ermöglicht Ihnen, 20% pro Buchung pro Eigenschaft pro Jahr zu sparen, jederzeit kündbar.'
    },
    '$36 for 1 year TIDY+': {
        pt: '$36 por 1 ano de TIDY+',
        es: '$36 por 1 año de TIDY+',
        ru: '36$ за 1 год TIDY+',
        fr: '36$ pour 1 an de TIDY+',
        de: '36$ für 1 Jahr TIDY+'
    },
    'total today': {
        pt: 'total hoje',
        es: 'total hoy',
        ru: 'итого сегодня',
        fr: 'total aujourd\'hui',
        de: 'Gesamt heute'
    },
    'Remove TIDY+': {
        pt: 'Remover TIDY+',
        es: 'Eliminar TIDY+',
        ru: 'Удалить TIDY+',
        fr: 'Supprimer TIDY+',
        de: 'TIDY+ entfernen'
    },
    'Add TIDY+': {
        pt: 'Adicionar TIDY+',
        es: 'Añadir TIDY+',
        ru: 'Добавить TIDY+',
        fr: 'Ajouter TIDY+',
        de: 'TIDY+ hinzufügen'
    },
    'View Other Subscription Types': {
        pt: 'Ver Outros Tipos de Assinatura',
        es: 'Ver Otros Tipos de Suscripción',
        ru: 'Посмотреть другие типы подписок',
        fr: 'Voir d\'autres types d\'abonnements',
        de: 'Andere Abonnementarten ansehen'
    },
    'Show More Services': {
        pt: 'Mostrar Mais Serviços',
        es: 'Mostrar Más Servicios',
        ru: 'Показать больше услуг',
        fr: 'Afficher plus de services',
        de: 'Mehr Dienste anzeigen'
    },
    'Select First Time': {
        pt: 'Selecionar Primeira Vez',
        es: 'Seleccionar Primera Vez',
        ru: 'Выбрать первый раз',
        fr: 'Sélectionner la première fois',
        de: 'Erstes Mal auswählen'
    },
    'Select Time': {
        pt: 'Selecionar Horário',
        es: 'Seleccionar Hora',
        ru: 'Выбрать время',
        fr: 'Sélectionner l\'heure',
        de: 'Zeit wählen'
    },
    'Send them a request': {
        pt: 'Enviar-lhes um pedido',
        es: 'Enviarles una solicitud',
        ru: 'Отправить им запрос',
        fr: 'Envoyer une demande',
        de: 'Eine Anfrage senden'
    },
    'Don\'t see a time you like?': {
        pt: 'Não vê um horário que goste?',
        es: '¿No ves una hora que te guste?',
        ru: 'Не видите время, которое вам нравится?',
        fr: 'Vous ne voyez pas un horaire qui vous plaît?',
        de: 'Sie sehen keine Zeit, die Ihnen gefällt?'
    },
    'Request time': {
        pt: 'Solicitar horário',
        es: 'Solicitar hora',
        ru: 'Запросить время',
        fr: 'Demander l\'heure',
        de: 'Zeit anfordern'
    },
    'Fully Booked': {
        pt: 'Totalmente Reservado',
        es: 'Completamente Reservado',
        ru: 'Полностью забронировано',
        fr: 'Complètement réservé',
        de: 'Vollständig gebucht'
    },
    'No Times Available': {
        pt: 'Nenhum Horário Disponível',
        es: 'No Hay Horarios Disponibles',
        ru: 'Нет доступного времени',
        fr: 'Aucun créneau disponible',
        de: 'Keine Zeiten verfügbar'
    },
    'Request Job with': {
        pt: 'Solicitar Trabalho com',
        es: 'Solicitar Trabajo con',
        ru: 'Запросить работу с',
        fr: 'Demander un travail avec',
        de: 'Job anfordern mit'
    },
    'Unfortunately there is no availability in your area for this plan type.': {
        pt: 'Infelizmente, não há disponibilidade na sua área para este tipo de plano.',
        es: 'Desafortunadamente, no hay disponibilidad en tu área para este tipo de plan.',
        ru: 'К сожалению, в вашем районе нет доступности для этого типа плана.',
        fr: 'Malheureusement, il n\'y a pas de disponibilité dans votre région pour ce type de plan.',
        de: 'Leider gibt es in Ihrem Bereich keine Verfügbarkeit für diesen Plantyp.'
    },
    'Click here': {
        pt: 'Clique aqui',
        es: 'Haz clic aquí',
        ru: 'Нажмите здесь',
        fr: 'Cliquez ici',
        de: 'Klicken Sie hier'
    },
    'to view all of our availability.': {
        pt: 'para ver toda a nossa disponibilidade.',
        es: 'para ver toda nuestra disponibilidad.',
        ru: 'чтобы просмотреть всю нашу доступность.',
        fr: 'pour voir toute notre disponibilité.',
        de: 'um unsere gesamte Verfügbarkeit zu sehen.'
    },
    'Select Date': {
        pt: 'Selecionar Data',
        es: 'Seleccionar Fecha',
        ru: 'Выбрать дату',
        fr: 'Sélectionner la date',
        de: 'Datum wählen'
    },
    'to view all of availability.': {
        pt: 'para ver toda a disponibilidade.',
        es: 'para ver toda la disponibilidad.',
        ru: 'чтобы просмотреть всю доступность.',
        fr: 'pour voir toute la disponibilité.',
        de: 'um alle Verfügbarkeiten zu sehen.'
    },
    'Message Concierge': {
        pt: 'Mensagem do Concierge',
        es: 'Mensaje del Conserje',
        ru: 'Сообщение консьержа',
        fr: 'Message du concierge',
        de: 'Nachricht an den Concierge'
    },
    'Select service to see times': {
        pt: 'Selecione o serviço para ver os horários',
        es: 'Selecciona el servicio para ver los horarios',
        ru: 'Выберите услугу, чтобы увидеть время',
        fr: 'Sélectionnez le service pour voir les horaires',
        de: 'Wählen Sie den Dienst, um die Zeiten zu sehen'
    },
    'Select service & frequency to see times': {
        pt: 'Selecione o serviço e a frequência para ver os horários',
        es: 'Selecciona el servicio y la frecuencia para ver los horarios',
        ru: 'Выберите услугу и частоту, чтобы увидеть время',
        fr: 'Sélectionnez le service et la fréquence pour voir les horaires',
        de: 'Wählen Sie den Dienst und die Häufigkeit, um die Zeiten zu sehen'
    },
    'Select Date & Time': {
        pt: 'Selecionar Data e Horário',
        es: 'Seleccionar Fecha y Hora',
        ru: 'Выбрать дату и время',
        fr: 'Sélectionner la date et l\'heure',
        de: 'Datum und Zeit wählen'
    },
    'One Time (+25%)': {
        pt: 'Uma vez (+25%)',
        es: 'Una vez (+25%)',
        ru: 'Один раз (+25%)',
        fr: 'Une fois (+25%)',
        de: 'Einmal (+25%)'
    },
    'Every 4 Weeks': {
        pt: 'A cada 4 semanas',
        es: 'Cada 4 semanas',
        ru: 'Каждые 4 недели',
        fr: 'Toutes les 4 semaines',
        de: 'Alle 4 Wochen'
    },
    'Every 2 Weeks': {
        pt: 'A cada 2 semanas',
        es: 'Cada 2 semanas',
        ru: 'Каждые 2 недели',
        fr: 'Toutes les 2 semaines',
        de: 'Alle 2 Wochen'
    },
    'Weekly': {
        pt: 'Semanalmente',
        es: 'Semanalmente',
        ru: 'Еженедельно',
        fr: 'Hebdomadairement',
        de: 'Wöchentlich'
    },
    '2x Per Week': {
        pt: '2x por semana',
        es: '2x por semana',
        ru: '2 раза в неделю',
        fr: '2x par semaine',
        de: '2x pro Woche'
    },
    '3x Per Week': {
        pt: '3x por semana',
        es: '3x por semana',
        ru: '3 раза в неделю',
        fr: '3x par semaine',
        de: '3x pro Woche'
    },
    'Any Rating': {
        pt: 'Qualquer classificação',
        es: 'Cualquier calificación',
        ru: 'Любой рейтинг',
        fr: 'Toute notation',
        de: 'Jede Bewertung'
    },
    '1 Hour (2 Pros)': {
        pt: '1 Hora (2 Profissionais)',
        es: '1 Hora (2 Profesionales)',
        ru: '1 час (2 профессионала)',
        fr: '1 heure (2 pros)',
        de: '1 Stunde (2 Profis)'
    },
    '2.5 Hours (2 Pros)': {
        pt: '2,5 Horas (2 Profissionais)',
        es: '2,5 Horas (2 Profesionales)',
        ru: '2,5 часа (2 профессионала)',
        fr: '2,5 heures (2 pros)',
        de: '2,5 Stunden (2 Profis)'
    },
    '4 Hours (2 Pros)': {
        pt: '4 Horas (2 Profissionais)',
        es: '4 Horas (2 Profesionales)',
        ru: '4 часа (2 профессионала)',
        fr: '4 heures (2 pros)',
        de: '4 Stunden (2 Profis)'
    },
    'Select Second Time': {
        pt: 'Selecionar Segundo Horário',
        es: 'Seleccionar Segunda Hora',
        ru: 'Выбрать второе время',
        fr: 'Sélectionner le deuxième horaire',
        de: 'Zweite Zeit wählen'
    },
    'Select Third Time': {
        pt: 'Selecionar Terceiro Horário',
        es: 'Seleccionar Tercera Hora',
        ru: 'Выбрать третье время',
        fr: 'Sélectionner le troisième horaire',
        de: 'Dritte Zeit wählen'
    },
    'Issue Accessing Page': {
        pt: 'Problema ao acessar a página',
        es: 'Problema al acceder a la página',
        ru: 'Проблема с доступом к странице',
        fr: 'Problème d\'accès à la page',
        de: 'Problem beim Zugriff auf die Seite'
    },
    'Please try again from your Schedule and the issue will be resolved.  Thanks for your patience.': {
        pt: 'Por favor, tente novamente a partir de sua agenda e o problema será resolvido. Obrigado pela sua paciência.',
        es: 'Por favor, intente de nuevo desde su horario y el problema se resolverá. Gracias por su paciencia.',
        ru: 'Пожалуйста, попробуйте еще раз из вашего расписания, и проблема будет решена. Спасибо за ваше терпение.',
        fr: 'Veuillez réessayer depuis votre emploi du temps et le problème sera résolu. Merci de votre patience.',
        de: 'Bitte versuchen Sie es erneut aus Ihrem Zeitplan, und das Problem wird gelöst sein. Danke für Ihre Geduld.'
    },
    'Go to Schedule': {
        pt: 'Ir para Agenda',
        es: 'Ir al Horario',
        ru: 'Перейти к расписанию',
        fr: 'Aller au Calendrier',
        de: 'Zum Zeitplan gehen'
    },
    '$10 coupon applied!': {
        pt: 'Cupom de $10 aplicado!',
        es: '¡Cupón de $10 aplicado!',
        ru: 'Применен купон на $10!',
        fr: 'Coupon de 10 $ appliqué!',
        de: '10 $ Gutschein angewendet!'
    },
    'Upgrade to TIDY+ to save 20%': {
        pt: 'Faça upgrade para TIDY+ para economizar 20%',
        es: 'Actualice a TIDY+ para ahorrar 20%',
        ru: 'Перейдите на TIDY+, чтобы сэкономить 20%',
        fr: 'Passez à TIDY+ pour économiser 20%',
        de: 'Wechseln Sie zu TIDY+, um 20% zu sparen'
    },
    'Select a recurring plan to save 20%': {
        pt: 'Selecione um plano recorrente para economizar 20%',
        es: 'Seleccione un plan recurrente para ahorrar 20%',
        ru: 'Выберите повторяющийся план, чтобы сэкономить 20%',
        fr: 'Choisissez un plan récurrent pour économiser 20%',
        de: 'Wählen Sie einen wiederkehrenden Plan, um 20% zu sparen'
    },
    'Saving 20% with a recurring plan!': {
        pt: 'Economizando 20% com um plano recorrente!',
        es: '¡Ahorro del 20% con un plan recurrente!',
        ru: 'Экономия 20% с повторяющимся планом!',
        fr: 'Économisez 20% avec un plan récurrent!',
        de: 'Sparen Sie 20% mit einem wiederkehrenden Plan!'
    },
    'Booking Question': {
        pt: 'Questão de Reserva',
        es: 'Pregunta de Reserva',
        ru: 'Вопрос о бронировании',
        fr: 'Question de réservation',
        de: 'Buchungsfrage'
    },

    //ConfirmBookingPage
    'for job (save 20%)': {
        pt: 'para trabalho (economize 20%)',
        es: 'para trabajo (ahorre 20%)',
        ru: 'для работы (сэкономьте 20%)',
        fr: 'pour le travail (économisez 20%)',
        de: 'für die Arbeit (sparen Sie 20%)'
    },
    'Saving 20% with your subscription!': {
        pt: 'Economizando 20% com sua assinatura!',
        es: '¡Ahorro del 20% con su suscripción!',
        ru: 'Экономия 20% с вашей подпиской!',
        fr: 'Économisez 20% avec votre abonnement!',
        de: 'Sparen Sie 20% mit Ihrem Abonnement!'
    },
    'Confirm': {
        pt: 'Confirmar',
        es: 'Confirmar',
        ru: 'Подтвердить',
        fr: 'Confirmer',
        de: 'Bestätigen'
    },
    'Substitute Request': {
        pt: 'Pedido de Substituição',
        es: 'Solicitud de Sustitución',
        ru: 'Запрос на замену',
        fr: 'Demande de remplacement',
        de: 'Ersatzanfrage'
    },
    'Booking with': {
        pt: 'Reserva com',
        es: 'Reserva con',
        ru: 'Бронирование с',
        fr: 'Réservation avec',
        de: 'Buchung mit'
    },
    'from': {
        pt: 'de',
        es: 'de',
        ru: 'от',
        fr: 'de',
        de: 'von'
    },
    'Earliest Start:': {
        pt: 'Início Mais Cedo:',
        es: 'Inicio Más Temprano:',
        ru: 'Самое раннее начало:',
        fr: 'Début le plus tôt:',
        de: 'Frühester Beginn:'
    },
    'Latest Finish:': {
        pt: 'Término Mais Tarde:',
        es: 'Final Más Tardío:',
        ru: 'Последнее завершение:',
        fr: 'Fin la plus tardive:',
        de: 'Spätestes Ende:'
    },
    'Total:': {
        pt: 'Total:',
        es: 'Total:',
        ru: 'Итого:',
        fr: 'Total:',
        de: 'Gesamt:'
    },
    '*Saving 20% with recurring plan': {
        pt: '*Economizando 20% com plano recorrente',
        es: '*Ahorro del 20% con plan recurrente',
        ru: '*Экономия 20% с повторяющимся планом',
        fr: '*Économisez 20% avec un plan récurrent',
        de: '*Sparen Sie 20% mit einem wiederkehrenden Plan'
    },
    'for job (saving 20%)': {
        pt: 'para trabalho (economizando 20%)',
        es: 'para trabajo (ahorrando 20%)',
        ru: 'для работы (экономия 20%)',
        fr: 'pour le travail (économisant 20%)',
        de: 'für die Arbeit (20% sparen)'
    },
    'remove': {
        pt: 'remover',
        es: 'eliminar',
        ru: 'удалить',
        fr: 'supprimer',
        de: 'entfernen'
    },
    'Minimum': {
        pt: 'Mínimo',
        es: 'Mínimo',
        ru: 'Минимум',
        fr: 'Minimum',
        de: 'Minimum'
    },
    'Times Requested:': {
        pt: 'Vezes Solicitadas:',
        es: 'Veces Solicitadas:',
        ru: 'Запрошено раз:',
        fr: 'Fois demandé:',
        de: 'Mal angefordert:'
    },
    '<b>*Note:</b> You selected times today. If a Pro accepts a time today <u>the price will be +50%</u>.': {
        pt: '<b>*Nota:</b> Você selecionou horários hoje. Se um Profissional aceitar um horário hoje <u>o preço será +50%</u>.',
        es: '<b>*Nota:</b> Seleccionaste horarios hoy. Si un Profesional acepta un horario hoy <u>el precio será +50%</u>.',
        ru: '<b>*Примечание:</b> Вы выбрали время сегодня. Если Профессионал примет время сегодня <u>цена будет +50%</u>.',
        fr: '<b>*Note:</b> Vous avez sélectionné des horaires aujourd\'hui. Si un Pro accepte un horaire aujourd\'hui <u>le prix sera de +50%</u>.',
        de: '<b>*Hinweis:</b> Sie haben heute Zeiten ausgewählt. Wenn ein Pro heute eine Zeit akzeptiert <u>wird der Preis um 50% steigen</u>.'
    },
    'Go Back': {
        pt: 'Voltar',
        es: 'Regresar',
        ru: 'Вернуться',
        fr: 'Retour',
        de: 'Zurück'
    },
    'This pro has raised their rates. To book them directly, you would need to increase your price to': {
        pt: 'Este profissional aumentou suas tarifas. Para contratá-lo diretamente, você precisará aumentar seu preço para',
        es: 'Este profesional ha aumentado sus tarifas. Para contratarlo directamente, necesitarás aumentar tu precio a',
        ru: 'Этот профессионал повысил свои тарифы. Чтобы забронировать его напрямую, вам нужно будет повысить вашу цену до',
        fr: 'Ce pro a augmenté ses tarifs. Pour le réserver directement, vous devrez augmenter votre prix à',
        de: 'Dieser Profi hat seine Preise erhöht. Um ihn direkt zu buchen, müssten Sie Ihren Preis auf'
    },
    'Try for Another Pro at These Prices': {
        pt: 'Tente Outro Profissional com Estes Preços',
        es: 'Intenta con Otro Profesional a Estos Precios',
        ru: 'Попробуйте Другого Профессионала по Этим Ценам',
        fr: 'Essayez un Autre Pro à Ces Prix',
        de: 'Versuchen Sie es mit einem Anderen Pro zu Diesen Preisen'
    },
    'Unfortunately no other pro is available at this price. You need to raise your price to book this job.': {
        pt: 'Infelizmente, nenhum outro profissional está disponível por este preço. Você precisa aumentar seu preço para reservar este trabalho.',
        es: 'Desafortunadamente, ningún otro profesional está disponible a este precio. Necesitas aumentar tu precio para reservar este trabajo.',
        ru: 'К сожалению, ни один другой профессионал не доступен по этой цене. Вам нужно поднять вашу цену, чтобы забронировать эту работу.',
        fr: 'Malheureusement, aucun autre pro n\'est disponible à ce prix. Vous devez augmenter votre prix pour réserver ce travail.',
        de: 'Leider ist kein anderer Profi zu diesem Preis verfügbar. Sie müssen Ihren Preis erhöhen, um diesen Job zu buchen.'
    },
    'Request Pro': {
        pt: 'Solicitar Profissional',
        es: 'Solicitar Profesional',
        ru: 'Запросить Профессионала',
        fr: 'Demander un Pro',
        de: 'Pro Anfordern'
    },
    'Request Job': {
        pt: 'Solicitar Trabalho',
        es: 'Solicitar Trabajo',
        ru: 'Запросить Работу',
        fr: 'Demander un Travail',
        de: 'Job Anfordern'
    },
    'Book Now': {
        pt: 'Reservar Agora',
        es: 'Reservar Ahora',
        ru: 'Забронировать Сейчас',
        fr: 'Réserver Maintenant',
        de: 'Jetzt Buchen'
    },
    'We will notify you right away when a pro responds.': {
        pt: 'Nós o notificaremos imediatamente quando um profissional responder.',
        es: 'Te notificaremos de inmediato cuando un profesional responda.',
        ru: 'Мы немедленно уведомим вас, когда профессионал ответит.',
        fr: 'Nous vous notifierons immédiatement lorsqu\'un pro répondra.',
        de: 'Wir werden Sie sofort benachrichtigen, wenn ein Profi antwortet.'
    },
    'Job Requested': {
        pt: 'Trabalho Solicitado',
        es: 'Trabajo Solicitado',
        ru: 'Работа Запрошена',
        fr: 'Travail Demandé',
        de: 'Job Angefordert'
    },
    'Substitute Requested': {
        pt: 'Substituto Solicitado',
        es: 'Sustituto Solicitado',
        ru: 'Запрошен Заменитель',
        fr: 'Remplaçant Demandé',
        de: 'Ersatz Angefordert'
    },
    'We will notify you when they respond.': {
        pt: 'Nós o notificaremos quando eles responderem.',
        es: 'Te notificaremos cuando respondan.',
        ru: 'Мы уведомим вас, когда они ответят.',
        fr: 'Nous vous informerons lorsqu\'ils répondront.',
        de: 'Wir werden Sie informieren, wenn sie antworten.'
    },
    'Your card was declined again. Please try another card: ': {
        pt: 'Seu cartão foi recusado novamente. Por favor, tente outro cartão: ',
        es: 'Tu tarjeta fue rechazada nuevamente. Por favor, intenta con otra tarjeta: ',
        ru: 'Ваша карта снова отклонена. Пожалуйста, попробуйте другую карту: ',
        fr: 'Votre carte a été refusée à nouveau. Veuillez essayer une autre carte: ',
        de: 'Ihre Karte wurde erneut abgelehnt. Bitte versuchen Sie es mit einer anderen Karte: '
    },
    'Your card was declined. Please update your card here: ': {
        pt: 'Seu cartão foi recusado. Por favor, atualize seu cartão aqui: ',
        es: 'Tu tarjeta fue rechazada. Por favor, actualiza tu tarjeta aquí: ',
        ru: 'Ваша карта была отклонена. Пожалуйста, обновите вашу карту здесь: ',
        fr: 'Votre carte a été refusée. Veuillez mettre à jour votre carte ici: ',
        de: 'Ihre Karte wurde abgelehnt. Bitte aktualisieren Sie Ihre Karte hier: '
    },
    'Save & Book': {
        pt: 'Salvar e Reservar',
        es: 'Guardar y Reservar',
        ru: 'Сохранить и Забронировать',
        fr: 'Enregistrer et Réserver',
        de: 'Speichern & Buchen'
    },

    //ModalMoreTimesPage
    'Select Times': {
        pt: 'Selecionar Horários',
        es: 'Seleccionar Horarios',
        ru: 'Выбрать Время',
        fr: 'Sélectionner les Horaires',
        de: 'Zeiten Auswählen'
    },
    'Confirm Selected Times': {
        pt: 'Confirmar Horários Selecionados',
        es: 'Confirmar Horarios Seleccionados',
        ru: 'Подтвердить Выбранное Время',
        fr: 'Confirmer les Horaires Sélectionnés',
        de: 'Ausgewählte Zeiten Bestätigen'
    },

    //BookingNotePage
    'Job Note': {
        pt: 'Nota de Trabalho',
        es: 'Nota de Trabajo',
        ru: 'Заметка о Работе',
        fr: 'Note de Travail',
        de: 'Arbeitsnotiz'
    },
    'Use a job note to convey general notes for this job to your pro. You can use a job note instead of To-Dos or to supplement them.': {
        pt: 'Use uma nota de trabalho para transmitir notas gerais para este trabalho ao seu profissional. Você pode usar uma nota de trabalho em vez de tarefas ou para complementá-las.',
        es: 'Utiliza una nota de trabajo para transmitir notas generales para este trabajo a tu profesional. Puedes usar una nota de trabajo en lugar de tareas o para complementarlas.',
        ru: 'Используйте служебную записку, чтобы передать общие заметки по этой работе вашему профессионалу. Вы можете использовать служебную записку вместо задач или для их дополнения.',
        fr: 'Utilisez une note de travail pour transmettre des notes générales pour ce travail à votre professionnel. Vous pouvez utiliser une note de travail au lieu de tâches ou pour les compléter.',
        de: 'Verwenden Sie eine Arbeitsnotiz, um allgemeine Notizen für diese Arbeit an Ihren Profi anzuvermitteln. Sie können eine Arbeitsnotiz anstelle von To-Dos verwenden oder diese ergänzen.'
    },
    'Save Note': {
        pt: 'Salvar Nota',
        es: 'Guardar Nota',
        ru: 'Сохранить Заметку',
        fr: 'Enregistrer la Note',
        de: 'Notiz Speichern'
    },

    //BrowseProsPage
    'Powered by': {
        pt: 'Desenvolvido por',
        es: 'Impulsado por',
        ru: 'Работает на',
        fr: 'Propulsé par',
        de: 'Angetrieben von'
    },
    'No results found, please try another category.': {
        pt: 'Nenhum resultado encontrado, por favor tente outra categoria.',
        es: 'No se encontraron resultados, por favor intente otra categoría.',
        ru: 'Результаты не найдены, пожалуйста, попробуйте другую категорию.',
        fr: 'Aucun résultat trouvé, veuillez essayer une autre catégorie.',
        de: 'Keine Ergebnisse gefunden, bitte versuchen Sie eine andere Kategorie.'
    },

    //NextStepsModalComponent
    '1. Book this pro through our partner,': {
        pt: '1. Reserve este profissional através do nosso parceiro,',
        es: '1. Reserve a este profesional a través de nuestro socio,',
        ru: '1. Забронируйте этого профессионала через нашего партнера,',
        fr: '1. Réservez ce professionnel via notre partenaire,',
        de: '1. Buchen Sie diesen Profi über unseren Partner,'
    },
    'Next': {
        pt: 'Próximo',
        es: 'Siguiente',
        ru: 'Далее',
        fr: 'Suivant',
        de: 'Weiter'
    },
    '2. Message concierge with the job details, so we can track and follow up for you. Email concierge@tidy.com or in app.': {
        pt: '2. Envie uma mensagem ao concierge com os detalhes do trabalho, para que possamos acompanhar e dar seguimento para você. Envie um e-mail para concierge@tidy.com ou no aplicativo.',
        es: '2. Envíe un mensaje al conserje con los detalles del trabajo, para que podamos rastrear y hacer un seguimiento para usted. Envíe un correo electrónico a concierge@tidy.com o en la aplicación.',
        ru: '2. Отправьте сообщение консьержу с деталями работы, чтобы мы могли отслеживать и следить за вами. Отправьте электронное письмо на concierge@tidy.com или в приложении.',
        fr: '2. Envoyez un message au concierge avec les détails du travail, afin que nous puissions suivre et faire le suivi pour vous. Envoyez un e-mail à concierge@tidy.com ou dans l\'application.',
        de: '2. Nachrichten-Concierge mit den Jobdetails, damit wir die Nachverfolgung für Sie übernehmen können. E-Mail an concierge@tidy.com oder in der App.'
    },

    //ProCardComponent
    'reviews': {
        pt: 'avaliações',
        es: 'reseñas',
        ru: 'отзывы',
        fr: 'avis',
        de: 'Bewertungen'
    },
    'Show More': {
        pt: 'Mostrar mais',
        es: 'Mostrar más',
        ru: 'Показать больше',
        fr: 'Voir plus',
        de: 'Mehr anzeigen'
    },
    'Show Less': {
        pt: 'Mostrar menos',
        es: 'Mostrar menos',
        ru: 'Показать меньше',
        fr: 'Voir moins',
        de: 'Weniger anzeigen'
    },
    'Select & Continue': {
        pt: 'Selecionar e Continuar',
        es: 'Seleccionar y Continuar',
        ru: 'Выбрать и продолжить',
        fr: 'Sélectionner et continuer',
        de: 'Auswählen & Weiter'
    },
    'In high demand': {
        pt: 'Em alta demanda',
        es: 'De alta demanda',
        ru: 'Пользуется спросом',
        fr: 'Très demandé',
        de: 'Stark nachgefragt'
    },
    'Licensed pro': {
        pt: 'Profissional licenciado',
        es: 'Profesional licenciado',
        ru: 'Лицензированный профессионал',
        fr: 'Pro agréé',
        de: 'Lizenzierter Profi'
    },
    'Great value': {
        pt: 'Ótimo custo-benefício',
        es: 'Gran valor',
        ru: 'Отличное соотношение цены и качества',
        fr: 'Excellent rapport qualité-prix',
        de: 'Großartiges Preis-Leistungs-Verhältnis'
    },
    'Hired': {
        pt: 'Contratado',
        es: 'Contratado',
        ru: 'Нанят',
        fr: 'Engagé',
        de: 'Eingestellt'
    },
    'times': {
        pt: 'vezes',
        es: 'veces',
        ru: 'раз',
        fr: 'fois',
        de: 'mal'
    },
    'years in business': {
        pt: 'anos no negócio',
        es: 'años en el negocio',
        ru: 'лет в бизнесе',
        fr: 'années d\'activité',
        de: 'Jahre im Geschäft'
    },

    //CallProPage
    'Please use phone number on file': {
        pt: 'Use o número de telefone no arquivo',
        es: 'Utilice el número de teléfono en archivo',
        ru: 'Используйте номер телефона из файла',
        fr: 'Utilisez le numéro de téléphone enregistré',
        de: 'Verwenden Sie die Telefonnummer aus der Datei'
    },
    'You have no phone number on file. Please add a phone number to call your pro.': {
        pt: 'Você não tem um número de telefone no arquivo. Por favor, adicione um número de telefone para ligar para seu profissional.',
        es: 'No tiene un número de teléfono en archivo. Por favor, agregue un número de teléfono para llamar a su profesional.',
        ru: 'У вас нет номера телефона в файле. Пожалуйста, добавьте номер телефона, чтобы позвонить вашему профессионалу.',
        fr: 'Vous n\'avez pas de numéro de téléphone enregistré. Veuillez ajouter un numéro de téléphone pour appeler votre professionnel.',
        de: 'Sie haben keine Telefonnummer in der Datei. Bitte fügen Sie eine Telefonnummer hinzu, um Ihren Profi anzurufen.'
    },
    'Add Phone Number': {
        pt: 'Adicionar número de telefone',
        es: 'Agregar número de teléfono',
        ru: 'Добавить номер телефона',
        fr: 'Ajouter un numéro de téléphone',
        de: 'Telefonnummer hinzufügen'
    },

    //ClientGetStartedPage
    'Welcome to TIDY!': {
        pt: 'Bem-vindo ao TIDY!',
        es: '¡Bienvenido a TIDY!',
        ru: 'Добро пожаловать в TIDY!',
        fr: 'Bienvenue chez TIDY!',
        de: 'Willkommen bei TIDY!'
    },
    'credit': {
        pt: 'crédito',
        es: 'crédito',
        ru: 'кредит',
        fr: 'crédit',
        de: 'Kredit'
    },
    '. Add a pro to send them payment from your credit.': {
        pt: '. Adicione um profissional para enviar o pagamento do seu crédito.',
        es: '. Agregue un profesional para enviarles el pago desde su crédito.',
        ru: '. Добавьте профессионала, чтобы отправить ему платеж с вашего кредита.',
        fr: '. Ajoutez un professionnel pour lui envoyer un paiement depuis votre crédit.',
        de: '. Fügen Sie einen Profi hinzu, um ihm eine Zahlung von Ihrem Kredit zu senden.'
    },
    'TIDY works best with your own pros. If you don\'t have one, ask a neighbor or friend for a referral as a first step.': {
        pt: 'TIDY funciona melhor com seus próprios profissionais. Se você não tem um, peça a um vizinho ou amigo uma indicação como primeiro passo.',
        es: 'TIDY funciona mejor con tus propios profesionales. Si no tienes uno, pide a un vecino o amigo que te refiera como primer paso.',
        ru: 'TIDY лучше всего работает со своими профессионалами. Если у вас их нет, попросите соседа или друга порекомендовать вас как первый шаг.',
        fr: 'TIDY fonctionne mieux avec vos propres professionnels. Si vous n\'en avez pas, demandez à un voisin ou à un ami de vous recommander comme première étape.',
        de: 'TIDY funktioniert am besten mit Ihren eigenen Profis. Wenn Sie keinen haben, bitten Sie einen Nachbarn oder Freund um eine Empfehlung als ersten Schritt.'
    },
    '(recommended)': {
        pt: '(recomendado)',
        es: '(recomendado)',
        ru: '(рекомендуется)',
        fr: '(recommandé)',
        de: '(empfohlen)'
    },
    'Pros receive an email when you send a payment. For them it\'s similar to accepting credit card or Venmo.': {
        pt: 'Os profissionais recebem um e-mail quando você envia um pagamento. Para eles, é semelhante a aceitar cartão de crédito ou Venmo.',
        es: 'Los profesionales reciben un correo electrónico cuando envías un pago. Para ellos es similar a aceptar tarjeta de crédito o Venmo.',
        ru: 'Профессионалы получают электронное письмо, когда вы отправляете платеж. Для них это похоже на прием кредитных карт или Venmo.',
        fr: 'Les professionnels reçoivent un e-mail lorsque vous envoyez un paiement. Pour eux, c\'est similaire à accepter une carte de crédit ou Venmo.',
        de: 'Profis erhalten eine E-Mail, wenn Sie eine Zahlung senden. Für sie ist es ähnlich wie das Akzeptieren einer Kreditkarte oder Venmo.'
    },
    'Find New Pro': {
        pt: 'Encontrar Novo Profissional',
        es: 'Encontrar Nuevo Profesional',
        ru: 'Найти Нового Профессионала',
        fr: 'Trouver un Nouveau Professionnel',
        de: 'Neuen Profi finden'
    },
    'If you don\'t have anyone with a referral, use our network to find a pro to help you.': {
        pt: 'Se você não tem ninguém com uma indicação, use nossa rede para encontrar um profissional para ajudá-lo.',
        es: 'Si no tienes a nadie con una referencia, usa nuestra red para encontrar un profesional que te ayude.',
        ru: 'Если у вас нет никого с рекомендацией, используйте нашу сеть, чтобы найти профессионала, который вам поможет.',
        fr: 'Si vous n\'avez personne avec une recommandation, utilisez notre réseau pour trouver un professionnel pour vous aider.',
        de: 'Wenn Sie niemanden mit einer Empfehlung haben, nutzen Sie unser Netzwerk, um einen Profi zu finden, der Ihnen hilft.'
    },
    'View the App': {
        pt: 'Visualizar o Aplicativo',
        es: 'Ver la Aplicación',
        ru: 'Посмотреть Приложение',
        fr: 'Voir l\'Application',
        de: 'Die App ansehen'
    },
    'Setup To-Dos, get maintenance suggestions & more.': {
        pt: 'Configure as Tarefas, obtenha sugestões de manutenção e mais.',
        es: 'Configura las Tareas, obtén sugerencias de mantenimiento y más.',
        ru: 'Настройте задачи, получите рекомендации по обслуживанию и многое другое.',
        fr: 'Configurez les tâches à faire, obtenez des suggestions de maintenance et plus encore.',
        de: 'Richten Sie To-Dos ein, erhalten Sie Wartungsvorschläge und mehr.'
    },

    //ClientGetStartedProAddedPage
    'Do you want to send payment now or later?': {
        pt: 'Você quer enviar o pagamento agora ou depois?',
        es: '¿Quieres enviar el pago ahora o más tarde?',
        ru: 'Вы хотите отправить платеж сейчас или позже?',
        fr: 'Voulez-vous envoyer le paiement maintenant ou plus tard?',
        de: 'Möchten Sie die Zahlung jetzt oder später senden?'
    },
    'Send Payment': {
        pt: 'Enviar Pagamento',
        es: 'Enviar Pago',
        ru: 'Отправить Платеж',
        fr: 'Envoyer le Paiement',
        de: 'Zahlung Senden'
    },
    'We\'ll send an email to accept the payment.': {
        pt: 'Enviaremos um e-mail para aceitar o pagamento.',
        es: 'Enviaremos un correo electrónico para aceptar el pago.',
        ru: 'Мы отправим электронное письмо для подтверждения платежа.',
        fr: 'Nous enverrons un e-mail pour accepter le paiement.',
        de: 'Wir senden eine E-Mail, um die Zahlung zu akzeptieren.'
    },
    'Send Job Request': {
        pt: 'Enviar Pedido de Trabalho',
        es: 'Enviar Solicitud de Trabajo',
        ru: 'Отправить Запрос на Работу',
        fr: 'Envoyer une Demande de Travail',
        de: 'Arbeitsanfrage Senden'
    },
    'Send a job request so': {
        pt: 'Enviar um pedido de trabalho para que',
        es: 'Enviar una solicitud de trabajo para que',
        ru: 'Отправить запрос на работу, чтобы',
        fr: 'Envoyer une demande de travail afin que',
        de: 'Eine Arbeitsanfrage senden, damit'
    },
    'can accept and receive payment once it is completed.': {
        pt: 'possa aceitar e receber o pagamento assim que estiver concluído.',
        es: 'pueda aceptar y recibir el pago una vez que se complete.',
        ru: 'может принять и получить оплату после ее завершения.',
        fr: 'puisse accepter et recevoir le paiement une fois terminé.',
        de: 'die Zahlung annehmen und erhalten kann, sobald sie abgeschlossen ist.'
    },

    //ClientHubPage
    'Welcome': {
        pt: 'Bem-vindo',
        es: 'Bienvenido',
        ru: 'Добро пожаловать',
        fr: 'Bienvenue',
        de: 'Willkommen'
    },
    'Need help? Contact us:': {
        pt: 'Precisa de ajuda? Contate-nos:',
        es: '¿Necesita ayuda? Contáctenos:',
        ru: 'Нужна помощь? Свяжитесь с нами:',
        fr: 'Besoin d\'aide? Contactez-nous :',
        de: 'Brauchen Sie Hilfe? Kontaktieren Sie uns:'
    },
    'Owed Bills': {
        pt: 'Contas Devidas',
        es: 'Facturas Adeudadas',
        ru: 'Должные Счета',
        fr: 'Factures Dues',
        de: 'Geschuldete Rechnungen'
    },
    'Bill': {
        pt: 'Fatura',
        es: 'Factura',
        ru: 'Счет',
        fr: 'Facture',
        de: 'Rechnung'
    },
    'Job on': {
        pt: 'Trabalho em',
        es: 'Trabajo en',
        ru: 'Работа на',
        fr: 'Travail sur',
        de: 'Job an'
    },
    'Show all bills': {
        pt: 'Mostrar todas as contas',
        es: 'Mostrar todas las facturas',
        ru: 'Показать все счета',
        fr: 'Afficher toutes les factures',
        de: 'Alle Rechnungen anzeigen'
    },
    'Show less bills': {
        pt: 'Mostrar menos contas',
        es: 'Mostrar menos facturas',
        ru: 'Показать меньше счетов',
        fr: 'Afficher moins de factures',
        de: 'Weniger Rechnungen anzeigen'
    },
    'No owed bills': {
        pt: 'Sem contas devidas',
        es: 'Sin facturas adeudadas',
        ru: 'Нет должных счетов',
        fr: 'Pas de factures dues',
        de: 'Keine geschuldeten Rechnungen'
    },
    'Pending Proposals': {
        pt: 'Propostas Pendentes',
        es: 'Propuestas Pendientes',
        ru: 'Ожидающие Предложения',
        fr: 'Propositions en Attente',
        de: 'Ausstehende Vorschläge'
    },
    'Sent': {
        pt: 'Enviado',
        es: 'Enviado',
        ru: 'Отправлено',
        fr: 'Envoyé',
        de: 'Gesendet'
    },
    'Show all proposals': {
        pt: 'Mostrar todas as propostas',
        es: 'Mostrar todas las propuestas',
        ru: 'Показать все предложения',
        fr: 'Afficher toutes les propositions',
        de: 'Alle Vorschläge anzeigen'
    },
    'Show less proposals': {
        pt: 'Mostrar menos propostas',
        es: 'Mostrar menos propuestas',
        ru: 'Показать меньше предложений',
        fr: 'Afficher moins de propositions',
        de: 'Weniger Vorschläge anzeigen'
    },
    'No pending proposals': {
        pt: 'Sem propostas pendentes',
        es: 'Sin propuestas pendientes',
        ru: 'Нет ожидающих предложений',
        fr: 'Pas de propositions en attente',
        de: 'Keine ausstehenden Vorschläge'
    },
    'Accepted Proposals': {
        pt: 'Propostas Aceitas',
        es: 'Propuestas Aceptadas',
        ru: 'Принятые Предложения',
        fr: 'Propositions Acceptées',
        de: 'Angenommene Vorschläge'
    },
    'No accepted proposals': {
        pt: 'Sem propostas aceitas',
        es: 'Sin propuestas aceptadas',
        ru: 'Нет принятых предложений',
        fr: 'Pas de propositions acceptées',
        de: 'Keine angenommenen Vorschläge'
    },
    'Paid Bills': {
        pt: 'Contas Pagas',
        es: 'Facturas Pagadas',
        ru: 'Оплаченные Счета',
        fr: 'Factures Payées',
        de: 'Bezahlte Rechnungen'
    },
    'Receipt': {
        pt: 'Recibo',
        es: 'Recibo',
        ru: 'Квитанция',
        fr: 'Reçu',
        de: 'Quittung'
    },
    'No paid bills': {
        pt: 'Sem contas pagas',
        es: 'Sin facturas pagadas',
        ru: 'Нет оплаченных счетов',
        fr: 'Pas de factures payées',
        de: 'Keine bezahlten Rechnungen'
    },
    'Next Job': {
        pt: 'Próximo Trabalho',
        es: 'Próximo Trabajo',
        ru: 'Следующая Работа',
        fr: 'Prochain Travail',
        de: 'Nächster Job'
    },
    'Job Scheduled for': {
        pt: 'Trabalho Agendado para',
        es: 'Trabajo Programado para',
        ru: 'Работа Запланирована на',
        fr: 'Travail Prévu pour',
        de: 'Job Geplant für'
    },

    //OpenDisputePage
    'Open a dispute directly with': {
        pt: 'Abra uma disputa diretamente com',
        es: 'Abre una disputa directamente con',
        ru: 'Открыть спор непосредственно с',
        fr: 'Ouvrir un litige directement avec',
        de: 'Eröffnen Sie einen Streit direkt mit'
    },
    'for the job on': {
        pt: 'para o trabalho em',
        es: 'para el trabajo en',
        ru: 'за работу на',
        fr: 'pour le travail sur',
        de: 'für den Job am'
    },
    'at': {
        pt: 'em',
        es: 'en',
        ru: 'в',
        fr: 'à',
        de: 'um'
    },
    'will have 3 days to accept your dispute or propose another resolution.': {
        pt: 'terá 3 dias para aceitar sua disputa ou propor outra resolução.',
        es: 'tendrá 3 días para aceptar su disputa o proponer otra resolución.',
        ru: 'будет иметь 3 дня, чтобы принять ваш спор или предложить другое решение.',
        fr: 'aura 3 jours pour accepter votre litige ou proposer une autre résolution.',
        de: 'hat 3 Tage Zeit, um Ihren Streit anzunehmen oder einen anderen Lösungsvorschlag zu machen.'
    },
    'What Happened?': {
        pt: 'O que aconteceu?',
        es: '¿Qué pasó?',
        ru: 'Что произошло?',
        fr: 'Que s\'est-il passé?',
        de: 'Was ist passiert?'
    },
    'What % of the job cost do you want back?': {
        pt: 'Qual % do custo do trabalho você quer de volta?',
        es: '¿Qué % del costo del trabajo quieres recuperar?',
        ru: 'Какой % стоимости работы вы хотите вернуть?',
        fr: 'Quel % du coût du travail voulez-vous récupérer?',
        de: 'Welchen % der Kosten des Jobs möchten Sie zurück?'
    },
    'Please enter a message': {
        pt: 'Por favor, insira uma mensagem',
        es: 'Por favor, ingrese un mensaje',
        ru: 'Пожалуйста, введите сообщение',
        fr: 'Veuillez entrer un message',
        de: 'Bitte geben Sie eine Nachricht ein'
    },
    'Please enter how much you are seeking.': {
        pt: 'Por favor, insira quanto você está buscando.',
        es: 'Por favor, ingrese cuánto está buscando.',
        ru: 'Пожалуйста, укажите, сколько вы ищете.',
        fr: 'Veuillez indiquer combien vous recherchez.',
        de: 'Bitte geben Sie an, wie viel Sie suchen.'
    },
    'How do you want the money back?': {
        pt: 'Como você quer o dinheiro de volta?',
        es: '¿Cómo quieres que te devuelvan el dinero?',
        ru: 'Как вы хотите вернуть деньги?',
        fr: 'Comment voulez-vous récupérer l\'argent?',
        de: 'Wie möchten Sie das Geld zurück?'
    },
    'Credit': {
        pt: 'Crédito',
        es: 'Crédito',
        ru: 'Кредит',
        fr: 'Crédit',
        de: 'Kredit'
    },
    'Refund': {
        pt: 'Reembolso',
        es: 'Reembolso',
        ru: 'Возврат',
        fr: 'Remboursement',
        de: 'Rückerstattung'
    },
    'Open Job Dispute': {
        pt: 'Abrir Disputa de Trabalho',
        es: 'Abrir Disputa Laboral',
        ru: 'Открыть трудовой спор',
        fr: 'Ouvrir un Litige de Travail',
        de: 'Arbeitsstreitigkeit eröffnen'
    },
    'Dispute Tips:': {
        pt: 'Dicas de Disputa:',
        es: 'Consejos para Disputas:',
        ru: 'Советы по спорам:',
        fr: 'Conseils pour les Litiges:',
        de: 'Tipps für Streitigkeiten:'
    },
    'Try a partial resolution': {
        pt: 'Tente uma resolução parcial',
        es: 'Intenta una resolución parcial',
        ru: 'Попробуйте частичное решение',
        fr: 'Essayez une résolution partielle',
        de: 'Versuchen Sie eine teilweise Lösung'
    },
    'Seeking "all" or "nothing" is often is rejected by the other party.': {
        pt: 'Buscar "tudo" ou "nada" muitas vezes é rejeitado pela outra parte.',
        es: 'Buscar "todo" o "nada" a menudo es rechazado por la otra parte.',
        ru: 'Стремление к "всему" или "ничему" часто отклоняется другой стороной.',
        fr: 'Chercher "tout" ou "rien" est souvent rejeté par l\'autre partie.',
        de: 'Das Streben nach "allem" oder "nichts" wird oft von der anderen Partei abgelehnt.'
    },
    'Be polite': {
        pt: 'Seja educado',
        es: 'Sea cortés',
        ru: 'Будьте вежливы',
        fr: 'Soyez poli',
        de: 'Seien Sie höflich'
    },
    'Parties will be more likely to accept your resolution.': {
        pt: 'As partes terão mais probabilidade de aceitar sua resolução.',
        es: 'Las partes tendrán más probabilidades de aceptar su resolución.',
        ru: 'Стороны с большей вероятностью примут ваше решение.',
        fr: 'Les parties seront plus susceptibles d\'accepter votre résolution.',
        de: 'Die Parteien werden eher Ihre Lösung akzeptieren.'
    },
    'Dispute Opened': {
        pt: 'Disputa Aberta',
        es: 'Disputa Abierta',
        ru: 'Спор открыт',
        fr: 'Litige Ouvert',
        de: 'Streit eröffnet'
    },
    'The pro must accept your dispute or reply within 3 days.': {
        pt: 'O profissional deve aceitar sua disputa ou responder dentro de 3 dias.',
        es: 'El profesional debe aceptar su disputa o responder dentro de 3 días.',
        ru: 'Профессионал должен принять ваш спор или ответить в течение 3 дней.',
        fr: 'Le professionnel doit accepter votre litige ou répondre dans les 3 jours.',
        de: 'Der Profi muss Ihren Streit annehmen oder innerhalb von 3 Tagen antworten.'
    },

    //ConciergeFilterComponent
    'Search': {
        pt: 'Pesquisar',
        es: 'Buscar',
        ru: 'Поиск',
        fr: 'Recherche',
        de: 'Suche'
    },
    'Filter': {
        pt: 'Filtro',
        es: 'Filtrar',
        ru: 'Фильтр',
        fr: 'Filtre',
        de: 'Filter'
    },
    'Date': {
        pt: 'Data',
        es: 'Fecha',
        ru: 'Дата',
        fr: 'Date',
        de: 'Datum'
    },
    'Waiting on TIDY': {
        pt: 'Aguardando TIDY',
        es: 'Esperando a TIDY',
        ru: 'Ожидание TIDY',
        fr: 'En attente de TIDY',
        de: 'Warten auf TIDY'
    },
    'Waiting on Customer': {
        pt: 'Aguardando o Cliente',
        es: 'Esperando al Cliente',
        ru: 'Ожидание клиента',
        fr: 'En attente du client',
        de: 'Warten auf den Kunden'
    },

    //ConciergeItemsComponent
    'Support Requests': {
        pt: 'Pedidos de Suporte',
        es: 'Solicitudes de Soporte',
        ru: 'Запросы на поддержку',
        fr: 'Demandes de support',
        de: 'Supportanfragen'
    },
    'Add Request': {
        pt: 'Adicionar Pedido',
        es: 'Agregar Solicitud',
        ru: 'Добавить запрос',
        fr: 'Ajouter une demande',
        de: 'Anfrage hinzufügen'
    },
    'Add Action': {
        pt: 'Adicionar Ação',
        es: 'Agregar Acción',
        ru: 'Добавить действие',
        fr: 'Ajouter une action',
        de: 'Aktion hinzufügen'
    },
    'Concierge Actions are where you can specify anything you need from TIDY. Our AI and concierge teams will figure out how to get it done for you. We accept anything relating to coordinate cleaning and maintenance at your properties.': {
        pt: 'Ações de Concierge são onde você pode especificar tudo o que precisa da TIDY. Nossas equipes de IA e concierge descobrirão como fazer isso por você. Aceitamos qualquer coisa relacionada à coordenação de limpeza e manutenção em suas propriedades.',
        es: 'Las Acciones de Conserjería son donde puedes especificar todo lo que necesitas de TIDY. Nuestros equipos de IA y conserjería averiguarán cómo hacerlo por ti. Aceptamos cualquier cosa relacionada con la coordinación de limpieza y mantenimiento en tus propiedades.',
        ru: 'Действия консьержа - это то место, где вы можете указать все, что вам нужно от TIDY. Наши команды ИИ и консьержей выяснят, как это сделать для вас. Мы принимаем все, что связано с координацией уборки и обслуживания ваших объектов.',
        fr: 'Les actions de conciergerie sont l\'endroit où vous pouvez spécifier tout ce dont vous avez besoin de TIDY. Nos équipes d\'IA et de conciergerie trouveront comment le faire pour vous. Nous acceptons tout ce qui concerne la coordination du nettoyage et de la maintenance de vos propriétés.',
        de: 'Concierge-Aktionen sind der Ort, an dem Sie alles angeben können, was Sie von TIDY benötigen. Unsere KI- und Concierge-Teams werden herausfinden, wie sie es für Sie erledigen können. Wir akzeptieren alles, was mit der Koordination von Reinigung und Wartung Ihrer Immobilien zu tun hat.'
    },
    'We expect to reply within 2 business hours. We will reply both in the app and to your email': {
        pt: 'Esperamos responder dentro de 2 horas úteis. Responderemos tanto no aplicativo quanto no seu e-mail',
        es: 'Esperamos responder dentro de 2 horas hábiles. Responderemos tanto en la aplicación como en tu correo electrónico',
        ru: 'Мы ожидаем ответить в течение 2 рабочих часов. Мы ответим как в приложении, так и на ваш электронный адрес',
        fr: 'Nous prévoyons de répondre dans les 2 heures ouvrables. Nous répondrons à la fois dans l\'application et à votre e-mail',
        de: 'Wir erwarten, innerhalb von 2 Geschäftsstunden zu antworten. Wir werden sowohl in der App als auch per E-Mail antworten'
    },
    'Subject': {
        pt: 'Assunto',
        es: 'Asunto',
        ru: 'Тема',
        fr: 'Sujet',
        de: 'Betreff'
    },
    'Description': {
        pt: 'Descrição',
        es: 'Descripción',
        ru: 'Описание',
        fr: 'Description',
        de: 'Beschreibung'
    },
    'Add Attachment': {
        pt: 'Adicionar Anexo',
        es: 'Agregar Adjunto',
        ru: 'Добавить вложение',
        fr: 'Ajouter une pièce jointe',
        de: 'Anhang hinzufügen'
    },
    'Save': {
        pt: 'Salvar',
        es: 'Guardar',
        ru: 'Сохранить',
        fr: 'Enregistrer',
        de: 'Speichern'
    },
    '<i>Waiting on TIDY</i>': {
        pt: '<i>Aguardando TIDY</i>',
        es: '<i>Esperando a TIDY</i>',
        ru: '<i>Ожидание TIDY</i>',
        fr: '<i>En attente de TIDY</i>',
        de: '<i>Warten auf TIDY</i>'
    },
    '<b><i>Waiting on You</i></b>': {
        pt: '<b><i>Aguardando Você</i></b>',
        es: '<b><i>Esperando por Ti</i></b>',
        ru: '<b><i>Ждем Вас</i></b>',
        fr: '<b><i>En attente de Vous</i></b>',
        de: '<b><i>Warten auf Sie</i></b>'
    },
    'We expect to reply within': {
        pt: 'Esperamos responder dentro de',
        es: 'Esperamos responder dentro de',
        ru: 'Мы ожидаем ответить в течение',
        fr: 'Nous prévoyons de répondre dans',
        de: 'Wir erwarten, innerhalb zu antworten'
    },
    'Our concierge will complete the task or reach out with any questions.': {
        pt: 'Nosso concierge completará a tarefa ou entrará em contato com quaisquer perguntas.',
        es: 'Nuestro conserje completará la tarea o se comunicará con cualquier pregunta.',
        ru: 'Наш консьерж выполнит задачу или свяжется с вами, если у него будут вопросы.',
        fr: 'Notre concierge accomplira la tâche ou vous contactera pour toute question.',
        de: 'Unser Concierge wird die Aufgabe abschließen oder sich bei Fragen melden.'
    },
    'Reply': {
        pt: 'Responder',
        es: 'Responder',
        ru: 'Ответить',
        fr: 'Répondre',
        de: 'Antworten'
    },
    'You': {
        pt: 'Você',
        es: 'Tú',
        ru: 'Вы',
        fr: 'Vous',
        de: 'Sie'
    },
    'TIDY Concierge': {
        pt: 'Concierge TIDY',
        es: 'Conserje TIDY',
        ru: 'Консьерж TIDY',
        fr: 'Concierge TIDY',
        de: 'TIDY Concierge'
    },
    'Attachments:': {
        pt: 'Anexos:',
        es: 'Adjuntos:',
        ru: 'Вложения:',
        fr: 'Pièces jointes:',
        de: 'Anhänge:'
    },
    'Send Reply': {
        pt: 'Enviar Resposta',
        es: 'Enviar Respuesta',
        ru: 'Отправить ответ',
        fr: 'Envoyer la réponse',
        de: 'Antwort senden'
    },
    'How to Use TIDY': {
        pt: 'Como Usar TIDY',
        es: 'Cómo Usar TIDY',
        ru: 'Как использовать TIDY',
        fr: 'Comment Utiliser TIDY',
        de: 'Wie man TIDY benutzt'
    },
    'Ask a question about TIDY.': {
        pt: 'Faça uma pergunta sobre TIDY.',
        es: 'Haz una pregunta sobre TIDY.',
        ru: 'Задайте вопрос о TIDY.',
        fr: 'Posez une question sur TIDY.',
        de: 'Stellen Sie eine Frage zu TIDY.'
    },
    'Issue with a Job': {
        pt: 'Problema com um Trabalho',
        es: 'Problema con un Trabajo',
        ru: 'Проблема с работой',
        fr: 'Problème avec un Travail',
        de: 'Problem mit einem Job'
    },
    'Notify us of an issue with a job/pro.': {
        pt: 'Notifique-nos sobre um problema com um trabalho/profissional.',
        es: 'Notifícanos de un problema con un trabajo/profesional.',
        ru: 'Сообщите нам о проблеме с работой/профессионалом.',
        fr: 'Informez-nous d\'un problème avec un travail/professionnel.',
        de: 'Benachrichtigen Sie uns über ein Problem mit einem Job/Profi.'
    },
    'Issue with Billing': {
        pt: 'Problema com Cobrança',
        es: 'Problema con la Facturación',
        ru: 'Проблема с биллингом',
        fr: 'Problème de Facturation',
        de: 'Problem mit der Abrechnung'
    },
    'Ask us a question about charges or credits.': {
        pt: 'Faça-nos uma pergunta sobre cobranças ou créditos.',
        es: 'Haznos una pregunta sobre cargos o créditos.',
        ru: 'Задайте нам вопрос о начислениях или кредитах.',
        fr: 'Posez-nous une question sur les charges ou les crédits.',
        de: 'Stellen Sie uns eine Frage zu Gebühren oder Gutschriften.'
    },
    'Other': {
        pt: 'Outro',
        es: 'Otro',
        ru: 'Другое',
        fr: 'Autre',
        de: 'Andere'
    },
    'Note: The slowest option': {
        pt: 'Nota: A opção mais lenta',
        es: 'Nota: La opción más lenta',
        ru: 'Примечание: Самый медленный вариант',
        fr: 'Note: L\'option la plus lente',
        de: 'Hinweis: Die langsamste Option'
    },
    'Which job did you have an issue with?': {
        pt: 'Com qual trabalho você teve um problema?',
        es: '¿Con qué trabajo tuviste un problema?',
        ru: 'С какой работой у вас возникли проблемы?',
        fr: 'Avec quel travail avez-vous eu un problème?',
        de: 'Bei welchem Job hatten Sie ein Problem?'
    },
    'Not Listed': {
        pt: 'Não Listado',
        es: 'No Listado',
        ru: 'Не указано',
        fr: 'Non Répertorié',
        de: 'Nicht aufgeführt'
    },
    'Show more jobs': {
        pt: 'Mostrar mais trabalhos',
        es: 'Mostrar más trabajos',
        ru: 'Показать больше работ',
        fr: 'Afficher plus de travaux',
        de: 'Mehr Jobs anzeigen'
    },
    'Show less jobs': {
        pt: 'Mostrar menos trabalhos',
        es: 'Mostrar menos trabajos',
        ru: 'Показать меньше работ',
        fr: 'Afficher moins de travaux',
        de: 'Weniger Jobs anzeigen'
    },
    'This job on ': {
        pt: 'Este trabalho em ',
        es: 'Este trabajo en ',
        ru: 'Эта работа на ',
        fr: 'Ce travail sur ',
        de: 'Dieser Job am '
    },
    'is marked as ': {
        pt: 'está marcado como ',
        es: 'está marcado como ',
        ru: 'отмечен как ',
        fr: 'est marqué comme ',
        de: 'ist markiert als '
    },
    '. What is your issue?': {
        pt: '. Qual é o seu problema?',
        es: '. ¿Cuál es tu problema?',
        ru: '. В чем ваша проблема?',
        fr: '. Quel est votre problème?',
        de: '. Was ist Ihr Problem?'
    },
    'The service quality was bad': {
        pt: 'A qualidade do serviço foi ruim',
        es: 'La calidad del servicio fue mala',
        ru: 'Качество услуг было плохим',
        fr: 'La qualité du service était mauvaise',
        de: 'Die Servicequalität war schlecht'
    },
    'The pro never showed up': {
        pt: 'O profissional nunca apareceu',
        es: 'El profesional nunca apareció',
        ru: 'Профессионал так и не пришел',
        fr: 'Le professionnel ne s\'est jamais présenté',
        de: 'Der Profi ist nie erschienen'
    },
    'Something is missing': {
        pt: 'Está faltando algo',
        es: 'Falta algo',
        ru: 'Чего-то не хватает',
        fr: 'Il manque quelque chose',
        de: 'Es fehlt etwas'
    },
    'Something is damaged': {
        pt: 'Algo está danificado',
        es: 'Algo está dañado',
        ru: 'Что-то повреждено',
        fr: 'Quelque chose est endommagé',
        de: 'Etwas ist beschädigt'
    },
    'I want to leave a tip': {
        pt: 'Eu quero deixar uma gorjeta',
        es: 'Quiero dejar una propina',
        ru: 'Я хочу оставить чаевые',
        fr: 'Je veux laisser un pourboire',
        de: 'Ich möchte ein Trinkgeld hinterlassen'
    },
    'Other service issue (slowest)': {
        pt: 'Outro problema de serviço (o mais lento)',
        es: 'Otro problema de servicio (el más lento)',
        ru: 'Другая проблема с сервисом (самая медленная)',
        fr: 'Autre problème de service (le plus lent)',
        de: 'Anderes Serviceproblem (das langsamste)'
    },
    'Billing Issue': {
        pt: 'Problema de Cobrança',
        es: 'Problema de Facturación',
        ru: 'Проблема с биллингом',
        fr: 'Problème de Facturation',
        de: 'Abrechnungsproblem'
    },
    'We are happy to help with any billing issues. Please tap “Need help?” on the billing item you have questions on, and we typically help within 4 business hours.': {
        pt: 'Ficamos felizes em ajudar com qualquer problema de cobrança. Por favor, toque em "Precisa de ajuda?" no item de cobrança sobre o qual você tem dúvidas, e normalmente ajudamos dentro de 4 horas úteis.',
        es: 'Estamos encantados de ayudar con cualquier problema de facturación. Por favor, toca "¿Necesitas ayuda?" en el elemento de facturación sobre el que tienes preguntas, y normalmente ayudamos dentro de 4 horas hábiles.',
        ru: 'Мы рады помочь с любыми вопросами по биллингу. Пожалуйста, нажмите "Нужна помощь?" на пункте биллинга, по которому у вас есть вопросы, и мы обычно помогаем в течение 4 рабочих часов.',
        fr: 'Nous sommes heureux de vous aider avec tout problème de facturation. Veuillez appuyer sur "Besoin d\'aide?" sur l\'élément de facturation sur lequel vous avez des questions, et nous aidons généralement dans les 4 heures ouvrables.',
        de: 'Wir helfen gerne bei allen Abrechnungsproblemen. Bitte tippen Sie auf "Hilfe benötigt?" beim Abrechnungsposten, zu dem Sie Fragen haben, und wir helfen in der Regel innerhalb von 4 Geschäftsstunden.'
    },
    'View Billing History': {
        pt: 'Visualizar Histórico de Cobrança',
        es: 'Ver Historial de Facturación',
        ru: 'Просмотреть историю биллинга',
        fr: 'Voir l\'Historique de Facturation',
        de: 'Abrechnungsverlauf anzeigen'
    },
    'Last Updated': {
        pt: 'Última Atualização',
        es: 'Última Actualización',
        ru: 'Последнее обновление',
        fr: 'Dernière Mise à Jour',
        de: 'Letzte Aktualisierung'
    },
    'Edit Rule': {
        pt: 'Editar Regra',
        es: 'Editar Regla',
        ru: 'Изменить правило',
        fr: 'Modifier la Règle',
        de: 'Regel bearbeiten'
    },
    'Action added': {
        pt: 'Ação adicionada',
        es: 'Acción añadida',
        ru: 'Действие добавлено',
        fr: 'Action ajoutée',
        de: 'Aktion hinzugefügt'
    },
    'Support requested': {
        pt: 'Suporte solicitado',
        es: 'Soporte solicitado',
        ru: 'Запрошена поддержка',
        fr: 'Support demandé',
        de: 'Support angefordert'
    },
    'Other Question': {
        pt: 'Outra Pergunta',
        es: 'Otra Pregunta',
        ru: 'Другой вопрос',
        fr: 'Autre Question',
        de: 'Weitere Frage'
    },
    'Job Issue': {
        pt: 'Problema de Trabalho',
        es: 'Problema de Trabajo',
        ru: 'Проблема с работой',
        fr: 'Problème de Travail',
        de: 'Jobproblem'
    },
    'Developer Question': {
        pt: 'Pergunta do Desenvolvedor',
        es: 'Pregunta del Desarrollador',
        ru: 'Вопрос разработчика',
        fr: 'Question du Développeur',
        de: 'Entwicklerfrage'
    },
    'Marked Completed': {
        pt: 'Marcado como Concluído',
        es: 'Marcado como Completado',
        ru: 'Отмечено как завершенное',
        fr: 'Marqué Comme Complété',
        de: 'Als Abgeschlossen Markiert'
    },
    'Concierge Action': {
        pt: 'Ação de Concierge',
        es: 'Acción de Conserje',
        ru: 'Действие консьержа',
        fr: 'Action de Concierge',
        de: 'Concierge-Aktion'
    },

    //ConciergeReplyPage
    'Reply to Concierge': {
        pt: 'Responder ao Concierge',
        es: 'Responder al Conserje',
        ru: 'Ответить консьержу',
        fr: 'Répondre au Concierge',
        de: 'Antwort an den Concierge'
    },
    '<i>Scheduled to do immediately</i>': {
        pt: '<i>Agendado para fazer imediatamente</i>',
        es: '<i>Programado para hacer inmediatamente</i>',
        ru: '<i>Запланировано сделать немедленно</i>',
        fr: '<i>Prévu pour faire immédiatement</i>',
        de: '<i>Sofort geplant</i>'
    },
    '<i>Scheduled to do on': {
        pt: '<i>Agendado para fazer em',
        es: '<i>Programado para hacer en',
        ru: '<i>Запланировано сделать на',
        fr: '<i>Prévu pour faire le',
        de: '<i>Geplant zu tun am'
    },

    //ConciergeMessagePage
    'You reported this issue on your': {
        pt: 'Você relatou este problema no seu',
        es: 'Reportaste este problema en tu',
        ru: 'Вы сообщили об этой проблеме на вашем',
        fr: 'Vous avez signalé ce problème sur votre',
        de: 'Sie haben dieses Problem bei Ihrem gemeldet'
    },
    'job.': {
        pt: 'trabalho.',
        es: 'trabajo.',
        ru: 'работе.',
        fr: 'emploi.',
        de: 'Job.'
    },
    'Tap here': {
        pt: 'Toque aqui',
        es: 'Toca aquí',
        ru: 'Нажмите здесь',
        fr: 'Tapez ici',
        de: 'Tippen Sie hier'
    },
    'for job details.': {
        pt: 'para detalhes do trabalho.',
        es: 'para detalles del trabajo.',
        ru: 'для подробностей о работе.',
        fr: 'pour les détails du travail.',
        de: 'für Jobdetails.'
    },

    //ConciergeRuleComponent
    'Rule': {
        pt: 'Regra',
        es: 'Regla',
        ru: 'Правило',
        fr: 'Règle',
        de: 'Regel'
    },
    'Save Change': {
        pt: 'Salvar Alteração',
        es: 'Guardar Cambio',
        ru: 'Сохранить изменение',
        fr: 'Sauvegarder les modifications',
        de: 'Änderung speichern'
    },
    'Add Rule': {
        pt: 'Adicionar Regra',
        es: 'Añadir Regla',
        ru: 'Добавить правило',
        fr: 'Ajouter une règle',
        de: 'Regel hinzufügen'
    },
    'Delete Rule': {
        pt: 'Excluir Regra',
        es: 'Eliminar Regla',
        ru: 'Удалить правило',
        fr: 'Supprimer la règle',
        de: 'Regel löschen'
    },
    'Rule updated': {
        pt: 'Regra atualizada',
        es: 'Regla actualizada',
        ru: 'Правило обновлено',
        fr: 'Règle mise à jour',
        de: 'Regel aktualisiert'
    },
    'Rule added': {
        pt: 'Regra adicionada',
        es: 'Regla añadida',
        ru: 'Правило добавлено',
        fr: 'Règle ajoutée',
        de: 'Regel hinzugefügt'
    },
    'Delete Rule?': {
        pt: 'Excluir Regra?',
        es: '¿Eliminar Regla?',
        ru: 'Удалить правило?',
        fr: 'Supprimer la règle?',
        de: 'Regel löschen?'
    },
    'Rule deleted': {
        pt: 'Regra excluída',
        es: 'Regla eliminada',
        ru: 'Правило удалено',
        fr: 'Règle supprimée',
        de: 'Regel gelöscht'
    },

    //ConciergeRulesComponent
    'Edit': {
        pt: 'Editar',
        es: 'Editar',
        ru: 'Редактировать',
        fr: 'Modifier',
        de: 'Bearbeiten'
    },
    'Dismiss': {
        pt: 'Dispensar',
        es: 'Descartar',
        ru: 'Отклонить',
        fr: 'Rejeter',
        de: 'Verwerfen'
    },
    'None': {
        pt: 'Nenhum',
        es: 'Ninguno',
        ru: 'Никто',
        fr: 'Aucun',
        de: 'Keiner'
    },
    'Suggestion dismissed': {
        pt: 'Sugestão dispensada',
        es: 'Sugerencia descartada',
        ru: 'Предложение отклонено',
        fr: 'Suggestion rejetée',
        de: 'Vorschlag verworfen'
    },

    //ContactConciergePage
    'Let us know:': {
        pt: 'Informe-nos:',
        es: 'Háganos saber:',
        ru: 'Сообщите нам:',
        fr: 'Faites-nous savoir :',
        de: 'Lassen Sie uns wissen:'
    },
    '<li>Contact info of pro(s) (name, email, phone, etc)</li>': {
        pt: '<li>Informações de contato do(s) profissional(is) (nome, e-mail, telefone, etc)</li>',
        es: '<li>Información de contacto de los profesionales (nombre, correo electrónico, teléfono, etc.)</li>',
        ru: '<li>Контактная информация профессионала(ов) (имя, электронная почта, телефон и т. д.)</li>',
        fr: '<li>Coordonnées du ou des professionnels (nom, e-mail, téléphone, etc.)</li>',
        de: '<li>Kontaktinformationen des/der Fachmanns/Fachleute (Name, E-Mail, Telefon usw.)</li>'
    },
    '<li>Which properties/services to add to</li>': {
        pt: '<li>Quais propriedades/serviços adicionar</li>',
        es: '<li>A qué propiedades/servicios agregar</li>',
        ru: '<li>К каким объектам/услугам добавить</li>',
        fr: '<li>À quelles propriétés/services ajouter</li>',
        de: '<li>Zu welchen Eigenschaften/Diensten hinzufügen</li>'
    },
    '<li>Anything else we should know!</li>': {
        pt: '<li>Algo mais que devemos saber!</li>',
        es: '<li>¡Algo más que debemos saber!</li>',
        ru: '<li>Что-нибудь еще, что мы должны знать!</li>',
        fr: '<li>Autre chose que nous devrions savoir !</li>',
        de: '<li>Alles andere, was wir wissen sollten!</li>'
    },
    'Send us a list of all your current inventory, either with current stock levels or not. We will load it for you, typically within 1 business day. We email you when done!': {
        pt: 'Envie-nos uma lista de todo o seu inventário atual, com ou sem os níveis de estoque atuais. Carregaremos para você, geralmente dentro de 1 dia útil. Enviaremos um e-mail quando terminar!',
        es: 'Envíenos una lista de todo su inventario actual, ya sea con niveles de stock actuales o no. Lo cargaremos por usted, típicamente dentro de 1 día hábil. ¡Le enviaremos un correo electrónico cuando esté hecho!',
        ru: 'Присылайте нам список всего вашего текущего инвентаря, с текущими уровнями запасов или без. Мы загрузим его для вас, обычно в течение 1 рабочего дня. Мы отправим вам электронное письмо, когда все будет готово!',
        fr: 'Envoyez-nous une liste de tout votre inventaire actuel, avec ou sans les niveaux de stock actuels. Nous le chargerons pour vous, généralement dans un délai d\'un jour ouvrable. Nous vous envoyons un e-mail lorsque c\'est fait !',
        de: 'Senden Sie uns eine Liste Ihres gesamten aktuellen Inventars, mit oder ohne aktuelle Lagerbestände. Wir laden es für Sie, in der Regel innerhalb von 1 Arbeitstag. Wir senden Ihnen eine E-Mail, wenn es fertig ist!'
    },
    'Send us your current checklists and instructions. We will load it for you, typically within 1 business day. We email you when done! Edit as needed from there.': {
        pt: 'Envie-nos suas listas de verificação e instruções atuais. Carregaremos para você, geralmente dentro de 1 dia útil. Enviaremos um e-mail quando terminar! Edite conforme necessário a partir daí.',
        es: 'Envíenos sus listas de verificación e instrucciones actuales. Lo cargaremos por usted, típicamente dentro de 1 día hábil. ¡Le enviaremos un correo electrónico cuando esté hecho! Edite según sea necesario desde allí.',
        ru: 'Присылайте нам ваши текущие списки проверок и инструкции. Мы загрузим их для вас, обычно в течение 1 рабочего дня. Мы отправим вам электронное письмо, когда все будет готово! Редактируйте по мере необходимости.',
        fr: 'Envoyez-nous vos listes de contrôle et instructions actuelles. Nous les chargerons pour vous, généralement dans un délai d\'un jour ouvrable. Nous vous envoyons un e-mail lorsque c\'est fait ! Modifiez selon les besoins à partir de là.',
        de: 'Senden Sie uns Ihre aktuellen Checklisten und Anweisungen. Wir laden sie für Sie, in der Regel innerhalb von 1 Arbeitstag. Wir senden Ihnen eine E-Mail, wenn es fertig ist! Bearbeiten Sie nach Bedarf von dort aus.'
    },
    'Tasks are where you can specify anything you need from TIDY. Our AI and concierge teams will figure out how to get it done for you. We accept anything relating to coordinate cleaning and maintenance at your properties.': {
        pt: 'Tarefas são onde você pode especificar tudo o que precisa da TIDY. Nossas equipes de IA e concierge descobrirão como fazer isso por você. Aceitamos qualquer coisa relacionada à coordenação de limpeza e manutenção em suas propriedades.',
        es: 'Las tareas son donde puede especificar todo lo que necesita de TIDY. Nuestros equipos de IA y conserjería averiguarán cómo hacerlo por usted. Aceptamos cualquier cosa relacionada con la coordinación de limpieza y mantenimiento en sus propiedades.',
        ru: 'Задачи - это то место, где вы можете указать все, что вам нужно от TIDY. Наши команды ИИ и консьержей выяснят, как это сделать для вас. Мы принимаем все, что связано с координацией уборки и обслуживания на ваших объектах.',
        fr: 'Les tâches sont l\'endroit où vous pouvez spécifier tout ce dont vous avez besoin de TIDY. Nos équipes d\'IA et de conciergerie trouveront comment le faire pour vous. Nous acceptons tout ce qui concerne la coordination du nettoyage et de l\'entretien de vos propriétés.',
        de: 'Aufgaben sind der Ort, an dem Sie alles angeben können, was Sie von TIDY benötigen. Unsere KI- und Concierge-Teams werden herausfinden, wie sie es für Sie erledigen können. Wir akzeptieren alles, was mit der Koordination von Reinigung und Wartung Ihrer Immobilien zu tun hat.'
    },
    'Can you add this to-do list to my account?': {
        pt: 'Você pode adicionar esta lista de tarefas à minha conta?',
        es: '¿Puede agregar esta lista de tareas a mi cuenta?',
        ru: 'Можете ли вы добавить этот список дел в мой аккаунт?',
        fr: 'Pouvez-vous ajouter cette liste de tâches à mon compte?',
        de: 'Können Sie diese To-Do-Liste zu meinem Konto hinzufügen?'
    },
    'Can you set up 3 vendors to come and clean the carpets, complete a regular cleaning, and complete an inspection?': {
        pt: 'Você pode configurar 3 fornecedores para vir e limpar os tapetes, realizar uma limpeza regular e realizar uma inspeção?',
        es: '¿Puede organizar 3 proveedores para que vengan y limpien las alfombras, realicen una limpieza regular y completen una inspección?',
        ru: 'Можете ли вы организовать 3 поставщиков для чистки ковров, проведения регулярной уборки и проведения инспекции?',
        fr: 'Pouvez-vous organiser 3 fournisseurs pour venir nettoyer les tapis, effectuer un nettoyage régulier et réaliser une inspection?',
        de: 'Können Sie 3 Dienstleister einrichten, um die Teppiche zu reinigen, eine reguläre Reinigung durchzuführen und eine Inspektion zu vervollständigen?'
    },
    'Please add to your message below which services you want to auto assign to this pro.': {
        pt: 'Por favor, adicione à sua mensagem abaixo quais serviços você deseja atribuir automaticamente a este profissional.',
        es: 'Por favor, agregue a su mensaje a continuación qué servicios desea asignar automáticamente a este profesional.',
        ru: 'Пожалуйста, добавьте в ваше сообщение ниже, какие услуги вы хотите автоматически назначить этому профессионалу.',
        fr: 'Veuillez ajouter à votre message ci-dessous quels services vous souhaitez attribuer automatiquement à ce professionnel.',
        de: 'Bitte fügen Sie Ihrer Nachricht unten hinzu, welche Dienste Sie diesem Profi automatisch zuweisen möchten.'
    },
    'Please let us know what workflow you want to create. For example: when a job is completed, create a concierge action.': {
        pt: 'Por favor, informe-nos qual fluxo de trabalho você deseja criar. Por exemplo: quando um trabalho é concluído, crie uma ação de concierge.',
        es: 'Por favor, infórmenos qué flujo de trabajo desea crear. Por ejemplo: cuando se completa un trabajo, crear una acción de conserjería.',
        ru: 'Пожалуйста, сообщите нам, какой рабочий процесс вы хотите создать. Например: когда работа завершена, создайте действие консьержа.',
        fr: 'Veuillez nous indiquer quel flux de travail vous souhaitez créer. Par exemple : lorsqu\'un travail est terminé, créez une action de conciergerie.',
        de: 'Bitte lassen Sie uns wissen, welchen Workflow Sie erstellen möchten. Zum Beispiel: Wenn eine Aufgabe abgeschlossen ist, erstellen Sie eine Concierge-Aktion.'
    },
    'Task name': {
        pt: 'Nome da tarefa',
        es: 'Nombre de la tarea',
        ru: 'Название задачи',
        fr: 'Nom de la tâche',
        de: 'Aufgabenname'
    },
    'Please enter your first name.': {
        pt: 'Por favor, insira seu primeiro nome.',
        es: 'Por favor, introduzca su primer nombre.',
        ru: 'Пожалуйста, введите ваше имя.',
        fr: 'Veuillez entrer votre prénom.',
        de: 'Bitte geben Sie Ihren Vornamen ein.'
    },
    'Email CC': {
        pt: 'E-mail CC',
        es: 'Correo electrónico CC',
        ru: 'Электронная почта CC',
        fr: 'E-mail CC',
        de: 'E-Mail CC'
    },
    'Please enter a valid email': {
        pt: 'Por favor, insira um e-mail válido',
        es: 'Por favor, introduzca un correo electrónico válido',
        ru: 'Пожалуйста, введите действительный адрес электронной почты',
        fr: 'Veuillez entrer un e-mail valide',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
    },
    'Add Email CC': {
        pt: 'Adicionar e-mail CC',
        es: 'Agregar correo electrónico CC',
        ru: 'Добавить электронную почту CC',
        fr: 'Ajouter un e-mail CC',
        de: 'E-Mail CC hinzufügen'
    },
    'Create Task': {
        pt: 'Criar tarefa',
        es: 'Crear tarea',
        ru: 'Создать задачу',
        fr: 'Créer une tâche',
        de: 'Aufgabe erstellen'
    },
    'Send to Concierge': {
        pt: 'Enviar para o Concierge',
        es: 'Enviar al conserje',
        ru: 'Отправить консьержу',
        fr: 'Envoyer au concierge',
        de: 'An den Concierge senden'
    },
    '"Other" is good if none of the other categories work.': {
        pt: '"Outro" é bom se nenhuma das outras categorias funcionar.',
        es: '"Otro" es bueno si ninguna de las otras categorías funciona.',
        ru: '"Другое" подходит, если ни одна из других категорий не подходит.',
        fr: '"Autre" est bon si aucune des autres catégories ne fonctionne.',
        de: '"Andere" ist gut, wenn keine der anderen Kategorien funktioniert.'
    },
    'Task Created': {
        pt: 'Tarefa criada',
        es: 'Tarea creada',
        ru: 'Задача создана',
        fr: 'Tâche créée',
        de: 'Aufgabe erstellt'
    },
    'Message Sent': {
        pt: 'Mensagem enviada',
        es: 'Mensaje enviado',
        ru: 'Сообщение отправлено',
        fr: 'Message envoyé',
        de: 'Nachricht gesendet'
    },
    'We expect to reply within 2 business hours. We will reply both in the app and to your email.': {
        pt: 'Esperamos responder dentro de 2 horas úteis. Responderemos tanto no aplicativo quanto no seu e-mail.',
        es: 'Esperamos responder dentro de 2 horas hábiles. Responderemos tanto en la aplicación como en su correo electrónico.',
        ru: 'Мы ожидаем ответить в течение 2 рабочих часов. Мы ответим как в приложении, так и на ваш электронный адрес.',
        fr: 'Nous prévoyons de répondre dans les 2 heures ouvrables. Nous répondrons à la fois dans l\'application et à votre e-mail.',
        de: 'Wir erwarten, innerhalb von 2 Geschäftsstunden zu antworten. Wir werden sowohl in der App als auch per E-Mail antworten.'
    },
    'Unable to attach photo. Please upload a PNG or JPEG file.': {
        pt: 'Não é possível anexar foto. Por favor, faça o upload de um arquivo PNG ou JPEG.',
        es: 'No se puede adjuntar foto. Por favor, suba un archivo PNG o JPEG.',
        ru: 'Не удается прикрепить фото. Пожалуйста, загрузите файл PNG или JPEG.',
        fr: 'Impossible de joindre une photo. Veuillez télécharger un fichier PNG ou JPEG.',
        de: 'Foto kann nicht angehängt werden. Bitte laden Sie eine PNG- oder JPEG-Datei hoch.'
    },
    'Current checklist and instructions': {
        pt: 'Lista de verificação e instruções atuais',
        es: 'Lista de verificación e instrucciones actuales',
        ru: 'Текущий список проверок и инструкции',
        fr: 'Liste de contrôle et instructions actuelles',
        de: 'Aktuelle Checkliste und Anweisungen'
    },
    'List of the inventory to add': {
        pt: 'Lista do inventário a adicionar',
        es: 'Lista del inventario a agregar',
        ru: 'Список инвентаря для добавления',
        fr: 'Liste des inventaires à ajouter',
        de: 'Liste des hinzuzufügenden Inventars'
    },
    'Detail about the pro(s)': {
        pt: 'Detalhe sobre o(s) profissional(is)',
        es: 'Detalle sobre el/los profesional(es)',
        ru: 'Детали о профессионале(ах)',
        fr: 'Détail sur le(s) professionnel(s)',
        de: 'Details über den/die Profi(s)'
    },
    'Task description (optional)': {
        pt: 'Descrição da tarefa (opcional)',
        es: 'Descripción de la tarea (opcional)',
        ru: 'Описание задачи (необязательно)',
        fr: 'Description de la tâche (facultatif)',
        de: 'Aufgabenbeschreibung (optional)'
    },
    'Message': {
        pt: 'Mensagem',
        es: 'Mensaje',
        ru: 'Сообщение',
        fr: 'Message',
        de: 'Nachricht'
    },
    '<b>Examples:</b>': {
        pt: '<b>Exemplos:</b>',
        es: 'Ejemplos:</b>',
        ru: '<b>Примеры:</b>',
        fr: '<b>Exemples:</b>',
        de: '<b>Beispiele:'
    },

    //DamageClaimPage
    'Damage Claim': {
        pt: 'Reivindicação de Danos',
        es: 'Reclamación de Daños',
        ru: 'Заявление о повреждении',
        fr: 'Réclamation de Dommages',
        de: 'Schadensanspruch'
    },
    'How Damage Claims Work': {
        pt: 'Como Funcionam as Reivindicações de Danos',
        es: 'Cómo Funcionan las Reclamaciones de Daños',
        ru: 'Как работают заявления о повреждениях',
        fr: 'Fonctionnement des Réclamations de Dommages',
        de: 'Wie Schadensansprüche funktionieren'
    },
    'If you believe your Pro made a mistake and caused damage to your home, use this form to request that your Pro cover the damage.': {
        pt: 'Se você acredita que seu Profissional cometeu um erro e causou danos à sua casa, use este formulário para solicitar que seu Profissional cubra o dano.',
        es: 'Si cree que su Profesional cometió un error y causó daños a su hogar, utilice este formulario para solicitar que su Profesional cubra los daños.',
        ru: 'Если вы считаете, что ваш Профессионал допустил ошибку и повредил ваш дом, используйте эту форму, чтобы запросить, чтобы ваш Профессионал покрыл ущерб.',
        fr: 'Si vous pensez que votre Pro a fait une erreur et causé des dommages à votre domicile, utilisez ce formulaire pour demander à votre Pro de couvrir les dommages.',
        de: 'Wenn Sie glauben, dass Ihr Profi einen Fehler gemacht hat und Schaden an Ihrem Zuhause verursacht hat, verwenden Sie dieses Formular, um zu verlangen, dass Ihr Profi den Schaden abdeckt.'
    },
    '<li>In order for claims to be considered, damage must be reported within 72 hours of the appointment start time, a claim must be filed within 14 days of the appointment, and you must be up to date with any payments at the time the claim is processed. Any claims that do not meet these criteria will not be considered.</li>': {
        pt: '<li>Para que as reivindicações sejam consideradas, o dano deve ser relatado dentro de 72 horas após o início do compromisso, uma reivindicação deve ser apresentada dentro de 14 dias após o compromisso, e você deve estar em dia com quaisquer pagamentos no momento em que a reivindicação é processada. Qualquer reivindicação que não atenda a esses critérios não será considerada.</li>',
        es: '<li>Para que las reclamaciones sean consideradas, el daño debe ser reportado dentro de las 72 horas posteriores al inicio de la cita, una reclamación debe ser presentada dentro de los 14 días posteriores a la cita, y usted debe estar al día con cualquier pago en el momento en que se procese la reclamación. Cualquier reclamación que no cumpla con estos criterios no será considerada.</li>',
        ru: '<li>Для рассмотрения претензий ущерб должен быть сообщен в течение 72 часов с момента начала встречи, претензия должна быть подана в течение 14 дней после встречи, и вы должны быть в курсе любых платежей на момент обработки претензии. Любые претензии, не соответствующие этим критериям, не будут рассмотрены.</li>',
        fr: '<li>Pour que les réclamations soient prises en compte, les dommages doivent être signalés dans les 72 heures suivant le début du rendez-vous, une réclamation doit être déposée dans les 14 jours suivant le rendez-vous, et vous devez être à jour avec tous les paiements au moment où la réclamation est traitée. Toute réclamation qui ne répond pas à ces critères ne sera pas considérée.</li>',
        de: '<li>Damit Ansprüche berücksichtigt werden können, muss der Schaden innerhalb von 72 Stunden nach Beginn des Termins gemeldet werden, ein Anspruch muss innerhalb von 14 Tagen nach dem Termin eingereicht werden, und Sie müssen zum Zeitpunkt der Bearbeitung des Anspruchs mit allen Zahlungen auf dem neuesten Stand sein. Ansprüche, die diese Kriterien nicht erfüllen, werden nicht berücksichtigt.</li>'
    },
    '<li>All claims will be resolved based on the merits of the claims investigation.</li>': {
        pt: '<li>Todas as reivindicações serão resolvidas com base nos méritos da investigação das reivindicações.</li>',
        es: '<li>Todas las reclamaciones se resolverán en base a los méritos de la investigación de las reclamaciones.</li>',
        ru: '<li>Все претензии будут решены на основе заслуг расследования претензий.</li>',
        fr: '<li>Toutes les réclamations seront résolues sur la base des mérites de l\'enquête sur les réclamations.</li>',
        de: '<li>Alle Ansprüche werden auf der Grundlage der Verdienste der Anspruchsprüfung gelöst.</li>'
    },
    '<li>Proof of damage and original value of the damaged item(s) is required.</li>': {
        pt: '<li>É necessário comprovar o dano e o valor original do(s) item(ns) danificado(s).</li>',
        es: '<li>Se requiere prueba del daño y el valor original del artículo(s) dañado(s).</li>',
        ru: '<li>Требуется доказательство повреждения и первоначальная стоимость поврежденного(ых) предмета(ов).</li>',
        fr: '<li>Une preuve des dommages et de la valeur originale de l\'article(s) endommagé(s) est requise.</li>',
        de: '<li>Ein Nachweis des Schadens und des Originalwerts des beschädigten Artikels ist erforderlich.</li>'
    },
    '<li>Claims will not be considered without detailed documentation.</li>': {
        pt: '<li>Reivindicações não serão consideradas sem documentação detalhada.</li>',
        es: '<li>Las reclamaciones no serán consideradas sin documentación detallada.</li>',
        ru: '<li>Претензии не будут рассмотрены без подробной документации.</li>',
        fr: '<li>Les réclamations ne seront pas prises en compte sans documentation détaillée.</li>',
        de: '<li>Ansprüche werden ohne detaillierte Dokumentation nicht berücksichtigt.</li>'
    },
    'TIDY does not guarantee replacement or reimbursement for the original cost of an item, and will determine the fair market value. If fair market value cannot be determined, TIDY can reimburse 25% of the cost of a similar replacement item. TIDY shall be the sole determiner of fair market value, or the cost of a similar replacement item. For any repair, TIDY shall reimburse for low cost provider for an average version of the repaired object. TIDY shall be the sole determiner of the eligibility of claims.': {
        pt: 'A TIDY não garante substituição ou reembolso pelo custo original de um item e determinará o valor de mercado justo. Se o valor de mercado justo não puder ser determinado, a TIDY pode reembolsar 25% do custo de um item de substituição semelhante. A TIDY será a única determinadora do valor de mercado justo ou do custo de um item de substituição semelhante. Para qualquer reparo, a TIDY reembolsará o fornecedor de baixo custo para uma versão média do objeto reparado. A TIDY será a única determinadora da elegibilidade das reivindicações.',
        es: 'TIDY no garantiza la reposición o el reembolso del costo original de un artículo y determinará el valor de mercado justo. Si no se puede determinar el valor de mercado justo, TIDY puede reembolsar el 25% del costo de un artículo de reemplazo similar. TIDY será el único determinante del valor de mercado justo o del costo de un artículo de reemplazo similar. Para cualquier reparación, TIDY reembolsará al proveedor de bajo costo para una versión promedio del objeto reparado. TIDY será el único determinante de la elegibilidad de las reclamaciones.',
        ru: 'TIDY не гарантирует замену или возмещение первоначальной стоимости товара и определит справедливую рыночную стоимость. Если справедливую рыночную стоимость невозможно определить, TIDY может возместить 25% стоимости аналогичного заменяемого товара. TIDY будет единственным определителем справедливой рыночной стоимости или стоимости аналогичного заменяемого товара. Для любого ремонта TIDY возместит стоимость поставщика низкой стоимости для средней версии отремонтированного объекта. TIDY будет единственным определителем права на претензии.',
        fr: 'TIDY ne garantit pas le remplacement ou le remboursement du coût original d\'un article et déterminera la juste valeur marchande. Si la juste valeur marchande ne peut être déterminée, TIDY peut rembourser 25% du coût d\'un article de remplacement similaire. TIDY sera le seul déterminant de la juste valeur marchande ou du coût d\'un article de remplacement similaire. Pour toute réparation, TIDY remboursera le fournisseur à faible coût pour une version moyenne de l\'objet réparé. TIDY sera le seul déterminant de l\'éligibilité des réclamations.',
        de: 'TIDY garantiert keinen Ersatz oder Erstattung der ursprünglichen Kosten eines Artikels und wird den fairen Marktwert bestimmen. Wenn der faire Marktwert nicht festgestellt werden kann, kann TIDY 25% der Kosten eines ähnlichen Ersatzartikels erstatten. TIDY ist der alleinige Bestimmer des fairen Marktwerts oder der Kosten eines ähnlichen Ersatzartikels. Für jede Reparatur erstattet TIDY den Anbieter mit niedrigen Kosten für eine durchschnittliche Version des reparierten Objekts. TIDY ist der alleinige Bestimmer der Anspruchsberechtigung.'
    },
    'All approved claims will be reimbursed with credit towards future Cleanings. If your claim is approved, any previous credits or refunds for that cleaning shall apply towards the reimbursement credit.': {
        pt: 'Todas as reivindicações aprovadas serão reembolsadas com crédito para futuras limpezas. Se sua reivindicação for aprovada, quaisquer créditos ou reembolsos anteriores para essa limpeza serão aplicados ao crédito de reembolso.',
        es: 'Todas las reclamaciones aprobadas serán reembolsadas con crédito para futuras limpiezas. Si su reclamación es aprobada, cualquier crédito o reembolso anterior para esa limpieza se aplicará al crédito de reembolso.',
        ru: 'Все одобренные претензии будут возмещены кредитом на будущие уборки. Если ваша претензия будет одобрена, любые предыдущие кредиты или возвраты за эту уборку будут применены к кредиту на возмещение.',
        fr: 'Toutes les réclamations approuvées seront remboursées par un crédit pour de futurs nettoyages. Si votre réclamation est approuvée, tout crédit ou remboursement antérieur pour ce nettoyage sera appliqué au crédit de remboursement.',
        de: 'Alle genehmigten Ansprüche werden mit einem Guthaben für zukünftige Reinigungen erstattet. Wenn Ihr Anspruch genehmigt wird, werden alle vorherigen Gutschriften oder Rückerstattungen für diese Reinigung auf das Erstattungsguthaben angewendet.'
    },
    'Common Reasons a Claim is Rejected': {
        pt: 'Razões Comuns para Rejeição de uma Reivindicação',
        es: 'Razones Comunes por las que se Rechaza una Reclamación',
        ru: 'Распространенные причины отказа в претензии',
        fr: 'Raisons Courantes du Rejet d\'une Réclamation',
        de: 'Häufige Gründe für die Ablehnung eines Anspruchs'
    },
    '<li>The damage was not due to a clear error on the Pro\'s part.</li>': {
        pt: '<li>O dano não foi devido a um erro claro por parte do Profissional.</li>',
        es: '<li>El daño no se debió a un error claro por parte del Profesional.</li>',
        ru: '<li>Повреждение не было вызвано явной ошибкой со стороны Профессионала.</li>',
        fr: '<li>Le dommage n\'était pas dû à une erreur claire de la part du Pro.</li>',
        de: '<li>Der Schaden war nicht auf einen klaren Fehler des Profis zurückzuführen.</li>'
    },
    '<li>The damage could reasonably be expected to occur in a home cleaning, not due to a mistake.</li>': {
        pt: '<li>O dano poderia razoavelmente ser esperado para ocorrer em uma limpeza doméstica, não devido a um erro.</li>',
        es: '<li>Se podría esperar razonablemente que el daño ocurriera en una limpieza doméstica, no debido a un error.</li>',
        ru: '<li>Повреждение могло разумно ожидаться во время домашней уборки, не из-за ошибки.</li>',
        fr: '<li>Il était raisonnable de s\'attendre à ce que le dommage se produise lors d\'un nettoyage domestique, et non à cause d\'une erreur.</li>',
        de: '<li>Es war zu erwarten, dass der Schaden bei einer Haushaltsreinigung auftritt, nicht aufgrund eines Fehlers.</li>'
    },
    '<li>The damage was pre-existing, or damage was caused by a pre-existing condition.</li>': {
        pt: '<li>O dano era pré-existente, ou o dano foi causado por uma condição pré-existente.</li>',
        es: '<li>El daño era preexistente, o el daño fue causado por una condición preexistente.</li>',
        ru: '<li>Повреждение было предшествующим, или повреждение было вызвано предшествующим состоянием.</li>',
        fr: '<li>Le dommage était préexistant, ou le dommage a été causé par une condition préexistante.</li>',
        de: '<li>Der Schaden war bereits vorhanden oder wurde durch einen bereits vorhandenen Zustand verursacht.</li>'
    },
    '<li>The damage is caused by faulty appliances or items in your home.</li>': {
        pt: '<li>O dano é causado por aparelhos defeituosos ou itens em sua casa.</li>',
        es: '<li>El daño es causado por electrodomésticos defectuosos o artículos en su hogar.</li>',
        ru: '<li>Повреждение вызвано неисправной бытовой техникой или предметами в вашем доме.</li>',
        fr: '<li>Le dommage est causé par des appareils défectueux ou des articles dans votre maison.</li>',
        de: '<li>Der Schaden wird durch fehlerhafte Geräte oder Gegenstände in Ihrem Zuhause verursacht.</li>'
    },
    '<li>The damage is determined to be standard wear and tear.</li>': {
        pt: '<li>O dano é determinado como desgaste normal.</li>',
        es: '<li>El daño se determina como desgaste normal.</li>',
        ru: '<li>Повреждение определено как стандартный износ.</li>',
        fr: '<li>Le dommage est déterminé comme étant de l\'usure normale.</li>',
        de: '<li>Der Schaden wird als normaler Verschleiß festgestellt.</li>'
    },
    '<li>You completed the repairs before an assessment of the damage could be made.</li>': {
        pt: '<li>Você completou os reparos antes que uma avaliação dos danos pudesse ser feita.</li>',
        es: '<li>Usted completó las reparaciones antes de que se pudiera hacer una evaluación de los daños.</li>',
        ru: '<li>Вы завершили ремонт до того, как была сделана оценка повреждений.</li>',
        fr: '<li>Vous avez effectué les réparations avant qu\'une évaluation des dommages puisse être faite.</li>',
        de: '<li>Sie haben die Reparaturen abgeschlossen, bevor eine Schadensbewertung vorgenommen werden konnte.</li>'
    },
    '<li>The claim was not made within 72 hours of the appointment time.</li>': {
        pt: '<li>A reclamação não foi feita dentro de 72 horas do horário do agendamento.</li>',
        es: '<li>La reclamación no se hizo dentro de las 72 horas de la hora de la cita.</li>',
        ru: '<li>Претензия не была подана в течение 72 часов с момента назначения.</li>',
        fr: '<li>La réclamation n\'a pas été faite dans les 72 heures suivant le rendez-vous.</li>',
        de: '<li>Die Forderung wurde nicht innerhalb von 72 Stunden nach dem Termin gemacht.</li>'
    },
    '<li>The damage was not made as a direct result of the cleaning or did not occur during the cleaning.</li>': {
        pt: '<li>O dano não foi resultado direto da limpeza ou não ocorreu durante a limpeza.</li>',
        es: '<li>El daño no fue resultado directo de la limpieza o no ocurrió durante la limpieza.</li>',
        ru: '<li>Повреждение не было прямым результатом уборки или не произошло во время уборки.</li>',
        fr: '<li>Le dommage n\'était pas le résultat direct du nettoyage ou ne s\'est pas produit pendant le nettoyage.</li>',
        de: '<li>Der Schaden war nicht die direkte Folge der Reinigung oder trat nicht während der Reinigung auf.</li>'
    },
    '<li>The damage was caused by cleaning products that you requested the pro to use, or caused by actions taken at your instruction.</li>': {
        pt: '<li>O dano foi causado por produtos de limpeza que você solicitou que o profissional usasse, ou causado por ações tomadas a seu pedido.</li>',
        es: '<li>El daño fue causado por productos de limpieza que usted solicitó que el profesional usara, o causado por acciones tomadas bajo su instrucción.</li>',
        ru: '<li>Повреждение было вызвано чистящими средствами, которые вы попросили профессионала использовать, или действиями, предпринятыми по вашей инструкции.</li>',
        fr: '<li>Le dommage a été causé par des produits de nettoyage que vous avez demandé au professionnel d\'utiliser, ou causé par des actions prises à votre instruction.</li>',
        de: '<li>Der Schaden wurde durch Reinigungsprodukte verursacht, die Sie den Profi gebeten haben zu verwenden, oder durch Maßnahmen, die auf Ihre Anweisung hin erfolgten.</li>'
    },
    'Additional Terms': {
        pt: 'Termos Adicionais',
        es: 'Términos Adicionales',
        ru: 'Дополнительные условия',
        fr: 'Conditions supplémentaires',
        de: 'Zusätzliche Bedingungen'
    },
    'YOU ARE NOT AN INSURED OR OTHER THIRD PARTY BENEFICIARY UNDER THE TIDY INSURANCE POLICY. TO THE EXTENT YOU DESIRE ADDITIONAL PROTECTION BEYOND THE COVERAGE AFFORDED BY THE TIDY CLIENT GUARANTEE, TIDY STRONGLY ENCOURAGES YOU TO PURCHASE SEPARATE INSURANCE THAT WILL COVER YOU AND YOUR PROPERTY FOR LOSSES CAUSED BY PROS.': {
        pt: 'VOCÊ NÃO É UM SEGURADO OU OUTRO BENEFICIÁRIO TERCEIRO SOB A POLÍTICA DE SEGURO TIDY. NA MEDIDA EM QUE DESEJA PROTEÇÃO ADICIONAL ALÉM DA COBERTURA FORNECIDA PELA GARANTIA DO CLIENTE TIDY, A TIDY O INCENTIVA FORTEMENTE A ADQUIRIR UM SEGURO SEPARADO QUE COBRIRÁ VOCÊ E SUA PROPRIEDADE PARA PERDAS CAUSADAS POR PROFISSIONAIS.',
        es: 'USTED NO ES UN ASEGURADO NI OTRO BENEFICIARIO DE TERCEROS BAJO LA PÓLIZA DE SEGURO DE TIDY. EN LA MEDIDA QUE DESEE PROTECCIÓN ADICIONAL MÁS ALLÁ DE LA COBERTURA PROPORCIONADA POR LA GARANTÍA DEL CLIENTE DE TIDY, TIDY LE ANIMA ENÉRGICAMENTE A QUE ADQUIERA UN SEGURO SEPARADO QUE CUBRA USTED Y SU PROPIEDAD POR PÉRDIDAS CAUSADAS POR PROFESIONALES.',
        ru: 'ВЫ НЕ ЯВЛЯЕТЕСЬ СТРАХОВАТЕЛЕМ ИЛИ ДРУГИМ ТРЕТЬИМ ЛИЦОМ, ПОЛУЧАЮЩИМ ВЫГОДУ ПО ПОЛИСУ СТРАХОВАНИЯ TIDY. В ТОЙ МЕРЕ, В КАКОЙ ВЫ ЖЕЛАЕТЕ ДОПОЛНИТЕЛЬНОЙ ЗАЩИТЫ СВЕРХ ПОКРЫТИЯ, ПРЕДОСТАВЛЯЕМОГО ГАРАНТИЕЙ КЛИЕНТА TIDY, TIDY НАСТОЯТЕЛЬНО РЕКОМЕНДУЕТ ВАМ ПРИОБРЕСТИ ОТДЕЛЬНУЮ СТРАХОВКУ, КОТОРАЯ ПОКРОЕТ ВАС И ВАШЕ ИМУЩЕСТВО ОТ УБЫТКОВ, ПРИЧИНЕННЫХ ПРОФЕССИОНАЛАМИ.',
        fr: 'VOUS N\'ÊTES PAS UN ASSURÉ NI UN AUTRE BÉNÉFICIAIRE TIERS SOUS LA POLITIQUE D\'ASSURANCE TIDY. DANS LA MESURE OÙ VOUS DÉSIREZ UNE PROTECTION SUPPLÉMENTAIRE AU-DELÀ DE LA COUVERTURE OFFERTE PAR LA GARANTIE CLIENT TIDY, TIDY VOUS ENCOURAGE FORTEMENT À ACHETER UNE ASSURANCE SÉPARÉE QUI COUVRIRA VOUS ET VOTRE PROPRIÉTÉ POUR LES PERTES CAUSÉES PAR DES PROFESSIONNELS.',
        de: 'SIE SIND KEIN VERSICHERTER ODER ANDERER DRITTBEGÜNSTIGTER UNTER DER TIDY-VERSICHERUNGSPOLICE. IN DEM UMFANG, IN DEM SIE ZUSÄTZLICHEN SCHUTZ ÜBER DEN DURCH DIE TIDY-KUNDENGARANTIE GEWÄHRTEN SCHUTZ HINAUS WÜNSCHEN, ERMUDET SIE TIDY NACHDRÜCKLICH, EINE SEPARATE VERSICHERUNG ZU ERWERBEN, DIE SIE UND IHR EIGENTUM VOR VERLUSTEN DURCH FACHLEUTE SCHÜTZT.'
    },
    'You acknowledge and agree that:': {
        pt: 'Você reconhece e concorda que:',
        es: 'Usted reconoce y acepta que:',
        ru: 'Вы признаете и соглашаетесь с тем, что:',
        fr: 'Vous reconnaissez et acceptez que :',
        de: 'Sie erkennen an und stimmen zu, dass:'
    },
    '<li>These terms are not intended to constitute an offer to insure, do not constitute insurance or an insurance contract, and do not take the place of insurance obtained or obtainable by you.</li>': {
        pt: '<li>Estes termos não têm a intenção de constituir uma oferta de seguro, não constituem seguro ou um contrato de seguro, e não substituem o seguro obtido ou que pode ser obtido por você.</li>',
        es: '<li>Estos términos no tienen la intención de constituir una oferta de seguro, no constituyen un seguro o un contrato de seguro, y no reemplazan el seguro obtenido o que se puede obtener por usted.</li>',
        ru: '<li>Эти условия не предназначены для того, чтобы составлять предложение о страховании, не являются страхованием или страховым контрактом и не заменяют страхование, полученное или доступное для вас.</li>',
        fr: '<li>Ces termes ne sont pas destinés à constituer une offre d\'assurance, ne constituent pas une assurance ou un contrat d\'assurance, et ne remplacent pas l\'assurance obtenue ou pouvant être obtenue par vous.</li>',
        de: '<li>Diese Bedingungen sind nicht als Angebot für eine Versicherung gedacht, stellen keine Versicherung oder einen Versicherungsvertrag dar und ersetzen keine von Ihnen erhaltene oder erhältliche Versicherung.</li>'
    },
    '<li>You agree to release, defend, indemnify, and hold TIDY and its affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with this agreement.</li>': {
        pt: '<li>Você concorda em liberar, defender, indenizar e manter a TIDY e suas afiliadas e subsidiárias, e seus oficiais, diretores, empregados e agentes, isentos de quaisquer reivindicações, responsabilidades, danos, perdas e despesas, incluindo, sem limitação, honorários legais e contábeis razoáveis, decorrentes de ou de qualquer forma relacionados a este acordo.</li>',
        es: '<li>Usted acepta liberar, defender, indemnizar y mantener a TIDY y sus filiales y subsidiarias, y a sus oficiales, directores, empleados y agentes, indemnes de y contra cualquier reclamación, responsabilidad, daños, pérdidas y gastos, incluyendo, sin limitación, honorarios legales y contables razonables, que surjan de o de cualquier manera relacionados con este acuerdo.</li>',
        ru: '<li>Вы соглашаетесь освободить, защитить, возместить ущерб и удерживать TIDY и ее филиалы и дочерние компании, их офицеров, директоров, сотрудников и агентов, от любых претензий, обязательств, ущерба, потерь и расходов, включая, без ограничений, разумные юридические и бухгалтерские сборы, возникающие из или в любом отношении связанные с этим соглашением.</li>',
        fr: '<li>Vous acceptez de libérer, défendre, indemniser et tenir TIDY et ses filiales et sociétés affiliées, ainsi que leurs dirigeants, directeurs, employés et agents, à l\'abri de toute réclamation, responsabilité, dommages, pertes et dépenses, y compris, sans limitation, les frais juridiques et comptables raisonnables, découlant de ou de quelque manière que ce soit liés à cet accord.</li>',
        de: '<li>Sie erklären sich damit einverstanden, TIDY und seine Tochtergesellschaften und verbundenen Unternehmen sowie deren Beamte, Direktoren, Mitarbeiter und Agenten von allen Ansprüchen, Verbindlichkeiten, Schäden, Verlusten und Kosten, einschließlich, aber nicht beschränkt auf angemessene Anwalts- und Buchhaltungsgebühren, freizustellen, zu verteidigen, zu entschädigen und schadlos zu halten, die aus oder in irgendeiner Weise mit dieser Vereinbarung zusammenhängen.</li>'
    },
    '<li>You may not assign or transfer these terms or rights, by operation of law or otherwise, without TIDY’s prior written consent.</li>': {
        pt: '<li>Você não pode ceder ou transferir estes termos ou direitos, por operação de lei ou de outra forma, sem o consentimento prévio por escrito da TIDY.</li>',
        es: '<li>Usted no puede ceder o transferir estos términos o derechos, por operación de ley o de otra manera, sin el consentimiento previo por escrito de TIDY.</li>',
        ru: '<li>Вы не можете передать или переуступить эти условия или права, по закону или иным образом, без предварительного письменного согласия TIDY.</li>',
        fr: '<li>Vous ne pouvez pas céder ou transférer ces termes ou droits, par voie de loi ou autrement, sans le consentement écrit préalable de TIDY.</li>',
        de: '<li>Sie dürfen diese Bedingungen oder Rechte nicht durch Gesetz oder auf andere Weise ohne die vorherige schriftliche Zustimmung von TIDY abtreten oder übertragen.</li>'
    },
    'IF YOU CHOOSE TO USE THE SITE, APPLICATION, OR SERVICES AS A CLIENT, YOU DO SO AT YOUR SOLE RISK. THE TIDY SERVICE AND APPLICATION IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, APPLICATION AND SERVICES, REMAINS WITH YOU.': {
        pt: 'SE VOCÊ ESCOLHER USAR O SITE, APLICAÇÃO OU SERVIÇOS COMO CLIENTE, FAZ ISSO POR SUA CONTA E RISCO. O SERVIÇO E A APLICAÇÃO TIDY SÃO FORNECIDOS "COMO ESTÃO", SEM GARANTIA DE QUALQUER TIPO, EXPRESSA OU IMPLÍCITA. VOCÊ RECONHECE E CONCORDA QUE, NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, TODO O RISCO DECORRENTE DO SEU ACESSO E USO DO SITE, APLICAÇÃO E SERVIÇOS, PERMANECE COM VOCÊ.',
        es: 'SI ELIGE UTILIZAR EL SITIO, LA APLICACIÓN O LOS SERVICIOS COMO CLIENTE, LO HACE BAJO SU PROPIO RIESGO. EL SERVICIO Y LA APLICACIÓN DE TIDY SE PROPORCIONAN "TAL CUAL", SIN GARANTÍA DE NINGÚN TIPO, EXPRESA O IMPLÍCITA. USTED RECONOCE Y ACEPTA QUE, EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY, TODO EL RIESGO DERIVADO DE SU ACCESO Y USO DEL SITIO, LA APLICACIÓN Y LOS SERVICIOS, PERMANECE CON USTED.',
        ru: 'ЕСЛИ ВЫ РЕШИТЕ ИСПОЛЬЗОВАТЬ САЙТ, ПРИЛОЖЕНИЕ ИЛИ УСЛУГИ КАК КЛИЕНТ, ВЫ ДЕЛАЕТЕ ЭТО НА СВОЙ СТРАХ И РИСК. СЕРВИС И ПРИЛОЖЕНИЕ TIDY ПРЕДОСТАВЛЯЮТСЯ "КАК ЕСТЬ", БЕЗ ГАРАНТИЙ КАКОЙ-ЛИБО ФОРМЫ, ЯВНЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ. ВЫ ПРИЗНАЕТЕ И СОГЛАШАЕТЕСЬ С ТЕМ, ЧТО, В МАКСИМАЛЬНО ДОПУСТИМОЙ СТЕПЕНИ, УСТАНОВЛЕННОЙ ЗАКОНОМ, ВСЕ РИСКИ, ВОЗНИКАЮЩИЕ В СВЯЗИ С ВАШИМ ДОСТУПОМ И ИСПОЛЬЗОВАНИЕМ САЙТА, ПРИЛОЖЕНИЯ И УСЛУГ, ОСТАЮТСЯ С ВАМИ.',
        fr: 'SI VOUS CHOISISSEZ D\'UTILISER LE SITE, L\'APPLICATION OU LES SERVICES EN TANT QUE CLIENT, VOUS LE FAITES À VOS PROPRES RISQUES. LE SERVICE ET L\'APPLICATION TIDY SONT FOURNIS "TELS QUELS", SANS GARANTIE D\'AUCUNE SORTE, EXPRESSE OU IMPLICITE. VOUS RECONNAISSEZ ET ACCEPTEZ QUE, DANS LA MESURE MAXIMALE AUTORISÉE PAR LA LOI, L\'ENSEMBLE DES RISQUES DÉCOULANT DE VOTRE ACCÈS ET UTILISATION DU SITE, DE L\'APPLICATION ET DES SERVICES, RESTE AVEC VOUS.',
        de: 'WENN SIE SICH ENTSCHEIDEN, DIE WEBSITE, DIE ANWENDUNG ODER DIE DIENSTE ALS KUNDE ZU NUTZEN, TUN SIE DIES AUF EIGENES RISIKO. DER TIDY-SERVICE UND DIE ANWENDUNG WERDEN "WIE BESEHEN" OHNE JEGLICHE GARANTIE, OB AUSDRÜCKLICH ODER IMPLIZIT, BEREITGESTELLT. SIE ERKENNEN AN UND STIMMEN ZU, DASS, SOWEIT GESETZLICH ZULÄSSIG, DAS GESAMTE RISIKO, DAS AUS IHREM ZUGANG UND DER NUTZUNG DER WEBSITE, DER ANWENDUNG UND DER DIENSTE ENTSTEHT, BEI IHNEN VERBLEIBT.'
    },
    'IN NO WAY DOES OUR POLICY DOES COVER THEFT.': {
        pt: 'DE NENHUMA FORMA NOSSA POLÍTICA COBRE ROUBO.',
        es: 'DE NINGUNA MANERA NUESTRA POLÍTICA CUBRE EL ROBO.',
        ru: 'НАША ПОЛИТИКА НИ В КОЕМ СЛУЧАЕ НЕ ПОКРЫВАЕТ КРАЖУ.',
        fr: 'EN AUCUN CAS NOTRE POLITIQUE NE COUVRE LE VOL.',
        de: 'UNSERE POLITIK DECKT AUF KEINEN FALL DIEBSTAHL AB.'
    },
    'THIS ONLY COVERS FUNCTIONAL REPLACEMENTS. ANY OBJECT THAT IS EXPENSIVE OR PRICELESS BEYOND ITS FUNCTIONAL USE, WE RECOMMEND GETTING SEPARATE INSURANCE TO COVER THAT OBJECT(S). NEITHER PROS NOR TIDY ARE ASSUMING EXCESS LIABILITY DUE TO THE FACT THAT YOUR GOODS ARE EXPENSIVE. UNDER NO CIRCUMSTANCE SHALL TIDY NOR PRO COVER DAMAGE TO ANY SINGLE MOVABLE OBJECT IN EXCESS OF $1,000. TIDY DOES NOT UNDERWRITE HOMES, AND AS SUCH WILL ONLY REIMBURSE OR REPLACE FOR AN AVERAGE VERSION OF THE DAMAGED ITEM. BY BOOKING A CLEANING FOR A HOUSE WITH EXPENSIVE GOODS, YOU EXPRESSLY AGREE TO ASSUME THE EXCESS RISK FROM SUCH EXPENSIVE GOODS. FOR EXAMPLE, IF YOU HAVE A PIECE OF RARE OR EXPENSIVE PROPERTY THAT IS DAMAGED IN THE COURSE OF A JOB, THIS WILL NOT LIKELY COVER THE VALUE OF THE PROPERTY.': {
        pt: 'ISSO SÓ COBRE SUBSTITUIÇÕES FUNCIONAIS. QUALQUER OBJETO QUE SEJA CARO OU INESTIMÁVEL ALÉM DE SEU USO FUNCIONAL, RECOMENDAMOS OBTER UM SEGURO SEPARADO PARA COBRIR ESSE(S) OBJETO(S). NEM PROS NEM TIDY ASSUMEM RESPONSABILIDADE EXCESSIVA DEVIDO AO FATO DE SEUS BENS SEREM CAROS. EM NENHUMA CIRCUNSTÂNCIA TIDY OU PRO COBRIRÃO DANOS A QUALQUER OBJETO MÓVEL ÚNICO QUE EXCEDA $1,000. TIDY NÃO ASSEGURA CASAS, E COMO TAL, SÓ REEMBOLSARÁ OU SUBSTITUIRÁ POR UMA VERSÃO MÉDIA DO ITEM DANIFICADO. AO AGENDAR UMA LIMPEZA PARA UMA CASA COM BENS CAROS, VOCÊ CONCORDA EXPRESSAMENTE EM ASSUMIR O RISCO EXCESSIVO DE TAIS BENS CAROS. POR EXEMPLO, SE VOCÊ TIVER UM OBJETO RARO OU CARO QUE SEJA DANIFICADO DURANTE UM TRABALHO, ISSO PROVAVELMENTE NÃO COBRIRÁ O VALOR DO BEM.',
        es: 'ESTO SOLO CUBRE REEMPLAZOS FUNCIONALES. CUALQUIER OBJETO QUE SEA CARO O INVALUABLE MÁS ALLÁ DE SU USO FUNCIONAL, RECOMENDAMOS OBTENER UN SEGURO SEPARADO PARA CUBRIR ESE(S) OBJETO(S). NI LOS PROFESIONALES NI TIDY ASUMEN RESPONSABILIDAD EXCESIVA DEBIDO A QUE SUS BIENES SON CAROS. BAJO NINGUNA CIRCUNSTANCIA TIDY NI EL PROFESIONAL CUBRIRÁN DAÑOS A CUALQUIER OBJETO MÓVIL ÚNICO QUE EXCEDA LOS $1,000. TIDY NO ASEGURA HOGARES, Y COMO TAL, SOLO REEMBOLSARÁ O REEMPLAZARÁ POR UNA VERSIÓN PROMEDIO DEL ARTÍCULO DAÑADO. AL RESERVAR UNA LIMPIEZA PARA UNA CASA CON BIENES CAROS, USTED ACEPTA EXPRESAMENTE ASUMIR EL RIESGO EXCESIVO DE TALES BIENES CAROS. POR EJEMPLO, SI TIENE UNA PIEZA DE PROPIEDAD RARA O CARA QUE SE DAÑA EN EL CURSO DE UN TRABAJO, ESTO PROBABLEMENTE NO CUBRIRÁ EL VALOR DE LA PROPIEDAD.',
        ru: 'ЭТО ПОКРЫВАЕТ ТОЛЬКО ФУНКЦИОНАЛЬНЫЕ ЗАМЕНЫ. ЛЮБОЙ ОБЬЕКТ, КОТОРЫЙ ДОРОГОЙ ИЛИ БЕСЦЕННЫЙ СВЕРХ ЕГО ФУНКЦИОНАЛЬНОГО ИСПОЛЬЗОВАНИЯ, МЫ РЕКОМЕНДУЕМ ПОЛУЧИТЬ ОТДЕЛЬНУЮ СТРАХОВКУ ДЛЯ ПОКРЫТИЯ ЭТОГО ОБЬЕКТА(ОВ). НИ ПРОФЕССИОНАЛЫ, НИ TIDY НЕ ПРИНИМАЮТ ИЗБЫТОЧНУЮ ОТВЕТСТВЕННОСТЬ ИЗ-ЗА ТОГО, ЧТО ВАШИ ТОВАРЫ ДОРОГИЕ. НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ TIDY ИЛИ ПРОФЕССИОНАЛ НЕ ПОКРОЮТ УЩЕРБ ОТДЕЛЬНОМУ ПЕРЕМЕЩАЕМОМУ ОБЬЕКТУ, ПРЕВЫШАЮЩЕМУ $1,000. TIDY НЕ СТРАХУЕТ ДОМА, И КАК ТАКОВОЙ, БУДЕТ ТОЛЬКО ВОЗМЕЩАТЬ ИЛИ ЗАМЕНЯТЬ СРЕДНЮЮ ВЕРСИЮ ПОВРЕЖДЕННОГО ПРЕДМЕТА. ЗАБРОНИРОВАВ УБОРКУ ДЛЯ ДОМА С ДОРОГИМИ ТОВАРАМИ, ВЫ ЯВНО СОГЛАШАЕТЕСЬ ПРИНЯТЬ ИЗБЫТОЧНЫЙ РИСК ОТ ТАКИХ ДОРОГИХ ТОВАРОВ. НАПРИМЕР, ЕСЛИ У ВАС ЕСТЬ РЕДКИЙ ИЛИ ДОРОГОЙ ПРЕДМЕТ, КОТОРЫЙ ПОВРЕЖДЕН В ХОДЕ РАБОТЫ, ЭТО ВРЯД ЛИ ПОКРОЕТ СТОИМОСТЬ СОБСТВЕННОСТИ.',
        fr: 'CELA NE COUVRE QUE LES REMPLACEMENTS FONCTIONNELS. TOUT OBJET QUI EST CHER OU INESTIMABLE AU-DELÀ DE SON UTILISATION FONCTIONNELLE, NOUS RECOMMANDONS D\'OBTENIR UNE ASSURANCE SÉPARÉE POUR COUVRIR CET OBJET(S). NI LES PROFESSIONNELS NI TIDY N\'ASSUMENT UNE RESPONSABILITÉ EXCESSIVE EN RAISON DU FAIT QUE VOS BIENS SONT CHERS. EN AUCUNE CIRCONSTANCE TIDY NI LE PROFESSIONNEL NE COUVRIRONT LES DOMMAGES À UN OBJET MOBILE UNIQUE EXCÉDANT 1 000 $. TIDY NE SOUSCRIT PAS LES MAISONS, ET EN TANT QUE TEL NE REMBOURSERA OU NE REMPLACERA QUE POUR UNE VERSION MOYENNE DE L\'ARTICLE ENDOMMAGÉ. EN RÉSERVANT UN NETTOYAGE POUR UNE MAISON AVEC DES BIENS COÛTEUX, VOUS ACCEPTEZ EXPRESSÉMENT D\'ASSUMER LE RISQUE EXCESSIF DE TELS BIENS COÛTEUX. PAR EXEMPLE, SI VOUS AVEZ UN BIEN RARE OU COÛTEUX QUI EST ENDOMMAGÉ AU COURS D\'UN TRAVAIL, CELA NE COUVRIRA PROBABLEMENT PAS LA VALEUR DE LA PROPRIÉTÉ.',
        de: 'DIES DECKT NUR FUNKTIONALE ERSATZTEILE AB. JEDES OBJEKT, DAS ÜBER SEINEN FUNKTIONALEN GEBRAUCH HINAUS TEUER ODER UNBEZAHLBAR IST, EMPFEHLEN WIR, EINE SEPARATE VERSICHERUNG ABZUSCHLIEßEN, UM DIESES OBJEKT(E) ZU SCHÜTZEN. WEDER PROFESSIONELLE NOCH TIDY ÜBERNEHMEN ÜBERMÄßIGE HAFTUNG, WEIL IHRE GÜTER TEUER SIND. UNTER KEINEN UMSTÄNDEN SOLLTEN TIDY ODER PRO SCHÄDEN AN EINEM EINZELNEN BEWEGLICHEN OBJEKT ÜBER 1.000 $ ABDECKEN. TIDY VERSICHERT KEINE HÄUSER UND WIRD DAHER NUR EINE DURCHSCHNITTLICHE VERSION DES BESCHÄDIGTEN ARTIKELS ERSTATTEN ODER ERSETZEN. WENN SIE EINE REINIGUNG FÜR EIN HAUS MIT TEUREN GÜTERN BUCHEN, STIMMEN SIE AUSDRÜCKLICH ZU, DAS ÜBERMÄßIGE RISIKO SOLCHER TEUREN GÜTER ZU ÜBERNEHMEN. BEISPIELSWEISE, WENN SIE EIN SELTENES ODER TEURES EIGENTUM HABEN, DAS IM RAHMEN EINER ARBEIT BESCHÄDIGT WIRD, WIRD DIES WAHRSCHEINLICH NICHT DEN WERT DES EIGENTUMS ABDECKEN.'
    },
    'These TIDY Client Guarantee Terms will be interpreted in accordance with the laws of the State of California and the United States of America, without regard to its conflict-of-law provisions.': {
        pt: 'Estes Termos de Garantia do Cliente TIDY serão interpretados de acordo com as leis do Estado da Califórnia e dos Estados Unidos da América, sem considerar suas disposições de conflito de leis.',
        es: 'Estos Términos de Garantía del Cliente de TIDY se interpretarán de acuerdo con las leyes del Estado de California y de los Estados Unidos de América, sin tener en cuenta sus disposiciones sobre conflictos de leyes.',
        ru: 'Эти условия гарантии клиента TIDY будут толковаться в соответствии с законами штата Калифорния и Соединенных Штатов Америки, без учета его положений о коллизиях законов.',
        fr: 'Ces termes de garantie client TIDY seront interprétés conformément aux lois de l\'État de Californie et des États-Unis d\'Amérique, sans égard à ses dispositions sur les conflits de lois.',
        de: 'Diese TIDY-Kundengarantiebedingungen werden gemäß den Gesetzen des Staates Kalifornien und der Vereinigten Staaten von Amerika ausgelegt, ohne Rücksicht auf ihre kollisionsrechtlichen Bestimmungen.'
    },
    'By completing this form, you acknowledge that you have read and accept the policies outlined by TIDY regarding damage claims.': {
        pt: 'Ao preencher este formulário, você reconhece que leu e aceita as políticas delineadas pela TIDY sobre reclamações de danos.',
        es: 'Al completar este formulario, usted reconoce que ha leído y acepta las políticas descritas por TIDY respecto a reclamaciones de daños.',
        ru: 'Заполнив эту форму, вы подтверждаете, что прочитали и принимаете политику TIDY в отношении требований о повреждениях.',
        fr: 'En complétant ce formulaire, vous reconnaissez avoir lu et accepté les politiques décrites par TIDY concernant les réclamations pour dommages.',
        de: 'Durch das Ausfüllen dieses Formulars erkennen Sie an, dass Sie die von TIDY dargelegten Richtlinien zu Schadensansprüchen gelesen und akzeptiert haben.'
    },
    'Damage Detail': {
        pt: 'Detalhe do Dano',
        es: 'Detalle del Daño',
        ru: 'Детали повреждения',
        fr: 'Détail des Dommages',
        de: 'Schadensdetail'
    },
    'What did your Pro damage?': {
        pt: 'O que o seu Pro danificou?',
        es: '¿Qué dañó su Pro?',
        ru: 'Что повредил ваш Про?',
        fr: 'Qu\'a endommagé votre Pro?',
        de: 'Was hat Ihr Pro beschädigt?'
    },
    'Please describe what was damaged.': {
        pt: 'Por favor, descreva o que foi danificado.',
        es: 'Por favor describa lo que fue dañado.',
        ru: 'Пожалуйста, опишите, что было повреждено.',
        fr: 'Veuillez décrire ce qui a été endommagé.',
        de: 'Bitte beschreiben Sie, was beschädigt wurde.'
    },
    'How did your Pro damage it?': {
        pt: 'Como o seu Pro danificou isso?',
        es: '¿Cómo dañó su Pro?',
        ru: 'Как ваш Про это повредил?',
        fr: 'Comment votre Pro l\'a-t-il endommagé?',
        de: 'Wie hat Ihr Pro es beschädigt?'
    },
    'Please describe how it was damaged.': {
        pt: 'Por favor, descreva como foi danificado.',
        es: 'Por favor describa cómo fue dañado.',
        ru: 'Пожалуйста, опишите, как это было повреждено.',
        fr: 'Veuillez décrire comment cela a été endommagé.',
        de: 'Bitte beschreiben Sie, wie es beschädigt wurde.'
    },
    'Link to replacement item or estimate': {
        pt: 'Link para item de substituição ou estimativa',
        es: 'Enlace al artículo de reemplazo o estimación',
        ru: 'Ссылка на замену товара или оценку',
        fr: 'Lien vers l\'article de remplacement ou l\'estimation',
        de: 'Link zum Ersatzartikel oder zur Schätzung'
    },
    'Please enter a link or estimate.': {
        pt: 'Por favor, insira um link ou estimativa.',
        es: 'Por favor, introduzca un enlace o una estimación.',
        ru: 'Пожалуйста, введите ссылку или оценку.',
        fr: 'Veuillez entrer un lien ou une estimation.',
        de: 'Bitte geben Sie einen Link oder eine Schätzung ein.'
    },
    'How much are you seeking from the Pro?': {
        pt: 'Quanto você está buscando do Pro?',
        es: '¿Cuánto está buscando del Pro?',
        ru: 'Сколько вы хотите получить от Про?',
        fr: 'Combien demandez-vous au Pro?',
        de: 'Wie viel fordern Sie von dem Pro?'
    },
    'Next Steps': {
        pt: 'Próximos Passos',
        es: 'Próximos Pasos',
        ru: 'Следующие шаги',
        fr: 'Prochaines Étapes',
        de: 'Nächste Schritte'
    },
    'We contact the Pro.': {
        pt: 'Nós contatamos o Pro.',
        es: 'Contactamos al Pro.',
        ru: 'Мы связываемся с Про.',
        fr: 'Nous contactons le Pro.',
        de: 'Wir kontaktieren den Pro.'
    },
    'We reach out to the Pro on your behalf, and collect more information. Since the damage claim can be a substantial amount of money for the Pro, it is our responsibility to ensure that claims are responsibly considered.': {
        pt: 'Nós entramos em contato com o Pro em seu nome e coletamos mais informações. Uma vez que a reivindicação de danos pode ser uma quantia substancial de dinheiro para o Pro, é nossa responsabilidade garantir que as reivindicações sejam consideradas de forma responsável.',
        es: 'Nos ponemos en contacto con el Pro en su nombre y recopilamos más información. Dado que la reclamación de daños puede ser una cantidad sustancial de dinero para el Pro, es nuestra responsabilidad asegurar que las reclamaciones se consideren de manera responsable.',
        ru: 'Мы связываемся с Про от вашего имени и собираем дополнительную информацию. Поскольку претензия на ущерб может быть значительной суммой денег для Про, наша ответственность - убедиться, что претензии рассматриваются ответственно.',
        fr: 'Nous contactons le Pro en votre nom et recueillons plus d\'informations. Étant donné que la réclamation pour dommages peut représenter une somme substantielle pour le Pro, il est de notre responsabilité de veiller à ce que les réclamations soient considérées de manière responsable.',
        de: 'Wir kontaktieren den Pro in Ihrem Namen und sammeln weitere Informationen. Da der Schadensanspruch eine erhebliche Summe für den Pro darstellen kann, ist es unsere Verantwortung sicherzustellen, dass die Ansprüche verantwortungsvoll berücksichtigt werden.'
    },
    'Your claim is closed.': {
        pt: 'Sua reivindicação está fechada.',
        es: 'Su reclamación está cerrada.',
        ru: 'Ваша претензия закрыта.',
        fr: 'Votre réclamation est fermée.',
        de: 'Ihr Anspruch ist geschlossen.'
    },
    'If your claim is approved, we will offer TIDY credit while we collect from the Pro on your behalf.': {
        pt: 'Se sua reivindicação for aprovada, ofereceremos crédito TIDY enquanto coletamos do Pro em seu nome.',
        es: 'Si su reclamación es aprobada, ofreceremos crédito TIDY mientras recogemos del Pro en su nombre.',
        ru: 'Если ваша претензия будет одобрена, мы предложим кредит TIDY, пока собираем с Про от вашего имени.',
        fr: 'Si votre réclamation est approuvée, nous vous offrirons un crédit TIDY pendant que nous recueillons auprès du Pro en votre nom.',
        de: 'Wenn Ihr Anspruch genehmigt wird, bieten wir Ihnen TIDY-Guthaben an, während wir in Ihrem Namen vom Pro sammeln.'
    },
    'Submit & Agree To Terms': {
        pt: 'Enviar e Concordar com os Termos',
        es: 'Enviar y Aceptar los Términos',
        ru: 'Отправить и согласиться с условиями',
        fr: 'Soumettre et Accepter les Termes',
        de: 'Einreichen & Zustimmen zu den Bedingungen'
    },
    'Damage Claim Submitted': {
        pt: 'Reivindicação de Dano Submetida',
        es: 'Reclamación de Daños Presentada',
        ru: 'Претензия на ущерб подана',
        fr: 'Réclamation de Dommages Soumise',
        de: 'Schadensanspruch eingereicht'
    },
    'We have reached out to the Pro on your behalf to collect more information.': {
        pt: 'Nós entramos em contato com o Pro em seu nome para coletar mais informações.',
        es: 'Nos hemos puesto en contacto con el Pro en su nombre para recopilar más información.',
        ru: 'Мы связались с Про от вашего имени, чтобы собрать больше информации.',
        fr: 'Nous avons contacté le Pro en votre nom pour recueillir plus d\'informations.',
        de: 'Wir haben uns in Ihrem Namen mit dem Pro in Verbindung gesetzt, um weitere Informationen zu sammeln.'
    },

    //DisputeResolutionPage
    'Reject Proposal': {
        pt: 'Rejeitar Proposta',
        es: 'Rechazar Propuesta',
        ru: 'Отклонить предложение',
        fr: 'Rejeter la Proposition',
        de: 'Vorschlag ablehnen'
    },
    'Accept Proposal': {
        pt: 'Aceitar Proposta',
        es: 'Aceptar Propuesta',
        ru: 'Принять предложение',
        fr: 'Accepter la Proposition',
        de: 'Vorschlag annehmen'
    },

    //GetHelpPage
    'What can we help with?': {
        pt: 'Com o que podemos ajudar?',
        es: '¿En qué podemos ayudarte?',
        ru: 'Чем мы можем помочь?',
        fr: 'Comment pouvons-nous aider?',
        de: 'Wie können wir helfen?'
    },
    'Create Concierge Action': {
        pt: 'Criar Ação de Concierge',
        es: 'Crear Acción de Conserje',
        ru: 'Создать действие консьержа',
        fr: 'Créer une Action de Conciergerie',
        de: 'Concierge-Aktion erstellen'
    },
    'Ask concierge to do something for you.': {
        pt: 'Peça ao concierge para fazer algo por você.',
        es: 'Pide al conserje que haga algo por ti.',
        ru: 'Попросите консьержа сделать что-то для вас.',
        fr: 'Demandez au concierge de faire quelque chose pour vous.',
        de: 'Bitten Sie den Concierge, etwas für Sie zu tun.'
    },

    //JobIssuePage

    //JobIssueDetailPage
    'Other Job Issue': {
        pt: 'Outra Questão de Trabalho',
        es: 'Otro Problema Laboral',
        ru: 'Другая Проблема на Работе',
        fr: 'Autre Problème de Travail',
        de: 'Anderes Arbeitsproblem'
    },

    //JobIssueNegativeReviewPage
    'was marked as complete by the pro': {
        pt: 'foi marcado como concluído pelo profissional',
        es: 'fue marcado como completado por el profesional',
        ru: 'было отмечено как завершенное профессионалом',
        fr: 'a été marqué comme complété par le pro',
        de: 'wurde vom Profi als abgeschlossen markiert',
    },

    //ConciergePage
    'My Rules': {
        pt: 'Minhas Regras',
        es: 'Mis Reglas',
        ru: 'Мои Правила',
        fr: 'Mes Règles',
        de: 'Meine Regeln'
    },
    'How Concierge & AI should work:': {
        pt: 'Como o Concierge & AI deve funcionar:',
        es: 'Cómo deben funcionar el Conserje & AI:',
        ru: 'Как должны работать Консьерж & AI:',
        fr: 'Comment le Concierge & AI doivent fonctionner :',
        de: 'Wie Concierge & AI funktionieren sollten:'
    },
    'No Rules': {
        pt: 'Sem Regras',
        es: 'Sin Reglas',
        ru: 'Нет Правил',
        fr: 'Pas de Règles',
        de: 'Keine Regeln'
    },
    'Suggestions': {
        pt: 'Sugestões',
        es: 'Sugerencias',
        ru: 'Предложения',
        fr: 'Suggestions',
        de: 'Vorschläge'
    },
    'Account set up recommendations from TIDY:': {
        pt: 'Recomendações de configuração de conta da TIDY:',
        es: 'Recomendaciones de configuración de cuenta de TIDY:',
        ru: 'Рекомендации по настройке аккаунта от TIDY:',
        fr: 'Recommandations de configuration de compte de TIDY:',
        de: 'Kontoeinrichtungsempfehlungen von TIDY:'
    },
    'No Suggestions': {
        pt: 'Sem Sugestões',
        es: 'Sin Sugerencias',
        ru: 'Нет Предложений',
        fr: 'Pas de Suggestions',
        de: 'Keine Vorschläge'
    },
    'Concierge Actions': {
        pt: 'Ações de Concierge',
        es: 'Acciones de Conserje',
        ru: 'Действия Консьержа',
        fr: 'Actions de Concierge',
        de: 'Concierge-Aktionen'
    },
    'Things you had us do on your behalf under your direction:': {
        pt: 'Coisas que você nos pediu para fazer em seu nome sob sua direção:',
        es: 'Cosas que nos pediste hacer en tu nombre bajo tu dirección:',
        ru: 'Вещи, которые вы просили нас делать от вашего имени под вашим руководством:',
        fr: 'Les choses que vous nous avez demandées de faire en votre nom sous votre direction :',
        de: 'Dinge, die Sie uns beauftragt haben, in Ihrem Namen unter Ihrer Anleitung zu tun:'
    },
    'No Actions': {
        pt: 'Sem Ações',
        es: 'Sin Acciones',
        ru: 'Нет Действий',
        fr: 'Pas d\'Actions',
        de: 'Keine Aktionen'
    },
    'Account set up or troubleshooting requests from our team:': {
        pt: 'Solicitações de configuração de conta ou resolução de problemas de nossa equipe:',
        es: 'Solicitudes de configuración de cuenta o resolución de problemas de nuestro equipo:',
        ru: 'Запросы на настройку аккаунта или устранение неполадок от нашей команды:',
        fr: 'Demandes de configuration de compte ou de dépannage de notre équipe :',
        de: 'Anfragen zur Kontoeinrichtung oder zur Fehlerbehebung von unserem Team:'
    },
    'No Requests': {
        pt: 'Sem Solicitações',
        es: 'Sin Solicitudes',
        ru: 'Нет Запросов',
        fr: 'Pas de Demandes',
        de: 'Keine Anfragen'
    },

    //ConfirmPage

    //ConfirmBookingPaymentPage
    'Top Ranked on Google!': {
        pt: 'Top no Google!',
        es: '¡Top en Google!',
        ru: 'Топ в Google!',
        fr: 'Top sur Google!',
        de: 'Top auf Google!'
    },
    'Amazing job! Her thoroughness was superb. The little things made it all the better (like refilling my keurig pod stand!) I am very impressed! - Dawn R.': {
        pt: 'Trabalho incrível! Sua meticulosidade foi excelente. Os pequenos detalhes tornaram tudo ainda melhor (como reabastecer meu suporte de cápsulas keurig!) Estou muito impressionado! - Dawn R.',
        es: '¡Trabajo asombroso! Su minuciosidad fue excelente. Los pequeños detalles lo hicieron todo mejor (¡como rellenar mi soporte de cápsulas keurig!) Estoy muy impresionado! - Dawn R.',
        ru: 'Потрясающая работа! Её тщательность была великолепна. Мелочи сделали всё ещё лучше (например, пополнение моей подставки для капсул keurig!) Я очень впечатлён! - Dawn R.',
        fr: 'Travail incroyable! Son approfondissement était superbe. Les petites choses ont rendu tout cela encore meilleur (comme le remplissage de mon support de dosettes keurig!) Je suis très impressionné! - Dawn R.',
        de: 'Tolle Arbeit! Ihre Gründlichkeit war hervorragend. Die kleinen Dinge machten alles noch besser (wie das Nachfüllen meines Keurig-Podständers!) Ich bin sehr beeindruckt! - Dawn R.'
    },
    '1,000+ Verified Reviews': {
        pt: 'Mais de 1.000 Avaliações Verificadas',
        es: 'Más de 1.000 Reseñas Verificadas',
        ru: 'Более 1 000 Проверенных Отзывов',
        fr: 'Plus de 1 000 Avis Vérifiés',
        de: 'Über 1.000 Verifizierte Bewertungen'
    },
    '4.5 Stars': {
        pt: '4,5 Estrelas',
        es: '4.5 Estrellas',
        ru: '4.5 Звезды',
        fr: '4,5 Étoiles',
        de: '4,5 Sterne'
    },
    'Saving': {
        pt: 'Economizando',
        es: 'Ahorro',
        ru: 'Экономия',
        fr: 'Économie',
        de: 'Sparen'
    },
    'with your': {
        pt: 'com seu',
        es: 'con tu',
        ru: 'с вашим',
        fr: 'avec votre',
        de: 'mit Ihrem'
    },
    'subscription!': {
        pt: 'assinatura!',
        es: 'suscripción!',
        ru: 'подписка!',
        fr: 'abonnement!',
        de: 'Abonnement!'
    },
    'Earliest:': {
        pt: 'Mais cedo:',
        es: 'Lo más pronto:',
        ru: 'Самое раннее:',
        fr: 'Le plus tôt:',
        de: 'Frühestens:'
    },
    'Latest:': {
        pt: 'Mais tarde:',
        es: 'Último:',
        ru: 'Последний:',
        fr: 'Dernier:',
        de: 'Spätestens:'
    },
    'for': {
        pt: 'para',
        es: 'para',
        ru: 'для',
        fr: 'pour',
        de: 'für'
    },
    'Saving 20% with recurring plan': {
        pt: 'Economizando 20% com plano recorrente',
        es: 'Ahorro del 20% con plan recurrente',
        ru: 'Экономия 20% с повторяющимся планом',
        fr: 'Économie de 20% avec le plan récurrent',
        de: '20% sparen mit wiederkehrendem Plan'
    },
    'gift code applied': {
        pt: 'código de presente aplicado',
        es: 'código de regalo aplicado',
        ru: 'применён подарочный код',
        fr: 'code cadeau appliqué',
        de: 'Geschenkcode angewendet'
    },
    'Instructions:': {
        pt: 'Instruções:',
        es: 'Instrucciones:',
        ru: 'Инструкции:',
        fr: 'Instructions:',
        de: 'Anweisungen:'
    },
    'that will automatically be used for this job.': {
        pt: 'que será automaticamente usado para este trabalho.',
        es: 'que se utilizará automáticamente para este trabajo.',
        ru: 'который будет автоматически использован для этой работы.',
        fr: 'qui sera automatiquement utilisé pour ce travail.',
        de: 'der automatisch für diesen Job verwendet wird.'
    },
    ', enough to pay for this job in full. We still require a card on file but you will not be charged.': {
        pt: ', suficiente para pagar este trabalho integralmente. Ainda exigimos um cartão em arquivo, mas você não será cobrado.',
        es: ', suficiente para pagar este trabajo en su totalidad. Todavía requerimos una tarjeta en archivo, pero no se te cobrará.',
        ru: ', достаточно, чтобы полностью оплатить эту работу. Мы все еще требуем карту на файл, но с вас не будет взиматься плата.',
        fr: ', suffisant pour payer ce travail en totalité. Nous exigeons toujours une carte en fichier mais vous ne serez pas facturé.',
        de: ', genug, um diesen Job vollständig zu bezahlen. Wir benötigen immer noch eine Karte auf Datei, aber es wird Ihnen nichts berechnet.'
    },
    'How do you want to pay?': {
        pt: 'Como você quer pagar?',
        es: '¿Cómo quieres pagar?',
        ru: 'Как вы хотите платить?',
        fr: 'Comment voulez-vous payer?',
        de: 'Wie möchten Sie bezahlen?'
    },
    'Charge in advance (save additional 10%)': {
        pt: 'Cobrar antecipadamente (economize 10% adicionais)',
        es: 'Cobrar por adelantado (ahorra un 10% adicional)',
        ru: 'Взимать заранее (экономия дополнительных 10%)',
        fr: 'Facturer à l\'avance (économisez 10% supplémentaires)',
        de: 'Vorauszahlung (zusätzlich 10% sparen)'
    },
    'Charge after job': {
        pt: 'Cobrar após o trabalho',
        es: 'Cobrar después del trabajo',
        ru: 'Взимать плату после работы',
        fr: 'Facturer après le travail',
        de: 'Nach der Arbeit berechnen'
    },
    '*Note: You selected times today. If a Pro accepts a time today the job price will be': {
        pt: '*Nota: Você selecionou horários hoje. Se um profissional aceitar um horário hoje, o preço do trabalho será',
        es: '*Nota: Ha seleccionado tiempos hoy. Si un profesional acepta un tiempo hoy, el precio del trabajo será',
        ru: '*Примечание: Вы выбрали время сегодня. Если профессионал примет время сегодня, цена работы будет',
        fr: '*Note: Vous avez sélectionné des horaires aujourd\'hui. Si un pro accepte un horaire aujourd\'hui, le prix du travail sera',
        de: '*Hinweis: Sie haben heute Zeiten ausgewählt. Wenn ein Profi heute eine Zeit akzeptiert, wird der Arbeitspreis sein'
    },
    'Enter Card Information': {
        pt: 'Insira as Informações do Cartão',
        es: 'Ingrese la Información de la Tarjeta',
        ru: 'Введите информацию о карте',
        fr: 'Entrez les Informations de la Carte',
        de: 'Geben Sie die Karteninformationen ein'
    },
    'Add Referral / Gift Code': {
        pt: 'Adicionar Código de Referência / Presente',
        es: 'Agregar Código de Referencia / Regalo',
        ru: 'Добавить реферальный / подарочный код',
        fr: 'Ajouter un Code de Parrainage / Cadeau',
        de: 'Empfehlungs-/Geschenkcode hinzufügen'
    },
    'Referral/Gift Code': {
        pt: 'Código de Referência/Presente',
        es: 'Código de Referencia/Regalo',
        ru: 'Реферальный/Подарочный код',
        fr: 'Code de Parrainage/Cadeau',
        de: 'Empfehlungs-/Geschenkcode'
    },
    'Code successfully applied!': {
        pt: 'Código aplicado com sucesso!',
        es: '¡Código aplicado con éxito!',
        ru: 'Код успешно применен!',
        fr: 'Code appliqué avec succès!',
        de: 'Code erfolgreich angewendet!'
    },
    'We apply credit from your referral/gift code first before charging. If your code covers the full cleaning, you won\'t be charged at all. For the safety of your booked cleaning person, we do require a card on file.': {
        pt: 'Aplicamos o crédito do seu código de referência/presente antes de cobrar. Se o seu código cobrir a limpeza completa, você não será cobrado. Para a segurança da pessoa que fará a limpeza, exigimos um cartão em arquivo.',
        es: 'Aplicamos el crédito de su código de referencia/regalo antes de cobrar. Si su código cubre la limpieza completa, no se le cobrará en absoluto. Para la seguridad de la persona de limpieza reservada, requerimos una tarjeta en archivo.',
        ru: 'Мы применяем кредит с вашего реферального/подарочного кода перед началом начисления. Если ваш код покрывает полную уборку, с вас не будет взиматься плата. Для безопасности вашего уборщика мы требуем наличие карты на файле.',
        fr: 'Nous appliquons le crédit de votre code de parrainage/cadeau avant de facturer. Si votre code couvre le nettoyage complet, vous ne serez pas facturé du tout. Pour la sécurité de votre personne de nettoyage réservée, nous exigeons une carte en fichier.',
        de: 'Wir wenden zuerst das Guthaben Ihres Empfehlungs-/Geschenkcodes an, bevor wir berechnen. Wenn Ihr Code die vollständige Reinigung abdeckt, werden Ihnen keine Kosten berechnet. Zur Sicherheit Ihrer gebuchten Reinigungskraft benötigen wir jedoch eine Karte in der Datei.'
    },
    'Enter Contact Detail': {
        pt: 'Insira o Detalhe de Contato',
        es: 'Ingrese el Detalle de Contacto',
        ru: 'Введите контактные данные',
        fr: 'Entrez les Détails de Contact',
        de: 'Kontaktdetails eingeben'
    },
    'First Name': {
        pt: 'Nome',
        es: 'Nombre',
        ru: 'Имя',
        fr: 'Prénom',
        de: 'Vorname'
    },
    'Last Name': {
        pt: 'Sobrenome',
        es: 'Apellido',
        ru: 'Фамилия',
        fr: 'Nom de famille',
        de: 'Nachname'
    },
    'Please enter your last name.': {
        pt: 'Por favor, insira seu sobrenome.',
        es: 'Por favor, ingrese su apellido.',
        ru: 'Пожалуйста, введите вашу фамилию.',
        fr: 'Veuillez entrer votre nom de famille.',
        de: 'Bitte geben Sie Ihren Nachnamen ein.'
    },
    'Email': {
        pt: 'E-mail',
        es: 'Correo electrónico',
        ru: 'Электронная почта',
        fr: 'E-mail',
        de: 'E-Mail'
    },
    'Please enter a valid phone.': {
        pt: 'Por favor, insira um telefone válido.',
        es: 'Por favor, ingrese un teléfono válido.',
        ru: 'Пожалуйста, введите действительный телефонный номер.',
        fr: 'Veuillez entrer un numéro de téléphone valide.',
        de: 'Bitte geben Sie eine gültige Telefonnummer ein.'
    },
    'I still agree to the <b>terms</b>': {
        pt: 'Eu ainda concordo com os <b>termos</b>',
        es: 'Todavía estoy de acuerdo con los <b>términos</b>',
        ru: 'Я все еще согласен с <b>условиями</b>',
        fr: 'Je suis toujours d\'accord avec les <b>conditions</b>',
        de: 'Ich stimme den <b>Bedingungen</b> weiterhin zu'
    },
    'You must agree to the terms to continue.': {
        pt: 'Você deve concordar com os termos para continuar.',
        es: 'Debe aceptar los términos para continuar.',
        ru: 'Вы должны согласиться с условиями, чтобы продолжить.',
        fr: 'Vous devez accepter les conditions pour continuer.',
        de: 'Sie müssen den Bedingungen zustimmen, um fortzufahren.'
    },
    'If you are unhappy with your first cleaning for any reason, get a new cleaning for free.': {
        pt: 'Se você não estiver satisfeito com sua primeira limpeza por qualquer motivo, obtenha uma nova limpeza gratuitamente.',
        es: 'Si no está satisfecho con su primera limpieza por cualquier motivo, obtenga una nueva limpieza gratis.',
        ru: 'Если вы недовольны своей первой уборкой по какой-либо причине, получите новую уборку бесплатно.',
        fr: 'Si vous n\'êtes pas satisfait de votre premier nettoyage pour quelque raison que ce soit, obtenez un nouveau nettoyage gratuitement.',
        de: 'Wenn Sie mit Ihrer ersten Reinigung aus irgendeinem Grund unzufrieden sind, erhalten Sie eine neue Reinigung kostenlos.'
    },
    'Cancel Any Time': {
        pt: 'Cancelar a Qualquer Momento',
        es: 'Cancelar en Cualquier Momento',
        ru: 'Отменить в любое время',
        fr: 'Annuler à Tout Moment',
        de: 'Jederzeit kündigen'
    },
    'Most pros ask 24 hours notice.': {
        pt: 'A maioria dos profissionais pede um aviso de 24 horas.',
        es: 'La mayoría de los profesionales piden un aviso de 24 horas.',
        ru: 'Большинство профессионалов просят уведомление за 24 часа.',
        fr: 'La plupart des professionnels demandent un préavis de 24 heures.',
        de: 'Die meisten Profis bitten um 24 Stunden Vorankündigung.'
    },
    'What\'s Included?': {
        pt: 'O que está incluído?',
        es: '¿Qué está incluido?',
        ru: 'Что включено?',
        fr: 'Qu\'est-ce qui est inclus?',
        de: 'Was ist enthalten?'
    },
    'All cleanings are completely customized by you, they can include anything you like!': {
        pt: 'Todas as limpezas são completamente personalizadas por você, podem incluir qualquer coisa que você goste!',
        es: '¡Todas las limpiezas están completamente personalizadas por ti, pueden incluir cualquier cosa que te guste!',
        ru: 'Все уборки полностью настраиваются вами, они могут включать все, что вам нравится!',
        fr: 'Tous les nettoyages sont entièrement personnalisés par vous, ils peuvent inclure tout ce que vous aimez!',
        de: 'Alle Reinigungen sind komplett von Ihnen angepasst, sie können alles beinhalten, was Sie mögen!'
    },
    'You selected a which is great for light or very focused cleanings.': {
        pt: 'Você selecionou uma opção que é ótima para limpezas leves ou muito focadas.',
        es: 'Has seleccionado una opción que es excelente para limpiezas ligeras o muy enfocadas.',
        ru: 'Вы выбрали вариант, который отлично подходит для легких или очень фокусированных уборок.',
        fr: 'Vous avez sélectionné une option qui est idéale pour les nettoyages légers ou très ciblés.',
        de: 'Sie haben eine Option gewählt, die sich hervorragend für leichte oder sehr fokussierte Reinigungen eignet.'
    },
    'which is great for a deeper cleaning of a small home, or a reasonable cleaning in most rooms a 2 bedroom home.': {
        pt: 'que é ótimo para uma limpeza mais profunda de uma casa pequena, ou uma limpeza razoável na maioria dos quartos de uma casa de 2 quartos.',
        es: 'que es excelente para una limpieza más profunda de una casa pequeña, o una limpieza razonable en la mayoría de las habitaciones de una casa de 2 dormitorios.',
        ru: 'что отлично подходит для более глубокой уборки небольшого дома или разумной уборки в большинстве комнат дома с двумя спальнями.',
        fr: 'ce qui est idéal pour un nettoyage en profondeur d\'une petite maison, ou un nettoyage raisonnable dans la plupart des pièces d\'une maison de 2 chambres.',
        de: 'was großartig für eine tiefere Reinigung eines kleinen Hauses oder eine angemessene Reinigung in den meisten Räumen eines 2-Schlafzimmer-Hauses ist.'
    },
    'which is great for most homes, if you need a load or 2 of laundry done, and gives enough time to get to most To Dos in a 3 bedroom home.': {
        pt: 'que é ótimo para a maioria das casas, se você precisar lavar uma ou duas cargas de roupa, e dá tempo suficiente para realizar a maioria das tarefas em uma casa de 3 quartos.',
        es: 'que es excelente para la mayoría de los hogares, si necesitas lavar una o dos cargas de ropa, y da tiempo suficiente para llegar a la mayoría de las tareas en una casa de 3 dormitorios.',
        ru: 'что отлично подходит для большинства домов, если вам нужно постирать одну или две загрузки белья, и дает достаточно времени, чтобы справиться с большинством задач в доме с тремя спальнями.',
        fr: 'ce qui est idéal pour la plupart des maisons, si vous avez besoin de faire une ou deux lessives, et donne suffisamment de temps pour accomplir la plupart des tâches dans une maison de 3 chambres.',
        de: 'was für die meisten Häuser großartig ist, wenn Sie eine oder zwei Ladungen Wäsche waschen müssen, und genügend Zeit bietet, um die meisten Aufgaben in einem 3-Schlafzimmer-Haus zu erledigen.'
    },
    'which is great for deep cleanings, move in / move out cleanings, and also can be great to jump start a clean home and then follow it up with a different recurring cleaning.': {
        pt: 'que é ótimo para limpezas profundas, mudanças de entrada / saída, e também pode ser ótimo para iniciar uma casa limpa e depois continuar com uma limpeza recorrente diferente.',
        es: 'que es excelente para limpiezas profundas, mudanzas de entrada / salida, y también puede ser excelente para comenzar con una casa limpia y luego seguir con una limpieza recurrente diferente.',
        ru: 'что отлично подходит для глубоких уборок, переездов на въезд / выезд, а также может быть отличным для начала уборки в чистом доме, а затем продолжения с другой повторяющейся уборкой.',
        fr: 'ce qui est idéal pour les nettoyages en profondeur, les déménagements entrants / sortants, et peut également être excellent pour démarrer une maison propre puis poursuivre avec un autre nettoyage récurrent.',
        de: 'was großartig für gründliche Reinigungen, Ein- / Auszugsreinigungen ist und auch großartig sein kann, um ein sauberes Zuhause zu starten und dann mit einer anderen wiederkehrenden Reinigung fortzufahren.'
    },
    'People commonly request things like the following, but it can really be anything! Examples of what you can pick:': {
        pt: 'As pessoas geralmente solicitam coisas como as seguintes, mas pode ser realmente qualquer coisa! Exemplos do que você pode escolher:',
        es: 'La gente comúnmente solicita cosas como las siguientes, ¡pero realmente puede ser cualquier cosa! Ejemplos de lo que puedes elegir:',
        ru: 'Люди обычно запрашивают вещи, подобные следующим, но это может быть действительно что угодно! Примеры того, что вы можете выбрать:',
        fr: 'Les gens demandent couramment des choses comme les suivantes, mais cela peut vraiment être n\'importe quoi! Exemples de ce que vous pouvez choisir:',
        de: 'Menschen fragen häufig nach Dingen wie den folgenden, aber es kann wirklich alles sein! Beispiele, was Sie wählen können:'
    },
    '<strong>Bedroom, Living Room & Common Areas</strong>': {
        pt: '<strong>Quarto, Sala de Estar & Áreas Comuns</strong>',
        es: '<strong>Dormitorio, Sala de Estar y Áreas Comunes</strong>',
        ru: '<strong>Спальня, Гостиная & Общие Зоны</strong>',
        fr: '<strong>Chambre, Salon & Zones Communes</strong>',
        de: '<strong>Schlafzimmer, Wohnzimmer & Gemeinschaftsbereiche</strong>'
    },
    '<li>Dust accessible surfaces</li>': {
        pt: '<li>Limpar superfícies acessíveis</li>',
        es: '<li>Limpiar superficies accesibles</li>',
        ru: '<li>Протирать доступные поверхности</li>',
        fr: '<li>Nettoyer les surfaces accessibles</li>',
        de: '<li>Zugängliche Oberflächen abstauben</li>'
    },
    '<li>Wipe down mirrors and glass fixtures</li>': {
        pt: '<li>Limpar espelhos e vidros</li>',
        es: '<li>Limpiar espejos y accesorios de vidrio</li>',
        ru: '<li>Протирать зеркала и стеклянные приборы</li>',
        fr: '<li>Nettoyer les miroirs et les installations en verre</li>',
        de: '<li>Spiegel und Glasoberflächen abwischen</li>'
    },
    '<li>Wipe down interior windows</li>': {
        pt: '<li>Limpar janelas internas</li>',
        es: '<li>Limpiar ventanas interiores</li>',
        ru: '<li>Протирать внутренние окна</li>',
        fr: '<li>Nettoyer les fenêtres intérieures</li>',
        de: '<li>Innenfenster abwischen</li>'
    },
    '<li>Run laundry & dryer</li>': {
        pt: '<li>Lavar e secar roupas</li>',
        es: '<li>Lavar y secar la ropa</li>',
        ru: '<li>Стирка и сушка белья</li>',
        fr: '<li>Faire la lessive et utiliser le sèche-linge</li>',
        de: '<li>Wäsche waschen & trocknen</li>'
    },
    '<li>Mop & vacuum floors</li>': {
        pt: '<li>Passar pano e aspirar o chão</li>',
        es: '<li>Fregar y aspirar suelos</li>',
        ru: '<li>Мыть и пылесосить полы</li>',
        fr: '<li>Laver et aspirer les sols</li>',
        de: '<li>Böden wischen & staubsaugen</li>'
    },
    '<li>Take out garbage</li>': {
        pt: '<li>Retirar o lixo</li>',
        es: '<li>Sacar la basura</li>',
        ru: '<li>Выносить мусор</li>',
        fr: '<li>Sortir les poubelles</li>',
        de: '<li>Müll herausbringen</li>'
    },
    '<strong>Bathroom Cleaning</strong>': {
        pt: '<strong>Limpeza de Banheiro</strong>',
        es: '<strong>Limpieza de Baños</strong>',
        ru: '<strong>Уборка в Ванной</strong>',
        fr: '<strong>Nettoyage de Salle de Bain</strong>',
        de: '<strong>Badezimmerreinigung</strong>'
    },
    '<li>Wash and sanitize the toilet, shower, tub and sink</li>': {
        pt: '<li>Lavar e higienizar o vaso sanitário, chuveiro, banheira e pia</li>',
        es: '<li>Lavar y desinfectar el inodoro, ducha, bañera y lavabo</li>',
        ru: '<li>Мыть и дезинфицировать туалет, душ, ванну и раковину</li>',
        fr: '<li>Laver et désinfecter les toilettes, la douche, la baignoire et le lavabo</li>',
        de: '<li>Toilette, Dusche, Badewanne und Waschbecken waschen und desinfizieren</li>'
    },
    '<li>Clean inside cabinets</li>': {
        pt: '<li>Limpar o interior dos armários</li>',
        es: '<li>Limpiar el interior de los armarios</li>',
        ru: '<li>Чистить внутри шкафов</li>',
        fr: '<li>Nettoyer l\'intérieur des placards</li>',
        de: '<li>Innenseiten der Schränke reinigen</li>'
    },
    '<strong>Kitchen Cleaning</strong>': {
        pt: '<strong>Limpeza de Cozinha</strong>',
        es: '<strong>Limpieza de Cocina</strong>',
        ru: '<strong>Уборка на Кухне</strong>',
        fr: '<strong>Nettoyage de Cuisine</strong>',
        de: '<strong>Küchenreinigung</strong>'
    },
    '<li>Empty sink and load up dishwasher</li>': {
        pt: '<li>Esvaziar a pia e carregar a lava-louças</li>',
        es: '<li>Vaciar el fregadero y cargar el lavavajillas</li>',
        ru: '<li>Опорожнить раковину и загрузить посудомоечную машину</li>',
        fr: '<li>Vider l\'évier et charger le lave-vaisselle</li>',
        de: '<li>Spüle leeren und Geschirrspüler beladen</li>'
    },
    '<li>Wipe down exterior of stove, oven and fridge</li>': {
        pt: '<li>Limpar o exterior do fogão, forno e geladeira</li>',
        es: '<li>Limpiar el exterior de la estufa, horno y refrigerador</li>',
        ru: '<li>Протирать внешнюю часть плиты, духовки и холодильника</li>',
        fr: '<li>Nettoyer l\'extérieur de la cuisinière, du four et du réfrigérateur</li>',
        de: '<li>Äußere Teile von Herd, Ofen und Kühlschrank abwischen</li>'
    },
    '<li>Clean inside fridge</li>': {
        pt: '<li>Limpar o interior da geladeira</li>',
        es: '<li>Limpiar el interior del refrigerador</li>',
        ru: '<li>Чистить внутри холодильника</li>',
        fr: '<li>Nettoyer l\'intérieur du réfrigérateur</li>',
        de: '<li>Innenraum des Kühlschranks reinigen</li>'
    },
    '<li>Clean inside oven</li>': {
        pt: '<li>Limpar o interior do forno</li>',
        es: '<li>Limpiar el interior del horno</li>',
        ru: '<li>Чистить внутри духовки</li>',
        fr: '<li>Nettoyer l\'intérieur du four</li>',
        de: '<li>Innenraum des Ofens reinigen</li>'
    },
    '<li>Take out garbage and recycling</li>': {
        pt: '<li>Retirar o lixo e a reciclagem</li>',
        es: '<li>Sacar la basura y el reciclaje</li>',
        ru: '<li>Выносить мусор и отходы для переработки</li>',
        fr: '<li>Sortir les poubelles et le recyclage</li>',
        de: '<li>Müll und Recycling herausbringen</li>'
    },
    'Need Alternate Agreement?': {
        pt: 'Precisa de um Acordo Alternativo?',
        es: '¿Necesita un Acuerdo Alternativo?',
        ru: 'Нужно альтернативное соглашение?',
        fr: 'Besoin d\'un Accord Alternatif?',
        de: 'Benötigen Sie eine alternative Vereinbarung?'
    },
    'If you need an alternate agreement or custom compliance steps, contact sales@tidy.com instead.': {
        pt: 'Se você precisar de um acordo alternativo ou etapas de conformidade personalizadas, entre em contato com sales@tidy.com.',
        es: 'Si necesita un acuerdo alternativo o pasos de cumplimiento personalizados, contacte a sales@tidy.com.',
        ru: 'Если вам нужно альтернативное соглашение или индивидуальные шаги по соблюдению, свяжитесь с sales@tidy.com.',
        fr: 'Si vous avez besoin d\'un accord alternatif ou d\'étapes de conformité personnalisées, contactez sales@tidy.com.',
        de: 'Wenn Sie eine alternative Vereinbarung oder benutzerdefinierte Compliance-Schritte benötigen, kontaktieren Sie sales@tidy.com.'
    },
    'Your card was declined again. Please try another card.': {
        pt: 'Seu cartão foi recusado novamente. Por favor, tente outro cartão.',
        es: 'Su tarjeta ha sido rechazada de nuevo. Por favor, intente con otra tarjeta.',
        ru: 'Ваша карта снова отклонена. Пожалуйста, попробуйте другую карту.',
        fr: 'Votre carte a été refusée à nouveau. Veuillez essayer une autre carte.',
        de: 'Ihre Karte wurde erneut abgelehnt. Bitte versuchen Sie es mit einer anderen Karte.'
    },

    //CreateAccountPage
    'Sign Up': {
        pt: 'Inscrever-se',
        es: 'Registrarse',
        ru: 'Зарегистрироваться',
        fr: 'S\'inscrire',
        de: 'Anmelden'
    },
    'Instantly Book': {
        pt: 'Reserva Imediata',
        es: 'Reserva Instantánea',
        ru: 'Мгновенное бронирование',
        fr: 'Réserver Instantanément',
        de: 'Sofort Buchen'
    },
    'uses TIDY to let you book & reschedule appointments online. It\\’s free for you to use.': {
        pt: 'usa TIDY para permitir que você reserve e reagende compromissos online. É grátis para você usar.',
        es: 'usa TIDY para permitirte reservar y reprogramar citas en línea. Es gratis para que lo uses.',
        ru: 'использует TIDY, чтобы вы могли бронировать и переносить встречи онлайн. Это бесплатно для вас.',
        fr: 'utilise TIDY pour vous permettre de réserver et de replanifier des rendez-vous en ligne. C\\’est gratuit pour vous.',
        de: 'verwendet TIDY, um Ihnen zu ermöglichen, Termine online zu buchen und umzuplanen. Es ist kostenlos für Sie.'
    },
    'Password': {
        pt: 'Senha',
        es: 'Contraseña',
        ru: 'Пароль',
        fr: 'Mot de passe',
        de: 'Passwort'
    },
    'Password enter a password.': {
        pt: 'Senha insira uma senha.',
        es: 'Contraseña ingrese una contraseña.',
        ru: 'Пароль введите пароль.',
        fr: 'Mot de passe entrez un mot de passe.',
        de: 'Passwort geben Sie ein Passwort ein.'
    },
    'What are you using us for?': {
        pt: 'Para que você está nos usando?',
        es: '¿Para qué nos estás usando?',
        ru: 'Для чего вы нас используете?',
        fr: 'Pourquoi nous utilisez-vous?',
        de: 'Wofür verwenden Sie uns?'
    },
    'Please select an option': {
        pt: 'Por favor, selecione uma opção',
        es: 'Por favor seleccione una opción',
        ru: 'Пожалуйста, выберите опцию',
        fr: 'Veuillez sélectionner une option',
        de: 'Bitte wählen Sie eine Option'
    },
    'Are you a property manager or property owner?': {
        pt: 'Você é um gerente de propriedade ou proprietário?',
        es: '¿Eres un administrador de propiedades o un propietario?',
        ru: 'Вы управляющий недвижимостью или владелец?',
        fr: 'Êtes-vous un gestionnaire de propriété ou un propriétaire?',
        de: 'Sind Sie ein Immobilienverwalter oder Eigentümer?'
    },
    'Please select an answer.': {
        pt: 'Por favor, selecione uma resposta.',
        es: 'Por favor seleccione una respuesta.',
        ru: 'Пожалуйста, выберите ответ.',
        fr: 'Veuillez sélectionner une réponse.',
        de: 'Bitte wählen Sie eine Antwort.'
    },
    'How many rental properties do you manage?': {
        pt: 'Quantos imóveis para aluguel você gerencia?',
        es: '¿Cuántas propiedades de alquiler gestionas?',
        ru: 'Сколько объектов арендной недвижимости вы управляете?',
        fr: 'Combien de propriétés locatives gérez-vous?',
        de: 'Wie viele Mietobjekte verwalten Sie?'
    },
    'Please enter the number of properties.': {
        pt: 'Por favor, insira o número de propriedades.',
        es: 'Por favor, introduzca el número de propiedades.',
        ru: 'Пожалуйста, введите количество объектов.',
        fr: 'Veuillez entrer le nombre de propriétés.',
        de: 'Bitte geben Sie die Anzahl der Immobilien ein.'
    },
    'What is the name of your company? (optional)': {
        pt: 'Qual é o nome da sua empresa? (opcional)',
        es: '¿Cuál es el nombre de tu empresa? (opcional)',
        ru: 'Как называется ваша компания? (необязательно)',
        fr: 'Quel est le nom de votre entreprise ? (facultatif)',
        de: 'Wie lautet der Name Ihres Unternehmens? (optional)'
    },
    'I agree to the ': {
        pt: 'Eu concordo com o ',
        es: 'Estoy de acuerdo con el ',
        ru: 'Я согласен с ',
        fr: 'Je suis d\'accord avec le ',
        de: 'Ich stimme dem '
    },
    ', which includes agreeing to individual arbitration.*': {
        pt: ', que inclui concordar com a arbitragem individual.*',
        es: ', que incluye aceptar el arbitraje individual.*',
        ru: ', что включает согласие на индивидуальное арбитражное разбирательство.*',
        fr: ', ce qui inclut l\'acceptation de l\'arbitrage individuel.*',
        de: ', was die Zustimmung zu einer individuellen Schiedsgerichtsbarkeit beinhaltet.*'
    },
    'terms': {
        pt: 'termos',
        es: 'términos',
        ru: 'условия',
        fr: 'conditions',
        de: 'Bedingungen'
    },
    'I own the properties': {
        pt: 'Eu possuo as propriedades',
        es: 'Poseo las propiedades',
        ru: 'Я владею недвижимостью',
        fr: 'Je possède les propriétés',
        de: 'Ich besitze die Immobilien'
    },
    'I manage properties for others': {
        pt: 'Eu gerencio propriedades para outros',
        es: 'Gestiono propiedades para otros',
        ru: 'Я управляю недвижимостью для других',
        fr: 'Je gère des propriétés pour autrui',
        de: 'Ich verwalte Immobilien für andere'
    },
    'For my Home': {
        pt: 'Para minha Casa',
        es: 'Para mi Casa',
        ru: 'Для моего дома',
        fr: 'Pour ma Maison',
        de: 'Für mein Zuhause'
    },
    'I Manage an Airbnb/Rental': {
        pt: 'Eu Gerencio um Airbnb/Aluguel',
        es: 'Gestiono un Airbnb/Alquiler',
        ru: 'Я управляю Airbnb/Арендой',
        fr: 'Je Gère un Airbnb/Location',
        de: 'Ich Verwalte ein Airbnb/Vermietung'
    },
    'For an Office': {
        pt: 'Para um Escritório',
        es: 'Para una Oficina',
        ru: 'Для офиса',
        fr: 'Pour un Bureau',
        de: 'Für ein Büro'
    },
    '\"Love my Pro. She remembers (or sees on her app) that I leave sheets in the dryer and put them on the bed for me.\"': {
        pt: '\"Adoro minha Profissional. Ela lembra (ou vê no aplicativo) que deixo lençóis na secadora e os coloca na cama para mim.\"',
        es: '\"Amo a mi Pro. Ella recuerda (o ve en su app) que dejo sábanas en la secadora y las pone en la cama para mí.\"',
        ru: '\"Люблю своего Профи. Она помнит (или видит в приложении), что я оставляю простыни в сушилке и кладу их на кровать для меня.\"',
        fr: '\"J\'adore mon Pro. Elle se souvient (ou voit sur son appli) que je laisse des draps dans le sèche-linge et les met sur le lit pour moi.\"',
        de: '\"Ich liebe meinen Pro. Sie erinnert sich (oder sieht in ihrer App), dass ich Bettlaken im Trockner lasse und sie für mich auf das Bett lege.\"'
    },
    '\"TIDY, my expectations were high, but you delivered. So nice to come back to a clean home!\"': {
        pt: '\"TIDY, minhas expectativas eram altas, mas vocês atenderam. Tão bom voltar para uma casa limpa!\"',
        es: '\"TIDY, mis expectativas eran altas, pero ustedes cumplieron. ¡Qué agradable es volver a un hogar limpio!\"',
        ru: '\"TIDY, мои ожидания были высоки, но вы справились. Так приятно возвращаться в чистый дом!\"',
        fr: '\"TIDY, mes attentes étaient élevées, mais vous avez livré. C\'est tellement agréable de revenir à une maison propre!\"',
        de: '\"TIDY, meine Erwartungen waren hoch, aber ihr habt geliefert. Es ist so schön, in ein sauberes Zuhause zurückzukehren!\"'
    },
    '\"TIDY removed the hassle from maintaining my home and helped me become so much more organized!\"': {
        pt: '\"TIDY eliminou o incômodo de manter minha casa e me ajudou a me organizar muito mais!\"',
        es: '\"TIDY eliminó la molestia de mantener mi hogar y me ayudó a organizarme mucho más!\"',
        ru: '\"TIDY избавил от хлопот по поддержанию моего дома и помог стать гораздо организованнее!\"',
        fr: '\"TIDY a supprimé les tracas de l\'entretien de ma maison et m\'a aidé à devenir beaucoup plus organisé!\"',
        de: '\"TIDY hat den Aufwand für die Instandhaltung meines Hauses beseitigt und mir geholfen, viel organisierter zu werden!\"'
    },
    '\"TIDY let me know what the manufacturers recommend for my smoke detector. I decided to follow it, then realized they weren\'t working at all! Getting suggestions made me sleep better at night.\"': {
        pt: '\"TIDY me informou o que os fabricantes recomendam para meu detector de fumaça. Decidi seguir, depois percebi que não estavam funcionando! Receber sugestões me fez dormir melhor à noite.\"',
        es: '\"TIDY me informó lo que los fabricantes recomiendan para mi detector de humo. Decidí seguirlo, luego me di cuenta de que no funcionaban en absoluto! Recibir sugerencias me hizo dormir mejor por la noche.\"',
        ru: '\"TIDY сообщил мне, что производители рекомендуют для моего детектора дыма. Я решил следовать этому, а потом понял, что они вообще не работают! Получение предложений помогло мне лучше спать ночью.\"',
        fr: '\"TIDY m\'a informé de ce que les fabricants recommandent pour mon détecteur de fumée. J\'ai décidé de le suivre, puis j\'ai réalisé qu\'ils ne fonctionnaient pas du tout! Obtenir des suggestions m\'a permis de mieux dormir la nuit.\"',
        de: '\"TIDY hat mir mitgeteilt, was die Hersteller für meinen Rauchmelder empfehlen. Ich beschloss, ihm zu folgen, dann stellte ich fest, dass sie überhaupt nicht funktionierten! Vorschläge zu bekommen, hat mir geholfen, nachts besser zu schlafen.\"'
    },
    '\"TIDY integrated into Airbnb for me and now my turnovers are automatic. Even though my guests randomly book, TIDY gets it done for me.\"': {
        pt: '\"TIDY integrou-se ao Airbnb para mim e agora meus turnovers são automáticos. Mesmo que meus hóspedes reservem aleatoriamente, TIDY faz isso por mim.\"',
        es: '\"TIDY se integró en Airbnb para mí y ahora mis cambios son automáticos. Aunque mis invitados reserven al azar, TIDY lo hace por mí.\"',
        ru: '\"TIDY интегрировался в Airbnb для меня, и теперь мои обороты автоматизированы. Хотя мои гости бронируют случайным образом, TIDY делает это за меня.\"',
        fr: '\"TIDY s\'est intégré à Airbnb pour moi et maintenant mes rotations sont automatiques. Même si mes invités réservent de manière aléatoire, TIDY le fait pour moi.\"',
        de: '\"TIDY hat sich für mich in Airbnb integriert und jetzt sind meine Umsätze automatisch. Auch wenn meine Gäste zufällig buchen, erledigt TIDY es für mich.\"'
    },
    '\"Every 90 days or so I need to get my properties cleaned. I use TIDY to get it done, mostly with my own pros, but I have them help me find backups. Works like a charm.\"': {
        pt: '\"A cada 90 dias ou mais, preciso limpar minhas propriedades. Eu uso TIDY para fazer isso, principalmente com meus próprios profissionais, mas peço que eles me ajudem a encontrar substitutos. Funciona como um encanto.\"',
        es: '\"Cada 90 días o así necesito limpiar mis propiedades. Utilizo TIDY para hacerlo, principalmente con mis propios profesionales, pero les pido que me ayuden a encontrar respaldos. Funciona como un encanto.\"',
        ru: '\"Каждые 90 дней или около того мне нужно убирать свои объекты. Я использую TIDY для этого, в основном со своими профессионалами, но я прошу их помочь мне найти запасные. Работает как по волшебству.\"',
        fr: 'Tous les 90 jours environ, j\'ai besoin de nettoyer mes propriétés. J\'utilise TIDY pour cela, principalement avec mes propres professionnels, mais je leur demande de m\'aider à trouver des remplaçants. Ça marche comme par magie.\"',
        de: '\"Alle 90 Tage oder so muss ich meine Immobilien reinigen lassen. Ich verwende TIDY dafür, meistens mit meinen eigenen Profis, aber ich lasse sie mir helfen, Ersatz zu finden. Funktioniert wie ein Charme.\"'
    },
    '\"I love how easy it is to schedule cleanings with TIDY!  Our office has never been so consistently clean.\"': {
        pt: '\"Adoro como é fácil agendar limpezas com TIDY! Nosso escritório nunca esteve tão consistentemente limpo.\"',
        es: '\"¡Me encanta lo fácil que es programar limpiezas con TIDY! Nuestra oficina nunca ha estado tan consistently limpia.\"',
        ru: '\"Мне нравится, как легко запланировать уборку с TIDY! Наш офис никогда не был таким постоянно чистым.\"',
        fr: '\"J\'adore comme il est facile de planifier des nettoyages avec TIDY! Notre bureau n\'a jamais été aussi constamment propre.\"',
        de: '\"Ich liebe es, wie einfach es ist, Reinigungen mit TIDY zu planen! Unser Büro war noch nie so konsequent sauber.\"'
    },
    '\"The TIDY cleaners are so efficient - we schedule them to clean after work so that the office is clean each morning.\"': {
        pt: '\"Os limpadores TIDY são tão eficientes - nós os agendamos para limpar após o trabalho para que o escritório esteja limpo todas as manhãs.\"',
        es: '\"Los limpiadores TIDY son tan eficientes - los programamos para limpiar después del trabajo para que la oficina esté limpia cada mañana.\"',
        ru: '\"Уборщики TIDY такие эффективные - мы планируем их уборку после работы, чтобы офис был чистым каждое утро.\"',
        fr: '\"Les nettoyeurs TIDY sont si efficaces - nous les programmons pour nettoyer après le travail afin que le bureau soit propre chaque matin.\"',
        de: '\"Die TIDY-Reinigungskräfte sind so effizient - wir planen sie nach der Arbeit zu reinigen, damit das Büro jeden Morgen sauber ist.\"'
    },

    //AddReportPage
    'Add Report': {
        pt: 'Adicionar Relatório',
        es: 'Agregar Informe',
        ru: 'Добавить Отчет',
        fr: 'Ajouter un rapport',
        de: 'Bericht hinzufügen'
    },
    'Report Type': {
        pt: 'Tipo de Relatório',
        es: 'Tipo de Informe',
        ru: 'Тип Отчета',
        fr: 'Type de rapport',
        de: 'Berichtstyp'
    },
    'Please select a report type.': {
        pt: 'Por favor, selecione um tipo de relatório.',
        es: 'Por favor, seleccione un tipo de informe.',
        ru: 'Пожалуйста, выберите тип отчета.',
        fr: 'Veuillez sélectionner un type de rapport.',
        de: 'Bitte wählen Sie einen Berichtstyp aus.'
    },
    'Report Name': {
        pt: 'Nome do Relatório',
        es: 'Nombre del Informe',
        ru: 'Название Отчета',
        fr: 'Nom du rapport',
        de: 'Berichtsname'
    },
    'Please enter a name for this report.': {
        pt: 'Por favor, insira um nome para este relatório.',
        es: 'Por favor, introduzca un nombre para este informe.',
        ru: 'Пожалуйста, введите название для этого отчета.',
        fr: 'Veuillez entrer un nom pour ce rapport.',
        de: 'Bitte geben Sie einen Namen für diesen Bericht ein.'
    },

    //EditReportColumnPage
    'Edit Column': {
        pt: 'Editar Coluna',
        es: 'Editar Columna',
        ru: 'Редактировать Колонку',
        fr: 'Modifier la colonne',
        de: 'Spalte bearbeiten'
    },
    'This column is in': {
        pt: 'Esta coluna está em',
        es: 'Esta columna está en',
        ru: 'Эта колонка находится в',
        fr: 'Cette colonne est dans',
        de: 'Diese Spalte befindet sich in'
    },
    'Show this column': {
        pt: 'Mostrar esta coluna',
        es: 'Mostrar esta columna',
        ru: 'Показать эту колонку',
        fr: 'Afficher cette colonne',
        de: 'Diese Spalte anzeigen'
    },
    'Sort by this column by default': {
        pt: 'Ordenar por esta coluna por padrão',
        es: 'Ordenar por esta columna por defecto',
        ru: 'Сортировать по этой колонке по умолчанию',
        fr: 'Trier par cette colonne par défaut',
        de: 'Standardmäßig nach dieser Spalte sortieren'
    },
    'Column Filters': {
        pt: 'Filtros de Coluna',
        es: 'Filtros de Columna',
        ru: 'Фильтры Колонки',
        fr: 'Filtres de colonne',
        de: 'Spaltenfilter'
    },
    'Add Filter': {
        pt: 'Adicionar Filtro',
        es: 'Agregar Filtro',
        ru: 'Добавить Фильтр',
        fr: 'Ajouter un filtre',
        de: 'Filter hinzufügen'
    },
    'No filters added': {
        pt: 'Nenhum filtro adicionado',
        es: 'No se añadieron filtros',
        ru: 'Фильтры не добавлены',
        fr: 'Aucun filtre ajouté',
        de: 'Keine Filter hinzugefügt'
    },
    'Date filter applied': {
        pt: 'Filtro de data aplicado',
        es: 'Filtro de fecha aplicado',
        ru: 'Применен фильтр по дате',
        fr: 'Filtre de date appliqué',
        de: 'Datumsfilter angewendet'
    },
    'Remove': {
        pt: 'Remover',
        es: 'Eliminar',
        ru: 'Удалить',
        fr: 'Supprimer',
        de: 'Entfernen'
    },
    'The following value': {
        pt: 'O seguinte valor',
        es: 'El siguiente valor',
        ru: 'Следующее значение',
        fr: 'La valeur suivante',
        de: 'Der folgende Wert'
    },
    'Please select a value.': {
        pt: 'Por favor, selecione um valor.',
        es: 'Por favor, seleccione un valor.',
        ru: 'Пожалуйста, выберите значение.',
        fr: 'Veuillez sélectionner une valeur.',
        de: 'Bitte wählen Sie einen Wert aus.'
    },
    'Ascending': {
        pt: 'Crescente',
        es: 'Ascendente',
        ru: 'По возрастанию',
        fr: 'Ascendant',
        de: 'Aufsteigend'
    },
    'Descending': {
        pt: 'Descendente',
        es: 'Descendente',
        ru: 'По убыванию',
        fr: 'Descendant',
        de: 'Absteigend'
    },
    'Contains': {
        pt: 'Contém',
        es: 'Contiene',
        ru: 'Содержит',
        fr: 'Contient',
        de: 'Enthält'
    },
    'Equals': {
        pt: 'Igual',
        es: 'Igual',
        ru: 'Равно',
        fr: 'Égal',
        de: 'Gleich'
    },
    'Starts with': {
        pt: 'Começa com',
        es: 'Empieza con',
        ru: 'Начинается с',
        fr: 'Commence par',
        de: 'Beginnt mit'
    },
    'Ends with': {
        pt: 'Termina com',
        es: 'Termina con',
        ru: 'Заканчивается на',
        fr: 'Finit par',
        de: 'Endet mit'
    },
    'Does not contain': {
        pt: 'Não contém',
        es: 'No contiene',
        ru: 'Не содержит',
        fr: 'Ne contient pas',
        de: 'Enthält nicht'
    },
    'Does not equal': {
        pt: 'Não é igual',
        es: 'No es igual',
        ru: 'Не равно',
        fr: 'N\'est pas égal',
        de: 'Ist nicht gleich'
    },

    //EditReportNamePage
    'Edit Report Name': {
        pt: 'Editar Nome do Relatório',
        es: 'Editar Nombre del Informe',
        ru: 'Изменить Название Отчета',
        fr: 'Modifier le Nom du Rapport',
        de: 'Berichtsnamen Bearbeiten'
    },
    'Please enter a name.': {
        pt: 'Por favor, insira um nome.',
        es: 'Por favor, introduzca un nombre.',
        ru: 'Пожалуйста, введите имя.',
        fr: 'Veuillez entrer un nom.',
        de: 'Bitte geben Sie einen Namen ein.'
    },
    'Save Name': {
        pt: 'Salvar Nome',
        es: 'Guardar Nombre',
        ru: 'Сохранить Имя',
        fr: 'Enregistrer le Nom',
        de: 'Namen Speichern'
    },

    //MapPopup
    'Learn More': {
        pt: 'Saiba Mais',
        es: 'Aprender Más',
        ru: 'Узнать Больше',
        fr: 'En Savoir Plus',
        de: 'Mehr Erfahren'
    },
    'View Address': {
        pt: 'Ver Endereço',
        es: 'Ver Dirección',
        ru: 'Посмотреть Адрес',
        fr: 'Voir l\'Adresse',
        de: 'Adresse Anzeigen'
    },

    //ReportSettingsPage
    'Dashboard Settings': {
        pt: 'Configurações do Painel',
        es: 'Configuraciones del Tablero',
        ru: 'Настройки панели',
        fr: 'Paramètres du Tableau',
        de: 'Dashboard-Einstellungen'
    },
    'Report Settings': {
        pt: 'Configurações de Relatório',
        es: 'Configuraciones de Informe',
        ru: 'Настройки отчета',
        fr: 'Paramètres du Rapport',
        de: 'Berichtseinstellungen'
    },
    'This is a': {
        pt: 'Isto é um',
        es: 'Esto es un',
        ru: 'Это',
        fr: 'Ceci est un',
        de: 'Dies ist ein'
    },
    'This means': {
        pt: 'Isso significa',
        es: 'Esto significa',
        ru: 'Это значит',
        fr: 'Cela signifie',
        de: 'Das bedeutet'
    },
    'will appear here. You can sort or filter': {
        pt: 'aparecerá aqui. Você pode ordenar ou filtrar',
        es: 'aparecerá aquí. Puedes ordenar o filtrar',
        ru: 'появится здесь. Вы можете сортировать или фильтровать',
        fr: 'apparaîtra ici. Vous pouvez trier ou filtrer',
        de: 'wird hier erscheinen. Sie können sortieren oder filtern'
    },
    'by a given column.': {
        pt: 'por uma coluna específica.',
        es: 'por una columna dada.',
        ru: 'по заданной колонке.',
        fr: 'par une colonne donnée.',
        de: 'nach einer bestimmten Spalte.'
    },
    'Columns': {
        pt: 'Colunas',
        es: 'Columnas',
        ru: 'Колонки',
        fr: 'Colonnes',
        de: 'Spalten'
    },
    'Sort column:': {
        pt: 'Ordenar coluna:',
        es: 'Ordenar columna:',
        ru: 'Сортировать колонку:',
        fr: 'Trier la colonne:',
        de: 'Spalte sortieren:'
    },
    'Filter column:': {
        pt: 'Filtrar coluna:',
        es: 'Filtrar columna:',
        ru: 'Фильтровать колонку:',
        fr: 'Filtrer la colonne:',
        de: 'Spalte filtern:'
    },
    'View Report': {
        pt: 'Visualizar Relatório',
        es: 'Ver Informe',
        ru: 'Посмотреть отчет',
        fr: 'Voir le Rapport',
        de: 'Bericht ansehen'
    },

    //EditAddressDetailPage
    'Edit Property': {
        pt: 'Editar Propriedade',
        es: 'Editar Propiedad',
        ru: 'Редактировать Собственность',
        fr: 'Modifier la Propriété',
        de: 'Eigentum bearbeiten'
    },
    'Edit Order': {
        pt: 'Editar Ordem',
        es: 'Editar Orden',
        ru: 'Редактировать Заказ',
        fr: 'Modifier la Commande',
        de: 'Bestellung bearbeiten'
    },
    'Build Type': {
        pt: 'Tipo de Construção',
        es: 'Tipo de Construcción',
        ru: 'Тип Здания',
        fr: 'Type de Construction',
        de: 'Bautyp'
    },
    'Please enter a build type.': {
        pt: 'Por favor, insira um tipo de construção.',
        es: 'Por favor, introduzca un tipo de construcción.',
        ru: 'Пожалуйста, введите тип здания.',
        fr: 'Veuillez entrer un type de construction.',
        de: 'Bitte geben Sie einen Bautyp ein.'
    },
    'Roof Type': {
        pt: 'Tipo de Telhado',
        es: 'Tipo de Techo',
        ru: 'Тип Крыши',
        fr: 'Type de Toit',
        de: 'Dachtyp'
    },
    'Please enter a roof type.': {
        pt: 'Por favor, insira um tipo de telhado.',
        es: 'Por favor, introduzca un tipo de techo.',
        ru: 'Пожалуйста, введите тип крыши.',
        fr: 'Veuillez entrer un type de toit.',
        de: 'Bitte geben Sie einen Dachtyp ein.'
    },
    'Square Feet': {
        pt: 'Pés Quadrados',
        es: 'Pies Cuadrados',
        ru: 'Квадратные Футы',
        fr: 'Pieds Carrés',
        de: 'Quadratfuß'
    },
    'Please enter the square feet.': {
        pt: 'Por favor, insira os pés quadrados.',
        es: 'Por favor, introduzca los pies cuadrados.',
        ru: 'Пожалуйста, введите квадратные футы.',
        fr: 'Veuillez entrer les pieds carrés.',
        de: 'Bitte geben Sie die Quadratfußzahl ein.'
    },
    'Cannot Delete Property': {
        pt: 'Não é possível Excluir Propriedade',
        es: 'No se puede Eliminar Propiedad',
        ru: 'Невозможно Удалить Собственность',
        fr: 'Impossible de Supprimer la Propriété',
        de: 'Eigentum kann nicht gelöscht werden'
    },
    'This property has scheduled jobs. Please cancel the jobs for this property before deleting this property.': {
        pt: 'Esta propriedade tem trabalhos agendados. Por favor, cancele os trabalhos para esta propriedade antes de excluí-la.',
        es: 'Esta propiedad tiene trabajos programados. Por favor, cancele los trabajos para esta propiedad antes de eliminarla.',
        ru: 'На этой собственности запланированы работы. Пожалуйста, отмените работы для этой собственности перед ее удалением.',
        fr: 'Cette propriété a des travaux prévus. Veuillez annuler les travaux pour cette propriété avant de la supprimer.',
        de: 'Diese Eigenschaft hat geplante Arbeiten. Bitte stornieren Sie die Arbeiten für diese Eigenschaft, bevor Sie sie löschen.'
    },
    'Delete Property?': {
        pt: 'Excluir Propriedade?',
        es: '¿Eliminar Propiedad?',
        ru: 'Удалить Собственность?',
        fr: 'Supprimer la Propriété?',
        de: 'Eigentum löschen?'
    },
    'Are you sure you want to delete this property?': {
        pt: 'Tem certeza de que deseja excluir esta propriedade?',
        es: '¿Está seguro de que desea eliminar esta propiedad?',
        ru: 'Вы уверены, что хотите удалить эту собственность?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette propriété?',
        de: 'Sind Sie sicher, dass Sie diese Eigenschaft löschen möchten?'
    },

    //FeedbackComponent
    'You Reviewed': {
        pt: 'Você Avaliou',
        es: 'Usted Revisó',
        ru: 'Вы Оценили',
        fr: 'Vous avez Revu',
        de: 'Sie haben Bewertet'
    },
    'Loved It': {
        pt: 'Adorei',
        es: 'Me Encantó',
        ru: 'Мне Очень Понравилось',
        fr: 'J\'ai Adoré',
        de: 'Hat mir Gefallen'
    },
    'Needs Work': {
        pt: 'Precisa de Melhorias',
        es: 'Necesita Mejoras',
        ru: 'Требует Доработки',
        fr: 'Nécessite des Améliorations',
        de: 'Benötigt Arbeit'
    },
    'Rate': {
        pt: 'Avaliar',
        es: 'Calificar',
        ru: 'Оценить',
        fr: 'Évaluer',
        de: 'Bewerten'
    },
    'Love It': {
        pt: 'Amei',
        es: 'Me Encanta',
        ru: 'Люблю Это',
        fr: 'J\'aime Ça',
        de: 'Liebe Es'
    },

    //LoveItPage
    'We\'re glad you loved it!': {
        pt: 'Estamos felizes que você adorou!',
        es: '¡Nos alegra que te haya encantado!',
        ru: 'Мы рады, что вам это понравилось!',
        fr: 'Nous sommes ravis que vous ayez adoré!',
        de: 'Wir freuen uns, dass es Ihnen gefallen hat!'
    },
    'Leave a Review': {
        pt: 'Deixe uma Avaliação',
        es: 'Deja una Reseña',
        ru: 'Оставить Отзыв',
        fr: 'Laissez un Avis',
        de: 'Hinterlassen Sie eine Bewertung'
    },
    'Review a Tip': {
        pt: 'Avaliar uma Dica',
        es: 'Evaluar un Consejo',
        ru: 'Оценить Совет',
        fr: 'Évaluer un Conseil',
        de: 'Bewerten Sie einen Tipp'
    },
    'Tips are common for one time cleanings, but not for recurring plans.': {
        pt: 'Gorjetas são comuns para limpezas únicas, mas não para planos recorrentes.',
        es: 'Las propinas son comunes para limpiezas únicas, pero no para planes recurrentes.',
        ru: 'Чаевые распространены для разовых уборок, но не для повторяющихся планов.',
        fr: 'Les pourboires sont courants pour les nettoyages ponctuels, mais pas pour les plans récurrents.',
        de: 'Trinkgelder sind bei einmaligen Reinigungen üblich, aber nicht bei wiederkehrenden Plänen.'
    },
    'Pros make more than average and are aware no tip is required.': {
        pt: 'Profissionais ganham mais que a média e sabem que não é necessário dar gorjeta.',
        es: 'Los profesionales ganan más que el promedio y saben que no se requiere propina.',
        ru: 'Профессионалы зарабатывают больше среднего и знают, что чаевые не обязательны.',
        fr: 'Les professionnels gagnent plus que la moyenne et sont conscients qu\'aucun pourboire n\'est requis.',
        de: 'Profis verdienen überdurchschnittlich und wissen, dass kein Trinkgeld erforderlich ist.'
    },
    'Please select an option.': {
        pt: 'Por favor, selecione uma opção.',
        es: 'Por favor, seleccione una opción.',
        ru: 'Пожалуйста, выберите опцию.',
        fr: 'Veuillez sélectionner une option.',
        de: 'Bitte wählen Sie eine Option.'
    },
    'Enter custom tip': {
        pt: 'Inserir gorjeta personalizada',
        es: 'Ingresar propina personalizada',
        ru: 'Введите пользовательский чаевые',
        fr: 'Entrez un pourboire personnalisé',
        de: 'Benutzerdefiniertes Trinkgeld eingeben'
    },
    'Submit Review': {
        pt: 'Enviar Avaliação',
        es: 'Enviar Reseña',
        ru: 'Отправить Отзыв',
        fr: 'Soumettre l\'Avis',
        de: 'Bewertung Absenden'
    },
    'Thanks For Your Feedback!': {
        pt: 'Obrigado pelo seu Feedback!',
        es: '¡Gracias por tus comentarios!',
        ru: 'Спасибо за ваш отзыв!',
        fr: 'Merci pour votre retour!',
        de: 'Danke für Ihr Feedback!'
    },
    'Skip': {
        pt: 'Pular',
        es: 'Saltar',
        ru: 'Пропустить',
        fr: 'Passer',
        de: 'Überspringen'
    },
    'Post a Review': {
        pt: 'Postar uma Avaliação',
        es: 'Publicar una Reseña',
        ru: 'Разместить Отзыв',
        fr: 'Publier un Avis',
        de: 'Eine Bewertung posten'
    },
    'We\'re glad you loved your cleaning!': {
        pt: 'Estamos felizes que você adorou sua limpeza!',
        es: '¡Nos alegra que te haya encantado la limpieza!',
        ru: 'Мы рады, что вам понравилась уборка!',
        fr: 'Nous sommes ravis que vous ayez adoré votre nettoyage!',
        de: 'Wir freuen uns, dass Ihnen die Reinigung gefallen hat!'
    },
    'Go to My Cleanings': {
        pt: 'Ir para Minhas Limpezas',
        es: 'Ir a Mis Limpiezas',
        ru: 'Перейти к моим Уборкам',
        fr: 'Aller à Mes Nettoyages',
        de: 'Zu meinen Reinigungen gehen'
    },
    'No Tip': {
        pt: 'Sem Gorjeta',
        es: 'Sin Propina',
        ru: 'Без чаевых',
        fr: 'Pas de Pourboire',
        de: 'Kein Trinkgeld'
    },
    'Custom Tip': {
        pt: 'Gorjeta Personalizada',
        es: 'Propina Personalizada',
        ru: 'Пользовательские чаевые',
        fr: 'Pourboire Personnalisé',
        de: 'Benutzerdefiniertes Trinkgeld'
    },

    //NeedsWorkMoreInfoPage
    'Please help us learn more about what went wrong with': {
        pt: 'Por favor, ajude-nos a entender o que deu errado com',
        es: 'Por favor, ayúdanos a aprender más sobre qué salió mal con',
        ru: 'Пожалуйста, помогите нам узнать больше о том, что пошло не так с',
        fr: 'Veuillez nous aider à en savoir plus sur ce qui a mal tourné avec',
        de: 'Bitte helfen Sie uns mehr darüber zu erfahren, was mit'
    },
    'Didn’t Finish To Dos': {
        pt: 'Não Concluiu as Tarefas',
        es: 'No Terminó las Tareas',
        ru: 'Не Закончил Задачи',
        fr: 'N\'a pas Fini les Tâches',
        de: 'Hat die Aufgaben Nicht Beendet'
    },
    'Our Concierge will review and email you ASAP.': {
        pt: 'Nosso Concierge irá revisar e enviar um e-mail o mais rápido possível.',
        es: 'Nuestro Conserje revisará y te enviará un correo electrónico lo antes posible.',
        ru: 'Наш консьерж рассмотрит и отправит вам электронное письмо как можно скорее.',
        fr: 'Notre concierge examinera et vous enverra un e-mail dès que possible.',
        de: 'Unser Concierge wird dies überprüfen und Ihnen so schnell wie möglich eine E-Mail senden.'
    },
    'What was the main reason they didn’t finish?': {
        pt: 'Qual foi a principal razão pela qual eles não terminaram?',
        es: '¿Cuál fue la razón principal por la que no terminaron?',
        ru: 'Какова была основная причина, по которой они не закончили?',
        fr: 'Quelle était la raison principale pour laquelle ils n\'ont pas fini?',
        de: 'Was war der Hauptgrund, warum sie nicht fertig wurden?'
    },
    'Arrived at': {
        pt: 'Chegou em',
        es: 'Llegó a',
        ru: 'Прибыл в',
        fr: 'Arrivé à',
        de: 'Angekommen um'
    },
    'Left at': {
        pt: 'Saiu em',
        es: 'Salió a',
        ru: 'Ушел в',
        fr: 'Parti à',
        de: 'Verlassen um'
    },
    'Abilities': {
        pt: 'Habilidades',
        es: 'Habilidades',
        ru: 'Способности',
        fr: 'Capacités',
        de: 'Fähigkeiten'
    },
    'Rate ability (1-5 stars):': {
        pt: 'Avalie a habilidade (1-5 estrelas):',
        es: 'Califica la habilidad (1-5 estrellas):',
        ru: 'Оцените способность (1-5 звезд):',
        fr: 'Évaluez la capacité (1-5 étoiles) :',
        de: 'Bewerten Sie die Fähigkeit (1-5 Sterne):'
    },
    'Missing Supplies': {
        pt: 'Faltam Suprimentos',
        es: 'Faltan Suministros',
        ru: 'Отсутствуют Расходные Материалы',
        fr: 'Fournitures Manquantes',
        de: 'Fehlende Materialien'
    },
    'Was missing supplies? Select all that apply:': {
        pt: 'Faltaram suprimentos? Selecione todos que se aplicam:',
        es: '¿Faltaron suministros? Selecciona todos los que apliquen:',
        ru: 'Не хватало расходных материалов? Выберите все, что применимо:',
        fr: 'Des fournitures manquaient? Sélectionnez tout ce qui s\'applique :',
        de: 'Fehlten Materialien? Wählen Sie alle zutreffenden aus:'
    },
    'Were there other issues with their supplies?': {
        pt: 'Houve outros problemas com os suprimentos?',
        es: '¿Hubo otros problemas con sus suministros?',
        ru: 'Были ли другие проблемы с их расходными материалами?',
        fr: 'Y avait-il d\'autres problèmes avec leurs fournitures?',
        de: 'Gab es andere Probleme mit ihren Materialien?'
    },
    'Unprofessional Attitude': {
        pt: 'Atitude Não Profissional',
        es: 'Actitud No Profesional',
        ru: 'Непрофессиональное Отношение',
        fr: 'Attitude Non Professionnelle',
        de: 'Unprofessionelle Einstellung'
    },
    'How was unprofessional?': {
        pt: 'Como foi a falta de profissionalismo?',
        es: '¿Cómo fue la falta de profesionalismo?',
        ru: 'Как проявлялось непрофессиональное поведение?',
        fr: 'En quoi était-ce non professionnel ?',
        de: 'Wie war es unprofessionell?'
    },
    'Safety Issue': {
        pt: 'Questão de Segurança',
        es: 'Problema de Seguridad',
        ru: 'Проблема Безопасности',
        fr: 'Problème de Sécurité',
        de: 'Sicherheitsproblem'
    },
    'What was the safety issue?': {
        pt: 'Qual foi o problema de segurança?',
        es: '¿Cuál fue el problema de seguridad?',
        ru: 'Какая была проблема с безопасностью?',
        fr: 'Quel était le problème de sécurité ?',
        de: 'Was war das Sicherheitsproblem?'
    },
    'Please enter a valid gift code.': {
        pt: 'Por favor, insira um código de presente válido.',
        es: 'Por favor, introduce un código de regalo válido.',
        ru: 'Пожалуйста, введите действительный подарочный код.',
        fr: 'Veuillez entrer un code cadeau valide.',
        de: 'Bitte geben Sie einen gültigen Geschenkcode ein.'
    },
    'Block': {
        pt: 'Bloquear',
        es: 'Bloquear',
        ru: 'Блокировать',
        fr: 'Bloquer',
        de: 'Blockieren'
    },
    'Would you have again?': {
        pt: 'Você teria novamente?',
        es: '¿Lo tendrías de nuevo?',
        ru: 'Возьмете ли вы снова?',
        fr: 'Le reprendriez-vous ?',
        de: 'Würden Sie es wieder haben?'
    },
    'Yes, I think they can get it next time': {
        pt: 'Sim, eu acho que eles podem conseguir na próxima vez',
        es: 'Sí, creo que pueden conseguirlo la próxima vez',
        ru: 'Да, я думаю, они смогут сделать это в следующий раз',
        fr: 'Oui, je pense qu\'ils peuvent y arriver la prochaine fois',
        de: 'Ja, ich denke, sie können es nächstes Mal schaffen'
    },
    'No, I don’t want them again': {
        pt: 'Não, eu não quero eles novamente',
        es: 'No, no quiero que vuelvan',
        ru: 'Нет, я не хочу их снова видеть',
        fr: 'Non, je ne veux pas les revoir',
        de: 'Nein, ich möchte sie nicht wieder haben'
    },
    'Constructive Feedback': {
        pt: 'Feedback Construtivo',
        es: 'Retroalimentación Constructiva',
        ru: 'Конструктивная обратная связь',
        fr: 'Retour Constructif',
        de: 'Konstruktives Feedback'
    },
    'Give Constructive Feedback (optional)': {
        pt: 'Dê um Feedback Construtivo (opcional)',
        es: 'Da una Retroalimentación Constructiva (opcional)',
        ru: 'Дайте Конструктивную Обратную Связь (по желанию)',
        fr: 'Donnez un Retour Constructif (facultatif)',
        de: 'Geben Sie konstruktives Feedback (optional)'
    },
    'Goes directly to them.': {
        pt: 'Vai diretamente para eles.',
        es: 'Va directamente a ellos.',
        ru: 'Идет напрямую к ним.',
        fr: 'Va directement à eux.',
        de: 'Geht direkt an sie.'
    },
    'Please enter feedback.': {
        pt: 'Por favor, insira o feedback.',
        es: 'Por favor, introduce tus comentarios.',
        ru: 'Пожалуйста, введите отзыв.',
        fr: 'Veuillez entrer vos commentaires.',
        de: 'Bitte geben Sie Feedback ein.'
    },
    'Worked too slowly': {
        pt: 'Trabalhou muito lentamente',
        es: 'Trabajó demasiado lento',
        ru: 'Работал слишком медленно',
        fr: 'A travaillé trop lentement',
        de: 'Hat zu langsam gearbeitet'
    },
    'Didn\'t follow my To Dos': {
        pt: 'Não seguiu minhas tarefas',
        es: 'No siguió mis tareas pendientes',
        ru: 'Не следовал моему списку дел',
        fr: 'N\'a pas suivi mes tâches à faire',
        de: 'Hat meine To-Dos nicht befolgt'
    },
    'Needed more time for the job': {
        pt: 'Precisava de mais tempo para o trabalho',
        es: 'Necesitaba más tiempo para el trabajo',
        ru: 'Нужно больше времени для работы',
        fr: 'Avait besoin de plus de temps pour le travail',
        de: 'Brauchte mehr Zeit für die Arbeit'
    },
    'Didn\'t stay the full duration': {
        pt: 'Não ficou pelo tempo completo',
        es: 'No se quedó la duración completa',
        ru: 'Не остался на весь срок',
        fr: 'N\'est pas resté toute la durée',
        de: 'Blieb nicht die ganze Dauer'
    },
    'Worst job ever': {
        pt: 'Pior trabalho de sempre',
        es: 'El peor trabajo de todos',
        ru: 'Худшая работа из всех',
        fr: 'Le pire travail jamais vu',
        de: 'Schlechteste Arbeit überhaupt'
    },
    'Very bad - Definitely don’t want them again': {
        pt: 'Muito ruim - Definitivamente não quero eles novamente',
        es: 'Muy mal - Definitivamente no quiero que vuelvan',
        ru: 'Очень плохо - Определенно не хочу их видеть снова',
        fr: 'Très mauvais - Je ne veux définitivement pas les revoir',
        de: 'Sehr schlecht - Will sie definitiv nicht wieder haben'
    },
    'Not great - I’d prefer a different pro': {
        pt: 'Não é ótimo - Eu preferiria outro profissional',
        es: 'No está bien - Preferiría otro profesional',
        ru: 'Не очень - Я бы предпочел другого специалиста',
        fr: 'Pas génial - Je préférerais un autre professionnel',
        de: 'Nicht toll - Ich würde einen anderen Profi bevorzugen'
    },
    'Ok - I think they could get it next time': {
        pt: 'Ok - Eu acho que eles podem conseguir na próxima vez',
        es: 'Ok - Creo que pueden conseguirlo la próxima vez',
        ru: 'Ok - Я думаю, они смогут сделать это в следующий раз',
        fr: 'Ok - Je pense qu\'ils peuvent y arriver la prochaine fois',
        de: 'Ok - Ich denke, sie können es nächstes Mal schaffen'
    },
    'Good but missed the mark on some To Dos': {
        pt: 'Bom, mas não cumpriu algumas tarefas',
        es: 'Bien pero no cumplió con algunas tareas',
        ru: 'Хорошо, но не справился с некоторыми задачами',
        fr: 'Bien mais a manqué certains points sur les tâches à faire',
        de: 'Gut, aber einige To-Dos verfehlt'
    },
    'Make sure to fill all required fields': {
        pt: 'Certifique-se de preencher todos os campos obrigatórios',
        es: 'Asegúrate de llenar todos los campos requeridos',
        ru: 'Убедитесь, что заполнены все обязательные поля',
        fr: 'Assurez-vous de remplir tous les champs requis',
        de: 'Stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind'
    },
    'Feedback Submitted': {
        pt: 'Feedback Enviado',
        es: 'Comentarios Enviados',
        ru: 'Отзыв отправлен',
        fr: 'Commentaires Soumis',
        de: 'Feedback eingereicht'
    },
    'Thanks so much for your feedback, we do take it very seriously and will keep it anonymous.': {
        pt: 'Muito obrigado pelo seu feedback, levamos isso muito a sério e manteremos anônimo.',
        es: 'Muchas gracias por tus comentarios, los tomamos muy en serio y los mantendremos anónimos.',
        ru: 'Большое спасибо за ваш отзыв, мы серьезно относимся к этому и сохраним анонимность.',
        fr: 'Merci beaucoup pour vos commentaires, nous prenons cela très au sérieux et les garderons anonymes.',
        de: 'Vielen Dank für Ihr Feedback, wir nehmen es sehr ernst und werden es anonym behandeln.'
    },
    'Go to My Schedule': {
        pt: 'Ir para Minha Agenda',
        es: 'Ir a Mi Horario',
        ru: 'Перейти к моему расписанию',
        fr: 'Aller à Mon Emploi du Temps',
        de: 'Zu meinem Zeitplan gehen'
    },

    //NeedsWorkWhatHappenedPage
    'We\'re sorry that you weren\'t happy with': {
        pt: 'Lamentamos que você não tenha ficado satisfeito com',
        es: 'Lamentamos que no estuvieras contento con',
        ru: 'Нам жаль, что вам не понравилось',
        fr: 'Nous sommes désolés que vous n\'ayez pas été satisfait avec',
        de: 'Es tut uns leid, dass Sie nicht zufrieden waren mit'
    },
    'We would love to get this right. What Happened?': {
        pt: 'Gostaríamos de acertar isso. O que aconteceu?',
        es: 'Nos encantaría hacerlo bien. ¿Qué pasó?',
        ru: 'Мы хотели бы исправить это. Что произошло?',
        fr: 'Nous aimerions rectifier cela. Que s\'est-il passé?',
        de: 'Wir würden das gerne richtig machen. Was ist passiert?'
    },

    //ImportPropertiesCSVPage
    'Import Properties': {
        pt: 'Importar Propriedades',
        es: 'Importar Propiedades',
        ru: 'Импорт свойств',
        fr: 'Importer des Propriétés',
        de: 'Eigenschaften importieren'
    },
    'CSV Template': {
        pt: 'Modelo CSV',
        es: 'Plantilla CSV',
        ru: 'Шаблон CSV',
        fr: 'Modèle CSV',
        de: 'CSV-Vorlage'
    },
    'Download Example': {
        pt: 'Baixar Exemplo',
        es: 'Descargar Ejemplo',
        ru: 'Скачать пример',
        fr: 'Télécharger un Exemple',
        de: 'Beispiel herunterladen'
    },
    'Select CSV': {
        pt: 'Selecionar CSV',
        es: 'Seleccionar CSV',
        ru: 'Выбрать CSV',
        fr: 'Sélectionner un CSV',
        de: 'CSV auswählen'
    },
    'Address, Unit, City, Zip, Nickname, Country Code': {
        pt: 'Endereço, Unidade, Cidade, CEP, Apelido, Código do País',
        es: 'Dirección, Unidad, Ciudad, Código Postal, Apodo, Código de País',
        ru: 'Адрес, Юнит, Город, Почтовый индекс, Никнейм, Код страны',
        fr: 'Adresse, Unité, Ville, Code Postal, Surnom, Code Pays',
        de: 'Adresse, Einheit, Stadt, PLZ, Spitzname, Ländercode'
    },
    'Importing properties. This may take some time.': {
        pt: 'Importando propriedades. Isso pode levar algum tempo.',
        es: 'Importando propiedades. Esto puede tomar algún tiempo.',
        ru: 'Импорт свойств. Это может занять некоторое время.',
        fr: 'Importation des propriétés. Cela peut prendre du temps.',
        de: 'Importieren von Eigenschaften. Dies kann einige Zeit dauern.'
    },
    'Please import a CSV file.': {
        pt: 'Por favor, importe um arquivo CSV.',
        es: 'Por favor, importe un archivo CSV.',
        ru: 'Пожалуйста, импортируйте файл CSV.',
        fr: 'Veuillez importer un fichier CSV.',
        de: 'Bitte importieren Sie eine CSV-Datei.'
    },
    'properties ready to be added!': {
        pt: 'propriedades prontas para serem adicionadas!',
        es: 'propiedades listas para ser agregadas!',
        ru: 'свойства готовы к добавлению!',
        fr: 'propriétés prêtes à être ajoutées!',
        de: 'Eigenschaften bereit zum Hinzufügen!'
    },
    'Skipped adding': {
        pt: 'Adição ignorada',
        es: 'Adición omitida',
        ru: 'Добавление пропущено',
        fr: 'Ajout ignoré',
        de: 'Hinzufügen übersprungen'
    },
    'properties': {
        pt: 'propriedades',
        es: 'propiedades',
        ru: 'свойства',
        fr: 'propriétés',
        de: 'Eigenschaften'
    },
    'Add Properties': {
        pt: 'Adicionar Propriedades',
        es: 'Agregar Propiedades',
        ru: 'Добавить свойства',
        fr: 'Ajouter des Propriétés',
        de: 'Eigenschaften hinzufügen'
    },
    'None of your properties were imported successfully. Please download the template above to ensure you have the correct format. Leave the unit or nickname column blank if there is no unit.': {
        pt: 'Nenhuma de suas propriedades foi importada com sucesso. Por favor, baixe o modelo acima para garantir que você tenha o formato correto. Deixe a coluna de unidade ou apelido em branco se não houver unidade.',
        es: 'Ninguna de tus propiedades fue importada con éxito. Por favor, descarga la plantilla anterior para asegurarte de tener el formato correcto. Deja en blanco la columna de unidad o apodo si no hay unidad.',
        ru: 'Ни одно из ваших свойств не было успешно импортировано. Пожалуйста, загрузите шаблон выше, чтобы убедиться, что у вас правильный формат. Оставьте столбец юнита или никнейма пустым, если юнит отсутствует.',
        fr: 'Aucune de vos propriétés n\'a été importée avec succès. Veuillez télécharger le modèle ci-dessus pour vous assurer que vous avez le bon format. Laissez la colonne de l\'unité ou du surnom vide s\'il n\'y a pas d\'unité.',
        de: 'Keine Ihrer Eigenschaften wurde erfolgreich importiert. Bitte laden Sie die obige Vorlage herunter, um sicherzustellen, dass Sie das richtige Format haben. Lassen Sie die Spalte für Einheit oder Spitzname leer, wenn keine Einheit vorhanden ist.'
    },
    'Error Uploading': {
        pt: 'Erro ao Carregar',
        es: 'Error al Subir',
        ru: 'Ошибка загрузки',
        fr: 'Erreur de Téléchargement',
        de: 'Fehler beim Hochladen'
    },
    'Google Maps did not return any results for the following properties:': {
        pt: 'O Google Maps não retornou nenhum resultado para as seguintes propriedades:',
        es: 'Google Maps no devolvió ningún resultado para las siguientes propiedades:',
        ru: 'Google Maps не вернул результатов для следующих свойств:',
        fr: 'Google Maps n\'a retourné aucun résultat pour les propriétés suivantes:',
        de: 'Google Maps lieferte keine Ergebnisse für die folgenden Eigenschaften:'
    },
    'Already Added': {
        pt: 'Já Adicionado',
        es: 'Ya Agregado',
        ru: 'Уже добавлено',
        fr: 'Déjà Ajouté',
        de: 'Bereits hinzugefügt'
    },
    'We\'ll skip adding these properties because you already added them to TIDY:': {
        pt: 'Vamos pular a adição dessas propriedades porque você já as adicionou ao TIDY:',
        es: 'Omitiremos agregar estas propiedades porque ya las agregaste a TIDY:',
        ru: 'Мы пропустим добавление этих свойств, потому что вы уже добавили их в TIDY:',
        fr: 'Nous passerons l\'ajout de ces propriétés car vous les avez déjà ajoutées à TIDY:',
        de: 'Wir werden das Hinzufügen dieser Eigenschaften überspringen, da Sie sie bereits zu TIDY hinzugefügt haben:'
    },
    'Duplicate Imports': {
        pt: 'Importações Duplicadas',
        es: 'Importaciones Duplicadas',
        ru: 'Дублирующиеся импорты',
        fr: 'Importations en Double',
        de: 'Doppelte Importe'
    },
    'You uploading the following properties twice so we\'ll only upload it once:': {
        pt: 'Você está carregando as seguintes propriedades duas vezes, então só vamos carregá-las uma vez:',
        es: 'Estás subiendo las siguientes propiedades dos veces, así que solo las subiremos una vez:',
        ru: 'Вы загружаете следующие свойства дважды, поэтому мы загрузим их только один раз:',
        fr: 'Vous téléchargez les propriétés suivantes deux fois, nous ne les téléchargerons donc qu\'une fois:',
        de: 'Sie laden die folgenden Eigenschaften zweimal hoch, daher werden wir sie nur einmal hochladen:'
    },
    'Properties to Add:': {
        pt: 'Propriedades para Adicionar:',
        es: 'Propiedades para Agregar:',
        ru: 'Свойства для добавления:',
        fr: 'Propriétés à Ajouter:',
        de: 'Zu hinzufügende Eigenschaften:'
    },
    'Unit:': {
        pt: 'Unidade:',
        es: 'Unidad:',
        ru: 'Юнит:',
        fr: 'Unité:',
        de: 'Einheit:'
    },
    'none': {
        pt: 'nenhum',
        es: 'ninguno',
        ru: 'никакой',
        fr: 'aucun',
        de: 'keine'
    },
    'City:': {
        pt: 'Cidade:',
        es: 'Ciudad:',
        ru: 'Город:',
        fr: 'Ville:',
        de: 'Stadt:'
    },
    'Zip:': {
        pt: 'CEP:',
        es: 'Código Postal:',
        ru: 'Почтовый индекс:',
        fr: 'Code Postal:',
        de: 'PLZ:'
    },
    'Nickname:': {
        pt: 'Apelido:',
        es: 'Apodo:',
        ru: 'Никнейм:',
        fr: 'Surnom:',
        de: 'Spitzname:'
    },
    'Country Code:': {
        pt: 'Código do País:',
        es: 'Código de País:',
        ru: 'Код страны:',
        fr: 'Code Pays:',
        de: 'Ländercode:'
    },
    'State:': {
        pt: 'Estado:',
        es: 'Estado:',
        ru: 'Штат:',
        fr: 'État:',
        de: 'Staat:'
    },

    //integration-items.ts
    'All Integrations': {
        pt: 'Todas as Integrações',
        es: 'Todas las Integraciones',
        ru: 'Все Интеграции',
        fr: 'Toutes les Intégrations',
        de: 'Alle Integrationen'
    },
    'Property Management': {
        pt: 'Gestão de Propriedades',
        es: 'Gestión de Propiedades',
        ru: 'Управление Недвижимостью',
        fr: 'Gestion Immobilière',
        de: 'Immobilienverwaltung'
    },
    'Booking Channel': {
        pt: 'Canal de Reservas',
        es: 'Canal de Reservas',
        ru: 'Канал Бронирования',
        fr: 'Canal de Réservation',
        de: 'Buchungskanal'
    },
    'Notifications': {
        pt: 'Notificações',
        es: 'Notificaciones',
        ru: 'Уведомления',
        fr: 'Notifications',
        de: 'Benachrichtigungen'
    },
    'Accounting': {
        pt: 'Contabilidade',
        es: 'Contabilidad',
        ru: 'Бухгалтерский Учет',
        fr: 'Comptabilité',
        de: 'Buchhaltung'
    },
    'Locks': {
        pt: 'Fechaduras',
        es: 'Cerraduras',
        ru: 'Замки',
        fr: 'Serrures',
        de: 'Schlösser'
    },
    'Thermostats': {
        pt: 'Termostatos',
        es: 'Termostatos',
        ru: 'Термостаты',
        fr: 'Thermostats',
        de: 'Thermostate'
    },
    'Integrate your property management system to sync data & automatically book jobs.': {
        pt: 'Integre seu sistema de gestão de propriedades para sincronizar dados e agendar trabalhos automaticamente.',
        es: 'Integra tu sistema de gestión de propiedades para sincronizar datos y reservar trabajos automáticamente.',
        ru: 'Интегрируйте вашу систему управления недвижимостью для синхронизации данных и автоматического бронирования работ.',
        fr: 'Intégrez votre système de gestion immobilière pour synchroniser les données et réserver automatiquement des travaux.',
        de: 'Integrieren Sie Ihr Immobilienverwaltungssystem, um Daten zu synchronisieren und automatisch Jobs zu buchen.'
    },
    'Integrate your booking channel to sync data & automatically book jobs.': {
        pt: 'Integre seu canal de reservas para sincronizar dados e agendar trabalhos automaticamente.',
        es: 'Integra tu canal de reservas para sincronizar datos y reservar trabajos automáticamente.',
        ru: 'Интегрируйте ваш канал бронирования для синхронизации данных и автоматического бронирования работ.',
        fr: 'Intégrez votre canal de réservation pour synchroniser les données et réserver automatiquement des travaux.',
        de: 'Integrieren Sie Ihren Buchungskanal, um Daten zu synchronisieren und automatisch Jobs zu buchen.'
    },
    'Control your vendor access by integrating with your smartlock or access control systems. Generate one time use keys and more.': {
        pt: 'Controle o acesso de seus fornecedores integrando com seu sistema de fechadura inteligente ou sistemas de controle de acesso. Gere chaves de uso único e mais.',
        es: 'Controla el acceso de tus proveedores integrando con tu cerradura inteligente o sistemas de control de acceso. Genera llaves de un solo uso y más.',
        ru: 'Контролируйте доступ поставщиков, интегрируясь с вашей умной замком или системами контроля доступа. Генерируйте одноразовые ключи и больше.',
        fr: 'Contrôlez l\'accès de vos fournisseurs en intégrant votre serrure intelligente ou systèmes de contrôle d\'accès. Générez des clés à usage unique et plus.',
        de: 'Steuern Sie den Zugang Ihrer Lieferanten durch Integration mit Ihrem Smartlock oder Zugangskontrollsystemen. Generieren Sie Einmal-Schlüssel und mehr.'
    },
    'Set a temperature automatically for vendors to feel comfortable but keep energy bills under control.': {
        pt: 'Defina uma temperatura automaticamente para que os fornecedores se sintam confortáveis, mas mantenha as contas de energia sob controle.',
        es: 'Establece una temperatura automáticamente para que los proveedores se sientan cómodos pero manteniendo las facturas de energía bajo control.',
        ru: 'Автоматически установите температуру, чтобы поставщики чувствовали себя комфортно, но контролировали энергетические счета.',
        fr: 'Réglez automatiquement une température pour que les fournisseurs se sentent à l\'aise tout en gardant les factures d\'énergie sous contrôle.',
        de: 'Stellen Sie automatisch eine Temperatur ein, damit sich die Anbieter wohl fühlen, aber die Energiekosten unter Kontrolle halten.'
    },
    'Add a webhook to get notified of selected events.': {
        pt: 'Adicione um webhook para ser notificado sobre eventos selecionados.',
        es: 'Agrega un webhook para ser notificado de eventos seleccionados.',
        ru: 'Добавьте webhook, чтобы получать уведомления о выбранных событиях.',
        fr: 'Ajoutez un webhook pour être notifié des événements sélectionnés.',
        de: 'Fügen Sie einen Webhook hinzu, um über ausgewählte Ereignisse benachrichtigt zu werden.'
    },
    'Sync your transaction data including payments by teams for easier accounting.': {
        pt: 'Sincronize seus dados de transação, incluindo pagamentos por equipes, para facilitar a contabilidade.',
        es: 'Sincroniza tus datos de transacción, incluidos los pagos por equipos, para facilitar la contabilidad.',
        ru: 'Синхронизируйте данные о транзакциях, включая платежи командами, для упрощения бухгалтерского учета.',
        fr: 'Synchronisez vos données de transaction, y compris les paiements par équipes, pour faciliter la comptabilité.',
        de: 'Synchronisieren Sie Ihre Transaktionsdaten, einschließlich Zahlungen von Teams, für eine einfachere Buchhaltung.'
    },
    'Integrate TIDY to sync data, automatically book jobs, unlock smartlocks, set temperature, get notifications, track accounting & more!': {
        pt: 'Integre TIDY para sincronizar dados, agendar trabalhos automaticamente, desbloquear fechaduras inteligentes, ajustar temperatura, receber notificações, acompanhar a contabilidade e mais!',
        es: 'Integra TIDY para sincronizar datos, reservar trabajos automáticamente, desbloquear cerraduras inteligentes, ajustar temperatura, recibir notificaciones, seguir la contabilidad ¡y más!',
        ru: 'Интегрируйте TIDY для синхронизации данных, автоматического бронирования работ, разблокировки умных замков, установки температуры, получения уведомлений, отслеживания бухгалтерии и многого другого!',
        fr: 'Intégrez TIDY pour synchroniser les données, réserver automatiquement des travaux, déverrouiller des serrures intelligentes, régler la température, recevoir des notifications, suivre la comptabilité et plus encore!',
        de: 'Integrieren Sie TIDY, um Daten zu synchronisieren, automatisch Jobs zu buchen, Smartlocks zu entsperren, die Temperatur einzustellen, Benachrichtigungen zu erhalten, die Buchhaltung zu verfolgen und mehr!'
    },
    'Alfred offers an array of modern, beautifully designed smart locks. They use Bluetooth and Z-Wave technology to offer a state-of-the-art smart home experience.': {
        pt: 'Alfred oferece uma variedade de fechaduras inteligentes modernas e bem projetadas. Eles usam tecnologia Bluetooth e Z-Wave para oferecer uma experiência de casa inteligente de última geração.',
        es: 'Alfred ofrece una variedad de cerraduras inteligentes modernas y bellamente diseñadas. Utilizan tecnología Bluetooth y Z-Wave para ofrecer una experiencia de hogar inteligente de última generación.',
        ru: 'Alfred предлагает ряд современных, красиво спроектированных умных замков. Они используют технологии Bluetooth и Z-Wave для предоставления передового опыта в умном доме.',
        fr: 'Alfred propose une gamme de serrures intelligentes modernes et magnifiquement conçues. Ils utilisent la technologie Bluetooth et Z-Wave pour offrir une expérience de maison intelligente de pointe.',
        de: 'Alfred bietet eine Reihe von modernen, schön gestalteten Smart-Locks. Sie verwenden Bluetooth- und Z-Wave-Technologie, um ein hochmodernes Smart-Home-Erlebnis zu bieten.'
    },
    'Allegion helps keep people safe and secure where they live work and visit. With more than 25 brands sold globally they specialize in security around the doorway and adjacent area: everything from residential locks and portable security to commercial locks, exit devices, openers and closers, electronic access control and workforce productivity solutions.': {
        pt: 'Allegion ajuda a manter as pessoas seguras e protegidas onde vivem, trabalham e visitam. Com mais de 25 marcas vendidas globalmente, eles se especializam em segurança ao redor da porta e área adjacente: tudo desde fechaduras residenciais e segurança portátil até fechaduras comerciais, dispositivos de saída, abridores e fechadores, controle de acesso eletrônico e soluções de produtividade da força de trabalho.',
        es: 'Allegion ayuda a mantener a las personas seguras y protegidas donde viven, trabajan y visitan. Con más de 25 marcas vendidas globalmente, se especializan en seguridad alrededor de la puerta y área adyacente: todo desde cerraduras residenciales y seguridad portátil hasta cerraduras comerciales, dispositivos de salida, abridores y cerradores, control de acceso electrónico y soluciones de productividad laboral.',
        ru: 'Allegion помогает обеспечить безопасность людей там, где они живут, работают и посещают. С более чем 25 брендами, продаваемыми по всему миру, они специализируются на безопасности вокруг дверного проема и прилегающей области: от жилых замков и портативной безопасности до коммерческих замков, выходных устройств, открывателей и закрывателей, электронного контроля доступа и решений для повышения производительности рабочей силы.',
        fr: 'Allegion aide à garder les gens en sécurité là où ils vivent, travaillent et visitent. Avec plus de 25 marques vendues mondialement, ils se spécialisent dans la sécurité autour de la porte et de la zone adjacente : tout, des serrures résidentielles et de la sécurité portable aux serrures commerciales, dispositifs de sortie, ouvreurs et fermeurs, contrôle d\'accès électronique et solutions de productivité de la main-d\'œuvre.',
        de: 'Allegion hilft dabei, Menschen dort sicher und geschützt zu halten, wo sie leben, arbeiten und besuchen. Mit mehr als 25 weltweit verkauften Marken spezialisieren sie sich auf Sicherheit rund um die Tür und den angrenzenden Bereich: alles von Wohnschlössern und tragbarer Sicherheit bis hin zu kommerziellen Schlössern, Ausgangsgeräten, Öffnern und Schließern, elektronischer Zugangskontrolle und Lösungen zur Steigerung der Arbeitsproduktivität.'
    },
    'With operations in over 70 countries, Assa Abloy solutions are used every day around the world and serve billions of people. They are found in homes and businesses operating across many industries. They’re specialists in access essentials: like mechanical and digital locks, cylinders, keys, tags, security doors and automated entrances.': {
        pt: 'Com operações em mais de 70 países, as soluções Assa Abloy são usadas todos os dias ao redor do mundo e atendem bilhões de pessoas. Elas são encontradas em casas e empresas que operam em várias indústrias. São especialistas em essenciais de acesso: como fechaduras mecânicas e digitais, cilindros, chaves, etiquetas, portas de segurança e entradas automatizadas.',
        es: 'Con operaciones en más de 70 países, las soluciones de Assa Abloy se utilizan todos los días en todo el mundo y sirven a miles de millones de personas. Se encuentran en hogares y negocios que operan en muchas industrias. Son especialistas en elementos esenciales de acceso: como cerraduras mecánicas y digitales, cilindros, llaves, etiquetas, puertas de seguridad y entradas automatizadas.',
        ru: 'С операциями более чем в 70 странах, решения Assa Abloy используются каждый день по всему миру и обслуживают миллиарды людей. Они находятся в домах и бизнесах, работающих во многих отраслях. Они специалисты по основам доступа: как механические и цифровые замки, цилиндры, ключи, бирки, безопасные двери и автоматизированные входы.',
        fr: 'Avec des opérations dans plus de 70 pays, les solutions Assa Abloy sont utilisées tous les jours dans le monde entier et servent des milliards de personnes. Elles se trouvent dans des maisons et des entreprises opérant dans de nombreuses industries. Ce sont des spécialistes des essentiels d\'accès : comme les serrures mécaniques et numériques, les cylindres, les clés, les étiquettes, les portes de sécurité et les entrées automatisées.',
        de: 'Mit Operationen in über 70 Ländern werden Assa Abloy-Lösungen jeden Tag weltweit eingesetzt und dienen Milliarden von Menschen. Sie finden sich in Häusern und Unternehmen, die in vielen Branchen tätig sind. Sie sind Spezialisten für Zugangsgrundlagen: wie mechanische und digitale Schlösser, Zylinder, Schlüssel, Tags, Sicherheitstüren und automatisierte Eingänge.'
    },
    'August’s mission is to make lives more simple and secure by offering unprecedented visibility and control over their front door. Their products, services, and partnerships deliver smarter home access that keep the bad guys out and let the good guys in.': {
        pt: 'A missão da August é tornar a vida mais simples e segura, oferecendo visibilidade e controle sem precedentes sobre a porta da frente. Seus produtos, serviços e parcerias oferecem acesso doméstico mais inteligente que mantém os maus de fora e deixa os bons entrarem.',
        es: 'La misión de August es hacer la vida más simple y segura ofreciendo una visibilidad y control sin precedentes sobre su puerta principal. Sus productos, servicios y asociaciones proporcionan un acceso a casa más inteligente que mantiene a los malos fuera y deja entrar a los buenos.',
        ru: 'Миссия August - сделать жизнь проще и безопаснее, предлагая беспрецедентную видимость и контроль над их передней дверью. Их продукты, услуги и партнерства обеспечивают более умный доступ домой, который не пускает плохих парней и позволяет хорошим войти.',
        fr: 'La mission d\'August est de rendre la vie plus simple et sécurisée en offrant une visibilité et un contrôle sans précédent sur leur porte d\'entrée. Leurs produits, services et partenariats offrent un accès à la maison plus intelligent qui garde les méchants à l\'extérieur et laisse entrer les bons.',
        de: 'Die Mission von August ist es, das Leben einfacher und sicherer zu machen, indem sie beispiellose Sichtbarkeit und Kontrolle über ihre Haustür bieten. Ihre Produkte, Dienstleistungen und Partnerschaften liefern intelligenteren Hauszugang, der die Bösen draußen hält und die Guten hereinlässt.'
    },
    'TIDY has partnered with ButterflyMX to provide a smartphone-based, building-wide access solution. With this integration, property staff and residents can easily and securely access your building’s front entrance, amenity spaces and individual apartment units – all from one mobile app. Staff and residents can also see who is at the property’s entryway, issue temporary and recurring visitor access, and remotely open doors and gates from anywhere.': {
        pt: 'A TIDY fez parceria com a ButterflyMX para fornecer uma solução de acesso em todo o edifício baseada em smartphone. Com essa integração, funcionários e moradores podem acessar facilmente e com segurança a entrada principal do seu prédio, espaços de comodidades e unidades de apartamentos individuais - tudo a partir de um único aplicativo móvel. Funcionários e moradores também podem ver quem está na entrada da propriedade, emitir acesso temporário e recorrente para visitantes e abrir portas e portões remotamente de qualquer lugar.',
        es: 'TIDY se ha asociado con ButterflyMX para proporcionar una solución de acceso en todo el edificio basada en smartphones. Con esta integración, el personal de la propiedad y los residentes pueden acceder fácil y seguramente a la entrada principal de su edificio, espacios de comodidades y unidades de apartamentos individuales, todo desde una sola aplicación móvil. El personal y los residentes también pueden ver quién está en la entrada de la propiedad, emitir acceso de visitantes temporal y recurrente, y abrir puertas y portones de forma remota desde cualquier lugar.',
        ru: 'TIDY партнерствует с ButterflyMX, чтобы предоставить решение для доступа в здание на основе смартфона. С этой интеграцией персонал и жители объекта могут легко и безопасно получить доступ к переднему входу вашего здания, пространствам удобств и отдельным квартирным блокам - все из одного мобильного приложения. Персонал и жители также могут видеть, кто находится у входа в здание, выдавать временный и повторяющийся доступ для посетителей и удаленно открывать двери и ворота из любой точки мира.',
        fr: 'TIDY s\'est associé à ButterflyMX pour fournir une solution d\'accès à l\'ensemble du bâtiment basée sur un smartphone. Avec cette intégration, le personnel de la propriété et les résidents peuvent facilement et en toute sécurité accéder à l\'entrée principale de votre bâtiment, aux espaces de commodités et aux unités d\'appartements individuelles, le tout à partir d\'une seule application mobile. Le personnel et les résidents peuvent également voir qui se trouve à l\'entrée de la propriété, émettre un accès visiteur temporaire et récurrent, et ouvrir à distance les portes et les portails de n\'importe où.',
        de: 'TIDY hat sich mit ButterflyMX zusammengetan, um eine smartphonebasierte, gebäudeumfassende Zugangslösung zu bieten. Mit dieser Integration können Immobilienpersonal und Bewohner einfach und sicher auf den Haupteingang Ihres Gebäudes, die Annehmlichkeitsbereiche und einzelne Wohnungseinheiten zugreifen – alles von einer mobilen App aus. Personal und Bewohner können auch sehen, wer sich am Eingang der Immobilie befindet, temporären und wiederkehrenden Besucherzugang ausstellen und Türen und Tore von überall aus fernöffnen.'
    },
    'Over 2 billion things that need to be identified, verified and tracked are connected through HID Global’s technology. TIDY works with a growing list of HID hardware to compliment any access control system.': {
        pt: 'Mais de 2 bilhões de coisas que precisam ser identificadas, verificadas e rastreadas estão conectadas através da tecnologia da HID Global. A TIDY trabalha com uma lista crescente de hardware HID para complementar qualquer sistema de controle de acesso.',
        es: 'Más de 2 mil millones de cosas que necesitan ser identificadas, verificadas y rastreadas están conectadas a través de la tecnología de HID Global. TIDY trabaja con una lista creciente de hardware HID para complementar cualquier sistema de control de acceso.',
        ru: 'Более 2 миллиардов объектов, которые необходимо идентифицировать, проверить и отслеживать, подключены через технологию HID Global. TIDY работает с растущим списком оборудования HID, чтобы дополнить любую систему контроля доступа.',
        fr: 'Plus de 2 milliards de choses qui doivent être identifiées, vérifiées et suivies sont connectées via la technologie de HID Global. TIDY travaille avec une liste croissante de matériel HID pour compléter tout système de contrôle d\'accès.',
        de: 'Über 2 Milliarden Dinge, die identifiziert, überprüft und verfolgt werden müssen, sind über die Technologie von HID Global verbunden. TIDY arbeitet mit einer wachsenden Liste von HID-Hardware, um jedes Zugangskontrollsystem zu ergänzen.'
    },
    'No keys, no hassle, no fuss. igloohome is the market leading keyless access solutions for smarter homes. Their attractive selection of smart locks allow owners to grant access to visitors remotely, via time-sensitive PIN codes or bluetooth keys.': {
        pt: 'Sem chaves, sem complicações, sem confusão. A igloohome é líder de mercado em soluções de acesso sem chave para casas mais inteligentes. Sua atraente seleção de fechaduras inteligentes permite que os proprietários concedam acesso aos visitantes remotamente, via códigos PIN sensíveis ao tempo ou chaves bluetooth.',
        es: 'Sin llaves, sin complicaciones, sin problemas. igloohome es la solución líder en el mercado de acceso sin llaves para hogares más inteligentes. Su atractiva selección de cerraduras inteligentes permite a los propietarios otorgar acceso a los visitantes de forma remota, mediante códigos PIN sensibles al tiempo o llaves bluetooth.',
        ru: 'Без ключей, без хлопот, без суеты. igloohome является лидером рынка в области безключевых решений доступа для умных домов. Их привлекательный выбор умных замков позволяет владельцам предоставлять доступ посетителям удаленно, через временные PIN-коды или bluetooth-ключи.',
        fr: 'Pas de clés, pas de tracas, pas de soucis. igloohome est la solution leader sur le marché pour l\'accès sans clé dans les maisons plus intelligentes. Leur sélection attrayante de serrures intelligentes permet aux propriétaires d\'accorder l\'accès aux visiteurs à distance, via des codes PIN sensibles au temps ou des clés bluetooth.',
        de: 'Keine Schlüssel, kein Ärger, kein Aufhebens. igloohome ist die marktführende schlüssellose Zugangslösung für intelligentere Häuser. Ihre attraktive Auswahl an intelligenten Schlössern ermöglicht es Eigentümern, Besuchern den Zugang aus der Ferne zu gewähren, über zeitabhängige PIN-Codes oder Bluetooth-Schlüssel.'
    },
    'KEYINCODE’s (KIC) offers a growing range of electronic and mechanical access control products which are designed to meet codes and standards for a range of security applications and needs. Code for access; electronic (keypad, card or phone) or mechanical.': {
        pt: 'A KEYINCODE (KIC) oferece uma gama crescente de produtos de controle de acesso eletrônico e mecânico, projetados para atender a códigos e padrões para uma variedade de aplicações e necessidades de segurança. Código para acesso; eletrônico (teclado, cartão ou telefone) ou mecânico.',
        es: 'KEYINCODE (KIC) ofrece una gama creciente de productos de control de acceso electrónico y mecánico diseñados para cumplir con códigos y estándares para una variedad de aplicaciones y necesidades de seguridad. Código para acceso; electrónico (teclado, tarjeta o teléfono) o mecánico.',
        ru: 'KEYINCODE (KIC) предлагает растущий ассортимент электронных и механических продуктов контроля доступа, разработанных для соответствия кодам и стандартам для различных приложений и потребностей в области безопасности. Код для доступа; электронный (клавиатура, карта или телефон) или механический.',
        fr: 'KEYINCODE (KIC) propose une gamme croissante de produits de contrôle d\'accès électroniques et mécaniques conçus pour répondre aux codes et normes pour une gamme d\'applications et de besoins en matière de sécurité. Code pour l\'accès; électronique (clavier, carte ou téléphone) ou mécanique.',
        de: 'KEYINCODE (KIC) bietet eine wachsende Palette von elektronischen und mechanischen Zugangskontrollprodukten, die entwickelt wurden, um Codes und Standards für eine Reihe von Sicherheitsanwendungen und -bedürfnissen zu erfüllen. Code für den Zugang; elektronisch (Tastatur, Karte oder Telefon) oder mechanisch.'
    },
    'Kozo Keikaku Engineering is TIDY’s key partner in Japan, independently managing their own sales and support. They have broad engineering expertise, and work with TIDY to provide cutting-edge smart lock technology and software to the Japanese market.': {
        pt: 'A Kozo Keikaku Engineering é um parceiro chave da TIDY no Japão, gerenciando de forma independente suas próprias vendas e suporte. Possuem ampla expertise em engenharia e trabalham com a TIDY para fornecer tecnologia de fechadura inteligente e software de ponta para o mercado japonês.',
        es: 'Kozo Keikaku Engineering es un socio clave de TIDY en Japón, gestionando de manera independiente sus propias ventas y soporte. Tienen una amplia experiencia en ingeniería y trabajan con TIDY para proporcionar tecnología de cerraduras inteligentes y software de vanguardia para el mercado japonés.',
        ru: 'Kozo Keikaku Engineering является ключевым партнером TIDY в Японии, независимо управляя своими продажами и поддержкой. У них широкая инженерная экспертиза, и они работают с TIDY, чтобы предоставить передовые технологии умных замков и программное обеспечение на японский рынок.',
        fr: 'Kozo Keikaku Engineering est un partenaire clé de TIDY au Japon, gérant de manière indépendante ses propres ventes et son support. Ils possèdent une large expertise en ingénierie et travaillent avec TIDY pour fournir une technologie de serrure intelligente et un logiciel de pointe sur le marché japonais.',
        de: 'Kozo Keikaku Engineering ist ein Schlüsselpartner von TIDY in Japan, der unabhängig seinen eigenen Verkauf und Support verwaltet. Sie verfügen über breite Ingenieurkompetenzen und arbeiten mit TIDY zusammen, um hochmoderne Smart-Lock-Technologie und Software für den japanischen Markt bereitzustellen.'
    },
    'TIDY partners with Kwikset to bring universal access control to their industry-leading hardware. TIDY works with the Kwikset Smartcode lever and deadbolt smart locks.': {
        pt: 'A TIDY faz parceria com a Kwikset para trazer controle de acesso universal para seu hardware líder de indústria. A TIDY trabalha com as fechaduras inteligentes Kwikset Smartcode, de alavanca e deadbolt.',
        es: 'TIDY se asocia con Kwikset para brindar control de acceso universal a su hardware líder en la industria. TIDY trabaja con las cerraduras inteligentes de palanca y deadbolt Kwikset Smartcode.',
        ru: 'TIDY сотрудничает с Kwikset для предоставления универсального контроля доступа к их ведущему в отрасли оборудованию. TIDY работает с умными замками Kwikset Smartcode, рычажными и сувальдными.',
        fr: 'TIDY s\'associe à Kwikset pour apporter un contrôle d\'accès universel à leur matériel de pointe dans l\'industrie. TIDY travaille avec les serrures intelligentes à levier et à pêne dormant Kwikset Smartcode.',
        de: 'TIDY arbeitet mit Kwikset zusammen, um universelle Zugangskontrolle für ihre branchenführende Hardware zu bringen. TIDY arbeitet mit den Kwikset Smartcode Hebel- und Riegelschlössern.'
    },
    'RemoteLock works with virtually any lock, brand or type of door, including hardwired ones.': {
        pt: 'O RemoteLock funciona com praticamente qualquer fechadura, marca ou tipo de porta, incluindo as com fiação.',
        es: 'RemoteLock funciona con prácticamente cualquier cerradura, marca o tipo de puerta, incluidas las cableadas.',
        ru: 'RemoteLock работает практически с любым замком, брендом или типом двери, включая проводные.',
        fr: 'RemoteLock fonctionne avec pratiquement n\'importe quelle serrure, marque ou type de porte, y compris celles câblées.',
        de: 'RemoteLock funktioniert mit nahezu jedem Schloss, jeder Marke oder jedem Türtyp, einschließlich der festverdrahteten.'
    },
    'TIDY partners with Schlage to bring universal access control to its industry-leading hardware. TIDY works with the Schlage Connect line of locks, supporting both deadbolt and lever locks in a variety of styles and finishes.': {
        pt: 'A TIDY faz parceria com a Schlage para trazer controle de acesso universal para seu hardware líder de indústria. A TIDY trabalha com a linha de fechaduras Schlage Connect, suportando tanto fechaduras deadbolt quanto de alavanca em uma variedade de estilos e acabamentos.',
        es: 'TIDY se asocia con Schlage para brindar control de acceso universal a su hardware líder en la industria. TIDY trabaja con la línea de cerraduras Schlage Connect, soportando tanto cerraduras de deadbolt como de palanca en una variedad de estilos y acabados.',
        ru: 'TIDY сотрудничает с Schlage для предоставления универсального контроля доступа к их ведущему в отрасли оборудованию. TIDY работает с линейкой замков Schlage Connect, поддерживая как сувальдные, так и рычажные замки в разнообразии стилей и отделок.',
        fr: 'TIDY s\'associe à Schlage pour apporter un contrôle d\'accès universel à son matériel de pointe dans l\'industrie. TIDY travaille avec la gamme de serrures Schlage Connect, prenant en charge à la fois les serrures à pêne dormant et à levier dans une variété de styles et de finitions.',
        de: 'TIDY arbeitet mit Schlage zusammen, um universelle Zugangskontrolle für ihre branchenführende Hardware zu bringen. TIDY arbeitet mit der Schlage Connect-Schlossreihe, die sowohl Riegel- als auch Hebelschlösser in einer Vielzahl von Stilen und Ausführungen unterstützt.'
    },
    'Since its founding as Nishi Seisakusho in 1933, West has developed innovative and creative cylinders and lever handles and provided them to society. The flagship brand “Agaho” series has become the centerpiece of West and the company has been providing designs to Europe and other countries around the world since winning the Gold Award at the IF Design Awards.': {
        pt: 'Desde sua fundação como Nishi Seisakusho em 1933, a West desenvolveu cilindros inovadores e criativos e alavancas, fornecendo-os à sociedade. A marca principal, a série “Agaho”, tornou-se o ponto central da West e a empresa tem fornecido designs para a Europa e outros países do mundo desde que ganhou o Prêmio de Ouro no IF Design Awards.',
        es: 'Desde su fundación como Nishi Seisakusho en 1933, West ha desarrollado cilindros innovadores y creativos y manijas de palanca y los ha proporcionado a la sociedad. La marca insignia, la serie “Agaho”, se ha convertido en el centro de West y la compañía ha estado proporcionando diseños a Europa y otros países del mundo desde que ganó el Premio de Oro en los IF Design Awards.',
        ru: 'С момента своего основания как Nishi Seisakusho в 1933 году, West разработала инновационные и креативные цилиндры и рычажные ручки и предоставила их обществу. Флагманская марка, серия “Agaho”, стала центральным элементом West, и компания предоставляет дизайны в Европу и другие страны мира с тех пор, как выиграла Золотую награду на IF Design Awards.',
        fr: 'Depuis sa fondation sous le nom de Nishi Seisakusho en 1933, West a développé des cylindres innovants et créatifs et des poignées de levier et les a fournis à la société. La marque phare, la série “Agaho”, est devenue le point central de West et l\'entreprise fournit des designs en Europe et dans d\'autres pays du monde depuis qu\'elle a remporté le Prix d\'Or aux IF Design Awards.',
        de: 'Seit seiner Gründung als Nishi Seisakusho im Jahr 1933 hat West innovative und kreative Zylinder und Hebelgriffe entwickelt und der Gesellschaft zur Verfügung gestellt. Die Flaggschiff-Marke, die „Agaho“-Serie, ist zum Herzstück von West geworden, und das Unternehmen liefert seit dem Gewinn des Gold Awards bei den IF Design Awards Designs nach Europa und in andere Länder weltweit.'
    },
    'Integrate with Ecobee to automatically change temperature around booked jobs.': {
        pt: 'Integre com o Ecobee para alterar automaticamente a temperatura em torno dos trabalhos agendados.',
        es: 'Integra con Ecobee para cambiar automáticamente la temperatura alrededor de los trabajos reservados.',
        ru: 'Интегрируйте с Ecobee для автоматического изменения температуры вокруг забронированных работ.',
        fr: 'Intégrez avec Ecobee pour changer automatiquement la température autour des travaux réservés.',
        de: 'Integrieren Sie mit Ecobee, um die Temperatur um gebuchte Jobs automatisch zu ändern.'
    },
    'Integrate with Nest to automatically change temperature around booked jobs.': {
        pt: 'Integre com o Nest para alterar automaticamente a temperatura em torno dos trabalhos agendados.',
        es: 'Integra con Nest para cambiar automáticamente la temperatura alrededor de los trabajos reservados.',
        ru: 'Интегрируйте с Nest для автоматического изменения температуры вокруг забронированных работ.',
        fr: 'Intégrez avec Nest pour changer automatiquement la température autour des travaux réservés.',
        de: 'Integrieren Sie mit Nest, um die Temperatur um gebuchte Jobs automatisch zu ändern.'
    },
    'Integrate with Honeywell to automatically change temperature around booked jobs.': {
        pt: 'Integre com o Honeywell para alterar automaticamente a temperatura em torno dos trabalhos agendados.',
        es: 'Integra con Honeywell para cambiar automáticamente la temperatura alrededor de los trabajos reservados.',
        ru: 'Интегрируйте с Honeywell для автоматического изменения температуры вокруг забронированных работ.',
        fr: 'Intégrez avec Honeywell pour changer automatiquement la température autour des travaux réservés.',
        de: 'Integrieren Sie mit Honeywell, um die Temperatur um gebuchte Jobs automatisch zu ändern.'
    },
    'QuickBooks is a small business accounting software program that businesses use to manage sales and expenses and keep track of daily transactions. Users can invoice customers, pay bills, generate reports for planning, tax filing, and more. To connect TIDY and QuickBooks, please follow the steps below:': {
        pt: 'O QuickBooks é um programa de software de contabilidade para pequenas empresas que as empresas usam para gerenciar vendas e despesas e acompanhar as transações diárias. Os usuários podem faturar clientes, pagar contas, gerar relatórios para planejamento, declaração de impostos e mais. Para conectar a TIDY e o QuickBooks, siga os passos abaixo:',
        es: 'QuickBooks es un programa de software de contabilidad para pequeñas empresas que las empresas utilizan para gestionar ventas y gastos y llevar un registro de las transacciones diarias. Los usuarios pueden facturar a clientes, pagar facturas, generar informes para la planificación, la presentación de impuestos y más. Para conectar TIDY y QuickBooks, siga los pasos a continuación:',
        ru: 'QuickBooks — это программное обеспечение для бухгалтерского учета малого бизнеса, которое компании используют для управления продажами и расходами и отслеживания ежедневных транзакций. Пользователи могут выставлять счета клиентам, оплачивать счета, генерировать отчеты для планирования, подачи налогов и многое другое. Чтобы подключить TIDY и QuickBooks, пожалуйста, следуйте приведенным ниже шагам:',
        fr: 'QuickBooks est un logiciel de comptabilité pour petites entreprises que les entreprises utilisent pour gérer les ventes et les dépenses et suivre les transactions quotidiennes. Les utilisateurs peuvent facturer les clients, payer les factures, générer des rapports pour la planification, la déclaration fiscale, et plus encore. Pour connecter TIDY et QuickBooks, veuillez suivre les étapes ci-dessous :',
        de: 'QuickBooks ist eine Buchhaltungssoftware für kleine Unternehmen, die Unternehmen zur Verwaltung von Verkäufen und Ausgaben und zur Verfolgung täglicher Transaktionen verwenden. Benutzer können Kundenrechnungen stellen, Rechnungen bezahlen, Berichte für die Planung, Steuererklärung und mehr erstellen. Um TIDY und QuickBooks zu verbinden, folgen Sie bitte den unten stehenden Schritten:'
    },
    'Add a webhook to get notified in your application.': {
        pt: 'Adicione um webhook para ser notificado em sua aplicação.',
        es: 'Agrega un webhook para recibir notificaciones en tu aplicación.',
        ru: 'Добавьте веб-хук, чтобы получать уведомления в вашем приложении.',
        fr: 'Ajoutez un webhook pour être notifié dans votre application.',
        de: 'Fügen Sie einen Webhook hinzu, um in Ihrer Anwendung benachrichtigt zu werden.'
    },
    'Integrate with Slack so your team can communicate the way you want.': {
        pt: 'Integre com o Slack para que sua equipe possa se comunicar da maneira que você deseja.',
        es: 'Integra con Slack para que tu equipo pueda comunicarse de la manera que desees.',
        ru: 'Интегрируйте с Slack, чтобы ваша команда могла общаться так, как вы хотите.',
        fr: 'Intégrez avec Slack pour que votre équipe puisse communiquer comme vous le souhaitez.',
        de: 'Integrieren Sie mit Slack, damit Ihr Team so kommunizieren kann, wie Sie es möchten.'
    },

    //AddIntegrationPage
    'Select Integration Option': {
        pt: 'Selecione a Opção de Integração',
        es: 'Seleccione la Opción de Integración',
        ru: 'Выберите Опцию Интеграции',
        fr: 'Sélectionnez l\'Option d\'Intégration',
        de: 'Wählen Sie die Integrationsoption'
    },
    'Direct Integration': {
        pt: 'Integração Direta',
        es: 'Integración Directa',
        ru: 'Прямая Интеграция',
        fr: 'Intégration Directe',
        de: 'Direkte Integration'
    },
    'Base URL': {
        pt: 'URL Base',
        es: 'URL Base',
        ru: 'Базовый URL',
        fr: 'URL de Base',
        de: 'Basis-URL'
    },
    'Please enter a valid base URL.': {
        pt: 'Por favor, insira um URL base válido.',
        es: 'Por favor, introduzca una URL base válida.',
        ru: 'Пожалуйста, введите действительный базовый URL.',
        fr: 'Veuillez entrer une URL de base valide.',
        de: 'Bitte geben Sie eine gültige Basis-URL ein.'
    },
    'Please enter a valid ID.': {
        pt: 'Por favor, insira um ID válido.',
        es: 'Por favor, introduzca un ID válido.',
        ru: 'Пожалуйста, введите действительный идентификатор.',
        fr: 'Veuillez saisir un ID valide.',
        de: 'Bitte geben Sie eine gültige ID ein.'
    },
    'Please enter a valid API key.': {
        pt: 'Por favor, insira uma chave de API válida.',
        es: 'Por favor, introduzca una clave de API válida.',
        ru: 'Пожалуйста, введите действительный ключ API.',
        fr: 'Veuillez entrer une clé API valide.',
        de: 'Bitte geben Sie einen gültigen API-Schlüssel ein.'
    },
    'You\’ve already added this integration for this property.  Please select a different property or': {
        pt: 'Você já adicionou esta integração para esta propriedade. Por favor, selecione uma propriedade diferente ou',
        es: 'Ya ha añadido esta integración para esta propiedad. Por favor, seleccione una propiedad diferente o',
        ru: 'Вы уже добавили эту интеграцию для этого объекта. Пожалуйста, выберите другой объект или',
        fr: 'Vous avez déjà ajouté cette intégration pour cette propriété. Veuillez sélectionner une propriété différente ou',
        de: 'Sie haben diese Integration bereits für diese Eigenschaft hinzugefügt. Bitte wählen Sie eine andere Eigenschaft oder'
    },
    'Disable this Integration': {
        pt: 'Desativar esta Integração',
        es: 'Desactivar esta Integración',
        ru: 'Отключить эту Интеграцию',
        fr: 'Désactiver cette Intégration',
        de: 'Diese Integration deaktivieren'
    },
    'RemoteLock works with virtually any lock, brand or type of door, including hardwired ones. Directly link RemoteLock to automatically generate one time lockbox codes for each job.': {
        pt: 'RemoteLock funciona com praticamente qualquer fechadura, marca ou tipo de porta, incluindo as fixas. Conecte diretamente o RemoteLock para gerar automaticamente códigos de caixa de bloqueio únicos para cada trabalho.',
        es: 'RemoteLock funciona con prácticamente cualquier cerradura, marca o tipo de puerta, incluidas las cableadas. Enlace directamente RemoteLock para generar automáticamente códigos de caja de bloqueo de un solo uso para cada trabajo.',
        ru: 'RemoteLock работает практически с любым замком, брендом или типом двери, включая проводные. Прямо соедините RemoteLock, чтобы автоматически генерировать одноразовые коды для ключницы для каждой работы.',
        fr: 'RemoteLock fonctionne avec pratiquement n\'importe quelle serrure, marque ou type de porte, y compris les modèles câblés. Liez directement RemoteLock pour générer automatiquement des codes de boîte à clés uniques pour chaque travail.',
        de: 'RemoteLock funktioniert mit nahezu jedem Schloss, jeder Marke oder jedem Türtyp, einschließlich festverdrahteter. Verbinden Sie RemoteLock direkt, um automatisch einmalige Codes für Schlüsselboxen für jeden Job zu generieren.'
    },
    'Select which devices to sync after integrating. For instructions, go': {
        pt: 'Selecione quais dispositivos sincronizar após a integração. Para instruções, vá',
        es: 'Seleccione qué dispositivos sincronizar después de la integración. Para obtener instrucciones, vaya',
        ru: 'Выберите, какие устройства синхронизировать после интеграции. Для получения инструкций перейдите',
        fr: 'Sélectionnez les appareils à synchroniser après l\'intégration. Pour les instructions, allez',
        de: 'Wählen Sie aus, welche Geräte nach der Integration synchronisiert werden sollen. Für Anweisungen gehen Sie'
    },
    'Directly link': {
        pt: 'Vincular diretamente',
        es: 'Vincular directamente',
        ru: 'Напрямую связать',
        fr: 'Lier directement',
        de: 'Direkt verknüpfen'
    },
    'and TIDY to automatically:': {
        pt: 'e TIDY para automaticamente:',
        es: 'y TIDY automáticamente para:',
        ru: 'и TIDY, чтобы автоматически:',
        fr: 'et TIDY pour automatiquement :',
        de: 'und TIDY, um automatisch:'
    },
    '1. Sync all properties from': {
        pt: '1. Sincronizar todas as propriedades de',
        es: '1. Sincronizar todas las propiedades de',
        ru: '1. Синхронизировать все объекты из',
        fr: '1. Synchroniser toutes les propriétés de',
        de: '1. Alle Eigenschaften synchronisieren von'
    },
    'to TIDY.': {
        pt: 'para TIDY.',
        es: 'a TIDY.',
        ru: 'в TIDY.',
        fr: 'vers TIDY.',
        de: 'zu TIDY.'
    },
    '2. Pull reservations from': {
        pt: '2. Puxar reservas de',
        es: '2. Extraer reservas de',
        ru: '2. Получить бронирования из',
        fr: '2. Extraire les réservations de',
        de: '2. Reservierungen abrufen von'
    },
    'into TIDY.': {
        pt: 'para TIDY.',
        es: 'en TIDY.',
        ru: 'в TIDY.',
        fr: 'dans TIDY.',
        de: 'in TIDY.'
    },
    '3. Push updates from TIDY to': {
        pt: '3. Enviar atualizações do TIDY para',
        es: '3. Enviar actualizaciones de TIDY a',
        ru: '3. Отправить обновления из TIDY в',
        fr: '3. Pousser les mises à jour de TIDY vers',
        de: '3. Updates von TIDY pushen zu'
    },
    'After adding the integration, you can opt to turn Automatic Booking on or off.': {
        pt: 'Após adicionar a integração, você pode optar por ativar ou desativar a Reserva Automática.',
        es: 'Después de agregar la integración, puede optar por activar o desactivar la Reserva Automática.',
        ru: 'После добавления интеграции вы можете включить или выключить Автоматическое бронирование.',
        fr: 'Après avoir ajouté l\'intégration, vous pouvez choisir d\'activer ou de désactiver la Réservation Automatique.',
        de: 'Nach dem Hinzufügen der Integration können Sie die Automatische Buchung ein- oder ausschalten.'
    },
    'For instructions, go': {
        pt: 'Para instruções, vá',
        es: 'Para obtener instrucciones, vaya',
        ru: 'Для получения инструкций перейдите',
        fr: 'Pour les instructions, allez',
        de: 'Für Anweisungen gehen Sie'
    },
    'iCal Integration': {
        pt: 'Integração iCal',
        es: 'Integración iCal',
        ru: 'Интеграция iCal',
        fr: 'Intégration iCal',
        de: 'iCal-Integration'
    },
    'You\'ve already added this integration for this property.  Please select a different property or': {
        pt: 'Você já adicionou esta integração para esta propriedade. Por favor, selecione uma propriedade diferente ou',
        es: 'Ya ha agregado esta integración para esta propiedad. Por favor, seleccione una propiedad diferente o',
        ru: 'Вы уже добавили эту интеграцию для этого объекта. Пожалуйста, выберите другой объект или',
        fr: 'Vous avez déjà ajouté cette intégration pour cette propriété. Veuillez sélectionner une propriété différente ou',
        de: 'Sie haben diese Integration bereits für diese Immobilie hinzugefügt. Bitte wählen Sie eine andere Immobilie oder'
    },
    'Export an iCal link from': {
        pt: 'Exportar um link iCal de',
        es: 'Exportar un enlace iCal desde',
        ru: 'Экспортировать ссылку iCal из',
        fr: 'Exporter un lien iCal depuis',
        de: 'Exportieren Sie einen iCal-Link von'
    },
    'to automatically pull guest reservations from': {
        pt: 'para extrair automaticamente as reservas de hóspedes de',
        es: 'para extraer automáticamente las reservas de huéspedes de',
        ru: 'для автоматического получения бронирований гостей из',
        fr: 'pour extraire automatiquement les réservations des invités de',
        de: 'um automatisch Gästereservierungen abzurufen von'
    },
    'Note that you must add the iCal link for <u>each property</u>.': {
        pt: 'Note que você deve adicionar o link iCal para <u>cada propriedade</u>.',
        es: 'Tenga en cuenta que debe agregar el enlace iCal para <u>cada propiedad</u>.',
        ru: 'Обратите внимание, что вы должны добавить ссылку iCal для <u>каждого объекта</u>.',
        fr: 'Notez que vous devez ajouter le lien iCal pour <u>chaque propriété</u>.',
        de: 'Beachten Sie, dass Sie den iCal-Link für <u>jede Immobilie</u> hinzufügen müssen.'
    },
    'iCal link': {
        pt: 'Link iCal',
        es: 'Enlace iCal',
        ru: 'Ссылка iCal',
        fr: 'Lien iCal',
        de: 'iCal-Link'
    },
    'Please enter a valid url': {
        pt: 'Por favor, insira uma URL válida',
        es: 'Por favor, ingrese una URL válida',
        ru: 'Пожалуйста, введите действительный URL',
        fr: 'Veuillez entrer une URL valide',
        de: 'Bitte geben Sie eine gültige URL ein'
    },
    'Concierge Integrations': {
        pt: 'Integrações de Concierge',
        es: 'Integraciones de Conserjería',
        ru: 'Интеграции Консьержа',
        fr: 'Intégrations Concierge',
        de: 'Concierge-Integrationen'
    },
    'Our Concierge Integrations mean our humans+AI will log into your systems to get the data you want. Add a username, password, and any other required information to log into an account. Passwords are encrypted and we recommend giving us a read-only user if possible. Daily syncs with Concierge are included with all paid plans, more frequent syncs will use Concierge Actions.': {
        pt: 'Nossas Integrações de Concierge significam que nossos humanos+IA farão login em seus sistemas para obter os dados que você deseja. Adicione um nome de usuário, senha e qualquer outra informação necessária para fazer login em uma conta. As senhas são criptografadas e recomendamos fornecer um usuário somente leitura, se possível. Sincronizações diárias com o Concierge estão incluídas em todos os planos pagos, sincronizações mais frequentes usarão Ações do Concierge.',
        es: 'Nuestras Integraciones de Conserjería significan que nuestros humanos+IA iniciarán sesión en sus sistemas para obtener los datos que desea. Agregue un nombre de usuario, contraseña y cualquier otra información requerida para iniciar sesión en una cuenta. Las contraseñas están encriptadas y recomendamos darnos un usuario de solo lectura si es posible. Las sincronizaciones diarias con el Conserje están incluidas en todos los planes pagos, las sincronizaciones más frecuentes utilizarán Acciones de Conserjería.',
        ru: 'Наши Интеграции Консьержа означают, что наши люди+ИИ войдут в ваши системы, чтобы получить нужные вам данные. Добавьте имя пользователя, пароль и любую другую необходимую информацию для входа в учетную запись. Пароли зашифрованы, и мы рекомендуем предоставить нам пользователя только для чтения, если это возможно. Ежедневные синхронизации с Консьержем включены во все платные планы, более частые синхронизации будут использовать Действия Консьержа.',
        fr: 'Nos Intégrations Concierge signifient que nos humains+IA se connecteront à vos systèmes pour obtenir les données que vous souhaitez. Ajoutez un nom d\'utilisateur, un mot de passe et toute autre information requise pour vous connecter à un compte. Les mots de passe sont cryptés et nous recommandons de nous donner un utilisateur en lecture seule si possible. Les synchronisations quotidiennes avec le Concierge sont incluses dans tous les forfaits payants, des synchronisations plus fréquentes utiliseront les Actions Concierge.',
        de: 'Unsere Concierge-Integrationen bedeuten, dass sich unsere Menschen+KI in Ihre Systeme einloggen, um die gewünschten Daten zu erhalten. Fügen Sie einen Benutzernamen, ein Passwort und alle anderen erforderlichen Informationen hinzu, um sich bei einem Konto anzumelden. Passwörter werden verschlüsselt und wir empfehlen, uns wenn möglich einen schreibgeschützten Benutzer zu geben. Tägliche Synchronisierungen mit dem Concierge sind in allen kostenpflichtigen Plänen enthalten, häufigere Synchronisierungen verwenden Concierge-Aktionen.'
    },
    'Please enter a username': {
        pt: 'Por favor, insira um nome de usuário',
        es: 'Por favor, ingrese un nombre de usuario',
        ru: 'Пожалуйста, введите имя пользователя',
        fr: 'Veuillez entrer un nom d\'utilisateur',
        de: 'Bitte geben Sie einen Benutzernamen ein'
    },
    'Username': {
        pt: 'Nome de usuário',
        es: 'Nombre de usuario',
        ru: 'Имя пользователя',
        fr: 'Nom d\'utilisateur',
        de: 'Benutzername'
    },
    'Please enter a password': {
        pt: 'Por favor, insira uma senha',
        es: 'Por favor, ingrese una contraseña',
        ru: 'Пожалуйста, введите пароль',
        fr: 'Veuillez entrer un mot de passe',
        de: 'Bitte geben Sie ein Passwort ein'
    },
    'Notes': {
        pt: 'Notas',
        es: 'Notas',
        ru: 'Примечания',
        fr: 'Notes',
        de: 'Notizen'
    },
    'Zapier Integration': {
        pt: 'Integração Zapier',
        es: 'Integración Zapier',
        ru: 'Интеграция Zapier',
        fr: 'Intégration Zapier',
        de: 'Zapier-Integration'
    },
    'Use': {
        pt: 'Usar',
        es: 'Usar',
        ru: 'Использовать',
        fr: 'Utiliser',
        de: 'Verwenden'
    },
    'to trigger actions from TIDY or from': {
        pt: 'para acionar ações do TIDY ou de',
        es: 'para activar acciones desde TIDY o desde',
        ru: 'для запуска действий из TIDY или из',
        fr: 'pour déclencher des actions depuis TIDY ou depuis',
        de: 'um Aktionen von TIDY oder von auszulösen'
    },
    'Then, take actions in TIDY or': {
        pt: 'Então, execute ações no TIDY ou',
        es: 'Luego, realice acciones en TIDY o',
        ru: 'Затем выполните действия в TIDY или',
        fr: 'Ensuite, effectuez des actions dans TIDY ou',
        de: 'Dann führen Sie Aktionen in TIDY oder aus'
    },
    'See Zapier for more details.': {
        pt: 'Veja o Zapier para mais detalhes.',
        es: 'Consulte Zapier para obtener más detalles.',
        ru: 'Смотрите Zapier для получения дополнительной информации.',
        fr: 'Voir Zapier pour plus de détails.',
        de: 'Siehe Zapier für weitere Details.'
    },
    'Contact Information': {
        pt: 'Informações de Contato',
        es: 'Información de Contacto',
        ru: 'Контактная информация',
        fr: 'Informations de Contact',
        de: 'Kontaktinformationen'
    },
    'Why Integrate?': {
        pt: 'Por que Integrar?',
        es: '¿Por qué Integrar?',
        ru: 'Зачем Интегрировать?',
        fr: 'Pourquoi Intégrer ?',
        de: 'Warum Integrieren?'
    },
    'Automatically Book Jobs': {
        pt: 'Agendar Trabalhos Automaticamente',
        es: 'Reservar Trabajos Automáticamente',
        ru: 'Автоматическое Бронирование Работ',
        fr: 'Réserver Automatiquement des Travaux',
        de: 'Automatisch Aufträge Buchen'
    },
    'TIDY can book turnover cleanings during specified times from your list of Pros.': {
        pt: 'O TIDY pode agendar limpezas de rotatividade durante horários específicos da sua lista de Profissionais.',
        es: 'TIDY puede reservar limpiezas de rotación durante horarios específicos de su lista de Profesionales.',
        ru: 'TIDY может бронировать уборки при смене гостей в указанное время из вашего списка Профессионалов.',
        fr: 'TIDY peut réserver des nettoyages de rotation pendant des horaires spécifiés à partir de votre liste de Pros.',
        de: 'TIDY kann Wechselreinigungen zu bestimmten Zeiten aus Ihrer Liste von Profis buchen.'
    },
    'Track Linked Reservations': {
        pt: 'Rastrear Reservas Vinculadas',
        es: 'Seguimiento de Reservas Vinculadas',
        ru: 'Отслеживание Связанных Бронирований',
        fr: 'Suivre les Réservations Liées',
        de: 'Verknüpfte Reservierungen Verfolgen'
    },
    'You can manage reservations for multiple properties across multiple sources.': {
        pt: 'Você pode gerenciar reservas para várias propriedades em várias fontes.',
        es: 'Puede gestionar reservas para múltiples propiedades a través de múltiples fuentes.',
        ru: 'Вы можете управлять бронированиями для нескольких объектов из нескольких источников.',
        fr: 'Vous pouvez gérer les réservations pour plusieurs propriétés à travers plusieurs sources.',
        de: 'Sie können Reservierungen für mehrere Immobilien über mehrere Quellen hinweg verwalten.'
    },
    'Searching': {
        pt: 'Pesquisando',
        es: 'Buscando',
        ru: 'Поиск',
        fr: 'Recherche',
        de: 'Suche'
    },
    '1 Hour Cleaning': {
        pt: 'Limpeza de 1 Hora',
        es: 'Limpieza de 1 Hora',
        ru: 'Уборка на 1 Час',
        fr: 'Nettoyage d\'1 Heure',
        de: '1 Stunde Reinigung'
    },
    '1 hour cleaning': {
        pt: 'limpeza de 1 hora',
        es: 'limpieza de 1 hora',
        ru: 'уборка на 1 час',
        fr: 'nettoyage d\'1 heure',
        de: '1 stunde reinigung'
    },
    '2.5 Hour Cleaning': {
        pt: 'Limpeza de 2,5 Horas',
        es: 'Limpieza de 2,5 Horas',
        ru: 'Уборка на 2,5 Часа',
        fr: 'Nettoyage de 2,5 Heures',
        de: '2,5 Stunden Reinigung'
    },
    '2.5 hour cleaning': {
        pt: 'limpeza de 2,5 horas',
        es: 'limpieza de 2,5 horas',
        ru: 'уборка на 2,5 часа',
        fr: 'nettoyage de 2,5 heures',
        de: '2,5 stunden reinigung'
    },
    'Update Integration': {
        pt: 'Atualizar Integração',
        es: 'Actualizar Integración',
        ru: 'Обновить Интеграцию',
        fr: 'Mettre à Jour l\'Intégration',
        de: 'Integration Aktualisieren'
    },
    'Add Integration': {
        pt: 'Adicionar Integração',
        es: 'Añadir Integración',
        ru: 'Добавить Интеграцию',
        fr: 'Ajouter une Intégration',
        de: 'Integration Hinzufügen'
    },
    'When importing addresses, we will attempt to match your existing addresses. If we can\'t find a match, we will make a new one.': {
        pt: 'Ao importar endereços, tentaremos corresponder aos seus endereços existentes. Se não encontrarmos uma correspondência, criaremos um novo.',
        es: 'Al importar direcciones, intentaremos hacer coincidir con sus direcciones existentes. Si no podemos encontrar una coincidencia, crearemos una nueva.',
        ru: 'При импорте адресов мы попытаемся сопоставить их с вашими существующими адресами. Если мы не сможем найти соответствие, мы создадим новый адрес.',
        fr: 'Lors de l\'importation d\'adresses, nous essaierons de faire correspondre vos adresses existantes. Si nous ne trouvons pas de correspondance, nous en créerons une nouvelle.',
        de: 'Beim Importieren von Adressen versuchen wir, Ihre bestehenden Adressen abzugleichen. Wenn wir keine Übereinstimmung finden, erstellen wir eine neue.'
    },
    'When importing, we will add all addresses from your integration. If you already have an address, a duplicate may be made and you will need to remove them manually.': {
        pt: 'Ao importar, adicionaremos todos os endereços da sua integração. Se você já tiver um endereço, um duplicado pode ser criado e você precisará removê-los manualmente.',
        es: 'Al importar, agregaremos todas las direcciones de su integración. Si ya tiene una dirección, se puede crear un duplicado y deberá eliminarlos manualmente.',
        ru: 'При импорте мы добавим все адреса из вашей интеграции. Если у вас уже есть адрес, может быть создан дубликат, и вам нужно будет удалить их вручную.',
        fr: 'Lors de l\'importation, nous ajouterons toutes les adresses de votre intégration. Si vous avez déjà une adresse, un doublon peut être créé et vous devrez les supprimer manuellement.',
        de: 'Beim Importieren fügen wir alle Adressen aus Ihrer Integration hinzu. Wenn Sie bereits eine Adresse haben, kann ein Duplikat erstellt werden und Sie müssen diese manuell entfernen.'
    },
    'Concierge Integration Syncing': {
        pt: 'Sincronização de Integração do Concierge',
        es: 'Sincronización de Integración del Conserje',
        ru: 'Синхронизация Интеграции Консьержа',
        fr: 'Synchronisation de l\'Intégration Concierge',
        de: 'Concierge-Integrationssynchronisierung'
    },
    'Our Concierge will log into your systems to get the data you want and reach out with any questions.': {
        pt: 'Nosso Concierge fará login em seus sistemas para obter os dados que você deseja e entrará em contato com quaisquer perguntas.',
        es: 'Nuestro Conserje iniciará sesión en sus sistemas para obtener los datos que desea y se comunicará con cualquier pregunta.',
        ru: 'Наш Консьерж войдет в ваши системы, чтобы получить нужные вам данные, и свяжется с вами, если возникнут вопросы.',
        fr: 'Notre Concierge se connectera à vos systèmes pour obtenir les données que vous souhaitez et vous contactera pour toute question.',
        de: 'Unser Concierge meldet sich in Ihren Systemen an, um die gewünschten Daten zu erhalten und bei Fragen auf Sie zuzukommen.'
    },
    'Disable Integration?': {
        pt: 'Desativar Integração?',
        es: '¿Desactivar Integración?',
        ru: 'Отключить Интеграцию?',
        fr: 'Désactiver l\'Intégration?',
        de: 'Integration Deaktivieren?'
    },
    'Are you sure you want to disable this integration?  This will remove any reservations from TIDY.': {
        pt: 'Tem certeza de que deseja desativar esta integração? Isso removerá todas as reservas do TIDY.',
        es: '¿Está seguro de que desea desactivar esta integración? Esto eliminará todas las reservas de TIDY.',
        ru: 'Вы уверены, что хотите отключить эту интеграцию? Это удалит все бронирования из TIDY.',
        fr: 'Êtes-vous sûr de vouloir désactiver cette intégration ? Cela supprimera toutes les réservations de TIDY.',
        de: 'Sind Sie sicher, dass Sie diese Integration deaktivieren möchten? Dies wird alle Reservierungen von TIDY entfernen.'
    },
    'Adding OwnerRez Integration': {
        pt: 'Adicionando Integração OwnerRez',
        es: 'Añadiendo Integración OwnerRez',
        ru: 'Добавление Интеграции OwnerRez',
        fr: 'Ajout de l\'Intégration OwnerRez',
        de: 'Hinzufügen der OwnerRez-Integration'
    },
    'Adding RemoteLock Integration': {
        pt: 'Adicionando Integração RemoteLock',
        es: 'Añadiendo Integración RemoteLock',
        ru: 'Добавление Интеграции RemoteLock',
        fr: 'Ajout de l\'Intégration RemoteLock',
        de: 'Hinzufügen der RemoteLock-Integration'
    },
    'Adding Smoobu Integration': {
        pt: 'Adicionando Integração Smoobu',
        es: 'Añadiendo Integración Smoobu',
        ru: 'Добавление Интеграции Smoobu',
        fr: 'Ajout de l\'Intégration Smoobu',
        de: 'Hinzufügen der Smoobu-Integration'
    },
    'Secret': {
        pt: 'Segredo',
        es: 'Secreto',
        ru: 'Секрет',
        fr: 'Secret',
        de: 'Geheimnis'
    },
    'API Access Token': {
        pt: 'Token de Acesso à API',
        es: 'Token de Acceso a la API',
        ru: 'Токен Доступа к API',
        fr: 'Jeton d\'Accès API',
        de: 'API-Zugriffstoken'
    },
    'Client Secret': {
        pt: 'Segredo do Cliente',
        es: 'Secreto del Cliente',
        ru: 'Секрет Клиента',
        fr: 'Secret Client',
        de: 'Client-Geheimnis'
    },
    'API Key': {
        pt: 'Chave da API',
        es: 'Clave de API',
        ru: 'Ключ API',
        fr: 'Clé API',
        de: 'API-Schlüssel'
    },
    'Client ID': {
        pt: 'ID do Cliente',
        es: 'ID del Cliente',
        ru: 'ID Клиента',
        fr: 'ID Client',
        de: 'Client-ID'
    },
    'Account ID': {
        pt: 'ID da Conta',
        es: 'ID de la Cuenta',
        ru: 'ID Аккаунта',
        fr: 'ID du Compte',
        de: 'Konto-ID'
    },
    'Request Integration': {
        pt: 'Solicitar Integração',
        es: 'Solicitar Integración',
        ru: 'Запросить Интеграцию',
        fr: 'Demander une Intégration',
        de: 'Integration Anfordern'
    },
    'Integration Requested': {
        pt: 'Integração Solicitada',
        es: 'Integración Solicitada',
        ru: 'Интеграция Запрошена',
        fr: 'Intégration Demandée',
        de: 'Integration Angefordert'
    },
    'Our Concierge will reach out in the next 1-2 business days.': {
        pt: 'Nosso Concierge entrará em contato nos próximos 1-2 dias úteis.',
        es: 'Nuestro Conserje se pondrá en contacto en los próximos 1-2 días hábiles.',
        ru: 'Наш Консьерж свяжется с вами в течение следующих 1-2 рабочих дней.',
        fr: 'Notre Concierge vous contactera dans les 1 à 2 jours ouvrables.',
        de: 'Unser Concierge wird sich in den nächsten 1-2 Werktagen bei Ihnen melden.'
    },
    'You have already voted on this feature!': {
        pt: 'Você já votou neste recurso!',
        es: '¡Ya has votado por esta función!',
        ru: 'Вы уже проголосовали за эту функцию!',
        fr: 'Vous avez déjà voté pour cette fonctionnalité !',
        de: 'Sie haben bereits für diese Funktion gestimmt!'
    },
    'Integration Already Requested': {
        pt: 'Integração Já Solicitada',
        es: 'Integración Ya Solicitada',
        ru: 'Интеграция Уже Запрошена',
        fr: 'Intégration Déjà Demandée',
        de: 'Integration Bereits Angefordert'
    },
    'Our Concierge will reach out in the next 1-2 business days from when you requested this integration. Thank you for your patience.': {
        pt: 'Nosso Concierge entrará em contato nos próximos 1-2 dias úteis a partir do momento em que você solicitou esta integração. Obrigado pela sua paciência.',
        es: 'Nuestro Conserje se pondrá en contacto en los próximos 1-2 días hábiles desde que solicitó esta integración. Gracias por su paciencia.',
        ru: 'Наш Консьерж свяжется с вами в течение следующих 1-2 рабочих дней с момента вашего запроса на эту интеграцию. Спасибо за ваше терпение.',
        fr: 'Notre Concierge vous contactera dans les 1 à 2 jours ouvrables suivant votre demande d\'intégration. Merci pour votre patience.',
        de: 'Unser Concierge wird sich in den nächsten 1-2 Werktagen ab dem Zeitpunkt Ihrer Integrationsanfrage bei Ihnen melden. Vielen Dank für Ihre Geduld.'
    },

    //EditAddressLockPage
    'Integrate Device(s)': {
        pt: 'Integrar Dispositivo(s)',
        es: 'Integrar Dispositivo(s)',
        ru: 'Интегрировать Устройство(а)',
        fr: 'Intégrer Appareil(s)',
        de: 'Gerät(e) Integrieren'
    },
    'Select which RemoteLock location/device(s) to associate with above TIDY property:': {
        pt: 'Selecione qual(is) localização/dispositivo(s) RemoteLock associar à propriedade TIDY acima:',
        es: 'Seleccione qué ubicación/dispositivo(s) de RemoteLock asociar con la propiedad TIDY anterior:',
        ru: 'Выберите, какое местоположение/устройство(а) RemoteLock связать с вышеуказанным объектом TIDY:',
        fr: 'Sélectionnez quel(s) emplacement(s)/appareil(s) RemoteLock associer à la propriété TIDY ci-dessus :',
        de: 'Wählen Sie aus, welche(r) RemoteLock-Standort(e)/Gerät(e) mit der obigen TIDY-Immobilie verknüpft werden soll(en):'
    },
    'Select Location': {
        pt: 'Selecionar Localização',
        es: 'Seleccionar Ubicación',
        ru: 'Выбрать Местоположение',
        fr: 'Sélectionner l\'Emplacement',
        de: 'Standort Auswählen'
    },
    'Select device(s) at': {
        pt: 'Selecionar dispositivo(s) em',
        es: 'Seleccionar dispositivo(s) en',
        ru: 'Выбрать устройство(а) в',
        fr: 'Sélectionner appareil(s) à',
        de: 'Gerät(e) auswählen bei'
    },
    'No devices added for this address.': {
        pt: 'Nenhum dispositivo adicionado para este endereço.',
        es: 'No se han añadido dispositivos para esta dirección.',
        ru: 'Для этого адреса не добавлено устройств.',
        fr: 'Aucun appareil ajouté pour cette adresse.',
        de: 'Keine Geräte für diese Adresse hinzugefügt.'
    },

    //EnableAutomaticBookingPage
    'Integration Added': {
        pt: 'Integração Adicionada',
        es: 'Integración Añadida',
        ru: 'Интеграция Добавлена',
        fr: 'Intégration Ajoutée',
        de: 'Integration Hinzugefügt'
    },
    'Use Automatic Booking': {
        pt: 'Usar Reserva Automática',
        es: 'Usar Reserva Automática',
        ru: 'Использовать Автоматическое Бронирование',
        fr: 'Utiliser la Réservation Automatique',
        de: 'Automatische Buchung Verwenden'
    },
    'When a reservation is synced, automatically add a job for your pros. Cancel anytime.': {
        pt: 'Quando uma reserva é sincronizada, adicione automaticamente um trabalho para seus profissionais. Cancele a qualquer momento.',
        es: 'Cuando se sincroniza una reserva, agregue automáticamente un trabajo para sus profesionales. Cancele en cualquier momento.',
        ru: 'Когда бронирование синхронизируется, автоматически добавляйте задание для ваших специалистов. Отмените в любое время.',
        fr: 'Lorsqu\'une réservation est synchronisée, ajoutez automatiquement un travail pour vos professionnels. Annulez à tout moment.',
        de: 'Wenn eine Reservierung synchronisiert wird, fügen Sie automatisch einen Auftrag für Ihre Profis hinzu. Jederzeit kündbar.'
    },
    'Type of job to book': {
        pt: 'Tipo de trabalho a reservar',
        es: 'Tipo de trabajo a reservar',
        ru: 'Тип работы для бронирования',
        fr: 'Type de travail à réserver',
        de: 'Art des zu buchenden Auftrags'
    },
    'Earliest start (typically the check out time)': {
        pt: 'Início mais cedo (normalmente a hora de check-out)',
        es: 'Inicio más temprano (típicamente la hora de salida)',
        ru: 'Самое раннее начало (обычно время выезда)',
        fr: 'Début au plus tôt (généralement l\'heure de départ)',
        de: 'Frühester Start (typischerweise die Check-out-Zeit)'
    },
    'Latest finish (typically the check in time)': {
        pt: 'Término mais tarde (normalmente a hora de check-in)',
        es: 'Finalización más tardía (típicamente la hora de entrada)',
        ru: 'Самое позднее завершение (обычно время заезда)',
        fr: 'Fin au plus tard (généralement l\'heure d\'arrivée)',
        de: 'Spätestes Ende (typischerweise die Check-in-Zeit)'
    },
    'Schedule jobs no later than': {
        pt: 'Agendar trabalhos no máximo até',
        es: 'Programar trabajos a más tardar',
        ru: 'Планировать задания не позднее чем',
        fr: 'Planifier les travaux au plus tard',
        de: 'Aufträge spätestens planen bis'
    },
    '<b>Warning:</b> This setting makes it more difficult to fill jobs.': {
        pt: '<b>Aviso:</b> Esta configuração torna mais difícil preencher vagas.',
        es: '<b>Advertencia:</b> Esta configuración dificulta la ocupación de trabajos.',
        ru: '<b>Предупреждение:</b> Эта настройка усложняет заполнение вакансий.',
        fr: '<b>Avertissement :</b> Ce paramètre rend plus difficile le remplissage des emplois.',
        de: '<b>Warnung:</b> Diese Einstellung erschwert das Besetzen von Aufträgen.'
    },
    'This means pros are only allowed to accept a job same day as checkout. If they can\'t do it then, your job request will go to other pros following your priorities. This is recommended only if you commonly get last minute bookings within 1 day of a prior booking, and are ok with not getting the pro you prefer.': {
        pt: 'Isso significa que os profissionais só podem aceitar um trabalho no mesmo dia do check-out. Se eles não puderem fazê-lo então, sua solicitação de trabalho irá para outros profissionais seguindo suas prioridades. Isso é recomendado apenas se você geralmente recebe reservas de última hora dentro de 1 dia de uma reserva anterior e está ok em não conseguir o profissional que prefere.',
        es: 'Esto significa que los profesionales solo pueden aceptar un trabajo el mismo día de la salida. Si no pueden hacerlo entonces, su solicitud de trabajo irá a otros profesionales siguiendo sus prioridades. Esto se recomienda solo si comúnmente recibe reservas de último minuto dentro de 1 día de una reserva anterior y está de acuerdo con no obtener el profesional que prefiere.',
        ru: 'Это означает, что специалисты могут принять работу только в день выезда. Если они не могут сделать это тогда, ваш запрос на работу перейдет к другим специалистам в соответствии с вашими приоритетами. Это рекомендуется только если вы часто получаете бронирования в последнюю минуту в течение 1 дня после предыдущего бронирования и вас устраивает, что вы не получите предпочитаемого специалиста.',
        fr: 'Cela signifie que les professionnels ne sont autorisés à accepter un travail que le jour même du départ. S\'ils ne peuvent pas le faire à ce moment-là, votre demande de travail ira à d\'autres professionnels selon vos priorités. Ceci n\'est recommandé que si vous recevez couramment des réservations de dernière minute dans la journée suivant une réservation précédente et si vous acceptez de ne pas obtenir le professionnel que vous préférez.',
        de: 'Das bedeutet, dass Profis einen Auftrag nur am selben Tag wie der Check-out annehmen dürfen. Wenn sie es dann nicht können, geht Ihre Auftragsanfrage an andere Profis entsprechend Ihren Prioritäten. Dies wird nur empfohlen, wenn Sie häufig Last-Minute-Buchungen innerhalb von 1 Tag nach einer vorherigen Buchung erhalten und damit einverstanden sind, nicht den von Ihnen bevorzugten Profi zu bekommen.'
    },
    'Pros are notified that a job same day as checkout is preferred, but are allowed to accept jobs up to': {
        pt: 'Os profissionais são notificados de que um trabalho no mesmo dia do check-out é preferido, mas podem aceitar trabalhos até',
        es: 'Se notifica a los profesionales que se prefiere un trabajo el mismo día de la salida, pero se les permite aceptar trabajos hasta',
        ru: 'Специалисты уведомляются о том, что предпочтительна работа в день выезда, но им разрешено принимать задания до',
        fr: 'Les professionnels sont informés qu\'un travail le jour même du départ est préféré, mais sont autorisés à accepter des travaux jusqu\'à',
        de: 'Profis werden benachrichtigt, dass ein Auftrag am selben Tag wie der Check-out bevorzugt wird, dürfen aber Aufträge bis zu'
    },
    'days later or before check in, whatever is first. For most people, we recommend allowing up to 3 days before checkout, as it helps you get your favorite pros while still getting jobs done before check in.': {
        pt: 'dias depois ou antes do check-in, o que vier primeiro. Para a maioria das pessoas, recomendamos permitir até 3 dias antes do check-out, pois isso ajuda você a conseguir seus profissionais favoritos enquanto ainda conclui os trabalhos antes do check-in.',
        es: 'días después o antes de la entrada, lo que ocurra primero. Para la mayoría de las personas, recomendamos permitir hasta 3 días antes de la salida, ya que le ayuda a conseguir sus profesionales favoritos mientras aún se realizan los trabajos antes de la entrada.',
        ru: 'дней позже или до заезда, в зависимости от того, что наступит раньше. Для большинства людей мы рекомендуем разрешить до 3 дней до выезда, так как это помогает вам получить ваших любимых специалистов, при этом работа выполняется до заезда.',
        fr: 'jours plus tard ou avant l\'arrivée, selon la première éventualité. Pour la plupart des gens, nous recommandons d\'autoriser jusqu\'à 3 jours avant le départ, car cela vous aide à obtenir vos professionnels préférés tout en faisant le travail avant l\'arrivée.',
        de: 'Tage später oder vor dem Check-in, je nachdem, was zuerst eintritt. Für die meisten Leute empfehlen wir, bis zu 3 Tage vor dem Check-out zu erlauben, da es Ihnen hilft, Ihre Lieblingsprofis zu bekommen, während die Aufträge trotzdem vor dem Check-in erledigt werden.'
    },
    '<b>Example:</b>': {
        pt: '<b>Exemplo:</b>',
        es: '<b>Ejemplo:</b>',
        ru: '<b>Пример:</b>',
        fr: '<b>Exemple :</b>',
        de: '<b>Beispiel:</b>'
    },
    'Next synced reservation:': {
        pt: 'Próxima reserva sincronizada:',
        es: 'Próxima reserva sincronizada:',
        ru: 'Следующее синхронизированное бронирование:',
        fr: 'Prochaine réservation synchronisée :',
        de: 'Nächste synchronisierte Reservierung:'
    },
    'Reservation after that:': {
        pt: 'Reserva após essa:',
        es: 'Reserva después de esa:',
        ru: 'Бронирование после этого:',
        fr: 'Réservation après celle-ci :',
        de: 'Reservierung danach:'
    },
    'These settings would add a': {
        pt: 'Estas configurações adicionariam um ',
        es: 'Estas configuraciones agregarían un ',
        ru: 'Эти настройки добавят ',
        fr: 'Ces paramètres ajouteraient un ',
        de: 'Diese Einstellungen würden ein hinzufügen'
    },
    'to start no earlier than March 6,': {
        pt: 'para começar não antes de 6 de março,',
        es: 'para comenzar no antes del 6 de marzo,',
        ru: 'чтобы начать не ранее 6 марта,',
        fr: 'pour commencer au plus tôt le 6 mars,',
        de: 'um frühestens am 6. März zu beginnen,'
    },
    '& be completed before March 8,': {
        pt: 'e ser concluído antes de 8 de março,',
        es: 'y completarse antes del 8 de marzo,',
        ru: 'и быть завершенным до 8 марта,',
        fr: 'et être terminé avant le 8 mars,',
        de: 'und vor dem 8. März abgeschlossen zu sein,'
    },
    'Save Settings': {
        pt: 'Salvar Configurações',
        es: 'Guardar Configuración',
        ru: 'Сохранить настройки',
        fr: 'Enregistrer les paramètres',
        de: 'Einstellungen speichern'
    },
    'Note: You can disable automatic booking for individual reservations & cancel jobs anytime.': {
        pt: 'Nota: Você pode desativar a reserva automática para reservas individuais e cancelar trabalhos a qualquer momento.',
        es: 'Nota: Puede desactivar la reserva automática para reservas individuales y cancelar trabajos en cualquier momento.',
        ru: 'Примечание: Вы можете отключить автоматическое бронирование для отдельных резерваций и отменить задания в любое время.',
        fr: 'Remarque : Vous pouvez désactiver la réservation automatique pour les réservations individuelles et annuler les travaux à tout moment.',
        de: 'Hinweis: Sie können die automatische Buchung für einzelne Reservierungen deaktivieren und Aufträge jederzeit stornieren.'
    },
    'Sync Pending. It looks like the iCal link is valid, but sometimes it takes a few hours to sync data or you don\'t have any guest reservations yet. Please check your "Schedule" section later to ensure everything looks right.': {
        pt: 'Sincronização pendente. Parece que o link iCal é válido, mas às vezes leva algumas horas para sincronizar os dados ou você ainda não tem reservas de hóspedes. Por favor, verifique sua seção "Agenda" mais tarde para garantir que tudo esteja correto.',
        es: 'Sincronización pendiente. Parece que el enlace iCal es válido, pero a veces tarda unas horas en sincronizar los datos o aún no tienes reservas de huéspedes. Por favor, revisa tu sección "Horario" más tarde para asegurarte de que todo esté bien.',
        ru: 'Ожидается синхронизация. Похоже, что ссылка iCal действительна, но иногда требуется несколько часов для синхронизации данных, или у вас еще нет бронирований гостей. Пожалуйста, проверьте раздел "Расписание" позже, чтобы убедиться, что все в порядке.',
        fr: 'Synchronisation en attente. Il semble que le lien iCal soit valide, mais parfois il faut quelques heures pour synchroniser les données ou vous n\'avez pas encore de réservations d\'invités. Veuillez vérifier votre section "Calendrier" plus tard pour vous assurer que tout est correct.',
        de: 'Synchronisierung ausstehend. Es scheint, dass der iCal-Link gültig ist, aber manchmal dauert es einige Stunden, um Daten zu synchronisieren, oder Sie haben noch keine Gästereservierungen. Bitte überprüfen Sie Ihren "Zeitplan"-Bereich später, um sicherzustellen, dass alles richtig aussieht.'
    },
    'Sync Pending. Please check your "Schedule" section later to ensure everything looks right.': {
        pt: 'Sincronização pendente. Por favor, verifique sua seção "Agenda" mais tarde para garantir que tudo esteja correto.',
        es: 'Sincronización pendiente. Por favor, revisa tu sección "Horario" más tarde para asegurarte de que todo esté bien.',
        ru: 'Ожидается синхронизация. Пожалуйста, проверьте раздел "Расписание" позже, чтобы убедиться, что все в порядке.',
        fr: 'Synchronisation en attente. Veuillez vérifier votre section "Calendrier" plus tard pour vous assurer que tout est correct.',
        de: 'Synchronisierung ausstehend. Bitte überprüfen Sie Ihren "Zeitplan"-Bereich später, um sicherzustellen, dass alles richtig aussieht.'
    },
    'Sync Success! You can view all your synced guest reservations in the "Schedule" section.': {
        pt: 'Sincronização bem-sucedida! Você pode ver todas as suas reservas de hóspedes sincronizadas na seção "Agenda".',
        es: '¡Sincronización exitosa! Puedes ver todas tus reservas de huéspedes sincronizadas en la sección "Horario".',
        ru: 'Синхронизация успешна! Вы можете просмотреть все синхронизированные бронирования гостей в разделе "Расписание".',
        fr: 'Synchronisation réussie ! Vous pouvez voir toutes vos réservations d\'invités synchronisées dans la section "Calendrier".',
        de: 'Synchronisierung erfolgreich! Sie können alle Ihre synchronisierten Gästereservierungen im Bereich "Zeitplan" einsehen.'
    },
    'Automatic Booking Enabled!': {
        pt: 'Reserva Automática Ativada!',
        es: '¡Reserva Automática Activada!',
        ru: 'Автоматическое бронирование включено!',
        fr: 'Réservation Automatique Activée !',
        de: 'Automatische Buchung Aktiviert!'
    },
    'It may take up to 5 minutes for job requests for your synced reservations to appear. You can add any existing Pro to your Pro priority list if you want them to accept jobs (working with existing Pros is free).': {
        pt: 'Pode levar até 5 minutos para que as solicitações de trabalho para suas reservas sincronizadas apareçam. Você pode adicionar qualquer Pro existente à sua lista de prioridade de Pros se quiser que eles aceitem trabalhos (trabalhar com Pros existentes é gratuito).',
        es: 'Puede tardar hasta 5 minutos en que aparezcan las solicitudes de trabajo para tus reservas sincronizadas. Puedes agregar cualquier Pro existente a tu lista de prioridad de Pros si quieres que acepten trabajos (trabajar con Pros existentes es gratis).',
        ru: 'Может потребоваться до 5 минут, чтобы появились запросы на работу для ваших синхронизированных бронирований. Вы можете добавить любого существующего Pro в свой список приоритетов Pro, если хотите, чтобы они принимали задания (работа с существующими Pro бесплатна).',
        fr: 'Il peut falloir jusqu\'à 5 minutes pour que les demandes de travail pour vos réservations synchronisées apparaissent. Vous pouvez ajouter n\'importe quel Pro existant à votre liste de priorité Pro si vous voulez qu\'ils acceptent des travaux (travailler avec des Pros existants est gratuit).',
        de: 'Es kann bis zu 5 Minuten dauern, bis Arbeitsanfragen für Ihre synchronisierten Reservierungen erscheinen. Sie können jeden bestehenden Pro zu Ihrer Pro-Prioritätsliste hinzufügen, wenn Sie möchten, dass sie Aufträge annehmen (die Arbeit mit bestehenden Pros ist kostenlos).'
    },
    'Review Pro Priority List': {
        pt: 'Revisar Lista de Prioridade de Pros',
        es: 'Revisar Lista de Prioridad de Pros',
        ru: 'Просмотреть список приоритетов Pro',
        fr: 'Examiner la Liste de Priorité des Pros',
        de: 'Pro-Prioritätsliste überprüfen'
    },
    'Automatic Booking Disabled': {
        pt: 'Reserva Automática Desativada',
        es: 'Reserva Automática Desactivada',
        ru: 'Автоматическое бронирование отключено',
        fr: 'Réservation Automatique Désactivée',
        de: 'Automatische Buchung Deaktiviert'
    },
    'The same day as the checkout': {
        pt: 'No mesmo dia do check-out',
        es: 'El mismo día del check-out',
        ru: 'В тот же день, что и выезд',
        fr: 'Le même jour que le départ',
        de: 'Am selben Tag wie der Check-out'
    },
    '1 day after checkout': {
        pt: '1 dia após o check-out',
        es: '1 día después del check-out',
        ru: '1 день после выезда',
        fr: '1 jour après le départ',
        de: '1 Tag nach dem Check-out'
    },
    '2 days after checkout': {
        pt: '2 dias após o check-out',
        es: '2 días después del check-out',
        ru: '2 дня после выезда',
        fr: '2 jours après le départ',
        de: '2 Tage nach dem Check-out'
    },
    '3 days after checkout': {
        pt: '3 dias após o check-out',
        es: '3 días después del check-out',
        ru: '3 дня после выезда',
        fr: '3 jours après le départ',
        de: '3 Tage nach dem Check-out'
    },

    //IntegrationOptionsPage
    //IntegrationsPage
    'My Integrations': {
        pt: 'Minhas Integrações',
        es: 'Mis Integraciones',
        ru: 'Мои Интеграции',
        fr: 'Mes Intégrations',
        de: 'Meine Integrationen'
    },
    'No integrations added': {
        pt: 'Nenhuma integração adicionada',
        es: 'No se han añadido integraciones',
        ru: 'Интеграции не добавлены',
        fr: 'Aucune intégration ajoutée',
        de: 'Keine Integrationen hinzugefügt'
    },
    'Integrated Devices:': {
        pt: 'Dispositivos Integrados:',
        es: 'Dispositivos Integrados:',
        ru: 'Интегрированные Устройства:',
        fr: 'Appareils Intégrés:',
        de: 'Integrierte Geräte:'
    },
    'Disable Integration': {
        pt: 'Desativar Integração',
        es: 'Desactivar Integración',
        ru: 'Отключить Интеграцию',
        fr: 'Désactiver l\'Intégration',
        de: 'Integration Deaktivieren'
    },
    'Team:': {
        pt: 'Equipe:',
        es: 'Equipo:',
        ru: 'Команда:',
        fr: 'Équipe:',
        de: 'Team:'
    },
    'Concierge Integration': {
        pt: 'Integração de Concierge',
        es: 'Integración de Conserjería',
        ru: 'Интеграция Консьержа',
        fr: 'Intégration Conciergerie',
        de: 'Concierge-Integration'
    },
    'iCal Link:': {
        pt: 'Link iCal:',
        es: 'Enlace iCal:',
        ru: 'Ссылка iCal:',
        fr: 'Lien iCal:',
        de: 'iCal-Link:'
    },
    'Last checked': {
        pt: 'Última verificação',
        es: 'Última comprobación',
        ru: 'Последняя проверка',
        fr: 'Dernière vérification',
        de: 'Zuletzt überprüft'
    },
    'Synced addresses:': {
        pt: 'Endereços sincronizados:',
        es: 'Direcciones sincronizadas:',
        ru: 'Синхронизированные адреса:',
        fr: 'Adresses synchronisées:',
        de: 'Synchronisierte Adressen:'
    },
    'Sync Pending..': {
        pt: 'Sincronização Pendente..',
        es: 'Sincronización Pendiente..',
        ru: 'Ожидание Синхронизации..',
        fr: 'Synchronisation en Attente..',
        de: 'Synchronisierung Ausstehend..'
    },
    'Syncing guest reservations..': {
        pt: 'Sincronizando reservas de hóspedes..',
        es: 'Sincronizando reservas de huéspedes..',
        ru: 'Синхронизация бронирований гостей..',
        fr: 'Synchronisation des réservations des invités..',
        de: 'Synchronisierung der Gästereservierungen..'
    },
    'Issue Syncing': {
        pt: 'Problema na Sincronização',
        es: 'Problema de Sincronización',
        ru: 'Проблема Синхронизации',
        fr: 'Problème de Synchronisation',
        de: 'Synchronisierungsproblem'
    },
    'There was an issue syncing this integration. Please update your integration details:': {
        pt: 'Houve um problema ao sincronizar esta integração. Por favor, atualize os detalhes da sua integração:',
        es: 'Hubo un problema al sincronizar esta integración. Por favor, actualice los detalles de su integración:',
        ru: 'Возникла проблема при синхронизации этой интеграции. Пожалуйста, обновите детали вашей интеграции:',
        fr: 'Il y a eu un problème lors de la synchronisation de cette intégration. Veuillez mettre à jour les détails de votre intégration:',
        de: 'Es gab ein Problem bei der Synchronisierung dieser Integration. Bitte aktualisieren Sie Ihre Integrationsdetails:'
    },
    'Update Integration Details': {
        pt: 'Atualizar Detalhes da Integração',
        es: 'Actualizar Detalles de la Integración',
        ru: 'Обновить Детали Интеграции',
        fr: 'Mettre à Jour les Détails de l\'Intégration',
        de: 'Integrationsdetails Aktualisieren'
    },
    'Automatic Booking': {
        pt: 'Reserva Automática',
        es: 'Reserva Automática',
        ru: 'Автоматическое Бронирование',
        fr: 'Réservation Automatique',
        de: 'Automatische Buchung'
    },
    'Add Integrations': {
        pt: 'Adicionar Integrações',
        es: 'Añadir Integraciones',
        ru: 'Добавить Интеграции',
        fr: 'Ajouter des Intégrations',
        de: 'Integrationen Hinzufügen'
    },
    'No results': {
        pt: 'Sem resultados',
        es: 'Sin resultados',
        ru: 'Нет результатов',
        fr: 'Aucun résultat',
        de: 'Keine Ergebnisse'
    },
    'This will remove any existing reservations from TIDY.': {
        pt: 'Isso removerá todas as reservas existentes do TIDY.',
        es: 'Esto eliminará todas las reservas existentes de TIDY.',
        ru: 'Это удалит все существующие бронирования из TIDY.',
        fr: 'Cela supprimera toutes les réservations existantes de TIDY.',
        de: 'Dies entfernt alle bestehenden Reservierungen von TIDY.'
    },
    'Disable': {
        pt: 'Desativar',
        es: 'Desactivar',
        ru: 'Отключить',
        fr: 'Désactiver',
        de: 'Deaktivieren'
    },
    'Integrations Help': {
        pt: 'Ajuda com Integrações',
        es: 'Ayuda con Integraciones',
        ru: 'Помощь по Интеграциям',
        fr: 'Aide sur les Intégrations',
        de: 'Hilfe zu Integrationen'
    },
    'Copied to clipboard': {
        pt: 'Copiado para a área de transferência',
        es: 'Copiado al portapapeles',
        ru: 'Скопировано в буфер обмена',
        fr: 'Copié dans le presse-papiers',
        de: 'In die Zwischenablage kopiert'
    },
    'Close': {
        pt: 'Fechar',
        es: 'Cerrar',
        ru: 'Закрыть',
        fr: 'Fermer',
        de: 'Schließen'
    },
    //RemoteLockPage
    'TIDY will automatically generate a lock code through RemoteLock for each job.': {
        pt: 'O TIDY irá gerar automaticamente um código de bloqueio através do RemoteLock para cada trabalho.',
        es: 'TIDY generará automáticamente un código de bloqueo a través de RemoteLock para cada trabajo.',
        ru: 'TIDY автоматически сгенерирует код блокировки через RemoteLock для каждой работы.',
        fr: 'TIDY générera automatiquement un code de verrouillage via RemoteLock pour chaque travail.',
        de: 'TIDY wird automatisch einen Sperrcode über RemoteLock für jeden Auftrag generieren.'
    },
    'No Devices Integrated': {
        pt: 'Nenhum Dispositivo Integrado',
        es: 'No Hay Dispositivos Integrados',
        ru: 'Нет Интегрированных Устройств',
        fr: 'Aucun Appareil Intégré',
        de: 'Keine Geräte Integriert'
    },
    //RequestIntegrationPage
    'Instructions for your vendors': {
        pt: 'Instruções para seus fornecedores',
        es: 'Instrucciones para sus proveedores',
        ru: 'Инструкции для ваших поставщиков',
        fr: 'Instructions pour vos fournisseurs',
        de: 'Anweisungen für Ihre Lieferanten'
    },
    'Number of properties to use for this': {
        pt: 'Número de propriedades a serem usadas para isso',
        es: 'Número de propiedades a utilizar para esto',
        ru: 'Количество объектов недвижимости для использования',
        fr: 'Nombre de propriétés à utiliser pour cela',
        de: 'Anzahl der Immobilien, die hierfür verwendet werden sollen'
    },
    'Any notes for our concierge?': {
        pt: 'Alguma observação para o nosso concierge?',
        es: '¿Alguna nota para nuestro conserje?',
        ru: 'Есть ли заметки для нашего консьержа?',
        fr: 'Des notes pour notre concierge ?',
        de: 'Irgendwelche Notizen für unseren Concierge?'
    },
    'By integrating with smart thermostats like': {
        pt: 'Ao integrar com termostatos inteligentes como',
        es: 'Al integrar con termostatos inteligentes como',
        ru: 'Интегрируясь с умными термостатами, такими как',
        fr: 'En intégrant des thermostats intelligents comme',
        de: 'Durch die Integration mit intelligenten Thermostaten wie'
    },
    'you can set up rules for how temperature should work around jobs. Popular examples of uses include:': {
        pt: 'você pode configurar regras para como a temperatura deve funcionar em torno dos trabalhos. Exemplos populares de usos incluem:',
        es: 'puede configurar reglas sobre cómo debe funcionar la temperatura alrededor de los trabajos. Ejemplos populares de usos incluyen:',
        ru: 'вы можете настроить правила работы температуры во время выполнения заданий. Популярные примеры использования включают:',
        fr: 'vous pouvez définir des règles sur le fonctionnement de la température autour des travaux. Des exemples populaires d\'utilisations incluent :',
        de: 'können Sie Regeln für die Temperaturregelung bei Aufträgen festlegen. Beliebte Anwendungsbeispiele sind:'
    },
    '<li>Setting min or max temperature during the time of the job. This allows you to ensure your pro is comfortable, while ensuring that energy is conserved.</li>': {
        pt: '<li>Definir temperatura mínima ou máxima durante o tempo do trabalho. Isso permite garantir que seu profissional esteja confortável, enquanto garante que a energia seja conservada.</li>',
        es: '<li>Establecer temperatura mínima o máxima durante el tiempo del trabajo. Esto le permite asegurarse de que su profesional esté cómodo, mientras se asegura de que se conserve la energía.</li>',
        ru: '<li>Установка минимальной или максимальной температуры во время выполнения работы. Это позволяет обеспечить комфорт вашего специалиста, одновременно обеспечивая экономию энергии.</li>',
        fr: '<li>Réglage de la température minimale ou maximale pendant la durée du travail. Cela vous permet de vous assurer que votre professionnel est à l\'aise, tout en veillant à ce que l\'énergie soit économisée.</li>',
        de: '<li>Einstellung der Mindest- oder Höchsttemperatur während der Arbeitszeit. Dies ermöglicht es Ihnen, sicherzustellen, dass Ihr Profi sich wohl fühlt, während gleichzeitig Energie gespart wird.</li>'
    },
    '<li>Set the temperature after jobs are over, so that you can conserve energy.</li>': {
        pt: '<li>Definir a temperatura após o término dos trabalhos, para que você possa economizar energia.</li>',
        es: '<li>Establecer la temperatura después de que los trabajos hayan terminado, para que pueda conservar energía.</li>',
        ru: '<li>Установить температуру после завершения работ, чтобы сэкономить энергию.</li>',
        fr: '<li>Régler la température après la fin des travaux, afin de pouvoir économiser de l\'énergie.</li>',
        de: '<li>Stellen Sie die Temperatur nach Abschluss der Arbeiten ein, um Energie zu sparen.</li>'
    },
    '<li>Save energy and maintain comfort by integrating with smart thermostat systems.</li>': {
        pt: '<li>Economize energia e mantenha o conforto integrando com sistemas de termostato inteligente.</li>',
        es: '<li>Ahorre energía y mantenga la comodidad integrándose con sistemas de termostato inteligente.</li>',
        ru: '<li>Экономьте энергию и поддерживайте комфорт, интегрируясь с системами умных термостатов.</li>',
        fr: '<li>Économisez de l\'énergie et maintenez le confort en intégrant des systèmes de thermostat intelligent.</li>',
        de: '<li>Sparen Sie Energie und erhalten Sie den Komfort durch die Integration mit intelligenten Thermostatsystemen.</li>'
    },
    'Integrating TIDY with': {
        pt: 'Integrando a TIDY com',
        es: 'Integrando TIDY con',
        ru: 'Интеграция TIDY с',
        fr: 'Intégration de TIDY avec',
        de: 'Integration von TIDY mit'
    },
    'allows you to control access to your home with your cleaning and maintenance vendors. Typically, this is used so you can automatically give pros access to the properties only when they need access.': {
        pt: 'permite que você controle o acesso à sua casa com seus fornecedores de limpeza e manutenção. Normalmente, isso é usado para que você possa dar automaticamente acesso aos profissionais às propriedades apenas quando eles precisam de acesso.',
        es: 'le permite controlar el acceso a su hogar con sus proveedores de limpieza y mantenimiento. Típicamente, esto se usa para que pueda dar automáticamente acceso a los profesionales a las propiedades solo cuando necesitan acceso.',
        ru: 'позволяет вам контролировать доступ к вашему дому для поставщиков услуг по уборке и обслуживанию. Обычно это используется для того, чтобы вы могли автоматически предоставлять профессионалам доступ к объектам только тогда, когда им это необходимо.',
        fr: 'vous permet de contrôler l\'accès à votre maison avec vos fournisseurs de nettoyage et d\'entretien. Généralement, cela est utilisé pour que vous puissiez automatiquement donner aux professionnels l\'accès aux propriétés uniquement lorsqu\'ils en ont besoin.',
        de: 'ermöglicht es Ihnen, den Zugang zu Ihrem Haus mit Ihren Reinigungs- und Wartungsanbietern zu kontrollieren. Typischerweise wird dies verwendet, damit Sie Profis automatisch nur dann Zugang zu den Immobilien geben können, wenn sie Zugang benötigen.'
    },
    'Once integrated, you can:': {
        pt: 'Uma vez integrado, você pode:',
        es: 'Una vez integrado, puede:',
        ru: 'После интеграции вы сможете:',
        fr: 'Une fois intégré, vous pouvez :',
        de: 'Nach der Integration können Sie:'
    },
    '<li>Unlock and lock your properties from your interface.</li>': {
        pt: '<li>Desbloquear e bloquear suas propriedades a partir da sua interface.</li>',
        es: '<li>Desbloquear y bloquear sus propiedades desde su interfaz.</li>',
        ru: '<li>Разблокировать и блокировать ваши объекты через ваш интерфейс.</li>',
        fr: '<li>Déverrouiller et verrouiller vos propriétés depuis votre interface.</li>',
        de: '<li>Ihre Immobilien über Ihre Benutzeroberfläche ent- und verriegeln.</li>'
    },
    '<li>Automatically create one time passwords to access your property during jobs.</li>': {
        pt: '<li>Criar automaticamente senhas de uso único para acessar sua propriedade durante os trabalhos.</li>',
        es: '<li>Crear automáticamente contraseñas de un solo uso para acceder a su propiedad durante los trabajos.</li>',
        ru: '<li>Автоматически создавать одноразовые пароли для доступа к вашему объекту во время выполнения работ.</li>',
        fr: '<li>Créer automatiquement des mots de passe à usage unique pour accéder à votre propriété pendant les travaux.</li>',
        de: '<li>Automatisch Einmalpasswörter erstellen, um während der Arbeiten auf Ihr Eigentum zuzugreifen.</li>'
    },
    '<li>Automatically unlock properties when pros indicate they have arrived and lock when they leave.</li>': {
        pt: '<li>Desbloquear automaticamente as propriedades quando os profissionais indicam que chegaram e bloquear quando saem.</li>',
        es: '<li>Desbloquear automáticamente las propiedades cuando los profesionales indican que han llegado y bloquear cuando se van.</li>',
        ru: '<li>Автоматически разблокировать объекты, когда специалисты сообщают о своем прибытии, и блокировать, когда они уходят.</li>',
        fr: '<li>Déverrouiller automatiquement les propriétés lorsque les professionnels indiquent qu\'ils sont arrivés et verrouiller lorsqu\'ils partent.</li>',
        de: '<li>Automatisches Entriegeln der Immobilien, wenn Profis ihre Ankunft anzeigen, und Verriegeln, wenn sie gehen.</li>'
    },
    '<li>Use the API to control</li>': {
        pt: '<li>Usar a API para controlar</li>',
        es: '<li>Usar la API para controlar</li>',
        ru: '<li>Использовать API для управления</li>',
        fr: '<li>Utiliser l\'API pour contrôler</li>',
        de: '<li>Die API zur Steuerung verwenden</li>'
    },
    '<li>Control access to your properties for both cleaning and maintenance.</li>': {
        pt: '<li>Controlar o acesso às suas propriedades tanto para limpeza quanto para manutenção.</li>',
        es: '<li>Controlar el acceso a sus propiedades tanto para limpieza como para mantenimiento.</li>',
        ru: '<li>Контролировать доступ к вашим объектам как для уборки, так и для обслуживания.</li>',
        fr: '<li>Contrôler l\'accès à vos propriétés pour le nettoyage et l\'entretien.</li>',
        de: '<li>Den Zugang zu Ihren Immobilien sowohl für Reinigung als auch für Wartung kontrollieren.</li>'
    },
    'Add Lock Integration': {
        pt: 'Adicionar Integração de Fechadura',
        es: 'Agregar Integración de Cerradura',
        ru: 'Добавить Интеграцию Замка',
        fr: 'Ajouter l\'Intégration de Serrure',
        de: 'Schloss-Integration Hinzufügen'
    },
    'Add Thermostat Integration': {
        pt: 'Adicionar Integração de Termostato',
        es: 'Agregar Integración de Termostato',
        ru: 'Добавить Интеграцию Термостата',
        fr: 'Ajouter l\'Intégration de Thermostat',
        de: 'Thermostat-Integration Hinzufügen'
    },
    'Add Accounting Integration': {
        pt: 'Adicionar Integração de Contabilidade',
        es: 'Agregar Integración de Contabilidad',
        ru: 'Добавить Интеграцию Бухгалтерии',
        fr: 'Ajouter l\'Intégration Comptable',
        de: 'Buchhaltungs-Integration Hinzufügen'
    },
    'TIDY Concierge will reach out in the next 1 business day to help set up your integration.': {
        pt: 'O Concierge do TIDY entrará em contato no próximo dia útil para ajudar a configurar sua integração.',
        es: 'El Conserje de TIDY se pondrá en contacto en el próximo día hábil para ayudar a configurar su integración.',
        ru: 'Консьерж TIDY свяжется с вами в течение следующего рабочего дня, чтобы помочь настроить вашу интеграцию.',
        fr: 'Le Concierge TIDY vous contactera dans le prochain jour ouvrable pour vous aider à configurer votre intégration.',
        de: 'Der TIDY-Concierge wird sich am nächsten Werktag bei Ihnen melden, um Ihnen bei der Einrichtung Ihrer Integration zu helfen.'
    },
    'You already requested to add this integration so our Concierge will reach out to you. Please reach out with any questions.': {
        pt: 'Você já solicitou adicionar esta integração, então nosso Concierge entrará em contato com você. Por favor, entre em contato se tiver alguma dúvida.',
        es: 'Ya solicitaste agregar esta integración, por lo que nuestro Concierge se pondrá en contacto contigo. Por favor, comunícate si tienes alguna pregunta.',
        ru: 'Вы уже запросили добавление этой интеграции, поэтому наш Консьерж свяжется с вами. Пожалуйста, обращайтесь, если у вас есть вопросы.',
        fr: 'Vous avez déjà demandé l\'ajout de cette intégration, notre Concierge vous contactera donc. N\'hésitez pas à nous contacter si vous avez des questions.',
        de: 'Sie haben bereits die Hinzufügung dieser Integration angefordert, daher wird sich unser Concierge mit Ihnen in Verbindung setzen. Bitte melden Sie sich bei Fragen.',
    },

    //InventoryPage
    'Add Item': {
        pt: 'Adicionar Item',
        es: 'Agregar Artículo',
        ru: 'Добавить Предмет',
        fr: 'Ajouter un Article',
        de: 'Artikel Hinzufügen'
    },
    'You haven\'t set up inventory tracking for any room items yet. You can manually add items to your rooms or you can scan your property and we will generate a fun map of your property and personalized maintenance plan, for free!': {
        pt: 'Você ainda não configurou o rastreamento de inventário para nenhum item de sala. Você pode adicionar itens manualmente às suas salas ou pode escanear sua propriedade e nós geraremos um mapa divertido da sua propriedade e um plano de manutenção personalizado, gratuitamente!',
        es: 'Aún no has configurado el seguimiento de inventario para ningún artículo de habitación. Puedes agregar artículos manualmente a tus habitaciones o puedes escanear tu propiedad y generaremos un mapa divertido de tu propiedad y un plan de mantenimiento personalizado, ¡gratis!',
        ru: 'Вы еще не настроили отслеживание инвентаря для каких-либо предметов в комнатах. Вы можете вручную добавить предметы в свои комнаты или отсканировать свою недвижимость, и мы бесплатно создадим забавную карту вашей недвижимости и персонализированный план обслуживания!',
        fr: 'Vous n\'avez pas encore configuré le suivi d\'inventaire pour les articles de pièce. Vous pouvez ajouter manuellement des articles à vos pièces ou vous pouvez scanner votre propriété et nous générerons gratuitement une carte amusante de votre propriété et un plan de maintenance personnalisé !',
        de: 'Sie haben noch kein Inventar-Tracking für Raumartikel eingerichtet. Sie können Artikel manuell zu Ihren Räumen hinzufügen oder Sie können Ihr Objekt scannen und wir erstellen kostenlos eine lustige Karte Ihres Objekts und einen personalisierten Wartungsplan!'
    },
    'Track Inventory & Maintenance': {
        pt: 'Rastrear Inventário e Manutenção',
        es: 'Seguimiento de Inventario y Mantenimiento',
        ru: 'Отслеживание Инвентаря и Обслуживания',
        fr: 'Suivi de l\'Inventaire et de la Maintenance',
        de: 'Inventar und Wartung Verfolgen'
    },
    'Track Inventory': {
        pt: 'Rastrear Inventário',
        es: 'Seguimiento de Inventario',
        ru: 'Отслеживание Инвентаря',
        fr: 'Suivi de l\'Inventaire',
        de: 'Inventar Verfolgen'
    },
    'Get alerts when the current quantity goes below your minimum quantity.': {
        pt: 'Receba alertas quando a quantidade atual ficar abaixo da quantidade mínima.',
        es: 'Recibe alertas cuando la cantidad actual sea inferior a tu cantidad mínima.',
        ru: 'Получайте оповещения, когда текущее количество становится ниже минимального.',
        fr: 'Recevez des alertes lorsque la quantité actuelle passe en dessous de votre quantité minimale.',
        de: 'Erhalten Sie Benachrichtigungen, wenn die aktuelle Menge unter Ihre Mindestmenge fällt.'
    },
    'Get Maintenance Tips': {
        pt: 'Obter Dicas de Manutenção',
        es: 'Obtener Consejos de Mantenimiento',
        ru: 'Получить Советы по Обслуживанию',
        fr: 'Obtenir des Conseils d\'Entretien',
        de: 'Wartungstipps Erhalten'
    },
    'We will tell you what the manufacturer recommends for each item.': {
        pt: 'Informaremos o que o fabricante recomenda para cada item.',
        es: 'Te diremos lo que el fabricante recomienda para cada artículo.',
        ru: 'Мы расскажем вам, что рекомендует производитель для каждого предмета.',
        fr: 'Nous vous dirons ce que le fabricant recommande pour chaque article.',
        de: 'Wir teilen Ihnen mit, was der Hersteller für jeden Artikel empfiehlt.'
    },
    'Clean Smarter': {
        pt: 'Limpeza Inteligente',
        es: 'Limpieza Más Inteligente',
        ru: 'Умная Уборка',
        fr: 'Nettoyage Plus Intelligent',
        de: 'Intelligenter Reinigen'
    },
    'Knowing what is in your home helps you and any pros you use follow best practices for that exact thing.': {
        pt: 'Saber o que há em sua casa ajuda você e quaisquer profissionais que você use a seguir as melhores práticas para aquele item específico.',
        es: 'Saber lo que hay en tu hogar te ayuda a ti y a cualquier profesional que utilices a seguir las mejores prácticas para ese artículo en particular.',
        ru: 'Знание того, что находится в вашем доме, помогает вам и любым специалистам, которых вы используете, следовать лучшим практикам для каждого конкретного предмета.',
        fr: 'Savoir ce qui se trouve dans votre maison vous aide, vous et tous les professionnels que vous utilisez, à suivre les meilleures pratiques pour cet élément précis.',
        de: 'Zu wissen, was sich in Ihrem Zuhause befindet, hilft Ihnen und allen Fachleuten, die Sie beauftragen, die besten Praktiken für genau diesen Gegenstand zu befolgen.'
    },
    'Send Us Your Inventory List (Recommended)': {
        pt: 'Envie-nos Sua Lista de Inventário (Recomendado)',
        es: 'Envíanos Tu Lista de Inventario (Recomendado)',
        ru: 'Отправьте Нам Свой Список Инвентаря (Рекомендуется)',
        fr: 'Envoyez-nous Votre Liste d\'Inventaire (Recommandé)',
        de: 'Senden Sie Uns Ihre Inventarliste (Empfohlen)'
    },
    'Add Items to Track': {
        pt: 'Adicionar Itens para Rastrear',
        es: 'Agregar Artículos para Seguimiento',
        ru: 'Добавить Предметы для Отслеживания',
        fr: 'Ajouter des Articles à Suivre',
        de: 'Artikel zum Verfolgen Hinzufügen'
    },
    'Item': {
        pt: 'Item',
        es: 'Artículo',
        ru: 'Предмет',
        fr: 'Article',
        de: 'Artikel'
    },
    'Current #': {
        pt: 'Atual #',
        es: 'Actual #',
        ru: 'Текущее #',
        fr: 'Actuel #',
        de: 'Aktuell #'
    },
    'Min #': {
        pt: 'Mín #',
        es: 'Mín #',
        ru: 'Мин #',
        fr: 'Min #',
        de: 'Min #'
    },

    //InvoiceAutopayPage
    'Bill Autopay': {
        pt: 'Pagamento Automático de Contas',
        es: 'Pago Automático de Facturas',
        ru: 'Автоматическая Оплата Счетов',
        fr: 'Paiement Automatique des Factures',
        de: 'Automatische Rechnungszahlung'
    },
    'When': {
        pt: 'Quando',
        es: 'Cuando',
        ru: 'Когда',
        fr: 'Quand',
        de: 'Wann'
    },
    'finishes your jobs they can send you a bill using their TIDY app.': {
        pt: 'termina seus trabalhos, eles podem enviar uma conta usando o aplicativo TIDY.',
        es: 'termina tus trabajos, pueden enviarte una factura usando su aplicación TIDY.',
        ru: 'заканчивает ваши работы, они могут отправить вам счет, используя приложение TIDY.',
        fr: 'termine vos travaux, ils peuvent vous envoyer une facture en utilisant leur application TIDY.',
        de: 'Ihre Aufträge abschließt, können sie Ihnen eine Rechnung über ihre TIDY-App senden.'
    },
    'Autopay': {
        pt: 'Pagamento Automático',
        es: 'Pago Automático',
        ru: 'Автоплатеж',
        fr: 'Paiement Automatique',
        de: 'Automatische Zahlung'
    },
    'Maximum amount to pay': {
        pt: 'Valor máximo a pagar',
        es: 'Cantidad máxima a pagar',
        ru: 'Максимальная сумма к оплате',
        fr: 'Montant maximum à payer',
        de: 'Maximaler Zahlungsbetrag'
    },
    'Please enter a maximum amount to pay.': {
        pt: 'Por favor, insira um valor máximo a pagar.',
        es: 'Por favor, ingrese una cantidad máxima a pagar.',
        ru: 'Пожалуйста, введите максимальную сумму для оплаты.',
        fr: 'Veuillez entrer un montant maximum à payer.',
        de: 'Bitte geben Sie einen maximalen Zahlungsbetrag ein.'
    },

    //PaidInvoicePage
    'Paid Bill': {
        pt: 'Conta Paga',
        es: 'Factura Pagada',
        ru: 'Оплаченный Счет',
        fr: 'Facture Payée',
        de: 'Bezahlte Rechnung'
    },
    'Bill From': {
        pt: 'Conta De',
        es: 'Factura De',
        ru: 'Счет От',
        fr: 'Facture De',
        de: 'Rechnung Von'
    },
    'To:': {
        pt: 'Para:',
        es: 'Para:',
        ru: 'Кому:',
        fr: 'À:',
        de: 'An:'
    },
    'Job Duration': {
        pt: 'Duração do Trabalho',
        es: 'Duración del Trabajo',
        ru: 'Продолжительность Работы',
        fr: 'Durée du Travail',
        de: 'Arbeitsdauer'
    },
    'Reported by Pro:': {
        pt: 'Relatado pelo Profissional:',
        es: 'Reportado por el Profesional:',
        ru: 'Сообщено Профессионалом:',
        fr: 'Signalé par le Pro:',
        de: 'Vom Profi gemeldet:'
    },
    'Using Realtime Updates:': {
        pt: 'Usando Atualizações em Tempo Real:',
        es: 'Usando Actualizaciones en Tiempo Real:',
        ru: 'Используя Обновления в Реальном Времени:',
        fr: 'Utilisant des Mises à Jour en Temps Réel:',
        de: 'Verwendung von Echtzeit-Updates:'
    },
    'We thank you for your business!': {
        pt: 'Agradecemos pelo seu negócio!',
        es: '¡Le agradecemos por su negocio!',
        ru: 'Благодарим вас за сотрудничество!',
        fr: 'Nous vous remercions pour votre confiance!',
        de: 'Wir danken Ihnen für Ihr Geschäft!'
    },
    'Bill Notes': {
        pt: 'Notas da Conta',
        es: 'Notas de la Factura',
        ru: 'Примечания к Счету',
        fr: 'Notes de Facture',
        de: 'Rechnungsnotizen'
    },
    'Add Internal Note': {
        pt: 'Adicionar Nota Interna',
        es: 'Agregar Nota Interna',
        ru: 'Добавить Внутреннюю Заметку',
        fr: 'Ajouter une Note Interne',
        de: 'Interne Notiz Hinzufügen'
    },
    'Add Note': {
        pt: 'Adicionar Nota',
        es: 'Agregar Nota',
        ru: 'Добавить Заметку',
        fr: 'Ajouter une Note',
        de: 'Notiz Hinzufügen'
    },
    'Please enter a message to send.': {
        pt: 'Por favor, insira uma mensagem para enviar.',
        es: 'Por favor, ingrese un mensaje para enviar.',
        ru: 'Пожалуйста, введите сообщение для отправки.',
        fr: 'Veuillez entrer un message à envoyer.',
        de: 'Bitte geben Sie eine Nachricht zum Senden ein.'
    },
    'No Bill Notes': {
        pt: 'Sem Notas de Conta',
        es: 'Sin Notas de Factura',
        ru: 'Нет Примечаний к Счету',
        fr: 'Pas de Notes de Facture',
        de: 'Keine Rechnungsnotizen'
    },
    'Internal note': {
        pt: 'Nota interna',
        es: 'Nota interna',
        ru: 'Внутренняя заметка',
        fr: 'Note interne',
        de: 'Interne Notiz'
    },
    'Public note': {
        pt: 'Nota pública',
        es: 'Nota pública',
        ru: 'Публичная заметка',
        fr: 'Note publique',
        de: 'Öffentliche Notiz'
    },

    //PendingInvoicePage
    'Credit with Pro': {
        pt: 'Crédito com Pro',
        es: 'Crédito con Pro',
        ru: 'Кредит с Pro',
        fr: 'Crédit avec Pro',
        de: 'Kredit mit Pro'
    },
    'TIDY Credit': {
        pt: 'Crédito TIDY',
        es: 'Crédito TIDY',
        ru: 'Кредит TIDY',
        fr: 'Crédit TIDY',
        de: 'TIDY-Guthaben'
    },
    'Pay Bill': {
        pt: 'Pagar Conta',
        es: 'Pagar Factura',
        ru: 'Оплатить Счет',
        fr: 'Payer la Facture',
        de: 'Rechnung Bezahlen'
    },
    'Save Card For Future Use': {
        pt: 'Salvar Cartão para Uso Futuro',
        es: 'Guardar Tarjeta para Uso Futuro',
        ru: 'Сохранить Карту для Будущего Использования',
        fr: 'Enregistrer la Carte pour une Utilisation Future',
        de: 'Karte für zukünftige Verwendung Speichern'
    },
    'Please enter a password.': {
        pt: 'Por favor, insira uma senha.',
        es: 'Por favor, ingrese una contraseña.',
        ru: 'Пожалуйста, введите пароль.',
        fr: 'Veuillez entrer un mot de passe.',
        de: 'Bitte geben Sie ein Passwort ein.'
    },
    'I agree to the': {
        pt: 'Eu concordo com os',
        es: 'Estoy de acuerdo con los',
        ru: 'Я согласен с',
        fr: 'J\'accepte les',
        de: 'Ich stimme den'
    },
    'Select payment method': {
        pt: 'Selecione o método de pagamento',
        es: 'Seleccione el método de pago',
        ru: 'Выберите способ оплаты',
        fr: 'Sélectionnez le mode de paiement',
        de: 'Zahlungsmethode auswählen'
    },
    'Pay With': {
        pt: 'Pagar Com',
        es: 'Pagar Con',
        ru: 'Оплатить С Помощью',
        fr: 'Payer Avec',
        de: 'Bezahlen Mit'
    },
    'Username:': {
        pt: 'Nome de Usuário:',
        es: 'Nombre de Usuario:',
        ru: 'Имя Пользователя:',
        fr: 'Nom d\'Utilisateur:',
        de: 'Benutzername:'
    },
    'Copy': {
        pt: 'Copiar',
        es: 'Copiar',
        ru: 'Копировать',
        fr: 'Copier',
        de: 'Kopieren'
    },
    'Copied': {
        pt: 'Copiado',
        es: 'Copiado',
        ru: 'Скопировано',
        fr: 'Copié',
        de: 'Kopiert'
    },
    'Please check that you are not a robot.': {
        pt: 'Por favor, verifique que você não é um robô.',
        es: 'Por favor, verifique que no es un robot.',
        ru: 'Пожалуйста, подтвердите, что вы не робот.',
        fr: 'Veuillez vérifier que vous n\'êtes pas un robot.',
        de: 'Bitte bestätigen Sie, dass Sie kein Roboter sind.'
    },
    'Mark as Paid': {
        pt: 'Marcar como Pago',
        es: 'Marcar como Pagado',
        ru: 'Отметить как Оплаченное',
        fr: 'Marquer comme Payé',
        de: 'Als Bezahlt Markieren'
    },
    'Use new card (preferred)': {
        pt: 'Usar novo cartão (preferencial)',
        es: 'Usar nueva tarjeta (preferido)',
        ru: 'Использовать новую карту (предпочтительно)',
        fr: 'Utiliser une nouvelle carte (préféré)',
        de: 'Neue Karte verwenden (bevorzugt)'
    },
    'Use new card': {
        pt: 'Usar novo cartão',
        es: 'Usar nueva tarjeta',
        ru: 'Использовать новую карту',
        fr: 'Utiliser une nouvelle carte',
        de: 'Neue Karte verwenden'
    },
    'Other options': {
        pt: 'Outras opções',
        es: 'Otras opciones',
        ru: 'Другие варианты',
        fr: 'Autres options',
        de: 'Andere Optionen'
    },
    'Please log into the account on file for this invoice.': {
        pt: 'Por favor, faça login na conta registrada para esta fatura.',
        es: 'Por favor, inicie sesión en la cuenta registrada para esta factura.',
        ru: 'Пожалуйста, войдите в учетную запись, зарегистрированную для этого счета.',
        fr: 'Veuillez vous connecter au compte enregistré pour cette facture.',
        de: 'Bitte melden Sie sich im für diese Rechnung hinterlegten Konto an.'
    },
    'The email and password you entered did not match our records. Please double-check and try again.': {
        pt: 'O e-mail e a senha que você inseriu não correspondem aos nossos registros. Por favor, verifique e tente novamente.',
        es: 'El correo electrónico y la contraseña que ingresó no coinciden con nuestros registros. Por favor, verifique e intente nuevamente.',
        ru: 'Введенные вами электронная почта и пароль не соответствуют нашим записям. Пожалуйста, проверьте и попробуйте снова.',
        fr: 'L\'e-mail et le mot de passe que vous avez saisis ne correspondent pas à nos enregistrements. Veuillez vérifier et réessayer.',
        de: 'Die eingegebene E-Mail-Adresse und das Passwort stimmen nicht mit unseren Aufzeichnungen überein. Bitte überprüfen Sie die Eingaben und versuchen Sie es erneut.'
    },
    'Invoice Paid': {
        pt: 'Fatura Paga',
        es: 'Factura Pagada',
        ru: 'Счет Оплачен',
        fr: 'Facture Payée',
        de: 'Rechnung Bezahlt'
    },
    'Log In to Use Card': {
        pt: 'Faça Login para Usar o Cartão',
        es: 'Inicie Sesión para Usar la Tarjeta',
        ru: 'Войдите, чтобы Использовать Карту',
        fr: 'Connectez-vous pour Utiliser la Carte',
        de: 'Anmelden, um Karte zu Verwenden'
    },
    'Log In to Save Card': {
        pt: 'Faça Login para Salvar o Cartão',
        es: 'Inicie Sesión para Guardar la Tarjeta',
        ru: 'Войдите, чтобы Сохранить Карту',
        fr: 'Connectez-vous pour Enregistrer la Carte',
        de: 'Anmelden, um Karte zu Speichern'
    },
    'Log In to Add Card': {
        pt: 'Faça Login para Adicionar o Cartão',
        es: 'Inicie Sesión para Agregar la Tarjeta',
        ru: 'Войдите, чтобы Добавить Карту',
        fr: 'Connectez-vous pour Ajouter la Carte',
        de: 'Anmelden, um Karte Hinzuzufügen'
    },
    'Create Account to Save Card': {
        pt: 'Crie uma Conta para Salvar o Cartão',
        es: 'Cree una Cuenta para Guardar la Tarjeta',
        ru: 'Создайте Аккаунт, чтобы Сохранить Карту',
        fr: 'Créez un Compte pour Enregistrer la Carte',
        de: 'Konto Erstellen, um Karte zu Speichern'
    },

    //SendPaymentOrPayInvoicePage
    'Do you want to pay a pending bill or send payment for a new job?': {
        pt: 'Você deseja pagar uma conta pendente ou enviar pagamento para um novo trabalho?',
        es: '¿Desea pagar una factura pendiente o enviar un pago por un nuevo trabajo?',
        ru: 'Вы хотите оплатить ожидающий счет или отправить оплату за новую работу?',
        fr: 'Voulez-vous payer une facture en attente ou envoyer un paiement pour un nouveau travail ?',
        de: 'Möchten Sie eine ausstehende Rechnung bezahlen oder eine Zahlung für einen neuen Auftrag senden?'
    },
    'Send Payment for New Job': {
        pt: 'Enviar Pagamento para Novo Trabalho',
        es: 'Enviar Pago por Nuevo Trabajo',
        ru: 'Отправить Оплату за Новую Работу',
        fr: 'Envoyer un Paiement pour un Nouveau Travail',
        de: 'Zahlung für Neuen Auftrag Senden'
    },
    'Please pay all outstanding bills before sending payment for a new job.': {
        pt: 'Por favor, pague todas as contas pendentes antes de enviar pagamento para um novo trabalho.',
        es: 'Por favor, pague todas las facturas pendientes antes de enviar el pago por un nuevo trabajo.',
        ru: 'Пожалуйста, оплатите все неоплаченные счета перед отправкой оплаты за новую работу.',
        fr: 'Veuillez payer toutes les factures en suspens avant d\'envoyer un paiement pour un nouveau travail.',
        de: 'Bitte bezahlen Sie alle ausstehenden Rechnungen, bevor Sie eine Zahlung für einen neuen Auftrag senden.'
    },
    'New Job': {
        pt: 'Novo Trabalho',
        es: 'Nuevo Trabajo',
        ru: 'Новая Работа',
        fr: 'Nouveau Travail',
        de: 'Neuer Auftrag'
    },
    'Send Payment for Pending Bills': {
        pt: 'Enviar Pagamento para Contas Pendentes',
        es: 'Enviar Pago por Facturas Pendientes',
        ru: 'Отправить Оплату за Ожидающие Счета',
        fr: 'Envoyer un Paiement pour les Factures en Attente',
        de: 'Zahlung für Ausstehende Rechnungen Senden'
    },
    'owed for': {
        pt: 'devido por',
        es: 'adeudado por',
        ru: 'задолжено за',
        fr: 'dû pour',
        de: 'geschuldet für'
    },
    'job': {
        pt: 'trabalho',
        es: 'trabajo',
        ru: 'работа',
        fr: 'travail',
        de: 'Auftrag'
    },

    //JobBackupTimesPage
    'Job Backup Times': {
        pt: 'Horários de Backup do Trabalho',
        es: 'Horarios de Respaldo del Trabajo',
        ru: 'Резервное Время Работы',
        fr: 'Horaires de Secours pour le Travail',
        de: 'Backup-Zeiten für den Auftrag'
    },
    'If my Pro can\'t make this time, automatically find a new time for my job at one of these times:': {
        pt: 'Se meu Profissional não puder neste horário, encontre automaticamente um novo horário para meu trabalho em um destes:',
        es: 'Si mi Profesional no puede en este horario, encuentre automáticamente un nuevo horario para mi trabajo en uno de estos:',
        ru: 'Если мой специалист не может в это время, автоматически найдите новое время для моей работы в одно из этих времен:',
        fr: 'Si mon Pro ne peut pas être disponible à cette heure, trouvez automatiquement un nouveau créneau pour mon travail parmi ces horaires :',
        de: 'Wenn mein Profi zu dieser Zeit nicht kann, finden Sie automatisch eine neue Zeit für meinen Auftrag zu einem dieser Zeitpunkte:'
    },
    'Add Backup Times': {
        pt: 'Adicionar Horários de Backup',
        es: 'Agregar Horarios de Respaldo',
        ru: 'Добавить Резервное Время',
        fr: 'Ajouter des Horaires de Secours',
        de: 'Backup-Zeiten Hinzufügen'
    },

    //LogIssuePage
    //LogIssuePage
    'Log Issue': {
        pt: 'Registrar Problema',
        es: 'Registrar Problema',
        ru: 'Зарегистрировать Проблему',
        fr: 'Signaler un Problème',
        de: 'Problem Melden'
    },
    'Where do you want to log the issue?': {
        pt: 'Onde você quer registrar o problema?',
        es: '¿Dónde quieres registrar el problema?',
        ru: 'Где вы хотите зарегистрировать проблему?',
        fr: 'Où voulez-vous signaler le problème ?',
        de: 'Wo möchten Sie das Problem melden?'
    },
    'Select property': {
        pt: 'Selecionar propriedade',
        es: 'Seleccionar propiedad',
        ru: 'Выбрать объект',
        fr: 'Sélectionner la propriété',
        de: 'Objekt auswählen'
    },
    'Please select a property.': {
        pt: 'Por favor, selecione uma propriedade.',
        es: 'Por favor, seleccione una propiedad.',
        ru: 'Пожалуйста, выберите объект.',
        fr: 'Veuillez sélectionner une propriété.',
        de: 'Bitte wählen Sie ein Objekt aus.'
    },
    'What do you want to log?': {
        pt: 'O que você quer registrar?',
        es: '¿Qué quieres registrar?',
        ru: 'Что вы хотите зарегистрировать?',
        fr: 'Que voulez-vous signaler ?',
        de: 'Was möchten Sie melden?'
    },
    'Damage': {
        pt: 'Dano',
        es: 'Daño',
        ru: 'Повреждение',
        fr: 'Dommage',
        de: 'Schaden'
    },
    'Low Inventory': {
        pt: 'Baixo Estoque',
        es: 'Inventario Bajo',
        ru: 'Низкий Запас',
        fr: 'Faible Inventaire',
        de: 'Niedriger Bestand'
    },
    'Utility Issue': {
        pt: 'Problema de Utilidade',
        es: 'Problema de Servicios',
        ru: 'Проблема с Коммунальными Услугами',
        fr: 'Problème de Services Publics',
        de: 'Versorgungsproblem'
    },
    'After logging an issue you can mark it as resolved.': {
        pt: 'Depois de registrar um problema, você pode marcá-lo como resolvido.',
        es: 'Después de registrar un problema, puedes marcarlo como resuelto.',
        ru: 'После регистрации проблемы вы можете отметить ее как решенную.',
        fr: 'Après avoir signalé un problème, vous pouvez le marquer comme résolu.',
        de: 'Nachdem Sie ein Problem gemeldet haben, können Sie es als gelöst markieren.'
    },
    'Please enter a description.': {
        pt: 'Por favor, insira uma descrição.',
        es: 'Por favor, ingrese una descripción.',
        ru: 'Пожалуйста, введите описание.',
        fr: 'Veuillez entrer une description.',
        de: 'Bitte geben Sie eine Beschreibung ein.'
    },
    'Add Photo': {
        pt: 'Adicionar Foto',
        es: 'Agregar Foto',
        ru: 'Добавить Фото',
        fr: 'Ajouter une Photo',
        de: 'Foto Hinzufügen'
    },
    'Photos:': {
        pt: 'Fotos:',
        es: 'Fotos:',
        ru: 'Фотографии:',
        fr: 'Photos :',
        de: 'Fotos:'
    },
    'Photo': {
        pt: 'Foto',
        es: 'Foto',
        ru: 'Фото',
        fr: 'Photo',
        de: 'Foto'
    },

    //AddProxyNumberComponent
    'AI Suggested Actions (Beta)': {
        pt: 'Ações Sugeridas por IA (Beta)',
        es: 'Acciones Sugeridas por IA (Beta)',
        ru: 'Действия, Предложенные ИИ (Бета)',
        fr: 'Actions Suggérées par l\'IA (Bêta)',
        de: 'KI-Vorgeschlagene Aktionen (Beta)'
    },
    '1. Share this with guests, tenants, or anyone you want to be able message in.': {
        pt: '1. Compartilhe isso com hóspedes, inquilinos ou qualquer pessoa que você queira que possa enviar mensagens.',
        es: '1. Comparte esto con huéspedes, inquilinos o cualquier persona que quieras que pueda enviar mensajes.',
        ru: '1. Поделитесь этим с гостями, арендаторами или любыми людьми, которым вы хотите разрешить отправлять сообщения.',
        fr: '1. Partagez ceci avec des invités, des locataires ou toute personne que vous souhaitez autoriser à envoyer des messages.',
        de: '1. Teilen Sie dies mit Gästen, Mietern oder allen, denen Sie das Senden von Nachrichten ermöglichen möchten.'
    },
    '2. As guests message in, they come into your Messages section.': {
        pt: '2. À medida que os hóspedes enviam mensagens, elas aparecem na sua seção de Mensagens.',
        es: '2. A medida que los huéspedes envían mensajes, aparecen en tu sección de Mensajes.',
        ru: '2. Когда гости отправляют сообщения, они появляются в вашем разделе Сообщения.',
        fr: '2. Lorsque les invités envoient des messages, ils apparaissent dans votre section Messages.',
        de: '2. Wenn Gäste Nachrichten senden, erscheinen sie in Ihrem Nachrichten-Bereich.'
    },
    '1. Share this with pros who you want to be able to message in.': {
        pt: '1. Compartilhe isso com profissionais que você quer que possam enviar mensagens.',
        es: '1. Comparte esto con profesionales a quienes quieres permitir enviar mensajes.',
        ru: '1. Поделитесь этим с профессионалами, которым вы хотите разрешить отправлять сообщения.',
        fr: '1. Partagez ceci avec les professionnels que vous souhaitez autoriser à envoyer des messages.',
        de: '1. Teilen Sie dies mit Profis, denen Sie das Senden von Nachrichten ermöglichen möchten.'
    },
    'How Pro Number\'s Work': {
        pt: 'Como Funcionam os Números de Profissionais',
        es: 'Cómo Funcionan los Números de Profesionales',
        ru: 'Как Работают Номера Профессионалов',
        fr: 'Comment Fonctionnent les Numéros de Professionnels',
        de: 'Wie Pro-Nummern Funktionieren'
    },
    'How Guest Number\'s Work': {
        pt: 'Como Funcionam os Números de Hóspedes',
        es: 'Cómo Funcionan los Números de Huéspedes',
        ru: 'Как Работают Номера Гостей',
        fr: 'Comment Fonctionnent les Numéros d\'Invités',
        de: 'Wie Gäste-Nummern Funktionieren'
    },
    '2. As pros message in, they come into your Messages section.': {
        pt: '2. À medida que os profissionais enviam mensagens, elas aparecem na sua seção de Mensagens.',
        es: '2. A medida que los profesionales envían mensajes, aparecen en tu sección de Mensajes.',
        ru: '2. Когда профессионалы отправляют сообщения, они появляются в вашем разделе Сообщения.',
        fr: '2. Lorsque les professionnels envoient des messages, ils apparaissent dans votre section Messages.',
        de: '2. Wenn Profis Nachrichten senden, erscheinen sie in Ihrem Nachrichten-Bereich.'
    },
    'Optional': {
        pt: 'Opcional',
        es: 'Opcional',
        ru: 'Опционально',
        fr: 'Optionnel',
        de: 'Optional'
    },
    '1. Set up workflows to make inbound messages create Concierge Actions.': {
        pt: '1. Configure fluxos de trabalho para que as mensagens recebidas criem Ações de Concierge.',
        es: '1. Configura flujos de trabajo para que los mensajes entrantes creen Acciones de Concierge.',
        ru: '1. Настройте рабочие процессы, чтобы входящие сообщения создавали Действия Консьержа.',
        fr: '1. Configurez des flux de travail pour que les messages entrants créent des Actions de Concierge.',
        de: '1. Richten Sie Workflows ein, damit eingehende Nachrichten Concierge-Aktionen erstellen.'
    },
    '2. Our team will create and update issues and jobs according to your preferences.': {
        pt: '2. Nossa equipe criará e atualizará problemas e tarefas de acordo com suas preferências.',
        es: '2. Nuestro equipo creará y actualizará problemas y trabajos según tus preferencias.',
        ru: '2. Наша команда будет создавать и обновлять проблемы и задания в соответствии с вашими предпочтениями.',
        fr: '2. Notre équipe créera et mettra à jour les problèmes et les tâches selon vos préférences.',
        de: '2. Unser Team wird Probleme und Aufgaben gemäß Ihren Präferenzen erstellen und aktualisieren.'
    },
    'Create a Guest Number': {
        pt: 'Criar um Número de Hóspede',
        es: 'Crear un Número de Huésped',
        ru: 'Создать Номер Гостя',
        fr: 'Créer un Numéro d\'Invité',
        de: 'Gäste-Nummer Erstellen'
    },
    'Your Pro Smart Number': {
        pt: 'Seu Número Inteligente de Profissional',
        es: 'Tu Número Inteligente de Profesional',
        ru: 'Ваш Умный Номер Профессионала',
        fr: 'Votre Numéro Intelligent de Professionnel',
        de: 'Ihre Pro Smart-Nummer'
    },
    'Your Guest Smart Number': {
        pt: 'Seu Número Inteligente de Hóspede',
        es: 'Tu Número Inteligente de Huésped',
        ru: 'Ваш Умный Номер Гостя',
        fr: 'Votre Numéro Intelligent d\'Invité',
        de: 'Ihre Gast Smart-Nummer'
    },
    'Number copied to clipboard.': {
        pt: 'Número copiado para a área de transferência.',
        es: 'Número copiado al portapapeles.',
        ru: 'Номер скопирован в буфер обмена.',
        fr: 'Numéro copié dans le presse-papiers.',
        de: 'Nummer in die Zwischenablage kopiert.'
    },
    'Unable to Add Number': {
        pt: 'Não é possível adicionar número',
        es: 'No se puede agregar número',
        ru: 'Невозможно добавить номер',
        fr: 'Impossible d\'ajouter le numéro',
        de: 'Nummer kann nicht hinzugefügt werden'
    },
    'You must be on a paid subscription to add a free trial. You can edit your subscription in the next page.': {
        pt: 'Você deve ter uma assinatura paga para adicionar um teste gratuito. Você pode editar sua assinatura na próxima página.',
        es: 'Debes tener una suscripción paga para agregar una prueba gratuita. Puedes editar tu suscripción en la siguiente página.',
        ru: 'Для добавления бесплатной пробной версии у вас должна быть платная подписка. Вы можете изменить свою подписку на следующей странице.',
        fr: 'Vous devez avoir un abonnement payant pour ajouter un essai gratuit. Vous pouvez modifier votre abonnement sur la page suivante.',
        de: 'Sie müssen ein bezahltes Abonnement haben, um eine kostenlose Testversion hinzuzufügen. Sie können Ihr Abonnement auf der nächsten Seite bearbeiten.'
    },
    'View Subscription': {
        pt: 'Ver Assinatura',
        es: 'Ver Suscripción',
        ru: 'Просмотреть Подписку',
        fr: 'Voir l\'Abonnement',
        de: 'Abonnement Anzeigen'
    },
    'Failed to create number. Please try again.': {
        pt: 'Falha ao criar número. Por favor, tente novamente.',
        es: 'Error al crear el número. Por favor, inténtalo de nuevo.',
        ru: 'Не удалось создать номер. Пожалуйста, попробуйте еще раз.',
        fr: 'Échec de la création du numéro. Veuillez réessayer.',
        de: 'Nummer konnte nicht erstellt werden. Bitte versuchen Sie es erneut.'
    },
    'Added to waitlist.': {
        pt: 'Adicionado à lista de espera.',
        es: 'Añadido a la lista de espera.',
        ru: 'Добавлено в список ожидания.',
        fr: 'Ajouté à la liste d\'attente.',
        de: 'Zur Warteliste hinzugefügt.'
    },
    'Number created successfully and copied to clipboard.': {
        pt: 'Número criado com sucesso e copiado para a área de transferência.',
        es: 'Número creado con éxito y copiado al portapapeles.',
        ru: 'Номер успешно создан и скопирован в буфер обмена.',
        fr: 'Numéro créé avec succès et copié dans le presse-papiers.',
        de: 'Nummer erfolgreich erstellt und in die Zwischenablage kopiert.'
    },

    //NewMessageModalComponent
    '<i>Number assigned to pro:</i>': {
        pt: '<i>Número atribuído ao profissional:</i>',
        es: '<i>Número asignado al profesional:</i>',
        ru: '<i>Номер, назначенный профессионалу:</i>',
        fr: '<i>Numéro attribué au professionnel :</i>',
        de: '<i>Nummer dem Profi zugewiesen:</i>'
    },
    '<i>Number assigned to property:</i>': {
        pt: '<i>Número atribuído à propriedade:</i>',
        es: '<i>Número asignado a la propiedad:</i>',
        ru: '<i>Номер, назначенный объекту недвижимости:</i>',
        fr: '<i>Numéro attribué à la propriété :</i>',
        de: '<i>Nummer dem Objekt zugewiesen:</i>'
    },
    '<i>No property assigned to this number</i>': {
        pt: '<i>Nenhuma propriedade atribuída a este número</i>',
        es: '<i>Ninguna propiedad asignada a este número</i>',
        ru: '<i>Нет объекта недвижимости, назначенного этому номеру</i>',
        fr: '<i>Aucune propriété attribuée à ce numéro</i>',
        de: '<i>Kein Objekt dieser Nummer zugewiesen</i>'
    },
    'Assign Number': {
        pt: 'Atribuir Número',
        es: 'Asignar Número',
        ru: 'Назначить Номер',
        fr: 'Attribuer un Numéro',
        de: 'Nummer Zuweisen'
    },
    'Assign Number to Pro': {
        pt: 'Atribuir Número ao Profissional',
        es: 'Asignar Número al Profesional',
        ru: 'Назначить Номер Профессионалу',
        fr: 'Attribuer un Numéro au Professionnel',
        de: 'Nummer dem Profi Zuweisen'
    },
    'Assign Number to Property': {
        pt: 'Atribuir Número à Propriedade',
        es: 'Asignar Número a la Propiedad',
        ru: 'Назначить Номер Объекту Недвижимости',
        fr: 'Attribuer un Numéro à la Propriété',
        de: 'Nummer dem Objekt Zuweisen'
    },
    'Call': {
        pt: 'Ligar',
        es: 'Llamar',
        ru: 'Позвонить',
        fr: 'Appeler',
        de: 'Anrufen'
    },
    'Send Message': {
        pt: 'Enviar Mensagem',
        es: 'Enviar Mensaje',
        ru: 'Отправить Сообщение',
        fr: 'Envoyer un Message',
        de: 'Nachricht Senden'
    },
    'Please select a pro to continue.': {
        pt: 'Por favor, selecione um profissional para continuar.',
        es: 'Por favor, seleccione un profesional para continuar.',
        ru: 'Пожалуйста, выберите профессионала, чтобы продолжить.',
        fr: 'Veuillez sélectionner un professionnel pour continuer.',
        de: 'Bitte wählen Sie einen Profi aus, um fortzufahren.'
    },
    'Please add a message or photo to continue.': {
        pt: 'Por favor, adicione uma mensagem ou foto para continuar.',
        es: 'Por favor, agregue un mensaje o foto para continuar.',
        ru: 'Пожалуйста, добавьте сообщение или фото, чтобы продолжить.',
        fr: 'Veuillez ajouter un message ou une photo pour continuer.',
        de: 'Bitte fügen Sie eine Nachricht oder ein Foto hinzu, um fortzufahren.'
    },
    'We couldn\'t send your message, please try again later!': {
        pt: 'Não foi possível enviar sua mensagem, por favor tente novamente mais tarde!',
        es: 'No pudimos enviar su mensaje, ¡por favor intente nuevamente más tarde!',
        ru: 'Мы не смогли отправить ваше сообщение, пожалуйста, попробуйте позже!',
        fr: 'Nous n\'avons pas pu envoyer votre message, veuillez réessayer plus tard !',
        de: 'Wir konnten Ihre Nachricht nicht senden, bitte versuchen Sie es später erneut!'
    },
    'Error sending message. Please try again later. Error message: ': {
        pt: 'Erro ao enviar mensagem. Por favor, tente novamente mais tarde. Mensagem de erro: ',
        es: 'Error al enviar el mensaje. Por favor, intente nuevamente más tarde. Mensaje de error: ',
        ru: 'Ошибка при отправке сообщения. Пожалуйста, попробуйте позже. Сообщение об ошибке: ',
        fr: 'Erreur lors de l\'envoi du message. Veuillez réessayer plus tard. Message d\'erreur : ',
        de: 'Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut. Fehlermeldung: '
    },
    'Assign number to pro': {
        pt: 'Atribuir número ao profissional',
        es: 'Asignar número al profesional',
        ru: 'Назначить номер профессионалу',
        fr: 'Attribuer un numéro au professionnel',
        de: 'Nummer dem Profi zuweisen'
    },
    'Assign number to property': {
        pt: 'Atribuir número à propriedade',
        es: 'Asignar número a la propiedad',
        ru: 'Назначить номер объекту недвижимости',
        fr: 'Attribuer un numéro à la propriété',
        de: 'Nummer dem Objekt zuweisen'
    },
    'Chat assigned successfully': {
        pt: 'Chat atribuído com sucesso',
        es: 'Chat asignado con éxito',
        ru: 'Чат успешно назначен',
        fr: 'Chat attribué avec succès',
        de: 'Chat erfolgreich zugewiesen'
    },

    //MessagesPage
    'Guest Smart Number': {
        pt: 'Número Inteligente do Hóspede',
        es: 'Número Inteligente del Huésped',
        ru: 'Умный номер гостя',
        fr: 'Numéro Intelligent de l\'Invité',
        de: 'Intelligente Gastnummer'
    },
    'Pro Smart Number': {
        pt: 'Número Inteligente do Profissional',
        es: 'Número Inteligente del Profesional',
        ru: 'Умный номер профессионала',
        fr: 'Numéro Intelligent du Professionnel',
        de: 'Intelligente Profinummer'
    },
    'Share this number with your guests/tenants. They can text in maintenance requests for triage/handling.': {
        pt: 'Compartilhe este número com seus hóspedes/inquilinos. Eles podem enviar solicitações de manutenção por mensagem para triagem/tratamento.',
        es: 'Comparta este número con sus huéspedes/inquilinos. Pueden enviar solicitudes de mantenimiento por mensaje para clasificación/manejo.',
        ru: 'Поделитесь этим номером с вашими гостями/арендаторами. Они могут отправлять запросы на обслуживание для сортировки/обработки.',
        fr: 'Partagez ce numéro avec vos invités/locataires. Ils peuvent envoyer des demandes de maintenance par message pour le triage/traitement.',
        de: 'Teilen Sie diese Nummer mit Ihren Gästen/Mietern. Sie können Wartungsanfragen per Nachricht zur Triage/Bearbeitung senden.'
    },
    'Share this number with your pros and messages come into TIDY. As you message, TIDY AI an read messages to update your account automatically.': {
        pt: 'Compartilhe este número com seus profissionais e as mensagens chegam ao TIDY. Conforme você envia mensagens, a IA do TIDY pode ler as mensagens para atualizar sua conta automaticamente.',
        es: 'Comparta este número con sus profesionales y los mensajes llegarán a TIDY. A medida que envía mensajes, la IA de TIDY puede leer los mensajes para actualizar su cuenta automáticamente.',
        ru: 'Поделитесь этим номером с вашими профессионалами, и сообщения поступят в TIDY. По мере отправки сообщений, ИИ TIDY может читать сообщения для автоматического обновления вашего аккаунта.',
        fr: 'Partagez ce numéro avec vos professionnels et les messages arrivent dans TIDY. Lorsque vous envoyez des messages, l\'IA de TIDY peut lire les messages pour mettre à jour votre compte automatiquement.',
        de: 'Teilen Sie diese Nummer mit Ihren Profis und die Nachrichten kommen in TIDY an. Während Sie Nachrichten senden, kann die TIDY KI die Nachrichten lesen, um Ihr Konto automatisch zu aktualisieren.'
    },
    'Last Message': {
        pt: 'Última Mensagem',
        es: 'Último Mensaje',
        ru: 'Последнее Сообщение',
        fr: 'Dernier Message',
        de: 'Letzte Nachricht'
    },
    'Thread': {
        pt: 'Conversa',
        es: 'Hilo',
        ru: 'Цепочка',
        fr: 'Fil de discussion',
        de: 'Thread'
    },

    //MissingItemPage
    'Missing Item': {
        pt: 'Item Faltante',
        es: 'Artículo Faltante',
        ru: 'Отсутствующий Предмет',
        fr: 'Objet Manquant',
        de: 'Fehlender Gegenstand'
    },
    'We\'re sorry to hear that something is missing!': {
        pt: 'Lamentamos saber que algo está faltando!',
        es: '¡Lamentamos saber que algo falta!',
        ru: 'Нам жаль слышать, что что-то пропало!',
        fr: 'Nous sommes désolés d\'apprendre que quelque chose manque !',
        de: 'Es tut uns leid zu hören, dass etwas fehlt!'
    },
    'If you would like to reach out to': {
        pt: 'Se você gostaria de entrar em contato com',
        es: 'Si desea comunicarse con',
        ru: 'Если вы хотите связаться с',
        fr: 'Si vous souhaitez contacter',
        de: 'Wenn Sie Kontakt aufnehmen möchten mit'
    },
    'directly, you can do so below.  This will send them a message and allow you to open up a communication session with them to discuss further.': {
        pt: 'diretamente, você pode fazê-lo abaixo. Isso enviará uma mensagem e permitirá que você abra uma sessão de comunicação com eles para discutir mais.',
        es: 'directamente, puede hacerlo a continuación. Esto les enviará un mensaje y le permitirá abrir una sesión de comunicación con ellos para discutir más.',
        ru: 'напрямую, вы можете сделать это ниже. Это отправит им сообщение и позволит вам открыть сеанс связи с ними для дальнейшего обсуждения.',
        fr: 'directement, vous pouvez le faire ci-dessous. Cela leur enverra un message et vous permettra d\'ouvrir une session de communication avec eux pour en discuter davantage.',
        de: 'direkt, können Sie dies unten tun. Dies sendet ihnen eine Nachricht und ermöglicht es Ihnen, eine Kommunikationssitzung mit ihnen zu eröffnen, um weitere Diskussionen zu führen.'
    },
    'If you believe that this was theft then you should contact the police right away, then our Concierge by': {
        pt: 'Se você acredita que isso foi um roubo, deve entrar em contato com a polícia imediatamente e, em seguida, com nosso Concierge',
        es: 'Si cree que esto fue un robo, debe contactar a la policía de inmediato, luego a nuestro Concierge',
        ru: 'Если вы считаете, что это была кража, вам следует немедленно связаться с полицией, а затем с нашим Консьержем',
        fr: 'Si vous pensez qu\'il s\'agit d\'un vol, vous devez contacter la police immédiatement, puis notre Concierge',
        de: 'Wenn Sie glauben, dass es sich um einen Diebstahl handelt, sollten Sie sofort die Polizei kontaktieren und dann unseren Concierge'
    },
    'clicking here': {
        pt: 'clicando aqui',
        es: 'haciendo clic aquí',
        ru: 'нажав здесь',
        fr: 'en cliquant ici',
        de: 'hier klicken'
    },
    '. The police are in the best position to help you, and we follow their lead on theft investigations.': {
        pt: '. A polícia está na melhor posição para ajudá-lo, e seguimos sua orientação nas investigações de roubo.',
        es: '. La policía está en la mejor posición para ayudarlo, y seguimos su ejemplo en las investigaciones de robo.',
        ru: '. Полиция находится в лучшем положении, чтобы помочь вам, и мы следуем их руководству в расследованиях краж.',
        fr: '. La police est la mieux placée pour vous aider, et nous suivons leur exemple dans les enquêtes sur les vols.',
        de: '. Die Polizei ist in der besten Position, Ihnen zu helfen, und wir folgen ihrer Führung bei Diebstahlsermittlungen.'
    },
    'While we will provide our full support to you in taking legal action against the Pro, we do not cover theft per our': {
        pt: 'Embora forneceremos todo o nosso apoio para você tomar medidas legais contra o Profissional, não cobrimos roubo de acordo com nossos',
        es: 'Aunque le brindaremos todo nuestro apoyo para tomar acciones legales contra el Profesional, no cubrimos el robo según nuestros',
        ru: 'Хотя мы окажем вам полную поддержку в принятии юридических мер против Профессионала, мы не покрываем кражу согласно нашим',
        fr: 'Bien que nous vous apporterons tout notre soutien pour intenter une action en justice contre le Professionnel, nous ne couvrons pas le vol selon nos',
        de: 'Während wir Ihnen unsere volle Unterstützung bei rechtlichen Schritten gegen den Profi zusichern, decken wir Diebstahl gemäß unseren'
    },
    '. This process may take some time, so we appreciate your patience.': {
        pt: '. Este processo pode levar algum tempo, por isso agradecemos sua paciência.',
        es: '. Este proceso puede llevar algún tiempo, por lo que agradecemos su paciencia.',
        ru: '. Этот процесс может занять некоторое время, поэтому мы ценим ваше терпение.',
        fr: '. Ce processus peut prendre du temps, nous apprécions donc votre patience.',
        de: '. Dieser Prozess kann einige Zeit in Anspruch nehmen, daher danken wir Ihnen für Ihre Geduld.'
    },
    'Describe the missing item': {
        pt: 'Descreva o item faltante',
        es: 'Describa el artículo faltante',
        ru: 'Опишите отсутствующий предмет',
        fr: 'Décrivez l\'objet manquant',
        de: 'Beschreiben Sie den fehlenden Gegenstand'
    },
    'Please describe what is missing.': {
        pt: 'Por favor, descreva o que está faltando.',
        es: 'Por favor, describa lo que falta.',
        ru: 'Пожалуйста, опишите, что отсутствует.',
        fr: 'Veuillez décrire ce qui manque.',
        de: 'Bitte beschreiben Sie, was fehlt.'
    },
    'We have emailed the other party requesting they call you to help coordinate a pickup. This temporary session will automatically close whenever both parties stop communicating for 48 hours.': {
        pt: 'Enviamos um e-mail à outra parte solicitando que eles liguem para você para ajudar a coordenar uma retirada. Esta sessão temporária será automaticamente encerrada sempre que ambas as partes pararem de se comunicar por 48 horas.',
        es: 'Hemos enviado un correo electrónico a la otra parte solicitando que lo llamen para ayudar a coordinar una recogida. Esta sesión temporal se cerrará automáticamente cuando ambas partes dejen de comunicarse durante 48 horas.',
        ru: 'Мы отправили электронное письмо другой стороне с просьбой позвонить вам, чтобы помочь координировать получение. Эта временная сессия автоматически закроется, когда обе стороны прекратят общение на 48 часов.',
        fr: 'Nous avons envoyé un e-mail à l\'autre partie leur demandant de vous appeler pour aider à coordonner un ramassage. Cette session temporaire se fermera automatiquement lorsque les deux parties cesseront de communiquer pendant 48 heures.',
        de: 'Wir haben der anderen Partei eine E-Mail geschickt und sie gebeten, Sie anzurufen, um bei der Koordinierung einer Abholung zu helfen. Diese temporäre Sitzung wird automatisch geschlossen, wenn beide Parteien 48 Stunden lang nicht mehr kommunizieren.'
    },

    //AccountNotificationSettingsPage
    'These notifications trigger for your chosen events.': {
        pt: 'Estas notificações são acionadas para os eventos escolhidos por você.',
        es: 'Estas notificaciones se activan para los eventos que usted elija.',
        ru: 'Эти уведомления срабатывают для выбранных вами событий.',
        fr: 'Ces notifications se déclenchent pour les événements que vous avez choisis.',
        de: 'Diese Benachrichtigungen werden für Ihre ausgewählten Ereignisse ausgelöst.'
    },
    'No notifications added.': {
        pt: 'Nenhuma notificação adicionada.',
        es: 'No se han añadido notificaciones.',
        ru: 'Уведомления не добавлены.',
        fr: 'Aucune notification ajoutée.',
        de: 'Keine Benachrichtigungen hinzugefügt.'
    },
    'Add Notifications': {
        pt: 'Adicionar Notificações',
        es: 'Añadir Notificaciones',
        ru: 'Добавить Уведомления',
        fr: 'Ajouter des Notifications',
        de: 'Benachrichtigungen Hinzufügen'
    },
    'How Account Notifications Work:': {
        pt: 'Como Funcionam as Notificações da Conta:',
        es: 'Cómo Funcionan las Notificaciones de la Cuenta:',
        ru: 'Как Работают Уведомления Аккаунта:',
        fr: 'Comment Fonctionnent les Notifications du Compte :',
        de: 'Wie Kontobenachrichtigungen Funktionieren:'
    },
    'Select Channel': {
        pt: 'Selecionar Canal',
        es: 'Seleccionar Canal',
        ru: 'Выбрать Канал',
        fr: 'Sélectionner le Canal',
        de: 'Kanal Auswählen'
    },
    'TIDY can push notifications to a webhook or Slack.': {
        pt: 'O TIDY pode enviar notificações para um webhook ou Slack.',
        es: 'TIDY puede enviar notificaciones a un webhook o Slack.',
        ru: 'TIDY может отправлять уведомления на вебхук или в Slack.',
        fr: 'TIDY peut envoyer des notifications à un webhook ou à Slack.',
        de: 'TIDY kann Benachrichtigungen an einen Webhook oder Slack senden.'
    },
    'Choose Events': {
        pt: 'Escolher Eventos',
        es: 'Elegir Eventos',
        ru: 'Выбрать События',
        fr: 'Choisir les Événements',
        de: 'Ereignisse Auswählen'
    },
    'Select what you want to be notified about (booked jobs, messages, etc.)': {
        pt: 'Selecione sobre o que deseja ser notificado (trabalhos agendados, mensagens, etc.)',
        es: 'Seleccione sobre qué desea ser notificado (trabajos reservados, mensajes, etc.)',
        ru: 'Выберите, о чем вы хотите получать уведомления (забронированные задания, сообщения и т.д.)',
        fr: 'Sélectionnez ce dont vous voulez être notifié (travaux réservés, messages, etc.)',
        de: 'Wählen Sie aus, worüber Sie benachrichtigt werden möchten (gebuchte Aufträge, Nachrichten usw.)'
    },
    'Receive Notifications': {
        pt: 'Receber Notificações',
        es: 'Recibir Notificaciones',
        ru: 'Получать Уведомления',
        fr: 'Recevoir des Notifications',
        de: 'Benachrichtigungen Erhalten'
    },
    'Get instant notifications for your chosen events.': {
        pt: 'Receba notificações instantâneas para os eventos escolhidos.',
        es: 'Reciba notificaciones instantáneas para los eventos elegidos.',
        ru: 'Получайте мгновенные уведомления о выбранных событиях.',
        fr: 'Recevez des notifications instantanées pour les événements choisis.',
        de: 'Erhalten Sie sofortige Benachrichtigungen für Ihre ausgewählten Ereignisse.'
    },
    'Remove Event for Channel?': {
        pt: 'Remover Evento do Canal?',
        es: '¿Eliminar Evento del Canal?',
        ru: 'Удалить Событие для Канала?',
        fr: 'Supprimer l\'Événement pour le Canal ?',
        de: 'Ereignis für Kanal Entfernen?'
    },

    //AccountSettingsPage
    'Account Settings': {
        pt: 'Configurações da Conta',
        es: 'Configuración de la Cuenta',
        ru: 'Настройки Аккаунта',
        fr: 'Paramètres du Compte',
        de: 'Kontoeinstellungen'
    },
    'Contact Info': {
        pt: 'Informações de Contato',
        es: 'Información de Contacto',
        ru: 'Контактная Информация',
        fr: 'Informations de Contact',
        de: 'Kontaktinformationen'
    },
    'Missing Phone number': {
        pt: 'Número de Telefone Ausente',
        es: 'Número de Teléfono Faltante',
        ru: 'Отсутствует Номер Телефона',
        fr: 'Numéro de Téléphone Manquant',
        de: 'Fehlende Telefonnummer'
    },
    'Account Notifications': {
        pt: 'Notificações da Conta',
        es: 'Notificaciones de la Cuenta',
        ru: 'Уведомления Аккаунта',
        fr: 'Notifications du Compte',
        de: 'Kontobenachrichtigungen'
    },
    'Account Type': {
        pt: 'Tipo de Conta',
        es: 'Tipo de Cuenta',
        ru: 'Тип Аккаунта',
        fr: 'Type de Compte',
        de: 'Kontotyp'
    },
    'Default Backup Times': {
        pt: 'Horários Padrão de Backup',
        es: 'Horarios Predeterminados de Respaldo',
        ru: 'Стандартное Время Резервного Копирования',
        fr: 'Heures de Sauvegarde par Défaut',
        de: 'Standard-Backup-Zeiten'
    },
    'Display Language': {
        pt: 'Idioma de Exibição',
        es: 'Idioma de Visualización',
        ru: 'Язык Интерфейса',
        fr: 'Langue d\'Affichage',
        de: 'Anzeigesprache'
    },
    'Referral Code': {
        pt: 'Código de Indicação',
        es: 'Código de Referencia',
        ru: 'Реферальный Код',
        fr: 'Code de Parrainage',
        de: 'Empfehlungscode'
    },
    'Friends who use this get $10 off their first job, then you get $10 off your next.': {
        pt: 'Amigos que usam isso recebem $10 de desconto no primeiro trabalho, depois você recebe $10 de desconto no próximo.',
        es: 'Los amigos que usen esto obtienen $10 de descuento en su primer trabajo, luego tú obtienes $10 de descuento en el siguiente.',
        ru: 'Друзья, которые используют это, получают скидку $10 на первую работу, затем вы получаете скидку $10 на следующую.',
        fr: 'Les amis qui utilisent ceci obtiennent 10$ de réduction sur leur premier travail, puis vous obtenez 10$ de réduction sur le suivant.',
        de: 'Freunde, die dies nutzen, erhalten 10$ Rabatt auf ihren ersten Auftrag, dann erhalten Sie 10$ Rabatt auf Ihren nächsten.'
    },
    'English': {
        pt: 'Inglês',
        es: 'Inglés',
        ru: 'Английский',
        fr: 'Anglais',
        de: 'Englisch'
    },
    'Portuguese': {
        pt: 'Português',
        es: 'Portugués',
        ru: 'Португальский',
        fr: 'Portugais',
        de: 'Portugiesisch'
    },
    'Spanish': {
        pt: 'Espanhol',
        es: 'Español',
        ru: 'Испанский',
        fr: 'Espagnol',
        de: 'Spanisch'
    },
    'Russian': {
        pt: 'Russo',
        es: 'Ruso',
        ru: 'Русский',
        fr: 'Russe',
        de: 'Russisch'
    },
    'French': {
        pt: 'Francês',
        es: 'Francés',
        ru: 'Французский',
        fr: 'Français',
        de: 'Französisch'
    },
    'Dutch': {
        pt: 'Holandês',
        es: 'Holandés',
        ru: 'Голландский',
        fr: 'Néerlandais',
        de: 'Niederländisch'
    },
    'Residential': {
        pt: 'Residencial',
        es: 'Residencial',
        ru: 'Жилой',
        fr: 'Résidentiel',
        de: 'Wohnbereich'
    },
    'Realtor': {
        pt: 'Corretor de Imóveis',
        es: 'Agente Inmobiliario',
        ru: 'Риэлтор',
        fr: 'Agent Immobilier',
        de: 'Makler'
    },
    'Rental': {
        pt: 'Aluguel',
        es: 'Alquiler',
        ru: 'Аренда',
        fr: 'Location',
        de: 'Vermietung'
    },
    'Office': {
        pt: 'Escritório',
        es: 'Oficina',
        ru: 'Офис',
        fr: 'Bureau',
        de: 'Büro'
    },

    //AccountTypePage
    'Edit Account Type': {
        pt: 'Editar Tipo de Conta',
        es: 'Editar Tipo de Cuenta',
        ru: 'Изменить Тип Аккаунта',
        fr: 'Modifier le Type de Compte',
        de: 'Kontotyp Bearbeiten'
    },
    'What are you using TIDY for?': {
        pt: 'Para que você está usando a TIDY?',
        es: '¿Para qué estás usando TIDY?',
        ru: 'Для чего вы используете TIDY?',
        fr: 'Pour quoi utilisez-vous TIDY ?',
        de: 'Wofür verwenden Sie TIDY?'
    },
    'Simple Mode': {
        pt: 'Modo Simples',
        es: 'Modo Simple',
        ru: 'Простой Режим',
        fr: 'Mode Simple',
        de: 'Einfacher Modus'
    },
    'Save Account Type': {
        pt: 'Salvar Tipo de Conta',
        es: 'Guardar Tipo de Cuenta',
        ru: 'Сохранить Тип Аккаунта',
        fr: 'Enregistrer le Type de Compte',
        de: 'Kontotyp Speichern'
    },
    'Account Type Updated': {
        pt: 'Tipo de Conta Atualizado',
        es: 'Tipo de Cuenta Actualizado',
        ru: 'Тип Аккаунта Обновлен',
        fr: 'Type de Compte Mis à Jour',
        de: 'Kontotyp Aktualisiert'
    },

    //AddAccountNotificationPage
    'Add notifications to your account:': {
        pt: 'Adicione notificações à sua conta:',
        es: 'Agregue notificaciones a su cuenta:',
        ru: 'Добавьте уведомления в свою учетную запись:',
        fr: 'Ajoutez des notifications à votre compte:',
        de: 'Fügen Sie Ihrem Konto Benachrichtigungen hinzu:'
    },
    'Team to Notify': {
        pt: 'Equipe a Notificar',
        es: 'Equipo a Notificar',
        ru: 'Команда для Уведомления',
        fr: 'Équipe à Notifier',
        de: 'Zu Benachrichtigendes Team'
    },
    'What team do you want to notify?': {
        pt: 'Qual equipe você deseja notificar?',
        es: '¿A qué equipo quieres notificar?',
        ru: 'Какую команду вы хотите уведомить?',
        fr: 'Quelle équipe voulez-vous notifier ?',
        de: 'Welches Team möchten Sie benachrichtigen?'
    },
    'Notification Channel': {
        pt: 'Canal de Notificação',
        es: 'Canal de Notificación',
        ru: 'Канал Уведомлений',
        fr: 'Canal de Notification',
        de: 'Benachrichtigungskanal'
    },
    'Where should we send these notifications?': {
        pt: 'Para onde devemos enviar essas notificações?',
        es: '¿Dónde debemos enviar estas notificaciones?',
        ru: 'Куда мы должны отправлять эти уведомления?',
        fr: 'Où devrions-nous envoyer ces notifications ?',
        de: 'Wohin sollen wir diese Benachrichtigungen senden?'
    },
    'Add a Slack webhook to get notified on Slack.': {
        pt: 'Adicione um webhook do Slack para receber notificações no Slack.',
        es: 'Agregue un webhook de Slack para recibir notificaciones en Slack.',
        ru: 'Добавьте вебхук Slack, чтобы получать уведомления в Slack.',
        fr: 'Ajoutez un webhook Slack pour être notifié sur Slack.',
        de: 'Fügen Sie einen Slack-Webhook hinzu, um auf Slack benachrichtigt zu werden.'
    },
    'Endpoint URL': {
        pt: 'URL do Endpoint',
        es: 'URL del Endpoint',
        ru: 'URL Конечной Точки',
        fr: 'URL du Point de Terminaison',
        de: 'Endpunkt-URL'
    },
    'Please input a URL.': {
        pt: 'Por favor, insira uma URL.',
        es: 'Por favor, ingrese una URL.',
        ru: 'Пожалуйста, введите URL.',
        fr: 'Veuillez saisir une URL.',
        de: 'Bitte geben Sie eine URL ein.'
    },
    'Notification Events': {
        pt: 'Eventos de Notificação',
        es: 'Eventos de Notificación',
        ru: 'События Уведомлений',
        fr: 'Événements de Notification',
        de: 'Benachrichtigungsereignisse'
    },
    'What events should trigger notifications?': {
        pt: 'Quais eventos devem acionar notificações?',
        es: '¿Qué eventos deben activar notificaciones?',
        ru: 'Какие события должны вызывать уведомления?',
        fr: 'Quels événements doivent déclencher des notifications ?',
        de: 'Welche Ereignisse sollen Benachrichtigungen auslösen?'
    },
    'Please select at least one event.': {
        pt: 'Por favor, selecione pelo menos um evento.',
        es: 'Por favor, seleccione al menos un evento.',
        ru: 'Пожалуйста, выберите хотя бы одно событие.',
        fr: 'Veuillez sélectionner au moins un événement.',
        de: 'Bitte wählen Sie mindestens ein Ereignis aus.'
    },
    'Add Account Notification': {
        pt: 'Adicionar Notificação de Conta',
        es: 'Agregar Notificación de Cuenta',
        ru: 'Добавить Уведомление Аккаунта',
        fr: 'Ajouter une Notification de Compte',
        de: 'Kontobenachrichtigung Hinzufügen'
    },
    'Webhook Added': {
        pt: 'Webhook Adicionado',
        es: 'Webhook Agregado',
        ru: 'Вебхук Добавлен',
        fr: 'Webhook Ajouté',
        de: 'Webhook Hinzugefügt'
    },

    //AddressesPage
    'Properties': {
        pt: 'Propriedades',
        es: 'Propiedades',
        ru: 'Объекты',
        fr: 'Propriétés',
        de: 'Immobilien'
    },
    'TIDY\'s features work even better if you have multiple properties. Automate cleanings, create maps, get maintenance tips, share to-do lists, & more!': {
        pt: 'As funcionalidades do TIDY funcionam ainda melhor se você tiver várias propriedades. Automatize limpezas, crie mapas, obtenha dicas de manutenção, compartilhe listas de tarefas e muito mais!',
        es: 'Las características de TIDY funcionan aún mejor si tienes múltiples propiedades. ¡Automatiza limpiezas, crea mapas, obtén consejos de mantenimiento, comparte listas de tareas y más!',
        ru: 'Функции TIDY работают еще лучше, если у вас несколько объектов. Автоматизируйте уборку, создавайте карты, получайте советы по обслуживанию, делитесь списками дел и многое другое!',
        fr: 'Les fonctionnalités de TIDY fonctionnent encore mieux si vous avez plusieurs propriétés. Automatisez les nettoyages, créez des plans, obtenez des conseils d\'entretien, partagez des listes de tâches et plus encore !',
        de: 'Die Funktionen von TIDY funktionieren noch besser, wenn Sie mehrere Immobilien haben. Automatisieren Sie Reinigungen, erstellen Sie Pläne, erhalten Sie Wartungstipps, teilen Sie To-Do-Listen und vieles mehr!'
    },
    'Synced with': {
        pt: 'Sincronizado com',
        es: 'Sincronizado con',
        ru: 'Синхронизировано с',
        fr: 'Synchronisé avec',
        de: 'Synchronisiert mit'
    },

    //ApiKeysPage
    //ApiKeysPage
    'Viewing live API keys': {
        pt: 'Visualizando chaves de API ativas',
        es: 'Visualizando claves de API activas',
        ru: 'Просмотр активных API-ключей',
        fr: 'Affichage des clés API actives',
        de: 'Anzeige aktiver API-Schlüssel'
    },
    'View Documentation': {
        pt: 'Ver Documentação',
        es: 'Ver Documentación',
        ru: 'Просмотреть Документацию',
        fr: 'Voir la Documentation',
        de: 'Dokumentation Anzeigen'
    },
    'Token': {
        pt: 'Token',
        es: 'Token',
        ru: 'Токен',
        fr: 'Jeton',
        de: 'Token'
    },
    'Environment': {
        pt: 'Ambiente',
        es: 'Entorno',
        ru: 'Окружение',
        fr: 'Environnement',
        de: 'Umgebung'
    },
    'Production': {
        pt: 'Produção',
        es: 'Producción',
        ru: 'Продакшн',
        fr: 'Production',
        de: 'Produktion'
    },
    'Delete Key': {
        pt: 'Excluir Chave',
        es: 'Eliminar Clave',
        ru: 'Удалить Ключ',
        fr: 'Supprimer la Clé',
        de: 'Schlüssel Löschen'
    },
    'You have no API keys. Generate one to use the TIDY API or Zapier.': {
        pt: 'Você não tem chaves de API. Gere uma para usar a API TIDY ou o Zapier.',
        es: 'No tienes claves de API. Genera una para usar la API de TIDY o Zapier.',
        ru: 'У вас нет API-ключей. Сгенерируйте один, чтобы использовать API TIDY или Zapier.',
        fr: 'Vous n\'avez pas de clés API. Générez-en une pour utiliser l\'API TIDY ou Zapier.',
        de: 'Sie haben keine API-Schlüssel. Generieren Sie einen, um die TIDY-API oder Zapier zu verwenden.'
    },
    'Create New API Key': {
        pt: 'Criar Nova Chave de API',
        es: 'Crear Nueva Clave de API',
        ru: 'Создать Новый API-Ключ',
        fr: 'Créer une Nouvelle Clé API',
        de: 'Neuen API-Schlüssel Erstellen'
    },
    'You will be unable to perform any more actions with this key.': {
        pt: 'Você não poderá realizar mais nenhuma ação com esta chave.',
        es: 'No podrás realizar más acciones con esta clave.',
        ru: 'Вы не сможете выполнять никаких действий с этим ключом.',
        fr: 'Vous ne pourrez plus effectuer d\'actions avec cette clé.',
        de: 'Sie können mit diesem Schlüssel keine weiteren Aktionen mehr durchführen.'
    },
    'Yes I want to delete this key.': {
        pt: 'Sim, quero excluir esta chave.',
        es: 'Sí, quiero eliminar esta clave.',
        ru: 'Да, я хочу удалить этот ключ.',
        fr: 'Oui, je veux supprimer cette clé.',
        de: 'Ja, ich möchte diesen Schlüssel löschen.'
    },
    'Delete API Key?': {
        pt: 'Excluir Chave de API?',
        es: '¿Eliminar Clave de API?',
        ru: 'Удалить API-Ключ?',
        fr: 'Supprimer la Clé API ?',
        de: 'API-Schlüssel Löschen?'
    },
    'Please confirm that you want to delete the key.': {
        pt: 'Por favor, confirme que deseja excluir a chave.',
        es: 'Por favor, confirma que deseas eliminar la clave.',
        ru: 'Пожалуйста, подтвердите, что вы хотите удалить ключ.',
        fr: 'Veuillez confirmer que vous souhaitez supprimer la clé.',
        de: 'Bitte bestätigen Sie, dass Sie den Schlüssel löschen möchten.'
    },
    'API Keys': {
        pt: 'Chaves de API',
        es: 'Claves de API',
        ru: 'API-Ключи',
        fr: 'Clés API',
        de: 'API-Schlüssel'
    },

    //AppBrandingPage
    'Your Branding': {
        pt: 'Sua Marca',
        es: 'Tu Marca',
        ru: 'Ваш Брендинг',
        fr: 'Votre Marque',
        de: 'Ihr Branding'
    },
    'Select desktop background:': {
        pt: 'Selecione o fundo da área de trabalho:',
        es: 'Seleccione el fondo de escritorio:',
        ru: 'Выберите фон рабочего стола:',
        fr: 'Sélectionnez le fond d\'écran:',
        de: 'Wählen Sie den Desktop-Hintergrund:'
    },
    'Background': {
        pt: 'Fundo',
        es: 'Fondo',
        ru: 'Фон',
        fr: 'Arrière-plan',
        de: 'Hintergrund'
    },
    'Color Hex Code (ex: #41CAB7)': {
        pt: 'Código Hexadecimal da Cor (ex: #41CAB7)',
        es: 'Código Hexadecimal del Color (ej: #41CAB7)',
        ru: 'Шестнадцатеричный код цвета (например: #41CAB7)',
        fr: 'Code Hexadécimal de Couleur (ex: #41CAB7)',
        de: 'Farb-Hex-Code (z.B.: #41CAB7)'
    },
    'Please enter a valid color hex.': {
        pt: 'Por favor, insira um código hexadecimal de cor válido.',
        es: 'Por favor, ingrese un código hexadecimal de color válido.',
        ru: 'Пожалуйста, введите действительный шестнадцатеричный код цвета.',
        fr: 'Veuillez entrer un code hexadécimal de couleur valide.',
        de: 'Bitte geben Sie einen gültigen Farb-Hex-Code ein.'
    },
    'Upload a background image': {
        pt: 'Carregar uma imagem de fundo',
        es: 'Subir una imagen de fondo',
        ru: 'Загрузить фоновое изображение',
        fr: 'Télécharger une image d\'arrière-plan',
        de: 'Laden Sie ein Hintergrundbild hoch'
    },
    'Attachment:': {
        pt: 'Anexo:',
        es: 'Adjunto:',
        ru: 'Вложение:',
        fr: 'Pièce jointe:',
        de: 'Anhang:'
    },
    'Logo': {
        pt: 'Logotipo',
        es: 'Logotipo',
        ru: 'Логотип',
        fr: 'Logo',
        de: 'Logo'
    },
    'Select app logo:': {
        pt: 'Selecione o logotipo do aplicativo:',
        es: 'Seleccione el logotipo de la aplicación:',
        ru: 'Выберите логотип приложения:',
        fr: 'Sélectionnez le logo de l\'application:',
        de: 'Wählen Sie das App-Logo:'
    },
    'Upload a Logo image': {
        pt: 'Carregar uma imagem de Logotipo',
        es: 'Subir una imagen de Logotipo',
        ru: 'Загрузить изображение Логотипа',
        fr: 'Télécharger une image de Logo',
        de: 'Laden Sie ein Logo-Bild hoch'
    },
    'Default TIDY background': {
        pt: 'Fundo TIDY padrão',
        es: 'Fondo TIDY predeterminado',
        ru: 'Стандартный фон TIDY',
        fr: 'Arrière-plan TIDY par défaut',
        de: 'Standard TIDY-Hintergrund'
    },
    'Solid color': {
        pt: 'Cor sólida',
        es: 'Color sólido',
        ru: 'Сплошной цвет',
        fr: 'Couleur unie',
        de: 'Einfarbig'
    },
    'Upload image': {
        pt: 'Carregar imagem',
        es: 'Subir imagen',
        ru: 'Загрузить изображение',
        fr: 'Télécharger l\'image',
        de: 'Bild hochladen'
    },
    'Default logo': {
        pt: 'Logotipo padrão',
        es: 'Logotipo predeterminado',
        ru: 'Стандартный логотип',
        fr: 'Logo par défaut',
        de: 'Standard-Logo'
    },

    //AutomaticBookingPage
    'Sync Pending': {
        pt: 'Sincronização Pendente',
        es: 'Sincronización Pendiente',
        ru: 'Ожидание синхронизации',
        fr: 'Synchronisation en Attente',
        de: 'Synchronisierung Ausstehend'
    },
    'TIDY is syncing the integration you added. Please check back soon.': {
        pt: 'TIDY está sincronizando a integração que você adicionou. Por favor, verifique em breve.',
        es: 'TIDY está sincronizando la integración que agregó. Por favor, vuelva a verificar pronto.',
        ru: 'TIDY синхронизирует добавленную вами интеграцию. Пожалуйста, проверьте позже.',
        fr: 'TIDY synchronise l\'intégration que vous avez ajoutée. Veuillez vérifier bientôt.',
        de: 'TIDY synchronisiert die von Ihnen hinzugefügte Integration. Bitte schauen Sie bald wieder vorbei.'
    },
    'TIDY can automatically book a job for your pros when a reservation is synced.': {
        pt: 'TIDY pode reservar automaticamente um trabalho para seus profissionais quando uma reserva é sincronizada.',
        es: 'TIDY puede reservar automáticamente un trabajo para sus profesionales cuando se sincroniza una reserva.',
        ru: 'TIDY может автоматически бронировать работу для ваших специалистов при синхронизации бронирования.',
        fr: 'TIDY peut automatiquement réserver un travail pour vos professionnels lorsqu\'une réservation est synchronisée.',
        de: 'TIDY kann automatisch einen Auftrag für Ihre Profis buchen, wenn eine Reservierung synchronisiert wird.'
    },
    'No Integration Synced': {
        pt: 'Nenhuma Integração Sincronizada',
        es: 'Ninguna Integración Sincronizada',
        ru: 'Нет синхронизированных интеграций',
        fr: 'Aucune Intégration Synchronisée',
        de: 'Keine Integration Synchronisiert'
    },

    //AutomaticBookingAddressPage
    'When a reservation is synced, automatically add a cleaning for your pros. Cancel anytime.': {
        pt: 'Quando uma reserva é sincronizada, adicione automaticamente uma limpeza para seus profissionais. Cancele a qualquer momento.',
        es: 'Cuando se sincroniza una reserva, agregue automáticamente una limpieza para sus profesionales. Cancele en cualquier momento.',
        ru: 'Когда бронирование синхронизируется, автоматически добавляйте уборку для ваших специалистов. Отмените в любое время.',
        fr: 'Lorsqu\'une réservation est synchronisée, ajoutez automatiquement un nettoyage pour vos professionnels. Annulez à tout moment.',
        de: 'Wenn eine Reservierung synchronisiert wird, fügen Sie automatisch eine Reinigung für Ihre Profis hinzu. Jederzeit kündbar.'
    },
    'Set up Automatic Booking:': {
        pt: 'Configurar Reserva Automática:',
        es: 'Configurar Reserva Automática:',
        ru: 'Настройка автоматического бронирования:',
        fr: 'Configurer la Réservation Automatique:',
        de: 'Automatische Buchung einrichten:'
    },
    'Add Integration(s)': {
        pt: 'Adicionar Integração(ões)',
        es: 'Agregar Integración(es)',
        ru: 'Добавить интеграцию(и)',
        fr: 'Ajouter Intégration(s)',
        de: 'Integration(en) hinzufügen'
    },
    '1 Added': {
        pt: '1 Adicionado',
        es: '1 Agregado',
        ru: '1 Добавлено',
        fr: '1 Ajouté',
        de: '1 Hinzugefügt'
    },
    '0 Added': {
        pt: '0 Adicionado',
        es: '0 Agregado',
        ru: '0 Добавлено',
        fr: '0 Ajouté',
        de: '0 Hinzugefügt'
    },
    'Add at least 1 integration for this property.': {
        pt: 'Adicione pelo menos 1 integração para esta propriedade.',
        es: 'Agregue al menos 1 integración para esta propiedad.',
        ru: 'Добавьте как минимум 1 интеграцию для этого объекта.',
        fr: 'Ajoutez au moins 1 intégration pour cette propriété.',
        de: 'Fügen Sie mindestens 1 Integration für diese Immobilie hinzu.'
    },
    'Job requests will be sent to pros in the order listed in the My Pros section.': {
        pt: 'As solicitações de trabalho serão enviadas aos profissionais na ordem listada na seção Meus Profissionais.',
        es: 'Las solicitudes de trabajo se enviarán a los profesionales en el orden listado en la sección Mis Profesionales.',
        ru: 'Запросы на работу будут отправлены специалистам в порядке, указанном в разделе Мои специалисты.',
        fr: 'Les demandes de travail seront envoyées aux professionnels dans l\'ordre indiqué dans la section Mes Pros.',
        de: 'Auftragsanfragen werden an Profis in der Reihenfolge gesendet, die im Abschnitt Meine Profis aufgeführt ist.'
    },
    'Default job name*': {
        pt: 'Nome padrão do trabalho*',
        es: 'Nombre predeterminado del trabajo*',
        ru: 'Стандартное название работы*',
        fr: 'Nom de travail par défaut*',
        de: 'Standard-Jobname*'
    },
    '*You can customize the job name per pro/property in the My Pros page.': {
        pt: '*Você pode personalizar o nome do trabalho por profissional/propriedade na página Meus Profissionais.',
        es: '*Puede personalizar el nombre del trabajo por profesional/propiedad en la página Mis Profesionales.',
        ru: '*Вы можете настроить название работы для каждого специалиста/объекта на странице Мои специалисты.',
        fr: '*Vous pouvez personnaliser le nom du travail par pro/propriété dans la page Mes Pros.',
        de: '*Sie können den Jobnamen pro Profi/Immobilie auf der Seite Meine Profis anpassen.'
    },
    'Mar 1 - Mar 3': {
        pt: '1 de mar - 3 de mar',
        es: '1 de mar - 3 de mar',
        ru: '1 мар - 3 мар',
        fr: '1 mar - 3 mar',
        de: '1. Mär - 3. Mär'
    },
    'Mar 8 - Mar 10': {
        pt: '8 de mar - 10 de mar',
        es: '8 de mar - 10 de mar',
        ru: '8 мар - 10 мар',
        fr: '8 mar - 10 mar',
        de: '8. Mär - 10. Mär'
    },
    'Add Your Pros': {
        pt: 'Adicione Seus Profissionais',
        es: 'Agregue Sus Profesionales',
        ru: 'Добавьте Ваших Специалистов',
        fr: 'Ajoutez Vos Professionnels',
        de: 'Fügen Sie Ihre Profis hinzu'
    },
    'Approved': {
        pt: 'Aprovado',
        es: 'Aprobado',
        ru: 'Одобрено',
        fr: 'Approuvé',
        de: 'Genehmigt'
    },
    'Pros you add will invoice you after each job at your agreed rates (no additional cost). You can opt to autopay each pro or not.': {
        pt: 'Os profissionais que você adicionar irão faturá-lo após cada trabalho nas taxas acordadas (sem custo adicional). Você pode optar por pagar automaticamente cada profissional ou não.',
        es: 'Los profesionales que agregue le facturarán después de cada trabajo a las tarifas acordadas (sin costo adicional). Puede optar por pagar automáticamente a cada profesional o no.',
        ru: 'Специалисты, которых вы добавите, будут выставлять вам счет после каждой работы по согласованным ставкам (без дополнительных затрат). Вы можете выбрать автоматическую оплату каждому специалисту или нет.',
        fr: 'Les professionnels que vous ajoutez vous factureront après chaque travail à vos tarifs convenus (sans frais supplémentaires). Vous pouvez choisir de payer automatiquement chaque professionnel ou non.',
        de: 'Die von Ihnen hinzugefügten Profis stellen Ihnen nach jedem Auftrag zu Ihren vereinbarten Tarifen eine Rechnung (keine zusätzlichen Kosten). Sie können wählen, ob Sie jeden Profi automatisch bezahlen möchten oder nicht.'
    },
    'Please add at least one integration for this address to turn on automatic booking.': {
        pt: 'Por favor, adicione pelo menos uma integração para este endereço para ativar a reserva automática.',
        es: 'Por favor, agregue al menos una integración para esta dirección para activar la reserva automática.',
        ru: 'Пожалуйста, добавьте хотя бы одну интеграцию для этого адреса, чтобы включить автоматическое бронирование.',
        fr: 'Veuillez ajouter au moins une intégration pour cette adresse pour activer la réservation automatique.',
        de: 'Bitte fügen Sie mindestens eine Integration für diese Adresse hinzu, um die automatische Buchung zu aktivieren.'
    },
    'Automatic Booking Enabled': {
        pt: 'Reserva Automática Ativada',
        es: 'Reserva Automática Habilitada',
        ru: 'Автоматическое бронирование включено',
        fr: 'Réservation Automatique Activée',
        de: 'Automatische Buchung Aktiviert'
    },
    'It may take up to 5 minutes for job requests for your synced reservations to appear.': {
        pt: 'Pode levar até 5 minutos para que as solicitações de trabalho para suas reservas sincronizadas apareçam.',
        es: 'Puede tardar hasta 5 minutos para que aparezcan las solicitudes de trabajo para sus reservas sincronizadas.',
        ru: 'Может потребоваться до 5 минут, чтобы появились запросы на работу для ваших синхронизированных бронирований.',
        fr: 'Il peut falloir jusqu\'à 5 minutes pour que les demandes de travail pour vos réservations synchronisées apparaissent.',
        de: 'Es kann bis zu 5 Minuten dauern, bis Arbeitsanfragen für Ihre synchronisierten Reservierungen erscheinen.'
    },
    'Charge in advance (save 10%)': {
        pt: 'Cobrar antecipadamente (economize 10%)',
        es: 'Cobrar por adelantado (ahorre 10%)',
        ru: 'Оплата заранее (экономия 10%)',
        fr: 'Facturer à l\'avance (économisez 10%)',
        de: 'Im Voraus berechnen (10% sparen)'
    },

    //BackupTimesPage
    'If my Pro can\'t make the time I booked, automatically find a new time for my job at one of these times:': {
        pt: 'Se meu Profissional não puder comparecer no horário que reservei, encontre automaticamente um novo horário para meu trabalho em um destes horários:',
        es: 'Si mi Profesional no puede cumplir con el horario que reservé, encuentre automáticamente un nuevo horario para mi trabajo en uno de estos horarios:',
        ru: 'Если мой специалист не может прийти в забронированное мной время, автоматически найдите новое время для моей работы в одно из этих времен:',
        fr: 'Si mon Professionnel ne peut pas respecter l\'heure que j\'ai réservée, trouvez automatiquement un nouveau créneau pour mon travail à l\'un de ces horaires :',
        de: 'Wenn mein Profi den von mir gebuchten Termin nicht einhalten kann, finden Sie automatisch eine neue Zeit für meinen Auftrag zu einem dieser Zeitpunkte:'
    },
    'Time': {
        pt: 'Hora',
        es: 'Hora',
        ru: 'Время',
        fr: 'Heure',
        de: 'Zeit'
    },
    'Default Backup Times Saved': {
        pt: 'Horários de Backup Padrão Salvos',
        es: 'Horarios de Respaldo Predeterminados Guardados',
        ru: 'Стандартное Резервное Время Сохранено',
        fr: 'Horaires de Sauvegarde par Défaut Enregistrés',
        de: 'Standard-Backup-Zeiten Gespeichert'
    },
    'Please select both a start time and and end time for each day.': {
        pt: 'Por favor, selecione uma hora de início e uma hora de término para cada dia.',
        es: 'Por favor, seleccione una hora de inicio y una hora de finalización para cada día.',
        ru: 'Пожалуйста, выберите время начала и время окончания для каждого дня.',
        fr: 'Veuillez sélectionner une heure de début et une heure de fin pour chaque jour.',
        de: 'Bitte wählen Sie sowohl eine Start- als auch eine Endzeit für jeden Tag aus.'
    },

    //BulkUploadPage
    'Bulk Upload': {
        pt: 'Upload em Massa',
        es: 'Carga Masiva',
        ru: 'Массовая Загрузка',
        fr: 'Téléchargement en Masse',
        de: 'Massen-Upload'
    },
    'Replace all to-do lists, reservations, or inventory by exporting or importing.': {
        pt: 'Substitua todas as listas de tarefas, reservas ou inventário exportando ou importando.',
        es: 'Reemplace todas las listas de tareas, reservas o inventario exportando o importando.',
        ru: 'Замените все списки дел, бронирования или инвентарь путем экспорта или импорта.',
        fr: 'Remplacez toutes les listes de tâches, réservations ou inventaires en exportant ou important.',
        de: 'Ersetzen Sie alle To-Do-Listen, Reservierungen oder Inventar durch Exportieren oder Importieren.'
    },
    'What would you like to import / export?': {
        pt: 'O que você gostaria de importar / exportar?',
        es: '¿Qué le gustaría importar / exportar?',
        ru: 'Что бы вы хотели импортировать / экспортировать?',
        fr: 'Que souhaitez-vous importer / exporter ?',
        de: 'Was möchten Sie importieren / exportieren?'
    },
    'Export': {
        pt: 'Exportar',
        es: 'Exportar',
        ru: 'Экспорт',
        fr: 'Exporter',
        de: 'Exportieren'
    },
    'Would you like to export this?': {
        pt: 'Gostaria de exportar isto?',
        es: '¿Le gustaría exportar esto?',
        ru: 'Хотите это экспортировать?',
        fr: 'Voulez-vous exporter ceci ?',
        de: 'Möchten Sie dies exportieren?'
    },
    'Import': {
        pt: 'Importar',
        es: 'Importar',
        ru: 'Импорт',
        fr: 'Importer',
        de: 'Importieren'
    },
    'Replace all reservations/inventory/to-dos by importing. When you upload a new file it will replace everything, so make sure the import is complete.': {
        pt: 'Substitua todas as reservas/inventário/tarefas importando. Quando você fizer o upload de um novo arquivo, ele substituirá tudo, então certifique-se de que a importação esteja completa.',
        es: 'Reemplace todas las reservas/inventario/tareas importando. Cuando cargue un nuevo archivo, reemplazará todo, así que asegúrese de que la importación esté completa.',
        ru: 'Замените все бронирования/инвентарь/задачи путем импорта. Когда вы загружаете новый файл, он заменит все, поэтому убедитесь, что импорт завершен.',
        fr: 'Remplacez toutes les réservations/inventaires/tâches en important. Lorsque vous téléchargez un nouveau fichier, il remplacera tout, alors assurez-vous que l\'importation est complète.',
        de: 'Ersetzen Sie alle Reservierungen/Inventar/Aufgaben durch Importieren. Wenn Sie eine neue Datei hochladen, wird alles ersetzt, stellen Sie also sicher, dass der Import vollständig ist.'
    },
    'Reservations': {
        pt: 'Reservas',
        es: 'Reservas',
        ru: 'Бронирования',
        fr: 'Réservations',
        de: 'Reservierungen'
    },
    'Import failed. We recommend that you export first, then make changes, then import. Contact Concierge for help.': {
        pt: 'A importação falhou. Recomendamos que você exporte primeiro, faça as alterações e depois importe. Entre em contato com o Concierge para obter ajuda.',
        es: 'La importación falló. Recomendamos que primero exporte, luego haga cambios y luego importe. Contacte al Concierge para obtener ayuda.',
        ru: 'Импорт не удался. Мы рекомендуем сначала экспортировать, затем внести изменения, а затем импортировать. Обратитесь в Консьерж-службу за помощью.',
        fr: 'L\'importation a échoué. Nous vous recommandons d\'exporter d\'abord, puis d\'apporter des modifications, puis d\'importer. Contactez le Concierge pour obtenir de l\'aide.',
        de: 'Import fehlgeschlagen. Wir empfehlen, dass Sie zuerst exportieren, dann Änderungen vornehmen und dann importieren. Kontaktieren Sie den Concierge für Hilfe.'
    },

    //BuyBundlePage
    'Buy Bundle': {
        pt: 'Comprar Pacote',
        es: 'Comprar Paquete',
        ru: 'Купить Пакет',
        fr: 'Acheter un Forfait',
        de: 'Paket Kaufen'
    },
    'Save $50 on TIDY!': {
        pt: 'Economize $50 no TIDY!',
        es: '¡Ahorre $50 en TIDY!',
        ru: 'Сэкономьте $50 на TIDY!',
        fr: 'Économisez 50$ sur TIDY !',
        de: 'Sparen Sie $50 bei TIDY!'
    },
    'Want to save money on your Home Cleanings?  Purchase $550 in TIDY Credit for only $500.': {
        pt: 'Quer economizar dinheiro em suas limpezas domésticas? Compre $550 em Crédito TIDY por apenas $500.',
        es: '¿Quiere ahorrar dinero en sus limpiezas del hogar? Compre $550 en Crédito TIDY por solo $500.',
        ru: 'Хотите сэкономить на уборке дома? Купите кредит TIDY на $550 всего за $500.',
        fr: 'Vous voulez économiser sur vos nettoyages à domicile ? Achetez 550$ de Crédit TIDY pour seulement 500$.',
        de: 'Möchten Sie bei Ihren Hausreinigungen sparen? Kaufen Sie $550 TIDY-Guthaben für nur $500.'
    },
    'Try this risk-free - you can still cancel anytime and are eligible for our Satisfaction Guarantee.': {
        pt: 'Experimente sem riscos - você ainda pode cancelar a qualquer momento e é elegível para nossa Garantia de Satisfação.',
        es: 'Pruébelo sin riesgo - aún puede cancelar en cualquier momento y es elegible para nuestra Garantía de Satisfacción.',
        ru: 'Попробуйте без риска - вы все еще можете отменить в любое время и имеете право на нашу Гарантию удовлетворения.',
        fr: 'Essayez sans risque - vous pouvez toujours annuler à tout moment et êtes éligible à notre Garantie de Satisfaction.',
        de: 'Probieren Sie es risikofrei - Sie können jederzeit kündigen und haben Anspruch auf unsere Zufriedenheitsgarantie.'
    },
    'By Tapping "Pay $500 for Bundle Now" we will charge your card on file $500, and add $550 credit to your account.': {
        pt: 'Ao tocar em "Pagar $500 pelo Pacote Agora", cobraremos $500 no seu cartão registrado e adicionaremos $550 de crédito à sua conta.',
        es: 'Al tocar "Pagar $500 por el Paquete Ahora", cargaremos $500 a su tarjeta registrada y agregaremos $550 de crédito a su cuenta.',
        ru: 'Нажав "Оплатить $500 за пакет сейчас", мы спишем $500 с вашей карты и добавим $550 кредита на ваш счет.',
        fr: 'En appuyant sur "Payer 500$ pour le forfait maintenant", nous débiterons votre carte enregistrée de 500$ et ajouterons 550$ de crédit à votre compte.',
        de: 'Durch Tippen auf "Jetzt $500 für Paket bezahlen" belasten wir Ihre hinterlegte Karte mit $500 und fügen Ihrem Konto $550 Guthaben hinzu.'
    },
    'Pay $500 for Bundle Now': {
        pt: 'Pagar $500 pelo Pacote Agora',
        es: 'Pagar $500 por el Paquete Ahora',
        ru: 'Оплатить $500 за пакет сейчас',
        fr: 'Payer 500$ pour le forfait maintenant',
        de: 'Jetzt $500 für Paket bezahlen'
    },
    'Your card was declined. Please update your card': {
        pt: 'Seu cartão foi recusado. Por favor, atualize seu cartão',
        es: 'Su tarjeta fue rechazada. Por favor, actualice su tarjeta',
        ru: 'Ваша карта отклонена. Пожалуйста, обновите данные карты',
        fr: 'Votre carte a été refusée. Veuillez mettre à jour votre carte',
        de: 'Ihre Karte wurde abgelehnt. Bitte aktualisieren Sie Ihre Karte'
    },
    'Bundle Bought': {
        pt: 'Pacote Comprado',
        es: 'Paquete Comprado',
        ru: 'Пакет Куплен',
        fr: 'Forfait Acheté',
        de: 'Paket Gekauft'
    },
    'Your card was charged $500 and you received $550 credit ($50 savings).  You can view this credit in your billing history now.': {
        pt: 'Seu cartão foi cobrado em $500 e você recebeu $550 de crédito (economia de $50). Você pode ver este crédito em seu histórico de faturamento agora.',
        es: 'Se cargaron $500 a su tarjeta y recibió $550 de crédito (ahorro de $50). Puede ver este crédito en su historial de facturación ahora.',
        ru: 'С вашей карты списано $500, и вы получили $550 кредита (экономия $50). Вы можете просмотреть этот кредит в истории счетов прямо сейчас.',
        fr: 'Votre carte a été débitée de 500$ et vous avez reçu 550$ de crédit (50$ d\'économies). Vous pouvez consulter ce crédit dans votre historique de facturation maintenant.',
        de: 'Ihre Karte wurde mit $500 belastet und Sie haben $550 Guthaben erhalten ($50 Ersparnis). Sie können dieses Guthaben jetzt in Ihrem Abrechnungsverlauf einsehen.'
    },

    //CancellationOptionsPage
    'There are several ways to cancel to accomodate your needs:': {
        pt: 'Existem várias maneiras de cancelar para atender às suas necessidades:',
        es: 'Hay varias formas de cancelar para adaptarse a sus necesidades:',
        ru: 'Есть несколько способов отмены, чтобы удовлетворить ваши потребности:',
        fr: 'Il existe plusieurs façons d\'annuler pour répondre à vos besoins :',
        de: 'Es gibt mehrere Möglichkeiten zu kündigen, um Ihren Bedürfnissen gerecht zu werden:'
    },
    'Cancel All Jobs': {
        pt: 'Cancelar Todos os Trabalhos',
        es: 'Cancelar Todos los Trabajos',
        ru: 'Отменить Все Работы',
        fr: 'Annuler Tous les Travaux',
        de: 'Alle Aufträge Stornieren'
    },
    'Use this if you want to make sure you have nothing future scheduled.': {
        pt: 'Use isso se quiser ter certeza de que não há nada agendado para o futuro.',
        es: 'Use esto si quiere asegurarse de que no tiene nada programado para el futuro.',
        ru: 'Используйте это, если хотите убедиться, что у вас нет запланированных работ в будущем.',
        fr: 'Utilisez ceci si vous voulez vous assurer que vous n\'avez rien de prévu pour l\'avenir.',
        de: 'Verwenden Sie dies, wenn Sie sicherstellen möchten, dass Sie nichts für die Zukunft geplant haben.'
    },
    'Cancel Account & Delete Data': {
        pt: 'Cancelar Conta e Excluir Dados',
        es: 'Cancelar Cuenta y Eliminar Datos',
        ru: 'Отменить Аккаунт и Удалить Данные',
        fr: 'Annuler le Compte et Supprimer les Données',
        de: 'Konto Kündigen und Daten Löschen'
    },
    'Use this if you are looking to delete as much information as possible.': {
        pt: 'Use isso se você deseja excluir o máximo de informações possível.',
        es: 'Use esto si busca eliminar la mayor cantidad de información posible.',
        ru: 'Используйте это, если хотите удалить как можно больше информации.',
        fr: 'Utilisez ceci si vous cherchez à supprimer autant d\'informations que possible.',
        de: 'Verwenden Sie dies, wenn Sie so viele Informationen wie möglich löschen möchten.'
    },
    'We do not recommend doing this if you need any job or billing history in the future or are looking for a refund, as that information can be relevant to you.': {
        pt: 'Não recomendamos fazer isso se você precisar de qualquer histórico de trabalho ou faturamento no futuro ou estiver procurando um reembolso, pois essas informações podem ser relevantes para você.',
        es: 'No recomendamos hacer esto si necesita algún historial de trabajo o facturación en el futuro o está buscando un reembolso, ya que esa información puede ser relevante para usted.',
        ru: 'Мы не рекомендуем делать это, если вам понадобится история работ или счетов в будущем, или если вы ищете возврат средств, так как эта информация может быть для вас актуальной.',
        fr: 'Nous ne recommandons pas de faire cela si vous avez besoin d\'un historique de travail ou de facturation à l\'avenir ou si vous cherchez un remboursement, car ces informations peuvent être pertinentes pour vous.',
        de: 'Wir empfehlen dies nicht, wenn Sie in Zukunft einen Arbeits- oder Abrechnungsverlauf benötigen oder eine Rückerstattung suchen, da diese Informationen für Sie relevant sein können.'
    },
    'Please resolve any job or billing issue first before deleting your data.': {
        pt: 'Por favor, resolva qualquer problema de trabalho ou faturamento antes de excluir seus dados.',
        es: 'Por favor, resuelva cualquier problema de trabajo o facturación antes de eliminar sus datos.',
        ru: 'Пожалуйста, разрешите любые проблемы с работой или оплатой, прежде чем удалять свои данные.',
        fr: 'Veuillez résoudre tout problème de travail ou de facturation avant de supprimer vos données.',
        de: 'Bitte lösen Sie zuerst alle Arbeits- oder Abrechnungsprobleme, bevor Sie Ihre Daten löschen.'
    },
    'You cannot do this if you have a pending charge or refund that has not fully processed. If you need billing help, remember to contact the TIDY Concierge.': {
        pt: 'Você não pode fazer isso se tiver uma cobrança pendente ou reembolso que não foi totalmente processado. Se precisar de ajuda com faturamento, lembre-se de entrar em contato com o Concierge TIDY.',
        es: 'No puede hacer esto si tiene un cargo pendiente o un reembolso que no se ha procesado completamente. Si necesita ayuda con la facturación, recuerde contactar al Concierge de TIDY.',
        ru: 'Вы не можете сделать это, если у вас есть ожидающий платеж или возврат, который еще не полностью обработан. Если вам нужна помощь с оплатой, не забудьте связаться с Консьержем TIDY.',
        fr: 'Vous ne pouvez pas faire cela si vous avez un paiement en attente ou un remboursement qui n\'a pas été entièrement traité. Si vous avez besoin d\'aide pour la facturation, n\'oubliez pas de contacter le Concierge TIDY.',
        de: 'Sie können dies nicht tun, wenn Sie eine ausstehende Belastung oder Rückerstattung haben, die noch nicht vollständig verarbeitet wurde. Wenn Sie Hilfe bei der Abrechnung benötigen, denken Sie daran, den TIDY Concierge zu kontaktieren.'
    },
    'Cancel Jobs & Delete Data': {
        pt: 'Cancelar Trabalhos e Excluir Dados',
        es: 'Cancelar Trabajos y Eliminar Datos',
        ru: 'Отменить Работы и Удалить Данные',
        fr: 'Annuler les Travaux et Supprimer les Données',
        de: 'Aufträge Stornieren und Daten Löschen'
    },
    'As your account data is important to resolve any job or billing related issues, please confirm the following:': {
        pt: 'Como os dados da sua conta são importantes para resolver quaisquer problemas relacionados a trabalhos ou faturamento, por favor, confirme o seguinte:',
        es: 'Como los datos de su cuenta son importantes para resolver cualquier problema relacionado con el trabajo o la facturación, por favor confirme lo siguiente:',
        ru: 'Поскольку данные вашего аккаунта важны для решения любых проблем, связанных с работой или оплатой, пожалуйста, подтвердите следующее:',
        fr: 'Comme les données de votre compte sont importantes pour résoudre tout problème lié au travail ou à la facturation, veuillez confirmer ce qui suit :',
        de: 'Da Ihre Kontodaten wichtig sind, um alle arbeits- oder abrechnungsbezogenen Probleme zu lösen, bestätigen Sie bitte Folgendes:'
    },
    'I have no outstanding job related issues.': {
        pt: 'Não tenho problemas pendentes relacionados a trabalhos.',
        es: 'No tengo problemas pendientes relacionados con el trabajo.',
        ru: 'У меня нет нерешенных проблем, связанных с работой.',
        fr: 'Je n\'ai aucun problème en suspens lié au travail.',
        de: 'Ich habe keine ausstehenden arbeitsbezogenen Probleme.'
    },
    'I have no outstanding billing related issues.': {
        pt: 'Não tenho problemas pendentes relacionados a faturamento.',
        es: 'No tengo problemas pendientes relacionados con la facturación.',
        ru: 'У меня нет нерешенных проблем, связанных с оплатой.',
        fr: 'Je n\'ai aucun problème en suspens lié à la facturation.',
        de: 'Ich habe keine ausstehenden abrechnungsbezogenen Probleme.'
    },
    'I understand that deleting my account cannot be un-done.': {
        pt: 'Eu entendo que a exclusão da minha conta não pode ser desfeita.',
        es: 'Entiendo que la eliminación de mi cuenta no se puede deshacer.',
        ru: 'Я понимаю, что удаление моего аккаунта нельзя отменить.',
        fr: 'Je comprends que la suppression de mon compte ne peut pas être annulée.',
        de: 'Ich verstehe, dass das Löschen meines Kontos nicht rückgängig gemacht werden kann.'
    },
    'Cancel Account': {
        pt: 'Cancelar Conta',
        es: 'Cancelar Cuenta',
        ru: 'Отменить Аккаунт',
        fr: 'Annuler le Compte',
        de: 'Konto Kündigen'
    },
    'Cancellation Options': {
        pt: 'Opções de Cancelamento',
        es: 'Opciones de Cancelación',
        ru: 'Варианты Отмены',
        fr: 'Options d\'Annulation',
        de: 'Kündigungsoptionen'
    },
    'Cancel All Jobs?': {
        pt: 'Cancelar Todos os Trabalhos?',
        es: '¿Cancelar Todos los Trabajos?',
        ru: 'Отменить Все Работы?',
        fr: 'Annuler Tous les Travaux ?',
        de: 'Alle Aufträge Stornieren?'
    },
    'Are you sure you want to cancel all your current jobs?': {
        pt: 'Tem certeza de que deseja cancelar todos os seus trabalhos atuais?',
        es: '¿Está seguro de que desea cancelar todos sus trabajos actuales?',
        ru: 'Вы уверены, что хотите отменить все ваши текущие работы?',
        fr: 'Êtes-vous sûr de vouloir annuler tous vos travaux en cours ?',
        de: 'Sind Sie sicher, dass Sie alle Ihre aktuellen Aufträge stornieren möchten?'
    },
    'Cancel Account Data?': {
        pt: 'Cancelar Dados da Conta?',
        es: '¿Cancelar Datos de la Cuenta?',
        ru: 'Отменить Данные Аккаунта?',
        fr: 'Annuler les Données du Compte ?',
        de: 'Kontodaten Löschen?'
    },
    'Please confirm above that you want to delete all account data.': {
        pt: 'Por favor, confirme acima que deseja excluir todos os dados da conta.',
        es: 'Por favor, confirme arriba que desea eliminar todos los datos de la cuenta.',
        ru: 'Пожалуйста, подтвердите выше, что вы хотите удалить все данные аккаунта.',
        fr: 'Veuillez confirmer ci-dessus que vous souhaitez supprimer toutes les données du compte.',
        de: 'Bitte bestätigen Sie oben, dass Sie alle Kontodaten löschen möchten.'
    },
    'Cancellation Failed': {
        pt: 'Falha no Cancelamento',
        es: 'Cancelación Fallida',
        ru: 'Ошибка Отмены',
        fr: 'Échec de l\'Annulation',
        de: 'Kündigung Fehlgeschlagen'
    },
    'You can only do this after all pending charges or credits have been resolved. Please give at least 72 hours after your last transaction to try this.': {
        pt: 'Você só pode fazer isso depois que todas as cobranças ou créditos pendentes forem resolvidos. Por favor, aguarde pelo menos 72 horas após sua última transação para tentar isso.',
        es: 'Solo puede hacer esto después de que se hayan resuelto todos los cargos o créditos pendientes. Por favor, espere al menos 72 horas después de su última transacción para intentar esto.',
        ru: 'Вы можете сделать это только после того, как все ожидающие платежи или кредиты будут обработаны. Пожалуйста, подождите не менее 72 часов после вашей последней транзакции, чтобы попробовать это.',
        fr: 'Vous ne pouvez faire cela qu\'après que tous les frais ou crédits en attente ont été résolus. Veuillez attendre au moins 72 heures après votre dernière transaction pour essayer ceci.',
        de: 'Sie können dies erst tun, nachdem alle ausstehenden Belastungen oder Gutschriften abgewickelt wurden. Bitte warten Sie mindestens 72 Stunden nach Ihrer letzten Transaktion, bevor Sie dies versuchen.'
    },

    //CardOnFilePage
    'Card on File': {
        pt: 'Cartão Registrado',
        es: 'Tarjeta Registrada',
        ru: 'Сохраненная Карта',
        fr: 'Carte Enregistrée',
        de: 'Hinterlegte Karte'
    },
    'No cards on file.': {
        pt: 'Nenhum cartão registrado.',
        es: 'No hay tarjetas registradas.',
        ru: 'Нет сохраненных карт.',
        fr: 'Aucune carte enregistrée.',
        de: 'Keine Karten hinterlegt.'
    },
    'Card ending in': {
        pt: 'Cartão terminando em',
        es: 'Tarjeta que termina en',
        ru: 'Карта, оканчивающаяся на',
        fr: 'Carte se terminant par',
        de: 'Karte endend auf'
    },
    '(primary)': {
        pt: '(principal)',
        es: '(principal)',
        ru: '(основная)',
        fr: '(principale)',
        de: '(primär)'
    },
    'Edit Card': {
        pt: 'Editar Cartão',
        es: 'Editar Tarjeta',
        ru: 'Редактировать Карту',
        fr: 'Modifier la Carte',
        de: 'Karte Bearbeiten'
    },
    'Make Card Primary': {
        pt: 'Tornar Cartão Principal',
        es: 'Hacer Tarjeta Principal',
        ru: 'Сделать Карту Основной',
        fr: 'Définir comme Carte Principale',
        de: 'Als Hauptkarte Festlegen'
    },
    'Remove Card': {
        pt: 'Remover Cartão',
        es: 'Eliminar Tarjeta',
        ru: 'Удалить Карту',
        fr: 'Supprimer la Carte',
        de: 'Karte Entfernen'
    },
    'Add Card': {
        pt: 'Adicionar Cartão',
        es: 'Agregar Tarjeta',
        ru: 'Добавить Карту',
        fr: 'Ajouter une Carte',
        de: 'Karte Hinzufügen'
    },
    'Remove card?': {
        pt: 'Remover cartão?',
        es: '¿Eliminar tarjeta?',
        ru: 'Удалить карту?',
        fr: 'Supprimer la carte ?',
        de: 'Karte entfernen?'
    },
    'Make this Card Primary?': {
        pt: 'Tornar este Cartão Principal?',
        es: '¿Hacer esta Tarjeta Principal?',
        ru: 'Сделать эту Карту Основной?',
        fr: 'Définir cette Carte comme Principale ?',
        de: 'Diese Karte zur Hauptkarte machen?'
    },
    'Failed to Make Card Primary': {
        pt: 'Falha ao Tornar Cartão Principal',
        es: 'Error al Hacer la Tarjeta Principal',
        ru: 'Не Удалось Сделать Карту Основной',
        fr: 'Échec de la Définition de la Carte Principale',
        de: 'Fehler beim Festlegen als Hauptkarte'
    },
    'Credit Card Removed': {
        pt: 'Cartão de Crédito Removido',
        es: 'Tarjeta de Crédito Eliminada',
        ru: 'Кредитная Карта Удалена',
        fr: 'Carte de Crédit Supprimée',
        de: 'Kreditkarte Entfernt'
    },
    'Failed to Remove Credit Card': {
        pt: 'Falha ao Remover Cartão de Crédito',
        es: 'Error al Eliminar la Tarjeta de Crédito',
        ru: 'Не Удалось Удалить Кредитную Карту',
        fr: 'Échec de la Suppression de la Carte de Crédit',
        de: 'Fehler beim Entfernen der Kreditkarte'
    },

    //CardEntryPage
    'How Payment Works': {
        pt: 'Como Funciona o Pagamento',
        es: 'Cómo Funciona el Pago',
        ru: 'Как Работает Оплата',
        fr: 'Comment Fonctionne le Paiement',
        de: 'Wie die Zahlung Funktioniert'
    },
    'TIDY requires a card on file to enable automatic booking. The card can be used for your subscription or to pay pros you book. Questions?': {
        pt: 'TIDY requer um cartão registrado para ativar a reserva automática. O cartão pode ser usado para sua assinatura ou para pagar profissionais que você reserva. Dúvidas?',
        es: 'TIDY requiere una tarjeta registrada para habilitar la reserva automática. La tarjeta se puede usar para su suscripción o para pagar a los profesionales que contrate. ¿Preguntas?',
        ru: 'TIDY требует карту в файле для включения автоматического бронирования. Карта может использоваться для вашей подписки или для оплаты профессионалов, которых вы бронируете. Вопросы?',
        fr: 'TIDY nécessite une carte enregistrée pour activer la réservation automatique. La carte peut être utilisée pour votre abonnement ou pour payer les professionnels que vous réservez. Des questions ?',
        de: 'TIDY benötigt eine hinterlegte Karte, um automatische Buchungen zu ermöglichen. Die Karte kann für Ihr Abonnement oder zur Bezahlung von gebuchten Profis verwendet werden. Fragen?'
    },
    'Contact our Concierge': {
        pt: 'Contate nosso Concierge',
        es: 'Contacte a nuestro Concierge',
        ru: 'Свяжитесь с нашим Консьержем',
        fr: 'Contactez notre Concierge',
        de: 'Kontaktieren Sie unseren Concierge'
    },
    'Schedule a Call': {
        pt: 'Agendar uma Chamada',
        es: 'Programar una Llamada',
        ru: 'Запланировать Звонок',
        fr: 'Planifier un Appel',
        de: 'Einen Anruf Planen'
    },
    'EditEnter Card Information': {
        pt: 'EditarInserir Informações do Cartão',
        es: 'EditarIngresar Información de la Tarjeta',
        ru: 'РедактироватьВвести Информацию о Карте',
        fr: 'ModifierEntrer les Informations de la Carte',
        de: 'BearbeitenKarteninformationen Eingeben'
    },
    'Make this my primary card': {
        pt: 'Tornar este meu cartão principal',
        es: 'Hacer esta mi tarjeta principal',
        ru: 'Сделать эту карту основной',
        fr: 'Définir comme ma carte principale',
        de: 'Diese Karte als Hauptkarte festlegen'
    },
    'Credit Card Edited': {
        pt: 'Cartão de Crédito Editado',
        es: 'Tarjeta de Crédito Editada',
        ru: 'Кредитная Карта Отредактирована',
        fr: 'Carte de Crédit Modifiée',
        de: 'Kreditkarte Bearbeitet'
    },
    'Credit Card Added': {
        pt: 'Cartão de Crédito Adicionado',
        es: 'Tarjeta de Crédito Agregada',
        ru: 'Кредитная Карта Добавлена',
        fr: 'Carte de Crédit Ajoutée',
        de: 'Kreditkarte Hinzugefügt'
    },
    'You\'ve enabled automatic booking and added a credit card. We will now send job requests to your Pro priority list as new events are synced. You can add any existing Pro to your Pro priority list if you want them to accept jobs (working with existing Pros is free).': {
        pt: 'Você ativou a reserva automática e adicionou um cartão de crédito. Agora enviaremos solicitações de trabalho para sua lista de prioridades de Profissionais à medida que novos eventos forem sincronizados. Você pode adicionar qualquer Profissional existente à sua lista de prioridades de Profissionais se quiser que eles aceitem trabalhos (trabalhar com Profissionais existentes é gratuito).',
        es: 'Ha habilitado la reserva automática y agregado una tarjeta de crédito. Ahora enviaremos solicitudes de trabajo a su lista de prioridades de Profesionales a medida que se sincronicen nuevos eventos. Puede agregar cualquier Profesional existente a su lista de prioridades de Profesionales si desea que acepten trabajos (trabajar con Profesionales existentes es gratis).',
        ru: 'Вы включили автоматическое бронирование и добавили кредитную карту. Теперь мы будем отправлять запросы на работу в ваш список приоритетных Профессионалов по мере синхронизации новых событий. Вы можете добавить любого существующего Профессионала в свой список приоритетных Профессионалов, если хотите, чтобы они принимали заказы (работа с существующими Профессионалами бесплатна).',
        fr: 'Vous avez activé la réservation automatique et ajouté une carte de crédit. Nous enverrons désormais des demandes de travail à votre liste de priorité de Professionnels au fur et à mesure que de nouveaux événements seront synchronisés. Vous pouvez ajouter n\'importe quel Professionnel existant à votre liste de priorité de Professionnels si vous voulez qu\'ils acceptent des travaux (travailler avec des Professionnels existants est gratuit).',
        de: 'Sie haben die automatische Buchung aktiviert und eine Kreditkarte hinzugefügt. Wir senden nun Jobanfragen an Ihre Pro-Prioritätsliste, sobald neue Ereignisse synchronisiert werden. Sie können jeden bestehenden Pro zu Ihrer Pro-Prioritätsliste hinzufügen, wenn Sie möchten, dass sie Jobs annehmen (die Arbeit mit bestehenden Pros ist kostenlos).'
    },
    'Your credit card was declined. Please double-check and try again.': {
        pt: 'Seu cartão de crédito foi recusado. Por favor, verifique e tente novamente.',
        es: 'Su tarjeta de crédito fue rechazada. Por favor, verifique e intente nuevamente.',
        ru: 'Ваша кредитная карта была отклонена. Пожалуйста, проверьте и попробуйте снова.',
        fr: 'Votre carte de crédit a été refusée. Veuillez vérifier et réessayer.',
        de: 'Ihre Kreditkarte wurde abgelehnt. Bitte überprüfen Sie die Angaben und versuchen Sie es erneut.'
    },

    //ContactInfoPage
    'Company Name': {
        pt: 'Nome da Empresa',
        es: 'Nombre de la Empresa',
        ru: 'Название Компании',
        fr: 'Nom de l\'Entreprise',
        de: 'Firmenname'
    },
    'Contact Info Saved': {
        pt: 'Informações de Contato Salvas',
        es: 'Información de Contacto Guardada',
        ru: 'Контактная Информация Сохранена',
        fr: 'Informations de Contact Enregistrées',
        de: 'Kontaktinformationen Gespeichert'
    },

    //DevelopersPage
    'Developers': {
        pt: 'Desenvolvedores',
        es: 'Desarrolladores',
        ru: 'Разработчики',
        fr: 'Développeurs',
        de: 'Entwickler'
    },
    'Use the TIDY API with your application.': {
        pt: 'Use a API TIDY com sua aplicação.',
        es: 'Use la API de TIDY con su aplicación.',
        ru: 'Используйте API TIDY в вашем приложении.',
        fr: 'Utilisez l\'API TIDY avec votre application.',
        de: 'Verwenden Sie die TIDY-API mit Ihrer Anwendung.'
    },
    'Documentation': {
        pt: 'Documentação',
        es: 'Documentación',
        ru: 'Документация',
        fr: 'Documentation',
        de: 'Dokumentation'
    },
    'Logs': {
        pt: 'Registros',
        es: 'Registros',
        ru: 'Журналы',
        fr: 'Journaux',
        de: 'Protokolle'
    },
    'Need Help?': {
        pt: 'Precisa de Ajuda?',
        es: '¿Necesita Ayuda?',
        ru: 'Нужна Помощь?',
        fr: 'Besoin d\'Aide ?',
        de: 'Brauchen Sie Hilfe?'
    },
    'Schedule a Developer Support Call': {
        pt: 'Agende uma Chamada de Suporte ao Desenvolvedor',
        es: 'Programe una Llamada de Soporte para Desarrolladores',
        ru: 'Запланировать Звонок Поддержки Разработчиков',
        fr: 'Planifier un Appel de Support Développeur',
        de: 'Planen Sie einen Entwickler-Support-Anruf'
    },
    'Webhooks': {
        pt: 'Webhooks',
        es: 'Webhooks',
        ru: 'Вебхуки',
        fr: 'Webhooks',
        de: 'Webhooks'
    },
    'Send Us an Email': {
        pt: 'Envie-nos um E-mail',
        es: 'Envíenos un Correo Electrónico',
        ru: 'Отправьте Нам Электронное Письмо',
        fr: 'Envoyez-nous un E-mail',
        de: 'Senden Sie uns eine E-Mail'
    },
    'Check Out Other Features': {
        pt: 'Confira Outros Recursos',
        es: 'Revise Otras Características',
        ru: 'Ознакомьтесь с Другими Функциями',
        fr: 'Découvrez Autres Fonctionnalités',
        de: 'Sehen Sie Sich Andere Funktionen An'
    },
    'Book a Job Instantly': {
        pt: 'Reserve um Trabalho Instantaneamente',
        es: 'Reserve un Trabajo Instantáneamente',
        ru: 'Мгновенно Забронировать Работу',
        fr: 'Réservez un Travail Instantanément',
        de: 'Buchen Sie Sofort einen Auftrag'
    },
    'Add a Pro': {
        pt: 'Adicionar um Profissional',
        es: 'Agregar un Profesional',
        ru: 'Добавить Профессионала',
        fr: 'Ajouter un Pro',
        de: 'Einen Profi Hinzufügen'
    },
    'Enable Developer Features': {
        pt: 'Ativar Recursos de Desenvolvedor',
        es: 'Habilitar Funciones de Desarrollador',
        ru: 'Включить Функции Разработчика',
        fr: 'Activer les Fonctionnalités Développeur',
        de: 'Entwicklerfunktionen Aktivieren'
    },
    'Free Cleaning & Home Service API': {
        pt: 'API Gratuita de Limpeza e Serviços Domésticos',
        es: 'API Gratuita de Limpieza y Servicios para el Hogar',
        ru: 'Бесплатный API для Уборки и Домашних Услуг',
        fr: 'API Gratuite de Nettoyage et Services à Domicile',
        de: 'Kostenlose API für Reinigung und Haushaltsservice'
    },
    'TIDY makes it easy to book cleaning or maintenance pros via our API, then manage or track those jobs. Use TIDY with your existing pros or we can help you find one through our network. The API is for internal use (e.g. property managers) or for end users of your product (e.g. property management software). Most people use it for free!': {
        pt: 'O TIDY facilita a reserva de profissionais de limpeza ou manutenção através da nossa API, e depois gerencia ou rastreia esses trabalhos. Use a TIDY com seus profissionais existentes ou podemos ajudá-lo a encontrar um através da nossa rede. A API é para uso interno (por exemplo, gerentes de propriedades) ou para usuários finais do seu produto (por exemplo, software de gerenciamento de propriedades). A maioria das pessoas usa gratuitamente!',
        es: 'TIDY facilita la reserva de profesionales de limpieza o mantenimiento a través de nuestra API, luego administra o rastrea esos trabajos. Use TIDY con sus profesionales existentes o podemos ayudarlo a encontrar uno a través de nuestra red. La API es para uso interno (por ejemplo, administradores de propiedades) o para usuarios finales de su producto (por ejemplo, software de administración de propiedades). ¡La mayoría de las personas la usan gratis!',
        ru: 'TIDY упрощает бронирование специалистов по уборке или обслуживанию через наш API, а затем управляет или отслеживает эти работы. Используйте TIDY с вашими существующими специалистами, или мы можем помочь вам найти их через нашу сеть. API предназначен для внутреннего использования (например, управляющими недвижимостью) или для конечных пользователей вашего продукта (например, программного обеспечения для управления недвижимостью). Большинство людей используют его бесплатно!',
        fr: 'TIDY facilite la réservation de professionnels du nettoyage ou de l\'entretien via notre API, puis gère ou suit ces travaux. Utilisez TIDY avec vos professionnels existants ou nous pouvons vous aider à en trouver un via notre réseau. L\'API est destinée à un usage interne (par exemple, les gestionnaires immobiliers) ou aux utilisateurs finaux de votre produit (par exemple, un logiciel de gestion immobilière). La plupart des gens l\'utilisent gratuitement !',
        de: 'TIDY macht es einfach, Reinigungs- oder Wartungsprofis über unsere API zu buchen und diese Aufträge dann zu verwalten oder zu verfolgen. Verwenden Sie TIDY mit Ihren bestehenden Profis oder wir können Ihnen helfen, einen über unser Netzwerk zu finden. Die API ist für den internen Gebrauch (z. B. Immobilienverwalter) oder für Endbenutzer Ihres Produkts (z. B. Immobilienverwaltungssoftware) gedacht. Die meisten Leute nutzen es kostenlos!'
    },
    'Request Bookings With Any Pro': {
        pt: 'Solicite Reservas Com Qualquer Profissional',
        es: 'Solicite Reservas Con Cualquier Profesional',
        ru: 'Запросите Бронирование у Любого Профессионала',
        fr: 'Demandez des Réservations Avec N\'importe Quel Pro',
        de: 'Anfragen für Buchungen Bei Jedem Profi'
    },
    'A pro from our network or any pro you add. Define your job request and send to your priority list. Your top priority pro will get the first chance to accept, followed by your next priority and so on.': {
        pt: 'Um profissional da nossa rede ou qualquer profissional que você adicionar. Defina sua solicitação de trabalho e envie para sua lista de prioridades. Seu profissional de maior prioridade terá a primeira chance de aceitar, seguido pelo próximo da prioridade e assim por diante.',
        es: 'Un profesional de nuestra red o cualquier profesional que agregue. Defina su solicitud de trabajo y envíela a su lista de prioridades. Su profesional de mayor prioridad tendrá la primera oportunidad de aceptar, seguido por su siguiente prioridad y así sucesivamente.',
        ru: 'Профессионал из нашей сети или любой профессионал, которого вы добавите. Определите свой запрос на работу и отправьте в свой список приоритетов. Ваш профессионал с наивысшим приоритетом получит первый шанс принять заказ, затем следующий по приоритету и так далее.',
        fr: 'Un pro de notre réseau ou tout pro que vous ajoutez. Définissez votre demande de travail et envoyez-la à votre liste de priorités. Votre pro de plus haute priorité aura la première chance d\'accepter, suivi de votre prochain pro prioritaire et ainsi de suite.',
        de: 'Ein Profi aus unserem Netzwerk oder jeder Profi, den Sie hinzufügen. Definieren Sie Ihre Jobanfrage und senden Sie sie an Ihre Prioritätsliste. Ihr Profi mit der höchsten Priorität erhält die erste Chance zur Annahme, gefolgt von Ihrem nächsten Prioritätsprofi und so weiter.'
    },
    'Customize the Job with To-Do Lists': {
        pt: 'Personalize o Trabalho com Listas de Tarefas',
        es: 'Personalice el Trabajo con Listas de Tareas',
        ru: 'Настройте Работу с Помощью Списков Задач',
        fr: 'Personnalisez le Travail avec des Listes de Tâches',
        de: 'Passen Sie den Auftrag mit To-Do-Listen An'
    },
    'Use the API to assign To-Do Lists to your jobs.': {
        pt: 'Use a API para atribuir Listas de Tarefas aos seus trabalhos.',
        es: 'Use la API para asignar Listas de Tareas a sus trabajos.',
        ru: 'Используйте API для назначения Списков Задач вашим работам.',
        fr: 'Utilisez l\'API pour attribuer des Listes de Tâches à vos travaux.',
        de: 'Verwenden Sie die API, um Ihren Aufträgen To-Do-Listen zuzuweisen.'
    },
    'Get Job Updates with Webhooks': {
        pt: 'Obtenha Atualizações de Trabalho com Webhooks',
        es: 'Obtenga Actualizaciones de Trabajo con Webhooks',
        ru: 'Получайте Обновления о Работе с Помощью Вебхуков',
        fr: 'Obtenez des Mises à Jour de Travail avec des Webhooks',
        de: 'Erhalten Sie Auftragsaktualisierungen mit Webhooks'
    },
    'Use webhooks to get alerts of things like jobs being scheduled, accepted, or completed.': {
        pt: 'Use webhooks para receber alertas de coisas como trabalhos sendo agendados, aceitos ou concluídos.',
        es: 'Use webhooks para recibir alertas de cosas como trabajos programados, aceptados o completados.',
        ru: 'Используйте вебхуки для получения оповещений о таких событиях, как планирование, принятие или завершение работ.',
        fr: 'Utilisez des webhooks pour recevoir des alertes sur des choses comme des travaux planifiés, acceptés ou terminés.',
        de: 'Verwenden Sie Webhooks, um Benachrichtigungen über Dinge wie geplante, akzeptierte oder abgeschlossene Aufträge zu erhalten.'
    },
    'Developer Features Enabled': {
        pt: 'Recursos de Desenvolvedor Ativados',
        es: 'Funciones de Desarrollador Habilitadas',
        ru: 'Функции Разработчика Включены',
        fr: 'Fonctionnalités Développeur Activées',
        de: 'Entwicklerfunktionen Aktiviert'
    },

    //EditAddressPage
    'Edit Property Details': {
        pt: 'Editar Detalhes da Propriedade',
        es: 'Editar Detalles de la Propiedad',
        ru: 'Редактировать Детали Недвижимости',
        fr: 'Modifier les Détails de la Propriété',
        de: 'Immobiliendetails Bearbeiten'
    },
    'Map Building In Progress': {
        pt: 'Construção do Mapa em Andamento',
        es: 'Construcción del Mapa en Progreso',
        ru: 'Создание Карты в Процессе',
        fr: 'Construction de la Carte en Cours',
        de: 'Kartenerstellung in Bearbeitung'
    },
    'This can take 72 hours or more, thanks for your patience.': {
        pt: 'Isso pode levar 72 horas ou mais, obrigado pela sua paciência.',
        es: 'Esto puede tardar 72 horas o más, gracias por su paciencia.',
        ru: 'Это может занять 72 часа или более, спасибо за ваше терпение.',
        fr: 'Cela peut prendre 72 heures ou plus, merci pour votre patience.',
        de: 'Dies kann 72 Stunden oder länger dauern, danke für Ihre Geduld.'
    },
    'Cancel Request': {
        pt: 'Cancelar Solicitação',
        es: 'Cancelar Solicitud',
        ru: 'Отменить Запрос',
        fr: 'Annuler la Demande',
        de: 'Anfrage Abbrechen'
    },
    'Action Needed for Map Request': {
        pt: 'Ação Necessária para Solicitação de Mapa',
        es: 'Acción Necesaria para Solicitud de Mapa',
        ru: 'Требуется Действие для Запроса Карты',
        fr: 'Action Nécessaire pour la Demande de Carte',
        de: 'Aktion für Kartenanfrage Erforderlich'
    },
    'Review your lot outline and video information': {
        pt: 'Revise o contorno do seu lote e as informações do vídeo',
        es: 'Revise el contorno de su lote y la información del video',
        ru: 'Просмотрите контур вашего участка и информацию о видео',
        fr: 'Examinez le contour de votre terrain et les informations vidéo',
        de: 'Überprüfen Sie Ihren Grundstücksumriss und die Videoinformationen'
    },
    'Redo Map Request': {
        pt: 'Refazer Solicitação de Mapa',
        es: 'Rehacer Solicitud de Mapa',
        ru: 'Повторить Запрос Карты',
        fr: 'Refaire la Demande de Carte',
        de: 'Kartenanfrage Wiederholen'
    },
    'Property Map': {
        pt: 'Mapa da Propriedade',
        es: 'Mapa de la Propiedad',
        ru: 'Карта Недвижимости',
        fr: 'Carte de la Propriété',
        de: 'Immobilienkarte'
    },
    'Get a Map & Maintenance Plan': {
        pt: 'Obter um Mapa e Plano de Manutenção',
        es: 'Obtener un Mapa y Plan de Mantenimiento',
        ru: 'Получить Карту и План Обслуживания',
        fr: 'Obtenir une Carte et un Plan de Maintenance',
        de: 'Karte & Wartungsplan Erhalten'
    },
    'Map & Maintenance Plan Requested': {
        pt: 'Mapa e Plano de Manutenção Solicitados',
        es: 'Mapa y Plan de Mantenimiento Solicitados',
        ru: 'Запрошены Карта и План Обслуживания',
        fr: 'Carte et Plan de Maintenance Demandés',
        de: 'Karte & Wartungsplan Angefordert'
    },
    'It\'s easy, useful, and fun to have a great sharable map of your property.': {
        pt: 'É fácil, útil e divertido ter um ótimo mapa compartilhável da sua propriedade.',
        es: 'Es fácil, útil y divertido tener un gran mapa compartible de su propiedad.',
        ru: 'Легко, полезно и весело иметь отличную карту вашей недвижимости, которой можно поделиться.',
        fr: 'C\'est facile, utile et amusant d\'avoir une excellente carte partageable de votre propriété.',
        de: 'Es ist einfach, nützlich und macht Spaß, eine großartige teilbare Karte Ihrer Immobilie zu haben.'
    },
    'Request Feature': {
        pt: 'Solicitar Recurso',
        es: 'Solicitar Función',
        ru: 'Запросить Функцию',
        fr: 'Demander une Fonctionnalité',
        de: 'Funktion Anfordern'
    },
    'Our Concierge will reach out to help.': {
        pt: 'Nosso Concierge entrará em contato para ajudar.',
        es: 'Nuestro Concierge se pondrá en contacto para ayudar.',
        ru: 'Наш Консьерж свяжется с вами, чтобы помочь.',
        fr: 'Notre Concierge vous contactera pour vous aider.',
        de: 'Unser Concierge wird sich mit Ihnen in Verbindung setzen, um zu helfen.'
    },
    'Scheduled Jobs': {
        pt: 'Trabalhos Agendados',
        es: 'Trabajos Programados',
        ru: 'Запланированные Работы',
        fr: 'Travaux Programmés',
        de: 'Geplante Aufträge'
    },
    'No Jobs Scheduled': {
        pt: 'Nenhum Trabalho Agendado',
        es: 'No Hay Trabajos Programados',
        ru: 'Нет Запланированных Работ',
        fr: 'Aucun Travail Programmé',
        de: 'Keine Aufträge Geplant'
    },
    'Past Jobs': {
        pt: 'Trabalhos Anteriores',
        es: 'Trabajos Pasados',
        ru: 'Прошлые Работы',
        fr: 'Travaux Passés',
        de: 'Vergangene Aufträge'
    },
    'No Past Jobs': {
        pt: 'Nenhum Trabalho Anterior',
        es: 'No Hay Trabajos Pasados',
        ru: 'Нет Прошлых Работ',
        fr: 'Aucun Travail Passé',
        de: 'Keine Vergangenen Aufträge'
    },
    'Logged Issues': {
        pt: 'Problemas Registrados',
        es: 'Problemas Registrados',
        ru: 'Зарегистрированные Проблемы',
        fr: 'Problèmes Enregistrés',
        de: 'Protokollierte Probleme'
    },
    'unresolved': {
        pt: 'não resolvidos',
        es: 'sin resolver',
        ru: 'нерешенные',
        fr: 'non résolus',
        de: 'ungelöst'
    },
    'No bills found': {
        pt: 'Nenhuma conta encontrada',
        es: 'No se encontraron facturas',
        ru: 'Счета не найдены',
        fr: 'Aucune facture trouvée',
        de: 'Keine Rechnungen gefunden'
    },
    'View All': {
        pt: 'Ver Tudo',
        es: 'Ver Todo',
        ru: 'Посмотреть Все',
        fr: 'Voir Tout',
        de: 'Alle Anzeigen'
    },
    'To-Do Lists': {
        pt: 'Listas de Tarefas',
        es: 'Listas de Tareas',
        ru: 'Списки Дел',
        fr: 'Listes de Tâches',
        de: 'To-Do-Listen'
    },
    'No To-Do Lists found': {
        pt: 'Nenhuma Lista de Tarefas encontrada',
        es: 'No se encontraron Listas de Tareas',
        ru: 'Списки Дел не найдены',
        fr: 'Aucune Liste de Tâches trouvée',
        de: 'Keine To-Do-Listen gefunden'
    },
    'Access Notes': {
        pt: 'Notas de Acesso',
        es: 'Notas de Acceso',
        ru: 'Заметки о Доступе',
        fr: 'Notes d\'Accès',
        de: 'Zugangshinweise'
    },
    'How to park:': {
        pt: 'Como estacionar:',
        es: 'Cómo estacionar:',
        ru: 'Как припарковаться:',
        fr: 'Comment se garer :',
        de: 'Wie man parkt:'
    },
    'Paid parking': {
        pt: 'Estacionamento pago',
        es: 'Estacionamiento de pago',
        ru: 'Платная парковка',
        fr: 'Parking payant',
        de: 'Kostenpflichtiges Parken'
    },
    'Free parking': {
        pt: 'Estacionamento gratuito',
        es: 'Estacionamiento gratuito',
        ru: 'Бесплатная парковка',
        fr: 'Parking gratuit',
        de: 'Kostenloses Parken'
    },
    'No parking notes added.': {
        pt: 'Nenhuma nota de estacionamento adicionada.',
        es: 'No se han agregado notas de estacionamiento.',
        ru: 'Заметки о парковке не добавлены.',
        fr: 'Aucune note de stationnement ajoutée.',
        de: 'Keine Parkhinweise hinzugefügt.'
    },
    'How to get in:': {
        pt: 'Como entrar:',
        es: 'Cómo entrar:',
        ru: 'Как войти:',
        fr: 'Comment entrer :',
        de: 'Wie man hineinkommt:'
    },
    'No access notes added.': {
        pt: 'Nenhuma nota de acesso adicionada.',
        es: 'No se han agregado notas de acceso.',
        ru: 'Заметки о доступе не добавлены.',
        fr: 'Aucune note d\'accès ajoutée.',
        de: 'Keine Zugangshinweise hinzugefügt.'
    },
    'How to close up:': {
        pt: 'Como fechar:',
        es: 'Cómo cerrar:',
        ru: 'Как закрыть:',
        fr: 'Comment fermer :',
        de: 'Wie man abschließt:'
    },
    'No closing notes added.': {
        pt: 'Nenhuma nota de fechamento adicionada.',
        es: 'No se han agregado notas de cierre.',
        ru: 'Заметки о закрытии не добавлены.',
        fr: 'Aucune note de fermeture ajoutée.',
        de: 'Keine Abschlusshinweise hinzugefügt.'
    },
    'Smart Lock Integrated': {
        pt: 'Fechadura Inteligente Integrada',
        es: 'Cerradura Inteligente Integrada',
        ru: 'Умный замок интегрирован',
        fr: 'Serrure Intelligente Intégrée',
        de: 'Intelligentes Schloss Integriert'
    },
    'The RemoteLock code will be available for the pro the day of the job.': {
        pt: 'O código RemoteLock estará disponível para o profissional no dia do trabalho.',
        es: 'El código RemoteLock estará disponible para el profesional el día del trabajo.',
        ru: 'Код RemoteLock будет доступен для специалиста в день работы.',
        fr: 'Le code RemoteLock sera disponible pour le professionnel le jour du travail.',
        de: 'Der RemoteLock-Code wird für den Profi am Tag des Auftrags verfügbar sein.'
    },
    'Lock Integrations': {
        pt: 'Integrações de Fechadura',
        es: 'Integraciones de Cerradura',
        ru: 'Интеграции замков',
        fr: 'Intégrations de Serrure',
        de: 'Schloss-Integrationen'
    },
    'Get Started': {
        pt: 'Começar',
        es: 'Empezar',
        ru: 'Начать',
        fr: 'Commencer',
        de: 'Loslegen'
    },
    'RemoteLock Integration': {
        pt: 'Integração RemoteLock',
        es: 'Integración RemoteLock',
        ru: 'Интеграция RemoteLock',
        fr: 'Intégration RemoteLock',
        de: 'RemoteLock-Integration'
    },
    'Smart Thermostat Integration': {
        pt: 'Integração de Termostato Inteligente',
        es: 'Integración de Termostato Inteligente',
        ru: 'Интеграция умного термостата',
        fr: 'Intégration de Thermostat Intelligent',
        de: 'Integration Intelligenter Thermostat'
    },
    'Rooms': {
        pt: 'Quartos',
        es: 'Habitaciones',
        ru: 'Комнаты',
        fr: 'Pièces',
        de: 'Räume'
    },
    'Edit Rooms': {
        pt: 'Editar Quartos',
        es: 'Editar Habitaciones',
        ru: 'Редактировать комнаты',
        fr: 'Modifier les Pièces',
        de: 'Räume Bearbeiten'
    },
    'No rooms added.': {
        pt: 'Nenhum quarto adicionado.',
        es: 'No se han añadido habitaciones.',
        ru: 'Комнаты не добавлены.',
        fr: 'Aucune pièce ajoutée.',
        de: 'Keine Räume hinzugefügt.'
    },
    'Add Room': {
        pt: 'Adicionar Quarto',
        es: 'Añadir Habitación',
        ru: 'Добавить комнату',
        fr: 'Ajouter une Pièce',
        de: 'Raum Hinzufügen'
    },
    'Items': {
        pt: 'Itens',
        es: 'Artículos',
        ru: 'Предметы',
        fr: 'Articles',
        de: 'Gegenstände'
    },
    'No Items Added': {
        pt: 'Nenhum Item Adicionado',
        es: 'No se han Añadido Artículos',
        ru: 'Предметы не добавлены',
        fr: 'Aucun Article Ajouté',
        de: 'Keine Gegenstände Hinzugefügt'
    },
    'at a meter': {
        pt: 'no parquímetro',
        es: 'en un parquímetro',
        ru: 'на счетчике',
        fr: 'au parcomètre',
        de: 'an einem Parkometer'
    },
    'in a paid lot': {
        pt: 'em um estacionamento pago',
        es: 'en un estacionamiento de pago',
        ru: 'на платной стоянке',
        fr: 'dans un parking payant',
        de: 'auf einem kostenpflichtigen Parkplatz'
    },
    'on the street': {
        pt: 'na rua',
        es: 'en la calle',
        ru: 'на улице',
        fr: 'dans la rue',
        de: 'auf der Straße'
    },
    'in my spot/driveway': {
        pt: 'na minha vaga/garagem',
        es: 'en mi lugar/entrada',
        ru: 'на моем месте/подъездной дорожке',
        fr: 'sur ma place/allée',
        de: 'auf meinem Platz/in meiner Einfahrt'
    },
    'in guest parking': {
        pt: 'no estacionamento para visitantes',
        es: 'en el estacionamiento para invitados',
        ru: 'на гостевой парковке',
        fr: 'dans le parking visiteur',
        de: 'auf dem Gästeparkplatz'
    },
    'Map': {
        pt: 'Mapa',
        es: 'Mapa',
        ru: 'Карта',
        fr: 'Carte',
        de: 'Karte'
    },
    'RemoteLock Integrations': {
        pt: 'Integrações RemoteLock',
        es: 'Integraciones RemoteLock',
        ru: 'Интеграции RemoteLock',
        fr: 'Intégrations RemoteLock',
        de: 'RemoteLock-Integrationen'
    },
    'TIDY Concierge will reach out to help set these up with you.': {
        pt: 'O Concierge TIDY entrará em contato para ajudar a configurar isso com você.',
        es: 'El Concierge de TIDY se pondrá en contacto para ayudarle a configurar esto con usted.',
        ru: 'Консьерж TIDY свяжется с вами, чтобы помочь настроить это.',
        fr: 'Le Concierge TIDY vous contactera pour vous aider à configurer cela avec vous.',
        de: 'Der TIDY-Concierge wird sich mit Ihnen in Verbindung setzen, um Ihnen bei der Einrichtung zu helfen.'
    },
    'Cancel Map Request?': {
        pt: 'Cancelar Solicitação de Mapa?',
        es: '¿Cancelar Solicitud de Mapa?',
        ru: 'Отменить запрос карты?',
        fr: 'Annuler la Demande de Carte ?',
        de: 'Kartenanfrage Stornieren?'
    },
    'Do you wish to cancel your current map request? You\'ll still be able to create a new one later': {
        pt: 'Deseja cancelar sua solicitação de mapa atual? Você ainda poderá criar uma nova mais tarde',
        es: '¿Desea cancelar su solicitud de mapa actual? Aún podrá crear una nueva más adelante',
        ru: 'Вы хотите отменить текущий запрос карты? Вы все еще сможете создать новый позже',
        fr: 'Souhaitez-vous annuler votre demande de carte actuelle ? Vous pourrez toujours en créer une nouvelle plus tard',
        de: 'Möchten Sie Ihre aktuelle Kartenanfrage stornieren? Sie können später immer noch eine neue erstellen'
    },
    'Map Request Canceled': {
        pt: 'Solicitação de Mapa Cancelada',
        es: 'Solicitud de Mapa Cancelada',
        ru: 'Запрос карты отменен',
        fr: 'Demande de Carte Annulée',
        de: 'Kartenanfrage Storniert'
    },

    //AddRoomItemPage
    'Have Concierge Add For Me': {
        pt: 'Peça ao Concierge para Adicionar por Mim',
        es: 'Que el Concierge lo Agregue por Mí',
        ru: 'Попросить Консьержа Добавить за Меня',
        fr: 'Demander au Concierge d\'Ajouter pour Moi',
        de: 'Concierge für mich Hinzufügen Lassen'
    },
    'We typically get this done in 1 business day, and email you back!': {
        pt: 'Normalmente fazemos isso em 1 dia útil e enviamos um e-mail de volta!',
        es: '¡Normalmente lo hacemos en 1 día hábil y le enviamos un correo electrónico de vuelta!',
        ru: 'Обычно мы выполняем это за 1 рабочий день и отправляем вам ответ по электронной почте!',
        fr: 'Nous le faisons généralement en 1 jour ouvrable et vous envoyons un e-mail en retour !',
        de: 'Wir erledigen dies normalerweise innerhalb von 1 Werktag und melden uns per E-Mail bei Ihnen zurück!'
    },
    'Get Map & Maintenance Plan': {
        pt: 'Obter Mapa e Plano de Manutenção',
        es: 'Obtener Mapa y Plan de Mantenimiento',
        ru: 'Получить Карту и План Обслуживания',
        fr: 'Obtenir une Carte et un Plan d\'Entretien',
        de: 'Karte & Wartungsplan Erhalten'
    },
    'Instead of adding a single item to your home, you can scan your property and we will generate a fun map of your property and personalized maintenance plan, for free!': {
        pt: 'Em vez de adicionar um único item à sua casa, você pode escanear sua propriedade e nós geraremos um mapa divertido da sua propriedade e um plano de manutenção personalizado, gratuitamente!',
        es: 'En lugar de agregar un solo artículo a su hogar, puede escanear su propiedad y generaremos un mapa divertido de su propiedad y un plan de mantenimiento personalizado, ¡gratis!',
        ru: 'Вместо добавления одного предмета в ваш дом, вы можете отсканировать вашу собственность, и мы бесплатно создадим забавную карту вашей собственности и персонализированный план обслуживания!',
        fr: 'Au lieu d\'ajouter un seul article à votre maison, vous pouvez scanner votre propriété et nous générerons gratuitement une carte amusante de votre propriété et un plan d\'entretien personnalisé !',
        de: 'Anstatt einen einzelnen Artikel zu Ihrem Zuhause hinzuzufügen, können Sie Ihr Eigentum scannen und wir erstellen kostenlos eine lustige Karte Ihres Eigentums und einen personalisierten Wartungsplan!'
    },
    'Create a Sharable Map': {
        pt: 'Criar um Mapa Compartilhável',
        es: 'Crear un Mapa Compartible',
        ru: 'Создать Карту для Обмена',
        fr: 'Créer une Carte Partageable',
        de: 'Teilbare Karte Erstellen'
    },
    'Your map will be with your to-do list to help your pro visually get the job done right.': {
        pt: 'Seu mapa estará com sua lista de tarefas para ajudar seu profissional a realizar o trabalho corretamente de forma visual.',
        es: 'Su mapa estará con su lista de tareas para ayudar a su profesional a realizar el trabajo correctamente de manera visual.',
        ru: 'Ваша карта будет с вашим списком дел, чтобы помочь вашему специалисту визуально выполнить работу правильно.',
        fr: 'Votre carte sera accompagnée de votre liste de tâches pour aider votre professionnel à bien faire le travail visuellement.',
        de: 'Ihre Karte wird mit Ihrer To-Do-Liste sein, um Ihrem Profi visuell zu helfen, die Arbeit richtig zu erledigen.'
    },
    'Scan Your Property': {
        pt: 'Escanear Sua Propriedade',
        es: 'Escanear Su Propiedad',
        ru: 'Сканировать Вашу Собственность',
        fr: 'Scanner Votre Propriété',
        de: 'Ihr Eigentum Scannen'
    },
    'Select the item to add:': {
        pt: 'Selecione o item para adicionar:',
        es: 'Seleccione el artículo para agregar:',
        ru: 'Выберите предмет для добавления:',
        fr: 'Sélectionnez l\'article à ajouter :',
        de: 'Wählen Sie den Artikel zum Hinzufügen:'
    },
    'Name or Model #': {
        pt: 'Nome ou Modelo #',
        es: 'Nombre o Modelo #',
        ru: 'Название или Модель #',
        fr: 'Nom ou Modèle #',
        de: 'Name oder Modell #'
    },
    'Model Number': {
        pt: 'Número do Modelo',
        es: 'Número de Modelo',
        ru: 'Номер Модели',
        fr: 'Numéro de Modèle',
        de: 'Modellnummer'
    },
    'Please enter the number of the new model.': {
        pt: 'Por favor, insira o número do novo modelo.',
        es: 'Por favor, ingrese el número del nuevo modelo.',
        ru: 'Пожалуйста, введите номер новой модели.',
        fr: 'Veuillez entrer le numéro du nouveau modèle.',
        de: 'Bitte geben Sie die Nummer des neuen Modells ein.'
    },
    'Year Installed': {
        pt: 'Ano de Instalação',
        es: 'Año de Instalación',
        ru: 'Год Установки',
        fr: 'Année d\'Installation',
        de: 'Installationsjahr'
    },
    'Get alerted when the inventory for this item goes below your minimum quantity.': {
        pt: 'Receba um alerta quando o inventário deste item ficar abaixo da quantidade mínima.',
        es: 'Reciba una alerta cuando el inventario de este artículo esté por debajo de su cantidad mínima.',
        ru: 'Получайте уведомление, когда запас этого предмета опускается ниже минимального количества.',
        fr: 'Soyez alerté lorsque l\'inventaire de cet article passe en dessous de votre quantité minimale.',
        de: 'Erhalten Sie eine Benachrichtigung, wenn der Bestand dieses Artikels unter Ihre Mindestmenge fällt.'
    },
    'Minimum Quantity': {
        pt: 'Quantidade Mínima',
        es: 'Cantidad Mínima',
        ru: 'Минимальное Количество',
        fr: 'Quantité Minimale',
        de: 'Mindestmenge'
    },
    'Please enter the minimum quantity.': {
        pt: 'Por favor, insira a quantidade mínima.',
        es: 'Por favor, ingrese la cantidad mínima.',
        ru: 'Пожалуйста, введите минимальное количество.',
        fr: 'Veuillez entrer la quantité minimale.',
        de: 'Bitte geben Sie die Mindestmenge ein.'
    },
    'Current Quantity': {
        pt: 'Quantidade Atual',
        es: 'Cantidad Actual',
        ru: 'Текущее Количество',
        fr: 'Quantité Actuelle',
        de: 'Aktuelle Menge'
    },
    'Please enter the current quantity.': {
        pt: 'Por favor, insira a quantidade atual.',
        es: 'Por favor, ingrese la cantidad actual.',
        ru: 'Пожалуйста, введите текущее количество.',
        fr: 'Veuillez entrer la quantité actuelle.',
        de: 'Bitte geben Sie die aktuelle Menge ein.'
    },
    'Why Add Items?': {
        pt: 'Por que Adicionar Itens?',
        es: '¿Por qué Agregar Artículos?',
        ru: 'Зачем Добавлять Предметы?',
        fr: 'Pourquoi Ajouter des Articles ?',
        de: 'Warum Artikel Hinzufügen?'
    },
    '+ Add New Model': {
        pt: '+ Adicionar Novo Modelo',
        es: '+ Agregar Nuevo Modelo',
        ru: '+ Добавить Новую Модель',
        fr: '+ Ajouter un Nouveau Modèle',
        de: '+ Neues Modell Hinzufügen'
    },
    'Please select a category': {
        pt: 'Por favor, selecione uma categoria',
        es: 'Por favor, seleccione una categoría',
        ru: 'Пожалуйста, выберите категорию',
        fr: 'Veuillez sélectionner une catégorie',
        de: 'Bitte wählen Sie eine Kategorie'
    },
    'When you add an item, we will tell you what the manufacturer recommends for that.': {
        pt: 'Quando você adicionar um item, informaremos o que o fabricante recomenda para ele.',
        es: 'Cuando agregue un artículo, le diremos lo que el fabricante recomienda para él.',
        ru: 'Когда вы добавляете предмет, мы расскажем вам, что производитель рекомендует для него.',
        fr: 'Lorsque vous ajoutez un article, nous vous dirons ce que le fabricant recommande pour celui-ci.',
        de: 'Wenn Sie einen Artikel hinzufügen, teilen wir Ihnen mit, was der Hersteller dafür empfiehlt.'
    },
    'Track Maintenance': {
        pt: 'Rastrear Manutenção',
        es: 'Rastrear Mantenimiento',
        ru: 'Отслеживать Обслуживание',
        fr: 'Suivre l\'Entretien',
        de: 'Wartung Verfolgen'
    },
    'Tracking what was done makes it easier for future maintenance and repairs. It\'s like a medical record of your home!': {
        pt: 'Rastrear o que foi feito facilita a manutenção e reparos futuros. É como um registro médico da sua casa!',
        es: 'Hacer un seguimiento de lo que se hizo facilita el mantenimiento y las reparaciones futuras. ¡Es como un historial médico de tu hogar!',
        ru: 'Отслеживание того, что было сделано, облегчает будущее обслуживание и ремонт. Это как медицинская карта вашего дома!',
        fr: 'Suivre ce qui a été fait facilite l\'entretien et les réparations futures. C\'est comme un dossier médical de votre maison !',
        de: 'Die Verfolgung dessen, was getan wurde, erleichtert zukünftige Wartungen und Reparaturen. Es ist wie eine Krankenakte für Ihr Zuhause!'
    },

    //MaintenancesInstancePage
    'Please let us know when you last completed these maintenances on this item:': {
        pt: 'Por favor, informe-nos quando você realizou pela última vez estas manutenções neste item:',
        es: 'Por favor, háganos saber cuándo completó por última vez estos mantenimientos en este artículo:',
        ru: 'Пожалуйста, сообщите нам, когда вы в последний раз выполняли это обслуживание для данного предмета:',
        fr: 'Veuillez nous indiquer quand vous avez effectué ces entretiens sur cet article pour la dernière fois :',
        de: 'Bitte teilen Sie uns mit, wann Sie diese Wartungen an diesem Artikel zuletzt durchgeführt haben:'
    },
    'Within a month': {
        pt: 'Dentro de um mês',
        es: 'Dentro de un mes',
        ru: 'В течение месяца',
        fr: 'Dans un mois',
        de: 'Innerhalb eines Monats'
    },
    '2-12 Months': {
        pt: '2-12 Meses',
        es: '2-12 Meses',
        ru: '2-12 Месяцев',
        fr: '2-12 Mois',
        de: '2-12 Monate'
    },
    'More than a year': {
        pt: 'Mais de um ano',
        es: 'Más de un año',
        ru: 'Более года',
        fr: 'Plus d\'un an',
        de: 'Mehr als ein Jahr'
    },
    'Within a year': {
        pt: 'Dentro de um ano',
        es: 'Dentro de un año',
        ru: 'В течение года',
        fr: 'Dans l\'année',
        de: 'Innerhalb eines Jahres'
    },
    '2-5 Years': {
        pt: '2-5 Anos',
        es: '2-5 Años',
        ru: '2-5 Лет',
        fr: '2-5 Ans',
        de: '2-5 Jahre'
    },
    'More than 6 years': {
        pt: 'Mais de 6 anos',
        es: 'Más de 6 años',
        ru: 'Более 6 лет',
        fr: 'Plus de 6 ans',
        de: 'Mehr als 6 Jahre'
    },
    'Please enter a time frame.': {
        pt: 'Por favor, insira um período de tempo.',
        es: 'Por favor, ingrese un marco de tiempo.',
        ru: 'Пожалуйста, введите временные рамки.',
        fr: 'Veuillez entrer une période.',
        de: 'Bitte geben Sie einen Zeitrahmen ein.'
    },

    //EditRoomsPage
    'No Rooms Added': {
        pt: 'Nenhum Quarto Adicionado',
        es: 'No Se Han Añadido Habitaciones',
        ru: 'Комнаты Не Добавлены',
        fr: 'Aucune Pièce Ajoutée',
        de: 'Keine Räume Hinzugefügt'
    },
    'Floor': {
        pt: 'Andar',
        es: 'Piso',
        ru: 'Этаж',
        fr: 'Étage',
        de: 'Stockwerk'
    },
    'Confirm Rooms': {
        pt: 'Confirmar Quartos',
        es: 'Confirmar Habitaciones',
        ru: 'Подтвердить Комнаты',
        fr: 'Confirmer les Pièces',
        de: 'Räume Bestätigen'
    },

    //AddRoomPage
    'Room': {
        pt: 'Quarto',
        es: 'Habitación',
        ru: 'Комната',
        fr: 'Pièce',
        de: 'Raum'
    },
    'Please enter the name of the room.': {
        pt: 'Por favor, insira o nome do quarto.',
        es: 'Por favor, ingrese el nombre de la habitación.',
        ru: 'Пожалуйста, введите название комнаты.',
        fr: 'Veuillez entrer le nom de la pièce.',
        de: 'Bitte geben Sie den Namen des Raums ein.'
    },
    'Room Type': {
        pt: 'Tipo de Quarto',
        es: 'Tipo de Habitación',
        ru: 'Тип Комнаты',
        fr: 'Type de Pièce',
        de: 'Raumtyp'
    },

    //EditRoomDetailsPage
    'Room Name': {
        pt: 'Nome do Quarto',
        es: 'Nombre de la Habitación',
        ru: 'Название Комнаты',
        fr: 'Nom de la Pièce',
        de: 'Raumname'
    },
    'Please enter the floor the room is on.': {
        pt: 'Por favor, insira o andar em que o quarto está.',
        es: 'Por favor, ingrese el piso en el que está la habitación.',
        ru: 'Пожалуйста, укажите этаж, на котором находится комната.',
        fr: 'Veuillez entrer l\'étage où se trouve la pièce.',
        de: 'Bitte geben Sie das Stockwerk ein, auf dem sich der Raum befindet.'
    },
    'Please select the room type.': {
        pt: 'Por favor, selecione o tipo de quarto.',
        es: 'Por favor, seleccione el tipo de habitación.',
        ru: 'Пожалуйста, выберите тип комнаты.',
        fr: 'Veuillez sélectionner le type de pièce.',
        de: 'Bitte wählen Sie den Raumtyp aus.'
    },
    'Delete Room?': {
        pt: 'Excluir Quarto?',
        es: '¿Eliminar Habitación?',
        ru: 'Удалить Комнату?',
        fr: 'Supprimer la Pièce ?',
        de: 'Raum Löschen?'
    },
    'Are you sure you want to delete this room?': {
        pt: 'Tem certeza de que deseja excluir este quarto?',
        es: '¿Está seguro de que desea eliminar esta habitación?',
        ru: 'Вы уверены, что хотите удалить эту комнату?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette pièce ?',
        de: 'Sind Sie sicher, dass Sie diesen Raum löschen möchten?'
    },
    'Delete Room': {
        pt: 'Excluir Quarto',
        es: 'Eliminar Habitación',
        ru: 'Удалить Комнату',
        fr: 'Supprimer la Pièce',
        de: 'Raum Löschen'
    },

    //ItemMaintenancePage
    'Maintenance': {
        pt: 'Manutenção',
        es: 'Mantenimiento',
        ru: 'Техническое обслуживание',
        fr: 'Entretien',
        de: 'Wartung'
    },
    'Do next:': {
        pt: 'Fazer a seguir:',
        es: 'Hacer a continuación:',
        ru: 'Сделать следующее:',
        fr: 'À faire ensuite :',
        de: 'Als nächstes tun:'
    },
    'Never completed before': {
        pt: 'Nunca concluído antes',
        es: 'Nunca completado antes',
        ru: 'Никогда не выполнялось ранее',
        fr: 'Jamais effectué auparavant',
        de: 'Noch nie zuvor abgeschlossen'
    },
    'Frequency:': {
        pt: 'Frequência:',
        es: 'Frecuencia:',
        ru: 'Частота:',
        fr: 'Fréquence :',
        de: 'Häufigkeit:'
    },
    'Mark Complete': {
        pt: 'Marcar como Concluído',
        es: 'Marcar como Completado',
        ru: 'Отметить как выполненное',
        fr: 'Marquer comme Terminé',
        de: 'Als Erledigt markieren'
    },
    'Skip Maintenance': {
        pt: 'Pular Manutenção',
        es: 'Omitir Mantenimiento',
        ru: 'Пропустить обслуживание',
        fr: 'Ignorer l\'Entretien',
        de: 'Wartung überspringen'
    },

    //MapsIntroductionPage
    'TIDY has the easiest way to make a map of your property, taking less than 5 minutes of your time.': {
        pt: 'TIDY tem a maneira mais fácil de fazer um mapa da sua propriedade, levando menos de 5 minutos do seu tempo.',
        es: 'TIDY tiene la forma más fácil de hacer un mapa de su propiedad, tomando menos de 5 minutos de su tiempo.',
        ru: 'TIDY предлагает самый простой способ создать карту вашей недвижимости, занимающий менее 5 минут вашего времени.',
        fr: 'TIDY a la façon la plus simple de faire une carte de votre propriété, prenant moins de 5 minutes de votre temps.',
        de: 'TIDY hat den einfachsten Weg, eine Karte Ihres Anwesens zu erstellen, die weniger als 5 Minuten Ihrer Zeit in Anspruch nimmt.'
    },
    'Please tell us more about:': {
        pt: 'Por favor, conte-nos mais sobre:',
        es: 'Por favor, cuéntenos más sobre:',
        ru: 'Пожалуйста, расскажите нам подробнее о:',
        fr: 'Veuillez nous en dire plus sur :',
        de: 'Bitte erzählen Sie uns mehr über:'
    },
    'Number of Bedrooms': {
        pt: 'Número de Quartos',
        es: 'Número de Dormitorios',
        ru: 'Количество Спален',
        fr: 'Nombre de Chambres',
        de: 'Anzahl der Schlafzimmer'
    },
    'Number of Bathrooms': {
        pt: 'Número de Banheiros',
        es: 'Número de Baños',
        ru: 'Количество Ванных Комнат',
        fr: 'Nombre de Salles de Bains',
        de: 'Anzahl der Badezimmer'
    },
    'Number of Floors': {
        pt: 'Número de Andares',
        es: 'Número de Pisos',
        ru: 'Количество Этажей',
        fr: 'Nombre d\'Étages',
        de: 'Anzahl der Stockwerke'
    },
    'Confirm Your Rooms': {
        pt: 'Confirme Seus Quartos',
        es: 'Confirme Sus Habitaciones',
        ru: 'Подтвердите Ваши Комнаты',
        fr: 'Confirmez Vos Pièces',
        de: 'Bestätigen Sie Ihre Räume'
    },
    'Confirm Your Location': {
        pt: 'Confirme Sua Localização',
        es: 'Confirme Su Ubicación',
        ru: 'Подтвердите Ваше Местоположение',
        fr: 'Confirmez Votre Emplacement',
        de: 'Bestätigen Sie Ihren Standort'
    },
    'Does this show your property?': {
        pt: 'Isso mostra sua propriedade?',
        es: '¿Esto muestra su propiedad?',
        ru: 'Это показывает вашу недвижимость?',
        fr: 'Cela montre-t-il votre propriété ?',
        de: 'Zeigt dies Ihr Anwesen?'
    },
    'Right now, we require a satellite image of your home to continue. We hope to support situations where your property is not shown in the future. We apologize for the inconvenience. If you have any feedback for us, please': {
        pt: 'No momento, precisamos de uma imagem de satélite da sua casa para continuar. Esperamos futuramente dar suporte a situações em que sua propriedade não é mostrada. Pedimos desculpas pelo inconveniente. Se você tiver algum feedback para nós, por favor',
        es: 'En este momento, requerimos una imagen satelital de su hogar para continuar. Esperamos poder admitir situaciones en las que su propiedad no se muestre en el futuro. Nos disculpamos por las molestias. Si tiene algún comentario para nosotros, por favor',
        ru: 'В настоящее время нам требуется спутниковое изображение вашего дома, чтобы продолжить. Мы надеемся в будущем поддерживать ситуации, когда ваша недвижимость не отображается. Приносим извинения за неудобства. Если у вас есть отзывы для нас, пожалуйста',
        fr: 'Pour le moment, nous avons besoin d\'une image satellite de votre maison pour continuer. Nous espérons prendre en charge les situations où votre propriété n\'est pas affichée à l\'avenir. Nous nous excusons pour le désagrément. Si vous avez des commentaires pour nous, s\'il vous plaît',
        de: 'Im Moment benötigen wir ein Satellitenbild Ihres Hauses, um fortzufahren. Wir hoffen, in Zukunft Situationen zu unterstützen, in denen Ihr Anwesen nicht angezeigt wird. Wir entschuldigen uns für die Unannehmlichkeiten. Wenn Sie Feedback für uns haben, bitte'
    },
    'tap here': {
        pt: 'toque aqui',
        es: 'toque aquí',
        ru: 'нажмите здесь',
        fr: 'appuyez ici',
        de: 'tippen Sie hier'
    },
    'Yes': {
        pt: 'Sim',
        es: 'Sí',
        ru: 'Да',
        fr: 'Oui',
        de: 'Ja'
    },
    'No': {
        pt: 'Não',
        es: 'No',
        ru: 'Нет',
        fr: 'Non',
        de: 'Nein'
    },

    //PropertyMappingPage
    'Select Your Lot': {
        pt: 'Selecione Seu Lote',
        es: 'Seleccione Su Lote',
        ru: 'Выберите Свой Участок',
        fr: 'Sélectionnez Votre Terrain',
        de: 'Wählen Sie Ihr Grundstück'
    },
    'First, tap a corner of your lot and drag to the next one.': {
        pt: 'Primeiro, toque em um canto do seu lote e arraste para o próximo.',
        es: 'Primero, toque una esquina de su lote y arrastre hasta la siguiente.',
        ru: 'Сначала коснитесь угла вашего участка и перетащите к следующему.',
        fr: 'D\'abord, appuyez sur un coin de votre terrain et faites glisser jusqu\'au suivant.',
        de: 'Tippen Sie zuerst auf eine Ecke Ihres Grundstücks und ziehen Sie zur nächsten.'
    },
    'Once the first line is created, tap each corner of your lot.': {
        pt: 'Depois que a primeira linha for criada, toque em cada canto do seu lote.',
        es: 'Una vez que se crea la primera línea, toque cada esquina de su lote.',
        ru: 'После создания первой линии коснитесь каждого угла вашего участка.',
        fr: 'Une fois la première ligne créée, appuyez sur chaque coin de votre terrain.',
        de: 'Sobald die erste Linie erstellt ist, tippen Sie auf jede Ecke Ihres Grundstücks.'
    },
    'To finish, <u><b>tap the screen and hold</b></u> on the last remaining corner.': {
        pt: 'Para finalizar, <u><b>toque e segure a tela</b></u> no último canto restante.',
        es: 'Para terminar, <u><b>mantenga presionada la pantalla</b></u> en la última esquina restante.',
        ru: 'Чтобы закончить, <u><b>нажмите и удерживайте экран</b></u> на последнем оставшемся углу.',
        fr: 'Pour terminer, <u><b>appuyez et maintenez l\'écran</b></u> sur le dernier coin restant.',
        de: 'Um abzuschließen, <u><b>tippen und halten Sie den Bildschirm</b></u> an der letzten verbleibenden Ecke.'
    },
    'First, click a corner of your lot and drag to the next one.': {
        pt: 'Primeiro, clique em um canto do seu lote e arraste para o próximo.',
        es: 'Primero, haga clic en una esquina de su lote y arrastre hasta la siguiente.',
        ru: 'Сначала щелкните угол вашего участка и перетащите к следующему.',
        fr: 'D\'abord, cliquez sur un coin de votre terrain et faites glisser jusqu\'au suivant.',
        de: 'Klicken Sie zuerst auf eine Ecke Ihres Grundstücks und ziehen Sie zur nächsten.'
    },
    'Once the first line is created, click each corner of your lot.': {
        pt: 'Depois que a primeira linha for criada, clique em cada canto do seu lote.',
        es: 'Una vez que se crea la primera línea, haga clic en cada esquina de su lote.',
        ru: 'После создания первой линии щелкните каждый угол вашего участка.',
        fr: 'Une fois la première ligne créée, cliquez sur chaque coin de votre terrain.',
        de: 'Sobald die erste Linie erstellt ist, klicken Sie auf jede Ecke Ihres Grundstücks.'
    },
    'To finish the selection, <u><b>double click</b></u> the last remaining corner.': {
        pt: 'Para finalizar a seleção, <u><b>clique duas vezes</b></u> no último canto restante.',
        es: 'Para finalizar la selección, <u><b>haga doble clic</b></u> en la última esquina restante.',
        ru: 'Чтобы завершить выбор, <u><b>дважды щелкните</b></u> последний оставшийся угол.',
        fr: 'Pour terminer la sélection, <u><b>double-cliquez</b></u> sur le dernier coin restant.',
        de: 'Um die Auswahl abzuschließen, <u><b>doppelklicken Sie</b></u> auf die letzte verbleibende Ecke.'
    },
    'Looks Good': {
        pt: 'Parece Bom',
        es: 'Se Ve Bien',
        ru: 'Выглядит Хорошо',
        fr: 'Ça Semble Bon',
        de: 'Sieht Gut Aus'
    },
    'Retry': {
        pt: 'Tentar Novamente',
        es: 'Reintentar',
        ru: 'Повторить',
        fr: 'Réessayer',
        de: 'Erneut Versuchen'
    },
    'You may only have one lot selection. Use Retry if you think your current selection is inaccurate.': {
        pt: 'Você só pode ter uma seleção de lote. Use Tentar Novamente se achar que sua seleção atual está imprecisa.',
        es: 'Solo puede tener una selección de lote. Use Reintentar si cree que su selección actual es inexacta.',
        ru: 'У вас может быть только один выбор участка. Используйте Повторить, если считаете, что ваш текущий выбор неточен.',
        fr: 'Vous ne pouvez avoir qu\'une seule sélection de terrain. Utilisez Réessayer si vous pensez que votre sélection actuelle est inexacte.',
        de: 'Sie können nur eine Grundstücksauswahl haben. Verwenden Sie Erneut Versuchen, wenn Sie denken, dass Ihre aktuelle Auswahl ungenau ist.'
    },
    'You may only have one Lot Selection, use Retry if you think your current selection is inaccurate.': {
        pt: 'Você só pode ter uma Seleção de Lote, use Tentar Novamente se achar que sua seleção atual está imprecisa.',
        es: 'Solo puede tener una Selección de Lote, use Reintentar si cree que su selección actual es inexacta.',
        ru: 'У вас может быть только один Выбор Участка, используйте Повторить, если считаете, что ваш текущий выбор неточен.',
        fr: 'Vous ne pouvez avoir qu\'une seule Sélection de Terrain, utilisez Réessayer si vous pensez que votre sélection actuelle est inexacte.',
        de: 'Sie können nur eine Grundstücksauswahl haben, verwenden Sie Erneut Versuchen, wenn Sie denken, dass Ihre aktuelle Auswahl ungenau ist.'
    },

    //PropertyScanningPage
    'Scan Interior of Your Property': {
        pt: 'Escanear o Interior da Sua Propriedade',
        es: 'Escanear el Interior de Su Propiedad',
        ru: 'Сканировать Интерьер Вашей Собственности',
        fr: 'Scanner l\'Intérieur de Votre Propriété',
        de: 'Innenbereich Ihres Eigentums Scannen'
    },
    'You record a video tour and our AI will analyze it to build a map of your home.': {
        pt: 'Você grava um tour em vídeo e nossa IA o analisará para construir um mapa da sua casa.',
        es: 'Usted graba un recorrido en video y nuestra IA lo analizará para construir un mapa de su hogar.',
        ru: 'Вы записываете видеотур, и наш ИИ проанализирует его, чтобы построить карту вашего дома.',
        fr: 'Vous enregistrez une visite vidéo et notre IA l\'analysera pour construire une carte de votre maison.',
        de: 'Sie nehmen eine Videotour auf und unsere KI analysiert sie, um eine Karte Ihres Zuhauses zu erstellen.'
    },
    'Just walk around like you are giving a quick tour to a friend, talking about what is shown in the video.': {
        pt: 'Basta caminhar como se estivesse dando um tour rápido a um amigo, falando sobre o que é mostrado no vídeo.',
        es: 'Simplemente camine como si estuviera dando un recorrido rápido a un amigo, hablando sobre lo que se muestra en el video.',
        ru: 'Просто ходите, как будто вы проводите быструю экскурсию для друга, рассказывая о том, что показано на видео.',
        fr: 'Marchez simplement comme si vous faisiez une visite rapide à un ami, en parlant de ce qui est montré dans la vidéo.',
        de: 'Gehen Sie einfach herum, als würden Sie einem Freund eine schnelle Tour geben, und sprechen Sie darüber, was im Video gezeigt wird.'
    },
    'Then, we will do the rest.': {
        pt: 'Depois, nós faremos o resto.',
        es: 'Luego, nosotros haremos el resto.',
        ru: 'Затем мы сделаем все остальное.',
        fr: 'Ensuite, nous ferons le reste.',
        de: 'Dann erledigen wir den Rest.'
    },
    'Skip This': {
        pt: 'Pular Isso',
        es: 'Saltar Esto',
        ru: 'Пропустить Это',
        fr: 'Passer Ceci',
        de: 'Dies Überspringen'
    },
    'Scan Complete!': {
        pt: 'Escaneamento Concluído!',
        es: '¡Escaneo Completo!',
        ru: 'Сканирование Завершено!',
        fr: 'Scan Terminé !',
        de: 'Scan Abgeschlossen!'
    },
    'This can take 72 hours or more to analyze, depending on factors like how busy the queue. You will get a notification when it is done.': {
        pt: 'Isso pode levar 72 horas ou mais para analisar, dependendo de fatores como o quão ocupada está a fila. Você receberá uma notificação quando estiver pronto.',
        es: 'Esto puede tardar 72 horas o más en analizarse, dependiendo de factores como qué tan ocupada esté la cola. Recibirá una notificación cuando esté listo.',
        ru: 'Анализ может занять 72 часа или более, в зависимости от таких факторов, как загруженность очереди. Вы получите уведомление, когда он будет готов.',
        fr: 'Cela peut prendre 72 heures ou plus pour analyser, en fonction de facteurs tels que l\'occupation de la file d\'attente. Vous recevrez une notification une fois terminé.',
        de: 'Die Analyse kann 72 Stunden oder länger dauern, abhängig von Faktoren wie der Auslastung der Warteschlange. Sie erhalten eine Benachrichtigung, wenn es fertig ist.'
    },

    //PropertyOptionsPage
    'This information is important to help your Pro get in, and never used for marketing. The more detail the better. Photos help a lot!': {
        pt: 'Esta informação é importante para ajudar o seu Profissional a entrar, e nunca é usada para marketing. Quanto mais detalhes, melhor. Fotos ajudam muito!',
        es: 'Esta información es importante para ayudar a su Profesional a entrar, y nunca se usa para marketing. Cuanto más detalle, mejor. ¡Las fotos ayudan mucho!',
        ru: 'Эта информация важна, чтобы помочь вашему Профессионалу войти, и никогда не используется для маркетинга. Чем больше деталей, тем лучше. Фотографии очень помогают!',
        fr: 'Ces informations sont importantes pour aider votre Pro à entrer, et ne sont jamais utilisées pour le marketing. Plus il y a de détails, mieux c\'est. Les photos aident beaucoup !',
        de: 'Diese Informationen sind wichtig, um Ihrem Profi den Zugang zu erleichtern, und werden nie für Marketing verwendet. Je mehr Details, desto besser. Fotos helfen sehr!'
    },
    'Details help jobs happen smoothly.': {
        pt: 'Detalhes ajudam os trabalhos a acontecerem sem problemas.',
        es: 'Los detalles ayudan a que los trabajos se realicen sin problemas.',
        ru: 'Детали помогают работе проходить гладко.',
        fr: 'Les détails aident les travaux à se dérouler en douceur.',
        de: 'Details helfen, dass Aufträge reibungslos ablaufen.'
    },
    'What kind of parking?': {
        pt: 'Que tipo de estacionamento?',
        es: '¿Qué tipo de estacionamiento?',
        ru: 'Какой тип парковки?',
        fr: 'Quel type de stationnement ?',
        de: 'Welche Art von Parkplatz?'
    },
    'How to Park': {
        pt: 'Como Estacionar',
        es: 'Cómo Estacionar',
        ru: 'Как Припарковаться',
        fr: 'Comment Se Garer',
        de: 'Wie man Parkt'
    },
    'What kind of spot?': {
        pt: 'Que tipo de vaga?',
        es: '¿Qué tipo de lugar?',
        ru: 'Какой тип места?',
        fr: 'Quel type d\'emplacement ?',
        de: 'Welche Art von Stellplatz?'
    },
    'How will you pay?': {
        pt: 'Como você vai pagar?',
        es: '¿Cómo vas a pagar?',
        ru: 'Как вы будете платить?',
        fr: 'Comment allez-vous payer ?',
        de: 'Wie werden Sie bezahlen?'
    },
    'How much will you reimburse?': {
        pt: 'Quanto você vai reembolsar?',
        es: '¿Cuánto reembolsarás?',
        ru: 'Сколько вы возместите?',
        fr: 'Combien allez-vous rembourser ?',
        de: 'Wie viel werden Sie erstatten?'
    },
    'Any notes to help park?': {
        pt: 'Alguma observação para ajudar a estacionar?',
        es: '¿Alguna nota para ayudar a estacionar?',
        ru: 'Есть ли заметки, чтобы помочь припарковаться?',
        fr: 'Des notes pour aider à se garer ?',
        de: 'Irgendwelche Hinweise zum Parken?'
    },
    'How to Get In': {
        pt: 'Como Entrar',
        es: 'Cómo Entrar',
        ru: 'Как Войти',
        fr: 'Comment Entrer',
        de: 'Wie man Hineinkommt'
    },
    'How do they access the property?': {
        pt: 'Como eles acessam a propriedade?',
        es: '¿Cómo acceden a la propiedad?',
        ru: 'Как они получают доступ к собственности?',
        fr: 'Comment accèdent-ils à la propriété ?',
        de: 'Wie gelangen sie auf das Grundstück?'
    },
    'Smart Lock Integrated: The RemoteLock code will be available for the pro the day of the job.': {
        pt: 'Fechadura Inteligente Integrada: O código RemoteLock estará disponível para o profissional no dia do trabalho.',
        es: 'Cerradura Inteligente Integrada: El código RemoteLock estará disponible para el profesional el día del trabajo.',
        ru: 'Интегрированный Умный Замок: Код RemoteLock будет доступен для профессионала в день работы.',
        fr: 'Serrure Intelligente Intégrée : Le code RemoteLock sera disponible pour le professionnel le jour du travail.',
        de: 'Integriertes Smart Lock: Der RemoteLock-Code wird für den Profi am Tag des Auftrags verfügbar sein.'
    },
    'How to Close Up': {
        pt: 'Como Fechar',
        es: 'Cómo Cerrar',
        ru: 'Как Закрыть',
        fr: 'Comment Fermer',
        de: 'Wie man Abschließt'
    },
    'What should they do as they leave?': {
        pt: 'O que eles devem fazer ao sair?',
        es: '¿Qué deben hacer al salir?',
        ru: 'Что они должны сделать при выходе?',
        fr: 'Que doivent-ils faire en partant ?',
        de: 'Was sollen sie beim Verlassen tun?'
    },
    'Cancel': {
        pt: 'Cancelar',
        es: 'Cancelar',
        ru: 'Отмена',
        fr: 'Annuler',
        de: 'Abbrechen'
    },
    'Alert': {
        pt: 'Alerta',
        es: 'Alerta',
        ru: 'Предупреждение',
        fr: 'Alerte',
        de: 'Warnung'
    },
    'We will paste your message to "How to Get In" input, this action will overwrite the current message. Should we proceed?': {
        pt: 'Vamos colar sua mensagem na entrada "Como Entrar", esta ação substituirá a mensagem atual. Devemos prosseguir?',
        es: 'Pegaremos su mensaje en la entrada "Cómo Entrar", esta acción sobrescribirá el mensaje actual. ¿Debemos proceder?',
        ru: 'Мы вставим ваше сообщение в поле "Как Войти", это действие перезапишет текущее сообщение. Продолжить?',
        fr: 'Nous allons coller votre message dans l\'entrée "Comment Entrer", cette action écrasera le message actuel. Devons-nous procéder ?',
        de: 'Wir werden Ihre Nachricht in das Feld "Wie man Hineinkommt" einfügen, diese Aktion wird die aktuelle Nachricht überschreiben. Sollen wir fortfahren?'
    },
    'Job Requested!': {
        pt: 'Trabalho Solicitado!',
        es: '¡Trabajo Solicitado!',
        ru: 'Работа Запрошена!',
        fr: 'Travail Demandé !',
        de: 'Auftrag Angefordert!'
    },
    'You\'re Scheduled!': {
        pt: 'Você Está Agendado!',
        es: '¡Estás Programado!',
        ru: 'Вы Запланированы!',
        fr: 'Vous Êtes Programmé !',
        de: 'Sie Sind Eingeplant!'
    },
    'Parking & Access Info Helps!': {
        pt: 'Informações de Estacionamento e Acesso Ajudam!',
        es: '¡La Información de Estacionamiento y Acceso Ayuda!',
        ru: 'Информация о Парковке и Доступе Помогает!',
        fr: 'Les Infos de Stationnement et d\'Accès Aident !',
        de: 'Park- und Zugangsinfos Helfen!'
    },
    'Access Info Helps!': {
        pt: 'Informações de Acesso Ajudam!',
        es: '¡La Información de Acceso Ayuda!',
        ru: 'Информация о Доступе Помогает!',
        fr: 'Les Infos d\'Accès Aident !',
        de: 'Zugangsinfos Helfen!'
    },
    'Parking Info Helps!': {
        pt: 'Informações de Estacionamento Ajudam!',
        es: '¡La Información de Estacionamiento Ayuda!',
        ru: 'Информация о Парковке Помогает!',
        fr: 'Les Infos de Stationnement Aident !',
        de: 'Parkinfos Helfen!'
    },
    'This address has scheduled jobs. Please cancel the jobs for this property before deleting this property.': {
        pt: 'Este endereço tem trabalhos agendados. Por favor, cancele os trabalhos para esta propriedade antes de excluí-la.',
        es: 'Esta dirección tiene trabajos programados. Por favor, cancele los trabajos para esta propiedad antes de eliminarla.',
        ru: 'По этому адресу есть запланированные работы. Пожалуйста, отмените работы для этой собственности перед ее удалением.',
        fr: 'Cette adresse a des travaux programmés. Veuillez annuler les travaux pour cette propriété avant de la supprimer.',
        de: 'Für diese Adresse sind Aufträge geplant. Bitte stornieren Sie die Aufträge für diese Immobilie, bevor Sie sie löschen.'
    },
    'Free': {
        pt: 'Grátis',
        es: 'Gratis',
        ru: 'Бесплатно',
        fr: 'Gratuit',
        de: 'Kostenlos'
    },
    'Meter': {
        pt: 'Parquímetro',
        es: 'Parquímetro',
        ru: 'Счетчик',
        fr: 'Parcmètre',
        de: 'Parkuhr'
    },
    'Paid Lot': {
        pt: 'Estacionamento Pago',
        es: 'Estacionamiento de Pago',
        ru: 'Платная Стоянка',
        fr: 'Parking Payant',
        de: 'Kostenpflichtiger Parkplatz'
    },
    'Street': {
        pt: 'Rua',
        es: 'Calle',
        ru: 'Улица',
        fr: 'Rue',
        de: 'Straße'
    },
    'My Spot / Driveway': {
        pt: 'Minha Vaga / Garagem',
        es: 'Mi Lugar / Entrada',
        ru: 'Мое Место / Подъездная Дорожка',
        fr: 'Ma Place / Allée',
        de: 'Mein Platz / Einfahrt'
    },
    'Guest Parking': {
        pt: 'Estacionamento para Visitantes',
        es: 'Estacionamiento para Invitados',
        ru: 'Гостевая Парковка',
        fr: 'Parking Visiteur',
        de: 'Gästeparkplatz'
    },
    'Cash': {
        pt: 'Dinheiro',
        es: 'Efectivo',
        ru: 'Наличные',
        fr: 'Espèces',
        de: 'Bargeld'
    },
    'Card': {
        pt: 'Cartão',
        es: 'Tarjeta',
        ru: 'Карта',
        fr: 'Carte',
        de: 'Karte'
    },

    //ItemDetailsPage
    'Edit Item': {
        pt: 'Editar Item',
        es: 'Editar Artículo',
        ru: 'Редактировать Предмет',
        fr: 'Modifier l\'Article',
        de: 'Artikel Bearbeiten'
    },
    'Details': {
        pt: 'Detalhes',
        es: 'Detalles',
        ru: 'Подробности',
        fr: 'Détails',
        de: 'Details'
    },
    'Brand:': {
        pt: 'Marca:',
        es: 'Marca:',
        ru: 'Бренд:',
        fr: 'Marque:',
        de: 'Marke:'
    },
    'Model #:': {
        pt: 'Modelo #:',
        es: 'Modelo #:',
        ru: 'Модель #:',
        fr: 'Modèle #:',
        de: 'Modell #:'
    },
    'Inventory Alerts: min quantity': {
        pt: 'Alertas de Inventário: quantidade mínima',
        es: 'Alertas de Inventario: cantidad mínima',
        ru: 'Оповещения о Запасах: минимальное количество',
        fr: 'Alertes d\'Inventaire: quantité minimale',
        de: 'Bestandsalarme: Mindestmenge'
    },
    'current quantity:': {
        pt: 'quantidade atual:',
        es: 'cantidad actual:',
        ru: 'текущее количество:',
        fr: 'quantité actuelle:',
        de: 'aktuelle Menge:'
    },
    'Change History': {
        pt: 'Histórico de Alterações',
        es: 'Historial de Cambios',
        ru: 'История Изменений',
        fr: 'Historique des Modifications',
        de: 'Änderungsverlauf'
    },
    'Quantity Change From': {
        pt: 'Alteração de Quantidade De',
        es: 'Cambio de Cantidad De',
        ru: 'Изменение Количества С',
        fr: 'Changement de Quantité De',
        de: 'Mengenänderung Von'
    },
    'to': {
        pt: 'para',
        es: 'a',
        ru: 'до',
        fr: 'à',
        de: 'zu'
    },
    'Made by': {
        pt: 'Feito por',
        es: 'Hecho por',
        ru: 'Сделано',
        fr: 'Fait par',
        de: 'Gemacht von'
    },
    'on': {
        pt: 'em',
        es: 'el',
        ru: 'на',
        fr: 'le',
        de: 'am'
    },
    'No quantity changes': {
        pt: 'Sem alterações de quantidade',
        es: 'Sin cambios de cantidad',
        ru: 'Нет изменений количества',
        fr: 'Pas de changements de quantité',
        de: 'Keine Mengenänderungen'
    },
    'Delete item?': {
        pt: 'Excluir item?',
        es: '¿Eliminar artículo?',
        ru: 'Удалить предмет?',
        fr: 'Supprimer l\'article?',
        de: 'Artikel löschen?'
    },
    'Delete Item': {
        pt: 'Excluir Item',
        es: 'Eliminar Artículo',
        ru: 'Удалить Предмет',
        fr: 'Supprimer l\'Article',
        de: 'Artikel Löschen'
    },

    //EditRoomItemPage
    'Model #': {
        pt: 'Modelo #',
        es: 'Modelo #',
        ru: 'Модель #',
        fr: 'Modèle #',
        de: 'Modell #'
    },
    'Update Item': {
        pt: 'Atualizar Item',
        es: 'Actualizar Artículo',
        ru: 'Обновить Предмет',
        fr: 'Mettre à Jour l\'Article',
        de: 'Artikel Aktualisieren'
    },
    '+ Add new model': {
        pt: '+ Adicionar novo modelo',
        es: '+ Agregar nuevo modelo',
        ru: '+ Добавить новую модель',
        fr: '+ Ajouter un nouveau modèle',
        de: '+ Neues Modell hinzufügen'
    },

    //EditLanguagePage
    'Edit Display Language': {
        pt: 'Editar Idioma de Exibição',
        es: 'Editar Idioma de Visualización',
        ru: 'Изменить Язык Отображения',
        fr: 'Modifier la Langue d\'Affichage',
        de: 'Anzeigesprache Bearbeiten'
    },
    'Language': {
        pt: 'Idioma',
        es: 'Idioma',
        ru: 'Язык',
        fr: 'Langue',
        de: 'Sprache'
    },
    'Save Language': {
        pt: 'Salvar Idioma',
        es: 'Guardar Idioma',
        ru: 'Сохранить Язык',
        fr: 'Enregistrer la Langue',
        de: 'Sprache Speichern'
    },
    'Language updated!': {
        pt: 'Idioma atualizado!',
        es: '¡Idioma actualizado!',
        ru: 'Язык обновлен!',
        fr: 'Langue mise à jour !',
        de: 'Sprache aktualisiert!'
    },

    //EditPricesPage
    'Edit Prices': {
        pt: 'Editar Preços',
        es: 'Editar Precios',
        ru: 'Редактировать Цены',
        fr: 'Modifier les Prix',
        de: 'Preise Bearbeiten'
    },
    'Edit your base prices for this property below. Your base price is what you pay for a recurring job where you are charged before. Same day, one time and charge after prices are calculated automatically based on your base price.': {
        pt: 'Edite seus preços base para esta propriedade abaixo. Seu preço base é o que você paga por um trabalho recorrente onde é cobrado antecipadamente. Preços para o mesmo dia, uma vez e cobrança posterior são calculados automaticamente com base no seu preço base.',
        es: 'Edite sus precios base para esta propiedad a continuación. Su precio base es lo que paga por un trabajo recurrente donde se le cobra por adelantado. Los precios del mismo día, una sola vez y el cargo posterior se calculan automáticamente según su precio base.',
        ru: 'Отредактируйте базовые цены для этого объекта ниже. Ваша базовая цена - это то, что вы платите за повторяющуюся работу, где с вас взимается плата заранее. Цены на тот же день, разовые и с последующей оплатой рассчитываются автоматически на основе вашей базовой цены.',
        fr: 'Modifiez vos prix de base pour cette propriété ci-dessous. Votre prix de base est ce que vous payez pour un travail récurrent où vous êtes facturé à l\'avance. Les prix pour le même jour, une seule fois et la facturation après sont calculés automatiquement en fonction de votre prix de base.',
        de: 'Bearbeiten Sie unten Ihre Grundpreise für dieses Objekt. Ihr Grundpreis ist das, was Sie für einen wiederkehrenden Auftrag zahlen, bei dem Sie im Voraus berechnet werden. Preise für denselben Tag, einmalige und nachträgliche Berechnung werden automatisch auf Basis Ihres Grundpreises berechnet.'
    },
    'Please select a price.': {
        pt: 'Por favor, selecione um preço.',
        es: 'Por favor, seleccione un precio.',
        ru: 'Пожалуйста, выберите цену.',
        fr: 'Veuillez sélectionner un prix.',
        de: 'Bitte wählen Sie einen Preis.'
    },

    //InputToDosPage
    'Edit To-Do Inputs': {
        pt: 'Editar Entradas de Tarefas',
        es: 'Editar Entradas de Tareas',
        ru: 'Редактировать Ввод Задач',
        fr: 'Modifier les Entrées de Tâches',
        de: 'Aufgabeneingaben Bearbeiten'
    },
    'Take Photo': {
        pt: 'Tirar Foto',
        es: 'Tomar Foto',
        ru: 'Сделать Фото',
        fr: 'Prendre une Photo',
        de: 'Foto Aufnehmen'
    },
    'DO NOT clean this room': {
        pt: 'NÃO limpe este quarto',
        es: 'NO limpie esta habitación',
        ru: 'НЕ убирайте эту комнату',
        fr: 'NE PAS nettoyer cette pièce',
        de: 'Diesen Raum NICHT reinigen'
    },
    'Please enter an answer.': {
        pt: 'Por favor, insira uma resposta.',
        es: 'Por favor, ingrese una respuesta.',
        ru: 'Пожалуйста, введите ответ.',
        fr: 'Veuillez entrer une réponse.',
        de: 'Bitte geben Sie eine Antwort ein.'
    },
    'Please enter an amount.': {
        pt: 'Por favor, insira um valor.',
        es: 'Por favor, ingrese una cantidad.',
        ru: 'Пожалуйста, введите сумму.',
        fr: 'Veuillez entrer un montant.',
        de: 'Bitte geben Sie einen Betrag ein.'
    },
    'Remove Photo': {
        pt: 'Remover Foto',
        es: 'Eliminar Foto',
        ru: 'Удалить Фото',
        fr: 'Supprimer la Photo',
        de: 'Foto Entfernen'
    },
    'Are you sure you want to remove this photo?': {
        pt: 'Tem certeza de que deseja remover esta foto?',
        es: '¿Está seguro de que desea eliminar esta foto?',
        ru: 'Вы уверены, что хотите удалить это фото?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette photo ?',
        de: 'Sind Sie sicher, dass Sie dieses Foto entfernen möchten?'
    },

    //LogsPage
    'No Logs Avaliable.': {
        pt: 'Nenhum Registro Disponível.',
        es: 'No Hay Registros Disponibles.',
        ru: 'Нет Доступных Журналов.',
        fr: 'Aucun Journal Disponible.',
        de: 'Keine Protokolle Verfügbar.'
    },

    //MyPricesPage
    'My Prices': {
        pt: 'Meus Preços',
        es: 'Mis Precios',
        ru: 'Мои Цены',
        fr: 'Mes Prix',
        de: 'Meine Preise'
    },
    'Prices for Pros You Added': {
        pt: 'Preços para Profissionais que Você Adicionou',
        es: 'Precios para Profesionales que Agregó',
        ru: 'Цены для Добавленных Вами Профессионалов',
        fr: 'Prix pour les Pros que Vous Avez Ajoutés',
        de: 'Preise für von Ihnen Hinzugefügte Profis'
    },
    'View price settings for pros you added': {
        pt: 'Ver configurações de preço para profissionais que você adicionou',
        es: 'Ver configuraciones de precio para profesionales que agregó',
        ru: 'Просмотреть настройки цен для добавленных вами профессионалов',
        fr: 'Voir les paramètres de prix pour les pros que vous avez ajoutés',
        de: 'Preiseinstellungen für von Ihnen hinzugefügte Profis anzeigen'
    },
    'here': {
        pt: 'aqui',
        es: 'aquí',
        ru: 'здесь',
        fr: 'ici',
        de: 'hier'
    },
    'Prices for Find New Pros': {
        pt: 'Preços para Encontrar Novos Profissionais',
        es: 'Precios para Encontrar Nuevos Profesionales',
        ru: 'Цены для Поиска Новых Профессионалов',
        fr: 'Prix pour Trouver de Nouveaux Pros',
        de: 'Preise für Neue Profis Finden'
    },
    'These prices apply to job requests made to pros that you have not added, using the Find New Pro option. Higher prices tends to attract more/better pros for improved acceptance rates, quality, and reliability.': {
        pt: 'Estes preços aplicam-se a pedidos de trabalho feitos a profissionais que você não adicionou, usando a opção Encontrar Novo Profissional. Preços mais altos tendem a atrair mais/melhores profissionais para melhorar as taxas de aceitação, qualidade e confiabilidade.',
        es: 'Estos precios se aplican a las solicitudes de trabajo realizadas a profesionales que no ha agregado, utilizando la opción Encontrar Nuevo Profesional. Los precios más altos tienden a atraer a más/mejores profesionales para mejorar las tasas de aceptación, calidad y confiabilidad.',
        ru: 'Эти цены применяются к запросам на работу, сделанным профессионалам, которых вы не добавили, используя опцию Найти Нового Профессионала. Более высокие цены, как правило, привлекают больше/лучших профессионалов для улучшения показателей принятия, качества и надежности.',
        fr: 'Ces prix s\'appliquent aux demandes de travail faites aux pros que vous n\'avez pas ajoutés, en utilisant l\'option Trouver un Nouveau Pro. Des prix plus élevés ont tendance à attirer plus/de meilleurs pros pour améliorer les taux d\'acceptation, la qualité et la fiabilité.',
        de: 'Diese Preise gelten für Arbeitsanfragen an Profis, die Sie nicht hinzugefügt haben, unter Verwendung der Option Neuen Profi Finden. Höhere Preise tendieren dazu, mehr/bessere Profis anzuziehen, um die Akzeptanzraten, Qualität und Zuverlässigkeit zu verbessern.'
    },
    'Select Property': {
        pt: 'Selecionar Propriedade',
        es: 'Seleccionar Propiedad',
        ru: 'Выбрать Объект',
        fr: 'Sélectionner la Propriété',
        de: 'Objekt Auswählen'
    },
    'Select Frequency': {
        pt: 'Selecionar Frequência',
        es: 'Seleccionar Frecuencia',
        ru: 'Выбрать Частоту',
        fr: 'Sélectionner la Fréquence',
        de: 'Häufigkeit Auswählen'
    },
    'Select Billing Preference': {
        pt: 'Selecionar Preferência de Faturamento',
        es: 'Seleccionar Preferencia de Facturación',
        ru: 'Выбрать Предпочтение по Выставлению Счетов',
        fr: 'Sélectionner la Préférence de Facturation',
        de: 'Abrechnungspräferenz Auswählen'
    },
    '<b>Your Current Selected Prices</b>': {
        pt: '<b>Seus Preços Selecionados Atualmente</b>',
        es: '<b>Sus Precios Seleccionados Actualmente</b>',
        ru: '<b>Ваши Текущие Выбранные Цены</b>',
        fr: '<b>Vos Prix Actuellement Sélectionnés</b>',
        de: '<b>Ihre Aktuell Ausgewählten Preise</b>'
    },
    'You have auto-bid enabled. These prices use auto-bid, which dynamically allocate your funds across cleaners and standbys, to improve reliability. You can set your price for anything. Just reach out to an account manager with any questions.': {
        pt: 'Você tem o lance automático ativado. Esses preços usam lance automático, que aloca dinamicamente seus fundos entre faxineiros e reservas, para melhorar a confiabilidade. Você pode definir seu preço para qualquer coisa. Basta entrar em contato com um gerente de conta para quaisquer dúvidas.',
        es: 'Tiene habilitada la oferta automática. Estos precios utilizan la oferta automática, que asigna dinámicamente sus fondos entre limpiadores y suplentes, para mejorar la confiabilidad. Puede establecer su precio para cualquier cosa. Simplemente comuníquese con un gerente de cuenta si tiene alguna pregunta.',
        ru: 'У вас включена автоматическая ставка. Эти цены используют автоматическую ставку, которая динамически распределяет ваши средства между уборщиками и резервными работниками для повышения надежности. Вы можете установить свою цену на что угодно. Просто обратитесь к менеджеру по работе с клиентами с любыми вопросами.',
        fr: 'Vous avez activé l\'enchère automatique. Ces prix utilisent l\'enchère automatique, qui alloue dynamiquement vos fonds entre les nettoyeurs et les remplaçants, pour améliorer la fiabilité. Vous pouvez fixer votre prix pour n\'importe quoi. Il vous suffit de contacter un gestionnaire de compte pour toute question.',
        de: 'Sie haben das automatische Bieten aktiviert. Diese Preise verwenden automatisches Bieten, das Ihre Mittel dynamisch zwischen Reinigungskräften und Bereitschaftskräften aufteilt, um die Zuverlässigkeit zu verbessern. Sie können Ihren Preis für alles festlegen. Wenden Sie sich einfach bei Fragen an einen Kundenbetreuer.'
    },
    'Cleaning Service': {
        pt: 'Serviço de Limpeza',
        es: 'Servicio de Limpieza',
        ru: 'Услуга Уборки',
        fr: 'Service de Nettoyage',
        de: 'Reinigungsservice'
    },
    'Recurring': {
        pt: 'Recorrente',
        es: 'Recurrente',
        ru: 'Повторяющийся',
        fr: 'Récurrent',
        de: 'Wiederkehrend'
    },
    'Same Day': {
        pt: 'Mesmo Dia',
        es: 'Mismo Día',
        ru: 'В Тот Же День',
        fr: 'Le Même Jour',
        de: 'Am Selben Tag'
    },
    'Charge Before': {
        pt: 'Cobrar Antes',
        es: 'Cobrar Antes',
        ru: 'Взимать Плату До',
        fr: 'Facturer Avant',
        de: 'Vor der Leistung Berechnen'
    },
    'Charge After': {
        pt: 'Cobrar Depois',
        es: 'Cobrar Después',
        ru: 'Взимать Плату После',
        fr: 'Facturer Après',
        de: 'Nach der Leistung Berechnen'
    },

    //PastJobPage
    'Past Job': {
        pt: 'Trabalho Anterior',
        es: 'Trabajo Anterior',
        ru: 'Прошлая Работа',
        fr: 'Travail Passé',
        de: 'Vergangener Auftrag'
    },
    'Print PDF': {
        pt: 'Imprimir PDF',
        es: 'Imprimir PDF',
        ru: 'Печать PDF',
        fr: 'Imprimer PDF',
        de: 'PDF Drucken'
    },
    'Cleaning canceled by you': {
        pt: 'Limpeza cancelada por você',
        es: 'Limpieza cancelada por usted',
        ru: 'Уборка отменена вами',
        fr: 'Nettoyage annulé par vous',
        de: 'Reinigung von Ihnen storniert'
    },
    'No Messages': {
        pt: 'Sem Mensagens',
        es: 'Sin Mensajes',
        ru: 'Нет Сообщений',
        fr: 'Pas de Messages',
        de: 'Keine Nachrichten'
    },
    'View': {
        pt: 'Ver',
        es: 'Ver',
        ru: 'Просмотр',
        fr: 'Voir',
        de: 'Ansehen'
    },
    'More Updates': {
        pt: 'Mais Atualizações',
        es: 'Más Actualizaciones',
        ru: 'Больше Обновлений',
        fr: 'Plus de Mises à Jour',
        de: 'Weitere Updates'
    },
    'More Update': {
        pt: 'Mais Atualização',
        es: 'Más Actualización',
        ru: 'Больше Обновлений',
        fr: 'Plus de Mise à Jour',
        de: 'Weiteres Update'
    },
    'Never Arrived': {
        pt: 'Nunca Chegou',
        es: 'Nunca Llegó',
        ru: 'Никогда Не Прибыл',
        fr: 'Jamais Arrivé',
        de: 'Nie Angekommen'
    },
    'Missing an Item?': {
        pt: 'Falta Algum Item?',
        es: '¿Falta Algún Artículo?',
        ru: 'Не Хватает Предмета?',
        fr: 'Un Article Manquant ?',
        de: 'Fehlt ein Gegenstand?'
    },
    'View / Print Invoice': {
        pt: 'Ver / Imprimir Fatura',
        es: 'Ver / Imprimir Factura',
        ru: 'Просмотр / Печать Счета',
        fr: 'Voir / Imprimer la Facture',
        de: 'Rechnung Ansehen / Drucken'
    },
    'Billing Breakdown': {
        pt: 'Detalhamento da Cobrança',
        es: 'Desglose de Facturación',
        ru: 'Разбивка Счета',
        fr: 'Détail de la Facturation',
        de: 'Abrechnungsaufschlüsselung'
    },
    'What you were billed for this.': {
        pt: 'O que foi cobrado por isso.',
        es: 'Lo que se le facturó por esto.',
        ru: 'Что вам выставили счет за это.',
        fr: 'Ce qui vous a été facturé pour cela.',
        de: 'Was Ihnen dafür in Rechnung gestellt wurde.'
    },
    'Cost': {
        pt: 'Custo',
        es: 'Costo',
        ru: 'Стоимость',
        fr: 'Coût',
        de: 'Kosten'
    },
    '(paid in full)': {
        pt: '(pago integralmente)',
        es: '(pagado en su totalidad)',
        ru: '(оплачено полностью)',
        fr: '(payé intégralement)',
        de: '(vollständig bezahlt)'
    },
    '(cancellation fee)': {
        pt: '(taxa de cancelamento)',
        es: '(cargo por cancelación)',
        ru: '(плата за отмену)',
        fr: '(frais d\'annulation)',
        de: '(Stornierungsgebühr)'
    },
    '(paid in half)': {
        pt: '(pago pela metade)',
        es: '(pagado a la mitad)',
        ru: '(оплачено наполовину)',
        fr: '(payé à moitié)',
        de: '(zur Hälfte bezahlt)'
    },
    'Tip': {
        pt: 'Gorjeta',
        es: 'Propina',
        ru: 'Чаевые',
        fr: 'Pourboire',
        de: 'Trinkgeld'
    },
    'Edit Tip': {
        pt: 'Editar Gorjeta',
        es: 'Editar Propina',
        ru: 'Изменить Чаевые',
        fr: 'Modifier le Pourboire',
        de: 'Trinkgeld Bearbeiten'
    },
    'Parking Reimbursement for': {
        pt: 'Reembolso de Estacionamento para',
        es: 'Reembolso de Estacionamiento para',
        ru: 'Возмещение за Парковку для',
        fr: 'Remboursement de Stationnement pour',
        de: 'Parkplatzerstattung für'
    },
    'Payment Breakdown': {
        pt: 'Detalhamento do Pagamento',
        es: 'Desglose del Pago',
        ru: 'Разбивка Платежа',
        fr: 'Détail du Paiement',
        de: 'Zahlungsaufschlüsselung'
    },
    'What you already paid for this.': {
        pt: 'O que você já pagou por isso.',
        es: 'Lo que ya pagó por esto.',
        ru: 'Что вы уже заплатили за это.',
        fr: 'Ce que vous avez déjà payé pour cela.',
        de: 'Was Sie dafür bereits bezahlt haben.'
    },
    'of': {
        pt: 'de',
        es: 'de',
        ru: 'из',
        fr: 'de',
        de: 'von'
    },
    'charge': {
        pt: 'cobrança',
        es: 'cargo',
        ru: 'плата',
        fr: 'frais',
        de: 'Gebühr'
    },
    'Before Photos / Videos': {
        pt: 'Fotos / Vídeos Antes',
        es: 'Fotos / Videos Antes',
        ru: 'Фото / Видео До',
        fr: 'Photos / Vidéos Avant',
        de: 'Fotos / Videos Vorher'
    },
    'Upload Photo': {
        pt: 'Carregar Foto',
        es: 'Subir Foto',
        ru: 'Загрузить Фото',
        fr: 'Télécharger Photo',
        de: 'Foto Hochladen'
    },
    'Upload Video': {
        pt: 'Carregar Vídeo',
        es: 'Subir Video',
        ru: 'Загрузить Видео',
        fr: 'Télécharger Vidéo',
        de: 'Video Hochladen'
    },
    'Add Video': {
        pt: 'Adicionar Vídeo',
        es: 'Añadir Video',
        ru: 'Добавить Видео',
        fr: 'Ajouter Vidéo',
        de: 'Video Hinzufügen'
    },
    'Uploaded by:': {
        pt: 'Carregado por:',
        es: 'Subido por:',
        ru: 'Загружено:',
        fr: 'Téléchargé par:',
        de: 'Hochgeladen von:'
    },
    'Location:': {
        pt: 'Localização:',
        es: 'Ubicación:',
        ru: 'Местоположение:',
        fr: 'Emplacement:',
        de: 'Standort:'
    },
    'Timestamp:': {
        pt: 'Marca temporal:',
        es: 'Marca de tiempo:',
        ru: 'Временная метка:',
        fr: 'Horodatage:',
        de: 'Zeitstempel:'
    },
    'No value added by pro': {
        pt: 'Nenhum valor adicionado pelo profissional',
        es: 'Ningún valor añadido por el profesional',
        ru: 'Профессионал не добавил ценности',
        fr: 'Aucune valeur ajoutée par le professionnel',
        de: 'Kein Mehrwert durch den Profi'
    },
    'No photo taken by pro': {
        pt: 'Nenhuma foto tirada pelo profissional',
        es: 'Ninguna foto tomada por el profesional',
        ru: 'Профессионал не сделал фото',
        fr: 'Aucune photo prise par le professionnel',
        de: 'Kein Foto vom Profi gemacht'
    },
    'After Photos / Videos': {
        pt: 'Fotos / Vídeos Depois',
        es: 'Fotos / Videos Después',
        ru: 'Фото / Видео После',
        fr: 'Photos / Vidéos Après',
        de: 'Fotos / Videos Nachher'
    },
    'Video': {
        pt: 'Vídeo',
        es: 'Video',
        ru: 'Видео',
        fr: 'Vidéo',
        de: 'Video'
    },
    'Error taking video. Please try again.': {
        pt: 'Erro ao gravar vídeo. Por favor, tente novamente.',
        es: 'Error al tomar el video. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка при съемке видео. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors de la prise de vidéo. Veuillez réessayer.',
        de: 'Fehler bei der Videoaufnahme. Bitte versuchen Sie es erneut.'
    },
    'Error uploading video. Please try again.': {
        pt: 'Erro ao carregar vídeo. Por favor, tente novamente.',
        es: 'Error al subir el video. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка при загрузке видео. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors du téléchargement de la vidéo. Veuillez réessayer.',
        de: 'Fehler beim Hochladen des Videos. Bitte versuchen Sie es erneut.'
    },
    'Remove photo?': {
        pt: 'Remover foto?',
        es: '¿Eliminar foto?',
        ru: 'Удалить фото?',
        fr: 'Supprimer la photo?',
        de: 'Foto entfernen?'
    },
    'Remove video?': {
        pt: 'Remover vídeo?',
        es: '¿Eliminar video?',
        ru: 'Удалить видео?',
        fr: 'Supprimer la vidéo?',
        de: 'Video entfernen?'
    },
    'Error uploading photo. Please try again.': {
        pt: 'Erro ao carregar foto. Por favor, tente novamente.',
        es: 'Error al subir la foto. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка при загрузке фото. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors du téléchargement de la photo. Veuillez réessayer.',
        de: 'Fehler beim Hochladen des Fotos. Bitte versuchen Sie es erneut.'
    },
    'Error removing photo. Please try again.': {
        pt: 'Erro ao remover foto. Por favor, tente novamente.',
        es: 'Error al eliminar la foto. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка при удалении фото. Пожалуйста, попробуйте еще раз.',
        fr: 'Erreur lors de la suppression de la photo. Veuillez réessayer.',
        de: 'Fehler beim Entfernen des Fotos. Bitte versuchen Sie es erneut.'
    },

    //EditTipPage
    'Enter the new tip amount here. You can edit tips for 48 hours.': {
        pt: 'Insira o novo valor da gorjeta aqui. Você pode editar gorjetas por 48 horas.',
        es: 'Ingrese el nuevo monto de propina aquí. Puede editar las propinas durante 48 horas.',
        ru: 'Введите новую сумму чаевых здесь. Вы можете редактировать чаевые в течение 48 часов.',
        fr: 'Entrez le nouveau montant du pourboire ici. Vous pouvez modifier les pourboires pendant 48 heures.',
        de: 'Geben Sie hier den neuen Trinkgeldbetrag ein. Sie können Trinkgelder 48 Stunden lang bearbeiten.'
    },
    'New Amount': {
        pt: 'Novo Valor',
        es: 'Nuevo Monto',
        ru: 'Новая Сумма',
        fr: 'Nouveau Montant',
        de: 'Neuer Betrag'
    },
    'Please enter a valid amount.': {
        pt: 'Por favor, insira um valor válido.',
        es: 'Por favor, ingrese un monto válido.',
        ru: 'Пожалуйста, введите действительную сумму.',
        fr: 'Veuillez entrer un montant valide.',
        de: 'Bitte geben Sie einen gültigen Betrag ein.'
    },

    //LostItemPage
    'Lost Item': {
        pt: 'Item Perdido',
        es: 'Objeto Perdido',
        ru: 'Потерянная Вещь',
        fr: 'Objet Perdu',
        de: 'Verlorener Gegenstand'
    },
    'Please confirm that you are missing an item and we will notify the other party.': {
        pt: 'Por favor, confirme que você perdeu um item e nós notificaremos a outra parte.',
        es: 'Por favor, confirme que le falta un objeto y notificaremos a la otra parte.',
        ru: 'Пожалуйста, подтвердите, что у вас пропал предмет, и мы уведомим другую сторону.',
        fr: 'Veuillez confirmer que vous avez perdu un objet et nous en informerons l\'autre partie.',
        de: 'Bitte bestätigen Sie, dass Ihnen ein Gegenstand fehlt, und wir werden die andere Partei benachrichtigen.'
    },
    'Describe the lost item.': {
        pt: 'Descreva o item perdido.',
        es: 'Describa el objeto perdido.',
        ru: 'Опишите потерянный предмет.',
        fr: 'Décrivez l\'objet perdu.',
        de: 'Beschreiben Sie den verlorenen Gegenstand.'
    },
    'Please describe the lost item.': {
        pt: 'Por favor, descreva o item perdido.',
        es: 'Por favor, describa el objeto perdido.',
        ru: 'Пожалуйста, опишите потерянный предмет.',
        fr: 'Veuillez décrire l\'objet perdu.',
        de: 'Bitte beschreiben Sie den verlorenen Gegenstand.'
    },
    'Pro Notified': {
        pt: 'Profissional Notificado',
        es: 'Profesional Notificado',
        ru: 'Профессионал Уведомлен',
        fr: 'Professionnel Notifié',
        de: 'Profi Benachrichtigt'
    },

    //PastJobsPage
    'Job History': {
        pt: 'Histórico de Trabalhos',
        es: 'Historial de Trabajos',
        ru: 'История Работ',
        fr: 'Historique des Travaux',
        de: 'Arbeitshistorie'
    },
    'Only past jobs that were billed will show in your job history.': {
        pt: 'Apenas trabalhos passados que foram faturados aparecerão no seu histórico de trabalhos.',
        es: 'Solo los trabajos pasados que se facturaron se mostrarán en su historial de trabajos.',
        ru: 'В вашей истории работ будут отображаться только прошлые работы, которые были оплачены.',
        fr: 'Seuls les travaux passés qui ont été facturés apparaîtront dans votre historique des travaux.',
        de: 'Nur vergangene Aufträge, die abgerechnet wurden, werden in Ihrer Arbeitshistorie angezeigt.'
    },
    'For billing history,': {
        pt: 'Para histórico de faturamento,',
        es: 'Para el historial de facturación,',
        ru: 'Для истории выставления счетов,',
        fr: 'Pour l\'historique de facturation,',
        de: 'Für die Abrechnungshistorie,'
    },
    'You have no past jobs': {
        pt: 'Você não tem trabalhos passados',
        es: 'No tienes trabajos pasados',
        ru: 'У вас нет прошлых работ',
        fr: 'Vous n\'avez pas de travaux passés',
        de: 'Sie haben keine vergangenen Aufträge'
    },
    'Status:': {
        pt: 'Status:',
        es: 'Estado:',
        ru: 'Статус:',
        fr: 'Statut :',
        de: 'Status:'
    },

    //PastPrivateJobPage
    'Did they finish the job?': {
        pt: 'Eles terminaram o trabalho?',
        es: '¿Terminaron el trabajo?',
        ru: 'Они закончили работу?',
        fr: 'Ont-ils terminé le travail ?',
        de: 'Haben sie den Auftrag abgeschlossen?'
    },
    'This job was cancelled. Talk to your pro for more info.': {
        pt: 'Este trabalho foi cancelado. Fale com seu profissional para mais informações.',
        es: 'Este trabajo fue cancelado. Habla con tu profesional para más información.',
        ru: 'Эта работа была отменена. Поговорите с вашим профессионалом для получения дополнительной информации.',
        fr: 'Ce travail a été annulé. Parlez à votre professionnel pour plus d\'informations.',
        de: 'Dieser Auftrag wurde storniert. Sprechen Sie mit Ihrem Profi für weitere Informationen.'
    },
    'Balance': {
        pt: 'Saldo',
        es: 'Saldo',
        ru: 'Баланс',
        fr: 'Solde',
        de: 'Saldo'
    },
    'Quoted Price': {
        pt: 'Preço Cotado',
        es: 'Precio Cotizado',
        ru: 'Предложенная Цена',
        fr: 'Prix Devisé',
        de: 'Angebotspreis'
    },
    'has not sent you a bill or marked this job as paid. The job was quoted to cost': {
        pt: 'não enviou uma fatura ou marcou este trabalho como pago. O trabalho foi cotado para custar',
        es: 'no te ha enviado una factura o marcado este trabajo como pagado. El trabajo fue cotizado para costar',
        ru: 'не отправил вам счет или не отметил эту работу как оплаченную. Работа была оценена в',
        fr: 'ne vous a pas envoyé de facture ou n\'a pas marqué ce travail comme payé. Le travail a été devisé pour coûter',
        de: 'hat Ihnen keine Rechnung geschickt oder diesen Auftrag nicht als bezahlt markiert. Der Auftrag wurde mit Kosten von'
    },
    'Ask them to send you a bill if want to pay for this job.': {
        pt: 'Peça-lhes para enviar uma fatura se quiser pagar por este trabalho.',
        es: 'Pídeles que te envíen una factura si quieres pagar por este trabajo.',
        ru: 'Попросите их отправить вам счет, если вы хотите оплатить эту работу.',
        fr: 'Demandez-leur de vous envoyer une facture si vous voulez payer pour ce travail.',
        de: 'Bitten Sie sie, Ihnen eine Rechnung zu schicken, wenn Sie für diesen Auftrag bezahlen möchten.'
    },
    'sent you a': {
        pt: 'enviou-lhe uma',
        es: 'te envió una',
        ru: 'отправил вам',
        fr: 'vous a envoyé une',
        de: 'hat Ihnen eine'
    },
    'bill for this job': {
        pt: 'fatura para este trabalho',
        es: 'factura por este trabajo',
        ru: 'счет за эту работу',
        fr: 'facture pour ce travail',
        de: 'Rechnung für diesen Auftrag geschickt'
    },
    'You paid a': {
        pt: 'Você pagou uma',
        es: 'Pagaste una',
        ru: 'Вы оплатили',
        fr: 'Vous avez payé une',
        de: 'Sie haben eine'
    },
    'bill for this bill.': {
        pt: 'fatura para esta conta.',
        es: 'factura por esta cuenta.',
        ru: 'счет за этот счет.',
        fr: 'facture pour cette facture.',
        de: 'Rechnung für diese Rechnung bezahlt.'
    },
    'Rate This Job': {
        pt: 'Avaliar Este Trabalho',
        es: 'Calificar Este Trabajo',
        ru: 'Оценить Эту Работу',
        fr: 'Évaluer Ce Travail',
        de: 'Diesen Auftrag bewerten'
    },
    'Uploaded by: You': {
        pt: 'Carregado por: Você',
        es: 'Subido por: Tú',
        ru: 'Загружено: Вами',
        fr: 'Téléchargé par : Vous',
        de: 'Hochgeladen von: Ihnen'
    },
    'Input:': {
        pt: 'Entrada:',
        es: 'Entrada:',
        ru: 'Ввод:',
        fr: 'Entrée :',
        de: 'Eingabe:'
    },

    //PaymentOptionsPage
    'Payments Options': {
        pt: 'Opções de Pagamento',
        es: 'Opciones de Pago',
        ru: 'Варианты Оплаты',
        fr: 'Options de Paiement',
        de: 'Zahlungsoptionen'
    },
    'Ending in': {
        pt: 'Terminando em',
        es: 'Terminando en',
        ru: 'Оканчивается на',
        fr: 'Se terminant par',
        de: 'Endet auf'
    },
    'No card on file': {
        pt: 'Nenhum cartão registrado',
        es: 'Sin tarjeta registrada',
        ru: 'Нет сохраненной карты',
        fr: 'Aucune carte enregistrée',
        de: 'Keine Karte hinterlegt'
    },
    'Payment Settings': {
        pt: 'Configurações de Pagamento',
        es: 'Configuración de Pago',
        ru: 'Настройки Оплаты',
        fr: 'Paramètres de Paiement',
        de: 'Zahlungseinstellungen'
    },
    'Charge after jobs are done': {
        pt: 'Cobrar após a conclusão dos trabalhos',
        es: 'Cobrar después de que se realicen los trabajos',
        ru: 'Оплата после выполнения работ',
        fr: 'Facturer après la fin des travaux',
        de: 'Nach Abschluss der Arbeiten berechnen'
    },
    'Buy a Bundle (save 10%)': {
        pt: 'Comprar um Pacote (economize 10%)',
        es: 'Comprar un Paquete (ahorre 10%)',
        ru: 'Купить Пакет (экономия 10%)',
        fr: 'Acheter un Forfait (économisez 10%)',
        de: 'Ein Paket kaufen (10% sparen)'
    },
    'Use a Gift Card': {
        pt: 'Usar um Cartão-Presente',
        es: 'Usar una Tarjeta de Regalo',
        ru: 'Использовать Подарочную Карту',
        fr: 'Utiliser une Carte Cadeau',
        de: 'Eine Geschenkkarte verwenden'
    },
    'View Your Current Prices': {
        pt: 'Ver Seus Preços Atuais',
        es: 'Ver Sus Precios Actuales',
        ru: 'Посмотреть Ваши Текущие Цены',
        fr: 'Voir Vos Prix Actuels',
        de: 'Ihre aktuellen Preise anzeigen'
    },
    'Subscription': {
        pt: 'Assinatura',
        es: 'Suscripción',
        ru: 'Подписка',
        fr: 'Abonnement',
        de: 'Abonnement'
    },

    //PaymentSettingsPage
    'This setting only applies to new pros that you find via TIDY, not pros you add.': {
        pt: 'Esta configuração se aplica apenas a novos profissionais que você encontra através do TIDY, não a profissionais que você adiciona.',
        es: 'Esta configuración solo se aplica a los nuevos profesionales que encuentre a través de TIDY, no a los profesionales que agregue.',
        ru: 'Эта настройка применяется только к новым специалистам, которых вы находите через TIDY, а не к специалистам, которых вы добавляете.',
        fr: 'Ce paramètre s\'applique uniquement aux nouveaux professionnels que vous trouvez via TIDY, pas aux professionnels que vous ajoutez.',
        de: 'Diese Einstellung gilt nur für neue Profis, die Sie über TIDY finden, nicht für Profis, die Sie hinzufügen.'
    },
    'You are not allowed to change to charge after': {
        pt: 'Você não tem permissão para mudar para cobrar depois',
        es: 'No se le permite cambiar a cobrar después',
        ru: 'Вам не разрешено изменять на оплату после',
        fr: 'Vous n\'êtes pas autorisé à changer pour facturer après',
        de: 'Es ist Ihnen nicht erlaubt, auf Nachberechnung zu ändern'
    },
    'You can change your payment settings only after booking a job.': {
        pt: 'Você pode alterar suas configurações de pagamento apenas após reservar um trabalho.',
        es: 'Puede cambiar su configuración de pago solo después de reservar un trabajo.',
        ru: 'Вы можете изменить настройки оплаты только после бронирования работы.',
        fr: 'Vous ne pouvez modifier vos paramètres de paiement qu\'après avoir réservé un travail.',
        de: 'Sie können Ihre Zahlungseinstellungen erst ändern, nachdem Sie einen Auftrag gebucht haben.'
    },
    'Payment Settings Updated': {
        pt: 'Configurações de Pagamento Atualizadas',
        es: 'Configuración de Pago Actualizada',
        ru: 'Настройки Оплаты Обновлены',
        fr: 'Paramètres de Paiement Mis à Jour',
        de: 'Zahlungseinstellungen Aktualisiert'
    },

    //UseGiftCardPage
    'Use Gift Card': {
        pt: 'Usar Cartão Presente',
        es: 'Usar Tarjeta de Regalo',
        ru: 'Использовать Подарочную Карту',
        fr: 'Utiliser une Carte Cadeau',
        de: 'Geschenkkarte Verwenden'
    },
    'Enter your gift code, and we will apply it to your account. Credit from gifts are always used before charging your card': {
        pt: 'Digite seu código de presente e nós o aplicaremos à sua conta. O crédito de presentes é sempre usado antes de cobrar seu cartão',
        es: 'Ingrese su código de regalo y lo aplicaremos a su cuenta. El crédito de los regalos siempre se usa antes de cargar su tarjeta',
        ru: 'Введите ваш подарочный код, и мы применим его к вашему аккаунту. Кредит от подарков всегда используется перед списанием средств с вашей карты',
        fr: 'Entrez votre code cadeau et nous l\'appliquerons à votre compte. Le crédit des cadeaux est toujours utilisé avant de facturer votre carte',
        de: 'Geben Sie Ihren Geschenkcode ein, und wir werden ihn auf Ihr Konto anwenden. Guthaben aus Geschenken wird immer verwendet, bevor Ihre Karte belastet wird'
    },
    'Gift Card Code': {
        pt: 'Código do Cartão Presente',
        es: 'Código de Tarjeta de Regalo',
        ru: 'Код Подарочной Карты',
        fr: 'Code de Carte Cadeau',
        de: 'Geschenkkarten-Code'
    },
    'Apply Code': {
        pt: 'Aplicar Código',
        es: 'Aplicar Código',
        ru: 'Применить Код',
        fr: 'Appliquer le Code',
        de: 'Code Anwenden'
    },
    'Gift Code Applied!': {
        pt: 'Código de Presente Aplicado!',
        es: '¡Código de Regalo Aplicado!',
        ru: 'Подарочный Код Применен!',
        fr: 'Code Cadeau Appliqué !',
        de: 'Geschenkcode Angewendet!'
    },
    'The credit has been applied to your account and will automatically apply to your next scheduled job. You can see the credit anytime in your billing history.': {
        pt: 'O crédito foi aplicado à sua conta e será automaticamente aplicado ao seu próximo trabalho agendado. Você pode ver o crédito a qualquer momento no seu histórico de faturamento.',
        es: 'El crédito se ha aplicado a su cuenta y se aplicará automáticamente a su próximo trabajo programado. Puede ver el crédito en cualquier momento en su historial de facturación.',
        ru: 'Кредит был применен к вашему аккаунту и будет автоматически применен к вашей следующей запланированной работе. Вы можете увидеть кредит в любое время в вашей истории платежей.',
        fr: 'Le crédit a été appliqué à votre compte et s\'appliquera automatiquement à votre prochain travail programmé. Vous pouvez voir le crédit à tout moment dans votre historique de facturation.',
        de: 'Das Guthaben wurde Ihrem Konto gutgeschrieben und wird automatisch auf Ihren nächsten geplanten Auftrag angewendet. Sie können das Guthaben jederzeit in Ihrem Abrechnungsverlauf einsehen.'
    },

    //WebhooksPage
    'Add webhooks and we will send events to that url.': {
        pt: 'Adicione webhooks e enviaremos eventos para essa URL.',
        es: 'Agregue webhooks y enviaremos eventos a esa URL.',
        ru: 'Добавьте вебхуки, и мы отправим события на этот URL.',
        fr: 'Ajoutez des webhooks et nous enverrons des événements à cette URL.',
        de: 'Fügen Sie Webhooks hinzu, und wir senden Ereignisse an diese URL.'
    },
    'Add Endpoint': {
        pt: 'Adicionar Endpoint',
        es: 'Agregar Endpoint',
        ru: 'Добавить Конечную Точку',
        fr: 'Ajouter un Point de Terminaison',
        de: 'Endpunkt Hinzufügen'
    },
    'Endpoints Receiving Events': {
        pt: 'Endpoints Recebendo Eventos',
        es: 'Endpoints Recibiendo Eventos',
        ru: 'Конечные Точки, Получающие События',
        fr: 'Points de Terminaison Recevant des Événements',
        de: 'Endpunkte, die Ereignisse Empfangen'
    },
    'No Active Webhooks': {
        pt: 'Sem Webhooks Ativos',
        es: 'Sin Webhooks Activos',
        ru: 'Нет Активных Вебхуков',
        fr: 'Aucun Webhook Actif',
        de: 'Keine Aktiven Webhooks'
    },
    'Standard Webhook': {
        pt: 'Webhook Padrão',
        es: 'Webhook Estándar',
        ru: 'Стандартный Вебхук',
        fr: 'Webhook Standard',
        de: 'Standard-Webhook'
    },
    'Slack Webhook': {
        pt: 'Webhook do Slack',
        es: 'Webhook de Slack',
        ru: 'Вебхук Slack',
        fr: 'Webhook Slack',
        de: 'Slack-Webhook'
    },
    'Listening to events:': {
        pt: 'Ouvindo eventos:',
        es: 'Escuchando eventos:',
        ru: 'Прослушивание событий:',
        fr: 'Écoute des événements :',
        de: 'Ereignisse abhören:'
    },

    //AddWebhookPage
    'Add Webhook Endpoint': {
        pt: 'Adicionar Endpoint de Webhook',
        es: 'Agregar Endpoint de Webhook',
        ru: 'Добавить Конечную Точку Вебхука',
        fr: 'Ajouter un Point de Terminaison Webhook',
        de: 'Webhook-Endpunkt Hinzufügen'
    },
    'Team to notify': {
        pt: 'Equipe a notificar',
        es: 'Equipo a notificar',
        ru: 'Команда для уведомления',
        fr: 'Équipe à notifier',
        de: 'Zu benachrichtigendes Team'
    },
    'Webhook channel:': {
        pt: 'Canal do Webhook:',
        es: 'Canal de Webhook:',
        ru: 'Канал Вебхука:',
        fr: 'Canal Webhook :',
        de: 'Webhook-Kanal:'
    },
    'Events to listen to:': {
        pt: 'Eventos para ouvir:',
        es: 'Eventos a escuchar:',
        ru: 'События для прослушивания:',
        fr: 'Événements à écouter :',
        de: 'Zu überwachende Ereignisse:'
    },
    'Please select events to listen to.': {
        pt: 'Por favor, selecione os eventos para ouvir.',
        es: 'Por favor, seleccione los eventos a escuchar.',
        ru: 'Пожалуйста, выберите события для прослушивания.',
        fr: 'Veuillez sélectionner les événements à écouter.',
        de: 'Bitte wählen Sie die zu überwachenden Ereignisse aus.'
    },

    //EditWebhookPage
    'Edit Webhook': {
        pt: 'Editar Webhook',
        es: 'Editar Webhook',
        ru: 'Редактировать Вебхук',
        fr: 'Modifier le Webhook',
        de: 'Webhook Bearbeiten'
    },
    'Changes Saved': {
        pt: 'Alterações Salvas',
        es: 'Cambios Guardados',
        ru: 'Изменения Сохранены',
        fr: 'Modifications Enregistrées',
        de: 'Änderungen Gespeichert'
    },

    //WebhookAttemptPage
    'Attempt Details': {
        pt: 'Detalhes da Tentativa',
        es: 'Detalles del Intento',
        ru: 'Детали Попытки',
        fr: 'Détails de la Tentative',
        de: 'Versuchsdetails'
    },
    'The request to your endpoint was successfull!': {
        pt: 'A solicitação para o seu endpoint foi bem-sucedida!',
        es: '¡La solicitud a su punto final fue exitosa!',
        ru: 'Запрос к вашей конечной точке был успешным!',
        fr: 'La requête vers votre point de terminaison a réussi !',
        de: 'Die Anfrage an Ihren Endpunkt war erfolgreich!'
    },
    'The request to your endpoint failed. Please check that the url you added is correct and reach out to the concierge with any questions.': {
        pt: 'A solicitação para o seu endpoint falhou. Por favor, verifique se a URL que você adicionou está correta e entre em contato com o concierge se tiver alguma dúvida.',
        es: 'La solicitud a su punto final falló. Por favor, verifique que la URL que agregó sea correcta y comuníquese con el conserje si tiene alguna pregunta.',
        ru: 'Запрос к вашей конечной точке не удался. Пожалуйста, проверьте правильность добавленного URL-адреса и обратитесь к консьержу, если у вас есть вопросы.',
        fr: 'La requête vers votre point de terminaison a échoué. Veuillez vérifier que l\'URL que vous avez ajoutée est correcte et contactez le concierge pour toute question.',
        de: 'Die Anfrage an Ihren Endpunkt ist fehlgeschlagen. Bitte überprüfen Sie, ob die von Ihnen hinzugefügte URL korrekt ist, und wenden Sie sich bei Fragen an den Concierge.'
    },
    'Request to Your Endpoint': {
        pt: 'Solicitação para Seu Endpoint',
        es: 'Solicitud a Su Punto Final',
        ru: 'Запрос к Вашей Конечной Точке',
        fr: 'Requête vers Votre Point de Terminaison',
        de: 'Anfrage an Ihren Endpunkt'
    },
    'Response': {
        pt: 'Resposta',
        es: 'Respuesta',
        ru: 'Ответ',
        fr: 'Réponse',
        de: 'Antwort'
    },
    'Error:': {
        pt: 'Erro:',
        es: 'Error:',
        ru: 'Ошибка:',
        fr: 'Erreur :',
        de: 'Fehler:'
    },

    //WebhookDetailsPage
    'Webhook Details': {
        pt: 'Detalhes do Webhook',
        es: 'Detalles del Webhook',
        ru: 'Детали Вебхука',
        fr: 'Détails du Webhook',
        de: 'Webhook-Details'
    },
    'Test Webhook': {
        pt: 'Testar Webhook',
        es: 'Probar Webhook',
        ru: 'Тестировать Вебхук',
        fr: 'Tester le Webhook',
        de: 'Webhook Testen'
    },
    'Delete Webhook': {
        pt: 'Excluir Webhook',
        es: 'Eliminar Webhook',
        ru: 'Удалить Вебхук',
        fr: 'Supprimer le Webhook',
        de: 'Webhook Löschen'
    },
    'Disable Webhook': {
        pt: 'Desativar Webhook',
        es: 'Deshabilitar Webhook',
        ru: 'Отключить Вебхук',
        fr: 'Désactiver le Webhook',
        de: 'Webhook Deaktivieren'
    },
    'Activate Webhook': {
        pt: 'Ativar Webhook',
        es: 'Activar Webhook',
        ru: 'Активировать Вебхук',
        fr: 'Activer le Webhook',
        de: 'Webhook Aktivieren'
    },
    'Attempts to send an event to your endpoint in the past 15 days': {
        pt: 'Tentativas de enviar um evento para seu endpoint nos últimos 15 dias',
        es: 'Intentos de enviar un evento a su punto final en los últimos 15 días',
        ru: 'Попытки отправить событие на вашу конечную точку за последние 15 дней',
        fr: 'Tentatives d\'envoi d\'un événement à votre point de terminaison au cours des 15 derniers jours',
        de: 'Versuche, ein Ereignis in den letzten 15 Tagen an Ihren Endpunkt zu senden'
    },
    'Webhook Attempts': {
        pt: 'Tentativas de Webhook',
        es: 'Intentos de Webhook',
        ru: 'Попытки Вебхука',
        fr: 'Tentatives de Webhook',
        de: 'Webhook-Versuche'
    },
    'No recent webhook attempts': {
        pt: 'Nenhuma tentativa recente de webhook',
        es: 'No hay intentos recientes de webhook',
        ru: 'Нет недавних попыток вебхука',
        fr: 'Aucune tentative récente de webhook',
        de: 'Keine kürzlichen Webhook-Versuche'
    },
    'Delete Webhook?': {
        pt: 'Excluir Webhook?',
        es: '¿Eliminar Webhook?',
        ru: 'Удалить Вебхук?',
        fr: 'Supprimer le Webhook ?',
        de: 'Webhook Löschen?'
    },
    'Are you sure you want to delete this webhook?': {
        pt: 'Tem certeza de que deseja excluir este webhook?',
        es: '¿Está seguro de que desea eliminar este webhook?',
        ru: 'Вы уверены, что хотите удалить этот вебхук?',
        fr: 'Êtes-vous sûr de vouloir supprimer ce webhook ?',
        de: 'Sind Sie sicher, dass Sie diesen Webhook löschen möchten?'
    },
    'Activate Webhook?': {
        pt: 'Ativar Webhook?',
        es: '¿Activar Webhook?',
        ru: 'Активировать Вебхук?',
        fr: 'Activer le Webhook ?',
        de: 'Webhook Aktivieren?'
    },
    'Are you sure you want to activate this webhook?': {
        pt: 'Tem certeza de que deseja ativar este webhook?',
        es: '¿Está seguro de que desea activar este webhook?',
        ru: 'Вы уверены, что хотите активировать этот вебхук?',
        fr: 'Êtes-vous sûr de vouloir activer ce webhook ?',
        de: 'Sind Sie sicher, dass Sie diesen Webhook aktivieren möchten?'
    },
    'Inactivate Webhook?': {
        pt: 'Inativar Webhook?',
        es: '¿Desactivar Webhook?',
        ru: 'Деактивировать Вебхук?',
        fr: 'Désactiver le Webhook ?',
        de: 'Webhook Deaktivieren?'
    },
    'Are you sure you want to inactivate this webhook?': {
        pt: 'Tem certeza de que deseja inativar este webhook?',
        es: '¿Está seguro de que desea desactivar este webhook?',
        ru: 'Вы уверены, что хотите деактивировать этот вебхук?',
        fr: 'Êtes-vous sûr de vouloir désactiver ce webhook ?',
        de: 'Sind Sie sicher, dass Sie diesen Webhook deaktivieren möchten?'
    },

    //MorePage
    'Missing access info': {
        pt: 'Informações de acesso ausentes',
        es: 'Falta información de acceso',
        ru: 'Отсутствует информация о доступе',
        fr: 'Informations d\'accès manquantes',
        de: 'Fehlende Zugangsinformationen'
    },
    'Missing parking info': {
        pt: 'Informações de estacionamento ausentes',
        es: 'Falta información de estacionamiento',
        ru: 'Отсутствует информация о парковке',
        fr: 'Informations de stationnement manquantes',
        de: 'Fehlende Parkplatzinformationen'
    },
    'View More Properties': {
        pt: 'Ver Mais Propriedades',
        es: 'Ver Más Propiedades',
        ru: 'Посмотреть Больше Объектов',
        fr: 'Voir Plus de Propriétés',
        de: 'Mehr Immobilien Anzeigen'
    },
    'Set your pro priority list.': {
        pt: 'Defina sua lista de prioridades de profissionais.',
        es: 'Establezca su lista de prioridades de profesionales.',
        ru: 'Установите список приоритетов ваших специалистов.',
        fr: 'Définissez votre liste de priorités des professionnels.',
        de: 'Legen Sie Ihre Profi-Prioritätenliste fest.'
    },
    'Users can log into your account with their own credentials. As the account owner, you can revoke access at any time.': {
        pt: 'Os usuários podem fazer login em sua conta com suas próprias credenciais. Como proprietário da conta, você pode revogar o acesso a qualquer momento.',
        es: 'Los usuarios pueden iniciar sesión en su cuenta con sus propias credenciales. Como propietario de la cuenta, puede revocar el acceso en cualquier momento.',
        ru: 'Пользователи могут входить в вашу учетную запись со своими собственными учетными данными. Как владелец учетной записи, вы можете отозвать доступ в любое время.',
        fr: 'Les utilisateurs peuvent se connecter à votre compte avec leurs propres identifiants. En tant que propriétaire du compte, vous pouvez révoquer l\'accès à tout moment.',
        de: 'Benutzer können sich mit ihren eigenen Anmeldedaten in Ihr Konto einloggen. Als Kontoinhaber können Sie den Zugriff jederzeit widerrufen.'
    },
    'Teams': {
        pt: 'Equipes',
        es: 'Equipos',
        ru: 'Команды',
        fr: 'Équipes',
        de: 'Teams'
    },
    'Organize your users and properties into teams to control access, notifications, billing, and reporting.': {
        pt: 'Organize seus usuários e propriedades em equipes para controlar acesso, notificações, faturamento e relatórios.',
        es: 'Organice sus usuarios y propiedades en equipos para controlar el acceso, las notificaciones, la facturación y los informes.',
        ru: 'Организуйте ваших пользователей и объекты в команды для управления доступом, уведомлениями, выставлением счетов и отчетностью.',
        fr: 'Organisez vos utilisateurs et propriétés en équipes pour contrôler l\'accès, les notifications, la facturation et les rapports.',
        de: 'Organisieren Sie Ihre Benutzer und Immobilien in Teams, um Zugriff, Benachrichtigungen, Abrechnung und Berichterstattung zu steuern.'
    },
    'View messages from your pros.': {
        pt: 'Visualize mensagens de seus profissionais.',
        es: 'Ver mensajes de sus profesionales.',
        ru: 'Просмотр сообщений от ваших специалистов.',
        fr: 'Voir les messages de vos professionnels.',
        de: 'Nachrichten von Ihren Profis anzeigen.'
    },
    'Get alerts when items go below your minimum quantity.': {
        pt: 'Receba alertas quando os itens ficarem abaixo da quantidade mínima.',
        es: 'Reciba alertas cuando los artículos estén por debajo de su cantidad mínima.',
        ru: 'Получайте оповещения, когда количество предметов становится ниже минимального.',
        fr: 'Recevez des alertes lorsque les articles passent en dessous de votre quantité minimale.',
        de: 'Erhalten Sie Benachrichtigungen, wenn Artikel unter Ihre Mindestmenge fallen.'
    },
    'Manage your guest reservations.': {
        pt: 'Gerencie as reservas de seus hóspedes.',
        es: 'Administre las reservaciones de sus huéspedes.',
        ru: 'Управляйте бронированиями ваших гостей.',
        fr: 'Gérez les réservations de vos invités.',
        de: 'Verwalten Sie Ihre Gästereservierungen.'
    },
    'Keep track of damage, low inventory and other tasks.': {
        pt: 'Acompanhe danos, baixo estoque e outras tarefas.',
        es: 'Realice un seguimiento de daños, bajo inventario y otras tareas.',
        ru: 'Отслеживайте повреждения, низкий уровень запасов и другие задачи.',
        fr: 'Suivez les dommages, le faible inventaire et autres tâches.',
        de: 'Behalten Sie Schäden, niedrigen Bestand und andere Aufgaben im Auge.'
    },
    'Pay & manage bills from your private pros.': {
        pt: 'Pague e gerencie contas de seus profissionais privados.',
        es: 'Pague y administre facturas de sus profesionales privados.',
        ru: 'Оплачивайте и управляйте счетами от ваших частных специалистов.',
        fr: 'Payez et gérez les factures de vos professionnels privés.',
        de: 'Bezahlen und verwalten Sie Rechnungen von Ihren privaten Profis.'
    },
    'Missing phone number': {
        pt: 'Número de telefone ausente',
        es: 'Falta número de teléfono',
        ru: 'Отсутствует номер телефона',
        fr: 'Numéro de téléphone manquant',
        de: 'Fehlende Telefonnummer'
    },
    'Account History': {
        pt: 'Histórico da Conta',
        es: 'Historial de la Cuenta',
        ru: 'История Аккаунта',
        fr: 'Historique du Compte',
        de: 'Kontoverlauf'
    },
    'Branding': {
        pt: 'Marca',
        es: 'Marca',
        ru: 'Брендинг',
        fr: 'Image de Marque',
        de: 'Branding'
    },
    'Customize the TIDY app with a unique background and logo.': {
        pt: 'Personalize o aplicativo TIDY com um fundo e logotipo únicos.',
        es: 'Personalice la aplicación TIDY con un fondo y logotipo únicos.',
        ru: 'Настройте приложение TIDY с уникальным фоном и логотипом.',
        fr: 'Personnalisez l\'application TIDY avec un arrière-plan et un logo uniques.',
        de: 'Passen Sie die TIDY-App mit einem einzigartigen Hintergrund und Logo an.'
    },
    'Advanced Features': {
        pt: 'Recursos Avançados',
        es: 'Características Avanzadas',
        ru: 'Расширенные Функции',
        fr: 'Fonctionnalités Avancées',
        de: 'Erweiterte Funktionen'
    },
    'Rental Property Features': {
        pt: 'Recursos de Propriedade para Aluguel',
        es: 'Características de Propiedad de Alquiler',
        ru: 'Функции Арендной Недвижимости',
        fr: 'Fonctionnalités de Propriété Locative',
        de: 'Mietobjekt-Funktionen'
    },
    'Integrate TIDY with AirBnb, VRBO, property management tools, Zapier, & more.': {
        pt: 'Integre a TIDY com AirBnb, VRBO, ferramentas de gerenciamento de propriedades, Zapier e mais.',
        es: 'Integre TIDY con AirBnb, VRBO, herramientas de gestión de propiedades, Zapier y más.',
        ru: 'Интегрируйте TIDY с AirBnb, VRBO, инструментами управления недвижимостью, Zapier и другими.',
        fr: 'Intégrez TIDY avec AirBnb, VRBO, des outils de gestion immobilière, Zapier et plus encore.',
        de: 'Integrieren Sie TIDY mit AirBnb, VRBO, Immobilienverwaltungstools, Zapier und mehr.'
    },
    'Automatic Booking Settings': {
        pt: 'Configurações de Reserva Automática',
        es: 'Configuración de Reserva Automática',
        ru: 'Настройки Автоматического Бронирования',
        fr: 'Paramètres de Réservation Automatique',
        de: 'Automatische Buchungseinstellungen'
    },
    'Automatically book jobs for synced reservations.': {
        pt: 'Reservar automaticamente trabalhos para reservas sincronizadas.',
        es: 'Reservar automáticamente trabajos para reservas sincronizadas.',
        ru: 'Автоматически бронировать задания для синхронизированных резервирований.',
        fr: 'Réserver automatiquement des tâches pour les réservations synchronisées.',
        de: 'Automatisch Aufträge für synchronisierte Reservierungen buchen.'
    },
    'Vendor Compliance': {
        pt: 'Conformidade do Fornecedor',
        es: 'Cumplimiento del Proveedor',
        ru: 'Соответствие Поставщика',
        fr: 'Conformité des Fournisseurs',
        de: 'Lieferanten-Compliance'
    },
    'Get your pros compliant with your terms.': {
        pt: 'Faça seus profissionais cumprirem seus termos.',
        es: 'Haga que sus profesionales cumplan con sus términos.',
        ru: 'Обеспечьте соответствие ваших специалистов вашим условиям.',
        fr: 'Assurez-vous que vos professionnels respectent vos conditions.',
        de: 'Bringen Sie Ihre Profis dazu, Ihre Bedingungen einzuhalten.'
    },
    'View reports for jobs, maintenances and reservations.': {
        pt: 'Visualize relatórios de trabalhos, manutenções e reservas.',
        es: 'Ver informes de trabajos, mantenimientos y reservas.',
        ru: 'Просмотр отчетов по работам, обслуживанию и бронированиям.',
        fr: 'Consultez les rapports sur les tâches, les maintenances et les réservations.',
        de: 'Berichte für Aufträge, Wartungen und Reservierungen anzeigen.'
    },
    'Sync your transaction data including payments by teams into QuickBooks for easier accounting.': {
        pt: 'Sincronize seus dados de transação, incluindo pagamentos por equipes, no QuickBooks para uma contabilidade mais fácil.',
        es: 'Sincronice sus datos de transacciones, incluidos los pagos por equipos, en QuickBooks para una contabilidad más fácil.',
        ru: 'Синхронизируйте данные о транзакциях, включая платежи командам, в QuickBooks для упрощения бухгалтерского учета.',
        fr: 'Synchronisez vos données de transaction, y compris les paiements par équipes, dans QuickBooks pour une comptabilité plus facile.',
        de: 'Synchronisieren Sie Ihre Transaktionsdaten einschließlich Teamzahlungen mit QuickBooks für eine einfachere Buchhaltung.'
    },
    'TIDY API & webhooks.': {
        pt: 'API TIDY e webhooks.',
        es: 'API de TIDY y webhooks.',
        ru: 'API TIDY и вебхуки.',
        fr: 'API TIDY et webhooks.',
        de: 'TIDY API & Webhooks.'
    },
    'Payment Options': {
        pt: 'Opções de Pagamento',
        es: 'Opciones de Pago',
        ru: 'Варианты Оплаты',
        fr: 'Options de Paiement',
        de: 'Zahlungsoptionen'
    },
    'Contact Support': {
        pt: 'Contatar Suporte',
        es: 'Contactar Soporte',
        ru: 'Связаться с Поддержкой',
        fr: 'Contacter le Support',
        de: 'Support kontaktieren'
    },
    'Logout': {
        pt: 'Sair',
        es: 'Cerrar Sesión',
        ru: 'Выйти',
        fr: 'Déconnexion',
        de: 'Abmelden'
    },

    //MyProsPage
    'Pro Priorities': {
        pt: 'Prioridades de Profissionais',
        es: 'Prioridades de Profesionales',
        ru: 'Приоритеты Специалистов',
        fr: 'Priorités des Professionnels',
        de: 'Profi-Prioritäten'
    },
    'Settings': {
        pt: 'Configurações',
        es: 'Ajustes',
        ru: 'Настройки',
        fr: 'Paramètres',
        de: 'Einstellungen'
    },
    'You haven\'t added any pros yet.': {
        pt: 'Você ainda não adicionou nenhum profissional.',
        es: 'Aún no has añadido ningún profesional.',
        ru: 'Вы еще не добавили ни одного специалиста.',
        fr: 'Vous n\'avez pas encore ajouté de professionnels.',
        de: 'Sie haben noch keine Profis hinzugefügt.'
    },
    'Favorites': {
        pt: 'Favoritos',
        es: 'Favoritos',
        ru: 'Избранные',
        fr: 'Favoris',
        de: 'Favoriten'
    },
    'Edit Priorities': {
        pt: 'Editar Prioridades',
        es: 'Editar Prioridades',
        ru: 'Редактировать Приоритеты',
        fr: 'Modifier les Priorités',
        de: 'Prioritäten bearbeiten'
    },
    'Warning: this setting decreases the number of Pros in your area.': {
        pt: 'Aviso: esta configuração diminui o número de Profissionais em sua área.',
        es: 'Advertencia: esta configuración disminuye el número de Profesionales en su área.',
        ru: 'Предупреждение: эта настройка уменьшает количество Специалистов в вашем районе.',
        fr: 'Attention : ce paramètre réduit le nombre de Professionnels dans votre région.',
        de: 'Warnung: Diese Einstellung verringert die Anzahl der Profis in Ihrer Umgebung.'
    },
    'No Pros Added': {
        pt: 'Nenhum Profissional Adicionado',
        es: 'No se han añadido Profesionales',
        ru: 'Специалисты не добавлены',
        fr: 'Aucun Professionnel Ajouté',
        de: 'Keine Profis hinzugefügt'
    },
    'Drag to add to approved list.': {
        pt: 'Arraste para adicionar à lista de aprovados.',
        es: 'Arrastra para añadir a la lista de aprobados.',
        ru: 'Перетащите, чтобы добавить в список одобренных.',
        fr: 'Faites glisser pour ajouter à la liste des approuvés.',
        de: 'Ziehen Sie, um zur Liste der Genehmigten hinzuzufügen.'
    },
    'Drag to add to favorites list.': {
        pt: 'Arraste para adicionar à lista de favoritos.',
        es: 'Arrastra para añadir a la lista de favoritos.',
        ru: 'Перетащите, чтобы добавить в список избранных.',
        fr: 'Faites glisser pour ajouter à la liste des favoris.',
        de: 'Ziehen Sie, um zur Favoritenliste hinzuzufügen.'
    },
    'Blocked': {
        pt: 'Bloqueados',
        es: 'Bloqueados',
        ru: 'Заблокированные',
        fr: 'Bloqués',
        de: 'Blockiert'
    },
    'Drag to add to blocked list.': {
        pt: 'Arraste para adicionar à lista de bloqueados.',
        es: 'Arrastra para añadir a la lista de bloqueados.',
        ru: 'Перетащите, чтобы добавить в список заблокированных.',
        fr: 'Faites glisser pour ajouter à la liste des bloqués.',
        de: 'Ziehen Sie, um zur Liste der Blockierten hinzuzufügen.'
    },
    'Want to get your pros compliant with your terms?': {
        pt: 'Quer que seus profissionais cumpram seus termos?',
        es: '¿Quieres que tus profesionales cumplan con tus términos?',
        ru: 'Хотите, чтобы ваши специалисты соответствовали вашим условиям?',
        fr: 'Vous voulez que vos professionnels respectent vos conditions ?',
        de: 'Möchten Sie, dass Ihre Profis Ihre Bedingungen einhalten?'
    },
    'Request Vendor Compliance': {
        pt: 'Solicitar Conformidade do Fornecedor',
        es: 'Solicitar Cumplimiento del Proveedor',
        ru: 'Запросить Соответствие Поставщика',
        fr: 'Demander la Conformité du Fournisseur',
        de: 'Lieferanten-Compliance anfordern'
    },
    'TIDY does not support finding new pros for this service type yet.': {
        pt: 'TIDY ainda não suporta encontrar novos profissionais para este tipo de serviço.',
        es: 'TIDY aún no admite encontrar nuevos profesionales para este tipo de servicio.',
        ru: 'TIDY пока не поддерживает поиск новых специалистов для этого типа услуг.',
        fr: 'TIDY ne prend pas encore en charge la recherche de nouveaux professionnels pour ce type de service.',
        de: 'TIDY unterstützt noch nicht die Suche nach neuen Profis für diese Art von Service.'
    },
    'to request this feature.': {
        pt: 'para solicitar este recurso.',
        es: 'para solicitar esta función.',
        ru: 'чтобы запросить эту функцию.',
        fr: 'pour demander cette fonctionnalité.',
        de: 'um diese Funktion anzufordern.'
    },
    'Prioritize Pros': {
        pt: 'Priorizar Profissionais',
        es: 'Priorizar Profesionales',
        ru: 'Приоритизировать Специалистов',
        fr: 'Prioriser les Professionnels',
        de: 'Profis priorisieren'
    },
    'Once you have had a Pro, this section lets you control who can do your jobs and in what order.': {
        pt: 'Depois de ter tido um Profissional, esta seção permite controlar quem pode fazer seus trabalhos e em que ordem.',
        es: 'Una vez que hayas tenido un Profesional, esta sección te permite controlar quién puede hacer tus trabajos y en qué orden.',
        ru: 'После того, как у вас был Специалист, этот раздел позволяет контролировать, кто может выполнять ваши задания и в каком порядке.',
        fr: 'Une fois que vous avez eu un Professionnel, cette section vous permet de contrôler qui peut effectuer vos travaux et dans quel ordre.',
        de: 'Sobald Sie einen Profi hatten, können Sie in diesem Abschnitt steuern, wer Ihre Aufträge ausführen kann und in welcher Reihenfolge.'
    },
    'Add a pro to send them jobs. It\'s easy and free for them.': {
        pt: 'Adicione um profissional para enviar-lhes trabalhos. É fácil e gratuito para eles.',
        es: 'Añade un profesional para enviarles trabajos. Es fácil y gratis para ellos.',
        ru: 'Добавьте специалиста, чтобы отправлять им задания. Это легко и бесплатно для них.',
        fr: 'Ajoutez un professionnel pour leur envoyer des travaux. C\'est facile et gratuit pour eux.',
        de: 'Fügen Sie einen Profi hinzu, um ihnen Aufträge zu senden. Es ist einfach und kostenlos für sie.'
    },
    'Settings for Finding New Pros': {
        pt: 'Configurações para Encontrar Novos Profissionais',
        es: 'Ajustes para Encontrar Nuevos Profesionales',
        ru: 'Настройки для Поиска Новых Специалистов',
        fr: 'Paramètres pour Trouver de Nouveaux Professionnels',
        de: 'Einstellungen zum Finden neuer Profis'
    },
    'Name': {
        pt: 'Nome',
        es: 'Nombre',
        ru: 'Имя',
        fr: 'Nom',
        de: 'Name'
    },
    'Services': {
        pt: 'Serviços',
        es: 'Servicios',
        ru: 'Услуги',
        fr: 'Services',
        de: 'Dienstleistungen'
    },
    'Last Job': {
        pt: 'Último Trabalho',
        es: 'Último Trabajo',
        ru: 'Последнее Задание',
        fr: 'Dernier Travail',
        de: 'Letzter Auftrag'
    },
    'You must have at least 1 private pro on your list to block Find New Pro.': {
        pt: 'Você deve ter pelo menos 1 profissional privado em sua lista para bloquear Encontrar Novo Profissional.',
        es: 'Debe tener al menos 1 profesional privado en su lista para bloquear Encontrar Nuevo Profesional.',
        ru: 'У вас должен быть как минимум 1 частный специалист в вашем списке, чтобы заблокировать функцию Найти Нового Специалиста.',
        fr: 'Vous devez avoir au moins 1 professionnel privé dans votre liste pour bloquer Trouver un Nouveau Professionnel.',
        de: 'Sie müssen mindestens 1 privaten Profi auf Ihrer Liste haben, um Neuen Profi Finden zu blockieren.'
    },
    'Block Pro?': {
        pt: 'Bloquear Profissional?',
        es: '¿Bloquear Profesional?',
        ru: 'Заблокировать Специалиста?',
        fr: 'Bloquer le Professionnel ?',
        de: 'Profi blockieren?'
    },
    'This means we will not pair you with them.': {
        pt: 'Isso significa que não iremos emparelhá-lo com eles.',
        es: 'Esto significa que no te emparejaremos con ellos.',
        ru: 'Это означает, что мы не будем сопоставлять вас с ними.',
        fr: 'Cela signifie que nous ne vous mettrons pas en relation avec eux.',
        de: 'Das bedeutet, wir werden Sie nicht mit ihnen zusammenbringen.'
    },
    'Block Pro': {
        pt: 'Bloquear Profissional',
        es: 'Bloquear Profesional',
        ru: 'Заблокировать Специалиста',
        fr: 'Bloquer le Professionnel',
        de: 'Profi blockieren'
    },
    'Don\'t Find New Pros?': {
        pt: 'Não Encontrar Novos Profissionais?',
        es: '¿No Buscar Nuevos Profesionales?',
        ru: 'Не Искать Новых Специалистов?',
        fr: 'Ne Pas Trouver de Nouveaux Professionnels ?',
        de: 'Keine Neuen Profis Finden?'
    },
    'This means TIDY will not find new Pros from our high quality network.': {
        pt: 'Isso significa que a TIDY não encontrará novos Profissionais em nossa rede de alta qualidade.',
        es: 'Esto significa que TIDY no encontrará nuevos Profesionales de nuestra red de alta calidad.',
        ru: 'Это означает, что TIDY не будет искать новых Специалистов в нашей высококачественной сети.',
        fr: 'Cela signifie que TIDY ne trouvera pas de nouveaux Professionnels dans notre réseau de haute qualité.',
        de: 'Das bedeutet, TIDY wird keine neuen Profis aus unserem hochwertigen Netzwerk finden.'
    },
    'Feature Requested': {
        pt: 'Recurso Solicitado',
        es: 'Función Solicitada',
        ru: 'Функция Запрошена',
        fr: 'Fonctionnalité Demandée',
        de: 'Funktion Angefordert'
    },
    'TIDY will let you know when this feature is available for you.': {
        pt: 'A TIDY informará você quando este recurso estiver disponível para você.',
        es: 'TIDY te informará cuando esta función esté disponible para ti.',
        ru: 'TIDY сообщит вам, когда эта функция станет доступной для вас.',
        fr: 'TIDY vous informera lorsque cette fonctionnalité sera disponible pour vous.',
        de: 'TIDY wird Sie benachrichtigen, wenn diese Funktion für Sie verfügbar ist.'
    },
    'Feature Already Requested': {
        pt: 'Recurso Já Solicitado',
        es: 'Función Ya Solicitada',
        ru: 'Функция Уже Запрошена',
        fr: 'Fonctionnalité Déjà Demandée',
        de: 'Funktion Bereits Angefordert'
    },
    'You already requested this feature.  TIDY will let you know when this feature is available for you.': {
        pt: 'Você já solicitou este recurso. A TIDY informará você quando este recurso estiver disponível para você.',
        es: 'Ya has solicitado esta función. TIDY te informará cuando esta función esté disponible para ti.',
        ru: 'Вы уже запросили эту функцию. TIDY сообщит вам, когда эта функция станет доступной для вас.',
        fr: 'Vous avez déjà demandé cette fonctionnalité. TIDY vous informera lorsque cette fonctionnalité sera disponible pour vous.',
        de: 'Sie haben diese Funktion bereits angefordert. TIDY wird Sie benachrichtigen, wenn diese Funktion für Sie verfügbar ist.'
    },
    'The pro will be removed from the list for this address for this service type. You cannot undo this action.': {
        pt: 'O profissional será removido da lista para este endereço para este tipo de serviço. Você não pode desfazer esta ação.',
        es: 'El profesional será eliminado de la lista para esta dirección para este tipo de servicio. No puedes deshacer esta acción.',
        ru: 'Специалист будет удален из списка для этого адреса для данного типа услуги. Вы не можете отменить это действие.',
        fr: 'Le professionnel sera retiré de la liste pour cette adresse pour ce type de service. Vous ne pouvez pas annuler cette action.',
        de: 'Der Profi wird für diese Adresse für diesen Servicetyp von der Liste entfernt. Sie können diese Aktion nicht rückgängig machen.'
    },

    //AddProPage
    'Who would you like to add?': {
        pt: 'Quem você gostaria de adicionar?',
        es: '¿A quién te gustaría agregar?',
        ru: 'Кого вы хотели бы добавить?',
        fr: 'Qui souhaitez-vous ajouter ?',
        de: 'Wen möchten Sie hinzufügen?'
    },
    'Where do you want to add this pro?': {
        pt: 'Onde você quer adicionar este profissional?',
        es: '¿Dónde quieres agregar este profesional?',
        ru: 'Куда вы хотите добавить этого специалиста?',
        fr: 'Où voulez-vous ajouter ce professionnel ?',
        de: 'Wo möchten Sie diesen Profi hinzufügen?'
    },
    'Select Properties': {
        pt: 'Selecionar Propriedades',
        es: 'Seleccionar Propiedades',
        ru: 'Выбрать Объекты',
        fr: 'Sélectionner les Propriétés',
        de: 'Eigenschaften Auswählen'
    },
    'Please select at least one property.': {
        pt: 'Por favor, selecione pelo menos uma propriedade.',
        es: 'Por favor, selecciona al menos una propiedad.',
        ru: 'Пожалуйста, выберите хотя бы один объект.',
        fr: 'Veuillez sélectionner au moins une propriété.',
        de: 'Bitte wählen Sie mindestens eine Eigenschaft aus.'
    },
    'Add someone you work with to send them requests to do your jobs.': {
        pt: 'Adicione alguém com quem você trabalha para enviar solicitações para fazer seus trabalhos.',
        es: 'Agrega a alguien con quien trabajas para enviarle solicitudes para hacer tus trabajos.',
        ru: 'Добавьте кого-то, с кем вы работаете, чтобы отправлять им запросы на выполнение ваших работ.',
        fr: 'Ajoutez quelqu\'un avec qui vous travaillez pour lui envoyer des demandes pour effectuer vos travaux.',
        de: 'Fügen Sie jemanden hinzu, mit dem Sie zusammenarbeiten, um ihm Anfragen für Ihre Aufträge zu senden.'
    },
    'Add someone you work with that you want to use your': {
        pt: 'Adicione alguém com quem você trabalha que você quer usar seu',
        es: 'Agrega a alguien con quien trabajas que quieres usar tu',
        ru: 'Добавьте кого-то, с кем вы работаете, кого вы хотите использовать ваш',
        fr: 'Ajoutez quelqu\'un avec qui vous travaillez que vous voulez utiliser votre',
        de: 'Fügen Sie jemanden hinzu, mit dem Sie zusammenarbeiten und der Ihre'
    },
    'to pay.': {
        pt: 'para pagar.',
        es: 'para pagar.',
        ru: 'для оплаты.',
        fr: 'pour payer.',
        de: 'verwenden soll, um zu bezahlen.'
    },
    'Their Name': {
        pt: 'Nome Deles',
        es: 'Su Nombre',
        ru: 'Их Имя',
        fr: 'Leur Nom',
        de: 'Ihr Name'
    },
    'Their Email': {
        pt: 'E-mail Deles',
        es: 'Su Correo Electrónico',
        ru: 'Их Электронная Почта',
        fr: 'Leur Email',
        de: 'Ihre E-Mail'
    },
    'Services Offered': {
        pt: 'Serviços Oferecidos',
        es: 'Servicios Ofrecidos',
        ru: 'Предлагаемые Услуги',
        fr: 'Services Proposés',
        de: 'Angebotene Dienstleistungen'
    },
    'Please select at least one service.': {
        pt: 'Por favor, selecione pelo menos um serviço.',
        es: 'Por favor, selecciona al menos un servicio.',
        ru: 'Пожалуйста, выберите хотя бы одну услугу.',
        fr: 'Veuillez sélectionner au moins un service.',
        de: 'Bitte wählen Sie mindestens einen Service aus.'
    },
    'Select the Pro(s) to add': {
        pt: 'Selecione o(s) Profissional(is) para adicionar',
        es: 'Selecciona el(los) Profesional(es) para agregar',
        ru: 'Выберите Специалиста(ов) для добавления',
        fr: 'Sélectionnez le(s) Professionnel(s) à ajouter',
        de: 'Wählen Sie den/die Profi(s) zum Hinzufügen aus'
    },
    'Please select at least one pro to add.': {
        pt: 'Por favor, selecione pelo menos um profissional para adicionar.',
        es: 'Por favor, selecciona al menos un profesional para agregar.',
        ru: 'Пожалуйста, выберите хотя бы одного специалиста для добавления.',
        fr: 'Veuillez sélectionner au moins un professionnel à ajouter.',
        de: 'Bitte wählen Sie mindestens einen Profi zum Hinzufügen aus.'
    },
    'What Happens Next?': {
        pt: 'O Que Acontece a Seguir?',
        es: '¿Qué Sucede Después?',
        ru: 'Что Будет Дальше?',
        fr: 'Que Se Passe-t-il Ensuite ?',
        de: 'Was Passiert als Nächstes?'
    },
    'Book Call': {
        pt: 'Agendar Chamada',
        es: 'Programar Llamada',
        ru: 'Запланировать Звонок',
        fr: 'Réserver un Appel',
        de: 'Anruf Buchen'
    },
    'New Pro': {
        pt: 'Novo Profissional',
        es: 'Nuevo Profesional',
        ru: 'Новый Специалист',
        fr: 'Nouveau Professionnel',
        de: 'Neuer Profi'
    },
    'Pro(s) from my other address': {
        pt: 'Profissional(is) do meu outro endereço',
        es: 'Profesional(es) de mi otra dirección',
        ru: 'Специалист(ы) с моего другого адреса',
        fr: 'Professionnel(s) de mon autre adresse',
        de: 'Profi(s) von meiner anderen Adresse'
    },
    'Pros Added': {
        pt: 'Profissionais Adicionados',
        es: 'Profesionales Agregados',
        ru: 'Специалисты Добавлены',
        fr: 'Professionnels Ajoutés',
        de: 'Profis Hinzugefügt'
    },
    'Go to My Pros': {
        pt: 'Ir para Meus Profissionais',
        es: 'Ir a Mis Profesionales',
        ru: 'Перейти к Моим Специалистам',
        fr: 'Aller à Mes Professionnels',
        de: 'Zu Meinen Profis Gehen'
    },
    'Pro is Added to Your Priorities': {
        pt: 'Profissional Adicionado às Suas Prioridades',
        es: 'Profesional Agregado a Tus Prioridades',
        ru: 'Специалист Добавлен в Ваши Приоритеты',
        fr: 'Le Professionnel est Ajouté à Vos Priorités',
        de: 'Profi wurde zu Ihren Prioritäten hinzugefügt'
    },
    'Book jobs, send requests, or send them payment for a completed job.': {
        pt: 'Agende trabalhos, envie solicitações ou envie pagamento por um trabalho concluído.',
        es: 'Reserva trabajos, envía solicitudes o envíales el pago por un trabajo completado.',
        ru: 'Бронируйте работы, отправляйте запросы или отправляйте им оплату за выполненную работу.',
        fr: 'Réservez des travaux, envoyez des demandes ou envoyez-leur un paiement pour un travail terminé.',
        de: 'Buchen Sie Aufträge, senden Sie Anfragen oder senden Sie ihnen die Bezahlung für einen abgeschlossenen Auftrag.'
    },
    'No Sign Up Necessary': {
        pt: 'Não é Necessário Cadastro',
        es: 'No es Necesario Registrarse',
        ru: 'Регистрация Не Требуется',
        fr: 'Pas d\'Inscription Nécessaire',
        de: 'Keine Anmeldung Erforderlich'
    },
    'Send a payment to your pro and they\'ll receive an email to accept it.': {
        pt: 'Envie um pagamento ao seu profissional e ele receberá um e-mail para aceitá-lo.',
        es: 'Envía un pago a tu profesional y recibirán un correo electrónico para aceptarlo.',
        ru: 'Отправьте платеж вашему специалисту, и он получит электронное письмо для его принятия.',
        fr: 'Envoyez un paiement à votre professionnel et il recevra un e-mail pour l\'accepter.',
        de: 'Senden Sie eine Zahlung an Ihren Profi, und er erhält eine E-Mail, um sie zu akzeptieren.'
    },
    'Send Payment Now or Later': {
        pt: 'Enviar Pagamento Agora ou Depois',
        es: 'Enviar Pago Ahora o Después',
        ru: 'Отправить Платеж Сейчас или Позже',
        fr: 'Envoyer le Paiement Maintenant ou Plus Tard',
        de: 'Zahlung Jetzt oder Später Senden'
    },
    'Send a payment for a past job or book one for the future.': {
        pt: 'Envie um pagamento por um trabalho passado ou agende um para o futuro.',
        es: 'Envía un pago por un trabajo pasado o reserva uno para el futuro.',
        ru: 'Отправьте платеж за прошлую работу или забронируйте на будущее.',
        fr: 'Envoyez un paiement pour un travail passé ou réservez-en un pour l\'avenir.',
        de: 'Senden Sie eine Zahlung für einen vergangenen Auftrag oder buchen Sie einen für die Zukunft.'
    },
    'When you have a job that needs a pro, we\'ll send them a job request per your priorities.': {
        pt: 'Quando você tiver um trabalho que precisa de um profissional, enviaremos uma solicitação de trabalho de acordo com suas prioridades.',
        es: 'Cuando tengas un trabajo que necesite un profesional, les enviaremos una solicitud de trabajo según tus prioridades.',
        ru: 'Когда у вас есть работа, требующая специалиста, мы отправим им запрос на работу в соответствии с вашими приоритетами.',
        fr: 'Lorsque vous avez un travail qui nécessite un professionnel, nous leur enverrons une demande de travail selon vos priorités.',
        de: 'Wenn Sie einen Auftrag haben, der einen Profi benötigt, senden wir ihnen eine Arbeitsanfrage gemäß Ihren Prioritäten.'
    },
    'Pros will start getting notifications to accept your jobs by email or SMS. They don\'t need to sign up first or download anything.': {
        pt: 'Os profissionais começarão a receber notificações para aceitar seus trabalhos por e-mail ou SMS. Eles não precisam se cadastrar primeiro ou baixar nada.',
        es: 'Los profesionales comenzarán a recibir notificaciones para aceptar tus trabajos por correo electrónico o SMS. No necesitan registrarse primero ni descargar nada.',
        ru: 'Специалисты начнут получать уведомления о принятии ваших заказов по электронной почте или SMS. Им не нужно сначала регистрироваться или что-то скачивать.',
        fr: 'Les professionnels commenceront à recevoir des notifications pour accepter vos travaux par e-mail ou SMS. Ils n\'ont pas besoin de s\'inscrire d\'abord ou de télécharger quoi que ce soit.',
        de: 'Profis werden Benachrichtigungen erhalten, um Ihre Aufträge per E-Mail oder SMS zu akzeptieren. Sie müssen sich nicht erst anmelden oder etwas herunterladen.'
    },
    'We can make your pro comfortable with your process. Just schedule a 5-minute call with us to set this up, it\'s free.': {
        pt: 'Podemos fazer seu profissional se sentir confortável com seu processo. Basta agendar uma chamada de 5 minutos conosco para configurar isso, é grátis.',
        es: 'Podemos hacer que tu profesional se sienta cómodo con tu proceso. Solo programa una llamada de 5 minutos con nosotros para configurar esto, es gratis.',
        ru: 'Мы можем помочь вашему специалисту освоиться с вашим процессом. Просто запланируйте 5-минутный звонок с нами, чтобы настроить это, это бесплатно.',
        fr: 'Nous pouvons mettre votre professionnel à l\'aise avec votre processus. Il suffit de programmer un appel de 5 minutes avec nous pour mettre cela en place, c\'est gratuit.',
        de: 'Wir können Ihren Profi mit Ihrem Prozess vertraut machen. Vereinbaren Sie einfach einen 5-minütigen Anruf mit uns, um dies einzurichten, es ist kostenlos.'
    },
    'Pros Easily Accept': {
        pt: 'Profissionais Aceitam Facilmente',
        es: 'Los Profesionales Aceptan Fácilmente',
        ru: 'Специалисты Легко Принимают',
        fr: 'Les Professionnels Acceptent Facilement',
        de: 'Profis Akzeptieren Einfach'
    },
    'Pros accept with a tap, like an eSignature. You get notified when they accept, and can see it in TIDY.': {
        pt: 'Os profissionais aceitam com um toque, como uma assinatura eletrônica. Você é notificado quando eles aceitam e pode ver isso no TIDY.',
        es: 'Los profesionales aceptan con un toque, como una firma electrónica. Recibes una notificación cuando aceptan y puedes verlo en TIDY.',
        ru: 'Специалисты принимают одним касанием, как электронную подпись. Вы получаете уведомление, когда они принимают, и можете увидеть это в TIDY.',
        fr: 'Les professionnels acceptent d\'un simple toucher, comme une signature électronique. Vous êtes notifié lorsqu\'ils acceptent et vous pouvez le voir dans TIDY.',
        de: 'Profis akzeptieren mit einem Tippen, wie eine elektronische Unterschrift. Sie werden benachrichtigt, wenn sie akzeptieren, und können es in TIDY sehen.'
    },
    'We Can Help': {
        pt: 'Podemos Ajudar',
        es: 'Podemos Ayudar',
        ru: 'Мы Можем Помочь',
        fr: 'Nous Pouvons Aider',
        de: 'Wir Können Helfen'
    },

    //BookWithProPage
    'Book Job': {
        pt: 'Agendar Trabalho',
        es: 'Reservar Trabajo',
        ru: 'Забронировать Работу',
        fr: 'Réserver un Travail',
        de: 'Auftrag Buchen'
    },
    'What would you like to book with': {
        pt: 'O que você gostaria de agendar com',
        es: '¿Qué te gustaría reservar con',
        ru: 'Что бы вы хотели забронировать с',
        fr: 'Que souhaitez-vous réserver avec',
        de: 'Was möchten Sie buchen mit'
    },
    'Reschedule Current Plan': {
        pt: 'Reagendar Plano Atual',
        es: 'Reprogramar Plan Actual',
        ru: 'Перепланировать Текущий План',
        fr: 'Reprogrammer le Plan Actuel',
        de: 'Aktuellen Plan Umplanen'
    },

    //MyProsEditProPage
    'Edit Pro': {
        pt: 'Editar Profissional',
        es: 'Editar Profesional',
        ru: 'Редактировать Специалиста',
        fr: 'Modifier le Professionnel',
        de: 'Profi Bearbeiten'
    },
    'Pro Contact Saved': {
        pt: 'Contato do Profissional Salvo',
        es: 'Contacto del Profesional Guardado',
        ru: 'Контакт Специалиста Сохранен',
        fr: 'Contact du Professionnel Enregistré',
        de: 'Profi-Kontakt Gespeichert'
    },
    'The contact info for this Pro has been updated.': {
        pt: 'As informações de contato deste Profissional foram atualizadas.',
        es: 'La información de contacto de este Profesional ha sido actualizada.',
        ru: 'Контактная информация этого Специалиста была обновлена.',
        fr: 'Les informations de contact de ce Professionnel ont été mises à jour.',
        de: 'Die Kontaktinformationen für diesen Profi wurden aktualisiert.'
    },

    //MyProPage
    'Approve & Unblock': {
        pt: 'Aprovar e Desbloquear',
        es: 'Aprobar y Desbloquear',
        ru: 'Одобрить и Разблокировать',
        fr: 'Approuver et Débloquer',
        de: 'Genehmigen und Entsperren'
    },
    'Autopay Settings': {
        pt: 'Configurações de Pagamento Automático',
        es: 'Configuración de Pago Automático',
        ru: 'Настройки Автоплатежа',
        fr: 'Paramètres de Paiement Automatique',
        de: 'Einstellungen für Automatische Zahlung'
    },
    'Auto Assign': {
        pt: 'Atribuição Automática',
        es: 'Asignación Automática',
        ru: 'Автоматическое Назначение',
        fr: 'Attribution Automatique',
        de: 'Automatische Zuweisung'
    },
    'You can auto assign this pro to any job opportunity instead of asking them to accept. Turn on Auto Assign in the job request workflows page': {
        pt: 'Você pode atribuir automaticamente este profissional a qualquer oportunidade de trabalho em vez de pedir que eles aceitem. Ative a Atribuição Automática na página de fluxos de trabalho de solicitação de trabalho',
        es: 'Puede asignar automáticamente a este profesional a cualquier oportunidad de trabajo en lugar de pedirles que acepten. Active la Asignación Automática en la página de flujos de trabajo de solicitud de trabajo',
        ru: 'Вы можете автоматически назначить этого специалиста на любую возможность работы, вместо того чтобы просить их принять. Включите Автоматическое Назначение на странице рабочих процессов запроса на работу',
        fr: 'Vous pouvez attribuer automatiquement ce professionnel à toute opportunité de travail au lieu de leur demander d\'accepter. Activez l\'Attribution Automatique dans la page des flux de travail des demandes d\'emploi',
        de: 'Sie können diesen Profi automatisch jeder Jobanfrage zuweisen, anstatt sie um Annahme zu bitten. Schalten Sie die Automatische Zuweisung auf der Seite für Arbeitsanfrage-Workflows ein'
    },
    'Proposals': {
        pt: 'Propostas',
        es: 'Propuestas',
        ru: 'Предложения',
        fr: 'Propositions',
        de: 'Vorschläge'
    },
    'No Past jobs': {
        pt: 'Nenhum Trabalho Anterior',
        es: 'Sin Trabajos Anteriores',
        ru: 'Нет Прошлых Работ',
        fr: 'Aucun Emploi Passé',
        de: 'Keine Vergangenen Aufträge'
    },
    'This will move them to the top of your favorites list.': {
        pt: 'Isso os moverá para o topo da sua lista de favoritos.',
        es: 'Esto los moverá a la parte superior de su lista de favoritos.',
        ru: 'Это переместит их в верхнюю часть вашего списка избранных.',
        fr: 'Cela les déplacera en haut de votre liste de favoris.',
        de: 'Dies wird sie an die Spitze Ihrer Favoritenliste verschieben.'
    },
    'Confirm Favorite': {
        pt: 'Confirmar Favorito',
        es: 'Confirmar Favorito',
        ru: 'Подтвердить Избранное',
        fr: 'Confirmer le Favori',
        de: 'Favorit Bestätigen'
    },
    'This will block them from your account, so they cannot see or accept any of your future job requests.': {
        pt: 'Isso os bloqueará da sua conta, para que não possam ver ou aceitar nenhuma das suas futuras solicitações de trabalho.',
        es: 'Esto los bloqueará de su cuenta, por lo que no podrán ver ni aceptar ninguna de sus futuras solicitudes de trabajo.',
        ru: 'Это заблокирует их от вашей учетной записи, поэтому они не смогут видеть или принимать ваши будущие запросы на работу.',
        fr: 'Cela les bloquera de votre compte, ils ne pourront donc pas voir ni accepter vos futures demandes de travail.',
        de: 'Dies wird sie von Ihrem Konto blockieren, sodass sie keine Ihrer zukünftigen Jobanfragen sehen oder akzeptieren können.'
    },
    'This will give your approval to them to see and accept future job requests.': {
        pt: 'Isso dará sua aprovação para que eles vejam e aceitem futuras solicitações de trabalho.',
        es: 'Esto les dará su aprobación para ver y aceptar futuras solicitudes de trabajo.',
        ru: 'Это даст им ваше одобрение на просмотр и принятие будущих запросов на работу.',
        fr: 'Cela leur donnera votre approbation pour voir et accepter les futures demandes de travail.',
        de: 'Dies gibt ihnen Ihre Zustimmung, zukünftige Jobanfragen zu sehen und zu akzeptieren.'
    },
    'Turn On Auto Assign': {
        pt: 'Ativar Atribuição Automática',
        es: 'Activar Asignación Automática',
        ru: 'Включить Автоматическое Назначение',
        fr: 'Activer l\'Attribution Automatique',
        de: 'Automatische Zuweisung Einschalten'
    },

    //ProAddedPage
    'Pro Added': {
        pt: 'Profissional Adicionado',
        es: 'Profesional Añadido',
        ru: 'Профессионал Добавлен',
        fr: 'Professionnel Ajouté',
        de: 'Profi Hinzugefügt'
    },
    'Would you like to send a job request to': {
        pt: 'Gostaria de enviar uma solicitação de trabalho para',
        es: '¿Le gustaría enviar una solicitud de trabajo a',
        ru: 'Хотите отправить запрос на работу',
        fr: 'Voulez-vous envoyer une demande de travail à',
        de: 'Möchten Sie eine Jobanfrage senden an'
    },
    'They can use the app to accept.': {
        pt: 'Eles podem usar o aplicativo para aceitar.',
        es: 'Pueden usar la aplicación para aceptar.',
        ru: 'Они могут использовать приложение для принятия.',
        fr: 'Ils peuvent utiliser l\'application pour accepter.',
        de: 'Sie können die App zum Akzeptieren verwenden.'
    },

    //ProSettingsPage
    'Pro Settings': {
        pt: 'Configurações do Profissional',
        es: 'Configuración del Profesional',
        ru: 'Настройки Профессионала',
        fr: 'Paramètres du Professionnel',
        de: 'Profi-Einstellungen'
    },
    'Select Field(s) to Edit': {
        pt: 'Selecione Campo(s) para Editar',
        es: 'Seleccione Campo(s) para Editar',
        ru: 'Выберите Поле(я) для Редактирования',
        fr: 'Sélectionnez le(s) Champ(s) à Modifier',
        de: 'Feld(er) zum Bearbeiten auswählen'
    },
    'Edit Service Name': {
        pt: 'Editar Nome do Serviço',
        es: 'Editar Nombre del Servicio',
        ru: 'Редактировать Название Услуги',
        fr: 'Modifier le Nom du Service',
        de: 'Dienstleistungsname bearbeiten'
    },
    'Edit Default Rate': {
        pt: 'Editar Taxa Padrão',
        es: 'Editar Tarifa Predeterminada',
        ru: 'Редактировать Стандартную Ставку',
        fr: 'Modifier le Tarif par Défaut',
        de: 'Standardrate bearbeiten'
    },
    'Edit Autopay Limit': {
        pt: 'Editar Limite de Pagamento Automático',
        es: 'Editar Límite de Pago Automático',
        ru: 'Редактировать Лимит Автоплатежа',
        fr: 'Modifier la Limite de Paiement Automatique',
        de: 'Autopay-Limit bearbeiten'
    },
    'Please select row(s) to edit.': {
        pt: 'Por favor, selecione linha(s) para editar.',
        es: 'Por favor, seleccione fila(s) para editar.',
        ru: 'Пожалуйста, выберите строку(и) для редактирования.',
        fr: 'Veuillez sélectionner la(les) ligne(s) à modifier.',
        de: 'Bitte wählen Sie Zeile(n) zum Bearbeiten aus.'
    },
    'You haven\'t added any private pros yet.': {
        pt: 'Você ainda não adicionou nenhum profissional privado.',
        es: 'Aún no ha añadido ningún profesional privado.',
        ru: 'Вы еще не добавили ни одного частного профессионала.',
        fr: 'Vous n\'avez pas encore ajouté de professionnels privés.',
        de: 'Sie haben noch keine privaten Profis hinzugefügt.'
    },
    'Select': {
        pt: 'Selecionar',
        es: 'Seleccionar',
        ru: 'Выбрать',
        fr: 'Sélectionner',
        de: 'Auswählen'
    },
    'Service Name': {
        pt: 'Nome do Serviço',
        es: 'Nombre del Servicio',
        ru: 'Название Услуги',
        fr: 'Nom du Service',
        de: 'Dienstleistungsname'
    },
    'Default Rate': {
        pt: 'Taxa Padrão',
        es: 'Tarifa Predeterminada',
        ru: 'Стандартная Ставка',
        fr: 'Tarif par Défaut',
        de: 'Standardrate'
    },
    'Autopay Limit': {
        pt: 'Limite de Pagamento Automático',
        es: 'Límite de Pago Automático',
        ru: 'Лимит Автоплатежа',
        fr: 'Limite de Paiement Automatique',
        de: 'Autopay-Limit'
    },

    //TidyFindNewProPage
    'Find New Pro Settings': {
        pt: 'Configurações de Encontrar Novo Profissional',
        es: 'Configuración de Buscar Nuevo Profesional',
        ru: 'Настройки Поиска Нового Специалиста',
        fr: 'Paramètres de Recherche de Nouveau Professionnel',
        de: 'Einstellungen für Neuen Profi Finden'
    },
    'When a higher priority Pro is unavailable, automatically request a new pro from TIDY\'s high quality network.': {
        pt: 'Quando um Profissional de maior prioridade não está disponível, solicite automaticamente um novo profissional da rede de alta qualidade da TIDY.',
        es: 'Cuando un Profesional de mayor prioridad no está disponible, solicite automáticamente un nuevo profesional de la red de alta calidad de TIDY.',
        ru: 'Когда специалист с более высоким приоритетом недоступен, автоматически запрашивайте нового специалиста из высококачественной сети TIDY.',
        fr: 'Lorsqu\'un Professionnel de priorité supérieure n\'est pas disponible, demandez automatiquement un nouveau professionnel du réseau de haute qualité de TIDY.',
        de: 'Wenn ein Profi mit höherer Priorität nicht verfügbar ist, fordern Sie automatisch einen neuen Profi aus TIDYs hochwertigem Netzwerk an.'
    },
    'When a higher priority Pro is unavailable, TIDY can help find the best available Pro from our high quality network.': {
        pt: 'Quando um Profissional de maior prioridade não está disponível, a TIDY pode ajudar a encontrar o melhor Profissional disponível em nossa rede de alta qualidade.',
        es: 'Cuando un Profesional de mayor prioridad no está disponible, TIDY puede ayudar a encontrar el mejor Profesional disponible de nuestra red de alta calidad.',
        ru: 'Когда специалист с более высоким приоритетом недоступен, TIDY может помочь найти лучшего доступного специалиста из нашей высококачественной сети.',
        fr: 'Lorsqu\'un Professionnel de priorité supérieure n\'est pas disponible, TIDY peut aider à trouver le meilleur Professionnel disponible dans notre réseau de haute qualité.',
        de: 'Wenn ein Profi mit höherer Priorität nicht verfügbar ist, kann TIDY helfen, den besten verfügbaren Profi aus unserem hochwertigen Netzwerk zu finden.'
    },
    'Gender Preferences': {
        pt: 'Preferências de Gênero',
        es: 'Preferencias de Género',
        ru: 'Предпочтения по Полу',
        fr: 'Préférences de Genre',
        de: 'Geschlechterpräferenzen'
    },
    'Save Setting': {
        pt: 'Salvar Configuração',
        es: 'Guardar Configuración',
        ru: 'Сохранить Настройку',
        fr: 'Enregistrer le Paramètre',
        de: 'Einstellung Speichern'
    },
    'Best Available': {
        pt: 'Melhor Disponível',
        es: 'Mejor Disponible',
        ru: 'Лучший Доступный',
        fr: 'Meilleur Disponible',
        de: 'Bestmöglich Verfügbar'
    },
    'Male Only': {
        pt: 'Apenas Masculino',
        es: 'Solo Masculino',
        ru: 'Только Мужчины',
        fr: 'Hommes Uniquement',
        de: 'Nur Männlich'
    },
    'Female Only': {
        pt: 'Apenas Feminino',
        es: 'Solo Femenino',
        ru: 'Только Женщины',
        fr: 'Femmes Uniquement',
        de: 'Nur Weiblich'
    },

    //PlansPage
    'Plans': {
        pt: 'Planos',
        es: 'Planes',
        ru: 'Планы',
        fr: 'Plans',
        de: 'Pläne'
    },
    'No plans booked.': {
        pt: 'Nenhum plano reservado.',
        es: 'No hay planes reservados.',
        ru: 'Планы не забронированы.',
        fr: 'Aucun plan réservé.',
        de: 'Keine Pläne gebucht.'
    },
    'Book Job(s)': {
        pt: 'Reservar Trabalho(s)',
        es: 'Reservar Trabajo(s)',
        ru: 'Забронировать Работу(ы)',
        fr: 'Réserver Travail(aux)',
        de: 'Job(s) Buchen'
    },
    'Select what you want to do:': {
        pt: 'Selecione o que você quer fazer:',
        es: 'Seleccione lo que quiere hacer:',
        ru: 'Выберите, что вы хотите сделать:',
        fr: 'Sélectionnez ce que vous voulez faire :',
        de: 'Wählen Sie aus, was Sie tun möchten:'
    },
    'With Pros from TIDY': {
        pt: 'Com Profissionais da TIDY',
        es: 'Con Profesionales de TIDY',
        ru: 'С Профессионалами от TIDY',
        fr: 'Avec des Pros de TIDY',
        de: 'Mit Profis von TIDY'
    },
    'Pending Job Request': {
        pt: 'Solicitação de Trabalho Pendente',
        es: 'Solicitud de Trabajo Pendiente',
        ru: 'Ожидающий Запрос на Работу',
        fr: 'Demande de Travail en Attente',
        de: 'Ausstehende Jobanfrage'
    },
    'Pending Job Requests': {
        pt: 'Solicitações de Trabalho Pendentes',
        es: 'Solicitudes de Trabajo Pendientes',
        ru: 'Ожидающие Запросы на Работу',
        fr: 'Demandes de Travail en Attente',
        de: 'Ausstehende Jobanfragen'
    },
    'Cancel Plan': {
        pt: 'Cancelar Plano',
        es: 'Cancelar Plan',
        ru: 'Отменить План',
        fr: 'Annuler le Plan',
        de: 'Plan Stornieren'
    },
    'One Time Job': {
        pt: 'Trabalho Único',
        es: 'Trabajo Único',
        ru: 'Разовая Работа',
        fr: 'Travail Ponctuel',
        de: 'Einmaliger Job'
    },
    'One Time Jobs': {
        pt: 'Trabalhos Únicos',
        es: 'Trabajos Únicos',
        ru: 'Разовые Работы',
        fr: 'Travaux Ponctuels',
        de: 'Einmalige Jobs'
    },
    'Reschedule Jobs': {
        pt: 'Reagendar Trabalhos',
        es: 'Reprogramar Trabajos',
        ru: 'Перепланировать Работы',
        fr: 'Reprogrammer les Travaux',
        de: 'Jobs Umplanen'
    },
    'Cancel Job': {
        pt: 'Cancelar Trabalho',
        es: 'Cancelar Trabajo',
        ru: 'Отменить Работу',
        fr: 'Annuler le Travail',
        de: 'Job Stornieren'
    },
    'Cancel Jobs': {
        pt: 'Cancelar Trabalhos',
        es: 'Cancelar Trabajos',
        ru: 'Отменить Работы',
        fr: 'Annuler les Travaux',
        de: 'Jobs Stornieren'
    },
    'Cancel Jobs?': {
        pt: 'Cancelar Trabalhos?',
        es: '¿Cancelar Trabajos?',
        ru: 'Отменить Работы?',
        fr: 'Annuler les Travaux ?',
        de: 'Jobs Stornieren?'
    },
    'Cancel Plan?': {
        pt: 'Cancelar Plano?',
        es: '¿Cancelar Plan?',
        ru: 'Отменить План?',
        fr: 'Annuler le Plan ?',
        de: 'Plan Stornieren?'
    },

    //ProDidNotShowUpPage
    'Pro Did Not Show Up': {
        pt: 'Profissional Não Apareceu',
        es: 'El Profesional No Se Presentó',
        ru: 'Специалист Не Явился',
        fr: 'Le Pro Ne S\'est Pas Présenté',
        de: 'Dienstleister Ist Nicht Erschienen'
    },
    'Oh no! Please confirm that': {
        pt: 'Oh não! Por favor, confirme que',
        es: '¡Oh no! Por favor, confirme que',
        ru: 'О нет! Пожалуйста, подтвердите, что',
        fr: 'Oh non ! Veuillez confirmer que',
        de: 'Oh nein! Bitte bestätigen Sie, dass'
    },
    'did not clean at all and should not be paid for this cleaning.': {
        pt: 'não limpou nada e não deve ser pago por esta limpeza.',
        es: 'no limpió en absoluto y no debe ser pagado por esta limpieza.',
        ru: 'вообще не убирал и не должен получить оплату за эту уборку.',
        fr: 'n\'a pas du tout nettoyé et ne devrait pas être payé pour ce nettoyage.',
        de: 'überhaupt nicht geputzt hat und für diese Reinigung nicht bezahlt werden sollte.'
    },
    'Add $10 credit to my account': {
        pt: 'Adicionar $10 de crédito à minha conta',
        es: 'Agregar $10 de crédito a mi cuenta',
        ru: 'Добавить $10 кредита на мой счет',
        fr: 'Ajouter un crédit de 10$ à mon compte',
        de: '10$ Guthaben zu meinem Konto hinzufügen'
    },
    'No Show Reported': {
        pt: 'Não Comparecimento Reportado',
        es: 'No Presentación Reportada',
        ru: 'Сообщено о Неявке',
        fr: 'Absence Signalée',
        de: 'Nichterscheinen Gemeldet'
    },
    'You will not be billed for this and can request a substitute in the next page if you\'d like.  Thanks for your patience.': {
        pt: 'Você não será cobrado por isso e pode solicitar um substituto na próxima página, se desejar. Obrigado pela sua paciência.',
        es: 'No se le cobrará por esto y puede solicitar un sustituto en la siguiente página si lo desea. Gracias por su paciencia.',
        ru: 'С вас не будет взиматься плата за это, и вы можете запросить замену на следующей странице, если хотите. Спасибо за ваше терпение.',
        fr: 'Vous ne serez pas facturé pour cela et vous pouvez demander un remplaçant sur la page suivante si vous le souhaitez. Merci pour votre patience.',
        de: 'Sie werden dafür nicht berechnet und können auf der nächsten Seite einen Ersatz anfordern, wenn Sie möchten. Danke für Ihre Geduld.'
    },

    //ProMessagesPage
    //ProposalsPage
    'by': {
        pt: 'por',
        es: 'por',
        ru: 'от',
        fr: 'par',
        de: 'von'
    },

    //AcceptedProposalPage
    'Accepted Proposal': {
        pt: 'Proposta Aceita',
        es: 'Propuesta Aceptada',
        ru: 'Принятое Предложение',
        fr: 'Proposition Acceptée',
        de: 'Angenommener Vorschlag'
    },
    'Proposal From': {
        pt: 'Proposta De',
        es: 'Propuesta De',
        ru: 'Предложение От',
        fr: 'Proposition De',
        de: 'Vorschlag Von'
    },
    'Valid At: All Properties': {
        pt: 'Válido Em: Todas as Propriedades',
        es: 'Válido En: Todas las Propiedades',
        ru: 'Действительно Для: Всех Объектов',
        fr: 'Valable Pour : Toutes les Propriétés',
        de: 'Gültig Für: Alle Immobilien'
    },
    'Valid At:': {
        pt: 'Válido Em:',
        es: 'Válido En:',
        ru: 'Действительно Для:',
        fr: 'Valable Pour :',
        de: 'Gültig Für:'
    },
    'Unit': {
        pt: 'Unidade',
        es: 'Unidad',
        ru: 'Единица',
        fr: 'Unité',
        de: 'Einheit'
    },
    'We are proud to offer this option for 30 days:': {
        pt: 'Temos o prazer de oferecer esta opção por 30 dias:',
        es: 'Nos enorgullece ofrecer esta opción durante 30 días:',
        ru: 'Мы с гордостью предлагаем эту опцию на 30 дней:',
        fr: 'Nous sommes fiers d\'offrir cette option pendant 30 jours:',
        de: 'Wir sind stolz darauf, diese Option für 30 Tage anzubieten:'
    },

    //ChooseAddressToBookPage
    'Choose Property to Book': {
        pt: 'Escolher Propriedade para Agendar',
        es: 'Elegir Propiedad para Reservar',
        ru: 'Выбрать Объект для Бронирования',
        fr: 'Choisir la Propriété à Réserver',
        de: 'Immobilie zum Buchen Auswählen'
    },
    'Please select a property': {
        pt: 'Por favor, selecione uma propriedade',
        es: 'Por favor, seleccione una propiedad',
        ru: 'Пожалуйста, выберите объект недвижимости',
        fr: 'Veuillez sélectionner une propriété',
        de: 'Bitte wählen Sie eine Immobilie aus'
    },
    'Nevermind, Book Later': {
        pt: 'Deixa pra lá, Agendar Depois',
        es: 'No importa, Reservar Después',
        ru: 'Неважно, Забронировать Позже',
        fr: 'Peu importe, Réserver Plus Tard',
        de: 'Egal, Später Buchen'
    },

    //PendingProposalPage
    'Reject Proposal?': {
        pt: 'Rejeitar Proposta?',
        es: '¿Rechazar Propuesta?',
        ru: 'Отклонить Предложение?',
        fr: 'Rejeter la Proposition ?',
        de: 'Angebot Ablehnen?'
    },
    'Proposal Rejected': {
        pt: 'Proposta Rejeitada',
        es: 'Propuesta Rechazada',
        ru: 'Предложение Отклонено',
        fr: 'Proposition Rejetée',
        de: 'Angebot Abgelehnt'
    },
    'Proposal Accepted': {
        pt: 'Proposta Aceita',
        es: 'Propuesta Aceptada',
        ru: 'Предложение Принято',
        fr: 'Proposition Acceptée',
        de: 'Angebot Angenommen'
    },
    'Schedule Later': {
        pt: 'Agendar Depois',
        es: 'Programar Después',
        ru: 'Запланировать Позже',
        fr: 'Planifier Plus Tard',
        de: 'Später Planen'
    },
    'Schedule Now': {
        pt: 'Agendar Agora',
        es: 'Programar Ahora',
        ru: 'Запланировать Сейчас',
        fr: 'Planifier Maintenant',
        de: 'Jetzt Planen'
    },
    'Proposal No Longer Valid': {
        pt: 'Proposta Não é Mais Válida',
        es: 'Propuesta Ya No es Válida',
        ru: 'Предложение Больше Недействительно',
        fr: 'Proposition N\'est Plus Valable',
        de: 'Angebot Nicht Mehr Gültig'
    },

    //ProxyMessagesPage
    //RentalClosingPage
    //ReportsPage
    'Overview': {
        pt: 'Visão Geral',
        es: 'Resumen',
        ru: 'Обзор',
        fr: 'Aperçu',
        de: 'Überblick'
    },
    'Completed Jobs': {
        pt: 'Trabalhos Concluídos',
        es: 'Trabajos Completados',
        ru: 'Завершенные Работы',
        fr: 'Travaux Terminés',
        de: 'Abgeschlossene Aufträge'
    },

    //RequestJobPage
    'Earliest Start': {
        pt: 'Início Mais Cedo',
        es: 'Inicio Más Temprano',
        ru: 'Самое Раннее Начало',
        fr: 'Début au Plus Tôt',
        de: 'Frühester Beginn'
    },
    'Please select an earliest start date no more than 3 days before the latest finish date.': {
        pt: 'Por favor, selecione uma data de início mais cedo não superior a 3 dias antes da data de término mais tardia.',
        es: 'Por favor, seleccione una fecha de inicio más temprana no más de 3 días antes de la fecha de finalización más tardía.',
        ru: 'Пожалуйста, выберите самую раннюю дату начала не более чем за 3 дня до самой поздней даты окончания.',
        fr: 'Veuillez sélectionner une date de début au plus tôt pas plus de 3 jours avant la date de fin au plus tard.',
        de: 'Bitte wählen Sie ein frühestes Startdatum, das nicht mehr als 3 Tage vor dem spätesten Enddatum liegt.'
    },
    'Latest Finish': {
        pt: 'Término Mais Tardio',
        es: 'Finalización Más Tardía',
        ru: 'Самое Позднее Окончание',
        fr: 'Fin au Plus Tard',
        de: 'Spätestes Ende'
    },
    'Please select a latest finish date no more than 3 days after the earliest start date.': {
        pt: 'Por favor, selecione uma data de término mais tardia não superior a 3 dias após a data de início mais cedo.',
        es: 'Por favor, seleccione una fecha de finalización más tardía no más de 3 días después de la fecha de inicio más temprana.',
        ru: 'Пожалуйста, выберите самую позднюю дату окончания не более чем через 3 дня после самой ранней даты начала.',
        fr: 'Veuillez sélectionner une date de fin au plus tard pas plus de 3 jours après la date de début au plus tôt.',
        de: 'Bitte wählen Sie ein spätestes Enddatum, das nicht mehr als 3 Tage nach dem frühesten Startdatum liegt.'
    },
    '<b>Note:</b> TIDY\'s Find New Pros feature only works for start times of 8:00am or later, end times of 11:00pm or earlier, and at least': {
        pt: '<b>Nota:</b> O recurso Encontrar Novos Profissionais da TIDY só funciona para horários de início às 8:00 ou mais tarde, horários de término às 23:00 ou mais cedo, e pelo menos',
        es: '<b>Nota:</b> La función Encontrar Nuevos Profesionales de TIDY solo funciona para horas de inicio a las 8:00 am o más tarde, horas de finalización a las 11:00 pm o más temprano, y al menos',
        ru: '<b>Примечание:</b> Функция поиска новых специалистов TIDY работает только для времени начала в 8:00 или позже, времени окончания в 23:00 или раньше, и как минимум',
        fr: '<b>Note:</b> La fonction Trouver de Nouveaux Pros de TIDY ne fonctionne que pour les heures de début à partir de 8h00, les heures de fin jusqu\'à 23h00, et au moins',
        de: '<b>Hinweis:</b> Die Funktion Neue Profis finden von TIDY funktioniert nur für Startzeiten ab 8:00 Uhr oder später, Endzeiten bis 23:00 Uhr oder früher und mindestens'
    },
    'Tell Us More': {
        pt: 'Conte-nos Mais',
        es: 'Cuéntanos Más',
        ru: 'Расскажите Нам Больше',
        fr: 'Dites-nous En Plus',
        de: 'Erzählen Sie Uns Mehr'
    },
    'Please upload an image': {
        pt: 'Por favor, carregue uma imagem',
        es: 'Por favor, suba una imagen',
        ru: 'Пожалуйста, загрузите изображение',
        fr: 'Veuillez télécharger une image',
        de: 'Bitte laden Sie ein Bild hoch'
    },
    '<b>Note:</b> You selected times today. If a Pro accepts a time today <u>the price will be +50%.</u>': {
        pt: '<b>Nota:</b> Você selecionou horários para hoje. Se um Profissional aceitar um horário hoje, <u>o preço será +50%.</u>',
        es: '<b>Nota:</b> Ha seleccionado horarios para hoy. Si un Profesional acepta un horario hoy, <u>el precio será +50%.</u>',
        ru: '<b>Примечание:</b> Вы выбрали время на сегодня. Если Профессионал примет время сегодня, <u>цена будет +50%.</u>',
        fr: '<b>Note:</b> Vous avez sélectionné des horaires pour aujourd\'hui. Si un Pro accepte un horaire aujourd\'hui, <u>le prix sera majoré de 50%.</u>',
        de: '<b>Hinweis:</b> Sie haben Zeiten für heute ausgewählt. Wenn ein Profi heute eine Zeit akzeptiert, <u>wird der Preis um 50% erhöht.</u>'
    },
    'Acceptance Probability': {
        pt: 'Probabilidade de Aceitação',
        es: 'Probabilidad de Aceptación',
        ru: 'Вероятность Принятия',
        fr: 'Probabilité d\'Acceptation',
        de: 'Annahmewahrscheinlichkeit'
    },
    'Select all times to see the estimated chance that a pro accepts your job.': {
        pt: 'Selecione todos os horários para ver a chance estimada de um profissional aceitar seu trabalho.',
        es: 'Seleccione todos los horarios para ver la probabilidad estimada de que un profesional acepte su trabajo.',
        ru: 'Выберите все времена, чтобы увидеть предполагаемую вероятность того, что профессионал примет вашу работу.',
        fr: 'Sélectionnez tous les horaires pour voir la probabilité estimée qu\'un professionnel accepte votre travail.',
        de: 'Wählen Sie alle Zeiten aus, um die geschätzte Chance zu sehen, dass ein Profi Ihren Auftrag annimmt.'
    },
    'We estimate a': {
        pt: 'Estimamos uma',
        es: 'Estimamos una',
        ru: 'Мы оцениваем',
        fr: 'Nous estimons une',
        de: 'Wir schätzen eine'
    },
    '% chance of a pro accepting this.': {
        pt: '% de chance de um profissional aceitar isso.',
        es: '% de probabilidad de que un profesional acepte esto.',
        ru: '% вероятность того, что профессионал примет это.',
        fr: '% de chance qu\'un professionnel accepte cela.',
        de: '% Chance, dass ein Profi dies akzeptiert.'
    },
    'To improve this:': {
        pt: 'Para melhorar isso:',
        es: 'Para mejorar esto:',
        ru: 'Чтобы улучшить это:',
        fr: 'Pour améliorer cela :',
        de: 'Um dies zu verbessern:'
    },
    'Powered by TIDY AI': {
        pt: 'Desenvolvido por TIDY AI',
        es: 'Impulsado por TIDY AI',
        ru: 'Работает на TIDY AI',
        fr: 'Propulsé par TIDY AI',
        de: 'Unterstützt von TIDY AI'
    },
    'Review Request': {
        pt: 'Revisar Solicitação',
        es: 'Revisar Solicitud',
        ru: 'Просмотреть Запрос',
        fr: 'Examiner la Demande',
        de: 'Anfrage Überprüfen'
    },
    'How Job Requests Work:': {
        pt: 'Como Funcionam as Solicitações de Trabalho:',
        es: 'Cómo Funcionan las Solicitudes de Trabajo:',
        ru: 'Как Работают Запросы на Работу:',
        fr: 'Comment Fonctionnent les Demandes de Travail :',
        de: 'Wie Jobanfragen Funktionieren:'
    },
    'We\'ll notify you right away if someone accepts.': {
        pt: 'Notificaremos você imediatamente se alguém aceitar.',
        es: 'Le notificaremos de inmediato si alguien acepta.',
        ru: 'Мы уведомим вас сразу же, если кто-то примет.',
        fr: 'Nous vous notifierons immédiatement si quelqu\'un accepte.',
        de: 'Wir benachrichtigen Sie sofort, wenn jemand annimmt.'
    },
    'What Times Work Today': {
        pt: 'Quais Horários Funcionam Hoje',
        es: 'Qué Horarios Funcionan Hoy',
        ru: 'Какое Время Подходит Сегодня',
        fr: 'Quels Horaires Fonctionnent Aujourd\'hui',
        de: 'Welche Zeiten Funktionieren Heute'
    },
    '5.5 hours of time for a 4 hour job.': {
        pt: '5,5 horas de tempo para um trabalho de 4 horas.',
        es: '5,5 horas de tiempo para un trabajo de 4 horas.',
        ru: '5,5 часов времени для 4-часовой работы.',
        fr: '5,5 heures de temps pour un travail de 4 heures.',
        de: '5,5 Stunden Zeit für einen 4-Stunden-Job.'
    },
    '4 hours of time for a 2.5 hour job.': {
        pt: '4 horas de tempo para um trabalho de 2,5 horas.',
        es: '4 horas de tiempo para un trabajo de 2,5 horas.',
        ru: '4 часа времени для 2,5-часовой работы.',
        fr: '4 heures de temps pour un travail de 2,5 heures.',
        de: '4 Stunden Zeit für einen 2,5-Stunden-Job.'
    },
    '2.5 hours of time for a 1 hour job.': {
        pt: '2,5 horas de tempo para um trabalho de 1 hora.',
        es: '2,5 horas de tiempo para un trabajo de 1 hora.',
        ru: '2,5 часа времени для 1-часовой работы.',
        fr: '2,5 heures de temps pour un travail d\'1 heure.',
        de: '2,5 Stunden Zeit für einen 1-Stunden-Job.'
    },

    //RequestProPage
    'Select Time(s)': {
        pt: 'Selecionar Horário(s)',
        es: 'Seleccionar Horario(s)',
        ru: 'Выбрать Время',
        fr: 'Sélectionner l\'Heure(s)',
        de: 'Zeit(en) Auswählen'
    },
    ' can counter with other times.': {
        pt: ' pode contrapropor outros horários.',
        es: ' puede contrarrestar con otros horarios.',
        ru: ' может предложить другое время.',
        fr: ' peut proposer d\'autres horaires.',
        de: ' kann mit anderen Zeiten kontern.'
    },
    'Please select a time.': {
        pt: 'Por favor, selecione um horário.',
        es: 'Por favor, seleccione un horario.',
        ru: 'Пожалуйста, выберите время.',
        fr: 'Veuillez sélectionner une heure.',
        de: 'Bitte wählen Sie eine Zeit aus.'
    },
    'Unfortunately': {
        pt: 'Infelizmente',
        es: 'Desafortunadamente',
        ru: 'К сожалению',
        fr: 'Malheureusement',
        de: 'Leider'
    },
    ' cannot be requested at this time.': {
        pt: ' não pode ser solicitado neste momento.',
        es: ' no se puede solicitar en este momento.',
        ru: ' нельзя запросить в данный момент.',
        fr: ' ne peut pas être demandé pour le moment.',
        de: ' kann zu diesem Zeitpunkt nicht angefordert werden.'
    },
    'Select a valid time': {
        pt: 'Selecione um horário válido',
        es: 'Seleccione un horario válido',
        ru: 'Выберите действительное время',
        fr: 'Sélectionnez une heure valide',
        de: 'Wählen Sie eine gültige Zeit'
    },
    'Requested Times:': {
        pt: 'Horários Solicitados:',
        es: 'Horarios Solicitados:',
        ru: 'Запрошенное Время:',
        fr: 'Heures Demandées :',
        de: 'Angeforderte Zeiten:'
    },
    'Priced later': {
        pt: 'Preço definido posteriormente',
        es: 'Precio definido más tarde',
        ru: 'Цена будет определена позже',
        fr: 'Prix défini ultérieurement',
        de: 'Preis wird später festgelegt'
    },
    'Select Day(s)': {
        pt: 'Selecionar Dia(s)',
        es: 'Seleccionar Día(s)',
        ru: 'Выбрать День(дни)',
        fr: 'Sélectionner le(s) Jour(s)',
        de: 'Tag(e) Auswählen'
    },
    'Increase Price': {
        pt: 'Aumentar Preço',
        es: 'Aumentar Precio',
        ru: 'Увеличить Цену',
        fr: 'Augmenter le Prix',
        de: 'Preis Erhöhen'
    },

    //RequestProCounterPage
    'Confirm & Book': {
        pt: 'Confirmar e Reservar',
        es: 'Confirmar y Reservar',
        ru: 'Подтвердить и Забронировать',
        fr: 'Confirmer et Réserver',
        de: 'Bestätigen & Buchen'
    },
    ' could not help you during the times you requested for a': {
        pt: ' não pôde ajudá-lo durante os horários que você solicitou para um',
        es: ' no pudo ayudarte durante los horarios que solicitaste para un',
        ru: ' не смог помочь вам в запрошенное время для',
        fr: ' n\'a pas pu vous aider pendant les heures que vous avez demandées pour un',
        de: ' konnte Ihnen zu den von Ihnen gewünschten Zeiten für einen nicht helfen'
    },
    'However they said they can at one of the following times.': {
        pt: 'No entanto, eles disseram que podem em um dos seguintes horários.',
        es: 'Sin embargo, dijeron que pueden en uno de los siguientes horarios.',
        ru: 'Однако они сказали, что могут в одно из следующих времен.',
        fr: 'Cependant, ils ont dit qu\'ils peuvent à l\'un des horaires suivants.',
        de: 'Sie sagten jedoch, dass sie zu einem der folgenden Zeitpunkte können.'
    },
    'Select Time With': {
        pt: 'Selecionar Horário Com',
        es: 'Seleccionar Hora Con',
        ru: 'Выбрать Время С',
        fr: 'Sélectionner l\'Heure Avec',
        de: 'Zeit Auswählen Mit'
    },
    'Decline Proposal': {
        pt: 'Recusar Proposta',
        es: 'Rechazar Propuesta',
        ru: 'Отклонить Предложение',
        fr: 'Refuser la Proposition',
        de: 'Vorschlag Ablehnen'
    },
    'Every Week': {
        pt: 'Toda Semana',
        es: 'Cada Semana',
        ru: 'Каждую Неделю',
        fr: 'Chaque Semaine',
        de: 'Jede Woche'
    },
    'Every Other Week': {
        pt: 'A Cada Duas Semanas',
        es: 'Cada Dos Semanas',
        ru: 'Каждые Две Недели',
        fr: 'Toutes les Deux Semaines',
        de: 'Jede Zweite Woche'
    },
    'Every Four Weeks': {
        pt: 'A Cada Quatro Semanas',
        es: 'Cada Cuatro Semanas',
        ru: 'Каждые Четыре Недели',
        fr: 'Toutes les Quatre Semaines',
        de: 'Alle Vier Wochen'
    },
    'Book Plan?': {
        pt: 'Reservar Plano?',
        es: '¿Reservar Plan?',
        ru: 'Забронировать План?',
        fr: 'Réserver le Plan ?',
        de: 'Plan Buchen?'
    },
    'Book Job?': {
        pt: 'Reservar Trabalho?',
        es: '¿Reservar Trabajo?',
        ru: 'Забронировать Работу?',
        fr: 'Réserver le Travail ?',
        de: 'Job Buchen?'
    },
    'Job Booked': {
        pt: 'Trabalho Reservado',
        es: 'Trabajo Reservado',
        ru: 'Работа Забронирована',
        fr: 'Travail Réservé',
        de: 'Job Gebucht'
    },
    'Plan Booked': {
        pt: 'Plano Reservado',
        es: 'Plan Reservado',
        ru: 'План Забронирован',
        fr: 'Plan Réservé',
        de: 'Plan Gebucht'
    },
    'Decline': {
        pt: 'Recusar',
        es: 'Rechazar',
        ru: 'Отклонить',
        fr: 'Refuser',
        de: 'Ablehnen'
    },
    'Proposal Declined': {
        pt: 'Proposta Recusada',
        es: 'Propuesta Rechazada',
        ru: 'Предложение Отклонено',
        fr: 'Proposition Refusée',
        de: 'Vorschlag Abgelehnt'
    },

    //RequestSubstitutePage
    'Request Substitute': {
        pt: 'Solicitar Substituto',
        es: 'Solicitar Sustituto',
        ru: 'Запросить Замену',
        fr: 'Demander un Remplaçant',
        de: 'Ersatz Anfordern'
    },
    'Select times you\'re available, we\'ll notify all pros near you, and send updates.': {
        pt: 'Selecione os horários disponíveis, notificaremos todos os profissionais próximos a você e enviaremos atualizações.',
        es: 'Seleccione los horarios en los que está disponible, notificaremos a todos los profesionales cercanos a usted y enviaremos actualizaciones.',
        ru: 'Выберите удобное для вас время, мы уведомим всех специалистов поблизости и отправим обновления.',
        fr: 'Sélectionnez les horaires où vous êtes disponible, nous préviendrons tous les professionnels près de chez vous et enverrons des mises à jour.',
        de: 'Wählen Sie Zeiten, zu denen Sie verfügbar sind, wir benachrichtigen alle Profis in Ihrer Nähe und senden Updates.'
    },
    'Select a confirmed time & we\'ll instantly book you with the available pro.': {
        pt: 'Selecione um horário confirmado e nós o reservaremos instantaneamente com o profissional disponível.',
        es: 'Seleccione un horario confirmado y lo reservaremos instantáneamente con el profesional disponible.',
        ru: 'Выберите подтвержденное время, и мы мгновенно забронируем вас с доступным специалистом.',
        fr: 'Sélectionnez un horaire confirmé et nous vous réserverons instantanément avec le professionnel disponible.',
        de: 'Wählen Sie eine bestätigte Zeit aus und wir buchen Sie sofort bei dem verfügbaren Profi.'
    },
    'to book later than': {
        pt: 'para reservar mais tarde que',
        es: 'para reservar más tarde que',
        ru: 'чтобы забронировать позже, чем',
        fr: 'pour réserver plus tard que',
        de: 'um später als zu buchen'
    },
    'Please select one or more times for the request.': {
        pt: 'Por favor, selecione um ou mais horários para a solicitação.',
        es: 'Por favor, seleccione uno o más horarios para la solicitud.',
        ru: 'Пожалуйста, выберите одно или несколько времен для запроса.',
        fr: 'Veuillez sélectionner un ou plusieurs horaires pour la demande.',
        de: 'Bitte wählen Sie eine oder mehrere Zeiten für die Anfrage aus.'
    },

    //ReservationPage
    'Check In:': {
        pt: 'Check-in:',
        es: 'Entrada:',
        ru: 'Заезд:',
        fr: 'Arrivée:',
        de: 'Check-in:'
    },
    'Check Out:': {
        pt: 'Check-out:',
        es: 'Salida:',
        ru: 'Выезд:',
        fr: 'Départ:',
        de: 'Check-out:'
    },
    'Added by you': {
        pt: 'Adicionado por você',
        es: 'Añadido por ti',
        ru: 'Добавлено вами',
        fr: 'Ajouté par vous',
        de: 'Von Ihnen hinzugefügt'
    },
    'Synced from your': {
        pt: 'Sincronizado do seu',
        es: 'Sincronizado desde tu',
        ru: 'Синхронизировано с вашего',
        fr: 'Synchronisé depuis votre',
        de: 'Synchronisiert von Ihrer'
    },
    'Synced from': {
        pt: 'Sincronizado de',
        es: 'Sincronizado desde',
        ru: 'Синхронизировано с',
        fr: 'Synchronisé depuis',
        de: 'Synchronisiert von'
    },
    'integration': {
        pt: 'integração',
        es: 'integración',
        ru: 'интеграции',
        fr: 'intégration',
        de: 'Integration'
    },
    'Guests:': {
        pt: 'Hóspedes:',
        es: 'Huéspedes:',
        ru: 'Гости:',
        fr: 'Invités:',
        de: 'Gäste:'
    },
    'guests': {
        pt: 'hóspedes',
        es: 'huéspedes',
        ru: 'гостей',
        fr: 'invités',
        de: 'Gäste'
    },
    'adults': {
        pt: 'adultos',
        es: 'adultos',
        ru: 'взрослых',
        fr: 'adultes',
        de: 'Erwachsene'
    },
    'pets': {
        pt: 'animais de estimação',
        es: 'mascotas',
        ru: 'домашних животных',
        fr: 'animaux de compagnie',
        de: 'Haustiere'
    },
    '<b>Status:</b>': {
        pt: '<b>Status:</b>',
        es: '<b>Estado:</b>',
        ru: '<b>Статус:</b>',
        fr: '<b>Statut:</b>',
        de: '<b>Status:</b>'
    },
    'Delete Reservation': {
        pt: 'Excluir Reserva',
        es: 'Eliminar Reservación',
        ru: 'Удалить Бронирование',
        fr: 'Supprimer la Réservation',
        de: 'Reservierung Löschen'
    },
    'Delete Reservation?': {
        pt: 'Excluir Reserva?',
        es: '¿Eliminar Reservación?',
        ru: 'Удалить Бронирование?',
        fr: 'Supprimer la Réservation?',
        de: 'Reservierung Löschen?'
    },
    'Are you sure you want to delete this reservation?': {
        pt: 'Tem certeza de que deseja excluir esta reserva?',
        es: '¿Estás seguro de que quieres eliminar esta reservación?',
        ru: 'Вы уверены, что хотите удалить это бронирование?',
        fr: 'Êtes-vous sûr de vouloir supprimer cette réservation?',
        de: 'Sind Sie sicher, dass Sie diese Reservierung löschen möchten?'
    },
    'Reservation deleted successfully': {
        pt: 'Reserva excluída com sucesso',
        es: 'Reservación eliminada con éxito',
        ru: 'Бронирование успешно удалено',
        fr: 'Réservation supprimée avec succès',
        de: 'Reservierung erfolgreich gelöscht'
    },

    //ReservationsPage
    'Check In': {
        pt: 'Check-in',
        es: 'Entrada',
        ru: 'Заезд',
        fr: 'Arrivée',
        de: 'Check-in'
    },
    'Check Out': {
        pt: 'Check-out',
        es: 'Salida',
        ru: 'Выезд',
        fr: 'Départ',
        de: 'Check-out'
    },
    'Created By': {
        pt: 'Criado Por',
        es: 'Creado Por',
        ru: 'Создано',
        fr: 'Créé Par',
        de: 'Erstellt Von'
    },

    //SchedulePage
    'Start Using Your Calendar:': {
        pt: 'Comece a Usar Seu Calendário:',
        es: 'Empiece a Usar Su Calendario:',
        ru: 'Начните Использовать Свой Календарь:',
        fr: 'Commencez à Utiliser Votre Calendrier:',
        de: 'Beginnen Sie mit der Nutzung Ihres Kalenders:'
    },
    'Book or manage your pros instantly, or find a new one.': {
        pt: 'Reserve ou gerencie seus profissionais instantaneamente, ou encontre um novo.',
        es: 'Reserve o administre sus profesionales al instante, o encuentre uno nuevo.',
        ru: 'Мгновенно бронируйте или управляйте своими профессионалами, или найдите нового.',
        fr: 'Réservez ou gérez vos professionnels instantanément, ou trouvez-en un nouveau.',
        de: 'Buchen oder verwalten Sie Ihre Profis sofort oder finden Sie einen neuen.'
    },
    'Sync Guest/Tenant Calendar': {
        pt: 'Sincronizar Calendário de Hóspedes/Inquilinos',
        es: 'Sincronizar Calendario de Huéspedes/Inquilinos',
        ru: 'Синхронизировать Календарь Гостей/Арендаторов',
        fr: 'Synchroniser le Calendrier des Invités/Locataires',
        de: 'Gast-/Mieterkalender Synchronisieren'
    },
    'Integrate Airbnb or a property management tool to get guest check-ins or tenant move outs synced to your calendar, to make automation easy.': {
        pt: 'Integre o Airbnb ou uma ferramenta de gerenciamento de propriedades para sincronizar check-ins de hóspedes ou mudanças de inquilinos em seu calendário, facilitando a automação.',
        es: 'Integre Airbnb o una herramienta de gestión de propiedades para sincronizar los registros de huéspedes o las salidas de inquilinos en su calendario, para facilitar la automatización.',
        ru: 'Интегрируйте Airbnb или инструмент управления недвижимостью, чтобы синхронизировать заезды гостей или выезды арендаторов с вашим календарем для легкой автоматизации.',
        fr: 'Intégrez Airbnb ou un outil de gestion immobilière pour synchroniser les arrivées des invités ou les départs des locataires dans votre calendrier, afin de faciliter l\'automatisation.',
        de: 'Integrieren Sie Airbnb oder ein Property-Management-Tool, um Gast-Check-ins oder Mieter-Auszüge mit Ihrem Kalender zu synchronisieren und die Automatisierung zu erleichtern.'
    },
    'Please add a property to book jobs.': {
        pt: 'Por favor, adicione uma propriedade para reservar trabalhos.',
        es: 'Por favor, añada una propiedad para reservar trabajos.',
        ru: 'Пожалуйста, добавьте объект недвижимости, чтобы бронировать работы.',
        fr: 'Veuillez ajouter une propriété pour réserver des travaux.',
        de: 'Bitte fügen Sie eine Immobilie hinzu, um Aufträge zu buchen.'
    },
    'Payment Info Needed': {
        pt: 'Informações de Pagamento Necessárias',
        es: 'Se Necesita Información de Pago',
        ru: 'Требуется Информация об Оплате',
        fr: 'Informations de Paiement Nécessaires',
        de: 'Zahlungsinformationen Erforderlich'
    },
    'You have automatic booking enabled but no card on file. Please add a card to get new Pros from TIDY.': {
        pt: 'Você tem a reserva automática ativada, mas não há cartão registrado. Por favor, adicione um cartão para obter novos Profissionais da TIDY.',
        es: 'Tiene habilitada la reserva automática pero no hay tarjeta registrada. Por favor, añada una tarjeta para obtener nuevos Profesionales de TIDY.',
        ru: 'У вас включено автоматическое бронирование, но нет карты в файле. Пожалуйста, добавьте карту, чтобы получить новых Профессионалов от TIDY.',
        fr: 'Vous avez activé la réservation automatique mais aucune carte n\'est enregistrée. Veuillez ajouter une carte pour obtenir de nouveaux Pros de TIDY.',
        de: 'Sie haben die automatische Buchung aktiviert, aber keine Karte hinterlegt. Bitte fügen Sie eine Karte hinzu, um neue Profis von TIDY zu erhalten.'
    },
    'Please add a phone number so your pro can contact you for your upcoming job.': {
        pt: 'Por favor, adicione um número de telefone para que seu profissional possa contatá-lo para o próximo trabalho.',
        es: 'Por favor, añada un número de teléfono para que su profesional pueda contactarlo para su próximo trabajo.',
        ru: 'Пожалуйста, добавьте номер телефона, чтобы ваш специалист мог связаться с вами по поводу предстоящей работы.',
        fr: 'Veuillez ajouter un numéro de téléphone pour que votre professionnel puisse vous contacter pour votre prochain travail.',
        de: 'Bitte fügen Sie eine Telefonnummer hinzu, damit Ihr Profi Sie für Ihren bevorstehenden Auftrag kontaktieren kann.'
    },
    'Add Phone': {
        pt: 'Adicionar Telefone',
        es: 'Añadir Teléfono',
        ru: 'Добавить Телефон',
        fr: 'Ajouter un Téléphone',
        de: 'Telefon Hinzufügen'
    },
    'You have no jobs booked at this property.': {
        pt: 'Você não tem trabalhos reservados nesta propriedade.',
        es: 'No tiene trabajos reservados en esta propiedad.',
        ru: 'У вас нет забронированных работ на этом объекте.',
        fr: 'Vous n\'avez aucun travail réservé sur cette propriété.',
        de: 'Sie haben keine Aufträge für diese Immobilie gebucht.'
    },
    'Why TIDY?': {
        pt: 'Por que TIDY?',
        es: '¿Por qué TIDY?',
        ru: 'Почему TIDY?',
        fr: 'Pourquoi TIDY?',
        de: 'Warum TIDY?'
    },
    'Concierge Phone Support': {
        pt: 'Suporte Telefônico de Concierge',
        es: 'Soporte Telefónico de Conserjería',
        ru: 'Телефонная Поддержка Консьержа',
        fr: 'Support Téléphonique de Conciergerie',
        de: 'Concierge-Telefonsupport'
    },
    'We\'re happy to answer any questions!': {
        pt: 'Estamos felizes em responder a quaisquer perguntas!',
        es: '¡Estamos encantados de responder cualquier pregunta!',
        ru: 'Мы с радостью ответим на любые вопросы!',
        fr: 'Nous sommes heureux de répondre à toutes vos questions!',
        de: 'Wir beantworten gerne alle Fragen!'
    },
    'Top Performers': {
        pt: 'Melhores Profissionais',
        es: 'Mejores Profesionales',
        ru: 'Лучшие Исполнители',
        fr: 'Meilleurs Performeurs',
        de: 'Top-Performer'
    },
    'Use certified Pros to only get the best.': {
        pt: 'Use profissionais certificados para obter apenas o melhor.',
        es: 'Use profesionales certificados para obtener solo lo mejor.',
        ru: 'Используйте сертифицированных профессионалов, чтобы получить только лучшее.',
        fr: 'Utilisez des professionnels certifiés pour n\'obtenir que le meilleur.',
        de: 'Verwenden Sie zertifizierte Profis, um nur das Beste zu erhalten.'
    },
    'Satisfaction Guaranteed': {
        pt: 'Satisfação Garantida',
        es: 'Satisfacción Garantizada',
        ru: 'Гарантия Удовлетворения',
        fr: 'Satisfaction Garantie',
        de: 'Zufriedenheit Garantiert'
    },
    'Protect yourself with our Satisfaction Guarantee.': {
        pt: 'Proteja-se com nossa Garantia de Satisfação.',
        es: 'Protéjase con nuestra Garantía de Satisfacción.',
        ru: 'Защитите себя нашей Гарантией Удовлетворения.',
        fr: 'Protégez-vous avec notre Garantie de Satisfaction.',
        de: 'Schützen Sie sich mit unserer Zufriedenheitsgarantie.'
    },
    'View availability instantly & manage the job online.': {
        pt: 'Veja a disponibilidade instantaneamente e gerencie o trabalho online.',
        es: 'Vea la disponibilidad al instante y administre el trabajo en línea.',
        ru: 'Мгновенно просматривайте доступность и управляйте работой онлайн.',
        fr: 'Consultez la disponibilité instantanément et gérez le travail en ligne.',
        de: 'Sehen Sie die Verfügbarkeit sofort und verwalten Sie den Auftrag online.'
    },
    'Trusted by Thousands': {
        pt: 'Confiado por Milhares',
        es: 'Confiado por Miles',
        ru: 'Доверие Тысяч',
        fr: 'Approuvé par des Milliers',
        de: 'Von Tausenden Vertraut'
    },
    'Over 50,000 people have used TIDY.': {
        pt: 'Mais de 50.000 pessoas usaram a TIDY.',
        es: 'Más de 50.000 personas han usado TIDY.',
        ru: 'Более 50 000 человек воспользовались услугами TIDY.',
        fr: 'Plus de 50 000 personnes ont utilisé TIDY.',
        de: 'Über 50.000 Menschen haben TIDY genutzt.'
    },
    '*All bookings subject to our': {
        pt: '*Todas as reservas estão sujeitas à nossa',
        es: '*Todas las reservas están sujetas a nuestros',
        ru: '*Все бронирования подлежат нашим',
        fr: '*Toutes les réservations sont soumises à nos',
        de: '*Alle Buchungen unterliegen unseren'
    },
    'Requests': {
        pt: 'Solicitações',
        es: 'Solicitudes',
        ru: 'Запросы',
        fr: 'Demandes',
        de: 'Anfragen'
    },
    'Request Pending': {
        pt: 'Solicitação Pendente',
        es: 'Solicitud Pendiente',
        ru: 'Запрос на Рассмотрении',
        fr: 'Demande en Attente',
        de: 'Anfrage Ausstehend'
    },
    'Change Request': {
        pt: 'Alterar Solicitação',
        es: 'Cambiar Solicitud',
        ru: 'Изменить Запрос',
        fr: 'Modifier la Demande',
        de: 'Anfrage Ändern'
    },
    'Proposed Other Times': {
        pt: 'Propôs Outros Horários',
        es: 'Propuso Otros Horarios',
        ru: 'Предложил Другое Время',
        fr: 'A Proposé D\'autres Horaires',
        de: 'Andere Zeiten Vorgeschlagen'
    },
    'indicated that they were not available for the times you requested, but provided other times they are available.': {
        pt: 'indicou que não estava disponível para os horários que você solicitou, mas forneceu outros horários em que está disponível.',
        es: 'indicó que no estaba disponible para los horarios que solicitó, pero proporcionó otros horarios en los que está disponible.',
        ru: 'указал(а), что не был(а) доступен(на) в запрошенное вами время, но предоставил(а) другое время, когда он(а) доступен(на).',
        fr: 'a indiqué qu\'il/elle n\'était pas disponible aux heures que vous avez demandées, mais a fourni d\'autres horaires où il/elle est disponible.',
        de: 'hat angegeben, dass sie für die von Ihnen gewünschten Zeiten nicht verfügbar waren, aber andere Zeiten angegeben, zu denen sie verfügbar sind.'
    },
    'View Other Times': {
        pt: 'Ver Outros Horários',
        es: 'Ver Otros Horarios',
        ru: 'Посмотреть Другое Время',
        fr: 'Voir Autres Horaires',
        de: 'Andere Zeiten Anzeigen'
    },
    'Edit Plans': {
        pt: 'Editar Planos',
        es: 'Editar Planes',
        ru: 'Редактировать Планы',
        fr: 'Modifier les Plans',
        de: 'Pläne Bearbeiten'
    },
    'Edit Plan': {
        pt: 'Editar Plano',
        es: 'Editar Plan',
        ru: 'Редактировать План',
        fr: 'Modifier le Plan',
        de: 'Plan Bearbeiten'
    },
    'One Time Plan': {
        pt: 'Plano Único',
        es: 'Plan de Una Vez',
        ru: 'Разовый План',
        fr: 'Plan Unique',
        de: 'Einmaliger Plan'
    },
    'No Recurring Plans': {
        pt: 'Sem Planos Recorrentes',
        es: 'Sin Planes Recurrentes',
        ru: 'Нет Повторяющихся Планов',
        fr: 'Pas de Plans Récurrents',
        de: 'Keine Wiederkehrenden Pläne'
    },
    'You still have a plan scheduled. We\'ll continue to book jobs automatically per your plan. If you want to edit your plan click <b>"Edit Plan"</b> above.': {
        pt: 'Você ainda tem um plano agendado. Continuaremos a reservar trabalhos automaticamente de acordo com seu plano. Se você quiser editar seu plano, clique em <b>"Editar Plano"</b> acima.',
        es: 'Todavía tiene un plan programado. Seguiremos reservando trabajos automáticamente según su plan. Si desea editar su plan, haga clic en <b>"Editar Plan"</b> arriba.',
        ru: 'У вас все еще есть запланированный план. Мы продолжим автоматически бронировать работы согласно вашему плану. Если вы хотите отредактировать свой план, нажмите <b>"Редактировать План"</b> выше.',
        fr: 'Vous avez toujours un plan programmé. Nous continuerons à réserver des travaux automatiquement selon votre plan. Si vous souhaitez modifier votre plan, cliquez sur <b>"Modifier le Plan"</b> ci-dessus.',
        de: 'Sie haben immer noch einen geplanten Plan. Wir werden weiterhin automatisch Aufträge gemäß Ihrem Plan buchen. Wenn Sie Ihren Plan bearbeiten möchten, klicken Sie oben auf <b>"Plan Bearbeiten"</b>.'
    },
    'No jobs booked.': {
        pt: 'Nenhum trabalho reservado.',
        es: 'No hay trabajos reservados.',
        ru: 'Нет забронированных работ.',
        fr: 'Aucun travail réservé.',
        de: 'Keine Aufträge gebucht.'
    },
    'You initiated multiple job requests.  If you do not want multiple jobs please cancel one request.': {
        pt: 'Você iniciou várias solicitações de trabalho. Se não quiser vários trabalhos, cancele uma solicitação.',
        es: 'Ha iniciado múltiples solicitudes de trabajo. Si no desea varios trabajos, cancele una solicitud.',
        ru: 'Вы инициировали несколько запросов на работу. Если вы не хотите несколько работ, пожалуйста, отмените один запрос.',
        fr: 'Vous avez initié plusieurs demandes de travail. Si vous ne voulez pas plusieurs travaux, veuillez annuler une demande.',
        de: 'Sie haben mehrere Auftragsanfragen initiiert. Wenn Sie keine mehrfachen Aufträge wünschen, stornieren Sie bitte eine Anfrage.'
    },
    'You have multiple jobs scheduled for': {
        pt: 'Você tem vários trabalhos agendados para',
        es: 'Tiene varios trabajos programados para',
        ru: 'У вас запланировано несколько работ на',
        fr: 'Vous avez plusieurs travaux programmés pour',
        de: 'Sie haben mehrere Aufträge geplant für'
    },
    'If you do not want multiple jobs on that day please cancel one.': {
        pt: 'Se você não quiser vários trabalhos nesse dia, cancele um.',
        es: 'Si no desea varios trabajos ese día, cancele uno.',
        ru: 'Если вы не хотите несколько работ в этот день, пожалуйста, отмените одну.',
        fr: 'Si vous ne voulez pas plusieurs travaux ce jour-là, veuillez en annuler un.',
        de: 'Wenn Sie an diesem Tag keine mehrfachen Aufträge wünschen, stornieren Sie bitte einen.'
    },
    'TIDY will automatically send a job request to your list of Pros once a reservation is within 4 weeks.': {
        pt: 'A TIDY enviará automaticamente uma solicitação de trabalho para sua lista de Profissionais assim que uma reserva estiver dentro de 4 semanas.',
        es: 'TIDY enviará automáticamente una solicitud de trabajo a su lista de Profesionales una vez que una reserva esté dentro de 4 semanas.',
        ru: 'TIDY автоматически отправит запрос на работу вашему списку Профессионалов, как только до бронирования останется 4 недели.',
        fr: 'TIDY enverra automatiquement une demande de travail à votre liste de Pros une fois qu\'une réservation sera à moins de 4 semaines.',
        de: 'TIDY sendet automatisch eine Auftragsanfrage an Ihre Liste von Profis, sobald eine Reservierung innerhalb von 4 Wochen liegt.'
    },
    'Your': {
        pt: 'Seu',
        es: 'Su',
        ru: 'Ваш',
        fr: 'Votre',
        de: 'Ihr'
    },
    'request is searching for a pro matching your preferences. When scheduled, we will message you.': {
        pt: 'pedido está procurando um profissional que corresponda às suas preferências. Quando agendado, enviaremos uma mensagem para você.',
        es: 'solicitud está buscando un profesional que coincida con sus preferencias. Cuando se programe, le enviaremos un mensaje.',
        ru: 'запрос ищет профессионала, соответствующего вашим предпочтениям. Когда будет запланировано, мы отправим вам сообщение.',
        fr: 'demande recherche un professionnel correspondant à vos préférences. Une fois programmé, nous vous enverrons un message.',
        de: 'Anfrage sucht nach einem Profi, der Ihren Präferenzen entspricht. Sobald geplant, werden wir Ihnen eine Nachricht senden.'
    },
    'Earliest Start: ': {
        pt: 'Início mais cedo: ',
        es: 'Inicio más temprano: ',
        ru: 'Самое раннее начало: ',
        fr: 'Début au plus tôt : ',
        de: 'Frühester Start: '
    },
    'Latest Finish: ': {
        pt: 'Término mais tarde: ',
        es: 'Finalización más tardía: ',
        ru: 'Самое позднее окончание: ',
        fr: 'Fin au plus tard : ',
        de: 'Spätestes Ende: '
    },
    'We are starting to contact pros in order of your priority list. Please check back soon for a full history.': {
        pt: 'Estamos começando a entrar em contato com os profissionais na ordem da sua lista de prioridades. Por favor, verifique em breve para um histórico completo.',
        es: 'Estamos empezando a contactar a los profesionales en el orden de su lista de prioridades. Por favor, vuelva pronto para ver un historial completo.',
        ru: 'Мы начинаем связываться с профессионалами в порядке вашего списка приоритетов. Пожалуйста, проверьте позже для полной истории.',
        fr: 'Nous commençons à contacter les pros dans l\'ordre de votre liste de priorités. Veuillez revenir bientôt pour un historique complet.',
        de: 'Wir beginnen damit, Profis in der Reihenfolge Ihrer Prioritätenliste zu kontaktieren. Bitte schauen Sie bald wieder vorbei, um eine vollständige Historie zu sehen.'
    },
    'View More History': {
        pt: 'Ver Mais Histórico',
        es: 'Ver Más Historial',
        ru: 'Посмотреть Больше Истории',
        fr: 'Voir Plus d\'Historique',
        de: 'Mehr Verlauf Anzeigen'
    },
    'Assign a Pro': {
        pt: 'Atribuir um Profissional',
        es: 'Asignar un Profesional',
        ru: 'Назначить Профессионала',
        fr: 'Attribuer un Pro',
        de: 'Einen Profi Zuweisen'
    },
    'No Pro Accepted Your Request': {
        pt: 'Nenhum Profissional Aceitou Sua Solicitação',
        es: 'Ningún Profesional Aceptó Su Solicitud',
        ru: 'Ни Один Профессионал Не Принял Ваш Запрос',
        fr: 'Aucun Pro N\'a Accepté Votre Demande',
        de: 'Kein Profi Hat Ihre Anfrage Angenommen'
    },
    'request was not accepted.': {
        pt: 'solicitação não foi aceita.',
        es: 'solicitud no fue aceptada.',
        ru: 'запрос не был принят.',
        fr: 'demande n\'a pas été acceptée.',
        de: 'Anfrage wurde nicht akzeptiert.'
    },
    'Request or Book Different Times': {
        pt: 'Solicitar ou Reservar Horários Diferentes',
        es: 'Solicitar o Reservar Diferentes Horarios',
        ru: 'Запросить или Забронировать Другое Время',
        fr: 'Demander ou Réserver des Horaires Différents',
        de: 'Andere Zeiten Anfragen oder Buchen'
    },
    '(Cancelled after Check-In)': {
        pt: '(Cancelado após Check-In)',
        es: '(Cancelado después del Check-In)',
        ru: '(Отменено после регистрации)',
        fr: '(Annulé après l\'enregistrement)',
        de: '(Nach dem Check-In storniert)'
    },
    '\'s Job': {
        pt: 'Trabalho de',
        es: 'Trabajo de',
        ru: 'Работа',
        fr: 'Travail de',
        de: 's Auftrag'
    },
    'Any negative feedback is anonymized. If': {
        pt: 'Qualquer feedback negativo é anonimizado. Se',
        es: 'Cualquier comentario negativo es anonimizado. Si',
        ru: 'Любой отрицательный отзыв анонимизируется. Если',
        fr: 'Tout retour négatif est anonymisé. Si',
        de: 'Jedes negative Feedback wird anonymisiert. Wenn'
    },
    'did not arrive please': {
        pt: 'não chegou, por favor',
        es: 'no llegó, por favor',
        ru: 'не прибыл, пожалуйста',
        fr: 'n\'est pas arrivé, veuillez',
        de: 'nicht angekommen ist, bitte'
    },
    'click here': {
        pt: 'clique aqui',
        es: 'haga clic aquí',
        ru: 'нажмите здесь',
        fr: 'cliquez ici',
        de: 'hier klicken'
    },
    'Needs Help': {
        pt: 'Precisa de Ajuda',
        es: 'Necesita Ayuda',
        ru: 'Нужна Помощь',
        fr: 'A Besoin d\'Aide',
        de: 'Braucht Hilfe'
    },
    'is having trouble accessing your property.  Skip Job': {
        pt: 'está tendo problemas para acessar sua propriedade. Pular Trabalho',
        es: 'está teniendo problemas para acceder a su propiedad. Saltar Trabajo',
        ru: 'испытывает трудности с доступом к вашей собственности. Пропустить Работу',
        fr: 'a des difficultés à accéder à votre propriété. Ignorer le Travail',
        de: 'hat Schwierigkeiten, auf Ihr Grundstück zuzugreifen. Auftrag Überspringen'
    },
    'Please reach out to help.': {
        pt: 'Por favor, entre em contato para ajudar.',
        es: 'Por favor, comuníquese para ayudar.',
        ru: 'Пожалуйста, свяжитесь, чтобы помочь.',
        fr: 'Veuillez nous contacter pour obtenir de l\'aide.',
        de: 'Bitte melden Sie sich, um zu helfen.'
    },
    'Action Needed': {
        pt: 'Ação Necessária',
        es: 'Acción Necesaria',
        ru: 'Требуется Действие',
        fr: 'Action Nécessaire',
        de: 'Handlung Erforderlich'
    },
    'confirmed they could not access your property.': {
        pt: 'confirmou que não conseguiu acessar sua propriedade.',
        es: 'confirmó que no pudo acceder a su propiedad.',
        ru: 'подтвердил, что не смог получить доступ к вашей собственности.',
        fr: 'a confirmé qu\'il ne pouvait pas accéder à votre propriété.',
        de: 'hat bestätigt, dass er keinen Zugang zu Ihrem Grundstück hatte.'
    },
    'If you believe': {
        pt: 'Se você acredita que',
        es: 'Si cree que',
        ru: 'Если вы считаете, что',
        fr: 'Si vous pensez que',
        de: 'Wenn Sie glauben, dass'
    },
    'was the one who cancelled, please': {
        pt: 'foi quem cancelou, por favor',
        es: 'fue quien canceló, por favor',
        ru: 'был тем, кто отменил, пожалуйста',
        fr: 'était celui qui a annulé, veuillez',
        de: 'derjenige war, der storniert hat, bitte'
    },
    'reported that you cancelled the job.': {
        pt: 'relatou que você cancelou o trabalho.',
        es: 'informó que usted canceló el trabajo.',
        ru: 'сообщил, что вы отменили работу.',
        fr: 'a signalé que vous avez annulé le travail.',
        de: 'hat gemeldet, dass Sie den Auftrag storniert haben.'
    },
    'Book New Time': {
        pt: 'Reservar Novo Horário',
        es: 'Reservar Nuevo Horario',
        ru: 'Забронировать Новое Время',
        fr: 'Réserver un Nouveau Créneau',
        de: 'Neue Zeit Buchen'
    },
    'You reported that': {
        pt: 'Você relatou que',
        es: 'Usted informó que',
        ru: 'Вы сообщили, что',
        fr: 'Vous avez signalé que',
        de: 'Sie haben gemeldet, dass'
    },
    'did not show up to the job.': {
        pt: 'não apareceu para o trabalho.',
        es: 'no se presentó al trabajo.',
        ru: 'не явился на работу.',
        fr: 'ne s\'est pas présenté au travail.',
        de: 'nicht zur Arbeit erschienen ist.'
    },
    'We\'re so sorry that they did that!  We are reaching out to find out what happened.': {
        pt: 'Lamentamos muito que isso tenha acontecido! Estamos entrando em contato para descobrir o que aconteceu.',
        es: '¡Lo sentimos mucho que hayan hecho eso! Nos estamos comunicando para averiguar qué sucedió.',
        ru: 'Нам очень жаль, что это произошло! Мы связываемся, чтобы выяснить, что случилось.',
        fr: 'Nous sommes vraiment désolés qu\'ils aient fait cela ! Nous les contactons pour savoir ce qui s\'est passé.',
        de: 'Es tut uns sehr leid, dass sie das getan haben! Wir setzen uns mit ihnen in Verbindung, um herauszufinden, was passiert ist.'
    },
    'Of course you will not be billed for this, which means any credit will remain in your account.': {
        pt: 'Claro que você não será cobrado por isso, o que significa que qualquer crédito permanecerá em sua conta.',
        es: 'Por supuesto, no se le cobrará por esto, lo que significa que cualquier crédito permanecerá en su cuenta.',
        ru: 'Конечно, с вас не будет взиматься плата за это, что означает, что любой кредит останется на вашем счету.',
        fr: 'Bien sûr, vous ne serez pas facturé pour cela, ce qui signifie que tout crédit restera sur votre compte.',
        de: 'Natürlich werden Sie dafür nicht berechnet, was bedeutet, dass jedes Guthaben auf Ihrem Konto verbleibt.'
    },
    'more updates': {
        pt: 'mais atualizações',
        es: 'más actualizaciones',
        ru: 'больше обновлений',
        fr: 'plus de mises à jour',
        de: 'weitere Updates'
    },
    'Searching for Substitute': {
        pt: 'Procurando Substituto',
        es: 'Buscando Sustituto',
        ru: 'Поиск Замены',
        fr: 'Recherche de Remplaçant',
        de: 'Suche nach Ersatz'
    },
    'Searching for Pro': {
        pt: 'Procurando Profissional',
        es: 'Buscando Profesional',
        ru: 'Поиск Профессионала',
        fr: 'Recherche de Professionnel',
        de: 'Suche nach Profi'
    },
    'Message from': {
        pt: 'Mensagem de',
        es: 'Mensaje de',
        ru: 'Сообщение от',
        fr: 'Message de',
        de: 'Nachricht von'
    },
    'Message from Concierge:': {
        pt: 'Mensagem do Concierge:',
        es: 'Mensaje del Conserje:',
        ru: 'Сообщение от Консьержа:',
        fr: 'Message du Concierge:',
        de: 'Nachricht vom Concierge:'
    },
    'Monday': {
        pt: 'Segunda-feira',
        es: 'Lunes',
        ru: 'Понедельник',
        fr: 'Lundi',
        de: 'Montag'
    },
    'Tuesday': {
        pt: 'Terça-feira',
        es: 'Martes',
        ru: 'Вторник',
        fr: 'Mardi',
        de: 'Dienstag'
    },
    'Wednesday': {
        pt: 'Quarta-feira',
        es: 'Miércoles',
        ru: 'Среда',
        fr: 'Mercredi',
        de: 'Mittwoch'
    },
    'Thursday': {
        pt: 'Quinta-feira',
        es: 'Jueves',
        ru: 'Четверг',
        fr: 'Jeudi',
        de: 'Donnerstag'
    },
    'Friday': {
        pt: 'Sexta-feira',
        es: 'Viernes',
        ru: 'Пятница',
        fr: 'Vendredi',
        de: 'Freitag'
    },
    'Saturday': {
        pt: 'Sábado',
        es: 'Sábado',
        ru: 'Суббота',
        fr: 'Samedi',
        de: 'Samstag'
    },
    'Sunday': {
        pt: 'Domingo',
        es: 'Domingo',
        ru: 'Воскресенье',
        fr: 'Dimanche',
        de: 'Sonntag'
    },
    'Cancel Request?': {
        pt: 'Cancelar Pedido?',
        es: '¿Cancelar Solicitud?',
        ru: 'Отменить Запрос?',
        fr: 'Annuler la Demande?',
        de: 'Anfrage Stornieren?'
    },
    'You can always create a new request if you\'d like.': {
        pt: 'Você sempre pode criar um novo pedido se desejar.',
        es: 'Siempre puede crear una nueva solicitud si lo desea.',
        ru: 'Вы всегда можете создать новый запрос, если хотите.',
        fr: 'Vous pouvez toujours créer une nouvelle demande si vous le souhaitez.',
        de: 'Sie können jederzeit eine neue Anfrage erstellen, wenn Sie möchten.'
    },
    'Skip Job?': {
        pt: 'Pular Trabalho?',
        es: '¿Saltar Trabajo?',
        ru: 'Пропустить Работу?',
        fr: 'Sauter le Travail?',
        de: 'Job Überspringen?'
    },
    'This will cancel the job (does not affect other jobs).': {
        pt: 'Isso cancelará o trabalho (não afeta outros trabalhos).',
        es: 'Esto cancelará el trabajo (no afecta a otros trabajos).',
        ru: 'Это отменит работу (не влияет на другие работы).',
        fr: 'Cela annulera le travail (n\'affecte pas les autres travaux).',
        de: 'Dies wird den Job stornieren (betrifft keine anderen Jobs).'
    },
    'Skip Job': {
        pt: 'Pular Trabalho',
        es: 'Saltar Trabajo',
        ru: 'Пропустить Работу',
        fr: 'Sauter le Travail',
        de: 'Job Überspringen'
    },
    'Keep Only 1 Pro?': {
        pt: 'Manter Apenas 1 Profissional?',
        es: '¿Mantener Solo 1 Profesional?',
        ru: 'Оставить Только 1 Профессионала?',
        fr: 'Garder Seulement 1 Pro?',
        de: 'Nur 1 Profi Behalten?'
    },
    'You\'ll be billed the prorated rate.': {
        pt: 'Você será cobrado pela taxa proporcional.',
        es: 'Se le cobrará la tarifa prorrateada.',
        ru: 'С вас будет взиматься пропорциональная плата.',
        fr: 'Vous serez facturé au tarif au prorata.',
        de: 'Ihnen wird der anteilige Tarif in Rechnung gestellt.'
    },
    'Kept One Pro': {
        pt: 'Manteve Um Profissional',
        es: 'Mantuvo Un Profesional',
        ru: 'Оставил Одного Профессионала',
        fr: 'A Gardé Un Pro',
        de: 'Einen Profi Behalten'
    },
    'You were billed the prorated rate. Please contact the concierge with any questions.': {
        pt: 'Você foi cobrado pela taxa proporcional. Por favor, entre em contato com o concierge se tiver alguma dúvida.',
        es: 'Se le cobró la tarifa prorrateada. Por favor, contacte al conserje si tiene alguna pregunta.',
        ru: 'С вас взяли пропорциональную плату. Пожалуйста, свяжитесь с консьержем, если у вас есть вопросы.',
        fr: 'Vous avez été facturé au tarif au prorata. Veuillez contacter le concierge pour toute question.',
        de: 'Ihnen wurde der anteilige Tarif in Rechnung gestellt. Bitte wenden Sie sich bei Fragen an den Concierge.'
    },

    //ContactProPage
    'Contact Pro': {
        pt: 'Contatar Profissional',
        es: 'Contactar Profesional',
        ru: 'Связаться с Профессионалом',
        fr: 'Contacter le Pro',
        de: 'Profi Kontaktieren'
    },
    'Text': {
        pt: 'Mensagem',
        es: 'Mensaje',
        ru: 'Сообщение',
        fr: 'Message',
        de: 'Nachricht'
    },
    'if': {
        pt: 'se',
        es: 'si',
        ru: 'если',
        fr: 'si',
        de: 'wenn'
    },
    'is not responding and did not show up.': {
        pt: 'não está respondendo e não apareceu.',
        es: 'no está respondiendo y no se presentó.',
        ru: 'не отвечает и не появился.',
        fr: 'ne répond pas et ne s\'est pas présenté.',
        de: 'nicht antwortet und nicht erschienen ist.'
    },
    'You can call / text your Pro once it is within 30 minutes of the job.': {
        pt: 'Você pode ligar / enviar mensagem para o seu Profissional quando estiver a 30 minutos do trabalho.',
        es: 'Puedes llamar / enviar un mensaje a tu Profesional cuando esté a 30 minutos del trabajo.',
        ru: 'Вы можете позвонить / отправить сообщение вашему Профессионалу, когда до работы останется 30 минут.',
        fr: 'Vous pouvez appeler / envoyer un message à votre Pro une fois que vous êtes à 30 minutes du travail.',
        de: 'Sie können Ihren Profi anrufen / ihm eine Nachricht senden, wenn es noch 30 Minuten bis zum Job sind.'
    },
    'To protect your privacy, we generate a temporary phone number for you to use for each job.': {
        pt: 'Para proteger sua privacidade, geramos um número de telefone temporário para você usar em cada trabalho.',
        es: 'Para proteger tu privacidad, generamos un número de teléfono temporal para que lo uses en cada trabajo.',
        ru: 'Для защиты вашей конфиденциальности мы генерируем временный номер телефона для использования в каждой работе.',
        fr: 'Pour protéger votre vie privée, nous générons un numéro de téléphone temporaire à utiliser pour chaque travail.',
        de: 'Um Ihre Privatsphäre zu schützen, generieren wir eine temporäre Telefonnummer, die Sie für jeden Auftrag verwenden können.'
    },
    'Please use your phone number on file:': {
        pt: 'Por favor, use o número de telefone registrado:',
        es: 'Por favor, usa el número de teléfono registrado:',
        ru: 'Пожалуйста, используйте ваш номер телефона в файле:',
        fr: 'Veuillez utiliser votre numéro de téléphone enregistré:',
        de: 'Bitte verwenden Sie Ihre hinterlegte Telefonnummer:'
    },
    'Please Add Phone Number': {
        pt: 'Por favor, Adicione o Número de Telefone',
        es: 'Por favor, Agrega el Número de Teléfono',
        ru: 'Пожалуйста, Добавьте Номер Телефона',
        fr: 'Veuillez Ajouter un Numéro de Téléphone',
        de: 'Bitte Telefonnummer Hinzufügen'
    },
    'You cannot contact your Pro and they cannot contact you because you don\'t have a phone number on file.': {
        pt: 'Você não pode contatar seu Profissional e eles não podem contatá-lo porque você não tem um número de telefone registrado.',
        es: 'No puedes contactar a tu Profesional y ellos no pueden contactarte porque no tienes un número de teléfono registrado.',
        ru: 'Вы не можете связаться с вашим Профессионалом, и они не могут связаться с вами, потому что у вас нет номера телефона в файле.',
        fr: 'Vous ne pouvez pas contacter votre Pro et ils ne peuvent pas vous contacter car vous n\'avez pas de numéro de téléphone enregistré.',
        de: 'Sie können Ihren Profi nicht kontaktieren und er kann Sie nicht kontaktieren, weil Sie keine Telefonnummer hinterlegt haben.'
    },
    'Please Wait to Mark No Show': {
        pt: 'Por favor, Aguarde para Marcar Não Comparecimento',
        es: 'Por favor, Espera para Marcar No Presentación',
        ru: 'Пожалуйста, Подождите, Чтобы Отметить Неявку',
        fr: 'Veuillez Attendre pour Marquer l\'Absence',
        de: 'Bitte Warten Sie, um Nichterscheinen zu Markieren'
    },
    'Pros typically ask for a 30 minute buffer for unexpected delays. Please wait until 20 minutes after start time before reporting them as a no show.': {
        pt: 'Os profissionais geralmente pedem um intervalo de 30 minutos para atrasos inesperados. Por favor, aguarde até 20 minutos após o horário de início antes de relatá-los como não comparecimento.',
        es: 'Los profesionales generalmente piden un margen de 30 minutos para retrasos inesperados. Por favor, espera hasta 20 minutos después de la hora de inicio antes de reportarlos como no presentados.',
        ru: 'Профессионалы обычно просят 30-минутный буфер для непредвиденных задержек. Пожалуйста, подождите 20 минут после времени начала, прежде чем сообщать о их неявке.',
        fr: 'Les pros demandent généralement un délai de 30 minutes pour les retards imprévus. Veuillez attendre 20 minutes après l\'heure de début avant de les signaler comme absents.',
        de: 'Profis bitten in der Regel um einen 30-minütigen Puffer für unerwartete Verzögerungen. Bitte warten Sie bis 20 Minuten nach der Startzeit, bevor Sie sie als nicht erschienen melden.'
    },

    //EditPlanPage
    'What would you like to do?': {
        pt: 'O que você gostaria de fazer?',
        es: '¿Qué te gustaría hacer?',
        ru: 'Что бы вы хотели сделать?',
        fr: 'Que voulez-vous faire ?',
        de: 'Was möchten Sie tun?'
    },
    'Edit Job': {
        pt: 'Editar Trabalho',
        es: 'Editar Trabajo',
        ru: 'Редактировать Работу',
        fr: 'Modifier le Travail',
        de: 'Auftrag Bearbeiten'
    },
    'Are you sure you want to cancel this job?': {
        pt: 'Tem certeza de que deseja cancelar este trabalho?',
        es: '¿Estás seguro de que quieres cancelar este trabajo?',
        ru: 'Вы уверены, что хотите отменить эту работу?',
        fr: 'Êtes-vous sûr de vouloir annuler ce travail ?',
        de: 'Sind Sie sicher, dass Sie diesen Auftrag stornieren möchten?'
    },
    'Are you sure you want to cancel this plan?': {
        pt: 'Tem certeza de que deseja cancelar este plano?',
        es: '¿Estás seguro de que quieres cancelar este plan?',
        ru: 'Вы уверены, что хотите отменить этот план?',
        fr: 'Êtes-vous sûr de vouloir annuler ce plan ?',
        de: 'Sind Sie sicher, dass Sie diesen Plan stornieren möchten?'
    },

    //JobPage
    'Send Link to': {
        pt: 'Enviar Link para',
        es: 'Enviar Enlace a',
        ru: 'Отправить Ссылку',
        fr: 'Envoyer le Lien à',
        de: 'Link Senden an'
    },
    'Mark Job as Complete': {
        pt: 'Marcar Trabalho como Concluído',
        es: 'Marcar Trabajo como Completado',
        ru: 'Отметить Работу как Завершенную',
        fr: 'Marquer le Travail comme Terminé',
        de: 'Auftrag als Abgeschlossen Markieren'
    },
    '<i>None Added</i>': {
        pt: '<i>Nenhuma Adicionada</i>',
        es: '<i>Ninguna Añadida</i>',
        ru: '<i>Ничего не Добавлено</i>',
        fr: '<i>Aucune Ajoutée</i>',
        de: '<i>Keine Hinzugefügt</i>'
    },
    'Backup Times': {
        pt: 'Horários de Backup',
        es: 'Horarios de Respaldo',
        ru: 'Резервное Время',
        fr: 'Horaires de Secours',
        de: 'Ausweichzeiten'
    },
    'times added': {
        pt: 'horários adicionados',
        es: 'horarios añadidos',
        ru: 'времени добавлено',
        fr: 'horaires ajoutés',
        de: 'Zeiten hinzugefügt'
    },
    'time added': {
        pt: 'horário adicionado',
        es: 'horario añadido',
        ru: 'время добавлено',
        fr: 'horaire ajouté',
        de: 'Zeit hinzugefügt'
    },
    'is having trouble accessing your property.  Please reach out to help.': {
        pt: 'está tendo problemas para acessar sua propriedade. Por favor, entre em contato para ajudar.',
        es: 'está teniendo problemas para acceder a tu propiedad. Por favor, comunícate para ayudar.',
        ru: 'испытывает трудности с доступом к вашей собственности. Пожалуйста, свяжитесь, чтобы помочь.',
        fr: 'a des difficultés à accéder à votre propriété. Veuillez le contacter pour l\'aider.',
        de: 'hat Schwierigkeiten, auf Ihr Grundstück zuzugreifen. Bitte melden Sie sich, um zu helfen.'
    },
    'Assign to Your Own Pro': {
        pt: 'Atribuir ao Seu Próprio Profissional',
        es: 'Asignar a Tu Propio Profesional',
        ru: 'Назначить Своему Специалисту',
        fr: 'Attribuer à Votre Propre Professionnel',
        de: 'Ihrem Eigenen Profi Zuweisen'
    },
    'List saved to job.': {
        pt: 'Lista salva no trabalho.',
        es: 'Lista guardada en el trabajo.',
        ru: 'Список сохранен в задании.',
        fr: 'Liste enregistrée pour le travail.',
        de: 'Liste zum Auftrag gespeichert.'
    },
    'To-Do List': {
        pt: 'Lista de Tarefas',
        es: 'Lista de Tareas',
        ru: 'Список Дел',
        fr: 'Liste de Tâches',
        de: 'To-Do-Liste'
    },
    'Change saved.': {
        pt: 'Alteração salva.',
        es: 'Cambio guardado.',
        ru: 'Изменение сохранено.',
        fr: 'Modification enregistrée.',
        de: 'Änderung gespeichert.'
    },
    'Saving...': {
        pt: 'Salvando...',
        es: 'Guardando...',
        ru: 'Сохранение...',
        fr: 'Enregistrement...',
        de: 'Speichern...'
    },
    'and': {
        pt: 'e',
        es: 'y',
        ru: 'и',
        fr: 'et',
        de: 'und'
    },
    'are': {
        pt: 'estão',
        es: 'están',
        ru: 'являются',
        fr: 'sont',
        de: 'sind'
    },
    'is': {
        pt: 'está',
        es: 'está',
        ru: 'является',
        fr: 'est',
        de: 'ist'
    },
    'working on your To-Do list now:': {
        pt: 'trabalhando na sua lista de tarefas agora:',
        es: 'trabajando en tu lista de tareas ahora:',
        ru: 'работает над вашим списком дел сейчас:',
        fr: 'travaille sur votre liste de tâches maintenant:',
        de: 'arbeitet jetzt an Ihrer To-Do-Liste:'
    },
    'Uploaded By:': {
        pt: 'Carregado Por:',
        es: 'Subido Por:',
        ru: 'Загружено:',
        fr: 'Téléchargé Par:',
        de: 'Hochgeladen Von:'
    },
    'None Added': {
        pt: 'Nenhum Adicionado',
        es: 'Ninguno Añadido',
        ru: 'Ничего не Добавлено',
        fr: 'Aucun Ajouté',
        de: 'Keine Hinzugefügt'
    },
    '(Before Photo Requested)': {
        pt: '(Foto Antes Solicitada)',
        es: '(Foto Antes Solicitada)',
        ru: '(Запрошено Фото До)',
        fr: '(Photo Avant Demandée)',
        de: '(Vorher-Foto Angefordert)'
    },
    '(After Photo Requested)': {
        pt: '(Foto Depois Solicitada)',
        es: '(Foto Después Solicitada)',
        ru: '(Запрошено Фото После)',
        fr: '(Photo Après Demandée)',
        de: '(Nachher-Foto Angefordert)'
    },
    '(None Requested)': {
        pt: '(Nenhum Solicitado)',
        es: '(Ninguno Solicitado)',
        ru: '(Ничего не Запрошено)',
        fr: '(Aucun Demandé)',
        de: '(Keine Angefordert)'
    },
    '(Before Video Requested)': {
        pt: '(Vídeo Antes Solicitado)',
        es: '(Video Antes Solicitado)',
        ru: '(Запрошено Видео До)',
        fr: '(Vidéo Avant Demandée)',
        de: '(Vorher-Video Angefordert)'
    },
    '(After Video Requested)': {
        pt: '(Vídeo Depois Solicitado)',
        es: '(Video Después Solicitado)',
        ru: '(Запрошено Видео После)',
        fr: '(Vidéo Après Demandée)',
        de: '(Nachher-Video Angefordert)'
    },
    'Don\'t Use To-Dos': {
        pt: 'Não usar Lista de Tarefas',
        es: 'No Usar Tareas',
        ru: 'Не Использовать Список Дел',
        fr: 'Ne Pas Utiliser les Tâches',
        de: 'To-Dos Nicht Verwenden'
    },
    'Unable to Call': {
        pt: 'Impossível Ligar',
        es: 'No se Puede Llamar',
        ru: 'Невозможно Позвонить',
        fr: 'Impossible d\'Appeler',
        de: 'Anruf Nicht Möglich'
    },
    'You can call / message your Pro once it is within 30 minutes of the job.': {
        pt: 'Você pode ligar / enviar mensagem para o seu Profissional quando estiver a 30 minutos do trabalho.',
        es: 'Puede llamar / enviar un mensaje a su Profesional cuando esté a 30 minutos del trabajo.',
        ru: 'Вы можете позвонить / отправить сообщение вашему Профессионалу, когда до работы останется 30 минут.',
        fr: 'Vous pouvez appeler / envoyer un message à votre Pro une fois que vous êtes à 30 minutes du travail.',
        de: 'Sie können Ihren Profi anrufen / ihm eine Nachricht senden, sobald es innerhalb von 30 Minuten vor dem Auftrag ist.'
    },
    'Public reply': {
        pt: 'Resposta pública',
        es: 'Respuesta pública',
        ru: 'Публичный ответ',
        fr: 'Réponse publique',
        de: 'Öffentliche Antwort'
    },

    //JobActivityPage
    'Messages & Updates': {
        pt: 'Mensagens e Atualizações',
        es: 'Mensajes y Actualizaciones',
        ru: 'Сообщения и Обновления',
        fr: 'Messages et Mises à Jour',
        de: 'Nachrichten & Updates'
    },

    //MarkJobCompletePage
    'Mark Job Complete': {
        pt: 'Marcar Trabalho como Concluído',
        es: 'Marcar Trabajo como Completado',
        ru: 'Отметить Работу как Завершенную',
        fr: 'Marquer le Travail comme Terminé',
        de: 'Auftrag als Abgeschlossen Markieren'
    },
    'Job price': {
        pt: 'Preço do trabalho',
        es: 'Precio del trabajo',
        ru: 'Цена работы',
        fr: 'Prix du travail',
        de: 'Auftragspreis'
    },
    'Job duration': {
        pt: 'Duração do trabalho',
        es: 'Duración del trabajo',
        ru: 'Продолжительность работы',
        fr: 'Durée du travail',
        de: 'Auftragsdauer'
    },
    'Mark as Complete': {
        pt: 'Marcar como Concluído',
        es: 'Marcar como Completado',
        ru: 'Отметить как Завершенное',
        fr: 'Marquer comme Terminé',
        de: 'Als Abgeschlossen Markieren'
    },
    'Job marked complete': {
        pt: 'Trabalho marcado como concluído',
        es: 'Trabajo marcado como completado',
        ru: 'Работа отмечена как завершенная',
        fr: 'Travail marqué comme terminé',
        de: 'Auftrag als abgeschlossen markiert'
    },

    //NotificationDetailPage
    'TIDY successfully sent this sms to': {
        pt: 'TIDY enviou com sucesso este SMS para',
        es: 'TIDY envió exitosamente este SMS a',
        ru: 'TIDY успешно отправил это SMS',
        fr: 'TIDY a envoyé avec succès ce SMS à',
        de: 'TIDY hat diese SMS erfolgreich gesendet an'
    },
    'TIDY successfully sent this email to': {
        pt: 'TIDY enviou com sucesso este e-mail para',
        es: 'TIDY envió exitosamente este correo electrónico a',
        ru: 'TIDY успешно отправил это электронное письмо',
        fr: 'TIDY a envoyé avec succès cet e-mail à',
        de: 'TIDY hat diese E-Mail erfolgreich gesendet an'
    },
    'TIDY successfully sent this in app notification to': {
        pt: 'TIDY enviou com sucesso esta notificação no aplicativo para',
        es: 'TIDY envió exitosamente esta notificación en la aplicación a',
        ru: 'TIDY успешно отправил это уведомление в приложении',
        fr: 'TIDY a envoyé avec succès cette notification dans l\'application à',
        de: 'TIDY hat diese In-App-Benachrichtigung erfolgreich gesendet an'
    },
    'Email Details': {
        pt: 'Detalhes do E-mail',
        es: 'Detalles del Correo Electrónico',
        ru: 'Детали Электронного Письма',
        fr: 'Détails de l\'E-mail',
        de: 'E-Mail-Details'
    },
    'SMS Details': {
        pt: 'Detalhes do SMS',
        es: 'Detalles del SMS',
        ru: 'Детали SMS',
        fr: 'Détails du SMS',
        de: 'SMS-Details'
    },
    'App Notification Details': {
        pt: 'Detalhes da Notificação do Aplicativo',
        es: 'Detalles de la Notificación de la Aplicación',
        ru: 'Детали Уведомления Приложения',
        fr: 'Détails de la Notification de l\'Application',
        de: 'App-Benachrichtigungsdetails'
    },

    //ReservationsOfDayPage
    'Reservations of Day': {
        pt: 'Reservas do Dia',
        es: 'Reservas del Día',
        ru: 'Бронирования на День',
        fr: 'Réservations du Jour',
        de: 'Reservierungen des Tages',
    },
    'Check in:': {
        pt: 'Check-in:',
        es: 'Entrada:',
        ru: 'Заезд:',
        fr: 'Arrivée:',
        de: 'Check-in:',
    },
    'Check out:': {
        pt: 'Check-out:',
        es: 'Salida:',
        ru: 'Выезд:',
        fr: 'Départ:',
        de: 'Check-out:',
    },

    //ScheduleMonthlyViewComponent
    'out': {
        pt: 'saída',
        es: 'salida',
        ru: 'выезд',
        fr: 'départ',
        de: 'Abreise',
    },
    'in': {
        pt: 'entrada',
        es: 'entrada',
        ru: 'въезд',
        fr: 'arrivée',
        de: 'Ankunft',
    },
    'more': {
        pt: 'mais',
        es: 'más',
        ru: 'ещё',
        fr: 'plus',
        de: 'mehr',
    },

    //ScheduleWeeklyFilterComponent
    'Type': {
        pt: 'Tipo',
        es: 'Tipo',
        ru: 'Тип',
        fr: 'Type',
        de: 'Typ',
    },
    'Property Name': {
        pt: 'Nome da Propriedade',
        es: 'Nombre de la Propiedad',
        ru: 'Название Объекта',
        fr: 'Nom de la Propriété',
        de: 'Objektname',
    },
    'Week': {
        pt: 'Semana',
        es: 'Semana',
        ru: 'Неделя',
        fr: 'Semaine',
        de: 'Woche',
    },
    'Month': {
        pt: 'Mês',
        es: 'Mes',
        ru: 'Месяц',
        fr: 'Mois',
        de: 'Monat',
    },
    'Scheduled': {
        pt: 'Agendado',
        es: 'Programado',
        ru: 'Запланировано',
        fr: 'Planifié',
        de: 'Geplant',
    },
    'In Progress': {
        pt: 'Em Andamento',
        es: 'En Progreso',
        ru: 'В Процессе',
        fr: 'En Cours',
        de: 'In Bearbeitung',
    },
    'Having Issues': {
        pt: 'Com Problemas',
        es: 'Con Problemas',
        ru: 'Есть Проблемы',
        fr: 'Avec des Problèmes',
        de: 'Mit Problemen',
    },
    'Requested': {
        pt: 'Solicitado',
        es: 'Solicitado',
        ru: 'Запрошено',
        fr: 'Demandé',
        de: 'Angefragt',
    },
    'Not Accepted': {
        pt: 'Não Aceito',
        es: 'No Aceptado',
        ru: 'Не Принято',
        fr: 'Non Accepté',
        de: 'Nicht Akzeptiert',
    },
    'Completed': {
        pt: 'Concluído',
        es: 'Completado',
        ru: 'Завершено',
        fr: 'Terminé',
        de: 'Abgeschlossen',
    },
    'Cancelled': {
        pt: 'Cancelado',
        es: 'Cancelado',
        ru: 'Отменено',
        fr: 'Annulé',
        de: 'Storniert',
    },
    'Pro Cancelled': {
        pt: 'Cancelado pelo Profissional',
        es: 'Cancelado por el Profesional',
        ru: 'Отменено Профессионалом',
        fr: 'Annulé par le Professionnel',
        de: 'Vom Profi Storniert',
    },

    //ScheduleWeeklyViewComponent
    'Next Check In': {
        pt: 'Próximo Check-in',
        es: 'Próximo Check-in',
        ru: 'Следующая Регистрация',
        fr: 'Prochain Enregistrement',
        de: 'Nächster Check-in',
    },
    '(Today)': {
        pt: '(Hoje)',
        es: '(Hoy)',
        ru: '(Сегодня)',
        fr: '(Aujourd\'hui)',
        de: '(Heute)',
    },
    'View All Times': {
        pt: 'Ver Todos os Horários',
        es: 'Ver Todos los Horarios',
        ru: 'Просмотреть Все Время',
        fr: 'Voir Tous les Horaires',
        de: 'Alle Zeiten Anzeigen',
    },

    //SummaryOfDayPage
    'Summary of Day': {
        pt: 'Resumo do Dia',
        es: 'Resumen del Día',
        ru: 'Сводка за День',
        fr: 'Résumé de la Journée',
        de: 'Tageszusammenfassung',
    },
    'No events on this day': {
        pt: 'Nenhum evento neste dia',
        es: 'No hay eventos en este día',
        ru: 'Нет событий на этот день',
        fr: 'Aucun événement ce jour',
        de: 'Keine Ereignisse an diesem Tag',
    },

    //SelectCategoryPage
    'Service Type': {
        pt: 'Tipo de Serviço',
        es: 'Tipo de Servicio',
        ru: 'Тип Услуги',
        fr: 'Type de Service',
        de: 'Dienstleistungsart',
    },
    'Job Time': {
        pt: 'Hora do Trabalho',
        es: 'Hora del Trabajo',
        ru: 'Время Работы',
        fr: 'Heure du Travail',
        de: 'Arbeitszeit',
    },
    'This pro doesn\'t offer any services in this category. Please ask them to add services in this category.': {
        pt: 'Este profissional não oferece nenhum serviço nesta categoria. Por favor, peça-lhe para adicionar serviços nesta categoria.',
        es: 'Este profesional no ofrece ningún servicio en esta categoría. Por favor, pídele que agregue servicios en esta categoría.',
        ru: 'Этот профессионал не предлагает услуг в этой категории. Пожалуйста, попросите его добавить услуги в эту категорию.',
        fr: 'Ce professionnel n\'offre aucun service dans cette catégorie. Veuillez lui demander d\'ajouter des services dans cette catégorie.',
        de: 'Dieser Profi bietet keine Dienstleistungen in dieser Kategorie an. Bitte fragen Sie ihn, ob er Dienstleistungen in dieser Kategorie hinzufügen kann.',
    },
    'You haven\'t added any': {
        pt: 'Você não adicionou nenhum',
        es: 'No has añadido ningún',
        ru: 'Вы не добавили ни одного',
        fr: 'Vous n\'avez ajouté aucun',
        de: 'Sie haben keine',
    },
    'pros yet. Add someone you work with to send them requests to do your jobs.': {
        pt: 'profissionais ainda. Adicione alguém com quem você trabalha para enviar-lhes solicitações para fazer seus trabalhos.',
        es: 'profesionales todavía. Agrega a alguien con quien trabajas para enviarles solicitudes para hacer tus trabajos.',
        ru: 'профессионалов еще. Добавьте кого-нибудь, с кем вы работаете, чтобы отправлять им запросы на выполнение ваших работ.',
        fr: 'professionnels encore. Ajoutez quelqu\'un avec qui vous travaillez pour leur envoyer des demandes pour effectuer vos travaux.',
        de: 'Profis hinzugefügt. Fügen Sie jemanden hinzu, mit dem Sie arbeiten, um ihnen Anfragen für Ihre Aufträge zu senden.',
    },
    'Assign pro': {
        pt: 'Atribuir profissional',
        es: 'Asignar profesional',
        ru: 'Назначить профессионала',
        fr: 'Attribuer un professionnel',
        de: 'Profi zuweisen',
    },
    'Book or request': {
        pt: 'Reservar ou solicitar',
        es: 'Reservar o solicitar',
        ru: 'Забронировать или запросить',
        fr: 'Réserver ou demander',
        de: 'Buchen oder anfragen',
    },

    //SendPaymentPage
    'credit.': {
        pt: 'de crédito.',
        es: 'de crédito.',
        ru: 'кредита.',
        fr: 'de crédit.',
        de: 'Guthaben.',
    },
    'If you want to send more, we will charge your card for the difference.': {
        pt: 'Se você quiser enviar mais, cobraremos a diferença no seu cartão.',
        es: 'Si deseas enviar más, cargaremos la diferencia a tu tarjeta.',
        ru: 'Если вы хотите отправить больше, мы снимем разницу с вашей карты.',
        fr: 'Si vous voulez envoyer plus, nous débiterons votre carte pour la différence.',
        de: 'Wenn Sie mehr senden möchten, belasten wir Ihre Karte mit der Differenz.',
    },
    'Please add a credit/debit card to your account to send payment.': {
        pt: 'Por favor, adicione um cartão de crédito/débito à sua conta para enviar o pagamento.',
        es: 'Por favor, añade una tarjeta de crédito/débito a tu cuenta para enviar el pago.',
        ru: 'Пожалуйста, добавьте кредитную/дебетовую карту к вашему аккаунту для отправки платежа.',
        fr: 'Veuillez ajouter une carte de crédit/débit à votre compte pour envoyer le paiement.',
        de: 'Bitte fügen Sie Ihrem Konto eine Kredit-/Debitkarte hinzu, um die Zahlung zu senden.',
    },
    'Add Credit/Debit Card': {
        pt: 'Adicionar Cartão de Crédito/Débito',
        es: 'Añadir Tarjeta de Crédito/Débito',
        ru: 'Добавить Кредитную/Дебетовую Карту',
        fr: 'Ajouter une Carte de Crédit/Débit',
        de: 'Kredit-/Debitkarte Hinzufügen',
    },
    'How much do you want to send?': {
        pt: 'Quanto você quer enviar?',
        es: '¿Cuánto quieres enviar?',
        ru: 'Сколько вы хотите отправить?',
        fr: 'Combien voulez-vous envoyer ?',
        de: 'Wie viel möchten Sie senden?',
    },
    'Amount': {
        pt: 'Quantia',
        es: 'Cantidad',
        ru: 'Сумма',
        fr: 'Montant',
        de: 'Betrag',
    },
    'Please select an amount.': {
        pt: 'Por favor, selecione uma quantia.',
        es: 'Por favor, selecciona una cantidad.',
        ru: 'Пожалуйста, выберите сумму.',
        fr: 'Veuillez sélectionner un montant.',
        de: 'Bitte wählen Sie einen Betrag aus.',
    },
    'What is it for?': {
        pt: 'Para que é?',
        es: '¿Para qué es?',
        ru: 'Для чего это?',
        fr: 'À quoi ça sert ?',
        de: 'Wofür ist es?',
    },
    'No services offered.': {
        pt: 'Nenhum serviço oferecido.',
        es: 'No se ofrecen servicios.',
        ru: 'Услуги не предлагаются.',
        fr: 'Aucun service offert.',
        de: 'Keine Dienstleistungen angeboten.',
    },
    'Please add a credit/debit card to send this amount. Any credit in your account will be used before any charges.': {
        pt: 'Por favor, adicione um cartão de crédito/débito para enviar esta quantia. Qualquer crédito em sua conta será usado antes de qualquer cobrança.',
        es: 'Por favor, añade una tarjeta de crédito/débito para enviar esta cantidad. Cualquier crédito en tu cuenta se utilizará antes de realizar cargos.',
        ru: 'Пожалуйста, добавьте кредитную/дебетовую карту, чтобы отправить эту сумму. Любой кредит на вашем счете будет использован до снятия средств.',
        fr: 'Veuillez ajouter une carte de crédit/débit pour envoyer ce montant. Tout crédit sur votre compte sera utilisé avant tout frais.',
        de: 'Bitte fügen Sie eine Kredit-/Debitkarte hinzu, um diesen Betrag zu senden. Jegliches Guthaben auf Ihrem Konto wird vor etwaigen Belastungen verwendet.',
    },
    'Your card was declined. Please add a new credit/debit card. Any credit in your account will be used before any charges.': {
        pt: 'Seu cartão foi recusado. Por favor, adicione um novo cartão de crédito/débito. Qualquer crédito em sua conta será usado antes de qualquer cobrança.',
        es: 'Tu tarjeta fue rechazada. Por favor, añade una nueva tarjeta de crédito/débito. Cualquier crédito en tu cuenta se utilizará antes de realizar cargos.',
        ru: 'Ваша карта была отклонена. Пожалуйста, добавьте новую кредитную/дебетовую карту. Любой кредит на вашем счете будет использован до снятия средств.',
        fr: 'Votre carte a été refusée. Veuillez ajouter une nouvelle carte de crédit/débit. Tout crédit sur votre compte sera utilisé avant tout frais.',
        de: 'Ihre Karte wurde abgelehnt. Bitte fügen Sie eine neue Kredit-/Debitkarte hinzu. Jegliches Guthaben auf Ihrem Konto wird vor etwaigen Belastungen verwendet.',
    },
    'Payment Sent': {
        pt: 'Pagamento Enviado',
        es: 'Pago Enviado',
        ru: 'Платеж Отправлен',
        fr: 'Paiement Envoyé',
        de: 'Zahlung Gesendet',
    },

    //ShareJobPage
    'Copy Job Link': {
        pt: 'Copiar Link do Trabalho',
        es: 'Copiar Enlace del Trabajo',
        ru: 'Копировать Ссылку на Работу',
        fr: 'Copier le Lien du Travail',
        de: 'Job-Link Kopieren',
    },
    'about job links': {
        pt: 'sobre links de trabalho',
        es: 'acerca de los enlaces de trabajo',
        ru: 'о ссылках на работу',
        fr: 'à propos des liens de travail',
        de: 'über Job-Links',
    },

    //ShareListPage
    'Share To-Do List': {
        pt: 'Compartilhar Lista de Tarefas',
        es: 'Compartir Lista de Tareas',
        ru: 'Поделиться Списком Дел',
        fr: 'Partager la Liste de Tâches',
        de: 'To-Do-Liste Teilen',
    },
    'Share your To-Do List with anyone and they can mark what was done:': {
        pt: 'Compartilhe sua Lista de Tarefas com qualquer pessoa e eles podem marcar o que foi feito:',
        es: 'Comparte tu Lista de Tareas con cualquiera y ellos pueden marcar lo que se hizo:',
        ru: 'Поделитесь своим Списком Дел с кем угодно, и они смогут отметить, что было сделано:',
        fr: 'Partagez votre Liste de Tâches avec n\'importe qui et ils peuvent marquer ce qui a été fait :',
        de: 'Teilen Sie Ihre To-Do-Liste mit jedem, und sie können markieren, was erledigt wurde:',
    },
    'Copy To-Do List Link': {
        pt: 'Copiar Link da Lista de Tarefas',
        es: 'Copiar Enlace de la Lista de Tareas',
        ru: 'Копировать Ссылку на Список Дел',
        fr: 'Copier le Lien de la Liste de Tâches',
        de: 'To-Do-Listen-Link Kopieren',
    },
    'about To-Do List links': {
        pt: 'sobre links de Lista de Tarefas',
        es: 'acerca de los enlaces de Lista de Tareas',
        ru: 'о ссылках на Список Дел',
        fr: 'à propos des liens de Liste de Tâches',
        de: 'über To-Do-Listen-Links',
    },

    //SubscriptionsPage
    'You are currently on the following subscription:': {
        pt: 'Você está atualmente na seguinte assinatura:',
        es: 'Actualmente tienes la siguiente suscripción:',
        ru: 'В настоящее время у вас следующая подписка:',
        fr: 'Vous êtes actuellement sur l\'abonnement suivant :',
        de: 'Sie haben derzeit das folgende Abonnement:',
    },
    'You changed to a': {
        pt: 'Você mudou para um',
        es: 'Cambiaste a un',
        ru: 'Вы перешли на',
        fr: 'Vous êtes passé à un',
        de: 'Sie haben zu einem',
    },
    '. This change will take effect on': {
        pt: '. Esta mudança entrará em vigor em',
        es: '. Este cambio entrará en vigor el',
        ru: '. Это изменение вступит в силу',
        fr: '. Ce changement prendra effet le',
        de: '. Diese Änderung tritt in Kraft am',
    },
    'Monthly Bill': {
        pt: 'Fatura Mensal',
        es: 'Factura Mensual',
        ru: 'Ежемесячный счет',
        fr: 'Facture Mensuelle',
        de: 'Monatliche Rechnung',
    },
    'Yearly Bill': {
        pt: 'Fatura Anual',
        es: 'Factura Anual',
        ru: 'Годовой счет',
        fr: 'Facture Annuelle',
        de: 'Jährliche Rechnung',
    },
    'Next Due Date': {
        pt: 'Próxima Data de Vencimento',
        es: 'Próxima Fecha de Vencimiento',
        ru: 'Следующая дата оплаты',
        fr: 'Prochaine Date d\'Échéance',
        de: 'Nächstes Fälligkeitsdatum',
    },
    'N/A': {
        pt: 'N/A',
        es: 'N/A',
        ru: 'Н/Д',
        fr: 'N/A',
        de: 'N/V',
    },
    'Please': {
        pt: 'Por favor',
        es: 'Por favor',
        ru: 'Пожалуйста',
        fr: 'Veuillez',
        de: 'Bitte',
    },
    'contact': {
        pt: 'contate',
        es: 'contacte',
        ru: 'свяжитесь с',
        fr: 'contactez',
        de: 'kontaktieren Sie',
    },
    'our concierge for any questions or changes. We are happy to help get it right.': {
        pt: 'nosso concierge para quaisquer perguntas ou alterações. Estamos felizes em ajudar a acertar.',
        es: 'a nuestro conserje para cualquier pregunta o cambio. Estamos encantados de ayudar a hacerlo bien.',
        ru: 'нашим консьержем по любым вопросам или изменениям. Мы рады помочь все исправить.',
        fr: 'notre concierge pour toute question ou modification. Nous sommes heureux de vous aider à bien faire les choses.',
        de: 'unseren Concierge für Fragen oder Änderungen. Wir helfen Ihnen gerne, es richtig zu machen.',
    },
    'Subscription Billing History': {
        pt: 'Histórico de Faturamento da Assinatura',
        es: 'Historial de Facturación de Suscripción',
        ru: 'История оплаты подписки',
        fr: 'Historique de Facturation de l\'Abonnement',
        de: 'Abrechnungsverlauf des Abonnements',
    },
    'Period:': {
        pt: 'Período:',
        es: 'Período:',
        ru: 'Период:',
        fr: 'Période :',
        de: 'Zeitraum:',
    },
    'paid of': {
        pt: 'pago de',
        es: 'pagado de',
        ru: 'оплачено из',
        fr: 'payé sur',
        de: 'bezahlt von',
    },
    'No history': {
        pt: 'Sem histórico',
        es: 'Sin historial',
        ru: 'Нет истории',
        fr: 'Pas d\'historique',
        de: 'Keine Historie',
    },
    'Change to Yearly?': {
        pt: 'Mudar para Anual?',
        es: '¿Cambiar a Anual?',
        ru: 'Перейти на годовой план?',
        fr: 'Passer à l\'Annuel ?',
        de: 'Auf Jährlich umstellen?',
    },
    'Subscription Changed': {
        pt: 'Assinatura Alterada',
        es: 'Suscripción Cambiada',
        ru: 'Подписка Изменена',
        fr: 'Abonnement Modifié',
        de: 'Abonnement Geändert',
    },
    'Change to Monthly?': {
        pt: 'Mudar para Mensal?',
        es: '¿Cambiar a Mensual?',
        ru: 'Перейти на ежемесячный план?',
        fr: 'Passer au Mensuel ?',
        de: 'Auf Monatlich umstellen?',
    },

    //ChangeSubscriptionPage
    'Select Subscription': {
        pt: 'Selecionar Assinatura',
        es: 'Seleccionar Suscripción',
        ru: 'Выбрать Подписку',
        fr: 'Sélectionner l\'Abonnement',
        de: 'Abonnement Auswählen'
    },
    'Change Subscription': {
        pt: 'Alterar Assinatura',
        es: 'Cambiar Suscripción',
        ru: 'Изменить Подписку',
        fr: 'Modifier l\'Abonnement',
        de: 'Abonnement Ändern'
    },
    'Subscription Total': {
        pt: 'Total da Assinatura',
        es: 'Total de la Suscripción',
        ru: 'Общая Сумма Подписки',
        fr: 'Total de l\'Abonnement',
        de: 'Abonnement Gesamt'
    },
    'Pay Yearly': {
        pt: 'Pagar Anualmente',
        es: 'Pagar Anualmente',
        ru: 'Оплатить Ежегодно',
        fr: 'Payer Annuellement',
        de: 'Jährlich Bezahlen'
    },
    'Pay Monthly': {
        pt: 'Pagar Mensalmente',
        es: 'Pagar Mensualmente',
        ru: 'Оплатить Ежемесячно',
        fr: 'Payer Mensuellement',
        de: 'Monatlich Bezahlen'
    },
    'unit)': {
        pt: 'unidade)',
        es: 'unidad)',
        ru: 'объект)',
        fr: 'unité)',
        de: 'Einheit)'
    },
    'units)': {
        pt: 'unidades)',
        es: 'unidades)',
        ru: 'объекты)',
        fr: 'unités)',
        de: 'Einheiten)'
    },
    'Custom Subscription:': {
        pt: 'Assinatura Personalizada:',
        es: 'Suscripción Personalizada:',
        ru: 'Индивидуальная Подписка:',
        fr: 'Abonnement Personnalisé :',
        de: 'Benutzerdefiniertes Abonnement:'
    },
    'You have a custom subscription. Please reach out to the concierge to make changes to your custom subscription.': {
        pt: 'Você tem uma assinatura personalizada. Entre em contato com o concierge para fazer alterações em sua assinatura personalizada.',
        es: 'Tienes una suscripción personalizada. Comunícate con el conserje para realizar cambios en tu suscripción personalizada.',
        ru: 'У вас индивидуальная подписка. Пожалуйста, обратитесь к консьержу, чтобы внести изменения в вашу индивидуальную подписку.',
        fr: 'Vous avez un abonnement personnalisé. Veuillez contacter le concierge pour apporter des modifications à votre abonnement personnalisé.',
        de: 'Sie haben ein benutzerdefiniertes Abonnement. Bitte wenden Sie sich an den Concierge, um Änderungen an Ihrem benutzerdefinierten Abonnement vorzunehmen.'
    },
    'Selected': {
        pt: 'Selecionado',
        es: 'Seleccionado',
        ru: 'Выбрано',
        fr: 'Sélectionné',
        de: 'Ausgewählt'
    },
    'Great for people with 1 property/unit.': {
        pt: 'Ótimo para pessoas com 1 propriedade/unidade.',
        es: 'Ideal para personas con 1 propiedad/unidad.',
        ru: 'Отлично подходит для людей с 1 объектом недвижимости/единицей.',
        fr: 'Idéal pour les personnes avec 1 propriété/unité.',
        de: 'Ideal für Personen mit 1 Immobilie/Einheit.'
    },
    '/unit/year': {
        pt: '/unidade/ano',
        es: '/unidad/año',
        ru: '/объект/год',
        fr: '/unité/an',
        de: '/Einheit/Jahr'
    },
    '/unit/mo': {
        pt: '/unidade/mês',
        es: '/unidad/mes',
        ru: '/объект/месяц',
        fr: '/unité/mois',
        de: '/Einheit/Monat'
    },
    'Free plan features:': {
        pt: 'Recursos do plano gratuito:',
        es: 'Características del plan gratuito:',
        ru: 'Функции бесплатного плана:',
        fr: 'Fonctionnalités du plan gratuit :',
        de: 'Funktionen des kostenlosen Plans:'
    },
    'Manage unlimited pros/jobs': {
        pt: 'Gerencie profissionais/trabalhos ilimitados',
        es: 'Gestiona profesionales/trabajos ilimitados',
        ru: 'Управляйте неограниченным количеством специалистов/заданий',
        fr: 'Gérez un nombre illimité de professionnels/tâches',
        de: 'Verwalten Sie unbegrenzte Profis/Aufträge'
    },
    'Amazing operations software': {
        pt: 'Software de operações incrível',
        es: 'Increíble software de operaciones',
        ru: 'Потрясающее программное обеспечение для операций',
        fr: 'Logiciel d\'exploitation incroyable',
        de: 'Erstaunliche Betriebssoftware'
    },
    'Book cleaning/maintenance anywhere': {
        pt: 'Agende limpeza/manutenção em qualquer lugar',
        es: 'Reserva limpieza/mantenimiento en cualquier lugar',
        ru: 'Заказывайте уборку/обслуживание где угодно',
        fr: 'Réservez le nettoyage/la maintenance n\'importe où',
        de: 'Buchen Sie Reinigung/Wartung überall'
    },
    'Current': {
        pt: 'Atual',
        es: 'Actual',
        ru: 'Текущий',
        fr: 'Actuel',
        de: 'Aktuell'
    },
    'Great for long-term rental managers.': {
        pt: 'Ótimo para gerentes de aluguel de longo prazo.',
        es: 'Ideal para administradores de alquileres a largo plazo.',
        ru: 'Отлично подходит для менеджеров долгосрочной аренды.',
        fr: 'Idéal pour les gestionnaires de locations à long terme.',
        de: 'Ideal für Langzeitvermietungsmanager.'
    },
    '<b>Everything in Free, plus:</b>': {
        pt: '<b>Tudo no plano Grátis, mais:</b>',
        es: '<b>Todo lo del plan Gratis, más:</b>',
        ru: '<b>Все, что есть в бесплатном плане, плюс:</b>',
        fr: '<b>Tout ce qui est dans le plan Gratuit, plus :</b>',
        de: '<b>Alles im kostenlosen Plan, plus:</b>'
    },
    'More users and properties': {
        pt: 'Mais usuários e propriedades',
        es: 'Más usuarios y propiedades',
        ru: 'Больше пользователей и объектов недвижимости',
        fr: 'Plus d\'utilisateurs et de propriétés',
        de: 'Mehr Benutzer und Immobilien'
    },
    'Save 20% on in-network jobs': {
        pt: 'Economize 20% em trabalhos na rede',
        es: 'Ahorra 20% en trabajos dentro de la red',
        ru: 'Экономьте 20% на работах в сети',
        fr: 'Économisez 20% sur les travaux en réseau',
        de: 'Sparen Sie 20% bei Aufträgen im Netzwerk'
    },
    'Basic integrations & automations': {
        pt: 'Integrações e automações básicas',
        es: 'Integraciones y automatizaciones básicas',
        ru: 'Базовые интеграции и автоматизации',
        fr: 'Intégrations et automatisations de base',
        de: 'Grundlegende Integrationen & Automatisierungen'
    },
    'Standard': {
        pt: 'Padrão',
        es: 'Estándar',
        ru: 'Стандартный',
        fr: 'Standard',
        de: 'Standard'
    },
    'Great for short-term rental (STR) managers.': {
        pt: 'Ótimo para gerentes de aluguel de curto prazo (STR).',
        es: 'Ideal para administradores de alquileres a corto plazo (STR).',
        ru: 'Отлично подходит для менеджеров краткосрочной аренды (STR).',
        fr: 'Idéal pour les gestionnaires de locations à court terme (STR).',
        de: 'Ideal für Kurzzeitvermietungsmanager (STR).'
    },
    '<b>Everything in TIDY+, plus:</b>': {
        pt: '<b>Tudo no TIDY+, mais:</b>',
        es: '<b>Todo lo de TIDY+, más:</b>',
        ru: '<b>Все, что есть в TIDY+, плюс:</b>',
        fr: '<b>Tout ce qui est dans TIDY+, plus :</b>',
        de: '<b>Alles in TIDY+, plus:</b>'
    },
    'All software features': {
        pt: 'Todos os recursos do software',
        es: 'Todas las características del software',
        ru: 'Все функции программного обеспечения',
        fr: 'Toutes les fonctionnalités du logiciel',
        de: 'Alle Softwarefunktionen'
    },
    'Powerful automations': {
        pt: 'Automações poderosas',
        es: 'Automatizaciones potentes',
        ru: 'Мощные автоматизации',
        fr: 'Automatisations puissantes',
        de: 'Leistungsstarke Automatisierungen'
    },
    'Limited use of our human concierge': {
        pt: 'Uso limitado do nosso concierge humano',
        es: 'Uso limitado de nuestro conserje humano',
        ru: 'Ограниченное использование нашего человека-консьержа',
        fr: 'Utilisation limitée de notre concierge humain',
        de: 'Begrenzte Nutzung unseres menschlichen Concierge'
    },
    'Advanced': {
        pt: 'Avançado',
        es: 'Avanzado',
        ru: 'Продвинутый',
        fr: 'Avancé',
        de: 'Erweitert'
    },
    'For the most advanced property managers.': {
        pt: 'Para os gerentes de propriedades mais avançados.',
        es: 'Para los administradores de propiedades más avanzados.',
        ru: 'Для самых продвинутых менеджеров недвижимости.',
        fr: 'Pour les gestionnaires immobiliers les plus avancés.',
        de: 'Für die fortschrittlichsten Immobilienverwalter.'
    },
    '<b>Everything in Standard, plus:</b>': {
        pt: '<b>Tudo no plano Padrão, mais:</b>',
        es: '<b>Todo lo del plan Estándar, más:</b>',
        ru: '<b>Все, что есть в Стандартном плане, плюс:</b>',
        fr: '<b>Tout ce qui est dans le plan Standard, plus :</b>',
        de: '<b>Alles im Standardplan, plus:</b>'
    },
    'Unlimited automations': {
        pt: 'Automações ilimitadas',
        es: 'Automatizaciones ilimitadas',
        ru: 'Неограниченные автоматизации',
        fr: 'Automatisations illimitées',
        de: 'Unbegrenzte Automatisierungen'
    },
    'Unlimited human concierge': {
        pt: 'Concierge humano ilimitado',
        es: 'Conserje humano ilimitado',
        ru: 'Неограниченный человек-консьерж',
        fr: 'Concierge humain illimité',
        de: 'Unbegrenzter menschlicher Concierge'
    },
    '24/7/365 emergency job requests': {
        pt: 'Solicitações de trabalho de emergência 24/7/365',
        es: 'Solicitudes de trabajo de emergencia 24/7/365',
        ru: 'Запросы на экстренную работу 24/7/365',
        fr: 'Demandes de travaux d\'urgence 24/7/365',
        de: '24/7/365 Notfall-Arbeitsanfragen'
    },
    'Talk to an Expert': {
        pt: 'Fale com um Especialista',
        es: 'Habla con un Experto',
        ru: 'Поговорить с Экспертом',
        fr: 'Parler à un Expert',
        de: 'Sprechen Sie mit einem Experten'
    },
    'Learn more about each plan': {
        pt: 'Saiba mais sobre cada plano',
        es: 'Aprende más sobre cada plan',
        ru: 'Узнайте больше о каждом плане',
        fr: 'En savoir plus sur chaque plan',
        de: 'Erfahren Sie mehr über jeden Plan'
    },
    'Change Subscription Now': {
        pt: 'Alterar Assinatura Agora',
        es: 'Cambiar Suscripción Ahora',
        ru: 'Изменить Подписку Сейчас',
        fr: 'Changer d\'Abonnement Maintenant',
        de: 'Abonnement Jetzt Ändern'
    },

    //TasksPage
    '<i>No Jobs Booked</i>': {
        pt: '<i>Nenhum Trabalho Agendado</i>',
        es: '<i>No Hay Trabajos Reservados</i>',
        ru: '<i>Нет Забронированных Работ</i>',
        fr: '<i>Aucun Travail Réservé</i>',
        de: '<i>Keine Aufträge Gebucht</i>',
    },
    '<i>Loading...</i>': {
        pt: '<i>Carregando...</i>',
        es: '<i>Cargando...</i>',
        ru: '<i>Загрузка...</i>',
        fr: '<i>Chargement...</i>',
        de: '<i>Laden...</i>',
    },
    'tasks': {
        pt: 'tarefas',
        es: 'tareas',
        ru: 'задачи',
        fr: 'tâches',
        de: 'Aufgaben',
    },
    'Archived': {
        pt: 'Arquivado',
        es: 'Archivado',
        ru: 'Архивировано',
        fr: 'Archivé',
        de: 'Archiviert',
    },
    'Add': {
        pt: 'Adicionar',
        es: 'Añadir',
        ru: 'Добавить',
        fr: 'Ajouter',
        de: 'Hinzufügen',
    },
    'Reported': {
        pt: 'Reportado',
        es: 'Reportado',
        ru: 'Сообщено',
        fr: 'Signalé',
        de: 'Gemeldet',
    },
    'Done': {
        pt: 'Concluído',
        es: 'Hecho',
        ru: 'Выполнено',
        fr: 'Terminé',
        de: 'Erledigt',
    },
    'All Urgencies': {
        pt: 'Todas as Urgências',
        es: 'Todas las Urgencias',
        ru: 'Все Срочности',
        fr: 'Toutes les Urgences',
        de: 'Alle Dringlichkeiten',
    },
    'Low': {
        pt: 'Baixa',
        es: 'Baja',
        ru: 'Низкая',
        fr: 'Faible',
        de: 'Niedrig',
    },
    'Normal': {
        pt: 'Normal',
        es: 'Normal',
        ru: 'Нормальная',
        fr: 'Normale',
        de: 'Normal',
    },
    'Emergency': {
        pt: 'Emergência',
        es: 'Emergencia',
        ru: 'Экстренная',
        fr: 'Urgence',
        de: 'Notfall',
    },
    'Mark Resolved?': {
        pt: 'Marcar como Resolvido?',
        es: '¿Marcar como Resuelto?',
        ru: 'Отметить как Решенное?',
        fr: 'Marquer comme Résolu ?',
        de: 'Als Gelöst markieren?',
    },
    'You cannot undo this action.': {
        pt: 'Você não pode desfazer esta ação.',
        es: 'No puedes deshacer esta acción.',
        ru: 'Вы не можете отменить это действие.',
        fr: 'Vous ne pouvez pas annuler cette action.',
        de: 'Sie können diese Aktion nicht rückgängig machen.',
    },
    'Resolve': {
        pt: 'Resolver',
        es: 'Resolver',
        ru: 'Решить',
        fr: 'Résoudre',
        de: 'Lösen',
    },
    'Delete Issue?': {
        pt: 'Excluir Problema?',
        es: '¿Eliminar Problema?',
        ru: 'Удалить Проблему?',
        fr: 'Supprimer le Problème ?',
        de: 'Problem Löschen?',
    },
    'Delete': {
        pt: 'Excluir',
        es: 'Eliminar',
        ru: 'Удалить',
        fr: 'Supprimer',
        de: 'Löschen',
    },

    //ArchivedTasksPage
    'Archived Tasks': {
        pt: 'Tarefas Arquivadas',
        es: 'Tareas Archivadas',
        ru: 'Архивированные Задачи',
        fr: 'Tâches Archivées',
        de: 'Archivierte Aufgaben',
    },
    'No Tasks': {
        pt: 'Sem Tarefas',
        es: 'Sin Tareas',
        ru: 'Нет Задач',
        fr: 'Aucune Tâche',
        de: 'Keine Aufgaben',
    },

    //CreateActionPlanPage
    '<i>Generating Concierge Action Plan</i>': {
        pt: '<i>Gerando Plano de Ação do Concierge</i>',
        es: '<i>Generando Plan de Acción del Conserje</i>',
        ru: '<i>Создание Плана Действий Консьержа</i>',
        fr: '<i>Génération du Plan d\'Action du Concierge</i>',
        de: '<i>Generierung des Concierge-Aktionsplans</i>',
    },
    '<i>Analyzing Task...</i>': {
        pt: '<i>Analisando Tarefa...</i>',
        es: '<i>Analizando Tarea...</i>',
        ru: '<i>Анализ Задачи...</i>',
        fr: '<i>Analyse de la Tâche...</i>',
        de: '<i>Aufgabe wird analysiert...</i>',
    },
    '<i>Analyzing Similar Action Plans...</i>': {
        pt: '<i>Analisando Planos de Ação Similares...</i>',
        es: '<i>Analizando Planes de Acción Similares...</i>',
        ru: '<i>Анализ Похожих Планов Действий...</i>',
        fr: '<i>Analyse des Plans d\'Action Similaires...</i>',
        de: '<i>Analyse ähnlicher Aktionspläne...</i>',
    },
    '<i>Generating Action Plan...</i>': {
        pt: '<i>Gerando Plano de Ação...</i>',
        es: '<i>Generando Plan de Acción...</i>',
        ru: '<i>Создание Плана Действий...</i>',
        fr: '<i>Génération du Plan d\'Action...</i>',
        de: '<i>Generierung des Aktionsplans...</i>',
    },
    'Concierge Action Plan:': {
        pt: 'Plano de Ação do Concierge:',
        es: 'Plan de Acción del Conserje:',
        ru: 'План Действий Консьержа:',
        fr: 'Plan d\'Action du Concierge :',
        de: 'Concierge-Aktionsplan:',
    },
    'Title': {
        pt: 'Título',
        es: 'Título',
        ru: 'Заголовок',
        fr: 'Titre',
        de: 'Titel',
    },
    'When should concierge do this?': {
        pt: 'Quando o concierge deve fazer isso?',
        es: '¿Cuándo debe hacer esto el conserje?',
        ru: 'Когда консьерж должен это сделать?',
        fr: 'Quand le concierge doit-il faire cela ?',
        de: 'Wann soll der Concierge dies tun?',
    },
    'Immediately': {
        pt: 'Imediatamente',
        es: 'Inmediatamente',
        ru: 'Немедленно',
        fr: 'Immédiatement',
        de: 'Sofort',
    },
    'Scheduled for a date': {
        pt: 'Agendado para uma data',
        es: 'Programado para una fecha',
        ru: 'Запланировано на дату',
        fr: 'Programmé pour une date',
        de: 'Für ein Datum geplant',
    },
    'Select date': {
        pt: 'Selecionar data',
        es: 'Seleccionar fecha',
        ru: 'Выбрать дату',
        fr: 'Sélectionner une date',
        de: 'Datum auswählen',
    },
    'Create Concierge Action Plan': {
        pt: 'Criar Plano de Ação do Concierge',
        es: 'Crear Plan de Acción del Conserje',
        ru: 'Создать План Действий Консьержа',
        fr: 'Créer un Plan d\'Action du Concierge',
        de: 'Concierge-Aktionsplan Erstellen',
    },
    'Skip For Now': {
        pt: 'Pular por Agora',
        es: 'Omitir por Ahora',
        ru: 'Пропустить Сейчас',
        fr: 'Ignorer pour le Moment',
        de: 'Vorerst Überspringen',
    },
    'Task Added': {
        pt: 'Tarefa Adicionada',
        es: 'Tarea Añadida',
        ru: 'Задача Добавлена',
        fr: 'Tâche Ajoutée',
        de: 'Aufgabe Hinzugefügt',
    },
    'Create Action Plan': {
        pt: 'Criar Plano de Ação',
        es: 'Crear Plan de Acción',
        ru: 'Создать План Действий',
        fr: 'Créer un Plan d\'Action',
        de: 'Aktionsplan Erstellen',
    },
    'Concierge action plan created!': {
        pt: 'Plano de ação do concierge criado!',
        es: '¡Plan de acción del conserje creado!',
        ru: 'План действий консьержа создан!',
        fr: 'Plan d\'action du concierge créé !',
        de: 'Concierge-Aktionsplan erstellt!',
    },

    //RecurringTasksPage
    'Recurring Tasks': {
        pt: 'Tarefas Recorrentes',
        es: 'Tareas Recurrentes',
        ru: 'Повторяющиеся Задачи',
        fr: 'Tâches Récurrentes',
        de: 'Wiederkehrende Aufgaben',
    },
    'Next Date': {
        pt: 'Próxima Data',
        es: 'Próxima Fecha',
        ru: 'Следующая Дата',
        fr: 'Prochaine Date',
        de: 'Nächstes Datum',
    },
    'Delete Maintenance Plan?': {
        pt: 'Excluir Plano de Manutenção?',
        es: '¿Eliminar Plan de Mantenimiento?',
        ru: 'Удалить План Обслуживания?',
        fr: 'Supprimer le Plan de Maintenance ?',
        de: 'Wartungsplan Löschen?',
    },
    'Edit Recurring Task': {
        pt: 'Editar Tarefa Recorrente',
        es: 'Editar Tarea Recurrente',
        ru: 'Редактировать Повторяющуюся Задачу',
        fr: 'Modifier la Tâche Récurrente',
        de: 'Wiederkehrende Aufgabe Bearbeiten',
    },
    'Add Recurring Task': {
        pt: 'Adicionar Tarefa Recorrente',
        es: 'Agregar Tarea Recurrente',
        ru: 'Добавить Повторяющуюся Задачу',
        fr: 'Ajouter une Tâche Récurrente',
        de: 'Wiederkehrende Aufgabe Hinzufügen',
    },

    //RecurringTaskComponent
    'Recurring Task': {
        pt: 'Tarefa Recorrente',
        es: 'Tarea Recurrente',
        ru: 'Повторяющаяся Задача',
        fr: 'Tâche Récurrente',
        de: 'Wiederkehrende Aufgabe',
    },
    'Saving Changes..': {
        pt: 'Salvando Alterações..',
        es: 'Guardando Cambios..',
        ru: 'Сохранение Изменений..',
        fr: 'Enregistrement des Modifications..',
        de: 'Änderungen werden gespeichert..',
    },
    'All Changes Saved.': {
        pt: 'Todas as Alterações Salvas.',
        es: 'Todos los Cambios Guardados.',
        ru: 'Все Изменения Сохранены.',
        fr: 'Toutes les Modifications Enregistrées.',
        de: 'Alle Änderungen Gespeichert.',
    },
    'Due Next': {
        pt: 'Próximo Vencimento',
        es: 'Próximo Vencimiento',
        ru: 'Следующий Срок',
        fr: 'Prochaine Échéance',
        de: 'Nächste Fälligkeit',
    },
    'Recur every': {
        pt: 'Repetir a cada',
        es: 'Repetir cada',
        ru: 'Повторять каждые',
        fr: 'Récurrence tous les',
        de: 'Wiederholen alle',
    },
    'Timeframe': {
        pt: 'Período',
        es: 'Marco de Tiempo',
        ru: 'Временные Рамки',
        fr: 'Période',
        de: 'Zeitrahmen',
    },
    'Select a property to view items': {
        pt: 'Selecione uma propriedade para ver os itens',
        es: 'Seleccione una propiedad para ver los elementos',
        ru: 'Выберите объект для просмотра элементов',
        fr: 'Sélectionnez une propriété pour afficher les éléments',
        de: 'Wählen Sie eine Immobilie aus, um Elemente anzuzeigen',
    },
    'Delete Recurring Task': {
        pt: 'Excluir Tarefa Recorrente',
        es: 'Eliminar Tarea Recurrente',
        ru: 'Удалить Повторяющуюся Задачу',
        fr: 'Supprimer la Tâche Récurrente',
        de: 'Wiederkehrende Aufgabe Löschen',
    },
    'Please fill out all the required fields.': {
        pt: 'Por favor, preencha todos os campos obrigatórios.',
        es: 'Por favor, complete todos los campos requeridos.',
        ru: 'Пожалуйста, заполните все обязательные поля.',
        fr: 'Veuillez remplir tous les champs obligatoires.',
        de: 'Bitte füllen Sie alle erforderlichen Felder aus.',
    },
    'Week(s)': {
        pt: 'Semana(s)',
        es: 'Semana(s)',
        ru: 'Неделя(и)',
        fr: 'Semaine(s)',
        de: 'Woche(n)',
    },
    'Month(s)': {
        pt: 'Mês(es)',
        es: 'Mes(es)',
        ru: 'Месяц(ы)',
        fr: 'Mois',
        de: 'Monat(e)',
    },
    'Year(s)': {
        pt: 'Ano(s)',
        es: 'Año(s)',
        ru: 'Год(ы)',
        fr: 'Année(s)',
        de: 'Jahr(e)',
    },
    'Delete Recurring Task?': {
        pt: 'Excluir Tarefa Recorrente?',
        es: '¿Eliminar Tarea Recurrente?',
        ru: 'Удалить Повторяющуюся Задачу?',
        fr: 'Supprimer la Tâche Récurrente ?',
        de: 'Wiederkehrende Aufgabe Löschen?',
    },
    'Add new model': {
        pt: 'Adicionar novo modelo',
        es: 'Agregar nuevo modelo',
        ru: 'Добавить новую модель',
        fr: 'Ajouter un nouveau modèle',
        de: 'Neues Modell hinzufügen',
    },

    //TaskPage
    'Task': {
        pt: 'Tarefa',
        es: 'Tarea',
        ru: 'Задача',
        fr: 'Tâche',
        de: 'Aufgabe',
    },
    'Add Task': {
        pt: 'Adicionar Tarefa',
        es: 'Agregar Tarea',
        ru: 'Добавить Задачу',
        fr: 'Ajouter une Tâche',
        de: 'Aufgabe Hinzufügen',
    },
    'Concierge Action Plan': {
        pt: 'Plano de Ação do Concierge',
        es: 'Plan de Acción del Conserje',
        ru: 'План Действий Консьержа',
        fr: 'Plan d\'Action du Concierge',
        de: 'Concierge-Aktionsplan',
    },
    'Scheduled to do immediately': {
        pt: 'Agendado para fazer imediatamente',
        es: 'Programado para hacer inmediatamente',
        ru: 'Запланировано на немедленное выполнение',
        fr: 'Prévu pour être fait immédiatement',
        de: 'Sofort zu erledigen geplant',
    },
    'Scheduled to do on': {
        pt: 'Agendado para fazer em',
        es: 'Programado para hacer el',
        ru: 'Запланировано на',
        fr: 'Prévu pour être fait le',
        de: 'Geplant für',
    },
    'Due Date': {
        pt: 'Data de Vencimento',
        es: 'Fecha de Vencimiento',
        ru: 'Срок Выполнения',
        fr: 'Date d\'Échéance',
        de: 'Fälligkeitsdatum',
    },
    'Urgency': {
        pt: 'Urgência',
        es: 'Urgencia',
        ru: 'Срочность',
        fr: 'Urgence',
        de: 'Dringlichkeit',
    },
    'Members': {
        pt: 'Membros',
        es: 'Miembros',
        ru: 'Участники',
        fr: 'Membres',
        de: 'Mitglieder',
    },
    'Photos': {
        pt: 'Fotos',
        es: 'Fotos',
        ru: 'Фотографии',
        fr: 'Photos',
        de: 'Fotos',
    },
    'Reported by': {
        pt: 'Relatado por',
        es: 'Reportado por',
        ru: 'Сообщено',
        fr: 'Signalé par',
        de: 'Gemeldet von',
    },
    'Reported at': {
        pt: 'Relatado em',
        es: 'Reportado el',
        ru: 'Сообщено в',
        fr: 'Signalé le',
        de: 'Gemeldet am',
    },
    'Archive Task': {
        pt: 'Arquivar Tarefa',
        es: 'Archivar Tarea',
        ru: 'Архивировать Задачу',
        fr: 'Archiver la Tâche',
        de: 'Aufgabe Archivieren',
    },
    'Unarchive Task': {
        pt: 'Desarquivar Tarefa',
        es: 'Desarchivar Tarea',
        ru: 'Разархивировать Задачу',
        fr: 'Désarchiver la Tâche',
        de: 'Aufgabe Wiederherstellen',
    },
    'Delete Task': {
        pt: 'Excluir Tarefa',
        es: 'Eliminar Tarea',
        ru: 'Удалить Задачу',
        fr: 'Supprimer la Tâche',
        de: 'Aufgabe Löschen',
    },
    'High': {
        pt: 'Alta',
        es: 'Alta',
        ru: 'Высокая',
        fr: 'Élevée',
        de: 'Hoch',
    },
    'Archive Task?': {
        pt: 'Arquivar Tarefa?',
        es: '¿Archivar Tarea?',
        ru: 'Архивировать Задачу?',
        fr: 'Archiver la Tâche ?',
        de: 'Aufgabe Archivieren?',
    },
    'You can view archived tasks and unarchive them in the archived tasks page.': {
        pt: 'Você pode visualizar tarefas arquivadas e desarquivá-las na página de tarefas arquivadas.',
        es: 'Puede ver las tareas archivadas y desarchivarlas en la página de tareas archivadas.',
        ru: 'Вы можете просмотреть архивированные задачи и разархивировать их на странице архивированных задач.',
        fr: 'Vous pouvez consulter les tâches archivées et les désarchiver dans la page des tâches archivées.',
        de: 'Sie können archivierte Aufgaben auf der Seite für archivierte Aufgaben anzeigen und wiederherstellen.',
    },
    'Unarchive Task?': {
        pt: 'Desarquivar Tarefa?',
        es: '¿Desarchivar Tarea?',
        ru: 'Разархивировать Задачу?',
        fr: 'Désarchiver la Tâche ?',
        de: 'Aufgabe Wiederherstellen?',
    },
    'Unarchive': {
        pt: 'Desarquivar',
        es: 'Desarchivar',
        ru: 'Разархивировать',
        fr: 'Désarchiver',
        de: 'Wiederherstellen',
    },
    'Delete Task?': {
        pt: 'Excluir Tarefa?',
        es: '¿Eliminar Tarea?',
        ru: 'Удалить Задачу?',
        fr: 'Supprimer la Tâche ?',
        de: 'Aufgabe Löschen?',
    },

    //AddAddressToTeamPage
    'Add Property to Team': {
        pt: 'Adicionar Propriedade à Equipe',
        es: 'Agregar Propiedad al Equipo',
        ru: 'Добавить Объект в Команду',
        fr: 'Ajouter une Propriété à l\'Équipe',
        de: 'Objekt zum Team hinzufügen'
    },
    'Team': {
        pt: 'Equipe',
        es: 'Equipo',
        ru: 'Команда',
        fr: 'Équipe',
        de: 'Team'
    },
    'Add to Property': {
        pt: 'Adicionar à Propriedade',
        es: 'Agregar a la Propiedad',
        ru: 'Добавить к Объекту',
        fr: 'Ajouter à la Propriété',
        de: 'Zum Objekt hinzufügen'
    },

    //AddTeamPage
    'Add Team': {
        pt: 'Adicionar Equipe',
        es: 'Agregar Equipo',
        ru: 'Добавить Команду',
        fr: 'Ajouter une Équipe',
        de: 'Team Hinzufügen',
    },
    'Team Name': {
        pt: 'Nome da Equipe',
        es: 'Nombre del Equipo',
        ru: 'Название Команды',
        fr: 'Nom de l\'Équipe',
        de: 'Teamname',
    },
    'Parent Team (optional)': {
        pt: 'Equipe Principal (opcional)',
        es: 'Equipo Principal (opcional)',
        ru: 'Родительская Команда (необязательно)',
        fr: 'Équipe Parente (facultatif)',
        de: 'Übergeordnetes Team (optional)',
    },

    //AddUserToTeamPage
    'Add to Team': {
        pt: 'Adicionar à Equipe',
        es: 'Agregar al Equipo',
        ru: 'Добавить в Команду',
        fr: 'Ajouter à l\'Équipe',
        de: 'Zum Team Hinzufügen',
    },
    'User': {
        pt: 'Usuário',
        es: 'Usuario',
        ru: 'Пользователь',
        fr: 'Utilisateur',
        de: 'Benutzer',
    },
    'Adding an admin to a team will change their user type to team member. This means they will only be able to see users and properties in their team.': {
        pt: 'Adicionar um administrador a uma equipe mudará seu tipo de usuário para membro da equipe. Isso significa que eles só poderão ver usuários e propriedades em sua equipe.',
        es: 'Agregar un administrador a un equipo cambiará su tipo de usuario a miembro del equipo. Esto significa que solo podrán ver usuarios y propiedades en su equipo.',
        ru: 'Добавление администратора в команду изменит его тип пользователя на члена команды. Это означает, что они смогут видеть только пользователей и объекты в своей команде.',
        fr: 'L\'ajout d\'un administrateur à une équipe changera son type d\'utilisateur en membre de l\'équipe. Cela signifie qu\'ils ne pourront voir que les utilisateurs et les propriétés de leur équipe.',
        de: 'Wenn Sie einen Administrator zu einem Team hinzufügen, wird sein Benutzertyp in Teammitglied geändert. Das bedeutet, dass sie nur Benutzer und Eigenschaften in ihrem Team sehen können.',
    },
    '(Owner)': {
        pt: '(Proprietário)',
        es: '(Propietario)',
        ru: '(Владелец)',
        fr: '(Propriétaire)',
        de: '(Besitzer)',
    },
    '(Admin)': {
        pt: '(Administrador)',
        es: '(Administrador)',
        ru: '(Администратор)',
        fr: '(Administrateur)',
        de: '(Administrator)',
    },
    '(Team Member)': {
        pt: '(Membro da Equipe)',
        es: '(Miembro del Equipo)',
        ru: '(Член Команды)',
        fr: '(Membre de l\'Équipe)',
        de: '(Teammitglied)',
    },

    //EditTeamNamePage
    //EditTeamNamePage
    'Edit Team Name': {
        pt: 'Editar Nome da Equipe',
        es: 'Editar Nombre del Equipo',
        ru: 'Изменить Название Команды',
        fr: 'Modifier le Nom de l\'Équipe',
        de: 'Teamname Bearbeiten',
    },
    'Please enter the name of the team.': {
        pt: 'Por favor, insira o nome da equipe.',
        es: 'Por favor, ingrese el nombre del equipo.',
        ru: 'Пожалуйста, введите название команды.',
        fr: 'Veuillez entrer le nom de l\'équipe.',
        de: 'Bitte geben Sie den Namen des Teams ein.',
    },

    //EditTeamParentPage
    'Edit Parent Team': {
        pt: 'Editar Equipe Principal',
        es: 'Editar Equipo Principal',
        ru: 'Изменить Родительскую Команду',
        fr: 'Modifier l\'Équipe Parente',
        de: 'Übergeordnetes Team Bearbeiten',
    },
    'Parent Team': {
        pt: 'Equipe Principal',
        es: 'Equipo Principal',
        ru: 'Родительская Команда',
        fr: 'Équipe Parente',
        de: 'Übergeordnetes Team',
    },

    //TeamPage
    'Team Detail': {
        pt: 'Detalhes da Equipe',
        es: 'Detalle del Equipo',
        ru: 'Детали Команды',
        fr: 'Détail de l\'Équipe',
        de: 'Team-Details',
    },
    'Team Name:': {
        pt: 'Nome da Equipe:',
        es: 'Nombre del Equipo:',
        ru: 'Название Команды:',
        fr: 'Nom de l\'Équipe:',
        de: 'Teamname:',
    },
    'Parent Team:': {
        pt: 'Equipe Principal:',
        es: 'Equipo Principal:',
        ru: 'Родительская Команда:',
        fr: 'Équipe Parente:',
        de: 'Übergeordnetes Team:',
    },
    'Team Members': {
        pt: 'Membros da Equipe',
        es: 'Miembros del Equipo',
        ru: 'Члены Команды',
        fr: 'Membres de l\'Équipe',
        de: 'Teammitglieder',
    },
    'Add Member': {
        pt: 'Adicionar Membro',
        es: 'Añadir Miembro',
        ru: 'Добавить Участника',
        fr: 'Ajouter un Membre',
        de: 'Mitglied Hinzufügen',
    },
    'Add team members to limit their access to only the properties in their teams.': {
        pt: 'Adicione membros da equipe para limitar seu acesso apenas às propriedades em suas equipes.',
        es: 'Agregue miembros del equipo para limitar su acceso solo a las propiedades en sus equipos.',
        ru: 'Добавьте членов команды, чтобы ограничить их доступ только к объектам в их командах.',
        fr: 'Ajoutez des membres à l\'équipe pour limiter leur accès uniquement aux propriétés de leurs équipes.',
        de: 'Fügen Sie Teammitglieder hinzu, um ihren Zugriff auf die Eigenschaften in ihren Teams zu beschränken.',
    },
    'No team members': {
        pt: 'Sem membros na equipe',
        es: 'Sin miembros del equipo',
        ru: 'Нет членов команды',
        fr: 'Aucun membre dans l\'équipe',
        de: 'Keine Teammitglieder',
    },
    'Add properties to this team so members can see them and for reporting.': {
        pt: 'Adicione propriedades a esta equipe para que os membros possam vê-las e para relatórios.',
        es: 'Agregue propiedades a este equipo para que los miembros puedan verlas y para informes.',
        ru: 'Добавьте объекты в эту команду, чтобы участники могли их видеть и для отчетности.',
        fr: 'Ajoutez des propriétés à cette équipe pour que les membres puissent les voir et pour les rapports.',
        de: 'Fügen Sie diesem Team Eigenschaften hinzu, damit Mitglieder sie sehen können und für Berichte.',
    },
    'No properties': {
        pt: 'Sem propriedades',
        es: 'Sin propiedades',
        ru: 'Нет объектов',
        fr: 'Aucune propriété',
        de: 'Keine Eigenschaften',
    },
    'No notifications added': {
        pt: 'Nenhuma notificação adicionada',
        es: 'No se han añadido notificaciones',
        ru: 'Уведомления не добавлены',
        fr: 'Aucune notification ajoutée',
        de: 'Keine Benachrichtigungen hinzugefügt',
    },
    'Team Billing': {
        pt: 'Faturamento da Equipe',
        es: 'Facturación del Equipo',
        ru: 'Биллинг Команды',
        fr: 'Facturation de l\'Équipe',
        de: 'Team-Abrechnung',
    },
    'Add a card to use only for jobs done for these properties. This enables Team Billing.': {
        pt: 'Adicione um cartão para usar apenas para trabalhos feitos para essas propriedades. Isso ativa o Faturamento da Equipe.',
        es: 'Agregue una tarjeta para usar solo para trabajos realizados para estas propiedades. Esto habilita la Facturación del Equipo.',
        ru: 'Добавьте карту для использования только для работ, выполненных для этих объектов. Это активирует Биллинг Команды.',
        fr: 'Ajoutez une carte à utiliser uniquement pour les travaux effectués pour ces propriétés. Cela active la Facturation de l\'Équipe.',
        de: 'Fügen Sie eine Karte hinzu, die nur für Arbeiten an diesen Eigenschaften verwendet wird. Dies aktiviert die Team-Abrechnung.',
    },
    'Delete Team?': {
        pt: 'Excluir Equipe?',
        es: '¿Eliminar Equipo?',
        ru: 'Удалить Команду?',
        fr: 'Supprimer l\'Équipe?',
        de: 'Team Löschen?',
    },
    'Remove Address?': {
        pt: 'Remover Endereço?',
        es: '¿Eliminar Dirección?',
        ru: 'Удалить Адрес?',
        fr: 'Supprimer l\'Adresse?',
        de: 'Adresse Entfernen?',
    },
    'Change to Admin?': {
        pt: 'Mudar para Administrador?',
        es: '¿Cambiar a Administrador?',
        ru: 'Изменить на Администратора?',
        fr: 'Changer en Administrateur?',
        de: 'Zum Administrator Ändern?',
    },
    'Removing a team manager from all teams will change their user type to <u>admin</u>. This means they will see all properties and users.': {
        pt: 'Remover um gerente de equipe de todas as equipes mudará seu tipo de usuário para <u>administrador</u>. Isso significa que eles verão todas as propriedades e usuários.',
        es: 'Eliminar un gerente de equipo de todos los equipos cambiará su tipo de usuario a <u>administrador</u>. Esto significa que verán todas las propiedades y usuarios.',
        ru: 'Удаление менеджера команды из всех команд изменит их тип пользователя на <u>администратор</u>. Это означает, что они будут видеть все объекты и пользователей.',
        fr: 'Retirer un gestionnaire d\'équipe de toutes les équipes changera son type d\'utilisateur en <u>administrateur</u>. Cela signifie qu\'il verra toutes les propriétés et tous les utilisateurs.',
        de: 'Wenn Sie einen Teammanager aus allen Teams entfernen, wird sein Benutzertyp zu <u>Administrator</u> geändert. Das bedeutet, dass er alle Eigenschaften und Benutzer sehen wird.',
    },
    'Remove Card?': {
        pt: 'Remover Cartão?',
        es: '¿Eliminar Tarjeta?',
        ru: 'Удалить Карту?',
        fr: 'Supprimer la Carte?',
        de: 'Karte Entfernen?',
    },

    //TeamsPage
    'No teams added': {
        pt: 'Nenhuma equipe adicionada',
        es: 'No se han agregado equipos',
        ru: 'Команды не добавлены',
        fr: 'Aucune équipe ajoutée',
        de: 'Keine Teams hinzugefügt',
    },
    'Need help setting up teams?': {
        pt: 'Precisa de ajuda para configurar equipes?',
        es: '¿Necesita ayuda para configurar equipos?',
        ru: 'Нужна помощь в настройке команд?',
        fr: 'Besoin d\'aide pour configurer les équipes?',
        de: 'Benötigen Sie Hilfe beim Einrichten von Teams?',
    },

    //ToDosPage
    'No jobs booked at this property. Please book a job to use To-Dos.': {
        pt: 'Nenhum trabalho agendado nesta propriedade. Por favor, agende um trabalho para usar as Tarefas.',
        es: 'No hay trabajos reservados en esta propiedad. Por favor, reserve un trabajo para usar las Tareas.',
        ru: 'Нет забронированных работ на этом объекте. Пожалуйста, забронируйте работу, чтобы использовать Списки Дел.',
        fr: 'Aucun travail réservé sur cette propriété. Veuillez réserver un travail pour utiliser les Tâches.',
        de: 'Keine Aufträge für dieses Objekt gebucht. Bitte buchen Sie einen Auftrag, um To-Dos zu verwenden.',
    },
    'Not Using To-Dos': {
        pt: 'Não Usando Tarefas',
        es: 'No Usando Tareas',
        ru: 'Не Используются Списки Дел',
        fr: 'Pas d\'Utilisation des Tâches',
        de: 'To-Dos Nicht Verwendet',
    },
    'You are not using To-Dos by default at this property. You can still use To-Dos for a job - just tap To-Dos on the job in your Schedule.': {
        pt: 'Você não está usando Tarefas por padrão nesta propriedade. Você ainda pode usar Tarefas para um trabalho - basta tocar em Tarefas no trabalho em sua Agenda.',
        es: 'No está utilizando Tareas por defecto en esta propiedad. Aún puede usar Tareas para un trabajo - simplemente toque Tareas en el trabajo en su Horario.',
        ru: 'Вы не используете Списки Дел по умолчанию на этом объекте. Вы все еще можете использовать Списки Дел для работы - просто нажмите на Списки Дел в работе в вашем Расписании.',
        fr: 'Vous n\'utilisez pas les Tâches par défaut sur cette propriété. Vous pouvez toujours utiliser les Tâches pour un travail - il suffit de taper sur Tâches sur le travail dans votre Calendrier.',
        de: 'Sie verwenden To-Dos standardmäßig nicht für dieses Objekt. Sie können To-Dos trotzdem für einen Auftrag verwenden - tippen Sie einfach auf To-Dos beim Auftrag in Ihrem Zeitplan.',
    },
    'No lists added for this property.': {
        pt: 'Nenhuma lista adicionada para esta propriedade.',
        es: 'No se han agregado listas para esta propiedad.',
        ru: 'Для этого объекта не добавлено списков.',
        fr: 'Aucune liste ajoutée pour cette propriété.',
        de: 'Keine Listen für dieses Objekt hinzugefügt.',
    },
    'Add To-Do List': {
        pt: 'Adicionar Lista de Tarefas',
        es: 'Agregar Lista de Tareas',
        ru: 'Добавить Список Дел',
        fr: 'Ajouter une Liste de Tâches',
        de: 'To-Do-Liste Hinzufügen',
    },
    'Remote Inspection': {
        pt: 'Inspeção Remota',
        es: 'Inspección Remota',
        ru: 'Удаленная Инспекция',
        fr: 'Inspection à Distance',
        de: 'Ferninspektion',
    },
    'Cards': {
        pt: 'Cartões',
        es: 'Tarjetas',
        ru: 'Карточки',
        fr: 'Cartes',
        de: 'Karten',
    },
    'No Cards Added': {
        pt: 'Nenhum Cartão Adicionado',
        es: 'No Se Han Agregado Tarjetas',
        ru: 'Карточки Не Добавлены',
        fr: 'Aucune Carte Ajoutée',
        de: 'Keine Karten Hinzugefügt',
    },
    '<b>Do not</b>': {
        pt: '<b>Não</b>',
        es: '<b>No</b>',
        ru: '<b>Не</b>',
        fr: '<b>Ne pas</b>',
        de: '<b>Nicht</b>',
    },
    '<b>Do not</b> clean this room': {
        pt: '<b>Não</b> limpe este quarto',
        es: '<b>No</b> limpie esta habitación',
        ru: '<b>Не</b> убирайте эту комнату',
        fr: '<b>Ne pas</b> nettoyer cette pièce',
        de: '<b>Nicht</b> diesen Raum reinigen',
    },
    'Not used for any jobs': {
        pt: 'Não usado para nenhum trabalho',
        es: 'No se usa para ningún trabajo',
        ru: 'Не используется ни для каких работ',
        fr: 'Non utilisé pour aucun travail',
        de: 'Für keine Aufträge verwendet',
    },

    //ToDosAddDoNotPage
    'Add Do Not': {
        pt: 'Adicionar Não Fazer',
        es: 'Agregar No Hacer',
        ru: 'Добавить Запрет',
        fr: 'Ajouter Ne Pas Faire',
        de: 'Nicht-Tun Hinzufügen',
    },
    'What should they NOT do?': {
        pt: 'O que eles NÃO devem fazer?',
        es: '¿Qué NO deben hacer?',
        ru: 'Что они НЕ должны делать?',
        fr: 'Que NE doivent-ils PAS faire ?',
        de: 'Was sollten sie NICHT tun?',
    },
    'Please enter the name of the do not': {
        pt: 'Por favor, insira o nome do não fazer',
        es: 'Por favor, ingrese el nombre del no hacer',
        ru: 'Пожалуйста, введите название запрета',
        fr: 'Veuillez entrer le nom du ne pas faire',
        de: 'Bitte geben Sie den Namen des Nicht-Tuns ein',
    },
    'Add to Rooms:': {
        pt: 'Adicionar aos Quartos:',
        es: 'Agregar a las Habitaciones:',
        ru: 'Добавить в Комнаты:',
        fr: 'Ajouter aux Chambres:',
        de: 'Zu Räumen hinzufügen:',
    },
    'You haven\'t added any cards specific to this property. Please add cards specific to this property to add a Do Not.': {
        pt: 'Você não adicionou nenhum cartão específico para esta propriedade. Por favor, adicione cartões específicos para esta propriedade para adicionar um Não Fazer.',
        es: 'No ha agregado ninguna tarjeta específica para esta propiedad. Por favor, agregue tarjetas específicas para esta propiedad para agregar un No Hacer.',
        ru: 'Вы не добавили карточки, специфичные для этого объекта. Пожалуйста, добавьте карточки, специфичные для этого объекта, чтобы добавить Запрет.',
        fr: 'Vous n\'avez ajouté aucune carte spécifique à cette propriété. Veuillez ajouter des cartes spécifiques à cette propriété pour ajouter un Ne Pas Faire.',
        de: 'Sie haben keine spezifischen Karten für dieses Objekt hinzugefügt. Bitte fügen Sie spezifische Karten für dieses Objekt hinzu, um ein Nicht-Tun hinzuzufügen.',
    },
    'Please select rooms to add to.': {
        pt: 'Por favor, selecione os quartos para adicionar.',
        es: 'Por favor, seleccione las habitaciones para agregar.',
        ru: 'Пожалуйста, выберите комнаты для добавления.',
        fr: 'Veuillez sélectionner les chambres à ajouter.',
        de: 'Bitte wählen Sie Räume zum Hinzufügen aus.',
    },

    //ToDosAddListPage
    'Create a To-Do list, then assign it to a job so your Pro can see what you want done.': {
        pt: 'Crie uma lista de tarefas e atribua-a a um trabalho para que seu Profissional possa ver o que você deseja que seja feito.',
        es: 'Cree una lista de tareas, luego asígnela a un trabajo para que su Profesional pueda ver lo que quiere que se haga.',
        ru: 'Создайте список дел, затем назначьте его на работу, чтобы ваш Профессионал мог видеть, что вы хотите сделать.',
        fr: 'Créez une liste de tâches, puis attribuez-la à un travail pour que votre Pro puisse voir ce que vous voulez faire.',
        de: 'Erstellen Sie eine To-Do-Liste und weisen Sie sie dann einem Auftrag zu, damit Ihr Profi sehen kann, was Sie erledigt haben möchten.',
    },
    'You haven\'t added any templates to copy from one property to another. Please go to the': {
        pt: 'Você não adicionou nenhum modelo para copiar de uma propriedade para outra. Por favor, vá para a',
        es: 'No ha agregado ninguna plantilla para copiar de una propiedad a otra. Por favor, vaya a la',
        ru: 'Вы не добавили шаблоны для копирования с одного объекта на другой. Пожалуйста, перейдите на',
        fr: 'Vous n\'avez ajouté aucun modèle à copier d\'une propriété à une autre. Veuillez aller à la',
        de: 'Sie haben keine Vorlagen hinzugefügt, um von einer Immobilie zur anderen zu kopieren. Bitte gehen Sie zur',
    },
    'list settings': {
        pt: 'página de configurações da lista',
        es: 'página de configuración de la lista',
        ru: 'страницу настроек списка',
        fr: 'page des paramètres de liste',
        de: 'Listeneinstellungsseite',
    },
    'page and make one of your lists a template to copy it. The list cannot have rooms specific to an address to make it a template.': {
        pt: 'e torne uma de suas listas um modelo para copiá-la. A lista não pode ter quartos específicos de um endereço para torná-la um modelo.',
        es: 'y haga que una de sus listas sea una plantilla para copiarla. La lista no puede tener habitaciones específicas de una dirección para convertirla en una plantilla.',
        ru: 'и сделайте один из ваших списков шаблоном для копирования. Список не может иметь комнаты, специфичные для адреса, чтобы сделать его шаблоном.',
        fr: 'et faites de l\'une de vos listes un modèle pour la copier. La liste ne peut pas avoir de pièces spécifiques à une adresse pour en faire un modèle.',
        de: 'und machen Sie eine Ihrer Listen zu einer Vorlage, um sie zu kopieren. Die Liste darf keine raumspezifischen Adressen enthalten, um sie zu einer Vorlage zu machen.',
    },
    'Select List to Copy to this Property': {
        pt: 'Selecione a Lista para Copiar para esta Propriedade',
        es: 'Seleccione la Lista para Copiar a esta Propiedad',
        ru: 'Выберите Список для Копирования на этот Объект',
        fr: 'Sélectionnez la Liste à Copier sur cette Propriété',
        de: 'Wählen Sie die Liste aus, die Sie auf diese Immobilie kopieren möchten',
    },
    'Note: you can only copy lists not associated with a property to another property.': {
        pt: 'Nota: você só pode copiar listas não associadas a uma propriedade para outra propriedade.',
        es: 'Nota: solo puede copiar listas no asociadas a una propiedad a otra propiedad.',
        ru: 'Примечание: вы можете копировать только списки, не связанные с объектом, на другой объект.',
        fr: 'Remarque : vous ne pouvez copier que des listes non associées à une propriété vers une autre propriété.',
        de: 'Hinweis: Sie können nur Listen kopieren, die nicht mit einer Immobilie verknüpft sind, auf eine andere Immobilie.',
    },
    'List Name': {
        pt: 'Nome da Lista',
        es: 'Nombre de la Lista',
        ru: 'Название Списка',
        fr: 'Nom de la Liste',
        de: 'Listenname',
    },
    'Please enter the name of the list.': {
        pt: 'Por favor, insira o nome da lista.',
        es: 'Por favor, ingrese el nombre de la lista.',
        ru: 'Пожалуйста, введите название списка.',
        fr: 'Veuillez entrer le nom de la liste.',
        de: 'Bitte geben Sie den Namen der Liste ein.',
    },
    'Set as default list at this property': {
        pt: 'Definir como lista padrão nesta propriedade',
        es: 'Establecer como lista predeterminada en esta propiedad',
        ru: 'Установить как список по умолчанию для этого объекта',
        fr: 'Définir comme liste par défaut pour cette propriété',
        de: 'Als Standardliste für diese Immobilie festlegen',
    },
    'Require pros to provide photos or videos so that you can remotely inspect each job.': {
        pt: 'Exigir que os profissionais forneçam fotos ou vídeos para que você possa inspecionar remotamente cada trabalho.',
        es: 'Requerir que los profesionales proporcionen fotos o videos para que pueda inspeccionar remotamente cada trabajo.',
        ru: 'Требовать от профессионалов предоставления фотографий или видео, чтобы вы могли удаленно проверять каждую работу.',
        fr: 'Exiger des professionnels qu\'ils fournissent des photos ou des vidéos afin que vous puissiez inspecter chaque travail à distance.',
        de: 'Verlangen Sie von Profis, Fotos oder Videos bereitzustellen, damit Sie jeden Auftrag aus der Ferne inspizieren können.',
    },
    'How would you like your pro to visually inspect?': {
        pt: 'Como você gostaria que seu profissional inspecionasse visualmente?',
        es: '¿Cómo le gustaría que su profesional inspeccionara visualmente?',
        ru: 'Как бы вы хотели, чтобы ваш профессионал проводил визуальный осмотр?',
        fr: 'Comment souhaitez-vous que votre professionnel effectue l\'inspection visuelle ?',
        de: 'Wie möchten Sie, dass Ihr Profi visuell inspiziert?',
    },
    'Please select how to inspect.': {
        pt: 'Por favor, selecione como inspecionar.',
        es: 'Por favor, seleccione cómo inspeccionar.',
        ru: 'Пожалуйста, выберите способ проверки.',
        fr: 'Veuillez sélectionner comment inspecter.',
        de: 'Bitte wählen Sie aus, wie inspiziert werden soll.',
    },
    'When would you like them to inspect?': {
        pt: 'Quando você gostaria que eles inspecionassem?',
        es: '¿Cuándo le gustaría que inspeccionaran?',
        ru: 'Когда бы вы хотели, чтобы они провели проверку?',
        fr: 'Quand souhaitez-vous qu\'ils inspectent ?',
        de: 'Wann möchten Sie, dass sie inspizieren?',
    },
    'Please select when to inspect.': {
        pt: 'Por favor, selecione quando inspecionar.',
        es: 'Por favor, seleccione cuándo inspeccionar.',
        ru: 'Пожалуйста, выберите, когда проводить проверку.',
        fr: 'Veuillez sélectionner quand inspecter.',
        de: 'Bitte wählen Sie aus, wann inspiziert werden soll.',
    },
    'Add To-Dos List': {
        pt: 'Adicionar Lista de Tarefas',
        es: 'Agregar Lista de Tareas',
        ru: 'Добавить Список Дел',
        fr: 'Ajouter une Liste de Tâches',
        de: 'To-Do-Liste Hinzufügen',
    },
    'Videos': {
        pt: 'Vídeos',
        es: 'Videos',
        ru: 'Видео',
        fr: 'Vidéos',
        de: 'Videos',
    },
    'Before and after the job': {
        pt: 'Antes e depois do trabalho',
        es: 'Antes y después del trabajo',
        ru: 'До и после работы',
        fr: 'Avant et après le travail',
        de: 'Vor und nach dem Auftrag',
    },
    'Before the job': {
        pt: 'Antes do trabalho',
        es: 'Antes del trabajo',
        ru: 'До работы',
        fr: 'Avant le travail',
        de: 'Vor dem Auftrag',
    },
    'After the job': {
        pt: 'Depois do trabalho',
        es: 'Después del trabajo',
        ru: 'После работы',
        fr: 'Après le travail',
        de: 'Nach dem Auftrag',
    },
    'Add new list': {
        pt: 'Adicionar nova lista',
        es: 'Agregar nueva lista',
        ru: 'Добавить новый список',
        fr: 'Ajouter une nouvelle liste',
        de: 'Neue Liste hinzufügen',
    },
    'Copy list from another address': {
        pt: 'Copiar lista de outro endereço',
        es: 'Copiar lista de otra dirección',
        ru: 'Копировать список с другого адреса',
        fr: 'Copier la liste d\'une autre adresse',
        de: 'Liste von einer anderen Adresse kopieren',
    },
    'Action not allowed. The Task List has rooms associated!': {
        pt: 'Ação não permitida. A Lista de Tarefas tem quartos associados!',
        es: 'Acción no permitida. ¡La Lista de Tareas tiene habitaciones asociadas!',
        ru: 'Действие не разрешено. К Списку Задач привязаны комнаты!',
        fr: 'Action non autorisée. La Liste des Tâches a des pièces associées !',
        de: 'Aktion nicht erlaubt. Die Aufgabenliste hat zugeordnete Räume!',
    },
    'This list has rooms unique to the address in it, so it cannot be copied. Please remove the rooms or create a new list with no address specific rooms in it.': {
        pt: 'Esta lista tem quartos exclusivos do endereço, então não pode ser copiada. Por favor, remova os quartos ou crie uma nova lista sem quartos específicos do endereço.',
        es: 'Esta lista tiene habitaciones únicas para la dirección, por lo que no se puede copiar. Por favor, elimine las habitaciones o cree una nueva lista sin habitaciones específicas de la dirección.',
        ru: 'Этот список содержит уникальные для адреса комнаты, поэтому его нельзя скопировать. Пожалуйста, удалите комнаты или создайте новый список без комнат, специфичных для адреса.',
        fr: 'Cette liste contient des pièces uniques à l\'adresse, elle ne peut donc pas être copiée. Veuillez supprimer les pièces ou créer une nouvelle liste sans pièces spécifiques à l\'adresse.',
        de: 'Diese Liste enthält Räume, die für die Adresse einzigartig sind, daher kann sie nicht kopiert werden. Bitte entfernen Sie die Räume oder erstellen Sie eine neue Liste ohne adressspezifische Räume.',
    },

    //ToDosAddRoomPage
    'Use cards to group To-Dos together for your pro.': {
        pt: 'Use cartões para agrupar tarefas para o seu profissional.',
        es: 'Use tarjetas para agrupar tareas para su profesional.',
        ru: 'Используйте карточки для группировки задач для вашего специалиста.',
        fr: 'Utilisez des cartes pour regrouper les tâches pour votre professionnel.',
        de: 'Verwenden Sie Karten, um Aufgaben für Ihren Profi zu gruppieren.',
    },
    'Does this card relate to a room in your home?': {
        pt: 'Este cartão está relacionado a um cômodo da sua casa?',
        es: '¿Esta tarjeta se relaciona con una habitación de su casa?',
        ru: 'Связана ли эта карточка с комнатой в вашем доме?',
        fr: 'Cette carte est-elle liée à une pièce de votre maison ?',
        de: 'Bezieht sich diese Karte auf einen Raum in Ihrem Zuhause?',
    },
    'Card Name': {
        pt: 'Nome do Cartão',
        es: 'Nombre de la Tarjeta',
        ru: 'Название Карточки',
        fr: 'Nom de la Carte',
        de: 'Kartenname',
    },
    'Please enter the name of the card.': {
        pt: 'Por favor, insira o nome do cartão.',
        es: 'Por favor, ingrese el nombre de la tarjeta.',
        ru: 'Пожалуйста, введите название карточки.',
        fr: 'Veuillez entrer le nom de la carte.',
        de: 'Bitte geben Sie den Namen der Karte ein.',
    },

    //ToDosAddRoomPhotoPage
    'Add Card Photo': {
        pt: 'Adicionar Foto do Cartão',
        es: 'Agregar Foto de la Tarjeta',
        ru: 'Добавить Фото Карточки',
        fr: 'Ajouter une Photo à la Carte',
        de: 'Kartenfoto hinzufügen',
    },
    'Add the photo caption': {
        pt: 'Adicionar a legenda da foto',
        es: 'Agregar el pie de foto',
        ru: 'Добавить подпись к фото',
        fr: 'Ajouter la légende de la photo',
        de: 'Fügen Sie die Bildunterschrift hinzu',
    },
    'Retake Photo': {
        pt: 'Tirar Foto Novamente',
        es: 'Volver a Tomar la Foto',
        ru: 'Переснять Фото',
        fr: 'Reprendre la Photo',
        de: 'Foto erneut aufnehmen',
    },

    //ToDosAddToDoPage
    'Add To-Do': {
        pt: 'Adicionar Tarefa',
        es: 'Agregar Tarea',
        ru: 'Добавить Задачу',
        fr: 'Ajouter une Tâche',
        de: 'Aufgabe Hinzufügen',
    },
    'To-Do': {
        pt: 'Tarefa',
        es: 'Tarea',
        ru: 'Задача',
        fr: 'Tâche',
        de: 'Aufgabe',
    },
    'Details (Optional)': {
        pt: 'Detalhes (Opcional)',
        es: 'Detalles (Opcional)',
        ru: 'Детали (Необязательно)',
        fr: 'Détails (Facultatif)',
        de: 'Details (Optional)',
    },
    'Estimated Time': {
        pt: 'Tempo Estimado',
        es: 'Tiempo Estimado',
        ru: 'Расчетное Время',
        fr: 'Temps Estimé',
        de: 'Geschätzte Zeit',
    },
    'Mark as Important': {
        pt: 'Marcar como Importante',
        es: 'Marcar como Importante',
        ru: 'Отметить как Важное',
        fr: 'Marquer comme Important',
        de: 'Als Wichtig Markieren',
    },
    'Add to Cards': {
        pt: 'Adicionar aos Cartões',
        es: 'Agregar a las Tarjetas',
        ru: 'Добавить в Карточки',
        fr: 'Ajouter aux Cartes',
        de: 'Zu Karten Hinzufügen',
    },
    'Please select cards to add To-Do.': {
        pt: 'Por favor, selecione os cartões para adicionar a Tarefa.',
        es: 'Por favor, seleccione las tarjetas para agregar la Tarea.',
        ru: 'Пожалуйста, выберите карточки, чтобы добавить Задачу.',
        fr: 'Veuillez sélectionner les cartes pour ajouter la Tâche.',
        de: 'Bitte wählen Sie Karten aus, um die Aufgabe hinzuzufügen.',
    },
    'Associate Item (Optional)': {
        pt: 'Associar Item (Opcional)',
        es: 'Asociar Elemento (Opcional)',
        ru: 'Связать Элемент (Необязательно)',
        fr: 'Associer un Élément (Facultatif)',
        de: 'Element Zuordnen (Optional)',
    },
    'No items added.': {
        pt: 'Nenhum item adicionado.',
        es: 'No se han agregado elementos.',
        ru: 'Элементы не добавлены.',
        fr: 'Aucun élément ajouté.',
        de: 'Keine Elemente hinzugefügt.',
    },
    'No items added. Please add an item before adding this custom field.': {
        pt: 'Nenhum item adicionado. Por favor, adicione um item antes de adicionar este campo personalizado.',
        es: 'No se han agregado elementos. Por favor, agregue un elemento antes de agregar este campo personalizado.',
        ru: 'Элементы не добавлены. Пожалуйста, добавьте элемент перед добавлением этого пользовательского поля.',
        fr: 'Aucun élément ajouté. Veuillez ajouter un élément avant d\'ajouter ce champ personnalisé.',
        de: 'Keine Elemente hinzugefügt. Bitte fügen Sie ein Element hinzu, bevor Sie dieses benutzerdefinierte Feld hinzufügen.',
    },
    '+ Add Item': {
        pt: '+ Adicionar Item',
        es: '+ Agregar Elemento',
        ru: '+ Добавить Элемент',
        fr: '+ Ajouter un Élément',
        de: '+ Element Hinzufügen',
    },
    '- Stop Adding item': {
        pt: '- Parar de Adicionar Item',
        es: '- Dejar de Agregar Elemento',
        ru: '- Прекратить Добавление Элемента',
        fr: '- Arrêter d\'Ajouter un Élément',
        de: '- Element Hinzufügen Beenden',
    },
    'Add New Item': {
        pt: 'Adicionar Novo Item',
        es: 'Agregar Nuevo Elemento',
        ru: 'Добавить Новый Элемент',
        fr: 'Ajouter un Nouvel Élément',
        de: 'Neues Element Hinzufügen',
    },
    'Category': {
        pt: 'Categoria',
        es: 'Categoría',
        ru: 'Категория',
        fr: 'Catégorie',
        de: 'Kategorie',
    },
    'Name / Model #': {
        pt: 'Nome / Modelo #',
        es: 'Nombre / Modelo #',
        ru: 'Название / Модель #',
        fr: 'Nom / Modèle #',
        de: 'Name / Modell #',
    },
    'Model Name / Number': {
        pt: 'Nome / Número do Modelo',
        es: 'Nombre / Número de Modelo',
        ru: 'Название / Номер Модели',
        fr: 'Nom / Numéro de Modèle',
        de: 'Modellname / Nummer',
    },
    'Track Inventory (Optional)': {
        pt: 'Rastrear Inventário (Opcional)',
        es: 'Seguimiento de Inventario (Opcional)',
        ru: 'Отслеживание Инвентаря (Необязательно)',
        fr: 'Suivi de l\'Inventaire (Facultatif)',
        de: 'Bestandsverfolgung (Optional)',
    },
    'We create low stock alerts if the current quantity goes below': {
        pt: 'Criamos alertas de estoque baixo se a quantidade atual ficar abaixo de',
        es: 'Creamos alertas de stock bajo si la cantidad actual baja de',
        ru: 'Мы создаем оповещения о низком запасе, если текущее количество падает ниже',
        fr: 'Nous créons des alertes de stock bas si la quantité actuelle descend en dessous de',
        de: 'Wir erstellen Warnungen bei niedrigem Bestand, wenn die aktuelle Menge unter',
    },
    '. To have pros update the current inventory,': {
        pt: '. Para que os profissionais atualizem o inventário atual,',
        es: '. Para que los profesionales actualicen el inventario actual,',
        ru: '. Чтобы профессионалы обновляли текущий инвентарь,',
        fr: '. Pour que les pros mettent à jour l\'inventaire actuel,',
        de: '. Damit Profis den aktuellen Bestand aktualisieren können,',
    },
    'add custom field': {
        pt: 'adicione um campo personalizado',
        es: 'agregue un campo personalizado',
        ru: 'добавьте пользовательское поле',
        fr: 'ajoutez un champ personnalisé',
        de: 'fügen Sie ein benutzerdefiniertes Feld hinzu',
    },
    'Track Inventory for this Item': {
        pt: 'Rastrear Inventário para este Item',
        es: 'Seguimiento de Inventario para este Elemento',
        ru: 'Отслеживать Инвентарь для этого Элемента',
        fr: 'Suivre l\'Inventaire pour cet Élément',
        de: 'Bestand für dieses Element verfolgen',
    },
    'Add Custom Field': {
        pt: 'Adicionar Campo Personalizado',
        es: 'Agregar Campo Personalizado',
        ru: 'Добавить Пользовательское Поле',
        fr: 'Ajouter un Champ Personnalisé',
        de: 'Benutzerdefiniertes Feld Hinzufügen',
    },
    'Custom Fields': {
        pt: 'Campos Personalizados',
        es: 'Campos Personalizados',
        ru: 'Пользовательские Поля',
        fr: 'Champs Personnalisés',
        de: 'Benutzerdefinierte Felder',
    },
    'Add Field': {
        pt: 'Adicionar Campo',
        es: 'Agregar Campo',
        ru: 'Добавить Поле',
        fr: 'Ajouter un Champ',
        de: 'Feld Hinzufügen',
    },
    'Your pro will be asked to fill out the following fields. Need help?': {
        pt: 'Seu profissional será solicitado a preencher os seguintes campos. Precisa de ajuda?',
        es: 'Se le pedirá a su profesional que complete los siguientes campos. ¿Necesita ayuda?',
        ru: 'Вашего специалиста попросят заполнить следующие поля. Нужна помощь?',
        fr: 'Votre pro sera invité à remplir les champs suivants. Besoin d\'aide ?',
        de: 'Ihr Profi wird gebeten, die folgenden Felder auszufüllen. Brauchen Sie Hilfe?',
    },
    'Contact Concierge': {
        pt: 'Contatar Concierge',
        es: 'Contactar al Conserje',
        ru: 'Связаться с Консьержем',
        fr: 'Contacter le Concierge',
        de: 'Kontaktieren Sie den Concierge',
    },
    'Type: Select': {
        pt: 'Tipo: Selecionar',
        es: 'Tipo: Seleccionar',
        ru: 'Тип: Выбрать',
        fr: 'Type : Sélectionner',
        de: 'Typ: Auswählen',
    },
    'Options: Done / Not Done': {
        pt: 'Opções: Feito / Não Feito',
        es: 'Opciones: Hecho / No Hecho',
        ru: 'Варианты: Выполнено / Не Выполнено',
        fr: 'Options : Fait / Pas Fait',
        de: 'Optionen: Erledigt / Nicht Erledigt',
    },
    'Field Label (what should the pro do?)': {
        pt: 'Rótulo do Campo (o que o profissional deve fazer?)',
        es: 'Etiqueta del Campo (¿qué debe hacer el profesional?)',
        ru: 'Метка Поля (что должен сделать специалист?)',
        fr: 'Libellé du Champ (que doit faire le pro ?)',
        de: 'Feldbezeichnung (was soll der Profi tun?)',
    },
    'Please enter a label.': {
        pt: 'Por favor, insira um rótulo.',
        es: 'Por favor, ingrese una etiqueta.',
        ru: 'Пожалуйста, введите метку.',
        fr: 'Veuillez entrer un libellé.',
        de: 'Bitte geben Sie eine Bezeichnung ein.',
    },
    'Field Type': {
        pt: 'Tipo de Campo',
        es: 'Tipo de Campo',
        ru: 'Тип Поля',
        fr: 'Type de Champ',
        de: 'Feldtyp',
    },
    'Please select a field type.': {
        pt: 'Por favor, selecione um tipo de campo.',
        es: 'Por favor, seleccione un tipo de campo.',
        ru: 'Пожалуйста, выберите тип поля.',
        fr: 'Veuillez sélectionner un type de champ.',
        de: 'Bitte wählen Sie einen Feldtyp aus.',
    },
    'Make this required for the pro': {
        pt: 'Tornar isto obrigatório para o profissional',
        es: 'Hacer esto obligatorio para el profesional',
        ru: 'Сделать это обязательным для специалиста',
        fr: 'Rendre ceci obligatoire pour le pro',
        de: 'Dies für den Profi erforderlich machen',
    },
    'Options': {
        pt: 'Opções',
        es: 'Opciones',
        ru: 'Варианты',
        fr: 'Options',
        de: 'Optionen',
    },
    'Add Option': {
        pt: 'Adicionar Opção',
        es: 'Agregar Opción',
        ru: 'Добавить Вариант',
        fr: 'Ajouter une Option',
        de: 'Option Hinzufügen',
    },
    'Option': {
        pt: 'Opção',
        es: 'Opción',
        ru: 'Вариант',
        fr: 'Option',
        de: 'Option',
    },
    'None Added - Add at Least': {
        pt: 'Nenhum Adicionado - Adicione pelo Menos',
        es: 'Ninguno Agregado - Agregar al Menos',
        ru: 'Ничего не Добавлено - Добавьте как Минимум',
        fr: 'Aucun Ajouté - Ajouter au Moins',
        de: 'Keine Hinzugefügt - Fügen Sie Mindestens Hinzu',
    },
    'To-Do Help': {
        pt: 'Ajuda de Tarefas',
        es: 'Ayuda de Tareas',
        ru: 'Помощь по Задачам',
        fr: 'Aide To-Do',
        de: 'To-Do-Hilfe',
    },

    //AllToDoListsPage
    'Defaults': {
        pt: 'Padrões',
        es: 'Valores predeterminados',
        ru: 'Значения по умолчанию',
        fr: 'Valeurs par défaut',
        de: 'Standardwerte',
    },
    'No To-Do Lists Created': {
        pt: 'Nenhuma Lista de Tarefas Criada',
        es: 'No Se Han Creado Listas de Tareas',
        ru: 'Списки Дел Не Созданы',
        fr: 'Aucune Liste de Tâches Créée',
        de: 'Keine To-Do-Listen Erstellt',
    },

    //ToDosApplyToJobPage
    'Changed saved.': {
        pt: 'Alterações salvas.',
        es: 'Cambios guardados.',
        ru: 'Изменения сохранены.',
        fr: 'Modifications enregistrées.',
        de: 'Änderungen gespeichert.'
    },
    'Before Videos': {
        pt: 'Vídeos Anteriores',
        es: 'Videos Anteriores',
        ru: 'Видео До',
        fr: 'Vidéos Avant',
        de: 'Videos Vorher'
    },
    'Before & After Videos': {
        pt: 'Vídeos Antes e Depois',
        es: 'Videos de Antes y Después',
        ru: 'Видео До и После',
        fr: 'Vidéos Avant et Après',
        de: 'Videos Vorher und Nachher'
    },
    'After Videos': {
        pt: 'Vídeos Posteriores',
        es: 'Videos Posteriores',
        ru: 'Видео После',
        fr: 'Vidéos Après',
        de: 'Videos Nachher'
    },

    //ToDosApplyToJobsPage
    'Apply List to Jobs': {
        pt: 'Aplicar Lista aos Trabalhos',
        es: 'Aplicar Lista a los Trabajos',
        ru: 'Применить Список к Работам',
        fr: 'Appliquer la Liste aux Travaux',
        de: 'Liste auf Aufträge Anwenden'
    },
    'Show More Jobs': {
        pt: 'Mostrar Mais Trabalhos',
        es: 'Mostrar Más Trabajos',
        ru: 'Показать Больше Работ',
        fr: 'Afficher Plus de Travaux',
        de: 'Mehr Aufträge Anzeigen'
    },
    'Use as default list for future jobs': {
        pt: 'Usar como lista padrão para trabalhos futuros',
        es: 'Usar como lista predeterminada para trabajos futuros',
        ru: 'Использовать как список по умолчанию для будущих работ',
        fr: 'Utiliser comme liste par défaut pour les travaux futurs',
        de: 'Als Standardliste für zukünftige Aufträge verwenden'
    },
    'You are currently not using To-Dos by default at this address.  Changes here will not affect that setting.': {
        pt: 'Você não está usando Tarefas por padrão neste endereço. As alterações aqui não afetarão essa configuração.',
        es: 'Actualmente no está utilizando Tareas por defecto en esta dirección. Los cambios aquí no afectarán esa configuración.',
        ru: 'В настоящее время вы не используете Задачи по умолчанию по этому адресу. Изменения здесь не повлияют на этот параметр.',
        fr: 'Vous n\'utilisez actuellement pas les Tâches par défaut à cette adresse. Les modifications ici n\'affecteront pas ce paramètre.',
        de: 'Sie verwenden derzeit keine To-Dos standardmäßig unter dieser Adresse. Änderungen hier werden diese Einstellung nicht beeinflussen.'
    },

    //ChangeToDoStatePage
    'Stop Using To-Dos?': {
        pt: 'Parar de Usar Tarefas?',
        es: '¿Dejar de Usar Tareas?',
        ru: 'Прекратить Использование Задач?',
        fr: 'Arrêter d\'Utiliser les Tâches?',
        de: 'To-Dos nicht mehr Verwenden?'
    },
    'This will remove lists from all jobs and stop using To-Dos for future jobs.  You can change this at any time.': {
        pt: 'Isso removerá listas de todos os trabalhos e interromperá o uso de Tarefas para trabalhos futuros. Você pode alterar isso a qualquer momento.',
        es: 'Esto eliminará las listas de todos los trabajos y dejará de usar Tareas para trabajos futuros. Puede cambiar esto en cualquier momento.',
        ru: 'Это удалит списки со всех работ и прекратит использование Задач для будущих работ. Вы можете изменить это в любое время.',
        fr: 'Cela supprimera les listes de tous les travaux et arrêtera d\'utiliser les Tâches pour les travaux futurs. Vous pouvez modifier cela à tout moment.',
        de: 'Dies entfernt Listen von allen Aufträgen und stoppt die Verwendung von To-Dos für zukünftige Aufträge. Sie können dies jederzeit ändern.'
    },
    'Start Using To-Dos?': {
        pt: 'Começar a Usar Tarefas?',
        es: '¿Empezar a Usar Tareas?',
        ru: 'Начать Использование Задач?',
        fr: 'Commencer à Utiliser les Tâches?',
        de: 'To-Dos Verwenden?'
    },
    'This will apply your default list to all booked and future jobs.  You can change this at any time.': {
        pt: 'Isso aplicará sua lista padrão a todos os trabalhos agendados e futuros. Você pode alterar isso a qualquer momento.',
        es: 'Esto aplicará su lista predeterminada a todos los trabajos reservados y futuros. Puede cambiar esto en cualquier momento.',
        ru: 'Это применит ваш список по умолчанию ко всем забронированным и будущим работам. Вы можете изменить это в любое время.',
        fr: 'Cela appliquera votre liste par défaut à tous les travaux réservés et futurs. Vous pouvez modifier cela à tout moment.',
        de: 'Dies wendet Ihre Standardliste auf alle gebuchten und zukünftigen Aufträge an. Sie können dies jederzeit ändern.'
    },

    //ToDosCreateFirstListPage
    'Add Rooms': {
        pt: 'Adicionar Quartos',
        es: 'Agregar Habitaciones',
        ru: 'Добавить Комнаты',
        fr: 'Ajouter des Pièces',
        de: 'Räume Hinzufügen'
    },
    'Create First List': {
        pt: 'Criar Primeira Lista',
        es: 'Crear Primera Lista',
        ru: 'Создать Первый Список',
        fr: 'Créer Première Liste',
        de: 'Erste Liste Erstellen'
    },
    'Please let us know some detail about the address for this list:': {
        pt: 'Por favor, nos informe alguns detalhes sobre o endereço para esta lista:',
        es: 'Por favor, háblenos sobre los detalles de la dirección para esta lista:',
        ru: 'Пожалуйста, сообщите нам некоторые детали об адресе для этого списка:',
        fr: 'Veuillez nous donner quelques détails sur l\'adresse pour cette liste:',
        de: 'Bitte teilen Sie uns einige Details zur Adresse für diese Liste mit:'
    },
    'Create To-Do List': {
        pt: 'Criar Lista de Tarefas',
        es: 'Crear Lista de Tareas',
        ru: 'Создать Список Задач',
        fr: 'Créer Liste de Tâches',
        de: 'To-Do-Liste Erstellen'
    },

    //DefaultListsPage
    'Account Service Defaults': {
        pt: 'Padrões de Serviço da Conta',
        es: 'Valores Predeterminados del Servicio de Cuenta',
        ru: 'Настройки Сервиса Аккаунта',
        fr: 'Paramètres de Service du Compte',
        de: 'Konto-Service-Standards'
    },
    'TIDY will use any account service default before your address default.': {
        pt: 'TIDY usará qualquer padrão de serviço da conta antes do padrão do seu endereço.',
        es: 'TIDY utilizará cualquier valor predeterminado del servicio de cuenta antes del valor predeterminado de su dirección.',
        ru: 'TIDY будет использовать любые настройки сервиса аккаунта перед настройками вашего адреса.',
        fr: 'TIDY utilisera tout paramètre de service du compte avant votre paramètre d\'adresse par défaut.',
        de: 'TIDY verwendet jeden Konto-Service-Standard vor Ihrem Adress-Standard.'
    },
    'None added': {
        pt: 'Nenhum adicionado',
        es: 'Ninguno agregado',
        ru: 'Ничего не добавлено',
        fr: 'Aucun ajouté',
        de: 'Keine hinzugefügt'
    },
    'You haven\'t added any templates to make into account service defaults. Please go to the': {
        pt: 'Você não adicionou nenhum modelo para transformar em padrões de serviço da conta. Por favor, vá para a',
        es: 'No ha agregado ninguna plantilla para convertir en valores predeterminados del servicio de cuenta. Por favor, vaya a la',
        ru: 'Вы не добавили шаблоны для создания настроек сервиса аккаунта. Пожалуйста, перейдите на',
        fr: 'Vous n\'avez ajouté aucun modèle à transformer en paramètres de service du compte. Veuillez aller à la',
        de: 'Sie haben keine Vorlagen hinzugefügt, die zu Konto-Service-Standards gemacht werden können. Bitte gehen Sie zu den'
    },
    'page and make one of your lists a template to make it an account service default. The list cannot have rooms specific to an address to make it a template.': {
        pt: 'página e transforme uma de suas listas em um modelo para torná-la um padrão de serviço da conta. A lista não pode ter quartos específicos para um endereço para torná-la um modelo.',
        es: 'página y convierta una de sus listas en una plantilla para convertirla en un valor predeterminado del servicio de cuenta. La lista no puede tener habitaciones específicas para una dirección para convertirla en una plantilla.',
        ru: 'страницу и сделайте один из ваших списков шаблоном, чтобы сделать его настройкой сервиса аккаунта. Список не может иметь комнаты, привязанные к конкретному адресу, чтобы стать шаблоном.',
        fr: 'page et faites de l\'une de vos listes un modèle pour en faire un paramètre de service du compte. La liste ne peut pas avoir de pièces spécifiques à une adresse pour en faire un modèle.',
        de: 'Seite und machen Sie eine Ihrer Listen zu einer Vorlage, um sie zu einem Konto-Service-Standard zu machen. Die Liste darf keine adressspezifischen Räume enthalten, um sie zu einer Vorlage zu machen.'
    },

    //ToDosDoNotCleanRoomPage
    'This room is marked as <u> do not clean</u>.': {
        pt: 'Este quarto está marcado como <u> não limpar</u>.',
        es: 'Esta habitación está marcada como <u> no limpiar</u>.',
        ru: 'Эта комната помечена как <u> не убирать</u>.',
        fr: 'Cette pièce est marquée comme <u> ne pas nettoyer</u>.',
        de: 'Dieser Raum ist als <u> nicht reinigen</u> markiert.'
    },
    'Mark Room as <u>Do Clean</u>': {
        pt: 'Marcar Quarto como <u>Limpar</u>',
        es: 'Marcar Habitación como <u>Limpiar</u>',
        ru: 'Отметить Комнату как <u>Убирать</u>',
        fr: 'Marquer la Pièce comme <u>À Nettoyer</u>',
        de: 'Raum als <u>Reinigen</u> markieren'
    },
    'Room updated!': {
        pt: 'Quarto atualizado!',
        es: '¡Habitación actualizada!',
        ru: 'Комната обновлена!',
        fr: 'Pièce mise à jour!',
        de: 'Raum aktualisiert!'
    },

    //EditDefaultListPage
    'Edit Default To-Do List': {
        pt: 'Editar Lista Padrão de Tarefas',
        es: 'Editar Lista Predeterminada de Tareas',
        ru: 'Редактировать Список Задач по Умолчанию',
        fr: 'Modifier la Liste de Tâches par Défaut',
        de: 'Standard-Aufgabenliste Bearbeiten'
    },
    'Editing the default list for address:': {
        pt: 'Editando a lista padrão para o endereço:',
        es: 'Editando la lista predeterminada para la dirección:',
        ru: 'Редактирование списка по умолчанию для адреса:',
        fr: 'Modification de la liste par défaut pour l\'adresse:',
        de: 'Bearbeiten der Standardliste für die Adresse:'
    },
    'Adding the default list for address:': {
        pt: 'Adicionando a lista padrão para o endereço:',
        es: 'Agregando la lista predeterminada para la dirección:',
        ru: 'Добавление списка по умолчанию для адреса:',
        fr: 'Ajout de la liste par défaut pour l\'adresse:',
        de: 'Hinzufügen der Standardliste für die Adresse:'
    },
    'Editing the default list for your account.': {
        pt: 'Editando a lista padrão para sua conta.',
        es: 'Editando la lista predeterminada para su cuenta.',
        ru: 'Редактирование списка по умолчанию для вашего аккаунта.',
        fr: 'Modification de la liste par défaut pour votre compte.',
        de: 'Bearbeiten der Standardliste für Ihr Konto.'
    },
    'Adding a default list for your account.': {
        pt: 'Adicionando uma lista padrão para sua conta.',
        es: 'Agregando una lista predeterminada para su cuenta.',
        ru: 'Добавление списка по умолчанию для вашего аккаунта.',
        fr: 'Ajout d\'une liste par défaut pour votre compte.',
        de: 'Hinzufügen einer Standardliste für Ihr Konto.'
    },
    'Default List': {
        pt: 'Lista Padrão',
        es: 'Lista Predeterminada',
        ru: 'Список по Умолчанию',
        fr: 'Liste par Défaut',
        de: 'Standardliste'
    },
    'Note: you can only make a template an account service default. Go to your': {
        pt: 'Nota: você só pode transformar um modelo em padrão de serviço da conta. Vá para sua',
        es: 'Nota: solo puede hacer que una plantilla sea un valor predeterminado del servicio de cuenta. Vaya a su',
        ru: 'Примечание: вы можете сделать только шаблон настройкой сервиса аккаунта. Перейдите на вашу',
        fr: 'Note : vous ne pouvez faire d\'un modèle qu\'un paramètre de service du compte. Allez à votre',
        de: 'Hinweis: Sie können nur eine Vorlage zu einem Konto-Service-Standard machen. Gehen Sie zu Ihrer'
    },

    //ToDosEditRoomInfoPage
    'Card Info': {
        pt: 'Informações do Cartão',
        es: 'Información de la Tarjeta',
        ru: 'Информация о Карте',
        fr: 'Informations sur la Carte',
        de: 'Karteninformationen'
    },
    'Please enter the name of the card': {
        pt: 'Por favor, insira o nome do cartão',
        es: 'Por favor, ingrese el nombre de la tarjeta',
        ru: 'Пожалуйста, введите название карты',
        fr: 'Veuillez entrer le nom de la carte',
        de: 'Bitte geben Sie den Namen der Karte ein'
    },
    'Please enter the floor the card is on': {
        pt: 'Por favor, insira o andar em que o cartão está',
        es: 'Por favor, ingrese el piso en el que está la tarjeta',
        ru: 'Пожалуйста, введите этаж, на котором находится карта',
        fr: 'Veuillez entrer l\'étage où se trouve la carte',
        de: 'Bitte geben Sie die Etage ein, auf der sich die Karte befindet'
    },
    'Please select the room type': {
        pt: 'Por favor, selecione o tipo de quarto',
        es: 'Por favor, seleccione el tipo de habitación',
        ru: 'Пожалуйста, выберите тип комнаты',
        fr: 'Veuillez sélectionner le type de chambre',
        de: 'Bitte wählen Sie den Zimmertyp'
    },
    'Delete Do Not': {
        pt: 'Excluir Não Fazer',
        es: 'Eliminar No Hacer',
        ru: 'Удалить Запрет',
        fr: 'Supprimer l\'Interdiction',
        de: 'Verbot Löschen'
    },

    //ToDosEditToDoPage
    'Edit To-Do': {
        pt: 'Editar Tarefa',
        es: 'Editar Tarea',
        ru: 'Редактировать Задачу',
        fr: 'Modifier la Tâche',
        de: 'Aufgabe Bearbeiten'
    },
    'Please enter the name of the To-Do': {
        pt: 'Por favor, insira o nome da tarefa',
        es: 'Por favor, ingrese el nombre de la tarea',
        ru: 'Пожалуйста, введите название задачи',
        fr: 'Veuillez entrer le nom de la tâche',
        de: 'Bitte geben Sie den Namen der Aufgabe ein'
    },
    'Details (optional)': {
        pt: 'Detalhes (opcional)',
        es: 'Detalles (opcional)',
        ru: 'Детали (необязательно)',
        fr: 'Détails (facultatif)',
        de: 'Details (optional)'
    },
    'You haven\'t added items. You can add items to associate with To-Dos, track maintenance & more': {
        pt: 'Você não adicionou itens. Você pode adicionar itens para associar com tarefas, rastrear manutenção e mais',
        es: 'No ha agregado elementos. Puede agregar elementos para asociar con tareas, rastrear mantenimiento y más',
        ru: 'Вы не добавили элементы. Вы можете добавить элементы для связи с задачами, отслеживания обслуживания и многого другого',
        fr: 'Vous n\'avez pas ajouté d\'éléments. Vous pouvez ajouter des éléments à associer aux tâches, suivre l\'entretien et plus encore',
        de: 'Sie haben keine Elemente hinzugefügt. Sie können Elemente hinzufügen, um sie mit Aufgaben zu verknüpfen, Wartung zu verfolgen und mehr'
    },
    'View Property': {
        pt: 'Ver Propriedade',
        es: 'Ver Propiedad',
        ru: 'Просмотр Недвижимости',
        fr: 'Voir la Propriété',
        de: 'Immobilie Anzeigen'
    },
    'add a custom count field': {
        pt: 'adicionar um campo de contagem personalizado',
        es: 'agregar un campo de recuento personalizado',
        ru: 'добавить пользовательское поле подсчета',
        fr: 'ajouter un champ de comptage personnalisé',
        de: 'benutzerdefiniertes Zählfeld hinzufügen'
    },
    'You don\'t have inventory tracking set up for this item. If you want pros update the current inventory, add a minimum quantity + current quantity to the room item in this property.': {
        pt: 'Você não configurou o rastreamento de inventário para este item. Se você deseja que os profissionais atualizem o inventário atual, adicione uma quantidade mínima + quantidade atual ao item do quarto nesta propriedade.',
        es: 'No tiene configurado el seguimiento de inventario para este elemento. Si desea que los profesionales actualicen el inventario actual, agregue una cantidad mínima + cantidad actual al elemento de la habitación en esta propiedad.',
        ru: 'У вас не настроено отслеживание инвентаря для этого элемента. Если вы хотите, чтобы специалисты обновляли текущий инвентарь, добавьте минимальное количество + текущее количество к элементу комнаты в этой недвижимости.',
        fr: 'Vous n\'avez pas configuré le suivi d\'inventaire pour cet élément. Si vous souhaitez que les pros mettent à jour l\'inventaire actuel, ajoutez une quantité minimale + quantité actuelle à l\'élément de la pièce dans cette propriété.',
        de: 'Sie haben keine Bestandsverfolgung für diesen Artikel eingerichtet. Wenn Sie möchten, dass Profis den aktuellen Bestand aktualisieren, fügen Sie eine Mindestmenge + aktuelle Menge zum Raumelement in dieser Immobilie hinzu.'
    },
    'our pro will be asked to fill out the following fields. Need help?': {
        pt: 'nosso profissional será solicitado a preencher os seguintes campos. Precisa de ajuda?',
        es: 'se le pedirá a nuestro profesional que complete los siguientes campos. ¿Necesita ayuda?',
        ru: 'нашего специалиста попросят заполнить следующие поля. Нужна помощь?',
        fr: 'notre pro sera invité à remplir les champs suivants. Besoin d\'aide ?',
        de: 'unser Profi wird gebeten, die folgenden Felder auszufüllen. Brauchen Sie Hilfe?'
    },
    'Delete To-Do': {
        pt: 'Excluir Tarefa',
        es: 'Eliminar Tarea',
        ru: 'Удалить Задачу',
        fr: 'Supprimer la Tâche',
        de: 'Aufgabe Löschen'
    },
    'Delete To-Do?': {
        pt: 'Excluir Tarefa?',
        es: '¿Eliminar Tarea?',
        ru: 'Удалить Задачу?',
        fr: 'Supprimer la Tâche ?',
        de: 'Aufgabe Löschen?'
    },
    'You will not be able to undo this action.': {
        pt: 'Você não poderá desfazer esta ação.',
        es: 'No podrá deshacer esta acción.',
        ru: 'Вы не сможете отменить это действие.',
        fr: 'Vous ne pourrez pas annuler cette action.',
        de: 'Sie können diese Aktion nicht rückgängig machen.'
    },

    //ToDosListSettingsPage
    'Default To-Do Lists': {
        pt: 'Listas de Tarefas Padrão',
        es: 'Listas de Tareas Predeterminadas',
        ru: 'Списки Задач по Умолчанию',
        fr: 'Listes de Tâches par Défaut',
        de: 'Standard-Aufgabenlisten'
    },
    'Set your default To-Do lists. First we use property service default, then account service default, then property default, then account default.': {
        pt: 'Defina suas listas de tarefas padrão. Primeiro usamos o padrão do serviço da propriedade, depois o padrão do serviço da conta, depois o padrão da propriedade e depois o padrão da conta.',
        es: 'Configure sus listas de tareas predeterminadas. Primero usamos el valor predeterminado del servicio de propiedad, luego el valor predeterminado del servicio de cuenta, luego el valor predeterminado de propiedad y luego el valor predeterminado de cuenta.',
        ru: 'Установите списки задач по умолчанию. Сначала мы используем настройки по умолчанию для услуги недвижимости, затем настройки по умолчанию для услуги учетной записи, затем настройки по умолчанию для недвижимости, затем настройки по умолчанию для учетной записи.',
        fr: 'Définissez vos listes de tâches par défaut. Nous utilisons d\'abord le service de propriété par défaut, puis le service de compte par défaut, puis la propriété par défaut, puis le compte par défaut.',
        de: 'Legen Sie Ihre Standard-Aufgabenlisten fest. Zuerst verwenden wir den Immobilien-Service-Standard, dann den Konto-Service-Standard, dann den Immobilien-Standard und dann den Konto-Standard.'
    },
    'You have not set up To-Dos for this property yet.': {
        pt: 'Você ainda não configurou tarefas para esta propriedade.',
        es: 'Aún no ha configurado tareas para esta propiedad.',
        ru: 'Вы еще не настроили задачи для этой недвижимости.',
        fr: 'Vous n\'avez pas encore configuré de tâches pour cette propriété.',
        de: 'Sie haben noch keine Aufgaben für diese Immobilie eingerichtet.'
    },
    'Set Up To-Dos': {
        pt: 'Configurar Tarefas',
        es: 'Configurar Tareas',
        ru: 'Настроить Задачи',
        fr: 'Configurer les Tâches',
        de: 'Aufgaben Einrichten'
    },
    'Property Service Default': {
        pt: 'Padrão de Serviço da Propriedade',
        es: 'Servicio Predeterminado de Propiedad',
        ru: 'Услуга Недвижимости по Умолчанию',
        fr: 'Service de Propriété par Défaut',
        de: 'Standard-Immobilien-Service'
    },
    'Account Service Default': {
        pt: 'Padrão de Serviço da Conta',
        es: 'Servicio Predeterminado de Cuenta',
        ru: 'Услуга Учетной Записи по Умолчанию',
        fr: 'Service de Compte par Défaut',
        de: 'Standard-Konto-Service'
    },
    'Only To-Do lists with no rooms specific to a property can be made an account service default.': {
        pt: 'Apenas listas de tarefas sem quartos específicos para uma propriedade podem ser definidas como padrão de serviço da conta.',
        es: 'Solo las listas de tareas sin habitaciones específicas para una propiedad pueden establecerse como servicio predeterminado de cuenta.',
        ru: 'Только списки задач без комнат, специфичных для недвижимости, могут быть установлены как услуга учетной записи по умолчанию.',
        fr: 'Seules les listes de tâches sans pièces spécifiques à une propriété peuvent être définies comme service de compte par défaut.',
        de: 'Nur Aufgabenlisten ohne immobilienspezifische Räume können als Konto-Service-Standard festgelegt werden.'
    },
    'All your To-Do lists have rooms specific to a property so cannot be made an account service default. Create a To-Do list with no property specific rooms to set an account service default.': {
        pt: 'Todas as suas listas de tarefas têm quartos específicos para uma propriedade, então não podem ser definidas como padrão de serviço da conta. Crie uma lista de tarefas sem quartos específicos de propriedade para definir um padrão de serviço da conta.',
        es: 'Todas sus listas de tareas tienen habitaciones específicas para una propiedad, por lo que no se pueden establecer como servicio predeterminado de cuenta. Cree una lista de tareas sin habitaciones específicas de propiedad para establecer un servicio predeterminado de cuenta.',
        ru: 'Все ваши списки задач имеют комнаты, специфичные для недвижимости, поэтому не могут быть установлены как услуга учетной записи по умолчанию. Создайте список задач без комнат, специфичных для недвижимости, чтобы установить услугу учетной записи по умолчанию.',
        fr: 'Toutes vos listes de tâches ont des pièces spécifiques à une propriété et ne peuvent donc pas être définies comme service de compte par défaut. Créez une liste de tâches sans pièces spécifiques à la propriété pour définir un service de compte par défaut.',
        de: 'Alle Ihre Aufgabenlisten haben immobilienspezifische Räume und können daher nicht als Konto-Service-Standard festgelegt werden. Erstellen Sie eine Aufgabenliste ohne immobilienspezifische Räume, um einen Konto-Service-Standard festzulegen.'
    },
    'Property Default': {
        pt: 'Padrão da Propriedade',
        es: 'Predeterminado de Propiedad',
        ru: 'Недвижимость по Умолчанию',
        fr: 'Propriété par Défaut',
        de: 'Immobilien-Standard'
    },
    'Account Default': {
        pt: 'Padrão da Conta',
        es: 'Predeterminado de Cuenta',
        ru: 'Учетная Запись по Умолчанию',
        fr: 'Compte par Défaut',
        de: 'Konto-Standard'
    },
    'Default list': {
        pt: 'Lista padrão',
        es: 'Lista predeterminada',
        ru: 'Список по умолчанию',
        fr: 'Liste par défaut',
        de: 'Standardliste'
    },
    'Only To-Do lists with no rooms specific to a property can be made an account default.': {
        pt: 'Apenas listas de tarefas sem quartos específicos para uma propriedade podem ser definidas como padrão da conta.',
        es: 'Solo las listas de tareas sin habitaciones específicas para una propiedad pueden establecerse como predeterminadas de cuenta.',
        ru: 'Только списки задач без комнат, специфичных для недвижимости, могут быть установлены как учетная запись по умолчанию.',
        fr: 'Seules les listes de tâches sans pièces spécifiques à une propriété peuvent être définies comme compte par défaut.',
        de: 'Nur Aufgabenlisten ohne immobilienspezifische Räume können als Konto-Standard festgelegt werden.'
    },
    'All your To-Do lists have rooms specific to a property so cannot be made an account default. Create a To-Do list with no property specific rooms to set an account default.': {
        pt: 'Todas as suas listas de tarefas têm quartos específicos para uma propriedade, então não podem ser definidas como padrão da conta. Crie uma lista de tarefas sem quartos específicos de propriedade para definir um padrão da conta.',
        es: 'Todas sus listas de tareas tienen habitaciones específicas para una propiedad, por lo que no se pueden establecer como predeterminadas de cuenta. Cree una lista de tareas sin habitaciones específicas de propiedad para establecer un valor predeterminado de cuenta.',
        ru: 'Все ваши списки задач имеют комнаты, специфичные для недвижимости, поэтому не могут быть установлены как учетная запись по умолчанию. Создайте список задач без комнат, специфичных для недвижимости, чтобы установить учетную запись по умолчанию.',
        fr: 'Toutes vos listes de tâches ont des pièces spécifiques à une propriété et ne peuvent donc pas être définies comme compte par défaut. Créez une liste de tâches sans pièces spécifiques à la propriété pour définir un compte par défaut.',
        de: 'Alle Ihre Aufgabenlisten haben immobilienspezifische Räume und können daher nicht als Konto-Standard festgelegt werden. Erstellen Sie eine Aufgabenliste ohne immobilienspezifische Räume, um einen Konto-Standard festzulegen.'
    },
    'Pro service defaults are in beta. If you want to use defaults for a service unique to your pro or have Concierge add To-Do lists for you please': {
        pt: 'Os padrões de serviço profissional estão em beta. Se você deseja usar padrões para um serviço exclusivo do seu profissional ou ter o Concierge adicionar listas de tarefas para você, por favor',
        es: 'Los valores predeterminados del servicio profesional están en versión beta. Si desea usar valores predeterminados para un servicio único para su profesional o que Concierge agregue listas de tareas por usted, por favor',
        ru: 'Настройки услуг профессионалов находятся в бета-версии. Если вы хотите использовать настройки по умолчанию для уникальной услуги вашего профессионала или чтобы Консьерж добавил для вас списки задач, пожалуйста',
        fr: 'Les paramètres par défaut du service professionnel sont en version bêta. Si vous souhaitez utiliser des paramètres par défaut pour un service unique à votre professionnel ou faire ajouter des listes de tâches par le Concierge, s\'il vous plaît',
        de: 'Die Standard-Einstellungen für professionelle Dienste befinden sich in der Beta-Phase. Wenn Sie Standards für einen für Ihren Profi einzigartigen Service verwenden möchten oder möchten, dass der Concierge Aufgabenlisten für Sie hinzufügt, bitte'
    },
    'No Default': {
        pt: 'Sem Padrão',
        es: 'Sin Predeterminado',
        ru: 'Нет По Умолчанию',
        fr: 'Pas de Défaut',
        de: 'Kein Standard'
    },
    'Successfully updated To-Do settings': {
        pt: 'Configurações de tarefas atualizadas com sucesso',
        es: 'Configuración de tareas actualizada exitosamente',
        ru: 'Настройки задач успешно обновлены',
        fr: 'Paramètres des tâches mis à jour avec succès',
        de: 'Aufgaben-Einstellungen erfolgreich aktualisiert'
    },

    //RemoteInspectionPage
    //ToDosRemoveRoomPage
    'Remove Room': {
        pt: 'Remover Quarto',
        es: 'Eliminar Habitación',
        ru: 'Удалить Комнату',
        fr: 'Supprimer la Pièce',
        de: 'Raum Entfernen'
    },
    'Mark as <u>Do Not Clean</u>': {
        pt: 'Marcar como <u>Não Limpar</u>',
        es: 'Marcar como <u>No Limpiar</u>',
        ru: 'Отметить как <u>Не Убирать</u>',
        fr: 'Marquer comme <u>Ne Pas Nettoyer</u>',
        de: 'Als <u>Nicht Reinigen</u> markieren'
    },
    'The Pro will see that you do not want them to clean this room. This will affect all To-Do list.': {
        pt: 'O profissional verá que você não quer que eles limpem este quarto. Isso afetará todas as listas de tarefas.',
        es: 'El profesional verá que no desea que limpien esta habitación. Esto afectará a todas las listas de tareas.',
        ru: 'Профессионал увидит, что вы не хотите, чтобы они убирали эту комнату. Это повлияет на все списки задач.',
        fr: 'Le professionnel verra que vous ne voulez pas qu\'ils nettoient cette pièce. Cela affectera toutes les listes de tâches.',
        de: 'Der Profi wird sehen, dass Sie nicht möchten, dass dieser Raum gereinigt wird. Dies wirkt sich auf alle Aufgabenlisten aus.'
    },
    'The Pro will see that you do not want them to clean this room. This only affects this To-Do list.': {
        pt: 'O profissional verá que você não quer que eles limpem este quarto. Isso afeta apenas esta lista de tarefas.',
        es: 'El profesional verá que no desea que limpien esta habitación. Esto solo afecta a esta lista de tareas.',
        ru: 'Профессионал увидит, что вы не хотите, чтобы они убирали эту комнату. Это влияет только на этот список задач.',
        fr: 'Le professionnel verra que vous ne voulez pas qu\'ils nettoient cette pièce. Cela n\'affecte que cette liste de tâches.',
        de: 'Der Profi wird sehen, dass Sie nicht möchten, dass dieser Raum gereinigt wird. Dies betrifft nur diese Aufgabenliste.'
    },
    'Mark as Do Not Clean?': {
        pt: 'Marcar como Não Limpar?',
        es: '¿Marcar como No Limpiar?',
        ru: 'Отметить как Не Убирать?',
        fr: 'Marquer comme Ne Pas Nettoyer?',
        de: 'Als Nicht Reinigen markieren?'
    },
    'This will delete the room from all To-Do lists. You will not be able to undo this action.': {
        pt: 'Isso excluirá o quarto de todas as listas de tarefas. Você não poderá desfazer esta ação.',
        es: 'Esto eliminará la habitación de todas las listas de tareas. No podrá deshacer esta acción.',
        ru: 'Это удалит комнату из всех списков задач. Вы не сможете отменить это действие.',
        fr: 'Cela supprimera la pièce de toutes les listes de tâches. Vous ne pourrez pas annuler cette action.',
        de: 'Dies löscht den Raum aus allen Aufgabenlisten. Sie können diese Aktion nicht rückgängig machen.'
    },

    //ToDosRenameListPage
    'Edit List': {
        pt: 'Editar Lista',
        es: 'Editar Lista',
        ru: 'Редактировать Список',
        fr: 'Modifier la Liste',
        de: 'Liste Bearbeiten'
    },
    'Note: This list is property specific so it cannot be used in other properties.': {
        pt: 'Nota: Esta lista é específica para propriedade, então não pode ser usada em outras propriedades.',
        es: 'Nota: Esta lista es específica de la propiedad, por lo que no se puede usar en otras propiedades.',
        ru: 'Примечание: Этот список привязан к объекту и не может использоваться для других объектов.',
        fr: 'Note : Cette liste est spécifique à la propriété et ne peut donc pas être utilisée dans d\'autres propriétés.',
        de: 'Hinweis: Diese Liste ist eigentumsgebunden und kann nicht in anderen Immobilien verwendet werden.'
    },
    'Note: This list is not property specific so can be used in other properties.': {
        pt: 'Nota: Esta lista não é específica para propriedade, então pode ser usada em outras propriedades.',
        es: 'Nota: Esta lista no es específica de la propiedad, por lo que se puede usar en otras propiedades.',
        ru: 'Примечание: Этот список не привязан к объекту и может использоваться для других объектов.',
        fr: 'Note : Cette liste n\'est pas spécifique à la propriété et peut donc être utilisée dans d\'autres propriétés.',
        de: 'Hinweis: Diese Liste ist nicht eigentumsgebunden und kann in anderen Immobilien verwendet werden.'
    },
    'Please enter the name of the list': {
        pt: 'Por favor, insira o nome da lista',
        es: 'Por favor, ingrese el nombre de la lista',
        ru: 'Пожалуйста, введите название списка',
        fr: 'Veuillez entrer le nom de la liste',
        de: 'Bitte geben Sie den Namen der Liste ein'
    },
    'Rename List': {
        pt: 'Renomear Lista',
        es: 'Renombrar Lista',
        ru: 'Переименовать Список',
        fr: 'Renommer la Liste',
        de: 'Liste Umbenennen'
    },
    'Delete List': {
        pt: 'Excluir Lista',
        es: 'Eliminar Lista',
        ru: 'Удалить Список',
        fr: 'Supprimer la Liste',
        de: 'Liste Löschen'
    },
    'Delete List?': {
        pt: 'Excluir Lista?',
        es: '¿Eliminar Lista?',
        ru: 'Удалить Список?',
        fr: 'Supprimer la Liste?',
        de: 'Liste Löschen?'
    },

    //ToDosRoomPage
    'Room Info': {
        pt: 'Informações do Quarto',
        es: 'Información de la Habitación',
        ru: 'Информация о Комнате',
        fr: 'Informations sur la Chambre',
        de: 'Zimmerinformationen'
    },
    'Floor Not Added': {
        pt: 'Andar Não Adicionado',
        es: 'Piso No Agregado',
        ru: 'Этаж Не Добавлен',
        fr: 'Étage Non Ajouté',
        de: 'Stockwerk Nicht Hinzugefügt'
    },
    'No To-Dos added in': {
        pt: 'Nenhuma Tarefa adicionada em',
        es: 'No hay Tareas agregadas en',
        ru: 'Нет добавленных задач в',
        fr: 'Aucune Tâche ajoutée dans',
        de: 'Keine Aufgaben hinzugefügt in'
    },
    '(notify if count < ': {
        pt: '(notificar se contagem < ',
        es: '(notificar si el conteo < ',
        ru: '(уведомить если количество < ',
        fr: '(notifier si le compte < ',
        de: '(benachrichtigen wenn Anzahl < '
    },
    'Custom Fields:': {
        pt: 'Campos Personalizados:',
        es: 'Campos Personalizados:',
        ru: 'Пользовательские Поля:',
        fr: 'Champs Personnalisés:',
        de: 'Benutzerdefinierte Felder:'
    },
    'Delete Card?': {
        pt: 'Excluir Cartão?',
        es: '¿Eliminar Tarjeta?',
        ru: 'Удалить Карточку?',
        fr: 'Supprimer la Carte?',
        de: 'Karte Löschen?'
    },
    'This will delete the card from all To-Do lists. You will not be able to undo this action.': {
        pt: 'Isso excluirá o cartão de todas as listas de Tarefas. Você não poderá desfazer esta ação.',
        es: 'Esto eliminará la tarjeta de todas las listas de Tareas. No podrás deshacer esta acción.',
        ru: 'Это удалит карточку из всех списков задач. Вы не сможете отменить это действие.',
        fr: 'Cela supprimera la carte de toutes les listes de Tâches. Vous ne pourrez pas annuler cette action.',
        de: 'Dies löscht die Karte aus allen Aufgabenlisten. Sie können diese Aktion nicht rückgängig machen.'
    },

    //ToDosSelectDefaultListPage
    'Select New Default List': {
        pt: 'Selecionar Nova Lista Padrão',
        es: 'Seleccionar Nueva Lista Predeterminada',
        ru: 'Выбрать Новый Список По Умолчанию',
        fr: 'Sélectionner Nouvelle Liste Par Défaut',
        de: 'Neue Standardliste Auswählen'
    },
    'To-Do list deleted.': {
        pt: 'Lista de Tarefas excluída.',
        es: 'Lista de Tareas eliminada.',
        ru: 'Список задач удален.',
        fr: 'Liste de Tâches supprimée.',
        de: 'Aufgabenliste gelöscht.'
    },
    'Please select a new default list.': {
        pt: 'Por favor, selecione uma nova lista padrão.',
        es: 'Por favor, seleccione una nueva lista predeterminada.',
        ru: 'Пожалуйста, выберите новый список по умолчанию.',
        fr: 'Veuillez sélectionner une nouvelle liste par défaut.',
        de: 'Bitte wählen Sie eine neue Standardliste aus.'
    },
    'Updates saved.': {
        pt: 'Atualizações salvas.',
        es: 'Actualizaciones guardadas.',
        ru: 'Обновления сохранены.',
        fr: 'Mises à jour enregistrées.',
        de: 'Aktualisierungen gespeichert.'
    },
    'Select the default list at this address.': {
        pt: 'Selecione a lista padrão neste endereço.',
        es: 'Seleccione la lista predeterminada en esta dirección.',
        ru: 'Выберите список по умолчанию по этому адресу.',
        fr: 'Sélectionnez la liste par défaut à cette adresse.',
        de: 'Wählen Sie die Standardliste für diese Adresse.'
    },
    'Set New Default List': {
        pt: 'Definir Nova Lista Padrão',
        es: 'Establecer Nueva Lista Predeterminada',
        ru: 'Установить Новый Список По Умолчанию',
        fr: 'Définir Nouvelle Liste Par Défaut',
        de: 'Neue Standardliste Festlegen'
    },
    'Don\'t use To-Dos by default': {
        pt: 'Não usar Tarefas por padrão',
        es: 'No usar Tareas por defecto',
        ru: 'Не использовать задачи по умолчанию',
        fr: 'Ne pas utiliser les Tâches par défaut',
        de: 'Aufgaben standardmäßig nicht verwenden'
    },
    'You are no longer using To-Dos by default at this address for future jobs.  You can still apply a list to a single job.': {
        pt: 'Você não está mais usando Tarefas por padrão neste endereço para trabalhos futuros. Você ainda pode aplicar uma lista a um único trabalho.',
        es: 'Ya no está usando Tareas por defecto en esta dirección para trabajos futuros. Aún puede aplicar una lista a un solo trabajo.',
        ru: 'Вы больше не используете задачи по умолчанию по этому адресу для будущих работ. Вы все еще можете применить список к отдельной работе.',
        fr: 'Vous n\'utilisez plus les Tâches par défaut à cette adresse pour les futurs travaux. Vous pouvez toujours appliquer une liste à un seul travail.',
        de: 'Sie verwenden keine Aufgaben mehr standardmäßig für zukünftige Aufträge an dieser Adresse. Sie können weiterhin eine Liste auf einen einzelnen Auftrag anwenden.'
    },
    'Default List Updated': {
        pt: 'Lista Padrão Atualizada',
        es: 'Lista Predeterminada Actualizada',
        ru: 'Список По Умолчанию Обновлен',
        fr: 'Liste Par Défaut Mise à Jour',
        de: 'Standardliste Aktualisiert'
    },
    'This list will automatically be used for all jobs at this address.': {
        pt: 'Esta lista será automaticamente usada para todos os trabalhos neste endereço.',
        es: 'Esta lista se utilizará automáticamente para todos los trabajos en esta dirección.',
        ru: 'Этот список будет автоматически использоваться для всех работ по этому адресу.',
        fr: 'Cette liste sera automatiquement utilisée pour tous les travaux à cette adresse.',
        de: 'Diese Liste wird automatisch für alle Aufträge an dieser Adresse verwendet.'
    },

    //UseToDosPage
    'TIDY To-Dos': {
        pt: 'Tarefas TIDY',
        es: 'Tareas TIDY',
        ru: 'Задачи TIDY',
        fr: 'Tâches TIDY',
        de: 'TIDY Aufgaben'
    },

    //UsersPage
    'Admins': {
        pt: 'Administradores',
        es: 'Administradores',
        ru: 'Администраторы',
        fr: 'Administrateurs',
        de: 'Administratoren'
    },
    'Admins can log into your account with their own credentials. As the account owner, you can revoke access at any time.': {
        pt: 'Os administradores podem fazer login em sua conta com suas próprias credenciais. Como proprietário da conta, você pode revogar o acesso a qualquer momento.',
        es: 'Los administradores pueden iniciar sesión en su cuenta con sus propias credenciales. Como propietario de la cuenta, puede revocar el acceso en cualquier momento.',
        ru: 'Администраторы могут входить в вашу учетную запись, используя свои собственные учетные данные. Как владелец учетной записи, вы можете отозвать доступ в любое время.',
        fr: 'Les administrateurs peuvent se connecter à votre compte avec leurs propres identifiants. En tant que propriétaire du compte, vous pouvez révoquer l\'accès à tout moment.',
        de: 'Administratoren können sich mit ihren eigenen Anmeldedaten in Ihr Konto einloggen. Als Kontoinhaber können Sie den Zugriff jederzeit widerrufen.'
    },
    'Users can log into your account with their own credentials and see the properties you assign to them. As the account owner, you can revoke access at any time.': {
        pt: 'Os usuários podem fazer login em sua conta com suas próprias credenciais e ver as propriedades que você atribui a eles. Como proprietário da conta, você pode revogar o acesso a qualquer momento.',
        es: 'Los usuarios pueden iniciar sesión en su cuenta con sus propias credenciales y ver las propiedades que les asigne. Como propietario de la cuenta, puede revocar el acceso en cualquier momento.',
        ru: 'Пользователи могут входить в вашу учетную запись, используя свои собственные учетные данные, и видеть назначенные вами объекты. Как владелец учетной записи, вы можете отозвать доступ в любое время.',
        fr: 'Les utilisateurs peuvent se connecter à votre compte avec leurs propres identifiants et voir les propriétés que vous leur attribuez. En tant que propriétaire du compte, vous pouvez révoquer l\'accès à tout moment.',
        de: 'Benutzer können sich mit ihren eigenen Anmeldedaten in Ihr Konto einloggen und die ihnen zugewiesenen Eigenschaften sehen. Als Kontoinhaber können Sie den Zugriff jederzeit widerrufen.'
    },
    'Add Admin': {
        pt: 'Adicionar Administrador',
        es: 'Agregar Administrador',
        ru: 'Добавить Администратора',
        fr: 'Ajouter un Administrateur',
        de: 'Administrator Hinzufügen'
    },
    'Add User': {
        pt: 'Adicionar Usuário',
        es: 'Agregar Usuario',
        ru: 'Добавить Пользователя',
        fr: 'Ajouter un Utilisateur',
        de: 'Benutzer Hinzufügen'
    },
    'No Properties Assigned': {
        pt: 'Nenhuma Propriedade Atribuída',
        es: 'Sin Propiedades Asignadas',
        ru: 'Нет Назначенных Объектов',
        fr: 'Aucune Propriété Attribuée',
        de: 'Keine Eigenschaften Zugewiesen'
    },
    'Owner': {
        pt: 'Proprietário',
        es: 'Propietario',
        ru: 'Владелец',
        fr: 'Propriétaire',
        de: 'Eigentümer'
    },
    'Admin': {
        pt: 'Administrador',
        es: 'Administrador',
        ru: 'Администратор',
        fr: 'Administrateur',
        de: 'Administrator'
    },
    'Team Manager': {
        pt: 'Gerente de Equipe',
        es: 'Gerente de Equipo',
        ru: 'Менеджер Команды',
        fr: 'Chef d\'Équipe',
        de: 'Teamleiter'
    },

    //AddNewUserPage
    'Admin Added': {
        pt: 'Administrador Adicionado',
        es: 'Administrador Agregado',
        ru: 'Администратор Добавлен',
        fr: 'Administrateur Ajouté',
        de: 'Administrator Hinzugefügt'
    },
    'User Added': {
        pt: 'Usuário Adicionado',
        es: 'Usuario Agregado',
        ru: 'Пользователь Добавлен',
        fr: 'Utilisateur Ajouté',
        de: 'Benutzer Hinzugefügt'
    },
    'Please enter the first name of the user.': {
        pt: 'Por favor, insira o nome do usuário.',
        es: 'Por favor, ingrese el nombre del usuario.',
        ru: 'Пожалуйста, введите имя пользователя.',
        fr: 'Veuillez entrer le prénom de l\'utilisateur.',
        de: 'Bitte geben Sie den Vornamen des Benutzers ein.'
    },
    'Please enter the last name of the user.': {
        pt: 'Por favor, insira o sobrenome do usuário.',
        es: 'Por favor, ingrese el apellido del usuario.',
        ru: 'Пожалуйста, введите фамилию пользователя.',
        fr: 'Veuillez entrer le nom de famille de l\'utilisateur.',
        de: 'Bitte geben Sie den Nachnamen des Benutzers ein.'
    },
    'Share this password with the user and they can change it once they create their account.': {
        pt: 'Compartilhe esta senha com o usuário e ele poderá alterá-la depois de criar sua conta.',
        es: 'Comparta esta contraseña con el usuario y podrá cambiarla una vez que cree su cuenta.',
        ru: 'Поделитесь этим паролем с пользователем, и он сможет изменить его после создания учетной записи.',
        fr: 'Partagez ce mot de passe avec l\'utilisateur qui pourra le modifier une fois son compte créé.',
        de: 'Teilen Sie dieses Passwort mit dem Benutzer. Er kann es ändern, sobald er sein Konto erstellt hat.'
    },
    'User Type': {
        pt: 'Tipo de Usuário',
        es: 'Tipo de Usuario',
        ru: 'Тип Пользователя',
        fr: 'Type d\'Utilisateur',
        de: 'Benutzertyp'
    },
    'Account admins can see all users and properties. Team managers can only see users and properties on their team.': {
        pt: 'Os administradores da conta podem ver todos os usuários e propriedades. Os gerentes de equipe só podem ver usuários e propriedades em sua equipe.',
        es: 'Los administradores de la cuenta pueden ver todos los usuarios y propiedades. Los gerentes de equipo solo pueden ver usuarios y propiedades en su equipo.',
        ru: 'Администраторы учетной записи могут видеть всех пользователей и объекты. Менеджеры команды могут видеть только пользователей и объекты в своей команде.',
        fr: 'Les administrateurs du compte peuvent voir tous les utilisateurs et propriétés. Les chefs d\'équipe ne peuvent voir que les utilisateurs et les propriétés de leur équipe.',
        de: 'Kontoadministratoren können alle Benutzer und Eigenschaften sehen. Teamleiter können nur Benutzer und Eigenschaften in ihrem Team sehen.'
    },
    'Create Your First Team:': {
        pt: 'Crie Sua Primeira Equipe:',
        es: 'Cree Su Primer Equipo:',
        ru: 'Создайте Свою Первую Команду:',
        fr: 'Créez Votre Première Équipe:',
        de: 'Erstellen Sie Ihr Erstes Team:'
    },
    'Please enter the first name of the team.': {
        pt: 'Por favor, insira o nome da equipe.',
        es: 'Por favor, ingrese el nombre del equipo.',
        ru: 'Пожалуйста, введите название команды.',
        fr: 'Veuillez entrer le nom de l\'équipe.',
        de: 'Bitte geben Sie den Namen des Teams ein.'
    },
    'Why Add Admins?': {
        pt: 'Por que Adicionar Administradores?',
        es: '¿Por qué Agregar Administradores?',
        ru: 'Зачем Добавлять Администраторов?',
        fr: 'Pourquoi Ajouter des Administrateurs?',
        de: 'Warum Administratoren Hinzufügen?'
    },
    'Why Add Users?': {
        pt: 'Por que Adicionar Usuários?',
        es: '¿Por qué Agregar Usuarios?',
        ru: 'Зачем Добавлять Пользователей?',
        fr: 'Pourquoi Ajouter des Utilisateurs?',
        de: 'Warum Benutzer Hinzufügen?'
    },
    'Send your password to': {
        pt: 'Envie sua senha para',
        es: 'Envíe su contraseña a',
        ru: 'Отправьте свой пароль',
        fr: 'Envoyez votre mot de passe à',
        de: 'Senden Sie Ihr Passwort an'
    },
    '. Here is a message you can use:': {
        pt: '. Aqui está uma mensagem que você pode usar:',
        es: '. Aquí hay un mensaje que puede usar:',
        ru: '. Вот сообщение, которое вы можете использовать:',
        fr: '. Voici un message que vous pouvez utiliser:',
        de: '. Hier ist eine Nachricht, die Sie verwenden können:'
    },
    'I added you as an admin in TIDY.': {
        pt: 'Adicionei você como administrador no TIDY.',
        es: 'Te agregué como administrador en TIDY.',
        ru: 'Я добавил вас как администратора в TIDY.',
        fr: 'Je vous ai ajouté comme administrateur dans TIDY.',
        de: 'Ich habe Sie als Administrator in TIDY hinzugefügt.'
    },
    'I added you to my account in TIDY.': {
        pt: 'Adicionei você à minha conta no TIDY.',
        es: 'Te agregué a mi cuenta en TIDY.',
        ru: 'Я добавил вас в свою учетную запись в TIDY.',
        fr: 'Je vous ai ajouté à mon compte dans TIDY.',
        de: 'Ich habe Sie zu meinem Konto in TIDY hinzugefügt.'
    },
    'Click here to log in: https://app.tidy.com/#/login': {
        pt: 'Clique aqui para fazer login: https://app.tidy.com/#/login',
        es: 'Haga clic aquí para iniciar sesión: https://app.tidy.com/#/login',
        ru: 'Нажмите здесь, чтобы войти: https://app.tidy.com/#/login',
        fr: 'Cliquez ici pour vous connecter: https://app.tidy.com/#/login',
        de: 'Klicken Sie hier zum Anmelden: https://app.tidy.com/#/login'
    },
    'Use this password:': {
        pt: 'Use esta senha:',
        es: 'Use esta contraseña:',
        ru: 'Используйте этот пароль:',
        fr: 'Utilisez ce mot de passe:',
        de: 'Verwenden Sie dieses Passwort:'
    },
    'Copy Text': {
        pt: 'Copiar Texto',
        es: 'Copiar Texto',
        ru: 'Копировать Текст',
        fr: 'Copier le Texte',
        de: 'Text Kopieren'
    },
    ' Copied': {
        pt: ' Copiado',
        es: ' Copiado',
        ru: ' Скопировано',
        fr: ' Copié',
        de: ' Kopiert'
    },
    'Account Admin': {
        pt: 'Administrador da Conta',
        es: 'Administrador de la Cuenta',
        ru: 'Администратор Аккаунта',
        fr: 'Administrateur du Compte',
        de: 'Kontoadministrator'
    },
    'Someone is already using this email. If this user wants to join your account instead, please contact the Concierge for help.': {
        pt: 'Alguém já está usando este email. Se este usuário quiser entrar em sua conta, entre em contato com o Concierge para obter ajuda.',
        es: 'Alguien ya está usando este correo electrónico. Si este usuario quiere unirse a su cuenta, comuníquese con el Concierge para obtener ayuda.',
        ru: 'Кто-то уже использует этот email. Если этот пользователь хочет присоединиться к вашей учетной записи, обратитесь за помощью к Консьержу.',
        fr: 'Quelqu\'un utilise déjà cet email. Si cet utilisateur souhaite plutôt rejoindre votre compte, veuillez contacter le Concierge pour obtenir de l\'aide.',
        de: 'Diese E-Mail-Adresse wird bereits verwendet. Wenn dieser Benutzer stattdessen Ihrem Konto beitreten möchte, wenden Sie sich bitte an den Concierge.'
    },
    'Shared Data': {
        pt: 'Dados Compartilhados',
        es: 'Datos Compartidos',
        ru: 'Общие Данные',
        fr: 'Données Partagées',
        de: 'Gemeinsame Daten'
    },
    'Assign properties to each user to let them see their job history, maintenance records, and more.': {
        pt: 'Atribua propriedades a cada usuário para permitir que eles vejam seu histórico de trabalho, registros de manutenção e mais.',
        es: 'Asigne propiedades a cada usuario para permitirles ver su historial de trabajo, registros de mantenimiento y más.',
        ru: 'Назначайте объекты каждому пользователю, чтобы они могли видеть свою историю работы, записи об обслуживании и многое другое.',
        fr: 'Attribuez des propriétés à chaque utilisateur pour leur permettre de voir leur historique de travail, leurs registres de maintenance et plus encore.',
        de: 'Weisen Sie jedem Benutzer Eigenschaften zu, damit sie ihre Arbeitshistorie, Wartungsaufzeichnungen und mehr sehen können.'
    },
    'Shared Inbox': {
        pt: 'Caixa de Entrada Compartilhada',
        es: 'Bandeja de Entrada Compartida',
        ru: 'Общий Почтовый Ящик',
        fr: 'Boîte de Réception Partagée',
        de: 'Gemeinsamer Posteingang'
    },
    'More easily send messages to pros as a group, with anyone able to come in and out of the conversation.': {
        pt: 'Envie mensagens para profissionais como grupo mais facilmente, com qualquer pessoa podendo entrar e sair da conversa.',
        es: 'Envíe mensajes a profesionales como grupo más fácilmente, con cualquier persona pudiendo entrar y salir de la conversación.',
        ru: 'Легче отправляйте сообщения профессионалам как группе, при этом любой может присоединиться к разговору и выйти из него.',
        fr: 'Envoyez plus facilement des messages aux professionnels en groupe, avec la possibilité pour chacun d\'entrer et sortir de la conversation.',
        de: 'Senden Sie Nachrichten einfacher an Profis als Gruppe, wobei jeder in das Gespräch ein- und aussteigen kann.'
    },
    'No Additional Cost': {
        pt: 'Sem Custo Adicional',
        es: 'Sin Costo Adicional',
        ru: 'Без Дополнительной Платы',
        fr: 'Sans Frais Supplémentaires',
        de: 'Keine Zusätzlichen Kosten'
    },
    'Add unlimited users to your account at no additional cost.': {
        pt: 'Adicione usuários ilimitados à sua conta sem custo adicional.',
        es: 'Agregue usuarios ilimitados a su cuenta sin costo adicional.',
        ru: 'Добавляйте неограниченное количество пользователей в свою учетную запись без дополнительной платы.',
        fr: 'Ajoutez des utilisateurs illimités à votre compte sans frais supplémentaires.',
        de: 'Fügen Sie Ihrem Konto unbegrenzt Benutzer ohne zusätzliche Kosten hinzu.'
    },

    //EditUserDetailsPage
    'Create your first team:': {
        pt: 'Crie sua primeira equipe:',
        es: 'Crea tu primer equipo:',
        ru: 'Создайте свою первую команду:',
        fr: 'Créez votre première équipe:',
        de: 'Erstellen Sie Ihr erstes Team:'
    },
    'Update Info': {
        pt: 'Atualizar Informações',
        es: 'Actualizar Información',
        ru: 'Обновить Информацию',
        fr: 'Mettre à Jour les Informations',
        de: 'Informationen Aktualisieren'
    },
    'Delete Account': {
        pt: 'Excluir Conta',
        es: 'Eliminar Cuenta',
        ru: 'Удалить Аккаунт',
        fr: 'Supprimer le Compte',
        de: 'Konto Löschen'
    },

    //UserPage
    'User Type:': {
        pt: 'Tipo de Usuário:',
        es: 'Tipo de Usuario:',
        ru: 'Тип Пользователя:',
        fr: 'Type d\'Utilisateur:',
        de: 'Benutzertyp:'
    },
    'This user can see all properties and users.': {
        pt: 'Este usuário pode ver todas as propriedades e usuários.',
        es: 'Este usuario puede ver todas las propiedades y usuarios.',
        ru: 'Этот пользователь может видеть все объекты и пользователей.',
        fr: 'Cet utilisateur peut voir toutes les propriétés et tous les utilisateurs.',
        de: 'Dieser Benutzer kann alle Eigenschaften und Benutzer sehen.'
    },
    'This user can only see properties and team members on their team.': {
        pt: 'Este usuário só pode ver propriedades e membros da equipe em sua equipe.',
        es: 'Este usuario solo puede ver propiedades y miembros del equipo en su equipo.',
        ru: 'Этот пользователь может видеть только объекты и членов команды в своей команде.',
        fr: 'Cet utilisateur ne peut voir que les propriétés et les membres de l\'équipe de son équipe.',
        de: 'Dieser Benutzer kann nur Eigenschaften und Teammitglieder in seinem Team sehen.'
    },
    'Not on any teams': {
        pt: 'Não está em nenhuma equipe',
        es: 'No está en ningún equipo',
        ru: 'Не состоит ни в одной команде',
        fr: 'N\'appartient à aucune équipe',
        de: 'In keinem Team'
    },
    'No properties assigned': {
        pt: 'Nenhuma propriedade atribuída',
        es: 'No hay propiedades asignadas',
        ru: 'Нет назначенных объектов',
        fr: 'Aucune propriété assignée',
        de: 'Keine Eigenschaften zugewiesen'
    },
    'Delete User': {
        pt: 'Excluir Usuário',
        es: 'Eliminar Usuario',
        ru: 'Удалить Пользователя',
        fr: 'Supprimer l\'Utilisateur',
        de: 'Benutzer Löschen'
    },
    'Remove From Team?': {
        pt: 'Remover da Equipe?',
        es: '¿Eliminar del Equipo?',
        ru: 'Удалить из Команды?',
        fr: 'Retirer de l\'Équipe?',
        de: 'Vom Team Entfernen?'
    },
    'Removing a team manager from all teams will change their user type to admin. This means they will see all properties and users.': {
        pt: 'Remover um gerente de equipe de todas as equipes mudará seu tipo de usuário para administrador. Isso significa que eles verão todas as propriedades e usuários.',
        es: 'Eliminar un gerente de equipo de todos los equipos cambiará su tipo de usuario a administrador. Esto significa que verán todas las propiedades y usuarios.',
        ru: 'Удаление менеджера команды из всех команд изменит их тип пользователя на администратора. Это означает, что они будут видеть все объекты и пользователей.',
        fr: 'La suppression d\'un gestionnaire d\'équipe de toutes les équipes changera son type d\'utilisateur en administrateur. Cela signifie qu\'il verra toutes les propriétés et tous les utilisateurs.',
        de: 'Wenn ein Teammanager aus allen Teams entfernt wird, ändert sich sein Benutzertyp in Administrator. Das bedeutet, dass er alle Eigenschaften und Benutzer sehen wird.'
    },
    'Delete User?': {
        pt: 'Excluir Usuário?',
        es: '¿Eliminar Usuario?',
        ru: 'Удалить Пользователя?',
        fr: 'Supprimer l\'Utilisateur?',
        de: 'Benutzer Löschen?'
    },

    //VendorCompliancePage
    'Add your coverage requirements': {
        pt: 'Adicione seus requisitos de cobertura',
        es: 'Agregue sus requisitos de cobertura',
        ru: 'Добавьте требования к покрытию',
        fr: 'Ajoutez vos exigences de couverture',
        de: 'Fügen Sie Ihre Deckungsanforderungen hinzu'
    },
    'Certs/COIs/ACORD 25s. We also help you manage other vendor requirements (W9s, Attestations, and other contracts).': {
        pt: 'Certificados/COIs/ACORD 25s. Também ajudamos você a gerenciar outros requisitos de fornecedores (W9s, Atestados e outros contratos).',
        es: 'Certificados/COIs/ACORD 25s. También lo ayudamos a administrar otros requisitos de proveedores (W9s, Certificaciones y otros contratos).',
        ru: 'Сертификаты/COIs/ACORD 25s. Мы также помогаем управлять другими требованиями к поставщикам (W9s, Аттестации и другие контракты).',
        fr: 'Certificats/COIs/ACORD 25s. Nous vous aidons également à gérer d\'autres exigences des fournisseurs (W9s, Attestations et autres contrats).',
        de: 'Zertifikate/COIs/ACORD 25s. Wir helfen Ihnen auch bei der Verwaltung anderer Lieferantenanforderungen (W9s, Bescheinigungen und andere Verträge).'
    },
    'We automatically check coverage': {
        pt: 'Verificamos a cobertura automaticamente',
        es: 'Verificamos la cobertura automáticamente',
        ru: 'Мы автоматически проверяем покрытие',
        fr: 'Nous vérifions automatiquement la couverture',
        de: 'Wir überprüfen die Deckung automatisch'
    },
    'We automatically notify vendors of your requirements, collect proof, and alert you of any issues.': {
        pt: 'Notificamos automaticamente os fornecedores sobre seus requisitos, coletamos provas e alertamos sobre quaisquer problemas.',
        es: 'Notificamos automáticamente a los proveedores sobre sus requisitos, recopilamos pruebas y le alertamos sobre cualquier problema.',
        ru: 'Мы автоматически уведомляем поставщиков о ваших требованиях, собираем доказательства и предупреждаем о любых проблемах.',
        fr: 'Nous informons automatiquement les fournisseurs de vos exigences, collectons des preuves et vous alertons en cas de problème.',
        de: 'Wir benachrichtigen Lieferanten automatisch über Ihre Anforderungen, sammeln Nachweise und informieren Sie über Probleme.'
    },
    'It\'s free': {
        pt: 'É grátis',
        es: 'Es gratis',
        ru: 'Это бесплатно',
        fr: 'C\'est gratuit',
        de: 'Es ist kostenlos'
    },
    'It is just one of the ways we help keep your property clean and maintained.': {
        pt: 'É apenas uma das maneiras que ajudamos a manter sua propriedade limpa e conservada.',
        es: 'Es solo una de las formas en que ayudamos a mantener su propiedad limpia y mantenida.',
        ru: 'Это лишь один из способов, которым мы помогаем содержать вашу собственность в чистоте и порядке.',
        fr: 'C\'est juste l\'une des façons dont nous aidons à garder votre propriété propre et entretenue.',
        de: 'Das ist nur eine der Möglichkeiten, wie wir Ihr Eigentum sauber und gepflegt halten.'
    },
    'Request to Get Access': {
        pt: 'Solicitar Acesso',
        es: 'Solicitar Acceso',
        ru: 'Запросить Доступ',
        fr: 'Demander l\'Accès',
        de: 'Zugang Anfordern'
    },
    'What type of requirements do you want to implement?': {
        pt: 'Que tipo de requisitos você deseja implementar?',
        es: '¿Qué tipo de requisitos desea implementar?',
        ru: 'Какие требования вы хотите внедрить?',
        fr: 'Quel type d\'exigences souhaitez-vous mettre en œuvre?',
        de: 'Welche Art von Anforderungen möchten Sie implementieren?'
    },
    'Any questions about this feature?': {
        pt: 'Alguma dúvida sobre este recurso?',
        es: '¿Alguna pregunta sobre esta función?',
        ru: 'Есть вопросы об этой функции?',
        fr: 'Des questions sur cette fonctionnalité?',
        de: 'Fragen zu dieser Funktion?'
    },
    'Request Access': {
        pt: 'Solicitar Acesso',
        es: 'Solicitar Acceso',
        ru: 'Запросить Доступ',
        fr: 'Demander l\'Accès',
        de: 'Zugang Anfordern'
    },
    'Insurance': {
        pt: 'Seguro',
        es: 'Seguro',
        ru: 'Страхование',
        fr: 'Assurance',
        de: 'Versicherung'
    },
    'Training/Certification': {
        pt: 'Treinamento/Certificação',
        es: 'Entrenamiento/Certificación',
        ru: 'Обучение/Сертификация',
        fr: 'Formation/Certification',
        de: 'Schulung/Zertifizierung'
    },
    'Background check': {
        pt: 'Verificação de antecedentes',
        es: 'Verificación de antecedentes',
        ru: 'Проверка биографии',
        fr: 'Vérification des antécédents',
        de: 'Hintergrundüberprüfung'
    },
    'Document signing (e.g. NDA)': {
        pt: 'Assinatura de documentos (ex: NDA)',
        es: 'Firma de documentos (ej: NDA)',
        ru: 'Подписание документов (например, NDA)',
        fr: 'Signature de documents (ex: NDA)',
        de: 'Dokumentenunterzeichnung (z.B. NDA)'
    },
    'Licenses': {
        pt: 'Licenças',
        es: 'Licencias',
        ru: 'Лицензии',
        fr: 'Licences',
        de: 'Lizenzen'
    },
    'TIDY Concierge will reach out in the next 1 business day to help set up vendor compliance with you.': {
        pt: 'O Concierge TIDY entrará em contato no próximo dia útil para ajudar a configurar a conformidade do fornecedor com você.',
        es: 'El Concierge de TIDY se comunicará con usted el siguiente día hábil para ayudarlo a configurar el cumplimiento del proveedor.',
        ru: 'Консьерж TIDY свяжется с вами в течение следующего рабочего дня, чтобы помочь настроить соответствие поставщика.',
        fr: 'Le Concierge TIDY vous contactera dans le prochain jour ouvrable pour vous aider à mettre en place la conformité des fournisseurs.',
        de: 'Der TIDY Concierge wird sich am nächsten Werktag bei Ihnen melden, um Ihnen bei der Einrichtung der Lieferanten-Compliance zu helfen.'
    },
    'You already requested to add this feature so our Concierge will reach out to you. Please reach out with any questions.': {
        pt: 'Você já solicitou a adição deste recurso, então nosso Concierge entrará em contato com você. Entre em contato se tiver alguma dúvida.',
        es: 'Ya solicitó agregar esta función, por lo que nuestro Concierge se comunicará con usted. Comuníquese si tiene alguna pregunta.',
        ru: 'Вы уже запросили добавление этой функции, поэтому наш Консьерж свяжется с вами. Пожалуйста, обращайтесь с любыми вопросами.',
        fr: 'Vous avez déjà demandé l\'ajout de cette fonctionnalité, notre Concierge vous contactera. N\'hésitez pas à nous contacter pour toute question.',
        de: 'Sie haben diese Funktion bereits angefordert, unser Concierge wird sich bei Ihnen melden. Bitte melden Sie sich bei Fragen.'
    },

    //WhyAddPrivateProsPage
    'No Private Pros Added': {
        pt: 'Nenhum Profissional Privado Adicionado',
        es: 'No Hay Profesionales Privados Agregados',
        ru: 'Частные специалисты не добавлены',
        fr: 'Aucun professionnel privé ajouté',
        de: 'Keine privaten Profis hinzugefügt'
    },
    'Please add a private pro to assign this job.': {
        pt: 'Adicione um profissional privado para atribuir este trabalho.',
        es: 'Agregue un profesional privado para asignar este trabajo.',
        ru: 'Пожалуйста, добавьте частного специалиста, чтобы назначить эту работу.',
        fr: 'Veuillez ajouter un professionnel privé pour attribuer ce travail.',
        de: 'Bitte fügen Sie einen privaten Profi hinzu, um diesen Auftrag zuzuweisen.'
    },
    'There\'s no action needed from them for you to assign them. If they choose, they can view and manage the job in their own app <i>TIDY for Pros</i>.': {
        pt: 'Não é necessária nenhuma ação deles para você atribuí-los. Se quiserem, podem visualizar e gerenciar o trabalho em seu próprio aplicativo <i>TIDY for Pros</i>.',
        es: 'No se necesita ninguna acción de su parte para asignarlos. Si lo desean, pueden ver y administrar el trabajo en su propia aplicación <i>TIDY for Pros</i>.',
        ru: 'От них не требуется никаких действий, чтобы вы могли назначить их. При желании они могут просматривать и управлять работой в своем собственном приложении <i>TIDY for Pros</i>.',
        fr: 'Aucune action n\'est nécessaire de leur part pour que vous puissiez les assigner. S\'ils le souhaitent, ils peuvent consulter et gérer le travail dans leur propre application <i>TIDY for Pros</i>.',
        de: 'Sie müssen nichts tun, damit Sie sie zuweisen können. Wenn sie möchten, können sie den Auftrag in ihrer eigenen App <i>TIDY for Pros</i> einsehen und verwalten.'
    },
    'Why Add Pros?': {
        pt: 'Por que adicionar profissionais?',
        es: '¿Por qué agregar profesionales?',
        ru: 'Зачем добавлять специалистов?',
        fr: 'Pourquoi ajouter des professionnels?',
        de: 'Warum Profis hinzufügen?'
    },
    'Manage Bookings': {
        pt: 'Gerenciar Reservas',
        es: 'Administrar Reservas',
        ru: 'Управление бронированиями',
        fr: 'Gérer les réservations',
        de: 'Buchungen verwalten'
    },
    'You and your Pro can reschedule, add To-Dos, notes, and more.': {
        pt: 'Você e seu profissional podem reagendar, adicionar tarefas, notas e mais.',
        es: 'Usted y su profesional pueden reprogramar, agregar tareas pendientes, notas y más.',
        ru: 'Вы и ваш специалист можете перепланировать, добавлять задачи, заметки и многое другое.',
        fr: 'Vous et votre professionnel pouvez replanifier, ajouter des tâches, des notes et plus encore.',
        de: 'Sie und Ihr Profi können Termine verschieben, To-Dos hinzufügen, Notizen machen und mehr.'
    },
    'Collect Payment': {
        pt: 'Receber Pagamento',
        es: 'Cobrar Pago',
        ru: 'Получение оплаты',
        fr: 'Collecter le paiement',
        de: 'Zahlung einziehen'
    },
    'Pros send invoices that you can easily pay in 1 tap.': {
        pt: 'Os profissionais enviam faturas que você pode pagar facilmente com 1 toque.',
        es: 'Los profesionales envían facturas que puede pagar fácilmente con 1 toque.',
        ru: 'Специалисты отправляют счета, которые вы можете легко оплатить одним нажатием.',
        fr: 'Les professionnels envoient des factures que vous pouvez facilement payer en 1 clic.',
        de: 'Profis senden Rechnungen, die Sie mit 1 Klick einfach bezahlen können.'
    },
    'Use Your Pros or New Pros': {
        pt: 'Use seus profissionais ou novos profissionais',
        es: 'Use sus profesionales o nuevos profesionales',
        ru: 'Используйте ваших специалистов или новых специалистов',
        fr: 'Utilisez vos professionnels ou de nouveaux professionnels',
        de: 'Nutzen Sie Ihre Profis oder neue Profis'
    },
    'Request a job and we\'ll contact Pros in order of the priority you set.': {
        pt: 'Solicite um trabalho e entraremos em contato com os profissionais na ordem de prioridade que você definir.',
        es: 'Solicite un trabajo y nos pondremos en contacto con los profesionales en el orden de prioridad que establezca.',
        ru: 'Запросите работу, и мы свяжемся со специалистами в порядке установленного вами приоритета.',
        fr: 'Demandez un travail et nous contacterons les professionnels dans l\'ordre de priorité que vous définissez.',
        de: 'Fordern Sie einen Auftrag an und wir kontaktieren die Profis in der von Ihnen festgelegten Prioritätsreihenfolge.'
    },
    'Easy & Free for Them': {
        pt: 'Fácil e gratuito para eles',
        es: 'Fácil y gratuito para ellos',
        ru: 'Легко и бесплатно для них',
        fr: 'Facile et gratuit pour eux',
        de: 'Einfach & kostenlos für sie'
    },
    'It takes just a few minutes for them to create an account and see your jobs.': {
        pt: 'Leva apenas alguns minutos para eles criarem uma conta e verem seus trabalhos.',
        es: 'Solo toma unos minutos para que creen una cuenta y vean sus trabajos.',
        ru: 'Им потребуется всего несколько минут, чтобы создать учетную запись и увидеть ваши задания.',
        fr: 'Il ne leur faut que quelques minutes pour créer un compte et voir vos travaux.',
        de: 'Es dauert nur wenige Minuten, bis sie ein Konto erstellt haben und Ihre Aufträge sehen können.'
    },

    //AddActionModal
    'Google Contacts': {
        pt: 'Contatos do Google',
        es: 'Contactos de Google',
        ru: 'Контакты Google',
        fr: 'Contacts Google',
        de: 'Google Kontakte'
    },
    'Event Name': {
        pt: 'Nome do Evento',
        es: 'Nombre del Evento',
        ru: 'Название события',
        fr: 'Nom de l\'événement',
        de: 'Ereignisname'
    },
    'Event Description': {
        pt: 'Descrição do Evento',
        es: 'Descripción del Evento',
        ru: 'Описание события',
        fr: 'Description de l\'événement',
        de: 'Ereignisbeschreibung'
    },
    'Parameter 1': {
        pt: 'Parâmetro 1',
        es: 'Parámetro 1',
        ru: 'Параметр 1',
        fr: 'Paramètre 1',
        de: 'Parameter 1'
    },
    'Description for Parameter 1': {
        pt: 'Descrição do Parâmetro 1',
        es: 'Descripción del Parámetro 1',
        ru: 'Описание параметра 1',
        fr: 'Description du paramètre 1',
        de: 'Beschreibung für Parameter 1'
    },
    'Parameter 2': {
        pt: 'Parâmetro 2',
        es: 'Parámetro 2',
        ru: 'Параметр 2',
        fr: 'Paramètre 2',
        de: 'Parameter 2'
    },
    'Description for Parameter 2': {
        pt: 'Descrição do Parâmetro 2',
        es: 'Descripción del Parámetro 2',
        ru: 'Описание параметра 2',
        fr: 'Description du paramètre 2',
        de: 'Beschreibung für Parameter 2'
    },

    //AutomationsSummaryPage
    'Custom Workflows': {
        pt: 'Fluxos de Trabalho Personalizados',
        es: 'Flujos de Trabajo Personalizados',
        ru: 'Пользовательские рабочие процессы',
        fr: 'Flux de travail personnalisés',
        de: 'Benutzerdefinierte Workflows'
    },
    'View Workflows': {
        pt: 'Ver Fluxos de Trabalho',
        es: 'Ver Flujos de Trabajo',
        ru: 'Просмотр рабочих процессов',
        fr: 'Voir les flux de travail',
        de: 'Workflows anzeigen'
    },
    'Workflow Run History': {
        pt: 'Histórico de Execução do Fluxo de Trabalho',
        es: 'Historial de Ejecución del Flujo de Trabajo',
        ru: 'История выполнения рабочего процесса',
        fr: 'Historique d\'exécution du flux de travail',
        de: 'Workflow-Ausführungsverlauf'
    },
    'Job Request Workflows': {
        pt: 'Fluxos de Trabalho de Solicitação de Trabalho',
        es: 'Flujos de Trabajo de Solicitud de Trabajo',
        ru: 'Рабочие процессы запроса работы',
        fr: 'Flux de travail des demandes d\'emploi',
        de: 'Job-Anfrage-Workflows'
    },
    'properties have automatic booking on': {
        pt: 'propriedades têm reserva automática ativada',
        es: 'propiedades tienen reserva automática activada',
        ru: 'объекты имеют автоматическое бронирование',
        fr: 'propriétés ont la réservation automatique activée',
        de: 'Immobilien haben automatische Buchung aktiviert'
    },
    'Automatic Notifications': {
        pt: 'Notificações Automáticas',
        es: 'Notificaciones Automáticas',
        ru: 'Автоматические уведомления',
        fr: 'Notifications automatiques',
        de: 'Automatische Benachrichtigungen'
    },
    'automatic notifications added': {
        pt: 'notificações automáticas adicionadas',
        es: 'notificaciones automáticas añadidas',
        ru: 'добавлены автоматические уведомления',
        fr: 'notifications automatiques ajoutées',
        de: 'automatische Benachrichtigungen hinzugefügt'
    },
    'How to Use Automations:': {
        pt: 'Como Usar Automações:',
        es: 'Cómo Usar las Automatizaciones:',
        ru: 'Как использовать автоматизации:',
        fr: 'Comment utiliser les automatisations:',
        de: 'Wie man Automatisierungen verwendet:'
    },
    'Automate with Software': {
        pt: 'Automatize com Software',
        es: 'Automatice con Software',
        ru: 'Автоматизация с помощью программного обеспечения',
        fr: 'Automatiser avec le logiciel',
        de: 'Mit Software automatisieren'
    },
    'Trigger workflows based on any event.': {
        pt: 'Acione fluxos de trabalho baseados em qualquer evento.',
        es: 'Active flujos de trabajo basados en cualquier evento.',
        ru: 'Запускайте рабочие процессы на основе любого события.',
        fr: 'Déclenchez des flux de travail basés sur n\'importe quel événement.',
        de: 'Lösen Sie Workflows basierend auf beliebigen Ereignissen aus.'
    },
    'Example: \"When a job is completed, prepare an invoice in a particular format.\"': {
        pt: 'Exemplo: \"Quando um trabalho é concluído, prepare uma fatura em um formato específico.\"',
        es: 'Ejemplo: \"Cuando se completa un trabajo, prepare una factura en un formato particular.\"',
        ru: 'Пример: \"Когда работа завершена, подготовьте счет в определенном формате.\"',
        fr: 'Exemple: \"Lorsqu\'un travail est terminé, préparez une facture dans un format particulier.\"',
        de: 'Beispiel: \"Wenn ein Auftrag abgeschlossen ist, erstellen Sie eine Rechnung in einem bestimmten Format.\"'
    },
    'Automate with Our Humans': {
        pt: 'Automatize com Nossos Humanos',
        es: 'Automatice con Nuestros Humanos',
        ru: 'Автоматизация с помощью наших сотрудников',
        fr: 'Automatiser avec nos humains',
        de: 'Automatisieren Sie mit unseren Menschen'
    },
    'Our concierge can complete things for you. A \"human-in-the loop\" means we can solve workflows other\'s can\'t.': {
        pt: 'Nosso concierge pode completar coisas para você. Um \"humano no ciclo\" significa que podemos resolver fluxos de trabalho que outros não conseguem.',
        es: 'Nuestro concierge puede completar cosas por usted. Un \"humano en el ciclo\" significa que podemos resolver flujos de trabajo que otros no pueden.',
        ru: 'Наш консьерж может выполнить задачи за вас. \"Человек в цикле\" означает, что мы можем решать рабочие процессы, которые другие не могут.',
        fr: 'Notre concierge peut accomplir des tâches pour vous. Un \"humain dans la boucle\" signifie que nous pouvons résoudre des flux de travail que d\'autres ne peuvent pas.',
        de: 'Unser Concierge kann Dinge für Sie erledigen. Ein \"Mensch im Kreislauf\" bedeutet, dass wir Workflows lösen können, die andere nicht können.'
    },
    'Example: \"Review before/after photos to see if they match my criteria.\"': {
        pt: 'Exemplo: \"Revise fotos antes/depois para ver se elas correspondem aos meus critérios.\"',
        es: 'Ejemplo: \"Revise las fotos de antes/después para ver si coinciden con mis criterios.\"',
        ru: 'Пример: \"Просмотрите фотографии до/после, чтобы увидеть, соответствуют ли они моим критериям.\"',
        fr: 'Exemple: \"Examinez les photos avant/après pour voir si elles correspondent à mes critères.\"',
        de: 'Beispiel: \"Überprüfen Sie Vorher/Nachher-Fotos, um zu sehen, ob sie meinen Kriterien entsprechen.\"'
    },
    'Any Complex Workflow': {
        pt: 'Qualquer Fluxo de Trabalho Complexo',
        es: 'Cualquier Flujo de Trabajo Complejo',
        ru: 'Любой сложный рабочий процесс',
        fr: 'Tout flux de travail complexe',
        de: 'Jeder komplexe Workflow'
    },
    'Really, we can automate almost any cleaning and maintenance workflow.': {
        pt: 'Realmente, podemos automatizar quase qualquer fluxo de trabalho de limpeza e manutenção.',
        es: 'Realmente, podemos automatizar casi cualquier flujo de trabajo de limpieza y mantenimiento.',
        ru: 'Действительно, мы можем автоматизировать практически любой рабочий процесс по уборке и обслуживанию.',
        fr: 'Vraiment, nous pouvons automatiser presque tous les flux de travail de nettoyage et d\'entretien.',
        de: 'Wirklich, wir können fast jeden Reinigungs- und Wartungsworkflow automatisieren.'
    },
    'Example: \"Schedule inspection, then based on the result of the inspection book a series of jobs to resolve the issues in the inspection and wrap it up with a cleaning.\"': {
        pt: 'Exemplo: \"Agende uma inspeção e, com base no resultado, programe uma série de trabalhos para resolver os problemas encontrados na inspeção e finalize com uma limpeza.\"',
        es: 'Ejemplo: \"Programe una inspección, luego, según el resultado de la inspección, reserve una serie de trabajos para resolver los problemas de la inspección y termine con una limpieza.\"',
        ru: 'Пример: \"Запланируйте проверку, затем на основе результатов проверки назначьте серию работ для решения выявленных проблем и завершите уборкой.\"',
        fr: 'Exemple: \"Planifiez une inspection, puis en fonction du résultat de l\'inspection, réservez une série de travaux pour résoudre les problèmes de l\'inspection et terminez par un nettoyage.\"',
        de: 'Beispiel: \"Planen Sie eine Inspektion, dann buchen Sie basierend auf dem Ergebnis der Inspektion eine Reihe von Arbeiten, um die Probleme zu beheben und schließen Sie mit einer Reinigung ab.\"'
    },
    'Automate Turnovers': {
        pt: 'Automatize Rotatividade',
        es: 'Automatice Rotaciones',
        ru: 'Автоматизация смен',
        fr: 'Automatiser les rotations',
        de: 'Automatisieren Sie Übergaben'
    },
    'Automatically create job requests in between guests/tenants to get a turnover done.': {
        pt: 'Crie automaticamente solicitações de trabalho entre hóspedes/inquilinos para realizar uma rotatividade.',
        es: 'Cree automáticamente solicitudes de trabajo entre huéspedes/inquilinos para realizar una rotación.',
        ru: 'Автоматически создавайте заявки на работу между гостями/арендаторами для выполнения смены.',
        fr: 'Créez automatiquement des demandes de travail entre les invités/locataires pour effectuer une rotation.',
        de: 'Erstellen Sie automatisch Arbeitsanfragen zwischen Gästen/Mietern, um eine Übergabe durchzuführen.'
    },
    'Example: Schedule a cleaning after a guest checks out before the next one checks in.': {
        pt: 'Exemplo: Agende uma limpeza após a saída de um hóspede antes da entrada do próximo.',
        es: 'Ejemplo: Programe una limpieza después de que un huésped se vaya antes de que llegue el siguiente.',
        ru: 'Пример: Запланируйте уборку после выезда гостя перед заездом следующего.',
        fr: 'Exemple: Planifiez un nettoyage après le départ d\'un invité avant l\'arrivée du suivant.',
        de: 'Beispiel: Planen Sie eine Reinigung nach dem Auschecken eines Gastes, bevor der nächste eincheckt.'
    },
    'Get Notified': {
        pt: 'Receba Notificações',
        es: 'Reciba Notificaciones',
        ru: 'Получайте уведомления',
        fr: 'Soyez notifié',
        de: 'Werden Sie benachrichtigt'
    },
    'Get notifications based on events in TIDY.': {
        pt: 'Receba notificações baseadas em eventos no TIDY.',
        es: 'Reciba notificaciones basadas en eventos en TIDY.',
        ru: 'Получайте уведомления на основе событий в TIDY.',
        fr: 'Recevez des notifications basées sur les événements dans TIDY.',
        de: 'Erhalten Sie Benachrichtigungen basierend auf Ereignissen in TIDY.'
    },
    'Example: Get notified by webhook when a job is complete.': {
        pt: 'Exemplo: Seja notificado por webhook quando um trabalho é concluído.',
        es: 'Exemplo: Reciba una notificación por webhook cuando se complete un trabajo.',
        ru: 'Пример: Получайте уведомления через webhook, когда работа завершена.',
        fr: 'Exemple: Soyez notifié par webhook lorsqu\'un travail est terminé.',
        de: 'Beispiel: Werden Sie per Webhook benachrichtigt, wenn ein Auftrag abgeschlossen ist.'
    },
    'Add Automation': {
        pt: 'Adicionar Automação',
        es: 'Agregar Automatización',
        ru: 'Добавить автоматизацию',
        fr: 'Ajouter une automatisation',
        de: 'Automatisierung hinzufügen'
    },
    'Total Workflows Triggered': {
        pt: 'Total de Fluxos de Trabalho Acionados',
        es: 'Total de Flujos de Trabajo Activados',
        ru: 'Всего запущено рабочих процессов',
        fr: 'Total des flux de travail déclenchés',
        de: 'Gesamte ausgelöste Workflows'
    },
    'Total Automated Tasks': {
        pt: 'Total de Tarefas Automatizadas',
        es: 'Total de Tareas Automatizadas',
        ru: 'Всего автоматизированных задач',
        fr: 'Total des tâches automatisées',
        de: 'Gesamte automatisierte Aufgaben'
    },
    'Total Concierge Actions': {
        pt: 'Total de Ações do Concierge',
        es: 'Total de Acciones del Concierge',
        ru: 'Всего действий консьержа',
        fr: 'Total des actions du concierge',
        de: 'Gesamte Concierge-Aktionen'
    },
    'Please publish the workflow to unpause it.': {
        pt: 'Por favor, publique o fluxo de trabalho para despausá-lo.',
        es: 'Por favor, publique el flujo de trabajo para reanudar.',
        ru: 'Пожалуйста, опубликуйте рабочий процесс, чтобы возобновить его.',
        fr: 'Veuillez publier le flux de travail pour le reprendre.',
        de: 'Bitte veröffentlichen Sie den Workflow, um ihn fortzusetzen.'
    },

    //EditWorkflowPage
    'Edit a field to save a new draft.': {
        pt: 'Edite um campo para salvar um novo rascunho.',
        es: 'Edite un campo para guardar un nuevo borrador.',
        ru: 'Отредактируйте поле, чтобы сохранить новый черновик.',
        fr: 'Modifiez un champ pour enregistrer un nouveau brouillon.',
        de: 'Bearbeiten Sie ein Feld, um einen neuen Entwurf zu speichern.'
    },
    'Edit a field to make a draft.': {
        pt: 'Edite um campo para criar um rascunho.',
        es: 'Edite un campo para crear un borrador.',
        ru: 'Отредактируйте поле, чтобы создать черновик.',
        fr: 'Modifiez un champ pour créer un brouillon.',
        de: 'Bearbeiten Sie ein Feld, um einen Entwurf zu erstellen.'
    },
    'Draft Saved.': {
        pt: 'Rascunho Salvo.',
        es: 'Borrador Guardado.',
        ru: 'Черновик сохранен.',
        fr: 'Brouillon Enregistré.',
        de: 'Entwurf Gespeichert.'
    },
    'Saving Draft...': {
        pt: 'Salvando Rascunho...',
        es: 'Guardando Borrador...',
        ru: 'Сохранение черновика...',
        fr: 'Enregistrement du Brouillon...',
        de: 'Entwurf wird gespeichert...'
    },
    'Workflow Title': {
        pt: 'Título do Fluxo de Trabalho',
        es: 'Título del Flujo de Trabajo',
        ru: 'Название рабочего процесса',
        fr: 'Titre du Flux de Travail',
        de: 'Workflow-Titel'
    },
    'Please enter a title.': {
        pt: 'Por favor, insira um título.',
        es: 'Por favor, ingrese un título.',
        ru: 'Пожалуйста, введите название.',
        fr: 'Veuillez entrer un titre.',
        de: 'Bitte geben Sie einen Titel ein.'
    },
    'Trigger': {
        pt: 'Gatilho',
        es: 'Disparador',
        ru: 'Триггер',
        fr: 'Déclencheur',
        de: 'Auslöser'
    },
    'Trigger Filters': {
        pt: 'Filtros de Gatilho',
        es: 'Filtros de Disparador',
        ru: 'Фильтры триггера',
        fr: 'Filtres de Déclencheur',
        de: 'Auslöser-Filter'
    },
    'Please enter a number': {
        pt: 'Por favor, insira um número',
        es: 'Por favor, ingrese un número',
        ru: 'Пожалуйста, введите число',
        fr: 'Veuillez entrer un nombre',
        de: 'Bitte geben Sie eine Nummer ein'
    },
    'Select Option': {
        pt: 'Selecionar Opção',
        es: 'Seleccionar Opción',
        ru: 'Выберите опцию',
        fr: 'Sélectionner une Option',
        de: 'Option auswählen'
    },
    'Search Triggers': {
        pt: 'Pesquisar Gatilhos',
        es: 'Buscar Disparadores',
        ru: 'Поиск триггеров',
        fr: 'Rechercher des Déclencheurs',
        de: 'Auslöser suchen'
    },
    'Select a trigger to add actions.': {
        pt: 'Selecione um gatilho para adicionar ações.',
        es: 'Seleccione un disparador para agregar acciones.',
        ru: 'Выберите триггер, чтобы добавить действия.',
        fr: 'Sélectionnez un déclencheur pour ajouter des actions.',
        de: 'Wählen Sie einen Auslöser, um Aktionen hinzuzufügen.'
    },
    'Action': {
        pt: 'Ação',
        es: 'Acción',
        ru: 'Действие',
        fr: 'Action',
        de: 'Aktion'
    },
    'Publish': {
        pt: 'Publicar',
        es: 'Publicar',
        ru: 'Опубликовать',
        fr: 'Publier',
        de: 'Veröffentlichen'
    },
    'Delete Workflow': {
        pt: 'Excluir Fluxo de Trabalho',
        es: 'Eliminar Flujo de Trabajo',
        ru: 'Удалить рабочий процесс',
        fr: 'Supprimer le Flux de Travail',
        de: 'Workflow löschen'
    },
    '+ Add Action': {
        pt: '+ Adicionar Ação',
        es: '+ Agregar Acción',
        ru: '+ Добавить действие',
        fr: '+ Ajouter une Action',
        de: '+ Aktion hinzufügen'
    },
    'This version has already been published. Make changes to publish a new version.': {
        pt: 'Esta versão já foi publicada. Faça alterações para publicar uma nova versão.',
        es: 'Esta versión ya ha sido publicada. Haga cambios para publicar una nueva versión.',
        ru: 'Эта версия уже опубликована. Внесите изменения, чтобы опубликовать новую версию.',
        fr: 'Cette version a déjà été publiée. Effectuez des modifications pour publier une nouvelle version.',
        de: 'Diese Version wurde bereits veröffentlicht. Nehmen Sie Änderungen vor, um eine neue Version zu veröffentlichen.'
    },
    'Please add a trigger and action to this workflow before publishing.': {
        pt: 'Por favor, adicione um gatilho e uma ação a este fluxo de trabalho antes de publicar.',
        es: 'Por favor, agregue un disparador y una acción a este flujo de trabajo antes de publicar.',
        ru: 'Пожалуйста, добавьте триггер и действие в этот рабочий процесс перед публикацией.',
        fr: 'Veuillez ajouter un déclencheur et une action à ce flux de travail avant de publier.',
        de: 'Bitte fügen Sie diesem Workflow einen Auslöser und eine Aktion hinzu, bevor Sie ihn veröffentlichen.'
    },
    'Request Trigger': {
        pt: 'Gatilho de Solicitação',
        es: 'Disparador de Solicitud',
        ru: 'Триггер запроса',
        fr: 'Déclencheur de Demande',
        de: 'Anforderungsauslöser'
    },
    'Trigger on all categories': {
        pt: 'Acionar em todas as categorias',
        es: 'Activar en todas las categorías',
        ru: 'Срабатывать во всех категориях',
        fr: 'Déclencher sur toutes les catégories',
        de: 'Bei allen Kategorien auslösen'
    },
    'Trigger on all properties': {
        pt: 'Acionar em todas as propriedades',
        es: 'Activar en todas las propiedades',
        ru: 'Срабатывать во всех объектах',
        fr: 'Déclencher sur toutes les propriétés',
        de: 'Bei allen Eigenschaften auslösen'
    },
    'Trigger on all services': {
        pt: 'Acionar em todos os serviços',
        es: 'Activar en todos los servicios',
        ru: 'Срабатывать во всех услугах',
        fr: 'Déclencher sur tous les services',
        de: 'Bei allen Diensten auslösen'
    },
    'Trigger on all issues': {
        pt: 'Acionar em todos os problemas',
        es: 'Activar en todos los problemas',
        ru: 'Срабатывать во всех проблемах',
        fr: 'Déclencher sur tous les problèmes',
        de: 'Bei allen Problemen auslösen'
    },
    'Trigger on all job states': {
        pt: 'Acionar em todos os estados de trabalho',
        es: 'Activar en todos los estados de trabajo',
        ru: 'Срабатывать во всех состояниях работы',
        fr: 'Déclencher sur tous les états de travail',
        de: 'Bei allen Auftragszuständen auslösen'
    },
    'No filter': {
        pt: 'Sem filtro',
        es: 'Sin filtro',
        ru: 'Без фильтра',
        fr: 'Pas de filtre',
        de: 'Kein Filter'
    },
    'Categories': {
        pt: 'Categorias',
        es: 'Categorías',
        ru: 'Категории',
        fr: 'Catégories',
        de: 'Kategorien'
    },
    'Addresses': {
        pt: 'Endereços',
        es: 'Direcciones',
        ru: 'Адреса',
        fr: 'Adresses',
        de: 'Adressen'
    },
    'Service Types': {
        pt: 'Tipos de Serviço',
        es: 'Tipos de Servicio',
        ru: 'Типы услуг',
        fr: 'Types de Service',
        de: 'Servicetypen'
    },
    'Issue Type': {
        pt: 'Tipo de Problema',
        es: 'Tipo de Problema',
        ru: 'Тип проблемы',
        fr: 'Type de Problème',
        de: 'Problemtyp'
    },
    'Job State': {
        pt: 'Estado do Trabalho',
        es: 'Estado del Trabajo',
        ru: 'Состояние работы',
        fr: 'État du Travail',
        de: 'Auftragsstatus'
    },
    'Assigned to Concierge?': {
        pt: 'Atribuído ao Concierge?',
        es: '¿Asignado al Concierge?',
        ru: 'Назначено консьержу?',
        fr: 'Assigné au Concierge?',
        de: 'Dem Concierge zugewiesen?'
    },
    'Trigger only on some properties': {
        pt: 'Acionar apenas em algumas propriedades',
        es: 'Activar solo en algunas propiedades',
        ru: 'Срабатывать только для некоторых объектов',
        fr: 'Déclencher uniquement sur certaines propriétés',
        de: 'Nur bei bestimmten Eigenschaften auslösen'
    },
    'Trigger on specific states': {
        pt: 'Acionar em estados específicos',
        es: 'Activar en estados específicos',
        ru: 'Срабатывать в определенных состояниях',
        fr: 'Déclencher sur des états spécifiques',
        de: 'Bei bestimmten Zuständen auslösen'
    },
    'Trigger only for concierge assigned or not': {
        pt: 'Acionar apenas para concierge atribuído ou não',
        es: 'Activar solo para concierge asignado o no',
        ru: 'Срабатывать только при назначении или отсутствии консьержа',
        fr: 'Déclencher uniquement pour concierge assigné ou non',
        de: 'Nur auslösen, wenn Concierge zugewiesen ist oder nicht'
    },
    'Delete Workflow?': {
        pt: 'Excluir Fluxo de Trabalho?',
        es: '¿Eliminar Flujo de Trabajo?',
        ru: 'Удалить рабочий процесс?',
        fr: 'Supprimer le Flux de Travail?',
        de: 'Workflow löschen?'
    },
    'Add Workflow': {
        pt: 'Adicionar Fluxo de Trabalho',
        es: 'Agregar Flujo de Trabajo',
        ru: 'Добавить рабочий процесс',
        fr: 'Ajouter un Flux de Travail',
        de: 'Workflow hinzufügen'
    },
    'Edit Workflow': {
        pt: 'Editar Fluxo de Trabalho',
        es: 'Editar Flujo de Trabajo',
        ru: 'Редактировать рабочий процесс',
        fr: 'Modifier le Flux de Travail',
        de: 'Workflow bearbeiten'
    },
    'These can narrow when a trigger fires.': {
        pt: 'Isso pode limitar quando um gatilho é acionado.',
        es: 'Esto puede limitar cuándo se activa un disparador.',
        ru: 'Это может ограничить, когда срабатывает триггер.',
        fr: 'Cela peut restreindre le moment où un déclencheur s\'active.',
        de: 'Dies kann einschränken, wann ein Auslöser ausgelöst wird.'
    },
    'Run this trigger on:': {
        pt: 'Executar este gatilho em:',
        es: 'Ejecutar este disparador en:',
        ru: 'Запустить этот триггер для:',
        fr: 'Exécuter ce déclencheur sur:',
        de: 'Diesen Auslöser ausführen bei:'
    },
    'Filter jobs completed by:': {
        pt: 'Filtrar trabalhos concluídos por:',
        es: 'Filtrar trabajos completados por:',
        ru: 'Фильтровать выполненные работы по:',
        fr: 'Filtrer les travaux terminés par:',
        de: 'Abgeschlossene Aufträge filtern nach:'
    },
    'Filter this trigger by:': {
        pt: 'Filtrar este gatilho por:',
        es: 'Filtrar este disparador por:',
        ru: 'Фильтровать этот триггер по:',
        fr: 'Filtrer ce déclencheur par:',
        de: 'Diesen Auslöser filtern nach:'
    },
    'Issue': {
        pt: 'Problema',
        es: 'Problema',
        ru: 'Проблема',
        fr: 'Problème',
        de: 'Problem'
    },
    'Edit Draft': {
        pt: 'Editar Rascunho',
        es: 'Editar Borrador',
        ru: 'Редактировать черновик',
        fr: 'Modifier le Brouillon',
        de: 'Entwurf bearbeiten'
    },

    //FilterNestedFormComponent
    'Switch to custom date': {
        pt: 'Mudar para data personalizada',
        es: 'Cambiar a fecha personalizada',
        ru: 'Переключиться на пользовательскую дату',
        fr: 'Passer à une date personnalisée',
        de: 'Zu benutzerdefiniertem Datum wechseln'
    },
    'And': {
        pt: 'E',
        es: 'Y',
        ru: 'И',
        fr: 'Et',
        de: 'Und'
    },
    'Add \'Or\' rule group': {
        pt: 'Adicionar grupo de regras \'Ou\'',
        es: 'Agregar grupo de reglas \'O\'',
        ru: 'Добавить группу правил \'Или\'',
        fr: 'Ajouter un groupe de règles \'Ou\'',
        de: '\'Oder\'-Regelgruppe hinzufügen'
    },
    'Enter Value...': {
        pt: 'Inserir valor...',
        es: 'Ingresar valor...',
        ru: 'Введите значение...',
        fr: 'Entrer une valeur...',
        de: 'Wert eingeben...'
    },
    'Yesterday': {
        pt: 'Ontem',
        es: 'Ayer',
        ru: 'Вчера',
        fr: 'Hier',
        de: 'Gestern'
    },
    'Tomorrow': {
        pt: 'Amanhã',
        es: 'Mañana',
        ru: 'Завтра',
        fr: 'Demain',
        de: 'Morgen'
    },
    'Choose Field': {
        pt: 'Escolher Campo',
        es: 'Elegir Campo',
        ru: 'Выбрать Поле',
        fr: 'Choisir le Champ',
        de: 'Feld auswählen'
    },
    'Choose Condition': {
        pt: 'Escolher Condição',
        es: 'Elegir Condición',
        ru: 'Выбрать Условие',
        fr: 'Choisir la Condition',
        de: 'Bedingung auswählen'
    },
    'Enter Value': {
        pt: 'Inserir Valor',
        es: 'Ingresar Valor',
        ru: 'Введите Значение',
        fr: 'Entrer une Valeur',
        de: 'Wert eingeben'
    },

    //WorkflowPage
    'Published Workflow': {
        pt: 'Fluxo de Trabalho Publicado',
        es: 'Flujo de Trabajo Publicado',
        ru: 'Опубликованный Рабочий Процесс',
        fr: 'Flux de Travail Publié',
        de: 'Veröffentlichter Workflow'
    },
    'You have a newer unpublished version.': {
        pt: 'Você tem uma versão mais recente não publicada.',
        es: 'Tienes una versión más reciente sin publicar.',
        ru: 'У вас есть более новая неопубликованная версия.',
        fr: 'Vous avez une version plus récente non publiée.',
        de: 'Sie haben eine neuere unveröffentlichte Version.'
    },

    //WorkflowHistoryPage
    'Workflow History': {
        pt: 'Histórico de Fluxo de Trabalho',
        es: 'Historial de Flujo de Trabajo',
        ru: 'История Рабочего Процесса',
        fr: 'Historique des Flux de Travail',
        de: 'Workflow-Historie'
    },
    'No workflows added yet. Add workflows to view the run history here.': {
        pt: 'Nenhum fluxo de trabalho adicionado ainda. Adicione fluxos de trabalho para ver o histórico de execução aqui.',
        es: 'Aún no se han agregado flujos de trabajo. Agregue flujos de trabajo para ver el historial de ejecución aquí.',
        ru: 'Рабочие процессы еще не добавлены. Добавьте рабочие процессы, чтобы просмотреть историю выполнения здесь.',
        fr: 'Aucun flux de travail n\'a encore été ajouté. Ajoutez des flux de travail pour voir l\'historique d\'exécution ici.',
        de: 'Noch keine Workflows hinzugefügt. Fügen Sie Workflows hinzu, um den Ausführungsverlauf hier anzuzeigen.'
    },
    'No history in this range.': {
        pt: 'Nenhum histórico neste intervalo.',
        es: 'No hay historial en este rango.',
        ru: 'Нет истории в этом диапазоне.',
        fr: 'Aucun historique dans cette plage.',
        de: 'Keine Historie in diesem Bereich.'
    },

    //WorkflowsPage
    'Workflows': {
        pt: 'Fluxos de Trabalho',
        es: 'Flujos de Trabajo',
        ru: 'Рабочие Процессы',
        fr: 'Flux de Travail',
        de: 'Workflows'
    },
    'No Workflows Added': {
        pt: 'Nenhum Fluxo de Trabalho Adicionado',
        es: 'No Hay Flujos de Trabajo Agregados',
        ru: 'Рабочие Процессы Не Добавлены',
        fr: 'Aucun Flux de Travail Ajouté',
        de: 'Keine Workflows Hinzugefügt'
    },
    'Templates': {
        pt: 'Modelos',
        es: 'Plantillas',
        ru: 'Шаблоны',
        fr: 'Modèles',
        de: 'Vorlagen'
    },
    'Trigger:': {
        pt: 'Gatilho:',
        es: 'Disparador:',
        ru: 'Триггер:',
        fr: 'Déclencheur:',
        de: 'Auslöser:'
    },
    'Actions:': {
        pt: 'Ações:',
        es: 'Acciones:',
        ru: 'Действия:',
        fr: 'Actions:',
        de: 'Aktionen:'
    },
    'Please Publish Workflow to Unpause It': {
        pt: 'Por favor, Publique o Fluxo de Trabalho para Despausá-lo',
        es: 'Por favor, Publique el Flujo de Trabajo para Desactivar la Pausa',
        ru: 'Пожалуйста, Опубликуйте Рабочий Процесс, чтобы Снять с Паузы',
        fr: 'Veuillez Publier le Flux de Travail pour le Réactiver',
        de: 'Bitte Veröffentlichen Sie den Workflow, um ihn zu Aktivieren'
    },
    'In order to unpause a workflow you will need to publish it first. To do this, click the workflow then click \"Publish\" at the bottom of the page.': {
        pt: 'Para despausar um fluxo de trabalho, você precisará publicá-lo primeiro. Para fazer isso, clique no fluxo de trabalho e depois clique em \"Publicar\" na parte inferior da página.',
        es: 'Para desactivar la pausa de un flujo de trabajo, primero deberá publicarlo. Para hacer esto, haga clic en el flujo de trabajo y luego haga clic en \"Publicar\" en la parte inferior de la página.',
        ru: 'Чтобы снять с паузы рабочий процесс, вам нужно сначала опубликовать его. Для этого нажмите на рабочий процесс, затем нажмите \"Опубликовать\" внизу страницы.',
        fr: 'Pour réactiver un flux de travail, vous devrez d\'abord le publier. Pour ce faire, cliquez sur le flux de travail puis cliquez sur \"Publier\" en bas de la page.',
        de: 'Um einen Workflow zu aktivieren, müssen Sie ihn zuerst veröffentlichen. Klicken Sie dazu auf den Workflow und dann auf \"Veröffentlichen\" am unteren Rand der Seite.'
    },

    //IntegrationAuditLogsPage
    'Audit Logs': {
        pt: 'Registros de Auditoria',
        es: 'Registros de Auditoría',
        ru: 'Журналы Аудита',
        fr: 'Journaux d\'Audit',
        de: 'Audit-Protokolle'
    },
    'Audit logs for all integrations in your account.': {
        pt: 'Registros de auditoria para todas as integrações em sua conta.',
        es: 'Registros de auditoría para todas las integraciones en su cuenta.',
        ru: 'Журналы аудита для всех интеграций в вашей учетной записи.',
        fr: 'Journaux d\'audit pour toutes les intégrations de votre compte.',
        de: 'Audit-Protokolle für alle Integrationen in Ihrem Konto.'
    },

    //NOT TRACKING PAGES AFTER THIS POINT, JUST ADDING NEW TRANSLATIONS AS NEEDED
    'Next Check In:': {
        pt: 'Próximo Check-in:',
        es: 'Próximo Check-in:',
        ru: 'Следующая регистрация:',
        fr: 'Prochain Check-in:',
        de: 'Nächster Check-in:'
    },
    'Next Job:': {
        pt: 'Próximo Trabalho:',
        es: 'Próximo Trabajo:',
        ru: 'Следующая Работа:',
        fr: 'Prochain Travail:',
        de: 'Nächster Auftrag:'
    },
    'Proposed Other Times:': {
        pt: 'Outros Horários Propostos:',
        es: 'Otros Horarios Propuestos:',
        ru: 'Предложенное Другое Время:',
        fr: 'Autres Horaires Proposés:',
        de: 'Vorgeschlagene Andere Zeiten:'
    },

    'Get it Done Right': {
        pt: 'Faça Direito',
        es: 'Hazlo Bien',
        ru: 'Сделай Правильно',
        fr: 'Faites-le Bien',
        de: 'Machen Sie es Richtig'
    },
    'Create a digital To-Do List and share it with any Pro for free!': {
        pt: 'Crie uma Lista Digital de Tarefas e compartilhe com qualquer Profissional gratuitamente!',
        es: 'Cree una Lista Digital de Tareas y compártala con cualquier Profesional gratis!',
        ru: 'Создайте цифровой список дел и поделитесь им с любым профессионалом бесплатно!',
        fr: 'Créez une Liste de Tâches numérique et partagez-la avec n\'importe quel Pro gratuitement!',
        de: 'Erstellen Sie eine digitale To-Do-Liste und teilen Sie sie kostenlos mit jedem Profi!'
    },
    'Kitchen': {
        pt: 'Cozinha',
        es: 'Cocina',
        ru: 'Кухня',
        fr: 'Cuisine',
        de: 'Küche'
    },
    'Bathroom': {
        pt: 'Banheiro',
        es: 'Baño',
        ru: 'Ванная',
        fr: 'Salle de Bain',
        de: 'Badezimmer'
    },
    'Laundry Room': {
        pt: 'Lavanderia',
        es: 'Cuarto de Lavado',
        ru: 'Прачечная',
        fr: 'Buanderie',
        de: 'Waschküche'
    },
    'Create To-Do List in 1 Minute': {
        pt: 'Crie Lista de Tarefas em 1 Minuto',
        es: 'Crear Lista de Tareas en 1 Minuto',
        ru: 'Создайте Список Дел за 1 Минуту',
        fr: 'Créer une Liste de Tâches en 1 Minute',
        de: 'To-Do-Liste in 1 Minute Erstellen'
    },
    'Use Most Popular': {
        pt: 'Use os Mais Populares',
        es: 'Usar los Más Populares',
        ru: 'Использовать Самые Популярные',
        fr: 'Utiliser les Plus Populaires',
        de: 'Verwenden Sie die Beliebtesten'
    },
    'Guest Reservations': {
        pt: 'Reservas de Hóspedes',
        es: 'Reservas de Huéspedes',
        ru: 'Бронирования Гостей',
        fr: 'Réservations des Invités',
        de: 'Gästereservierungen'
    },
    'Smart Lock': {
        pt: 'Fechadura Inteligente',
        es: 'Cerradura Inteligente',
        ru: 'Умный Замок',
        fr: 'Serrure Intelligente',
        de: 'Smartes Schloss'
    },

    'Next Week': {
        pt: 'Próxima Semana',
        es: 'Próxima Semana',
        ru: 'Следующая Неделя',
        fr: 'Semaine Prochaine',
        de: 'Nächste Woche'
    },
    'Next Month': {
        pt: 'Próximo Mês',
        es: 'Próximo Mes',
        ru: 'Следующий Месяц',
        fr: 'Mois Prochain',
        de: 'Nächster Monat'
    },
    'In the Future': {
        pt: 'No Futuro',
        es: 'En el Futuro',
        ru: 'В Будущем',
        fr: 'Dans le Futur',
        de: 'In der Zukunft'
    },
    'In the Past': {
        pt: 'No Passado',
        es: 'En el Pasado',
        ru: 'В Прошлом',
        fr: 'Dans le Passé',
        de: 'In der Vergangenheit'
    },
    'Descending order': {
        pt: 'Ordem Decrescente',
        es: 'Orden Descendente',
        ru: 'По убыванию',
        fr: 'Ordre Décroissant',
        de: 'Absteigende Reihenfolge'
    },
    'Ascending order': {
        pt: 'Ordem Crescente',
        es: 'Orden Ascendente',
        ru: 'По возрастанию',
        fr: 'Ordre Croissant',
        de: 'Aufsteigende Reihenfolge'
    },
    'Job Service Type': {
        pt: 'Tipo de Serviço do Trabalho',
        es: 'Tipo de Servicio del Trabajo',
        ru: 'Тип Сервиса Работы',
        fr: 'Type de Service du Travail',
        de: 'Art des Arbeitsservice'
    },
    'Job Price': {
        pt: 'Preço do Trabalho',
        es: 'Precio del Trabajo',
        ru: 'Цена Работы',
        fr: 'Prix du Travail',
        de: 'Arbeitspreis'
    },
    'Job Status': {
        pt: 'Status do Trabalho',
        es: 'Estado del Trabajo',
        ru: 'Статус Работы',
        fr: 'Statut du Travail',
        de: 'Arbeitsstatus'
    },
    'Job ID': {
        pt: 'ID do Trabalho',
        es: 'ID del Trabajo',
        ru: 'ID Работы',
        fr: 'ID du Travail',
        de: 'Arbeits-ID'
    },
    'Address ID': {
        pt: 'ID do Endereço',
        es: 'ID de la Dirección',
        ru: 'ID Адреса',
        fr: 'ID de l\'Adresse',
        de: 'Adress-ID'
    },
    '(hidden)': {
        pt: '(oculto)',
        es: '(oculto)',
        ru: '(скрыто)',
        fr: '(caché)',
        de: '(versteckt)'
    },

    'Active Integrations': {
        pt: 'Integrações Ativas',
        es: 'Integraciones Activas',
        ru: 'Активные Интеграции',
        fr: 'Intégrations Actives',
        de: 'Aktive Integrationen'
    },
    'Show Discounts': {
        pt: 'Mostrar Descontos',
        es: 'Mostrar Descuentos',
        ru: 'Показать Скидки',
        fr: 'Afficher les Réductions',
        de: 'Rabatte Anzeigen'
    },
    'Featured': {
        pt: 'Destaque',
        es: 'Destacado',
        ru: 'Рекомендуемые',
        fr: 'En Vedette',
        de: 'Empfohlen'
    },
    'Current Subscription': {
        pt: 'Assinatura Atual',
        es: 'Suscripción Actual',
        ru: 'Текущая Подписка',
        fr: 'Abonnement Actuel',
        de: 'Aktuelle Abonnement'
    },
    'Address Name': {
        pt: 'Nome do Endereço',
        es: 'Nombre de la Dirección',
        ru: 'Название Адреса',
        fr: 'Nom de l\'Adresse',
        de: 'Adressname'
    },
    'City': {
        pt: 'Cidade',
        es: 'Ciudad',
        ru: 'Город',
        fr: 'Ville',
        de: 'Stadt'
    },
    'Canceled': {
        pt: 'Cancelado',
        es: 'Cancelado',
        ru: 'Отменено',
        fr: 'Annulé',
        de: 'Storniert'
    },
    'Request Cancelled': {
        pt: 'Solicitação Cancelada',
        es: 'Solicitud Cancelada',
        ru: 'Запрос Отменен',
        fr: 'Demande Annulée',
        de: 'Anfrage Storniert'
    },
    'Client Cancelled': {
        pt: 'Cliente Cancelou',
        es: 'Cliente Canceló',
        ru: 'Клиент Отменил',
        fr: 'Client a Annulé',
        de: 'Kunde hat Storniert'
    },
    'PROPERTIES': {
        pt: 'PROPRIEDADES',
        es: 'PROPIEDADES',
        ru: 'ОБЪЕКТЫ',
        fr: 'PROPRIÉTÉS',
        de: 'IMMOBILIEN'
    },
    'PROS': {
        pt: 'PROFISSIONAIS',
        es: 'PROFESIONALES',
        ru: 'СПЕЦИАЛИСТЫ',
        fr: 'PROFESSIONNELS',
        de: 'PROFIS'
    },
    'PAGES': {
        pt: 'PÁGINAS',
        es: 'PÁGINAS',
        ru: 'СТРАНИЦЫ',
        fr: 'PAGES',
        de: 'SEITEN'
    },
    'HELP ARTICLES': {
        pt: 'ARTIGOS DE AJUDA',
        es: 'ARTÍCULOS DE AYUDA',
        ru: 'СПРАВОЧНЫЕ СТАТЬИ',
        fr: 'ARTICLES D\'AIDE',
        de: 'HILFE-ARTIKEL'
    },
    'completed': {
        pt: 'concluído',
        es: 'completado',
        ru: 'завершено',
        fr: 'terminé',
        de: 'abgeschlossen'
    },
    'cancelled': {
        pt: 'cancelado',
        es: 'cancelado',
        ru: 'отменено',
        fr: 'annulé',
        de: 'storniert'
    },
    'canceled': {
        pt: 'cancelado',
        es: 'cancelado',
        ru: 'отменено',
        fr: 'annulé',
        de: 'storniert'
    },
    'last minute cancellation': {
        pt: 'cancelamento de última hora',
        es: 'cancelación de último minuto',
        ru: 'отмена в последнюю минуту',
        fr: 'annulation de dernière minute',
        de: 'kurzfristige Stornierung'
    },
    'your subscription': {
        pt: 'sua assinatura',
        es: 'su suscripción',
        ru: 'ваша подписка',
        fr: 'votre abonnement',
        de: 'Ihr Abonnement'
    },
    'Only past jobs that were billed will show in your job history. For billing history,': {
        pt: 'Apenas trabalhos passados que foram cobrados aparecerão no seu histórico. Para histórico de cobranças,',
        es: 'Solo los trabajos pasados que fueron facturados aparecerán en su historial. Para el historial de facturación,',
        ru: 'В истории работ отображаются только оплаченные прошлые работы. Для истории платежей,',
        fr: 'Seuls les travaux passés qui ont été facturés apparaîtront dans votre historique. Pour l\'historique de facturation,',
        de: 'Nur vergangene Aufträge, die abgerechnet wurden, werden in Ihrer Auftragshistorie angezeigt. Für die Abrechnungshistorie,'
    },
    'To': {
        pt: 'Para',
        es: 'Para',
        ru: 'К',
        fr: 'À',
        de: 'An'
    },
    'Copy Link': {
        pt: 'Copiar Link',
        es: 'Copiar Enlace',
        ru: 'Копировать Ссылку',
        fr: 'Copier le Lien',
        de: 'Link Kopieren'
    },
    'General': {
        pt: 'Geral',
        es: 'General',
        ru: 'Общее',
        fr: 'Général',
        de: 'Allgemein'
    },
    'Pest Report': {
        pt: 'Relatório de Pragas',
        es: 'Informe de Plagas',
        ru: 'Отчет о Вредителях',
        fr: 'Rapport sur les Nuisibles',
        de: 'Schädlingsbericht'
    },
    'Lost and Found': {
        pt: 'Achados e Perdidos',
        es: 'Objetos Perdidos',
        ru: 'Бюро Находок',
        fr: 'Objets Trouvés',
        de: 'Fundsachen'
    },
    'Lost And Found': {
        pt: 'Achados e Perdidos',
        es: 'Objetos Perdidos',
        ru: 'Бюро Находок',
        fr: 'Objets Trouvés',
        de: 'Fundsachen'
    },
    'Turnover': {
        pt: 'Rotatividade',
        es: 'Rotación',
        ru: 'Оборот',
        fr: 'Rotation',
        de: 'Wechsel'
    },
    'Person': {
        pt: 'Pessoa',
        es: 'Persona',
        ru: 'Человек',
        fr: 'Personne',
        de: 'Person'
    },
    'January': {
        pt: 'Janeiro',
        es: 'Enero',
        ru: 'Январь',
        fr: 'Janvier',
        de: 'Januar'
    },
    'February': {
        pt: 'Fevereiro',
        es: 'Febrero',
        ru: 'Февраль',
        fr: 'Février',
        de: 'Februar'
    },
    'March': {
        pt: 'Março',
        es: 'Marzo',
        ru: 'Март',
        fr: 'Mars',
        de: 'März'
    },
    'April': {
        pt: 'Abril',
        es: 'Abril',
        ru: 'Апрель',
        fr: 'Avril',
        de: 'April'
    },
    'May': {
        pt: 'Maio',
        es: 'Mayo',
        ru: 'Май',
        fr: 'Mai',
        de: 'Mai'
    },
    'June': {
        pt: 'Junho',
        es: 'Junio',
        ru: 'Июнь',
        fr: 'Juin',
        de: 'Juni'
    },
    'July': {
        pt: 'Julho',
        es: 'Julio',
        ru: 'Июль',
        fr: 'Juillet',
        de: 'Juli'
    },
    'August': {
        pt: 'Agosto',
        es: 'Agosto',
        ru: 'Август',
        fr: 'Août',
        de: 'August'
    },
    'September': {
        pt: 'Setembro',
        es: 'Septiembre',
        ru: 'Сентябрь',
        fr: 'Septembre',
        de: 'September'
    },
    'October': {
        pt: 'Outubro',
        es: 'Octubre',
        ru: 'Октябрь',
        fr: 'Octobre',
        de: 'Oktober'
    },
    'November': {
        pt: 'Novembro',
        es: 'Noviembre',
        ru: 'Ноябрь',
        fr: 'Novembre',
        de: 'November'
    },
    'December': {
        pt: 'Dezembro',
        es: 'Diciembre',
        ru: 'Декабрь',
        fr: 'Décembre',
        de: 'Dezember'
    },
    'Assigned Pro': {
        pt: 'Profissional Atribuído',
        es: 'Profesional Asignado',
        ru: 'Назначенный Специалист',
        fr: 'Professionnel Assigné',
        de: 'Zugewiesener Profi'
    },

    'You requested': {
        pt: 'Você solicitou',
        es: 'Has solicitado',
        ru: 'Вы запросили',
        fr: 'Vous avez demandé',
        de: 'Sie haben angefordert'
    },
    'for a': {
        pt: 'para um',
        es: 'para un',
        ru: 'для',
        fr: 'pour un',
        de: 'für eine'
    },
    'during one of these times:': {
        pt: 'durante um destes horários:',
        es: 'durante uno de estos horarios:',
        ru: 'в одно из этих времен:',
        fr: 'pendant l\'un de ces horaires:',
        de: 'während einer dieser Zeiten:'
    },
    'Quick clean, best for focused needs or small homes.': {
        pt: 'Limpeza rápida, melhor para necessidades específicas ou casas pequenas.',
        es: 'Limpieza rápida, mejor para necesidades específicas o casas pequeñas.',
        ru: 'Быстрая уборка, лучше всего подходит для целевых потребностей или небольших домов.',
        fr: 'Nettoyage rapide, idéal pour les besoins ciblés ou les petites maisons.',
        de: 'Schnelle Reinigung, am besten für gezielte Bedürfnisse oder kleine Häuser.'
    },
    'Long enough for cleaning most homes, with 2 Pros for redundancy.': {
        pt: 'Tempo suficiente para limpar a maioria das casas, com 2 profissionais para redundância.',
        es: 'Tiempo suficiente para limpiar la mayoría de las casas, con 2 profesionales para redundancia.',
        ru: 'Достаточно времени для уборки большинства домов, с 2 специалистами для надежности.',
        fr: 'Assez long pour nettoyer la plupart des maisons, avec 2 professionnels pour la redondance.',
        de: 'Lang genug für die Reinigung der meisten Häuser, mit 2 Profis für Redundanz.'
    },
    'Most popular cleaning, good for general cleanings of most homes.': {
        pt: 'Limpeza mais popular, boa para limpezas gerais da maioria das casas.',
        es: 'Limpieza más popular, buena para limpiezas generales de la mayoría de las casas.',
        ru: 'Самая популярная уборка, подходит для общей уборки большинства домов.',
        fr: 'Nettoyage le plus populaire, bon pour les nettoyages généraux de la plupart des maisons.',
        de: 'Beliebteste Reinigung, gut für allgemeine Reinigungen der meisten Häuser.'
    },
    'Long enough for deeper cleanings of most homes, with 2 Pros for redundancy.': {
        pt: 'Tempo suficiente para limpezas mais profundas da maioria das casas, com 2 profissionais para redundância.',
        es: 'Tiempo suficiente para limpiezas más profundas de la mayoría de las casas, con 2 profesionales para redundancia.',
        ru: 'Достаточно времени для более глубокой уборки большинства домов, с 2 специалистами для надежности.',
        fr: 'Assez long pour des nettoyages plus profonds de la plupart des maisons, avec 2 professionnels pour la redondance.',
        de: 'Lang genug für tiefere Reinigungen der meisten Häuser, mit 2 Profis für Redundanz.'
    },
    'Great for cleaning large homes or deep cleaning small homes.': {
        pt: 'Ótimo para limpar casas grandes ou fazer limpeza profunda em casas pequenas.',
        es: 'Excelente para limpiar casas grandes o hacer limpieza profunda en casas pequeñas.',
        ru: 'Отлично подходит для уборки больших домов или глубокой уборки маленьких домов.',
        fr: 'Idéal pour nettoyer les grandes maisons ou faire un nettoyage en profondeur des petites maisons.',
        de: 'Ideal für die Reinigung großer Häuser oder die Grundreinigung kleiner Häuser.'
    },
    'Long enough for a deep, detailed cleaning in most homes.': {
        pt: 'Tempo suficiente para uma limpeza profunda e detalhada na maioria das casas.',
        es: 'Tiempo suficiente para una limpieza profunda y detallada en la mayoría de las casas.',
        ru: 'Достаточно времени для глубокой, детальной уборки в большинстве домов.',
        fr: 'Assez long pour un nettoyage profond et détaillé dans la plupart des maisons.',
        de: 'Lang genug für eine gründliche, detaillierte Reinigung in den meisten Häusern.'
    },
    '1 Hour Cleaning (2 Pros)': {
        pt: '1 Hora de Limpeza (2 Profissionais)',
        es: '1 Hora de Limpieza (2 Profesionales)',
        ru: '1 Час Уборки (2 Специалиста)',
        fr: '1 Heure de Nettoyage (2 Professionnels)',
        de: '1 Stunde Reinigung (2 Profis)'
    },
    '1 hour cleaning (2 pros)': {
        pt: '1 hora de limpeza (2 profissionais)',
        es: '1 hora de limpieza (2 profesionales)',
        ru: '1 час уборки (2 специалиста)',
        fr: '1 heure de nettoyage (2 professionnels)',
        de: '1 stunde reinigung (2 profis)'
    },
    '2.5 Hour Cleaning (2 Pros)': {
        pt: '2,5 Horas de Limpeza (2 Profissionais)',
        es: '2,5 Horas de Limpieza (2 Profesionales)',
        ru: '2,5 Часа Уборки (2 Специалиста)',
        fr: '2,5 Heures de Nettoyage (2 Professionnels)',
        de: '2,5 Stunden Reinigung (2 Profis)'
    },
    '2.5 hour cleaning (2 pros)': {
        pt: '2,5 horas de limpeza (2 profissionais)',
        es: '2,5 horas de limpieza (2 profesionales)',
        ru: '2,5 часа уборки (2 специалиста)',
        fr: '2,5 heures de nettoyage (2 professionnels)',
        de: '2,5 stunden reinigung (2 profis)'
    },
    '4 Hour Cleaning (2 Pros)': {
        pt: '4 Horas de Limpeza (2 Profissionais)',
        es: '4 Horas de Limpieza (2 Profesionales)',
        ru: '4 Часа Уборки (2 Специалиста)',
        fr: '4 Heures de Nettoyage (2 Professionnels)',
        de: '4 Stunden Reinigung (2 Profis)'
    },
    '4 hour cleaning (2 pros)': {
        pt: '4 horas de limpeza (2 profissionais)',
        es: '4 horas de limpieza (2 profesionales)',
        ru: '4 часа уборки (2 специалиста)',
        fr: '4 heures de nettoyage (2 professionnels)',
        de: '4 stunden reinigung (2 profis)'
    },
    'Priced Later': {
        pt: 'Preço Posterior',
        es: 'Precio Posterior',
        ru: 'Цена Позже',
        fr: 'Prix Ultérieur',
        de: 'Preis Später'
    },
    'Hourly': {
        pt: 'Por Hora',
        es: 'Por Hora',
        ru: 'Почасовой',
        fr: 'Horaire',
        de: 'Stündlich'
    },
    '4 Hour Cleaning': {
        pt: '4 Horas de Limpeza',
        es: '4 Horas de Limpieza',
        ru: '4 Часа Уборки',
        fr: '4 Heures de Nettoyage',
        de: '4 Stunden Reinigung'
    },
    '4 hour cleaning': {
        pt: '4 horas de limpeza',
        es: '4 horas de limpieza',
        ru: '4 часа уборки',
        fr: '4 heures de nettoyage',
        de: '4 stunden reinigung'
    },
    'Same day jobs are hard': {
        pt: 'Trabalhos no mesmo dia são difíceis',
        es: 'Los trabajos del mismo día son difíciles',
        ru: 'Работа в тот же день затруднительна',
        fr: 'Les travaux le jour même sont difficiles',
        de: 'Aufträge am selben Tag sind schwierig'
    },
    'Allow all genders': {
        pt: 'Permitir todos os gêneros',
        es: 'Permitir todos los géneros',
        ru: 'Разрешить все пола',
        fr: 'Autoriser tous les genres',
        de: 'Alle Geschlechter zulassen'
    },
    'Pros prefer longer jobs': {
        pt: 'Profissionais preferem trabalhos mais longos',
        es: 'Los profesionales prefieren trabajos más largos',
        ru: 'Специалисты предпочитают более длительную работу',
        fr: 'Les professionnels préfèrent les travaux plus longs',
        de: 'Profis bevorzugen längere Aufträge'
    },
    'Lower cancellation rate': {
        pt: 'Menor taxa de cancelamento',
        es: 'Menor tasa de cancelación',
        ru: 'Низкий процент отмен',
        fr: 'Taux d\'annulation plus bas',
        de: 'Niedrigere Stornierungsrate'
    },
    'Add more days': {
        pt: 'Adicionar mais dias',
        es: 'Agregar más días',
        ru: 'Добавить больше дней',
        fr: 'Ajouter plus de jours',
        de: 'Weitere Tage hinzufügen'
    },
    'TIDY will send job requests to ': {
        pt: 'TIDY enviará pedidos de trabalho para ',
        es: 'TIDY enviará solicitudes de trabajo a ',
        ru: 'TIDY отправит запросы на работу ',
        fr: 'TIDY enverra des demandes de travail à ',
        de: 'TIDY wird Arbeitsanfragen senden an '
    },
    'TIDY will send job requests to new pros.': {
        pt: 'TIDY enviará pedidos de trabalho para novos profissionais.',
        es: 'TIDY enviará solicitudes de trabajo a nuevos profesionales.',
        ru: 'TIDY отправит запросы на работу новым специалистам.',
        fr: 'TIDY enverra des demandes de travail aux nouveaux pros.',
        de: 'TIDY wird Arbeitsanfragen an neue Profis senden.'
    },
    'Last Completed': {
        pt: 'Última Conclusão',
        es: 'Última Completada',
        ru: 'Последнее Выполнение',
        fr: 'Dernière Terminée',
        de: 'Zuletzt Abgeschlossen'
    },
    'Next Maintenance': {
        pt: 'Próxima Manutenção',
        es: 'Próximo Mantenimiento',
        ru: 'Следующее Обслуживание',
        fr: 'Prochain Entretien',
        de: 'Nächste Wartung'
    },
    'Cleaning': {
        pt: 'Limpeza',
        es: 'Limpieza',
        ru: 'Уборка',
        fr: 'Nettoyage',
        de: 'Reinigung'
    },
    'Pool Cleaning': {
        pt: 'Limpeza de Piscina',
        es: 'Limpieza de Piscina',
        ru: 'Чистка Бассейна',
        fr: 'Nettoyage de Piscine',
        de: 'Poolreinigung'
    },
    'Carpet Cleaning': {
        pt: 'Limpeza de Carpete',
        es: 'Limpieza de Alfombras',
        ru: 'Чистка Ковров',
        fr: 'Nettoyage de Tapis',
        de: 'Teppichreinigung'
    },
    'Window Washing': {
        pt: 'Lavagem de Janelas',
        es: 'Lavado de Ventanas',
        ru: 'Мытье Окон',
        fr: 'Lavage de Vitres',
        de: 'Fensterreinigung'
    },
    'Lawn & Garden Care': {
        pt: 'Cuidados com Gramado e Jardim',
        es: 'Cuidado del Césped y Jardín',
        ru: 'Уход за Газоном и Садом',
        fr: 'Entretien Pelouse et Jardin',
        de: 'Rasen- und Gartenpflege'
    },
    'Junk Removal': {
        pt: 'Remoção de Entulho',
        es: 'Eliminación de Basura',
        ru: 'Вывоз Мусора',
        fr: 'Enlèvement des Déchets',
        de: 'Müllentsorgung'
    },
    'Pest Control': {
        pt: 'Controle de Pragas',
        es: 'Control de Plagas',
        ru: 'Борьба с Вредителями',
        fr: 'Lutte Antiparasitaire',
        de: 'Schädlingsbekämpfung'
    },
    'Pressure Washing': {
        pt: 'Lavagem à Pressão',
        es: 'Lavado a Presión',
        ru: 'Мойка под Давлением',
        fr: 'Nettoyage à Pression',
        de: 'Hochdruckreinigung'
    },
    'Tree Trimming': {
        pt: 'Poda de Árvores',
        es: 'Poda de Árboles',
        ru: 'Обрезка Деревьев',
        fr: 'Élagage d\'Arbres',
        de: 'Baumpflege'
    },
    'Inspection': {
        pt: 'Inspeção',
        es: 'Inspección',
        ru: 'Инспекция',
        fr: 'Inspection',
        de: 'Inspektion'
    },
    'Handyman': {
        pt: 'Faz-Tudo',
        es: 'Manitas',
        ru: 'Мастер на Все Руки',
        fr: 'Bricoleur',
        de: 'Handwerker'
    },
    'Trash Service': {
        pt: 'Serviço de Lixo',
        es: 'Servicio de Basura',
        ru: 'Вывоз Мусора',
        fr: 'Service des Ordures',
        de: 'Müllservice'
    },
    'Laundry Service': {
        pt: 'Serviço de Lavanderia',
        es: 'Servicio de Lavandería',
        ru: 'Услуги Прачечной',
        fr: 'Service de Blanchisserie',
        de: 'Wäscheservice'
    },
    'Locksmith': {
        pt: 'Chaveiro',
        es: 'Cerrajero',
        ru: 'Слесарь',
        fr: 'Serrurier',
        de: 'Schlüsseldienst'
    },
    'Painting': {
        pt: 'Pintura',
        es: 'Pintura',
        ru: 'Покраска',
        fr: 'Peinture',
        de: 'Malerarbeiten'
    },
    'Grill Maintenance': {
        pt: 'Manutenção de Churrasqueira',
        es: 'Mantenimiento de Parrilla',
        ru: 'Обслуживание Гриля',
        fr: 'Entretien du Gril',
        de: 'Grillwartung'
    },
    'Biohazard Cleaning': {
        pt: 'Limpeza de Risco Biológico',
        es: 'Limpieza de Riesgo Biológico',
        ru: 'Очистка Биологических Загрязнений',
        fr: 'Nettoyage Biologique',
        de: 'Biogefährdungsreinigung'
    },
    'Plumbing': {
        pt: 'Encanamento',
        es: 'Fontanería',
        ru: 'Сантехника',
        fr: 'Plomberie',
        de: 'Sanitärarbeiten'
    },
    'Appliance Repair': {
        pt: 'Reparo de Eletrodomésticos',
        es: 'Reparación de Electrodomésticos',
        ru: 'Ремонт Бытовой Техники',
        fr: 'Réparation d\'Appareils',
        de: 'Gerätereparatur'
    },
    ' and ': {
        pt: ' e ',
        es: ' y ',
        ru: ' и ',
        fr: ' et ',
        de: ' und '
    },
    ', then ': {
        pt: ', depois ',
        es: ', luego ',
        ru: ', затем ',
        fr: ', puis ',
        de: ', dann '
    },
    'new pros': {
        pt: 'novos profissionais',
        es: 'nuevos profesionales',
        ru: 'новые специалисты',
        fr: 'nouveaux pros',
        de: 'neue Profis'
    },
    'Pros that you\'ve added or had jobs with will appear here.': {
        pt: 'Os profissionais que você adicionou ou teve trabalhos aparecerão aqui.',
        es: 'Los profesionales que has añadido o con los que has tenido trabajos aparecerán aquí.',
        ru: 'Здесь появятся специалисты, которых вы добавили или с которыми у вас были работы.',
        fr: 'Les pros que vous avez ajoutés ou avec qui vous avez eu des travaux apparaîtront ici.',
        de: 'Profis, die Sie hinzugefügt haben oder mit denen Sie Aufträge hatten, werden hier angezeigt.'
    },

    'Booked': {
        pt: 'Reservado',
        es: 'Reservado',
        ru: 'Забронировано',
        fr: 'Réservé',
        de: 'Bucht'
    },
    'Create Guest Smart Number': {
        pt: 'Criar Número Inteligente de Hospede',
        es: 'Crear Número Inteligente de Huésped',
        ru: 'Создать Номер Интеллектуального Хоста',
        fr: 'Créer un Numéro Intelligente de Hôte',
        de: 'Gast-Smart-Nummer erstellen'
    },
    'Create Pro Smart Number': {
        pt: 'Criar Número Inteligente de Profissional',
        es: 'Crear Número Inteligente de Profesional',
        ru: 'Создать Номер Интеллектуального Специалиста',
        fr: 'Créer un Numéro Intelligente de Professionnel',
        de: 'Profi-Smart-Nummer erstellen'
    },
    '+ Guest Smart Number': {
        pt: '+ Número Inteligente de Hospede',
        es: '+ Número Inteligente de Huésped',
        ru: '+ Номер Интеллектуального Хоста',
        fr: '+ Numéro Intelligente de Hôte',
        de: '+ Gast-Smart-Nummer'
    },
    '+ Pro Smart Number': {
        pt: '+ Número Inteligente de Profissional',
        es: '+ Número Inteligente de Profesional',
        ru: '+ Номер Интеллектуального Специалиста',
        fr: '+ Numéro Intelligente de Professionnel',
        de: '+ Profi-Smart-Nummer'
    },
    'Please enter a valid date.': {
        pt: 'Por favor, insira uma data válida.',
        es: 'Por favor, ingrese una fecha válida.',
        ru: 'Пожалуйста, введите действительную дату.',
        fr: 'Veuillez entrer une date valide.',
        de: 'Bitte geben Sie ein gültiges Datum ein.'
    },
    'Make card': {
        pt: 'Fazer cartão',
        es: 'Hacer tarjeta',
        ru: 'Сделать карту',
        fr: 'Faire une carte',
        de: 'Karte machen'
    },
    'primary?': {
        pt: 'primário?',
        es: 'primario?',
        ru: 'первичный?',
        fr: 'primaire?',
        de: 'primär?'
    },

    'Chat message sent': {
        pt: 'Mensagem de chat enviada',
        es: 'Mensaje de chat enviado',
        ru: 'Сообщение чата отправлено',
        fr: 'Message de chat envoyé',
        de: 'Chat-Nachricht gesendet'
    },
    'Chat message received': {
        pt: 'Mensaje de chat recibido',
        es: 'Mensaje de chat recibido',
        ru: 'Сообщение чата получено',
        fr: 'Message de chat reçu',
        de: 'Chat-Nachricht empfangen'
    },
    'Job scheduled': {
        pt: 'Trabalho agendado',
        es: 'Trabajo agendado',
        ru: 'Работа запланирована',
        fr: 'Travail planifié',
        de: 'Job geplant'
    },
    'Job completed': {
        pt: 'Trabalho concluído',
        es: 'Trabajo completado',
        ru: 'Работа завершена',
        fr: 'Travail terminé',
        de: 'Job abgeschlossen'
    },
    'Job cancelled': {
        pt: 'Trabalho cancelado',
        es: 'Trabajo cancelado',
        ru: 'Работа отменена',
        fr: 'Travail annulé',
        de: 'Job abgebrochen'
    },
    'Job failed': {
        pt: 'Trabalho falhou',
        es: 'Trabajo fallido',
        ru: 'Работа не удалась',
        fr: 'Travail annulé',
        de: 'Job abgebrochen'
    },
    'All Teams': {
        pt: 'Todos os Equipes',
        es: 'Todos los Equipos',
        ru: 'Все Команды',
        fr: 'Tous les Equipes',
        de: 'Alle Teams'
    },

    'Job In-progress': {
        pt: 'Trabalho em andamento',
        es: 'Trabajo en progreso',
        ru: 'Работа в процессе',
        fr: 'Travail en cours',
        de: 'Arbeit im Gange'
    },
    'Payment Methods': {
        pt: 'Métodos de Pagamento',
        es: 'Métodos de Pago',
        ru: 'Способы оплаты',
        fr: 'Méthodes de paiement',
        de: 'Zahlungsmethoden'
    },
    'Load Credit': {
        pt: 'Carregar Crédito',
        es: 'Cargar Crédito',
        ru: 'Загрузить кредит',
        fr: 'Charger le crédit',
        de: 'Guthaben aufladen'
    },
    'Bank Account': {
        pt: 'Conta Bancária',
        es: 'Cuenta Bancaria',
        ru: 'Банковский счет',
        fr: 'Compte bancaire',
        de: 'Bankkonto'
    },
    ' ending in ': {
        pt: ' terminando em ',
        es: ' terminando en ',
        ru: ' заканчивается на ',
        fr: ' se terminant par ',
        de: ' endet auf '
    },
    'Add Missing Bank Info': {
        pt: 'Adicionar Informações Bancárias Faltantes',
        es: 'Agregar Información Bancaria Faltante',
        ru: 'Добавить недостающую банковскую информацию',
        fr: 'Ajouter les informations bancaires manquantes',
        de: 'Fehlende Bankinformationen hinzufügen'
    },
    'Confirm Payment Method': {
        pt: 'Confirmar Método de Pagamento',
        es: 'Confirmar Método de Pago',
        ru: 'Подтвердить способ оплаты',
        fr: 'Confirmer le mode de paiement',
        de: 'Zahlungsmethode bestätigen'
    },
    'Make Primary': {
        pt: 'Tornar Primário',
        es: 'Hacer Primario',
        ru: 'Сделать основным',
        fr: 'Rendre principal',
        de: 'Primär machen'
    },
    'Remove from Team': {
        pt: 'Remover da Equipe',
        es: 'Eliminar del Equipo',
        ru: 'Удалить из команды',
        fr: 'Retirer de l\'équipe',
        de: 'Aus dem Team entfernen'
    },
    'Remove payment method?': {
        pt: 'Remover método de pagamento?',
        es: '¿Eliminar método de pago?',
        ru: 'Удалить способ оплаты?',
        fr: 'Supprimer le mode de paiement?',
        de: 'Zahlungsmethode entfernen?'
    },
    'Remove payment method': {
        pt: 'Remover método de pagamento',
        es: 'Eliminar método de pago',
        ru: 'Удалить способ оплаты',
        fr: 'Supprimer le mode de paiement',
        de: 'Zahlungsmethode entfernen'
    },
    'Are you sure you want to remove this payment method?': {
        pt: 'Tem certeza de que deseja remover este método de pagamento?',
        es: '¿Está seguro de que desea eliminar este método de pago?',
        ru: 'Вы уверены, что хотите удалить этот способ оплаты?',
        fr: 'Êtes-vous sûr de vouloir supprimer ce mode de paiement?',
        de: 'Sind Sie sicher, dass Sie diese Zahlungsmethode entfernen möchten?'
    },
    'Payment Method Removed': {
        pt: 'Método de Pagamento Removido',
        es: 'Método de Pago Eliminado',
        ru: 'Способ оплаты удален',
        fr: 'Mode de paiement supprimé',
        de: 'Zahlungsmethode entfernt'
    },
    'Failed to Remove Payment Method ': {
        pt: 'Falha ao Remover Método de Pagamento ',
        es: 'Error al Eliminar Método de Pago ',
        ru: 'Не удалось удалить способ оплаты ',
        fr: 'Échec de la suppression du mode de paiement ',
        de: 'Entfernen der Zahlungsmethode fehlgeschlagen '
    },
    'Remove card': {
        pt: 'Remover cartão',
        es: 'Eliminar tarjeta',
        ru: 'Удалить карту',
        fr: 'Supprimer la carte',
        de: 'Karte entfernen'
    },
    'Card Made Primary': {
        pt: 'Cartão Tornado Primário',
        es: 'Tarjeta Hecha Primaria',
        ru: 'Карта сделана основной',
        fr: 'Carte rendue principale',
        de: 'Karte primär gemacht'
    },
    'Failed to Make Card Primary ': {
        pt: 'Falha ao Tornar Cartão Primário ',
        es: 'Error al Hacer Tarjeta Primaria ',
        ru: 'Не удалось сделать карту основной ',
        fr: 'Échec de rendre la carte principale ',
        de: 'Fehler beim Primärmachen der Karte '
    },
    'Bank account confirmed successfully.': {
        pt: 'Conta bancária confirmada com sucesso.',
        es: 'Cuenta bancaria confirmada con éxito.',
        ru: 'Банковский счет успешно подтвержден.',
        fr: 'Compte bancaire confirmé avec succès.',
        de: 'Bankkonto erfolgreich bestätigt.'
    },
    'Error confirming bank account. ': {
        pt: 'Erro ao confirmar conta bancária. ',
        es: 'Error al confirmar cuenta bancaria. ',
        ru: 'Ошибка подтверждения банковского счета. ',
        fr: 'Erreur lors de la confirmation du compte bancaire. ',
        de: 'Fehler bei der Bestätigung des Bankkontos. '
    },
    'Succeeded': {
        pt: 'Sucesso',
        es: 'Exitoso',
        ru: 'Успешно',
        fr: 'Réussi',
        de: 'Erfolgreich'
    },
    'Action Required': {
        pt: 'Ação Necessária',
        es: 'Acción Requerida',
        ru: 'Требуется действие',
        fr: 'Action requise',
        de: 'Aktion erforderlich'
    },

    'Payment Method': {
        pt: 'Método de Pagamento',
        es: 'Método de Pago',
        ru: 'Способ Оплаты',
        fr: 'Méthode de Paiement',
        de: 'Zahlungsmethode'
    },
    'Add Credit': {
        pt: 'Adicionar Crédito',
        es: 'Agregar Crédito',
        ru: 'Добавить Кредит',
        fr: 'Ajouter du Crédit',
        de: 'Kredit Hinzufügen'
    },
    'Add New Payment Method': {
        pt: 'Adicionar Novo Método de Pagamento',
        es: 'Agregar Nuevo Método de Pago',
        ru: 'Добавить Новый Способ Оплаты',
        fr: 'Ajouter une Nouvelle Méthode de Paiement',
        de: 'Neue Zahlungsmethode Hinzufügen'
    },
    'Please fill in all fields': {
        pt: 'Por favor, preencha todos os campos',
        es: 'Por favor, complete todos los campos',
        ru: 'Пожалуйста, заполните все поля',
        fr: 'Veuillez remplir tous les champs',
        de: 'Bitte füllen Sie alle Felder aus'
    },
    'Credit added successfully': {
        pt: 'Crédito adicionado com sucesso',
        es: 'Crédito agregado con éxito',
        ru: 'Кредит успешно добавлен',
        fr: 'Crédit ajouté avec succès',
        de: 'Kredit erfolgreich hinzugefügt'
    },
    'Error adding credit': {
        pt: 'Erro ao adicionar crédito',
        es: 'Error al agregar crédito',
        ru: 'Ошибка добавления кредита',
        fr: 'Erreur lors de l\'ajout de crédit',
        de: 'Fehler beim Hinzufügen von Kredit'
    },
    'ending in': {
        pt: 'terminando em',
        es: 'terminando en',
        ru: 'заканчивается на',
        fr: 'se terminant par',
        de: 'endet mit'
    },
    'Edit Payment Method': {
        pt: 'Editar Método de Pagamento',
        es: 'Editar Método de Pago',
        ru: 'Редактировать Способ Оплаты',
        fr: 'Modifier la Méthode de Paiement',
        de: 'Zahlungsmethode Bearbeiten'
    },
    'Add Payment Method': {
        pt: 'Adicionar Método de Pagamento',
        es: 'Agregar Método de Pago',
        ru: 'Добавить Способ Оплаты',
        fr: 'Ajouter une Méthode de Paiement',
        de: 'Zahlungsmethode Hinzufügen'
    },
    'TIDY requires a card on file to enable automatic booking. The card can be used for your subscription or to pay pros you book. Questions? ': {
        pt: 'TIDY requer um cartão registrado para habilitar a reserva automática. O cartão pode ser usado para sua assinatura ou para pagar os profissionais que você reserva. Perguntas? ',
        es: 'TIDY requiere una tarjeta registrada para habilitar la reserva automática. La tarjeta puede usarse para su suscripción o para pagar a los profesionales que reserve. ¿Preguntas? ',
        ru: 'TIDY требует карту в файле для включения автоматического бронирования. Карта может быть использована для вашей подписки или для оплаты профессионалов, которых вы бронируете. Вопросы? ',
        fr: 'TIDY nécessite une carte enregistrée pour activer la réservation automatique. La carte peut être utilisée pour votre abonnement ou pour payer les professionnels que vous réservez. Questions? ',
        de: 'TIDY erfordert eine registrierte Karte, um automatische Buchungen zu ermöglichen. Die Karte kann für Ihr Abonnement oder zur Bezahlung der von Ihnen gebuchten Profis verwendet werden. Fragen? '
    },
    'Editing card ending in': {
        pt: 'Editando cartão terminando em',
        es: 'Editando tarjeta terminando en',
        ru: 'Редактирование карты, заканчивающейся на',
        fr: 'Modification de la carte se terminant par',
        de: 'Bearbeitung der Karte endet mit'
    },
    'Choose a card or bank account': {
        pt: 'Escolha um cartão ou conta bancária',
        es: 'Elija una tarjeta o cuenta bancaria',
        ru: 'Выберите карту или банковский счет',
        fr: 'Choisissez une carte ou un compte bancaire',
        de: 'Wählen Sie eine Karte oder ein Bankkonto'
    },
    'Choose a card or add a new card': {
        pt: 'Escolha um cartão ou adicione um novo cartão',
        es: 'Elija una tarjeta o agregue una nueva tarjeta',
        ru: 'Выберите карту или добавьте новую карту',
        fr: 'Choisissez une carte ou ajoutez une nouvelle carte',
        de: 'Wählen Sie eine Karte oder fügen Sie eine neue Karte hinzu'
    },
    'Account Holder\'s Full Name': {
        pt: 'Nome Completo do Titular da Conta',
        es: 'Nombre Completo del Titular de la Cuenta',
        ru: 'Полное Имя Владельца Счета',
        fr: 'Nom Complet du Titulaire du Compte',
        de: 'Vollständiger Name des Kontoinhabers'
    },
    'Please enter the account holder\'s full name.': {
        pt: 'Por favor, insira o nome completo do titular da conta.',
        es: 'Por favor, ingrese el nombre completo del titular de la cuenta.',
        ru: 'Пожалуйста, введите полное имя владельца счета.',
        fr: 'Veuillez entrer le nom complet du titulaire du compte.',
        de: 'Bitte geben Sie den vollständigen Namen des Kontoinhabers ein.'
    },
    'Add a new card': {
        pt: 'Adicionar um novo cartão',
        es: 'Agregar una nueva tarjeta',
        ru: 'Добавить новую карту',
        fr: 'Ajouter une nouvelle carte',
        de: 'Neue Karte hinzufügen'
    },
    'Choose a card': {
        pt: 'Escolha um cartão',
        es: 'Elija una tarjeta',
        ru: 'Выберите карту',
        fr: 'Choisissez une carte',
        de: 'Wählen Sie eine Karte'
    },
    'Card added to team successfully.': {
        pt: 'Cartão adicionado à equipe com sucesso.',
        es: 'Tarjeta agregada al equipo con éxito.',
        ru: 'Карта успешно добавлена в команду.',
        fr: 'Carte ajoutée à l\'équipe avec succès.',
        de: 'Karte erfolgreich zum Team hinzugefügt.'
    },
    'Error adding card to team.': {
        pt: 'Erro ao adicionar cartão à equipe.',
        es: 'Error al agregar tarjeta al equipo.',
        ru: 'Ошибка добавления карты в команду.',
        fr: 'Erreur lors de l\'ajout de la carte à l\'équipe.',
        de: 'Fehler beim Hinzufügen der Karte zum Team.'
    },
    'Bank account added successfully.': {
        pt: 'Conta bancária adicionada com sucesso.',
        es: 'Cuenta bancaria agregada con éxito.',
        ru: 'Банковский счет успешно добавлен.',
        fr: 'Compte bancaire ajouté avec succès.',
        de: 'Bankkonto erfolgreich hinzugefügt.'
    },
    'Error adding bank account. ': {
        pt: 'Erro ao adicionar conta bancária. ',
        es: 'Error al agregar cuenta bancaria. ',
        ru: 'Ошибка добавления банковского счета. ',
        fr: 'Erreur lors de l\'ajout du compte bancaire. ',
        de: 'Fehler beim Hinzufügen des Bankkontos. '
    },


    'left from': {
        pt: 'restante de',
        es: 'restante de',
        ru: 'осталось от',
        fr: 'restant de',
        de: 'übrig von'
    },
    'credit added by': {
        pt: 'crédito adicionado por',
        es: 'crédito agregado por',
        ru: 'кредит добавлен',
        fr: 'crédit ajouté par',
        de: 'Guthaben hinzugefügt von'
    },
    'Pending Credit:': {
        pt: 'Crédito Pendente:',
        es: 'Crédito Pendiente:',
        ru: 'Ожидающий Кредит:',
        fr: 'Crédit en Attente:',
        de: 'Ausstehende Gutschrift:'
    },
    'The credit you loaded will be added to your account once the charges are completed.': {
        pt: 'O crédito que você carregou será adicionado à sua conta assim que as cobranças forem concluídas.',
        es: 'El crédito que cargó se agregará a su cuenta una vez que se completen los cargos.',
        ru: 'Загруженный вами кредит будет добавлен на ваш счет после завершения платежей.',
        fr: 'Le crédit que vous avez chargé sera ajouté à votre compte une fois les frais terminés.',
        de: 'Das von Ihnen geladene Guthaben wird Ihrem Konto gutgeschrieben, sobald die Gebühren abgeschlossen sind.'
    },
    'Current Credit:': {
        pt: 'Crédito Atual:',
        es: 'Crédito Actual:',
        ru: 'Текущий Кредит:',
        fr: 'Crédit Actuel:',
        de: 'Aktuelles Guthaben:'
    },
    'debit': {
        pt: 'débito',
        es: 'débito',
        ru: 'дебет',
        fr: 'débit',
        de: 'Lastschrift'
    },
    'Bank account ending in': {
        pt: 'Conta bancária terminando em',
        es: 'Cuenta bancaria terminando en',
        ru: 'Банковский счет, оканчивающийся на',
        fr: 'Compte bancaire se terminant par',
        de: 'Bankkonto endend auf'
    },
    'Bank account': {
        pt: 'Conta bancária',
        es: 'Cuenta bancaria',
        ru: 'Банковский счет',
        fr: 'Compte bancaire',
        de: 'Bankkonto'
    },
    'charge on card': {
        pt: 'cobrança no cartão',
        es: 'cargo en la tarjeta',
        ru: 'платеж по карте',
        fr: 'frais sur la carte',
        de: 'Gebühr auf Karte'
    },
    'debit from account': {
        pt: 'débito da conta',
        es: 'débito de la cuenta',
        ru: 'списание со счета',
        fr: 'débit du compte',
        de: 'Lastschrift vom Konto'
    },
    'Unable to refund. Please contact us in the Concierge section for support.': {
        pt: 'Não é possível reembolsar. Entre em contato conosco na seção Concierge para obter suporte.',
        es: 'No se puede reembolsar. Contáctenos en la sección Concierge para obtener ayuda.',
        ru: 'Невозможно выполнить возврат. Пожалуйста, свяжитесь с нами в разделе Консьерж для поддержки.',
        fr: 'Impossible de rembourser. Veuillez nous contacter dans la section Conciergerie pour obtenir de l\'aide.',
        de: 'Rückerstattung nicht möglich. Bitte kontaktieren Sie uns im Concierge-Bereich für Unterstützung.'
    },
    'Error fetching invoice, please log in to view the bill if not logged in.': {
        pt: 'Erro ao buscar fatura, faça login para visualizar a conta se não estiver conectado.',
        es: 'Error al obtener la factura, inicie sesión para ver la factura si no ha iniciado sesión.',
        ru: 'Ошибка при получении счета, пожалуйста, войдите в систему, чтобы просмотреть счет, если вы не авторизованы.',
        fr: 'Erreur lors de la récupération de la facture, veuillez vous connecter pour voir la facture si vous n\'êtes pas connecté.',
        de: 'Fehler beim Abrufen der Rechnung. Bitte melden Sie sich an, um die Rechnung einzusehen, wenn Sie nicht angemeldet sind.'
    },
    '(preferred)': {
        pt: '(preferencial)',
        es: '(preferido)',
        ru: '(предпочтительно)',
        fr: '(préféré)',
        de: '(bevorzugt)'
    },
    'ACH Debit': {
        pt: 'Débito ACH',
        es: 'Débito ACH',
        ru: 'ACH Дебет',
        fr: 'Débit ACH',
        de: 'ACH-Lastschrift'
    },
    'Use my primary credit card on file (ends in': {
        pt: 'Usar meu cartão de crédito principal registrado (termina em',
        es: 'Usar mi tarjeta de crédito principal registrada (termina en',
        ru: 'Использовать мою основную кредитную карту в файле (заканчивается на',
        fr: 'Utiliser ma carte de crédit principale enregistrée (se termine par',
        de: 'Meine gespeicherte Hauptkreditkarte verwenden (endet auf'
    },
    'How do you want to pay the remaining': {
        pt: 'Como você deseja pagar o restante',
        es: 'Cómo desea pagar el resto',
        ru: 'Как вы хотите оплатить оставшуюся сумму',
        fr: 'Comment voulez-vous payer le reste',
        de: 'Wie möchten Sie den Restbetrag bezahlen'
    },
    'balance?': {
        pt: 'saldo?',
        es: 'saldo?',
        ru: 'баланс?',
        fr: 'solde?',
        de: 'Betrag?'
    },
    'Bill link copied': {
        pt: 'Link da conta copiado',
        es: 'Enlace de factura copiado',
        ru: 'Ссылка на счет скопирована',
        fr: 'Lien de facture copié',
        de: 'Rechnungslink kopiert'
    },

    'Booking Channels': {
        pt: 'Canais de Reserva',
        es: 'Canales de Reserva',
        ru: 'Каналы бронирования',
        fr: 'Canaux de réservation',
        de: 'Buchungskanäle'
    },
    'Smart Locks': {
        pt: 'Fechaduras Inteligentes',
        es: 'Cerraduras Inteligentes',
        ru: 'Умные замки',
        fr: 'Serrures intelligentes',
        de: 'Smart Locks'
    },
    'Smart Thermostats': {
        pt: 'Termostatos Inteligentes',
        es: 'Termostatos Inteligentes',
        ru: 'Умные термостаты',
        fr: 'Thermostats intelligents',
        de: 'Smart Thermostate'
    },
    'address': {
        pt: 'endereço',
        es: 'dirección',
        ru: 'адрес',
        fr: 'adresse',
        de: 'Adresse'
    },
    'addresses': {
        pt: 'endereços',
        es: 'direcciones',
        ru: 'адреса',
        fr: 'adresses',
        de: 'Adressen'
    },
    'devices': {
        pt: 'dispositivos',
        es: 'dispositivos',
        ru: 'устройства',
        fr: 'appareils',
        de: 'Geräte'
    },
    'integrations added': {
        pt: 'integrações adicionadas',
        es: 'integraciones añadidas',
        ru: 'интеграции добавлены',
        fr: 'intégrations ajoutées',
        de: 'Integrationen hinzugefügt'
    },
    'Pending Integrations': {
        pt: 'Integrações Pendentes',
        es: 'Integraciones Pendientes',
        ru: 'Ожидающие интеграции',
        fr: 'Intégrations en attente',
        de: 'Ausstehende Integrationen'
    },
    'There was an issue syncing this integration. Please update your integration details.': {
        pt: 'Houve um problema ao sincronizar esta integração. Por favor, atualize os detalhes da sua integração.',
        es: 'Hubo un problema al sincronizar esta integración. Por favor, actualice los detalles de su integración.',
        ru: 'Возникла проблема при синхронизации этой интеграции. Пожалуйста, обновите детали интеграции.',
        fr: 'Un problème est survenu lors de la synchronisation de cette intégration. Veuillez mettre à jour les détails de votre intégration.',
        de: 'Bei der Synchronisierung dieser Integration ist ein Problem aufgetreten. Bitte aktualisieren Sie Ihre Integrationsdetails.'
    },

    'Monthly Subscription Plan': {
        pt: 'Plano de Assinatura Mensal',
        es: 'Plan de Suscripción Mensual',
        ru: 'Месячный план подписки',
        fr: 'Plan de souscription mensuel',
        de: 'Monatlicher Abonnementplan'
    },
    'Laundry': {
        pt: 'Lavanderia',
        es: 'Lavandería',
        ru: 'Прачечная',
        fr: 'Lave-linge',
        de: 'Waschservice'
    },
    '1 hour cleaning (2 Pros)': {
        pt: 'Limpeza de 1 hora (2 profissionais)',
        es: 'Limpieza de 1 hora (2 profesionales)',
        ru: 'Уборка за 1 час (2 профессионала)',
        fr: 'Nettoyage de 1 heure (2 professionnels)',
        de: '1-Stunden-Putz (2 Fachleute)'
    },
    '2.5 hour cleaning (2 Pros)': {
        pt: 'Limpeza de 2.5 horas (2 profissionais)',
        es: 'Limpieza de 2.5 horas (2 profesionales)',
        ru: 'Уборка за 2.5 часа (2 профессионала)',
        fr: 'Nettoyage de 2,5 heures (2 professionnels)',
        de: '2,5-Stunden-Putz (2 Fachleute)'
    },
    '4 hour cleaning (2 Pros)': {
        pt: 'Limpeza de 4 horas (2 profissionais)',
        es: 'Limpieza de 4 horas (2 profesionales)',
        ru: 'Уборка за 4 часа (2 профессионала)',
        fr: 'Nettoyage de 4 heures (2 professionnels)',
        de: '4-Stunden-Putz (2 Fachleute)'
    },

    'Suggested Maintenance': {
        pt: 'Manutenção Sugerida',
        es: 'Mantenimiento Sugerido',
        ru: 'Предлагаемое обслуживание',
        fr: 'Maintenance suggérée',
        de: 'Vorgeschlagene Wartung'
    },
    'Past Maintenance': {
        pt: 'Manutenção Passada',
        es: 'Mantenimiento Pasado',
        ru: 'Прошедшее обслуживание',
        fr: 'Maintenance passée',
        de: 'Vergangene Wartung'
    },
    'Synced Reservations': {
        pt: 'Reservas Sincronizadas',
        es: 'Reservas Sincronizadas',
        ru: 'Синхронизированные бронирования',
        fr: 'Réservations synchronisées',
        de: 'Synchronisierte Reservierungen'
    },
    'Maintenances': {
        pt: 'Manutenções',
        es: 'Mantenimientos',
        ru: 'Обслуживания',
        fr: 'Entretiens',
        de: 'Wartungen'
    },
    'Hourly Time Details': {
        pt: 'Detalhes de Tempo por Hora',
        es: 'Detalles de Tiempo por Hora',
        ru: 'Детали времени в час',
        fr: 'Détails de temps par heure',
        de: 'Zeitdetails pro Stunde'
    },

    ' left from ': {
        pt: 'restante de',
        es: 'restante de',
        ru: 'осталось от',
        fr: 'restant de',
        de: 'übrig von'
    },
    ' credit added by ': {
        pt: 'crédito adicionado por',
        es: 'crédito agregado por',
        ru: 'кредит добавлен',
        fr: 'crédit ajouté par',
        de: 'Guthaben hinzugefügt von'
    },
    'charge on card ': {
        pt: 'cobrança no cartão',
        es: 'cargo en la tarjeta',
        ru: 'платеж по карте',
        fr: 'frais sur la carte',
        de: 'Gebühr auf Karte'
    },
    'debit from account ': {
        pt: 'débito da conta',
        es: 'débito de la cuenta',
        ru: 'списание со счета',
        fr: 'débit du compte',
        de: 'Lastschrift vom Konto'
    },
    'on bank ': {
        pt: 'no banco',
        es: 'en el banco',
        ru: 'на банковском счете',
        fr: 'sur le compte bancaire',
        de: 'auf dem Bankkonto'
    },
    'on card ': {
        pt: 'no cartão',
        es: 'en la tarjeta',
        ru: 'на карте',
        fr: 'sur la carte',
        de: 'auf der Karte'
    },
    ' charge': {
        pt: 'cobrado',
        es: 'cobrado',
        ru: 'списан',
        fr: 'facturé',
        de: 'abgerechnet'
    },


    'Items per page': {
        pt: 'Itens por página',
        es: 'Items por página',
        ru: 'Элементов на страницу',
        fr: 'Articles par page',
        de: 'Elemente pro Seite'
    },

    'Shared Job Link': {
        pt: 'Link de limpeza compartilhado',
        es: 'Link de limpieza compartido',
        ru: 'Ссылка на уборку, совместно используемая',
        fr: 'Lien de nettoyage partagé',
        de: 'Geteilte Job-Link'
    },
    'AI': {
        pt: 'IA',
        es: 'IA',
        ru: 'ИИ',
        fr: 'IA',
        de: 'IA'
    },

    'SMS': {
        pt: 'SMS',
        es: 'SMS',
        ru: 'СМС',
        fr: 'SMS',
        de: 'SMS'
    },
    'Revoke Access': {
        pt: 'Revogar Acesso',
        es: 'Revocar Acceso',
        ru: 'Отозвать доступ',
        fr: 'Révoquer l\'accès',
        de: 'Zugriff widerrufen'
    },
    'Format': {
        pt: 'Formato',
        es: 'Formato',
        ru: 'Формат',
        fr: 'Format',
        de: 'Format'
    },
    'Change Temperature': {
        pt: 'Alterar Temperatura',
        es: 'Cambiar Temperatura',
        ru: 'Изменить температуру',
        fr: 'Changer la température',
        de: 'Temperatur ändern'
    },
    'Delay': {
        pt: 'Atraso',
        es: 'Retraso',
        ru: 'Задержка',
        fr: 'Délai',
        de: 'Verzögerung'
    },
    'Grant Access': {
        pt: 'Conceder Acesso',
        es: 'Conceder Acceso',
        ru: 'Предоставить доступ',
        fr: 'Accorder l\'accès',
        de: 'Zugriff gewähren'
    },
    'Photo Analysis': {
        pt: 'Análise de Foto',
        es: 'Análisis de Foto',
        ru: 'Анализ фото',
        fr: 'Analyse photo',
        de: 'Fotoanalyse'
    },
    'Message Analysis': {
        pt: 'Análise de Mensagem',
        es: 'Análisis de Mensaje',
        ru: 'Анализ сообщения',
        fr: 'Analyse de message',
        de: 'Nachrichtenanalyse'
    },
    'Checklist Analysis': {
        pt: 'Análise de Lista de Verificação',
        es: 'Análisis de Lista de Verificación',
        ru: 'Анализ контрольного списка',
        fr: 'Analyse de liste de contrôle',
        de: 'Checklistenanalyse'
    },
    'Job Created (After delay)': {
        pt: 'Trabalho Criado (Após atraso)',
        es: 'Trabajo Creado (Después del retraso)',
        ru: 'Задание создано (после задержки)',
        fr: 'Travail créé (après délai)',
        de: 'Auftrag erstellt (nach Verzögerung)'
    },
    'Job Should Have Started': {
        pt: 'Trabalho Deveria Ter Começado',
        es: 'El Trabajo Debería Haber Comenzado',
        ru: 'Задание должно было начаться',
        fr: 'Le travail aurait dû commencer',
        de: 'Auftrag hätte beginnen sollen'
    },
    'Job Alert': {
        pt: 'Alerta de Trabalho',
        es: 'Alerta de Trabajo',
        ru: 'Оповещение о задании',
        fr: 'Alerte de travail',
        de: 'Auftragsalarm'
    },
    'Pro Arrived': {
        pt: 'Profissional Chegou',
        es: 'Profesional Llegó',
        ru: 'Специалист прибыл',
        fr: 'Pro est arrivé',
        de: 'Profi ist eingetroffen'
    },
    'Pro Unassigned from Job': {
        pt: 'Profissional Removido do Trabalho',
        es: 'Profesional Desasignado del Trabajo',
        ru: 'Специалист снят с задания',
        fr: 'Pro désaffecté du travail',
        de: 'Profi vom Auftrag entfernt'
    },
    'Pro Assigned to Job': {
        pt: 'Profissional Atribuído ao Trabalho',
        es: 'Profesional Asignado al Trabajo',
        ru: 'Специалист назначен на задание',
        fr: 'Pro affecté au travail',
        de: 'Profi dem Auftrag zugewiesen'
    },
    'Task Due Soon': {
        pt: 'Tarefa a Vencer em Breve',
        es: 'Tarea Por Vencer Pronto',
        ru: 'Срок выполнения задачи скоро истекает',
        fr: 'Tâche bientôt échue',
        de: 'Aufgabe bald fällig'
    },
    'Task Updated': {
        pt: 'Tarefa Atualizada',
        es: 'Tarea Actualizada',
        ru: 'Задача обновлена',
        fr: 'Tâche mise à jour',
        de: 'Aufgabe aktualisiert'
    },
    'Guest Reservation Starts Soon': {
        pt: 'Reserva do Hóspede Começa em Breve',
        es: 'La Reserva del Huésped Comienza Pronto',
        ru: 'Бронирование гостя скоро начнется',
        fr: 'La réservation de l\'invité commence bientôt',
        de: 'Gästereservierung beginnt bald'
    },
    'Job Starts Soon': {
        pt: 'Trabalho Começa em Breve',
        es: 'El Trabajo Comienza Pronto',
        ru: 'Задание скоро начнется',
        fr: 'Le travail commence bientôt',
        de: 'Auftrag beginnt bald'
    },
    'Message Created': {
        pt: 'Mensagem Criada',
        es: 'Mensaje Creado',
        ru: 'Сообщение создано',
        fr: 'Message créé',
        de: 'Nachricht erstellt'
    },
    'Every Month': {
        pt: 'Todo Mês',
        es: 'Cada Mes',
        ru: 'Каждый месяц',
        fr: 'Chaque mois',
        de: 'Jeden Monat'
    },
    'Every Year': {
        pt: 'Todo Ano',
        es: 'Cada Año',
        ru: 'Каждый год',
        fr: 'Chaque année',
        de: 'Jedes Jahr'
    },
    'Every Day': {
        pt: 'Todo Dia',
        es: 'Cada Día',
        ru: 'Каждый день',
        fr: 'Chaque jour',
        de: 'Jeden Tag'
    },
    'Every Hour': {
        pt: 'Toda Hora',
        es: 'Cada Hora',
        ru: 'Каждый час',
        fr: 'Chaque heure',
        de: 'Jede Stunde'
    },
    'Guest Reservation Cancelled': {
        pt: 'Reserva do Hóspede Cancelada',
        es: 'Reserva del Huésped Cancelada',
        ru: 'Бронирование гостя отменено',
        fr: 'Réservation de l\'invité annulée',
        de: 'Gästereservierung storniert'
    },
    'Guest Reservation Created': {
        pt: 'Reserva do Hóspede Criada',
        es: 'Reserva del Huésped Creada',
        ru: 'Бронирование гостя создано',
        fr: 'Réservation de l\'invité créée',
        de: 'Gästereservierung erstellt'
    },
    'Job Created': {
        pt: 'Trabalho Criado',
        es: 'Trabajo Creado',
        ru: 'Задание создано',
        fr: 'Travail créé',
        de: 'Auftrag erstellt'
    },
    'Job Completed': {
        pt: 'Trabalho Concluído',
        es: 'Trabajo Completado',
        ru: 'Задание выполнено',
        fr: 'Travail terminé',
        de: 'Auftrag abgeschlossen'
    },
    ' (eg: ': {
        pt: ' (ex: ',
        es: ' (ej: ',
        ru: ' (например: ',
        fr: ' (ex: ',
        de: ' (ex: '
    },
    'Limit Max Jobs Per Day': {
        pt: 'Limitar Máximo de Trabalhos Por Dia',
        es: 'Límite Máximo de Trabajos Por Día',
        ru: 'Ограничить максимальное количество заданий в день',
        fr: 'Limite Maximum de Travaux Par Jour',
        de: 'Maximale Aufträge Pro Tag Begrenzen'
    },
    'Max jobs per day': {
        pt: 'Máximo de trabalhos por dia',
        es: 'Máximo de trabajos por día',
        ru: 'Максимум заданий в день',
        fr: 'Maximum de travaux par jour',
        de: 'Maximale Aufträge pro Tag'
    },
    'Remove ': {
        pt: 'Remover ',
        es: 'Eliminar ',
        ru: 'Удалить ',
        fr: 'Supprimer ',
        de: 'Entfernen '
    },
    ' From List?': {
        pt: ' Da Lista?',
        es: ' De la Lista?',
        ru: ' Из списка?',
        fr: ' De la Liste?',
        de: ' Von der Liste?'
    },
    'To: ': {
        pt: 'Para: ',
        es: 'Para: ',
        ru: 'Кому: ',
        fr: 'À: ',
        de: 'An: '
    },
    'Sent ': {
        pt: 'Enviado ',
        es: 'Enviado ',
        ru: 'Отправлено ',
        fr: 'Envoyé ',
        de: 'Gesendet '
    },
    ' at ': {
        pt: ' às ',
        es: ' a las ',
        ru: ' в ',
        fr: ' à ',
        de: ' um '
    },
    'Unit ': {
        pt: 'Unidade ',
        es: 'Unidad ',
        ru: 'Единица ',
        fr: 'Unité ',
        de: 'Einheit '
    },
    ' hour ': {
        pt: ' hora ',
        es: ' hora ',
        ru: ' час ',
        fr: ' heure ',
        de: ' Stunde '
    },
    ' hours ': {
        pt: ' horas ',
        es: ' horas ',
        ru: ' часов ',
        fr: ' heures ',
        de: ' Stunden '
    },
    ' minutes': {
        pt: ' minutos',
        es: ' minutos',
        ru: ' минут',
        fr: ' minutes',
        de: ' Minuten'
    },
    'Check': {
        pt: 'Verificar',
        es: 'Verificar',
        ru: 'Проверить',
        fr: 'Vérifier',
        de: 'Prüfen'
    },
    'After Photos': {
        pt: 'Fotos Depois',
        es: 'Fotos Después',
        ru: 'Фото После',
        fr: 'Photos Après',
        de: 'Fotos Nachher'
    },
    'Before Photos': {
        pt: 'Fotos Antes',
        es: 'Fotos Antes',
        ru: 'Фото До',
        fr: 'Photos Avant',
        de: 'Fotos Vorher'
    },
    'Before & After Photos': {
        pt: 'Fotos Antes e Depois',
        es: 'Fotos Antes y Después',
        ru: 'Фото До и После',
        fr: 'Photos Avant et Après',
        de: 'Fotos Vorher und Nachher'
    },
    'Property Specific': {
        pt: 'Específico da Propriedade',
        es: 'Específico de la Propiedad',
        ru: 'Специфичное для объекта',
        fr: 'Spécifique à la propriété',
        de: 'Eigenspezifisch'
    },
    'No Inspection': {
        pt: 'Sem Inspeção',
        es: 'Sin Inspección',
        ru: 'Без осмотра',
        fr: 'Sans inspection',
        de: 'Ohne Inspektion'
    },
    'Location unavailable - using property location for report': {
        pt: 'Localização indisponível - usando localização do imóvel para relatório',
        es: 'Ubicación no disponible - usando ubicación del inmueble para informe',
        ru: 'Местоположение недоступно - используется местоположение объекта для отчета',
        fr: 'Emplacement indisponible - utilisation de l\'emplacement du bien pour le rapport',
        de: 'Standort nicht verfügbar - Verwendung der Immobilienstandort für Bericht'
    },
    'Marked as performed by pro': {
        pt: 'Marcado como realizado pelo profissional',
        es: 'Marcado como realizado por el profesional',
        ru: 'Отмечено как выполненное специалистом',
        fr: 'Marqué comme réalisé par le professionnel',
        de: 'Markiert als von Profi ausgeführt'
    },
    'Marked as not performed by pro': {
        pt: 'Marcado como não realizado pelo profissional',
        es: 'Marcado como no realizado por el profesional',
        ru: 'Отмечено как не выполненное специалистом',
        fr: 'Marqué comme non réalisé par le professionnel',
        de: 'Markiert als nicht von Profi ausgeführt'
    },
    'Not marked by pro': {
        pt: 'Não marcado pelo profissional',
        es: 'No marcado por el profesional',
        ru: 'Не отмечено специалистом',
        fr: 'Non marqué par le professionnel',
        de: 'Nicht markiert von Profi'
    },

    'Use To-Dos at ': {
        pt: 'Usar Tarefas em',
        es: 'Usar Tareas en',
        ru: 'Использовать задачи в',
        fr: 'Utiliser les tâches à',
        de: 'To-Dos verwenden um'
    },
    'Default to-do list for ': {
        pt: 'Lista de tarefas padrão para',
        es: 'Lista de tareas predeterminada para',
        ru: 'Список задач по умолчанию для',
        fr: 'Liste de tâches par défaut pour',
        de: 'Standard-Aufgabenliste für'
    },
    'Write a Note': {
        pt: 'Escrever uma Nota',
        es: 'Escribir una Nota',
        ru: 'Написать заметку',
        fr: 'Écrire une Note',
        de: 'Eine Notiz schreiben'
    },
    '<b>Automatic Booking</b>': {
        pt: '<b>Reserva Automática</b>',
        es: '<b>Reserva Automática</b>',
        ru: '<b>Автоматическое бронирование</b>',
        fr: '<b>Réservation Automatique</b>',
        de: '<b>Automatische Buchung</b>'
    },
    'You had a guest check out on': {
        pt: 'Você teve um hóspede que fez check-out em',
        es: 'Tuviste un huésped que hizo check-out el',
        ru: 'У вас был выезд гостя',
        fr: 'Vous avez eu un départ de client le',
        de: 'Ein Gast ist ausgecheckt am'
    },
    '. Do you want to keep automatic booking on for this reservation?': {
        pt: '. Deseja manter a reserva automática ativa para esta reserva?',
        es: '. ¿Desea mantener la reserva automática activada para esta reserva?',
        ru: '. Хотите сохранить автоматическое бронирование для этой брони?',
        fr: '. Voulez-vous garder la réservation automatique active pour cette réservation?',
        de: '. Möchten Sie die automatische Buchung für diese Reservierung aktiviert lassen?'
    },
    'Keep Automatic Booking On': {
        pt: 'Manter Reserva Automática Ativa',
        es: 'Mantener Reserva Automática Activada',
        ru: 'Оставить автоматическое бронирование включенным',
        fr: 'Garder la Réservation Automatique Activée',
        de: 'Automatische Buchung aktiviert lassen'
    },
    'Never want them again?': {
        pt: 'Não quer mais recebê-los?',
        es: '¿No quieres recibirlos nunca más?',
        ru: 'Больше не хотите их видеть?',
        fr: 'Ne plus jamais les revoir ?',
        de: 'Nie wieder erwünscht?'
    },
    'Block Pro From All Properties': {
        pt: 'Bloquear Profissional de Todas as Propriedades',
        es: 'Bloquear Profesional de Todas las Propiedades',
        ru: 'Заблокировать специалиста для всех объектов',
        fr: 'Bloquer le Pro de Toutes les Propriétés',
        de: 'Pro von Allen Immobilien Blockieren'
    },
    ' more': {
        pt: ' mais',
        es: ' más',
        ru: ' ещё',
        fr: ' plus',
        de: ' mehr'
    },

    'ago': {
        pt: 'atrás',
        es: 'atrás',
        ru: 'назад',
        fr: 'il y a',
        de: 'vor'
    },
    'days': {
        pt: 'dias',
        es: 'días',
        ru: 'дней',
        fr: 'jours',
        de: 'Tage'
    },
    'months': {
        pt: 'meses',
        es: 'meses',
        ru: 'месяцев',
        fr: 'mois',
        de: 'Monate'
    },
    'weeks': {
        pt: 'semanas',
        es: 'semanas',
        ru: 'недель',
        fr: 'semaines',
        de: 'Wochen'
    },
    'hours': {
        pt: 'horas',
        es: 'horas',
        ru: 'часов',
        fr: 'heures',
        de: 'Stunden'
    },
    'minutes': {
        pt: 'minutos',
        es: 'minutos',
        ru: 'минут',
        fr: 'minutes',
        de: 'Minuten'
    },
    'years': {
        pt: 'anos',
        es: 'años',
        ru: 'лет',
        fr: 'années',
        de: 'Jahre'
    },
    'am': {
        pt: 'am',
        es: 'am',
        ru: 'дп',
        fr: 'am',
        de: 'am'
    },
    'pm': {
        pt: 'pm',
        es: 'pm',
        ru: 'пп',
        fr: 'pm',
        de: 'pm'
    },
    'Default Settings': {
        es: 'Configuraciones Predeterminadas',
        ru: 'Настройки по умолчанию',
        fr: 'Paramètres par défaut',
        de: 'Standardeinstellungen'
    },
    'What\'s New?': {
        pt: 'O que há de novo?',
        es: '¿Qué hay de nuevo?',
        ru: 'Что нового?',
        fr: 'Qu\'est-ce qui a changé?',
        de: 'Was ist neu?'
    },
    'OFF': {
        pt: 'DESLIGADO',
        es: 'APAGADO',
        ru: 'ВЫКЛ',
        fr: 'DÉSACTIVÉ',
        de: 'AUS'
    },
    'ON': {
        pt: 'LIGADO',
        es: 'ENCENDIDO',
        ru: 'ВКЛ',
        fr: 'ACTIVÉ',
        de: 'EIN'
    },

    'The inventory of this item is currently low. We created a task for the operations team to restock per your settings.': {
        pt: 'O estoque deste item está baixo no momento. Criamos uma tarefa para a equipe de operações reabastecer conforme suas configurações.',
        es: 'El inventario de este artículo está actualmente bajo. Creamos una tarea para que el equipo de operaciones reabastezca según sus configuraciones.',
        ru: 'Запас этого товара в настоящее время низкий. Мы создали задачу для операционной команды пополнить запасы согласно вашим настройкам.',
        fr: 'Le stock de cet article est actuellement bas. Nous avons créé une tâche pour que l\'équipe des opérations réapprovisionne selon vos paramètres.',
        de: 'Der Bestand dieses Artikels ist derzeit niedrig. Wir haben eine Aufgabe für das Betriebsteam erstellt, um gemäß Ihren Einstellungen nachzufüllen.'
    },
    'No Model Specified': {
        pt: 'Nenhum Modelo Especificado',
        es: 'Ningún Modelo Especificado',
        ru: 'Модель не указана',
        fr: 'Aucun Modèle Spécifié',
        de: 'Kein Modell Angegeben'
    },
    'Current Quantity: ': {
        pt: 'Quantidade Atual: ',
        es: 'Cantidad Actual: ',
        ru: 'Текущее количество: ',
        fr: 'Quantité Actuelle: ',
        de: 'Aktuelle Menge: '
    },
    'Low Inventory Alert: ': {
        pt: 'Alerta de Baixo Estoque: ',
        es: 'Alerta de Inventario Bajo: ',
        ru: 'Оповещение о низком запасе: ',
        fr: 'Alerte de Stock Faible: ',
        de: 'Warnung bei niedrigem Bestand: '
    },
    'Min Amount: ': {
        pt: 'Quantidade Mínima: ',
        es: 'Cantidad Mínima: ',
        ru: 'Минимальное количество: ',
        fr: 'Montant Minimum: ',
        de: 'Mindestmenge: '
    },
    'Max Amount: ': {
        pt: 'Quantidade Máxima: ',
        es: 'Cantidad Máxima: ',
        ru: 'Максимальное количество: ',
        fr: 'Montant Maximum: ',
        de: 'Maximalmenge: '
    },
    'no max': {
        pt: 'sem máximo',
        es: 'sin máximo',
        ru: 'без максимума',
        fr: 'pas de maximum',
        de: 'kein Maximum'
    },
    'Opportunistic Order Level: ': {
        pt: 'Nível de Pedido Oportuno: ',
        es: 'Nivel de Pedido Oportunista: ',
        ru: 'Уровень оппортунистического заказа: ',
        fr: 'Niveau de Commande Opportuniste: ',
        de: 'Opportunistisches Bestellniveau: '
    },
    ' min (restock if other items are being restocked)': {
        pt: ' mín (reabastecer se outros itens estiverem sendo reabastecidos)',
        es: ' mín (reabastecer si se están reabasteciendo otros artículos)',
        ru: ' мин (пополнить, если пополняются другие товары)',
        fr: ' min (réapprovisionner si d\'autres articles sont réapprovisionnés)',
        de: ' min (nachfüllen, wenn andere Artikel nachgefüllt werden)'
    },
    ' min / ': {
        pt: ' mín / ',
        es: ' mín / ',
        ru: ' мин / ',
        fr: ' min / ',
        de: ' min / '
    },
    'Where to Order: ': {
        pt: 'Onde Pedir: ',
        es: 'Dónde Ordenar: ',
        ru: 'Где заказать: ',
        fr: 'Où Commander: ',
        de: 'Wo Bestellen: '
    },
    'Notes: ': {
        pt: 'Notas: ',
        es: 'Notas: ',
        ru: 'Примечания: ',
        fr: 'Notes: ',
        de: 'Notizen: '
    },
    'Delivery Timing Preferences: ': {
        pt: 'Preferências de Horário de Entrega: ',
        es: 'Preferencias de Tiempo de Entrega: ',
        ru: 'Предпочтения по времени доставки: ',
        fr: 'Préférences de Délai de Livraison: ',
        de: 'Lieferzeiten-Präferenzen: '
    },

    'Select ': {
        pt: 'Selecionar ',
        es: 'Seleccionar ',
        ru: 'Выбрать ',
        fr: 'Sélectionner ',
        de: 'Auswählen '
    },

    'Min Quantity': {
        pt: 'Quantidade Mínima',
        es: 'Cantidad Mínima',
        ru: 'Минимальное количество',
        fr: 'Quantité Minimale',
        de: 'Mindestmenge'
    },
    'Max Quantity': {
        pt: 'Quantidade Máxima',
        es: 'Cantidad Máxima',
        ru: 'Максимальное количество',
        fr: 'Quantité Maximale',
        de: 'Maximalmenge'
    },
    'Please enter the minimum quantity': {
        pt: 'Por favor, insira a quantidade mínima',
        es: 'Por favor, ingrese la cantidad mínima',
        ru: 'Пожалуйста, введите минимальное количество',
        fr: 'Veuillez saisir la quantité minimale',
        de: 'Bitte geben Sie die Mindestmenge ein'
    },
    'Min Restocking Price': {
        pt: 'Preço Mínimo de Reabastecimento',
        es: 'Precio Mínimo de Reabastecimiento',
        ru: 'Минимальная цена пополнения',
        fr: 'Prix Minimum de Réapprovisionnement',
        de: 'Minimaler Nachfüllpreis'
    },
    'Max Restocking Price': {
        pt: 'Preço Máximo de Reabastecimento',
        es: 'Precio Máximo de Reabastecimiento',
        ru: 'Максимальная цена пополнения',
        fr: 'Prix Maximum de Réapprovisionnement',
        de: 'Maximaler Nachfüllpreis'
    },
    'Opportunistic Order Level': {
        pt: 'Nível de Pedido Oportuno',
        es: 'Nivel de Pedido Oportunista',
        ru: 'Уровень оппортунистического заказа',
        fr: 'Niveau de Commande Opportuniste',
        de: 'Opportunistisches Bestellniveau'
    },
    'Where to Order': {
        pt: 'Onde Pedir',
        es: 'Dónde Ordenar',
        ru: 'Где заказать',
        fr: 'Où Commander',
        de: 'Wo Bestellen'
    },
    'Delivery Timing Preferences': {
        pt: 'Preferências de Horário de Entrega',
        es: 'Preferencias de Tiempo de Entrega',
        ru: 'Предпочтения по времени доставки',
        fr: 'Préférences de Délai de Livraison',
        de: 'Lieferzeiten-Präferenzen'
    },
    'Restocking Notes': {
        pt: 'Notas de Reabastecimento',
        es: 'Notas de Reabastecimiento',
        ru: 'Примечания к пополнению',
        fr: 'Notes de Réapprovisionnement',
        de: 'Nachfüllnotizen'
    },
    '$36/yr. Typically pays back in 2 jobs. Cancel anytime.': {
        pt: '$36/ano. Geralmente se paga em 2 trabalhos. Cancele quando quiser.',
        es: '$36/año. Normalmente se recupera en 2 trabajos. Cancela cuando quieras.',
        ru: '$36/год. Обычно окупается за 2 работы. Отмена в любое время.',
        fr: '$36/an. Se rentabilise généralement en 2 travaux. Annulez quand vous voulez.',
        de: '$36/Jahr. Amortisiert sich typischerweise nach 2 Aufträgen. Jederzeit kündbar.'
    },
    '$36/yr. Typically pays back in 2 jobs.': {
        pt: '$36/ano. Geralmente se paga em 2 trabalhos.',
        es: '$36/año. Normalmente se recupera en 2 trabajos.',
        ru: '$36/год. Обычно окупается за 2 работы.',
        fr: '$36/an. Se rentabilise généralement en 2 travaux.',
        de: '$36/Jahr. Amortisiert sich typischerweise nach 2 Aufträgen.'
    },
    'Cancel anytime.': {
        pt: 'Cancele quando quiser.',
        es: 'Cancela cuando quieras.',
        ru: 'Отмена в любое время.',
        fr: 'Annulez quand vous voulez.',
        de: 'Jederzeit kündbar.'
    },
    'Save 20%': {
        pt: 'Economize 20%',
        es: 'Ahorra 20%',
        ru: 'Экономия 20%',
        fr: 'Économisez 20%',
        de: 'Sparen Sie 20%'
    },
    '20% off on all bookings made with TIDY.': {
        pt: '20% de desconto em todas as reservas feitas com TIDY.',
        es: '20% de descuento en todas las reservas hechas con TIDY.',
        ru: '20% скидка на все бронирования через TIDY.',
        fr: '20% de réduction sur toutes les réservations effectuées avec TIDY.',
        de: '20% Rabatt auf alle Buchungen mit TIDY.'
    },
    'Powerful Software': {
        pt: 'Software Poderoso',
        es: 'Software Potente',
        ru: 'Мощное Программное Обеспечение',
        fr: 'Logiciel Puissant',
        de: 'Leistungsstarke Software'
    },
    'Software to manage your rental\'s cleaning and maintenance.': {
        pt: 'Software para gerenciar a limpeza e manutenção do seu aluguel.',
        es: 'Software para gestionar la limpieza y mantenimiento de su alquiler.',
        ru: 'Программное обеспечение для управления уборкой и обслуживанием вашей аренды.',
        fr: 'Logiciel pour gérer le nettoyage et l\'entretien de votre location.',
        de: 'Software zur Verwaltung der Reinigung und Wartung Ihrer Vermietung.'
    },
    'Advanced Automations': {
        pt: 'Automações Avançadas',
        es: 'Automatizaciones Avanzadas',
        ru: 'Продвинутые Автоматизации',
        fr: 'Automatisations Avancées',
        de: 'Erweiterte Automatisierungen'
    },
    'Use our AI and human concierge to solve tasks for you.': {
        pt: 'Use nossa IA e concierge humano para resolver tarefas para você.',
        es: 'Use nuestra IA y conserje humano para resolver tareas por usted.',
        ru: 'Используйте наш ИИ и человека-консьержа для решения задач.',
        fr: 'Utilisez notre IA et concierge humain pour résoudre vos tâches.',
        de: 'Nutzen Sie unsere KI und menschlichen Concierge zur Lösung Ihrer Aufgaben.'
    },
    'Add TIDY+ to Checkout': {
        pt: 'Adicionar TIDY+ ao Checkout',
        es: 'Agregar TIDY+ al Pago',
        ru: 'Добавить TIDY+ к Оформлению',
        fr: 'Ajouter TIDY+ au Paiement',
        de: 'TIDY+ zum Checkout hinzufügen'
    },
    'No Thanks': {
        pt: 'Não, Obrigado',
        es: 'No, Gracias',
        ru: 'Нет, Спасибо',
        fr: 'Non Merci',
        de: 'Nein Danke'
    },
    ' /unit/mo': {
        pt: ' /unidade/mês',
        es: ' /unidad/mes',
        ru: ' /объект/месяц',
        fr: ' /unité/mois',
        de: ' /Einheit/Monat'
    },
    ' /unit/year': {
        pt: '/unidade/ano',
        es: '/unidad/año',
        ru: '/объект/год',
        fr: '/unité/an',
        de: '/Einheit/Jahr'
    },

    '<b>Featured</b>': {
        pt: '<b>Destacado</b>',
        es: '<b>Destacado</b>',
        ru: '<b>Рекомендуемые</b>',
        fr: '<b>Recommandé</b>',
        de: '<b>Empfohlen</b>'
    },
    '<b>All Integrations</b>': {
        pt: '<b>Todas as Integrações</b>',
        es: '<b>Todas las Integraciones</b>',
        ru: '<b>Все интеграции</b>',
        fr: '<b>Toutes les intégrations</b>',
        de: '<b>Alle Integrations</b>'
    },

    ': Direct Integration': {
        pt: ': Integração Direta',
        es: ': Integración Directa',
        ru: ': Прямая интеграция',
        fr: ': Intégration Directe',
        de: ': Direkte Integration'
    },
    ': Concierge Integration': {
        pt: ': Integração de Concierge',
        es: ': Integración de Concierge',
        ru: ': Интеграция Concierge',
        fr: ': Intégration de Concierge',
        de: ': Concierge-Integration'
    },
    ': iCal Integration': {
        pt: ': Integração iCal',
        es: ': Integración iCal',
        ru: ': Интеграция iCal',
        fr: ': Intégration iCal',
        de: ': iCal-Integration'
    },

    'Team: ': {
        pt: 'Equipe: ',
        es: 'Equipo: ',
        ru: 'Команда: ',
        fr: 'Équipe: ',
        de: 'Team: '
    },
    '<u>jobs</u>.': {
        pt: '<u>trabalhos</u>.',
        es: '<u>trabajos</u>.',
        ru: '<u>работы</u>.',
        fr: '<u>travaux</u>.',
        de: '<u>Aufträge</u>.'
    },
    '<u>maintenances</u>.': {
        pt: '<u>manutenções</u>.',
        es: '<u>mantenimientos</u>.',
        ru: '<u>обслуживание</u>.',
        fr: '<u>entretiens</u>.',
        de: '<u>Wartungen</u>.'
    },
    '<u>reservations</u>.': {
        pt: '<u>reservas</u>.',
        es: '<u>reservas</u>.',
        ru: '<u>бронирования</u>.',
        fr: '<u>réservations</u>.',
        de: '<u>Reservierungen</u>.'
    },
    'Error loading report': {
        pt: 'Erro ao carregar relatório',
        es: 'Error al cargar informe',
        ru: 'Ошибка загрузки отчета',
        fr: 'Erreur de chargement du rapport',
        de: 'Fehler beim Laden des Berichts'
    },
    'This means pros are only allowed to accept a job same day as checkout. If they can\'t do it then, your job request will go to other pros following your priorities. This is recommended only if you commonly get last minute bookings within 1 day of a prior booking, and are ok with not getting the pro you prefer. ': {
        pt: 'Isso significa que os profissionais só podem aceitar um trabalho no mesmo dia do checkout. Se eles não puderem fazê-lo, sua solicitação de trabalho irá para outros profissionais seguindo suas prioridades. Isso é recomendado apenas se você normalmente recebe reservas de última hora dentro de 1 dia de uma reserva anterior e está ok em não conseguir o profissional que prefere. ',
        es: 'Esto significa que los profesionales solo pueden aceptar un trabajo el mismo día de la salida. Si no pueden hacerlo, su solicitud de trabajo irá a otros profesionales siguiendo sus prioridades. Esto se recomienda solo si comúnmente recibe reservas de último minuto dentro de 1 día de una reserva anterior y está de acuerdo con no obtener el profesional que prefiere. ',
        ru: 'Это означает, что профессионалы могут принять работу только в день выезда. Если они не могут этого сделать, ваш запрос на работу перейдет к другим профессионалам в соответствии с вашими приоритетами. Это рекомендуется только если вы часто получаете бронирования в последнюю минуту в течение 1 дня после предыдущего бронирования и вас устраивает, что вы не получите предпочитаемого профессионала. ',
        fr: 'Cela signifie que les pros ne peuvent accepter un travail que le jour du départ. S\'ils ne peuvent pas le faire, votre demande de travail ira à d\'autres pros selon vos priorités. Ceci est recommandé uniquement si vous recevez couramment des réservations de dernière minute dans la journée suivant une réservation précédente et si cela ne vous dérange pas de ne pas avoir le pro que vous préférez. ',
        de: 'Das bedeutet, dass Profis einen Auftrag nur am Tag des Check-outs annehmen können. Wenn sie es dann nicht können, geht Ihre Auftragsanfrage an andere Profis entsprechend Ihrer Prioritäten. Dies wird nur empfohlen, wenn Sie häufig Last-Minute-Buchungen innerhalb von 1 Tag nach einer vorherigen Buchung erhalten und damit einverstanden sind, nicht den bevorzugten Profi zu bekommen. '
    },
    'When a reservation is synced, automatically add a cleaning for your pros. Cancel anytime. ': {
        pt: 'Quando uma reserva é sincronizada, adicione automaticamente uma limpeza para seus profissionais. Cancele a qualquer momento. ',
        es: 'Cuando se sincroniza una reserva, agregue automáticamente una limpieza para sus profesionales. Cancele en cualquier momento. ',
        ru: 'Когда бронирование синхронизируется, автоматически добавляйте уборку для ваших профессионалов. Отмените в любое время. ',
        fr: 'Lorsqu\'une réservation est synchronisée, ajoutez automatiquement un nettoyage pour vos pros. Annulez à tout moment. ',
        de: 'Wenn eine Reservierung synchronisiert wird, fügen Sie automatisch eine Reinigung für Ihre Profis hinzu. Jederzeit kündbar. '
    },
    '(auto assign)': {
        pt: '(atribuição automática)',
        es: '(asignación automática)',
        ru: '(автоназначение)',
        fr: '(attribution automatique)',
        de: '(automatische Zuweisung)'
    },
    'How much time before the job should we notify all pros?': {
        pt: 'Quanto tempo antes do trabalho devemos notificar todos os profissionais?',
        es: '¿Cuánto tiempo antes del trabajo debemos notificar a todos los profesionales?',
        ru: 'За сколько времени до работы мы должны уведомить всех профессионалов?',
        fr: 'Combien de temps avant le travail devons-nous notifier tous les pros?',
        de: 'Wie lange vor dem Auftrag sollen wir alle Profis benachrichtigen?'
    },
    'What % of the time before the job should we notify all pros?': {
        pt: 'Qual % do tempo antes do trabalho devemos notificar todos os profissionais?',
        es: '¿Qué % del tiempo antes del trabajo debemos notificar a todos los profesionales?',
        ru: 'За какой % времени до работы мы должны уведомить всех профессионалов?',
        fr: 'Quel % du temps avant le travail devons-nous notifier tous les pros?',
        de: 'Welcher % der Zeit vor dem Auftrag sollen wir alle Profis benachrichtigen?'
    },
    'For <u>': {
        pt: 'Para <u>',
        es: 'Para <u>',
        ru: 'Для <u>',
        fr: 'Pour <u>',
        de: 'Für <u>'
    },
    'Pro removed from list': {
        pt: 'Profissional removido da lista',
        es: 'Profesional eliminado de la lista',
        ru: 'Профессионал удален из списка',
        fr: 'Pro supprimé de la liste',
        de: 'Profi von der Liste entfernt'
    },
    'You already requested this feature. TIDY will let you know when this feature is available for you.': {
        pt: 'Você já solicitou este recurso. O TIDY avisará quando este recurso estiver disponível para você.',
        es: 'Ya solicitó esta función. TIDY le avisará cuando esta función esté disponible para usted.',
        ru: 'Вы уже запросили эту функцию. TIDY сообщит вам, когда эта функция станет доступной для вас.',
        fr: 'Vous avez déjà demandé cette fonctionnalité. TIDY vous informera lorsque cette fonctionnalité sera disponible pour vous.',
        de: 'Sie haben diese Funktion bereits angefordert. TIDY wird Sie benachrichtigen, wenn diese Funktion für Sie verfügbar ist.'
    },
    'hour': {
        pt: 'hora',
        es: 'hora',
        ru: 'час',
        fr: 'heure',
        de: 'Stunde'
    },
    'We\'ll notify everyone on your list at least ': {
        pt: 'Notificaremos todos na sua lista pelo menos ',
        es: 'Notificaremos a todos en su lista al menos ',
        ru: 'Мы уведомим всех в вашем списке как минимум за ',
        fr: 'Nous notifierons tout le monde sur votre liste au moins ',
        de: 'Wir werden alle auf Ihrer Liste mindestens '
    },
    ' hours before the job starts or ': {
        pt: ' horas antes do início do trabalho ou ',
        es: ' horas antes de que comience el trabajo o ',
        ru: ' часов до начала работы или ',
        fr: ' heures avant le début du travail ou ',
        de: ' Stunden vor Arbeitsbeginn oder '
    },
    '% of the time between your request time and the job start time, whichever is first.': {
        pt: '% do tempo entre sua solicitação e o início do trabalho, o que ocorrer primeiro.',
        es: '% del tiempo entre su solicitud y el inicio del trabajo, lo que ocurra primero.',
        ru: '% времени между вашим запросом и началом работы, что наступит раньше.',
        fr: '% du temps entre votre demande et le début du travail, selon la première éventualité.',
        de: '% der Zeit zwischen Ihrer Anfrage und dem Arbeitsbeginn, je nachdem, was zuerst eintritt.'
    },
    'Find New Pros is disabled ': {
        pt: 'Encontrar Novos Profissionais está desativado ',
        es: 'Encontrar Nuevos Profesionales está desactivado ',
        ru: 'Поиск Новых Профессионалов отключен ',
        fr: 'Trouver de Nouveaux Pros est désactivé ',
        de: 'Neue Profis finden ist deaktiviert '
    },

    'Take Inventory': {
        pt: 'Fazer Inventário',
        es: 'Hacer Inventario',
        ru: 'Провести Инвентаризацию',
        fr: 'Faire l\'Inventaire',
        de: 'Inventur Durchführen'
    },
    'Checkbox(es': {
        pt: 'Caixa(s) de Seleção',
        es: 'Casilla(s) de Verificación',
        ru: 'Флажок(и)',
        fr: 'Case(s) à Cocher',
        de: 'Kontrollkästchen'
    },
    'Take Photos': {
        pt: 'Tirar Fotos',
        es: 'Tomar Fotos',
        ru: 'Сделать Фотографии',
        fr: 'Prendre des Photos',
        de: 'Fotos Aufnehmen'
    },
    'Do Not': {
        pt: 'Não Faça',
        es: 'No',
        ru: 'Не',
        fr: 'Ne Pas',
        de: 'Nicht'
    },
    'All your To-Do lists have rooms specific to a property so cannot be copied to other properties. Create a To-Do list with no property specific rooms to copy it.': {
        pt: 'Todas as suas listas de tarefas têm cômodos específicos para uma propriedade e não podem ser copiadas para outras propriedades. Crie uma lista de tarefas sem cômodos específicos de propriedade para copiá-la.',
        es: 'Todas sus listas de tareas tienen habitaciones específicas para una propiedad y no se pueden copiar a otras propiedades. Cree una lista de tareas sin habitaciones específicas de propiedad para copiarla.',
        ru: 'Все ваши списки дел имеют комнаты, специфичные для объекта, поэтому их нельзя скопировать в другие объекты. Создайте список дел без комнат, специфичных для объекта, чтобы скопировать его.',
        fr: 'Toutes vos listes de tâches ont des pièces spécifiques à une propriété et ne peuvent donc pas être copiées vers d\'autres propriétés. Créez une liste de tâches sans pièces spécifiques à la propriété pour la copier.',
        de: 'Alle Ihre To-Do-Listen haben raumspezifische Einträge für eine Immobilie und können daher nicht auf andere Immobilien kopiert werden. Erstellen Sie eine To-Do-Liste ohne immobilienspezifische Räume, um sie zu kopieren.'
    },
    'Guest Smart #: ': {
        pt: 'Nº Smart do Hóspede: ',
        es: 'Nº Smart del Huésped: ',
        ru: 'Смарт № Гостя: ',
        fr: 'N° Smart de l\'Invité: ',
        de: 'Gast Smart-Nr.: '
    },
    'Pro Smart #: ': {
        pt: 'Nº Smart do Profissional: ',
        es: 'Nº Smart del Profesional: ',
        ru: 'Смарт № Профессионала: ',
        fr: 'N° Smart du Pro: ',
        de: 'Profi Smart-Nr.: '
    },
    'Starts ': {
        pt: 'Começa ',
        es: 'Comienza ',
        ru: 'Начинается ',
        fr: 'Commence ',
        de: 'Beginnt '
    },
    'Proposal': {
        pt: 'Proposta',
        es: 'Propuesta',
        ru: 'Предложение',
        fr: 'Proposition',
        de: 'Angebot'
    },
    'Book a': {
        pt: 'Reservar um',
        es: 'Reservar un',
        ru: 'Забронировать',
        fr: 'Réserver un',
        de: 'Buchen Sie einen'
    },
    'with': {
        pt: 'com',
        es: 'con',
        ru: 'с',
        fr: 'avec',
        de: 'mit'
    },
    'Search team': {
        pt: 'Pesquisar equipe',
        es: 'Buscar equipo',
        ru: 'Поиск команды',
        fr: 'Rechercher équipe',
        de: 'Team suchen'
    },
    'Service Category': {
        pt: 'Categoria de Serviço',
        es: 'Categoría de Servicio',
        ru: 'Категория Услуги',
        fr: 'Catégorie de Service',
        de: 'Servicekategorie'
    },
    'Relate the task to an item?': {
        pt: 'Relacionar a tarefa a um item?',
        es: '¿Relacionar la tarea con un elemento?',
        ru: 'Связать задачу с элементом?',
        fr: 'Lier la tâche à un élément?',
        de: 'Aufgabe mit einem Element verknüpfen?'
    },
    'Share': {
        pt: 'Compartilhar',
        es: 'Compartir',
        ru: 'Поделиться',
        fr: 'Partager',
        de: 'Teilen'
    },
    'Priority List': {
        pt: 'Lista de Prioridades',
        es: 'Lista de Prioridad',
        ru: 'Список Приоритетов',
        fr: 'Liste de Priorités',
        de: 'Prioritätenliste'
    },
    'Edit ': {
        pt: 'Editar ',
        es: 'Editar ',
        ru: 'Редактировать ',
        fr: 'Modifier ',
        de: 'Bearbeiten '
    },
    'Please enter their first name.': {
        pt: 'Por favor, insira o primeiro nome.',
        es: 'Por favor, ingrese su nombre.',
        ru: 'Пожалуйста, введите их имя.',
        fr: 'Veuillez entrer leur prénom.',
        de: 'Bitte geben Sie ihren Vornamen ein.'
    },
    'Please enter their last name.': {
        pt: 'Por favor, insira o sobrenome.',
        es: 'Por favor, ingrese su apellido.',
        ru: 'Пожалуйста, введите их фамилию.',
        fr: 'Veuillez entrer leur nom de famille.',
        de: 'Bitte geben Sie ihren Nachnamen ein.'
    },
    'Address name': {
        pt: 'Nome do endereço',
        es: 'Nombre de la dirección',
        ru: 'Название адреса',
        fr: 'Nom de l\'adresse',
        de: 'Adressname'
    },
    'Earliest start time (typically the check out time)': {
        pt: 'Hora mais cedo para início (normalmente a hora do check-out)',
        es: 'Hora más temprana de inicio (típicamente la hora de salida)',
        ru: 'Самое раннее время начала (обычно время выезда)',
        fr: 'Heure de début au plus tôt (généralement l\'heure de départ)',
        de: 'Früheste Startzeit (normalerweise die Check-out-Zeit)'
    },
    'Latest finish time (typically the check in time)': {
        pt: 'Hora mais tarde para término (normalmente a hora do check-in)',
        es: 'Hora más tardía de finalización (típicamente la hora de entrada)',
        ru: 'Самое позднее время окончания (обычно время заезда)',
        fr: 'Heure de fin au plus tard (généralement l\'heure d\'arrivée)',
        de: 'Späteste Endzeit (normalerweise die Check-in-Zeit)'
    },
    'Create job requests no earlier than': {
        pt: 'Criar pedidos de trabalho não antes de',
        es: 'Crear solicitudes de trabajo no antes de',
        ru: 'Создавать заявки на работу не ранее чем',
        fr: 'Créer des demandes de travail pas avant',
        de: 'Arbeitsaufträge nicht früher erstellen als'
    },
    'day': {
        pt: 'dia',
        es: 'día',
        ru: 'день',
        fr: 'jour',
        de: 'Tag'
    },
    'days before check in': {
        pt: 'dias antes do check-in',
        es: 'días antes del check-in',
        ru: 'дней до заезда',
        fr: 'jours avant l\'arrivée',
        de: 'Tage vor Check-in'
    },
    'day before check in': {
        pt: 'dia antes do check-in',
        es: 'día antes del check-in',
        ru: 'день до заезда',
        fr: 'jour avant l\'arrivée',
        de: 'Tag vor Check-in'
    },

    'Getting Started': {
        pt: 'Começando',
        es: 'Empezando',
        ru: 'Начало работы',
        fr: 'Premiers pas',
        de: 'Erste Schritte'
    },
    'TIDY AI': {
        pt: 'TIDY AI',
        es: 'TIDY AI',
        ru: 'TIDY AI',
        fr: 'TIDY AI',
        de: 'TIDY AI'
    },
    'Teams & Users': {
        pt: 'Equipes e Usuários',
        es: 'Equipos y Usuarios',
        ru: 'Команды и пользователи',
        fr: 'Équipes et utilisateurs',
        de: 'Teams und Benutzer'
    },
    'Properties/Addresses': {
        pt: 'Propriedades/Endereços',
        es: 'Propiedades/Direcciones',
        ru: 'Объекты/Адреса',
        fr: 'Propriétés/Adresses',
        de: 'Immobilien/Adressen'
    },
    'Access Control / Smartlocks': {
        pt: 'Controle de Acesso / Fechaduras Inteligentes',
        es: 'Control de Acceso / Cerraduras Inteligentes',
        ru: 'Контроль доступа / Умные замки',
        fr: 'Contrôle d\'accès / Serrures connectées',
        de: 'Zugangskontrolle / Smart Locks'
    },
    'Billing, Pricing, & Payments': {
        pt: 'Faturamento, Preços e Pagamentos',
        es: 'Facturación, Precios y Pagos',
        ru: 'Биллинг, цены и платежи',
        fr: 'Facturation, prix et paiements',
        de: 'Abrechnung, Preise & Zahlungen'
    },
    'Compliance, Ratings, Safety, Insurance, & Protection': {
        pt: 'Conformidade, Avaliações, Segurança, Seguros e Proteção',
        es: 'Cumplimiento, Calificaciones, Seguridad, Seguros y Protección',
        ru: 'Соответствие, рейтинги, безопасность, страхование и защита',
        fr: 'Conformité, évaluations, sécurité, assurance et protection',
        de: 'Compliance, Bewertungen, Sicherheit, Versicherung & Schutz'
    },
    'Property Maps': {
        pt: 'Mapas de Propriedades',
        es: 'Mapas de Propiedades',
        ru: 'Карты объектов',
        fr: 'Plans des propriétés',
        de: 'Immobilienkarten'
    },
    'Inventory Tracking': {
        pt: 'Controle de Inventário',
        es: 'Seguimiento de Inventario',
        ru: 'Учет инвентаря',
        fr: 'Suivi des stocks',
        de: 'Bestandsverfolgung'
    },
    'Inspections': {
        pt: 'Inspeções',
        es: 'Inspecciones',
        ru: 'Проверки',
        fr: 'Inspections',
        de: 'Inspektionen'
    },
    'Automatic Translation': {
        pt: 'Tradução Automática',
        es: 'Traducción Automática',
        ru: 'Автоматический перевод',
        fr: 'Traduction automatique',
        de: 'Automatische Übersetzung'
    },
    'Other Questions': {
        pt: 'Outras Perguntas',
        es: 'Otras Preguntas',
        ru: 'Другие вопросы',
        fr: 'Autres questions',
        de: 'Andere Fragen'
    },
    'Best Practices': {
        pt: 'Melhores Práticas',
        es: 'Mejores Prácticas',
        ru: 'Лучшие практики',
        fr: 'Meilleures pratiques',
        de: 'Best Practices'
    },
    'Changelog': {
        pt: 'Registro de alterações',
        es: 'Registro de cambios',
        ru: 'История изменений',
        fr: 'Journal des modifications',
        de: 'Änderungsprotokoll'
    },
    'App Branding': {
        pt: 'Marca do Aplicativo',
        es: 'Marca de la Aplicación',
        ru: 'Брендинг приложения',
        fr: 'Image de marque de l\'application',
        de: 'App-Branding'
    },
    'Gift Card': {
        pt: 'Cartão Presente',
        es: 'Tarjeta de Regalo',
        ru: 'Подарочная карта',
        fr: 'Carte cadeau',
        de: 'Geschenkkarte'
    },
    'schedule job': {
        pt: 'agendar trabalho',
        es: 'programar trabajo',
        ru: 'запланировать работу',
        fr: 'planifier le travail',
        de: 'Auftrag planen'
    },
    'My Pro Settings': {
        pt: 'Minhas Configurações Pro',
        es: 'Mi Configuración Pro',
        ru: 'Мои настройки Pro',
        fr: 'Mes paramètres Pro',
        de: 'Meine Pro-Einstellungen'
    },
    'No results for: ': {
        pt: 'Sem resultados para: ',
        es: 'Sin resultados para: ',
        ru: 'Нет результатов для: ',
        fr: 'Aucun résultat pour : ',
        de: 'Keine Ergebnisse für: '
    },
    'EMPLOYEES': {
        pt: 'FUNCIONÁRIOS',
        es: 'EMPLEADOS',
        ru: 'СОТРУДНИКИ',
        fr: 'EMPLOYÉS',
        de: 'MITARBEITER'
    },
    'cost': {
        pt: 'custo',
        es: 'costo',
        ru: 'стоимость',
        fr: 'coût',
        de: 'Kosten'
    },
    'payment': {
        pt: 'pagamento',
        es: 'pago',
        ru: 'оплата',
        fr: 'paiement',
        de: 'Zahlung'
    },
    'credit card': {
        pt: 'cartão de crédito',
        es: 'tarjeta de crédito',
        ru: 'кредитная карта',
        fr: 'carte de crédit',
        de: 'Kreditkarte'
    },
    'billing': {
        pt: 'faturamento',
        es: 'facturación',
        ru: 'выставление счета',
        fr: 'facturation',
        de: 'Abrechnung'
    },
    'contact support': {
        pt: 'contatar suporte',
        es: 'contactar soporte',
        ru: 'связаться с поддержкой',
        fr: 'contacter le support',
        de: 'Support kontaktieren'
    },
    'help': {
        pt: 'ajuda',
        es: 'ayuda',
        ru: 'помощь',
        fr: 'aide',
        de: 'Hilfe'
    },
    'todo': {
        pt: 'tarefa',
        es: 'tarea',
        ru: 'задача',
        fr: 'tâche',
        de: 'Aufgabe'
    },
    'to do': {
        pt: 'a fazer',
        es: 'por hacer',
        ru: 'сделать',
        fr: 'à faire',
        de: 'zu erledigen'
    },
    'List Settings': {
        pt: 'Configurações da Lista',
        es: 'Configuración de la Lista',
        ru: 'Настройки Списка',
        fr: 'Paramètres de la Liste',
        de: 'Listeneinstellungen'
    },

    'Please enter the rule.': {
        pt: 'Por favor, insira a regra.',
        es: 'Por favor, ingrese la regla.',
        ru: 'Пожалуйста, введите правило.',
        fr: 'Veuillez entrer la règle.',
        de: 'Bitte geben Sie die Regel ein.'
    },
    'Floor 1': {
        pt: 'Andar 1',
        es: 'Piso 1',
        ru: 'Этаж 1',
        fr: 'Étage 1',
        de: 'Stock 1'
    },
    'Floor 2': {
        pt: 'Andar 2',
        es: 'Piso 2',
        ru: 'Этаж 2',
        fr: 'Étage 2',
        de: 'Stock 2'
    },
    'Floor 3': {
        pt: 'Andar 3',
        es: 'Piso 3',
        ru: 'Этаж 3',
        fr: 'Étage 3',
        de: 'Stock 3'
    },
    'Floor 4': {
        pt: 'Andar 4',
        es: 'Piso 4',
        ru: 'Этаж 4',
        fr: 'Étage 4',
        de: 'Stock 4'
    },

    'Bedroom': {
        pt: 'Quarto',
        es: 'Dormitorio',
        ru: 'Спальня',
        fr: 'Chambre',
        de: 'Schlafzimmer'
    },
    'Back Yard': {
        pt: 'Quintal',
        es: 'Patio Trasero',
        ru: 'Задний двор',
        fr: 'Arrière-cour',
        de: 'Hinterhof'
    },
    'Living Room / Family Room': {
        pt: 'Sala de Estar / Sala de Família',
        es: 'Sala de Estar / Sala Familiar',
        ru: 'Гостиная / Семейная комната',
        fr: 'Salon / Salle Familiale',
        de: 'Wohnzimmer / Familienzimmer'
    },
    'Front Yard': {
        pt: 'Jardim da Frente',
        es: 'Patio Delantero',
        ru: 'Передний двор',
        fr: 'Cour Avant',
        de: 'Vorgarten'
    },
    'Dining Room': {
        pt: 'Sala de Jantar',
        es: 'Comedor',
        ru: 'Столовая',
        fr: 'Salle à Manger',
        de: 'Esszimmer'
    },
    'Stairwell': {
        pt: 'Escadaria',
        es: 'Escalera',
        ru: 'Лестничная клетка',
        fr: 'Cage d\'escalier',
        de: 'Treppenhaus'
    },
    'Hallway': {
        pt: 'Corredor',
        es: 'Pasillo',
        ru: 'Коридор',
        fr: 'Couloir',
        de: 'Flur'
    },
    'Garage': {
        pt: 'Garagem',
        es: 'Garaje',
        ru: 'Гараж',
        fr: 'Garage',
        de: 'Garage'
    },
    'Patio': {
        pt: 'Pátio',
        es: 'Patio',
        ru: 'Патио',
        fr: 'Terrasse',
        de: 'Terrasse'
    },

    'Require Custom Fields': {
        pt: 'Exigir Campos Personalizados',
        es: 'Requerir Campos Personalizados',
        ru: 'Требовать Пользовательские Поля',
        fr: 'Exiger des Champs Personnalisés',
        de: 'Benutzerdefinierte Felder Erforderlich'
    },
    'Require the pro to enter inputs into all your custom fields to complete the job. If disabled the pro will not be required to enter any inputs but will see an alert if they are skipping any fields you indicated are required.': {
        pt: 'Exigir que o profissional preencha todos os seus campos personalizados para concluir o trabalho. Se desativado, o profissional não será obrigado a inserir nenhuma entrada, mas verá um alerta se estiver pulando campos que você indicou como obrigatórios.',
        es: 'Requerir que el profesional ingrese datos en todos sus campos personalizados para completar el trabajo. Si está deshabilitado, el profesional no estará obligado a ingresar ningún dato, pero verá una alerta si omite campos que indicó como obligatorios.',
        ru: 'Требовать от специалиста заполнения всех ваших пользовательских полей для завершения работы. Если отключено, специалист не будет обязан вводить какие-либо данные, но увидит предупреждение, если пропустит поля, которые вы указали как обязательные.',
        fr: 'Exiger que le professionnel remplisse tous vos champs personnalisés pour terminer le travail. Si désactivé, le professionnel ne sera pas obligé de saisir des données mais verra une alerte s\'il saute des champs que vous avez indiqués comme obligatoires.',
        de: 'Der Profi muss alle Ihre benutzerdefinierten Felder ausfüllen, um den Auftrag abzuschließen. Wenn deaktiviert, muss der Profi keine Eingaben machen, sieht aber eine Warnung, wenn er Felder überspringt, die Sie als erforderlich markiert haben.'
    },
    'Loading...': {
        pt: 'Carregando...',
        es: 'Cargando...',
        ru: 'Загрузка...',
        fr: 'Chargement...',
        de: 'Laden...'
    },
    'Send to': {
        pt: 'Enviar para',
        es: 'Enviar a',
        ru: 'Отправить',
        fr: 'Envoyer à',
        de: 'Senden an'
    },
    ' can see this job in the <u>TIDY Pro App</u> if they use their email on file. You can also share a link to the job with them:': {
        pt: ' pode ver este trabalho no <u>TIDY Pro App</u> se usar o email registrado. Você também pode compartilhar um link do trabalho com eles:',
        es: ' puede ver este trabajo en la <u>TIDY Pro App</u> si usa su correo electrónico registrado. También puede compartir un enlace al trabajo con ellos:',
        ru: ' может увидеть эту работу в <u>TIDY Pro App</u>, если использует зарегистрированную электронную почту. Вы также можете поделиться с ними ссылкой на работу:',
        fr: ' peut voir ce travail dans l\'<u>TIDY Pro App</u> s\'ils utilisent leur email enregistré. Vous pouvez également partager un lien vers le travail avec eux:',
        de: ' kann diesen Auftrag in der <u>TIDY Pro App</u> sehen, wenn sie ihre hinterlegte E-Mail verwenden. Sie können auch einen Link zum Auftrag mit ihnen teilen:'
    },
    'Assign an existing pro': {
        pt: 'Atribuir a um profissional existente',
        es: 'Asignar a un profesional existente',
        ru: 'Назначить существующего специалиста',
        fr: 'Assigner un professionnel existant',
        de: 'Einen existierenden Profi zuweisen'
    },
    'Add & assign a new pro': {
        pt: 'Adicionar e atribuir a um novo profissional',
        es: 'Agregar y asignar a un nuevo profesional',
        ru: 'Добавить и назначить нового специалиста',
        fr: 'Ajouter et assigner un nouveau professionnel',
        de: 'Neuen Profi hinzufügen und zuweisen'
    },
    'Send Link to ': {
        pt: 'Enviar Link para ',
        es: 'Enviar Enlace a ',
        ru: 'Отправить Ссылку ',
        fr: 'Envoyer le Lien à ',
        de: 'Link Senden an '
    },
    ' /unit/month': {
        pt: ' /unidade/mês',
        es: ' /unidad/mes',
        ru: ' /единица/месяц',
        fr: ' /unité/mois',
        de: ' /Einheit/Monat'
    },
    '/year': {
        pt: '/ano',
        es: '/año',
        ru: '/год',
        fr: '/an',
        de: '/Jahr'
    },
    '/month': {
        pt: '/mês',
        es: '/mes',
        ru: '/месяц',
        fr: '/mois',
        de: '/Monat'
    },
    'unit': {
        pt: 'unidade',
        es: 'unidad',
        ru: 'единица',
        fr: 'unité',
        de: 'Einheit'
    },
    'units': {
        pt: 'unidades',
        es: 'unidades',
        ru: 'единицы',
        fr: 'unités',
        de: 'Einheiten'
    },
    'Max Jobs Per Day': {
        pt: 'Máximo de Trabalhos Por Dia',
        es: 'Máximo de Trabajos Por Día',
        ru: 'Максимум Заданий в День',
        fr: 'Maximum de Tâches Par Jour',
        de: 'Maximale Aufträge Pro Tag'
    },
    'Regular Cleaning': {
        pt: 'Limpeza Regular',
        es: 'Limpieza Regular',
        ru: 'Регулярная Уборка',
        fr: 'Nettoyage Régulier',
        de: 'Regelmäßige Reinigung'
    },
    'Restock Price': {
        pt: 'Preço de Reabastecimento',
        es: 'Precio de Reabastecimiento',
        ru: 'Цена Пополнения',
        fr: 'Prix de Réapprovisionnement',
        de: 'Nachfüllpreis'
    },
    'Restock Price: $': {
        pt: 'Preço de Reabastecimento: $',
        es: 'Precio de Reabastecimiento: $',
        ru: 'Цена Пополнения: $',
        fr: 'Prix de Réapprovisionnement: $',
        de: 'Nachfüllpreis: $'
    },
    'Between guest stays': {
        pt: 'Entre estadias de hóspedes',
        es: 'Entre estadías de huéspedes',
        ru: 'Между пребыванием гостей',
        fr: 'Entre les séjours des invités',
        de: 'Zwischen Gästeaufenthalten'
    },
    'As soon as possible': {
        pt: 'O mais rápido possível',
        es: 'Lo antes posible',
        ru: 'Как можно скорее',
        fr: 'Dès que possible',
        de: 'So schnell wie möglich'
    },
    'Checkbox(es)': {
        pt: 'Caixa(s) de Seleção',
        es: 'Casilla(s) de Verificación',
        ru: 'Флажок(ки)',
        fr: 'Case(s) à Cocher',
        de: 'Kontrollkästchen'
    },
    'Text Area': {
        pt: 'Área de Texto',
        es: 'Área de Texto',
        ru: 'Текстовая Область',
        fr: 'Zone de Texte',
        de: 'Textbereich'
    },

    'Export ': {
        pt: 'Exportar ',
        es: 'Exportar ',
        ru: 'Экспорт ',
        fr: 'Exporter ',
        de: 'Exportieren '
    },
    'Replace all reservations': {
        pt: 'Substituir todas as reservas',
        es: 'Reemplazar todas las reservas',
        ru: 'Заменить все бронирования',
        fr: 'Remplacer toutes les réservations',
        de: 'Alle Reservierungen ersetzen'
    },
    'Import ': {
        pt: 'Importar ',
        es: 'Importar ',
        ru: 'Импорт ',
        fr: 'Importer ',
        de: 'Importieren '
    },
    'CSV Format': {
        pt: 'Formato CSV',
        es: 'Formato CSV',
        ru: 'Формат CSV',
        fr: 'Format CSV',
        de: 'CSV-Format'
    },
    'Replace reservations from all sources.': {
        pt: 'Substituir reservas de todas as fontes.',
        es: 'Reemplazar reservas de todas las fuentes.',
        ru: 'Заменить бронирования из всех источников.',
        fr: 'Remplacer les réservations de toutes les sources.',
        de: 'Reservierungen aus allen Quellen ersetzen.'
    },
    'Error checking for blocked jobs ': {
        pt: 'Erro ao verificar trabalhos bloqueados ',
        es: 'Error al verificar trabajos bloqueados ',
        ru: 'Ошибка при проверке заблокированных заданий ',
        fr: 'Erreur lors de la vérification des tâches bloquées ',
        de: 'Fehler beim Überprüfen blockierter Aufgaben '
    },
    'Please Enable Automatic Booking for This Property': {
        pt: 'Por favor, Ative a Reserva Automática para Esta Propriedade',
        es: 'Por favor, Active la Reserva Automática para Esta Propiedad',
        ru: 'Пожалуйста, Включите Автоматическое Бронирование для Этого Объекта',
        fr: 'Veuillez Activer la Réservation Automatique pour Cette Propriété',
        de: 'Bitte Aktivieren Sie die Automatische Buchung für Diese Immobilie'
    },
    'Please enable automatic booking for this property to turn it on for this reservation.': {
        pt: 'Ative a reserva automática para esta propriedade para ativá-la para esta reserva.',
        es: 'Active la reserva automática para esta propiedad para activarla para esta reserva.',
        ru: 'Включите автоматическое бронирование для этого объекта, чтобы активировать его для этой брони.',
        fr: 'Veuillez activer la réservation automatique pour cette propriété pour l\'activer pour cette réservation.',
        de: 'Bitte aktivieren Sie die automatische Buchung für diese Immobilie, um sie für diese Reservierung zu aktivieren.'
    },
    'View Automations': {
        pt: 'Ver Automações',
        es: 'Ver Automatizaciones',
        ru: 'Просмотр Автоматизаций',
        fr: 'Voir les Automatisations',
        de: 'Automatisierungen Anzeigen'
    },
    'Automatic booking updated successfully': {
        pt: 'Reserva automática atualizada com sucesso',
        es: 'Reserva automática actualizada con éxito',
        ru: 'Автоматическое бронирование успешно обновлено',
        fr: 'Réservation automatique mise à jour avec succès',
        de: 'Automatische Buchung erfolgreich aktualisiert'
    },
    'Enable automatic booking for this reservation': {
        pt: 'Ativar reserva automática para esta reserva',
        es: 'Activar reserva automática para esta reserva',
        ru: 'Включить автоматическое бронирование для этой брони',
        fr: 'Activer la réservation automatique pour cette réservation',
        de: 'Automatische Buchung für diese Reservierung aktivieren'
    },
    'Please select a user': {
        pt: 'Por favor, selecione um usuário',
        es: 'Por favor, seleccione un usuario',
        ru: 'Пожалуйста, выберите пользователя',
        fr: 'Veuillez sélectionner un utilisateur',
        de: 'Bitte wählen Sie einen Benutzer'
    },
    'Please select a team': {
        pt: 'Por favor, selecione uma equipe',
        es: 'Por favor, seleccione un equipo',
        ru: 'Пожалуйста, выберите команду',
        fr: 'Veuillez sélectionner une équipe',
        de: 'Bitte wählen Sie ein Team'
    },
    'Apt': {
        pt: 'Apto',
        es: 'Apto',
        ru: 'Кв',
        fr: 'Apt',
        de: 'Whg'
    },
    'Apartment': {
        pt: 'Apartamento',
        es: 'Apartamento',
        ru: 'Квартира',
        fr: 'Appartement',
        de: 'Wohnung'
    },
    'Suite': {
        pt: 'Suíte',
        es: 'Suite',
        ru: 'Люкс',
        fr: 'Suite',
        de: 'Suite'
    },
    'Flat': {
        pt: 'Apartamento',
        es: 'Piso',
        ru: 'Квартира',
        fr: 'Appartement',
        de: 'Wohnung'
    },
    '#': {
        pt: '#',
        es: '#',
        ru: '№',
        fr: '#',
        de: '#'
    },
    'Number': {
        pt: 'Número',
        es: 'Número',
        ru: 'Номер',
        fr: 'Numéro',
        de: 'Nummer'
    },
    'House': {
        pt: 'Casa',
        es: 'Casa',
        ru: 'Дом',
        fr: 'Maison',
        de: 'Haus'
    },
    'Delete Property': {
        pt: 'Excluir Propriedade',
        es: 'Eliminar Propiedad',
        ru: 'Удалить Объект',
        fr: 'Supprimer la Propriété',
        de: 'Immobilie Löschen'
    },
    ' More Job Message': {
        pt: ' Mais Mensagem de Trabalho',
        es: ' Más Mensaje de Trabajo',
        ru: ' Дополнительное Сообщение о Работе',
        fr: ' Plus de Message de Travail',
        de: ' Weitere Auftragsnachricht'
    },
    'View ': {
        pt: 'Visualizar ',
        es: 'Ver ',
        ru: 'Просмотр ',
        fr: 'Voir ',
        de: 'Ansehen '
    },
    'Uploaded by: ': {
        pt: 'Carregado por: ',
        es: 'Subido por: ',
        ru: 'Загружено: ',
        fr: 'Téléchargé par: ',
        de: 'Hochgeladen von: '
    },
    'Uploaded by: you': {
        pt: 'Carregado por: você',
        es: 'Subido por: usted',
        ru: 'Загружено: вами',
        fr: 'Téléchargé par: vous',
        de: 'Hochgeladen von: Ihnen'
    },
    'Share Job Link': {
        pt: 'Compartilhar Link do Trabalho',
        es: 'Compartir Enlace del Trabajo',
        ru: 'Поделиться Ссылкой на Работу',
        fr: 'Partager le Lien du Travail',
        de: 'Auftragslink Teilen'
    },
    'You can share this link to the job:': {
        pt: 'Você pode compartilhar este link para o trabalho:',
        es: 'Puede compartir este enlace al trabajo:',
        ru: 'Вы можете поделиться этой ссылкой на работу:',
        fr: 'Vous pouvez partager ce lien vers le travail:',
        de: 'Sie können diesen Link zum Auftrag teilen:'
    },
    'Send Link': {
        pt: 'Enviar Link',
        es: 'Enviar Enlace',
        ru: 'Отправить Ссылку',
        fr: 'Envoyer le Lien',
        de: 'Link Senden'
    },
    'Please enter a valid address': {
        pt: 'Por favor, insira um endereço válido',
        es: 'Por favor, ingrese una dirección válida',
        ru: 'Пожалуйста, введите действительный адрес',
        fr: 'Veuillez entrer une adresse valide',
        de: 'Bitte geben Sie eine gültige Adresse ein'
    },
    'Please enter a valid unit': {
        pt: 'Por favor, insira uma unidade válida',
        es: 'Por favor, ingrese una unidad válida',
        ru: 'Пожалуйста, введите действительный номер помещения',
        fr: 'Veuillez entrer une unité valide',
        de: 'Bitte geben Sie eine gültige Einheit ein'
    },
    'Integration(s)': {
        pt: 'Integração(ões)',
        es: 'Integración(es)',
        ru: 'Интеграция(и)',
        fr: 'Intégration(s)',
        de: 'Integration(en)'
    },
    'Job(s) to Book': {
        pt: 'Trabalho(s) para Reservar',
        es: 'Trabajo(s) para Reservar',
        ru: 'Работа(ы) для Бронирования',
        fr: 'Travail(s) à Réserver',
        de: 'Zu Buchende Aufträge'
    },
    'Remove Job': {
        pt: 'Remover Trabalho',
        es: 'Eliminar Trabajo',
        ru: 'Удалить Работу',
        fr: 'Supprimer le Travail',
        de: 'Auftrag Entfernen'
    },
    'Add Another Job': {
        pt: 'Adicionar Outro Trabalho',
        es: 'Agregar Otro Trabajo',
        ru: 'Добавить Другую Работу',
        fr: 'Ajouter un Autre Travail',
        de: 'Weiteren Auftrag Hinzufügen'
    },
    '*You can customize the job name per pro/property ': {
        pt: '*Você pode personalizar o nome do trabalho por profissional/propriedade ',
        es: '*Puede personalizar el nombre del trabajo por profesional/propiedad ',
        ru: '*Вы можете настроить название работы для каждого специалиста/объекта ',
        fr: '*Vous pouvez personnaliser le nom du travail par pro/propriété ',
        de: '*Sie können den Auftragsnamen pro Dienstleister/Immobilie anpassen '
    },
    'Job(s) Time Range': {
        pt: 'Intervalo de Tempo do(s) Trabalho(s)',
        es: 'Rango de Tiempo del(los) Trabajo(s)',
        ru: 'Временной Диапазон Работ(ы)',
        fr: 'Plage Horaire du(des) Travail(s)',
        de: 'Zeitspanne der Aufträge'
    },
    'to start no earlier than March 3,': {
        pt: 'para começar não antes de 3 de março,',
        es: 'para comenzar no antes del 3 de marzo,',
        ru: 'начало не ранее 3 марта,',
        fr: 'pour commencer pas avant le 3 mars,',
        de: 'beginnt nicht früher als am 3. März,'
    },
    'Job(s) Date Settings': {
        pt: 'Configurações de Data do(s) Trabalho(s)',
        es: 'Configuración de Fecha del(los) Trabajo(s)',
        ru: 'Настройки Даты Работ(ы)',
        fr: 'Paramètres de Date du(des) Travail(s)',
        de: 'Datumseinstellungen der Aufträge'
    },
    'All Workflows': {
        pt: 'Todos os Fluxos de Trabalho',
        es: 'Todos los Flujos de Trabajo',
        ru: 'Все Рабочие Процессы',
        fr: 'Tous les Flux de Travail',
        de: 'Alle Arbeitsabläufe'
    },
    'Allow Find New Pros': {
        pt: 'Permitir Encontrar Novos Profissionais',
        es: 'Permitir Encontrar Nuevos Profesionales',
        ru: 'Разрешить Поиск Новых Специалистов',
        fr: 'Autoriser la Recherche de Nouveaux Pros',
        de: 'Neue Dienstleister Suchen Erlauben'
    },
    'You can load credit via ACH debit or credit card ': {
        pt: 'Você pode carregar crédito via débito ACH ou cartão de crédito ',
        es: 'Puede cargar crédito mediante débito ACH o tarjeta de crédito ',
        ru: 'Вы можете пополнить счет через ACH дебет или кредитную карту ',
        fr: 'Vous pouvez charger du crédit via débit ACH ou carte de crédit ',
        de: 'Sie können Guthaben per ACH-Lastschrift oder Kreditkarte laden '
    },
    'Total Amount': {
        pt: 'Valor Total',
        es: 'Monto Total',
        ru: 'Общая Сумма',
        fr: 'Montant Total',
        de: 'Gesamtbetrag'
    },
    '+ Add New Item': {
        pt: '+ Adicionar Novo Item',
        es: '+ Agregar Nuevo Elemento',
        ru: '+ Добавить Новый Элемент',
        fr: '+ Ajouter Nouvel Élément',
        de: '+ Neuen Artikel Hinzufügen'
    },
    'Archive': {
        pt: 'Arquivar',
        es: 'Archivar',
        ru: 'Архивировать',
        fr: 'Archiver',
        de: 'Archivieren'
    },

    'day ago': {
        pt: 'dia atrás',
        es: 'día atrás',
        ru: 'день назад',
        fr: 'jour passé',
        de: 'Tag her'
    },
    'days ago': {
        pt: 'dias atrás',
        es: 'días atrás',
        ru: 'дней назад',
        fr: 'jours passés',
        de: 'Tage her'
    },
    'hours ago': {
        pt: 'horas atrás',
        es: 'horas atrás',
        ru: 'часов назад',
        fr: 'heures passées',
        de: 'Stunden her'
    },
    'hour ago': {
        pt: 'hora atrás',
        es: 'hora atrás',
        ru: 'час назад',
        fr: 'heure passée',
        de: 'Stunde her'
    },
    'minute ago': {
        pt: 'minuto atrás',
        es: 'minuto atrás',
        ru: 'минуту назад',
        fr: 'minute passée',
        de: 'Minute her'
    },
    'minutes ago': {
        pt: 'minutos atrás',
        es: 'minutos atrás',
        ru: 'минут назад',
        fr: 'minutes passées',
        de: 'Minuten her'
    },
    'just now': {
        pt: 'agora mesmo',
        es: 'justo ahora',
        ru: 'только что',
        fr: 'à l\'instant',
        de: 'gerade eben'
    },
    'unarchived the task at': {
        pt: 'desarquivou a tarefa em',
        es: 'desarchivó la tarea en',
        ru: 'разархивировал задачу в',
        fr: 'a désarchivé la tâche à',
        de: 'hat die Aufgabe entarchiviert um'
    },
    'archived the task at': {
        pt: 'arquivou a tarefa em',
        es: 'archivó la tarea en',
        ru: 'архивировал задачу в',
        fr: 'a archivé la tâche à',
        de: 'hat die Aufgabe archiviert um'
    },
    'updated field': {
        pt: 'campo atualizado',
        es: 'campo actualizado',
        ru: 'обновленное поле',
        fr: 'champ mis à jour',
        de: 'Feld aktualisiert'
    },
    'cleared field': {
        pt: 'campo limpo',
        es: 'campo borrado',
        ru: 'очищенное поле',
        fr: 'champ effacé',
        de: 'Feld gelöscht'
    },
    'uploaded a file': {
        pt: 'carregou um arquivo',
        es: 'subió un archivo',
        ru: 'загрузил файл',
        fr: 'a téléchargé un fichier',
        de: 'hat eine Datei hochgeladen'
    },
    'added an attachment': {
        pt: 'adicionou um anexo',
        es: 'agregó un adjunto',
        ru: 'добавил вложение',
        fr: 'a ajouté une pièce jointe',
        de: 'hat einen Anhang hinzugefügt'
    },
    'deleted a file': {
        pt: 'excluiu um arquivo',
        es: 'eliminó un archivo',
        ru: 'удалил файл',
        fr: 'a supprimé un fichier',
        de: 'hat eine Datei gelöscht'
    },
    'deleted an attachment': {
        pt: 'excluiu um anexo',
        es: 'eliminó un adjunto',
        ru: 'удалил вложение',
        fr: 'a supprimé une pièce jointe',
        de: 'hat einen Anhang gelöscht'
    },
    'Delete Comment': {
        pt: 'Excluir Comentário',
        es: 'Eliminar Comentario',
        ru: 'Удалить Комментарий',
        fr: 'Supprimer le Commentaire',
        de: 'Kommentar Löschen'
    },
    'Are you sure you want to delete this comment?': {
        pt: 'Tem certeza que deseja excluir este comentário?',
        es: '¿Está seguro de que desea eliminar este comentario?',
        ru: 'Вы уверены, что хотите удалить этот комментарий?',
        fr: 'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
        de: 'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?'
    },

    'Activity': {
        pt: 'Atividade',
        es: 'Actividad',
        ru: 'Активность',
        fr: 'Activité',
        de: 'Aktivität'
    },
    'Are you sure you want to do this?': {
        pt: 'Tem certeza que deseja fazer isso?',
        es: '¿Está seguro de que quiere hacer esto?',
        ru: 'Вы уверены, что хотите это сделать?',
        fr: 'Êtes-vous sûr de vouloir faire cela ?',
        de: 'Sind Sie sicher, dass Sie das tun möchten?'
    },
    'Confirm!': {
        pt: 'Confirmar!',
        es: '¡Confirmar!',
        ru: 'Подтвердить!',
        fr: 'Confirmer !',
        de: 'Bestätigen!'
    },
    'You must be on a paid subscription to create a pro number. You can edit your subscription here.': {
        pt: 'Você precisa ter uma assinatura paga para criar um número pro. Você pode editar sua assinatura aqui.',
        es: 'Debe tener una suscripción paga para crear un número pro. Puede editar su suscripción aquí.',
        ru: 'Для создания про-номера необходима платная подписка. Вы можете изменить подписку здесь.',
        fr: 'Vous devez avoir un abonnement payant pour créer un numéro pro. Vous pouvez modifier votre abonnement ici.',
        de: 'Sie benötigen ein bezahltes Abonnement, um eine Pro-Nummer zu erstellen. Sie können Ihr Abonnement hier bearbeiten.'
    },
    'You must be on a paid subscription to create a guest number. You can edit your subscription here.': {
        pt: 'Você precisa ter uma assinatura paga para criar um número de convidado. Você pode editar sua assinatura aqui.',
        es: 'Debe tener una suscripción paga para crear un número de invitado. Puede editar su suscripción aquí.',
        ru: 'Для создания гостевого номера необходима платная подписка. Вы можете изменить подписку здесь.',
        fr: 'Vous devez avoir un abonnement payant pour créer un numéro d\'invité. Vous pouvez modifier votre abonnement ici.',
        de: 'Sie benötigen ein bezahltes Abonnement, um eine Gast-Nummer zu erstellen. Sie können Ihr Abonnement hier bearbeiten.'
    },
    'Successfully assigned ': {
        pt: 'Atribuído com sucesso ',
        es: 'Asignado exitosamente ',
        ru: 'Успешно назначено ',
        fr: 'Assigné avec succès ',
        de: 'Erfolgreich zugewiesen '
    },
    'Rates updated!': {
        pt: 'Taxas atualizadas!',
        es: '¡Tarifas actualizadas!',
        ru: 'Тарифы обновлены!',
        fr: 'Tarifs mis à jour !',
        de: 'Preise aktualisiert!'
    },
    'Raise Rates to Book': {
        pt: 'Aumentar Taxas para Reservar',
        es: 'Aumentar Tarifas para Reservar',
        ru: 'Повысить Тарифы для Бронирования',
        fr: 'Augmenter les Tarifs pour Réserver',
        de: 'Preise zum Buchen erhöhen'
    },

    'Change Rates to ': {
        pt: 'Alterar Taxas para ',
        es: 'Cambiar Tarifas a ',
        ru: 'Изменить Тарифы на ',
        fr: 'Changer les Tarifs à ',
        de: 'Preise ändern auf '
    },
    'You can also ': {
        pt: 'Você também pode ',
        es: 'También puede ',
        ru: 'Вы также можете ',
        fr: 'Vous pouvez aussi ',
        de: 'Sie können auch '
    },
    'request a job with ': {
        pt: 'solicitar um trabalho com ',
        es: 'solicitar un trabajo con ',
        ru: 'запросить работу с ',
        fr: 'demander un travail avec ',
        de: 'einen Job anfragen mit '
    },
    ' and they may still accept at your current rates.': {
        pt: ' e eles ainda podem aceitar suas taxas atuais.',
        es: ' y ellos aún pueden aceptar sus tarifas actuales.',
        ru: ' и они все еще могут принять ваши текущие тарифы.',
        fr: ' et ils peuvent toujours accepter vos tarifs actuels.',
        de: ' und sie können Ihre aktuellen Preise trotzdem akzeptieren.'
    },
    'Saving ': {
        pt: 'Economizando ',
        es: 'Ahorrando ',
        ru: 'Экономия ',
        fr: 'Économie de ',
        de: 'Sparen '
    },
    ' with your ': {
        pt: ' com sua ',
        es: ' con su ',
        ru: ' с вашей ',
        fr: ' avec votre ',
        de: ' mit Ihrem '
    },
    ' subscription!': {
        pt: ' assinatura!',
        es: ' suscripción!',
        ru: ' подпиской!',
        fr: ' abonnement !',
        de: ' Abonnement!'
    },

    'Job Quotes': {
        pt: 'Cotações de Trabalho',
        es: 'Cotizaciones de Trabajo',
        ru: 'Цены на Работу',
        fr: 'Devis de Travail',
        de: 'Arbeitskosten'
    },

    'Restocking': {
        pt: 'Reabastecimento',
        es: 'Reabastecimiento',
        ru: 'Пополнение запасов',
        fr: 'Réapprovisionnement',
        de: 'Nachschub'
    },

    'Popular rules': {
        pt: 'Regras Populares',
        es: 'Reglas Populares',
        ru: 'Популярные правила',
        fr: 'Règles Populaires',
        de: 'Beliebte Regeln'
    },

    '+ Add Another Rule': {
        pt: '+ Adicionar Outra Regra',
        es: '+ Agregar Otra Regla',
        ru: '+ Добавить Другую Правило',
        fr: '+ Ajouter Une Autre Règle',
        de: '+ Eine Andere Regel hinzufügen'
    },

    'Add Rule(s)': {
        pt: 'Adicionar Regra(s)',
        es: 'Agregar Regla(s)',
        ru: 'Добавить Правило(а)',
        fr: 'Ajouter Une Autre Règle',
        de: 'Eine Andere Regel hinzufügen'
    },

    'Rules added': {
        pt: 'Regras adicionadas',
        es: 'Reglas agregadas',
        ru: 'Правила добавлены',
        fr: 'Règles ajoutées',
        de: 'Regeln hinzugefügt'
    },

    'Please fill out all fields': {
        pt: 'Por favor, preencha todos os campos',
        es: 'Por favor, complete todos los campos',
        ru: 'Пожалуйста, заполните все поля',
        fr: 'Veuillez remplir tous les champs',
        de: 'Bitte füllen Sie alle Felder aus'
    },
    'Concierge Messaging Settings': {
        pt: 'Configurações de Mensagens do Concierge',
        es: 'Configuraciones de Mensajes del Concierge',
        ru: 'Настройки Сообщений Консьержа',
        fr: 'Paramètres de Messagerie du Concierge',
        de: 'Einstellungen für Concierge-Nachrichten'
    },

    'Concierge Level': {
        pt: 'Nível do Concierge',
        es: 'Nivel del Concierge',
        ru: 'Уровень Консьержа',
        fr: 'Niveau du Concierge',
        de: 'Niveau des Concierges'
    },

    'Allow pros, guests, or tenants a dedicated line as &quot;Your Ops Number&quot;': {
        pt: 'Permitir que profissionais, convidados ou inquilinos tenham uma linha dedicada como &quot;Seu Número de Operações&quot;',
        es: 'Permitir que profesionales, invitados o inquilinos tengan una línea dedicada como &quot;Su Número de Operaciones&quot;',
        ru: 'Разрешить профессионалам, гостям или арендаторам иметь отдельную линию в качестве &quot;Вашего Номера Операций&quot;',
        fr: 'Autoriser les professionnels, les invités ou les locataires à avoir une ligne dédiée comme &quot;Votre Numéro de Service&quot;',
        de: 'Erlauben, dass Profi, Gäste oder Mieter einen dedizierten Anruf als &quot;Ihre Service-Nummer&quot; haben'
    },

    'Great for more consistency and easier to share instructions, you can just say &quot;here is my number&quot;. $39/yr add on.': {
        pt: 'Excelente para mais consistência e fácil compartilhamento de instruções, você pode simplesmente dizer &quot;aqui está meu número&quot;. Adição de $39/ano.',
        es: 'Excelente para más consistencia y fácil compartimiento de instrucciones, puedes decir &quot;aquí está mi número&quot;. Adición de $39/año.',
        ru: 'Великолепно для большей согласованности и легкого обмена инструкциями, вы можете просто сказать &quot;вот мой номер&quot;. Дополнение за $39/год.',
        fr: 'Excellent pour plus de consistance et de facilité de partage des instructions, vous pouvez simplement dire &quot;voici mon numéro&quot;. Ajout de $39/an.',
        de: 'Großartig für mehr Konsistenz und einfache Anweisungsteilung, Sie können einfach sagen &quot;hier ist meine Nummer&quot;. Zusatz für $39/Jahr.'
    },

    'Low - Support pros with inbound app questions but do not otherwise contact them.': {
        pt: 'Baixo - Apoie profissionais com perguntas de aplicativo de entrada, mas não entre em contato com eles de outra forma.',
        es: 'Bajo - Apoye a los profesionales con preguntas de la aplicación de entrada, pero no contacte a otros profesionales.',
        ru: 'Низкий - Поддерживайте профессионалов с вопросами по входящему приложению, но не связывайтесь с ними другим способом.',
        fr: 'Faible - Supportez les professionnels avec des questions sur l\'application entrante, mais ne les contactez pas autrement.',
        de: 'Niedrig - Unterstützen Sie Fachleute mit Fragen zu den eingehenden Anwendungen, aber kontaktieren Sie sie nicht anders.'
    },

    'Medium - Support + Contact pros as needed on my behalf for job requests only, following their preferences.': {
        pt: 'Médio - Apoie + Contate profissionais conforme necessário em meu nome para solicitações de trabalho apenas, seguindo suas preferências.',
        es: 'Medio - Apoyo + Contacto a profesionales según sea necesario en mi nombre para solicitudes de trabajo únicamente, siguiendo sus preferencias.',
        ru: 'Средний - Поддержка + Контакт с профессионалами по мере необходимости от имени для запросов на работу только, следуя их предпочтениям.',
        fr: 'Moyen - Soutien + Contactez les professionnels selon vos besoins pour les demandes de travail uniquement, en suivant leurs préférences.',
        de: 'Mittel - Unterstützung + Kontaktieren Sie Fachleute nach Bedarf für Anfragen zu Jobs nur, folgend ihren Präferenzen.'
    },

    'High - Respond to inbound messages, make calls on my behalf, and only escalate as needed.': {
        pt: 'Alto - Responda a mensagens de entrada, faça chamadas em meu nome e apenas escalone conforme necessário.',
        es: 'Alto - Responda a mensajes entrantes, haga llamadas en mi nombre y solo escalone según sea necesario.',
        ru: 'Высокий - Отвечайте на входящие сообщения, делая звонки от имени и только при необходимости.',
        fr: 'Haut - Répondez aux messages entrants, faites des appels en mon nom et ne les escaladez que si nécessaire.',
        de: 'Hoch - Antworte auf eingehende Nachrichten, tue Anrufe für mich und stufe nur bei Bedarf auf.'
    },

    'Error saving concierge level': {
        pt: 'Erro ao salvar nível do Concierge',
        es: 'Error al guardar el nivel del Concierge',
        ru: 'Ошибка при сохранении уровня Консьержа',
        fr: 'Erreur lors de la sauvegarde du niveau du Concierge',
        de: 'Fehler beim Speichern des Concierge-Niveaus'
    },

    'Error saving smart number preference': {
        pt: 'Erro ao salvar preferência de número inteligente',
        es: 'Error al guardar la preferencia de número inteligente',
        ru: 'Ошибка при сохранении предпочтения номера',
        fr: 'Erreur lors de la sauvegarde de la préférence de numéro intelligent',
        de: 'Fehler beim Speichern der bevorzugten Nummer'
    },

    'Upload Access Notes': {
        pt: 'Carregar Notas de Acesso',
        es: 'Cargar Notas de Acceso',
        ru: 'Загрузить Заметки о Доступе',
        fr: 'Télécharger les Notes d\'Accès',
        de: 'Zugriffsnotizen hochladen'
    },

    'Upload a CSV file with access notes for your property.': {
        pt: 'Carregue um arquivo CSV com notas de acesso para sua propriedade.',
        es: 'Cargue un archivo CSV con notas de acceso para su propiedad.',
        ru: 'Загрузите CSV-файл с заметками о доступе для вашего свойства.',
        fr: 'Téléchargez un fichier CSV avec des notes d\'accès pour votre propriété.',
        de: 'Laden Sie eine CSV-Datei mit Zugangshinweisen für Ihre Eigentum herunter.'
    },

    'Please complete the Stripe onboarding process to add a bank account.': {
        pt: 'Por favor, complete o processo de onboarding do Stripe para adicionar uma conta bancária.',
        es: 'Por favor, complete el proceso de onboarding de Stripe para agregar una cuenta bancaria.',
        ru: 'Пожалуйста, завершите процесс Stripe onboarding для добавления банковского счета.',
        fr: 'Veuillez compléter le processus d\'onboarding Stripe pour ajouter un compte bancaire.',
        de: 'Bitte führen Sie den Stripe-Onboarding-Prozess aus, um ein Bankkonto hinzuzufügen.'
    },

    'Send Reservation Data from': {
        pt: 'Enviar Dados de Reserva de',
        es: 'Enviar Datos de Reserva de',
        ru: 'Отправить Данные Бронирования',
        fr: 'Envoyer les Données de Réservation de',
        de: 'Buchungsdaten von'
    },

    'to TIDY': {
        pt: 'para TIDY',
        es: 'para TIDY',
        ru: 'для TIDY',
        fr: 'à TIDY',
    },

    'We will show this data to pros or customers who have access to it. Match the data from your PMS to TIDY, or use the defaults.': {
        pt: 'Mostraremos esses dados para profissionais ou clientes que têm acesso a eles. Combine os dados do seu PMS com o TIDY ou use os padrões.',
        es: 'Mostraremos estos datos a profesionales o clientes que tengan acceso a ellos. Combine los datos de su PMS con TIDY o use los predeterminados.',
        ru: 'Мы покажем эти данные профессионалам или клиентам, которые имеют к ним доступ. Сопоставьте данные из вашего PMS с TIDY или используйте стандартные значения.',
        fr: 'Nous afficherons ces données aux professionnels ou aux clients qui ont accès à elles. Faites correspondre les données de votre PMS à TIDY ou utilisez les valeurs par défaut.',
        de: 'Wir zeigen diese Daten den Profis oder Kunden, die Zugriff darauf haben. Passen Sie die Daten aus Ihrem PMS an TIDY an oder verwenden Sie die Standardwerte.'
    },

    'Reservation Card Example': {
        pt: 'Exemplo de Cartão de Reserva',
        es: 'Ejemplo de Tarjeta de Reserva',
        ru: 'Пример Карточки Бронирования',
        fr: 'Exemple de Carte de Réservation',
        de: 'Beispiel für Buchungs-Karte'
    },

    '2 guests': {
        pt: '2 hóspedes',
        es: '2 huéspedes',
        ru: '2 гостя',
        fr: '2 invités',
        de: '2 Gäste'
    },

}


import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'audit-log-item.html',
  encapsulation: ViewEncapsulation.None
})

export class IntegrationAuditLogItemPage implements OnInit {

  guestReservationSyncRun: any;
  guestReservationAddresses: string;

  constructor(
    private navCtrl: CustomNavController,
    private guestReservations: GuestReservations,
    public windowService: WindowService
  ) {}

  async ngOnInit() {
    this.guestReservationSyncRun = this.navCtrl.getParam('guestReservationSyncRun');
    this.guestReservationSyncRun = await this.guestReservations.getAuditLog(this.guestReservationSyncRun.id);

    if(!this.guestReservationSyncRun) {
      return this.navCtrl.navigateRoot('audit-logs');
    }

    try {
      const addresses =
        this.guestReservationSyncRun.data?.sync_results?.units?.map((unit) =>
          this.getPropertyName(unit?.adapted_address)
        );

      this.guestReservationAddresses = addresses.join(', ');
    } catch (error) {
      this.guestReservationAddresses = '';
    }

    this.guestReservationSyncRun.data = JSON.stringify(this.guestReservationSyncRun.data, null, 2);
    this.guestReservationSyncRun.failure_info = JSON.stringify(this.guestReservationSyncRun.failure_info, null, 2);
  }

  getPropertyName(address) {
    if (address?.address_name) {
      return address.address_name;
    }
    if (address?.address2 && address?.address2 !== '') {
      return address?.address1 + ', ' + address?.address2;
    }
    return address?.address1;
  }
}

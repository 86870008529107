<div class="onboarding-header">
  <div class="onboarding-header-title">
    <tidy-text [title]="'Video Tutorial'"></tidy-text>
  </div>
  <div>
    <button
      mat-button
      fill="clear"
      color="primary"
      style="min-width: 0px"
      (click)="closeModal()">
      <tidy-text [body]="'X'"></tidy-text>
    </button>
  </div>
</div>
<tidy-divider></tidy-divider>

<div class="advanced-setup-content">
  <ng-container *ngIf="videoUrl">
    <iframe
      frameborder="0"
      class="video-tutorial-iframe"
      [src]="videoUrl"
      allow="autoplay"></iframe>
  </ng-container>
</div>

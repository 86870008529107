<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [title]="'Audit Log'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="full-page" [style.marginTop]="!windowService.isDesktopRes ? '60px' : '0px'">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService?.isDesktopRes">
      <tidy-text
        [title]="'Audit Log'">
      </tidy-text>
    </tidy-desktop-header>

      <tidy-card *ngIf="guestReservationSyncRun">
        <tidy-row class="vertical-align-middle extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image
            [src]="guestReservationSyncRun?.status == 'failure' ? 'assets/img/block.svg' : 'assets/img/checkmark.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="guestReservationSyncRun?.status | titlecase">
          </tidy-text>
          <div style="margin-left: 5%">
            <tidy-image
              [src]="guestReservationSyncRun?.guest_reservation_source_type?.logo_url" style="width:35px">
            </tidy-image>
          </div>
          <div style="margin-left: 5%">
            <tidy-text [header]="guestReservationSyncRun?.guest_reservation_source_type?.name">
            </tidy-text><br>
            <tidy-text
              [helper]="guestReservationSyncRun?.created_at | tidyDate:'h:mm a MMM d, yyyy'">
            </tidy-text>
          </div>
          <div style="margin-left: 5%" *ngIf="guestReservationAddresses && guestReservationAddresses !== ''">
            <tidy-text [header]="'Addresses:'">
            </tidy-text><br>
            <tidy-text [body]="guestReservationAddresses"></tidy-text>
          </div>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="extra-bottom-padding extra-top-padding vertical-align-center">
          <tidy-text
            [header]="'Sync Data:'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <pre style="white-space: pre-wrap;
          font-family: 'Inter', Arial, sans-serif;
          font-weight: 300;
          font-size: 14px;
          color: black">{{guestReservationSyncRun?.data}}</pre>
        </tidy-row>
        <tidy-row class="extra-bottom-padding extra-top-padding vertical-align-center">
          <tidy-text
            [header]="'Failure Data:'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <pre style="white-space: pre-wrap;
          font-family: 'Inter', Arial, sans-serif;
          font-weight: 300;
          font-size: 14px;
          color: black">{{guestReservationSyncRun?.failure_info}}</pre>
        </tidy-row>
      </tidy-card>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

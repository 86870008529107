<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Plans'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container >

      <tidy-row>
        <tidy-filter
          [initValue]="address?.id"
          [items]="addressFilter"
          [fitContent]="true"
          (optionChange)="changeAddress($event)">
        </tidy-filter>
      </tidy-row>

      <ng-container *ngIf="plans?.length == 0">
        <tidy-alert>
          <tidy-row>
            <tidy-text
              [body]="'No plans booked.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-button
          [text]="'Book Job(s)'"
          [action]="bookJob.bind(this, 'add_job')"
          class="primary">
        </tidy-button>
      </ng-container>

      <tidy-row *ngIf="cameFromBookingFlow && plans?.length" [align]="'center'">
        <tidy-text
          [body]="'Select what you want to do:'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let plan of plans">

        <tidy-card *ngIf="plan?.plan_frequency !== 'once'">
          <tidy-row class="vertical-align-middle">
            <tidy-image
              [src]="'assets/img/icons/blue_sparkle.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="plan?.formatted_plan_with_weekdays_and_times | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [body]="plan?.team == null ? 'With Pros from TIDY' : 'With ' + plan?.team?.name">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="getJobRequestsFromPlan(plan?.id)?.length > 0" [ngClass]="getJobsFromPlan(plan?.id)?.length ? 'vertical-align-center' : 'vertical-align-center extra-bottom-padding'">
            <tidy-image
              [src]="'assets/img/date.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="getJobRequestsFromPlan(plan?.id)?.length">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'Pending Job Request' + (getJobRequestsFromPlan(plan?.id)?.length > 1 ? 's' : '')">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let job of getJobsFromPlan(plan?.id); let last = last">
            <tidy-row [ngClass]="last ? 'extra-bottom-padding vertical-align-center' : 'vertical-align-center'">
              <tidy-image
                [src]="'assets/img/date.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                ' ' +
                (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'at'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-card-button (action)="goToReschedulePlan(plan)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/arrow-forward-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Reschedule Plan'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToAddOneTimeJob()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/add-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Add One Time Job'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="cancelPlan(plan)" *ngIf="!cameFromBookingFlow">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/close-circle-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Cancel Plan'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card *ngIf="plan?.plan_frequency == 'once'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/icons/blue_sparkle.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'One Time Job' + (getJobsFromPlan(plan?.id)?.length + getJobRequestsFromPlan(plan?.id)?.length > 1 ? 's' : '')">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [body]="plan?.team == null ? 'With Pros from TIDY' : 'With ' + plan?.team?.name">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="getJobRequestsFromPlan(plan?.id)?.length > 0" [ngClass]="getJobsFromPlan(plan?.id)?.length ? 'vertical-align-center' : 'vertical-align-center extra-bottom-padding'">
            <tidy-image
              [src]="'assets/img/date.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="getJobRequestsFromPlan(plan?.id)?.length">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'Pending Job Request' + (getJobRequestsFromPlan(plan?.id)?.length > 1 ? 's' : '')">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let job of getJobsFromPlan(plan?.id); let last = last">
            <tidy-row [ngClass]="last ? 'extra-bottom-padding vertical-align-center' : 'vertical-align-center'">
              <tidy-image
                [src]="'assets/img/date.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                ' ' +
                (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'at'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-card-button (action)="goToReschedulePlan(plan)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/arrow-forward-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Reschedule Job' + (getJobsFromPlan(plan?.id)?.length + getJobRequestsFromPlan(plan?.id)?.length > 1 ? 's' : '')">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToAddOneTimeJob()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/add-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Add Job'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="cancelPlan(plan)" *ngIf="!cameFromBookingFlow">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/close-circle-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Cancel Job' + (getJobsFromPlan(plan?.id)?.length + getJobRequestsFromPlan(plan?.id)?.length > 1 ? 's' : '')">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

      </ng-container>

    </ng-container>

  </tidy-wrapper>

  <ng-container *ngIf="!loaded">
    <ion-skeleton-text
      animated="true"
      style="margin-top: -50px; width: 100%; height: 100vh; border-radius: 0;">
    </ion-skeleton-text>
  </ng-container>

</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent && !windowService.isDesktopRes"
    [title]="'Past Job'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="past-job-padding">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="!isRightSideContent && windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Past Job'">
      </tidy-text>
      <ng-container *ngIf="loaded">
        <div class="desktop-nav-button align-right">
          <tidy-button
            [action]="printPdf.bind(this)"
            [text]="'Print PDF'"
            [smallBody]="true">
          </tidy-button>
        </div>
      </ng-container>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 198px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <ion-row>
        <ion-col [sizeLg]="!isRightSideContent && windowService.isDesktopRes ? 8 : 12" [sizeSm]="!isRightSideContent && windowService.isDesktopRes ? 6 : 12">

      <ng-container *ngIf="isRightSideContent">
        <tidy-row [align]="'center'" style="margin-bottom: 1rem;">
          <tidy-text
            [body]="'Print PDF'"
            class="link"
            (action)="printPdf()">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-row *ngIf="job?.job?.status !== 'completed' && job?.job?.status !== 'incomplete' && job?.job?.state !== 'completed'" [align]="'center'">
        <tidy-text
          [body]="'Did they finish the job?'">
        </tidy-text>
        <tidy-text
          [body]="'Mark Complete'"
          class="link"
          (action)="goToMarkJobComplete()">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <div>
            <tidy-text
              [header]="job?.booking?.bookable_service?.name | titlecase">
            </tidy-text><br>
            <tidy-text
              [body]="displayAddress"
              [translate]="false">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [header]="(job?.job?.date | parseDate: 'ddd M/D')">
            </tidy-text><br>
            <tidy-text
              [body]="(job?.job?.start_time | parseDate: 'h:mma')">
            </tidy-text>
          </div>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true" *ngFor="let pro of job?.homekeepers">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="pro.name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            *ngIf="windowService.isDesktopRes"
            [body]="pro?.phone | telephone">
          </tidy-text>
          <tidy-text
            *ngIf="!windowService.isDesktopRes"
            [body]="pro?.phone | telephone"
            (action)="contactPro(pro?.phone)"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/clock-black.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="'Job Duration'">
          </tidy-text>
          <tidy-text
            *ngIf="!proReportedDuration && !fromStartDuration"
            [body]="': N/A'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="proReportedDuration">
          <tidy-text
            [body]="'Reported by Pro:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="proReportedDuration">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="fromStartDuration">
          <tidy-text
            [body]="'Using Realtime Updates:'">
          </tidy-text>
          <tidy-text
            [body]="fromStartDuration">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let event of jobEvents">
          <tidy-row *ngIf="event?.event?.name === 'samantha_service.booking.created'">
            <tidy-text
              [body]="event?.text + ' at ' + (event?.happened_at | customdate:'M/D h:mma')">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-row *ngIf="!job?.homekeepers?.length" [align]="'center'">
        <tidy-text
          [body]="'This job was cancelled. Talk to your pro for more info.'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="job?.homekeepers?.length">

        <tidy-card style="padding: 0px" *ngIf="isRightSideContent || !windowService.isDesktopRes">
          <div attr.id="map{{jobId}}" #map  style="width: 100%; height: 300px; border-radius: 6px;">
          </div>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToInvoicePage()" class="arrow-to-top" [hideArrow]="!invoice">
            <tidy-row [alignLastItemRight]="(invoice && !invoice?.full_paid) || !invoice" [ngClass]="((invoice && !invoice?.full_paid) || !invoice) ? '' : 'vertical-align-center'">
              <tidy-image
                [src]="'assets/img/dollar.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="invoice ? 'Balance' : 'Quoted Price'">
              </tidy-text>
              <tidy-text
                *ngIf="invoice && !invoice?.full_paid"
                [body]="invoice?.amount_due*-1 | tcurrency"
                style="margin-right:5px">
              </tidy-text>
              <tidy-text
                *ngIf="!invoice"
                [body]="job?.booking?.bookable_service?.price | tcurrency"
                style="margin-right:5px">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!invoice">
              <tidy-text
                [body]="pro?.homekeeper?.first_name"
                [translate]="false">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'has not sent you a bill or marked this job as paid. The job was quoted to cost'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(job?.booking?.bookable_service?.price | tcurrency) + '.'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Ask them to send you a bill if want to pay for this job.'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!invoice?.full_paid && invoice">
              <tidy-text
                [body]="pro?.homekeeper?.first_name"
                [translate]="false">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'sent you a'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="invoice?.amount_due | tcurrency">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'bill for this job'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="invoice?.full_paid">
              <tidy-text
                [body]="'You paid a'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(invoice?.amount | tcurrency)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'bill for this bill.'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToLoggedIssues()" class="arrow-to-top">
            <tidy-row [alignLastItemRight]="true">
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Tasks'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="unresolvedLoggedIssues?.length">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'unresolved'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToShareJobPage()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/paper-plane.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Share Job Link'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <ng-container *ngIf="isRightSideContent || !windowService.isDesktopRes">
          <ng-container *ngFor="let item of activity">
            <tidy-card class="no-padding" style="padding-bottom: 15px;">
              <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding extra-left-padding extra-right-padding">
                <tidy-image
                  [src]="'assets/img/account.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="item?.pro?.text ? item?.pro?.text : item?.pro?.first_name"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-left-padding extra-right-padding" style="padding-bottom: 0px">
                <form [formGroup]="form" novalidate action="#">
                  <tidy-select
                    style="width:35%; font-size: 8px"
                    [form]="form.controls.messageType"
                    formControlName="messageType"
                    [items]="messageSelectItems"
                    [smallText]="true"
                    [selectedItem]="selectedMessageType"
                    (optionChange)="updateIsInternalComment($event)">
                  </tidy-select>
                </form>
              </tidy-row>
              <tidy-row class="field-top-padding extra-bottom-padding extra-left-padding extra-right-padding" *ngIf="pro?.text == pro?.first_name">
                <form [formGroup]="form" novalidate action="#">
                  <tidy-textarea
                    [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                    formControlName="message"
                    [form]="form.controls.message"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter a message to send.'"
                    [rightIconAction]="sendMessage.bind(this)"
                    [leftIconAction]="sendAttachment.bind(this)"
                    [showBlackButtons]="isInternalComment">
                  </tidy-textarea>
                </form>
              </tidy-row>
              <tidy-row *ngIf="item?.messages?.length == 0" [align]="'center'" class="extra-left-padding extra-right-padding extra-bottom-padding">
                <tidy-text
                  [body]="'No Messages'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let activity of item?.messages; let last = last; let index = index">
                <message
                  *ngIf="index < 3"
                  [message]="activity"
                  [last]="last"
                  [showJobLink]="false"
                  [proId]="item?.pro?.id">
                </message>
              </ng-container>
              <tidy-row [align]="'center'" *ngIf="item?.messages?.length > 3" class="extra-left-padding extra-right-padding extra-bottom-padding">
                <tidy-text
                  class="link"
                  [actionBody]="('View ' | translate) + (item?.messages?.length - 3) + (' More Job Message' | translate) + ((item?.messages?.length - 3 > 1) ? 's' : '')"
                  (action)="goToJobActivityPage(item)">
                </tidy-text>
              </tidy-row>
            </tidy-card>
          </ng-container>
        </ng-container>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Rate This Job'"
            class="link"
            (action)="goToProHomepage()">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="rooms?.length > 0">
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
            <tidy-text
              [title]="'To-Dos'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Edit'"
              (action)="goToEditToDos()"
              class="link">
            </tidy-text>
          </tidy-row>

          <tidy-card *ngIf="bookingNote?.length || bookingFormAnswers?.length">
            <tidy-card-button class="arrow-to-top">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/information.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Job Note'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngFor="let note of bookingNote">
                <ng-container *ngIf="note?.type == 'text'">
                  <tidy-text
                    [body]="note?.text">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="note?.type == 'image'">
                  <tidy-image
                    [src]="note?.media_url">
                  </tidy-image>
                </ng-container>
                <ng-container *ngIf="note?.type == 'video'">
                  <tidy-image
                    [src]="note?.media_url">
                  </tidy-image>
                </ng-container>
              </tidy-row>
              <ng-container *ngFor="let answer of bookingFormAnswers">
                <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
                  <tidy-row>
                    <tidy-text
                      [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                    </tidy-text>
                  </tidy-row>
                  <tidy-photo-note
                    *ngIf="answer?.template_input?.image_url"
                    [src]="answer?.template_input?.image_url">
                  </tidy-photo-note>
                </ng-container>
                <ng-container *ngIf="answer?.template_input?.type == 'select'">
                  <tidy-row>
                    <tidy-text
                      [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                    </tidy-text>
                  </tidy-row>
                  <tidy-photo-note
                    *ngIf="answer?.template_input?.image_url"
                    [src]="answer?.template_input?.image_url">
                  </tidy-photo-note>
                </ng-container>
                <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
                  <tidy-row>
                    <tidy-text
                      [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                    </tidy-text>
                  </tidy-row>
                  <tidy-photo-note
                    *ngIf="answer?.template_input?.image_url"
                    [src]="answer?.template_input?.image_url">
                  </tidy-photo-note>
                </ng-container>
                <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
                  <tidy-row>
                    <tidy-text
                      [body]="answer?.template_input?.label">
                    </tidy-text>
                  </tidy-row>
                  <tidy-carrousel
                    class="photo-notes-carrousel only-padding-bottom"
                    [scaleImages]="true">
                    <tidy-photo-note
                      *tidyCarrousel
                      [src]="answer?.data?.value">
                    </tidy-photo-note>
                  </tidy-carrousel>
                </ng-container>
                <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
                  <tidy-row>
                    <tidy-text
                      [body]="answer?.template_input?.label">
                    </tidy-text>
                  </tidy-row>
                  <tidy-carrousel
                    class="photo-notes-carrousel only-padding-bottom"
                    [scaleImages]="true">
                    <ng-container *ngFor="let imageUrl of answer?.data?.value">
                      <tidy-photo-note
                        *tidyCarrousel
                        [src]="imageUrl">
                      </tidy-photo-note>
                    </ng-container>
                  </tidy-carrousel>
                </ng-container>
              </ng-container>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <tidy-image
                  [src]="'assets/img/camera.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Before Photos / Videos'">
                </tidy-text>
              </div>
              <div style="display: flex; align-items: center;" *ngIf="isFullWidth">
                <tidy-file-upload
                  [accept]="'image/*'"
                  [showPreview]="false"
                  [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                  (addedImagesChange)="uploadPhoto($event, 'before_job')"
                  [showTextLink]="true"
                  style="margin-right: 10px">
                </tidy-file-upload>
                <tidy-file-upload
                  [accept]="'video/mp4'"
                  [showPreview]="false"
                  [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                  (addedImagesChange)="uploadVideo($event, 'before_job')"
                  [showTextLink]="true">
                </tidy-file-upload>
              </div>
            </div>
            <div *ngIf="!isFullWidth" style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Photo'"
                (action)="takePhoto('before_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'image/*'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                (addedImagesChange)="uploadPhoto($event, 'before_job')"
                [showTextLink]="true"
                style="margin-right: 10px">
              </tidy-file-upload>
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Video'"
                (action)="takeVideo('before_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'video/mp4'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                (addedImagesChange)="uploadVideo($event, 'before_job')"
                [showTextLink]="true">
              </tidy-file-upload>
            </div>
            <tidy-row *ngIf="beforePhotos?.length == 0" class="extra-top-padding">
              <tidy-text
                [helper]="'None Added'">
              </tidy-text>
            </tidy-row>
            <ion-row>
              <ng-container *ngFor="let item of beforePhotos; let i = index">
                <ion-col [size]="isFullWidth ? 6 : 12">
                  <ng-container *ngIf="item.mediaType === 'video'">
                    <tidy-row>
                      <tidy-video
                        class="before-after-video"
                        [src]="item?.url"
                        style="width: 100%; height: auto; max-width: 100%;">
                      </tidy-video>
                    </tidy-row>
                  </ng-container>
                  <ng-container *ngIf="item.mediaType === 'photo'">
                    <tidy-photo-note
                      *ngIf="item?.url"
                      class="full-width no-margin photo-note-job"
                      [src]="item?.url"
                      style="width: 100%; height: auto; max-width: 100%;">
                    </tidy-photo-note>
                  </ng-container>
                  <div *ngIf="item?.metadata || item?.uploadedBy" class="timestamp-caption">
                    <tidy-row [alignLastItemRight]="true">
                      <tidy-text
                        [smallBody]="('Uploaded by: ' | translate) +  (item?.uploadedBy ? item?.uploadedBy : 'you')"
                        class="white">
                      </tidy-text>
                      <div>
                        <tidy-image
                          [src]="'assets/img/trash-outline-white.svg'"
                          (action)="removePhoto(item, i, 'before_job')"
                          class="body-size"
                          style="cursor: pointer">
                        </tidy-image>
                      </div>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.location">
                      <tidy-text
                        [smallBody]="'Location:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="item?.metadata?.location"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.displayDate">
                      <tidy-text
                        [smallBody]="'Timestamp:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="(item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                  </div>
                </ion-col>
              </ng-container>
            </ion-row>
          </tidy-card>

          <tidy-card *ngFor="let room of rooms">
            <tidy-row class="vertical-align-center">
              <tidy-image
                *ngIf="room?.icon"
                [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="room?.name"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let toDo of room?.todos; let lastToDo = last">
              <tidy-row *ngIf="toDo?.title !== 'Take Before Photo' && toDo?.title !== 'Take After Photo' && toDo?.title !== 'Take Before Video' && toDo?.title !== 'Take After Video'" [ngClass]="!lastToDo ? 'extra-bottom-padding vertical-align-middle' : 'vertical-align-middle'">
                <tidy-image
                  [matTooltip]="(toDos.getToDoTooltip(toDo?.customer_performance || toDo?.performance)) | translate"
                  [src]="toDos.getToDoIcon(toDo?.customer_performance || toDo?.performance)"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="toDo?.title"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let option of toDo?.options; let last = lastOption">
                <tidy-row *ngIf="option?.value_type !== 'photo' && option?.value_type !== 'photos'" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                  <ng-container *ngIf="option?.notes && (option?.customer_value || option?.json_value || option?.value)">
                    <tidy-text
                      [body]="option?.name + ': ' + option?.notes"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'Input:'"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="(option?.customer_value || option?.json_value || option?.value)"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="!option?.notes && (option?.customer_value || option?.json_value || option?.value)">
                    <tidy-text
                      [body]="option?.name"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'Input:'"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="(option?.customer_value || option?.json_value || option?.value)"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="option?.notes && !option?.customer_value && !option?.value && !option?.json_value">
                    <tidy-text
                      [body]="option?.name + ': ' + option?.notes"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'No value added by pro'"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="!option?.notes && !option?.customer_value && !option?.value && !option?.json_value">
                    <tidy-text
                      [body]="option?.name"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [helper]="'No value added by pro'"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
                <tidy-row *ngIf="option?.value_type?.includes('photo')" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                  <tidy-text
                    *ngIf="option?.notes"
                    [body]="option?.name + ': ' + option?.notes"
                    [translate]="false">
                  </tidy-text>
                  <tidy-text
                    *ngIf="!option?.notes"
                    [body]="option?.name"
                    [translate]="false">
                  </tidy-text>
                  <tidy-photo-note
                    *ngIf="option?.value"
                    class="full-width no-margin photo-note-job"
                    [src]="option?.value">
                  </tidy-photo-note>
                  <div *ngIf="option?.metadata" class="timestamp-caption">
                    <tidy-row *ngIf="option?.metadata?.location">
                      <tidy-text
                        [smallBody]="'Location:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="option?.metadata?.location"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="option?.metadata?.displayDate">
                      <tidy-text
                        [smallBody]="'Timestamp:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="(option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                  </div>
                  <ng-container *ngFor="let option of option?.jsonValues">
                    <tidy-photo-note
                      *ngIf="option?.value"
                      class="full-width no-margin photo-note-job"
                      [src]="option?.value">
                    </tidy-photo-note>
                    <div *ngIf="option?.metadata" class="timestamp-caption">
                      <tidy-row *ngIf="option?.metadata?.location">
                        <tidy-text
                          [smallBody]="'Location:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="option?.metadata?.location"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row *ngIf="option?.metadata?.displayDate">
                        <tidy-text
                          [smallBody]="'Timestamp:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="(option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!option?.customer_value && !option?.value && !option?.jsonValues">
                    <br>
                    <tidy-text
                      [helper]="'No photo taken by pro'">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-card>
        </ng-container>

        <tidy-card>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <div style="display: flex; align-items: center;">
              <tidy-image
                [src]="'assets/img/camera.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'After Photos / Videos'">
              </tidy-text>
            </div>
            <div style="display: flex; align-items: center;" *ngIf="isFullWidth">
              <tidy-file-upload
                [accept]="'image/*'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                (addedImagesChange)="uploadPhoto($event, 'after_job')"
                [showTextLink]="true"
                style="margin-right: 10px">
              </tidy-file-upload>
              <tidy-file-upload
                [accept]="'video/mp4'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                (addedImagesChange)="uploadVideo($event, 'after_job')"
                [showTextLink]="true">
              </tidy-file-upload>
            </div>
          </div>
          <div *ngIf="!isFullWidth" style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
            <tidy-text
              *ngIf="isNativeMobile"
              [actionBody]="'Take Photo'"
              (action)="takePhoto('after_job')"
              class="link"
              style="margin-right: 10px">
            </tidy-text>
            <tidy-file-upload
              [accept]="'image/*'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
              (addedImagesChange)="uploadPhoto($event, 'after_job')"
              [showTextLink]="true"
              style="margin-right: 10px">
            </tidy-file-upload>
            <tidy-text
              *ngIf="isNativeMobile"
              [actionBody]="'Take Video'"
              (action)="takeVideo('after_job')"
              class="link"
              style="margin-right: 10px">
            </tidy-text>
            <tidy-file-upload
              [accept]="'video/mp4'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
              (addedImagesChange)="uploadVideo($event, 'after_job')"
              [showTextLink]="true">
            </tidy-file-upload>
          </div>
          <tidy-row *ngIf="afterPhotos?.length == 0" class="extra-top-padding">
            <tidy-text
              [helper]="'None Added'">
            </tidy-text>
          </tidy-row>
          <ion-row>
            <ng-container *ngFor="let item of afterPhotos; let i = index">
              <ion-col [size]="isFullWidth ? 6 : 12">
                <ng-container *ngIf="item.mediaType === 'video'">
                  <tidy-row>
                    <tidy-video
                      class="before-after-video"
                      [src]="item?.url"
                      style="width: 100%; height: auto; max-width: 100%;">
                    </tidy-video>
                  </tidy-row>
                </ng-container>
                <ng-container *ngIf="item.mediaType === 'photo'">
                  <tidy-photo-note
                    *ngIf="item?.url"
                    class="full-width no-margin photo-note-job"
                    [src]="item?.url"
                    style="width: 100%; height: auto; max-width: 100%;">
                  </tidy-photo-note>
                </ng-container>
                <div *ngIf="item?.metadata || item?.uploadedBy" class="timestamp-caption">
                  <tidy-row [alignLastItemRight]="true">
                    <tidy-text
                      [smallBody]="'Uploaded by: ' +  (item?.uploadedBy ? item?.uploadedBy : 'You')"
                      class="white">
                    </tidy-text>
                    <div>
                      <tidy-image
                        [src]="'assets/img/trash-outline-white.svg'"
                        (action)="removePhoto(item, i, 'after_job')"
                        class="body-size"
                        style="cursor: pointer">
                      </tidy-image>
                    </div>
                  </tidy-row>
                  <tidy-row *ngIf="item?.metadata?.location">
                    <tidy-text
                      [smallBody]="'Location:'"
                      class="white">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [smallBody]="item?.metadata?.location"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="item?.metadata?.displayDate">
                    <tidy-text
                      [smallBody]="'Timestamp:'"
                      class="white">
                    </tidy-text>
                    <tidy-text
                      [smallBody]="(item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                </div>
              </ion-col>
            </ng-container>
          </ion-row>
        </tidy-card>

      </ng-container>

        </ion-col>
        <ng-container *ngIf="!isRightSideContent && windowService.isDesktopRes">
          <ion-col [sizeLg]="4" [sizeSm]="6">
            <tidy-row *ngIf="job?.job?.status !== 'completed' && job?.job?.status !== 'incomplete'" style="padding-top: 18px"></tidy-row>
            <tidy-card style="padding: 0px">
              <div attr.id="map{{jobId}}" #map  style="width: 100%; height: 300px; border-radius: 6px;">
              </div>
            </tidy-card>

            <ng-container *ngFor="let item of activity">
              <tidy-card class="no-padding" style="padding-bottom: 15px;">
                <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding extra-left-padding extra-right-padding">
                  <tidy-image
                    [src]="'assets/img/account.svg'"
                    class="title-size">
                  </tidy-image>
                  <tidy-text
                    [header]="item?.pro?.text ? item?.pro?.text : item?.pro?.first_name"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-left-padding extra-right-padding" style="padding-bottom: 0px">
                  <form [formGroup]="form" novalidate action="#">
                    <tidy-select
                      style="width:35%; font-size: 8px"
                      [form]="form.controls.messageType"
                      formControlName="messageType"
                      [items]="messageSelectItems"
                      [smallText]="true"
                      [selectedItem]="selectedMessageType"
                      (optionChange)="updateIsInternalComment($event)">
                    </tidy-select>
                  </form>
                </tidy-row>
                <tidy-row class="field-top-padding extra-bottom-padding extra-left-padding extra-right-padding" *ngIf="pro?.text == pro?.first_name">
                  <form [formGroup]="form" novalidate action="#">
                    <tidy-textarea
                      [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                      formControlName="message"
                      [form]="form.controls.message"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter a message to send.'"
                      [rightIconAction]="sendMessage.bind(this)"
                      [leftIconAction]="sendAttachment.bind(this)"
                      [showBlackButtons]="isInternalComment">
                    </tidy-textarea>
                  </form>
                </tidy-row>
                <tidy-row *ngIf="item?.messages?.length == 0" [align]="'center'" class="extra-left-padding extra-right-padding extra-bottom-padding">
                  <tidy-text
                    [body]="'No Messages'">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngFor="let activity of item?.messages; let last = last; let index = index">
                  <message
                    *ngIf="index < 3"
                    [message]="activity"
                    [last]="last"
                    [showJobLink]="false">
                  </message>
                </ng-container>
                <tidy-row [align]="'center'" *ngIf="item?.messages?.length > 3" class="extra-left-padding extra-right-padding extra-bottom-padding">
                  <tidy-text
                    class="link"
                    [actionBody]="'View ' + (item?.messages?.length - 3) + ' More Job Message' + ((item?.messages?.length - 3 > 1) ? 's' : '')"
                    (action)="goToJobActivityPage(item)">
                  </tidy-text>
                </tidy-row>
              </tidy-card>
            </ng-container>
          </ion-col>
        </ng-container>
      </ion-row>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template let-extraClass="extraClass" #topNav>
  <ng-container *ngIf="!windowService.isDesktopRes">
    <tidy-top-nav
      [class]="extraClass"
      [canGoBack]="true"
      [customBack]="customBackPage"
      [title]="'Property'"
      [showLinkIcon]="true"
      [linkIcon]="'create'"
      (linkAction)="editAddress()">
    </tidy-top-nav>
  </ng-container>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Property'">
      </tidy-text>
      <ng-container *ngIf="isPropertyDetailsLoaded">
        <form [formGroup]="form" novalidate action="#">
          <div class="desktop-item">
            <tidy-select
              class="select-custom-input"
              [label]="'Property'"
              [items]="addressFilter"
              [form]="form.get('property')"
              formControlName="property"
              (optionChange)="changeAddress($event)">
            </tidy-select>
          </div>
        </form>
        <ion-img
          [matTooltip]="'Edit Property Details' | translate"
          [src]="'assets/img/edit-outline-black.svg'"
          class="desktop-title-icon"
          (click)="editAddress()">
        </ion-img>
      </ng-container>
      <ng-container *ngIf="!isPropertyDetailsLoaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 198px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <ng-container *ngIf="!windowService.isDesktopRes">
        <div class="extra-header-mobile">
          <div class="property-content">
            <tidy-text [header]="'Property Details for'"> </tidy-text>
            <tidy-filter
              class="property-filter"
              [initValue]="addressId"
              [items]="addressFilter"
              [fitContent]="true"
              (optionChange)="changeAddress($event)">
            </tidy-filter>
          </div>
        </div>
      </ng-container>
      <tidy-row [align]="'center'" *ngIf="address?.integration?.name">
        <tidy-text [body]="'Synced with'"> </tidy-text><span>&nbsp;</span>
        <tidy-text [body]="address.integration.name" [translate]="false">
        </tidy-text>
        <tidy-text [helper]="' ' + '('+ address.integration.state + ')'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="showCards">
        <ion-row>
          <ion-col
            size="12"
            size-md="6"
            *ngFor="let column of columns; let i = index"
            [class]="i === 0 ? 'column-right-padding' : 'column-left-padding'">
            <ng-container *ngFor="let item of column.items">
              <ng-container *ngIf="item.value === 'map'">
                <ng-container *ngIf="!isMapRequestsLoaded && !map && !mapUrl">
                  <tidy-card>
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/house-sketch.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text [header]="'Property Map'"> </tidy-text>
                    </tidy-row>
                    <ion-row>
                      <ion-skeleton-text
                        animated
                        style="
                          width: 100%;
                          height: 40px;
                          border-radius: 6px;
                        "></ion-skeleton-text>
                    </ion-row>
                  </tidy-card>
                </ng-container>
                <ng-container *ngIf="isMapRequestsLoaded || map || mapUrl">
                  <ng-container *ngIf="mapRequests.length > 0">
                    <ng-container *ngFor="let request of mapRequests">
                      <tidy-card
                        *ngIf="(request?.redo_requests?.length == 0 || !request?.redo_requests) && !map">
                        <tidy-row class="vertical-align-center">
                          <tidy-image
                            [src]="'assets/img/house-sketch.svg'"
                            class="header-size">
                          </tidy-image>
                          <tidy-text [header]="'Map Building In Progress'">
                          </tidy-text>
                        </tidy-row>
                        <tidy-row>
                          <tidy-text
                            [body]="'This can take 72 hours or more, thanks for your patience.'">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="'Cancel Request'"
                            (action)="cancelMapRequestModal()"
                            class="link">
                          </tidy-text>
                        </tidy-row>
                      </tidy-card>
                      <tidy-card *ngIf="request?.redo_requests?.length > 0">
                        <tidy-row class="vertical-align-center">
                          <tidy-image
                            [src]="'assets/svg/alert-circle.svg'"
                            class="header-size">
                          </tidy-image>
                          <tidy-text [header]="'Action Needed for Map Request'">
                          </tidy-text>
                        </tidy-row>
                        <tidy-row>
                          <tidy-text
                            [body]="'Review your lot outline and video information'">
                          </tidy-text>
                        </tidy-row>
                        <tidy-row class="extra-bottom-padding">
                          <tidy-button
                            [action]="reviewMapRequest.bind(this, request)"
                            [text]="'Redo Map Request'"
                            class="primary">
                          </tidy-button>
                        </tidy-row>
                        <tidy-row [align]="'center'">
                          <tidy-text
                            [body]="'Cancel Request'"
                            (action)="cancelMapRequestModal()"
                            class="link">
                          </tidy-text>
                        </tidy-row>
                      </tidy-card>
                    </ng-container>
                  </ng-container>

                  <tidy-card *ngIf="mapUrl">
                    <form [formGroup]="form">
                      <tidy-row
                        class="extra-bottom-padding"
                        [alignLastItemRight]="mapItems?.length > 1"
                        [ngClass]="mapItems?.length > 1 ? '' : 'vertical-align-center'">
                        <tidy-image
                          [src]="'assets/img/map.svg'"
                          class="header-size">
                        </tidy-image>
                        <tidy-text [header]="'Property Map'"> </tidy-text>
                        <tidy-select
                          *ngIf="mapItems?.length > 1"
                          [items]="mapItems"
                          formControlName="map"
                          [form]="form.controls.map"
                          (optionChange)="changeMap($event)"
                          style="width: 20px">
                        </tidy-select>
                      </tidy-row>
                      <div class="map-wrap">
                        <iframe class="frame" [src]="mapUrl"></iframe>
                      </div>
                    </form>
                  </tidy-card>

                  <tidy-card *ngIf="mapRequests?.length === 0 && !map">
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/house-sketch.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text
                        *ngIf="!didRequestMap"
                        [header]="'Get a Map & Maintenance Plan'">
                      </tidy-text>
                      <tidy-text
                        *ngIf="didRequestMap"
                        [header]="'Map & Maintenance Plan Requested'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="!didRequestMap">
                      <tidy-text
                        [body]="'It\'s easy, useful, and fun to have a great sharable map of your property.'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [actionBody]="'Request Feature'"
                        class="link"
                        (action)="requestMap()">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="didRequestMap">
                      <tidy-text
                        [body]="'Our Concierge will reach out to help.'">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.value === 'scheduled-jobs'">
                <tidy-card>
                  <tidy-card-button
                    (action)="goToJobsPage()"
                    class="arrow-to-top">
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/book.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Scheduled Jobs'"> </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <ng-container *ngIf="!isJobsLoaded">
                        <ion-skeleton-text
                          animated
                          style="
                            width: 100%;
                            height: 20px;
                            border-radius: 6px;
                          "></ion-skeleton-text>
                      </ng-container>
                      <ng-container *ngIf="isJobsLoaded">
                        <ng-container *ngIf="nextJob">
                          <tidy-text
                            [body]="'Next:'">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="(nextJob?.template_data?.job?.start_datetime_local | parseDate: 'ddd') + ' ' + (nextJob?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="'at'">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="nextJob?.template_data?.job?.start_datetime_local | parseDate: 'h:mma'">
                          </tidy-text>
                        </ng-container>
                        <tidy-text
                          *ngIf="!nextJob"
                          [helper]="'No Jobs Scheduled'">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'past-jobs'">
                <tidy-card>
                  <tidy-card-button
                    (action)="goToPastJobsPage()"
                    class="arrow-to-top">
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/checkmark-calendar.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Past Jobs'"> </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <ng-container *ngIf="!isPastJobsLoaded">
                        <ion-skeleton-text
                          animated
                          style="
                            width: 100%;
                            height: 60px;
                            border-radius: 6px;
                          "></ion-skeleton-text>
                      </ng-container>
                      <ng-container *ngIf="isPastJobsLoaded">
                        <tidy-text
                          *ngIf="pastJobs?.length === 0"
                          [helper]="'No Past Jobs'">
                        </tidy-text>
                        <ng-container *ngIf="pastJobs?.length > 0">
                          <ion-row class="extra-top-padding">
                            <ion-col>
                              <tidy-text [header]="'Pro'"> </tidy-text>
                            </ion-col>
                            <ion-col>
                              <tidy-text [header]="'Service'"> </tidy-text>
                            </ion-col>
                            <ion-col>
                              <tidy-text [header]="'Date'"> </tidy-text>
                            </ion-col>
                          </ion-row>
                        </ng-container>
                        <ng-container *ngFor="let item of pastJobs | slice: 0:3">
                          <ion-row>
                            <ion-col>
                              <tidy-text [body]="item?.homekeeper | titlecase | abbrLastName">
                              </tidy-text>
                            </ion-col>
                            <ion-col>
                              <tidy-text [body]="item.service | titlecase">
                              </tidy-text>
                            </ion-col>
                            <ion-col>
                              <tidy-text [body]="item.start_datetime_local | parseDate: 'M/D h:mma'"> </tidy-text>
                            </ion-col>
                          </ion-row>
                        </ng-container>
                      </ng-container>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'logged-issues'">
                <tidy-card>
                  <tidy-card-button
                    (action)="goToLoggedIssues()"
                    class="arrow-to-top">
                    <tidy-row [alignLastItemRight]="true">
                      <tidy-image
                        [src]="'assets/img/alert.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Logged Issues'"> </tidy-text>
                      <ng-container *ngIf="!isLoggedIssuesLoaded">
                        <ion-skeleton-text
                          animated
                          style="
                            width: 20%;
                            height: 20px;
                            border-radius: 6px;
                          "></ion-skeleton-text>
                      </ng-container>
                      <ng-container *ngIf="isLoggedIssuesLoaded">
                        <tidy-text
                          [helper]="unresolvedLoggedIssues?.length">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [helper]="'unresolved'">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'bills'">
                <tidy-card>
                  <tidy-card-button
                    (action)="goToBillsPage()"
                    class="arrow-to-top">
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/invoice.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Bills'"> </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="!isBillsLoaded">
                      <ion-skeleton-text
                        animated
                        style="
                          width: 100%;
                          height: 60px;
                          border-radius: 6px;
                        "></ion-skeleton-text>
                    </ng-container>
                    <ng-container *ngIf="isBillsLoaded">
                      <ng-container *ngIf="invoices?.length === 0">
                        <tidy-text [helper]="'No bills found'"> </tidy-text>
                      </ng-container>
                      <ng-container *ngIf="invoices?.length > 0">
                        <ion-row class="extra-top-padding">
                          <ion-col>
                            <tidy-text [header]="'Pro'"> </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [header]="'Service'"> </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [header]="'Date'"> </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [header]="'Owed'"> </tidy-text>
                          </ion-col>
                        </ion-row>
                      </ng-container>
                      <ng-container *ngFor="let item of invoices | slice: 0:3">
                        <ion-row>
                          <ion-col>
                            <tidy-text [body]="item?.pro | titlecase | abbrLastName">
                            </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [body]="item.serviceNames | titlecase">
                            </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [body]="item.dates"> </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [body]="(item?.amount_due | tcurrency)">
                            </tidy-text>
                          </ion-col>
                        </ion-row>
                      </ng-container>
                    </ng-container>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'pros'">
                  <tidy-card
                    class="arrow-to-top">
                    <tidy-row
                      *ngIf="pros?.length <= 3"
                      (click)="goToProsPage()"
                      style="cursor: pointer;"
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/pros.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Pros'"> </tidy-text>
                      <tidy-text
                        *ngIf="pros?.length > 3"
                        [actionBody]="'View All'"
                        class="link"
                        (action)="goToProsPage()">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row
                      *ngIf="pros?.length > 3"
                      (click)="goToProsPage()"
                      style="cursor: pointer;"
                      class="extra-bottom-padding"
                      [alignLastItemRight]="true">
                      <tidy-image
                        [src]="'assets/img/pros.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Pros'"> </tidy-text>
                      <tidy-text
                        *ngIf="pros?.length > 3"
                        [actionBody]="'View All'"
                        class="link"
                        (action)="goToProsPage()">
                      </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="!isProsLoaded">
                      <ion-skeleton-text
                        animated
                        style="
                          width: 100%;
                          height: 60px;
                          border-radius: 6px;
                        "></ion-skeleton-text>
                    </ng-container>
                    <ng-container *ngIf="isProsLoaded">
                      <ng-container *ngIf="pros?.length === 0">
                        <tidy-text [helper]="'No pros found'"> </tidy-text>
                      </ng-container>
                      <ng-container *ngIf="pros?.length > 0">
                        <ion-row class="extra-top-padding">
                          <ion-col size="3">
                            <tidy-text [header]="'Pro'"> </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text [header]="'Service'"> </tidy-text>
                          </ion-col>
                        </ion-row>
                      </ng-container>
                      <ng-container *ngFor="let item of pros | slice: 0:3">
                        <ion-row>
                          <ion-col size="3">
                            <tidy-text
                              [actionBody]="item?.name | titlecase | abbrLastName"
                              class="link"
                              (action)="goToPro(item?.id)">
                            </tidy-text>
                          </ion-col>
                          <ion-col>
                            <tidy-text
                              [body]="item.serviceNames | titlecase | truncate:60">
                            </tidy-text>
                          </ion-col>
                        </ion-row>
                      </ng-container>
                    </ng-container>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'to-dos'">
                <tidy-card>
                  <tidy-card-button
                    (action)="goToToDosPage()"
                    class="arrow-to-top">
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/inventory.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'To-Do Lists'"> </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="!isToDosLoaded">
                      <ion-skeleton-text
                        animated
                        style="
                          width: 100%;
                          height: 60px;
                          border-radius: 6px;
                        "></ion-skeleton-text>
                    </ng-container>
                    <ng-container *ngIf="isToDosLoaded">
                      <ng-container *ngIf="toDosList?.length === 0">
                        <tidy-text [helper]="'No To-Do Lists found'"> </tidy-text>
                      </ng-container>
                      <ng-container *ngFor="let item of toDosList | slice: 0:3">
                        <ion-row>
                          <ion-col>
                            <tidy-text [body]="item.title"> </tidy-text>
                          </ion-col>
                        </ion-row>
                      </ng-container>
                    </ng-container>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'upcoming-maintenances'">
                <ng-container *ngIf="!isMaintenancesLoaded">
                  <tidy-card>
                    <tidy-row
                      class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/tool-box.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Next Suggested Maintenances'">
                      </tidy-text>
                    </tidy-row>
                    <ion-row>
                      <ion-skeleton-text
                        animated
                        style="
                          width: 100%;
                          height: 60px;
                          border-radius: 6px;
                        "></ion-skeleton-text>
                    </ion-row>
                  </tidy-card>
                </ng-container>
                <ng-container *ngIf="isMaintenancesLoaded">
                  <maintenances-cards
                    [upcomingMaintenances]="upcomingMaintenances"
                    [addressId]="address.id"></maintenances-cards>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.value === 'access-notes'">
                <tidy-card>
                  <tidy-row
                    [alignLastItemRight]="true"
                    class="extra-bottom-padding">
                    <tidy-image
                      [src]="'assets/img/notes.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text [header]="'Access Notes'"> </tidy-text>
                    <tidy-text
                      [body]="'Edit'"
                      (action)="goToEditAccess()"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngIf="!isAccessNotesLoaded">
                    <ion-skeleton-text
                      animated
                      style="
                        width: 100%;
                        height: 60px;
                        border-radius: 6px;
                      "></ion-skeleton-text>
                  </ng-container>
                  <ng-container *ngIf="isAccessNotesLoaded">
                    <tidy-row
                      class="extra-bottom-padding"
                      *ngIf="address?.parking_notes || address?.paid_parking || address?.parking_spot ">
                      <tidy-text [body]="'How to park:'"> </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        *ngIf="address?.paid_parking !== null"
                        [body]="address?.paid_parking ? 'Paid parking' : 'Free parking'">
                      </tidy-text>
                      <tidy-text
                        *ngIf="address?.parking_spot"
                        [body]="parkingSpots[address?.parking_spot] + '. '">
                      </tidy-text>
                      <tidy-text
                        *ngIf="address?.parking_notes === null"
                        [helper]="'No parking notes added.'">
                      </tidy-text>
                      <tidy-text
                        *ngIf="address?.parking_notes"
                        [body]="address?.parking_notes"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row class="extra-bottom-padding">
                      <tidy-text [body]="'How to get in:'"> </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        *ngIf="address?.home_access !== null && address?.home_access !== ''"
                        [body]="address?.home_access"
                        [translate]="false">
                      </tidy-text>
                      <tidy-text
                        *ngIf="address?.home_access == null || address?.home_access == ''"
                        [helper]="'No access notes added.'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text [body]="'How to close up:'"> </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        *ngIf="address?.home_close !== null && address?.home_close !== ''"
                        [body]="address?.home_close"
                        [translate]="false">
                      </tidy-text>
                      <tidy-text
                        *ngIf="address?.home_close == null || address?.home_close == ''"
                        [helper]="'No closing notes added.'">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                  <ng-container
                    *ngIf="getInUseDevices(address?.remote_access_address?.remote_access_devices)?.length > 0">
                    <tidy-row class="extra-top-padding extra-bottom-padding">
                      <tidy-divider> </tidy-divider>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'Smart Lock Integrated: The RemoteLock code will be available for the pro the day of the job.'">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'lock-integrations'">
                <tidy-card *ngIf="userRole !== 'member' && !hasLockIntegration">
                  <tidy-row class="vertical-align-center extra-bottom-padding">
                    <tidy-image
                      [src]="'assets/img/smart-door.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text [header]="'Lock Integrations'"> </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Control your vendor access by integrating with your smartlock or access control systems. Generate one time use keys and more.'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [actionBody]="'Get Started'"
                      class="link"
                      (action)="goToLockIntegrationsPage()">
                    </tidy-text>
                  </tidy-row>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'remotelock-integrations'">
                <tidy-card *ngIf="userRole !== 'member' && hasLockIntegration">
                  <tidy-card-button (action)="goToEditAddressLockPage(address)">
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        *ngIf="address?.remote_access_address"
                        [src]="'assets/img/checkmark.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-image
                        *ngIf="!address?.remote_access_address"
                        [src]="'assets/img/block.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text
                        [header]="'RemoteLock Integration'"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <ng-container
                      *ngFor="let device of getInUseDevices(address?.remote_access_address?.remote_access_devices)">
                      <tidy-row class="vertical-align-center extra-top-padding">
                        <tidy-image
                          [src]="'assets/img/lock-grey.svg'"
                          class="body-size">
                        </tidy-image>
                        <tidy-text
                          [body]="device?.device_name"
                          [translate]="false">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>
                    <tidy-row
                      class="extra-top-padding"
                      *ngIf="getInUseDevices(address?.remote_access_address?.remote_access_devices)?.length == 0">
                      <tidy-text [helper]="'No Devices Integrated'">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'thermostat-integration'">
                <tidy-card *ngIf="userRole !== 'member'">
                  <tidy-row class="vertical-align-center extra-bottom-padding">
                    <tidy-image
                      [src]="'assets/img/temperature.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text [header]="'Smart Thermostat Integration'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Set a temperature automatically for vendors to feel comfortable but keep energy bills under control.'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [actionBody]="'Get Started'"
                      class="link"
                      (action)="goToSmartThermostatPage()">
                    </tidy-text>
                  </tidy-row>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'rooms'">
                <tidy-card>
                  <ng-container *ngIf="!isRoomsLoaded">
                    <tidy-row class="extra-bottom-padding">
                      <div class="room-loading-row">
                        <tidy-image
                          [src]="'assets/img/icons/rooms/bedroom_1.svg'"
                          class="title-size">
                        </tidy-image>
                        <tidy-text [header]="'Rooms'"> </tidy-text>
                      </div>
                    </tidy-row>
                    <ion-skeleton-text
                      animated
                      style="
                        width: 100%;
                        height: 350px;
                        border-radius: 6px;
                      "></ion-skeleton-text>
                  </ng-container>
                  <ng-container *ngIf="isRoomsLoaded">
                    <tidy-row
                      [alignLastItemRight]="floors?.length"
                      class="extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/home.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text [header]="'Rooms'"> </tidy-text>
                      <tidy-text
                        [body]="'Edit Rooms'"
                        (action)="goToEditRooms()"
                        class="link"
                        *ngIf="floors?.length">
                      </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="!floors?.length">
                      <tidy-row>
                        <tidy-text [body]="'No rooms added.'"> </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [actionBody]="'Add Room'"
                          (action)="goToAddRoomsFlow()"
                          class="link">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>

                    <ng-container *ngFor="let floor of floors; let i = index">
                      <ng-container *ngIf="floor">
                        <tidy-row *ngFor="let room of floor; let last = last" class="vertical-align-center">
                          <tidy-image
                            [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                            class="body-size">
                          </tidy-image>
                          <tidy-text
                            [body]="room.name"
                            [translate]="false">
                          </tidy-text>
                        </tidy-row>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tidy-card>
              </ng-container>
              <ng-container *ngIf="item.value === 'addressItems'">
                <tidy-card>
                  <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
                    <tidy-image
                      [src]="'assets/img/task-list.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text 
                      [header]="'Restocking'">
                    </tidy-text>
                    <tidy-text 
                      [actionBody]="'Add Item'"
                      (action)="goToAddItem()"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                  <ion-skeleton-text
                    *ngIf="!isAddressItemsLoaded"
                    animated
                    style="
                      width: 100%;
                      height: 350px;
                      border-radius: 6px;">
                  </ion-skeleton-text>
                  <tidy-row *ngIf="addressItems?.length == 0">
                    <tidy-text 
                      [helper]="'No Items Added'">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngFor="let addressItem of addressItems">
                    <tidy-card-button (action)="goToItemDetails(addressItem)">
                      <tidy-row>
                        <tidy-text
                          [body]="addressItem?.name">
                        </tidy-text>
                      </tidy-row>
                    </tidy-card-button>
                  </ng-container>
                </tidy-card>
              </ng-container>
            </ng-container>
          </ion-col>
        </ion-row>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content"></app-scroll-wrapper>

import { Component, OnInit } from '@angular/core';
import { Client } from 'src/providers/client/client';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

@Component({
  selector: 'select-find-new-pros-option-onboarding',
  templateUrl: './select-find-new-pros-option-onboarding.html',
  styleUrls: ['./select-find-new-pros-option-onboarding.scss'],
})
export class SelectFindNewProsOptionOnboarding implements OnInit {
  enableFindNewPros = false;

  constructor(
    public onboardingProvider: OnboardingProvider,
    private client: Client,
    private util: Util,
    private storage: TidyStorage
  ) {}

  async ngOnInit() {
    const metadata = await this.client.getMetadata(['external.enableFindNewPros']);
    this.enableFindNewPros = metadata?.[0]?.value || false;
  }

  goToConciergeAction() {
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'createConciergeAction';
    this.onboardingProvider.priorPages.push('selectFindNewPros');
  }

  async submit() {
    const loading = await this.util.showLoading();
    try {
      await Promise.all([
        this.client.addMetadata({
          key: 'enableFindNewPros',
          value: this.enableFindNewPros,
        }),
        this.onboardingProvider.markGoalAsCompleted('selectFindNewPros')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
    } catch (error) {
      const errorMessage =
        error?.error?.message || 'Error saving find new pros preference';
      this.util.showError(errorMessage);
    } finally {
      loading.dismiss();
    }
  }
}

import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Input, ViewEncapsulation, ViewChild, AfterViewInit, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  selector: 'tidy-notification-history',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notification-history.component.scss'],
  template: `
  <mat-stepper
    *ngIf="items?.length > 0"
    #stepper
    class="tidy-stepper"
    orientation="vertical"
    [linear]="false"
    (selectionChange)="stepChange($event)"
    (click)="handleStepClick($event, stepper.selectedIndex)"
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="collapseAll(stepsRefs)"
    >

    <ng-template matStepperIcon="edit" MatStepperIconContext>
      <mat-icon class="icon-override"></mat-icon>
    </ng-template>

    <ng-template matStepperIcon="done" MatStepperIconContext>
      <mat-icon class="icon-override"></mat-icon>
    </ng-template>

    <ng-template matStepperIcon="number" MatStepperIconContext>
      <mat-icon class="icon-override"></mat-icon>
    </ng-template>

    <ng-container *ngFor="let item of items; let i = index">

      <mat-step [attr.data-happened]="item.data.happened">
        <ng-container *ngIf="item?.data.type !== 'declined'">
          <ng-template matStepLabel>
            <tidy-text
              [body]="item?.text + (item.data.happened ? ' (contacted)' : '')">
            </tidy-text>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="item?.data.type === 'declined'">
          <ng-template matStepLabel>
            <tidy-text
              [body]="item?.text + ' (declined)'">
            </tidy-text>
          </ng-template>
          <tidy-row class="vertical-align-center step-content-row" *ngIf="item?.data?.declined_reason">
            <tidy-image
              class="step-icon force-width"
              [src]="'assets/svg/information-circle-outline.svg'">
            </tidy-image>
            <tidy-text
              [body]="'Declined Reason: ' + builDeclinedString(item?.data?.declined_reason)">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row class="vertical-align-center step-content-row" *ngIf="item?.data?.declined_show_at">
          <tidy-image
            class="step-icon force-width"
            [src]="'assets/svg/calendar-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="'Declined on: ' + buildDate(item?.data?.declined_show_at)">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center step-content-row" *ngIf="item?.data?.show_at">
          <tidy-image
            class="step-icon force-width"
            [src]="'assets/svg/calendar-outline.svg'">
          </tidy-image>
          <tidy-text
            [body]="'Contacted on: ' + buildDate(item?.data?.show_at)">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="!item?.text?.includes('New Pros')">

          <tidy-row class="vertical-align-center step-content-row" *ngIf="item?.data?.homekeeper_id">
            <tidy-image
              class="step-icon force-width"
              [src]="'assets/svg/person-circle-outline.svg'">
            </tidy-image>
            <tidy-text
              class="link"
              [body]="'View Pro'"
              (action)="viewPro(item?.data?.homekeeper_id)">
            </tidy-text>
          </tidy-row>

          <ng-container *ngIf="notificationHistory?.notifications">
            <tidy-row class="vertical-align-center step-content-row" *ngIf="notificationHistory?.notifications?.app">
              <tidy-image
                class="step-icon force-width"
                [src]="notificationHistory?.notifications?.app?.status == 'sent' ? 'assets/svg/checkmark-circle-outline.svg' : 'assets/svg/alert-circle-outline.svg'">
              </tidy-image>
              <tidy-text
                [body]="notificationHistory?.notifications?.app?.status == 'sent' ? 'Notified in app' : 'In-app notifications not set up'">
              </tidy-text>
              <tidy-text
                *ngIf="notificationHistory?.notifications?.app?.status == 'sent'"
                class="link"
                [body]="' Details'"
                (action)="viewHistory(notificationHistory, 'app')">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-center step-content-row" *ngIf="notificationHistory?.notifications?.sms">
              <tidy-image
                class="step-icon force-width"
                [src]="notificationHistory?.notifications?.sms?.status == 'queued' ? 'assets/svg/checkmark-circle-outline.svg' : 'assets/svg/alert-circle-outline.svg'">
              </tidy-image>
              <tidy-text
                [body]="notificationHistory?.notifications?.sms?.status == 'queued' ? 'Notified via sms' : 'Failed to notify via sms'">
              </tidy-text>
              <tidy-text
                *ngIf="notificationHistory?.notifications?.sms?.status == 'queued'"
                class="link"
                [body]="' Details'"
                (action)="viewHistory(notificationHistory, 'sms')">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-center step-content-row" *ngIf="notificationHistory?.notifications?.email">
              <tidy-image
                class="step-icon force-width"
                [src]="notificationHistory?.notifications?.email?.status == 'OK' ? 'assets/svg/checkmark-circle-outline.svg' : 'assets/svg/alert-circle-outline.svg'">
              </tidy-image>
              <tidy-text
                [body]="notificationHistory?.notifications?.email?.status == 'OK' ? 'Notified via email' : 'Failed to notify via email'">
              </tidy-text>
              <tidy-text
                *ngIf="notificationHistory?.notifications?.email?.status == 'OK'"
                class="link"
                [body]="' Details'"
                (action)="viewHistory(notificationHistory, 'email')">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </ng-container>
      </mat-step>
    </ng-container>

  </mat-stepper>
  `,
})

export class NotificationHistoryComponent implements AfterViewInit {

  @Input() items: any;
  @ViewChild('stepper') stepper: MatStepper;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() viewProAction = new EventEmitter<string>();
  @Output() viewHistoryDetails = new EventEmitter<any>();
  @Input() notificationHistory: any;
  stepsRefs: HTMLElement[];

  readonly cdklayerLimit = 30;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const tidyStepperRef = this.elementRef.nativeElement as HTMLElement;
    const matStepperRef = tidyStepperRef.firstChild as HTMLElement;
    this.stepsRefs = Array.from(matStepperRef.children) as HTMLElement[];
    this.collapseAll(this.stepsRefs);
    this.updateIcons(this.stepsRefs);
    this.disableStepPointerCursor();
  }
  
  private disableStepPointerCursor() {
    const stepHeaders = this.elementRef.nativeElement.querySelectorAll('.mat-step-header');
    stepHeaders.forEach((header: HTMLElement, index: number) => {
      if (!this.items[index].data.happened) {
        this.renderer.setStyle(header, 'cursor', 'default');
        this.renderer.addClass(header, 'hide-caret');
      }
    });
  }

  collapseAll(matSteps: HTMLElement[], collapse = true) {
    matSteps.forEach(step => {
      const stepContent = step.children.item(1).children.item(0);
      collapse ?
        stepContent.classList.add('collapsed') :
        stepContent.classList.remove('collapsed');
    });
  }

  updateIcons(matSteps: HTMLElement[]) {
    matSteps.forEach((step, index) => {
      const stepLabel = step.firstChild as HTMLElement;
      let addingClass = 'info';
      if (this.items[index].data.type !== 'declined' && this.items[index].data.happened) {
        addingClass = 'contacted';
      }
      if (this.items[index].data.type == 'declined') {
        addingClass = 'declined';
      }
      stepLabel.classList.add(addingClass);
    });
  }

  buildDate(date) {
    const day = LuxonDateTime.fromISO(date).setZone(LuxonDateTime.local().zoneName).toFormat('ccc M/d/yy').replace(/^[a-z]/, match => match.toUpperCase());
    const time = LuxonDateTime.fromISO(date).setZone(LuxonDateTime.local().zoneName).toFormat('h:mma').toLowerCase();
    return day + ' ' + time;
  }
    
  builDeclinedString(text) {
    let newText = '';
    newText = text.replace("The pro declined,", "");
    newText = newText.replace("saying", "said");
    return newText;
  }

  viewPro(proId) {
    this.viewProAction.emit(proId);
  }

  viewHistory(history, type) {
    history.notification_type = type;
    this.viewHistoryDetails.emit(history);
  }

  handleStepClick(event: MouseEvent, index: number) {
    if (!this.items[index].data.happened) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    const isCurrentlySelected = this.stepper.selectedIndex === index;
    const stepContent = this.stepsRefs[index].children.item(1).children.item(0) as HTMLElement;
    const isExpanded = !stepContent.classList.contains('collapsed');
    if (isCurrentlySelected && isExpanded) {
      this.collapseAll(this.stepsRefs, true);
      this.focusUnfocusStep(this.stepper.selectedIndex, false);
    } else {
      this.collapseAll(this.stepsRefs, true);
      stepContent.classList.remove('collapsed');
      this.focusUnfocusStep(index);
      const selectionChange: StepperSelectionEvent = {
        selectedIndex: index,
        previouslySelectedIndex: this.stepper.selectedIndex,
        selectedStep: this.stepper.steps.toArray()[index],
        previouslySelectedStep: this.stepper.selected
      };
      this.selectionChange.emit(selectionChange);
      this.stepper.selectedIndex = index;
    }
  }

  stepChange(selectionChange: StepperSelectionEvent) {
    this.focusUnfocusStep(selectionChange.selectedIndex);
    this.focusUnfocusStep(selectionChange.previouslySelectedIndex, false);
  }

  focusUnfocusStep(index, focus = true) {
    const stepLabel = this.stepsRefs[index].firstChild as HTMLElement;
    if (stepLabel) {
      stepLabel.classList[focus ? 'add' : 'remove']('focused-step');
    } else {
      this.focusUnfocusStep(index, focus);
    }
  }


}

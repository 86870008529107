import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { UserService } from 'src/providers/user/user';
import { WindowService } from 'src/shared/providers/window.service';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { EditUserDetailsPage } from '../edit-user-details/edit-user-details';
import { AddUserToTeamPage } from 'src/pages/team/add-user-to-team/add-user-to-team';
import { TeamPage } from 'src/pages/team/team/team';
import { UsersPage } from '../users';

@Component({
  templateUrl: 'user.html'
})

export class UserPage extends TimeoutableComponent implements OnInit {

  clientInfo: any;
  errorMessage: string;
  membersList: any;
  role: string;
  showBackButton: boolean;
  teamsUserIsOn: any;
  teams: any;
  user: any;
  userAddresses: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private teamService: TeamService,
    private userService: UserService,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('User');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    try {
      await this.getPageData();
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getPageData() {
    this.user = await this.getParam('user') || await this.getUser();
    this.rightSidePanelService.setDialogPageTitle(this.user?.first_name);
    this.teams = await this.teamService.getTeams();
    this.getTeamsUserIsOn();
    this.role = this.getRole(this.user.role);
    this.clientInfo = await this.client.getClientInfo();
    this.userAddresses = this.getUserAddresses();
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getUser() {
    const userId = this.route.snapshot.paramMap.get('userId') || await this.storage.retrieve('userId');
    const usersList = await this.userService.getUsersList();
    return usersList.find((user) => user.id == userId);
  }

  getRole(role) {
    if (role == 'owner') {
      return 'Owner'
    } else if (role == 'admin') {
      return 'Admin'
    } else {
      return 'Team Manager'
    }
  }

  getTeamsUserIsOn() {
    this.teamsUserIsOn = [];
    this.teams.map((team) => {
      team.team_members.map((user) => {
        if (user.customer_member_id == this.user.id) {
          this.teamsUserIsOn.push(({
            name: team.name,
            team_member_id: user.id,
            team_id: team.id
          }));
        }
      });
    });
  }

  goToEditUserDetailsPage() {
    const params = {
      member: this.user,
      teamsUserIsOn: this.teamsUserIsOn,
      clientInfo: this.clientInfo
    }
    this.rightSidePanelService.navigateTo('edit-user', params, EditUserDetailsPage);
  }

  async goToAddUserToTeamPage() {
    try {
      await this.storage.save('userId', this.user.id)
      const params = {
        usersList: await this.getParam('usersList') || await this.userService.getUsersList(),
        user: this.user,
        teams: this.teams,
        nextPage: `user/${this.user.id}`
      }
      this.rightSidePanelService.navigateTo('add-user-to-team', params, AddUserToTeamPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToTeamPage(teamId) {
    const params = {
      teamId: teamId
    }
    await this.storage.save('teamId', teamId)
    this.rightSidePanelService.navigateTo(`team/${teamId}`, params, TeamPage);
  }

  async removeFromTeam(teamMemberId) {
    const isConvertingMemberToAdmin = this.teamsUserIsOn.length - 1 == 0;
    const confirmParams: ConfirmPageParamsModel = {
      title: (isConvertingMemberToAdmin ? 'Change to Admin?' : 'Remove From Team?'),
      body: isConvertingMemberToAdmin ? 'Removing a team manager from all teams will change their user type to admin. This means they will see all properties and users.' : '',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmRemoveFromTeam.bind(this, teamMemberId, isConvertingMemberToAdmin)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRemoveFromTeam(teamMemberId, isConvertingMemberToAdmin) {
    try {
      await this.teamService.removeUserFromTeam(teamMemberId);
      if (isConvertingMemberToAdmin) {
        const userPayload = {
          role: 'admin'
        }
        await this.userService.changeUserRole(this.user.id, userPayload);
      }
      this.getPageData();
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  async deleteUser() {
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Delete User?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmDeleteUser.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteUser() {
    try {
      await this.userService.deleteUser(this.user.id);
      this.rightSidePanelService.navigateTo('users', {}, UsersPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  getUserAddresses() {
    const array: any = [];
    if (this.user?.team_addresses_permissions) {
      this.user.team_addresses_permissions.map((team) => {
        team.addresses.map((address) => {
          const addressName = address?.address_name || (address?.address1 + (address?.address2 ? (' ' + address?.address2) : '') + ', ' + address?.add_state);
          if (!array.includes(addressName)) {
            array.push(addressName);
          }
        });
      });
    }
    return array;
  }

}

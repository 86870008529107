import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { roomTypeSelect } from 'src/shared/constants/roomTypeSelect';
import { floorSelect } from 'src/shared/constants/floorSelect';

import { RoomModel } from 'src/models/to-do.model';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { Genome } from 'src/providers/genome/genome';
import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'edit-room-details.html',
  providers: [PhotoCaptionService]
})

export class EditRoomDetailsPage implements OnInit {

  dialogParams: any;
  form: UntypedFormGroup;
  floorSelectItems = floorSelect;
  loaded: boolean;
  room: RoomModel;
  roomTypeSelectItems = roomTypeSelect;
  submitted: boolean;
  photoNoteUrls: PhotoNoteUrlModel[];
  errorMessage: string;
  addressId: number;
  isRightSideContent: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private modalController: ModalController,
    private genome: Genome,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.room = this.navCtrl.getParam('room') || this.dialogParams.room;
    this.addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
    this.createForm();
    this.rightSidePanelService.setDialogPageTitle(this.room.name + ' Info');
    this.loaded = true;
  }

  createForm() {
    let form = {
      name: [this.room.name, Validators.required],
      floor: [this.room.floor, Validators.required],
      category: [this.room.category, Validators.required]
    };
    this.form = this.fb.group(form);
  }

  async updateRoom() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const data = {
      address_room : {
        name: this.form.value.name,
        floor: this.form.value.floor,
        category: this.form.value.category
      }
    }
    try {
      await this.genome.updateRoom(this.addressId, this.room.id, data)
      this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async deleteRoom() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Room?',
      body: `Are you sure you want to delete this room?`,
      backText: 'Go Back',
      confirmText: 'Delete Room',
      confirmAction: this.confirmDeleteRoom.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteRoom() {
    try {
      await this.genome.deleteRoom(this.addressId, this.room.id);
      this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
      this.modalController.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

}

export const bookJobConfigurations = {
  add_job: {
    title: 'Book Jobs',
    confirm_button_text: 'Review Booking',
    hide_frequency: false,
    frequency_value: null,
    buildBookingData: ({ addressId, frequency, serviceTypeKey, dates, ratingChosen, hkRating, backupTimesBlocks = [] }) => {
      return {
        address_id: addressId,
        frequency: frequency,
        service_type_key: serviceTypeKey,
        dates: dates,
        rating_chosen: ratingChosen,
        hk_rating: hkRating,
        backup_times_blocks: backupTimesBlocks.map(dt => ({ date: dt.date, start_time: dt.time }))
      }
    }
  },

  add_one_time_job: {
    title: 'Add One Time Job',
    confirm_button_text: 'Review Booking',
    hide_frequency: true,
    frequency_value: { viewValue: 'One Time', value: 'once' },
    buildBookingData: ({ addressId, serviceTypeKey, dates, ratingChosen, hkRating, backupTimesBlocks = [] }) => {
      return {
        address_id: addressId,
        booking: {
          service_type_key: serviceTypeKey,
          preferred_start_date: dates[0].date,
          preferred_start_time: dates[0].time,
        },
        rating_options: {hk_rating: hkRating, rating_chosen: ratingChosen},
        homekeeper_ids: dates[0].homekeeper_ids,
        backup_times_blocks: backupTimesBlocks.map(dt => ({ date: dt.date, start_time: dt.time }))
      };
    }
  },

  reschedule_plan: {
    title: 'Reschedule',
    confirm_button_text: 'Review Booking',
    hide_frequency: false,
    frequency_value: null,
    buildBookingData: ({ frequency, serviceTypeKey, dates, ratingChosen, hkRating }) => {
      return {
        frequency: frequency,
        service_type_key: serviceTypeKey,
        dates: dates,
        backup_times_blocks: [],
        rating_chosen: ratingChosen,
        hk_rating: hkRating
      };
    }
  },

  reschedule_job: {
    title: 'Reschedule',
    confirm_button_text: 'Review Booking',
    hide_frequency: true,
    frequency_value: { viewValue: 'One Time', value: 'once' },
    buildBookingData: ({ serviceTypeKey, dates, ratingChosen, hkRating, backupTimesBlocks = [] }) => {
      return {
        booking: {
          service_type_key: serviceTypeKey,
          preferred_start_date: dates[0].date,
          preferred_start_time: dates[0].time,
        },
        rating_options: {hk_rating: hkRating, rating_chosen: ratingChosen},
        homekeeper_ids: dates[0].homekeeper_ids,
        backup_times_blocks: backupTimesBlocks.map(dt => ({ date: dt.date, start_time: dt.time }))
      };
    }
  }
}

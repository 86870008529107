import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class Homekeeper {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  getHkNotAvailableReason(hkId, serviceKey) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/not-bookable-reason?homekeeper_id=${hkId}&service_type_key=${serviceKey}`;
    return this.httpClient.get(url);
  }

  getHkHistory(hkId = null) {
    const params = hkId ? `?homekeeper_id=${hkId}` : '';
    const url = `api/v1/customer/cleanings/history${params}`;
    return this.httpClient.get(url);
  }

}

<div class="desktop-header" *ngIf="!isMobileBreakpoint">
  <tidy-text
    [title]="'Tasks'">
  </tidy-text>
  <div class="tasks-desktop-nav-search">
    <form [formGroup]="form" novalidate action="#">
      <tidy-search-input
        formControlName="search"
        [placeholder]="'Search'"
        [icon]="'assets/svg/search-outline.svg'">
      </tidy-search-input>
    </form>
  </div>
  <div class="header-button" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{closeDisabled: true}">
    <tidy-image
      [src]="'assets/img/filter-white.svg'"
      style="height: 12px;">
    </tidy-image>
    <tidy-text
      [headerButton]="'Filter'"
      class="white">
    </tidy-text>
  </div>
  <div class="header-button" (click)="goToRecurringTasks()">
    <tidy-image
      [src]="'assets/img/recurring.svg'"
      style="height: 12px;">
    </tidy-image>
    <tidy-text
      [headerButton]="'Recurring'"
      class="white">
    </tidy-text>
  </div>
  <div class="header-button" (click)="goToArchivedTasks()">
    <tidy-image
      [src]="'assets/img/archive.svg'"
      style="height: 12px;">
    </tidy-image>
    <tidy-text
      [headerButton]="'Archived'"
      class="white">
    </tidy-text>
  </div>
  <div class="header-button primary" (click)="addIssue()">
    <tidy-image
      [src]="'assets/img/add-white.svg'"
      style="height: 12px;">
    </tidy-image>
    <tidy-text
      [headerButton]="'Add Task'"
      class="white">
    </tidy-text>
  </div>
  <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
</div>

<div *ngIf="isMobileBreakpoint" style="padding-top: 45px">
  <div class="mobile-nav-search issues-mobile-container">
    <form [formGroup]="form" novalidate action="#">
      <tidy-input
        #searchInput
        class="search-input"
        [icon]="'assets/svg/search-outline.svg'"
        [activeIcon]="'assets/svg/search-tidy-green.svg'"
        [noBorder]="true"
        type="search"
        formControlName="search"
        [label]="'Search'"
        [form]="form">
      </tidy-input>
    </form>
  </div>
  <div style="display: flex; gap: 10px;">
    <tidy-button
      [image]="'assets/icon/filter-white.png'"
      [text]="'Filter'"
      [smallBody]="true"
      [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{closeDisabled: true}"
      class="secondary desktop-nav-button"
      style="flex: 0 0 calc(50% - 5px);">
    </tidy-button>
    <tidy-button
      [image]="'assets/img/recurring.svg'"
      [text]="'Recurring Tasks'"
      [smallBody]="true"
      [action]="goToRecurringTasks.bind(this)"
      class="secondary desktop-nav-button"
      style="flex: 0 0 calc(50% - 5px);">
    </tidy-button>
  </div>
</div>

<ng-container *ngFor="let chip of chips">
  <ion-chip class="filter-chip">
    <ion-label>{{ chip.displayParentName | titlecase | translate}}: {{ chip.name | titlecase | translate }}</ion-label>
    <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
  </ion-chip>
</ng-container>

<mat-menu #menu="matMenu" class="filter-menu">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
        <mat-checkbox
          class="checkbox"
          [checked]="getNodeChecked(node)"
          (click)="$event.stopPropagation()"
          (change)="checkNode(node, $event)">
          {{ node.name | titlecase | translate }}
        </mat-checkbox>
      </button>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <button
        class="node-button-padding"
        mat-menu-item
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
        {{ node.name | titlecase | translate }}
      </button>
    </mat-tree-node>
  </mat-tree>
</mat-menu>

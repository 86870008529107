import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbrLastName'
})
export class TidyAbbreviateLastName implements PipeTransform {
  transform(name: string, lastNameOnly: boolean = false) {

    if (lastNameOnly) {
      return name ? `${name[0]}.` : '';
    }

    const names = name.split(/\s+/);

    const firstName = names[0];
    const lastNameAbbr = this.abbreviateLastName(names);
    return `${firstName}${lastNameAbbr}`;
  }

  abbreviateLastName(names: Array<string>) {
    const lastNameIndex = (names.length - 1);

    if (lastNameIndex > 0 && names[lastNameIndex]) {
      return ` ${names[lastNameIndex][0]}.`;
    }
    return '';
  }
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Your Branding'"
  >
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"
></ng-container>

<ng-template #content>
  <form [formGroup]="radioForm">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"
      ></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text [header]="'Background'"> </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text [body]="'Select desktop background:'"> </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-radio-button
              formControlName="background"
              [form]="radioForm.controls.background"
              [items]="backgroundOptions"
              (optionChange)="backgroundSelection($event);">
            </tidy-radio-button>
          </tidy-row>

          <form [formGroup]="form">
            <tidy-row *ngIf="backgroundOption === 'color'" class="field-top-padding">
              <tidy-input
                formControlName="color"
                [form]="form.controls.color"
                [label]="'Color Hex Code (ex: #41CAB7)'"
                [errorMessage]="'Please enter a valid color hex.'"
                [icon]="'assets/svg/color-palette-outline.svg'"
              >
              </tidy-input>
            </tidy-row>
          </form>

          <!--<ng-container *ngIf="backgroundOption === 'image'">
            <tidy-row
              *ngIf="backgroundUrl"
              class="text-center extra-bottom-padding">
              <tidy-image class="full-width" [src]="backgroundUrl"></tidy-image>
            </tidy-row>
            <tidy-row *ngIf="!backgroundAttachment" class="field-top-padding">
              <tidy-text
                [actionBody]="'Upload a background image'"
                (action)="addBackgroundAttachment()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="backgroundAttachment" class="field-top-padding">
              <tidy-row>
                <tidy-text [body]="'Attachment:'"> </tidy-text>
              </tidy-row>
              <tidy-two-column-row>
                <tidy-text [body]="backgroundAttachment.filename"> </tidy-text>
                <tidy-text
                  [actionBody]="'remove'"
                  (action)="removeBackground()"
                  class="link red">
                </tidy-text>
              </tidy-two-column-row>
            </tidy-row>
          </ng-container>-->

          <tidy-error-message [text]="backgroundErrorMessage">
          </tidy-error-message>

          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-text [header]="'Logo'"> </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text [body]="'Select app logo:'"> </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-radio-button
              formControlName="logo"
              [form]="radioForm.controls.logo"
              [items]="logoOptions"
              (optionChange)="logoSelection($event);">
            </tidy-radio-button>
          </tidy-row>

          <ng-container *ngIf="logoOption === 'image'">
            <tidy-row *ngIf="logoUrl" class="text-center  field-top-padding extra-bottom-padding">
              <tidy-image class="full-width" [src]="logoUrl"></tidy-image>
            </tidy-row>
            <tidy-row *ngIf="!logoAttachment" class="field-top-padding">
              <tidy-text
                [actionBody]="'Upload a Logo image'"
                (action)="addLogoAttachment()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="logoAttachment" class="field-top-padding">
              <tidy-row>
                <tidy-text [body]="'Attachment:'"> </tidy-text>
              </tidy-row>
              <tidy-two-column-row>
                <tidy-text [body]="logoAttachment.filename"> </tidy-text>
                <tidy-text
                  [actionBody]="'remove'"
                  (action)="removeLogo()"
                  class="link red">
                </tidy-text>
              </tidy-two-column-row>
            </tidy-row>
          </ng-container>

          <tidy-error-message [text]="logoErrorMessage"> </tidy-error-message>
        </tidy-card>

        <tidy-error-message [text]="errorMessage">
        </tidy-error-message>

        <tidy-row>
          <tidy-button
            [text]="'Save Changes'"
            [action]="submit.bind(this)"
            [disabled]="!form.valid && backgroundOption === 'color'"
            class="primary">
          </tidy-button>
        </tidy-row>

      </ng-container>
      </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

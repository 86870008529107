import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Addresses } from "src/providers/customer/addresses";
import { BookJob } from 'src/providers/book-job/book-job';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Pros } from 'src/providers/pros/pros';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';

import { Util } from 'src/shared/util/util';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { GuestReservationSource } from 'src/models/guest-reservations';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';

import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'automatic-booking-form',
  templateUrl: './automatic-booking-form.component.html',
  styleUrls: ['./automatic-booking-form.component.scss']
})

export class AutomaticBookingFormComponent extends TimeoutableComponent implements OnInit {

  @Input() isRightSideContent: boolean;
  @Input() dialogParams: any;
  @Input() cameFromOnboarding: boolean;
  address: any;
  automaticBooking: boolean;
  backPage: string;
  cameFromAddIntegration: boolean;
  jobItems: TidySelectStringValueModel[];
  jobsList: string;
  errorMessage: string;
  findNewPro: boolean;
  form: UntypedFormGroup;
  payNewProsOptions: any;
  proCount: number;
  maxDaysItems: any;
  serviceCost: any;
  substitutesOff: boolean;
  timeRangeErrorMessage: string;
  times: {
    start: TidySelectStringValueModel[];
    end: TidySelectStringValueModel[];
  };
  updatedSettings = {
    billing: {
      charge: {type: ''}
    }
  };
  waterfallSettings: any;
  integration: GuestReservationSource;
  jobsCount: number = 0;
  jobControls: string[] = ['job0'];
  numberDaysPriorToCheckInItems: any[] = [];
  earliestStartControls: string[] = [];
  latestEndControls: string[] = [];
  didAddDirectIntegration: boolean;
  integrationMessage: string;

  constructor(
    private addresses: Addresses,
    private bookJobService: BookJob,
    private card: Card,
    private client: Client,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService,
    public onboardingProvider: OnboardingProvider
  ) {
    super();
    this.form = this.fb.group({
      startTime: [''],
      endTime: [''],
      earlestStart0: [''],
      latestEnd0: [''],
      job0: ['', Validators.required],
      payNewPros: [],
      maxDays: [''],
      numberDaysPriorToCheckIn: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.numberDaysPriorToCheckInItems = this.getNumberDaysPriorToCheckInItems();
      this.maxDaysItems = this.getMaxDaysItems();
      this.getFormFieldOptions();
      const settings = await this.client.getClientSettings();
      this.updateTimeOptions();
      if (this.cameFromOnboarding) {
        this.jobItems = this.guestReservations.getCleaningOptions();
        this.automaticBooking = true;
        this.patchDefaultValues();
        this.form.patchValue({
          payNewPros: settings.billing.charge.type,
          maxDays: 0,
        });
        this.loaded = true;
        return;
      }
      this.cameFromAddIntegration = await this.getParam('cameFromAddIntegration');
      this.address = await this.getParam('address') || await this.getAddress();
      this.jobItems = await this.getJobItems();
      this.rightSidePanelService.setDialogPageTitle(this.cameFromAddIntegration ? 'Integration Added' : 'Automatic Booking');
      this.backPage = localStorage.getItem('automaticBookingAddressBackPage') || 'automatic-booking';
      const serviceCategory = 1;
      const proData = await this.pros.getPros(serviceCategory, this.address.id);
      this.proCount = this.getProCount(proData);
      this.automaticBooking = this.address?.address_automatic_booking_setting?.automatic_booking_enabled;
      if (this.automaticBooking) {
        this.form.patchValue({
          startTime: this.address.address_automatic_booking_setting.booking_start_time_no_earlier_than, 
          endTime: this.address.address_automatic_booking_setting.booking_end_time_no_later_than
        });
        await this.createAndPatchJobForm();
      } else {
        this.patchDefaultValues();
      }
      this.integration = await this.getParam('integration');
      this.form.patchValue({
        payNewPros: settings.billing.charge.type,
        maxDays: this.integration?.address_automatic_booking_setting?.max_days_to_perform_job_after_checkout || 0,
      });
      this.jobsList = this.getJobsList();
      this.didAddDirectIntegration = this.integration?.address_automatic_booking_setting == null;
      if (this.cameFromAddIntegration) this.fetchReservationStatus();
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  patchDefaultValues() {
    const defaultTimesOption = { startTime: '10:30', endTime: '16:00'};
    this.form.patchValue(defaultTimesOption);
    this.form.patchValue({ job0: this.jobItems[2].value});
    this.form.patchValue({ numberDaysPriorToCheckIn: 28 });
  }

  fetchReservationStatus() {
    if (this.integration.guest_reservation_fetch_status == 'pending' && !this.didAddDirectIntegration) {
      this.integrationMessage = `Sync Pending. It looks like the iCal link is valid, but sometimes it takes a few hours to sync data or you don't have any guest reservations yet. Please check your "Schedule" section later to ensure everything looks right.`;
    } else if (this.integration.guest_reservation_fetch_status == 'pending' && this.didAddDirectIntegration) {
      this.integrationMessage = `Sync Pending. Please check your "Schedule" section later to ensure everything looks right.`;
    } else {
      this.integrationMessage = `Sync Success! You can view all your synced guest reservations in the "Schedule" section.`;
    }
  }

  getNumberDaysPriorToCheckInItems() {
    const daysArray = [];
    for (let i = 1; i <= 180; i++) {
      daysArray.push({
        viewValue: `${i} ${i > 1 ? (new TranslationPipe()).transform('days before check in') : (new TranslationPipe()).transform('day before check in')}`,
        value: i
      });
    }
    return daysArray;
  }

  async createAndPatchJobForm() {
    const automaticBookingSettings = await this.guestReservations.getAutomaticBookingSettings(this.address.id);
    automaticBookingSettings.map((setting, index) => {
      if (index !==0) {
        this.jobControls.push(`job${this.jobsCount}`);
        this.form.addControl(`job${this.jobsCount}`, this.fb.control('', Validators.required));
      } else {
        this.form.patchValue({ numberDaysPriorToCheckIn: setting?.book_when_days_left_to_check_in_date_is_within});
      }
      this.form.get(`job${this.jobsCount}`).setValue(setting.required_team_service.key);
      this.jobsCount += 1;
    });
  }

  async getJobItems() {
    const jobItems = await this.addresses.getPriceTable(this.address.id);
    const filteredJobItems = jobItems.filter(job => !job.name.includes('2 Pros'));
    return filteredJobItems.map(job => ({ viewValue: job.name.replace(/\b\w/g, char => char.toUpperCase()), value: job.key }));
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  getProCount(proData) {
    let count = 0;
    proData.approved.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        count += 1;
      }
    });
    proData.favorited.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        count += 1;
      }
    });
    return count;
  }

  async getAddress() {
    const addressId = this.route.snapshot.paramMap.get('addressId') || this.dialogParams.address.id;
    const addresses = await this.client.getAddresses();
    return addresses.find(address => address.id == addressId);
  }

  updateTimeOptions() {
    this.checkTimeRange(this.form.value.startTime, this.form.value.endTime, this.form.value.job0);
    this.times = this.guestReservations.getAutomaticBookingTimes(this.form.value.startTime, this.form.value.endTime);
  }

  checkTimeRange(startTime, endTime, cleaningTime){
    const defaultLimit = 90;
    const cleaningInMinutes = this.guestReservations.convertStringToMinutes(this.guestReservations.getCleaningHours(cleaningTime));
    const startInMinutes = this.guestReservations.convertStringToMinutes(startTime);
    const endInMinutes = this.guestReservations.convertStringToMinutes(endTime);
    const limitTimeWindow = defaultLimit + cleaningInMinutes;
    const timeWindow = endInMinutes - startInMinutes;
    const diplayLimit: number = limitTimeWindow/60;
    const diplayCleaning: number = cleaningInMinutes/60;
    if(timeWindow < limitTimeWindow){
      this.timeRangeErrorMessage = `To support same day check ins and check outs, we recommend having an "earliest start" and "latest finish" at least ${diplayLimit} hours apart for ${diplayCleaning} hour jobs.`;
    } else {
      this.timeRangeErrorMessage = '';
    }
  }

  async save() {
    if (!this.address.address_automatic_booking_setting) {
      return this.errorMessage = 'Please add at least one integration for this address to turn on automatic booking.';
    }
    if (this.timeRangeErrorMessage) {
      return this.errorMessage = this.timeRangeErrorMessage;
    }
    try {
      const automaticBookingPayload = {
        booking_start_time_no_earlier_than: this.form.value.startTime,
        booking_end_time_no_later_than: this.form.value.endTime,
        automatic_booking_enabled: this.automaticBooking,
        max_days_to_perform_job_after_checkout: this.form.value.maxDays,
        book_when_days_left_to_check_in_date_is_within: this.form.value.numberDaysPriorToCheckIn
      }
      if (this.jobControls.length == 1) {
        automaticBookingPayload['bookable_service_key'] = this.form.value.job0;
      } else {
        let bookableServiceKeys = '';
        this.jobControls.map((control, index) => {
          bookableServiceKeys += this.form.get(control).value;
          if (index < this.jobControls.length - 1) {
            bookableServiceKeys += ',';
          }
        });
        automaticBookingPayload['bookable_service_keys'] = bookableServiceKeys;
      }
      let automaticBookingId = this.address.address_automatic_booking_setting.id;
      if (this.cameFromAddIntegration && !this.didAddDirectIntegration) {
        automaticBookingId = this.integration.address_automatic_booking_setting.id;
      }
      await Promise.all([
        this.guestReservations.updateAddressAutomaticBooking(automaticBookingPayload, automaticBookingId),
        this.onboardingProvider.markGoalAsCompleted('enableAutomaticBooking')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      const addressResponse = await this.client.getAddresses(true);
      this.goToSuccessPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  getKey(): string {
    if (!this.substitutesOff && this.findNewPro) {
      return 'substitutes_on';
    } else if (this.substitutesOff && this.findNewPro) {
      return 'substitutes_off';
    } else {
      return 'never_use_tidy';
    }
  }

  async goToSuccessPage() {
    let successParams = {};
    if (this.automaticBooking) {
      const hasCreditCard = await this.card.hasValidCard();
      const doesNotAllowNewPros = false //this.waterfallSettings.key == 'never_use_tidy';
      if (hasCreditCard || doesNotAllowNewPros) {
        successParams = {
          header: 'Automatic Booking Enabled',
          body: 'It may take up to 5 minutes for job requests for your synced reservations to appear.',
          buttonText: 'Ok',
          buttonRoute: 'automations/summary'
        }
        this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
      } else {
        const params = {
          cameFromAutomaticBooking: true,
          goToMyProsAfter: true
        };
        const url = 'payment-methods/payment-method-form';
        this.rightSidePanelService.navigateTo(url, params);
      }
    } else {
      successParams = {
        header: 'Automatic Booking Disabled',
        body: '',
        buttonText: 'Ok',
        buttonRoute: 'automations/summary'
      };
      this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
    }
  }

  learnMoreAutomaticBooking() {
    this.util.openUrl('https://help.tidy.com/integrations');
  }

  goToIntegrations() {
    localStorage.setItem('integrationsBackPage', `automatic-booking-property/${this.address.id}`)
    this.navCtrl.navigateForward('integrations/all');
  }

  goToMyPros() {
    localStorage.setItem('addressId', this.address.id);
    localStorage.setItem('myProsBackPage', `automatic-booking-property/${this.address.id}`);
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
    this.navCtrl.navigateForward(url);
  }

  getFormFieldOptions() {
    this.payNewProsOptions = [
      {
        viewValue: 'Charge in advance (save 10%)',
        value: 'before_cleaning'},
      {
        viewValue: 'Charge after job',
        value: 'after_cleaning'
      }
    ];
  }

  updateFindNewPro() {
    this.findNewPro = !this.findNewPro;
  }

  getMaxDaysItems() {
    return [
      {
        viewValue: 'The same day as the checkout',
        value: 0
      },
      {
        viewValue: '1 day after checkout',
        value: 1
      },
      {
        viewValue: '2 days after checkout',
        value: 2
      },
      {
        viewValue: '3 days after checkout',
        value: 3
      }
    ]
  }

  goToLearnMoreMaxDays() {
    this.util.openUrl('https://help.tidy.com/automatic-booking#ARN35');
  }

  addAnotherJob() {
    this.jobsCount += 1;
    const controlName = `job${this.jobsCount}`;
    this.jobControls.push(controlName);
    this.form.addControl(controlName, this.fb.control('', Validators.required));
    setTimeout(() => {
      if (this.form.get(controlName)) {
        let index = this.jobsCount - 1;
        while (this.jobControls.some(control => this.form.get(control).value === this.jobItems[index]?.value)) {
          index += 1;
        }
        this.form.get(controlName).setValue(this.jobItems[index]?.value || null);
        this.jobsList = this.getJobsList();
      }
    });
  }

  removeJob(controlName: string) {
    this.jobsCount -= 1;
    this.jobControls = this.jobControls.filter(name => name !== controlName);
    this.form.removeControl(controlName);
    this.jobsList = this.getJobsList();
  }

  getJobsList() {
    let jobsList = '';
    this.jobControls.map((jobControl, index) => {
      const jobItem = this.jobItems.find((item) => item.value == this.form.get(jobControl).value);
      if (index == 0) {
        jobsList += jobItem.viewValue;
      } else if (index == this.jobControls.length - 1) {
        jobsList += ` ${new TranslationPipe().transform('and')} ${jobItem.viewValue}`;
      } else {
        jobsList += `, ${jobItem.viewValue}`;
      }
    });
    return jobsList;
  }

  goToMyProSettings() {
    this.navCtrl.navigateForward('pro-settings');
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Edit To-Do'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <tidy-card>
          <form [formGroup]="form">
            <tidy-row class="field-bottom-padding">
              <tidy-auto-complete
                [label]="'To-Do'"
                [showGoogleIcon]="false"
                formControlName="title"
                [options]="toDoAutoComplete"
                [form]="form.controls.title"
                [icon]="'assets/svg/list-circle-outline.svg'"
                [isToSearchBetweenOptions]="true"
                [submitted]="submitted"
                [errorMessage]="'Please enter the name of the To-Do'">
              </tidy-auto-complete>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-textarea
                [label]="'Details (optional)'"
                formControlName="note"
                [form]="form.controls.note"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [activeIcon]="'assets/svg/information-circle-outline.svg'">
              </tidy-textarea>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/stopwatch-outline.svg'"
                [label]="'Estimated Time'"
                [form]="form.controls.time"
                formControlName="time"
                [items]="estimatedTimes">
              </tidy-select>
            </tidy-row>
            <tidy-row [alignLastItemRight]="true">
              <tidy-checkbox
                [(checked)]="important"
                [items]="[{viewValue: 'Mark as Important', value: false}]"
                [initValue]="important">
              </tidy-checkbox>
              <tidy-text
                class="link"
                [body]="'Add Photo'"
                (action)="takePhoto()">
              </tidy-text>
            </tidy-row>
            <photo-caption
              [photoUrls]="photoNotesUrls"
              [type]="'task'"
              (deletePhoto)="maskImageAsDeleted($event)">
            </photo-caption>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-text
                [header]="'Associate Item (Optional)'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="roomObjectsItems?.length == 1">
              <tidy-text
                [helper]="'You haven\'t added items. You can add items to associate with To-Dos, track maintenance & more'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [actionBody]="'here'"
                (action)="goToAddressPage()"
                class="link">
              </tidy-text>
              <tidy-text
                [helper]="'.'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="roomObjectsItems?.length == 1 && numberOfRequiredObjects == 0">
              <tidy-text
                [helper]="'No items added.'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="roomObjectsItems?.length == 1 && numberOfRequiredObjects > 0">
              <tidy-text
                [helper]="'No items added. Please add an item before adding this custom field.'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [actionBody]="'View Property'"
                (action)="goToAddressPage()"
                class="link">
              </tidy-text>
            </ng-container>
            <tidy-row *ngIf="roomObjectsItems?.length > 1">
              <tidy-radio-button
                formControlName="roomObject"
                [form]="form.controls.roomObject"
                [items]="roomObjectsItems"
                (optionChange)="selectRoomObject($event)">
              </tidy-radio-button>
            </tidy-row>
            <ng-container *ngIf="selectedRoomObject">
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [header]="'Track Inventory'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="selectedRoomObject?.min_quantity && hasCountFieldAdded">
                <tidy-text
                  [body]="'We create low stock alerts if the current quantity goes below'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="selectedRoomObject?.min_quantity">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [actionBody]="'add a custom count field'"
                  (action)="addCustomCountField()"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="'.'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="selectedRoomObject?.min_quantity && !hasCountFieldAdded">
                <tidy-text
                  [body]="'We create low stock alerts if the current quantity goes below'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="selectedRoomObject?.min_quantity">
                </tidy-text>
                <tidy-text
                  [body]="'. To have pros update the current inventory,'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [actionBody]="'add a custom count field'"
                  (action)="addCustomCountField()"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="'.'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="!selectedRoomObject?.min_quantity">
                <tidy-text
                  [body]="'You don\'t have inventory tracking set up for this item. If you want pros update the current inventory, add a minimum quantity + current quantity to the room item in this property.'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [actionBody]="'View Property'"
                  (action)="goToAddressPage()"
                  class="link">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </form>
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="!isAddingCustomFields">
          <tidy-text
            [actionBody]="'Add Custom Field'"
            class="link"
            (action)="expandFields()">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="isAddingCustomFields">
          <tidy-row [alignLastItemRight]="true" style="margin-bottom: 5px">
            <tidy-text
              [header]="'Custom Fields'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Add Field'"
              class="link"
              (action)="addCustomField()">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="customFieldForm?.length">
            <tidy-text
              [body]="'Your pro will be asked to fill out the following fields. Need help?'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'Contact Concierge'"
              class="link"
              (action)="goToContactConciergePage()">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <tidy-row>
              <tidy-text
                [body]="'Type: Select'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Options: Done / Not Done'">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>

        <tidy-card *ngFor="let customForm of customFieldForm; let i = index">
          <form [formGroup]="customFieldForm[i].form">
            <tidy-row class="extra-bottom-padding">
              <tidy-input
                [label]="'Field Label (what should the pro do?)'"
                formControlName="label"
                [form]="customForm.form.controls.label"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [activeIcon]="'assets/svg/information-circle-outline.svg'"
                [customMask]="{ maxLenghth: 40 }"
                [submitted]="submitted"
                [errorMessage]="'Please enter a label.'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'Field Type'"
                [form]="customForm.form.controls.type"
                formControlName="type"
                [items]="customFieldTypeItems"
                (optionChange)="selectFieldType($event, i)"
                [submitted]="submitted"
                [errorMessage]="'Please select a field type.'">
              </tidy-select>
            </tidy-row>
            <tidy-row *ngIf="selectedFieldType">
              <tidy-checkbox
                [items]="[{viewValue: 'Make this required for the pro', value: true}]"
                (click)="selectRequired(customForm, customForm.form.value.required)"
                [initValue]="customForm.form.value.required">
              </tidy-checkbox>
            </tidy-row>
            <ng-container *ngIf="customForm.numberOfRequiredOptions > 0">
              <tidy-row [alignLastItemRight]="true">
                <tidy-text
                  [header]="'Options'"
                  style="margin-right: 5px">
                </tidy-text>
                <tidy-text
                  [actionBody]="'Add Option'"
                  class="link"
                  (action)="addOption(i)">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let option of customForm.options; let index = index">
                <tidy-row>
                  <tidy-text
                    [body]="'Option ' + (index + 1)"
                    style="margin-right: 5px">
                  </tidy-text>
                  <tidy-text
                    [actionBody]="'X'"
                    class="link"
                    (action)="removeOption(i, index)">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <form [formGroup]="customFieldForm[i].options[index]">
                    <tidy-input
                      [label]="'Option ' + (index + 1) + ' Label'"
                      formControlName="option"
                      [form]="customFieldForm[i].options[index].controls.option"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [activeIcon]="'assets/svg/information-circle-outline.svg'"
                      [customMask]="{ maxLenghth: 40 }"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter a label.'">
                    </tidy-input>
                  </form>
                </tidy-row>
              </ng-container>
              <tidy-row *ngIf="customForm.options.length == 0" class="extra-bottom-padding">
                <tidy-text
                  [helper]="'None Added - Add at Least ' + customForm.numberOfRequiredOptions">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="submitted && (customForm.options.length < customForm.numberOfRequiredOptions)" class="extra-bottom-padding">
                <tidy-error-message
                  [text]="'Please add at least ' + customForm.numberOfRequiredOptions + ' option' + (customForm.numberOfRequiredOptions == 1 ? '' : 's')">
                </tidy-error-message>
              </tidy-row>
            </ng-container>
          </form>
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [actionBody]="'Remove Field'"
              class="link"
              (action)="removeField(i)">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="isAddingCustomFields" class="extra-bottom-padding">
          <tidy-text
            [actionBody]="'Add Field'"
            class="link"
            (action)="addCustomField()">
          </tidy-text>
        </tidy-row>

        <tidy-error-message
          *ngIf="errorMessage?.length"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Save Changes'"
          [action]="saveChanges.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Delete To-Do'"
            (action)="deleteToDo()"
            class="link red">
          </tidy-text>
        </tidy-row>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

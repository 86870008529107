<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? '' : 'vertical-center hasnt-top-nav'">

    <ng-container *ngIf="loaded">
      <tidy-alert *ngIf="!hasNonTrialPaidSubscription && !selectedPrivatePro && (bookingExperiment == 'rental-v1b' || bookingExperiment == 'rental-v2b') && viewData.frequency == 'One Time' && !isUpdatingSubscription">
        <div style="background: rgba(0, 170, 186, 0.8); padding: 10px 5px 10px 5px; margin: -10px; border-radius: 5px;">
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [header]="'Upgrade to TIDY+ & Save 20%'"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [actionBody]="'TIDY+ lets you save 20% booking per property per year, cancel anytime.'"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'">
            <tidy-text
              [actionBody]="(viewData.price | tcurrency)"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'for job (save 20%)'"
              class="white">
            </tidy-text><br>
            <tidy-text
              [actionBody]="'$36 for 1 year TIDY+'"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-divider style="background: white; width: 150px; margin: auto"></tidy-divider>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [actionBody]="(viewData.price + 3600 | tcurrency)"
              class="white">
            </tidy-text><span>&nbsp;</span>.
            <tidy-text
              [actionBody]="'total today'"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-top-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/discount.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              (click)="updateSubscription(this)"
              [largeBody]="isUpdatingSubscription ? 'Remove TIDY+' : 'Add TIDY+'"
              class="white link"
              style="cursor: pointer">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-top-padding">
            <tidy-text
              (click)="goToViewSubscriptions(this)"
              [actionBody]="'View Other Subscription Types'"
              class="white link"
              style="cursor: pointer">
            </tidy-text>
          </tidy-row>
        </div>
      </tidy-alert>

      <tidy-alert *ngIf="!selectedPrivatePro && hasNonTrialPaidSubscription && viewData?.frequency == 'One Time' &&  bookingType !== 'add_one_time_job'">
        <div style="background: rgba(0, 170, 186, 0.8); padding: 10px 5px 10px 5px; margin: -10px; border-radius: 5px;">
          <tidy-row [align]="'center'">
            <tidy-text
              [actionBody]="'Saving 20% with your'"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="me.getSubscriptionType(currentSubscription?.key)"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'subscription!'"
              class="white">
            </tidy-text>
          </tidy-row>
        </div>
      </tidy-alert>
    </ng-container>

    <tidy-card>
      <ng-container *ngIf="loaded">
        <tidy-row *ngIf="!isRightSideContent" [align]="'center'">
          <tidy-text
            [title]="'Confirm'">
          </tidy-text>
          <ng-container *ngIf="isRequestSubstitute">
            <span>&nbsp;</span>
            <tidy-text
              [title]="'Substitute Request'">
            </tidy-text>
          </ng-container>
        </tidy-row>

        <tidy-row *ngIf="!address?.address_name" [align]="'center'">
          <tidy-text
            [header]="'For'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="address?.address1"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [header]="address?.address2 !== null ? ' ' + address?.address2 : ''"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="address?.address_name" [align]="'center'">
          <tidy-text
            [header]="'For'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="address?.address_name"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="flowType === 'private'" [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'Booking with'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="proName | titlecase"
            [translate]="false">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'from'">
          </tidy-text>
          <tidy-text
            [body]="proTeamName | titlecase"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="flowType !== 'private' && proName && !isProRequest" [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [header]="'Booking with'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="proName"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="isProRequest" [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [header]="'Sending Request to '">
          </tidy-text>
          <tidy-text
            [header]="proName"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image
            [src]="'assets/svg/time-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="viewData?.service | titlecase">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/refresh-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="viewData?.frequency">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="!isProRequest && !isJobRequest">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="viewData?.dateTime">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="viewData?.dateTimeTwo" class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="viewData?.dateTimeTwo">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="viewData?.dateTimeThree" class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="viewData?.dateTimeThree">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="isJobRequest">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Earliest Start:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(requestDates?.startDate | customdate:'ddd M/D') + ' ' + (bookData?.booking?.start_time_no_earlier_than | customtime:'h:mma')">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Latest Finish:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(requestDates?.endDate | customdate:'ddd M/D') + ' ' + (bookData?.booking?.end_time_no_later_than | customtime:'h:mma')">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row class="vertical-align-center" *ngIf="displayPrice">
          <tidy-image
            [src]="'assets/svg/card-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Total:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="displayPrice">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!selectedPrivatePro && priceExperiment == 'showCrossedOutDiscount' && viewData?.frequency !== 'One Time'">
          <tidy-text
            [body]="'*Saving 20% with recurring plan'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!selectedPrivatePro && isUpdatingSubscription">
          <tidy-text
            [body]="'*' + (viewData?.price | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'for job (saving 20%)'">
          </tidy-text><br>
          <tidy-text
            [body]="(currentSubscription?.amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'for'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="me.getSubscriptionType(currentSubscription?.key)">
          </tidy-text>
          <tidy-text
            [body]="'remove'"
            class="link"
            (action)="updateSubscription()">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!viewData?.service?.includes('hour')" class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/time-outline.svg'"
            class="body-size">
          </tidy-image>
          <ng-container *ngIf="viewData?.billingType == 'hourly'">
            <tidy-text
              [body]="'Minimum'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(viewData?.duration | duration)">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="viewData?.billingType !== 'hourly'"
            [body]="viewData?.duration | duration">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="isProRequest">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/time-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Times Requested:'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let time of requests.buildRequestTimeRangeList(params?.payload?.request_time_blocks); let last = last">
            <tidy-row>
              <tidy-text
                [body]="time">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </ng-container>

      </ng-container>

      <div [style.display]="cardDeclined ? 'block' : 'none'">
        <tidy-error-message
          [text]="cardError">
        </tidy-error-message>
        <ion-card-content>
          <form class="checkout">
            <ion-label position="stacked">Enter Card Information</ion-label>
            <div class="form-row">
              <div id="card-info" #cardInfo></div>
            </div>
          </form>
        </ion-card-content>
      </div>

      <ng-container *ngIf="loaded">
        <tidy-row class="extra-bottom-padding" *ngIf="showSameDayTimeAlert">
          <ng-container *ngIf="sameDayPrice !== 0">
            <tidy-text
              [body]="'<b>*Note:</b> You selected times today. If a Pro accepts a time today <u>the job price will be'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(sameDayPrice | tcurrency) + '</u>.'">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="sameDayPrice == 0"
            [body]="'<b>*Note:</b> You selected times today. If a Pro accepts a time today <u>the price will be +50%</u>.'">
          </tidy-text>
        </tidy-row>

        <tidy-two-column-row>
          <tidy-button
            [text]="'Go Back'"
            [action]="goBack.bind(this)"
            class="secondary">
          </tidy-button>

          <tidy-button
            [text]="buttonTitle"
            [action]="confirmBooking.bind(this)"
            [submitted]="submitted"
            [disabled]="disableSubmit"
            class="primary">
          </tidy-button>
        </tidy-two-column-row>
      </ng-container>
    </tidy-card>

    <ng-container *ngIf="loaded">
      <tidy-alert *ngIf="showNeedsToRaisePricesError">
        <tidy-row [align]="'center'">
          <tidy-image
            [src]="'assets/svg/alert-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Unable to Book'">
          </tidy-text>
        </tidy-row>
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'This pro has raised their rates. To book them directly, you would need to increase your price to'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="((viewData.frequency == 'One Time' && bookingType == 'add_job' ? addressTier?.amount*1.1 : addressTier?.amount)  | tcurrency) + '.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-button
            [text]="'Raise My Price to ' + ((viewData.frequency == 'One Time' && bookingType == 'add_job' ? addressTier?.amount*1.1 : addressTier?.amount)  | tcurrency) + ' & Book'"
            [action]="addEditJobPlanRaisePrices.bind(this)"
            class="primary">
          </tidy-button>
        </tidy-row>
        <tidy-row>
          <tidy-button
            [text]="'Try for Another Pro at These Prices'"
            [action]="addEditJobPlanWithAnyPro.bind(this)"
            class="secondary">
          </tidy-button>
        </tidy-row>
      </tidy-alert>

      <tidy-row class="extra-top-padding" *ngIf="triedOtherProsError">
        <tidy-error-message
          [text]="'Unfortunately no other pro is available at this price. You need to raise your price to book this job.'">
        </tidy-error-message>
      </tidy-row>

      <tidy-row class="extra-top-padding" *ngIf="errorMessage">
        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

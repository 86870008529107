<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Edit Default To-Do List'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <ng-container *ngIf="type == 'address' && action == 'edit'">
          <tidy-text
            [body]="'Editing the default list for address:'">
          </tidy-text>
          <tidy-text
            [body]="address?.viewValue">
          </tidy-text>
        </ng-container>
        <ng-container *ngIf="type == 'address' && action == 'add'">
          <tidy-text
            [body]="'Adding the default list for address:'">
          </tidy-text>
          <tidy-text
            [body]="address?.viewValue">
          </tidy-text>
        </ng-container>
        <tidy-text
          *ngIf="type == 'account' && action == 'edit'"
          [body]="'Editing the default list for your account.'">
        </tidy-text>
        <tidy-text
          *ngIf="type == 'account' && action == 'add'"
          [body]="'Adding a default list for your account.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-bottom-padding" *ngIf="type == 'account'">
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Service'"
              [form]="form.controls.service"
              formControlName="service"
              [items]="serviceItems">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/list.svg'"
              [label]="'Default List'"
              [form]="form.controls.defaultList"
              formControlName="defaultList"
              [items]="listItems">
            </tidy-select>
          </tidy-row>
          <tidy-row *ngIf="listItems?.length == 0">
            <tidy-text
              [body]="'You haven\'t added any templates to make into account service defaults. Please go to the'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'list settings'"
              class="link"
              (action)="goToListSettings()">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'page and make one of your lists a template to make it an account service default. The list cannot have rooms specific to an address to make it a template.'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="listItems?.length !== 0">
            <tidy-text
              [body]="'Note: you can only make a template an account service default. Go to your'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'list settings'"
              class="link"
              (action)="goToListSettings()">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'page and make one of your lists a template to make it an account service default. The list cannot have rooms specific to an address to make it a template.'">
            </tidy-text>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Save'"
        [action]="saveNewDefault.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

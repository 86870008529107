import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';

import { Webhooks } from 'src/providers/webhooks/webhooks';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WebhookAttemptPage } from '../webhook-attempt/webhook-attempt';
import { WebhooksPage } from '../webhooks';
import { EditWebhookPage } from '../edit-webhook/edit-webhook';

@Component({
  templateUrl: 'webhook-details.html',
  encapsulation: ViewEncapsulation.None
})

export class WebhookDetailsPage extends TimeoutableComponent implements OnInit {

  isAccountAdmin: boolean;
  webhookEvents: string;
  webhookChannel: string;
  webhookDescription: string;
  webhookDetailsBackPage: string;
  webhookId: string;
  webhookState: string;
  webhookUrl: string;
  webhookTeamName: any;
  webhookTeamId: any;
  attempts: any;
  testObject: any;
  errorMessage: string;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private webhookI: Webhooks,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Webhook Details');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      await this.getParams();
      this.attempts = await this.webhookI.getWebhookAttemps(this.webhookId);
      const userRole = localStorage.getItem('user_role');
      this.isAccountAdmin = userRole !== 'member';
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getParams() {
    this.webhookEvents = localStorage.getItem('webhookEvents');
    this.webhookChannel = localStorage.getItem('webhookChannel');
    this.webhookDescription = localStorage.getItem('webhookDescription');
    this.webhookId = localStorage.getItem('webhookId');
    this.webhookState = localStorage.getItem('webhookState');
    this.webhookUrl = localStorage.getItem('webhookUrl');
    this.webhookTeamName = localStorage.getItem('webhookTeamName');
    this.webhookTeamId = localStorage.getItem('webhookTeamId');
    this.webhookDetailsBackPage = await this.getParam('webhookDetailsBackPage');
  }

  @Loading('', true)
  async runTestWebhook() {
    try {
      this.testObject = await this.webhookI.testWebhook(this.webhookId);
      this.goToWebhookAttempt(this.testObject, 'succeeded')
    } catch ( err ) {
      this.goToWebhookAttempt(err, 'failed')
    }
  }

  async disableWebhook() {
    try {
      await this.webhookI.inactivateWebhook(this.webhookId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
      this.rightSidePanelService.navigateTo('webhooks', {}, WebhooksPage);
    }
  }

  async enableWebhook() {
    try {
      await this.webhookI.activateWebhook(this.webhookId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
      this.rightSidePanelService.navigateTo('webhooks', {}, WebhooksPage);
    }
  }

  async deleteWebhook() {
    try {
      await this.webhookI.deleteWebhook(this.webhookId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
      this.rightSidePanelService.navigateTo('webhooks', {}, WebhooksPage);
    }
  }

  async confirmWebhookDeletion() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Webhook?',
      body: 'Are you sure you want to delete this webhook?',
      backText: 'Go Back',
      confirmText: 'Delete Webhook',
      confirmAction: this.deleteWebhook.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmWebhookActivation() {
    const params: ConfirmPageParamsModel = {
      title: 'Activate Webhook?',
      body: 'Are you sure you want to activate this webhook?',
      backText: 'Go Back',
      confirmText: 'Activate Webhook',
      confirmAction: this.enableWebhook.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmWebhookDeactivation() {
    const params: ConfirmPageParamsModel = {
      title: 'Inactivate Webhook?',
      body: 'Are you sure you want to inactivate this webhook?',
      backText: 'Go Back',
      confirmText: 'Disable Webhook',
      confirmAction: this.disableWebhook.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  goToWebhookAttempt(attemptObject, status) {
    const params = {
      customTitle: 'Attempt Details',
      request: attemptObject.request,
      response: attemptObject.response,
      errorReturned: attemptObject,
      nextPage: 'webhook-details',
      status: status
    };
    this.rightSidePanelService.navigateTo('webhook-attempt', params, WebhookAttemptPage);
  }

  goToEditWebhookPage() {
    const params = {
      webhookEvents: this.webhookEvents,
      webhookChannel: this.webhookChannel,
      webhookDescription: this.webhookDescription,
      webhookId: this.webhookId,
      webhookUrl: this.webhookUrl
    }
    this.rightSidePanelService.navigateTo('edit-webhook', params, EditWebhookPage);
  }

}

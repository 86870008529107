<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Browse Pros'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="customBack">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }">
    </ng-container>
    <tidy-row *ngIf="!address?.address_name" [align]="'center'">
      <tidy-text
        [header]="'For'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [header]="address?.address1"
        [translate]="false">
      </tidy-text>
      <tidy-text
        [header]="address?.address2 !== null ? ' ' + address?.address2 : ''"
        [translate]="false">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="address?.address_name" [align]="'center'">
      <tidy-text
        [header]="'For'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [header]="address?.address_name"
        [translate]="false">
      </tidy-text>
    </tidy-row>
    <div class="thumbtack-row">
      <div>
        <tidy-image src="assets/icon/thumbtack_icon.png"></tidy-image>
      </div>
      <div>
        <tidy-text [header]="'Powered by'"></tidy-text><span>&nbsp;</span>
        <tidy-text [header]="'Thumbtack'"></tidy-text>
      </div>
    </div>
    <ng-container *ngIf="!wasDataLoaded && pros.length === 0 && !wasError">
      <ng-container *ngFor="let skeleton of skeletons">
        <tidy-card>
          <div class="skeleton-header">
            <div>
              <ion-skeleton-text animated="true" style="width: 80px; height: 70px; border-radius: 6px;"></ion-skeleton-text>
            </div>
            <div>
              <ion-skeleton-text animated="true" style="width: 80%; height: 20px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated="true" style="width: 80%; height: 20px; border-radius: 6px;"></ion-skeleton-text>
            </div>
          </div>
          <div>
            <ion-skeleton-text animated="true" style="width: 120px; height: 18px; border-radius: 6px;"></ion-skeleton-text>
          </div>
          <div>
            <ion-skeleton-text animated="true" style="width: 150px; height: 18px; border-radius: 6px;"></ion-skeleton-text>
          </div>
          <div style="margin-bottom: 10px;">
            <ion-skeleton-text animated="true" style="width: 180px; height: 18px; border-radius: 6px;"></ion-skeleton-text>
          </div>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%; height: 60px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%; height: 40px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="wasDataLoaded && pros.length > 0">
      <ng-container *ngFor="let pro of pros">
        <tidy-pro-card [pro]="pro" (selectedPro)="openNextStepsModal($event)"></tidy-pro-card>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="wasError">
      <div class="no-results-row">
        <tidy-text [body]="'No results found, please try another category.'"></tidy-text>
      </div>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template #content>
  <div class="content-padding">
    <tidy-row [align]="'center'" class="header" [class.mobile-header]="isMobileResolution">
      <tidy-text [title]="'Next Steps'"> </tidy-text>
    </tidy-row>

    <tidy-row class="step-row">
      <tidy-text
        [body]="'1. Book this pro through our partner,'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'Thumbtack.'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="step-row">
      <tidy-text
        [body]="'2. Message concierge with the job details, so we can track and follow up for you. Email concierge@tidy.com or in app.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-button
        [text]="'Next'"
        class="primary next-button"
        (click)="goToNext()"
      ></tidy-button>
    </tidy-row>
  </div>
</ng-template>

<ion-content *ngIf="isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-container *ngIf="!isMobileResolution">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';
import { ToDos } from 'src/providers/to-dos/to-dos';

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';

import { AddressModel } from 'src/models/address.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { RoomModel } from 'src/models/to-do.model';

import { CurrentAddress } from 'src/providers/addresses/current-address';
import { WindowService } from 'src/shared/providers/window.service';
import { Loading } from '../../shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Events } from 'src/providers/events/events';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { routes } from 'src/app/aggregators/pages';


@Component({
  templateUrl: 'to-dos.html'
})

export class ToDosPage implements OnInit {

  addressFilter: TidySelectNumberValueModel[];
  addressLoaded: boolean;
  addressId: number;
  addressResponse: AddressModel[];
  errorMessage: string;
  listLoaded: boolean;
  rooms: RoomModel[];
  fabButtonOpen = false;
  hasToDoLists: boolean;
  hasTwoOrMoreLists: boolean;
  hasJobs: boolean;
  hasJobsIsLoading: boolean;
  isRightSideContent = true;
  jobsAppliedSentence: string;
  selectedList: any;
  selectedListName: string;
  taskListId: number;
  toDoFilter: TidySelectNumberValueModel[];
  toDoLists: any;
  toDoState: string;
  toDosOnboarding: boolean;
  contentHeight: number;
  editPrio: boolean = false;
  loaded: boolean;

  constructor(
    private client: Client,
    private customDatePipe: CustomDatePipe,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private schedule: Schedule,
    public toDos: ToDos,
    private currentAddress: CurrentAddress,
    public windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private events: Events,
  ) {
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.getPageData();
  }

  async getPageData() {
    try {
      this.loaded = false;
      this.errorMessage = '';
      this.toDosOnboarding = false;
      localStorage.removeItem('roomId');
      await this.getAddresses();
      await this.changeAddress(this.addressId, false);
      this.rightSidePanelService.setDialogPageTitle(this.selectedList?.title);
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err.message;
    }
  }

  async getAddresses() {
    this.addressResponse = await this.client.getAddresses();
    this.addressFilter = this.client.parseAddressList(this.addressResponse, true);
    this.addressId = this.client.getSelectedAddressId(this.addressResponse);
    localStorage.setItem('isAllAddressesSelected', 'false');
    this.events.publish('update:addressId', this.addressId);
  }

  async getToDos() {
    this.listLoaded = false;
    this.toDoLists = await this.toDos.getAllToDoListsForAddress(this.addressId);
    this.toDoState = await this.toDos.getToDoState(this.addressId);
    if (this.toDoState === null) {
      return this.toDosOnboarding = true;
    }
    this.toDoFilter = this.toDos.parseToDoFilterItems(this.toDoLists, this.addressId);
    this.hasToDoLists = this.toDoFilter.length !== 0;
    this.hasTwoOrMoreLists = this.toDoFilter.length > 3;
    if (this.hasToDoLists) {
      this.taskListId = this.toDos.getSelectedTaskListId(this.toDoFilter);
      await this.getToDoList();
    }
    this.listLoaded = true;
  }

  async getToDoList() {
    const rooms = await this.toDos.getListDetail(this.taskListId);
    this.rooms = await this.toDos.buildRoomIcons(rooms);
    this.selectedList = this.toDoLists.find((list) => list.id == this.taskListId);
    if (!this.selectedList) {
      this.selectedList = this.toDoLists[0];
      this.taskListId = this.toDoLists[0].id;
    }
    this.selectedListName = this.selectedList.title;
  }

  async changeAddress(addressId, removeStoredToDoList) {
    try {
      this.toDosOnboarding = false;
      this.addressLoaded = false;
      if (removeStoredToDoList) {
        localStorage.removeItem('taskListId');
      }
      if (addressId === 0) {
        return this.rightSidePanelService.navigateTo('add-property', {});
      }
      this.addressId = addressId;
      this.currentAddress.addressId = addressId;
      await this.getToDos();
      if (this.taskListId) {
        localStorage.setItem('taskListId', this.taskListId.toString());
      }
      this.addressLoaded = true;
      this.checkIfHasJobs();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err.message;
    }
  }

  async checkIfHasJobs() {
    this.hasJobsIsLoading = true;
    const cards = await this.schedule.getCards(this.addressId);
    const jobs = this.schedule.parseJobs(cards);
    this.hasJobs = jobs.length > 0;
    this.jobsAppliedSentence = await this.buildJobsAppliedSentence();
    this.hasJobsIsLoading = false;
  }

  async buildJobsAppliedSentence() {
    const jobs = await this.toDos.getAppliedJobs(this.taskListId);
    if (jobs.length === 0) {
      return 'Not used for any jobs';
    }
    const date = this.customDatePipe.transform(jobs[0].date, 'M/D', '');
    return 'Used next on ' + date;
  }

  async changeList(listId) {
    this.listLoaded = false;
    if (listId === -1) {
      return this.goToAddList();
    } else {
      this.taskListId = listId;
      localStorage.setItem('taskListId', listId);
      await this.getToDoList();
      this.listLoaded = true;
    }
  }

  goToRoom(room, type) {
    if (type === 'doNotClean') {
      const doNotCleanParams = {
        doNotCleanRoom: room
      };
      this.rightSidePanelService.navigateTo('do-not-clean-room', doNotCleanParams);
    } else if (type === 'doClean') {
      localStorage.setItem('roomId', room.id);
      const doCleanParams = {
        room: room,
        rooms: this.rooms
      };
      this.rightSidePanelService.navigateTo('card', doCleanParams);
    }
  }

  goToAddToDo() {
    localStorage.removeItem('roomId');
    const params = {
      rooms: this.toDos.parseRoomCheckboxItems(this.rooms),
      roomsResponse: this.rooms,
      selectedListId: this.selectedList.id,
      addressId: this.addressId
    }
    this.rightSidePanelService.navigateTo('add-to-do', params);
  }

  goToAddDoNot() {
    localStorage.removeItem('roomId');
    const rooms = this.rooms.filter(room => room.type !== 'group');
    const params = {
      rooms: this.toDos.parseRoomCheckboxItems(rooms)
    }
    this.rightSidePanelService.navigateTo('add-do-not', params);
  }

  goToAddRoom() {
    const params = {
      list: this.selectedList,
      addressId: this.addressId
    }
    this.rightSidePanelService.navigateTo('add-card', params);
  }

  goToAddList() {
    const params = {
      addressFilter: this.addressFilter.filter((address) => address.value !== 0),
      hasToDoLists: this.hasToDoLists
    }
    this.rightSidePanelService.navigateTo('add-to-dos-list', params);
  }

  goToListSettings() {
    const params = {
      addressFilter: this.addressFilter,
      toDoLists: this.toDoLists
    }
    this.rightSidePanelService.navigateTo('list-settings', params);
  }

  editJobsApplied() {
    const params = {
      listName: this.selectedListName,
      toDoState: this.toDoState,
      listIsDefault: this.selectedList.is_default,
      hasTwoOrMoreLists: this.hasTwoOrMoreLists,
      toDoLists: this.toDoLists
    }
    this.rightSidePanelService.navigateTo('apply-to-jobs', params);
  }

  async bookJob(bookingType) {
    await this.storage.save('bookJobBackPage', 'to-dos');
    await this.client.getClientInfo();
    const address = this.addressResponse.find(ad => ad.id === this.addressId);
    localStorage.setItem('region_id', address.region_id.toString());
    const hasPrivatePros = await this.pros.checkIfHasPrivatePro();
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    if (hasPrivatePros || isRentalClient) {
      this.rightSidePanelService.navigateTo('select-category', { address, bookingType });
    } else {
      this.rightSidePanelService.navigateTo('book-job', { address, bookingType });
    }
  }

  usingToDos(): boolean {
    return (this.toDoState === 'active');
  }

  listSelected(): boolean {
    return (this.taskListId !== -2);
  }

  @Loading('', true)
  async updatePriorities(orderedRooms): Promise<void>{
    let priorityList: string = '';
    orderedRooms.map((room) => {
      priorityList += `${room.type}.${room.id},`
    });
    await this.toDos.updateRoomPriority(this.addressId, this.taskListId, priorityList.slice(0, -1))
  }

  goToShareList() {
    const params = {
      selectedListName: this.selectedListName,
      rooms: this.rooms,
      taskListId: this.taskListId
    }
    this.rightSidePanelService.navigateTo('share-list', params);
  }

  async goToRemoteInspectionPage(list) {
    const params = {
      list: this.selectedList,
      addressId: this.addressId,
      finishPage: 'to-dos'
    }
    this.rightSidePanelService.navigateTo('remote-inspection', params);
  }

  getCardIcon(room) {
    if (this.editPrio) {
      return 'assets/svg/menu-tidy-green.svg';
    } else {
      return 'assets/img/icons/rooms/' + room?.icon + '.svg';
    }
  }

  goToEditList() {
    const params = {
      selectedList: this.selectedList,
      addressId: this.addressId
    }
    this.rightSidePanelService.navigateTo('rename-list', params);
  }

  @Loading('', true)
  async saveBlockCompleteOnMissingFields(value) {
    await this.toDos.saveBlockCompleteOnMissingFields(this.taskListId, value);
  }

}

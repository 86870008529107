<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Needs Work'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Please help us learn more about what went wrong with'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="hkName + '.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'Our Concierge will review and email you ASAP.'">
        </tidy-text>
      </tidy-row>

      <form [formGroup]="feedbackForm" *ngIf="loaded">

          <tidy-card *ngIf="answers['what_happened.didnt_finish'] == 't'">
            <tidy-row>
              <tidy-text
                [header]="'Didn’t Finish To Dos'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'What was the main reason they didn’t finish?'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-radio-button
                (optionChange)="setDidntFinish($event)"
                [items]="finishTypes">
              </tidy-radio-button>
            </tidy-row>

            <tidy-two-column-row *ngIf="didntFinish == 'didnt_finish.didnt_stay.arrived_at'" style="padding-top: 20px;">
              <tidy-select
                style="max-width: 35%; margin: 0;"
                [icon]="'assets/svg/time-outline.svg'"
                [label]="'Arrived at'"
                [form]="feedbackForm.controls['didnt_finish.didnt_stay.arrived_at']"
                formControlName="didnt_finish.didnt_stay.arrived_at"
                (optionChange)="updateDidntFinish('didnt_finish.didnt_stay.arrived_at', $event)"
                [items]="timeOpts">
              </tidy-select>
              <span style="align-self: center; justify-content: space-around; max-width: 10%;">-</span>
              <tidy-select
                style="max-width: 35%; margin-right: 10px;"
                [icon]="'assets/svg/time-outline.svg'"
                [label]="'Left at'"
                [form]="feedbackForm.controls['didnt_finish.didnt_stay.left_at']"
                (optionChange)="updateDidntFinish('didnt_finish.didnt_stay.left_at', $event)"
                [items]="timeOpts">
              </tidy-select>
            </tidy-two-column-row>

          </tidy-card>

          <tidy-card *ngIf="answers['what_happened.bad_ability'] == 't'">
            <tidy-row>
              <tidy-text
                [header]="'Abilities'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Rate'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="hkName + '\'s ability (1-5 stars):'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-grid>
                <ion-row>
                  <ion-col size="2" *ngFor="let star of stars; index as i" style="margin-right:5px">
                    <tidy-image
                      [src]="'assets/svg/' + (i < rating ? 'star-tidy-green' : 'star-grey') + '.svg'"
                      [ngClass]="i < rating ? 'green' : 'grey'"
                      (click)="setStarRate(i)"
                      class="title-size">
                    </tidy-image>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-row>
            <tidy-text
              [helper]="starMessage(feedbackForm.value['bad_ability.star_rating'])">
            </tidy-text>
          </tidy-card>

          <tidy-card *ngIf="answers['what_happened.bad_supplies'] == 't' && isCleaningJob">
            <tidy-row>
              <tidy-text
                [header]="'Missing Supplies'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Was'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="hkName">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'missing supplies? Select all that apply:'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let key of badSuppKeys[0]">
              <tidy-checkbox
                [formControlName]="key"
                [form]="feedbackForm.controls.key"
                [items]="[{viewValue: badSupp[key] , value: true}]">
              </tidy-checkbox>
            </ng-container>
            <tidy-row class="extra-top-padding">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'Were there other issues with their supplies?'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let key of badSuppKeys[1]">
              <tidy-checkbox
                [formControlName]="key"
                [form]="feedbackForm.controls.key"
                [items]="[{viewValue: badSupp[key] , value: true}]">
              </tidy-checkbox>
            </ng-container>
          </tidy-card>

          <tidy-card *ngIf="answers['what_happened.bad_attitude'] == 't'">
            <tidy-row>
              <tidy-text
                [header]="'Unprofessional Attitude'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'How was'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="hkName">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'unprofessional?'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngFor="let key of badAttitudeKeys">
              <tidy-checkbox
                [formControlName]="key"
                [form]="feedbackForm.controls.key"
                [items]="[{viewValue: badAttitude[key] , value: true}]">
              </tidy-checkbox>
            </ng-container>
          </tidy-card>

          <tidy-card *ngIf="answers['what_happened.serious_issue'] == 't'">
            <tidy-row>
              <tidy-text
                [header]="'Safety Issue'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'What was the safety issue?'">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-textarea
                formControlName="serious_issue"
                [label]="hkName + ' will not see this'"
                [form]="feedbackForm.controls.serious_issue"
                [errorMessage]="'Please enter a valid gift code.'"
                [icon]="'assets/svg/alert-circle-outline.svg'">
              </tidy-textarea>
            </tidy-row>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <tidy-text
                [header]="'Block'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="hkName + '?'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Would you have'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="hkName">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'again?'">
              </tidy-text>
            </tidy-row>

            <tidy-radio-button
              formControlName="blacklist_hk"
              [form]="feedbackForm.controls.blacklist_hk"
              [errorMessage]="'Please enter a valid gift code.'"
              [items]="[{viewValue: 'Yes, I think they can get it next time', value: false},{viewValue: 'No, I don’t want them again', value: true}]"
              class="no-margin-first">
            </tidy-radio-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <tidy-text
                [header]="'Constructive Feedback'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Give' + ' '">
              </tidy-text>
              <tidy-text
                [body]="hkName + ' '">
              </tidy-text>
              <tidy-text
                [body]="'Constructive Feedback (optional)'">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-textarea
                formControlName="constructive_feedback"
                [label]="'Goes directly to them.'"
                [form]="feedbackForm.controls.constructive_feedback"
                [errorMessage]="'Please enter feedback.'"
                [icon]="'assets/svg/person-circle-outline.svg'">
              </tidy-textarea>
            </tidy-row>
          </tidy-card>

          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [text]="'Next'"
            [action]="sendFeedback.bind(this)"
            class="primary">
          </tidy-button>

      </form>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class Webhooks {
  constructor(
    private httpClient: HttpClientCustom
  ) {}

  getWebhooks(){
    const url = 'api/v1/customer/webhooks';
    return this.httpClient.get(url);
  }

  getWebhookAttemps(webhookId: string){
    const url = `api/v1/customer/webhook-attempts?customer_webhook_id=${webhookId}`;
    return this.httpClient.get(url);
  }

  addWebhook(payload){
    const url = 'api/v1/customer/webhooks';
    return this.httpClient.post(url, payload);
  }

  editWebhook(webhookId, payload) {
    const url = `api/v1/customer/webhooks/${webhookId}/update`;
    return this.httpClient.put(url, payload);
  }

  testWebhook(webhookId: string){
    const data = {
      event_type: 'booking.scheduled'
    };
    const url = `api/v1/customer/webhooks/${webhookId}/test`;
    return this.httpClient.post(url, data);
  }

  deleteWebhook(webhookId: string){
    const url = `api/v1/customer/webhooks/${webhookId}`;
    return this.httpClient.delete(url, {});
  }

  inactivateWebhook(webhookId: string){
    const url = `api/v1/customer/webhooks/${webhookId}/inactivate`;
    return this.httpClient.put(url, {});
  }

  activateWebhook(webhookId: string){
    const url = `api/v1/customer/webhooks/${webhookId}/activate`;
    return this.httpClient.put(url, {});
  }

  getWebhookEvents(){
    const url = `api/v1/customer/webhooks/event-types`;
    return this.httpClient.get(url);
  }

  buildNotificationSettingsArray(webhooks, withUrl = true) {
    const array: any = [];
    webhooks.map((webhook, i) => {
      if (webhook.state == 'active') {
        webhook.events.map((event) => {
          const alreadyAddedChannelToEvent = array.find((item) => item.display_name == event.display_name);
          if (alreadyAddedChannelToEvent) {
            const index = array.findIndex((item) => item == alreadyAddedChannelToEvent)
            const channelObject = {
              name: this.buildChannelName(webhooks[i].channel, webhook.webhook_url, withUrl),
              webhook_id: webhook.id
            }
            array[index].channels.push(channelObject);
          } else {
            array.push({
              display_name: event.display_name,
              key: event.key,
              channels: [
                {
                  name: this.buildChannelName(webhook.channel, webhook.webhook_url, withUrl),
                  webhook_id: webhook.id
                }
              ]
            });
          }
        });
      }
    });
    return array;
  }

  buildChannelName(webhookChannel, webhookUrl, withUrl) {
    if (!withUrl) {
      return new TitleCasePipe().transform(webhookChannel);
    }
    let url = webhookUrl;
    url = url.split('https://').pop();
    url = url.split('https:').pop();
    url = url.split('www.').pop();
    return new TitleCasePipe().transform(webhookChannel) + ': ' + url;
  }

}

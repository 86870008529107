import { Schedule } from 'src/providers/schedule/schedule';
import { Util } from 'src/shared/util/util';
import { ModalController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { AddressModel } from 'src/models/address.model';
import { JobCard } from 'src/models/schedule.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  selector: 'unable-to-message-modal',
  templateUrl: 'unable-to-message.html',
  styleUrls: ['./unable-to-message.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnableToMessageModalComponent {
  isMobileResolution = false;
  form: UntypedFormGroup;
  imagePreview: string;
  address: AddressModel;
  imageFile: any;
  card: JobCard;

  constructor(
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private storage: TidyStorage,
    private util: Util,
    private schedule: Schedule,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.isMobileResolution = window.innerWidth <= 870;
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
    storage.retrieve('unableToMessageParams').then((res) => {
      if (res) {
        this.form.patchValue(res);
        this.imagePreview = res?.files?.[0]?.location || '';
        this.imageFile = res?.files?.[0]?.file;
        this.address = res?.address;
        this.card = res?.card;
      }
    });
  }

  dismissModal(): void {
    this.storage.save('unable-to-message:selected-some-action', true);
    this.dialog?.closeAll();
    this.modalCtrl.getTop().then((res) => {
      if (res) {
        this.modalCtrl.dismiss();
      }
    });
  }

  async sendAttachment(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        loading.dismiss();
        const errorMessage =
          'Unable to attach photo. Please upload a PNG or JPEG file.';
        this.util.showError(errorMessage);
        return;
      }
      if (this.imagePreview === '') {
        this.modalCtrl.getTop().then((res) => {
          if (res) {
            res.setCurrentBreakpoint(0.8);
          }
        });
      }
      this.imagePreview = attachment.location;
      this.imageFile = attachment.file;
      loading.dismiss();
    } catch (err) {
      loading.dismiss();
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  goToCancelOrReschedule(): void {
    this.dismissModal();
  }

  addAccessNote(): void {
    this.util.closeLastOpenedToast();
    if (this.form.invalid && !this.imagePreview) {
      this.util.showWarning('Please add a message or photo to continue.');
      return;
    }

    const params = {
      address: this.address,
      hasLockDevicesIntegrated: this.hasLockDevicesIntegrated(
        this.address?.remote_access_address?.remote_access_devices
      ),
      howToGetInParams: {
        message: this.form.value.message,
        files: [
          {
            location: this.imagePreview,
            file: this.imageFile,
          },
        ],
      },
    };
    this.dismissModal();
    this.rightSidePanelService.navigateTo('access-notes', params);
  }

  addJobNote(): void {
    const bookingNotes = [
      {
        type: 'text',
        text: this.form.value.message,
      },
      {
        type: 'image',
        location: this.imagePreview,
        file: this.imageFile,
      },
    ]
    const params = {
      bookingNote: bookingNotes,
      bookingId: this.card.template_data?.booking?.id,
      backPage: `job/${this.address.id}/${this.card.template_data?.job?.id}`,
    };
    this.dismissModal();
    this.rightSidePanelService.navigateTo('booking-note', params);
  }

  hasLockDevicesIntegrated(devices: any[]): boolean {
    return devices?.some((device) => device?.in_use) || false;
  }
}

import { Injectable } from '@angular/core';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

@Injectable()
export class StaticData {

  constructor() {}

  getPlanFrequencyMessages(type, home) {
    const list =  {
      'tidy': `The TIDY is a quick clean, best for focused needs or small ${home}s.`,
      'plus': `The TIDY+ is our most popular cleaning, good for general cleanings of most ${home}s.`,
      'mighty': `The MIGHTY is long enough for a deep, detailed cleaning in most ${home}s.`,
      'tidy_xl': `The TIDYXL is great for cleaning large ${home}s or deep cleaning small ${home}s.`
    }
    return list[type];
  }

  selectTimeOpts(min: string = '00:00', max: string = '24:00'): Array<TidySelectStringValueModel> {
    const timeOpts = [
      {viewValue: '12:00am', value: '00:00'},
      {viewValue: '12:30am', value: '00:30'},
      {viewValue: '1:00am', value: '01:00'},
      {viewValue: '1:30am', value: '01:30'},
      {viewValue: '2:00am', value: '02:00'},
      {viewValue: '2:30am', value: '02:30'},
      {viewValue: '3:00am', value: '03:00'},
      {viewValue: '3:30am', value: '03:30'},
      {viewValue: '4:00am', value: '04:00'},
      {viewValue: '4:30am', value: '04:30'},
      {viewValue: '5:00am', value: '05:00'},
      {viewValue: '5:30am', value: '05:30'},
      {viewValue: '6:00am', value: '06:00'},
      {viewValue: '6:30am', value: '06:30'},
      {viewValue: '7:00am', value: '07:00'},
      {viewValue: '7:30am', value: '07:30'},
      {viewValue: '8:00am', value: '08:00'},
      {viewValue: '8:30am', value: '08:30'},
      {viewValue: '9:00am', value: '09:00'},
      {viewValue: '9:30am', value: '09:30'},
      {viewValue: '10:00am', value: '10:00'},
      {viewValue: '10:30am', value: '10:30'},
      {viewValue: '11:00am', value: '11:00'},
      {viewValue: '11:30am', value: '11:30'},
      {viewValue: '12:00pm', value: '12:00'},
      {viewValue: '12:30pm', value: '12:30'},
      {viewValue: '1:00pm', value: '13:00'},
      {viewValue: '1:30pm', value: '13:30'},
      {viewValue: '2:00pm', value: '14:00'},
      {viewValue: '2:30pm', value: '14:30'},
      {viewValue: '3:00pm', value: '15:00'},
      {viewValue: '3:30pm', value: '15:30'},
      {viewValue: '4:00pm', value: '16:00'},
      {viewValue: '4:30pm', value: '16:30'},
      {viewValue: '5:00pm', value: '17:00'},
      {viewValue: '5:30pm', value: '17:30'},
      {viewValue: '6:00pm', value: '18:00'},
      {viewValue: '6:30pm', value: '18:30'},
      {viewValue: '7:00pm', value: '19:00'},
      {viewValue: '7:30pm', value: '19:30'},
      {viewValue: '8:00pm', value: '20:00'},
      {viewValue: '8:30pm', value: '20:30'},
      {viewValue: '9:00pm', value: '21:00'},
      {viewValue: '9:30pm', value: '21:30'},
      {viewValue: '10:00pm', value: '22:00'},
      {viewValue: '10:30pm', value: '22:30'},
      {viewValue: '11:00pm', value: '23:00'},
      {viewValue: '11:30pm', value: '23:30'}
    ];
    return timeOpts.filter(timeOpt => timeOpt.value >= min && timeOpt.value <= max);
  };

}

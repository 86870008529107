<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Scan Your Property'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container [ngSwitch]="modalFlow">

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <ng-container *ngSwitchCase="'secondModal'">

          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'TIDY has the easiest way to make a map of your property, taking less than 5 minutes of your time.'">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <tidy-row>
              <tidy-text
                [header]="'Please tell us more about:'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="addressName">
              </tidy-text>
            </tidy-row>
            <form [formGroup]="form">
              <tidy-row class="field-top-padding field-bottom-padding">
                <tidy-select
                  [label]="'Number of Bedrooms'"
                  [form]="form.controls.beds"
                  formControlName="beds"
                  [items]="roomOptions">
                </tidy-select>
              </tidy-row>
              <tidy-row class="field-bottom-padding">
                <tidy-select
                  [label]="'Number of Bathrooms'"
                  [form]="form.controls.baths"
                  formControlName="baths"
                  [items]="roomOptions">
                </tidy-select>
              </tidy-row>
              <tidy-row>
                <tidy-select
                  [label]="'Number of Floors'"
                  [form]="form.controls.floors"
                  formControlName="floors"
                  [items]="floorOptions">
                </tidy-select>
              </tidy-row>
            </form>
          </tidy-card>
          <tidy-button
            [text]="'Next'"
            [action]="secondModal.bind(this)"
            class="primary">
          </tidy-button>
        </ng-container>

        <ng-container *ngSwitchCase="'thirdModal'">
          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/svg/home.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Confirm Your Rooms'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let floor of floors">
              <ng-container *ngIf="floor">
                <tidy-row class="vertical-align-center" *ngFor="let room of floor">
                  <tidy-image
                    [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="room?.name">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'Edit Rooms'"
                (action)="goToEditRooms()"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-card>
          <tidy-button
            [text]="'Next'"
            [action]="fourthModal.bind(this)"
            class="primary">
          </tidy-button>
        </ng-container>

        <ng-container *ngSwitchCase="'confirmMap'">
          <ng-container *ngIf="!isImageLoading">
            <tidy-card style="padding-bottom: 0px">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/svg/navigate-circle.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Confirm Your Location'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding">
                <tidy-text
                  [body]="'Does this show your property?'">
                </tidy-text>
              </tidy-row>
              <div style="margin-right: -15px; margin-left: -15px" *ngIf="!isImageLoading">
                <img [src]="mapToShow"
                  alt="map">
              </div>
            </tidy-card>
            <ng-container *ngIf="rejectionAlert">
              <tidy-card>
                <tidy-row>
                  <tidy-text
                    [body]="'Right now, we require a satellite image of your home to continue. We hope to support situations where your property is not shown in the future. We apologize for the inconvenience. If you have any feedback for us, please'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    (action)="goToConcierge()"
                    [body]="'tap here'"
                    class="link">
                  </tidy-text>
                  <tidy-text
                  [body]="'.'">
                </tidy-text>
                </tidy-row>
              </tidy-card>
            </ng-container>
            <tidy-button
              [text]="'Yes'"
              [action]="submitMap.bind(this)"
              class="primary">
            </tidy-button>
            <tidy-button
              [text]="'No'"
              [action]="rejectMap.bind(this)"
              class="secondary">
            </tidy-button>
          </ng-container>

          <ng-container *ngIf="isImageLoading">
            <tidy-row>
              <ion-item>
                <ion-thumbnail slot="start" style="width: 339px; height: 339px">
                  <ion-skeleton-text animated></ion-skeleton-text>
                </ion-thumbnail>
              </ion-item>
            </tidy-row>
          </ng-container>

        </ng-container>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
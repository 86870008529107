<tidy-card>
  <tidy-card-button (action)="goToUploadList()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <!--TODO add a better icon-->
        <tidy-image [src]="'assets/img/integrate.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Upload To-Do Lists'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Upload a CSV file with to-do lists for your properties.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-row class="extra-bottom-padding">
  <tidy-text [helper]="'or'" class="divider"> </tidy-text>
</tidy-row>

<tidy-card>
  <tidy-card-button (action)="goToPopularList()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <!--TODO add a better icon-->
        <tidy-image [src]="'assets/img/todos.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Start With Popular List for each Property'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Start with a popular list for each property.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

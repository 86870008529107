import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { SelectModel } from 'src/tidy-ui-components/components/form/select/select.model';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { ScheduleAssuranceModel } from 'src/models/schedule-assurance.model';
import * as moment from 'moment';
import { StaticData } from 'src/providers/static-data/static-data';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'backup-times.html',
  encapsulation: ViewEncapsulation.None
})
export class BackupTimesPage extends TimeoutableComponent implements OnInit {

  checkedBkpDays: Array<any> = [];
  errorMessage: string;
  endTimeOpts: Array<SelectModel>;
  form: UntypedFormGroup;
  scheduleAssurance: ScheduleAssuranceModel;
  startTimeOpts: Array<SelectModel>;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private staticData: StaticData,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Default Backup Times');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.getTimeOptions()
      this.scheduleAssurance = await this.client.getScheduleAssurance();
      this.initForm(this.scheduleAssurance.customerAddress.times);
      this.patchBkpDays(this.scheduleAssurance.customerAddress.times);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  getTimeOptions() {
    this.endTimeOpts = this.staticData.selectTimeOpts('07:00', '22:00');
    this.startTimeOpts = this.endTimeOpts.slice(0, -1);
  }

  initForm(times: Array<Object>) {
    const daysFormArray = times.map(time => {
      return this.fb.group(
        {...time, checked: this.validStartTime(time)},
        { validator: this.validateGroup.bind(this) }
      );
    });

    this.form = this.fb.group({
      days: this.fb.array(daysFormArray)
    });
  }

  patchBkpDays(times: Array<any>) {
    times.map((time, index) => {
      this.checkedBkpDays[index] = {
        checked : this.validStartTime(time)
      };
    });
  }

  validStartTime(time) {
    return time?.start_time !== null
        && time?.start_time !== '00:00';
  }

  checkboxChange(value, index) {
    if (!value) {
      const dayFormGroup: UntypedFormGroup = this.getWeekdayFormGroup(index);
      dayFormGroup.controls.start_time.setValue(null);
      dayFormGroup.controls.end_time.setValue(null);
    }
  }

  startTimeChange(startTime: string, weekDayIndex: number) {
    const dayFormGroup: UntypedFormGroup = this.getWeekdayFormGroup(weekDayIndex);
    const endTimeControl: UntypedFormControl = <UntypedFormControl>dayFormGroup.controls.end_time
    if (startTime >= endTimeControl.value ) {
      endTimeControl.setValue(null);
    }
  }

  getWeekdayFormGroup(weekDayIndex: number): UntypedFormGroup {
    const daysFormArray: UntypedFormArray = <UntypedFormArray>this.form.controls.days;
    return <UntypedFormGroup>daysFormArray.controls[weekDayIndex];
  }

  endTimesFilter(index, item) {
    const startTime = this.form.controls.days.value[index].start_time;
    const diff = moment(startTime, 'hh:mm').diff(moment(item.value, 'hh:mm'));

    return diff < 0;
  }

  mountSuccessPageParams() {
    return {
      header: 'Default Backup Times Saved',
      body: '',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

  @Loading('', true)
  async saveChanges() {
    this.errorMessage = '';

    if (!this.form.valid) {
      return this.errorMessage = 'Please select both a start time and and end time for each day.';
    }
    try {
      this.errorMessage = '';
      await this.client.saveBackupTimes(this.form.controls.days.value, this.scheduleAssurance);
      const params = this.mountSuccessPageParams();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch (err) {
      this.errorMessage = err?.message || err?.error?.message;
    }
  }

  validateGroup(group: UntypedFormGroup): ValidationErrors | null {
    const valid = this.validateTime(group.value.start_time) && this.validateTime(group.value.end_time);

    return !group.value.checked || valid ? null : { dateInvalid: true }
  }

  validateTime(time: string) {
    return time !== '' && time != null && time !== '00:00';
  }
}

import { Injectable } from '@angular/core';
import { Cleaning } from 'src/providers/cleaning-cards/cleaning';
import { Logger } from 'src/providers/logger';

@Injectable({
  providedIn: 'root'
})
export class GetCleaningsService {
  constructor(
    private cleaning: Cleaning,
    private logger: Logger
  ) { }

  setIntervalCards(): number {
    return window.setInterval(async () => {
      this.getCards();
    }, 60000);
  }

  async getCards(): Promise<any> {
    try {
      return await this.cleaning.getCards();
    } catch (e) {
      this.logger.error(e);
    }
  }
}

import { Injectable } from '@angular/core';
import { resizeImage } from 'src/shared/util/resize-image';

@Injectable({
  providedIn: 'root'
})
export class FilePicker {
  async getImg() {
    const file = await this.pickFile('image/*');
    const base64String = file.dataUrl.replace(`data:${file.format};base64,`, '');

    return {...file, base64String};
  }

  pickFile(fileTypeFilter: string = '*'): Promise<{ dataUrl: string, format: string, filename: string }> {
    return new Promise<any>((resolve, reject) => {
      let input = document.querySelector('#_file-capture-input') as HTMLInputElement;
  
      const cleanup = () => {
        input.parentNode && input.parentNode.removeChild(input);
      };
  
      if (!input) {
        input = document.createElement('input');
        input.id = '_file-capture-input';
        input.type = 'file';
        document.body.appendChild(input);
      }
  
      input.accept = fileTypeFilter;
      input.removeAttribute('capture');
  
      input.addEventListener('change', (e: any) => {
        const file = input.files && input.files[0];
        if (!file) {
          cleanup();
          reject(new Error("No file selected or selection was cancelled"));
          return;
        }
        const format = file.type;
  
        let reader = new FileReader();
  
        reader.onload = async () => {
          const isImage = format.includes('image');
          const dataUrl = isImage ? await resizeImage(reader.result as string, format) : reader.result;
  
          resolve({
            dataUrl,
            format,
            filename: file.name
          });
  
          cleanup();
        };
        reader.readAsDataURL(file);
      });
  
      input.click();
  
      input.onerror = (err) => {
        reject(err);
        cleanup();
      };
  
      input.addEventListener('cancel', () => {
        cleanup();
        reject(new Error("File selection was cancelled"));
      });
    });
  }
}

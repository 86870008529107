<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="loaded ? (('Send Link to ' | translate) + job?.template_data?.homekeepers[0]?.first_name) : ''"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="!loaded && errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <div>
            <tidy-text
              [header]="(job?.template_data?.booking?.bookable_service?.name | titlecase)">
            </tidy-text><br>
            <tidy-text
              [body]="addressName">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [header]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
              ' ' +
              (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
            </tidy-text><br>
            <tidy-text
              [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
            </tidy-text>
          </div>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding extra-top-padding">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="job?.template_data?.homekeepers[0]?.text ? job?.template_data?.homekeepers[0]?.text : job?.template_data?.homekeepers[0]?.first_name">
          </tidy-text>
          <tidy-text
            [body]="job?.template_data?.homekeepers[0]?.phone | telephone">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-row>
          <tidy-text *ngIf="!isPastJobLink"
            [body]="job?.template_data?.homekeepers[0]?.first_name + (' can see this job in the <u>TIDY Pro App</u> if they use their email on file. You can also share a link to the job with them:' | translate)">
          </tidy-text>
          <tidy-text *ngIf="isPastJobLink"
            body="You can share this link to the job:'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="jobLink">
          </tidy-text>
          <tidy-text
            *ngIf="copied"
            style="margin-left:5px"
            [helper]="'Copied'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [action]="copyLink.bind(this)"
          [text]="'Copy Job Link'"
          class="primary">
        </tidy-button>
        <tidy-row class="extra-top-padding" [align]="'center'">
          <tidy-text
            class="link"
            [actionBody]="'Learn More'"
            (action)="learnMore()">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'about job links'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Select Times'"
    [canGoBack]="false"
    closeIconDesktop="arrow-back-black"
    closeIconMobile="arrow-back-white"
    (closeItemAction)="closeModal()"
    id="top-nav-height">
    <tidy-wrapper>
      <tidy-row>
        <tidy-divider>
        </tidy-divider>
      </tidy-row>

      <tidy-grid>
        <ion-row>
          <ion-col size="10">
            <tidy-row class="vertical-align-center">
              <tidy-image
                class="body-size"
                [src]="'assets/img/icons/number_1_white.svg'">
              </tidy-image>
              <tidy-text
                [actionBody]="frequencyLabel | titlecase"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                class="body-size"
                [src]="'assets/img/icons/number_2_white.svg'">
              </tidy-image>
              <tidy-text
                [actionBody]="firstTime | titlecase"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                class="body-size"
                [src]="'assets/img/icons/number_3_white.svg'">
              </tidy-image>
              <tidy-text
                [actionBody]="secondDateLabel() | titlecase"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center" *ngIf="thriceAWeek">
              <tidy-image
                class="body-size"
                [src]="'assets/img/icons/number_4_white.svg'">
              </tidy-image>
              <tidy-text
                [actionBody]="thirdDateLabel() | titlecase"
                class="white">
              </tidy-text>
            </tidy-row>
          </ion-col>
          <ion-col size="2" class="vertical-align-center">
            <tidy-row class="extra-left-padding">
              <tidy-text
                [title]="+selectedPrice | tcurrency"
                class="text-price">
              </tidy-text>
            </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-grid>

      <tidy-button
        [text]="'Confirm Selected Times'"
        [action]="confirmSelections.bind(this)"
        [disabled]="!isFormValid()"
        class="primary">
      </tidy-button>

    </tidy-wrapper>
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="!closeSecondTime">
        <tidy-row>
          <tidy-text
            [title]="'Select Second Time'">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngFor="let item of dateTimes; index as idx">
          <tidy-row>
            <tidy-text
              [header]="item.date | customdate:'dddd M/D':''">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-radio-button
              chunkSize="3"
              formControlName="second_time"
              (optionChange)="selectSecondTime($event, idx)"
              [form]="form.controls.second_time"
              [(ngModel)]="form.value.second_time"
              [items]="item.times">
            </tidy-radio-button>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="thriceAWeek && closeSecondTime">
        <tidy-row>
          <tidy-text
            [title]="'Select Third Time'">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngFor="let item of dateTimes">
          <tidy-row>
            <tidy-text
              [header]="item.date | customdate:'dddd M/D':''">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-radio-button
              chunkSize="3"
              formControlName="third_time"
              (optionChange)="selectThirdTime($event)"
              [form]="form.controls.third_time"
              [(ngModel)]="form.value.third_time"
              [items]="item.times">
            </tidy-radio-button>
          </tidy-row>
        </tidy-card>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [style.padding-top.px]="topNavHeight" [templateContent]="content"></app-scroll-wrapper>

import { Injectable } from '@angular/core';
import {
  Platform,
  LoadingController,
  LoadingOptions,
  AlertController,
  AlertButton,
  AlertOptions,
} from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as Mailcheck from 'mailcheck';
import * as Bowser from 'bowser';
import { UrlSegment } from '@angular/router';
import { WindowService } from '../providers/window.service';
import { DateTime as LuxonDateTime } from 'luxon';
import { Period } from 'src/models/schedule-weekly-view.model';
import { ToastController } from '@ionic/angular';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

declare const window: any;

@Injectable()
export class Util {
  constructor(
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private windowService: WindowService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
  ) {}

  getOs() {
    let os = 'other';
    if (Bowser.mac) {
      os = 'mac';
    } else if (Bowser.windows) {
      os = 'windows';
    } else if (Bowser.android) {
      os = 'android';
    } else if (Bowser.ios) {
      os = 'ios';
    }
    return os;
  }

  getAppType() {
    if (this.platform.is('cordova')) {
      return 'native';
    } else if (this.isDesktop()) {
      return 'desktop web';
    } else if (!this.isDesktop()) {
      return 'mobile web';
    }
  }

  isCordova() {
    return this.platform.is('cordova');
  }

  isDesktop() {
    return this.windowService.isDesktopRes;
  }

  openUrl(url): void {
    if (this.platform.is('cordova')) {
      this.platform
        .ready()
        .then(() =>
          this.inAppBrowser.create(url, '_system', 'location=true').show()
        );
    } else {
      window.open(url, '_blank');
    }
  }

  deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  checkEmailMistyping(emailToCheck) {
    let suggestedEmail = '';
    Mailcheck.run({
      email: emailToCheck,
      suggested: (suggestion) => {
        suggestedEmail = suggestion.full;
      },
      empty: (_) => {
        suggestedEmail = '';
      },
    });
    return suggestedEmail;
  }

  removeCurrencyMask(value) {
    return value ? value.replace(/[$.,]/g, '') : null;
  }

  copyToClipBoard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.showSuccess(new TranslationPipe().transform('Copied to clipboard'));
  }

  camelToSnakeCase(str): string {
    return str.replace(
      /[A-Z]|[\d.]+(?!$)(?!\d)/g,
      (letter) => `_${letter.toLowerCase()}`
    );
  }

  camelKeysToSnakeKeys(obj: object): object {
    const newObject = {};
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        newObject[this.camelToSnakeCase(key)] = this.camelKeysToSnakeKeys(
          obj[key]
        );
      } else {
        newObject[this.camelToSnakeCase(key)] = obj[key];
      }
    }
    return newObject;
  }

  encodeUrlSegment(value: Array<UrlSegment>): string {
    const strUrl = value.toString();
    const encodeRegex = new RegExp("'", 'g');
    const formatRegex = new RegExp(',', 'g');
    return strUrl.replace(encodeRegex, '%27').replace(formatRegex, '/');
  }

  isDateInsidePeriod(date: string, period: Period): boolean {
    const dateToCheck = LuxonDateTime.fromISO(date);
    const startDate = LuxonDateTime.fromISO(period.startDate);
    const endDate = LuxonDateTime.fromISO(period.endDate);
    return startDate <= dateToCheck && dateToCheck <= endDate;
  }

  getWeekdayFromDay(day: string): string {
    return LuxonDateTime.fromISO(day).toFormat('EEEE');
  }

  parseDateToFormat(date: string, format: string): string {
    const dateTime = LuxonDateTime.fromISO(date);
    if (format === 'MMM d') {
      const translationPipe = new TranslationPipe();
      const month = dateTime.toFormat('MMMM');
      const translatedMonth = translationPipe.transform(month);
      return translatedMonth.slice(0,3) + ' ' + dateTime.toFormat('d');
    }
    return dateTime.toFormat(format);
  }

  async showError(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-error',
      icon: 'alert-circle-outline',
    });
    toast.present();
  }

  async showInfo(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-info',
      icon: 'information-circle-outline',
    });
    toast.present();
  }

  async showSuccess(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-success',
      icon: 'checkmark-circle-outline',
    });
    toast.present();
  }

  async showWarning(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: new TranslationPipe().transform(message),
      duration,
      cssClass: 'toast-warning',
      icon: 'alert-circle-outline',
    });
    toast.present();
  }

  async closeLastOpenedToast(): Promise<void> {
    const toast = await this.toastCtrl.getTop();
    if (toast) {
      toast.dismiss();
    }
  }

  async showLoading(params?: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const options: LoadingOptions = {
      message: new TranslationPipe().transform('Loading...'),
      spinner: 'circles',
      mode: 'ios',
      ...params,
    };
    const loading = await this.loadingCtrl.create(options);
    loading.present();
    return loading;
  }

  async showAlert(
    header: string,
    message: string,
    backdropDismiss = false,
    buttons?: Array<AlertButton>
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header,
      message: new TranslationPipe().transform(message),
      buttons,
      backdropDismiss,
      mode: 'ios',
    });

    await alert.present();
    return alert;
  }

  async showConfirmAlert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: new TranslationPipe().transform('Confirm!'),
      message: new TranslationPipe().transform('Are you sure you want to do this?'),
      mode: 'ios',
      buttons: [
        {
          text: new TranslationPipe().transform('Go Back'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: new TranslationPipe().transform('Confirm'),
          role: 'confirm',
        },
      ],
      ...options,
    });
    alert.present();
    return alert;
  }

  parsePaymentMethods(paymentMethods) {
    return paymentMethods?.map((paymentMethod) => {
      let statusClass = '';
      let statusIcon = '';
      if (paymentMethod.status === 'succeeded') {
        statusClass = 'charge-badge-success';
        statusIcon = 'checkmark-circle';
      } else if (paymentMethod.status === 'action_required') {
        statusClass = 'charge-badge-pending';
        statusIcon = 'warning';
      }
      return {
        ...paymentMethod,
        class: statusClass,
        icon: statusIcon,
        status: paymentMethod.status,
      };
    });
  }
}

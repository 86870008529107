import {
  Component,
  Input,
  ViewEncapsulation,
  forwardRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tidy-search-input',
  templateUrl: 'search-input.html',
  styleUrls: ['./search-input.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})
export class SearchInputComponent implements ControlValueAccessor {
  
  @Input() placeholder: string = 'Search...';
  @Input() icon: string = 'assets/svg/search.svg';
  @Input() fixedWidth: boolean = true;
  @Output() valueChange = new EventEmitter<string>();

  value: string = '';
  isInputEmpty: boolean = true;
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.isInputEmpty = !value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInputChange(value: string): void {
    this.value = value;
    this.isInputEmpty = !value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  clearInput(): void {
    this.value = '';
    this.isInputEmpty = true;
    this.onChange('');
    this.onTouched();
    this.valueChange.emit('');
  }
}

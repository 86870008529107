import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'log-details.html',
  encapsulation: ViewEncapsulation.None
})

export class logDetailsPage implements OnInit {

  dialogParams: any;
  loaded: boolean;
  request: any;
  response: any;
  id: any;
  requestBodyJSON: string;
  responseBodyJSON: string;
  timestamp: Date;
  isRightSideContent: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Log Details');
    }
    this.id = this.navCtrl.getParam('id') || this.dialogParams?.id;
    this.timestamp = this.navCtrl.getParam('timestamp') || this.dialogParams?.timestamp;
    this.request = this.navCtrl.getParam('request') || this.dialogParams?.request;
    this.response = this.navCtrl.getParam('response') || this.dialogParams?.response;
    this.setLogDetails(this.request.body, this.response.body);
    this.loaded = true;
  }

  setLogDetails(request: any, response: any){
    this.requestBodyJSON = request;
    this.responseBodyJSON = response;
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Auth } from 'src/providers/auth/auth';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { CookieProvider } from 'src/providers/cookie/cookie';
import { Me } from 'src/providers/me/me';
import { Proposals } from 'src/providers/proposals/proposals';

import { validateEmail } from 'src/shared/validator/validateEmail';
import validationUtils from 'src/shared/util/validation-utils';

@Component({
  templateUrl: 'create-account-to-book.html'
})

export class CreateAccountToBookPage implements OnInit {

  customerResponse: any;
  errorMessage: string;
  form: UntypedFormGroup;
  proposal: any;
  submitted: boolean;
  termsError: boolean;
  utmzTidy: any;

  constructor(
    private auth: Auth,
    private client: Client,
    private fb: UntypedFormBuilder,
    private formBuilder: UntypedFormBuilder,
    private events: Events,
    private cookieProvider: CookieProvider,
    private me: Me,
    private navCtrl: CustomNavController,
    private proposals: Proposals
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      password: ['', Validators.required],
      terms: [true, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    this.proposal = this.navCtrl.getParam('proposal');
    this.form.patchValue({email: this.proposal.customer.email});
  }

  async createAccount() {
    this.submitted = true;
    this.termsError = false;
    if (this.proposal.customer.email !== this.form.value.email) {
      return this.errorMessage = 'This proposal was sent to a different user. Please log into the user the proposal was sent to.';
    }
    if (!this.form.valid) {
      if (!this.form.controls.terms.valid) {
        this.termsError = true;
      }
      return;
    }
    try {
      await this.createUser();
      await this.updateCustomer();
      localStorage.setItem('addressId', this.proposal.customer.address_ids[0]);
      this.proposals.goToBookJob(this.proposal);
    } catch (err) {
      localStorage.clear();
      this.errorMessage = err.message;
    }
  }

  async createUser() {
    await this.authLogin('signUp');
    await this.getCustomers();
    await this.auth.setSession();
    await this.authLogin('signUpPassword');
  }

  async authLogin(scope) {
    const credentials = this.getCredentials();
    const privateProSignUpData = null
    await this.auth.login(credentials, scope, privateProSignUpData);
  }

  getCredentials() {
    this.utmzTidy = this.cookieProvider.getCookie('__utmztidy');
    return {
      username: this.form.value.email,
      password: this.form.value.password,
      date_added_to_cookie: this.utmzTidy ? this.utmzTidy.dateTime : '',
      utm_source: this.utmzTidy ? this.utmzTidy.source : ''
    };
  }

  async getCustomers() {
    const customersObj = this.getCustomer();
    this.customerResponse = await this.client.getCustomers(customersObj);
  }

  getCustomer() {
    return {
      'customer': {
        'booked_via': 'Self Booking',
        'email': this.form.value.email,
        'first_name': this.form.value.firstName,
        'last_name': this.form.value.lastName,
        'password': this.form.value.password,
        'account_type': 'regular',
        'account_name': '',
      }
    }
  }

  async updateCustomer() {
    await this.client.updateCustomer({customer: {developer: false}}, this.customerResponse.id);
  }

  goToClientHub() {
    const clientHubUuId = this.navCtrl.getParam('clientHubUuId');
    this.navCtrl.navigateForward(`client/${clientHubUuId}`);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewEncapsulation, OnChanges, AfterViewInit, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../default-class/value-accessor';

import { RadioButtonModel } from './radio-button.model';

import { deepClone } from '../../../utils/deep-clone';

@Component({
  selector: 'tidy-radio-button',
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RadioButtonComponent,
    multi: true,
  }],
  templateUrl: 'radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent extends ValueAccessorBase<any> implements OnInit, OnChanges, AfterViewInit {
  @Input() errorMessage: string;
  @Input() form: any;
  @Input() helper: string;
  @Input() highlightLabel: boolean;
  @Input() icon: string;
  @Input() items: RadioButtonModel[];
  @Input() label: string;
  @Input() linkText: string;
  @Input() selectError: boolean;
  @Input() selectMessage: string;
  @Input() submitted: boolean;
  @Input() chunkSize = 1;
  @Input() hideItemValues = [] as string[];
  @Input() ordenation: number[];
  itemsInternal: RadioButtonModel[];
  originalCollection: RadioButtonModel[];
  showAllItems = false;

  @Output() linkAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() optionChange: EventEmitter<RadioButtonModel> = new EventEmitter<RadioButtonModel>();
  @Output() showAllOptions: EventEmitter<void> = new EventEmitter<void>();

  public selected: string;
  public loaded = false;

  readonly ATTEMPTS_LIMIT = 10;

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    this.originalCollection = deepClone(this.items);
    this.itemsInternal = this.splitCollectionInChunk(this.originalCollection);
    this.selected = this.value;
  }

  ngAfterViewInit() {
    this.calcLabelWrap();
  }

  onChange(item: RadioButtonModel) {
    this.value = item.value;
    this.optionChange.emit(item);
  }

  expandOptions() {
    this.hideItemValues = [];
    this.showAllItems = true;
    this.itemsInternal = this.splitCollectionInChunk(this.originalCollection);
    this.showAllOptions.emit();
  }

  ngOnChanges(changes: { ordenation?: SimpleChange, items?: SimpleChange }) {
    if (this.showAllItems && changes.ordenation) {
      this.orderItems(changes.ordenation.currentValue);
    }

    if (changes.items && !changes.items.isFirstChange()) {
      this.itemsInternal = this.splitCollectionInChunk(changes.items.currentValue);
    }
  }

  orderItems(newOrdenation: number[]) {
    this.checkDuplicated(newOrdenation);

    const ordenationSet = new Set(newOrdenation);

    const itemsWithIndex = this.originalCollection.map((item, index) => {
      return {
        ...item,
        index
      };
    });

    itemsWithIndex.sort((firstElement, secondElement) => {
      let foundValue = false;

      return [...ordenationSet].reduce((accumulator, order) => {
        if (foundValue) {
          return accumulator;
        }

        if ((firstElement.index - order) === 0) {
          foundValue = true;
          return -1;
        }

        if ((secondElement.index - order) === 0) {
          foundValue = true;
          return 1;
        }
      }, 0);
    });

    this.itemsInternal = this.splitCollectionInChunk(itemsWithIndex);
  }

  checkDuplicated(ordenation) {
    const items = [];

    ordenation.find((item) => {
      if (items.includes(item)) {
        console.warn(`duplicated element: ${item}`);
        return true;
      }

      items.push(item);
    });
  }

  splitCollectionInChunk(items: RadioButtonModel[]) {
    const itemsPerChunkSize = Math.ceil((items.length - this.hideItemValues.length) / this.chunkSize);

    const chunkItems = [];

    for (let i = 0; i < items.length; i += itemsPerChunkSize) {
      const chunkSliceItems = items.slice(i, i + itemsPerChunkSize);
      const chunkItemsFilter = chunkSliceItems.filter(item => !this.hideItemValues.includes(item.value));

      if (chunkItemsFilter.length) {
        chunkItems.push(chunkItemsFilter);
      }
    }

    return chunkItems;
  }

  calcLabelWrap(attempts: number = 0): ReturnType<typeof setTimeout> | void {

    const nativeElementRef = (this.elementRef?.nativeElement as HTMLElement);

    const radioContainerCollection = nativeElementRef?.getElementsByClassName('mat-radio-container');
    const radioButtonElem = (radioContainerCollection?.item(0) as HTMLElement);

    const radioLabelCollection = nativeElementRef?.getElementsByClassName('mat-radio-label-content');
    const radioLabelElem = (radioLabelCollection?.item(0) as HTMLElement);

    if (!(radioButtonElem && radioButtonElem) || !radioButtonElem?.offsetHeight || !radioLabelElem?.offsetHeight) {

      if (attempts > this.ATTEMPTS_LIMIT) {
        return;
      }

      return setTimeout(() => {
        this.calcLabelWrap((attempts + 1));
      }, 100);
    }

    const diffHeight = radioLabelElem.offsetHeight - radioButtonElem.offsetHeight;

    if (diffHeight > 0) {
      const top = radioLabelElem.offsetHeight / 8;
      radioButtonElem.style.top = `${top}px`;

      const lineHeight = radioLabelElem.offsetHeight / 3 + 8;
      radioLabelElem.style.lineHeight = `${lineHeight}px`;
    }
  }
}

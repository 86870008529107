import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { Menu } from 'src/providers/menu/menu';
import { Util } from 'src/shared/util/util';
import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'api-keys.html',
  encapsulation: ViewEncapsulation.None
})

export class ApiKeysPage implements OnInit {

  apiKeys: [{
    id: string,
    token: string,
  }];
  deletingKey = false;
  deletedKeyId: number;
  errorMessage: string;
  form: UntypedFormGroup;
  title = 'API Keys';
  loaded: boolean;
  isRightSideContent: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private util: Util,
    public menu: Menu,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      checkbox: [false, Validators.requiredTrue]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.rightSidePanelService.setDialogPageTitle('API Keys');
    }
    this.errorMessage = '';
    try {
      this.apiKeys = await this.client.getApiKeys();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async addApiKey() {
    this.errorMessage = '';
    try{
      await this.client.addApiKey();
      this.apiKeys = await this.client.getApiKeys();
    } catch(err){
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async deleteApiKey(id) {
    this.title = 'Delete API Key?';
    this.rightSidePanelService.setDialogPageTitle(this.title);
    this.deletingKey = true;
    this.deletedKeyId = id;
  }

  async confirmDeleteApiKey() {
    this.errorMessage = '';
    if (this.form.invalid) {
      return this.errorMessage = 'Please confirm that you want to delete the key.'
    }
    try {
      await this.client.deleteApiKey(this.deletedKeyId);
      this.apiKeys = await this.client.getApiKeys();
      this.stopDeletingApiKey();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  stopDeletingApiKey() {
    this.title = 'API Keys';
    this.rightSidePanelService.setDialogPageTitle(this.title);
    this.deletingKey = false;
  }

  goToApiDocumentation() {
    this.util.openUrl('https://help.tidy.com/api-introduction');
  }
}

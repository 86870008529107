import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tidy-toggle',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./toggle.component.scss'],
  templateUrl: 'toggle.component.html'
})

export  class ToggleComponent implements OnInit, OnChanges {

  @Input() toggled: boolean = false;
  @Output() toggleChanged = new EventEmitter<boolean>();

  selected: boolean;

  ngOnInit() {
    this.selected = this.toggled;
  }

  ngOnChanges() {
    this.selected = this.toggled;
  }

  onToggle() {
    this.toggleChanged.emit(this.selected);
  }

}

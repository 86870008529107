import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingStateService {
  private loadingStack: HTMLIonLoadingElement[] = [];
  private pendingRemoveStack: number[] = [];
  readonly LOADING_OVERLAY_TIMEOUT: number = 15000;

  constructor(
    private loadingCtrl: LoadingController
  ) {}

  set loading(value: boolean) {
    if(value) {
      this.createLoadingOverlay().then(overlayElem => {
        this.loadingStack.push(overlayElem);
        if(this.pendingRemoveStack.length) {
          this.removeNextLoading();
          this.pendingRemoveStack.pop();
        } else {
          overlayElem.present();
        }
      });
    } else {
      this.removeNextLoading();
    }
  }

  get loading() {
    return this.loadingStack.length > 0;
  }

  removeNextLoading() {
    if(this.loading) {
      const overlayElem = this.loadingStack.pop();
      overlayElem && overlayElem.dismiss();
    } else {
      this.pendingRemoveStack.push(this.pendingRemoveStack.length);
    }
  }

  async createLoadingOverlay(): Promise<HTMLIonLoadingElement> {
    return this.loadingCtrl.create({
      cssClass: 'custom-loading',
      duration: this.LOADING_OVERLAY_TIMEOUT,
      spinner: null
    });
  }
}

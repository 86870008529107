import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { navRootOptions } from 'src/shared/constants/rootNavigation';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Client } from "src/providers/client/client";
import { ActivatedRoute } from '@angular/router';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'cancellation-options.html',
  encapsulation: ViewEncapsulation.None
})

export class CancellationOptionsPage implements OnInit {

  errorMessage: string;
  title: string;
  cancellingAccount: boolean;
  form: UntypedFormGroup;
  isAccountOwner: boolean;
  cancellationCheckResponse: any;
  cantGoBack: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      firstCheck: [false, Validators.requiredTrue],
      secondCheck: [false, Validators.requiredTrue],
      thirdCheck: [false, Validators.requiredTrue]
  });
};

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Cancellation Options');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.cantGoBack = this.route.snapshot.data.cantGoBack;
    this.isAccountOwner = localStorage.getItem('user_role') == 'owner';;
    this.title = 'Cancellation Options';
    this.cancellingAccount = false;
  }

  async confirmcancelAllJobs(){
    const params: ConfirmPageParamsModel = {
      title: 'Cancel All Jobs?',
      body: 'Are you sure you want to cancel all your current jobs?',
      backText: 'Go Back',
      confirmText: 'Cancel All Jobs',
      confirmAction: this.cancelAllJobs.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async cancelAllJobs(){
    try {
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      await this.client.cancelAllCleanings();
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  async cancelAccount() {
    this.title = 'Cancel Account Data?';
    this.cancellingAccount = true;
  }

  async confirmAccountCancellation() {
    this.errorMessage = '';
    if (this.form.invalid) {
      return this.errorMessage = 'Please confirm above that you want to delete all account data.'
    }
    try {
      this.cancellationCheckResponse = await this.client.cancelAccountCheck();
      const cancelStatus = this.cancellationCheckResponse.valid;

      if(cancelStatus){
        await this.client.cancelAccount();
        localStorage.clear();
        this.navCtrl.navigateRoot('login', navRootOptions);
      }else{
        const params = this.mountFailedPageParams();
        this.rightSidePanelService.navigateTo('success', params, SuccessPage);
      }

    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  stopCancellingAccount() {
    this.title = 'Cancellation Options';
    this.cancellingAccount = false;
  }

  mountFailedPageParams() {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    return {
      header: 'Cancellation Failed',
      body: 'You can only do this after all pending charges or credits have been resolved. Please give at least 72 hours after your last transaction to try this.',
      buttonText: 'Ok',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, FormArray } from '@angular/forms';
import { AutoCompleteAddressModel } from 'src/models/autocomplete-address.model';
import { AddressComponentsModel, AddressForm } from 'src/providers/address-form/address-form';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';
import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Client } from 'src/providers/client/client';
import { Util } from 'src/shared/util/util';
import { Addresses } from 'src/providers/customer/addresses';
import { CurrentAddress } from 'src/providers/addresses/current-address';

@Component({
  selector: 'tidy-property-onboarding',
  templateUrl: './property-onboarding.component.html',
  styleUrls: ['./property-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PropertyOnboardingComponent implements OnInit {

  submitted = false;
  errorMessage: string;
  showNoPropertiesContent = false;
  forms: FormArray;
  countryList: RadioButtonModel[];
  autocompleteAddress: AutoCompleteAddressModel[] = [];
  radioButtonAddress: RadioButtonModel[] = [];
  didChooseAddress = false;
  typedOnAddress = false;
  zipCodeMask = {
    mask: '00000' // US mask
  };

  constructor(
    private onboardingProvider: OnboardingProvider,
    private formBuilder: UntypedFormBuilder,
    private addressForm: AddressForm,
    private fb: UntypedFormBuilder,
    private multipleCountryService: MultipleCountryService,
    private googleGeocode: GoogleGeocode,
    private addresses: Addresses,
    private client: Client,
    private currentAddress: CurrentAddress,
    private util: Util
  ) {
    this.forms = this.fb.array([]);
    this.forms.push(this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      addressNickname: [''],
      phone: [''],
    }));
    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  ngOnInit() {
    this.onboardingProvider.priorPages.push('property');
  }

  goToAddDirectIntegration() {
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'directIntegration';
  }

  goToImportCSV() {
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'importCSV';
  }

  async addProperty() {
    this.submitted = true;
    if (this.forms.controls.some(form => form.invalid)) {
      return;
    }
    const addressArray = this.forms.controls[0].value.address.split(',');
    //TODO: Note look at add-address.ts. There is some logic regarding autocomplete / multipleCountryService / addressStateFallback. We need to add that logic here.
    const data: any = {
      country_code: this.forms.controls[0].value.countryCode,
      add_state: this.forms.controls[0].value.state,
      city: this.forms.controls[0].value.city,
      address1: addressArray[0],
      address2: this.forms.controls[0].value.address2,
      zip: this.forms.controls[0].value.zipcode,
      latitude: this.forms.controls[0].value.latitude,
      longitude: this.forms.controls[0].value.longitude
    }
    if (this.forms.controls[0].value.addressNickname) {
      data['address_name'] = this.forms.controls[0].value.addressNickname;
    }
    const address = await this.addresses.createAddress(data);
    localStorage.setItem('region_id', address.region_id);
    this.currentAddress.addressId = address.id;
    const addressResponse = await this.client.getAddresses(true);
    const moreDetailAddressResponse = await this.client.getMoreDetailAddresses(true);
    this.util.showSuccess('Property added succesfully');
    if (this.onboardingProvider.flow == 'property') {
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
    } else {
      this.onboardingProvider.showBackButton = false;
      this.onboardingProvider.priorPages = [];
      this.onboardingProvider.page = this.onboardingProvider.flow;
      this.onboardingProvider.shouldShowAddProperty = false;
      this.onboardingProvider.setOnboardingFlowText();
    }
  }

  async chooseItem(item: { placeId: string, value: string | number, display: string}, form: UntypedFormGroup) {
    const zipcode = await this.addressForm.chooseItem(item);
    if (!zipcode && this.multipleCountryService.checkIfContryCodeIsUs(form.get('countryCode').value)) {
      this.errorMessage = `It looks like this address isn't detailed enough, please update it. If you still have issues please contact us in the Concierge section.`;
    }
    const address: AddressComponentsModel = await this.addressForm.getAdditonalAddressInfo(item.placeId);
    form.patchValue({ zipcode, ...address });
    const latLngData = await this.googleGeocode.getLatLong(item.placeId);
    form.patchValue(latLngData);
    this.autocompleteAddress = [];
    this.didChooseAddress = true;
    this.typedOnAddress = false;
  }

  updateSearch = async (form: UntypedFormGroup) => {
    this.errorMessage = '';
    try {
      this.autocompleteAddress = await this.addressForm.updateSearch(form.value.address, form.get('countryCode').value);
    } catch (err) {
      this.errorMessage = 'An error occurred when getting address information. Please type another address.';
    }
    this.radioButtonAddress = this.autocompleteAddress.map(address => {
      return {
        value: address.value,
        viewValue: address.display,
        placeId: address.placeId
      }
    });
    this.typedOnAddress = true;
    this.didChooseAddress = false;
  }

  changeCountrySelected(countryCodeSelected, form: UntypedFormGroup) {
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCodeSelected);
    form.get('zipcode').setValidators(validatorObject.validator);
    this.zipCodeMask = validatorObject.mask;
    setTimeout(() => {
      form.patchValue({
        address: null,
        address2: null,
        zipcode: null
      });
    });
  }

  addAnotherProperty() {
    this.forms.push(this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      addressNickname: [''],
      phone: [''],
    }));
  }

  removeProperty(form: UntypedFormGroup) {
    this.forms.removeAt(this.forms.controls.indexOf(form));
  }
}

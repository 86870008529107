<div class="onboarding-header">
  <div class="onboarding-header-title">
    <tidy-text [title]="'Schedule a Free Advanced Setup Call'"></tidy-text>
  </div>
  <div>
    <button mat-button fill="clear" color="primary" style="min-width: 0px" (click)="closeModal()">
      <tidy-text [body]="'X'"></tidy-text>
    </button>
  </div>
</div>
<tidy-divider></tidy-divider>

<div class="advanced-setup-content">
  <div>
    <tidy-button
      class="secondary"
      [action]="scheduleCall.bind(this)"
      [text]="'Schedule Call'">
    </tidy-button>
  </div>
</div>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="loaded ? (proName + '\'s Proposal') : ''"
    [canGoBack]="true">
    <tidy-wrapper *ngIf="loaded">
      <tidy-row>
        <tidy-divider>
        </tidy-divider>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/icons/number_1_white.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [actionBody]="(service?.name | titlecase) + ' - ' + planFrequencyName"
          class="white">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center extra-bottom-padding">
        <tidy-image
          [src]="'assets/img/icons/number_2_white.svg'"
          class="body-size">
        </tidy-image>
        <ng-container *ngIf="this.form.value.time">
          <tidy-text
            [actionBody]="this.form.value.time?.date | customdate:'ddd M/D'"
            class="white">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [actionBody]="'at'"
            class="white">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [actionBody]="this.form.value.time?.time | customdate:'h:mma':null:'HH:mm'"
            class="white">
          </tidy-text>
        </ng-container>
        <ng-container *ngIf="this.form.value.time">
          <tidy-text
            [actionBody]="'Select Time'"
            class="white">
          </tidy-text>
        </ng-container>
      </tidy-row>
      <tidy-button
        [text]="'Confirm & Book'"
        [action]="acceptTime.bind(this)"
        [disabled]="form.invalid"
        class="primary">
      </tidy-button>
    </tidy-wrapper>
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row>
        <tidy-text
          [body]="proName">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'could not help you during the times you requested for a'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="service?.name + '.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'However they said they can at one of the following times.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [title]="'Select Time With'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [title]="proName">
        </tidy-text><br>
        <tidy-text
          [body]="'@' + addressName">
        </tidy-text>
      </tidy-row>

      <form [formGroup]="form" novalidate action="#">
        <tidy-card *ngFor="let day of proposedTimes">
          <tidy-row>
            <tidy-text
              [header]="day.date | customdate:'dddd M/D'">
            </tidy-text>
          </tidy-row>
            <tidy-row>
              <tidy-radio-button
                formControlName="time"
                [form]="form.controls.time"
                [items]="day.times"
                [(ngModel)]="form.value.time"
                chunkSize="2">
              </tidy-radio-button>
            </tidy-row>
        </tidy-card>
      </form>

      <tidy-button
        *ngIf="windowService?.isDesktopRes"
        [text]="'Confirm & Book'"
        [action]="acceptTime.bind(this)"
        [disabled]="form.invalid"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          (action)="decline()"
          class="link red"
          [body]="'Decline Proposal'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

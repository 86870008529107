import {
        Component,
        OnInit,
        ViewChild,
        ViewEncapsulation,
        Input,
        EventEmitter,
        Output,
        SimpleChanges,
        OnChanges,
        ChangeDetectionStrategy,
        ChangeDetectorRef,
      } from '@angular/core';
import { ValueAccessorBase } from '../default-class/value-accessor';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterModel } from './filter.model';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import translate from "translate";

@Component({
  selector: 'tidy-filter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FilterComponent,
      multi: true,
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'tidy-filter-overlay-panel' },
    },
  ],
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent
  extends ValueAccessorBase<any>
  implements OnInit, OnChanges
{
  @Input() items: FilterModel[];
  @Input() label: string;
  @Input() icon: string;
  @Input() submitted: boolean;
  @Input() newValue: string | number | boolean = null;
  @Input() fitContent: boolean;
  @Input() initValue: string | number | boolean;
  @Input() translate = false;
  @Output() optionChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchPlaceholder: string = '';
  @ViewChild(NgModel, { static: false }) model: NgModel;

  activeClass = {};
  public selected: string;
  public loaded = false;
  touchUpdate = false;
  readonly ENABLE_SEARCH_MIN_ITEMS = 5;
  listedItems: FilterModel[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  async ngOnInit() {
    this.value = this.initValue;
    this.selected = this.value;
    this.activeClass['fit-content'] = this.fitContent;
    translate.engine = "google";
    translate.key = '2e06be14780df1d2aff3bad979ccd9e25182d888';
    await this.translateItemValues();
  }

  /**
   * Translate the viewValue of the items,
   * use change detection to update the view when the translation is done.
   */
  async translateItemValues(): Promise<void> {
    let language = navigator.language;
    if (language?.includes('-')) {
      language = language.split('-')[0];
    }
    if (this.translate && language && language !== 'en') {
      const translations = await Promise.all(this.items.map(async (item) => {
        try {
          const translatedValue = await translate(item.viewValue, language);
          return { ...item, viewValue: translatedValue };
        } catch (err) {
          console.error(err);
          return item;
        }
      }));
      this.items = translations;
      this.listedItems = translations;
      this.changeDetectorRef.detectChanges();
    }
  }

  onChange(e) {
    this.touchUpdate = true;
    setTimeout(() => this.optionChange.emit(e.value));
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(this.newValue){
      this.value = this.newValue;
      this.selected = this.value;
    }
    await this.translateItemValues();
    this.listedItems = this.items;
  }

  searchItems(value: string) {
    this.listedItems = this.items.filter((item) => {
      return item.viewValue.toLowerCase().includes(value.toLowerCase());
    });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Edit Prices'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row class="extra-bottom-padding">
        <form [formGroup]="addressForm">
          <tidy-filter
            [items]="addressList"
            [form]="addressForm.controls.address"
            formControlName="address"
            (optionChange)="changeProperty()"
            [initValue]="addressId">
          </tidy-filter>
        </form>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Edit your base prices for this property below. Your base price is what you pay for a recurring job where you are charged before. Same day, one time and charge after prices are calculated automatically based on your base price.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <ng-container *ngFor="let service of services">
            <tidy-row *ngIf="service.billing_type !== 'price_later'">
              <tidy-input
                [formControlName]="service.id"
                [label]="service.name"
                [form]="form.controls[service.id]"
                [icon]="'assets/svg/cash-outline.svg'"
                [errorMessage]="'Please select a price.'"
                [submitted]="submitted"
                inputMode="numeric"
                [mask]="'currencyMask'">
              </tidy-input>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Save Changes'"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

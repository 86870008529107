import { Component, Input, ViewEncapsulation, Host, Optional } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingStateService } from '../../providers/loading-state/loading-state.service';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'tidy-button',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./button.component.scss'],
  template: `
    <div class="wrapper">
      <button
        expand="block"
        [ngClass]="{
          'mat-flat-button': hasATidyCardHost,
          'mat-raised-button': !hasATidyCardHost
        }"
        mat-button
        [disabled]="disabled || loadingState.loading"
        tappable
        (click)="executeAction()"
        [type]="type" block
      >
        <tidy-image *ngIf="image" [src]="image" [width]="imageWidth"></tidy-image>
        <tidy-text [largeBody]="text" *ngIf="!actionLoading && !smallText && !smallBody"></tidy-text>
        <tidy-text [body]="text" *ngIf="!actionLoading && smallText"></tidy-text>
        <tidy-text [smallBody]="text" *ngIf="!actionLoading && smallBody"></tidy-text>
        <ion-spinner *ngIf="actionLoading"></ion-spinner>
      </button>
    </div>
  `
})
export class ButtonComponent {

  @Input() image: string;
  @Input() imageWidth: string;
  @Input() text: string;
  @Input() type = 'submit';
  @Input() disabled = false;
  @Input() action = null;
  @Input() smallText = false;
  @Input() smallBody = false;
  actionLoading: boolean;

  constructor(
    public loadingState: LoadingStateService,
    private toastCtrl: ToastController,
    @Optional() @Host() public hasATidyCardHost: CardComponent
  ) { }

  async executeAction() {
    if (this.loadingState.loading || !this.action) {
      return;
    }

    try {
      this.loadingState.loading = true;
      this.actionLoading = true;
      await this.action();
    } catch (error) {
      await this.showError(error);
    } finally {
      this.loadingState.loading = false;
      this.actionLoading = false;
    }
  }

  async showError(message) {
    await (await this.toastCtrl.create({ message, duration: 3000 })).present();
  }
}

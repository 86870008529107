<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Scan Your Property'"
    [canGoBack]="'true'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-card style="padding-bottom: 0px">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/navigate-circle.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Select Your Lot'">
        </tidy-text>
      </tidy-row>
      <ng-container *ngIf="isMobile">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'First, tap a corner of your lot and drag to the next one.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Once the first line is created, tap each corner of your lot.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'To finish, <u><b>tap the screen and hold</b></u> on the last remaining corner.'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <ng-container *ngIf="!isMobile">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'First, click a corner of your lot and drag to the next one.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Once the first line is created, click each corner of your lot.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'To finish the selection, <u><b>double click</b></u> the last remaining corner.'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <div style="margin-right: -15px; margin-left: -15px">
        <img [src]="mapToShow" id="map"/>
      </div>
    </tidy-card>

    <ng-container>
      <tidy-button
        [text]="'Looks Good'"
        [action]="submitSelection.bind(this)"
        class="primary"
        [disabled]="!showButtons">
      </tidy-button>
      <tidy-button
        [text]="'Retry'"
        [action]="resetAnotations.bind(this)"
        class="secondary"
        [disabled]="!showButtons">
      </tidy-button>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Pipe, PipeTransform } from '@angular/core';

/**
 *  How to Use
 *
 *  Input:  {{'+19099088613' | telephone:'format'}}
 *  Output: (909) 908-8613
 *
 *  Input:  {{'(909) 908-8613' | telephone:'clean'}}
 *  Output: 9099088613
 */

@Pipe({
  name: 'telephone'
})
export class TelephonePipe implements PipeTransform {

  transform(tel: string, method: string = 'format'): string {
    const maxLength = 10;
    let longDistanceCode = '';

    if (!tel) {
      return '';
    }

    if (tel.charAt(1) !== '1') {
      return tel;
    }

    let value = this.standardizeNumber(tel);

    if (value.length > maxLength) {

      if (value.charAt(0) === '1') {
        longDistanceCode = value.charAt(0);
        value = value.substring(1, maxLength + 1);
      } else {
        value = value.substring(0, maxLength);
      }

    }

    return method === 'clean'
            ? value
            : this.formatPhoneNumber(value, longDistanceCode);
  }

  standardizeNumber(telephone: string) {
    return telephone.toString().replace('+1', '').trim().replace(/\D/g, '');
  }

  formatPhoneNumber(value, longDistanceCode) {
    const cityCodeLength = 3;
    const numberLength = 7;

    if (value.length > cityCodeLength) {
      const city = value.slice(0, cityCodeLength);
      let number = value.slice(cityCodeLength);

      if (number.length > cityCodeLength) {
        number = number.slice(0, cityCodeLength) + '-' + number.slice(cityCodeLength, numberLength);
      }

      return `${longDistanceCode} (${city}) ${number}`.trim();
    }
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FeedbackCleaning } from 'src/providers/feedback/feedback';
import { Logger } from 'src/providers/logger';
import { CleaningCards } from 'src/providers/cleaning-cards/cleaning-cards';
import { Auth } from 'src/providers/auth/auth';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CustomNavParams } from 'src/shared/providers/custom-nav-params/custom-nav-params';
import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { NeedsWorkMoreInfoPage } from '../more-info/more-info';
import { Util } from 'src/shared/util/util';


@Component({
  templateUrl: 'what-happened.html',
  encapsulation: ViewEncapsulation.None
})
export class NeedsWorkWhatHappenedPage implements OnInit {

  cleaningId: any;
  controls: any;
  errorMessage: any;
  hkJobId: any;
  hkName: any;
  keys: any;
  loaded = false;
  invalidMessage = false;
  pro: any;
  questions: any;
  whatHpndForm: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private auth: Auth,
    private customNavParams: CustomNavParams,
    private cleaningCards: CleaningCards,
    private formBuilder: UntypedFormBuilder,
    private feedbackCleaning: FeedbackCleaning,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private utils: Util,
  ) {
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Needs Work');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    await this.loadParams();
    this.startFeedback();
    const controls = this.feedbackCleaning.getWhatHappenedControls();
    this.keys = Object.keys(controls);
    this.questions = this.feedbackCleaning.getWhatHappenedQuestions(this.hkName);
    this.whatHpndForm = this.formBuilder.group(controls);
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async startFeedback() {
    try {
      await this.feedbackCleaning.startFeedback(this.cleaningId, this.hkJobId, 'negative');
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  async loadParams() {
    this.hkName = await this.getParam('firstName');
    this.pro = await this.getParam('pro');
    this.cleaningId = await this.getParam('cleaningId') || this.route.snapshot.paramMap.get('cleaningId') || await this.getParam('jobId') || this.route.snapshot.paramMap.get('jobId');
    this.hkJobId = await this.getParam('hkJobId') || this.route.snapshot.paramMap.get('hkJobId');
    const authToken = await this.getParam('authToken') || this.route.snapshot.paramMap.get('authToken');
    const addressId = await this.getParam('addressId') || this.route.snapshot.paramMap.get('addressId');

    if (authToken) {
      await this.authenticate(authToken, addressId);
    }
    if (this.hkName === null) {
      await this.getHkName();
    }
  }

  async authenticate(authToken, addressId) {
    try {
      if (authToken && addressId) {
        const authData = { authToken: authToken, addressId: addressId }
        await this.auth.autoLogin(authData);
      }
    } catch (err) {
      this.logger.error(err, 'feedback-init');
      this.errorMessage = err.message;
    }
  }

  async sendFeedback() {
    if (!this.validateSome()) {
      this.invalidMessage = true;
      return;
    }
    try {
      const jobId = this.cleaningId || this.dialogParams?.cleaningId || this.dialogParams?.jobId;
      const params = {
        jobId,
        hkJobId: this.hkJobId,
        type: 'negative'
      };
      await this.feedbackCleaning.submitMoreNegativeFeedback(params, this.whatHpndForm.value);

      const nextPageParams = {
        hkName: this.hkName,
        pro: this.pro,
        isCleaningJob: await this.getParam('isCleaningJob'),
        cleaningId: jobId
      }
      this.rightSidePanelService.navigateTo(`needs-work-more-info/${jobId}/${this.hkJobId}`, nextPageParams, NeedsWorkMoreInfoPage);
    } catch (error) {
      const errorMessage = error?.message || error?.err?.error?.message || 'Something went wrong';
      this.utils.showError(errorMessage);
    }
  }

  validateSome() {
    const values = Object.values(this.whatHpndForm.value);

    return values.some(value => {
      return value === true;
    });
  }

  async getHkName() {
    const cleaningData = await this.cleaningCards.getCleaning(this.cleaningId);
    const hkData = await this.cleaningCards.getHkdataByHkJobId(cleaningData, this.hkJobId);
    this.hkName = hkData.firstName;
    this.customNavParams.setParams('feedbackHkName', this.hkName);
  }

}

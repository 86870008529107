<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="!windowService.isDesktopRes"
    [title]="'Properties'"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-desktop-header *ngIf="windowService.isDesktopRes">
        <tidy-image
          style="z-index: 1000"
          [src]="'assets/svg/arrow-back-black.svg'"
          (action)="goBack()"
          class="desktop-back-icon">
        </tidy-image>
        <tidy-text
          [title]="'Properties'">
        </tidy-text>
        <div class="desktop-nav-button desktop-nav-button-green">
          <tidy-button
            [action]="goToAddAddress.bind(this)"
            [image]="'assets/img/add-white.svg'"
            [text]="'Add Property'"
            [smallBody]="true">
          </tidy-button>
        </div>
        <div style="display: flex; justify-content: flex-end; align-items: center; margin-left: auto;">
            <tidy-text [body]="'Edit Order'"></tidy-text>
            <tidy-toggle
              [toggled]="isEditPropertiesEnabled"
              (toggleChanged)="onToggleChange()"
              class="extra-left-margin">
            </tidy-toggle>
        </div>
        <ng-container *ngIf="!loaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 198px;
              height: 40px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
      </tidy-desktop-header>

      <tidy-row *ngIf="!windowService.isDesktopRes" style="padding-top: 6rem">
        <tidy-button
          [text]="'Add Property'"
          [action]="goToAddAddress.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row
        *ngIf="!windowService.isDesktopRes"
        [alignLastItemRight]="true"
        style="display: flex; justify-content: center">
        <tidy-text [body]="'Edit Order'"></tidy-text>
        <tidy-toggle
          [toggled]="isEditPropertiesEnabled"
          (toggleChanged)="onToggleChange()"
          class="extra-left-margin">
        </tidy-toggle>
      </tidy-row>

      <ng-container *ngIf="!isEditPropertiesEnabled">
        <tidy-alert>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-top-padding" style="padding-bottom: 0px">
              <tidy-input
                #searchInput
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="form">
              </tidy-input>
            </tidy-row>
          </form>
        </tidy-alert>
      </ng-container>

      <tidy-drag-drop
        dragDropId="addresses-dd"
        [dataList]="addresses"
        emptyListColor="green"
        emptyListMessage="Drag to add to list."
        emptyDisabledListMessage="No Addresses Added"
        [disabled]="!isEditPropertiesEnabled"
        (listDropped)="updateAddresses($event)">
        <ng-container *ngFor="let address of addresses; let last = last">
          <ng-container *tidyDragDropItem>
            <tidy-card *ngIf="isEditPropertiesEnabled">
              <ng-container *ngIf="!address?.address_name">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    class="header-size"
                    style="position: absolute; margin-top: 23px"
                    [src]="'assets/svg/menu-tidy-green.svg'">
                  </tidy-image>
                  <tidy-text
                    style="padding-left: 25px"
                    [body]="address?.address1 + ' '"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row
                  style="padding-left: 25px"
                  *ngIf="address?.address2 !== null && address?.address2 !== ''">
                  <tidy-text [body]="address?.address2"> </tidy-text>
                </tidy-row>
                <tidy-row style="padding-left: 25px">
                  <tidy-text
                    *ngIf="address?.city !== null && address?.city !== ''"
                    [helper]="address?.city">
                  </tidy-text>
                  <tidy-text
                    *ngIf="address?.zip !== null && address?.zip !== ''"
                    [helper]="', ' + address?.zip">
                  </tidy-text>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="address?.address_name">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    class="header-size"
                    [src]="'assets/svg/menu-tidy-green.svg'"
                    [style]="address?.integration?.name ? 'position: absolute; margin-top: 23px' : ''">
                  </tidy-image>
                  <tidy-text
                    [body]="address?.address_name"
                    [translate]="false"
                    [style]="address?.integration?.name ? 'padding-left: 25px' : ''">
                  </tidy-text>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="address?.integration?.name">
                <tidy-row style="padding-left: 25px">
                  <tidy-text [helper]="'Synced with '"> </tidy-text>
                  <tidy-text
                    [helper]="address?.integration?.name"
                    [translate]="false">
                  </tidy-text>
                  <tidy-text [helper]="' ('+ address.integration.state + ')'">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </tidy-card>
          </ng-container>
        </ng-container>
      </tidy-drag-drop>

      <ng-container *ngIf="!isEditPropertiesEnabled">
        <tidy-card *ngFor="let address of addresses; let last = last">
          <tidy-card-button (action)="goToEditAddress(address)">
            <ng-container *ngIf="!address?.address_name">
              <tidy-row>
                <tidy-text [body]="address?.address1 + ' '" [translate]="false">
                </tidy-text>
              </tidy-row>
              <tidy-row
                *ngIf="address?.address2 !== null && address?.address2 !== ''">
                <tidy-text [body]="address?.address2"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  *ngIf="address?.city !== null && address?.city !== ''"
                  [helper]="address?.city">
                </tidy-text>
                <tidy-text
                  *ngIf="address?.zip !== null && address?.zip !== ''"
                  [helper]="', ' + address?.zip">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="address?.address_name">
              <tidy-row>
                <tidy-text [body]="address?.address_name" [translate]="false">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="address?.integration?.name">
              <tidy-row>
                <tidy-text [helper]="'Synced with'"> </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="address?.integration?.name"
                  [translate]="false">
                </tidy-text>
                <tidy-text [helper]="' ('+ address.integration.state + ')'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="addresses?.length == 0">
        <tidy-text
          [body]="'No results'">
        </tidy-text>
      </tidy-alert>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'selectAccessNotes'">
</tidy-onboarding-modal>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Notifications'"
    [canGoBack]="true"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Add notifications to your account:'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <ng-container *ngIf="isAccountAdmin && hasTeams">
            <tidy-row>
              <tidy-text
                [header]="'Team to Notify'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [label]="'What team do you want to notify?'"
                formControlName="team"
                [form]="form.controls.team"
                [items]="teamItems">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-text
              [header]="'Notification Channel'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Where should we send these notifications?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-radio-button
              formControlName="channel"
              [form]="form.controls.channel"
              [items]="channelItems">
            </tidy-radio-button>
          </tidy-row>
          <ng-container *ngIf="form.value.channel">
            <tidy-row>
              <tidy-text
                [header]="getWebhookUrlLabel()">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                *ngIf="form.value.channel == 'slack'"
                [body]="'Add a Slack webhook to get notified on Slack.' ">
              </tidy-text>
              <tidy-text
                *ngIf="form.value.channel == 'webhook'"
                [body]="'Add a webhook to get notified in your application.' ">
              </tidy-text>
              <tidy-text
                [body]="'Learn More'"
                class="link"
                (action)="goToLearnMoreNotifications()">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-bottom-padding field-top-padding">
              <tidy-input
                formControlName="webhookUrl"
                [label]="'Endpoint URL'"
                [form]="form.controls.webhookUrl"
                [icon]="'assets/svg/globe-outline.svg'"
                [errorMessage]="'Please input a URL.'"
                [submitted]="submitted">
              </tidy-input>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-text
              [header]="'Notification Events'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'What events should trigger notifications?'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let event of eventItems">
            <tidy-checkbox
              [(checked)]="event.checked"
              [items]="[{value: event.value, viewValue: (event.viewValue | translate)}]">
            </tidy-checkbox>
          </ng-container>
          <tidy-error-message
            *ngIf="showSelectedEventsError"
            [text]="'Please select at least one event.'">
          </tidy-error-message>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add Account Notification'"
          [action]="addWebhook.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


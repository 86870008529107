import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BookJob } from 'src/providers/book-job/book-job';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SelectCategoryPage } from '../select-category/select-category';
import { WindowService } from 'src/shared/providers/window.service';

import { BookJobPage } from 'src/pages/booking/book-job/book-job';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'plans.html',
  encapsulation: ViewEncapsulation.None
})

export class PlansPage implements OnInit {

  address: any;
  addressFilter: any;
  addressResponse: any;
  cameFromBookingFlow: boolean;
  errorMessage: string;
  jobs: any;
  plans: any;
  isRightSideContent: boolean;
  loaded: boolean;

  constructor(
    private bookJobService: BookJob,
    private client: Client,
    private currentAddress: CurrentAddress,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private rightSidePanelService: RightSidePanelService,
    private schedule: Schedule,
    private storage: TidyStorage,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.rightSidePanelService.setDialogPageTitle('Plans');
      const dialogParams = await this.storage.retrieve('dialog-params');
      this.plans = dialogParams.plans;
      this.address = dialogParams.address;
      this.addressFilter = dialogParams.addressFilter;
      this.addressResponse = await this.client.getMoreDetailAddresses(true);
      this.jobs = await this.schedule.getCards(this.address.id),
      this.cameFromBookingFlow = dialogParams.cameFromBookingFlow;
      await this.changeAddress(this.address.id);
    } else {
      this.plans = this.navCtrl.getParam('plans', true);
      this.address = this.navCtrl.getParam('address', true);
      this.addressFilter = this.navCtrl.getParam('addressFilter', true);
      this.addressResponse = await this.client.getMoreDetailAddresses(true);
      this.jobs = this.navCtrl.getParam('cards') || await this.schedule.getCards(this.address.id);
      this.cameFromBookingFlow = this.navCtrl.getParam('cameFromBookingFlow');
      await this.changeAddress(this.address.id);
    }
    this.loaded = true;
  }

  @Loading('', true)
  async changeAddress(selectedAddressId) {
    try {
      this.jobs = await this.schedule.getCards(selectedAddressId);
      this.addressResponse.map((address) => {
        if (address.id === selectedAddressId) {
          this.plans = address.plans;
          this.address = address;
          this.currentAddress.addressId = address.id.toString();
        };
      });
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getJobsFromPlan(planId) {
    const jobs: any = [];
    this.jobs.map((job) => {
      if (job.template_data?.booking?.plan_id == planId && job?.template == 'cleaning') {
        jobs.push(job);
      }
    });
    return jobs;
  }

  getJobRequestsFromPlan(planId) {
    const jobRequests: any = [];
    this.jobs.map((job) => {
      if (job.template_data?.booking?.plan_id == planId && job?.template == 'job_request') {
        jobRequests.push(job);
      }
    });
    return jobRequests;
  }

  async goToReschedulePlan(plan) {
    const params = {
      bookingType: 'reschedule_plan',
      address: this.address,
      planId: plan.id,
      isReschedulingOneTimePlan: plan.plan_frequency == 'once' ? 'job' : 'plan'
    }
    const dialogParams = await this.storage.retrieve('dialog-params');
    params['categoryId'] = dialogParams?.categoryId;
    params['categoryHeader'] = dialogParams?.categoryHeader;
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async goToAddOneTimeJob() {
    await this.storage.save('bookJobBackPage', 'plans');
    let params = {
      address: this.address,
      bookingType: 'add_one_time_job',
      isReschedulingOneTimePlan: false
    }
    const dialogParams = await this.storage.retrieve('dialog-params');
    params['categoryId'] = dialogParams?.categoryId;
    params['categoryHeader'] = dialogParams?.categoryHeader;
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async cancelPlan(plan) {
    const hasMultipleJobsInPlan = (this.getJobsFromPlan(plan?.id)?.length + this.getJobRequestsFromPlan(plan?.id)?.length) > 1;
    const params: ConfirmPageParamsModel = {
      title: plan?.plan_frequency == 'once' ? (hasMultipleJobsInPlan ? 'Cancel Jobs?' : 'Cancel Job?') : 'Cancel Plan?',
      body: '',
      backText: 'Go Back',
      confirmText: plan?.plan_frequency == 'once' ? (hasMultipleJobsInPlan ? 'Cancel Jobs' : 'Cancel Job') : 'Cancel Plan',
      confirmAction: await this.confirmCancel.bind(this, plan.id),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancel(planId) {
    try {
      this.storage.delete('moreDetailAddresses');
      await this.schedule.cancelPlan(planId);
      this.modalCtrl.dismiss();
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async bookJob(bookingType) {
    await this.storage.save('bookJobBackPage', 'plans');
    await this.client.getClientInfo();
    localStorage.setItem('region_id', this.address.region_id);
    const address = this.address;
    const hasPrivatePros = await this.pros.checkIfHasPrivatePro();
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    if (hasPrivatePros || isRentalClient) {
      const url = 'select-category';
      const component = SelectCategoryPage;
      this.rightSidePanelService.navigateTo(url, {}, component);
    } else {
      this.navCtrl.navigateForward('book-job', { address, bookingType });
    }
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Util } from 'src/shared/util/util';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { OnboardingComponent } from '../onboarding/onboarding';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'app-pick-your-goals',
  templateUrl: './pick-your-goals.html',
  styleUrls: ['./pick-your-goals.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PickYourGoalsComponent implements OnInit {

  goals: any[] = [];
  chosenGoals: any[] = [];
  errorMessage: string;
  loaded: boolean = false;

  constructor(
    private modalController: ModalController,
    private storage: TidyStorage,
    private util: Util,
    private onboardingProvider: OnboardingProvider
  ) {}

  async ngOnInit() {
    const goals = await this.storage.retrieve('goalsToPick');
    this.goals = [
      {
        title: 'Automate Turnovers',
        image: 'assets/svg/bolt.svg',
      },
      {
        title: 'Automate Restocking',
        image: 'assets/svg/package-open.svg',
      },
      {
        title: 'Smarter Maintenance',
        image: 'assets/svg/tool.svg',
      },
      {
        title: 'Organize Operations',
        image: 'assets/svg/bolt.svg',
      },
      {
        title: 'Enable Upsells',
        image: 'assets/svg/dollar-sign.svg',
      },
      {
        title: 'Improve Job Quality',
        image: 'assets/svg/check-square.svg',
      },
      {
        title: 'Save Time',
        image: 'assets/svg/clock.svg',
      }
    ];
    goals.map((goal) => {
      this.goals.map((g) => {
        if (g.title === goal.title) {
          g['id'] = goal.id;
        }
      });
    });
    this.loaded = true;
  }

  selectGoal(goal: any) {
    if (this.chosenGoals.includes(goal)) {
      this.chosenGoals = this.chosenGoals.filter((g) => g !== goal);
    } else {
      this.chosenGoals.push(goal);
    }
  }

  pickAllGoals() {
    if (this.chosenGoals.length === this.goals.length) {
      this.chosenGoals = [];
    } else {
      this.chosenGoals = this.goals;
    }
  }

  async saveGoals() {
    await this.storage.delete('didJustSignUp');
    if (this.chosenGoals.length === 0) {
      return this.errorMessage = 'Please select at least one goal';
    }
    let payload = this.chosenGoals.map((g) => {
      return {user_goal_type_id: g.id, chosen: true};
    });
    this.goals.map((g) => {
      if (!this.chosenGoals.includes(g)) {
        payload.push({user_goal_type_id: g.id, chosen: false});
      }
    });
    try {
      await this.onboardingProvider.saveChosenGoals(payload);
      await this.onboardingProvider.getGoalsFromServer();
      await this.onboardingProvider.setShowOnboardingInHeader(true);
      await this.modalController.getTop().then((modal) => {
        modal.canDismiss = true;
        modal.dismiss();
      });
      this.openOnboarding();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async openOnboarding() {
    const modal = await this.modalController.create({
      component: OnboardingComponent,
      cssClass: 'onboarding-modal',
      mode: 'ios',
    });
    await modal.present();
  }

}

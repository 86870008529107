<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        *ngIf="type === 'support.other'"
        [body]="otherText">
      </tidy-text>
      <tidy-text
        [body]="'Open a dispute directly with'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="job?.homekeepers[0].first_name">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'for the job on'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="(job?.job?.date | customdate:'M/D/YY')">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'at'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="(job?.job?.start_time | customdate:'h:mma') + '. ' + job?.homekeepers[0].first_name">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'will have 3 days to accept your dispute or propose another resolution.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
         <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-textarea
            [label]="'What Happened?'"
            formControlName="message"
            [form]="form.controls.message"
            [submitted]="submitted"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [errorMessage]="'Please enter a message'">
          </tidy-textarea>
        </tidy-row>
        <tidy-row>
          <tidy-input
            [label]="'What % of the job cost do you want back?'"
            [icon]="'assets/svg/cash-outline.svg'"
            formControlName="percentage"
            [form]="form.controls.percentage"
            [submitted]="submitted"
            [customMask]="percentageMask"
            [inputMode]="'numeric'"
            [errorMessage]="'Please enter how much you are seeking.'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-radio-button
            [label]="'How do you want the money back?'"
            formControlName="cashback"
            [form]="form.controls.cashback"
            [items]="[{viewValue: 'Credit', value: 'credit'},{viewValue: 'Refund', value: 'refund'}]">
          </tidy-radio-button>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Open Job Dispute'"
      [action]="send.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-row [align]="'center'">
      <tidy-text
        [header]="'Dispute Tips:'">
      </tidy-text>
    </tidy-row>

    <tidy-alert class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/split-money.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
            <tidy-row>
              <tidy-text
                [header]="'Try a partial resolution'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                body='Seeking "all" or "nothing" is often is rejected by the other party.'>
              </tidy-text>
            </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-alert>

    <tidy-alert class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/shake-hands.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
            <tidy-row>
              <tidy-text
                [header]="'Be polite'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Parties will be more likely to accept your resolution.'">
              </tidy-text>
            </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

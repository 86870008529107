<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'How would you like to add a workflow?'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card class="concierge-alert default-card">
      <tidy-card-button
        class="arrow-to-top"
        style="padding-bottom: 0px"
        (action)="goToAddConciergeTask()">
        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/img/concierge.svg'" class="header-size">
          </tidy-image>
          <tidy-text [header]="'Have Concierge Set Up For Me'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'TIDY can help solve almost any workflow revolving around cleaning and maintenance. We recommend having our team of experts set them up for you, free!'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card class="default-card">
      <tidy-card-button
        class="arrow-to-top"
        style="padding-bottom: 0px"
        (action)="goToBuildFromSketch()">
        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/icon/sketch.png'" class="header-size">
          </tidy-image>
          <tidy-text [header]="'Build from Scratch'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text [body]="'Pick a trigger and set of actions.'"> </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

    <tidy-card class="workflows-alert-row default-card">
      <tidy-row class="vertical-align-center">
        <tidy-image [src]="'assets/icon/popular.png'" class="header-size">
        </tidy-image>
        <tidy-text [header]="'Start from a Popular Workflow'"> </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text [body]="'See what others do.'"> </tidy-text>
      </tidy-row>

      <div class="form-div">
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding" style="padding-bottom: 0px">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </tidy-row>
        </form>
      </div>

      <ion-row class="workflows-row">
        <ng-container *ngFor="let item of recommendedWorkflows; let i = index">
          <div class="workflow-card" (click)="goToEditByRecommendedWorkflow(i)">
            <div
              class="workflow-img-container">
              <div class="internal-card">
                <tidy-image
                  [src]="getCategoryIcon(item.trigger.category)"
                  class="header-size">
                </tidy-image>
              </div>
            </div>
            <div class="description-container">
              <tidy-row>
                <tidy-text [body]="item.description"> </tidy-text>
              </tidy-row>
            </div>
          </div>
        </ng-container>
      </ion-row>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

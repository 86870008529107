import { Component, Renderer2, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as confetti from 'canvas-confetti';
import { DateTime as LuxonDateTime } from 'luxon';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ModalController } from '@ionic/angular';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { debounceTime } from 'rxjs/operators';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Schedule } from 'src/providers/schedule/schedule';
import { WindowService } from 'src/shared/providers/window.service';
import { snakeCaseToTitleCase } from 'src/shared/util/snake-to-tile-case';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Util } from 'src/shared/util/util';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { JobPage } from 'src/pages/schedule/job/job';
import { CreateActionPlanPage } from '../create-action-plan/create-action-plan';
import { Concierge } from 'src/providers/concierge/concierge';
import { ConciergeReplyPage } from 'src/pages/concierge/concierge-message/concierge-reply/concierge-reply';
import { Genome } from 'src/providers/genome/genome';
import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { UserPage } from 'src/pages/users/user/user';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'app-task',
  templateUrl: 'task.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['task.scss']
})

export class TaskPage implements OnInit {

  addressResponse: any;
  loaded: boolean;
  dialogParams: any;
  errorMessage: string;
  urgencyOptions =
    [{viewValue: `Low`, value: 'low', icon: 'assets/img/low_priority.svg'},
    {viewValue: `Normal`, value: 'normal', icon: 'assets/img/normal_priority.svg'},
    {viewValue: `High`, value: 'high', icon: 'assets/img/high_priority.svg'},
    {viewValue: `Emergency`, value: 'emergency', icon: 'assets/img/emergency_priority.svg'}];
  statusOptions = [{viewValue: 'Reported', value: 'reported'}, {viewValue: 'Approved', value: 'approved'} , {viewValue: 'In Progress', value: 'in_progress'}, {viewValue: 'Done', value: 'done'}];
  isUpdatingIssue: boolean;
  issueTypeOptions: any;
  propertyOptions: any;
  issueReportTypes: any;
  isSavingChanges: boolean;
  rootRef: Promise<ElementRef>;
  issueForm: UntypedFormGroup;
  issue: any;
  reportPhotos = [];
  submitted = false;
  hasAssignedPeople: boolean;
  activities: any[];
  messageForm: UntypedFormGroup;
  messageSubmitted = false;
  allItems: any[];
  jobsLoaded = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private client: Client,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    public reportIssue: ReportIssue,
    private renderer2: Renderer2,
    private storage: TidyStorage,
    private schedule: Schedule,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    private concierge: Concierge,
    private genome: Genome
  ) {
    this.issueForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      urgency: ['normal', Validators.required],
      address_id: ['', Validators.required],
      status: ['reported', Validators.required],
      issue_report_type_id: ['', Validators.required],
      due_date: ['']
    });
    this.messageForm = this.fb.group({
      message: ['']
    });
  }

  async ngOnInit() {
    this.loadData();
  }

  async loadData() {
    try {
      this.loaded = false;
      const dialogParams = await this.storage.retrieve('dialog-params');
      if (dialogParams?.issue) {
        this.getActivities(dialogParams.issue.id);
      }
      this.issueReportTypes = dialogParams.issueReportTypes;
      this.addressResponse = dialogParams.addressResponse;
      this.issue = dialogParams.issue;
      this.rightSidePanelService.setDialogPageTitle('Task');
      this.issueTypeOptions = this.issueReportTypes.map((issueType) => ({
        viewValue: snakeCaseToTitleCase(issueType.name),
        value: issueType.id,
      }));
      this.patchIssueTypeForm();
      this.propertyOptions = this.client.parseAddressList(
        this.addressResponse,
        false
      );
      if (this.issue) {
        this.isUpdatingIssue = true;
        this.issue['checkedItems'] = this.issue.room_objects;
        this.patchIssueForm();
      } else {
        this.issueForm.patchValue({
          address_id: this.propertyOptions[0].value,
        });
        this.issue = {};
        this.issue['allUsers'] = await this.storage.retrieve('allUsers');
        this.issue['allUsers'].unshift({
          name: 'Concierge',
          id: 0,
          checked: false,
        });
        this.issue['checkedUsers'] = [];
        this.issue['checkedJobs'] = [];
        this.issue['checkedItems'] = [];
      }
      const updatedParams = {
        ...dialogParams,
        addressId: this.issueForm.value.address_id,
      };
      this.storage.save('dialog-params', updatedParams);
      this.loaded = true;
      await this.getDataForAddress();
      this.checkItems();
      this.subscribeToIssueChanges();
    } catch (error) {
      console.error(error);
      this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    }
  }

  checkItems() {
    if (!this.issue['checkedItems']) {
      this.issue['checkedItems'] = [];
    }
    this.allItems.map((item) => {
      const checkedItem = this.issue['checkedItems'].find((checkedItem) => checkedItem.id == item.id);
      item['checked'] = !!checkedItem;
      item['attachmentId'] = checkedItem?.attachment_id;
    });
  }

  async getActivities(issueId: number) {
    const issueReport = await this.reportIssue.getIssueReportsWithActivities(issueId);
    this.activities = this.parseActivities(issueReport?.activities);
  }

  openPhoto(photoUrl: string) {
    this.util.openUrl(photoUrl);
  }

  parseActivities(activities: any[]) {
    return activities.map((activity) => {
      const userName = activity?.by_user?.first_name + ' ' + activity?.by_user?.last_name;
      const description = this.getActivityDescription(activity, userName);
      const createdAt = LuxonDateTime.fromISO(activity.created_at);
      const formattedDate = createdAt.toFormat('M/d/yy h:mma').toLowerCase();
      const textDate = this.getRelativeTime(createdAt);
      const pastMoreThanOneDay = textDate.includes('day');
      const photo = this.getPhotoFromActivity(activity);
      return {
        ...activity,
        userName,
        description,
        createdAt: formattedDate,
        textDate,
        pastMoreThanOneDay,
        photo
      };
    });
  }

  getPhotoFromActivity(activity: any) {
    if (activity?.activity_type === 'create_file') {
      const photo = this.issue.issue_report_photos.find((photo) => photo.id == activity?.data?.file_id);
      return photo?.photo_url;
    }
    return null;
  }

  getRelativeTime(createdAt: LuxonDateTime): string {
    const now = LuxonDateTime.now();
    const diff = now.diff(createdAt, ['days', 'hours', 'minutes']).toObject();
    if (diff.days >= 1) {
      return `${Math.floor(diff.days)} ${new TranslationPipe().transform('day' + (diff.days > 1 ? 's' : '') + ' ago')}`;
    } else if (diff.hours >= 1) {
      return `${Math.floor(diff.hours)} ${new TranslationPipe().transform('hour' + (diff.hours > 1 ? 's' : '') + ' ago')}`;
    } else if (diff.minutes >= 1) {
      return `${Math.floor(diff.minutes)} ${new TranslationPipe().transform('minute' + (diff.minutes > 1 ? 's' : '') + ' ago')}`;
    } else {
      return new TranslationPipe().transform('just now');
    }
  }
  getActivityDescription(activity: any, userName: string): string {
    try {
      let description = activity?.description;
      if (activity?.data?.old_params) {
        const changes = Object.keys(activity.data.old_params).map(key => {
          let formattedKey = this.formatKey(key);
          let oldKey = activity.data.old_params[key];
          let newKey = activity.data.new_params[key];
          if (key === 'address_id') {
            formattedKey = 'Address';
            const oldAddress = this.addressResponse.find((address) => address.id == oldKey);
            const newAddress = this.addressResponse.find((address) => address.id == newKey);
            oldKey = this.getPropertyName(oldAddress);
            newKey = this.getPropertyName(newAddress);
          }
          if (key === 'issue_report_type_id') {
            formattedKey = 'Type';
            oldKey = this.issueTypeOptions.find((type) => type.value == oldKey)?.viewValue;
            newKey = this.issueTypeOptions.find((type) => type.value == newKey)?.viewValue;
          }
          if (key === 'archived_at') {
            const formattedDate = LuxonDateTime.fromISO(newKey).toFormat('M/d/yy h:mma').toLowerCase();
            if (formattedDate === 'invalid datetime' || newKey === null) {
              const parsedCreatedAt = LuxonDateTime.fromISO(activity.created_at).toFormat('M/d/yy h:mma').toLowerCase();
              return `<b>${userName}</b> ${new TranslationPipe().transform('unarchived the task at')} <b>${parsedCreatedAt}</b>`;
            }
            return `<b>${userName}</b> ${new TranslationPipe().transform('archived the task at')} <b>${formattedDate}</b>`;
          }
          if (oldKey === '' || oldKey === null) {
            return `<b>${userName}</b> ${new TranslationPipe().transform('updated field')} <b>${formattedKey}</b> ${new TranslationPipe().transform('to')} <b>${newKey}</b>`;
          }
          if (newKey === '' || newKey === null) {
            return `<b>${userName}</b> ${new TranslationPipe().transform('cleared field')} <b>${formattedKey}</b>`;
          }
          return `<b>${userName}</b> ${new TranslationPipe().transform('updated field')} <b>${formattedKey}</b> ${new TranslationPipe().transform('from')} <b>${oldKey}</b> ${new TranslationPipe().transform('to')} <b>${newKey}</b>`;
        }).join(', ');
        description = changes || description;
      }
      if (activity?.activity_type === 'create_file') {
        description = `<b>${userName}</b> ${new TranslationPipe().transform('uploaded a file')}`;
      }
      if (activity?.activity_type === 'create_attachment') {
        description = `<b>${userName}</b> ${new TranslationPipe().transform('added an attachment')}`;
      }
      if (activity?.activity_type === 'delete_file') {
        description = `<b>${userName}</b> ${new TranslationPipe().transform('deleted a file')}`;
      }
      if (activity?.activity_type === 'delete_attachment') {
        description = `<b>${userName}</b> ${new TranslationPipe().transform('deleted an attachment')}`;
      }
      if (activity?.activity_type === 'comment') {
        description = `${activity?.data?.content}`;
      }
      return description;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  getPropertyName(address) {
    if (address.address_name) {
      return address.address_name;
    }
    if (address.address2 && address.address2 !== '') {
      return address.address1 + ', ' + address.address2;
    }
    return address.address1;
  }

  formatKey(key: string): string {
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  async sendMessage(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      this.messageSubmitted = true;
      const payload = {
        issue_report_id: this.issue.id,
        activity_type: 'comment',
        comment: this.messageForm.value.message
      }
      await this.reportIssue.createIssueReportActivityComment(payload);
      this.getActivities(this.issue.id);
      this.messageForm.reset();
    } catch (error) {
      console.error(error);
      this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    } finally {
      loading.dismiss();
    }
  }

  async deleteActivity(activity: any): Promise<void> {
    const alertOptions = {
      header: 'Delete Comment',
      message: 'Are you sure you want to delete this comment?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Delete',
          role: 'confirm',
        },
      ],
    };
    const alert = await this.util.showConfirmAlert(alertOptions);
    alert.onDidDismiss().then(async (res) => {
      if (res.role !== 'confirm') {
        return;
      }
      const loading = await this.util.showLoading();
      try {
        await this.reportIssue.deleteIssueReportActivity(activity.id);
        this.getActivities(this.issue.id);
      } catch (error) {
        console.error(error);
        this.errorMessage =
          error.error && error.error.message
            ? error.error.message
            : error.message;
      } finally {
        loading.dismiss();
      }
    });
  }

  patchIssueTypeForm() {
    const types = {
      'https://api-sandbox3.tidy.com/': 34,
      'https://api-staging.tidy.com/': 3,
      'https://api-production.gotidy.com/': 35
    }
    this.issueForm.patchValue({
      issue_report_type_id: types[environment.api_secure]
    });
  }

  async getDataForAddress() {
    this.jobsLoaded = false;
    const itemsPromise = this.genome.getRoomsObjects(this.issueForm.value.address_id);
    const cardsPromise = this.schedule.getCards(this.issueForm.value.address_id);
    this.allItems = await itemsPromise;
    const cards = await cardsPromise;
    const filteredCards = cards.filter((card) => card?.template_data?.job);
    const timezone = this.getTimezoneForAddress(this.issueForm.value.address_id);
    this.issue['allJobs'] = [];
    filteredCards.forEach((card) => {
      const addedJob = this.issue?.bookings?.find((booking) => booking.id == card?.template_data?.booking?.id);
      const dateTime = LuxonDateTime.fromISO(card?.template_data?.job?.start_datetime_local);
      const formattedTime = dateTime.setZone(timezone).toFormat('M/d h:mma').toLowerCase();
      this.issue['allJobs'].push({
        id: card?.template_data?.booking?.id,
        job_id: card?.template_data?.job?.id,
        is_private: card?.template_data?.job?.is_private,
        service_name: card?.template_data?.job?.service_type_details?.name,
        name: formattedTime,
        status: null,
        start_datetime_local: card?.template_data?.job?.start_datetime_local,
        checked: !!this.issue?.bookings?.find((booking) => booking.id == card?.template_data?.booking?.id),
        attachmentId: addedJob ? addedJob.attachment_id : null,
        icon_url: card?.template_data?.job?.service_type_details?.service_category?.icon_url
      });
    });
    if (this.isUpdatingIssue) {
      this.issue.bookings.map((booking) => {
        const job = this.issue['allJobs'].find((job) => job.id == booking?.id);
        const dateTime = LuxonDateTime.fromISO(booking.job_data.start_datetime_local);
        const formattedTime = dateTime.setZone(timezone).toFormat('M/d h:mma').toLowerCase();
        const jobIsScheduled = !!job;
        if (!jobIsScheduled) {
          this.issue['allJobs'].unshift(({
            isPastJob: true,
            id: booking?.id,
            job_id: booking?.job_data?.id,
            is_private: booking?.job_data?.is_private,
            service_name: booking.job_data.service_name,
            name: formattedTime,
            checked: true,
            icon_url: booking.job_data.service_icon_url,
            status: this.reportIssue.getJobStatusData(booking.job_data.homekeeper_jobs, booking.job_data.job_progress),
            attachmentId: booking.attachment_id,
            start_datetime_local: booking.start_datetime_local
          }));
        }
      });
    }
    this.jobsLoaded = true;
  }

  getTimezoneForAddress(addressId) {
    const address = this.addressResponse.find((address) => address.id == addressId);
    return address?.timezone;
  }

  patchIssueForm() {
    this.issueForm.patchValue({
      title: this.issue.title,
      description: this.issue.description,
      urgency: this.issue.urgency,
      address_id: this.issue.address_id,
      status: this.issue.status,
      issue_report_type_id: this.issue.issue_report_type.id,
      due_date: LuxonDateTime.fromISO(this.issue.due_date).toISO()
    });
    this.reportPhotos = this.issue.issue_report_photos;
    this.cdr.detectChanges();
    this.issueForm.controls.urgency.updateValueAndValidity();
  }

  subscribeToIssueChanges() {
    for (const field in this.issueForm.controls) {
      const control = this.issueForm.get(field);
      control.valueChanges.pipe(
        debounceTime(1000)
      ).subscribe(value => {
        this.updateIssue(field, value);
      });
    }
  }

  getIssueHeaderIcon() {
    const icons = {
      'low': 'assets/img/low_priority.svg',
      'normal': 'assets/img/normal_priority.svg',
      'high': 'assets/img/high_priority.svg',
      'emergency': 'assets/img/emergency_priority.svg'
    };
    return icons[this.issue.urgency];
  }

  async updateIssue(field, value) {
    if (this.issue[field] === value) {
      return;
    }
    const isIssueReportTypeIdField = field == 'issue_report_type_id';
    if (isIssueReportTypeIdField) {
      if (this.issue?.issue_report_type?.id === value) {
        return;
      }
      field = 'issue_report_type_id';
    }
    if (field == 'address_id') {
      if (this.isUpdatingIssue) {
        await this.removeAnyAssignedJobs();
        await this.removeAnyCheckedItems();
      }
      this.issue.bookings = [];
      this.issue.checkedJobs = [];
      await this.getDataForAddress();
    }
    if (!this.isUpdatingIssue) {
      return;
    }
    this.isSavingChanges = true;
    this.submitted = true;
    try {
      const params = {[field]: value};
      await this.reportIssue.updateIssueReport(this.issue.id, params);
      if (field === 'status' && value === 'done') {
        this.throwConfetti();
      }
      this.issue[field] = value;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.getActivities(this.issue.id);
    this.storage.delete('tasksLastSavedAt');
    return setTimeout(() => {
      this.isSavingChanges = false;
    }, 1000);
  }

  async removeAnyAssignedJobs() {
    await Promise.all(this.issue.allJobs.map(async (job) => {
      if (job['attachmentId']) {
        await this.reportIssue.deleteIssueReportAttachment(job['attachmentId']);
        job['attachmentId'] = null;
        job['checked'] = false;
      }
    }));
    this.issue['checkedJobs'] = [];
  }

  async removeAnyCheckedItems() {
    await Promise.all(this.allItems.map(async (item) => {
      if (item['attachmentId']) {
        await this.reportIssue.deleteIssueReportAttachment(item['attachmentId']);
        item['attachmentId'] = null;
        item['checked'] = false;
      }
    }));
    this.issue['checkedItems'] = [];
  }

  async throwConfetti() {
    const canvas = this.renderer2.createElement('canvas');
    canvas.addEventListener('click', () => canvas.style.display = 'none');
    this.renderer2.appendChild((await this.rootRef).nativeElement, canvas);
    const confettiAnimation = confetti.create(canvas, { resize: true });
    await confettiAnimation({
      particleCount: 25,
      origin: { y: 0.7 },
    })
    this.renderer2.removeChild((await this.rootRef).nativeElement, canvas);
  }

  async archiveTask() {
    const params: ConfirmPageParamsModel = {
      title: 'Archive Task?',
      body: 'You can view archived tasks and unarchive them in the archived tasks page.',
      backText: 'Go Back',
      confirmText: 'Archive ',
      confirmAction: this.confirmArchiveTask.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmArchiveTask() {
    try {
      const payload = {
        archived: true
      }
      await this.reportIssue.updateIssueReport(this.issue.id, payload);
      this.storage.delete('tasksLastSavedAt');
      this.modalCtrl.dismiss();
      this.closePage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async unarchiveTask() {
    const params: ConfirmPageParamsModel = {
      title: 'Unarchive Task?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Unarchive',
      confirmAction: this.confirmUnarchiveTask.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmUnarchiveTask() {
    try {
      const payload = {
        archived: false
      }
      await this.reportIssue.updateIssueReport(this.issue.id, payload);
      this.storage.delete('tasksLastSavedAt');
      this.modalCtrl.dismiss();
      this.closePage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async deleteTask() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Task?',
      body: 'You cannot undo this action',
      backText: 'Go Back',
      confirmText: 'Delete',
      confirmAction: this.confirmDeleteTask.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmDeleteTask() {
    try {
      await this.reportIssue.deleteReportedIssue(this.issue.id);
      this.storage.delete('tasksLastSavedAt');
      this.modalCtrl.dismiss();
      this.closePage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async addAttachment() {
    const attachment = await this.reportIssue.addAttachment();
    let newAttachmentIndex = this.reportPhotos.length;
    if (attachment.filename) {
      let photoObject = {
        description: attachment.filename,
        photo_url: attachment.fileKey
      }
      if (!this.isUpdatingIssue) {
        photoObject['full_photo_url'] = this.getFullPhotoURLForEnv(attachment.fileKey)
      }
      this.reportPhotos.push(photoObject);
    }
    if (this.isUpdatingIssue) {
      this.reportIssue.saveAttachment(this.issue.id, attachment).then((newAttachment) => {
        this.reportPhotos[newAttachmentIndex] = newAttachment;
      }).catch((error) => {
        this.reportPhotos.splice(newAttachmentIndex, 1);
        this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
      });
    }
  }

  getFullPhotoURLForEnv(fileKey) {
    let prefix = '';
    if (environment.api_secure == 'https://api-sandbox3.tidy.com/') {
      prefix = 'https://samantha-temp-file-uploads.s3.us-west-2.amazonaws.com/sandbox3/';
    }
    if (environment.api_secure == 'https://api-staging.tidy.com/') {
      prefix = 'https://samantha-temp-file-uploads.s3.us-west-2.amazonaws.com/staging/';
    }
    if (environment.api_secure == 'https://api-production.gotidy.com/') {
      prefix = 'https://samantha-temp-file-uploads.s3.us-west-2.amazonaws.com/production/';
    }
    return prefix + fileKey;
  }

  async removeAttachment(index, attachment) {
    try {
      if (this.isUpdatingIssue) {
        await this.reportIssue.deleteAttachment(attachment.id);
      }
      this.reportPhotos.splice(index, 1);
    } catch (error) {
      this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    }
  }

  async saveIssue() {
    this.submitted = true;
    if (this.issueForm.invalid) return;
    let data = this.issueForm.value;
    try {
      data['issue_report_photos'] = this.reportPhotos;
      data['assigned_to_concierge'] = this.checkIfConciergeIsAssigned();
      const issueResponse = await this.reportIssue.reportIssue(data);
      if (!this.isUpdatingIssue) {
        await this.saveCheckedUsers(issueResponse);
        await this.saveCheckedJobs(issueResponse);
        await this.saveCheckedItems(issueResponse);
      }
      this.storage.delete('tasksLastSavedAt');
      if (data.assigned_to_concierge) {
        this.issue = issueResponse;
        const isAddingIssue = true;
        this.goToCreateActionPlan(isAddingIssue);
      } else {
        this.closePage();
      }
    } catch (error) {
      this.errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    }
  }

  checkIfConciergeIsAssigned() {
    let conciergeIsAssigned = false;
    this.issue.checkedUsers.map(user => {
      const userIsConcierge = user.id == 0;
      if (userIsConcierge) {
        conciergeIsAssigned = true;
      }
    });
    return conciergeIsAssigned;
  }

  async saveCheckedUsers(issueResponse) {
    await Promise.all(this.issue.checkedUsers.map(async user => {
      const userIsNotConcierge = user.id !== 0;
      if (userIsNotConcierge) {
        const payload = {
          issue_report_id: issueResponse.id,
          attachable_id: user.id,
          attachable_type: 'CustomerMember'
        };
        await this.reportIssue.addIssueReportAttachment(payload);
      }
    }));
  }

  async saveCheckedJobs(issueResponse) {
    await Promise.all(this.issue.checkedJobs.map(async job => {
      const payload = {
        issue_report_id: issueResponse.id,
        attachable_id: job.id,
        attachable_type: 'Booking'
      };
      await this.reportIssue.addIssueReportAttachment(payload);
    }));
  }

  async saveCheckedItems(issueResponse) {
    await Promise.all(this.issue.checkedItems.map(async item => {
      const payload = {
        issue_report_id: issueResponse.id,
        attachable_id: item.id,
        attachable_type: 'RoomObject'
      };
      await this.reportIssue.addIssueReportAttachment(payload);
    }));
  }

  openAttachment(url) {
    this.util.openUrl(url);
  }

  async checkOrUncheckJob(job, event) {
    let attachmentResponse = null;
    if (this.isUpdatingIssue) {
      if (event.checked) {
        const payload = {
          issue_report_id: this.issue.id,
          attachable_id: job.id,
          attachable_type: 'Booking'
        }
        attachmentResponse = await this.reportIssue.addIssueReportAttachment(payload);
        job['attachmentId'] = attachmentResponse.attachment_id;
      } else {
        await this.reportIssue.deleteIssueReportAttachment(job['attachmentId']);
      }
    }
    const jobIndex = this.issue['checkedJobs']?.findIndex((checkedJob) => checkedJob.id == job.id);
    if (jobIndex !== -1) {
      this.issue['checkedJobs']?.splice(jobIndex, 1);
    } else {
      let checkedJob = {
        ...job
      }
      if (attachmentResponse) {
        checkedJob['status'] = this.reportIssue.getJobStatusData(attachmentResponse.job_data.homekeeper_jobs, attachmentResponse.job_data.job_progress);
      }
      this.issue['checkedJobs'].push(checkedJob);
    }
    this.storage.delete('tasksLastSavedAt');
  }

  async checkOrUncheckPerson(task, user, event) {
    let attachmentId = null;
    if (this.isUpdatingIssue) {
      if (user.name == 'Concierge') {
        if (event.checked) {
          const isAddingIssue = false;
          this.goToCreateActionPlan(isAddingIssue);
        } else {
          this.reportIssue.updateIssueReport(task.id, {assigned_to_concierge: event.checked});
        }
      } else {
        if (event.checked) {
          const payload = {
            issue_report_id: task.id,
            attachable_id: user.id,
            attachable_type: 'CustomerMember'
          }
          const attachmentResponse = await this.reportIssue.addIssueReportAttachment(payload);
          attachmentId = attachmentResponse.attachment_id;
        } else {
          await this.reportIssue.deleteIssueReportAttachment(user.attachmentId);
        }
      }
    }
    const userIndex = task['checkedUsers']?.findIndex((checkedUser) => checkedUser.id == user.id);
    if (userIndex !== -1) {
      task['checkedUsers']?.splice(userIndex, 1);
    } else {
      let checkedUser = {
        id: user.id,
        name: user.name,
        checked: event.checked
      }
      if (attachmentId) {
        this.issue.allUsers.find((allUser) => allUser.id == user.id)['attachmentId'] = attachmentId;
      }
      task['checkedUsers'].push(checkedUser);
    }
    this.storage.delete('tasksLastSavedAt');
  }

  async checkOrUncheckItem(item, event) {
    let attachmentId = null;
    if (this.isUpdatingIssue) {
      if (event.checked) {
        const payload = {
          issue_report_id: this.issue.id,
          attachable_id: item.id,
          attachable_type: 'RoomObject'
        }
        const attachmentResponse = await this.reportIssue.addIssueReportAttachment(payload);
        attachmentId = attachmentResponse.attachment_id;
      } else {
        await this.reportIssue.deleteIssueReportAttachment(item.attachmentId);
      }
    }
    const itemIndex = this.issue['checkedItems']?.findIndex((checkedItem) => checkedItem.id == item.id);
    if (itemIndex !== -1) {
      this.issue['checkedItems']?.splice(itemIndex, 1);
    } else {
      let checkedItem = {
        id: item.id,
        name: item.name,
        checked: event.checked
      }
      if (attachmentId) {
        this.allItems.find((allItem) => allItem.id == item.id)['attachmentId'] = attachmentId;
      }
      this.issue['checkedItems'].push(checkedItem);
    }
    this.storage.delete('tasksLastSavedAt');
  }

  goToItem(item) {
    if (!this.windowService.isDesktopRes) {
      return;
    }
    const params = {
      itemId: item.id,
      cameFromEditItem: false
    };
    this.rightSidePanelService.navigateTo(`item/${item.id}`, params, ItemDetailsPage);
  }

  closePage() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.back();
    }
  }

  async goToJob(job) {
    if (!this.windowService.isDesktopRes) {
      return;
    }
    const pastJobStatuses = ['completed', 'cancelled'];
    const isPastJob = pastJobStatuses.includes(job?.job_data?.job_progress) || job?.isPastJob;
    const dialogParams = await this.storage.retrieve('dialog-params');
    if (dialogParams && this.windowService.isDesktopRes) {
      const updatedParams = {
        ...dialogParams,
        job,
        jobId: job?.job_id,
        isPastJob,
        isPrivateJob: job?.is_private,
        addressId: this.issueForm.value.address_id
      };
      await this.storage.save('dialog-params', updatedParams);
      this.navigateToJobPageInsideDialog(isPastJob, job.is_private);
      return;
    }
    if (isPastJob) {
      if (job.is_private) {
        this.navCtrl.navigateForward(`past-private-job/${job.job_id}`);
      } else {
        this.navCtrl.navigateForward(`past-job/${job.job_id}`);
      }
    } else {
      const params = {
        jobId: job?.job_id,
        addressId: this.issueForm.value.address_id
      };
      this.rightSidePanelService.navigateTo(`job/${this.issueForm.value.address_id}/${job.job_id}`, params);
    }
  }

  navigateToJobPageInsideDialog(
    isPastJob: boolean,
    isPrivateJob: boolean
  ): void {
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastPrivateJobPage);
    } else if (isPastJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastJobPage);
    } else {
      this.rightSidePanelService.replaceComponentInDialog(JobPage);
    }
  }

  async goToUser(user) {
    if (!this.windowService.isDesktopRes) {
      return;
    }
    await this.storage.save('userId', user.id);
    this.rightSidePanelService.navigateTo(`user/${user.id}`, {}, UserPage);
  }

  async goToCreateActionPlan(isAddingIssue) {
    try {
      const issueAddress = this.addressResponse.find((address) => address.id == this.issue.address_id);
      const params = {
        issue: this.issue,
        issueAddress,
        isAddingIssue: isAddingIssue
       };
      if (this.windowService.isDesktopRes) {
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(CreateActionPlanPage);
      } else {
        this.navCtrl.navigateForward('create-action-plan', params)
      }
    } catch (err) {
    }
  }

  async goToConciergeMessage(conciergeItem) {
    try {
      await this.concierge.setClientInfo();
      const params = {
        issue: this.issue,
        itemId: conciergeItem.id
      }
      if (this.windowService.isDesktopRes) {
        this.rightSidePanelService.navigateTo('concierge-reply', params, ConciergeReplyPage);
      } else {
        this.navCtrl.navigateForward('concierge-reply', params);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Pros } from 'src/providers/pros/pros';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';

import { Util } from 'src/shared/util/util';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { AddAddressPage } from 'src/pages/add-address/add-address';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { ConfirmPage } from 'src/pages/confirm/confirm.component';
import { VendorCompliancePage } from 'src/pages/vendor-compliance/vendor-compliance';
import { TidyFindNewProPage } from 'src/pages/my-pros/tidy-find-new-pro/tidy-find-new-pro';
import { AddProPage } from 'src/pages/my-pros/add-pro/add-pro';
import { SuccessPage } from 'src/shared/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'job-request-workflows.html',
  styleUrls: ['./job-request-workflows.scss'],
  encapsulation: ViewEncapsulation.None
})

export class JobRequestWorkflows implements OnInit {

  allowsFindNewPros: boolean;
  addressId: number;
  addressFilter: TidySelectNumberValueModel[];
  addressResponse: any;
  backPage: string;
  categoryId: number;
  clientSettings: any;
  errorMessage: string;
  isRentalClient: boolean;
  isRightSideContent: boolean;
  loaded: boolean;
  timeCutoffItems: any;
  jobId: number;
  workflow: any;
  customerId: number;
  prosServices: any;
  percentCutoffItems: any;
  form: UntypedFormGroup;
  hasPrivatePro: boolean;
  hasPros: boolean;
  prosData: any;
  editPrio = false;
  panelClosedSubscription: Subscription;
  priorityListSentence: string;
  serviceCategoryItems: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private pros: Pros,
    private util: Util,
    private currentAddress: CurrentAddress,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
  ) {
    this.form = this.fb.group({
      pros: [1, Validators.required],
      timeCutoff: [''],
      percentCutoff: [''],
      property: [''],
      service: ['']
    });
  }

  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      await this.getPageData();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getTimeCutoffItems() {
    const items = [];
    for (let i = 1; i <= 72; i += 1) {
      items.push({
        value: i,
        viewValue: `${i} ${i === 1 ? (new TranslationPipe()).transform('hour') : (new TranslationPipe()).transform('hours')}`
      });
    }
    return items;
  }

  getPercentCutoffItems() {
    const items = [];
    for (let i = 1; i <= 100; i += 1) {
      items.push({
        value: i,
        viewValue: `${i}%`
      });
    }
    return items;
  }

  async getPageData() {
    this.rightSidePanelService.setDialogPageTitle('Job Request Workflows');
    this.errorMessage = '';
    this.backPage = localStorage.getItem('myProsBackPage') || 'more';
    this.prosServices = [{
      viewValue: (new TranslationPipe()).transform('Cleaning'),
      value: 1
    }];
    await this.getAddresses();
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const categories = await this.pros.getServiceCategoriesOrFetch();
    this.serviceCategoryItems = this.getServiceCategoryItems(categories);
    this.timeCutoffItems = this.getTimeCutoffItems();
    this.percentCutoffItems = this.getPercentCutoffItems();
    this.clientSettings = await this.client.getClientSettings();
    this.form.patchValue({
      timeCutoff: this.clientSettings.reschedule.max_time_cutoff,
      percentCutoff: this.clientSettings.reschedule.max_percent_cutoff,
      property: this.addressId,
      service: this.categoryId || this.serviceCategoryItems[0].value
    })
    await this.getProsData();
  }

  async getProsData() {
    this.categoryId = parseInt(localStorage.getItem('selectedCategoryId')) || 1;
    const prosData = await this.getPros(this.categoryId);
    this.prosData = this.pros.parseProsIntoGroups(prosData);
    this.hasPrivatePro = this.checkIfHasPrivateProsInAddress();
    this.hasPros = this.checkIfHasProsInAddress();
    this.allowsFindNewPros = this.checkIfAllowsFindNewPros();
    this.priorityListSentence = this.getPriorityListSentence();
  }

  checkIfAllowsFindNewPros() {
    const isBlocked = this.prosData.blocked.find((item) => item.object_type == 'dynamic_sa')
    return !isBlocked;
  }

  async getPros(id) {
    return await this.pros.getPros(id);
  }

  getServiceCategoryItems(serviceCategories) {
    const array: any = [];
    serviceCategories.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.id
      });
    });
    return array;
  }

  async changeServiceCategory(id) {
    this.categoryId = id;
    localStorage.setItem('selectedCategoryId', id);
    const prosData = await this.getPros(this.categoryId);
    this.prosData = this.pros.parseProsIntoGroups(prosData);
    this.priorityListSentence = this.getPriorityListSentence();
  }

  async getAddresses() {
    this.addressResponse = await this.client.getAddresses();
    this.addressFilter = await this.client.parseAddressList(this.addressResponse, false);
    this.addressId = await this.client.getSelectedAddressId(this.addressResponse);
  }

  async getDisplayData() {
    const prosData = await this.getPros(this.categoryId);
    this.prosData = this.pros.parseProsIntoGroups(prosData);
    this.priorityListSentence = this.getPriorityListSentence();
    this.allowsFindNewPros = this.checkIfAllowsFindNewPros();
  }

  @Loading('', true)
  async changeAddress(addressId) {
    if (addressId === 0) {
      return this.goToPage('add-property', {}, AddAddressPage);
    }
    this.currentAddress.addressId = addressId;
    await this.getDisplayData();
  }

  checkIfHasProsInAddress(): boolean {
    const allObjects: any = [];
    this.prosData.favorited.map((object) => allObjects.push(object));
    this.prosData.approved.map((object) => allObjects.push(object));
    this.prosData.blocked.map((object) => allObjects.push(object));
    const isTidyFindNewProsAppearing = allObjects.find((object) => object.object_type == 'dynamic_da');
    let numberOfPros = this.prosData.favorited.length + this.prosData.approved.length + this.prosData.blocked.length;
    if (isTidyFindNewProsAppearing) {
      numberOfPros -= 1;
    }
    return numberOfPros > 0 || this.categoryId !== 1;
  }

  checkIfHasPrivateProsInAddress(): boolean {
    const allPros = [...this.prosData.approved, ...this.prosData.favorited].flat();
    return allPros.some(pro => pro?.object?.is_private);
  }

  @Loading('', true)
  async updatePriorities() {
    this.prosData.favorited.map((pro, index) => {
      if (index == 0) {
        if (Array.isArray(pro)) {
          pro.map((item) => {
            item.priority = 1;
          });
        } else {
          pro.priority = 1;
        }
      } else {
        const priorPro = this.prosData.favorited[index - 1];
        const priorProPriority = Array.isArray(priorPro) ? priorPro[0].priority : priorPro.priority;
        if (Array.isArray(pro)) {
          pro.map((item) => {
            item.priority = priorProPriority + 1;
          });
        } else {
          pro.priority = priorProPriority + 1;
        }
      }
    });
    const payload = this.prepareProsPayload();
    await this.pros.updateProsPriorities(payload, this.form.controls.pros.value);
    this.prosData.approved = [];
    this.priorityListSentence = this.getPriorityListSentence();
  }

  prepareProsPayload() {
    let newProsData = JSON.parse(JSON.stringify(this.prosData));
    newProsData.approved = [];
    newProsData.favorited = [];
    this.prosData.favorited.forEach((pro) => {
      if (Array.isArray(pro)) {
        pro.forEach((item) => {
          newProsData.favorited.push({ ...item });
        });
      } else {
        newProsData.favorited.push({ ...pro });
      }
    });
    return newProsData;
  }

  highlightCards(startIndex: number, endIndex: number, list: string) {
    const firstCard = this.prosData[list][startIndex];
    const secondCard = this.prosData[list][endIndex];
    firstCard.highlight = true;
    secondCard.highlight = true;
  }

  unhighlightCards(startIndex: number, endIndex: number, list: string) {
    const firstCard = this.prosData[list][startIndex];
    const secondCard = this.prosData[list][endIndex];
    if (firstCard) firstCard.highlight = false;
    if (secondCard) secondCard.highlight = false;
  }

  mergePros(startIndex: number, endIndex: number, list: string) {
    const firstCard = this.prosData[list][startIndex];
    const nextCard = this.prosData[list][endIndex];
    let mergedCards = [];
    if (Array.isArray(firstCard)) {
      mergedCards = [...firstCard];
    } else {
      mergedCards.push(firstCard);
    }
    if (Array.isArray(nextCard)) {
      mergedCards = [...mergedCards, ...nextCard];
    } else {
      mergedCards.push(nextCard);
    }
    this.prosData[list][startIndex] = mergedCards;
    this.prosData[list].splice(endIndex, 1);
    this.unhighlightCards(startIndex, endIndex, list);
    this.updatePriorities();
  }

  unmergePro(index: number, proIndex: number, list: string) {
    const pros = this.prosData[list][index];
    const pro = pros[proIndex];
    this.prosData[list][index].splice(proIndex, 1);
    this.prosData[list].splice(index + 1, 0, pro);
    const updatedPros = this.prosData[list][index];
    if (updatedPros.length === 1) {
      this.prosData[list][index] = updatedPros[0];
    }
    this.unhighlightCards(index, index + 1, list);
    this.updatePriorities();
  }

  goToVendorCompliance() {
    const params = {
      successRoute: 'job-request-workflows'
    }
    this.goToPage('vendor-compliance', params, VendorCompliancePage);
  }

  goToTidyFindNewPro(allowsFindNewPros) {
    const params = {
      allowsFindNewPros: allowsFindNewPros,
      prosData: this.prosData,
      hasPrivatePro: this.hasPrivatePro
    }
    this.goToPage('tidy-find-new-pro', params, TidyFindNewProPage);
  }

  goToTidyFindNewProFromOnboarding() {
    const params = {
      cameFromMyProsOnboardingPage: true,
      serviceCategoryItems: this.serviceCategoryItems,
      allowsFindNewPros: true
    }
    this.goToPage('tidy-find-new-pro', params, TidyFindNewProPage);
  }

  goToPro(proId) {
    const params = {
      categoryId: this.categoryId,
      proId: proId
    }
    this.goToPage(`my-pro/${proId}`, params, MyProPage);
  }

  addPro() {
    const params = {
      serviceCategoryItems: this.serviceCategoryItems,
      categoryId: this.categoryId
    }
    this.goToPage('add-pro', params, AddProPage);
  }

  learnMoreAddPros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  async requestCategory() {
    try {
      const payload = {
        key: 'find-new-pro/' + this.categoryId
      }
      await this.client.requestUpcomingFeature(payload);
      const params = this.mountSuccessPage();
      this.goToPage('success', params, SuccessPage);
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const params = this.mountAlreadyRequestedSuccessPage();
        this.goToPage('success', params, SuccessPage);
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  mountSuccessPage() {
    return {
      header: 'Feature Requested',
      body: 'TIDY will let you know when this feature is available for you.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes && !this.isRightSideContent ? 'job-request-workflows' : 'automations/summary'
    };
  }

  mountAlreadyRequestedSuccessPage() {
    return {
      header: 'Feature Already Requested',
      body: 'You already requested this feature. TIDY will let you know when this feature is available for you.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes && !this.isRightSideContent ? 'job-request-workflows' : 'automations/summary'
    };
  }

  getPriorityListSentence() {
    const address = this.addressResponse.find(ad => ad.id === this.addressId);
    const addressName = address.address_name ? address.address_name : address.address1;
    const priorityListSentence = this.pros.updatePriorityListSentence(this.prosData);
    return (new TranslationPipe()).transform('For <u>') + addressName + '</u>, ' + priorityListSentence;
  }

  async removeProFromList(pro) {
    const params = {
      title: new TranslationPipe().transform('Remove ') + pro?.object?.first_name + new TranslationPipe().transform(' From List?'),
      body: 'The pro will be removed from the list for this address for this service type. You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Remove from List',
      confirmAction: this.confirmRemoveProFromList.bind(this, pro),
      confirmButtonClass: 'secondary',
      blockAction: this.confirmBlockPro.bind(this, pro),
    }
    const modal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await modal.present();
  }

  async confirmRemoveProFromList(pro) {
    try {
      const payload = {
        service_type_id: this.categoryId,
        homekeeper_ids: [pro?.object?.id]
      }
      await this.pros.removeProFromAddressList(this.addressId, payload);
      await this.getProsData();
      this.modalCtrl.dismiss(true);
      this.util.showSuccess('Pro removed from list');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async confirmBlockPro(removedPro) {
    this.prosData.favorited.forEach((item, index) => {
      if (Array.isArray(item)) {
        item.forEach((pro, proIndex) => {
          if (pro.id == removedPro.id) {
            this.prosData.favorited[index].splice(proIndex, 1);
          }
        });
      } else if (item.id == removedPro.id) {
        this.prosData.favorited.splice(index, 1);
      }
    });
    try {
      this.prosData.blocked.push(removedPro);
      const serviceTypeId = 1;
      const payload = this.prepareProsPayload();
      await this.pros.updateProsPriorities(payload, serviceTypeId);
      await this.getProsData();
      this.modalCtrl.dismiss(true);
      this.util.showSuccess('Pro removed from list');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async updateAutoAssign(addressHomekeeperPriority) {
    addressHomekeeperPriority.ignore_homekeeper_availability = !addressHomekeeperPriority?.ignore_homekeeper_availability;
    const payload = {
      ignore_homekeeper_availability: addressHomekeeperPriority.ignore_homekeeper_availability
    };
    await this.pros.updateAutoAssignSettings(payload, addressHomekeeperPriority?.id);

  }

  updateTimeCutoff(value) {
    const payload = {
      reschedule: {
        max_percent_cutoff: this.clientSettings.reschedule.max_percent_cutoff,
        max_time_cutoff: value
      }
    }
    this.client.saveClientSettings(payload);
  }

  updatePercentCutoff(value) {
    const payload = {
      reschedule: {
        max_percent_cutoff: value,
        max_time_cutoff: this.clientSettings.reschedule.max_time_cutoff
      }
    }
    this.client.saveClientSettings(payload);
  }

  async goToPage(url, params, component) {
    if (this.windowService.isDesktopRes) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      if (this.windowService.isDesktopRes && !this.isRightSideContent) {
        this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
          this.getPageData();
        });
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

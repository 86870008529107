import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { cardOptions } from 'src/old-pages/booking/confirm/confirm-card-options';

declare const Stripe;
@Injectable()
export class StripeProvider {
  stripe: any;
  elements: any;

  constructor() {
    this.initialize();
  }

  initialize() {
    this.stripe = Stripe(environment.stripe_key);
    this.elements = this.stripe.elements();
  }

  getStripe() {
    return this.stripe;
  }

  getInstance() {
    return this.stripe;
  }

  getElements() {
    return this.elements;
  }

  destroyCard() {
    const existingCardEl = this.elements.getElement('card');
    if (existingCardEl && !existingCardEl._destroyed) {
      existingCardEl.destroy();
    }
  }

  getCardElement() {
    this.destroyCard();
    return this.elements.create('card', cardOptions);
  }

  collectBankAccountForSetup(payload: { name: string; client_secret: string }) {
    return this.stripe.collectBankAccountForSetup({
      clientSecret: payload.client_secret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: { name: payload.name },
        },
      },
    });
  }
}

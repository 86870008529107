<span class="tidy-table-wrapper" style="overflow: hidden;">
  <span class="tidy-table-wrapper__content" #tableWrapper style="overflow-x: auto; width: 100%;">
    <table mat-table matSort [fixedLayout]="false" [dataSource]="tabledataSource" class="mat-elevation-z8 mobile-table" (matSortChange)="emitSortChange($event)" style="table-layout: auto; min-width: 100%;">

      <ng-container *ngFor="let column of displayedColumns; let i = index">
        <ng-container [matColumnDef]="column" *ngIf="column === 'Select'">
          <th mat-header-cell *matHeaderCellDef class="select-header" style="min-width: 100px; white-space: nowrap; background-color: white;">
            <mat-checkbox (change)="$event ? toggleAllRows() : null; this.emitSelectionChange()"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td class="tidy-table-wrapper__content-cell" mat-cell *matCellDef="let row" style="min-width: 100px; white-space: nowrap; background-color: white;">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null; this.emitSelectionChange()"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="column" *ngIf="i == 0 && column !== 'Select'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px; white-space: nowrap; background-color: white;">
            <span
              [innerHTML]="(columnslabels[i] ? sanitize(columnslabels[i] | translate) : '')">
            </span>
          </th>
          <td class="tidy-table-wrapper__content-cell" mat-cell
            *matCellDef="let element"
            (click)="handleCellClick(column, element, $event)"
            [ngClass]="{'action': element[column].action, 'minColumnWidth': minColumnWidth, 'desktop-min-width': windowService.isDesktopRes}"
            style="min-width: 100px; white-space: nowrap;">
            <ng-container *ngIf="column !== 'last-column'">
              <span class="cell-text" [title]="element[column].value" style="white-space: nowrap; overflow: visible;">
                <tidy-image
                  *ngIf="element[column].icon && !element[column].showIconOnRight"
                  [src]="element[column].icon">
                </tidy-image>
                <span class="no-wrap-text" [innerHTML]="element[column].value !== null && element[column].value !== undefined ? sanitize(element[column].value) : ''"></span>
                <tidy-image
                  *ngIf="element[column].icon && element[column].showIconOnRight"
                  [src]="element[column].icon">
                </tidy-image>
              </span>
            </ng-container>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="column" *ngIf="i > 0 && column !== 'Select'">
          <ng-container *ngIf="column !== 'last-column'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px; white-space: nowrap;">
              <span
                [innerHTML]="columnslabels[i] ? sanitize(columnslabels[i] | translate) : ''">
              </span>
            </th>
          </ng-container>
          <ng-container *ngIf="column === 'last-column'">
            <th mat-header-cell *matHeaderCellDef style="width: 10%; min-width: 100px;"></th>
          </ng-container>
          <td class="tidy-table-wrapper__content-cell" mat-cell
            *matCellDef="let element"
            (click)="handleCellClick(column, element, $event)"
            [ngClass]="{'action': element[column].action || element[column].editable, 'minColumnWidth': minColumnWidth, 'desktop-min-width': windowService.isDesktopRes}"
            style="min-width: 100px; white-space: nowrap;">
            <ng-container *ngIf="column !== 'last-column'">
              <span *ngIf="!isEditing(element, column)" class="cell-text" [title]="element[column].value" style="white-space: nowrap; overflow: visible;">
                <tidy-image
                  *ngIf="element[column].icon && !element[column].showIconOnRight"
                  [src]="element[column].icon">
                </tidy-image>
                <span class="no-wrap-text" [innerHTML]="element[column].value !== null && element[column].value !== undefined ? sanitize(element[column].value) : ''"></span>
                <tidy-image
                  *ngIf="element[column].icon && element[column].showIconOnRight"
                  [src]="element[column].icon">
                </tidy-image>
              </span>
              <span *ngIf="isEditing(element, column)" class="cell-content">
                <input
                  type="number"
                  [(ngModel)]="editValue"
                  (keydown)="onEditKeydown($event)"
                  (blur)="saveEdit()"
                  #editInput
                  class="editable-cell-input"
                  placeholder="{{element[column].value}}"
                  autofocus>
              </span>
            </ng-container>
            <ng-container *ngIf="column === 'last-column'">
              <span style="cursor: pointer;" *ngIf="showArrowIcon">
                <ion-icon
                  name="chevron-forward-outline"
                  class="clickable-row-icon"
                  style="margin-right: 0px">
                </ion-icon>
              </span>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'clickable': allRowIsClickable, 'unread': row?.isUnread}"></tr>

    </table>

    <tidy-loader *ngIf="isLoading" style="display: flex; justify-content: center;"></tidy-loader>

    <ion-infinite-scroll
      *ngIf="infiniteScroll && scrollWithPage"
      threshold="100px"
      (ionInfinite)="onIonInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </span>

  <!-- Only show paginator if not using infinite scroll -->
  <!--<mat-toolbar class="mat-toolbar-mobile" *ngIf="!infiniteScroll">
    <mat-toolbar-row>
      <button mat-icon-button>
        <mat-icon (click)="exportCsv(tabledataSource)" title="Export as CSV">save_alt</mat-icon>
      </button>
      <mat-paginator
        class="mat-paginator-mobile"
        [ngClass]="{'hidden': !showPaginator}"
        [length]="totalRows || 100"
        [pageSize]="rowsToShow || 10"
        [pageSizeOptions]="[5, 10, 25, 100, 200]"
        aria-label="Select page"
        (page)="selectPage($event)">
      </mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>-->
</span>

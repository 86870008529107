import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFileName'
})
export class UrlFileNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const splittedUrl = value.split('/');
    return splittedUrl[splittedUrl.length - 1];
  }
}

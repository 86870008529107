<ng-container *ngIf="dateRangeForm">
  <mat-form-field  (click)="openOptionsPicker()" *ngIf="!showRangeOptions" [appearance]="appearance" [class.header]="header">
      <mat-label *ngIf="label" class="vertical-align-center">
        <tidy-text [body]="label"></tidy-text>
      </mat-label>

      <mat-date-range-input [rangePicker]="picker" *ngIf="rangeOption === 'custom'" #dateRangeInput>
        <input #startDateInput matStartDate [formControl]="dateRangeForm.controls.start" placeholder="Start date">
        <input matEndDate [formControl]="dateRangeForm.controls.end" placeholder="End date" (dateChange)="emitOptionChange()">
      </mat-date-range-input>

      <mat-select *ngIf="rangeOption !== 'custom'" [formControl]="dateRangeForm.controls.customStringSelect">
        <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{option.viewValue| translate}}</mat-option>
      </mat-select>

      <tidy-image
        *ngIf="rangeOption === 'custom'"
        [src]="'assets/svg/caret-down.svg'"
        class="caret-icon">
      </tidy-image>

      <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field *ngIf="showRangeOptions" [appearance]="appearance" [class.header]="header">
    <mat-label *ngIf="label" class="vertical-align-center">
      <tidy-text [body]="label"></tidy-text>
    </mat-label>
    <mat-select #optionsPicker (selectionChange)="selectOption($event.value)" [formControl]="dateRangeForm.controls.customStringSelect">
      <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{option.viewValue | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <tidy-error-message *ngIf="!dateRangeForm.valid && submitted" [text]="errorMessage">
  </tidy-error-message>
</ng-container>

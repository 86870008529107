import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, pairwise, map } from 'rxjs/operators';
import { RightSideDialogData } from 'src/shared/components/dynamic-dialog-container/dynamic-dialog-container';
import { CustomNavController } from '../navigation/custom-nav-controller';
import { HeaderProvider } from 'src/providers/header/header';

import { routes } from 'src/app/aggregators/pages';

@Injectable()
export class RightSidePanelService {

  historyContext$ = new BehaviorSubject<Array<ComponentType<unknown>>>([]);
  isOpen$ = new BehaviorSubject<boolean>(false);
  isClosingPanel: boolean;
  currentComponent$ = new BehaviorSubject<ComponentType<unknown>>(null);
  currentComponentData: any;
  dropDownMenuAction$ = new BehaviorSubject<string>(null);
  pageTitle$ = new BehaviorSubject<string>(null);
  isLoading$ = new BehaviorSubject<boolean>(false);
  requestMadeInsideRightSidePanel$ = new BehaviorSubject<boolean>(false);

  constructor(
    public headerProvider: HeaderProvider,
    private storage: TidyStorage,
    private navCtrl: CustomNavController
  ) {}

  /**
   * Save the component in the history context
   * @param component The component to save
   * @returns Promise<void>
   */
  async saveDialogHistoryContext(
    component: ComponentType<unknown>,
    data: any = null
  ): Promise<void> {
    this.historyContext$.next([...this.historyContext$.getValue(), component]);
    const currentHistoryContext = this.historyContext$.getValue();
    console.warn('[RightSidePanelService] Current History Context', currentHistoryContext);
    this.currentComponent$.next(component);
    this.currentComponentData = data;
  }

  /**
   * Replace the component in the dialog
   * @param component The component to replace
   * @param dialog The dialog reference
   * @returns void
   */
  replaceComponentInDialog(component: ComponentType<unknown>): void {
    this.saveDialogHistoryContext(component);
  }

  /**
   * Set the dialog page title
   * @param title The title to set
   * @param dialog The dialog reference
   * @returns void
   */
  setDialogPageTitle(title: string): void {
    this.pageTitle$.next(title);
  }

  /**
   * Set the request made inside right side panel
   * @param value The value to set
   * @returns void
   */
  setRequestMadeInsideRightSidePanel(value: boolean): void {
    const isOpen = this.isOpen$.getValue();
    if (!isOpen) {
      this.requestMadeInsideRightSidePanel$.next(false);
      return;
    }
    this.requestMadeInsideRightSidePanel$.next(value);
  }

  /**
   * Notifies when the right side panel is closed after being opened.
   * @returns Observable that emits once when the panel is closed.
   */
  panelClosed(): Observable<boolean> {
    return this.isOpen$.pipe(
      filter((isOpen) => !isOpen), // Emit only when isOpen becomes false
      take(1) // Complete after the first emission
    );
  }

  /**
   * Notifies when the right side panel is closed after being opened.
   * @returns Observable that emits when the panel is closed.
   */
  afterPanelClosed(): Observable<boolean> {
    return this.isOpen$.pipe(
      pairwise(),  // Emit the previous and current value as an array
      filter(([prev, curr]) => prev && !curr),  // Check if the previous value was true and current is false
      map(([prev, curr]) => curr)  // Map to the current value
    );
  }

  /**
   * Open the right side panel
   * @param component The component to open
   * @returns void
   */
  async openRightSidePanel(component: ComponentType<unknown>, data: RightSideDialogData = null) {
    this.historyContext$.next([]);
    this.isOpen$.next(true);
    this.currentComponent$.next(null);
    this.currentComponentData = data;
    this.saveDialogHistoryContext(component, data);
  }

  /**
   * Go back to the previous component
   */
  async goBack(): Promise<void> {
    const historyContext =
      this.historyContext$.getValue();
    historyContext.pop();
    const previousComponent = historyContext[historyContext.length - 1];
    this.historyContext$.next(historyContext);
    this.currentComponent$.next(previousComponent);
  }

  async goBackInLayers(layers: number = 1): Promise<void> {
    for (let i = 0; i < layers; i++) {
      await this.goBack();
    }
  }

  /**
   * Close the right side panel
   * @returns void
   */
  async closeRightSidePanel() {
    this.isClosingPanel = true;
    this.headerProvider.showConciergePage = false;
    const dialog = document.getElementById('right-side-dialog');
    if (!dialog) {
      this.storage.delete('dialog-params');
      this.storage.delete('dialog-right-side-open');
      return;
    }
    dialog.classList.add('slide-out-right-animation');
    setTimeout(async () => {
      this.historyContext$.next([]);
      this.isOpen$.next(false);
      this.currentComponent$.next(null);
      this.pageTitle$.next(null);
      this.storage.delete('dialog-params');
      this.storage.delete('dialog-right-side-open');
      const dialogParams = await this.storage.retrieve('dialog-params');
      this.isClosingPanel = false;
    }, 200);
  }

  /**
   * Set the dialog loading state
   * @param isLoading The loading state to set
   * @returns void
   */
  setDialogLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  /**
   * Navigate to a route with or without the right side panel
   * @param route
   * @param params
   * @param component
   * @param checkIsOpen
   * @returns
   */
  async navigateTo(route: string, params: any = {}, component: ComponentType<unknown> = null, checkIsOpen = true): Promise<void> {
    try {
      component = component ? component : this.getComponentFromRoute(route);
      const dialogParams = await this.storage.retrieve('dialog-params');
      params = { ...dialogParams, ...params };
      await this.storage.save('dialog-params', params);
      const isRightSideContent = await this.storage.retrieve('dialog-right-side-open');
      const isOpen = this.isOpen$.getValue();
      if (isRightSideContent && (checkIsOpen ? isOpen : true)) {
        this.storage.save('dialog-right-side-open', true);
        const historyContext = this.historyContext$.getValue();
        if (historyContext.length === 0) {
          this.openRightSidePanel(component);
          return;
        }
        this.replaceComponentInDialog(component);
      } else {
        this.navCtrl.navigateForward(route, params);
      }
    } catch (err) {
      console.error(err);
    }
  }

  getComponentFromRoute(route) {
    const data = routes.find((item) => item.path == route);
    return data.component;
  }

  emitDropDownMenuAction(action: string): void {
    this.dropDownMenuAction$.next(action);
  }

}

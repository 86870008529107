import {
  Component,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TidyDateFormatPipe } from 'src/shared/pipes/tidy-date.pipe';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ReservationsOfDayPage } from '../reservations-of-day/reservations-of-day';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import {
  AvailabilityRequestResponse,
  Job,
} from 'src/models/schedule-weekly-view.model';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { JobPage } from '../job/job';
import { ReservationPage } from 'src/pages/reservation/reservation';
import { Util } from 'src/shared/util/util';
import { AddressModel } from 'src/models/address.model';
import { RequestProPage } from 'src/pages/request-pro/request-pro';

@Component({
  templateUrl: 'summary-of-day.html',
  styleUrls: ['./summary-of-day.scss'],
  selector: 'tidy-summary-of-day',
  encapsulation: ViewEncapsulation.None,
})
export class SummaryOfDayPage implements OnChanges, OnInit {
  isRightSideContent = true;
  dialogParams: any;
  propertiesWithEvents: any;
  pageTitle: string;

  constructor(
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private store: TidyStorage,
    private navCtrl: CustomNavController,
    private util: Util
  ) {}

  ngOnChanges(changes: SimpleChanges) {}

  async ngOnInit() {
    localStorage.setItem('jobPageBackPath', 'summary-of-day');
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.propertiesWithEvents = this.parsePropertiesWithEvents(
      this.dialogParams.day,
      this.dialogParams.properties
    );
    this.addCheckInCheckOutBoolean();
    this.dialogParams = {
      ...this.dialogParams,
      propertiesWithEvents: this.propertiesWithEvents,
    };
    this.store.save('dialog-params', this.dialogParams);
    if (this.dialogParams.type === 'reservation') {
      this.goToReservationsWithoutHistoryContext();
      return;
    }
    const parsedDate = new TidyDateFormatPipe().transform(
      this.dialogParams.day.fullDate,
      'cccc M/d/yy'
    );
    this.rightSidePanelService.setDialogPageTitle(parsedDate);
    this.pageTitle = parsedDate;
  }

  addCheckInCheckOutBoolean() {
    this.propertiesWithEvents.map((property) => {
      property.items.map((item) => {
        const isReservaiton = item?.checkInTime && !item?.parsedRequestTimes;
        if (isReservaiton) {
          if (item.startDate == this.dialogParams.day.fullDate) {
            item['isCheckIn'] = true;
          } else {
            item['isCheckIn'] = false;
          }
        }
      });
    });
  }

  parsePropertiesWithEvents(day: any, properties: any) {
    return properties
      .map((property: any) => {
        const jobsAtThisDay = property.filteredJobs.filter(
          (job) => job.startDate === day.fullDate
        );
        const availabilityRequestsAtThisDay =
          property.filteredAvailabilityRequests.filter(
            (job) => job.startDate === day.fullDate
          );
        const reservationsStartingThisDay = property.reservations.filter(
          (reservation) => reservation.startDate === day.fullDate
        );
        const reservationsEndingThisDay = property.reservations.filter(
          (reservation) => reservation.endDate === day.fullDate
        );
        let items = [
          ...jobsAtThisDay,
          ...reservationsStartingThisDay,
          ...reservationsEndingThisDay,
          ...availabilityRequestsAtThisDay,
        ];
        return {
          id: property?.id,
          name: property?.name,
          address: property?.address,
          items: this.sortItems(items, day.fullDate),
          reservationInfo: {
            starting: reservationsStartingThisDay.length,
            ending: reservationsEndingThisDay.length,
          },
        };
      })
      .filter((property) => property.items.length > 0);
  }

  sortItems(items, date) {
    let sortedItems = [];

    items.forEach((item) => {
      const isReservationCheckingOutToday =
        item?.period?.endDate == date && item?.checkInTime;
      const isJob = item?.booking || item?.parsedRequestTimes;
      const isReservationCheckingInToday =
        item?.period?.endDate !== date && item?.checkInTime;

      if (isReservationCheckingOutToday) {
        sortedItems.push(item);
      } else if (isJob) {
        sortedItems.push(item);
      } else if (isReservationCheckingInToday) {
        sortedItems.push(item);
      }
    });

    return sortedItems;
  }

  goToJobPage(cleaning: any) {
    if (cleaning?.parsedRequestTimes) {
      this.handleNavigatingAvailabilityRequest(cleaning);
      return;
    }
    const addressId = cleaning?.address_id;
    const isMobileResolution = window.innerWidth <= 870;
    const isPastJob =
      cleaning.status.name === 'cancelled' ||
      cleaning.status.name === 'completed';
    const isPrivateJob = cleaning.isPrivate;
    if (!isMobileResolution) {
      this.navigateToJobPageInsideDialog(
        cleaning,
        addressId,
        isPastJob,
        isPrivateJob
      );
      return;
    }
    localStorage.setItem('pastJobBackPage', 'schedule');
    if (isPastJob) {
      if (isPrivateJob) {
        this.navCtrl.navigateForward([`past-private-job/${cleaning.id}`]);
      } else {
        this.navCtrl.navigateForward([`past-job/${cleaning.id}`]);
      }
    } else {
      this.navCtrl.navigateForward([`job/${addressId}/${cleaning.id}`], {
        cleaning,
      });
    }
  }

  async handleNavigatingAvailabilityRequest(cleaning: Job) {
    // TODO: Monthly filters
    const loading = await this.util.showLoading();
    const availabilityRequest: AvailabilityRequestResponse = cleaning?.object;
    if (availabilityRequest.status === 'countered') {
      this.goToRequestProCounter(availabilityRequest, cleaning.address);
    } else {
      this.editProRequest(availabilityRequest, cleaning.address);
    }
    loading.dismiss();
    return;
  }

  goToRequestProCounter(
    availabilityRequest: AvailabilityRequestResponse,
    address: AddressModel
  ) {
    const params = {
      planFrequency: availabilityRequest.frequency,
      service: availabilityRequest.service_type_details,
      proposedTimes: availabilityRequest.request_times,
      proName: availabilityRequest.homekeeper?.name.split(' ')[0],
      hkId: availabilityRequest.homekeeper?.id,
      availabilityRequestId: availabilityRequest.id,
      addressId: address.id,
      addressName: address.address_name,
    };
    this.navCtrl.navigateForward('request-pro-counter', params);
  }

  async editProRequest(
    availabilityRequest: AvailabilityRequestResponse,
    address: AddressModel
  ) {
    try {
      const isMobileResolution = window.innerWidth <= 870;
      const params = {
        cameFromEditRequest: true,
        availabilityRequestId: availabilityRequest.id,
        requestTimes: availabilityRequest.request_times,
        serviceType: availabilityRequest.service_type_details.key,
        times: availabilityRequest.request_times,
        frequency: {
          viewValue: availabilityRequest.frequency,
          value: availabilityRequest.frequency,
        },
        pro: {
          first_name: availabilityRequest.homekeeper?.name.split(' ')[0],
          id: availabilityRequest.homekeeper?.id,
        },
        address,
      };
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.navigateTo(
          'request-pro',
          params,
          RequestProPage
        );
      } else {
        this.navCtrl.navigateForward('request-pro', params);
      }
    } catch (err) {
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  navigateToJobPageInsideDialog(
    cleaning: Job,
    addressId: number,
    isPastJob: boolean,
    isPrivateJob: boolean
  ): void {
    const params = {
      jobId: cleaning.id,
      addressId: addressId,
      ...cleaning,
    };
    this.storage.save('dialog-params', params);
    this.storage.save('dialog-right-side-open', true);
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.navigateTo(
        `past-private-job/${cleaning.id}`,
        params,
        PastPrivateJobPage
      );
    } else if (isPastJob) {
      this.rightSidePanelService.navigateTo(
        `past-job/${cleaning.id}`,
        params,
        PastJobPage
      );
    } else {
      this.rightSidePanelService.navigateTo(
        `job/${addressId}/${cleaning.id}`,
        params,
        JobPage
      );
    }
  }

  goToReservationsWithoutHistoryContext() {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(ReservationsOfDayPage);
    } else {
      this.storage.save('dialog-right-side-open', false);
      this.navCtrl.navigateForward('summary-of-day', this.dialogParams);
    }
  }

  goToReservations() {
    this.rightSidePanelService.navigateTo(
      'reservations-of-day',
      {},
      ReservationsOfDayPage
    );
  }

  goToReservation(reservation: any) {
    const addressName =
      reservation?.address?.address_name ||
      reservation?.address?.address1 +
        (reservation?.address?.address2
          ? ' ' + reservation?.address?.address2
          : '');
    const guestReservation = {
      ...reservation,
      check_in_date: reservation?.object?.check_in_date,
      check_out_date: reservation?.object?.check_out_date,
      check_in_time: reservation?.object?.check_in_time,
      check_out_time: reservation?.object?.check_out_time,
    };
    const params = {
      guestReservation,
      address: addressName,
      addressId: reservation?.address?.id
    };
    this.rightSidePanelService.navigateTo(
      'reservation',
      params,
      ReservationPage
    );
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Default Backup Times'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <form [formGroup]="form" novalidate action="#">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'If my Pro can\'t make the time I booked, automatically find a new time for my job at one of these times:'">
            </tidy-text>
          </tidy-row>
          <tidy-card>
            <ng-container *ngFor="let day of checkedBkpDays; let i=index">
              <ng-container formArrayName="days">
                <ng-container [formGroupName]="i">
                  <tidy-checkbox
                    [items]="[{viewValue: (i | tweekday), value: true}]"
                    formControlName="checked"
                    [checked]="getWeekdayFormGroup(i).controls.checked.value"
                    (checkedChange)="checkboxChange($event, i)">
                  </tidy-checkbox>
                  <tidy-row class="field-top-padding">
                    <tidy-two-column-row *ngIf="getWeekdayFormGroup(i).controls.checked.value">
                      <tidy-select
                        style="max-width: 45%;"
                        [icon]="'assets/svg/time-outline.svg'"
                        [label]="'Time'"
                        [form]="getWeekdayFormGroup(i).controls.start_time"
                        formControlName="start_time"
                        (optionChange)="startTimeChange($event, i)"
                        [items]="startTimeOpts">
                      </tidy-select>
                      <tidy-text
                        [body]="'to'"
                        style="line-height: 65px"
                        [ngStyle]="{'margin-left': windowService.isDesktopRes ? '8px' : '3px'}">
                      </tidy-text>
                      <tidy-select
                        style="max-width: 45%;"
                        [icon]="'assets/svg/time-outline.svg'"
                        [label]="'Time'"
                        [form]="getWeekdayFormGroup(i).controls.end_time"
                        formControlName="end_time"
                        [listedItems]="endTimeOpts.filter(endTimesFilter.bind(this, i))">
                      </tidy-select>
                    </tidy-two-column-row>
                  </tidy-row>
                </ng-container>
              </ng-container>
            </ng-container>
          </tidy-card>

        <tidy-error-message [text]="errorMessage"></tidy-error-message>

        <tidy-button
          [text]="'Save Changes'"
          [action]="saveChanges.bind(this)"
          class="primary">
        </tidy-button>

      </form>
    </ng-container>
  </tidy-wrapper>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

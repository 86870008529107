import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BookJob } from 'src/providers/book-job/book-job';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { Util } from 'src/shared/util/util';

import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';

@Component({
  templateUrl: 'send-payment.html',
  encapsulation: ViewEncapsulation.None
})

export class SendPaymentPage implements OnInit {

  address: any;
  allowsInvoiceAutopay: boolean;
  cameFromGetStarted: boolean;
  creditBalance: number;
  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  jobTimeItems: any = [];
  hasCreditCard: boolean;
  isRightSideContent: boolean;
  pro: any;
  privatePlanId: any;
  serviceItems: any;
  serviceCategoryItems: any;
  servicesResponse: any;
  submitted: boolean;
  userRole: string;

  constructor(
    private bookJob: BookJob,
    private card: Card,
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {
    this.form = this.fb.group({
      service: ['', Validators.required],
      serviceCategory: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      price: ['', Validators.required],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Send Payment');
      }
      this.pro = this.navCtrl.getParam('pro') || this.dialogParams?.pro || await this.pros.getProDetail(this.navCtrl.getParam('proId' || this.dialogParams.proId));
      this.userRole = localStorage.getItem('user_role');
      if (this.userRole !== 'member') {
        this.creditBalance = await this.getUsableCredit();
      }
      this.address = this.navCtrl.getParam('address') || this.dialogParams?.address;
      this.allowsInvoiceAutopay = this.navCtrl.getParam('allowsInvoiceAutopay') || this.dialogParams?.allowsInvoiceAutopay || await this.checkIfAllowsInvoiceAutopay();
      this.privatePlanId = this.navCtrl.getParam('privatePlanId') || this.dialogParams?.privatePlanId || this.getPrivatePlanId();
      this.serviceCategoryItems = await this.getServiceCategoryItems();
      await this.getServiceItems();
      this.jobTimeItems = this.bookJob.getJobTimeItems();
      this.form.patchValue({
        serviceCategory: this.servicesResponse[0].serviceType.key,
        service: this.serviceItems[0].value,
        time: this.jobTimeItems[0].value
      })
      this.cameFromGetStarted = this.navCtrl.getParam('cameFromGetStarted') || this.dialogParams?.cameFromGetStarted;
      this.hasCreditCard = await this.card.hasValidCard();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async getUsableCredit() {
    const billingData = await this.client.getBillingData();
    let credit = 0;
    billingData.balance_details.map((detail) => {
      const cameFromEmployer = detail.transferred_from !== null;
      if (cameFromEmployer) {
        credit += detail.amount_left;
      }
    });
    return credit;
  }

  async checkIfAllowsInvoiceAutopay() {
    const autopaySettings = await this.pros.getAutopaySettings(this.pro.team.id);
    let allowsInvoiceAutopay = false;
    autopaySettings.map((settings) => {
      if (settings?.key === 'payment.autopay') {
        allowsInvoiceAutopay = settings?.value === "true";
      }
    });
    return allowsInvoiceAutopay;
  }

  getPrivatePlanId() {
    let id = null;
    if (this.address.plans.length) {
      if (this.pro.is_private) {
        this.address.plans.map((plan) => {
          if (plan.is_private) {
            id = plan.id;
          }
        });
      }
    }
    return id;
  }

  async getServiceCategoryItems() {
    const categories = await this.pros.getServiceCategories();
    return categories.map((category) => {
      return {
        value: category.key,
        viewValue: category.name
      }
    });
  }

  async getServiceItems() {
    this.servicesResponse = await this.bookJob.fetchTeamServices(this.pro.homekeeper.id);
    this.parseServiceItemsByCategory(this.servicesResponse[0].serviceType.key);
  }

  parseServiceItemsByCategory(category) {
    this.serviceItems = [];
    this.servicesResponse.map((service) => {
      const alreadyAdded = this.serviceItems.find(item => service.title == item.viewValue);
      if (!alreadyAdded && service.serviceType.key == category) {
        this.serviceItems.push({
          viewValue: service.title,
          value: service.key
        });
      }
    });
  }

  @Loading('', true)
  async sendPayment() {
    try {
      this.submitted = true;
      if (!this.form.valid) {
        return;
      }
      const payload = {
        homekeeper_id: this.pro.homekeeper.id,
        amount: parseInt(this.util.removeCurrencyMask(this.form.value.price), 10),
        address_id: this.address.id,
        job:           {
          date: this.form.value.date,
          start_time: this.form.value.time,
          service_type_key: this.form.value.service
        }
      }
      await this.pros.sendPayment(payload, this.pro.homekeeper.id);
      this.showInvoicePaidSuccess();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  showInvoicePaidSuccess() {
    const params = {
      proId: this.pro.homekeeper.id
    }
    this.util.showSuccess('Payment Sent');
    this.rightSidePanelService.navigateTo(`my-pro/${this.pro.homekeeper.id}`, params, MyProPage);
  }

  goToAddCard() {
    const params = {
      cameFromSendPayment: true,
      pro: this.pro,
      creditBalance: this.creditBalance,
      address: this.address,
      allowsInvoiceAutopay: this.allowsInvoiceAutopay
    }
    this.rightSidePanelService.navigateTo('get-started-card-on-file/card', params);
  }

}

import {Component, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { ValueAccessorBase } from '../default-class/value-accessor';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tidy-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatePickerComponent,
    multi: true,
  }],
})
export class DatePickerComponent extends ValueAccessorBase<any> {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public icon: string;
  @Input() public form: any;
  @Input() public submitted: boolean;
  @Input() public errorMessage: string;
  @Input() public noPastDates: boolean;
  @Input() public minDate: string | Date;
  @Input() public minSameDate: boolean = false;
  @Output() optionChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(NgModel, {static: false}) model: NgModel;

  calendarOpened: boolean = false;
  touchedState = false;

  changeDate(e) {
    this.value = e.value;
    this.optionChange.emit(this.value);
  }

  changeTouch = () => {
    this.touchedState = true;
  }

  ngOnChanges() {
    if (this.noPastDates) {
      this.minDate = new Date();
      this.minDate.setHours(0, 0 , 0, 0);
    }

    if (this.beforeThanMinDate() && !this.minSameDate) {
      this.value = undefined;
    }
  }

  beforeThanMinDate() {
    const dateValue = new Date(this.value);
    const dateMin = new Date(this.minDate);

    return dateValue < dateMin;
  }

  toggleMatPicker() {
    this.calendarOpened = !this.calendarOpened;
  }

  calendarClosed() {
    this.calendarOpened = false;
  }
}

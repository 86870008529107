import { EventEmitter, Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class Dashboard {

  public newReportAdded: EventEmitter<any> = new EventEmitter();
  customerId;
  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  async addDashboardReport(payload: {dashboard_report_type_id: number, name: string}) {
    const url = 'api/v1/customer/dashboard/reports';
    const response = await this.httpClient.post(url, payload);

    this.newReportAdded.emit(response);
    return response;
  }

  async buildDashboardSectionItems(update: boolean = false): Promise<any[]> {
    const array: any = [];
    return await new Promise(async (resolve) => {
      setTimeout(async () => {
        const items = await this.getDashboardSectionItems(update);
        items.map((item) => {
          const icon = this.getIcon(item.default_date_key);
          array.push({
            icon: icon.icon,
            activeIcon: icon.activeIcon,
            label: item.name,
            target: `reports/${item.id}`
          });
        });
        resolve(array);
      }, 2000);
    });
  }

  getIcon(defaultDateKey) {
    const array = defaultDateKey.split('.');
    const key = array[0];
    switch(key) {
      case('guest_reservation'):
        return {
          icon: 'assets/img/link-white.svg',
          activeIcon: 'assets/img/link-green.svg'
        };
        break;
      case('jobs'):
        return {
          icon: 'assets/img/briefcase-white.svg',
          activeIcon: 'assets/img/briefcase-green.svg'
        };
        break;
      case('overview'):
        return {
          icon: 'assets/img/earth.svg',
          activeIcon: 'assets/img/earth-green.svg'
        };
        break;
      case('maintenances'):
        return {
          icon: 'assets/img/build-white.svg',
          activeIcon: 'assets/img/build-green.svg'
        };
        break;
      default:
        return {
          icon: 'assets/img/earth.svg',
          activeIcon: 'assets/img/earth-green.svg'
        };
    }
  }

  async deleteDashboardReport(dashboardCustomerReportId) {
    const url = `api/v1/customer/dashboard/reports/${dashboardCustomerReportId}`;
    return await this.httpClient.delete(url);
  }

  async getDashboardReportData(dashboardCustomerReportId) {
    const url = `api/v1/customer/dashboard/reports/${dashboardCustomerReportId}/report`;
    return await this.httpClient.get(url);
  }

  async getDashboardReports(update: boolean = false) {
    let reports = await this.storage.retrieve('allReports');
    const time = await this.storage.retrieve('allReportsTime');
    const fiveMinutes = 5 * 60 * 1000;
    const currentTime = DateTime.now().toMillis();
    const isCacheExpired = !reports || (time + fiveMinutes < currentTime);
    if (isCacheExpired || update) {
      const url = 'api/v1/customer/dashboard/reports';
      reports = await this.httpClient.get(url);
      await this.storage.save('allReports', reports);
      const time = currentTime;
      await this.storage.save('allReportsTime', time);
    }
    return reports;
  }

  getDashboardIcon(id, activeType) {
    const icons = {
      1: {
        inactive: 'assets/img/addresses.svg',
        active: 'assets/img/addresses-selected.svg',
      }
    }
    return icons[id][activeType];
  }

  async getDashboardReportTypeTemplates() {
    const url = 'api/v1/customer/dashboard/reports/templates';
    return await this.httpClient.get(url);
  }

  async getDashboardSectionItems(update: boolean = false) {
    const customerId = localStorage.getItem('customer_id');
    const dashboardCustomerId = localStorage.getItem('dashboardCustomerId');
    const expiredCustomerData = (customerId !== dashboardCustomerId);
    let dashboardSectionItems = await this.storage.retrieve('dashboardSectionItems');
    if (!dashboardSectionItems || expiredCustomerData || update)  {
      localStorage.setItem('dashboardCustomerId', customerId)
      dashboardSectionItems = await this.getDashboardReports(update);
      if (dashboardSectionItems?.length == 0) {
        dashboardSectionItems = this.resetDashboardWithNewDefaults();
      }
      await this.storage.save('dashboardSectionItems', dashboardSectionItems);
    }
    return dashboardSectionItems;
  }

  async resetDashboardWithNewDefaults() {
    const url = 'api/v1/customer/dashboard/reports/defaults';
    return await this.httpClient.post(url, {});
  }

  async updateDashboardReport(payload, dashboardCustomerReportId) {
    const url = `api/v1/customer/dashboard/reports/${dashboardCustomerReportId}`;
    return await this.httpClient.put(url, payload);
  }

  async updateDashboardReportFields(payload, dashboardCustomerReportId) {
    const url = `api/v1/customer/dashboard/reports/${dashboardCustomerReportId}/fields`;
    return await this.httpClient.put(url, payload);
  }

  async updateDashboardReportFilters(filters, dashboardCustomerReportId) {
    const url = `api/v1/customer/dashboard/reports/${dashboardCustomerReportId}/filters`;
    return await this.httpClient.put(url, filters);
  }

  async updateDashboardSectionItems(update: boolean = false) {
    const dashboardSectionItems = await this.getDashboardSectionItems(update);
    this.storage.save('dashboardSectionItems', dashboardSectionItems);
  }

  async getMapEvents() {
    const url = 'api/v1/customer/dashboard/map/events';
    return await this.httpClient.get(url);
  }

  async getNudgeCards() {
    const url = 'api/v1/customer/spotlighted-features';
    return await this.httpClient.get(url);
  }

  getHouseImage(index) {
    const houses = {
      1: 'assets/img/home-green.svg',
      2: 'assets/img/home-pink.svg',
      3: 'assets/img/home-purple.svg',
      4: 'assets/img/home-darker-pink.svg',
      5: 'assets/img/home-yellow.svg',
      6: 'assets/img/home-light-blue.svg',
      7: 'assets/img/home-brown.svg',
      8: 'assets/img/home-orange.svg',
      9: 'assets/img/home-peach.svg',
      10: 'assets/img/home-bright-orange.svg'
    }
    return houses[index];
  }
}

<tidy-card>
  <form [formGroup]="form" novalidate action="#">
    <tidy-row>
      <tidy-radio-button
        [label]="'Do you use a property management system (PMS) to manage your properties?'"
        formControlName="type"
        [form]="form.controls.type"
        [items]="typeItems"
        [submitted]="submitted"
        [errorMessage]="'Please select an option.'"
        (optionChange)="onTypeChange()">
      </tidy-radio-button>
    </tidy-row>
    <tidy-row [ngStyle]="{'display': form.value.type === 'no' ? 'block' : 'none'}" class="extra-top-padding">
      <tidy-radio-button
        [label]="'What is your primary way of renting properties?'"
        formControlName="primaryType"
        [form]="form.controls.primaryType"
        [items]="primaryTypeItems"
        [submitted]="submitted"
        [errorMessage]="'Please select the primary way you rent properties.'">
      </tidy-radio-button>
    </tidy-row>
  </form>
</tidy-card>

<tidy-button
  [text]="'Next'"
  [action]="goToAddIntegrationPage.bind(this)"
  [class]="'primary'">
</tidy-button>


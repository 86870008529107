<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Load Credit'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-row>
      <div>

        <ng-container *ngIf="!loaded">
          <tidy-card>
            <ion-skeleton-text
              animated="true"
              style="width: 100%; height: 45px; border-radius: 6px; margin-bottom: 1rem">
            </ion-skeleton-text>
            <ion-skeleton-text
              animated="true"
              style="width: 100%; height: 45px; border-radius: 6px; margin-bottom: 1rem">
            </ion-skeleton-text>
          </tidy-card>
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 60px; border-radius: 6px; margin-bottom: 1rem">
          </ion-skeleton-text>
        </ng-container>

        <ng-container *ngIf="loaded && paymentMethodsItems.length === 1">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Please add a payment method to load credit.'">
            </tidy-text>
          </tidy-row>
          <tidy-button
            [text]="'Add Payment Method'"
            [action]="goToAddPaymentMethod.bind(this)"
            class="primary">
          </tidy-button>
        </ng-container>

        <ng-container *ngIf="loaded && paymentMethodsItems.length > 1">
          <tidy-card>
            <form [formGroup]="form">
              <tidy-row>
              <tidy-select
                [multiple]="false"
                [label]="'Payment Method'"
                [items]="paymentMethodsItems"
                [form]="form.controls.paymentMethod"
                formControlName="paymentMethod"
                class="select-custom-input select-custom-input-bigger"
                (optionChange)="changePaymentMethod($event)">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-input
                formControlName="price"
                [label]="'Amount'"
                [form]="form.controls.price"
                [errorMessage]="'Please select an amount.'"
                [submitted]="submitted"
                inputMode="numeric"
                [mask]="'currencyMask'"
                class="select-custom-input select-custom-input-bigger">
              </tidy-input>
            </tidy-row>
            </form>
          </tidy-card>

          <tidy-button
            [text]="'Add Credit'"
            [action]="addCredit.bind(this)"
            class="primary">
          </tidy-button>

        </ng-container>

        <!-- <tidy-row style="margin-bottom: 1rem">
          <tidy-text [title]="'Credit History'"></tidy-text>
        </tidy-row>

        <ng-container *ngIf="!loaded">
          <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
            <tidy-card>
              <ion-skeleton-text
                animated="true"
                style="width: 120px; height: 30px; border-radius: 6px">
              </ion-skeleton-text>
              <div style="margin-top: 1rem"></div>
              <div class="vertical-align-center justify-content-between" style="margin-bottom: -1rem">
                <div style="width: 48%">
                  <ion-skeleton-text
                    animated="true"
                    style="
                      width: 100%;
                      height: 28px;
                      border-radius: 6px;
                      margin-bottom: 2rem;
                    ">
                  </ion-skeleton-text>
                </div>
                <div style="width: 50%; margin-left: 1rem">
                  <ion-skeleton-text
                    animated="true"
                    style="
                      width: 100%;
                      height: 28px;
                      border-radius: 6px;
                      margin-bottom: 2rem;
                    ">
                  </ion-skeleton-text>
                </div>
              </div>
              <ion-skeleton-text
                animated="true"
                style="width: 60%; height: 25px; border-radius: 6px">
              </ion-skeleton-text>
              <ion-skeleton-text
                animated="true"
                style="width: 40%; height: 25px; border-radius: 6px">
              </ion-skeleton-text>
            </tidy-card>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!loadedNewCredit">
          <ng-container>
            <tidy-card>
              <ion-skeleton-text
                animated="true"
                style="width: 120px; height: 30px; border-radius: 6px">
              </ion-skeleton-text>
              <div style="margin-top: 1rem"></div>
              <div class="vertical-align-center justify-content-between" style="margin-bottom: -1rem">
                <div style="width: 48%">
                  <ion-skeleton-text
                    animated="true"
                    style="
                      width: 100%;
                      height: 28px;
                      border-radius: 6px;
                      margin-bottom: 2rem;
                    ">
                  </ion-skeleton-text>
                </div>
                <div style="width: 50%; margin-left: 1rem">
                  <ion-skeleton-text
                    animated="true"
                    style="
                      width: 100%;
                      height: 28px;
                      border-radius: 6px;
                      margin-bottom: 2rem;
                    ">
                  </ion-skeleton-text>
                </div>
              </div>
              <ion-skeleton-text
                animated="true"
                style="width: 60%; height: 25px; border-radius: 6px">
              </ion-skeleton-text>
              <ion-skeleton-text
                animated="true"
                style="width: 40%; height: 25px; border-radius: 6px">
              </ion-skeleton-text>
            </tidy-card>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let credit of creditHistory">
          <tidy-card>
            <ng-container *ngIf="credit?.status">
              <tidy-row>
                <ion-chip [class]="credit?.class">
                  <ion-icon [name]="credit?.icon"></ion-icon>
                  <ion-label>{{ credit?.status | titlecase }}</ion-label>
                </ion-chip>
              </tidy-row>
            </ng-container>
            <div class="vertical-align-center justify-content-between">
              <div style="margin-bottom: 1rem">
                <tidy-image [src]="'assets/img/coin.png'" class="header-size">
                </tidy-image>
                <tidy-text [header]="'Amount added: '"></tidy-text>
                <tidy-text
                  [body]="credit?.amount / 100 | currency"
                  style="margin-left: 5px"></tidy-text>
              </div>
              <div>
                <tidy-text [header]="'Date: '"></tidy-text>
                <tidy-text
                  [body]="credit?.date | customdate: 'MM/DD/YYYY'"
                  style="margin-left: 5px"></tidy-text>
              </div>
            </div>
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="credit?.payment_method?.type === 'card' ? 'assets/svg/card.svg' : 'assets/img/bank.png'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="(credit?.payment_method?.type === 'card' ? 'Card' : 'Bank Account') + (credit?.payment_method?.metadata?.last4 ? ' ending in ' + credit?.payment_method?.metadata?.last4 : '')">
              </tidy-text>
              <ng-container *ngIf="credit?.payment_method?.is_primary">
                <tidy-text [header]="'(primary)'" style="margin-left: 5px">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <ng-container *ngIf="credit?.payment_method?.metadata?.bank_name">
              <tidy-row style="margin-top: -15px">
                <tidy-text
                  [body]="credit?.payment_method?.metadata?.bank_name + (credit?.payment_method?.metadata?.account_type ? ' | ' + credit?.payment_method?.metadata?.account_type : '')">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card>
        </ng-container> -->
      </div>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="text?.title"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
       <tidy-text
         [body]="'What would you like to do?'">
       </tidy-text>
     </tidy-row>

    <tidy-button
     [text]="text?.buttonOne"
     [action]="reschedule.bind(this)"
     class="primary">
    </tidy-button>

    <tidy-button
     [text]="text?.buttonTwo"
     [action]="cancel.bind(this)"
     class="secondary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="loaded ? headerText + ' Pro' : ''"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper class="right-side-scroll-enabled">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="job?.template_data?.job?.service_type_details?.service_category?.icon_url"
                  style="width:30px;">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="job?.template_data?.booking?.bookable_service?.name | titlecase">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                    ' ' +
                    (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D') +
                    ' at ' +
                    (job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row [ngClass]="form?.value?.existingOrNew == 'newPro' ? 'extra-bottom-padding' : ''">
            <tidy-radio-button
              [label]="'What do you want to do?'"
              formControlName="existingOrNew"
              [form]="form.controls.existingOrNew"
              (optionChange)="selectExistingOrNew($event)"
              [items]="existingOrNewItems">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row *ngIf="selection == 'existingPro' && !privateProItems?.length">
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'You haven\'t added any private pros. Add pros to book jobs with them, collect payment and more.'">
              </tidy-text>
            </tidy-row>
          </tidy-row>

          <tidy-row *ngIf="form?.value?.existingOrNew == 'existingPro' && privateProItems?.length" class="extra-top-padding">
            <tidy-radio-button
              [label]="'Select the pro:'"
              formControlName="privatePro"
              [form]="form.controls.privatePro"
              [items]="privateProItems"
              (optionChange)="selectPro($event)">
            </tidy-radio-button>
          </tidy-row>

          <ng-container *ngIf="form?.value?.existingOrNew == 'newPro'">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-input
                formControlName="name"
                [label]="'Their Name*'"
                [form]="form.controls.name"
                [submitted]="submitted"
                [errorMessage]="'Please enter a name.'"
                [icon]="'assets/svg/person-outline.svg'"
                [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="email"
                [label]="'Their Email*'"
                [form]="form.controls.email"
                type="email"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid email.'"
                [icon]="'assets/svg/mail-outline.svg'"
                [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-input-international-phone
                formControlName="phone"
                [label]="'Their Phone'"
                [form]="form.controls.phone"
                [inputMode]="'numeric'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid phone.'"
                [icon]="'assets/svg/phone-portrait-outline.svg'"
                [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
              </tidy-input-international-phone>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-button
          [text]="submitButtonText"
          [action]="confirm.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Sign Up'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <form [formGroup]="form" [id]="formIds.CREATE_ACCOUNT_FORM">
          <tidy-row *ngIf="giftCodeMessage && this.giftCode?.giver_name" [align]="'center'">
            <tidy-text
              [header]="giftCodeMessage">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="flowType === 'private'" [align]="'center'" class="extra-bottom-padding">
            <tidy-row>
              <tidy-text
                [header]="'Instantly Book'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="(proName | titlecase)">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="(proName | titlecase)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'from'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(proTeamName | titlecase)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'uses TIDY to let you book & reschedule appointments online. It\’s free for you to use.'">
              </tidy-text>
            </tidy-row>
          </tidy-row>

          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="firstName"
              [form]="form.controls.firstName"
              [label]="'First Name'"
              [errorMessage]="'Please enter your first name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="lastName"
              [form]="form.controls.lastName"
              [label]="'Last Name'"
              [errorMessage]="'Please enter your last name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="email"
              [form]="form.controls.email"
              [label]="'Email'"
              type="email"
              [errorMessage]="'Please enter a valid email.'"
              [submitted]="submitted"
              [inputId]="inputIds.EMAIL_FIELD"
              [icon]="'assets/svg/mail-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row [ngClass]="flowType == 'rental' ? 'field-bottom-padding' : 'extra-bottom-padding'">
            <tidy-input
              formControlName="password"
              [form]="form.controls.password"
              [label]="'Password'"
              [errorMessage]="'Password enter a password.'"
              [submitted]="submitted"
              type="password"
              [icon]="'assets/svg/lock-closed-outline.svg'">
            </tidy-input>
          </tidy-row>

          <ng-container *ngIf="flowType === 'normal' || flowType === 'private'">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'What are you using us for?'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-radio-button
                formControlName="type"
                [form]="form.controls.type"
                [items]="accountTypes"
                [errorMessage]="'Please select an option'"
                [submitted]="submitted"
                (optionChange)="selectType($event)">
              </tidy-radio-button>
            </tidy-row>
          </ng-container>

          <ng-container *ngIf="form?.value?.type == 'rental' || flowType == 'rental'">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'Are you a property manager or property owner?'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-radio-button
                formControlName="ownerType"
                [form]="form.controls.ownerType"
                [items]="ownerTypeItems"
                [errorMessage]="'Please select an answer.'"
                [submitted]="submitted">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row>
              <tidy-input
                formControlName="numberOfProperties"
                [label]="'How many rental properties do you manage?'"
                [form]="form.controls.numberOfProperties"
                [submitted]="submitted"
                [errorMessage]="'Please enter the number of properties.'"
                inputMode="numeric"
                [mask]="'number'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-input
                formControlName="accountName"
                [label]="'What is the name of your company? (optional)'"
                [form]="form.controls.accountName">
              </tidy-input>
            </tidy-row>
          </ng-container>

          <tidy-row class="extra-top-padding">
            <tidy-grid>
              <ion-row>
                <ion-col size="1">
                  <tidy-checkbox
                    style="margin-right: 5px"
                    formControlName="terms"
                    [form]="form.controls.terms"
                    [items]="[{viewValue: '', value: true}]">
                  </tidy-checkbox>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="'I agree to the '">
                  </tidy-text>
                  <tidy-text
                    (action)="openTerms()"
                    [body]="'terms'"
                    class="link">
                  </tidy-text>
                  <tidy-text
                    [body]="', which includes agreeing to individual arbitration.*'">
                  </tidy-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>

          <tidy-row *ngIf="termsError">
            <tidy-error-message
              [text]="'You must agree to the terms to continue.'">
            </tidy-error-message>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row>
        <tidy-button
          [text]="'Continue'"
          class="primary"
          [action]="createAccount.bind(this)">
        </tidy-button>
      </tidy-row>

      <ng-container *ngIf="flowType === 'normal' || flowType === 'rental' || flowType === 'company'">
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'Already have an account? '">
          </tidy-text>
          <tidy-text
            (action)="goToLoginPage()"
            [body]="'Log In'"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="flowType === 'maintenance'">
        <tidy-row [align]="'center'">
          <tidy-text
            [header]="'Use TIDY to:'">
          </tidy-text>
        </tidy-row>
        <tidy-alert>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/tool-box.svg'"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Get Maintenance Tips'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'We will tell you what the manufacturer recommends for each item.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-alert>
        <tidy-alert>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/house-sketch.svg'"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Create a Sharable Map'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Your map will be with your to-do list to help your pro visually get the job done right.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-alert>
        <tidy-alert>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/light-bulb.svg'"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Clean Smarter'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Knowing what is in your home helps you and any pros you use follow best practices for that exact thing.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-alert>
      </ng-container>

      <tidy-alert *ngIf="!windowService.isDesktopRes && flowType !== 'private' && flowType !== 'developer'">
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [header]="'Over 10,000 Happy Clients'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let quote of quotes; let first = first">
          <tidy-row [ngClass]="first ? 'extra-bottom-padding' : ''">
            <tidy-grid>
              <ion-row>
                <ion-col size="3">
                  <tidy-image
                    style="margin: auto; width: 40px"
                    [src]="quote?.icon">
                  </tidy-image>
                </ion-col>
                <ion-col>
                  <tidy-text
                    [body]="quote?.text">
                  </tidy-text>
                </ion-col>
              </ion-row>
              <tidy-row [align]="'right'">
                <tidy-text
                  [body]="'- ' + quote?.person">
                </tidy-text>
              </tidy-row>
            </tidy-grid>
          </tidy-row>
        </ng-container>
      </tidy-alert>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'*If you would like an alternate agreement, contact sales@tidy.com instead of agreeing to these terms.'">
        </tidy-text>
      </tidy-row>
    </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <!--Note this used to be a wrapper, but it was making the 10px at the bottom less opaque so I removed-->
  <!--Couldn't figure out why-->
  <div style="margin: 10px" *ngIf="loaded && windowService.isDesktopRes && flowType !== 'private' && flowType !== 'developer'">

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [header]="'Over 10,000 Happy Clients'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngFor="let quote of quotes; let first = first">
      <tidy-row [ngClass]="first ? 'extra-bottom-padding' : ''">
        <tidy-grid>
          <ion-row>
            <ion-col size="3">
              <tidy-image
                style="margin: auto; width: 40px"
                [src]="quote?.icon">
              </tidy-image>
            </ion-col>
            <ion-col>
              <tidy-text
                [body]="quote?.text">
              </tidy-text>
            </ion-col>
          </ion-row>
          <tidy-row [align]="'right'">
            <tidy-text
              [body]="'- ' + quote?.person">
            </tidy-text>
          </tidy-row>
        </tidy-grid>
      </tidy-row>
    </ng-container>

  </div>
</ng-template>

<app-scroll-wrapper [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Requests } from 'src/providers/requests/requests';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { WindowService } from 'src/shared/providers/window.service';

import { TypePlanFormatPipe } from 'src/shared/pipes/plan-type-format.pipe';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'request-pro-counter.html',
  encapsulation: ViewEncapsulation.None
})

export class RequestProCounterPage implements OnInit {

  addressName: any;
  builtTimeItems: boolean;
  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  proName: any;
  loaded: boolean;
  planFrequency: any;
  planFrequencyName: string;
  proposedTimes: any;
  service: any;

  constructor(
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private typePlanFormatPipe: TypePlanFormatPipe,
    public requests: Requests,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      time: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    const params = this.isRightSideContent ? this.dialogParams : this.navCtrl.getParams(false);
    const hasNavParams = Object.keys(params).length > 1;
    if (hasNavParams) {
      this.loadPageParams(params);
    }
    this.rightSidePanelService.setDialogPageTitle(this.proName + '\'s ' + (new TranslationPipe()).transform('Proposal'));
    this.loaded = true;
  }

  loadPageParams(params) {
    this.planFrequency = params.planFrequency;
    this.planFrequencyName = this.getPlanFrequencyName(this.planFrequency);
    this.service = params.service;
    this.proposedTimes = this.formatProposedTimes(params.proposedTimes);
    this.proName = params.proName;
    this.addressName = params.addressName;
  }

  getPlanFrequencyName(frequency) {
    const frequencies = {
      every_week: 'Every Week',
      every_other_week: 'Every Other Week',
      every_four_weeks: 'Every Four Weeks',
      once: 'One Time'
    }
    return frequencies[frequency];
  }

  formatProposedTimes(requestDays) {
    const datePipe = new CustomDatePipe();
    const array: any = [];
    requestDays.map(day => {
      const formattedDay: any = {
        date: day.date,
        times: []
      };
      const timeArray = day.times;
      timeArray.map((time) => {
        formattedDay.times.push({
          value: {...day, time},
          viewValue: datePipe.transform(time, 'h:mma', null, 'HH:mm')
        });
      });
      array.push(formattedDay);
    });
    return array;
  }

  async acceptTime() {
    const once = this.planFrequency === 'once';
    const params: ConfirmPageParamsModel = {
      title: once ? 'Book Job?' : 'Book Plan?',
      body: `${(new TranslationPipe()).transform('Book a')} ${this.service.name} ${!once ? this.planFrequencyName : ''} ${(new TranslationPipe()).transform('with')} ${this.proName}?`,
      backText: 'Go Back',
      confirmText: 'Book Now',
      confirmAction: await this.confirmAcceptTime.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmAcceptTime() {
    const availabilityRequestId = this.navCtrl.getParam('availabilityRequestId') || this.dialogParams.availabilityRequestId;
    const addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
    const payload = {
      date: this.form.value.time.date,
      time: this.form.value.time.time
    }
    try {
      await this.requests.acceptProCounterRequest(availabilityRequestId, addressId, payload);
      const once = this.planFrequency === 'once';
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      const params = {
        header: once ? 'Job Booked' : 'Plan Booked',
        body: once ? `${this.service.name} ${(new TranslationPipe()).transform('Booked')}` : `${this.service.name} ${this.planFrequencyName} ${(new TranslationPipe()).transform('Booked')}`,
        buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
      }
      this.rightSidePanelService.navigateTo('success', params);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async decline() {
    const params: ConfirmPageParamsModel = {
      title: 'Decline Proposal',
      body: '',
      backText: 'Go Back',
      confirmText: 'Decline',
      confirmAction: await this.confirmDecline.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDecline() {
    try {
      const availabilityRequestId = this.navCtrl.getParam('availabilityRequestId') || this.dialogParams.availabilityRequestId;
      const addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
      await this.requests.rejectProCounterRequest(availabilityRequestId, addressId);
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      const params = {
        header: 'Proposal Declined',
        body: '',
        buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
      };
      this.rightSidePanelService.navigateTo('success', params);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  buildTimeItems(day) {
    if (!this.builtTimeItems) {
      const array = [];
      const times = day[1];
      times.map((time) => {
        array.push({
          value: time,
          viewValue: time.time
        });
      });
      this.builtTimeItems = true;
      return array;
    }
  }

}

import { Component, ElementRef, OnInit, EventEmitter, Input, Output, ViewChild, ViewEncapsulation,  } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDatepicker, MatDateRangeInput } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { DateTime } from 'luxon';

@Component({
  selector: 'tidy-daterange',
  templateUrl: 'daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataRangePickerComponent implements OnInit {

  @Input() dateRangeForm: UntypedFormGroup;
  @Input() errorMessage;
  @Input() icon = 'assets/svg/calendar.svg';
  @Input() label = 'Date Range';
  @Input() selectOptions: any;;
  @Input() submitted = false;
  @Input() header = false;
  @Input() rangeOption: string;
  @Input() appearance = 'none';
  @Output() customDateOption: EventEmitter<UntypedFormGroup | null> = new EventEmitter<UntypedFormGroup>();
  @Output() stringDateOption: EventEmitter<string | null> = new EventEmitter<string | null>();
  @ViewChild('dateRangeInput') dateRangeInput: MatDateRangeInput<Date>;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @ViewChild('optionsPicker') optionsPicker: MatSelect;
  @ViewChild('startDateInput') startDateInput: ElementRef;

  showRangeOptions = false;
  prevRangeOption = null;

  ngOnInit() {
    this.selectOptions = this.selectOptions ? this.selectOptions : [
      { viewValue: 'Custom', value: 'custom' },
      { viewValue: 'Today', value: 'today' },
      { viewValue: 'Tomorrow', value: 'tomorrow' },
      { viewValue: 'Last Week', value: 'last_week' },
      { viewValue: 'Next Week', value: 'next_week' },
      { viewValue: 'Last Month', value: 'last_month' },
      { viewValue: 'Next Month', value: 'next_month' },
      { viewValue: 'In the Future', value: 'future' },
      { viewValue: 'In the Past', value: 'past' },
      { viewValue: 'All Time', value: 'all_time' }
    ];

    if (this.header) {
      this.rangeOption = 'all_time';
      this.dateRangeForm.patchValue({
        customStringSelect: 'all_time',
        start: null,
        end: null
      });
    }
  }

  selectOption(option) {
    this.prevRangeOption = this.rangeOption;
    this.rangeOption = option;

    if (option === 'custom') {
      this.openDatePicker();
    } else if (option === 'all_time') {
      this.stringDateOption.emit(option);
      this.dateRangeForm.patchValue({start: null, end: null});
    } else {
      this.stringDateOption.emit(option);
      const timeRange = this.getTimeRange(option);
      this.dateRangeForm.patchValue({start: timeRange.start, end: timeRange.end});
    }
    this.showRangeOptions = false;
  }

  openDatePicker() {
    if (!this.picker || !this.dateRangeInput) {
      setTimeout(() => this.openDatePicker(), 0);
    } else {
      this.picker.open();
      this.dateRangeForm.patchValue({start: null, end: null});
      const closedSub = this.picker.closedStream.subscribe(_ => {
        if (!this.dateRangeForm.value.start || !this.dateRangeForm.value.end) {
          this.dateRangeForm.patchValue({start: null, end: null, customStringSelect: this.prevRangeOption});
          this.rangeOption = this.prevRangeOption;
        }
        closedSub.unsubscribe();
      });
    }
  }

  openOptionsPicker() {
    this.showRangeOptions = true;
    if (!this.optionsPicker) {
      setTimeout(() => this.openOptionsPicker(), 0);
    } else {
      this.focusStartDate();
      this.optionsPicker.open();
      const subscription = this.optionsPicker._closedStream.pipe().subscribe(() => {
        this.showRangeOptions = false;
        subscription?.unsubscribe();
      });
    }
  }

  focusStartDate() {
    if (!this.startDateInput) {
      setTimeout(() => this.focusStartDate(), 0);
    } else {
      this.startDateInput?.nativeElement?.focus();
    }
  }

  emitOptionChange() {
    if (!this.dateRangeForm.value.start || !this.dateRangeForm.value.end) {
      return;
    }
    this.customDateOption.emit(this.dateRangeForm.value);
  }

  getTimeRange(option) {
    const now = DateTime.now();
    let start;
    let end;

    switch (option) {
      case 'today': {
        start = now.startOf('day');
        end = now.endOf('day');
        break;
      }
      case 'tomorrow': {
        const tomorrow = now.plus({day: 1});
        start = tomorrow.startOf('day');
        end = tomorrow.endOf('day');
        break;
      }
      case 'last_week': {
        const lastWeekDay = now.startOf('week').plus({day: -1});
        start = lastWeekDay.startOf('week');
        end = lastWeekDay.endOf('week');
        break;
      }
      case 'next_week': {
        const nextWeekDay = now.endOf('week').plus({day: 1});
        start = nextWeekDay.startOf('week');
        end = nextWeekDay.endOf('week');
        break;
      }
      case 'last_month': {
        const lastMonthDay = now.startOf('month').plus({day: -1});
        start = lastMonthDay.startOf('month');
        end = lastMonthDay.endOf('month');
        break;
      }
      case 'next_month': {
        const nextMonthDay = now.endOf('month').plus({day: 1});
        start = nextMonthDay.startOf('month');
        end = nextMonthDay.endOf('month');
        break;
      }
    }

    return {
      start: start.toISO(),
      end: end.toISO()
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'book-with-pro.html'
})
export class BookWithProPage implements OnInit {

  address: any;
  bookingType: any;
  proName: any;
  proId: any;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Book Job');
    this.loadData();
  }

  async loadData() {
    this.address = await this.getParam('address');
    this.proName = await this.getParam('proName');
    this.proId = await this.getParam('proId');
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = await this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async goToReschedulePlan() {
    const address = this.address;
    const bookingType = 'reschedule_plan';
    const isPrivatePro = await this.getParam('isPrivatePro');
    const proFirstName = await this.getParam('proFirstName');
    const pros = await this.getParam('pros');
    const params = { address, bookingType, hkId: this.proId, filterByPro: true, isPrivatePro, proFirstName, pros };
    const bookingKey = await this.getParam('bookingKey');
    if (bookingKey) {
      params['bookingKey'] = bookingKey;
    }
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async goToAddOneTimeJob() {
    const address = this.address;
    const bookingType = 'add_one_time_job';
    const isPrivatePro = await this.getParam('isPrivatePro');
    const proFirstName = await this.getParam('proFirstName');
    const pros = await this.getParam('pros');
    const params = { address, bookingType, hkId: this.proId, filterByPro: true, isPrivatePro, proFirstName, pros };
    const bookingKey = await this.getParam('bookingKey');
    if (bookingKey) {
      params['bookingKey'] = bookingKey;
    }
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

}

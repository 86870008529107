<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Reservations'"
    [showLinkIcon]="true"
    [linkIcon]="'add-circle'"
    (linkAction)="goToAddReservation()">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Reservations'">
      </tidy-text>
      <ng-container *ngIf="!isDataLoaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 675px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
      <ng-container *ngIf="isDataLoaded">
        <form [formGroup]="form" novalidate action="#">
          <tidy-select
            class="desktop-header-field"
            [items]="addressFilter"
            [multiple]="false"
            [form]="form.get('selectedAddress')"
            formControlName="selectedAddress"
            (optionChange)="onFilterValueChange($event, 'address')">
          </tidy-select>
        </form>
        <tidy-button
          [action]="goToAddReservation.bind(this)"
          [image]="'assets/svg/add-circle-outline-white.svg'"
          [text]="'Add Reservation'"
          [smallBody]="true"
          class="desktop-header-button primary-button">
        </tidy-button>
      </ng-container>
    </tidy-desktop-header>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!windowService.isDesktopRes">
        <div class="custom-desktop-header" style="padding-top: 5rem;">
          <ng-container *ngIf="!isDataLoaded">
            <div style="width: 100%">
              <ion-skeleton-text
                animated="true"
                style="height: 40px; border-radius: 6px"></ion-skeleton-text>
            </div>
          </ng-container>
          <ng-container *ngIf="isDataLoaded">
            <form [formGroup]="form" novalidate action="#" class="form-filters">
              <tidy-select
                class="bills-select-custom-input"
                [label]="'Property'"
                [items]="addressFilter"
                [multiple]="false"
                [form]="form.get('selectedAddress')"
                formControlName="selectedAddress"
                (optionChange)="onFilterValueChange($event, 'address')">
              </tidy-select>
            </form>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!isDataLoaded">
        <div class="table-placeholder">
          <div style="width: 100%">
            <ion-skeleton-text
              animated="true"
              style="height: 40px; border-radius: 6px"></ion-skeleton-text>
          </div>
          <div style="width: 100%">
            <ion-skeleton-text
              animated="true"
              style="height: 500px; border-radius: 6px"></ion-skeleton-text>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isDataLoaded">
        <tidy-card *ngIf="currentRows?.length > 0" class="no-padding">
          <tidy-table
            #tidyTable
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            [allRowIsClickable]="true"
            [rowsToShow]="25"
            [showColumnLines]="false"
            (sortChange)="sortChanged($event)"
            (cellClick)="onTableColumnClick($event)"
            (pageChange)="pageChange($event)"
            [totalRows]="totalRows"
            [infiniteScroll]="true">
          </tidy-table>
        </tidy-card>
      </ng-container>
      <ng-container *ngIf="isDataLoaded && currentRows?.length === 0">
        <tidy-alert>
          <tidy-row>
            <tidy-text [body]="'No Results'"> </tidy-text>
          </tidy-row>
        </tidy-alert>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content"></app-scroll-wrapper>
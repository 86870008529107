import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  storedRoutes = new Map<string, DetachedRouteHandle>();
  shouldDetachRoutes = [
    'dashboard',
    'schedule',
    'messages',
    'tasks',
    'all-to-do-lists',
    'inventory',
    'bills',
    'my-pros',
    'automations/summary',
    'more-desktop'
  ]

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (window.innerWidth <= 870) {
      return false;
    }
    return this.shouldDetachRoutes.includes(route.routeConfig?.path);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.routeConfig?.path && handle) {
      const now = new Date().getTime();
      localStorage.setItem(route.routeConfig.path + '.lastStore', now.toString());
      this.storedRoutes.set(route.routeConfig.path, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routePath = route.routeConfig?.path;
    if (!routePath) return false;
    const lastStore = localStorage.getItem(`${routePath}.lastStore`);
    if (!lastStore) return false;
    const now = new Date().getTime();
    const timeSinceDetach = now - parseInt(lastStore);
    return timeSinceDetach < 3 * 60 * 60 * 1000 && this.storedRoutes.has(routePath);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routePath = route.routeConfig?.path;
    if (!routePath) return null;
    return this.storedRoutes.get(routePath) || null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
<ng-template #content>
  <tidy-wrapper
    (contentHeight)="contentHeight = $event"
    [class]="windowService?.isDesktopRes ? 'full-width-desktop-padding-pro-settings' : 'fix-mobile-padding'">
    <ng-container *ngIf="loaded">

      <form [formGroup]="form">
        <tidy-desktop-header>
          <ion-img
            [src]="'assets/svg/arrow-back-black.svg'"
            class="desktop-back-icon"
            (click)="goBack()">
          </ion-img>
          <tidy-text
            [title]="'Pro Settings'">
          </tidy-text>
          <div>
            <tidy-select
              class="pro-settings-select-custom-input"
              [label]="'Pro'"
              [items]="proList"
              [multiple]="true"
              [form]="form.get('selectedPro')"
              formControlName="selectedPro"
              (optionChange)="onFilterValueChange($event, 'pro')">
            </tidy-select>
          </div>
          <div>
            <tidy-select
              class="pro-settings-select-custom-input"
              [label]="'Property'"
              [items]="addressFilter"
              [multiple]="true"
              [form]="form.get('selectedAddress')"
              formControlName="selectedAddress"
              (optionChange)="onFilterValueChange($event, 'address')">
            </tidy-select>
          </div>
          <div>
            <tidy-select
              class="pro-settings-select-custom-input"
              [label]="'Service Category'"
              [items]="serviceCategoryItems"
              [multiple]="true"
              [form]="form.get('selectedServiceCategory')"
              formControlName="selectedServiceCategory"
              (optionChange)="onFilterValueChange($event, 'serviceCategory')">
            </tidy-select>
          </div>
        </tidy-desktop-header>

        <!--<mat-menu #menu="matMenu" class="filter-menu">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
                <mat-checkbox
                  class="checkbox"
                  [checked]="getNodeChecked(node)"
                  (click)="$event.stopPropagation()"
                  (change)="checkNode(node, $event)">
                  {{ node.name | titlecase }}
                </mat-checkbox>
              </button>
            </mat-tree-node>
            <mat-tree-node
              *matTreeNodeDef="let node; when: hasChild"
              matTreeNodePadding>
              <button
                class="node-button-padding"
                mat-menu-item
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
                {{ node.name | titlecase }}
              </button>
            </mat-tree-node>
          </mat-tree>
        </mat-menu>

        <div>
          <ng-container *ngFor="let chip of chips">
            <ion-chip class="filter-chip">
              <ion-label>{{ chip.displayParentName | titlecase }}: {{ chip.name | titlecase }}</ion-label>
              <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
            </ion-chip>
          </ng-container>
        </div>-->

        <tidy-row *ngIf="errorMessage">
          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>
        </tidy-row>

        <ion-card class="form-card">
          <ion-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Select Field(s) to Edit'" class="select-fields-text">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>

            <ion-row>
              <ion-col class="settings-col" size="12" size-lg="3">
                <tidy-input
                  class="settings-input"
                  formControlName="serviceName"
                  [form]="form.controls.serviceName"
                  [label]="'Edit Service Name'">
                </tidy-input>
              </ion-col>
              <ion-col class="settings-col" size="12" size-lg="3">
                <tidy-input
                  class="settings-input"
                  formControlName="defaultRate"
                  [form]="form.controls.defaultRate"
                  [label]="'Edit Default Rate'"
                  [mask]="'currencyMask'">
                </tidy-input>
              </ion-col>
              <ion-col class="settings-col" size="12" size-lg="3">
                <tidy-input
                  class="settings-input"
                  formControlName="autopayLimit"
                  [form]="form.controls.autopayLimit"
                  [label]="'Edit Autopay Limit'"
                  [mask]="'currencyMask'">
                </tidy-input>
              </ion-col>
              <ion-col class="settings-col" size="12" size-lg="3">
                <tidy-button
                  [text]="'Save'"
                  [action]="saveSettings.bind(this)"
                  class="primary save-pro-settings tall-button">
                </tidy-button>
              </ion-col>
            </ion-row>
        </ion-card>
        <tidy-row *ngIf="selectedRowsErrorMessage" class="extra-top-padding">
          <tidy-error-message
            [text]="'Please select row(s) to edit.'">
          </tidy-error-message>
        </tidy-row>
        <tidy-row [style.display]="didLoadTable ? 'block' : 'none'" *ngIf="rows?.length > 0">
          <tidy-card class="no-padding">
            <tidy-table
              #tidyTable
              [dataSource]="shownRows"
              [displayedColumns]="headers"
              [columnslabels]="headers"
              [rowsToShow]="25"
              (sortChange)="sortChanged($event)"
              (selectedRowsChange)="selectedRowsChange($event)"
              (unselectedRowsChange)="unselectedRowsChange($event)"
              (pageChange)="pageChange($event)"
              [infiniteScroll]="true"
              [totalRows]="totalRows">
            </tidy-table>
          </tidy-card>
        </tidy-row>
        <ng-container *ngIf="rows?.length == 0">
          <tidy-divider class="divider-margin"></tidy-divider>
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'You haven\'t added any private pros yet.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-card style="margin-top: 1.5rem; padding: 0px; height: 1000px; padding: 0px;" *ngIf="!didLoadTable">
          <ion-skeleton-text animated style="width: 100%; height: 1000px"></ion-skeleton-text>
        </tidy-card>
      </form>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <ion-skeleton-text animated style="margin-left: -20px; width: 100%; position: absolute; height: 100%; top: 0; bottom: 0"></ion-skeleton-text>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content"
  [desktopWidthContent]="desktopWidthContent">
</app-scroll-wrapper>

<tidy-card>
  <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
    <tidy-text
      [header]="'CSV Template'">
    </tidy-text>
    <tidy-text
      [actionBody]="'Download Example'"
      class="link"
      (action)="downloadCSVTemplate()">
    </tidy-text>
  </tidy-row>
  <tidy-row>
    <tidy-text
      [body]="'Address, Unit, City, Zip, Nickname, Country Code'">
    </tidy-text>
  </tidy-row>
</tidy-card>

<tidy-card *ngIf="!loadedProperties">
  <tidy-row [alignLastItemRight]="true">
    <tidy-image
      [src]="'assets/img/csv.svg'"
      class="title-size">
    </tidy-image>
    <tidy-text
      [header]="'Import CSV'">
    </tidy-text>
    <tidy-text
      (action)="openFilePicker()"
      [body]="'Select CSV'"
      class="link">
    </tidy-text>
  </tidy-row>
</tidy-card>

<ng-container *ngIf="isLoadingProperties">
  <tidy-row [align]="'center'">
    <ion-spinner style="width: 50px" class="sc-ion-loading-ios spinner-lines hydrated"></ion-spinner><br>
    <tidy-text
      [body]="'Importing properties. This may take some time.'">
    </tidy-text>
  </tidy-row>
</ng-container>

<tidy-error-message
  *ngIf="showNotCSVError"
  [text]="'Please import a CSV file.'">
</tidy-error-message>

<ng-container *ngIf="loadedProperties">

  <tidy-card>
    <tidy-row class="extra-bottom-padding vertical-align-center">
      <tidy-image
        [src]="'assets/img/csv.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Import CSV'">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="properties?.length" class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/checkmark.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="properties?.length">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'properties ready to be added!'">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="errorProperties?.length || alreadyUploadedProperties?.length || alreadyAddedProperties?.length" class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/yellow-alert.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="'Skipped adding'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="(errorProperties?.length + alreadyUploadedProperties?.length + alreadyAddedProperties?.length)">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'properties'">
      </tidy-text>
    </tidy-row>
  </tidy-card>

  <tidy-error-message
    *ngIf="errorMessage"
    [text]="errorMessage">
    </tidy-error-message>

  <tidy-alert *ngIf="!properties?.length">
    <tidy-row>
      <tidy-text
        [body]="'None of your properties were imported successfully. Please download the template above to ensure you have the correct format. Leave the unit or nickname column blank if there is no unit.'">
      </tidy-text>
    </tidy-row>
  </tidy-alert>

  <tidy-alert *ngIf="errorProperties?.length > 0">
    <tidy-row>
      <tidy-image
        [src]="'assets/img/yellow-alert.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Error Uploading'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'Google Maps did not return any results for the following properties:'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="vertical-align-center" *ngFor="let property of errorProperties">
      <tidy-image
        [src]="'assets/img/home.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="property[0]">
      </tidy-text>
    </tidy-row>
  </tidy-alert>

  <tidy-alert *ngIf="alreadyUploadedProperties?.length > 0">
    <tidy-row>
      <tidy-image
        [src]="'assets/img/yellow-alert.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Already Added'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'We\'ll skip adding these properties because you already added them to TIDY:'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="vertical-align-center" *ngFor="let property of alreadyUploadedProperties">
      <tidy-image
        [src]="'assets/img/home.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="property[0]">
      </tidy-text>
    </tidy-row>
  </tidy-alert>

  <tidy-alert *ngIf="alreadyAddedProperties?.length > 0">
    <tidy-row>
      <tidy-image
        [src]="'assets/img/yellow-alert.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="'Duplicate Imports'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-bottom-padding">
      <tidy-text
        [body]="'You uploading the following properties twice so we\'ll only upload it once:'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="vertical-align-center" *ngFor="let property of alreadyAddedProperties">
      <tidy-image
        [src]="'assets/img/home.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="property[0]">
      </tidy-text>
    </tidy-row>
  </tidy-alert>

  <ng-container *ngIf="properties?.length > 0">
    <tidy-row>
      <tidy-text
        [header]="'Properties to Add:'">
      </tidy-text>
    </tidy-row>

    <tidy-card *ngFor="let property of properties; let i = index">
      <tidy-row [alignLastItemRight]="true">
        <tidy-image
          [src]="'assets/img/home.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="property[0]">
        </tidy-text>
        <tidy-text
          [actionBody]="'X'"
          (action)="removeProperty(i)"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'Unit:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          *ngIf="property[1] !== ''"
          [body]="property[1]">
        </tidy-text><span>&nbsp;</span>
        <tidy-text 
          *ngIf="property[1] == ''"
          [body]="'none'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'City:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="property[2]">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'Zip:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="property[3]">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'Nickname:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="property[4]">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'Country Code:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="property[5]">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center">
        <tidy-text
          [body]="'State:'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="property[6]">
        </tidy-text>
      </tidy-row>
    </tidy-card>
  </ng-container>
</ng-container>

<tidy-button
  [text]="'Add Properties'"
  class="primary"
  [action]="addProperties.bind(this)">
</tidy-button>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, Validators } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';

import { CameraProvider } from 'src/shared/providers/camera/camera';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from 'src/providers/genome/genome';
import { Logger } from 'src/providers/logger';
import { PhotoNotes } from "src/providers/photo-notes/photo-notes";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';

import { toDoAutocomplete } from 'src/shared/constants/toDoAutocomplete';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'add-to-do.html',
  providers: [PhotoCaptionService]
})

export class ToDosAddToDoPage implements OnInit {

  addressId: any;
  characteristicsArray: any = [];
  characteristicsChosen: any;
  createNewModel: boolean;
  customFieldTypeItems = [];
  customFieldForm: CustomFieldFormModel[] = [];
  estimatedTimes: TidySelectNumberValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  getInventoryAlertsForNewItem: boolean;
  isRightSideContent: boolean;
  itemModelItems: any;
  important = false;
  itemCategoryItems: any;
  itemInstallationDateItems: any;
  isAddingCustomFields: boolean;
  itemCharacteristics: any;
  loaded = false;
  numberOfRequiredObjects: number = 0;
  preSelectedRoom: number;
  photoUrls: PhotoNoteUrlModel[] = [];
  roomError: boolean;
  rooms: TidySelectNumberValueModel[];
  roomObjects: any;
  roomObjectsItems: any = [];
  roomsResponse: any;
  showAddItemForm: boolean;
  selectedListId: any;
  selectedRoomItems = [];
  selectedRooms = [];
  selectedGroups = [];
  selectedRoomObject: any;
  selectedFieldType: boolean;
  submitted: boolean;
  toDoAutoComplete = toDoAutocomplete;
  taskCategoryOptions: any;

  constructor(
    private camera: CameraProvider,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private logger: Logger,
    private photoNotes: PhotoNotes,
    private photoCaptionService: PhotoCaptionService,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      note: [''],
      time: [5, Validators.required],
      roomObject: [''],
      itemCategory: [''],
      itemYearInstalled: [''],
      itemModel: [''],
      itemNewModel: [''],
      itemCharacteristics: [''],
      itemInventoryMinQuantity: [''],
      itemInventoryCurrentQuantity: [''],
      itemCurrentQuantity: [''],
      itemMinQuantity: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add To-Do');
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.roomsResponse = this.navCtrl.getParam('roomsResponse') || dialogParams.roomsResponse;
    this.rooms = this.navCtrl.getParam('rooms') || dialogParams.rooms;
    this.addressId = this.navCtrl.getParam('addressId') || dialogParams.addressId;
    this.selectedListId = this.navCtrl.getParam('selectedListId') || dialogParams.selectedListId;
    this.buildEstimatedTimes();
    await this.buildCustomFieldTypeItems();
    await this.getRoomObjectItems();
    await this.getDataForAddItemForm();
    this.populateRoomCheckboxes();
    this.form.patchValue({roomObject: 'none'});
    this.loaded = true;
  }

  async getRoomObjectItems() {
    this.roomObjects = await this.genome.getRoomsObjects(this.addressId);
    this.roomObjectsItems = [];
    this.roomObjects.map((item) => {
      const viewValue = item.model.name ? item.model.name : item.model.category.name;
      this.roomObjectsItems.push({
        value: item.id,
        viewValue,
      });
    });
    this.roomObjectsItems.push({
      value: 'none',
      viewValue: 'None'
    });
    this.roomObjectsItems.push({
      value: 'newItem',
      viewValue: '+ Add New Item'
    });
  }

  async getDataForAddItemForm() {
    await this.getItemCategoryItems();
    this.itemInstallationDateItems = this.getItemInstallationDateItems();
  }

  async getItemCategoryItems() {
    const itemCategories = await this.genome.getCategories();
    this.itemCategoryItems = itemCategories.map((category) => {
      return {
        value: category.id,
        viewValue: category.name
      }
    });
  }

  getItemInstallationDateItems() {
    const array: any = [];
    let year = LuxonDateTime.local().year;
    for (let i = year; i > (year - 232); i--) {
      array.push({
        value: i,
        viewValue: i
      });
    }
    return array;
  }

  selectModel(model) {
    this.createNewModel = model == 'addNewModel';
  }

  async selectNewItemCategory(category) {
    this.createNewModel = false;
    this.itemModelItems = [];
    this.characteristicsChosen = {};
    const characteristics = await this.genome.getCharacteristics(category);
    this.itemCharacteristics = characteristics.map((characteristic) => {
      const forms = characteristic.characteristics.map((section) => {
        const formData = {
          value: section.id,
          viewValue: section.name
        };
        return formData;
      });
      this.characteristicsChosen[characteristic.name] = -1;
      const data = {
        name: characteristic.name,
        formData: forms
      };
      return data;
    });
    await this.getModels('');
    if (this.itemModelItems.length == 1) {
      this.createNewModel = true;
    }
  }

  async selectCharacteristic(characteristic, id) {
    this.characteristicsChosen[characteristic.name] = id;
    let query = this.buildQueryString(this.characteristicsChosen);
    await this.getModels(query);
    if (this.itemModelItems.length == 1) {
      this.createNewModel = true;
    }
  }

  buildQueryString(obj) {
    let finalQuery = '';
    let finalArray = [];
    for (var key in obj) {
      if (obj[key] != -1) {
        finalQuery += `&filters[characteristic_ids][]=${obj[key]}`;
        finalArray.push(obj[key]);
      }
    }
    this.characteristicsArray = finalArray;
    return finalQuery;
  }

  @Loading('', true)
  async getModels(query) {
    const models = await this.genome.getObjectModel(this.form.value.itemCategory, query);
    this.itemModelItems = models.map((model) => {
      const formData = {
        value: model.id,
        viewValue: model.model
      };
      return formData;
    });
    this.itemModelItems.push({
      viewValue: 'Add new model',
      value: 'addNewModel'
    });
    this.form.patchValue({itemModel: this.itemModelItems[0].value});
  }

  selectGetInventoryAlerts() {
    this.getInventoryAlertsForNewItem = !this.getInventoryAlertsForNewItem;
    if (this.getInventoryAlertsForNewItem) {
      this.form.controls.itemCurrentQuantity.setValidators([Validators.required]);
      this.form.controls.itemMinQuantity.setValidators([Validators.required]);
    } else {
      this.form.controls.itemCurrentQuantity.clearValidators();
      this.form.controls.itemCurrentQuantity.updateValueAndValidity();
      this.form.controls.itemMinQuantity.clearValidators();
      this.form.controls.itemMinQuantity.updateValueAndValidity();
    }
  }

  populateRoomCheckboxes() {
    const preSelectedRoom = localStorage.getItem('roomId');
    if (preSelectedRoom) {
      this.preSelectedRoom = parseFloat(preSelectedRoom);
      this.selectRoom(this.preSelectedRoom);
    }
  }

  buildEstimatedTimes()  {
    let times = [];
    for (let i = 1; i < 32; i++) {
      times.push({
        viewValue: i + ' minutes',
        value: i
      });
    }
    this.estimatedTimes = times;
  }

  async buildCustomFieldTypeItems() {
    this.taskCategoryOptions = await this.toDos.getTaskCategoryOptions();
    this.taskCategoryOptions.map((item) => {
      if (item.name !== 'Vacuum' && item.name !== 'Mop') {
        this.customFieldTypeItems.push(({
          viewValue: item.name,
          value: item.id
        }));
      }
    });
  }

  selectRoom(id) {
    this.getInventoryAlertsForNewItem = false;
    this.showAddItemForm = false;
    this.roomError = false;
    this.submitted = false;
    let alreadySelected = false;
    this.selectedRooms.find((room) => {
      if (id === room) {
        alreadySelected = true;
      };
    });
    this.selectedGroups.find((room) => {
      if (id === room) {
        alreadySelected = true;
      };
    });
    const isRoom = this.checkIfIsRoom(id);
    if (!alreadySelected) {
      if (isRoom) {
        this.selectedRooms.push(id);
      } else {
        this.selectedGroups.push(id);
      }
    } else {
      if (isRoom) {
        this.selectedRooms = this.selectedRooms.filter((room) => {
          if (id !== room) {
            return room;
          };
        });
      } else {
        this.selectedGroups = this.selectedGroups.filter((group) => {
          if (id !== group) {
            return group;
          };
        });
      }
    }
    this.selectedRoomItems = this.selectedRooms.map((roomId) => {
      return {
        value: roomId,
        viewValue: this.getRoomNameFromID(roomId)
      }
    });
  }

  getRoomNameFromID(roomId) {
    const room = this.roomsResponse.find((room) => room.id == roomId);
    return room.name;
  }

  checkIfIsRoom(id) {
    const room = this.roomsResponse.find((room) => room.id == id);
    return room.type !== 'group';
  }

  async addToDo() {
    try {
      this.roomError = false;
      this.submitted = true;
      if (this.selectedRooms.length === 0 && this.selectedGroups.length === 0) {
        this.roomError = true;
      }
      let hasFieldOptionError = false;
      this.customFieldForm.map((field) => {
        const hasNotSelectedFieldTypeYet = !field.hasOwnProperty('numberOfRequiredOptions');
        if (field.options.length < field.numberOfRequiredOptions || hasNotSelectedFieldTypeYet) {
          hasFieldOptionError = true;
        }
      });
      if (!this.form.valid || hasFieldOptionError || this.roomError) {
        return;
      }
      let payload = {
        address_id:            this.addressId,
        address_task_list_id:  this.selectedListId,
        title:                 this.form.value.title,
        note:                  this.form.value.note,
        estimated_time:        this.form.value.time,
        task_type:             'cleaning',
        task_category_options: [],
        is_important:          this.important
      }
      if (this.selectedRooms.length > 0) {
        payload['address_room_ids'] = this.selectedRooms;
      }
      if (this.selectedGroups.length > 0) {
        payload['address_task_group_ids'] = this.selectedGroups;
      }
      if (this.form.value.roomObject !== 'none') {
        const itemId = this.showAddItemForm ? await this.addItemAndGetRoomItemID() : this.form.value.roomObject.toString();
        payload['room_object_id'] = itemId;
      }
      const isAddingTakeInventoryForCurrentItem = this.getInventoryAlertsForNewItem && !this.showAddItemForm;
      if (isAddingTakeInventoryForCurrentItem) {
        await this.updateInventorySettingsForCurrentItem();
      }
      this.customFieldForm.map((field) => {
        let item = {
          id: field.form.value.type,
          notes: field.form.value.label,
          required: field.form.value.required
        }
        if (field.options.length > 0) {
          item['allowed_values'] = '';
          field.options.map((option) => {
            item['allowed_values'] += option.controls.option.value;
            item['allowed_values'] += ', ';
          });
        }
        if (item['allowed_values']) {
          item['allowed_values'] = item['allowed_values'].substring(0, item['allowed_values'].length - 2);
        }
        payload['task_category_options'].push(item);
      });
      const tasks = await this.toDos.addToDo(this.addressId, this.selectedListId, payload);
      await this.assignPhotoNotes(tasks);
      const route = localStorage.getItem('roomId') ? 'card' : 'to-dos';
      let dialogParams = await this.storage.retrieve('dialog-params');
      if (dialogParams?.room) dialogParams.room = null;
      await this.storage.save('dialog-params', dialogParams);
      this.rightSidePanelService.navigateTo(route);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async updateInventorySettingsForCurrentItem() {
    const data = {
      min_quantity: this.form.value.itemMinQuantity,
      current_quantity: this.form.value.itemCurrentQuantity
    }
    await this.genome.updateRoomObject(this.form.value.roomObject, data);
  }

  async addItemAndGetRoomItemID() {
    let data = {
      category_id: this.form.value.itemCategory,
      characteristic_ids: this.characteristicsArray,
      address_id: this.addressId,
      installation_date: `01/01/${this.form.value.itemYearInstalled}`,
      model_id: this.form.value.itemModel
    }
    if (this.getInventoryAlertsForNewItem) {
      data['min_quantity'] = this.form.value.itemMinQuantity;
      data['current_quantity'] = this.form.value.itemCurrentQuantity;
    }
    if (this.createNewModel) {
      const modelData = {
        category_id: this.form.value.itemCategory,
        model: this.form.value.itemNewModel,
        name: `${this.form.value.itemCategory} ${this.form.value.itemNewModel}`,
        characteristic_ids: this.characteristicsArray
      }
      const newModel = await this.genome.addNewModel(modelData);
      data.model_id = newModel.id;
    }
    const item = await this.genome.addRoomObject(data);
    return item.id.toString();
  }

  async takePhoto() {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg('task');
      this.photoUrls.push(newPhotoNote);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async assignPhotoNotes(tasks) {
    await Promise.all(tasks.map(async (task, index) => {
      if (this.selectedRooms[index]) {
        await this.uploadPhotos(this.selectedRooms[index], task.id, 'room');
      }
      if (this.selectedGroups[index]) {
        await this.uploadPhotos(this.selectedGroups[index], task.id, 'group');
      }
    }));
  }

  async uploadPhotos(roomId: string, taskId: number, roomType) {
    const photoNote = roomType == 'group' ?
      this.toDos.addGroupTaskPhotoNote.bind(this.toDos, roomId, taskId) :
      this.toDos.taskPhotoNotes.bind(this.toDos, roomId, taskId);
    await this.photoCaptionService.uploadPhotosGeneric(
      this.photoUrls,
      'task',
      photoNote,
      this.photoNotes.photoCaption.bind(this.photoNotes),
      roomType
    );
  }

  expandFields() {
    this.addCustomField();
    this.isAddingCustomFields = true;
  }

  addCustomField() {
    const formGroup = this.fb.group({
      type: ['', Validators.required],
      label: ['', Validators.required],
      required: [true, Validators.required],
      options: this.fb.array([])
    });
    this.customFieldForm.push({
      form: formGroup,
      options: [],
      objects: []
    });
  }

  addCustomCountField() {
    const formGroup = this.fb.group({
      type: ['', Validators.required],
      label: ['', Validators.required],
      required: ['', Validators.required],
      options: this.fb.array([])
    });
    this.customFieldForm.push({
      form: formGroup,
      options: [],
      objects: []
    });
    const taskCategory = this.taskCategoryOptions.find((category) => category.name == 'Take Inventory');
    this.selectFieldType(taskCategory.id, this.customFieldForm.length - 1);
    this.customFieldForm[this.customFieldForm.length - 1].form.patchValue({
      type: taskCategory.id,
      label: 'Count # of ' + (this.selectedRoomObject.model.name ? this.selectedRoomObject.model.name : this.selectedRoomObject.model.category.name) + 's'
    });
    this.isAddingCustomFields = true;
  }

  removeField(fieldIndex) {
    this.customFieldForm.splice(fieldIndex, 1);
    if (this.customFieldForm.length == 0) {
      this.isAddingCustomFields = false;
    }
  }

  selectFieldType(taskCategoryOptionId, i) {
    this.submitted = false;
    const selectedOption = this.taskCategoryOptions.find((option) => option.id == taskCategoryOptionId);
    this.customFieldForm[i]['numberOfRequiredOptions'] = 0;
    this.numberOfRequiredObjects = 0;
    if (selectedOption.name == 'Checkbox(es)') {
      this.customFieldForm[i]['numberOfRequiredOptions'] = 1;
    }
    if (selectedOption.name == 'Select') {
      this.customFieldForm[i]['numberOfRequiredOptions'] = 2;
    }
    if (selectedOption.name == 'Take Inventory') {
      this.numberOfRequiredObjects = 1;
    }
    if (selectedOption.name !== 'Checkbox(es)') {
      this.selectedFieldType = true;
    } else {
      this.selectedFieldType = false;
    }
  }

  addOption(index) {
    this.submitted = false;
    const formGroup = this.fb.group({
      option: ['', Validators.required]
    });
    this.customFieldForm[index].options.push(formGroup);
  }

  removeOption(customFieldFormIndex, optionIndex) {
    this.customFieldForm[customFieldFormIndex].options.splice(optionIndex, 1);
  }

  goToContactConciergePage() {
    const params = {
      title: 'To-Do Help',
      type: 'support.to_do_help',
      metadata: {
        suptype: 'to_do_help'
      }
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  goToAddressPage() {
    this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
  }

  selectRoomObject(selection) {
    this.selectedRoomObject = null;
    this.showAddItemForm = false;
    if (selection.value == 'newItem') {
      this.form.patchValue({
        itemCategory: this.itemCategoryItems[0].value,
        itemYearInstalled: this.itemInstallationDateItems[0].value
      });
      this.selectNewItemCategory(this.form.value.itemCategory);
      this.showAddItemForm = true;
      return;
    }
    if (selection.value == 'none') {
      return;
    }
    this.roomObjects.map((item) => {
      if (item.id == selection.value) {
        this.selectedRoomObject = item;
      }
    });
  }

  selectRequired(form, selection) {
    form.form.patchValue({required: !selection});
  }

  toggleShowAddItemForm() {
    this.showAddItemForm = !this.showAddItemForm;
    if (this.showAddItemForm) {
      this.form.patchValue({roomObject: 'newItem'});
      this.selectRoomObject({value: 'newItem'});
    } else {
      this.form.patchValue({roomObject: 'none'});
      this.selectRoomObject({value: 'none'});
    }
  }

}

interface CustomFieldFormModel {
  form: UntypedFormGroup;
  options: any;
  objects: any;
  optionsError?: boolean;
  numberOfRequiredOptions?: number;
  numberOfRequiredObjects?: number;
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="loaded ? getPageTitle() : ''"
    [showLinkIcon]="!isAddingWorkflow"
    [linkIcon]="'trash'"
    (linkAction)="deleteWorkflow()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="full-page">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-row *ngIf="!isSavingDraft">
        <ng-container *ngIf="!didMakeNewDraft">
          <tidy-text
            *ngIf="!isAddingWorkflow"
            [body]="'Edit a field to save a new draft.'">
          </tidy-text>
          <tidy-text
            *ngIf="isAddingWorkflow"
            [body]="'Edit a field to make a draft.'">
          </tidy-text>
        </ng-container>
        <tidy-text
          *ngIf="didMakeNewDraft"
          [body]="'Draft Saved.'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="isSavingDraft">
        <tidy-text
          [body]="'Saving Draft...'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <ng-container>
          <form [formGroup]="workflowForm" novalidate action="#">
            <tidy-row>
              <tidy-input
                label="Workflow Title"
                formControlName="name"
                [form]="workflowForm.get('name')"
                [submitted]="submitted"
                [errorMessage]="'Please enter a title.'"
                outsideClick>
              </tidy-input>
            </tidy-row>
          </form>
        </ng-container>
      </tidy-card>

      <tidy-card>
          <tidy-drop-down-menu style="position: absolute; right: 10px; top: 0" *ngIf="selectedTrigger">
            <tidy-drop-down-menu-item
              icon="edit"
              (click)="changeTrigger()">
              Change Trigger
            </tidy-drop-down-menu-item>
          </tidy-drop-down-menu>
          <ng-container *ngIf="selectedTrigger">
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <div class="trigger-title-container">
                <tidy-image
                  [src]="'assets/img/play.svg'"
                  class="title-size">
                </tidy-image>
                <div>
                  <tidy-text
                    [header]="'Trigger'">
                  </tidy-text>
                </div>
              </div>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="selectedTrigger.icon_url"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="selectedTrigger.name">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="selectedTrigger.description">
              </tidy-text>
            </tidy-row>
            <ng-container>
              <ng-container *ngIf="shouldDisplayTriggerFiltersText()">
              <tidy-row class="extra-top-padding">
                <strong>
                  {{'Trigger Filters' | translate}}
                </strong>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="getTriggerFilterHeaderText()">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <form [formGroup]="workflowVersionForm.get('trigger_data').get('input_values')">
                <ng-container *ngFor="let input of selectedTrigger?.inputs">
                  <tidy-row *ngIf="input.form.type === 'number'">
                    <tidy-input
                      [label]="input.form.label"
                      [formControlName]="input.key"
                      [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key)"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter a number'"
                      [type]="input.form.type">
                    </tidy-input>
                  </tidy-row>
                  <tidy-row *ngIf="input.form.type === 'multi_select' || input.key === 'assigned_to_concierge'" class="radio-row">
                    <tidy-radio-button
                      [label]="getFilterNameInSingular(input.form.label) + ' Filter:'"
                      [formControlName]="input.key + '-radio'"
                      [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key + '-radio')"
                      (optionChange)="onRadioOptionChange($event, workflowVersionForm.get('trigger_data').get('input_values').get(input.key))"
                      [items]="radioItems[input.form.label]">
                    </tidy-radio-button>
                  </tidy-row>
                  <tidy-row *ngIf="input.key !== 'assigned_to_concierge' && (input.form.type === 'select' || input.form.type === 'multi_select')">
                    <ng-container *ngIf="input.form.type === 'multi_select' ? workflowVersionForm.get('trigger_data').get('input_values').get(input.key + '-radio')?.value !== '' : true">
                      <tidy-select
                        #tidySelect
                        [label]="input.form.label.toLowerCase().includes('address') ? 'Properties' : input.form.label"
                        [items]="input.items"
                        [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key)"
                        [formControlName]="input.key"
                        [multiple]="input.form.type === 'multi_select'"
                        [class]="input.form.type === 'multi_select' ? 'extra-top-padding' : ''">
                      </tidy-select>
                    </ng-container>
                  </tidy-row>
                  <tidy-row *ngIf="input.key == 'assigned_to_concierge' && workflowVersionForm.get('trigger_data').get('input_values').get(input.key + '-radio')?.value !== ''">
                    <tidy-select
                      #tidySelect
                      [label]="'Select Option'"
                      [items]="input.items"
                      [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key)"
                      [formControlName]="input.key">
                    </tidy-select>
                  </tidy-row>
                  <tidy-row *ngIf="input.form.type === 'text' && input.key !== 'email_prefix'">
                    <tidy-input
                      [label]="input.label"
                      [formControlName]="input.key"
                      [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key)">
                    </tidy-input>
                  </tidy-row>
                  <tidy-row *ngIf="input.form.type === 'text' && input.key === 'email_prefix'">
                    <tidy-input
                      [textSuffix]="emailSuffix"
                      [label]="input.form.label"
                      [formControlName]="input.key"
                      [form]="workflowVersionForm.get('trigger_data').get('input_values').get(input.key)"
                      [buttonIcon]="'content_copy'"
                      [isInputEmpty]="!workflowVersionForm.get('trigger_data').get('input_values').get(input.key).value"
                      [action]="copyEmail.bind(this, workflowVersionForm.get('trigger_data').get('input_values').get(input.key).value)">
                    </tidy-input>
                  </tidy-row>
                </ng-container>
              </form>
            </ng-container>
          </ng-container>

          <!-- New trigger -->
          <ng-container *ngIf="!selectedTrigger">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/play.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Trigger'">
                </tidy-text>
              </tidy-row>
              <tidy-row [formGroup]="searchForm">
                <tidy-input
                  #thisInput
                  label="Search Triggers"
                  icon="assets/ionic-icon-v3/ios-search-outline.svg"
                  activeIcon="src/assets/ionic-icon-v3/ios-search-outline.svg"
                  [form]="searchForm.get('title')"
                  (keyup)="searchTriggers(thisInput.value)"
                  formControlName="title"
                  class="title-input">
                </tidy-input>
              </tidy-row>

              <ng-container *ngFor="let pTrigger of filteredTriggers; let triggerIndex = index">
                <ng-container *ngIf="pTrigger?.data">
                  <tidy-row class="extra-top-padding vertical-align-middle">
                    <tidy-image
                      [src]="pTrigger.data[0].icon_url">
                    </tidy-image>
                    <tidy-text
                      [body]="pTrigger?.category">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngFor="let item of pTrigger.data; let even = even; let index = index">
                    <tidy-row class="vertical-align-center">
                      <tidy-text
                        (action)="selectTrigger(item)"
                        [actionBody]="item.name"
                        class="link">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!filteredTriggers[0]?.data">
                <ng-container *ngFor="let item of filteredTriggers; let even = even; let first = first">
                  <tidy-row [ngClass]="first ? 'extra-top-padding' : ''">
                    <tidy-text [body]="'+ '">
                    </tidy-text>
                    <tidy-text (action)="selectTrigger(item)" [actionBody]="item.name" class="link">
                    </tidy-text>
                  </tidy-row>
                </ng-container>
              </ng-container>
          </ng-container>
      </tidy-card>

      <tidy-alert *ngIf="!selectedTrigger">
        <tidy-row>
          <tidy-text
            [body]="'Select a trigger to add actions.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <!-- ACTIONS -->
      <ng-container *ngIf="selectedTrigger">
        <ng-container *ngFor="let action of selectedActions; let actionIndex = index;">
          <tidy-card>
            <tidy-row class="extra-bottom-padding">
              <tidy-drop-down-menu style="position: absolute; right: 10px; top: 0" (closed)="logMenuClosed($event, actionIndex)">
                <tidy-drop-down-menu-item icon="delete_outline" (click)="deleteAction(actionIndex)">Remove Action</tidy-drop-down-menu-item>
              </tidy-drop-down-menu>
              <div style="display: flex; align-items: end;">
                <tidy-image
                  [src]="'assets/img/check.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Action'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [header]="(actionIndex + 1)">
                </tidy-text>
                <tidy-input *ngIf="(action.state == 'edit')"
                  label="Title"
                  formControlName="name"
                  [form]="getActionForm(actionIndex).get('name')"
                  (outsideClick)="action.state = null"
                  outsideClick>
                </tidy-input>
              </div>
            </tidy-row>
            <form [formGroup]="getActionForm(actionIndex)" novalidate action="#">

              <ng-container *ngIf="getActionForm(actionIndex).value.id">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="action.icon_url"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    body="{{getActionForm(actionIndex).value.name}}">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="action.description">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-top-padding">
                  <ng-container *ngFor="let input of action?.inputs">
                    <ng-container *ngIf="input?.form?.type === 'filter'">
                      <filter-nested-form
                        [filterGroups]="action?.input_values?.filter_groups"
                        [outputs]="action?.parsedOutputs"
                        [inputOptions]="action?.inputOptions"
                        (formValues)="onFormValuesChange($event, actionIndex)">
                      </filter-nested-form>
                    </ng-container>
                    <ng-container *ngIf="input?.form?.type !== 'filter'">
                      <tidy-row [formGroup]="getActionForm(actionIndex).get('input_values')">
                        <tidy-select *ngIf="input.form.type === 'multiselect'"
                          [label]="input.form.label"
                          [items]="input.options"
                          [form]="getActionForm(actionIndex).get('input_values').get(input.key)"
                          [formControlName]="input.key">
                        </tidy-select>

                      <ng-container *ngIf="input.form.type !== 'files'; else filesTemplate">
                        <tidy-chip-text-area
                          [id]="getActionForm(actionIndex).get('formId').value"
                          [inputKey]="input.key"
                          [inputTitle]="input.form.label"
                          [isRequired]="input.required"
                          (inputChange)="onInputChange($event)"
                          (inputFocusIn)="setSelectedChipTextArea(input.key)">
                        </tidy-chip-text-area>
                      </ng-container>

                      <ng-template #filesTemplate>
                        <tidy-row style="margin-top: 1rem;">
                          <tidy-text
                            [actionBody]="'Add Attachment'"
                            (action)="addAttachment(action)"
                            class="link">
                          </tidy-text>
                        </tidy-row>
                        <ion-row *ngIf="action?.input_values?.attachments?.length > 0">
                          <ion-col size="12" sizeMd="6" sizeLg="4" sizeXl="3" *ngFor="let attachment of action?.input_values?.attachments; let i = index">
                            <div
                              style="width: 100%"
                              class="image-preview">
                              <img [src]="attachment" />
                              <div class="remove-image-button">
                                <button
                                  matSuffix
                                  mat-icon-button
                                  mat-mini-fab
                                  (click)="removeAttachment(action, i)">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </div>
                            </div>
                          </ion-col>
                        </ion-row>
                      </ng-template>

                        <ng-container *ngIf="selectedChipTextAreaId === input.key">
                          <ng-container *ngFor="let output of action?.outputs; let outputIndex = index">
                            <div (click)="toggleShowOutputOptions(actionIndex, outputIndex)" class="output-div">
                              <tidy-row class="extra-top-padding vertical-align-middle">
                                <tidy-text
                                  [body]="output?.title">
                                </tidy-text>
                                <tidy-image
                                  style="margin-left: 5px"
                                  [src]="output?.showOptions ? 'assets/img/play-grey-up.svg' : 'assets/img/play-grey-down.svg'"
                                  class="body-size">
                                </tidy-image>
                              </tidy-row>
                            </div>
                            <ng-container *ngIf="output?.showOptions">
                              <ng-container *ngFor="let item of output?.array; let even = even; let index = index; let first = first">
                                <tidy-row [ngClass]="first ? 'extra-top-padding' : ''">
                                  <tidy-text
                                    [body]="'+ '">
                                  </tidy-text>
                                  <tidy-text
                                    (click)="addChipPlaceholder(item, getActionForm(actionIndex).get('formId').value, input.key)"
                                    [actionBody]="item.plain_title || item.title"
                                    class="link">
                                  </tidy-text>
                                  <tidy-text
                                    [body]="(' (eg: ' | translate) + item.example + ')'">
                                  </tidy-text>
                                  <ng-container *ngIf="item.description">
                                    <button matTooltip="{{item.description | translate}}" mat-button style="margin-left: 5px; width: 10px !important; min-width: unset!important; background: none; color: inherit; border: none; padding: 0; font: inherit; cursor: pointer; outline: inherit;">
                                      <tidy-text
                                        [helper]="'?'">
                                      </tidy-text>
                                    </button>
                                  </ng-container>
                                </tidy-row>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </tidy-row>
                    </ng-container>
                  </ng-container>
                </tidy-row>
              </ng-container>

              <!-- New action -->
              <ng-container *ngIf="!getActionForm(actionIndex).value.id">
                <tidy-row class="extra-bottom-padding">
                  <tidy-row [formGroup]="searchForm">
                    <tidy-input
                      #thisInput
                      label="Search"
                      icon="assets/ionic-icon-v3/ios-search-outline.svg"
                      activeIcon="src/assets/ionic-icon-v3/ios-search-outline.svg"
                      [form]="searchForm.get('title')"
                      (keyup)="searchActions(thisInput.value)"
                      formControlName="title"
                      class="title-input">
                    </tidy-input>
                  </tidy-row>
                  <ng-container *ngFor="let pAction of filteredActions">
                    <ng-container *ngIf="pAction?.data">
                      <tidy-row class="extra-top-padding vertical-align-middle">
                          <tidy-image
                            [src]="pAction.data[0].icon_url">
                          </tidy-image>
                          <tidy-text
                            [body]="pAction?.category">
                          </tidy-text>
                        </tidy-row>
                      <ng-container *ngFor="let item of pAction.data; let even = even; let index = index">
                        <tidy-row class="vertical-align-center">
                          <tidy-text
                            (action)="selectActionType(item, actionIndex)"
                            [actionBody]="item.name"
                            class="link">
                          </tidy-text>
                        </tidy-row>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!filteredActions[0]?.data">
                    <ng-container *ngFor="let item of filteredActions; let even = even; let first = first">
                      <tidy-row [ngClass]="first ? 'extra-top-padding' : ''">
                        <tidy-text
                          (action)="selectActionType(item, actionIndex)"
                          [actionBody]="item.name"
                          class="link">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>
                  </ng-container>
                </tidy-row>
              </ng-container>

            </form>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-row *ngIf="selectedTrigger" [align]="'center'">
        <tidy-text
          [actionBody]="'+ Add Action'"
          (action)="addAction()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-button
          (click)="$event.stopPropagation()"
          text="Publish"
          [action]="publishWorkflowVersion.bind(this)"
          [disabled]="publishedVersionIsLatestVersion"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row *ngIf="!isAddingWorkflow" [align]="'center'">
        <tidy-text
          [body]="'Delete Workflow'"
          class="red link"
          (action)="deleteWorkflow()">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="publishedVersionIsLatestVersion" [align]="'center'">
        <tidy-text
          [body]="'This version has already been published. Make changes to publish a new version.'">
        </tidy-text>
      </tidy-row>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [title]="pageTitle"
    [class]="extraClass"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="right-side-scroll-enabled">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="task?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row class="no-padding">
          <tidy-image
            [src]="getIssueHeaderIcon(task)"
            class="urgency-icon">
          </tidy-image>
          <tidy-text
            [body]="issueAddress?.address_name || issueAddress?.address1 + (issueAddress?.address2 ? ', ' + issueAddress.address2 : '')">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="loadingActionSuggestions">
        <tidy-row>
          <tidy-text
            [body]="'<i>Generating Concierge Action Plan</i>'">
          </tidy-text>
        </tidy-row>
        <div class="generating-action-plan generating">
          <div class="step">
            <tidy-image
              class="step__icon"
              src="assets/img/bow-chip.svg"
              style="width: 40px; height: 50px">
            </tidy-image>
            <tidy-text
              body="<i>Analyzing Task...</i>">
            </tidy-text>
          </div>
          <div class="step">
            <tidy-image
              class="step__icon"
              src="assets/img/bow-chip.svg"
              style="width: 40px; height: 50px">
            </tidy-image>
            <tidy-text
              body="<i>Analyzing Similar Action Plans...</i>">
            </tidy-text>
          </div>
          <div class="step">
            <tidy-image
              class="step__icon icon-pulse"
              src="assets/img/bow-chip.svg"
              style="width: 40px; height: 50px">
            </tidy-image>
            <tidy-text
              body="<i>Generating Action Plan...</i>">
            </tidy-text>
          </div>
        </div>
      </ng-container>

      <tidy-row *ngIf="!loadingActionSuggestions">
        <tidy-text
          [header]="'Concierge Action Plan:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let suggestion of conciergeActionSuggestions?.suggestions; let i = index">
        <div class="action-flex-row" *ngIf="editingSuggestion !== suggestion">
          <div class="first-column">
            <tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                class="step__icon"
                src="assets/img/concierge.svg"
                style="width: 25px; height: 20px">
              </tidy-image>
              <tidy-text
                [header]="suggestion.title">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="suggestion.description">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="suggestion.scheduled == 'Immediate' || suggestion.scheduled == 'immediate' ? '<i>Scheduled to do immediately</i>' : '<i>Scheduled to do on ' + (suggestion.scheduled | customdate: 'M/D/Y') + '</i>'">
              </tidy-text>
            </tidy-row>
          </div>
          <div class="second-column">
            <tidy-row class="vertical-align-center">
              <tidy-image
                class="step__icon edit-delete-icon header-size"
                src="assets/svg/create-outline.svg"
                (action)="editSuggestion(suggestion)">
              </tidy-image>
              <tidy-image
                class="step__icon edit-delete-icon header-size"
                src="assets/svg/trash-outline.svg"
                (action)="deleteSuggestion(i)">
              </tidy-image>
            </tidy-row>
          </div>
        </div>
        <ng-container *ngIf="editingSuggestion == suggestion">
          <form [formGroup]="editSuggestionForm">
            <tidy-row>
              <tidy-textarea
                label="Title"
                [submitted]="submitted"
                [errorMessage]="'Please enter a description'"
                [form]="editSuggestionForm.get('title')"
                formControlName="title">
              </tidy-textarea>
            </tidy-row>
            <tidy-row>
              <tidy-textarea
                label="Description"
                [submitted]="submitted"
                [errorMessage]="'Please enter a description'"
                [form]="editSuggestionForm.get('description')"
                formControlName="description">
              </tidy-textarea>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'When should concierge do this?'"
                formControlName="scheduled"
                [form]="editSuggestionForm.get('scheduled')"
                (optionChange)="selectWhenConciergeShouldDo($event, suggestion)"
                [items]="[{value: 'Immediate', viewValue: 'Immediately'}, {value: 'Scheduled', viewValue: 'Scheduled for a date'}]">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row *ngIf="suggestion?.scheduled !== 'Immediate'" class="extra-bottom-padding">
              <tidy-datepicker
                label="Select date"
                [icon]="'assets/svg/calendar-outline.svg'"
                formControlName="date"
                [form]="editSuggestionForm.get('date')"
                errorMessage="Please select a date."
                [submitted]="submitted">
              </tidy-datepicker>
            </tidy-row>
            <tidy-row>
              <tidy-button
                text="Save"
                [action]="confirmEditSuggestion.bind(this)"
                class="secondary">
              </tidy-button>
            </tidy-row>
            <tidy-row>
              <tidy-button
                text="Cancel"
                [action]="cancelEditSuggestion.bind(this)"
                class="secondary">
              </tidy-button>
            </tidy-row>
          </form>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="isAddingAction">
        <form [formGroup]="editSuggestionForm">
          <tidy-row>
            <tidy-textarea
              label="Title"
              [submitted]="submitted"
              [errorMessage]="'Please enter a description'"
              [form]="editSuggestionForm.get('title')"
              formControlName="title">
            </tidy-textarea>
          </tidy-row>
          <tidy-row>
            <tidy-textarea
              label="Description"
              [submitted]="submitted"
              [errorMessage]="'Please enter a description'"
              [form]="editSuggestionForm.get('description')"
              formControlName="description">
            </tidy-textarea>
          </tidy-row>
          <tidy-row>
            <tidy-button
              text="Save"
              [action]="confirmEditSuggestion.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
          <tidy-row>
            <tidy-button
              text="Cancel"
              [action]="cancelEditSuggestion.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </form>
      </tidy-card>

      <ng-container *ngIf="!loadingActionSuggestions">
        <tidy-row [align]="'center'" *ngIf="!isAddingAction">
          <tidy-text
            [actionBody]="'Add Action'"
            (action)="addActionPlanStep()"
            class="link">
          </tidy-text>
        </tidy-row>
  
        <tidy-button
          text="Create Concierge Action Plan"
          [action]="createConciergeActionPlan.bind(this)"
          class="primary"
          [disabled]="editSuggestionForm">
        </tidy-button>
  
        <tidy-row [align]="'center'">
          <tidy-text
            actionBody="Skip For Now"
            (action)="skipActionPlan()"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcurrency'
})
export class TidyCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    const prefix = '$';
    const minusPrefix = '-$';
    const normalizedValue = Math.sqrt( value * value );
    const valueWithDecimal = normalizedValue / 100;

    const strValue = value % 100 > 0 ? valueWithDecimal.toFixed(2) : valueWithDecimal;
    const strValueWithCommas = this.addCommas(strValue);

    if (value < 0) {
      return minusPrefix + strValueWithCommas;
    }

    return prefix + strValueWithCommas;
  }

  addCommas(strValue) {
    let str = strValue.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

}

let loadingBarStackRef = (window as any).loadingBarStack || [];

function toggleLoadingBars(visibility: string) {
  const allLoadingBars = document.querySelectorAll('.js-tidy-loading') as NodeListOf<HTMLElement>;

  allLoadingBars.forEach((loadingBar) => {
    loadingBar.style.display = visibility;
  });
}

export function Loading(content: string, loadingBar = false, { timeout = 20, countdown = false } = {}) {

  return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {
      let element;
      if (loadingBar) {
        toggleLoadingBars('block');
        loadingBarStackRef = loadingBarStackRef || [];
        loadingBarStackRef.push('');
      } else {
        element = document.getElementById("tidy-loading-modal");
        element.style.display = "block";
      }

      const result = originalMethod.apply(this, args);
      return buildResult(result, element, timeout)
    };

    return descriptor;
  };
}

const buildResult = (result, element, timeout): Promise<any> => {
  let time: number;

  return new Promise(resolve => {
    time = window.setTimeout(() => dismiss(element), timeout * 1000);
    resolve(result);
  }).then((result) => {
    dismiss(element);
    return result;
  }).catch( (err) => {
    dismiss(element);
    throw err;
  }).finally(() => {
    clearTimeout(time);
  });
}

const dismiss = (element): void => {
  loadingBarStackRef.pop();
  if (loadingBarStackRef.length === 0) {
    toggleLoadingBars('none');
  }
  if (element?.style) {
    element.style.display = "none";
  }
}

export const clearStack = (): void => {
  loadingBarStackRef = [];
  toggleLoadingBars('none');
}

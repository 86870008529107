import { WindowService } from 'src/shared/providers/window.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkflowPage } from 'src/pages/workflows/workflow/workflow';
import { WorkflowModel } from 'src/models/workflow.model';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Workflows } from 'src/providers/workflows/workflows';
import { Util } from 'src/shared/util/util';
import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Concierge } from 'src/providers/concierge/concierge';

@Component({
  selector: 'automate-workflows-onboarding',
  templateUrl: './automate-workflows-onboarding.html',
  styleUrls: ['./automate-workflows-onboarding.scss'],
})
export class AutomateWorkflowsOnboarding implements OnInit {
  workflowTemplates: any[] = [];
  selectedWorkflows: any[] = [];
  form: FormGroup;
  loaded = false;

  constructor(
    public onboardingProvider: OnboardingProvider,
    private workflowsProvider: Workflows,
    private formBuilder: FormBuilder,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private navCtrl: CustomNavController,
    private windowService: WindowService,
    private concierge: Concierge
  ) {
    this.form = this.formBuilder.group({
      workflowRequest: [''],
    });
  }

  async ngOnInit() {
    this.workflowTemplates =
      await this.workflowsProvider.getWorkflowTemplates();
    this.loaded = true;
  }

  updateSelectedTemplates(event) {
    console.log(event);
  }

  async saveWorkflows() {
    const selectedTemplates = this.workflowTemplates.filter(
      (template) => template.isSelected
    );
    const workflowRequest = this.form.value.workflowRequest;
    const item = {
      data: {
        subject: 'Request Workflow(s)',
        body: workflowRequest,
        emails: [],
        attachments: [],
      },
      type: 'support.concierge_task',
    };
    const loading = await this.util.showLoading();
    try {
      if (workflowRequest.length > 0) {
        await this.concierge.addConciergeItem(item);
      }
      await Promise.all([
        ...selectedTemplates.map((template, index) =>
          this.addTemplate(template.id, index)
        ),
        this.onboardingProvider.markGoalAsCompleted('automateWorkflows'),
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Workflows saved succesfully');
    } catch (err) {
      this.util.showError(
        err?.error?.message ? err.error.message : err.message
      );
    } finally {
      loading.dismiss();
    }
  }

  selectTemplate(template) {
    template.isSelected = !template.isSelected;
  }

  async addTemplate(templateId, index) {
    try {
      const payload = {
        workflow_template_id: templateId,
      };
      await this.workflowsProvider.addWorkflowTemplate(payload);
      const workflows = await this.workflowsProvider.getWorkflows();
      if (index === 0) {
        const addedWorkflow = workflows.find(
          (workflow) => workflow.from_workflow_template_id == templateId
        );
        this.goToWorkFlow(addedWorkflow);
        this.util.showSuccess('Template Added!');
      }
    } catch (err) {
      const errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  async goToWorkFlow(workflow: WorkflowModel) {
    setTimeout(async () => {
      const workflowWithVersions =
        await this.workflowsProvider.getWorkflowWithVersions(workflow.id);
      const params = {
        workflow: workflowWithVersions,
      };
      this.storage.save('workflowId', workflow.id);
      if (workflowWithVersions.published_version) {
        this.goToPage(
          `/automations/workflow/${workflow.id}`,
          params,
          WorkflowPage
        );
      } else {
        this.goToPage(
          `/automations/workflow/${workflow.id}/edit`,
          params,
          EditWorkflowPage
        );
      }
    }, 200);
  }

  async goToPage(url, params, component) {
    if (this.windowService.isDesktopRes) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve(
        'dialog-right-side-open'
      );
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(
          url,
          params,
          component,
          false
        );
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }
}


<ng-container *ngIf="loaded">
  <form [formGroup]="form" novalidate action="#">

    <tidy-row *ngIf="integrationSrcType?.logo_description_image_url">
      <tidy-image
        class="hero-size"
        [src]="integrationSrcType?.logo_description_image_url">
      </tidy-image>
    </tidy-row>

    <tidy-card style="padding: 10px; flex-grow: 1; display: flex; flex-direction: column;">
      <div style="flex-grow: 1; display: flex; flex-direction: column;">
        <tidy-row style="flex-grow: 1;">
          <ion-row style="height: 100%;">
            <ion-col size="3" style="margin: auto">
                <tidy-image
                [src]="integrationSrcType?.logo_url"
                style="width:100%; max-height: 65px">
                </tidy-image>
            </ion-col>
            <ion-col style="display: flex; flex-direction: column;">
              <tidy-row class="vertical-align-middle">
                <tidy-text
                  [header]="integrationSrcType?.name"
                  [translate]="false">
                </tidy-text>
                <ng-container *ngIf="integrationSrcType?.discount">
                  <tidy-image
                    [src]="'assets/img/coupon.svg'"
                    style="margin-left: 10px; width: 20px">
                  </tidy-image>
                  <tidy-text
                    [body]="integrationSrcType?.discount">
                  </tidy-text>
                </ng-container>
              </tidy-row>
              <tidy-row *ngIf="integrationSrcType?.short_description !== null" style="flex-grow: 1;">
                <tidy-text
                  [body]="integrationSrcType?.short_description">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </div>
      <ng-container *ngIf="!onlyShowDirectIntegration && !isUpdatingIntegration">
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row [alignLastItemRight]="integrationSrcType?.tidy_help_link" [class.vertical-align-center]="!integrationSrcType?.tidy_help_link">
          <tidy-text
            [header]="'How do you want to integrate?'">
          </tidy-text>
          <tidy-text
            *ngIf="integrationSrcType?.tidy_help_link"
            (action)="goToHelp()"
            body="Get Help"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-radio-button
            formControlName="integrationOption"
            [form]="form.controls.integrationOption"
            [items]="integrationOptionItems"
            [submitted]="submitted"
            [errorMessage]="'Please select an option.'"
            (optionChange)="updateFormValidation($event)">
          </tidy-radio-button>
        </tidy-row>
      </ng-container>
      <tidy-row class="extra-top-padding extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="getSelectedFormatIcon()"
          class="title-size">
        </tidy-image>
        <tidy-text
          [header]="getSelectedFormatHeader()">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="longDescription" [ngClass]="{'extra-bottom-padding': isDirectFormRequired || reservationSrc?.last_sync_at || form.value.integrationOption == 'icalendar' || form.value.integrationOption == 'concierge_integration' || form.value.integrationOption == 'zapier'}">
        <tidy-text
          [body]="longDescription">
        </tidy-text>
      </tidy-row>
      <ng-container *ngIf="isDirectFormRequired && form.value.integrationOption == 'api'">
        <tidy-row class="field-top-padding field-bottom-padding" *ngIf="isBaseURLRequired">
          <tidy-input
            formControlName="baseURL"
            [form]="form.controls.baseURL"
            [icon]="'assets/svg/globe-outline.svg'"
            [label]="'Base URL'"
            [errorMessage]="'Please enter a valid base URL.'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row [ngClass]="isBaseURLRequired ? 'field-top-padding field-bottom-padding' : 'field-bottom-padding'" *ngIf="isAccountIdRequired && integrationSrcType.name !== 'Guesty'">
          <tidy-input
            formControlName="accountId"
            [form]="form.controls.accountId"
            [icon]="'assets/svg/person-circle-outline.svg'"
            [label]="getClientIdFieldHeader()"
            [errorMessage]="'Please enter a valid ID.'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row [ngClass]="isAccountIdRequired ? '' : 'field-top-padding'">
          <tidy-input
            formControlName="apiKey"
            [form]="form.controls.apiKey"
            [icon]="'assets/svg/key-outline.svg'"
            [label]="getApiKeyFieldHeader()"
            [errorMessage]="'Please enter a valid API key.'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
      </ng-container>
      <tidy-row *ngIf="reservationSrc?.last_sync_at" class="text-center extra-bottom-padding">
        <tidy-text
          [helper]="'You\’ve already added this integration for this property.  Please select a different property or '">
        </tidy-text>
        <tidy-text
          [body]="'Disable this Integration'"
          (action)="deactivateIntegration()"
          class="red link">
        </tidy-text>
      </tidy-row>
      <ng-container  *ngIf="form.value.integrationOption == 'icalendar'">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="importUrl"
            [form]="form.controls.importUrl"
            [icon]="'assets/svg/globe-outline.svg'"
            label="iCal link"
            [errorMessage]="'Please enter a valid url'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row *ngIf="addressesSelectItems.length > 0">
          <tidy-select
            [icon]="'assets/svg/home-outline.svg'"
            [label]="'Property'"
            [form]="form.controls.addressId"
            formControlName="addressId"
            [items]="addressesSelectItems">
          </tidy-select>
        </tidy-row>
        <tidy-row *ngIf="addressesSelectItems.length == 0">
          <tidy-text
            [body]="'Please add properties before integrating with iCal. '">
          </tidy-text>
          <tidy-text
            [body]="'Add Properties'"
            (action)="goToAddProperty()"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <ng-container  *ngIf="form.value.integrationOption == 'concierge_integration'">
        <tidy-row>
          <tidy-input
            formControlName="conciergeLogin"
            [form]="form.controls.conciergeLogin"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [label]="'Username'"
            [errorMessage]="'Please enter a username'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input
            formControlName="conciergePassword"
            [form]="form.controls.conciergePassword"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [label]="'Password'"
            [errorMessage]="'Please enter a password'"
            type="password"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-textarea
            formControlName="conciergeNotes"
            [form]="form.controls.conciergeNotes"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [label]="'Notes'">
          </tidy-textarea>
        </tidy-row>
      </ng-container>
      <ng-container  *ngIf="form.value.integrationOption == 'zapier'">
        <tidy-row>
          <tidy-text
            class="link"
            [body]="'See Zapier for more details.'"
            (action)="goToZapier()">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </tidy-card>

    <tidy-card *ngIf="isSelfBookingFlow">
      <tidy-row class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/img/smartphone.svg'"
          class="title-size">
        </tidy-image>
        <tidy-text
          [header]="'Contact Information'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="field-top-padding">
        <tidy-input-international-phone
          formControlName="phone"
          [label]="'Phone'"
          [form]="form.controls.phone"
          [inputMode]="'numeric'"
          [submitted]="submitted"
          [errorMessage]="'Please enter a valid phone.'"
          [icon]="'assets/svg/phone-portrait-outline.svg'"
          [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
        </tidy-input-international-phone>
      </tidy-row>
    </tidy-card>

    <tidy-button
      [text]="getPrimaryButtonTitle()"
      [action]="addReservationSource.bind(this)"
      [disabled]="(form.value.integrationOption == 'zapier' || alreadyAddedIntegration) || (addressesSelectItems.length == 0 && form.value.integrationOption == 'icalendar')"
      class="primary">
    </tidy-button>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </form>
</ng-container>

<ng-container *ngIf="!loaded">
  <tidy-card style="padding: 0; height: 1000px;" class="no-padding">
    <ion-skeleton-text animated style="width: 100%; height: 1000px; margin: 0; border-radius: 6px;"></ion-skeleton-text>
  </tidy-card>
</ng-container>
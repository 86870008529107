import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Logger } from '../logger';
import { Events } from 'src/providers/events/events';

import { timeout, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpClientCustom {

  url = '';
  constructor(
    private http: HttpClient,
    private logger: Logger,
    private events: Events
  ) {}

  get = (url, data = {}, timeout = 20000) => this.request('GET', url, {}, timeout);

  post = (url, data, timeout = 20000) => this.request('POST', url, data, timeout);

  put = (url, data, timeout = 20000) => this.request('PUT', url, data, timeout);

  delete = (url, data = null, timeout = 20000) => this.request('DELETE', url, data, timeout);

  request(method, url, data = {}, timeoutMilliseconds): Promise<any> {
    this.url = this.isExternalUrl(url) ? url : `${environment.api_url}${url}`;
    return this.http
      .request(method, this.url, this.payload(data, method))
      .pipe(
        timeout(timeoutMilliseconds)
      ).toPromise()
      .catch( error => this.onError(error, this.url, data) );
  }

  getFullRequest = (url, data = {}, timeout = 20000) => this.fullRequest('GET', url, {}, timeout);

  fullRequest(method, url, data = {}, timeoutMilliseconds): Promise<any> {
    this.url = this.isExternalUrl(url) ? url : `${environment.api_url}${url}`;
    return this.http
      .request(method, this.url, {
        ...this.payload(data, method),
        observe: 'response'
      })
      .pipe(
        timeout(timeoutMilliseconds)
      ).toPromise()
      .catch(error => this.onError(error, this.url, data));
  }

  header(): HttpHeaders {
    const headers = {
      'Authorization': `${localStorage.getItem('tidy_token_type')} ${localStorage.getItem('tidy_token')}`,
      'Content-Type': 'application/json'
    };
    if (!this.isExternalUrl(this.url)) {
      headers['X-App-Version'] = environment.version;
    }
    const httpHeaders = new HttpHeaders(headers);
    return httpHeaders;
  }

  payload(data: any, method: string): any {
    return {
      headers: this.header(),
      body: JSON.stringify(data, (k, v) => v === undefined ? null : v),
    }
  }

  checkAuthToken(error) {
    if (error instanceof HttpErrorResponse) {
      if (error?.error?.error?.message === 'The access token is invalid' || error?.error?.error?.code == 401) {
        this.events.publish('authToken:isInvalid');
      }
    }
  }

  onError(error, url, data): Promise<any> {

    this.checkAuthToken(error);

    this.logger.requestError(error, url, data);

    if (error?.error) {
      const apiError = error.error;
      if (apiError.error) {
        return Promise.reject({ code: apiError.error.code, message: apiError.error.message, err : apiError });
      }
      return Promise.reject(apiError);
    }

    return Promise.reject(error);
  }

  isExternalUrl(url): Boolean {
    return (url.indexOf('https://') === 0);
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'add-do-not.html'
})

export class ToDosAddDoNotPage implements OnInit {

  form: UntypedFormGroup;
  loaded: boolean;
  preSelectedRoom: number;
  isRightSideContent: boolean;
  roomError: boolean;
  rooms: TidySelectNumberValueModel[];
  submitted: boolean;
  selectedRooms = [];

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {
    this.form = this.fb.group({
      body: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    await this.populateRoomCheckboxes();
    this.loaded = true;
  }

  async populateRoomCheckboxes() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rooms = this.navCtrl.getParam('rooms') || dialogParams.rooms;
    const preSelectedRoom = localStorage.getItem('roomId');
    if (preSelectedRoom) {
      this.preSelectedRoom = parseFloat(preSelectedRoom);
      this.selectRoom(this.preSelectedRoom);
    }
  }

  selectRoom(roomId) {
    let roomAlreadySelected = false;
    this.selectedRooms.find((room) => {
      if (roomId === room) {
        roomAlreadySelected = true;
      };
    });
    if (!roomAlreadySelected) {
      this.selectedRooms.push(roomId);
    } else {
      this.selectedRooms = this.selectedRooms.filter((room) => {
        if (roomId !== room) {
          return room;
        };
      });
    }
  }

  async addDoNot() {
    this.submitted = true;
    if (this.selectedRooms.length === 0) {
      this.roomError = true;
    }
    if (!this.form.valid) {
      return;
    }
    if (this.roomError) {
      return;
    }
    const params = {
      body: this.form.value.body,
      selectedRooms: this.selectedRooms
    }
    await this.toDos.addDoNot(params);
    const route = localStorage.getItem('roomId') ? 'card' : 'to-dos';
    let dialogParams = await this.storage.retrieve('dialog-params');
    if (dialogParams?.room) dialogParams.room = null;
    await this.storage.save('dialog-params', dialogParams);
    this.rightSidePanelService.navigateTo(route);
  }
  
}

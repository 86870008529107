<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header>
      <tidy-text
        [title]="'Campaigns'">
      </tidy-text>
    </tidy-desktop-header>

    <ion-skeleton-text *ngIf="!loaded" animated="true" style="width: calc(100% + 60px); height: 100vh; margin-left: -20px"></ion-skeleton-text>

    <ng-container *ngIf="loaded">

      <tidy-row>
        <tidy-text
          [body]="'Set up your campaigns.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-table
          [dataSource]="rows"
          [displayedColumns]="headers"
          [columnslabels]="headers">
        </tidy-table>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Talk to your account manager about starting a new campaign.'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { cardOptions } from 'src/old-pages/booking/confirm/confirm-card-options';
import { Card } from 'src/providers/card/card';
import { StripeProvider } from 'src/providers/stripe/stripe-provider';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'payment-method-form.html',
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodFormPage implements AfterViewInit, OnInit, OnDestroy {
  backPage: string;
  cameFromAutomaticBooking: boolean;
  cameFromSendPayment: boolean;
  cardBeingEditted: any;
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  @ViewChild('bankInfo', { static: false }) bankInfo: ElementRef;
  cardEl: any;
  cardHandler = this.onChange.bind(this);
  dialogParams: any;
  errorMessage: String;
  hasValidCaptcha: string | null = null;
  isPrimaryCard: boolean;
  showDefaultCardCheckbox: boolean;
  loaded: boolean;
  teamId: any;
  isRightSideContent = true;
  cardOrBankItems: any[] = [
    { viewValue: 'Card', value: 'card' },
    { viewValue: 'Bank Account', value: 'bank' },
  ];
  chooseOrAddCardItems: any[] = [
    { viewValue: 'Add a new card', value: 'add' },
    { viewValue: 'Choose a card', value: 'choose' },
  ];
  form = new FormGroup({
    cardOrBank: new FormControl('card'),
    chooseOrAddCard: new FormControl('add'),
    paymentMethod: new FormControl(null),
    name: new FormControl('', Validators.required),
  });
  isCardSelected = true;
  paymentMethodsItems: any[] = [];
  isAddMissingBankInfo: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private cardService: Card,
    private stripeProvider: StripeProvider,
    private navCtrl: CustomNavController,
    private util: Util,
    private windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private paymentMethodsProvider: PaymentMethodsProvider
  ) {}

  @Loading('', true)
  async ngOnInit(): Promise<void> {
    try {
      this.loaded = false;
      this.initVariables();
      this.loaded = true;
    } catch (err) {
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  ngAfterViewInit(): void {
    this.initiateCardElement();
  }

  ngOnDestroy(): void {
    if (this.cardEl) {
      this.cardEl.removeEventListener('change', this.cardHandler);
      this.cardEl.destroy();
      this.cardEl = null;
    }
  }

  async initVariables(): Promise<void> {
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.cardBeingEditted = this.navCtrl.getParam('card');
    const cards = this.navCtrl.getParam('cards') || this.dialogParams?.cards;
    this.teamId = this.navCtrl.getParam('teamId') || this.dialogParams?.teamId;
    this.showDefaultCardCheckbox =
      this.teamId || (cards?.length == 1 && this.cardBeingEditted)
        ? false
        : true;
    this.isPrimaryCard =
      this.teamId || !this.cardBeingEditted
        ? false
        : this.cardBeingEditted?.is_primary;
    this.cameFromSendPayment =
      this.navCtrl.getParam('cameFromSendPayment') ||
      this.dialogParams?.cameFromSendPayment;
    this.cameFromAutomaticBooking =
      this.navCtrl.getParam('cameFromAutomaticBooking') ||
      this.dialogParams?.cameFromAutomaticBooking;
    this.backPage =
      this.navCtrl.getParam('backPage') ||
      this.dialogParams?.backPage ||
      'more';
    this.rightSidePanelService.setDialogPageTitle(
      this.cardBeingEditted ? 'Edit Payment Method' : 'Add Payment Method'
    );
    this.isAddMissingBankInfo =
      this.dialogParams?.action === 'addMissingBankInfo' ||
      this.navCtrl.getParam('action') === 'addMissingBankInfo';
    if (this.isAddMissingBankInfo) {
      this.form.controls.cardOrBank.setValue('bank');
      this.isCardSelected = false;
    }
    let paymentMethods = await this.paymentMethodsProvider.getPaymentMethods();
    paymentMethods = this.util.parsePaymentMethods(paymentMethods);
    this.paymentMethodsItems = paymentMethods
      .map((paymentMethod) => ({
        ...paymentMethod,
        viewValue: this.getPaymentMethodLabel(paymentMethod),
        value: paymentMethod.id,
        type: paymentMethod.type,
        icon:
          paymentMethod.type === 'card'
            ? 'assets/svg/card.svg'
            : 'assets/img/bank.png',
      }))
      .filter(
        (paymentMethod) =>
          paymentMethod.status === 'succeeded' &&
          (this.teamId ? paymentMethod.type === 'card' : false)
      );
  }

  getPaymentMethodLabel(paymentMethod: any) {
    let label = paymentMethod.type === 'card' ? 'Card' : 'Bank Account';
    if (paymentMethod?.metadata?.last4) {
      label += ` ${new TranslationPipe().transform('ending in')} ${paymentMethod.metadata.last4}`;
    }
    if (paymentMethod?.is_prmary) {
      label += ` ${new TranslationPipe().transform('(primary)')}`;
    }
    return label;
  }

  selectChooseOrAddCard(event: {
    viewValue: string;
    value: 'choose' | 'add';
  }): void {
    if (event.value === 'choose') {
      this.isCardSelected = false;
      if (this.cardEl) {
        this.cardEl.removeEventListener('change', this.cardHandler);
        this.cardEl.destroy();
        this.cardEl = null;
      }
    } else {
      this.isCardSelected = true;
      this.initiateCardElement();
    }
  }

  selectCardOrBank(event: { viewValue: string; value: 'card' | 'bank' }): void {
    if (event.value === 'bank') {
      this.isCardSelected = false;
      if (this.cardEl) {
        this.cardEl.removeEventListener('change', this.cardHandler);
        this.cardEl.destroy();
        this.cardEl = null;
      }
    } else {
      this.isCardSelected = true;
      this.initiateCardElement();
    }
  }

  initiateCardElement(): void {
    if (this.cardEl) {
      this.cardEl.removeEventListener('change', this.cardHandler);
      this.cardEl.destroy();
      this.cardEl = null;
    }
    setTimeout(() => {
      this.cardEl = this.stripeProvider
        .getElements()
        .create('card', cardOptions);
      this.cardEl.mount(this.cardInfo.nativeElement);
      this.cardEl.addEventListener('change', this.cardHandler);
    }, 1000);
  }

  onChange(err: any): void {
    this.errorMessage = err.error ? err.error.message : err.message;
    this.cd.detectChanges();
  }

  async handleChooseCard(): Promise<void> {
    if (!this.form.value.paymentMethod) {
      this.errorMessage = 'Please select a card.';
    } else {
      try {
        const payload = {
          account_team_id: this.teamId,
          payment_method_id: this.form.value.paymentMethod,
        };
        await this.paymentMethodsProvider.associatePaymentMethodWithTeam(
          this.teamId,
          payload
        );
        this.util.showSuccess('Card added to team successfully.');
        this.navBack();
      } catch (err) {
        this.errorMessage = 'Error adding card to team.';
        this.util.showError(this.errorMessage as string);
      }
    }
  }

  async submitCard(): Promise<void> {
    this.errorMessage = '';
    if (!this.hasValidCaptcha) {
      this.errorMessage = 'Please check that you are not a robot.';
      return;
    }
    if (this.teamId && this.form.value.chooseOrAddCard === 'choose') {
      await this.handleChooseCard();
      return;
    }
    const { token, error } = await this.stripeProvider
      .getInstance()
      .createToken(this.cardEl);
    if (error) {
      this.setErrorCard(error);
    } else {
      await this.checkCard(token);
    }
  }

  async checkCard(token: any): Promise<void> {
    try {
      const paymentMethod: any = await this.cardService.saveCard({
        type: 'card',
        stripe_card_token: token.id,
        primary_payment_method: this.isPrimaryCard,
      });
      if (this.teamId) {
        const payload = {
          account_team_id: this.teamId,
          payment_method_id: paymentMethod.id,
        };
        await this.paymentMethodsProvider.associatePaymentMethodWithTeam(
          this.teamId,
          payload
        );
      }
      if (this.cardBeingEditted) {
        await this.paymentMethodsProvider.removePaymentMethod(
          this.cardBeingEditted.id
        );
      }
      await this.paymentMethodsProvider.getPaymentMethods(true);
      this.goToNextPage();
    } catch (err) {
      this.setErrorCard(err);
    }
  }

  captchaEvents(captchaResult): void {
    this.hasValidCaptcha = captchaResult;
  }

  goToNextPage(): void {
    localStorage.setItem('firstBooking', 'false');
    const goToMyProsAfter =
      this.navCtrl.getParam('goToMyProsAfter') ||
      this.dialogParams?.goToMyProsAfter;
    let url = '';
    let params = {};
    if (this.teamId) {
      this.util.showSuccess('Card added to team successfully.');
      this.navBack();
      return;
    } else if (goToMyProsAfter) {
      const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      url = 'success';
      params = {
        header: `Credit Card Added`,
        body: "You've enabled automatic booking and added a credit card. We will now send job requests to your Pro priority list as new events are synced. You can add any existing Pro to your Pro priority list if you want them to accept jobs (working with existing Pros is free).",
        buttonText: 'Review Pro Priority List',
        buttonRoute:
          isRentalClient && this.windowService.isDesktopRes
            ? 'my-pros'
            : 'job-request-workflows',
      };
    } else if (this.cameFromSendPayment) {
      url = 'get-started-send-payment';
      params = {
        pro: this.navCtrl.getParam('pro') || this.dialogParams?.pro,
        creditBalance:
          this.navCtrl.getParam('creditBalance') ||
          this.dialogParams?.creditBalance,
        address: this.navCtrl.getParam('address') || this.dialogParams?.address,
        allowsInvoiceAutopay:
          this.navCtrl.getParam('allowsInvoiceAutopay') ||
          this.dialogParams?.allowsInvoiceAutopay,
      };
    } else {
      url = 'success';
      params = {
        header: this.cardBeingEditted
          ? 'Credit Card Edited'
          : 'Credit Card Added',
        body: '',
        buttonText: 'Ok',
        buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more',
      };
    }
    this.rightSidePanelService.navigateTo(url, params);
  }

  setErrorCard(err: any): void {
    if (err.code === 402) {
      this.errorMessage =
        'Your credit card was declined. Please double-check and try again.';
    } else {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToContactConcierge(): void {
    const params = {
      title: 'Integrations Help',
      type: 'support.integration',
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  goToScheduleCall(): void {
    const url = 'https://www.tidy.com/schedule-integration-support';
    this.util.openUrl(url);
  }

  navBack(): void {
    const isDesktopRes = this.windowService.isDesktopRes;
    if (isDesktopRes) {
      this.rightSidePanelService.goBack();
    } else {
      this.rightSidePanelService.closeRightSidePanel();
    }
  }

  async handleAddMissingBankInfo(name: string): Promise<void> {
    try {
      const response = await this.stripeProvider.collectBankAccountForSetup({
        name,
        client_secret:
          this.dialogParams?.client_secret ||
          (await this.navCtrl.getParam('client_secret')),
      });
      if (response.setupIntent.status === 'requires_payment_method') {
        this.util.showWarning('Please complete the Stripe onboarding process to add a bank account.');
        return;
      }
      this.util.showSuccess('Bank account added successfully.');
      this.navBack();
    } catch (err) {
      this.util.showError('Error adding bank account. ' + err.message);
      console.error(err);
    }
  }

  async nextForBankAccount(): Promise<void> {
    if (!this.hasValidCaptcha) {
      this.errorMessage = 'Please check that you are not a robot.';
      return;
    }
    try {
      const { name } = this.form.value;
      if (this.isAddMissingBankInfo) {
        this.handleAddMissingBankInfo(name);
        return;
      }
      const response = await this.paymentMethodsProvider.addBankAccount();
      const clientSecret = response.metadata.stripe.client_secret;
      const token = await this.stripeProvider.collectBankAccountForSetup({
        name,
        client_secret: clientSecret,
      });

      await this.confirmPaymentMethod(response.id);
    } catch (err) {
      console.error(err);
      if (err.message === 'This action is not allowed!') {
        this.util.showWarning('Please complete the Stripe onboarding process to add a bank account.');
        return;
      }
      this.util.showError(new TranslationPipe().transform('Error adding bank account. ') + err.message);
    }
  }

  async confirmPaymentMethod(paymentMethodId: string) {
    const loading = await this.util.showLoading();
    try {
      await this.paymentMethodsProvider.confirmPaymentMethod(paymentMethodId);
      this.util.showSuccess('Bank account confirmed successfully.');
      this.navBack();
    } catch (confirmError) {
      console.error(confirmError);
      if (confirmError.message === 'This action is not allowed!') {
        this.util.showWarning('Please complete the Stripe onboarding process to add a bank account.');
        return;
      }
      this.util.showError(
        new TranslationPipe().transform('Error confirming bank account. ') + confirmError.message
      );
    } finally {
      loading.dismiss();
    }
  }
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]=" loaded ? (cardBeingEditted ? 'Edit Payment Method' : 'Add Payment Method') : ''"
    [noBow]="cameFromAutomaticBooking"
    [canGoBack]="!cameFromAutomaticBooking"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="cameFromAutomaticBooking">
      <tidy-alert>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image [src]="'assets/img/credit-card.svg'" class="title-size">
          </tidy-image>
          <tidy-text [header]="'How Payment Works'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'TIDY requires a card on file to enable automatic booking. The card can be used for your subscription or to pay pros you book. Questions? '">
          </tidy-text>
          <tidy-text
            [body]="'Contact our Concierge'"
            class="link"
            (action)="goToContactConcierge()">
          </tidy-text>
          <tidy-text [body]="' or '"> </tidy-text>
          <tidy-text
            [body]="'Schedule a Call'"
            class="link"
            (action)="goToScheduleCall()">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>

    <ng-container *ngIf="cardBeingEditted">
      <tidy-row [align]="'center'">
        <tidy-text [body]="'Editing card ending in'"> </tidy-text
        ><span>&nbsp;</span>
        <tidy-text [body]="cardBeingEditted?.last4 + ':'"> </tidy-text>
      </tidy-row>
    </ng-container>

    <tidy-card *ngIf="!teamId && !isAddMissingBankInfo">
      <form [formGroup]="form">
        <tidy-radio-button
          [label]="'Choose a card or bank account'"
          formControlName="cardOrBank"
          [form]="form.controls.cardOrBank"
          [items]="cardOrBankItems"
          (optionChange)="selectCardOrBank($event)">
        </tidy-radio-button>
      </form>
    </tidy-card>

    <tidy-card *ngIf="teamId">
      <form [formGroup]="form">
        <tidy-radio-button
          [label]="'Choose a card or add a new card'"
          formControlName="chooseOrAddCard"
          [form]="form.controls.chooseOrAddCard"
          [items]="chooseOrAddCardItems"
          (optionChange)="selectChooseOrAddCard($event)">
        </tidy-radio-button>
      </form>
    </tidy-card>

    <tidy-card *ngIf="form.value.chooseOrAddCard === 'choose' && teamId">
      <tidy-row>
        <tidy-select
          [multiple]="false"
          [label]="'Payment Method'"
          [items]="paymentMethodsItems"
          [form]="form.controls.paymentMethod"
          formControlName="paymentMethod"
          class="select-custom-input select-custom-input-bigger">
        </tidy-select>
      </tidy-row>
    </tidy-card>

    <tidy-card *ngIf="isCardSelected">
      <tidy-row>
        <tidy-text [body]="'Enter Card Information'"> </tidy-text>
      </tidy-row>

      <div class="form-row">
        <form>
          <div id="card-info" #cardInfo></div>
        </form>
      </div>

      <tidy-row *ngIf="showDefaultCardCheckbox">
        <tidy-checkbox
          [(checked)]="isPrimaryCard"
          [items]="[
            {
              viewValue: 'Make this my primary card', value: true
            }
          ]"
          [initValue]="isPrimaryCard">
        </tidy-checkbox>
      </tidy-row>

    </tidy-card>

    <tidy-card *ngIf="!isCardSelected && !teamId">
      <tidy-row>
        <tidy-text [body]="'Enter Bank Account Information'"> </tidy-text>
      </tidy-row>

      <form [formGroup]="form" style="margin-bottom: 1rem">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="name"
            [form]="form.controls.name"
            [label]="'Account Holder\'s Full Name'"
            [errorMessage]="'Please enter the account holder\'s full name.'"
            [submitted]="submitted"
            [icon]="'assets/svg/person-outline.svg'">
          </tidy-input>
        </tidy-row>
      </form>

      <tidy-error-message [text]="errorMessage"> </tidy-error-message>
    </tidy-card>

    <ng-container>
      <div
        style="display: flex; align-content: center; justify-content: center">
        <re-captcha (resolved)="captchaEvents($event)"></re-captcha>
      </div>
    </ng-container>

    <tidy-row *ngIf="form.value.cardOrBank === 'card'">
      <tidy-button
        [text]="'Save'"
        [action]="submitCard.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
    <tidy-row *ngIf="form.value.cardOrBank === 'bank'">
      <tidy-button
        [text]="'Next'"
        [action]="nextForBankAccount.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>
    <tidy-error-message [text]="errorMessage"> </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Webhook Endpoint'"
    [canGoBack]="true"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-bottom-padding" *ngIf="isAccountAdmin && hasTeams">
            <tidy-select
              [icon]="'assets/svg/people-outline.svg'"
              [label]="'Team to notify'"
              formControlName="team"
              [form]="form.controls.team"
              [items]="teamItems">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-bottom-padding field-top-padding">
            <tidy-input
              formControlName="webhookUrl"
              [form]="form.controls.webhookUrl"
              [label]="'Endpoint URL'"
              [icon]="'assets/svg/globe-outline.svg'"
              [errorMessage]="'Please input a URL.'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="description"
              [form]="form.controls.description"
              [label]="'Description'"
              [icon]="'assets/svg/information-circle-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'Webhook channel:'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-radio-button
              formControlName="channel"
              [form]="form.controls.channel"
              [items]="channelItems">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'Events to listen to:'">
            </tidy-text>
          </tidy-row>

          <ng-container *ngFor="let event of eventItems">
            <tidy-checkbox
              [(checked)]="event.checked"
              [items]="[{value: event.value, viewValue: event.viewValue}]">
            </tidy-checkbox>
          </ng-container>

          <tidy-error-message
            *ngIf="showSelectedEventsError"
            [text]="'Please select events to listen to.'">
          </tidy-error-message>
        </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add Endpoint'"
          [action]="addWebhook.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AddressChangeService {

  private addressChangeSource = new Subject<number>();
  addressChange$ = this.addressChangeSource.asObservable();

  changeAddress(addressId: number) {
    this.addressChangeSource.next(addressId);
  }

}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Dashboard } from 'src/providers/dashboard/dashboard';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: './report-settings.html',
})

export class ReportSettingsPage implements OnInit {

  cameFromReportAdded: boolean;
  dialogParams: any;
  editOrder: boolean;
  errorMessage: string;
  isRightSideContent: boolean;
  report: any;
  reportType: string;

  constructor(
    private dashboard: Dashboard,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {}

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.report = this.navCtrl.getParam('report') || this.dialogParams.report || await this.getReport();
      const hiddenFields = [
        'address.id',
        'address.address_2',
        'address.address_name',
        'address.city',
        'address.state',
        'address.zipcode',
        'address.country',
        'next_job.id',
        'next_job.start_time',
        'last_completed_job.id',
        'last_completed_job.start_time',
        'next_job.status',
        'last_completed_job.status',
        'last_completed_job.service_type',
        'next_job.service_type',
        'maintenances.name',
        'maintenances.id',
      ];
      this.report.settings.fields = this.report.settings.fields
        .filter(field => !hiddenFields.includes(field.field_key))
        .filter(field => field?.field_name);
      this.reportType = this.getReportType();
      this.rightSidePanelService.setDialogPageTitle(this.report.name == 'Overview' ? 'Dashboard Settings' : 'Report Settings');
      this.cameFromReportAdded = localStorage.getItem('cameFromReportAdded') == 'true';
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getReportType() {
    const types = {
      'job.date': 'jobs',
      'jobs.date': 'jobs',
      'maintenances.date': 'maintenances',
      'guest_reservation.check_in_date': 'reservations'
    };
    return types[this.report.settings.default_date_key];
  }

  async getReport() {
    const loading = await this.util.showLoading();
    try {
      const id = this.route.snapshot.paramMap.get('dashboard_customer_report_id') || localStorage.getItem('reportId');
      return await this.dashboard.getDashboardReportData(id);
    } catch (err) {
      this.util.showError(err?.message || 'Error loading report');
    } finally {
      loading.dismiss();
    }
  }

  goToEditReportColumn(field) {
    const params = {
      field: field,
      report: this.report
    }
    this.rightSidePanelService.navigateTo('dashboard/edit-report-column', params);
  }

  goToEditReportName() {
    const params = {
      report: this.report
    }
    this.rightSidePanelService.navigateTo('dashboard/edit-report-name', params);
  }

  goToReport() {
    this.navCtrl.navigateForward(`reports/${this.report.id}`);
  }

}

import { HttpClientCustom } from '../custom/http-client';
import { Injectable } from '@angular/core';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

export type BankAccountCreationResponse = {
  id: string;
  status: string;
  action_required: string;
  metadata: {
    stripe: {
      client_secret: string;
    };
  };
};

@Injectable()
export class PaymentMethodsProvider {
  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  async getPaymentMethods(getFromBackend: boolean = false) {
    let paymentMethods = await this.storage.retrieve('paymentMethods');
    console.log(paymentMethods);
    if (!paymentMethods || getFromBackend) {
      paymentMethods = await this.httpClient.get('api/v1/customer/payment-methods');
      await this.storage.save('paymentMethods', paymentMethods);
    }
    return paymentMethods;
  }

  async addBankAccount(): Promise<BankAccountCreationResponse> {
    return await this.httpClient.post('api/v1/customer/payment-methods', {
      type: 'us_bank_account',
    });
  }

  async confirmPaymentMethod(paymentMethodId: string) {
    return await this.httpClient.post(
      `api/v1/customer/payment-methods/${paymentMethodId}/confirm`,
      {}
    );
  }

  getPaymentMethodsWithAccountTeams() {
    const url = 'api/v1/customer/payment-methods?includes=account_teams';
    return this.httpClient.get(url);
  }

  async removePaymentMethod(paymentMethodId) {
    const url = `api/v1/customer/payment-methods/${paymentMethodId}`;
    return this.httpClient.delete(url);
  }

  async removePaymentMethodFromTeam(accountTeamId) {
    const url = `api/v1/customer/account-teams/${accountTeamId}/remove-payment-method`;
    return this.httpClient.post(url, {});
  }

  associatePaymentMethodWithTeam(accountTeamId, payload) {
    const url = `api/v1/customer/account-teams/${accountTeamId}/set-payment-method`;
    return this.httpClient.post(url, payload);
  }

  makeCardPrimary(paymentMethodId) {
    const url = `api/v1/customer/payment-methods/${paymentMethodId}/set-as-primary`;
    return this.httpClient.post(url, {});
  }

  async addCredit(paymentMethodId: string, amount: number) {
    const url = `api/v1/customer/charges`;
    return this.httpClient.post(url, {
      payment_method_id: paymentMethodId,
      amount: amount,
    });
  }

}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

export interface ChipPlaceholder {
  text: string;
  value: string;
}

export interface ChipBody {
  content: string;
  chips: ChipPlaceholder[];
}

export interface InputFocusOut {
  id: string;
  value: string;
}

@Component({
  selector: "tidy-chip-text-area",
  templateUrl: "chip-text-area.html",
  styleUrls: ["./chip-text-area.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChipTextArea implements OnInit, OnChanges {
  @Input() id: string;
  @Input() inputTitle: string;
  @Input() placeholder: ChipPlaceholder;
  @Input() body: ChipBody;
  @Input() inputKey: string;
  @Input() isRequired: boolean;
  @Input() readonly = false;
  @Output() inputChange = new EventEmitter<InputFocusOut>();
  @Output() inputFocusIn = new EventEmitter<InputFocusOut>();
  @Output() inputFocusOut = new EventEmitter<InputFocusOut>();
  @Output() onInit = new EventEmitter<void>();
  innerHtml: string;
  placeholders: ChipPlaceholder[] = [];
  control: FormControl;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.control = new FormControl('', this.isRequired ? Validators.required : null);
    this.watchForPaste();
    this.watchForInputChanges();
    this.watchForInputFocusIn();
    this.watchForFocusOut();
    this.onInit.emit();
  }

  ngOnChanges(event: SimpleChanges): void {
    if (event?.isRequired) {
      if (!this.control) {
        this.control = new FormControl('', event.isRequired.currentValue ? Validators.required : null);
      } else {
        this.control.setValidators(event.isRequired.currentValue ? Validators.required : null);
      }
    }
    if (this.placeholder && this.placeholder?.text && this.placeholder?.value) {
      this.appendPlaceholder(this.placeholder);
    }
    if (this.body) {
      this.parseAndSetBody();
    }
  }

  watchForPaste(): void {
    setTimeout(() => {
      const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
      container.addEventListener("paste", (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData)
          .items;
        for (let index in items) {
          const item = items[index];
          if (
            item.type !== undefined &&
            item.type !== "text/plain" &&
            item.type !== "text/html"
          ) {
            event.preventDefault();
            break;
          }
        }
      });
    }, 1000);
  }

  watchForInputFocusIn(): void {
    setTimeout(() => {
      const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
      container.addEventListener("focusin", (event) => {
        this.inputFocusIn.emit({ id: this.id, value: this.innerHtml });
      });
    }, 1000);
  }

  watchForFocusOut(): void {
    setTimeout(() => {
      const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
      container.addEventListener("focusout", (event) => {
        this.inputFocusOut.emit({ id: this.id, value: this.innerHtml });
      });
    }, 1000);
  }

  watchForInputChanges(): void {
    let typingTimer: any;
    const doneTypingInterval = 1000;

    setTimeout(() => {
      const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
      container.addEventListener("input", (event) => {
        clearTimeout(typingTimer); // Clear the timer if the user is still typing
        typingTimer = setTimeout(() => {
          this.parseAndEmitInnerHtml(); // User is considered done typing after 1 second, emit the innerHtml
        }, doneTypingInterval);
      });
    }, 1000);
  }

  parseAndEmitInnerHtml(): void {
    const innerHtml =
      this.el.nativeElement.querySelector("#customMessage-" + this.id).innerHTML;

    const parser = new DOMParser();
    const doc = parser.parseFromString(innerHtml, "text/html");
    const chips = doc.querySelectorAll("ion-chip");

    chips.forEach((chip) => {
      const regex = /{([^}]+)}/g;
      const matches = chip.id.match(regex);
      const textNode = doc.createTextNode(' ' + (matches ? chip.id : `{${chip.id}}`));
      chip.parentNode.replaceChild(textNode, chip);
    });

    let newHtml = doc.body.innerHTML;
    newHtml = newHtml.replace(/&nbsp;/g, " ");

    this.innerHtml = newHtml;
    this.inputChange.emit({ id: this.id, value: this.innerHtml });
    this.control.setValue(this.innerHtml);
  }

  removePlaceholder(span: any): void {
    this.renderer.removeChild(
      this.el.nativeElement.querySelector("#customMessage-" + this.id),
      span
    );
    setTimeout(() => {
      this.parseAndEmitInnerHtml();
    }, 1000);
  }

  appendPlaceholder(field: ChipPlaceholder): void {
    // Create ion-chip element
    const ionChip = this.renderer.createElement("ion-chip");

    // Create span for the field text
    const span = this.renderer.createElement("span");
    const text = this.renderer.createText(`${field.text}`);
    this.renderer.appendChild(span, text);

    this.renderer.appendChild(ionChip, span);
    if (!this.readonly) {
      // Create ion-icon element for the remove icon
      const ionIcon = this.renderer.createElement("ion-icon");
      this.renderer.setAttribute(ionIcon, "name", "close-circle");
      this.renderer.listen(ionIcon, "click", () =>
        this.removePlaceholder(ionChip)
      );

      // Append the span and ion-icon to the ion-chip
      this.renderer.appendChild(ionChip, ionIcon);
    } else {
      this.renderer.addClass(ionChip, "fix-padding");
    }

    // Add attributes and classes to the ion-chip
    this.renderer.setAttribute(ionChip, "contenteditable", "false");
    this.renderer.setAttribute(ionChip, "id", field.value);
    this.renderer.addClass(ionChip, "custom-chip");

    // Append the ion-chip to the container
    const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
    this.renderer.appendChild(container, ionChip);

    // Append a non-breaking space after the ion-chip
    const space = this.renderer.createText("\u00A0");
    this.renderer.appendChild(container, space);

    this.parseAndEmitInnerHtml();

    const foundPlaceholder = this.placeholders.find(
      (placeholder) => placeholder.value === field.value
    );
    if (!foundPlaceholder) {
      this.placeholders.push(field);
    } else {
      this.placeholders = this.placeholders.map((placeholder) => {
        if (placeholder.value === field.value) {
          return field;
        }
        return placeholder;
      });
    }
    this.placeholder = null;
    this.inputFocusOut.emit({ id: this.id, value: this.innerHtml });
  }

  parseAndSetBody(): void {
    const { content, chips } = this.body;

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    const container = this.el.nativeElement.querySelector("#customMessage-" + this.id);
    this.renderer.setAttribute(container, "contenteditable", `${!this.readonly}`);
    container.innerHTML = "";

    doc.body.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const words = node.textContent.split(" ");
        words.forEach((word) => {
          const cleanWord = word.replace(/[.,]$/, '');
          const chip = chips.find((chip) => chip.value === cleanWord);
          if (chip) {
            const match = word.match(/([.,])?$/);
            const punctuation = match ? match[0] : '';
            this.appendPlaceholder(chip);
            if (punctuation) {
              const textNode = this.renderer.createText(`${punctuation} `);
              this.renderer.appendChild(container, textNode);
            }
          } else {
            const textNode = this.renderer.createText(`${word} `);
            this.renderer.appendChild(container, textNode);
          }
        });
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const clonedNode = node.cloneNode(true);
        this.renderer.appendChild(container, clonedNode);
      }
    });

    this.parseAndEmitInnerHtml();
    this.body = null;
  }
}

import { Injectable } from '@angular/core';
import { Auth } from '../auth/auth';
import { HttpClientCustom } from '../custom/http-client';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectModel } from 'src/tidy-ui-components/components/form/select/select.model';

declare const dataLayer: any;

@Injectable()
export class Customers {

  private apiUrl: string = environment.api_secure;

  constructor(
    private auth: Auth,
    private http: HttpClient,
    private httpClient: HttpClientCustom
  ) {}

  getCustomers(credentials, flow) {
    const url = 'api/v1/customers';

    return this.httpClient.post(url, credentials).then( data => {
      if (flow === 'bookingProcess') {
        localStorage.setItem('booking', 'customer');
      }
      return data;
    });
  }

  saveScheduleAssuranceData(time, stateHk, stateBk) {
    const saData = this.scheduleAssuranceDataFormat(time, stateHk, stateBk);
    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/settings/reschedule`;
    return this.httpClient.put(url, saData);
  }

  saveBackupTimes(times, saData) {
    saData.customerAddress.times = times;

    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/settings/reschedule`;
    return this.httpClient.put(url, {...saData.customerAddress});
  }

  scheduleAssuranceDataFormat(time, stateHk, stateBk) {
    const defaultTime: any = this.timeScheduleAssurance();
    const data: any = {
      state: 'active',
      plan: 'change_plan'
    };
    // AHAT
    if (stateHk === true && stateBk === true) {
      data.cleaning = 'any_homekeeper_customized_times';
    } else if (stateHk === true && stateBk === false) {
      data.cleaning = 'any_homekeeper_same_cleaning';
    } else if (stateHk === false && stateBk === false) {
      data.state = 'inactive';
    } else if (stateHk === false && stateBk === true) {
      data.cleaning = 'preferred_homekeeper_customized_times';
    }

    for (const indexTime in time) {
      for (const indexDefaultTime in defaultTime.times) {
        if (time[indexTime].times.week_day === defaultTime.times[indexDefaultTime].week_day) {
          defaultTime.times[indexDefaultTime] = time[indexTime].times;
        }
      }
    };

    data.times = defaultTime.times;
    return data
  }

  timeScheduleAssurance() {
    return {
      'times': [
        { 'week_day': 0, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 1, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 2, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 3, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 4, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 5, 'start_time': '00:00', 'end_time': '00:00' },
        { 'week_day': 6, 'start_time': '00:00', 'end_time': '00:00' },
      ]
    }
  }

  saveScheduleAssurance(time, stateBk, stateHk): Promise<any> {
    const customerId = localStorage.getItem('customer_id');
    const saData = this.scheduleAssuranceFormat(time, stateBk, stateHk);
    const url = `api/v1/customers/${customerId}/settings/reschedule`;

    return this.httpClient.put(url, saData);
  }

  getScheduleAssurance() {
    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/settings/reschedule`;

    return this.httpClient.get(url).then(data => {
      return { customerAddress: data };
    });
  }


  scheduleAssuranceTypes(type): void {
    const state = type.state
    const answers: any = {};
    if (type.cleaning === 'any_homekeeper_customized_times' && state === 'active') {
      answers.homekeeperSubstitutes = true;
      answers.backupTimes = true;
    } else if (type.cleaning === 'any_homekeeper_same_cleaning' && state === 'active') {
      answers.homekeeperSubstitutes = true;
      answers.backupTimes = false;
    } else if (type.cleaning === 'preferred_homekeeper_customized_times' && state === 'active') {
      answers.homekeeperSubstitutes = false;
      answers.backupTimes = true;
    } else if (state === 'inactive') {
      answers.homekeeperSubstitutes = false;
      answers.backupTimes = false;
    }
    return answers;
  }

  scheduleAssuranceFormat(time, stateBk, stateHk) {
    const times: Array<Object> = [];
    const data: any = {
      state: 'active',
      plan: 'change_plan'
    };
    const plan = JSON.parse(localStorage.getItem('plan'));
    if ((stateHk === 'allow' && stateBk === 'inactive') || (stateBk === 'active' && plan.oneMoreDays)) {
      data.cleaning = 'any_homekeeper_same_cleaning';
    } else if (stateHk === 'notAllow' && stateBk === 'active') {
      data.cleaning = 'preferred_homekeeper_customized_times';
    } else if (stateHk === 'allow' && stateBk === 'active') {
      data.cleaning = 'any_homekeeper_customized_times';
    }
    for (const prop in time) {
      times.push(time[prop].times)
    }
    data.times = times;
    return data
  }

  backupTimeStatic() {
    return {
      'days': [
        { 'name': 'Sunday', 'short_name': 'Sun', 'value': 6, 'sort': 0 },
        { 'name': 'Monday', 'short_name': 'Mon', 'value': 0, 'sort': 1 },
        { 'name': 'Tuesday', 'short_name': 'Tue', 'value': 1, 'sort': 2 },
        { 'name': 'Wednesday', 'short_name': 'Wed', 'value': 2, 'sort': 3 },
        { 'name': 'Thursday', 'short_name': 'Thu', 'value': 3, 'sort': 4 },
        { 'name': 'Friday', 'short_name': 'Fri', 'value': 4, 'sort': 5 },
        { 'name': 'Saturday', 'short_name': 'Sat', 'value': 5, 'sort': 6 },
      ]
    }
  }

  backupStartHourStatic() {
    return {
      'hours': [
        { 'time': '08:00am', 'value': '08:00' },
        { 'time': '11:00am', 'value': '11:00' },
        { 'time': '02:00pm', 'value': '14:00' },
        { 'time': '05:00pm', 'value': '17:00' },
        { 'time': '8:00pm', 'value': '20:00' }
      ]
    }
  }

  backupTimesSelectOpts(): Array<SelectModel> {
    return [
      { viewValue: '08:00am', value: '08:00' },
      { viewValue: '11:00am', value: '11:00' },
      { viewValue: '02:00pm', value: '14:00' },
      { viewValue: '05:00pm', value: '17:00' },
      { viewValue: '8:00pm', value: '20:00' },
      { viewValue: '11:00pm', value: '23:00' }
    ];
  }

  backupStartHoursStatic() {
    return {
      'hours': [
        { 'time': '08:00am', 'value': '08:00' },
        { 'time': '09:30am', 'value': '09:30' },
        { 'time': '11:00am', 'value': '11:00' },
        { 'time': '12:30pm', 'value': '12:30' },
        { 'time': '02:00pm', 'value': '14:00' },
        { 'time': '03:30pm', 'value': '15:30' },
        { 'time': '05:00pm', 'value': '17:00' },
        { 'time': '06:30pm', 'value': '18:30' },
        { 'time': '8:00pm', 'value': '20:00' },
        { 'time': '9:30pm', 'value': '21:30' }
      ]
    }
  }

  backupEndHourStatic() {
    return {
      'hours': [
        { 'time': '11:00am', 'value': '11:00' },
        { 'time': '2:00pm', 'value': '14:00' },
        { 'time': '5:00pm', 'value': '17:00' },
        { 'time': '8:00pm', 'value': '20:00' },
        { 'time': '11:00pm', 'value': '23:00' },
      ]
    }
  }

  getWorkTime() {
    return [{
      'begin': '8:00am',
      'beginFormated': '08:00',
      'end': '11:00am',
      'endFormated': '11:00',
      'indexItem': 0
    }, {
      'begin': '11:00am',
      'beginFormated': '11:00',
      'end': '2:00pm',
      'endFormated': '14:00',
      'indexItem': 1
    }, {
      'begin': '2:00pm',
      'beginFormated': '14:00',
      'end': '5:00pm',
      'endFormated': '17:00',
      'indexItem': 2
    }, {
      'begin': '5:00pm',
      'beginFormated': '17:00',
      'end': '8:00pm',
      'endFormated': '20:00',
      'indexItem': 3
    }, {
      'begin': '8:00pm',
      'beginFormated': '20:00',
      'end': '11:00pm',
      'endFormated': '23:00',
      'indexItem': 4
    }];
  }

  formatAppliedFlow(data) {
    if (data.blacklisted_hks.length > 0) {
      data.blacklisted_hks.map(item => {
        if (item.name) {
          item.formatedName = item.name.split(' ')[0];
        }
      });
    }
    return data;
  }

  prepareToSaveDays(days) {
    return days.filter((item) => {
      if (item.status === true && item.workTimeEnd && item.workTimeBegin) {
        const times = {
          week_day: item.weekDay,
          end_time: item.workTimeEnd.endFormated,
          start_time: item.workTimeBegin.beginFormated
        }
        return item.times = times;
      }
    });
  }

  changePassword(data) {
    return this.auth.getTidyToken().then(response => {
      const res: any = response;

      const headers = new HttpHeaders({
        'Authorization': `bearer ${res.access_token}`,
        'Content-Type': 'application/json'
      });

      return this.http.post(`${this.apiUrl}api/v1/customers/change-password`,
        JSON.stringify(data), { headers: headers })
        .toPromise();
    });
  }

  recoverPassword(requestData) {
    return this.auth.getTidyToken().then(response => {
      const res: any = response;

      const headers = new HttpHeaders({
        'Authorization': `bearer ${res.access_token}`,
        'Content-Type': 'application/json'
      });

      return new Promise((resolve, reject) => {
        this.http.post(
          `${this.apiUrl}api/v1/customers/recover-password`,
          JSON.stringify(requestData),
         { headers: headers }
        )
          .subscribe(
          data => resolve(data),
          err => reject(err)
          );
      })
    });
  }
}

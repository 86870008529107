<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="!isNormalBookingFlow"
    [title]="page?.title"
    [showLinkIcon]="!isNormalBookingFlow"
    [linkIcon]="'trash'"
    (linkAction)="deleteAddress()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="!isNormalBookingFlow">
        <tidy-row>
          <ng-container *ngIf="address?.address_name">
            <tidy-text
              [header]="address?.address_name + ' '"
              [translate]="false">
            </tidy-text>
          </ng-container>
          <ng-container *ngIf="!address?.address_name">
            <tidy-text
              [header]="address?.address1 + ' '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="address?.address2 !== null"
              [header]="address?.address2 + ' '"
              [translate]="false">
            </tidy-text>
          </ng-container>
          <tidy-text
            [header]="address?.city +
            ', ' +
            address?.zip"
            [translate]="false">
          </tidy-text>
        </tidy-row>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'This information is important to help your Pro get in, and never used for marketing. The more detail the better. Photos help a lot!'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="!form?.valid && showAlert">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/alert.svg'">
          </tidy-image>
          <tidy-text
            [header]="alertHeader">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Details help jobs happen smoothly.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card *ngIf="isNormalBookingFlow && showPhoneEmailFields">
        <form [formGroup]="form" [id]="formIds.PROPERTY_INFO_FORM">
          <tidy-row class="field-bottom-padding field-top-padding">
            <tidy-input
              formControlName="email"
              [label]="'Email'"
              [form]="form.controls.email"
              type="email"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid email.'"
              [icon]="'assets/svg/mail-outline.svg'"
              [inputId]="inputIds.EMAIL_FIELD"
              [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input-international-phone
              formControlName="phone"
              [label]="'Phone'"
              [form]="form.controls.phone"
              [inputMode]="'numeric'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [inputId]="inputIds.PHONE_FIELD"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
            </tidy-input-international-phone>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" [id]="formIds.PROPERTY_INFO_FORM">
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/car.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'How to Park'">
            </tidy-text>
            <tidy-text
              [body]="'Add Photo'"
              (action)="getImg('parking')"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [label]="'What kind of parking?'"
              [form]="form.controls.paid_parking"
              formControlName="paid_parking"
              [items]="parkingTypes"
              (optionChange)="clearFields()">
            </tidy-select>
          </tidy-row>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [label]="'What kind of spot?'"
              [form]="form.controls.parking_spot"
              formControlName="parking_spot"
              [items]="form.controls.paid_parking.value ? paidParkingSpots : freeParkingSpots">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="form.controls.paid_parking.value">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-select
                [label]="'How will you pay?'"
                [form]="form.controls.parking_pay_with"
                formControlName="parking_pay_with"
                [items]="payOptions">
              </tidy-select>
            </tidy-row>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-select
                [label]="'How much will you reimburse?'"
                [form]="form.controls.max_parking_cost"
                formControlName="max_parking_cost"
                [items]="maxCostOptions">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'Any notes to help park?'"
              formControlName="parking_notes"
              [form]="form.controls.parking_notes">
            </tidy-input>
          </tidy-row>
          <photo-caption
            [photoUrls]="parkingPhotoUrls"
            (deletePhoto)="confirmDelete($event)"
            [type]="'parking'">
          </photo-caption>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" [id]="formIds.PROPERTY_INFO_FORM">
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/unlock.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'How to Get In'">
            </tidy-text>
            <tidy-text
              [body]="'Add Photo'"
              (action)="getImg('access')"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'How do they access the property?'"
              formControlName="home_access"
              [form]="form.controls.home_access">
            </tidy-input>
          </tidy-row>
          <photo-caption
            [photoUrls]="accessPhotoUrls"
            (deletePhoto)="confirmDelete($event)"
            [type]="'access'">
          </photo-caption>
          <ng-container *ngIf="hasLockDevicesIntegrated">
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Smart Lock Integrated: The RemoteLock code will be available for the pro the day of the job.'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" [id]="formIds.PROPERTY_INFO_FORM">
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/lock.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'How to Close Up'">
            </tidy-text>
            <tidy-text
              class="link"
              [body]="'Add Photo'"
              (action)="getImg('closing')">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'What should they do as they leave?'"
              formControlName="home_close"
              [form]="form.controls.home_close">
            </tidy-input>
          </tidy-row>
          <photo-caption
            [photoUrls]="closingPhotoUrls"
            (deletePhoto)="confirmDelete($event)"
            [type]="'closing'">
          </photo-caption>
          <ng-container *ngIf="hasLockDevicesIntegrated">
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Smart Lock Integrated: The RemoteLock code will be available for the pro the day of the job.'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="save.bind(this)"
        [text]="page?.button"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PillModel, ProModel } from 'src/models/thumbtack.model';

@Component({
  selector: 'tidy-pro-card',
  templateUrl: 'pro-card.component.html',
  styleUrls: ['./pro-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProCardComponent implements OnInit, OnChanges {
  pills: PillModel[] = [];
  stars = Array(5).fill(0);
  rating = 0;
  isReviewExpanded = false;

  @Input() pro: ProModel;
  @Output() selectedPro = new EventEmitter<ProModel>();

  constructor() {}

  ngOnInit(): void {
    this.getPills();
    this.getRating();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPills();
    this.getRating();
  }

  getPills(): void {
    if (!this.pro?.pills) {
      return;
    }
    const pillsReferences = {
      popular: {
        label: 'In high demand',
        class: 'low-price',
      },
      licensed: {
        label: 'Licensed pro',
        class: 'low-price',
      },
      low_price: {
        label: 'Great value',
        class: 'low-price',
      },
    };
    this.pills = this.pro.pills?.map((pill) => {
      return {
        label: pillsReferences[pill].label,
        class: 'default-pill ' + pillsReferences[pill].class,
      };
    })
  }

  getRating(): void {
    this.rating = Math.floor(Math.round(this.pro?.rating * 2) / 2);
  }

  toggleReview(): void {
    this.isReviewExpanded = !this.isReviewExpanded;
  }

  onClickSelectPro(pro: ProModel): void {
    this.selectedPro.emit(pro);
  }
}

<div class="photo-note-container">
  <ng-container *ngIf="photos.length > 0">
    <ion-slides [pager]="photos.length > 1" [options]="{spaceBetween:5, slidesPerView:1}" centeredSlides>

      <ion-slide *ngFor="let photo of photos; let i = index">
        <img class="img-photo" [src]="photo.photo_url"/>
        <span class="slide-index white-shadow">{{i+1}}/{{photos.length}}</span>
        <span *ngIf="!noEdit" tappable class="delete-button white-shadow" tappable (click)="confirmDeletePhoto(i)">&times;</span>
        <div *ngIf="!noEdit || photo.photo_note_caption" class="photo-caption" [ngClass]="photo.photo_note_caption == '' ? 'empty-caption': 'full-caption'">
          <span class="pencil-icon" *ngIf="!noEdit"></span>
          <form *ngIf="!tempPhotos" [formGroup]="photoCaption" class="caption-form">
          <ion-item class="caption-item">
            <ion-textarea [disabled]="noEdit" customTextarea [text]="photo.photo_note_caption" placeholder="Add Caption" class="custom-textarea caption-input" formControlName="{{photo.id || photo.key}}" [runNgAfterViewChecked]="true">
                <ion-spinner></ion-spinner>
            </ion-textarea>
          </ion-item>
          </form>
          <ion-item *ngIf="tempPhotos" class="caption-item">
            <ion-textarea [(ngModel)]="photo.photo_note_caption" customTextarea [text]="photo.photo_note_caption" placeholder="Add Caption" class="custom-textarea caption-input" [runNgAfterViewChecked]="true">
              <ion-spinner></ion-spinner>
            </ion-textarea>
          </ion-item>
        </div>
      </ion-slide>
    </ion-slides>
  </ng-container>
</div>

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Reservation'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">
      <tidy-card>
        <form [formGroup]="form" action="#">
          <tidy-row>
            <tidy-select
              [label]="'Property'"
              [items]="addressFilter"
              [multiple]="false"
              [form]="form.get('property')"
              [icon]="'assets/svg/home-outline.svg'"
              [errorMessage]="'Please select a property.'"
              [submitted]="submitted"
              formControlName="property">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-datepicker
              label="Check In Date"
              [icon]="'assets/svg/calendar-outline.svg'"
              formControlName="checkInDate"
              [form]="form.controls.checkInDate"
              errorMessage="Please enter a valid date."
              [submitted]="submitted">
            </tidy-datepicker>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [label]="'Check In Time'"
              [icon]="'assets/svg/time-outline.svg'"
              [form]="form.controls.checkInTime"
              formControlName="checkInTime"
              [items]="timesItems">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-datepicker
              label="Check Out Date"
              [icon]="'assets/svg/calendar-outline.svg'"
              formControlName="checkOutDate"
              [form]="form.controls.checkOutDate"
              errorMessage="Please enter a valid date."
              [submitted]="submitted">
            </tidy-datepicker>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [label]="'Check Out Time'"
              [icon]="'assets/svg/time-outline.svg'"
              [form]="form.controls.checkOutTime"
              formControlName="checkOutTime"
              [items]="timesItems">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="guests"
              [form]="form.controls.guests"
              [type]="'number'"
              label="Guests"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="adults"
              [form]="form.controls.adults"
              [type]="'number'"
              label="Adults"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="children"
              [form]="form.controls.children"
              [type]="'number'"
              label="Children"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="pets"
              [form]="form.controls.pets"
              [type]="'number'"
              label="Pets"
              [icon]="'assets/svg/paw-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row style="padding-top: 1rem;">
            <tidy-button
              [text]="'Add Reservation'"
              class="primary"
              [action]="addReservation.bind(this)">
            </tidy-button>
          </tidy-row>
        </form>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

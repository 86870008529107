import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-alert',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./alert.component.scss'],
  template: `
      <ng-content></ng-content>
  `
})

export  class AlertComponent {
  @Input() showArrow: boolean;

}

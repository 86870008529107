import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';

import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';

import { AutoCompleteAddressModel } from 'src/models/autocomplete-address.model';
import { Logger } from '../logger';

declare const google: any;

export interface AddressComponentsModel {
  city: string,
  state: string
}

@Injectable()
export class AddressForm {

  acService: any;
  autocompleteItems = [];
  autocompleteAddress: AutoCompleteAddressModel[];

  constructor(
    private googleGeocode: GoogleGeocode,
    private platform: Platform,
    public _zone: NgZone,
    private logger: Logger
  ) {
    this.acService = new google.maps.places.AutocompleteService();
  }

  async updateSearch(address: string, country: string = 'US') {
    if (address.length <= 1) {
      return [];
    }
    const config = {
      types: ['geocode'],
      input: address,
      componentRestrictions: { country: country }
    };

    await this.acService.getPlacePredictions(config,  (predictions, status) => {
      if (predictions) {
        this._zone.run(() => {
          this.autocompleteItems = predictions;
        });
      } else {
        this.autocompleteItems = [];
      }
    });

    const autocompleteAddress = this.autocompleteItems.map(item => {
      return {
        placeId: item.place_id,
        value: item.description,
        display: item.description
      };
    });
    return autocompleteAddress;
  }

  chooseItem = async (item) => {
    const response: any = await this.googleGeocode.getGeocode(item.placeId);
    const address_components = response.results[0].address_components;
    const zipcode = this.findPostalCode(address_components);
    return zipcode;
  }

  async getAdditonalAddressInfo(placeId) {
    try {
      const response: any = await this.googleGeocode.getGeocode(placeId);
      const addressComponents = response.results[0].address_components;

      let addressList = { state: '', city: '' };

      addressComponents.map((address) => {
        if (address.types.includes('locality') || address.types.includes('postal_town') || address.types.includes('sublocality')) {
          addressList = { ...addressList, city: address.long_name };
        }

        if (address.types.includes('administrative_area_level_1')) {
          addressList = { ...addressList, state: address.long_name };
        }
      });

      return addressList;
    } catch (err) {
      this.logger.error(err, 'error getting address additional data');
      return { state: '', city: '' };
    }
  }

  findPostalCode(addressComponents) {
    let postalCode = '';
    if (addressComponents) {
      for (const i in addressComponents) {
        if (addressComponents[i].types[0] === 'postal_code') {
          postalCode = addressComponents[i].long_name;
        }
      }
    } else {
      postalCode = null;
    }
    return postalCode;
  }

  addressError(formValue) {
    let hasError = false;
    const address = formValue.toLowerCase();
    const errors = [
      'po box',
      'p.o. box',
      'p o box',
      'p. o box',
      'p o. box',
      'p.o box',
    ];
    errors.map((error) => {
      if (address.includes(error)) {
        return hasError = true;
      }
    })
    return hasError;
  }
}

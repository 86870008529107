import { Util } from 'src/shared/util/util';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Client } from 'src/providers/client/client';
import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  selector: 'past-jobs',
  templateUrl: 'past-jobs.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['past-jobs.scss'],
})
export class PastJobsPage implements OnInit {
  cleaningHistory: any;
  form: UntypedFormGroup;
  shownHistory: any;
  selectedAddress = '';
  addressFilter: TidySelectNumberValueModel[];
  cantGoBack: boolean;
  addressId: number;
  loaded: boolean;
  totalRecords: number;
  pageSize: number = 10;
  dateRangeForm: UntypedFormGroup;
  selectOptions: any;
  filters: any = {};
  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    private reportIssue: ReportIssue,
    private util: Util,
  ) {
    this.form = this.fb.group({
      property: [''],
    });
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: ''
    });
    this.selectOptions = [
      { viewValue: 'Custom', value: 'custom' },
      { viewValue: 'Today', value: 'today' },
      { viewValue: 'Last Week', value: 'last_week' },
      { viewValue: 'Last Month', value: 'last_month' },
      { viewValue: 'All Time', value: 'all_time' }
    ];
  }

  @Loading('', true)
  async ngOnInit(): Promise<any> {
    try {
      this.dateRangeForm.patchValue({ customStringSelect: 'last_week' });
      this.addressId = Number(this.navCtrl.getParam('addressId')) || 0;
      if (this.addressId && this.addressId !== 0) {
        this.filters['address_ids'] = [this.addressId];
        this.form.patchValue({ property: [this.addressId] });
      }
      await this.selectStringDateRange('last_week');
      this.cantGoBack = this.route.snapshot.data.cantGoBack;
      await this.populateAddressFilter();
      this.loaded = true;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadCleaningsHistory(page: number = 1, perPage: number = 10, filters = {}) {
    const loading = await this.util.showLoading();
    try {
      const cleaningHistory =
        await this.client.getCleaningsHistoryWithTotalRecords(page, perPage, filters);
      this.totalRecords = cleaningHistory.totalRecords;
      this.cleaningHistory = cleaningHistory.body.flatMap((cleaning) => {
        cleaning.homekeeper_jobs = cleaning?.homekeeper_jobs || [
          {
            homekeeper: {
              name: cleaning?.homekeeper,
            },
          },
        ];
        const status = this.reportIssue.getJobStatusData(
          cleaning?.homekeeper_jobs,
          cleaning?.job_progress,
          cleaning
        );
        cleaning.status = status;
        if (cleaning.cleaning_id !== null) {
          return cleaning;
        } else {
          return [];
        }
      });
      if (this.addressId !== 0) {
        this.shownHistory = this.cleaningHistory.filter(
          (item) => item.address_id == this.addressId
        );
      } else {
        this.shownHistory = this.cleaningHistory;
      }
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    } finally {
      loading.dismiss();
      window.scrollTo(0, 0);
    }
  }

  selectPage(pagination: {
    pageIndex: number;
    pageSize: number;
    previousPageIndex: number;
    length: number;
  }) {
    this.loadCleaningsHistory(pagination.pageIndex + 1, pagination.pageSize, this.filters);
  }

  selectCustomDateRange(selection) {
    const start = new Date(selection.start);
    const end = new Date(selection.end);
    const startDate = LuxonDateTime.fromJSDate(start).toISODate();
    const endDate = LuxonDateTime.fromJSDate(end).toISODate();
    this.filters['from_date'] = startDate;
    this.filters['until_date'] = endDate;
    this.loadCleaningsHistory(1, this.pageSize, this.filters);
  }

  async selectStringDateRange(selection) {
    const today = LuxonDateTime.local();
    let startDate = today.toISODate();
    let endDate = today.toISODate();
    if (selection === 'last_week') {
      startDate = today.minus({ days: 7 }).toISODate();
    }
    if (selection === 'last_month') {
      startDate = today.minus({ months: 1 }).toISODate();
    }
    if (selection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    this.filters['from_date'] = startDate;
    this.filters['until_date'] = endDate;
    await this.loadCleaningsHistory(1, this.pageSize, this.filters);
  }

  parseStatus(cleaning: any): string {
    if (!cleaning?.job_progress) {
      return cleaning?.status || '';
    }
    const parsedStatus = cleaning.job_progress
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return parsedStatus;
  }

  goToBillingHistory() {
    this.goToPage('billing-history', {}, BillingHistoryPage);
  }

  async populateAddressFilter() {
    this.addressFilter = this.navCtrl.getParam('addressFilter');
    if (this.addressFilter === null) {
      const addresses = await this.client.getAddresses();
      this.addressFilter = await this.client.parseAddressList(addresses, true);
    }
    this.addressFilter.pop();
  }

  goToCleaningDetail(cleaning) {
    localStorage.setItem('pastJobBackPage', 'past-jobs');
    if (cleaning.is_private) {
      this.navCtrl.navigateForward([
        `past-private-job/${cleaning.cleaning_id}`,
      ]);
    } else {
      this.navCtrl.navigateForward([`past-job/${cleaning.cleaning_id}`]);
    }
  }

  changeAddress(addressIds) {
    this.filters['address_ids'] = addressIds;
    this.loadCleaningsHistory(1, this.pageSize, this.filters);
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }

  async goToPage(url, params, component = null) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve(
        'dialog-right-side-open'
      );
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }
}

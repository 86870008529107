import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';

import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';

import { WindowService } from 'src/shared/providers/window.service';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';
import { ChangeSubscriptionPage } from './change-subscription/change-subscription';
import { GetHelpPage } from '../concierge/get-help/get-help';

@Component({
  templateUrl: 'subscription.html'
})

export class SubscriptionsPage extends TimeoutableComponent implements OnInit {

  billableAddresses: number;
  bill: number;
  currentSubscription: any;
  errorMessage: string;
  nextSubscription: any;
  subscriptionInvoices: any;
  subscriptionType: string;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private me: Me,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private tidyCurrencyPipe: TidyCurrencyPipe,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Subscription');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      const subscriptions = await this.me.getSubscriptions();
      this.subscriptionInvoices = await this.me.getSubscriptionInvoices();
      this.filterSubscriptions(subscriptions);
      this.billableAddresses = this.currentSubscription?.customer_address_count - this.currentSubscription?.plan?.free_address_count;
      this.subscriptionType = this.currentSubscription?.plan?.renewal_frequency;
      this.bill = this.currentSubscription?.plan?.amount * (this.billableAddresses);
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  filterSubscriptions(subscriptions) {
    if (subscriptions.length > 1) {
      subscriptions.map((subscription) => {
        if (subscription.is_activated_in_next_cycle) {
          this.nextSubscription = subscription;
        } else {
          this.currentSubscription = subscription;
        }
      });
    } else {
      this.currentSubscription = subscriptions[0];
    }
    if (this.currentSubscription) {
      this.currentSubscription.invoice = this.subscriptionInvoices.find(subInvoice => {
        return subInvoice?.subscription?.id === this.currentSubscription.id;
      });
    }
  }

  goToContactConcierge() {
    this.rightSidePanelService.navigateTo('get-help', {}, GetHelpPage);
  }

  async changeToYearly() {
    const currentMonthlyBill = this.tidyCurrencyPipe.transform(this.bill);
    const currentYearlyBill = this.tidyCurrencyPipe.transform(this.bill*12);
    const preview = await this.me.getSubscriptionChangePreview('customer_app_yearly_subscription_1');
    const amountCharged = this.tidyCurrencyPipe.transform(preview.preview_next_invoice.total_amount_to_bill);
    const bill = preview.preview_next_invoice.amount_per_address * preview.preview_next_invoice.customer_total_paid_addresses_count;
    const newBill = this.tidyCurrencyPipe.transform(bill);
    const savings = this.tidyCurrencyPipe.transform(this.bill*12 - bill);
    const params: ConfirmPageParamsModel = {
      title: 'Change to Yearly?',
      body: 'Your monthly subscription costs a total of ' + currentMonthlyBill + ' per month (' + currentYearlyBill + ' total per year). If you change to yearly, we will bill you ' + newBill + ' per year (a ' + savings +' savings). Confirm and you will immediately be charged the pro rata difference of ' + amountCharged + '.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmChangeToYearly.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmChangeToYearly() {
    try {
      const payload = {
        plan_type_key: 'customer_app_yearly_subscription_1'
      }
      await this.me.changeSubscription(payload);
      const params = {
        header: 'Subscription Changed',
        body: '',
        buttonText: 'Ok',
        buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
      };
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async changeToMonthly() {
    const currentYearlyBill = this.tidyCurrencyPipe.transform(this.bill);
    const currentMonthlyBill = this.tidyCurrencyPipe.transform(this.bill/12);
    const newBill = this.tidyCurrencyPipe.transform(this.billableAddresses*3000);
    const params: ConfirmPageParamsModel = {
      title: 'Change to Monthly?',
      body: 'You are currently charged ' + currentYearlyBill + ' per year (which averages to ' + currentMonthlyBill + ' per month). If you change to monthly, you will be billed ' + newBill + ' per month. This will start at the end of your current billing period.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmChangeToMonthly.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmChangeToMonthly() {
    try {
      const payload = {
        plan_type_key: 'customer_app_monthly_subscription_1'
      }
      await this.me.changeSubscription(payload);
      const params = {
        header: 'Subscription Changed',
        body: '',
        buttonText: 'Ok',
        buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
      };
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  goToChangeSubscriptionPage() {
    const params = {
      currentSubscription: this.currentSubscription,
      nextSubscription: this.nextSubscription,
      billableAddresses: this.billableAddresses
    }
    this.rightSidePanelService.navigateTo('change-subscription', params, ChangeSubscriptionPage);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Edit To-Do Inputs'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngFor="let room of rooms; let roomIndex = index">
        <ng-container *ngIf="!room?.is_do_not_clean_room">
          <tidy-row *ngIf="room?.todos?.length > 0" class="vertical-align-middle">
            <tidy-image
              *ngIf="room?.category"
              [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="room?.name">
            </tidy-text>
          </tidy-row>

          <ng-container *ngFor="let todoItem of room?.todos; let toDoIndex = index">
            <tidy-card *ngIf="!room.complete" [id]="todoItem.id">
              <ng-container *ngIf="todoItem?.customFieldForm">
                <tidy-row class="vertical-align-middle">
                  <div [ngStyle]="todoItem?.important ? {'background': '#FDF8BF', 'padding': '2px'} : {}">
                    <tidy-image
                      [src]="'assets/svg/star.svg'"
                      class="text-top-align">
                    </tidy-image>
                    <tidy-text
                      [body]="todoItem?.title">
                    </tidy-text>
                  </div>
                </tidy-row>
                <tidy-row class="extra-top-padding">
                  <form [formGroup]="rooms[roomIndex]?.todos[toDoIndex].customFieldForm">
                    <ng-container *ngFor="let field of todoItem?.options; let last = last">
                      <ng-container *ngIf="field?.name?.includes('Take Photo')">
                        <tidy-row>
                          <tidy-text
                            [body]="field?.notes">
                          </tidy-text>
                        </tidy-row>
                        <tidy-button
                          *ngIf="isNativeMobile"
                          [text]="'Take Photo'"
                          [action]="takeCustomToDoPhoto.bind(this, field, toDoIndex, roomIndex, todoItem, room)"
                          [class]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id].invalid && rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id].touched ? 'secondary take-photo-button-animation' : 'secondary'">
                        </tidy-button>
                        <tidy-file-upload
                          [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                          [images]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id].value || []"
                          (addedImagesChange)="uploadCustomToDoPhoto($event, field, toDoIndex, roomIndex, todoItem, room)"
                          (removedImageChange)="removeCustomToDoPhoto($event, field, toDoIndex, roomIndex, todoItem, room)">
                        </tidy-file-upload>
                      </ng-container>
                      <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Text Area' || field?.name == 'Count'">
                        <tidy-textarea
                          [label]="field?.notes"
                          [formControlName]="field.id"
                          [form]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id]"
                          (keyup)="updateInputedToDos(todoItem, room)"
                          [submitted]="submitted"
                          [errorMessage]="'Please enter an answer.'">
                        </tidy-textarea>
                      </tidy-row>
                      <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Select'">
                        <tidy-radio-button
                          [label]="field?.notes"
                          [form]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id]"
                          [formControlName]="field.id"
                          [items]="field?.allowed_values"
                          (optionChange)="updateInputedToDos(todoItem, room)"
                          [submitted]="submitted"
                          [errorMessage]="'Please select an option.'">
                        </tidy-radio-button>
                      </tidy-row>
                      <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name == 'Take Inventory'">
                        <tidy-input
                          [formControlName]="field.id"
                          [form]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id]"
                          [label]="field?.notes"
                          inputMode="numeric"
                          [mask]="'number'"
                          (keyup)="updateInputedToDos(todoItem, room)"
                          [submitted]="submitted"
                          [errorMessage]="'Please enter an amount.'">
                        </tidy-input>
                      </tidy-row>
                      <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="field?.name?.includes('Checkbox')">
                        <tidy-row>
                          <tidy-text
                            [body]="field?.notes">
                          </tidy-text>
                        </tidy-row>
                        <ng-container *ngFor="let checkbox of field?.allowed_values">
                          <tidy-checkbox
                            [form]="rooms[roomIndex].todos[toDoIndex].customFieldForm.controls[field.id + '-' + checkbox.viewValue]"
                            [formControlName]="field.id + '-' + checkbox.viewValue"
                            [items]="[checkbox]"
                            (checkedChange)="updateInputedToDos(todoItem, room)">
                          </tidy-checkbox>
                        </ng-container>
                      </tidy-row>
                    </ng-container>
                  </form>
                </tidy-row>
                <tidy-row class="extra-top-padding extra-bottom-padding">
                  <tidy-divider></tidy-divider>
                </tidy-row>
              </ng-container>
              <tidy-row [ngClass]="todoItem?.customFieldForm ? 'js-todo-group' : 'js-todo-group'">
                <tidy-radio-button
                  *ngIf="todoItem?.customFieldForm"
                  [(ngModel)]="todoItem.performance"
                  (optionChange)="updateInputedToDos(todoItem, room)"
                  class="horizontal-align no-wrap"
                  [items]="[{viewValue: 'Not Done', value: 'not_performed'}, {viewValue: 'Done', value: 'performed'}]">
                </tidy-radio-button>
                <tidy-radio-button
                  *ngIf="!todoItem?.customFieldForm"
                  [(ngModel)]="todoItem.performance"
                  (optionChange)="updateInputedToDos(todoItem, room)"
                  class="horizontal-align no-wrap"
                  [label]="todoItem.title"
                  [helper]="todoItem.note"
                  [icon]="todoItem.important ? 'assets/svg/star.svg' : ''"
                  [highlightLabel]="todoItem.important"
                  [items]="[{viewValue: 'Not Done', value: 'not_performed'}, {viewValue: 'Done', value: 'performed'}]">
                </tidy-radio-button>
              </tidy-row>
            </tidy-card>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="room?.is_do_not_clean_room">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/rooms/' + room?.category + '.png'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="room?.name">
            </tidy-text>
          </tidy-row>
          <tidy-card>
            <tidy-row>
              <tidy-text
                [body]="'DO NOT clean this room'"
                class="red">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-button
        [text]="'Save Changes'"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TimeoutError } from 'rxjs';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';

@Component({
  selector: 'confirm-page',
  templateUrl: './confirm.component.html',
})

export class ConfirmPage {

  @Input() title: string = '10';
  @Input() body: string;
  @Input() backText: string;
  @Input() confirmText: string;
  @Input() confirmAction: (data?) => Promise<any>;
  @Input() confirmActionParams: any = null;
  @Input() customBackAction: (data?) => Promise<any>;
  @Input() blockAction: (data?) => Promise<any>;
  @Input() customBackParams: any = null;
  @Input() sameRowButtons = true;
  @Input() confirmButtonClass = 'primary';
  @Input() backButtonClass = 'secondary';
  @Input() showAutomaticBookingQuestion = false;
  @Input() reservationDateTime = ''
  errorMessage = '';

  constructor(
    private modalCtrl: ModalController,
    private timeoutErrorHandler: TimeoutErrorHandler
  ) {}

  backAction = async () => {
    if (this.customBackAction) {
      try {
        await this.customBackAction(this.customBackParams);
      } catch (err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    } else {
      this.modalCtrl.dismiss();
    }
  }

  async confirm() {
    try {
      await this.confirmAction(this.confirmActionParams);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async triggerBlockAction() {
    try {
      await this.blockAction(this.confirmActionParams);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  timeoutHandler(error) {
    if (error instanceof TimeoutError) {
      this.title = this.timeoutErrorHandler.timeoutParams.header;
      this.body = this.timeoutErrorHandler.timeoutParams.body;
      this.confirmText = this.timeoutErrorHandler.timeoutParams.buttonText;
    }
    throw error;
  }

  updateAutomaticBooking(selection) {
    selection = !selection;
    localStorage.setItem('keepAutomaticBookingOn', selection.toString());
  }

}

export interface ConfirmPageParamsModel {
  title: string;
  body: string;
  backText: string;
  confirmText: string;
  confirmAction: (data?) => Promise<any>;
  confirmActionParams?: any;
  customBackAction?: (data?) => Promise<any>;
  blockAction?: (data?) => Promise<any>;
  customBackParams?: any;
  sameRowButtons?: boolean;
  confirmButtonClass?: string;
  backButtonClass?: string;
  reservationDateTime?: string;
  showAutomaticBookingQuestion?: boolean;
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'payment-methods.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-methods.scss'],
})
export class PaymentMethodsPage extends TimeoutableComponent implements OnInit {
  card: any;
  form: UntypedFormGroup;
  errorMessage: string;
  isRightSideContent = true;
  paymentMethods: any[];
  filteredPaymentMethods: any[];
  paymentMethodsItems: any[];
  statusItems: any[];
  selectedPaymentMethods: string[] = [];
  selectedStatuses: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private paymentMethodsProvider: PaymentMethodsProvider,
    private util: Util
  ) {
    super();
    this.form = this.fb.group({
      paymentMethod: [''],
      status: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent =
        (await this.storage.retrieve('dialog-right-side-open')) || false;
      this.rightSidePanelService.setDialogPageTitle('Payment Methods');
      this.loaded = false;
      const paymentMethods = await this.paymentMethodsProvider.getPaymentMethods(true);
      this.paymentMethods = this.util.parsePaymentMethods(paymentMethods);
      this.filteredPaymentMethods = this.paymentMethods;
      this.paymentMethodsItems =this.getPaymentMethodsItems();
      this.statusItems = this.getPaymentMethodStatuses();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.loaded = true;
    }
  }

  getPaymentMethodsItems() {
    return [
      { viewValue: 'Card', value: 'card', icon: 'assets/svg/card.svg' },
      {
        viewValue: 'Bank Account',
        value: 'us_bank_account',
        icon: 'assets/img/bank.png',
      },
    ];
  }

  getPaymentMethodStatuses() {
    return [
      {
        viewValue: 'Succeeded',
        value: 'succeeded',
        icon: 'assets/img/map-black-check.svg'
      },
      {
        viewValue: 'Action Required',
        value: 'action_required',
        icon: 'assets/img/concierge-action.svg'
      }
    ];
  }

  changePaymentMethod(value: string[]) {
    this.selectedPaymentMethods = value;
    this.filteredPaymentMethods = this.paymentMethods.filter(
      (paymentMethod) =>
        (value.length === 0 || value.includes(paymentMethod.type)) &&
        (this.selectedStatuses.length === 0 ||
          this.selectedStatuses.includes(paymentMethod.status))
    );
  }

  changeStatus(value: string[]) {
    this.selectedStatuses = value;
    this.filteredPaymentMethods = this.paymentMethods.filter(
      (paymentMethod) =>
        (value.length === 0 || value.includes(paymentMethod.status)) &&
        (this.selectedPaymentMethods.length === 0 ||
          this.selectedPaymentMethods.includes(paymentMethod.type))
    );
  }

  async goToAddCard() {
    this.storage.delete('dialog-params');
    const params = {
      paymentMethods: this.paymentMethods,
    };
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  handleAction(event: { type: string; paymentMethod: any }) {
    if (event.type === 'refresh') {
      this.ngOnInit();
    }
  }
}

import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Schedule } from 'src/providers/schedule/schedule';

import { ClientSettingsModel } from 'src/models/client.model';
import { ScheduleCardModel } from 'src/models/schedule.model';

import { Loading } from 'src/shared/components/loading/loading';
import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'contact-pro.html',
})

export class ContactProPage implements OnInit {

  pros: any;
  settings: ClientSettingsModel;
  card: ScheduleCardModel;
  loaded: boolean;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private util: Util,
    private windowService: WindowService
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.settings = await this.client.getClientSettings();
      const noPhoneAdded = this.settings.profile.phone === null;
      if (noPhoneAdded) {
        return this.goToAddPhonePage();
      }
      this.card = await this.navCtrl.getParam('card');
      const contact = await this.schedule.getContactData(this.card.id);
      this.pros = contact.homekeepers;
      this.loaded = true;
    } catch (err) {
    }
  }

  goToAddPhonePage() {
    const successParams = {
      header: 'Please Add Phone Number',
      body: 'You cannot contact your Pro and they cannot contact you because you don\'t have a phone number on file.',
      buttonText: 'Add Phone Number',
      buttonRoute: 'contact-info'
    };
    this.util.showError('Please add a phone number. You cannot contact your Pro and they cannot contact you because you don\'t have a phone number on file.');
    this.navCtrl.navigateForward('contact-info');
  }

  goToEditContactInfo() {
    this.navCtrl.navigateForward('contact-info');
  }

  openSms(pro) {
    return window.location.href = `sms://${pro.masked_phone_number}`;
  }

  openTell(pro) {
    return window.location.href = `tel:${pro.masked_phone_number}`;
  }

  markNoShow(pro) {
    let params;
    const now = new Date();
    const twentyMinAfterStartTime = new Date(this.card.template_data.job.start_datetime_local);
    twentyMinAfterStartTime.setMinutes(twentyMinAfterStartTime.getMinutes() + 20);
    const isTooEarly = now < twentyMinAfterStartTime;
    if (isTooEarly) {
      return this.showTooEarlyForNoShow();
    } else {
      params = {
        proName: pro.first_name,
        hkId: pro.id,
        jobId: this.card.template_data.job.id
      }
      this.navCtrl.navigateForward('pro-did-not-show-up', params);
    }
  }

  showTooEarlyForNoShow() {
    this.util.showError('Please Wait to Mark No Show. Pros typically ask for a 30 minute buffer for unexpected delays. Please wait until 20 minutes after start time before reporting them as a no show.');
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const route = shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list';
    this.navCtrl.navigateForward(route);
  }
  
}

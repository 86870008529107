<ng-template #content>
  <tidy-wrapper
    [class]="isRightSideContent ? '' : 'vertical-center hasnt-top-nav'">
    <tidy-card>
      <ng-container *ngIf="loaded">
        <tidy-row *ngIf="!isRightSideContent" [align]="'center'">
          <tidy-text [title]="'Confirm' | translate"> </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!address?.address_name" [align]="'center'">
          <tidy-text [header]="'For' | translate"> </tidy-text><span>&nbsp;</span>
          <tidy-text [header]="address?.address1" [translate]="false">
          </tidy-text>
          <tidy-text
            [header]="address?.address2 !== null ? ' ' + address?.address2 : ''"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="address?.address_name" [align]="'center'">
          <tidy-text [header]="'For' | translate"> </tidy-text><span>&nbsp;</span>
          <tidy-text [header]="address?.address_name" [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-row
          *ngIf="viewData?.proName"
          [align]="'center'"
          class="extra-bottom-padding">
          <tidy-text [header]="'Booking with' | translate"> </tidy-text><span>&nbsp;</span>
          <tidy-text [header]="viewData?.proName" [translate]="false"> </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image [src]="'assets/svg/time-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="viewData?.service | titlecase | translate"> </tidy-text>
        </tidy-row>

        <!-- <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/refresh-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="viewData?.frequency">
          </tidy-text>
        </tidy-row> -->

        <ng-container>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/svg/calendar-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text [body]="viewData?.duration"> </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/svg/card-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="'Total:' | translate"> </tidy-text><span>&nbsp;</span>
          <tidy-text [body]="viewData?.price | tcurrency"> </tidy-text>
        </tidy-row>

        <!-- <tidy-row *ngIf="!selectedPrivatePro && priceExperiment == 'showCrossedOutDiscount' && viewData?.frequency !== 'One Time'">
          <tidy-text
            [body]="'*Saving 20% with recurring plan'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!selectedPrivatePro && isUpdatingSubscription">
          <tidy-text
            [body]="'*' + (viewData?.price | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'for job (saving 20%)'">
          </tidy-text><br>
          <tidy-text
            [body]="(currentSubscription?.amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'for'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="me.getSubscriptionType(currentSubscription?.key)">
          </tidy-text>
          <tidy-text
            [body]="'remove'"
            class="link"
            (action)="updateSubscription()">
          </tidy-text>
        </tidy-row> -->

        <!-- <tidy-row *ngIf="!viewData?.service?.includes('hour')" class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/time-outline.svg'"
            class="body-size">
          </tidy-image>
          <ng-container *ngIf="viewData?.billingType == 'hourly'">
            <tidy-text
              [body]="'Minimum'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(viewData?.duration | duration)">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="viewData?.billingType !== 'hourly'"
            [body]="viewData?.duration | duration">
          </tidy-text>
        </tidy-row> -->
      </ng-container>

      <ng-container *ngIf="loaded">
        <tidy-two-column-row>
          <tidy-button
            [text]="'Go Back' | translate"
            [action]="goBack.bind(this)"
            class="secondary">
          </tidy-button>

          <tidy-button
            [text]="'Confirm' | translate"
            [action]="confirmBooking.bind(this)"
            [submitted]="submitted"
            class="primary">
          </tidy-button>
        </tidy-two-column-row>
      </ng-container>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { transition, style, animate, trigger } from '@angular/animations';
import moment from 'moment-timezone';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { Genome } from 'src/providers/genome/genome';

const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate('0.5s ease-out', style({
    opacity: 0
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);

@Component({
  templateUrl: 'add-item-maintenance.html',
  animations: [
    fadeOut
  ]
})

export class MaintenancesInstancePage implements OnInit {

  loaded: boolean;
  errorMessage: string;
  maintenances: any[];
  form: UntypedFormGroup;
  addressId: number;
  monthlyOptions: { viewValue: string; value: string; }[] = [
    { viewValue: 'Within a month', value: moment().subtract(1, 'months').format('YYYY-MM-DD') },
    { viewValue: '2-12 Months', value: moment().subtract(6, 'months').format('YYYY-MM-DD') },
    { viewValue: 'More than a year', value: moment().subtract(1, 'years').format('YYYY-MM-DD') }
  ];
  annualOptions: { viewValue: string; value: string; }[] = [
    { viewValue: 'Within a year', value: moment().subtract(6, 'months').format('YYYY-MM-DD') },
    { viewValue: '2-5 Years', value: moment().subtract(3, 'years').format('YYYY-MM-DD') },
    { viewValue: 'More than 6 years', value: moment().subtract(7, 'years').format('YYYY-MM-DD') }
  ];

  constructor(
    private navCtrl: CustomNavController,
    private genome: Genome,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      maintenaceDates: this.fb.array([]),
    });
  }

  get maintenaceDates() {
    return this.form.controls["maintenaceDates"] as UntypedFormArray;
  }

  addDate() {
    const dateForm = this.fb.group({
      lastMaintened: ['', Validators.required]
    });
    this.maintenaceDates.push(dateForm);
  }

  deleteDate(dateIndex: number) {
    this.maintenaceDates.removeAt(dateIndex);
  }

  @Loading('', true)
  ngOnInit() {
    this.loaded = false;
    this.maintenances = this.navCtrl.getParam('maintenances', true);
    this.addressId = this.navCtrl.getParam('addressId', true);
    this.setMaintenancesTimeframe();
    this.loaded = true;
  }

  setMaintenancesTimeframe() {
    this.maintenances.map((maintenance, index) => {
      if (maintenance.maintenance.frequency_unit === 'year') {
        this.maintenances[index].formValues = this.annualOptions;
      } else {
        this.maintenances[index].formValues = this.monthlyOptions;
      }
      this.addDate();
    });
  }

  toMainCrudPage() {
    this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
  }

  async addItem(maintenance: any, form: UntypedFormGroup, index: number, value: string) {
    form.patchValue({ lastMaintened: value });
    this.errorMessage = '';
    if (!form.valid) {
      return this.errorMessage = 'Please enter a time frame.';
    }
    const data = {
      maintenance_id: maintenance.maintenance.id,
      room_object_id: maintenance.room_object.id,
      status: 'completed',
      maintained_at: form.value.lastMaintened
    };
    try {
      await this.genome.updateMaintenanceStatus(data);
      this.deleteDate(index);
      if (this.maintenaceDates.length === 0) this.toMainCrudPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
}

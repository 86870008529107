<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="loaded ? ('Request ' + pro?.first_name) : ''"
    [closeButton]="!(cameFromBookJob || cameFromEditRequest)"
    (closeItemAction)="goToMyPros()"
    [canGoBack]="cameFromBookJob || cameFromEditRequest"
    id="top-nav-height">
    <tidy-wrapper *ngIf="loaded">

      <tidy-row>
        <tidy-divider>
        </tidy-divider>
      </tidy-row>

      <tidy-grid>
        <ion-row>
          <ion-col size="10">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/icons/number_1_white.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [actionBody]="(service?.title | titlecase) || 'Select Service '"
                class="white">
              </tidy-text>
              <tidy-text
                [actionBody]="frequencyName ? ('&nbsp- ' + frequencyName) : ''"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/icons/number_2_white.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [actionBody]="getMobileTimeTitle()"
                class="white">
              </tidy-text>
            </tidy-row>
          </ion-col>
          <ion-col size="2" class="vertical-align-center">
            <tidy-row class="extra-left-padding" *ngIf="frequencyName && serviceHasPrice()">
              <tidy-text
                [title]="bookJobService.getDisplayPriceForJob(planId, service, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, pro?.private)"
                class="green text-price">
              </tidy-text>
            </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-grid>

      <tidy-button
        [text]="'Review Request'"
        [action]="submit.bind(this)"
        [disabled]="form.invalid || selectedTimes?.length == 0"
        class="primary">
      </tidy-button>

    </tidy-wrapper>
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled right-side-extra-bottom-padding' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row>
        <tidy-text
          [title]="'Select Service'">
        </tidy-text><br>
        <ng-container *ngIf="address?.address_name">
          <tidy-text
            [body]="'@' + address?.address_name">
          </tidy-text>
        </ng-container>
        <ng-container *ngIf="!address?.address_name">
          <tidy-text
            [body]="'@' + address?.address1">
          </tidy-text>
          <tidy-text
            [body]="address?.address2 !== null ? ' ' + address?.address2 : ''">
          </tidy-text>
        </ng-container>
      </tidy-row>

      <ng-container *ngFor="let service of services; let i = index">
        <tidy-card *ngIf="showService(service)">
          <form [formGroup]="form" novalidate action="#">
            <tidy-card-button [hideArrow]="true" (action)="handleServiceClick(service)">
              <tidy-row class="position-relative">
                <tidy-grid>
                  <tidy-row>
                    <ion-row>
                      <ion-col size="10">
                        <tidy-radio-button
                          formControlName="service"
                          class="radio-card-title"
                          [form]="form.controls.service"
                          (optionChange)="selectService()"
                          [items]="[{value: service.key, viewValue: service.title | titlecase}]">
                        </tidy-radio-button>
                      </ion-col>
                      <tidy-image
                        class="width-60 height-60 justify-center position-absolute right-0 no-margin tranlate-y-10per"
                        [src]="service.image">
                      </tidy-image>
                    </ion-row>
                  </tidy-row>
                </tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="10">
                        <tidy-text
                          [body]="bookJobService.getDisplayPriceForJob(planId, service, 'One Time', true, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, pro?.private)">
                        </tidy-text>
                        <tidy-text
                          [body]="service.description">
                        </tidy-text>
                      </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-row>
              <ng-container *ngIf="form.value.service === service.key && !addingOneTimeJob" >
                <tidy-row>
                  <tidy-divider>
                  </tidy-divider>
                </tidy-row>
                <tidy-row class="no-padding">
                  <tidy-radio-button
                    chunkSize="2"
                    formControlName="frequency"
                    (optionChange)="selectFrequency($event)"
                    [form]="form.controls.frequency"
                    [items]="getFrequencyItems(service.frequencies)">
                  </tidy-radio-button>
                </tidy-row>
              </ng-container>
            </tidy-card-button>
          </form>
        </tidy-card>
      </ng-container>

      <tidy-row [align]="'center'" class="extra-bottom-padding" *ngIf="didChooseService && !showMoreServices">
        <tidy-text
          (action)="showServices()"
          [body]="'Show More Services'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="!service?.isBasePriceAllowedToBook && didChooseFrequency">
        <tidy-row class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/request-failed.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Your Price Is Low'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Your price for this service is lower than the pro\’s price so it is less likely they will accept your request. You can keep your price the same or increase it.'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [text]="'Change My Rate to ' + (service?.prices?.lowestPossibleBasePrice | tcurrency)"
          [action]="changeServiceRates.bind(this)"
          [disabled]="form.invalid"
          class="primary">
        </tidy-button>
      </tidy-alert>

      <tidy-row>
        <tidy-text
          [title]="'Select Time(s)'">
        </tidy-text>
        <ng-container *ngIf="didChooseFrequency">
          <br>
          <tidy-text
            [body]="pro?.first_name">
          </tidy-text>
          <tidy-text
            [body]="' can counter with other times.'"
            [translate]="false">
          </tidy-text>
        </ng-container>
      </tidy-row>

      <tidy-error-message
        *ngIf="notSelectedTimesError"
        [text]="'Please select a time.'">
      </tidy-error-message>

      <tidy-loader
        *ngIf="isLoadingTimes">
      </tidy-loader>

      <tidy-alert *ngIf="!didChooseFrequency && !isLoadingTimes">
        <tidy-row>
          <tidy-text
            [body]="'Select service to see times'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="didChooseFrequency && !isLoadingTimes">
        <tidy-alert *ngIf="requestableDays?.length == 0">
          <tidy-row>
            <tidy-text
              [body]="'Unfortunately '">
            </tidy-text>
            <tidy-text
              [body]="pro?.first_name"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="' cannot be requested at this time.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card *ngFor="let day of requestableDays; let dayIndex = index">

          <tidy-row class="vertical-align-center">
            <ng-container [formGroup]="weekDayCheckBox[dayIndex]">
              <tidy-checkbox
                [items]="[{viewValue: day?.displayFormat, value: true}]"
                (checkedChange)="markCheckbox($event, dayIndex)"
                formControlName="checked"
                [checked]="weekDayCheckBox[dayIndex].value.checked"
                [initValue]="weekDayCheckBox[dayIndex].value.checked">
              </tidy-checkbox>
            </ng-container>
          </tidy-row>

          <ng-container *ngFor="let formGrp of timeRangeForms[dayIndex]; let i = index">

            <tidy-two-column-row [formGroup]="formGrp" style="padding-top: 15px;">
              <tidy-select
                style="max-width: 35%; margin: 0;"
                [icon]="'assets/svg/time-outline.svg'"
                [label]="'Time'"
                [form]="formGrp.controls.start_time"
                formControlName="start_time"
                errorMessage="Select a valid time"
                [submitted]="true"
                (optionChange)="updateValuesValidity(dayIndex); startTimeChange(formGrp)"
                [items]="dayIndex === 0 ? todayStartOpts : timeOpts">
              </tidy-select>
              <span style="align-self: center; justify-content: space-around; max-width: 10%;">-</span>
              <tidy-select
                style="max-width: 35%; margin-right: 10px;"
                [icon]="'assets/svg/time-outline.svg'"
                [label]="'Time'"
                [form]="formGrp.controls.end_time"
                formControlName="end_time"
                errorMessage="Select a valid time"
                [submitted]="true"
                (optionChange)="updateValuesValidity(dayIndex)"
                [items]="endTimes(formGrp.value.start_time)">
              </tidy-select>
              <tidy-image (action)="removeTimeRange(dayIndex, i)" src="assets/img/trash-icon-outline.svg" class="icon body-size" style="max-width: 16px; align-self: center;"></tidy-image>
              <tidy-image (action)="addNewTimeRange(dayIndex)" src="assets/img/add.svg" class="icon body-size" style="max-width: 16px; align-self: center; opacity: .6; margin-left: auto;"></tidy-image>
            </tidy-two-column-row>

          </ng-container>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper class="custom-right-column" *ngIf="loaded && didChooseService && windowService.isDesktopRes">

    <tidy-row [align]="isRightSideContent ? 'left' : 'center'">
      <tidy-text
        [title]="(service?.title | titlecase) || 'Select Service'">
      </tidy-text>
    </tidy-row>

    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/refresh-circle-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="frequencyName || 'Select Frequency'">
      </tidy-text>
    </tidy-row>

    <tidy-row class="vertical-align-center" *ngIf="service?.price !== ''">
      <tidy-image
        [src]="'assets/svg/card-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        *ngIf="frequencyName && serviceHasPrice() && service?.billingType !== 'price_later' && service?.billingType !== 'hourly' && service?.price"
        [body]="service?.price | tcurrency">
      </tidy-text>
      <tidy-text
        *ngIf="frequencyName && serviceHasPrice() && service?.billingType == 'hourly'"
        [body]="(service?.price | tcurrency) + '/hr'">
      </tidy-text>
      <tidy-text
        *ngIf="service?.billingType == 'price_later' || !service?.price"
        [body]="'Priced later'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="selectedTimes.length > 0">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/svg/calendar-outline.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [body]="'Requested Times:'">
        </tidy-text>
      </tidy-row>

      <tidy-row class="extra-bottom-padding" *ngFor="let time of requests.buildRequestTimeRangeList(selectedTimes)">
        <tidy-text
          [body]="time">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <tidy-button
      [text]="'Review Request'"
      [action]="submit.bind(this)"
      [disabled]="form.invalid || selectedTimes?.length == 0"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [style.padding-top.px]="topNavHeight" [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-template #reviewBooking>
  <tidy-wrapper class="custom-right-column black-column">
    <tidy-grid>
      <ion-row>
        <ion-col size="10">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/icons/number_1_white.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [actionBody]="(service?.title | titlecase) || 'Select Service '"
              class="white">
            </tidy-text>
            <tidy-text
              [actionBody]="frequencyName ? ('&nbsp- ' + frequencyName) : ''"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/icons/number_2_white.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [actionBody]="getMobileTimeTitle()"
              class="white">
            </tidy-text>
          </tidy-row>
        </ion-col>
        <ion-col size="2" class="vertical-align-center">
          <tidy-row class="extra-left-padding" *ngIf="frequencyName && serviceHasPrice()">
            <tidy-text
              [title]="bookJobService.getDisplayPriceForJob(planId, service, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, pro?.private)"
              class="green text-price">
            </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-grid>

    <tidy-button
      [text]="'Review Request'"
      [action]="submit.bind(this)"
      [disabled]="form.invalid || selectedTimes?.length == 0"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="isRightSideContent">
  <div class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="reviewBooking"></ng-container>
  </div>
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

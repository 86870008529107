import { Component, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Concierge } from 'src/providers/concierge/concierge';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import {
  ConfirmPage,
  ConfirmPageParamsModel,
} from 'src/pages/confirm/confirm.component';
import { ConciergeRulesComponent } from 'src/pages/concierge/concierge-rules/concierge-rules';

import { Util } from 'src/shared/util/util';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  templateUrl: 'concierge-rule.html',
  selector: 'concierge-rule',
  styleUrls: ['concierge-rule.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConciergeRuleComponent {
  rule: any;
  dialogParams: any;
  form: UntypedFormGroup;
  forms: FormArray;
  isRightSideContent: boolean;
  errorMessage: string;
  submitted: boolean;
  pageTitle: string;
  popularRules = [
    {
      added: false,
      name: '+ Rule 1',
      description: 'Description 1',
      icon: 'assets/svg/information-circle-outline.svg',
    },
    {
      added: false,
      name: '+ Rule 2',
      description: 'Description 2',
      icon: 'assets/svg/information-circle-outline.svg',
    },
    {
      added: false,
      name: '+ Rule 3',
      description: 'Description 3',
      icon: 'assets/svg/information-circle-outline.svg',
    },
    {
      added: false,
      name: '+ Rule 4',
      description: 'Description 4',
      icon: 'assets/svg/information-circle-outline.svg',
    },
    {
      added: false,
      name: '+ Rule 5',
      description: 'Description 5',
      icon: 'assets/svg/information-circle-outline.svg',
    },
    {
      added: false,
      name: '+ Rule 6',
      description: 'Description 6',
      icon: 'assets/svg/information-circle-outline.svg',
    },
  ];
  cameFromOnboarding: boolean;

  constructor(
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService,
    private fb: UntypedFormBuilder,
    private storage: TidyStorage,
    private concierge: Concierge,
    private navCtrl: CustomNavController,
    private util: Util,
    private modalCtrl: ModalController,
    private onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      rule: ['', Validators.required],
    });
    this.forms = this.fb.array([]);
    this.forms.push(
      this.fb.group({
        rule: ['', Validators.required],
      })
    );
  }

  async ngOnInit() {
    this.cameFromOnboarding =
      (await this.storage.retrieve('cameFromOnboarding')) || false;
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.pageTitle = this.getParam('addEditPageTitle') || 'Add Rule';
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.rule = this.getParam('rule');
    if (this.rule) {
      this.form.patchValue({
        rule: this.rule.description,
      });
    }
  }

  getParam(param) {
    return this.dialogParams[param];
  }

  addPopularRule(rule) {
    rule.added = !rule.added;
    if (rule.added) {
      this.forms.push(
        this.fb.group({
          rule: [rule.name.replace('+', '').trim(), Validators.required],
        })
      );
    } else {
      const index = this.forms.controls.findIndex(
        (item) => item.value.rule === rule.name.replace('+', '').trim()
      );
      if (index !== -1) {
        this.forms.removeAt(index);
      }
    }
  }

  addRule() {
    this.forms.push(
      this.fb.group({
        rule: ['', Validators.required],
      })
    );
  }

  removeRule(index: number, rule: string) {
    this.popularRules.forEach((popularRule) => {
      if (popularRule.name.replace('+', '').trim() === rule) {
        popularRule.added = false;
      }
    });
    this.forms.removeAt(index);
  }

  async saveRule() {
    this.submitted = true;
    if (this.rule ? this.form.invalid : this.forms.invalid) {
      this.util.showWarning('Please fill out all fields');
      return;
    }
    try {
      const payload = {
        description: this.form.value.rule,
      };
      if (this.rule) {
        await this.concierge.updateRule(this.rule.id, payload);
        const successMessage = 'Rule updated';
        this.util.showSuccess(successMessage);
      } else {
        this.saveMultipleRules();
      }
      this.goToRulesPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    }
  }

  async saveMultipleRules() {
    const loading = await this.util.showLoading();
    try {
      await Promise.all(
        this.forms.controls.map(async (form) => {
          const payload = {
            description: form.value.rule,
          };
          await this.concierge.addRule(payload);
        })
      );
      const successMessage = 'Rules added';
      this.util.showSuccess(successMessage);
      if (this.cameFromOnboarding) {
        this.onboardingProvider.markGoalAsCompleted('createConciergeRules');
        this.onboardingProvider.setShowOnboardingOnPage(false);
        this.onboardingProvider.setShowOnboardingInHeader(true);
      }
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
      this.util.showError(this.errorMessage);
    } finally {
      loading.dismiss();
    }
  }

  async deleteRule() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Rule?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove',
      confirmAction: this.confirmDeleteRule.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal',
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmDeleteRule() {
    try {
      await this.concierge.deleteRule(this.rule.id);
      this.goToRulesPage();
      const successMessage = 'Rule deleted';
      this.util.showSuccess(successMessage);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    }
  }

  async goToRulesPage() {
    const cameFromConciergePage = this.getParam('cameFromConciergePage');
    if (cameFromConciergePage) {
      this.closePage();
    } else {
      const dialogData = {
        shouldUpdateItems: true,
        type: 'rules',
        title: 'Rules',
      };
      await this.storage.save('dialog-params', dialogData);
      await this.storage.save('shouldUpdateItems', true);
      const url = 'concierge-rules';
      const component = ConciergeRulesComponent;
      if (this.windowService.isDesktopRes) {
        await this.storage.save('dialog-right-side-open', true);
        this.rightSidePanelService.openRightSidePanel(component);
      } else {
        //TODO this isn't sending params correctly
        await this.storage.save('dialog-right-side-open', false);
        this.navCtrl.navigateForward(url);
      }
    }
  }

  closePage() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.navigateForward('concierge');
    }
  }
}

import { Injectable } from "@angular/core";
import { PlanData } from "../plans/plan-data";
import { SessionSteps } from "./sessionSteps";

import { Booking } from 'src/providers/booking/booking';
import { Client } from 'src/providers/client/client';
import { Me } from "../me/me";
import { Schedule } from 'src/providers/schedule/schedule';

@Injectable()
export class AfterLoginRedirect {
  addressesList: any;

  constructor(
    private booking: Booking,
    private client: Client,
    private planData: PlanData,
    private sessionSteps: SessionSteps,
    private schedule: Schedule,
    private me: Me
  ) {}

  async getTarget(bookingId = null) {
    const accType = await this.me.getAccType();
    if (accType == 'employer') {
      return { page: 'employer-dashboard/employer-dashboard' };
    }
    const userRole = localStorage.getItem('user_role');
    if (userRole == 'member') {
      return { page: 'dashboard' };
    }
    try {
      const billingData = await this.client.getBillingData();
      const creditBalance = billingData?.balance;
      const employerName = billingData?.balance_details[0]?.transferred_from?.account_name;
      localStorage.setItem('creditBalance', creditBalance.toString());
      localStorage.setItem('employerName', employerName);
      if (employerName && employerName !== 'undefined') {
        const addressResponse = await this.client.getAddresses(true);
        const address = addressResponse[0];
        if (!address) {
          return { page: 'add-first-property/employee' };
        } else {
          const cards = await this.schedule.getCards(address.id);
          const cleaningHistory = await this.client.getCleaningsHistory();
          if (!cards.length && !cleaningHistory.length) {
            return { page: 'get-started' };
          } else {
            return { page: 'dashboard' };
          }
        }
      }
    } catch(err) {}
    this.addressesList = await this.client.getAddresses(true);
    return this.process(this.addressesList, bookingId, accType)
  }

  async process(addresses, bookingId, accType) {
    if (addresses.length === 0) {
      await this.checkStripe();
      return {
        page: this.client.getAddFirstAddressRedirectPage(accType),
        data: {
          hasNoAddresses: true
      }
      };
    }
    if (bookingId) {
      const path = await this.booking.getBookingPath(bookingId);
      return { page: path };
    }
    localStorage.setItem('addressId', this.addressesList[0].id);
    if (this.addressesList?.length == 1 && accType !== 'rental') {
      return { page: 'schedule' };
    } else {
      return { page: 'dashboard' };
    }
  }

  async checkStripe() {
    const stripeInfo = await this.planData.getStripeInfo();
    if (stripeInfo.status === "invalid") {
      this.sessionSteps.setBookingProcess();
    }
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [title]="'Recurring Task'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-row *ngIf="maintenance">
        <tidy-text
          [body]="isSavingChanges ? 'Saving Changes..' : 'All Changes Saved.'">
        </tidy-text>
      </tidy-row>

        <tidy-card class="transparent-card">
          <form [formGroup]="maintenanceForm" novalidate action="#">
            <tidy-row>
              <tidy-input
                label="Title"
                [icon]="'assets/svg/checkbox-outline.svg'"
                [submitted]="submitted"
                class="title-input"
                [form]="maintenanceForm.get('name')"
                formControlName="name">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-textarea
                label="Description"
                [icon]="'assets/svg/create-outline.svg'"
                formControlName="description"
                [form]="maintenanceForm.get('description')"
                [submitted]="submitted"
                [errorMessage]="'Please enter a description.'">
              </tidy-textarea>
            </tidy-row>
            <tidy-row>
              <tidy-datepicker
                label="Due Next"
                [icon]="'assets/svg/calendar-outline.svg'"
                formControlName="next_do_date"
                [form]="maintenanceForm.get('next_do_date')"
                errorMessage="Please enter a valid date."
                [submitted]="submitted">
              </tidy-datepicker>
            </tidy-row>
            <tidy-two-column-row>
              <tidy-select
                style="max-width: 49%; margin-right: 2%;"
                label="Recur every"
                [icon]="'assets/svg/refresh-circle-outline.svg'"
                [items]="frequencyNumberOptions"
                [submitted]="submitted"
                [form]="maintenanceForm.get('frequency_number')"
                formControlName="frequency_number">
              </tidy-select>
              <tidy-select
                style="max-width: 49%; margin: 0;"
                label="Timeframe"
                [items]="frequencyOptions"
                [submitted]="submitted"
                [form]="maintenanceForm.get('frequency')"
                formControlName="frequency">
              </tidy-select>
            </tidy-two-column-row>
            <tidy-row>
              <tidy-select
                *ngIf="propertyOptions"
                label="Property"
                [icon]="'assets/svg/home-outline.svg'"
                [items]="propertyOptions"
                [submitted]="submitted"
                [form]="maintenanceForm.get('address_id')"
                formControlName="address_id"
                (optionChange)="addressChanged()">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [disableElement]="maintenance"
                label="Relate the task to an item?"
                [icon]="'assets/svg/help-circle-outline.svg'"
                [items]="[{value: 'yes', viewValue: 'Yes'}, {value: 'no', viewValue: 'No'}]"
                (optionChange)="selectItemRelation($event)"
                [form]="maintenanceForm.get('item_relation')"
                formControlName="item_relation">
              </tidy-select>
            </tidy-row>
            <tidy-row *ngIf="maintenanceForm.get('item_relation').value == 'yes' && roomObjectOptions?.length > 1 && maintenanceForm.get('address_id').value">
              <tidy-text
                *ngIf="!maintenanceForm.get('address_id').value"
                [body]="'Select a property to view items'"
                class="red">
              </tidy-text>
              <tidy-select
                *ngIf="roomObjectOptions?.length"
                [disableElement]="maintenance"
                label="Item"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [items]="roomObjectOptions"
                [submitted]="submitted"
                [form]="maintenanceForm.get('room_object_id')"
                formControlName="room_object_id">
              </tidy-select>
            </tidy-row>

            <ng-container *ngIf="maintenanceForm?.value?.room_object_id == 0 || (maintenanceForm.get('item_relation').value == 'yes' && roomObjectOptions?.length == 1 && maintenanceForm.get('address_id').value)">
              <tidy-row>
                <tidy-text
                  [header]="'Add Item'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="!roomItems?.length && roomItemsLoaded && maintenanceForm?.value?.room_object_id !== 0">
                <tidy-text
                  [body]="'You haven\'t added any items or rooms added to this property. Add a room to the property before then come back to this page to add an item.'">
                </tidy-text>
                <tidy-text
                  [body]="'Add Room'"
                  (action)="goToAddRoom()"
                  class="link">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="roomItems?.length && roomItemsLoaded">
                <tidy-row>
                  <tidy-text
                    [body]="'You haven\'t added any items added to this property. Add your first one below.'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-select
                    [label]="'Category'"
                    [form]="maintenanceForm.controls.category"
                    formControlName="category"
                    [items]="categoryItems"
                    (optionChange)="selectCategory($event)">
                  </tidy-select>
                </tidy-row>      
                <tidy-row *ngIf="!roomItems && roomItemsLoaded">
                  <tidy-text
                    [body]="'You haven\'t added any cards to this address yet. Please add cards first '">
                  </tidy-text>
                  <tidy-text
                    [body]="'here'"
                    class="link"
                    (action)="goToAddressPage(maintenanceForm.value.address)">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngFor="let characteristic of characteristics">
                  <tidy-row class="field-bottom-padding">
                    <tidy-select
                      [label]="'Select ' + characteristic.name"
                      [form]="maintenanceForm.controls.characteristic"
                      formControlName="characteristic"
                      (optionChange)="selectCharacteristic(characteristic, $event)"
                      [items]="characteristic.formData">
                    </tidy-select>
                  </tidy-row>
                </ng-container>
                <tidy-row class="extra-bottom-padding">
                  <tidy-select
                    [label]="'Year Installed'"
                    [form]="maintenanceForm.controls.installation_date"
                    formControlName="installation_date"
                    errorMessage="Please enter a valid date."
                    [items]="installationDateItems"
                    [submitted]="submitted">
                  </tidy-select>
                </tidy-row>
                <tidy-row [ngClass]="createNewModel ? 'extra-bottom-padding' : ''" *ngIf="models?.length">
                  <tidy-select
                    [label]="'Model #'"
                    [form]="maintenanceForm.controls.model"
                    (optionChange)="selectModel($event)"
                    formControlName="model"
                    [items]="models">
                  </tidy-select>
                </tidy-row>
                <form [formGroup]="modelForm">
                  <ng-container *ngIf="createNewModel">
                    <tidy-row class="extra-bottom-padding">
                      <tidy-input
                        [label]="'Model Number'"
                        formControlName="newModel"
                        [form]="modelForm.controls.newModel"
                        [icon]="'assets/svg/list-circle-outline.svg'"
                        [activeIcon]="'assets/svg/list-circle-outline.svg'"
                        [submitted]="submitted"
                        [errorMessage]="'Please enter the number of the new model'">
                      </tidy-input>
                    </tidy-row>
                  </ng-container>
                </form>
                <tidy-row>
                  <tidy-checkbox
                    [items]="[{viewValue: 'Track Inventory', value: true}]"
                    [initValue]="getInventoryAlerts"
                    (checkedChange)="selectGetInventoryAlerts()">
                  </tidy-checkbox>
                </tidy-row>
                <tidy-text
                  [body]="'Get alerted when the inventory for this item goes below your minimum quantity.'">
                </tidy-text>
                <ng-container *ngIf="getInventoryAlerts">
                  <tidy-row class="extra-top-padding extra-bottom-padding">
                    <tidy-input
                      [label]="'Minimum Quantity'"
                      formControlName="minQuantity"
                      [form]="maintenanceForm.controls.minQuantity"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [activeIcon]="'assets/svg/information-circle-outline.svg'"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter the minimum quantity'">
                    </tidy-input>
                  </tidy-row>
                  <tidy-row>
                    <tidy-input
                      [label]="'Current Quantity'"
                      formControlName="currentQuantity"
                      [form]="maintenanceForm.controls.currentQuantity"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [activeIcon]="'assets/svg/information-circle-outline.svg'"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter the current quantity'">
                    </tidy-input>
                  </tidy-row>
                </ng-container>
              </ng-container>
            </ng-container>
          </form>

          <!--<div class="attachments">
            <tidy-row>
              <tidy-text
                body="Photos">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                body="+ Add Photo"
                class="link"
                (click)="$event.stopPropagation()"
                (action)="addAttachment(issue)">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-text
                body="Videos">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                body="+ Add Video"
                class="link"
                (click)="$event.stopPropagation()"
                (action)="addAttachment(issue)">
              </tidy-text>
            </tidy-row>

            <tidy-row *ngIf="reportPhotos?.length" class="field-top-padding">
              <tidy-row>
                <tidy-text
                  [body]="'Photos:'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngFor="let attachment of reportPhotos; let i = index">
                <tidy-text *ngIf="issue"
                  [actionBody]="attachment.description ?? ('Photo '+ (i+1))"
                  (action)="openAttachment(attachment.photo_url)"
                  class="link">
                </tidy-text>
                <tidy-text *ngIf="!issue"
                  [body]="attachment.description">
                </tidy-text>
                <tidy-text
                  [actionBody]="'remove'"
                  (action)="removeAttachment(i, attachment)"
                  class="link red">
                </tidy-text>
              </tidy-row>
            </tidy-row>

          </div>-->
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="maintenance">
          <tidy-text
            [body]="'Delete Recurring Task'"
            class="link red"
            (action)="deleteRecurringTask()">
          </tidy-text>
        </tidy-row>
        
        <tidy-button
          *ngIf="!maintenance"
          [action]="saveMaintenance.bind(this)"
          class="primary save-issue-button"
          [text]="'Add Recurring Task'">
        </tidy-button>

        <tidy-error-message
          *ngIf="formError"
          [text]="'Please fill out all the required fields.'">
        </tidy-error-message>

        <div style="height: 100px"></div>

      </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!windowService.isDesktopRes">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="windowService.isDesktopRes">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
import { Injectable } from "@angular/core";
import { AddressModel } from "src/models/address.model";

import { HttpClientCustom } from "src/providers/custom/http-client";

@Injectable()
export class Genome {
  constructor(private httpClient: HttpClientCustom) {}

  async addRooms(addressId, data) {
    const url = `api/v1/customer/addresses/${addressId}/rooms`;
    return await this.httpClient.post(url, data);
  }

  async updateRoom(addressId, roomId, data) {
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}`;
    return await this.httpClient.put(url, data);
  }

  async deleteRoom(addressId, roomId) {
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}`;
    return await this.httpClient.delete(url);
  }

  async markRoomAsDoNotClean(addressId, roomId) {
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}/mark-as-to-not-clean`;
    return await this.httpClient.post(url, {});
  }

  async getRooms(addressId) {
    const url = `api/v1/instructions/addresses/${addressId}/address-rooms?includes=characteristics`;
    return await this.httpClient.get(url);
  }

  async getInventoryRoomObjects() {
    const url = `api/v1/customer/room-objects?filters[only_with_quantity]=${true}&includes=with_address_details`;
    return await this.httpClient.get(url);
  }

  async getRoomsObjects(addressId) {
    const url = `api/v1/customer/room-objects?filters[address_id]=${addressId}`;
    return await this.httpClient.get(url);
  }

  async getRoomObject(roomObjectId) {
    const url = `api/v1/customer/room-objects/${roomObjectId}?includes=with_address_tasks,with_change_history,with_address_details,characteristics`;
    return await this.httpClient.get(url);
  }

  async addRoomObject(data) {
    const url = `api/v1/customer/room-objects`;
    return await this.httpClient.post(url, data);
  }

  async updateRoomObject(objectId, data) {
    const url = `api/v1/customer/room-objects/${objectId}`;
    return await this.httpClient.put(url, data);
  }

  async inactivateRoomObject(roomObjectId) {
    const url = `api/v1/customer/room-objects/${roomObjectId}/inactivate`;
    return await this.httpClient.post(url, {});
  }

  async getCategoriesForRoom(category) {
    const url = `api/v1/customer/genome/categories?filters[room_category]=${category}`;
    return await this.httpClient.get(url);
  }

  async getCategories() {
    const url = `api/v1/customer/genome/categories`;
    return await this.httpClient.get(url);
  }

  async getCharacteristics(categoryId) {
    const url = `api/v1/customer/genome/characteristic-types?category_id=${categoryId}`;
    return await this.httpClient.get(url);
  }

  async getObjectModel(categoryId, queryString) {
    const url = `api/v1/customer/genome/object-models?category_id=${categoryId}${queryString}`;
    return await this.httpClient.get(url);
  }

  async getAddressDetails(addressId) {
    const url = `api/v1/customer/address-details?address_id=${addressId}`;
    return await this.httpClient.get(url);
  }

  async saveAddressDetails(payload) {
    const url = `api/v1/customer/address-details`;
    return await this.httpClient.post(url, payload);
  }

  async getAddress(addressId): Promise<AddressModel> {
    const url = `api/v1/customer/addresses/${addressId}?includes=plans`;
    return await this.httpClient.get(url);
  }

  async addNewModel(data) {
    const url = `api/v1/customer/genome/object-models`;
    return await this.httpClient.post(url, data);
  }

  async getUpcomingMaintenances(addressId: number | string) {
    const url = `/api/v1/customer/room-object-maintenance-instances/to-do?address_id=${addressId}&upcoming_days=730&includes=steps,characteristics`;
    return await this.httpClient.get(url);
  }

  async getPastMaintenancesByRoomObject(
    addressId: number,
    roomObjectId: string
  ) {
    const url = `/api/v1/customer/room-object-maintenance-instances?address_id=${addressId}&filters[room_object_ids]=${roomObjectId}&includes=steps,characteristics`;
    return await this.httpClient.get(url);
  }

  async getUpcomingMaintenancesByRoomObject(addressId: number, roomObjectId) {
    const url = `/api/v1/customer/room-object-maintenance-instances/to-do?address_id=${addressId}&upcoming_days=730&filters[room_object_ids]=${roomObjectId}&includes=steps,characteristics`;
    return await this.httpClient.get(url);
  }

  async updateMaintenanceStatus(data) {
    const url = "/api/v1/customer/room-object-maintenance-instances";
    return await this.httpClient.post(url, data);
  }

  async createManyRoomObjects(data) {
    const url = "api/v1/customer/room-objects/create-many";
    return await this.httpClient.post(url, data);
  }
}

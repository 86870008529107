import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class FirstAvailableTimes {

  frequency: any = ['thrice_a_week', 'twice_a_week', 'every_week', 'every_other_week', 'every_four_weeks', 'once'];
  constructor(private httpClient: HttpClientCustom) { }

  getFirstTimes(region_id, bookingType: any = {}): Promise<any> {
    let bookingTypeData = '';
    if (bookingType.rental === true) {
      bookingTypeData = '&booking_type=rental';
    }
    const address_id = localStorage.getItem('addressId');
    const url = `api/v1/calendar/first-available-times?address_id=${address_id}&region_id=${region_id}${bookingTypeData}`;
    return this.httpClient.get(url);
  }

  getFrequency(params): Promise<any> {
    const { regionId, bookingType, planName, frequency, isOffPlan, rescheduleJobId, typeOfPlan, planId } = params;
    let bookingTypeData = '';
    let offPlanCleaning = '';
    let rescheduleData = '';
    if (bookingType && bookingType.rental === true) {
      bookingTypeData = '&booking_type=rental';
    }
    if (isOffPlan === true) {
      offPlanCleaning = `off_plan_cleaning=true&`;
    }
    if (rescheduleJobId) {
      rescheduleData = `&reschedule_job_id=${rescheduleJobId}`
    }
    if (!rescheduleJobId && typeOfPlan === 'reschedule-plan' && planId) {
      rescheduleData = `&reschedule_plan_id=${planId}`;
    }
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/calendar/first-available-times?${offPlanCleaning}address_id=${addressId}&region_id=${regionId}${bookingTypeData}&service_type=${planName}&frequency=${frequency}${rescheduleData}`;
    return this.httpClient.get(url);
  }

  getFrequencyByPlan() {
    return this.frequency;
  }


}

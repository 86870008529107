<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Item'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-alert>
          <tidy-card-button class="arrow-to-top" style="padding-bottom: 0px" (action)="goToAddConciergeTask()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Have Concierge Add For Me'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'We typically get this done in 1 business day, and email you back!'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-alert>

        <tidy-card *ngIf="hasNoItemsOrMap">
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [title]="'Get Map & Maintenance Plan'">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [body]="'Instead of adding a single item to your home, you can scan your property and we will generate a fun map of your property and personalized maintenance plan, for free!'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/house-sketch.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Create a Sharable Map'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Your map will be with your to-do list to help your pro visually get the job done right.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/tool-box.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Get Maintenance Tips'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'We will tell you what the manufacturer recommends for each item.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/light-bulb.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Clean Smarter'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Knowing what is in your home helps you and any pros you use follow best practices for that exact thing.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Scan Your Property'"
              [action]="beginMapping.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Add Item'"
              [action]="dontScanHome.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </tidy-card>

        <ng-container *ngIf="!hasNoItemsOrMap">
          <ng-container *ngIf="!categoryChosen">
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'Select the item to add:'">
              </tidy-text>
            </tidy-row>
            <tidy-alert>
              <form [formGroup]="form" novalidate action="#">
                <tidy-row class="field-top-padding" style="padding-bottom:0px">
                  <tidy-input
                    #searchInput
                    [icon]="'assets/svg/search-outline.svg'"
                    [activeIcon]="'assets/svg/search-tidy-green.svg'"
                    [noBorder]="true"
                    type="search"
                    formControlName="search"
                    [label]="'Search'"
                    [form]="form">
                  </tidy-input>
                </tidy-row>
              </form>
            </tidy-alert>
            <div *ngFor="let item of categories; let index = index">
              <tidy-three-column-row *ngIf="index%3 == 0 && categories[index+1]?.name">
                <tidy-card style="padding-top:10px; padding-bottom:5px; cursor: pointer">
                  <tidy-card-button [hideArrow]="true" (action)="selectCategory(item)" style="cursor: pointer">
                    <tidy-row [align]="'center'">
                      <img
                        [src]="item?.default_icon_url"
                        style="width:60%">
                    </tidy-row>
                    <tidy-row [align]="'center'">
                      <tidy-text
                        [body]="item?.name">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
                <tidy-card style="padding-top:10px; padding-bottom:5px; cursor: pointer" *ngIf="categories[index+1]?.name">
                  <tidy-card-button [hideArrow]="true" (action)="selectCategory(categories[index+1])" style="cursor: pointer">
                    <tidy-row [align]="'center'">
                      <img
                        [src]="categories[index+1]?.default_icon_url"
                        style="width:60%">
                    </tidy-row>
                    <tidy-row [align]="'center'">
                      <tidy-text
                        [body]="categories[index+1]?.name">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
                <tidy-card style="padding-top:10px; padding-bottom:5px; cursor: pointer" *ngIf="categories[index+2]?.name">
                  <tidy-card-button [hideArrow]="true" (action)="selectCategory(categories[index+2])" style="cursor: pointer">
                    <tidy-row [align]="'center'">
                      <img
                        [src]="categories[index+2]?.default_icon_url"
                        style="width:60%">
                    </tidy-row>
                    <tidy-row [align]="'center'">
                      <tidy-text
                        [body]="categories[index+2]?.name">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>
                <div style="width: 100%; margin-left: 29px" *ngIf="!categories[index+2]?.name">
                </div>
              </tidy-three-column-row>
              <tidy-card style="padding-top:10px; padding-bottom:5px; width: 33.3%; cursor: pointer" *ngIf="index%3 == 0 && !categories[index+1]?.name && !categories[index+2]?.name">
                <tidy-card-button [hideArrow]="true" (action)="selectCategory(item)" style="cursor: pointer">
                  <tidy-row [align]="'center'">
                    <img
                      [src]="item?.default_icon_url"
                      style="width:60%">
                  </tidy-row>
                  <tidy-row [align]="'center'">
                    <tidy-text
                      [body]="item?.name">
                    </tidy-text>
                  </tidy-row>
                </tidy-card-button>
              </tidy-card>
            </div>
          </ng-container>
          <ng-container *ngIf="categoryChosen">
            <tidy-card>
              <tidy-row [align]="'center'" class="extra-bottom-padding">
                <tidy-image
                  *ngIf="category?.image"
                  [src]="category?.image"
                  style="width:30%">
                </tidy-image>
              </tidy-row>
              <tidy-row [align]="'center'" class="extra-bottom-padding">
                <tidy-text
                  [title]="category?.name">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="showAddressSelect">
                <tidy-select
                  [label]="'Address'"
                  [form]="form.controls.address"
                  formControlName="address"
                  [items]="addressItems">
                </tidy-select>
              </tidy-row>
              <tidy-row *ngIf="!item?.consumable">
                <tidy-auto-complete
                  [label]="item?.consumable ? 'Name' : 'Name or Model #'"
                  formControlName="model"
                  [options]="models"
                  [form]="form.controls.model"
                  (action)="selectModel($event)">
                </tidy-auto-complete>
              </tidy-row>
              <tidy-row *ngIf="item?.consumable">
                <tidy-input
                  [label]="'Name'"
                  formControlName="model"
                  [form]="form.controls.model"
                  [submitted]="submitted">
                </tidy-input>
              </tidy-row>
              <ng-container *ngIf="!selectedModelID">
                <ng-container *ngFor="let characteristic of characteristics; let index = index">
                  <tidy-row>
                    <tidy-select
                    [label]="'Select ' + characteristic.name"
                    [form]="form.controls['characteristic_' + index]"
                    formControlName="{{ 'characteristic_' + index }}"
                    (optionChange)="selectCharacteristic(characteristic, $event)"
                    [items]="characteristic.formData">
                  </tidy-select>
                  </tidy-row>
                </ng-container>
              </ng-container>
              <tidy-row>
                <tidy-select
                  [label]="'Year Installed'"
                  [form]="form.controls.installation_date"
                  formControlName="installation_date"
                  errorMessage="Please enter a valid date."
                  [items]="installationDateItems"
                  [submitted]="submitted">
                </tidy-select>
              </tidy-row>
              <tidy-row *ngIf="showConsumables">
                <tidy-checkbox
                  [items]="[{viewValue: 'Track Inventory', value: true}]"
                  [initValue]="getInventoryAlerts"
                  (checkedChange)="selectGetInventoryAlerts()">
                </tidy-checkbox>
              </tidy-row>
              <tidy-row class="extra-bottom-padding" *ngIf="showConsumables">
                <tidy-text
                  [body]="'Get alerted when the inventory for this item goes below your minimum quantity.'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="getInventoryAlerts">
                <div class="three-column-form-row padding-bottom-5">
                  <tidy-input
                    [label]="'Min Quantity'"
                    formControlName="minQuantity"
                    [form]="form.controls.minQuantity"
                    inputMode="numeric"
                    [mask]="'number'"
                    [submitted]="submitted">
                  </tidy-input>
                  <tidy-input
                    [label]="'Max Quantity'"
                    formControlName="maxQuantity"
                    [form]="form.controls.maxQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                  <tidy-input
                    [label]="'Current Quantity'"
                    formControlName="currentQuantity"
                    [form]="form.controls.currentQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                </div>
                <tidy-row *ngIf="!form.controls.minQuantity.valid && submitted">
                  <tidy-error-message
                    [text]="'Please enter the minimum quantity'">
                  </tidy-error-message>
                </tidy-row>
                <div class="two-column-form-row padding-bottom-5">
                  <tidy-input
                    [label]="'Min Restocking Price'"
                    formControlName="minRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.minRestockingPrice">
                  </tidy-input>
                  <tidy-input
                    [label]="'Max Restocking Price'"
                    formControlName="maxRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.maxRestockingPrice">
                  </tidy-input>
                </div>
                <tidy-row>
                  <tidy-input
                    [label]="'Opportunistic Order Level'"
                    formControlName="opportunisticOrderLevel"
                    [form]="form.controls.opportunisticOrderLevel"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Where to Order'"
                    formControlName="whereToOrder"
                    [form]="form.controls.whereToOrder">
                  </tidy-input>
                </tidy-row>
                <tidy-row>
                  <tidy-select
                    [label]="'Delivery Timing Preferences'"
                    [form]="form.controls.deliveryTimingPreferences"
                    formControlName="deliveryTimingPreferences"
                    [items]="deliveryTimingPreferenceItems">
                  </tidy-select>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Restocking Notes'"
                    formControlName="restockingNotes"
                    [form]="form.controls.restockingNotes">
                  </tidy-input>
                </tidy-row>
              </ng-container>
            </tidy-card>
          </ng-container>

          <tidy-error-message
            *ngIf="errorMessage"
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            *ngIf="categoryChosen"
            [text]="'Add Item'"
            [action]="addItem.bind(this)"
            class="primary">
          </tidy-button>

          <tidy-row [align]="'center'" class="extra-top-padding">
            <tidy-text
              [title]="'Why Add Items?'">
            </tidy-text>
          </tidy-row>

          <tidy-alert *ngFor="let card of salesCards; let last = last; let first = first" style="padding:5px">
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="card?.image"
                    style="width:35px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="card?.title">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="card?.text">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-alert>
        </ng-container>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

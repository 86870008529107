import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Addresses } from "src/providers/customer/addresses";
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { Loading } from 'src/shared/components/loading/loading';
import { WindowService } from "src/shared/providers/window.service";

import { Util } from 'src/shared/util/util'
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { EditPricesPage } from '../edit-prices/edit-prices';


interface Frequency {
  value: string;
  viewValue: string;
}

interface Price {
  name: string;
  price: number;
}

interface Filters {
  frequency: string;
  errorMessage: string;
  sameDayCleaning: boolean;
  addressId: number;
  chargeAhead: boolean;
}

@Component({
  templateUrl: 'my-prices.html',
  encapsulation: ViewEncapsulation.None
})

export class MyPricesPage extends TimeoutableComponent implements OnInit {
  addressList: any;
  addressSelected: number;
  errorMessage: string;
  services: any;
  jobTypes: TidySelectStringValueModel[] = [
    { value: 'Cleaning Service', viewValue: 'Cleaning Service'}
  ];
  frequencies: Frequency[] = [
    { value: 'every_week', viewValue: 'Recurring' },
    { value: 'once', viewValue: 'One Time' },
    { value: 'same_day_cleaning', viewValue: 'Same Day' }
  ];
  billingPreference = [
    { value: 'charge_before', viewValue: 'Charge Before'},
    { value: 'charge_after', viewValue: 'Charge After'},
  ];
  filtersForm: UntypedFormGroup;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private addresses: Addresses,
    private client: Client,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
    this.filtersForm = this.formBuilder.group({
      frequency: ['every_week', Validators.required],
      addressId: [parseInt(localStorage.getItem('addressId'), 10), Validators.required],
      chargeType: ['charge_before', Validators.required],
      jobType: ['Cleaning Service']
    })
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('My Prices');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.addressList = await this.buildAddressFilter();
      this.services = await this.addresses.getPriceTable(this.filtersForm.value.addressId);
      this.loaded = true;
    } catch (err) {
      const unhandledError = await this.timeoutHandler(err);
      this.errorMessage = unhandledError?.error?.message ?? unhandledError?.message;
    }
  }

  async buildAddressFilter() {
    const addresses = await this.client.getAddresses();
    return addresses.map(address => {
      return { value: address.id, viewValue: address?.address_name || `${address.address1} ${address.zip}`}
    });
  }

  getPrice(service) {
    let price = service.prices.base_price;
    if (this.filtersForm.value.frequency == 'once') {
      price = this.addPercent(price, service.optional_price_additional_fees.one_time_job.value);
    }
    if (this.filtersForm.value.frequency == 'same_day_cleaning') {
      price = this.addPercent(price, service.optional_price_additional_fees.same_day_job.value);
    }
    if (this.filtersForm.value.chargeType == 'charge_after') {
      price = this.addPercent(price, service.optional_price_additional_fees.charge_after?.value || 10);
    }
    return (price == 0 || price == null) ? 'Priced Later' : new TidyCurrencyPipe().transform(price);
  }

  addPercent(amount, percent) {
    amount /= 100;
    amount = (amount + (amount * (percent / 100)));
    return Math.round(amount) * 100;
  }

  @Loading('', true)
  async changeProperty() {
    try {
      this.services = await this.addresses.getPriceTable(this.filtersForm.value.addressId);
    } catch (err) {
      const unhandledError = await this.timeoutHandler(err);
      this.errorMessage = unhandledError?.error?.message ?? unhandledError?.message;
    }
  }

  goToProSettingsPage() {
    if (!this.windowService.isDesktopRes) {
      return this.util.showSuccess('Please log into tidy.com on your desktop to your pro view price settings.');
    }
    this.navCtrl.navigateForward('pro-settings');
  }

  goToEditPricesPage() {
    const params = {
      services: this.services,
      addressList: this.addressList,
      addressId: this.filtersForm.value.addressId
    }
    this.rightSidePanelService.navigateTo('edit-prices', params, EditPricesPage);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Rooms'"
    [canGoBack]="true"
    [customBack]="'edit-property/' + address?.id">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="!loaded && errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [header]="addressName"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="floors?.length == 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'No Rooms Added'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [text]="'Add Room'"
          [action]="goToAddRoomPage.bind(this, 1)"
          class="primary">
        </tidy-button>
      </ng-container>
      
      <ng-container *ngFor="let floor of floors; let i = index">
        <ng-container *ngIf="floor">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [header]="'Floor'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="i">
            </tidy-text>
            <tidy-text
              [body]="'Add Room'"
              class="link"
              (action)="goToAddRoomPage(i)">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let room of floor">
            <tidy-card class="no-padding">
              <tidy-card-button
                class="arrow-to-top no-padding"
                [customArrowPositon]="{ top: '18px', right: '15px' }"
                (action)="goToEditRoomDetails(room)">
                <tidy-row class="vertical-align-center extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding">
                  <tidy-image
                    [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                    class="title-size">
                  </tidy-image>
                  <tidy-text
                    [header]="room?.name"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
            </tidy-card>
          </ng-container>
        </ng-container>
      </ng-container>

      <tidy-button
        *ngIf="cameFromBuildMap"
        [text]="'Confirm Rooms'"
        [action]="confirmRooms.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'notification-details.html'
})

export class NotificationDetailPage implements OnInit {

  pageTitle: string;
  notification: any;
  loaded: boolean;
  detailsType: string;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadData();
  }

  async loadData() {
    this.loaded = false;
    this.notification = await this.getParam('notification');
    this.detailsType = this.notification.notification_type;
    this.pageTitle = this.getPageTitle();
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.loaded = true;
  }

  getPageTitle() {
    if (this.detailsType === 'email') {
      return 'Email Details';
    } else if (this.detailsType === 'sms') {
      return 'SMS Details';
    } else {
      return 'App Notification Details';
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }
}

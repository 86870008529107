export const toDosMockData = [
  {
      "id": 353384,
      "title": "2 Departure Clean Short",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353411,
      "title": "Air Filter Change",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 367206,
      "title": "Appliance Repair",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623563,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353424,
      "title": "Appliance Repair",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353388,
      "title": "Arrival Clean",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353410,
      "title": "Battery Replacement",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353394,
      "title": "Biohazard",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353427,
      "title": "blowing",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 353379,
      "title": "COVID-19 Disinfect and Deep Clean",
      "state": "active",
      "is_customer_list": true,
      "is_template": true,
      "address_id": 623057,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371748,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623527,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371746,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623529,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371745,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623551,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371773,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623530,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371779,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 627354,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371753,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623564,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371754,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623562,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371750,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623560,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371788,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 650786,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371783,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 633887,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371733,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623561,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371786,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 650608,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371776,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 627355,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371741,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623556,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371782,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 631197,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  },
  {
      "id": 371751,
      "title": "Damages/Additional Cleaning Needed",
      "state": "active",
      "is_customer_list": true,
      "is_template": false,
      "address_id": 623558,
      "default_list_settings": [],
      "block_complete_on_missing_fields": false,
      "before_after_photos_state": "inactive"
  }
]
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Auth } from 'src/providers/auth/auth';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Integrations } from 'src/providers/integrations/integrations';

import { environment } from 'src/environments/environment';

@Injectable()
export class IntegrationAddedRedirectGuard implements CanActivate {

  associatedRemoteAccessAddressesCount: any;
  remoteAccessAddressesCount: any;

  constructor (
    private auth: Auth,
    private client: Client,
    private guestReservations: GuestReservations,
    private integrations: Integrations,
    private navCtrl: CustomNavController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    localStorage.setItem('getAddressesFromBackend', 'true');
    const urlSearch = window.location.search;
    const searchParams = new URLSearchParams(urlSearch);
    const code = searchParams.get('code');
    const integration = route.params.integration.toString();
    const integrationName = this.getIntegrationName(integration);
    if (this.auth.isAuthenticated()) {
      try {
        await this.addIntegration(integrationName, integration, code);
        return false;
      } catch (err) {
        this.goToFailurePage(err);
        return false;
      }
    }
    this.goToNotLoggedInSuccessPage(integrationName, code);
    return false;
  }

  async addIntegration(integrationName, integration, code) {
    if (integrationName == 'RemoteLock') {
      const payload = {
        type: 'remote_property_access',
        key: 'remote_lock',
        data: {
          code: code
        }
      };
      await this.integrations.addIntegration(payload);
      await this.associateRemoteAddresses();
      this.goToSuccessRemoteLockPage();
    } else if (integrationName == 'OwnerRez') {
      const ids = {
        'https://api-sandbox3.tidy.com/': 213,
        'https://api-staging.tidy.com/': 147,
        'https://api-production.gotidy.com/': 643
      };
      const payload = {
        guest_reservation_source_type_id: ids[environment.api_secure],
        data: {
          code: code
        }
      }
      await this.guestReservations.addDirectReservationSource(payload);
      this.goToSuccessPage(integrationName);
    } else if (integrationName == 'Smoobu') {
      const ids = {
        'https://api-production.gotidy.com/': 843
      };
      const payload = {
        guest_reservation_source_type_id: ids[environment.api_secure],
        data: {
          code: code
        }
      };
      await this.guestReservations.addDirectReservationSource(payload);
      this.goToSuccessPage(integrationName);
    } else if (integrationName == 'Hospitable') {
      const ids = {
        'https://api-production.gotidy.com/': 1542
      };
      const payload = {
        guest_reservation_source_type_id: ids[environment.api_secure],
        data: {
          code: code
        }
      }
      await this.guestReservations.addDirectReservationSource(payload);
      this.goToSuccessPage(integrationName);
    }
  } 

  async associateRemoteAddresses() {
    const addresses = await this.client.getMoreDetailAddresses();
    const remoteAccessAddresses = await this.integrations.getRemoteAccessAddresses();
    this.remoteAccessAddressesCount = remoteAccessAddresses.length;
    let payload = {
      remote_access_addresses_changes : []
    };
    this.associatedRemoteAccessAddressesCount = 0;
    remoteAccessAddresses.map((remoteAccessAddress) => {
      addresses.map((address) => {
        if (address.address1 == remoteAccessAddress.address1 && ((address.address2 == remoteAccessAddress.address2) || (address.address2 == '' && remoteAccessAddress.address2 == null))) {
          this.associatedRemoteAccessAddressesCount += 1;
          payload.remote_access_addresses_changes.push({
            address_id : address.id,
            id: remoteAccessAddress.id
          });
        }
      });
    });
    if (payload.remote_access_addresses_changes.length > 0) {
      await this.integrations.associateRemoteAccessAddresses(payload);
    }
  }

  getIntegrationName(integration) {
    const name = {
      'ownerrez': 'OwnerRez',
      'smoobu': 'Smoobu',
      'remotelock': 'RemoteLock',
      'hospitable': 'Hospitable'
    }
    return name[integration];
  }

  goToSuccessPage(integrationName) {
    const successParams = {
      header: integrationName + ' Integration Syncing',
      body: 'It can take up to 15 minutes for the first sync. You can view all your synced guest reservations in the "Schedule" section.',
      buttonText: 'Ok',
      buttonRoute: 'integrations/all'
    };
    this.navCtrl.navigateForward('success-no-auth', successParams);
  }

  goToSuccessRemoteLockPage() {
    const altSuccessParams = {
      header: 'RemoteLock Integration Added',
      body: 'You haven\'t added any locations at RemoteLock.com. Please add locations and devices there, then associate them with your TIDY addresses in the next page.',
      buttonText: 'Ok',
      buttonRoute: 'remotelock'
    };
    const successParams = {
      header: 'RemoteLock Integration Added',
      body: 'TIDY found ' + this.remoteAccessAddressesCount + ' locations added at RemoteLock.com and associated them with ' + this.associatedRemoteAccessAddressesCount + ' of your TIDY addresses. Please double check the associations and select which device(s) to use in the next page.',
      buttonText: 'Ok',
      buttonRoute: 'remotelock'
    };
    this.navCtrl.navigateForward('success-no-auth', successParams);
  }

  goToNotLoggedInSuccessPage(integrationName, code) {
    const successParams = {
      header: 'Please Contact the Concierge',
      body: 'You were not logged into tidy.com so we were unable to complete adding the integration with ' + integrationName + '. Please log in and try again.',
      buttonText: 'Ok',
      buttonRoute: ''
    };
    this.navCtrl.navigateForward('success-no-auth', successParams);
  }

  goToFailurePage(err) {
    const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    const successParams = {
      header: '',
      body: `${errorMessage}. Please contact the concierge with any questions.`,
      buttonText: 'Ok',
      buttonRoute: ''
    };
    this.navCtrl.navigateForward('success-no-auth', successParams);
  }

}

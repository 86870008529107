import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  NgZone,
  ViewEncapsulation,
} from "@angular/core";
import { Router, Event, ResolveEnd, ActivationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { Platform, MenuController, ModalController } from "@ionic/angular";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { App, URLOpenListenerEvent } from '@capacitor/app';

import * as Bowser from "bowser";
import OneSignal from "onesignal-cordova-plugin";

import { Addresses } from "src/providers/customer/addresses";
import { Events } from "src/providers/events/events";
import { Logger } from "src/providers/logger";
import { LoadingBarHandler } from "src/providers/loading-bar-handler/loading-bar-handler";
import { Menu } from "src/providers/menu/menu";
import { StaticCompanyText } from "src/providers/company/static-company-text";
import { TidySession } from "src/providers/tidy-session/tidy-session";
import { TrackParams } from "src/providers/tidy-session/track-params.interface";

import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { Loading } from "src/shared/components/loading/loading";
import { Util } from "src/shared/util/util";
import { navRootOptions } from "src/shared/constants/rootNavigation";
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { WindowService } from "src/shared/providers/window.service";

import { BREAK_LARGE_RESOLUTION } from "android/app/src/constants/app.contants";

import { CookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { OneSignalNotification } from "src/shared/providers/one-signal-notification";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { Capacitor } from "@capacitor/core";
import { DateTime } from 'luxon';

declare const window: any;
declare const document: any;

import { translations } from 'src/shared/constants/language-translations';
import { PickYourGoalsComponent } from "src/shared/components/pick-your-goals/pick-your-goals";

@Component({
  selector: "app-root",
  templateUrl: "app.html",
  styleUrls: ["./app.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TidyHomekeepingApp implements OnInit {

  nameAndAddress: any;
  hideNavBar = false;
  menuType = "desktop";
  width = document.documentElement.clientWidth;
  currentComponent: any;
  addressSelectorOpened = false;
  enableMenu = false;
  pagesToShowHeader = false;
  translations = translations;
  capacitor = Capacitor;
  isHalloween = false;

  readonly breakLargeResolution = BREAK_LARGE_RESOLUTION;

  constructor(
    private addresses: Addresses,
    private cookieService: CookieService,
    private events: Events,
    private keyboard: Keyboard,
    private loadingBarHandler: LoadingBarHandler,
    private logger: Logger,
    public mainMenu: Menu,
    private menu: MenuController,
    private navCtrl: CustomNavController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private staticCompanyText: StaticCompanyText,
    private storage: TidyStorage,
    private tidySession: TidySession,
    private titleService: Title,
    private util: Util,
    private elementRef: ElementRef,
    public windowService: WindowService,
    private oneSignalNotification: OneSignalNotification,
    private zone: NgZone,
    public rightSidePanelService: RightSidePanelService,
    private modalController: ModalController
  ) {
    this.platform.ready().then(() => {
      this.initDeeplinks();
    });
  }

  ngOnInit() {
    this.checkHalloween();
    this.addPopStateListener();
    this.preventiveRemoveAriaOwns();
    this.isUnsupportedBrowser();
    this.subscribeEvents();
    this.setBackground();
    this.menuType =
      this.width > this.breakLargeResolution ? "desktop" : "mobile";
    this.setVersion();
    this.storage.delete('proId');
    this.storage.delete('dialog-params');
    this.storage.delete('dialog-right-side-open');
    this.setIosWrapperPadding();
    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof ActivationEnd && event) {
        try {
          this.tidySession.setfirstUrlViewed(event?.snapshot?.url);
          this.trackUserNavigation(event);
        } catch (error) {
          this.logger.log(error);
        }
      }
      if (event instanceof ResolveEnd){
        this.setTitle(event);
        this.setBackground();
        this.setNameAddress();
      }
    });
    //this.openPickYourGoals();
  }

  checkHalloween() {
    const now = DateTime.now();
    this.isHalloween = now.month === 10 && now.day >= 28 && now.day <= 31;
  }

  addPopStateListener() {
    window.addEventListener('popstate', () => {
      this.storage.save('dialog-right-side-open', false);
    });
  }

  // TODO: Remove this function once the bug is fixed
  /**
  * Temporary code to fix Chrome browser crashing.
  * See: https://issues.chromium.org/issues/335553723?pli=1
  */
  preventiveRemoveAriaOwns() {
    document.addEventListener('DOMNodeInserted', () => {
      const elements = document.querySelectorAll('[aria-owns]');
      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });
  }

	initDeeplinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'app.tidy.com/#/';
        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://app.tidy.com/#/', '/more']
        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.navCtrl.navigateForward(appPath);
        }
        else{
          this.navCtrl.navigateForward('login');
        }
      });
    });
	}

  trackUserNavigation(event) {
    const tidySessionId = this.tidySession.configureTidySessionCookie();
    let pageName = "";
    if (event.snapshot && event.snapshot.data && event.snapshot.data.title) {
      pageName = event.snapshot.data.title;
    }
    const data: TrackParams = {
      tidySessionId,
      eventType: "PAGE_LOAD",
      metaData: "",
      pageName,
    };
    this.tidySession.trackEvent(data);
  }

  subscribeEvents() {
    this.events.subscribe("authToken:isInvalid", () => this.logout());
    this.events.subscribe("logout", () => this.logout());
    this.events.subscribe("updateBackground", () => this.setBackground());
    this.events.subscribe("address:changed", (address) =>
      this.addressChanged(address)
    );
    this.events.subscribe("menu:open", () => this.toggleMenu(true));
    this.events.subscribe("menu:close", () => this.toggleMenu(false));
    this.platform
      .ready()
      .then(() => this.platformReady())
      .catch((err) => this.logger.log("Err on platform.ready: " + err));
    this.loadingBarHandler.setup();
  }

  setTitle(event) {
    const { title } = event.state.root.children[0].data;
    this.titleService.setTitle(title);
  }

  setNameAddress() {
    if (
      typeof this.nameAndAddress === "undefined" ||
      !this.nameAndAddress.custumerId
    ) {
      this.nameAndAddress = this.mainMenu.getNameAndAddress();
      this.nameAndAddress.address = this.addresses.getCurrentAddress();
    }
  }

  isNative(): boolean {
    return this.platform.is("hybrid");
  }

  toggleMenu(enableMenu) {
    this.enableMenu = enableMenu;
  }

  addressChanged(address) {
    if (!address) {
      return;
    }
    this.addresses.setCurrentAddress(address);
    this.nameAndAddress.address = this.addresses.getCurrentAddress();
  }

  async platformReady(): Promise<any> {
    await this.splashScreen.hide();
    await this.keyboard.hideFormAccessoryBar(true);
    this.askTrackingPermission();
    this.initPushNotifications();
  }

  askTrackingPermission() {
    if (this.platform.is("cordova") && this.platform.is("ios")) {
      if (window.cordova) {
        window.cordova.exec(null, null, "idfa", "requestPermission", []);
      }
    }
  }

  setVersion() {
    window.tidyAppVersion = environment.version;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.width = event.currentTarget.innerWidth;
    this.menuType =
      this.width > this.breakLargeResolution ? "desktop" : "mobile";
  }

  setBackground() {
    const isCompany = this.staticCompanyText.isCompany();
    const background = localStorage.getItem('custom.background');
    const componentElem = this.elementRef.nativeElement as HTMLElement;
    if(background && background != '' && background !== 'nil'){
      if(background[0] === '#'){
        componentElem.className = null;
        componentElem.style.backgroundImage = null;
        componentElem.style.backgroundColor = background;
        componentElem.style.setProperty('--ion-color-tidy_background', background);
      }
      else {
        componentElem.className = null;
        componentElem.style.backgroundColor = null;
        componentElem.style.setProperty('--ion-color-tidy_background', '#FCF7F0');
        componentElem.style.backgroundImage = `url(${background})`;
      }
    } else {
      componentElem.style.backgroundImage = null;
      componentElem.style.backgroundColor = null;
      componentElem.style.setProperty('--ion-color-tidy_background', '#FCF7F0');
      componentElem.className = isCompany ? "company" : "regular";
    }
  }

  @Loading("Loading...")
  logout() {
    localStorage.clear();
    this.storage.clear();
    this.menu.close();
    this.navCtrl.navigateRoot("/", navRootOptions);
    this.setBackground();
    this.nameAndAddress = undefined;
    window.location.reload();
  }

  goToPage(page) {
    if (page === "Help") {
      return this.goToZendesk();
    }

    this.menu.close();

    const rootPages = [
      "schedule-assurance-confirm",
      "cancel-cleaning",
      "to-dos-settings",
    ];
    if (rootPages.indexOf(page) > -1) {
      this.navCtrl.navigateForward(page);
    } else {
      this.navCtrl.navigateRoot(page, navRootOptions);
    }
  }

  isUnsupportedBrowser() {
    if (this.windowService.isDesktopRes) {
      if (Bowser.msie || (Bowser.safari && parseInt(Bowser.version, 10) < 9)) {
        window.location.href = "https://secure.tidy.com";
      }
    }
  }

  openCloseAddressSelector() {
    this.addressSelectorOpened = !this.addressSelectorOpened;
  }

  goToMainPage(address) {
    this.events.publish("address:changed", address);
    this.navCtrl.navigateForward("my-cleanings");
    this.menu.close();
  }

  goToZendesk() {
    const url = "https://help.tidy.com/";
    this.util.openUrl(url);
  }

  initPushNotifications(): void {
    this.oneSignalNotification.initOneSignal();
  }

  private setIosWrapperPadding() {
    let padding = '15px';
    if (Capacitor.getPlatform() === 'ios') {
      padding = '40px';
    }
    document.documentElement.style.setProperty('--custom-ios-padding-top', padding);
  }

  async openPickYourGoals() {
    const userGoals = await this.storage.retrieve('userGoals');
    const tidyToken = localStorage.getItem('tidy_token');
    const alreadyOpened = await this.modalController.getTop();
    if (userGoals || !tidyToken || alreadyOpened) {
      return;
    }
    const modal = await this.modalController.create({
      component: PickYourGoalsComponent,
      cssClass: 'onboarding-modal',
      mode: 'ios',
      canDismiss: false,
    });
    await modal.present();
  }
}

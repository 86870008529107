import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ConfirmPageParamsModel,
  ConfirmPage,
} from 'src/pages/confirm/confirm.component';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';
import { LoadCreditPage } from '../load-credit/load-credit';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'tidy-payment-method-card',
  templateUrl: 'payment-method-card.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-method-card.scss'],
})
export class PaymentMethodCardComponent implements OnInit {

  @Input() paymentMethods: any[];
  @Input() paymentMethod: any;
  @Input() teamId: string;
  @Output() action = new EventEmitter<{ type: string; paymentMethod: any }>();

  constructor(
    private rightSidePanelService: RightSidePanelService,
    private modalCtrl: ModalController,
    private util: Util,
    private paymentMethodsProvider: PaymentMethodsProvider
  ) {}

  ngOnInit() {}

  async confirmDeletePaymentMethod(paymentMethod) {
    const params: ConfirmPageParamsModel = {
      title: 'Remove payment method?',
      body: paymentMethod.metadata?.last4
        ? ` ${ new TranslationPipe().transform('Remove payment method')} **** **** **** ${paymentMethod.metadata?.last4}?`
        : new TranslationPipe().transform('Are you sure you want to remove this payment method?'),
      backText: 'Go Back',
      confirmText: 'Remove',
      confirmAction: this.deletePaymentMethod.bind(this, paymentMethod),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
    });
    await confirmationModal.present();
  }

  async deletePaymentMethod(paymentMethod) {
    try {
      if (this.teamId) {
        await this.paymentMethodsProvider.removePaymentMethodFromTeam(
          this.teamId
        );
      } else {
        await this.paymentMethodsProvider.removePaymentMethod(paymentMethod.id);
      }
      this.modalCtrl.dismiss();
      this.util.showSuccess('Payment Method Removed');
      this.action.emit({ type: 'refresh', paymentMethod: paymentMethod });
    } catch (err) {
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(new TranslationPipe().transform('Failed to Remove Payment Method ') + errorMessage);
      this.modalCtrl.dismiss();
    }
  }

  async makeCardPrimary(card) {
    const params: ConfirmPageParamsModel = {
      title: 'Make this Card Primary?',
      body: `${new TranslationPipe().transform('Make card')} **** **** **** ${card.metadata?.last4} ${new TranslationPipe().transform('primary?')}`,
      backText: 'Go Back',
      confirmText: 'Confirm ',
      confirmAction: this.confirmMakeCardPrimary.bind(this, card),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
    });
    await confirmationModal.present();
  }

  async confirmMakeCardPrimary(card) {
    try {
      await this.paymentMethodsProvider.makeCardPrimary(card.id);
      this.action.emit({ type: 'refresh', paymentMethod: card });
      this.modalCtrl.dismiss();
      this.util.showSuccess('Card Made Primary');
    } catch (err) {
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(new TranslationPipe().transform('Failed to Make Card Primary ') + errorMessage);
      this.modalCtrl.dismiss();
    }
  }

  async addMissingBankInfo(paymentMethod) {
    const params = {
      paymentMethod: paymentMethod,
      client_secret: paymentMethod.metadata.stripe.client_secret,
      action: 'addMissingBankInfo',
    };
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  async confirmPaymentMethod(paymentMethod: any) {
    const loading = await this.util.showLoading();
    try {
      const confirmResponse =
        await this.paymentMethodsProvider.confirmPaymentMethod(
          paymentMethod.id
        );
      console.log(confirmResponse);
      this.util.showSuccess('Bank account confirmed successfully.');
      this.action.emit({
        type: 'refresh',
        paymentMethod: { id: paymentMethod.id },
      });
    } catch (confirmError) {
      console.error(confirmError);
      this.util.showError(
        new TranslationPipe().transform('Error confirming bank account. ') + confirmError.message
      );
    } finally {
      loading.dismiss();
    }
  }

  goToLoadCredit(paymentMethod) {
    console.log(paymentMethod)
    const params = {
      paymentMethods: this.paymentMethods,
      paymentMethod: paymentMethod
    }
    this.rightSidePanelService.navigateTo('load-credit', params, LoadCreditPage);
  }
  
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Call ' + pro?.first_name"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row align="center" *ngIf="clientPhone">
      <tidy-text
        [body]="'Please use phone number on file:'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="(clientPhone | telephone:'format')">
      </tidy-text>
    </tidy-row>

    <tidy-row align="center" *ngIf="!clientPhone">
      <tidy-text
        [body]="'You have no phone number on file. Please add a phone number to call your pro.'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'Add Phone Number'"
        class="link"
        (action)="goToAddPhoneNumber()">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [action]="callPro.bind(this)"
      [text]="'Call ' + pro?.first_name"
      [disabled]="!clientPhone"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Accepted Proposal'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [title]="'Proposal From'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="ownerName">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="ownerEmail">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="ownerPhone | telephone:'format'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <!--TODO divider to have [color] input and add dynamic color-->
          <tidy-divider
            class="full-card-width thick"
            style="background: #4DAEED">
          </tidy-divider>
        </tidy-row>

        <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [body]="'To:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(clientName | titlecase)">
            </tidy-text>
            <div>
              <tidy-text
                [body]="'Sent'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(createdAt | customdate:'M/D/YY')">
              </tidy-text>
            </div>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="!address"
              [body]="'Valid At: All Properties'">
            </tidy-text>
            <ng-container *ngIf="address">
              <tidy-text
                [body]="'Valid At:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="address?.address + ','">
              </tidy-text><span>&nbsp;</span>
              <ng-container *ngIf="address?.unit">
                <tidy-text
                  [body]="'Unit'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="address?.unit + ','">
                </tidy-text><span>&nbsp;</span>
              </ng-container>
              <tidy-text
                [body]="address?.zip">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Cost'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true">
          <div style="width:85%">
            <tidy-text
              [header]="(serviceName | titlecase)">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [body]="(servicePrice | tcurrency)">
            </tidy-text>
          </div>
        </tidy-row>

        <tidy-row style="width:85%" class="extra-bottom-padding">
          <tidy-text
            [body]="serviceDescription">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [body]="'We are proud to offer this option for 30 days:'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="ownerName"
            class="signature">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>
      </tidy-card>

      <tidy-button
        [action]="bookJob.bind(this)"
        [text]="'Schedule Job'"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>

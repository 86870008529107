import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-initials-icon',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'initials-icon.component.html',
  styleUrls: ['./initials-icon.component.scss'],
})

export class InitialsIconComponent implements OnInit, OnChanges {
  @Input() fullName: string;
  nameInitials: string = '';

  ngOnInit(): void {
    this.nameInitials = this.parseInitials(this.fullName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.nameInitials = this.parseInitials(changes.fullName.currentValue);
  }

  parseInitials(fullName): string {
    return fullName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
}

import { TidyStorage } from './../../shared/providers/tidy-storage';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ThumbtackProvider } from 'src/providers/thumbtack/thumbtack.provider';
import { ProModel, ThumbtackProModel } from 'src/models/thumbtack.model';
import { NextStepsModalComponent } from './next-steps-modal-component/next-steps-modal-component';
import { ModalController } from '@ionic/angular';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

@Component({
  templateUrl: 'browse-pros.html',
  styleUrls: ['./browse-pros.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class BrowseProsPage implements OnInit {

  pros: ProModel[] = [];
  skeletons = Array(5).fill(0);
  wasDataLoaded = false;
  wasError = false;
  address: any;
  categoryHeader: string;
  dialogParams: any;
  isRightSideContent = true;
  customBack: string;

  constructor(
    private navCtrl: CustomNavController,
    private thumbtackProvider: ThumbtackProvider,
    private modalCtrl: ModalController,
    private dialog: MatDialog,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.rightSidePanelService.setDialogPageTitle('Browse Pros');
      }
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.address = this.dialogParams?.address || await this.navCtrl.getParam('address');
      this.categoryHeader = this.dialogParams?.categoryHeader || this.navCtrl.getParam('categoryHeader');
      this.customBack = this.dialogParams?.customBack || this.navCtrl.getParam('customBack');
      this.getThumbtackPros();
      this.rightSidePanelService.setDialogLoading(false);
    } catch (error) {
      this.wasError = true;
    }
  }

  async getThumbtackPros(): Promise<void> {
    try {
      const thumbtackCategory = this.thumbtackProvider.getThumbtackCategory(
        this.categoryHeader
      );
      const thumbtackParams = {
        zipCode: this.address.zip,
        categoryName: thumbtackCategory?.categoryName,
        categoryId: thumbtackCategory?.categoryId,
      };
      const response = await this.thumbtackProvider.getProList(thumbtackParams);
      this.pros = this.parsePros(response.results);
      this.wasDataLoaded = true;
      if (!this.pros.length) {
        this.wasError = true;
      }
    } catch (error) {
      this.wasError = true;
    }
  }

  parsePros(pros: ThumbtackProModel[]): ProModel[] {
    return pros.map((pro: ThumbtackProModel) => {
      return {
        serviceId: pro.service_id,
        businessName: pro.business_name,
        rating: pro.rating,
        numReviews: pro.num_reviews,
        yearsInBusiness: pro.years_in_business,
        numHires: pro.num_hires,
        thumbtackUrl: pro.thumbtack_url,
        imageUrl: pro.image_url,
        backgroundImageUrl: pro.background_image_url,
        featuredReview: pro.featured_review,
        introduction: pro.introduction,
        location: pro.location,
        numOfEmployees: pro.num_of_employees,
        hasBackgroundCheck: pro.has_background_check,
        businessLocation: pro.business_location,
        iframeUrl: pro.iframe_url,
        pills: pro.pills,
      };
    });
  }

  async openNextStepsModal(pro: ProModel): Promise<void> {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
        const dialog = this.dialog.open(NextStepsModalComponent, {
          panelClass: 'next-steps-modal',
        })
        dialog.afterClosed().subscribe((res) => {
          if (res) {
            this.goToThumbtack(pro);
          }
        })
      } else {
        this.modalCtrl.create({
          component: NextStepsModalComponent,
          mode: 'ios',
          backdropDismiss: true,
          showBackdrop: true,
          swipeToClose: true,
          breakpoints: [0, 0.3, 0.5, 0.8, 1],
          initialBreakpoint: 0.8,
          presentingElement: await this.modalCtrl.getTop(),
          canDismiss: true,
        }).then(modal => {
          modal.present();
          modal.onDidDismiss().then((res) => {
            if (res?.data) {
              this.goToThumbtack(pro);
            }
          })
        });
      }
  }

  goToThumbtack(pro: ProModel): void {
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url: pro.thumbtackUrl });
      return;
    }
    window.open(pro.thumbtackUrl, '_blank');
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';
import { Communication } from 'src/providers/communication/communication';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'proxy-messages.html',
})

export class ProxyMessagesPage implements OnInit {

  backPage: string;
  errorMessage: string;
  loaded: boolean;
  messageForm: UntypedFormGroup;
  messages: any;
  proxyUser: any;
  isRightSideContent: boolean;
  dialogParams: any;
  submitted: boolean;
  assignLabel = '';
  assignList = [];

  constructor(
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private rightSidePanelService: RightSidePanelService,
    private schedule: Schedule,
    private storage: TidyStorage,
    private util: Util,
  ) {
    this.messageForm = this.fb.group({
      message: [''],
      assignedTo: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.proxyUser = await this.storage.retrieve('proxyUser');
      this.handleAssignInput();
      const messages = await this.communication.getProxySharedInbox(this.proxyUser.chatRoomKey);
      this.messages = await messages.reverse();
      this.backPage = 'messages';
      await this.markMessagesAsRead();
      this.rightSidePanelService.setDialogPageTitle('Messages With ' + this.proxyUser?.first_name + ' ' + this.proxyUser?.last_name);
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (
        !message.is_read &&
        message.from_member.type !== 'CustomerMember' &&
        message?.chat_room_key
      ) {
        array.push(message?.chat_room_key);
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.messageForm.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'proxy',
        key: this.proxyUser.chatRoomKey,
      },
      message: {
        text: this.messageForm.value.message,
        files: attachments,
      },
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (
        response?.data?.message ==
        "We couldn't send your message, please try again later!"
      ) {
        return (this.errorMessage = response.data.message);
      }
      this.messageForm.patchValue({
        message: '',
      });
      const messages = await this.communication.getProxySharedInbox(this.proxyUser.chatRoomKey);
      this.messages = await messages.reverse();
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  callPro() {
    window.location.href = `tel:${this.proxyUser.phoneNumber}`;
  }

  getDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return LuxonDateTime.fromISO(date).setZone(timezone).toRelative();
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async handleAssignInput(): Promise<void> {
    if (this.proxyUser.first_name.toLowerCase() === 'pro') {
      this.assignLabel = 'Assign number to pro';
      const proList = await this.storage.retrieve('proList');
      this.assignList = this.filterAssignList(proList);
      this.messageForm.get('assignedTo').setValue(this.proxyUser?.data?.assigned_to?.id);
      return;
    }
    this.assignLabel = 'Assign number to property';
    const addressFilter = await this.storage.retrieve('addressFilter');
    this.assignList = addressFilter;
    this.messageForm.get('assignedTo').setValue(this.proxyUser?.data?.assigned_to?.id);
  }

  filterAssignList(list) {
    return list.filter((item) => !isNaN(item.value));
  }

  async onSelectAssign(id: number): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      await this.communication.assignChat(
        this.proxyUser.chatRoomKey,
        id,
        this.proxyUser.first_name.toLowerCase() === 'pro' ? 'homekeeper' : 'address'
      );
      loading.dismiss();
      this.util.showSuccess('Chat assigned successfully');
    } catch (err) {
      loading.dismiss();
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

}

<ng-container *ngIf="loaded">
  <form [formGroup]="form" novalidate action="#">
    <tidy-row class="extra-bottom-padding">
      <tidy-search-input
        formControlName="search"
        [placeholder]="'Search'"
        [icon]="'assets/svg/search-outline.svg'">
      </tidy-search-input>
    </tidy-row>
  </form>
  <tidy-card *ngFor="let option of integrationOptions">
    <tidy-card-button (action)="goToAddIntegration(option)" class="no-below-stroke">
      <tidy-image
        [src]="option.logo_url"
        style="width:70%; height:30px">
      </tidy-image>
    </tidy-card-button>
    <tidy-row *ngIf="option.short_description !== null">
      <tidy-text
        [body]="option.short_description">
      </tidy-text>
    </tidy-row>
  </tidy-card>
</ng-container>

<ng-container *ngIf="!loaded">
  <tidy-card style="padding: 3px">
    <ion-skeleton-text animated="true" style="margin-left: 10px; width: 100px; height: 16px"></ion-skeleton-text>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
  <tidy-card>
    <tidy-card-button [hideArrow]="true" class="no-below-stroke">
      <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
    </tidy-card-button>
    <tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
      <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
    </tidy-row>
  </tidy-card>
</ng-container>
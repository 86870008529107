import { Analytics } from 'src/providers/analytics/analytics';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Auth } from 'src/providers/auth/auth';
import { Client } from 'src/providers/client/client';
import { CookieProvider } from 'src/providers/cookie/cookie';
import { Me } from 'src/providers/me/me';
import { TidySession } from 'src/providers/tidy-session/tidy-session';

import { privateProSignUpDataModel } from 'src/models/private-pro-signup';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import validationUtils from 'src/shared/util/validation-utils';
import { Util } from 'src/shared/util/util';
import { Loading } from 'src/shared/components/loading/loading';
import { OnboardingFlow } from 'src/shared/enums/onboarding-flow';
import { WindowService } from 'src/shared/providers/window.service';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { HubspotFieldIds, HubspotFormIds } from 'src/shared/enums/hubspot.enum';

import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Component({
  templateUrl: 'create-account.html',
})

export class CreateAccountPage implements OnDestroy, OnInit {

  accountTypes: any;
  customerResponse: any;
  form: UntypedFormGroup;
  flowType: OnboardingFlow;
  giftCode: any = false;
  giftCodeMessage: string;
  loaded: boolean;
  ownerTypeItems: any;
  submitted: boolean;
  termsError: boolean;
  utmzTidy: any;
  alreadyTaken: boolean;
  errorMessage: string;
  proName: string;
  proTeamName: string;
  hkId: string;
  quotes: any;
  referralCode: string;
  paramsSubscription: Subscription;
  inputIds: any = HubspotFieldIds;
  formIds: any = HubspotFormIds;


  constructor(
    private auth: Auth,
    private client: Client,
    private fb: UntypedFormBuilder,
    private cookieProvider: CookieProvider,
    private me: Me,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private tidySession: TidySession,
    private util: Util,
    private storage: TidyStorage,
    private cookieService: CookieService,
    private windowService: WindowService,
    private analytics: Analytics
  ) {
    this.form = this.fb.group({
      accountName: [''],
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      password: ['', Validators.required],
      type: [''],
      terms: [true, Validators.requiredTrue],
      numberOfProperties: [''],
      ownerType: ['']
    });

    this.storage.clear();
  }

  ngOnInit() {
    this.loaded = false;
    this.initReferral();
    this.flowType = this.route.snapshot.data.flowType;
    this.tidySession.onboardingFlow = this.flowType;
    if (this.flowType === OnboardingFlow.PRIVATE) {
      this.getPrivateParams();
    }
    if (this.flowType === OnboardingFlow.NORMAL || this.flowType === OnboardingFlow.PRIVATE) {
      this.setTypeForm();
    }
    if (this.flowType === OnboardingFlow.NORMAL || this.flowType === OnboardingFlow.RENTAL || this.flowType === OnboardingFlow.COMPANY || this.flowType === OnboardingFlow.MAINTENANCE) {
      this.quotes = this.getQuotes();
    }
    if (this.flowType === OnboardingFlow.NORMAL) {
      localStorage.setItem('firstBooking', 'true');
    }
    if (this.flowType === OnboardingFlow.RENTAL) {
      this.setFormValidation({value: 'rental'});
    }
    this.ownerTypeItems = this.getOwnerTypeItems();
    this.loaded = true;
  }

  async initReferral(){
    let giftCodeParam: string;
    this.paramsSubscription = this.route.queryParams.subscribe(params => {
      giftCodeParam = params['gift-code'];
    });
    if (giftCodeParam) {
      try {
        this.giftCode = await this.client.validateGiftCode(giftCodeParam);
        this.cookieService.put('giftCodeParam', giftCodeParam);
        this.giftCodeMessage = `Welcome from ${this.giftCode?.giver_name}!`
      } catch (err) {
        this.errorMessage = `${(err.error && err.error.message) ? err.error.message : err.message} / Please check that you entered the correct URL` ;
      }
    }
  }

  getOwnerTypeItems() {
    return [
      {
        viewValue: 'I own the properties',
        value: 'own'
      },
      {
        viewValue: 'I manage properties for others',
        value: 'manage'
      },
      {
        viewValue: 'Other',
        value: 'other'
      }
    ];
  }

  getPrivateParams() {
    this.hkId = this.route.snapshot.paramMap.get('hkId');
    const proName = this.route.snapshot.paramMap.get('proName');
    this.proName = decodeURI(proName);
    const proTeamName = this.route.snapshot.paramMap.get('proTeamName');
    this.proTeamName = decodeURI(proTeamName);
  }

  setTypeForm() {
    this.form.controls.type.setValidators([Validators.required]);
    this.accountTypes = [
      {viewValue: 'For my Home', value: 'regular'},
      {viewValue: 'I Manage an Airbnb/Rental', value: 'rental'},
      {viewValue: 'For an Office', value: 'company'}
    ];
  }

  @Loading('', true)
  async createAccount() {
    const privateProSignUpData: privateProSignUpDataModel = {
      hkId: this.hkId,
      proName: this.proName,
      proTeamName: this.proTeamName
    }
    this.submitted = true;
    this.termsError = false;
    if (!this.form.valid) {
      if (!this.form.controls.terms.valid) {
        this.termsError = true;
      }
      return;
    }
    if (this.form.value.firstName.trim().length < 2 || this.form.value.lastName.trim().length < 2) {
      this.form.patchValue({
        firstName: this.form.value.firstName.trim(),
        lastName: this.form.value.lastName.trim()
      });
      return;
    }
    try {
      await this.createUser(privateProSignUpData);
      await this.updateCustomer();
      if (this.flowType !== OnboardingFlow.DEVELOPER) {
        await this.saveAccountType();
      }
      if (this.form.controls.type.value === 'rental' && this.flowType !== OnboardingFlow.PRIVATE) {
        this.flowType = OnboardingFlow.RENTAL;
      }
      if (this.flowType === OnboardingFlow.COMPANY) {
        this.flowType = OnboardingFlow.NORMAL;
      }
      localStorage.setItem('firstName', this.form.value.firstName);
      localStorage.setItem('lastName', this.form.value.lastName);
      localStorage.setItem('email', this.form.value.email);
      const bookingExperiment = this.getBookingExperiment();
      const flagToSave = this.getFlagToSave(bookingExperiment);
      localStorage.setItem('bookingExperiment', flagToSave);
      this.me.load(true);
      if (this.flowType === 'rental') {
        this.navCtrl.navigateForward('rental-get-started');
        /*TODO add this back when onboarding is ready:
        this.storage.save('didJustSignUp', true);
        this.navCtrl.navigateForward('dashboard');
        */
      } else {
        this.navCtrl.navigateForward(['add-first-property', this.flowType]);
      }
      this.analytics.configureGoogleGtag('initiate_checkout', '7n8-CIHWwbkBEIfH5s0D', {});
      this.analytics.configureGoogleGtag('test_conversion', 'cx2iCPTyzIsZEIfH5s0D', {});
    } catch (error) {
      console.error(error);
      localStorage.clear();
      const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
      this.util.showError(errorMessage, 10000);
      this.setErrorMsg(errorMessage);
    }
  }

  getFlagToSave(flag) {
    if (this.form.value.email.includes('rental-control')) {
      return 'rental-control'
    } else if (this.form.value.email.includes('rental-v1a')) {
      return 'rental-v1a'
    } else if (this.form.value.email.includes('rental-v1b')) {
      return 'rental-v1b'
    } else if (this.form.value.email.includes('rental-v2a')) {
      return 'rental-v2a'
    } else if (this.form.value.email.includes('rental-v2b')) {
      return 'rental-v2b'
    } else if (this.form.value.email.includes('rental-v3')) {
      return 'rental-v3'
    } else if (this.form.value.email.includes('consumer-control')) {
      return 'consumer-control'
    } else if (this.form.value.email.includes('consumer-test1')) {
      return 'consumer-test1'
    } else if (this.form.value.email.includes('consumer-test2')) {
      return 'consumer-test2'
    } else {
      return flag;
    }
  }

  getBookingExperiment(): string {
    let flag = '';
    const idString = this.customerResponse.id.toString();
    let lastDigit = idString[idString.length - 1];
    if (this.form.controls.type.value === 'rental') {
      if (lastDigit == 0 || lastDigit == 1 || lastDigit == 2) {
        return 'rental-control';
      } else if (lastDigit == 3 || lastDigit == 4) {
        return 'rental-v2a';
      } else if (lastDigit == 5 || lastDigit == 6) {
        return 'rental-v2b';
      } else if (lastDigit == 7 || lastDigit == 8 || lastDigit == 9) {
        return 'rental-v3';
      }
    } else {
      if (lastDigit == 0 || lastDigit == 1 || lastDigit == 2 || lastDigit == 3) {
        return 'consumer-control';
      } else if (lastDigit == 4 || lastDigit == 5 || lastDigit == 6) {
        return 'consumer-test1';
      } else if (lastDigit == 7 || lastDigit == 8 || lastDigit == 9) {
        return 'consumer-test2';
      }
    }
    return flag;
  }

  async createUser (privateProSignUpData: privateProSignUpDataModel = null) {
    await this.authLogin('signUp', privateProSignUpData);
    await this.getCustomers();
    await this.auth.setSession();
    await this.authLogin('signUpPassword', privateProSignUpData);
  }

  async authLogin(scope, privateProSignUpData: privateProSignUpDataModel = null) {
    const credentials = this.getCredentials();
    await this.auth.login(credentials, scope, privateProSignUpData);
  }

  getCredentials () {
    this.utmzTidy = this.cookieProvider.getCookie('__utmztidy');
    return {
      username: this.form.value.email,
      password: this.form.value.password,
      date_added_to_cookie: this.utmzTidy ? this.utmzTidy.dateTime : '',
      utm_source: this.utmzTidy ? this.utmzTidy.source : ''
    };
  }

  async getCustomers() {
    const customersObj = this.getCustomer();
    this.customerResponse = await this.client.getCustomers(customersObj);
    localStorage.setItem('customer_id', this.customerResponse.id);
    const cartMetadata = {
      customer: {
        booked_via: 'Self Booking',
        email: this.form.value.email,
        first_name: this.form.value.firstName.trim(),
        last_name: this.form.value.lastName.trim(),
        account_type: 'regular',
        account_name: this.form.value.accountName,
        customer_id: this.customerResponse.id,
        ...this.customerResponse
      }
    }
    await this.storage.save('cartMetadata', cartMetadata);
    await this.storage.save('isFirstBooking', true);
  }

  async updateCustomer() {
    await this.client.updateCustomer({customer: {developer: this.flowType === OnboardingFlow.DEVELOPER}}, this.customerResponse.id);
    if (this.form.value.type == 'rental') {
      const value = [
         {
            question: 'How many rental properties do you manage?',
            answer: this.form.value.numberOfProperties
         },
         {
            question: 'Are you a property manager or property owner?',
            answer: this.form.value.ownerType
         }
      ];
      const metadata = {
         key: 'ca-create-account-page',
         value: JSON.stringify(value)
      }
      await this.client.addMetadata(metadata);
    }
  }

  async saveAccountType() {
    const settings = await this.client.getClientSettings();
    if (this.flowType === OnboardingFlow.NORMAL || this.flowType === OnboardingFlow.PRIVATE) {
      settings.profile.account_type = this.form.value.type;
    } else if (this.flowType === OnboardingFlow.MAINTENANCE) {
      settings.profile.account_type = 'regular';
    } else {
      settings.profile.account_type = this.flowType;
    }
    if (settings.invoices.alt_email == '') {
      settings.invoices.alt_email = null;
    }
    await this.client.saveClientSettings(settings);
  }

  selectType(type) {
    this.setFormValidation(type);
    this.quotes = this.getUpdatedQuotes();
  }

  getCustomer() {
    return {
      'customer': {
        'booked_via': 'Self Booking',
        'email': this.form.value.email,
        'first_name': this.form.value.firstName.trim(),
        'last_name': this.form.value.lastName.trim(),
        'password': this.form.value.password,
        'account_type': 'regular',
        'account_name': this.form.value.accountName,
      }
    }
  }

  openTerms() {
    this.util.openUrl('https://www.tidy.com/terms');
  }

  goToLoginPage() {
    this.navCtrl.navigateForward('login');
  }

  setErrorMsg(error) {
    this.alreadyTaken = (error === 'Email has already been taken');
    this.errorMessage = error;
    setTimeout(() => {
      this.errorMessage = '';
    }, 10000);
  }

  getQuotes() {
    const flowQuotes = {
      [OnboardingFlow.NORMAL]: this.getNormalQuote(),
      [OnboardingFlow.COMPANY]: this.getCompanyQuote(),
      [OnboardingFlow.MAINTENANCE]: this.getMaintenanceQuote(),
      [OnboardingFlow.RENTAL]: this.getRentalQuote()
    };
    return flowQuotes[this.flowType];
  }

  getUpdatedQuotes() {
    const flowQuotes = {
      ['regular']: this.getNormalQuote(),
      ['rental']: this.getRentalQuote(),
      ['company']: this.getCompanyQuote()
    };
    return flowQuotes[this.form.value.type];
  }

  getNormalQuote() {
    return [{
      icon: 'assets/img/tidy_client_1.jpg',
      text: '\"Love my Pro. She remembers (or sees on her app) that I leave sheets in the dryer and put them on the bed for me.\"',
      person: 'Jenny P'
    },
    {
      icon: 'assets/img/tidy_client_2.jpg',
      text: '\"TIDY, my expectations were high, but you delivered. So nice to come back to a clean home!\"',
      person: 'Nancy C'
    }];
  }

  getMaintenanceQuote() {
    return [{
      icon: 'assets/img/tidy_office_1.jpg',
      text: '\"TIDY removed the hassle from maintaining my home and helped me become so much more organized!\"',
      person: 'Lisa R'
    },
    {
      icon: 'assets/img/tidy_office_2.jpg',
      text: '\"TIDY let me know what the manufacturers recommend for my smoke detector. I decided to follow it, then realized they weren\'t working at all! Getting suggestions made me sleep better at night.\"',
      person: 'Joanna B'
    }];
  }

  getRentalQuote() {
    return [{
      icon: 'assets/img/randy.jpg',
      text: '\"TIDY integrated into Airbnb for me and now my turnovers are automatic. Even though my guests randomly book, TIDY gets it done for me.\"',
      person: 'Randy Y'
    },
    {
      icon: 'assets/img/jessica.jpg',
      text: '\"Every 90 days or so I need to get my properties cleaned. I use TIDY to get it done, mostly with my own pros, but I have them help me find backups. Works like a charm.\"',
      person: 'Jessica M'
    }];
  }

  getCompanyQuote() {
    return [{
      icon: 'assets/img/tidy_office_1.jpg',
      text: '\"I love how easy it is to schedule cleanings with TIDY!  Our office has never been so consistently clean.\"',
      person: 'Lisa R'
    },
    {
      icon: 'assets/img/tidy_office_2.jpg',
      text: '\"The TIDY cleaners are so efficient - we schedule them to clean after work so that the office is clean each morning.\"',
      person: 'Joanna B'
    }];
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  setFormValidation(accountType) {
    if (accountType.value == 'rental') {
      this.form.controls.numberOfProperties.setValidators([Validators.required]);
      this.form.controls.ownerType.setValidators([Validators.required]);
    } else {
      this.form.controls.numberOfProperties.clearValidators();
      this.form.controls.numberOfProperties.updateValueAndValidity();
      this.form.controls.ownerType.clearValidators();
      this.form.controls.ownerType.updateValueAndValidity();
    }
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Bulk Upload'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Replace all to-do lists, reservations, or inventory by exporting or importing.'">
        </tidy-text>
      </tidy-row>

      <tidy-alert>
        <tidy-row>
          <form [formGroup]="form">
            <tidy-radio-button
              [label]="'What would you like to import / export?'"
              [items]="typeItems"
              [form]="form.controls.type"
              formControlName="type">
            </tidy-radio-button>
          </form>
        </tidy-row>
      </tidy-alert>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Export'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Would you like to export this?'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [text]="('Export ' | translate) + getSelectedTypeName()"
          [action]="exportItem.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Import'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Replace all reservations/inventory/to-dos by importing. When you upload a new file it will replace everything, so make sure the import is complete.'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="reservationImportForm" *ngIf="form.value.type == 'guest_reservations'">
          <tidy-row class="field-top-padding field-bottom-padding" >
            <tidy-select
              [icon]="'assets/svg/location-outline.svg'"
              [label]="'CSV Format'"
              [items]="guestReservationCsvSourceList"
              [form]="reservationImportForm.get('sourceTypeId')"
              formControlName="sourceTypeId">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              [items]="[{value: true, viewValue: 'Replace all reservations'}]"
              [initValue]="false"
              (checkedChange)="replaceAllReservationsChanged($event)">
            </tidy-checkbox>
            <tidy-text
              body="Replace reservations from all sources."
            ></tidy-text>
          </tidy-row>
        </form>
        <tidy-button
          [text]="('Import ' | translate) + getSelectedTypeName()"
          [action]="importItem.bind(this)"
          class="secondary">
        </tidy-button>
        <tidy-row class="extra-top-padding" *ngIf="showNotCSVError">
          <tidy-error-message
            [text]="'Please import a CSV file.'">
          </tidy-error-message>
        </tidy-row>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

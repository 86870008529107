import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Genome } from 'src/providers/genome/genome';
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';
import { Client } from 'src/providers/client/client';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { AddRoomItemPage } from 'src/pages/more/edit-address/add-room-item/add-room-item';
import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { EditRoomItemPage } from 'src/pages/more/edit-address/room-item-details/edit-room-item/edit-room-item';
import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';
import { restockingMockData } from 'src/shared/constants/onboarding/restocking';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  templateUrl: './maintenance.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./maintenance.scss']
})

export class MaintenancePage implements OnInit, OnDestroy {

  currentRows: any;
  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  form: UntypedFormGroup;
  filteredRows: any = [];
  panelClosedSubscription: Subscription;
  headers: any;
  rows: any;
  showLoadingSpinner: boolean;
  loaded: boolean;
  addressResponse: any;
  addressId: any;
  addressItems: any;
  propertyForm: UntypedFormGroup;
  shownRowsCount: number = 50;
  signUpForm: UntypedFormGroup;
  showOnboarding: boolean = false;
  hasProperties: boolean = false;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding: boolean;

  constructor(
    private currentAddress: CurrentAddress,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    private util: Util,
    private client: Client,
    private dashboard: Dashboard,
    public onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      search: [''],
    });
    this.propertyForm = this.fb.group({
      property: ['', Validators.required],
    });
    this.signUpForm = this.fb.group({
      whereToOrder: ['', Validators.required],
    });
    this.form.get('search').valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => this.updateSearch({ search: value }));
  }

  async ngOnInit() {
    try {
      await this.checkIfShouldShowOnboarding();
      console.log(this.hasProperties)
      this.loadPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.getPageData();
    this.loaded = true;
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/inventory').then(async () => {
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('addMaintenanceItems');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async getPageData() {
    try {
      await this.getPropertyItems();
      this.propertyForm.get('property').setValue('all');
      let roomObjects = this.showOnboarding ? restockingMockData : await this.genome.getInventoryRoomObjects();
      roomObjects = roomObjects.filter((object) => !object.consumable)
      this.buildRows(roomObjects);
      this.buildHeaders();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async getPropertyItems() {
    this.addressResponse = await this.client.getAddresses();
    if (this.addressResponse.length == 0) {
      return [];
    }
    this.addressId = this.client.getSelectedAddressId(this.addressResponse);
    this.addressItems = this.client.parseAddressList(this.addressResponse, true);
    this.addressItems.unshift({
      value: 'all',
      viewValue: 'All Properties'
    })
    this.addressItems.pop();
  }

  changeProperty() {
    if (this.propertyForm.value.property == 'all') {
      this.currentRows = this.rows;
    } else {
      this.currentRows = this.rows.filter((row) => {
        if (row['Property'].addressId == this.propertyForm.value.property) {
          return row;
        }
      });
    }
  }

  buildRows(roomObjects) {
    this.rows = [];
    roomObjects.map((object, index) => {
      let cell = {
        'Property': {
          value: this.getPropertyName(object.address),
          action: this.goToPropertyPage.bind(this, object.address.id),
          addressId: object.address.id
        },
        'Item': {
          value: (!object.model.model || object.model.model === '') ? `${object.model.category.name}` : object.model.name,
          action: null,
          icon: object?.model?.category?.default_icon_url
        },
        'last-column': {
          value: '',
          action: null,
          hasChevron: true
        },
        id: object.id
      }
      this.rows.push(cell);
    });
    this.currentRows = this.rows.slice(0, this.shownRowsCount);
  }

  async updateCurrentQuantity(object, newValue) {
    if (this.showOnboarding) {
      return;
    }
    try {
      this.showLoadingSpinner = true;
      await this.genome.updateRoomObject(object.id, {current_quantity: newValue});
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    } finally {
      this.showLoadingSpinner = false;
    }
  }

  async updateMinQuantity(object, newValue) {
    if (this.showOnboarding) {
      return;
    }
    try {
      this.showLoadingSpinner = true;
      await this.genome.updateRoomObject(object.id, {min_quantity: newValue});
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    } finally {
      this.showLoadingSpinner = false;
    }
  }

  getPropertyName(address) {
    if (address.address_name) {
      return address.address_name;
    } else if (address.address2 && address.address2 !== '') {
      return address.address1 + ', ' + address.address2;
    } else {return address.address1
      return address.address1;
    }
  }

  buildHeaders() {
    this.headers = [
      'Property',
      'Item',
      'last-column'
    ];
  }

  goToRoomItemPage(object) {
    const params = {
      addressId: object.address.id,
      itemId: object.id,
      cameFromEditItem: false
    };
    localStorage.setItem('roomItemBackPage', 'inventory');
    this.goToPage(`item-details/${object.id}`, params, ItemDetailsPage);
  }

  goToEditRoomItemPage(object) {
    const params = {
      addressId: object.address.id,
      itemId: object.id
    };
    localStorage.setItem('roomItemBackPage', 'inventory');
    this.goToPage('edit-room-item', params, EditRoomItemPage);
  }

  goToPropertyPage(addressId) {
    if (this.showOnboarding) {
      return;
    }
    this.currentAddress.addressId = addressId.toString();
    localStorage.setItem('editPropertyBackPage', 'inventory');
    this.navCtrl.navigateForward(`edit-property/${addressId}`);
  }

  updateSearch({search}) {
    this.shownRowsCount = 50;
    if (search.length < 2) {
      this.filteredRows = [];
      return this.currentRows = this.rows.slice(0, 50);
    }
    const term = search.toLowerCase();
    if (this.propertyForm.value.property == 'all') {
      this.filteredRows = this.rows.filter((row) => {
        if (row['Property'].value?.toLowerCase()?.indexOf(term) > -1 || row['Item'].value?.toLowerCase()?.indexOf(term) > -1) {
          return row;
        }
      });
    } else {
      this.filteredRows = this.currentRows.filter((row) => {
        if (row['Item'].value?.toLowerCase()?.indexOf(term) > -1) {
          return row;
        }
      });
    }
    this.currentRows = this.filteredRows.slice(0, 50);
  }

  sortChanged(sort) {
    if (sort.direction == 'asc' || sort.direction == '') {
      this.rows.sort((a, b) => a[sort.active].value > b[sort.active].value ? 1 : -1);
      this.currentRows = this.rows;
    } else {
      this.rows.sort((a, b) => a[sort.active].value < b[sort.active].value ? 1 : -1);
      this.currentRows = this.rows;
    }
  }

  goToAddRoomItemPage() {
    if (this.showOnboarding) {
      return;
    }
    const params = {
      showAddressSelect: true,
      showConsumables: false
    }
    this.goToPage('add-item', params, AddRoomItemPage);
  }

  goToAddConciergeTask() {
    const params = {
      title: 'Concierge Action',
      type: 'support.concierge_task',
      isAddingRoomItem: true
    }
    this.goToPage('contact-concierge', params, ContactConciergePage);
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.getPageData();
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  onTableColumnClick(event: {column: string, element: any}) {
    if (this.showOnboarding) {
      return;
    }
    if (event.element[event.column].action || event.element[event.column].editable) {
      return;
    }
    this.goToRoomItemPage(this.findOriginalObject(event.element));
  }

  findOriginalObject(element: any) {
    // Find the original object from roomObjects that matches this row
    const address = this.addressResponse.find(addr => addr.id === element['Property'].addressId);
    return {
      address: address,
      id: element.id // We need to add this to the row data
    };
  }

  pageChange() {
    this.shownRowsCount += 50;
    if (this.filteredRows.length > 0) {
      this.currentRows = this.filteredRows.slice(0, this.shownRowsCount);
    } else {
      this.currentRows = this.rows.slice(0, this.shownRowsCount);
    }
  }

}

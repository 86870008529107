import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { LoveItPage } from 'src/pages/feedback/love-it/love-it';
import { NeedsWorkWhatHappenedPage } from 'src/pages/feedback/needs-work/what-happened/what-happened';
import { Subscription } from 'rxjs';

@Component({
  selector: 'feedback',
  templateUrl: 'feedback.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnDestroy {

  @Input() isCleaningJob: any;
  @Input() cleaningId: any;
  @Input() feedback: any;
  @Input() pros: any;
  @Output() panelClosed = new EventEmitter();
  errorMessage: any;
  edittingReview = false;
  edittingPro: any;
  panelClosedSubscription: Subscription;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  ngOnDestroy() {
    if (this.panelClosedSubscription) {
      this.panelClosedSubscription.unsubscribe();
    }
  }

  needsWork(pro) {
    const params = {
      firstName: pro.data.homekeeper.first_name,
      pro: pro.data.homekeeper,
      isCleaningJob: this.isCleaningJob,
      jobId: this.cleaningId,
      hkJobId: pro.homekeeperJobId
    }
    this.goToPage(`needs-work-what-happened/${this.cleaningId}/${pro.homekeeperJobId}`, params, NeedsWorkWhatHappenedPage);
  }

  lovedIt(pro) {
    const params = {
      jobId: this.cleaningId,
      hkJobId: pro.homekeeperJobId
    }
    this.goToPage(`love-it/${this.cleaningId}/${pro.homekeeperJobId}`, params, LoveItPage);
  }

  editReview(pro) {
    this.edittingPro = pro;
    this.edittingReview = true;
  }

  stopEdittingReview() {
    this.edittingReview = false;
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.panelClosed.emit();
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

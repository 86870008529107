<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Reservation'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/calendar-link.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="(guestReservation?.check_in_date | customdate:'M/DD/YY') +
            ' - ' +
            (guestReservation?.check_out_date | customdate:'M/DD/YY')">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="'Reservation'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image [src]="'assets/svg/home-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="address"> </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/svg/time-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Check In:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_in_time | customdate:'h:mma':'local':'HH:mm'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_in_date | customdate:'M/DD/YY'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/svg/time-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Check Out:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_out_time | customdate:'h:mma':'local':'HH:mm'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_out_date | customdate:'M/DD/YY'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/information-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <!-- TODO address inline styles: https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-->
          <ng-container
            *ngIf="guestReservation?.guest_reservation_source_type?.name === 'Add Manually' || detail?.source_type_name === 'Add Manually'; else syncTemplate">
            <tidy-text [body]="'Added by you'"> </tidy-text>
          </ng-container>
          <ng-template #syncTemplate>
            <tidy-text
              [body]="'Synced from'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="detail?.source_type_name">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'integration'"
              (action)="goToIntegrations()"
              class="link">
            </tidy-text>
          </ng-template>
        </tidy-row>

        <ng-container *ngIf="hasReservationDetails">
          <tidy-row class="vertical-align-center">
            <tidy-image [src]="'assets/icon/group.png'" class="body-size">
            </tidy-image>
            <tidy-text [body]="getGuestInfoString()"></tidy-text>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="customFields?.length > 0">
          <tidy-row class="vertical-align-center" *ngFor="let customField of customFields">
            <tidy-image
            [src]="'assets/svg/information-circle-outline.svg'"
            class="body-size">
          </tidy-image>
            <tidy-text [body]="customField?.name + ': ' + ' ' + (customField?.value || 'N/A')"></tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <ng-container>
          <tidy-row>
            <tidy-text [body]="'<b>Automatic Booking</b>'"></tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-checkbox
              [items]="[{value: true, viewValue: 'Enable automatic booking for this reservation'}]"
              [initValue]="detail?.automatic_booking_settings[0]?.is_automatic_booking_enabled"
              (checkedChange)="updateAutomaticBooking()">
            </tidy-checkbox>
          </tidy-row>
        </ng-container>
        <tidy-row>
          <tidy-text [body]="'Status:'"></tidy-text><span>&nbsp;</span>
          <ng-container
            *ngFor="let status of detail?.automatic_booking_statuses; let i = index">
            <tidy-text [body]="status?.text"> </tidy-text>
            <span *ngIf="i < detail?.automatic_booking_statuses.length - 1">
              <tidy-text [body]="', '"> </tidy-text>
            </span>
          </ng-container>
        </tidy-row>
      </tidy-card>

      <ng-container *ngIf="isLoadingBlockedData">
        <tidy-row>
          <ion-row>
            <ion-col>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 180px; height: 24px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 100%; height: 80px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </ng-container>

      <ng-container *ngIf="blockingJobs.length > 0 && !isLoadingBlockedData">
        <tidy-row>
          <tidy-text [header]="'Blocking Jobs'"></tidy-text>
        </tidy-row>
        <tidy-card *ngFor="let item of blockingJobs">
          <tidy-card-button
            (action)="goToJobPage(item)"
            class="no-below-stroke no-padding">
            <ion-row>
              <tidy-row class="vertical-align-center no-padding">
                <tidy-image
                  [src]="item?.status?.icon || item?.icon"
                  style="width: 30px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text [header]="item?.service_name | titlecase">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding" *ngIf="!item?.parsedRequestTimes">
                  <tidy-text
                    [body]="(item?.startDate | parseDate: 'ddd') +
                    ' ' +
                    (item?.startDate | parseDate: 'M/D') +
                    ' at ' +
                    item?.time">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding" *ngIf="item?.parsedRequestTimes">
                  <tidy-text [body]="item?.parsedRequestTimes"> </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="calendarReservations.length > 0 && !isLoadingBlockedData">
        <tidy-row>
          <tidy-text [header]="'Overlapping Reservations'"></tidy-text>
        </tidy-row>
        <tidy-alert *ngFor="let item of calendarReservations">
          <tidy-card-button (action)="goToReservation(item)" class="no-below-stroke no-padding">
            <ion-row>
              <tidy-row class="vertical-align-center no-padding">
                <tidy-image
                  [src]="item?.icon" style="width: 30px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="(item?.isCheckIn ? 'Check In' : 'Check Out') | titlecase">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding">
                  <tidy-text
                    [body]="'Check in: ' +
                    ' ' +
                    (item?.startDate | parseDate: 'ddd') +
                        ' ' +
                        (item?.startDate | parseDate: 'M/D') +
                    ' at ' +
                    (item?.checkInTime)">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding">
                  <tidy-text
                    [body]="'Check out: ' +
                    ' ' +
                    (item?.endDate | parseDate: 'ddd') +
                        ' ' +
                        (item?.endDate | parseDate: 'M/D') +
                    ' at ' +
                    (item?.checkOutTime)">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-card-button>
        </tidy-alert>
      </ng-container>

      <ng-container
        *ngIf="guestReservation?.guest_reservation_source_type?.name === 'Add Manually'">
        <tidy-row style="text-align: center">
          <tidy-text
            [body]="'Delete Reservation'"
            (action)="deleteReservation()"
            class="link red">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Lost Item'"
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [body]="'Please confirm that you are missing an item and we will notify the other party.'">
      </tidy-text>
    </tidy-row>
    
    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="items"
            [label]="'Describe the lost item.'"
            [form]="form.controls.items"
            [submitted]="submitted"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [errorMessage]="'Please describe the lost item.'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-two-column-row>
      <tidy-button
        [text]="'Go Back'"
        [action]="backToPastJob.bind(this)"
        class="secondary">
      </tidy-button>
      <tidy-button
        [text]="'Confirm'"
        class="primary"
        [action]="confirm.bind(this)">
      </tidy-button>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import validationUtils from 'src/shared/util/validation-utils';
import { Loading } from 'src/shared/components/loading/loading';

import { Client } from 'src/providers/client/client';

import { TeamService } from 'src/providers/team/team';
import { UserService } from 'src/providers/user/user';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { UsersPage } from '../users';

@Component({
  templateUrl: 'add-new-user.html'
})

export class AddNewUserPage implements OnInit {

  addressId: number;
  addedUser: boolean;
  clientInfo: any;
  form: UntypedFormGroup;
  isEmployerPortal: boolean;
  loaded = false;
  salesCards: any;
  showCopiedText: boolean;
  submitted: boolean;
  teamItems: any;
  errorMessage: string;
  roleItems: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private teamService: TeamService,
    private windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      password: ['', Validators.required],
      role: ['', Validators.required],
      team: ['', Validators.required],
      teamName: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add User');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.salesCards = this.getSalesCardes();
    const userRole = localStorage.getItem('user_role');
    if (userRole !== 'owner') {
      this.navCtrl.navigateRoot('dashboard');
    }
    this.isEmployerPortal = await this.getParam('isEmployerPortal');
    await this.getTeamItems();
    this.getRoleItems();
    this.clientInfo = await this.client.getClientInfo();
    if (this.clientInfo.customer_account.account_type == 'regular') {
      this.form.controls.role.clearValidators();
      this.form.controls.role.updateValueAndValidity();
    }
    this.rightSidePanelService.setDialogPageTitle(this.addedUser ? (this.isEmployerPortal ? 'Admin Added' : 'User Added') : (this.isEmployerPortal ? 'Add Admin' : 'Add User'));
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getTeamItems() {
    this.teamItems = [];
    const teams = await this.teamService.getTeams();
    teams.map((team) => {
      this.teamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
  }

  getRoleItems() {
    this.roleItems = [
      {
        value: 'admin',
        viewValue: 'Account Admin'
      },
      {
        value: 'member',
        viewValue: 'Team Manager'
      }
    ]
  }

  @Loading('', true)
  async addNewUser() {
    this.storage.delete('tasksLastSavedAt');
    if (this.form.value.role == 'member') {
      if (this.teamItems.length > 0) {
        this.form.controls.team.setValidators([Validators.required]);
        this.form.controls.teamName.clearValidators();
        this.form.controls.teamName.updateValueAndValidity();
      } else {
        this.form.controls.teamName.setValidators([Validators.required]);
        this.form.controls.team.clearValidators();
        this.form.controls.team.updateValueAndValidity();
      }
    } else {
      this.form.controls.team.clearValidators();
      this.form.controls.team.updateValueAndValidity();
      this.form.controls.teamName.clearValidators();
      this.form.controls.teamName.updateValueAndValidity();
    }
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const data: any = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email,
      password: this.form.value.password
    }
    try {
      const user = await this.userService.addNewUser(data);
      if (this.form.value.role == 'member') {
        let customerAccountTeamId = this.form.value.team;
        if (this.teamItems.length == 0) {
          const teamPayload = {
            name: this.form.value.teamName
          }
          const team = await this.teamService.createTeam(teamPayload);
          customerAccountTeamId = team.id;
        }
        const userPayload = {
          customer_member_id: user.id,
          customer_account_team_id: customerAccountTeamId,
          role: 'manage'
        }
        await this.teamService.addUserToTeam(userPayload);
        await this.userService.changeUserRole(user.id, {role: 'member'});
      } else if (this.form.value.role == 'admin') {
        await this.userService.changeUserRole(user.id, {role: 'admin'});
      }
      this.addedUser = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      if (this.errorMessage.includes('Email has already been taken') || this.errorMessage.includes('This email is already in use.')) {
        this.errorMessage = 'Someone is already using this email. If this user wants to join your account instead, please contact the Concierge for help.';
      }
    }
  }

  goToUsersPage() {
    this.rightSidePanelService.navigateTo('users', {}, UsersPage);
  }

  copyText() {
    const textArea = document.createElement('textarea');
    textArea.value = (this.isEmployerPortal ? 'I added you as an admin in TIDY.' : 'I added you to my account in TIDY.') + '\r\n' + 'Click here to log in: https://app.tidy.com/#/login' + '\r\n' + 'Use this password: ' + this.form.value.password;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.showCopiedText = true;
    setTimeout(() => this.showCopiedText = false, 3000);
  }

  getSalesCardes() {
    return [
      {
        title: 'Shared Data',
        text: 'Assign properties to each user to let them see their job history, maintenance records, and more.',
        image: 'assets/img/cloud-service.svg'
      },
      {
        title: 'Shared Inbox',
        text: 'More easily send messages to pros as a group, with anyone able to come in and out of the conversation.',
        image: 'assets/img/inbox.svg'
      },
      {
        title: 'No Additional Cost',
        text: 'Add unlimited users to your account at no additional cost.',
        image: 'assets/img/free.svg'
      }
    ];
  }
}

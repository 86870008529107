import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { Util } from "src/shared/util/util";
import { WindowService } from 'src/shared/providers/window.service';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamPage } from '../team/team';
import { AddTeamPage } from '../add-team/add-team';

@Component({
  templateUrl: 'teams.html'
})

export class TeamsPage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  teamsList: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private team: TeamService,
    private windowService: WindowService,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Teams');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    try {
      this.teamsList = await this.team.getTeams();
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  goToTeamPage(team) {
    const params = {
      team: team
    }
    this.rightSidePanelService.navigateTo(`team/${team.id}`, params, TeamPage);
  }

  goToAddTeamPage() {
    this.rightSidePanelService.navigateTo('add-team', {}, AddTeamPage);
  }

  goToTeamsHelpUrl() {
    const url = "https://help.tidy.com/teams";
    this.util.openUrl(url);
  }

}

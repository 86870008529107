import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Client } from 'src/providers/client/client';
import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import { Util } from 'src/shared/util/util';
import { Communication } from 'src/providers/communication/communication';

@Component({
  selector: 'enable-concierge-messaging-onboarding',
  templateUrl: './enable-concierge-messaging-onboarding.html',
  styleUrls: ['./enable-concierge-messaging-onboarding.scss'],
})
export class EnableConciergeMessagingOnboarding implements OnInit {
  radioButtonConciergeLevel: RadioButtonModel[] = [];
  form: FormGroup;
  smartNumber: boolean;

  constructor(
    public onboardingProvider: OnboardingProvider,
    private formBuilder: FormBuilder,
    private client: Client,
    private util: Util,
    private communication: Communication
  ) {}

  ngOnInit() {
    this.radioButtonConciergeLevel = [
      {
        value: 'low',
        viewValue:
          'Low - Support pros with inbound app questions but do not otherwise contact them.',
      },
      {
        value: 'medium',
        viewValue:
          'Medium - Support + Contact pros as needed on my behalf for job requests only, following their preferences.',
      },
      {
        value: 'high',
        viewValue:
          'High - Respond to inbound messages, make calls on my behalf, and only escalate as needed.',
      },
    ];

    this.form = this.formBuilder.group({
      conciergeLevel: ['low'],
    });
    this.patchConciergeLevelValue();
    this.patchSmartNumberValue();
  }

  async patchConciergeLevelValue() {
    const metadata = await this.client.getMetadata(['external.conciergeLevel']);
    const conciergeLevel = metadata?.[0]?.value || 'low';
    this.form.get('conciergeLevel').setValue(conciergeLevel);
  }

  async patchSmartNumberValue() {
    const metadata = await this.client.getMetadata(['external.smartNumber']);
    const smartNumber = metadata?.[0]?.value || false;
    this.smartNumber = smartNumber;
  }

  async submit() {
    try {
      await Promise.all([
        this.client.addMetadata({
          key: 'conciergeLevel',
          value: this.form.value.conciergeLevel,
        }),
        this.communication.createProxyNumber('guest'),
        this.onboardingProvider.markGoalAsCompleted('messageAutomation')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Settings saved succesfully');
    } catch (error) {
      const errorMessage = error?.error?.message || 'Error saving concierge level';
      this.util.showError(errorMessage);
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { Client } from 'src/providers/client/client';

@Injectable()
export class CleaningTypes {
  serviceData: any = [
    {
      'type': 'tidy',
      'name': 'TIDY',
      'duration': 60,
      'number_of_homekeepers': 1
    },
    {
      'type': 'plus',
      'name': 'TIDY+',
      'duration': 150,
      'number_of_homekeepers': 1
    },
    {
      'type': 'tidy_xl',
      'name': 'TIDY XL',
      'duration': 240,
      'number_of_homekeepers': 1,
    },
    {
      'type': 'turnover_tidy',
      'name': 'Turnover TIDY',
      'duration': 60,
      'number_of_homekeepers': 2
    },
    {
      'type': 'turnover_plus',
      'name': 'Turnover TIDY+',
      'duration': 150,
      'number_of_homekeepers': 2
    },
    {
      'type': 'mighty',
      'name': 'MIGHTY',
      'duration': 240,
      'number_of_homekeepers': 2
    }
  ]
  constructor(
    private client: Client,
    private httpClient: HttpClientCustom
  ) {}

  async getCleaningTypes(): Promise<any> {
    const addressResponse = await this.client.getAddresses();
    const addressId = await this.client.getSelectedAddressId(addressResponse);
    const selectedAddress = addressResponse.find((address) => {
      return address.id === addressId;
    });
    return[
      {
        type: "tidy",
        name: "1 hour cleaning",
        duration: 60,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00",
          "21:30"
        ],
        price: 4500,
        otc_price: 5000,
        sdc_price: 6800
      },
      {
        type: "tidy_xl",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 13500,
        otc_price: 14900,
        sdc_price: 20300
      },
      {
        type: "plus",
        name: "2.5 hour cleaning",
        duration: 150,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00"
        ],
        price: 9000,
        otc_price: 9900,
        sdc_price: 13500
      },
      {
        type: "half_mighty",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 1,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 12500,
        otc_price: 13800,
        sdc_price: 18800
      },
      {
        type: "turnover_tidy",
        name: "1 hour cleaning",
        duration: 60,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00",
          "21:30"
        ],
        price: 9000,
        otc_price: 9900,
        sdc_price: 13500
      },
      {
        type: "turnover_plus",
        name: "2.5 hour cleaning",
        duration: 150,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30",
          "20:00"
        ],
        price: 18000,
        otc_price: 19800,
        sdc_price: 27000
      },
      {
        type: "mighty",
        name: "4 hour cleaning",
        duration: 240,
        number_of_homekeepers: 2,
        start_times: [
          "08:00",
          "09:30",
          "11:00",
          "12:30",
          "14:00",
          "15:30",
          "17:00",
          "18:30"
        ],
        price: 25000,
        otc_price: 27500,
        sdc_price: 37500
      }
    ];
  }

  getTypeOfPlan(hkNumber, data) {
    const clonedData = data.slice();
    const dataPlan: any = clonedData.filter((plan: any) => {
      return (plan.number_of_homekeepers === hkNumber)
    })
    return dataPlan;
  }

  async getNumberOfHk(plan) {
    const cleaningTypes = await this.getCleaningTypes();
    let hkNumber = 0;
    cleaningTypes.map((cleaningType) => {
      if (plan === cleaningType.type) {
        hkNumber = cleaningType.number_of_homekeepers;
      }
    });
    return hkNumber;
  }

  getHkCleaningData (): any[] {
    return [
      {
        title: '1 Homekeeper',
        description: 'Save Money',
        hkNumber: 1,
        imageName: '1_hk'
      },
      {
        title: '2 Homekeepers',
        description: 'More Reliability',
        hkNumber: 2,
        imageName: '2_hks'
      }
    ];
  }

  getStaticCLeaningTypes (regionData) {
    regionData.map((item) => {
      item = this.serviceData.map((serviceItem) => {
        if (serviceItem.type === item.service_type) {
          serviceItem.price = item.amount;
        }
      })
    });
    return this.serviceData;
  }

  getCleaningTypeHkNumber(type) {
    return this.serviceData.find(item => item.type === type);
  }

  getPerson(type) {
    const service = this.serviceData.find(item => item.type === type);
    const duration = service.duration / 60;
    const hours = duration > 1 ? 'hours' : 'hour';
    const person = service.number_of_homekeepers > 1 ? 'persons' : 'person';
    return `${duration} ${hours} ${service.number_of_homekeepers } ${person} Cleaning ${service.name}`;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FilePicker, PickFilesResult } from '@capawesome/capacitor-file-picker';
import { randomUUID } from '../../utils/secure-utils';
import { Camera } from '@capacitor/camera';
import * as exifr from 'exifr';
import { Exif as CapacitorExif } from '@capacitor-community/exif';
import { ToastController } from '@ionic/angular';

const MAX_IMAGES_LIMIT = 10;

@Component({
  selector: 'tidy-file-upload',
  templateUrl: 'file-upload.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() showTextLink: boolean;
  @Input() multiple = true;
  @Input() accept = 'image/*';
  @Input() label = 'Choose a file';
  @Input() images: any[] = [];
  @Input() showPreview = true;
  @Input() showUploadButton = true;
  @Input() showDeleteButton = true;
  @Input() showDownloadButton = true;
  @Input() slidesPerView = 2.4;
  @Input() autoAdjustSlides = true;
  @Output() addedImagesChange = new EventEmitter<any[]>();
  @Output() removedImageChange = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  @Input() id: string;

  constructor(private toastCtrl: ToastController) {}

  isMobile = Capacitor.isNativePlatform();

  ngOnInit(): void {
    const randomId = Math.random().toString(36).substring(7);
    this.id = this.id || `tidy-file-upload-${randomId}`;
    this.showDownloadButton = !this.isMobile && this.accept.includes('image');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images) {
      this.images = changes.images.currentValue;
      this.autoAdjustSlidesPerView();
    }
    if (changes.id) {
      this.id = changes.id.currentValue;
    }
  }

  async showInfo(message: string, duration = 5000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      cssClass: 'toast-info',
      icon: 'information-circle-outline',
    });
    toast.present();
  }

  async onFileChange(event: Event): Promise<void> {
    try {
      console.log('onFileChange', event);
      const input = event.target as HTMLInputElement;
      const files: FileList | any = input.files;
      if (files && files.length > MAX_IMAGES_LIMIT) {
        this.onError.emit(
          `You can only upload a maximum of ${MAX_IMAGES_LIMIT} files at once.`
        );
        input.value = '';
        return;
      }
      console.log('files', files);


      if (this.accept.includes('image')) {
        const imagesAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const allowedTypes = imagesAllowedTypes;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const isValidType = allowedTypes.some((type) => file.type === type);

          if (!isValidType) {
            await this.showInfo(
              `Please upload a file with one of the following types: ${allowedTypes.join(
                ', '
              )}`
            );
            input.value = '';
            return;
          }
        }
      }
      const readFilesPromises = [];
      const filesOutOfPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (this.accept.includes('video')) {
          if (!file.type.includes('video')) {
            this.onError.emit('Please upload a video file');
            throw new Error('Please upload a video file');
          }
          await this.validateWebFile(file);
          filesOutOfPromises.push({
            file,
            mediaType: 'video',
          });
          continue;
        }
        if (!file.type.includes('image')) {
          this.onError.emit('Please upload an image file');
          throw new Error('Please upload an image file');
        }
        const reader = new FileReader();

        const readFilePromise = new Promise(async (resolve) => {
          reader.onload = async (e) => {
            const image: any = {
              uuid: randomUUID(),
              url: e.target.result,
              file,
              mediaType: 'photo',
            };
            try {
              const exifData = await exifr.parse(file);
              image.exif = exifData;
            } catch (error) {
              console.warn('Failed to parse EXIF data:', error);
              image.exif = null;
            }
            resolve(image);
          };
          reader.readAsDataURL(file);
        });

        readFilesPromises.push(readFilePromise);
      }

      input.value = '';

      if (filesOutOfPromises.length > 0) {
        this.addedImagesChange.emit(filesOutOfPromises);
        this.autoAdjustSlidesPerView();
        return;
      }

      Promise.all(readFilesPromises).then((addedImages) => {
        this.addedImagesChange.emit(addedImages);
        console.log('addedImages', addedImages);
        this.autoAdjustSlidesPerView();
      });
    } catch (error) {
      console.error(error);
      console.error('Error picking images', error);
      this.onError.emit(error);
    }
  }

  downloadImage(image: any): void {
    this.saveImageToDeviceOnWeb(image.url, `photo-${Date.now()}.jpeg`);
  }

  // Save photo to device on mobile web (Downloads folder)
  async saveImageToDeviceOnWeb(
    base64Photo: string,
    fileName: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const blob = this.base64ToBlob(base64Photo, 'image/jpeg');
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        resolve();
      } catch (err) {
        console.error(
          'error on trying to save image to device on mobile web:',
          err
        );
        reject(err);
      }
    });
  }

  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  async pickPhotos(): Promise<void> {
    try {
      let result: PickFilesResult | any;
      let mediaType = 'photo';
      if (this.accept.includes('image')) {
        const images = await Camera.pickImages({
          limit: this.multiple ? MAX_IMAGES_LIMIT : 1,
        });

        if (images.photos.length > MAX_IMAGES_LIMIT) {
          await this.showInfo(
            `You can only upload a maximum of ${MAX_IMAGES_LIMIT} images at once.`
          );
          return;
        }

        const addedImages = await Promise.all(
          images.photos.map(async (image) => {
            const blob = await fetch(image.webPath).then((r) => r.blob());
            const base64 = await this.blobToBase64(blob);
            return {
              uuid: randomUUID(),
              url: base64,
              file: image,
              mediaType: 'photo',
            };
          })
        );
        this.addedImagesChange.emit(addedImages);
        this.autoAdjustSlidesPerView();
        return;
      }
      if (this.accept.includes('video')) {
        result = await FilePicker.pickVideos({
          limit: 1,
          readData: false,
        });
        this.validateMobileFile(result);
        mediaType = 'video';
      }

      const addedImages = await Promise.all(
        result.files.map(async (file) => {
          let exif = {};
          try {
            const coordinates = await CapacitorExif.getCoordinates({
              pathToImage: file.path,
            });
            exif = {
              latitude: coordinates?.lat,
              longitude: coordinates?.lng,
            };
          } catch (error) {
            console.error('Error getting coordinates', error);
          }
          return {
            uuid: randomUUID(),
            url: this.accept.includes('video')
              ? null
              : `data:${file.mimeType};base64,${file.data}`,
            file,
            mediaType,
            exif,
          };
        })
      );

      this.addedImagesChange.emit(addedImages);
      this.autoAdjustSlidesPerView();
    } catch (error) {
      console.error('Error picking images', error);
      this.onError.emit(error);
    }
  }

  async blobToBase64(blob: Blob): Promise<string> {
    const arrayBuffer = await blob.arrayBuffer();
    const base64 = this.arrayBufferToBase64(arrayBuffer);
    return `data:${blob.type};base64,${base64}`;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  autoAdjustSlidesPerView(): void {
    if (
      !this.images ||
      this.images?.length === 0 ||
      this.images?.length === 1
    ) {
      this.slidesPerView = 1;
      return;
    }
    if (this.autoAdjustSlides) {
      this.slidesPerView = this.images?.length > 2 ? 2.4 : 2;
    }
  }

  removeImage(index: number, image: any): void {
    this.removedImageChange.emit({ index, image });
    this.autoAdjustSlidesPerView();
  }

  validateMobileFile(result: PickFilesResult) {
    /* const sizeLimit = 30000000; */
    const durationLimit = 180;
    /* const errorMessage = `Please upload a file smaller than 30MB and shorter than 3 minutes.`; */
    const errorMessage = `Please upload a file shorter than 3 minutes.`;

    for (const file of result.files) {
      if (/* file.size > sizeLimit ||  */ file.duration > durationLimit) {
        throw new Error(errorMessage);
      }
    }
  }

  async validateWebFile(file: File): Promise<void> {
    /* const sizeLimit = 30000000; */
    const durationLimit = 180;
    /* const errorMessage = `Please upload a file smaller than 30MB and shorter than 3 minutes.`; */
    const errorMessage = `Please upload a file shorter than 3 minutes.`;

    console.log('file', file);
    return await new Promise<void>((res, rej) => {
      /* if (file.size > sizeLimit) {
        console.log('file.size > sizeLimit', file.size > sizeLimit);
        rej(errorMessage);
      } */
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        console.log('video.duration', video.duration);
        if (video.duration > durationLimit) {
          console.log(
            'video.duration > durationLimit',
            video.duration > durationLimit
          );
          rej(errorMessage);
        }
        res();
      };
    });
  }
}

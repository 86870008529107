<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true"
    [title]="'Book Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-alert>
      <tidy-row [alignLastItemRight]="true">
        <div style="width:85%">
          <tidy-text
            [header]="(proposal?.service_details?.service_name | titlecase)">
          </tidy-text>
        </div>
        <div>
          <tidy-text
            [body]="(proposal?.service_details?.price | tcurrency)">
          </tidy-text>
        </div>
      </tidy-row>

      <tidy-row style="width:85%">
        <tidy-text
          [body]="proposal?.description">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Log In to Book'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="email"
              [form]="form.controls.email"
              [label]="'Email'"
              [icon]="'assets/svg/mail-outline.svg'"
              [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
              [errorMessage]="'Please enter a valid email'"
              [submitted]="submitted"
              type="email">
            </tidy-input>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-input
              formControlName="password"
              [form]="form.controls.password"
              [label]="'Password'"
              [icon]="'assets/svg/lock-closed-outline.svg'"
              [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
              [type]="'password'"
              [errorMessage]="'Please enter a password'"
              [submitted]="submitted">
            </tidy-input>
          </tidy-row>
          <tidy-row align="center">
            <tidy-text
              class="link"
              [body]="'Forgot Password?'"
              (action)="pushToForgotPasswordPage()">
            </tidy-text>
          </tidy-row>
        </form>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [action]="logIn.bind(this)"
      [text]="'Next'"
      class="primary">
    </tidy-button>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Nevermind, Book Later'"
        (action)="goToClientHub()"
        class="link">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>

<div cdkDropList
  *ngIf="contentChecked"
  [id]="dragDropId"
  [cdkDropListData]="dataList"
  [cdkDropListConnectedTo]="connectedDragDropsIds"
  [cdkDropListDisabled]="disabled"
  [cdkDropListEnterPredicate]="allowDrop.bind(this)"
  >
  <div *ngFor="let template of dragDropItems; let i = index" cdkDrag (cdkDragDropped)="itemDropped($event)" [cdkDragData]="dataList[i]">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>
  <div class="empty-list" *ngIf="!disabled && dataList?.length === 0">
    <div>{{emptyListMessage}}</div>
  </div>
</div>
<tidy-alert class="empty-list-alert" *ngIf="disabled && dataList?.length === 0">
  <tidy-row [align]="'center'">
    <tidy-text
      [helper]="emptyDisabledListMessage">
    </tidy-text>
  </tidy-row>
</tidy-alert>

import { Injectable } from '@angular/core';

@Injectable()
export class CustomNavParams {

  setParams(key: string, data: object): void {
    if (data) {
      localStorage.setItem(this.storageKey(key), JSON.stringify(data));
    }
  }

  readParams(key: string): any {
    const params = localStorage.getItem(this.storageKey(key));
    return params ? JSON.parse(params) : {};
  }

  removeParams(key: string): void {
    localStorage.removeItem(this.storageKey(key));
  }

  private storageKey(key: string): string {
    return `navparams_${key}`;
  }

}

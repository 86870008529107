import { Util } from './../../../util/util';
import { Component, OnInit } from '@angular/core';
import {
  TidySelectBooleanValueModel,
  TidySelectNumberValueModel,
  TidySelectStringValueModel,
} from 'src/models/tidy-select-item.model';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { PhotoCaptionService } from '../../photo-caption/photo-caption.service';
import {
  UntypedFormBuilder,
  Validators,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { Client } from 'src/providers/client/client';
import { Addresses } from 'src/providers/customer/addresses';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';

@Component({
  selector: 'select-access-notes-option-onboarding',
  templateUrl: './select-access-notes-option-onboarding.html',
  providers: [PhotoCaptionService],
})
export class SelectAccessNotesOptionOnboarding implements OnInit {
  parkingTypes: TidySelectBooleanValueModel[];
  paidParkingSpots: TidySelectStringValueModel[];
  freeParkingSpots: TidySelectStringValueModel[];
  errorMessage: string;
  forms: FormArray;
  maxCostOptions: TidySelectNumberValueModel[];
  payOptions: TidySelectStringValueModel[];
  addresses: any[] = [];

  constructor(
    public onboardingProvider: OnboardingProvider,
    private photoCaptionService: PhotoCaptionService,
    private fb: UntypedFormBuilder,
    private client: Client,
    private addressesProvider: Addresses,
    private photoNotes: PhotoNotes,
    private util: Util
  ) {}

  async ngOnInit() {
    try {
      localStorage.removeItem('addressId');
      this.forms = this.fb.array([]);
      this.addresses = await this.client.getAddresses();
      this.initializeForms();
      this.setFormOptions();
    } catch (err) {
      console.error(err);
    }
  }

  initializeForms() {
    this.addresses.forEach((address) => {
      this.forms.push(
        this.fb.group({
          address_id: [address.id],
          address_name: [
            this.getAddressName(address) || '',
            Validators.required,
          ],
          paid_parking: [address?.paid_parking || false, Validators.required],
          parking_notes: [address?.parking_notes || '', Validators.required],
          home_access: [address?.home_access || '', Validators.required],
          home_close: [address?.home_close || ''],
          parking_spot: [address?.parking_spot || '', Validators.required],
          parking_pay_with: [
            address?.parking_pay_with || '',
            address.paid_parking ? Validators.required : null,
          ],
          max_parking_cost: [
            address?.max_parking_cost || '',
            address.paid_parking ? Validators.required : null,
          ],
          accessPhotoUrls: [
            this.photoCaptionService.mountPhotoUrls(
              address.photo_notes,
              'access'
            ),
          ],
          closingPhotoUrls: [
            this.photoCaptionService.mountPhotoUrls(
              address.photo_notes,
              'closing'
            ),
          ],
          parkingPhotoUrls: [
            this.photoCaptionService.mountPhotoUrls(
              address.photo_notes,
              'parking'
            ),
          ],
        })
      );
    });
    console.log(this.forms);
  }

  getAddressName(address) {
    let addressName = '';
    if (address.address_name) {
      addressName = address.address_name;
    } else if (address.address2) {
      addressName = address.address1 + ', ' + address.address2;
    } else {
      addressName = address.address1;
    }
    return addressName;
  }

  goToUploadAccessNotes() {
    this.onboardingProvider.priorPages.push('selectAccessNotes');
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'createConciergeAction';
  }

  async getImg(form: FormGroup, name: string) {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg(name);

      switch (name) {
        case 'parking':
          form.get('parkingPhotoUrls').value.push(newPhotoNote);
          break;
        case 'access':
          form.get('accessPhotoUrls').value.push(newPhotoNote);
          break;
        case 'closing':
          form.get('closingPhotoUrls').value.push(newPhotoNote);
          break;
        default:
          null;
      }
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  setFormOptions() {
    this.parkingTypes = [
      {
        viewValue: 'Free',
        value: false,
      },
      {
        viewValue: 'Paid',
        value: true,
      },
    ];
    this.paidParkingSpots = [
      {
        viewValue: 'Meter',
        value: 'meter',
      },
      {
        viewValue: 'Paid Lot',
        value: 'paidlot',
      },
    ];
    this.freeParkingSpots = [
      {
        viewValue: 'Street',
        value: 'street',
      },
      {
        viewValue: 'My Spot / Driveway',
        value: 'myspot',
      },
      {
        viewValue: 'Guest Parking',
        value: 'guest',
      },
    ];
    this.payOptions = [
      {
        viewValue: 'Cash',
        value: 'cash',
      },
      {
        viewValue: 'Card',
        value: 'card',
      },
    ];
    let maxCostOptions = [];
    for (let i = 1; i <= 30; i++) {
      maxCostOptions.push({
        viewValue: 'Max $' + i,
        value: i * 100,
      });
    }
    this.maxCostOptions = maxCostOptions;
  }

  setValidators(form: FormGroup) {
    if (form.get('paid_parking').value) {
      const requiredParkingFields = ['parking_pay_with', 'max_parking_cost'];
      requiredParkingFields.forEach((field) =>
        form.get(field).setValidators([Validators.required])
      );
    }
  }

  clearFields(form: FormGroup) {
    form.patchValue({
      parking_spot: null,
      parking_pay_with: null,
      max_parking_cost: null,
    });
    this.setValidators(form);
  }

  async confirmDelete(photoId: number, form: FormGroup) {
    try {
      this.photoNotes.addressId = form.value.address_id;
      await this.photoCaptionService.confirmDelete(photoId, [
        ...form.get('accessPhotoUrls').value,
        ...form.get('parkingPhotoUrls').value,
        ...form.get('closingPhotoUrls').value,
      ]);
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async save() {
    const loading = await this.util.showLoading();
    try {
      this.errorMessage = '';
      await this.uploadPhotos();
      const addresses = [];
      this.forms.controls.map(async (form) => {
        const addressFields = [
          'paid_parking',
          'parking_spot',
          'parking_pay_with',
          'max_parking_cost',
          'parking_notes',
          'home_access',
          'home_close',
        ];
        const address = this.addresses.find(
          (address) => address.id === form.value.address_id
        );
        addressFields.forEach(
          (field) => (address[field] = form.get(field).value)
        );
        address.address_id = form.value.address_id;
        addresses.push(address);
      });
      await Promise.all([
        this.addressesProvider.updateMany({ addresses }),
        this.onboardingProvider.markGoalAsCompleted('selectAccessNotes')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Access notes saved succesfully');
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    } finally {
      await loading.dismiss();
    }
  }

  async uploadPhotos() {
    try {
      for (const form of this.forms.controls) {
        const addressId = form.value.address_id;
        this.photoNotes.addressId = addressId;
        console.log(addressId);

        await this.photoCaptionService.uploadPhotosGeneric(
          form.get('accessPhotoUrls').value,
          'access',
          this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'access'),
          this.photoNotes.photoCaption.bind(this.photoNotes),
          'room'
        );

        await this.photoCaptionService.uploadPhotosGeneric(
          form.get('parkingPhotoUrls').value,
          'parking',
          this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'parking'),
          this.photoNotes.photoCaption.bind(this.photoNotes),
          'room'
        );

        await this.photoCaptionService.uploadPhotosGeneric(
          form.get('closingPhotoUrls').value,
          'closing',
          this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'closing'),
          this.photoNotes.photoCaption.bind(this.photoNotes),
          'room'
        );
      }
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
}

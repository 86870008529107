<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="isBookingJob ? 'Select Subscription' : 'Change Subscription'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
  <form [formGroup]="form">

      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <div *ngIf="isMobileResolution || isRightSideContent" [ngStyle]="!isRightSideContent ? {'margin-top': '60px'} : {}">
          <tidy-card class="top-card">
            <div class="flex-row">
              <div>
                <div class="padding-bottom-5px">
                  <tidy-text
                    [header]="'Subscription Total'">
                  </tidy-text>
                </div>
                <tidy-radio-button
                  formControlName="yearlyMonthly"
                  [form]="form.controls.yearlyMonthly"
                  [items]="[{value: 'yearly', viewValue: 'Pay Yearly'}, {value: 'monthly', viewValue: 'Pay Monthly'}]"
                  (optionChange)="selectSubscription(selectedSubscription)">
                </tidy-radio-button>
              </div>
              <div *ngIf="selectedCostPerUnit || changedCustomSubscription">
                <div style="margin-bottom: 5px; margin-top: -5px">
                  <div class="price-header-mobile">
                    ${{selectedCost / 100}}
                    <tidy-text
                      style="margin-left: -5px"
                      [body]="form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month'">
                    </tidy-text>
                  </div>
                </div>
                <div>
                  <tidy-text
                    [body]="'($' + (selectedCostPerUnit / 100) + ' x ' + numberOfUnits">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'unit' + (numberOfUnits == 1 ? '' : 's') + ')'">
                  </tidy-text>
                </div>
              </div>
              <div *ngIf="customSubscription && !changedCustomSubscription">
                <div style="margin-bottom: 5px; margin-top: -5px">
                  <div class="price-header-mobile">
                    ${{customSubscription?.plan?.amount * numberOfUnits / 100}}
                    <tidy-text style="margin-left: -5px" [body]="form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month'">
                    </tidy-text>
                  </div>
                </div>
                <div>
                  <tidy-text
                    [body]="'($' + (customSubscription?.plan?.amount / 100) + ' x ' + numberOfUnits">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'unit' + (numberOfUnits == 1 ? '' : 's') + ')'">
                  </tidy-text>
                </div>
              </div>
            </div>
          </tidy-card>

          <tidy-card *ngIf="customSubscription" [ngClass]="selectedSubscription == customSubscription ? 'light-black-background' : ''">
            <tidy-row>
              <tidy-text
                [header]="'Custom Subscription:'">
              </tidy-text>
              <tidy-text
                [header]="customSubscription?.plan?.description">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'You have a custom subscription. Please reach out to the concierge to make changes to your custom subscription.'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="changedCustomSubscription ? 'Select' : 'Selected'"
              [action]="selectCustomSubscription.bind(this)"
              class="secondary"
              [disabled]="selectedSubscription == customSubscription">
            </tidy-button>
          </tidy-card>

          <tidy-card [ngClass]="selectedSubscription == 'Free' ? 'light-black-background' : ''">
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="'Free'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [actionBody]="'Great for people with 1 property/unit.'"
                class="green">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="'$0'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'/unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'<b>Free plan features:</b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Manage unlimited pros/jobs'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Amazing operations software'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Book cleaning/maintenance anywhere'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="currentSubscription == 'Free' ? 'Current' : (selectedSubscription == 'Free' ? 'Selected' : 'Select')"
              [action]="selectSubscription.bind(this, 'Free')"
              [disabled]="true"
              class="secondary"
              [disabled]="currentSubscription == 'Free'">
            </tidy-button>
          </tidy-card>
          <tidy-card [ngClass]="selectedSubscription == 'TIDY+' ? 'light-black-background' : ''">
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="'TIDY+'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [actionBody]="'Great for long-term rental managers.'"
                class="green">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="cost['TIDY+'][form?.value?.yearlyMonthly] | tcurrency">
              </tidy-text>
              <tidy-text
                [body]="' /unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'<b>Everything in Free, plus:</b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'More users and properties'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Save 20% on in-network jobs'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Basic integrations & automations'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="currentSubscription == 'TIDY+' ? 'Current' : (selectedSubscription == 'TIDY+' ? 'Selected' : 'Select')"
              [action]="selectSubscription.bind(this, 'TIDY+')"
              class="secondary"
              [disabled]="currentSubscription == 'TIDY+'">
            </tidy-button>
          </tidy-card>
          <tidy-card [ngClass]="selectedSubscription == 'Standard' ? 'light-black-background' : ''">
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="'Standard'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [actionBody]="'Great for short-term rental (STR) managers.'"
                class="green">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="cost['Standard'][form?.value?.yearlyMonthly] | tcurrency">
              </tidy-text>
              <tidy-text
                [body]="' /unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'<b>Everything in TIDY+, plus:</b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'All software features'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Powerful automations'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Limited use of our human concierge'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="currentSubscription == 'Standard' ? 'Current' : (selectedSubscription == 'Standard' ? 'Selected' : 'Select')"
              [action]="selectSubscription.bind(this, 'Standard')"
              class="secondary"
              [disabled]="currentSubscription == 'Standard'">
            </tidy-button>
          </tidy-card>
          <tidy-card [ngClass]="selectedSubscription == 'advanced' ? 'light-black-background' : ''">
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="'Advanced'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [actionBody]="'For the most advanced property managers.'"
                class="green">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [title]="cost['Advanced'][form?.value?.yearlyMonthly] | tcurrency">
              </tidy-text>
              <tidy-text
                [body]="' /unit/month'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'<b>Everything in Standard, plus:</b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Unlimited automations'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'Unlimited human concierge'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/checkmark-circle-green.svg'">
              </tidy-image>
              <tidy-text
                [body]="'24/7/365 emergency job requests'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="currentSubscription == 'Advanced' ? 'Current' : ('Talk to an Expert')"
              [action]="goToLearnMoreAdvanced.bind(this, 'Advanced')"
              class="secondary"
              [disabled]="currentSubscription == 'Advanced'">
            </tidy-button>
          </tidy-card>

          <tidy-row [align]="'center'" class="extra-top-padding extra-bottom-padding">
            <tidy-text
              [actionBody]="'Learn more about each plan'"
              class="link"
              (action)="goToLearnMorePlans()">
            </tidy-text>
          </tidy-row>

          <tidy-button
            [disabled]="selectedSubscription == currentSubscription && currentCostPerUnit == selectedCostPerUnit"
            [text]="'Change Subscription Now'"
            [action]="save.bind(this)"
            class="primary">
          </tidy-button>
        </div>

        <div *ngIf="!isMobileResolution && !isRightSideContent">

          <tidy-card class="top-card">
            <div class="flex-row">
              <div>
                <div class="padding-bottom-5px">
                  <tidy-text
                    [title]="'Subscription Total'">
                  </tidy-text>
                </div>
                <tidy-radio-button
                  formControlName="yearlyMonthly"
                  [form]="form.controls.yearlyMonthly"
                  [items]="[{value: 'yearly', viewValue: 'Pay Yearly'}, {value: 'monthly', viewValue: 'Pay Monthly'}]"
                  (optionChange)="selectSubscription(selectedSubscription)">
                </tidy-radio-button>
              </div>
              <div *ngIf="selectedCostPerUnit || changedCustomSubscription">
                <div style="margin-bottom: 5px; margin-top: -5px">
                  <div class="price-header-desktop">
                    ${{selectedCost / 100}}
                    <tidy-text
                      style="margin-left: -5px"
                      [body]="form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month'">
                    </tidy-text>
                  </div>
                </div>
                <div>
                  <tidy-text
                    [body]="'($' + (selectedCostPerUnit / 100) + ' x ' + numberOfUnits">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'unit' + (numberOfUnits == 1 ? '' : 's') + ')'">
                  </tidy-text>
                </div>
              </div>
              <div *ngIf="customSubscription && !changedCustomSubscription">
                <div style="margin-bottom: 5px; margin-top: -5px">
                  <div class="price-header-mobile">
                    ${{customSubscription?.plan?.amount * (numberOfUnits) / 100}}
                    <tidy-text style="margin-left: -5px" [body]="form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month'">
                    </tidy-text>
                  </div>
                </div>
                <div>
                  <tidy-text
                    [body]="'($' + (customSubscription?.plan?.amount / 100) + ' x ' + numberOfUnits">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'unit' + (numberOfUnits == 1 ? '' : 's') + ')'">
                  </tidy-text>
                </div>
              </div>
            </div>
          </tidy-card>

          <tidy-card *ngIf="customSubscription" [ngClass]="selectedSubscription == customSubscription ? 'light-black-background' : ''">
            <tidy-row>
              <tidy-text
                [header]="'Custom Subscription:'">
              </tidy-text>
              <tidy-text
                [header]="customSubscription?.plan?.description">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'You have a custom subscription. Please reach out to the concierge to make changes to your custom subscription.'">
              </tidy-text>
            </tidy-row>
            <tidy-button
              [text]="changedCustomSubscription ? 'Select' : 'Selected'"
              [action]="selectCustomSubscription.bind(this)"
              class="secondary"
              [disabled]="selectedSubscription == customSubscription">
            </tidy-button>
          </tidy-card>

          <div class="four-column-row" *ngIf="!isMobileResolution">
            <tidy-card [ngClass]="selectedSubscription == 'Free' ? 'light-black-background' : ''">
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="'Free'">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [actionBody]="'Great for people with 1 property/unit.'"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider></tidy-divider>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="'$0'">
                </tidy-text>
                <tidy-text
                  [body]="' /unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="'<b>Free plan features:</b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Manage unlimited pros/jobs'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Amazing operations software'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Book cleaning/maintenance anywhere'">
                </tidy-text>
              </tidy-row>
              <div style="height:75px"></div>
              <tidy-button
                style="width: calc(100% - 30px); bottom: 15px; position: absolute"
                [text]="currentSubscription == 'Free' ? 'Current' : (selectedSubscription == 'Free' ? 'Selected' : 'Select')"
                [action]="selectSubscription.bind(this, 'Free')"
                [disabled]="true"
                class="secondary"
                [disabled]="currentSubscription == 'Free'">
              </tidy-button>
            </tidy-card>
            <tidy-card [ngClass]="selectedSubscription == 'TIDY+' ? 'light-black-background' : ''">
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="'TIDY+'">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [actionBody]="'Great for long-term rental managers.'"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider></tidy-divider>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="cost['TIDY+'][form?.value?.yearlyMonthly] | tcurrency">
                </tidy-text>
                <tidy-text
                  [body]="' /unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="'<b>Everything in Free, plus:</b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'More users and properties'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Save 20% on in-network jobs'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Basic integrations & automations'">
                </tidy-text>
              </tidy-row>
              <div style="height:75px"></div>
              <tidy-button
                style="width: calc(100% - 30px); bottom: 15px; position: absolute"
                [text]="currentSubscription == 'TIDY+' ? 'Current' : (selectedSubscription == 'TIDY+' ? 'Selected' : 'Select')"
                [action]="selectSubscription.bind(this, 'TIDY+')"
                class="secondary"
                [disabled]="currentSubscription == 'TIDY+'">
              </tidy-button>
            </tidy-card>
            <tidy-card [ngClass]="selectedSubscription == 'Standard' ? 'light-black-background' : ''">
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="'Standard'">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [actionBody]="'Great for short-term rental (STR) managers.'"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider></tidy-divider>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="cost['Standard'][form?.value?.yearlyMonthly] | tcurrency">
                </tidy-text>
                <tidy-text
                  [body]="' /unit' + (form?.value?.yearlyMonthly == 'yearly' ? '/year' : '/month')">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="'<b>Everything in TIDY+, plus:</b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'All software features'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Powerful automations'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Limited use of our human concierge'">
                </tidy-text>
              </tidy-row>
              <div style="height:75px"></div>
              <tidy-button
                style="width: calc(100% - 30px); bottom: 15px; position: absolute"
                [text]="currentSubscription == 'Standard' ? 'Current' : (selectedSubscription == 'Standard' ? 'Selected' : 'Select')"
                [action]="selectSubscription.bind(this, 'Standard')"
                class="secondary"
                [disabled]="currentSubscription == 'Standard'">
              </tidy-button>
            </tidy-card>
            <tidy-card [ngClass]="selectedSubscription == 'Advanced' ? 'light-black-background' : ''">
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="'Advanced'">
                </tidy-text>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [actionBody]="'For the most advanced property managers.'"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider></tidy-divider>
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  [title]="cost['Advanced'][form?.value?.yearlyMonthly] | tcurrency">
                </tidy-text>
                <tidy-text
                  [body]="' /unit/month'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="'<b>Everything in Standard, plus:</b>'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Unlimited automations'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'Unlimited human concierge'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-middle">
                <tidy-image
                  [src]="'assets/img/checkmark-circle-green.svg'">
                </tidy-image>
                <tidy-text
                  [body]="'24/7/365 emergency job requests'">
                </tidy-text>
              </tidy-row>
              <div style="height:75px"></div>
              <tidy-button
                style="width: calc(100% - 30px); bottom: 15px; position: absolute"
                [text]="currentSubscription == 'Advanced' ? 'Current' : ('Learn More')"
                [action]="goToLearnMoreAdvanced.bind(this, 'Advanced')"
                class="secondary"
                [disabled]="currentSubscription == 'Advanced'">
              </tidy-button>
            </tidy-card>
          </div>

          <tidy-row [align]="'center'" class="extra-top-padding extra-bottom-padding">
            <tidy-text
              [actionBody]="'Learn more about each plan'"
              class="link"
              (action)="goToLearnMorePlans()">
            </tidy-text>
          </tidy-row>

          <tidy-button
            [disabled]="selectedSubscription == currentSubscription && currentCostPerUnit == selectedCostPerUnit"
            [text]="'Change Subscription Now'"
            [action]="save.bind(this)"
            class="primary">
          </tidy-button>

       </div>
      </ng-container>
    </form>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<tidy-row *ngIf="icon || helper || linkText || label">
  <div [ngClass]="{'highlight' : highlightLabel}">
    <tidy-image
      *ngIf="icon"
      [src]="icon"
      class="text-top-align">
    </tidy-image>
    <tidy-text
      [body]="label">
    </tidy-text>
  </div>
  <ng-container *ngIf="helper">
    <tidy-text
      [helper]="helper">
    </tidy-text>
  </ng-container>
  <ng-container *ngIf="linkText">
    <tidy-text
      [body]="' '">
    </tidy-text>
    <tidy-text
      [body]="linkText"
      class="link"
      (action)="linkAction.emit()">
    </tidy-text>
  </ng-container>
</tidy-row>

<mat-radio-group [value]="value" [class.invalid-field]="!form?.valid" [class.chunk-elements]="!!chunkSize">
  <div *ngFor="let itemGroup of itemsInternal; let i = index;" class="group">
    <mat-radio-button
      class="radio-button"
      *ngFor="let item of itemGroup; let i = index"
      [value]="item?.value"
      (change)="onChange(item)"
    >
      {{item?.viewValue | translate}}
    </mat-radio-button>
  </div>
</mat-radio-group>

<tidy-row *ngIf="!showAllItems && hideItemValues?.length" class="field-top-padding">
  <tidy-text
    [body]="'More'"
    (action)="expandOptions()"
    class="link more-text"
  ></tidy-text>
</tidy-row>

<tidy-error-message *ngIf="selectError"
  [text]="selectMessage">
</tidy-error-message>

<tidy-error-message *ngIf="errorMessage && submitted && !form.valid"
  [text]="errorMessage">
</tidy-error-message>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { roomTypeSelect } from 'src/shared/constants/roomTypeSelect';
import { floorSelect } from 'src/shared/constants/floorSelect';

@Component({
  templateUrl: 'add-room.html'
})

export class ToDosAddRoomPage implements OnInit {

  errorMessage: string;
  estimatedTimes: any;
  form: UntypedFormGroup;
  floorSelectItems: any;
  list: any;
  loaded: boolean;
  isRightSideContent: boolean;
  relateItems: any;
  rooms: any;
  roomTypeSelectItems: any;
  submitted: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      floor: [1, Validators.required],
      category: ['bedroom', Validators.required],
      relation: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Add Card');
    this.floorSelectItems = floorSelect;
    this.roomTypeSelectItems = roomTypeSelect;
    this.relateItems = this.getRelateItems();
    this.form.patchValue({relation: 'yes'});
    this.list = this.navCtrl.getParam('list') || dialogParams.list;
    this.loaded = true;
  }

  getRelateItems() {
    return [
      {
        value: 'yes',
        viewValue: 'Yes'
      },
      {
        value: 'no',
        viewValue: 'No'
      }
    ]
  }

  async addRoom() {
    try {
      this.submitted = true;
      if (!this.form.valid) {
        return;
      }
      if (this.form.value.relation == 'yes') {
        const data = {
          name: this.form.value.name,
          floor: this.form.value.floor,
          category: this.form.value.category
        }
        await this.toDos.addRoom(data);
      } else {
        const postPayload = {
          name: this.form.value.name
        }
        const group = await this.toDos.addGroup(postPayload);
        const payload = {
          id: group.id,
          address_task_list_id: this.list.id
        }
        await this.toDos.updateGroup(group.id, payload);
      }
      await this.storage.save('didMakeToDoUpdates', true);
      this.rightSidePanelService.navigateTo('to-dos');
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

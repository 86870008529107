import { Events } from 'src/providers/events/events';
import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { UntypedFormBuilder,UntypedFormGroup, Validators } from "@angular/forms";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from "src/providers/genome/genome";
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';
import { Logger } from "src/providers/logger";
import { OpenAIService } from "src/providers/open-ai/open-ai";
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from "src/shared/components/loading/loading";
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Subscription } from 'rxjs';
import { RecurringTaskComponent } from 'src/pages/tasks/recurring-tasks/recurring-task/recurring-task';
import { ModalController } from '@ionic/angular';
import { MaintenancePlans } from 'src/providers/maintenance-plans/maintenance-plans';
import { Client } from 'src/providers/client/client';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  selector: 'recurring-tasks',
  templateUrl: "recurring-tasks.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["recurring-tasks.scss"]
})
export class RecurringTasksPage implements OnInit {

  advice: any;
  adviceLoading: boolean;
  loaded: boolean;
  errorMessage: string;
  addressId: number | string;
  didSelectRelation: boolean;
  form: UntypedFormGroup;
  hasItems: boolean;
  helperMessage: string = "";
  mapRequests: any;
  map: any;
  objectItems: any;
  relateItems: any;
  rooms: any;
  skippedMapping: boolean;
  upcomingMaintenances: any;
  addressResponse: any;
  selectedFilters: any;
  maintenancePlans: any;
  filteredMaintenancePlans: any;
  panelClosedSubscription: Subscription;

  constructor(
    private genome: Genome,
    private genomeMaps: GenomeMaps,
    private fb: UntypedFormBuilder,
    private tidyAi: OpenAIService,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private toDos: ToDos,
    private events: Events,
    public windowService: WindowService,
    private storage: TidyStorage,
    private modalCtrl: ModalController,
    private rightSidePanelService: RightSidePanelService,
    private maintenance: MaintenancePlans,
    private client: Client
  ) {
    this.form = this.fb.group({
      message: ["", Validators.required],
      roomObject: [""]
    });
  }

  async ngOnInit() {
    this.addressResponse = await this.navCtrl.getParam('addressResponse');
    this.loadMaintenancePlans();
    this.loadAddresses();
  }

  loadAddresses() {
    this.client.getAddresses().then((response) => {
      this.addressResponse = response;
    }).catch((err) => {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    })
  }

  getRelateItems() {
    return [
      {
        value: 'yes',
        viewValue: 'Yes'
      },
      {
        value: 'no',
        viewValue: 'No'
      }
    ]
  }

  selectRelation(selection) {
    if (selection.value == 'yes') {
      this.didSelectRelation = true;
    } else {
      this.didSelectRelation = false;
    }
  }

  checkIfHasItems(rooms) {
    let hasItems = false;
    this.objectItems = []
    rooms.map((room) => {
      if (room.room_objects.length) {
        this.addObjectItems(room.room_objects);
        hasItems = true;
      }
    });
    return hasItems;
  }

  addObjectItems(roomObjects) {
    roomObjects.map((object) => {
      this.objectItems.push(({
        value: object.id,
        viewValue: object.name
      }));
    });
  }

  async askAdvice() {
    if (!this.form.value.message) {
      return;
    } else {
      this.adviceLoading = true;
      try {
        const payload: any = {
          context_key: "advice",
          message: this.form.value.message,
          context_data: {
            address_id: this.addressId
          }
        };
        if (this.form.value.roomObject) {
          payload['room_object_id'] = this.form.value.roomObject;
        }
        this.advice = await this.tidyAi.tidyAI(payload);
      } catch (err) {
        this.logger.error(err);
        this.errorMessage =
          err.error && err.error.message ? err.error.message : err.message;
      }
      this.adviceLoading = false;
    }
  }

  @Loading("", true)
  async beginMapping() {
    const address = await this.genome.getAddress(this.addressId);
    const params = {
      address: address,
      floors: this.getFloors(this.toDos.buildRoomIcons(this.rooms)),
      addressName: this.buildAddressName(address)
    };
    this.navCtrl.navigateForward(`map-introduction`, params);
  }

  getFloors(rooms) {
    const floors = [];
    rooms.forEach(room => {
      floors[room.floor] = floors[room.floor] ?? [];
      floors[room.floor].push(room);
    });
    return floors;
  }

  buildAddressName(address) {
    return address?.address_name || (address.address1 + ' ' + (address.address2 !== null ? (address.address2 + ' ') : '') + (address.city !== null ? (address.city + ' ') : '') + (address.zip !== null ? address.zip : ''));
  }

  skipMapping() {
    this.skippedMapping = true;
    localStorage.setItem('skippedMapping', 'true');
  }

  goToAddressPage() {
    this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
  }

  goToConciergePage() {
    const params = {
      title: 'Other Question',
      type: 'support.other',
      question: this.form.value.message
    }
    this.navCtrl.navigateForward('contact-concierge', params);
  }

  onFilterChanges(event) {
    this.selectedFilters = event.selectedFilters;
    this.reloadMaintenances();
  }

  onSearchChanges(event) {
    this.filteredMaintenancePlans = this.maintenancePlans.filter((maintenance) => {
      return maintenance.name.toLowerCase().includes(event.search.toLowerCase());
    });
  }

  openEditMaintenance(maintenance) {
    this.openMaintenanceModal(maintenance);
  }

  async loadMaintenancePlans(hasFilterChanged = false) {
    try {
      this.loaded = false;
      const cachedRecurringTasks = this.navCtrl.getParam('recurringTasks');
      this.maintenancePlans = !hasFilterChanged && cachedRecurringTasks ? cachedRecurringTasks : await this.maintenance.getMaintenancePlans(this.selectedFilters);
      this.filteredMaintenancePlans = this.maintenancePlans;
      this.loaded = true;
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  reloadMaintenances() {
    this.loadMaintenancePlans(true);
  }

  async openMaintenanceModal(maintenance = null): Promise<void> {
    const params = {
      maintenance: maintenance
    }
    this.storage.save('dialog-right-side-open', true);
    this.storage.save('dialog-params', params);
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.openRightSidePanel(RecurringTaskComponent);
      this.rightSidePanelService.setDialogPageTitle(maintenance ? 'Edit Recurring Task' : 'Add Recurring Task');
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(() => {
        this.loadMaintenancePlans(true);
      });
    } else {
      this.navCtrl.navigateForward('recurring-task')
    }
  }

  async deleteMaintenancePlan(maintenance) {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Maintenance Plan?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete ',
      confirmAction: this.confirmDeleteMaintenancePlan.bind(this),
      confirmActionParams: maintenance
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmDeleteMaintenancePlan(maintenance) {
    try {
      await this.maintenance.deleteMaintenancePlan(maintenance.id);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }
}

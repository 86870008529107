import { Injectable } from '@angular/core';
import { Aws } from '../aws/aws';
import { HttpClientCustom } from '../custom/http-client';

import { Client } from 'src/providers/client/client';

import { AttachmentModel } from 'src/models/concierge.model';
import { generateUuid } from '../tidy-session/session-uuid';
import { Util } from "src/shared/util/util";
import { CameraProvider } from 'src/shared/providers/camera/camera';

@Injectable()
export class Concierge {

  constructor(
    private aws: Aws,
    private client: Client,
    private httpClient: HttpClientCustom,
    private util: Util,
    private cameraProvider: CameraProvider
  ) {}

  async getConciergeItems(filters = ''): Promise<{ body: any[], totalRecords: string }> {
    const url = `api/v1/customer/concierge-items?visible=true${filters}&not_created_by=Genome`;
    return this.httpClient.getFullRequest(url)
      .then(response => {
        return {
          body: response.body.filter(item => item.created_by !== 'Genome'),
          totalRecords: response.headers.get('X-Total-Records')
        };
      });
  }

  getConciergeItemById(conciergeItemId) {
    const url = `api/v1/customer/concierge-items/${conciergeItemId}`;
    return this.httpClient.get(url);
  }

  addConciergeItem(data) {
    const url = 'api/v1/customer/concierge-items';
    return this.httpClient.post(url, data);
  }

  addManyConciergeItems(items: {
    type: string,
    data: {
      subject: string,
      body: string,
      emails: string[],
      attachments: string[],
      scheduled?: string,
    },
    metadata?: any
  }[], taskId: string = null) {
    const url = 'api/v1/customer/concierge-items/create-many';
    const data = {
      concierge_items: items,
      ...(taskId && { issue_report_id: taskId })
    }
    return this.httpClient.post(url, data);
  }

  openDispute(data) {
    const url = 'api/v1/customer/cleaning-disputes';
    return this.httpClient.post(url, data);
  }

  replyDisputeCounter(conciergeItemId, data){
    const url = `/api/v1/customer/concierge-items/${conciergeItemId}/action`;
    return this.httpClient.post(url, data);
  }

  addConciergeTicketComment(data) {
    const url = 'api/v1/customer/support/ticket-comments';
    return this.httpClient.post(url, data);
  }

  submitGenomeInformationCollection(id, data) {
    const url = `/api/v1/customer/concierge-items/${id}/action`;
    return this.httpClient.post(url, data);
  }

  submitConciergeAction(itemId, data) {
    const url = `api/v1/customer/concierge-items/${itemId}/action`;
    return this.httpClient.post(url, data);
  }

  getStatusIcon(status) {
    switch (status) {
      case 'waiting_on_tidy':
        return 'assets/img/yellow-pending.svg';
      case 'waiting_on_customer':
        return 'assets/img/yellow-alert.svg';
      case 'completed':
        return 'assets/img/checkmark.svg';
      case 'closed':
        return 'assets/img/checkmark.svg';
    }
  }

  getDisputeIcon(type) {
    switch (type) {
      case 'dispute.customer_dispute_initiated':
        return 'assets/img/yellow-pending.svg';
      case 'dispute.customer_dispute_counter_answer':
        return 'assets/img/yellow-alert.svg';
      case 'dispute.customer_dispute_won':
        return 'assets/img/checkmark.svg';
      case 'dispute.customer_dispute_lost':
        return 'assets/img/checkmark.svg';
    }
  }

  getReplyTime(type) {
    switch (type) {
      case 'support.other':
        return '2 business days';
      case 'support.how_to':
        return '2 business hours';
      case 'support.billing':
        return '4 business hours';
      case 'support.job_issue':
        return '2 business days';
      case 'support.developer':
        return '2 business hours';
      case 'support.integration':
        return '2 business hours';
      default:
        return '2 business hours';
    }
  }

  async setClientInfo() {
    const alreadySetClientInfo = localStorage.getItem('clientEmail') !== null;
    if (!alreadySetClientInfo) {
      const customerId = localStorage.getItem('customer_id');
      const clientInfo = await this.client.getCustomerInfo(customerId);
      localStorage.setItem('clientEmail', clientInfo.email);
      localStorage.setItem('isRentalClient', clientInfo.account_type === 'rental' ? 'true' : 'false');
      localStorage.setItem('isDeveloperClient', clientInfo.developer ? 'true' : 'false');
    }
  }

  async addAttachment(): Promise<AttachmentModel> {
    const uuid = generateUuid();
    const file = await this.cameraProvider.getImg();
    const fileKey = `concierge-attachments/${uuid}/${file.filename}`;
    if (!file?.format) {
      const dataSource = file?.dataUrl !== undefined ? file?.dataUrl : file?.base64String;
      file.format = dataSource.split(',')[0].split('/')[1];
    }
    if (file.format.includes('jpeg') || file.format.includes('png') || file.format.includes('svg')) {
      const loading = await this.util.showLoading();
      const response = await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      loading.dismiss();
      return {
        filename: file.filename,
        fileKey,
        url: response.Location
      };
    }
    this.util.showError('File type not supported');
    throw new Error('File type not supported');
  }

  getRules() {
    const url = 'api/v1/customer/concierge-rules';
    return this.httpClient.get(url);
  }

  addRule(data) {
    const url = 'api/v1/customer/concierge-rules';
    return this.httpClient.post(url, data);
  }

  updateRule(ruleId, data) {
    const url = `api/v1/customer/concierge-rules/${ruleId}`;
    return this.httpClient.put(url, data);
  }

  deleteRule(ruleId) {
    const url = `api/v1/customer/concierge-rules/${ruleId}`;
    return this.httpClient.delete(url);
  }

  getSuggestions() {
    const url = `api/v1/customer/concierge-suggestions?not_dismissed=true`;
    return this.httpClient.get(url);
  }

  dismissSuggestion(suggestionId) {
    const url = `api/v1/customer/concierge-suggestions/${suggestionId}/dismiss`;
    return this.httpClient.post(url, {});
  }
}

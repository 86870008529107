import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-divider',
  encapsulation: ViewEncapsulation.None,

  styleUrls: ['./divider.component.scss'],
  template: `
    <div>
    </div>
  `
})

export  class DividerComponent {

}

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { IMaskModule } from 'angular-imask';

import { AlertComponent } from './components/alert/alert.component';
import { AutocompleteComponent } from './components/form/auto-complete/auto-complete.component';
import { BarComponent } from './components/bar/bar.component';
import { BestTimesCalendarComponent } from './components/best-times-calendar/best-times-calendar.component';
import { BookableLegendComponent } from './components/bookable-legend/bookable-legend.component';
import { ButtonComponent } from './components/button/button.component';
import { CardButtonComponent } from './components/card-button/card-button.component';
import { CardComponent } from './components/card/card.component';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { CheckboxButtonComponent } from './components/checkbox-button/checkbox-button.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { DatePickerComponent } from './components/form/datepicker/datepicker.component';
import { DataRangePickerComponent } from './components/form/daterange-picker/daterange-picker.component';
import { DividerComponent } from './components/divider/divider.component';
import { DragDropListComponent } from './components/drag-drop/drag-drop-list.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FabButtonComponent } from './components/fab/fab-button/fab-button.component';
import { FabComponent } from './components/fab/fab.component';
import { FabListComponent } from './components/fab/fab-list/fab-list.component';
import { FieldPairComponent } from './components/form/field-pair/field-pair.component';
import { FilterComponent } from './components/form/filter/filter.component';
import { GridComponent } from './components/grid/grid.component';
import { HeaderToggleComponent } from './components/header-toggle/header-toggle.component';
import { IconComponent } from './components/icon/icon.component';
import { ImageComponent } from './components/image/image.component';
import { InitialsIconComponent } from './components/initials-icon/initials-icon.component';
import { InputComponent } from './components/form/input/input.component';
import { InputInternationalPhoneComponent } from './components/form/input-international-phone/input-international-phone.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { MarkerComponent } from './components/maps/marker/marker.component';
import { ModalComponent } from './components/modal-component/modal.component';
import { MatRipple } from '@angular/material/core';
import { NgCalendarModule } from 'ionic2-calendar';
import { PhotoNoteComponent } from './components/photo-note/photo-note.component';
import { RadioButtonComponent } from './components/form/radio-button/radio-button.component';
import { RoundButtonComponent } from './components/round-button/round-button.component';
import { RoundButtonRowComponent } from './components/round-button-row/round-button-row';
import { RowComponent } from './components/row/row.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { SelectComponent } from './components/form/select/select.component';
import { ScrollWrapperComponent } from './components/scroll-wrapper/scroll-wrapper.component';
import { TableComponent } from './components/tidy-table/table.component';
import { TextComponent } from './components/text/text.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { TextareaComponent } from './components/form/textarea/textarea.component';
import { TidyMaterialModule } from './material-module';
import { ToggleComponent } from './components/form/toggle/toggle.component';
import { ThreeColumnRowComponent } from './components/three-column-row/three-column-row.component';
import { TwoColumnRowComponent } from './components/two-column-row/two-column-row.component';
import { TidyRipple } from './directives/tidy-ripple';
import { VideoComponent } from './components/video/video.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { InViewportModule } from 'ng-in-viewport';
import { ImageViewerModule } from './components/image-viewer/module/image-viewer.module'
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SearchInputComponent } from 'src/tidy-ui-components/components/search-input/search-input';

import { BlockCopyPasteDirective } from './directives/block-copy-paste-field';
import { DragDropItemDirective } from './directives/tidy-drag-drop-item';
import { TidyCarrouselDirective } from './directives/tidy-carrousel';
import { TidySizeDirective } from './directives/tidy-size';
import { LoaderComponent } from './components/loader/loader.component';
import { TranslationPipe } from './pipes/translation.pipe';

import { ParseTextPipe } from './pipes/text.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const files = [
  AlertComponent,
  AutocompleteComponent,
  BarComponent,
  BestTimesCalendarComponent,
  BookableLegendComponent,
  ButtonComponent,
  CardButtonComponent,
  CardComponent,
  CarrouselComponent,
  CheckboxButtonComponent,
  CheckboxComponent,
  DatePickerComponent,
  DataRangePickerComponent,
  DividerComponent,
  DragDropListComponent,
  ErrorMessageComponent,
  FabButtonComponent,
  FabComponent,
  FabListComponent,
  FieldPairComponent,
  FilterComponent,
  GridComponent,
  HeaderToggleComponent,
  IconComponent,
  ImageComponent,
  InitialsIconComponent,
  InputComponent,
  InputInternationalPhoneComponent,
  LoadingBarComponent,
  LoaderComponent,
  MarkerComponent,
  ModalComponent,
  PhotoNoteComponent,
  RadioButtonComponent,
  RoundButtonComponent,
  RoundButtonRowComponent,
  RowComponent,
  StepperComponent,
  SelectComponent,
  ScrollWrapperComponent,
  TableComponent,
  TextComponent,
  TranslationsComponent,
  TextareaComponent,
  ToggleComponent,
  ThreeColumnRowComponent,
  TwoColumnRowComponent,
  VideoComponent,
  WrapperComponent,
  FileUploadComponent,
  SearchInputComponent
];

@NgModule({
  imports: [
    IMaskModule,
    CommonModule,
    FormsModule,
    IonicModule,
    NgCalendarModule,
    ReactiveFormsModule,
    TidyMaterialModule,
    InViewportModule,
    NgxMatSelectSearchModule,
    ImageViewerModule
  ],
  declarations: [
    ...files,
    BlockCopyPasteDirective,
    DragDropItemDirective,
    TidyCarrouselDirective,
    TidyRipple,
    TidySizeDirective,
    ParseTextPipe,
    TranslationPipe
  ],
  exports: [
    ...files,
    BlockCopyPasteDirective,
    DragDropItemDirective,
    TidyCarrouselDirective,
    TidyRipple,
    TidySizeDirective,
    ImageViewerModule
  ],
  providers: [
    MatRipple
  ]
})
export class TidyUiComponentsModule {}

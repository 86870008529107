import { Injectable } from '@angular/core';

import { HttpClientCustom } from 'src/providers/custom/http-client';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { Aws } from 'src/providers/aws/aws';

import { generateUuid } from 'src/providers/tidy-session/session-uuid';

import { AttachmentModel } from 'src/models/concierge.model';
import { TasksFilter } from 'src/pages/tasks/tasks-filter/tasks-filter';
import { TidyStorage } from "src/shared/providers/tidy-storage";

@Injectable()
export class ReportIssue {

  constructor(
    private aws: Aws,
    private filePicker: FilePicker,
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  getIssueReportsWithActivities(issueId: number) {
    const url = `api/v1/customer/issue-reports/${issueId}?includes=issue_report_activities`;
    return this.httpClient.get(url);
  }

  createIssueReportActivityComment(payload: any) {
    const url = `api/v1/customer/issue-report-activities`;
    return this.httpClient.post(url, payload);
  }

  deleteIssueReportActivity(activityId: number) {
    const url = `api/v1/customer/issue-report-activities/${activityId}`;
    return this.httpClient.delete(url);
  }

  getEligibleTypes() {
    const url = `api/v1/customer/issue-report-types`;
    return this.httpClient.get(url);
  }

  getIssueReports() {
    const url = `api/v1/customer/issue-reports?filters[include_archived]=${false}&includes=members,bookings,maintenance_plans,concierge_items,room_objects`;
    return this.httpClient.get(url);
  }

  getArchivedIssueReports() {
    const url = `api/v1/customer/issue-reports?filters[include_archived]=${true}&includes=members,bookings,maintenance_plans,concierge_items,room_objects`;
    return this.httpClient.get(url);
  }

  async getIssueReportTypes() {
    let reportTypes = await this.storage.retrieve('reportTypes');
    if (reportTypes) {
      return reportTypes;
    }
    const url = `api/v1/customer/issue-report-types`;
    reportTypes = await this.httpClient.get(url);
    await this.storage.save('reportTypes', reportTypes);
    return reportTypes;
  }

  getIssueReportsByAddress(addressId) {
    const url = `api/v1/customer/issue-reports?filters[address_ids]=${addressId}`;
    return this.httpClient.get(url);
  }

  getIssueReport(issueReportId) {
    const url = `api/v1/customer/issue-reports/${issueReportId}`;
    return this.httpClient.get(url);
  }

  reportIssue(data) {
    const url = `api/v1/customer/issue-reports`;
    return this.httpClient.post(url, data);
  }

  updateIssueReport(issueReportId, data) {
    const url = `api/v1/customer/issue-reports/${issueReportId}`;
    return this.httpClient.put(url, {params: data});
  }

  resolveReportedIssue(issueReportId) {
    const url = `api/v1/customer/issue-reports/${issueReportId}/resolve`;
    return this.httpClient.post(url, {});
  }

  deleteReportedIssue(issueReportId) {
    const url = `api/v1/customer/issue-reports/${issueReportId}`;
    return this.httpClient.delete(url);
  }

  async addAttachment(): Promise<AttachmentModel> {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `issue-reports/${uuid}/${file.filename}`;
    if (file.format.includes('jpeg') || file.format.includes('png')) {
      await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return {
        filename: file.filename,
        fileKey
      };
    } else {
      return {
        filename: '',
        fileKey: ''
      };
    }
  }

  async saveAttachment(issueReportId: string, attachment: AttachmentModel) {
    const url = `api/v1/customer/issue-report-photos`;
    const params = {
      issue_report_id: issueReportId,
      photo_url: attachment.fileKey,
      description: attachment.filename
    };
    return this.httpClient.post(url, params);
  }

  async deleteAttachment(attachmentId: string) {
    const url = `api/v1/customer/issue-report-photos/${attachmentId}`;
    return this.httpClient.delete(url);
  }

  addIssueReportAttachment(payload) {
    const url = 'api/v1/customer/issue-report-attachments';
    return this.httpClient.post(url, payload);
  }

  async deleteIssueReportAttachment(attachmentId: string) {
    const url = `api/v1/customer/issue-report-attachments/${attachmentId}`;
    return this.httpClient.delete(url);
  }

  getJobStatusData(homekeeperJobs, status, job = null) {
    const proName = homekeeperJobs?.[0]?.homekeeper?.name?.split(' ');
    const showTestData = localStorage.getItem('showTestData') == 'true';
    if (status == 'request_waiting_reply' || status == 'substitute_requested') {
      return {
        name: 'requested',
        icon_type: 'image',
        icon: 'assets/icon/searching.svg',
        class: 'wiggle-animation',
        status: status
      }
    } else if (!status) {
      return {
        name: 'requested',
        icon_type: 'image',
        icon: 'assets/img/briefcase-question.svg',
        class: 'wiggle-animation',
        status: status
      }
    } else if (status == 'homekeeper_call_out') {
      return {
        name: 'call out',
        icon_type: 'image',
        icon: 'assets/img/request-failed.svg',
        class: 'grow-shrink-animation',
        status: status
      }
    } else if (status == 'request_failed') {
      return {
        name: 'not accepted',
        icon_type: 'image',
        icon: 'assets/img/request-failed.svg',
        class: 'grow-shrink-animation',
        status: status
      }
    } else if (status == 'request_cancelled' || status == 'homekeeper_cancelled' || status == 'client_cancelled') {
      return {
        name: status == 'homekeeper_cancelled' ? 'client cancelled' : 'cancelled',
        icon_type: 'image',
        icon: 'assets/img/canceled.svg',
        status: status
      }
    } else if (status == 'completed') {
      return {
        name: 'completed',
        icon_type: 'image',
        icon: 'assets/img/complete-checkmark.svg',
        status: status
      }
    } else if (status == 'no_show' || status == 'homekeeper_no_show') {
      return {
        name: 'no show',
        icon_type: 'image',
        icon: 'assets/img/request-failed.svg',
        status: status
      }
    } else if (!proName) {
      return {
        name: 'scheduled',
        icon_type: 'none',
        status: status
      }
    } else if (status == 'on_the_way') {
      return {
        name: 'on the way',
        icon_type: 'initials',
        initials: proName ? proName[0][0] + (proName[proName?.length - 1][0] ? proName[proName?.length - 1][0] : ''): 'BA',
        class: 'pro-initials-on-the-way',
        status: status
      }
    } else if (status == 'arrived_at_home') {
      return {
        name: 'in progress',
        icon_type: 'initials',
        initials: proName ? proName[0][0] + (proName[proName?.length - 1][0] ? proName[proName?.length - 1][0] : ''): 'BA',
        class: 'pro-initials-arrived-at-home',
        status: status
      }
    } else if (status == 'having_issues') {
      return {
        name: 'having issues',
        icon_type: 'initials',
        initials: proName ? proName[0][0] + (proName[proName?.length - 1][0] ? proName[proName?.length - 1][0] : ''): 'BA',
        class: 'pro-initials-having-issues',
        status: status
      }
    } else if (status == 'in_progress') {
      return {
        name: 'in progress',
        icon_type: 'initials',
        initials: proName ? proName[0][0] + (proName[proName?.length - 1][0] ? proName[proName?.length - 1][0] : ''): 'BA',
        class: 'pro-initials-in-progress',
        status: status
      }
    } else if (status == 'checked_in' || status == 'scheduled') {
      return {
        name: 'scheduled',
        icon_type: 'initials',
        initials: proName ? proName[0][0] + (proName[proName?.length - 1][0] ? proName[proName?.length - 1][0] : ''): 'BA',
        class: 'pro-initials-scheduled',
        status: status
      }
    }
    /*if (!showTestData) {
      if (status == 'request_waiting_reply') {
        return {
          name: 'requested',
          icon_type: 'image',
          icon: 'assets/icon/searching.svg',
          class: 'wiggle-animation',
          status: status
        }
      } else if (!status) {
        return {
          name: 'requested',
          icon_type: 'image',
          icon: 'assets/img/briefcase-question.svg',
          class: 'wiggle-animation',
          status: status
        }
      } else if (status == 'homekeeper_call_out') {
        return {
          name: 'call out',
          icon_type: 'image',
          icon: 'assets/img/request-failed.svg',
          class: 'grow-shrink-animation',
          status: status
        }
      } else if (status == 'request_failed') {
        return {
          name: 'not accepted',
          icon_type: 'image',
          icon: 'assets/img/request-failed.svg',
          class: 'grow-shrink-animation',
          status: status
        }
      } else if (status == 'request_cancelled' || status == 'homekeeper_cancelled' || status == 'client_cancelled') {
        return {
          name: status == 'homekeeper_cancelled' ? 'pro cancelled' : 'cancelled',
          icon_type: 'image',
          icon: 'assets/img/canceled.svg',
          status: status
        }
      } else if (status == 'completed') {
        return {
          name: 'completed',
          icon_type: 'image',
          icon: 'assets/img/complete-checkmark.svg',
          status: status
        }
      } else if (!proName) {
        return {
          name: 'scheduled',
          icon_type: 'none',
          status: status
        }
      } else if (status == 'on_the_way') {
        return {
          name: 'in progress',
          icon_type: 'initials',
          initials: proName[0][0] + proName[proName?.length - 1][0],
          class: 'pro-initials-on-the-way',
          status: 'in_progress'
        }
      } else if (status == 'arrived_at_home') {
        return {
          name: 'in progress',
          icon_type: 'initials',
          initials: proName[0][0] + proName[proName?.length - 1][0],
          class: 'pro-initials-arrived-at-home',
          status: 'in_progress'
        }
      } else if (status == 'having_issues') {
        return {
          name: 'having issues',
          icon_type: 'initials',
          initials: proName[0][0] + proName[proName?.length - 1][0],
          class: 'pro-initials-having-issues',
          status: status
        }
      } else if (status == 'in_progress') {
        return {
          name: 'in progress',
          icon_type: 'initials',
          initials: proName[0][0] + proName[proName?.length - 1][0],
          class: 'pro-initials-in-progress',
          status: status
        }
      } else if (status == 'checked_in' || status == 'scheduled') {
        return {
          name: 'scheduled',
          icon_type: 'initials',
          initials: proName[0][0] + proName[proName?.length - 1][0],
          class: 'pro-initials-scheduled',
          status: status
        }
      }
    } else {
      let index = parseInt(localStorage.getItem('taskIndex'));
      index += 1;
      if (index > 8) {
        index = 0;
      }
      localStorage.setItem('taskIndex', index.toString());
      if (index == 0) {
        return {
          icon_type: 'image',
          icon: 'assets/icon/searching.svg',
          class: 'wiggle-animation'
        }
      } else if (index == 1) {
        return {
          icon_type: 'image',
          icon: 'assets/img/request-failed.svg',
          class: 'grow-shrink-animation'
        }
      } else if (index == 2) {
        return {
          icon_type: 'image',
          icon: 'assets/img/canceled.svg',
        }
      } else if (index == 3) {
        return {
          icon_type: 'initials',
          initials: proName ? (proName[0][0] + proName[proName?.length - 1][0]) : 'SE',
          class: 'pro-initials-on-the-way',
        }
      } else if (index == 4) {
        return {
          icon_type: 'initials',
          initials: proName ? (proName[0][0] + proName[proName?.length - 1][0]) : 'SE',
          class: 'pro-initials-arrived-at-home'
        }
      } else if (index == 5) {
        return {
          icon_type: 'initials',
          initials: proName ? (proName[0][0] + proName[proName?.length - 1][0]) : 'SE',
          class: 'pro-initials-having-issues'
        }
      } else if (index == 6) {
        return {
          icon_type: 'initials',
          initials: proName ? (proName[0][0] + proName[proName?.length - 1][0]) : 'SE',
          class: 'pro-initials-in-progress'
        }
      } else if (index == 7) {
        return {
          icon_type: 'initials',
          initials: proName ? (proName[0][0] + proName[proName?.length - 1][0]) : 'SE',
          class: 'pro-initials-scheduled'
        }
      } else if (index == 8) {
        return {
          icon_type: 'image',
          icon: 'assets/img/complete-checkmark.svg'
        }
      }
    }*/
  }

  getActionSuggestions(issueReportId): Promise<{ data: { concierge_action_suggestions: ActionSuggestion[]} }> {
    const url = `api/v1/customer/ai/trigger-feature`;
    const data = {
      feature: 'concierge_action_suggestion',
      data: { issue_report_id: issueReportId }
    };

    return this.httpClient.post(url,  data);
  }
}

interface ActionSuggestion {
  description: string;
  title: string;
  scheduled: string;
  [key: string]: any;
}

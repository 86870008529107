<tidy-card>
  <tidy-card-button (action)="goToAddDirectIntegration()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image [src]="'assets/img/integrate.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Import All Properties'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="
              'If you use a PMS system you can integrate to import all properties.'
            ">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-card>
  <tidy-card-button (action)="goToImportCSV()" class="no-below-stroke">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image [src]="'assets/img/csv.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Import CSV'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text [body]="'Import all properties by importing a CSV.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-row class="extra-bottom-padding">
  <tidy-text [helper]="'or'" class="divider"> </tidy-text>
</tidy-row>

<ng-container *ngFor="let form of forms.controls; let first = first">
  <tidy-card>
    <form [formGroup]="form" novalidate action="#">
      <tidy-row class="extra-bottom-padding" [alignLastItemRight]="!first">
        <tidy-image [src]="'assets/img/home.svg'" class="title-size">
        </tidy-image>
        <tidy-text [header]="'Add Property'"> </tidy-text>
        <tidy-text
          *ngIf="!first"
          class="link red"
          [body]="'Remove'"
          (action)="removeProperty(form)">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-input-international-phone
          formControlName="phone"
          [label]="'Phone'"
          [form]="form.controls.phone"
          [inputMode]="'numeric'"
          [submitted]="submitted"
          [errorMessage]="'Please enter a valid phone.'"
          [icon]="'assets/svg/phone-portrait-outline.svg'"
          [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
        </tidy-input-international-phone>
      </tidy-row>

      <tidy-row>
        <tidy-select
          [icon]="'assets/svg/location-outline.svg'"
          [label]="'Country'"
          [items]="countryList"
          [form]="form.get('countryCode')"
          formControlName="countryCode"
          (optionChange)="changeCountrySelected($event, form)">
        </tidy-select>
      </tidy-row>

      <tidy-row>
        <tidy-auto-complete
          [showGoogleIcon]="true"
          label="Address"
          formControlName="address"
          [options]="autocompleteAddress"
          [icon]="'assets/svg/home-outline.svg'"
          [form]="form.controls.address"
          [errorMessage]="'Please enter a valid address'"
          [submitted]="submitted"
          (keyup)="updateSearch(form)"
          (action)="chooseItem($event, form)">
        </tidy-auto-complete>
      </tidy-row>

      <tidy-row>
        <tidy-input
          formControlName="address2"
          [form]="form.controls.address2"
          label="Unit / Apartment"
          [errorMessage]="'Please enter a valid unit'"
          [submitted]="submitted"
          [icon]="'assets/img/door.svg'">
        </tidy-input>
      </tidy-row>

      <tidy-row>
        <tidy-input
          formControlName="zipcode"
          [customMask]="zipCodeMask"
          [form]="form.controls.zipcode"
          [errorMessage]="'Please enter a valid zipcode'"
          [submitted]="submitted"
          [inputMode]="'numeric'"
          label="Postal Code"
          [icon]="'assets/img/mailbox.svg'">
        </tidy-input>
      </tidy-row>

      <tidy-row>
        <tidy-input
          formControlName="addressNickname"
          [form]="form.controls.addressNickname"
          [label]="'Property Nickname (optional)'"
          [icon]="'assets/svg/information-circle-outline.svg'">
        </tidy-input>
      </tidy-row>

      <ng-container
        *ngIf="
          radioButtonAddress.length > 0 &&
          submitted &&
          (typedOnAddress || !didChooseAddress)
        ">
        <tidy-row>
          <tidy-text [header]="'Please confirm your address:'"> </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-radio-button
            [helper]="'If you do not see your address please retype it above'"
            [items]="radioButtonAddress"
            (optionChange)="chooseItem($event, form); setAddressFormControl($event)">
          </tidy-radio-button>
        </tidy-row>
      </ng-container>
    </form>
  </tidy-card>
</ng-container>

<tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
</tidy-error-message>

<!--<tidy-row [align]="'center'">
  <tidy-text
    [actionBody]="'+ Add Another Property'"
    (action)="addAnotherProperty()"
    class="link">
  </tidy-text>
</tidy-row>-->

<tidy-row class="extra-top-padding">
  <tidy-button
    [text]="'Add Property'"
    class="primary"
    [action]="addProperty.bind(this)">
  </tidy-button>
</tidy-row>

import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "tidyDate",
})
export class TidyDateFormatPipe implements PipeTransform {
  transform(value: string | Date, format: string): string {
    let date: DateTime;
    if (typeof value === "string") {
      date = DateTime.fromISO(value);
    } else if (value instanceof Date) {
      date = DateTime.fromJSDate(value);
    } else {
      throw new Error("Invalid date value");
    }
    return date.toFormat(format);
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pro?.homekeeper?.name"
    [customBack]="customBackPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-row *ngIf="!pro?.is_private" [align]="'center'">
          <tidy-text
            [body]="pro?.availability">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <ng-container *ngIf="!isProLoaded">
            <tidy-row>
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="isProLoaded">
            <tidy-row [alignLastItemRight]="pro?.is_private && (pro?.homekeeper?.state === 'pending' || pro?.homekeeper?.phone)" class="extra-bottom-padding" [ngClass]="!(pro?.homekeeper?.state === 'pending' || pro?.homekeeper?.phone) ? 'vertical-align-center' : ''">
              <tidy-image
                [src]="'assets/img/account.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="pro?.homekeeper?.name"
                [translate]="false">
              </tidy-text>
              <tidy-text
                *ngIf="pro?.homekeeper?.state === 'pending'"
                style="margin-left: 5px"
                [body]="'Edit'"
                (action)="goToEditContactInfo()"
                class="link">
              </tidy-text>
              <tidy-text
                *ngIf="pro?.is_private"
                [body]="pro?.homekeeper?.phone | telephone"
                (action)="contactPro()"
                class="link">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="!isProsDataLoaded">
            <tidy-row style="padding-top: 1rem;">
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="isProsDataLoaded">
            <tidy-card-button (action)="goToBookJob()" *ngIf="!isCurrentProBlocked">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/book.svg'"
                  [class]="body-size">
                </tidy-image>
                <tidy-text
                  body="Book Job">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="blockProConfirmation()" *ngIf="!isCurrentProBlocked">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/block.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  body="Block Pro">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <form [formGroup]="maxJobsForm" novalidate action="#">
              <tidy-row style="padding-top: 1rem;">
                <tidy-checkbox
                  [items]="[{value: true, viewValue: 'Limit Max Jobs Per Day'}]"
                  formControlName="limitMaxJobs"
                  [form]="maxJobsForm.controls.limitMaxJobs"
                  (checkedChange)="limitMaxJobs()">
                </tidy-checkbox>
              </tidy-row>
              <tidy-row *ngIf="maxJobsForm.controls.limitMaxJobs.value">
                <tidy-input
                  formControlName="maxJobs"
                  [form]="maxJobsForm.controls.maxJobs"
                  [submitted]="submitted"
                  [inputMode]="'numeric'"
                  [type]="'number'"
                  label="Max Jobs Per Day">
                  </tidy-input>
              </tidy-row>
            </form>
            <tidy-card-button (action)="approveAndUnblockProConfirmation()" *ngIf="isCurrentProBlocked">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/checkmark.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  body="Approve & Unblock">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
          <ng-container *ngIf="!isMessagesLoaded">
            <tidy-row style="padding-top: 2rem;">
              <ion-skeleton-text animated style="width: 100%; height: 65px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row style="padding-top: 1rem;">
              <ion-skeleton-text animated style="width: 120px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 200px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row style="padding-top: 0.5rem;">
              <ion-skeleton-text animated style="width: 120px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 200px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
            <tidy-row style="padding-top: 0.5rem;">
              <ion-skeleton-text animated style="width: 120px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 200px; height: 25px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="isMessagesLoaded">
            <tidy-row class="field-top-padding extra-bottom-padding" style="padding-top: 2rem;">
              <form [formGroup]="form" novalidate action="#">
                <tidy-textarea
                  [label]="'Send Message'"
                  formControlName="message"
                  [form]="form.controls.message"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter a message to send.'"
                  [rightIconAction]="sendMessage.bind(this)"
                  [leftIconAction]="sendAttachment.bind(this)">
                </tidy-textarea>
              </form>
            </tidy-row>
            <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
              <tidy-text
                [helper]="'No Messages'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let message of messages; let last = last">
              <message
                [message]="message"
                [last]="last"
                [proId]="proId">
              </message>
            </ng-container>
            <tidy-row *ngIf="messages?.length > 0" [align]="'center'" style="padding-top: 2rem;">
              <tidy-text
                [body]="'View All'"
                class="link"
                (action)="goToAllMessagesPage()">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-card *ngIf="pro?.is_private">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/dollar.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Balance'">
            </tidy-text>
            <ng-container *ngIf="!isBillingsDataLoaded">
              <ion-skeleton-text animated style="height: 15px; border-radius: 6px; margin-left: 170px;"></ion-skeleton-text>
            </ng-container>
            <ng-container *ngIf="isBillingsDataLoaded">
              <tidy-text
              [body]="creditBalance?.credit_balance | tcurrency">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <ng-container *ngIf="!isBillingsDataLoaded">
          <tidy-row style="padding-top: 1rem;">
            <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
        </ng-container>
          <ng-container *ngIf="isBillingsDataLoaded">
            <tidy-card-button (action)="goToSendPayment()" *ngIf="pro?.is_private">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/salary.svg'"
                  [class]="body-size">
                </tidy-image>
                <tidy-text
                  body="Send Payment">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="goToProBillingHistoryPage()" *ngIf="pro?.is_private && userRole !== 'member'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/more-history.svg'"
                  [class]="body-size">
                </tidy-image>
                <tidy-text
                  body="Billing History">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
        </tidy-card>

        <tidy-card *ngIf="pro?.is_private">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/invoice.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Bills'">
            </tidy-text>
            <ng-container *ngIf="!isBillingsDataLoaded">
              <ion-skeleton-text animated style="height: 15px; border-radius: 6px; margin-left: 150px;"></ion-skeleton-text>
            </ng-container>
            <ng-container *ngIf="isBillingsDataLoaded">
              <div>
                <tidy-text
                  [body]="'Autopay Settings'"
                  class="link"
                  (action)="goToProSettings()">
                </tidy-text>
              </div>
            </ng-container>
          </tidy-row>
          <ng-container *ngIf="!isBillingsDataLoaded">
            <tidy-row style="padding-top: 1rem;">
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="isBillingsDataLoaded">
            <ng-container *ngFor="let invoice of pendingInvoices">
              <tidy-card-button (action)="goToInvoicePage(invoice)">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/alert.svg'"
                    [class]="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="(invoice?.amount | tcurrency)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'total for'">
                  </tidy-text><span>&nbsp;</span>
                    <tidy-text
                    [body]="(invoice?.items[0]?.service_details?.date | parseDate: 'M/D')">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'job'">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
            </ng-container>
            <tidy-card-button (action)="goToBillsPage()" *ngIf="pendingInvoices?.length >= 10">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  [class]="body-size">
                </tidy-image>
                <tidy-text
                  body="View More">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-row *ngIf="pendingInvoices?.length == 0">
              <tidy-text
                [body]="pendingInvoices?.length">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Pending'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-card *ngIf="pro?.is_private">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/auto-assign.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Auto Assign'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'You can auto assign this pro to any job opportunity instead of asking them to accept. Turn on Auto Assign in the job request workflows page'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'here'"
              class="link"
              (action)="goToJobRequestWorkflows()">
            </tidy-text>
            <tidy-text
              [body]="'.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/paper-plane.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Proposals'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="!isBillingsDataLoaded">
            <tidy-row style="padding-top: 1rem;">
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 100%; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="isBillingsDataLoaded">
            <ng-container *ngFor="let proposal of pendingProposals">
              <tidy-card-button (action)="goToProposalPage(proposal)">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/alert.svg'"
                    [class]="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="proposal?.proposable?.team_service?.name | titlecase">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
            </ng-container>
            <tidy-row *ngIf="pendingProposals?.length == 0">
              <tidy-text
                [body]="pendingProposals?.length">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Pending'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-row>
          <tidy-text
            [header]="'Past Jobs'">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="isPastJobsLoaded">
          <tidy-alert *ngIf="pastJobs?.length === 0">
            <tidy-row>
              <tidy-text
                [body]="'No Past jobs'">
              </tidy-text>
            </tidy-row>
          </tidy-alert>

          <tidy-card *ngFor="let job of pastJobs">
            <tidy-card-button (action)="goToPastJob(job?.cleaning_id, job?.is_private)">
              <tidy-row>
                <tidy-text
                  [body]="job?.service">
                </tidy-text>
                <tidy-text
                  [body]="' on ' + (job?.start_datetime_local | parseDate: 'ddd M/D h:mma')">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [helper]="job?.display_address">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>

        <ng-container *ngIf="!isPastJobsLoaded">
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%; height: 75px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%; height: 75px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%; height: 75px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%; height: 75px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated style="width: 100%; height: 75px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
        </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

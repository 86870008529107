import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ToDoListRoomModel } from 'src/models/to-do.model';

import { CurrentAddress } from 'src/providers/addresses/current-address';

@Component({
  templateUrl: 'edit-default-list.html',
  encapsulation: ViewEncapsulation.None
})

export class EditDefaultListPage implements OnInit {

  action: string;
  address: any;
  allToDoLists: any;
  errorMessage: string;
  form: UntypedFormGroup;
  listItems: any;
  oldDefaultList: any;
  oldDefaultService: any;
  serviceItems: any;
  type: string;
  loaded: boolean;

  constructor(
    private bookJob: BookJob,
    private client: Client,
    private currentAddress: CurrentAddress,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    public toDos: ToDos
  ) {
    this.form = this.fb.group({
      defaultList: ['', Validators.required],
      service: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      const selectedList = this.navCtrl.getParam('selectedList');
      this.allToDoLists = this.navCtrl.getParam('allToDoLists');
      this.type = this.navCtrl.getParam('type');
      this.action = this.navCtrl.getParam('action');
      this.address = this.navCtrl.getParam('address');
      this.listItems = this.buildListItems();
      if (this.type == 'account') {
        this.serviceItems = await this.buildServiceItems();
      }
      if (this.action == 'edit') {
        this.oldDefaultList = selectedList.id;
        this.form.patchValue({
          defaultList: this.oldDefaultList
        });
        if (this.type == 'account') {
          this.oldDefaultService = selectedList.default_list_setting.key.replace('default_list.', '');
          this.form.patchValue({
            service: this.oldDefaultService
          });
        }
      }
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildListItems() {
    let array = [];
    this.allToDoLists.map((list) => {
      if ((this.type == 'address' && list.address_id == this.address.value) || this.type == 'account') {
        array.push({
          value: list.id,
          viewValue: list.title
        });
      }
    });
    return array;
  }

  async buildServiceItems() {
    const services = await this.bookJob.fetchAllRequiredTeamServices();
    let array = [];
    services.map((service) => {
      array.push(({
        viewValue: service.name,
        value: service.key
      }));
    });
    return array;
  }

  async saveNewDefault() {
    try {
      if (this.action == 'edit') {
        await this.removeOldDefault();
      }
      let payload = {
        address_task_list_id: this.form.value.defaultList
      }
      if (this.type == 'account') {
        payload['settings'] = [{
          key: `default_list.${this.form.value.service}`,
          value: true
        }];
      } else {
        payload['address_id'] = this.address.value;
        payload['settings'] = [{
          key: `default_list.address.${this.address.value}`,
          value: true
        }];
      }
      await this.toDos.saveDefaultList(this.form.value.defaultList, payload)
      this.navCtrl.navigateForward('default-lists');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async removeOldDefault() {
    const editPayload = {
      address_task_list_id: this.oldDefaultList,
      settings: []
    }
    if (this.type == 'account') {
      editPayload.settings.push({
        key: `default_list.${this.oldDefaultService}`,
        value: false
      });
    } else {
      editPayload['address_id'] = this.address.value;
      editPayload.settings.push({
        key: `default_list.address.${this.address.value}`,
        value: false
      });
    }
    await this.toDos.saveDefaultList(this.oldDefaultList, editPayload);
  }

  goToListSettings() {
    this.navCtrl.navigateForward('list-settings');
  }

}

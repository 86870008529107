import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-fab-list',
  template: `<ion-fab-list [side]="side" [class.fab-list-active]="openfab">
    <ng-content></ng-content>
  </ion-fab-list>`,
  styleUrls: ['fab-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FabListComponent {
  @Input() openfab: boolean;
  @Input() side = 'top';
}

import { Component, ElementRef, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { RecoverAccountService } from 'src/providers/recover-account/recover-account';
import { Util } from 'src/shared/util/util';

import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import { RecoverAccountModel } from 'src/models/recover-account.model';
import { SuccessPageParamsModel } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'forgot-username.html',
  encapsulation: ViewEncapsulation.None
})

export class ForgotUsernamePage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  countryList: Array<RadioButtonModel>;
  submitted = false;
  loaded: boolean;
  zipCodeMask = {
    mask: '00000' // US mask
  };
  noAddressPickedTemplate: ElementRef;
  invalidAddressError: string;

  constructor(
    private fb: UntypedFormBuilder,
    private multipleCountryService: MultipleCountryService,
    private navCtrl: CustomNavController,
    private recoverAccountService: RecoverAccountService,
    private util: Util
  ) {
    this.form = this.fb.group({
      phone: ['', Validators.required],
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100)])],
      zipcode: [''],
      chargeAmount: [''],
      chargeDate: [''],
      cardLastDigits: ['', Validators.minLength(4)],
      captchaToken: ['', Validators.required]
    },
    {validator: this.validateAtLeastTwoInfo.bind(this)
    });
  }

  ngOnInit() {
    this.loaded = false;
    this.countryList = this.multipleCountryService.countryRadioButtonList;
    this.loaded = true;
  }

  captchaResolved(token) {
    this.form.controls.captchaToken.setValue(token);
  }

  async submit() {
    this.errorMessage = '';
    this.submitted = true;

    if (this.form.invalid) {
      this.errorMessage = this.form.errors?.missingInfo && 'Please include at least 2 pieces of information.';
      return;
    }

    if(this.invalidAddressError) {
      this.errorMessage = this.invalidAddressError;
      return;
    }

    try {
      const addressArray = this.form.value.address.split(',');
      const recoverAccData: RecoverAccountModel = {
        phone: this.form.value.phone,
        address: {
          address1: addressArray?.shift(),
          zipcode: this.form.value.zipcode
        },
        recentCharge: {
          amount: parseInt(this.util.removeCurrencyMask(this.form.value.chargeAmount), 10),
          date: this.form.value.chargeDate?.toString(),
          last4CardDigits: this.form.value.cardLastDigits
        }
      };
      const matchedAccounts = await this.recoverAccountService.recoverUserName(recoverAccData);

      if (matchedAccounts?.emails?.length === 0) {
        this.errorMessage = 'No account matching this information';
      } else {
        this.matchingAccounts(matchedAccounts?.emails);
      }
    } catch (err) {
      this.errorMessage = err?.error?.message || err?.message || err;
    }
  }

  validateAtLeastTwoInfo(group: UntypedFormGroup): ValidationErrors | null {
    const infoGroups = [
      ['phone'],
      ['countryCode', 'address', 'zipcode'],
      ['chargeAmount', 'chargeDate', 'cardLastDigits']
    ];
    const missingInfo = infoGroups.filter(infoGroup => {
      return infoGroup.some(controlName => {
        return !group.controls[controlName].value;
      })
    });

    if (missingInfo.length > 1) {
      return { missingInfo: true };
    }
    return null;
  }

  matchingAccounts(matchedAccounts: Array<string>) {
    const emailsStr = matchedAccounts.reduce((acc, curr) => {
      return `${acc}${curr}<br>`;
    }, '<br>');

    const successParams: SuccessPageParamsModel = {
      header: 'Matching Accounts',
      body: `We found the following account(s) that matched this information.\
             If this matches your account, you can log in using this email and\
             your password:
             ${emailsStr}
             If the email is incorrect, you can edit it in your account after`,
      buttonText: 'Next',
      buttonRoute: 'login'
    };
    this.navCtrl.navigateForward('success-no-auth', successParams);
  }

  setZipcode(zipcode: string) {
    this.form.controls.zipcode.setValue(zipcode);
  }

  onError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  setNotAddressPickedTemplate(template: ElementRef) {
    this.noAddressPickedTemplate = template;
  }
}

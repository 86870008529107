<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [title]="windowService.isDesktopRes ? report?.name : 'Reports'"
    [canGoBack]="!windowService.isDesktopRes"
    [customBack]="'more'"
    [showLinkIcon]="windowService.isDesktopRes"
    [linkIcon]="'settings-outline'"
    (linkAction)="goToReportSettings()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes" style="height: 51px">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Reports'">
      </tidy-text>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 545px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
      <ng-container *ngIf="loaded">
        <form [formGroup]="dateRangeForm" novalidate action="#" class="form-filters">
          <div class="desktop-item">
            <tidy-select
              class="select-custom-input"
              [label]="'Report Type'"
              [items]="reportFilterItems"
              (optionChange)="changeReportFilter($event)"
              [form]="dateRangeForm.get('type')"
              formControlName="type">
            </tidy-select>
          </div>
          <div class="desktop-item daterange-card">
            <div class="daterange-custom-container">
              <tidy-daterange
                class="daterange-custom-input"
                [label]="dateField?.field_name"
                (customDateOption)="saveCustomDateFilter($event)"
                (stringDateOption)="saveStringDateFilter($event)"
                [(dateRangeForm)]="dateRangeForm"
                [rangeOption]="dateFilter?.value?.includes('from') ? 'custom' : ''"
                [appearance]="'outline'">
              </tidy-daterange>
            </div>
          </div>
        </form>
        <div class="desktop-nav-button desktop-nav-button-green">
          <tidy-button
            [action]="goToAddReport.bind(this)"
            [image]="'assets/img/add-white.svg'"
            [text]="'Add Report'"
            [smallBody]="true">
          </tidy-button>
        </div>
        <ion-img
          [src]="'assets/img/gear.svg'"
          class="desktop-title-icon"
          (click)="goToReportSettings()">
        </ion-img>
      </ng-container>
    </tidy-desktop-header>

    <tidy-card style="padding: 0px; height: 600px; padding: 0px;" *ngIf="!loaded">
      <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0px"></ion-skeleton-text>
    </tidy-card>

    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!windowService.isDesktopRes">
        <tidy-row>
          <tidy-filter
            [initValue]="report?.id"
            [items]="reportFilterItems"
            [fitContent]="true"
            (optionChange)="changeReportFilter($event)">
          </tidy-filter>
        </tidy-row>
        <tidy-row [alignLastItemRight]="true" style="margin-top: -50px; margin-bottom:20px">
          <div>
            <tidy-image
              [src]="'assets/img/settings.svg'"
              class="title-size"
              (action)="goToReportSettings()">
            </tidy-image>
          </div>
        </tidy-row>
      </ng-container>

      <tidy-card *ngIf="rows?.length > 0" class="no-padding">
        <tidy-table
          [dataSource]="currentRows"
          [displayedColumns]="headers"
          [columnslabels]="headers"
          (sortChange)="sortChanged($event)"
          [showColumnLines]="false"
          [rowsToShow]="50"
          [infiniteScroll]="true"
          [totalRows]="rows?.length"
          (pageChange)="pageChange($event)"
          [showArrowIcon]="false">
        </tidy-table>
      </tidy-card>

      <tidy-alert *ngIf="!rows?.length">
        <tidy-row>
          <tidy-text
            [body]="'There are no results for this report.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card style="padding: 15px 15px 15px 5px" *ngFor="let card of nudgeCards">
        <tidy-card-button (action)="goToPage(card?.destination)">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin:auto">
                    <tidy-image
                      [src]="card?.icon"
                      [ngStyle]="{'width': card?.iconWidth}">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col tidySize="5">
                  <tidy-row>
                    <tidy-text
                      [header]="card?.header">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="card?.body">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-row class="extra-bottom-padding" *ngIf="!windowService.isDesktopRes">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 95px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content">
</app-scroll-wrapper>

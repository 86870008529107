export function resizeImage(photoBase64: string, format: string): Promise<string> {
  const img = document.createElement('img') as HTMLImageElement;
  img.src = photoBase64;

  return new Promise((resolve, reject) => {
    img.onload = (e) => {
      const canvas = document.createElement('canvas');

      const MAX_WIDTH = 1080;
      const MAX_HEIGHT = 1080;
      let width = img.width;
      let height = img.height;

      // Calculate aspect ratio
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      const dataUrl: string = canvas.toDataURL(`image/${format}`);

      resolve(dataUrl);
    }

    img.onerror = (err) => {
      reject(err)
    }
  })
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [title]="'Job History'"
    [canGoBack]="!cantGoBack"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text [title]="'Job History'"> </tidy-text>
      <ng-container *ngIf="loaded">
        <form
          [formGroup]="form"
          novalidate
          action="#"
          style="display: flex; flex-direction: row">
          <div class="desktop-item">
            <tidy-select
              class="select-custom-input"
              [label]="'Property'"
              [items]="addressFilter"
              [form]="form.get('property')"
              formControlName="property"
              (optionChange)="changeAddress($event)"
              [multiple]="true">
            </tidy-select>
          </div>
          <div class="desktop-item desktop-bills-item daterange-card">
            <div class="daterange-custom-container">
              <tidy-daterange
                class="daterange-custom-input"
                [label]="'Date Range'"
                (customDateOption)="selectCustomDateRange($event)"
                (stringDateOption)="selectStringDateRange($event)"
                [(dateRangeForm)]="dateRangeForm"
                [selectOptions]="selectOptions"
                [rangeOption]="true"
                [appearance]="'outline'">
              </tidy-daterange>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="width: 198px; height: 40px; border-radius: 6px">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <ng-container *ngIf="loaded">
      <tidy-row
        class="extra-bottom-padding"
        [align]="'center'"
        *ngIf="!windowService.isDesktopRes">
        <tidy-text
          [body]="'Only past jobs that were billed will show in your job history. For billing history,'">
        </tidy-text>
        <tidy-text
          [body]="'tap here'"
          (action)="goToBillingHistory()"
          class="link">
        </tidy-text>
        <tidy-text [body]="'.'"> </tidy-text>
      </tidy-row>

      <tidy-row class="extra-bottom-padding" *ngIf="windowService.isDesktopRes">
        <tidy-text
          [body]="'Only past jobs that were billed will show in your job history. For billing history,'">
        </tidy-text>
        <tidy-text
          [body]="'tap here'"
          (action)="goToBillingHistory()"
          class="link">
        </tidy-text>
        <tidy-text [body]="'.'"> </tidy-text>
      </tidy-row>

      <tidy-row
        class="extra-bottom-padding"
        *ngIf="!windowService.isDesktopRes">
        <div style="margin-bottom: 1rem">
          <tidy-select
            class="select-custom-input"
            [label]="'Property'"
            [items]="addressFilter"
            [form]="form.get('property')"
            formControlName="property"
            (optionChange)="changeAddress($event)"
            [multiple]="true">
          </tidy-select>
        </div>
        <div class="daterange-custom-container">
          <tidy-daterange
            class="daterange-custom-input"
            [label]="'Date Range'"
            (customDateOption)="selectCustomDateRange($event)"
            (stringDateOption)="selectStringDateRange($event)"
            [(dateRangeForm)]="dateRangeForm"
            [selectOptions]="selectOptions"
            [rangeOption]="true"
            [appearance]="'outline'">
          </tidy-daterange>
        </div>
      </tidy-row>

      <ng-container *ngIf="shownHistory?.length == 0">
        <tidy-row [align]="'center'" >
          <tidy-alert>
            <tidy-text
              [body]="'You have no past jobs'">
            </tidy-text>
          </tidy-alert>
        </tidy-row>
      </ng-container>

      <tidy-card *ngFor="let cleaning of shownHistory; let last = last">
        <tidy-card-button (action)="goToCleaningDetail(cleaning)" >
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="(cleaning?.service | titlecase)">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/book.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="(cleaning?.start_datetime_local | parseDate: 'dddd M/D')">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'at'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(cleaning?.start_datetime_local | parseDate: 'h:mma')">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/home.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="cleaning?.display_address"
              [translate]="false">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="cleaning?.status?.icon"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Status:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(cleaning?.status?.name | titlecase)">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

    <mat-paginator
      style="background: none"
      *ngIf="shownHistory?.length > 0"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="selectPage($event)">
    </mat-paginator>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content"></app-scroll-wrapper>

import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Subject, TimeoutError } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppActivatedRoute, AppRouter, AppTimeoutHandler } from 'src/app/app.module';

@Component({template: ''})
export abstract class TimeoutableComponent implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  private isActive: boolean;
  public loaded$ = false;
  protected alreadyShowedOfflineOrTimeoutAlert: boolean;
  protected successPageLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected retrySubject = new Subject<void>();

  constructor(
  ) {
    AppRouter.events.pipe(
      takeUntil(this.destroy$),
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.isActive = this.isComponentActive(
        AppRouter.routerState.snapshot.root.pathFromRoot,
        AppActivatedRoute.snapshot.component
      );
    });

    this.retrySubject.subscribe(() => {
      this.alreadyShowedOfflineOrTimeoutAlert = !this.loaded;
    });
  }

  onDestroy() {}

  ngOnDestroy() {
    this.onDestroy();
    this.destroy$?.next();
    this.destroy$?.complete();
    this.isActive = false;
  }

  set loaded(value: boolean) {
    this.loaded$ = value;
    this.successPageLoad.emit(value);
  }

  get loaded(): boolean {
    return this.loaded$;
  }

  // TODO: Add this class to the components that I removed

  ionViewDidEnter?(): any;

  ngOnInit?(): any;

  timeoutHandler(error): Promise<any | undefined> {
    return new Promise<any>((resolve, reject) => {
      if (this.isActive && !this.loaded && !this.alreadyShowedOfflineOrTimeoutAlert && error instanceof TimeoutError) {

        this.alreadyShowedOfflineOrTimeoutAlert = true;
        const callback = this.ionViewDidEnter?.bind(this) || this.ngOnInit?.bind(this);
        AppTimeoutHandler.showTimeoutAlert(this.successPageLoad, callback, this.retrySubject);
        resolve(null);
      } else {
        resolve(error);
      }
    });
  }

  private isComponentActive(path: ActivatedRouteSnapshot[], component: any): boolean {
    return path.some((ss: ActivatedRouteSnapshot) => {
      return ss.component === component || this.isComponentActive(ss.children, component);
    });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
    <tidy-top-nav
      *ngIf="!windowService.isDesktopRes"
      [class]="extraClass"
      [title]="'Audit Logs'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Audit Logs'">
      </tidy-text>
      <ng-container *ngIf="isFiltersLoaded">
        <div class="desktop-nav-search">
          <form [formGroup]="searchForm" novalidate action="#">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="searchForm">
            </tidy-input>
          </form>
        </div>
        <form
          [formGroup]="form"
          novalidate
          action="#"
          style="display: flex">
          <div class="desktop-item desktop-message-item">
            <tidy-select
              class="search-input"
              [label]="'Status'"
              [items]="statusList"
              [multiple]="false"
              [form]="form.get('status')"
              formControlName="status"
              (optionChange)="onFilterValueChange()">
            </tidy-select>
          </div>
          <div class="desktop-item desktop-message-item">
            <tidy-select
              class="search-input"
              [label]="'Property'"
              [items]="addressFilter"
              [multiple]="true"
              [form]="form.get('selectedAddress')"
              formControlName="selectedAddress"
              (optionChange)="onFilterValueChange()">
            </tidy-select>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="!isFiltersLoaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 573px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <tidy-row></tidy-row>
    
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="workflowItems?.length == 0">
        <tidy-row>
          <tidy-text [body]="'No workflows added yet. Add workflows to view the run history here.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row *ngIf="workflowItems?.length > 0">
        <tidy-filter
          [initValue]="0"
          [items]="workflowItems"
          [fitContent]="true"
          (optionChange)="selectWorkflow($event)">
        </tidy-filter>
      </tidy-row>

      <!-- <tidy-row class="daterange-card">
        <div style="
        background: white;
        border-radius: 100px;
        padding: 5px 20px 5px 20px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: 250px;
        margin: auto;
        max-width: 80%">
          <tidy-daterange
            [label]="'Date Range'"
            (customDateOption)="selectCustomDateRange($event)"
            (stringDateOption)="selectStringDateRange($event)"
            [(dateRangeForm)]="dateRangeForm"
            [selectOptions]="selectOptions"
            [rangeOption]="true">
          </tidy-daterange>
        </div>
      </tidy-row> -->

      <ng-container *ngIf="isFiltersLoaded && !windowService.isDesktopRes">
        <ion-row style="margin-bottom: 0px">
          <ion-col style="padding: 0px; padding-bottom: 10px">
            <form [formGroup]="searchForm" novalidate action="#">
              <tidy-input
                #searchInput
                class="white-search-input"
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="searchForm">
              </tidy-input>
            </form>
          </ion-col>
        </ion-row>
        <form
          [formGroup]="form"
          novalidate
          action="#">
          <ion-row>
            <ion-col style="padding: 0px; padding-bottom: 10px">
              <tidy-select
                class="white-search-input"
                [label]="'Status'"
                [items]="statusList"
                [multiple]="false"
                [form]="form.get('status')"
                formControlName="status"
                (optionChange)="onFilterValueChange()">
              </tidy-select>
            </ion-col>
            <ion-col style="padding: 0px">
              <tidy-select
                class="white-search-input"
                [label]="'Property'"
                [items]="addressFilter"
                [multiple]="true"
                [form]="form.get('selectedAddress')"
                formControlName="selectedAddress"
                (optionChange)="onFilterValueChange()">
              </tidy-select>
            </ion-col>
          </ion-row>
        </form>
      </ng-container>
      <ng-container *ngIf="!isFiltersLoaded && !windowService.isDesktopRes">
        <ion-skeleton-text
          animated="true"
          style="
            width: 573px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>

      <tidy-alert *ngIf="history?.length == 0 && workflowsLoaded">
        <tidy-row>
          <tidy-text
            [body]="'No history in this range.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="loaded">
        <tidy-card *ngFor="let guestReservationSyncRun of history">
          <tidy-card-button (action)="goToReservationSyncRunPage(guestReservationSyncRun)">
            <tidy-row class="vertical-align-middle" [alignLastItemRight]="true">
              <div style="margin-right: 20px">
                <tidy-image
                  [src]="guestReservationSyncRun?.guest_reservation_source_type?.logo_url"
                  style="width:35px">
                </tidy-image>
              </div>
              <div>
                <tidy-text
                  [header]="guestReservationSyncRun?.guest_reservation_source_type?.name">
                </tidy-text><br>
                <tidy-text
                  [helper]="guestReservationSyncRun?.created_at | tidyDate:'h:mm a MMM d, yyyy'">
                </tidy-text>
              </div>
              <div style="margin-right: 20px">
                <tidy-image
                  [src]="guestReservationSyncRun?.status == 'failure' ? 'assets/img/block.svg' : 'assets/img/checkmark.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="guestReservationSyncRun?.status | titlecase">
                </tidy-text>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
        <tidy-card-button (action)="goToWorkflowRunPage(run)">
          <tidy-row class="vertical-align-middle" [alignLastItemRight]="true">
            <div style="width:20%">
              <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
            </div>
            <div style="width:15%; display: flex">
              <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
            </div>
            <div style="width:25%; margin-left:30px">
              <ion-skeleton-text animated style="width: 80%; height: 20px"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 20px"></ion-skeleton-text>
            </div>
            <div>
            </div>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <mat-paginator
      style="background: none"
      *ngIf="history?.length > 0"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="selectPage($event)">
    </mat-paginator>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

import { Component, ViewEncapsulation, Input, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Events } from 'src/providers/events/events';
import { ModalController } from '@ionic/angular';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Util } from 'src/shared/util/util';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  selector: 'success-page',
  templateUrl: './success.html',
  encapsulation: ViewEncapsulation.None
})
export class SuccessPage implements OnDestroy, OnInit {

  params: SuccessPageParamsModel;
  @Input() modalParams: SuccessPageParamsModel;
  errorMessage: string;
  modalSubscription: Subscription;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private customNavCtrl: CustomNavController,
    private util: Util,
    private events: Events,
    private modalCtrl: ModalController,
    private router: Router,
    private storage: TidyStorage,
  ) {}

  async ngOnInit(): Promise<void> {
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.params = this.dialogParams || this.customNavCtrl.getParams() || this.modalParams || this.params;
    if (this.params.showDesktopNav === false) {
      this.events.publish('menu:close');
    }
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.modalSubscription = this.modalParams?.dismiss?.subscribe(dissmiss => {
      if (dissmiss) {
        this.dismissModal(this.modalParams?.buttonRoute)
      }
    });
  }

  async buttonAction() {
    try {
      if (this.params?.customAction) {
        await this.params.customAction();
        this.params.actionSubject?.next();
        return;
      }
      if (!this.params.externalUrl) {
        this.params.actionSubject?.next();
        this.customNavCtrl.navigateRoot(this.params.buttonRoute || 'dashboard', this.params.navigationExtras || {});
      } else {
        this.util.openUrl(this.params.buttonRoute);
      }
      this.dismissModal(this.modalParams?.buttonRoute);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async secondButtonAction() {
    try {
      await this.params.secondCustomAction();
      this.dismissModal();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async dismissModal(targetPage: string = null) {
    const topModal = await this.modalCtrl.getTop();
    if (topModal) {
      await this.modalCtrl.dismiss(topModal.id);
    }

    if (this.router.url.includes(targetPage)) {
      window.location.reload();
    }
  }

  ngOnDestroy() {
    this.modalSubscription?.unsubscribe();
  }
}

export interface SuccessPageParamsModel {
  header: string;
  externalUrl?: boolean;
  body: string;
  buttonText: string;
  buttonRoute?: string;
  titlecase?: boolean;
  navigationExtras?: {[key: string]: string}
  customAction?: (data?) => Promise<any>;
  showDesktopNav?: boolean;
  secondButtonText?: string;
  secondCustomAction?: (data?) => Promise<any>;
  dismiss?: EventEmitter<boolean>;
  actionSubject?: Subject<void>;
}

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { Aws } from 'src/providers/aws/aws';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';

import { generateUuid } from 'src/providers/tidy-session/session-uuid';
import { InvoiceFilterParams } from 'src/models/invoice.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class Invoices {

  constructor(
    private aws: Aws,
    private httpClient: HttpClientCustom,
    private navCtrl: CustomNavController,
    private filePicker: FilePicker
  ) {}

  getInvoices(params: {
    teamId: string,
    status: 'paid' | 'pending' | null,
    jobId: string | number | null,
  }) {
    const searchParams = new URLSearchParams();

    searchParams.append('team_ids', params.teamId);
    searchParams.append('state', 'active')
    searchParams.append('includes', 'items')

    if (params.status) {
      searchParams.append('status', params.status);
    }
    if (params.jobId) {
      searchParams.append('job_ids', params.jobId.toString());
    }

    const timeout = 60000;
    const url = `api/v1/customer/invoices?${searchParams.toString()}&includes=amount_due,homekeeper_data,items`;
    return this.httpClient.get(url, timeout);
  }

  getPendingInvoices(addressId = null) {
    let url = 'api/v1/customer/invoices?status=pending&includes=amount_due,homekeeper_data,items';
    if (addressId) {
      url += `&address_ids=${addressId}`
    }
    const timeout = 60000;
    return this.httpClient.get(url, timeout);
  }

  getAllInvoices(params: Partial<InvoiceFilterParams>, pageSize, pageIndex) {
    let url = `api/v1/customer/invoices?includes=homekeeper_data,items&state=active&per_page=${pageSize}&page=${pageIndex}&`;
    if (params?.status) {
      url += `status=${params.status.join(',')}&`;
    }
    if (params?.team_ids) {
      url += `team_ids=${params.team_ids.join(',')}&`;
    }
    if (params?.created_since_date) {
      url += `created_since_date=${params.created_since_date}&`;
    }
    if (params?.created_until_date) {
      url += `created_until_date=${params.created_until_date}&`;
    }
    if (params?.address_ids) {
      url += `address_ids=${params.address_ids.join(',')}`;
    }
    return this.httpClient.getFullRequest(url)
      .then(response => {
        return {
          body: response.body,
          totalRecords: response.headers.get('X-Total-Records')
        };
      });
  }

  getPaidInvoices(addressId = null) {
    let url = 'api/v1/customer/invoices?status=paid';
    if (addressId) {
      url += `&address_ids=${addressId}`
    }
    const timeout = 60000;
    return this.httpClient.get(url, timeout);
  }

  getInvoiceById(invoiceId) {
    const url = `api/v1/customer/invoices/${invoiceId}?includes=tidy_credit_amount,amount_due,homekeeper_data,items,notifications`;
    return this.httpClient.get(url);
  }

  getInvoice(uuid) {
    const url = `api/v1/tidy-website/invoices/${uuid}?includes=tidy_credit_amount`;
    return this.httpClient.get(url);
  }

  payInvoiceCardOnFile(invoiceId, payload) {
    const url = `api/v1/customer/invoices/${invoiceId}/pay`;
    return this.httpClient.post(url, payload);
  }

  payInvoiceOneTimeCard(payload: {invoice_uuid: string, card_token: string, currency?: string}) {
    const url = `api/v1/tidy-website/invoices/${payload.invoice_uuid}/pay-anonymous`;
    return this.httpClient.post(url, payload);
  }

  getPaymentOptions(invoiceUuid) {
    const url = `api/v1/tidy-website/invoices/${invoiceUuid}/payment-options`;
    return this.httpClient.get(url);
  }

  recordDirectPayment(payload) {
    const url = `api/v1/tidy-website/invoices/${payload.invoice_uuid}/direct-payment`;
    return this.httpClient.post(url, payload);
  }

  getInvoiceNotes(invoiceId) {
    const url = `api/v1/customer/invoice-notes?invoice_id=${invoiceId}`;
    return this.httpClient.get(url);
  }

  createInvoiceNote(payload) {
    const url = 'api/v1/customer/invoice-notes';
    return this.httpClient.post(url, payload);
  }

  async addAttachment() {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `message/${uuid}/${file.filename}`;
    if (file.format.includes('jpeg') || file.format.includes('png')) {
      const response = await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return response.Location;
    } else {
      return '';
    }
  }

  async copyBillLink(link) {
    let fullLink = 'https://app.tidy.com/#/';
    if (environment.api_secure == 'https://api-sandbox3.tidy.com/') {
      fullLink = 'https://ca-v2-sandbox3.tidy.com/#/';
    }
    if (environment.api_secure == 'https://api-staging.tidy.com/') {
      fullLink = 'https://ca-v2-staging.tidy.com/#/';
    }
    fullLink += link;
    const textArea = document.createElement('textarea');
    textArea.value = fullLink;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

}

import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { EmployerService } from "src/providers/employer/employer";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import validationUtils from "src/shared/util/validation-utils";

@Component({
  templateUrl: 'edit-employee-details.html'
})

export class EmployerEditEmployeeDetailsPage implements OnInit {

  dialogParams: any;
  employee: any;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private employer: EmployerService,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([validationUtils.validateEmail])]
    });
  }

  async ngOnInit() {
    this.rightSidePanelService.setDialogPageTitle('Edit Employee');
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.employee = this.dialogParams.employee;
    this.form.patchValue({
      firstName: this.employee.source_user_first_name,
      lastName: this.employee.source_user_last_name,
      email: this.employee.source_user_email
    });
  }

  async saveChanges() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = {
        id: this.employee.id,
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        email: this.form.value.email
      };
      await this.employer.editEmployee(payload);
      this.employee.source_user_email = this.form.value.email;
      this.employee.source_user_first_name = this.form.value.firstName;
      this.employee.source_user_last_name = this.form.value.lastName;
      const params = {
        employee: this.employee,
        employeeBalance: this.dialogParams.employeeBalance,
        companyCredit: this.dialogParams.companyCredit
      }
      await this.employer.listEmployees(true);
      this.rightSidePanelService.navigateTo('employer-edit-employee', params);
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

}

<form [formGroup]="nestedForm">
  <div formArrayName="forms">
    <ng-container
      *ngFor="let form of forms?.controls; let formIndex = index"
      [formGroupName]="formIndex">
      <tidy-alert @slideFromTop>
        <form [formGroup]="form">
          <ng-container
            *ngFor="let field of form?.controls; let fieldIndex = index; let lastField = last"
            [formGroupName]="fieldIndex">
            <div @slideFromTop class="animated-div">
              <div class="field-header-container">
                <div>
                  <ng-container *ngIf="formIndex === 0">
                    <tidy-text
                      [header]="fieldIndex === 0 ? 'Only continue if' : 'And'"></tidy-text>
                  </ng-container>
                  <ng-container *ngIf="formIndex > 0">
                    <tidy-text
                      [header]="fieldIndex === 0 ? 'Or continue if' : 'And'"></tidy-text>
                  </ng-container>
                </div>
                <div>
                  <!-- INFO: Avoid to use tidy-button here because it has a bug -->
                  <ng-container *ngIf="fieldIndex === 0; else removeRule">
                    <div (click)="removeForm(formIndex)" class="remove-rule-group-div">
                      <img src="assets/icon/trash-white.png" alt="Trash">
                      <tidy-text [body]="'Remove Rule Group'"></tidy-text>
                    </div>
                  </ng-container>
                  <ng-template #removeRule>
                    <div (click)="removeField(form, formIndex, fieldIndex)" class="remove-rule-group-div">
                      <img src="assets/icon/trash-white.png" alt="Trash">
                      <tidy-text [body]="'Remove Rule'"></tidy-text>
                    </div>
                  </ng-template>
                </div>
              </div>
              <ion-row>
                <ng-container
                  *ngFor="let operatorField of operatorFields; let operatorFieldIndex = index">
                  <ng-container *ngIf="operatorFieldIndex === formIndex">
                    <ng-container
                      *ngFor="let operatorFieldItem of operatorField; let i = index">
                      <ng-container *ngIf="i === fieldIndex">
                        <ng-container *ngFor="let item of operatorFieldItem">
                          <ng-container *ngIf="item?.type === 'select'">
                            <tidy-select
                              [label]="item?.label"
                              [items]="item?.items"
                              [multiple]="item?.multiple"
                              [form]="field?.get(item?.formControlName)"
                              [formControlName]="item?.formControlName"
                              (optionChange)="onSelectChanges($event, field, formIndex, fieldIndex, item)">
                            </tidy-select>
                          </ng-container>
                          <ng-container
                            *ngIf="item?.type === 'number'">
                            <ion-col>
                              <tidy-input
                                [formControlName]="item?.formControlName"
                                [form]="field?.get(item?.formControlName)"
                                [label]="item?.label"
                                [type]="item?.type || 'text'"
                                [errorMessage]="'Please enter a valid value'">
                              </tidy-input>
                            </ion-col>
                          </ng-container>
                          <ng-container
                            *ngIf="item?.type === 'text' || item?.type === 'dateText'">
                            <div class="chip-text-area-container">
                              <tidy-chip-text-area
                                [id]="item?.id"
                                [inputKey]="item?.formControlName"
                                [inputTitle]="item?.label"
                                [isRequired]="item?.required"
                                (inputChange)="onInputChange($event, field)"
                                (inputFocusIn)="setSelectedChipTextArea(item?.id)"
                                (onInit)="onChipTextAreaInit(item?.id, field?.get(item?.formControlName))">
                              </tidy-chip-text-area>
                              <div class="chip-container">
                                <ion-chip *ngFor="let chip of chips" (click)="addChipPlaceholder(chip, item?.id)">
                                  <strong>+<span>&nbsp;</span></strong> {{ chip.text }}
                                </ion-chip>
                              </div>
                              <ng-container *ngIf="item?.type === 'dateText'">
                                <tidy-button
                                  [text]="'Switch to custom date'"
                                  [smallText]="true"
                                  class="primary form-action-button"
                                  [type]="'button'"
                                  [action]="switchToCustomDate.bind(this, field, item)">
                                </tidy-button>
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="item?.type === 'date'">
                            <ion-col>
                              <tidy-datepicker
                                [formControlName]="item?.formControlName"
                                [form]="field?.get(item?.formControlName)"
                                [label]="item?.label"
                                [icon]="'assets/svg/calendar-outline.svg'"
                                [errorMessage]="'Please enter a valid date.'">
                              </tidy-datepicker>
                              <tidy-button
                                [text]="'Switch to custom date'"
                                [smallText]="true"
                                class="primary form-action-button"
                                [type]="'button'"
                                [action]="switchToCustomDate.bind(this, field, item)">
                              </tidy-button>
                            </ion-col>
                          </ng-container>
                          <ng-container *ngIf="item?.type === 'customDate'">
                            <ion-col>
                              <tidy-daterange
                                class="daterange-custom-input"
                                [label]="item?.label"
                                (stringDateOption)="selectStringDateRange($event, field, item)"
                                [(dateRangeForm)]="item.dateRangeForm"
                                [selectOptions]="item?.selectOptions"
                                [rangeOption]="true"
                                [icon]="'assets/svg/calendar-outline.svg'"
                                [appearance]="'outline'"
                                [errorMessage]="'Please enter a valid date.'">
                              </tidy-daterange>
                            </ion-col>
                          </ng-container>
                          <ng-container *ngIf="item.type === 'money'">
                            <ion-col>
                              <tidy-input
                                [formControlName]="item?.formControlName"
                                [form]="field?.get(item?.formControlName)"
                                [label]="item?.label"
                                [mask]="'currencyMask'"
                                [errorMessage]="'Please enter a valid value'">
                              </tidy-input>
                            </ion-col>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ion-row>
              <ion-row *ngIf="lastField">
                <div style="padding-left: 6px">
                  <tidy-button
                    [text]="'And'"
                    [smallText]="true"
                    class="primary form-action-button"
                    [type]="'button'"
                    [image]="'assets/img/add-white.svg'"
                    [action]="addNestedField.bind(this, form, formIndex)">
                  </tidy-button>
                </div>
              </ion-row>
            </div>
          </ng-container>
        </form>
      </tidy-alert>
    </ng-container>
  </div>
  <ion-row class="add-form-row animated-div" @slideFromTop>
    <div>
      <tidy-button
        text="Add 'Or' rule group"
        [smallText]="true"
        class="primary form-action-button"
        [type]="'button'"
        [image]="'assets/img/add-white.svg'"
        [action]="addNestedForm.bind(this)">
      </tidy-button>
    </div>
  </ion-row>
</form>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="addedUser ? (isEmployerPortal ? 'Admin Added' : 'User Added') : (isEmployerPortal ? 'Add Admin' : 'Add User')"
    [canGoBack]="!addedUser"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <ng-container *ngIf="!addedUser">
          <tidy-card>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-input
                formControlName="firstName"
                [form]="form.controls.firstName"
                [label]="'First Name'"
                [errorMessage]="'Please enter the first name of the user.'"
                [submitted]="submitted"
                [icon]="'assets/svg/person-outline.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="lastName"
                [form]="form.controls.lastName"
                [label]="'Last Name'"
                [errorMessage]="'Please enter the last name of the user.'"
                [submitted]="submitted"
                [icon]="'assets/svg/person-outline.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="email"
                [form]="form.controls.email"
                [label]="'Email'"
                type="email"
                [errorMessage]="'Please enter a valid email.'"
                [submitted]="submitted"
                [icon]="'assets/svg/mail-outline.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row>
              <tidy-input
                formControlName="password"
                [form]="form.controls.password"
                [label]="'Password'"
                [errorMessage]="'Please enter a password.'"
                [submitted]="submitted"
                type="password"
                [icon]="'assets/svg/lock-closed-outline.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row [align]="'center'" class="extra-bottom-padding">
              <tidy-text
                [helper]="'Share this password with the user and they can change it once they create their account.'">
              </tidy-text>
            </tidy-row>

            <tidy-row *ngIf="clientInfo?.customer_account?.account_type !== 'regular'">
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'User Type'"
                [items]="roleItems"
                [form]="form.controls.role"
                formControlName="role"
                [submitted]="submitted"
                [errorMessage]="'Please select a user type'">
              </tidy-select>
            </tidy-row>

            <tidy-row [align]="'center'" [ngClass]="user?.role == 'admin' && form?.value?.role == 'member' ? 'extra-bottom-padding' : ''">
              <tidy-text
                [helper]="'Account admins can see all users and properties. Team managers can only see users and properties on their team.'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="form?.value?.role == 'member'">
              <tidy-row *ngIf="teamItems?.length > 0">
                <tidy-select
                  [icon]="'assets/svg/location-outline.svg'"
                  [label]="'Add to Team'"
                  [items]="teamItems"
                  [form]="form.controls.team"
                  formControlName="team"
                  [submitted]="submitted"
                  [errorMessage]="'Please select a team'">
                </tidy-select>
              </tidy-row>
              <ng-container *ngIf="teamItems?.length == 0">
                <tidy-row class="extra-top-padding">
                  <tidy-text
                    [header]="'Create Your First Team:'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    formControlName="teamName"
                    [form]="form.controls.teamName"
                    [label]="'Team Name'"
                    [errorMessage]="'Please enter the first name of the team.'"
                    [submitted]="submitted"
                    [icon]="'assets/svg/information-circle-outline.svg'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-card>

          <tidy-error-message
            *ngIf="errorMessage"
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [text]="isEmployerPortal ? 'Add Admin' : 'Add User'"
            [action]="addNewUser.bind(this)"
            class="primary">
          </tidy-button>

          <ng-container *ngIf="!isEmployerPortal">
            <tidy-row [align]="'center'">
              <tidy-text
                [header]="isEmployerPortal ? 'Why Add Admins?' : 'Why Add Users?'">
              </tidy-text>
            </tidy-row>

            <tidy-alert style="padding: 15px 15px 15px 10px" *ngFor="let card of salesCards">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin: auto">
                        <tidy-image
                          [src]="card?.image"
                          style="width:35px">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="card?.title">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="card?.text">
                        </tidy-text>
                        <tidy-text
                          *ngIf="card?.title == 'We Can Help'"
                          [actionBody]="'Book Call'"
                          class="link"
                          (action)="goToBookCall()">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-alert>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="addedUser">

          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Send your password to'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="form?.value?.firstName + ' ' + form?.value?.lastName">
            </tidy-text>
            <tidy-text
              [body]="'. Here is a message you can use:'">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="isEmployerPortal ? 'I added you as an admin in TIDY.' : 'I added you to my account in TIDY.'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'Click here to log in: https://app.tidy.com/#/login'">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'Use this password:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="form?.value?.password">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'Copy Text'"
                class="link"
                (action)="copyText()">
              </tidy-text>
              <tidy-text
                *ngIf="showCopiedText"
                [helper]="' Copied'">
              </tidy-text>
            </tidy-row>
          </tidy-card>

          <tidy-button
            [text]="'Ok'"
            [action]="goToUsersPage.bind(this)"
            class="primary">
          </tidy-button>

        </ng-container>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Add Property'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'properties'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngIf="loaded">
      <form [formGroup]="form" novalidate action="#">
        <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <ng-container *ngIf="isRentalClient">

          <tidy-card style="padding: 15px 15px 15px 5px">
            <tidy-card-button (action)="goToAddDirectIntegration()">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin:auto">
                        <tidy-image
                          [src]="'assets/img/integrate.svg'"
                          style="width:45px">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="'Integrate PMS'">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="'If you use a PMS system you can integrate to import all properties.'">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-card-button>
            <tidy-card-button (action)="goToImportCSV()">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin:auto">
                        <tidy-image
                          [src]="'assets/img/csv.svg'"
                          style="width:30px">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="'Import CSV'">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="'Import all properties by importing a CSV.'">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-card-button>
          </tidy-card>

          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'or'"
              class="divider">
            </tidy-text>
          </tidy-row>

        </ng-container>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/home.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              *ngIf="isRentalClient"
              [header]="'Add One Property'">
            </tidy-text>
            <tidy-text
              *ngIf="!isRentalClient"
              [header]="'Add Property'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/location-outline.svg'"
              [label]="'Country'"
              [items]="countryList"
              [form]="form.get('countryCode')"
              formControlName="countryCode"
              (optionChange)="changeCountrySelected($event)">
            </tidy-select>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-auto-complete
              [showGoogleIcon]="true"
              label="Address"
              formControlName="address"
              [options]="autocompleteAddress"
              [icon]="'assets/svg/home-outline.svg'"
              [form]="form.controls.address"
              [errorMessage]="'Please enter a valid address'"
              [submitted]="submitted"
              (keyup)="updateSearch()"
              (action)="chooseItem($event)">
            </tidy-auto-complete>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="address2"
              [form]="form.controls.address2"
              label="Unit / Apartment"
              [errorMessage]="'Please enter a valid unit'"
              [submitted]="submitted"
              [icon]="'assets/img/door.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="zipcode"
              [customMask]="zipCodeMask"
              [form]="form.controls.zipcode"
              [errorMessage]="'Please enter a valid zipcode'"
              [submitted]="submitted"
              [inputMode]="'numeric'"
              label="Postal Code"
              [icon]="'assets/img/mailbox.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row>
            <tidy-input
              formControlName="addressNickname"
              [form]="form.controls.addressNickname"
              [label]="'Property Nickname (optional)'"
              [icon]="'assets/svg/information-circle-outline.svg'">
            </tidy-input>
          </tidy-row>
        </tidy-card>

        <tidy-card *ngIf="radioButtonAddress.length > 0 && submitted && (typedOnAddress || !didChooseAddress)">
          <tidy-row>
            <tidy-text
              [header]="'Please confirm your address:'">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-radio-button
              [helper]="'If you do not see your address please retype it above'"
              [items]="radioButtonAddress"
              (optionChange)="chooseItem($event); setAddressFormControl($event)">
            </tidy-radio-button>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add Property'"
          class="primary"
          [action]="addAddress.bind(this)">
        </tidy-button>

      </form>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

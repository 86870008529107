<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Schedule'"
    [canGoBack]="shouldShowAllToDosPage"
    [customBack]="'schedule'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-loading-bar *ngIf="isRightSideContent" class="js-tidy-loading"></tidy-loading-bar>
  <tidy-wrapper
    [hasBottomNav]="!shouldShowAllToDosPage"
    (contentHeight)="contentHeight = $event"
    [ngClass]="{'fab-page': loaded && cards?.length > 0 && !hasOnlyReservations && !hasOnlyCrafRequests && !isAllAddressesSelected}">

    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <!--Address Filter-->
    <tidy-row
      [class.tidy-filter-padding]="isAllAddressesSelected"
      *ngIf="!windowService?.isDesktopRes && addressFilterLoaded && addressId && !shouldShowAllToDosPage">
      <tidy-filter
        [initValue]="addressId"
        [items]="addressFilter"
        [fitContent]="true"
        (optionChange)="changeAddress($event)">
      </tidy-filter>
    </tidy-row>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="isAllAddressesSelected">
      <tidy-schedule-weekly-filter
        [addresses]="addressResponse"
        [selectedPeriodRange]="selectedPeriodRange"
        (onFilter)="onFilterChanges($event)"
        (onSearch)="onSearchChanges($event)"
        (onGoToPreviousPeriod)="goToPreviousPeriod()"
        (onGoToNextPeriod)="goToNextPeriod()"
        (onChangeScheduleViewMode)="changeScheduleViewMode($event)">
      </tidy-schedule-weekly-filter>

      <ng-container *ngIf="!isScheduleDataLoaded && scheduleViewMode !== 'MONTHLY'">
        <ion-skeleton-text animated="true" style="width: 100%; height: 100vh"></ion-skeleton-text>
      </ng-container>

      <ng-container *ngIf="scheduleViewMode === 'MONTHLY'">
        <tidy-schedule-monthly-view
          [properties]="schedule.properties"
          [selectedPeriodRange]="selectedPeriodRange"
          [isLoading]="isLoadingSchedule"
          (onClickAction)="handleClickActionMonthlyView($event)">
        </tidy-schedule-monthly-view>
      </ng-container>
      <ng-container *ngIf="isScheduleDataLoaded">
        <ng-container *ngIf="scheduleViewMode === 'WEEKLY'">
          <div class="remove-wrapper-margin">
            <tidy-schedule-weekly-view
              [isMobileResolution]="!windowService?.isDesktopRes"
              [properties]="schedule.properties"
              [daysOfWeek]="daysOfWeek"
              [selectedPeriodRange]="selectedPeriodRange"
              (onClickAction)="handleClickAction($event)"
              (onAssignedPro)="handleAssignedPro($event)">
            </tidy-schedule-weekly-view>
          </div>
        </ng-container>
      </ng-container>

      <div style="padding: 20px; margin-bottom: 100px" [ngStyle]="!windowService?.isDesktopRes ? {'margin-top': '-80px'} : {}"  *ngIf="addressResponse?.length == 1 && !addressResponse[0]?.integration?.name && !hasJobsAtOnlyAddress">
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-text
            [title]="'Start Using Your Calendar:'">
          </tidy-text>
        </tidy-row>

        <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
          <tidy-card-button (action)="bookJobSelectAddress()">
            <tidy-row>
              <ion-row>
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/book-job.svg'"
                    style="width:40px;height:50px">
                  </tidy-image>
                </tidy-row>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Instantly Book'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Book or manage your pros instantly, or find a new one.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
          <tidy-card-button (action)="goToAllIntegrations()">
            <tidy-row>
              <ion-row>
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/integration.svg'"
                    style="width:40px;height:50px">
                  </tidy-image>
                </tidy-row>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Sync Guest/Tenant Calendar'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Integrate Airbnb or a property management tool to get guest check-ins or tenant move outs synced to your calendar, to make automation easy.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </div>

    </ng-container>

    <ng-container *ngIf="!isAllAddressesSelected">

      <ng-container *ngIf="loaded && !hasAddresses">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Please add a property to book jobs.'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [text]="'Add Property'"
          [action]="goToAddAddress.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="loaded && hasAddresses">

        <ng-container *ngIf="cardsLoaded">

          <ng-container *ngIf="isRentalClient">
            <tidy-alert *ngIf="automaticBookingEnabled">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/search-calendar-enabled.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Automatic Booking: ON'">
                </tidy-text>
                <tidy-text
                  style="margin-left: 5px"
                  [body]="'Edit'"
                  (action)="goToIntegrations()"
                  class="link">
                </tidy-text>
              </tidy-row>
            </tidy-alert>

            <tidy-alert *ngIf="!automaticBookingEnabled">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/search-calendar-disabled.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Automatic Booking: OFF'">
                </tidy-text>
                <tidy-text
                  style="margin-left: 5px"
                  [body]="'Edit'"
                  (action)="goToIntegrations()"
                  class="link">
                </tidy-text>
              </tidy-row>
            </tidy-alert>
          </ng-container>

          <ng-container *ngIf="automaticBookingEnabled && !hasCreditCard && waterfallSettings?.key !== 'never_use_tidy'">
            <tidy-alert>
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Payment Info Needed'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'You have automatic booking enabled but no card on file. Please add a card to get new Pros from TIDY. '">
                </tidy-text>
                <tidy-text
                  [body]="'Add Payment Info'"
                  class="link"
                  (action)="goToAddCreditCard()">
                </tidy-text>
              </tidy-row>
            </tidy-alert>
          </ng-container>

          <tidy-alert *ngIf="!hasPhone && (plans?.length > 0 && (cards?.length > 0 || !hasOnlyReservations))">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                class="red"
                [actionBody]="'Missing Phone number'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Please add a phone number so your pro can contact you for your upcoming job.'">
              </tidy-text>
              <tidy-text
                [body]="'Add Phone'"
                (action)="goToEditContactInfo()"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-alert>

          <tidy-alert *ngIf="plans?.length === 0 && (cards?.length === 0 || hasOnlyReservations) || (plans[0]?.plan_frequency == 'once' && !hasJobs)">
            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'You have no jobs booked at this property. '">
              </tidy-text>
            </tidy-row>
          </tidy-alert>

          <!--No Jobs Alert-->
          <ng-container *ngIf="(plans?.length === 0 && cards?.length === 0) || (plans[0]?.plan_frequency == 'once' && cards?.length === 0 )">

            <tidy-button
              [text]="'Book Job(s)'"
              [action]="bookJob.bind(this, 'add_job')"
              class="primary">
            </tidy-button>

            <tidy-row [align]="'center'" class="extra-top-padding">
              <tidy-text
                [title]="'Why TIDY?'">
              </tidy-text>
            </tidy-row>

            <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px" *ngIf="showContactConcierge">
              <tidy-row>
                <ion-row>
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/concierge.svg'"
                      style="width:40px;height:50px">
                    </tidy-image>
                  </tidy-row>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="'Concierge Phone Support'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'We\'re happy to answer any questions! '">
                      </tidy-text>
                      <tidy-text
                        [body]="'Schedule a Call'"
                        (action)="goToScheduleCallPage()"
                        class="link">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card>

            <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
              <tidy-row>
                <ion-row>
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/waving-user.svg'"
                      style="width:40px;height:50px">
                    </tidy-image>
                  </tidy-row>
                  <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Top Performers'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Use certified Pros to only get the best.'">
                    </tidy-text>
                  </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card>

            <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
              <tidy-row>
                <ion-row>
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/satisfaction-guarantee.svg'"
                      style="width:40px;height:50px">
                    </tidy-image>
                  </tidy-row>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="'Satisfaction Guaranteed'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'Protect yourself with our Satisfaction Guarantee.'">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card>

            <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
              <tidy-row>
                <ion-row>
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/book-job.svg'"
                      style="width:40px;height:50px">
                    </tidy-image>
                  </tidy-row>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="'Instantly Book'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="'View availability instantly & manage the job online.'">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card>

            <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
              <tidy-row>
                <ion-row>
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/5-star.svg'"
                      style="width:40px;height:50px">
                    </tidy-image>
                  </tidy-row>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="'Trusted by Thousands'">
                      </tidy-text>
                    </tidy-row>
                      <tidy-text
                        [body]="'Over 50,000 people have used TIDY. '">
                      </tidy-text>
                      <tidy-text
                        [body]="'See Reviews'"
                        (action)="goToReviews()"
                        class="link">
                      </tidy-text>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card>

            <tidy-row [align]="'center'">
              <tidy-text
                [body]="'*All bookings subject to our '">
              </tidy-text>
              <tidy-text
                [body]="'terms'"
                (action)="goToTerms()"
                class="link">
              </tidy-text>
            </tidy-row>
          </ng-container>

          <tidy-row *ngIf="hasRequests">
            <tidy-text
              [header]="'Requests'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let card of cards">

            <!--CRAF Template-->
            <tidy-card *ngIf="card?.template === 'pending_availability_request'">

              <!--CRAF Template / Pending request_type-->
              <ng-container *ngIf="card?.template_data?.request_type === 'pending'">
                <tidy-row class="extra-bottom-padding vertical-align-center">
                  <tidy-image
                    [src]="'assets/icon/searching.svg'"
                    class="header-size wiggle-animation">
                  </tidy-image>
                  <tidy-text
                    [header]="'Request Pending'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    [body]="requests.getProRequestBody(card)">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding" *ngFor="let day of requests.buildRequestTimeList(card?.template_data?.availability_request?.request_times)">
                  <tidy-text
                    [body]="day">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngIf="card?.template_data?.job?.is_private">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-button
                      [text]="'Assign Pro'"
                      [action]="goToChangePro.bind(this, card)"
                      class="primary">
                    </tidy-button>
                  </tidy-row>
                </ng-container>
                <tidy-row class="extra-bottom-padding">
                  <tidy-button
                    [text]="'Change Request'"
                    [action]="editProRequest.bind(this, card?.template_data?.availability_request, card?.template_data?.homekeeper, card?.template_data?.availability_request?.request_times)"
                    class="secondary">
                  </tidy-button>
                </tidy-row>
                <tidy-row [align]="'center'" class="extra-top-padding">
                  <tidy-text
                    (action)="cancelProRequest(card?.template_data?.availability_request)"
                    [body]="'Cancel Request'"
                    class="link">
                  </tidy-text>
                </tidy-row>
              </ng-container>

              <!--CRAF Template / Countered request_type-->
              <ng-container *ngIf="card?.template_data?.request_type === 'countered'">
                <tidy-row class="extra-bottom-padding vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/request-counter.svg'"
                    class="title-size">
                  </tidy-image>
                  <tidy-text
                    [header]="card?.template_data?.homekeeper?.homekeeper_name"
                    [translate]="false">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [header]="'Proposed Other Times'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    [body]="card?.template_data?.homekeeper?.homekeeper_name"
                    [translate]="false">
                  </tidy-text>
                  <tidy-text
                    [body]="' indicated that they were not available for the times you requested, but provided other times they are available.'">
                  </tidy-text>
                </tidy-row>
                <tidy-button
                  [text]="'View Other Times'"
                  [action]="goToRequestProCounter.bind(this, card?.template_data?.availability_request, card?.template_data?.homekeeper)"
                  class="primary">
                </tidy-button>
              </ng-container>
            </tidy-card>
          </ng-container>

          <tidy-row [align]="'center'" *ngIf="showContactConcierge && (plans?.length !== 0 || cards?.length !== 0)">
            <tidy-text
              [body]="'Need Help? '">
            </tidy-text><br>
            <tidy-text
              [actionBody]="'Schedule a Call'"
              (action)="goToScheduleCallPage()"
              class="link">
            </tidy-text>
          </tidy-row>

          <!--Plans-->
          <ng-container *ngIf="plans?.length > 0">
            <tidy-row>
              <tidy-text
                [header]="'Plans'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="plans[0]?.plan_frequency !== 'once'">
              <tidy-card>
                <tidy-card-button (action)="goToPlansPage()" [hideArrow]="true">
                  <tidy-row>
                    <tidy-grid>
                      <ion-row>
                        <ion-col size="2">
                          <div style="margin: auto">
                            <tidy-image
                              [src]="'assets/img/icons/blue_sparkle.svg'"
                              style="width:35px">
                            </tidy-image>
                          </div>
                        </ion-col>
                        <ion-col size="10">
                          <tidy-text
                            [body]="plans[0]?.formatted_plan_with_weekdays_and_times | titlecase">
                          </tidy-text>
                          <ng-container *ngIf="plans?.length > 1">
                            <tidy-text
                              [body]="' + ' + (plans?.length - 1)">
                            </tidy-text><span>&nbsp;</span>
                            <tidy-text
                              [body]="'More'">
                            </tidy-text>
                          </ng-container><br>
                          <tidy-text
                            class="link"
                            [actionBody]="(plans?.length > 1) ? 'Edit Plans' : 'Edit Plan'">
                          </tidy-text>
                        </ion-col>
                      </ion-row>
                    </tidy-grid>
                  </tidy-row>
                </tidy-card-button>
              </tidy-card>
            </ng-container>
            <tidy-row *ngIf="plans[0]?.plan_frequency == 'once' || hasAllOneTimePlans">
              <tidy-text
                *ngIf="plans[0]?.plan_frequency == 'once'"
                [body]="'One Time Plan '">
              </tidy-text>
              <tidy-text
                *ngIf="plans[0]?.plan_frequency !== 'once'"
                [body]="'No Recurring Plans '">
              </tidy-text>
              <tidy-text
                (action)="goToPlansPage()"
                [actionBody]="'Edit'"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-alert *ngIf="!hasAllOneTimePlans && !hasJobs">
              <tidy-text
                [body]="'You still have a plan scheduled. We’ll continue to book jobs automatically per your plan. If you want to edit your plan click <b>“Edit Plan”</b> above.'">
              </tidy-text>
            </tidy-alert>
          </ng-container>

          <ng-container *ngIf="hasOnlyReservations">
            <tidy-button
              [text]="'Book Job(s)'"
              [action]="bookJob.bind(this, 'add_job')"
              class="primary">
            </tidy-button>

            <tidy-row>
              <tidy-text
                [header]="'Reservations'">
              </tidy-text>
            </tidy-row>
          </ng-container>

          <ng-container *ngIf="cards?.length > 0">
            <tidy-row *ngIf="!hasOnlyReservations">
              <tidy-text
                [header]="'Jobs'">
              </tidy-text>
            </tidy-row>

            <tidy-alert *ngIf="hasOnlyCrafRequests">
              <tidy-row>
                <tidy-text
                  [body]="'No jobs booked.'">
                </tidy-text>
              </tidy-row>
            </tidy-alert>

            <tidy-button
              *ngIf="hasOnlyCrafRequests"
              [text]="'Book Job(s)'"
              [action]="bookJob.bind(this, 'add_job')"
              class="primary">
            </tidy-button>

            <!--Alert that appears if you have multiple job request pending-->
            <tidy-alert *ngIf="twoCoops && !(hasReservations || automaticBookingEnabled)">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="'You initiated multiple job requests.  If you do not want multiple jobs please cancel one request.'">
                </tidy-text>
              </tidy-row>
            </tidy-alert>

            <ng-container *ngFor="let card of cards; let index = index">

              <!--Address Alert Template / address_alerts-multiple_cleanings_on_same_day_alert ID-->
              <tidy-alert *ngIf="card?.template == 'address_alerts' && card?.id == 'address_alerts-multiple_cleanings_on_same_day_alert'">
                <tidy-row>
                  <tidy-image
                    [src]="'assets/img/alert.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'You have multiple jobs scheduled for'">
                  </tidy-text><span>&nbsp;</span>
                  <span *ngFor="let date of card?.template_data?.alert_data?.dates; let last=last">
                    <tidy-text
                      [body]="date | parseDate: 'M/D' +
                      (last ? '.' : ',')">
                    </tidy-text><span>&nbsp;</span>
                    <ng-container *ngIf="last">
                      <tidy-text
                        [body]="'If you do not want multiple jobs on that day please cancel one.'">
                      </tidy-text>
                    </ng-container>
                  </span>
                </tidy-row>
              </tidy-alert>

              <!--Alert that appears when automatic booking is on-->
              <tidy-alert *ngIf="card?.fourWeekAlert">
                <tidy-row>
                  <tidy-text
                    [body]="'TIDY will automatically send a job request to your list of Pros once a reservation is within ' + numberOfDaysToBookWithin + ' days of the check in date.'">
                  </tidy-text>
                  <tidy-text
                    [body]="'Learn More'"
                    class="link"
                    (action)="learnMoreReservations()">
                  </tidy-text>
                </tidy-row>
              </tidy-alert>

              <!--Job Request Template / Pending Status-->
              <tidy-card *ngIf="card.template == 'job_request' && card.template_data.job_request_status == 'pending'">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/icon/searching.svg'"
                    class="header-size wiggle-animation">
                  </tidy-image>
                  <tidy-text
                    [header]="'Request Pending'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    [body]="'Your '">
                  </tidy-text>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.bookable_service?.name)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'request is searching for a pro matching your preferences. When scheduled, we will message you.'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/svg/play-circle-outline.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Earliest Start:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.start_date_no_earlier_than | customdate:'ddd M/D') + ' ' + (card?.template_data?.booking?.start_time_no_earlier_than | customtime:'h:mma')">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="vertical-align-center extra-bottom-padding">
                  <tidy-image
                    [src]="'assets/svg/stop-circle-outline.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Latest Finish:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.end_date_no_later_than | customdate:'ddd M/D') + ' ' + (card?.template_data?.booking?.end_time_no_later_than | customtime:'h:mma')">
                  </tidy-text>
                </tidy-row>
                <tidy-row *ngIf="card?.template_data?.potential_homekeepers?.length > 0">
                  <tidy-alert class="light-green-background">
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/alert-circle-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Pros have also bid as low as <b>' + (getSmallestQuote(card?.template_data?.potential_homekeepers) | tcurrency) + '</b> for this job.'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [actionBody]="'View Bids'"
                        class="link"
                        (action)="goToBidsPage(card, card?.template_data?.booking)">
                      </tidy-text>
                    </tidy-row>
                  </tidy-alert>
                </tidy-row>
                <tidy-row *ngIf="card?.template_data?.notification_history[0]?.text == 'We are starting to contact pros in order of your priority list. Please check back soon for a full history.'" class="extra-bottom-padding">
                  <tidy-text
                    [body]="'We are starting to contact pros in order of your priority list. Please check back soon for a full history.'">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngIf="schedule.showNotificationHistory(card?.template_data?.notification_history)">
                  <tidy-row style="margin-left: -10px; margin-right: -10px">
                    <tidy-notification-history
                      [items]="card?.template_data?.notification_history"
                      (viewProAction)="goToProPage($event)"
                      (viewHistoryDetails)="goToNotificationDetailsPage($event)"
                      [notificationHistory]="notificationHistory"
                      (selectionChange)="checkWaterfallHistory($event, card)">
                    </tidy-notification-history>
                  </tidy-row>
                </ng-container>
                <tidy-row>
                  <tidy-button
                    [text]="'Change Request'"
                    [action]="editWaterfallRequest.bind(this, card)"
                    class="secondary">
                  </tidy-button>
                </tidy-row>
                <tidy-row *ngIf="!card?.template_data?.job?.service_type_details?.name?.includes('2 Pros')">
                  <tidy-button
                    [text]="'Assign a Pro'"
                    [action]="goToChangePro.bind(this, card)"
                    class="secondary">
                  </tidy-button>
                </tidy-row>
                <tidy-row [align]="'center'"  class="extra-top-padding">
                  <tidy-text
                    (action)="cancelWaterfallRequest(card?.template_data?.booking?.id, card?.template_data?.job?.id)"
                    [body]="'Cancel Request'"
                    class="link">
                  </tidy-text>
                </tidy-row>
              </tidy-card>

              <!--Job Request Template / Failed Status-->
              <tidy-card *ngIf="card.template == 'job_request' && card.template_data.job_request_status == 'failed'">
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/img/alert.svg'"
                    class="header-size">
                  </tidy-image>
                  <tidy-text
                    [header]="'No Pro Accepted Your Request'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    [body]="'Your'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.bookable_service?.name)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'request was not accepted.'">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/svg/play-circle-outline.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Earliest Start:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.start_date_no_earlier_than | customdate:'ddd M/D') + ' ' + (card?.template_data?.booking?.start_time_no_earlier_than | customtime:'h:mma')">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="vertical-align-center">
                  <tidy-image
                    [src]="'assets/svg/stop-circle-outline.svg'"
                    class="body-size">
                  </tidy-image>
                  <tidy-text
                    [body]="'Latest Finish:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(card?.template_data?.booking?.end_date_no_later_than | customdate:'ddd M/D') + ' ' + (card?.template_data?.booking?.end_time_no_later_than | customtime:'h:mma')">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngIf="schedule.showNotificationHistory(card?.template_data?.notification_history)">
                  <tidy-row class="extra-top-padding" style="margin-left: -10px; margin-right: -10px">
                    <tidy-notification-history
                      [items]="card?.template_data?.notification_history"
                      (viewProAction)="goToProPage($event)"
                      (viewHistoryDetails)="goToNotificationDetailsPage($event)"
                      [notificationHistory]="notificationHistory"
                      (selectionChange)="checkWaterfallHistory($event, card)"
                      >
                    </tidy-notification-history>
                  </tidy-row>
                </ng-container>
                <tidy-row>
                  <tidy-button
                    [text]="'Request or Book Different Times'"
                    [action]="editWaterfallRequest.bind(this, card)"
                    class="primary">
                  </tidy-button>
                </tidy-row>
                <tidy-row *ngIf="!card?.template_data?.job?.service_type_details?.name?.includes('2 Pros')">
                  <tidy-button
                    [text]="'Assign a Pro'"
                    [action]="goToChangePro.bind(this, card)"
                    class="secondary">
                  </tidy-button>
                </tidy-row>
                <tidy-row>
                  <tidy-button
                    [text]="'Schedule Later'"
                    [action]="cancelWaterfallRequest.bind(this, card?.template_data?.booking?.id, card?.template_data?.job?.id)"
                    class="secondary">
                  </tidy-button>
                </tidy-row>
              </tidy-card>

              <!--Reservation Template-->
              <tidy-alert *ngIf="card?.template === 'guest_reservations'" style="padding:0 15px">
                <tidy-card-button class="no-below-stroke" (action)="goToReservation(card)">
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/calendar-link.svg'"
                      class="header-size">
                    </tidy-image>
                    <tidy-text
                      [header]="(card?.template_data?.check_in_date | customdate:'M/D/YY') +
                      ' - ' +
                      (card?.template_data?.check_out_date | customdate:'M/D/YY')">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [header]="'Reservation'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="vertical-align-center" *ngIf="card?.template_data?.status === 'inactivated_after_check_in'">
                    <tidy-text
                      [body]="'(Cancelled after Check-In)'">
                    </tidy-text>
                  </tidy-row>
                </tidy-card-button>
              </tidy-alert>

              <!--Feedback Template-->
              <tidy-card *ngIf="card?.template === 'feedback'">
                <tidy-row class="extra-bottom-padding">
                  <ion-row>
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="card?.template_data?.job?.service_type_details?.service_category?.icon_url"
                        style="width:30px;">
                      </tidy-image>
                    </tidy-row>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="card?.template_data?.booking?.bookable_service?.name | titlecase">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="(card?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                          ' ' +
                          (card?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="'at'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="(card?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
                <ng-container class="extra-bottom-padding" *ngFor="let pro of card?.template_data?.homekeepers; let first = first">
                  <tidy-row class="extra-bottom-padding" [align]="'center'">
                    <tidy-text
                      [title]="'Rate'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [title]="pro?.first_name"
                      [translate]="false">
                    </tidy-text>
                    <tidy-text
                      [title]="'\'s Job'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-grid>
                      <ion-row>
                        <ion-col size="2">
                        </ion-col>
                        <ion-col size="4">
                          <tidy-round-button
                            class="black"
                            [image]="'assets/img/icons/thumb-down.svg'"
                            (action)="needsWork(card, pro)"
                            [label]="'Needs Work'" >
                          </tidy-round-button>
                        </ion-col>
                        <ion-col size="4">
                          <tidy-round-button
                            class="green"
                            [image]="'assets/img/icons/white-heart.svg'"
                            (action)="loveIt(card, pro)"
                            [label]="'Love It'" >
                          </tidy-round-button>
                        </ion-col>
                        <ion-col size="2">
                        </ion-col>
                      </ion-row>
                    </tidy-grid>
                  </tidy-row>
                  <tidy-row class="extra-top-padding" [align]="'center'" [ngClass]="card?.template_data?.homekeepers?.length > 1 && first ? 'extra-bottom-padding' : ''">
                    <tidy-text
                      [body]="'Any negative feedback is anonymized. If'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="pro?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'did not arrive please'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      (action)="reportNoShow(pro?.first_name, card?.template_data?.job?.id, pro?.id)"
                      [body]="'click here'"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                </ng-container>
              </tidy-card>

              <!--Cleaning Template-->
              <tidy-card *ngIf="card?.template == 'cleaning'">

                <!--Service & Date/Time Header-->
                <tidy-card-button (action)="goToJobPage(card)" class="no-below-stroke no-padding">
                  <tidy-row class="no-padding">
                    <ion-row>
                      <tidy-row class="vertical-align-center">
                        <tidy-image
                          [src]="card?.template_data?.job?.service_type_details?.service_category?.icon_url"
                          style="width:30px;">
                        </tidy-image>
                      </tidy-row>
                      <ion-col>
                        <tidy-row>
                          <tidy-text
                            [header]="card?.template_data?.booking?.bookable_service?.name | titlecase">
                          </tidy-text>
                        </tidy-row>
                        <tidy-row>
                          <tidy-text
                            [body]="(card?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                            ' ' +
                            (card?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="'at'">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [body]="(card?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
                          </tidy-text>
                        </tidy-row>
                      </ion-col>
                    </ion-row>
                  </tidy-row>
                </tidy-card-button>

                <!--Cant Access Scenario-->
                <ng-container *ngIf="card?.template_data?.scenario_name === 'hk_initiated_no_access'">
                  <tidy-row class="extra-top-padding vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/alert.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text
                      [title]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [title]="'Needs Help'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'is having trouble accessing your property.  Please reach out to help.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-button
                      [text]="'Contact '
                      + card?.template_data?.homekeepers[0]?.first_name"
                      [action]="contactPro.bind(this, card)"
                      class="primary">
                    </tidy-button>
                  </tidy-row>
                </ng-container>

                <!--Cant Access Confirmed Scenario-->
                <ng-container *ngIf="card?.template_data?.scenario_name === 'hk_confirmed_no_access'">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-divider></tidy-divider>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/alert.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text
                      [title]="'Action Needed'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'confirmed they could not access your property.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Reschedule Job'"
                      [action]="rescheduleJob.bind(this, card)"
                      class="primary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Skip Job'"
                      [action]="skipJob.bind(this, card?.template_data?.booking?.id, card?.template_data?.job?.id)"
                      class="secondary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row class="extra-top-padding" [align]="'center'">
                    <tidy-text
                      [body]="'If you believe'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'was the one who cancelled, please'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      (action)="reportNoShow(card?.template_data?.homekeepers[0]?.first_name, card?.template_data?.job?.id, card?.template_data?.homekeepers[0]?.id)"
                      [body]="'click here'"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                </ng-container>

                <!--Client Refused Service Scenario-->
                <ng-container *ngIf="card?.template_data?.scenario_name === 'hk_reported_client_cancellation'">
                  <tidy-row class="extra-top-padding vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/alert.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text
                      [title]="'Action Needed'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'reported that you cancelled the job.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Book New Time'"
                      [action]="rescheduleJob.bind(this, card)"
                      class="primary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row class="extra-top-padding" [align]="'center'">
                    <tidy-text
                      [body]="'If you believe'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'was the one who cancelled, please'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      (action)="reportNoShow(card?.template_data?.homekeepers[0]?.first_name, card?.template_data?.job?.id, card?.template_data?.homekeepers[0]?.id)"
                      [body]="'click here'"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                </ng-container>

                <!--Marked No Show Scenario-->
                <ng-container *ngIf="card?.template_data?.scenario_name === 'customer_reported_hk_no_showed' || card?.template_data?.scenario_name === 'concierge_reported_hk_no_showed'">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-divider></tidy-divider>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/alert.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text
                      [title]="'Action Needed'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="'You reported that'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="card?.template_data?.homekeepers[0]?.first_name"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'did not show up to the job.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="'We\'re so sorry that they did that!  We are reaching out to find out what happened.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <tidy-text
                      [body]="'Of course you will not be billed for this, which means any credit will remain in your account.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Request Substitute'"
                      [action]="requestSubstitute.bind(this, card?.template_data?.job?.id)"
                      class="primary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Reschedule Job'"
                      [action]="rescheduleJob.bind(this, card)"
                      class="secondary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row>
                    <tidy-button
                      [text]="'Assign a Pro'"
                      [action]="goToChangePro.bind(this, card)"
                      class="secondary">
                    </tidy-button>
                  </tidy-row>
                  <tidy-row [align]="'center'">
                    <tidy-text
                      (action)="skipJob(card?.template_data?.booking?.id, card?.template_data?.job?.id)"
                      [body]="'Skip Job'"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                </ng-container>

                <!--Updates Section-->
                <ng-container *ngIf="showUpdates(card)">
                  <message
                    [message]="card?.template_data?.activity_timelines[0]?.activity_timeline_items[0]"
                    [showJobLink]="false"
                    [addExtraPadding]="false">
                  </message>
                  <ng-container *ngIf="card?.template_data?.activity_timelines[0]?.activity_timeline_items?.length > 1">
                    <tidy-text
                      [helper]="'+ ' + (card?.template_data?.activity_timelines[0]?.activity_timeline_items?.length - 1)">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [helper]="'more updates'">
                    </tidy-text>
                  </ng-container>
                </ng-container>

                <!--Unassigned Scenario-->
                <ng-container *ngIf="card?.template_data?.unassigned">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-divider></tidy-divider>
                  </tidy-row>
                  <tidy-row *ngIf="card?.template_data?.scenario_type === 'unassigned'" class="vertical-align-center extra-bottom-padding">
                    <ng-container *ngIf="card?.template_data?.unassigned?.call_to_action === 'Other Actions (No Action Needed)'; else actionNeeded">
                      <tidy-image
                        [src]="'assets/icon/searching.svg'"
                        class="title-size wiggle-animation">
                      </tidy-image>
                      <tidy-text
                        [title]="'Searching for Substitute'">
                      </tidy-text>
                    </ng-container>
                    <ng-template #actionNeeded>
                      <tidy-image
                        [src]="'assets/img/alert.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-text
                        [title]="'Action Needed'">
                      </tidy-text>
                    </ng-template>
                  </tidy-row>
                  <tidy-row *ngIf="card?.template_data?.scenario_type === 'manual_request'" class="vertical-align-center extra-bottom-padding">
                    <tidy-image
                      [src]="'assets/icon/searching.svg'"
                      class="title-size wiggle-animation">
                    </tidy-image>
                    <tidy-text
                      [title]="card?.template_data?.scenario_name === 'same_day_cleaning_substitute_requested' ?
                      'Searching for Pro' :
                      'Searching for Substitute'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="card?.template_data?.unassigned?.homekeeper" class="extra-bottom-padding">
                    <tidy-text
                      [header]="'Message from'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [header]="card?.template_data?.unassigned?.homekeeper?.first_name + ':'"
                      [translate]="false">
                    </tidy-text><br>
                    <ng-container *ngFor="let text of card?.template_data?.unassigned?.homekeeper?.text">
                      <tidy-text
                        *ngIf="text?.type === 'string'"
                        [body]="text?.value">
                      </tidy-text>
                      <br *ngIf="text?.type === 'br'">
                    </ng-container>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding">
                    <ng-container *ngIf="card?.template_data?.unassigned?.homekeeper !== undefined">
                      <tidy-text
                        [header]="'Message from Concierge:'">
                      </tidy-text><br>
                    </ng-container>
                    <ng-container *ngFor="let text of card?.template_data?.unassigned?.concierge?.text">
                      <tidy-text
                        *ngIf="text?.type === 'string'"
                        [body]="text?.value">
                      </tidy-text>
                      <ng-container *ngIf="text?.type === 'list'">
                        <ng-container *ngFor="let item of text?.value">
                          <tidy-text
                            *ngIf="item?.type === 'string'"
                            [body]="item?.value">
                          </tidy-text>
                          <br *ngIf="item?.type === 'br'">
                        </ng-container>
                      </ng-container>
                      <br *ngIf="text?.type === 'br'">
                    </ng-container>
                  </tidy-row>
                  <tidy-row class="extra-bottom-padding" [alignLastItemRight]="card?.template_data?.scenario_type === 'manual_request'">
                    <tidy-text
                      [header]="schedule.callToActionText(card.template_data.unassigned.call_to_action)">
                    </tidy-text>
                    <tidy-text
                      *ngIf="card?.template_data?.scenario_type === 'manual_request'"
                      (action)="learnMoreSdc()"
                      [body]="'Learn More'"
                      class="link">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngFor="let button of card?.template_data?.unassigned?.buttons">
                    <tidy-row>
                      <tidy-button
                        [text]="button?.text"
                        [action]="unassignedAction.bind(this, button?.action, card)"
                        [ngClass]="button?.color === 'green' ? 'primary' : 'secondary'">
                      </tidy-button>
                    </tidy-row>
                  </ng-container>
                  <tidy-row>
                    <tidy-button
                      [text]="'Assign a Pro'"
                      [action]="goToChangePro.bind(this, card)"
                      class="secondary">
                    </tidy-button>
                  </tidy-row>
                </ng-container>

              </tidy-card>

            </ng-container>
          </ng-container>
        </ng-container>

      </ng-container>

    </ng-container>
    <ng-container *ngIf="!loaded && !isAllAddressesSelected">
      <tidy-row class="extra-bottom-padding" *ngIf="!windowService?.isDesktopRes && !addressFilterLoaded">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 188px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-row class="extra-bottom-padding">
        <ion-skeleton-text animated style="width: 20%; height: 16px;"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <tidy-grid>
              <ion-row>
                <ion-col size="2">
                  <ion-skeleton-text animated style="width: 50%; height: 20px"></ion-skeleton-text>
                </ion-col>
                <ion-col size="10">
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 22%"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </tidy-grid>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-row>
        <ion-item style="--min-height: 0">
          <ion-skeleton-text animated slot="start" style="width: 14%; height: 16px"></ion-skeleton-text>
          <ion-skeleton-text animated slot="end" style="width: 50%; height: 16px"></ion-skeleton-text>
        </ion-item>
      </tidy-row>

      <tidy-card *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]">
        <tidy-card-button [hideArrow]="true" class="no-below-stroke no-padding">
          <tidy-row class="no-padding">
            <ion-row>
              <tidy-row class="vertical-align-center">
                <ion-skeleton-text animated slot="start" style="width:32px; height:32px"></ion-skeleton-text>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-row class="vertical-align-center">
                    <ion-skeleton-text animated slot="start" style="width:140px"></ion-skeleton-text>
                  </tidy-row>
                </tidy-row>
                <tidy-row>
                  <tidy-row class="vertical-align-center">
                    <ion-skeleton-text animated slot="start" style="width: 155px"></ion-skeleton-text>
                  </tidy-row>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<tidy-fab [isRightSidePanelButton]="true" [disableToggle]="true" *ngIf="isRightSideContent && loaded && ((cards?.length > 0 && !hasOnlyReservations && !hasOnlyCrafRequests && !isAllAddressesSelected) || (isAllAddressesSelected && !windowService?.isDesktopRes))">
  <tidy-fab-button class="main-btn" (click)="onFABButtonClick()">
    <tidy-image src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text largeBody="Add Job" class="white"></tidy-text>
  </tidy-fab-button>
</tidy-fab>

<tidy-fab [isRightSidePanelButton]="false" [top]="contentHeight" [disableToggle]="true" *ngIf="!isRightSideContent && loaded && ((cards?.length > 0 && !hasOnlyReservations && !hasOnlyCrafRequests && !isAllAddressesSelected) || (isAllAddressesSelected && !windowService?.isDesktopRes))" [class.bottom-nav-padding]="!shouldShowAllToDosPage">
  <tidy-fab-button class="main-btn" (click)="onFABButtonClick()">
    <tidy-image src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text largeBody="Add Job" class="white"></tidy-text>
  </tidy-fab-button>
</tidy-fab>

<tidy-bottom-nav activeRoute="/schedule" *ngIf="!shouldShowAllToDosPage">
</tidy-bottom-nav>

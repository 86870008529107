import {
  Component,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReservationPage } from 'src/pages/reservation/reservation';
import { TidyDateFormatPipe } from 'src/shared/pipes/tidy-date.pipe';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'reservations-of-day.html',
  styleUrls: ['./reservations-of-day.scss'],
  selector: 'tidy-reservations-of-day',
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsOfDayPage implements OnChanges, OnInit {
  isRightSideContent = true;
  dialogParams: any;
  propertiesWithEvents: any;
  pageTitle: string;

  constructor(
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {}

  ngOnChanges(changes: SimpleChanges) {}

  async ngOnInit() {
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    const language = localStorage.getItem('language');
    let parsedDate;
    if (language && language !== 'en') {
      const dayName = new TidyDateFormatPipe().transform(this.dialogParams.day.fullDate, 'cccc');
      const translatedDay = new TranslationPipe().transform(dayName);
      const dateFormat = new TidyDateFormatPipe().transform(this.dialogParams.day.fullDate, 'M/d/yy');
      parsedDate = `${translatedDay} ${dateFormat}`;
    } else {
      parsedDate = new TidyDateFormatPipe().transform(this.dialogParams.day.fullDate, 'cccc M/d/yy');
    }
    this.propertiesWithEvents = this.dialogParams.propertiesWithEvents.filter(
      (property) =>
        property?.items?.length > 0 &&
        property?.items?.some((item) => !item?.booking)
    );
    this.addCheckInCheckOutBoolean();
    this.pageTitle = new TranslationPipe().transform('Reservations') +  ` - ${parsedDate}`;
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
  }

  addCheckInCheckOutBoolean() {
    this.propertiesWithEvents.map((property) => {
      property.items.map((item) => {
        const isReservaiton = item?.checkInTime;
        if (isReservaiton) {
          if (item.startDate == this.dialogParams.day.fullDate) {
            item['isCheckIn'] = true;
          } else {
            item['isCheckIn'] = false;
          }
        }
      });
    });
  }

  goToReservation(reservation: any) {
    const addressName =
      reservation?.address?.address_name ||
      reservation?.address?.address1 +
        (reservation?.address?.address2
          ? ' ' + reservation?.address?.address2
          : '');
    const guestReservation = {
      ...reservation,
      check_in_date: reservation?.object?.check_in_date,
      check_out_date: reservation?.object?.check_out_date,
      check_in_time: reservation?.object?.check_in_time,
      check_out_time: reservation?.object?.check_out_time,
    };
    const params = {
      guestReservation,
      address: addressName,
    };
    this.rightSidePanelService.navigateTo(
      'reservation',
      params,
      ReservationPage
    );
  }
}

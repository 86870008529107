import { ActivateMenuGuard } from 'src/providers/guard/activate-menu-guard.guard';
import { AddressForm } from 'src/providers/address-form/address-form';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { Analytics } from 'src/providers/analytics/analytics';
import { Addresses } from '../../providers/customer/addresses';
import { AfterLoginRedirect } from '../../providers/auth/afterLoginRedirect';
import { Auth } from '../../providers/auth/auth';
import { AuthGuard } from '../../providers/guard/auth-guard.guard';
import { AddressChangeService } from 'src/providers/address-change/address-change';
import { AvailableTimesForRange } from '../../providers/calendar/available-times-for-range';
import { Aws } from '../../providers/aws/aws';
import { Booking } from 'src/providers/booking/booking';
import { BookJob } from 'src/providers/book-job/book-job';
import { IntegrationAddedRedirectGuard } from 'src/providers/guard/integration-added-redirect.guard';
import { CalendarDay } from '../../providers/calendar/calendar-day';
import { Card } from '../../providers/card/card';
import { Client } from '../../providers/client/client';
import { Communication } from 'src/providers/communication/communication';
import { GiftCode } from '../../providers/gift-code/gift-code'
import { Cleaning } from '../../providers/cleaning-cards/cleaning';
import { CleaningCards } from '../../providers/cleaning-cards/cleaning-cards';
import { CleaningTypes } from '../../providers/cleaning-types/cleaning-types';
import { CreditCard } from 'src/providers/credit-card/credit-card';
import { Customer } from '../../providers/customer/customer';
import { CleaningsRooms } from '../../providers/customer/cleanings/rooms/cleanings-rooms';
import { Customers } from '../../providers/customers/customers';
import { Dashboard } from '../../providers/dashboard/dashboard';
import { DisableMenuGuard } from 'src/providers/guard/disable-menu-guard.guard';
import { EmployerIntegrationsProvider } from '../../providers/employer-integrations/employer-integrations';
import { FeatureExperiments } from '../../providers/abtest/feature-experiments';
import { FeedbackCleaning } from '../../providers/feedback/feedback';
import { ParkingReimbursement } from 'src/providers/parking-reimbursement/parking-reimbursement';
import { FirstAvailableTimes } from '../../providers/calendar/first-available-times';
import { GenerateUuid } from '../../providers/uuid/uuid';
import { CookieProvider } from '../../providers/cookie/cookie';
import { GoogleGeocode } from '../../providers/google-geocode/google-geocode';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { HttpClientCustom } from '../../providers/custom/http-client';
import { Instructions } from '../../providers/instructions/instructions';
import { Launchdarkly } from '../../providers/abtest/launchdarkly';
import { Localstorage } from '../../providers/localstorage/localstorage';
import { Logger } from '../../providers/logger';
import { Me } from '../../providers/me/me';
import { UserService } from 'src/providers/user/user';
import { Menu } from '../../providers/menu/menu';
import { MyHouse } from '../../providers/my-house/my-house';
import { Homekeeper } from '../../providers/homekeeper/homekeeper';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { PhotoNotes } from '../../providers/photo-notes/photo-notes';
import { PlanData } from '../../providers/plans/plan-data';
import { PlanFrequency } from '../../providers/plans/plan-frequency';
import { Proposals } from 'src/providers/proposals/proposals';
import { ClientHub } from 'src/providers/client-hub/client-hub';
import { Pros } from '../../providers/pros/pros';
import { RecoverAccountService } from 'src/providers/recover-account/recover-account';
import { Requests } from '../../providers/requests/requests';
import { SessionSteps } from '../../providers/auth/sessionSteps';
import { StaticCompanyText } from '../../providers/company/static-company-text'
import { StaticData } from '../../providers/static-data/static-data';
import { StripeProvider } from '../../providers/stripe/stripe-provider';
import { SubstituteResquest } from '../../providers/substitute-request/substitute-request';
import { Schedule } from 'src/providers/schedule/schedule';
import { TeamService } from 'src/providers/team/team';
import { Tips } from 'src/providers/tips/tips';
import { Util } from '../../shared/util/util';
import { TidySession } from '../../providers/tidy-session/tidy-session';
import { LostItem } from '../../providers/lost-item/lost-item';
import { ToDos } from '../../providers/to-dos/to-dos';
import { Webhooks } from '../../providers/webhooks/webhooks';
import { Concierge } from '../../providers/concierge/concierge';
import { Logs } from '../../providers/logs/logs';
import { Invoices } from '../../providers/invoices/invoices';
import { Genome } from 'src/providers/genome/genome';
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';
import { AccountTypeGuard } from 'src/providers/guard/account-type.guard';
import { Integrations } from 'src/providers/integrations/integrations';

import { Camera } from "@ionic-native/camera/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ReportIssue } from '../../providers/report-issue/report-issue';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Provider } from '@angular/core';
import { Workflows } from 'src/providers/workflows/workflows';
import { ThumbtackProvider } from 'src/providers/thumbtack/thumbtack.provider';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { MaintenancePlans } from 'src/providers/maintenance-plans/maintenance-plans';
import { PastJobPdfProvider } from 'src/pages/more/past-job-pdf/past-job-pdf';
import { HeaderProvider } from 'src/providers/header/header';
import { LocationProvider } from 'src/providers/location/location';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { GoalsProvider } from 'src/providers/goals/goals';

export const providers: Array<Provider> = [
  ActivateMenuGuard,
  AddressForm,
  Analytics,
  Addresses,
  AfterLoginRedirect,
  Auth,
  AccountTypeGuard,
  AuthGuard,
  AddressChangeService,
  AvailableTimesForRange,
  Aws,
  Booking,
  BookJob,
  IntegrationAddedRedirectGuard,
  CalendarDay,
  Camera,
  Card,
  Client,
  GiftCode,
  Cleaning,
  CleaningCards,
  CleaningTypes,
  CreditCard,
  Customer,
  CleaningsRooms,
  Customers,
  Dashboard,
  DisableMenuGuard,
  EmployerIntegrationsProvider,
  FeatureExperiments,
  FeedbackCleaning,
  ParkingReimbursement,
  FirstAvailableTimes,
  GenerateUuid,
  CookieProvider,
  GoogleGeocode,
  GuestReservations,
  HttpClientCustom,
  InAppBrowser,
  Instructions,
  Keyboard,
  ReportIssue,
  Launchdarkly,
  Localstorage,
  Logger,
  LostItem,
  Me,
  UserService,
  Menu,
  MyHouse,
  MultipleCountryService,
  Homekeeper,
  OneSignal,
  PhotoNotes,
  PlanData,
  PlanFrequency,
  Proposals,
  ClientHub,
  Communication,
  Integrations,
  PastJobPdfProvider,
  Pros,
  RecoverAccountService,
  Requests,
  SessionSteps,
  SocialSharing,
  SplashScreen,
  StaticCompanyText,
  StaticData,
  StatusBar,
  StripeProvider,
  SubstituteResquest,
  Schedule,
  TeamService,
  Tips,
  TidySession,
  ToDos,
  Webhooks,
  Logs,
  Invoices,
  Util,
  Concierge,
  Genome,
  GenomeMaps,
  Workflows,
  ThumbtackProvider,
  RightSidePanelService,
  HeaderProvider,
  MaintenancePlans,
  LocationProvider,
  PaymentMethodsProvider,
  OnboardingProvider,
  GoalsProvider
]

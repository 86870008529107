import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-fab-button',
  template: `
    <button
      expand="block"
      mat-raised-button
      tappable
      type="submit" block
      class="fab-button"
    >
    <ng-content></ng-content>
    </button>
    `,
  styleUrls: ['fab-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FabButtonComponent {

  @Input() text: string;
}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { AddProPage } from '../my-pros/add-pro/add-pro';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { JobCard } from 'src/models/schedule.model';

@Component({
  templateUrl: 'why-add-private-pros.html',
  encapsulation: ViewEncapsulation.None
})

export class WhyAddPrivateProsPage implements OnInit {

  salesCards: any;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('No Private Pros Added');
    this.loadData();
  }

  loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.salesCards = this.getSalesCards();
    this.rightSidePanelService.setDialogLoading(false);
  }

  getSalesCards() {
    return [
      {
        title: 'Manage Bookings',
        text: 'You and your Pro can reschedule, add To-Dos, notes, and more.',
        image: 'assets/img/calendar.svg'
      },
      {
        title: 'Collect Payment',
        text: 'Pros send invoices that you can easily pay in 1 tap.',
        image: 'assets/img/credit-card.svg'
      },
      {
        title: 'Use Your Pros or New Pros',
        text: 'Request a job and we\'ll contact Pros in order of the priority you set.',
        image: 'assets/img/pros.svg'
      },
      {
        title: 'Easy & Free for Them',
        text: 'It takes just a few minutes for them to create an account and see your jobs.',
        image: 'assets/img/free.svg'
      }
    ];
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async goToAddPros() {
    const job: JobCard = await this.getParam('job');
    const params = {
      cameFromAssignPro: true,
      bookingId: job.template_data.booking.id,
    }
    const url = 'add-pro';
    const component = AddProPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

}

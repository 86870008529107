import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'select-restocking-option',
  templateUrl: './select-restocking-option.component.html',
  styleUrls: ['./select-restocking-option.component.scss']
})

export class SelectRestockingOptionOnboardingComponent {

  constructor(
    public onboardingProvider: OnboardingProvider
  ) {}

  goToCreateConciergeAction() {
    this.onboardingProvider.page = 'createConciergeAction';
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.priorPages.push('inventoryAutomation');
  }

  goToUploadInventoryList() {
    this.onboardingProvider.page = 'restocking';
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.priorPages.push('inventoryAutomation');
  }

} 
import { Injectable } from '@angular/core';
import { HttpClientCustom } from 'src/providers/custom/http-client';
import { RecoverAccountModel } from 'src/models/recover-account.model';
import { Util } from 'src/shared/util/util';
@Injectable({
  providedIn: 'root'
})
export class RecoverAccountService {

  constructor(
    private httpClient: HttpClientCustom,
    private util: Util
  ) {}

  recoverUserName(params: RecoverAccountModel) {
    const url = 'api/v1/customer/recover-email';

    const snakeCaseParams = this.util.camelKeysToSnakeKeys(params);
    return this.httpClient.post(url, snakeCaseParams);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TranslationService {
  
  private _translations: any;

  get translations(): any {
    return this._translations;
  }

  set translations(value: any) {
    this._translations = value;
  }
  
}
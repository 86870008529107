<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'No Private Pros Added'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Please add a private pro to assign this job.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'There\'s no action needed from them for you to assign them. If they choose, they can view and manage the job in their own app <i>TIDY for Pros</i>.'">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [text]="'Add & Assign Pro'"
      [action]="goToAddPros.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-row [align]="'center'" class="extra-top-padding">
      <tidy-text
        [title]="'Why Add Pros?'">
      </tidy-text>
    </tidy-row>

    <tidy-alert style="padding: 15px 15px 15px 10px" *ngFor="let card of salesCards; let last = last; let first = first">
      <tidy-grid>
        <tidy-row>
          <ion-row>
            <ion-col size="2" class="vertical-align-center">
              <div style="margin: auto">
                <tidy-image
                  [src]="card?.image"
                  style="width:35px">
                </tidy-image>
              </div>
            </ion-col>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="card?.title">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="card?.text">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-grid>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

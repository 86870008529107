import { Injectable } from '@angular/core';

import { HttpClientCustom } from 'src/providers/custom/http-client';
import { ParkingReimbursementRequest } from 'src/shared/models/parking-reimbursement-request';

@Injectable()
export class ParkingReimbursement {
  constructor(
    private httpClient: HttpClientCustom
  ) { }

  listRequests(jobId: number): Promise<ParkingReimbursementRequest[]> {
    const url = `api/v1/customer/cleanings/${jobId}/parking-reimbursements`;
    return this.httpClient.get(url);
  }

  showRequest(jobId: number, parkingReimbursementRequestId: number): Promise<ParkingReimbursementRequest> {
    const url = `api/v1/customer/cleanings/${jobId}/parking-reimbursements/${parkingReimbursementRequestId}`;
    return this.httpClient.get(url);
  }

  responseRequest(jobId: number, parkingReimbursementRequestId: number, status: string): Promise<ParkingReimbursementRequest> {
    const url = `/api/v1/customer/cleanings/${jobId}/parking-reimbursements/${parkingReimbursementRequestId}/response`;
    return this.httpClient.put(url, { status });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Create Password'"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="recoverPasswordForm">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row class="field-bottom-padding field-top-padding">
          <tidy-input
            formControlName="password"
            [form]="recoverPasswordForm.controls.password"
            [label]="'Password'"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            [type]="'password'"
            [errorMessage]="'Please enter a password'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>

        <tidy-row>
          <tidy-input
            formControlName="confirm_password"
            [form]="recoverPasswordForm.controls.confirm_password"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            [type]="'password'"
            [errorMessage]="'Please enter a password'"
            [label]="'Confirm password'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [text]="'Save Password'"
        [action]="onSubmit.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message [text]="errorMessage"></tidy-error-message>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customtime'
})
export class CustomTimePipe implements PipeTransform {
  transform(value: string, format: string, type: string = null): string {
    return moment(value, 'HH:mm:ss').format(format);
  }
}

import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'select-maintenance-option-onboarding',
  templateUrl: './select-maintenance-option-onboarding.html',
  styleUrls: ['./select-maintenance-option-onboarding.scss']
})
export class SelectMaintenanceOptionOnboarding {
  constructor(
    public onboardingProvider: OnboardingProvider
  ) {}
} 
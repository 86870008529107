import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { Logger } from '../logger';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { DateTime } from 'luxon';
import { LocationProvider } from '../location/location';

const GUMLET_URL = 'https://apps-tidy-images.gumlet.io';
const AWS_S3_URL = 'https://samantha-file-uploads.s3.us-west-2.amazonaws.com';

export interface S3ObjectMetadata {
  latitude: string;
  longitude: string;
  date: string;
  timezone: string;
  address: string;
}

export enum BeforeAfterMediaType {
  VIDEO = 'video',
  PHOTO = 'photo'
}

export interface S3UploadResponse {
  Bucket: string,
  Key: string,
  Location: string
};

@Injectable()
export class Aws {

  private RETRY_DELAY = 30000;

  constructor(
    private logger: Logger,
    private locationProvider: LocationProvider
  ) {}

  async saveJobMediaToAws(MediaData, mediaType: string, url: string, file?: any, exif?: any, retries = 0) {
    try {
      if (mediaType == BeforeAfterMediaType.VIDEO) {
        return await this.uploadVideoToS3(MediaData, url, file, exif);
      }

      return await this.uploadImageToS3(MediaData, url, file, exif);
    } catch (err) {
      if (retries < 3) {
        setTimeout( () => this.saveJobMediaToAws(MediaData, mediaType, url, file, retries + 1), this.RETRY_DELAY );
      } else {
        this.logger.error(err, 'Error trying to upload photo.');
      }
    }
  }

  parseCoordinate(coordString: string): string {
    if (!coordString) {
      return null;
    }
    const [degrees, minutes, seconds] = coordString.split(',').map((part) => {
      const [numerator, denominator] = part.split('/').map(Number);
      return numerator / denominator;
    });

    return (degrees + minutes / 60 + seconds / 3600).toString();
  }

  getLatitude(exif) {
    try {
      if (exif?.latitude) {
        return exif?.latitude?.toString();
      }
      if (exif?.GPS?.Latitude) {
        return exif?.GPS?.Latitude?.toString();
      }
      if (this.parseCoordinate(exif?.GPSLatitude)) {
        return this.parseCoordinate(exif?.GPSLatitude);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getLongitude(exif) {
    try {
      if (exif?.longitude) {
        return exif?.longitude?.toString();
      }
      if (exif?.GPS?.Longitude) {
        return exif?.GPS?.Longitude?.toString();
      }
      if (this.parseCoordinate(exif?.GPSLongitude)) {
        return this.parseCoordinate(exif?.GPSLongitude);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getDate(exif, file) {
    try {
      if (exif?.CreateDate) {
        return exif?.CreateDate?.toISOString();
      }
      if (exif?.DateTimeOriginal) {
        return DateTime.fromFormat(
          exif?.DateTimeOriginal,
          'yyyy:MM:dd HH:mm:ss'
        )?.toISO();
      }
      if (file?.modifiedAt || exif?.modifiedAt) {
        return DateTime.fromMillis(file?.modifiedAt || exif?.modifiedAt)?.toISO();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // INFO: All these different handlings of properties is because of the different sources of data we can get from the platforms.
  async getFileMetadata(file?: any, exif?: any) {
    try {
      const date = new Date();
      let metadata: any = {
        date: file?.lastModifiedDate?.toISOString() || date.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      exif = file?.exif || exif;
      if (exif) {
        console.log('exif', exif);
        const latitude = this.getLatitude(exif);
        const longitude = this.getLongitude(exif);
        const date = this.getDate(exif, file);
        metadata.latitude = latitude;
        metadata.longitude = longitude;
        metadata.date = date;

        if (latitude !== null || longitude !== null || date !== null) {
          return metadata;
        }
      }

      if (metadata.date === null) {
        metadata.date = file?.lastModifiedDate?.toISOString() || date.toISOString();
      }

      if (Capacitor.isNativePlatform()) {
        await Geolocation.requestPermissions();
      }

      await Geolocation.checkPermissions();
      try {
        const deviceLocation = await this.locationProvider.get();
        return {
          ...metadata,
          latitude: deviceLocation?.latitude?.toString() || null,
          longitude: deviceLocation?.longitude?.toString() || null,
        };
      } catch (error) {
        console.error(error);
        return metadata;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private filterMetadata(metadata: any): any {
    if (!metadata) return null;

    const filteredMetadata = Object.fromEntries(
      Object.entries(metadata).filter(([_, value]) => value != null && value !== '' && value !== undefined)
    );

    return Object.keys(filteredMetadata).length > 0 ? filteredMetadata : null;
  }

  async uploadFileToS3(fileData, key, contentType, file?: any, exif?): Promise<any> {
    const body = this.dataURItoBlob(fileData, contentType) || fileData;
    const metadata = await this.getFileMetadata(file, exif);
    const params: any = {
      'Bucket': environment.aws_s3_bucket,
      'Key': key,
      'Body': body,
      'Content-Type': contentType,
    };

    const filteredMetadata = this.filterMetadata(metadata);
    if (filteredMetadata) {
      params.Metadata = filteredMetadata;
    }
    console.log('Params sent to S3', params);

    return new Promise<any>((resolve, reject) => {
      this.getS3().upload(params, (err, data) => {
        if (err) {
          console.error('Error on upload to S3', err);
          reject(err);
        }
        resolve(data);
      })
    });
  }

  async getObjectMetadata(objectUrl: string): Promise<S3ObjectMetadata> {
    const awsObjectUrl = this.parseGumletUrl(objectUrl);
    const objectResponse = await fetch(awsObjectUrl);
    const objectHeaders = objectResponse.headers;

    return {
      latitude: objectHeaders.get('x-amz-meta-latitude'),
      longitude: objectHeaders.get('x-amz-meta-longitude'),
      date: objectHeaders.get('x-amz-meta-date'),
      timezone: objectHeaders.get('x-amz-meta-timezone'),
      address: objectHeaders.get('x-amz-meta-address'),
    }
  }

  parseGumletUrl(url: string): string {
    if(!url.includes(GUMLET_URL)) return url;

    return url.replace(GUMLET_URL, AWS_S3_URL);
  }

  uploadImageToS3(picture, key, file?: any, exif?): Promise<any> {
    return this.uploadFileToS3(picture, key, 'image/jpeg', file, exif);
  }

  uploadVideoToS3(picture, key, file?: any, exif?): Promise<S3UploadResponse> {
    return this.uploadFileToS3(picture, key, 'video/mp4', file, exif);
  }

  dataURItoBlob(dataURI, contentType) {
    try {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      return new Blob([new Uint8Array(array)], {type: contentType});
    } catch {
      return null;
    }
  }

  private getS3() {
    return new AWS.S3({
      accessKeyId: environment.aws_access_key_id,
      secretAccessKey: environment.aws_secret_access_key,
      region: environment.aws_s3_region_bucket,
    });
  }

}

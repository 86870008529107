<tidy-card class="card-test">
  <div class="header-row">
    <div>
      <img [src]="pro?.imageUrl" class="background-image" />
    </div>
    <div class="header-content-row">
      <tidy-text [header]="pro?.businessName"></tidy-text>
      <ng-container *ngIf="rating >= 0">
        <div class="rating-row">
          <div>
            <ng-container *ngFor="let star of stars; let i = index">
              <tidy-image
                [src]="i < rating ? 'assets/icon/star-green.png' : 'assets/icon/star-grey.png'">
              </tidy-image>
            </ng-container>
          </div>
          <div>
            <ng-container *ngIf="pro?.rating && pro?.numReviews">
              <tidy-text
              [smallBody]="pro?.rating?.toFixed(2) + ' - ' + pro?.numReviews"
              class="reviews-text">
            </tidy-text><span>&nbsp;</span>
              <tidy-text
                [smallBody]="'reviews'"
                class="reviews-text">
              </tidy-text>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="pills?.length > 0">
        <div class="pills-row">
          <ng-container *ngFor="let pill of pills">
            <div [class]="pill?.class">
              <tidy-text [smallBody]="pill?.label"></tidy-text>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div>
    <ng-container *ngIf="pro?.businessLocation">
      <div class="pro-info-row">
        <tidy-image src="assets/icon/location-pin.png"></tidy-image>
        <tidy-text [smallBody]="pro?.businessLocation"></tidy-text>
      </div>
    </ng-container>
    <ng-container *ngIf="pro?.numHires">
      <div class="pro-info-row">
        <tidy-image src="assets/icon/trophy-cup-silhouette.png"></tidy-image>
        <tidy-text [smallBody]="'Hired'"></tidy-text><span>&nbsp;</span>
        <tidy-text [smallBody]="pro?.numHires"></tidy-text><span>&nbsp;</span>
        <tidy-text [smallBody]="'times'"></tidy-text>
      </div>
    </ng-container>
    <ng-container *ngIf="pro?.yearsInBusiness">
      <div class="pro-info-row">
        <tidy-image src="assets/icon/briefcase.png"></tidy-image>
        <tidy-text [smallBody]="pro?.yearsInBusiness"></tidy-text><span>&nbsp;</span>
        <tidy-text [smallBody]="'years in business'"></tidy-text>
      </div>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="pro?.featuredReview">
      <div class="featured-review-row">
        <div>
          <tidy-image src="assets/icon/left-quote.png" class="left-quote"></tidy-image>
        </div>
        <div>
          <tidy-text
            [smallBody]="!isReviewExpanded ? (pro?.featuredReview | truncate:90:true) : pro?.featuredReview">
          </tidy-text>
        </div>
        <ng-container *ngIf="pro?.featuredReview?.length > 90">
          <div class="show-more-row" (click)="toggleReview()">
            <tidy-text [smallBody]="!isReviewExpanded ? 'Show More' : 'Show Less'"></tidy-text>
            <tidy-image
              src="assets/icon/arrow-green.png"
              [class]="isReviewExpanded ? 'arrow-green-icon arrow-down' : 'arrow-green-icon arrow-up'">
            </tidy-image>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="pro?.thumbtackUrl">
      <tidy-button
        [text]="'Select & Continue'"
        [class]="'primary thumbtack-button'"
        (click)="onClickSelectPro(pro)">
      </tidy-button>
    </ng-container>
  </div>
</tidy-card>

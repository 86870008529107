<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="isMobileResolution"
    [class]="extraClass"
    [canGoBack]="!windowService.isDesktopRes || backPage?.includes('automatic-booking-property')"
    [title]="'My Pros'"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper
    (contentHeight)="contentHeight = $event">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-desktop-header>
        <tidy-text
          [title]="'My Pros'">
        </tidy-text>
        <ng-container *ngIf="!loaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 440px;
              height: 27px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="loaded">
          <form [formGroup]="searchForm" novalidate action="#">
            <tidy-search-input
              formControlName="search"
              [placeholder]="'Search'"
              [icon]="'assets/svg/search-outline.svg'">
            </tidy-search-input>
          </form>
          <!--<div class="header-button">
            <tidy-image
              [src]="'assets/img/filter-white.svg'"
              style="height: 12px;"
              (action)="goToAddRoomItemPage()">
            </tidy-image>
            <tidy-text
              [headerButton]="'Filter'"
              class="white">
            </tidy-text>
          </div>-->
          <div class="header-button" (click)="goToAddProAllAddresses()">
            <tidy-image
              [src]="'assets/img/add-button-white.svg'"
              style="height: 12px;">
            </tidy-image>
            <tidy-text
              [headerButton]="'Add Pro'"
              class="white">
            </tidy-text>
          </div>
          <div class="header-button" (click)="goToFindNewPro()">
            <tidy-image
              [src]="'assets/img/people.svg'"
              style="height: 12px;">
            </tidy-image>
            <tidy-text
              [headerButton]="'Find New Pro'"
              class="white">
            </tidy-text>
          </div>
        </ng-container>
        <div class="spinner-settings-container">
          <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 15px"></ion-spinner>
          <ion-img
            *ngIf="loaded"
            [src]="'assets/img/gear.svg'"
            class="desktop-title-icon"
            (click)="goToProSettings()">
          </ion-img>
        </div>
      </tidy-desktop-header>

    <ng-container *ngIf="loaded">
      <!--<tidy-desktop-header>
        <tidy-text
          [title]="'My Pros'">
        </tidy-text>
        <div class="desktop-nav-search">
          <form [formGroup]="searchForm" novalidate action="#">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="searchForm">
            </tidy-input>
          </form>
        </div>
        <div class="desktop-nav-button">
          <tidy-button
            [action]="goToAddProAllAddresses.bind(this)"
            [image]="'assets/img/add-pro-white.svg'"
            [text]="'Add Pro'"
            [smallBody]="true">
          </tidy-button>
        </div>
        <div class="desktop-nav-button">
          <tidy-button
            [action]="goToFindNewPro.bind(this)"
            [image]="'assets/img/people.svg'"
            [text]="'Find New Pro'"
            [smallBody]="true">
          </tidy-button>
        </div>
        <div class="spinner-settings-container">
          <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 15px"></ion-spinner>
          <ion-img
            [src]="'assets/img/gear.svg'"
            class="desktop-title-icon"
            (click)="goToProSettings()">
          </ion-img>
        </div>
      </tidy-desktop-header>-->

      <div *ngIf="allPros?.length > 0">
        <tidy-card class="no-padding">
          <tidy-table
            [dataSource]="shownPros"
            [displayedColumns]="allProHeaders"
            [columnslabels]="allProHeaders"
            [allRowIsClickable]="true"
            (cellClick)="onTableColumnClick($event)">
          </tidy-table>
        </tidy-card>
      </div>
      <tidy-alert *ngIf="allPros?.length == 0">
        <tidy-text
          [body]="'You haven\'t added any pros yet.'">
        </tidy-text>
      </tidy-alert>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>
  </ng-container>

  <ng-container *ngIf="!loaded">
    <tidy-card style="padding: 0px; height: 600px; padding: 0px;">
      <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
    </tidy-card>
  </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="!isMobileResolution" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'addPros'">
</tidy-onboarding-modal>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="room?.name ? room?.name : ''"
    [canGoBack]="true"
    [customBack]="'to-dos'"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteRoom()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper (contentHeight)="contentHeight = $event" class="fab-page">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-error-message
        *ngIf="errorMessage?.length"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-card class="no-padding">

        <tidy-card-button
          class="arrow-to-top no-padding"
          [customArrowPositon]="{ top: '18px', right: '15px' }"
          (action)="goToEditRoomInfo()">
          <tidy-row class="vertical-align-center extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding">
            <tidy-image
              *ngIf="room?.type !== 'group'"
              [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="room?.type == 'group' ? 'Card Info' : 'Room Info'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-left-padding extra-right-padding extra-bottom-padding" *ngIf="room?.type !== 'group'">
            <tidy-image
              [src]="'assets/img/information-circle-yellow.svg'"
              class="header-size">
            </tidy-image>
            <ng-container *ngIf="room?.floor !== null">
              <tidy-text
                [body]="'Floor'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="room?.floor">
              </tidy-text>
            </ng-container>
            <tidy-text
              *ngIf="room?.floor === null"
              [body]="'Floor Not Added'"
              class="red">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>

        <div class="slide-container">
          <tidy-carrousel
            [scaleImages]="true"
            *ngIf="room?.photos?.length > 0"
            class="photo-notes-carrousel only-padding-bottom"
          >
            <ng-container *ngFor="let photoNote of room?.photos">
              <tidy-photo-note
                class="full-width there-is-carrousel no-margin"
                *tidyCarrousel
                [caption]="photoNote.photo_note_caption"
                [src]="photoNote.photo_url"
              ></tidy-photo-note>
            </ng-container>
          </tidy-carrousel>
        </div>

        <tidy-row *ngFor="let doNot of room?.notes" class="vertical-align-center extra-left-padding extra-right-padding extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/block.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            class="red"
            [body]="'<b>Do not</b>'">
          </tidy-text>
          <tidy-text
            class="red"
            [body]="doNot?.body">
          </tidy-text>
        </tidy-row>
      </tidy-card>


      <tidy-row [align]="'center'" *ngIf="isRightSideContent">
        <tidy-text
          [body]="'Delete Room'"
          (action)="deleteRoom()"
          class="red link">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="room?.tasks?.length > 0" [alignLastItemRight]="true" class="extra-top-padding" style="margin-bottom: 0px;">
        <tidy-row [alignLastItemRight]="true" class="full-width">
          <tidy-text
            [header]="'To-Dos'">
          </tidy-text>
          <tidy-text
            [body]="'Edit Priorities'">
          </tidy-text>
        </tidy-row>
        <tidy-toggle [toggled]="editPrio" (toggleChanged)="editPrio = !editPrio" class="extra-left-margin"></tidy-toggle>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="room?.tasks?.length === 0">
        <tidy-text
          [body]="'No To-Dos added in'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="room?.name + '.'"
          [translate]="false">
        </tidy-text>
      </tidy-row>

      <tidy-drag-drop
        *ngIf="room?.tasks?.length !== 0"
        dragDropId="favorites-dd"
        [dataList]="room?.tasks"
        emptyListColor="green"
        emptyDisabledListMessage="No To-Dos Added"
        [disabled]="!editPrio"
        (listDropped)="updateToDoOrder($event)">
        <ng-container *ngFor="let toDo of room?.tasks">
          <ng-container *tidyDragDropItem>
            <tidy-card class="no-padding">
              <ng-container *ngIf="!toDo?.task_type?.includes('video')">
                <tidy-card-button
                  class="arrow-to-top no-padding"
                  [hideArrow]="editPrio"
                  (action)="editPrio ? '': goToEditToDo(toDo)"
                  [hideArrow]="toDo?.task_type === 'take_before_photo' || toDo?.task_type === 'take_after_photo'"
                  [customArrowPositon]="{ top: '15px', right: '15px' }">
                  <tidy-row class="extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding" [ngStyle]="editPrio ? {'padding-bottom' : '15px'} : ''">
                    <span [ngStyle]="toDos.important(toDo?.is_important)" class="vertical-align-center" style="width: fit-content">
                      <tidy-image
                        *ngIf="editPrio"
                        [src]="'assets/svg/menu-tidy-green.svg'"
                        class="title-size">
                      </tidy-image>
                      <tidy-image
                        *ngIf="toDo?.is_important && !editPrio"
                        [src]="'assets/img/star.svg'"
                        class="body-size position-absolute">
                      </tidy-image>
                      <tidy-text
                        [class.padding-left-20]="toDo?.is_important"
                        [header]="toDo?.title"
                        [translate]="false">
                      </tidy-text>
                    </span>
                  </tidy-row>
                  <ng-container *ngIf="!editPrio">
                    <tidy-row class="vertical-align-center extra-left-padding extra-right-padding extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/stopwatch.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text
                        [body]="toDo?.estimated_time +
                        ' min'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="toDo?.note !== '' && toDo?.note !== null" class="vertical-align-center extra-left-padding extra-right-padding extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/notes.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="toDo?.note"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="toDo?.room_object" class="vertical-align-middle extra-left-padding extra-right-padding extra-bottom-padding">
                      <img
                        [src]="toDo?.room_object?.model?.category?.default_icon_url"
                        style="width:16px; margin-right: 5px; margin-bottom: -3px">
                      <tidy-text
                        [body]="(!toDo?.room_object?.model?.model || toDo?.room_object?.model?.model === '') ? toDo?.room_object?.model?.category?.name : toDo?.room_object?.model?.name"
                        [translate]="false">
                      </tidy-text>
                      <ng-container *ngIf="toDo?.room_object?.min_quantity">
                        <span>&nbsp;</span>
                        <tidy-text
                          [body]="'(notify if count < '">
                        </tidy-text>
                        <tidy-text
                          [body]="toDo?.room_object?.min_quantity + ')'">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                    <ng-container *ngIf="toDo?.options?.length > 0">
                      <tidy-row class="extra-bottom-padding extra-left-padding extra-right-padding">
                        <tidy-divider></tidy-divider>
                      </tidy-row>
                      <tidy-row class="extra-left-padding extra-right-padding extra-bottom-padding">
                        <tidy-text
                          [body]="'Custom Fields:'">
                        </tidy-text>
                      </tidy-row>
                      <ng-container *ngFor="let field of toDo?.options">
                        <tidy-row class="extra-left-padding extra-right-padding extra-bottom-padding vertical-align-middle">
                          <tidy-image
                            [src]="toDos.getCustomFieldIcon(field?.name)"
                            class="header-size">
                          </tidy-image>
                          <tidy-text
                            [body]="field?.name + ': ' + field?.notes + (field?.allowed_values?.length > 0 ? ': ' : '')">
                          </tidy-text>
                          <ng-container *ngFor="let option of field?.allowed_values; let last = last">
                            <tidy-text
                              [body]="option + (last ? '' : ', ')">
                            </tidy-text>
                          </ng-container>
                        </tidy-row>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tidy-card-button>
                <ng-container *ngIf="!editPrio">
                  <div class="slide-container">
                    <tidy-carrousel
                      [scaleImages]="true"
                      *ngIf="toDo?.photo_notes?.length > 0"
                      class="photo-notes-carrousel only-padding-bottom">
                      <ng-container *ngFor="let photoNote of toDo?.photo_notes">
                        <tidy-photo-note
                          class="full-width there-is-carrousel no-margin"
                          *tidyCarrousel
                          [caption]="photoNote.photo_note_caption"
                          [src]="photoNote.photo_url"
                          ></tidy-photo-note>
                      </ng-container>
                    </tidy-carrousel>
                  </div>
                </ng-container>
              </ng-container>
            </tidy-card>
          </ng-container>
        </ng-container>
      </tidy-drag-drop>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 38%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated="true" style="width: 50%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 42%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 80%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 27%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 58%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 61%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 44%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 76%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 40%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 24%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<tidy-fab [isRightSidePanelButton]="isRightSideContent" *ngIf="loaded">
  <tidy-fab-button class="main-btn">
    <tidy-image src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text largeBody="Add" class="white"></tidy-text>
  </tidy-fab-button>
  <tidy-fab-list>
    <tidy-fab-button
      (click)="goToAddToDo()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="To-Do" class="white"></tidy-text>
    </tidy-fab-button>
    <tidy-fab-button
      (click)="goToAddDoNot()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="Do Not" class="white"></tidy-text>
    </tidy-fab-button>
    <tidy-fab-button
      (click)="takeRoomPhoto()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="Photo" class="white"></tidy-text>
    </tidy-fab-button>
  </tidy-fab-list>
</tidy-fab>

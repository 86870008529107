<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Payment Settings'"
    [canGoBack]="!cantGoBack">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-radio-button
            [label]="'How do you want to pay?'"
            formControlName="setting"
            [form]="form.controls.setting"
            [items]="[{viewValue: 'Charge in advance (save 10%)', value: 'before_cleaning'},{viewValue: 'Charge after job', value: 'after_cleaning'}]"
            class="no-margin-first">
          </tidy-radio-button>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'This setting only applies to new pros that you find via TIDY, not pros you add.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save Changes'"
        [action]="save.bind(this)"
        class="primary">
      </tidy-button>

    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

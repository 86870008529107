import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from './../../util/util';
import { Component, AfterViewInit, OnInit, ElementRef, ViewChild, Renderer2, ViewEncapsulation, HostListener, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConciergeItemsComponent } from 'src/pages/concierge/concierge-items/concierge-items';
import { Events } from 'src/providers/events/events';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ContactInfoPage } from 'src/pages/more/contact-info/contact-info';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe';
import { HeaderProvider } from 'src/providers/header/header';
import { ActivatedRoute } from '@angular/router';
import { AddressChangeService } from 'src/providers/address-change/address-change';
import { Router } from '@angular/router';
import { EmployerService } from "src/providers/employer/employer";

import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { ConciergePage } from 'src/pages/concierge/concierge';
import { EmployerEditEmployeePage } from 'src/pages/employer/edit-employee/edit-employee';

import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingComponent } from '../onboarding/onboarding';
import { ModalController } from '@ionic/angular';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit {

  completedGoals = 0;
  totalGoals = 0;
  showOnboarding: boolean;
  currentRoute: any;
  logo = '/assets/img/bowtie-white.svg';
  form: UntypedFormGroup;
  showHelpDropdown = false;
  showInitialsDropdown = false;
  userName: string;
  isEmployerAccount: boolean;
  imutableSearchItems = [];
  searchItems = [];
  showGlowAnimation: boolean;
  isInitialLoad: boolean;
  initials = '';
  @ViewChild('searchInput', { read: ElementRef }) searchInputEl: ElementRef;
  @ViewChild('helpIconRef') helpIconRef: ElementRef;
  @ViewChild('helpDropdownRef') helpDropdownRef: ElementRef;
  @ViewChild('initialsIconRef') initialsIconRef: ElementRef;
  @ViewChild('initialsDropdownRef') initialsDropdownRef: ElementRef;

  constructor(
    private addressChangeService: AddressChangeService,
    private employerService: EmployerService,
    private util: Util,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    private events: Events,
    private renderer: Renderer2,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private client: Client,
    private cdr: ChangeDetectorRef,
    private pros: Pros,
    public headerProvider: HeaderProvider,
    private router: Router,
    private modalController: ModalController,
    private onboardingProvider: OnboardingProvider
  ) {
    this.form = new UntypedFormGroup({
      search: new UntypedFormControl(''),
    });
  }

  async ngOnInit(): Promise<void> {
    this.isEmployerAccount = localStorage.getItem('accountType') == 'employer';
    this.getLogo();
    this.getUserName();
    this.getSearchItems();
    this.watchSearchItemsChanges();
    this.renderer.listen('document', 'click', (event) => {
      this.onDocumentClick(event);
    });
    const hideOnboarding = await this.storage.retrieve('hideOnboarding')
    this.showOnboarding = !hideOnboarding;
    this.setOnboardinGoalCount();
    this.isInitialLoad = true;
    this.onboardingProvider.getShowOnboardingInHeader().subscribe((show) => {
      if (!this.isInitialLoad) {
        this.setOnboardinGoalCount();
        this.showOnboarding = show;
        if (this.showOnboarding) {
          this.showGlowAnimation = true;
        }
      }
      this.isInitialLoad = false;
    });

  }

  async setOnboardinGoalCount() {
    const goals = await this.storage.retrieve('onboardingGoals');
    if (goals) {
      this.completedGoals = this.getCompletedGoalCount(goals);
      this.totalGoals = goals.length;
    }
  }

  getCompletedGoalCount(goals) {
    return goals.filter((goal) => goal.state == 'done').length;
  }

  async toggleShowOnboarding() {
    this.showOnboarding = !this.showOnboarding;
    await this.storage.save('hideOnboarding', !this.showOnboarding);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.listen('document', 'click', (event) => {
        this.onDocumentClick(event);
      });
    });
  }

  onDocumentClick(event: Event) {
    if (this.searchInputEl && this.searchInputEl.nativeElement) {
      const clickedInside = this.searchInputEl.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.form.patchValue({ search: '' });
        this.cdr.detectChanges();
      }
    }
  }

  onContainerClick(event: Event) {
    event.stopPropagation();
  }

  getLogo(): void {
    const customLogo = localStorage.getItem('custom.logo');
    this.logo =
      customLogo && customLogo != 'nil' && customLogo != 'undefined'
        ? customLogo
        : '/assets/img/bowtie-white.svg';
  }

  async getUserName(): Promise<void> {
    const me = await this.storage.retrieve('me');
    this.userName = me?.current_user?.name || 'User';
    this.initials = this.parseInitials(this.userName);
  }

  parseInitials(fullName): string {
    return fullName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }

  async getSearchItems(): Promise<void> {
    if (this.isEmployerAccount) {
      const employees = await this.employerService.listEmployees();
      this.searchItems = [{
        list: 'EMPLOYEES',
        items: this.parseEmployees(employees)
      }];
      this.imutableSearchItems = this.searchItems;
    } else {
      const addressList = await this.client.getAddresses();
      const parsedAddressList = this.parseAddressList(addressList);
      const pros = await this.pros.getAllPros();
      const parsedPros = this.parsePros(pros);
      this.searchItems = [
        {
          list: 'PROPERTIES',
          items: parsedAddressList
        },
        {
          list: 'PROS',
          items: parsedPros
        },
        {
          list: 'PAGES',
          items: this.headerProvider.sectionsItems
        },
        {
          list: 'HELP ARTICLES',
          items: this.headerProvider.helpArticlesItems
        },
      ];
      this.imutableSearchItems = this.searchItems;
    }
  }

  parseEmployees(employees) {
    const array: any = [];
    employees.map((employee) => {
      array.push({
        title: employee?.source_user_first_name + ' ' + employee?.source_user_last_name,
        image: 'assets/img/user-white.svg',
        isRightSidePanel: true,
        component: EmployerEditEmployeePage,
        params: {
          employee: employee,
          shouldGetEmployeeBalance: true
        },
        link: 'employer-edit-employee'
      })
    });
    return array;
  }

  watchSearchItemsChanges(): void {
    this.form.valueChanges.subscribe((value) => {
      const newSearchItems = this.imutableSearchItems
        .map((item) => {
          return {
            ...item,
            items: item.items.filter((i) =>
              (new TranslationPipe()).transform(i.title).toLowerCase().includes(value.search.toLowerCase()) || i?.searchTerms?.some(term => (new TranslationPipe()).transform(term).toLowerCase().includes(value.search.toLowerCase()))
            ),
          };
        })
        .filter((item) => item.items.length > 0);
      if (JSON.stringify(newSearchItems) !== JSON.stringify(this.searchItems)) {
        this.searchItems = newSearchItems;
      }
    });
  }

  parseAddressList(addressList) {
    return addressList.map((address) => {
      const addressName = this.getPropertyName(address);
      return {
        list: 'Properties',
        title: addressName,
        link: `edit-property/${address.id}`,
        image: 'assets/img/house-white.svg'
      };
    });
  }

  parsePros(pros) {
    const prosItems = pros.map((pro) => {
      return {
        list: 'Pros',
        title: pro?.name ? new TidyAbbreviateLastName().transform(pro.name) : '',
        link: `my-pro/${pro.id}`,
        image: 'assets/img/user-white.svg',
        isRightSidePanel: true,
        component: MyProPage,
        params: {
          proId: pro.id
        }
      };
    });
    return prosItems;
  }

  getPropertyName(address: any): string {
    if (address.address_name) {
      return address.address_name;
    }
    if (address?.address2 && address?.address2 !== '') {
      return `${address.address1}, ${address.address2}`;
    }
    return address.address1;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      this.showHelpDropdown &&
      !this.helpIconRef.nativeElement.contains(event.target) &&
      !this.helpDropdownRef.nativeElement.contains(event.target)
    ) {
      this.showHelpDropdown = false;
    }
    if (
      this.showInitialsDropdown &&
      !this.initialsIconRef.nativeElement.contains(event.target) &&
      !this.initialsDropdownRef.nativeElement.contains(event.target)
    ) {
      this.showInitialsDropdown = false;
    }
  }

  toggleHelpDropdown(event: Event) {
    event.stopPropagation();
    this.showHelpDropdown = !this.showHelpDropdown;
    this.showInitialsDropdown = false;
  }

  navigateToWhatsNew() {
    this.showHelpDropdown = false;
    this.util.openUrl('https://tidy.com/blog?tab=product-updates');
  }

  navigateToDocuments() {
    this.showHelpDropdown = false;
    this.util.openUrl('http://help.tidy.com/');
  }

  navigateToConcierge() {
    this.headerProvider.showConciergePage = true;
    this.showHelpDropdown = false;
    this.openRightSidePanel({}, ConciergePage);
  }

  toggleInitialsDropdown(event: Event) {
    event.stopPropagation();
    this.showInitialsDropdown = !this.showInitialsDropdown;
    this.showHelpDropdown = false;
  }

  navigateToProfile() {
    this.showInitialsDropdown = false;
  }

  logout(): void {
    this.events.publish('logout');
    localStorage.clear();
  }

  goToAddSupportRequest(): void {
    this.showHelpDropdown = false;
    const component = ConciergeItemsComponent;
    const params = {
      title: 'Support Requests',
      type: null,
      isAddingItem: true,
    };
    this.openRightSidePanel(params, component);
  }

  goToContactInfo() {
    this.showInitialsDropdown = false;
    const component = ContactInfoPage;
    this.openRightSidePanel({}, component);
  }

  async openRightSidePanel(params, component) {
    await this.storage.save('shouldUpdateItems', false);
    const dialogParams = await this.storage.retrieve('dialog-params');
    params = {
      ...dialogParams,
      ...params,
    };
    await this.storage.save('dialog-params', params);
    await this.storage.save('dialog-right-side-open', true);
    this.rightSidePanelService.openRightSidePanel(component);
  }

  handleClickItem(item) {
    this.form.patchValue({ search: '' });
    if (item?.link) {
      if (item?.link.includes('https://')) {
        this.util.openUrl(item.link);
      } else {
        if (item.isRightSidePanel) {
          this.storage.save('dialog-right-side-open', true);
          this.storage.save('dialog-params', item?.params);
          this.rightSidePanelService.openRightSidePanel(item?.component);
        } else {
          if (item.link.startsWith('edit-property/') && this.router.url.includes('edit-property')) {
            const addressId = parseInt(item.link.split('/')[1]);
            this.addressChangeService.changeAddress(addressId);
          } else {
            this.navCtrl.navigateForward(item.link);
          }
        }
      }
    }
  }

  async openOnboarding() {
    const modal = await this.modalController.create({
      component: OnboardingComponent,
      cssClass: 'onboarding-modal',
      mode: 'ios',
    });
    await modal.present();
  }

}

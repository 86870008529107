import { Component, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../default-class/value-accessor';
import { LoadingStateService } from '../../../providers/loading-state/loading-state.service';

@Component({
  selector: 'tidy-textarea',
  templateUrl: 'textarea.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true,
    },
  ],
})
export class TextareaComponent extends ValueAccessorBase<any> {
  @Input() label: string;
  @Input() form: UntypedFormControl;
  @Input() icon: string;
  @Input() public activeIcon: string;
  @Input() public submitted: boolean;
  @Input() public errorMessage: string;
  @Input() leftIconAction = null;
  @Input() rightIconAction = null;
  @Input() showBlackButtons: boolean;
  actionLoading: boolean;
  showSideButton: boolean = false;
  isInputEmpty = true;
  focused = false;

  constructor(public loadingState: LoadingStateService) {
    super();
  }

  setFocused(status) {
    this.focused = status;
  }

  keyUpHandle(event) {
    this.isInputEmpty = event.target.value === '';
  }

  async executeRightIconAction() {
    this.errorMessage = '';
    if (this.loadingState.loading || !this.rightIconAction) {
      return;
    }
    try {
      this.loadingState.loading = true;
      this.actionLoading = true;
      await this.rightIconAction();
      this.isInputEmpty = true;
    } catch (err) {
      this.errorMessage = err.error && err.error.message ? err.error.message : err.message;
    } finally {
      this.loadingState.loading = false;
      this.actionLoading = false;
    }
  }

  async executeLeftIconAction() {
    this.errorMessage = '';
    if (this.loadingState.loading || !this.leftIconAction) {
      return;
    }
    try {
      this.loadingState.loading = true;
      await this.leftIconAction();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    } finally {
      this.loadingState.loading = false;
    }
  }
}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { LostItem } from 'src/providers/lost-item/lost-item'
import { Util } from 'src/shared/util/util';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';
import { ContactConciergePage } from '../concierge/contact-concierge/contact-concierge';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'missing-item.html',
  encapsulation: ViewEncapsulation.None
})

export class MissingItemPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  hkJobId: string;
  jobId: string;
  job: any;
  proName: string;
  submitted: boolean;
  privateJob: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private lostItem: LostItem,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      description: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Missing Item');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.job = await this.getParam('job');
    this.jobId = await this.getParam('jobId');
    this.hkJobId = await this.getParam('hkJobId');
    this.proName = await this.getParam('proName');
    this.privateJob = await this.getParam('privateJob');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async confirmMissingItem() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    try {
      await this.lostItem.update(this.jobId, this.hkJobId, this.form.value.description);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const successParams = {
      header: this.proName + ' Notified',
      body: 'We have emailed the other party requesting they call you to help coordinate a pickup. This temporary session will automatically close whenever both parties stop communicating for 48 hours.',
      buttonText: 'Ok',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
    this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
  }

  goToContactConcierge() {
    const params = {
      title: 'Missing Item',
      type: 'support.job_issue',
      metadata: {
        suptype: 'missing_item',
        job_id: this.jobId,
        job_datetime: this.job.job.start_datetime,
        privateJob: this.privateJob
      }
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

  goToTerms() {
    this.util.openUrl('https://www.tidy.com/terms');
  }
}

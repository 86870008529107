import { Component, ViewEncapsulation, Input, HostBinding, SimpleChanges } from '@angular/core';

@Component({
  selector: 'tidy-photo-note',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./photo-note.component.scss'],
  template: `
    <tidy-image imageViewer [width]="imageWidth" [src]="srcWithUniqueQuery" class="full-width"></tidy-image>
    <span class="photo-caption" *ngIf="caption">{{caption}}</span>
  `
})
export class PhotoNoteComponent {
  @Input() caption: string;
  @Input() src: string;
  @Input() isFullWidth = false;
  @HostBinding('class.full-width') setFullWidth = false;
  imageWidth: string;
  srcWithUniqueQuery: string;

  ngOnInit() {
    this.setFullWidth = this.isFullWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.src) {
      this.updateSrcWithUniqueQuery();
    }
  }

  private updateSrcWithUniqueQuery() {
    const isBase64 = this.src.includes('data:image');
    if (isBase64) {
      this.srcWithUniqueQuery = this.src;
      return;
    }
    const timestamp = new Date().getTime();
    this.srcWithUniqueQuery = `${this.src}?v=${timestamp}`;
  }

  public setImageWidth(width: string) {
    this.imageWidth = width
  }
}

import { Injectable } from '@angular/core';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Client } from 'src/providers/client/client';
import { BehaviorSubject } from 'rxjs';
import { HttpClientCustom } from 'src/providers/custom/http-client';

@Injectable({
  providedIn: 'root'
})

export class OnboardingProvider {

    flow: string;
    page: string;
    title: string;
    subTitle: string;
    priorPages: string[];
    showBackButton: boolean;
    hasProperties: boolean;
    showOnboardingInHeader$ = new BehaviorSubject<boolean>(false);
    showOnboardingOnPage$ = new BehaviorSubject<boolean>(false);
    shouldShowAddProperty: boolean;
    description: string;
    buttonText: string;

  constructor(
    private storage: TidyStorage,
    private client: Client,
    private httpClient: HttpClientCustom
  ) {
    this.priorPages = [];
  }

  setOnboardingFlowText() {
    this.title = flowText[this.flow].title;
    this.subTitle = flowText[this.flow].subTitle;
    this.description = flowText[this.flow].description;
    this.buttonText = flowText[this.flow].buttonText;
    if (this.shouldShowAddProperty) {
      this.subTitle = 'Please add your properties to get started.';
    }
  }

  goBack() {
    this.page = this.priorPages[this.priorPages.length - 1];
    this.priorPages.pop();
    if (this.priorPages.length == 0) {
      this.showBackButton = false;
    }
  }

  async checkIfShouldShowOnboarding(flow: string) {
    const hideOnboarding = await this.storage.retrieve('hideOnboarding');
    if (hideOnboarding) {
      return false;
    }
    const onboardingGoals = await this.storage.retrieve('onboardingGoals');
    let showOnboarding = true;
    onboardingGoals.map((goal) => {
      goal.user_goal_steps.map((step) => {
        if (step.flow === flow && step.state == 'done') {
          showOnboarding = false;
        }
      })
    })
    return showOnboarding;
  }

  async checkIfHasProperties() {
    const properties = await this.client.getMoreDetailAddresses(false);
    return properties.length > 0;
  }

  setShowOnboardingInHeader(show: boolean) {
    this.showOnboardingInHeader$.next(show);
  }

  getShowOnboardingInHeader() {
    return this.showOnboardingInHeader$.asObservable();
  }

  setShowOnboardingOnPage(show: boolean) {
    this.showOnboardingOnPage$.next(show);
  }

  getShowOnboardingOnPage() {
    return this.showOnboardingOnPage$.asObservable();
  }

  getGoalsToPickList() {
    const url = 'api/v1/customer/user-goal-types?include=user_goal_type_steps';
    return this.httpClient.get(url);
  }

  saveChosenGoals(goals) {
    const url = 'api/v1/customer/user-goals/create-many';
    return this.httpClient.post(url, goals);
  }

  async getGoalsFromServer() {
    const url = 'api/v1/customer/user-goals?includes=user_goal_type,user_goal_steps,user_goal_type_step';
    const response = await this.httpClient.get(url);
    if (response.length == 0) {
      return;
    }
    const parsedGoals = this.parseGoals(response);
    await this.storage.save('onboardingGoals', parsedGoals);
  }

  async getUnchosenGoalsFromServer() {
    const url = 'api/v1/customer/user-goal-types?includes=user_goal_type_steps&unchosen_only=true';
    let response = await this.httpClient.get(url);
    if (response.length == 0) {
      return;
    }
    response = response.filter((goal) => goal.title !== 'Advanced');
    response = response.map((goal) => {
      goal['user_goal_steps'] = goal.user_goal_type_steps;
      goal['user_goal_type'] = {};
      goal['user_goal_type']['title'] = goal.title;
      return goal;
    })
    const parsedGoals = this.parseGoals(response);
    await this.storage.save('unchosenOnboardingGoals', parsedGoals);
  }

  async parseGoals(goals) {
    goals = this.removeNonV1Steps(goals);
    goals.map((goal) => {
      goal['isOpened'] = false;
      goal['icon'] = this.getGoalIcon(goal?.title || goal.user_goal_type.title);
      let completedSteps = 0;
      goal.user_goal_steps.map((step) => {
        const stepData = this.getStepData(step.title);
        step['page'] = stepData.pageLink;
        step['flow'] = stepData.flow;
        step['openMode'] = stepData?.openMode;
        step['tags'] = stepData?.tag;
        if (step.state === 'done') {
          completedSteps++;
        }
      });
      goal['completedSteps'] = completedSteps;
    });
    return goals;
  }

  removeNonV1Steps(goals) {
    const skipSteps = ['Handle Tasks for You', 'Schedule Automation', 'Notifications', 'Smart Maintenance', 'Restocking Automation'];
    goals.map((goal) => {
      goal.user_goal_steps = goal.user_goal_steps.filter((step) => !skipSteps.includes(step.title));
    })
    return goals;
  }

  getGoalIcon(goalTitle: string) {
    const goalIcons = {
      'Automate Turnovers': 'assets/svg/bolt.svg',
      'Automate Restocking': 'assets/svg/package-open.svg',
      'Automate Maintenance': 'assets/svg/tool.svg',
      'Organize Operations': 'assets/svg/bolt.svg',
      'Automate Upsells': 'assets/svg/dollar-sign.svg',
      'Improve Job Quality': 'assets/svg/check-square.svg',
      'Save Time': 'assets/svg/clock.svg',
    };
    return goalIcons[goalTitle];
  }

  getStepData(stepTitle: string) {
    const stepData = {
      'Integrate': {
        flow: 'selectIntegration',
        pageLink: 'integrations/all',
        tag: {
          text: 'Recommended',
          class: 'recommended-tag',
        }
      },
      'Pro Automation': {
        flow: 'addPros',
        pageLink: 'my-pros',
      },
      'Automatic Booking': {
        flow: 'enableAutomaticBooking',
        pageLink: 'automations/summary',
      },
      'Find New Pros': {
        flow: 'selectFindNewPros',
        pageLink: 'automations/summary',
      },
      'Access Notes': {
        flow: 'selectAccessNotes',
        pageLink: 'properties',   
      },
      'Inventory Automation': {
        flow: 'inventoryAutomation',
        pageLink: 'inventory',
      },
      'Bills': {
        flow: 'bills',
        pageLink: 'bills',
      },
      'Bill Automation': {
        flow: 'bills',
        pageLink: 'bills',
      },
      'Message Automation': {
        flow: 'messageAutomation',
        pageLink: 'messages',
      },
      'Messages': {
        flow: 'messageAutomation',
        pageLink: 'messages',
      },
      'Automate Messages': {
        flow: 'messageAutomation',
        pageLink: 'messages',
      },
      'Customize Your Workflows': {
        flow: 'automateWorkflows',
        pageLink: 'automations/summary',
      },
      'Integrate Upsell Provider': {
        flow: 'upsell',
        pageLink: 'dashboard',
      },
      'To-Do List': {
        flow: 'toDos',
        pageLink: 'all-to-do-lists',
      },
      'Add Inspections': {
        flow: 'addInspections',
        pageLink: 'all-to-do-lists',
      },
      'Create Rules': {
        flow: 'createConciergeRules',
        pageLink: 'concierge-rule',
        openMode: 'modal'
      },
      /*

      Skipping for v1: 

      'Handle Tasks for You': {
        flow: '',
        pageLink: 'concierge',
      },
      'Restocking Automation': {
        flow: 'selectRestockingOption',
        pageLink: 'inventory',
      },
      'Smart Maintenance': {
        pageLink: 'maintenance',
      },
      'Notifications': {
        pageLink: 'notifications',
      },
      'Schedule Automation': {
        pageLink: 'automations/upsell-scheduling',
      },
      */
    }
    return stepData[stepTitle];
  }

  async markGoalAsCompleted(flow: string) {
    //TODO if user has not added the goal, add it then mark complete
    //TODO if the goal has already been marked as complete do nothing (for add integration for example)
    const goals = await this.storage.retrieve('onboardingGoals');
    if (!goals) {
      return;
    }
    let stepIDs = [];
    goals.map((goal) => {
      goal.user_goal_steps.map((step) => {
        if (step.flow === flow) {
          stepIDs.push(step.id);
        }
      })
    })
    if (stepIDs.length === 0) {
      return;
    }
    const promises = stepIDs.map((stepID) => {
      const url = `api/v1/customer/user-goal-steps/${stepID}/mark-as-done`;
      return this.httpClient.post(url, {});
    });
    await Promise.all(promises);
    await this.getGoalsFromServer();
  }
  
}

const flowText = {
  messageAutomation: {
    title: 'Getting Started: Message Automation',
    subTitle: 'View messages from pros and automate responses following your rules.',
    description: 'View messages from pros and automate responses following your rules.',
    buttonText: 'Automate Messaging'
  },
  toDos: {
    title: 'Getting Started: To-Dos',
    subTitle: 'Jobs get done right with digital to-do lists. Easily share. No download or signup required.',
    description: 'Jobs get done right with digital to-do lists. Easily share. No download or signup required.',
    buttonText: 'Set Up To-Dos'
  },
  tasks: {
    title: 'Getting Started: Tasks',
    subTitle: 'Track maintenance tasks & suggestions. Have the Concierge get them done for you.',
    description: 'Track maintenance tasks & suggestions. Have the Concierge get them done for you.',
    buttonText: 'Create a Task'
  },
  inventoryAutomation: {
    title: 'Getting Started: Restocking Automation',
    subTitle: 'Automate restocking of things like soaps, coffee, toilet paper, & more.',
    description: 'Automate restocking of things like soaps, coffee, toilet paper, & more.',
    buttonText: 'Automate Restocking'
  },
  maintenance: {
    title: 'Getting Started: Maintenance',
    subTitle: 'Smart maintenance suggestions custom to you. What to do, what to skip, & how to save.',
    description: 'Smart maintenance suggestions custom to you. What to do, what to skip, & how to save.',
    buttonText: 'Set Up Smart Maintenance'
  },
  bills: {
    title: 'Getting Started: Bills',
    subTitle: 'Set up rules to pay pros on autopilot.',
    description: 'Set up rules to pay pros on autopilot.',
    buttonText: 'Automate Bill Pay'
  },
  addPros: {
    title: 'Getting Started: Add Your Pros',
    subTitle: 'Use TIDY with any pro. If they don\'t use TIDY, we can automate via SMS or phone.',
    description: 'Use TIDY with any pro. If they don\'t use TIDY, we can automate via SMS or phone.',
    buttonText: 'Add Your Pros'
  },
  selectIntegration: {
    title: 'Getting Started: Integrate',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Add Integration'
  },
  property: {
    title: 'Getting Started: Schedule',
    subTitle: 'Add your properties to get started.',
    description: 'Add your properties to get started.',
    buttonText: 'Add Properties'
  },
  automateWorkflows: {
    title: 'Getting Started: Automate Workflows',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Automate Workflows'
  },
  upsell: {
    title: 'Getting Started: Upsell',
    subTitle: 'Enable automated handling of early check-in, late check-out, and mid-stay clean requests.',
    description: 'Enable automated handling of early check-in, late check-out, and mid-stay clean requests.',
    buttonText: 'Get Started Upselling'
  },
  selectFindNewPros: {
    title: 'Getting Started: Find New Pros',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Find New Pros'
  },
  selectAccessNotes: {
    title: 'Getting Started: Access Notes',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Add Access Notes'
  },
  addMaintenanceItems: {
    title: 'Getting Started: Add Maintenance Items',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Add Maintenance Items'
  },
  addInspections: {
    title: 'Getting Started: Add Inspections',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Add Inspections'
  },
  enableAutomaticBooking: {
    title: 'Getting Started: Automatic Booking',
    subTitle: 'Placeholder Text',
    description: 'Placeholder Text',
    buttonText: 'Set Up Automatic Booking'
  },

  /*
    
  Skipping for v1 but can still add text Chris if you want:
  
  schedule: {
  title: 'Schedule',
  subTitle: 'Track all cleanings, maintenance, and reservations in realtime.',
  description: 'Track all cleanings, maintenance, and reservations in realtime.',
  buttonText: 'Automate or Book Jobs'

  },*/

}

<mat-form-field
  [class.invalid-field]="!form.valid && submitted"
  appearance="outline"
>
  <input
    type="text"
    matInput
    (focusout)="onFocusOut()"
    (keydown)="onKeyDown()"
    (keyup)="onKeyUp($event.target.value)"
    [matAutocomplete]="auto"
    [value]="getValue()"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [class]="showGoogleIcon === true ? 'powered-by-google' : ''"
  >
    <ng-container *ngIf="showOnFocus || isFocused">
      <ng-container *ngIf="isToSearchBetweenOptions">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          (click)="choseItem(option)"
          [value]="option.display"
        >
          {{ option.display }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isToSearchBetweenOptions">
        <mat-option
          *ngFor="let option of options"
          (click)="choseItem(option)"
          [value]="option.display"
        >
          {{ option.display }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
  <mat-label *ngIf="label">
    <tidy-image [src]="icon" *ngIf="icon" class="black" class="outline-field-label-icon"></tidy-image>
    <tidy-text [body]="label"></tidy-text>
  </mat-label>
</mat-form-field>
<tidy-error-message *ngIf="!form.valid && submitted" [text]="errorMessage">
</tidy-error-message>

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';


@Injectable()
export class Cleaning {

  constructor (private httpClient: HttpClientCustom) {
  }

  getHelpText(hks) {
    return [{hkNumber: 1, data: {
        before30m: [
          { 'value': `Please contact ${hks[0].first_name} for time sensitive issues.  You can call / text ${hks[0].first_name} once it is within 30 minutes of the cleaning.`, 'type': 'string'},
          { 'value': '', 'type': 'br' },
          { 'value': 'To protect your privacy, we generate a temporary phone number for you to use for each cleaning.', 'type': 'string'},
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string' },
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        before: [
          { 'value': `Please contact ${hks[0].first_name} for time sensitive issues.`, 'type': 'string' },
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string' },
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        during: [
          { 'value': `Please contact ${hks[0].first_name} for time sensitive issues.  If ${hks[0].first_name} is not responding or did not show up `, 'type': 'string' },
          { 'value': 'click here', 'type': 'link', 'action': 'no_show', 'homekeeperId': hks[0].id},
          { 'value': '.', 'type': 'string' },
          { 'value': '', 'type': 'br' },
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string'                    },
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        after: [
          { 'value': 'Please', 'type': 'string' },
          { 'value': 'click here', 'type': 'link', 'action': 'no_show', 'homekeeperId': hks[0].id },
          { 'value': `if ${hks[0].first_name} did not show up for the cleaning.`, 'type': 'string' },
          { 'value': '', 'type': 'br' },
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string' },
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ]
    }},
    {
      hkNumber: 2, data: {
        before30m: [
          { 'value': 'Please contact the Homekeepers for time sensitive issues.  You can call / text them once it is within 30 minutes of the cleaning.', 'type': 'string'},
          { 'value': '', 'type': 'br' },
          { 'value': 'To protect your privacy, we generate a temporary phone number for you to use for each cleaning.' , 'type': 'string'},
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string' },
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        before: [
          { 'value': 'Please contact Homekeepers for time sensitive issues.', 'type': 'string' },
          { 'value': '', 'type': 'br' },
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string'},
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        during: [
          { 'value': 'Please contact Homekeepers for time sensitive issues.', 'type': 'string' },
          { 'value': '', 'type': 'br' },
          { 'value': '', 'type': 'br' },
          { 'value': 'You can also check our ', 'type': 'string'},
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
        ],
        after: [
          { 'value': 'You can also check our ', 'type': 'string'},
          { 'value': 'help center', 'type': 'link', 'action': 'help' },
          { 'value': 'for non time sensitive questions.', 'type': 'string' },
          { 'value': '', 'type': 'br' },
        ].concat(this.getHKListNotArrive(hks))
      }
    }
  ];
 }

  async checkIfHasCleaningToday(date) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/jobs?date=${date}&assigned_with_some_homekeeper=true`;
    return await this.httpClient.get(url);
  }

  getContactData(cardId) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/cards/${cardId}/contact`;
    return this.httpClient.get(url);
  }

  getHelpData(cardId) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/cards/${cardId}/help`;
    return this.httpClient.get(url);
  }

  getHKListNotArrive(hks) {
    const hkList = [];
    hks.forEach((hk) => {
      hkList.push([
        { 'value': `If ${hk.first_name} did not show up for the cleaning please `, 'type': 'string'},
        { 'value': 'click here', 'type': 'link', 'action': 'no_show', 'homekeeperId': hk.id},
        { 'value': '.', 'type': 'string' },
        { 'value': '', 'type': 'br' },
        { 'value': '', 'type': 'br' },
      ]);
    })
    return [].concat.apply([], hkList);
  }

  getHKListNotResponding(hks) {
    const hkList = [];
    hks.forEach((hk) => {
      hkList.push([
        { 'value': `If ${hk.first_name} is not responding or did not show up`, 'type': 'string'},
        { 'value': 'click here', 'type': 'link', 'action': 'no_show'},
        { 'value': '.', 'type': 'string' },
        { 'value': '', 'type': 'br' },
        { 'value': '', 'type': 'br' },
      ]);
    })
    return [].concat.apply([], hkList);
  }

  async getCard (cleaningId = null) {
    const result = await this.getCards();
    return result.find((item) => {
      if (!item.template_data || !item.template_data.job) {
        return;
      }
      return item.template_data.job.id === cleaningId
    });
  }

  async getUpdates (cleaningUpdateId) {
    const url = `api/v1/customer/cleaning-updates/${cleaningUpdateId}/page`;
    const icons = {
      skip: 'close',
      keep: 'contact',
      reschedule: 'play-forward',
      request: 'play-forward',
      loved_it: 'tidy-love-it',
      phone_call: 'call',
      phone_text: 'text',
      cancel: 'close',
      needs_work: 'tidy-needs-work'
    }
    const result = await this.httpClient.get(url)
    result.buttons = result.buttons.map((item) => {
       item.formatedIcon = icons[item.icon];
       return item;
    })
    return result;
  }

  getIcon (action) {
    const icons = {
      'help': 'ios-help-circle-outline',
      'help_zendesk': 'ios-help-circle-outline',
      'todos': 'tidy-bowtie',
      'reschedule_skip': 'ios-time-outline',
      'request_substitute': 'ios-alert-outline',
      'reschedule_cleaning': 'ios-time-outline',
    }
    return icons[action];
  }

  async getCards() {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/cards/list`;
    const result = await this.httpClient.get(url)
    result.map((item) => {
      if (!item.template_data.options) {
        return;
      }
      return item.template_data.options.map((option) => {
        option.icon = this.getIcon(option.action);
      })
    })
    return result;
  }
}

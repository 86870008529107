<!--TOOD replace inline style https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-in-onboarding-->
<tidy-wrapper>
  <tidy-row [align]="'center'">
    <tidy-text
      [title]="'Get it Done Right'">
    </tidy-text>
  </tidy-row>

  <tidy-row class="extra-bottom-padding" [align]="'center'">
    <tidy-text
      [body]="'Create a digital To-Do List and share it with any Pro for free!'">
    </tidy-text>
  </tidy-row>

  <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/icons/rooms/kitchen.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [header]="'Kitchen'">
      </tidy-text>
    </tidy-row>
    <ion-skeleton-text></ion-skeleton-text>
    <ion-skeleton-text></ion-skeleton-text>
  </tidy-card>
  <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/icons/rooms/bathroom_1.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [header]="'Bathroom'">
      </tidy-text>
    </tidy-row>
    <ion-skeleton-text></ion-skeleton-text>
    <ion-skeleton-text></ion-skeleton-text>
  </tidy-card>
  <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/icons/rooms/laundry.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [header]="'Laundry Room'">
      </tidy-text>
    </tidy-row>
    <ion-skeleton-text></ion-skeleton-text>
    <ion-skeleton-text></ion-skeleton-text>
  </tidy-card>

  <tidy-error-message
    *ngIf="errorMessage"
    [text]="errorMessage">
  </tidy-error-message>

  <tidy-row>
    <tidy-button
      [text]="'Have Concierge Add For Me'"
      [action]="goToAddConciergeTask.bind(this)"
      class="primary"
      [disabled]="buttonsDisabled">
    </tidy-button>
  </tidy-row>

  <tidy-row>
    <tidy-button
      [text]="'Create To-Do List in 1 Minute'"
      [action]="handleOnboardingSelection.bind(this, 'create_list')"
      class="primary"
      [disabled]="buttonsDisabled">
    </tidy-button>
  </tidy-row>

  <tidy-row>
    <tidy-button
      [text]="'Don\'t Use To-Dos'"
      [action]="handleOnboardingSelection.bind(this, 'skip')"
      class="secondary"
      [disabled]="buttonsDisabled">
    </tidy-button>
  </tidy-row>

  <tidy-row [align]="'center'">
    <tidy-text
      [body]="'Use Most Popular'"
      (action)="handleOnboardingSelection('most_popular')"
      class="link"
      [class.link-disabled]="buttonsDisabled">
    </tidy-text>
  </tidy-row>
</tidy-wrapper>

<ng-container *ngIf="loaded">

  <tidy-card>
    <tidy-card-button (action)="goToUploadPros()">
      <ion-row>
        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/img/csv.svg'" style="width: 20px">
          </tidy-image>
        </tidy-row>
        <ion-col>
          <tidy-row>
            <tidy-text [header]="'Upload Pros'"> </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text [body]="'Upload a CSV file with the names, emails, and phone numbers of the pros you want to add.'"> </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-card-button>
  </tidy-card>

  <tidy-row class="extra-bottom-padding">
    <tidy-text [helper]="'or'" class="divider"> </tidy-text>
  </tidy-row>

  <tidy-row class="extra-bottom-padding">
    <tidy-text [header]="'Add Pros Manually'"> </tidy-text>
  </tidy-row>

  <ng-container *ngFor="let form of forms.controls; let i = index">
    <form [formGroup]="form">
      <tidy-card>
        <tidy-row>
          <tidy-input
            formControlName="name"
            [label]="'Pro Name'"
            [form]="form.controls.name"
            [submitted]="submitted"
            [errorMessage]="'Please enter a name.'"
            [icon]="'assets/svg/person-outline.svg'"
            [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>
        <tidy-two-column-row>
          <tidy-input
            formControlName="email"
            [label]="'Email'"
            [form]="form.controls.email"
            type="email"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email.'"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
          </tidy-input>
          <tidy-input-international-phone
            formControlName="phone"
            [label]="'Phone'"
            [form]="form.controls.phone"
            [inputMode]="'numeric'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid phone.'"
            [icon]="'assets/svg/phone-portrait-outline.svg'"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
          </tidy-input-international-phone>
        </tidy-two-column-row>
        <tidy-row>
          <tidy-select
            #tidySelect
            [label]="'Services Offered'"
            [items]="serviceCategoryItems"
            [form]="form.controls.serviceCategories"
            formControlName="serviceCategories"
            [multiple]="true"
            [submitted]="submitted"
            [errorMessage]="'Please select at least one service.'">
          </tidy-select>
        </tidy-row>
        <tidy-row [ngStyle]="{'margin-bottom': form?.value?.whereToAdd == 'select' ? '0px' : '-70px'}">
          <tidy-radio-button
            class="horizontal-alignment no-wrap"
            formControlName="whereToAdd"
            [form]="form.controls.whereToAdd"
            [items]="whereToAddItems">
          </tidy-radio-button>
        </tidy-row>
        <tidy-row [ngStyle]="{ visibility: form?.value?.whereToAdd == 'select' ? 'visible' : 'hidden'}">
          <tidy-select
            #tidySelect
            [label]="'Select Properties'"
            [items]="propertiesToAddItems"
            [form]="form.controls.propertiesToAdd"
            formControlName="propertiesToAdd"
            [multiple]="true"
            [submitted]="submitted"
            [errorMessage]="'Please select at least one property.'">
          </tidy-select>
        </tidy-row>
      </tidy-card>
    </form>
  </ng-container>
  <tidy-row [align]="'center'" class="extra-bottom-padding">
    <tidy-text
      [actionBody]="'+ Add Another Pro'"
      class="link"
      (action)="pushEmptyForm()">
    </tidy-text>
  </tidy-row>

  <tidy-button
    [text]="forms.controls.length > 0 ? 'Add Pros' : 'Add Pro'"
    class="primary"
    [action]="addPros.bind(this)">
  </tidy-button>

</ng-container>

<ng-container *ngIf="!loaded">
  <ion-skeleton-text animated="true" style="width: 100%; height: 550px"></ion-skeleton-text>
</ng-container>
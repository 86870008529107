import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TidyStorage {

  constructor(
    private router: Router,
    private storage: Storage
  ) {
    this.storage.create();
  }

  save(key: string, value: any): Promise<void> {
    return this.storage?.set(key, value);
  }

  async retrieve(key: string): Promise<any> {
    return await this.storage?.get(key);
  }

  delete(key: string): any {
    this.storage?.remove(key);
  }

  async clear() {
    await this.storage?.clear();
    localStorage.clear();
  }

  setStoredRoute(route: string): Promise<boolean> {
    return new Promise((resolve) => {
      const subscription = this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes(route)) {
          subscription.unsubscribe();
          resolve(true);
        }
      });
    });
  }

}

import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-two-column-row',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./two-column-row.component.scss'],
  template: `
    <ng-content></ng-content>
  `
})

export  class TwoColumnRowComponent {

}

import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output, EventEmitter  } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../default-class/value-accessor';
import { CheckboxModel } from './checkbox.model';

@Component({
  selector: 'tidy-checkbox',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./checkbox.component.scss'],
  templateUrl: 'checkbox.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxComponent,
    multi: true,
  }],
})

export  class CheckboxComponent extends ValueAccessorBase<any> implements OnInit {
  @Input() items: CheckboxModel;
  @Input() label: string;
  @Input() icon: string;
  @ViewChild(NgModel, {static: false}) model: NgModel;
  @Input() checked = false;
  @Input() initValue: string | number | boolean;
  @Input() disabled = false;
  public selected: string;
  public loaded = false;

  @Output() checkedChange = new EventEmitter<boolean>();

  ngOnInit() {
    this.value = this.initValue;
    this.selected = this.value;
  }

  selectItem() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}

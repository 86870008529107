import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { ReportSettingsPage } from 'src/pages/dashboard/report-settings/report-settings';
import { ToDosRoomPage } from 'src/pages/to-dos/room/room';
import { ToDosPage } from 'src/pages/to-dos/to-dos';
import { ConciergePage } from 'src/pages/concierge/concierge';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { ScheduleListPage } from 'src/pages/schedule/schedule-list/schedule-list';
import { ProAddedPage } from 'src/pages/my-pros/pro-added/pro-added';
import { PropertyOptionsPage } from 'src/pages/more/edit-address/property-options/property-options';
import { JobPage } from 'src/pages/schedule/job/job';
import { EmployerEditEmployeePage } from 'src/pages/employer/edit-employee/edit-employee';
import { SummaryOfDayPage } from 'src/pages/schedule/summary-of-day/summary-of-day';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';

@Component({
  selector: 'app-right-side-dialog-content',
  template: `
    <div
      class="right-side-modal slide-in-right-animation"
      [id]="'right-side-dialog'">
      <tidy-row class="right-side-panel-header" [alignLastItemRight]="true">
        <div class="right-side-panel-title">
          <img
            *ngIf="canGoBack"
            class="back-button"
            (click)="goBack()"
            src="assets/img/white-back-arrow.svg" />
          <tidy-text
            class="title-text"
            [header]="rightSidePanelService.pageTitle$ | async">
          </tidy-text>
        </div>
        <tidy-drop-down-menu class="drop-down-menu" *ngIf="rightSidePanelService.currentComponentData?.dropDownMenu">
          <tidy-drop-down-menu-item *ngFor="let item of rightSidePanelService.currentComponentData?.dropDownMenu"
            (click)="dropDownMenuClicked(item.action)">
            {{item.label}}
          </tidy-drop-down-menu-item>
        </tidy-drop-down-menu>
        <div class="cross-icon">
          <img src="assets/img/white-close.svg" (click)="dismissModal()" />
        </div>
      </tidy-row>
      <ng-container
        *ngIf="{
          value: rightSidePanelService.isLoading$ | async
        } as isLoading">
        <ng-container *ngIf="isLoading.value">
        <div class="desktop">
          <ion-skeleton-text
          animated
          slot="start"
          style="width: 100%; height: 100vh; margin-top: -10px"></ion-skeleton-text>
        </div>
        </ng-container>
      </ng-container>
      <div class="scrollable-content">
        <ng-template #dynamicComponentContainer></ng-template>
      </div>
    </div>
  `,
})

export class DialogContentComponent implements AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  container: ViewContainerRef;
  pageTitle: string;
  canGoBack: boolean;
  currentComponentSubscription$: Subscription;
  routerSubscription$: Subscription;

  constructor(
    public rightSidePanelService: RightSidePanelService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.watchCurrentComponentChanges();
    this.watchRouterEvents();
  }

  ngOnDestroy(): void {
    this.currentComponentSubscription$.unsubscribe();
    this.routerSubscription$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateScrollableContent();
  }

  updateScrollableContent(): void {
    setTimeout(() => {
      const customRightColumn = document.querySelector('.custom-right-column') as HTMLElement;
      const scrollableContent = document.querySelector('.scrollable-content') as HTMLElement;
      if (!scrollableContent) {
        return;
      }
      if (customRightColumn) {
        scrollableContent.style.overflow = 'hidden';
      } else {
        scrollableContent.style.overflow = 'auto';
      }
    }, 500);
  }

  /**
   * Watch for changes in the current component and load it
   */
  watchCurrentComponentChanges(): void {
    this.currentComponentSubscription$ = new Subscription();
    this.currentComponentSubscription$.add(
      this.rightSidePanelService.currentComponent$.subscribe(() => {
        this.loadOrReplaceComponent();
        this.checkCanGoBack();
        this.updateScrollableContent();
      })
    );
  }

  /**
   * Watch for router events
   */
  watchRouterEvents(): void {
    this.routerSubscription$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dismissModal(); // INFO: Close the panel if the route changes
      }
    });
  }

  /**
   * Check if the user can go back
   */
  async checkCanGoBack(): Promise<void> {
    const hideBackButton = this.checkIfShouldHideBackButton();
    const historyContext = this.rightSidePanelService.historyContext$.getValue();
    this.canGoBack = historyContext?.length > 1 && !hideBackButton;
  }

  checkIfShouldHideBackButton() {
    const currentComponent = this.rightSidePanelService.currentComponent$.getValue();
    if (currentComponent == BillingHistoryPage || currentComponent == EmployerEditEmployeePage || currentComponent == ToDosPage || currentComponent == ItemDetailsPage && this.rightSidePanelService.currentComponentData?.cameFromEditItem || currentComponent == ReportSettingsPage || currentComponent == ConciergePage || (currentComponent == AutomaticBookingAddressPage && this.rightSidePanelService.currentComponentData?.cameFromAddIntegration) || currentComponent == ScheduleListPage || currentComponent == ProAddedPage || currentComponent == PropertyOptionsPage) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Go back to the previous component
   */
  async goBack(): Promise<void> {
    const historyContext = this.rightSidePanelService.historyContext$.getValue();
    historyContext.pop();
    const hardCodedBackPage = this.getHardCodedBackPage();
    const previousComponent = historyContext[historyContext.length - 1];
    this.rightSidePanelService.historyContext$.next(historyContext);
    this.rightSidePanelService.currentComponent$.next(hardCodedBackPage ? hardCodedBackPage : previousComponent);
    this.loadOrReplaceComponent();
    this.checkCanGoBack();
    this.updateScrollableContent();
  }

  getHardCodedBackPage() {
    const currentComponent = this.rightSidePanelService.currentComponent$.getValue();
    if (currentComponent == ToDosRoomPage) {
      return ToDosPage;
    } else if (currentComponent == JobPage) {
      const cameFromSummaryOfDay = localStorage.getItem('jobPageBackPath') == 'summary-of-day';
      if (cameFromSummaryOfDay) {
        return SummaryOfDayPage;
      } else {
        return ScheduleListPage;
      }
    } else {
      return null;
    }
  }

  /**
   * Dismiss the modal
   */
  dismissModal(): void {
    this.rightSidePanelService.closeRightSidePanel();
  }

  dropDownMenuClicked(action: string): void {
    this.rightSidePanelService.emitDropDownMenuAction(action);
  }

  /**
   * Load or replace the component in the dialog
   */
  async loadOrReplaceComponent(): Promise<void> {
    const currentComponent =
      this.rightSidePanelService.currentComponent$.getValue();
    this.container.clear();
    if (!currentComponent) {
      return;
    }
    const componentRef = this.container.createComponent(currentComponent);
    (componentRef.instance as any).data = this.rightSidePanelService.currentComponentData;
    this.updateScrollableContent();
    // INFO: If you need to pass data to the component or access its instance, you can do so via componentRef.instance
  }
}

export interface RightSideDialogData {
  [key: string]: any;
  dropDownMenu?: {
    label: string;
    action: string;
  }[];
};

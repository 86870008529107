<tidy-card>
  <tidy-card-button (action)="addAttachment()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/csv.svg'"
          style="width:20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text
            [header]="uploadButtonTitle">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'We can handle one or multiple files of any type.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-row *ngIf="attachments.length" class="extra-bottom-padding">
  <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
    <tidy-text
      [body]="attachment.filename">
    </tidy-text>
    <tidy-text
      [actionBody]="'remove'"
      (action)="removeAttachment(i)"
      class="link red">
    </tidy-text>
  </tidy-two-column-row>
</tidy-row>

<form [formGroup]="itemForm" novalidate action="#">
  <tidy-row class="extra-bottom-padding">
    <tidy-textarea
      [label]="'Notes for Concierge (optional)'"
      [icon]="'assets/svg/create-outline.svg'"
      [submitted]="submitted"
      [errorMessage]="'Please enter a description'"
      [form]="itemForm.get('body')"
      formControlName="body">
    </tidy-textarea>
  </tidy-row>
</form>

<tidy-button
  [text]="submitButtonTitle"
  [action]="saveItem.bind(this)"
  class="primary">
</tidy-button>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'RemoteLock'"
    [canGoBack]="true"
    [customBack]="'integrations/all'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'TIDY will automatically generate a lock code through RemoteLock for each job.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [actionBody]="'Learn More'"
          (action)="goToLearnMore()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="addresses?.length > 3">
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding" style="padding-bottom:0px">
            <tidy-input
              #searchInput
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-alert>

      <tidy-card *ngFor="let address of addresses; let last = last">
        <tidy-card-button (action)="goToEditAddressLockPage(address)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              *ngIf="address?.remote_access_address"
              [src]="'assets/img/checkmark.svg'"
              class="header-size">
            </tidy-image>
            <tidy-image
              *ngIf="!address?.remote_access_address"
              [src]="'assets/img/block.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              *ngIf="!address?.address_name"
              [body]="address?.address1 + ' '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="address?.address_name"
              [body]="address?.address_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="address?.address2 !== null && address?.address2 !== '' && !address?.address_name">
            <tidy-text
              [body]="address?.address2">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="address?.city !== null && address?.city !== ''"
              [helper]="address?.city">
            </tidy-text>
            <tidy-text
              *ngIf="address?.zip !== null && address?.zip !== ''"
              [helper]="', ' + address?.zip">>
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let device of getInUseDevices(address?.remote_access_address?.remote_access_devices)">
            <tidy-row class="vertical-align-center extra-top-padding">
              <tidy-image
                [src]="'assets/img/lock-grey.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="device?.device_name"
                [translate]="false">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row class="extra-top-padding" *ngIf="getInUseDevices(address?.remote_access_address?.remote_access_devices)?.length == 0">
            <tidy-text
              [helper]="'No Devices Integrated'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

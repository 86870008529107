<mat-form-field
  [class.invalid-field]="!disableElement && !form.valid && submitted"
  [class.no-border]="noBorder"
  appearance="outline"
>
  <mat-label *ngIf="label">
    <tidy-image [src]="icon" *ngIf="icon" class="outline-field-label-icon"></tidy-image>
    <tidy-text [body]="label" ></tidy-text>
  </mat-label>

  <input
    *ngIf="!inputMask"
    [id]="inputId"
    matInput
    #neurotypicalInput
    (keydown)="changeTouch()"
    (keyup)="keyUpHandle($event)"
    [(ngModel)]="value"
    [type]="type"
    [disabled]="disableElement"
    [attr.inputMode]="inputMode"
    (focus)="setFocused(true)"
    (focusout)="setFocused(false)"
    [autocomplete]="autoComplete"
  />

  <input
    *ngIf="inputMask"
    [attr.maxlength]="inputMask.maxLenghth"
    [imask]="inputMask"
    [unmask]="unmask"
    matInput
    #maskedInput
    [disabled]="disableElement"
    [class.offset-content]="inputMask?.additionalCharacter && !isInputEmpty"
    (keydown)="changeTouch()"
    [(ngModel)]="value"
    (keyup)="keyUpHandle($event)"
    [type]="type"
    [attr.inputMode]="inputMode"
    (focus)="setFocused(true)"
    (focusout)="setFocused(false)"
    [autocomplete]="autoComplete"
  />

  <span *ngIf="textSuffix" matSuffix class="text-suffix">
    {{ textSuffix }}
  </span>

  <button
    *ngIf="action"
    matSuffix
    mat-icon-button
    [ngClass]="{ empty: isInputEmpty }"
    [disabled]="loadingState.loading || isInputEmpty"
    (click)="executeAction()"
  >
    <mat-icon [ngClass]="{ 'default-icon': !buttonIcon }" [style.color]="buttonIconColor">{{ buttonIcon }}</mat-icon>
  </button>

  <span
    class="additional-character"
    *ngIf="inputMask?.additionalCharacter && !isInputEmpty"
  >
    {{ inputMask?.additionalCharacter }}
  </span>
</mat-form-field>
<tidy-error-message
  *ngIf="!disableElement && !form.valid && submitted"
  [text]="errorMessage"
>
</tidy-error-message>

import { Util } from 'src/shared/util/util';
import { TidyStorage } from './../../shared/providers/tidy-storage';
import { Pros } from './../../providers/pros/pros';
import {
  Component,
  HostListener,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateTime as LuxonDateTime } from 'luxon';
import { debounceTime } from 'rxjs/operators';
import { Client } from 'src/providers/client/client';
import { Communication } from 'src/providers/communication/communication';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { NewMessageModalComponent } from './new-message-modal/new-message-modal';
import { JobActivityPage } from '../schedule/job-activity/job-activity';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Subscription } from 'rxjs';
import { AddProxyNumberComponent } from './add-proxy-number/add-proxy-number';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe';
import { ProMessagesPage } from 'src/pages/pro-messages/pro-messages';
import { ProxyMessagesPage } from 'src/pages/proxy-messages/proxy-messages';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { ItemNode, FlatNode, ChipFilter } from 'src/models/schedule.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { messagesMockData } from 'src/shared/constants/onboarding/messages';

@Component({
  templateUrl: 'messages.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./messages.scss'],
})
export class MessagesPage extends TimeoutableComponent implements OnDestroy {
  address: any;
  desktopWidthContent: string = scrollContentWidth.LARGE;
  addressId: any;
  addressFilter: any;
  addressResponse: any;
  clientName: string;
  errorMessage: string;
  messages: any[];
  shownMessages: any[];
  imutableMessages: any[];
  form: UntypedFormGroup;
  allPros: any[];
  proList = [];
  showLoadingSpinner: boolean;
  selectedAddresses: number[];
  selectedPros: number[];
  searchForm: UntypedFormGroup;
  dateRangeForm: UntypedFormGroup;
  selectOptions: any;
  panelClosedSubscription: Subscription;
  guestNumber: string;
  proNumber: string;
  filters: any = {};
  showDesktopHamburgerMenu: boolean;
  showExpandedDesktopHamburger: boolean;
  proxyPros: any[];
  isFiltersLoaded: boolean;
  headers = ['Thread', 'Last Message', 'Sent', 'last-column'];
  rows: any;
  currentRows: any;
  wasTableLoaded: boolean;
  selectedFilters: {
    pro: any[];
    propertyName: any[];
  } = {
    pro: [],
    propertyName: []
  };
  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  chips: ChipFilter[] = [];
  filterNamesReferences = {
    'Person': 'pro',
    'Property': 'propertyName'
  };
  showOnboarding: boolean;
  hasProperties: boolean;
  didCheckOnboarding: boolean;
  showOnboardingSubscription$: Subscription;

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    this.showDesktopHamburgerMenu = event.target.innerWidth < 1610;
  }

  constructor(
    private client: Client,
    public communication: Communication,
    public currentAddress: CurrentAddress,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private fb: FormBuilder,
    private pros: Pros,
    private storage: TidyStorage,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    public onboardingProvider: OnboardingProvider
  ) {
    super();
    this.form = this.fb.group({
      selectedPro: ['', Validators.required],
      selectedAddress: ['', Validators.required],
    });
    this.searchForm = this.fb.group({
      search: [''],
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: '',
    });
    this.selectOptions = [
      { viewValue: 'Custom', value: 'custom' },
      { viewValue: 'Today', value: 'today' },
      { viewValue: 'Last Week', value: 'last_week' },
      { viewValue: 'Last Month', value: 'last_month' },
      { viewValue: 'All Time', value: 'all_time' },
    ];
  }

  async ngOnInit() {
    try {
      this.loadPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.checkIfShouldShowOnboarding();
    this.errorMessage = '';
    this.addressResponse = await this.client.getAddresses();
    //this.addressId = this.client.getSelectedAddressId(this.addressResponse);
    this.addressFilter = this.client.parseAddressList(
      this.addressResponse,
      false
    );
    this.storage.save('addressFilter', this.addressFilter);
    this.showDesktopHamburgerMenu = window.innerWidth < 1610;
    this.clientName = localStorage.getItem('customer_name');
    this.loaded = true;
    await this.getProxyNumbers();
    await this.loadMessages();
    this.wasTableLoaded = true;
    this.getTreeData();
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/messages').then(async () => {
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('messageAutomation');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  getProxyPros(proxyNumbers: any[]): any[] {
    return proxyNumbers
      .map((item) => {
        return item?.chat_rooms?.map((chatRoom) => {
          const pro = chatRoom?.all_chat_room_members?.find(
            (member) => member?.type === 'PhoneNumber'
          );
          const parsedPhoneNumber = chatRoom?.data?.phone_number?.replace(
            /^\d{1,3}(\d{3})(\d{3})(\d{4})$/,
            '$1-$2-$3'
          );
          return {
            ...pro,
            chat_room_key: chatRoom?.key,
            data: chatRoom?.data,
            last_name: parsedPhoneNumber,
          };
        });
      })
      .flat()
      .filter((item) => item);
  }

  ngOnDestroy(): void {
    this.panelClosedSubscription?.unsubscribe();
    this.storage.delete('proId');
    this.storage.delete('chatRoomKey');
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  toggleDesktopHamburger() {
    this.showExpandedDesktopHamburger = !this.showExpandedDesktopHamburger;
  }

  async getFilteredMessages(filters: any): Promise<void> {
    const loading = await this.util.showLoading();
    await this.loadMessages(filters);
    if (this.searchForm.value.search) {
      this.updateSearch({search: this.searchForm.value.search});
    }
    loading.dismiss();
  }

  async selectCustomDateRange(selection) {
    const start = new Date(selection.start);
    const end = new Date(selection.end);
    const startDate = LuxonDateTime.fromJSDate(start).toISODate();
    const endDate = LuxonDateTime.fromJSDate(end).toISODate();
    this.filters['fromDate'] = startDate;
    this.filters['untilDate'] = endDate;
    await this.getFilteredMessages(this.filters);
    this.filterRows();
  }

  async selectStringDateRange(selection) {
    const today = LuxonDateTime.local();
    let startDate = today.toISODate();
    let endDate = today.toISODate();
    if (selection === 'last_week') {
      startDate = today.minus({ days: 7 }).toISODate();
    }
    if (selection === 'last_month') {
      startDate = today.minus({ months: 1 }).toISODate();
    }
    if (selection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    this.filters['fromDate'] = startDate;
    this.filters['untilDate'] = endDate;
    await this.getFilteredMessages(this.filters);
    this.filterRows();
  }

  async getProxyNumbers(): Promise<void> {
    try {
      const proxyNumbers = await this.communication.getProxyNumbers();
      this.proxyPros = this.getProxyPros(proxyNumbers);
      const pros = await this.pros.getAllPros();
      this.allPros = [...this.proxyPros, ...pros];
      this.proList = this.parseProList(this.allPros);
      this.storage.save('pros', this.allPros);
      this.proList = this.parseProList(this.allPros);
      this.storage.save('proList', this.proList);
      this.guestNumber = proxyNumbers?.find(
        (item) => item?.number_type === 'guest'
      )?.phone_number;
      this.guestNumber = this.getParsedProxyNumber(this.guestNumber);
      this.proNumber = proxyNumbers?.find(
        (item) => item?.number_type === 'homekeeper'
      )?.phone_number;
      this.proNumber = this.getParsedProxyNumber(this.proNumber);
      this.isFiltersLoaded = true;
    } catch (err) {
      this.isFiltersLoaded = true;
      console.error(err);
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  getParsedProxyNumber(number: string): string {
    if (!number) {
      return;
    }
    return number?.replace(/^\+\d{1,3}(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  }

  updateSearch({ search }) {
    if (search.length < 2 || search == '') {
      this.shownMessages = this.messages;
      this.buildRows(this.shownMessages);
      return;
    }
    const term = search.toLowerCase();
    this.shownMessages = [];
    this.messages.map((message) => {
      message.chatRoom.all_chat_room_members.map((chatRoom) => {
        const personIsMatch =
          chatRoom?.first_name?.toLowerCase().includes(term) ||
          chatRoom?.last_name?.toLowerCase().includes(term);
        if (personIsMatch && !this.shownMessages.includes(message)) {
          this.shownMessages.push(message);
        }
      });
      const isTextMatch = message.chatRoom.last_chat_room_message.content.text
        .toLowerCase()
        .includes(term);
      if (isTextMatch && !this.shownMessages.includes(message)) {
        this.shownMessages.push(message);
      }
    });
    this.buildRows(this.shownMessages);
  }

  parseProList(pros: any[]): any[] {
    return pros.map((pro) => {
      return {
        value: pro?.chat_room_key || pro?.id,
        viewValue: pro?.first_name + ' ' + pro?.last_name,
        teamId: pro?.main_team_id,
        chatRoomKey: pro?.chat_room_key,
      };
    });
  }

  parseAndSortMessagesByDate(messages) {
    const array: any = [];
    messages.map((message) => {
      message.chat_rooms.map((chatRoom) => {
        const homekeeper = chatRoom.all_chat_room_members.find(
          (item) => item.type == 'Homekeeper' || item.type == 'PhoneNumber'
        );
        homekeeper.chat_room_key = chatRoom?.key;
        const displayName = this.getDisplayName(
          homekeeper,
          message?.job,
          chatRoom
        );
        const parsedDateTime = this.getJobDateTime(
          chatRoom.last_chat_room_message.sent_at
        );
        const { parsedDate, lastMessageWasSentLessThan8HoursAgo } =
          this.getRelativeMessageDateTime(
            chatRoom?.last_chat_room_message?.sent_at
          );
        array.push({
          isUnread: chatRoom?.last_chat_room_message?.is_read === false && chatRoom?.last_chat_room_message?.from_member?.type !== 'CustomerMember',
          chatRoom,
          job: message.job,
          type: message.type,
          homekeeper,
          relativeDateTime: parsedDate,
          parsedDateTime,
          lastMessageWasSentLessThan8HoursAgo,
          displayName,
        });
      });
    });
    array.sort((a, b) =>
      a.chatRoom.last_chat_room_message.sent_at <
      b.chatRoom.last_chat_room_message.sent_at
        ? 1
        : -1
    );
    return array;
  }

  getDisplayName(homekeeper: any, job?: any, chatRoom?: any): string {
    let displayName = '';
    if (chatRoom.type === 'proxy') {
      const phoneNumberMember = chatRoom?.all_chat_room_members.find(
        (member) => member.type == 'PhoneNumber'
      );
      const parsedPhoneNumber = chatRoom?.data?.phone_number?.replace(
        /^\d{1,3}(\d{3})(\d{3})(\d{4})$/,
        '$1-$2-$3'
      );
      displayName = `${phoneNumberMember?.first_name} ${parsedPhoneNumber}`;
      const pro = this.proList.find(
        (pro) => pro.value === chatRoom?.data?.assigned_to?.id
      );
      const address = this.addressFilter.find(
        (item) => item.value == chatRoom?.data?.assigned_to?.id
      );
      if (pro?.viewValue || address?.viewValue) {
        displayName = `${displayName} @ ${
          pro?.viewValue || address?.viewValue
        }`;
      }
      return displayName;
    }
    displayName = `${homekeeper?.first_name} ${homekeeper?.last_name}`;
    if (job) {
      const jobInfo = this.getJobInfo(job);
      const address = this.addressResponse.find(
        (item) => item.id == job?.address_id
      );
      const addressName = this.getPropertyName(address);
      displayName = `${displayName} for ${jobInfo} @ ${addressName}`;
    }
    return displayName;
  }

  getJobInfo(job: any): string {
    let timezone = job?.timezone_name;
    if (!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const time = job.start_datetime;
    const parsedDate = LuxonDateTime.fromISO(time)
      .setZone(timezone)
      .toFormat('L/d h a')
      .replace(' AM', 'am')
      .replace(' PM', 'pm');
    return `${parsedDate} ${
      job.state.charAt(0).toUpperCase() + job.state.slice(1)
    } Job`;
  }

  getPropertyName(address: any): string {
    if (address?.address_name) {
      return address.address_name;
    }

    if (address?.address2 && address?.address2 !== '') {
      return `${address.address1}, ${address.address2}`;
    }

    return address?.address1;
  }

  dontShowClientMessagesAsUnread() {
    this.messages.map((message) => {
      if (
        message.chatRoom.last_chat_room_message.from_member.type ==
          'CustomerMember' &&
        !message.chatRoom.last_chat_room_message.is_read
      ) {
        message.chatRoom.last_chat_room_message.is_read = true;
      }
    });
  }

  @Loading('', true)
  goToMessagesPage(message: any): void {
    this.storage.save('chatRoomKey', message?.chatRoom?.key);
    try {
      this.markMessagesAsRead(message);
      if (message.type === 'account') {
        this.goToAccountMessagesPage(message);
        return;
      }
      if (message.type === 'proxy') {
        this.goToProxyMessagesPage(message);
        return;
      }
      this.goToJobMessagesPage(message);
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead(message: any) {
    const messages = [...this.messages];
    const index = messages.indexOf(message);
    if (
      message.isUnread &&
      message?.chatRoom?.key
    ) {
      this.communication.markMessagesAsRead([message?.chatRoom?.key]);
      message.isUnread = false;
      message.chatRoom.last_chat_room_message.is_read = true;
      messages.splice(index, 1, message);
      this.messages = this.parseAndSortMessagesByDate(messages);
      this.buildRows(this.messages);
      this.shownMessages = this.messages;
      this.imutableMessages = this.messages;
    }
  }

  async goToAccountMessagesPage(message: any): Promise<void> {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      await this.storage.save('proId', message.homekeeper.id);
      await this.storage.delete('chatRoomKey');
      this.openNewMessageModal(false);
    } else {
      const params = {
        backPage: 'messages',
        proId: message.homekeeper.id
      };
      this.goToPage(
        `pro-messages/${message.homekeeper.id}`,
        params,
        ProMessagesPage
      );
    }
  }

  async goToProxyMessagesPage(message: any): Promise<void> {
    try {
      const isMobileResolution = window.innerWidth <= 870;
      if (!isMobileResolution) {
        await this.storage.save('chatRoomKey', message.chatRoom.key);
        await this.storage.delete('proId');
        this.openNewMessageModal(false);
      } else {
        const proxyUser = {
          ...message.homekeeper,
          data: message?.chatRoom?.data,
          chatRoomKey: message.chatRoom.key,
        };
        this.storage.save('proxyUser', proxyUser);
        this.goToPage(`smart-messages`, {}, ProxyMessagesPage);
      }
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async goToJobMessagesPage(message: any): Promise<void> {
    try {
      const isPastJob =
        message.job.state == 'completed' ||
        message.job.state == 'cancelled_once' ||
        message.job.state == 'cancelled';
      const isPrivateJob = message.job.is_private;
      const messageHomekeeper = message.chatRoom.all_chat_room_members.find(
        (item) => item.type == 'Homekeeper'
      );
      const pros = await this.storage.retrieve('pros');
      let homekeeper = pros.find((pro) => pro.id == messageHomekeeper.id);
      if (!homekeeper) {
        homekeeper = {
          ...messageHomekeeper,
          main_team_id: message?.chatRoom?.data?.team_id,
        }
      }
      this.openJobActivityPage(
        message.job,
        homekeeper,
        isPastJob,
        isPrivateJob
      );
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async openJobActivityPage(
    job: any,
    pro: any,
    isPastJob: boolean,
    isPrivateJob: boolean
  ) {
    try {
      const isMobileResolution = window.innerWidth <= 870;
      if (!isMobileResolution) {
        const params = {
          jobId: job.id,
          pro,
          showSendMessage: true,
          isPastJob,
          isPrivateJob,
        };
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(JobActivityPage);
        this.rightSidePanelService.setDialogPageTitle('Messages');
        this.panelClosedSubscription?.unsubscribe();
        this.panelClosedSubscription = this.rightSidePanelService
          .panelClosed()
          .subscribe(() => {
            this.storage.delete('proId');
            this.storage.delete('chatRoomKey');
            this.reloadMessages();
          });
      } else {
        this.navigateToJobActivityPage(job);
      }
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  navigateToJobActivityPage(job: any): void {
    if (
      job.state == 'completed' ||
      job.state == 'cancelled_once' ||
      job.state == 'cancelled'
    ) {
      localStorage.setItem('pastJobBackPage', 'messages');
      if (job.is_private) {
        this.navCtrl.navigateForward(`past-private-job/${job.id}`);
      } else {
        this.navCtrl.navigateForward(`past-job/${job.id}`);
      }
    } else {
      localStorage.setItem('jobBackPage', 'messages');
      this.navCtrl.navigateForward(`job/${job.address_id}/${job.id}`);
    }
  }

  getJobDateTime(time, timezone?) {
    if (!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return LuxonDateTime.fromISO(time)
      .setZone(timezone)
      .toFormat('L/d h:mm a')
      .replace(' AM', 'am')
      .replace(' PM', 'pm');
  }

  getRelativeMessageDateTime(date: string): {
    parsedDate: string;
    lastMessageWasSentLessThan8HoursAgo: boolean;
  } {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relativeDate = LuxonDateTime.fromISO(date)
      .setZone(timezone)
      .toRelative();
    const lastMessageWasSentLessThan8HoursAgo =
      LuxonDateTime.fromISO(date).setZone(timezone).diffNow('hours').hours < -8;
    if (
      relativeDate.includes('day') ||
      relativeDate.includes('month') ||
      relativeDate.includes('year')
    ) {
      const parsedDate = LuxonDateTime.fromISO(date)
        .setZone(timezone)
        .toFormat('EEE LLL d h:mm a');
      return { parsedDate, lastMessageWasSentLessThan8HoursAgo };
    } else {
      return { parsedDate: relativeDate, lastMessageWasSentLessThan8HoursAgo };
    }
  }

  getProName(message) {
    const pro = message.chatRoom.all_chat_room_members.find(
      (item) => item.type == 'Homekeeper'
    );
    return pro?.first_name;
  }

  onFilterValueChange(event: number[], type: string): void {
    if (type === 'pro') {
      this.selectedPros = event;
    } else {
      this.selectedAddresses = event;
    }
    this.filterRows();
    if (this.searchForm.value.search) {
      this.updateSearch({search: this.searchForm.value.search});
    }
  }

  filterRows() {
    this.messages = this.imutableMessages.filter((message) => {
      const pro = message.homekeeper;
      const address = message?.job?.address_id || message?.chatRoom?.data?.assigned_to?.id;
      const proFilterPassed = this.selectedPros?.length
        ? this.selectedPros.includes(pro.id) || this.selectedPros.includes(pro?.chat_room_key) || this.selectedPros.includes(message?.chatRoom?.data?.assigned_to?.id)
        : true;
      const addressFilterPassed = this.selectedAddresses?.length
        ? this.selectedAddresses.includes(address)
        : true;
      return proFilterPassed && addressFilterPassed;
    });
    this.buildRows(this.messages);
    this.shownMessages = this.messages;
  }

  async openNewMessageModal(shouldDeleteKeys = true): Promise<void> {
    const isMobileResolution = window.innerWidth <= 870;
    if (shouldDeleteKeys) {
      await this.storage.delete('proId')
      await this.storage.delete('chatRoomKey')
    }
    if (!isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(NewMessageModalComponent);
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService
        .panelClosed()
        .subscribe(() => {
          this.storage.delete('proId');
          this.storage.delete('chatRoomKey');
          this.reloadMessages();
        });
    } else {
      this.modalCtrl
        .create({
          component: NewMessageModalComponent,
          mode: 'ios',
          backdropDismiss: true,
          showBackdrop: true,
          swipeToClose: true,
          breakpoints: [0, 0.35, 0.5, 0.8, 1],
          initialBreakpoint: 0.8,
          presentingElement: await this.modalCtrl.getTop(),
          canDismiss: true,
        })
        .then((modal) => {
          modal.present();
          modal.onDidDismiss().then((res) => {
            if (res.data) {
              this.reloadMessages();
            }
          });
        });
    }
  }

  async openAddProxyNumberModal(type: string): Promise<void> {
    const params = {
      type,
      number: type === 'pro' ? this.proNumber : this.guestNumber,
    };
    this.goToPage('add-number', params, AddProxyNumberComponent);
  }

  async patchProxyNumber(): Promise<void> {
    const createdNumber = await this.storage.retrieve('created-number');
    if (createdNumber) {
      if (createdNumber.type === 'homekeeper') {
        this.proNumber = createdNumber.number;
      } else {
        this.guestNumber = createdNumber.number;
      }
    }
  }

  async reloadMessages(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      this.proList = [];
      this.proxyPros = [];
      this.allPros = [];
      await this.getProxyNumbers();
      if (this.filters && Object.keys(this.filters).length !== 0) {
        await this.loadMessages(this.filters);
      } else {
        await this.loadMessages();
      }
      this.filterRows();
      if (this.searchForm.value.search) {
        this.updateSearch({search: this.searchForm.value.search});
      }
      loading.dismiss();
    } catch (error) {
      loading.dismiss();
      console.error(error);
    }
  }

  async loadMessages(filters?: any): Promise<void> {
    const messages = this.showOnboarding ? messagesMockData : await this.communication.getAllMessages(filters);
    this.messages = this.parseAndSortMessagesByDate(messages);
    this.buildRows(this.messages);
    this.shownMessages = this.messages;
    this.imutableMessages = this.messages;
    this.dontShowClientMessagesAsUnread();
  }

  buildRows(messages: any[]): void {
    this.rows = [];
    messages.map((item) => {
      const sentValue = item?.lastMessageWasSentLessThan8HoursAgo
        ? item?.parsedDateTime
        : item?.relativeDateTime;
      const messageValue =
        item?.chatRoom?.last_chat_room_message?.content?.text;
      this.rows.push({
        ...item,
        Thread: {
          value: item?.displayName,
          action: null
        },
        'Last Message': {
          value: messageValue,
          files: item?.chatRoom?.last_chat_room_message?.content?.files,
          action: null,
        },
        Sent: {
          value: sentValue,
          action: null,
        },
        'last-column': {
          value: '',
          action: null,
        },
      });
    });
    this.currentRows = this.rows;
  }

  sortChanged(sort) {
    if (sort.direction == 'asc' || sort.direction == '') {
      this.rows.sort((a, b) =>
        a[sort.active].value > b[sort.active].value ? 1 : -1
      );
      this.currentRows = this.rows;
    } else {
      this.rows.sort((a, b) =>
        a[sort.active].value < b[sort.active].value ? 1 : -1
      );
      this.currentRows = this.rows;
    }
  }

  onTableColumnClick(event) {
    this.goToMessagesPage(event?.element || event);
  }

  async goToPage(url, params, component) {
    const isDesktopRes = this.windowService.isDesktopRes;
    if (isDesktopRes) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService
        .panelClosed()
        .subscribe(() => {
          this.storage.delete('proId');
          this.storage.delete('chatRoomKey');
          this.reloadMessages();
        });
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getTreeData(): void {
    const treeData = [
      {
        name: 'Person',
        children: this.proList.map(pro => ({
          name: pro.viewValue,
          value: pro.value,
          checked: false
        }))
      },
      {
        name: 'Property',
        children: this.addressFilter.map(address => ({
          name: address.viewValue,
          value: address.value,
          checked: false
        }))
      }
    ];
    treeData.forEach((parent, index) => {
      if (parent.children?.length === 0) {
        treeData.splice(index, 1);
      }
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    this.dataSource.data = treeData;
  }

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = this.dataSource.data[
      node.data.parentId
    ].children.find((child) => child.name === node.name).value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip);
    }
  }

  addChip(chip: ChipFilter): void {
    if (!this.selectedFilters) {
      this.selectedFilters = {
        pro: [],
        propertyName: []
      };
    }
    this.selectedFilters[chip.parentName].push(chip.value);
    this.chips.push(chip);
    
    // Update the selected arrays used by filterRows
    if (chip.parentName === 'pro') {
      this.selectedPros = this.selectedFilters.pro;
    } else if (chip.parentName === 'propertyName') {
      this.selectedAddresses = this.selectedFilters.propertyName;
    }
    
    this.filterRows();
    if (this.searchForm.value.search) {
      this.updateSearch({search: this.searchForm.value.search});
    }
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ]?.includes(child.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip.value);
    this.chips = this.chips.filter(
      (item) => item.value !== chip.value
    );

    // Update the selected arrays used by filterRows
    if (chip.parentName === 'pro') {
      this.selectedPros = this.selectedFilters.pro;
    } else if (chip.parentName === 'propertyName') {
      this.selectedAddresses = this.selectedFilters.propertyName;
    }
    
    this.filterRows();
    if (this.searchForm.value.search) {
      this.updateSearch({search: this.searchForm.value.search});
    }
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters?.[matchedParentName]?.includes(foundNodeValue);
  }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: 'tidy-drop-down-menu-item',
  template: `<button mat-menu-item>
              <mat-icon *ngIf="icon">{{icon}}</mat-icon>
              <ng-content></ng-content>
            </button>
            `,
})
export class DropDownMenuItem {
  @Input() icon;

}

<ng-template #content>
  <tidy-wrapper style="padding-bottom: 20px; padding-top: 20px">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-image
        style="width:200px"
        [src]="'assets/img/tidy_logo.svg'">
      </tidy-image>
    </tidy-row>

    <tidy-two-column-row>
      <tidy-button item-left
        [text]="'Log In'"
        [action]="logIn.bind(this)"
        class="primary">
      </tidy-button>
      <tidy-button item-right
        [text]="'Sign Up'"
        [action]="signUp.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-two-column-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'We help care for your property.  With amazing free features including:'">
      </tidy-text>
    </tidy-row>

    <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/book-job.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
          <tidy-row>
            <tidy-text
              [header]="'Easy Instant Booking'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Book a cleaning or other service in seconds.'">
            </tidy-text>
          </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-card>

    <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/to-dos.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
          <tidy-row>
            <tidy-text
              [header]="'Digital To-Do Lists'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Add photos, priorities, and instructions to get things done right.'">
            </tidy-text>
          </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-card>

    <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/integrate.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
          <tidy-row>
            <tidy-text
              [header]="'Integrate AirBnB & More'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Integrate with any channel for automatic bookings.'">
            </tidy-text>
          </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-card>

    <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/user-red.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
          <tidy-row>
            <tidy-text
              [header]="'Manage Existing Pros'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Use TIDY to manage any existing pros, vendors, or even employees.'">
            </tidy-text>
          </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-card>

    <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">

      <tidy-row>
        <ion-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/concierge.svg'"
              style="width:40px;height:50px">
            </tidy-image>
          </tidy-row>
          <ion-col>
          <tidy-row>
            <tidy-text
              [header]="'Concierge Ready to Help'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Our Concierge is easy to contact in-app in just 2 taps.'">
            </tidy-text>
          </tidy-row>
          </ion-col>
        </ion-row>
      </tidy-row>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

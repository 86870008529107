<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Job Issue'"
    [canGoBack]="true"
    [customBack]="'job-issue'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'This job on'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="(job?.job?.date | parseDate: 'ddd') +
          ' ' +
          (job?.job?.date | parseDate: 'M/D')">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'at'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="(job?.job?.start_time | parseDate: 'h:mma')">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'is marked as'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="jobStatus">
        </tidy-text>
        <tidy-text
          [body]="'. What is your issue?'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="selectIssue('bad_quality')">
        <tidy-row>
          <tidy-text
            [body]="'The service quality was bad'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="selectIssue('no_show')">
        <tidy-row>
          <tidy-text
            [body]="'The pro never showed up'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="selectIssue('missing_item')">
        <tidy-row>
          <tidy-text
            [body]="'Something is missing'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="selectIssue('damage_claim')">
        <tidy-row>
          <tidy-text
            [body]="'Something is damaged'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="selectIssue('tip')">
        <tidy-row>
          <tidy-text
            [body]="'I want to leave a tip'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="selectIssue('other_issue')">
        <tidy-row>
          <tidy-text
            [body]="'Other service issue (slowest)'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Util } from 'src/shared/util/util';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Integrations } from 'src/providers/integrations/integrations';

@Component({
  selector: 'app-add-custom-field',
  templateUrl: './add-custom-field.html',
})

export class AddCustomFieldPage implements OnInit {
  form: UntypedFormGroup;
  isRightSideContent = true;
  fieldTypes = [
    { value: 'string', viewValue: 'Text' },
    { value: 'integer', viewValue: 'Number' },
    { value: 'boolean', viewValue: 'Boolean' },
  ];
  visibilityTypes = [
    { value: true, viewValue: 'Public (Visible to all pros)' },
    { value: false, viewValue: 'Private (Visible to only you)' },
  ];
  loaded: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    private integrations: Integrations,
  ) {
    this.form = this.fb.group({
      field_name: ['', Validators.required],
      field_type: ['', Validators.required],
      visibility: ['', Validators.required],
      default_value: [''],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Custom Field');
    this.loaded = true;
  }

  async addCustomField() {
    const loading = await this.util.showLoading();
    try {
      console.log(this.form.value);
      const formValue = this.form.value;
      const payload = {
        field_name: formValue.field_name,
        field_type: formValue.field_type,
        is_visible_to_homekeeper: formValue.visibility,
        default_value: formValue.default_value,
        model_type: 'GuestReservation',
      };
      await this.integrations.addCustomField(payload);
      this.util.showSuccess('Custom field added successfully');
      this.rightSidePanelService.closeRightSidePanel();
    } catch (error) {
      console.error(error);
      const errorMessage = error?.err?.error?.message || 'Error adding custom field';
      this.util.showError(errorMessage);
    } finally {
      await loading.dismiss();
    }
  }
}

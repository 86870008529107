<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [noBow]="true"
    [canGoBack]="true"
    [title]="'Edit Column'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <tidy-text
            [title]="column?.field_name">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'This column is in'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="report?.name + '.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row>
          <tidy-checkbox
            [(checked)]="showColumn"
            [items]="[
              {
                viewValue: 'Show this column', value: true
              }
            ]"
            [initValue]="showColumn">
          </tidy-checkbox>
        </tidy-row>
        <tidy-row>
          <tidy-checkbox
            [(checked)]="sortByThisColumn"
            [items]="[
              {
                viewValue: 'Sort by this column by default', value: true
              }
            ]"
            [initValue]="sortByThisColumn">
          </tidy-checkbox>
        </tidy-row>
        <tidy-row *ngIf="sortByThisColumn">
          <form [formGroup]="form" novalidate action="#">
            <tidy-select
              [form]="form.controls.sortType"
              formControlName="sortType"
              [items]="sortTypeItems">
            </tidy-select>
          </form>
        </tidy-row>
      </tidy-card>

      <tidy-row [alignLastItemRight]="column?.type !== 'date'" *ngIf="filters?.length == 0 && newFilters?.length == 0">
        <tidy-text
          [header]="'Column Filters'">
        </tidy-text>
        <tidy-text
          *ngIf="column?.type !== 'date'"
          [body]="'Add Filter'"
          class="link"
          (action)="addFilter()">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="(filters?.length !== 0 || newFilters?.length !== 0) && column?.type !== 'date'">
        <tidy-text
          [header]="'Column Filters'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="filters?.length == 0 && newFilters?.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'No filters added'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngFor="let filter of filters">
        <tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              *ngIf="column?.type !== 'date'"
              [body]="(filter?.operator | titlecase) + ': ' + filter?.value">
            </tidy-text>
            <tidy-text
              *ngIf="column?.type == 'date'"
              [body]="'Date filter applied'">
            </tidy-text>
            <tidy-text
              [body]="'Remove'"
              (action)="removeFilter(filter)"
              class="link">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngFor="let filter of newFilters">
        <tidy-card *ngIf="column?.type == 'number' || column?.type == 'string'">
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [form]="form.controls.operator"
                formControlName="operator"
                [items]="operatorItems">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-input
                [form]="form.controls.value"
                formControlName="value"
                [label]="'The following value'"
                [submitted]="submitted"
                [errorMessage]="'Please select a value.'">
              </tidy-input>
            </tidy-row>
            <tidy-row [align]="'center'" class="extra-top-padding">
              <tidy-text
                [body]="'Remove'"
                (action)="removeNewFilter()"
                class="link">
              </tidy-text>
            </tidy-row>
          </form>
        </tidy-card>
      </ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="saveChanges.bind(this)"
        [text]="'Save Changes'"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

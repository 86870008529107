import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AccountNotificationSettingsPage } from '../../account-notification-settings/account-notification-settings';

@Component({
  templateUrl: 'webhook-attempt.html',
  encapsulation: ViewEncapsulation.None
})

export class WebhookAttemptPage implements OnInit {

  loaded: boolean;
  title: string;
  request: any;
  response: any;
  error: any;
  requestJSON: string;
  responseJSON: string;
  errorJSON: string;
  status: string;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;

    this.status = await this.getParam('status');
    this.title = await this.getParam('customTitle');
    this.rightSidePanelService.setDialogPageTitle(this.title);
    this.request = await this.getParam('request');
    this.response = await this.getParam('response');
    this.error = await this.getParam('errorReturned');

    this.setAttemptDetails(this.request, this.response);
    this.setErrorDetails(this.error);

    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  setErrorDetails(err: any) {
    this.errorJSON = err;
  }

  setAttemptDetails(request: any, response: any) {
    this.requestJSON = request;
    this.responseJSON = response;
  }

  async goToWebhookDetails() {
    const nextPage = await this.getParam('nextPage');
    if (nextPage == 'webhook-details' && this.title !== 'Attempt Details') {
      const webhook = await this.getParam('webhook');
      localStorage.setItem('webhookDescription', webhook.description);
      localStorage.setItem('webhookId', webhook.id);
      localStorage.setItem('webhookState', webhook.state);
      localStorage.setItem('webhookUrl', webhook.webhook_url);
      localStorage.setItem('webhookChannel', webhook.channel);
      localStorage.setItem('webhookTeamId', webhook?.customer_account_team?.id);
      localStorage.setItem('webhookTeamName', webhook?.customer_account_team?.name);
      localStorage.setItem('webhookEvents', this.buildWebhookEventsList(webhook.events));
      localStorage.setItem('webhookDetailsBackPage', 'webhooks');
    }
    const params = {
      webhookDetailsBackPage: 'webhooks'
    }
    this.rightSidePanelService.navigateTo(nextPage, params, AccountNotificationSettingsPage);
  }

  buildWebhookEventsList(events) {
    let list = '';
    events.map((event, i) => {
      list += event.display_name;
      if (i < events.length - 1) {
        list += ', ';
      }
    })
    return list;
  }
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Add Integration'"
    [customBack]="backButton">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container>

      <ng-container *ngIf="loaded">
        <tidy-alert>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-top-padding" style="padding-bottom:0px">
              <tidy-input
                #searchInput
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="form">
              </tidy-input>
            </tidy-row>
          </form>
        </tidy-alert>
        <tidy-card *ngFor="let option of integrationOptions">
          <tidy-card-button (action)="goToAddIntegration(option)" class="no-below-stroke">
            <tidy-image
              [src]="option.logo_url"
              style="width:70%; height:30px">
            </tidy-image>
          </tidy-card-button>
          <tidy-row *ngIf="option.short_description !== null">
            <tidy-text
              [body]="option.short_description">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="!loaded">
        <tidy-alert style="height:69px">
        </tidy-alert>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
        <tidy-card>
          <tidy-card-button [hideArrow]="true" class="no-below-stroke">
            <ion-skeleton-text animated="true" style="width: 100px; height: 50px"></ion-skeleton-text>
          </tidy-card-button>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

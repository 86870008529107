<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [title]="'Automations'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'schedule'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="windowService?.isDesktopRes">
      <tidy-text
        [title]="'Automations'">
      </tidy-text>
      <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
    </tidy-desktop-header>

    <div class="two-column-layout" *ngIf="!loaded">
      <div class="column">
        <tidy-card style="padding: 0px; height: 127px; padding: 0px;">
          <ion-skeleton-text animated style="width: 100%; height: 127px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
        <tidy-card style="padding: 0px; height: 40px; padding: 0px;">
          <ion-skeleton-text animated style="width: 100%; height: 40px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
        <tidy-card style="padding: 0px; height: 58px; padding: 0px;">
          <ion-skeleton-text animated style="width: 100%; height: 58px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
        <tidy-card style="padding: 0px; height: 58px; padding: 0px;">
          <ion-skeleton-text animated style="width: 100%; height: 58px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
      </div>
      <div class="column">
        <tidy-card style="padding: 0px; height: 313px; padding: 0px;">
          <ion-skeleton-text animated style="width: 100%; height: 313px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
      </div>
    </div>

      <div class="two-column-layout" *ngIf="loaded">
        <div class="column">
          <tidy-card>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/web-programming.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Custom Workflows'">
              </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="goToWorkflows()">
              <tidy-text
                [body]="'View Workflows'">
              </tidy-text>
            </tidy-card-button>
            <tidy-card-button (action)="goToWorkflowHistory()">
              <tidy-text
                [body]="'Workflow Run History'">
              </tidy-text>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToJobRequestWorkflows()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/icon/searching.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Job Request Workflows'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToAutomaticBooking()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/automatic-booking.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Automatic Booking'">
                </tidy-text>
              </tidy-row>
              <tidy-text
                [body]="automaticBookingAddresses?.length">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'properties have automatic booking on'">
              </tidy-text>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToNotifications()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Automatic Notifications'">
                </tidy-text>
              </tidy-row>
              <tidy-text
                [body]="notificationSettings?.length">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'automatic notifications added'">
              </tidy-text>
            </tidy-card-button>
          </tidy-card>
        </div>
        <div class="column">
          <tidy-card>
            <apx-chart
              [series]="chartOptions?.series"
              [chart]="chartOptions?.chart"
              [xaxis]="chartOptions?.xaxis"
              [yaxis]="chartOptions?.yaxis"
              [dataLabels]="chartOptions?.dataLabels"
              [tooltip]="chartOptions?.tooltip">
            </apx-chart>
          </tidy-card>
        </div>
      </div>

      <tidy-row>
        <tidy-text
          [header]="'How to Use Automations:'">
        </tidy-text>
      </tidy-row>

      <tidy-card style="padding: 15px 15px 15px 15px" *ngFor="let text of salesText?.workflows; let last = last">
        <tidy-row style="padding-bottom: 0px">
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="text.image"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="text.header">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.description">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.example">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>

      <tidy-card style="padding: 15px 15px 15px 15px" *ngFor="let text of salesText?.automaticBooking; let last = last">
        <tidy-row style="padding-bottom: 0px">
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="text.image"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="text.header">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.description">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.example">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>

      <tidy-card style="padding: 15px 15px 15px 15px" *ngFor="let text of salesText?.automaticNotifications; let last = last">
        <tidy-row style="padding-bottom: 0px">
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="text.image"
                style="width:40px;height:50px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="text.header">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.description">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="text.example">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="onboardingFlow">
</tidy-onboarding-modal>

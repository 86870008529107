<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [noBow]="true"
    [title]="'Integrations'"
    [customBack]="backPage"
    [showLinkIcon]="true"
    [linkIcon]="'help-circle'"
    (linkAction)="goToContactConcierge()"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }">
</ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"> </ng-container>
    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="windowService.isDesktopRes">
      <tidy-desktop-header>
        <tidy-image
          [src]="'assets/svg/arrow-back-black.svg'"
          (action)="goBack()"
          class="desktop-back-icon">
        </tidy-image>
        <tidy-text
          [title]="'Integrations'">
        </tidy-text>
      </tidy-desktop-header>
    </ng-container>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-card-button (action)="goToActiveIntegrations()" class="arrow-to-top" [hideArrow]="activeIntegrations?.length == 0">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Active Integrations'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="activeIntegrations?.length == 0">
            <tidy-text
              *ngIf="integrationTypeForm?.value?.type == 'all'"
              [body]="'No integrations added'">
            </tidy-text>
            <tidy-text
              *ngIf="integrationTypeForm?.value?.type !== 'all'"
              [body]="('No ' | translate) + selectedIntegrationTypeName.toLowerCase() + (' integrations added' | translate)">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let integration of activeIntegrations">
            <ng-container *ngIf="integration.format == 'lock'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration-images/remote-lock.png'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="'RemoteLock'"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  [body]="': ' + integratedDevicesCount + (' devices integrated' | translate)">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.addresses?.length"
                  [body]="' (' + integration?.addresses?.length + ' ' + (integration?.addresses?.length === 1 ? 'address' : 'addresses') + ')'">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="integration.format == 'notification'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration-images/' + integration?.integration?.channel + '.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="integration?.integration?.channel == 'webhook' ? 'Webhook' : 'Slack'"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  [body]="': ' + integration?.integration?.webhook_url">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.addresses?.length"
                  [body]="' (' + integration?.addresses?.length + ' ' + (integration?.addresses?.length === 1 ? ('address' | translate) : ('addresses' | translate)) + ')'">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="integration.format !== 'notification' && integration.format !== 'lock'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  *ngIf="integration?.guest_reservation_source_type?.format == 'api'"
                  [src]="'assets/img/gold-link.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-image
                  *ngIf="integration?.guest_reservation_source_type?.format == 'concierge_integration'"
                  [src]="'assets/img/concierge.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-image
                  *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'"
                  [src]="'assets/img/ical.png'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="integration.guest_reservation_source_type?.name | titlecase">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'api'"
                  [body]="': Direct Integration'">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'concierge_integration'"
                  [body]="': Concierge Integration'">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'"
                  [body]="': iCal Integration '">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  *ngIf="integration?.addresses?.length"
                  [body]="' (' + integration?.addresses?.length + ' ' + (integration?.addresses?.length === 1 ? ('address' | translate) : ('addresses' | translate)) + ')'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-card-button>
      </tidy-card>

      <tidy-row *ngIf="pendingIntegrations?.length">
        <tidy-text
          [header]="'Pending Integrations'">
       </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let integration of pendingIntegrations">
        <tidy-card>
          <tidy-card-button (action)="goToUpdateIntegration(integration)" [hideArrow]="integration.state !== 'paused'" class="arrow-to-top">
            <ng-container *ngIf="integration.format == 'lock'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration-images/remote-lock.png'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'RemoteLock'"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="integration.format == 'notification'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration-images/' + integration?.integration?.channel + '.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="integration?.integration?.channel == 'webhook' ? 'Webhook' : 'Slack'"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="integration.format !== 'notification' && integration.format !== 'lock'">
              <tidy-row class="vertical-align-center">
                <ion-spinner *ngIf="integration.state !== 'paused'" name="lines" class="card-loading-spinner"></ion-spinner>
                <tidy-image
                  *ngIf="integration.state == 'paused'"
                  [src]="'assets/img/action-needed.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="integration.guest_reservation_source_type?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'api'"
                  [body]="'Direct Integration'">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'concierge_integration'"
                  [body]="'Concierge Integration'">
                </tidy-text>
                <tidy-text
                  *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'"
                  [body]="'iCal Integration '">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="integration.state !== 'paused' && integration?.guest_reservation_source_type?.format !== 'concierge_integration'">
              <ng-container *ngIf="!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length <= 0">
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    body="Sync Pending..">
                  </tidy-text>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length > 0">
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    body="Syncing guest reservations..">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
            <tidy-row *ngIf="integration.state == 'paused'">
              <tidy-text
                body="There was an issue syncing this integration. Please update your integration details.">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
      
      <div style="margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center;">
        <tidy-text
            [header]="'Add Integrations'">
        </tidy-text>
        <tidy-checkbox
          (checkedChange)="toggleShowDiscounts()"
          [(checked)]="termsChecked"
          [items]="[
            {
              viewValue: 'Show Discounts', value: true
            }
          ]"
          [initValue]="false">
        </tidy-checkbox>
      </div>

      <ng-container *ngIf="loaded">
        <div class="desktop-nav-search full-width" style="margin-bottom: 10px">
          <form [formGroup]="form" novalidate action="#">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </form>
        </div>
      </ng-container>

      <ng-container *ngFor="let item of integrationItems" style="display: flex">
        <div class="integrations-chip" (click)="changeSelectedIntegrationType(item.value)" [ngStyle]="{'background-color': item.show ? '#1E2125' : 'rgba(30, 33, 37, 0.1)'}" >
          <div class="integrations-chip-text" [class.white]="item.show" >
            {{item.viewValue | translate}}
          </div>
        </div>
      </ng-container>

      <tidy-row>
        <tidy-text
          [body]="'<b>Featured</b>'">
       </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="!shownAllFeaturedIntegrations?.length || (showDiscounts && !hasShownAllFeaturedIntegrationsDiscounts)">
        <tidy-text
          [body]="'No results'">
        </tidy-text>
      </tidy-alert>

      <ng-container *ngFor="let integration of shownAllFeaturedIntegrations; let i = index; let last = last; let even = even">
        <div style="position: relative;" *ngIf="showDiscounts ? integration?.discount : true">
          <tidy-image
            *ngIf="integration?.discount"
            [src]="'assets/img/star.svg'"
            style="width: 25px; position: absolute; top: -10px; left: -10px; z-index: 1;">
          </tidy-image>
          <tidy-card style="padding: 10px; position: relative; z-index: 0;">
            <tidy-card-button (action)="goToAddIntegration(integration)">
              <tidy-row>
                <div style="display: flex; align-items: center;">
                  <div style="flex: 0 0 auto; width: 60px; margin: auto;">
                    <tidy-image
                      [src]="integration?.logo_url"
                      style="width:100%; max-height: 30px">
                    </tidy-image>
                  </div>
                  <div style="flex: 1; margin-left: 10px;">
                    <tidy-row class="vertical-align-center">
                      <tidy-text
                        [header]="integration?.name"
                        [translate]="false">
                      </tidy-text>
                      <ng-container *ngIf="integration?.discount && windowService.isDesktopRes">
                        <tidy-image
                          [src]="'assets/img/coupon.svg'"
                          style="margin-left: 10px; width: 20px">
                        </tidy-image>
                        <tidy-text
                          [body]="integration?.discount">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                    <tidy-row *ngIf="integration?.discount && !windowService.isDesktopRes" class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/img/coupon.svg'"
                        style="width: 20px">
                      </tidy-image>
                      <tidy-text
                        [body]="integration?.discount">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="integration?.short_description !== null">
                      <tidy-text
                        [body]="integration?.short_description"
                        style="overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                                line-clamp: 2;
                        -webkit-box-orient: vertical;">
                      </tidy-text>
                    </tidy-row>
                  </div>
                </div>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </div>
      </ng-container>

      <tidy-row>
        <tidy-text
          [body]="'<b>All Integrations</b>'">
       </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="!shownAllIntegrations?.length || (showDiscounts && !hasShownAllIntegrationsDiscounts)">
        <tidy-text
          [body]="'No results'">
        </tidy-text>
      </tidy-alert>

      <ng-container *ngFor="let integration of shownAllIntegrations; let i = index; let last = last">
        <div style="position: relative;" *ngIf="!windowService?.isDesktopRes && (showDiscounts ? integration?.discount : true)">
          <tidy-image
            *ngIf="integration?.discount"
            [src]="'assets/img/star.svg'"
            style="width: 25px; position: absolute; top: -10px; left: -10px; z-index: 1;">
          </tidy-image>
          <tidy-card style="padding: 10px">
            <tidy-card-button (action)="goToAddIntegration(integration)">
              <tidy-row>
                <ion-row>
                  <ion-col size="3" style="margin: auto">
                      <tidy-image
                      [src]="integration?.logo_url"
                      style="width:100%; max-height: 65px">
                      </tidy-image>
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="integration?.name"
                        [translate]="false">
                      </tidy-text>
                      <ng-container *ngIf="integration?.discount">
                        <tidy-image
                          [src]="'assets/img/coupon.svg'"
                          style="margin-left: 10px; width: 20px">
                        </tidy-image>
                        <tidy-text
                          [body]="integration?.discount">
                        </tidy-text>
                      </ng-container>
                    </tidy-row>
                    <tidy-row *ngIf="integration?.short_description !== null">
                      <tidy-text
                        [body]="integration?.short_description"
                        style="overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                                line-clamp: 2;
                        -webkit-box-orient: vertical;">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </div>
        <div *ngIf="i % 3 === 0 && windowService?.isDesktopRes" style="margin-bottom: -10px; display: flex; gap: 10px;">
          <div style="position: relative; flex: 1; width: 33.33%; display: flex; flex-direction: column;" *ngIf="showDiscounts ? integration?.discount : true">
            <tidy-image
              *ngIf="integration?.discount"
              [src]="'assets/img/star.svg'"
              style="width: 25px; position: absolute; top: -10px; left: -10px; z-index: 1;">
            </tidy-image>
            <tidy-card style="padding: 10px; flex-grow: 1; display: flex; flex-direction: column;">
              <tidy-card-button (action)="goToAddIntegration(integration)" style="flex-grow: 1; display: flex; flex-direction: column;">
                <tidy-row style="flex-grow: 1;">
                  <ion-row style="height: 100%;">
                    <ion-col size="3" style="margin: auto">
                        <tidy-image
                        [src]="integration?.logo_url"
                        style="width:100%; max-height: 65px">
                        </tidy-image>
                    </ion-col>
                    <ion-col style="display: flex; flex-direction: column;">
                      <tidy-row class="vertical-align-middle">
                        <tidy-text
                          [header]="integration?.name"
                          [translate]="false">
                        </tidy-text>
                        <ng-container *ngIf="integration?.discount">
                          <tidy-image
                            [src]="'assets/img/coupon.svg'"
                            style="margin-left: 10px; width: 20px">
                          </tidy-image>
                          <tidy-text
                            [body]="integration?.discount">
                          </tidy-text>
                        </ng-container>
                      </tidy-row>
                      <tidy-row *ngIf="integration?.short_description !== null" style="flex-grow: 1;">
                        <tidy-text
                          [body]="integration?.short_description"
                          style="overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                                  line-clamp: 2;
                          -webkit-box-orient: vertical;">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-card-button>
            </tidy-card>
          </div>
          <div style="position: relative; flex: 1; width: 33.33%; display: flex; flex-direction: column;" *ngIf="showDiscounts ? integration?.discount : true">
            <tidy-image
              *ngIf="shownAllIntegrations[i+1]?.discount"
              [src]="'assets/img/star.svg'"
              style="width: 25px; position: absolute; top: -10px; left: -10px; z-index: 1;">
            </tidy-image>
            <tidy-card style="padding: 10px; flex-grow: 1; display: flex; flex-direction: column;" *ngIf="shownAllIntegrations[i+1]">
              <tidy-card-button (action)="goToAddIntegration(shownAllIntegrations[i+1])" style="flex-grow: 1; display: flex; flex-direction: column;">
                <tidy-row style="flex-grow: 1;">
                  <ion-row style="height: 100%;">
                    <ion-col size="3" style="margin: auto">
                      <tidy-image
                      [src]="shownAllIntegrations[i+1]?.logo_url"
                      style="width:100%; max-height: 65px">
                      </tidy-image>
                    </ion-col>
                    <ion-col style="display: flex; flex-direction: column;">
                      <tidy-row class="vertical-align-middle">
                        <tidy-text
                          [header]="shownAllIntegrations[i+1]?.name"
                          [translate]="false">
                        </tidy-text>
                        <ng-container *ngIf="shownAllIntegrations[i+1]?.discount">
                          <tidy-image
                            [src]="'assets/img/coupon.svg'"
                            style="margin-left: 10px; width: 20px">
                          </tidy-image>
                          <tidy-text
                            [body]="shownAllIntegrations[i+1]?.discount">
                          </tidy-text>
                        </ng-container>
                      </tidy-row>
                      <tidy-row *ngIf="shownAllIntegrations[i+1]?.short_description !== null" style="flex-grow: 1;">
                        <tidy-text
                          [body]="shownAllIntegrations[i+1]?.short_description"
                          style="overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                                  line-clamp: 2;
                          -webkit-box-orient: vertical;">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-card-button>
            </tidy-card>
          </div>
          <div style="position: relative; flex: 1; width: 33.33%; display: flex; flex-direction: column;" *ngIf="showDiscounts ? integration?.discount : true">
            <tidy-image
              *ngIf="shownAllIntegrations[i+2]?.discount"
              [src]="'assets/img/star.svg'"
              style="width: 25px; position: absolute; top: -10px; left: -10px; z-index: 1;">
            </tidy-image>
            <tidy-card style="padding: 10px; flex-grow: 1; display: flex; flex-direction: column;" *ngIf="shownAllIntegrations[i+2]">
              <tidy-card-button (action)="goToAddIntegration(shownAllIntegrations[i+2])" style="flex-grow: 1; display: flex; flex-direction: column;">
                <tidy-row style="flex-grow: 1;">
                  <ion-row style="height: 100%;">
                    <ion-col size="3" style="margin: auto">
                      <tidy-image
                      [src]="shownAllIntegrations[i+2]?.logo_url"
                      style="width:100%; max-height: 65px">
                      </tidy-image>
                    </ion-col>
                    <ion-col style="display: flex; flex-direction: column;">
                      <tidy-row class="vertical-align-middle">
                        <tidy-text
                          [header]="shownAllIntegrations[i+2]?.name"
                          [translate]="false">
                        </tidy-text>
                        <ng-container *ngIf="shownAllIntegrations[i+2]?.discount">
                          <tidy-image
                            [src]="'assets/img/coupon.svg'"
                            style="margin-left: 10px; width: 20px">
                          </tidy-image>
                          <tidy-text
                            [body]="shownAllIntegrations[i+2]?.discount">
                          </tidy-text>
                        </ng-container>
                      </tidy-row>
                      <tidy-row *ngIf="shownAllIntegrations[i+2]?.short_description !== null" style="flex-grow: 1;">
                        <tidy-text
                          [body]="shownAllIntegrations[i+2]?.short_description"
                          style="overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                                  line-clamp: 2;
                          -webkit-box-orient: vertical;">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-card-button>
            </tidy-card>
          </div>
        </div>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-skeleton-text animated="true" style="width: 100%; height:14px"></ion-skeleton-text>
        <ion-skeleton-text animated="true" style="width: 130px; margin: auto; height:14px"></ion-skeleton-text>
      </tidy-row>
      <tidy-row class="extra-top-padding">
        <ion-skeleton-text animated="true" style="width: 140px; height: 16px"></ion-skeleton-text>
      </tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%; height: 45px"></ion-skeleton-text>
      <tidy-row class="extra-top-padding">
        <ion-skeleton-text animated="true" style="width: 148px; height: 16px"></ion-skeleton-text>
      </tidy-row>
      <ion-skeleton-text animated="true" style="width: 100%; height: 70px"></ion-skeleton-text>
      <tidy-card style="padding: 10px" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]">
        <tidy-card-button (action)="goToAddIntegration(integration)" [hideArrow]="true">
          <tidy-row>
            <ion-row>
              <ion-col size="3" style="margin: auto">
                <ion-skeleton-text animated="true" style="width: 100%; height: 65px"></ion-skeleton-text>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <ion-skeleton-text animated="true" style="width: 90px; height: 16px"></ion-skeleton-text>
                </tidy-row>
                <tidy-row>
                  <ion-skeleton-text animated="true" style="width: 100%; height: 14px"></ion-skeleton-text>
                  <ion-skeleton-text animated="true" style="width: 100%; height: 14px"></ion-skeleton-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content">
</app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'selectIntegration'">
</tidy-onboarding-modal>

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';

import { Client } from 'src/providers/client/client';
import { CustomNavParams } from 'src/shared/providers/custom-nav-params/custom-nav-params';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading'
import { WindowService } from 'src/shared/providers/window.service';

import { CleaningCards } from 'src/providers/cleaning-cards/cleaning-cards';
import { FeedbackCleaning } from 'src/providers/feedback/feedback';
import { CleaningTypes } from 'src/providers/cleaning-types/cleaning-types';
import { Pros } from "src/providers/pros/pros";
import { StaticData } from "src/providers/static-data/static-data";
import { Logger } from "src/providers/logger";
import { TimeoutableComponent } from "src/shared/components/timeoutable/timeoutable.component";

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { SuccessPage } from "src/shared/pages/success/success";

@Component({
  templateUrl: 'more-info.html',
  encapsulation: ViewEncapsulation.None
})

export class NeedsWorkMoreInfoPage extends TimeoutableComponent implements OnInit {

  answers: any;
  blockPro: { viewValue: string; value: boolean; }[];
  badAttitude: any;
  badAttitudeKeys: any;
  badSuppKeys: any;
  badSupp: any;
  cleaningId: any;
  cleaningDuration: number;
  didntFinish: any;
  errorMessage: string;
  feedbackData: any;
  feedbackForm: any;
  finishTypes: { viewValue: string; value: string; }[];
  hkJobId: any;
  hkName: string;
  isCleaningJob: boolean;
  pro: any;
  prosData: any;
  rating = 0;
  stars = new Array(5);
  submitted: boolean = false;
  timeOpts = [];
  timeFormA: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private customNavParams: CustomNavParams,
    private feedbackCleaning: FeedbackCleaning,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private cleaningCards: CleaningCards,
    private cleaningTypes: CleaningTypes,
    private staticData: StaticData,
    private modalCtrl: ModalController,
    private pros: Pros,
    private logger: Logger,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Needs Work');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      await this.loadParams();
      await this.loadPageData();
      this.loaded = true;
      this.loadCleaningDuration();
    } catch (err) {
      console.error('err', err);
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async loadParams() {
    this.pro = await this.getParam('pro');
    this.cleaningId = await this.getParam('cleaningId') || this.route.snapshot.paramMap.get('cleaningId');
    this.hkJobId =  await this.getParam('hkJobId') || this.route.snapshot.paramMap.get('hkJobId');
  }

  async loadPageData() {
    this.setAuxiliarForms();
    this.feedbackData = await this.feedbackCleaning.getFeedback({jobId: this.cleaningId, hkJobId: this.hkJobId});
    this.hkName = await this.getParam('hkName');
    this.answers = this.feedbackCleaning.parseAnswers(this.feedbackData);
    this.timeOpts = this.staticData.selectTimeOpts();
    const controls = this.feedbackCleaning.getLearnMoreKeys();
    this.badAttitudeKeys = this.feedbackCleaning.getBadAttitudeKeys();
    this.badAttitude = this.feedbackCleaning.getBadAttitudeQuestions();
    this.badSuppKeys = this.feedbackCleaning.getBadSuppliesKeys();
    this.badSupp = this.feedbackCleaning.getBadSuppliesQuestions();
    this.feedbackForm = this.formBuilder.group(controls);
    this.isCleaningJob = await this.getParam('isCleaningJob')
  }

  setAuxiliarForms(){
    this.finishTypes = [
      {viewValue: 'Worked too slowly', value: 'didnt_finish.cleaned_slowly'},
      {viewValue: 'Didn\'t follow my To Dos', value: 'didnt_finish.didnt_follow'},
      {viewValue: 'Needed more time for the job', value: 'didnt_finish.needed_more_time'},
      {viewValue: 'Didn\'t stay the full duration', value: 'didnt_finish.didnt_stay.arrived_at'}
    ];
    this.blockPro = [
      {viewValue: 'Worked too slowly', value: false},
      {viewValue: 'Didn\'t follow my To Dos', value: true},
    ];
  }

  @Loading('', true)
  async loadCleaningDuration () {
    const cleaning = await this.cleaningCards.getCleaning(this.cleaningId);
    const cleaningType = this.cleaningTypes.getCleaningTypeHkNumber(cleaning.job.cleaning_service_type);
    this.cleaningDuration = cleaningType.duration;
  }

  setStarRate(value) {
    this.feedbackForm.controls["bad_ability.star_rating"].setValue(value+1);
    this.rating = value +1;
  }

  setDidntFinish(option: RadioButtonModel) {
    this.didntFinish = option.value;
    const fields = [
      'didnt_finish.cleaned_slowly',
      'didnt_finish.didnt_follow',
      'didnt_finish.needed_more_time',
      'didnt_finish.didnt_stay.arrived_at',
      'didnt_finish.didnt_stay.left_at'
    ];
    fields.forEach(field => {
      this.feedbackForm.controls[field].setValue(null);
    });
    this.feedbackForm.controls[option.value].setValue(true);
  }

  starMessage(index) {
    const messages = [
      `Worst job ever`,
      `Very bad - Definitely don’t want them again`,
      `Not great - I’d prefer a different pro`,
      `Ok - I think they could get it next time`,
      `Good but missed the mark on some To Dos`
    ];
    return messages[index - 1];
  }

  @Loading('', true)
  async sendFeedback(){
    this.submitted = true;
    this.errorMessage = '';
    if (this.feedbackForm.invalid) {
      return this.errorMessage = 'Make sure to fill all required fields';
    }
    if (this.feedbackForm.value.blacklist_hk) {
      return this.blockCurrentPro();
    }
    this.confirmSendFeedback();
  }

  async confirmSendFeedback() {
    try {
      const params = {
        jobId: this.cleaningId,
        hkJobId: this.hkJobId,
        type: 'negative'
      };
      const successParams = this.mountSuccessPageParams();
      await this.feedbackCleaning.submitMoreNegativeFeedback(params, this.feedbackForm.value, true);
      await this.client.getMoreDetailAddresses(true);
      this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
    } catch (err){
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async blockCurrentPro() {
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Block ' + this.hkName + '?',
      body: 'Never use this pro again for anything in the account?',
      backText: 'Go Back',
      confirmText: 'Block Them - Never Use Again',
      confirmAction: this.confirmBlockCurrentPro.bind(this)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmBlockCurrentPro(){
    const serviceTypeId = 1;
    this.prosData = await this.pros.getPros(serviceTypeId);
    let collectionToAdd = this.prosData.blocked;
    const approvedIndex = this.prosData.approved.findIndex(pro => pro.object.id == this.pro.id);
    const favoritedIndex = this.prosData.favorited.findIndex(pro => pro.object.id == this.pro.id);
    const blockedIndex = this.prosData.blocked.findIndex(pro => pro.object.id == this.pro.id);
    let removedElement: any[];
    if (approvedIndex >= 0) {
      removedElement = this.prosData.approved.splice(approvedIndex, 1);
    }
    if (favoritedIndex >= 0) {
      removedElement = this.prosData.favorited.splice(favoritedIndex, 1);
    }
    if (blockedIndex >= 0) {
      removedElement = this.prosData.blocked.splice(blockedIndex, 1);
    }
    if (removedElement) {
      const indexToAdd = 0;
      collectionToAdd.splice(indexToAdd, 0, ...removedElement);
    }
    try {
      await this.pros.updateProsPriorities(this.prosData, serviceTypeId);
      this.modalCtrl.dismiss();
      this.confirmSendFeedback();
    } catch (err) {
      this.logger.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  updateDidntFinish(key, time) {
    this.feedbackForm.controls[key].setValue(time);
  }

  mountSuccessPageParams() {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    return {
      header: 'Feedback Submitted',
      body: 'Thanks so much for your feedback, we do take it very seriously and will keep it anonymous.',
      buttonText: 'Go to My Schedule',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
  }
}

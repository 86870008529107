<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="title"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="text">
        </tidy-text>
      </tidy-row>

      <tidy-two-column-row>
        <tidy-button
          [text]="'Go Back'"
          [action]="goBack.bind(this)"
          class="secondary">
        </tidy-button>
        <tidy-button
          [text]="'Confirm'"
          [action]="confirm.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-two-column-row>

    </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

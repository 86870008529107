import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { ScheduleCardModel } from 'src/models/schedule.model';
import { ToDoListRoomModel } from 'src/models/to-do.model';

@Component({
  templateUrl: 'apply-to-jobs.html',
  encapsulation: ViewEncapsulation.None
})

export class ToDosApplyToJobsPage implements OnInit {

  addressID: any;
  hasTwoOrMoreLists: boolean;
  jobs: ScheduleCardModel[];
  isRightSideContent: boolean;
  listIsDefault: boolean;
  listName: string;
  loaded: boolean;
  notSelectedJobs = [];
  needsNewDefault: boolean;
  numberJobsToShow: number = 5;
  selectedAddressId: any;
  selectedJobs = [];
  startedAsDefault: boolean;
  toDoState: string;
  toDoLists: ToDoListRoomModel[];

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private schedule: Schedule,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      await this.getParams();
      await this.getJobs();
      this.loaded = true;
    } catch (err) {
    }
  }

  async getParams() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Apply List to Jobs');
    this.listName = this.navCtrl.getParam('listName') || dialogParams.listName;
    this.toDoState = this.navCtrl.getParam('toDoState') || dialogParams.toDoState;
    this.listIsDefault = this.navCtrl.getParam('listIsDefault') || dialogParams.listIsDefault;
    this.startedAsDefault = this.listIsDefault;
    this.hasTwoOrMoreLists = this.navCtrl.getParam('hasTwoOrMoreLists') || dialogParams.hasTwoOrMoreLists;
    this.toDoLists = this.navCtrl.getParam('toDoLists') || dialogParams.toDoLists;
    this.addressID = localStorage.getItem('addressId') || dialogParams.addressId;
  }

  showJob(i) {
    if (i < this.numberJobsToShow) {
      return true;
    }
  }

  showMoreJobs() {
    this.numberJobsToShow = 100;
  }

  async getJobs() {
    const scheduleCards = await this.schedule.getCards(this.addressID);
    this.jobs = this.schedule.parseJobs(scheduleCards);
    this.parseSelectedJobs();
  }

  selectJob(jobId) {
    let jobAlreadySelected = false;
    this.selectedJobs.find((job) => {
      if (jobId === job) {
        jobAlreadySelected = true;
      };
    });
    if (!jobAlreadySelected) {
      this.selectedJobs.push(jobId);
      this.notSelectedJobs = this.notSelectedJobs.filter((job) => {
        if (jobId !== job) {
          return job;
        };
      });
    } else {
      this.notSelectedJobs.push(jobId);
      this.selectedJobs = this.selectedJobs.filter((job) => {
        if (jobId !== job) {
          return job;
        };
      });
    };
  }

  async saveChanges() {
    this.storage.delete('addresses');
    const taskListId = localStorage.getItem('taskListId');
    if (this.selectedJobs.length > 0) {
      await this.toDos.assignListToJob(this.selectedJobs, taskListId);
    }
    if (this.notSelectedJobs.length > 0) {
      await this.toDos.assignListToJob(this.notSelectedJobs, null);
    }
    if (this.hasTwoOrMoreLists) {
      if (this.startedAsDefault && !this.listIsDefault) {
        const params = {
          toDoLists: this.toDoLists,
          defaultListId: this.toDoLists[0].id,
          cameFromApplyListToJobs: true
        }
        return this.rightSidePanelService.navigateTo('select-default-list', params);
      } else if (!this.startedAsDefault && this.listIsDefault) {
        const payload = {
          address_task_list_id: taskListId,
          address_id: this.addressID,
          settings: [{
            key: `default_list.address.${this.addressID}`,
            value: true
          }]
        }
        await this.toDos.saveDefaultList(taskListId, payload);
      }
    }
    this.rightSidePanelService.navigateTo('to-dos');
  }

  changeDefault() {
    this.listIsDefault = !this.listIsDefault;
  }

  parseSelectedJobs() {
    this.jobs.map((job) => {
      if (job.template_data.job.task_list?.title === this.listName) {
        this.selectedJobs.push(job.template_data.job.id);
      } else {
        this.notSelectedJobs.push(job.template_data.job.id);
      }
    });
  }


}

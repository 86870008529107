<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Missing Item'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row>
      <tidy-text
        [body]="'We\'re sorry to hear that something is missing!'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'If you would like to reach out to'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="proName">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'directly, you can do so below.  This will send them a message and allow you to open up a communication session with them to discuss further.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'If you believe that this was theft then you should contact the police right away, then our Concierge by'">
      </tidy-text>
      <tidy-text
        [actionBody]="'clicking here'"
        (action)="goToContactConcierge()"
        class="link">
      </tidy-text>
      <tidy-text
        [body]="'. The police are in the best position to help you, and we follow their lead on theft investigations.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'While we will provide our full support to you in taking legal action against the Pro, we do not cover theft per our'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [actionBody]="'terms'"
        (action)="goToTerms()"
        class="link">
      </tidy-text>
      <tidy-text
        [body]="'. This process may take some time, so we appreciate your patience.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding">
         <tidy-textarea
           formControlName="description"
           [form]="form.controls.description"
           [label]="'Describe the missing item'"
           [submitted]="submitted"
           [icon]="'assets/svg/information-circle-outline.svg'"
           [errorMessage]="'Please describe what is missing.'">
         </tidy-textarea>
       </tidy-row>
     </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Contact ' + proName"
      [action]="confirmMissingItem.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Send Payment'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>

  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'" *ngIf="cameFromGetStarted && userRole !== 'member'">
        <tidy-text
          [body]="'You have'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="creditBalance | tcurrency">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'credit.'">
        </tidy-text>
        <ng-container *ngIf="creditBalance > 0">
          <span>&nbsp;</span>
          <tidy-text
            [body]="'If you want to send more, we will charge your card for the difference.'">
          </tidy-text>
        </ng-container>
      </tidy-row>

      <ng-container *ngIf="!hasCreditCard && creditBalance == 0">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Please add a credit/debit card to your account to send payment.'">
          </tidy-text>
          <ng-container *ngIf="creditBalance > 0">
            <span>&nbsp;</span>
            <tidy-text   
              [body]="'If you want to send more, we will charge your card for the difference.'">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <tidy-button
          [text]="'Add Credit/Debit Card'"
          [action]="goToAddCard.bind(this)"
          class="secondary">
        </tidy-button>
      </ng-container>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-bottom-padding">
            <tidy-text
              [header]="'How much do you want to send?'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="price"
              [label]="'Amount'"
              [form]="form.controls.price"
              [icon]="'assets/svg/card-outline.svg'"
              [errorMessage]="'Please select an amount.'"
              [submitted]="submitted"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-bottom-padding">
            <tidy-text
              [header]="'What is it for?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-datepicker
              label="Job Date"
              [icon]="'assets/svg/calendar-outline.svg'"
              formControlName="date"
              [form]="form.controls.date"
              errorMessage="Please enter a valid date."
              [submitted]="submitted">
            </tidy-datepicker>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [label]="'Job Time'"
              [icon]="'assets/svg/time-outline.svg'"
              [form]="form.controls.time"
              formControlName="time"
              [items]="jobTimeItems">
            </tidy-select>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-select
              [label]="'Service'"
              [icon]="'assets/svg/briefcase-outline.svg'"
              [items]="serviceCategoryItems"
              (optionChange)="parseServiceItemsByCategory($event)"
              [form]="form.controls.serviceCategory"
              formControlName="serviceCategory">
            </tidy-select>
          </tidy-row>
          <tidy-row *ngIf="serviceItems?.length">
            <tidy-radio-button
              formControlName="service"
              [form]="form.controls.service"
              [items]="serviceItems">
            </tidy-radio-button>
          </tidy-row>
          <tidy-row *ngIf="!serviceItems?.length" class="extra-top-padding">
            <tidy-text
              [helper]="'No services offered.'">
            </tidy-text>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Send Payment'"
        [action]="sendPayment.bind(this)"
        class="primary">
      </tidy-button>

      <ng-container *ngIf="errorMessage">
        <tidy-error-message
          *ngIf="!errorMessage.includes('Customer Token not found') && !errorMessage.includes('Credit Card is required to send a payment!') && !errorMessage?.includes('Your card was declined')"
          [text]="errorMessage">
        </tidy-error-message>

        <ng-container *ngIf="errorMessage.includes('Customer Token not found') || errorMessage.includes('Credit Card is required to send a payment!') || errorMessage?.includes('Your card was declined')">
          <tidy-error-message
            *ngIf="!errorMessage?.includes('Your card was declined')"
            [text]="'Please add a credit/debit card to send this amount. Any credit in your account will be used before any charges.'">
          </tidy-error-message>
          <tidy-error-message
            *ngIf="errorMessage?.includes('Your card was declined')"
            [text]="'Your card was declined. Please add a new credit/debit card. Any credit in your account will be used before any charges.'">
          </tidy-error-message>
          <tidy-button
            [text]="'Add Credit/Debit Card'"
            [action]="goToAddCard.bind(this)"
            class="secondary">
          </tidy-button>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 90%; height: 18px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 95%; height: 55px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 38%; height: 18px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 95%; height: 55px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 95%; height: 55px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 95%; height: 55px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%; height: 18px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-add-direct-integration-onboarding',
  templateUrl: './add-direct-integration-onboarding.component.html',
  styleUrls: ['./add-direct-integration-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddDirectIntegrationOnboardingComponent {
  constructor() {}
} 




<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Webhooks'"
    [canGoBack]="true"
    [customBack]="'more/developers'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Add webhooks and we will send events to that url.'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'Learn More'"
            (action)="goToLearnMore()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-button
          [text]="'Add Endpoint'"
          [action]="goToAddWebhook.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-row class="extra-top-padding">
          <tidy-text
            [header]="'Endpoints Receiving Events'">
          </tidy-text>
        </tidy-row>

        <tidy-alert *ngIf="webhookResponse.length === 0">
          <tidy-row>
            <tidy-text
              [body]="'No Active Webhooks'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card *ngFor="let currentWebhook of webhookResponse; let last = last">

          <tidy-card-button (action)="goToWebhookDetails(currentWebhook)">

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="currentWebhook?.webhook_url"
                [translate]="false">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/url.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="currentWebhook?.channel == 'webhook' ? 'Standard Webhook' : 'Slack Webhook'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-center extra-bottom-padding" *ngIf="currentWebhook?.description">
              <tidy-image
                [src]="'assets/img/info.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="currentWebhook?.description"
                [translate]="false">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image *ngIf="currentWebhook.state === 'inactive'"
                [src]="'assets/icon/pause.svg'"
                class="body-size">
              </tidy-image>
              <tidy-image *ngIf="currentWebhook.state === 'active'"
                [src]="'assets/icon/check.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Status:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(currentWebhook?.state | titlecase)">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-middle" class="extra-bottom-padding" *ngIf="currentWebhook?.customer_account_team && isAccountAdmin">
              <tidy-image
                [src]="'assets/img/organization.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Team:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="currentWebhook?.customer_account_team?.name"
                [translate]="false">
              </tidy-text>
            </tidy-row>

            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Listening to events:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="buildWebhookEventsList(currentWebhook.events)">
              </tidy-text>
            </tidy-row>

          </tidy-card-button>
        </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { ModalController } from '@ionic/angular';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: 'next-steps-modal-component.html',
  styleUrls: ['./next-steps-modal-component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NextStepsModalComponent {
  isMobileResolution = false;

  constructor(
    private modalCtrl: ModalController,
    private dialogRef: MatDialogRef<NextStepsModalComponent>,
    ) {
    this.isMobileResolution = window.innerWidth <= 870;
  }

  goToNext(): void {
    if (this.dialogRef?.close) {
      this.dialogRef.close(true);
    }
    this.modalCtrl.getTop().then((res) => {
      if (res) {
        this.modalCtrl.dismiss(true);
      }
    });
  }

}

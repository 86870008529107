<!--<div class="desktop-header" *ngIf="windowService.isDesktopRes">
  <tidy-text
    [title]="'Schedule'">
  </tidy-text>
  <div class="vertical-align-center">
    <tidy-image
      (click)="goToPreviousPeriod()"
      [src]="'assets/icon/arrowhead.png'"
      class="body-size arrow-icon reverse-arrow">
    </tidy-image>
    <tidy-text
      [body]="getPeriodRangeText()">
    </tidy-text>
    <tidy-image
      (click)="goToNextPeriod()"
      [src]="'assets/icon/arrowhead.png'"
      class="body-size arrow-icon last-arrow">
    </tidy-image>
  </div>
  <div style="width: 80px">
    <form [formGroup]="form">
      <tidy-select
        [items]="scheduleViewModeList"
        [form]="form.get('scheduleViewMode')"
        formControlName="scheduleViewMode"
        (optionChange)="changeScheduleViewMode($event)"
        class="desktop-header-field">
      </tidy-select>
    </form>
  </div>
  <div style="width: 180px">
    <form [formGroup]="form">
      <tidy-input
        #searchInput
        class="desktop-header-field"
        [icon]="'assets/svg/search-outline.svg'"
        [activeIcon]="'assets/svg/search-tidy-green.svg'"
        [noBorder]="true"
        type="search"
        formControlName="search"
        [label]="'Search'"
        [form]="form">
      </tidy-input>
    </form>
  </div>
  <tidy-button
    class="desktop-header-button"
    [image]="'assets/icon/filter-white.png'"
    [text]="'Filter'"
    [smallBody]="true"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{closeDisabled: true}">
  </tidy-button>
  <tidy-button
    class="desktop-header-button primary-button"
    [image]="'assets/img/book-jobs-white.svg'"
    [text]="'Book Job(s)'"
    [smallBody]="true"
    [action]="bookJobSelectAddress.bind(this)">
  </tidy-button>
  <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
</div>-->

<div class="desktop-header" *ngIf="windowService.isDesktopRes">
  <tidy-text
    [title]="'Schedule'">
  </tidy-text>
  <ng-container *ngIf="!loaded">
    <ion-skeleton-text
      animated="true"
      style="
        width: 690px;
        height: 27px;
        border-radius: 6px;">
    </ion-skeleton-text>
  </ng-container>
  <ng-container *ngIf="loaded">
    <div class="vertical-align-center">
      <tidy-image
        (click)="goToPreviousPeriod()"
        [src]="'assets/icon/arrowhead.png'"
        class="body-size arrow-icon reverse-arrow">
      </tidy-image>
      <tidy-text
        [body]="getPeriodRangeText()">
      </tidy-text>
      <tidy-image
        (click)="goToNextPeriod()"
        [src]="'assets/icon/arrowhead.png'"
        class="body-size arrow-icon last-arrow">
      </tidy-image>
    </div>
    <div style="width: 80px">
      <form [formGroup]="form">
        <tidy-select
          [items]="scheduleViewModeList"
          [form]="form.get('scheduleViewMode')"
          formControlName="scheduleViewMode"
          (optionChange)="changeScheduleViewMode($event)"
          class="desktop-header-field">
        </tidy-select>
      </form>
    </div>
    <form [formGroup]="form" novalidate action="#">
      <tidy-search-input
        formControlName="search"
        [placeholder]="'Search'"
        [icon]="'assets/svg/search-outline.svg'">
      </tidy-search-input>
    </form>
    <div class="header-button" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{closeDisabled: true}">
      <tidy-image
        [src]="'assets/img/filter-white.svg'"
        style="height: 13px;">
      </tidy-image>
      <tidy-text
        [headerButton]="'Filter'"
        class="white">
      </tidy-text>
    </div>
    <div class="header-button primary" (click)="bookJobSelectAddress()">
      <tidy-image
        [src]="'assets/img/book-jobs-white.svg'"
        style="height: 16px;">
      </tidy-image>
      <tidy-text
        [headerButton]="'Book Job'"
        class="white">
      </tidy-text>
    </div>
  </ng-container>
  <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
</div>  

<div *ngIf="!windowService.isDesktopRes">
  <div>
    <div class="vertical-align-center" style="margin-top: 50px; margin-bottom: 15px; display: flex; justify-content: center;">
      <tidy-image
        (click)="goToPreviousPeriod()"
        [src]="'assets/icon/arrowhead.png'"
        class="body-size arrow-icon reverse-arrow">
      </tidy-image>
      <tidy-text
        [body]="(this.selectedPeriodRange.startDate | date: 'MMM d') + ' - ' + (this.selectedPeriodRange.endDate | date: 'MMM d')">
      </tidy-text>
      <tidy-image
        (click)="goToNextPeriod()"
        [src]="'assets/icon/arrowhead.png'"
        class="body-size arrow-icon last-arrow">
      </tidy-image>
    </div>
    <div style="margin-bottom: 10px; display: flex; align-items: center; width: 100%;">
      <div style="flex: 0 0 70%; padding-right: 10px">
        <form [formGroup]="form">
          <tidy-search-input
            formControlName="search"
            [placeholder]="'Search'"
            [icon]="'assets/svg/search-outline.svg'"
            [fixedWidth]="false">
          </tidy-search-input>
        </form>
      </div>
      <div style="flex: 0 0 30%;" class="header-button" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{closeDisabled: true}">
        <tidy-image
          [src]="'assets/img/filter-white.svg'"
          style="height: 13px;">
        </tidy-image>
        <tidy-text
          [headerButton]="'Filter'"
          class="white">
        </tidy-text>
      </div>
      <!--<div style="flex: 0 0 30%;">
        <tidy-button
          class="desktop-header-button"
          [image]="'assets/icon/filter-white.png'"
          [text]="'Filter'"
          [smallBody]="true"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{closeDisabled: true}">
        </tidy-button>
      </div>-->
    </div>
  </div>
</div>

<div *ngIf="chips?.length > 0" style="padding-bottom: 1rem">
  <ng-container *ngFor="let chip of chips">
    <ion-chip class="filter-chip">
      <ion-label>{{ chip.displayParentName | titlecase }}: {{ chip.name | titlecase }}</ion-label>
      <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
    </ion-chip>
  </ng-container>
</div>

<mat-menu #menu="matMenu" class="filter-menu">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
        <mat-checkbox
          class="checkbox"
          [checked]="getNodeChecked(node)"
          (click)="$event.stopPropagation()"
          (change)="checkNode(node, $event)">
          {{ node.name | titlecase | translate }}
        </mat-checkbox>
      </button>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <button
        class="node-button-padding"
        mat-menu-item
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
        {{ node.name | titlecase | translate }}
      </button>
    </mat-tree-node>
  </mat-tree>
</mat-menu>
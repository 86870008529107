<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav [class]="extraClass" [canGoBack]="true" [title]="'Find Your Account'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text body="This can be used to find an account you may have with TIDY.
                        All usernames are emails, but this can be helpful if you are
                        not sure which email you used or if you think you may have
                        had a typo on your email.">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'">
        <tidy-text body="Please include <u>at least 2</u> of the following pieces of information:">
        </tidy-text>
      </tidy-row>
      <form [formGroup]="form">
      <tidy-card>
        <tidy-row class="field-bottom-padding">
          <tidy-text
            header="Phone:">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-input-international-phone
            item-right
            placeholder="Phone number"
            formControlName="phone"
            label="Phone"
            [form]="form.controls.phone"
            [inputMode]="'numeric'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid phone.'"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
          </tidy-input-international-phone>
        </tidy-row>

        <tidy-row class="extra-top-padding field-bottom-padding">
          <tidy-text
          header="Address:">
          </tidy-text>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-select
            [icon]="'assets/svg/home-outline.svg'"
            [label]="'Country'"
            [items]="countryList"
            [form]="form.controls.countryCode"
            formControlName="countryCode">
          </tidy-select>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-auto-complete-address
            [submitted]="submitted"
            [addressFormControl]="form.controls.address"
            [countryCode]="form.controls.countryCode.value"
            (onZipcodeChange)="setZipcode($event)"
            (onError)="onError($event)"
            (noAddressPickedUpdate)="setNotAddressPickedTemplate($event)"
            (invalidAddressError)="invalidAddressError = $event"
          ></tidy-auto-complete-address>
        </tidy-row>

        <tidy-row>
          <tidy-input
            formControlName="zipcode"
            [customMask]="zipCodeMask"
            [form]="form.controls.zipcode"
            [errorMessage]="'Please enter a valid zipcode'"
            [submitted]="submitted"
            [inputMode]="'numeric'"
            label="Zip Code"
            [icon]="'assets/svg/home-outline.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="extra-top-padding field-bottom-padding">
          <tidy-text
          header="Recent Charge:">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-input
            formControlName="chargeAmount"
            [mask]="'currencyMask'"
            [form]="form.controls.chargeAmount"
            [errorMessage]="'Please enter a valid amount'"
            [submitted]="submitted"
            [inputMode]="'numeric'"
            label="Amount of charge"
            [icon]="'assets/svg/cash-outline.svg'"
            [activeIcon]="'assets/svg/cash-outline-tidy-green.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-datepicker
            label="Date of charge"
            [icon]="'assets/svg/calendar-outline.svg'"
            formControlName="chargeDate"
            [form]="form.controls.chargeDate"
            errorMessage="Please enter a valid date."
            [submitted]="submitted">
          </tidy-datepicker>
        </tidy-row>

        <tidy-row>
          <tidy-input
            formControlName="cardLastDigits"
            [form]="form.controls.cardLastDigits"
            [customMask]="{mask: '0000'}"
            [errorMessage]="'Please enter a valid value'"
            [submitted]="submitted"
            [inputMode]="'numeric'"
            label="Last 4 digits of card"
            [icon]="'assets/svg/card-outline.svg'"
            [activeIcon]="'assets/svg/card-outline.svg'">
          </tidy-input>
        </tidy-row>

      </tidy-card>

      <ng-container *ngTemplateOutlet="noAddressPickedTemplate">
      </ng-container>

      </form>
      <tidy-row>
        <re-captcha (resolved)="captchaResolved($event)">
        </re-captcha>
        <tidy-error-message *ngIf="submitted && form.controls.captchaToken.invalid" [text]="'Please check the captcha.'"></tidy-error-message>
      </tidy-row>

      <tidy-error-message *ngIf="errorMessage" [text]="errorMessage"></tidy-error-message>

      <tidy-button
        [text]="'Next'"
        class="primary"
        [action]="submit.bind(this)">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

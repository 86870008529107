<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="!loaded ? '' : user?.first_name"
    [canGoBack]="true"
    [customBack]="windowService.isDesktopRes ? 'more/users' : 'users'"
    (linkAction)="deleteUser()"
    [showLinkIcon]="loaded && user?.role !== 'owner'"
    [linkIcon]="'trash'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="user?.first_name + ' ' + user?.last_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            class="link"
            (action)="goToEditUserDetailsPage()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/email.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [helper]="user?.email"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" *ngIf="user?.phone">
          <tidy-image
            [src]="'assets/img/call-grey.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [helper]="user?.phone | telephone:'format'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="clientInfo?.customer_account?.account_type !== 'regular'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/information-circle-grey.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [helper]="'User Type:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [helper]="role">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-top-padding extra-bottom-padding" *ngIf="user?.role !== 'owner'">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="user?.role == 'admin'">
            <tidy-text
              [helper]="'This user can see all properties and users.'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="user?.role == 'member'">
            <tidy-text
              [helper]="'This user can only see properties and team members on their team.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-card *ngIf="user?.role !== 'owner' && clientInfo?.customer_account?.account_type !== 'regular'">
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/organization.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Teams'">
          </tidy-text>
          <tidy-text
            [body]="'Add to Team'"
            class="link"
            (action)="goToAddUserToTeamPage()">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let team of teamsUserIsOn" [alignLastItemRight]="true">
          <tidy-text
            [body]="team?.name"
            (action)="goToTeamPage(team?.team_id)"
            class="link"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'X'"
            class="link"
            (action)="removeFromTeam(team?.team_member_id)">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="teamsUserIsOn?.length == 0" class="vertical-align-center">
          <tidy-text
            [helper]="'Not on any teams'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="user?.role !== 'owner' && user?.role !== 'admin'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/home.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Properties'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let address of userAddresses" class="vertical-align-middle">
          <tidy-image
            [src]="'assets/img/location.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="address"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="!userAddresses?.length" class="vertical-align-center">
          <tidy-text
            [helper]="'No properties assigned'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row *ngIf="isRightSideContent" [align]="'center'">
        <tidy-text
          class="link red"
          [body]="'Delete User'"
          (action)="deleteUser()">
        </tidy-text>
      </tidy-row>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

export async function squareImage (photoBase64: string, format: string): Promise<string> {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  const ctx = canvas.getContext('2d');
  const image = new Image();
  image.src = photoBase64;

  await new Promise((resolve, reject) => {
    if (image.complete) {
      resolve({});
    }
        
    image.onload = () => {
      resolve({});
    }

    image.onerror = (err) => {
      reject(err);
    }
  });

  return new Promise((resolve) => {
    const MAX_WIDTH = 1080;
    const MAX_HEIGHT = 1080;
    let width = image.width;
    let height = image.height;
    
    // Calculate aspect ratio
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    let smallestSize = Math.min(MAX_WIDTH, width, height);

    canvas.width = smallestSize;
    canvas.height = smallestSize;
    
    var obj = {
      sx: (smallestSize - width) / 2,
      sy: (smallestSize - height) / 2,
      sWidth: width,
      sHeight: height,
    }

    const {
      sx,
      sy,
      sWidth,
      sHeight,
    } = obj
    
    ctx.drawImage(image, sx, sy, sWidth, sHeight);

    resolve(canvas.toDataURL(`image/${format}`));
  });
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import { Platform } from '@ionic/angular';

import { Auth } from 'src/providers/auth/auth';
import { MultipleCountryService } from 'src/providers/addresses/multiple-country.service';
import { OnboardingFlow } from 'src/shared/enums/onboarding-flow';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { AddressComponentsModel, AddressForm } from 'src/providers/address-form/address-form';
import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';
import { AutoCompleteAddressModel } from 'src/models/autocomplete-address.model';
import { Loading } from 'src/shared/components/loading/loading';
import { Addresses } from 'src/providers/customer/addresses';
import { Client } from 'src/providers/client/client';
import { Analytics } from 'src/providers/analytics/analytics';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/providers/logger';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  selector: 'app-add-first-address',
  templateUrl: './add-first-address.page.html',
  styleUrls: ['./add-first-address.page.scss'],
})

export class AddFirstAddressPage implements OnInit {

  cameFromRentalOnboardingViewApp: boolean;
  customFlowDiscount: number;
  customFlowName: string;
  customFlowImage: string;
  customFlowSmallImage: string;
  customerResponse: any;
  creditBalance: number;
  employerName: string;
  form: UntypedFormGroup;
  loaded: boolean;
  flowType: OnboardingFlow;
  nonCustomFlowTypes = [
    'company',
    'developer',
    'normal',
    'private',
    'rental',
    'maintenance',
    'employee'
  ];
  OnboardingFlow = OnboardingFlow;
  countryList: RadioButtonModel[];
  errorMessage: string;
  didChooseAddress = false;
  typedOnAddress = false;
  radioButtonAddress: RadioButtonModel[] = [];
  autocompleteAddress: AutoCompleteAddressModel[];
  submitted: boolean;
  hkId: string;
  zipCodeMask = {
    mask: '00000' // US mask
  };

  constructor(
    private auth: Auth,
    private fb: UntypedFormBuilder,
    private multipleCountryService: MultipleCountryService,
    private navCtrl: CustomNavController,
    private platform: Platform,
    private addressForm: AddressForm,
    private googleGeocode: GoogleGeocode,
    private addresses: Addresses,
    private client: Client,
    private analytics: Analytics,
    private route: ActivatedRoute,
    private logger: Logger,
    private currentAddress: CurrentAddress,
    public windowService: WindowService
  ) {
    this.form = this.fb.group({
      countryCode: ['US', Validators.required],
      address: ['', Validators.compose([Validators.maxLength(100), Validators.required])],
      address2: ['', Validators.compose([Validators.maxLength(100)])],
      zipcode: ['', this.multipleCountryService.getCountrySettings('US').validator],
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      addressNickname: [''],
      phone: [''],
    });

    this.countryList = this.multipleCountryService.countryRadioButtonList;
  }

  async ngOnInit() {
    this.flowType = this.route.snapshot.paramMap.get('flowType') as OnboardingFlow;
    this.cameFromRentalOnboardingViewApp = this.navCtrl.getParam('cameFromRentalOnboardingViewApp');
    console.log(this.cameFromRentalOnboardingViewApp)
    const isCustomFlow = !this.nonCustomFlowTypes.includes(this.flowType);
    if (isCustomFlow) {
      await this.getCustomFlowData();
      this.form.controls.phone.setValidators([Validators.required]);
      return this.loaded = true;
    }
    this.analytics.configureBing('initiate_checkout', 'initiate_checkout', 'initiate_checkout', '1');
    if (this.flowType === OnboardingFlow.RENTAL) {
      this.form.controls.phone.setValidators([Validators.required]);
    }
    this.creditBalance = parseInt(localStorage.getItem('creditBalance'));
    this.employerName = localStorage.getItem('employerName');
    if (!this.creditBalance) {
      const billingData = await this.client.getBillingData();
      this.creditBalance = billingData?.balance;
      this.employerName = billingData?.balance_details[0]?.transferred_from?.account_name;
      localStorage.setItem('creditBalance', this.creditBalance.toString());
      localStorage.setItem('employerName', this.employerName);
    }
    this.loaded = true;
  }

  async getCustomFlowData() {
    try {
      const flowData = await this.client.getCustomSBFlowData(this.flowType);
      this.customFlowName = flowData.settings.giver.name;
      this.customFlowImage = flowData.settings.giver.image_url;
      this.customFlowSmallImage = flowData.settings.giver.small_image_url;
      this.customFlowDiscount = flowData.settings.discount;
      localStorage.setItem('customFlowName', this.customFlowName);
      localStorage.setItem('customFlowImage', this.customFlowImage);
      localStorage.setItem('customFlowSmallImage', this.customFlowSmallImage);
      localStorage.setItem('customFlowDiscount', this.customFlowDiscount.toString());
      localStorage.setItem('customFlowReferralCode', flowData.referral_code);
    } catch (err) {
      return this.navCtrl.navigateForward('');
    }
  }

  // TODO remove this to use tidy-auto-complete-address https://github.com/TIDYAPP/client-mobile-v2/pull/523/files
  updateSearch = async () => {
    this.errorMessage = '';

    try {
      this.autocompleteAddress = await this.addressForm.updateSearch(this.form.value.address, this.form.get('countryCode').value);
    } catch (err) {
      this.errorMessage = 'An error occurred when getting address information. Please type another address.';
    }

    this.radioButtonAddress = this.autocompleteAddress.map(address => {
      return {
        value: address.value,
        viewValue: address.display,
        placeId: address.placeId
      }
    });

    this.typedOnAddress = true;
    this.didChooseAddress = false;
  }

  // TODO remove this to use tidy-auto-complete-address https://github.com/TIDYAPP/client-mobile-v2/pull/523/files
  async chooseItem(item: { placeId: string, value: string | number, display: string}) {
    const zipcode = await this.addressForm.chooseItem(item);

    if (!zipcode && this.multipleCountryService.checkIfContryCodeIsUs(this.form.get('countryCode').value)) {
      this.errorMessage = `It looks like this address isn't detailed enough, please update it. If you still have issues please contact us in the Concierge section.`;
    }

    const address: AddressComponentsModel = await this.addressForm.getAdditonalAddressInfo(item.placeId);
    this.form.patchValue({ zipcode, ...address });
    const latLngData = await this.googleGeocode.getLatLong(item.placeId);
    this.form.patchValue(latLngData);
    this.autocompleteAddress = [];
    this.didChooseAddress = true;
    this.typedOnAddress = false;
  }

  setAddressFormControl(item: RadioButtonModel) {
    this.form.controls.address.setValue(item.value);
  }

  @Loading('', true)
  async addAddress() {
    this.submitted = true;
    this.errorMessage = '';

    if (!this.didChooseAddress && this.autocompleteAddress.length === 0) {
      return this.errorMessage = 'We could not validate your address with Google. Please enter an address that can be found with Google Maps.';
    }

    if (!this.form.valid || !this.didChooseAddress) {
      return;
    }

    const addressArray = this.form.value.address.split(',');

    if (!addressArray[2] && this.multipleCountryService.checkIfContryCodeIsUs(this.form.get('countryCode').value)) {
      this.errorMessage = `It looks like this address isn't detailed enough, please update it. If you still have issues please contact us in the Concierge section.`;
      return;
    }

    try {
      if (this.customFlowName) {
        const temporaryEmail = this.flowType + (Math.random() * 9999999999999) + '@testemail234234.com';
        await this.createUser(temporaryEmail);
      }

      const addressStateFallback: string = addressArray[2]?.trim();

      const data: any = {
        country_code: this.form.value.countryCode,
        add_state: this.form.value.state || addressStateFallback,
        city: this.form.value.city,
        address1: addressArray[0],
        address2: this.form.value.address2,
        zip: this.form.value.zipcode,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude
      }

      if (this.form.value.addressNickname) {
        data['address_name'] = this.form.value.addressNickname;
      }

      if (this.flowType === OnboardingFlow.PRIVATE) {
        this.hkId = localStorage.getItem('hkId');
        data.homekeeper_id = this.hkId;
      }
      const address = await this.addresses.createAddress(data);
      this.currentAddress.addressId = address.id;
      localStorage.setItem('region_id', address.region_id);

      if (this.cameFromRentalOnboardingViewApp) {
        return this.navCtrl.navigateForward('dashboard');
      }

      switch (this.flowType) {
        case OnboardingFlow.EMPLOYEE: {
          this.navCtrl.navigateForward('get-started', { address });
          break;
        };
        case OnboardingFlow.RENTAL: {
          localStorage.setItem('cameFromRentalSelfBooking', 'true');
          const settings = await this.packageSettings();
          await this.client.saveClientSettings(settings);
          const flowType = this.flowType;
          const hkId = this.hkId;
          const isSelfBooking = true;
          this.navCtrl.navigateForward('book-job', { address, flowType, hkId, isSelfBooking });
          break;
        };
        case OnboardingFlow.DEVELOPER: {
          localStorage.setItem('cameFromDeveloperSelfBooking', 'true');
          const successParams = this.mountSuccessDeveloperParams();
          this.navCtrl.navigateForward('success', successParams);
          break;
        };
        case OnboardingFlow.PRIVATE:
        case OnboardingFlow.NORMAL: {
          const flowType = this.flowType;
          const hkId = this.hkId;
          const isSelfBooking = true;
          this.navCtrl.navigateForward('book-job', { address, flowType, hkId, isSelfBooking });
          break;
        };
        case OnboardingFlow.MAINTENANCE: {
          localStorage.setItem('cameFromMaintenanceSelfBooking', 'true');
          this.navCtrl.navigateForward('dashboard');
          break;
        };
        default: {
          const isSelfBooking = true;
          if (this.customFlowName) {
            const params = {
              isSelfBooking: isSelfBooking,
              address: address
            }
            this.navCtrl.navigateForward('book-job', params);
          } else {
            this.navCtrl.navigateForward('schedule', { isSelfBooking });
          }
        }
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }


  }

  async createUser(temporaryEmail) {
    await this.authLogin(temporaryEmail, 'signUp');
    await this.getCustomers(temporaryEmail);
    await this.auth.setSession();
    await this.authLogin(temporaryEmail, 'signUpPassword');
  }

  async authLogin(temporaryEmail, scope) {
    const credentials = {
      username: temporaryEmail,
      password: 'password'
    };
    await this.auth.login(credentials, scope);
  }

  async getCustomers(temporaryEmail) {
    const customersObj = this.getCustomer(temporaryEmail);
    this.customerResponse = await this.client.getCustomers(customersObj);
    localStorage.setItem('customer_id', this.customerResponse.id);
  }

  getCustomer(temporaryEmail) {
    return {
      'customer': {
        'booked_via': 'Self Booking',
        'email': temporaryEmail,
        'first_name': 'Client',
        'last_name': '1',
        'password': 'password',
        'account_type': 'regular',
        'account_name': '',
      }
    }
  }

  async packageSettings() {
    const settings = await this.client.getClientSettings();
    return {
      profile: {
        last_name: settings.profile.last_name,
        first_name: settings.profile.first_name,
        email: settings.profile.email,
        phone: this.form.value.phone,
        account_type: OnboardingFlow.RENTAL
      }
    }
  }

  mountSuccessDeveloperParams() {
    return {
      header: 'Account Created',
      body: 'You\'re ready to start using TIDY Developer features.',
      buttonText: 'Ok',
      buttonRoute: 'more/developers'
    };
  }

  changeCountrySelected(countryCodeSelected) {
    const validatorObject = this.multipleCountryService.getCountrySettings(countryCodeSelected);
    this.form.get('zipcode').setValidators(validatorObject.validator);
    this.zipCodeMask = validatorObject.mask;

    setTimeout(() => {
      this.form.patchValue({
        address: null,
        address2: null,
        zipcode: null
      });
    });
  }

  goToAddDirectIntegration() {
    localStorage.setItem('integrationOptionsBackButton', 'add-first-property/rental')
    this.navCtrl.navigateForward('direct-integration-options');
  }

  goToImportCSV() {
    const params = {
      backButton: 'add-first-property/rental'
    }
    this.navCtrl.navigateForward('import-properties', params);
  }

  goToLoginPage() {
    this.navCtrl.navigateForward('login');
  }

}

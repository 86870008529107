import { Component, QueryList, ViewChildren, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalController, ToastController } from "@ionic/angular";
import { debounceTime } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

import { ConfirmPage, ConfirmPageParamsModel } from "src/pages/confirm/confirm.component";

import { Workflows } from "src/providers/workflows/workflows";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ChipPlaceholder, ChipTextArea, InputFocusOut } from "src/shared/components/chip-text-area/chip-text-area";
import { Loading } from 'src/shared/components/loading/loading';
import { ActionModel, InputModel, RecommendedWorkflow, TriggerModel } from "src/models/workflow.model";
import { FieldOption, FormValues } from "../filter-nested-form/filter-nested-form.interface";
const ALL_OPTIONS_VALUE = '';

import { WorkflowsPage } from 'src/pages/workflows/workflows';
import { Util } from "src/shared/util/util";

@Component({
  templateUrl: 'edit-workflow.html',
  styleUrls: ['edit-workflow.scss']
})

export class EditWorkflowPage implements OnInit {

  actionsForms: FormArray = new FormArray([]);
  allActions: any;
  allActionsData: any;
  allTriggers: any;
  allTriggersData: any;
  allOutputs: any;
  dontUpdateOutputs: boolean;
  dialogParams: any;
  publishedVersionIsLatestVersion: boolean;
  didMakeNewDraft: boolean;
  errorMessage: string;
  filteredActions: any;
  filteredTriggers: any;
  isAddingWorkflow: boolean;
  isSavingDraft: boolean;
  isUpdatingWorkflow: boolean;
  isCreatingWorkflowVersion: boolean;
  isRightSideContent: boolean;
  latestVersion: any;
  loaded: boolean;
  paramForms: any = {};
  searchForm: FormGroup;
  selectedTrigger: any;
  selectedActions: any[] = [];
  submitted: boolean;
  triggerInputsLoaded: boolean;
  workflowForm: FormGroup;
  workflowVersionForm: FormGroup;
  workflowId: string;
  radioItems: any;
  selectedChipTextAreaId: string;
  recommendedWorkflowIndex: number;
  recommendedWorkflows: RecommendedWorkflow[] = []
  emailSuffix = '';

  @ViewChildren(ChipTextArea) chipTextAreaComponents!: QueryList<ChipTextArea>
  @ViewChildren('tidySelect') tidySelectComponents!: QueryList<any>

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private workflowProvider: Workflows,
    private viewScroller: ViewportScroller,
    private toastCtrl: ToastController,
    private util: Util
  ) {
    this.workflowForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.workflowVersionForm = this.formBuilder.group({
      trigger_data: this.formBuilder.group({
        id: ['', Validators.required],
        name: [''],
        description: [''],
        input_values: this.formBuilder.group({}),
        data: this.formBuilder.group({})
      }),
      actions_data: this.formBuilder.array([])
    });
    this.searchForm = this.formBuilder.group({
      title: ''
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      const navParams = this.navCtrl.getParams(false);
      await this.getAllTriggersAndActions();
      this.workflowId = this.route.snapshot.paramMap.get('workflowId') || await this.storage.retrieve('workflowId');
      if (this.workflowId === 'new') {
        await this.createNewWorkflow();
      } else {
        await this.patchWorkflowForm();
      }
      this.initiateAutoSave();
      const pageTitle = this.getPageTitle();
      this.rightSidePanelService.setDialogPageTitle(pageTitle);
      this.loaded = true;
      this.recommendedWorkflows = this.workflowProvider.getRecommendedWorkflows();
      this.recommendedWorkflowIndex = Number(navParams?.recommendedWorkflowIndex || this.dialogParams?.recommendedWorkflowIndex);
      const recommendedWorkflow = this.recommendedWorkflows[this.recommendedWorkflowIndex];
      if (recommendedWorkflow) {
        this.onSelectRecommendedWorkflow(recommendedWorkflow);
      }
      const me = await this.storage.retrieve('me');
      this.emailSuffix = me?.customer_account?.workflow_inbound_email_suffix || '';
    } catch (err) {
      console.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  setSelectedChipTextArea(id: string): void {
    this.selectedChipTextAreaId = id;
  }

  async getAllTriggersAndActions() {
    this.allTriggers = await this.workflowProvider.getWorkflowTriggerTypes();
    let triggerResult = this.allTriggers.reduce((acc, obj) => {
      let key = obj['category'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.allTriggers = Object.keys(triggerResult).map(key => ({ category: key, data: triggerResult[key] }));
    this.allTriggersData = [];
    this.allTriggers.map((trigger) => {
      trigger.data.map((data) => {
        this.allTriggersData.push(data);
      });
    });
    this.filteredTriggers = this.allTriggers;
    this.allActions = await this.workflowProvider.getWorkflowActionTypes();
    let actionResult = this.allActions.reduce((acc, obj) => {
      let key = obj['category'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.allActions = Object.keys(actionResult).map(key => ({ category: key, data: actionResult[key] }));
    this.allActionsData = [];
    this.allActions.map((trigger) => {
      trigger.data.map((data) => {
        this.allActionsData.push(data);
      });
    });
    this.filteredActions = this.allActions;
  }

  async createNewWorkflow() {
    this.isAddingWorkflow = true;
    const payload = {
      'name': 'Workflow ' + await this.getWorkflowNumber(),
      'description': '',
      'tags' :[]
    };
    const workflow = await this.workflowProvider.createWorkflow(payload);
    await this.workflowProvider.pauseWorkflow(workflow.id);
    this.workflowId = workflow.id;
    this.workflowForm.patchValue({
      name: 'Workflow ' + this.workflowId
    });
    localStorage.setItem('newWorkflowId', this.workflowId);
  }

  async getWorkflowNumber() {
    const numberOfWorkflows = this.navCtrl.getParam('numberOfWorkflows') || this.dialogParams?.numberOfWorkflows;
    if (numberOfWorkflows) {
      return numberOfWorkflows
    } else {
      const workflows = await this.workflowProvider.getWorkflows();
      return workflows.length;
    }
  }

  async patchWorkflowForm() {
    const workflow = this.navCtrl.getParam('workflow') || this.dialogParams?.workflow || await this.workflowProvider.getWorkflowWithVersions(this.workflowId);
    this.workflowForm.patchValue({
      name: workflow.name
    });
    if (workflow?.published_version) {
      this.publishedVersionIsLatestVersion = workflow?.draft_version ? (workflow.draft_version.id < workflow.published_version.id) : true;
    }
    if (this.publishedVersionIsLatestVersion) {
      this.latestVersion = workflow.published_version;
    } else {
      this.latestVersion = workflow.draft_version;
    }
    if (this.latestVersion?.workflow_version_trigger) {
      this.patchTriggerForm(this.latestVersion.workflow_version_trigger);
    }
    if (this.latestVersion?.workflow_version_actions) {
      this.patchActionsForms(this.latestVersion.workflow_version_actions);
    }
  }

  async patchTriggerForm(trigger) {
    this.triggerInputsLoaded = false;
    const triggerType = this.allTriggersData.find((item) => item.id == trigger.trigger_id);
    const triggerData = {
      id: triggerType.id,
      name: trigger.name,
      icon_url: triggerType.icon_url,
      description: triggerType.description,
      inputs: triggerType.inputs
    };
    this.workflowVersionForm.patchValue({
      trigger_data: triggerData
    });
    this.selectedTrigger = triggerData;
    await this.getInputValues();
    const triggerInputsForm = this.workflowVersionForm.get('trigger_data').get('input_values') as FormGroup;
    triggerType?.inputs?.forEach((input) => {
      let value = this.latestVersion?.workflow_version_trigger?.input_values?.[input.key];
      const isAllValue = value == undefined || value == null || value?.length == 0;
      value = isAllValue ? ALL_OPTIONS_VALUE : value;
      triggerInputsForm.addControl(input.key, this.formBuilder.control(value, input.required ? Validators.required : null));
      const label = input.form.label;
      triggerInputsForm.addControl(input.key + '-radio', this.formBuilder.control(isAllValue ? '' : this.getAdjustedLabel(label.toLowerCase()), null));
    });
    this.triggerInputsLoaded = true;
    this.forceUpdateForm(triggerType);
  }

  forceUpdateForm(triggerType) {
    setTimeout(() => {
      const selectComponentsToArray = this.tidySelectComponents.toArray();
      selectComponentsToArray.forEach((selectComponent) => {
        triggerType?.inputs?.forEach((input) => {
          const label = input.form.label.toLowerCase().includes('address') ? 'Properties' : input.form.label;
          if (label === selectComponent.label) {
            selectComponent.items = input.items
              .map((item) => {
                return {
                  viewValue: item.viewValue,
                  value: item.value
                }
              });
            selectComponent.listedItems = selectComponent.items;
            if (this.latestVersion?.workflow_version_trigger?.input_values?.[input.key]) {
              const responseValue = this.latestVersion?.workflow_version_trigger?.input_values?.[input.key];
              selectComponent.form.patchValue(responseValue);
            }
          }
        })
      })
    }, 200)
  }

  async patchActionsForms(actions) {
    this.actionsForms = this.formBuilder.array([]);
    this.selectedActions = [];
    const loadInputs = (actionData, actionType) => {
      const inputs = Object.entries(actionData.input_values)
      const inputsForms = this.formBuilder.group({})
      inputs.forEach((input) => {
        const key = input[0];
        const value = input[1];
        const validator = actionType.required ? Validators.required : null;
        const control = this.formBuilder.control(value, validator)
        inputsForms.addControl(key, control)
      })
      return inputsForms;
    }
    await Promise.all(actions.map(async (action) => {
      const actionType = this.allActionsData.find((item) => item.id == action.action_id);
      const formId = Math.random().toString(36).substring(7);
      const actionForm = {
        formId: [formId],
        id: [actionType.id, Validators.required],
        name: [action.name, Validators.required],
        description: [action.description],
        input_values: loadInputs(action, actionType),
        icon_url: actionType.icon_url
      };
      const actionFormGroup = this.formBuilder.group(actionForm);
      this.actionsForms.push(actionFormGroup);
      const inputData = await this.workflowProvider.getInputData(actionType.id, 'Action');
      const inputOptions = this.getInputOptions(inputData);
      this.selectedActions.push({
        ...actionFormGroup.value,
        inputs: actionType.inputs,
        inputData,
        inputOptions,
      });
      return Promise.resolve();
    }));
    this.selectedActions = this.actionsForms.value.map(value => {
      const formId = value.formId;
      return this.selectedActions.find(action => action.formId === formId);
    });
    this.addOutputsToActions();
    setTimeout(() => {
      this.selectedActions.forEach((action, index) => {
        this.patchChipTextArea(
          action?.formId,
          this.actionsForms?.value?.[index]?.input_values
        )
      })
    }, 1000);
  }

  patchChipTextArea(formId: string, input_values: any): void {
    const chipTextAreaComponents = this.chipTextAreaComponents
      .filter((component) => component.id === formId)
    if (!formId || !input_values) {
      return;
    }

    chipTextAreaComponents.forEach(chipTextAreaComponent => {
    chipTextAreaComponent.body = {
      content: this.parseBody(input_values[chipTextAreaComponent.inputKey]),
      chips: this.parseOutputs()
    }
    chipTextAreaComponent.parseAndSetBody()
    })
  }

  parseOutputs(): ChipPlaceholder[] {
    if (!this.allOutputs?.length) {
      return [];
    }
    return this.allOutputs.map((output) => {
      return {
        text: output.plain_title ? output.plain_title : output.title,
        value: output.key
      }
    })
  }

  parseBody(body: string, removeNotFoundOutputs = false): string {
    if (!body) {
      return '';
    }
    const regex = /{([^}]+)}}/g;
    const matches = body.match(regex);
    if (!matches) {
      return body;
    }
    const parsedBody = matches.reduce((acc, match) => {
      const foundOutput = this.allOutputs.find((output) => output.key === match);
      if (foundOutput) {
        return acc.replace(match, foundOutput.key);
      } else {
        if (removeNotFoundOutputs) {
          return acc.replace(match, '');
        }
      }
      return acc;
    }, body);
    return parsedBody;
  }

  initiateAutoSave() {
    this.isCreatingWorkflowVersion = true;
    setTimeout(() => this.isCreatingWorkflowVersion = false, 3000);
    this.workflowForm.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => this.updateWorkflowTitle());
    this.workflowVersionForm.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe((value) => {
      this.createWorkflowVersion()
    });
    this.actionsForms.controls.map((form) => {
      form.valueChanges.pipe(
        debounceTime(1000)
      ).subscribe(value => this.createWorkflowVersion());
    });
  }

  onRadioOptionChange(event: any, inputForm: FormGroup): void {
    if (event.value === '') {
      inputForm.reset();
    }
  }

  async updateWorkflowTitle() {
    if (this.isUpdatingWorkflow) {
      return;
    }
    this.isSavingDraft = true;
    this.isUpdatingWorkflow = true;
    try {
      const payload = {
        'name': this.workflowForm.value.name,
        'description': '',
        'tags' :[]
      }
      await this.workflowProvider.updateWorkflow(payload, this.workflowId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    setTimeout(() => this.isSavingDraft = false, 1000);
    this.didMakeNewDraft = true;
    this.isUpdatingWorkflow = false;
  }

  async createWorkflowVersion(actionsPayload = null) {
    if (this.isCreatingWorkflowVersion) {
      return;
    }
    this.isSavingDraft = true;
    this.isUpdatingWorkflow = true;
    this.dialogParams.workflow = null;
    await this.storage.save('dialog-params', this.dialogParams);
    const didAddTrigger = this.workflowVersionForm.value.trigger_data.id;
    if (didAddTrigger) {
      let newVersionPayload = {
        workflow_id: Number(this.workflowId),
        trigger_data: {
          id: this.workflowVersionForm.value.trigger_data.id,
          name: this.workflowVersionForm.value.trigger_data.name,
          description: this.workflowVersionForm.value.trigger_data.description
        },
      }
      let didAddTriggerInputValues = false;
      const inputValues = Object.entries(this.workflowVersionForm.value.trigger_data.input_values).reduce((newObj, [key, value]) => {
        if (value !== ALL_OPTIONS_VALUE || (value !== ALL_OPTIONS_VALUE && key !== 'address_ids')) {
          const objValue = value as any;
          newObj[key] = Array.isArray(objValue) && objValue?.includes(ALL_OPTIONS_VALUE) ? null : value;
          const foundInput = this.selectedTrigger?.inputs?.find((input) => input.key === key);
          if (foundInput?.form?.type === 'number' && newObj[key] !== null) {
            newObj[key] = Number(newObj[key]);
          }
          didAddTriggerInputValues = true;
        }
        return newObj;
      }, {});
      if (didAddTriggerInputValues) {
        newVersionPayload.trigger_data['input_values'] = this.removeNotMatchedInputs(inputValues);
      }
      if (this.latestVersion) {
        // if the trigger is the same as the latest version, use the latest version's trigger id
        // if the trigger is different, use the new trigger's id that will be created
        if (newVersionPayload.trigger_data['id'] === this.latestVersion.workflow_version_trigger.trigger_id) {
          newVersionPayload.trigger_data['step_id'] = this.latestVersion.workflow_version_trigger.step_id
        }
      }
      const didAddAction = this.actionsForms.value.length > 0;
      if (didAddAction && !actionsPayload) {
        const filteredActions = this.actionsForms.value.filter((action) => action.id !== '');
        newVersionPayload['actions_data'] = this.parseActions(filteredActions);
      } else {
        newVersionPayload['actions_data'] = actionsPayload;
      }
      this.latestVersion = await this.workflowProvider.createWorkflowVersion(newVersionPayload);
      this.addOutputsToActions();
    }
    setTimeout(() => this.isSavingDraft = false, 1000);
    this.publishedVersionIsLatestVersion = false;
    this.didMakeNewDraft = true;
    this.isUpdatingWorkflow = false;
  }

  removeNotMatchedInputs(inputValues) {
    const availableInputKeys = this.selectedTrigger?.inputs?.map((input) => input.key);
    return Object.entries(inputValues).reduce((newObj, [key, value]) => {
      if (availableInputKeys.includes(key)) {
        newObj[key] = value;
      }
      return newObj;
    }, {});
  }

  addOutputsToActions() {
    if (this.dontUpdateOutputs) {
      return;
    }
    const triggerOutputs = this.removeSomeTextFromResponse(this.latestVersion.workflow_version_trigger.output_variables);
    this.allOutputs = [
      ...triggerOutputs
    ]
    this.dontUpdateOutputs = false;
    this.latestVersion.workflow_version_actions.map((action, versionIndex) => {
      this.selectedActions[versionIndex]['outputs'] = [{
        array: triggerOutputs,
        title: '<i>Add outputs from the <u>' + this.latestVersion.workflow_version_trigger.name + '</u> trigger</i>',
        showOptions: false
      }];
      this.selectedActions[versionIndex]['parsedOutputs'] = this.getParsedOutputs(this.selectedActions[versionIndex]['outputs']);
      if (versionIndex !== 0) {
        this.latestVersion.workflow_version_actions.map((action, actionIndex) => {
          if (versionIndex > actionIndex && action.output_variables.length > 0) {
            const actionOutputs = this.removeSomeTextFromResponse(action.output_variables);
            this.allOutputs.concat(actionOutputs);
            this.selectedActions[versionIndex]['outputs'].push({
              array: actionOutputs,
              title: '<i>Add outputs from the <u>' + action.name + '</u> action</i>',
              showOptions: false
            });
            this.selectedActions[versionIndex]['parsedOutputs'] = this.getParsedOutputs(this.selectedActions[versionIndex]['outputs']);
          }
        });
      }
    });
  }

  removeSomeTextFromResponse(outputs) {
    outputs.map((output) => {
      if (output.title?.includes('Event')) {
        output.title = output.title.replace('Event.', '');
      }
      if (output.title?.includes('Model: Job')) {
        output.title = output.title.replace('Model: Job.', '');
      }
      if (output.title?.includes('object')) {
        output.title = null;
      }
    });
    const filteredOutputs = outputs.filter((output) => output.title !== null)
    return filteredOutputs;
  }

  parseActions(actions: any[]): any[] {
    return actions.map((action, index) => {
      const { formId, ...rest } = action
      let payload = {
        ...rest,
        input_values: {}
      }
      if (action?.input_values?.filter_groups) {
        payload['input_values']['filter_groups'] = action.input_values.filter_groups;
      }
      if (this.latestVersion?.workflow_version_actions[index]) {
        // if the action is the same as the latest version, use the latest version's action id
        // if the action is different, use the new action's id that will be created
        if (this.latestVersion.workflow_version_actions[index].action_id === action.id) {
          payload['step_id'] = this.latestVersion.workflow_version_actions[index]?.step_id;
        }
      }
      const foundChipTextArea = this.chipTextAreaComponents.filter((component) => component.id === action.formId);
      foundChipTextArea.forEach(input => {
        if (input.innerHtml) {
          payload['input_values'][input.inputKey] = input.innerHtml
        }
      });
      if (action.input_values?.attachments) {
        payload.input_values.attachments = action.input_values.attachments;
      }
      return payload;
    });
  }

  async publishWorkflowVersion() {
    this.submitted = true;
    if (!this.workflowForm.valid) {
      return;
    }
    try {
      if (this.hasNotFilledRequiredChipTextArea()) {
        this.handleNotFilledRequiredChipTextArea();
        return;
      }
      await this.workflowProvider.publishWorkflow(this.latestVersion.id);
      await this.workflowProvider.unpauseWorkflow(this.workflowId);
      this.goToPage('automations/workflows', {}, WorkflowsPage);
    } catch (err) {
      if (!this.latestVersion?.id) {
        this.errorMessage = 'Please add a trigger and action to this workflow before publishing.';
        this.showError(this.errorMessage);
        return;
      }
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async showError(message: string): Promise<void> {
    const toast = await this.toastCtrl.create({ message, duration: 7000 });
    toast.present();
  }

  hasNotFilledRequiredChipTextArea(): boolean {
    const chipTextAreaComponents = this.chipTextAreaComponents?.toArray();
    if (!chipTextAreaComponents) {
      return false;
    }
    const notFilledRequiredChipTextArea = chipTextAreaComponents.filter((component) => {
      return component.isRequired && (!component.innerHtml || component.innerHtml === '');
    })
    return notFilledRequiredChipTextArea.length > 0;
  }

  handleNotFilledRequiredChipTextArea(): void {
    const chipTextAreaComponents = this.chipTextAreaComponents?.toArray();
    if (!chipTextAreaComponents) {
      return;
    }
    const notFilledRequiredChipTextArea = chipTextAreaComponents.filter((component) => {
      return component.isRequired && (!component.innerHtml || component.innerHtml === '');
    })
    notFilledRequiredChipTextArea.forEach((component) => {
      component.control.markAsTouched();
    })
    const anchorId = notFilledRequiredChipTextArea[0]?.id;
    if (anchorId) {
      this.viewScroller.scrollToAnchor(anchorId);
    }
  }

  get actionsData(): FormArray {
    return this.workflowVersionForm.get('actions_data') as FormArray;
  }

  getActionForm(index: number): FormGroup {
    return this.actionsForms.at(index) as FormGroup;
  }

  shouldDisplayTriggerFiltersText(): boolean {
    return this.triggerInputsLoaded && this.selectedTrigger?.inputs?.length && this.selectedTrigger?.inputs?.some((input) => input?.form?.type === 'multi_select' || input?.form?.type === 'select');
  }

  copyEmail(email: string): void {
    this.util.copyToClipBoard(email + this.emailSuffix);
  }

  @Loading('', true)
  async selectTrigger(trigger) {
    this.triggerInputsLoaded = false;
    if (trigger.name == 'Request Trigger') {
      return this.rightSidePanelService.navigateTo('automations/feature-request', { featureType: 'trigger', recommendedWorkflowIndex: this.recommendedWorkflowIndex });
    }
    if (trigger.coming_soon) {
      return this.rightSidePanelService.navigateTo('automations/feature-request', { feature: trigger, featureType: 'trigger', recommendedWorkflowIndex: this.recommendedWorkflowIndex });
    }
    this.workflowVersionForm.patchValue({
      trigger_data: {
        id: trigger.id,
        name: trigger.name,
        input_values: {},
        data: {}
      }
    });
    const triggerInputsForm = this.workflowVersionForm.get('trigger_data').get('input_values') as FormGroup;
    trigger?.inputs?.forEach((input) => {
      const defaultValue = this.getDefaultInputValue(input);
      triggerInputsForm.addControl(input.key, this.formBuilder.control(defaultValue, input.required ? Validators.required : null ));
      triggerInputsForm.addControl(input.key + '-radio', this.formBuilder.control('', null));
    });
    this.selectedTrigger = trigger;
    await this.getInputValues();
    if (this.selectedActions.length == 0) {
      this.addAction();
    }
    this.triggerInputsLoaded = true;
    this.isCreatingWorkflowVersion = false;
    await this.createWorkflowVersion();
  }

  getDefaultInputValue(input) {
    if (input.key == 'timezone') {
      return 'America/Los_Angeles';
    } else if (input.key == 'trigger_on_weekends') {
      return true;
    } else if (input.key == 'time_of_day') {
      return 12;
    } else if (input.key == 'day_of_the_week') {
      return 0;
    } else if (input.key == 'date_of_the_month') {
      return 1;
    }
  }

  async getInputValues() {
    const hasSelect = this.selectedTrigger.inputs.find((input) => input.form.type == 'select' || input.form.type == 'multi_select' || input.key == 'assigned_to_concierge');
    if (hasSelect) {
      const inputData = await this.workflowProvider.getInputData(this.selectedTrigger.id, 'Trigger');
      inputData.map((data) => {
        this.selectedTrigger.inputs.map((input) => {
          if (data.input_key == input.key) {
            input['items'] = data.input_options.map((option) => ({
              value: option.value,
              viewValue: option.label
            }));

            const allOptionsLabel = (label) => {
              const foundLabel = {
                'Categories': 'Trigger on all categories',
                'Addresses': 'Trigger on all properties',
                'Address': 'Trigger on all properties',
                'Service Types': 'Trigger on all services',
                'Service Type': 'Trigger on all services',
                'Issue Type': 'Trigger on all issues',
                'Job State': 'Trigger on all job states',
                'Assigned to Concierge?': 'No filter',
                'Only trigger on scheduled or in progress jobs': 'No filter',
              }[label];
              return foundLabel ?? `All ${label}`;
            }

            this.addRadioItems(input.form.label, allOptionsLabel(input.form.label))

            if (!input.required && input.form.type !== 'multi_select' && input.key !== 'assigned_to_concierge') {
              input['items'].unshift({
                value: ALL_OPTIONS_VALUE,
                viewValue: allOptionsLabel(input.form.label)
              });
            }
          }
        })
      });
    }
  }

  addRadioItems(label: string, allOptionsLabel: string): void {
    if (this.radioItems?.[label]) {
      return;
    }
    const adjustedLabel = this.getAdjustedLabel(label.toLowerCase());
    this.radioItems = {
      ...this.radioItems,
      [label]: [
        {
          value: ALL_OPTIONS_VALUE,
          viewValue: allOptionsLabel
        },
        {
          value: adjustedLabel,
          viewValue: adjustedLabel
        },
      ]
    }
  }

  getAdjustedLabel(label) {
    let adjustedLabel = 'Trigger only on some ' + label;
    if (label.includes('address')) {
      adjustedLabel = 'Trigger only on some properties';
    } else if (label.includes('state')) {
      adjustedLabel = 'Trigger on specific states';
    } else if (label.includes('assigned to concierge?')) {
      adjustedLabel = 'Trigger only for concierge assigned or not';
    }
    return adjustedLabel;
  }

  async addAction() {
    const actionIndex = this.selectedActions.length;
    const actionForm = {
      formId: [''],
      id: [''],
      name: [''],
      description: [''],
      input_values: this.formBuilder.group({})
    };
    const actionFormGroup = this.formBuilder.group(actionForm);
    this.actionsForms.insert(actionIndex, actionFormGroup);
    this.selectedActions.push(actionFormGroup.value);
  }

  async selectActionType(newAction, formIndex: number) {
    if (newAction.coming_soon) {
      return this.rightSidePanelService.navigateTo('automations/feature-request', { feature: newAction, featureType: 'action' });
    }
    this.isCreatingWorkflowVersion = false;
    const form = this.getActionForm(formIndex);
    if (!form) {
      return;
    }
    const inputData = await this.workflowProvider.getInputData(newAction.id, 'Action');
    newAction.inputData = inputData || [];
    newAction.inputOptions = this.getInputOptions(inputData);
    form.patchValue({
      formId: Math.random().toString(36).substring(7),
      id: newAction.id,
      name: newAction.name,
    });
    newAction?.inputs?.forEach((input) => {
      (form.controls.input_values as FormGroup).addControl(input.key, this.formBuilder.control('', input.required ? Validators.required : null));
    });
    this.selectedActions[formIndex] = newAction;
    this.createWorkflowVersion();
    form.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => this.createWorkflowVersion());
  }

  getInputOptions(inputData: any[]) {
    if (!inputData) {
      return [];
    }
    const inputOptions = inputData.map((data) => data?.input_options).flat();
    return inputOptions || [];
  }

  searchActions(value) {
    if (value == '') {
      return this.filteredActions = this.allActions;
    }
    this.filteredActions = [];
    const valueLower = value.toLowerCase();
    const actionFilter = (action) => {
      if (action.name.toLocaleLowerCase().includes(valueLower)) return true;
      if (action.description.toLocaleLowerCase().includes(valueLower)) return true;
    }
    this.filteredActions = this.allActionsData.filter(actionFilter);
  }

  searchTriggers(value) {
    if (value == '') {
      return this.filteredTriggers = this.allTriggers;
    }
    this.filteredTriggers = [];
    const valueLower = value.toLowerCase();
    const triggerFilter = (trigger) => {
      if (trigger.name.toLocaleLowerCase().includes(valueLower)) return true;
      if (trigger.description.toLocaleLowerCase().includes(valueLower)) return true;
    }
    this.filteredTriggers = this.allTriggersData.filter(triggerFilter);
  }

  changeTrigger() {
    this.selectedTrigger = null;
  }

  async deleteWorkflow() {
    const confirmDelete: ConfirmPageParamsModel = {
      title: 'Delete Workflow?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete',
      confirmAction: async () => {
        this.confirmDeleteWorkflow();
        this.modalCtrl.dismiss();
      }
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmDelete,
      animated: false,
      cssClass: 'tidy-transparent-modal',
    });
    confirmationModal.present();
  }

  async confirmDeleteWorkflow() {
    try {
      await this.workflowProvider.deleteWorkflow(this.workflowId);
      this.goToPage('automations/workflows', {}, WorkflowsPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  forceUpdateOfNextChipTextArea(actionIndex: any): void {
    const foundNextAction = this.getActionForm(actionIndex + 1);
    if (!foundNextAction) {
      return;
    }
    const foundNextChipTextArea = this.chipTextAreaComponents.find((component) => component.id === foundNextAction.get('formId').value)
    const foundCurrentAction = this.getActionForm(actionIndex);
    const foundChipTextArea = this.chipTextAreaComponents.find((component) => component.id === foundCurrentAction.get('formId').value)
    foundChipTextArea.body = {
      content: foundNextChipTextArea.innerHtml,
      chips: foundNextChipTextArea.placeholders
    }
    foundChipTextArea.parseAndSetBody()
  }

  async deleteAction(actionIndex: any) {
    const filteredActions = this.actionsForms.value.filter((action) => action.id !== '');
    let actionsPayload = this.parseActions(filteredActions);
    actionsPayload.splice(actionIndex, 1);
    this.forceUpdateOfNextChipTextArea(actionIndex);
    this.actionsForms.removeAt(actionIndex);
    this.selectedActions.splice(actionIndex, 1);
    const didAddAction = this.actionsForms.value.length > 0;
    if (didAddAction) {
      this.createWorkflowVersion(actionsPayload);
    }
  }

  goToAddConciergeTask() {
    const params = {
      title: this.isAddingWorkflow ? 'Add Workflow' : 'Edit Workflow',
      type: 'support.concierge_task',
      isWorkflow: true,
      workflowName: this.workflowForm.value.name,
      metadata: {
        workflow_id: this.workflowId
      }
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  onInputChange(event: InputFocusOut) {
    if (event.value) {
      this.createWorkflowVersion();
    }
  }

  addChipPlaceholder(output: any, actionIndex: string, inputKey: string): void {
    this.dontUpdateOutputs = true;
    const chipTextAreaComponent = this.chipTextAreaComponents.find((component) => component.id === actionIndex && component.inputKey === inputKey);
    const placeholder = {
      text: output.plain_title ? output.plain_title : output.title,
      value: output.key
    }
    chipTextAreaComponent.appendPlaceholder(placeholder)
  }

  toggleShowOutputOptions(actionIndex, outputIndex) {
    this.selectedActions[actionIndex].outputs[outputIndex].showOptions = this.selectedActions[actionIndex].outputs[outputIndex].showOptions ? false : true;
  }

  getTriggerFilterHeaderText() {
    if (this.selectedTrigger?.inputs?.some((input) => input?.form?.type === 'multi_select' || input?.form?.type === 'select')) {
      return 'These can narrow when a trigger fires.'
    }
    if (this.selectedTrigger.name.includes('Every')) {
      return 'Run this trigger on:'
    } else if (this.selectedTrigger.name == 'Job Completed') {
      return 'Filter jobs completed by:'
    } else {
      return 'Filter this trigger by:'
    }
  }

  getFilterNameInSingular(label: string): string {
    const filterNames = {
      'Categories': 'Category',
      'Addresses': 'Property',
      'Address': 'Property',
      'Service Types': 'Service',
      'Service Type': 'Service',
      'Issue Type': 'Issue',
      'Assigned to Concierge?': 'Concierge',
    }
    return filterNames[label] || label
  }

  getPageTitle() {
    if (this.isAddingWorkflow) {
      return 'Add Workflow';
    } else {
      return 'Edit Draft';
    }
  }

  getTriggerViewValues(values, label, items) {
    if (values == '') {
      return 'All ' + label;
    }
    let string = '';
    if (values) {
      values.map((value) => {
        const item = items.find((item) => item.value == value);
        string += item.viewValue + ', ';
      });
    }
    string = string.slice(0, -2);
    return string;
  }

  async onSelectRecommendedWorkflow(
    workflow: RecommendedWorkflow
  ): Promise<void> {
    await this.foundAndSelectTrigger(workflow.trigger);
    this.foundAndSelectActions(workflow.actions);
    this.patchTriggerInputs(workflow.trigger.inputs);
    setTimeout(() => {
      this.actionsForms.value.forEach((action, index) => {
        const chipTextAreaComponents = this.chipTextAreaComponents.filter(
          (component) => component.id === action.formId
        );
        const foundAction = workflow.actions[index];
        chipTextAreaComponents.forEach((chipTextAreaComponent) => {
          const parsedContent = this.parseBodyToGetStepId(
            foundAction?.[chipTextAreaComponent?.inputKey] || ''
          );
          chipTextAreaComponent.body = {
            content: this.parseBody((parsedContent || ''), true),
            chips: this.parseOutputs(),
          };
          chipTextAreaComponent.parseAndSetBody();
        });
      });
    }, 1000);
  }

  patchTriggerInputs(inputs: InputModel[]): void {
    if (!inputs?.length) {
      return;
    }
    const inputsForm = this.workflowVersionForm.get(
      'trigger_data'
    ).get('input_values') as FormGroup;
    inputs.forEach((input) => {
      const key = input.key;
      const value = input.value;
      inputsForm.get(key).patchValue(value);
    });
  }

  parseBodyToGetStepId(body: string): string {
    const outputs = this.parseOutputs();
    const stepId = outputs[0]?.value?.replace('{{', '').replace('}}', '').split('.')[0];
    if (!stepId) {
      return body;
    }
    const regex = new RegExp('step_id', 'g');
    const parsedBody = body.replace(regex, stepId);
    return parsedBody;
  }

  async foundAndSelectTrigger(trigger: TriggerModel): Promise<void> {
    const { category, name } = trigger;
    const foundTriggerCategory = this.filteredTriggers.find(
      (item) => item.category.toLowerCase() === category.toLowerCase()
    );
    const foundTrigger = foundTriggerCategory.data.find(
      (item) => item.name.toLowerCase() === name.toLowerCase()
    );
    await this.selectTrigger(foundTrigger);
  }

  foundAndSelectActions(actions: ActionModel[]): void {
    actions.forEach((action) => {
      const actionKey = action.key;
      if (!actionKey) {
        return;
      }
      const foundConciergeTaskAction = this.filteredActions
        .map((action) => action.data.filter((item) => item.key === actionKey))
        .flat()
        .find((item) => item.key === actionKey);
      this.selectActionType(foundConciergeTaskAction, 0);
    });
  }

  getParsedOutputs(outputs: any[]): FieldOption[] {
    if (!outputs?.length) {
      return [];
    }
    const allOutputs = outputs?.map((output) => output?.array)?.flat();
    return allOutputs?.map((output) => ({
      viewValue: output?.plain_title || output?.title,
      value: output?.key
    }))
  }

  onFormValuesChange(formValues: FormValues[][], actionIndex: number): void {
    const actionForm = this.getActionForm(actionIndex);
    const inputValues = actionForm?.get('input_values') as FormGroup;
    inputValues.patchValue({
      filter_groups: formValues
    });
  }

  async addAttachment(action: any): Promise<void> {
    this.errorMessage = '';
    try {
      const attachment = await this.workflowProvider.addAttachment();
      if (attachment.filename !== '') {
        if (!action?.input_values) {
          action.input_values = {};
        }
        if (!action.input_values?.attachments) {
          action.input_values.attachments = [];
        }
        action.input_values.attachments.push(attachment.url);
        const actionForm = this.actionsForms.value?.find((form) => form.id === action.id);
        actionForm.input_values.attachments = action.input_values.attachments;
        this.createWorkflowVersion();
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      console.error(err);
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  removeAttachment(action: any, index: number): void {
    action.input_values.attachments.splice(index, 1);
    this.createWorkflowVersion();
  }

  async goToPage(url, params, component) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

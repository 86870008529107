import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Auth } from 'src/providers/auth/auth';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CookieProvider } from 'src/providers/cookie/cookie';
import { Me } from 'src/providers/me/me';
import { Proposals } from 'src/providers/proposals/proposals';

import { validateEmail } from 'src/shared/validator/validateEmail';

@Component({
  templateUrl: 'log-in-to-book.html'
})

export class LogInToBookPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  proposal: any;
  submitted: boolean;

  constructor(
    private auth: Auth,
    private _fb: UntypedFormBuilder,
    private cookieProvider: CookieProvider,
    private me: Me,
    private navCtrl: CustomNavController,
    private proposals: Proposals
  ) {
    this.form = this._fb.group({
      email: ['', Validators.compose([Validators.required, validateEmail])],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.proposal = this.navCtrl.getParam('proposal');
    this.form.patchValue({email: this.proposal.customer.email});
  }

  async logIn() {
    this.submitted = true;
    this.errorMessage = '';
    if (this.proposal.customer.email !== this.form.value.email) {
      return this.errorMessage = 'This proposal was sent to a different user. Please log into the user the proposal was sent to.';
    }
    if (!this.form.valid) {
      return;
    }
    const credentials = this.buildCredentials();
    try {
      await this.auth.login(credentials, 'login');
      await this.loadAccount();
      localStorage.setItem('addressId', this.proposal.customer.address_ids[0]);
      this.proposals.goToBookJob(this.proposal);
    } catch (err) {
      this.errorMessage = 'The email and password you entered did not match our records. Please double-check and try again.';
    }
  }

  buildCredentials(): any {
    const utmzTidy = this.cookieProvider.getCookie('__utmztidy');
    return {
      username: this.form.value.email,
      password: this.form.value.password,
      date_added_to_cookie: utmzTidy ? utmzTidy.dateTime : '',
      utm_source: utmzTidy ? utmzTidy.source : ''
    };
  }

  loadAccount() {
    return this.me.load().then(async account => {
      const customerObj: any = account;
      this.auth.setAccountTypeStorage(customerObj.account_type);
      await this.auth.setSession();
    });
  }

  goToClientHub() {
    const clientHubUuId = this.navCtrl.getParam('clientHubUuId');
    this.navCtrl.navigateForward(`client/${clientHubUuId}`);
  }
}

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Injectable()
export class UserService {

  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  async getUsersList() {
    const url = 'api/v1/customer/customer-members?includes=team_addresses_permissions';
    const data = await this.httpClient.get(url);
    return data;
  }

  addNewUser(params) {
    const url = 'api/v1/customer/customer-members';
    return this.httpClient.post(url, params);
  }

  updateUserInfo(params, memberId) {
    const url = `/api/v1/customer/customer-members/${memberId}`;
    return this.httpClient.put(url, params);
  }

  deleteUser(memberId) {
    const url = `/api/v1/customer/customer-members/${memberId}`;
    return this.httpClient.delete(url);
  }

  changeUserRole(memberId, params) {
    const url = `/api/v1/customer/customer-members/${memberId}/change-role`;
    return this.httpClient.post(url, params);
  }
}

import { Component, OnInit } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

@Component({
  selector: 'select-integration-onboarding',
  templateUrl: './select-integration-onboarding.html',
  styleUrls: ['./select-integration-onboarding.scss']
})

export class SelectIntegrationOnboarding implements OnInit {

  typeItems = [
    {
      viewValue: 'Yes',
      value: 'yes'
    },
    {
      viewValue: 'No',
      value: 'no'
    }
  ];
  primaryTypeItems = [
    {
      viewValue: 'AirBnB',
      value: 'airbnb'
    },
    {
      viewValue: 'VRBO',
      value: 'vrbo'
    }
  ];
  form: UntypedFormGroup;
  submitted = false;

  constructor(
    private guestReservations: GuestReservations,
    private onboardingProvider: OnboardingProvider,
    private formBuilder: UntypedFormBuilder,
    private storage: TidyStorage
  ) {
    this.form = this.formBuilder.group({
      type: [null, Validators.required],
      primaryType: [null]
    });
  }

  ngOnInit() {
    this.onboardingProvider.subTitle = 'Placeholder Text';
  }

  async goToAddIntegrationPage() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.onboardingProvider.priorPages.push('selectIntegration');
    if (this.form.value.type === 'yes') {
      this.storage.save('cameFromIntegrateOnboarding', true);
      this.onboardingProvider.page = 'directIntegration';
      this.onboardingProvider.showBackButton = true;
    } else if (this.form.value.primaryType === 'airbnb') {
      const integration = await this.getIntegration('Airbnb');
      await this.storage.save('reservationSrcType', integration);
      this.onboardingProvider.page = 'addDirectIntegration';
      this.onboardingProvider.showBackButton = true;
    } else if (this.form.value.primaryType === 'vrbo') {
      const integration = await this.getIntegration('Vrbo');
      await this.storage.save('reservationSrcType',integration);
      this.onboardingProvider.page = 'addDirectIntegration';
      this.onboardingProvider.showBackButton = true;
    }
  }

  async getIntegration(integrationType: string) {
    const allIntegrations = await this.guestReservations.getSourceTypes();
    const filteredIntegrations = this.filterToShowOneIntegrationPerType(allIntegrations);
    const integration = filteredIntegrations.find(integration => integration.name === integrationType);
    return integration;
  }

  filterToShowOneIntegrationPerType(allIntegrations) {
    const filteredIntegrations: any = [];
    allIntegrations.map((integration) => {
      const alreadyAddedIndex = filteredIntegrations.findIndex(item => item.name === integration.name);
      if (alreadyAddedIndex !== -1) {
        filteredIntegrations[alreadyAddedIndex]['formats'].push(integration.format);
        filteredIntegrations[alreadyAddedIndex]['integrations'].push(integration);
        integration.active_details.map((detail) => {
          filteredIntegrations[alreadyAddedIndex]['active_details'].push(detail);
        });
      } else {
        filteredIntegrations.push({
          formats: [integration.format],
          integrations: [integration],
          ...integration
        });
      }
    });
    return filteredIntegrations;
  }

  onTypeChange() {
    if (this.form.value.type === 'no') {
      this.form.get('primaryType').setValidators([Validators.required]);
    } else {
      this.form.get('primaryType').clearValidators();
    }
    this.form.get('primaryType').updateValueAndValidity();
  }


} 
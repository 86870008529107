export const restockingMockData = [
  {
      "id": 35340,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Ironing Board",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5142,
          "name": "Ironing Board",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35339,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Iron",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5141,
          "name": "Iron",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35342,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass Champagne Glass",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10005,
          "name": "Wine Glass Champagne Glass",
          "model": "Champagne Glass",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35343,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Mug",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5637,
          "name": "Mug",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 417,
              "name": "Mug",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hot-drink.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35350,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Fork",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5358,
          "name": "Fork",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 251,
              "name": "Fork",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/fork.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35355,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toaster",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 6766,
          "name": "Toaster",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 517,
              "name": "Toaster",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/toaster (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35341,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7623,
          "name": "Wine Glass",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35344,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Plate",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4448,
          "name": "Plate",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 248,
              "name": "Plate",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35345,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Bowl",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4954,
          "name": "Bowl",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35346,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Glass / Cup",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5600,
          "name": "Glass / Cup",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 416,
              "name": "Glass / Cup",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/glass-of-water.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35347,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Pot",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5020,
          "name": "Pot",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35348,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Pan / Skillet",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5392,
          "name": "Pan / Skillet",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35349,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Spatula",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5840,
          "name": "Spatula",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35351,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35353,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5316,
          "name": "Knife",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35354,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee Pot Coffee Maker",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10008,
          "name": "Coffee Pot Coffee Maker",
          "model": "Coffee Maker",
          "kind": "specific",
          "category": {
              "id": 516,
              "name": "Coffee Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/coffee-pot (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35356,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Blender",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7742,
          "name": "Blender",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35361,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Ironing Board",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5142,
          "name": "Ironing Board",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35352,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Spoon",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10007,
          "name": "Spoon",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35369,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Bowl",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4954,
          "name": "Bowl",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35390,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7623,
          "name": "Wine Glass",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35357,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Crib Pack and Play",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10009,
          "name": "Crib Pack and Play",
          "model": "Pack and Play",
          "kind": "specific",
          "category": {
              "id": 778,
              "name": "Crib",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cribicon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard",
                  "garage"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35360,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Iron",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5141,
          "name": "Iron",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35358,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Crib Pack and Play",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10009,
          "name": "Crib Pack and Play",
          "model": "Pack and Play",
          "kind": "specific",
          "category": {
              "id": 778,
              "name": "Crib",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cribicon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard",
                  "garage"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35364,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Vacuum",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5211,
          "name": "Vacuum",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 412,
              "name": "Vacuum",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/vacuum.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35372,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Glass / Cup",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5600,
          "name": "Glass / Cup",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 416,
              "name": "Glass / Cup",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/glass-of-water.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35382,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Blender",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7742,
          "name": "Blender",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35388,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Broom",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5212,
          "name": "Broom",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35569,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10089,
          "name": "599 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35570,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 Champagne Glasses",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10090,
          "name": "599 Champagne Glasses",
          "model": "Champagne Glasses",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35571,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "417 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10091,
          "name": "417 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 417,
              "name": "Mug",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hot-drink.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35572,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "248 ",
      "min_quantity": 24,
      "current_quantity": 25,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10092,
          "name": "248 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 248,
              "name": "Plate",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35573,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 ",
      "min_quantity": 24,
      "current_quantity": 25,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8808,
          "name": "249 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35575,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "428 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10094,
          "name": "428 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35577,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 Mixing Bowls",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10096,
          "name": "249 Mixing Bowls",
          "model": "Mixing Bowls",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35578,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 Cooking Dish",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10097,
          "name": "429 Cooking Dish",
          "model": "Cooking Dish",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35580,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "251 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10099,
          "name": "251 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 251,
              "name": "Fork",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/fork.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35581,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35582,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "744 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10100,
          "name": "744 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35583,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "250 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10101,
          "name": "250 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35585,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "517 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10103,
          "name": "517 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 517,
              "name": "Toaster",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/toaster (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35588,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 King Throw Blanket",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10106,
          "name": "408 King Throw Blanket",
          "model": "King Throw Blanket",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35590,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Throw Blankets",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10108,
          "name": "408 Queen Throw Blankets",
          "model": "Queen Throw Blankets",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35592,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Twin Throw Blanket",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10110,
          "name": "408 Twin Throw Blanket",
          "model": "Twin Throw Blanket",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35598,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Crib Pack and Play",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10009,
          "name": "Crib Pack and Play",
          "model": "Pack and Play",
          "kind": "specific",
          "category": {
              "id": 778,
              "name": "Crib",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cribicon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard",
                  "garage"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35617,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "412 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9406,
          "name": "412 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 412,
              "name": "Vacuum",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/vacuum.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35619,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 ",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10089,
          "name": "599 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35630,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10095,
          "name": "429 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35631,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8808,
          "name": "249 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35632,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 ",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10095,
          "name": "429 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35633,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "424 ",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10098,
          "name": "424 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35635,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35636,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "744 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10100,
          "name": "744 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35637,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "250 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10101,
          "name": "250 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35640,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "598 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10104,
          "name": "598 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35644,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Throw Blankets",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10108,
          "name": "408 Queen Throw Blankets",
          "model": "Queen Throw Blankets",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35651,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Twin Duvet Inserts",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10109,
          "name": "408 Twin Duvet Inserts",
          "model": "Twin Duvet Inserts",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35652,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Twin Throw Blanket",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10110,
          "name": "408 Twin Throw Blanket",
          "model": "Twin Throw Blanket",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35363,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Broom",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5212,
          "name": "Broom",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35365,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7623,
          "name": "Wine Glass",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35367,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Mug",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5637,
          "name": "Mug",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 417,
              "name": "Mug",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hot-drink.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35380,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee Pot Coffee Maker",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10008,
          "name": "Coffee Pot Coffee Maker",
          "model": "Coffee Maker",
          "kind": "specific",
          "category": {
              "id": 516,
              "name": "Coffee Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/coffee-pot (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35386,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Ironing Board",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5142,
          "name": "Ironing Board",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35389,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Vacuum",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5211,
          "name": "Vacuum",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 412,
              "name": "Vacuum",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/vacuum.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35378,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Spoon",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10007,
          "name": "Spoon",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35366,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass Champagne Glass",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10005,
          "name": "Wine Glass Champagne Glass",
          "model": "Champagne Glass",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35368,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Plate",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4448,
          "name": "Plate",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 248,
              "name": "Plate",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35373,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Pot",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5020,
          "name": "Pot",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35374,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Pan / Skillet",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5392,
          "name": "Pan / Skillet",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35375,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Spatula",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5840,
          "name": "Spatula",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35376,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Fork",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5358,
          "name": "Fork",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 251,
              "name": "Fork",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/fork.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35377,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 8,
      "current_quantity": 9,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35379,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5316,
          "name": "Knife",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35383,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Crib Pack and Play",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10009,
          "name": "Crib Pack and Play",
          "model": "Pack and Play",
          "kind": "specific",
          "category": {
              "id": 778,
              "name": "Crib",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cribicon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard",
                  "garage"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35385,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Iron",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5141,
          "name": "Iron",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 32334,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 2,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 32336,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32337,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32338,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32339,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 6,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32340,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32341,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32342,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32343,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32344,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32346,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32347,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32348,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32350,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32351,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toilet Paper",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4514,
          "name": "Toilet Paper",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 244,
              "name": "Toilet Paper",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/toilet-paper.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32352,
      "address_id": 632943,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 632943,
          "address1": "Pampas Place",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89146",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 150,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1319771,
          "longitude": -115.2153937,
          "created_at": "2024-09-10T10:03:13Z",
          "updated_at": "2025-01-13T18:03:22Z"
      }
  },
  {
      "id": 32354,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32355,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32358,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32359,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32360,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32361,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32362,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32364,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32366,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32367,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toilet Paper",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4514,
          "name": "Toilet Paper",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 244,
              "name": "Toilet Paper",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/toilet-paper.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32368,
      "address_id": 623561,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 11,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 623561,
          "address1": "1918 Pintura Circle",
          "address2": null,
          "city": "North Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89031",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 151,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Pintura 1918",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.2512335,
          "longitude": -115.1693707,
          "created_at": "2024-07-24T21:38:59Z",
          "updated_at": "2024-07-24T21:38:59Z"
      }
  },
  {
      "id": 32369,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32370,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32371,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32372,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 35381,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toaster",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 6766,
          "name": "Toaster",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 517,
              "name": "Toaster",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/toaster (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32378,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 35613,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "414 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10119,
          "name": "414 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 32401,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32375,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent",
      "min_quantity": 2,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32418,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32422,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32424,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32427,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32430,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage Bag",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4550,
          "name": "Garbage Bag",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32431,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32432,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toilet Paper",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4514,
          "name": "Toilet Paper",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 244,
              "name": "Toilet Paper",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/toilet-paper.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32434,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32435,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32444,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 35772,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "421 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10121,
          "name": "421 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35800,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass Champagne Glass",
      "min_quantity": 10,
      "current_quantity": 11,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10005,
          "name": "Wine Glass Champagne Glass",
          "model": "Champagne Glass",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 32449,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 27,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32492,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32493,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32495,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 6,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32496,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32497,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32498,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32499,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32501,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32502,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32503,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32506,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toilet Paper",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4514,
          "name": "Toilet Paper",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 244,
              "name": "Toilet Paper",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/toilet-paper.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32508,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32512,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32515,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32516,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32517,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32519,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32521,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32523,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32526,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32529,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32532,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32533,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32534,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32536,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag  2-4 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9612,
          "name": "Garbage/Trash Bag  2-4 gallon cases of trash bags",
          "model": " 2-4 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32374,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32376,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 32381,
      "address_id": 623550,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "2-4 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9609,
          "name": null,
          "model": "2-4 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623550,
          "address1": "8157 Creek Water Lane",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89123",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Creekwater 8157",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0411866,
          "longitude": -115.1420048,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-11-15T20:40:27Z"
      }
  },
  {
      "id": 35391,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7623,
          "name": "Wine Glass",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 32396,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 32421,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 6,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32423,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32425,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32426,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32428,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32429,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32433,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32436,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 100,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32439,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32440,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32442,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32443,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32447,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32494,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32500,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Body Wash",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8569,
          "name": "Body Wash",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 704,
              "name": "Body Wash",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/body-lotion (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32504,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag  2-4 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9612,
          "name": "Garbage/Trash Bag  2-4 gallon cases of trash bags",
          "model": " 2-4 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32505,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Paper Towels",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4415,
          "name": "Paper Towels",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 246,
              "name": "Paper Towels",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/paper-towel.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32507,
      "address_id": 623558,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 14,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 623558,
          "address1": "531 Silent Siesta Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Silent Siesta 531",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.024487,
          "longitude": -114.955811,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:57Z"
      }
  },
  {
      "id": 32510,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32511,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 6,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32513,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dishwasher Pods",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8571,
          "name": "Dishwasher Pods",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 706,
              "name": "Dishwasher Pods",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/laundry (1).svg",
              "room_categories": [
                  "office",
                  "patio",
                  "reception_area",
                  "kitchen",
                  "hallway",
                  "stairwell",
                  "back_yard",
                  "bedroom",
                  "backyard",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32514,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8205,
          "name": "Detergent Laundry Pods",
          "model": "Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32518,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Floor Cleaner",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 7315,
          "name": "Floor Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 552,
              "name": "Floor Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/floor-cleaner (1).svg",
              "room_categories": [
                  "kitchen",
                  "stairwell",
                  "back_yard",
                  "office",
                  "patio",
                  "reception_area",
                  "hallway",
                  "living_room"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32522,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Toilet Paper",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4514,
          "name": "Toilet Paper",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 244,
              "name": "Toilet Paper",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/toilet-paper.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32525,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32527,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32528,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 3,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32530,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Detergent  Laundry Pods",
      "min_quantity": 2,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9650,
          "name": "Detergent  Laundry Pods",
          "model": " Laundry Pods",
          "kind": "specific",
          "category": {
              "id": 253,
              "name": "Detergent",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/detergent (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32535,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35574,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "416 Drinking Glasses",
      "min_quantity": 18,
      "current_quantity": 19,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10093,
          "name": "416 Drinking Glasses",
          "model": "Drinking Glasses",
          "kind": "specific",
          "category": {
              "id": 416,
              "name": "Glass / Cup",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/glass-of-water.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35576,
      "address_id": 623530,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10095,
          "name": "429 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623530,
          "address1": "Champions Avenue",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89142",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr]  Champions 5048",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1441714,
          "longitude": -115.0610809,
          "created_at": "2024-07-24T21:38:38Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35579,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "424 ",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10098,
          "name": "424 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 32409,
      "address_id": 623552,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623552,
          "address1": "4200 South Valley View Boulevard",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89103",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Valley View 4200 #3047",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1126358,
          "longitude": -115.187581,
          "created_at": "2024-07-24T21:38:53Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 32445,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9610,
          "name": "Garbage/Trash Bag 13 gallon cases of trash bags",
          "model": "13 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32520,
      "address_id": 623551,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "2-4 gallon cases of trash bags",
      "min_quantity": 2,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9609,
          "name": null,
          "model": "2-4 gallon cases of trash bags",
          "kind": "specific",
          "category": {
              "id": 245,
              "name": "Garbage/Trash Bag",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/garbage-bag.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623551,
          "address1": "2070 Irwin Circle",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Irwin 2070",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1090045,
          "longitude": -115.1241957,
          "created_at": "2024-07-24T21:38:52Z",
          "updated_at": "2024-07-24T21:38:53Z"
      }
  },
  {
      "id": 35584,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "745 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10102,
          "name": "745 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 745,
              "name": "Cofee Maker",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cofeemakericon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35586,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "598 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10104,
          "name": "598 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35587,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 King Duvet Insert",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10105,
          "name": "408 King Duvet Insert",
          "model": "King Duvet Insert",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35589,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Duvet Inserts",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10107,
          "name": "408 Queen Duvet Inserts",
          "model": "Queen Duvet Inserts",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35593,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Fitted Sheet",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10111,
          "name": "408 Queen Fitted Sheet",
          "model": "Queen Fitted Sheet",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35612,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "413 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10118,
          "name": "413 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35615,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "421 ",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10121,
          "name": "421 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35620,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass Champagne Glass",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10005,
          "name": "Wine Glass Champagne Glass",
          "model": "Champagne Glass",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35623,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "417 ",
      "min_quantity": 6,
      "current_quantity": 7,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10091,
          "name": "417 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 417,
              "name": "Mug",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hot-drink.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35625,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "248 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10092,
          "name": "248 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 248,
              "name": "Plate",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35627,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8808,
          "name": "249 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35628,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "416 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10126,
          "name": "416 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 416,
              "name": "Glass / Cup",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/glass-of-water.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35634,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "251 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10099,
          "name": "251 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 251,
              "name": "Fork",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/fork.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35638,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "745 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10102,
          "name": "745 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 745,
              "name": "Cofee Maker",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cofeemakericon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35641,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 King Duvet Inserts",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10127,
          "name": "408 King Duvet Inserts",
          "model": "King Duvet Inserts",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35642,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 King Throw Blanket",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10106,
          "name": "408 King Throw Blanket",
          "model": "King Throw Blanket",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35643,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Duvet Inserts",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10107,
          "name": "408 Queen Duvet Inserts",
          "model": "Queen Duvet Inserts",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35696,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "744 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10100,
          "name": "744 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35699,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Queen Duvet Insert",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10162,
          "name": "408 Queen Duvet Insert",
          "model": "Queen Duvet Insert",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35769,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "413 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10118,
          "name": "413 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35770,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "414 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10119,
          "name": "414 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35773,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "412 ",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 9406,
          "name": "412 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 412,
              "name": "Vacuum",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/vacuum.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35774,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 ",
      "min_quantity": 10,
      "current_quantity": 11,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10089,
          "name": "599 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35777,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "248 ",
      "min_quantity": 15,
      "current_quantity": 16,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10092,
          "name": "248 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 248,
              "name": "Plate",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35780,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "428 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10094,
          "name": "428 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35781,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10095,
          "name": "429 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35782,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 Mixing Bowls",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10096,
          "name": "249 Mixing Bowls",
          "model": "Mixing Bowls",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35783,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "429 Cooking Dish",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10097,
          "name": "429 Cooking Dish",
          "model": "Cooking Dish",
          "kind": "specific",
          "category": {
              "id": 429,
              "name": "Pan / Skillet",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/frying-pan.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35784,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "424 ",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10098,
          "name": "424 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35786,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 12,
      "current_quantity": 12,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35791,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "598 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10104,
          "name": "598 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35794,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "413 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10118,
          "name": "413 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35801,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "417 ",
      "min_quantity": 10,
      "current_quantity": 11,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10091,
          "name": "417 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 417,
              "name": "Mug",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hot-drink.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35805,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "428 ",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10094,
          "name": "428 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35807,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 Mixing Bowls",
      "min_quantity": 3,
      "current_quantity": 4,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10096,
          "name": "249 Mixing Bowls",
          "model": "Mixing Bowls",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35810,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "251 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10099,
          "name": "251 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 251,
              "name": "Fork",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/fork.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35811,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Knife Butter Knife",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10006,
          "name": "Knife Butter Knife",
          "model": "Butter Knife",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35812,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "744 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10100,
          "name": "744 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 744,
              "name": "Spoon",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spoon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35813,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "250 ",
      "min_quantity": 12,
      "current_quantity": 13,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10101,
          "name": "250 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35814,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "745 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10102,
          "name": "745 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 745,
              "name": "Cofee Maker",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cofeemakericon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35815,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "517 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10103,
          "name": "517 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 517,
              "name": "Toaster",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/toaster (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35816,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "598 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10104,
          "name": "598 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 598,
              "name": "Blender",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/blender.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35819,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "413 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10118,
          "name": "413 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 413,
              "name": "Iron",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/electrical.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 32419,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Soap",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4481,
          "name": "Dish Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 252,
              "name": "Dish Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/dish-soap (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32420,
      "address_id": 627354,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Coffee",
      "min_quantity": 1,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4584,
          "name": "Coffee",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 257,
              "name": "Coffee",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/beans (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 627354,
          "address1": "Adams Street",
          "address2": null,
          "city": "Hoboken",
          "add_state": "NJ",
          "country_code": "US",
          "zip": "07030",
          "state": "active",
          "home_access": "door security code: 1921",
          "home_close": null,
          "region_id": 405,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 2,
          "floors_amount": 2,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Adams-102 #4",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 40.7387812,
          "longitude": -74.0373887,
          "created_at": "2024-08-12T22:07:02Z",
          "updated_at": "2024-08-29T17:45:48Z"
      }
  },
  {
      "id": 32437,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "All Purpose Cleaner",
      "min_quantity": 4,
      "current_quantity": 0,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5425,
          "name": "All Purpose Cleaner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 334,
              "name": "All Purpose Cleaner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/All Purpose Cleaner.svg",
              "room_categories": [
                  "kitchen",
                  "dining-room",
                  "main_workspace",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "bedroom",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32438,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Sponge",
      "min_quantity": 12,
      "current_quantity": 9,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4921,
          "name": "Sponge",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 254,
              "name": "Sponge",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/sponge (1).svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32441,
      "address_id": 623547,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623547,
          "address1": "120 West Highland Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89015",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 152,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 8,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "(INACTIVE) [str] Highland 120",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.0209711,
          "longitude": -114.9885103,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-12-05T18:33:57Z"
      }
  },
  {
      "id": 32524,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32531,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Shampoo",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4516,
          "name": "Shampoo",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 255,
              "name": "Shampoo",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/shampoo (1).svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 32539,
      "address_id": 623549,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Dish Towel",
      "min_quantity": 12,
      "current_quantity": 12,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5056,
          "name": "Dish Towel",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 333,
              "name": "Dish Towel",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/Dish Towel.svg",
              "room_categories": [
                  "kitchen"
              ]
          }
      },
      "address": {
          "id": 623549,
          "address1": "The Signature at MGM Grand - Tower B",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89109",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 154,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 2,
          "bath_count": 1,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Harmon 135 #1615",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1067937,
          "longitude": -115.1665441,
          "created_at": "2024-07-24T21:38:51Z",
          "updated_at": "2024-07-24T21:38:51Z"
      }
  },
  {
      "id": 35591,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "408 Twin Duvet Inserts",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10109,
          "name": "408 Twin Duvet Inserts",
          "model": "Twin Duvet Inserts",
          "kind": "specific",
          "category": {
              "id": 408,
              "name": "Sheets",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/folded.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 35629,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "428 ",
      "min_quantity": 2,
      "current_quantity": 3,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10094,
          "name": "428 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35639,
      "address_id": 623569,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "517 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10103,
          "name": "517 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 517,
              "name": "Toaster",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/toaster (1).svg",
              "room_categories": [
                  "kitchen",
                  "office",
                  "reception_area",
                  "main_workspace",
                  "dining-room"
              ]
          }
      },
      "address": {
          "id": 623569,
          "address1": "Aztec Way",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89169",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 3,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[mtr] Aztec 1444",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1280555,
          "longitude": -115.1331032,
          "created_at": "2024-07-24T21:39:04Z",
          "updated_at": "2024-10-08T20:44:09Z"
      }
  },
  {
      "id": 35775,
      "address_id": 623548,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Wine Glass Champagne Glass",
      "min_quantity": 10,
      "current_quantity": 11,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10005,
          "name": "Wine Glass Champagne Glass",
          "model": "Champagne Glass",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623548,
          "address1": "2031 Babylon Mill Street",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89002",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 6,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Babylon Mill 2031",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.006287,
          "longitude": -114.936317,
          "created_at": "2024-07-24T21:38:50Z",
          "updated_at": "2024-07-24T21:38:50Z"
      }
  },
  {
      "id": 35795,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "414 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10119,
          "name": "414 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 414,
              "name": "Ironing Board",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/ironing.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35797,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "421 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10121,
          "name": "421 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 35822,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "421 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10121,
          "name": "421 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 421,
              "name": "Broom",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/broom.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35824,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 ",
      "min_quantity": 16,
      "current_quantity": 17,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10089,
          "name": "599 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35825,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 Champagne Glasses",
      "min_quantity": 16,
      "current_quantity": 17,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10090,
          "name": "599 Champagne Glasses",
          "model": "Champagne Glasses",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35828,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "249 ",
      "min_quantity": 16,
      "current_quantity": 17,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 8808,
          "name": "249 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 249,
              "name": "Bowl",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/bowl.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35829,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "416 Drinking Glass",
      "min_quantity": 16,
      "current_quantity": 17,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10196,
          "name": "416 Drinking Glass",
          "model": "Drinking Glass",
          "kind": "specific",
          "category": {
              "id": 416,
              "name": "Glass / Cup",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/glass-of-water.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35834,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "424 ",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10098,
          "name": "424 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 424,
              "name": "Spatula",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/spatula.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35836,
      "address_id": 623563,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "250 Butter Knives",
      "min_quantity": 16,
      "current_quantity": 17,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10198,
          "name": "250 Butter Knives",
          "model": "Butter Knives",
          "kind": "specific",
          "category": {
              "id": 250,
              "name": "Knife",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/knife.svg",
              "room_categories": []
          }
      },
      "address": {
          "id": 623563,
          "address1": "Gabriel Drive",
          "address2": null,
          "city": "Las Vegas",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89119",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 10,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Gabriel 1857",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.1045715,
          "longitude": -115.1255341,
          "created_at": "2024-07-24T21:39:00Z",
          "updated_at": "2025-03-06T20:02:55Z"
      }
  },
  {
      "id": 35842,
      "address_id": 623529,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "778 ",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10199,
          "name": "778 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 778,
              "name": "Crib",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/cribicon.svg",
              "room_categories": [
                  "backyard",
                  "back_yard",
                  "bathroom",
                  "bedroom",
                  "dining_room",
                  "dining-room",
                  "front_yard",
                  "front-yard",
                  "garage"
              ]
          }
      },
      "address": {
          "id": 623529,
          "address1": "2432 Greens Avenue",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Greens 2432",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.06381226,
          "longitude": -115.07437897,
          "created_at": "2024-07-24T21:38:37Z",
          "updated_at": "2024-07-24T21:38:37Z"
      }
  },
  {
      "id": 35849,
      "address_id": 623529,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "599 ",
      "min_quantity": 14,
      "current_quantity": 15,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10089,
          "name": "599 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 599,
              "name": "Wine Glass",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/wineglass.svg",
              "room_categories": [
                  "front-yard",
                  "bathroom",
                  "laundry",
                  "front_yard",
                  "garage",
                  "bedroom",
                  "backyard",
                  "back_yard",
                  "living_room",
                  "hallway",
                  "stairwell",
                  "kitchen",
                  "reception_area",
                  "patio",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623529,
          "address1": "2432 Greens Avenue",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Greens 2432",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.06381226,
          "longitude": -115.07437897,
          "created_at": "2024-07-24T21:38:37Z",
          "updated_at": "2024-07-24T21:38:37Z"
      }
  },
  {
      "id": 35855,
      "address_id": 623529,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "428 ",
      "min_quantity": 4,
      "current_quantity": 5,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 10094,
          "name": "428 ",
          "model": "",
          "kind": "specific",
          "category": {
              "id": 428,
              "name": "Pot",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/pot.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623529,
          "address1": "2432 Greens Avenue",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 9,
          "bath_count": 4,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Greens 2432",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.06381226,
          "longitude": -115.07437897,
          "created_at": "2024-07-24T21:38:37Z",
          "updated_at": "2024-07-24T21:38:37Z"
      }
  },
  {
      "id": 36601,
      "address_id": 623559,
      "state": "active",
      "installation_date": "2025-01-01",
      "description": null,
      "name": "Hair Dryer",
      "min_quantity": 1,
      "current_quantity": 1,
      "low_quantity_alert": true,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5022,
          "name": "Hair Dryer",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 407,
              "name": "Hair Dryer",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hair-dryer.svg",
              "room_categories": [
                  "kitchen",
                  "hallway",
                  "living_room",
                  "bedroom",
                  "garage",
                  "laundry",
                  "dining_room",
                  "bathroom",
                  "main_workspace",
                  "dining-room",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623559,
          "address1": "1822 Nuevo Road",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 7,
          "bath_count": 3,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Nuevo 1822",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.050533,
          "longitude": -115.067474,
          "created_at": "2024-07-24T21:38:57Z",
          "updated_at": "2024-07-24T21:38:58Z"
      }
  },
  {
      "id": 32385,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Hand Soap",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 5059,
          "name": "Hand Soap",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 372,
              "name": "Hand Soap",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/hand-soap.svg",
              "room_categories": [
                  "dining-room",
                  "bathroom",
                  "dining_room",
                  "laundry",
                  "garage",
                  "living_room",
                  "kitchen",
                  "reception_area",
                  "office"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
  {
      "id": 32394,
      "address_id": 623556,
      "state": "active",
      "installation_date": "2024-01-01",
      "description": null,
      "name": "Conditioner",
      "min_quantity": 1,
      "current_quantity": 2,
      "low_quantity_alert": false,
      "item_cost": null,
      "max_quantity": null,
      "opportunistic_quantity": null,
      "min_restocking_price": null,
      "max_restocking_price": null,
      "where_to_order": null,
      "delivery_timing_preference": null,
      "restocking_notes": null,
      "photo_url": null,
      "model": {
          "id": 4515,
          "name": "Conditioner",
          "model": null,
          "kind": "generic",
          "category": {
              "id": 247,
              "name": "Conditioner",
              "default_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/hair-conditioner.svg",
              "room_categories": [
                  "bathroom"
              ]
          }
      },
      "address": {
          "id": 623556,
          "address1": "3149 Belvedere Drive",
          "address2": null,
          "city": "Henderson",
          "add_state": "NV",
          "country_code": "US",
          "zip": "89014",
          "state": "active",
          "home_access": "",
          "home_close": null,
          "region_id": 153,
          "general_instructions": null,
          "hk_notes": null,
          "bed_count": 4,
          "bath_count": 2,
          "floors_amount": 1,
          "paid_parking": null,
          "parking_spot": null,
          "parking_pay_with": null,
          "max_parking_cost": null,
          "parking_notes": null,
          "office_size": null,
          "office_type": null,
          "address_name": "[str] Belvedere 3149",
          "average_nightly_rate": null,
          "average_stay": null,
          "average_bookings": null,
          "latitude": 36.063164,
          "longitude": -115.089806,
          "created_at": "2024-07-24T21:38:55Z",
          "updated_at": "2024-07-24T21:38:56Z"
      }
  },
]
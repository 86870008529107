export const toDoAutocomplete = [
  {
    value: 'Mop floor',
    display: 'Mop Floor'
  },
  {
    value: 'Vacuum floor',
    display: 'Vacuum floor'
  },
  {
    value: 'Wipe down mirrors',
    display: 'Wipe down mirrors'
  },
  {
    value: 'Dust and wipe furniture',
    display: 'Dust and wipe furniture'
  },
  {
    value: 'Clean inside windows and sills',
    display: 'Clean inside windows and sills'
  },
  {
    value: 'Wipe down baseboards',
    display: 'Wipe down baseboards'
  },
  {
    value: 'Make bed',
    display: 'Make bed'
  },
  {
    value: 'Wipe down cabinets',
    display: 'Wipe down cabinets'
  },
  {
    value: 'Clean toilet',
    display: 'Clean toilet'
  },
  {
    value: 'Wipe down sink and counters',
    display: 'Wipe down sink and counters'
  },
  {
    value: 'Clean shower and tub',
    display: 'Clean shower and tub'
  },
  {
    value: 'Clean scuffs on doors and walls',
    display: 'Clean scuffs on doors and walls'
  },
  {
    value: 'Dust ceiling fans',
    display: 'Dust ceiling fans'
  },
  {
    value: 'Take out trash and wipe can',
    display: 'Take out trash and wipe can'
  },
  {
    value: 'Dust blinds',
    display: 'Dust blinds'
  },
  {
    value: 'Wipe down surfaces',
    display: 'Wipe down surfaces'
  },
  {
    value: 'Wash dishes',
    display: 'Wash dishes'
  },
  {
    value: 'Wipe down sink',
    display: 'Wipe down sink'
  },
  {
    value: 'Wipe down table and chairs',
    display: 'Wipe down table and chairs'
  },
  {
    value: 'Wipe down appliances',
    display: 'Wipe down appliances'
  },
  {
    value: 'Straighten all clothing in closet',
    display: 'Straighten all clothing in closet'
  },
  {
    value: 'Clean door into house',
    display: 'Clean door into house'
  },
  {
    value: 'Organize items',
    display: 'Organize items'
  },
  {
    value: 'Organize toys and items',
    display: 'Organize toys and items'
  },
  {
    value: 'Vacuum inside closets',
    display: 'Vacuum inside closets'
  },
  {
    value: 'Wipe down all doors',
    display: 'Wipe down all doors'
  },
  {
    value: 'Vacuum sofas and cushions',
    display: 'Vacuum sofas and cushions'
  },
  {
    value: 'Vacuum inside closets',
    display: 'Vacuum inside closets'
  },
  {
    value: 'Wipe down baseboards',
    display: 'Wipe down baseboards'
  },
  {
    value: 'Dust stair rail',
    display: 'Dust stair rail'
  },
  {
    value: 'Clean refrigerator',
    display: 'Clean refrigerator'
  },
  {
    value: 'Clean oven',
    display: 'Clean oven'
  },
  {
    value: 'Clean stove',
    display: 'Clean stove'
  },
  {
    value: 'Clean microwave',
    display: 'Clean microwave'
  },
  {
    value: 'Vacuum sofas and cushions',
    display: 'Vacuum sofas and cushions'
  },
  {
    value: 'Sweep floor',
    display: 'Sweep floor'
  },
  {
    value: 'Oven clean',
    display: 'Oven clean'
  },
  {
    value: 'Pick up clutter',
    display: 'Pick up clutter'
  },
  {
    value: 'Dust',
    display: 'Dust'
  },
  {
    value: 'Clean shower',
    display: 'Clean shower'
  },
  {
    value: 'Clean toaster',
    display: 'Clean toaster'
  },
  {
    value: 'Clean coffeemaker',
    display: 'Clean coffeemaker'
  },
  {
    value: 'Clean windows',
    display: 'Clean windows'
  },
  {
    value: 'Clean baseboards',
    display: 'Clean baseboards'
  },
  {
    value: 'Dust furniture',
    display: 'Dust furniture'
  },
  {
    value: 'Sweep',
    display: 'Sweep'
  },
  {
    value: 'Vacuum rug',
    display: 'Vacuum rug'
  },
  {
    value: 'Take out trash',
    display: 'Take out trash'
  },
  {
    value: 'Dust ceiling fan',
    display: 'Dust ceiling fan'
  },
  {
    value: 'Wipe baseboards',
    display: 'Wipe baseboards'
  },
  {
    value: 'Clean sink',
    display: 'Clean sink'
  },
  {
    value: 'Empty trash',
    display: 'Empty trash'
  },
  {
    value: 'Wipe down mirror',
    display: 'Wipe down mirror'
  },
  {
    value: 'Dust surfaces',
    display: 'Dust surfaces'
  },
  {
    value: 'Wipe down walls',
    display: 'Wipe down walls'
  },
  {
    value: 'Clean blinds',
    display: 'Clean blinds'
  },
  {
    value: 'Vacuum',
    display: 'Vacuum'
  },
  {
    value: 'Clean mirrors',
    display: 'Clean mirrors'
  },
  {
    value: 'Sweep and mop floor',
    display: 'Sweep and mop floor'
  },
  {
    value: 'Mop',
    display: 'Mop'
  },
  {
    value: 'Change sheets',
    display: 'Change sheets'
  },
  {
    value: 'Clean floor',
    display: 'Clean floor'
  },
  {
    value: 'Clean mirror',
    display: 'Clean mirror'
  },
  {
    value: 'Clean shower',
    display: 'Clean shower'
  },
  {
    value: 'Vacuum and mop floor',
    display: 'Vacuum and mop floor'
  },
  {
    value: 'Dust baseboards',
    display: 'Dust baseboards'
  },
  {
    value: 'Vacuum stairs',
    display: 'Vacuum stairs'
  },
  {
    value: 'Dust shutters',
    display: 'Dust shutters'
  },
  {
    value: 'Wipe down blinds',
    display: 'Wipe down blinds'
  },
  {
    value: 'Vacuum rugs',
    display: 'Vacuum rugs'
  },
  {
    value: 'Clean ceiling fan',
    display: 'Clean ceiling fan'
  },
  {
    value: 'Dusting',
    display: 'Dusting'
  },
  {
    value: 'Vacuum carpet',
    display: 'Vacuum carpet'
  },
  {
    value: 'Clean tub',
    display: 'Clean tub'
  },
  {
    value: 'Sweep patio',
    display: 'Sweep patio'
  },
  {
    value: 'Clean window sills',
    display: 'Clean window sills'
  },
  {
    value: 'Wipe down table',
    display: 'Wipe down table'
  },
  {
    value: 'Sweep and mop',
    display: 'Sweep and mop'
  },
  {
    value: 'Clean stove top',
    display: 'Clean stove top'
  },
  {
    value: 'Take out trash ',
    display: 'Take out trash '
  },
  {
    value: 'Do laundry',
    display: 'Do laundry'
  },
  {
    value: 'Wipe down windows',
    display: 'Wipe down windows'
  },
  {
    value: 'Clean sink and counters',
    display: 'Clean sink and counters'
  },
  {
    value: 'Clean baseboards ',
    display: 'Clean baseboards '
  },
  {
    value: 'Dust',
    display: 'Dust'
  },
  {
    value: 'Clean windows',
    display: 'Clean windows'
  },
  {
    value: 'Dust light fixtures',
    display: 'Dust light fixtures'
  },
  {
    value: 'Wipe window sills',
    display: 'Wipe window sills'
  },
  {
    value: 'Wipe down furniture',
    display: 'Wipe down furniture'
  },
  {
    value: 'Vacuum couch',
    display: 'Vacuum couch'
  },
  {
    value: 'Clean walls',
    display: 'Clean walls'
  },
  {
    value: 'Baseboards',
    display: 'Baseboards'
  },
  {
    value: 'Wipe down counters',
    display: 'Wipe down counters'
  },
  {
    value: 'Wipe down doors',
    display: 'Wipe down doors'
  },
  {
    value: 'Sweep floor',
    display: 'Sweep floor'
  },
  {
    value: 'Dust fan',
    display: 'Dust fan'
  },
  {
    value: 'Dust window sills',
    display: 'Dust window sills'
  },
  {
    value: 'Clean windows and sills',
    display: 'Clean windows and sills'
  },
  {
    value: 'Make beds',
    display: 'Make beds'
  },
  {
    value: 'Wipe blinds',
    display: 'wipe blinds'
  },
  {
    value: 'Wipe surfaces',
    display: 'Wipe surfaces'
  },
  {
    value: 'Wash windows',
    display: 'Wash windows'
  },
  {
    value: 'Clean bathtub',
    display: 'Clean bathtub'
  },
  {
    value: 'Clean stovetop',
    display: 'Clean stovetop'
  },
  {
    value: 'Clean toilet',
    display: 'Clean toilet'
  },
  {
    value: 'Dust shelves',
    display: 'Dust shelves'
  },
  {
    value: 'Clean baseboards',
    display: 'Clean baseboards'
  },
  {
    value: 'Dust furniture',
    display: 'Dust furniture'
  },
  {
    value: 'Wipe baseboards',
    display: 'wipe baseboards'
  },
  {
    value: 'Dust picture frames',
    display: 'Dust picture frames'
  },
  {
    value: 'Clean sliding glass door',
    display: 'Clean sliding glass door'
  },
  {
    value: 'Clean surfaces',
    display: 'Clean surfaces'
  },
  {
    value: 'Wipe down table',
    display: 'Wipe down table'
  },
  {
    value: 'Wipe can',
    display: 'Wipe can'
  },
  {
    value: 'Dust chandelier',
    display: 'Dust chandelier'
  },
  {
    value: 'Swiffer floor with my Swiffer',
    display: 'Swiffer floor with my Swiffer'
  },
  {
    value: 'Wipe down stove',
    display: 'Wipe down stove'
  },
  {
    value: 'Vacuum area rug',
    display: 'Vacuum area rug'
  },
  {
    value: 'Water plants',
    display: 'Water plants'
  },
  {
    value: 'Wipe down ceiling fan',
    display: 'Wipe down ceiling fan'
  },
  {
    value: 'Fold laundry',
    display: 'Fold laundry'
  },
  {
    value: 'Wipe walls',
    display: 'Wipe walls'
  },
  {
    value: 'Wipe down sink and counter',
    display: 'Wipe down sink and counter'
  },
  {
    value: 'Clean shutters',
    display: 'Clean shutters'
  },
  {
    value: 'Wipe down door',
    display: 'Wipe down door'
  },
  {
    value: 'Clean window blinds',
    display: 'Clean window blinds'
  },
  {
    value: 'Dust baseboards',
    display: 'Dust baseboards'
  },
  {
    value: 'Dust and wipe down furniture',
    display: 'Dust and wipe down furniture'
  },
  {
    value: 'Clean ceiling fans',
    display: 'Clean ceiling fans'
  },
  {
    value: 'Sweep',
    display: 'Sweep'
  },
  {
    value: 'Clean inside window and sill',
    display: 'Clean inside window and sill'
  },
  {
    value: 'Change sheets and make bed',
    display: 'Change sheets and make bed'
  },
  {
    value: 'Wipe mirrors',
    display: 'Wipe mirrors'
  },
  {
    value: 'Empty trash cans',
    display: 'Empty trash cans'
  },
  {
    value: 'Wash sheets',
    display: 'Wash sheets'
  },
  {
    value: 'Clean scuffs on walls',
    display: 'Clean scuffs on walls'
  },
  {
    value: 'Wipe down surfaces',
    display: 'Wipe down surfaces'
  },
  {
    value: 'Dust surfaces',
    display: 'Dust surfaces'
  },
  {
    value: 'Clean fridge',
    display: 'Clean fridge'
  },
  {
    value: 'Dust everything',
    display: 'Dust everything'
  },
  {
    value: 'Wipe down trash can',
    display: 'Wipe down trash can'
  },
  {
    value: 'Clean light switches',
    display: 'Clean light switches'
  },
  {
    value: 'Dust blinds ',
    display: 'Dust blinds '
  },
  {
    value: 'General cleaning',
    display: 'General cleaning'
  },
  {
    value: 'Clean microwave',
    display: 'Clean microwave'
  },
  {
    value: 'Wipe baseboards',
    display: 'Wipe baseboards'
  },
  {
    value: 'Make bed',
    display: 'Make bed'
  },
  {
    value: 'Wipe window sill',
    display: 'Wipe window sill'
  },
  {
    value: 'Clean inside window sills',
    display: 'Clean inside window sills'
  },
  {
    value: 'Clean counters',
    display: 'Clean counters'
  },
  {
    value: 'Wipe down baseboards',
    display: 'Wipe down baseboards'
  },
  {
    value: 'Dust ceiling corners',
    display: 'Dust ceiling corners'
  },
  {
    value: 'Clean light fixtures',
    display: 'Clean light fixtures'
  },
  {
    value: 'Wipe down sink',
    display: 'Wipe down sink'
  },
  {
    value: 'Fold clothes',
    display: 'Fold clothes'
  },
  {
    value: 'Wipe down shutters',
    display: 'Wipe down shutters'
  },
  {
    value: 'Dust pictures',
    display: 'Dust pictures'
  },
  {
    value: 'Vacuum closets',
    display: 'Vacuum closets'
  },
  {
    value: 'Dust window blinds',
    display: 'Dust window blinds'
  },
  {
    value: 'Clean fan',
    display: 'Clean fan'
  },
  {
    value: 'Clean inside cabinets',
    display: 'Clean inside cabinets'
  },
  {
    value: 'Change sheets',
    display: 'Change sheets'
  },
  {
    value: 'Clean toilets',
    display: 'Clean toilets'
  },
  {
    value: 'Scrub sink',
    display: 'Scrub sink'
  },
  {
    value: 'Dust fans',
    display: 'Dust fans'
  },
  {
    value: 'Wipe down window sill',
    display: 'Wipe down window sill'
  },
  {
    value: 'Clean windowsills',
    display: 'Clean windowsills'
  },
  {
    value: 'Clean inside windows',
    display: 'Clean inside windows'
  },
  {
    value: 'Change bed sheets',
    display: 'Change bed sheets'
  },
  {
    value: 'Dust vents',
    display: 'Dust vents'
  },
  {
    value: 'Wipe windows',
    display: 'Wipe windows'
  },
  {
    value: 'Wipe down light fixtures',
    display: 'Wipe down light fixtures'
  },
  {
    value: 'Wash walls',
    display: 'Wash walls'
  },
  {
    value: 'Remove cobwebs',
    display: 'Remove cobwebs'
  },
  {
    value: 'Clean cabinets',
    display: 'Clean cabinets'
  },
  {
    value: 'Dust window sill',
    display: 'Dust window sill'
  },
  {
    value: 'Wipe down light switches',
    display: 'Wipe down light switches'
  },
  {
    value: 'Wipe down desk',
    display: 'wipe down desk'
  },
  {
    value: 'Dust bookshelves',
    display: 'Dust bookshelves'
  },
  {
    value: 'Clean windows and window sills',
    display: 'Clean windows and window sills'
  },
  {
    value: 'Dust ceiling light',
    display: 'Dust ceiling light'
  },
  {
    value: 'Wipe down walls and baseboards',
    display: 'Wipe down walls and baseboards'
  },
  {
    value: 'Wipe cabinets',
    display: 'Wipe cabinets'
  },
  {
    value: 'Wipe down sinks and counters',
    display: 'Wipe down sinks and counters'
  },
  {
    value: 'Clean sliding glass doors',
    display: 'Clean sliding glass doors'
  },
  {
    value: 'Dust baseboards',
    display: 'Dust baseboards'
  },
  {
    value: 'Scrub baseboards',
    display: 'Scrub baseboards'
  },
  {
    value: 'Wipe down washer and dryer',
    display: 'Wipe down washer and dryer'
  },
  {
    value: 'Clean sink and counter',
    display: 'Clean sink and counter'
  },
  {
    value: 'Wipe down shelves',
    display: 'Wipe down shelves'
  }
]

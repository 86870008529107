import { Injectable } from '@angular/core';
import moment from 'moment';

import { HttpClientCustom } from '../custom/http-client';

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class Requests {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  addJobRequest(addressId, data) {
    const url = `api/v1/customer/bookings`;
    return this.httpClient.post(url, data);
  }

  addProRequest(addressId, data) {
    const url = `/api/v2/customer/addresses/${addressId}/availability-requests`;
    return this.httpClient.post(url, data);
  }

  deleteProRequest(addressId, availabilityRequestId) {
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}`;
    return this.httpClient.delete(url, {availability_request_id: availabilityRequestId});
  }

  acceptProCounterRequest(availabilityRequestId, addressId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}/accept`;
    return this.httpClient.put(url, payload);
  }

  rejectProCounterRequest(availabilityRequestId, addressId) {
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}/reject`;
    return this.httpClient.put(url, {});
  }

  addRequestSubstitute(data, cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/request-substitutes`;
    return this.httpClient.post(url, data);
  }

  getProRequestBody(card) {
    const proName = card.template_data.homekeeper.homekeeper_name;
    const frequencies = {
      'once': 'one time',
      'every_four_weeks': 'every four weeks',
      'every_other_week': 'every other week',
      'every_week': 'every week',
    }
    const frequency = frequencies[card.template_data.availability_request.frequency];
    const serviceFrequency = frequency == 'one time' ?
      card.template_data.availability_request.service_type_details.name :
      `${card.template_data.availability_request.service_type_details.name} ${frequency}`;
    const language = localStorage.getItem('language');
    if (language && language !== 'en') {
      return new TranslationPipe().transform('You requested') + ' ' + proName + ' ' + new TranslationPipe().transform('for a') + ' ' + serviceFrequency + ' ' + new TranslationPipe().transform('during one of these times:');
    } else {
      return `You requested ${proName} for a ${serviceFrequency} during one of these times:`
    }
  }

  parseRanges(blocks) {
    const strArray = blocks.split('|');

    const ranges = strArray.reduce((acc, curr) => {
      const next = moment(curr, 'HH:mm').add(30, 'm').format('HH:mm');
      return [...acc, curr, next];
    }, []);

    const rmDuplicate = ranges.filter((elem, index) => {
      return elem !== ranges[index + 1]
          && elem !== ranges[index - 1];
    });

    return rmDuplicate.map((elem, index) => {
      if (index % 2 === 0) {
        return {start: elem, end: rmDuplicate[index + 1]};
      }
    }).filter(elem => elem);
  }

  buildRequestTimeRangeList(requestDates) {
    return requestDates.map(requestDate => {
      const ranges = requestDate.times.reduce((acc, curr) => {
        const startTime = this.formatDate(curr.start_time, 'h:mma', 'HH:mm');
        const endTime = this.formatDate(curr.end_time, 'h:mma', 'HH:mm');
        return `${acc}${acc ? ', ' : ''}${startTime}-${endTime}`;
      }, '');
      return `<b>${this.formatDate(requestDate.date, 'ddd M/D')}</b>: ${ranges}`;
    });
  }

  buildRequestTimeList(response) {
    const data = response.map((day) => {
      let item = '<b>';
      item += this.formatDate(day.date, 'ddd M/D') + ': ';
      item += '</b>';
      day.times.map((time, i) => {
        item += this.formatDate(time, 'h:mma', 'HH:mm');
        if (i < (day.times.length -1)) {
          item += ', '
        }
      });
      return item;
    });
    return data;
  }

  formatDate(dateTime, format, parseMask = null) {
    return new CustomDatePipe().transform(dateTime, format, '', parseMask);
  }
}

import { Component, OnInit } from "@angular/core";

import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { Menu } from 'src/providers/menu/menu';
import { Workflows } from "src/providers/workflows/workflows";
import { WindowService } from 'src/shared/providers/window.service';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';

import { WorkflowModel } from "src/models/workflow.model";

import { SuccessPageParamsModel } from 'src/shared/pages/success/success';
import { WorkflowPage } from 'src/pages/workflows/workflow/workflow';
import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { AddWorkflowPage } from 'src/pages/workflows/add-workflow/add-workflow';

@Component({
  templateUrl: 'workflows.html',
  styleUrls: ['workflows.scss']
})
export class WorkflowsPage  implements OnInit {

  isPausingUnpausingWorkflow: boolean;
  isRightSideContent: boolean;
  errorMessage: string;
  loaded: boolean;
  paramForms: any = {};
  contentHeight;
  workflows: Array<WorkflowModel>;
  workflowTemplates: any;
  filteredWorkflows: Array<WorkflowModel>;
  isSavingChange: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private workflowsProvider: Workflows,
    public windowService: WindowService,
    private menu: Menu,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
    ) {}

  async ngOnInit() {
    try {
      this.loaded = false;
      this.rightSidePanelService.setDialogPageTitle('Workflows');
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.menu.selectedAutomation = 'workflows';
      await this.loadWorkflows();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async loadWorkflows() {
    try {
      this.workflows = await this.workflowsProvider.getWorkflows();
      this.workflowTemplates = await this.workflowsProvider.getWorkflowTemplates();
      this.filteredWorkflows = this.workflows;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToWorkFlow(workflow: WorkflowModel) {
    setTimeout(async () => {
      if (this.isPausingUnpausingWorkflow) {
        return this.isPausingUnpausingWorkflow = false;
      }
      const workflowWithVersions = await this.workflowsProvider.getWorkflowWithVersions(workflow.id);
      const params = {
        workflow: workflowWithVersions
      }
      this.storage.save('workflowId', workflow.id);
      if (workflowWithVersions.published_version) {
        this.goToPage(`/automations/workflow/${workflow.id}`, params, WorkflowPage);
      } else {
        this.goToPage(`/automations/workflow/${workflow.id}/edit`, params, EditWorkflowPage);
      }
    }, 200);
  }

  addWorkflow() {
    localStorage.removeItem('newWorkflowId');
    this.storage.save('workflowId', 'new');
    this.goToPage('automations/workflow/new/edit', {}, EditWorkflowPage);
  }

  async pauseUnpauseWorkflow(selection) {
    const workflow = await this.workflowsProvider.getWorkflowWithVersions(selection.id);
    this.isPausingUnpausingWorkflow = true;
    this.isSavingChange = true;
    try {
      if (!workflow.published_version) {
        this.isSavingChange = false;
        const params = this.getErrorCantUnpausePage();
        return this.rightSidePanelService.navigateTo('success', params);
      }
      if (workflow.paused_at) {
        await this.workflowsProvider.unpauseWorkflow(workflow.id);
        workflow.paused_at = null;
      } else {
        await this.workflowsProvider.pauseWorkflow(workflow.id);
        workflow.paused_at = new Date();
      }
      setTimeout(() => {
        this.isSavingChange = false;
      }, 1000);
      setTimeout(() => {
        this.isPausingUnpausingWorkflow = false;
      }, 200);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.loadWorkflows();
    }
  }

  getErrorCantUnpausePage(): SuccessPageParamsModel {
    return {
      header: 'Please Publish Workflow to Unpause It',
      body: 'In order to unpause a workflow you will need to publish it first. To do this, click the workflow then click \"Publish\" at the bottom of the page.',
      buttonText: 'Ok',
      buttonRoute: 'automations/summary'
    };
  }

  async addTemplate(templateId) {
    try {
      const payload = {
        workflow_template_id: templateId
      }
      await this.workflowsProvider.addWorkflowTemplate(payload);
      const workflows = await this.workflowsProvider.getWorkflows();
      const addedWorkflow = workflows.find((workflow) => workflow.from_workflow_template_id == templateId);
      this.goToWorkFlow(addedWorkflow);
      this.util.showSuccess('Template Added!');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToPage(url, params, component) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }

}

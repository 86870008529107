import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Loading } from 'src/shared/components/loading/loading';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Util } from 'src/shared/util/util';
import { Menu } from 'src/providers/menu/menu';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddWebhookPage } from './add-webhook/add-webhook';
import { WebhookDetailsPage } from './webhook-details/webhook-details';

@Component({
  templateUrl: 'webhooks.html',
  encapsulation: ViewEncapsulation.None
})

export class WebhooksPage extends TimeoutableComponent implements OnInit {

   webhookResponse: any[];
   isAccountAdmin: boolean;
   isRightSideContent = true;
   dialogParams: any;

  constructor (
    private webhook: Webhooks,
    private navCtrl: CustomNavController,
    private util: Util,
    public menu: Menu,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Webhooks');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.webhookResponse = await this.webhook.getWebhooks();
      const userRole = localStorage.getItem('user_role');
      this.isAccountAdmin = userRole !== 'member';
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  goToWebhookDetails(webhook: any) {
    localStorage.setItem('webhookDescription', webhook.description);
    localStorage.setItem('webhookId', webhook.id);
    localStorage.setItem('webhookState', webhook.state);
    localStorage.setItem('webhookUrl', webhook.webhook_url);
    localStorage.setItem('webhookChannel', webhook.channel);
    localStorage.setItem('webhookTeamId', webhook?.customer_account_team?.id);
    localStorage.setItem('webhookTeamName', webhook?.customer_account_team?.name);
    localStorage.setItem('webhookEvents', this.buildWebhookEventsList(webhook.events));
    localStorage.setItem('webhookDetailsBackPage', 'webhooks');
    this.rightSidePanelService.navigateTo('webhook-details', {}, WebhookDetailsPage);
  }

  goToAddWebhook() {
    this.rightSidePanelService.navigateTo('add-webhook', {}, AddWebhookPage);
  }

  goToLearnMore() {
    const url = 'https://help.tidy.com/webhooks';
    this.util.openUrl(url);
  }

  buildWebhookEventsList(events) {
    let list = '';
    events.map((event, i) => {
      list += event.display_name;
      if (i < events.length - 1) {
        list += ', ';
      }
    })
    return list;
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { roomTypeSelect } from 'src/shared/constants/roomTypeSelect';
import { floorSelect } from 'src/shared/constants/floorSelect';

import { RoomModel } from 'src/models/to-do.model';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { Logger } from 'src/providers/logger';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';

@Component({
  templateUrl: 'edit-room-info.html',
  providers: [PhotoCaptionService]
})

export class ToDosEditRoomInfoPage implements OnInit {

  form: UntypedFormGroup;
  floorSelectItems = floorSelect;
  isRightSideContent = true;
  loaded: boolean;
  room: RoomModel;
  roomTypeSelectItems = roomTypeSelect;
  submitted: boolean;
  photoNoteUrls: PhotoNoteUrlModel[];
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos,
    private logger: Logger,
    private photoCaptionService: PhotoCaptionService,
    private photoNotes: PhotoNotes
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Card Info');
    this.room = this.navCtrl.getParam('room') || dialogParams.room;
    this.photoNoteUrls = this.photoCaptionService.mountPhotoUrls(this.room.photos, this.room.type);
    this.createForm();
    this.loaded = true;
  }

  createForm() {
    const form = this.room.type !== 'group' ? {
      name: [this.room.name, Validators.required],
      floor: [this.room.floor, Validators.required],
      category: [this.room.category, Validators.required]
    } : {
      name: [this.room.name, Validators.required],
    }
    this.room.notes.map((doNot, i) => {
      form['doNot' + i] = [doNot.body, Validators.required];
    });
    this.form = this.fb.group(form);
  }

  deleteDoNot(doNot, i) {
    this.storage.delete('addresses');
    this.room.notes[i].state = 'inactive';
  }

  async updateRoom() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    await this.uploadPhotos();
    this.storage.delete('addresses');
    this.room.notes.map((doNot, i) => {
      if (doNot.state === 'inactive') {
        this.toDos.deleteDoNot(doNot.id);
      } else {
        this.toDos.updateDoNot(this.form.value['doNot' + i], this.room.id, doNot.id);
      }
    });
    const data = this.room?.type !== 'group' ? {
      address_room : {
        name: this.form.value.name,
        floor: this.form.value.floor,
        category: this.form.value.category
      }
    } : {
      name: this.form.value.name
    }
    if (this.room.type == 'room') {
      await this.toDos.updateRoom(data)
    } else {
      await this.toDos.updateGroupName(this.room.id, data)
    }
    let dialogParams = await this.storage.retrieve('dialog-params');
    if (dialogParams?.room) dialogParams.room = null;
    await this.storage.save('dialog-params', dialogParams);
    this.rightSidePanelService.navigateTo('card');
  }

  async uploadPhotos() {
    try {
      await this.photoCaptionService.uploadPhotosGeneric(
        this.photoNoteUrls,
        this.room.type,
        this.photoNotes.roomPhotoNote.bind(this.photoNotes, this.room.id),
        this.photoNotes.photoCaption.bind(this.photoNotes),
        'room'
      );
    } catch(err) {
      this.logger.error(err, 'upload image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async confirmDelete (photoId: number) {
    try {
      this.storage.delete('addresses');
      await this.photoCaptionService.confirmDelete(
        photoId,
        this.photoNoteUrls
      );
    } catch (err) {
      this.logger.error(err, 'delete image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
}

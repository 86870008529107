import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';
import { ActivatedRoute } from '@angular/router';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ContactConciergePage } from '../contact-concierge/contact-concierge';
import { JobIssuePage } from '../job-issue/job-issue';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'get-help.html',
  encapsulation: ViewEncapsulation.None
})

export class GetHelpPage implements OnInit {

  loaded: boolean;
  showDeveloper: boolean;
  showIntegration: boolean;
  cantGoBack: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private client: Client,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private util: Util
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Get Help');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.cantGoBack = this.route.snapshot.data.cantGoBack;
    const showIntegration = localStorage.getItem('isRentalClient');
    this.showIntegration = showIntegration === 'true';
    const isDeveloperClient = localStorage.getItem('isDeveloperClient');
    this.showDeveloper = isDeveloperClient === 'true';
    this.loaded = true;
  }

  goToContactConcierge(title: string, type: string) {
    const params = {
      title: title,
      type: type
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

  @Loading('', true)
  async jobIssue() {
    this.loaded = false;
    const pastJobsResponse = await this.client.getJobHistory();
    let hasPastJobs = false;
    pastJobsResponse.map((job) => {
      if (job?.service && job?.start_datetime_local) {
        hasPastJobs = true;
      }
    });
    if (hasPastJobs) {
      if (pastJobsResponse.length > 5) {
        pastJobsResponse.length = 5;
      }
      const params = {
        pastJobsResponse
      }
      this.rightSidePanelService.navigateTo('job-issue', params, JobIssuePage);
    } else {
      return this.goToContactConcierge('Job Issue', 'support.job_issue');
    }
  }

  billingIssue() {
    this.util.showSuccess(`Please tap \“Need help?\” on the billing item you have questions on, and we typically help within 4 business hours.`, 10000);
    this.rightSidePanelService.navigateTo('billing-history', {});
  }

  conciergeTask() {
    this.goToContactConcierge('Concierge Action', 'support.concierge_task');
  }

  howToUseTidy() {
    this.goToContactConcierge('How to Use TIDY', 'support.how_to');
  }

  otherQuestion() {
    this.goToContactConcierge('Other Question', 'support.other');
  }
}

<ng-template #content>
  <div style="padding: 20px !important; padding-top: 35px !important">
    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [title]="'Upgrade to TIDY+ & Save 20%'" class="white">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" style="padding-bottom: 30px">
      <tidy-text
        *ngIf="!isMobileResolution"
        [actionBody]="'$36/yr. Typically pays back in 2 jobs. Cancel anytime.'"
        class="white">
      </tidy-text>
      <tidy-text
        *ngIf="isMobileResolution"
        [actionBody]="'$36/yr. Typically pays back in 2 jobs.'"
        class="white">
      </tidy-text>
      <ng-container *ngIf="isMobileResolution">
        <br>
        <tidy-text
          [actionBody]="'Cancel anytime.'"
          class="white">
        </tidy-text>
      </ng-container>
    </tidy-row>

    <ion-row style="padding-bottom: 10px">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/discount-tag.svg'"
          style="width: 40px; height: 50px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Save 20%'" class="white"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [actionBody]="'20% off on all bookings made with TIDY.'"
            class="white">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>

    <ion-row style="padding-bottom: 10px">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/software.svg'"
          style="width: 40px; height: 50px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Powerful Software '" class="white">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [actionBody]="'Software to manage your rental’s cleaning and maintenance.'"
            class="white">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>

    <ion-row style="padding-bottom: 10px">
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/ai.svg'"
          style="width: 40px; height: 50px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Advanced Automations'" class="white">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [actionBody]="'Use our AI and human concierge to solve tasks for you.'"
            class="white">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>

    <tidy-row [align]="'center'" class="extra-top-padding extra-bottom-padding">
      <tidy-text
        [largeBody]="'Add TIDY+ to Checkout'"
        (click)="addTidyPlus()"
        class="link white"
        style="cursor: pointer">>
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [actionBody]="'View Other Subscription Types'"
        class="link white"
        (click)="goToViewSubscriptions()"
        style="cursor: pointer">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [actionBody]="'No Thanks'"
        class="link white"
        (click)="noThanks()"
        style="cursor: pointer">
      </tidy-text>
    </tidy-row>
  </div>
</ng-template>

<ion-content *ngIf="isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-container *ngIf="!isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-goal-tasks-card',
  templateUrl: './goal-tasks-card.html',
  styleUrls: ['./goal-tasks-card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoalTasksCardComponent implements OnInit {
  
  @Input() goal: any;
  @Input() isUnchosenGoal: boolean;
  @Output() skipGoal = new EventEmitter<any>();
  @Output() goToTaskDetails = new EventEmitter<Task>();
  @Output() addGoal = new EventEmitter<any>();
  @Output() openVideo = new EventEmitter<any>();

  ngOnInit(): void {}

  toggleGoalExpansion(goal) {
    goal.isOpened = !goal.isOpened;
  }

  sortSteps(steps) {
    return [...steps].sort((a, b) => {
      if (a.state == 'done' && b.state == 'done') return 0;
      return a.state == 'done' ? 1 : -1;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Genome } from 'src/providers/genome/genome';
import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { AddressModel } from 'src/models/address.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';


@Component({
  templateUrl: 'maps-introduction.html'
})

export class MapsIntroductionPage implements OnInit {

  address: AddressModel;
  addressName: string;
  cameFromBuildMap: boolean;
  dialogParams: any;
  errorMessage: string;
  floors: any;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  loaded: boolean;
  mapData: any;
  mapUrl: any;
  modalFlow: string;
  mapToShow: any;
  isImageLoading: boolean;
  rejectionAlert: boolean;
  roomOptions: TidySelectNumberValueModel[];
  floorOptions: TidySelectNumberValueModel[];

  constructor(
    private client: Client,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private genome: Genome,
    private geocode: GoogleGeocode,
    private genomeMaps: GenomeMaps,
    private toDos: ToDos,
    private sanitizer: DomSanitizer,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.formBuilder.group({
      beds: [2],
      baths: [2],
      floors: [2],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Scan Your Property');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.modalFlow = 'firstModal';
    this.address = this.navCtrl.getParam('address') || this.dialogParams.address;
    this.addressName = this.navCtrl.getParam('addressName') || this.dialogParams.addressName;
    this.floors = this.navCtrl.getParam('floors') || this.dialogParams.floors;
    this.roomOptions = this.buildOptions(10);
    this.floorOptions = this.buildOptions(5);
    if (!this.floors.length) {
      this.modalFlow = 'secondModal';
    } else {
      this.modalFlow = 'thirdModal';
    }
    this.loaded = true;
  }


  async secondModal() {
    const roomData = {
      address_id: this.address.id,
      address_instructions: {
        beds: this.form.value.beds,
        baths: this.form.value.baths,
        floors_amount: this.form.value.floors
      }
    }
    try {
      await this.client.saveHomeData(roomData);
      const rooms = await this.genome.getRooms(this.address.id);
      const roomWithIcons = this.toDos.buildRoomIcons(rooms);
      const floors = [];
      roomWithIcons.forEach(room => {
        floors[room.floor] = floors[room.floor] ?? [];
        floors[room.floor].push(room);
      });
      this.floors = floors;
      this.modalFlow = 'thirdModal';
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
        this.mapToShow = reader.result;
    }, false);
    if (image) {
        reader.readAsDataURL(image);
    }
  }

  async fourthModal(){
    this.isImageLoading = true;
    this.geocode.getAddressImage(this.address).subscribe(data => {
      this.mapData = data;
      this.mapUrl = URL.createObjectURL(data);
      this.mapToShow = this.sanitizer.bypassSecurityTrustUrl(this.mapUrl);
      this.isImageLoading = false;
    }, err => {
      this.isImageLoading = false;
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    });
    this.modalFlow = 'confirmMap';
  }

  async submitMap(){
    const awsResponse = await this.genomeMaps.uploadMapToS3(this.mapData, this.address.id);
    const params = {
      address: this.address,
      map: this.mapUrl,
      awsData: awsResponse
    };
    this.rightSidePanelService.navigateTo(`map-building`, params);
  }

  rejectMap(){
    this.rejectionAlert = true;
  }

  goToConcierge() {
    this.rightSidePanelService.navigateTo(`get-help`);
  }

  goToEditRooms() {
    const params = {
      cameFromBuildMap: true,
      addressName: this.addressName,
      addressId: this.address.id
    }
    this.rightSidePanelService.navigateTo(`edit-property/${this.address.id}/edit-rooms`, params);
  }

  buildOptions(maxNumber): TidySelectNumberValueModel[] {
   return Array.from({ length: maxNumber },(_, i) => ({ viewValue: `${i + 1}`, value: i + 1 }));
  }
  
}

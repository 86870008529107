import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Concierge } from 'src/providers/concierge/concierge';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'damage-claim.html',
  encapsulation: ViewEncapsulation.None
})

export class DamageClaimPage implements OnInit {

  attachment: any;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private concierge: Concierge,
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private util: Util
  ) {
    this.form = this.fb.group({
      detail: ['', Validators.required],
      how: ['', Validators.required],
      link: ['', Validators.required],
      seeking: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Damage Claim');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  addAttachment() {
    //TODO this.attachment =
  }

  async submit() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const job = await this.getParam('job');
    const isPrivate = await this.getParam('privateJob');
    const data = {
    	type: 'support.job_issue',
    	data: {
    		subject: 'Damage Claim',
    		body: 'What did your Pro damage?<br>\
        ' + this.form.value.detail + '<br><br>\
        How did your Pro damage it?<br>\
        ' + this.form.value.how + '<br><br>\
        Link to replacement item or estimate:<br>\
        ' + this.form.value.link + '<br><br>\
        How much are you seeking from the pro?<br>\
        ' + this.form.value.seeking,
        emails: [],
        attachments: []
    	},
      metadata: {
        detail: {},
        job_id: job.job.id,
        job_datetime: job.job.start_datetime,
        privateJob: isPrivate
      }
    };
    try {
      await this.concierge.addConciergeItem(data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    this.util.showSuccess('Damage Claim Submitted');
    this.rightSidePanelService.navigateTo('concierge');
  }
  
}

import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-three-column-row',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./three-column-row.component.scss'],
  template: `
    <ng-content></ng-content>
  `
})

export  class ThreeColumnRowComponent {

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true"
    [title]="loaded ? clientHubInfo?.client_hub_details.team.name : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="hasnt-top-nav">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="!windowService.isDesktopRes">
        <tidy-grid>
            <ion-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Welcome'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [header]="(getClientFirstName(clientHubInfo?.client_hub_details.customer.name) | titlecase) + '!'"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Need help? Contact us:'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="clientHubInfo?.client_hub_details.team.owner.name + ' - ' + clientHubInfo?.client_hub_details.team.owner.email"
                    [translate]="false">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="(clientHubInfo?.client_hub_details.team.owner.phone | telephone)">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
        </tidy-grid>
      </tidy-alert>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/card.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Owed Bills'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let invoice of clientHubInfo?.pending_invoices | slice:0:showLimiter['pendingInvoices']">
          <tidy-card-button (action)="goToInvoicePage(invoice)">
            <tidy-row>
              <tidy-text
                [body]="(invoice?.amount_due | tcurrency)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Bill'">
              </tidy-text>
              <br>
              <ng-container *ngFor="let job of invoice?.items">
                <tidy-text
                  [helper]="'Job on'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="(job?.service_details?.date | customdate:'M/D/YY')">
                </tidy-text><br>
              </ng-container>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-card-button *ngIf="clientHubInfo?.pending_invoices.length >= 4" (action)="showLimit('pendingInvoices')">
          <tidy-row>
            <tidy-text
              *ngIf="showLimiter['pendingInvoices'] == 3"
              [body]="'Show all bills'">
            </tidy-text>
            <tidy-text
              *ngIf="showLimiter['pendingInvoices'] !== 3"
              [body]="'Show less bills'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-row  *ngIf="clientHubInfo?.pending_invoices.length == 0" class="extra-top-padding">
          <tidy-text
            [helper]="'No owed bills'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/proposals-black.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Pending Proposals'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let proposal of clientHubInfo?.pending_proposals | slice:0:showLimiter['pendingProposals']">
          <tidy-card-button (action)="goToPendingProposalPage(proposal)">
            <tidy-row>
              <tidy-text
                [body]="proposal?.service_details?.service_name | titlecase">
              </tidy-text>
              <br>
              <tidy-text
                [helper]="'Sent'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="(proposal?.created_at | customdate:'M/D/YY')">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-card-button *ngIf="clientHubInfo?.pending_proposals.length >= 4" (action)="showLimit('pendingProposals')">
          <tidy-row>
            <tidy-text
              *ngIf="showLimiter['pendingProposals'] == 3"
              [body]="'Show all proposals'">
            </tidy-text>
            <tidy-text
              *ngIf="showLimiter['pendingProposals'] !== 3"
              [body]="'Show less proposals'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-row  *ngIf="clientHubInfo?.pending_proposals.length == 0" class="extra-top-padding">
          <tidy-text
            [helper]="'No pending proposals'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/briefcase.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Accepted Proposals'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let proposal of clientHubInfo?.accepted_proposals | slice:0:showLimiter['acceptedProposals']">
          <tidy-card-button (action)="goToAcceptedProposalPage(proposal)">
            <tidy-row>
              <tidy-text
                [body]="proposal?.service_details?.service_name | titlecase">
              </tidy-text>
              <br>
              <tidy-text
                [helper]="'Sent'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="(proposal?.created_at | customdate:'M/D/YY')">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-card-button *ngIf="clientHubInfo?.accepted_proposals.length >= 4" (action)="showLimit('acceptedProposals')">
          <tidy-row>
            <tidy-text
              *ngIf="showLimiter['acceptedProposals'] == 3"
              [body]="'Show all proposals'">
            </tidy-text>
            <tidy-text
              *ngIf="showLimiter['acceptedProposals'] !== 3"
              [body]="'Show less proposals'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-row  *ngIf="clientHubInfo?.accepted_proposals.length == 0" class="extra-top-padding">
          <tidy-text
            [helper]="'No accepted proposals'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/checkmark-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Paid Bills'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let invoice of clientHubInfo?.paid_invoices | slice:0:showLimiter['paidInvoices']">
          <tidy-card-button (action)="goToPaidInvoicePage(invoice)">
            <tidy-row>
              <tidy-text
                [body]="(invoice?.amount | tcurrency)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Receipt'">
              </tidy-text>
              <br>
              <tidy-text
                [helper]="'Job on'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="(invoice?.created_at | customdate:'M/D/YY')">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-card-button *ngIf="clientHubInfo?.paid_invoices.length >= 4" (action)="showLimit('paidInvoices')">
          <tidy-row>
            <tidy-text
              *ngIf="showLimiter['paidInvoices'] == 3"
              [body]="'Show all bills'">
            </tidy-text>
            <tidy-text
              *ngIf="showLimiter['paidInvoices'] !== 3"
              [body]="'Show less bills'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-row  *ngIf="clientHubInfo?.paid_invoices.length == 0" class="extra-top-padding">
          <tidy-text
            [helper]="'No paid bills'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/calendar.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Next Job'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="clientHubInfo?.next_job?.start_date !== null" class="extra-top-padding">
          <tidy-text
            [body]="'Job Scheduled for'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(clientHubInfo?.next_job?.start_date | customdate:'M/D/YY')">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="clientHubInfo?.next_job?.start_date === null" class="extra-top-padding">
          <tidy-text
            [helper]="'No jobs scheduled'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-alert>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 180px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 160px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 240px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated="true" style="width: 120px"></ion-skeleton-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 150px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 180px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 190px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 150px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
            <ion-skeleton-text animated="true" style="width: 100px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <ion-skeleton-text animated="true" style="width: 130px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" class="extra-top-padding">
          <ion-skeleton-text animated="true" style="width: 180px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper *ngIf="loaded">

    <tidy-row [align]="'center'">
      <tidy-text
        [header]="'Welcome'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [header]="(getClientFirstName(clientHubInfo?.client_hub_details.customer.name) | titlecase) + '!'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Need help? Contact us:'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-text
        [body]="clientHubInfo?.client_hub_details.team.owner.name + '<br>' + clientHubInfo?.client_hub_details.team.owner.email + '<br>' + (clientHubInfo?.client_hub_details.team.owner.phone | telephone)">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<div class="wrapper-containing-block">
  <app-scroll-wrapper [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>
</div>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

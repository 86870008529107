import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class EmployerIntegrationsProvider {

  constructor(
    private httpClient: HttpClientCustom,
  ) {}

  getIntegrations() {
    return this.httpClient.get('api/v1/customer/customer-integrations');
  }

  async getMergeLinkToken() {
    return await this.httpClient.post('api/v1/customer/customer-integrations/create-merge-link-token', {});
  }

  async createMergeIntegration(token) {
    const payload = {
      type: 'hris',
      key: 'merge',
      data: {
        public_token: token,
      }
    };
    return await this.httpClient.post('api/v1/customer/customer-integrations', payload);
  }

}

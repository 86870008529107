import { WindowService } from './../../../shared/providers/window.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { GiftCode } from 'src/providers/gift-code/gift-code'
import { SuccessPage } from 'src/shared/pages/success/success';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'use-gift-card.html',
  encapsulation: ViewEncapsulation.None
})

export class UseGiftCardPage implements OnInit {

  errorMessage: String;
  form: UntypedFormGroup;
  submitted: Boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private giftCode: GiftCode,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService,
  ) {
    this.form = this.fb.group({
      code: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Use Gift Card');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.back();
    }
  }

  async checkGiftCode () {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const code = this.form.get('code').value
      const params = this.mountSuccessPageParams();
      await this.giftCode.saveGiftCard({ gift_code: code });
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch (error) {
      this.errorMessage = error.message;
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Gift Code Applied!',
      body: 'The credit has been applied to your account and will automatically apply to your next scheduled job. You can see the credit anytime in your billing history.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }
  
}

import { Component, Input, ViewEncapsulation, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Concierge } from "src/providers/concierge/concierge";
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Util } from "src/shared/util/util";

import { ConciergeRuleComponent } from 'src/pages/concierge/concierge-rule/concierge-rule';

@Component({
    templateUrl: 'concierge-rules.html',
    styleUrls: ['./concierge-rules.scss'],
    selector: 'concierge-rules',
    encapsulation: ViewEncapsulation.None,
})

export class ConciergeRulesComponent implements OnInit {

  items: any;
  pageTitle: string;
  type: string;
  isMobileResolution: boolean;
  shownItems: any[];
  searchForm: UntypedFormGroup;
  errorMessage: string;
  isRightSideContent: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private fb: UntypedFormBuilder,
    private concierge: Concierge,
    private util: Util,
  ) {
    this.searchForm = this.fb.group({
      search: [''],
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.loadDataAndParams();
  }

  async loadDataAndParams() {
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.type = this.getParam('type', dialogParams);
    const shouldUpdateItems = await this.storage.retrieve('shouldUpdateItems');
    if (shouldUpdateItems) {
      this.items = this.type == 'rules' ? await this.concierge.getRules() : await this.concierge.getSuggestions();
    } else {
      this.items = this.getParam('items', dialogParams);
    }
    this.shownItems = this.items;
    this.pageTitle = this.getParam('title', dialogParams);
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
  }

  getParam(param, dialogParams) {
    return dialogParams[param];
  }

  updateSearch({ search }) {
    if (search.length < 2 || search == '') {
      this.shownItems = this.items;
      return;
    }
    const term = search.toLowerCase();
    this.shownItems = [];
    this.items.map((item) => {
      const isTextMatch = item.description.toLowerCase().includes(term);
      if (isTextMatch && !this.shownItems.includes(item)) {
        this.shownItems.push(item);
      }
    });
  }

  async goToEditRulePage(rule) {
    const url = 'concierge-rule';
    const params = {
      rule: rule,
      addEditPageTitle: 'Edit Rule'
    }
    const component = ConciergeRuleComponent;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async goToAddRulePage() {
    const url = 'concierge-rule';
    const params = {
      rule: null,
      addEditPageTitle: 'Add Rule'
    };
    const component = ConciergeRuleComponent;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async dismissSuggestion(suggestion) {
    try {
      await this.concierge.dismissSuggestion(suggestion.id);
      this.items = await this.concierge.getSuggestions();
      this.shownItems = this.items;
      const successMessage = 'Suggestion dismissed';
      this.util.showSuccess(successMessage);
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    }
  }

}

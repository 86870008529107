import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { validateEmail } from 'src/shared/validator/validateEmail';
import { Auth } from 'src/providers/auth/auth';
import { Logger } from 'src/providers/logger';

@Component({
  templateUrl: 'reset-password.html',
  encapsulation: ViewEncapsulation.None
})

export class ResetPasswordPage {

  form: UntypedFormGroup;
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: Auth,
    private navCtrl: CustomNavController,
    private logger: Logger
  ) {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, validateEmail])]
    });
  }

  async onSubmit() {
    this.errorMessage = "";
    try {
      if (this.form.valid) {
        const requestData = {
          'email': this.form.value.email
        }
        await this.auth.recoverPassword(requestData);
        const params = this.mountSuccessPageParams();
        this.navCtrl.navigateForward('success-no-auth', params);
      }
    } catch (err) {
      this.errorMessage = "We couldn't find an account with that information";
      this.logger.error(err, 'reset-password-trying-send-email');
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Please Check Your Email',
      body: 'We\'ve sent you a link to create a new password.',
      buttonText: 'Back',
      buttonRoute: 'login'
    };
  }

}

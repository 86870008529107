<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Bills'"
    [showLinkIcon]="true"
    [linkIcon]="'settings-outline'"
    (linkAction)="goToBillSettingsPage()">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="windowService.isDesktopRes">
      <tidy-desktop-header>
        <tidy-text
          [title]="'Bills'">
        </tidy-text>
        <ng-container *ngIf="!loaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 70px;
              height: 27px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="loaded">
          <form [formGroup]="form" novalidate action="#" class="form-filters">
            <div class="desktop-item desktop-bills-item daterange-card">
              <div class="daterange-custom-container">
                <tidy-daterange
                  class="daterange-custom-input"
                  [label]="'Date Range'"
                  (customDateOption)="selectCustomDateRange($event)"
                  (stringDateOption)="selectStringDateRange($event)"
                  [(dateRangeForm)]="dateRangeForm"
                  [selectOptions]="selectOptions"
                  [rangeOption]="true"
                  [appearance]="'outline'"
                  [header]="true">
                </tidy-daterange>
              </div>
            </div>
          </form>
          <div class="desktop-nav-button">
            <tidy-button
              [image]="'assets/icon/filter-white.png'"
              [text]="'Filter'"
              [smallBody]="true"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{closeDisabled: true}">
            </tidy-button>
          </div>
          <ng-container *ngFor="let chip of chips">
            <ion-chip class="filter-chip">
              <ion-label>{{ chip.displayParentName | titlecase | translate}}: {{ chip.name | titlecase | translate }}</ion-label>
              <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
            </ion-chip>
          </ng-container>
          <mat-menu #menu="matMenu" class="filter-menu">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
                  <mat-checkbox
                    class="checkbox"
                    [checked]="getNodeChecked(node)"
                    (click)="$event.stopPropagation()"
                    (change)="checkNode(node, $event)">
                    {{ node.name | titlecase | translate }}
                  </mat-checkbox>
                </button>
              </mat-tree-node>
              <mat-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding>
                <button
                  class="node-button-padding"
                  mat-menu-item
                  matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                  </mat-icon>
                  {{ node.name | titlecase | translate }}
                </button>
              </mat-tree-node>
            </mat-tree>
          </mat-menu>
        </ng-container>
        <div class="spinner-settings-container">
          <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 15px"></ion-spinner>
          <ion-img
            *ngIf="loaded"
            [src]="'assets/img/gear.svg'"
            class="desktop-title-icon"
            (click)="goToBillSettingsPage()">
          </ion-img>
        </div>
      </tidy-desktop-header>

      <ng-container *ngIf="!isDataLoaded">
        <tidy-card style="padding: 0px; height: 600px; padding: 0px;" *ngIf="!wasTableLoaded">
          <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="isDataLoaded">
        <tidy-card [style.display]="currentRows?.length > 0 ? 'block' : 'none'" class="no-padding">
          <tidy-table
            #tidyTable
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            [allRowIsClickable]="true"
            [rowsToShow]="25"
            [showColumnLines]="false"
            (sortChange)="sortChanged($event)"
            (cellClick)="onTableColumnClick($event)"
            (pageChange)="pageChange($event)"
            [infiniteScroll]="true"
            [totalRows]="totalRows">
          </tidy-table>
        </tidy-card>
      </ng-container>
      <tidy-alert [style.display]="currentRows?.length == 0 ? 'block' : 'none'">
        <tidy-text [body]="'No Results'"> </tidy-text>
      </tidy-alert>
    </ng-container>

    <ng-container *ngIf="!windowService.isDesktopRes">
      <ng-container *ngIf="!isDataLoaded">
        <div style="width: 100%; padding-top: 5rem;">
          <ion-skeleton-text
            animated="true"
            style="height: 75px; border-radius: 6px"></ion-skeleton-text>
        </div>
        <div style="width: 100%; padding-top: 1rem;">
          <ion-skeleton-text
            animated="true"
            style="height: 200px; border-radius: 6px"></ion-skeleton-text>
        </div>
        <div style="width: 100%;">
          <ion-skeleton-text
            animated="true"
            style="height: 200px; border-radius: 6px"></ion-skeleton-text>
        </div>
        <div style="width: 100%;">
          <ion-skeleton-text
            animated="true"
            style="height: 200px; border-radius: 6px"></ion-skeleton-text>
        </div>
      </ng-container>
      <div *ngIf="isDataLoaded" style="padding-top: 6rem;">
        <form [formGroup]="form" novalidate action="#">
          <div class="mobile-item">
            <tidy-select
              class="bills-select-custom-input"
              [label]="'Status'"
              [items]="statusItems"
              [multiple]="false"
              [form]="form.get('selectedStatus')"
              formControlName="selectedStatus"
              (optionChange)="onFilterValueChange($event, 'status')">
            </tidy-select>
          </div>
          <div class="mobile-item">
            <tidy-select
              class="bills-select-custom-input"
              [label]="'Pro'"
              [items]="proList"
              [multiple]="true"
              [form]="form.get('selectedPro')"
              formControlName="selectedPro"
              (optionChange)="onFilterValueChange($event, 'pro')">
            </tidy-select>
          </div>
          <div class="mobile-item">
            <tidy-select
              class="bills-select-custom-input"
              [label]="'Property'"
              [items]="addressFilter"
              [multiple]="true"
              [form]="form.get('selectedAddress')"
              formControlName="selectedAddress"
              (optionChange)="onFilterValueChange($event, 'address')">
            </tidy-select>
          </div>
          <div class="mobile-item daterange-card">
            <div class="daterange-custom-container">
              <tidy-daterange
                class="daterange-custom-input"
                [label]="'Date Range'"
                (customDateOption)="selectCustomDateRange($event)"
                (stringDateOption)="selectStringDateRange($event)"
                [(dateRangeForm)]="dateRangeForm"
                [selectOptions]="selectOptions"
                [rangeOption]="true"
                [appearance]="'outline'">
              </tidy-daterange>
            </div>
          </div>
        </form>
      </div>

      <tidy-card *ngFor="let bill of bills">
        <tidy-card-button
          (action)="goToInvoicePage(bill)"
          class="arrow-to-top">
          <tidy-row>
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col size="6">
                    <tidy-row>
                      <tidy-text
                        [title]="bill?.homekeeper_data?.name | titlecase"
                        [translate]="false">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
          </tidy-row>
        </tidy-card-button>
        <div style="position: absolute; left: 0; right: 0; height: 7px; background: #275C7C;"></div>
        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text [body]="'Service'"> </tidy-text>
          <tidy-text [body]="'Owed'"> </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider> </tidy-divider>
        </tidy-row>
        <ng-container *ngFor="let service of bill?.items; let last = last">
          <tidy-row [alignLastItemRight]="true">
            <div style="width: 85%">
              <tidy-text
                [header]="service?.service_details?.name | titlecase">
              </tidy-text>
            </div>
            <div>
              <tidy-text
                *ngIf="service?.amount"
                [body]="service?.amount | tcurrency">
              </tidy-text>
              <tidy-text
                *ngIf="!service?.amount"
                [body]="bill?.amount | tcurrency">
              </tidy-text>
            </div>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="(service?.service_details?.date | customdate:'M/D') +
                ' at ' +
                service?.service_details?.address?.address +
                ', ' +
                (service?.service_details?.address?.unit ? ('Unit ' + service?.service_details?.address?.unit + ', ') : '') +
                service?.service_details?.address?.zip"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row
            class="extra-top-padding"
            [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-divider> </tidy-divider>
          </tidy-row>
        </ng-container>
        <ng-container *ngIf="!bill.full_paid">
          <tidy-row
            [alignLastItemRight]="true"
            class="extra-top-padding"
            *ngIf="bill.amount - bill.amount > 0">
            <tidy-text [header]="'Credit Applied'"> </tidy-text>
            <tidy-text [body]="bill.amount - bill.amount | tcurrency">
            </tidy-text>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
            <tidy-text [header]="'Total'"> </tidy-text>
            <tidy-text
              [body]="'<b>' + (bill?.amount | tcurrency) + '</b>'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <ng-container *ngIf="bill.full_paid">
          <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
            <tidy-text [header]="'Total'"> </tidy-text>
            <tidy-text [body]="'<b>' + (bill?.amount | tcurrency) + '</b>'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-alert *ngIf="!bills?.length && isDataLoaded">
        <tidy-row>
          <tidy-text [body]="'No Results'"> </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>
    
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content">
</app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'bills'">
</tidy-onboarding-modal>

<tidy-alert>
  <tidy-row>
    <tidy-image
      (click)="closePopup()"
      [src]="'assets/svg/close-circle.svg'"
      class="title-size align-last-right close-popup-icon"
      style="margin-left: auto;">
    </tidy-image>
  </tidy-row>
  <tidy-row [align]="'center'" *ngIf="data?.image" style="margin-top: -25px">
    <tidy-image
      [src]="data?.image"
      style="width:70px">
    </tidy-image>
  </tidy-row>
  <tidy-row [align]="'center'" [ngClass]="data?.addressId ? '' : 'no-bottom-padding'">
    <tidy-text
      [header]="data?.header">
    </tidy-text>
    <ng-container *ngIf="data?.body">
      <br>
      <tidy-text
        [body]="data?.body + (data?.learnMore ? ' ' : '')">
      </tidy-text>
      <tidy-text
        *ngIf="data?.body"
        [body]="'Learn More'"
        (action)="goToLink(data?.learnMore)"
        class="link">
      </tidy-text>
    </ng-container>
  </tidy-row>
  <tidy-row class="no-bottom-padding" [align]="'center'">
    <tidy-text
      *ngIf="data?.addressId"
      [body]="'View Address'"
      (action)="goToAddressPage(data?.addressId)"
      class="link">
    </tidy-text>
  </tidy-row>
</tidy-alert>

import { Component, OnInit } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { ScheduleCardModel } from 'src/models/schedule.model';

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'job-backup-times.html',
  selector: 'job-backup-times'
})

export class JobBackupTimesPage implements OnInit {

  backupTimes: any;
  categoryImage: string;
  errorMessage: string;
  job: ScheduleCardModel;
  jobDate: string;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Job Backup Times');
    this.loadData();
  }

  @Loading('', true)
  async loadData() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.loaded = false;
      await this.getJobParams();
      await this.getJobBackupTimes();
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  async getJobParams() {
    const params = await this.getParam('params');
    this.job = params.card;
    this.categoryImage = await this.getParam('categoryImage');
    this.jobDate =
      this.formatDate(this.job.template_data.job.start_datetime_local, 'ddd') +
      ' ' +
      this.formatDate(this.job.template_data.job.start_datetime_local, 'M/D');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getJobBackupTimes() {
    const response = await this.getParam('jobBackupTimes') || await this.client.getJobBackupTimes(this.job.template_data.booking.id);
    const backupTimes = [];
    const datesToDisplay = 7;
    for (let i = 0; i < datesToDisplay; i++) {
      backupTimes.push({
        date: '',
        checked: false,
        times: []
      })
    };
    let date = response[0].date;
    let dateCount = 0;
    response.map((item) => {
      if (item.date !== date) {
        date = item.date;
        dateCount += 1;
      }
      backupTimes[dateCount].date = date;
      backupTimes[dateCount].checked = backupTimes[dateCount].checked || item.selected;
      backupTimes[dateCount].times.push({
        viewValue: this.formatDate(`${item.date} ${item.start_time}`, 'h:mma'),
        value: item.start_time,
        checked: item.selected
      });
    })
    this.backupTimes = backupTimes;
  }

  async saveJobBackupTimes() {
    try {
      const times = this.prepareTimesPayload();
      await this.client.saveJobBackupTimes(this.job.template_data.booking.id, times);
      this.goBack();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  prepareTimesPayload() {
    let array = [];
    this.backupTimes.map((date) => {
      date.times.map((time) => {
        if (time.checked) {
          array.push({
            date: date.date,
            start_time: time.value
          });
        }
      })
    })
    return array;
  }

  async goBack() {
    if (this.dialogParams) {
      this.rightSidePanelService.goBack();
    } else {
      const addressId = await this.getParam('addressId');
      const params = await this.getParam('params');
      this.navCtrl.navigateBack(`job/${addressId}/${this.job.template_data.job.id}`, params);
    }
  }

  addTimesToDate(date) {
    date.checked = true;
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '');
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { billsItem, automationsItem, dashboardReportItem, tasksItem, desktopNavItems, inventoryItem, moreAutomaticBookingItem, vendorComplianceItem, moreAccountingItem, automationsSectionItems, employerItems, teamsItem, yourBrandingItem, auditLogsItem } from 'src/shared/components/desktop-nav/desktop-nav-items';

import { Auth } from 'src/providers/auth/auth';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Subscription } from 'rxjs';
import { Events } from '../events/events';

@Injectable({
  providedIn: 'root'
})
export class Menu implements OnDestroy {

  dashboardSection: string;
  private _automationSection: string;
  private _rootSection: string;
  public _showSecondaryMenu = false;
  private _selectedIntegration: string;
  private _selectedAutomation: string;
  readonly storageKeys = ['rootSection', 'automationSection', 'showSecondaryMenu', 'selectedIntegration', 'selectedAutomation'];
  subscriptions: Subscription[] = [];

  get showSecondaryMenu() {
    return this._showSecondaryMenu;
  }

  set rootSection(value: string) {
    this._rootSection = value;
    this.storage.save('rootSection', value);
  }

  get rootSection() {
    return this._rootSection;
  }

  set automationSection(value: string) {
    this._automationSection = value;
    this.storage.save('automationSection', value);
  }

  get automationSection() {
    return this._automationSection;
  }

  set selectedIntegration(value: string) {
    this._selectedIntegration = value;
    this.storage.save('selectedIntegration', value);
  }

  get selectedIntegration() {
    return this._selectedIntegration;
  }

  set selectedAutomation(value: string) {
    this._selectedAutomation = value;
    this.storage.save('selectedAutomation', value);
  }

  get selectedAutomation() {
    return this._selectedAutomation;
  }

  constructor(
    private auth: Auth,
    private dashboard:  Dashboard,
    private router: Router,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private events: Events
  ) {
    const navSub = this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        const rootItems = [...desktopNavItems, tasksItem, billsItem, automationsItem, dashboardReportItem, ...employerItems, inventoryItem];
        const isRootSection = rootItems.some(desktopNavItem => {
          return desktopNavItem.target === event.url || 
                 (desktopNavItem.target !== '/employer-settings' && event.url.startsWith(desktopNavItem.target));
        });

        if (isRootSection) {
          this._rootSection = event.url;
          this.storage.save('rootSection', event.url);
        }

        if (this.auth.isAuthenticated()) {
          const dashboardItems = await this.dashboard.buildDashboardSectionItems();
          const isDashboardSection = dashboardItems.some(desktopNavItem => {
            return desktopNavItem.target === event.url;
          });
          if (isDashboardSection) {
            this.dashboardSection = event.url;
          }
        }

        const automationSection = automationsSectionItems.some(automationSectionItem => {
          return automationSectionItem.target === event.url;
        });
        if (automationSection) {
          this.automationSection = event.url;
        }
      }
    });
    const logoutSub = this.subscribeLogout();

    this.subscriptions.push(navSub);
    this.subscriptions.push(logoutSub);
    this.initValues();
  }

  async initValues() {
    this._rootSection = await this.storage.retrieve('rootSection');
    this._automationSection = await this.storage.retrieve('automationSection');
    this._selectedIntegration = await this.storage.retrieve('selectedIntegration');
    this._selectedAutomation = await this.storage.retrieve('selectedAutomation');
    this._showSecondaryMenu = await this.storage.retrieve('showSecondaryMenu');
  }

  getNameAndAddress(): any {
    return {
      customerName: localStorage.getItem('customer_name'),
      custumerId: localStorage.getItem('address_id_menu')
    }
  }

  async goToRootSection() {
    const root = this.rootSection;
    await this.navCtrl.navigateRoot('reload');
    await this.navCtrl.navigateRoot(root);
  }

  subscribeLogout(): Subscription {
   return this.events
    .subscribe('logout', () => {
      this.storageKeys
        .forEach(key => {
          this.storage.delete(key);
        });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions
      .forEach(subscription => {
        subscription.unsubscribe();
      });
  }
}

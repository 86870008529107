import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'pro-added.html',
  encapsulation: ViewEncapsulation.None
})

export class ProAddedPage implements OnInit {

  dialogParams: any;
  loaded: boolean;
  pro: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Pro Added');
    }
    this.pro = this.navCtrl.getParam('pro') || this.dialogParams.pro;
    this.loaded = true;
  }

  goToMyPros() {
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
    this.navCtrl.navigateForward(url);
  }

  async sendJobRequest() {
    const params = {
      pro: this.pro,
      address: this.navCtrl.getParam('address') || this.dialogParams.address
    }
    this.rightSidePanelService.navigateTo('request-pro', params);
  }

}

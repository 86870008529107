import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';

import { DoNotCleanRoomModel } from 'src/models/to-do.model';

@Component({
  templateUrl: 'do-not-clean-room.html'
})

export class ToDosDoNotCleanRoomPage implements OnInit {

  doNotClean: boolean;
  doNotCleanRoom: DoNotCleanRoomModel;
  errorMessage: string;
  isRightSideContent: boolean;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos,
    private util: Util
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle(this.doNotCleanRoom?.address_room?.name);
    this.doNotCleanRoom = this.navCtrl.getParam('doNotCleanRoom') || dialogParams.doNotCleanRoom;
    this.loaded = true;
  }

  async markRoomAsDoClean() {
    try {
      await this.toDos.markRoomAsDoClean(this.doNotCleanRoom.do_not_clean_room_id);
      const successMessage = 'Room updated!';
      this.util.showSuccess(successMessage);
      this.rightSidePanelService.navigateTo('to-dos');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}

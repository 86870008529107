import { Injectable } from '@angular/core';
import { HttpClientCustom } from 'src/providers/custom/http-client';
import { Aws } from '../aws/aws';

@Injectable()
export class GenomeMaps {

  constructor(
    private httpClient: HttpClientCustom,
    private aws: Aws,
  ) {}

  async checkMapRequests(addressId) {
    const url = `api/v1/customer/genome/address-map-requests/?address_id=${addressId}`;
    return await this.httpClient.get(url);
  }

  uploadMapToS3(picture: string, addressId: number): Promise<any> {
    const uniqueKey = `${addressId}/map/${Date.now()}`;
    return this.aws.uploadFileToS3(picture, uniqueKey, 'image/png');
  }

  async getMapRender(addressId) {
    const url = `api/v1/customer/genome/address-map-renders/?address_id=${addressId}`;
    return await this.httpClient.get(url);
  }

  async submitMapRequest(data) {
    const url = `api/v1/customer/genome/address-map-requests`;
    return await this.httpClient.post(url, data);
  }

  async updateMapRequest(mapRequestId, data) {
    const url = `api/v1/customer/genome/address-map-requests/${mapRequestId}`;
    return await this.httpClient.put(url, data);
  }

  async cancelMapRequest(mapRequestId) {
    const url = `api/v1/customer/genome/address-map-requests/${mapRequestId}/deactivate`;
    return await this.httpClient.post(url, {});
  }
}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Addresses } from 'src/providers/customer/addresses';
import { BookJob } from 'src/providers/book-job/book-job';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';

import { Util } from 'src/shared/util/util';

import { ClientSettingsModel } from 'src/models/client.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { Loading } from 'src/shared/components/loading/loading';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from 'src/shared/providers/window.service';

import { SubscriptionsPage } from 'src/pages/subscription/subscription';
import { GetRefundPage } from 'src/pages/billing-history/get-refund/get-refund';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { DateTime } from 'luxon';

import { BillingItemHelpPage } from 'src/pages/billing-history/billing-item-help/billing-item-help';

@Component({
  templateUrl: 'billing-history.html',
  encapsulation: ViewEncapsulation.None
})

export class BillingHistoryPage implements OnInit {

  addressId: number;
  addressFilter: TidySelectNumberValueModel[];
  billingLoaded: boolean;
  backPage: string;
  billingHistory: any;
  billingData: any = null;
  chargesLoaded: boolean;
  dialogParams: any;
  errorMessage: string;
  settings: ClientSettingsModel;
  showBilling: boolean;
  cantGoBack: boolean;
  cameFromMyProPage: boolean;
  hasProSelected: boolean;
  hasPrivatePro: boolean;
  isRightSideContent: boolean;
  loaded: boolean;
  proList: any;
  proFilterItems: any;
  selectedProId: any;
  selectedProName: string;
  proFilterValue: number;
  userRole: string;
  paymentMethods: any[];
  totalPendingCharges: number;
  pendingClientCharges: any[];

  constructor(
    private addresses: Addresses,
    private bookJob: BookJob,
    private client: Client,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private util: Util,
    private route: ActivatedRoute,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    private paymentMethodsProvider: PaymentMethodsProvider
  ) {}

  async ngOnInit() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.rightSidePanelService.setDialogPageTitle('Billing History');
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.backPage = localStorage.getItem('billingHistoryBackPage') || 'more';
      this.cantGoBack = this.route.snapshot.data.cantGoBack;
      this.userRole = localStorage.getItem('user_role');
      this.cameFromMyProPage = this.navCtrl.getParam('cameFromMyProPage') || this.dialogParams?.cameFromMyProPage;
      this.selectedProId = this.navCtrl.getParam('proId') || this.dialogParams?.proId;
      this.showBilling = await this.checkIfShowBilling();
      this.paymentMethods = await this.paymentMethodsProvider.getPaymentMethods();
      this.paymentMethods = this.util.parsePaymentMethods(this.paymentMethods);
      const [billingHistory, billingData, pendingClientCharges, proList, addressResponse] = await Promise.all([
        this.getBillingHistory(),
        this.getBillingData(),
        this.loadPendingClientCharges(),
        this.getProsList(),
        this.client.getAddresses()
      ]);
      this.billingData = billingData;
      this.pendingClientCharges = pendingClientCharges;
      this.billingHistory = billingHistory;
      this.concatenateAndSortBillingHistory();
      this.proList = proList;
      this.addressFilter = this.client.parseAddressList(addressResponse, false);
      this.addressFilter.unshift({
        viewValue: 'All Properties',
        value: 0
      });
      this.proFilterItems = this.buildProFilterItems();
      if (this.cameFromMyProPage) {
        this.hasPrivatePro = true;
        this.billingData = this.navCtrl.getParam('billingData') || this.dialogParams?.billingData;
        this.proFilterValue = this.selectedProId;
        this.backPage = `my-pro/${this.selectedProId}`;
        this.hasProSelected = true;
        let selectedPro = this.proFilterItems.find((item) => item.value == this.selectedProId);
        const proName = this.navCtrl.getParam('proName') || this.dialogParams?.proName || 'Selected Pro History';
        if (!selectedPro) {
          selectedPro = {
            value: this.selectedProId,
            viewValue: proName,
          }
        }
        this.selectedProName = selectedPro.viewValue;
      } else {
        this.proFilterValue = 0;
      }
      this.billingLoaded = true;
      this.chargesLoaded = true;
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      console.error('err', err);
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async getProsList() {
    this.hasPrivatePro = this.cameFromMyProPage ? true : await this.pros.checkIfHasPrivatePro();
    if (this.hasPrivatePro) {
      const allPrivatePros = await this.pros.getAllPrivatePros(); //TODO this will ping twice upon first load
      return allPrivatePros;
    } else {
      return [];
    }
  }

  buildProFilterItems() {
    const array: any = [];
    array.push({
      value: 0,
      viewValue: 'TIDY History'
    });
    this.proList.map((pro) => {
      array.push({
        value: pro.id,
        viewValue: pro.first_name + ' History'
      });
    });
    return array;
  }

  async getBillingData() {
    if (this.cameFromMyProPage) {
      return await this.pros.getCreditBalance(this.selectedProId);
    } else {
      if (this.showBilling && this.userRole !== 'member') {
        return await this.client.getBillingData();
      }
    }
  }

  async getBillingHistory() {
    if (this.cameFromMyProPage) {
      return await this.pros.getBillingHistory(this.selectedProId);
    } else {
      let billingHistory = await this.client.getAllBillingHistory(this.paymentMethods);
      return billingHistory;
    }
  }

  concatenateAndSortBillingHistory() {
    this.billingHistory = [...this.billingHistory, ...this.pendingClientCharges];
    this.billingHistory.sort((a, b) => {
      const dateA = a.isPendingClientCharge ? DateTime.fromISO(a.date) : DateTime.fromISO(a.created_at);
      const dateB = b.isPendingClientCharge ? DateTime.fromISO(b.date) : DateTime.fromISO(b.created_at);
      return dateB.diff(dateA).toMillis();
    });
  }

  async checkIfShowBilling() {
    if (this.cameFromMyProPage) {
      return true;
    } else {
      const showBilling = await this.storage.retrieve('showBilling');
      if (showBilling) {
        return showBilling;
      } else {
        const settings = await this.client.getClientSettings();
        return (settings.billing && settings.billing.hide_billing_history === 'false');
      }
    }
  }

  async loadPendingClientCharges(): Promise<any[]> {
    if (this.showBilling && !this.cameFromMyProPage) {
      let pendingClientCharges = await this.client.getCustomerCharges();
      const statuses = {
        succeeded: {
          icon: 'checkmark-circle',
          class: 'charge-badge-success'
        },
        pending: {
          icon: 'ellipsis-horizontal-circle-outline',
          class: 'charge-badge-pending'
        },
        processing: {
          icon: 'ellipsis-horizontal-circle-outline',
          class: 'charge-badge-pending'
        },
        canceled: {
          icon: 'warning',
          class: 'charge-badge-canceled'
        },
        failed: {
          icon: 'warning',
          class: 'charge-badge-canceled'
        }
      };
      pendingClientCharges = pendingClientCharges.map((charge) => ({
        ...charge,
        isPendingClientCharge: true,
        payment_method: this.paymentMethods.find((paymentMethod) => paymentMethod.id === charge.payment_method_id),
        class: statuses?.[charge?.status]?.class || 'charge-badge-pending',
        icon: statuses?.[charge?.status]?.icon || 'ellipsis-horizontal-circle-outline',
        formattedDate: DateTime.fromISO(charge.date).toFormat('M/d/yy'),
      }));
      pendingClientCharges = pendingClientCharges.filter(credit => credit.status !== 'succeeded').reverse();
      this.totalPendingCharges = pendingClientCharges.reduce((acc, credit) => acc + credit.amount, 0);
      return pendingClientCharges;
    } else {
      return [];
    }
  }

  @Loading('', true)
  async changePro(selection) {
    this.errorMessage = '';
    this.billingLoaded = false;
    try {
      if (selection == 0) {
        this.billingData = await this.client.getBillingData();
        this.billingHistory = await this.client.getAllBillingHistory(this.paymentMethods);
        this.concatenateAndSortBillingHistory();
        this.hasProSelected = false;
        this.proFilterValue = 0;
      } else {
        const proTeamId = this.getProTeamId(selection);
        this.billingData = await this.pros.getCreditBalance(proTeamId);
        this.billingHistory = await this.pros.getBillingHistory(selection);
        this.selectedProId = selection;
        const selectedPro = this.proFilterItems.find((item) => item.value == selection);
        this.selectedProName = selectedPro.viewValue;
        this.proFilterValue = selection;
        this.hasProSelected = true;
      }
      this.billingLoaded = true;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  getProTeamId(proId) {
    const pro = this.proList.find((pro) => pro.id == proId);
    return pro?.main_team_id;
  }

  @Loading('', true)
  async changeAddress(addressId) {
    this.errorMessage = '';
    this.chargesLoaded = false;
    try {
      if (addressId === 0) {
        if (this.hasProSelected) {
          this.billingHistory = await this.pros.getBillingHistory(this.selectedProId);
        } else {
          this.billingHistory = await this.client.getAllBillingHistory(this.paymentMethods);
        }
      } else {
        if (this.hasProSelected) {
          this.billingHistory = await this.pros.getBillingHistoryByAddress(this.selectedProId, [addressId]);
        } else {
          this.billingHistory = await this.client.getBillingHistory(addressId, this.paymentMethods);
        }
      }
      this.chargesLoaded = true;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  showCantRefund() {
    const message = 'Unable to refund. This charge has already been used by a job. Please contact us in the Concierge section for support.';
    this.util.showError(message, 10000);
  }

  waiveFeeResults(results) {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const params = {
      header: results,
      body: 'You can waive up to 2 cancellation fees every 10 completed cleanings. Must be within 90 days of the fee. Please click "Get Help" on the charge if you have any questions.',
      buttonText: 'Ok',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
    this.goToPage('success', params);
  }

  learnAboutRefund() {
    const url = 'https://help.tidy.com/viewing-billing-history#time-to-receive-refunds';
    this.util.openUrl(url);
  }

  goToCleaningDetails(cleaningId) {
    const url = this.hasProSelected ? 'past-private-job' : 'past-job';
    const component = this.hasProSelected ? PastPrivateJobPage : PastJobPage;
    this.goToPage([url, cleaningId], {jobId: cleaningId}, component);
  }

  getRefund(refundable) {
    if (!refundable) {
      this.showCantRefund();
    } else {
      this.goToPage('get-refund', {refundable}, GetRefundPage);
    }
  }

  async waiveFee(bookingId) {
    try {
      await this.client.requestWaiveFee(bookingId);
      this.waiveFeeResults('Fee Waived');
    } catch (err) {
      this.waiveFeeResults('Unable to Waive Fee');
    }
  }

  goToSubscriptionPage() {
    this.goToPage('subscription', {}, SubscriptionsPage);
  }

  async goToPage(url, params, component = null) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  goToBillingItemHelp(item) {
    this.goToPage('billing-item-help', {item}, BillingItemHelpPage);
  }

}

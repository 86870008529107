<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Automatic Booking'"
    [canGoBack]="!windowService.isDesktopRes || showBackButton"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="hasSyncPending">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/yellow-pending.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Sync Pending'">
          </tidy-text>
        </tidy-row>
        <tidy-text
          [body]="'TIDY is syncing the integration you added. Please check back soon.'">
        </tidy-text>
      </tidy-alert>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'TIDY can automatically book a job for your pros when a reservation is synced.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [actionBody]="'Learn More'"
          (action)="learnMoreAutomaticBooking()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-alert>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding" style="padding-bottom:0px">
            <tidy-input
              #searchInput
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-alert>

      <tidy-alert *ngIf="addresses?.length == 0">
        <tidy-text
          [body]="'No results'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let address of addresses">
        <tidy-card-button (action)="goToAutomaticBookingAddress(address)" class="arrow-to-top">
          <tidy-row class="vertical-align-center">
            <tidy-image
              *ngIf="address?.address_automatic_booking_setting?.automatic_booking_enabled"
              [src]="'assets/img/checkmark.svg'"
              class="body-size">
            </tidy-image>
            <tidy-image
              *ngIf="!address?.address_automatic_booking_setting?.automatic_booking_enabled"
              [src]="'assets/img/block.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              *ngIf="!address?.address_name"
              [body]="address?.address1"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="address?.address_name"
              [body]="address?.address_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="address?.address2 && !address?.address_name">
            <tidy-image
              [src]="'assets/img/home.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="address?.address2"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="address?.address_automatic_booking_setting?.automatic_booking_enabled">
            <tidy-row class="extra-top-padding vertical-align-center">
              <tidy-image
                [src]="'assets/img/suitcase.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="address?.address_automatic_booking_setting?.required_team_service?.name">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/clock.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="(address?.address_automatic_booking_setting?.booking_start_time_no_earlier_than | customtime:'h:mma') + ' - ' + (address?.address_automatic_booking_setting?.booking_end_time_no_later_than | customtime:'h:mma')">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="!address?.address_automatic_booking_setting">
            <tidy-text
              [helper]="'No Integration Synced'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-skeleton-text animated="true" style="width: 100%; height:14px"></ion-skeleton-text>
        <ion-skeleton-text animated="true" style="width: 230px; margin: auto; height:14px"></ion-skeleton-text>
      </tidy-row>

      <tidy-alert style="width: 100%; height:70px">
      </tidy-alert>

      <tidy-card *ngFor="let card of [1,2,3,4,5,6,7]">
        <tidy-card-button [hideArrow]="true">
          <tidy-row class="extra-bottom-padding">
            <ion-skeleton-text animated="true" style="width: 130px; height: 14px"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 120px; height: 14px"></ion-skeleton-text>
          </tidy-row>
          <tidy-row>
            <ion-skeleton-text animated="true" style="width: 140px; height: 14px"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

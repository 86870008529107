import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';

import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

@Component({
  templateUrl: './upsell.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./upsell.scss']
})

export class UpsellPage implements OnInit {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  showOnboardingSubscription$: Subscription;
  showOnboarding: boolean = false;
  hasProperties: boolean = false;

  constructor(
    public windowService: WindowService,
    private util: Util,
    public onboardingProvider: OnboardingProvider
  ) {}

  async ngOnInit() {
    try {
      this.checkIfShouldShowOnboarding();
      this.getPageData();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('upsell');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.getPageData();
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  getPageData() {
    //TODO
  }

}

import { Component } from '@angular/core';

import { AvailableTimesForRange } from 'src/providers/calendar/available-times-for-range';
import { BookJob } from 'src/providers/book-job/book-job';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';

import { Loading } from 'src/shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { BookJobPage } from '../booking/book-job/book-job';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { RequestJobPage } from '../request-job/request-job';
import { BrowseProsPage } from '../browse-pros/browse-pros';
import { AddProPage } from '../my-pros/add-pro/add-pro';

@Component({
  templateUrl: 'book-or-request.html'
})

export class BookOrRequestPage {

  errorMessage: string;
  isRentalClient: boolean;
  loaded: boolean;
  pageTitle: string;
  params: any;
  pros: any;
  prosData: any;
  priorityListSentence: string;
  scenario: string;
  shownPros: any;
  showUnableToBookJob: boolean;
  showUnableToLogJob: boolean;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private availableTimesForRange: AvailableTimesForRange,
    private bookJobService: BookJob,
    private client: Client,
    private navCtrl: CustomNavController,
    private prosService: Pros,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    this.rightSidePanelService.setDialogLoading(true);
    this.loaded = false;
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    this.params = {
      address: this.navCtrl.getParam('address') || this.dialogParams?.address,
      bookingType: this.navCtrl.getParam('bookingType') || this.dialogParams?.bookingType,
      categoryId: this.navCtrl.getParam('categoryId') || this.dialogParams?.categoryId,
      categoryHeader: this.navCtrl.getParam('categoryHeader') || this.dialogParams?.categoryHeader,
      filterByPro: this.navCtrl.getParam('filterByPro') || this.dialogParams?.filterByPro,
      isPrivatePro: this.navCtrl.getParam('isPrivatePro') || this.dialogParams?.isPrivatePro,
      proFirstName: this.navCtrl.getParam('proFirstName') || this.dialogParams?.proFirstName,
      jobId: this.navCtrl.getParam('jobId') || this.dialogParams?.jobId,
      bookingId: this.navCtrl.getParam('bookingId') || this.dialogParams?.bookingId,
      bookingKey: this.navCtrl.getParam('bookingKey') || this.dialogParams?.bookingKey,
      shouldInsteadAddJob: this.navCtrl.getParam('shouldInsteadAddJob') || this.dialogParams?.shouldInsteadAddJob,
      planId: this.navCtrl.getParam('planId') || this.dialogParams?.planId,
      date: this.navCtrl.getParam('date') || this.dialogParams?.date,
    };
    if (this.params.filterByPro) {
      this.params['hkId'] = this.navCtrl.getParam('hkId') || this.dialogParams?.hkId;
    }
    this.scenario = await this.getScenario();
    this.pageTitle = this.getPageTitle();
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.getPriorityListSentence();
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getPriorityListSentence() {
    const prosData = this.prosService.parseProsIntoGroups(this.prosData);
    this.priorityListSentence = this.prosService.updatePriorityListSentence(prosData);
  }

  async getScenario() {
    this.prosData = await this.prosService.getPros(this.params.categoryId);
    const hasPros = await this.prosService.checkIfHasProsInCategory(this.prosData);
    this.pros = this.parsePros(this.prosData);
    this.shownPros = [];
    this.pros.map((pro, index) => {
    if (index < 2) {
        this.shownPros.push(pro);
      }
    });
    if (this.params.categoryId == 1) {
      if (hasPros) {
        return 'hasCleaningPros';
      } else {
        return 'notHasCleaningPros';
      }
    } else {
      if (hasPros) {
        return 'hasNonCleaningPros';
      } else {
        return 'notHasNonCleaningPros';
      }
    }
  }

  parsePros(pros) {
    let prosArray = [];
    pros.approved.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        prosArray.push((pro));
      }
    });
    pros.favorited.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        prosArray.push((pro));
      }
    });
    return prosArray;
  }

  getPageTitle() {
    const titles = {
      add_job: 'Book Jobs',
      add_one_time_job: 'Add One Time Job',
      reschedule_plan: 'Reschedule Plan',
      reschedule_job: 'Reschedule Job',
    }
    return titles[this.params.bookingType];
  }

  async goToBookJob() {
    try {
      this.params.pros = await this.bookJobService.fetchPros(this.params.categoryId);
      if (this.params.categoryId !== 1) {
        this.removeNonProFilters();
      }
      const url = 'book-job';
      const component = BookJobPage;
      this.rightSidePanelService.navigateTo(url, this.params, component);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBookJobwithNewPro() {
    this.params['shouldNotDefaultToLastPro'] = true;
    this.goToBookJob();
  }

  goToBookJobWithPro(pro) {
    this.params['shouldNotDefaultToLastPro'] = false;
    this.params.filterByPro = true;
    this.params['hkId'] = pro.object.id;
    this.goToBookJob();
  }

  @Loading('', true)
  async goToLogJob() {
    try {
      this.params.pros = await this.bookJobService.fetchPros(this.params.categoryId);
      this.removeNonProFilters();
      this.params['isLoggingJob'] = true;

      this.navCtrl.navigateForward('book-job', this.params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  removeNonProFilters() {
    this.params.pros.splice(0, 4);
  }

  @Loading('', true)
  async goToRequestJob() {
    try {
      if (this.params.categoryId == 1 && !this.params.filterByPro && this.params?.jobId) {
        const substituteData = await this.availableTimesForRange.checkIfCanRequestSubstitute();
        const substituteJobId = substituteData.substitute_available_for_job_id;
        if (substituteJobId !== null) {
          return this.navCtrl.navigateForward(`request-substitute/${substituteJobId}`);
        }
      }
      if (this.params.filterByPro) {
        const params = {
          addingOneTimeJob: this.params.bookingType === 'add_one_time_job',
          address: this.params.address,
          pro: {
            first_name: this.params.proFirstName,
            id: this.params.hkId,
            private: this.params.isPrivatePro
          }
        };
        const url = 'request-job';
        const component = RequestJobPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        const client = await this.client.getClientInfo();
        const services = await this.bookJobService.fetchRequiredTeamServices('all', false, this.params.categoryId);
        const params: any = {
          cameFromBookOrRequest: true,
          address: this.params.address,
          bookingType: this.params.bookingType,
          services: services,
          selectedService: this.getOneHourServiceData(),
          bookingId: this.params.bookingId
        };
        const url = 'request-job';
        const component = RequestJobPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getOneHourServiceData() {
    return {
      billingType: "flat_rate",
      description: "Quick clean, best for focused needs or small homes.",
      duration: 60,
      image: "assets/img/plans/regular_cleaning.one_hour.svg",
      key: "regular_cleaning.one_hour",
      planId: null,
      price: 5500,
      title: "1 hour cleaning"
    }
  }

  goToAddPro() {
    const url = 'add-pro';
    const component = AddProPage;
    this.rightSidePanelService.navigateTo(url, {}, component);
  }

  goToBrowsePros() {
    const url = 'brose-pros';
    const component = BrowseProsPage;
    this.rightSidePanelService.navigateTo(url, this.params, component);
  }

  showAllPros() {
    this.shownPros = this.pros;
  }

}

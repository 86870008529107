import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Card } from 'src/providers/card/card';
import { ClientSettingsModel } from 'src/models/client.model';
import { Client } from 'src/providers/client/client';
import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'payment-options.html'
})
export class PaymentOptionsPage implements OnInit {

  card: any;
  cards: any;
  errorMessage: string;
  subscription: any;
  cantGoBack: boolean;
  clientInfo: any;
  userRole: string;
  settings: ClientSettingsModel;
  loaded: boolean;

  constructor(
    private cardService: Card,
    private client: Client,
    private route: ActivatedRoute,
    private navCtrl: CustomNavController,
    private util: Util
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.cantGoBack = this.route.snapshot.data.cantGoBack;
      this.clientInfo = await this.client.getClientInfo();
      this.userRole = localStorage.getItem('user_role');
      if (this.userRole !== 'member') {
        this.cards = await this.cardService.getCreditCards();
        this.cards.map((card) => {
          if (card.is_primary) {
            this.card = card;
          }
        });
      }
      this.settings = await this.client.getClientSettings();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToEditCard() {
    const params = {
      cards: this.cards
    }
    this.navCtrl.navigateForward('payment-methods', params);
  }

  @Loading('', true)
  async goToPaymentSettings() {
    try {
      const params = {
        setting: this.util.deepClone(this.settings)
      };
      this.navCtrl.navigateForward('payment-settings', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBuyBundle() {
    this.navCtrl.navigateForward('buy-bundle');
  }

  goToUseGiftCard() {
    this.navCtrl.navigateForward('use-gift-card');
  }

  goToViewPrices() {
    this.navCtrl.navigateForward('my-prices');
  }

  goToSubscriptions() {
    this.navCtrl.navigateForward('subscription');
  }
}

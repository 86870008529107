import { WindowService } from 'src/shared/providers/window.service';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { debounceTime } from 'rxjs/operators';
import { Client } from 'src/providers/client/client';

@Component({
  templateUrl: 'audit-logs.html',
  encapsulation: ViewEncapsulation.None
})

export class IntegrationAuditLogsPage implements OnInit {
  form: UntypedFormGroup;
  searchForm: UntypedFormGroup;
  dateRangeForm: UntypedFormGroup;
  guestReservationSyncRuns: any;
  history: any = [];
  totalRecords: number;
  loaded: boolean;
  errorMessage: string;
  isFiltersLoaded: boolean;
  addressFilter: any;
  addressResponse: any;
  statusList: any;
  pageSize: number = 10;

  constructor(
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    public windowService: WindowService,
    private client: Client,
  ) {
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: ''
    });
    this.searchForm = fb.group({
      search: [''],
    });
    this.form = fb.group({
      status: [''],
      selectedAddress: [[]],
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => this.updateSearch(value));
  }

  async ngOnInit() {
    this.dateRangeForm.patchValue({ customStringSelect: 'last_week' });
    await Promise.all([
      this.loadGuestReservationSyncRuns(),
      this.loadFiltersData()
    ]);
    this.isFiltersLoaded = true;
    this.loaded = true;
  }

  async loadFiltersData() {
    this.addressResponse = await this.client.getAddresses();
    this.addressFilter = this.client.parseAddressList(
      this.addressResponse,
      false
    );
    this.statusList = [
      {
        value: '',
        viewValue: 'All'
      },
      {
        value: 'success',
        viewValue: 'Success'
      },
      {
        value: 'failure',
        viewValue: 'Failure'
      },
      {
        value: 'running',
        viewValue: 'Running'
      }
    ]
  }

  onFilterValueChange() {
    this.loadGuestReservationSyncRuns(1, 10, {
      status: this.form.get('status').value,
      address_ids: this.form.get('selectedAddress').value
    });
  }

  async loadGuestReservationSyncRuns(pageNumber: number = 1, pageSize: number = 10, filters = {}) {
    try {
      this.pageSize = pageSize;
      const guestReservationSyncRuns = (await this.guestReservations.getAuditLogs(pageNumber, pageSize, filters));
      this.guestReservationSyncRuns = guestReservationSyncRuns.body;
      this.totalRecords = guestReservationSyncRuns.totalRecords;

      this.history = this.guestReservationSyncRuns;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToReservationSyncRunPage(syncRun) {
    this.navCtrl.navigateForward('audit-log', { guestReservationSyncRun: syncRun });
  }

  async updateSearch({ search }) {
    if (!search || search.length < 2) {
      this.history = this.guestReservationSyncRuns;
      return;
    }

    this.history = this.guestReservationSyncRuns.filter((run) => {
      return run.guest_reservation_source_type.name.toLowerCase().includes(search.toLowerCase());
    });
  }

  selectPage(pagination: {
    pageIndex: number;
    pageSize: number;
    previousPageIndex: number;
    length: number;
  }) {
    this.loadGuestReservationSyncRuns(pagination.pageIndex + 1, pagination.pageSize);
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }
}

export const scheduleMockData = [
  {
      "date": "Mar 26, 2025",
      "jobs": [
          {
              "id": 2146505,
              "date": "Mar 26, 2025",
              "address_id": 623525,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225855,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561732,
                      "homekeeper": {
                          "id": 244940,
                          "name": "Blueline Enterprises"
                      }
                  }
              ]
          },
          {
              "id": 2146507,
              "date": "Mar 26, 2025",
              "address_id": 623544,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225857,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561734,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2113200,
              "date": "Mar 26, 2025",
              "address_id": 623549,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "completed",
              "chat_rooms_with_unread_messages": [
                  {
                      "key": "homekeeper_job_id-2519219",
                      "data": {
                          "job_id": 2113200,
                          "team_id": 218880,
                          "homekeeper_id": 221879,
                          "homekeeper_job_id": 2519219,
                          "customer_account_id": 543457
                      },
                      "type": "homekeeper_job",
                      "expires_at": null
                  }
              ],
              "is_job_request": false,
              "booking": {
                  "id": 1198357,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519219,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2138192,
              "date": "Mar 26, 2025",
              "address_id": 623549,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198368,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2552189,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2146504,
              "date": "Mar 26, 2025",
              "address_id": 623551,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225854,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561731,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146508,
              "date": "Mar 26, 2025",
              "address_id": 623556,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225858,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561735,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146506,
              "date": "Mar 26, 2025",
              "address_id": 623563,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225856,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561733,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2145739,
              "date": "Mar 26, 2025",
              "address_id": 631197,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "completed",
              "chat_rooms_with_unread_messages": [
                  {
                      "key": "homekeeper_job_id-2562011",
                      "data": {
                          "job_id": 2145739,
                          "team_id": 241339,
                          "homekeeper_id": 244360,
                          "homekeeper_job_id": 2562011,
                          "customer_account_id": 543457
                      },
                      "type": "homekeeper_job",
                      "expires_at": null
                  }
              ],
              "is_job_request": false,
              "booking": {
                  "id": 1225232,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562011,
                      "homekeeper": {
                          "id": 244360,
                          "name": "Arif Yaqoobi"
                      }
                  }
              ]
          },
          {
              "id": 2145740,
              "date": "Mar 26, 2025",
              "address_id": 631197,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225233,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2560759,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          },
          {
              "id": 2146510,
              "date": "Mar 26, 2025",
              "address_id": 650608,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225860,
                  "start_date_no_earlier_than": "03/26/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/26/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561737,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1963853,
              "address_id": 623549,
              "check_in_date": "Mar 22, 2025",
              "check_out_date": "Mar 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1986274,
              "address_id": 631197,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1971458,
              "address_id": 623544,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1973121,
              "address_id": 623545,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1977155,
              "address_id": 623549,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 4,
                  "total_children": 0
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1972282,
              "address_id": 631197,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1961122,
              "address_id": 623527,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 11,
                  "total_children": 4
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1932985,
              "address_id": 623529,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1949253,
              "address_id": 623534,
              "check_in_date": "Jan 31, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987402,
              "address_id": 623538,
              "check_in_date": "Jan 14, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1971604,
              "address_id": 623548,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1964649,
              "address_id": 623552,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1974099,
              "address_id": 623555,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1984595,
              "address_id": 623558,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1975857,
              "address_id": 623559,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1961120,
              "address_id": 623560,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 5,
                  "total_children": 2
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1940084,
              "address_id": 623563,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1975567,
              "address_id": 623564,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1968093,
              "address_id": 623569,
              "check_in_date": "Mar 22, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1974050,
              "address_id": 624945,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1975180,
              "address_id": 627356,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1978054,
              "address_id": 644042,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Mar 27, 2025",
      "jobs": [
          {
              "id": 2113315,
              "date": "Mar 27, 2025",
              "address_id": 623529,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198520,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519334,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2113333,
              "date": "Mar 27, 2025",
              "address_id": 623529,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198521,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519352,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2146974,
              "date": "Mar 27, 2025",
              "address_id": 623530,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226255,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562286,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146961,
              "date": "Mar 27, 2025",
              "address_id": 623544,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226243,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562276,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146973,
              "date": "Mar 27, 2025",
              "address_id": 623545,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226254,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562285,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2126081,
              "date": "Mar 27, 2025",
              "address_id": 623548,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1209039,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2534290,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2126082,
              "date": "Mar 27, 2025",
              "address_id": 623548,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1209040,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2534291,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2131005,
              "date": "Mar 27, 2025",
              "address_id": 623555,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213103,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2539872,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2131006,
              "date": "Mar 27, 2025",
              "address_id": 623555,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213104,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2539873,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2146970,
              "date": "Mar 27, 2025",
              "address_id": 623556,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226251,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562282,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146976,
              "date": "Mar 27, 2025",
              "address_id": 623560,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226257,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562288,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146972,
              "date": "Mar 27, 2025",
              "address_id": 623563,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226253,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562284,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146977,
              "date": "Mar 27, 2025",
              "address_id": 623564,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226258,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562289,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2132332,
              "date": "Mar 27, 2025",
              "address_id": 623569,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214212,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2541449,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2132337,
              "date": "Mar 27, 2025",
              "address_id": 623569,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214211,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2541479,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2130884,
              "date": "Mar 27, 2025",
              "address_id": 624945,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213005,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2556541,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2130885,
              "date": "Mar 27, 2025",
              "address_id": 624945,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213006,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2539738,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          },
          {
              "id": 2137077,
              "date": "Mar 27, 2025",
              "address_id": 644042,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1218006,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2551107,
                      "homekeeper": {
                          "id": 230479,
                          "name": "Lunna Cleaning"
                      }
                  }
              ]
          },
          {
              "id": 2137078,
              "date": "Mar 27, 2025",
              "address_id": 644042,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1218007,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2551108,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2146975,
              "date": "Mar 27, 2025",
              "address_id": 644042,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226256,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562287,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2146971,
              "date": "Mar 27, 2025",
              "address_id": 650608,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226252,
                  "start_date_no_earlier_than": "03/27/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/27/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562283,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1932985,
              "address_id": 623529,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1971604,
              "address_id": 623548,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1974099,
              "address_id": 623555,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1968093,
              "address_id": 623569,
              "check_in_date": "Mar 22, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1974050,
              "address_id": 624945,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1978054,
              "address_id": 644042,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1926508,
              "address_id": 623529,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1958377,
              "address_id": 623530,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1962163,
              "address_id": 623555,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1965686,
              "address_id": 623569,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1979260,
              "address_id": 627355,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 2,
                  "total_children": null
              }
          },
          {
              "id": 1955333,
              "address_id": 644042,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1970046,
              "address_id": 650608,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1961122,
              "address_id": 623527,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 11,
                  "total_children": 4
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1949253,
              "address_id": 623534,
              "check_in_date": "Jan 31, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987402,
              "address_id": 623538,
              "check_in_date": "Jan 14, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1971458,
              "address_id": 623544,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1973121,
              "address_id": 623545,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1977155,
              "address_id": 623549,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 4,
                  "total_children": 0
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1964649,
              "address_id": 623552,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1984595,
              "address_id": 623558,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1975857,
              "address_id": 623559,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1961120,
              "address_id": 623560,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 5,
                  "total_children": 2
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1940084,
              "address_id": 623563,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1975567,
              "address_id": 623564,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1975180,
              "address_id": 627356,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1972282,
              "address_id": 631197,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Mar 28, 2025",
      "jobs": [
          {
              "id": 2113653,
              "date": "Mar 28, 2025",
              "address_id": 623527,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198721,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519566,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2113664,
              "date": "Mar 28, 2025",
              "address_id": 623527,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198720,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519571,
                      "homekeeper": {
                          "id": 230479,
                          "name": "Lunna Cleaning"
                      }
                  }
              ]
          },
          {
              "id": 2123351,
              "date": "Mar 28, 2025",
              "address_id": 623527,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206744,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530832,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2123360,
              "date": "Mar 28, 2025",
              "address_id": 623529,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206753,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530842,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2147366,
              "date": "Mar 28, 2025",
              "address_id": 623530,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226584,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562719,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2109369,
              "date": "Mar 28, 2025",
              "address_id": 623534,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1195341,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2515180,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2109414,
              "date": "Mar 28, 2025",
              "address_id": 623534,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1195377,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2515249,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2125617,
              "date": "Mar 28, 2025",
              "address_id": 623544,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1208665,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2533684,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2125618,
              "date": "Mar 28, 2025",
              "address_id": 623544,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1208666,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2533685,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2123347,
              "date": "Mar 28, 2025",
              "address_id": 623545,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206740,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530828,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2147369,
              "date": "Mar 28, 2025",
              "address_id": 623545,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226587,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562722,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2135166,
              "date": "Mar 28, 2025",
              "address_id": 623549,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216554,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544833,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2135167,
              "date": "Mar 28, 2025",
              "address_id": 623549,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216555,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544834,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2123332,
              "date": "Mar 28, 2025",
              "address_id": 623555,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206726,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530812,
                      "homekeeper": {
                          "id": 231039,
                          "name": "Brandon Scott"
                      }
                  }
              ]
          },
          {
              "id": 2123337,
              "date": "Mar 28, 2025",
              "address_id": 623556,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206731,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530818,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2144671,
              "date": "Mar 28, 2025",
              "address_id": 623558,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1224307,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2559440,
                      "homekeeper": {
                          "id": 231013,
                          "name": "Maria Mendoza"
                      }
                  }
              ]
          },
          {
              "id": 2144672,
              "date": "Mar 28, 2025",
              "address_id": 623558,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1224308,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2559441,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2133323,
              "date": "Mar 28, 2025",
              "address_id": 623559,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215017,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2542631,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2133324,
              "date": "Mar 28, 2025",
              "address_id": 623559,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215018,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2542632,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2113808,
              "date": "Mar 28, 2025",
              "address_id": 623560,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198770,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519607,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2114316,
              "date": "Mar 28, 2025",
              "address_id": 623560,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198771,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519961,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2123341,
              "date": "Mar 28, 2025",
              "address_id": 623560,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206735,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530822,
                      "homekeeper": {
                          "id": 244720,
                          "name": "Ignacio Ortez"
                      }
                  }
              ]
          },
          {
              "id": 2147367,
              "date": "Mar 28, 2025",
              "address_id": 623560,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226585,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562720,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2113239,
              "date": "Mar 28, 2025",
              "address_id": 623563,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198388,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519258,
                      "homekeeper": {
                          "id": 230479,
                          "name": "Lunna Cleaning"
                      }
                  }
              ]
          },
          {
              "id": 2123356,
              "date": "Mar 28, 2025",
              "address_id": 623563,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206749,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530838,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2113275,
              "date": "Mar 28, 2025",
              "address_id": 623563,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "12:30",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198392,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519294,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2132696,
              "date": "Mar 28, 2025",
              "address_id": 623564,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214524,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2541933,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2132697,
              "date": "Mar 28, 2025",
              "address_id": 623564,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214525,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2541935,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2147370,
              "date": "Mar 28, 2025",
              "address_id": 623564,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226588,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562723,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2147372,
              "date": "Mar 28, 2025",
              "address_id": 623569,
              "service_name": "Laundry Dropoff",
              "original_service_name": "Laundry Dropoff",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226589,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562726,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2139545,
              "date": "Mar 28, 2025",
              "address_id": 627355,
              "service_name": "4 hour cleaning",
              "original_service_name": "4 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "10:30",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1220028,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "10:30am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562450,
                      "homekeeper": {
                          "id": 244360,
                          "name": "Arif Yaqoobi"
                      }
                  }
              ]
          },
          {
              "id": 2139547,
              "date": "Mar 28, 2025",
              "address_id": 627355,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "10:30",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1220029,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "10:30am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2553756,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          },
          {
              "id": 2147119,
              "date": "Mar 28, 2025",
              "address_id": 627355,
              "service_name": "Hallway Cleaning",
              "original_service_name": "Hallway Cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226378,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "1:30pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562455,
                      "homekeeper": {
                          "id": 244360,
                          "name": "Arif Yaqoobi"
                      }
                  }
              ]
          },
          {
              "id": 2123364,
              "date": "Mar 28, 2025",
              "address_id": 644042,
              "service_name": "Pool cleaning tp",
              "original_service_name": "Pool cleaning tp",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206757,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530846,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2147368,
              "date": "Mar 28, 2025",
              "address_id": 644042,
              "service_name": "Trash In",
              "original_service_name": "Trash In",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226586,
                  "start_date_no_earlier_than": "03/28/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/28/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562721,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1961122,
              "address_id": 623527,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 11,
                  "total_children": 4
              }
          },
          {
              "id": 1949253,
              "address_id": 623534,
              "check_in_date": "Jan 31, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1971458,
              "address_id": 623544,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1977155,
              "address_id": 623549,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 4,
                  "total_children": 0
              }
          },
          {
              "id": 1984595,
              "address_id": 623558,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1975857,
              "address_id": 623559,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1961120,
              "address_id": 623560,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 5,
                  "total_children": 2
              }
          },
          {
              "id": 1940084,
              "address_id": 623563,
              "check_in_date": "Mar 24, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1975567,
              "address_id": 623564,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1979260,
              "address_id": 627355,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 28, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 2,
                  "total_children": null
              }
          },
          {
              "id": 1987026,
              "address_id": 623525,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1969303,
              "address_id": 623544,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1972039,
              "address_id": 623548,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 7,
                  "total_children": 2
              }
          },
          {
              "id": 1975518,
              "address_id": 623549,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 4,
                  "total_children": 1
              }
          },
          {
              "id": 1958858,
              "address_id": 623558,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962845,
              "address_id": 623560,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 11,
                  "total_children": 6
              }
          },
          {
              "id": 1949534,
              "address_id": 623564,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 14,
                  "total_guests": 14,
                  "total_children": 0
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1926508,
              "address_id": 623529,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1958377,
              "address_id": 623530,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987402,
              "address_id": 623538,
              "check_in_date": "Jan 14, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1973121,
              "address_id": 623545,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1964649,
              "address_id": 623552,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962163,
              "address_id": 623555,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1965686,
              "address_id": 623569,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1975180,
              "address_id": 627356,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1972282,
              "address_id": 631197,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1955333,
              "address_id": 644042,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1970046,
              "address_id": 650608,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Mar 29, 2025",
      "jobs": [
          {
              "id": 2109416,
              "date": "Mar 29, 2025",
              "address_id": 623534,
              "service_name": "Laundry Dropoff",
              "original_service_name": "Laundry Dropoff",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1195379,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2515251,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2146318,
              "date": "Mar 29, 2025",
              "address_id": 623538,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225706,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561424,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2146319,
              "date": "Mar 29, 2025",
              "address_id": 623538,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225707,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2561425,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2145454,
              "date": "Mar 29, 2025",
              "address_id": 624945,
              "service_name": "Hallway Cleaning",
              "original_service_name": "Hallway Cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1224988,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "1:30pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562447,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2127602,
              "date": "Mar 29, 2025",
              "address_id": 631197,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1210304,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562446,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2127603,
              "date": "Mar 29, 2025",
              "address_id": 631197,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1210305,
                  "start_date_no_earlier_than": "03/29/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/29/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2536205,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1987402,
              "address_id": 623538,
              "check_in_date": "Jan 14, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1972282,
              "address_id": 631197,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 29, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1966293,
              "address_id": 623559,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1927039,
              "address_id": 623563,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1973508,
              "address_id": 627355,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1978989,
              "address_id": 631197,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987026,
              "address_id": 623525,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1926508,
              "address_id": 623529,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1958377,
              "address_id": 623530,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1969303,
              "address_id": 623544,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1973121,
              "address_id": 623545,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1972039,
              "address_id": 623548,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 7,
                  "total_children": 2
              }
          },
          {
              "id": 1975518,
              "address_id": 623549,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 4,
                  "total_children": 1
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1964649,
              "address_id": 623552,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962163,
              "address_id": 623555,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1958858,
              "address_id": 623558,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962845,
              "address_id": 623560,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 11,
                  "total_children": 6
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1949534,
              "address_id": 623564,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 14,
                  "total_guests": 14,
                  "total_children": 0
              }
          },
          {
              "id": 1965686,
              "address_id": 623569,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1975180,
              "address_id": 627356,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1955333,
              "address_id": 644042,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1970046,
              "address_id": 650608,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Mar 30, 2025",
      "jobs": [
          {
              "id": 2146945,
              "date": "Mar 30, 2025",
              "address_id": 623525,
              "service_name": "1 hour cleaning",
              "original_service_name": "1 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1226228,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2562254,
                      "homekeeper": {
                          "id": 231984,
                          "name": "Alyssa Sanborn"
                      }
                  }
              ]
          },
          {
              "id": 2134592,
              "date": "Mar 30, 2025",
              "address_id": 623530,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216065,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544161,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2134593,
              "date": "Mar 30, 2025",
              "address_id": 623530,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216066,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544162,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2119952,
              "date": "Mar 30, 2025",
              "address_id": 623531,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1203884,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2526501,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2122400,
              "date": "Mar 30, 2025",
              "address_id": 623544,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1205909,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2529651,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2122401,
              "date": "Mar 30, 2025",
              "address_id": 623544,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1205910,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2529652,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2129037,
              "date": "Mar 30, 2025",
              "address_id": 623545,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1211474,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2537798,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2129038,
              "date": "Mar 30, 2025",
              "address_id": 623545,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1211475,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2537799,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2134088,
              "date": "Mar 30, 2025",
              "address_id": 623549,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215665,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2543558,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2134089,
              "date": "Mar 30, 2025",
              "address_id": 623549,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215666,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2543559,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2113156,
              "date": "Mar 30, 2025",
              "address_id": 623552,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198374,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519183,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2113190,
              "date": "Mar 30, 2025",
              "address_id": 623552,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198373,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519209,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2113594,
              "date": "Mar 30, 2025",
              "address_id": 623555,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198679,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519520,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2113612,
              "date": "Mar 30, 2025",
              "address_id": 623555,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "12:30",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198682,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519538,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2134591,
              "date": "Mar 30, 2025",
              "address_id": 623569,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216064,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544160,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2134607,
              "date": "Mar 30, 2025",
              "address_id": 623569,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216063,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544219,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2131953,
              "date": "Mar 30, 2025",
              "address_id": 627356,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213889,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2542772,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2131954,
              "date": "Mar 30, 2025",
              "address_id": 627356,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1213890,
                  "start_date_no_earlier_than": "03/30/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/30/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2541028,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1958377,
              "address_id": 623530,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1969303,
              "address_id": 623544,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1973121,
              "address_id": 623545,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1975518,
              "address_id": 623549,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 4,
                  "total_children": 1
              }
          },
          {
              "id": 1964649,
              "address_id": 623552,
              "check_in_date": "Mar 25, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1962163,
              "address_id": 623555,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1965686,
              "address_id": 623569,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1975180,
              "address_id": 627356,
              "check_in_date": "Mar 23, 2025",
              "check_out_date": "Mar 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987634,
              "address_id": 623545,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1959090,
              "address_id": 623552,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1987026,
              "address_id": 623525,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1926508,
              "address_id": 623529,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1972039,
              "address_id": 623548,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 7,
                  "total_children": 2
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1958858,
              "address_id": 623558,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1966293,
              "address_id": 623559,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1962845,
              "address_id": 623560,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 11,
                  "total_children": 6
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1927039,
              "address_id": 623563,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1949534,
              "address_id": 623564,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 14,
                  "total_guests": 14,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1973508,
              "address_id": 627355,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1978989,
              "address_id": 631197,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1955333,
              "address_id": 644042,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1970046,
              "address_id": 650608,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Mar 31, 2025",
      "jobs": [
          {
              "id": 2113316,
              "date": "Mar 31, 2025",
              "address_id": 623529,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198524,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519335,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2114318,
              "date": "Mar 31, 2025",
              "address_id": 623529,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198525,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519963,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2124156,
              "date": "Mar 31, 2025",
              "address_id": 623529,
              "service_name": "Pressure Washing",
              "original_service_name": "Pressure Washing",
              "service_category_id": 40,
              "service_category": "pressure_washing",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pressure_washing.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pressure_washing_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1207429,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2531810,
                      "homekeeper": {
                          "id": 247193,
                          "name": "Thierry Mamalis"
                      }
                  }
              ]
          },
          {
              "id": 2127128,
              "date": "Mar 31, 2025",
              "address_id": 623548,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1209912,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2535645,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2127129,
              "date": "Mar 31, 2025",
              "address_id": 623548,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1209913,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2535646,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2113874,
              "date": "Mar 31, 2025",
              "address_id": 623558,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198836,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519647,
                      "homekeeper": {
                          "id": 231013,
                          "name": "Maria Mendoza"
                      }
                  }
              ]
          },
          {
              "id": 2114320,
              "date": "Mar 31, 2025",
              "address_id": 623558,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198838,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519965,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2113809,
              "date": "Mar 31, 2025",
              "address_id": 623560,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198772,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519608,
                      "homekeeper": {
                          "id": 221879,
                          "name": "Aurelia Ili"
                      }
                  }
              ]
          },
          {
              "id": 2114319,
              "date": "Mar 31, 2025",
              "address_id": 623560,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198773,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519964,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2133074,
              "date": "Mar 31, 2025",
              "address_id": 623561,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214819,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2542357,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2133075,
              "date": "Mar 31, 2025",
              "address_id": 623561,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1214820,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2542358,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2113989,
              "date": "Mar 31, 2025",
              "address_id": 623564,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198880,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519687,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2114021,
              "date": "Mar 31, 2025",
              "address_id": 623564,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198881,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519719,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2119372,
              "date": "Mar 31, 2025",
              "address_id": 624945,
              "service_name": "Handyman",
              "original_service_name": "Handyman",
              "service_category_id": 75,
              "service_category": "handyman",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/handyman.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/handyman_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [
                  {
                      "key": "homekeeper_job_id-2525796",
                      "data": {
                          "job_id": 2119372,
                          "team_id": 231274,
                          "homekeeper_id": 234283,
                          "homekeeper_job_id": 2525796,
                          "customer_account_id": 543457
                      },
                      "type": "homekeeper_job",
                      "expires_at": null
                  }
              ],
              "is_job_request": false,
              "booking": {
                  "id": 1203385,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2525796,
                      "homekeeper": {
                          "id": 234283,
                          "name": "Erik Pedersen"
                      }
                  }
              ]
          },
          {
              "id": 2129539,
              "date": "Mar 31, 2025",
              "address_id": 627354,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1211893,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2556869,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2129540,
              "date": "Mar 31, 2025",
              "address_id": 627354,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1211895,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2538309,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          },
          {
              "id": 2119373,
              "date": "Mar 31, 2025",
              "address_id": 627355,
              "service_name": "Handyman",
              "original_service_name": "Handyman",
              "service_category_id": 75,
              "service_category": "handyman",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/handyman.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/handyman_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [
                  {
                      "key": "homekeeper_job_id-2525797",
                      "data": {
                          "job_id": 2119373,
                          "team_id": 231274,
                          "homekeeper_id": 234283,
                          "homekeeper_job_id": 2525797,
                          "customer_account_id": 543457
                      },
                      "type": "homekeeper_job",
                      "expires_at": null
                  }
              ],
              "is_job_request": false,
              "booking": {
                  "id": 1203386,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2525797,
                      "homekeeper": {
                          "id": 234283,
                          "name": "Erik Pedersen"
                      }
                  }
              ]
          },
          {
              "id": 2139020,
              "date": "Mar 31, 2025",
              "address_id": 631197,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1219594,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2556870,
                      "homekeeper": {
                          "id": 238245,
                          "name": "Jennifer Hume"
                      }
                  }
              ]
          },
          {
              "id": 2139021,
              "date": "Mar 31, 2025",
              "address_id": 631197,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "US/Eastern",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1219595,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2553110,
                      "homekeeper": {
                          "id": 234295,
                          "name": "Danish Shaik"
                      }
                  }
              ]
          },
          {
              "id": 2134588,
              "date": "Mar 31, 2025",
              "address_id": 644042,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216060,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544157,
                      "homekeeper": {
                          "id": 230479,
                          "name": "Lunna Cleaning"
                      }
                  }
              ]
          },
          {
              "id": 2134590,
              "date": "Mar 31, 2025",
              "address_id": 644042,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216062,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544159,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2133666,
              "date": "Mar 31, 2025",
              "address_id": 650608,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215334,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2543016,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2133673,
              "date": "Mar 31, 2025",
              "address_id": 650608,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1215333,
                  "start_date_no_earlier_than": "03/31/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "03/31/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2543021,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1926508,
              "address_id": 623529,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1972039,
              "address_id": 623548,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 7,
                  "total_children": 2
              }
          },
          {
              "id": 1977270,
              "address_id": 623551,
              "check_in_date": "Feb 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 1,
                  "total_children": null
              }
          },
          {
              "id": 1958858,
              "address_id": 623558,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962845,
              "address_id": 623560,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 11,
                  "total_children": 6
              }
          },
          {
              "id": 1975264,
              "address_id": 623561,
              "check_in_date": "Mar 26, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 6,
                  "total_children": 1
              }
          },
          {
              "id": 1949534,
              "address_id": 623564,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 14,
                  "total_guests": 14,
                  "total_children": 0
              }
          },
          {
              "id": 1968854,
              "address_id": 627354,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978989,
              "address_id": 631197,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1955333,
              "address_id": 644042,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1970046,
              "address_id": 650608,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Mar 31, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 8,
                  "total_guests": 8,
                  "total_children": 0
              }
          },
          {
              "id": 1971773,
              "address_id": 623527,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 5,
                  "total_children": 3
              }
          },
          {
              "id": 1977592,
              "address_id": 623534,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1964039,
              "address_id": 623544,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1972178,
              "address_id": 623548,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 5,
                  "total_children": 2
              }
          },
          {
              "id": 1935424,
              "address_id": 623549,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1966929,
              "address_id": 623560,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 10,
                  "total_children": 3
              }
          },
          {
              "id": 1971388,
              "address_id": 631197,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 10, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1972705,
              "address_id": 650608,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 7,
                  "total_children": 5
              }
          },
          {
              "id": 1987026,
              "address_id": 623525,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1987634,
              "address_id": 623545,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1959090,
              "address_id": 623552,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1966293,
              "address_id": 623559,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1927039,
              "address_id": 623563,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1973508,
              "address_id": 627355,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  },
  {
      "date": "Apr 01, 2025",
      "jobs": [
          {
              "id": 2136160,
              "date": "Apr 01, 2025",
              "address_id": 623525,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217293,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545841,
                      "homekeeper": {
                          "id": 244940,
                          "name": "Blueline Enterprises"
                      }
                  }
              ]
          },
          {
              "id": 2136119,
              "date": "Apr 01, 2025",
              "address_id": 623527,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217253,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545810,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2136126,
              "date": "Apr 01, 2025",
              "address_id": 623529,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217260,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545815,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2123232,
              "date": "Apr 01, 2025",
              "address_id": 623537,
              "service_name": "Laundry Dropoff",
              "original_service_name": "Laundry Dropoff",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1206636,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2530702,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2145575,
              "date": "Apr 01, 2025",
              "address_id": 623537,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225072,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2560545,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2145576,
              "date": "Apr 01, 2025",
              "address_id": 623537,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1225073,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2560546,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2136109,
              "date": "Apr 01, 2025",
              "address_id": 623544,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217243,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545800,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2136113,
              "date": "Apr 01, 2025",
              "address_id": 623548,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217247,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545806,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2136181,
              "date": "Apr 01, 2025",
              "address_id": 623548,
              "service_name": "Trash Service",
              "original_service_name": "Trash Service",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217309,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545862,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2136111,
              "date": "Apr 01, 2025",
              "address_id": 623551,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217245,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545802,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2136159,
              "date": "Apr 01, 2025",
              "address_id": 623551,
              "service_name": "Trash Out",
              "original_service_name": "Trash Out",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217292,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "11:30am"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545840,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2113158,
              "date": "Apr 01, 2025",
              "address_id": 623552,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198382,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519184,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2114127,
              "date": "Apr 01, 2025",
              "address_id": 623552,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198381,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519779,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2136493,
              "date": "Apr 01, 2025",
              "address_id": 623555,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217556,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2549704,
                      "homekeeper": {
                          "id": 244720,
                          "name": "Ignacio Ortez"
                      }
                  }
              ]
          },
          {
              "id": 2136173,
              "date": "Apr 01, 2025",
              "address_id": 623558,
              "service_name": "Trash Service",
              "original_service_name": "Trash Service",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217302,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545847,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2134585,
              "date": "Apr 01, 2025",
              "address_id": 623559,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216057,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544154,
                      "homekeeper": {
                          "id": 230477,
                          "name": "Harold Lesure"
                      }
                  }
              ]
          },
          {
              "id": 2134586,
              "date": "Apr 01, 2025",
              "address_id": 623559,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1216058,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2544155,
                      "homekeeper": {
                          "id": 231022,
                          "name": "Ricky Salazar"
                      }
                  }
              ]
          },
          {
              "id": 2136117,
              "date": "Apr 01, 2025",
              "address_id": 623560,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217251,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545809,
                      "homekeeper": {
                          "id": 244720,
                          "name": "Ignacio Ortez"
                      }
                  }
              ]
          },
          {
              "id": 2136115,
              "date": "Apr 01, 2025",
              "address_id": 623562,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217249,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545808,
                      "homekeeper": {
                          "id": 244720,
                          "name": "Ignacio Ortez"
                      }
                  }
              ]
          },
          {
              "id": 2136180,
              "date": "Apr 01, 2025",
              "address_id": 623562,
              "service_name": "Trash Service",
              "original_service_name": "Trash Service",
              "service_category_id": 108,
              "service_category": "trash_service",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/trash_service_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217308,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545861,
                      "homekeeper": {
                          "id": 244948,
                          "name": "Laki Siaki"
                      }
                  }
              ]
          },
          {
              "id": 2113240,
              "date": "Apr 01, 2025",
              "address_id": 623563,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198396,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519259,
                      "homekeeper": {
                          "id": 230479,
                          "name": "Lunna Cleaning"
                      }
                  }
              ]
          },
          {
              "id": 2114139,
              "date": "Apr 01, 2025",
              "address_id": 623563,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1198399,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2519788,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2136125,
              "date": "Apr 01, 2025",
              "address_id": 623563,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217259,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545814,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          },
          {
              "id": 2136114,
              "date": "Apr 01, 2025",
              "address_id": 623564,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217248,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545807,
                      "homekeeper": {
                          "id": 244720,
                          "name": "Ignacio Ortez"
                      }
                  }
              ]
          },
          {
              "id": 2142731,
              "date": "Apr 01, 2025",
              "address_id": 623572,
              "service_name": "2.5 hour cleaning",
              "original_service_name": "2.5 hour cleaning",
              "service_category_id": 1,
              "service_category": "regular_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/regular_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1222698,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2557384,
                      "homekeeper": {
                          "id": 230482,
                          "name": "SnF Cleaning LLC"
                      }
                  }
              ]
          },
          {
              "id": 2142732,
              "date": "Apr 01, 2025",
              "address_id": 623572,
              "service_name": "Laundry Pickup",
              "original_service_name": "Laundry Pickup",
              "service_category_id": 141,
              "service_category": "laundry",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/laundry_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1222699,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "4:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2557388,
                      "homekeeper": {
                          "id": 230478,
                          "name": "Lisa Daniels"
                      }
                  }
              ]
          },
          {
              "id": 2136128,
              "date": "Apr 01, 2025",
              "address_id": 644042,
              "service_name": "Pool Cleaning",
              "original_service_name": "Pool Cleaning",
              "service_category_id": 34,
              "service_category": "pool_cleaning",
              "service_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning.svg",
              "service_map_icon_url": "https://d30thx9uw6scot.cloudfront.net/svgs/pool_cleaning_map.svg",
              "start_time": "11:00",
              "timezone": "America/Los_Angeles",
              "is_private": true,
              "job_progress": "scheduled",
              "chat_rooms_with_unread_messages": [],
              "is_job_request": false,
              "booking": {
                  "id": 1217262,
                  "start_date_no_earlier_than": "04/01/2025",
                  "start_time_no_earlier_than": "11:00am",
                  "end_date_no_later_than": "04/01/2025",
                  "end_time_no_later_than": "12:00pm"
              },
              "homekeeper_jobs": [
                  {
                      "id": 2545817,
                      "homekeeper": {
                          "id": 230481,
                          "name": "Tiffany Humphrey"
                      }
                  }
              ]
          }
      ],
      "reservations": [
          {
              "id": 1986137,
              "address_id": 623537,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1959090,
              "address_id": 623552,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1966293,
              "address_id": 623559,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1927039,
              "address_id": 623563,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 13,
                  "total_guests": 13,
                  "total_children": 0
              }
          },
          {
              "id": 1981264,
              "address_id": 623572,
              "check_in_date": "Mar 01, 2025",
              "check_out_date": "Apr 01, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_out",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1963377,
              "address_id": 623541,
              "check_in_date": "Apr 01, 2025",
              "check_out_date": "Apr 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 2,
                  "total_children": null
              }
          },
          {
              "id": 1956736,
              "address_id": 623552,
              "check_in_date": "Apr 01, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 4,
                  "total_children": 0
              }
          },
          {
              "id": 1968334,
              "address_id": 623561,
              "check_in_date": "Apr 01, 2025",
              "check_out_date": "Apr 06, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1987630,
              "address_id": 623564,
              "check_in_date": "Apr 01, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1963479,
              "address_id": 623569,
              "check_in_date": "Apr 01, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "check_in",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 4,
                  "total_children": 0
              }
          },
          {
              "id": 1987026,
              "address_id": 623525,
              "check_in_date": "Mar 28, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 5,
                  "total_guests": 5,
                  "total_children": 0
              }
          },
          {
              "id": 1971773,
              "address_id": 623527,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 5,
                  "total_children": 3
              }
          },
          {
              "id": 1971655,
              "address_id": 623528,
              "check_in_date": "Jan 26, 2025",
              "check_out_date": "May 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1981260,
              "address_id": 623531,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 26, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 2,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1977592,
              "address_id": 623534,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 30, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978499,
              "address_id": 623535,
              "check_in_date": "Mar 09, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 6,
                  "total_children": 2
              }
          },
          {
              "id": 1964039,
              "address_id": 623544,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 7,
                  "total_children": 0
              }
          },
          {
              "id": 1987634,
              "address_id": 623545,
              "check_in_date": "Mar 30, 2025",
              "check_out_date": "Apr 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 12,
                  "total_guests": 12,
                  "total_children": 0
              }
          },
          {
              "id": 1972178,
              "address_id": 623548,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 5,
                  "total_children": 2
              }
          },
          {
              "id": 1935424,
              "address_id": 623549,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 1,
                  "total_guests": 1,
                  "total_children": 0
              }
          },
          {
              "id": 1934089,
              "address_id": 623554,
              "check_in_date": "Feb 23, 2025",
              "check_out_date": "Apr 27, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 10,
                  "total_guests": 10,
                  "total_children": 0
              }
          },
          {
              "id": 1962186,
              "address_id": 623556,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 4,
                  "total_guests": 7,
                  "total_children": 3
              }
          },
          {
              "id": 1966929,
              "address_id": 623560,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 03, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 7,
                  "total_guests": 10,
                  "total_children": 3
              }
          },
          {
              "id": 1927012,
              "address_id": 623562,
              "check_in_date": "Mar 18, 2025",
              "check_out_date": "Apr 08, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 6,
                  "total_guests": 6,
                  "total_children": 0
              }
          },
          {
              "id": 1955308,
              "address_id": 624945,
              "check_in_date": "Mar 27, 2025",
              "check_out_date": "Apr 05, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 2,
                  "total_children": 0
              }
          },
          {
              "id": 1978816,
              "address_id": 627213,
              "check_in_date": "Mar 15, 2025",
              "check_out_date": "May 19, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": null,
                  "total_adults": null,
                  "total_guests": 3,
                  "total_children": null
              }
          },
          {
              "id": 1973508,
              "address_id": 627355,
              "check_in_date": "Mar 29, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "10:30",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1971388,
              "address_id": 631197,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 10, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "US/Eastern",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 3,
                  "total_children": 1
              }
          },
          {
              "id": 1971121,
              "address_id": 633887,
              "check_in_date": "Feb 24, 2025",
              "check_out_date": "May 02, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 3,
                  "total_guests": 3,
                  "total_children": 0
              }
          },
          {
              "id": 1981141,
              "address_id": 649779,
              "check_in_date": "Mar 14, 2025",
              "check_out_date": "Apr 14, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 4,
                  "total_children": 2
              }
          },
          {
              "id": 1972705,
              "address_id": 650608,
              "check_in_date": "Mar 31, 2025",
              "check_out_date": "Apr 04, 2025",
              "check_in_time": "16:00",
              "check_out_time": "11:00",
              "origin_check_in_check_out_time": "automatic_booking_setting",
              "reservation_status": "stay",
              "timezone": "America/Los_Angeles",
              "details": {
                  "total_pets": 0,
                  "total_adults": 2,
                  "total_guests": 7,
                  "total_children": 5
              }
          }
      ],
      "availability_requests": [],
      "holiday": null
  }
]
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateTime as LuxonDateTime } from 'luxon';
import { Client } from 'src/providers/client/client';
import { Communication } from 'src/providers/communication/communication';
import { Concierge } from 'src/providers/concierge/concierge';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { Events } from 'src/providers/events/events';
import { Invoices } from 'src/providers/invoices/invoices';
import { WindowService } from 'src/shared/providers/window.service';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Loading } from 'src/shared/components/loading/loading';
import { DynamicComponentProvider } from 'src/shared/providers/dynamic-component/dynamic-component';
import { MapPopup } from './map-popup/map-popup';
import { Me } from 'src/providers/me/me';
import { popupOptions } from './map-popup/popup-opts';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { AddressModel } from 'src/models/address.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ReportSettingsPage } from 'src/pages/dashboard/report-settings/report-settings';
import { ConciergePage } from 'src/pages/concierge/concierge';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { UsersPage } from 'src/pages/users/users';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { Schedule } from 'src/providers/schedule/schedule';
import { Job } from 'src/models/schedule-weekly-view.model';
import { PastJobPage } from '../more/past-job/past-job';
import { PastPrivateJobPage } from '../more/past-private-job/past-private-job';
import { JobPage } from '../schedule/job/job';
import { Subscription } from 'rxjs';
import { PickYourGoalsComponent } from 'src/shared/components/pick-your-goals/pick-your-goals';
import { ModalController } from '@ionic/angular';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
declare const mapboxgl: any;
declare const google: any;

@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardPage implements OnInit, OnDestroy {

  addressIds = [];
  addresses: AddressModel[];
  conciergeItems: any;
  currentRows: any;
  clientName: string;
  errorMessage: string;
  hasPrivatePro: boolean;
  headers: any;
  houseIconIndex: number;
  pendingInvoices = [];
  isInvoicesLoaded: boolean;
  isRentalClient: boolean;
  @ViewChild('map', {static: false}) mapElement: ElementRef;
  messages: any;
  nudgeCards: any;
  routeReused: boolean;
  rows: any;
  report: any;
  reportId: string;
  standbyCount: number = 0;
  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  unresolvedLoggedIssues: any;
  mapInstance: any;
  isMapLoaded: boolean;
  isMessagesAndIssuesLoaded: boolean;
  isNudgeCardsLoaded: boolean;
  isTableLoaded: boolean;
  isConciergeItemsLoaded: boolean;
  loaded: boolean;
  showLoadingSpinner: boolean;
  language: string;
  panelClosedSubscription: Subscription = new Subscription();
  shownRowsCount: number = 10;
  showOnboarding: boolean;
  showOnboardingSubscription$: Subscription;
  onboardingFlow: string;

  constructor(
    private concierge: Concierge,
    public communication: Communication,
    private client: Client,
    private dashboard: Dashboard,
    private invoices: Invoices,
    private me: Me,
    private navCtrl: CustomNavController,
    private reportIssue: ReportIssue,
    private rightSidePanelService: RightSidePanelService,
    private dynamicComponentService: DynamicComponentProvider,
    private events: Events,
    public windowService: WindowService,
    private storage: TidyStorage,
    private util: Util,
    private schedule: Schedule,
    private modalController: ModalController,
    private onboardingProvider: OnboardingProvider
  ) {}

  async ngOnInit() {
    try {
      this.openPickYourGoals();
      this.loadPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async openPickYourGoals() {
    const goalsToPick = await this.onboardingProvider.getGoalsToPickList();
    await this.storage.save('goalsToPick', goalsToPick);
    const hideOnboarding = await this.storage.retrieve('hideOnboarding');
    const tidyToken = localStorage.getItem('tidy_token');
    const alreadyOpened = await this.modalController.getTop();
    const onboardingGoals = await this.storage.retrieve('onboardingGoals');
    if (onboardingGoals || hideOnboarding || !tidyToken || alreadyOpened) {
      return;
    }
    const modal = await this.modalController.create({
      component: PickYourGoalsComponent,
      cssClass: 'onboarding-modal',
      mode: 'ios',
      canDismiss: false,
    });
    await modal.present();
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    this.checkIfShouldShowOnboarding();
    this.loaded = true;
    this.language = localStorage.getItem('language');
    this.isMapLoaded = this.mapInstance ? true : false;
    if (!this.windowService.isDesktopRes) {
      // INFO: This is to prevent the map from not being loaded a second time on mobile
      this.isMapLoaded = false;
    }
    this.errorMessage = '';
    if (this.routeReused) {
      await Promise.all([
        this.loadUserInfo(),
        this.loadNudgeCards(),
        this.loadConciergeItems(),
        this.loadInvoices(),
        this.loadMessagesAndIssuesReports(),
        this.loadReports()
      ]);
    } else {
      this.loadUserInfo();
      this.loadNudgeCards();
      this.loadConciergeItems();
      this.loadInvoices();
      this.loadMessagesAndIssuesReports();
      this.loadReports();
    }
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/dashboard').then(async () => {
        this.routeReused = true;
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.onboardingFlow = await this.storage.retrieve('onboardingFlow');
    this.showOnboarding = this.onboardingFlow == 'upsell' && await this.onboardingProvider.checkIfShouldShowOnboarding(this.onboardingFlow);
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.watchShowOnboarding();
    }
    await this.storage.delete('onboardingFlow');
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  async loadReports(): Promise<void> {
    try {
      const reports = await this.dashboard.getDashboardSectionItems();
      const reportResponse = reports.find((reportItem) => reportItem?.name == 'Overview');
      this.reportId = reportResponse?.id;
      localStorage.setItem('reportId', this.reportId);

      const [addresses, report] = await Promise.all([
        this.client.getAddresses(),
        this.dashboard.getDashboardReportData(this.reportId)
      ]);
      this.addresses = addresses;
      this.report = report;
      this.loadTableAndBuildMap();
    } catch (error) {
      this.buildMap()
      this.handleLoadErrors(error);
    }
  }

  async loadUserInfo(): Promise<void> {
    try {
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      if (this.isRentalClient === null) {
        await this.me.load();
        this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      }
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async loadTableAndBuildMap(): Promise<void> {
    try {
      await this.loadTable();
      await this.buildMap();
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async loadMessagesAndIssuesReports(): Promise<void> {
    try {
      this.messages = await this.communication.getAllMessages();
      const loggedIssuesResponse = await this.reportIssue.getIssueReports();
      this.unresolvedLoggedIssues = loggedIssuesResponse.filter((item) => item?.status !== 'done');
      this.isMessagesAndIssuesLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async loadNudgeCards(): Promise<void> {
    try {
      const nudgeCards = await this.dashboard.getNudgeCards();
      this.nudgeCards = await this.getNudgeCards(nudgeCards);
      this.hasPrivatePro = !nudgeCards?.includes('existing_pros');
      localStorage.setItem('hasPrivatePro', this.hasPrivatePro ? 'true' : 'false');
      this.clientName = localStorage.getItem('customer_name');
      this.isNudgeCardsLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async loadConciergeItems(): Promise<void> {
    try {
      const { body } = await this.concierge.getConciergeItems();
      this.conciergeItems = body;
      this.isConciergeItemsLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async loadInvoices(): Promise<void> {
    try {
      const invoices = await this.invoices.getPendingInvoices();
      this.parseInvoices(invoices);
      this.isInvoicesLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('dashboard')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  parseInvoices(invoices) {
    this.pendingInvoices = [];
    invoices.map(invoice => {
      if (!invoice?.full_paid) {
        this.pendingInvoices.push(invoice);
      }
    });
  }

  parseReportFields(fields: any[]): any[] {
    fields.map((field) => {
      if (field.field_name === 'Next Job Date') {
        field.field_name = 'Next Job';
      }
      if (field.field_name === 'Last Completed Job Date') {
        field.field_name = 'Last Completed';
      }
      if (field.field_name === 'Maintenance Due Date') {
        field.field_name = 'Next Maintenance';
      }
    });
    return fields;
  }

  async loadTable() {
    try {
      this.isTableLoaded = false;
      const hiddenFields = [
        'address.address_2',
        'address.address_name',
        'address.city',
        'address.state',
        'address.zipcode',
        'address.country',
        'next_job.start_time',
        'last_completed_job.start_time',
        'next_job.status',
        'last_completed_job.status',
        'last_completed_job.service_type',
        'next_job.service_type',
        'maintenances.name'
      ];

      this.report.settings.fields = this.parseReportFields(this.report.settings.fields);
      this.rows = await this.buildRows(hiddenFields);
      console.log(this.rows)
      this.currentRows = this.rows.slice(0, 10);
      console.log(this.rows)

      this.headers = this.report.settings.fields
        .filter(field => !hiddenFields.includes(field.field_key) && field.field_name && field.is_field_visible)
        .map(field => field.field_name);
      this.headers.push('last-column');

      this.isTableLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  buildRows(hiddenFields: string[]) {
    if (!this.report || !this.report?.settings) {
      return;
    }
    const indexOfAddressIdField = this.report.settings.fields.findIndex(
      (field) => {
        return field.field_key === 'address.id';
      }
    );
    try {
      const rows = this.report.data.rows.map((row) => {
        const object: any = {};
        row.map((item, index) => {
          if (this.report.settings.fields?.[index]?.is_field_visible && !hiddenFields.includes(this.report.settings.fields[index].field_key)) {
            const fieldKey = this.report.settings.fields[index].field_key;
            object[this.report.settings.fields[index].field_name] = {
              value: this.getParsedRowName(item, row, index),
              action: this.addressActionCheck(
                fieldKey,
                indexOfAddressIdField,
                row
              ),
            };
          }
        });
        object['last-column'] = {
          value: '',
          action: null
        };
        return object;
      });
      return rows;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  getParsedRowName(item: any, row: any, index: number): string {
    const headers = this.report.data.header;
    const header = headers[index];
    if (header === 'Address') {
      return this.getParsedAddressName(row);
    }
    if (header === 'Next Job Date') {
      const nextJobType = row[this.getFieldIndex('next_job.service_type')];
      if (nextJobType) {
        return `${new TranslationPipe().transform(nextJobType)} (${this.getParsedRowDate(row, 'Next Job Date', 'Next Job Time')})`;
      }
      return this.getParsedRowDate(row, 'Next Job Date', 'Next Job Time');
    }
    if (header === 'Last Completed Job Date') {
      const lastCompletedJobType = row[this.getFieldIndex('last_completed_job.service_type')];
      if (lastCompletedJobType) {
        return `${new TranslationPipe().transform(lastCompletedJobType)} (${this.getParsedRowDate(row, 'Last Completed Job Date', 'Last Completed Job Time')})`;
      }
      return this.getParsedRowDate(row, 'Last Completed Job Date', 'Last Completed Job Time');
    }
    if (header === 'Maintenance Due Date') {
      const maintenanceName = row[this.getFieldIndex('maintenances.name')];
      if (maintenanceName) {
        return `${new TranslationPipe().transform(maintenanceName)} (${this.getParsedRowDate(row, 'Maintenance Due Date', 'Maintenance Due Time')})`;
      }
      return this.getParsedRowDate(row, 'Maintenance Due Date', 'Maintenance Due Time');
    }
    return item;
  }

  getParsedAddressName(row: any): string {
    const headers = this.report.data.header;
    const addressHeaderIndex = headers.findIndex((header) => header === 'Address');
    const unitHeaderIndex = headers.findIndex((header) => header === 'Unit');
    const addressNameHeaderIndex = headers.findIndex((header) => header === 'Address Name');
    const address = row[addressHeaderIndex];
    const unit = row[unitHeaderIndex];
    const addressName = row[addressNameHeaderIndex];
    return addressName ? addressName : `${address}${unit ? `, ${unit}` : ''}`;
  }

  getNextReservationDate(property) {
    const format = this.language && this.language !== 'en' ? 'MMMM d' : 'MMM d';
    let date = LuxonDateTime.fromFormat(property.nextReservationDate, 'yyyy-MM-dd').toFormat(format);
    if (this.language && this.language !== 'en') {
      const month = date.split(' ')[0]; 
      const day = date.split(' ')[1];
      return `${new TranslationPipe().transform(month).slice(0,3)} ${day}`;
    } else {
      return date;
    }
  }

  getParsedRowDate(row: any, dateHeader: string, timeHeader: string): string {
    const headers = this.report.data.header;
    const dateHeaderIndex = headers.findIndex((header) => header === dateHeader);
    const timeHeaderIndex = headers.findIndex((header) => header === timeHeader);
    const date = row?.[dateHeaderIndex];
    const time = row?.[timeHeaderIndex];
    const format = this.language && this.language !== 'en' ? 'MMMM d' : 'MMM d';
    if (date && time) {
      const dateTimeString = `${date} ${time}`;
      let dateTime = LuxonDateTime.fromFormat(dateTimeString, 'LLL d, yyyy HH:mm').toFormat(format + ' h:mm a');
      if (this.language && this.language !== 'en') {
        const parts = dateTime.split(' ');
        const month = parts[0];
        const rest = parts.slice(1).join(' ');
        return `${new TranslationPipe().transform(month).slice(0,3)} ${this.translateAMPM(rest)}`;
      }
      return this.translateAMPM(dateTime);
    }
    if (date && !time) {
      let dateTime = LuxonDateTime.fromFormat(date, 'LLL d, yyyy').toFormat(format);
      if (this.language && this.language !== 'en') {
        const month = dateTime.split(' ')[0];
        const day = dateTime.split(' ')[1];
        return `${new TranslationPipe().transform(month).slice(0,3)} ${this.translateAMPM(day)}`;
      }
      return this.translateAMPM(dateTime);
    }
    return '';
  }

  translateAMPM(dateTime) {
    return dateTime.replace('AM', new TranslationPipe().transform('am')).replace('PM', new TranslationPipe().transform('pm'));
  }

  checkIfAutomaticBookingEnabled(addressId) {
    const address = this.addresses.find(addressItem => addressItem.id == addressId);
    if (address?.address_automatic_booking_setting?.automatic_booking_enabled) {
      return 'enabled';
    } else {
      return 'disabled'
    }
  }

  getAutomaticBookingDetail(addressId, field) {
    const address = this.addresses.find(addressItem => addressItem.id == addressId);
    if (field == 'service') {
      return address?.address_automatic_booking_setting?.required_team_service?.name;
    }
    if (field == 'earliest_time') {
      return address?.address_automatic_booking_setting?.booking_start_time_no_earlier_than;
    }
    if (field == 'end_time') {
      return address?.address_automatic_booking_setting?.booking_end_time_no_later_than;
    }
  }

  addressActionCheck(fieldKey, indexOfAddressId, row) {
    if (fieldKey === 'address.address_1' && indexOfAddressId !== -1 && row[indexOfAddressId]) {
      return this.goToAddress.bind(this, row[indexOfAddressId]);
    }
    if (fieldKey.includes('last_completed_job')) {
      const rowIndex = this.report.settings.fields.find((item) => item.field_key === 'last_completed_job.id')?.field_order - 1;
      const lastCompletedJobId = row[rowIndex];
      return () => this.getJobCardAndJobUrl(row[indexOfAddressId], lastCompletedJobId, true);
    }
    if (fieldKey.includes('next_job')) {
      const rowIndex = this.report.settings.fields.find((item) => item.field_key === 'next_job.id')?.field_order - 1;
      const nextJobId = row[rowIndex];
      return () => this.getJobCardAndJobUrl(row[indexOfAddressId], nextJobId, false);
    }
    if (fieldKey.includes('maintenances')) {
      return this.goToAddress.bind(this, row[indexOfAddressId]);
    }
    return null;
  }

  async getJobCardAndJobUrl(addressId, jobId, isPastJob) {
    if (!jobId) {
      return null;
    }
    const job = await this.schedule.getJobCard(addressId, jobId);
    this.goToCleaningDetail(job.template_data, addressId, isPastJob);
  }

  @Loading('', true)
  async goToCleaningDetail(cleaning: any, addressId: number, isPastJob: boolean) {
    try {
      const isMobileResolution = window.innerWidth <= 870;
      const isPrivateJob = cleaning.isPrivate;
      cleaning.job.booking = cleaning.booking;
      if (!isMobileResolution) {
        this.navigateToJobPageInsideDialog(cleaning.job, addressId, isPastJob, isPrivateJob);
        return;
      }
      localStorage.setItem('pastJobBackPage', 'dashboard');
      if (isPastJob) {
        if (isPrivateJob) {
          this.navCtrl.navigateForward([`past-private-job/${cleaning.job.id}`]);
        } else {
          this.navCtrl.navigateForward([`past-job/${cleaning.job.id}`]);
        }
      } else {
        this.navCtrl.navigateForward([`job/${addressId}/${cleaning.job.id}`], { object: cleaning.job });
      }
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  navigateToJobPageInsideDialog(cleaning: Job, addressId: number, isPastJob: boolean, isPrivateJob: boolean): void {
    const params = {
      jobId: cleaning.id,
      addressId: addressId,
      bookingId: cleaning.booking.id,
      ...cleaning,
    }
    this.storage.save('dialog-params', params);
    this.storage.save('dialog-right-side-open', true);
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.openRightSidePanel(PastPrivateJobPage);
    } else if (isPastJob) {
      this.rightSidePanelService.openRightSidePanel(PastJobPage);
    } else {
      this.rightSidePanelService.openRightSidePanel(JobPage);
    }
  }

  goToEditAddressPage(rowIndex) {
    this.report.data.rows.map((column, i) => {
      if (i == rowIndex) {
        const addressId = column[19];
        this.goToAddress(addressId);
      }
    });
  }

  goToAddress(addressId) {
    localStorage.setItem('addressId', addressId);
    this.storage.save('rootSection', '/more');
    this.events.publish('updateMenu');
    this.navCtrl.navigateForward(`edit-property/${addressId}`);
  }

  goToAutomaticBooking(addressId) {
    localStorage.setItem('automaticBookingAddressBackPage', 'dashboard');
    const params = {
      addressId: addressId
    }
    this.goToPage(`automatic-booking-property/${addressId}`, params, AutomaticBookingAddressPage);
  }

  async buildMap() {
    try {
      if (!this.mapElement) {
        return setTimeout(() => this.buildMap(), 0);
      }
      const events = await this.dashboard.getMapEvents();
      if (!this.isMapLoaded) {
        this.mapInstance = this.createMap();
      }
      const addressLocations = this.getAddressLocations();
      this.addEventsToMap(events, this.mapInstance);
      this.addAddressLocationsToMap(addressLocations, this.mapInstance);
      if (!addressLocations.length) {
        await this.extendBoundsToShowAllAddresses(this.mapInstance, events);
        this.isMapLoaded = true;
        return;
      }
      this.setMapBounds(this.mapInstance, addressLocations, events);
      this.isMapLoaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
      if (this.mapInstance) {
        this.mapInstance.setCenter([-118.2437, 34.0522]);
        this.mapInstance.setZoom(10);
      }
    }
  }

  setMapBounds(map, addressLocations, events) {
    const bounds = new mapboxgl.LngLatBounds();
    addressLocations.forEach((location) => {
      bounds.extend([location.longitude, location.latitude]);
    });
    events.forEach((event) => {
      bounds.extend([event.longitude, event.latitude]);
    });
    map.fitBounds(bounds, { padding: 50, duration: 0 });
  }

  async extendBoundsToShowAllAddresses(map, events) {
    try {
      const bounds = new mapboxgl.LngLatBounds();
      const addresses = await this.client.getAddresses();
      addresses.map((address) => {
        bounds.extend([address.longitude, address.latitude]);
      });
      events.map((event) => {
        bounds.extend([event.longitude, event.latitude]);
      });
      map.fitBounds(bounds, { padding: 50, duration: 0 });
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  createMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrdHVoNHc1bTIwYjYycHE1NmhjdzVtdzMifQ.OIqKrdlDh4fJH0g0MO1zLg';
    return new mapboxgl.Map({
      container: `map${this.reportId}`,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      maxZoom: 11,
    });
  }

  getAddressLocations() {
    const addressLocations: any = [];
    const latitudeRowIndex = this.getFieldIndex('address.latitude');
    const longitudeRowIndex = this.getFieldIndex('address.longitude');
    const addressIdIndex = this.getFieldIndex('address.id');
    const nextJobStatusIndex = this.getFieldIndex('next_job.status');
    this.report.data.rows.filter((item, index) => index < 50).map((row, rowIndex) => {
      row.map((item, itemIndex) => {
        if (itemIndex == 0) {
          addressLocations.push({
            latitude: '',
            longitude: '',
            address: '',
          });
        }
        switch (itemIndex) {
          case 0:
            addressLocations[rowIndex].address = item;
            break;
          case latitudeRowIndex:
            addressLocations[rowIndex].latitude = item;
            break;
          case longitudeRowIndex:
            addressLocations[rowIndex].longitude = item;
            break;
          case addressIdIndex:
            addressLocations[rowIndex].addressId = item;
            break;
          case nextJobStatusIndex:
            addressLocations[rowIndex].nextJobStatus = item;
            break;
        }
      });
    });
    return addressLocations;
  }

  getFieldIndex(fieldKey) {
    const field = this.report.settings.fields.find (fieldItem => fieldItem.field_key == fieldKey);
    return this.report.settings.fields.indexOf(field);
  }

  addAddressLocationsToMap(addressLocations, map) {
    let houseIndex = 1;
    addressLocations.forEach((location, i) => {
      const popupContent = this.dynamicComponentService.injectComponent(
        MapPopup, x => x.data = {
          image: 'assets/img/house.svg',
          header: location.address,
          addressId: location.addressId,
          nextJobStatus: location.nextJobStatus
        }
      );
      const popupOpts = {
        offset: {
          'bottom': [0, -22]
        }
      }
      this.createPopupAndMarker(map, popupContent, this.dashboard.getHouseImage(houseIndex), location, true, popupOpts);
      if (houseIndex == 10) {
        houseIndex = 1;
      } else {
        houseIndex += 1;
      }
    });
  }

  getHouseIcon(index) {
    if (index == 10) {
      this.houseIconIndex = 1;
    } else {
      this.houseIconIndex = index;
    }
    return this.dashboard.getHouseImage(this.houseIconIndex);
  }

  addEventsToMap(events, map) {
    events.forEach((event, i) => {
      const data = this.getEventData(event, i);
      if (data !== null) {
        const popupContent = this.dynamicComponentService.injectComponent(
          MapPopup, x => x.data = {
            image: data.popupImage,
            body: data?.body || '',
            header: data.header,
            learnMore: data.learnMore
          }
        );
        this.createPopupAndMarker(map, popupContent, data.mapIcon, event);
      }
    });
  }

  createPopupAndMarker(map, popupContent, mapIcon, location, isAddressLocation = false, customOpts = null) {
    const popup = new mapboxgl.Popup({...popupOptions, ...customOpts}).setDOMContent(popupContent);
    const isJobOpportunity = true;
    if (location.nextJobStatus == 'job_request') {
      const lottiePlayerEl = document.createElement('div');
      lottiePlayerEl.innerHTML = `<lottie-player src="https://assets1.lottiefiles.com/packages/lf20_yw0d2riu.json"  background="transparent"  speed="0.5"  style="width: 150px; height: 150px;"  loop  autoplay></lottie-player>`;
      this.createMarkerInstance({map, el: lottiePlayerEl, longLat: [ location.longitude, location.latitude ]}, popup);
    }
    const el = document.createElement('div');
    el.className = 'marker';
    if (isAddressLocation) {
      el.innerHTML = `<image src="${mapIcon}" style="width: 40px; height: 40px; padding-top: 10px;"></image>`;
    } else {
      el.innerHTML = `<image src="${mapIcon}" style="width: 30px; height: 30px;"></image>`;
    }
    this.createMarkerInstance({map, el, longLat: [ location.longitude, location.latitude ]}, popup);
  }

  createMarkerInstance(obj, popup) {
    const {el, longLat, map} = obj;
    const marker = new mapboxgl.Marker(el);

    marker.getElement().addEventListener('click', () => {

      const flyToCoords = [...longLat];
      const getZoom = map.getZoom() ;
      const zoomOut = getZoom < 0;

      const lgnSum = zoomOut ? 200 / (5 * getZoom) : 50 / (2 ** getZoom);

      if (flyToCoords[1] + lgnSum > 90) {
        flyToCoords[1] = 90;
      } else if ((flyToCoords[1] + lgnSum < -90)) {
        flyToCoords[1] = -90;
      } else {
        flyToCoords[1] += lgnSum;
      }

      map.flyTo({
        center: flyToCoords
      })
    });

    marker.setLngLat(longLat).setPopup(popup).addTo(map);
  }

  getEventData(event, i) {
    const categories = {
      'Regular Cleaning': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Pool Cleaning': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Carpet Cleaning': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Window Washing': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Lawn & Garden Care': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Junk Removal': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Pest Control': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Pressure Washing': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Tree Trimming': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Inspection': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Trash Service': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Handyman': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Laundry Service': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Locksmith': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Painting': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Grill Maintenance': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Biohazard Cleaning': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Plumbing': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      },
      'Appliance Repair': {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      }
    }
    if (event.event.name == 'samantha.job.created') {
      const category: any = categories[event.event.data.service_category] || {
        mapIcon: event.event.data.service_map_icon_url,
        popupImage: event.event.data.service_icon_url
      };
      category['body'] = 'Recently completed near you.';
      category['header'] = this.titleCase(event.event.data.service_name);
      category['learnMore'] = 'https://help.tidy.com/dashboard#nn-overview-report';
      return category;
    } else if (event.event.name == 'samantha.emergency_cleaning_request.standby.created') {
      this.standbyCount += 1;
      return {
        mapIcon: this.getStandbyImage(),
        body: 'This Pro is on standby to accept jobs in your area.',
        header: 'Standby',
        popupImage: 'assets/img/standby-popup.svg',
        learnMore: 'https://help.tidy.com/dashboard#nn-overview-report'
      };
    } else {
      return null;
    }
  }

  titleCase(name) {
    return name.split(' ')
       .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
       .join(' ');
   }

  getStandbyImage() {
    if (this.standbyCount % 3 == 0) {
      return 'assets/img/car-up-left.svg';
    } else if (this.standbyCount % 2 == 0) {
      return 'assets/img/car-right.svg';
    } else {
      return 'assets/img/car-down.svg';
    }
  }

  goToReportSettings() {
    localStorage.setItem('cameFromReportAdded', 'false');
    const params = {
      report: this.report
    }
    this.goToPage(`dashboard/report-settings/${this.report.id}`, params, ReportSettingsPage);
    this.panelClosedSubscription = this.rightSidePanelService.afterPanelClosed().subscribe(() => {
      this.loadReports();
      this.panelClosedSubscription.unsubscribe();
    });
  }

  @Loading('', true)
  async sortChanged(sort) {
    try {
      const sortField = this.report?.settings?.fields.find(field => {
        return field.field_name === sort.active;
      });
      const data = {
        sort_by_field_keys: [{
          field_key: sortField.field_key,
          sort: sort.direction
        }]
      };
      await this.dashboard.updateDashboardReport(data, this.report.id);
      this.report = await this.dashboard.getDashboardReportData(this.reportId);
      await this.loadTable();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

  navigateToPage(page) {
    if (page == 'schedule') {
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      page = shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list';
    }
    if (page == 'rental-demo') {
      return this.util.openUrl('https://www.tidy.com/get-demo');
    }
    if (page == 'users') {
      return this.goToPage('users', {}, UsersPage);
    }
    this.navCtrl.navigateForward(page);
  }

  async getNudgeCards(nudgeCards) {
    const editAddressDestination = this.report?.data?.rows?.length ? `edit-property/${this.report.data.rows[0][19]}` : 'addresses';
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const cards = {
      schedule: {
        header: 'Schedule',
        body: 'Schedule a cleaning with a certified Pro in 1 minute.',
        icon: 'assets/img/book-job.svg',
        iconWidth: '25px',
        destination: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list',
        showCard: true
      },
      to_do_list: {
        header: 'Create a To-Do List',
        body: 'Create a digital To-Do list to share with any Pro. (Free)',
        icon: 'assets/img/to-dos.svg',
        iconWidth: '30px',
        destination: this.windowService.isDesktopRes ? 'all-to-do-lists' : 'to-dos',
        showCard: true
      },
      existing_pros: {
        header: 'Add Existing Pro',
        body: 'Add an existing Pro you work with to automate requests with them. (Free)',
        icon: 'assets/img/user-red.svg',
        iconWidth: '25px',
        destination: this.isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows',
        showCard: true
      },
      map: {
        header: 'Get an Animated Map',
        body: 'Get a free map of your property.',
        icon: 'assets/img/house-sketch.svg',
        iconWidth: '25px',
        destination: editAddressDestination,
        showCard: true
      },
      customer_members: {
        header: 'Users',
        body: 'Add a user so they can log into your account with their own credentials. As the team leader, you can revoke access at any time.',
        icon: 'assets/img/team.svg',
        iconWidth: '25px',
        destination: 'users',
        showCard: true
      }
    };
    const maintenance = {
      header: 'Get a Maintenance Plan',
      body: 'It\'s free! Just scan your home to get a personalized maintenance plan.',
      icon: 'assets/img/tool-box.svg',
      iconWidth: '25px',
      destination: editAddressDestination,
      showCard: true
    };
    const integrations = {
      header: 'Integrate',
      body: 'Connect Airbnb & other channels to automatically book turnovers. (Free)',
      icon: 'assets/img/integrate.svg',
      iconWidth: '40px',
      destination: 'integrations/all',
      showCard: true
    };
    const array: any = [];
    const isJobReport = this.report?.settings?.default_date_key == 'jobs.date';
    const hasNoJobItems = !this.report?.data?.rows?.length;
    if (isJobReport && hasNoJobItems) {
      const schedule = {
        header: 'Schedule',
        body: 'Schedule a cleaning with a certified Pro in 1 minute.',
        icon: 'assets/img/book-job.svg',
        iconWidth: '25px',
        destination: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list',
        showCard: true
      };
      array.push(schedule);
      return array;
    }
    const isMaintenanceReport = this.report?.settings?.default_date_key == 'maintenances.date';
    const hasNoMaintenanceItems = !this.report?.data?.rows?.length;
    const backendSaysShowMaintenance = nudgeCards.includes('maintenance');
    if (isMaintenanceReport && hasNoMaintenanceItems && backendSaysShowMaintenance) {
      array.push(maintenance);
      return array;
    }
    const isReservationReport = this.report?.settings?.default_date_key == 'guest_reservation.check_in_date';
    const hasNoReservationItems = !this.report?.data?.rows?.length;
    const backendSaysShowIntegration = nudgeCards.includes('integrations');
    if (isReservationReport && hasNoReservationItems && backendSaysShowIntegration) {
      array.push(integrations);
      return array;
    }
    nudgeCards.map((card) => {
      if (card !== 'maintenance' && card !== 'integrations') {
        array.push(cards[card]);
      }
    });
    const cameFromMaintenanceSelfBooking = localStorage.getItem('cameFromMaintenanceSelfBooking');
    if (cameFromMaintenanceSelfBooking && backendSaysShowMaintenance) {
      array.unshift(maintenance);
    } else if (backendSaysShowMaintenance) {
      array.push(maintenance);
    }
    const cameFromRentalSelfBooking = localStorage.getItem('cameFromRentalSelfBooking');
    const backendSaysShowIntegrations = nudgeCards.includes('integrations');
    if (cameFromRentalSelfBooking && backendSaysShowIntegrations) {
      array.unshift(integrations);
    } else if (backendSaysShowIntegrations) {
      array.push(integrations);
    }
    if (array?.length > 0) {
      array.push({
        header: 'Get a Demo',
        body: 'The TIDY Concierge can help make sure you have everything set up right.',
        icon: 'assets/img/calendar.svg',
        iconWidth: '30px',
        destination: 'rental-demo',
        showCard: true
      });
    }
    return array;
  }

  goToConciergePage() {
    localStorage.setItem('isAllAddressesSelected', 'true');
    this.goToPage('concierge', {}, ConciergePage);
  }

  goToBillsPage() {
    localStorage.setItem('isAllAddressesSelected', 'true');
    this.navCtrl.navigateForward('bills');
  }

  goToMessagesPage() {
    localStorage.setItem('isAllAddressesSelected', 'true');
    const params = {
      messages: this.messages
    }
    this.navCtrl.navigateForward('messages');
  }

  getMessageDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relative = LuxonDateTime.fromISO(date).setZone(timezone).toRelative();
    if (!relative) return '';
    const matches = relative.match(/^(\d+)\s+(\w+)\s+ago$/);
    if (matches) {
      const [_, number, unit] = matches;
      return `${number} ${new TranslationPipe().transform(unit)} ${new TranslationPipe().transform('ago')}`;
    }
    return relative;
  }

  getRelativeDateFromISO(date, addressId) {
    const address = this.addresses?.find((address) => address?.id == addressId);
    if (!address) return '';
    const relative = LuxonDateTime.fromISO(date, {zone: 'utc'}).setZone(address?.timezone).toRelative();
    if (!relative) return '';
    const matches = relative.match(/^(\d+)\s+(\w+)\s+ago$/);
    if (matches) {
      const [_, number, unit] = matches;
      return `${number} ${new TranslationPipe().transform(unit)} ${new TranslationPipe().transform('ago')}`;
    }
    return relative;
  }

  getLoggedIssueAddress(addressId) {
    const address = this.addresses?.find((address) => address?.id == addressId);
    return address ? (address?.address1 + (address?.address2 ? (' ' + address?.address2) : '')) : '';
  }

  goToLoggedIssuesPage() {
    localStorage.setItem('isAllAddressesSelected', 'true');
    localStorage.setItem('loggedIssuesBackPage', 'dashboard');
    this.navCtrl.navigateForward('tasks');
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  pageChange() {
    this.shownRowsCount += 10;
    this.currentRows = this.rows.slice(0, this.shownRowsCount);
  }

  ionViewDidLeave() {
    this.mapInstance?.remove();
  }

  ngOnDestroy() {
    this.mapInstance?.remove();
    this.panelClosedSubscription.unsubscribe();
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }
}

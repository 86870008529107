import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ToDoListModel, RoomModel, ToDoListRoomModel } from 'src/models/to-do.model';
import { Aws } from '../aws/aws';
import { DateTime as LuxonDateTime } from 'luxon';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class ToDos {

  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage,
    private aws: Aws,
  ) {}

  getStorageIds() {
    return {
      addressId: localStorage.getItem('addressId'),
      taskListId: localStorage.getItem('taskListId'),
      roomId: localStorage.getItem('roomId'),
    }
  }

  getAllToDoLists(perPage, pageIndex, addressIds = null) {
    let url = `api/v1/customer/task-lists?per_page=${perPage}&page=${pageIndex}`;
    if (addressIds) {
      url += `&filters[address_ids]=${addressIds}`;
    }
    return this.httpClient.getFullRequest(url)
    .then(response => {
      return {
        body: response.body,
        totalRecords: response.headers.get('X-Total-Records')
      };
    });
  }

  getAllIsCustomerToDoLists() {
    const url = 'api/v1/customer/task-lists?filters[is_customer_list]=true&per_page=1000';
    return this.httpClient.get(url);
  }

  async getAllToDoListsForAddress(addressId) {
    const url = `api/v1/customer/task-lists?filters[address_ids]=${addressId}&per_page=1000`;
    const response = await this.httpClient.get(url);
    response.map((list) => {
      list.default_list_settings.map((setting) => {
        if (setting.key.includes('default_list.address')) {
          list['is_default'] = true;
        }
      });
    });
    return response;
  }

  async getAllToDoListsForAddressAndServiceDefaults(addressId, serviceKey) {
    const addressUrl = `api/v1/customer/task-lists?filters[address_ids]=${addressId}`;
    const addressLists = await this.httpClient.get(addressUrl);
    const serviceDefaultsUrl = `api/v1/customer/task-lists?filters[is_customer_list]=true`;
    let serviceDefaultLists = await this.httpClient.get(serviceDefaultsUrl);
    serviceDefaultLists = serviceDefaultLists.filter((list) => {
      const listIsAlreadyInAddress = addressLists.find((addressList) => addressList.id == list.id);
      if (!listIsAlreadyInAddress && this.isServiceDefault(list, serviceKey)) {
        return list;
      }
    });
    return [
      ...addressLists,
      ...serviceDefaultLists
    ];
  }

  isServiceDefault(list, serviceKey) {
    let isServiceDefault = false;
    list.default_list_settings.map((setting) => {
      if (setting.key.includes(serviceKey)) {
        isServiceDefault = true;
      }
    });
    return isServiceDefault;
  }

  getAppliedJobs(addressTaskListId) {
    const url = `api/v1/customer/task-lists/${addressTaskListId}/applied-jobs`;
    return this.httpClient.get(url);
  }

  async getToDosForPastJob(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/rooms`;
    return await this.httpClient.get(url);
  }

  parseToDoFilterItems(lists, addressId): TidySelectNumberValueModel[] {
    let parsedLists = [];
    if (lists.length > 0) {
      lists.map((list) => {
        parsedLists.push({
          viewValue: list.title,
          value: list.id
        });
      });
      parsedLists.push({
        viewValue: '+ Add List',
        value: -1
      });
    }
    return parsedLists;
  }

  getSelectedTaskListId(toDoFilter): number {
    let taskListId;
    const storageTaskListId = localStorage.getItem('taskListId');
    if (storageTaskListId !== 'null' && storageTaskListId !== null && storageTaskListId !== '-1' && storageTaskListId !== '-2') {
      taskListId = parseFloat(storageTaskListId);
    } else {
      taskListId = toDoFilter[0].value;
    }
    localStorage.setItem('taskListId', taskListId);
    return taskListId;
  }

  createList(addressId, title: string, beforeAfterPhotoState: string) {
    const data = {
      title: title,
      before_after_photos_state: beforeAfterPhotoState
    }
    const url = `api/v1/customer/addresses/${addressId}/task-lists`;
    return this.httpClient.post(url, data);
  }

  deleteList(addressId, addressTaskListId) {
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${addressTaskListId}`;
    return this.httpClient.delete(url);
  }

  updateListTitle(addressId, addressTaskListId, title: string) {
    const data = {
      title: title
    }
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${addressTaskListId}`;
    return this.httpClient.put(url, data);
  }

  updateListBeforeAfterPhotos(addressId, addressTaskListId, beforeAfterPhotoState: string) {
    const data = {
      before_after_photos_state: beforeAfterPhotoState
    }
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${addressTaskListId}`;
    return this.httpClient.put(url, data);
  }

  deleteRoom() {
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/rooms/${id.roomId}/remove-from-task-list`
    return this.httpClient.post(url, {});
  }

  deleteGroup(addressTaskGroupId, addressTaskListId) {
    const url = `api/v1/customer/address-task-groups/${addressTaskGroupId}/remove-from-task-list`;

    const data = {
      address_task_list_id: addressTaskListId
    }
    return this.httpClient.post(url, data);
  }

  markRoomAsDoNotClean() {
    const id = this.getStorageIds();
    const data = {
      address_room_ids: [
        id.roomId
      ]
    };
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/do-not-clean-rooms`;
    return this.httpClient.post(url, data);
  }

  markRoomAsDoClean(doNotCleanRoomId) {
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/do-not-clean-rooms/${doNotCleanRoomId}/inactivate`;
    return this.httpClient.put(url, {});
  }

  deleteToDo(taskId) {
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/tasks/${taskId}`;
    return this.httpClient.delete(url);
  }

  deleteDoNot(noteId) {
    const id = this.getStorageIds();
    const url = `api/v1/instructions/addresses/${id.addressId}/address_rooms/${id.roomId}/room-notes/${noteId}`;
    return this.httpClient.delete(url);
  }

  addGroup(data) {
    const url = 'api/v1/customer/address-task-groups';
    return this.httpClient.post(url, data);
  }

  updateGroup(addressTaskGroupId, payload) {
    const url = `api/v1/customer/address-task-groups/${addressTaskGroupId}/add-to-task-list`;
    return this.httpClient.post(url, payload);
  }

  updateMultipleGroups(addressId, taskListId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${taskListId}/set-task-groups`;
    return this.httpClient.put(url, payload);
  }

  updateGroupName(addressTaskGroupId, payload) {
    const url = `api/v1/customer/address-task-groups/${addressTaskGroupId}`;
    return this.httpClient.put(url, payload);
  }

  updateListToBeCustomerList(addressId, taskListId, customerListPayload) {
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${taskListId}`;
    return this.httpClient.put(url, customerListPayload)
  }

  addRoom({name, floor, category}) {
    const data = {
      address_room : {
        name: name,
        floor: floor,
        category: category
      }
    }
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/rooms`;
    return this.httpClient.post(url, data);
  }

  updateRoom(data) {
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/rooms/${id.roomId}`;
    return this.httpClient.put(url, data);
  }

  assignListToJob(jobIds, addressTaskListId) {
    const data = {
      job_ids: jobIds,
      address_task_list_id: addressTaskListId
    }
    const url = `api/v1/customer/cleanings/assign-address-task-list-jobs`;
    return this.httpClient.put(url, data);
  }

  updateDefaultList(addressId, addressTaskListId) {
    const data = {
      address_task_list_id: addressTaskListId
    }
    const url = `api/v1/customer/addresses/${addressId}/assign-default-address-task-list`;
    return this.httpClient.put(url, data);
  }

  saveTaskListSettings(taskListId, payload: {settings: {key: string, value: any}[]}) {
    const url = `api/v1/customer/task-lists/${taskListId}/settings`;
    return this.httpClient.put(url, payload);
  }

  saveDefaultList(taskListId, payload) {
    return this.saveTaskListSettings(taskListId, payload);
  }

  saveBlockCompleteOnMissingFields(taskListId, value: boolean) {
    const payload = {
      settings: [{
        key: `block_complete_on_missing_fields`,
        value
      }]
    };
    return this.saveTaskListSettings(taskListId, payload);
  }

  updateRoomPriority(addressId, addressTaskListId, orderedRoomIds) {
    const data = {
      address_id: addressId,
      address_task_list_id: addressTaskListId,
      address_room_ids_in_order: orderedRoomIds
    };
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${addressTaskListId}/set-room-display-order`;
    return this.httpClient.put(url, data);
  }

  getListDetail(addressTaskListId): Promise<RoomModel[]> {
    const url = `api/v1/customer/task-lists/${addressTaskListId}/tasks?hide_do_not_clean_rooms=false`;
    return this.httpClient.get(url);
  }

  addToDo(addressId, taskListId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${taskListId}/tasks`;
    return this.httpClient.post(url, payload);
  }

  updateToDo(taskId, payload) {
    const id = this.getStorageIds();
    const url = `api/v1/customer/addresses/${id.addressId}/task-lists/${id.taskListId}/tasks/${taskId}`;
    return this.httpClient.put(url, payload);
  }

  addDoNot({body, selectedRooms}) {
    const id = this.getStorageIds();
    const data = {
      room_note: {
        body: body
      },
      rooms: selectedRooms
    };
    const url = `api/v1/instructions/addresses/${id.addressId}/address_rooms/multiple-room-notes`;
    return this.httpClient.post(url, data);
  }

  updateDoNot(body, roomId, roomNoteId) {
    const id = this.getStorageIds();
    const data = {
      room_note: {
        body: body
      }
    };
    const url = `api/v1/instructions/addresses/${id.addressId}/address_rooms/${roomId}/room-notes/${roomNoteId}`;
    return this.httpClient.put(url, data);
  }

  async changeToDoState(state): Promise<ToDoListModel> {
    const id = this.getStorageIds();
    const data = {
      default_address_task_list_state: state
    };
    await this.storage.save(`toDoState/${id.addressId}`, state);
    const url = `api/v1/customer/addresses/${id.addressId}/default-address-task-list-state`;
    return this.httpClient.put(url, data);
  }

  parseRoomCheckboxItems(rooms): TidySelectNumberValueModel[] {
    let parsedRooms = [];
    if (rooms.length > 0) {
      rooms.map((room) => {
        parsedRooms.push({
          viewValue: room.name,
          value: room.id
        });
      });
    }
    return parsedRooms;
  }

  important(important) {
    if (important) {
      return {
        'background-color': '#FDF8BF',
        'padding' : '2px'
      };
    };
  }

  combineListResponse(toDoLists: ToDoListModel): ToDoListRoomModel[] {
    let lists = [];
    if (toDoLists.default_list !== null) {
      toDoLists.default_list['is_default'] = true;
      lists.push(toDoLists.default_list);
    }
    toDoLists.non_default_lists.map((list) => {
      list['is_default'] = false;
      lists.push(list);
    })
    return lists;
  }

  buildRoomIcons(rooms) {
    let bedroomCount = 1;
    let bathroomCount = 1;
    const formattedRooms = rooms.map((room) => {
      if (room.category === 'bedroom') {
        room['icon'] = `bedroom_${bedroomCount}`;
        bedroomCount += 1;
      } else if (room.category === 'bathroom') {
        room['icon'] = `bathroom_${bathroomCount}`;
        bathroomCount += 1;
      } else {
        room['icon'] = room.category;
      }
      return room;
    })
    return formattedRooms;
  }

  async roomPhotoNote(roomId, awsKey: string) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}/photo-notes`;
    return this.httpClient.post(url, {s3_photo_url_key: awsKey, type: 'room'});
  }

  async addGroupPhotoNote(taskGroupId, photoUrl) {
    const url = `api/v1/customer/address-task-groups/${taskGroupId}/photo-notes`;
    return this.httpClient.post(url, {photo_url: photoUrl});
  }

  async taskPhotoNotes(roomId, taskId, awsKey: string) {
    const photoKey = `${roomId}/${taskId}`;
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}/tasks/${taskId}/photo-notes`;
    return this.httpClient.post(url, {s3_photo_url_key: awsKey, type: 'task'});
  }

  async addGroupTaskPhotoNote(taskGroupId, taskId, photoUrl) {
    const url = `api/v1/customer/address-task-groups/${taskGroupId}/tasks/${taskId}/photo-notes`;
    return this.httpClient.post(url, {photo_url: photoUrl});
  }

  getTaskCategoryOptions() {
    const url = 'api/v1/customer/task-category-options';
    return this.httpClient.get(url);
  }

  getVideoInspectionSettings(rooms) {
    let inspectionSetting = null;
    let hasBeforeVideos = false;
    rooms.map((room) => {
      room.tasks.map((task) => {
        if (task.task_type == 'take_before_video') {
          hasBeforeVideos = true;
          inspectionSetting = 'Before Videos';
        } else if (task.task_type == 'take_after_video') {
          inspectionSetting = hasBeforeVideos ? 'Before & After Videos' : 'After Videos';
        }
      });
    });
    return inspectionSetting;
  }

  getRemoteInspectionStatus(state) {
    const states = {
      photo: new TranslationPipe().transform('Before & After Photos'),
      video: new TranslationPipe().transform('Before & After Videos'),
      before_photo_only: new TranslationPipe().transform('Before Photos'),
      after_photo_only: new TranslationPipe().transform('After Photos'),
      before_video_only: new TranslationPipe().transform('Before Videos'),
      after_video_only: new TranslationPipe().transform('After Videos'),
      inactive: new TranslationPipe().transform('No Inspection')
    }
    return states[state] || 'OFF';
  }

  getCustomFieldIcon(fieldName) {
    const icons = {
      'Take Inventory': 'assets/img/count.svg',
      'Select': 'assets/img/radio-button.svg',
      'Text Area': 'assets/img/text-area.svg',
      'Checkbox(es)':  'assets/img/checkbox.svg',
      'Take Photo':  'assets/img/take-photo.svg',
      'Take Photos':  'assets/img/take-photo.svg',
    }
    return icons[fieldName];
  }

  copyListToAddress(fromAddressId, taskListId, payload) {
    const url = `api/v1/customer/addresses/${fromAddressId}/task-lists/${taskListId}/copy-task-list`;
    return this.httpClient.post(url, payload);
  }

  updateToDoOrder(addressId, taskListId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/task-lists/${taskListId}/set-tasks-display-order`;
    return this.httpClient.put(url, payload);
  }

  updateDoNotUseToDos(state: string): Promise<ToDoListModel> {
    const ids = this.getStorageIds();
    const value = state === 'active' ? 'false' : 'true';
    this.storage.save(`toDoState/${ids.addressId}`, state);
    const payload = {
      do_not_use_to_dos: {
        key: `do_not_use_to_dos.address.${ids.addressId}`,
        value: `${value}`
      }
    };
    const url = `api/v1/customer/settings`;
    return this.httpClient.put(url, payload);
  }

  updateDoNotUseToDosForAddress(addressId, state: string): Promise<ToDoListModel> {
    const value = state === 'active' ? 'false' : 'true';
    this.storage.save(`toDoState/${addressId}`, state);
    const payload = {
      do_not_use_to_dos: {
        key: `do_not_use_to_dos.address.${addressId}`,
        value: `${value}`
      }
    };
    const url = `api/v1/customer/settings`;
    return this.httpClient.put(url, payload);
  }

  async getToDoState(addressId) {
    let toDoState = await this.storage.retrieve(`toDoState/${addressId}`);
    if (toDoState) {
      return toDoState;
    }
    const addressesToDosSettings = await this.getDoNotUseToDos();
    if (addressesToDosSettings?.do_not_use_to_dos) {
      const toDosStatesByAddress = Object.keys(addressesToDosSettings.do_not_use_to_dos).map((key) => {
        const addressId = Number(key.split('.')[2]);
        const toDoState = addressesToDosSettings.do_not_use_to_dos[key];
        return { addressId, toDoState };
      });
      const addressToDoState = toDosStatesByAddress.find((item) => item.addressId === addressId);
      if (addressToDoState) {
        toDoState = addressToDoState?.toDoState === 'true' ? 'inactive' : 'active';
      }
    }
    await this.storage.save(`toDoState/${addressId}`, toDoState);
    return toDoState;
  }

  getDoNotUseToDos(): Promise<any> {
    const url = `api/v1/customer/settings`;
    return this.httpClient.get(url);
  }

  async getMetadataFromFiles(rooms: any[]): Promise<any[]> {
    try {
      for (const room of rooms) {
        const files = room?.before_after_photos;
        const todos = room?.todos;

        if (files?.length > 0) {
          await this.processFiles(files);
        }

        if (todos?.length > 0) {
          for (const todo of todos) {
            await this.processTodoOptions(todo.options);
          }
        }
      }
      return rooms;
    } catch (err) {
      console.error(err);
      return rooms;
    }
  }

  async processFiles(files: any[]): Promise<void> {
    for (const file of files) {
      file.metadata = await this.getMetadata(file.photo_url);
    }
  }

  async processFilesAndReturn(files: any[]): Promise<any[]> {
    for (const file of files) {
      file.metadata = await this.getMetadata(file.media_url);
    }
    return files;
  }

  async processTodoOptions(options: any[]): Promise<void> {
    const availableTypes = ['photo', 'video', 'photos', 'videos'];
    for (const option of options) {
      if (availableTypes.includes(option?.value_type)) {
        if (option?.value) {
          option.metadata = await this.getMetadata(option.value);
        }
        if (option?.customer_value) {
          option.jsonValues = [];
          const jsonValues = JSON.parse(option.customer_value);
          jsonValues.map(async (value) => {
            option.jsonValues.push({
              value: value,
              metadata: await this.getMetadata(value)
            });
          })
        } else if (option?.json_value) {
          if (typeof option.json_value === 'string') {
            option.jsonValues = [{
              value: option.json_value,
              metadata: await this.getMetadata(option.json_value)
            }];
          } else if (Array.isArray(option.json_value)) {
            option.jsonValues = await Promise.all(option.json_value.map(async (jsonValue) => {
              return {
                value: jsonValue,
                metadata: await this.getMetadata(jsonValue)
              }
            }));
          }
        }
      }
    }
  }

  async getMetadata(url: string) {
    const metadata = await this.aws.getObjectMetadata(url);
    if (!metadata) {
      return null;
    }
    const dateInUtc = LuxonDateTime.fromISO(metadata.date, { zone: 'utc' });
    const displayDate = dateInUtc.setZone(metadata.timezone).toISO();
    const isInvalidLocation =
      metadata?.latitude === '0' ||
      metadata?.longitude === '0' ||
      metadata?.latitude === 'NaN' ||
      metadata?.longitude === 'NaN' ||
      !metadata?.latitude ||
      !metadata?.longitude;
    const location = isInvalidLocation ? new TranslationPipe().transform('Location unavailable - using property location for report') : `${metadata.latitude}, ${metadata.longitude}`;
    return {
      ...metadata,
      displayDate,
      location,
    };
  }

  updateInputedToDos(payload, jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/to-dos`;
    return this.httpClient.put(url, payload);
  }

  getToDoIcon(performance) {
    if (performance === 'performed') {
      return 'assets/img/to-do-done.svg';
    } else if (performance === 'not_performed') {
      return 'assets/img/to-do-not-done.svg';
    } else {
      return 'assets/img/to-do-not-marked.svg';
    }
  }

  getToDoTooltip(performance) {
    if (performance === 'performed') {
      return new TranslationPipe().transform('Marked as performed by pro');
    } else if (performance === 'not_performed') {
      return new TranslationPipe().transform('Marked as not performed by pro');
    } else {
      return new TranslationPipe().transform('Not marked by pro');
    }
  }

}

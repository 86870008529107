export const cardOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      fontWeight: 300,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      padding: '4px',
    }
  }
}

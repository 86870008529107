<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="title"
    [noBow]="title !== 'Attempt Details'"
    [canGoBack]="title == 'Attempt Details'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <ng-container>
          <tidy-row [align]="'center'">
            <tidy-text
              *ngIf="status !== 'failed'"
              [body]="'The request to your endpoint was successfull!'">
            </tidy-text>
            <tidy-text
              *ngIf="status == 'failed'"
              [body]="'The request to your endpoint failed. Please check that the url you added is correct and reach out to the concierge with any questions.'">
            </tidy-text>
          </tidy-row>
          <tidy-button
            [text]="'Ok'"
            [action]="goToWebhookDetails.bind(this)"
            class="primary">
          </tidy-button>
        </ng-container>

        <ng-container *ngIf="status === 'succeeded'">
          <tidy-card>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Request to Your Endpoint'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-json-formatter [dataJson]="requestJSON"></tidy-json-formatter>
            </tidy-row>
          </tidy-card>

          <tidy-card>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Response'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-json-formatter [dataJson]="responseJSON"></tidy-json-formatter>
            </tidy-row>
          </tidy-card>
        </ng-container>

        <tidy-card *ngIf="status === 'failed'">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Error:'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-json-formatter [dataJson]="errorJSON"></tidy-json-formatter>
          </tidy-row>
        </tidy-card>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';


@Injectable()
export class CookieProvider {
  constructor(private _cookieService: CookieService) {}

  getCookie(key: string) {
    const cookie = this._cookieService.get(key);
    if (cookie) {
      return JSON.parse(this._cookieService.get(key));
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortName"
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      .split(" ")
      .map((n, i) => i!== 0? i > 1 ? '' : n[0]+'.' : n)
      .join(" ");
  }
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Archived Tasks'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container style="margin-top: 20px" *ngIf="loaded">
      <div class="desktop-title-row-padding-fix" *ngIf="windowService?.isDesktopRes">
        <ion-img
          [src]="'assets/svg/arrow-back-black.svg'"
          class="desktop-back-icon"
          (click)="goBack()">
        </ion-img>
        <tidy-text
          [title]="'Archived Tasks'">
        </tidy-text>
        <div class="desktop-nav-search">
          <form [formGroup]="form" novalidate action="#">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </form>
        </div>
      </div>
      <tidy-alert *ngIf="!archivedTasks?.length">
        <tidy-row>
          <tidy-text
            [body]="'No Tasks'">
          </tidy-text>
         </tidy-row>
      </tidy-alert>
      <ng-container *ngFor="let task of archivedTasks">
        <tidy-card (click)="goToTask(task)" style="cursor: pointer">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [ngStyle]="task?.checkedUsers?.length <= 1 ? {'max-width': '50ch'} : {'max-width': '20ch'}"
              [header]="task?.title">
            </tidy-text>
            <div class="task-assign-user">
              <div *ngIf="getUserIcon(task?.checkedUsers) == 'user'" class="initials">
                {{(task?.checkedUsers[0]?.name | slice:0:1).toUpperCase()}}
              </div>
              <tidy-image
                *ngIf="getUserIcon(task?.checkedUsers) == 'concierge'"
                [src]="'assets/img/concierge.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                style="margin-left: -5px"
                *ngIf="task?.checkedUsers?.length > 1"
                [body]="'+' + (task?.checkedUsers?.length - 1)">
              </tidy-text>
            </div>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="getIssueHeaderIcon(task)"
              class="urgency-icon">
            </tidy-image>
            <tidy-text
              [body]="getAddressName(task)">
            </tidy-text>
            <div>
              <ion-chip *ngIf="task?.jobs?.length" style="margin-right: 0;">
                <tidy-image
                  [src]="categories[0].icon_url"
                  style="height: 25px">
                </tidy-image>
                <tidy-image
                  [src]="'assets/img/task-job-canceled.svg'"
                  style="height: 25px">
                </tidy-image>
              </ion-chip>
              <tidy-text
                style="margin-left: 30px"
                *ngIf="task?.jobs?.length > 1"
                [body]="'+' + (task?.jobs?.length - 1)">
              </tidy-text>
            </div>
          </tidy-row>
        </tidy-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <ion-row>
        <ion-col>
          <ion-skeleton-text
            animated="true"
            style="
              width: 100%;
              height: 800px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

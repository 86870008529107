<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Developers'"
    [class]="extraClass"
    [canGoBack]="!windowService.isDesktopRes"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="isDeveloperEnabled">
        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-text
              [header]="'Developers'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Use the TIDY API with your application.'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="goToSideRail('api-keys')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/key.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'API Keys'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToSideRail('webhooks')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/url.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Webhooks'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToExternalPage('https://help.tidy.com/developers')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/info.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Documentation'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToSideRail('logs')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/logs.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Logs'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-text
              [header]="'Need Help?'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="goToExternalPage('https://www.tidy.com/schedule-api-support')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Schedule a Developer Support Call'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToExternalPage('https://help.tidy.com/tidy-concierge')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/send.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Send Us an Email'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card *ngIf="!windowService.isDesktopRes && cameFromDeveloperSelfBooking">
          <tidy-row class="vertical-align-center">
            <tidy-text
              [header]="'Check Out Other Features'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="goToPage('to-dos')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/to-dos.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'To-Dos'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToPage('schedule')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/book-job.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Book a Job Instantly'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToPage('my-pros')">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/add-pro.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Add a Pro'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="!isDeveloperEnabled">
        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Enable Developer Features'"
          [action]="enableDeveloperFeatures.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [header]="'Free Cleaning & Home Service API'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-image
              [src]="'assets/img/wireframe-1.svg'"
              class="hero-size">
            </tidy-image>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY makes it easy to book cleaning or maintenance pros via our API, then manage or track those jobs. Use TIDY with your existing pros or we can help you find one through our network. The API is for internal use (e.g. property managers) or for end users of your product (e.g. property management software). Most people use it for free!'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [header]="'Request Bookings With Any Pro'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-image
              [src]="'assets/img/wireframe-2.svg'"
              class="hero-size">
            </tidy-image>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'A pro from our network or any pro you add. Define your job request and send to your priority list. Your top priority pro will get the first chance to accept, followed by your next priority and so on.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [header]="'Customize the Job with To-Do Lists'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-image
              [src]="'assets/img/wireframe-3.svg'"
              class="hero-size">
            </tidy-image>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Use the API to assign To-Do Lists to your jobs.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-text
              [header]="'Get Job Updates with Webhooks'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-image
              [src]="'assets/img/wireframe-4.svg'"
              class="hero-size">
            </tidy-image>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Use webhooks to get alerts of things like jobs being scheduled, accepted, or completed.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Enable Developer Features'"
          [action]="enableDeveloperFeatures.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

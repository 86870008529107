import { Component, Input, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { DateTime as LuxonDateTime } from 'luxon';

import { Client } from "src/providers/client/client";
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from "src/providers/genome/genome";
import { MaintenancePlans } from "src/providers/maintenance-plans/maintenance-plans";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';
import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  selector: "recurring-task",
  templateUrl: "recurring-task.html",
  styleUrls: ["recurring-task.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RecurringTaskComponent {

  @Input() data: any;
  isRightSideContent: boolean = false;
  maintenanceForm: UntypedFormGroup;
  maintenance: any;
  submitted: boolean = false;
  addressResponse: any;
  errorMessage: string;
  roomObjectOptions: any;
  isSavingChanges: boolean;
  propertyOptions: any;
  frequencyOptions = [{ value: "weeks", viewValue: "Week(s)" }, { value: "months", viewValue: "Month(s)" }, { value: "years", viewValue: "Year(s)" }];
  frequencyNumberOptions: any;
  loaded: boolean;
  roomItemsLoaded: boolean;
  roomItems: any;
  models: any;
  characteristicsChosen: any;
  characteristics: any;
  characteristicsArray: any;
  installationDateItems: any;
  modelForm: UntypedFormGroup;
  createNewModel: boolean;
  getInventoryAlerts: boolean;
  categoryItems: any;
  formError: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private maintenancePlans: MaintenancePlans,
    private modalCtrl: ModalController,
    private genome: Genome,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    private navCtrl: CustomNavController,
    client: Client
  ) {
    this.maintenanceForm = this.fb.group({
      name: ['', Validators.required],
      room_object_id: [''],
      address_id: ['', Validators.required],
      next_do_date: ['', Validators.required],
      frequency_number: ['', Validators.required],
      frequency: ['', Validators.required],
      description: [''],
      item_relation: [''],
      room: [''],
      category: [''],
      characteristic: [''],
      installation_date: [''],
      model: [''],
      currentQuantity: [''],
      minQuantity: ['']
    });
    this.modelForm = this.fb.group({
      newModel: ['', Validators.required]
    });
    client.getAddresses().then((response) => {
      this.propertyOptions = client.parseAddressList(response, false);
    }).catch((err) => {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    });
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.loaded = false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.maintenance = dialogParams.maintenance;
    this.maintenanceForm.patchValue({
      ...this.maintenance,
      address_id: this.maintenance?.address?.id,
      room_object_id: this.maintenance?.room_object?.id,
      item_relation: this.maintenance?.room_object ? 'yes' : 'no'
    });
    this.installationDateItems = this.buildInstallationDateItems();
    const categories = await this.genome.getCategories();
    this.buildCategoryItems(categories);
    this.loadRoomObjects();
    if (this.maintenance) {
      this.subscribeToFormChanges();
    }
    this.frequencyNumberOptions = this.buildFrequencyNumberOptions();
    this.getRoomItems();
    this.loaded = true;
  }

  buildCategoryItems(categories) {
    this.categoryItems = [];
    categories.map((category) => {
      this.categoryItems.push({
        value: category.id,
        viewValue: category.name
      });
    });
  }

  buildFrequencyNumberOptions() {
    let options = [];
    for (let i = 1; i < 51; i++) {
      options.push({value: i, viewValue: i});
    }
    return options;
  }

  subscribeToFormChanges() {
    for (const field in this.maintenanceForm.controls) {
      const control = this.maintenanceForm.get(field);
      control.valueChanges.pipe(
        debounceTime(1000)
      ).subscribe(value => {
        this.updateRecurringTask();
      });
    }
  }

  async getRoomItems() {
    this.roomItemsLoaded = false;
    const rooms = await this.genome.getRooms(this.maintenanceForm.value.address_id);
    this.roomItems = rooms.map((room) => {
      return {
        value: room.id,
        viewValue: room.name
      }
    });
    this.roomItemsLoaded = true;
  }

  @Loading('', true)
  async addressChanged() {
    await this.getRoomItems();
    this.maintenanceForm.patchValue({room: this.roomItems[0]?.value});
    this.maintenanceForm.patchValue({ room_object_id: null });
    this.loadRoomObjects();
  }

  loadRoomObjects() {
    const addressId = this.maintenanceForm.value.address_id;
    this.roomObjectOptions = null;
    if (!addressId) return;
    this.genome.getRoomsObjects(addressId).then((response) => {
      this.roomObjectOptions = response.map((roomObject) => {
        return {
          value: roomObject.id,
          viewValue: roomObject.name
        }
      });
      if (!this.maintenance) {
        this.roomObjectOptions.unshift({
          viewValue: 'Add New Item',
          value: 0
        })
      }
    }).catch((err) => {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    })

  }

  async updateRecurringTask() {
    this.formError = false;
    this.isSavingChanges = true;
    this.submitted = true;
    if (this.maintenanceForm.invalid) {
      this.formError = true;
      return;
    }
    try {
      let payload = {...this.maintenanceForm.value};
      const isAddingNewItem = payload.room_object_id == 0;
      if (isAddingNewItem) {
        delete payload.room_object_id;
      }
      await this.maintenancePlans.updateMaintenancePlan(this.maintenance.id, payload);
      this.storage.delete('tasksLastSavedAt');
    } catch (err) {
      console.log('1')
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    return setTimeout(() => {
      this.isSavingChanges = false;
    }, 1000);
  }

  async saveMaintenance() {
    this.submitted = true;
    this.formError = false;
    if (this.maintenanceForm.invalid) {
      this.formError = true;
      return;
    }
    try {
      if (this.maintenanceForm.get('item_relation').value == 'yes' && (this.roomObjectOptions?.length == 1 || this.maintenanceForm.get('room_object_id').value == 0)) {
        await this.addItem();
      }
      let payload = {...this.maintenanceForm.value};
      const isAddingNewItem = payload.room_object_id == 0;
      if (isAddingNewItem) {
        delete payload.room_object_id;
      }
      await this.maintenancePlans.createMaintenancePlan(payload);
      this.storage.delete('tasksLastSavedAt');
      this.closePage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async addItem() {
    this.submitted = true;
    this.errorMessage = '';
    let data;
    if (!this.maintenanceForm.valid) {
      this.errorMessage = 'Please select a category';
      return;
    }
    data = {
      address_id: this.maintenanceForm.value.address_id,
      category_id: this.maintenanceForm.value.category,
      characteristic_ids: this.characteristicsArray,
      installation_date: `01/01/${this.maintenanceForm.value.installation_date}`,
      model_id: this.maintenanceForm.value.model
    }
    if (this.getInventoryAlerts) {
      data['min_quantity'] = this.maintenanceForm.value.minQuantity;
      data['current_quantity'] = this.maintenanceForm.value.currentQuantity;
    }
    if (this.createNewModel) {
      if (!this.modelForm.valid) {
        return;
      }
      const modelData = {
        category_id: this.maintenanceForm.value.category,
        model: this.modelForm.value.newModel,
        name: `${this.getCategoryName()} ${this.modelForm.value.newModel}`,
        characteristic_ids: this.characteristicsArray
      }
      try {
        const newModel = await this.genome.addNewModel(modelData);
        data.model_id = newModel.id;
      } catch (err) {
        this.errorMessage = err.error ? err.error.message : err.message;
      }
    }
    try {
      const roomObject = await this.genome.addRoomObject(data);
      this.maintenanceForm.patchValue({room_object_id: roomObject.id});
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  getCategoryName() {
    const category = this.categoryItems.find((item) => item.value == this.maintenanceForm.value.category);
    return category.viewValue;
  }

  selectItemRelation(selection) {
    console.log('selection')
    if (selection == 'no') {
      this.maintenanceForm.patchValue({room_object_id: ''});
      this.maintenanceForm.controls.category.clearValidators();
      this.maintenanceForm.controls.category.updateValueAndValidity();
      this.maintenanceForm.controls.room.clearValidators();
      this.maintenanceForm.controls.room.updateValueAndValidity();
      this.maintenanceForm.controls.installation_date.clearValidators();
      this.maintenanceForm.controls.installation_date.updateValueAndValidity();
      this.maintenanceForm.controls.model.clearValidators();
      this.maintenanceForm.controls.model.updateValueAndValidity();
    } else if (!this.roomObjectOptions?.length) {
      this.maintenanceForm.controls.category.setValidators([Validators.required]);
      this.maintenanceForm.controls.room.setValidators([Validators.required]);
      this.maintenanceForm.controls.installation_date.setValidators([Validators.required]);
      this.maintenanceForm.controls.model.setValidators([Validators.required]);
    }
    console.log('selection')
  }

  async deleteRecurringTask() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Recurring Task?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete ',
      confirmAction: this.confirmDeleteRecurringTask.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.onDidDismiss().then(() => this.modalCtrl.dismiss());
    await confirmationModal.present();
  }

  async confirmDeleteRecurringTask() {
    try {
      await this.maintenancePlans.deleteMaintenancePlan(this.maintenance.id);
      this.modalCtrl.dismiss();
      this.storage.delete('tasksLastSavedAt');
      this.closePage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  @Loading('', true)
  async selectCategory(category) {
    this.models = [];
    this.characteristicsChosen = {};
    const characteristics = await this.genome.getCharacteristics(category);
    this.characteristics = characteristics.map((characteristic) => {
      const forms = characteristic.characteristics.map((section) => {
        const formData = {
          value: section.id,
          viewValue: section.name
        };
        return formData;
      });
      this.characteristicsChosen[characteristic.name] = -1;
      const data = {
        name: characteristic.name,
        formData: forms
      };
      return data;
    });
    await this.getModels('');
    console.log(this.models)
  }

  @Loading('', true)
  async getModels(query) {
    const models = await this.genome.getObjectModel(this.maintenanceForm.value.category, query);
    this.models = models.map((model) => {
      const formData = {
        value: model.id,
        viewValue: model.model
      };
      return formData;
    });
    this.models.push({
      viewValue: 'Add new model',
      value: 0
    });
  }

  @Loading('', true)
  selectCharacteristic(characteristic, id) {
    this.characteristicsChosen[characteristic.name] = id;
    let query = this.buildQueryString(this.characteristicsChosen);
    this.getModels(query);
  }

  buildQueryString(obj) {
    let finalQuery = '';
    let finalArray = [];
    for (var key in obj) {
      if (obj[key] != -1) {
        finalQuery += `&filters[characteristic_ids][]=${obj[key]}`;
        finalArray.push(obj[key]);
      }
    }
    this.characteristicsArray = finalArray;
    return finalQuery;
  }

  buildInstallationDateItems() {
    const array: any = [];
    let year = LuxonDateTime.now().year;
    for (let i = year; i > (year - 232); i--) {
      array.push({
        value: i,
        viewValue: i
      });
    }
    return array;
  }

  selectGetInventoryAlerts() {
    this.getInventoryAlerts = !this.getInventoryAlerts;
    if (this.getInventoryAlerts) {
      this.maintenanceForm.controls.currentQuantity.setValidators([Validators.required]);
      this.maintenanceForm.controls.minQuantity.setValidators([Validators.required]);
    } else {
      this.maintenanceForm.controls.currentQuantity.clearValidators();
      this.maintenanceForm.controls.currentQuantity.updateValueAndValidity();
      this.maintenanceForm.controls.minQuantity.clearValidators();
      this.maintenanceForm.controls.minQuantity.updateValueAndValidity();
    }
  }

  goToAddressPage(addressId) {
    this.navCtrl.navigateForward(`edit-property/${addressId}`)
  }

  selectModel(model) {
    this.createNewModel = model === 0;
  }

  goToAddRoom() {
    this.navCtrl.navigateForward(`edit-property/${this.maintenanceForm.value.address_id}`);
  }

  closePage() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.back();
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="loaded? room.name + ' Info' : ''"
    [canGoBack]="true"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteRoom()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <form [formGroup]="form">
        <tidy-card>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              [label]="'Room Name'"
              formControlName="name"
              [form]="form.controls.name"
              [icon]="'assets/svg/list-circle-outline.svg'"
              [activeIcon]="'assets/svg/list-circle-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter the name of the room.'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/home-outline.svg'"
              [label]="'Floor'"
              [form]="form.controls.floor"
              formControlName="floor"
              [items]="floorSelectItems"
              [submitted]="submitted"
              [errorMessage]="'Please enter the floor the room is on.'">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/ellipsis-horizontal-circle-outline.svg'"
              [label]="'Room Type'"
              [form]="form.controls.category"
              formControlName="category"
              [items]="roomTypeSelectItems"
              [submitted]="submitted"
              [errorMessage]="'Please select the room type.'">
            </tidy-select>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Save Changes'"
          [action]="updateRoom.bind(this)"
          class="primary">
        </tidy-button>
      </form>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  templateUrl: 'block-on-missing-fields.html'
})

export class BlockOnMissingFieldsPage implements OnInit {

  addressId: any;
  finishPage: any;
  errorMessage: string;
  isRightSideContent = true;
  list: any;
  blockCompleteOnMissingFields: boolean = false;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {
  }

  async ngOnInit() {
    this.loaded = false;
    this.rightSidePanelService.setDialogLoading(true);
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Custom Field Requirements');
    this.addressId = this.navCtrl.getParam('addressId') || dialogParams.addressId;
    this.finishPage = this.navCtrl.getParam('finishPage') || dialogParams.finishPage;
    this.list = this.navCtrl.getParam('list') || dialogParams.list;
    this.blockCompleteOnMissingFields = this.list.block_complete_on_missing_fields;
    this.loaded = true;
    setTimeout(() => {
      this.rightSidePanelService.setDialogLoading(false);
    }, 1000);
  }


  async confirmChange() {
    this.errorMessage = '';
    try {
      await this.toDos.saveBlockCompleteOnMissingFields(this.list.id, this.blockCompleteOnMissingFields);
      await this.storage.save('didMakeToDoUpdates', true);
      this.rightSidePanelService.navigateTo(this.finishPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}

<tidy-card *ngIf="parkingReimbursements.length > 0">
  <tidy-row>
    <tidy-text
      [header]="'Parking Reimbursement Request'">
    </tidy-text>
  </tidy-row>

  <ng-container *ngFor="let parkingReimbursement of parkingReimbursements">
    <tidy-row>
      <tidy-text
        [body]="' Your pro ' + parkingReimbursement?.homekeeper?.name + ' is requesting reimbursement for ' + (parkingReimbursement.amount | tcurrency) + ' that they paid for parking during this cleaning. 100% of reimbursement goes to the pro.'">
      </tidy-text>
    </tidy-row>

      <ion-row>
        <ion-col style="text-align: center">
          <div (click)="responseRequest(cleaningId, parkingReimbursement.id, 'rejected')" class="parking-btn reject-btn">
            <div class="reject-icon parking-icon"></div>
          </div>
          <tidy-text
            [body]="'Reject Request - Pay Max of $0'">
          </tidy-text>
        </ion-col>
        <ion-col style="text-align: center">
          <div (click)="responseRequest(cleaningId, parkingReimbursement.id, 'approved')" class="parking-btn approve-btn">
            <div class="approve-icon parking-icon"></div>
          </div>
          <tidy-text
            [body]="'Accept Request - Pay '+ (parkingReimbursement.amount | tcurrency)">
          </tidy-text>
        </ion-col>
      </ion-row>

      <tidy-row class="extra-top-padding">
        <img [src]="parkingReimbursement.photo_url">
      </tidy-row>

  </ng-container>

</tidy-card>

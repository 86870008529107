import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class Instructions {

  private apiUrl = environment.api_url;

  constructor(
    private http: HttpClient,
    private httpClient: HttpClientCustom
  ) {}


  getRooms(rooms) {
    return rooms.reduce((acu, curr)  => {
      if (curr.isChecked === true) {
        acu.push(curr.address_room_id);
      }
      return acu;
    }, []);
  }

  async getRoom(params) {
    const {cleaningId, roomId} = params;
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/jobs/${cleaningId}/rooms/${roomId}/tasks`;
    const result = await this.httpClient.get(url);
    return result;
  }
}

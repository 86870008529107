import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { RecommendedWorkflow } from 'src/models/workflow.model';
import { Workflows } from 'src/providers/workflows/workflows';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'workflow-feature-request.html'
})

export class WorkflowFeatureRequestPage implements OnInit {

  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  selectedFeature: any;
  featureType: string;
  recommendedWorkflows: RecommendedWorkflow[] = [];
  isRightSideContent: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private workflowProvider: Workflows,
  ) {
    this.form = this.fb.group({
      note: ['', Validators.required],
      numberOfProperties: ['', Validators.required],
      notes: ['']
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle(`Request ${this.featureType}`);
    }
    this.errorMessage = '';
    this.selectedFeature = this.navCtrl.getParam('feature') || this.dialogParams.feature;
    this.featureType = this.navCtrl.getParam('featureType') || this.dialogParams.featureType;
    this.recommendedWorkflows = this.workflowProvider.getRecommendedWorkflows();
    const recommendedWorkflowIndex = Number(this.navCtrl.getParam('recommendedWorkflowIndex') || this.dialogParams?.recommendedWorkflowIndex);
    const recommendedWorkflow = this.recommendedWorkflows[recommendedWorkflowIndex];
    if (recommendedWorkflow) {
      this.form.patchValue({
        notes: recommendedWorkflow?.actions?.[0]?.body
      });
    }
  }

  async requestFeature() {
    try {
      const payload = {
        key: this.selectedFeature.key,
        note: `Selected Feature: ${this.featureType} ${this.selectedFeature.name} / Instructions for vendors: ${this.form.value.notes}`
      }
      await this.client.requestUpcomingFeature(payload);
      const params = this.mountSuccessPage();
      this.rightSidePanelService.navigateTo('success', params);
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const params = this.mountAlreadyRequestedSuccessPage();
        this.rightSidePanelService.navigateTo('success', params);
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  mountSuccessPage() {
    return {
      header: 'Feature Requested',
      body: 'TIDY Concierge will reach out in the next 1 business day to help you.',
      buttonText: 'Ok',
      buttonRoute: 'automations/summary'
    };
  }

  mountAlreadyRequestedSuccessPage() {
    return {
      header: 'Feature Already Requested',
      body: `You already requested to add this ${this.featureType} so our Concierge will reach out to you. Please reach out with any questions.`,
      buttonText: 'Ok',
      buttonRoute: 'automations/summary'
    };
  }
}

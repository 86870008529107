import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'enable-automatic-booking-onboarding',
  templateUrl: './enable-automatic-booking-onboarding.html'
})
export class EnableAutomaticBookingOnboarding {
  constructor(
    public onboardingProvider: OnboardingProvider
  ) {
    console.log('2')
  }
} 
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Client } from 'src/providers/client/client';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

@Component({
  selector: 'bills-onboarding',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss'],
})
export class BillsOnboardingComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private client: Client,
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private onboardingProvider: OnboardingProvider,
    private storage: TidyStorage
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      useTidyForPayments: [null],
      autopay: [null],
    });

    const useTidyForPayments = await this.client.getMetadata(['external.useTidyForPayments']);
    this.form.get('useTidyForPayments').setValue(JSON.parse(useTidyForPayments?.[0]?.value || 'null'));
    const autopay = await this.client.getMetadata(['external.autopay']);
    this.form.get('autopay').setValue(JSON.parse(autopay?.[0]?.value || 'null'));
  }

  async save() {
    const formValue = this.form.value;
    if (formValue.useTidyForPayments === null || (formValue.useTidyForPayments === true && formValue.autopay === null)) {
      this.util.showWarning('Please fill in all fields');
      return;
    }
    try {
      await Promise.all([
        this.client.addMetadata({
          key: 'useTidyForPayments',
          value: formValue.useTidyForPayments,
        }),
        this.client.addMetadata({
          key: 'autopay',
          value: formValue.useTidyForPayments ? formValue.autopay : false,
        }),
        this.onboardingProvider.markGoalAsCompleted('bills')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Bill settings saved succesfully');
    } catch (error) {
      console.error(error);
      this.util.showError('Error saving bill settings' + error?.message);
    }
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Workflow Run'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row class="vertical-align-middle">
          <tidy-text
            [header]="run?.workflow_name">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="run?.status == 'failed' ? 'assets/img/block.svg' : 'assets/img/checkmark.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="run?.status | titlecase">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [helper]="getDate(run?.status_updated_at)">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
              [src]="'assets/img/play.svg'"
              class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Trigger'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="run?.trigger_icon_url"
            class="title-size">
          </tidy-image>
          <tidy-text
            [body]="run?.trigger_name">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <pre style="display: inline;
          font-family: 'Inter', Arial, sans-serif;
          font-weight: 300;
          font-size: 14px;
          color: black">{{triggerOutputs}}</pre>
        </tidy-row>
        <ng-container *ngFor="let action of run?.workflow_version_action_runs; let last=last; let actionIndex=index">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/check.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Action'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="(actionIndex + 1)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" [ngClass]="!last ? 'extra-bottom-padding' : ''">
            <tidy-image
              [src]="action?.action_icon_url"
              class="title-size">
            </tidy-image>
            <tidy-text
              [body]="action?.action_name">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" [ngClass]="!last ? 'extra-bottom-padding' : ''" *ngIf="action?.error?.message">
            <tidy-text
              [body]="'Error:'"
              class="red">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="action?.error?.message"
              class="red">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

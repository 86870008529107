<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Notifications'"
    [customBack]="backPage || 'more/account-settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'These notifications trigger for your chosen events.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [actionBody]="'Learn More'"
          class="link"
          (action)="goToLearnMoreNotifications()">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="notificationSettings[0]?.notifications.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'No notifications added.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-button
        [text]="'Add Notifications'"
        [action]="goToAddNotificationPage.bind(this)"
        class="primary">
      </tidy-button>

      <ng-container *ngFor="let team of notificationSettings" class="vertical-align-middle">
        <tidy-row *ngIf="notificationSettings?.length > 1 && team?.notifications?.length">
          <tidy-text
            [title]="team?.title">
          </tidy-text>
        </tidy-row>
        <tidy-card *ngFor="let notification of team?.notifications">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="(notification.display_name | translate) | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let channel of notification.channels" [alignLastItemRight]="true">
            <tidy-text
              class="link"
              [actionBody]="channel?.name?.substring(0,37) + (channel?.name?.length > 37 ? '...' : '')"
              (action)="goToWebhook(channel)">
            </tidy-text>
            <tidy-text
              class="link"
              [actionBody]="'X'"
              style="margin-left:5px"
              (action)="removeEvent(notification, channel)">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="notificationSettings?.length == 0">
        <tidy-row class="extra-top-padding" [align]="'center'">
          <tidy-text
            [header]="'How Account Notifications Work:'">
          </tidy-text>
        </tidy-row>
        <tidy-alert style="padding: 15px 15px 15px 10px" *ngFor="let card of salesCards">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="card?.image"
                      style="width:35px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="card?.title">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="card?.text">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-alert>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

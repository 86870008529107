<form [formGroup]="form">
  <div class="card-container" style="margin-bottom: 2rem; margin-top: 1rem">
    <div class="card-header">
      <tidy-row>
        <tidy-text [header]="'Concierge Level'"> </tidy-text>
      </tidy-row>
    </div>
    <tidy-divider></tidy-divider>

    <div class="card-body">
      <tidy-row>
        <tidy-radio-button
          [form]="form"
          formControlName="conciergeLevel"
          [items]="radioButtonConciergeLevel">
        </tidy-radio-button>
      </tidy-row>
    </div>
  </div>

  <div class="card-container" style="margin-bottom: 2rem">
    <div class="card-header">
      <div class="card-header-content">
        <tidy-row>
          <tidy-text [header]="'Smart Number'"> </tidy-text>
        </tidy-row>
        <tidy-toggle
          [toggled]="smartNumber"
          (toggleChanged)="smartNumber = !smartNumber">
        </tidy-toggle>
      </div>
    </div>
    <tidy-divider></tidy-divider>
    <div class="card-body">
      <tidy-row>
        <tidy-text
          [body]="'<b>Allow pros, guests, or tenants a dedicated line as &quot;Your Ops Number&quot;</b>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Great for more consistency and easier to share instructions, you can just say &quot;here is my number&quot;. $39/yr add on.'">
        </tidy-text>
      </tidy-row>
    </div>
  </div>
</form>

<tidy-button
  [text]="'Save Settings'"
  [action]="submit.bind(this)"
  [class]="'primary'">
</tidy-button>

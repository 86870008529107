import { Directive, HostBinding, Input } from '@angular/core';
import { MatRipple } from '@angular/material/core';

@Directive({
  selector: '[tidyRipple]'
})
export class TidyRipple extends MatRipple {
  @Input() desktopAndMobileColor: boolean;

  @HostBinding('class.tidy-ripple-desktop-and-mobile-color') _isToAddColor: boolean;
  @HostBinding('class.tidy-ripple-wrapper')

  ngOnChanges() {
    this._isToAddColor = this.desktopAndMobileColor;
  }
} 

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Forgot Password'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="!showConfirmationMessage">
        <tidy-card>
         <tidy-row class="field-top-padding">
           <tidy-input
             formControlName="email"
             [label]="'Email'"
             [form]="form.controls.email"
             type="email"
             [errorMessage]="'Please enter a valid email.'"
             [icon]="'assets/svg/mail-outline.svg'"
             [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
           </tidy-input>
         </tidy-row>
       </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Send Password Reset Email'"
          [action]="onSubmit.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="isMobileResolution"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="!number">
        <tidy-button
          [text]="pageTitle"
          class="primary"
          (click)="createProxyNumber()">
        </tidy-button>
      </ng-container>
      <ng-container *ngIf="number">
        <div class="proxy-copy-row">
          <div>
            <tidy-text class="proxy-number" [header]="number"> </tidy-text>
          </div>
          <div>
            <img
              class="proxy-copy-icon"
              src="assets/icon/copy-circle-black.svg"
              (click)="copyToClipboard(number)" />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="number">
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-checkbox
              style="margin-right: 5px"
              formControlName="enableAI"
              [form]="form.controls.enableAI"
              [items]="[{viewValue: '', value: true}]">
            </tidy-checkbox>
            <tidy-text
              [body]="'AI Suggested Actions (Beta)'">
            </tidy-text>
          </tidy-row>
        </form>
      </ng-container>

      <ng-container *ngIf="numberType === 'guest'">
        <tidy-row class="extra-top-padding">
          <tidy-text [header]="'How Guest Number\'s Work'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'1. Share this with guests, tenants, or anyone you want to be able message in.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'2. As guests message in, they come into your Messages section.'">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <ng-container *ngIf="numberType === 'pro'">
        <tidy-row class="extra-top-padding">
          <tidy-text [header]="'How Pro Number\'s Work'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'1. Share this with pros who you want to be able to message in.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'2. As pros message in, they come into your Messages section.'">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-row style="padding-top: 1rem">
        <tidy-text [header]="'Optional'"> </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'1. Set up workflows to make inbound messages create Concierge Actions.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'2. Our team will create and update issues and jobs according to your preferences.'">
        </tidy-text>
      </tidy-row>

    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="isMobileResolution">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="!isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

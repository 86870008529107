import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Genome } from 'src/providers/genome/genome';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { AddressModel } from 'src/models/address.model';

@Component({
  templateUrl: 'edit-rooms.html'
})

export class EditRoomsPage implements OnInit {

  addressName: string;
  cameFromBuildMap: boolean;
  dialogParams: any;
  rooms: any;
  errorMessage: string;
  address: AddressModel;
  floors: any;
  loaded: boolean;
  isRightSideContent: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private genome: Genome,
    private route: ActivatedRoute,
    private toDos: ToDos,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Rooms');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    const addressId = this.route.snapshot.paramMap.get('addressId') || this.dialogParams.addressId;
    try {
      const [ rooms, address ] = await this.getRoomInfo(addressId);
      this.rooms = rooms;
      this.address = address;
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
    this.rooms = this.toDos.buildRoomIcons(this.rooms);
    this.sortByfloor();
  }

  async getRoomInfo(addressId){
    const rooms = this.genome.getRooms(addressId);
    const address = this.genome.getAddress(addressId);
    this.addressName = this.navCtrl.getParam('addressName') || this.dialogParams.addressName;
    this.cameFromBuildMap = this.navCtrl.getParam('cameFromBuildMap') || this.dialogParams?.cameFromBuildMap;
    return await Promise.all([rooms, address])
  }

  sortByfloor(){
    const floors = [];
    this.rooms.forEach(room => {
      floors[room.floor || 1] = floors[room.floor || 1] ?? [];
      floors[room.floor || 1].push(room);
    });
    this.floors = floors;
  }

  goToAddRoomPage(floor) {
    const params = {
      addressId: this.address.id,
      floor: floor
    };
    this.rightSidePanelService.navigateTo('add-new-room', params);
  }

  goToEditRoomDetails(room){
    const params = {
      room,
      addressId: this.address.id
    };
    this.rightSidePanelService.navigateTo(`edit-room-details`, params);
  }

  confirmRooms(){
    const params = {
      address: this.address,
      floors: this.floors,
      addressName: this.addressName
    };
    this.rightSidePanelService.navigateTo(`map-introduction`, params);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { SuccessPage } from 'src/shared/pages/success/success';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'vendor-compliance.html'
})

export class VendorCompliancePage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  requirementItems: any;
  requirementError: boolean;
  loaded: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      note: [''],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Vendor Compliance');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.requirementItems = this.buildRequirementItems();
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  buildRequirementItems() {
    return [
      {
        value: 'Insurance',
        viewValue: 'Insurance',
        checked: false
      },
      {
        value: 'Training/Certification',
        viewValue: 'Training/Certification',
        checked: false
      },
      {
        value: 'Background check',
        viewValue: 'Background check',
        checked: false
      },
      {
        value: 'Document signing (e.g. NDA)',
        viewValue: 'Document signing (e.g. NDA)',
        checked: false
      },
      {
        value: 'Licenses',
        viewValue: 'Licenses',
        checked: false
      }
    ];
  }

  async requestAccess() {
    this.requirementError = false;
    this.errorMessage = '';
    const selectedRequirements = this.getSelectedRequirements();
    if (selectedRequirements == '') {
      return this.requirementError = true;
    }
    try {
      const payload = {
        key: 'vendor-compliance',
        note: 'Requirement: ' + selectedRequirements + (this.form.controls.note.value ? (' Note: ' + this.form.controls.note.value) : '')
      }
      await this.client.requestUpcomingFeature(payload);
      const params = await this.mountSuccessPage();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const params = await this.mountAlreadyRequestedSuccessPage();
        this.rightSidePanelService.navigateTo('success', params, SuccessPage);
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  getSelectedRequirements() {
    let requirements = '';
    this.requirementItems.map((item) => {
      if (item.checked) {
        requirements += item.value + ', ';
      }
    });
    requirements = requirements.slice(0, -2);
    return requirements;
  }

  async mountSuccessPage() {
    return {
      header: 'Feature Requested',
      body: 'TIDY Concierge will reach out in the next 1 business day to help set up vendor compliance with you.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

  async mountAlreadyRequestedSuccessPage() {
    return {
      header: 'Feature Already Requested',
      body: 'You already requested to add this feature so our Concierge will reach out to you. Please reach out with any questions.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

}

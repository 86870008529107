import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Events } from 'src/providers/events/events';

import { RoutingState } from 'src/shared/providers/providers/routing-state';

import * as moment from 'moment';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'tidy-top-nav',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./top-nav.component.scss'],
  templateUrl: './top-nav.component.html'
})

export class TopNavComponent implements OnInit{

  @Input() backButton: boolean;
  @Input() canGoBack: boolean;
  @Input() countdownTarget: moment.Moment;
  @Input() customBack: string;
  @Input() closeIconMobile = 'close-circle-white';
  @Input() closeIconDesktop = 'close-circle';
  @Input() linkIcon: string;
  @Input() noBow = false;
  @Input() navigationExtras = {};
  @Input() title: string;
  @Input() showLinkIcon = false;

  @Output() closeItemAction: EventEmitter<any> = new EventEmitter();
  @Output() linkAction: EventEmitter<any> = new EventEmitter();

  loading = false;
  checkGoBack;
  current: string;
  logo: string;

  constructor(
    private customNavController: NavController,
    private router: Router,
    private routingState: RoutingState,
    private toastCtrl: ToastController,
    private events: Events,
  ) {
    this.events.subscribe('updateMenu', () => this.loadLogo());
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkGoBack = this.routingState.canGoBack();
        this.current = event.url;
      }
    });
  }

  ngOnInit() {
    this.loadLogo();
    this.setTopNavSpaceSize();
  }

  private setTopNavSpaceSize() {
    let spaceSize = '0px 20px 13px 20px';
    if (window.innerWidth <= 880) {
      spaceSize = '13px 20px 13px 20px';
    }
    if (Capacitor.getPlatform() === 'ios') {
      spaceSize = '40px 20px 13px 20px';
    }
    document.documentElement.style.setProperty('--top-nav-space-size', spaceSize);
  }

  loadLogo(){
    const customLogo = localStorage.getItem('custom.logo');
    this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
  }

  showCloseItem() {
    return (this.closeItemAction.observers.length > 0);
  }

  async executeAction(emitter: EventEmitter<any>) {
    this.loading = true;
    try {
      await emitter.emit();
    } catch (error) {
      this.showError(error);
    }
    this.loading = false;
  }

  goBack() {
    if (this.customBack) {
      this.customNavController.navigateForward(this.customBack, this.navigationExtras);
    } else {
      this.customNavController.back();
    }
  }

  async showError(message) {
    const toast = await this.toastCtrl.create({ message, duration: 3000 });
    toast.present();
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Job Issue'"
    [canGoBack]="true"
    [customBack]="'get-help'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Which job did you have an issue with?'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
          <ng-container *ngFor="let job of pastJobsResponse | slice:0:showLimiter">
            <tidy-card-button (action)="next(job)">
              <tidy-row>
                <tidy-text
                  [body]="job?.service">
                </tidy-text>
                <tidy-text
                  [body]="' on ' + (job?.start_datetime_local | parseDate: 'ddd M/D h:mma')">
                </tidy-text>
                <br>
                <tidy-text
                  [helper]="job?.display_address">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
          <tidy-card-button (action)="next()">
            <tidy-row>
              <tidy-text
                [body]="'Not Listed'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button *ngIf="pastJobsResponse.length > 10" (action)="showLimit()">
            <tidy-row>
              <tidy-text
                *ngIf="showLimiter == 10"
                [body]="'Show more jobs'">
              </tidy-text>
              <tidy-text
                *ngIf="showLimiter !== 10"
                [body]="'Show less jobs'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ElementRef, Renderer2, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
declare const MergeLink: any;


@Component({
  templateUrl: 'integrations.html'
})

export class EmployerIntegrationsMockPage implements OnInit {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  loaded: boolean;
  linkToken: any;

  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
    private navCtrl: CustomNavController,
    private renderer2: Renderer2
  ) {}

  async ngOnInit() {
    this.loaded = false;
    await this.getPublicKey();
    this.initMerge()
    this.loaded = true;
  }

  initMerge(){
    MergeLink.initialize({
      linkToken: this.linkToken,
      onSuccess: (public_token) => (this.sucess(public_token))
    });
  }

  sucess(public_token) {
    const MergeHrisApi = require("@mergeapi/merge_hris_api");
    let defaultClient = MergeHrisApi.ApiClient.instance;

    defaultClient.authentications["tokenAuth"] = {
      type: "bearer",
      accessToken: "22fiM0j8s8RZfZuHvUhPX67KFf2M178etWa13eMj-71gnD6Mw1yn2w",
    };

    const apiInstance = new MergeHrisApi.AccountTokenApi();

    const publicToken = public_token;

    apiInstance.accountTokenRetrieve(publicToken, (error, data) => {
      if (error) {
        console.error(error);
      } else {
        console.log(data.account_token);
      }
    });
  };

  async getPublicKey(){
    return new Promise<void>((resolve,reject) => {
      const MergeHrisApi = require('@mergeapi/merge_hris_api');

      let defaultClient = MergeHrisApi.ApiClient.instance;

      defaultClient.authentications['tokenAuth'] = {type: "bearer", accessToken: "22fiM0j8s8RZfZuHvUhPX67KFf2M178etWa13eMj-71gnD6Mw1yn2w"}

      const apiInstance = new MergeHrisApi.LinkTokenApi();

      const endUserDetails = {
        "end_user_origin_id": Math.floor(Math.random() * (99999999 - 10000000) + 10000000), // unique entity ID
        "end_user_organization_name": 'demo org',  // your user's organization name
        "end_user_email_address": `demo@demo${Math.floor(Math.random() * (9999999 - 1000000) + 1000000)}.com`, // your user's email address
        "categories": ["hris"] // choose your category
      }

      apiInstance.linkTokenCreate(endUserDetails, (error, data) => {
        if (error) {
          console.error(error);
          reject();
        } else {
          this.linkToken = data.link_token;
          console.log(data.link_token);
          resolve();
        }
      });
    })
  }

  openMergeLink(){
    MergeLink.openLink();
  }
}

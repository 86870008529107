import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { bookJobConfigurations } from 'src/pages/booking/book-job/book-job-configurations';
import { ActivatedRoute } from '@angular/router';

import { Logger } from "src/providers/logger";
import { SubstituteResquest } from "src/providers/substitute-request/substitute-request";

import { Loading } from 'src/shared/components/loading/loading';
import { Util } from "src/shared/util/util";
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ConfirmBookingPage } from '../booking/confirm-booking/confirm-booking';
import { BookJobPage } from '../booking/book-job/book-job';

@Component({
  templateUrl: 'request-substitute.html',
  encapsulation: ViewEncapsulation.None
})

export class RequestSubstitutePage implements OnInit {

  jobId: any;
  substituteTimes: any;
  cntSelected = 0;
  isAirbnb: boolean;
  errorMessage: any;
  showConfirmedMessage: boolean = false;
  jobData: any;
  configuration: any;
  address: any;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private subRequest: SubstituteResquest,
    private util: Util,
    private logger: Logger,
    private schedule: Schedule,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Request Substitute');
    this.loadData();
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.loaded = false;
    try {
      await this.loadParams();
      this.hasConfirmedTime(this.substituteTimes);
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
      this.logger.error(err);
    }
  }

  @Loading('', true)
  async loadParams() {
    this.jobId = this.route.snapshot.paramMap.get('jobId') || this.dialogParams?.jobId;
    this.jobData = await this.schedule.getJobDetail(this.jobId);
    this.substituteTimes = await this.subRequest.getSubstituteTimes(this.jobId);
  }

  selectTime(timeSlot, i, j) {
    this.substituteTimes[i].times[j].selected = true;
    if (this.checkConfirmedTime(timeSlot)) { return; }

    timeSlot.selected ? this.cntSelected++ : this.cntSelected--;
  }

  checkConfirmedTime(selectedTime: any) {
    if (selectedTime.action === 'create_opc' || selectedTime.action === 'reschedule_cleaning') {
      this.configuration = bookJobConfigurations['reschedule_job'];
      const planId = this.jobData.job.plan_id;
      const bookingType = 'reschedule_job';
      const flowType = null;
      const bookingId = this.jobData.booking.id
      const jobId = this.jobId;
      const viewData = this.buildViewData(selectedTime, true);
      const bookData = this.configuration.buildBookingData({
        addressId: this.jobData.address.id,
        frequency: 'once',
        planId: this.jobData.job.plan_id,
        serviceTypeKey: this.jobData.booking.bookable_service.key,
        dates:[{
          date: selectedTime.cleaning.date,
          time: selectedTime.cleaning.start_time,
          homekeeper_ids: selectedTime.homekeepers.map((pro) =>{
            return pro.id
          }),
        }],
        ratingChosen: true,
        hkRating: 'specific_hk'
      });
      const isMobileResolution = window.innerWidth <= 870;
      const params = {
        planId,
        bookingType,
        flowType,
        bookData,
        viewData,
        bookingId,
        jobId,
        address: this.jobData.address,
        customBack: isMobileResolution ? `request-substitute/${this.jobId}` : null,
       }
      const url = 'confirm-booking';
      const component = ConfirmBookingPage;
      this.rightSidePanelService.navigateTo(url, params, component);
      return true;
    }
  }

  buildViewData(selectedTime: any, confirmedBooking : boolean) {
    const data: any = {
      service: this.jobData.booking.bookable_service.name,
      frequency: 'One Time',
      image: this.jobData.booking.bookable_service?.icon_url || `assets/img/plans/${this.jobData.booking.bookable_service.key}.svg`,
      price: this.jobData.booking.bookable_service.price,
      billingType: 'flat_rate'
    };
    if(confirmedBooking){
      data.dateTime = this.getDateLabel(selectedTime, confirmedBooking);
    }
    else {
      let dates = this.getDateLabel(selectedTime, confirmedBooking);
      data.dateTime = dates[0];
      data.dateTimeTwo = dates[1];
      data.dateTimeThree = dates[2];
    }
    return data;
  }

  getDateLabel(selectedTime: any, confirmedBooking : boolean){
    if(confirmedBooking){
      const date = this.formatDate(selectedTime.cleaning.date, 'ddd M/D');
      const time = this.formatDate(selectedTime.cleaning.date + ' ' + selectedTime.cleaning.start_time, 'h:mma');
      const dateLabel = [date, time].filter(Boolean).join(' ');
      return dateLabel;
    } else {
      let datelabels = selectedTime.map((day) => {
      const date = this.formatDate(day.date, 'ddd M/D');
      const times = day.times.map(time => {
        return this.formatDate(day.date + ' ' + time.cleaning.start_time, ' h:mma');
      });
      return [date, times.map(time => {return time})].filter(Boolean).join('');
    });
      return datelabels;
    }
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

  prepareRedirectData(substituteTimes) {
    const selectedTimes = this.filterSelectedTimes(substituteTimes);
    return this.filterEmptyDates(selectedTimes);
  }

  filterSelectedTimes(substituteTimes) {
    const selectedTimes = this.util.deepClone(substituteTimes);
    selectedTimes.forEach((date) => {
      const filtered = date.times.filter((time) => {
        return time.selected;
      });
      date.times = filtered;
    });

    return selectedTimes;
  }

  filterEmptyDates(substituteTimes) {
    const result = substituteTimes.filter((elem) => {
      return (elem.times.length > 0);
    });
    return result;
  }

  rescheduleCleaning() {
    const address = this.jobData.address;
    const bookingType = 'reschedule_job'
    const jobId = this.jobData.job.id;
    const bookingId = this.jobData.booking.id;
    const params = { address, bookingType, jobId, bookingId };
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToConfirmRequest() {
    this.errorMessage = '';
    if (this.cntSelected < 1) {
      return this.errorMessage = 'Please select one or more times for the request.'
    }
    const selectedTimes = this.prepareRedirectData(this.substituteTimes);
    const clonedTimes = this.util.deepClone(selectedTimes);
    const viewData = this.buildViewData(clonedTimes, false);
    const params = {
      requestTimes: clonedTimes,
      jobId: this.jobId,
      viewData,
      isRequestSubstitute: true,
      address: this.jobData.address,
    };
    const url = 'confirm-booking';
    const component = ConfirmBookingPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  hasConfirmedTime (substituteTimes) {
    substituteTimes.forEach((weekday) => {
      const hasHomekeeper: boolean = weekday.times.some(time => time.homekeepers.length > 0);
      this.showConfirmedMessage = hasHomekeeper;
      if (hasHomekeeper) { return; }
    })
  }

}

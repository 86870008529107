<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="!cantGoBack"
    [title]="'Payments Options'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-card-button (action)="goToEditCard()" style="padding-top:0px">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/credit-card.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Card on File'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <ng-container *ngIf="card?.last4">
              <tidy-text
                [helper]="'Ending in'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="card?.last4">
              </tidy-text>
            </ng-container>
            <tidy-text
              *ngIf="!card?.last4"
              [helper]="'No card on file'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToPaymentSettings()">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/settings.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Payment Settings'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="settings?.billing?.charge?.type === 'before_cleaning'"
              [helper]="'Charge in advance (save 10%)'">
            </tidy-text>
            <tidy-text
              *ngIf="settings?.billing?.charge?.type !== 'before_cleaning'"
              [helper]="'Charge after jobs are done'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBuyBundle()" *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/coupon.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Buy a Bundle (save 10%)'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToUseGiftCard()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/present.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Use a Gift Card'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToViewPrices()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/information-circle-yellow.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'View Your Current Prices'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToSubscriptions()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/residential.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Subscription'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
declare const window: any;

@Injectable()
export class Analytics {
  constructor(
  ) { }

  configureGoogleGtag (url: string, groupId: string, options) {
    gtag('js', new Date());

    gtag('config', environment.google_analytics_id, { 'page_path' : url });
    gtag('event', 'conversion', {'send_to': `${environment.google_analytics_id}/${groupId}`, ...options});
  }

  configureBing(event: string, eventCategory: string, eventLabel: string, eventValue: string) {
    window.uetq.push('event', event, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'event_value': eventValue
    });
  }
}

import { Injectable } from '@angular/core';
import { createConsoleLogger } from 'ldclient-js';
import { AttachmentModel } from 'src/models/concierge.model';
import { RecommendedWorkflow } from 'src/models/workflow.model';
import { HttpClientCustom } from 'src/providers/custom/http-client';
import { generateUuid } from '../tidy-session/session-uuid';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { Aws } from '../aws/aws';

@Injectable()
export class Workflows {
  constructor(
    private httpClient: HttpClientCustom,
    private filePicker: FilePicker,
    private aws: Aws,
    ) {
  }

  getWorkflowTemplates(): Promise<any> {
    const url = 'api/v1/customer/workflow-templates';
    return this.httpClient.get(url);
  }

  addWorkflowTemplate(payload): Promise<any> {
    const url = 'api/v1/customer/workflows/create-from-workflow-template';
    return this.httpClient.post(url, payload);
  }

  getWorkflows(): Promise<any> {
    const url = 'api/v1/customer/workflows';
    return this.httpClient.get(url);
  }

  getWorkflowWithVersions(workflowId): Promise<any> {
    const url = `api/v1/customer/workflows/${workflowId}?includes=published_version,draft_version,latest_run`;
    return this.httpClient.get(url);
  }

  getWorkflowVersion(workflowId: string | number): Promise<any> {
    const url = `api/v1/customer/workflow-versions?filters[workflow_ids]=${[workflowId]}&includes=workflow_version_trigger,workflow_version_actions`;
    return this.httpClient.get(url);
  }

  getWorkflowTriggerTypes(): Promise<any> {
    const url = `api/v1/customer/workflow-triggers`;
    return this.httpClient.get(url);
  }

  getWorkflowActionTypes(): Promise<any> {
    const url = `api/v1/customer/workflow-actions`;
    return this.httpClient.get(url);
  }

  createWorkflowVersion(payload): Promise<any> {
    const url = `api/v1/customer/workflow-versions/save-draft`;
    return this.httpClient.post(url, payload);
  }

  createWorkflow(payload) {
    const url = `api/v1/customer/workflows`;
    return this.httpClient.post(url, payload);
  }

  updateWorkflow(payload, workflowId) {
    const url = `api/v1/customer/workflows/${workflowId}`;
    return this.httpClient.put(url, payload);
  }

  pauseWorkflow(id) {
    const url = `api/v1/customer/workflows/${id}/pause`;
    return this.httpClient.post(url, {});
  }

  unpauseWorkflow(id) {
    const url = `api/v1/customer/workflows/${id}/unpause`;
    return this.httpClient.post(url, {});
  }

  deleteWorkflow(id) {
    const url = `api/v1/customer/workflows/${id}`;
    return this.httpClient.delete(url);
  }

  publishWorkflow(workflowVersionId) {
    const url = `api/v1/customer/workflow-versions/${workflowVersionId}/publish`;
    return this.httpClient.post(url, {});
  }

  getWorkflowVersionRuns(workflowIds, startDate, endDate, pageNumber, perPage, search = null) {
    let url = `api/v1/customer/workflow-version-runs?filters[workflow_ids]=${workflowIds}&page=${pageNumber}&per_page=${perPage}&filters[period[start_date]]=${startDate}&filters[period[end_date]]=${endDate}`;
    if (search) {
      url += `&filters[search_text]=${search}`;
    }
    return this.httpClient.getFullRequest(url)
      .then(response => {
        return {
          body: response.body,
          totalRecords: response.headers.get('X-Total-Records')
        };
      });
  }

  getUsageSummary(startDate, endDate) {
    const url = `api/v1/customer/workflow-usage-summary?start_date=${startDate}&end_date=${endDate}`;
    return this.httpClient.get(url);
  }

  getInputData(workflowStepId, workflowStepType) {
    const url = `api/v1/customer/workflow-inputs-data?workflow_step_id=${workflowStepId}&workflow_step_type=${workflowStepType}`;
    return this.httpClient.get(url);
  }

  async addAttachment(): Promise<AttachmentModel> {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `workflow-attachments/${uuid}/${file.filename}`;
    if (file.format.includes('jpeg') || file.format.includes('png') || file.format.includes('svg')) {
      const response = await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return {
        filename: file.filename,
        fileKey,
        url: response.Location
      };
    } else {
      return {
        filename: '',
        fileKey: '',
        url: ''
      };
    }
  }

  getRecommendedWorkflows(): RecommendedWorkflow[] {
    return [
      {
        description:
          'When a linked reservation is cancelled, cancel the associated job.',
        trigger: {
          category: 'Guest Reservations',
          name: 'Guest reservation cancelled',
        },
        filter: null,
        actions: [
          {
            subject: 'Canceled Reservation Id {{1.event.data.object.id}}',
            body: 'The reservation {{1.event.data.object.id}} was cancelled for {{step_id.event.data.object.address_id}} Please review the jobs that follow, and ensure the following: (1) There are not two consecutive jobs of the same time (for example, there should not be two cleanings booked in between guests, only 1). (2) Make sure there is still a job booked after the prior guest leaves.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a job is completed, review before/after photos.',
        trigger: {
          category: 'Jobs',
          name: 'Job Completed',
        },
        filter: null,
        actions: [
          {
            subject: 'Job Completed: Review Before/After Photos for {{step_id.event.data.object.id}}',
            body: 'The job {{step_id.event.data.object.id}} at {{step_id.event.data.object.address_id}} has been completed. Please review the attached before and after photos to ensure quality standards are met. Provide feedback if necessary.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'Once per day, check for any long running unassigned jobs and take action.',
        trigger: {
          category: 'Scheduled',
          name: 'every day',
          inputs: [
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Daily Check: Long Running Unassigned Jobs Alert',
            body: 'Please review any long running unassigned jobs as of today. Prioritize and assign these jobs promptly to avoid service delays at our properties.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a new issue is created, review the issue and handle in custom fashion.',
        trigger: {
          category: 'Issues',
          name: 'issue created',
        },
        filter: null,
        actions: [
          {
            subject: 'New Issue Reported: {{step_id.event.data.object.id}} at  {{step_id.event.data.object.address_id}}',
            body: 'A new issue {{step_id.event.data.object.id}} has been reported at {{step_id.event.data.object.address_id}} Please review and determine the appropriate course of action based on our standard procedures.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a new pro has a first job scheduled, have Concierge schedule an onboarding.',
        trigger: {
          category: 'Scheduled',
          name: 'every day',
          inputs: [
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'New Pro Onboarding Required for {{step_id.event.data.object.pro_name}}',
            body: 'A new professional, {{step_id.event.data.object.pro_name}}, has their first job scheduled. Please coordinate with Concierge to arrange their onboarding session.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a new message is received, check to see if any jobs should be canceled or rescheduled.',
        trigger: {
          category: 'Messages',
          name: 'message recieved',
        },
        filter: null,
        actions: [
          {
            subject: 'New Message Received: Review for Potential Job Adjustments',
            body: 'A new message {{step_id.event.data.object.id}} has been received from {{step_id.event.data.object.pro_name}} related to our properties. Please review it to determine if any jobs need to be canceled or rescheduled.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'The day before a job is scheduled, send a guest a notification a job is coming.',
        trigger: {
          category: 'Scheduled',
          name: 'every day',
          inputs: [
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Reminder: Notify Guest About Upcoming Job for {{step_id.event.data.object.address_id}}',
            body: 'Please send a notification to the guest staying at {{step_id.event.data.object.address_id}} informing them of the job scheduled for tomorrow.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a job is complete, confirm everything with the pro.',
        trigger: {
          category: 'Jobs',
          name: 'job completed',
        },
        filter: null,
        actions: [
          {
            subject: 'Job Completion Confirmation Needed for {{step_id.event.data.object.id}}',
            body: 'The job {{step_id.event.data.object.id}} at {{step_id.event.data.object.address_id}} has been marked as complete. Please confirm all details and completion standards with the professional.',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'Once per week, make sure jobs are consistent with my preferences.',
        trigger: {
          category: 'Scheduled',
          name: 'every week',
          inputs: [
            {
              key: 'day_of_the_week',
              value: 5,
            },
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Weekly Review: Ensure Job Consistency with Preferences',
            body: 'Conduct your weekly review to ensure all jobs for the past week align with our established preferences and standards. Our standards are:',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When an inspection is complete, review the results, and based on them book cleaning or maintenance.',
        trigger: {
          category: 'Jobs',
          name: 'job completed',
          inputs: [
            {
              key: 'service_category_keys-radio',
              value: 'Trigger only on some categories',
            },
            {
              key: 'service_category_keys',
              value: ['inspection'],
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Inspection Completed: Review and Action Required for {{step_id.event.data.object.address_id}}',
            body: 'The inspection at {{step_id.event.data.object.address_id}} is complete. Please review the results and book necessary cleaning or maintenance tasks accordingly. Here our our rules on what to do: ',
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'If a job is unlikely to occur before a guest checks in, let me know.',
        trigger: {
          category: 'Scheduled',
          name: 'every day',
          inputs: [
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Potential Job Delay Alert for {{step_id.event.data.object.address_id}}',
            body: "There is a concern that the job scheduled at {{step_id.event.data.object.address_id}} might not be completed before the next guest's check-in. Please investigate and provide an update urgently.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a job is complete, send invoice to accounting in a particular format.',
        trigger: {
          category: 'Jobs',
          name: 'job completed',
        },
        filter: null,
        actions: [
          {
            subject: 'Invoice Submission Required: Job Completed at {{step_id.event.data.object.address_id}}',
            body: "The job at {{step_id.event.data.object.address_id}} has been completed. Please prepare and send the invoice to accounting in the specified format.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a job is complete, update my property management system with custom information.',
        trigger: {
          category: 'Jobs',
          name: 'job completed',
        },
        filter: null,
        actions: [
          {
            subject: 'Update Required in Property Management System for Completed Job',
            body: "Job {{step_id.event.data.object.id}} at {{step_id.event.data.object.address_id}} is complete. Please update our property management system with the relevant custom information.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a low inventory alert is received, take action to resolve.',
        trigger: {
          category: 'Issues',
          name: 'issue created',
          inputs: [
            {
              key: 'issue_type_names-radio',
              value: 'Trigger only on some issue type',
            },
            {
              key: 'issue_type_names',
              value: ['low_inventory'],
            },
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Low Inventory Alert: Immediate Action Required',
            body: "We have received a low inventory alert for {{1.event.data.object.description}} Please take immediate action to replenish this item to avoid service disruptions.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'Once per month, send a summary of pro performance for the prior month.',
        trigger: {
          category: 'Scheduled',
          name: 'every month',
        },
        filter: null,
        actions: [
          {
            subject: 'Monthly Pro Performance Summary Required',
            body: "Please compile and send the monthly performance summary for our professionals covering the last month.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'The day before a job is scheduled, send a guest a notification a job is coming.',
        trigger: {
          category: 'Scheduled',
          name: 'every day',
          inputs: [
            {
              key: 'time_of_day',
              value: 15,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Quarterly Review: Seasonal Maintenance Planning',
            body: "It's time for our quarterly seasonal maintenance review. Please go through the maintenance list and create jobs accordingly for the upcoming season.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'When a cleaning job is completed, schedule an inspection.',
        trigger: {
          category: 'Jobs',
          name: 'job completed',
          inputs: [
            {
              key: 'service_category_keys-radio',
              value: 'Trigger only on some categories',
            },
            {
              key: 'service_category_keys',
              value: ['regular_cleaning'],
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Inspection Scheduling Needed for Recently Completed Cleaning at {{step_id.event.data.object.address_id}}',
            body: "The cleaning job at {{step_id.event.data.object.address_id}} has been completed. Please schedule an inspection to ensure the cleaning meets our quality standards.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'Once per quareter, review my seasonal maintenance list and create jobs based on the list.',
        trigger: {
          category: 'Scheduled',
          name: 'every month',
          inputs: [
            {
              key: 'date_of_the_month',
              value: 1,
            },
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Quarterly Task: Review Seasonal Maintenance List',
            body: "It's time to review the seasonal maintenance list for the quarter. Please create and schedule jobs based on this list to ensure timely maintenance of our properties.",
            key: 'concierge_task.create',
          },
        ],
      },
      {
        description: 'Monthly reporting, once per month draft and send a report of spend following my format.',
        trigger: {
          category: 'Scheduled',
          name: 'every month',
          inputs: [
            {
              key: 'date_of_the_month',
              value: 1,
            },
            {
              key: 'time_of_day',
              value: 8,
            }
          ]
        },
        filter: null,
        actions: [
          {
            subject: 'Monthly Spend Report Draft Required',
            body: "Please prepare and send the monthly spend report. Ensure the report follows our established format and includes all relevant expenditures.",
            key: 'concierge_task.create',
          },
        ],
      },
    ];
  }
}

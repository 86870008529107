import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';

import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { Util } from 'src/shared/util/util';
import { Integrations } from 'src/providers/integrations/integrations';

import { AccountNotificationSettingsPage } from 'src/pages/more/account-notification-settings/account-notification-settings';
import { RemoteLockPage } from '../remote-lock/remote-lock';
import { AddIntegrationPage } from '../add-integration/add-integration';
import { AutomaticBookingPage } from 'src/pages/more/automatic-booking/automatic-booking';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  templateUrl: 'active-integrations.html'
})

export class ActiveIntegrationsPage implements OnInit {

  addresses: any;
  dialogParams: any;
  isRightSideContent: boolean;
  loaded: boolean;
  remoteAccessAddresses: any;
  webhooks: any;
  shownActiveIntegrations: any;
  activeIntegrations: any;

  constructor(
    private guestReservations: GuestReservations,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private snackBar: MatSnackBar,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    private webhooksService: Webhooks,
    private integrations: Integrations
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Active Integrations');
    }
    this.webhooks = this.navCtrl.getParam('webhooks') || this.dialogParams.webhooks;
    this.remoteAccessAddresses = this.navCtrl.getParam('remoteAccessAddresses') || this.dialogParams.remoteAccessAddresses;
    this.addresses = this.navCtrl.getParam('addresses') || this.dialogParams.addresses;
    this.activeIntegrations = this.navCtrl.getParam('activeIntegrations') || this.dialogParams.activeIntegrations;
    this.shownActiveIntegrations = this.activeIntegrations;
    this.loaded = true;
  }

  goToRemoteLockPage() {
    const params = {
      remoteAccessAddresses: this.remoteAccessAddresses
    }
    this.rightSidePanelService.navigateTo('remotelock', params, RemoteLockPage);
  }

  goToAccountNotificationsPage() {
    const params = {
      webhooks: this.webhooks
    }
    this.rightSidePanelService.navigateTo('account-notifications', params, AccountNotificationSettingsPage);
  }

  goToUpdateIntegration(integration) {
    const params = {
      reservationSrcType: {
        name: integration.guest_reservation_source_type.name,
        id: integration.guest_reservation_source_type.id,
        logo_url: integration.guest_reservation_source_type.logo_url,
      },
      sourceType: integration.guest_reservation_source_type.format,
      isUpdatingIntegration: true
    }
    this.rightSidePanelService.navigateTo('add-integration', params, AddIntegrationPage);
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    this.snackBar.open('Copied to clipboard', 'Close', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['default-snackbar']
    })
  }

  async disableIntegration(id) {
    const params: ConfirmPageParamsModel = {
      title: 'Disable Integration?',
      body: 'This will remove any existing reservations from TIDY.',
      backText: 'Go Back',
      confirmText: 'Disable',
      confirmAction: await this.confirmDisableIntegration.bind(this, id),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDisableIntegration(id) {
    try {
      await this.integrations.deactivateIntegration(id);
      await this.getActiveIntegrations();
      await this.storage.save('didMakeIntegrationChanges', true);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  goToAutomaticBooking() {
    const params = {
      showBackButton: true
    };
    this.rightSidePanelService.navigateTo('automatic-booking', params, AutomaticBookingPage);
  }

  goToAutomaticBookingAddress(integration) {
    const address = this.addresses.find((address) => address.id == integration.address_id);
    const params = {
      address: address
    };
    this.rightSidePanelService.navigateTo('automatic-booking-property', params, AutomaticBookingAddressPage);
  }

  async disableGuestReservationIntegration(id) {
    const params: ConfirmPageParamsModel = {
      title: 'Disable Integration?',
      body: 'This will remove any existing reservations from TIDY.',
      backText: 'Go Back',
      confirmText: 'Disable',
      confirmAction: await this.confirmDisableGuestReservationIntegration.bind(this, id),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDisableGuestReservationIntegration(id) {
    try {
      await this.guestReservations.deactivateIntegration(id);
      await this.getActiveIntegrations();
      await this.storage.save('didMakeIntegrationChanges', true);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
      throw err;
    }
  }

  async getActiveIntegrations() {
    await this.getActiveGuestReservationSourceIntegrations();
    await this.getActiveLockIntegrations();
    await this.getActiveWebhookIntegrations();
  }

  async getActiveGuestReservationSourceIntegrations() {
    let activeIntegrationsResponse = await this.guestReservations.getReservationSource();
    activeIntegrationsResponse = this.guestReservations.addIntegrationsAddressInfo(activeIntegrationsResponse, this.addresses);
    this.activeIntegrations = activeIntegrationsResponse.filter(integration => !this.showPendingOrIssueAlert(integration));
    this.shownActiveIntegrations = this.activeIntegrations;
  }

  async getActiveLockIntegrations() {
    const lockIntegrations = await this.integrations.getIntegrations();
    lockIntegrations.map((integration) => {
      if (integration.type == 'remote_property_access' && integration.key == 'remote_lock') {
        this.activeIntegrations.push({
          format: 'lock',
          integration: integration
        });
      }
    });
  }

  async getActiveWebhookIntegrations() {
    this.webhooks = await this.webhooksService.getWebhooks();
    this.webhooks.map((integration) => {
      this.activeIntegrations.push({
        format: 'notification',
        integration: integration
      });
    });
  }

  showPendingOrIssueAlert(integration) {
    if (integration?.guest_reservation_source_type?.format !== 'concierge_integration') {
      if (!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length <= 0) {
        return true;
      }
      if (!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length > 0) {
        return true;
      }
    }
    if (integration.state == 'paused') {
      return true;
    }
    return false;
  }

  goToIntegrationSettings(integration) {
    const params = {
      integration: integration
    };
    this.navCtrl.navigateForward('integration-settings', params);
  }
}

export function humanize(str: string): string {

  try {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) {
        return m.toUpperCase();
      });
  } catch (error) {
    return str;
  }
}

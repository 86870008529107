<ng-content>
</ng-content>
<p *ngIf="erroMsg">{{this.erroMsg}}</p>
<p *ngIf="logdebug">{{this.logdebug}}</p>


<ng-container *ngIf="!hasRecorded && !videoIsUploading">
  <tidy-row class="extra-bottom-padding">
    <video id="stream-video" autoplay playsinline muted style="width: 100%; min-height: 339px;"></video>
  </tidy-row>

  <tidy-row *ngIf="cameraStatus === 'standby'">
    <tidy-button
      [text]="'Start Recording'"
      [action]="record.bind(this)"
      class="primary">
    </tidy-button>
  </tidy-row>

  <tidy-row *ngIf="cameraStatus === 'recording'">
    <tidy-button
      [text]="'Stop Recording'"
      [action]="stop.bind(this)"
      class="primary">
    </tidy-button>
  </tidy-row>

</ng-container>

<ng-container *ngIf="videoIsUploading">
  <tidy-card style="height:339px; padding:0px">
    <ion-skeleton-text animated style="width:100%; height:339px"></ion-skeleton-text>
  </tidy-card>
  <tidy-row [align]="'center'">
    <tidy-text
      [body]="'Wait until we upload your video...'">
    </tidy-text>
  </tidy-row>
</ng-container>

<ng-container *ngIf="hasRecorded">
  <tidy-row class="extra-bottom-padding">
    <video autoplay playsinline muted loop style="width: 100%; min-height: 339px;"><source [src]="awsUrl" type="video/mp4"></video>
  </tidy-row>

  <tidy-button
    [text]="'Looks Good'"
    [action]="submitVideo.bind(this)"
    class="primary"
    style="padding-bottom:10px">
  </tidy-button>

  <tidy-button
    [text]="'Try Again'"
    [action]="setStream.bind(this)"
    class="secondary">
  </tidy-button>
</ng-container>

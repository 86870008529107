import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { CameraResultType, ImageOptions, CameraSource, Photo } from '@capacitor/camera';

import { Aws } from 'src/providers/aws/aws';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { WindowService } from 'src/shared/providers/window.service';

import { Util } from 'src/shared/util/util';

const defaultOptions: ImageOptions = {
  width: 1080,
  height: 1080,
  resultType: CameraResultType.Base64,
  source: CameraSource.Camera,
  correctOrientation: true,
  saveToGallery: true
};

export const takePhotoOptions: ImageOptions = {
  quality: 85,
  ...defaultOptions
};

@Component({
  templateUrl: 'input-to-dos.html',
  encapsulation: ViewEncapsulation.None
})

export class InputToDosPage implements OnInit {

  addressForm: UntypedFormGroup;
  addressId: any;
  addressList: any;
  alreadyUploadedPhotos: any;
  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  inputtedToDos: any;
  isRightSideContent: boolean;
  isNativeMobile = Capacitor.isNativePlatform();
  loaded: boolean;
  rooms: any;
  services: any;
  submitted: boolean;

  constructor(
    private aws: Aws,
    private camera: CameraProvider,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos,
    private util: Util,
    public windowService: WindowService
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rooms = this.navCtrl.getParam('rooms') || this.dialogParams.rooms;
    this.rightSidePanelService.setDialogPageTitle('Edit To-Do Inputs');
    await this.convertTakePhotoValuesToBase64();
    this.formatAllowedValuesForFields();
    this.addCustomFields();
    this.useCustomerPerformanceIfPresent();
    await this.patchFormWithInputtedToDos();
    this.buildInitialInputedToDos();
    this.loaded = true;
  }

  async convertTakePhotoValuesToBase64() {
    //tidy-file-upload component only deals with base64 strings, so FE converts any already uploaded AWS image URL to a base64
    //FE also builds an array of alreadyUploadedPhotos to ensure we don't re-upload them upon saving
    this.alreadyUploadedPhotos = [];
    await Promise.all(this.rooms.map(async (room) => {
      await Promise.all(room.todos.map(async (toDo) => {
        await Promise.all(toDo.options.map(async (option) => {
          if (option.name.includes('Take Photo') && option.jsonValues) {
            let values = await Promise.all(option.jsonValues.map(async (value) => {
              const base64 = await this.convertAWSURLToBase64(value.value);
              this.alreadyUploadedPhotos.push({
                url: value.value,
                base64: base64
              });
              return {
                url: base64,
                mediaType: 'photo'
              };
            }));
            option.jsonValues = values;
          }
        }));
      }));
    }));
  }

  async convertAWSURLToBase64(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch image');
      const imageBlob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      return null;
    }
  }

  formatAllowedValuesForFields() {
    this.rooms.map((room) => {
      room.todos.map((todo) => {
        todo.options.map((option) => {
          let array = [];
          option.allowed_values?.map((value) => {
            array.push({
              value: value,
              viewValue: value
            });
          });
          option.allowed_values = array
        });
      });
    });
  }

  addCustomFields() {
    this.rooms.map((room) => {
      room.todos.map((toDo) => {
        let group = {};
        group['taskId'] = [toDo.id];
        toDo.options.map((customField) => {
          if (customField.name !== 'Checkbox(es)') {
            if (customField?.required) {
              group[customField.id] = ['', Validators.required];
            } else {
              group[customField.id] = [''];
            }
          } else {
            customField.allowed_values.map((allowedValue) => {
              group[customField.id + '-' + allowedValue.viewValue] = [false];
            });
          }
        });
        if (toDo.options?.length > 0) {
          const customFieldForm: UntypedFormGroup = this.fb.group(group);
          toDo['customFieldForm'] = customFieldForm;
        }
      });
    });
  }

  useCustomerPerformanceIfPresent() {
    this.rooms.map((room) => {
      room.todos.map((toDo) => {
        if (toDo.customer_performance) {
          toDo.performance = toDo.customer_performance;
        }
      })
    })
  }

  async patchFormWithInputtedToDos() {
    this.rooms.map((room, roomIndex) => {
      room.todos.map((toDo, toDoIndex) => {
        toDo.options.map((option) => {
          const isTakePhotoField = option?.jsonValues;
          const didCustomerChangeToDos = option?.customer_value;
          if (isTakePhotoField) {
            option.value = option.jsonValues; //to-dos provider already handles building a jsonValues array with using customer_value if present, else uses json_value
          } else if (didCustomerChangeToDos) {
            option.value = option.customer_value;
          } else {
            option.value = option.json_value;
          }
          if (option.value !== '' && option.value) {
            const isCheckbox = this.checkIfOptionIsCheckbox(option.id);
            if (isCheckbox) {
              const valueArray = option.value.split(', ');
              valueArray.map((value) => {
                this.rooms[roomIndex].todos[toDoIndex].customFieldForm.get(option.id.toString() + '-' + value).patchValue(true);
              });
            } else {
              this.rooms[roomIndex].todos[toDoIndex].customFieldForm.get(option.id.toString()).patchValue(option.value);
            }
          }
        });
      });
    });
  }

  checkIfOptionIsCheckbox(taskOptionId) {
    let optionIsCheckbox = false;
    this.rooms.map((room) => {
      room.todos.map((task) => {
        task.options.map((option) => {
          if (taskOptionId == option.id && option.name == 'Checkbox(es)') {
            optionIsCheckbox = true;
          }
        });
      });
    });
    return optionIsCheckbox;
  }

  buildInitialInputedToDos() {
    this.inputtedToDos = [];
    this.rooms.map((room) => {
      room.todos.map((toDo) => {
        this.updateInputedToDos(toDo, room);
      });
    });
  }

  async updateInputedToDos(toDo, room) {
    let data = {
      task_id: toDo.id,
      performance: toDo.performance,
      job_task_options: []
    }
    toDo.options?.map(option => {
      data.job_task_options.push({
        id: option.id,
        value: this.parseCustomFormFieldValue(toDo, option)
      });
    });
    const index = this.inputtedToDos.findIndex(item => item.task_id === toDo.id);
    if (index !== -1) {
      this.inputtedToDos[index] = data;
    } else {
      this.inputtedToDos.push(data);
    }
  }

  parseCustomFormFieldValue(toDo, option) {
    if (option.name == 'Checkbox(es)') {
      let selections = '';
      option.allowed_values.map((value, index) => {
        if (toDo.customFieldForm?.value[option.id + '-' + value.viewValue]) {
          selections += value.viewValue + ', ';
        }
      });
      return selections.substring(0, selections.length - 2);
    } else {
      return toDo.customFieldForm?.value[option.id];
    }
  }

  async takeCustomToDoPhoto(field, toDoIndex, roomIndex, toDo, room) {
    this.camera.takeCustomToDoPhoto(takePhotoOptions).then((photo: Photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto = photo?.dataUrl && !photo?.base64String ? photo?.dataUrl : `data:image/jpeg;base64,${photo.base64String}`;
      const { photoField, photoFieldValue } = this.getPhotoField(field, toDoIndex, roomIndex);
      const newPhoto = { url: parsedPhoto, mediaType: 'photo' };
      photoField.patchValue([...photoFieldValue, newPhoto]);
      this.updateInputedToDos(toDo, room);
    });
  }

  async uploadCustomToDoPhoto(event, field, toDoIndex, roomIndex, toDo, room) {
    const { photoField, photoFieldValue } = this.getPhotoField(field, toDoIndex, roomIndex);
    photoField.patchValue([...photoFieldValue, ...event]);
    this.updateInputedToDos(toDo, room);
  }

  async removeCustomToDoPhoto(event, field, toDoIndex, roomIndex, toDo, room) {
    const alertOptions = {
      header: 'Remove Photo',
      message: 'Are you sure you want to remove this photo?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Remove',
          role: 'confirm',
        },
      ],
    };
    const alert = await this.util.showConfirmAlert(alertOptions);
    alert.onDidDismiss().then(async (res) => {
      if (res.role !== 'confirm') {
        return;
      }
      const existingPhotoIndex = this.alreadyUploadedPhotos.findIndex(photo => photo.base64 === event.image.url);
      if (existingPhotoIndex !== -1) {
        this.alreadyUploadedPhotos.splice(existingPhotoIndex, 1);
      }
      const { photoField, photoFieldValue } = this.getPhotoField(field, toDoIndex, roomIndex);
      photoFieldValue.splice(event.index, 1);
      photoField.patchValue(photoFieldValue);
      this.updateInputedToDos(toDo, room);
    });
  }

  getPhotoField(field, toDoIndex, roomIndex) {
    const photoField = this.rooms[roomIndex].todos[toDoIndex].customFieldForm.get(field.id.toString());
    const photoFieldValue = photoField?.value || [];
    return { photoField, photoFieldValue };
  }
  
  async saveChanges() {
    try {
      const payload = {
        to_dos: await this.buildPayload()
      }
      await this.toDos.updateInputedToDos(payload, this.navCtrl.getParam('jobId') || this.dialogParams.jobId);
      if (this.isRightSideContent) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateBack(this.navCtrl.getParam('nextPage') || this.dialogParams.nextPage);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(this.errorMessage, 10000);
    }
  }

  buildPayload() {
    const promiseArray = this.inputtedToDos.map(async (toDo) => {
      if (toDo.job_task_options) {
        await Promise.all(toDo.job_task_options.map(async (option) => {
          const isTakePhotoTask = this.checkIfIsTakePhotoTask(option.id);
          if (isTakePhotoTask) {
            if (Array.isArray(option.value)) {
              const awsUrls = await Promise.all(option.value.map(async (item, index) => {
                const alreadyUploadedImageURL = this.getAlreadyUploadedImageURLs(item.url);
                if (alreadyUploadedImageURL) {
                  return alreadyUploadedImageURL;
                } else {
                  const url = `inputtedToDos/addressTask/${option.id}/${index}`;
                  const aws = await this.aws.uploadImageToS3(item.url, url);
                  return aws.Location;
                }
              }));
              option.value = awsUrls;
            } else {
              const alreadyUploadedImageURL = this.getAlreadyUploadedImageURLs(option.value);
              if (alreadyUploadedImageURL) {
                option.value = alreadyUploadedImageURL;
              } else {
                const url = `inputtedToDos/addressTask/${option.id}`;
                const aws = await this.aws.uploadImageToS3(option.value, url);
                option.value = aws.Location;
              }   
            }
          }
        }));
      }
      return {
        task_id: toDo.task_id,
        performance: toDo.performance,
        job_task_options: toDo.job_task_options.map((option) => {
          return {
            id: option.id,
            value: option.value
          }
        })
      }
    });
    return Promise.all(promiseArray);
  }

  checkIfIsTakePhotoTask(addressTaskOptionId) {
    let isPhototask = false;
    this.rooms.map((room) => {
      room.todos.map((toDo) => {
        toDo.options.map((option) => {
          if (option.id == addressTaskOptionId && option.name.includes('Take Photo')) {
            isPhototask = true;
          }
        });
      });
    });
    return isPhototask;
  }

  getAlreadyUploadedImageURLs(base64) {
    let alreadyUploadedURL = null;
    this.alreadyUploadedPhotos.map((photo) => {
      if (photo.base64 == base64) {
        alreadyUploadedURL = photo.url;
      }
    });
    return alreadyUploadedURL;
  }

}

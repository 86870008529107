<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Card Photo'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <div>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row
          class="text-center extra-bottom-padding"
        >
          <tidy-image
            class="full-width"
            [src]="photoUrl"
          ></tidy-image>
        </tidy-row>
        <tidy-row>
          <tidy-input
            [label]="'Add the photo caption'"
            [icon]="'assets/svg/camera-outline.svg'"
            [activeIcon]="'assets/svg/camera-outline-tidy-green.svg'"
            [(ngModel)]="caption"
            [form]="formControl">
          </tidy-input>
        </tidy-row>
        <tidy-row class="text-center">
          <tidy-text
            [body]="'Retake Photo'"
            class="link red"
            (action)="retakeImage()"
          ></tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <tidy-error-message
          *ngIf="errorMessage?.length"
          [text]="errorMessage"
        >
        </tidy-error-message>
      </tidy-row>

      <tidy-button
        [action]="save.bind(this)"
        [text]="'Save'"
        class="primary"
      >
      </tidy-button>
    </tidy-wrapper>
  </div>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


<!--<div class="onboarding-header">
  <div class="onboarding-header-title">
    <tidy-text [title]="'Onboarding'"></tidy-text>
  </div>
  <div>
    <button mat-button fill="clear" color="primary" class="blue-button" (click)="openAdvancedSetup()">
      <img src="assets/svg/chat-circle.svg" class="chat-icon" />
      <tidy-text
        [body]="'<strong>Free Advanced Setup</strong>'"
        class="setup-text"></tidy-text>
    </button>
    <button mat-button fill="clear" color="primary" class="black-button-close" style="min-width: 0px" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>-->

<div class="onboarding-container-padding">
  <div class="onboarding-goals" #confettiContainer>
    <tidy-row class="extra-bottom-padding">
      <tidy-text [title]="'Your Goals'"></tidy-text>
    </tidy-row>
    <div *ngFor="let goal of goals" class="accordion-item">
      <tidy-goal-tasks-card
        [goal]="goal"
        (skipGoal)="skipGoal(goal)"
        (goToTaskDetails)="goToStepDetails($event)"
        (openVideo)="openVideo(goal)">
      </tidy-goal-tasks-card>
    </div>
    <div class="accordion-item">
      <div class="accordion-header">
        <div class="accordion-header-content">
          <div
            class="goal-icon-container"
            style="background-color: rgb(243 232 255)">
            <img src="assets/svg/message-circle-purple.svg" class="goal-icon" />
          </div>
          <tidy-text [body]="'<strong>Advanced</strong>'"> </tidy-text>
        </div>
      </div>

      <div class="accordion-content">
        <form [formGroup]="form">
          <ng-container *ngFor="let task of form.controls.tasks.value">
            <div style="display: flex; align-items: center; gap: 1rem">
              <tidy-checkbox
                (click)="$event.stopPropagation()"
                class="checkbox-container"
                [items]="[{value: true, viewValue: ''}]"
                [initValue]="false">
              </tidy-checkbox>
              <div style="flex: 1">
                <tidy-text [body]="task.title"></tidy-text>
              </div>
            </div>
          </ng-container>
          <div style="display: flex; align-items: center; gap: 1rem">
            <div style="flex: 1">
              <tidy-input
                formControlName="task"
                [form]="form"
                label="Add new task">
              </tidy-input>
            </div>
            <div>
              <button mat-raised-button class="black-button" (click)="addStep()">
                <tidy-text [headerButton]="'+ Add'" class="white"></tidy-text>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <tidy-row
      style="margin-top: 2rem; margin-bottom: 2rem; cursor: pointer"
      [alignLastItemRight]="true"
      (click)="showSuggestions = !showSuggestions">
      <tidy-text [title]="'Additional Suggestions'"></tidy-text>

      <div>
        <img
          src="assets/svg/chevron-right.svg"
          class="chevron-icon"
          [style.transform]="showSuggestions ? 'rotate(-90deg)' : 'rotate(90deg)'" />
      </div>
    </tidy-row>
    <ng-container *ngIf="showSuggestions">
      <div *ngFor="let goal of unchosenGoals" class="accordion-item">
        <tidy-goal-tasks-card
          [goal]="goal"
          [isUnchosenGoal]="true"
          (addGoal)="addGoal(goal)"
          (goToTaskDetails)="goToStepDetails($event)"
          (openVideo)="openVideo(goal)">
        </tidy-goal-tasks-card>
      </div>
    </ng-container>
  </div>
</div>

<div class="backdrop"></div>

import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Util } from 'src/shared/util/util';
import { Communication } from 'src/providers/communication/communication';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Me } from 'src/providers/me/me';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'add-proxy-number',
  templateUrl: 'add-proxy-number.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./add-proxy-number.scss'],
})
export class AddProxyNumberComponent implements OnInit {
  isMobileResolution = false;
  pageTitle = 'Create a Guest Number';
  dialogParams: any;
  numberType: string;
  number: string;
  form: UntypedFormGroup;

  constructor(
    private storage: TidyStorage,
    private rightSidePanel: RightSidePanelService,
    private communication: Communication,
    private util: Util,
    private fb: FormBuilder,
    private navCtrl: CustomNavController,
    public me: Me,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      enableAI: [false, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    const enableAI = (await this.storage.retrieve('enableAI')) || false;
    this.form.patchValue({ enableAI });
    this.watchCheckboxChanges();
    this.isMobileResolution = this.windowService.isDesktopRes === false;
    if (this.isMobileResolution) {
      this.dialogParams = await this.navCtrl.getParams();
    } else {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.numberType = this.dialogParams?.type;
    this.number = this.dialogParams?.number;
    const firstWord = this.number ? 'Your' : 'Create'
    if (this.numberType === 'pro') {
      this.pageTitle = firstWord + ' Pro Smart Number';
    } else {
      this.pageTitle = firstWord + ' Guest Smart Number';
    }
    this.rightSidePanel.setDialogPageTitle(new TranslationPipe().transform(this.pageTitle));
  }

  copyToClipboard(number: string): void {
    this.util.copyToClipBoard(number);
    this.util.showSuccess('Number copied to clipboard.');
  }

  async createProxyNumber(): Promise<void> {
    const subscriptionTypes = await this.me.getSubscriptionTypes();
    const hasNonTrialPaidSubscription = await this.me.checkIfHasNonTrialPaidSubscription(subscriptionTypes);
    if (!hasNonTrialPaidSubscription) {
      const text = new TranslationPipe().transform('You must be on a paid subscription to ' + (this.numberType === 'pro' ? 'create a pro number' : 'create a guest number') + '. You can edit your subscription here.');
      this.util.showError(text);
      return this.rightSidePanel.navigateTo('subscription', {});
    }
    const loading = await this.util.showLoading();
    const numberType = this.numberType === 'pro' ? 'homekeeper' : 'guest';
    await this.communication.createProxyNumber(numberType).then((response) => {
      this.util.copyToClipBoard(response.phone_number);
      this.util.showSuccess('Number created successfully and copied to clipboard.');
      this.number = response.phone_number.replace(/^\+\d{1,3}(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
      this.storage.save('created-number', { type: numberType, number: this.number });
      loading.dismiss();
    }).catch((error) => {
      console.error('Error creating number', error);
      loading.dismiss();
      this.util.showWarning('Failed to create number. Please try again.');
    });
  }

  watchCheckboxChanges(): void {
    this.form.controls.enableAI.valueChanges.subscribe((value) => {
      this.storage.save('enableAI', value);
      if (value) {
        this.util.showSuccess('Added to waitlist.');
      }
    })
  }
}

import { Util } from 'src/shared/util/util';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DateTime as LuxonDateTime } from 'luxon';
import {
  EventTypes,
  Period,
  Properties,
  Property,
  Reservation,
  Job,
  Event,
  ClickAction,
  ClickTargets,
  AvailabilityRequest,
} from 'src/models/schedule-weekly-view.model';
import { ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ViewChild, ElementRef } from '@angular/core';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Booking } from 'src/providers/booking/booking';
import { Schedule } from 'src/providers/schedule/schedule';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'schedule-weekly-view.html',
  styleUrls: ['./schedule-weekly-view.scss'],
  selector: 'tidy-schedule-weekly-view',
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleWeeklyViewComponent implements OnChanges, AfterViewInit {
  @ViewChild('assignProMenuTrigger') assignProMenuTrigger: MatMenuTrigger;
  @Input() isMobileResolution: boolean;
  @Input() selectedPeriodRange: Period;
  @Input() daysOfWeek: string[];
  @Input() properties: Properties;
  @Input() viewMode: string;
  @Output() onClickAction = new EventEmitter<ClickAction>();
  @Output() onAssignedPro = new EventEmitter<ClickAction>();
  today = LuxonDateTime.local().toISODate();
  hasPrivatePros: boolean;
  sortProperty: string;
  sortDirection: 'asc' | 'desc';
  allProperties: Properties;
  wasCardClicked = false;
  @ViewChild('scrollableContainer', { static: true })
  scrollableContainer: ElementRef;
  privatePros: any;
  showAllTimesProposals: any[] = [];
  language: string;
  
  fixedColumnLeft = '0px';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const scrollEvent = {
      target: this.scrollableContainer.nativeElement,
    };
    this.updateFixedColumnLeft(scrollEvent);
  }

  constructor(
    public bookJobService: BookJob,
    private booking: Booking,
    private changeDetectorRef: ChangeDetectorRef,
    private client: Client,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private util: Util,
    public schedule: Schedule,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService
  ) {}

  async ngAfterViewInit() {
    this.language = localStorage.getItem('language');
    this.hasPrivatePros = await this.pros.checkIfHasPrivatePro();
    this.scrollableContainer.nativeElement.addEventListener(
      'scroll',
      (event: any) => {
        this.updateFixedColumnLeft(event);
      }
    );
    this.schedule.weeklyViewContainer = this.scrollableContainer.nativeElement;
    this.schedule.weeklyViewContainer.scrollTop =
      this.schedule.weeklyViewScrollPosition;
  }

  updateFixedColumnLeft(event: any) {
    this.fixedColumnLeft = '0px';
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(): void {
    // Show all properties at once
    this.properties = this.properties;
    this.allProperties = this.properties;
    this.sortDirection = 'asc';
    this.sortProperty = this.daysOfWeek[0];
  }

  getDayFromWeekBasedOnWeekday(weekday: string, format = 'MM-dd-yyyy'): string {
    const startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate);
    const endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate);
    
    // Check if this is the Mon-Sun week view (this will have exactly 7 days from Monday to Sunday)
    const isMonSunWeek = this.viewMode === 'WEEK_MON_SUN' || 
                         (startDate.weekday === 1 && // Monday
                          endDate.weekday === 7 && // Sunday
                          endDate.diff(startDate, 'days').days === 6);
                          
    for (let day = startDate; day <= endDate; day = day.plus({ days: 1 })) {
      if (day.toFormat('EEEE') === weekday) {
        if (this.language && this.language !== 'en') {
          const monthName = day.toFormat('MMMM');
          const translatedMonth = new TranslationPipe().transform(monthName);
          const dayNum = day.toFormat('d');
          return `${translatedMonth.slice(0,3)} ${dayNum}`;
        }
        return day.toFormat(format);
      }
    }
    return null;
  }

  getNextReservationDate(property) {
    const format = this.language && this.language !== 'en' ? 'MMMM d' : 'MMM d';
    let date = LuxonDateTime.fromFormat(property.nextReservationDate, 'yyyy-MM-dd').toFormat(format);
    if (this.language && this.language !== 'en') {
      const month = date.split(' ')[0]; 
      const day = date.split(' ')[1];
      return `${new TranslationPipe().transform(month).slice(0,3)} ${day}`;
    } else {
      return date;
    }
  }

  getNextJobDate(property) {
    const format = this.language && this.language !== 'en' ? 'MMMM d' : 'MMM d';
    let date = LuxonDateTime.fromFormat(property.nextJobDate, 'yyyy-MM-dd').toFormat(format);
    if (this.language && this.language !== 'en') {
      const month = date.split(' ')[0]; 
      const day = date.split(' ')[1];
      return `${new TranslationPipe().transform(month).slice(0,3)} ${day}`;
    } else {
      return date;
    }
  }

  hasEventAtProperty(
    day: string,
    property: Property,
    eventType: EventTypes
  ): boolean {
    return !!this.getEventAtProperty(day, property, eventType);
  }

  daysBetweenWeekdays(startWeekday: string, endWeekday: string): number {
    const weekdays = this.daysOfWeek;
    const startIndex = weekdays.indexOf(startWeekday);
    const endIndex = weekdays.indexOf(endWeekday);
    if (startIndex === -1 || endIndex === -1) {
      return -1;
    }
    let daysDiff = endIndex - startIndex;
    if (daysDiff < 0) {
      daysDiff += 7;
    }
    return daysDiff;
  }

  getReservationAtPeriodLength(
    property: Property,
    event: Job | Reservation
  ): number | null {
    //TODO add better logic here to check the max number of reservations this proeprty has on a given day
    const reservations = property.filteredReservations.filter(
      (reservation) =>
        LuxonDateTime.fromISO(reservation.startDate) <=
          LuxonDateTime.fromISO(event.startDate) ||
        LuxonDateTime.fromISO(reservation.endDate) >=
          LuxonDateTime.fromISO(event.endDate)
    );
    return reservations.length;
  }

  hasJobAtPeriod(property: Property, event: Job | Reservation): Job | null {
    return property.filteredJobs.find(
      (job) =>
        LuxonDateTime.fromISO(job.startDate) <=
          LuxonDateTime.fromISO(event.startDate) &&
        LuxonDateTime.fromISO(job.endDate) >=
          LuxonDateTime.fromISO(event.endDate) &&
        job.id !== event.id
    );
  }

  someReservationStartsAtSameWeekday(
    property: Property,
    event: Job | AvailabilityRequest
  ): boolean {
    return property.filteredReservations.some(
      (reservation) =>
        reservation.startWeekday === event.startWeekday &&
        reservation.id !== event.id
    );
  }

  someReservationEndsAtSameWeekday(
    property: Property,
    event: Job | AvailabilityRequest
  ): boolean {
    return property.filteredReservations.some(
      (reservation) =>
        reservation.endWeekday === event.endWeekday &&
        reservation.id !== event.id
    );
  }

  hasSomeReservationBetweenJobDay(
    property: Property,
    event: Job | AvailabilityRequest
  ): boolean {
    return property.filteredReservations.some((reservation) => {
      const reservationStart = LuxonDateTime.fromISO(reservation.startDate);
      const reservationEnd = LuxonDateTime.fromISO(reservation.endDate);
      const eventStart = LuxonDateTime.fromISO(event.startDate);
      const eventEnd = LuxonDateTime.fromISO(event.endDate);

      return (
        (reservationStart <= eventStart && reservationEnd >= eventStart) ||
        (reservationStart <= eventEnd && reservationEnd >= eventEnd) ||
        (reservationStart >= eventStart && reservationEnd <= eventEnd)
      );
    });
  }

  moreThanOneReservationEndsAtSameDay(
    property: Property,
    event: Job | AvailabilityRequest
  ): boolean {
    return (
      property.filteredReservations.filter(
        (reservation) =>
          reservation.endWeekday === event.endWeekday &&
          reservation.id !== event.id
      ).length > 1
    );
  }

  getPaddingTop(
    event: Job | AvailabilityRequest,
    property: Property,
    eventType: EventTypes,
    index: number
  ): number {
    let paddingTop = 5;
    if (index > 0) {
      paddingTop = 0;
      return paddingTop;
    }
    const hasSomeReservationBetweenJobDay =
      this.hasSomeReservationBetweenJobDay(property, event);
    const moreThanOneReservationEndsAtSameDay =
      this.moreThanOneReservationEndsAtSameDay(property, event);
    const startsAtSameDay = this.someReservationStartsAtSameWeekday(
      property,
      event
    );
    const reservationAtPeriodLength = this.getReservationAtPeriodLength(
      property,
      event
    );
    const twoOrMoreReservationsStartsOnTheFirstWeekDay = this.twoOrMoreReservationsStartsOnTheFirstWeekDay(property);
    const isFirstWeekDay = event.startWeekday === this.daysOfWeek[0];
    if (hasSomeReservationBetweenJobDay && (!startsAtSameDay || twoOrMoreReservationsStartsOnTheFirstWeekDay && !isFirstWeekDay)) {
      paddingTop =
        reservationAtPeriodLength > 1 && moreThanOneReservationEndsAtSameDay
          ? (34 + (10 * reservationAtPeriodLength))
          : 34;
        if (twoOrMoreReservationsStartsOnTheFirstWeekDay && !isFirstWeekDay) {
          if (startsAtSameDay) {
            paddingTop = paddingTop + 5;
          } else {
            paddingTop = 44 + (10 * reservationAtPeriodLength);
          }
        }
    }
    return paddingTop;
  }

  twoOrMoreReservationsStartsOnTheFirstWeekDay(property: Property): boolean {
    return property.filteredReservations.filter(
      (reservation) => reservation.startWeekday === this.daysOfWeek[0]
    ).length > 1;
  }

  getEventAtProperty(
    day: string,
    property: Property,
    eventType: EventTypes
  ): Event {
    const eventsAtDay = (property[eventType] as (Job | Reservation)[]).filter(
      (event) =>
        event.startWeekday === day &&
        this.util.isDateInsidePeriod(event.startDate, this.selectedPeriodRange)
    );
    const event: Reservation | Job = eventsAtDay?.[0];
    if (!event) {
      return null;
    }
    const width = this.getEventWidth(event, eventType);
    return {
      ...event,
      width,
    };
  }

  getJobsAtProperty(day: string, property: Property): Event[] {
    const jobsAtDay: Job[] = property.filteredJobs.filter(
      (job) =>
        job.startWeekday === day &&
        this.util.isDateInsidePeriod(job.startDate, this.selectedPeriodRange)
    );
    return jobsAtDay.map((job, index) => {
      const width = this.getEventWidth(job, 'jobs');
      const paddingTop = this.getPaddingTop(job, property, 'jobs', index);
      return {
        ...job,
        width,
        paddingTop,
      };
    });
  }

  getAvailabilityRequestsAtProperty(day: string, property: Property): Event[] {
    const requestsAtDay: AvailabilityRequest[] =
      property.filteredAvailabilityRequests.filter(
        (request) =>
          request.startWeekday === day &&
          this.util.isDateInsidePeriod(
            request.startDate,
            this.selectedPeriodRange
          )
      );
    return requestsAtDay.map((request, index) => {
      const width = this.getEventWidth(request, 'jobs');
      const paddingTop = this.getPaddingTop(
        request,
        property,
        'availabilityRequests',
        index
      );
      return {
        ...request,
        width,
        paddingTop,
      };
    });
  }

  getReservationsAtProperty(day: string, property: Property): Event[] {
    const reservationsAtDay: Reservation[] = property.filteredReservations.filter(
      (reservation) =>
        reservation.startWeekday === day &&
        this.util.isDateInsidePeriod(
          reservation.startDate,
          this.selectedPeriodRange
        )
    );
    return reservationsAtDay.map((reservation, index) => {
      const width = this.getEventWidth(reservation, 'reservations');
      return {
        ...reservation,
        width,
      };
    });
  }

  /**
   * Track by function for ngFor loops to improve performance by not re-rendering the DOM elements
   * @param index
   * @param item
   * @returns
   */
  trackByFn(index, item) {
    return item.id;
  }

  getEventWidth(event: Reservation | Job, eventType: EventTypes): number {
    const weekdays = this.daysOfWeek;
    const startIndex = weekdays.indexOf(event.startWeekday);
    const endIndex = weekdays.indexOf(event.endWeekday);
    const daysDiff = endIndex - startIndex;
    const cardWidth = eventType === 'jobs' ? 108 : 108;

    // Fixed width adjustments for widest screen
    const widthAdjustments = {
      7: -23,
      6: -20,
      5: -17,
      4: -13,
      3: -10,
      2: -7,
      1: -4,
    };

    if (daysDiff < 0) {
      const lastWeekday = weekdays[weekdays.length - 1];
      const lastWeekdayDaysDiff = this.daysBetweenWeekdays(
        event.startWeekday,
        lastWeekday
      );
      return (
        cardWidth * lastWeekdayDaysDiff +
        cardWidth +
        (widthAdjustments[lastWeekdayDaysDiff] || 0)
      );
    }

    const totalDaysInWeek = 7;
    const eventDurationInDays =
      this.daysBetweenWeekdays(event.startWeekday, event.endWeekday) + 1;
    const eventWidthUnits =
      (eventDurationInDays / totalDaysInWeek) * cardWidth * 7;

    return eventWidthUnits + (widthAdjustments[eventDurationInDays] || 0);
  }

  emitClickAction(
    target: ClickTargets,
    property: Property,
    eventType?: EventTypes,
    event?: Event
  ): void {
    this.schedule.weeklyViewScrollPosition =
      this.schedule.weeklyViewContainer.scrollHeight;
    localStorage.setItem('addressId', property.id.toString());
    this.wasCardClicked = true;
    this.onClickAction.emit({
      target,
      eventType,
      property,
      event,
    });
  }

  sort(weekDay: string, sortDirection: 'asc' | 'desc'): void {
    this.sortDirection = sortDirection;
    this.sortProperty = weekDay;
    this.allProperties.sort((a: Property, b: Property) => {
      const aHasEvent =
        this.hasEventAtProperty(weekDay, a, 'filteredJobs') ||
        this.hasEventAtProperty(weekDay, a, 'filteredReservations') ||
        this.hasEventAtProperty(weekDay, a, 'filteredAvailabilityRequests');
      const bHasEvent =
        this.hasEventAtProperty(weekDay, b, 'filteredJobs') ||
        this.hasEventAtProperty(weekDay, b, 'filteredReservations') ||
        this.hasEventAtProperty(weekDay, b, 'filteredAvailabilityRequests');
      if (aHasEvent && bHasEvent) {
        return 0;
      } else if (aHasEvent) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (bHasEvent) {
        return sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
    this.properties = this.allProperties.slice(0, 10);
  }

  getIsCurrentDay(day) {
    return day === this.today.split('T')[0];
  }

  isToday(weekday: string): boolean {
    const today = LuxonDateTime.local();
    const todayWeekday = today.toFormat('EEEE');
    
    if (weekday !== todayWeekday) {
      return false;
    }
    
    const startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate);
    const endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate);
    const todayDate = today.toISODate();
    
    for (let day = startDate; day <= endDate; day = day.plus({ days: 1 })) {
      if (day.toFormat('EEEE') === weekday) {
        return day.toISODate() === todayDate;
      }
    }
    
    return false;
  }

  async goToBookjob(day, property) {
    this.schedule.weeklyViewScrollPosition =
      this.schedule.weeklyViewContainer.scrollHeight;
    this.bookJobService.clearBookJobStorage();
    const wasCardClicked = await new Promise((resolve) =>
      setTimeout(() => {
        if (this.wasCardClicked) {
          resolve(this.wasCardClicked);
          return;
        }
        resolve(null);
      }, 100)
    );
    if (wasCardClicked) {
      this.wasCardClicked = false;
      return;
    }
    const date = this.getDayFromWeekBasedOnWeekday(day);
    console.log(date)
    await this.storage.save('bookJobBackPage', 'schedule');
    await this.client.getClientInfo();
    const addresses = await this.client.getMoreDetailAddresses();
    const addressId = property.id;
    localStorage.setItem('addressId', addressId.toString());
    const address = addresses.find((ad) => ad.id === addressId);
    const bookingType = 'add_job';
    const hasPrivatePro = await this.pros.checkIfHasPrivatePro();
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const params = {
      address,
      bookingType,
      date,
      providedStartDate: date,
    };
    if (hasPrivatePro || isRentalClient) {
      this.navigateToBookJobPage('select-category', params);
    } else {
      this.navigateToBookJobPage('book-job', params);
    }
  }

  navigateToBookJobPage(route: string, params: any): void {
    if (!this.isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(
        route === 'select-category' ? SelectCategoryPage : BookJobPage
      );
    } else {
      this.navCtrl.navigateForward(route, params);
    }
  }

  async assignPro(job, pro, event, index) {
    try {
      this.assignProMenuTrigger.closeMenu();
      this.changeDetectorRef.detectChanges();
      this.bookJobService.isAssigningPrivatePro = job?.booking?.id;
      localStorage.setItem('proName', pro.name);
      await this.booking.assignPrivatePro(job?.booking?.id, pro?.team_id);
    } catch (err) {
      localStorage.setItem('assignProError', 'true');
      const errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
    this.onAssignedPro.emit(job);
  }

  async showProMenu(job, property, event) {
    event.stopPropagation();
    this.privatePros = [];
    const pros = await this.pros.getAllPrivatePros();
    pros.map((pro) => {
      this.privatePros.push({
        name: pro.first_name + ' ' + pro.last_name,
        team_id: pro.main_team_id,
      });
    });
  }

  viewAllRequestTimes(job, event) {
    event.stopPropagation();
    this.showAllTimesProposals.push(job?.id);
    this.changeDetectorRef.detectChanges();
  }
}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from "src/providers/logger";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";

import { ConciergeItemModel } from 'src/models/concierge.model';
import { Util } from 'src/shared/util/util';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'dispute-resolution.html',
  encapsulation: ViewEncapsulation.None
})

export class DisputeResolutionPage implements OnInit {

  isRightSideContent: boolean;
  email: string;
  errorMessage: string;
  dispute: ConciergeItemModel;
  replyTime: string;
  status: string;
  createdAt: Date;
  jobData: any;
  isJobIssue: boolean;
  loaded: boolean;

  constructor(
    private rightSidePanelService: RightSidePanelService,
    private concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private util: Util
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.loadDataAndParams();
  }

  async loadDataAndParams() {
    this.loaded = false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.dispute = dialogParams.dispute || this.navCtrl.getParam('dispute');
    this.rightSidePanelService.setDialogPageTitle(this.dispute.header_text);
    try {
      if (!this.dispute) {
        const disputeItemId = this.route.snapshot.paramMap.get('disputeItemId');
        this.dispute = await this.concierge.getConciergeItemById(disputeItemId);
      }
      this.loaded = true;
    } catch (err) {
      if (err) {
        this.logger.error(err);
        this.errorMessage = err?.error?.message ?? err?.message;
      }
    }
  }

  async replyCounter(action) {
    const data = {
      concierge_item_id: this.dispute.id,
	    action_name: action,
	    params: {
		    concierge_item_id: this.dispute.id,
		    concierge_item_type_component_id: this.dispute.components[0].id,
		    customer_cleaning_dispute_id: this.dispute.metadata?.customer_cleaning_dispute_id
	    }
    }
    try {
      await this.concierge.replyDisputeCounter(this.dispute.id, data);
      this.rightSidePanelService.navigateTo('concierge');
    } catch (err) {
      this.logger.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Team'"
    [canGoBack]="true"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

          <tidy-card>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="teamName"
                [form]="form.controls.teamName"
                [label]="'Team Name'"
                [errorMessage]="'Please enter the first name of the team.'"
                [submitted]="submitted"
                [icon]="'assets/svg/information-circle-outline.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row *ngIf="parentTeamItems?.length > 0">
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'Parent Team (optional)'"
                [items]="parentTeamItems"
                [form]="form.controls.parentTeam"
                formControlName="parentTeam">
              </tidy-select>
            </tidy-row>
          </tidy-card>

          <tidy-error-message
            *ngIf="errorMessage"
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [text]="'Add Team'"
            [action]="addTeam.bind(this)"
            class="primary">
          </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

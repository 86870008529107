import { Pipe, PipeTransform } from '@angular/core';
import { StaticCompanyText } from 'src/providers/company/static-company-text';

@Pipe({
  name: 'translateOffice'
})
export class TranslateOfficePipe implements PipeTransform {

  constructor(
    private staticCompanyText: StaticCompanyText
  ) {}

  transform(value: string) {
    return this.staticCompanyText.getVariable(value);
  }

}

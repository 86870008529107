import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tweekday'
})
export class TidyNumberToWeekDayPipe implements PipeTransform {
  transform(value: number, short: boolean = false): string {
    const shortWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    if (short) {
      return shortWeekDays[value];
    }
    return weekDays[value];
  }
}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-loading-bar',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./loading-bar.component.scss'],
  template: `
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  `
})

export  class LoadingBarComponent {
}

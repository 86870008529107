<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'TIDY To-Dos'"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>

    <to-dos-onboarding
      [addressResponse]="addressResponse"
      [addressId]="addressId"
      [isNormalBookingFlow]="true">
    </to-dos-onboarding>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <to-dos-onboarding
    [addressResponse]="addressResponse"
    [addressId]="addressId"
    [isNormalBookingFlow]="true">
  </to-dos-onboarding>
</ng-container>

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-desktop-header',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./desktop-header.scss'],
  template: `
    <ng-content></ng-content>
  `
})

export  class DesktopHeaderComponent {

  constructor(
  ) {}

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Quote for TIDY'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form>
    <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-error-message
        [text]="errorMessage"
        *ngIf="errorMessage">
      </tidy-error-message>

      <tidy-row [align]="'center'">
        <tidy-text
          [title]="name">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'We are delighted to start! Our goal is to help you dramatically improve your cleaning and maintenance management. Please complete this and our team of experts will get to work.'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="showResponsibilities">
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'TIDY\'s Responsibilities'">
            </tidy-text>
           </tidy-row>
           <tidy-row>
            <tidy-text
              [body]="'TIDY shall:'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'<li>Provide software and human services to help make managing of cleaning and maintenance better.</li>'">
            </tidy-text>
            <tidy-text
              [body]="'<li>Suggest how to improve quality, reduce costs, or avoid issues.</li>'">
            </tidy-text>
            <tidy-text
              [body]="'<li>Provide automations to save time.</li>'">
            </tidy-text>
            <tidy-text
              [body]="'<li>Provide support your team and your team and your pros.</li>'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY cannot:'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'<li>We cannot guarantee results from on-site third-party vendors, as everything about your management is custom to you. We
              do give guidance and want you to succeed.</li>'">
            </tidy-text>
            <tidy-text
              [body]="'<li>We cannot personally do anything on-site.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
  
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Client\'s Responsibilities'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'We are most successful when we work together with clients to understand and automate your cleaning and maintenance
              workflows. We need your help:'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'<li>Giving us the information we need to set up and manage your account.</li>'">
            </tidy-text>
            <tidy-text
              [body]="'<li>Ensure you give us any/all pros you have ever found as acceptable, even if not preferred. Customers that provide us with
              an existing vendor are 10x more likely to be successful, so we require customers to add at least 1 or request a waiver.</li>'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Additionally you can view the terms and conditions '">
            </tidy-text>
            <tidy-text
              class="link"
              (action)="goToTermsAndConditions()"
              [actionBody]="'here.'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/rental-property.svg'"
            style="width: 30px">
          </tidy-image>
          <tidy-text
            [title]="plan">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Cost: ' + planCost">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Description: ' + planCostDescription">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image
            [src]="'assets/img/concierge.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Onboarding'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'TIDY shall help with any account setup, data loading, team training, or other work to get the account set up correctly for you. We will do whatever it takes to get you set up for success, use us!'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Customer\’s onboarding fees: ' + onboardingCost">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/money-back-guarantee.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Money Back Guarantee'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'TIDY shall deliver your money back on subscriptions if you are unhappy for any reason and cancel within 14 days. Note that payments sent to pros for jobs cannot be refunded with this guarantee.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/hourglass.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Deadline'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'This offer is valid through ' + deadline">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
         <tidy-text
          [header]="'Next Steps'">
         </tidy-text><br>
         <tidy-text
          [body]="getStartedText">
         </tidy-text>
       </tidy-row>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Enter Card Information'">
          </tidy-text>
        </tidy-row>

        <div class="form-row">
          <div id="card-info" #cardInfo></div>
        </div>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>
      </tidy-card>

      <ng-container>
        <div style="display: flex; align-content: center; justify-content: center;">
          <re-captcha (resolved)="captchaEvents($event)"></re-captcha>
        </div>
      </ng-container>

      <tidy-row>
        <tidy-button
          [text]="'I Agree'"
          [action]="submitCard.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Webhook Details'"
    [canGoBack]="true"
    [customBack]="webhookDetailsBackPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="webhookUrl">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/url.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="webhookChannel == 'webhook' ? 'Standard Webhook' : 'Slack Webhook'">>
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center extra-bottom-padding" *ngIf="webhookDescription">
            <tidy-image
              [src]="'assets/img/info.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="webhookDescription">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image *ngIf="webhookState === 'inactive'"
              [src]="'assets/icon/pause.svg'"
              class="body-size">
            </tidy-image>
            <tidy-image *ngIf="webhookState === 'active'"
              [src]="'assets/icon/check.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Status:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(webhookState | titlecase)">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-middle" class="extra-bottom-padding" *ngIf="webhookTeamName !== 'undefined' && isAccountAdmin">
            <tidy-image
              [src]="'assets/img/organization.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Team:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="webhookTeamName">
            </tidy-text>
          </tidy-row>

          <tidy-row class="vertical-align-middle extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Listening to events:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="webhookEvents">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="runTestWebhook()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/send.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Test Webhook'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>

          <tidy-card-button (action)="goToEditWebhookPage()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/edit.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Edit Webhook'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>

          <tidy-card-button (action)="confirmWebhookDeletion()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/block.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Delete Webhook'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>

          <tidy-card-button *ngIf="webhookState === 'active'" (action)="confirmWebhookDeactivation()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/pause.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Disable Webhook'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>

          <tidy-card-button *ngIf="webhookState === 'inactive'" (action)="confirmWebhookActivation()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/icon/check.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Activate Webhook'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>

        </tidy-card>

        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-text
            [header]="'Webhook Attempts'">
          </tidy-text><br>
          <tidy-text
            [body]="'Attempts to send an event to your endpoint in the past 15 days'">
          </tidy-text>
        </tidy-row>

        <tidy-alert *ngIf="attempts?.length === 0">
          <tidy-row>
            <tidy-text
              [body]="'No recent webhook attempts'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-card *ngFor="let attempt of attempts">
          <tidy-card-button (action)="goToWebhookAttempt(attempt, attempt.status)">
            <tidy-row>
              <tidy-text
                [body]="attempt?.created_at | customdate:'dddd M/D/YY h:mm a' + ' - '">
              </tidy-text>
              <tidy-text
                [body]="attempt?.status | titlecase"
                [ngClass]="attempt?.status === 'failed' ? 'red' : 'green'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

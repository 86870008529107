import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'pro-added.html'
})

export class ClientGetStartedProAddedPage implements OnInit {

  address: any;
  creditBalance: number;
  pro: any;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  ngOnInit() {
    this.address = this.navCtrl.getParam('address');
    this.pro = this.navCtrl.getParam('pro');
    this.creditBalance = parseInt(localStorage.getItem('creditBalance'));
  }

  goToSendJobRequest() {
    const params = {
      pro: this.pro,
      address: this.address
    }
    this.navCtrl.navigateForward('request-pro', params);
  }

  goToViewApp() {
    this.navCtrl.navigateForward('dashboard');
  }

  goToSendPayment() {
    const params = {
      proId: this.pro.id,
      address: this.address,
      creditBalance: this.creditBalance,
      cameFromGetStarted: true
    }
    this.navCtrl.navigateForward('get-started-send-payment', params);
  }

}

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Injectable()
export class Pros {

  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage,
    private onboardingProvider: OnboardingProvider
  ) {}

  async updateMaxJobs(payload: { team_id: number, key: string, value: number }) {
    const url = 'api/v1/customer/team-settings';
    return this.httpClient.put(url, payload);
  }

  async getAllPros() {
    const hasProperties = await this.onboardingProvider.checkIfHasProperties();
    if (!hasProperties) {
      return [];
    }
    const pros = await this.storage.retrieve('allPros');
    const shouldFetchPros = pros?.length === 0 || await this.storage.retrieve('shouldFetchPros');
    if (pros && !shouldFetchPros) {
      return pros;
    }
    this.storage.save('shouldFetchPros', false);
    const url = `api/v1/customer/homekeepers?includes=next_job,last_job,address_homekeeper_priorities`;
    const response = await this.httpClient.get(url);
    await this.storage.save('allPros', response);
    return response;
  }

  async getAllProsByAddress() {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/homekeepers?includes=address_homekeeper_priorities`;
    const response = await this.httpClient.get(url);
    let prosInAddress = [];
    response.map((pro) => {
      pro.address_homekeeper_priorities = pro.address_homekeeper_priorities.filter((priority) => priority.address_id == addressId);
      if (pro?.address_homekeeper_priorities?.length && !prosInAddress.some(addedPro => addedPro === pro)) {
        prosInAddress.push(pro);
      }
    });
    return prosInAddress;
  }

  async getAllPrivateProsByAddress(addressId) {
    const id = addressId || localStorage.getItem('addressId');
    const url = `api/v1/customer/homekeepers?filters[address_ids]=${id}`;
    const pros = await this.httpClient.get(url);
    const array: any = [];
    pros.map((pro) => {
      if (pro.is_private) {
        array.push(pro);
      }
    });
    return array;
  }

  async getAllPrivatePros() {
    const url = `api/v1/customer/homekeepers`;
    const pros = await this.httpClient.get(url);
    const array: any = [];
    pros.map((pro) => {
      if (pro?.is_private) {
        array.push(pro);
      }
    });
    array.sort((a, b) => a.name.localeCompare(b.name));
    return array;
  }

  async checkIfHasPrivatePro(proListResponse = null) {
    let hasPrivatePro = localStorage.getItem('hasPrivatePro') == 'true';
    if (hasPrivatePro !== null) {
      return hasPrivatePro;
    } else {
      const proList = proListResponse || await this.getAllPrivatePros();
      localStorage.setItem('hasPrivatePro', proList.length ? 'true' : 'false');
      return hasPrivatePro;
    }
  }

  addNewPro(params) {
    const url = `api/v1/customer/homekeepers`;
    return this.httpClient.post(url, params);
  }

  updateProInfo(proId: number, profile: Partial<{name: string, email: string, phone: string}>) {
    const url = `api/v1/customer/homekeepers/${proId}`;
    const data = {homekeeper_id: proId, profile};

    return this.httpClient.put(url, data);
  }

  addExistingPros(params, addressId) {
    const url = `api/v1/customer/addresses/${addressId}/homekeeper-list/add-homekeepers`;
    return this.httpClient.post(url, params);
  }

  getProsExcludingAddress() {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/homekeepers?exclude_homeekepers_on_address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  getPros(serviceType: number, addressId = null) {
    const id = addressId || localStorage.getItem('addressId');
    const formattedServiceType = serviceType.toString();
    const url = `api/v1/customer/addresses/${id}/homekeeper-list?service_type_id=${formattedServiceType}`;
    return this.httpClient.get(url);
  }

  async getServiceCategories() {
    const serviceTypes = await this.storage.retrieve('serviceTypes');
    if (serviceTypes) {
      return serviceTypes;
    } else {
      const url = 'api/v1/customer/service-types';
      const response = this.httpClient.get(url);
      await this.storage.save('serviceTypes', response);
      return response;
    }
  }

  getProDetail(proId, addressId = null) {
    const id = addressId ?? localStorage.getItem('addressId') ;
    const url = `api/v1/customer/homekeepers/${proId}/addresses/${id}/details-page`;
    return this.httpClient.get(url);
  }

  updateProsPriorities(pros, serviceTypeId: number) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/homekeeper-list`;
    const params = {
      service_type_id: serviceTypeId,
      ...pros
    };
    return this.httpClient.put(url, params);
  }

  getAllProsSettings(perPage, page, params) {
    let url = `api/v1/customer/team-address-services?filters[only_private_homekeepers]=true&per_page=${perPage}&page=${page}`;
    if (params?.homekeeper_ids) {
      url += `&filters[homekeeper_ids]=${params.homekeeper_ids.join(',')}`;
    }
    if (params?.service_category_ids) {
      url += `&filters[service_category_ids]=${params.service_category_ids.join(',')}`;
    }
    if (params?.address_ids) {
      url += `&filters[address_ids]=${params.address_ids.join(',')}`;
    }
    return this.httpClient.getFullRequest(url)
      .then(response => {
        return {
          body: response.body,
          totalRecords: response.headers.get('X-Total-Records')
        };
      });
  }

  batchUpdateProSettings(payload) {
    const url = 'api/v1/customer/team-address-services/batch-update';
    return this.httpClient.post(url, payload);
  }

  getAutopaySettingsAllPros() {
    const url = `api/v1/customer/team-settings`;
    return this.httpClient.get(url);
  }

  getAutopaySettings(teamId) {
    const url = `api/v1/customer/team-settings?team_id=${teamId}`;
    return this.httpClient.get(url);
  }

  updateAutopaySettings(payload) {
    const url = 'api/v1/customer/team-settings';
    return this.httpClient.put(url, payload);
  }

  sendPayment(payload, homekeeperId) {
    const url = `api/v1/customer/homekeepers/${homekeeperId}/send-payment`;
    return this.httpClient.post(url, payload);
  }

  getCreditBalance(homekeeperTeamId, addressId = null) {
    let url = `api/v1/customer/billing/credit-balance?homekeeper_team_id=${homekeeperTeamId}`;
    if (addressId) {
      url += `&address_id=${addressId}`
    }
    return this.httpClient.get(url);
  }

  getBillingHistory(proId) {
    const url = `api/v1/customer/billing/private-detail-history?homekeeper_id=${proId}`;
    return this.httpClient.get(url);
  }

  getBillingHistoryByAddress(proId, addressId) {
    const url = `api/v1/customer/billing/private-detail-history?homekeeper_id=${proId}&address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  async checkIfHasProsInCategory(pros) {
    let hasPros = false;
    pros.approved.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        hasPros = true;
      }
    });
    pros.favorited.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        hasPros = true;
      }
    });
    return hasPros;
  }

  parseProsIntoGroups(prosData) {
    let allPros = [];

    const greatestPriority = prosData.favorited.reduce((max, pro) => {
      if (Array.isArray(pro)) {
        pro.forEach((item) => {
          return Math.max(max, item.priority);
        });
      } else {
        return Math.max(max, pro.priority);
      }
    }, 0);

    prosData.favorited.forEach((pro) => {
      allPros.push(pro);
    });
    prosData.approved.forEach((pro) => {
      pro.priority = greatestPriority + 1;
      pro.has_priority = true;
      allPros.push(pro);
    });
    let pros = [];
    allPros.map((pro, index) => {
      if (index !== 0) {
        const priorPro = pros[pros?.length - 1];
        const priorProIsArray = Array.isArray(priorPro);
        const priorPriority = priorProIsArray ? priorPro[0].priority : priorPro.priority;
        if (priorPriority == pro.priority) {
          if (priorProIsArray) {
            pros[pros?.length - 1].push(pro);
          } else {
            pros[pros?.length - 1] = [
              priorPro,
              pro
            ];
          }
        } else {
          pros.push(pro);
        }
      } else {
        pros.push(pro);
      }
    });
    prosData.favorited = pros;
    return prosData;
  }

  updatePriorityListSentence(prosData) {
    const notBlockedPros = [...prosData?.favorited, ...prosData?.approved];
    if (!notBlockedPros.length) {
      return new TranslationPipe().transform('Pros that you\'ve added or had jobs with will appear here.');
    }
    let favoritedSentence = new TranslationPipe().transform('TIDY will send job requests to ');
    prosData?.favorited.map((pro, index) => {
      let proName = '';
      const isArray = Array.isArray(pro);
      if (isArray) {
        pro.map((item, itemIndex) => {
          const name = this.isNotDynamicSA(item) ? item?.object?.first_name : new TranslationPipe().transform('new pros');
          proName += name + (itemIndex < pro.length - 1 ? new TranslationPipe().transform(' and ') : '');
        });
      } else {
        proName = this.isNotDynamicSA(pro) ? pro?.object?.first_name : new TranslationPipe().transform('new pros');
      }
      favoritedSentence += proName + (index < prosData.favorited.length - 1 ? new TranslationPipe().transform(', then ') : '');
    });
    const hasOnlyFindNewPros =
      prosData?.favorited?.length == 0 && prosData?.approved?.length == 1 && !this.isNotDynamicSA(prosData?.approved[0]) ||
      prosData?.favorited?.length == 1 && prosData?.approved?.length == 0 && !this.isNotDynamicSA(prosData?.favorited[0]);
    if (hasOnlyFindNewPros) {
      favoritedSentence = new TranslationPipe().transform('TIDY will send job requests to new pros.')
    } else {
      favoritedSentence += '.';
    }
    return favoritedSentence;
  }

  isNotDynamicSA(item) {
    return item?.object_type !== 'dynamic_sa';
  }

  removeProFromAddressList(addressId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/homekeeper-list/inactivate-homekeepers`;
    return this.httpClient.put(url, payload);
  }

  saveServiceCategories(categories: any[]): void {
    this.storage.save('serviceCategories', categories);
    const date = new Date();
    this.storage.save('lastServiceCategoriesUpdate', date.getTime());
  }

  async getServiceCategoriesFromStorage(): Promise<any[]> {
    return await this.storage.retrieve('serviceCategories');
  }

  async getServiceCategoriesOrFetch(): Promise<any> {
    const date = new Date();
    const lastServiceCategoriesUpdate = await this.storage.retrieve('lastServiceCategoriesUpdate');
    const hasLessThenOneHourOfDifference = (date.getTime() - lastServiceCategoriesUpdate) / 1000 / 60 / 60 < 1;
    const storedServiceCategories = await this.getServiceCategoriesFromStorage();
    if (lastServiceCategoriesUpdate && storedServiceCategories.length > 0 && hasLessThenOneHourOfDifference) {
      return storedServiceCategories;
    }
    const serviceCategories = await this.getServiceCategories();
    this.saveServiceCategories(serviceCategories);
    return serviceCategories;
  }
  
  parseDurationIntoHoursAndMintes(duration, type) {
    if (!duration) {
      return null;
    }
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    let hoursAndMinutes = '';
    if (hours > 0) {
      hoursAndMinutes += hours + (hours == 1 ? new TranslationPipe().transform(' hour ') : new TranslationPipe().transform(' hours '));
    }
    if (minutes > 0) {
      hoursAndMinutes += minutes + new TranslationPipe().transform(' minutes');
    }
    return hoursAndMinutes;
  }

  updateAutoAssignSettings(payload, addressHomekeeperPriorityID) {
    const url = `api/v1/customer/address-homekeeper-priorities/${addressHomekeeperPriorityID}`;
    return this.httpClient.put(url, payload);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add To-Do List'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Create a To-Do list, then assign it to a job so your Pro can see what you want done.'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <form [formGroup]="form">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/home-outline.svg'"
                [label]="'Property'"
                [form]="form.controls.address"
                formControlName="address"
                [items]="addressFilter"
                (optionChange)="changeAddress($event)">
              </tidy-select>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                [label]="'What do you want to do?'"
                formControlName="whatToDo"
                [form]="form.controls.whatToDo"
                [items]="whatToDoItems"
                (optionChange)="updateWhatToDo($event)">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row *ngIf="form?.value?.whatToDo == 'copy' && listToCopyItems?.length == 0">
              <tidy-text
                [body]="'All your To-Do lists have rooms specific to a property so cannot be copied to other properties. Create a To-Do list with no property specific rooms to copy it.'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="form?.value?.whatToDo == 'copy' && listToCopyItems?.length > 0">
              <tidy-row class="field-top-padding field-bottom-padding">
                <tidy-select
                  [icon]="'assets/svg/list.svg'"
                  [label]="'Select List to Copy to this Property'"
                  [form]="form.controls.listToCopy"
                  formControlName="listToCopy"
                  [items]="listToCopyItems">
                </tidy-select>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Note: you can only copy lists not associated with a property to another property.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="form?.value?.whatToDo == 'new'">
              <tidy-row class="extra-bottom-padding">
                <tidy-input
                  [label]="'List Name'"
                  formControlName="listName"
                  [form]="form.controls.listName"
                  [icon]="'assets/svg/list-circle-outline.svg'"
                  [activeIcon]="'assets/svg/list-circle-outline.svg'"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter the name of the list.'">
                </tidy-input>
              </tidy-row>
              <tidy-row *ngIf="hasToDoLists">
                <tidy-checkbox
                  [(checked)]="default"
                  [items]="[{viewValue: 'Set as default list at this property', value: false}]"
                  [initValue]="default">
                </tidy-checkbox>
              </tidy-row>
              <tidy-row [alignLastItemRight]="true">
                <tidy-row [alignLastItemRight]="true" class="full-width">
                  <tidy-image
                    [src]="'assets/img/camera.svg'"
                    class='header-size'>
                  </tidy-image>
                  <tidy-text
                    [body]="'Remote Inspection'">
                  </tidy-text>
                  <div></div>
                </tidy-row>
                <tidy-toggle
                  [toggled]="remoteInspection"
                  (toggleChanged)="remoteInspection = !remoteInspection">
                </tidy-toggle>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Require pros to provide photos or videos so that you can remotely inspect each job.'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="remoteInspection">
                <tidy-row class="extra-top-padding extra-bottom-padding">
                  <tidy-divider>
                  </tidy-divider>
                </tidy-row>
                <form [formGroup]="form" novalidate action="#">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-radio-button
                      formControlName="type"
                      [form]="form.controls.type"
                      [label]="'How would you like your pro to visually inspect?'"
                      [items]="typeItems"
                      [submitted]="submitted"
                      [errorMessage]="'Please select how to inspect.'">
                    </tidy-radio-button>
                  </tidy-row>
                  <tidy-row>
                    <tidy-radio-button
                      formControlName="when"
                      [form]="form.controls.when"
                      [label]="'When would you like them to inspect?'"
                      [items]="whenItems"
                      [submitted]="submitted"
                      [errorMessage]="'Please select when to inspect.'">
                    </tidy-radio-button>
                  </tidy-row>
                </form>
              </ng-container>
            </ng-container>
          </form>
        </tidy-card>

        <tidy-button
          [text]="'Add To-Dos List'"
          [action]="addList.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

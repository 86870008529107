import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Injectable()
export class Booking {

  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  getBookingDetail(bookingId) {
    const includes = `current_job,service_type_details,booking_notes,address_task_list,address,plan`;
    const url = `/api/v1/customer/bookings/${bookingId}?includes=${includes}`;
    return this.httpClient.get(url);
  }

  assignPrivatePro(bookingId, teamId){
    const data = {
      booking_id: bookingId,
      team_id: teamId
    }
    const url = `/api/v1/customer/bookings/${bookingId}/replace-team`;
    return this.httpClient.post(url, data);
  }

  async getBookingPath(bookingId) {
    const booking = await this.getBookingDetail(bookingId);
    const params = {
      jobId: booking.current_job.id,
      addressId: booking.address_id,
      object: booking.current_job,
    }
    this.storage.save('dialog-params', params);
    const jobId = booking.current_job.id;
    const addressId = booking.current_job.address_id;
    const isFinished = booking.current_job.status == "completed" || booking.current_job.status == "incomplete";
    const isPastJob = isFinished && !booking.current_job.is_private;
    const isPastPrivateJob = isFinished && booking.current_job.is_private;
    localStorage.setItem('addressId', booking.address_id);
    if (isPastJob) {
      return `past-job/${jobId}`;
    }
    if (isPastPrivateJob) {
      return `past-private-job/${jobId}`;
    }
    return `job/${addressId}/${jobId}`;
  }

}

export interface BookingResponse {
  id?: number;
  address_id?: number;
  bookable_service_key?: string;
  preferred_start_date?: string;
  preferred_start_time?: string;
  start_date_no_earlier_than?: string;
  start_time_no_earlier_than?: string;
  end_date_no_later_than?: string;
  end_time_no_later_than?: string;
  price?: number;
  status?: string;
  cancelled_by?: string | null;
  request_type?: string;
  acceptance_probability?: any;
  current_job?: CurrentJob;
  booking_notes?: any[] | null;
  address_task_list?: {
    id?: number;
    title?: string;
    state?: string;
    is_customer_list?: boolean | null;
    is_template?: boolean | null;
  } | null;
  address?: {
    id?: number;
    address1?: string;
    address2?: string;
    city?: string;
    add_state?: string;
    address_name?: string;
    latitude?: string;
    longitude?: string;
    region_id?: number;
    default_address_task_list_state?: string;
    [key: string]: any;
  };
  plan?: {
    id?: number;
    plan_frequency?: string;
  };
  service_type_details?: ServiceTypeDetails;
  [key: string]: any;
}

interface CurrentJob {
  id?: number;
  cleaning_service_type?: string;
  date?: string;
  start_time?: string;
  start_datetime?: string;
  start_datetime_local?: string;
  end_time?: string;
  end_datetime?: string;
  end_datetime_local?: string;
  notes?: string;
  status?: string;
  cancelled?: boolean;
  cancelled_once?: boolean | null;
  paid?: boolean | null;
  rating?: number | null;
  rating_note?: string;
  repeating_flag?: boolean | null;
  week_day?: number;
  week_start_time?: string;
  week_end_time?: string;
  starting_day?: string | null;
  occurrence?: number;
  booking_id?: number;
  job_reference_id?: number;
  state?: string;
  billing_status?: string | null;
  cancellation_reason?: string;
  rooms_remaining?: number;
  customer_feedback?: string | null;
  half_cleaning?: boolean;
  issue?: string | null;
  same_day_cleaning?: boolean;
  low_availability?: boolean;
  hk_rating?: string | null;
  rating_chosen?: boolean;
  skip_homekeepers_number?: boolean;
  happened_with_some_homekeeper?: boolean;
  is_private?: boolean;
  created_at?: string;
  updated_at?: string;
  plan_id?: number;
  [key: string]: any;
}

interface ServiceTypeDetails {
  id?: string;
  name?: string;
  original_name?: string;
  description?: string;
  key?: string;
  duration_in_minutes?: number;
  duration_to_block_off?: number;
  frequency_options?: string;
  is_base_price_allowed_to_book?: boolean;
  is_private?: boolean;
  prices?: {
    base_price?: number;
    lowest_possible_base_price?: number;
  };
  billing_type?: string;
  auto_pay_limit?: number;
  required_price_additional_fees?: {
    charge_type?: {
      value?: number;
      type?: string;
    }
  };
  optional_price_additional_fees?: {
    one_time_job?: {
      value?: number;
      type?: string;
    },
    same_day_job?: {
      value?: number;
      type?: string;
    }
  };
  address_id?: number;
  service_category?: {
    id?: number;
    key?: string;
    name?: string;
    icon_url?: string;
    map_icon_url?: string;
  };
  [key: string]: any;
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../providers/translation.service';

@Pipe({
  name: 'translate'
})

export class TranslationPipe implements PipeTransform {

  constructor(
    private translationService: TranslationService
  ) {}

  transform(value: string): string {
    const language = localStorage.getItem('language');
    if (language && language !== 'en') {
      if (this.translationService.translations[value]) {
        value =  this.translationService.translations[value][language];
      }
    }
    return value;
  }

}
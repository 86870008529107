import { Component, OnDestroy, ViewEncapsulation, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import moment from 'moment-timezone';
import { DateTime, DateTime as LuxonDateTime } from 'luxon';
import { BookJob } from 'src/providers/book-job/book-job';
import { Booking } from 'src/providers/booking/booking';
import { Communication } from 'src/providers/communication/communication';
import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Me } from 'src/providers/me/me';
import { Pros } from 'src/providers/pros/pros';
import { Requests } from 'src/providers/requests/requests';
import { Schedule } from 'src/providers/schedule/schedule';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Loading } from 'src/shared/components/loading/loading';
import { AddressModel, PlanModel } from 'src/models/address.model';
import { Filter, ScheduleCardModel, SelectedFilters } from 'src/models/schedule.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { GuestReservationSource } from 'src/models/guest-reservations';
import { Util } from 'src/shared/util/util';
import { Logger } from 'src/providers/logger';
import { WindowService } from 'src/shared/providers/window.service';
import { ClientSettingsModel } from 'src/models/client.model';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { AvailabilityRequest, AvailabilityRequestResponse, ClickAction, GuestReservationResponse, Job, onClickActionMonthlyView, Period, Properties, Reservation } from 'src/models/schedule-weekly-view.model';
import { Menu } from 'src/providers/menu/menu';
import { Events } from 'src/providers/events/events';
import { RequestProPage } from '../request-pro/request-pro';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { from, merge, Subscription } from 'rxjs';
import { SelectCategoryPage } from '../select-category/select-category';
import { PastPrivateJobPage } from '../more/past-private-job/past-private-job';
import { PastJobPage } from '../more/past-job/past-job';
import { JobPage } from './job/job';
import { ReservationPage } from '../reservation/reservation';
import { BookJobPage } from '../booking/book-job/book-job';
import { CallProPage } from 'src/pages/call-pro/call-pro';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SummaryOfDayPage } from './summary-of-day/summary-of-day';
import { finalize, tap } from 'rxjs/operators';
import { ScheduleListPage } from 'src/pages/schedule/schedule-list/schedule-list';
import { ActivatedRoute } from '@angular/router';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { scheduleMockData } from 'src/shared/constants/onboarding/schedule';
import { propertiesMockData } from 'src/shared/constants/onboarding/properties';

@Component({
  templateUrl: 'schedule.html',
  styleUrls: ['./schedule.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SchedulePage implements OnDestroy, OnInit {

  allTableStartEndDays: any;
  addressFilter: TidySelectNumberValueModel[];
  activeIntegrations: Array<GuestReservationSource> = [];
  addressFilterLoaded: boolean;
  addressId = null;
  addressName: string;
  addressResponse: AddressModel[];
  addressRegionId: number;
  cards: ScheduleCardModel[];
  cardsLoaded: boolean;
  clientName: string;
  contentHeight: number;
  errorMessage: string;
  showContactConcierge = false;
  settings: ClientSettingsModel;
  hasJobs: boolean;
  hasPhone: boolean;
  hasPrivatePro: boolean;
  hasRequests: boolean;
  hasOnlyCrafRequests: boolean;
  hasOnlyReservations: boolean;
  hasJobsAtOnlyAddress: boolean;
  hasAllOneTimePlans: boolean;
  isAllAddressesSelected: boolean;
  isRentalClient: boolean;
  automaticBookingEnabled: boolean;
  loadingNotifications: boolean;
  hasCreditCard: boolean;
  nextAvailableDate: string;
  plans: PlanModel[];
  waterfallSettings: any;
  toDoState: string;
  twoCoops = false;
  notificationHistory: any;
  hasAddresses: boolean;
  hasReservations: boolean;
  desktopWidthContent: string = scrollContentWidth.PORTRAIT;
  daysOfWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  selectedPeriodRange = {
    startDate: LuxonDateTime.local().toISODate(),
    endDate: LuxonDateTime.local().plus({ days: 6 }).toISODate()
  }
  isScheduleDataLoaded = false;
  propertiesFilters: SelectedFilters = {
    status: [],
    type: [],
    service: [],
    address: [],
    propertyName: [],
    pro: [],
  }
  propertiesSearch = '';
  phoneNumber: any;
  routeReused: boolean;
  selectedProsIds: number[] = [];
  showLoadingSpinner: boolean;
  panelClosedSubscription: Subscription;
  scheduleViewMode: 'MONTHLY' | 'WEEK_MON_SUN' | 'SEVEN_DAYS';
  form: UntypedFormGroup;
  isLoadingSchedule = true;
  loaded: boolean;
  filters: {
    homekeeper_ids?: number[];
    address_ids?: number[];
  } = {};
  showOnboarding: boolean;
  hasProperties: boolean;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding = false;

  constructor(
    private bookingService: Booking,
    private bookJobService: BookJob,
    private card: Card,
    public communication: Communication,
    private client: Client,
    private me: Me,
    public schedule: Schedule,
    private navCtrl: CustomNavController,
    private util: Util,
    private modalCtrl: ModalController,
    private pros: Pros,
    public  requests: Requests,
    private route: ActivatedRoute,
    private guestReservations: GuestReservations,
    public windowService: WindowService,
    private currentAddress: CurrentAddress,
    private logger: Logger,
    private storage: TidyStorage,
    private loadingCtrl: LoadingController,
    public menu: Menu,
    private events: Events,
    private reportIssue: ReportIssue,
    private rightSidePanelService: RightSidePanelService,
    private onboardingProvider: OnboardingProvider
  ) {
    this.storage.delete('proId');
    this.storage.delete('chatRoomKey');
    this.panelClosedSubscription = this.rightSidePanelService.afterPanelClosed().subscribe(() => {
      const selectCategoryOpened = localStorage.getItem('select-category-opened');
      if (selectCategoryOpened !== 'true' && this.scheduleViewMode === 'MONTHLY') {
        return;
      }
      localStorage.setItem('select-category-opened', 'false');
      const getAddressesFromBackend = this.navCtrl.getParam('getAddressesFromBackend');
      const route = this.route.snapshot.url.map(segment => segment.path).join('/');
      let requestMadeInsideRightSidePanel = this.rightSidePanelService.requestMadeInsideRightSidePanel$.getValue();
      const madeUpdateToSchedule = localStorage.getItem('madeUpdateToSchedule') == 'true';
      if (madeUpdateToSchedule) {
        requestMadeInsideRightSidePanel = true;
        localStorage.removeItem('madeUpdateToSchedule');
      }
      if (route.includes('schedule') && requestMadeInsideRightSidePanel) {
        localStorage.setItem('skipSortingProperties', 'true');
        const openScheduleListSideRail = false;
        this.getPageData(getAddressesFromBackend, openScheduleListSideRail);
      }
    });
    this.form = new UntypedFormGroup({
      scheduleViewMode: new UntypedFormControl(''),
    });
  }

  async ngOnInit() {
    try {
      this.isLoadingSchedule = true;
      await this.checkIfShouldShowOnboarding();
      await this.loadPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    this.scheduleViewMode = await this.storage.retrieve('scheduleViewMode') || 'SEVEN_DAYS';
    this.getInitialSelectedPeriodRange();
    this.errorMessage = '';
    await this.getInitialData();
    await this.getAditionalData();
    this.loaded = true;
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/schedule').then(async () => {
        this.routeReused = true;
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    const hasProperties = await this.onboardingProvider.checkIfHasProperties();
    const hideOnboarding = await this.storage.retrieve('hideOnboarding');
    this.showOnboarding = !hasProperties && !hideOnboarding;
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
    this.panelClosedSubscription.unsubscribe();
  }

  onSearchChanges(event: any): void {
    this.propertiesSearch = event.search.toLowerCase();
    this.updateTableFilters()
  }

  onFilterChanges(event: Filter): void {
    this.propertiesFilters = event.selectedFilters;
    if (event.lastSelectedFilter === 'pro') {
      this.filters.homekeeper_ids = event.selectedFilters.pro
      this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
      return
    }
    if (event.lastSelectedFilter === 'address' || event.lastSelectedFilter === 'propertyName') {
      this.filters.address_ids = [...event.selectedFilters.address, ...event.selectedFilters.propertyName]
      this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
      return
    }
    this.updateTableFilters()
  }

  updateTableFilters(): void {
    const filteredProperties = this.schedule.updateTableFilter(this.schedule.imutableProperties, this.propertiesFilters)
    this.schedule.properties = this.schedule.updateTableSearch(filteredProperties, this.propertiesSearch)
    if (this.selectedProsIds.length > 0) {
      this.schedule.properties = this.schedule.properties.filter((property) => property.jobs.length > 0 || property.reservations.length > 0)
    }
  }

  changeScheduleViewMode(event: any): void {
    this.schedule.properties = [];
    this.schedule.imutableProperties = [];
    this.isScheduleDataLoaded = false;
    this.isLoadingSchedule = true;
    this.scheduleViewMode = event;
    this.storage.save('scheduleViewMode', event);
    this.getInitialSelectedPeriodRange();
    this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
  }

  getInitialSelectedPeriodRange(): void {
    this.daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const today = LuxonDateTime.local();
    let startDate, endDate;
    if (this.scheduleViewMode === 'MONTHLY') {
      const firstDayOfMonth = today.startOf('month');
      if (firstDayOfMonth.weekday !== 7) {
        startDate = firstDayOfMonth.minus({ days: firstDayOfMonth.weekday }).toISODate();
      } else {
        startDate = firstDayOfMonth.toISODate();
      }
      endDate = today.endOf('month').toISODate();
    } else if (this.scheduleViewMode === 'WEEK_MON_SUN') {
      // For Monday-Sunday week view
      const currentWeekday = today.weekday; // 1 = Monday, 7 = Sunday
      startDate = today.minus({ days: currentWeekday - 1 }).toISODate(); // Go back to Monday
      endDate = today.plus({ days: 7 - currentWeekday }).toISODate(); // Go forward to Sunday
      
      // When in Mon-Sun mode, we want the days of the week to always be in order from Monday to Sunday
      this.daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    } else {
      // SEVEN_DAYS view (default "weekly" view that shows 7 days from today)
      startDate = today.toISODate();
      endDate = today.plus({ days: 6 }).toISODate();
      
      // For SEVEN_DAYS view, reorder the days of the week based on today
      const todayWeekday = today.weekday - 1; // 0-based index for today's weekday (0 = Monday)
      const reorderedDaysOfWeek = [];
      
      // Start with today's weekday and add the next 6 days
      for (let i = 0; i < 7; i++) {
        const dayIndex = (todayWeekday + i) % 7;
        reorderedDaysOfWeek.push(this.daysOfWeek[dayIndex]);
      }
      
      this.daysOfWeek = reorderedDaysOfWeek;
    }
    this.selectedPeriodRange = { startDate, endDate };
    const periodRange = localStorage.getItem('periodRange');
    if (periodRange) {
      const parsedPeriodRange = JSON.parse(periodRange);
      const parsedStartDate = LuxonDateTime.fromISO(startDate).toJSDate().setHours(0, 0, 0, 0);
      const parsedRetrievedStartDate = LuxonDateTime.fromISO(parsedPeriodRange.startDate).toJSDate().setHours(0, 0, 0, 0);
      if (parsedStartDate > parsedRetrievedStartDate) {
        this.selectedPeriodRange = { startDate, endDate };
      } else {
        this.selectedPeriodRange = JSON.parse(periodRange);
      }
    }
  }

  async goToNextPeriod(): Promise<void> {
    this.isScheduleDataLoaded = false;
    this.clearPropertiesEvents();
    let startDate, endDate;
    if (this.scheduleViewMode === 'MONTHLY') {
      const firstDayOfMonth = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate).plus({ months: 1 }).startOf('month');
      if (firstDayOfMonth.weekday !== 7) {
        startDate = firstDayOfMonth.minus({ days: firstDayOfMonth.weekday % 7 }).toISODate();
      } else {
        startDate = firstDayOfMonth.toISODate();
      }
      const lastDayOfMonth = firstDayOfMonth.plus({ months: 1 }).minus({ days: 1 });
      endDate = lastDayOfMonth.toISODate();
    } else if (this.scheduleViewMode === 'WEEK_MON_SUN') {
      // For Monday-Sunday week view, move 7 days forward
      startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate)
        .plus({ days: 7 })
        .toISODate();
      endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate)
        .plus({ days: 7 })
        .toISODate();
    } else {
      // SEVEN_DAYS view (default)
      startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate)
        .plus({ days: 7 })
        .toISODate();
      endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate)
        .plus({ days: 7 })
        .toISODate();
    }
    this.selectedPeriodRange = { startDate, endDate };
    this.isScheduleDataLoaded = false;
    this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
  }

  goToPreviousPeriod(): void {
    this.clearPropertiesEvents();
    let startDate, endDate;
    if (this.scheduleViewMode === 'MONTHLY') {
      const firstDayOfMonth = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate).minus({ months: 1 }).startOf('month');
      if (firstDayOfMonth.weekday !== 7) {
        startDate = firstDayOfMonth.minus({ days: firstDayOfMonth.weekday % 7 }).toISODate();
      } else {
        startDate = firstDayOfMonth.toISODate();
      }
      const lastDayOfMonth = firstDayOfMonth.plus({ months: 1 }).minus({ days: 1 });
      endDate = lastDayOfMonth.toISODate();
    } else if (this.scheduleViewMode === 'WEEK_MON_SUN') {
      // For Monday-Sunday week view, move 7 days backward
      startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate)
        .minus({ days: 7 })
        .toISODate();
      endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate)
        .minus({ days: 7 })
        .toISODate();
    } else {
      // SEVEN_DAYS view (default)
      startDate = LuxonDateTime.fromISO(this.selectedPeriodRange.startDate)
        .minus({ days: 7 })
        .toISODate();
      endDate = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate)
        .minus({ days: 7 })
        .toISODate();
    }
    this.selectedPeriodRange = { startDate, endDate };
    this.isScheduleDataLoaded = false;
    this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
  }

  clearPropertiesEvents(): void {
    this.schedule.properties = this.schedule.properties.map((property) => {
      property.reservations = [];
      property.jobs = [];
      return property;
    });
    this.schedule.imutableProperties = [...this.schedule.properties];
  }

  async getInitialData(): Promise<void> {
    try {
      this.hasRequests = false;
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      const getAddressesFromBackend = this.navCtrl.getParam('getAddressesFromBackend');
      await this.getPageData(getAddressesFromBackend);
      this.loaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async getAditionalData(): Promise<void> {
    try {
      if (this.isAllAddressesSelected) {
        return;
      }
      if (this.hasAddresses && this.cards?.length) {
        this.checkForCards();
        this.checkForTwoCoops();
        this.checkForRequests();
        this.checkIfOnlyReservations();
        this.checkIfOnlyCrafRequests();
        if (this.hasJobs) {
          const messages = await this.getMessages();
          this.buildActivityTimelines(messages);
        }
      }
      const me = await this.me.load();
      this.showContactConciergeCheck(me);
    } catch (error) {
      if (error?.code === 404 || error?.message?.includes("Couldn't find Address with")) {
        this.goToAllAddresses();
      }
      this.handleLoadErrors(error);
    }
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('schedule')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  parseScheduleData(scheduleData: any, addresses: TidySelectNumberValueModel[]): Properties {
    const allJobs = scheduleData?.map((address) => address?.jobs).flat();
    const allReservations = scheduleData?.map((address) => address?.reservations).flat();
    const allAvailabilityRequests = scheduleData?.map((address) => address?.availability_requests).flat();
    const properties: Properties = addresses?.map((address) => {
      if (address?.value === 0 || address?.value === -1) {
        return
      }
      const foundReservations = allReservations.filter((reservation) => reservation?.address_id === address?.value)
      const foundAddress = this.addressResponse.find((addressResponse) => addressResponse?.id === address?.value);
      const parsedReservations = this.schedule.parseCalendarReservations(foundReservations, foundAddress, this.selectedPeriodRange, this.daysOfWeek);
      const foundJobs = allJobs.filter((job) => job?.address_id === address?.value)
      const parsedJobs = this.schedule.parseCalendarJobs(foundJobs, foundAddress);
      const foundAvailabilityRequests = allAvailabilityRequests.filter((request) => request?.address_id === address?.value)
      const availabilityRequests = this.schedule.parseCalendarAvailabilityRequests(foundAvailabilityRequests, foundAddress, this.selectedPeriodRange, this.daysOfWeek);
      const startDateIsInPast = this.checkIfStartDateIsInPast(parsedReservations[0]?.period?.startDate);
      const nextReservationDate = (startDateIsInPast ? parsedReservations[1]?.period?.startDate : parsedReservations[0]?.period?.startDate) || null;
      const nextJobDate = parsedJobs[0]?.period?.startDate || null;
      return {
        id: address?.value,
        name: address?.viewValue,
        address: foundAddress?.address1,
        reservations: parsedReservations,
        filteredReservations: parsedReservations,
        jobs: parsedJobs,
        filteredJobs: parsedJobs,
        availabilityRequests,
        filteredAvailabilityRequests: availabilityRequests,
        nextReservationDate,
        nextJobDate,
      }
    }).filter((property) => property);
    return properties;
  }

  checkIfStartDateIsInPast(date) {
    const today = DateTime.now().toISODate();
    const isBeforeToday = DateTime.fromISO(date) < DateTime.fromISO(today);
    return isBeforeToday;
  }

  async showLoading(): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingCtrl.create({
      message: 'Loading..',
      showBackdrop: true,
      backdropDismiss: false,
      mode: 'ios',
      animated: true,
    });

    loading.present();
    return loading;
  }

  async getScheduleData(
    period: Period,
    addresses: TidySelectNumberValueModel[]
  ): Promise<void> {
    try {
      let scheduleData = [];

      if (this.scheduleViewMode === 'MONTHLY' && !this.routeReused) {
        const endMonth = period.endDate.split('-')[1];
        const startMonth = period.startDate.split('-')[1];
        if (endMonth !== startMonth) {
          period.startDate =
            period.endDate.split('-')[0] + '-' + endMonth + '-' + '01';
        }

        const startDate = LuxonDateTime.fromISO(period.startDate);
        const endDate = LuxonDateTime.fromISO(period.endDate);
        const middleDate = LuxonDateTime.fromISO(
          period.startDate.split('-')[0] + '-' + endMonth + '-' + '15'
        );
        const requestParams = [
          {
            startDate: startDate.toISODate(),
            endDate: middleDate.toISODate(),
            scope: 'jobs',
          },
          {
            startDate: middleDate.plus({ days: 1 }).toISODate(),
            endDate: endDate.toISODate(),
            scope: 'jobs',
          },
          {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            scope: 'reservations',
          },
          {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            scope: 'availability_requests',
          },
        ];
        const weeklyDataObservables = requestParams.map((requestParam) =>
          from(
            this.schedule.getScheduleCalendar(
              requestParam.startDate,
              requestParam.endDate,
              this.filters,
              requestParam?.scope
            )
          ).pipe(
            tap((result) => {
              scheduleData = scheduleData.concat(result);
              this.operationsAfterScheduleDataRetrieved(
                scheduleData,
                addresses
              );
            })
          )
        );
        merge(...weeklyDataObservables)
          .pipe(
            finalize(() => {
              this.isScheduleDataLoaded = true;
              this.isLoadingSchedule = false;
            })
          )
          .subscribe({
            error: (error) => {
              this.isLoadingSchedule = false;
            },
          });
      } else {
        scheduleData = this.showOnboarding ? scheduleMockData : await this.schedule.getScheduleCalendar(
          period.startDate,
          period.endDate,
          this.filters
        );
        this.operationsAfterScheduleDataRetrieved(scheduleData, addresses);
        this.isScheduleDataLoaded = true;
        this.isLoadingSchedule = false;
      }
    } catch (error) {
      this.isLoadingSchedule = false;
    }
  }

  operationsAfterScheduleDataRetrieved(
    scheduleData: any,
    addresses: TidySelectNumberValueModel[]
  ) {
    if (this.addressResponse.length == 1) {
      scheduleData.map((day) => {
        day.jobs?.map((job) => {
          if (job.address_id == this.addressResponse[0].id) {
            this.hasJobsAtOnlyAddress = true;
          }
        });
      });
    }
    const parsedScheduleData = this.parseScheduleData(scheduleData, addresses);
    this.schedule.properties = parsedScheduleData;
    this.schedule.imutableProperties = parsedScheduleData;
    const skipSortingProperties =
      localStorage.getItem('skipSortingProperties') == 'true';
    if (!skipSortingProperties) {
      this.sortProperties();
    } else {
      this.keepCurrentPropertiesOrder();
    }
    localStorage.setItem('skipSortingProperties', 'false');
    this.updateTableFilters();
  }

  keepCurrentPropertiesOrder() {
    const orderMap = new Map(
      this.schedule.imutableProperties.map((prop, index) => [prop.id, index])
    );
    this.schedule.properties.sort(
      (a, b) => orderMap.get(a.id) - orderMap.get(b.id)
    );
    this.schedule.imutableProperties = this.schedule.properties;
  }

  sortProperties(): void {
    this.schedule.properties = this.schedule.properties.sort((a, b) => {
      const aNextDate = a?.nextReservationDate || a?.nextJobDate;
      const bNextDate = b?.nextReservationDate || b?.nextJobDate;
      if (!aNextDate && !bNextDate) {
        return 0;
      }
      if (!aNextDate) {
        return 1;
      }
      if (!bNextDate) {
        return -1;
      }
      const aNextDateTime = new Date(aNextDate).getTime();
      const bNextDateTime = new Date(bNextDate).getTime();
      return aNextDateTime - bNextDateTime;
    });
    this.schedule.imutableProperties = this.schedule.properties;
  }

  async getPageData(getAddressesFromBackend, openScheduleListSideRail = true) {
    const shouldOpenBookingPage = localStorage.getItem('shouldOpenBookingPage') == 'true';
    this.hasAddresses = await this.getAddresses(getAddressesFromBackend);
    this.isAllAddressesSelected = true;
    if (this.isAllAddressesSelected) {
      this.desktopWidthContent = scrollContentWidth.NORMAL;
      this.events.publish('updateMenu', '');
      this.addressId = -1;
      await this.getScheduleData(this.selectedPeriodRange, this.addressFilter);
      if (this.addressResponse.length == 1 && openScheduleListSideRail && !shouldOpenBookingPage && this.windowService.isDesktopRes) {
        if (this.rightSidePanelService.isClosingPanel) {
          setTimeout(() => {
            this.goToPropertyPage(this.addressResponse[0].id);
          }, 301);
        } else {
          this.goToPropertyPage(this.addressResponse[0].id);
        }
      }
      return
    } else {
      localStorage.setItem('isAllAddressesSelected', 'false')
      this.events.publish('updateMenu', '');
    }
    this.getHasCreditCardInfo();
    if (this.hasAddresses) {
      this.desktopWidthContent = scrollContentWidth.PORTRAIT;
      this.getPlans();
      this.getClientSettings();
      await this.getCards();
    }
    localStorage.setItem('shouldOpenBookingPage', 'false');
  }


  buildAllTableStartEndDaysArray(startDate) {
    this.allTableStartEndDays = [];
    for (let i = 0; i < 30; i++) {
      if (i !== 0) {
        startDate = startDate.plus({ days: 3 });
      }
      this.allTableStartEndDays.push({
        startDate: startDate.toISODate(),
        endDate: startDate.plus({ days: 2 }).toISODate()
      });
    }
  }

  buildReservationAndJobsHtml(calendar, row, header) {
    const addressId = row['<div style="margin-left: -15px">Property</div>'].id;
    let jobs = [];
    let reservations = [];
    calendar.map((day) => {
      day.jobs.map((job) => {
        if (job.address_id == addressId) {
          jobs.push(job);
        }
      });
      day.reservations.map((reservation) => {
        if (reservation.address_id == addressId) {
          reservation['date'] = day.date;
          reservations.push(reservation);
        }
      });
    });
    const jobsString = this.buildJobsString(jobs, header);
    const reservationsString = this.buildReservationsString(reservations, header);
    let string = jobsString + reservationsString;
    if (string == '') {
      return '<div style="text-align: left !important">-</div>';
    } else {
      string = string.replace(/<br>\s*$/, "");
      return string;
    }
  }

  buildJobsString(jobs, header) {
    let string = '';
    jobs.map((job) => {
      if (`<div style="margin-left: -15px">${job.date}</div>` == header) {
        const startTime = LuxonDateTime.fromISO(`1970-01-01T${job.start_time}:00.000`);
        const formattedTime = startTime.toFormat('h:mm a');
        const jobLink = this.getJobLink(job);
        string += `
        <div class="vertical-align-middle" style="text-align: left !important">
          <img style="width: 16px; margin-right: 5px; margin-bottom: -3px" src="${job.service_icon_url}"><a style="color: #00AABA" href="${jobLink}">${formattedTime}</a> - ${job.job_progress}
        `;
        if (job?.open_issue_count > 0) {
          string += '<img style="width: 16px; margin-right: 5px; margin-bottom: -3px" src="assets/img/alert.svg">';
        }
        string += '</div><br>'
      }
    });
    return string;
  }

  getJobLink(job) {
    let link = '#'
    if (job?.job_progress == 'completed' || job?.job_progress == 'client_cancelled' || job?.job_progress == 'homekeeper_cancelled') {
      link += `/past-job/${job.id}`;
    } else {
      link += `/job/${job.address_id}/${job.id}`;
    }
    return link;
  }

  buildReservationsString(reservations, header) {
    let string = '';
    reservations.map((reservation) => {
      if (`<div style="margin-left: -15px">${reservation.date}</div>` == header) {
        const startDate = LuxonDateTime.fromFormat(reservation.check_in_date, 'MMM d, yyyy');
        const formattedStartDate = startDate.toFormat('M/d');
        const endDate = LuxonDateTime.fromFormat(reservation.check_out_date, 'MMM d, yyyy');
        const formattedEndDate = endDate.toFormat('M/d');
        string += `
        <div class="vertical-align-middle" style="color: #666; text-align: left !important">
          <img style="width: 16px; margin-right: 5px; margin-bottom: -3px" src="assets/img/calendar-link.svg">${formattedStartDate} - ${formattedEndDate}
        </div><br>
        `;
      }
    });
    return string;
  }

  async goToPropertyPage(addressId) {
    const isMobileResolution = window.innerWidth <= 870;
    this.currentAddress.addressId = addressId.toString();
    localStorage.setItem('isAllAddressesSelected', 'false');
    if (isMobileResolution) {
      await this.storage.save('dialog-right-side-open', false)
      this.navCtrl.navigateForward('schedule-list');
    } else {
      await this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(ScheduleListPage);
    }
  }

  async getClientSettings() {
    this.phoneNumber = await this.storage.retrieve('phoneNumber');
    if (this.phoneNumber) {
      this.hasPhone = this.phoneNumber !== null && this.phoneNumber !== '';
    } else {
      this.settings = await this.client.getClientSettings();
      this.phoneNumber = this.settings?.profile?.phone;
      this.hasPhone = this.settings?.profile?.phone === null || this.settings?.profile?.phone === '';
    }
    this.hasPrivatePro = await this.pros.checkIfHasPrivatePro();
  }

  async getAddresses(getAddressesFromBackend) {
    if (this.showOnboarding) {
      this.addressResponse = propertiesMockData;
    } else {
      this.addressResponse = await this.client.getMoreDetailAddresses(getAddressesFromBackend);
    }
    if(this.addressResponse.length === 0){
      localStorage.setItem('getAddressesFromBackend', 'true');
      return false;
    }
    else {
      if (this.showOnboarding) {
        this.addressId = propertiesMockData[0].id;
      } else {
        this.addressId = await this.client.getSelectedAddressId(this.addressResponse);
      }
      this.addressFilter = this.client.parseAddressList(this.addressResponse, true);
      if (this.addressResponse.length > 1 || this.isRentalClient) {
        const allPropertiesOption = {
          value: -1,
          viewValue: 'All Properties',
        }
        this.addressFilter.unshift(allPropertiesOption);
      }
      this.addressFilterLoaded = true;
      this.getSelectedAddressInfo();
      return true;
    }
  }

  getSelectedAddressInfo() {
    const selectedAddress = this.selectedAddress();
    this.toDoState = selectedAddress?.default_address_task_list_state;
    localStorage.setItem('timezone', selectedAddress?.timezone);
    localStorage.setItem('toDoState', this.toDoState);
    this.addressRegionId = selectedAddress?.region_id;
    this.addressName = this.client.buildAddressName(selectedAddress);
  }

  async getHasCreditCardInfo() {
    const address = this.selectedAddress();
    this.automaticBookingEnabled = address?.address_automatic_booking_setting?.automatic_booking_enabled;
    if (this.automaticBookingEnabled) {
      this.hasCreditCard = await this.card.hasValidCard();
    }
  }

  getPlans() {
    let plans;
    this.addressResponse.map((address) => {
      if (address.id === this.addressId) {
        plans = address.plans;
      };
    });
    this.plans = plans == null ? [] : plans;
    this.checkIfHasAllOneTimePlans();
  }

  checkIfHasAllOneTimePlans() {
    this.hasAllOneTimePlans = true;
    this.plans.map((plan) => {
      if (plan?.plan_frequency !== 'once') {
        this.hasAllOneTimePlans = false;
      }
    });
  }

  async getCards() {
    this.cardsLoaded = false;
    let cards = await this.schedule.getCards(this.addressId);
    //TODO https://trello.com/c/70YyrgSM/7701-b-list-endpoint-add-unassigned-data-to-the-list-response
    //Have BE return the data from getUpdates for each card instead of FE running this method
    cards.map(async (card) => {
      if (card?.template_data?.scenario_type === 'unassigned' || card?.template_data?.scenario_type === 'manual_request') {
        let updateId;
        card?.template_data?.updates.map(async (update) => {
          let updateHasAction;
          update?.text?.map((text) => {
            updateHasAction = text?.action === 'cleaning_update_page';
          });
          if (updateHasAction) {
            card.template_data['unassigned'] = await this.schedule.getUpdates(update.id);
          };
        });
      };
    });
    if (this.automaticBookingEnabled) {
      const today = moment();
      let addedFourWeekAlert = false;
      cards.map(card => {
        if (card?.template === 'guest_reservations') {
          const reservationDate = moment(card?.template_data?.check_out_date);
          const isFourWeeksOut = reservationDate.subtract(4, 'weeks').isSameOrAfter(today);
          if (isFourWeeksOut && !addedFourWeekAlert) {
            card['fourWeekAlert'] = true;
            addedFourWeekAlert = true;
          }
        }
      });
    }
    cards.map((card) => {
      if (card.template_data?.notification_history) {
        card.template_data.notification_history = this.schedule.getFilteredWaterfallHistory(card.template_data.notification_history);
      }
    })
    this.cards = cards;
    this.updateJobRequestStatus();
    this.hasJobs = !!this.cards?.find((card) => {
      return card?.template !== 'guest_reservations';
    });
    this.cardsLoaded = true;
  }

  async getMessages() {
    let jobIdsArray = [];
    this.cards?.map((card) => {
      if (card?.template == 'cleaning') {
        jobIdsArray.push(card?.template_data?.job?.id);
      }
    })
    const messages = await this.communication.getJobMessages(jobIdsArray);
    return messages;
  }

  buildActivityTimelines(messages) {
    this.cards?.map((card) => {
      if (card?.template == 'cleaning') {
        const homekeeperJobId = card?.template_data?.activity_timelines?.[0]?.homekeeper_job_id;
        const homekeeperJobMessages = messages?.filter((message) => message?.metadata?.homekeeper_job_id == homekeeperJobId);
        let updates = card?.template_data?.activity_timelines[0]?.activity_timeline_items?.filter((item) => item.type == 'cleaning_update');
        if (homekeeperJobMessages?.length > 0) {
          if (card?.template_data?.activity_timelines[0]) {
            card.template_data.activity_timelines[0]['activity_timeline_items'] = this.concatenateMessagesAndUpdates(updates, homekeeperJobMessages);
          } else {
            card?.template_data?.activity_timelines?.push({
              activity_timeline_items: homekeeperJobMessages
            });
          }
        }
      }
    });
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates?.map((update) => {
      update['sent_at'] = update?.data?.created_at;
    });
    if (updates?.length) {
      const activity = updates?.concat(messages);
      activity?.sort((a, b) => a?.sent_at < b?.sent_at ? 1 : -1);
      return activity;
    } else {
      return messages;
    }
  }

  checkForCards() {
    if (!this.cards?.length || this.cards?.length === 0) {
      localStorage.setItem('no_plan', 'true');
    }
  }

  checkForTwoCoops() {
    if (this.cards?.length > 1) {
      const numberOfPendingCoops = this.cards?.filter((card) => card?.template === 'job_request');
      this.twoCoops = (numberOfPendingCoops?.length > 1) ? true : false;
    }
  }

  checkForRequests() {
    this.cards?.map((card) => {
      if (card?.template === 'pending_availability_request') {
        this.hasRequests = true;
      }
    })
  }

  checkIfOnlyReservations() {
    this.hasReservations = !!this.cards?.find((card) => {
      return card?.template === 'guest_reservations';
    });
    this.hasOnlyReservations = !this.hasJobs && this.hasReservations;
  }

  checkIfOnlyCrafRequests() {
    const hasJobs = !!this.cards?.find((card) => {
      return card?.template !== 'pending_availability_request';
    });
    const hasCrafRequests = !!this.cards?.find((card) => {
      return card?.template === 'pending_availability_request';
    });
    this.hasOnlyCrafRequests = !hasJobs && hasCrafRequests;
  }

  async showContactConciergeCheck(me) {
    try {
      this.clientName = me?.customer_account?.name;
      if (!this.isRentalClient) {
        return;
      }
      const hasPastJobsStorage = localStorage.getItem('hasPastJobs');
      if (hasPastJobsStorage !== null) {
        this.showContactConcierge = hasPastJobsStorage === 'false';
      } else {
        const pastJobs = await this.client.getCleaningsHistory();
        const hasPastJobs = pastJobs?.length > 0;
        localStorage.setItem('hasPastJobs', hasPastJobs ? 'true' : 'false');
        this.showContactConcierge = !hasPastJobs;
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  updateJobRequestStatus() {
    this.cards.map((card) => {
      if (card?.template_data?.notification_history) {
        if (card?.template_data?.notification_history[0]?.text == 'All pros on your priority list have declined.') {
          card.template_data.job_request_status = 'failed';
        }
      }
    });
  }

  applyListToJob(job) {
    if (this.toDoState === null) {
      const params = {
        addressResponse: this.addressResponse,
        addressId: this.addressId
      }
      return this.navCtrl.navigateForward('use-to-dos', params);
    }
    const params = {
      job: job,
    }
    this.navCtrl.navigateForward('apply-to-job', params);
  }

  goToEditBackupTimes(job) {
    const params = {
      job: job
    }
    this.navCtrl.navigateForward('job-backup-times', params);
  }

  learnMoreSdc() {
    this.util.openUrl('https://help.tidy.com/add-or-request-a-job');
  }

  async bookJob(bookingType) {
    await this.storage.save('bookJobBackPage', 'schedule');
    await this.client.getClientInfo();
    localStorage.setItem('region_id', this.addressRegionId.toString());
    const address = this.selectedAddress() || this.addressResponse[0];
    const isMobileResolution = window.innerWidth <= 870;
    const params = {
      address,
      bookingType,
    }
    if (this.hasPrivatePro || this.isRentalClient) {
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(SelectCategoryPage);
      } else {
        this.navCtrl.navigateForward('select-category', params);
      }4
    } else {
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(BookJobPage);
      } else {
        this.navCtrl.navigateForward('book-job', { address, bookingType });
      }
    }
  }

  async goToPlansPage() {
    const params = {
      address: this.selectedAddress(),
      plans: this.plans,
      addressFilter: this.addressFilter,
      addressResponse: this.addressResponse,
      cards: this.cards
    }
    this.addressId = null;
    await this.navCtrl.navigateForward('plans', params);
  }

  rescheduleCancel(card) {
    const address = this.selectedAddress();
    const bookingId = card.template_data.booking.id;
    const bookingKey = card.template_data.booking.bookable_service.key;
    this.navCtrl.navigateForward('reschedule-cancel', { address, card, bookingId, bookingKey });
  }

  async editProRequest(availabilityRequest: AvailabilityRequestResponse) {
    this.addressId = parseInt(localStorage.getItem('addressId'));
    try {
      const isMobileResolution = window.innerWidth <= 870;
      const params = {
        cameFromEditRequest: true,
        availabilityRequestId: availabilityRequest.id,
        requestTimes: availabilityRequest.request_times,
        serviceType: availabilityRequest.service_type_details.key,
        times: availabilityRequest.request_times,
        frequency: {
          viewValue: availabilityRequest.frequency,
          value: availabilityRequest.frequency
        },
        pro: {
          first_name: availabilityRequest.homekeeper?.name.split(' ')[0],
          id: availabilityRequest.homekeeper?.id
        },
        address: this.selectedAddress()
      };
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(RequestProPage);
      } else {
        this.navCtrl.navigateForward('request-pro', params);
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async cancelProRequest(availabilityRequest) {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: 'You can always create a new request if you\'d like.',
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelProRequest.bind(this, availabilityRequest)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelProRequest(availabilityRequest) {
    try {
      await this.schedule.cancelCrafRequest(availabilityRequest.id);
      await this.getCards();
      this.checkForRequests();
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  editWaterfallRequest(card) {
    const params: any = {
      selectedService: {
        key: card.template_data.booking.bookable_service.key,
        title: card.template_data.booking.bookable_service.name,
        price: card.template_data.booking.price,
        duration: card.template_data.booking.bookable_service.duration_to_block_off
      },
      address: {
        id: this.addressId,
        region_id: this.addressRegionId,
      },
      planId: card.template_data.booking.plan_id,
      bookingId: card.template_data.booking.id,
      serviceTypeId: card.template_data.booking.bookable_service.service_type_id
    };
    this.navCtrl.navigateForward('request-job', params);
  }

  async cancelWaterfallRequest(jobId) {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: 'You can always create a new request if you\'d like.',
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelWaterfallRequest.bind(this, jobId)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelWaterfallRequest(bookingId) {
    try {
      await this.schedule.cancelJob(bookingId);
      const getAddressesFromBackend = true;
      await this.getPageData(getAddressesFromBackend);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  goToRequestProCounter(availabilityRequest: AvailabilityRequestResponse) {
    const params = {
      planFrequency: availabilityRequest.frequency,
      service: availabilityRequest.service_type_details,
      proposedTimes: availabilityRequest.request_times,
      proName: availabilityRequest.homekeeper?.name.split(' ')[0],
      hkId: availabilityRequest.homekeeper?.id,
      availabilityRequestId: availabilityRequest.id,
      addressId: this.addressId,
      addressName: this.addressName
    };
    this.navCtrl.navigateForward('request-pro-counter', params);
  }

  contactPro(card) {
    const params = {
      pro: card?.template_data?.homekeepers[0],
      isPrivate: card.template_data.job.is_private,
      clientPhone: this.phoneNumber
    }
    const url = 'call-pro';
    const component = CallProPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToProPage(proId) {
    localStorage.setItem('myProBackPage', 'schedule');
    this.navCtrl.navigateForward(`my-pro/${proId}`);
  }

  @Loading('', true)
  async checkWaterfallHistory(selectionChange, card){
    const notification = card?.template_data?.notification_history[selectionChange.selectedIndex];
    this.notificationHistory = '';
    if(notification.type === 'history' && (notification?.data?.id && notification?.data?.unassigned_job_id)){
      try {
        this.notificationHistory = await this.client.getWaterfallNotificationHistory(notification?.data?.id);
      } catch (err) {
        this.notificationHistory = '';
        this.logger.error(err);
      }
    } else {
      this.notificationHistory = '';
    }
  }

  goToNotificationDetailsPage(notification) {
    const params = {
      notification
    }
    this.navCtrl.navigateForward('notification-details', params);
  }

  goToEditContactInfo() {
    const params = {
      settings: this.settings
    };
    this.navCtrl.navigateForward('contact-info', params);
  }

  async goToJobPage(card) {
    const address = this.selectedAddress();
    const params = {
      card: card,
      address: address,
      addressName: this.addressName,
      settings: this.settings,
      cameFromSchedulePage: true,
      clientName: this.clientName
    };
    localStorage.setItem('jobBackPage', 'schedule');
    this.navCtrl.navigateForward(`job/${address.id}/${card.template_data.job.id}`, params);
  }

  needsWork(job, pro) {
    const params = {
      firstName: pro.first_name,
      pro,
      isCleaningJob: job.template_data.job.service_type_details.service_category == 'Regular Cleaning'
    }
    this.navCtrl.navigateForward(`needs-work-what-happened/${job.template_data.job.id}/${pro.homekeeper_job_id}`, params);
  }

  loveIt(job, pro) {
    this.navCtrl.navigateForward(`love-it/${job.template_data.job.id}/${pro.homekeeper_job_id}`);
  }

  reportNoShow(proName, jobId, hkId) {
    const params = {
      proName: proName,
      hkId: hkId,
      jobId: jobId
    }
    this.navCtrl.navigateForward('pro-did-not-show-up', params);
  }

  async skipJob(bookingId) {
    const params: ConfirmPageParamsModel = {
      title: 'Skip Job?',
      body: 'This will cancel the job (does not affect other jobs).',
      backText: 'Go Back',
      confirmText: 'Skip Job',
      confirmAction: this.skipJobAction.bind(this, bookingId)
    }

    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal',
    });

    await confirmationModal.present();
  }

  async skipJobAction(bookingId) {
    try {
      await this.schedule.cancelJob(bookingId);
      const getAddressesFromBackend = true;
      await this.getPageData(getAddressesFromBackend);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async rescheduleJob(card) {
    const address = this.selectedAddress();
    const bookingType = card.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
    const planId = card.template_data.booking.plan_id;
    const jobId = card.template_data.job.id;
    const bookingId = card.template_data.booking.id;
    this.navCtrl.navigateForward('book-job', { address, bookingType, jobId, bookingId, planId });
  }

  requestSubstitute(jobId) {
    this.navCtrl.navigateForward(`request-substitute/${jobId}`);
  }

  unassignedAction(action, card) {
    const pages = {
      'skip_cleaning': 'skip-cleaning',
      'reschedule_cleaning': `reschedule-skip/reschedule/`,
      'request_substitute': `request-substitute/${card.template_data.job.id}`,
      'cancel_substitute': 'cleaning-detail',
      'keep_one_homekeeper': 'cleaning-detail',
      'same_day_cleaning_page_v1': 'same-day-cleaning',
    }
    if (action == 'keep_one_homekeeper') {
      return this.keepOnlyOnePro(card.template_data.job.id);
    }
    if (action === 'reschedule_cleaning') {
      const bookingType = card.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
      const planId = card.template_data.booking.plan_id;
      const address = this.selectedAddress();
      const jobId = card.template_data.job.id;
      const bookingId = card.template_data.booking.id;
      return this.navCtrl.navigateForward('book-job', { address, bookingType, jobId, bookingId, planId });
    }
    if (action === 'skip_cleaning' || action === 'cancel_substitute') {
      this.skipJob(card.template_data.booking.id);
    } else if (action === 'same_day_cleaning_page_v1') {
      const service = card.template_data.booking;
      const params = {
        selectedService: {
          title: service.bookable_service.name,
          image: service.bookable_service?.icon_url || 'assets/img/plans/' + service.bookable_service.key + '.svg',
          description: service.bookable_service.description,
          key: service.bookable_service.key,
          price: service.price,
          duration: service.bookable_service.duration_to_block_off
        },
        address: this.selectedAddress(),
        planId: service.plan_id,
        bookingId: card.template_data.booking.id,
        bookingType: 'reschedule_job',
        priceIsPlusFiftyPercent: true
      };
      this.navCtrl.navigateForward('request-job', params);
    } else if (action === 'request_substitute'){
      this.requestSubstitute(card.template_data.job.id);
    } else {
      const link = pages[action] ? pages[action] : {};
      const param = {
        jobId: card.template_data.job.id
      };
      this.navCtrl.navigateForward(link, param);
    }
  }

  async keepOnlyOnePro(jobId) {
    const params: ConfirmPageParamsModel = {
      title: 'Keep Only 1 Pro?',
      body: 'You\ll be billed the prorated rate.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmKeepOnePro.bind(this, jobId)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmKeepOnePro(jobId) {
    try {
      await this.schedule.keepOneProForTwoProJob(jobId);
      const successParams = {
        header: 'Kept One Pro',
        body: 'You were billed the prorated rate. Please contact the concierge with any questions.',
        buttonText: 'Ok',
        buttonRoute: 'schedule'
      };
      this.navCtrl.navigateForward('success', successParams);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  selectedAddress() {
    return this.addressResponse.find(ad => ad.id === this.addressId);
  }

  goToReviews() {
    this.util.openUrl('https://www.tidy.com/reviews');
  }

  goToReservation(event) {
    let guestReservation = event?.event?.object
    const didClickReservationFromAddressView = !guestReservation;
    this.addressId = event?.property?.id;
    this.addressName = event?.property?.name;
    if (didClickReservationFromAddressView) {
      guestReservation = event?.template_data;
      guestReservation['id'] = event.id.replace('guest_reservation-', '');
      this.addressId = event?.template_data?.address?.id;
      this.addressName = event?.template_data?.address?.address1;
    }
    const params = {
      guestReservation,
      address: this.addressName,
      addressId: event?.template_data?.address?.id || event?.event?.address?.id
    };
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.rightSidePanelService.setDialogPageTitle('Reservation');
      this.storage.save('dialog-params', params);
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(ReservationPage);
    } else {
      this.navCtrl.navigateForward('reservation', params);
    }
  }

  goToTerms() {
    this.util.openUrl('https://www.tidy.com/legal/property-managers-customer');
  }

  learnMoreReservations() {
    this.util.openUrl('https://help.tidy.com/integrations');
   }

  goToScheduleCallPage() {
    this.util.openUrl('https://www.tidy.com/schedule-integration-support');
  }

  goToIntegrations() {
    const address = this.addressResponse.find((address) => {
      return address.id == this.addressId;
    });
    const params = {
      address
    }
    this.navCtrl.navigateForward('automatic-booking-property', params);
  }

  goToShareJobPage(job) {
    const params = {
      job: job,
      addressName: this.addressName
    };
    this.navCtrl.navigateForward('share-job', params);
  }

  showUpdates(card) {
    return card?.template_data?.activity_timelines[0]?.activity_timeline_items?.length && !card?.template_data?.unassigned;
  }

  async goToChangePro(card) {
    try {
      const privateProsList = await this.pros.getAllPrivatePros();
      const params = {
        job: card,
        addressId: this.addressId,
        privateProsList: privateProsList,
        booking: card.template_data.booking
      }
      const url = privateProsList?.length ? 'assign-pro' : 'why-add-private-pros';
      this.navCtrl.navigateForward(url, params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToAddAddress(){
    this.navCtrl.navigateForward('add-property');
  }

  goToBookDemo() {
    this.util.openUrl('https://www.tidy.com/get-demo');
  }

  async handleClickActionMonthlyView(event: onClickActionMonthlyView): Promise<void> {
    if (event?.job?.parsedRequestTimes) {
      const loading = await this.showLoading();
      this.addressId = event?.job.address?.id;
      const availabilityRequest: AvailabilityRequestResponse = event?.job?.object;
      if (availabilityRequest.status === 'countered') {
        this.goToRequestProCounter(availabilityRequest);
      } else {
        this.editProRequest(availabilityRequest);
      }
      loading.dismiss();
      return;
    }
    if (event.type === 'job') {
      this.goToCleaningDetail(event.job, event.job.address.id);
    }
    if (event.type === 'day' || event.type === 'reservation') {
      const dialogParams = await this.storage.retrieve('dialog-params');
      const params = { ...dialogParams, ...event };
      this.storage.save('dialog-params', params);
      const isMobileResolution = window.innerWidth <= 870;
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.rightSidePanelService.openRightSidePanel(SummaryOfDayPage);
      } else {
        this.storage.save('dialog-right-side-open', false);
        this.navCtrl.navigateForward('summary-of-day', params);
      }
    }
  }

  async handleClickAction(event: ClickAction): Promise<void> {
    this.bookJobService.clearBookJobStorage();
    if (event.eventType === 'availabilityRequests') {
      const loading = await this.showLoading();
      this.addressId = event?.property?.id;
      const availabilityRequest: AvailabilityRequestResponse = event?.event?.object;
      if (availabilityRequest.status === 'countered') {
        this.goToRequestProCounter(availabilityRequest);
      } else {
        this.editProRequest(availabilityRequest);
      }
      loading.dismiss();
      return
    }
    if (event?.target === 'property') {
      this.goToPropertyPage(event?.property?.id);
      return
    }
    if (event?.eventType === 'jobs') {
      this.goToCleaningDetail(event.event as Job, event.property.id);
      return
    }
    const loading = await this.showLoading();

    this.goToReservation(event);
    loading.dismiss();
  }

  @Loading('', true)
  async goToCleaningDetail(cleaning: any, addressId: number) {
    const isMobileResolution = window.innerWidth <= 870;
    const isPastJob = cleaning.status.name === 'cancelled' || cleaning.status.name === 'completed';
    const isPrivateJob = cleaning.isPrivate;
    if (!isMobileResolution) {
      this.navigateToJobPageInsideDialog(cleaning, addressId, isPastJob, isPrivateJob);
      return;
    }
    localStorage.setItem('pastJobBackPage', 'schedule');
    if (isPastJob) {
      if (isPrivateJob) {
        this.navCtrl.navigateForward([`past-private-job/${cleaning.id}`]);
      } else {
        this.navCtrl.navigateForward([`past-job/${cleaning.id}`]);
      }
    } else {
      this.navCtrl.navigateForward([`job/${addressId}/${cleaning.id}`], { object: cleaning.object });
    }
  }

  navigateToJobPageInsideDialog(cleaning: Job, addressId: number, isPastJob: boolean, isPrivateJob: boolean): void {
    const params = {
      jobId: cleaning.id,
      addressId: addressId,
      ...cleaning,
    }
    this.storage.save('dialog-params', params);
    this.storage.save('dialog-right-side-open', true);
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.openRightSidePanel(PastPrivateJobPage);
    } else if (isPastJob) {
      this.rightSidePanelService.openRightSidePanel(PastJobPage);
    } else {
      this.rightSidePanelService.openRightSidePanel(JobPage);
    }
  }

  goToAllAddresses(): void {
    localStorage.setItem('isAllAddressesSelected', 'true');
    location.reload();
  }

  onFABButtonClick() {
    if (!this.isAllAddressesSelected) {
      this.bookJob('add_one_time_job');
    } else if (this.addressResponse.length == 1) {
      const bookingType = this.addressResponse[0].plans.length > 0 ? 'add_one_time_job' : 'add_job';
      return this.bookJob(bookingType);
    } else {
      this.bookJobSelectAddress();
    }
  }

  bookJobSelectAddress() {
    this.storage.save('bookJobBackPage', 'schedule');
    const isMobileResolution = window.innerWidth <= 870;
    const params = {
      isSelectingAddressToBook: true
      }
    if (!isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(SelectCategoryPage);
    } else {
      this.navCtrl.navigateForward('select-category', params);
    }
  }

  goToAllIntegrations() {
    this.navCtrl.navigateForward('integrations/all')
  }

  @Loading('', true)
  async handleAssignedPro(job) {
    const assignProError = localStorage.getItem('assignProError') == 'true';
    if (!assignProError) {
      const getAddressesFromBackend = this.navCtrl.getParam('getAddressesFromBackend');
      await this.getPageData(getAddressesFromBackend);
      const proName = localStorage.getItem('proName');
      const successMessage = 'Successfully assigned ' + proName + '!';
      this.util.showSuccess(successMessage);
    }
    localStorage.setItem('proName', '');
    localStorage.setItem('assignProError', 'false');
    this.bookJobService.isAssigningPrivatePro = '';
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [backButton]="true"
    [title]="'Log In'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [id]="formIds.LOGIN_FORM" [formGroup]="loginForm" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="email"
            [form]="loginForm.controls.email"
            [label]="'Email'"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
            [errorMessage]="'Please enter a valid email'"
            [inputId]="inputIds.EMAIL_FIELD"
            [submitted]="submitted"
            type="email">
          </tidy-input>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-input
            formControlName="password"
            [form]="loginForm.controls.password"
            [label]="'Password'"
            [icon]="'assets/svg/lock-closed-outline.svg'"
            [activeIcon]="'assets/svg/lock-closed-outline-tidy-green.svg'"
            [type]="'password'"
            [errorMessage]="'Please enter a password'"
            [submitted]="submitted">
          </tidy-input>
        </tidy-row>
        <tidy-row align="center">
          <tidy-text
            class="link"
            [body]="'Forgot Password?'"
            (action)="pushToForgotPasswordPage()">
          </tidy-text>
        </tidy-row>
        <tidy-row align="center">
          <tidy-text
            class="link"
            [body]="'Forgot Username?'"
            (action)="pushToForgotUsernamePage()">
          </tidy-text>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row *ngIf="wrongAppError || wrongLoginError">
      <tidy-text
        *ngIf="wrongAppError"
        [body]="'It looks like you are a Pro. Please log in using '"
        class="red">
      </tidy-text>
      <tidy-text
        *ngIf="wrongLoginError"
        [body]="'Invalid email or password, please try again. If logging in as a Pro, use '"
        class="red">
      </tidy-text>
      <tidy-text
        [body]="'TIDY for Pros.'"
        (action)="goToTIDYForPros()"
        class="red link">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-button
        [text]="'Log In'"
        [action]="onSubmit.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <tidy-row align="center">
      <tidy-text
        [body]="'or create account'"
        (action)="goToSignUpPage()"
        class="link">
      </tidy-text>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';

import { Loading } from 'src/shared/components/loading/loading';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'booking-note.html',
  providers: [PhotoCaptionService]
})

export class BookingNotePage {

  bookingId: any;
  form: UntypedFormGroup;
  errorMessage: string;
  bookingNote: any;
  images: any[];
  textNoteId: any;
  videos: any[];
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private schedule: Schedule,
    private navCtrl: CustomNavController,
    private photoCaptionService: PhotoCaptionService,
    private fb: UntypedFormBuilder,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      textNote: ''
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Job Note');
    this.loadData();
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.bookingId = await this.getParam('bookingId')
    this.images = [];
    this.videos = [];
    this.bookingNote = await this.getParam('bookingNote');
    this.bookingNote.map((note) => {
      if (note.type == 'text') {
        this.textNoteId = note.id;
        this.form.patchValue({textNote: note.text});
      } else if (note.type == 'image') {
        this.images.push(note);
      } else if (note.type == 'video') {
        this.videos.push(note);
      }
    })
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  @Loading('', true)
  async saveBookingNote() {
    this.errorMessage = '';
    try {
      await this.addEditDeleteTextNote();
      await this.addEditDeletePhotoNotes();
      await this.addEditDeleteImageNotes();
      if (this.dialogParams) {
        this.rightSidePanelService.goBack();
        return;
      }
      const backPage = await this.getParam('backPage');
      const params = await this.getParam('params');
      this.navCtrl.navigateBack(backPage, params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async addEditDeleteTextNote() {
    const isAddingNewNote = !this.textNoteId && (this.form.value.textNote !== '' || this.form.value.textNote !== null);
    const isUpdatingCurrentNote = this.textNoteId && (this.form.value.textNote !== '' && this.form.value.textNote !== null);
    const isDeletingCurrentNote = this.textNoteId && (this.form.value.textNote == '' || this.form.value.textNote == null);
    if (isAddingNewNote) {
      const payload = {
        booking_id: this.bookingId,
        type: 'text',
        text: this.form.value.textNote
      }
      await this.schedule.createJobNote(payload);
    } else if (isUpdatingCurrentNote) {
      const payload = {
        id: this.textNoteId,
        type: 'text',
        text: this.form.value.textNote
      }
      await this.schedule.updateJobNote(this.textNoteId, payload);
    } else if (isDeletingCurrentNote) {
      await this.schedule.deleteJobNote(this.textNoteId);
    }
  }

  addEditDeletePhotoNotes() {
    //todo
  }

  addEditDeleteImageNotes() {
    //todo
  }

  deleteImage(index) {
    //todo
  }

  deleteVideo(index) {
    //todo
  }

  async addImage() {
    try {
      const image = await this.photoCaptionService.getImg('task');
      this.images.push(image);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  addVideo() {
    //todo
  }

}

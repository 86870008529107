import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';

import { Loading } from 'src/shared/components/loading/loading';

import { Util } from 'src/shared/util/util';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { environment } from 'src/environments/environment';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'share-job.html',
  encapsulation: ViewEncapsulation.None
})

export class ShareJobPage implements OnInit {

  addressName: string;
  copied: boolean;
  errorMessage: string;
  job: any;
  jobLink: any;
  dialogParams: any;
  isRightSideContent = true;
  isPastJobLink: boolean;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Send Link');
    this.loadData();
  }

  @Loading('', true)
  async loadData() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.job = await this.getParam('job');
      this.isPastJobLink = await this.getParam('isPastJobLink');
      this.rightSidePanelService.setDialogPageTitle((new TranslationPipe().transform('Send Link to ')) + this.job?.template_data?.homekeepers[0]?.first_name);
      this.addressName = await this.getParam('addressName');
      const payload = {
        link_origin_id: this.job.template_data.booking.id,
        link_origin_key: 'booking'
      };
      const response = await this.schedule.createJobLink(payload);
      this.jobLink = await this.buildJobLink(response);
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async buildJobLink(sharedLinkResponse) {
    if (this.isPastJobLink) {
      return 'app.tidy.com/#/past-shared-job/' + sharedLinkResponse.uuid;
    } else {
      return 'pro.tidy.com/#/job/' + sharedLinkResponse.uuid;
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  copyLink() {
    const textArea = document.createElement('textarea');
    textArea.value = this.jobLink;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  learnMore() {
    this.util.openUrl('https://help.tidy.com/job-links');
  }
}

import { Events } from 'src/providers/events/events';
import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { UntypedFormBuilder,UntypedFormGroup, Validators } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
import { DateTime as LuxonDateTime } from 'luxon';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from "src/providers/genome/genome";
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';
import { Logger } from "src/providers/logger";
import { OpenAIService } from "src/providers/open-ai/open-ai";
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';
import { UserService } from 'src/providers/user/user';

import { Loading } from "src/shared/components/loading/loading";
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Subscription } from 'rxjs';
import { RecurringTaskComponent } from 'src/pages/tasks/recurring-tasks/recurring-task/recurring-task';
import { ModalController } from '@ionic/angular';
import { MaintenancePlans } from 'src/providers/maintenance-plans/maintenance-plans';
import { Client } from 'src/providers/client/client';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { TaskPage } from 'src/pages/tasks/task/task';

@Component({
  templateUrl: "archived-tasks.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["archived-tasks.scss"]
})

export class ArchivedTasksPage implements OnInit {

  allArchivedTasks: any;
  archivedTasks: any;
  addressesIndexedById: any;
  issueReportTypes: any;
  categories: any;

  advice: any;
  adviceLoading: boolean;
  loaded: boolean;
  errorMessage: string;
  addressId: number | string;
  didSelectRelation: boolean;
  form: UntypedFormGroup;
  hasItems: boolean;
  helperMessage: string = "";
  mapRequests: any;
  map: any;
  objectItems: any;
  relateItems: any;
  rooms: any;
  skippedMapping: boolean;
  upcomingMaintenances: any;
  addressResponse: any;
  selectedFilters: any;
  maintenancePlans: any;
  filteredMaintenancePlans: any;
  panelClosedSubscription: Subscription;

  constructor(
    private pros: Pros,
    private reportIssue: ReportIssue,
    private genome: Genome,
    private genomeMaps: GenomeMaps,
    private fb: UntypedFormBuilder,
    private tidyAi: OpenAIService,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private toDos: ToDos,
    private events: Events,
    public windowService: WindowService,
    private storage: TidyStorage,
    private modalCtrl: ModalController,
    private rightSidePanelService: RightSidePanelService,
    private maintenance: MaintenancePlans,
    private client: Client,
    private schedule: Schedule,
    private userService: UserService
  ) {
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => this.onSearch(value))
  }

  ngOnInit() {
    this.getPageData();
  }

  async getPageData() {
    this.loaded = false;
    const archivedTasksParam = await this.navCtrl.getParam('archivedTasks');
    this.allArchivedTasks = archivedTasksParam || await this.getArchivedTasks();
    if (!archivedTasksParam) {
      this.parseUsersAndJobs();
    }
    this.archivedTasks = this.allArchivedTasks;
    this.addressesIndexedById = await this.storage.retrieve('addressesIndexedById');
    this.issueReportTypes = await this.reportIssue.getIssueReportTypes();
    this.categories = await this.pros.getServiceCategories();
    this.loaded = true;
  }

  async getArchivedTasks() {
    const loggedIssuesResponse = await this.reportIssue.getArchivedIssueReports();
    return loggedIssuesResponse.filter((task) => task.archived_at);
  }

  async parseUsersAndJobs() {
    let allUsersResponse = await this.userService.getUsersList();
    let allUsers = [];
    allUsersResponse.map((user) => {
      allUsers.push({
        name: user.first_name + ' ' + user.last_name,
        id: user.id
      });
    });
    this.storage.save('allUsers', allUsers);
    let cards = {};
    let addressIds = [];
    await Promise.all(this.allArchivedTasks.map(async (task) => {
      if (!addressIds.includes(task.address_id)) {
        addressIds.push(task.address_id);
        cards[task.address_id] = await this.schedule.getCards(task.address_id);
      }
    }));
    this.allArchivedTasks.forEach((task) => {
      const addressCards = cards[task.address_id].filter((card) => card?.template_data?.job);
      task['allJobs'] = [];
        addressCards.forEach((job) => {
          const addedJob = task.bookings.find((booking) => booking.id == job?.template_data?.booking?.id);
          let jobObject = {
            id: job?.template_data?.booking?.id,
            name: LuxonDateTime.fromISO(job.template_data.job.start_datetime_local).toFormat('M/d/yy h:mma').toLowerCase() + ' ' + job.template_data.booking.bookable_service.name,
            checked: !!addedJob,
            icon_url: job?.template_data?.job?.service_type_details?.service_category?.icon_url
          }
          if (addedJob) {
            jobObject['attachmentId'] = addedJob.attachment_id;
          }
          task['allJobs'].push(jobObject);
        });
        task['checkedJobs'] = task['allJobs'].filter((job) => job.checked);
        task['allUsers'] = [];
        allUsers.map((user) => {
          const addedUser = task.members.find((member) => member.id == user.id);
          let userObject = {
            checked: !!addedUser,
            name: user.name,
            id: user.id
          }
          if (addedUser) {
            userObject['attachmentId'] = addedUser.attachment_id;
          }
          task['allUsers'].push(userObject);
        });
        task['allUsers'].unshift({
          name: 'Concierge',
          id: 0,
          checked: task.assigned_to_concierge
        });
        task['checkedUsers'] = task['allUsers'].filter((user) => user.checked);
    });
  }

  onSearch(value) {
    value = value.search.toLowerCase();
    console.log(value)
    this.archivedTasks = this.allArchivedTasks.filter((task) => {
      return task.description.toLowerCase().includes(value) ||
             task.issue_report_type.name.toLowerCase().includes(value) || 
             task.title.toLowerCase().includes(value);
    });
  }

  async goToTask(issue) {
    const params = {
      issue,
      addressResponse: await this.storage.retrieve('addressResponse'),
      issueReportTypes: this.issueReportTypes,
      categories: this.categories
    }
    this.storage.save('dialog-right-side-open', true);
    this.storage.save('dialog-params', params);
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.openRightSidePanel(TaskPage);
      this.rightSidePanelService.setDialogPageTitle('Task');
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(() => {
        this.getPageData();
      });
    } else {
      this.navCtrl.navigateForward('task');
    }
  }

  goBack() {
    this.navCtrl.navigateBack('tasks');
  }

  getUserIcon(assignedUsers) {
    if (!assignedUsers?.length) {
      return '';
    }
    const conciergeUser = assignedUsers.find((user) => user.id == 0);
    if (conciergeUser) {
      return 'concierge';
    } else {
      return 'user';
    }
  }

  getIssueDueDate(dueDate) {
    return LuxonDateTime.fromISO(dueDate).toFormat('M/d/yyyy');
  }

  getIssueHeaderIcon(issue) {
    if (!issue) return '';
    const icons = {
      'low': 'assets/img/low_priority.svg',
      'normal': 'assets/img/normal_priority.svg',
      'high': 'assets/img/high_priority.svg',
      'emergency': 'assets/img/emergency_priority.svg'
    };
    return icons[issue.urgency];
  }

  getAddressName(task) {
    if (!task.address_id) return '';
    const address = this.addressesIndexedById[task.address_id];
    let addressName = '';
    if (address.address_name) {
      addressName = address.address_name
    } else if (address.address2) {
      addressName = address.address1 + ', ' + address.address2;
    } else {
      addressName = address.address1;
    }
    if (task?.jobs?.length == 1) {
      if (addressName.length < 18) {
        return addressName;
      } else {
        return addressName.substring(0, 18) + '..';
      }
    } else if (task?.jobs?.length > 1) {
      if (addressName.length < 10) {
        return addressName;
      } else {
        return addressName.substring(0, 10) + '..';
      }
    } else {
      return addressName;
    }
  }

}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { RoomModel } from 'src/models/to-do.model';

import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'share-list.html',
  encapsulation: ViewEncapsulation.None
})

export class ShareListPage implements OnInit {

  copied: boolean;
  errorMessage: string;
  isRightSideContent: boolean;
  listLink: any;
  loaded: boolean;
  rooms: RoomModel[];
  selectedListName: string;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private schedule: Schedule,
    private util: Util
  ) {
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      const dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Share To-Do List');
      this.selectedListName = this.navCtrl.getParam('selectedListName') || dialogParams.selectedListName;
      this.rooms = this.navCtrl.getParam('rooms') || dialogParams.rooms;
      const payload = {
        link_origin_id: this.navCtrl.getParam('taskListId') || dialogParams.taskListId,
        link_origin_key: 'address_task_list'
      };
      const response = await this.schedule.createJobLink(payload);
      this.listLink = 'pro.tidy.com/#/to-do-list/' + response.uuid;
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  copyLink() {
    const textArea = document.createElement('textarea');
    textArea.value = this.listLink;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  learnMore() {
    this.util.openUrl('https://help.tidy.com/to-do-list-links');
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Goal } from 'src/models/onboarding.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'tidy-video-tutorial',
  templateUrl: './video-tutorial.html',
  styleUrls: ['./video-tutorial.scss'],
})
export class VideoTutorialComponent implements OnInit {
  goal: Goal;
  videoUrl: SafeResourceUrl;

  constructor(
    private modalController: ModalController,
    private tidyStorage: TidyStorage,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    this.goal = await this.tidyStorage.retrieve('goal');
    console.log(this.goal);
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.goal.videoUrl
    );
    console.log(this.videoUrl);
  }

  scheduleCall() {
    console.log('scheduleCall');
  }

  closeModal() {
    this.modalController.dismiss('video-tutorial-modal');
  }
}

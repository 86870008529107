import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Loading } from 'src/shared/components/loading/loading';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ClientSettingsModel } from 'src/models/client.model';

import validationUtils from 'src/shared/util/validation-utils';
import { HubspotFieldIds, HubspotFormIds } from 'src/shared/enums/hubspot.enum';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'contact-info.html'
})

export class ContactInfoPage implements OnInit {

  form: UntypedFormGroup;
  inputIds: any = HubspotFieldIds;
  formIds: any = HubspotFormIds;
  settings: ClientSettingsModel;
  submitted: boolean;
  showCompanyName: boolean;
  errorMessage: string;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private me: Me,
    private storage: TidyStorage,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      companyName: [''],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      billEmail: ['', [Validators.required, validationUtils.validateEmail]],
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      phone: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Contact Info');
    this.loadData();
  }

  @Loading('', true)
  async loadData() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.loaded = false;
      this.settings = await this.getParam('settings');
      const accountType = localStorage.getItem('accountType');
      const userRole = localStorage.getItem('user_role');
      this.showCompanyName = (accountType == 'rental' || accountType == 'company') && userRole !== 'member';
      if (this.settings === null) {
        this.settings = await this.client.getClientSettings();
      }
      this.form.patchValue({
        firstName: this.settings.profile.first_name,
        lastName: this.settings.profile.last_name,
        email: this.settings.profile.email,
        phone: this.settings.profile.phone !== null ? this.settings.profile.phone : '',
        companyName: this.settings.profile.account_name,
        billEmail: this.settings.invoices.alt_email !== '' && this.settings.invoices.alt_email ? this.settings.invoices.alt_email : this.settings.profile.email
      });
      this.errorMessage = '';
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  @Loading('', true)
  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.firstName.trim().length < 2 || this.form.value.lastName.trim().length < 2) {
      this.form.patchValue({
        firstName: this.form.value.firstName.trim(),
        lastName: this.form.value.lastName.trim()
      });
      return;
    }
    const payload: any = {
      profile: {
        last_name: this.form.value.lastName.trim(),
        first_name: this.form.value.firstName.trim(),
        phone: this.form.value.phone,
        email: this.form.value.email,
        account_name: this.form.value.companyName,
        alt_email: this.form.value.billEmail == '' ? null : this.form.value.billEmail,
      }
    }
    try {
      await this.client.saveClientSettings(payload);
      await this.storage.save('phoneNumber', this.form.value.phone);
      await this.me.load(true);
      const params = this.mountSuccessPageParams();
      const url = 'success';
      const component = SuccessPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Contact Info Saved',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'more'
    };
  }
}

import { Component, HostListener, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { SelectCategoryPage } from '../select-category/select-category';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';
import { Util } from 'src/shared/util/util';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { ConfirmPage } from '../confirm/confirm.component';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { WindowService } from 'src/shared/providers/window.service';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { debounceTime } from 'rxjs/operators';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { AddProPage } from 'src/pages/my-pros/add-pro/add-pro';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';

import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { myProsMockData } from 'src/shared/constants/onboarding/my-pros';

const BREAK_LARGE_RESOLUTION = 880;

@Component({
  templateUrl: 'my-pros.html',
  styleUrls: ['./my-pros.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyProsPage implements OnInit {

  allowsFindNewPros: boolean;
  addressId: number;
  addressFilter: TidySelectNumberValueModel[];
  addressResponse: any;
  allPros: any;
  allProHeaders: any;
  backPage: string;
  categoryId: number;
  readonly desktopWidthContent: string = scrollContentWidth.NORMAL;
  errorMessage: string;
  isRentalClient: boolean;
  isAllAddressesSelected: boolean;
  jobId: number;
  customerId: number;
  panelClosedSubscription: Subscription;
  prosServices: any;
  form: UntypedFormGroup;
  showLoadingSpinner: boolean;
  searchForm: UntypedFormGroup;
  hasPrivatePro: boolean;
  hasPros: boolean;
  prosData: any;
  loaded: boolean;
  editPrio = false;
  priorityListSentence: string;
  showDesktopHamburgerMenu: boolean;
  showExpandedDesktopHamburger: boolean;
  shownPros: any;
  serviceCategoryItems: any;
  isMobileResolution = window.innerWidth <= BREAK_LARGE_RESOLUTION;
  allProsResponse: any;
  categories: any;
  showOnboarding: boolean = false;
  hasProperties: boolean = false;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding: boolean;
  @HostListener("window:resize", ["$event"])
  async onResize(event) {
    this.isMobileResolution = event.target.innerWidth <= BREAK_LARGE_RESOLUTION;
    this.showDesktopHamburgerMenu = event.target.innerWidth < 1010;
  }

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private pros: Pros,
    private util: Util,
    private currentAddress: CurrentAddress,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      pros: [1, Validators.required]
    });
    this.searchForm = this.fb.group({
      search: ['']
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
  }

  async ngOnInit() {
    try {
      await this.checkIfShouldShowOnboarding();
      this.loadPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.getPageData();
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/my-pros').then(async () => {
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('addPros');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async getPageData() {
    this.backPage = localStorage.getItem('myProsBackPage') || 'more';
    this.prosServices = [{
      viewValue: 'Cleaning',
      value: 1
    }];
    await this.getAddresses();
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    this.categories = await this.pros.getServiceCategoriesOrFetch();
    this.serviceCategoryItems = this.getServiceCategoryItems(this.categories);
    this.isAllAddressesSelected = localStorage.getItem('isAllAddressesSelected') == 'true' && !this.isMobileResolution;
    this.hasPrivatePro = await this.pros.checkIfHasPrivatePro();
    this.allPros = await this.getAllPros();
    console.log(this.allPros)
    this.shownPros = this.allPros;
    this.allProHeaders = this.getAllProsHeaders();
    this.showDesktopHamburgerMenu = window.innerWidth < 995;
    this.loaded = true;
  }

  async getProsData() {
    this.categoryId = parseInt(localStorage.getItem('selectedCategoryId')) || 1;
    this.prosData = await this.getPros(this.categoryId);
    this.hasPrivatePro = this.checkIfHasPrivateProsInAddress();
    this.hasPros = this.checkIfHasProsInAddress();
    this.allowsFindNewPros = this.checkIfAllowsFindNewPros();
    this.priorityListSentence = this.getPriorityListSentence();
  }

  checkIfAllowsFindNewPros() {
    const isBlocked = this.prosData.blocked.find((item) => item.object_type == 'dynamic_sa')
    return !isBlocked;
  }

  updateSearch({ search }) {
    if (search.length < 2 || search == '') {
      return this.shownPros = this.allPros;
    }
    const term = search.toLowerCase();
    this.shownPros = this.allPros.filter((pro) => {
      if (pro['Name'].value.toLowerCase().includes(term) || pro['Properties'].searchValue.toLowerCase().includes(term) || pro['Services'].value.toLowerCase().includes(term)) {
        return pro;
      }
    });
  }

  async getAllPros() {
    this.allProsResponse = this.showOnboarding ? myProsMockData : await this.pros.getAllPros();
    console.log(this.allProsResponse)
    let allProsArray = [];
    this.allProsResponse.map((pro) => {
      let addressesViewValue = '';
      let addressesSearchValue = '';
      let services = '';
      pro.address_homekeeper_priorities?.map((item, index) => {
        const address = this.addressResponse.find((address) => address.id == item.address_id);
        let addressName = address?.address_name ? address?.address_name : address?.address1;
        if (this.showOnboarding) {
          addressName = item?.address_name || '159 Morning Star';
        }
        if (!addressesViewValue.includes(addressName)) {
          addressesSearchValue += addressName + ', ';
          if (index < 5) {
            addressesViewValue += addressName + ', ';
          }
        }
        let service = this.categories.find((category) => category.id == item.service_type_id)?.name;
        service = !service ? '' : (service == 'Regular Cleaning' ? new TranslationPipe().transform('Cleaning') : new TranslationPipe().transform(service));
        if (!services.includes(service)) {
          services += new TranslationPipe().transform(service) + ', ';
        }
      });
      addressesSearchValue = addressesSearchValue.slice(0, -2);
      addressesViewValue = addressesViewValue.slice(0, -2);
      services = services.slice(0, -2);

      // Get count of unique properties
      const uniqueAddressIds = new Set(pro.address_homekeeper_priorities.map(p => p.address_id));
      const uniqueAddressCount = uniqueAddressIds.size;

      allProsArray.push({
        'Name': {
          value: pro.name,
          action: null
        },
        'Properties': {
          searchValue: addressesSearchValue,
          value: uniqueAddressCount > 5 ? addressesViewValue + ` + ${uniqueAddressCount - 5} ${new TranslationPipe().transform('more')}` : addressesViewValue,
          action: null
        },
        'Services': {
          value: services,
          action: null
        },
        'Last Job': {
          value: this.getJobString(pro.last_job),
          action: null
        },
        'Next Job': {
          value: this.getJobString(pro.next_job),
          action: null
        },
        'last-column': {
          value: '',
          action: null
        },
        'teamId': pro.main_team_id,
        'proId': pro.id
      });
    });
    return allProsArray;
  }

  getJobString(job) {
    if (!job) {
      return '';
    }
    const date = LuxonDateTime.fromISO(job.date);
    const formattedDate = date.toFormat('M/d/yy');
    let time = LuxonDateTime.fromISO(job.start_time, { setZone: true });
    const formattedTime = time.toFormat('h:mma');
    return formattedDate + ' ' + formattedTime.toLowerCase();
  }

  getAllProsHeaders() {
    return [
      'Name',
      'Properties',
      'Services',
      'Last Job',
      'Next Job',
      'last-column'
    ];
  }

  async getPros(id) {
    let pros = await this.pros.getPros(id);
    if (this.categoryId !== 1) {
      pros = this.removeFindNewProsCard(pros);
    }
    return pros;
  }

  removeFindNewProsCard(pros) {
    const approved: any = [];
    pros.approved.map(pro => {
      if (pro.object_type !== 'dynamic_sa') {
        approved.push(pro);
      }
    });
    pros.approved = approved;
    return pros;
  }

  getServiceCategoryItems(serviceCategories) {
    const array: any = [];
    serviceCategories.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.id
      });
    });
    return array;
  }

  async changeServiceCategory(id) {
    this.categoryId = id;
    localStorage.setItem('selectedCategoryId', id);
    this.prosData = await this.getPros(id);
  }

  async getAddresses() {
    this.addressResponse = await this.client.getAddresses();
    this.addressFilter = await this.client.parseAddressList(this.addressResponse, true);
    this.addressId = await this.client.getSelectedAddressId(this.addressResponse);
  }

  async getDisplayData() {
    this.prosData = await this.getPros(this.form.value.pros);
    this.priorityListSentence = this.getPriorityListSentence();
  }

  @Loading('', true)
  async changeAddress(addressId) {
    if (addressId === 0) {
      return this.navCtrl.navigateForward('add-property');
    }
    this.currentAddress.addressId = addressId;
    await this.getDisplayData();
  }

  checkIfHasProsInAddress(): boolean {
    const allObjects: any = [];
    this.prosData.favorited.map((object) => allObjects.push(object));
    this.prosData.approved.map((object) => allObjects.push(object));
    this.prosData.blocked.map((object) => allObjects.push(object));
    const isTidyFindNewProsAppearing = allObjects.find((object) => object.object_type == 'dynamic_da');
    let numberOfPros = this.prosData.favorited.length + this.prosData.approved.length + this.prosData.blocked.length;
    if (isTidyFindNewProsAppearing) {
      numberOfPros -= 1;
    }
    return numberOfPros > 0 || this.categoryId !== 1;
  }

  checkIfHasPrivateProsInAddress(): boolean {
    let hasPrivatePro = false;
    this.prosData.approved.map((pro) => {
      if (pro?.object?.is_private) {
        hasPrivatePro = true;
      }
    });
    this.prosData.favorited.map((pro) => {
      if (pro?.object?.is_private) {
        hasPrivatePro = true;
      }
    });
    return hasPrivatePro;
  }

  isAbleToBlock(item) {
    return (item?.object_type !== 'dynamic_sa' || this.hasPrivatePro);
  }

  handleNotAbleToBlockPro(): void {
    this.util.showWarning('You must have at least 1 private pro on your list to block Find New Pro.', 7000);
  }

  blockPro() {
    this.modalCtrl.dismiss(true);
  }

  @Loading('', true)
  async updatePriorities() {
    await this.pros.updateProsPriorities(this.prosData, this.form.controls.pros.value);
    this.priorityListSentence = this.getPriorityListSentence();
  }

  goToVendorCompliance() {
    const params = {
      successRoute: 'my-pros'
    }
    this.navCtrl.navigateForward('vendor-compliance', params);
  }

  goToTidyFindNewPro(allowsFindNewPros) {
    const params = {
      allowsFindNewPros: allowsFindNewPros,
      prosData: this.prosData,
      hasPrivatePro: this.hasPrivatePro
    }
    this.navCtrl.navigateForward('tidy-find-new-pro', params);
  }

  goToTidyFindNewProFromOnboarding() {
    const params = {
      cameFromMyProsOnboardingPage: true,
      serviceCategoryItems: this.serviceCategoryItems,
      allowsFindNewPros: true
    }
    this.navCtrl.navigateForward('tidy-find-new-pro', params);
  }

  goToPro(proId) {
    const pro = this.allProsResponse.find((pro) => pro.id === proId);
    let selectedAddressId = localStorage.getItem('addressId');
    let categoryId;
    let address;
    pro.address_homekeeper_priorities.map((priority) => {
      if (priority.address_id == selectedAddressId) {
        address = this.addressResponse.find((address) => address.id == selectedAddressId);
        categoryId = priority.service_type_id;
      }
    });
    if (!address) {
      selectedAddressId = pro.address_homekeeper_priorities[0].address_id;
      localStorage.setItem('addressId', selectedAddressId.toString());
      address = this.addressResponse.find((address) => address.id == selectedAddressId);
      pro.address_homekeeper_priorities.map((priority) => {
        if (priority.address_id == address.id) {
          categoryId = priority.service_type_id;
        }
      });
    }
    pro.address_homekeeper_priorities.map((item) => {
      const serviceName = this.categories.find((category) => category.id == item.service_type_id)?.name;
      if (serviceName == 'Regular Cleaning' && item.address_id == this.addressId) categoryId = item.service_type_id;
    });
    localStorage.setItem('myProBackPage', 'my-pros');
    const params = {
      proId: proId,
      address: address,
      categoryId: categoryId
    };
    this.goToPage(`my-pro/${proId}`, params, MyProPage);
  }

  goToProSettings() {
    let proNames = {};
    let proIds = {};
    this.allPros.map((pro) => {
      proIds[pro.teamId] = pro.proId;
      proNames[pro.teamId] = pro['Name'].value;
    });
    this.storage.save('proNames', proNames);
    this.storage.save('proIds', proIds);
    this.navCtrl.navigateForward('pro-settings');
  }

  addPro() {
    const params = {
      serviceCategoryItems: this.serviceCategoryItems,
      categoryId: this.categoryId
    }
    this.goToPage('add-pro', params, AddProPage);
  }

  goToAddProAllAddresses() {
    const params = {
      serviceCategoryItems: this.serviceCategoryItems,
      noAddressSelected: true
    }
    this.goToPage('add-pro', params, AddProPage);
  }

  goToFindNewPro() {
    const params = {
      address: this.addressResponse.find(ad => ad.id === this.addressId),
      isFindingPro: true
    }
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(SelectCategoryPage);
    } else {
      this.navCtrl.navigateForward('select-category', params);
    }
  }

  learnMoreAddPros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  learnMorePros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  @Loading('', true)
  async refresh() {
    this.prosData = await this.getPros(this.form.value.pros);
  }

  getPriorityListSentence() {
    const address = this.addressResponse.find(ad => ad.id === this.addressId);
    const addressName = address.address_name ? address.address_name : address.address1;
    const priorityListSentence = this.pros.updatePriorityListSentence(this.prosData);
    return 'For <u>' + addressName + '</u> ' + priorityListSentence;
  }

  async removeProFromList(pro) {
    const params = {
      title: new TranslationPipe().transform('Remove') + ' ' + pro?.object?.first_name + ' ' + new TranslationPipe().transform('From List?'),
      body: 'The pro will be removed from the list for this address for this service type. You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Remove from List',
      confirmAction: this.confirmRemoveProFromList.bind(this, pro),
      confirmButtonClass: 'secondary',
      blockAction: this.confirmBlockPro.bind(this, pro),
    }
    const modal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await modal.present();
  }

  async confirmRemoveProFromList(pro) {
    try {
      const payload = {
        service_type_id: this.form.value.pros,
        homekeeper_ids: [pro?.object?.id]
      }
      await this.pros.removeProFromAddressList(this.addressId, payload);
      this.prosData = await this.getPros(this.categoryId);
      this.hasPros = this.checkIfHasProsInAddress();
      this.hasPrivatePro = this.checkIfHasPrivateProsInAddress();
      this.priorityListSentence = this.pros.updatePriorityListSentence(this.prosData);
      this.modalCtrl.dismiss(true);
      this.util.showSuccess('Pro removed from list');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async confirmBlockPro() {
    const approvedIndex = this.prosData.approved.findIndex(pro => pro.object_type == 'dynamic_sa');
    const favoritedIndex = this.prosData.favorited.findIndex(pro => pro.object_type == 'dynamic_sa');
    let removedElement: any[];
    if (approvedIndex >= 0) {
      removedElement = this.prosData.approved.splice(approvedIndex, 1);
    }
    if (favoritedIndex >= 0) {
      removedElement = this.prosData.favorited.splice(favoritedIndex, 1);
    }
    if (removedElement) {
      const indexToAdd = 0;
      this.prosData.blocked.splice(indexToAdd, 0, ...removedElement);
    }
    try {
      const serviceTypeId = 1;
      await this.pros.updateProsPriorities(this.prosData, serviceTypeId);
      this.prosData = await this.getPros(this.categoryId);
      this.hasPros = this.checkIfHasProsInAddress();
      this.hasPrivatePro = this.checkIfHasPrivateProsInAddress();
      this.priorityListSentence = this.pros.updatePriorityListSentence(this.prosData);
      this.modalCtrl.dismiss(true);
      this.util.showSuccess('Pro removed from list');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  toggleDesktopHamburger() {
    this.showExpandedDesktopHamburger = !this.showExpandedDesktopHamburger;
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didAddPro = await this.storage.retrieve('didAddPro');
        if (didAddPro) {
          await this.storage.save('didAddPro', false);
          this.getPageData();
        }
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  onTableColumnClick(event: {column: string, element: any}) {
    if (!event.element[event.column].action) {
      this.goToPro(event.element.proId);
    }
  }

}

import { UntypedFormGroup } from '@angular/forms';

export function validateEqual(field: string, confirmField: string) {
  return (group: UntypedFormGroup): {[key: string]: any} => {
    const password = group.controls[field];
    const confirmPassword = group.controls[confirmField];

    if (password.value !== confirmPassword.value) {
      return {
        mismatchedPasswords: true
      };
    }
  }
}

import { Component, OnInit } from '@angular/core';

import { Logs } from 'src/providers/logs/logs';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'logs.html'
})

export class LogsPage implements OnInit {

  logHistory: any[];
  errorMessage: string;
  loaded: boolean;
  isRightSideContent: boolean;

  constructor(
    private logs: Logs,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.errorMessage = '';
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.rightSidePanelService.setDialogPageTitle('Logs');
    }
    try {
      this.logHistory = await this.logs.getLogHistory();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
    this.loaded = true;
  }

  goToLogDetails(logObject: any) {
    const params = {
      id: logObject.id,
      timestamp: logObject.created_at,
      request: logObject.request,
      response: logObject.response,
    };
    this.rightSidePanelService.navigateTo('log-details', params);
  }

}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class RoutingState {

  private history = [];

  constructor(
    private router: Router
  ) {}

  public loadRouting(): void {
    this.router.events
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history = [...this.history, event.urlAfterRedirects];
      }
    });
  }

  public getHistory() {
    return this.history;
  }

  public canGoBack(): boolean {
    return (this.history.length > 1);
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }
}

import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Dashboard } from 'src/providers/dashboard/dashboard';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WindowService } from 'src/shared/providers/window.service';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { AddReportPage } from 'src/pages/dashboard/add-report/add-report';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { JobPage } from 'src/pages/schedule/job/job';
import { ReportSettingsPage } from 'src/pages/dashboard/report-settings/report-settings';

@Component({
  selector: 'reports',
  templateUrl: './reports.html',
  styleUrls: ['./reports.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportsPage implements OnDestroy {

  addressId: any;
  dateRangeForm: UntypedFormGroup;
  dateField: any;
  dateFilter: any;
  errorMessage: string;
  headers: any;
  loaded: boolean;
  nudgeCards: any;
  rows: any;
  reports: any;
  report: any;
  reportId: string;
  reportFilterItems: TidySelectNumberValueModel[];
  showReport: boolean = true;
  standbyCount: number = 0;
  prevSub: Subscription;
  panelClosedSubscription: Subscription;
  readonly desktopWidthContent: string = scrollContentWidth.NORMAL;
  shownRowsCount: number = 50;
  currentRows: any[] = [];

  constructor(
    private dashboard: Dashboard,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    fb: UntypedFormBuilder,
    private router: Router,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private schedule: Schedule,
    public windowService: WindowService
  ) {
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: '',
      type: ''
    });

    this.prevSub = this.router.events.pipe(debounceTime(100))
    .subscribe(async event => {
      if (event instanceof NavigationEnd && event.url.includes('/reports')) {
        this.reports = (await this.dashboard.getDashboardSectionItems())
          .filter(report => report.name !== "Overview");
        const reportId = this.route.snapshot.paramMap.get('reportId') || localStorage.getItem('selectedReport');
        if (reportId) {
          this.reportId = reportId;
        } else {
          this.reportId = this.reports[0].id.toString();
        }
        localStorage.setItem('selectedReport', this.reportId.toString())
        this.getPageData();
      }
    });
  }

  ngOnDestroy() {
    this.prevSub?.unsubscribe()
    this.panelClosedSubscription?.unsubscribe();
  }

  @Loading('', true)
  async getPageData() {
    this.loaded = false;
    try {
      this.reports = (await this.dashboard.getDashboardSectionItems())
        .filter(report => report.name !== "Overview");

      this.report = await this.dashboard.getDashboardReportData(this.reportId);
      this.reportFilterItems = await this.getReportFilterItems();
      await this.loadTable();
      const nudgeCards = await this.dashboard.getNudgeCards();
      this.nudgeCards = await this.getNudgeCards(nudgeCards);
      await this.patchDateFilter();
      this.dateRangeForm.patchValue({type: this.report.id});
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getReportFilterItems() {
    const array: any = [];
    this.reports.map((report) => {
      array.push({
        viewValue: report.name,
        value: report.id
      });
    });
    if (!this.windowService.isDesktopRes) {
      array.push({
        viewValue: 'Add Report',
        value: -1
      });
    }
    return array;
  }

  async changeReportFilter(reportId) {
    this.shownRowsCount = 50;
    if (reportId == -1) {
      this.goToAddReport();
      return;
    }
    this.loaded = false;
    this.reportId = reportId;
    await this.getPageData();
    this.loaded = true;
  }

  goToAddReport() {
    this.goToPage('dashboard/add-report', {}, AddReportPage);
  }

  loadTable() {
    this.rows = this.buildRows();
    this.currentRows = this.rows.slice(0, this.shownRowsCount);
    this.headers = [];
    this.report.settings.fields.filter((field) => {
      if (field.is_field_visible) {
        this.headers.push(field.field_name);
      }
      if (field.field_key == this.report.settings.default_date_key) {
        this.dateField = field;
      }
    });
    this.headers.push('last-column');
    if (this.dateField) {
      this.dateFilter = this.report.settings.filters.find((filter) => filter.dashboard_report_type_field_id == this.dateField.dashboard_report_type_field_id );
    }
  }

  buildRows() {
    const indexOfAddressIdField = this.report.settings.fields.findIndex(field => {
      return field.field_key === 'address.id';
    });
    const indexOfJobIdField = this.report.settings.fields.findIndex(field => {
      return field.field_key === 'jobs.id';
    });

    return this.report.data.rows.map((row) => {
      const object: any = {};
      row.map((item, index) => {
        if (this.report.settings.fields[index].is_field_visible) {
          const fieldKey = this.report.settings.fields[index].field_key;
          object[this.report.settings.fields[index].field_name] = {
            value: item,
            action: this.actionCheck(fieldKey, indexOfAddressIdField, indexOfJobIdField, row)
          };
        }
      });
      object['last-column'] = {
        value: '',
        action: null
      };
      return object;
    });
  }

  actionCheck(fieldKey, indexOfAddressId, indexOfJobIdField, row) {
    if (fieldKey === 'address.address_1' && indexOfAddressId !== -1 && row[indexOfAddressId]) {
      return this.goToAddress.bind(this, row[indexOfAddressId]);
    } else if (fieldKey == 'jobs.date' && indexOfJobIdField !== -1 && row[indexOfJobIdField]) {
      if (this.report.name == 'Scheduled Jobs') {
        return this.goToScheduledJob.bind(this, row[indexOfAddressId], row[indexOfJobIdField]);
      } else if (this.report.name == 'Completed Jobs') {
        return this.goToPastJob.bind(this, row[indexOfAddressId], row[indexOfJobIdField]);
      }
    }
    return null;
  }

  goToAddress(addressId) {
    this.navCtrl.navigateForward(['edit-property', addressId]);
  }

  goToScheduledJob(addressId, jobId) {
    const params = {
      jobId: jobId,
      addressId: addressId
    }
    this.goToPage(`job/${addressId}/${jobId}`, params, JobPage);
  }

  async goToPastJob(addressId, jobId) {
    const job = await this.schedule.getJobDetail(jobId);
    const params = {
      jobId: jobId,
      job: job.id
    }
    if (job?.is_private) {
      this.goToPage(`past-private-job/${jobId}`, params, PastPrivateJobPage);
    } else {
      this.goToPage(`past-job/${jobId}`, params, PastJobPage);
    }
  }

  titleCase(name) {
    return name.split(' ')
       .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
       .join(' ');
   }

  getStandbyImage() {
    if (this.standbyCount % 3 == 0) {
      return 'assets/img/car-up-left.svg';
    } else if (this.standbyCount % 2 == 0) {
      return 'assets/img/car-right.svg';
    } else {
      return 'assets/img/car-down.svg';
    }
  }

  patchDateFilter() {
    let hasDateFilter = false;
    this.report.settings.filters.map((filter) => {
      if (filter.dashboard_report_type_field_id == this.dateField.dashboard_report_type_field_id) {
        hasDateFilter = true;
        const isCustomRangeFilter = this.dateFilter?.value?.includes('from');
        const isPastOrFutureFilter = this.dateFilter.value == '{today}' && (this.dateFilter.operator == '<=' || this.dateFilter.operator == '>=');
        if (isCustomRangeFilter) {
          const dates = this.dateFilter.value.split('"');
          this.dateRangeForm.patchValue({start: new Date(dates[3]), end: new Date(dates[7])});
        } else if (isPastOrFutureFilter) {
          const string = this.dateFilter.operator == '<=' ? 'past' : 'future';
          this.dateRangeForm.patchValue({customStringSelect: string});
        } else {
          const array = filter.value.split('{');
          const arrayTwo = array[1].split('}');
          this.dateRangeForm.patchValue({customStringSelect: arrayTwo[0]});
        }
      }
    });
    if (!hasDateFilter) {
      this.dateRangeForm.patchValue({customStringSelect: 'all_time'});
    }
  }

  @Loading('', true)
  async saveCustomDateFilter(option) {
    this.shownRowsCount = 50;
    try {
      if (option.start && !option.end) {
        return;
      }
      let payload: any = {};
      payload.filters = [
        {
          dashboard_customer_report_field_id: this.dateField.id,
          operator: 'between',
          value: `{\"from\": \"${this.formatDate(option.start, 'YYYY/MM/DD')}\", \"to\": \"${this.formatDate(option.end, 'YYYY/MM/DD')}\"}`
        }
      ];
      payload = this.addCurrentFiltersToPayload(payload);
      await this.dashboard.updateDashboardReportFilters(payload, this.report.id);
      await this.getPageData();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async saveStringDateFilter(option) {
    this.shownRowsCount = 50;
    try {
      let payload: any = {};
      if (option == 'all_time') {
        payload = {
          filters: []
        };
        await this.dashboard.updateDashboardReportFilters(payload, this.report.id);
      } else if (option == 'past' || option == 'future') {
        payload.filters = [
          {
            dashboard_customer_report_field_id: this.dateField.id,
            operator: option == 'past' ? '<=' : '>=',
            value: '{today}'
          }
        ];
      } else {
        let operator = 'between';
        if (option == 'today' || option == 'yesterday' || option == 'tomorrow') {
          operator = 'equals';
        }
        payload.filters = [
          {
            dashboard_customer_report_field_id: this.dateField.id,
            operator: operator,
            value: `{${option}}`
          }
        ];
      }
      payload = this.addCurrentFiltersToPayload(payload);
      await this.dashboard.updateDashboardReportFilters(payload, this.report.id);
      await this.getPageData();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  addCurrentFiltersToPayload(payload) {
    this.report.settings.filters.map((filter) =>  {
      if (filter.dashboard_report_type_field_id !== this.dateField.dashboard_report_type_field_id) {
        payload.filters.push({
          dashboard_customer_report_field_id: filter.field_id,
          operator: filter.operator,
          value: filter.value
        });
      }
    });
    return payload;
  }

  goToReportSettings() {
    localStorage.setItem('cameFromReportAdded', 'false');
    const params = {
      report: this.report
    }
    this.goToPage(`dashboard/report-settings/${this.report.id}`, params, ReportSettingsPage);
  }

  @Loading('Loading...')
  async sortChanged(sort) {
    try {
      const sortField = this.report?.settings?.fields.find(field => {
        return field.field_name === sort.active;
      });
      const data = {
        sort_by_field_keys: [{
          field_key: sortField.field_key,
          sort: sort.direction
        }]
      };
      await this.dashboard.updateDashboardReport(data, this.report.id);
      this.report = await this.dashboard.getDashboardReportData(this.reportId);
      await this.loadTable();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

  setShowReport() {
    this.showReport = true;
  }

  goToEditAddressPage() {
    const addressId = this.report.data.rows[0][18];
    this.navCtrl.navigateForward(`edit-property/${addressId}`);
  }

  async getNudgeCards(nudgeCards) {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const editAddressDestination = this.report.data.rows.length ? `edit-property/${this.report.data.rows[0][18]}` : 'properties';
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const cards = {
      schedule: {
        header: 'Schedule',
        body: 'Schedule a cleaning with a certified Pro in 1 minute.',
        icon: 'assets/img/book-job.svg',
        iconWidth: '25px',
        destination: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list',
        showCard: true
      },
      to_do_list: {
        header: 'Create a To-Do List',
        body: 'Create a digital To-Do list to share with any Pro. (Free)',
        icon: 'assets/img/to-dos.svg',
        iconWidth: '30px',
        destination: 'to-dos',
        showCard: true
      },
      existing_pros: {
        header: 'Add Existing Pro',
        body: 'Add an existing Pro you work with to automate requests with them. (Free)',
        icon: 'assets/img/user-red.svg',
        iconWidth: '25px',
        destination: isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows',
        showCard: true
      },
      map: {
        header: 'Get an Animated Map',
        body: 'Get a free map of your property.',
        icon: 'assets/img/house-sketch.svg',
        iconWidth: '25px',
        destination: editAddressDestination,
        showCard: true
      },
      customer_members: {
        header: 'Users',
        body: 'Add a user so they can log into your account with their own credentials. As the team leader, you can revoke access at any time.',
        icon: 'assets/img/team.svg',
        iconWidth: '25px',
        destination: 'users',
        showCard: true
      }
    };
    const maintenance = {
      header: 'Get a Maintenance Plan',
      body: 'It\'s free! Just scan your home to get a personalized maintenance plan.',
      icon: 'assets/img/tool-box.svg',
      iconWidth: '25px',
      destination: editAddressDestination,
      showCard: true
    };
    const integrations = {
      header: 'Integrate',
      body: 'Connect Airbnb & other channels to automatically book turnovers. (Free)',
      icon: 'assets/img/integrate.svg',
      iconWidth: '40px',
      destination: 'integrations',
      showCard: true
    };
    const array: any = [];
    const isJobReport = this.report.settings.default_date_key == 'jobs.date';
    const hasNoJobItems = !this.report.data.rows.length;
    if (isJobReport && hasNoJobItems) {
      const schedule = {
        header: 'Schedule',
        body: 'Schedule a cleaning with a certified Pro in 1 minute.',
        icon: 'assets/img/book-job.svg',
        iconWidth: '25px',
        destination: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list',
        showCard: true
      };
      array.push(schedule);
      return array;
    }
    const isMaintenanceReport = this.report.settings.default_date_key == 'maintenances.date';
    const hasNoMaintenanceItems = !this.report.data.rows.length;
    const backendSaysShowMaintenance = nudgeCards.includes('maintenance');
    if (isMaintenanceReport && hasNoMaintenanceItems && backendSaysShowMaintenance) {
      array.push(maintenance);
      return array;
    }
    const isReservationReport = this.report.settings.default_date_key == 'guest_reservation.check_in_date';
    const hasNoReservationItems = !this.report.data.rows.length;
    const backendSaysShowIntegration = nudgeCards.includes('integrations');
    if (isReservationReport && hasNoReservationItems && backendSaysShowIntegration) {
      array.push(integrations);
      return array;
    }
    return [];
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);

        this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
          this.getPageData();
        });
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }

  pageChange() {
    this.shownRowsCount += 50;
    this.currentRows = this.rows.slice(0, this.shownRowsCount);
  }

}

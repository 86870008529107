<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent && !windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Job Request Workflows'"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-desktop-header *ngIf="windowService.isDesktopRes && !isRightSideContent">
        <tidy-text
          [title]="'My Pros'">
        </tidy-text>
        <ng-container *ngIf="loaded">
          <form [formGroup]="form" novalidate action="#" class="bills-form-filters form-filters">
            <div class="desktop-item">
              <tidy-select
                class="select-custom-input"
                [label]="'Property'"
                [items]="addressFilter"
                [form]="form.get('property')"
                formControlName="property"
                (optionChange)="changeAddress($event)">
              </tidy-select>
            </div>
            <div class="desktop-item">
              <tidy-select
                class="select-custom-input"
                [label]="'Service Type'"
                [items]="serviceCategoryItems"
                [form]="form.get('service')"
                formControlName="service"
                (optionChange)="changeServiceCategory($event)">
              </tidy-select>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="!loaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 198px;
              height: 40px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
      </tidy-desktop-header>

      <ng-container *ngIf="hasPros">
        <tidy-row *ngIf="!windowService.isDesktopRes || isRightSideContent">
          <tidy-filter
            [initValue]="addressId"
            [items]="addressFilter"
            [fitContent]="true"
            (optionChange)="changeAddress($event)">
          </tidy-filter>
        </tidy-row>

        <tidy-row *ngIf="!windowService.isDesktopRes || isRightSideContent">
          <tidy-filter
            [initValue]="categoryId"
            [items]="serviceCategoryItems"
            [fitContent]="true"
            (optionChange)="changeServiceCategory($event)">
          </tidy-filter>
        </tidy-row>

        <tidy-row>
          <tidy-text
            [body]="priorityListSentence"
            style="margin-right:5px">
          </tidy-text>
          <tidy-text
            [body]="'Add Pro'"
            (action)="addPro()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-text
              [header]="'Priority List'">
            </tidy-text>
            <tidy-text
              [body]="'Edit'">
            </tidy-text>
          </tidy-row>
          <tidy-toggle [toggled]="editPrio" (toggleChanged)="editPrio = !editPrio" class="extra-left-margin"></tidy-toggle>
        </tidy-row>

        <tidy-drag-drop
          dragDropId="approved-dd"
          [connectedDragDropsIds]="['favorites-dd', 'blocked-dd']"
          [dataList]="prosData?.favorited"
          emptyListColor="green"
          emptyListMessage="No Pros Added"
          emptyDisabledListMessage="No Pros Added"
          [disabled]="!editPrio"
          (listDropped)="updatePriorities()">
          <ng-container *ngFor="let item of prosData?.favorited; let i = index; let last = last">
            <ng-container *tidyDragDropItem>
              <tidy-card *ngIf="!editPrio">
                <ng-container *ngIf="!item?.object_type">
                  <ng-container *ngFor="let pro of item; let proIndex = index">
                    <tidy-card-button (action)="goToPro(pro?.object?.id)" *ngIf="pro?.object_type === 'homekeeper'">
                      <tidy-row>
                        <tidy-text
                          [header]="pro?.object?.name | abbrLastName"
                          [translate]="false">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          *ngIf="pro?.ignore_homekeeper_availability"
                          [helper]="'(auto assign)'">
                        </tidy-text>
                      </tidy-row>
                    </tidy-card-button>
                    <tidy-card-button [customIcon]="'assets/img/settings.svg'" [hideArrow]="true" (action)="goToTidyFindNewPro(true)" *ngIf="pro?.object_type === 'dynamic_sa'">
                      <tidy-row class="vertical-align-center">
                        <tidy-image
                          [src]="'assets/img/search.svg'"
                          class="header-size">
                        </tidy-image>
                        <tidy-text
                          [header]="'Find New Pro'">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row *ngIf="pro?.object?.gender_preference !== 'any'" class="extra-top-padding">
                        <tidy-text
                          [body]="(pro?.object?.gender_preference | titlecase) + ' Only'">
                        </tidy-text><br>
                        <tidy-text
                          [helper]="'Warning: this setting decreases the number of Pros in your area.'">
                        </tidy-text>
                      </tidy-row>
                    </tidy-card-button>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="item?.object_type">
                  <tidy-card-button (action)="goToPro(item?.object?.id)" *ngIf="item?.object_type === 'homekeeper'">
                    <tidy-row>
                      <tidy-text
                        [header]="item?.object?.name | abbrLastName"
                        [translate]="false">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        *ngIf="item?.ignore_homekeeper_availability"
                        [helper]="'(auto assign)'">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                  <tidy-card-button [customIcon]="'assets/img/settings.svg'" [hideArrow]="true" class="arrow-to-top" (action)="goToTidyFindNewPro(true)" *ngIf="item?.object_type === 'dynamic_sa'">
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/img/search.svg'"
                        class="header-size">
                      </tidy-image>
                      <tidy-text
                        [header]="'Find New Pro'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="item?.object?.gender_preference !== 'any'" class="extra-top-padding">
                      <tidy-text
                        [body]="(item?.object?.gender_preference | titlecase) + ' Only'">
                      </tidy-text><br>
                      <tidy-text
                        [helper]="'Warning: this setting decreases the number of Pros in your area.'">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </ng-container>
              </tidy-card>

              <tidy-card *ngIf="editPrio" [class.highlight]="item?.highlight">
                <tidy-card-button [hideArrow]="true">
                  <ng-container *ngIf="!item?.object_type">
                    <ng-container *ngFor="let pro of item; let proIndex = index">
                      <tidy-row [class.next-row]="proIndex > 0">
                        <div class="flex-container">
                          <div>
                            <tidy-image
                              *ngIf="proIndex === 0"
                              class="header-size swap-merged"
                              [src]="'assets/svg/menu-tidy-green.svg'">
                            </tidy-image>
                            <tidy-text
                              *ngIf="pro?.object_type === 'dynamic_sa'"
                              [header]="'Find New Pro'">
                            </tidy-text>
                            <tidy-text
                              *ngIf="pro?.object_type !== 'dynamic_sa'"
                              [header]="pro?.object?.name | abbrLastName"
                              [translate]="false">
                            </tidy-text>
                          </div>
                          <div>
                            <tidy-image
                              *ngIf="pro?.object_type !== 'dynamic_sa'"
                              style="
                                float: right;
                                height: 20px;
                                cursor: pointer;
                                right: -6px;
                                position: relative;
                              "
                              [src]="'assets/svg/trash-black.svg'"
                              (action)="removeProFromList(pro)">
                            </tidy-image>
                            <tidy-image
                              style="
                                height: 20px;
                                cursor: pointer;
                              "
                              [matTooltip]="'Unmerge' | translate"
                              [src]="'assets/icon/expand.svg'"
                              (action)="unmergePro(i, proIndex, 'favorited')">
                            </tidy-image>
                          </div>
                        </div>
                      </tidy-row>
                      <tidy-row class="no-padding" *ngIf="pro?.object_type !== 'dynamic_sa'">
                        <tidy-checkbox
                          [initValue]="pro?.ignore_homekeeper_availability"
                          [items]="[{viewValue: 'Auto Assign', value: true}]"
                          (checkedChange)="updateAutoAssign(pro)">
                        </tidy-checkbox>
                      </tidy-row>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="item?.object_type">
                    <tidy-row [alignLastItemRight]="item?.object_type !== 'dynamic_sa'" [ngClass]="{'vertical-align-center': item?.object_type === 'dynamic_sa'}">
                      <tidy-image
                        class="header-size"
                        [src]="'assets/svg/menu-tidy-green.svg'">
                      </tidy-image>
                      <tidy-text
                        *ngIf="item?.object_type === 'dynamic_sa'"
                        [header]="'Find New Pro'">
                      </tidy-text>
                      <tidy-text
                        *ngIf="item?.object_type !== 'dynamic_sa'"
                        [header]="item?.object?.name | abbrLastName"
                        [translate]="false">
                      </tidy-text>
                      <div *ngIf="item?.object_type !== 'dynamic_sa'">
                        <tidy-image
                          style="float: right; height: 20px; cursor: pointer"
                          [src]="'assets/svg/trash-black.svg'"
                          (action)="removeProFromList(item)">
                        </tidy-image>
                      </div>
                    </tidy-row>
                    <tidy-row class="no-padding" *ngIf="item?.object_type !== 'dynamic_sa'">
                      <tidy-checkbox
                        [initValue]="item?.ignore_homekeeper_availability"
                        [items]="[{viewValue: 'Auto Assign', value: true}]"
                        (checkedChange)="updateAutoAssign(item)">
                      </tidy-checkbox>
                    </tidy-row>
                  </ng-container>
                </tidy-card-button>
              </tidy-card>
              <ng-container *ngIf="editPrio && !last">
                <div
                  class="transparent-div"
                  (mouseenter)="highlightCards(i, i + 1, 'favorited')"
                  (mouseleave)="unhighlightCards(i, i + 1, 'favorited') ">
                  <img
                    (click)="mergePros(i, i + 1, 'favorited')"
                    src="assets/icon/compress-green.svg"
                    class="hover-icon"
                    [matTooltip]="'Merge' | translate"/>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </tidy-drag-drop>

        <tidy-row>
          <tidy-text
            [header]="'Settings'">
          </tidy-text>
        </tidy-row>

        <!--<tidy-row *ngIf="!editPrio">
          <tidy-text
            [body]="'We\'ll start sending your job request to pros no earlier than 30 days before the job\'s start time.'">
          </tidy-text>
        </tidy-row>-->

        <tidy-row *ngIf="!editPrio">
          <tidy-text
            [body]="('We\'ll notify everyone on your list at least ' | translate) + form?.value?.timeCutoff + ((' hours before the job starts or ' | translate) + form?.value?.percentCutoff + ('% of the time between your request time and the job start time, whichever is first.' | translate))">
          </tidy-text>
        </tidy-row>

        <form [formGroup]="form" novalidate action="#" *ngIf="editPrio">
          <tidy-row>
            <div class="desktop-item">
              <tidy-select
                class="select-custom-input"
                [label]="'How much time before the job should we notify all pros?'"
                [items]="timeCutoffItems"
                [form]="form.get('timeCutoff')"
                formControlName="timeCutoff"
                (optionChange)="updateTimeCutoff($event)">
              </tidy-select>
            </div>
          </tidy-row>
          <tidy-row>
            <div class="desktop-item">
              <tidy-select
                class="select-custom-input"
                [label]="'What % of the time before the job should we notify all pros?'"
                [items]="percentCutoffItems"
                [form]="form.get('percentCutoff')"
                formControlName="percentCutoff"
                (optionChange)="updatePercentCutoff($event)">
              </tidy-select>
            </div>
          </tidy-row>
        </form>

        <!--<tidy-drag-drop
          dragDropId="favorites-dd"
          [connectedDragDropsIds]="['approved-dd', 'blocked-dd']"
          [dataList]="prosData?.favorited"
          emptyListColor="green"
          emptyListMessage="Drag to add to favorites list."
          emptyDisabledListMessage="No Pros Added"
          [disabled]="!editPrio"
          (listDropped)="updatePriorities()">
          <ng-container *ngFor="let priority of workflow">
            <ng-container *tidyDragDropItem>
              <tidy-card *ngIf="!editPrio">
                <ng-container *ngFor="let pro of priority?.pros; let last = last">
                  <tidy-row [alignLastItemRight]="true" [ngClass]="{'no-padding': last, 'extra-bottom-padding': !last}">
                    <tidy-text
                      [header]="pro?.object?.name ? (pro?.object?.name || abbrLastName) : 'Find New Pros'"
                      [translate]="false">
                    </tidy-text>
                    <tidy-helper
                      [body]="pro?.object?.last_job_date ? ('Last Job ' + (pro?.object?.last_job_date | parseDate: 'M/D')) : ''">
                    </tidy-helper>
                  </tidy-row>
                </ng-container>
              </tidy-card>
              <tidy-card *ngIf="editPrio">
                <ng-container *ngFor="let pro of priority?.pros; let last = last">
                  <tidy-row [alignLastItemRight]="true" [ngClass]="{'no-padding': last, 'extra-bottom-padding': !last}">
                    <tidy-image
                      class="header-size"
                      [src]="'assets/svg/menu-tidy-green.svg'">
                    </tidy-image>
                    <tidy-text
                      [header]="pro?.object?.name ? (pro?.object?.name || abbrLastName) : 'Find New Pros'"
                      [translate]="false">
                    </tidy-text>
                    <tidy-helper
                      [body]="pro?.object?.last_job_date ? ('Last Job ' + (pro?.object?.last_job_date | parseDate: 'M/D')) : ''">
                    </tidy-helper>
                    <div>
                      <tidy-image
                        *ngIf="pro?.object_type !== 'dynamic_sa'"
                        style="
                          float: right;
                          height: 20px;
                          cursor: pointer;
                          right: -6px;
                          position: relative;
                        "
                        [src]="'assets/svg/trash-black.svg'"
                        (action)="removeProFromList(pro)">
                      </tidy-image>
                      <tidy-image
                        style="
                          height: 20px;
                          cursor: pointer;
                        "
                        [matTooltip]="'Unmerge'"
                        [src]="'assets/icon/expand.svg'"
                        (action)="unmergePro(i, proIndex, 'approved')">
                      </tidy-image>
                    </div>
                  </tidy-row>
                </ng-container>
              </tidy-card>
              
              <tidy-card *ngIf="!editPrio">
                <tidy-card-button (action)="goToPro(item?.object?.id)" *ngIf="item?.object_type === 'homekeeper'">
                  <tidy-row
                    class="vertical-align-center"
                    [alignLastItemRight]="true">
                    <tidy-image
                      [src]="'assets/img/star-pro.svg'"
                      class="title-size">
                    </tidy-image>
                    <tidy-text
                      [header]="item?.object?.name | abbrLastName"
                      [translate]="false">
                    </tidy-text>
                    <tidy-text
                      [body]="item?.object?.last_job ? 'Last Job ' + (item?.object?.last_job | parseDate: 'M/D') : ''">
                    </tidy-text>
                  </tidy-row>
                </tidy-card-button>
                <tidy-card-button [customIcon]="'assets/img/settings.svg'" [hideArrow]="true" class="arrow-to-top" (action)="goToTidyFindNewPro(true)" *ngIf="item?.object_type === 'dynamic_sa'">
                  <tidy-row class="vertical-align-center">
                    <tidy-image
                      [src]="'assets/img/search.svg'"
                      class="header-size">
                    </tidy-image>
                    <tidy-text
                      [header]="'Find New Pro'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="item?.object?.gender_preference !== 'any'" class="extra-top-padding">
                    <tidy-text
                      [body]="(item?.object?.gender_preference | titlecase) + ' Only'">
                    </tidy-text><br>
                    <tidy-text
                      [helper]="'Warning: this setting decreases the number of Pros in your area.'">
                    </tidy-text>
                  </tidy-row>
                </tidy-card-button>
              </tidy-card>
              <tidy-card *ngIf="editPrio">
                <tidy-card-button [hideArrow]="true">
                  <tidy-row [alignLastItemRight]="item?.object_type !== 'dynamic_sa'">
                    <tidy-image
                      class="header-size"
                      [src]="'assets/svg/menu-tidy-green.svg'">
                    </tidy-image>
                    <tidy-text
                      *ngIf="item?.object_type === 'dynamic_sa'"
                      [header]="'Find New Pro'">
                    </tidy-text>
                    <tidy-text
                      *ngIf="item?.object_type !== 'dynamic_sa'"
                      [header]="item?.object?.name | abbrLastName"
                      [translate]="false">
                    </tidy-text>
                    <div *ngIf="item?.object_type !== 'dynamic_sa'">
                      <tidy-image
                        style="float: right; height: 20px; cursor: pointer"
                        [src]="'assets/svg/trash-black.svg'"
                        (action)="removeProFromList(item)">
                      </tidy-image>
                    </div>
                  </tidy-row>
                </tidy-card-button>
              </tidy-card>
            </ng-container>
          </ng-container>
        </tidy-drag-drop>-->

      <tidy-row *ngIf="!allowsFindNewPros">
        <tidy-text
          [body]="'Find New Pros is disabled '">
        </tidy-text>
        <tidy-text
          *ngIf="editPrio"
          [body]="'Edit'"
          class="link"
          (action)="goToTidyFindNewPro(false)">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="!hasPros">
        <tidy-row [align]="'center'" *ngIf="!windowService.isDesktopRes">
          <tidy-text
            [title]="'Prioritize Pros'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Once you have had a Pro, this section lets you control who can do your jobs and in what order.'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-image
            [src]="'assets/img/calendar-down.svg'"
            style="width:70px">
          </tidy-image>
        </tidy-row>

        <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/add-pro.svg'"
              class="title-size">
            </tidy-image>
            <div style="width:140px"><ion-skeleton-text></ion-skeleton-text></div>
            <div>
            <tidy-image
              [src]="'assets/img/block.svg'"
              class="title-size">
            </tidy-image>
          </div>
          </tidy-row>
        </tidy-card>
        <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/add-pro.svg'"
              class="title-size">
            </tidy-image>
            <div style="width:140px"><ion-skeleton-text></ion-skeleton-text></div>
            <div>
            <tidy-image
              [src]="'assets/img/block.svg'"
              class="title-size">
            </tidy-image>
          </div>
          </tidy-row>
        </tidy-card>
        <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/add-pro.svg'"
              class="title-size">
            </tidy-image>
            <div style="width:140px"><ion-skeleton-text></ion-skeleton-text></div>
            <div>
              <tidy-image
                [src]="'assets/img/checkmark-circle-blue.svg'"
                class="title-size">
              </tidy-image>
            </div>
          </tidy-row>
        </tidy-card>
        <tidy-card style="width:70%; margin-left: auto; margin-right: auto; padding: 10px">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/add-pro.svg'"
              class="title-size">
            </tidy-image>
            <div style="width:140px"><ion-skeleton-text></ion-skeleton-text></div>
          </tidy-row>
        </tidy-card>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [body]="'Add a pro to send them jobs. It\'s easy and free for them. '">
          </tidy-text>
          <tidy-text
            [body]="'Learn More'"
            (action)="learnMoreAddPros()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-button
          [text]="'Add Pro'"
          [action]="addPro.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Settings for Finding New Pros'"
            (action)="goToTidyFindNewProFromOnboarding()"
            class="link">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </ng-container>

  <ng-container *ngIf="!loaded">
    <ion-skeleton-text animated style="margin-left: -20px; width: 100%; position: absolute; height: 100%; top: 0; bottom: 0"></ion-skeleton-text>
  </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [isfullWidthContent]="windowService.isDesktopRes" [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

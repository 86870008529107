<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Maintenance'"
    [customBack]="'more'"
    [canGoBack]="!windowService.isDesktopRes"
    [showLinkIcon]="rows?.length"
    [linkIcon]="'add-circle'"
    (linkAction)="goToAddRoomItemPage()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-desktop-header *ngIf="windowService?.isDesktopRes">
      <tidy-text
        [title]="'Maintenance'">
      </tidy-text>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 310px;
            height: 27px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
      <ng-container *ngIf="loaded">
        <form [formGroup]="form" novalidate action="#">
          <tidy-search-input
            formControlName="search"
            [placeholder]="'Search'"
            [icon]="'assets/svg/search-outline.svg'">
          </tidy-search-input>
        </form>
        <div class="header-button primary" (click)="goToAddRoomItemPage()">
          <tidy-image
            [src]="'assets/img/add-button-white.svg'"
            style="height: 12px;"
            (action)="goToAddRoomItemPage()">
          </tidy-image>
          <tidy-text
            [headerButton]="'Add Item'"
            class="white">
          </tidy-text>
        </div>
      </ng-container>
      <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
    </tidy-desktop-header>


    <!--<ng-container *ngIf="loaded">
      <div style="display: flex; align-items: center;">
        <tidy-text
          [pageTitle]="'Inventory'">
        </tidy-text>
        <div style="display: flex; background: #E4E6E9; border: 0.5px solid #D5D5D5; border-radius: 4px; padding: 5px 10px; margin-left: 15px; cursor: pointer;">
          <tidy-image
            [src]="'assets/img/inventory-list.svg'">
          </tidy-image>
          <div style="font-family: 'Inter',Arial,sans-serif; font-weight: 300; font-size: 14px; color: #000;">
            All ({{rows?.length}})
          </div>
        </div>
        <div style="display: flex; border: 0.5px solid #D5D5D5; border-radius: 4px; padding: 5px 10px; margin-left: 10px; cursor: pointer;">
          <tidy-image
            [src]="'assets/svg/alert-circle-outline.svg'">
          </tidy-image>
          <div style="font-family: 'Inter',Arial,sans-serif; font-weight: 300; font-size: 14px; color: #000;">
            Low Stock (0)
          </div>
        </div>
        <tidy-image
          [src]="'assets/svg/add-outline.svg'"
          style="margin-left: 10px; width: 20px; height: 20px; cursor: pointer;"
          (action)="goToAddRoomItemPage()">
        </tidy-image>
      </div>
      <tidy-divider style="margin-left: -20px; margin-right: -20px; margin-top: 10px; margin-bottom: 10px;"></tidy-divider>
      <div style="display: flex; align-items: center;">
        <tidy-image
          [src]="'assets/svg/filter-outline.svg'"
          style="margin-right: 10px; width: 18px; cursor: pointer;">
        </tidy-image>
        <div style="font-family: 'Inter',Arial,sans-serif; font-weight: 300; font-size: 14px; color: #000;">
          Filter
        </div>
      </div>
      <tidy-divider style="margin-left: -20px; margin-right: -20px; margin-top: 10px; margin-bottom: 10px;"></tidy-divider>
    </ng-container>-->


    <!--<tidy-desktop-header *ngIf="windowService?.isDesktopRes">

      <ng-container *ngIf="loaded">

        <div class="desktop-nav-search">
          <form [formGroup]="form" novalidate action="#">
            <tidy-input
              #searchInput
              class="search-input"
              [icon]="'assets/svg/search-outline.svg'"
              [activeIcon]="'assets/svg/search-tidy-green.svg'"
              [noBorder]="true"
              type="search"
              formControlName="search"
              [label]="'Search'"
              [form]="form">
            </tidy-input>
          </form>
        </div>
        <form [formGroup]="propertyForm" novalidate action="#" class="form-filters">
          <tidy-select
            style="width: 200px"
            class="select-custom-input"
            [items]="addressItems"
            [form]="propertyForm.get('property')"
            formControlName="property"
            (optionChange)="changeProperty()">
          </tidy-select>
        </form>
        <tidy-image
          [src]="'assets/svg/add-outline.svg'"
          style="width: 20px; height: 20px; cursor: pointer;"
          (action)="goToAddRoomItemPage()">
        </tidy-image>
      </ng-container>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 320px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
      <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
    </tidy-desktop-header>-->

    <div class="inventory-header-mobile-padding" *ngIf="!windowService?.isDesktopRes">
      <div *ngIf="rows?.length">
        <form [formGroup]="form" novalidate action="#">
          <tidy-search-input
            formControlName="search"
            [placeholder]="'Search'"
            [icon]="'assets/svg/search-outline.svg'">
          </tidy-search-input>
        </form>
      </div>
    </div>

    <ng-container *ngIf="loaded">
      <ng-container  *ngIf="rows?.length">
        <tidy-card class="no-padding">
          <tidy-table
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            (sortChange)="sortChanged($event)"
            [showColumnLines]="false"
            [rowsToShow]="50"
            [allRowIsClickable]="true"
            (cellClick)="onTableColumnClick($event)"
            [infiniteScroll]="true"
            [totalRows]="filteredRows?.length > 0 ? filteredRows?.length : rows?.length"
            (pageChange)="pageChange($event)">
          </tidy-table>
        </tidy-card>
        <!--<tidy-row style="margin-left: -10px; margin-right: -10px;">
          <tidy-table
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            (sortChange)="sortChanged($event)"
            [showColumnLines]="false"
            [rowsToShow]="100">
          </tidy-table>
        </tidy-row>-->
      </ng-container>
      <tidy-alert *ngIf="currentRows?.length == 0 || rows?.length ==  0">
        <tidy-row>
          <tidy-text
            [body]="'No Results'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card style="padding: 0px; height: 600px; padding: 0px;">
        <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'addMaintenanceItems'">
</tidy-onboarding-modal>

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { DateTime as LuxonDateTime } from 'luxon';

@Injectable()
export class Card {
  constructor(private httpClient: HttpClientCustom) {}

  async cardSaveCounter() {
    let cardsLastTwoMinutes =
      parseInt(localStorage.getItem('cardTriesPerMinute')) || 0;
    let cardsLastDay = parseInt(localStorage.getItem('cardTriesPerDay')) || 0;
    if (this.enoughTimeHasPassed()) {
      localStorage.removeItem('cardTriesPerMinute');
      localStorage.removeItem('cardTriesPerDay');
      localStorage.removeItem('cardTriesPerMinuteLastDate');
      localStorage.removeItem('cardTriesPerDayLastDate');
      return { limit: false, errorCode: null };
    } else if (cardsLastTwoMinutes >= 2) {
      return { limit: true, errorCode: 1 };
    } else if (cardsLastDay >= 10) {
      return { limit: true, errorCode: 2 };
    } else {
      cardsLastTwoMinutes += 1;
      cardsLastDay += 1;
      localStorage.setItem(
        'cardTriesPerMinute',
        cardsLastTwoMinutes.toString()
      );
      localStorage.setItem('cardTriesPerDay', cardsLastDay.toString());
      if (cardsLastTwoMinutes == 1) {
        const now = LuxonDateTime.local();
        localStorage.setItem('cardTriesPerMinuteLastDate', now.toISO());
      }
      if (cardsLastDay == 1) {
        const now = LuxonDateTime.local();
        localStorage.setItem('cardTriesPerDayLastDate', now.toISO());
      }
      return { limit: false, errorCode: null };
    }
  }

  enoughTimeHasPassed() {
    const cardTriesPerMinuteLastDate = localStorage.getItem(
      'cardTriesPerMinuteLastDate'
    );
    const cardTriesPerDayLastDate = localStorage.getItem(
      'cardTriesPerDayLastDate'
    );
    let now: any = LuxonDateTime.local();
    now = now.toISO();
    let enoughTimeHasPassed = false;
    if (cardTriesPerMinuteLastDate) {
      const start = LuxonDateTime.fromISO(cardTriesPerMinuteLastDate);
      const end = LuxonDateTime.fromISO(now);
      const diffInSeconds = end.diff(start).as('seconds');
      enoughTimeHasPassed = diffInSeconds > 120;
    } else if (cardTriesPerDayLastDate) {
      const start = LuxonDateTime.fromISO(cardTriesPerDayLastDate);
      const end = LuxonDateTime.fromISO(now);
      const diffInSeconds = end.diff(start).as('seconds');
      enoughTimeHasPassed = diffInSeconds > 86400;
    }
    return enoughTimeHasPassed;
  }

  async saveCard(data) {
    const url = 'api/v1/customer/payment-methods';
    return await new Promise(async (resolve, reject) => {
      const cardController = await this.cardSaveCounter();
      if (cardController.limit) {
        cardController.errorCode === 1
          ? reject(
              new Error(
                "Please wait a few minutes to try a new credit card. If you're having issues please log out and back in."
              )
            )
          : reject(
              new Error(
                "You have tried too many cards today. Please try again tomorrow. If you're having issues please log out and back in."
              )
            );
      } else {
        resolve(this.httpClient.post(url, data));
      }
    });
  }

  async hasValidCard() {
    const url = 'api/v1/customer/payment-methods';
    const cards = await this.httpClient.get(url);
    let hasCard = false;
    cards.map((card) => {
      if (card?.metadata?.last4 && card?.type === 'card') {
        hasCard = true;
      }
    });
    localStorage.setItem('hasCard', hasCard ? 'true' : 'false');
    return hasCard;
  }

  async getCreditCards() {
    const paymentMethods = await this.httpClient.get('api/v1/customer/payment-methods?includes=account_teams');
    return paymentMethods.filter((paymentMethod) => paymentMethod.type === 'card');
  }

  async getCreditCardsForTeams(teamIds: number[]) {
    const paymentMethods = await this.httpClient.get(`api/v1/customer/payment-methods?filters[account_team_ids]=${teamIds.join(',')}`);
    return paymentMethods.filter((paymentMethod) => paymentMethod.type === 'card');
  }
}

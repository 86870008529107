<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Add Custom Field'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngIf="loaded">
      <form [formGroup]="form" novalidate action="#">
        <ng-container
          *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-card>
          <tidy-row >
            <tidy-input
              formControlName="field_name"
              [form]="form.controls.field_name"
              label="Field Name"
              [errorMessage]="'Please enter a valid field name'">
            </tidy-input>
          </tidy-row>

          <tidy-row>
            <tidy-select
              [label]="'Field Type'"
              [items]="fieldTypes"
              [form]="form.get('field_type')"
              formControlName="field_type">
            </tidy-select>
          </tidy-row>

          <tidy-row>
            <tidy-select
              [label]="'Visibility'"
              [items]="visibilityTypes"
              [form]="form.get('visibility')"
              formControlName="visibility">
            </tidy-select>
          </tidy-row>

          <tidy-row>
            <tidy-input
              formControlName="default_value"
              [form]="form.controls.default_value"
              label="Fallback Value"
              [errorMessage]="'Please enter a valid fallback value'">
            </tidy-input>
          </tidy-row>
        </tidy-card>

        <tidy-button
          [text]="'Add Custom Field'"
          class="primary"
          [action]="addCustomField.bind(this)">
        </tidy-button>
      </form>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template #content>
  <tidy-wrapper>

    <tidy-desktop-header>
      <tidy-text
        [title]="'To-Do Lists'">
      </tidy-text>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 540px;
            height: 27px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
      <ng-container *ngIf="loaded">
        <!--<form [formGroup]="searchForm" novalidate action="#">
          <tidy-search-input
            formControlName="search"
            [placeholder]="'Search'"
            [icon]="'assets/svg/search-outline.svg'">
          </tidy-search-input>
        </form>-->
        <div class="header-button" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{closeDisabled: true}">
          <tidy-image
            [src]="'assets/img/filter-white.svg'"
            style="height: 12px;">
          </tidy-image>
          <tidy-text
            [headerButton]="'Filter'"
            class="white">
          </tidy-text>
        </div>
        <div class="header-button" (click)="goToDefaultSettingsPage()">
          <tidy-image
            [src]="'assets/img/settings-white.svg'"
            style="height: 12px;">
          </tidy-image>
          <tidy-text
            [headerButton]="'Defaults'"
            class="white">
          </tidy-text>
        </div>
        <div class="header-button primary" (click)="goToAddToDoList()">
          <tidy-image
            [src]="'assets/img/add-button-white.svg'"
            style="height: 12px;">
          </tidy-image>
          <tidy-text
            [headerButton]="'Add To-Do List'"
            class="white">
          </tidy-text>
        </div>
      </ng-container>
      <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
    </tidy-desktop-header>

    <ng-container *ngFor="let chip of chips">
      <ion-chip class="filter-chip">
        <ion-label>{{ chip.displayParentName | titlecase | translate}}: {{ chip.name | titlecase | translate }}</ion-label>
        <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
      </ion-chip>
    </ng-container>
    <mat-menu #menu="matMenu" class="filter-menu" *ngIf="!showOnboarding && !showAddInspectionsOnboarding">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
            <mat-checkbox
              class="checkbox"
              [checked]="getNodeChecked(node)"
              (click)="$event.stopPropagation()"
              (change)="checkNode(node, $event)">
              {{ node.name | titlecase | translate }}
            </mat-checkbox>
          </button>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding>
          <button
            class="node-button-padding"
            mat-menu-item
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
            {{ node.name | titlecase | translate }}
          </button>
        </mat-tree-node>
      </mat-tree>
    </mat-menu>

    <ng-container *ngIf="!loaded">
      <tidy-card style="padding: 0px; height: 600px; padding: 0px;">
        <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
      </tidy-card>
    </ng-container>

    <div *ngIf="loaded">
      <tidy-card [style.display]="currentRows?.length > 0 ? 'block' : 'none'" class="no-padding">
        <tidy-table
          #tidyTable
          [dataSource]="currentRows"
          [displayedColumns]="headers"
          [columnslabels]="headers"
          [allRowIsClickable]="true"
          [rowsToShow]="25"
          [showColumnLines]="false"
          (sortChange)="sortChanged($event)"
          (cellClick)="goToToDoList($event)"
          (pageChange)="pageChange($event)"
          [infiniteScroll]="true"
          [totalRows]="totalRows">
        </tidy-table>
      </tidy-card>
    </div>

    <ng-container *ngIf="currentRows?.length === 0 && !isSearching">
      <tidy-alert>
        <tidy-text
          [body]="'No To-Do Lists Created'">
        </tidy-text>
      </tidy-alert>
      <to-dos-onboarding
        [buttonsDisabled]="showOnboarding || showAddInspectionsOnboarding"
        [addressResponse]="addressResponse"
        [addressId]="form?.value?.property == 'all' ? addressId : form?.value?.property"
        [isNormalBookingFlow]="false">
      </to-dos-onboarding>
    </ng-container>

    <tidy-alert *ngIf="currentRows?.length == 0 && isSearching">
      <tidy-row>
        <tidy-text
          [body]="'No results'">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content">
</app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="onboardingFlow">
</tidy-onboarding-modal>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Job Note'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>

    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Use a job note to convey general notes for this job to your pro. You can use a job note instead of To-Dos or to supplement them.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row class="field-bottom-padding">
        <form [formGroup]="form" novalidate action="#">
          <tidy-input
            formControlName="textNote"
            [form]="form.controls.textNote"
            label="Job Note"
            [icon]="'assets/svg/information-circle-outline.svg'">
          </tidy-input>
        </form>
      </tidy-row>
    </tidy-card>

    <!--

    <tidy-card *ngFor="let image of images; let index = indedx">
      <tidy-row>
        <tidy-image
          [src]="image.url">
        </tidy-image>
      </tidy-row>
      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [actionBody]="'Delete'"
          (action)="deleteImage(index)"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-card *ngFor="let video of videos; let index = indedx">
      <tidy-row>
        <tidy-image
          [src]="video.media_url">
        </tidy-image>
      </tidy-row>
      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [actionBody]="'Delete'"
          (action)="deleteVideo(index)"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-row>
      <tidy-text
        [actionBody]="'Add Image'"
        (action)="addImage()"
        class="link">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [actionBody]="'Add Video'"
        (action)="addVideo()"
        class="link">
      </tidy-text>
    </tidy-row>

    -->

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Save Note'"
      class="primary"
      [action]="saveBookingNote.bind(this)">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

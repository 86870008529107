// Angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';

// Ionic
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// pipes
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { ParseDatePipe } from './pipes/parse-date.pipe';
import { CustomTimePipe } from './pipes/custom-time';
import { FrequencyFormatPipe } from './pipes/plans-frequency-format.pipe';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { TelephonePipe } from './pipes/telephone.pipe';
import { TypePlanFormatPipe } from './pipes/plan-type-format.pipe';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { FormatStatePipe } from './pipes/format-state.pipe';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe'
import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { TidyNumberToWeekDayPipe } from './pipes/tidy-number-to-weekday.pipe'
import { ShortNamePipe } from './pipes/short-name.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TranslationPipe } from './pipes/translation.pipe';


// components
import { TasksFilterComponent } from 'src/pages/tasks/tasks-filter/tasks-filter';
import { ScheduleWeeklyFilterComponent } from 'src/pages/schedule/schedule-weekly-filter/schedule-weekly-filter';
import { ScheduleWeeklyViewComponent } from 'src/pages/schedule/schedule-weekly-view/schedule-weekly-view';
import { ChipTextArea } from './components/chip-text-area/chip-text-area';
import { AccountTypeSelection } from './components/account-type-selector/account-type-selection';
import { AutocompleteAddressComponent } from 'src/shared/components/autocomplete-address/autocomplete-address';
import { CleaningTypePlan } from './components/cleaning-type-plan/cleaning-type-plan';
import { NavBarDesktop } from './components/nav-bar-desktop/nav-bar-desktop';
import { PastHomekeeper } from './components/past-homekeeper/past-homekeeper';
import { TodosMenu } from './components/todos-menu/todos-menu';
import { TeamTreeComponent } from './components/tree-component/tree.component';
import { VideoScanner } from 'src/pages/more/edit-address/property-mapping/scanner-component/scanner-component'
import { FeedbackComponent } from 'src/pages/feedback/feedback.component'
import { SubscriptionModalComponent } from './components/subscription-modal/subscription-modal'

import { TidyCardDirective } from './theme-components/tidy-card/tidy-card.directive';
import { TidyCardHeader } from './theme-components/tidy-card/tidy-card-header.component';
import { TidySelectSmall } from './theme-components/tidy-select-small/tidy-select-small';
import { TidyCardList } from './theme-components/tidy-card/tidy-card-list.component';
import { TidyCardTitleDirective } from './theme-components/tidy-card/tidy-card-title.directive';
import { TidyLinkDirective } from './theme-components/tidy-link/tidy-link.directive';
import { TidyCardItem } from './theme-components/tidy-card/tidy-card-item.component';
import { TidyLabelDirective } from './theme-components/tidy-label/tidy-label.directive';

import { TidyCardInput } from './theme-components/tidy-card-input/tidy-card-input.component';
import { TidyCardSelect } from './theme-components/tidy-card-select/tidy-card-select.component';
import { TidyCardIndicator } from './theme-components/tidy-card-indicator/tidy-card-indicator.component';

import { TidyMenuAddressSelectorComponent } from './theme-components/tidy-menu/tidy-menu-address-selector/tidy-menu-address-selector.component';
import { TidyMenuComponent } from './theme-components/tidy-menu/tidy-menu';
import { TidyMenuHeader } from './theme-components/tidy-menu/tidy-menu-header/tidy-menu-header.component';
import { TidyMenuTopButtonsComponent } from './theme-components/tidy-menu/tidy-menu-top-buttons/tidy-menu-top-buttons.component';
import { TidyMenuBottomButtonsComponent } from './theme-components/tidy-menu/tidy-menu-bottom-buttons/tidy-menu-bottom-buttons.component';
import { TidyBigIconComponent } from './theme-components/tidy-big-icon/tidy-big-icon.component';
import { TidyMissingField } from './theme-components/tidy-menu/tidy-missing-field/tidy-missing-field';

import { ToDosOnboardingComponent } from 'src/shared/components/to-dos-onboarding/to-dos-onboarding';

import { ParkingReimbursementComponent } from 'src/shared/components/parking-reimbursement/parking-reimbursement.component';
import { PlanFrequencyMessage } from './components/plan-frequency-message/plan-frequency-message';
import { PhotoNote } from './components/photo-note/photo-note';
import { PhotoCaption } from 'src/shared/components/photo-caption/photo-caption.component';

import { TidyActiveButtonComponent } from './components/tidy-active-button/tidy-active-button';

import { TranslateOfficePipe } from './pipes/translate-office-pipe';
import { ReplaceEnterToBlurDirective } from './directives/replace-enter-to-blur';
import { CustomTextareaDirective } from './directives/custom-textarea';
import { ChangeSquareClassDirective } from './directives/change-square-class';
import { GoToNextDirective } from './directives/go-to-next';
import { GenomeItemsComponent } from 'src/shared/components/genome-items/genome-items.component';

import { FrequencyRequestTimesComponent } from './components/craf/frequency-request-times/frequency-request-times.component';
import { ListRequestTimesComponent } from './components/craf/list-request-times/list-request-times.component';
import { RequestTimesSectionComponent } from './components/craf/request-times-section/request-times-section.component';
import { MaintenancesCardsComponent } from 'src/shared/components/maintenances-cards/maintenances-cards.component'
import { MessageComponent } from 'src/shared/components/message/message.component'
import { DesktopHeaderComponent } from 'src/shared/components/desktop-header/desktop-header';
import { NotificationHistoryComponent } from 'src/shared/components/notification-history/notification-history.component'

import { DesktopNavComponent } from 'src/shared/components/desktop-nav/desktop-nav.component';
import { TidyNavbar } from './components/tidy-navbar/tidy-navbar';
import { TopNavComponent } from 'src/shared/components/top-nav/top-nav.component';

import { FilterNestedFormComponent } from 'src/pages/workflows/filter-nested-form/filter-nested-form';

// Services
import { GetCleaningsService } from './services/craf/get-cleanings.service';

// pages
import { BottomNavComponent } from 'src/shared/components/bottom-nav/bottom-nav-component';
import { SuccessPage } from 'src/shared/pages/success/success';

// providers
import { CustomNavParams } from './providers/custom-nav-params/custom-nav-params';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RoutingState } from 'src/shared/providers/providers/routing-state';
import { TidyUiComponentsModule } from 'src/tidy-ui-components/tidy-ui-components.module';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CameraPreviewComponent } from './components/camera-preview/camera-preview.component';
import { DynamicComponentProvider } from './providers/dynamic-component/dynamic-component';
import { MapPopup } from 'src/pages/dashboard/map-popup/map-popup';
import { RemoveEmployeeComponent } from 'src/pages/employer/edit-employee/confirm-remove-employee/confirm-remove-employee';
import { AddActionModal } from 'src/pages/workflows/add-action-modal/add-action-modal';
import { DropDownMenu } from 'src/pages/workflows/drop-down-menu/drop-down-menu';
import { OutsideClickDirective } from './directives/outside-click';
import { DropDownMenuItem } from 'src/pages/workflows/drop-down-menu/drop-down-menu-item/drop-down-menu-item';
import { SelectOptionsPipe } from './pipes/select-options.pipe';
import { TidyDateFormatPipe } from './pipes/tidy-date.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UrlFileNamePipe } from './pipes/url-file-name.pipe';
import { removeUnderscorePipe } from './pipes/remove-underscore.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { RecurringTasksFilter } from 'src/pages/tasks/recurring-tasks/recurring-tasks-filter/recurring-tasks-filter';
import { DialogContentComponent } from './components/dynamic-dialog-container/dynamic-dialog-container';
import { ConciergeFilterComponent } from 'src/pages/concierge/concierge-filter/concierge-filter';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { ScheduleMonthlyViewComponent } from 'src/pages/schedule/schedule-monthly-view/schedule-monthly-view';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeaderComponent } from './components/header/header';
import { PaymentMethodCardComponent } from 'src/pages/more/payment-methods/payment-method-card/payment-method-card';

//Onboarding Goals Modal:
import { OnboardingComponent } from './components/onboarding/onboarding';
import { GoalTasksCardComponent } from './components/onboarding/goal-tasks-card/goal-tasks-card';
import { AdvancedSetupComponent } from './components/onboarding/advanced-setup/advanced-setup';
import { VideoTutorialComponent } from './components/onboarding/video-tutorial/video-tutorial';
import { PickYourGoalsComponent } from './components/pick-your-goals/pick-your-goals';
import { PickGoalCardComponent } from './components/pick-your-goals/pick-goal-card/pick-goal-card';

//Onboarding Page Modal:
import { OnboardingModalComponent } from './components/onboarding/onboarding-modal.component';

//Onboarding Modal Pages:
import { RestockingOnboardingComponent } from './components/onboarding/restocking/restocking-onboarding.component';
import { PropertyOnboardingComponent } from './components/onboarding/property/property-onboarding.component';
import { DirectIntegrationOnboardingComponent } from './components/onboarding/direct-integration/direct-integration-onboarding.component';
import { AddDirectIntegrationOnboardingComponent } from './components/onboarding/add-direct-integration/add-direct-integration-onboarding.component';
import { ImportCSVOnboardingComponent } from './components/onboarding/import-csv/import-csv-onboarding.component';
import { AddProsOnboardingComponent } from './components/onboarding/add-pros/add-pros.component';
import { UploadProsOnboardingComponent } from './components/onboarding/upload-pros/upload-pros.component';
import { SelectIntegrationOnboarding } from './components/onboarding/select-integration-onboarding/select-integration-onboarding';
import { EnableAutomaticBookingOnboarding } from './components/onboarding/enable-automatic-booking-onboarding/enable-automatic-booking-onboarding';
import { CreateConciergeActionOnboarding } from './components/onboarding/create-concierge-action-onboarding/create-concierge-action-onboarding';
import { SelectAccessNotesOptionOnboarding } from './components/onboarding/select-access-notes-option-onboarding/select-access-notes-option-onboarding';
import { InputAccessNotesOnboarding } from './components/onboarding/input-access-notes-onboarding/input-access-notes-onboarding';
import { SelectFindNewProsOptionOnboarding } from './components/onboarding/select-find-new-pros-option-onboarding/select-find-new-pros-option-onboarding';
import { SelectRestockingOptionOnboardingComponent } from './components/select-restocking-option/select-restocking-option.component';
import { SelectMaintenanceOptionOnboarding } from './components/onboarding/select-maintenance-option-onboarding/select-maintenance-option-onboarding';
import { AddMaintenanceItemsOnboarding } from './components/onboarding/add-maintenance-items-onboarding/add-maintenance-items-onboarding';
import { EnableGuestSmartNumberOnboarding } from './components/onboarding/enable-guest-smart-number-onboarding/enable-guest-smart-number-onboarding';
import { AutomateWorkflowsOnboarding } from './components/onboarding/automate-workflows-onboarding/automate-workflows-onboarding';
import { EnableConciergeMessagingOnboarding } from './components/onboarding/enable-concierge-messaging-onboarding/enable-concierge-messaging-onboarding';
import { CreateConciergeRulesOnboarding } from './components/onboarding/create-concierge-rules-onboarding/create-concierge-rules-onboarding';
import { ScheduleOnboardingComponent } from './components/onboarding/schedule/schedule.component';
import { TasksOnboardingComponent } from './components/onboarding/tasks/tasks.component';
import { ToDosModalOnboardingComponent } from './components/onboarding/to-dos/to-dos.component';
import { BillsOnboardingComponent } from './components/onboarding/bills/bills.component';
import { AddIntegrationFormComponent } from './components/add-integration-form/add-integration-form.component';
import { AutomaticBookingFormComponent } from './components/automatic-booking-form/automatic-booking-form.component';
import { IntegrateUpsellProviderComponent } from './components/integrate-upsell-provider/integrate-upsell-provider.component';
import { AddInspectionsOnboardingComponent } from './components/onboarding/add-inspections-onboarding/add-inspections-onboarding';
import { PopularListModalOnboardingComponent } from './components/onboarding/to-dos/popular-list/popular-list.component';

const files = [
  AddInspectionsOnboardingComponent,
  IntegrateUpsellProviderComponent,
  SelectRestockingOptionOnboardingComponent,
  BillsOnboardingComponent,
  ToDosModalOnboardingComponent,
  TasksOnboardingComponent,
  ScheduleOnboardingComponent,
  UploadProsOnboardingComponent,
  AddProsOnboardingComponent,
  AutomaticBookingFormComponent,
  ConciergeFilterComponent,
  TasksFilterComponent,
  TimeAgoPipe,
  DialogContentComponent,
  OnboardingModalComponent,
  ScheduleWeeklyFilterComponent,
  ScheduleWeeklyViewComponent,
  ChipTextArea,
  AccountTypeSelection,
  TeamTreeComponent,
  AutocompleteAddressComponent,
  BottomNavComponent,
  CameraPreviewComponent,
  ChangeSquareClassDirective,
  CleaningTypePlan,
  CustomDatePipe,
  CustomTextareaDirective,
  OutsideClickDirective,
  CustomTimePipe,
  DesktopNavComponent,
  DurationPipe,
  VideoScanner,
  FirstNamePipe,
  ShortNamePipe,
  CapitalizePipe,
  FeedbackComponent,
  SubscriptionModalComponent,
  FormatStatePipe,
  FrequencyFormatPipe,
  TruncatePipe,
  FrequencyRequestTimesComponent,
  GoToNextDirective,
  GenomeItemsComponent,
  ListRequestTimesComponent,
  MessageComponent,
  DesktopHeaderComponent,
  NotificationHistoryComponent,
  NavBarDesktop,
  ParkingReimbursementComponent,
  ParseDatePipe,
  PastHomekeeper,
  PhotoCaption,
  PhotoNote,
  PlanFrequencyMessage,
  RemoveEmployeeComponent,
  RemoveSpacesPipe,
  removeUnderscorePipe,
  ReplaceEnterToBlurDirective,
  RequestTimesSectionComponent,
  SelectOptionsPipe,
  SuccessPage,
  TelephonePipe,
  TidyAbbreviateLastName,
  TidyActiveButtonComponent,
  TidyBigIconComponent,
  TidyCardDirective,
  TidyCardHeader,
  TidyCardIndicator,
  TidyCardInput,
  TidyCardItem,
  TidyCardList,
  TidyCardSelect,
  TidyCardTitleDirective,
  TidyCurrencyPipe,
  TidyLabelDirective,
  TidyLinkDirective,
  TidyMenuAddressSelectorComponent,
  TidyMenuBottomButtonsComponent,
  TidyMenuComponent,
  TidyMenuHeader,
  TidyMenuTopButtonsComponent,
  TidyMissingField,
  TidyNavbar,
  TidyNumberToWeekDayPipe,
  TranslationPipe,
  TidySelectSmall,
  TodosMenu,
  TopNavComponent,
  TranslateOfficePipe,
  TypePlanFormatPipe,
  ToDosOnboardingComponent,
  UrlFileNamePipe,
  MapPopup,
  MaintenancesCardsComponent,
  AddActionModal,
  DropDownMenu,
  DropDownMenuItem,
  TidyDateFormatPipe,
  RecurringTasksFilter,
  FilterNestedFormComponent,
  ScheduleMonthlyViewComponent,
  HeaderComponent,
  PaymentMethodCardComponent,
  RestockingOnboardingComponent,
  PropertyOnboardingComponent,
  DirectIntegrationOnboardingComponent,
  AddDirectIntegrationOnboardingComponent,
  ImportCSVOnboardingComponent,
  OnboardingComponent,
  GoalTasksCardComponent,
  AdvancedSetupComponent,
  VideoTutorialComponent,
  PickYourGoalsComponent,
  PickGoalCardComponent,
  SelectIntegrationOnboarding,
  EnableAutomaticBookingOnboarding,
  CreateConciergeActionOnboarding,
  SelectAccessNotesOptionOnboarding,
  InputAccessNotesOnboarding,
  SelectFindNewProsOptionOnboarding,
  SelectMaintenanceOptionOnboarding,
  AddMaintenanceItemsOnboarding,
  EnableGuestSmartNumberOnboarding,
  AutomateWorkflowsOnboarding,
  EnableConciergeMessagingOnboarding,
  CreateConciergeRulesOnboarding,
  AddIntegrationFormComponent,
  PopularListModalOnboardingComponent
];

@NgModule({
  imports: [
    MatTooltipModule,
    CommonModule,
    CdkStepperModule,
    MatStepperModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatIconModule,
    IonicModule,
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    ReactiveFormsModule,
    TidyUiComponentsModule,
  ],
  declarations: files,
  exports: [
    ...files,
    TidyUiComponentsModule
  ],
  bootstrap: [TeamTreeComponent],
  providers: [
    CustomNavController,
    CustomNavParams,
    FrequencyFormatPipe,
    GetCleaningsService,
    RoutingState,
    TelephonePipe,
    TidyStorage,
    CustomDatePipe,
    TypePlanFormatPipe,
    TranslationPipe,
    DynamicComponentProvider,
    TidyCurrencyPipe,
    TruncatePipe,
    TidyDateFormatPipe,
    JobMediasProvider,
    MediaCapture,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { UserService } from 'src/providers/user/user';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddNewUserPage } from './add-new-user/add-new-user';
import { UserPage } from './user/user';
import { EditUserDetailsPage } from 'src/pages/users/edit-user-details/edit-user-details'

@Component({
  templateUrl: 'users.html'
})

export class UsersPage extends TimeoutableComponent implements OnInit {

  allUsersList: any;
  clientInfo: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isEmployerPortal: boolean;
  showBackButton: boolean;
  usersList: any;
  isRightSideContent = true;
  panelClosedSubscription: Subscription;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private userService: UserService,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  @Loading('', true)
  async ngOnInit() {
    this.isEmployerPortal = this.route.snapshot.data.isEmployerPortal;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.errorMessage = '';
    this.loaded = false;
    await this.getData();
    this.loaded = true;
  }

  async getData() {
    try {
      const userRole = localStorage.getItem('user_role');
      this.rightSidePanelService.setDialogPageTitle(this.isEmployerPortal ? 'Admins' : 'Users');
      if (userRole == 'member') {
        return this.navCtrl.navigateForward('dashboard');
      }
      this.allUsersList = await this.userService.getUsersList();
      this.usersList = this.allUsersList;
      this.clientInfo = await this.client.getClientInfo();
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToAddNewUserPage() {
    const url = this.isEmployerPortal ? 'employer-add-new-admin/employer-add-new-admin' : 'add-new-user';
    const params = {
      isEmployerPortal: this.isEmployerPortal
    }
    if (this.isEmployerPortal) {
      this.openRightSidePanel(AddNewUserPage, params);
    } else {
      this.rightSidePanelService.navigateTo(url, params, AddNewUserPage);
    }
  }

  async goToUserPage(user) {
    await this.storage.save('userId', user.id);
    const url = this.isEmployerPortal ? 'employer-edit-admin/employer-edit-admin' : `user/${user.id}`;
    const params = {
      member: user,
      usersList: this.usersList
    }
    if (this.isEmployerPortal) {
      this.openRightSidePanel(EditUserDetailsPage, params);
    } else {
      this.rightSidePanelService.navigateTo(url, params, UserPage);
    }
  }

  openRightSidePanel(component, params = null) {
    this.storage.save('dialog-right-side-open', true);
    this.storage.save('dialog-params', params);
    this.rightSidePanelService.openRightSidePanel(component);
    this.panelClosedSubscription?.unsubscribe();
    this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
      this.getData();
    });
  }

  getUserType(role) {
    if (role == 'owner') {
      return 'Owner'
    } else if (role == 'admin') {
      return 'Admin'
    } else {
      return 'Team Manager'
    }
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.usersList = this.allUsersList;
    }
    const term = search.toLowerCase();
    this.usersList = this.allUsersList.filter((option) => {
      const name = option.first_name + ' ' + option.last_name;
      if (name.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
  }

  getUserAddresses(user) {
    const array: any = [];
    user.team_addresses_permissions.map((team) => {
      team.addresses.map((address) => {
        const addressName = address?.address_name || (address?.address1 + (address?.address2 ? (' ' + address?.address2) : '') + ', ' + address?.add_state);
        if (!array.includes(addressName)) {
          array.push(addressName);
        }
      });
    });
    return array;
  }

}

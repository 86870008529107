import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'load-credit.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['load-credit.scss'],
})
export class LoadCreditPage implements OnInit {
  
  form: FormGroup;
  isRightSideContent = true;
  dialogParams: any;
  paymentMethodsItems: any[] = [];
  creditHistory: any[] = [];
  loaded = false;

  constructor(
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private navCtrl: CustomNavController,
    private paymentMethodsProvider: PaymentMethodsProvider,
    private util: Util,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.initializeForm();
    this.setDialogTitle();
    await this.checkRightSideContent();
    await this.loadPaymentMethods();
    /* await this.loadCreditHistory(); */
    const paymentMethod = await this.getParam('paymentMethod');
    console.log(paymentMethod)
    console.log(this.paymentMethodsItems)
    this.form.patchValue({
      paymentMethod: paymentMethod ? paymentMethod.id : this.paymentMethodsItems[0].value
    });
    this.loaded = true;
  }

  private initializeForm(): void {
    this.form = new FormGroup({
      price: new FormControl(null, [Validators.min(1)]),
      paymentMethod: new FormControl(null),
    });
  }

  private setDialogTitle(): void {
    this.rightSidePanelService.setDialogPageTitle('Load Credit');
  }

  private async checkRightSideContent(): Promise<void> {
    this.isRightSideContent = (await this.storage.retrieve('dialog-right-side-open')) || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
  }

  private async loadPaymentMethods(): Promise<void> {
    let paymentMethods = await this.getParam('paymentMethods');
    console.log(paymentMethods)
    if (!paymentMethods) {
      paymentMethods = await this.paymentMethodsProvider.getPaymentMethods();
      paymentMethods = this.util.parsePaymentMethods(paymentMethods);
    }
    console.log(paymentMethods)
    this.paymentMethodsItems = this.mapAndFilterPaymentMethods(paymentMethods);
  }

  private mapAndFilterPaymentMethods(paymentMethods: any[]): any[] {
    const paymentMethodsItems = paymentMethods
      .map((paymentMethod) => ({
        ...paymentMethod,
        viewValue: this.getPaymentMethodLabel(paymentMethod),
        value: paymentMethod.id,
        type: paymentMethod.type,
        icon: paymentMethod.type === 'card' ? 'assets/svg/card.svg' : 'assets/img/bank.png',
      }))
      .filter((paymentMethod) => paymentMethod.status === 'succeeded');
      paymentMethodsItems.push({
        viewValue: 'Add New Payment Method',
        value: 'add-new-payment-method',
        icon: 'assets/svg/add-outline.svg'
      });
    return paymentMethodsItems;
  }

  async getParam(paramName: string): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    return param;
  }

  getPaymentMethodLabel(paymentMethod: any): string {
    let label = paymentMethod.type === 'card' ? (new TranslationPipe().transform('Card')) : (new TranslationPipe().transform('Bank Account'));
    if (paymentMethod?.metadata?.last4) {
      label += ` ${new TranslationPipe().transform('ending in')} ${paymentMethod.metadata.last4}`;
    }
    if (paymentMethod?.is_prmary) {
      label += ` ${new TranslationPipe().transform('(primary)')}`;
    }
    return label;
  }

  async addCredit(): Promise<void> {
    if (!this.form.value.price || !this.form.value.paymentMethod) {
      this.util.showWarning('Please fill in all fields');
      return;
    }
    const paymentMethodId = this.form.get('paymentMethod').value;
    const amountString = this.form.get('price').value;
    const amount = parseFloat(amountString.replace('$', '').replace(',', '')) * 100;
    try {
      await this.paymentMethodsProvider.addCredit(paymentMethodId, amount);
      this.util.showSuccess('Credit added successfully');
      this.rightSidePanelService.navigateTo('billing-history');
    } catch (error) {
      this.util.showError(`${new TranslationPipe().transform('Error adding credit')}: ${error.message}`);
      console.error('Error adding credit', error);
    }
  }

  navBack(): void {
    const isDesktopRes = this.windowService.isDesktopRes;
    if (isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.navigateRoot('more');
    }
  }

  async goToAddPaymentMethod() {
    this.storage.delete('dialog-params');
    const params = {
      paymentMethods: this.paymentMethodsItems,
    };
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  changePaymentMethod(value: string) {
    if (value === 'add-new-payment-method') {
      this.goToAddPaymentMethod();
    }
  }

}

import { Component, ViewEncapsulation, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Util } from 'src/shared/util/util';

@Component({
  selector: 'tidy-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OnboardingModalComponent implements OnInit {

  @Input() hasProperties: boolean;
  @Input() page: string;
  showPrimaryModal = false;
  showedPrimaryModalOnFirstLoad = false;
  
  constructor(
    private cdr: ChangeDetectorRef,
    public onboardingProvider: OnboardingProvider,
    private util: Util
  ) {}

  ngOnInit() {
    this.onboardingProvider.flow = this.page;
    this.onboardingProvider.hasProperties = this.hasProperties;
    this.onboardingProvider.shouldShowAddProperty = this.checkIfShouldShowAddProperty();
    this.onboardingProvider.setOnboardingFlowText();
    this.showPropertyPageIfRequired();
    this.cdr.detectChanges();
    if (this.onboardingProvider.flow === 'automateWorkflows' || this.onboardingProvider.flow === 'upsell' || this.onboardingProvider.flow === 'selectFindNewPros') {
      this.showedPrimaryModalOnFirstLoad = true;
      this.showPrimaryModal = true;
    }
  }

  checkIfShouldShowAddProperty() {
    const showPropertyFlows = ['toDos', 'restocking', 'addMaintenanceItems', 'addPros', 'schedule', 'selectAccessNotes'];
    return showPropertyFlows.includes(this.onboardingProvider.flow) && !this.hasProperties;
  }

  onOverlayClick(event: MouseEvent) {
    if (this.showedPrimaryModalOnFirstLoad) {
      return this.onboardingProvider.setShowOnboardingOnPage(false);
    }
    this.showPrimaryModal = false;
    this.showPropertyPageIfRequired();
    this.cdr.detectChanges();
  }

  showPropertyPageIfRequired() {
    if (this.onboardingProvider.shouldShowAddProperty) {
      this.onboardingProvider.page = 'property';
    } else {
      this.onboardingProvider.page = this.onboardingProvider.flow;
    }
  }

  goToPrimaryModal() {
    this.showPrimaryModal = true;
    this.cdr.detectChanges();
    }

  goBack() {
    this.onboardingProvider.page = this.onboardingProvider.priorPages[this.onboardingProvider.priorPages.length - 1];
    this.onboardingProvider.priorPages.pop();
    if (this.onboardingProvider.priorPages.length == 0) {
      this.onboardingProvider.showBackButton = false;
    }
  }

  openScheduleCallModal() {
    this.util.openUrl('https://www.tidy.com/get-demo');
  }

}
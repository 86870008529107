import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientHub } from 'src/providers/client-hub/client-hub';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'client-hub.html'
})

export class ClientHubPage implements OnInit {

  clientHubUuId: string;
  clientHubInfo: any;
  errorMessage: string;
  loaded: boolean;
  showLimiter:{ [id: string] : number} = {
    'pendingProposals' : 3,
    'acceptedProposals' : 3,
    'pendingInvoices' : 3,
    'paidInvoices' : 3
  };

  constructor(
    private clientHub: ClientHub,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    public windowService: WindowService
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.clientHubUuId = this.route.snapshot.paramMap.get('client_hub_uuid');
      this.clientHubInfo = await this.clientHub.getClientHub(this.clientHubUuId);
      this.loaded = true;
    } catch(err) {
      this.clientHub.goToErrorPage();
    }
  }

  goToInvoicePage(invoice) {
    const params = {
      clientHubInfo: this.clientHubInfo,
      clientHubUuId: this.clientHubUuId,
      successRoute: `client/${this.clientHubUuId}`
    }
    this.navCtrl.navigateForward(`client-pending-bill/${invoice.uuid}`, params);
  }

  goToPaidInvoicePage(invoice) {
    const params = {
      invoice: invoice,
      cameFromClientHub: true
    }
    this.navCtrl.navigateForward(`client-paid-bill/${invoice.uuid}`, params);
  }

  goToPendingProposalPage(proposal) {
    const params = {
      proposal: proposal,
      cameFromClientHub: true,
      clientHubUuId: this.clientHubUuId,
      clientHasAccount: this.clientHubInfo.client_hub_details.customer.state == 'active'
    }
    this.navCtrl.navigateForward(`pending-proposal/${proposal.uuid}`, params);
  }

  goToAcceptedProposalPage(proposal) {
    const params = {
      proposal: proposal,
      cameFromClientHub: true,
      clientHubUuId: this.clientHubUuId,
      clientHasAccount: this.clientHubInfo.client_hub_details.customer.state == 'active'
    }
    this.navCtrl.navigateForward('accepted-proposal', params);
  }

  showLimit(key) {
    this.showLimiter[key] = this.showLimiter[key] === 3 ? 30 : 3;
  }

  getClientFirstName(name) {
    let spaceIndex = name.indexOf(' ');
    return spaceIndex === -1 ? name : name.substr(0, spaceIndex);
  };
}

import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
@Component({
  selector: 'to-dos-modal-onboarding',
  templateUrl: './to-dos.component.html',
  styleUrls: ['./to-dos.component.scss'],
})
export class ToDosModalOnboardingComponent {
  constructor(private onboardingProvider: OnboardingProvider) {}

  goToUploadList() {
    this.onboardingProvider.priorPages.push('toDos');
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'createConciergeAction';
  }

  goToPopularList() {
    this.onboardingProvider.priorPages.push('toDos');
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'popularList';
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';

import { Loading } from 'src/shared/components/loading/loading';

import { ProposalModel } from 'src/shared/models/proposals.model';

@Component({
  templateUrl: 'proposals.html'
})

export class ProposalsPage implements OnInit {

  currentProposals: ProposalModel[];
  pageTitle: string;
  status: string;
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private route: ActivatedRoute
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.status = this.route.snapshot.paramMap.get('proposalStatus');
      this.pageTitle = this.status === 'pending' ? 'Pending Proposals' : 'Accepted Proposals';
      this.currentProposals = this.navCtrl.getParam('currentProposals');
      if (!this.currentProposals) {
        this.currentProposals = await this.proposals.getProposals(this.status);
      }
      this.loaded = true;
    } catch (err) {
    }
  }

  goToProposalPage(proposal) {
    if (this.status == 'pending') {
      this.navCtrl.navigateForward(`${this.status}-proposal/${proposal.uuid}`);
    } else {
      const params = {
        proposal: proposal,
        proIsPrivate: this.navCtrl.getParam('proIsPrivate')
      }
      this.navCtrl.navigateForward(`${this.status}-proposal`, params);
    }
  }
}

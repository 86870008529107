import { Component, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'tidy-advanced-setup',
  templateUrl: './advanced-setup.html',
  styleUrls: ['./advanced-setup.scss'],
})
export class AdvancedSetupComponent {
  constructor(private modalController: ModalController) {}

  scheduleCall() {
    console.log('scheduleCall');
  }

  closeModal() {
    this.modalController.dismiss('advanced-setup-modal');
  }
}

<label>
  <ion-icon
    *ngIf="icon"
    [name]="icon"
  ></ion-icon>
  <tidy-text
    [body]="label">
  </tidy-text>
</label>

<section>
  <mat-checkbox (change)="selectItem()" [(ngModel)]="value" class="example-margin" *ngFor="let item of items" [disabled]="disabled">
    <tidy-text
      [body]="item.viewValue">
    </tidy-text>
  </mat-checkbox>
</section>

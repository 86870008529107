<tidy-card>
  <tidy-card-button (action)="goToCreateConciergeAction()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/csv.svg'"
          style="width:20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text
            [header]="'Upload Your Inventory List'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Already have a list? Upload it.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-card>
  <tidy-card-button (action)="goToUploadInventoryList()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/integrate.svg'"
          style="width:20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text
            [header]="'Create an Inventory List'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Start with the most popular items and add more as needed.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>
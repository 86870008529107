import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { Auth } from '../auth/auth';
import { Addresses } from '../customer/addresses';
import { Customers } from '../customers/customers';
import { Localstorage } from '../localstorage/localstorage';

@Injectable()
export class Customer {
  constructor(
    private httpClient: HttpClientCustom,
    private addresses: Addresses,
    private auth: Auth,
    private customers: Customers,
    private localStorage: Localstorage
  ) { }

  getCleaningsHistory(hkId = null) {
    const params = hkId ? `?homekeeper_id=${hkId}` : '';
    const url = `api/v1/customer/cleanings/history${params}`;
    return this.httpClient.get(url);
  }

  getCleaningHistByAddress(cleaningsHistory, selection) {
    let resultData = cleaningsHistory;
    if (selection) {
      resultData = cleaningsHistory.filter(item => {
        return item.display_address === selection;
      });
    }

    return resultData;
  }

  saveGeneralInformation(payload) {
    payload.phone = payload.phone.split(/[\s-()]+/).join('');
    const url = `api/v1/customer/signup/steps/general-information`;

    return this.httpClient.put(url, payload);
  }

  getBillingCredits(cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/billing/credits-added`;
    return this.httpClient.get(url);
  }

  billingRefund(data) {
    const url = `api/v1/customer/billing/refund`;
    return this.httpClient.post(url, data);
  }

  getBillingData() {
    const url = 'api/v1/customer/billing/balance-history';
    return this.httpClient.get(url);
  }

  async getBillingHistory(dataArray: any) {
    let address = '?';
    for (let i = 0; i < dataArray.length; i++) {
      address += `addresses[][${i}]=${dataArray[i]}&`;
    }
    const url = `api/v1/customer/billing/history/${address}`;
    let result = await this.httpClient.get(url);
    result = this.mountRefundStatus(result);
    return result;
  }

  mountRefundStatus(result) {
    return result.map((item) => {
      const total = 0;
      if (item.refunded === true) {
        item.refund_type = '(Refunded)';
      }
      item.details.forEach((detail) => {
        if (item.refunded === false && detail.debit_type === 'refund') {
          item.refund_type = '(Partially Refunded)';
        }
      });
      return item;
    });
  }

  buyBundle() {
    const customerId = localStorage.getItem('customer_id');
    const data = {
      customer_id: customerId,
      amount: 50000,
      currency: 'usd',
      obs: 'bundle credit'
    }
    const url = `api/v1/customer/billing/buy-bundle`;
    return this.httpClient.post(url, data);
  }

  async getComunicationContacts(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/communication/contents`;
    const result = await  this.httpClient.get(url);
    return result.reverse();
  }

  rescheduleCleaning(data, cleaningId) {
    const addressId = localStorage.getItem('addressId');
    data.address_id = addressId;
    const url = `api/v1/customer/cleanings/${cleaningId}/reschedule`;
    return this.httpClient.post(url, data);
  }

  addCleaning(data) {
    const addressId = localStorage.getItem('addressId');
    data.address_id = addressId;

    const url = `api/v1/customer/addresses/${addressId}/cleanings`;
    return this.httpClient.post(url, data);
  }

  getSmsComunication(data) {
    const url = `api/v1/customer/communication/start`;
    return this.httpClient.post(url, data);
  }

  saveTest(data) {
    const url = `api/v1/customer/feature_experiments`;
    return this.httpClient.post(url, data);
  }

  getRequestSubstitute(cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/request-substitute-times`;
    return this.httpClient.get(url);
  }

  addRequestSubstitute(data, cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/request-substitutes`;
    return this.httpClient.post(url, data);
  }

  deleteRequestSubstitute(cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/request-substitutes`;
    return this.httpClient.delete(url);
  }

  settings() {
    const url = `api/v1/customer/settings`;
    return this.httpClient.get(url);
  }

  async saveSettings(data) {
    const url = `api/v1/customer/settings`;
    const result = await this.httpClient.put(url, data);
    this.auth.setAccountTypeStorage(result.profile.account_type);
    return result;
  }

  changeAccountType(type: string) {
    const data = {
      profile: {
        account_type: type
      }
    };
    return this.saveSettings(data);
  }

  addRequestSubstitute1hk(data, jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/request-half-substitutes`;
    return this.httpClient.post(url, data);
  }

  markHalfCleaningAsOk(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/mark-half-cleaning-as-ok`;
    return this.httpClient.post(url, {});
  }

  async getHkBlackListed() {
    const url = `api/v1/customer/homekeepers/blacklisted`;
    const result = await this.httpClient.get(url);
    result.map((item) => {
      if (item.homekeeper && item.homekeeper.name) {
        item.homekeeper.firstName = item.homekeeper.name.split(' ')[0];
      }
      return item;
    });
    return result;
  }

  async getElegibleForBlacklist() {
    const url = 'api/v1/customer/homekeepers/eligible-for-blacklist';
    const result: any = await this.httpClient.get(url);
    result.map((item) => {
      if (item.homekeeper && item.homekeeper.name) {
        item.homekeeper.firstName = item.homekeeper.name.split(' ')[0];
      }
      return item;
    });
    return result;
  }

  addBlacklist(hkId) {
    const url = `api/v1/customer/homekeepers/${hkId}/add-to-blacklist`;
    return this.httpClient.post(url, {});
  }

  removeBlacklist(hkId) {
    const url = `api/v1/customer/homekeepers/${hkId}/remove-from-blacklist`;
    return this.httpClient.delete(url);
  }

  addFavorite(hkId) {
    const url = `api/v1/customer/homekeepers/${hkId}/add-to-favorites`;
    return this.httpClient.post(url, {});
  }

  removeFavorite(hkId) {
    const url = `api/v1/customer/homekeepers/${hkId}/remove-from-favorites`;
    return this.httpClient.delete(url);
  }

  confirmText (status, hkName) {
    const staticText = {
      addBlacklist: {
        title: 'Add to Blacklist?',
        btConfirm: 'Blacklist',
        description: `Are you sure you want to add ${hkName} to your blacklist?  This will remove them from any cleanings you have scheduled with them and ensure you are not paired in the future.`
      },
      removeBlacklist: {
        title: 'Remove Blacklist?',
        btConfirm: 'Remove',
        description: `Are you sure you want to remove ${hkName} from your blacklist?  This will ensure that you can be paired in the future.`
      },
      addFavorite: {
        title: 'Add to Favorite?',
        btConfirm: 'Favorite',
        description: `Are you sure you want to add ${hkName} to your favorites?  We always try to pair you with your favorite first.`
      },
      removeFavorite: {
        title: 'Remove from Favorite?',
        btConfirm: 'Unfavorite',
        description: `Are you sure you want to remove ${hkName} from your favorites?  We always try to pair you with your favorite first.`
      }
    }
    return staticText[status];
  }

  confirmAccountChangeText (changeType) {
    const staticText = {
      accountName: {
        title: 'Update Account Name',
        btConfirm: 'Update',
        description: 'Update your account name?',
        postConfirmTitle: 'Account name Updated',
        postConfirmDescription: 'Your account name were updated.'
      },
      accountType: {
        title: 'Update Account Type',
        btConfirm: 'Update',
        description: 'Update your account type preferences?',
        postConfirmTitle: 'Account Type Updated',
        postConfirmDescription: 'Your account type were updated.'
      },
      before_cleaning: {
        title: 'Update Billing?',
        btConfirm: 'Update',
        description: 'Update preferences to charge ahead for the next cleaning and save 10% on each cleaning?',
        postConfirmTitle: 'Billing Updated',
        postConfirmDescription: 'Your billing preferences were updated to charge ahead for the next cleaning.'
      },
      after_cleaning: {
        title: 'Update Billing?',
        btConfirm: 'Update',
        description: 'Update your preferences to charge after your cleaning and pay 10% more for each cleaning?',
        postConfirmTitle: 'Billing Updated',
        postConfirmDescription: 'Your billing preferences were updated to charge after the cleaning.'
      },
      buyBundle: {
        title: 'Buy Bundle',
        btConfirm: 'Buy Bundle',
        description: 'Save $50 on TIDY!\n\nWant to save money on your Home Cleanings?  Purchase $550 in TIDY Credit for only $500.\n\nTry this risk-free - you can still cancel anytime and are eligible for our Satisfaction Guarantee.\n\nBy Tapping "Buy Bundle" we will charge your card on file $500, and add $550 credit to your account.',
        postConfirmTitle: 'Bundle Purchased!',
        postConfirmDescription: 'Your card was charged $500 and you received $550 credit ($50 savings).  You can view this credit in your billing history now.',
        additionalLinkText: 'Learn More',
        additionalLinkUrl: 'https://help.tidy.com/payment-methods-and-settings#bundles'
      }
    }

    return staticText[changeType];
  }

  cancelAccount(){
    const url = 'api/v1/customer/cancel-account';
    return this.httpClient.delete(url);
  }

  cancelAllCleanings(){
    const url = 'api/v1/customer/plans/cancel-all';
    return this.httpClient.delete(url);
  }

  getRefundAnswers() {
    return [
      {
        text: 'No availability I want',
        textFormated: 'no_availability',
        answer: 'Refund successful. A refund will appear on your original payment method in 2-10 business days.'
      },
      {
        text: 'Moving/traveling',
        textFormated: 'moving',
        answer: 'Refund successful. A refund will appear on your original payment method in 2-10 business days.'
      },
      {
        text: 'Don’t need cleaning right now',
        textFormated: 'do_not_need',
        answer: 'Refund successful. A refund will appear on your original payment method in 2-10 business days.'
      },
      {
        text: 'Unhappy with cleaning',
        textFormated: 'bad_cleaning',
        answer: 'Your refund has been issued, and someone will reach out shortly to address your issue.  We would love to make things right.'
      },
      {
        text: 'Homekeeper canceled on me',
        textFormated: 'no_show',
        answer: 'Your refund has been issued, and someone will reach out shortly to address your issue.  We would love to make things right.'
      },
      {
        text: 'I prefer to work directly with my Homekeeper',
        textFormated: 'work_directly',
        answer: 'Refund successful. A refund will appear on your original payment method in 2-4 business days.'
      }
    ];
  }

  acceptCounteredTimes(availabilityRequestId: string, date, time) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}/accept`;

    return this.httpClient.put(url, { date, time });
  }

  rejectCounteredTimes(availabilityRequestId: string) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}/reject`;

    return this.httpClient.put(url, {});
  }

  deleteRequestTime(availabilityRequestId: string) {
    const addressId = localStorage.getItem('addressId');

    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}`;

    return this.httpClient.delete(url, {availability_request_id: availabilityRequestId});
  }
}

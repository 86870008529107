<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Pending Proposal'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="8">
                  <tidy-row>
                    <tidy-text
                      [title]="'Proposal From'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proposal?.team?.owner?.name">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proposal?.team?.owner?.email">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="proposal?.team?.owner?.phone | telephone:'format'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
                <ion-col>
                  <!--TODO add logo-->
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <!--TODO divider to have [color] input and add dynamic color-->
          <tidy-divider
            class="full-card-width thick"
            style="background: #4DAEED">
          </tidy-divider>
        </tidy-row>

        <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [body]="'To:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(proposal?.customer?.name | titlecase)">
            </tidy-text>
            <tidy-text
              [body]="proposal?.created_at | customdate:'M/D/YY'">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text
              *ngIf="!proposal?.customer?.addresses?.length > 1"
              [body]="'Valid At: All Properties'">
            </tidy-text>
            <ng-container *ngIf="proposal?.customer?.addresses?.length == 1">
              <tidy-text
                [body]="'Valid At:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="proposal?.customer?.addresses[0]?.address + ','">
              </tidy-text><span>&nbsp;</span>
              <ng-container *ngIf="proposal?.customer?.addresses[0]?.unit">
                <tidy-text
                  [body]="'Unit'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="proposal?.customer?.addresses[0]?.unit + ','">
                </tidy-text><span>&nbsp;</span>
              </ng-container>
              <tidy-text
                [body]="proposal?.customer?.addresses[0]?.zip">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Cost'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true">
          <div style="width:85%">
            <tidy-text
              [header]="(proposal?.service_details?.service_name | titlecase)">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [body]="(proposal?.service_details?.price | tcurrency)">
            </tidy-text>
          </div>
        </tidy-row>

        <tidy-row style="width:85%" class="extra-bottom-padding">
          <tidy-text
            [body]="proposal?.description">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [body]="'We are proud to offer this option for 30 days:'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="proposal?.team?.owner?.name"
            class="signature">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>
      </tidy-card>

      <tidy-button
        [action]="markAccepted.bind(this)"
        [text]="'Accept Proposal'"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Reject Proposal'"
          class="link"
          (action)="rejectProposal()">
        </tidy-text>
      </tidy-row>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { AddressModel } from 'src/models/address.model';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'use-to-dos.html',
  encapsulation: ViewEncapsulation.None,
})

export class UseToDosPage implements OnInit {

  addressResponse: AddressModel;
  addressId: number;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('TIDY To-Dos');
    this.loadData();
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.addressResponse = await this.getParam('addressResponse');
    this.addressId = await this.getParam('addressId');
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }
}

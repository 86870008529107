import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-card',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card.component.scss'],
  template: `
    <section>
      <ng-content></ng-content>
    </section>
  `
})

export  class CardComponent {
  @Input() showArrow: boolean;

}

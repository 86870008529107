import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ModalMoreTimeDataModel, SelectedTimesModel } from 'src/models/book-job.model';

import { WindowService } from "src/shared/providers/window.service";

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';

@Component({
  templateUrl: 'modal-more-times.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'book-job'
})
export class ModalMoreTimesPage implements OnInit, OnDestroy {

  @Input() firstTime: any;
  @Input() thriceAWeek: boolean;
  @Input() dateTimes: any[];
  @Input() frequencyLabel: string;
  @Input() selectedPrice;
  @Input() eventEmitter: EventEmitter<ModalMoreTimeDataModel>;

  form: UntypedFormGroup;
  closeSecondTime = false;
  completed = false;

  second_time;
  third_time;

  topNavHeight: number = 0;
  private resizeObserver: ResizeObserver;

  constructor(
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private windowService: WindowService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      second_time: ['', Validators.required],
      third_time: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.observeTopNavHeight();
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  private observeTopNavHeight() {
    const element = document.getElementById('top-nav-height');
      if (element) {
        this.topNavHeight = element.offsetHeight + 20;
        this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.topNavHeight = entry.contentRect.height + 20;
          this.changeDetectorRef.detectChanges();
        }
      });
      this.resizeObserver.observe(element);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({
      completed: this.completed,
      timesSelected: this.getTimesSelected()
    });
  }

  secondDateLabel() {
    if (this.form.value.second_time === '') {
      return 'Select Second Time';
    }
    const date = this.formatDate(this.second_time.value, 'dddd M/D');
    return [date, this.second_time.viewValue].filter(Boolean).join(' at ');
  }

  thirdDateLabel() {
    if (this.form.value.third_time === '') {
      return 'Select Third Time';
    }
    const date = this.formatDate(this.third_time.value, 'dddd M/D');
    return [date, this.third_time.viewValue].filter(Boolean).join(' at ');
  }

  selectSecondTime(dateTime, idx) {
    this.second_time = dateTime;
    if (this.thriceAWeek) {
      this.closeSecondTime = true;
      this.dateTimes = this.dateTimes.filter((_, i) => i > idx);
    } else {
      this.completed = true;
      const data = {completed: true, timesSelected: this.getTimesSelected()};
      this.eventEmitter.emit(data);
      if (this.windowService.isDesktopRes) {
        this.closeModal();
      }
    }
  }

  selectThirdTime(dateTime) {
    this.third_time = dateTime;
    this.completed = true;
    const data = {completed: true, timesSelected: this.getTimesSelected()};
    this.eventEmitter.emit(data);
    if (this.windowService.isDesktopRes) {
      this.closeModal();
    }
  }

  isFormValid() {
    const secondTimeFilled = this.form.value.second_time !== '';
    const thirdTimeFilled = this.form.value.third_time !== '';

    return secondTimeFilled && (!this.thriceAWeek || thirdTimeFilled);
  }

  confirmSelections() {
    const timesSelected = this.getTimesSelected();
    this.modalCtrl.dismiss({ completed: true, timesSelected });
  }

  getTimesSelected(): Array<SelectedTimesModel> {
    return this.thriceAWeek
    ? [this.second_time, this.third_time]
    : [this.second_time];
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

}

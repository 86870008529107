import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'create-concierge-rules-onboarding',
  templateUrl: './create-concierge-rules-onboarding.html',
  styleUrls: ['./create-concierge-rules-onboarding.scss']
})
export class CreateConciergeRulesOnboarding {
  constructor(
    public onboardingProvider: OnboardingProvider
  ) {}
} 
import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Injectable()
export class ClientHub {

  constructor(
    private httpClient: HttpClientCustom,
    private navCtrl: CustomNavController
  ) {}

  getClientHub(id) {
    const url = `api/v1/tidy-website/client-hubs/${id}`;
    return this.httpClient.get(url);
  }

  goToErrorPage() {
    const params = {
      header: 'Client Hub Not Avaliable',
      body: 'Please contact your Pro or TIDY Concierge with questions.',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.navCtrl.navigateForward('success-no-auth', params);
  }

  getSharedLink(sharedLinkUuid) {
    const includes = 'job_durations,tips,rooms_with_completed_todos,job_medias'
    const url = `api/v1/tidy-website/shared-links/${sharedLinkUuid}?active_only=false&includes=${includes}`;
    return this.httpClient.get(url);
  }
}

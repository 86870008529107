<tidy-error-message
  *ngIf="errorMessage"
  [text]="errorMessage">
</tidy-error-message>

<ng-container *ngIf="!isInvoiceNote">

  <!--TODO: Add logic to only show IF suggestion AND status == 'waiting'-->
  <div [ngStyle]="internalNoteStyle(true)" *ngIf="message?.content?.chat_suggestion?.name == 'reschedule_booking' && message?.content?.chat_suggestion?.status == 'waiting'">
    <tidy-row [alignLastItemRight]="true" class="extra-left-padding extra-right-padding" >
      <tidy-image
        [src]="'assets/img/tidy-ai.svg'"
        class="title-size">
      </tidy-image>
      <tidy-text
        [body]="'TIDY Concierge'"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px">
        {{getDateTime(message)}}
      </div>
      <tidy-text
        [actionBody]="'Dismiss'"
        (action)="dismissSuggestion()"
        class="red link">
      </tidy-text>
    </tidy-row>
    <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
      <tidy-text
        [body]="'Want to reschedule your ' + message?.metadata?.service_type_details?.name + '?'">
      </tidy-text>
    </tidy-row>
    <ng-container *ngIf="message?.content?.chat_suggestion?.data?.new_date">
      <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
        <tidy-text
          [body]="'Old time: ' + getSuggestionJobDate(message?.metadata?.job?.date) + ' at ' + getSuggestionJobTime(message?.metadata?.job?.start_time)">
        </tidy-text>
      </tidy-row>
      <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" class="extra-bottom-padding">
        <tidy-text
          [body]="'New time: ' + getSuggestionJobDate(message?.content?.chat_suggestion?.data?.new_date) + ' at ' + getSuggestionJobTime(message?.content?.chat_suggestion?.data?.new_start_time)">
        </tidy-text>
      </tidy-row>
      <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" class="extra-bottom-padding vertical-align-center">
        <tidy-image
          [src]="'assets/svg/checkmark-circle-outline.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [actionBody]="'Reschedule Job'"
          class="link"
          (action)="rescheduleJob()">
        </tidy-text>
      </tidy-row>
    </ng-container>
    <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/information-circle-outline.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [actionBody]="message?.content?.chat_suggestion?.data?.new_date ? 'View Other Times' : 'View Times'"
        class="link"
        (action)="goToBookingViewRescheduleJob()">
      </tidy-text>
    </tidy-row>
  </div>

  <!--TODO: Add logic to only show IF suggestion AND status == 'waiting'-->
  <div [ngStyle]="internalNoteStyle(true)" *ngIf="message?.content?.chat_suggestion?.name == 'cancel_booking' && message?.content?.chat_suggestion?.status == 'waiting'">
    <tidy-row [alignLastItemRight]="true" class="extra-left-padding extra-right-padding" >
      <tidy-image
        [src]="'assets/img/tidy-ai.svg'"
        class="title-size">
      </tidy-image>
      <tidy-text
        [body]="'TIDY Concierge'"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px">
        {{getDateTime(message)}}
      </div>
      <tidy-text
        [actionBody]="'Dismiss'"
        (action)="dismissSuggestion()"
        class="red link">
      </tidy-text>
    </tidy-row>
    <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
      <tidy-text
        [body]="'Want to cancel your ' + message?.metadata?.service_type_details?.name + '?'">
      </tidy-text>
    </tidy-row>
    <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/close-circle-outline.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [actionBody]="'Cancel Job'"
        class="link"
        (action)="cancelJob()">
      </tidy-text>
    </tidy-row>
  </div>

  <div *ngIf="message?.type == 'text'" [ngStyle]="internalNoteStyle(message?.is_internal)" [ngStyle]="errorSendingStyle(message?.content?.phone_status == 'failed')">
    <tidy-row class="vertical-align-center" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
      <tidy-image
        *ngIf="message?.is_internal"
        class="title-size"
        [src]="'assets/svg/create.svg'">
      </tidy-image>
      <tidy-initials-icon
        *ngIf="!message?.is_internal"
        [fullName]="message?.from_member?.first_name == 'Guest' ? 'Guest' : message?.from_member?.first_name + ' ' + message?.from_member?.last_name">
      </tidy-initials-icon>
      <tidy-text
        [body]="message?.from_member?.first_name"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px">
        {{getDateTime(message)}}
      </div>
      <ng-container *ngIf="message?.metadata?.job?.id && showJobLink">
        <tidy-image
          [src]="'assets/svg/briefcase-outline.svg'"
          style="font-size: 13px; margin-left: 10px; margin-top: 3px; opacity: 30%">
        </tidy-image>
        <div
        style="color: #00AABA;
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        margin: 3px 0px 0px 0px;
        cursor: pointer"
        (click)="goToJobPage(message?.metadata?.job)">
          {{getJobDate(message?.metadata?.job?.start_datetime, message?.job?.timezone_name)}} Job
        </div>
      </ng-container>
    </tidy-row>
    <tidy-row *ngFor="let media of message?.content?.files" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" [alignLastItemRight]="true" style="padding-bottom: 0px; margin-top:-5px">
      <tidy-photo-note
        style="max-width: 150px"
        [src]="media">
      </tidy-photo-note>
    </tidy-row>
    <tidy-row *ngIf="message?.content?.text !== '' && message?.content?.text" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" style="padding-bottom: 0px">
      <tidy-text
        [body]="message?.content?.text">
      </tidy-text>
    </tidy-row>
    <tidy-row *ngIf="message?.content?.phone_status == 'failed'" class="vertical-align-middle" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" style="padding-bottom: 0px">
      <tidy-image
        [src]="'assets/img/alert-circle-outline-red.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        class="red"
        [smallBody]="message?.content?.error_message">
      </tidy-text>
    </tidy-row>
  </div>

  <ng-container *ngIf="message?.type == 'phone_call'">
    <tidy-row class="vertical-align-center" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
      <tidy-image
        [src]="'assets/img/phone-black.svg'"
        class="phone-icon"
        [ngClass]="(message?.content?.text == 'Calling...' || message?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
      </tidy-image>
      <tidy-text
        [body]="message?.from_member?.first_name"
        [translate]="false">
      </tidy-text>
      <div
      style="color: #666;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 300;
      margin: 3px 0px 0px 5px;
      cursor: pointer">
        {{getDateTime(message)}}
      </div>
      <ng-container *ngIf="message?.metadata?.job?.id && showJobLink">
        <tidy-image
          [src]="'assets/svg/briefcase-outline.svg'"
          style="font-size: 13px; margin-left: 10px; margin-top: 3px; opacity: 30%">
        </tidy-image>
        <div
        style="color: #00AABA;
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        margin: 3px 0px 0px 0px;
        cursor: pointer"
        (click)="goToJobPage(message?.metadata?.job)">
          {{getJobDate(message?.metadata?.job?.start_datetime, message?.job?.timezone_name)}} Job
        </div>
      </ng-container>
    </tidy-row>
    <tidy-row [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding, 'extra-bottom-padding': last}">
      <tidy-text
        [body]="'<i>' + message?.content?.text + '</i>'">
      </tidy-text>
      <tidy-text
        *ngIf="message?.content?.text == 'Call finished.'"
        [body]="'<i>' + communication.getCallMinutes(message?.content?.phone_status?.duration) + '</i>'">
      </tidy-text>
    </tidy-row>
  </ng-container>

  <ng-container *ngIf="message?.type == 'cleaning_update'">
    <div
    style="color: #666;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 300;
    padding-left: 15px;
    padding-right: 15px;">
      {{getDateTime(message)}}
    </div>
    <tidy-row class="vertical-align-middle" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding, 'extra-bottom-padding': last}">
      <tidy-text
        [body]="'<i>' + message?.data?.text + '</i>'">
      </tidy-text>
    </tidy-row>
  </ng-container>
</ng-container>

<div *ngIf="isInvoiceNote" [ngStyle]="internalNoteStyle(message?.is_internal)">
  <tidy-row class="vertical-align-center" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
    <tidy-image
      *ngIf="message?.is_internal"
      class="title-size"
      [src]="'assets/svg/create.svg'">
    </tidy-image>
    <tidy-initials-icon
      *ngIf="!message?.is_internal"
      [fullName]="message?.created_by">
    </tidy-initials-icon>
    <tidy-text
      [body]="message?.created_by"
      [translate]="false">
    </tidy-text>
    <div
    *ngIf="message?.sent_at"
    style="color: #666;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 300;
    margin: 3px 0px 0px 5px">
      {{getDateTime(message)}}
    </div>
  </tidy-row>
  <tidy-row *ngIf="message?.media_url" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" [alignLastItemRight]="true" style="padding-bottom: 0px; margin-top:-5px">
    <tidy-photo-note
      style="max-width: 150px"
      [src]="message?.media_url">
    </tidy-photo-note>
  </tidy-row>
  <tidy-row *ngIf="message?.text !== '' && message?.text" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}" style="padding-bottom: 0px">
    <tidy-text
      [body]="message?.text">
    </tidy-text>
  </tidy-row>
</div>

<tidy-row *ngIf="!last" class="extra-bottom-padding" [ngClass]="{'extra-left-padding': addExtraPadding, 'extra-right-padding': addExtraPadding}">
</tidy-row>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ReportIssue } from 'src/providers/report-issue/report-issue';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'log-issue.html',
  encapsulation: ViewEncapsulation.None
})

export class LogIssuePage implements OnInit {

  addressFilter: any;
  addressId: any;
  addressSubmitted: boolean;
  attachments = [];
  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  submitted: boolean;
  title: string;
  type: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private reportIssue: ReportIssue
  ) {
    this.form = this.fb.group({
      description: ['', Validators.required],
      address: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.loaded = false;
    this.addressFilter = this.navCtrl.getParam('addressFilter');
    this.addressFilter.splice(0,1);
    this.addressId = this.navCtrl.getParam('addressId');
    this.form.patchValue({address: this.addressId || this.addressFilter[0].value});
    this.loaded = true;
  }

  getTitle() {
    const titles = {
      'damage': 'Damage',
      'low_inventory': 'Low Inventory',
      'pest_report': 'Pest Report',
      'utility_issue': 'Utility Issue',
      'other': 'Other'
    }
    return titles[this.type];
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let data = {
      address_id: this.addressId,
    	homekeeper_job_id: this.navCtrl.getParam('homekeeperJobId'),
    	issue_report_type_id: this.getReportIssueId(),
      description: this.form.value.description,
      issue_report_photos: this.attachments
    };
    try {
      await this.reportIssue.reportIssue(data);
      this.navCtrl.navigateForward(`tasks`);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.reportIssue.addAttachment();
      if (attachment.filename !== '') {
        this.attachments.push({
          'photo_url': attachment.fileKey
        });
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }

  getReportIssueId() {
    const types = {
      'https://api-sandbox3.tidy.com/': {
        'damage': 2,
        'low_inventory': 3,
        'pest_report': 67,
        'utility_issue': 100,
        'other': 34
      },
      'https://api-staging.tidy.com/': {
        'damage': 1,
        'low_inventory': 2,
        'pest_report': 34,
        'utility_issue': 67,
        'other': 3
      },
      'https://api-production.gotidy.com/': {
        'damage': 1,
        'low_inventory': 2,
        'pest_report': 68,
        'utility_issue': 101,
        'other': 35
      }
    }
    return types[environment.api_secure][this.type];
  }

  selectType(type) {
    this.type = type;
    this.title = this.getTitle();
  }

  selectAddress() {
    this.addressSubmitted = true;
    if (!this.form.controls.address.valid) {
      return;
    }
    this.addressId = this.form.value.address;
  }

}

<tidy-card *ngIf="pros.length > 0">

  <ng-container *ngIf="!edittingReview">
    <ng-container class="extra-bottom-padding" *ngFor="let pro of feedback; let first = first">
      <tidy-row *ngIf="pro?.data?.homekeeper_job_feedback !== null">
        <tidy-text
          [body]="'You Reviewed'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="pro?.data?.homekeeper?.first_name + ':'">
        </tidy-text><span>&nbsp;</span>
          <tidy-text
          [body]="pro?.data?.homekeeper_job_feedback.type === 'positive' ? 'Loved It' : 'Needs Work'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'Edit'"
          (action)="editReview(pro)"
          class="link">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="pro?.data?.homekeeper_job_feedback === null">
        <tidy-row class="extra-bottom-padding" [align]="'center'">
          <tidy-text
            [title]="'Rate'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [title]="pro?.data?.homekeeper?.first_name + '\'s'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [title]="'Job'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-grid>
            <ion-row>
              <ion-col size="2">
              </ion-col>
              <ion-col size="4">
                <tidy-round-button
                  class="black"
                  [image]="'assets/img/icons/thumb-down.svg'"
                  (action)="needsWork(pro)"
                  [label]="'Needs Work'" >
                </tidy-round-button>
              </ion-col>
              <ion-col size="4">
                <tidy-round-button
                  class="green"
                  [image]="'assets/img/icons/white-heart.svg'"
                  (action)="lovedIt(pro)"
                  [label]="'Love It'" >
                </tidy-round-button>
              </ion-col>
              <ion-col size="2">
              </ion-col>
            </ion-row>
          </tidy-grid>
        </tidy-row>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="edittingReview">
    <tidy-row class="extra-bottom-padding" [align]="'center'">
      <tidy-text
        [title]="'Rate'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [title]="edittingPro?.data?.homekeeper?.first_name + '\'s'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [title]="'Job'">
      </tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-grid>
        <ion-row>
          <ion-col size="2">
          </ion-col>
          <ion-col size="4">
            <tidy-round-button
              class="black"
              [image]="'assets/img/icons/thumb-down.svg'"
              (action)="needsWork(edittingPro)"
              [label]="'Needs Work'" >
            </tidy-round-button>
          </ion-col>
          <ion-col size="4">
            <tidy-round-button
              class="green"
              [image]="'assets/img/icons/white-heart.svg'"
              (action)="lovedIt(edittingPro)"
              [label]="'Love It'" >
            </tidy-round-button>
          </ion-col>
          <ion-col size="2">
          </ion-col>
        </ion-row>
      </tidy-grid>
    </tidy-row>
  </ng-container>

</tidy-card>

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Recurring Tasks'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container>

      <tidy-recurring-tasks-filter
        [addresses]="addressResponse"
        [loaded]="loaded"
        (onFilter)="onFilterChanges($event)"
        (onSearch)="onSearchChanges($event)"
        (onAddMaintenance)="openMaintenanceModal()">
      </tidy-recurring-tasks-filter>

      <tidy-card style="margin-top: 20px" *ngIf="loaded">
        <div style="overflow-x: auto">
          <table class="maintenance-list-table">
            <thead>
              <tr class="maintenance-list-header">
                <th>
                  <tidy-text [header]="'Title'"> </tidy-text>
                </th>
                <th>
                  <tidy-text [header]="'Property'"> </tidy-text>
                </th>
                <th>
                  <tidy-text [header]="'Item'"> </tidy-text>
                </th>
                <th>
                  <tidy-text [header]="'Next Date'"> </tidy-text>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="maintenancePlans?.length > 0">
              <tr
                *ngFor="let maintenance of filteredMaintenancePlans; let i = index"
                (click)="openEditMaintenance(maintenance)"
                class="maintenance-list-item">
                <td>
                  <tidy-text
                    [body]="maintenance?.name">
                  </tidy-text>
                </td>
                <td>
                  <tidy-text
                    [body]="maintenance?.address.address_name || maintenance?.address.address1"
                    [translate]="false">
                  </tidy-text>
                </td>
                <td>
                  <tidy-text
                    [body]="maintenance?.room_object?.name || '-'">
                  </tidy-text>
                </td>
                <td>
                  <tidy-text
                    [body]="maintenance?.next_do_date | date: 'MMM d YYYY'">
                  </tidy-text>
                </td>
                <td style="text-align: end;">
                  <ion-icon
                    name="chevron-forward-outline"
                    class="chevron-icon">
                  </ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </tidy-card>

      <ng-container *ngIf="!loaded">
        <ion-row>
          <ion-col>
            <ion-skeleton-text
              animated="true"
              style="
                width: 100%;
                height: 800px;
                border-radius: 6px;">
            </ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ng-container>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>

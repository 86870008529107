import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-error-message',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./error-message.component.scss'],
  template: `
    <ng-container>
      <span [innerHTML]="text | translate"></span>
    </ng-container>
  `
})

export  class ErrorMessageComponent {

  @Input() text: string;

}

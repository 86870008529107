<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Team'"
    [customBack]="'teams'"
    [canGoBack]="true"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteTeam()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/organization.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Team Detail'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Team Name:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="team?.name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            class="link"
            (action)="goToEditTeamName()"
            style="margin-left: 5px">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Parent Team:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            *ngIf="parentTeam == undefined"
            [body]="'None'">
          </tidy-text>
          <tidy-text
            *ngIf="parentTeam !== undefined"
            [body]="parentTeam?.name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            class="link"
            (action)="goToEditParentTeam()"
            style="margin-left: 5px">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/team.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Team Members'">
          </tidy-text>
          <tidy-text
            [body]="'Add Member'"
            class="link"
            (action)="goToAddUserToTeamPage()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Add team members to limit their access to only the properties in their teams.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let user of team?.team_members" [alignLastItemRight]="true">
          <tidy-text
            [body]="user?.customer_member_name"
            (action)="goToUserPage(user?.customer_member_id)"
            class="link"
            [translate]="false">
          </tidy-text>
          <tidy-text
            style="margin-left: 5px"
            [body]="'X'"
            class="link"
            (action)="removeUserFromTeam(user)">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="team?.team_members?.length == 0">
          <tidy-text
            [helper]="'No team members'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/home.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Properties'">
          </tidy-text>
          <tidy-text
            [body]="'Add Property'"
            class="link"
            (action)="goToAddPropertyToTeamPage()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Add properties to this team so members can see them and for reporting.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let address of team?.addresses" [alignLastItemRight]="true">
          <tidy-text
            *ngIf="address?.address_name"
            [body]="address?.address_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            *ngIf="!address?.address_name"
            [body]="address.address1 + ((address.address2 == null || address.address2 == '') ? '' : (' ' + address.address2))"
            [translate]="false">
          </tidy-text>
          <tidy-text
            style="margin-left: 5px"
            [body]="'X'"
            class="link"
            (action)="removePropertyFromTeam(address)">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="team?.addresses?.length == 0">
          <tidy-text
            [helper]="'No properties'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [header]="'Notifications'">
        </tidy-text>
        <tidy-text
          [body]="'Add Notifications'"
          class="link"
          (action)="goToAddNotificationsPage()">
        </tidy-text>
      </tidy-row>
      <tidy-alert *ngIf="notificationSettings?.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'No notifications added'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
      <tidy-card *ngFor="let notification of notificationSettings">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/alert.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="notification.display_name | titlecase">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let channel of notification.channels">
          <tidy-text
            [body]="channel?.name?.substring(0,37) + (channel?.name?.length > 37 ? '...' : '')"
            [translate]="false">
          </tidy-text>
          <tidy-text
            class="link"
            [actionBody]="'X'"
            style="margin-left:5px"
            (action)="removeEvent(notification, channel)">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row [alignLastItemRight]="userRole !== 'member'">
        <tidy-text
          [header]="'Team Billing'">
        </tidy-text>
        <tidy-text
          *ngIf="userRole !== 'member' && !teamCard"
          [body]="'Add Card'"
          class="link"
          (action)="goToAddTeamCardPage()">
        </tidy-text>
        <tidy-text
          *ngIf="userRole !== 'member' && teamCard"
          [body]="'Replace Card'"
          class="link"
          (action)="goToAddTeamCardPage()">
        </tidy-text>
      </tidy-row>
      <tidy-alert *ngIf="!teamCard">
        <tidy-row>
          <tidy-text
            [body]="'Add a card to use only for jobs done for these properties. This enables Team Billing.'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [actionBody]="'Learn More'"
            class="link"
            (action)="goToLearnMoreTeamBilling()">
          </tidy-text>
        </tidy-row>
      </tidy-alert>
      <ng-container *ngIf="teamCard">
        <tidy-payment-method-card
          [teamId]="team.id"
          [paymentMethod]="teamCard"
          (action)="handleAction($event)">
        </tidy-payment-method-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class Logs {
  constructor(
    private httpClient: HttpClientCustom
  ) {}

  getLogHistory(){
    const url = '/api/v1/customer/api/request-histories';
    return this.httpClient.get(url);
  }
}

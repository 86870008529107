import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'invoice-autopay.html'
})

export class InvoiceAutopayPage implements OnInit {

  autoPayMaxAmount: number;
  allowsInvoiceAutopay: boolean;
  errorMessage: string;
  form: FormGroup;
  loaded: boolean;
  pro: any;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private util: Util
  ) {
    this.form = this.fb.group({
      autoPayMaxAmount: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.autoPayMaxAmount = this.navCtrl.getParam('autoPayMaxAmount');
    this.allowsInvoiceAutopay = this.navCtrl.getParam('allowsInvoiceAutopay');
    this.pro = this.navCtrl.getParam('pro');
    if (this.autoPayMaxAmount) {
      this.form.patchValue({
        autoPayMaxAmount: `${this.autoPayMaxAmount}`
      });
    }
    this.loaded = true;
  }

  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const autopayPayload = {
        team_id: this.pro.team.id,
        key: 'payment.autopay',
        value: this.allowsInvoiceAutopay
      }
      await this.pros.updateAutopaySettings(autopayPayload);
      const amountPayload = {
        team_id: this.pro.team.id,
        key: 'payment.autopay_limit',
        value: parseInt(this.util.removeCurrencyMask(this.form.value.autoPayMaxAmount), 10)
      }
      await this.pros.updateAutopaySettings(amountPayload);
      this.navCtrl.navigateForward(`my-pro/${this.pro.homekeeper.id}`)
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ToDoListRoomModel } from 'src/models/to-do.model';

@Component({
  templateUrl: 'select-default-list.html'
})

export class ToDosSelectDefaultListPage implements OnInit {

  cameFromApplyListToJobs: boolean;
  cameFromDeleteList: boolean;
  deletedList: ToDoListRoomModel;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  submitted: boolean;
  loaded: boolean;
  toDoLists: TidySelectNumberValueModel[];

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private toDos: ToDos,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      defaultList: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Select New Default List');
    const toDoLists = this.navCtrl.getParam('toDoLists') || dialogParams.toDoLists;
    this.toDoLists = this.repackageListsForRadioButtons(toDoLists);
    this.deletedList = this.navCtrl.getParam('deletedList') || dialogParams.deletedList;
    this.cameFromApplyListToJobs = this.navCtrl.getParam('cameFromApplyListToJobs') || dialogParams.cameFromApplyListToJobs;
    this.cameFromDeleteList = this.deletedList !== null;
    const hasNoDefaultList = this.navCtrl.getParam('hasNoDefaultList') || dialogParams.hasNoDefaultList;
    if (!this.cameFromDeleteList && !this.cameFromApplyListToJobs && !hasNoDefaultList) {
      const toDoState = this.navCtrl.getParam('toDoState') || dialogParams.toDoState;
      this.form.patchValue({
        defaultList: toDoState == 'inactive' ? 0 : this.navCtrl.getParam('defaultListId')
      });
    }
    this.loaded = true;
  }

  repackageListsForRadioButtons(toDoLists) {
    let lists = [];
    toDoLists.map((list) => {
      lists.push({
        viewValue: list.title,
        value: list.id
      });
    });
    lists.push({
      viewValue: 'Don\'t use To-Dos by default',
      value: 0
    });
    return lists;
  }

  @Loading('', true)
  async updateDefaultList() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const addressId = localStorage.getItem('addressId');
    let defaultListId = this.form.value.defaultList;
    let payload = {
      address_task_list_id: defaultListId,
      address_id: addressId,
      settings: [{
        key: `default_list.address.${addressId}`,
        value: true
      }]
    }
    if (defaultListId === 0) {
      defaultListId = null;
      localStorage.setItem('taskListId', '-2');
      await this.toDos.updateDoNotUseToDos('inactive');
    } else {
      localStorage.setItem('taskListId', defaultListId);
      await this.toDos.updateDoNotUseToDos('active');
      await this.toDos.saveDefaultList(defaultListId, payload)
    }
    this.storage.delete('addresses');
    const params = this.mountSuccessPageParams(defaultListId);
    await this.storage.save('didMakeToDoUpdates', true);
    this.navCtrl.navigateForward('success', params);
  }

  mountSuccessPageParams(defaultListId) {
    if (defaultListId === null) {
      return {
        header: 'Not Using To-Dos',
        body: 'You are no longer using To-Dos by default at this address for future jobs.  You can still apply a list to a single job.',
        buttonText: 'Ok',
        buttonRoute: `list-settings`
      };
    } else {
      return {
        header: 'Default List Updated',
        body: 'This list will automatically be used for all jobs at this address.',
        buttonText: 'Ok',
        buttonRoute: `to-dos`
      };
    }
  }
}

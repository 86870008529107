<div class="calendar-month remove-wrapper-margin">
  <div class="weekdays">
    <div class="weekend-color">
      <tidy-text class="day-text" [body]="language && language !== 'en' ? (('Sunday' | translate) | slice:0:3) : 'Sun'"></tidy-text>
    </div>
    <div>
      <tidy-text [body]="language && language !== 'en' ? (('Monday' | translate) | slice:0:3) : 'Mon'"></tidy-text>
    </div>
    <div>
      <tidy-text [body]="language && language !== 'en' ? (('Tuesday' | translate) | slice:0:3) : 'Tues'"></tidy-text>
    </div>
    <div>
      <tidy-text [body]="language && language !== 'en' ? (('Wednesday' | translate) | slice:0:3) : 'Wed'"></tidy-text>
    </div>
    <div>
      <tidy-text [body]="language && language !== 'en' ? (('Thursday' | translate) | slice:0:3) : 'Thu'"></tidy-text>
    </div>
    <div>
      <tidy-text [body]="language && language !== 'en' ? (('Friday' | translate) | slice:0:3) : 'Fri'"></tidy-text>
    </div>
    <div class="weekend-color">
      <tidy-text class="day-text" [body]="language && language !== 'en' ? (('Saturday' | translate) | slice:0:3) : 'Sat'"></tidy-text>
    </div>
  </div>
  <div class="days">
    <ng-container *ngFor="let day of days">
      <div
        [class]="
          (day.otherMonth ? 'day other-month' : 'day') +
          (day.isWeekend ? ' weekend-color weekend-background' : '')"
        (click)="onClickDay(day)">
        <div class="day-header">
          <div class="month-label">
            <ng-container
              *ngIf="(day.filteredReservations.length === 0 && isLoading && !day.otherMonth)">
              <div style="margin-left: 5px;">
                <ion-skeleton-text
                  animated="true"
                  style="
                    height: 22px;
                    border-radius: 10px;
                    width: 100px;
                  "></ion-skeleton-text>
              </div>
            </ng-container>
            <ng-container
              *ngIf="day.reservationInfo?.starting > 0 || day.reservationInfo?.ending > 0">
              <div
                [class]="!day.isWeekend ? 'reservation-container' : 'reservation-container reservation-weekend'"
                [attr.id]="day.isSaturday ? 'hover-trigger-left' : 'hover-trigger-right'"
                (click)="onClickReservation(day)">
                <div class="align-center">
                  <div>
                    <tidy-image
                      class="custom-calendar-link-icon"
                      [src]="'assets/img/calendar-link.svg'"></tidy-image>
                  </div>
                  <div>
                    <ng-container *ngIf="windowWidth >= 820 && (windowWidth < 1070 && day.date !== 1 || windowWidth >= 1070)">
                      <tidy-text [body]="windowWidth <= 1245 ? (getReservationText(day) | truncate: 5) : getReservationText(day)"></tidy-text>
                    </ng-container>
                    <div
                      [class]="!day.isFirstWeek ? 'popup' : 'popup popup-first-week'"
                      id="popup-content">
                      <div class="popup-content">
                        <div class="container vertical-align-center">
                          <tidy-image
                            class="custom-calendar-link-icon"
                            [src]="'assets/img/calendar-link.svg'">
                          </tidy-image>
                          <tidy-text [header]="'Reservations'"> </tidy-text>
                        </div>
                        <div class="divider"></div>
                        <ng-container
                          *ngFor="let reservation of day.filteredReservations | slice: 0:4; let i = index">
                          <div
                            [class]="i === 0 ? 'container' : 'container bullet-item'">
                            <tidy-text
                              [body]="'• ' + reservation?.title"></tidy-text>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="day.filteredReservations.length > 4">
                          <div class="container bullet-item">
                            <tidy-text
                              [body]="'+' + (day.filteredReservations.length - 4) + (' more' | translate)"></tidy-text>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="day-label">
            <ng-container *ngIf="day.date === 1">
              <tidy-text
                style="margin-right: 5px;"
                class="day-text"
                [body]="day.shortMonth + ' '"></tidy-text>
            </ng-container>
            <ng-container *ngIf="day.isToday; else defaultDay">
              <div class="today-background-container">
                <div class="today-background"></div>
                <tidy-text class="day-text" [body]="day.date"></tidy-text>
              </div>
            </ng-container>
            <ng-template #defaultDay>
              <tidy-text class="day-text" [body]="day.date"></tidy-text>
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="day.filteredJobs.length !== 0 || !isLoading">
          <ng-container
            *ngIf="day.filteredReservations.length > 0 && day.filteredJobs.length > 0">
            <div style="margin-top: 1rem"></div>
          </ng-container>
          <ng-container
            *ngFor="let job of day.filteredJobs | slice: 0:totalJobsToShow; let i = index">
            <div
              class="job-container"
              [attr.id]="day.isSaturday ? 'hover-trigger-left' : 'hover-trigger-right'"
              (click)="onClickJob(job)">
              <div class="align-center">
                <div>
                  <tidy-image
                    *ngIf="job?.status?.icon_type == 'image'"
                    [src]="job?.status?.icon"
                    [class]="job?.status?.class"
                    style="height: 15px">
                  </tidy-image>
                </div>
                <div
                  *ngIf="job?.status?.icon_type == 'initials'"
                  [ngStyle]="job?.status?.status == 'on_the_way' || job?.status?.status == 'arrived_at_home' ? {'margin-right': '8px'} : {}">
                  <tidy-initials-icon
                    class="custom-initials-icon"
                    [fullName]="job?.title || job?.homekeeperName || 'BA'">
                  </tidy-initials-icon>
                </div>
                <tidy-text
                  [ngStyle]="{'margin-left': job?.status?.class == 'pro-initials-in-progress' ? '5px' : '0px'}"
                  [body]="windowWidth <= 1330 ? ((job?.address?.address_name || job?.address?.address1) | truncate: getJobNameTruncateBreakpoint()) : (job?.address?.address_name || job?.address?.address1)">
                </tidy-text>
              </div>
              <div class="popup" id="popup-content">
                <div class="popup-content">
                  <div class="container vertical-align-center">
                    <tidy-image [src]="job?.icon" class="body-size">
                    </tidy-image>
                    <tidy-text
                      [header]="job?.object?.service_name || job?.object?.service_type_details?.name">
                    </tidy-text>
                  </div>
                  <div class="divider"></div>
                  <div class="container">
                    <tidy-text
                      [body]="job?.parsedRequestTimes ? job?.parsedRequestTimes : (job?.date + ' ' + job?.time)"></tidy-text>
                  </div>
                  <div
                    class="container bullet-item"
                    *ngIf="job?.homekeeperNames; else homekeeperNameObject">
                    <tidy-text [body]="job?.homekeeperNames"></tidy-text>
                  </div>
                  <ng-template #homekeeperNameObject>
                    <div
                      class="container bullet-item"
                      *ngIf="job?.object?.homekeeper?.name">
                      <tidy-text
                        [body]="job?.object?.homekeeper?.name"></tidy-text>
                    </div>
                  </ng-template>
                  <div class="container bullet-item">
                    <tidy-text
                      [body]="job?.address?.address_name || job?.address?.address1"></tidy-text>
                  </div>
                  <div class="container bullet-item">
                    <tidy-text
                      [body]="job?.status?.name | titlecase"></tidy-text>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="day.filteredJobs.length > totalJobsToShow">
            <div class="job-container">
              <div class="align-center more-jobs">
                <tidy-text
                  [body]="'+' + (day.filteredJobs.length - totalJobsToShow) + (' more' | translate)"></tidy-text>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="(day.filteredJobs.length === 0 && isLoading && !day.otherMonth)">
          <div style="margin-top: 1rem">
            <ion-skeleton-text
              animated="true"
              style="
                height: 16px;
                border-radius: 6px;
                width: 100%;
              "></ion-skeleton-text>
          </div>
          <div *ngIf="totalJobsToShow > 1">
            <ion-skeleton-text
              animated="true"
              style="
                height: 16px;
                border-radius: 6px;
                width: 100%;
              "></ion-skeleton-text>
          </div>
          <div>
            <ion-skeleton-text
              animated="true"
              style="
                height: 16px;
                border-radius: 6px;
                width: 40%;
              "></ion-skeleton-text>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import JSONFormatter from 'json-formatter-js';

@Component({
  selector: 'tidy-json-formatter',
  templateUrl: './json-formatter.component.html',
  styleUrls: ['./json-formatter.component.scss'],
})
export class JsonFormatterComponent {
  constructor() { }
  @ViewChild('jsonRender', { static: false, read: ElementRef }) jsonRender: ElementRef;
  @Input() dataJson;

  ngAfterViewInit() {
    if (typeof this.dataJson === 'string') {
      const formatter = new JSONFormatter(JSON.parse(this.dataJson));
      this.jsonRender.nativeElement.appendChild(formatter.render());
    } else {
      const formatter = new JSONFormatter(this.dataJson);
      this.jsonRender.nativeElement.appendChild(formatter.render());
    }
  }
}

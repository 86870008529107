<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="loaded ? ('Edit ' | translate) + member?.first_name : ''"
    [canGoBack]="true"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteMember()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="firstName"
              [form]="form.controls.firstName"
              [label]="'First Name'"
              [errorMessage]="'Please enter their first name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="lastName"
              [form]="form.controls.lastName"
              [label]="'Last Name'"
              [errorMessage]="'Please enter their last name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="email"
              [form]="form.controls.email"
              [label]="'Email'"
              type="email"
              [errorMessage]="'Please enter a valid email'"
              [submitted]="submitted"
              [icon]="'assets/svg/mail-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row class="field-bottom-padding">
            <tidy-input
              formControlName="password"
              [form]="form.controls.password"
              [label]="'Password'"
              [errorMessage]="'Password enter a password.'"
              [submitted]="submitted"
              type="password"
              [icon]="'assets/svg/lock-closed-outline.svg'">
            </tidy-input>
          </tidy-row>

          <tidy-row [ngClass]="member?.role !== 'owner' ? 'field-bottom-padding' : ''">
            <tidy-input-international-phone
              formControlName="phone"
              [label]="'Their Phone'"
              [form]="form.controls.phone"
              [inputMode]="'numeric'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
            </tidy-input-international-phone>
          </tidy-row>

          <ng-container *ngIf="member?.role !== 'owner' && clientInfo?.customer_account?.account_type !== 'regular'">
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'User Type'"
                [items]="roleItems"
                [form]="form.controls.role"
                formControlName="role"
                [submitted]="submitted"
                [errorMessage]="'Please select a user type'">
              </tidy-select>
            </tidy-row>

            <tidy-row [align]="'center'" [ngClass]="user?.role == 'admin' && form?.value?.role == 'member' ? 'extra-bottom-padding' : ''">
              <tidy-text
                [helper]="'Account admins can see all users and properties. Team managers can only see users and properties on their team.'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="member?.role == 'admin' && form?.value?.role == 'member'">
              <tidy-row *ngIf="teamItems?.length > 0">
                <tidy-select
                  [icon]="'assets/svg/location-outline.svg'"
                  [label]="'Add to Team'"
                  [items]="teamItems"
                  [form]="form.controls.team"
                  formControlName="team"
                  [submitted]="submitted"
                  [errorMessage]="'Please select a team'">
                </tidy-select>
              </tidy-row>
              <ng-container *ngIf="teamItems?.length == 0">
                <tidy-row>
                  <tidy-text
                    [body]="'Create your first team:'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    formControlName="teamName"
                    [form]="form.controls.teamName"
                    [label]="'Team Name'"
                    [errorMessage]="'Please enter the first name of the team.'"
                    [submitted]="submitted"
                    [icon]="'assets/svg/information-circle-outline.svg'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
            </ng-container>
          </ng-container>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Update Info'"
          [action]="updateMember.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'introduction.html',
  encapsulation: ViewEncapsulation.None
})

export class IntroductionPage {

  constructor(
    private navCtrl: CustomNavController
  ) {}

  signUp() {
    this.navCtrl.navigateForward('create-account');
  }

  logIn() {
    this.navCtrl.navigateForward('login');
  }
}

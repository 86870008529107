import { Component, OnInit } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';

@Component({
  templateUrl: 'change-to-do-state.html'
})

export class ChangeToDoStatePage implements OnInit {

  state: string;
  text: string;
  title: string;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private toDos: ToDos
  ) {}

  ngOnInit() {
    this.state = this.navCtrl.getParam('toDoState');
    if (this.state === 'active') {
      this.title = 'Stop Using To-Dos?';
      this.text = 'This will remove lists from all jobs and stop using To-Dos for future jobs.  You can change this at any time.'
    } else {
      this.title = 'Start Using To-Dos?';
      this.text = 'This will apply your default list to all booked and future jobs.  You can change this at any time.'
    }
  }

  async confirm() {
    const newState = this.state === 'active' ? 'inactive' : 'active';
    await this.toDos.updateDoNotUseToDos(newState);
    const addresses = await this.client.getAddresses(true);
    this.navCtrl.navigateForward('to-dos');
  }

  async goBack() {
    this.navCtrl.navigateForward('to-dos');
  }
}

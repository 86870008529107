<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Needs Work'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper *ngIf="loaded" [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [body]="'We\'re sorry that you weren\'t happy with'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="hkName + '.'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'We would love to get this right. What Happened?'">
          </tidy-text>
        </tidy-row>

        <form [formGroup]="whatHpndForm">
          <ng-container *ngFor="let key of keys">
            <tidy-checkbox
              [formControlName]="key"
              [form]="whatHpndForm.controls.key"
              [items]="[{viewValue: questions[key] , value: true}]">
            </tidy-checkbox>
          </ng-container>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Next'"
        [action]="sendFeedback.bind(this)"
        class="primary">
      </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

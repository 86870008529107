import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'accepted-proposal.html'
})

export class AcceptedProposalPage implements OnInit {

  address: [];
  addressId: any;
  bookingKey: string;
  cameFromClientHub: boolean;
  clientName: string;
  createdAt: string;
  fromClientHub: boolean;
  loaded: boolean;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  proId: number;
  proposalUuId: string;
  serviceName: string;
  servicePrice: string;
  serviceDescription: string;

  constructor(
    private navCtrl: CustomNavController,
    private proposals: Proposals,
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    const proposal = this.navCtrl.getParam('proposal');
    this.cameFromClientHub = this.navCtrl.getParam('cameFromClientHub');
    this.buildPageData(proposal);
    this.loaded = true;
  }

  buildPageData(proposal) {
    if (this.cameFromClientHub) {
      this.proId = proposal.team.owner.id;
      this.ownerName = proposal.team.owner.name;
      this.ownerEmail = proposal.team.owner.email;
      this.ownerPhone = proposal.team.owner.phone;
      this.clientName = proposal.customer.name;
      this.address = ((proposal.customer?.addresses) && (proposal.customer?.addresses.length === 1)) ? proposal.customer.addresses[0] : null;
      this.createdAt = proposal.created_at;
      this.serviceName = proposal.service_details.service_name;
      this.servicePrice = proposal.service_details.price;
      this.serviceDescription = proposal.description;
      this.addressId = proposal.customer.address_ids[0];
      this.bookingKey = proposal.service_details.team_service_key;
    } else {
      this.proId = proposal.team.owner.id;
      this.ownerName = proposal.team.owner.first_name + ' ' + proposal.team.owner.last_name;
      this.ownerEmail = proposal.team.owner.email;
      this.ownerPhone = proposal.team.owner.phone;
      this.clientName = localStorage.getItem('customer_name');
      this.address = ((proposal?.addresses) && (proposal?.addresses.length === 1)) ? proposal.addresses[0] : null;
      this.createdAt = proposal.created_at;
      this.serviceName = proposal.proposable.team_service.name;
      this.servicePrice = proposal.proposable.client_price;
      this.serviceDescription = proposal.description;
      this.addressId = this.navCtrl.getParam('addressId'); //TODO Rachid will add to the response
      this.bookingKey = proposal.proposable.team_service.key;
    }
  }

  bookJob() {
    const clientHasAccount = this.navCtrl.getParam('clientHasAccount');
    const proposalParam = this.navCtrl.getParam('proposal');
    const clientHubUuId = this.navCtrl.getParam('clientHubUuId');
    const proposal = {
      team: {
        owner: {
          name: this.ownerName,
          id: this.proId
        }
      },
      customer: {
        address_ids: [this.addressId],
        email: proposalParam.customer?.email || localStorage.getItem('customer_email')
      },
      service_details: {
        team_service_key: this.bookingKey,
        service_name: this.serviceName,
        price: this.servicePrice
      },
      description: this.serviceDescription
    };
    this.proposals.bookJobForProposal(clientHasAccount, proposal, clientHubUuId);
  }
}

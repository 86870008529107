import { Injectable } from "@angular/core";
import { Events } from "../events/events";

@Injectable({
  providedIn: 'root'
})
export class CurrentAddress {

  constructor(
    private events: Events
  ) {
  }

  get addressId() {
    return localStorage.getItem('addressId');
  }

  set addressId(addressId) {
    localStorage.setItem('addressId', addressId);
    this.events.publish('address:changed');
  }
}

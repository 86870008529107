<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="proName? 'Edit '+ (proName | shortName) +' Tip' : ''"
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [body]="'Enter the new tip amount here. You can edit tips for 48 hours.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="tipValue"
            [label]="'New Amount'"
            [inputMode]="'numeric'"
            [form]="form.controls.tipValue"
            [submitted]="submitted"
            [icon]="'assets/svg/cash-outline.svg'"
            [errorMessage]="'Please enter a valid amount.'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-two-column-row>
      <tidy-button
        [text]="'Go Back'"
        [action]="goBack.bind(this)"
        class="secondary">
      </tidy-button>
      <tidy-button
        [text]="'Edit Tip'"
        class="primary"
        [action]="submit.bind(this)">
      </tidy-button>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
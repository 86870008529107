import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';
import { Webhooks } from 'src/providers/webhooks/webhooks';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { Util } from "src/shared/util/util";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WebhookAttemptPage } from '../webhooks/webhook-attempt/webhook-attempt';

@Component({
  templateUrl: 'add-account-notification.html',
  encapsulation: ViewEncapsulation.None
})

export class AddAccountNotificationPage extends TimeoutableComponent implements OnInit {

  isAccountAdmin: boolean;
  channelItems: any;
  eventItems: any;
  errorMessage: string;
  form: UntypedFormGroup;
  hasTeams: boolean;
  submitted: boolean;
  showSelectedEventsError: boolean;
  teamItems: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private teamService: TeamService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private webhooksService: Webhooks,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      webhookUrl: ['', Validators.required],
      description: '',
      channel: ['', Validators.required],
      events: [''],
      team: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Add Notifications');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.errorMessage = '';
      this.loaded = false;
      this.channelItems = this.buildChannelItems();
      this.eventItems = await this.buildEventItems();
      this.form.patchValue({channel: await this.getParam('selectedChannel')});
      const userRole = localStorage.getItem('user_role');
      this.isAccountAdmin = userRole !== 'member';
      if (this.isAccountAdmin) {
        await this.getTeamItems();
        this.hasTeams = this.teamItems.length > 1;
        this.form.controls.team.setValidators([Validators.required]);
      }
      const cameFromTeamPageId = await this.getParam('cameFromTeamPageId')
      if (cameFromTeamPageId) {
        this.form.patchValue({team: cameFromTeamPageId});
      } else {
        this.form.patchValue({team: 0});
      }
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getTeamItems() {
    this.teamItems = [];
    const teams = await this.teamService.getTeams();
    teams.map((team) => {
      this.teamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
    this.teamItems.unshift({
      viewValue: 'All Teams',
      value: 0
    });
  }

  async buildEventItems() {
    const events = await this.webhooksService.getWebhookEvents();
    const array: any = [];
    events.map((event) => {
      array.push({
        value: event.key,
        viewValue: event.display_name,
        description: event.description,
        checked: false
      })
    });
    return array;
  }

  buildChannelItems() {
    return [
      {
        value: 'webhook',
        viewValue: 'Webhook'
      },
      {
        value: 'slack',
        viewValue: 'Slack'
      }
    ];
  }

  async addWebhook() {
    const selectedEvents: any = [];
    this.eventItems.map((item) => {
      if (item.checked) {
        selectedEvents.push(item.value);
      }
    });
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid || selectedEvents.length == 0) {
      if (selectedEvents.length == 0) {
        this.showSelectedEventsError = true;
      }
      return;
    }
    try {
      const payload: any = {
        webhook_url: this.form.value.webhookUrl,
        description: this.form.value.description,
        channel: this.form.value.channel,
        events: selectedEvents
      }
      if (this.form.value.team !== 0) {
        payload['account_team_id'] = this.form.value.team;
      }
      const response: any = await this.webhooksService.addWebhook(payload);
      this.runTestWebhook(response.id);
      await this.webhooksService.testWebhook(response.id);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    }
  }

  @Loading('', true)
  async runTestWebhook(webhookId) {
    try {
      const testObject = await this.webhooksService.testWebhook(webhookId);
      this.goToWebhookAttempt(testObject, 'succeeded')
    } catch ( err ) {
      this.goToWebhookAttempt(err, 'failed')
    }
  }

  goToWebhookAttempt(attemptObject, status) {
    const params = {
      customTitle: 'Webhook Added',
      request: attemptObject.request,
      response: attemptObject.response,
      errorReturned: attemptObject,
      nextPage: 'account-notifications',
      status: status
    };
    this.rightSidePanelService.navigateTo('webhook-attempt', params, WebhookAttemptPage);
  }

  getWebhookUrlLabel() {
    const event = this.channelItems.find(event => event.value == this.form.value.channel);
    return event.viewValue;
  }

  goToLearnMoreNotifications() {
    const url = "https://help.tidy.com/notifications";
    this.util.openUrl(url);
  }

  goBack() {
    if (this.isRightSideContent) {
      this.rightSidePanelService.goBack();
    } else {
      this.navCtrl.back();
    }
  }

}

import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';

import { Util } from 'src/shared/util/util';

import { validateEmail } from 'src/shared/validator/validateEmail';
import { AttachmentModel } from 'src/models/concierge.model';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'contact-concierge.html',
  encapsulation: ViewEncapsulation.None
})

export class ContactConciergePage extends TimeoutableComponent {

  addingEmailCC: boolean;
  dontShowOtherText: boolean;
  errorMessage: string;
  email: string;
  form: UntypedFormGroup;
  metadata: any;
  otherText: string;
  isAddingRoomItem: boolean;
  isAutoAssign: boolean;
  isAddingPro: boolean;
  isAddingToDoList: boolean;
  isWorkflow: boolean;
  replyTime: string;
  submitted: boolean;
  title: string;
  type: string;
  attachments: AttachmentModel[] = [];
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private client: Client,
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
  ) {
    super();
    this.form = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email: ['']
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.title = await this.getParam('title');
      this.rightSidePanelService.setDialogPageTitle(this.title);
      this.email = await this.getEmail();
      this.type = await this.getParam('type');
      this.metadata = await this.getParam('metadata');
      this.replyTime = this.concierge.getReplyTime(this.type);
      this.otherText = '\"Other\" is good if none of the other categories work. ';
      this.isAddingPro = await this.getParam('isAddingPro');
      if (this.isAddingPro) {
        this.form.patchValue({
          subject: 'Add New Pro(s)'
        });
      }
      this.isAddingRoomItem = await this.getParam('isAddingRoomItem');
      if (this.isAddingRoomItem) {
        this.form.patchValue({
          subject: 'Add New Inventory'
        });
      }
      this.isAddingToDoList = await this.getParam('isAddingToDoList');
      if (this.isAddingToDoList) {
        this.form.patchValue({
          subject: 'Add To-Do List'
        });
      }
      this.isWorkflow = await this.getParam('isWorkflow');
      if (this.isWorkflow) {
        const workflowName = await this.getParam('workflowName');
        this.form.patchValue({
          subject: (this.metadata.workflow_id ? 'Edit ' : 'Add ') + workflowName
        });
      }
      const question = await this.getParam('question');
      if (question) {
        this.isAutoAssign = question.includes('auto assign');
        this.dontShowOtherText = true;
        this.form.patchValue({
          subject: this.isAutoAssign ? 'Turn On Auto Assign' : 'Support Question',
          message: question
        });
      }
      if (this.title == 'Booking Question') {
        this.dontShowOtherText = true;
      }
      if (this.title == 'Increase Rates') {
        this.form.patchValue({
          subject: 'I Need to Increase my Rates',
          message: await this.getParam('message')
        });
      }
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    return param;
  }

  async getEmail() {
    let email = localStorage.getItem('clientEmail');
    if (email) {
      return email;
    } else {
      try {
        const customerId = localStorage.getItem('customer_id');
        const clientInfo = await this.client.getCustomerInfo(customerId);
        localStorage.setItem('clientEmail', clientInfo.email);
      } catch (err) {
        this.errorMessage = err.error ? err.error.message : err.message;
      }
    }
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let data = {
    	type: this.type,
    	data: {
    		subject: this.form.value.subject,
    		body: this.form.value.message,
        emails: [],
        attachments: this.attachments.map(item => item.fileKey)
    	}
    };
    if (this.metadata) {
     data['metadata'] = this.metadata;
    }
    if (this.addingEmailCC) {
     data.data.emails.push(this.form.value.email);
    }
    try {
      await this.concierge.addConciergeItem(data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    this.util.showSuccess(this.type == 'support.concierge_task' ? 'Task Created' : 'Message Sent');
    this.rightSidePanelService.navigateTo('concierge');
  }

  addEmailCC() {
    this.form.controls.email.setValidators([validateEmail]);
    this.addingEmailCC = true;
  }

  @Loading('', true)
  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      if (attachment.filename !== '') {
        this.attachments.push(attachment);
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }

  getTaskDescriptionText() {
    if (this.isAddingToDoList) {
      return 'Current checklist and instructions';
    } else if (this.isAddingRoomItem) {
      return 'List of the inventory to add';
    } else if (this.isAddingPro) {
      return 'Detail about the pro(s)';
    } else if (this.type == 'support.concierge_task') {
      return 'Task description (optional)';
    } else {
      return 'Message';
    }
  }

}

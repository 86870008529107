import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AttachmentModel } from 'src/models/concierge.model';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Concierge } from 'src/providers/concierge/concierge';
import { Util } from 'src/shared/util/util';
@Component({
  selector: 'create-concierge-action-onboarding',
  templateUrl: './create-concierge-action-onboarding.html'
})
export class CreateConciergeActionOnboarding implements OnInit {

  uploadButtonTitle: string;
  submitButtonTitle: string;
  itemForm: FormGroup;
  submitted = false;
  attachments: AttachmentModel[] = [];
  errorMessage: string;

  constructor(
    public onboardingProvider: OnboardingProvider,
    private fb: FormBuilder,
    private concierge: Concierge,
    private util: Util,
  ) {
    this.itemForm = this.fb.group({
      subject: ['', Validators.required],
      body: ''
    });
  }

  ngOnInit() {
    this.uploadButtonTitle = this.getUploadButtonTitle();
    this.submitButtonTitle = this.getSubmitButtonTitle();
  }

  getUploadButtonTitle() {
    const titles = {
      'inventoryAutomation': 'Upload Your Inventory List',
      'addPros': 'Upload Your Pros List',
      'selectFindNewPros': 'Upload Your Pros List',
      'toDos': 'Upload Your To-Do List',
    }
    return titles[this.onboardingProvider.flow];
  }

  getSubmitButtonTitle() {
    const titles = {
      'inventoryAutomation': 'Save Inventory List',
      'addPros': 'Save Pros',
      'selectFindNewPros': 'Save Preferences',
      'toDos': 'Save To-Do List',
    }
    return titles[this.onboardingProvider.flow];
  }

  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      this.attachments.push(attachment);
    } catch (err) {
      console.error(err);
      this.errorMessage = err;
    }
  }

  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }

  async saveItem() {
    const data = {
      ...this.itemForm.value,
      attachments: this.attachments.map(item => item.fileKey),
      emails: []
    }
    const item = {
      data,
      type: 'support.concierge_task',
    }
    const loading = await this.util.showLoading();
    try {
      await Promise.all([
        this.concierge.addConciergeItem(item),
        this.onboardingProvider.markGoalAsCompleted(this.onboardingProvider.flow)
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      const successMessage = 'Action added';
      this.util.showSuccess(successMessage);
      loading.dismiss();
      this.onboardingProvider.goBack();
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    }
  }
}

import { Component, Input, ViewEncapsulation, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'tidy-row',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./row.component.scss'],
  template: `
    <div  [ngStyle]="myStyles" [ngClass]="activeClass">
      <ng-content ></ng-content>
    </div>`
})

export class RowComponent implements OnInit {

  @Input() align: string;
  @Input() alignLastItemRight: boolean;
  @Input() padding: string;
  myStyles: any = {};
  activeClass =
    {'row-component': true
  };
  @HostBinding('style.justify-content') justifyContent = 'inherit';

  ngOnInit() {
    this.myStyles['text-align'] = this.align;
    this.activeClass['align-last-right'] = this.alignLastItemRight;
    this.activeClass['align-center'] = this.align === 'center';

    if (this.align === 'center') {
      this.justifyContent = this.align;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Menu } from 'src/providers/menu/menu';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { Util } from "src/shared/util/util";

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddAccountNotificationPage } from '../add-account-notification/add-account-notification';
import { WebhookDetailsPage } from '../webhooks/webhook-details/webhook-details';

@Component({
  templateUrl: 'account-notification-settings.html'
})
export class AccountNotificationSettingsPage extends TimeoutableComponent implements OnInit {

  backPage: string;
  notificationSettings: any;
  salesCards: any;
  errorMessage: string;
  webhooks: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private menu: Menu,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private webhooksService: Webhooks,
    private windowService: WindowService,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Notifications');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.errorMessage = '';
      this.menu.selectedAutomation = 'account-notifications';
      this.loaded = false;
      await this.buildNotificationSettings();
      this.salesCards = this.getSalesCards();
      this.backPage = await this.getParam('backPage');
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async buildNotificationSettings() {
    this.notificationSettings = [
      {
        title: 'All Teams',
        notifications: []
      }
    ];
    this.webhooks = await this.getParam('webhooks') || await this.webhooksService.getWebhooks();
    this.webhooks.map((setting) => {
      if (setting.customer_account_team !== undefined) {
        const alreadyAddedTeam = this.notificationSettings.find((item) => item.title == setting.customer_account_team.name);
        if (alreadyAddedTeam) {
          const index = this.notificationSettings.map((setting) => setting.name.indexOf(setting.customer_account_team.name));
          this.notificationSettings[index].notifications.push(setting);
        } else {
          this.notificationSettings.push({
            title: setting.customer_account_team.name,
            notifications: [setting]
          });
        }
      } else {
        this.notificationSettings[0].notifications.push(setting);
      }
    });
    this.notificationSettings.map(async (setting) => {
      setting.notifications = await this.webhooksService.buildNotificationSettingsArray(setting.notifications);
    })
  }

  goToAddNotificationPage() {
    this.rightSidePanelService.navigateTo('add-account-notification', {}, AddAccountNotificationPage);
  }

  getSalesCards() {
    return [
      {
        title: 'Select Channel',
        text: 'TIDY can push notifications to a webhook or Slack.',
        image: 'assets/img/chain-link.svg'
      },
      {
        title: 'Choose Events',
        text: 'Select what you want to be notified about (booked jobs, messages, etc.)',
        image: 'assets/img/light-bulb.svg'
      },
      {
        title: 'Receive Notifications',
        text: 'Get instant notifications for your chosen events.',
        image: 'assets/img/paper-plane.svg'
      }
    ];
  }

  goToLearnMoreNotifications() {
    const url = "https://help.tidy.com/notifications";
    this.util.openUrl(url);
  }

  async removeEvent(event, channel) {
    this.errorMessage = '';
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Remove Event for Channel?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove',
      confirmAction: this.confirmRemoveEvent.bind(this, event.key, channel)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRemoveEvent(eventKey, channel) {
    try {
      const webhook = this.webhooks.find((webhook) => webhook.id == channel.webhook_id);
      const events = webhook.events.filter((event) => event.key !== eventKey);
      const eventsPayload = events.map((event) => event.key);
      if (eventsPayload.length == 0) {
        await this.webhooksService.deleteWebhook(channel.webhook_id);
      } else {
        const payload = {
          events: eventsPayload
        }
        await this.webhooksService.editWebhook(channel.webhook_id, payload);
      }
      await this.buildNotificationSettings();
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally{
      this.modalController.dismiss();
    }
  }

  goToWebhook(channel) {
    const webhook = this.webhooks.find((webhook) => channel.webhook_id == webhook.id);
    localStorage.setItem('webhookDescription', webhook.description);
    localStorage.setItem('webhookId', webhook.id);
    localStorage.setItem('webhookState', webhook.state);
    localStorage.setItem('webhookUrl', webhook.webhook_url);
    localStorage.setItem('webhookChannel', webhook.channel);
    localStorage.setItem('webhookTeamId', webhook?.customer_account_team?.id);
    localStorage.setItem('webhookTeamName', webhook?.customer_account_team?.name);
    localStorage.setItem('webhookEvents', this.buildWebhookEventsList(webhook.events));
    localStorage.setItem('webhookDetailsBackPage', 'account-notifications');
    this.rightSidePanelService.navigateTo('webhook-details', {}, WebhookDetailsPage);
  }

  buildWebhookEventsList(events) {
    let list = '';
    events.map((event, i) => {
      list += event.display_name;
      if (i < events.length - 1) {
        list += ', ';
      }
    })
    return list;
  }

}

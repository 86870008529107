<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'List Settings'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          *ngIf="!selectedList?.is_customer_list"
          [body]="'Note: This list is property specific so it cannot be used in other properties.'">
        </tidy-text>
        <tidy-text
          *ngIf="selectedList?.is_customer_list"
          [body]="'Note: This list is not property specific so can be used in other properties.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'List Name'"
              formControlName="title"
              [form]="form.controls.title"
              [icon]="'assets/svg/list-circle-outline.svg'"
              [activeIcon]="'assets/svg/list-circle-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter the name of the list'">
            </tidy-input>
          </tidy-row>
        </form>
        <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-image
              [src]="'assets/img/checklist.svg'"
              class='header-size'>
            </tidy-image>
            <tidy-text
              body="Require Custom Fields">
            </tidy-text>
            <div></div>
          </tidy-row>
          <tidy-toggle
            [toggled]="blockCompleteOnMissingFields"
            (toggleChanged)="blockCompleteOnMissingFields = !blockCompleteOnMissingFields">
          </tidy-toggle>
        </tidy-row>
        <tidy-row>
          <tidy-text
            body="Require the pro to enter inputs into all your custom fields to complete the job. If disabled the pro will not be required to enter any inputs but will see an alert if they are skipping any fields you indicated are required.">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [text]="'Save Changes'"
        [action]="renameList.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [actionBody]="'Delete List'"
          (action)="deleteList()"
          class="red link">
        </tidy-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

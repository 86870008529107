<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="!loaded ? '' : (cameFromAssignPro ? 'Add & Assign Pro' : 'Add Pro')"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" style="height: 100%;">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <ng-container *ngIf="!noAddressSelected">
          <tidy-row [align]="'center'" *ngIf="!address?.address_name">
            <tidy-text
              [header]="'For'"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="address?.address1"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [header]="address?.address2 !== null ? ' ' + address?.address2 : ''"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="address?.address_name" [align]="'center'">
            <tidy-text
              [header]="'For'"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="address?.address_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-alert>
          <tidy-card-button class="arrow-to-top" style="padding-bottom: 0px" (action)="goToAddConciergeTask()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Have Concierge Add For Me'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'We typically get this done in 1 business day, and email you back!'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-alert>

        <tidy-card>
          <ng-container *ngIf="hasOtherPros && !noAddressSelected">
            <tidy-row>
              <tidy-text
                [header]="'Who would you like to add?'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-radio-button
                formControlName="addType"
                [form]="form.controls.addType"
                [items]="addTypeItems"
                (optionChange)="updateRequiredFields($event)">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="!hasOtherPros || form?.controls?.addType?.value == 'new' || noAddressSelected">
            <ng-container *ngIf="noAddressSelected">
              <tidy-row>
                <tidy-text
                  [header]="'Where do you want to add this pro?'">
                </tidy-text>
              </tidy-row>
              <tidy-row [ngStyle]="{'margin-bottom': form?.value?.whereToAdd == 'select' ? '0px' : '-75px'}">
                <tidy-radio-button
                  formControlName="whereToAdd"
                  [form]="form.controls.whereToAdd"
                  [items]="whereToAddItems">
                </tidy-radio-button>
              </tidy-row>
              <tidy-row [ngStyle]="{'visibility': form?.value?.whereToAdd == 'select' ? 'visible' : 'hidden'}">
                <tidy-select
                  #tidySelect
                  [label]="'Select Properties'"
                  [items]="propertiesToAddItems"
                  [form]="form.controls.propertiesToAdd"
                  formControlName="propertiesToAdd"
                  [multiple]="true"
                  [submitted]="submitted"
                  [errorMessage]="'Please select at least one property.'">
                </tidy-select>
              </tidy-row>
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider>
                </tidy-divider>
              </tidy-row>
            </ng-container>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                *ngIf="!cameFromGetStartedPage"
                [body]="'Add someone you work with to send them requests to do your jobs.'">
              </tidy-text>
              <ng-container *ngIf="cameFromGetStartedPage">
                <tidy-text   
                  [body]="'Add someone you work with that you want to use your'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text   
                  [body]="(creditBalance | tcurrency)">
                </tidy-text><span>&nbsp;</span>
                <tidy-text   
                  [body]="'to pay.'">
                </tidy-text>
              </ng-container>
              <span>&nbsp;</span>
              <tidy-text
                class="link"
                [body]="'Learn More'"
                (action)="learnMoreAddPros()">
              </tidy-text>
            </tidy-row>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-input
                formControlName="name"
                [label]="'Their Name'"
                [form]="form.controls.name"
                [submitted]="submitted"
                [errorMessage]="'Please enter a name.'"
                [icon]="'assets/svg/person-outline.svg'"
                [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="email"
                [label]="'Their Email'"
                [form]="form.controls.email"
                type="email"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid email.'"
                [icon]="'assets/svg/mail-outline.svg'"
                [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-input-international-phone
                formControlName="phone"
                [label]="'Their Phone'"
                [form]="form.controls.phone"
                [inputMode]="'numeric'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a valid phone.'"
                [icon]="'assets/svg/phone-portrait-outline.svg'"
                [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
              </tidy-input-international-phone>
            </tidy-row>
            <tidy-row>
              <tidy-select
                #tidySelect
                [label]="'Services Offered'"
                [items]="serviceCategoryItems"
                [form]="form.controls.serviceCategories"
                formControlName="serviceCategories"
                [multiple]="true"
                [submitted]="submitted"
                [errorMessage]="'Please select at least one service.'">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="form?.controls?.addType?.value == 'other' && !noAddressSelected">
            <tidy-row>
              <tidy-select
                #tidySelect
                [label]="'Select the Pro(s) to add'"
                [items]="proItems"
                [form]="form.controls.prosToAdd"
                formControlName="prosToAdd"
                [multiple]="true"
                [submitted]="submitted"
                [errorMessage]="'Please select at least one pro to add.'">
              </tidy-select>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="cameFromAssignPro ? 'Add & Assign Pro' : (form.value.prosToAdd?.length > 1 ? 'Add Pros' : 'Add Pro')"
          [action]="submit.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-row [align]="'center'" class="extra-top-padding">
          <tidy-text
            [title]="'What Happens Next?'">
          </tidy-text>
        </tidy-row>

        <tidy-alert style="padding: 15px 15px 15px 10px" *ngFor="let card of salesCards; let last = last; let first = first">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col size="2" class="vertical-align-center">
                    <div style="margin: auto">
                      <tidy-image
                        [src]="card?.image"
                        style="width:35px">
                      </tidy-image>
                    </div>
                  </ion-col>
                  <ion-col>
                    <tidy-row>
                      <tidy-text
                        [header]="card?.title">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="card?.text">
                      </tidy-text>
                      <tidy-text
                        *ngIf="card?.title == 'We Can Help'"
                        [actionBody]="'Book Call'"
                        class="link"
                        (action)="goToBookCall()">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>
        </tidy-alert>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Util } from 'src/shared/util/util';
import { Component, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit} from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import { Addresses } from "src/providers/customer/addresses";
import { BookJob } from 'src/providers/book-job/book-job';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';
import { Requests } from 'src/providers/requests/requests';
import { StripeProvider } from 'src/providers/stripe/stripe-provider';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';
import { OnboardingFlow } from 'src/shared/enums/onboarding-flow';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'confirm-booking-page',
  styleUrls: ['./confirm-booking.scss'],
  templateUrl: './confirm-booking.html',
})

export class ConfirmBookingPage implements AfterViewInit, OnDestroy, OnInit {

  address: any;
  addressId: any;
  addressTier: any;
  buttonTitle: string;
  bookingExperiment: string;
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  cardEl: any;
  cardError: string;
  cardHandler = this.onChange.bind(this);
  currentPrice: any;
  currentSubscription: any;
  bookData;
  bookingId;
  displayPrice: any;
  flowType: string;
  planId;
  jobId: any;
  loaded: boolean;
  bookingType;
  cardDeclined = false;
  viewData;
  errorMessage;
  disableSubmit = false;
  isProRequest: boolean;
  isJobRequest: boolean;
  isLoggingJob: boolean;
  hasNonTrialPaidSubscription: any;
  priceExperiment: string;
  params: any;
  proName: string;
  proTeamName: string;
  requestDates: string;
  requestTimes: any;
  isRequestSubstitute: boolean;
  selectedServiceData: any;
  selectedPrivatePro: any;
  sameDayPrice: any;
  showSameDayTimeAlert: boolean;
  showNeedsToRaisePricesError: boolean;
  isUpdatingSubscription: boolean;
  submitted: boolean;
  subscriptionExperiment: string;
  triedOtherProsError: boolean;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private addresses: Addresses,
    public bookJob: BookJob,
    private card: Card,
    private cd: ChangeDetectorRef,
    private client: Client,
    public me: Me,
    private navCtrl: CustomNavController,
    public requests: Requests,
    private storage: TidyStorage,
    private stripeProvider: StripeProvider,
    private schedule: Schedule,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService,
    private util: Util,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadDataAndParams();
  }

  ngAfterViewInit() {
    if (!this.cardEl) {
      this.cardEl = this.stripeProvider.getCardElement();
      this.cardEl.mount(this.cardInfo.nativeElement);
      this.cardEl.addEventListener('change', this.cardHandler);
    }
  }

  async loadDataAndParams() {
    try {
      this.loaded = false;
      this.bookingType = await this.getParam('bookingType');
      this.bookingExperiment = localStorage.getItem('bookingExperiment');
      this.priceExperiment = this.bookJob.getPriceExperiment();
      this.subscriptionExperiment = this.bookJob.getSubscriptionExperiment();
      this.isUpdatingSubscription = this.navCtrl.getParam('isUpdatingSubscription', false) || localStorage.getItem('isUpdatingSubscription') == 'true';
      this.hasNonTrialPaidSubscription = this.navCtrl.getParam('hasNonTrialPaidSubscription', false) || localStorage.getItem('hasNonTrialPaidSubscription') == 'true';
      this.currentSubscription = await this.storage.retrieve('currentSubscription');
      this.flowType = await this.getParam('flowType'); //TODO fix
      if (this.flowType === OnboardingFlow.PRIVATE) {
        localStorage.setItem('firstBooking', 'true');
        this.proTeamName = localStorage.getItem('proTeamName');
        this.proName = localStorage.getItem('proName')
      } else {
        this.proName = await this.getParam('proName');
      }
      this.params = this.dialogParams || this.navCtrl.getParams(false);
      if (!this.params) {
        const storedParam = this.dialogParams || await this.storage.retrieve('confirmBookingPaymentParams');
        this.params = storedParam?.params;
      }
      this.address = await this.getParam('address');
      this.bookData = await this.getParam('bookData');
      this.requestDates = await this.getParam('requestDates');
      this.requestTimes = await this.getParam('requestTimes');
      this.planId = await this.getParam('planId');
      this.jobId = await this.getParam('jobId');
      this.isRequestSubstitute = await this.getParam('isRequestSubstitute');
      if (this.isRequestSubstitute) {
        this.rightSidePanelService.setDialogPageTitle('Confirm Request Substitute');
      } else {
        this.rightSidePanelService.setDialogPageTitle('Confirm');
      }
      this.bookingId = await this.getParam('bookingId');
      this.viewData = await this.getParam('viewData');
      this.isProRequest = await this.getParam('isProRequest');
      this.isJobRequest = await this.getParam('isJobRequest');
      this.isLoggingJob = await this.getParam('isLoggingJob');
      this.showSameDayTimeAlert = await this.getParam('showSameDayTimeAlert');
      this.addressId = await this.getParam('addressId');
      if (this.showSameDayTimeAlert) {
        this.sameDayPrice = Math.round(this.viewData.basePrice * 1.5 / 100) * 100;
      }
      this.buttonTitle = this.buildButtonTitle();
      this.selectedServiceData = await this.getParam('selectedServiceData');
      this.selectedPrivatePro = await this.getParam('selectedPrivatePro');
      this.displayPrice = this.getDisplayPrice();
      this.loaded = true;
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  getDisplayPrice() {
    const subscriptionAmount = this.isUpdatingSubscription ? this.currentSubscription.amount : 0;
    const customFlowName = null;
    const giftCodeAmount = null;
    return this.bookJob.getDisplayPriceForJob(
      this.planId,
      this.selectedServiceData,
      this.viewData?.frequency,
      true,
      this.bookingType,
      this.hasNonTrialPaidSubscription,
      this.priceExperiment,
      this.bookingExperiment,
      customFlowName,
      this.selectedPrivatePro,
      giftCodeAmount,
      subscriptionAmount);
  }

  buildButtonTitle() {
    if (this.isProRequest) {
      return 'Request Pro';
    } else if (this.isJobRequest) {
      return 'Request Job';
    } else if (this.isRequestSubstitute) {
      return 'Request Job';
    } else if (this.isLoggingJob) {
      return 'Add Job';
    } else {
      return 'Book Now';
    }
  }

  onChange({ error }) {
    this.errorMessage = error ? error.message : null;
    this.disableSubmit = false;
    this.cd.detectChanges();
  }

  goBack() {
    if (this.isRightSideContent) {
      this.rightSidePanelService.goBack();
      return;
    }
    const customBack = this.navCtrl.getParam('customBack', false);
    const redirectPage = customBack ? customBack : 'book-job';
    this.navCtrl.navigateBack(redirectPage);
  }

  @Loading('', true)
  async confirmBooking() {
    this.errorMessage = null;
    this.submitted = true;
    this.storage.delete('tasksLastSavedAt');
    if (this.cardDeclined) {
      return await this.handleDeclinedCard();
    }
    if (this.isUpdatingSubscription) {
      const payload = {
        plan_type_key: this.currentSubscription.key
      }
      await this.me.changeSubscription(payload);
    }
    localStorage.setItem('isUpdatingSubscription', 'false');
    this.storage.delete('moreDetailAddresses');
    localStorage.setItem('firstBooking', 'false');
    if (this.isProRequest) {
      await this.addEditProRequest();
    } else if (this.isJobRequest) {
      await this.addEditJobRequest();
    } else if (this.isRequestSubstitute) {
      await this.jobSubstituteRequest();
    } else {
      await this.addEditJobPlan();
    }
    await this.storage.save('isFirstBooking', false);
    await this.storage.save('cartMetadata', null);
  }

  navigateToRouteOrRightSidePanel(params?: any): void {
    if (this.isRightSideContent) {
      this.rightSidePanelService.closeRightSidePanel();
      const successHeader = params?.header ? params.header : 'Success';
      const successBody = params?.body ? params.body : 'Successfully booked your job!';
      const successMessage = successHeader + '<br>' + successBody;
      this.util.showSuccess(successMessage);
      return;
    }
    const url = 'success';
    const component = SuccessPage;
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    params['buttonRoute'] = shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list';
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  @Loading('', true)
  async addEditProRequest() {
    try {
      const payload = await this.getParam('payload');
      this.schedule.addSkeletonJobToCalendar(payload.request_time_blocks[0].date, payload.request_time_blocks[0].times[0].start_time, this.addressId);
      await this.requests.addProRequest(this.addressId, payload);
      const availabilityRequestId = await this.getParam('availabilityRequestId');
      if (availabilityRequestId) {
        await this.requests.deleteProRequest(this.addressId, availabilityRequestId);
      }
      const proName = await this.getParam('proName');
      const params = {
        header: proName + ' Requested',
        body: 'We will notify you right away when a pro responds.'
      }
      this.navigateToRouteOrRightSidePanel(params);
    } catch(err) {
      this.schedule.removeSkeletonJobFromCalendar();
      this.disableSubmit = true;
      this.catchErrors(err);
    }
  }

  @Loading('', true)
  async addEditJobRequest() {
    try {
      this.schedule.addSkeletonJobToCalendar(this.bookData.booking.start_date_no_earlier_than, this.bookData.booking.end_date_no_later_than, this.bookData.address_id);
      if (this.bookingId) {
        await this.schedule.cancelJob(this.bookingId);
      }
      await this.requests.addJobRequest(this.addressId, this.bookData);
      const params = {
        header: 'Job Requested',
        body: 'We will notify you when a Pro responds.'
      }
      this.navigateToRouteOrRightSidePanel(params);
    } catch(err) {
      this.schedule.removeSkeletonJobFromCalendar();
      this.disableSubmit = true;
      this.catchErrors(err);
    }
  }

  async jobSubstituteRequest() {
    try {
      const data = this.prepareRequestSubData();
      await this.requests.addRequestSubstitute(data, this.jobId);
      const params = {
        header: 'Substitute Requested',
        body: 'We will notify you when they respond.'
      }
      this.navigateToRouteOrRightSidePanel(params);
    } catch (err) {
      this.disableSubmit = true;
      this.catchErrors(err);
    }
  }

  @Loading('', true)
  async addEditJobPlan() {
    const date = this.bookData?.booking ? this.bookData.booking.preferred_start_date : this.bookData.dates[0].date;
    const time = this.bookData?.booking ? this.bookData.booking.preferred_start_date : this.bookData.dates[0].time;
    this.schedule.addSkeletonJobToCalendar(date, time, this.bookData.address_id);
    this.showNeedsToRaisePricesError = false;
    try {
      const shouldInsteadAddJob = await this.getParam('shouldInsteadAddJob');

      const isBookingOneTimePlan = this.bookingType == 'add_job' && !this.bookData.frequency;
      if (shouldInsteadAddJob || this.isLoggingJob || isBookingOneTimePlan) {
        this.bookingType = 'add_one_time_job';
      }
      await this.bookJob.saveJob(this.bookingType, this.bookData, this.planId, this.bookingId);
      const serviceText = new TitleCasePipe().transform(this.viewData.service); const params = {
        header: new TranslationPipe().transform(serviceText) + ' ' + new TranslationPipe().transform('Booked'),
        body: `${this.viewData.frequency}<br>${this.viewData.dateTime}`
      };
      this.navigateToRouteOrRightSidePanel(params);
    } catch(err) {
      this.schedule.removeSkeletonJobFromCalendar();
      this.disableSubmit = true;
      this.catchErrors(err);
    }
  }

  addEditJobPlanWithAnyPro() {
    this.errorMessage = '';
    try {
      this.bookData.hk_rating = "any_rating";
      this.addEditJobPlan();
    } catch(err) {
      this.catchErrors(err);
    }
  }

  async addEditJobPlanRaisePrices() {
    this.errorMessage = '';
    this.triedOtherProsError = false;
    try {
      const payload = {
        tier_id: this.addressTier.tier_id,
      }
      await this.addresses.updateAddressTier(this.addressId, payload);
      this.addEditJobPlan();
    } catch(err) {
      this.catchErrors(err);
    }
  }

  prepareRequestSubData() {
    const data = {times: []};
    this.requestTimes.map(item => {
      item.times.map(time => {
        data.times.push({date: time.cleaning.date, start_time: time.cleaning.start_time})
      })
    });
    return data;
  }

  async handleDeclinedCard() {
    const { token, error } = await this.stripeProvider.getInstance().createToken(this.cardEl);
    if (error) {
      return this.cardError = 'Your card was declined again. Please try another card: ';
    } else {
      try {
        await this.card.saveCard({
          type: 'card',
          stripe_card_token: token.id,
          primary_payment_method: true,
        });
        this.cardDeclined = false;
        this.disableSubmit = false;
      } catch (err) {
        return this.cardError = 'Your card was declined again. Please try another card: ';
      }
    }
  }

  async catchErrors(err) {
    try {
      console.log(err);
      if (err?.message?.includes('Rates are not up to date, please try again in some seconds')) {
        if (this.showNeedsToRaisePricesError) {
          return this.triedOtherProsError = true;
        }
        this.disableSubmit = true;
        const serviceTypeKey = await this.getParam('serviceTypeKey');
        const homekeeperId = await this.getParam('homekeeperId');
        this.addressTier = await this.addresses.getAddressTier(this.addressId, serviceTypeKey, homekeeperId);
        this.showNeedsToRaisePricesError = true;
      } else if (err.code === 402) {
        this.cardDeclined = true;
        this.cardError = 'Your card was declined. Please update your card here: ';
        this.buttonTitle = 'Save & Book';
      } else {
        this.errorMessage = err.error ? err.error.message : err.message;
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async updateSubscription() {
    this.isUpdatingSubscription = !this.isUpdatingSubscription;
    localStorage.setItem('isUpdatingSubscription', this.isUpdatingSubscription ? 'true' : 'false');
    this.hasNonTrialPaidSubscription = !this.hasNonTrialPaidSubscription;
    if (this.isUpdatingSubscription) {
      localStorage.setItem('hasNonTrialPaidSubscription', 'true');
      const tidyPlusSubscription = await this.storage.retrieve('tidyPlusSubscription');
      this.currentSubscription = tidyPlusSubscription;
      this.storage.save('currentSubscription', tidyPlusSubscription);
    } else {
      localStorage.setItem('hasNonTrialPaidSubscription', 'false');
      this.storage.delete('currentSubscription');
      this.currentSubscription.amount = 0;
    }
    this.displayPrice = this.getDisplayPrice();
  }

  async goToViewSubscriptions() {
    const confirmBookingPaymentParams = {
      selectedServiceData: this.selectedServiceData,
      bookingType: this.bookingType,
      isUpdatingSubscription: this.isUpdatingSubscription,
      hasNonTrialPaidSubscription: this.hasNonTrialPaidSubscription,
      viewData: this.viewData,
      params: this.params,
      bookData: this.bookData,
      requestDates: this.requestDates,
      showSameDayTimeAlert: this.showSameDayTimeAlert,
      planId: this.planId,
      bookingId: this.bookingId,
      isProRequest: this.isProRequest,
      isJobRequest: this.isJobRequest,
      selectedPrivatePro: this.selectedPrivatePro,
      shouldInsteadAddJob: await this.getParam('shouldInsteadAddJob'),
      addressId: await this.getParam('addressId'),
      payload: await this.getParam('payload'),
      availabilityRequestId: await this.getParam('availabilityRequestId')
    }
    this.storage.save('confirmBookingPaymentParams', confirmBookingPaymentParams);
    const params = {
      isBookingJob: true,
      nextBookingPage: 'confirm-booking'
    }
    this.navCtrl.navigateForward('select-subscription', params);
  }

  async getParam(param) {
    const navParam = this.navCtrl.getParam(param, false);
    if (navParam) {
      return navParam;
    } else {
      const storedParam = this.dialogParams || await this.storage.retrieve('confirmBookingPaymentParams')
      return storedParam?.[param];
    }
  }

  ngOnDestroy() {
    this.cardEl?.removeEventListener('change', this.cardHandler);
    this.cardEl?.destroy();
  }

}

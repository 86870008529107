<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Integrations'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Integrate with an HRIS system if you want to sync your list of employees into TIDY for easier management.'">
        </tidy-text>
      </tidy-row>

      <tidy-button
        [text]="'Add Integrations'"
        class="primary"
        [action]="openMergeLink.bind(this)">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

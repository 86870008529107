export const roomTypeSelect = [
  {
    viewValue: 'Bedroom',
    value: 'bedroom'
  },
  {
    viewValue: 'Bathroom',
    value: 'bathroom'
  },
  {
    viewValue: 'Back Yard',
    value: 'backyard'
  },
  {
    viewValue: 'Living Room / Family Room',
    value: 'living_room'
  },
  {
    viewValue: 'Front Yard',
    value: 'front_yard'
  },
  {
    viewValue: 'Dining Room',
    value: 'dining_room'
  },
  {
    viewValue: 'Stairwell',
    value: 'stairwell'
  },
  {
    viewValue: 'Hallway',
    value: 'hallway'
  },
  {
    viewValue: 'Kitchen',
    value: 'kitchen'
  },
  {
    viewValue: 'Garage',
    value: 'garage'
  },
  {
    viewValue: 'Laundry',
    value: 'laundry'
  },
  {
    viewValue: 'Office',
    value: 'office'
  },
  {
    viewValue: 'Patio',
    value: 'patio'
  }
]

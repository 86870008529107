import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Addresses } from 'src/providers/customer/addresses';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { RoomModel } from 'src/models/to-do.model';
import { ScheduleCardModel } from 'src/models/schedule.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ParseDatePipe } from 'src/shared/pipes/parse-date.pipe';

@Component({
  templateUrl: 'apply-to-job.html'
})

export class ToDosApplyToJobPage implements OnInit {

  addressId: number;
  categoryImage: string;
  form: UntypedFormGroup;
  job: ScheduleCardModel;
  rooms: RoomModel[];
  saving: boolean;
  toDoFilter: TidySelectNumberValueModel[];
  toDoState: string;
  usingToDos: boolean;
  videoInspection: string;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private addresses: Addresses,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private storage: TidyStorage,
    private toDos: ToDos,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      list: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('To-Dos');
    this.loadData();
  }

  @Loading('', true)
  async loadData() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.loaded = false;
      await this.getParams();
      await this.getLists();
      await this.populateListSelect();
      await this.getRooms();
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
    }
  }

  async getParams() {
    const addressId = localStorage.getItem('addressId');
    this.addressId = parseInt(addressId);
    this.job = await this.getParam('job');
    this.rightSidePanelService.setDialogPageTitle('To-Dos for ' + (new ParseDatePipe().transform(this.job?.template_data?.job?.start_datetime_local, 'M/D')));
    this.categoryImage = await this.getParam('categoryImage');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getLists() {
    const toDoLists = await this.toDos.getAllToDoListsForAddressAndServiceDefaults(this.addressId, this.job.template_data.job.service_type_details.key);
    this.toDoFilter = await this.toDos.parseToDoFilterItems(toDoLists, this.addressId);
    this.toDoFilter.pop();
    this.toDoFilter.push({
      viewValue: 'Don\'t Use To-Dos',
      value: -2
    });
  }

  populateListSelect() {
    if (this.job.template_data.job.task_list.title !== null) {
      this.usingToDos = true;
      this.form.patchValue({
        list: this.job.template_data.job.task_list.id
      });
    } else {
      this.usingToDos = false;
      this.form.patchValue({
        list: -2
      });
    }
  }

  async getRooms() {
    if (this.usingToDos) {
      const rooms = await this.toDos.getListDetail(this.job.template_data.job.task_list.id);
      this.rooms = this.toDos.buildRoomIcons(rooms);
      this.videoInspection = await this.toDos.getVideoInspectionSettings(this.rooms);
    }
  }

  @Loading('', true)
  async saveList() {
    this.storage.delete('addresses');
    this.saving = true;
    this.usingToDos = this.form.value.list !== -2;
    const jobId = [this.job.template_data.job.id];
    const listID = this.form.value.list === -2 ? null : this.form.value.list;
    await this.toDos.assignListToJob(jobId, listID);
    if (this.usingToDos) {
      const rooms = await this.toDos.getListDetail(this.form.value.list);
      this.rooms = this.toDos.buildRoomIcons(rooms);
    }
    this.saving = false;
  }

  goToRoom(room) {
    localStorage.setItem('roomId', room.id);
    const params = {
      room: room,
      rooms: this.rooms
    }
    this.navCtrl.navigateForward('room', params);
  }
}

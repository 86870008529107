<tidy-error-message
  *ngIf="errorMessage"
  [text]="errorMessage">
</tidy-error-message>

<ng-container *ngFor="let item of genomeItems">
  <tidy-card>
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/light-bulb.svg'"
        class="header-size">
      </tidy-image>
      <tidy-text
        [header]="item.header_text"
        style="padding-top: 4px">
      </tidy-text>
    </tidy-row>
    <ng-container *ngFor="let body of item.components">
      <tidy-row *ngIf="body?.key === 'text'" class="extra-bottom-padding">
        <tidy-text
          [body]="body.value">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="body?.key === 'multiline_text'" class="extra-bottom-padding">
        <ng-container *ngFor="let value of body.value">
          <tidy-text
            [body]="value.html">
          </tidy-text>
        </ng-container>
      </tidy-row>
    </ng-container>

    <form [formGroup]="form">
      <ng-container *ngFor="let component of item.components">
        <ng-container [ngSwitch]="component.key">
          <ng-container *ngSwitchCase="'grouped_action_buttons'">
            <ng-container *ngFor="let componentValue of component.value">
              <ng-container *ngIf="componentValue.action === 'mark_suggestion_as_done'">
                <tidy-button
                  [action]="submitAction.bind(this, item, component, componentValue)"
                  [text]="componentValue.value"
                  class="primary">
                </tidy-button>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let componentValue of component.value">
              <ng-container *ngIf="componentValue.action === 'hide_suggestion_for_customer'">
                <tidy-row [align]="'center'" class="extra-top-padding">
                  <tidy-text
                    class="link"
                    [body]="componentValue.value"
                    (action)="submitAction(item, component, componentValue)">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'input'">
            <ng-container [ngSwitch]="component.value.input_type">
              <ng-container *ngSwitchCase="'radio'">
                <tidy-row class="extra-bottom-padding">
                  <tidy-radio-button
                    formControlName="action"
                    [form]="form.controls.action"
                    [items]="component.value.input_values"
                    class="horizontal-alignment no-wrap">
                  </tidy-radio-button>
                </tidy-row>
                <tidy-row>
                  <tidy-button
                    [action]="submitAction.bind(this, item, component)"
                    [text]="'Submit'"
                    class="primary">
                  </tidy-button>
                </tidy-row>
              </ng-container>
              <ng-container *ngSwitchCase="'select'">
                <tidy-row class="field-top-padding extra-bottom-padding">
                  <tidy-select
                    [label]="'Select'"
                    [form]="form.controls.action"
                    formControlName="action"
                    [items]="component.value.input_values">
                  </tidy-select>
                </tidy-row>
                <tidy-row>
                  <tidy-button
                    [action]="submitAction.bind(this, item, component)"
                    [text]="'Submit'"
                    class="primary">
                  </tidy-button>
                </tidy-row>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <tidy-row class="extra-bottom-padding">
                  <ng-container *ngFor="let key of component.value.input_values">
                    <tidy-checkbox
                      [form]="form.controls.action"
                      (click)="setCheckboxValue(key)"
                      [items]="[{viewValue: key.viewValue , value: key.value}]">
                    </tidy-checkbox>
                  </ng-container>
                </tidy-row>
                <tidy-row>
                  <tidy-button
                    [action]="submitAction.bind(this, item, component)"
                    [text]="'Submit'"
                    class="primary">
                  </tidy-button>
                </tidy-row>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <div class="input-button-row field-top-padding">
                  <tidy-input
                    formControlName="action"
                    [label]="component?.value?.placeholder"
                    [form]="form"
                    errorMessage="Invalid Entry"
                    [submitted]="submitted"
                    style="width: 100%; padding: 0px 10px 0px 0px;">
                  </tidy-input>
                  <tidy-button
                    [action]="submitAction.bind(this, item, component)"
                    [text]="'Submit'"
                    [smallText]="true"
                    class="no-padding">
                  </tidy-button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                <div class="input-button-row field-top-padding">
                  <tidy-input
                    formControlName="action"
                    [label]="component?.value?.placeholder"
                    [form]="form"
                    errorMessage="Please enter the amount"
                    [submitted]="submitted"
                    inputMode="numeric"
                    [mask]="'number'"
                    style="width: 100%; padding: 0px 10px 0px 0px;">
                  </tidy-input>
                  <tidy-button
                    [action]="submitAction.bind(this, item, component)"
                    [text]="'Submit'"
                    [smallText]="true"
                    class="no-padding">
                  </tidy-button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'link'">
            <tidy-card-button (action)="genomeRedirect(component.value.url)">
              <tidy-text
                [body]="component.value.label">
              </tidy-text>
            </tidy-card-button>
          </ng-container>

        </ng-container>
      </ng-container>
    </form>

  </tidy-card>
</ng-container>

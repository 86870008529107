import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Util } from 'src/shared/util/util';
import { Auth } from 'src/providers/auth/auth';
import { AfterLoginRedirect } from 'src/providers/auth/afterLoginRedirect';
import { Me } from 'src/providers/me/me';
import { CookieProvider } from 'src/providers/cookie/cookie';
import { Loading } from "src/shared/components/loading/loading";
import { ActivatedRoute } from '@angular/router';
import { validateEmail } from 'src/shared/validator/validateEmail';
import { Events } from 'src/providers/events/events';

import { privateProSignUpDataModel } from 'src/models/private-pro-signup';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { OneSignalNotification } from 'src/shared/providers/one-signal-notification';
import { HubspotFieldIds, HubspotFormIds } from 'src/shared/enums/hubspot.enum';
import { Client } from 'src/providers/client/client';

@Component({
  selector: 'page-login',
  templateUrl: 'log-in.html',
  encapsulation: ViewEncapsulation.None
})

export class LoginPage implements OnInit {

  loginForm: UntypedFormGroup;
  isLoading: Boolean;
  errorMessage: String;
  proData: privateProSignUpDataModel;
  submitted: Boolean = false;
  wrongAppError: boolean;
  wrongLoginError: boolean;
  utmzTidy: any;
  inputIds: any = HubspotFieldIds;
  formIds: any = HubspotFormIds;

  constructor (
    private _fb: UntypedFormBuilder,
    private auth: Auth,
    private me: Me,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private cookieProvider: CookieProvider,
    private events: Events,
    private afterLoginRedirect: AfterLoginRedirect,
    private util: Util,
    private storage: TidyStorage,
    private oneSignalNotification: OneSignalNotification,
    private client: Client
  ) {
    this.loginForm = this._fb.group({
      email: ['', Validators.compose([Validators.required, validateEmail])],
      password: ['', Validators.required]
    });

    this.isLoading = false;
  }


  @Loading('Loading...')
  async ngOnInit() {
    this.utmzTidy = this.cookieProvider.getCookie('__utmztidy');
    this.storage.clear();
    localStorage.clear();

    this.proData = {
      hkId: this.route.snapshot.paramMap.get('hkId')
    };
    const auth_token = this.route.snapshot.paramMap.get('token')
    if (auth_token) {
      try {
        await this.auth.login({ auth_token }, 'login', this.proData);
        await this.loadAccount();
      } catch (err) {
        if (err.status === 404) {
          this.errorMessage = 'This link is expired, please try again';
        }
      }
    }
  }

  async onSubmit(): Promise<any> {
    this.submitted = true;
    this.errorMessage = '';
    this.wrongAppError = false;
    this.wrongLoginError = false;
    if (this.loginForm.valid) {
      this.isLoading = true;
      const credentials = this.buildCredentials();
      try {
        await this.auth.login(credentials, 'login', this.proData);
        await this.loadAccount();

      } catch (err) {
        this.isLoading = false;
        this.displayError(err);
      }
    }
  }

  displayError(err): void {
    if (err.err && err.err.error === 'wrong_app') {
      this.wrongAppError = true;
    } else {
      this.wrongLoginError = true;
    }
  }

  buildCredentials(): any {
    return {
      username: this.loginForm.value.email,
      password: this.loginForm.value.password,
      date_added_to_cookie: this.utmzTidy ? this.utmzTidy.dateTime : '',
      utm_source: this.utmzTidy ? this.utmzTidy.source : ''
    };
  }

  loadAccount() {
    return this.me.load(true).then(async account => {
      const customerObj: any = account;
      this.auth.setAccountTypeStorage(customerObj.customer_account.account_type);
      const bookingId = this.navCtrl.getParam('bookingId');
      const target = await this.afterLoginRedirect.getTarget(bookingId);
      await this.auth.setSession();
      this.goToTarget(target);
      this.oneSignalNotification.setUpNotificationUser(account.current_user.id);
      const bookingExperiment = this.getBookingExperiment(customerObj);
      localStorage.setItem('bookingExperiment', bookingExperiment);
    });
  }

  getBookingExperiment(customerObj): string {
    let flag = '';
    const idString = customerObj.customer_account.id.toString();
    let lastDigit = idString[idString.length - 1];
    if (customerObj.customer_account.account_type === 'rental') {
      if (lastDigit == 0 || lastDigit == 1 || lastDigit == 2) {
        return 'rental-control';
      } else if (lastDigit == 3 || lastDigit == 4) {
        return 'rental-v2a';
      } else if (lastDigit == 5 || lastDigit == 6) {
        return 'rental-v2b';
      } else if (lastDigit == 7 || lastDigit == 8 || lastDigit == 9) {
        return 'rental-v3';
      }
    } else {
      if (lastDigit == 0 || lastDigit == 1 || lastDigit == 2 || lastDigit == 3) {
        return 'consumer-control';
      } else if (lastDigit == 4 || lastDigit == 5 || lastDigit == 6) {
        return 'consumer-test1';
      } else if (lastDigit == 7 || lastDigit == 8 || lastDigit == 9) {
        return 'consumer-test2';
      }
    }
    return flag;
  }

  pushToForgotPasswordPage() {
    this.navCtrl.navigateForward('reset-password');
  }

  pushToForgotUsernamePage() {
    this.navCtrl.navigateForward('forgot-username');
  }

  goToSignUpPage() {
    this.navCtrl.navigateForward('create-account');
  }

  goToTarget(target) {
    if (target.toPublish) {
      this.events.publish('address:changed', target.toPublish);
    }

    if (target.data) {
      this.navCtrl.navigateForward(target.page, target.data);
    } else {
      this.navCtrl.navigateForward(target.page);
    }
  }

  goToTIDYForPros() {
    const url = 'https://pro.tidy.com/#/login';
    this.util.openUrl(url);
  }
  
}

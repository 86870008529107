import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AfterLoginRedirect } from '../auth/afterLoginRedirect';
import { Auth } from '../auth/auth';
import { CustomNavController } from '../../shared/providers/navigation/custom-nav-controller';
import { Events } from '../events/events';
import { Logger } from '../logger';
import { Me } from '../me/me';

@Injectable({
  providedIn: 'root'
})
export class IntroductionGuard implements CanActivate {
  constructor(
    private afterLoginRedirect: AfterLoginRedirect,
    private auth: Auth,
    private navCtrl: CustomNavController,
    private events: Events,
    private logger: Logger,
    private me: Me
  ) {}

  async canActivate(): Promise<boolean> {
    const cameFromBookingLink = localStorage.getItem('cameFromBookingLink') == 'true';
    if (cameFromBookingLink) {
      //When user goes to path `booking/:bookingId` it is triggering this introduction guard. I don't know why. This is a simple solution to avoid that.
      localStorage.clearItem('cameFromBookingLink');
      return true;
    }
    const urlSearch = window.location.search;
    const searchParams = new URLSearchParams(urlSearch);
    const integrationType = searchParams.get('integrationType');
    if (integrationType) {
      const code = searchParams.get('code');
      this.navCtrl.navigateForward(`authorization/${integrationType}?code=${code}`);
      return false;
    }
    if (this.auth.isAuthenticated()) {
      try {
        const response = await this.me.load();
        if (Object.keys(response).length > 0) {
          const target = await this.afterLoginRedirect.getTarget();
          this.goToTarget(target);
          return false;
        }
      } catch (e) {
        this.logger.error(e)
      }
    }
    return true;
  }

  goToTarget(target) {
    if (target.toPublish) {
      this.events.publish('address:changed', target.toPublish);
    }
    this.navCtrl.navigateForward(target.page, target.data);
  }
}

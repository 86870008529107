<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Getting Started with TIDY for Employers'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Helping you provide janitorial services or cleaning as a beneift to your employees.'">
      </tidy-text>
    </tidy-row>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToPage('employer-integration/employer-integration')">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/integration.svg'"
                    [style]="'width: 50px'">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Step 1: HRIS Integration'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Choose your integration (optional) or give a list to us.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToPage('employer-campaign/employer-campaign')" style="border-bottom: 0px;">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/budget.svg'"
                    [style]="'width: 50px'">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Step 2: Pick Campaign Budget & Distribution'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Decide how much you want to spend on the campaign, and pick your preferred method of distributing the cleaning credits:'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
      <tidy-card-button [hideArrow]="true">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [body]="'<u>Per-Employee (most common)</u>.
                    This can be set one time, monthly, quarterly, or annually.
                    For example, you can give employees a $100/month budget.
                    Set restrictions, such as 30 hours a week or remote only.
                    You define your rules, and we help ensure its administered right.
                    Credits are sent directly to employees which they can use right away.
                    Once they link it to their TIDY account, the credit automatically gets added.'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'<u>Custom Campaign</u>.
                    You can control the increments of the credits, and budget them out.
                    For example, you can generate credits of $1,000 for 2 employees and $100 for 20 employees.
                   To do this, you let us know how you want things done, and we will send you codes with the amount you specify, to be used by your employees.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToPage('employer-campaign/employer-campaign')">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/paper-plane.svg'"
                    [style]="'width: 45px'">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Step 3: Invoice & Start Campaign'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'We send you an invoice, pay it via ACH or wire transfer, then your campaign will start.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToPage('employer-campaign/employer-campaign')">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/bag.svg'"
                    [style]="'width: 50px'">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Step 4: Tracking Tax Benefit Compliance'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'If you are giving this as a tax advantaged benefit, then with each employee we make it easy for them to specify which of their space is for work.
                    We ensure its clear on receipts which of the benefit was for workplace janitorial purposes, this should be 100% tax deductible and pre-tax for your employees.
                    Talk to your tax advisor for more questions.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToPage('employer-campaign/employer-campaign')">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/report.svg'"
                    [style]="'width: 50px'">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Step 5: Reporting & Compliance'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Generate reports both for benefit usage and accounting purposes.
                    We offer a wide range of reports, custom to your needs.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tidySize]'
})
export class TidySizeDirective implements AfterViewInit {

  constructor(
    private el: ElementRef,
    private renderer2: Renderer2
    ) { }

  @Input() tidySize = '';
  @Input() breakOffset = 1050;
  readonly windowWidth = window.innerWidth;

  ngAfterViewInit() {
    this.render(this.windowWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.render(event?.target?.innerWidth);
  }

  render(wWidth) {
    if (!this.el) {
      return;
    }
    if (wWidth < this.breakOffset) {
      this.renderer2.setStyle(this.el.nativeElement, 'width', 'inherit');
      this.renderer2.setStyle(this.el.nativeElement, 'max-width', 'inherit');
    } else {
      const sizeCalc = `calc(calc(${this.tidySize} / 10) * 100%)`;

      ['width', 'max-width', 'flex'].forEach(attr => {
        this.renderer2.setStyle(this.el.nativeElement, attr, sizeCalc);
      });
    }
  }

}

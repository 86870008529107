<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="editingEmployee?.source_user_first_name + ' ' + editingEmployee?.source_user_last_name">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            (action)="goToEditEmployeeDetailsPage()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="editingEmployee?.source_user_email">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <tidy-text
          [header]="'Credits'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let credit of employeeBalance?.transfers">
        <tidy-card *ngIf="(credit?.amount_sent - credit?.amount_used - credit?.amount_cancelled) > 0">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/img/salary.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="((credit?.amount_sent - credit?.amount_used - credit?.amount_cancelled) | tcurrency) + ' remaining of ' + (credit?.amount_sent | tcurrency) + ' credit'">
            </tidy-text>
            <tidy-text
              [body]="'Remove'"
              (action)="removeCredit(credit)"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [helper]="'Added on ' + (credit?.sent_at | parseDate: 'M/D')">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="employeeBalance?.lifetime_amount_sent - employeeBalance?.lifetime_amount_used - employeeBalance?.lifetime_amount_cancelled == 0">
        <tidy-row>
          <tidy-text
            [body]="'None'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-button
        [action]="goToAddEmployeeCreditPage.bind(this)"
        [text]="'Add Credit'"
        class="primary"
        [disabled]="companyCredit <= 0">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Delete Employee'"
          (action)="deleteEmployee()"
          class="red link">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="companyCredit > 0">
        <tidy-text
          [body]="'You currently have ' + (companyCredit | tcurrency) + ' credit you can add for employees.'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="companyCredit <= 0">
        <tidy-text
          [body]="'You currently have $0 credit. Please add more credit to your account to add for employees. '">
        </tidy-text>
        <tidy-text
          [body]="'Add Credit'"
          class="link"
          (action)="goToAddCredit()">
        </tidy-text>
      </tidy-row>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ion-content class="ion-content-right-column">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>
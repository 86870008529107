import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { PlanData } from '../plans/plan-data';
import { environment } from 'src/environments/environment';

import { ClientModel } from 'src/models/client.model';
import { HttpParams } from '@angular/common/http';
import { Events } from '../events/events';

import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

import { Client } from 'src/providers/client/client';

declare const dataLayer: any;

@Injectable()
export class Me {

  constructor(
    private client: Client,
    private httpClient: HttpClientCustom,
    private planData: PlanData,
    private storage: TidyStorage,
    private events: Events,
    private onboardingProvider: OnboardingProvider
  ) { }

  async load(getFromBackend = false): Promise<ClientModel> {
    const data = await this.getMyData(getFromBackend);
    const stripeData: any = await this.planData.getStripeInfo()
    let firstBooking = data?.customer_account?.first_booking;
    //TODO remove setting first booking with stripeInfo once old SBflow gets removed
    if (stripeData?.status === 'invalid') {
      firstBooking = true;
    }
    localStorage.setItem('isRentalClient', data?.customer_account?.account_type == 'rental' ? 'true' : 'false');
    localStorage.setItem('accountType', data?.customer_account?.account_type);
    localStorage.setItem('advancedMode', data?.customer_account?.advanced_mode ? 'true' : 'false');
    this.setCustomerSetting('custom.background', data?.customer_account?.white_label_data?.background);
    this.setCustomerSetting('custom.logo', data?.customer_account?.white_label_data?.logo);
    localStorage.setItem('firstBooking', firstBooking);
    localStorage.setItem('user_role', data?.current_user?.role);
    localStorage.setItem('customer_id', data?.customer_account?.id);
    localStorage.setItem('customer_name', data?.current_user?.name);
    localStorage.setItem('customer_email', data?.current_user?.email);
    if (data?.customer_account.account_name !== '' && data?.customer_account?.account_name !== null) {
      localStorage.setItem('companyName', data?.customer_account?.account_name);
    }
    this.events.publish("updateMenu");
    this.events.publish("updateBackground");
    if (data?.customer_account?.account_type == 'rental') {
      this.getOnboardingGoals(data);
    }
    return data;
  }

  async getOnboardingGoals(data) {
    if (data?.customer_account?.hide_onboarding) {
      this.storage.save('hideOnboarding', true);
    } else {
      const didJustSignUp = await this.storage.retrieve('didJustSignUp');
      const serverOnboardingGoals = await this.storage.retrieve('onboardingGoals');
      if (!didJustSignUp && !serverOnboardingGoals) {
        await this.onboardingProvider.getGoalsFromServer();
        await this.onboardingProvider.getUnchosenGoalsFromServer();
      }
    }
  }

  private setCustomerSetting(key: string, value: any) {
    if (!value) return localStorage.removeItem(key);

    localStorage.setItem(key, value);
  }

  async getAccType(): Promise<string> {
    const accType = localStorage.getItem('accountType');
    if (accType) {
      return accType;
    }

    await this.load();
    return localStorage.getItem('accountType');
  }

  sendFeedback(data, jobId): Promise<any> {
    const url = `api/v1/me/jobs/${jobId}/feedback`;

    return this.httpClient.post(url, data);
  }

  async getMyData(getFromBackend = false): Promise<any> {
    if (!getFromBackend) {
      const storedData = await this.storage.retrieve('me');
      if (storedData) {
        return storedData;
      }
    }
    try {
      const currentLanguage = await this.client.getMetadata(['external.language']);
      const language = currentLanguage?.[0]?.value || 'en';
      localStorage.setItem('language', language);
    } catch(err) {
      localStorage.setItem('language', 'en');
    }
    const data = await this.httpClient.get('api/v1/customer/me?includes=permissions');
    this.storage.save('me', data);
    return data;
  }

  alreadyCancelledCleaningWithin24hours(): Promise<any> {
    return this.httpClient.get('api/v1/customer/me?includes=already_cancelled_cleaning_within_24hours');
  }

  getName() {
    return localStorage.getItem('customer_name');
  }

  async getMetadata(key){
    const query = new HttpParams({fromObject: {keys:[`${key}`]}}).toString();
    const url = `api/v1/customer/metadata?${query}`;
    return await this.httpClient.get(url);
  }

  async shareContent() {
    const me = await this.getMyData();
    const firstName = me.name.split(" ")[0];
    const referralCode = me.referral_code;
    const appUrl = environment.customer_portal;

    const url = `${appUrl}#/booking-signup/signUp/${referralCode}`;

    const message = `${firstName} has given you a FREE $10 credit for a TIDY home cleaning. To claim your gift, sign up using this link: ${url}`

    return {message, url};
  }

  getSubscriptionChangePreview(newPlanTypeKey) {
    const url = `api/v1/customer/subscription/preview-plan-change-balance?new_plan_type_key=${newPlanTypeKey}`;
    return this.httpClient.get(url);
  }


  getSubscriptions() {
    const url = 'api/v1/customer/subscription/subscriptions?includes=customer_address_count';
    return this.httpClient.get(url);
  }

  getCurrentSubscription(subscriptions) {
    let currentSubscription = null;
    if (subscriptions.length > 1) {
      subscriptions.map((subscription) => {
        if (subscription.is_activated_in_next_cycle) {
          currentSubscription = subscription;
        }
      });
    } else {
      currentSubscription = subscriptions[0];
    }
    return currentSubscription;
  }

  getSubscriptionTypes() {
    const url = 'api/v1/customer/subscription/plan-types';
    return this.httpClient.get(url);
  }

  changeSubscription(data) {
    const url = 'api/v1/customer/subscriptions/subscribe';
    return this.httpClient.put(url, data);
  }

  getSubscriptionPreview(planTypeKeys) {
    const planTypeKey = 'customer_app_yearly_subscription_1';
    const url = `api/v1/customer/subscription/preview-plan-change-balance?new_plan_type_key=${planTypeKey}&includes=usage`;
    return this.httpClient.get(url);
  }

  getSubscriptionInvoices() {
    const url = 'api/v1/customer/subscription/subscription-invoices';
    return this.httpClient.get(url);
  }

  getBookingFormSelectValue(data) {
    const keys = Object.keys(data);
    return data[keys[0]];
  }

  getBookingFormMultiselectValue(data) {
    let string = '';
    data.map((item, i) => {
      const keys = Object.keys(item);
      string += item[keys[0]];
      if (i < data.length - 1) {
        string += ', ';
      }
    });
    return string;
  }

  endSubscriptionFreeTrial(subscriptionPlanId) {
    const payload = {
      plan_subscription_id: subscriptionPlanId
    }
    const url = 'api/v1/customer/subscription/end-trial';
    return this.httpClient.put(url, payload);
  }


  async checkIfHasNonTrialPaidSubscription(subscriptionTypes) {
    let hasNonTrialPaidSubscription = localStorage.getItem('hasNonTrialPaidSubscription') == 'true';
    if (hasNonTrialPaidSubscription) {
      return true;
    }
    const subscriptions = await this.getSubscriptions();
    const currentSubscription = subscriptions.length == 1 ?
      subscriptions[0] : 
      subscriptions.find((item) => item.is_activated_in_next_cycle);
    if (!currentSubscription?.plan) {
      return false;
    }
    const planType = subscriptionTypes.find((type) => type.key == currentSubscription?.plan.key)
    hasNonTrialPaidSubscription = this.checkIfIsOTCDiscountSubscription(currentSubscription?.plan?.key, currentSubscription?.plan.trial_days);
    this.storage.save('currentSubscription', planType);
    localStorage.setItem('hasNonTrialPaidSubscription', hasNonTrialPaidSubscription ? 'true' : 'false');
    return hasNonTrialPaidSubscription;
  }

  checkIfIsOTCDiscountSubscription(key, trialDays) {
    if (!key) {
      return false;
    } else if (this.getSubscriptionType(key) == 'custom') {
      return true;
    } else if (trialDays == 0 && !key.includes('free')) {
      return true;
    } else {
      return false;
    }
  }

  getSubscriptionType(key) {
    const types = {
      'free_tier_yearly_subscription_not_trial': 'Free',
      'tidy_plus_yearly_subscription_not_trial': 'TIDY+',
      'tidy_plus_yearly_subscription_trial': 'TIDY+',
      'tidy_plus_monthly_subscription_not_trial': 'TIDY+',
      'tidy_plus_monthly_subscription_trial': 'TIDY+',
      'standard_yearly_subscription_not_trial': 'Standard',
      'standard_yearly_subscription_trial': 'Standard',
      'standard_monthly_subscription_not_trial': 'Standard',
      'standard_monthly_subscription_trial': 'Standard',
      'advanced_yearly_subscription_not_trial': 'Advanced',
      'advanced_yearly_subscription_trial': 'Advanced',
      'advanced_monthly_subscription_not_trial': 'Advanced',
      'advanced_monthly_subscription_trial': 'Advanced'
    }
    return types[key] || 'custom';
  }

}
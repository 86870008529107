import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Client } from 'src/providers/client/client';
import { Me } from 'src/providers/me/me';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';
import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'developers.html'
})

export class DevelopersPage extends TimeoutableComponent implements OnInit {

  cameFromDeveloperSelfBooking: boolean;
  errorMessage: string;
  isDeveloperEnabled: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private me: Me,
    private util: Util,
    private route: ActivatedRoute,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Developers');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      const clientId = localStorage.getItem('customer_id') || await this.getClientId();
      const customerInfo = await this.client.getCustomerInfo(clientId);
      this.isDeveloperEnabled = customerInfo.developer;
      this.cameFromDeveloperSelfBooking = localStorage.getItem('cameFromDeveloperSelfBooking') === 'true';
      localStorage.removeItem('cameFromDeveloperSelfBooking');
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getClientId() {
    const me = await this.me.load();
    return me.customer_account.id;
  }

  goToPage(page) {
    if (page == 'my-pros') {
      const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      page = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows'
    }
    this.navCtrl.navigateForward(page);
  }

  goToSideRail(page) {
    this.rightSidePanelService.navigateTo(page);
  }

  goToExternalPage(page) {
    this.util.openUrl(page);
  }

  async enableDeveloperFeatures() {
    this.errorMessage = '';
    try {
      const clientInfo = await this.client.getClientInfo();
      const params = {
        customer: {
          developer: true
        }
      }
      await this.client.updateCustomer(params, clientInfo.customer_account.id);
      const successParams = this.mountSuccessPageParams();
      this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Developer Features Enabled',
      body: '',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

}

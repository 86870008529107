import { Component, ElementRef, ViewEncapsulation } from '@angular/core';

import { Util } from 'src/shared/util/util';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'tidy-mapbox-popup',
  templateUrl: 'map-popup.html',
  styleUrls: ['./map-popup.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapPopup {

  public data: any;

  constructor(
    private elComponentRef: ElementRef,
    private navCtrl: CustomNavController,
    private util: Util
  ) {}

  goToLink(url) {
    this.util.openUrl(url);
  }

  async goToAddressPage(addressId) {
    await this.navCtrl.navigateRoot('more');
    this.navCtrl.navigateForward(`edit-property/${addressId}`);
  }

  closePopup() {
    this.elComponentRef.nativeElement.parentNode.parentNode.parentNode.remove();
  }
}

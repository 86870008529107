<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [title]="'Concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true" (contentHeight)="contentHeight = $event" [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-desktop-header *ngIf="windowService.isDesktopRes && !isRightSideContent">
      <tidy-text
        [title]="'Concierge'">
      </tidy-text>
    </tidy-desktop-header>

    <tidy-row class="vertical-align-center" style="margin-bottom: 5px">
      <tidy-text
        header="My Rules">
      </tidy-text><span>&nbsp;</span> <span>&nbsp;</span>
      <tidy-text
        actionBody="Add Rule"
        class="link"
        (action)="goToAddRule()">
      </tidy-text>
    </tidy-row>
    <tidy-row style="margin-bottom: 10px">
      <tidy-text
        [body]="'How Concierge & AI should work:'">
      </tidy-text>
    </tidy-row>
    <tidy-alert *ngIf="rulesTableItems?.length == 0">
      <tidy-text
        body="No Rules">
      </tidy-text>
    </tidy-alert>
    <tidy-card *ngIf="rulesTableItems?.length > 0 && rulesLoaded">
      <tidy-card-button (action)="goToSection('rules')">
        <ng-container *ngFor="let rule of rulesTableItems | slice:0:3; let last = last">
          <tidy-row class="vertical-align-middle" [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-image
              [src]="'assets/img/rule.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="rule['Rule'].value">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="rules?.length > 3" class="extra-top-padding">
          <tidy-text
            actionBody="View More"
            class="link"
            (action)="null">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>
    <tidy-card *ngIf="!rulesLoaded" style="padding: 0px; height: 200px; padding: 0px;">
      <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
    </tidy-card>

    <tidy-row class="vertical-align-center" style="margin-bottom: 5px">
      <tidy-text
        header="Suggestions">
      </tidy-text>
    </tidy-row>
    <tidy-row style="margin-bottom: 10px">
      <tidy-text
        [body]="'Account set up recommendations from TIDY:'">
      </tidy-text>
    </tidy-row>
    <tidy-alert *ngIf="suggestionsTableItems?.length == 0">
      <tidy-text
        body="No Suggestions">
      </tidy-text>
    </tidy-alert>
    <tidy-card *ngIf="suggestionsTableItems?.length > 0 && suggestionsLoaded">
      <tidy-card-button (action)="goToSection('suggestions')">
        <ng-container *ngFor="let suggestion of suggestionsTableItems | slice:0:3; let last = last">
          <tidy-row class="vertical-align-middle" [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-image
              [src]="'assets/img/idea.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="suggestion['Suggestion'].value">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="suggestions?.length > 3" class="extra-top-padding">
          <tidy-text
            actionBody="View More"
            class="link"
            (action)="null">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>
    <tidy-card *ngIf="!suggestionsLoaded" style="padding: 0px; height: 200px; padding: 0px;">
      <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
    </tidy-card>

    <tidy-row class="vertical-align-center" style="margin-bottom: 5px">
      <tidy-text
        header="Concierge Actions">
      </tidy-text><span>&nbsp;</span> <span>&nbsp;</span>
      <tidy-text
        actionBody="Add Action"
        class="link"
        (action)="goToAddConciergeAction()">
      </tidy-text>
    </tidy-row>
    <tidy-row style="margin-bottom: 10px">
      <tidy-text
        [body]="'Things you had us do on your behalf under your direction:'">
      </tidy-text>
    </tidy-row>
    <tidy-alert *ngIf="conciergeActionTableItems?.length == 0">
      <tidy-text
        body="Nothing pending…">
      </tidy-text>
    </tidy-alert>
    <tidy-card *ngIf="conciergeActionTableItems?.length > 0 && actionsLoaded">
      <tidy-card-button (action)="goToSection('conciergeActions')">
        <ng-container *ngFor="let action of conciergeActionTableItems | slice:0:3; let last = last">
          <tidy-row class="vertical-align-middle" [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-image
              [src]="'assets/img/tick.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="action['Subject']?.value">
            </tidy-text>
            <tidy-text
              [smallBody]="' (' + action['Status']?.value.toLowerCase() + ')'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="conciergeActions?.length > 3" class="extra-top-padding">
          <tidy-text
            actionBody="View More"
            class="link"
            (action)="null">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>
    <tidy-card *ngIf="!actionsLoaded" style="padding: 0px; height: 200px; padding: 0px;">
      <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
    </tidy-card>

    <tidy-row class="vertical-align-center" style="margin-bottom: 5px">
      <tidy-text
        header="Support Requests">
      </tidy-text><span>&nbsp;</span> <span>&nbsp;</span>
      <tidy-text
        actionBody="Add Request"
        class="link"
        (action)="goToAddSupportRequest()">
      </tidy-text>
    </tidy-row>
    <tidy-row style="margin-bottom: 10px">
      <tidy-text
        [body]="'Account set up or troubleshooting requests from our team:'">
      </tidy-text>
    </tidy-row>
    <tidy-alert *ngIf="supportRequestTableItems?.length == 0">
      <tidy-text
        body="Nothing pending…">
      </tidy-text>
    </tidy-alert>
    <tidy-card *ngIf="supportRequestTableItems?.length > 0 && supportRequestsLoaded">
      <tidy-card-button (action)="goToSection('supportRequests')">
        <ng-container *ngFor="let request of supportRequestTableItems | slice:0:3; let last = last">
          <tidy-row class="vertical-align-middle" [ngClass]="last ? '' : 'extra-bottom-padding'">
            <tidy-image
              [src]="request['Status']?.value?.includes('dispute') ? concierge.getDisputeIcon(request['Status']?.value) : 'assets/img/support-request.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="request['Subject']?.value">
            </tidy-text>
            <tidy-text
              *ngIf="!request['Status']?.value?.includes('dispute')"
              [smallBody]="' (' + request['Status']?.value.toLowerCase() + ')'">
            </tidy-text>
          </tidy-row>
        </ng-container>
        <tidy-row *ngIf="supportRequests?.length > 3" class="extra-top-padding">
          <tidy-text
            actionBody="View More"
            class="link"
            (action)="null">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>
    <tidy-card *ngIf="!supportRequestsLoaded" style="padding: 0px; height: 200px; padding: 0px;">
      <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
    </tidy-card>


    <!-- <ng-container *ngIf="selectedCategory">
    <ng-container *ngFor="let item of filteredItems">

      <ng-container *ngIf="item?.type.includes('dispute')">
        <tidy-card>
          <tidy-card-button (action)="goToDispute(item)" [hideArrow]="item?.type === 'dispute.customer_dispute_counter_response' ? true : false" [customIcon]=" item?.type === 'dispute.customer_dispute_counter_response' ? 'assets/img/play-black.svg' : ''" [customArrowPositon]="{ top: '18px', width: '11px'}">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="concierge.getDisputeIcon(item?.type)"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="item?.header_text">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.type === 'dispute.customer_dispute_initiated'">
              <tidy-text
                [body]="'<i>Waiting on Pro</i>'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.type === 'dispute.customer_dispute_counter_answer'">
              <tidy-text
                [body]="'<b><i>Waiting on You</i><b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.type == 'dispute.customer_dispute_won' || item?.type == 'dispute.customer_dispute_lost'">
              <tidy-text
                [body]="'<i>Dispute Closed</i>'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="item?.type.includes('support')">
        <tidy-card *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'">
          <tidy-card-button (action)="goToConciergeMessage(item)" [hideArrow]="true" [customIcon]="'assets/img/play-black.svg'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
              <tidy-text
                [body]="'<b><i>Waiting on You</i><b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'<i>' + (item?.components[0]?.value?.last_update_at | customdate:'M/D/YY h:mma') + '</i>'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <tidy-card *ngIf="item?.components[0]?.value?.status !== 'waiting_on_customer'">
          <tidy-card-button (action)="goToConciergeMessage(item)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="(item?.metadata?.request_data?.subject | slice:0:30) + (item?.metadata?.request_data?.subject?.length > 30 ? '..' : '')">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
              <tidy-text
                [body]="'<i>Waiting on TIDY</i>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'<i>' + (item?.components[0]?.value?.last_update_at | customdate:'M/D/YY h:mma') + '</i>'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>
    </ng-container>
    </ng-container> -->

    <!-- <genome-items [genomeItems]="genomeItems"></genome-items> -->

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content" [isfullWidthContent]="windowService.isDesktopRes"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<tidy-bottom-nav activeRoute="/concierge">
</tidy-bottom-nav>

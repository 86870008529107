<tidy-card>
  <ng-container *ngIf="paymentMethod?.status">
    <tidy-row>
      <ion-chip [class]="paymentMethod?.class" *ngIf="paymentMethod?.status !== 'succeeded'">
        <ion-icon [name]="paymentMethod?.icon"></ion-icon>
        <ion-label>{{ ((paymentMethod?.status.replace('_', ' ')) | translate) | titlecase }}</ion-label>
      </ion-chip>
    </tidy-row>
  </ng-container>
  <tidy-row [class.extra-bottom-padding]="!paymentMethod?.metadata?.bank_name" class="vertical-align-center">
    <tidy-image
      [src]="paymentMethod?.type === 'card' ? 'assets/svg/card.svg' : 'assets/img/bank.png'"
      class="header-size">
    </tidy-image>
    <tidy-text
      [header]="(paymentMethod?.type === 'card' ? ('Card' | translate) : ('Bank Account' | translate)) + (paymentMethod?.metadata?.last4 ? (' ending in ' | translate) + paymentMethod?.metadata?.last4 : '')">
    </tidy-text>
    <ng-container *ngIf="paymentMethod?.is_primary">
      <tidy-text [header]="'(primary)'" style="margin-left: 5px">
      </tidy-text>
    </ng-container>
  </tidy-row>
  <ng-container *ngIf="paymentMethod?.metadata?.bank_name">
    <tidy-row>
      <tidy-text
        [body]="paymentMethod?.metadata?.bank_name + (paymentMethod?.metadata?.account_type ? ' | ' + paymentMethod?.metadata?.account_type.toUpperCase() : '')">
      </tidy-text>
    </tidy-row>
  </ng-container>

  <tidy-card-button
    (action)="addMissingBankInfo(paymentMethod)"
    *ngIf="paymentMethod?.action_required === 'collect_bank_info'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/arrow-redo-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text [body]="'Add Missing Bank Info'"> </tidy-text>
    </tidy-row>
  </tidy-card-button>

  <tidy-card-button
    (action)="confirmPaymentMethod(paymentMethod)"
    *ngIf="paymentMethod?.action_required === 'requires_confirmation'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/arrow-redo-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text [body]="'Confirm Payment Method'"> </tidy-text>
    </tidy-row>
  </tidy-card-button>

  <tidy-card-button (action)="goToLoadCredit(paymentMethod)" *ngIf="paymentMethod?.status == 'succeeded'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/cash-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text [body]="'Load Credit'"> </tidy-text>
    </tidy-row>
  </tidy-card-button>

  <tidy-card-button
    (action)="makeCardPrimary(paymentMethod)"
    *ngIf="paymentMethod?.metadata?.last4 && !paymentMethod?.is_primary && paymentMethod?.type === 'card'">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/checkmark-circle-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text [body]="'Make Primary'"> </tidy-text>
    </tidy-row>
  </tidy-card-button>

  <tidy-card-button (action)="confirmDeletePaymentMethod(paymentMethod)">
    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/close-circle-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text [body]="teamId ? 'Remove from Team' : 'Delete'"> </tidy-text>
    </tidy-row>
  </tidy-card-button>
</tidy-card>

import { Injectable, OnDestroy } from "@angular/core";
import { BREAK_LARGE_RESOLUTION } from "android/app/src/constants/app.contants";
import { fromEvent, ReplaySubject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WindowService implements OnDestroy {

  public windowSize: number = window?.innerWidth;
  private destroyed$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor() {
    fromEvent(window, 'resize')
    .pipe(
      debounceTime(300),
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
        this.windowSize = window?.innerWidth ?? this.windowSize;
    });
  }

  public get isDesktopRes(): boolean {
    return this.windowSize > BREAK_LARGE_RESOLUTION;
  }

  ngOnDestroy() {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Concierge } from 'src/providers/concierge/concierge';
import { SuccessPage } from 'src/shared/pages/success/success';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'advanced-dispute.html',
  encapsulation: ViewEncapsulation.None
})

export class OpenDisputePage implements OnInit {

  percentageMask = {
    lazy: false,
    maxLength: 3,
    mask: 'num%',
    blocks: {
      num: {
        mask: Number,
      }
    }

  };
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  title: string;
  type: string;
  cashBackTypes: {viewValue: String, value: String}[];
  metadata: any;
  job: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      cashback: ['', Validators.required],
      message: ['', Validators.required],
      percentage: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.title = await this.getParam('title');
    this.rightSidePanelService.setDialogPageTitle(this.title);
    this.job = await this.getParam('job');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    let percentage = parseInt(this.form.value.percentage, 10);
    if (!this.form.valid) {
      return;
    }
    let data = {
      homekeeper_job_id: this.job.homekeeper_jobs[0].id,
      cashback_type: this.form.value.cashback,
      percentage,
      message: this.form.value.message
    };
    try {
      await this.concierge.openDispute(data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const params = {
      header: 'Dispute Opened',
      body: `The pro must accept your dispute or reply within 3 days.`,
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.rightSidePanelService.navigateTo('success', params, SuccessPage);
  }

}

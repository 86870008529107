<div class="scrollable-container" #scrollableContainer [class.mobile]="!windowService.isDesktopRes">
  <div class="calendar">
    <div class="calendar-days">
      <div class="calendar-header fixed-header property-header" [class.mobile-width]="!windowService.isDesktopRes">
      </div>
      <div
        *ngFor="let day of daysOfWeek"
        class="calendar-header fixed-header"
        [class.today-column]="isToday(day)">
        <div class="header-div">
          <div class="day-header-wrapper" [class.today-header]="isToday(day)">
            <tidy-text
              *ngIf="viewMode !== 'WEEK_MON_SUN'"
              [header]="(day | translate).slice(0, 3) + ' ' + getDayFromWeekBasedOnWeekday(day, 'MMM d')"
              class="day-from-week">
            </tidy-text>
            <tidy-text
              *ngIf="viewMode === 'WEEK_MON_SUN'"
              [header]="(day | translate).slice(0, 3) + ' ' + getDayFromWeekBasedOnWeekday(day, 'd')"
              class="day-from-week">
            </tidy-text>
          </div>
          <div class="sort-container">
              <tidy-image
                [src]="'assets/icon/sort-up.png'"
                [ngClass]="{
                'sort-enabled': sortDirection === 'asc' && sortProperty === day,
                'sort-disabled': sortProperty !== day || sortDirection !== 'asc'
                }"
                (click)="sort(day, 'asc')">
              </tidy-image>
              <tidy-image
                [src]="'assets/icon/sort-up.png'"
                class="sort-down"
                [ngClass]="{
                'sort-enabled': sortDirection === 'desc' && sortProperty === day,
                'sort-disabled': sortProperty !== day || sortDirection !== 'desc'
                }"
                (click)="sort(day, 'desc')">
              </tidy-image>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let property of properties">
        <div class="calendar-day property-col fixed-column" [class.mobile-width]="!windowService.isDesktopRes" [style.left]="fixedColumnLeft">
          <tidy-row>
            <tidy-text
              *ngIf="windowService.isDesktopRes"
              [clickableHeader]="property?.name || property.address"
              (action)="emitClickAction('property', property)"
              [darkHover]="true">
            </tidy-text>
            <tidy-text
              *ngIf="!windowService.isDesktopRes"
              [clickableBody]="property?.name || property.address"
              (action)="emitClickAction('property', property)"
              [darkHover]="true">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="property.nextReservationDate">
            <tidy-text
              [body]="'Next Check In:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="getNextReservationDate(property)">
            </tidy-text>
            <ng-container *ngIf="property.nextReservationDate === today">
              <span>&nbsp;</span>
              <tidy-text
                [body]="'(Today)'"
                class="next-date-text">
              </tidy-text>
            </ng-container>
          </tidy-row>
          <tidy-row *ngIf="property.nextJobDate">
            <tidy-text
              class="next-date-text"
              [body]="'Next Job:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              class="next-date-text"
              [body]="getNextJobDate(property)">
            </tidy-text>
            <ng-container *ngIf="property.nextJobDate === today">
              <span>&nbsp;</span>
              <tidy-text
                class="next-date-text"
                [body]="'(Today)'">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </div>
        <div
          (click)="goToBookjob(day, property)"
          *ngFor="let day of daysOfWeek"
          [class]="getIsCurrentDay(getDayFromWeekBasedOnWeekday(day)) ? 'calendar-day current-day' : 'calendar-day'"
          class="cell-highlight">
          <ng-container *ngIf="hasEventAtProperty(day, property, 'filteredReservations')">
            <ng-container *ngFor="let reservation of getReservationsAtProperty(day, property); trackBy: trackByFn">
              <div
                class="card-container"
                [style]="'width: ' + reservation?.width + '%;'">
                <tidy-alert class="reservation-alert small-padding">
                  <div class="event-card-content">
                    <tidy-image
                      [src]="reservation?.icon">
                    </tidy-image>
                    <div (click)="emitClickAction('event', property, 'reservations', reservation)">
                      <tidy-text
                        class="reservation-text"
                        *ngIf="reservation?.title"
                        [body]="reservation?.title + ' '">
                      </tidy-text>
                    </div>
                  </div>
                </tidy-alert>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="hasEventAtProperty(day, property, 'filteredJobs')">
            <ng-container *ngFor="let job of getJobsAtProperty(day, property); let last = last; trackBy: trackByFn">
            <tidy-card *ngIf="job?.skeleton" style="margin-bottom: 5px; left: -5px; padding: 0px; height: 60px">
              <ion-skeleton-text animated style="width: 100%; height: 60px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
            </tidy-card> 
            <div
              *ngIf="!job?.skeleton"
              class="card-container job-card-container"
              [style]="'width: 103%;' + 'padding-top: ' + job?.paddingTop + 'px;'"
              (click)="emitClickAction('event', property, 'jobs', job)">
              <tidy-card>
                <div
                  class="unread-messages-indicator"
                  *ngIf="job?.hasUnreadMessages"
                  (click)="emitClickAction('event', property, 'jobs', job)">
                  <tidy-image [src]="'assets/img/email-white.png'">
                  </tidy-image>
                </div>
                <div class="event-card-content job-card-content vertical-align-center">
                  <div>
                    <tidy-image
                      [src]="job?.icon">
                    </tidy-image>
                  </div>
                  <div *ngIf="job?.isPrivate || job?.isJobRequest">
                    <ng-container *ngIf="bookJobService.isAssigningPrivatePro !== job?.booking?.id" >
                      <tidy-text
                        *ngIf="hasPrivatePros && !job?.title?.includes('2 Pros') && job?.status?.name !== 'completed' && job?.status?.name !== 'cancelled'"
                        class="no-wrap job-title"
                        [clickableBody]="(job?.title) | titlecase | truncate:22:false"
                        mat-icon-button [matMenuTriggerFor]="assignProMenu"  #assignProMenuTrigger="matMenuTrigger" (click)="showProMenu(job, property, $event)">
                      </tidy-text>
                      <tidy-text
                        *ngIf="!(hasPrivatePros && !job?.title?.includes('2 Pros') && job?.status?.name !== 'completed' && job?.status?.name !== 'cancelled')"
                        class="no-wrap job-title"
                        [body]="(job?.title) | titlecase | truncate:22:false">
                      </tidy-text>
                    </ng-container>
                    <ion-skeleton-text *ngIf="bookJobService.isAssigningPrivatePro == job?.booking?.id"
                      animated="true"
                      style="width: 90px; height: 18px; margin-right: 20px; margin-top: -2px; margin-bottom: -2px">
                    </ion-skeleton-text>
                    <mat-menu #assignProMenu="matMenu">
                      <ng-container *ngFor="let pro of privatePros">
                        <button mat-menu-item (click)="assignPro(job, pro, $event)">
                          {{pro?.name}}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                  <div *ngIf="!job?.isPrivate && !job?.isJobRequest">
                    <tidy-text
                      class="no-wrap job-title"
                      [body]="(job?.title) | titlecase | truncate:22:false">
                    </tidy-text>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="footer-content">
                    <ng-container *ngIf="!job?.isJobRequest">
                      <div class="footer-text">
                        <tidy-text
                          [body]="job?.time">
                        </tidy-text>
                      </div>
                      <div class="footer-text align-center">
                        <tidy-image
                          *ngIf="job?.status?.icon_type == 'image'"
                          [src]="job?.status?.icon"
                          [class]="job?.status?.class"
                          style="height: 15px">
                        </tidy-image>
                        <div
                          *ngIf="job?.status?.icon_type == 'initials'"
                          [ngClass]="job?.status?.class"
                          [ngStyle]="job?.status?.status == 'on_the_way' || job?.status?.status == 'arrived_at_home' ? {'margin-right': '8px'} : {}">
                          {{(job?.homekeeperName || 'BA')?.toUpperCase()}}
                        </div>
                        <tidy-text
                          [ngStyle]="{'margin-left': job?.status?.class == 'pro-initials-in-progress' ? '5px' : '0px'}"
                          [body]="job?.status?.name | titlecase">
                        </tidy-text>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="job?.isJobRequest">
                      <div class="footer-text">
                        <tidy-text
                          *ngIf="job?.booking?.startDateNoEarlierThan !== job?.booking?.endDateNoLaterThan"
                          [body]="job?.booking?.startDateNoEarlierThan | date: 'MMM d'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="job?.booking?.startTimeNoEarlierThan">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="'-'">
                        </tidy-text>
                        <ng-container *ngIf="job?.booking?.startDateNoEarlierThan !== job?.booking?.endDateNoLaterThan">
                          <span>&nbsp;</span>
                          <tidy-text
                            [body]="job?.booking?.endDateNoLaterThan | date: 'MMM d'">
                          </tidy-text>
                        </ng-container><span>&nbsp;</span>
                        <tidy-text
                          [body]="(job?.booking?.startDateNoEarlierThan !== job?.booking?.endDateNoLaterThan ? job?.booking?.endTimeNoLaterThan : (job?.booking?.endTimeNoLaterThan | truncate:16))">
                        </tidy-text>
                      </div>
                      <div class="footer-text align-center">
                        <tidy-image
                          [src]="job?.status?.icon"
                          [ngClass]="job?.status?.class ? job?.status?.class : ''"
                          style="height: 15px">
                        </tidy-image>
                        <tidy-text
                          [body]="job?.status?.name | titlecase">
                        </tidy-text>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </tidy-card>
            </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="hasEventAtProperty(day, property, 'filteredAvailabilityRequests')">
            <ng-container *ngFor="let job of getAvailabilityRequestsAtProperty(day, property); trackBy: trackByFn">
            <div
              class="card-container job-card-container"
              [style]="'width: ' + job?.width + '%;' + 'padding-top: ' + (job?.paddingTop) + 'px;'"
              (click)="emitClickAction('event', property, 'availabilityRequests', job)">
              <tidy-card>
                <div class="event-card-content job-card-content vertical-align-center">
                  <div>
                    <tidy-image
                      [src]="job?.icon">
                    </tidy-image>
                  </div>
                  <div>
                    <tidy-text
                      class="no-wrap job-title"
                      [body]="(job?.title) | titlecase | truncate:22:false">
                    </tidy-text>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="footer-text" *ngIf="job?.initiatedBy == 'homekeeper'">
                    <tidy-row class="times-row-wrapper">
                      <tidy-text
                        [body]="job?.title">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="'Proposed Other Times:'">
                      </tidy-text>
                    </tidy-row>
                  </div>
                  <div class="footer-content">
                    <div class="footer-text">
                      <tidy-row class="times-row-wrapper extra-bottom-padding">
                        <tidy-text
                          *ngIf="showAllTimesProposals.includes(job?.id)"
                          [body]="job?.parsedRequestTimes">
                        </tidy-text>
                        <tidy-text
                          *ngIf="!showAllTimesProposals.includes(job?.id)"
                          [body]="job?.parsedRequestTimes.split('<br><br>', 2)[0]">
                        </tidy-text><br>
                        <tidy-text
                          *ngIf="!showAllTimesProposals.includes(job?.id) && (job?.parsedRequestTimes.split('<br>').length > 2)"
                          class="link"
                          (click)="viewAllRequestTimes(job, $event)"
                          [smallBody]="'View All Times'">
                        </tidy-text>
                      </tidy-row>
                    </div>
                    <div class="footer-text align-center" *ngIf="job?.initiatedBy !== 'homekeeper'">
                      <tidy-image
                        [src]="job?.status?.icon"
                        [ngClass]="job?.status?.class ? job?.status?.class : ''"
                        style="height: 15px">
                      </tidy-image>
                      <tidy-text
                        [body]="job?.status?.name | titlecase">
                      </tidy-text>
                    </div>
                  </div>
                </div>
              </tidy-card>
            </div>
            </ng-container>
          </ng-container>
          <div class="add-job-button">
            <tidy-round-button
              [buttonWidth]="25"
              [imageWidth]="10"
              class="green"
              [image]="'assets/svg/add-heavy.svg'"
              (action)="goToBookjob(day, property)">
            </tidy-round-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

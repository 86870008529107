<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Scan Your Property'"
    [canGoBack]="'true'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      [text]="errorMessage"
      *ngIf="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <ng-container [ngSwitch]="modalFlow">

        <ng-container *ngSwitchCase="'firstModal'">
          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/svg/videocam.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Scan Interior of Your Property'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'You record a video tour and our AI will analyze it to build a map of your home.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Just walk around like you are giving a quick tour to a friend, talking about what is shown in the video.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Then, we will do the rest.'">
              </tidy-text>
            </tidy-row>
          </tidy-card>
          <tidy-button
            [text]="'Next'"
            [action]="secondModal.bind(this)"
            class="primary">
          </tidy-button>
          <tidy-button
            [text]="'Skip This'"
            [action]="submit.bind(this)"
            class="secondary">
          </tidy-button>
        </ng-container>

        <ng-container *ngSwitchCase="'recordVideo'">
          <scanner-component (videoUrl)="videoRecorded($event)">
          </scanner-component>
        </ng-container>

      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

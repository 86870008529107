import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Dashboard } from 'src/providers/dashboard/dashboard';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: './edit-report-name.html',
})

export class EditReportNamePage implements OnInit {

  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  loaded: boolean;
  report: any;
  submitted: boolean;

  constructor(
    private dashboard: Dashboard,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.report = this.navCtrl.getParam('report') || this.dialogParams.report;
    this.form.patchValue({name: this.report.name});
    this.loaded = true;
  }

  async saveName() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = {
        name: this.form.value.name
      }
      await this.dashboard.updateDashboardReport(payload, this.report.id);
      await this.dashboard.getDashboardSectionItems(true);
      this.rightSidePanelService.navigateTo(`dashboard/report-settings/${this.report.id}`);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
  
}

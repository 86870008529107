<form [formGroup]="form" novalidate action="#">
  <tidy-card>
    <tidy-row>
      <tidy-text [body]="'Do you want to use TIDY for payments?'"></tidy-text>
    </tidy-row>
    <tidy-row>
      <tidy-radio-button
        [items]="[
          { value: true, viewValue: 'Yes' },
          { value: false, viewValue: 'No' }
        ]"
        formControlName="useTidyForPayments"
        [form]="form.controls.useTidyForPayments">
      </tidy-radio-button>
    </tidy-row>
  </tidy-card>

  <ng-container *ngIf="form.value.useTidyForPayments">
    <tidy-card>
      <tidy-row>
        <tidy-text [body]="'Do you want to autopay?'"></tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-radio-button
          [items]="[
            { value: true, viewValue: 'Yes' },
            { value: false, viewValue: 'No' }
          ]"
          formControlName="autopay"
          [form]="form.controls.autopay">
        </tidy-radio-button>
      </tidy-row>
    </tidy-card>
  </ng-container>

  <tidy-button
    [text]="'Save Bill Settings'"
    class="primary"
    [action]="save.bind(this)">
  </tidy-button>
</form>

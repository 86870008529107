import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Pros } from 'src/providers/pros/pros';
import { WindowService } from 'src/shared/providers/window.service';
import { Loading } from 'src/shared/components/loading/loading';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe';
import { DateTime as LuxonDateTime } from 'luxon';
import { TidyDateFormatPipe } from 'src/shared/pipes/tidy-date.pipe';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ReservationPage } from '../reservation/reservation';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Subscription } from 'rxjs';
import { AddReservationPage } from '../add-reservation/add-reservation';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'reservations.html',
  styleUrls: ['reservations.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ReservationsPage implements OnDestroy, OnInit {

  addressResponse: any;
  addressFilter: any;
  reservations: any;
  errorMessage: string;
  headers: any;
  rows: any;
  currentRows: any;
  form: UntypedFormGroup;
  proList = [];
  filters: { address_id: number | null, page?: number, per_page?: number };
  isDataLoaded = false;
  @ViewChild('tidyTable') tidyTable: any;
  panelClosedSubscription: Subscription;
  totalRows: number;
  loaded: boolean;
  isGettingFilteredReservations = false;
  pageIndex = 1;

  constructor(
    private client: Client,
    private currentAddress: CurrentAddress,
    private navCtrl: CustomNavController,
    private pros: Pros,
    public windowService: WindowService,
    private fb: FormBuilder,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private guestReservations: GuestReservations,
    private util: Util
  ) {
    this.form = this.fb.group({
      selectedAddress: [0, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.panelClosedSubscription?.unsubscribe();
  }

  @Loading('', true)
  async ngOnInit() {
    this.filters = {
      address_id: null,
      page: 1,
      per_page: 25
    };
    this.form.reset();
    this.errorMessage = '';
    this.loaded = true;
    this.buildHeaders();
    localStorage.setItem('isAllAddressesSelected', 'true');
    await this.getInitialData();
  }

  async getInitialData(): Promise<void> {
    try {
      this.isDataLoaded = false;
      const prosPromise = this.pros.getAllPros();
      const addressDataPromise = this.getAddressData();
      const reservationsPromise = this.getFilteredReservations(this.filters);
      const [pros] = await Promise.all([
        prosPromise,
        addressDataPromise,
        reservationsPromise,
      ]);
      this.proList = this.parseProList(pros);
      this.isDataLoaded = true;
    } catch (err) {
      console.error(err);
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  parseProList(pros: any[]): any[] {
    return pros.map((pro) => {
      return {
        value: pro.id,
        viewValue: !pro?.name ? '' : new TidyAbbreviateLastName().transform(pro.name),
        teamId: pro?.main_team_id,
      };
    });
  }

  buildRows(reservations: any[]): void {
    this.rows = [];
    reservations.map((item) => {
      const address = item?.address;
      this.rows.push({
        id: item.id,
        Property: {
          value: this.getPropertyName(address) || '',
          action: this.goToProperty.bind(this, address?.id),
        },
        'Check In': {
          value:
            this.getParsedDate(item?.check_in_date) +
            ' ' +
            new TidyDateFormatPipe().transform(item?.check_in_time, 'h:mma').toLowerCase(),
          action: null,
        },
        'Check Out': {
          value:
            this.getParsedDate(item?.check_out_date) +
            ' ' +
            new TidyDateFormatPipe().transform(item?.check_out_time, 'h:mma').toLowerCase(),
          action: null,
        },
        'Created By': {
          value:
            item?.guest_reservation_source_type?.name === 'Add Manually'
              ? 'You'
              : item?.guest_reservation_source_type?.name,
          action: null,
        },
        'last-column': {
          value: '',
          action: null,
        },
      });
    });
    this.currentRows = this.rows;
  }

  getParsedDate(date: string): string {
    const language = localStorage.getItem('language');
    const format = language && language !== 'en' ? 'cccc M/d/yy' : 'EEE M/d/yy';
    let parsedDate = LuxonDateTime.fromFormat(date, 'yyyy-MM-dd').toFormat(
      format
    );
    if (language && language !== 'en') {
      const dayPart = parsedDate.split(' ')[0];
      const restPart = parsedDate.split(' ').slice(1).join(' ');
      parsedDate = new TranslationPipe().transform(dayPart) + ' ' + restPart;
    }
    return parsedDate;
  }

  goToProperty(id: number): void {
    this.currentAddress.addressId = id.toString();
    localStorage.setItem('isAllAddressesSelected', 'false');
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
  }

  getPropertyName(address: any): string {
    if (address?.address_name) {
      return address.address_name + (address.zip ? ', ' + address.zip : '');
    }
    return (
      (address?.address || address?.address1) +
      (address.zip ? ', ' + address.zip : '')
    );
  }

  buildHeaders(): void {
    this.headers = [
      'Property',
      'Check In',
      'Check Out',
      'Created By',
      'last-column',
    ];
  }

  sortChanged(sort) {
    this.rows.sort((a, b) => {
      if (sort.active === 'Check In' || sort.active === 'Check Out') {
        const dateA = LuxonDateTime.fromFormat(
          a[sort.active].value,
          'EEE M/d/yy h:mma'
        )
          .toJSDate()
          .getTime();
        const dateB = LuxonDateTime.fromFormat(
          b[sort.active].value,
          'EEE M/d/yy h:mma'
        )
          .toJSDate()
          .getTime();
        return sort.direction === 'asc' ? dateA - dateB : dateB - dateA;
      } else {
        const valueA = a[sort.active].value;
        const valueB = b[sort.active].value;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sort.direction === 'asc' || sort.direction === ''
            ? valueA - valueB
            : valueB - valueA;
        } else {
          const strA = String(valueA).toLowerCase();
          const strB = String(valueB).toLowerCase();
          if (strA < strB)
            return sort.direction === 'asc' || sort.direction === '' ? -1 : 1;
          if (strA > strB)
            return sort.direction === 'asc' || sort.direction === '' ? 1 : -1;
          return 0;
        }
      }
    });
    this.currentRows = this.rows;
  }

  async getFilteredReservations(params?: {address_id: number; page?: number; per_page?: number}, isPageChange = false): Promise<void> {
    const reservations = await this.guestReservations.getReservations(params);
    this.totalRows = reservations.totalRecords;
    if (isPageChange) {
      this.reservations = [
        ...this.reservations,
        ...reservations.body.sort(
          (a, b) => new Date(b.check_in_date).getTime() - new Date(a.check_in_date).getTime()
        )
      ];
    } else {
      this.reservations = reservations.body.sort(
        (a, b) =>
          new Date(b.check_in_date).getTime() -
          new Date(a.check_in_date).getTime()
      );
    }
    this.buildRows(this.reservations);
    this.panelClosedSubscription?.unsubscribe();
  }

  onFilterValueChange(event: number | string, type: string): void {
    this.pageIndex = 1;
    this.filters.page = this.pageIndex;
    if (type === 'address') {
      const addressId = event as number;
      this.filters['address_id'] = addressId;
    }
    this.getFilteredReservations(this.filters);
  }

  onTableColumnClick(event) {
    if (event.column === 'Property') {
      return;
    }
    const foundReservation = this.reservations.find(
      (reservation) => reservation.id === event.element.id
    );
    this.goToReservation(foundReservation);
  }

  async getAddressData() {
    this.addressFilter = await this.buildAddressFilter();
    this.addressFilter.unshift({
      value: 0,
      viewValue: 'All Properties',
    });
    this.form.patchValue({
      selectedAddress: 0,
    });
  }

  async buildAddressFilter() {
    this.addressResponse = await this.client.getAddresses();
    return this.client.parseAddressList(this.addressResponse, false);
  }

  goToReservation(reservation: any): void {
    const addressName = reservation?.address?.address1;
    const params = {
      guestReservation: reservation,
      address: addressName,
      addressId: reservation?.address?.id
    };
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.rightSidePanelService.setDialogPageTitle('Reservation');
      this.storage.save('dialog-params', params);
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(ReservationPage);
      this.panelClosedSubscription = new Subscription();
      this.panelClosedSubscription.add(
        this.rightSidePanelService.afterPanelClosed().subscribe(() => {
          this.getFilteredReservations(this.filters);
        })
      );
    } else {
      this.navCtrl.navigateForward('reservation', params);
    }
  }

  goToAddReservation() {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.rightSidePanelService.setDialogPageTitle('Add Reservation');
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(AddReservationPage);
      this.panelClosedSubscription = new Subscription();
      this.panelClosedSubscription.add(
        this.rightSidePanelService.afterPanelClosed().subscribe(() => {
          this.getFilteredReservations(this.filters);
        })
      );
    } else {
      this.navCtrl.navigateForward('add-reservation');
    }
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }

  async pageChange() {
    if (this.isGettingFilteredReservations) {
      return;
    }
    this.isGettingFilteredReservations = true;
    if (this.tidyTable) {
      this.tidyTable.isLoading = true;
    }
    this.pageIndex += 1;
    this.filters.page = this.pageIndex;
    try {
      await this.getFilteredReservations(this.filters, true);
    } finally {
      if (this.tidyTable) {
        this.tidyTable.isLoading = false;
      }
      this.isGettingFilteredReservations = false;
    }
  }

}
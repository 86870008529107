import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { squareImage } from 'src/shared/util/square-image';
import { Camera, CameraResultType,ImageOptions, Photo } from '@capacitor/camera';
import { Util } from 'src/shared/util/util';

@Injectable({
  providedIn: 'root'
})
export class CameraProvider {
  CAMERA_OPTIONS: ImageOptions = {
    allowEditing: false,
    correctOrientation: true,
    height: 1080,
    quality: 75,
    resultType: CameraResultType.DataUrl,
    saveToGallery: false,
    width: 1080
  };

  constructor(
    private filePicker: FilePicker,
    private platform: Platform,
    private util: Util
  ) {
  }

  async getImg(turnSquareImage = false, options: ImageOptions = this.CAMERA_OPTIONS): Promise<{ base64String: string, dataUrl: string, format?: string; filename?: string }> {
    if (this.platform.is('hybrid')) {
      const imageData: Photo = await Camera.getPhoto(this.CAMERA_OPTIONS)
      return {
        base64String: imageData.base64String,
        dataUrl: imageData.dataUrl,
        filename: new Date().getTime() + '.jpeg'
      };
    } else {
      const file = await this.filePicker.getImg();

      if (turnSquareImage) {
        const squaredImage = await this.getSquaredImage(file);
        return squaredImage;
      }

      return file;
    }
  }

  async takeCustomToDoPhoto(options: ImageOptions): Promise<Photo> {
    try {
      const cameraPhoto: Photo = await Camera.getPhoto(options);
      const imageHead = `data:image/${cameraPhoto.format};base64,`;
      const dataUrl = imageHead + cameraPhoto.base64String;
      const resizedImage: string = await this.resizeImage(dataUrl, cameraPhoto.format)
      cameraPhoto.dataUrl = resizedImage;
      cameraPhoto.base64String = resizedImage.replace(imageHead, '');
      return cameraPhoto;
    } catch (error) {
      const errorMessage = error?.error?.message ?? error?.message;
      this.util.showError('Error on get photo: ' + errorMessage)
      console.error(error);
      return null;
    }
  }

  resizeImage(
    dataUrl: string,
    format: string = null,
    quality: number = 0.5
  ): Promise<string> {
    if (typeof dataUrl !== 'string') {
      return dataUrl;
    }
    if (format === 'heic') {
      format = 'jpeg';
    }
    if (dataUrl.includes('heic')) {
      dataUrl = dataUrl.replace('heic', 'jpeg');
    }
    format = format ?? this.extractFormat(dataUrl);
    const img = document.createElement('img') as HTMLImageElement;
    img.src = dataUrl;

    return new Promise((resolve, reject) => {
      img.onload = (e) => {
        const canvas = document.createElement('canvas');

        const MAX_WIDTH = 1080;
        const MAX_HEIGHT = 1080;
        let width = img.width;
        let height = img.height;

        // Calculate aspect ratio
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const dataUrl: string = canvas.toDataURL(`image/${format}`, quality);
        resolve(dataUrl);
      };

      img.onerror = (err) => {
        console.error('error on resize image', err)
        reject(err);
      };
    });
  }

  extractFormat(dataUrl: string): string {
    return dataUrl.substring("data:image/".length, dataUrl.indexOf(";base64"));
  }

  async getSquaredImage(
    file: any
  ): Promise<{ base64String: string; dataUrl: string; format?: string }> {
    const squaredImage = await squareImage(file.dataUrl, file.format);
    const base64String = squaredImage.replace(
      `data:${file.format};base64,`,
      ''
    );

    return { dataUrl: squaredImage, base64String, format: file.format };
  }
}

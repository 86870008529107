import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { CapitalizePipe } from 'src/shared/pipes/capitalize.pipe';
import { ParseDatePipe } from 'src/shared/pipes/parse-date.pipe';
import { ShortNamePipe } from 'src/shared/pipes/short-name.pipe';
import { TelephonePipe } from 'src/shared/pipes/telephone.pipe';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe';
import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { TidyDateFormatPipe } from 'src/shared/pipes/tidy-date.pipe';
import { Util } from 'src/shared/util/util';
import { CustomTimePipe } from 'src/shared/pipes/custom-time';

export interface PastJobPdfParams {
  job: any;
  invoice: any;
  pro: any;
  tips: any;
  parkingReimbursements: any;
  billingInfo: any;
  activities: any;
  messages: any;
  rooms: any;
  jobId: any;
  totalTipsValue: any;
  address: any;
  proReportedDuration: any;
  fromStartDuration: any;
  beforePhotos: any;
  afterPhotos: any;
}

@Injectable()
export class PastJobPdfProvider {
  constructor(private util: Util) {}

  async exportToPDF(params: PastJobPdfParams) {
    const loading = await this.util.showLoading();
    try {
      const homekeepersContent = this.buildHomekeepersContent(params);
      const balanceContent = this.buildBalanceContent(params);
      const activitiesContent = this.buildActivitiesContent(params);
      const messagesContent = this.buildMessagesContent(params);
      const toDosContent = this.buildToDosContent(params);
      const mapImage = await this.captureMapImage(params);
      const beforePhotos = await this.buildBeforeAfterContent(params.beforePhotos, params);
      const afterPhotos = await this.buildBeforeAfterContent(params.afterPhotos, params);

      const printableContent = this.buildPrintableContent(
        params,
        homekeepersContent,
        balanceContent,
        activitiesContent,
        messagesContent,
        beforePhotos,
        toDosContent,
        afterPhotos,
        mapImage
      );
      this.openPrintWindow(printableContent);
    } catch (error) {
      console.error('Error exporting to PDF:', error);
      const errorMessage = error?.message ? error?.message : error || 'An error occurred';
      this.util.showError('Error exporting to PDF: ' + errorMessage);
    } finally {
      loading.dismiss();
    }
  }

  buildHomekeepersContent(params: any): string {
    let homekeepersContent = '';
    if (params?.job?.homekeepers?.length > 0) {
      for (const homekeeper of params?.job?.homekeepers) {
        homekeepersContent += `
        <div>
          <div class="row">
            <span class="content-text">${new TidyAbbreviateLastName().transform(
              homekeeper?.name
            )}</span>
          </div>
          <div class="row">
            <span class="content-text">${new TelephonePipe().transform(
              homekeeper?.phone
            )}</span>
          </div>
        </div>
    `;
      }
    }
    return homekeepersContent;
  }

  buildBalanceContent(params: any): string {
    let balanceContent = '';

    if (params?.job?.is_private) {
      if (!params?.invoice) return '';

      balanceContent = `
    <div class="content-padding">
            <div>
              <span
                class="content-title"
                >${
                  'Balance:' +
                  ' (' +
                  (params?.invoice
                    ? new TidyCurrencyPipe().transform(
                        params?.invoice?.amount_due * -1
                      )
                    : new TidyCurrencyPipe().transform(
                        params?.job?.booking?.bookable_service?.price * -1
                      )) +
                  ')'
                }</span>
            </div>
            ${
              !params?.invoice
                ? `<div class="row">
            <span class="content-text">${params?.pro?.homekeeper?.first_name} has not sent you a bill or marked this job as paid. Ask them to send you a bill if want to pay for this job.
            </span>
          </div>`
                : ''
            }

          ${
            !params?.invoice?.full_paid && params?.invoice
              ? `<div>
          <span
            class="content-text">${
              params?.pro?.homekeeper?.first_name
            } sent you a ${new TidyCurrencyPipe().transform(
                  params?.invoice?.amount_due
                )} bill for this job
          </span>
        </div>`
              : ''
          }

        ${
          params?.invoice?.full_paid
            ? `<div>
        <span
          class="content-text">${
            'You paid a ' +
            new TidyCurrencyPipe().transform(params?.invoice?.amount) +
            ' bill for this bill.'
          }
        </span>
      </div>`
            : ''
        }

          </div>
          <div class="divider"></div>
    `;
    } else {
      let tipsContent = '';
      for (const tip of params?.tips) {
        tipsContent += `
      <div class="row">
        <span class="content-text">+ ${new TidyCurrencyPipe().transform(
          tip?.amount
        )}</span>
        <span class="content-text"> Tip for </span>
        <span
          class="content-text">
          ${new ShortNamePipe().transform(tip?.proName) + ' '}
        </span>
      </div>
    `;
      }
      let parkingReimbursementContent = '';
      for (const parkingReimbursement of params?.parkingReimbursements) {
        parkingReimbursementContent += `
      <div class="row">
        <span
          class="content-text">${
            '+ ' +
            new TidyCurrencyPipe().transform(parkingReimbursement?.amount)
          }
        </span>
        <span class="content-text"> Parking Reimbursement for </span>
        <span
          class="content-text">
        ${new ShortNamePipe().transform(parkingReimbursement?.proName) + ' '}
        </span>
      </div>
    `;
        }

        let billingInfoContent = '';
        for (const item of params?.billingInfo?.details) {
          billingInfoContent += `
      <div class="row">
        <span
          class="content-text">${
            new TidyCurrencyPipe().transform(item?.amount_used) +
            ' of ' +
            new TidyCurrencyPipe().transform(item?.amount) +
            ' charge on'
          }
        </span>
        <span class="content-text">
          <span>&nbsp;</span>${new ParseDatePipe().transform(item?.date, 'M/D/YY')}
        </span>
      </div>
    `;
        }
        balanceContent = `
    <div class="content-padding" *ngIf="!job?.is_private">
    <div class="vertical-align-center">
      <span class="content-title">Billing Breakdown:</span>
    </div>
    <div class="extra-bottom-padding">
      <span class="content-text">What you were billed for this</span>
    </div>
    <div class="row">
      <span class="content-text">${
        new TidyCurrencyPipe().transform(params?.billingInfo?.ammountUsedSum) + ' '
      }</span><span>&nbsp;</span>
      <span class="content-text">Cost</span><span>&nbsp;</span>
      ${
        params?.billingInfo?.full_paid === true
          ? `
      <span
        *ngIf="billingInfo?.full_paid === true"
        class="content-text">
        (paid in full)
      </span>
      `
          : ``
      }
      ${
        params?.billingInfo?.full_paid === false &&
        params?.job?.job?.state === 'cancelled'
          ? `
      <span
        class="content-text">
        (cancellation fee)
      </span>
      `
          : ``
      }
      ${
        params?.billingInfo?.full_paid === false &&
        params?.job?.job?.state === 'incomplete'
          ? `
      <span
        class="content-text">
        (paid in half)
      </span>
      `
          : ``
      }
    </div>
    ${tipsContent}
    ${parkingReimbursementContent}
    <div class="row">
      <span
        class="content-text">
        ${
          new TidyCurrencyPipe().transform(
            params?.billingInfo?.ammountUsedSum + params?.totalTipsValue
          ) + ' Total'
        }
      </span>
    </div>
  </div>
  <div
    class="divider"></div>

    <div class="content-padding">
            <div class="vertical-align-center">
              <span class="content-title">Payment Breakdown:</span>
            </div>
            <div class="extra-bottom-padding">
              <span class="content-text">What you already paid for this.</span>
            </div>
            ${billingInfoContent}
          </div>

          <div
            class="divider"></div>
    `;
    }
    return balanceContent;
  }

  buildActivitiesContent(params: any): string {
    let activitiesContent = '';
    if (params?.activities?.length > 0) {
      activitiesContent = `
    <div class="content-padding">
    <div class="row">
      <span class="content-title">Updates:</span>
    </div>
    `;
      for (const activity of params?.activities) {
        for (const item of activity?.messages) {
          if (item?.type === 'cleaning_update') {
            activitiesContent += `
                <div
                  class="message-padding">
                  <div class="row">
                    <span
                      class="content-text date-text">${new TidyDateFormatPipe().transform(
                        item?.sent_at,
                        'EEE LLL d h:mm a'
                      )}</span>
                  </div>
                  <div class="row">
                    <span
                      class="content-text">${
                        item?.data?.text || item?.content?.text
                      }</span>
                  </div>
                </div>`;
          }
        }
      }
      activitiesContent += `</div> <div class="divider"></div>`;
    }
    return activitiesContent;
  }

  buildMessagesContent(params: any): string {
    let messagesContent = '';
    if (params?.messages?.length > 0) {
      messagesContent = `
    <div class="content-padding">
    <div class="row">
      <span class="content-title">Messages:</span>
    </div>
    `;
      for (const item of params?.messages) {
        messagesContent += `
      <div class="message-padding">
        <div class="row">
          <span
            class="content-text date-text">${new TidyDateFormatPipe().transform(
              item?.sent_at,
              'EEE LLL d h:mm a'
            )}</span>
        </div>
        <div class="row">
          <span
            class="content-text">${
              item?.data?.text || item?.content?.text
            }</span>
        </div>
        ${item?.content?.files?.length > 0
          ? `
        ${item?.content?.files
          ?.map(
            (file) =>
              `<div class="row"><img src="${file}" class="full-width no-margin photo-note-job" /></div>`
          )
          .join('')}`
          : ``
      }
      </div>`;
      }
      messagesContent += `</div> <div class="divider"></div>`;
    }
    return messagesContent;
  }

  buildBeforeAfterContent(photos, params) {
    photos = photos.filter(photo => photo.mediaType !== 'video');
    if (!photos.length) {
      return '';
    }
    const type = photos[0].category == 'before_job' ? 'Before Photos:' : 'After Photos:';
    const address = `${params?.address?.address1 || params?.job?.job?.address1 ? params?.address?.address1 || params?.job?.job?.address1 : ''} ${params?.address?.address2 || params?.job?.job?.address2 ? params?.address?.address2 || params?.job?.job?.address2 : ''} ${params?.address?.city || params?.job?.job?.city ? params?.address?.city || params?.job?.job?.city : ''} ${params?.address?.zip || params?.job?.job?.zip ? params?.address?.zip || params?.job?.job?.zip : ''}`;
    return `
    <div class="row">
      <span class="content-title">${type}</span>
    </div>
    <div class="slide-container">
      ${photos.map((photo) => `
        <div class="full-width no-margin photo-note-job" style="margin-top: 1rem !important;">
          <img src="${photo.media_url}" class="full-width there-is-carrousel no-margin photo-note-job"/>
        </div>
        <div style="margin-bottom: 1rem">
          <div class="row no-margin"></div>
          ${photo.metadata?.location ?
            `<div class="row no-margin">
              <div class="timestamp-caption">
                <span class="content-text">Location: ${photo.metadata.location === 'Location unavailable - using property location for report' ? address : photo.metadata.location}</span>
              </div>
            </div>` : ''}
          ${photo.metadata?.displayDate ?
            `<div class="row no-margin">
              <div class="timestamp-caption">
                <span class="content-text">Timestamp: ${new ParseDatePipe().transform(photo.metadata.displayDate, 'ddd M/D h:mma')}</span>
              </div>
            </div>` : ''}
        </div>`).join('')}
    </div>`;
  }

  buildToDosContent(params: any): string {
    let toDosContent = '';
    const address = `${params?.address?.address1 || params?.job?.job?.address1 ? params?.address?.address1 || params?.job?.job?.address1 : ''} ${params?.address?.address2 || params?.job?.job?.address2 ? params?.address?.address2 || params?.job?.job?.address2 : ''} ${params?.address?.city || params?.job?.job?.city ? params?.address?.city || params?.job?.job?.city : ''} ${params?.address?.zip || params?.job?.job?.zip ? params?.address?.zip || params?.job?.job?.zip : ''}`;
    if (params?.rooms?.length > 0) {
      toDosContent = `
    <div class="content-padding">
    <div class="row">
      <span class="content-title">To-Do List:</span>
    </div>
    `;

      for (const room of params?.rooms) {
        toDosContent += `
          <div class="row vertical-align-center">
            ${
              room?.icon
                ? `<img src="assets/img/icons/rooms/${room?.icon}.svg" class="title-size" />`
                : ''
            }
            <span class="content-title">${room?.name}</span>
          </div>
          ${room?.todos
            ?.map(
              (toDo) => `
          ${
            toDo?.title !== 'Take Before Photo' &&
            toDo?.title !== 'Take After Photo' &&
            toDo?.title !== 'Take Before Video' &&
            toDo?.title !== 'Take After Video'
              ? `
          <div
            class="row vertical-align-middle">
          <img
            src="${
              (toDo?.customer_performance ? toDo?.customer_performance === 'performed' : toDo?.performance === 'performed')
                ? 'assets/svg/checkmark-circle-outline.svg'
                : 'assets/svg/close-circle-outline.svg'
            }"
            class="body-size" />
          <span class="content-text">${toDo?.title}</span>
          ${
            !toDo?.performance && !toDo?.customer_performance
              ? `
          <span class="content-text" style="font-weight: bold; margin-left: 6px;"> (Not marked)</span>
          `
              : ''
          }
        </div>
        `
              : ''
          }
        ${toDo?.options
          ?.map(
            (option) => `
          ${
            option?.value_type !== 'photos'
              ? `
          <div>
            ${
              option.notes && (option?.customer_value || option?.value || option?.json_value)
                ? `
            <span class="content-text">${
              option?.name + ': ' + option?.notes
            }</span><br>
            <span class="content-text">Input: ${option?.customer_value || option?.value || option?.json_value}</span>
            `
                : ''
            }
            ${
              !option?.notes && (option?.customer_value || option?.value || option?.json_value)
                ? `
            <span class="content-text">${option?.name}</span><br>
            <span class="content-text">Input: ${option?.customer_value || option?.value || option?.json_value}</span>
            `
                : ''
            }
            ${
              option?.notes && !option?.customer_value && !option?.value && !option?.jsonValues && !option?.json_value
                ? `
            <span class="content-text">${
              option?.name + ': ' + option?.notes
            }</span>
            <br />
            <span class="content-text">No value added by pro</span>
            `
                : ''
            }
            ${
              !option?.notes && !option?.customer_value && !option?.value && !option?.jsonValues && !option?.json_value
                ? `
            <span class="content-text">${option?.name}</span>
            <br />
            <span class="content-text">No value added by pro</span>
            `
                : ''
            }
        </div>
          `
              : ''
          }
          ${
            option?.value_type?.includes('photo') || option?.value_type?.includes('photos') || option?.value_type?.includes('video')
              ? `
          <div>
          ${
            option?.notes
              ? `
          <span
            class="content-text">${option?.name + ': ' + option?.notes}</span>
          `
              : ''
          }
          ${
            !option?.notes
              ? `
          <span
            class="content-text">${option?.name}</span>
          `
              : ''
          }
          ${option?.jsonValues
            ?.map(
              (option) => `
              ${
                option?.value
                  ? `
              <div class="full-width no-margin photo-note-job">
                <img
                  src="${option?.value}"
                  class="full-width no-margin photo-note-job" />
              </div>
              `
                  : ''
              }
            <div style="margin-bottom: 1rem">
            ${
              option?.metadata?.location
                ? `
                <div class="row no-margin">
            <div
              class="timestamp-caption">
              <span
                class="content-text">Location: ${option?.metadata?.location === 'Location unavailable - using property location for report' ? address : option?.metadata?.location}</span>
            </div>
            </div>
            `
                : ''
            }
            ${
              option?.metadata?.displayDate
                ? `
            <div class="row no-margin">
            <div
              class="timestamp-caption">
              <span
                class="content-text">Timestamp: ${new ParseDatePipe().transform(option?.metadata?.displayDate, 'ddd M/D h:mma')}</span>
            </div>
            </div>
            </div>
            `
                : ''
            }
          `
        )
        .join('')}


          ${
            !option?.customer_value && !option?.value && !option?.jsonValues
              ? `
          <br />
          <span
            class="content-text">No photo taken by pro</span>
          `
              : ''
          }
        </div>
          `
              : ''
          }
        `
          )
          .join('')}
          `
            )
            .join('')}
          `;
      }
      toDosContent += `</div> <div class="divider"></div>`;
    }
    return toDosContent;
  }

  captureMapImage(params: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const mapElement = document.getElementById('map' + params?.jobId);
      if (mapElement) {
        const scale = 1;
        const rect = mapElement.getBoundingClientRect();
        html2canvas(mapElement, {
          useCORS: true,
          logging: true,
          scale: scale,
          width: rect?.width,
          height: rect?.height,
        })
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const mapImg = `<img src="${imgData}" style="width: 400px; height: auto" />`;
            resolve(mapImg);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve('');
      }
    });
  }

  buildPrintableContent(
    params: any,
    homekeepersContent: string,
    balanceContent: string,
    activitiesContent: string,
    messagesContent: string,
    beforePhotos: string,
    toDosContent: string,
    afterPhotos: string,
    mapImage: string
  ): string {
    return `
  <html>
  <head>
    <title>Past Job PDF</title>
    <link rel="icon" type="image/x-icon" href="assets/icon/favicon.ico">
    <style>
      * {
        font-family: Neuton, Arial, sans-serif;
      }
      .pdf-container {
        overflow: auto;
        background: white;
        min-height: 100vh;
        .desktop {
          background: white;
        }
      }

      .timestamp-caption {
        padding: 6px;
        background: rgba(0,0,0,.87);
        .content-text {
          color: white;
          font-weight: 500;
        }
      }

      .no-margin {
        margin: 0 !important;
      }

      .title-size {
        width: 22px;
        position: relative;
        margin-right: 5px;
      }

      .body-size {
        width: 16px;
        position: relative;
        margin-right: 5px;
      }

      .padding-content {
        padding: 40px;
      }

      .pdf-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .logo {
          width: 200px;
        }
        .header-title {
          font-size: 30px !important;
          font-weight: 700;
        }
      }

      .pdf-content {
        .content-title {
          font-size: 25px;
          font-weight: 700;
        }
        .content-text {
          font-size: 18px;
          font-family: 'Inter', Arial, sans-serif;
          font-weight: 300;
        }
      }

      .content-padding {
        padding-top: 0.3rem;
        .vertical-align-center {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }

      .photo-note-job {
        width: 400px;
        .full-width {
          width: 400px;
        }
      }

      .timestamp-caption {
        width: 388px;
      }

      .pdf-divider {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .date-text {
        font-weight: 700 !important;
      }

      .message-padding {
        padding-top: 1rem;
      }

      .divider {
        display: block;
        height: 1px;
        background: rgba(0, 0, 0, 0.12);
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
      }

      .export-btn {
        text-align: center;
      }

      @media screen and (max-width: 800px) {
        .export-btn {
          text-align: left;
          min-width: 100%;
          margin: 10px 0;
        }
      }

      @media print {
        .export-btn {
          display: none;
        }
      }
    </style>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap"
      rel="stylesheet" />
  </head>
  <body>
  <div class="desktop padding-content" id="pdf-content">
    <div class="pdf-header">
      <div>
        <img class="logo" src="assets/img/tidy-logo-h.png" alt="TIDY" />
      </div>
      <div class="export-btn">
        <a href="#" onclick="window.print();" style="color: #41CAB7; font-weight: 700; font-size: 20px;">Export to PDF</a>
      </div>
      <div>
        <span class="header-title">Past Job</span>
      </div>
    </div>
    <div class="divider"></div>

    <div class="pdf-content">
      <div class="row">
        <span class="content-title">General Job Info:</span>
      </div>
      <div class="row">
        <span class="content-text"
          >${new TitleCasePipe().transform(
            params?.job?.booking?.bookable_service?.name
          )} - ${new ParseDatePipe().transform(params?.job?.date || params?.job?.job?.date, 'ddd M/D')} ${new CustomTimePipe().transform(params?.job?.start_time || params?.job?.job?.start_time, 'h:mma')}</span
        >
      </div>
      <div class="row">
        <span class="content-text"
          >${params?.address?.address1 || params?.job?.job?.address1 ? params?.address?.address1 || params?.job?.job?.address1 : ''} ${params?.address?.address2 || params?.job?.job?.address2 ? params?.address?.address2 || params?.job?.job?.address2 : ''}</span
        >
      </div>

      ${homekeepersContent ? `
      <div class="content-padding">
        <div class="row">
          <span class="content-title">Pro:</span>
        </div>
        ${homekeepersContent}
      </div>

      <div class="content-padding">
        <div class="row">
          <span
            class="content-title">Job Duration:
          </span>
          <span
            ${!params?.proReportedDuration && !params?.fromStartDuration ? `class="content-title"> N/A` : ''}
          </span>
        </div>
        ${params?.proReportedDuration ? `
        <div class="row">
          <span
            class="content-text">Reported by Pro: ${
              params?.proReportedDuration
            }</span>
        </div>
        ` : ''}
        ${params?.fromStartDuration ? `
        <div class="row">
          <span
            class="content-text">Using Realtime Updates: ${
              params?.fromStartDuration
            }</span>
        </div>
        ` : ''}
      </div>
      <div class="divider"></div>

      ${balanceContent}
      ` : `
      <div class="content-padding">
        <div class="row">
          <span class="content-title">This job was cancelled. Talk to your pro for more info.</span>
        </div>
      </div>
      `}

      ${activitiesContent}

      ${messagesContent}

      ${beforePhotos}

      ${toDosContent}

      ${afterPhotos}

    ${mapImage ? `
      <div class="row">
        <span class="content-title">Map:</span>
      </div>
      ${mapImage}
    </div>
    ` : ''}
  </div>
</body>
</html>
  `;
  }

  openPrintWindow(printableContent: string): void {
    const printWindow = window.open('', '_blank') ?? window.open('', '_self');
    printWindow.document.open();
    printWindow.document.write(printableContent);
  }

  hasBeforeAfterPhotos(array) {
    return array?.some((item) => item?.media_format == 'photo');
  }
}

import { Component, Input, ViewChild, Output, EventEmitter, OnInit  } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from '../loading/loading';

@Component({
  selector: 'todos-menu',
  template: `
    <ion-fab slot="fixed" right vertical="bottom" class="tidy-todos-actions" #fab>
      <ion-fab-button color="todos_tidy_green"><ion-icon name="add"></ion-icon></ion-fab-button>
      <ion-fab-list side="top">
        <ion-fab-button *ngIf="room" tappable (click)="addRoomPhoto()">Add Card Photo</ion-fab-button>
        <ion-fab-button tappable (click)="navigateToAddTodo()">Add To Do</ion-fab-button>
        <ion-fab-button tappable (click)="navigateToDoNot()">Add Do Not</ion-fab-button>
        <ion-fab-button *ngIf="!room" tappable (click)="navigateToAddRoom()">Add Card</ion-fab-button>
      </ion-fab-list>
    </ion-fab>
    `
})
export class TodosMenu {
  @Input() room: number;
  @Input() cleaningId: number;
  @Output() roomPhoto = new EventEmitter();
  @ViewChild('fab', { static: true }) fab;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  navigateToAddRoom() {
    this.navCtrl.navigateForward(['add-room-page', this.cleaningId]);
  }

  navigateToAddTodo() {
    this.navCtrl.navigateForward(['add-todo', this.cleaningId]);
  }

  navigateToDoNot() {
    this.navCtrl.navigateForward(['add-todo-not', this.cleaningId]);
  }

  @Loading('Loading...')
  async addRoomPhoto() {
    this.roomPhoto.emit();
  }

}

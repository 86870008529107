<tidy-row class="extra-bottom-padding">
  <tidy-text
    [title]="'What do you want to offer?'">
  </tidy-text>
</tidy-row>

<ng-container *ngFor="let item of whatToOfferItems">
  <tidy-card class="card-checkbox" [ngClass]="{'selected-card-checkbox': item.isSelected}" (click)="selectWhatToOffer(item)">
    <div style="display: flex; align-items: center;">
      <tidy-text
        [header]="item?.name">
      </tidy-text>
    </div>
    <tidy-row>
      <tidy-text
        [body]="item?.description">
      </tidy-text>
    </tidy-row>
  </tidy-card>
</ng-container>

<tidy-row class="extra-top-padding extra-bottom-padding">
  <tidy-text
    [title]="'How do you want guests to request upsells?'">
  </tidy-text>
</tidy-row>

<ng-container *ngFor="let item of howToRequestItems">
  <tidy-card class="card-checkbox" [ngClass]="{'selected-card-checkbox': item.isSelected}" (click)="selectWhatToOffer(item)">
    <div style="display: flex; align-items: center;">
      <tidy-text
        [header]="item?.name">
      </tidy-text>
    </div>
    <tidy-row>
      <tidy-text
        [body]="item?.description">
      </tidy-text>
    </tidy-row>
  </tidy-card>
</ng-container>

<tidy-row class="extra-top-padding">
  <tidy-button
    [text]="'Save Upsell Settings'"
    [action]="save.bind(this)"
    class="primary">
  </tidy-button>
</tidy-row>



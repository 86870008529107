import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Subscription } from 'rxjs';

import { Client } from "src/providers/client/client";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { Loading } from "src/shared/components/loading/loading";
import { AddressModel } from "src/models/address.model";
import { CurrentAddress } from "src/providers/addresses/current-address";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddAddressPage } from 'src/pages/add-address/add-address';
import { WindowService } from "src/shared/providers/window.service";
import { OnboardingProvider } from "src/providers/onboarding/onboarding.provider";
import { propertiesMockData } from 'src/shared/constants/onboarding/properties';

@Component({
  templateUrl: "addresses.html",
  styleUrls: ["addresses.scss"],
})
export class AddressesPage implements OnInit, OnDestroy {

  addresses: AddressModel[];
  addressResponse: AddressModel[];
  form: UntypedFormGroup;
  isEditPropertiesEnabled = false;
  panelClosedSubscription: Subscription;
  loaded: boolean;
  showOnboarding: boolean = false;
  hasProperties: boolean = false;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private currentAddress: CurrentAddress,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    public onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      search: [""],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
  }

  @Loading("", true)
  async ngOnInit() {
    try {
      this.loaded = false;
      await this.checkIfShouldShowOnboarding();
      await this.getPageData();
      this.loaded = true;
    } catch (err) {
    }
  }

  async getPageData() {
    this.addressResponse = this.showOnboarding ? propertiesMockData : await this.client.getMoreDetailAddresses(true);
    console.log(this.addressResponse)
    this.addresses = this.addressResponse;
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('selectAccessNotes');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.getPageData();
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  goToAddAddress() {
    if (this.showOnboarding) {
      return;
    }
    this.goToPage('property', {}, AddAddressPage);
  }

  goToEditAddress(address) {
    if (this.showOnboarding) {
      return;
    }
    this.currentAddress.addressId = address.id;
    const params = {
      address: address,
    };
    localStorage.setItem("editPropertyBackPage", "properties");
    this.navCtrl.navigateForward(`edit-property/${address.id}`, params);
  }

  updateSearch({ search }) {
    if (!search) {
      this.addresses = this.addressResponse;
      return;
    }
    if (search.length < 2) {
      return (this.addresses = this.addressResponse);
    }
    const term = search.toLowerCase();
    this.addresses = this.addressResponse.filter((option) => {
      if (
        option.address1.toLowerCase().indexOf(term) > -1 ||
        option.address_name?.toLowerCase().indexOf(term) > -1
      ) {
        return option;
      }
    });
  }

  onToggleChange(): void {
    this.addresses = this.addressResponse;
    this.form.reset();
    this.isEditPropertiesEnabled = !this.isEditPropertiesEnabled;
  }

  @Loading("", true)
  async updateAddresses(event: any[]): Promise<void> {
    const addressesIds = event.map((address) => address.id);
    try {
      await this.client.saveNewAddressesOrder(addressesIds);
    } catch (error) {
      console.log(error);
    }
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }
}

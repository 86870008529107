import { Injectable } from '@angular/core';
import { CurrentAddress } from '../addresses/current-address';
import { HttpClientCustom } from '../custom/http-client';
import { Events } from '../events/events';
import { Localstorage } from '../localstorage/localstorage';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Injectable()
export class Addresses {

  private parkingMessages: any = {
    false: {
      street: "We recommend scheduling on days with no street sweeping.",
      myspot: "Please note if your pro will need a permit for your spot.",
      guest: "Please note if your pro will need a permit for your guest spot."
    },
    true: {
      cash: "You will pay your pro for parking with cash when they arrive.",
      card: "We will charge your card for parking after the cleaning (never more than your max $)."
    }
  }

  constructor(
    private httpClient: HttpClientCustom,
    private localStorage: Localstorage,
    private events: Events,
    private currentAddress: CurrentAddress,
    private storage: TidyStorage
  ) { }

  addPlanFormatObj(data, hkRating, ratingChosen) {
    const time = data.time[0];
    const dataFormated = {
      frequency: 'once',
      service_type: data.type,
      dates: [{
        date: time.date,
        time: time.time,
        homekeepers: time.homekeepers
      }],
      hk_rating: hkRating,
      rating_chosen: ratingChosen
    }
    return dataFormated;
  }

  cancelAddressPlan(customerFeedback): Promise<any> {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/plan/cancel`;

    return this.httpClient.post(url, { feedback: customerFeedback }).then(_ => {
      localStorage.removeItem('plan');
      localStorage.removeItem('plan_id');
      localStorage.removeItem('job_id');
      localStorage.setItem('no_plan', 'true');
    });
  }

  createAddress(data) {
    const url = 'api/v1/customer/addresses';
    return this.httpClient.post(url, data).then(response => {
      this.events.publish('add:address');
      return response;
    }).catch(err => {
      throw err;
    });
  }

  getAddress(addressId?) {
    if (!addressId) { addressId = localStorage.getItem('addressId'); }

    const url = `api/v1/customer/addresses/${addressId}?includes=plan`
    return this.httpClient.get(url);
  }

  getAddressId (addressArray) {
    const addressesIds = addressArray.map( address => {
      return address.id;
    })
    return addressesIds;
  }

  getCurrentAddress() {
    return localStorage.getItem('address_name');
  }

  async getPlanId() {
    const address = await this.getAddress();
    if (address.plan) {
      this.setCurrentAddress(address);
      return address.plan.id;
    }
    return;
  }

  getTimezone() {
    return localStorage.getItem('timezone');
  }

  setAddressName(address) {
    if (address?.address_name) {
      localStorage.setItem('address_name', address.address_name);
      return;
    }
    if (address.address2 && address.address2 !== '') {
      localStorage.setItem('address_name', `${address.address1}, ${address.address2}, ${address.zip}`);
    } else {
      localStorage.setItem('address_name', `${address.address1}, ${address.zip}`);
    }
  }

  setCurrentAddress(address) {
    const plan = address.plan;
    this.currentAddress.addressId = address.id;
    localStorage.setItem('address_id_menu', address.id);
    localStorage.setItem('region_id', address.region_id);
    localStorage.setItem('plan_id', plan ? plan.id : '');
    localStorage.setItem('timezone', address.timezone);
    localStorage.removeItem('job_id');
    localStorage.removeItem('plan');
    this.setAddressName(address);

    if (plan) {
      localStorage.removeItem('no_plan');
    } else {
      localStorage.setItem('no_plan', 'true');
    }

  }

  update(data, addressId?) {
    if (!addressId) { addressId = localStorage.getItem('addressId'); }
    const url = `api/v1/customer/addresses/${addressId}?includes=plan`;

    return this.httpClient.put(url, data).then(address => {
      this.setCurrentAddress(address);
    });
  }

  updateMany(data) {
    const url = `api/v1/customer/addresses/update-many`;
    return this.httpClient.put(url, data);
  }

  updateAddress(data, addressId) {
    const url = `api/v1/customer/addresses/${addressId}`;
    return this.httpClient.put(url, data);
  }

  deleteAddress(addressId) {
    const url = `api/v1/customer/addresses/${addressId}`
    return this.httpClient.delete(url);
  }

  getParkingMessage(paid, selectParam) {
    return this.parkingMessages[paid][selectParam];
  }

  getTodosPossibleTemplates(addressId) {
    const url = `api/v1/customer/addresses/${addressId}/to-dos/possible-templates`
    return this.httpClient.get(url);
  }

  replaceTodos(addressId, templateId) {
    const url = `api/v1/customer/addresses/${addressId}/to-dos/replace`
    const data = {'to_do_template_id': templateId};
    return this.httpClient.put(url, data);
  }

  getPriceTable(addressId: number) {
    const url = `api/v1/customer/no-team-address-services?filters[address_ids]=${addressId}`;
    return this.httpClient.get(url);
  }

  changePrices(addressId, payload) {
    const url = `api/v1/customer/addresses/${addressId}/change-services-prices`;
    return this.httpClient.post(url, payload);
  }

  addAvailabilityRequest(data) {
    const addressId = this.localStorage.getSingleData('addressId');

    const url = `api/v1/customer/addresses/${addressId}/availability-requests`;

    return this.httpClient.post(url, data);
  }

  updateAddressTier(addressId, payload) {
    const url = `api/v1/customer/addresses/${addressId}`;
    return this.httpClient.put(url, payload);
  }

  getAddressTier(addressId, serviceTypeKey, homekeeperId) {
    const url = `api/v1/customer/addresses/${addressId}/new-tier-service?service_type_key=${serviceTypeKey}&homekeeper_id=${homekeeperId}`
    return this.httpClient.get(url);
  }

}

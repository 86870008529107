import {
  Component,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import {
  onClickActionMonthlyView,
  Day,
  Job,
  Period,
  Property,
} from 'src/models/schedule-weekly-view.model';
import { DateTime as LuxonDateTime } from 'luxon';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'schedule-monthly-view.html',
  styleUrls: ['./schedule-monthly-view.scss'],
  selector: 'tidy-schedule-monthly-view',
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleMonthlyViewComponent implements OnChanges, OnInit {

  @Input() selectedPeriodRange: Period;
  @Input() properties: Property[];
  today = LuxonDateTime.local().toISODate();
  month: number;
  year: number;
  days: Day[] = [];
  wasCardClicked = false;
  @Output() onClickAction = new EventEmitter<onClickActionMonthlyView>();
  windowWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    this.totalJobsToShow = Object.keys(this.jobsQuantityBreakpoints).reduce(
      (prev, curr) => {
        if (windowHeight > parseInt(curr)) {
          return this.jobsQuantityBreakpoints[curr];
        }
        return prev;
      },
      0
    );
  }
  @Input() isLoading: boolean;
  totalJobsToShow: number;
  jobsQuantityBreakpoints = {
    '0': 1,
    '830': 2,
    '978': 3,
  };
  jobNamesLengthTruncateBreakpoints = {
    '0': 10,
    '1070': 15,
  };
  language: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    this.totalJobsToShow = Object.keys(this.jobsQuantityBreakpoints).reduce(
      (prev, curr) => {
        if (windowHeight > parseInt(curr)) {
          return this.jobsQuantityBreakpoints[curr];
        }
        return prev;
      },
      0
    );
    if (changes['selectedPeriodRange'] || changes['properties']) {
      const month = LuxonDateTime.fromISO(
        this.selectedPeriodRange.endDate
      ).month;
      const year = LuxonDateTime.fromISO(this.selectedPeriodRange.endDate).year;
      this.generateCalendar(month, year);
    }
  }

  ngOnInit(): void {
    this.language = localStorage.getItem('language');
    this.windowWidth = window.innerWidth;
    this.month = LuxonDateTime.local().month;
    this.year = LuxonDateTime.local().year;
  }

  /**
   * Generate the calendar for the given month and year
   * @param month The month to generate the calendar for
   * @param year The year to generate the calendar for
   */
  generateCalendar(month: number, year: number): void {
    this.days = [];
    const firstDay = LuxonDateTime.local(year, month, 1).weekday % 7;
    const lastDate = LuxonDateTime.local(year, month).endOf('month').day;
    let prevLastDate = LuxonDateTime.local(year, month - 1).endOf('month').day;
    let isPreviousYear = false;
    if (isNaN(prevLastDate)) {
      prevLastDate = LuxonDateTime.local(year - 1, 12).endOf('month').day;
      isPreviousYear = true;
    }
    const monthName = LuxonDateTime.local(year, month).monthLong;
    const translatedMonth = new TranslationPipe().transform(monthName);
    const shortMonth = translatedMonth.slice(0, 3);
    // INFO: Fill in the days from the previous month
    for (let i = firstDay; i > 0; i--) {
      this.days.push({
        date: prevLastDate - i + 1,
        otherMonth: true,
        previousMonth: true,
        isWeekend: false,
        isToday: false,
        nextMonth: false,
        isFirstWeek: true,
        fullDate: LuxonDateTime.local(
          isPreviousYear ? year - 1 : year,
          isPreviousYear ? 12 : month - 1,
          prevLastDate - i + 1
        ).toISODate(),
      });
    }

    // INFO: Fill in the days of the current month
    for (let i = 1; i <= lastDate; i++) {
      const isToday =
        LuxonDateTime.local().day === i &&
        LuxonDateTime.local().month === month &&
        LuxonDateTime.local().year === year;
      this.days.push({
        date: i,
        shortMonth,
        otherMonth: false,
        previousMonth: false,
        isWeekend: false,
        isFirstWeek: this.days.length < 7,
        isToday,
        nextMonth: false,
        fullDate: LuxonDateTime.local(year, month, i).toISODate(),
      });
    }
    // Fill in the days of the next month
    const totalDays = firstDay + lastDate;
    const nextDays = (7 - (totalDays % 7)) % 7;
    let nextMonthLong = LuxonDateTime.local(year, month + 1).monthLong;
    let isNextYear = false;
    if (nextMonthLong === null) {
      nextMonthLong = LuxonDateTime.local(year + 1, 1).monthLong;
      isNextYear = true;
    }
    const nextMonthTranslated = new TranslationPipe().transform(nextMonthLong);
    const nextMonthShort = nextMonthTranslated.slice(0, 3);
    for (let i = 1; i <= nextDays; i++) {
      this.days.push({
        date: i,
        otherMonth: true,
        previousMonth: false,
        nextMonth: true,
        isWeekend: false,
        isToday: false,
        isFirstWeek: false,
        shortMonth: nextMonthShort,
        fullDate: LuxonDateTime.local(
          isNextYear ? year + 1 : year,
          isNextYear ? 1 : month + 1,
          i
        ).toISODate(),
      });
    }

    // INFO: Mark weekends
    this.days.forEach((day) => {
      const date = LuxonDateTime.fromISO(day.fullDate);
      const dayOfWeek = date.weekday;
      if (dayOfWeek === 6 || dayOfWeek === 7) {
        day.isWeekend = true;
        day['isSaturday'] = dayOfWeek == 6;
      }
    });

    this.addJobsToDays();
    this.addReservationsToDays();
  }

  addJobsToDays(): void {
    try {
      this.days.forEach((day) => {
        const filteredJobs = this.properties
          .map((property) =>
            property.filteredJobs.filter(
              (job) => job.startDate === day.fullDate
            )
          )
          .flat();
        const filteredAvailabilityRequests = this.properties
          .map((property) =>
            property.filteredAvailabilityRequests.filter(
              (availability) => availability.startDate === day.fullDate
            )
          )
          .flat();
        day.filteredJobs = [...filteredJobs, ...filteredAvailabilityRequests];
      });
    } catch (error) {
      console.error('Error adding jobs to days', error);
    }
  }

  addReservationsToDays(): void {
    try {
      this.days.forEach((day) => {
        const filteredReservations = this.properties
          .map((property) =>
            property.filteredReservations.filter(
              (reservation) =>
                reservation?.startDate === day.fullDate ||
                reservation?.endDate === day.fullDate
            )
          )
          .flat();
        day.filteredReservations = filteredReservations;
        const startingReservationsAtThisDay = filteredReservations.filter(
          (reservation) => LuxonDateTime.fromFormat(reservation?.object?.check_in_date, 'MMM dd, yyyy').toFormat('yyyy-MM-dd') === day.fullDate
        );
        const endingReservationsAtThisDay = filteredReservations.filter(
          (reservation) => LuxonDateTime.fromFormat(reservation?.object?.check_out_date, 'MMM dd, yyyy').toFormat('yyyy-MM-dd') === day.fullDate
        );
        day.reservationInfo = {
          starting: startingReservationsAtThisDay.length,
          ending: endingReservationsAtThisDay.length,
        };
      });
    } catch (error) {
      console.error('Error adding reservations to days', error);
    }
  }

  onClickJob(job: Job): void {
    this.wasCardClicked = true;
    this.onClickAction.emit({
      type: 'job',
      job,
      properties: this.properties,
    });
  }

  async onClickDay(day: any): Promise<void> {
    const wasCardClicked = await new Promise((resolve) =>
      setTimeout(() => {
        if (this.wasCardClicked) {
          resolve(this.wasCardClicked);
          return;
        }
        resolve(null);
      }, 100)
    );
    if (wasCardClicked) {
      this.wasCardClicked = false;
      return;
    }
    this.onClickAction.emit({
      type: 'day',
      day,
      properties: this.properties,
    });
  }

  async onClickReservation(day: any): Promise<void> {
    this.wasCardClicked = true;
    this.onClickAction.emit({
      type: 'reservation',
      day,
      properties: this.properties,
    });
  }

  getReservationText(day: any): string {
    if (day.reservationInfo?.starting > 0 && day.reservationInfo?.ending > 0) {
      return `${day.reservationInfo.starting} ${new TranslationPipe().transform('in')} / ${day.reservationInfo.ending} ${new TranslationPipe().transform('out')}`;
    } else if (day.reservationInfo?.ending > 0) {
      return `${day.reservationInfo.ending} ${new TranslationPipe().transform('out')}`;
    } else if (day.reservationInfo?.starting > 0) {
      return `${day.reservationInfo.starting} ${new TranslationPipe().transform('in')}`;
    }
    return '';
  }

  getJobNameTruncateBreakpoint(): number {
    const breakpoints = Object.entries(this.jobNamesLengthTruncateBreakpoints)
      .sort(([a], [b]) => parseInt(b) - parseInt(a));

    for (const [width, truncateSize] of breakpoints) {
      if (this.windowWidth > parseInt(width)) {
        return truncateSize;
      }
    }

    return breakpoints[breakpoints.length - 1][1];
  }
}

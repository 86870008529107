import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { EmployerService } from 'src/providers/employer/employer';

@Component({
  templateUrl: 'campaign.html'
})

export class EmployerCampaignPage implements OnInit {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  rows: any;
  headers: any;
  campaigns: Partial<{
    name: string,
    frequency: string,
    description: string
  }>[] = [];
  loaded: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private employerService: EmployerService
  ) {}

  async ngOnInit() {
    try {
      this.campaigns = await this.employerService.listCampaigns();
      this.rows = this.buildRows();
      this.headers = this.buildHeaders();
      this.loaded = true;
    } catch (err) {
    }
  }

  buildHeaders() {
    return [
      'Name',
      'Frequency',
      'Description',
      'Status'
    ];
  }

  buildRows() {
   const dataSrc = this.campaigns.length ? this.campaigns : [{}];
   return dataSrc.map(campaign => {
      return {
        'Name': {
          value: campaign.name,
          action: null
        },
        'Frequency': {
          value: campaign.frequency,
          action: null
        },
        'Description': {
          value: campaign.description,
          action: null
        },
        'Status': {
          value: 'Active',
          action: null
        }
      }
    });
  }

  newCampaign() {

  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatState' })
export class FormatStatePipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (value === 'cancelled' || value === 'cancelled_once') {
      value = 'last minute cancellation';
    }

    if (value) {
      return value.replace('_', ' ');
    }
  }
}

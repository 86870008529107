import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';

@Component({
  template: `
    <ng-container *ngFor="let photoNote of photoUrls; let i = index;">
      <ng-container *ngIf="!photoNote.deleted">
        <tidy-row class="text-center extra-bottom-padding field-top-padding">
          <tidy-image
            class="hero-size"
            [src]="photoNote.url">
          </tidy-image>
        </tidy-row>
  
        <tidy-row class="extra-bottom-padding">
          <tidy-input
            [label]="'Add the photo caption'"
            [icon]="'assets/svg/camera-outline.svg'"
            [activeIcon]="'assets/svg/camera-outline-tidy-green.svg'"
            [(ngModel)]="photoNote.photoNoteCaption"
            (input)="markChanged(photoNote)"
            [form]="control">
          </tidy-input>
        </tidy-row>
  
        <tidy-row class="text-center">
          <tidy-text
            [body]="'Delete Photo'"
            class="red"
            (action)="deleteClick(photoNote.id, i)">
          </tidy-text>
        </tidy-row>
      </ng-container>
    </ng-container>
  `,
  selector: 'photo-caption'
})
export class PhotoCaption {
  @Input() photoUrls: PhotoNoteUrlModel[];
  @Output() deletePhoto: EventEmitter<number> = new EventEmitter<number>();
  @Input() type: string;
  control = new UntypedFormControl();
  subscription: Subscription;
  startDelete = false;
  deleteImageIndex: number;

  constructor(
    private photoCaptionService: PhotoCaptionService
  ) {}

  async deleteClick(photoId: number, imageIndex: number) {
    this.deleteImageIndex = imageIndex;

    if (photoId === -1) {
      await this.removeImage();

      return;
    }

    this.deletePhoto.emit(photoId);
    this.startDelete = true;
    this.subscribeDeleteImageListener();
  }

  subscribeDeleteImageListener() {
    this.photoCaptionService.deleteImage$.pipe(take(1)).subscribe(async () => {
      if (this.startDelete) {
        await this.removeImage();
      }
    });
  }

  async removeImage() {
    this.photoUrls.splice(this.deleteImageIndex, 1);

    this.startDelete = false;
  }

  markChanged(photoNote) {
    photoNote['captionChanged'] = true;
  }
}

export interface PhotoNoteUrlModel {
  id: number;
  url: string;
  type: string;
  photoNoteCaption: string;
  captionChanged: boolean;
  deleted: boolean;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ThumbtackCategoriesInterface,
  GetProListParamsModel,
  ThumbtackProListResponse,
  ThumbtackCategoryModel,
} from 'src/models/thumbtack.model';

const THUMBTACK_AUTH_TOKEN = "VGlkeTpOa29XQWUzX1ZTQktZR3hUVnN4ZzhtVkJPX2drbUZUcmJqWDFrRkpmUDRnPQ==";

@Injectable()
export class ThumbtackProvider {
  thumbtackCategoriesReferences: ThumbtackCategoriesInterface = {
    'Appliance Repair': {
      categoryName: 'Appliance Repair or Maintenance',
      categoryId: '166573972257055145',
    },
    'Carpet Cleaning': {
      categoryName: 'Carpet Cleaning',
      categoryId: '122435952865247528',
    },
    'Junk Removal': {
      categoryName: 'Junk Removal',
      categoryId: '150848602323501530',
    },
    'House Cleaning': {
      categoryName: 'House Cleaning',
      categoryId: '219264413294461288',
    },
    'Pressure Washing': {
      categoryName: 'Pressure Washing',
      categoryId: '124317505632420266',
    },
    'Window Washing': {
      categoryName: 'Window Cleaning',
      categoryId: '122707076295909723',
    },
    Locksmith: {
      categoryName: 'Lock Installation and Repair',
      categoryId: '135130438449717531',
    },
    Handyman: {
      categoryName: 'Handyman',
      categoryId: '109125193401647362',
    },
    Inspection: {
      categoryName: 'Home Inspection',
      categoryId: '152053870920155482',
    },
    'Lawn and Garden': {
      categoryName: 'Full Service Lawn Care',
      categoryId: '240123621172183344',
    },
    'Tree Trimming': {
      categoryName: 'Tree Trimming and Removal',
      categoryId: '240142059022278971',
    },
    Painting: {
      categoryName: 'Interior Painting',
      categoryId: '122681972262289742',
    },
    Plumbing: {
      categoryName: 'Plumbing Drain Repair',
      categoryId: '124332799881036214',
    },
    'Pool Cleaning': {
      categoryName: 'Swimming Pool Cleaning, Maintenance, and Inspection',
      categoryId: '151786726772498727',
    },
    'Pest Control': {
      categoryName: 'Pest Control Services',
      categoryId: '133665232699441654',
    },
    'Cleaning': {
      categoryName: 'House Cleaning',
      categoryId: '219264413294461288',
    },
  };

  constructor(private httpClient: HttpClient) {}

  async getProList(
    params: GetProListParamsModel
  ): Promise<ThumbtackProListResponse> {
    const url = 'https://api.thumbtack.com/v1/partners/discoverylite/pros';
    const response = (await this.httpClient
      .get(url, {
        params: {
          zip_code: params.zipCode,
          category_pk: params.categoryId,
          utm_source: 'cma-tidy',
        },
        headers: {
          Authorization: 'Basic ' + THUMBTACK_AUTH_TOKEN,
        },
      })
      .toPromise()) as ThumbtackProListResponse;
    return response;
  }

  getThumbtackCategory(tidyCategoryName: string): ThumbtackCategoryModel {
    return this.thumbtackCategoriesReferences[tidyCategoryName];
  }
  
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [title]="'Messages'"
    [class]="extraClass"
    [canGoBack]="!windowService.isDesktopRes"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-desktop-header *ngIf="windowService.isDesktopRes">
        <tidy-text
          [title]="'Messages'">
        </tidy-text>
        <ng-container *ngIf="!wasTableLoaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 543px;
              height: 27px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="wasTableLoaded">
          <form [formGroup]="searchForm" novalidate action="#">
            <tidy-search-input
              formControlName="search"
              [placeholder]="'Search'"
              [icon]="'assets/svg/search-outline.svg'">
            </tidy-search-input>
          </form>
          <form
            [formGroup]="form"
            novalidate
            action="#"
            class="messages-form-filters">
            <!--<div class="desktop-item desktop-message-item">
              <tidy-select
                class="messages-select-custom-input"
                [label]="'Person'"
                [items]="proList"
                [multiple]="true"
                [form]="form.get('selectedPro')"
                formControlName="selectedPro"
                (optionChange)="onFilterValueChange($event, 'pro')">
              </tidy-select>
            </div>
            <div class="desktop-item desktop-message-item">
              <tidy-select
                class="messages-select-custom-input"
                [label]="'Property'"
                [items]="addressFilter"
                [multiple]="true"
                [form]="form.get('selectedAddress')"
                formControlName="selectedAddress"
                (optionChange)="onFilterValueChange($event, 'address')">
              </tidy-select>
            </div>!-->
            <div class="desktop-item desktop-bills-item daterange-card">
              <div class="daterange-custom-container">
                <tidy-daterange
                  class="daterange-custom-input"
                  [label]="'Date Range'"
                  (customDateOption)="selectCustomDateRange($event)"
                  (stringDateOption)="selectStringDateRange($event)"
                  [(dateRangeForm)]="dateRangeForm"
                  [selectOptions]="selectOptions"
                  [rangeOption]="true"
                  [appearance]="'outline'"
                  [header]="true">
                </tidy-daterange>
              </div>
            </div>
          </form>
          <div class="desktop-nav-button">
            <tidy-button
              [image]="'assets/icon/filter-white.png'"
              [text]="'Filter'"
              [smallBody]="true"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{closeDisabled: true}">
            </tidy-button>
          </div>
          <div
            class="message-action-buttons-header"
            *ngIf="!showDesktopHamburgerMenu">
            <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 10px"></ion-spinner>
            <div class="desktop-nav-button" style="margin-right: 10px">
              <tidy-button
                [text]="guestNumber ? ('Guest Smart #: ' | translate) + guestNumber : '+ Guest Smart Number'"
                [smallBody]="true"
                (click)="openAddProxyNumberModal('guest')">
              </tidy-button>
            </div>
            <div class="desktop-nav-button">
              <tidy-button
                [text]="proNumber ? ('Pro Smart #: ' | translate) + proNumber : '+ Pro Smart Number'"
                [smallBody]="true"
                (click)="openAddProxyNumberModal('pro')">
              </tidy-button>
            </div>
          </div>
          <div class="spinner-settings-container">
            <div *ngIf="showDesktopHamburgerMenu">
              <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 45px"></ion-spinner>
              <ion-fab
                class="desktop-hamburger custom-fab-position"
                (click)="toggleDesktopHamburger()"
                [class.fab-open]="open">
                <ion-img
                  [src]="'assets/img/menu-more-black.svg'"
                  class="desktop-hamburger-icon"></ion-img>
                <ion-fab-list
                  [class.fab-list-active]="showExpandedDesktopHamburger"
                  class="desktop-hamburger-fab-list-container">
                  <div class="desktop-hamburger-button">
                    <tidy-button
                      [text]="guestNumber ? 'Guest Smart #: ' + guestNumber : '+ Guest Smart Number'"
                      [smallBody]="true"
                      (click)="openAddProxyNumberModal('guest')">
                    </tidy-button>
                  </div>
                  <div class="desktop-hamburger-button">
                    <tidy-button
                      [text]="proNumber ? 'Pro Smart #: ' + proNumber : '+ Pro Smart Number'"
                      [smallBody]="true"
                      (click)="openAddProxyNumberModal('pro')">
                    </tidy-button>
                  </div>
                </ion-fab-list>
              </ion-fab>
            </div>
          </div>
        </ng-container>
      </tidy-desktop-header>

      <ng-container *ngFor="let chip of chips">
        <ion-chip class="filter-chip">
          <ion-label>{{ chip.displayParentName | titlecase | translate}}: {{ chip.name | titlecase | translate }}</ion-label>
          <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
        </ion-chip>
      </ng-container>
      <mat-menu #menu="matMenu" class="filter-menu">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
              <mat-checkbox
                class="checkbox"
                [checked]="getNodeChecked(node)"
                (click)="$event.stopPropagation()"
                (change)="checkNode(node, $event)">
                {{ node.name | titlecase | translate }}
              </mat-checkbox>
            </button>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding>
            <button
              class="node-button-padding"
              mat-menu-item
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
              {{ node.name | titlecase | translate }}
            </button>
          </mat-tree-node>
        </mat-tree>
      </mat-menu>

      <!--<tidy-desktop-header *ngIf="windowService.isDesktopRes">
        <tidy-text
          [title]="'Messages'">
        </tidy-text>
        <ng-container *ngIf="isFiltersLoaded">
          <div class="desktop-nav-search">
            <form [formGroup]="searchForm" novalidate action="#">
              <tidy-input
                #searchInput
                class="search-input"
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="searchForm">
              </tidy-input>
            </form>
          </div>
          <form
            [formGroup]="form"
            novalidate
            action="#"
            class="messages-form-filters">
            <div class="desktop-item desktop-message-item">
              <tidy-select
                class="messages-select-custom-input"
                [label]="'Person'"
                [items]="proList"
                [multiple]="true"
                [form]="form.get('selectedPro')"
                formControlName="selectedPro"
                (optionChange)="onFilterValueChange($event, 'pro')">
              </tidy-select>
            </div>
            <div class="desktop-item desktop-message-item">
              <tidy-select
                class="messages-select-custom-input"
                [label]="'Property'"
                [items]="addressFilter"
                [multiple]="true"
                [form]="form.get('selectedAddress')"
                formControlName="selectedAddress"
                (optionChange)="onFilterValueChange($event, 'address')">
              </tidy-select>
            </div>
            <div class="desktop-item desktop-message-item daterange-card">
              <div class="daterange-custom-container">
                <tidy-daterange
                  class="daterange-custom-input"
                  [label]="'Date Range'"
                  (customDateOption)="selectCustomDateRange($event)"
                  (stringDateOption)="selectStringDateRange($event)"
                  [(dateRangeForm)]="dateRangeForm"
                  [selectOptions]="selectOptions"
                  [rangeOption]="true"
                  [appearance]="'outline'">
                </tidy-daterange>
              </div>
            </div>
          </form>
          <div
            class="message-action-buttons-header"
            *ngIf="!showDesktopHamburgerMenu">
            <div class="desktop-nav-button" style="margin-right: 10px">
              <tidy-button
                [text]="guestNumber ? 'Guest Smart #: ' + guestNumber : '+ Guest Smart Number'"
                [smallBody]="true"
                (click)="openAddProxyNumberModal('guest')">
              </tidy-button>
            </div>
            <div class="desktop-nav-button">
              <tidy-button
                [text]="proNumber ? 'Pro Smart #: ' + proNumber : '+ Pro Smart Number'"
                [smallBody]="true"
                (click)="openAddProxyNumberModal('pro')">
              </tidy-button>
            </div>
          </div>
          <div class="spinner-settings-container">
            <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 45px"></ion-spinner>
            <div *ngIf="showDesktopHamburgerMenu">
              <ion-fab
                class="desktop-hamburger custom-fab-position"
                (click)="toggleDesktopHamburger()"
                [class.fab-open]="open">
                <ion-img
                  [src]="'assets/img/menu-more-black.svg'"
                  class="desktop-hamburger-icon"></ion-img>
                <ion-fab-list
                  [class.fab-list-active]="showExpandedDesktopHamburger"
                  class="desktop-hamburger-fab-list-container">
                  <div class="desktop-hamburger-button">
                    <tidy-button
                      [text]="guestNumber ? 'Guest Smart #: ' + guestNumber : '+ Guest Smart Number'"
                      [smallBody]="true"
                      (click)="openAddProxyNumberModal('guest')">
                    </tidy-button>
                  </div>
                  <div class="desktop-hamburger-button">
                    <tidy-button
                      [text]="proNumber ? 'Pro Smart #: ' + proNumber : '+ Pro Smart Number'"
                      [smallBody]="true"
                      (click)="openAddProxyNumberModal('pro')">
                    </tidy-button>
                  </div>
                </ion-fab-list>
              </ion-fab>
            </div>
          </div>
          
        </ng-container>
        <ng-container *ngIf="!isFiltersLoaded">
          <ion-skeleton-text
            animated="true"
            style="
              width: 573px;
              height: 40px;
              border-radius: 6px;">
          </ion-skeleton-text>
        </ng-container>
      </tidy-desktop-header>-->
      
      <div *ngIf="loaded && !windowService.isDesktopRes" class="mobile-padding">
        <ion-row class="messages-mobile-header">
          <ion-col>
            <form [formGroup]="searchForm" novalidate action="#">
              <tidy-input
                #searchInput
                class="search-input"
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="searchForm">
              </tidy-input>
            </form>
          </ion-col>
          <ion-col>
            <tidy-select
              class="messages-select-custom-input"
              [label]="'Pro'"
              [items]="proList"
              [multiple]="true"
              [form]="form.get('selectedPro')"
              formControlName="selectedPro"
              (optionChange)="onFilterValueChange($event, 'pro')">
            </tidy-select>
          </ion-col>
          <ion-col>
            <tidy-select
              class="messages-select-custom-input"
              [label]="'Property'"
              [items]="addressFilter"
              [multiple]="true"
              [form]="form.get('selectedAddress')"
              formControlName="selectedAddress"
              (optionChange)="onFilterValueChange($event, 'address')">
            </tidy-select>
          </ion-col>
          <ion-col>
            <div class="daterange-card">
              <div class="daterange-custom-container">
                <tidy-daterange
                  class="daterange-custom-input"
                  [label]="'Date Range'"
                  (customDateOption)="selectCustomDateRange($event)"
                  (stringDateOption)="selectStringDateRange($event)"
                  [(dateRangeForm)]="dateRangeForm"
                  [selectOptions]="selectOptions"
                  [rangeOption]="true"
                  [appearance]="'outline'">
                </tidy-daterange>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>

      <ng-container *ngIf="wasTableLoaded">
        <tidy-card *ngIf="currentRows?.length > 0" class="no-padding">
          <tidy-table
            class="messages-table"
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            [allRowIsClickable]="true"
            [rowsToShow]="10"
            [showColumnLines]="false"
            [totalRows]="currentRows.length"
            (sortChange)="sortChanged($event)"
            (cellClick)="onTableColumnClick($event)">
          </tidy-table>
        </tidy-card>
      </ng-container>
      <ng-container *ngIf="currentRows?.length === 0">
        <tidy-alert style="margin-bottom: 10px">
          <tidy-text [body]="'No Results'"> </tidy-text>
        </tidy-alert>
      </ng-container>

      <tidy-card style="padding: 0px; height: 600px; padding: 0px;" *ngIf="!wasTableLoaded">
        <ion-skeleton-text animated style="width: 100%; height: 600px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
      </tidy-card>

    <div class="floating-div">
      <button class="custom-floating-button" (click)="openNewMessageModal()">
        <div class="button-content">
          <div>
            <tidy-image [src]="'assets/icon/chat.png'" class="chat-icon-size">
            </tidy-image>
          </div>
          <div>
            <tidy-text
              [header]="'Send Message'"
              class="send-message-text"></tidy-text>
          </div>
        </div>
      </button>
    </div>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content"
  [desktopWidthContent]="desktopWidthContent">
</app-scroll-wrapper>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'messageAutomation'">
</tidy-onboarding-modal>

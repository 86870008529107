import { Component, ViewEncapsulation } from '@angular/core';
import { OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'add-integration.html',
  encapsulation: ViewEncapsulation.None
})

export class AddIntegrationPage implements OnInit {

  isUpdatingIntegration: boolean;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.isUpdatingIntegration = await this.getParam('isUpdatingIntegration');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

}

import { Component } from '@angular/core';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'input-access-notes-onboarding',
  templateUrl: './input-access-notes-onboarding.html',
  styleUrls: ['./input-access-notes-onboarding.scss']
})
export class InputAccessNotesOnboarding {
  constructor(
    public onboardingProvider: OnboardingProvider
  ) {}
} 
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';

@Injectable()
export class AccountTypeGuard implements CanActivate {

  constructor (
    private me: Me,
    private navCtrl: CustomNavController
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const accType = await this.me.getAccType();

    if (accType == 'employer') {
      this.navCtrl.navigateRoot('employer-dashboard/employer-dashboard');
      return false;
    }

    return true;
  }
}

import { Injectable } from "@angular/core";
import { HttpClientCustom } from '../custom/http-client';

interface GiftCodeType {
  gift_code: string
}

@Injectable()
export class GiftCode {
  constructor(private httpClient: HttpClientCustom) {}

  saveGiftCard (data: GiftCodeType) {
    const url = `api/v1/customer/billing/gift/redeem`
    return this.httpClient.post(url, data)
  }
}

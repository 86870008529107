import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'pro-did-not-show-up.html',
  encapsulation: ViewEncapsulation.None
})

export class ProDidNotShowUpPage implements OnInit {

  blockPro: boolean;
  errorMessage: string;
  getCredit: boolean;
  hkId: string;
  jobId: string;
  proName: string;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private client: Client,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Pro Did Not Show Up');
    this.loadData();
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.getCredit = true;
    this.jobId = await this.getParam('jobId');
    this.proName = await this.getParam('proName');
    this.hkId = await this.getParam('hkId');
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  submit() {
    this.errorMessage = '';
    const data = {
      'homekeeper_ids': [],
      'add_credit': false,
      'job_id': this.jobId,
      'amount': 0,
      'performances': [
        {
          'performance': 'no_show',
          'homekeeper_id': this.hkId
        }
      ]
    };
    if (this.getCredit) {
      data.add_credit = true;
      data.amount = 1000;
    }
    if (this.blockPro) {
      data.homekeeper_ids.push(this.hkId);
    }
    try {
      this.client.markNoShow(data);
      this.goToSuccessPage();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToSuccessPage() {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const params = {
      header: 'No Show Reported',
      body: 'You will not be billed for this and can request a substitute in the next page if you\'d like.  Thanks for your patience.',
      buttonText: 'Ok',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
    this.rightSidePanelService.navigateTo('success', params);
  }

  goBack() {
    if (this.dialogParams) {
      this.rightSidePanelService.goBack();
      return;
    }
    this.navCtrl.back();
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'choose-address-to-book.html'
})

export class ChooseAddressToBookPage implements OnInit {

  addressItems: TidySelectNumberValueModel[];
  clientHubUuId: any;
  form: UntypedFormGroup;
  loaded: boolean;
  proposal: any;
  submitted: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private proposals: Proposals
  ) {
    this.form = this.formBuilder.group({
      address: ['', Validators.required],
    })
  }

  async ngOnInit() {
    this.loaded = false;
    this.proposal = this.navCtrl.getParam('proposal');
    this.clientHubUuId = this.navCtrl.getParam('clientHubUuId')
    this.addressItems = this.buildAdressItems();
    this.loaded = true;
  }

  buildAdressItems() {
    let array = [];
    this.proposal.customer.address_ids.map((addressId, index) => {
      array.push({
        viewValue: this.proposal.customer.addresses[index]?.address_name ? this.proposal.customer.addresses[index]?.address_name : this.proposal.customer.addresses[index]?.address,
        value: addressId
      });
    });
    return array;
  }

  async chooseAddress() {
    this.proposal.customer.address_ids = [this.form.value.address];
    const clientHasAccount = this.navCtrl.getParam('clientHasAccount');
    await this.proposals.bookJobForProposal(clientHasAccount, this.proposal, this.clientHubUuId);
  }

  goToClientHub() {
    this.navCtrl.navigateForward(`client/${this.clientHubUuId}`);
  }
}

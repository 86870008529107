<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'First name *'"
            formControlName="firstName"
            [form]="form.controls.firstName"
            [icon]="'assets/svg/person-circle-outline.svg'"
            [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter employee\'s first name'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-input
            [label]="'Last name *'"
            formControlName="lastName"
            [form]="form.controls.lastName"
            [icon]="'assets/svg/person-circle-outline.svg'"
            [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter employee\'s last name'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input
            [label]="'Email *'"
            formControlName="email"
            type="email"
            [form]="form.controls.email"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-button
      [text]="'Save'"
      [action]="saveChanges.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<ion-content class="ion-content-right-column">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>
import { Component, EventEmitter, Output, Input } from "@angular/core";

@Component({
  templateUrl: 'drop-down-menu.html',
  styleUrls: ['./drop-down-menu.scss'],
  selector: 'tidy-drop-down-menu'
})
export class DropDownMenu {

  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Input() icon: string; 
  @Input() initialsIcon: string; 
  
  constructor() {

  }

  menuClosed(reason: any) {
    this.closed.emit(reason);
  }  
}

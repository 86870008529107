import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { isDate } from 'moment';
import { debounceTime } from 'rxjs/operators';
import { CapitalizePipe } from 'src/shared/pipes/capitalize.pipe';
import { DateTime } from 'luxon';
import {
  FieldOption,
  FormField,
  FormValues,
  InputField,
  InputOption,
  OperatorInput,
} from './filter-nested-form.interface';
import { Subscription } from 'rxjs';
import {
  ChipPlaceholder,
  ChipTextArea,
  InputFocusOut,
} from 'src/shared/components/chip-text-area/chip-text-area';

@Component({
  selector: 'filter-nested-form',
  templateUrl: 'filter-nested-form.html',
  styleUrls: ['filter-nested-form.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideFromTop', [
      transition(':enter', [
        style({ transform: 'translateY(-10%)', opacity: 0.5 }),
        animate(
          '0.3s ease-out',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-in',
          style({ transform: 'translateY(-20%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class FilterNestedFormComponent implements OnInit, OnChanges, OnDestroy {
  nestedForm: FormGroup;
  @Input() outputs: FieldOption[] = [];
  @Input() inputOptions: OperatorInput[] = [];
  @Input() filterGroups: FormValues[][] = [];
  conditions: FieldOption[] = [];
  operatorFields: FormField[][][] = [];
  @Output() formValues = new EventEmitter<FormValues[][]>();
  formSubscription: Subscription;
  chips: ChipPlaceholder[] = [];
  @ViewChildren(ChipTextArea) chipTextAreaComponents!: QueryList<ChipTextArea>;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    try {
      this.getConditions();
      this.createForm();
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inputOptions) {
      this.inputOptions = changes.inputOptions.currentValue;
      this.getConditions();
    }
    if (changes.outputs) {
      this.outputs = changes.outputs.currentValue;
      if (!changes.outputs.previousValue && this.outputs) {
        this.createForm();
      }
    }
  }

  /**
   * Initialize the form
   */
  createForm() {
    this.nestedForm = this.fb.group({
      forms: this.fb.array([]),
    });
    if (!this.outputs) {
      console.warn('No outputs found');
      return;
    }
    if (this.filterGroups) {
      this.patchForm();
    } else {
      this.addNestedForm();
      this.patchFirstChipTextAreaWithChips();
    }
    this.watchFormValueChanges();
  }

  /**
   * Patch the form
   */
  patchForm() {
    setTimeout(() => {
      try {
        this.filterGroups.forEach((group, groupIndex) => {
          const form = this.fb.array([]) as FormArray;
          const fields = this.getInitialFields();
          this.operatorFields.push([fields]);
          group.forEach((field, fieldIndex) => {
            const isRightValueDateType = this.checkIfDate(field.right_value);
            const rightValue = this.getParsedRightValue(field);
            const fieldToBePatched = {
              left_value: [field.left_value],
              operator: [field.operator],
              right_value: [rightValue],
            };
            if (field?.timezone) {
              fieldToBePatched['timezone'] = [field.timezone];
            }
            form.push(this.fb.group(fieldToBePatched));
            const fields = this.operatorFields[groupIndex];
            const initialFields = this.getInitialFields();
            fields.push(initialFields);
            this.onConditionChanges(
              field.operator,
              form.controls[fieldIndex] as FormGroup,
              groupIndex,
              fieldIndex
            );
            this.handleDateInputPatch(
              field,
              groupIndex,
              fieldIndex,
              isRightValueDateType
            );
          });
          this.forms.push(form);
        });
      } catch (error) {
        console.error(error);
      }
    }, 500);
  }

  /**
   * Handle the initialization of the chip text area
   * @param id
   * @param field
   */
  onChipTextAreaInit(id: string, field: FormGroup) {
    setTimeout(() => {
      this.chips = this.parseOutputsToChips(this.outputs);
      const chipTextArea = this.chipTextAreaComponents.find(
        (chip) => chip.id === id
      );
      chipTextArea.body = {
        content: field?.value,
        chips: this.chips,
      };
      chipTextArea.parseAndSetBody();
    }, 1000);
  }

  /**
   * Handle the patch of the date input
   * @param field
   * @param groupIndex
   * @param fieldIndex
   * @param isRightValueDateType
   */
  handleDateInputPatch(
    field: FormValues,
    groupIndex: number,
    fieldIndex: number,
    isRightValueDateType: boolean
  ) {
    if (field?.operator?.toLowerCase()?.includes('date')) {
      const operatorFields = this.operatorFields[groupIndex][fieldIndex];
      const rightValueInput = operatorFields.find(
        (input) => input?.formControlName === 'right_value'
      );
      const regex = /{([^}]+)}}/g;
      const matches = field?.right_value?.match(regex);
      if (matches) {
        rightValueInput.type = 'dateText';
        return;
      }
      if (isRightValueDateType) {
        if (rightValueInput) {
          rightValueInput.type = 'date';
        }
      } else {
        rightValueInput.dateRangeForm = this.fb.group({
          start: '',
          end: '',
          customStringSelect: field.right_value,
        });
      }
    }
  }

  /**
   * Check if the value is a date
   * @param value
   * @returns
   */
  checkIfDate(value: string): boolean {
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    return dateRegex.test(value);
  }

  /**
   * Get the parsed right value
   * @param rightValue
   * @returns
   */
  getParsedRightValue(field: FormValues): string | Date {
    const rightValue = field.right_value;

    const isDateType = this.checkIfDate(rightValue);
    if (isDateType) {
      return DateTime.fromFormat(rightValue, 'MM/dd/yyyy').toJSDate();
    }

    if (field.operator.includes('money')) {
      return `${Number(rightValue) / 100}`;
    }

    return rightValue;
  }

  /**
   * Watch the form value changes
   */
  watchFormValueChanges(): void {
    this.formSubscription = new Subscription();
    this.formSubscription = this.forms.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => this.parseAndEmitFormValues(value));
  }

  /**
   * Parse and emit the form values
   * @param forms
   */
  parseAndEmitFormValues(forms: FormValues[][]): void {
    const parsedValues = forms.map((form) => {
      return form.map((field) => {
        const rightValue = field?.right_value as string;
        if (rightValue && isDate(rightValue)) {
          field.right_value =
            DateTime.fromJSDate(rightValue).toFormat('MM/dd/yyyy');
        }
        if (rightValue && rightValue.includes('$')) {
          field.right_value = `${Number(rightValue.replace(/\$|\s/g, '')) * 100}`
        }
        return field;
      });
    });
    console.log(parsedValues);
    this.formValues.emit(parsedValues);
  }

  /**
   * Get the conditions from input options
   */
  getConditions(): void {
    if (!this.inputOptions) {
      return;
    }
    this.conditions = this.inputOptions.map((inputOption) => {
      return {
        viewValue: inputOption.operator_label,
        value: inputOption.operator_key,
      };
    });
  }

  /**
   * Handle the changes of the select
   * @param condition
   * @param field
   * @param formIndex
   * @param fieldIndex
   * @param item
   */
  onSelectChanges(
    condition: string,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number,
    item: FormField
  ): void {
    if (item?.formControlName === 'operator') {
      this.onConditionChanges(condition, field, formIndex, fieldIndex);
    }
  }

  /**
   * Handle the changes of the condition
   * @param condition
   * @param field
   * @param formIndex
   * @param fieldIndex
   */
  onConditionChanges(
    condition: string,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number
  ): void {
    try {
      const inputOption = this.inputOptions.find(
        (option) => option.operator_key === condition
      );
      this.addNewFields(inputOption, field, formIndex, fieldIndex);
      this.removeOldFields(inputOption, field, formIndex, fieldIndex);
      this.handleDisplayOfRightValueField(
        inputOption,
        field,
        formIndex,
        fieldIndex
      );
      this.handleTypeOfRightValueField(
        inputOption,
        field,
        formIndex,
        fieldIndex
      );
      this.handleRequirementOfFields(inputOption, field);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Add new fields to the form that correspond to the new condition
   * @param inputOption
   * @param field
   * @param formIndex
   * @param fieldIndex
   */
  addNewFields(
    inputOption: OperatorInput,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number
  ): void {
    inputOption?.operator_inputs?.forEach((input) => {
      const operatorField = this.operatorFields?.[formIndex]?.[
        fieldIndex
      ]?.find((field) => field?.formControlName === input?.key);
      if (!field.get(input?.key) || !operatorField) {
        if (!field.get(input?.key)) {
          field.addControl(input?.key, new FormControl(''));
        }
        const fields = this.operatorFields[formIndex][fieldIndex];
        const parsedInputLabel = this.parseInputLabel(input);
        const newField = {
          label: parsedInputLabel,
          type: input?.type,
          formControlName: input?.key,
          items: this.parseFormOptionsToItemsModel(input?.form?.options),
        };
        fields.push(newField);
      }
    });
  }

  /**
   * Parse the input label
   * @param input
   * @returns
   */
  parseInputLabel(input: InputField): string {
    let inputLabel = input?.label || input?.key;
    const initialFields = this.getInitialFields();
    const foundField = initialFields.find(
      (field) => field?.formControlName === input?.key
    );
    if (foundField) {
      inputLabel = foundField.label;
    }
    return new CapitalizePipe().transform(inputLabel);
  }

  /**
   * Remove old fields from the form that don't correspond to the new condition
   * @param inputOption
   * @param field
   * @param formIndex
   * @param fieldIndex
   */
  removeOldFields(
    inputOption: OperatorInput,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number
  ): void {
    const inputKeys = new Set(
      inputOption?.operator_inputs?.map((input) => input.key)
    );
    Object.keys(field.controls).forEach((key) => {
      if (!inputKeys.has(key)) {
        field.removeControl(key);
        const index = this.operatorFields[formIndex][fieldIndex].findIndex(
          (field) => field.formControlName === key
        );
        if (index > -1) {
          this.operatorFields[formIndex][fieldIndex].splice(index, 1);
        }
      }
    });
  }

  /**
   * Parse the form options to items model
   * @param options
   * @returns
   */
  parseFormOptionsToItemsModel(options: InputOption[]): FieldOption[] {
    if (!options) {
      return [];
    }
    return options.map((option) => {
      return {
        viewValue: option.label,
        value: option.value,
      };
    });
  }

  /**
   * Handle the requirement of the fields
   * @param inputOption
   * @param field
   */
  handleRequirementOfFields(
    inputOption: OperatorInput,
    field: FormGroup
  ): void {
    inputOption?.operator_inputs?.forEach((input) => {
      if (input?.required) {
        field.get(input?.key).setValidators([Validators.required]);
      } else {
        field.get(input?.key).clearValidators();
      }
      field.get(input?.key).updateValueAndValidity();
    });
  }

  /**
   * Handle the display of the right value field
   * @param inputOption
   * @param field
   * @param formIndex
   * @param fieldIndex
   */
  handleDisplayOfRightValueField(
    inputOption: OperatorInput,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number
  ): void {
    const hasRightValue = inputOption?.operator_inputs?.find(
      (input) => input?.key === 'right_value'
    );
    if (!hasRightValue) {
      field.removeControl('right_value');
      const rightValueIndex = this.operatorFields[formIndex][
        fieldIndex
      ].findIndex((field) => field?.formControlName === 'right_value');
      if (rightValueIndex > -1) {
        this.operatorFields[formIndex][fieldIndex].splice(rightValueIndex, 1);
      }
    } else {
      if (!field.get('right_value')) {
        field.addControl('right_value', new FormControl(''));
        const fields = this.operatorFields[formIndex][fieldIndex];
        const input = {
          label: 'Enter Value...',
          type: 'text',
          formControlName: 'right_value',
          id: this.generateId(),
        };
        fields.push(input);
      }
    }
  }

  /**
   * Handle the type of the right value field
   * @param inputOption
   * @param field
   * @param formIndex
   * @param fieldIndex
   * @returns
   */
  handleTypeOfRightValueField(
    inputOption: OperatorInput,
    field: FormGroup,
    formIndex: number,
    fieldIndex: number
  ): void {
    const rightValueField = field.get('right_value');
    if (!rightValueField) {
      return;
    }
    const operatorLabel = inputOption?.operator_label;
    const operatorFields = this.operatorFields[formIndex][fieldIndex];
    const rightValueInput = operatorFields.find(
      (input) => input?.formControlName === 'right_value'
    );
    if (operatorLabel?.toLowerCase()?.includes('(number)')) {
      if (rightValueInput) {
        rightValueInput.type = 'number';
      }
    }
    if (operatorLabel?.toLowerCase()?.includes('(text)')) {
      if (rightValueInput) {
        rightValueInput.type = 'text';
      }
    }
    if (operatorLabel?.toLowerCase()?.includes('(date)')) {
      if (rightValueInput) {
        rightValueInput.type = 'customDate';
        this.initializeInputCustomDateType(inputOption, rightValueInput);
      }
    }
    if (operatorLabel?.toLowerCase()?.includes('(money)')) {
      if (rightValueInput) {
        rightValueInput.type = 'money';
      }
    }
  }

  /**
   * Initialize the input custom date type
   * @param inputOption
   * @param rightValueInput
   */
  initializeInputCustomDateType(
    inputOption: OperatorInput,
    rightValueInput: FormField
  ) {
    const textDaysOptions = [
      { viewValue: 'Date', value: 'date' },
      { viewValue: 'Yesterday', value: 'yesterday' },
      { viewValue: 'Today', value: 'today' },
      { viewValue: 'Tomorrow', value: 'tomorrow' },
      { viewValue: 'Monday', value: 'monday' },
      { viewValue: 'Tuesday', value: 'tuesday' },
      { viewValue: 'Wednesday', value: 'wednesday' },
      { viewValue: 'Thursday', value: 'thursday' },
      { viewValue: 'Friday', value: 'friday' },
      { viewValue: 'Saturday', value: 'saturday' },
      { viewValue: 'Sunday', value: 'sunday' },
      { viewValue: 'Custom', value: 'dateText' },
    ];
    rightValueInput.selectOptions = [...textDaysOptions];
    rightValueInput.dateRangeForm = this.fb.group({
      start: '',
      end: '',
      customStringSelect: '',
    });
  }

  /**
   * Get the form array
   * @readonly
   * @type {FormArray}
   * @memberof FilterNestedFormComponent
   */
  get forms(): FormArray {
    return this.nestedForm.get('forms') as FormArray;
  }

  generateId(): string {
    const randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return randomId;
  }

  /**
   * Add a nested form to the form array
   */
  addNestedForm(): void {
    const form = this.fb.array([
      this.fb.group({
        left_value: [''],
        operator: [''],
        right_value: [''],
      }),
    ]);
    this.forms.push(form);
    setTimeout(() => {
      const fields = this.getInitialFields();
      this.operatorFields.push([fields]);
    }, 500);
  }

  /**
   * Get the initial fields for the form
   * @returns
   */
  getInitialFields(): FormField[] {
    return [
      {
        label: 'Choose Field',
        type: 'select',
        items: this.outputs,
        multiple: false,
        formControlName: 'left_value',
      },
      {
        label: 'Choose Condition',
        type: 'select',
        items: this.conditions,
        multiple: false,
        formControlName: 'operator',
      },
      {
        label: 'Enter Value',
        type: 'text',
        formControlName: 'right_value',
        id: this.generateId(),
      },
    ];
  }

  /**
   * Add a field to the form
   * @param form
   * @param formIndex
   */
  addNestedField(form: FormArray, formIndex: number): void {
    form.push(
      this.fb.group({
        left_value: [''],
        operator: [''],
        right_value: [''],
      })
    );
    const fields = this.operatorFields[formIndex];
    const initialFields = this.getInitialFields();
    fields.push(initialFields);
  }

  /**
   * Remove a form from the form array
   * @param index
   */
  removeForm(index: number) {
    this.forms.removeAt(index);
    this.operatorFields.splice(index, 1);
  }

  /**
   * Remove a field from the form
   * @param form
   * @param formIndex
   * @param fieldIndex
   */
  removeField(form: FormArray, formIndex: number, fieldIndex: number): void {
    form.removeAt(fieldIndex);
    this.operatorFields[formIndex].splice(fieldIndex, 1);
  }

  /**
   * Select string date range
   * @param selection
   * @param field
   * @param item
   * @returns
   */
  selectStringDateRange(selection: string, field: FormGroup, item: FormField) {
    if (selection === 'dateText') {
      item.type = 'dateText';
      return;
    }
    if (selection === 'date') {
      item.type = 'date';
      return;
    }
    field.get('right_value').setValue(selection);
  }

  /**
   * Switch to custom date type
   * @param field
   * @param item
   */
  switchToCustomDate(field: FormGroup, item: FormField) {
    item.dateRangeForm?.reset();
    field.get('right_value').setValue('');
    item.type = 'customDate';
  }

  /**
   * Patch the first chip text area with chips
   */
  patchFirstChipTextAreaWithChips() {
    setTimeout(() => {
      this.chips = this.parseOutputsToChips(this.outputs);
      const firstChipTextArea = this.chipTextAreaComponents[0];
      firstChipTextArea.body = {
        content: '',
        chips: this.chips,
      };
      firstChipTextArea.parseAndSetBody();
    }, 1000);
  }

  /**
   * Parse the outputs to chips
   * @param outputs
   * @returns
   */
  parseOutputsToChips(outputs: FieldOption[]): ChipPlaceholder[] {
    if (!outputs) {
      return [];
    }
    return outputs?.map((output) => {
      return {
        text: output.viewValue,
        value: output.value,
      };
    });
  }

  /**
   * Add a chip placeholder
   * @param chip
   * @param id
   */
  addChipPlaceholder(chip: ChipPlaceholder, id: string): void {
    const chipTextAreaComponent = this.chipTextAreaComponents.find(
      (component) => component.id === id
    );
    chipTextAreaComponent.appendPlaceholder(chip);
  }

  /**
   * Handle the input change
   * @param event
   * @param field
   */
  onInputChange(event: InputFocusOut, field: FormGroup) {
    field.get('right_value').setValue(event.value);
  }

  /**
   * Set the selected chip text area
   * @param id
   */
  setSelectedChipTextArea(id: string) {
    console.log('selected chip text area id', id);
  }
}

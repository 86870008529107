<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Active Integrations'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card *ngFor="let integration of shownActiveIntegrations">
        <ng-container *ngIf="integration.format == 'lock'">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/integration-images/remote-lock.png'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'RemoteLock'"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="goToRemoteLockPage()">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/padlock.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Integrated Devices: ' + integratedDevicesCount">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="disableIntegration(integration.integration.id)">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/block.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [body]="'Disable Integration'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
        <tidy-card-button *ngIf="integration.format == 'notification'" (action)="goToAccountNotificationsPage()" class="arrow-to-top">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/integration-images/' + integration?.integration?.channel + '.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="integration?.integration?.channel == 'webhook' ? 'Webhook' : 'Slack'"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="integration?.integration?.customer_account_team">
            <tidy-text
              [body]="'Team: '">
            </tidy-text>
            <tidy-text
              [body]="integration?.integration?.customer_account_team?.name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="integration?.integration?.webhook_url"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <ng-container *ngIf="integration.format !== 'notification' && integration.format !== 'lock'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              *ngIf="integration?.guest_reservation_source_type?.format == 'api'"
              [src]="'assets/img/gold-link.svg'"
              class="header-size">
            </tidy-image>
            <tidy-image
              *ngIf="integration?.guest_reservation_source_type?.format == 'concierge_integration'"
              [src]="'assets/img/concierge.svg'"
              class="header-size">
            </tidy-image>
            <tidy-image
              *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'"
              [src]="'assets/img/ical.png'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="integration.guest_reservation_source_type?.name | titlecase">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="integration?.guest_reservation_source_type?.format == 'api'"
              [body]="'Direct Integration'">
            </tidy-text>
            <tidy-text
              *ngIf="integration?.guest_reservation_source_type?.format == 'concierge_integration'"
              [body]="'Concierge Integration'">
            </tidy-text>
            <tidy-text
              *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'"
              [body]="'iCal Integration'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar' && integration?.url">
            <tidy-row>
              <tidy-text
                [body]="'iCal Link: ' + integration?.url.slice(0, 30) + '..'"
                [translate]="false">
              </tidy-text>
              <tidy-image
                matTooltip="Copy iCal Link"
                [src]="'assets/icon/copy-circle-black.svg'"
                class="title-size"
                style="cursor: pointer; position: absolute; margin-top: -3px; margin-left: 10px;"
                (click)="copyToClipboard(integration?.url)">
              </tidy-image>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'">
            <tidy-text
              *ngIf="integration?.address?.address_name"
              [translate]="false"
              [body]="integration?.address?.address_name">
            </tidy-text>
            <tidy-text
              *ngIf="!integration?.address?.address_name && integration?.address?.address1"
              [translate]="false"
              [body]="integration?.address?.address1 + ' ' + (integration?.address?.address2 || '')">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="integration.last_sync_at" [ngClass]="integration?.guest_reservation_source_type?.format ==='icalendar' ? 'extra-bottom-padding' : ''">
            <tidy-text
              body="Last checked ">
            </tidy-text>
            <tidy-text
              [body]="integration.last_sync_at | parseDate: 'M/D/YY'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="(integration?.guest_reservation_source_type?.format ==='api' && integration.addresses.length > 0)" class="extra-bottom-padding">
            <tidy-text
              body=" Synced addresses:">
            </tidy-text>
            <ng-container *ngFor="let adress of integration.addresses; let last = last">
              <ng-container *ngIf="adress?.address_name; else notAddedAddressName">
                <tidy-text
                  [body]="' ' + adress.address_name"
                  [translate]="false">
                </tidy-text>
              </ng-container>
              <ng-template #notAddedAddressName>
                <ng-container *ngIf="!adress?.address2 && adress?.address1">
                  <tidy-text
                    [body]="' ' + adress.address1"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="adress?.address2 && adress?.address1">
                  <tidy-text
                    [body]="' ' + adress.address1 + ' ' + adress.address2"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
              </ng-template>
              <tidy-text
                *ngIf="!last"
                [body]="','">
              </tidy-text>
              <tidy-text
                *ngIf="last"
                [body]="'.'">
              </tidy-text>
            </ng-container>
          </tidy-row>
          <ng-container *ngIf="integration?.guest_reservation_source_type?.format !== 'concierge_integration'">
            <tidy-row *ngIf="!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length <= 0" class="extra-bottom-padding">
              <tidy-text
                body="Sync Pending..">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length > 0" class="extra-bottom-padding">
              <tidy-text
                body="Syncing guest reservations..">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="integration.state == 'paused'">
            <tidy-row class="extra-bottom-padding">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/action-needed.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                header="Issue Syncing">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                body="There was an issue syncing this integration. Please update your integration details:">
              </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="goToUpdateIntegration(integration)">
              <tidy-row class="vertical-align-center">
                <tidy-text
                  [body]="'Update Integration Details'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
          <ng-container *ngIf="integration.state !== 'paused'">
            <tidy-card-button (action)="goToAutomaticBooking()" *ngIf="integration?.guest_reservation_source_type?.format !== 'icalendar'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="integration?.address_automatic_booking_setting?.automatic_booking_enabled ? 'assets/img/search-calendar-enabled.svg' : 'assets/img/search-calendar-disabled.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="'Automatic Booking'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="goToIntegrationSettings(integration)" *ngIf="integration?.guest_reservation_source_type?.format !== 'icalendar'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/settings.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="'Send Reservation Data from'">
                </tidy-text>
                <span>&nbsp;</span>
                <tidy-text
                  [body]="integration?.guest_reservation_source_type?.name">
                </tidy-text>
                <span>&nbsp;</span>
                <tidy-text
                  [body]="'to TIDY'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="goToAutomaticBookingAddress(integration)" *ngIf="integration?.guest_reservation_source_type?.format == 'icalendar'">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/suitcase.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="'Automatic Booking: ' + (integration?.address_automatic_booking_setting?.automatic_booking_enabled ? 'ON' : 'OFF')">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="disableGuestReservationIntegration(integration.id)">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/block.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="'Disable Integration'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
        </ng-container>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { UserService } from 'src/providers/user/user';
import { Util } from 'src/shared/util/util';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { WindowService } from 'src/shared/providers/window.service';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddUserToTeamPage } from '../add-user-to-team/add-user-to-team';
import { TeamsPage } from '../teams/teams';
import { UserPage } from 'src/pages/users/user/user';
import { EditTeamNamePage } from '../edit-team-name/edit-team-name';
import { EditTeamParentPage } from '../edit-team-parent/edit-team-parent';
import { SuccessPage } from 'src/shared/pages/success/success';
import { AddAccountNotificationPage } from 'src/pages/more/add-account-notification/add-account-notification';
import { AddAddressToTeamPage } from 'src/pages/team/add-address-to-team/add-address-to-team';
import { PaymentMethodsProvider } from 'src/providers/payment-methods/payment-methods.provider';

@Component({
  templateUrl: 'team.html'
})

export class TeamPage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  notificationSettings: any;
  parentTeam: any;
  team: any;
  teams: any;
  teamCard: any;
  webhooks: any;
  userRole: string;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private card: Card,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private teamService: TeamService,
    private userService: UserService,
    private util: Util,
    private webhooksService: Webhooks,
    public windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private paymentMethodsProvider: PaymentMethodsProvider
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    this.errorMessage = '';
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Team');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    try {
      console.log(this.dialogParams)
      this.team = await this.getParam('team') || await this.getTeam();
      await this.storage.save('teamId', this.team.id);
      console.log(await this.getParam('team'))
      console.log(this.team)
      this.teams = await this.getParam('teams') || await this.teamService.getTeams();
      await this.buildNotificationSettings();
      this.getParentTeam();
      const cards = await this.card.getCreditCardsForTeams([this.team.id]);
      const parsedCards = this.util.parsePaymentMethods(cards);
      this.teamCard = parsedCards?.[0];
      this.userRole = localStorage.getItem('user_role');
    } catch(err) {
      console.error(err);
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    return param;
  }

  async buildNotificationSettings() {
    let notificationSettings = [];
    this.webhooks = await this.webhooksService.getWebhooks();
    this.webhooks.map((setting) => {
      if (setting?.customer_account_team?.id == this.team.id) {
        notificationSettings.push(setting);
      }
    });
    this.notificationSettings = await this.webhooksService.buildNotificationSettingsArray(notificationSettings);
  }

  async getTeam() {
    const teamId = this.route.snapshot.paramMap.get('teamId') ||
                   await this.storage.retrieve('teamId') ||
                   (await this.getParam('team'))?.id;
    const teams = await this.teamService.getTeams();
    return teams.find((team) => team.id == teamId);
  }

  getParentTeam() {
    this.teams.map((team) => {
      if (team.id == this.team.parent_team_id) {
        this.parentTeam = team;
      }
    });
  }

  async goToAddUserToTeamPage() {
    const params = {
      teamId: this.team.id,
      teams: this.teams,
      nextPage: `team/${this.team.id}`
    }
    this.rightSidePanelService.navigateTo('add-user-to-team', params, AddUserToTeamPage);
  }

  async deleteTeam() {
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Delete Team?',
      body: 'Are you sure you want to delete ' + this.team.name + '?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmDeleteTeam.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteTeam() {
    try {
      await this.teamService.deleteTeam(this.team.id);
      this.rightSidePanelService.navigateTo('teams', {}, TeamsPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  async goToUserPage(customerMemberId) {
    await this.storage.save('userId', customerMemberId);
    this.rightSidePanelService.navigateTo(`user/${customerMemberId}`, {}, UserPage);
  }

  async goToAddPropertyToTeamPage() {
    const params = {
      teamId: this.team.id,
      teams: this.teams,
      nextPage: `team/${this.team.id}`
    }
    this.rightSidePanelService.navigateTo('add-property-to-team', params, AddAddressToTeamPage);
  }

  goToEditTeamName() {
    const params = {
      team: this.team,
      nextPage: `team/${this.team.id}`
    }
    this.rightSidePanelService.navigateTo('edit-team-name', params, EditTeamNamePage);
  }

  async goToEditParentTeam() {
    const params = {
      team: this.team,
      teams: this.teams,
      nextPage: `team/${this.team.id}`
    }
    this.rightSidePanelService.navigateTo('edit-parent-team', params, EditTeamParentPage);
  }

  async removePropertyFromTeam(address) {
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Remove Address?',
      body: 'Are you sure you want to remove ' + (address?.address_name || address.address1) + ' from ' + this.team.name + '?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmRemovePropertyFromTeam.bind(this, address.id)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRemovePropertyFromTeam(addressId) {
    try {
      const payload = {
        account_team_id: this.team.id,
        address_ids: addressId.toString()
      }
      await this.teamService.removeAddressesFromTeam(payload);
      this.team = await this.getTeam();
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  async removeUserFromTeam(user) {
    const teamsUserIsOn = this.getTeamsUserIsOn(user.customer_member_id);
    const isConvertingMemberToAdmin = teamsUserIsOn.length - 1 == 0;
    const confirmParams: ConfirmPageParamsModel = {
      title: (isConvertingMemberToAdmin ? 'Change to Admin?' : 'Remove ' + user.customer_member_name + '?'),
      body: isConvertingMemberToAdmin ? 'Removing a team manager from all teams will change their user type to <u>admin</u>. This means they will see all properties and users.' : 'Are you sure you want to remove ' + user.customer_member_name + ' from ' + this.team.name + '?',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmRemoveUserFromTeam.bind(this, user.customer_member_id, user.id, isConvertingMemberToAdmin)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRemoveUserFromTeam(customerMemberId, teamMemberId, isConvertingMemberToAdmin) {
    try {
      await this.teamService.removeUserFromTeam(teamMemberId);
      if (isConvertingMemberToAdmin) {
        const userPayload = {
          role: 'admin'
        }
        await this.userService.changeUserRole(customerMemberId, userPayload);
      }
      this.team = await this.getTeam();
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  getTeamsUserIsOn(userId) {
    const teamsUserIsOn: any = [];
    this.teams.map((team) => {
      team.team_members.map((user) => {
        if (user.customer_member_id == userId) {
          teamsUserIsOn.push(({}));
        }
      });
    });
    return teamsUserIsOn;
  }

  async removeEvent(event, channel) {
    this.errorMessage = '';
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Remove Event for Channel?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove',
      confirmAction: this.confirmRemoveEvent.bind(this, event.key, channel)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRemoveEvent(eventKey, channel) {
    try {
      const webhook = this.webhooks.find((webhook) => webhook.id == channel.webhook_id);
      const events = webhook.events.filter((event) => event.key !== eventKey);
      const eventsPayload = events.map((event) => event.key);
      if (eventsPayload.length == 0) {
        await this.webhooksService.deleteWebhook(channel.webhook_id);
      } else {
        const payload = {
          events: eventsPayload
        }
        await this.webhooksService.editWebhook(channel.webhook_id, payload);
      }
      await this.buildNotificationSettings();
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally{
      this.modalController.dismiss();
    }
  }

  goToAddNotificationsPage() {
    const params = {
      cameFromTeamPageId: this.team.id
    }
    this.rightSidePanelService.navigateTo('add-account-notification', params, AddAccountNotificationPage);
  }

  goToAddTeamCardPage() {
    const params = {
      teamId: this.team.id,
      backPage: `team/${this.team.id}`
    }
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  goToEditTeamCardPage() {
    const params = {
      teamId: this.team.id,
      card: this.teamCard,
      backPage: `team/${this.team.id}`
    }
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  goToLearnMoreTeamBilling() {
    this.util.openUrl('https://help.tidy.com/team-billing');
  }

  async confirmDeleteTeamCard() {
    const params: ConfirmPageParamsModel = {
      title: 'Remove Card?',
      body: ` Remove card **** **** **** ${this.teamCard.last4}?`,
      backText: 'Go Back',
      confirmText: 'Remove ',
      confirmAction: this.deleteTeamCard.bind(this)
    }
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async deleteTeamCard() {
    try {
      await this.paymentMethodsProvider.removePaymentMethod(this.teamCard.id);
      const params = this.mountSuccessPageParams();
      this.modalController.dismiss();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch (err){
      const errorMessage = err.error ? err.error.message : err.message;
      const params = this.mountFailedPageParams(errorMessage);
      this.modalController.dismiss();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Credit Card Removed',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
  }

  mountFailedPageParams(error) {
    return {
      header: 'Failed to Remove Credit Card',
      body: `${error}`,
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
  }

  async handleAction(event: { type: string; paymentMethod: any }) {
    if (event.type === 'refresh') {
      const cards = await this.card.getCreditCardsForTeams([this.team.id]);
      const parsedCards = this.util.parsePaymentMethods(cards);
      this.teamCard = parsedCards?.[0];
    }
  }
}

import {
  Component,
  ViewEncapsulation,
  Input,
  TemplateRef,
  ContentChildren,
  AfterContentInit,
  ViewChild,
  Output,
  EventEmitter,
  QueryList,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { TidyCarrouselDirective } from '../../directives/tidy-carrousel';
import { PhotoNoteComponent } from '../photo-note/photo-note.component';

@Component({
  selector: 'tidy-carrousel',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./carrousel.component.scss'],
  template: `
    <ion-slides (ionSlideWillChange)="handleSlideWillChange()" *ngIf="contentInit" pager="true" [options]="slideOpts">
      <ion-slide *ngFor="let template of templates">
        <div class="slide-wrapper">
          <ng-container class="slide-template" [ngTemplateOutlet]="template"></ng-container>
        </div>
      </ion-slide>
    </ion-slides>
  `
})

export  class CarrouselComponent implements AfterContentInit, OnChanges {
  contentInit = false;
  @Input() scaleImages = false;
  isImageScaled = false;
  @Input() slidesNumber: number;
  @Input() slidesPerView = 1;
  @ContentChildren(TidyCarrouselDirective, {read: TemplateRef}) templates: TemplateRef<any>[];
  @ViewChild(IonSlides) ionSlides: IonSlides;
  @ContentChildren(PhotoNoteComponent) photoNotes: QueryList<PhotoNoteComponent>;
  @Output() slideIndexDidChange: EventEmitter<number> = new EventEmitter<number>();

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    grabCursor: true,
    slidesPerView: this.slidesPerView,
  };

  ngOnChanges(changes: SimpleChanges) {
    this.slideOpts = {
      ...this.slideOpts,
      slidesPerView: this.slidesPerView,
    };
    this.photoNotes = changes.photoNotes ? changes.photoNotes.currentValue : this.photoNotes;
    this.templates = changes.templates ? changes.templates.currentValue : this.templates;
  }

  ngAfterContentInit()	{
    this.contentInit = true;
  }

  ngAfterContentChecked() {
    if(!this.isImageScaled && this.ionSlides) {
      this.ionSlides.getSwiper().then((swiper: { width: number }) => {
        this.photoNotes.forEach((photoNote) => {
          photoNote.setImageWidth(`${swiper.width}px`);
        });
      });

      this.isImageScaled = true;
    }
  }

  getActiveIndex() {
    return this.ionSlides.getActiveIndex();
  }

  updateSlider() {
    return this.ionSlides.update();
  }

  async handleSlideWillChange() {
    this.slideIndexDidChange.emit(await this.ionSlides.getActiveIndex());
  }
}

import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ItemNode, FlatNode, ChipFilter } from 'src/models/schedule.model';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Invoices } from 'src/providers/invoices/invoices';
import { Pros } from 'src/providers/pros/pros';
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';

import { InvoiceFilterParams } from 'src/models/invoice.model';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { TidyAbbreviateLastName } from 'src/shared/pipes/tidy-abbr-lastname.pipe';

import { BillSettingsPage } from 'src/pages/bills/bills-settings/bills-settings';
import { PaidInvoicePage } from 'src/pages/invoices/paid-invoice/paid-invoice';
import { PendingInvoicePage } from 'src/pages/invoices/pending-invoice/pending-invoice';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { EditAddressPage } from 'src/pages/more/edit-address/edit-address';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { billsMockData } from 'src/shared/constants/onboarding/bills';

@Component({
  templateUrl: 'bills.html',
  styleUrls: ['bills.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BillsPage implements OnInit {

  addressResponse: any;
  addressFilter: any;
  bills: any = [];
  errorMessage: string;
  isGettingFilteredBills: boolean = false;
  issues: any;
  loggedIssuesResponse: any;
  showLoadingSpinner: boolean;
  statusItems = [
    {
      viewValue: 'All',
      value: 'all'
    },
    {
      viewValue: 'Pending',
      value: 'pending'
    },
    {
      viewValue: 'Paid',
      value: 'paid'
    }
  ];
  headers: any;
  rows: any;
  currentRows: any;
  form: UntypedFormGroup;
  dateRangeForm: UntypedFormGroup;
  loaded: boolean;
  panelClosedSubscription: Subscription;
  pageSize: any;
  pageIndex: any;
  proList = [];
  routeReused: boolean;
  selectedAddresses: number[];
  selectedPros: number[];
  selectOptions: any;
  totalRows: any;
  filters: Partial<InvoiceFilterParams>;
  isDataLoaded = false;
  @ViewChild('tidyTable') tidyTable: any;
  didCheckOnboarding: boolean;

  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  chips: ChipFilter[] = [{
    displayParentName: 'Status',
    parentName: 'status',
    name: 'Pending',
    value: 'pending'
  }];
  selectedFilters: {
    status: string[];
    pro: number[];
    propertyName: number[];
  } = {
    status: ['pending'],
    pro: [],
    propertyName: []
  };
  filterNamesReferences = {
    'Status': 'status',
    'Pro': 'pro',
    'Property': 'propertyName'
  };
  showOnboarding: boolean;
  hasProperties: boolean;
  showOnboardingSubscription$: Subscription;

  constructor(
    private client: Client,
    private currentAddress: CurrentAddress,
    private invoices: Invoices,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public windowService: WindowService,
    private fb: FormBuilder,
    private util: Util,
    private onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      selectedPro: ['', Validators.required],
      selectedAddress: ['', Validators.required],
      selectedStatus: ['all', Validators.required],
    });
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: ''
    });
    this.selectOptions = [
      { viewValue: 'Custom', value: 'custom' },
      { viewValue: 'Today', value: 'today' },
      { viewValue: 'Last Week', value: 'last_week' },
      { viewValue: 'Last Month', value: 'last_month' },
      { viewValue: 'All Time', value: 'all_time' }
    ];
  }

  async ngOnInit() {
    try {
      await this.checkIfShouldShowOnboarding();
      await this.loadPage();
      this.openBillPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.getPageDataNoLoadingIndicator();
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/bills').then(async () => {
        this.bills = [];
        this.routeReused = true;
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('bills');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async openBillPage() {
    const shouldOpenBillPageID = await this.storage.retrieve('shouldOpenBillPageID');
    if (shouldOpenBillPageID) {
      let bill = this.bills.find((bill) => bill.id == shouldOpenBillPageID);
      if (!bill) {
        bill = await this.invoices.getInvoiceById(shouldOpenBillPageID);
      }
      this.goToInvoicePage(bill);
      await this.storage.delete('shouldOpenBillPageID')
    }
  }

  async getPageDataNoLoadingIndicator() {
    this.filters = {
      status: ['pending'],
      team_ids: null,
      created_since_date: null,
      created_until_date: null,
      address_ids: null
    };
    this.pageSize = 25;
    this.pageIndex = 1;
    this.form.reset();
    this.buildHeaders();
    localStorage.setItem('isAllAddressesSelected', 'true');
    await this.getInitialData();
    setTimeout(() => {
      if (this.tidyTable?.paginator) {
        this.tidyTable.paginator.length = this.totalRows;
      }
    }, 1000);
    this.loaded = true;
  }

  @Loading('', true)
  async getPageData() {
    this.filters = {
      status: ['pending'],
      team_ids: null,
      created_since_date: null,
      created_until_date: null,
      address_ids: null
    };
    this.pageSize = 25;
    this.pageIndex = 1;
    this.form.reset();
    this.buildHeaders();
    localStorage.setItem('isAllAddressesSelected', 'true');
    await this.getInitialData();
    setTimeout(() => {
      this.tidyTable.paginator.length = this.totalRows;
    }, 500);
    this.loaded = true;
  }

  async getInitialData(): Promise<void> {
    try {
      const prosPromise = this.pros.getAllPros();
      const addressDataPromise = this.getAddressData();
      const billsPromise = this.getFilteredBills(this.filters, false);
      this.form.patchValue({
        selectedStatus: 'pending'
      })
      const [pros] = await Promise.all([prosPromise, addressDataPromise, billsPromise]);
      this.proList = this.parseProList(pros);
      this.getTreeData();
      this.isDataLoaded = true;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  parseProList(pros: any[]): any[] {
    return pros.map((pro) => {
      return {
        value: pro.id,
        viewValue: pro?.name ? new TidyAbbreviateLastName().transform(pro.name) : '',
        teamId: pro?.main_team_id,
      };
    });
  }

  buildRows(bills: any[]): void {
    this.rows = [];
    bills.map((item) => {
      const serviceDetails = item.items?.[0]?.service_details;
      this.rows.push({
        id: item.id,
        'Pro': {
          value: new TidyAbbreviateLastName().transform(item.homekeeper_data.name),
          action: this.goToPro.bind(this, item.homekeeper_data.id, serviceDetails?.address?.id),
        },
        'Property': {
          value: this.getPropertyName(serviceDetails?.address) || '',
          action: this.goToProperty.bind(this, serviceDetails?.address?.id),
        },
        'Status': {
          value: item.full_paid ? 'Paid' : 'Pending',
          action: null,
        },
        'Job': {
          value: serviceDetails?.name || '',
          action: null,
        },
        'Job Date': {
          value: this.getParsedJobDate(serviceDetails),
          action: null,
        },
        'Total Amount': {
          value: new TidyCurrencyPipe().transform(item.amount),
          action: null,
        },
        'last-column': {
          value: '',
          action: null,
        },
      });
    });
    this.currentRows = this.rows;
  }

  getParsedJobDate(serviceDetails: any): string {
    const parsedDate = LuxonDateTime.fromFormat(serviceDetails?.date, 'yyyy-MM-dd').toFormat('EEE M/d/yy');
    return parsedDate;
  }

  goToPro(id: number, addressId: number): void {
    const params = {
      addressId,
      proId: id
    };
    this.goToPage(`my-pro/${id}`, params, MyProPage);
  }

  goToProperty(id: number): void {
    this.currentAddress.addressId = id.toString();
    localStorage.setItem('editPropertyBackPage', 'bills');
    this.navCtrl.navigateForward(`edit-property/${id}`);
  }

  getPropertyName(address: any): string {
    if (address?.address_name) {
      return address.address_name + ', ' + address.zip;
    }
    return address.address + ', ' + address.zip;
  }

  buildHeaders(): void {
    this.headers = [
      'Pro',
      'Property',
      'Status',
      'Job',
      'Job Date',
      'Total Amount',
      'last-column'
    ];
  }

  sortChanged(sort) {
    this.rows.sort((a, b) => {
      if (sort.active === 'Job Date') {
        const dateA = LuxonDateTime.fromFormat(a[sort.active].value, 'EEE M/d/yy').toJSDate().getTime();
        const dateB = LuxonDateTime.fromFormat(b[sort.active].value, 'EEE M/d/yy').toJSDate().getTime();
        return sort.direction === 'asc' ? dateA - dateB : dateB - dateA;
      } else {
        const valueA = this.parseRowNumberValue(a[sort.active].value);
        const valueB = this.parseRowNumberValue(b[sort.active].value);

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sort.direction === 'asc' || sort.direction === '' ? valueA - valueB : valueB - valueA;
        } else {
          const strA = String(valueA).toLowerCase();
          const strB = String(valueB).toLowerCase();
          if (strA < strB) return sort.direction === 'asc' || sort.direction === '' ? -1 : 1;
          if (strA > strB) return sort.direction === 'asc' || sort.direction === '' ? 1 : -1;
          return 0;
        }
      }
    });
    this.currentRows = this.rows;
  }

  parseRowNumberValue(value: string): string | number {
    if (value.includes('$')) {
      const numberValue = Number(value.replace(/\$|\.|\s/g, ''));
      return isNaN(numberValue) ? value : numberValue;
    }
    return value;
  }

  async getFilteredBills(params?: Partial<InvoiceFilterParams>, showLoadingSpinner = true): Promise<void> {
    let loading = null;
    if (this.loaded && !this.routeReused && showLoadingSpinner) {
      this.pageIndex = 1;
      loading = await this.util.showLoading();
    }
    const bills = this.showOnboarding ? {body: billsMockData, totalRecords: billsMockData.length} : await this.invoices.getAllInvoices(params, this.pageSize, this.pageIndex);
    this.totalRows = Number(bills.totalRecords) || 100;
    if (showLoadingSpinner) {
      this.bills = [
        ...bills.body.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      ];
    } else {
      this.bills = [
        ...this.bills,
        ...bills.body.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      ];
    }
    const paidInvoiceId = await this.storage.retrieve('paidInvoiceId');
    if (paidInvoiceId && params.status.includes('pending')) {
      this.bills = this.bills.filter((bill) => bill.id !== paidInvoiceId);
      await this.storage.delete('paidInvoiceId');
    }
    this.buildRows(this.bills);
    if (this.tidyTable) {
      setTimeout(() => {
        this.tidyTable.paginator.length = this.totalRows;
        this.tidyTable.paginator.pageIndex = this.pageIndex - 1;
      }, 500);
    }
    loading?.dismiss();
  }

  onFilterValueChange(event: number[] | string, type: string): void {
    this.pageIndex = 1;
    if (type === 'pro') {
      const proIds = event as number[];
      const foundPros = this.proList.filter((pro) => proIds.includes(pro.value));
      this.filters['team_ids'] = foundPros.length > 0 ? foundPros.map((pro) => pro.teamId) : null;
    }
    if (type === 'status') {
      const status = event as string;
      this.filters['status'] = status !== 'all' ? [status] : null;
    }
    if (type === 'address') {
      const addressIds = event as number[];
      this.filters['address_ids'] = addressIds.length > 0 ? addressIds : null;
    }
    this.getFilteredBills(this.filters);
  }

  selectCustomDateRange(selection) {
    const start = new Date(selection.start);
    const end = new Date(selection.end);
    const startDate = LuxonDateTime.fromJSDate(start).toISODate();
    const endDate = LuxonDateTime.fromJSDate(end).toISODate();
    this.filters['created_since_date'] = startDate;
    this.filters['created_until_date'] = endDate;
    this.getFilteredBills(this.filters);
  }

  selectStringDateRange(selection) {
    const today = LuxonDateTime.local();
    let startDate = today.toISODate();
    let endDate = today.toISODate();
    if (selection === 'last_week') {
      startDate = today.minus({ days: 7 }).toISODate();
    }
    if (selection === 'last_month') {
      startDate = today.minus({ months: 1 }).toISODate();
    }
    if (selection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    this.filters['created_since_date'] = startDate;
    this.filters['created_until_date'] = endDate;
    this.getFilteredBills(this.filters);
  }

  onTableColumnClick(event) {
    if (event.column === 'Pro' || event.column === 'Property') {
      return;
    }
    const foundBill = this.bills.find((bill) => bill.id === event.element.id);
    this.goToInvoicePage(foundBill);
  }

  async getAddressData() {
    this.addressFilter = await this.navCtrl.getParam('addressFilter') || await this.buildAddressFilter();
  }

  async buildAddressFilter() {
    this.addressResponse = await this.client.getAddresses();
    return this.client.parseAddressList(this.addressResponse, false);
  }

  async goToInvoicePage(invoice) {
    try {
      const params: any = {
        invoiceId: invoice.id,
        hasAccount: true,
        proName: invoice.homekeeper_data.name,
        proEmail: invoice.homekeeper_data.email,
        proPhone: invoice.homekeeper_data.phone,
        proTeamId: invoice.team_id,
        proId: invoice.homekeeper_data.id,
        isLoggedIntoClientApp: true,
        successRoute: 'bills',
        invoice,
      }
      const path = invoice.full_paid ? `paid-bill/${invoice.id}` : `pending-bill/${invoice.id}`;
      const component = invoice.full_paid ? PaidInvoicePage : PendingInvoicePage;
      this.goToPage(path, params, component);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didMakeBillChanges = await this.storage.retrieve('didMakeBillChanges');
        if (didMakeBillChanges) {
          this.bills = [];
          await this.storage.save('didMakeBillChanges', false);
          this.getPageData();
        }
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  goToBillSettingsPage() {
    this.goToPage('bill-settings', {}, BillSettingsPage);
  }

  async pageChange() {
    if (this.isGettingFilteredBills) {
      return;
    }
    this.isGettingFilteredBills = true;
    if (this.tidyTable) {
      this.tidyTable.isLoading = true;
    }
    this.pageIndex += 1;
    try {
      await this.getFilteredBills(this.filters, false);
    } finally {
      if (this.tidyTable) {
        this.tidyTable.isLoading = false;
      }
      this.isGettingFilteredBills = false;
    }
  }

  getTreeData(): void {
    const treeData = [
      {
        name: 'Status',
        children: this.statusItems.map(status => ({
          name: status.viewValue,
          value: status.value.toString(),
          checked: status.value === 'pending',
          parentId: 0
        }))
      },
      {
        name: 'Pro',
        children: this.proList.map(pro => ({
          name: pro.viewValue,
          value: pro.value,
          checked: false,
          parentId: 1
        }))
      },
      {
        name: 'Property',
        children: this.addressFilter.map(address => ({
          name: address.viewValue,
          value: address.value,
          checked: false,
          parentId: 2
        }))
      }
    ];
    treeData.forEach((parent, index) => {
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    this.dataSource.data = treeData;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = node.data.value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!node?.data?.parentId && node?.data?.parentId !== 0) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip);
    }
  }

  addChip(chip: ChipFilter): void {
    if (!this.selectedFilters) {
      this.selectedFilters = {
        status: ['pending'],
        pro: [],
        propertyName: []
      };
    }
    if (chip.parentName === 'status') {
      this.selectedFilters.status = [chip.value.toString()];
      this.chips = this.chips.filter(c => c.parentName !== 'status');
    } else {
      this.selectedFilters[chip.parentName].push(Number(chip.value));
    }
    this.chips.push(chip);
    if (chip.parentName === 'pro') {
      this.onFilterValueChange(this.selectedFilters.pro, 'pro');
    } else if (chip.parentName === 'status') {
      this.onFilterValueChange(chip.value.toString(), 'status');
    } else if (chip.parentName === 'propertyName') {
      this.onFilterValueChange(this.selectedFilters.propertyName, 'address');
    }
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (parent.name === 'Status') {
          child.checked = this.selectedFilters.status.includes(child.value.toString());
        } else if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ]?.includes(Number(child.value))
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter): void {
    if (chip.parentName === 'status') {
      this.selectedFilters.status = ['all'];
      this.onFilterValueChange('all', 'status');
    } else {
      this.selectedFilters[chip.parentName] = this.selectedFilters[
        chip.parentName
      ].filter((value) => value !== chip.value);

      if (chip.parentName === 'pro') {
        this.onFilterValueChange(this.selectedFilters.pro, 'pro');
      } else if (chip.parentName === 'propertyName') {
        this.onFilterValueChange(this.selectedFilters.propertyName, 'address');
      }
    }

    this.chips = this.chips.filter(
      (item) => !(item.parentName === chip.parentName && item.value === chip.value)
    );
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!node?.data?.parentId && node?.data?.parentId !== 0) {
      return false;
    }
    const { foundParentName, foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    if (matchedParentName === 'status') {
      return this.selectedFilters.status.includes(foundNodeValue.toString());
    }
    return this.selectedFilters?.[matchedParentName]?.includes(Number(foundNodeValue));
  }

}

export const bottomMenuItems = [
  {
    icon: 'assets/img/menu-dashboard-white.svg',
    activeIcon: 'assets/img/menu-dashboard-green.svg',
    label: 'Dashboard',
    target: 'dashboard',
  },
  {
    icon: 'assets/img/menu-schedule-white.svg',
    activeIcon: 'assets/img/menu-schedule-green.svg',
    label: 'Schedule',
    target: '/schedule',
  },
  {
    icon: 'assets/img/menu-to-dos-white.svg',
    activeIcon: 'assets/img/menu-to-dos-green.svg',
    label: 'To-Dos',
    target: '/to-dos',
  },
  {
    icon: 'assets/img/menu-concierge-white.svg',
    activeIcon: 'assets/img/menu-concierge-green.svg',
    label: 'Concierge',
    target: '/concierge'
  },
  {
    icon: 'assets/img/menu-more-white.svg',
    activeIcon: 'assets/img/menu-more-green.svg',
    label: 'More',
    target: '/more'
  }
];

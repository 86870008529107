<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Request Job'"
    [canGoBack]="true"
    [customBack]="customBackPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-wrapper-scroll' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <!--<ng-container *ngIf="!hasPros && waterfallSettings?.key == 'never_use_tidy'">
        <tidy-alert>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Unable to Book'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'You don\'t allow new pros and haven\'t added any of your new pros for this service type. Please enable TIDY Find New Pros or add your own pros to book.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Allow TIDY Find New Pros'"
              [action]="turnOnTidyFindNewPros.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Add Pros'"
              [action]="goToAddPro.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </tidy-alert>
      </ng-container>-->

      <tidy-alert style="padding: 15px 15px 15px 5px" *ngIf="customFlowName">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="customFlowSmallImage"
                    style="width: 50px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <div style="margin-top: 5px">
                  <tidy-text
                    [body]="customFlowName">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'offers a'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="customFlowDiscount">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'% discount on all cleanings.'">
                  </tidy-text>
                </div>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-alert>

      <ng-container>
        <ng-container *ngFor="let service of services; let i = index">
          <tidy-card *ngIf="showService(service)">
            <form [formGroup]="form" novalidate action="#">
              <tidy-card-button [hideArrow]="true" (action)="handleServiceClick(service)">
                <tidy-row class="position-relative">
                  <tidy-grid>
                    <tidy-row>
                      <ion-row>
                        <ion-col size="10">
                          <tidy-radio-button
                            formControlName="service"
                            class="radio-card-title"
                            [form]="form.controls.service"
                            (optionChange)="serviceChange()"
                            [items]="[{value: service.key, viewValue: service.title | titlecase}]">
                          </tidy-radio-button>
                        </ion-col>
                        <tidy-image
                          class="width-60 height-60 justify-center position-absolute right-0 no-margin tranlate-y-10per"
                          [src]="service.image">
                        </tidy-image>
                      </ion-row>
                    </tidy-row>
                  </tidy-grid>
                  <tidy-row>
                    <ion-row>
                      <ion-col size="10">
                        <tidy-text
                          [body]="bookJobService.getDisplayPriceForJob(planId, service, 'One Time', true, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, false) + ' - '">
                        </tidy-text>
                        <tidy-text
                          [body]="service.description">
                        </tidy-text>
                      </ion-col>
                    </ion-row>
                  </tidy-row>
                </tidy-row>
                <ng-container *ngIf="form.value.service === service.key" >
                  <tidy-row class="extra-top-padding extra-bottom-padding">
                    <tidy-divider>
                    </tidy-divider>
                  </tidy-row>
                  <form [formGroup]="form" novalidate action="#">
                    <tidy-row class="field-bottom-padding">
                      <tidy-text
                        [header]="'Earliest Start'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-two-column-row style="width:50%">
                        <tidy-datepicker
                          #noEarlierDate
                          style="padding-right:10px"
                          [label]="'Date'"
                          [icon]="'assets/svg/calendar-outline.svg'"
                          formControlName="noEarlierDate"
                          [form]="form.controls.noEarlierDate"
                          errorMessage="Please enter a date."
                          [noPastDates]="true"
                          (optionChange)="earlierDateChange($event)"
                          [submitted]="submitted">
                        </tidy-datepicker>
                        <tidy-select
                          [icon]="'assets/svg/time-outline.svg'"
                          [label]="'Time'"
                          [listedItems]="noEarlierTimes"
                          [form]="form.controls.noEarlierTime"
                          formControlName="noEarlierTime"
                          (optionChange)="selectNoEarlierTime($event)"
                          errorMessage="Please enter a time."
                          [hideSearch]="true"
                          [submitted]="submitted">
                        </tidy-select>
                      </tidy-two-column-row>
                    </tidy-row>
                    <tidy-error-message
                      *ngIf="tooFarStartDateError"
                      [text]="'Please select an earliest start date no more than 3 days before the latest finish date.'">
                    </tidy-error-message>
                    <tidy-row class="extra-top-padding field-bottom-padding">
                      <tidy-text
                        [header]="'Latest Finish'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-two-column-row style="width:50%">
                        <tidy-datepicker
                          style="padding-right:10px"
                          [label]="'Date'"
                          [icon]="'assets/svg/calendar-outline.svg'"
                          formControlName="noLaterDate"
                          [form]="form.controls.noLaterDate"
                          errorMessage="Please enter a date."
                          [minDate]="form.controls.noEarlierDate.value"
                          (optionChange)="updateNoLaterOpts()"
                          [submitted]="submitted">
                        </tidy-datepicker>
                        <tidy-select
                          [icon]="'assets/svg/time-outline.svg'"
                          [label]="'Time'"
                          [listedItems]="noLaterTimes"
                          [form]="form.controls.noLaterTime"
                          formControlName="noLaterTime"
                          (optionChange)="selectNoLaterTime($event)"
                          errorMessage="Please enter a time."
                          [hideSearch]="true"
                          [submitted]="submitted">
                        </tidy-select>
                      </tidy-two-column-row>
                    </tidy-row>
                    <tidy-error-message
                      *ngIf="tooFarEndDateError"
                      [text]="'Please select a latest finish date no more than 3 days after the earliest start date.'">
                    </tidy-error-message>
                    <tidy-row *ngIf="showTimesError" class="extra-top-padding">
                      <tidy-text
                        [body]="'<b>Note:</b> TIDY\'s Find New Pros feature only works for start times of 8:00am or later, end times of 11:00pm or earlier, and at least'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="getTimesErrorText()">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row class="extra-top-padding field-bottom-padding" *ngIf="forms?.length">
                      <tidy-text
                        [header]="'Tell Us More'">
                      </tidy-text>
                    </tidy-row>
                    <ng-container *ngIf="forms">
                      <ng-container *ngFor="let field of forms[0]?.inputs; let last = last; let index = index">
                        <tidy-row [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.type == 'text'">
                          <tidy-input
                            [label]="field?.label"
                            [formControlName]="field?.id?.toString()"
                            [form]="form.controls[field?.id?.toString()]"
                            [submitted]="submitted"
                            [errorMessage]="'Please enter an answer.'">
                          </tidy-input>
                        </tidy-row>
                        <tidy-row [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.type == 'text_area'">
                          <tidy-textarea
                            [label]="field?.label"
                            [formControlName]="field?.id?.toString()"
                            [form]="form.controls[field?.id?.toString()]"
                            [submitted]="submitted"
                            [errorMessage]="'Please enter an answer.'">
                          </tidy-textarea>
                        </tidy-row>
                        <tidy-row [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.type == 'select' && field?.data?.type !== 'image'">
                          <tidy-radio-button
                            [label]="field?.label"
                            [form]="form.controls[field?.id?.toString()]"
                            (optionChange)="changeSelectValue($event, index)"
                            [formControlName]="field?.id?.toString()"
                            [items]="field?.items"
                            [submitted]="submitted"
                            [errorMessage]="'Please enter an answer.'">
                          </tidy-radio-button>
                        </tidy-row>
                        <ng-container [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.type == 'multi_select'">
                          <tidy-row>
                            <tidy-text
                              [body]="field?.label">
                            </tidy-text>
                          </tidy-row>
                          <tidy-row>
                            <ng-container *ngFor="let checkbox of field?.data?.options">
                              <tidy-checkbox
                                [items]="[{value: checkbox.key, viewValue: checkbox.label}]"
                                (checkedChange)="selectCheckbox($event, checkbox, field, index)">
                              </tidy-checkbox>
                            </ng-container>
                          </tidy-row>
                        </ng-container>
                        <tidy-row *ngIf="forms[0]?.inputs[index]?.requiresTextArea">
                          <tidy-textarea
                            [label]="'Other'"
                            [formControlName]="field?.id?.toString() + 'textArea'"
                            [form]="form.controls[field?.id?.toString() + 'textArea']"
                            [submitted]="submitted"
                            [errorMessage]="'Please enter an answer.'">
                          </tidy-textarea>
                        </tidy-row>
                        <tidy-row [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.data?.type == 'image'">
                          <tidy-row>
                            <tidy-row>
                              <tidy-text
                                [body]="field?.label">
                              </tidy-text><br>
                              <tidy-text
                                *ngIf="!getAttachment(field)"
                                [actionBody]="'Add Image'"
                                (action)="addAttachment(field)"
                                class="link">
                              </tidy-text>
                            </tidy-row>
                            <tidy-two-column-row *ngIf="getAttachment(field)">
                              <tidy-text
                                [body]="getAttachment(field)?.fileName">
                              </tidy-text>
                              <tidy-text
                                [actionBody]="'remove'"
                                (action)="removeAttachment(field)"
                                class="link red">
                              </tidy-text>
                            </tidy-two-column-row>
                            <tidy-error-message *ngIf="forms[0]?.inputs[index]?.imageUploadError && submitted && hasImageUploadError"
                              [text]="'Please upload an image'">
                            </tidy-error-message>
                          </tidy-row>
                        </tidy-row>
                        <tidy-row [ngClass]="{'extra-bottom-padding': !last}" *ngIf="field?.data?.type == 'images'">
                          <tidy-row>
                            <tidy-text
                              [body]="field?.label">
                            </tidy-text><br>
                          </tidy-row>
                          <tidy-two-column-row *ngFor="let imageUrl of getAttachment(field)?.value">
                            <tidy-text
                              [body]="imageUrl | urlFileName">
                            </tidy-text>
                            <tidy-text
                              [actionBody]="'remove'"
                              (action)="removeAttachment(field, imageUrl)"
                              class="link red">
                            </tidy-text>
                          </tidy-two-column-row>
                          <tidy-row>
                            <tidy-text
                              [actionBody]="'Add Images'"
                              (action)="addAttachment(field);"
                              class="link">
                            </tidy-text>
                          </tidy-row>
                          <tidy-error-message *ngIf="forms[0]?.inputs[index]?.imageUploadError && submitted && hasImageUploadError"
                            [text]="'Please upload an image'">
                          </tidy-error-message>
                        </tidy-row>
                      </ng-container>
                    </ng-container>
                  </form>
                </ng-container>
              </tidy-card-button>
            </form>
          </tidy-card>
        </ng-container>

        <tidy-row [align]="'center'" class="extra-bottom-padding" *ngIf="didChooseService && !showMoreServices && services?.length > 1">
          <tidy-text
            (action)="showServices()"
            [body]="'Show More Services'"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-alert *ngIf="showSameDayTimeAlert">
          <tidy-row>
            <tidy-text
              [body]="'<b>Note:</b> You selected times today. If a Pro accepts a time today <u>the price will be +50%.</u>'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-alert *ngIf="!windowService?.isDesktopRes || isRightSideContent">
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [header]="'Acceptance Probability'">
            </tidy-text>
          </tidy-row>

          <tidy-row [align]="'center'" *ngIf="!didSelectAllTimes" class="extra-bottom-padding">
            <tidy-text
              [body]="'Select all times to see the estimated chance that a pro accepts your job.'">
            </tidy-text>
          </tidy-row>

          <ng-container *ngIf="didSelectAllTimes && probabilityData">
            <tidy-row [align]="'center'" class="extra-bottom-padding">
              <tidy-text
                [body]="'We estimate a'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="probabilityData?.acceptance_probability">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'% chance of a pro accepting this.'">
              </tidy-text>
              <tidy-text
                [actionBody]="'Learn More'"
                class="link"
                (action)="goToLearnMoreProbability()">>
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="probabilityData?.acceptance_probability <= 98">
              <tidy-row [align]="'center'" style="padding-bottom: 20px">
                <tidy-text
                  [body]="'To improve this:'">
                </tidy-text>
              </tidy-row>

              <ng-container *ngFor="let factor of probabilityData?.factors">
                <tidy-row [align]="'center'" style="padding-bottom: 25px">
                  <tidy-text
                     style="background: #F8F8F8; padding: 10px !important; border-radius: 10px; border: 1px solid #212121"
                    [body]="factor?.factor">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ng-container>
          </ng-container>

          <tidy-row [align]="'center'" class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/tidy-ai.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [helper]="'Powered by TIDY AI'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Review Request'"
          [action]="submit.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-alert *ngIf="priorityListSentence !== 'Pros that you\'ve added or had jobs with will appear here.'">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'How Job Requests Work:'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="priorityListSentence">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'We\'ll notify you right away if someone accepts.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper class="custom-right-column" *ngIf="loaded && windowService.isDesktopRes">

    <tidy-row [align]="'center'">
      <tidy-text
        [header]="'Acceptance Probability'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="!didSelectAllTimes">
      <tidy-text
        [body]="'Select all times to see the estimated chance that a pro accepts your job.'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="didSelectAllTimes && probabilityData">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'We estimate a'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="probabilityData?.acceptance_probability">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'% chance of a pro accepting this.'">
        </tidy-text>
        <tidy-text
          [actionBody]="'Learn More'"
          class="link"
          (action)="goToLearnMoreProbability()">>
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="probabilityData?.acceptance_probability <= 98">
        <tidy-row [align]="'center'" *ngIf="probabilityData?.factors?.length">
          <tidy-text
            [body]="'To improve this:'">
          </tidy-text>
        </tidy-row>

        <ng-container *ngFor="let factor of probabilityData?.factors">
          <tidy-row [align]="'center'" style="padding-bottom: 10px">
            <tidy-text
               style="background: #F8F8F8; padding: 10px !important; border-radius: 10px; border: 1px solid #212121"
              [body]="factor?.factor">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </ng-container>
    </ng-container>

    <tidy-row [align]="'center'" class="vertical-align-center">
      <tidy-image
        [src]="'assets/img/tidy-ai.svg'"
        class="title-size">
      </tidy-image>
      <tidy-text
        [helper]="'Powered by TIDY AI'">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <div>
    <app-scroll-wrapper [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>
  </div>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Dashboard'"
    [showLinkIcon]="loaded && rows?.length > 5"
    [linkIcon]="'settings-outline'"
    (linkAction)="goToReportSettings()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true" [ngStyle]="!windowService?.isDesktopRes ? {'margin-top': '55px'} : {}">
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="loaded && windowService?.isDesktopRes">
      <tidy-text
        [title]="'Dashboard'">
      </tidy-text>
      <div class="spinner-settings-container">
        <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner" style="margin-right: 15px"></ion-spinner>
        <ion-img
          *ngIf="report"
          [src]="'assets/img/gear.svg'"
          class="desktop-title-icon"
          (click)="goToReportSettings()">
        </ion-img>
      </div>
    </tidy-desktop-header>

    <tidy-error-message
      *ngIf="errorMessage && addresses?.length > 0"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card style="padding: 0; height: 300px;" class="no-padding">
        <ng-container *ngIf="!isMapLoaded">
          <ion-skeleton-text animated style="width: 100%; height: 300px; margin: 0; border-radius: 6px;"></ion-skeleton-text>
        </ng-container>
        <div attr.id="map{{reportId}}" #map style="width: 100%; height: 300px; border-radius: 6px;"></div>
      </tidy-card>

      <ng-container *ngIf="rows?.length < 5 && !isRentalClient">
        <tidy-card *ngFor="let row of rows; let index = index">
          <tidy-card-button class="no-below-stroke" (action)="row['Next Maintenance']?.action()" style="padding:0px">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="getHouseIcon(index + 1)"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="row['Address Name']?.value ? row['Address Name']?.value : row?.Address?.value"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row?.Unit?.value && !row['Address Name']?.value" class="vertical-align-center">
              <tidy-text
                [body]="row?.Unit?.value">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/suitcase.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Jobs'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Next Job']?.value">
              <tidy-text
                [body]="'Next Job: '">
              </tidy-text>
              <tidy-text
                [actionBody]="row['Next Job']?.value"
                (action)="row['Next Job']?.action()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!row['Next Job']?.value">
              <tidy-text
                [body]="'No jobs scheduled'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Last Completed']?.value">
              <tidy-text
                [body]="'Last Completed: ' + row['Last Completed']?.value">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToAutomaticBooking(addressIds[index])" *ngIf="row['Automatic Booking']?.value">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled' ? 'assets/img/automatic-booking.svg' : 'assets/img/automatic-booking-off.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Automatic Booking: ' + (checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled' ? 'ON' : 'OFF')">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled'">
              <tidy-row>
                <tidy-text
                  [body]="getAutomaticBookingDetail(addressIds[index], 'service')">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(getAutomaticBookingDetail(addressIds[index], 'earliest_time') | customtime:'h:mma') +
                  ' - ' +
                  (getAutomaticBookingDetail(addressIds[index], 'end_time') | customtime:'h:mma')">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="checkIfAutomaticBookingEnabled(addressIds[index]) == 'disabled'">
              <tidy-row>
                <tidy-text
                  [body]="'When a reservation is synced, add a cleaning for your pros. Cancel anytime.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true" style="padding-bottom: 0px">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/tool-box.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Next Suggested Maintenances'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Next Maintenance']?.value">
              <tidy-text
                [actionBody]="row['Next Maintenance']?.value"
                (action)="row['Next Maintenance']?.action()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!row['Next Maintenance']?.value">
              <tidy-text
                [body]="'No suggested maintenances'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="!windowService?.isDesktopRes">

        <tidy-card>
          <tidy-card-button (action)="goToMessagesPage()" class="arrow-to-top" style="padding-bottom:0px">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/message.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Messages'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!messages?.length">
              <tidy-text
                [body]="'0 recent'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="messages?.length">
              <tidy-row class="vertical-align-center">
                <tidy-initials-icon
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type !== 'call'"
                  [fullName]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.first_name + ' ' + messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.last_name">
                </tidy-initials-icon>
                <tidy-image
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type == 'call'"
                  [src]="'assets/img/phone-black.svg'"
                  class="phone-icon"
                  [ngClass]="(messages?.[0]?.chat_rooms[0]?.content?.text == 'Calling...' || messages?.[0]?.chat_rooms[0]?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
                </tidy-image>
                <tidy-text
                  [body]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.first_name"
                  [translate]="false">
                </tidy-text>
                <div
                style="color: #666;
                font-family: 'Inter';
                font-size: 13px;
                font-weight: 300;
                margin: 3px 0px 0px 5px">
                  {{getMessageDate(messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.sent_at)}}
                </div>
              </tidy-row>
              <tidy-row *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text" class="no-padding">
                <tidy-text
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type !== 'call'"
                  [body]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text"
                  style="overflow: hidden;
                   text-overflow: ellipsis;
                   display: -webkit-box;
                   -webkit-line-clamp: 3;
                           line-clamp: 3;
                   -webkit-box-orient: vertical;">
                </tidy-text>
                <ng-container *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type == 'call'">
                  <tidy-text
                    [body]="'<i>' + messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text + '</i>'">
                  </tidy-text>
                  <tidy-text
                    *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text == 'Call finished.'"
                    [body]="'<i>' + communication.getCallMinutes(messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.duration) + '</i>'">
                  </tidy-text>
                </ng-container>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
          <tidy-row *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files && messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files?.length > 0" style="margin-top:-5px">
            <tidy-photo-note
              style="max-width: 150px"
              [src]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files">
            </tidy-photo-note>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToLoggedIssuesPage()" class="arrow-to-top">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Tasks'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="unresolvedLoggedIssues?.length == 0">
              <tidy-text
                [body]="'0 unresolved'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="unresolvedLoggedIssues?.length > 0">
              <tidy-row class="vertical-align-center">
                <tidy-text
                  [body]="getLoggedIssueAddress(unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.address_id)"
                  [translate]="false">
                </tidy-text>
                <div
                style="color: #666;
                font-family: 'Inter';
                font-size: 13px;
                font-weight: 300;
                margin: 3px 0px 0px 5px">
                  {{getRelativeDateFromISO(unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.created_at, unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.address_id)}}
                </div>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.description"
                  style="overflow: hidden;
                   text-overflow: ellipsis;
                   display: -webkit-box;
                   -webkit-line-clamp: 3;
                           line-clamp: 3;
                   -webkit-box-orient: vertical;">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="unresolvedLoggedIssues?.length > 1" class="no-padding">
                <tidy-text
                  [helper]="' + ' + (unresolvedLoggedIssues?.length - 1)">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="'more unresolved'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
        </tidy-card>

      </ng-container>

      <tidy-two-column-row *ngIf="windowService.isDesktopRes">

        <tidy-card>
          <tidy-card-button (action)="goToMessagesPage()" class="arrow-to-top" style="padding-bottom:0px">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/message.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Messages'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="!isMessagesAndIssuesLoaded">
              <tidy-row class="vertical-align-center" style="padding-bottom: 0px; margin-bottom: -2px;">
                <ion-skeleton-text animated style="width: 20px; height: 19px; border-radius: 100%; margin-right: 6px;"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100px; height: 13px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row style="padding-bottom: 0px;">
                <ion-skeleton-text animated style="width: 90px; height: 13px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ng-container>
            <tidy-row *ngIf="!messages?.length && isMessagesAndIssuesLoaded">
              <tidy-text
                [body]="'0 recent'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="messages?.length && isMessagesAndIssuesLoaded">
              <tidy-row class="vertical-align-center">
                <tidy-initials-icon
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type !== 'call'"
                  [fullName]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.first_name + ' ' + messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.last_name">
                </tidy-initials-icon>
                <tidy-image
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type == 'call'"
                  [src]="'assets/img/phone-black.svg'"
                  class="phone-icon"
                  [ngClass]="(messages?.[0]?.chat_rooms[0]?.content?.text == 'Calling...' || messages?.[0]?.chat_rooms[0]?.content?.text == 'Call in-progress...') ? 'wiggle-animation' : ''">
                </tidy-image>
                <tidy-text
                  [body]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.from_member?.first_name"
                  [translate]="false">
                </tidy-text>
                <div
                style="color: #666;
                font-family: 'Inter';
                font-size: 13px;
                font-weight: 300;
                margin: 3px 0px 0px 5px">
                  {{getMessageDate(messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.sent_at)}}
                </div>
              </tidy-row>
              <tidy-row *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text">
                <tidy-text
                  *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type !== 'call'"
                  [body]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text"
                  style="overflow: hidden;
                   text-overflow: ellipsis;
                   display: -webkit-box;
                   -webkit-line-clamp: 3;
                           line-clamp: 3;
                   -webkit-box-orient: vertical;">
                </tidy-text>
                <ng-container *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.type == 'call'">
                  <tidy-text
                    [body]="'<i>' + messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text + '</i>'">
                  </tidy-text>
                  <tidy-text
                    *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.text == 'Call finished.'"
                    [body]="'<i>' + communication.getCallMinutes(messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.duration) + '</i>'">
                  </tidy-text>
                </ng-container>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
          <tidy-row *ngIf="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files && messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files?.length > 0" style="margin-top:-5px">
            <tidy-photo-note
              style="max-width: 150px"
              [src]="messages?.[0]?.chat_rooms[0]?.last_chat_room_message?.content?.files">
            </tidy-photo-note>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToLoggedIssuesPage()" class="arrow-to-top">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Tasks'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="!isMessagesAndIssuesLoaded">
              <tidy-row style="padding-bottom: 0px;">
                <ion-skeleton-text animated style="width: 150px; height: 13px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row style="padding-bottom: 0px;">
                <ion-skeleton-text animated style="width: 60px; height: 13px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row style="padding-bottom: 0px;">
                <ion-skeleton-text animated style="width: 150px; height: 13px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ng-container>
            <tidy-row *ngIf="unresolvedLoggedIssues?.length == 0 && isMessagesAndIssuesLoaded">
              <tidy-text
                [body]="'0 unresolved'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="unresolvedLoggedIssues?.length > 0 && isMessagesAndIssuesLoaded">
              <tidy-row class="vertical-align-center">
                <tidy-text
                  [body]="getLoggedIssueAddress(unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.address_id)"
                  [translate]="false">
                </tidy-text>
                <div
                style="color: #666;
                font-family: 'Inter';
                font-size: 13px;
                font-weight: 300;
                margin: 3px 0px 0px 5px">
                  {{getRelativeDateFromISO(unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.created_at, unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.address_id)}}
                </div>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="unresolvedLoggedIssues[unresolvedLoggedIssues?.length - 1]?.description"
                  style="overflow: hidden;
                   text-overflow: ellipsis;
                   display: -webkit-box;
                   -webkit-line-clamp: 3;
                           line-clamp: 3;
                   -webkit-box-orient: vertical;">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="unresolvedLoggedIssues?.length > 1 && isMessagesAndIssuesLoaded">
                <tidy-text
                  [helper]="' + ' + (unresolvedLoggedIssues?.length - 1)">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="'more unresolved'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
        </tidy-card>

      </tidy-two-column-row>

      <tidy-two-column-row  *ngIf="windowService.isDesktopRes">

        <tidy-card>
          <tidy-card-button (action)="goToBillsPage()">
            <tidy-row [alignLastItemRight]="true">
              <tidy-image
                [src]="'assets/img/invoice.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Bills'">
              </tidy-text>
              <div class="vertical-align-middle" *ngIf="isInvoicesLoaded && pendingInvoices?.length">
                <tidy-image
                  [src]="'assets/svg/alert-circle.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="pendingInvoices?.length">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'unpaid'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
              <div class="vertical-align-middle" *ngIf="isInvoicesLoaded && !pendingInvoices?.length">
                <tidy-text
                  [body]="'0 unpaid'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
              <div *ngIf="!isInvoicesLoaded">
                <ion-skeleton-text animated style="margin-right: 5px; width: 80px; height: 16px; float: right; border-radius: 6px;"></ion-skeleton-text>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToConciergePage()">
            <tidy-row [alignLastItemRight]="true">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Concierge'">
              </tidy-text>
              <div class="vertical-align-middle" *ngIf="conciergeItems?.length">
                <tidy-text
                  [body]="conciergeItems?.length">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'threads'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
              <div class="vertical-align-middle" *ngIf="!conciergeItems?.length">
                <ng-container *ngIf="!isConciergeItemsLoaded">
                  <ion-skeleton-text animated style="margin-right: 5px; width: 90px; height: 13px; margin-right: 5px; float: right; border-radius: 6px;"></ion-skeleton-text>
                </ng-container>
                <ng-container *ngIf="isConciergeItemsLoaded">
                  <tidy-text
                    [body]="'0 threads'"
                    style="margin-right:5px">
                  </tidy-text>
                </ng-container>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

      </tidy-two-column-row>

      <ng-container *ngIf="!windowService.isDesktopRes">

        <tidy-card>
          <tidy-card-button (action)="goToBillsPage()">
            <tidy-row [alignLastItemRight]="true">
              <tidy-image
                [src]="'assets/img/invoice.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Bills'">
              </tidy-text>
              <div class="vertical-align-middle" *ngIf="pendingInvoices?.length">
                <tidy-image
                  [src]="'assets/svg/alert-circle.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="pendingInvoices?.length">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'unpaid'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
              <div class="vertical-align-middle" *ngIf="!pendingInvoices?.length">
                <tidy-text
                  [body]="'0 unpaid'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-card-button (action)="goToConciergePage()">
            <tidy-row [alignLastItemRight]="true">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Concierge'">
              </tidy-text>
              <div class="vertical-align-middle" *ngIf="conciergeItems?.length">
                <tidy-text
                  [body]="conciergeItems?.length">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'threads'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
              <div class="vertical-align-middle" *ngIf="!conciergeItems?.length">
                <tidy-text
                  [body]="'0 threads'"
                  style="margin-right:5px">
                </tidy-text>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

      </ng-container>

      <tidy-card *ngIf="!isTableLoaded" class="no-padding">
        <ion-skeleton-text animated style="width: 100%; height: 500px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
      </tidy-card>

      <ng-container *ngIf="rows?.length < 5 && isRentalClient && isTableLoaded">
        <tidy-card *ngFor="let row of rows; let index = index">
          <tidy-card-button class="no-below-stroke" (action)="row['Next Maintenance']?.action()" style="padding:0px">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="getHouseIcon(index + 1)"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="row['Address Name']?.value ? row['Address Name']?.value : row?.Address?.value"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row?.Unit?.value && !row['Address Name']?.value" class="vertical-align-center">
              <tidy-text
                [body]="row?.Unit?.value">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/suitcase.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Jobs'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Next Job']?.value">
              <tidy-text
                [body]="'Next:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Next Job Service Type']?.value">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'on'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Next Job Date']?.value">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!row['Next Job']?.value">
              <tidy-text
                [body]="'No jobs scheduled'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Last Completed']?.value">
              <tidy-text
                [body]="'Last:'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Last Completed Job Service Type']?.value">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'on'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Last Completed Job Date']?.value">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="goToAutomaticBooking(addressIds[index])" *ngIf="row['Automatic Booking']?.value">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled' ? 'assets/img/automatic-booking.svg' : 'assets/img/automatic-booking-off.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Automatic Booking: ' + (checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled' ? 'ON' : 'OFF')">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="checkIfAutomaticBookingEnabled(addressIds[index]) == 'enabled'">
              <tidy-row>
                <tidy-text
                  [body]="getAutomaticBookingDetail(addressIds[index], 'service')">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(getAutomaticBookingDetail(addressIds[index], 'earliest_time') | customtime:'h:mma') +
                  ' - ' +
                  (getAutomaticBookingDetail(addressIds[index], 'end_time') | customtime:'h:mma')">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="checkIfAutomaticBookingEnabled(addressIds[index]) == 'disabled'">
              <tidy-row>
                <tidy-text
                  [body]="'When a reservation is synced, add a cleaning for your pros. Cancel anytime.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card-button>
          <tidy-card-button [hideArrow]="true">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/tool-box.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Next Suggested Maintenances'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="row['Next Maintenance']?.value">
              <tidy-text
                [body]="'Next'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Maintenance Name']?.value">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'on'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="row['Maintenance Due Date']?.value">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!row['Next Maintenance']?.value">
              <tidy-text
                [body]="'No suggested maintenances'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <!--<tidy-row [align]="'center'" *ngIf="nudgeCards?.length > 0">
        <tidy-text
          *ngIf="nudgeCards?.length >= 5"
          [header]="'Get Started Using TIDY:'">
        </tidy-text>
        <tidy-text
          *ngIf="nudgeCards?.length < 5"
          [header]="'Other Ways to Use TIDY:'">
        </tidy-text>
      </tidy-row>-->

      <!--<tidy-card style="padding: 15px 15px 15px 5px" *ngFor="let card of nudgeCards">
        <tidy-card-button (action)="navigateToPage(card?.destination)">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin:auto">
                    <tidy-image
                      [src]="card?.icon"
                      [ngStyle]="{'width': card?.iconWidth}">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col tidySize="5">
                  <tidy-row>
                    <tidy-text
                      [header]="card?.header">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="card?.body">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
      </tidy-card>-->

      <ng-container *ngIf="rows?.length >= 5 && isTableLoaded">
        <tidy-card class="no-padding dashboard-table">
          <tidy-table
            [dataSource]="currentRows"
            [displayedColumns]="headers"
            [columnslabels]="headers"
            (sortChange)="sortChanged($event)"
            [showColumnLines]="false"
            [rowsToShow]="50"
            [infiniteScroll]="true"
            [totalRows]="rows?.length"
            (pageChange)="pageChange($event)"
            [scrollWithPage]="true"
            [showArrowIcon]="false">
          </tidy-table>
        </tidy-card>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="dashboard">
</tidy-bottom-nav>

<tidy-onboarding-modal
  *ngIf="showOnboarding"
  #onboardingModal
  [page]="onboardingFlow">
</tidy-onboarding-modal>

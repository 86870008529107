<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Pro Added'"
    [class]="extraClass"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'You have'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'<b>' + (creditBalance | tcurrency) + '</b>.'"
        [translate]="false">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'Do you want to send'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="pro?.first_name"
        [translate]="false">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'payment now or later?'">
      </tidy-text>
    </tidy-row>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToSendPayment()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/payment-method.svg'"
                    [ngStyle]="{'width': '35px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Send Payment'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'We\'ll send'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="pro?.first_name"
                    [translate]="false">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'an email to accept the payment.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToSendJobRequest()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/checkmark-calendar.svg'"
                    [ngStyle]="{'width': '35px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Send Job Request'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Send a job request so'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="pro?.first_name">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'can accept and receive payment once it is completed.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToViewApp()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/app.svg'"
                    [ngStyle]="{'width': '45px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'View the App'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Setup To-Dos, get maintenance suggestions & more.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

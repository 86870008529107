export const myProsMockData = [
  {
      "id": 168783,
      "main_team_id": 165855,
      "name": "Thomaz Senra",
      "first_name": "Thomaz",
      "last_name": "Senra",
      "state": "homekeeper_paused",
      "gender": "male",
      "is_private": true,
      "team_name": "Thomaz Thomaz's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3262442,
              "address_id": 623057,
              "homekeeper_id": 168783,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 221879,
      "main_team_id": 218880,
      "name": "Aurelia Ili",
      "first_name": "Aurelia",
      "last_name": "Ili",
      "state": "active",
      "gender": "male",
      "is_private": true,
      "team_name": "Aurelia Ili's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371437,
              "address_id": 623526,
              "homekeeper_id": 221879,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3272319,
              "address_id": 623057,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298646,
              "address_id": 623564,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3299118,
              "address_id": 623537,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3300291,
              "address_id": 623554,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302482,
              "address_id": 623562,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302304,
              "address_id": 623563,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302382,
              "address_id": 623545,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302279,
              "address_id": 623547,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302328,
              "address_id": 623551,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302359,
              "address_id": 623555,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302475,
              "address_id": 623548,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302402,
              "address_id": 623560,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302553,
              "address_id": 623532,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302807,
              "address_id": 623534,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302690,
              "address_id": 623535,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302802,
              "address_id": 623531,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302845,
              "address_id": 623536,
              "homekeeper_id": 221879,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302865,
              "address_id": 623567,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302914,
              "address_id": 623533,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302872,
              "address_id": 623538,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302862,
              "address_id": 623541,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330341,
              "address_id": 623558,
              "homekeeper_id": 221879,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330291,
              "address_id": 623550,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330401,
              "address_id": 623528,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3330365,
              "address_id": 623544,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3385244,
              "address_id": 623528,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385246,
              "address_id": 623547,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385247,
              "address_id": 623535,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385248,
              "address_id": 623560,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385250,
              "address_id": 623530,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385251,
              "address_id": 623539,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385252,
              "address_id": 623567,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385253,
              "address_id": 623538,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385256,
              "address_id": 623529,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385257,
              "address_id": 623551,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385238,
              "address_id": 623549,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385258,
              "address_id": 623555,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385259,
              "address_id": 623548,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385260,
              "address_id": 623532,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385261,
              "address_id": 623534,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385263,
              "address_id": 623531,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385264,
              "address_id": 623566,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385265,
              "address_id": 623541,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385266,
              "address_id": 623559,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385267,
              "address_id": 623561,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385268,
              "address_id": 623564,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385269,
              "address_id": 623537,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385289,
              "address_id": 623569,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385290,
              "address_id": 623554,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385291,
              "address_id": 623562,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385292,
              "address_id": 623552,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385293,
              "address_id": 623563,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385295,
              "address_id": 623527,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385296,
              "address_id": 623545,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385297,
              "address_id": 623550,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385298,
              "address_id": 623556,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385299,
              "address_id": 623558,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385300,
              "address_id": 623536,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385302,
              "address_id": 623568,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385303,
              "address_id": 623526,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385304,
              "address_id": 623572,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385305,
              "address_id": 623057,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385306,
              "address_id": 623533,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431371,
              "address_id": 623568,
              "homekeeper_id": 221879,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431383,
              "address_id": 623572,
              "homekeeper_id": 221879,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431432,
              "address_id": 629247,
              "homekeeper_id": 221879,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431435,
              "address_id": 623549,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302886,
              "address_id": 623530,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3303557,
              "address_id": 623539,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534825,
              "address_id": 629247,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534826,
              "address_id": 627213,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538361,
              "address_id": 623556,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518587,
              "address_id": 644042,
              "homekeeper_id": 221879,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518646,
              "address_id": 644042,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546688,
              "address_id": 627213,
              "homekeeper_id": 221879,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546904,
              "address_id": 623552,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546934,
              "address_id": 623529,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3554867,
              "address_id": 623559,
              "homekeeper_id": 221879,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526049,
              "address_id": 623566,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3549219,
              "address_id": 623527,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527716,
              "address_id": 623544,
              "homekeeper_id": 221879,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3564319,
              "address_id": 623530,
              "homekeeper_id": 221879,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3565907,
              "address_id": 623561,
              "homekeeper_id": 221879,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3572328,
              "address_id": 623569,
              "homekeeper_id": 221879,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2113808,
          "date": "2025-03-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623560,
              "address1": "4029 Waldorf Court",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2113200,
          "date": "2025-03-26",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623549,
              "address1": "The Signature at MGM Grand - Tower B",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 230477,
      "main_team_id": 227475,
      "name": "Harold Lesure",
      "first_name": "Harold",
      "last_name": "Lesure",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Reliable Lv's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371434,
              "address_id": 623526,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3272417,
              "address_id": 623057,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302480,
              "address_id": 623562,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302302,
              "address_id": 623563,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302379,
              "address_id": 623545,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302282,
              "address_id": 623547,
              "homekeeper_id": 230477,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302325,
              "address_id": 623551,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302403,
              "address_id": 623560,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302473,
              "address_id": 623548,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302551,
              "address_id": 623532,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302693,
              "address_id": 623535,
              "homekeeper_id": 230477,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302800,
              "address_id": 623531,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302842,
              "address_id": 623536,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302866,
              "address_id": 623567,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302873,
              "address_id": 623538,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302911,
              "address_id": 623533,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302887,
              "address_id": 623530,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302859,
              "address_id": 623541,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3303559,
              "address_id": 623539,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330339,
              "address_id": 623558,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330290,
              "address_id": 623550,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330403,
              "address_id": 623528,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330367,
              "address_id": 623544,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3398473,
              "address_id": 632943,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3299115,
              "address_id": 623537,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3431370,
              "address_id": 623568,
              "homekeeper_id": 230477,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431382,
              "address_id": 623572,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431431,
              "address_id": 629247,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3431436,
              "address_id": 623549,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302804,
              "address_id": 623534,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3300286,
              "address_id": 623554,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302356,
              "address_id": 623555,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3298640,
              "address_id": 623564,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534769,
              "address_id": 623564,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534770,
              "address_id": 623555,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534771,
              "address_id": 623554,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534772,
              "address_id": 632943,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534774,
              "address_id": 623561,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534775,
              "address_id": 623537,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534776,
              "address_id": 623534,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534777,
              "address_id": 623566,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534778,
              "address_id": 623541,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534779,
              "address_id": 629247,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534780,
              "address_id": 623559,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534782,
              "address_id": 623551,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534783,
              "address_id": 623529,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534784,
              "address_id": 623552,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534785,
              "address_id": 623545,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534786,
              "address_id": 623572,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534789,
              "address_id": 623531,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534790,
              "address_id": 623536,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534791,
              "address_id": 623567,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534792,
              "address_id": 623538,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534793,
              "address_id": 623533,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534794,
              "address_id": 623530,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534795,
              "address_id": 623526,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534796,
              "address_id": 623532,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534797,
              "address_id": 627213,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534798,
              "address_id": 623549,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534799,
              "address_id": 623562,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534800,
              "address_id": 623563,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534801,
              "address_id": 623527,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534802,
              "address_id": 623560,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534803,
              "address_id": 623569,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534804,
              "address_id": 644042,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534805,
              "address_id": 623548,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534806,
              "address_id": 623556,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458771,
              "address_id": 623561,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458772,
              "address_id": 623545,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458773,
              "address_id": 623552,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458774,
              "address_id": 623529,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458775,
              "address_id": 623551,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458777,
              "address_id": 623566,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458778,
              "address_id": 623541,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458779,
              "address_id": 632943,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458780,
              "address_id": 623537,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458781,
              "address_id": 629247,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458783,
              "address_id": 623534,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458784,
              "address_id": 623554,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458785,
              "address_id": 623555,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458786,
              "address_id": 623564,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458787,
              "address_id": 623559,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458788,
              "address_id": 623560,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458789,
              "address_id": 623548,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458790,
              "address_id": 623532,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458793,
              "address_id": 623531,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458794,
              "address_id": 623536,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458795,
              "address_id": 623567,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458796,
              "address_id": 623549,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458797,
              "address_id": 623538,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458798,
              "address_id": 623533,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458799,
              "address_id": 623530,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458800,
              "address_id": 623572,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458801,
              "address_id": 623526,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458802,
              "address_id": 623569,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458803,
              "address_id": 623562,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458804,
              "address_id": 623563,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458805,
              "address_id": 623527,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458806,
              "address_id": 627213,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458807,
              "address_id": 623556,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458809,
              "address_id": 623544,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458810,
              "address_id": 623550,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458811,
              "address_id": 623558,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458812,
              "address_id": 623539,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458813,
              "address_id": 623528,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458815,
              "address_id": 623535,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458816,
              "address_id": 623568,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458817,
              "address_id": 623547,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3458824,
              "address_id": 623057,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534808,
              "address_id": 623544,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534809,
              "address_id": 623558,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534811,
              "address_id": 623550,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534812,
              "address_id": 623539,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534813,
              "address_id": 623528,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534814,
              "address_id": 623568,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534816,
              "address_id": 623547,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534818,
              "address_id": 623535,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534824,
              "address_id": 623057,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538360,
              "address_id": 623556,
              "homekeeper_id": 230477,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539197,
              "address_id": 650608,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538946,
              "address_id": 629247,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518588,
              "address_id": 644042,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518675,
              "address_id": 644042,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546686,
              "address_id": 627213,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546901,
              "address_id": 623552,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3546931,
              "address_id": 623529,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3554864,
              "address_id": 623559,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3562355,
              "address_id": 623559,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562356,
              "address_id": 623559,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562646,
              "address_id": 623539,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526046,
              "address_id": 623566,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3549217,
              "address_id": 623527,
              "homekeeper_id": 230477,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562609,
              "address_id": 623534,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562610,
              "address_id": 623554,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562611,
              "address_id": 623555,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562612,
              "address_id": 623564,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562613,
              "address_id": 623545,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562614,
              "address_id": 623551,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562616,
              "address_id": 623541,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562617,
              "address_id": 632943,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562618,
              "address_id": 623537,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562619,
              "address_id": 629247,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562621,
              "address_id": 623552,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562622,
              "address_id": 623529,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562623,
              "address_id": 623569,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562624,
              "address_id": 623566,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562625,
              "address_id": 623561,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562627,
              "address_id": 623533,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562628,
              "address_id": 623530,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562629,
              "address_id": 623572,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562630,
              "address_id": 627213,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562631,
              "address_id": 623562,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562632,
              "address_id": 623563,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562633,
              "address_id": 623549,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562634,
              "address_id": 623560,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562635,
              "address_id": 623548,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562636,
              "address_id": 623532,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562639,
              "address_id": 623531,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562640,
              "address_id": 623536,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562641,
              "address_id": 623567,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562642,
              "address_id": 623538,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562643,
              "address_id": 644042,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562644,
              "address_id": 623526,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562645,
              "address_id": 623527,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3565904,
              "address_id": 623561,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3572325,
              "address_id": 623569,
              "homekeeper_id": 230477,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562647,
              "address_id": 623550,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562648,
              "address_id": 623528,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562649,
              "address_id": 623544,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562651,
              "address_id": 623556,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562653,
              "address_id": 623558,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562655,
              "address_id": 623568,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562657,
              "address_id": 623535,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562658,
              "address_id": 623547,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562664,
              "address_id": 623057,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562665,
              "address_id": 650608,
              "homekeeper_id": 230477,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 42,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2131005,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623555,
              "address1": "3322 West Mardon Avenue",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2113703,
          "date": "2025-03-24",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623545,
              "address1": "3953 Waterford Lane",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 230478,
      "main_team_id": 227476,
      "name": "Lisa Daniels",
      "first_name": "Lisa",
      "last_name": "Daniels",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Busy Laundromat's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273129,
              "address_id": 623057,
              "homekeeper_id": 230478,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3398779,
              "address_id": 632943,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534717,
              "address_id": 649779,
              "homekeeper_id": 230478,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3385487,
              "address_id": 623531,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538858,
              "address_id": 623539,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538948,
              "address_id": 650608,
              "homekeeper_id": 230478,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3539187,
              "address_id": 623554,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538804,
              "address_id": 623561,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538808,
              "address_id": 623550,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538901,
              "address_id": 623572,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538869,
              "address_id": 623527,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538876,
              "address_id": 623544,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538915,
              "address_id": 623566,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538800,
              "address_id": 623559,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538812,
              "address_id": 623556,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538813,
              "address_id": 623552,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538829,
              "address_id": 623551,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538839,
              "address_id": 623555,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538870,
              "address_id": 623560,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538857,
              "address_id": 623525,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538868,
              "address_id": 623537,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538872,
              "address_id": 623562,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538855,
              "address_id": 623564,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538875,
              "address_id": 623526,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538904,
              "address_id": 623534,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3539182,
              "address_id": 623549,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538922,
              "address_id": 623541,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538926,
              "address_id": 623538,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538934,
              "address_id": 623533,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538939,
              "address_id": 627213,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538941,
              "address_id": 623569,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3539540,
              "address_id": 642999,
              "homekeeper_id": 230478,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540797,
              "address_id": 623563,
              "homekeeper_id": 230478,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540806,
              "address_id": 623057,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518660,
              "address_id": 644042,
              "homekeeper_id": 230478,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527460,
              "address_id": 623530,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527464,
              "address_id": 623532,
              "homekeeper_id": 230478,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2132332,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623569,
              "address1": "Aztec Way",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2138192,
          "date": "2025-03-26",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623549,
              "address1": "The Signature at MGM Grand - Tower B",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 230479,
      "main_team_id": 227477,
      "name": "Lunna Cleaning",
      "first_name": "Lunna",
      "last_name": "Cleaning",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Lunna Cleaning's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371436,
              "address_id": 623526,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3272251,
              "address_id": 623057,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298644,
              "address_id": 623564,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3299117,
              "address_id": 623537,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3300290,
              "address_id": 623554,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302481,
              "address_id": 623562,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302381,
              "address_id": 623545,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302301,
              "address_id": 623563,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302281,
              "address_id": 623547,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302327,
              "address_id": 623551,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302358,
              "address_id": 623555,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302405,
              "address_id": 623560,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302474,
              "address_id": 623548,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302806,
              "address_id": 623534,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302692,
              "address_id": 623535,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302801,
              "address_id": 623531,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302844,
              "address_id": 623536,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302868,
              "address_id": 623567,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302875,
              "address_id": 623538,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302913,
              "address_id": 623533,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302861,
              "address_id": 623541,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302889,
              "address_id": 623530,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3303561,
              "address_id": 623539,
              "homekeeper_id": 230479,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330340,
              "address_id": 623558,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330288,
              "address_id": 623550,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3330404,
              "address_id": 623528,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330368,
              "address_id": 623544,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302550,
              "address_id": 623532,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3431369,
              "address_id": 623568,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431433,
              "address_id": 629247,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431438,
              "address_id": 623549,
              "homekeeper_id": 230479,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534886,
              "address_id": 644042,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534887,
              "address_id": 623563,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534888,
              "address_id": 623556,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534889,
              "address_id": 623527,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534890,
              "address_id": 623532,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534891,
              "address_id": 623550,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534892,
              "address_id": 633887,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534894,
              "address_id": 623531,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534895,
              "address_id": 623569,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534896,
              "address_id": 623561,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534897,
              "address_id": 623564,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534898,
              "address_id": 623537,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534899,
              "address_id": 623554,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534900,
              "address_id": 623562,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534901,
              "address_id": 623552,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534903,
              "address_id": 623545,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534904,
              "address_id": 623555,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534905,
              "address_id": 623529,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534906,
              "address_id": 623551,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534907,
              "address_id": 623547,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534908,
              "address_id": 623559,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534909,
              "address_id": 623566,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534910,
              "address_id": 623535,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534911,
              "address_id": 623541,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534913,
              "address_id": 623534,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534914,
              "address_id": 623548,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534915,
              "address_id": 623568,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534916,
              "address_id": 627213,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534917,
              "address_id": 629247,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534918,
              "address_id": 623536,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534919,
              "address_id": 623567,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534920,
              "address_id": 623538,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534921,
              "address_id": 623533,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534922,
              "address_id": 623560,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534923,
              "address_id": 623530,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534926,
              "address_id": 623549,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534927,
              "address_id": 623558,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534928,
              "address_id": 623526,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534929,
              "address_id": 623528,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534930,
              "address_id": 623544,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534931,
              "address_id": 623539,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534933,
              "address_id": 623057,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480613,
              "address_id": 623556,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480614,
              "address_id": 623527,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480615,
              "address_id": 623532,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480616,
              "address_id": 623550,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480618,
              "address_id": 623563,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480619,
              "address_id": 633887,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480621,
              "address_id": 623545,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480622,
              "address_id": 623569,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480623,
              "address_id": 623561,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480624,
              "address_id": 623564,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480625,
              "address_id": 623537,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480626,
              "address_id": 623554,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480627,
              "address_id": 623562,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480628,
              "address_id": 623552,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480629,
              "address_id": 623547,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480630,
              "address_id": 623529,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480631,
              "address_id": 623551,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480632,
              "address_id": 623555,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480633,
              "address_id": 623548,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480634,
              "address_id": 623534,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480636,
              "address_id": 623535,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480637,
              "address_id": 623531,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480638,
              "address_id": 623566,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480639,
              "address_id": 623541,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480640,
              "address_id": 623568,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480641,
              "address_id": 627213,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480642,
              "address_id": 629247,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480643,
              "address_id": 623559,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480644,
              "address_id": 623567,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480645,
              "address_id": 623533,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480646,
              "address_id": 623526,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480647,
              "address_id": 623530,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480648,
              "address_id": 623528,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480649,
              "address_id": 623560,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480650,
              "address_id": 623544,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480653,
              "address_id": 623549,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480654,
              "address_id": 623536,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480655,
              "address_id": 623538,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480656,
              "address_id": 623558,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480658,
              "address_id": 623539,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3480659,
              "address_id": 623057,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538358,
              "address_id": 623556,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540229,
              "address_id": 650608,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518586,
              "address_id": 644042,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3518676,
              "address_id": 644042,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3545978,
              "address_id": 623563,
              "homekeeper_id": 230479,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 144,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546687,
              "address_id": 627213,
              "homekeeper_id": 230479,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546903,
              "address_id": 623552,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546933,
              "address_id": 623529,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3554866,
              "address_id": 623559,
              "homekeeper_id": 230479,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526048,
              "address_id": 623566,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527425,
              "address_id": 633887,
              "homekeeper_id": 230479,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3549216,
              "address_id": 623527,
              "homekeeper_id": 230479,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3565906,
              "address_id": 623561,
              "homekeeper_id": 230479,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3572327,
              "address_id": 623569,
              "homekeeper_id": 230479,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2137077,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 644042,
              "address1": "4142 Victoria Street",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2113238,
          "date": "2025-03-23",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623563,
              "address1": "Gabriel Drive",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 230480,
      "main_team_id": 227478,
      "name": "Liliana Angulo Vallejo",
      "first_name": "Liliana",
      "last_name": "Vallejo",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Lili's Cleaning's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3272202,
              "address_id": 623057,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298648,
              "address_id": 623564,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3299119,
              "address_id": 623537,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3300293,
              "address_id": 623554,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302483,
              "address_id": 623562,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302306,
              "address_id": 623563,
              "homekeeper_id": 230480,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302383,
              "address_id": 623545,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302283,
              "address_id": 623547,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302329,
              "address_id": 623551,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302360,
              "address_id": 623555,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302406,
              "address_id": 623560,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302476,
              "address_id": 623548,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302554,
              "address_id": 623532,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302808,
              "address_id": 623534,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302691,
              "address_id": 623535,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302869,
              "address_id": 623567,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302841,
              "address_id": 623536,
              "homekeeper_id": 230480,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302876,
              "address_id": 623538,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302863,
              "address_id": 623541,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302890,
              "address_id": 623530,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3303558,
              "address_id": 623539,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330342,
              "address_id": 623558,
              "homekeeper_id": 230480,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330292,
              "address_id": 623550,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330369,
              "address_id": 623544,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330405,
              "address_id": 623528,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431368,
              "address_id": 623568,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431384,
              "address_id": 623572,
              "homekeeper_id": 230480,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431439,
              "address_id": 623549,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3371433,
              "address_id": 623526,
              "homekeeper_id": 230480,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302910,
              "address_id": 623533,
              "homekeeper_id": 230480,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538362,
              "address_id": 623556,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539188,
              "address_id": 623554,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538805,
              "address_id": 623561,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538809,
              "address_id": 623550,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538906,
              "address_id": 623572,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538880,
              "address_id": 623527,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538893,
              "address_id": 623568,
              "homekeeper_id": 230480,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538886,
              "address_id": 623544,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538860,
              "address_id": 623548,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538917,
              "address_id": 623566,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539184,
              "address_id": 623549,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538801,
              "address_id": 623559,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538814,
              "address_id": 623556,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538833,
              "address_id": 623551,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3540230,
              "address_id": 650608,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538841,
              "address_id": 623555,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538881,
              "address_id": 623560,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538887,
              "address_id": 623537,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538882,
              "address_id": 623562,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538884,
              "address_id": 623526,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538878,
              "address_id": 623539,
              "homekeeper_id": 230480,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538907,
              "address_id": 623534,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538923,
              "address_id": 623541,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538927,
              "address_id": 623538,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538933,
              "address_id": 623533,
              "homekeeper_id": 230480,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540807,
              "address_id": 623057,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518589,
              "address_id": 644042,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546905,
              "address_id": 623552,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546935,
              "address_id": 623529,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546941,
              "address_id": 623533,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546942,
              "address_id": 623526,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546943,
              "address_id": 623536,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546944,
              "address_id": 623532,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546945,
              "address_id": 623535,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546946,
              "address_id": 623539,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546947,
              "address_id": 623568,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3554868,
              "address_id": 623559,
              "homekeeper_id": 230480,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546948,
              "address_id": 623549,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546949,
              "address_id": 623554,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546950,
              "address_id": 623559,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546951,
              "address_id": 623561,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546952,
              "address_id": 623550,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546953,
              "address_id": 623556,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546954,
              "address_id": 623551,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546955,
              "address_id": 623572,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546956,
              "address_id": 623555,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546957,
              "address_id": 623560,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546958,
              "address_id": 623527,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546959,
              "address_id": 623544,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546960,
              "address_id": 623537,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546961,
              "address_id": 623562,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546962,
              "address_id": 623548,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546963,
              "address_id": 623534,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546964,
              "address_id": 623566,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546965,
              "address_id": 623541,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546966,
              "address_id": 623538,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546967,
              "address_id": 623057,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546968,
              "address_id": 644042,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546969,
              "address_id": 623530,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546972,
              "address_id": 623552,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546974,
              "address_id": 623528,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546976,
              "address_id": 623545,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546977,
              "address_id": 623547,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546979,
              "address_id": 623567,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546980,
              "address_id": 623529,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546981,
              "address_id": 623569,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546982,
              "address_id": 623564,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546983,
              "address_id": 623563,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546984,
              "address_id": 623558,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546987,
              "address_id": 650608,
              "homekeeper_id": 230480,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526051,
              "address_id": 623566,
              "homekeeper_id": 230480,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527461,
              "address_id": 623530,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527465,
              "address_id": 623532,
              "homekeeper_id": 230480,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3549221,
              "address_id": 623527,
              "homekeeper_id": 230480,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3565908,
              "address_id": 623561,
              "homekeeper_id": 230480,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3572329,
              "address_id": 623569,
              "homekeeper_id": 230480,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 230481,
      "main_team_id": 227479,
      "name": "Tiffany Humphrey",
      "first_name": "Tiffany",
      "last_name": "Humphrey",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Tranquil Pools's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556025,
              "address_id": 623560,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556047,
              "address_id": 623562,
              "homekeeper_id": 230481,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437409,
              "address_id": 623544,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556093,
              "address_id": 623563,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3556104,
              "address_id": 623563,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3556149,
              "address_id": 623529,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3556188,
              "address_id": 623556,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3274477,
              "address_id": 623057,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274478,
              "address_id": 623566,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274484,
              "address_id": 623531,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274485,
              "address_id": 623534,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274487,
              "address_id": 623548,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274488,
              "address_id": 623551,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274491,
              "address_id": 623541,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274493,
              "address_id": 623567,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274494,
              "address_id": 623536,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274495,
              "address_id": 623532,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274496,
              "address_id": 623537,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274498,
              "address_id": 623538,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274500,
              "address_id": 623527,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274506,
              "address_id": 623552,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274507,
              "address_id": 623564,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274508,
              "address_id": 623526,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274509,
              "address_id": 623530,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274510,
              "address_id": 623554,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274511,
              "address_id": 623558,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274513,
              "address_id": 623553,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274514,
              "address_id": 623528,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274515,
              "address_id": 623535,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274517,
              "address_id": 623550,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274518,
              "address_id": 623533,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274519,
              "address_id": 623569,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274521,
              "address_id": 623568,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274523,
              "address_id": 623572,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274524,
              "address_id": 623525,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274525,
              "address_id": 623549,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331436,
              "address_id": 623559,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331444,
              "address_id": 623561,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351312,
              "address_id": 623547,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556900,
              "address_id": 623555,
              "homekeeper_id": 230481,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517307,
              "address_id": 623561,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539955,
              "address_id": 623545,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517346,
              "address_id": 623535,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517348,
              "address_id": 623550,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517349,
              "address_id": 623533,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517350,
              "address_id": 623569,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517352,
              "address_id": 623568,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517354,
              "address_id": 623572,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517356,
              "address_id": 623549,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517357,
              "address_id": 623547,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517358,
              "address_id": 623555,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518648,
              "address_id": 644042,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518617,
              "address_id": 644042,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3520968,
              "address_id": 623525,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521484,
              "address_id": 623553,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517308,
              "address_id": 623559,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517313,
              "address_id": 623057,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517314,
              "address_id": 623566,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517316,
              "address_id": 623539,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517317,
              "address_id": 623563,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517318,
              "address_id": 623531,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517319,
              "address_id": 623534,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517320,
              "address_id": 623548,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517321,
              "address_id": 623551,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517324,
              "address_id": 623541,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517325,
              "address_id": 623529,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517326,
              "address_id": 623567,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517327,
              "address_id": 623536,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517328,
              "address_id": 623532,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517329,
              "address_id": 623537,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517330,
              "address_id": 623556,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517331,
              "address_id": 623538,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517332,
              "address_id": 623527,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517334,
              "address_id": 623545,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517335,
              "address_id": 623560,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517337,
              "address_id": 623552,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517338,
              "address_id": 623564,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517339,
              "address_id": 623526,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517340,
              "address_id": 623530,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517341,
              "address_id": 623554,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517342,
              "address_id": 623558,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517343,
              "address_id": 623562,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517345,
              "address_id": 623528,
              "homekeeper_id": 230481,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527719,
              "address_id": 623544,
              "homekeeper_id": 230481,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2123347,
          "date": "2025-03-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623545,
              "address1": "3953 Waterford Lane",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2123287,
          "date": "2025-03-25",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623551,
              "address1": "2070 Irwin Circle",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 230482,
      "main_team_id": 227480,
      "name": "SnF Cleaning LLC",
      "first_name": "SnF",
      "last_name": "LLC",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "S\u0026F Llc's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3532666,
              "address_id": 623545,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3371435,
              "address_id": 623526,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3272466,
              "address_id": 623057,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298642,
              "address_id": 623564,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3299116,
              "address_id": 623537,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3300288,
              "address_id": 623554,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302303,
              "address_id": 623563,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302280,
              "address_id": 623547,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302326,
              "address_id": 623551,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302357,
              "address_id": 623555,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302404,
              "address_id": 623560,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302552,
              "address_id": 623532,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302805,
              "address_id": 623534,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302694,
              "address_id": 623535,
              "homekeeper_id": 230482,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302843,
              "address_id": 623536,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302867,
              "address_id": 623567,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302799,
              "address_id": 623531,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302874,
              "address_id": 623538,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302912,
              "address_id": 623533,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302860,
              "address_id": 623541,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302888,
              "address_id": 623530,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3303560,
              "address_id": 623539,
              "homekeeper_id": 230482,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330338,
              "address_id": 623558,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330289,
              "address_id": 623550,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330402,
              "address_id": 623528,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330366,
              "address_id": 623544,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385488,
              "address_id": 623531,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431381,
              "address_id": 623572,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3431434,
              "address_id": 629247,
              "homekeeper_id": 230482,
              "priority": 4,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431437,
              "address_id": 623549,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302472,
              "address_id": 623548,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3302479,
              "address_id": 623562,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3431367,
              "address_id": 623568,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534716,
              "address_id": 649779,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534828,
              "address_id": 623548,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534829,
              "address_id": 627213,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534830,
              "address_id": 623572,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534832,
              "address_id": 623531,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534834,
              "address_id": 623569,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534835,
              "address_id": 623568,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534836,
              "address_id": 623562,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534837,
              "address_id": 633887,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534838,
              "address_id": 623532,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534840,
              "address_id": 623547,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534841,
              "address_id": 623529,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534842,
              "address_id": 623551,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534843,
              "address_id": 623555,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534844,
              "address_id": 623534,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534846,
              "address_id": 623528,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534847,
              "address_id": 623544,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534849,
              "address_id": 623556,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534850,
              "address_id": 623561,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534851,
              "address_id": 623564,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534852,
              "address_id": 623537,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534854,
              "address_id": 644042,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534855,
              "address_id": 623559,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534856,
              "address_id": 623566,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534857,
              "address_id": 623554,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534858,
              "address_id": 623530,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534859,
              "address_id": 623552,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534860,
              "address_id": 623541,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534861,
              "address_id": 623558,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534862,
              "address_id": 623550,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534863,
              "address_id": 623549,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534864,
              "address_id": 623563,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534865,
              "address_id": 623567,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534866,
              "address_id": 623536,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534867,
              "address_id": 623533,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534869,
              "address_id": 623538,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534870,
              "address_id": 623526,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534871,
              "address_id": 623560,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534872,
              "address_id": 623527,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534873,
              "address_id": 629247,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534874,
              "address_id": 623539,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534875,
              "address_id": 623535,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534877,
              "address_id": 623057,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534879,
              "address_id": 623545,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534883,
              "address_id": 649779,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3483847,
              "address_id": 623547,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538359,
              "address_id": 623556,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539189,
              "address_id": 623554,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538806,
              "address_id": 623561,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538810,
              "address_id": 623550,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538828,
              "address_id": 623529,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538897,
              "address_id": 623535,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538909,
              "address_id": 623572,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3540224,
              "address_id": 650608,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538889,
              "address_id": 623527,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538885,
              "address_id": 623568,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538894,
              "address_id": 623544,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538871,
              "address_id": 623548,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538873,
              "address_id": 623564,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538898,
              "address_id": 623558,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538918,
              "address_id": 623566,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538947,
              "address_id": 629247,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539535,
              "address_id": 650786,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3539185,
              "address_id": 623549,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538802,
              "address_id": 623559,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538816,
              "address_id": 623556,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538815,
              "address_id": 623552,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538835,
              "address_id": 623551,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538843,
              "address_id": 623555,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538921,
              "address_id": 623567,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538890,
              "address_id": 623560,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538852,
              "address_id": 623545,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538900,
              "address_id": 623528,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538896,
              "address_id": 623537,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538891,
              "address_id": 623562,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538892,
              "address_id": 623526,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538867,
              "address_id": 623539,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538910,
              "address_id": 623534,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538916,
              "address_id": 623536,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538924,
              "address_id": 623541,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538928,
              "address_id": 623538,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538935,
              "address_id": 623533,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538940,
              "address_id": 627213,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538942,
              "address_id": 623569,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539539,
              "address_id": 642999,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540798,
              "address_id": 623563,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3540808,
              "address_id": 623057,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518590,
              "address_id": 644042,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518658,
              "address_id": 644042,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546685,
              "address_id": 627213,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3546902,
              "address_id": 623552,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546932,
              "address_id": 623529,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3554865,
              "address_id": 623559,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526047,
              "address_id": 623566,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527392,
              "address_id": 633887,
              "homekeeper_id": 230482,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527423,
              "address_id": 633887,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527462,
              "address_id": 623530,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527466,
              "address_id": 623532,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3549218,
              "address_id": 623527,
              "homekeeper_id": 230482,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3565905,
              "address_id": 623561,
              "homekeeper_id": 230482,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3572326,
              "address_id": 623569,
              "homekeeper_id": 230482,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2132337,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623569,
              "address1": "Aztec Way",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2146298,
          "date": "2025-03-25",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623531,
              "address1": "1900 Bookbinder Drive",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 231012,
      "main_team_id": 228009,
      "name": "Tiffany Maid Service",
      "first_name": "Tiffany",
      "last_name": "Service",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Tiffany Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3272153,
              "address_id": 623057,
              "homekeeper_id": 231012,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298650,
              "address_id": 623564,
              "homekeeper_id": 231012,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231013,
      "main_team_id": 228010,
      "name": "Maria Mendoza",
      "first_name": "Maria",
      "last_name": "Mendoza",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Merry Maids's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371438,
              "address_id": 623526,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3272368,
              "address_id": 623057,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298649,
              "address_id": 623564,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3299120,
              "address_id": 623537,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3300294,
              "address_id": 623554,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302484,
              "address_id": 623562,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302305,
              "address_id": 623563,
              "homekeeper_id": 231013,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302384,
              "address_id": 623545,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302284,
              "address_id": 623547,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302330,
              "address_id": 623551,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302361,
              "address_id": 623555,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302407,
              "address_id": 623560,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302477,
              "address_id": 623548,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302555,
              "address_id": 623532,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302809,
              "address_id": 623534,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302695,
              "address_id": 623535,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302803,
              "address_id": 623531,
              "homekeeper_id": 231013,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302846,
              "address_id": 623536,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302870,
              "address_id": 623567,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302877,
              "address_id": 623538,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302915,
              "address_id": 623533,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302864,
              "address_id": 623541,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302891,
              "address_id": 623530,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3303562,
              "address_id": 623539,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330337,
              "address_id": 623558,
              "homekeeper_id": 231013,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3330370,
              "address_id": 623544,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330406,
              "address_id": 623528,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431372,
              "address_id": 623568,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3431440,
              "address_id": 623549,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534944,
              "address_id": 623558,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534945,
              "address_id": 623531,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534946,
              "address_id": 623563,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534947,
              "address_id": 623566,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534948,
              "address_id": 623527,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534949,
              "address_id": 623562,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534950,
              "address_id": 623552,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534952,
              "address_id": 623545,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534953,
              "address_id": 623547,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534954,
              "address_id": 623529,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534955,
              "address_id": 623551,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534956,
              "address_id": 623555,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534957,
              "address_id": 623560,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534958,
              "address_id": 623548,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534959,
              "address_id": 623532,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534961,
              "address_id": 623534,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534963,
              "address_id": 623535,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534964,
              "address_id": 623536,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534965,
              "address_id": 623567,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534966,
              "address_id": 623538,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534967,
              "address_id": 623541,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534968,
              "address_id": 623530,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534969,
              "address_id": 623539,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534972,
              "address_id": 623561,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534973,
              "address_id": 623564,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534974,
              "address_id": 623537,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534975,
              "address_id": 623554,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534976,
              "address_id": 623549,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534977,
              "address_id": 623569,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534978,
              "address_id": 623544,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534979,
              "address_id": 623528,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534980,
              "address_id": 623526,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534981,
              "address_id": 623556,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534982,
              "address_id": 623568,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534983,
              "address_id": 623533,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534984,
              "address_id": 623057,
              "homekeeper_id": 231013,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538363,
              "address_id": 623556,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546906,
              "address_id": 623552,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546936,
              "address_id": 623529,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526050,
              "address_id": 623566,
              "homekeeper_id": 231013,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3549220,
              "address_id": 623527,
              "homekeeper_id": 231013,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3565909,
              "address_id": 623561,
              "homekeeper_id": 231013,
              "priority": 6,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3572330,
              "address_id": 623569,
              "homekeeper_id": 231013,
              "priority": 5,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2144671,
          "date": "2025-03-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623558,
              "address1": "531 Silent Siesta Drive",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2142868,
          "date": "2025-03-24",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623558,
              "address1": "531 Silent Siesta Drive",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 231020,
      "main_team_id": 228017,
      "name": "Silver Sky Cleaning Co.",
      "first_name": "Silver",
      "last_name": "Co.",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Silver Co.'s Team",
      "address_homekeeper_priorities": [
          {
              "id": 3272963,
              "address_id": 623057,
              "homekeeper_id": 231020,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298651,
              "address_id": 623564,
              "homekeeper_id": 231020,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231022,
      "main_team_id": 228019,
      "name": "Ricky Salazar",
      "first_name": "Ricky",
      "last_name": "Salazar",
      "state": "active",
      "gender": "male",
      "is_private": true,
      "team_name": "Next-Gen Laundry Services ",
      "address_homekeeper_priorities": [
          {
              "id": 3273012,
              "address_id": 623057,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298652,
              "address_id": 623564,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3302285,
              "address_id": 623547,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534222,
              "address_id": 644042,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538837,
              "address_id": 623555,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538895,
              "address_id": 623558,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538865,
              "address_id": 623526,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3539186,
              "address_id": 623554,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538803,
              "address_id": 623561,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538807,
              "address_id": 623550,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538826,
              "address_id": 623529,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538888,
              "address_id": 623535,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538903,
              "address_id": 623572,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527463,
              "address_id": 623532,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538859,
              "address_id": 623527,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538877,
              "address_id": 623568,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538866,
              "address_id": 623544,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538854,
              "address_id": 623548,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538863,
              "address_id": 623564,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538912,
              "address_id": 623566,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538945,
              "address_id": 629247,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3511961,
              "address_id": 623533,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511963,
              "address_id": 623534,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511967,
              "address_id": 623057,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511968,
              "address_id": 623549,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511970,
              "address_id": 623572,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511971,
              "address_id": 623537,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539183,
              "address_id": 623549,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538799,
              "address_id": 623559,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538811,
              "address_id": 623556,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538831,
              "address_id": 623551,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538919,
              "address_id": 623567,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538862,
              "address_id": 623560,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538848,
              "address_id": 623545,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538899,
              "address_id": 623528,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538879,
              "address_id": 623537,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538861,
              "address_id": 623562,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538914,
              "address_id": 623534,
              "homekeeper_id": 231022,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3538944,
              "address_id": 633887,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538913,
              "address_id": 623536,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538920,
              "address_id": 623541,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538925,
              "address_id": 623538,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538936,
              "address_id": 623533,
              "homekeeper_id": 231022,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539536,
              "address_id": 650786,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540799,
              "address_id": 623563,
              "homekeeper_id": 231022,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3540805,
              "address_id": 623057,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511931,
              "address_id": 623535,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511939,
              "address_id": 623528,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511941,
              "address_id": 623567,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511942,
              "address_id": 623536,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511944,
              "address_id": 623568,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511947,
              "address_id": 623561,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511948,
              "address_id": 623566,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511949,
              "address_id": 623541,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511950,
              "address_id": 623538,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511951,
              "address_id": 623532,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3511954,
              "address_id": 623527,
              "homekeeper_id": 231022,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3513916,
              "address_id": 623550,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3513927,
              "address_id": 623554,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518657,
              "address_id": 644042,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3522725,
              "address_id": 623548,
              "homekeeper_id": 231022,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522892,
              "address_id": 623544,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522915,
              "address_id": 623547,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523357,
              "address_id": 623526,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523322,
              "address_id": 623529,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523361,
              "address_id": 623559,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527357,
              "address_id": 623530,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527377,
              "address_id": 623558,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527383,
              "address_id": 623560,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527390,
              "address_id": 623563,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527406,
              "address_id": 629247,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527452,
              "address_id": 623564,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527418,
              "address_id": 623545,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527434,
              "address_id": 623556,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527440,
              "address_id": 623562,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527445,
              "address_id": 623551,
              "homekeeper_id": 231022,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527458,
              "address_id": 623555,
              "homekeeper_id": 231022,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527459,
              "address_id": 623530,
              "homekeeper_id": 231022,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          }
      ],
      "next_job": {
          "id": 2126082,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623548,
              "address1": "2031 Babylon Mill Street",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2112941,
          "date": "2025-03-25",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623556,
              "address1": "3149 Belvedere Drive",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 231023,
      "main_team_id": 228020,
      "name": "Aj's Laundry",
      "first_name": "Aj's",
      "last_name": "Laundry",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Aj's Laundry's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273061,
              "address_id": 623057,
              "homekeeper_id": 231023,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3298653,
              "address_id": 623564,
              "homekeeper_id": 231023,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231024,
      "main_team_id": 228021,
      "name": "Evgeny Rogozinskiy",
      "first_name": "Evgeny",
      "last_name": "Rogozinskiy",
      "state": "active",
      "gender": "Male",
      "is_private": true,
      "team_name": "Handy Lv's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273227,
              "address_id": 623057,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273228,
              "address_id": 623566,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273229,
              "address_id": 623547,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273231,
              "address_id": 623539,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273232,
              "address_id": 623559,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273233,
              "address_id": 623563,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273234,
              "address_id": 623531,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273235,
              "address_id": 623534,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273236,
              "address_id": 623561,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273237,
              "address_id": 623548,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273238,
              "address_id": 623551,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273241,
              "address_id": 623541,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273242,
              "address_id": 623529,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273243,
              "address_id": 623567,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273244,
              "address_id": 623536,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273245,
              "address_id": 623532,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273246,
              "address_id": 623537,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273247,
              "address_id": 623556,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273248,
              "address_id": 623538,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273249,
              "address_id": 623555,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273250,
              "address_id": 623527,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273252,
              "address_id": 623545,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273253,
              "address_id": 623544,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273254,
              "address_id": 623560,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273256,
              "address_id": 623552,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273257,
              "address_id": 623564,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273258,
              "address_id": 623526,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273259,
              "address_id": 623530,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273260,
              "address_id": 623554,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273261,
              "address_id": 623558,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273262,
              "address_id": 623562,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273263,
              "address_id": 623553,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273264,
              "address_id": 623528,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273265,
              "address_id": 623535,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273267,
              "address_id": 623550,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273268,
              "address_id": 623533,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273269,
              "address_id": 623569,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273271,
              "address_id": 623568,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273273,
              "address_id": 623572,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273274,
              "address_id": 623525,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273275,
              "address_id": 623549,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330610,
              "address_id": 623559,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330611,
              "address_id": 623561,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330612,
              "address_id": 623550,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330613,
              "address_id": 623556,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330614,
              "address_id": 623552,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330616,
              "address_id": 623547,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330617,
              "address_id": 623563,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330618,
              "address_id": 623551,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330620,
              "address_id": 623529,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330621,
              "address_id": 623555,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330622,
              "address_id": 623527,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330623,
              "address_id": 623545,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330624,
              "address_id": 623560,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330626,
              "address_id": 623558,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330627,
              "address_id": 623548,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330628,
              "address_id": 623564,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330629,
              "address_id": 623562,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330632,
              "address_id": 623526,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330633,
              "address_id": 623539,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330634,
              "address_id": 623537,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330635,
              "address_id": 623532,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330636,
              "address_id": 623568,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330638,
              "address_id": 623535,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330640,
              "address_id": 623528,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330641,
              "address_id": 623572,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330644,
              "address_id": 623531,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330645,
              "address_id": 623534,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330646,
              "address_id": 623566,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330647,
              "address_id": 623536,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330648,
              "address_id": 623541,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330649,
              "address_id": 623567,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330650,
              "address_id": 623538,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330652,
              "address_id": 623530,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330653,
              "address_id": 623554,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330654,
              "address_id": 623533,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330655,
              "address_id": 623569,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330658,
              "address_id": 624945,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330659,
              "address_id": 623057,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330660,
              "address_id": 627355,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330662,
              "address_id": 627356,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330671,
              "address_id": 627354,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330679,
              "address_id": 627213,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385242,
              "address_id": 623549,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518670,
              "address_id": 644042,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518644,
              "address_id": 644042,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520964,
              "address_id": 623525,
              "homekeeper_id": 231024,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521481,
              "address_id": 623553,
              "homekeeper_id": 231024,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527714,
              "address_id": 623544,
              "homekeeper_id": 231024,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231025,
      "main_team_id": 228022,
      "name": "Jason Rowland",
      "first_name": "Jason",
      "last_name": "Rowland",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Rowland's Services's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273276,
              "address_id": 623057,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273277,
              "address_id": 623057,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273278,
              "address_id": 623566,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273279,
              "address_id": 623566,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273280,
              "address_id": 623547,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273281,
              "address_id": 623547,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273284,
              "address_id": 623539,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273285,
              "address_id": 623539,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273286,
              "address_id": 623559,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273287,
              "address_id": 623559,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273288,
              "address_id": 623563,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273289,
              "address_id": 623563,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273290,
              "address_id": 623531,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273291,
              "address_id": 623531,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273292,
              "address_id": 623534,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273293,
              "address_id": 623534,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273294,
              "address_id": 623561,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273295,
              "address_id": 623561,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273296,
              "address_id": 623548,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273297,
              "address_id": 623548,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273298,
              "address_id": 623551,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273299,
              "address_id": 623551,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273304,
              "address_id": 623541,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273305,
              "address_id": 623541,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273306,
              "address_id": 623529,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273307,
              "address_id": 623529,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273308,
              "address_id": 623567,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273309,
              "address_id": 623567,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273310,
              "address_id": 623536,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273311,
              "address_id": 623536,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273312,
              "address_id": 623532,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273313,
              "address_id": 623532,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273314,
              "address_id": 623537,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273315,
              "address_id": 623537,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273316,
              "address_id": 623556,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273317,
              "address_id": 623556,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273318,
              "address_id": 623538,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273319,
              "address_id": 623538,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273320,
              "address_id": 623555,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273321,
              "address_id": 623555,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273322,
              "address_id": 623527,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273323,
              "address_id": 623527,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273326,
              "address_id": 623545,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273327,
              "address_id": 623545,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273328,
              "address_id": 623544,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273329,
              "address_id": 623544,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273330,
              "address_id": 623560,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273331,
              "address_id": 623560,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273334,
              "address_id": 623552,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273335,
              "address_id": 623552,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273336,
              "address_id": 623564,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273337,
              "address_id": 623564,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273338,
              "address_id": 623526,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273339,
              "address_id": 623526,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273340,
              "address_id": 623530,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273341,
              "address_id": 623530,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273342,
              "address_id": 623554,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273343,
              "address_id": 623554,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273344,
              "address_id": 623558,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273345,
              "address_id": 623558,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273346,
              "address_id": 623562,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273347,
              "address_id": 623562,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273348,
              "address_id": 623553,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273349,
              "address_id": 623553,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273350,
              "address_id": 623528,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273351,
              "address_id": 623528,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273352,
              "address_id": 623535,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273353,
              "address_id": 623535,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273356,
              "address_id": 623550,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273357,
              "address_id": 623550,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273358,
              "address_id": 623533,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273359,
              "address_id": 623533,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273360,
              "address_id": 623569,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273361,
              "address_id": 623569,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273364,
              "address_id": 623568,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273365,
              "address_id": 623568,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273368,
              "address_id": 623572,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273369,
              "address_id": 623572,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273370,
              "address_id": 623525,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273371,
              "address_id": 623525,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273372,
              "address_id": 623549,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273373,
              "address_id": 623549,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518625,
              "address_id": 644042,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518671,
              "address_id": 644042,
              "homekeeper_id": 231025,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527709,
              "address_id": 623544,
              "homekeeper_id": 231025,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231026,
      "main_team_id": 228023,
      "name": "Leo's  Handyman Services",
      "first_name": "Leo's",
      "last_name": "Services",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Leo's Services's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273414,
              "address_id": 623057,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273415,
              "address_id": 623057,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273416,
              "address_id": 623057,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273417,
              "address_id": 623566,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273418,
              "address_id": 623566,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273419,
              "address_id": 623566,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273420,
              "address_id": 623547,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273421,
              "address_id": 623547,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273422,
              "address_id": 623547,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273426,
              "address_id": 623539,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273427,
              "address_id": 623539,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273428,
              "address_id": 623539,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273429,
              "address_id": 623559,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273430,
              "address_id": 623559,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273431,
              "address_id": 623559,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273432,
              "address_id": 623563,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273433,
              "address_id": 623563,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273434,
              "address_id": 623563,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273435,
              "address_id": 623531,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273436,
              "address_id": 623531,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273437,
              "address_id": 623531,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273438,
              "address_id": 623534,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273439,
              "address_id": 623534,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273440,
              "address_id": 623534,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273441,
              "address_id": 623561,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273442,
              "address_id": 623561,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273443,
              "address_id": 623561,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273444,
              "address_id": 623548,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273445,
              "address_id": 623548,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273446,
              "address_id": 623548,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273447,
              "address_id": 623551,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273448,
              "address_id": 623551,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273449,
              "address_id": 623551,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273456,
              "address_id": 623541,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273457,
              "address_id": 623541,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273458,
              "address_id": 623541,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273459,
              "address_id": 623529,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273460,
              "address_id": 623529,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273461,
              "address_id": 623529,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273462,
              "address_id": 623567,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273463,
              "address_id": 623567,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273464,
              "address_id": 623567,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273465,
              "address_id": 623536,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273466,
              "address_id": 623536,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273467,
              "address_id": 623536,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273468,
              "address_id": 623532,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273469,
              "address_id": 623532,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273470,
              "address_id": 623532,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273471,
              "address_id": 623537,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273472,
              "address_id": 623537,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273473,
              "address_id": 623537,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273474,
              "address_id": 623556,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273475,
              "address_id": 623556,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273476,
              "address_id": 623556,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273477,
              "address_id": 623538,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273478,
              "address_id": 623538,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273479,
              "address_id": 623538,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273480,
              "address_id": 623555,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273481,
              "address_id": 623555,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273482,
              "address_id": 623555,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273483,
              "address_id": 623527,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273484,
              "address_id": 623527,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273485,
              "address_id": 623527,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273489,
              "address_id": 623545,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273490,
              "address_id": 623545,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273491,
              "address_id": 623545,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273493,
              "address_id": 623544,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273494,
              "address_id": 623544,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273495,
              "address_id": 623560,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273496,
              "address_id": 623560,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273497,
              "address_id": 623560,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273501,
              "address_id": 623552,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273502,
              "address_id": 623552,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273503,
              "address_id": 623552,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273504,
              "address_id": 623564,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273505,
              "address_id": 623564,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273506,
              "address_id": 623564,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273507,
              "address_id": 623526,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273508,
              "address_id": 623526,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273509,
              "address_id": 623526,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273510,
              "address_id": 623530,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273511,
              "address_id": 623530,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273512,
              "address_id": 623530,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273513,
              "address_id": 623554,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273514,
              "address_id": 623554,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273515,
              "address_id": 623554,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273516,
              "address_id": 623558,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273517,
              "address_id": 623558,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273518,
              "address_id": 623558,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273519,
              "address_id": 623562,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273520,
              "address_id": 623562,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273521,
              "address_id": 623562,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273523,
              "address_id": 623553,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273524,
              "address_id": 623553,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273525,
              "address_id": 623528,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273526,
              "address_id": 623528,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273527,
              "address_id": 623528,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273528,
              "address_id": 623535,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273529,
              "address_id": 623535,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273530,
              "address_id": 623535,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273534,
              "address_id": 623550,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273535,
              "address_id": 623550,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273536,
              "address_id": 623550,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273537,
              "address_id": 623533,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273538,
              "address_id": 623533,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273539,
              "address_id": 623533,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273540,
              "address_id": 623569,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273541,
              "address_id": 623569,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273542,
              "address_id": 623569,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273546,
              "address_id": 623568,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273547,
              "address_id": 623568,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273548,
              "address_id": 623568,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273552,
              "address_id": 623572,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273553,
              "address_id": 623572,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273554,
              "address_id": 623572,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273556,
              "address_id": 623525,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273557,
              "address_id": 623525,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273559,
              "address_id": 623549,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273560,
              "address_id": 623549,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385239,
              "address_id": 623549,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518661,
              "address_id": 644042,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518640,
              "address_id": 644042,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518664,
              "address_id": 644042,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520961,
              "address_id": 623525,
              "homekeeper_id": 231026,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521478,
              "address_id": 623553,
              "homekeeper_id": 231026,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527710,
              "address_id": 623544,
              "homekeeper_id": 231026,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2127630,
          "date": "2025-04-10",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623560,
              "address1": "4029 Waldorf Court",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": null
  },
  {
      "id": 231027,
      "main_team_id": 228024,
      "name": "Choice Pool Management Llc",
      "first_name": "Choice",
      "last_name": "Llc",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Choice Llc's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556023,
              "address_id": 623560,
              "homekeeper_id": 231027,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556045,
              "address_id": 623562,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437410,
              "address_id": 623544,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556094,
              "address_id": 623563,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556102,
              "address_id": 623563,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556150,
              "address_id": 623529,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556189,
              "address_id": 623556,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273561,
              "address_id": 623057,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273562,
              "address_id": 623566,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273568,
              "address_id": 623531,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273569,
              "address_id": 623534,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273571,
              "address_id": 623548,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273572,
              "address_id": 623551,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273575,
              "address_id": 623541,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273577,
              "address_id": 623567,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273578,
              "address_id": 623536,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273579,
              "address_id": 623532,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273580,
              "address_id": 623537,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273582,
              "address_id": 623538,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273584,
              "address_id": 623527,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273590,
              "address_id": 623552,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273591,
              "address_id": 623564,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273592,
              "address_id": 623526,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273593,
              "address_id": 623530,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273594,
              "address_id": 623554,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273595,
              "address_id": 623558,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273597,
              "address_id": 623553,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273598,
              "address_id": 623528,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273599,
              "address_id": 623535,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273601,
              "address_id": 623550,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273602,
              "address_id": 623533,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273603,
              "address_id": 623569,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273605,
              "address_id": 623568,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273607,
              "address_id": 623572,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273608,
              "address_id": 623525,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273609,
              "address_id": 623549,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331443,
              "address_id": 623559,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331445,
              "address_id": 623561,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351310,
              "address_id": 623547,
              "homekeeper_id": 231027,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556898,
              "address_id": 623555,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539956,
              "address_id": 623545,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518618,
              "address_id": 644042,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566237,
              "address_id": 623539,
              "homekeeper_id": 231027,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231028,
      "main_team_id": 228025,
      "name": "Mauricio Landivar",
      "first_name": "Mauricio",
      "last_name": "Landivar",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Lvms Maintenance's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273610,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273611,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273612,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273613,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273614,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273615,
              "address_id": 623057,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273616,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273617,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273618,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273619,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273620,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273621,
              "address_id": 623566,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273622,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273623,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273624,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273626,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273627,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273634,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273635,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273636,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273637,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273638,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273639,
              "address_id": 623539,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273640,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273641,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273642,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273644,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273645,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273646,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273647,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273648,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273650,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273651,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273652,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273653,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273654,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273655,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273656,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273657,
              "address_id": 623531,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273658,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273659,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273660,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273661,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273662,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273663,
              "address_id": 623534,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273664,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273665,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273666,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273667,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273668,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273669,
              "address_id": 623561,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273670,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273671,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273672,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273674,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273675,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273676,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273677,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273678,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273680,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273681,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273694,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273695,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273696,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273697,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273698,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273699,
              "address_id": 623541,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273700,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273701,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273702,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273704,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273705,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273706,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273707,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273708,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273709,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273710,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273711,
              "address_id": 623567,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273712,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273713,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273714,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273715,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273716,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273717,
              "address_id": 623536,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273718,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273719,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273720,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273721,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273722,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273723,
              "address_id": 623532,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273724,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273725,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273726,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273727,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273728,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273729,
              "address_id": 623537,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273730,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273731,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273732,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273734,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273735,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273736,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273737,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273738,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273739,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273740,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273741,
              "address_id": 623538,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273742,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273743,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273744,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273746,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273747,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273748,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273749,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273750,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273751,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273752,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273753,
              "address_id": 623527,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273760,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273761,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273762,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273764,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273765,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273767,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273768,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273770,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273771,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273772,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273773,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273774,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273776,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273777,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273784,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273785,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273786,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273788,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273789,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273790,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273791,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273792,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273794,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273795,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273796,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273797,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273798,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273800,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273801,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273802,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273803,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273804,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273806,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273807,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273808,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273809,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273810,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273812,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273813,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273814,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273815,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273816,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273818,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273819,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273820,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273821,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273822,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273824,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273825,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273827,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273828,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273829,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273830,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273831,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273832,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273833,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273834,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273835,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273836,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273837,
              "address_id": 623528,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273838,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273839,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273840,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273841,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273842,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273843,
              "address_id": 623535,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273850,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273851,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273852,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273854,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273855,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273856,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273857,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273858,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273859,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273860,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273861,
              "address_id": 623533,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273862,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273863,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273864,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273865,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273866,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273867,
              "address_id": 623569,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273874,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273875,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273876,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273877,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273878,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273879,
              "address_id": 623568,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273886,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273887,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273888,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273889,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273890,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273891,
              "address_id": 623572,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273893,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273894,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273896,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273897,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273899,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273900,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273901,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273902,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273903,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385240,
              "address_id": 623549,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3534221,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3513915,
              "address_id": 623550,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3513919,
              "address_id": 623552,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3513926,
              "address_id": 623554,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518636,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518628,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518642,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518662,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518665,
              "address_id": 644042,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520962,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521479,
              "address_id": 623553,
              "homekeeper_id": 231028,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522724,
              "address_id": 623548,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522791,
              "address_id": 623525,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522893,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522914,
              "address_id": 623547,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523356,
              "address_id": 623526,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523321,
              "address_id": 623529,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523360,
              "address_id": 623559,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527358,
              "address_id": 623530,
              "homekeeper_id": 231028,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527376,
              "address_id": 623558,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527384,
              "address_id": 623560,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527389,
              "address_id": 623563,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527451,
              "address_id": 623564,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527417,
              "address_id": 623545,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527433,
              "address_id": 623556,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527441,
              "address_id": 623562,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527444,
              "address_id": 623551,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527457,
              "address_id": 623555,
              "homekeeper_id": 231028,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527711,
              "address_id": 623544,
              "homekeeper_id": 231028,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231031,
      "main_team_id": 228027,
      "name": "Sergio Handyman Service",
      "first_name": "Sergio",
      "last_name": "Service",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Sergio Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3273904,
              "address_id": 623057,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273905,
              "address_id": 623057,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273906,
              "address_id": 623057,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273907,
              "address_id": 623566,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273908,
              "address_id": 623566,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273909,
              "address_id": 623566,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273910,
              "address_id": 623547,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273911,
              "address_id": 623547,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273912,
              "address_id": 623547,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273916,
              "address_id": 623539,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273917,
              "address_id": 623539,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273918,
              "address_id": 623539,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273919,
              "address_id": 623559,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273920,
              "address_id": 623559,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273921,
              "address_id": 623559,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273922,
              "address_id": 623563,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273923,
              "address_id": 623563,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273924,
              "address_id": 623563,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273925,
              "address_id": 623531,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273926,
              "address_id": 623531,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273927,
              "address_id": 623531,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273928,
              "address_id": 623534,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273929,
              "address_id": 623534,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273930,
              "address_id": 623534,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273931,
              "address_id": 623561,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273932,
              "address_id": 623561,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273933,
              "address_id": 623561,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273934,
              "address_id": 623548,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273935,
              "address_id": 623548,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273936,
              "address_id": 623548,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273937,
              "address_id": 623551,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273938,
              "address_id": 623551,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273939,
              "address_id": 623551,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273946,
              "address_id": 623541,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273947,
              "address_id": 623541,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273948,
              "address_id": 623541,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273949,
              "address_id": 623529,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273950,
              "address_id": 623529,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273951,
              "address_id": 623529,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273952,
              "address_id": 623567,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273953,
              "address_id": 623567,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273954,
              "address_id": 623567,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273955,
              "address_id": 623536,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273956,
              "address_id": 623536,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273957,
              "address_id": 623536,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273958,
              "address_id": 623532,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273959,
              "address_id": 623532,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273960,
              "address_id": 623532,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273961,
              "address_id": 623537,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273962,
              "address_id": 623537,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273963,
              "address_id": 623537,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273964,
              "address_id": 623556,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273965,
              "address_id": 623556,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273966,
              "address_id": 623556,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273967,
              "address_id": 623538,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273968,
              "address_id": 623538,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273969,
              "address_id": 623538,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273970,
              "address_id": 623555,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273971,
              "address_id": 623555,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273972,
              "address_id": 623555,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273973,
              "address_id": 623527,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273974,
              "address_id": 623527,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273975,
              "address_id": 623527,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273979,
              "address_id": 623545,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273980,
              "address_id": 623545,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273981,
              "address_id": 623545,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273983,
              "address_id": 623544,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273984,
              "address_id": 623544,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273985,
              "address_id": 623560,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273986,
              "address_id": 623560,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273987,
              "address_id": 623560,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273991,
              "address_id": 623552,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273992,
              "address_id": 623552,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273993,
              "address_id": 623552,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273994,
              "address_id": 623564,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273995,
              "address_id": 623564,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273996,
              "address_id": 623564,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273997,
              "address_id": 623526,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273998,
              "address_id": 623526,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3273999,
              "address_id": 623526,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274000,
              "address_id": 623530,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274001,
              "address_id": 623530,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274002,
              "address_id": 623530,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274003,
              "address_id": 623554,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274004,
              "address_id": 623554,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274005,
              "address_id": 623554,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274006,
              "address_id": 623558,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274007,
              "address_id": 623558,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274008,
              "address_id": 623558,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274009,
              "address_id": 623562,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274010,
              "address_id": 623562,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274011,
              "address_id": 623562,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274013,
              "address_id": 623553,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274014,
              "address_id": 623553,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274015,
              "address_id": 623528,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274016,
              "address_id": 623528,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274017,
              "address_id": 623528,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274018,
              "address_id": 623535,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274019,
              "address_id": 623535,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274020,
              "address_id": 623535,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274024,
              "address_id": 623550,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274025,
              "address_id": 623550,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274026,
              "address_id": 623550,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274027,
              "address_id": 623533,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274028,
              "address_id": 623533,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274029,
              "address_id": 623533,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274030,
              "address_id": 623569,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274031,
              "address_id": 623569,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274032,
              "address_id": 623569,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274036,
              "address_id": 623568,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274037,
              "address_id": 623568,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274038,
              "address_id": 623568,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274042,
              "address_id": 623572,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274043,
              "address_id": 623572,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274044,
              "address_id": 623572,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274046,
              "address_id": 623525,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274047,
              "address_id": 623525,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274049,
              "address_id": 623549,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274050,
              "address_id": 623549,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385241,
              "address_id": 623549,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518643,
              "address_id": 644042,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518663,
              "address_id": 644042,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 143,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518666,
              "address_id": 644042,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520963,
              "address_id": 623525,
              "homekeeper_id": 231031,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521480,
              "address_id": 623553,
              "homekeeper_id": 231031,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527712,
              "address_id": 623544,
              "homekeeper_id": 231031,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231032,
      "main_team_id": 228028,
      "name": "Jp \u0026 Jc Landscape Maintenance",
      "first_name": "Jp",
      "last_name": "Maintenance",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Jp Maintenance's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274070,
              "address_id": 623057,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274071,
              "address_id": 623057,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274072,
              "address_id": 623566,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274073,
              "address_id": 623566,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274074,
              "address_id": 623547,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274075,
              "address_id": 623547,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274078,
              "address_id": 623539,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274079,
              "address_id": 623539,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274080,
              "address_id": 623559,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274081,
              "address_id": 623559,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274082,
              "address_id": 623563,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274083,
              "address_id": 623563,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274084,
              "address_id": 623531,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274085,
              "address_id": 623531,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274086,
              "address_id": 623534,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274087,
              "address_id": 623534,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274088,
              "address_id": 623561,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274089,
              "address_id": 623561,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274090,
              "address_id": 623548,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274091,
              "address_id": 623548,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274092,
              "address_id": 623551,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274093,
              "address_id": 623551,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274098,
              "address_id": 623541,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274099,
              "address_id": 623541,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274100,
              "address_id": 623529,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274101,
              "address_id": 623529,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274102,
              "address_id": 623567,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274103,
              "address_id": 623567,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274104,
              "address_id": 623536,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274105,
              "address_id": 623536,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274106,
              "address_id": 623532,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274107,
              "address_id": 623532,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274108,
              "address_id": 623537,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274109,
              "address_id": 623537,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274110,
              "address_id": 623556,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274111,
              "address_id": 623556,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274112,
              "address_id": 623538,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274113,
              "address_id": 623538,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274114,
              "address_id": 623555,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274115,
              "address_id": 623555,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274116,
              "address_id": 623527,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274117,
              "address_id": 623527,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274120,
              "address_id": 623545,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274121,
              "address_id": 623545,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274122,
              "address_id": 623544,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274123,
              "address_id": 623544,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274124,
              "address_id": 623560,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274125,
              "address_id": 623560,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274128,
              "address_id": 623552,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274129,
              "address_id": 623552,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274130,
              "address_id": 623564,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274131,
              "address_id": 623564,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274132,
              "address_id": 623526,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274133,
              "address_id": 623526,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274134,
              "address_id": 623530,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274135,
              "address_id": 623530,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274136,
              "address_id": 623554,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274137,
              "address_id": 623554,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274138,
              "address_id": 623558,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274139,
              "address_id": 623558,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274140,
              "address_id": 623562,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274141,
              "address_id": 623562,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274142,
              "address_id": 623553,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274143,
              "address_id": 623553,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274144,
              "address_id": 623528,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274145,
              "address_id": 623528,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274146,
              "address_id": 623535,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274147,
              "address_id": 623535,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274150,
              "address_id": 623550,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274151,
              "address_id": 623550,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274152,
              "address_id": 623533,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274153,
              "address_id": 623533,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274154,
              "address_id": 623569,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274155,
              "address_id": 623569,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274158,
              "address_id": 623568,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274159,
              "address_id": 623568,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274162,
              "address_id": 623572,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274163,
              "address_id": 623572,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274164,
              "address_id": 623525,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274165,
              "address_id": 623525,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274166,
              "address_id": 623549,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274167,
              "address_id": 623549,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518629,
              "address_id": 644042,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518637,
              "address_id": 644042,
              "homekeeper_id": 231032,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231033,
      "main_team_id": 228029,
      "name": "Sorensenscape **Not For Ltr's**",
      "first_name": "Sorensenscape",
      "last_name": "Ltr's**",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Sorensenscape Ltr's**'s Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274168,
              "address_id": 623057,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274169,
              "address_id": 623057,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274170,
              "address_id": 623566,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274171,
              "address_id": 623566,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274172,
              "address_id": 623547,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274173,
              "address_id": 623547,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274176,
              "address_id": 623539,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274177,
              "address_id": 623539,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274178,
              "address_id": 623559,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274179,
              "address_id": 623559,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274180,
              "address_id": 623563,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274181,
              "address_id": 623563,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274182,
              "address_id": 623531,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274183,
              "address_id": 623531,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274184,
              "address_id": 623534,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274185,
              "address_id": 623534,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274186,
              "address_id": 623561,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274187,
              "address_id": 623561,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274188,
              "address_id": 623548,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274189,
              "address_id": 623548,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274190,
              "address_id": 623551,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274191,
              "address_id": 623551,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274196,
              "address_id": 623541,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274197,
              "address_id": 623541,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274198,
              "address_id": 623529,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274199,
              "address_id": 623529,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274200,
              "address_id": 623567,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274201,
              "address_id": 623567,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274202,
              "address_id": 623536,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274203,
              "address_id": 623536,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274204,
              "address_id": 623532,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274205,
              "address_id": 623532,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274206,
              "address_id": 623537,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274207,
              "address_id": 623537,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274208,
              "address_id": 623556,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274209,
              "address_id": 623556,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274210,
              "address_id": 623538,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274211,
              "address_id": 623538,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274212,
              "address_id": 623555,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274213,
              "address_id": 623555,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274214,
              "address_id": 623527,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274215,
              "address_id": 623527,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274218,
              "address_id": 623545,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274219,
              "address_id": 623545,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274220,
              "address_id": 623544,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274221,
              "address_id": 623544,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274222,
              "address_id": 623560,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274223,
              "address_id": 623560,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274226,
              "address_id": 623552,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274227,
              "address_id": 623552,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274228,
              "address_id": 623564,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274229,
              "address_id": 623564,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274230,
              "address_id": 623526,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274231,
              "address_id": 623526,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274232,
              "address_id": 623530,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274233,
              "address_id": 623530,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274234,
              "address_id": 623554,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274235,
              "address_id": 623554,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274236,
              "address_id": 623558,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274237,
              "address_id": 623558,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274238,
              "address_id": 623562,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274239,
              "address_id": 623562,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274240,
              "address_id": 623553,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274241,
              "address_id": 623553,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274242,
              "address_id": 623528,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274243,
              "address_id": 623528,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274244,
              "address_id": 623535,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274245,
              "address_id": 623535,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274248,
              "address_id": 623550,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274249,
              "address_id": 623550,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274250,
              "address_id": 623533,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274251,
              "address_id": 623533,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274252,
              "address_id": 623569,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274253,
              "address_id": 623569,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274256,
              "address_id": 623568,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274257,
              "address_id": 623568,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274260,
              "address_id": 623572,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274261,
              "address_id": 623572,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274262,
              "address_id": 623525,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274263,
              "address_id": 623525,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274264,
              "address_id": 623549,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274265,
              "address_id": 623549,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518630,
              "address_id": 644042,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518638,
              "address_id": 644042,
              "homekeeper_id": 231033,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231036,
      "main_team_id": 228032,
      "name": "Pacific Pools",
      "first_name": "Pacific",
      "last_name": "Pools",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Pacific Pools's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556024,
              "address_id": 623560,
              "homekeeper_id": 231036,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556046,
              "address_id": 623562,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437411,
              "address_id": 623544,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556095,
              "address_id": 623563,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556103,
              "address_id": 623563,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556151,
              "address_id": 623529,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556190,
              "address_id": 623556,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274428,
              "address_id": 623057,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274429,
              "address_id": 623566,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274435,
              "address_id": 623531,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274436,
              "address_id": 623534,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274438,
              "address_id": 623548,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274439,
              "address_id": 623551,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274442,
              "address_id": 623541,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274444,
              "address_id": 623567,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274445,
              "address_id": 623536,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274446,
              "address_id": 623532,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274447,
              "address_id": 623537,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274449,
              "address_id": 623538,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274451,
              "address_id": 623527,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274457,
              "address_id": 623552,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274458,
              "address_id": 623564,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274459,
              "address_id": 623526,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274460,
              "address_id": 623530,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274461,
              "address_id": 623554,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274462,
              "address_id": 623558,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274464,
              "address_id": 623553,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274465,
              "address_id": 623528,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274466,
              "address_id": 623535,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274468,
              "address_id": 623550,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274469,
              "address_id": 623533,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274470,
              "address_id": 623569,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274472,
              "address_id": 623568,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274474,
              "address_id": 623572,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274475,
              "address_id": 623525,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274476,
              "address_id": 623549,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331437,
              "address_id": 623559,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331446,
              "address_id": 623561,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351311,
              "address_id": 623547,
              "homekeeper_id": 231036,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556899,
              "address_id": 623555,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539957,
              "address_id": 623545,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518619,
              "address_id": 644042,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566238,
              "address_id": 623539,
              "homekeeper_id": 231036,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231037,
      "main_team_id": 228033,
      "name": "Priority Pools",
      "first_name": "Priority",
      "last_name": "Pools",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Priority Pools's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556026,
              "address_id": 623560,
              "homekeeper_id": 231037,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556048,
              "address_id": 623562,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437412,
              "address_id": 623544,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556096,
              "address_id": 623563,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556105,
              "address_id": 623563,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556152,
              "address_id": 623529,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556191,
              "address_id": 623556,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274526,
              "address_id": 623057,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274527,
              "address_id": 623566,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274533,
              "address_id": 623531,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274534,
              "address_id": 623534,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274536,
              "address_id": 623548,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274537,
              "address_id": 623551,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274540,
              "address_id": 623541,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274542,
              "address_id": 623567,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274543,
              "address_id": 623536,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274544,
              "address_id": 623532,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274545,
              "address_id": 623537,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274547,
              "address_id": 623538,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274549,
              "address_id": 623527,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274555,
              "address_id": 623552,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274556,
              "address_id": 623564,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274557,
              "address_id": 623526,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274558,
              "address_id": 623530,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274559,
              "address_id": 623554,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274560,
              "address_id": 623558,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274562,
              "address_id": 623553,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274563,
              "address_id": 623528,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274564,
              "address_id": 623535,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274566,
              "address_id": 623550,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274567,
              "address_id": 623533,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274568,
              "address_id": 623569,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274570,
              "address_id": 623568,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274572,
              "address_id": 623572,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274573,
              "address_id": 623525,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274574,
              "address_id": 623549,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331438,
              "address_id": 623559,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331447,
              "address_id": 623561,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351313,
              "address_id": 623547,
              "homekeeper_id": 231037,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556901,
              "address_id": 623555,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539958,
              "address_id": 623545,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518620,
              "address_id": 644042,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566240,
              "address_id": 623539,
              "homekeeper_id": 231037,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231038,
      "main_team_id": 228034,
      "name": "Elite Pool Service",
      "first_name": "Elite",
      "last_name": "Service",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Elite Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556027,
              "address_id": 623560,
              "homekeeper_id": 231038,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556049,
              "address_id": 623562,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437413,
              "address_id": 623544,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556097,
              "address_id": 623563,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556106,
              "address_id": 623563,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556153,
              "address_id": 623529,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556192,
              "address_id": 623556,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274575,
              "address_id": 623057,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274576,
              "address_id": 623566,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274582,
              "address_id": 623531,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274583,
              "address_id": 623534,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274585,
              "address_id": 623548,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274586,
              "address_id": 623551,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274589,
              "address_id": 623541,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274591,
              "address_id": 623567,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274592,
              "address_id": 623536,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274593,
              "address_id": 623532,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274594,
              "address_id": 623537,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274596,
              "address_id": 623538,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274598,
              "address_id": 623527,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274604,
              "address_id": 623552,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274605,
              "address_id": 623564,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274606,
              "address_id": 623526,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274607,
              "address_id": 623530,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274608,
              "address_id": 623554,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274609,
              "address_id": 623558,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274611,
              "address_id": 623553,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274612,
              "address_id": 623528,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274613,
              "address_id": 623535,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274615,
              "address_id": 623550,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274616,
              "address_id": 623533,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274617,
              "address_id": 623569,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274619,
              "address_id": 623568,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274621,
              "address_id": 623572,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274622,
              "address_id": 623525,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274623,
              "address_id": 623549,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331439,
              "address_id": 623559,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331448,
              "address_id": 623561,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351314,
              "address_id": 623547,
              "homekeeper_id": 231038,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556902,
              "address_id": 623555,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539959,
              "address_id": 623545,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518621,
              "address_id": 644042,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566241,
              "address_id": 623539,
              "homekeeper_id": 231038,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231039,
      "main_team_id": 228035,
      "name": "Brandon Scott",
      "first_name": "Brandon",
      "last_name": "Scott",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Elite Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556028,
              "address_id": 623560,
              "homekeeper_id": 231039,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556050,
              "address_id": 623562,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437414,
              "address_id": 623544,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556098,
              "address_id": 623563,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556107,
              "address_id": 623563,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556154,
              "address_id": 623529,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556193,
              "address_id": 623556,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274643,
              "address_id": 623057,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274644,
              "address_id": 623566,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274650,
              "address_id": 623531,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274651,
              "address_id": 623534,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274653,
              "address_id": 623548,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274654,
              "address_id": 623551,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274657,
              "address_id": 623541,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274659,
              "address_id": 623567,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274660,
              "address_id": 623536,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274661,
              "address_id": 623532,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274662,
              "address_id": 623537,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274664,
              "address_id": 623538,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274666,
              "address_id": 623527,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274672,
              "address_id": 623552,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274673,
              "address_id": 623564,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274674,
              "address_id": 623526,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274675,
              "address_id": 623530,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274676,
              "address_id": 623554,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274677,
              "address_id": 623558,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274679,
              "address_id": 623553,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274680,
              "address_id": 623528,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274681,
              "address_id": 623535,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274683,
              "address_id": 623550,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274684,
              "address_id": 623533,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274685,
              "address_id": 623569,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274687,
              "address_id": 623568,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274689,
              "address_id": 623572,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274690,
              "address_id": 623525,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274691,
              "address_id": 623549,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331440,
              "address_id": 623559,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331449,
              "address_id": 623561,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351315,
              "address_id": 623547,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351306,
              "address_id": 627213,
              "homekeeper_id": 231039,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556907,
              "address_id": 623555,
              "homekeeper_id": 231039,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539960,
              "address_id": 623545,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518622,
              "address_id": 644042,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566242,
              "address_id": 623539,
              "homekeeper_id": 231039,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2123332,
          "date": "2025-03-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623555,
              "address1": "3322 West Mardon Avenue",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2123331,
          "date": "2025-03-21",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623555,
              "address1": "3322 West Mardon Avenue",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 231040,
      "main_team_id": 228036,
      "name": "Poo Lice Pool Svc",
      "first_name": "Poo",
      "last_name": "Svc",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Poo Svc's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556029,
              "address_id": 623560,
              "homekeeper_id": 231040,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556051,
              "address_id": 623562,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437415,
              "address_id": 623544,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556099,
              "address_id": 623563,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556108,
              "address_id": 623563,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556155,
              "address_id": 623529,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556194,
              "address_id": 623556,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274692,
              "address_id": 623057,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274693,
              "address_id": 623566,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274699,
              "address_id": 623531,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274700,
              "address_id": 623534,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274702,
              "address_id": 623548,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274703,
              "address_id": 623551,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274706,
              "address_id": 623541,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274708,
              "address_id": 623567,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274709,
              "address_id": 623536,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274710,
              "address_id": 623532,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274711,
              "address_id": 623537,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274713,
              "address_id": 623538,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274715,
              "address_id": 623527,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274721,
              "address_id": 623552,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274722,
              "address_id": 623564,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274723,
              "address_id": 623526,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274724,
              "address_id": 623530,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274725,
              "address_id": 623554,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274726,
              "address_id": 623558,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274728,
              "address_id": 623553,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274729,
              "address_id": 623528,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274730,
              "address_id": 623535,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274732,
              "address_id": 623550,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274733,
              "address_id": 623533,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274734,
              "address_id": 623569,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274736,
              "address_id": 623568,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274738,
              "address_id": 623572,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274739,
              "address_id": 623525,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274740,
              "address_id": 623549,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331441,
              "address_id": 623559,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331450,
              "address_id": 623561,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351316,
              "address_id": 623547,
              "homekeeper_id": 231040,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556903,
              "address_id": 623555,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539961,
              "address_id": 623545,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518623,
              "address_id": 644042,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566243,
              "address_id": 623539,
              "homekeeper_id": 231040,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231041,
      "main_team_id": 228037,
      "name": "Dignity Plumbing",
      "first_name": "Dignity",
      "last_name": "Plumbing",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Dignity Plumbing's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274760,
              "address_id": 623057,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274761,
              "address_id": 623566,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274762,
              "address_id": 623547,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274764,
              "address_id": 623539,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274765,
              "address_id": 623559,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274766,
              "address_id": 623563,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274767,
              "address_id": 623531,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274768,
              "address_id": 623534,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274769,
              "address_id": 623561,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274770,
              "address_id": 623548,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274771,
              "address_id": 623551,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274774,
              "address_id": 623541,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274775,
              "address_id": 623529,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274776,
              "address_id": 623567,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274777,
              "address_id": 623536,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274778,
              "address_id": 623532,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274779,
              "address_id": 623537,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274780,
              "address_id": 623556,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274781,
              "address_id": 623538,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274782,
              "address_id": 623555,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274783,
              "address_id": 623527,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274785,
              "address_id": 623545,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274786,
              "address_id": 623544,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274787,
              "address_id": 623560,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274789,
              "address_id": 623552,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274790,
              "address_id": 623564,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274791,
              "address_id": 623526,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274792,
              "address_id": 623530,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274793,
              "address_id": 623554,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274794,
              "address_id": 623558,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274795,
              "address_id": 623562,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274796,
              "address_id": 623553,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274797,
              "address_id": 623528,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274798,
              "address_id": 623535,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274800,
              "address_id": 623550,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274801,
              "address_id": 623533,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274802,
              "address_id": 623569,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274804,
              "address_id": 623568,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274806,
              "address_id": 623572,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274807,
              "address_id": 623525,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274808,
              "address_id": 623549,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518667,
              "address_id": 644042,
              "homekeeper_id": 231041,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231042,
      "main_team_id": 228038,
      "name": "Priority Appliances",
      "first_name": "Priority",
      "last_name": "Appliances",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Priority Appliances's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274809,
              "address_id": 623057,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274810,
              "address_id": 623566,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274811,
              "address_id": 623547,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274813,
              "address_id": 623539,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274814,
              "address_id": 623559,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274815,
              "address_id": 623563,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274816,
              "address_id": 623531,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274817,
              "address_id": 623534,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274818,
              "address_id": 623561,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274819,
              "address_id": 623548,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274820,
              "address_id": 623551,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274823,
              "address_id": 623541,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274824,
              "address_id": 623529,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274825,
              "address_id": 623567,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274826,
              "address_id": 623536,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274827,
              "address_id": 623532,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274828,
              "address_id": 623537,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274829,
              "address_id": 623556,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274830,
              "address_id": 623538,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274831,
              "address_id": 623555,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274832,
              "address_id": 623527,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274834,
              "address_id": 623545,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274835,
              "address_id": 623544,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274836,
              "address_id": 623560,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274838,
              "address_id": 623552,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274839,
              "address_id": 623564,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274840,
              "address_id": 623526,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274841,
              "address_id": 623530,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274842,
              "address_id": 623554,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274843,
              "address_id": 623558,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274844,
              "address_id": 623562,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274845,
              "address_id": 623553,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274846,
              "address_id": 623528,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274847,
              "address_id": 623535,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274849,
              "address_id": 623550,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274850,
              "address_id": 623533,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274851,
              "address_id": 623569,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274853,
              "address_id": 623568,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274855,
              "address_id": 623572,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274856,
              "address_id": 623525,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274857,
              "address_id": 623549,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518672,
              "address_id": 644042,
              "homekeeper_id": 231042,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231044,
      "main_team_id": 228040,
      "name": "Preventive Pest Control",
      "first_name": "Preventive",
      "last_name": "Control",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Preventive Control's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274858,
              "address_id": 623057,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274859,
              "address_id": 623566,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274860,
              "address_id": 623547,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274862,
              "address_id": 623539,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274863,
              "address_id": 623559,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274864,
              "address_id": 623563,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274865,
              "address_id": 623531,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274866,
              "address_id": 623534,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274867,
              "address_id": 623561,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274868,
              "address_id": 623548,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274869,
              "address_id": 623551,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274872,
              "address_id": 623541,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274873,
              "address_id": 623529,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274874,
              "address_id": 623567,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274875,
              "address_id": 623536,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274876,
              "address_id": 623532,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274877,
              "address_id": 623537,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274878,
              "address_id": 623556,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274879,
              "address_id": 623538,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274880,
              "address_id": 623555,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274881,
              "address_id": 623527,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274883,
              "address_id": 623545,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274884,
              "address_id": 623544,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274885,
              "address_id": 623560,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274887,
              "address_id": 623552,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274888,
              "address_id": 623564,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274889,
              "address_id": 623526,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274890,
              "address_id": 623530,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274891,
              "address_id": 623554,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274892,
              "address_id": 623558,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274893,
              "address_id": 623562,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274894,
              "address_id": 623553,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274895,
              "address_id": 623528,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274896,
              "address_id": 623535,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274898,
              "address_id": 623550,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274899,
              "address_id": 623533,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274900,
              "address_id": 623569,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274902,
              "address_id": 623568,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274904,
              "address_id": 623572,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274905,
              "address_id": 623525,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274906,
              "address_id": 623549,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518632,
              "address_id": 644042,
              "homekeeper_id": 231044,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231045,
      "main_team_id": 228041,
      "name": "J2 Carpet And Tile Cleaning",
      "first_name": "J2",
      "last_name": "Cleaning",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "J2 Cleaning's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3274907,
              "address_id": 623057,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274908,
              "address_id": 623566,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274909,
              "address_id": 623547,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274911,
              "address_id": 623539,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274912,
              "address_id": 623559,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274913,
              "address_id": 623563,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274914,
              "address_id": 623531,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274915,
              "address_id": 623534,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274916,
              "address_id": 623561,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274917,
              "address_id": 623548,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274918,
              "address_id": 623551,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274921,
              "address_id": 623541,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274922,
              "address_id": 623529,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274923,
              "address_id": 623567,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274924,
              "address_id": 623536,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274925,
              "address_id": 623532,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274926,
              "address_id": 623537,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274927,
              "address_id": 623556,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274928,
              "address_id": 623538,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274929,
              "address_id": 623555,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274930,
              "address_id": 623527,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274932,
              "address_id": 623545,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274933,
              "address_id": 623544,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274934,
              "address_id": 623560,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274936,
              "address_id": 623552,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274937,
              "address_id": 623564,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274938,
              "address_id": 623526,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274939,
              "address_id": 623530,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274940,
              "address_id": 623554,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274941,
              "address_id": 623558,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274942,
              "address_id": 623562,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274943,
              "address_id": 623553,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274944,
              "address_id": 623528,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274945,
              "address_id": 623535,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274947,
              "address_id": 623550,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274948,
              "address_id": 623533,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274949,
              "address_id": 623569,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274951,
              "address_id": 623568,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274953,
              "address_id": 623572,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274954,
              "address_id": 623525,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274955,
              "address_id": 623549,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518626,
              "address_id": 644042,
              "homekeeper_id": 231045,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231046,
      "main_team_id": 228042,
      "name": "John Marshall",
      "first_name": "John",
      "last_name": "Marshall",
      "state": "active",
      "gender": "male",
      "is_private": true,
      "team_name": "Quality Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556030,
              "address_id": 623560,
              "homekeeper_id": 231046,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556052,
              "address_id": 623562,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556100,
              "address_id": 623563,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556109,
              "address_id": 623563,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556156,
              "address_id": 623529,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556195,
              "address_id": 623556,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274956,
              "address_id": 623057,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274957,
              "address_id": 623057,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274958,
              "address_id": 623057,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274959,
              "address_id": 623057,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274960,
              "address_id": 623566,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274962,
              "address_id": 623566,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274963,
              "address_id": 623566,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274964,
              "address_id": 623547,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274967,
              "address_id": 623547,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274972,
              "address_id": 623539,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274975,
              "address_id": 623539,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274976,
              "address_id": 623559,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274979,
              "address_id": 623559,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274980,
              "address_id": 623563,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274983,
              "address_id": 623563,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274984,
              "address_id": 623531,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274986,
              "address_id": 623531,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274987,
              "address_id": 623531,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274988,
              "address_id": 623534,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274990,
              "address_id": 623534,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274991,
              "address_id": 623534,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274992,
              "address_id": 623561,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274995,
              "address_id": 623561,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274996,
              "address_id": 623548,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274998,
              "address_id": 623548,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3274999,
              "address_id": 623548,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275000,
              "address_id": 623551,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275002,
              "address_id": 623551,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275003,
              "address_id": 623551,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275012,
              "address_id": 623541,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275014,
              "address_id": 623541,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275015,
              "address_id": 623541,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275016,
              "address_id": 623529,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275019,
              "address_id": 623529,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275020,
              "address_id": 623567,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275022,
              "address_id": 623567,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275023,
              "address_id": 623567,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275024,
              "address_id": 623536,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275026,
              "address_id": 623536,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275027,
              "address_id": 623536,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275028,
              "address_id": 623532,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275030,
              "address_id": 623532,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275031,
              "address_id": 623532,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275032,
              "address_id": 623537,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275034,
              "address_id": 623537,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275035,
              "address_id": 623537,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275036,
              "address_id": 623556,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275039,
              "address_id": 623556,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275040,
              "address_id": 623538,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275042,
              "address_id": 623538,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275043,
              "address_id": 623538,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275044,
              "address_id": 623555,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275047,
              "address_id": 623555,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275048,
              "address_id": 623527,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275050,
              "address_id": 623527,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275051,
              "address_id": 623527,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275056,
              "address_id": 623545,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275059,
              "address_id": 623545,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275063,
              "address_id": 623544,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275064,
              "address_id": 623560,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275067,
              "address_id": 623560,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275072,
              "address_id": 623552,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275074,
              "address_id": 623552,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275075,
              "address_id": 623552,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275076,
              "address_id": 623564,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275078,
              "address_id": 623564,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275079,
              "address_id": 623564,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275080,
              "address_id": 623526,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275082,
              "address_id": 623526,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275083,
              "address_id": 623526,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275084,
              "address_id": 623530,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275086,
              "address_id": 623530,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275087,
              "address_id": 623530,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275088,
              "address_id": 623554,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275090,
              "address_id": 623554,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275091,
              "address_id": 623554,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275092,
              "address_id": 623558,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275094,
              "address_id": 623558,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275095,
              "address_id": 623558,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275096,
              "address_id": 623562,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275099,
              "address_id": 623562,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275102,
              "address_id": 623553,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275103,
              "address_id": 623553,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275104,
              "address_id": 623528,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275106,
              "address_id": 623528,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275107,
              "address_id": 623528,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275108,
              "address_id": 623535,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275110,
              "address_id": 623535,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275111,
              "address_id": 623535,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275116,
              "address_id": 623550,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275118,
              "address_id": 623550,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275119,
              "address_id": 623550,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275120,
              "address_id": 623533,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275122,
              "address_id": 623533,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275123,
              "address_id": 623533,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275124,
              "address_id": 623569,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275126,
              "address_id": 623569,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275127,
              "address_id": 623569,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275132,
              "address_id": 623568,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275134,
              "address_id": 623568,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275135,
              "address_id": 623568,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275140,
              "address_id": 623572,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275142,
              "address_id": 623572,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275143,
              "address_id": 623572,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275146,
              "address_id": 623525,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275147,
              "address_id": 623525,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275150,
              "address_id": 623549,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275151,
              "address_id": 623549,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3533740,
              "address_id": 623559,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556906,
              "address_id": 623555,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539962,
              "address_id": 623545,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526026,
              "address_id": 623547,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526027,
              "address_id": 623561,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526029,
              "address_id": 623544,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526030,
              "address_id": 644042,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526052,
              "address_id": 623566,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3526219,
              "address_id": 629247,
              "homekeeper_id": 231046,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527713,
              "address_id": 623544,
              "homekeeper_id": 231046,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566244,
              "address_id": 623539,
              "homekeeper_id": 231046,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231047,
      "main_team_id": 228043,
      "name": "Hometeam",
      "first_name": "Hometeam",
      "last_name": "Hometeam",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Hometeam Hometeam's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275177,
              "address_id": 623057,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275178,
              "address_id": 623566,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275179,
              "address_id": 623547,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275181,
              "address_id": 623539,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275182,
              "address_id": 623559,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275183,
              "address_id": 623563,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275184,
              "address_id": 623531,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275185,
              "address_id": 623534,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275186,
              "address_id": 623561,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275187,
              "address_id": 623548,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275188,
              "address_id": 623551,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275191,
              "address_id": 623541,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275192,
              "address_id": 623529,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275193,
              "address_id": 623567,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275194,
              "address_id": 623536,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275195,
              "address_id": 623532,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275196,
              "address_id": 623537,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275197,
              "address_id": 623556,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275198,
              "address_id": 623538,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275199,
              "address_id": 623555,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275200,
              "address_id": 623527,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275202,
              "address_id": 623545,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275203,
              "address_id": 623544,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275204,
              "address_id": 623560,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275206,
              "address_id": 623552,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275207,
              "address_id": 623564,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275208,
              "address_id": 623526,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275209,
              "address_id": 623530,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275210,
              "address_id": 623554,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275211,
              "address_id": 623558,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275212,
              "address_id": 623562,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275213,
              "address_id": 623553,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275214,
              "address_id": 623528,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275215,
              "address_id": 623535,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275217,
              "address_id": 623550,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275218,
              "address_id": 623533,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275219,
              "address_id": 623569,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275221,
              "address_id": 623568,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275223,
              "address_id": 623572,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275224,
              "address_id": 623525,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275225,
              "address_id": 623549,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518633,
              "address_id": 644042,
              "homekeeper_id": 231047,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231048,
      "main_team_id": 228044,
      "name": "Sunkist Pools",
      "first_name": "Sunkist",
      "last_name": "Pools",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Sunkist Pools's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556033,
              "address_id": 623560,
              "homekeeper_id": 231048,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556053,
              "address_id": 623562,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3437416,
              "address_id": 623544,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556101,
              "address_id": 623563,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556110,
              "address_id": 623563,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556157,
              "address_id": 623529,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556196,
              "address_id": 623556,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275226,
              "address_id": 623057,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275227,
              "address_id": 623566,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275233,
              "address_id": 623531,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275234,
              "address_id": 623534,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275236,
              "address_id": 623548,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275237,
              "address_id": 623551,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275240,
              "address_id": 623541,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275242,
              "address_id": 623567,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275243,
              "address_id": 623536,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275244,
              "address_id": 623532,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275245,
              "address_id": 623537,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275247,
              "address_id": 623538,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275249,
              "address_id": 623527,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275255,
              "address_id": 623552,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275256,
              "address_id": 623564,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275257,
              "address_id": 623526,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275258,
              "address_id": 623530,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275259,
              "address_id": 623554,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275260,
              "address_id": 623558,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275262,
              "address_id": 623553,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275263,
              "address_id": 623528,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275264,
              "address_id": 623535,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275266,
              "address_id": 623550,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275267,
              "address_id": 623533,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275268,
              "address_id": 623569,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275270,
              "address_id": 623568,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275272,
              "address_id": 623572,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275273,
              "address_id": 623525,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275274,
              "address_id": 623549,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331442,
              "address_id": 623559,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3331451,
              "address_id": 623561,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3351317,
              "address_id": 623547,
              "homekeeper_id": 231048,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556904,
              "address_id": 623555,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3539963,
              "address_id": 623545,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518624,
              "address_id": 644042,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566245,
              "address_id": 623539,
              "homekeeper_id": 231048,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231049,
      "main_team_id": 228045,
      "name": "Silver Star Plumbing",
      "first_name": "Silver",
      "last_name": "Plumbing",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Silver Plumbing's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275275,
              "address_id": 623057,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275276,
              "address_id": 623566,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275277,
              "address_id": 623547,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275279,
              "address_id": 623539,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275280,
              "address_id": 623559,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275281,
              "address_id": 623563,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275282,
              "address_id": 623531,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275283,
              "address_id": 623534,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275284,
              "address_id": 623561,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275285,
              "address_id": 623548,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275286,
              "address_id": 623551,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275289,
              "address_id": 623541,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275290,
              "address_id": 623529,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275291,
              "address_id": 623567,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275292,
              "address_id": 623536,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275293,
              "address_id": 623532,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275294,
              "address_id": 623537,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275295,
              "address_id": 623556,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275296,
              "address_id": 623538,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275297,
              "address_id": 623555,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275298,
              "address_id": 623527,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275300,
              "address_id": 623545,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275301,
              "address_id": 623544,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275302,
              "address_id": 623560,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275304,
              "address_id": 623552,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275305,
              "address_id": 623564,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275306,
              "address_id": 623526,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275307,
              "address_id": 623530,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275308,
              "address_id": 623554,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275309,
              "address_id": 623558,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275310,
              "address_id": 623562,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275311,
              "address_id": 623553,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275312,
              "address_id": 623528,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275313,
              "address_id": 623535,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275315,
              "address_id": 623550,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275316,
              "address_id": 623533,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275317,
              "address_id": 623569,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275319,
              "address_id": 623568,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275321,
              "address_id": 623572,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275322,
              "address_id": 623525,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275323,
              "address_id": 623549,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518668,
              "address_id": 644042,
              "homekeeper_id": 231049,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231050,
      "main_team_id": 228046,
      "name": "Down Under Plumbing",
      "first_name": "Down",
      "last_name": "Plumbing",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Down Plumbing's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275324,
              "address_id": 623057,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275325,
              "address_id": 623566,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275326,
              "address_id": 623547,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275328,
              "address_id": 623539,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275329,
              "address_id": 623559,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275330,
              "address_id": 623563,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275331,
              "address_id": 623531,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275332,
              "address_id": 623534,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275333,
              "address_id": 623561,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275334,
              "address_id": 623548,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275335,
              "address_id": 623551,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275338,
              "address_id": 623541,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275339,
              "address_id": 623529,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275340,
              "address_id": 623567,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275341,
              "address_id": 623536,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275342,
              "address_id": 623532,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275343,
              "address_id": 623537,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275344,
              "address_id": 623556,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275345,
              "address_id": 623538,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275346,
              "address_id": 623555,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275347,
              "address_id": 623527,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275349,
              "address_id": 623545,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275350,
              "address_id": 623544,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275351,
              "address_id": 623560,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275353,
              "address_id": 623552,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275354,
              "address_id": 623564,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275355,
              "address_id": 623526,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275356,
              "address_id": 623530,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275357,
              "address_id": 623554,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275358,
              "address_id": 623558,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275359,
              "address_id": 623562,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275360,
              "address_id": 623553,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275361,
              "address_id": 623528,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275362,
              "address_id": 623535,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275364,
              "address_id": 623550,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275365,
              "address_id": 623533,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275366,
              "address_id": 623569,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275368,
              "address_id": 623568,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275370,
              "address_id": 623572,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275371,
              "address_id": 623525,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275372,
              "address_id": 623549,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518669,
              "address_id": 644042,
              "homekeeper_id": 231050,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 178,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231051,
      "main_team_id": 228047,
      "name": "Lowe's Appliance Warranty",
      "first_name": "Lowe's",
      "last_name": "Warranty",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Lowe's Warranty's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275373,
              "address_id": 623057,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275374,
              "address_id": 623566,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275375,
              "address_id": 623547,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275377,
              "address_id": 623539,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275378,
              "address_id": 623559,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275379,
              "address_id": 623563,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275380,
              "address_id": 623531,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275381,
              "address_id": 623534,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275382,
              "address_id": 623561,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275383,
              "address_id": 623548,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275384,
              "address_id": 623551,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275387,
              "address_id": 623541,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275388,
              "address_id": 623529,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275389,
              "address_id": 623567,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275390,
              "address_id": 623536,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275391,
              "address_id": 623532,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275392,
              "address_id": 623537,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275393,
              "address_id": 623556,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275394,
              "address_id": 623538,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275395,
              "address_id": 623555,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275396,
              "address_id": 623527,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275398,
              "address_id": 623545,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275399,
              "address_id": 623544,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275400,
              "address_id": 623560,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275402,
              "address_id": 623552,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275403,
              "address_id": 623564,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275404,
              "address_id": 623526,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275405,
              "address_id": 623530,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275406,
              "address_id": 623554,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275407,
              "address_id": 623558,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275408,
              "address_id": 623562,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275409,
              "address_id": 623553,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275410,
              "address_id": 623528,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275411,
              "address_id": 623535,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275413,
              "address_id": 623550,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275414,
              "address_id": 623533,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275415,
              "address_id": 623569,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275417,
              "address_id": 623568,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275419,
              "address_id": 623572,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275420,
              "address_id": 623525,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275421,
              "address_id": 623549,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518673,
              "address_id": 644042,
              "homekeeper_id": 231051,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231052,
      "main_team_id": 228048,
      "name": "J\u0026M Appliance Repair",
      "first_name": "J\u0026M",
      "last_name": "Repair",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "J\u0026M Repair's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275422,
              "address_id": 623057,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275423,
              "address_id": 623566,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275424,
              "address_id": 623547,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275426,
              "address_id": 623539,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275427,
              "address_id": 623559,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275428,
              "address_id": 623563,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275429,
              "address_id": 623531,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275430,
              "address_id": 623534,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275431,
              "address_id": 623561,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275432,
              "address_id": 623548,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275433,
              "address_id": 623551,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275436,
              "address_id": 623541,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275437,
              "address_id": 623529,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275438,
              "address_id": 623567,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275439,
              "address_id": 623536,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275440,
              "address_id": 623532,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275441,
              "address_id": 623537,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275442,
              "address_id": 623556,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275443,
              "address_id": 623538,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275444,
              "address_id": 623555,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275445,
              "address_id": 623527,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275447,
              "address_id": 623545,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275448,
              "address_id": 623544,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275449,
              "address_id": 623560,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275451,
              "address_id": 623552,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275452,
              "address_id": 623564,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275453,
              "address_id": 623526,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275454,
              "address_id": 623530,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275455,
              "address_id": 623554,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275456,
              "address_id": 623558,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275457,
              "address_id": 623562,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275458,
              "address_id": 623553,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275459,
              "address_id": 623528,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275460,
              "address_id": 623535,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275462,
              "address_id": 623550,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275463,
              "address_id": 623533,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275464,
              "address_id": 623569,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275466,
              "address_id": 623568,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275468,
              "address_id": 623572,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275469,
              "address_id": 623525,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275470,
              "address_id": 623549,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518674,
              "address_id": 644042,
              "homekeeper_id": 231052,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 211,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231053,
      "main_team_id": 228049,
      "name": "Tree Service Solutions",
      "first_name": "Tree",
      "last_name": "Solutions",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Tree Solutions's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275471,
              "address_id": 623057,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275472,
              "address_id": 623566,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275473,
              "address_id": 623547,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275475,
              "address_id": 623539,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275476,
              "address_id": 623559,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275477,
              "address_id": 623563,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275478,
              "address_id": 623531,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275479,
              "address_id": 623534,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275480,
              "address_id": 623561,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275481,
              "address_id": 623548,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275482,
              "address_id": 623551,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275485,
              "address_id": 623541,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275486,
              "address_id": 623529,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275487,
              "address_id": 623567,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275488,
              "address_id": 623536,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275489,
              "address_id": 623532,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275490,
              "address_id": 623537,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275491,
              "address_id": 623556,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275492,
              "address_id": 623538,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275493,
              "address_id": 623555,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275494,
              "address_id": 623527,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275496,
              "address_id": 623545,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275497,
              "address_id": 623544,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275498,
              "address_id": 623560,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275500,
              "address_id": 623552,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275501,
              "address_id": 623564,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275502,
              "address_id": 623526,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275503,
              "address_id": 623530,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275504,
              "address_id": 623554,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275505,
              "address_id": 623558,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275506,
              "address_id": 623562,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275507,
              "address_id": 623553,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275508,
              "address_id": 623528,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275509,
              "address_id": 623535,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275511,
              "address_id": 623550,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275512,
              "address_id": 623533,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275513,
              "address_id": 623569,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275515,
              "address_id": 623568,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275517,
              "address_id": 623572,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275518,
              "address_id": 623525,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275519,
              "address_id": 623549,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518639,
              "address_id": 644042,
              "homekeeper_id": 231053,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 41,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231054,
      "main_team_id": 228050,
      "name": "Raysco Carpet Cleaning",
      "first_name": "Raysco",
      "last_name": "Cleaning",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Raysco Cleaning's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275539,
              "address_id": 623057,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275540,
              "address_id": 623566,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275541,
              "address_id": 623547,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275543,
              "address_id": 623539,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275544,
              "address_id": 623559,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275545,
              "address_id": 623563,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275546,
              "address_id": 623531,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275547,
              "address_id": 623534,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275548,
              "address_id": 623561,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275549,
              "address_id": 623548,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275550,
              "address_id": 623551,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275553,
              "address_id": 623541,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275554,
              "address_id": 623529,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275555,
              "address_id": 623567,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275556,
              "address_id": 623536,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275557,
              "address_id": 623532,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275558,
              "address_id": 623537,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275559,
              "address_id": 623556,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275560,
              "address_id": 623538,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275561,
              "address_id": 623555,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275562,
              "address_id": 623527,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275564,
              "address_id": 623545,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275565,
              "address_id": 623544,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275566,
              "address_id": 623560,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275568,
              "address_id": 623552,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275569,
              "address_id": 623564,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275570,
              "address_id": 623526,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275571,
              "address_id": 623530,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275572,
              "address_id": 623554,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275573,
              "address_id": 623558,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275574,
              "address_id": 623562,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275575,
              "address_id": 623553,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275576,
              "address_id": 623528,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275577,
              "address_id": 623535,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275579,
              "address_id": 623550,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275580,
              "address_id": 623533,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275581,
              "address_id": 623569,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275583,
              "address_id": 623568,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275585,
              "address_id": 623572,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275586,
              "address_id": 623525,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275587,
              "address_id": 623549,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518627,
              "address_id": 644042,
              "homekeeper_id": 231054,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 35,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231056,
      "main_team_id": 228052,
      "name": "The Pigeon Man",
      "first_name": "The",
      "last_name": "Man",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "The Man's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275589,
              "address_id": 623057,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275590,
              "address_id": 623566,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275591,
              "address_id": 623547,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275593,
              "address_id": 623539,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275594,
              "address_id": 623559,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275595,
              "address_id": 623563,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275596,
              "address_id": 623531,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275597,
              "address_id": 623534,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275598,
              "address_id": 623561,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275599,
              "address_id": 623548,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275600,
              "address_id": 623551,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275603,
              "address_id": 623541,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275604,
              "address_id": 623529,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275605,
              "address_id": 623567,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275606,
              "address_id": 623536,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275607,
              "address_id": 623532,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275608,
              "address_id": 623537,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275609,
              "address_id": 623556,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275610,
              "address_id": 623538,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275611,
              "address_id": 623555,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275612,
              "address_id": 623527,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275614,
              "address_id": 623545,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275615,
              "address_id": 623544,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275616,
              "address_id": 623560,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275618,
              "address_id": 623552,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275619,
              "address_id": 623564,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275620,
              "address_id": 623526,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275621,
              "address_id": 623530,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275622,
              "address_id": 623554,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275623,
              "address_id": 623558,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275624,
              "address_id": 623562,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275625,
              "address_id": 623553,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275626,
              "address_id": 623528,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275627,
              "address_id": 623535,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275629,
              "address_id": 623550,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275630,
              "address_id": 623533,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275631,
              "address_id": 623569,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275633,
              "address_id": 623568,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275635,
              "address_id": 623572,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275636,
              "address_id": 623525,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275637,
              "address_id": 623549,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518634,
              "address_id": 644042,
              "homekeeper_id": 231056,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231057,
      "main_team_id": 228053,
      "name": "R And C Pest Control",
      "first_name": "R",
      "last_name": "Control",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "R Control's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275638,
              "address_id": 623057,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275639,
              "address_id": 623566,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275640,
              "address_id": 623547,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275642,
              "address_id": 623539,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275643,
              "address_id": 623559,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275644,
              "address_id": 623563,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275645,
              "address_id": 623531,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275646,
              "address_id": 623534,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275647,
              "address_id": 623561,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275648,
              "address_id": 623548,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275649,
              "address_id": 623551,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275652,
              "address_id": 623541,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275653,
              "address_id": 623529,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275654,
              "address_id": 623567,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275655,
              "address_id": 623536,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275656,
              "address_id": 623532,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275657,
              "address_id": 623537,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275658,
              "address_id": 623556,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275659,
              "address_id": 623538,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275660,
              "address_id": 623555,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275661,
              "address_id": 623527,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275663,
              "address_id": 623545,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275664,
              "address_id": 623544,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275665,
              "address_id": 623560,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275667,
              "address_id": 623552,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275668,
              "address_id": 623564,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275669,
              "address_id": 623526,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275670,
              "address_id": 623530,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275671,
              "address_id": 623554,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275672,
              "address_id": 623558,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275673,
              "address_id": 623562,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275674,
              "address_id": 623553,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275675,
              "address_id": 623528,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275676,
              "address_id": 623535,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275678,
              "address_id": 623550,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275679,
              "address_id": 623533,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275680,
              "address_id": 623569,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275682,
              "address_id": 623568,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275684,
              "address_id": 623572,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275685,
              "address_id": 623525,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275686,
              "address_id": 623549,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518635,
              "address_id": 644042,
              "homekeeper_id": 231057,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 39,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231058,
      "main_team_id": 228054,
      "name": "Battleborn Landscaping",
      "first_name": "Battleborn",
      "last_name": "Landscaping",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Battleborn Landscaping's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3275687,
              "address_id": 623057,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275688,
              "address_id": 623566,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275689,
              "address_id": 623547,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275691,
              "address_id": 623539,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275692,
              "address_id": 623559,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275693,
              "address_id": 623563,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275694,
              "address_id": 623531,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275695,
              "address_id": 623534,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275696,
              "address_id": 623561,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275697,
              "address_id": 623548,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275698,
              "address_id": 623551,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275701,
              "address_id": 623541,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275702,
              "address_id": 623529,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275703,
              "address_id": 623567,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275704,
              "address_id": 623536,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275705,
              "address_id": 623532,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275706,
              "address_id": 623537,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275707,
              "address_id": 623556,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275708,
              "address_id": 623538,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275709,
              "address_id": 623555,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275710,
              "address_id": 623527,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275712,
              "address_id": 623545,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275713,
              "address_id": 623544,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275714,
              "address_id": 623560,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275716,
              "address_id": 623552,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275717,
              "address_id": 623564,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275718,
              "address_id": 623526,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275719,
              "address_id": 623530,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275720,
              "address_id": 623554,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275721,
              "address_id": 623558,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275722,
              "address_id": 623562,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275723,
              "address_id": 623553,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275724,
              "address_id": 623528,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275725,
              "address_id": 623535,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275727,
              "address_id": 623550,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275728,
              "address_id": 623533,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275729,
              "address_id": 623569,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275731,
              "address_id": 623568,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275733,
              "address_id": 623572,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275734,
              "address_id": 623525,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3275735,
              "address_id": 623549,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518631,
              "address_id": 644042,
              "homekeeper_id": 231058,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 37,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231754,
      "main_team_id": 228750,
      "name": "Rubi Gordan",
      "first_name": "Rubi",
      "last_name": "Gordan",
      "state": "pending",
      "gender": "",
      "is_private": true,
      "team_name": "Rubi Gordan's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3291952,
              "address_id": 623057,
              "homekeeper_id": 231754,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3562188,
              "address_id": 623553,
              "homekeeper_id": 231754,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231755,
      "main_team_id": 228751,
      "name": "Katie Reno’s Biggest Little Cleaning",
      "first_name": "Katie",
      "last_name": "Cleaning",
      "state": "pending",
      "gender": "",
      "is_private": true,
      "team_name": "Reno’s Company's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371374,
              "address_id": 623525,
              "homekeeper_id": 231755,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231756,
      "main_team_id": 228752,
      "name": "Crystal Marcontell",
      "first_name": "Crystal",
      "last_name": "Marcontell",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Reno’s Company's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371375,
              "address_id": 623525,
              "homekeeper_id": 231756,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231982,
      "main_team_id": 228978,
      "name": "Sheena Achurra",
      "first_name": "Sheena",
      "last_name": "Achurra",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Sheena Sheng's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3534941,
              "address_id": 623553,
              "homekeeper_id": 231982,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 231984,
      "main_team_id": 228980,
      "name": "Alyssa Sanborn",
      "first_name": "Alyssa",
      "last_name": "Sanborn",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Alyssa Crazy Clean Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371373,
              "address_id": 623525,
              "homekeeper_id": 231984,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534940,
              "address_id": 623525,
              "homekeeper_id": 231984,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2146945,
          "date": "2025-03-30",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2136105,
          "date": "2025-03-15",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 233460,
      "main_team_id": 230456,
      "name": "Vince Salviano",
      "first_name": "Vince",
      "last_name": "Salviano",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Vince Salviano's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3330702,
              "address_id": 623559,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330703,
              "address_id": 623561,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330704,
              "address_id": 623550,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330705,
              "address_id": 623556,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330706,
              "address_id": 623552,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330708,
              "address_id": 623547,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330709,
              "address_id": 623563,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330710,
              "address_id": 623551,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330712,
              "address_id": 623529,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330713,
              "address_id": 623555,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330714,
              "address_id": 623527,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330715,
              "address_id": 623545,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330716,
              "address_id": 623560,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330718,
              "address_id": 623558,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330719,
              "address_id": 623548,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330720,
              "address_id": 623564,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330721,
              "address_id": 623562,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330724,
              "address_id": 623526,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330725,
              "address_id": 623539,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330726,
              "address_id": 623537,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330727,
              "address_id": 623532,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330728,
              "address_id": 623568,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330730,
              "address_id": 623535,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330732,
              "address_id": 623528,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330733,
              "address_id": 623572,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330736,
              "address_id": 623531,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330737,
              "address_id": 623534,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330738,
              "address_id": 623566,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330739,
              "address_id": 623536,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330740,
              "address_id": 623541,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330741,
              "address_id": 623567,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330742,
              "address_id": 623538,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330744,
              "address_id": 623530,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330745,
              "address_id": 623554,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330746,
              "address_id": 623533,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330747,
              "address_id": 623569,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330750,
              "address_id": 624945,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330751,
              "address_id": 623057,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330752,
              "address_id": 627355,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330753,
              "address_id": 627356,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330754,
              "address_id": 627354,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3330755,
              "address_id": 627213,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3385243,
              "address_id": 623549,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3518645,
              "address_id": 644042,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520965,
              "address_id": 623525,
              "homekeeper_id": 233460,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521482,
              "address_id": 623553,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527715,
              "address_id": 623544,
              "homekeeper_id": 233460,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3528791,
              "address_id": 633887,
              "homekeeper_id": 233460,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 234283,
      "main_team_id": 231274,
      "name": "Erik Pedersen",
      "first_name": "Erik",
      "last_name": "Pedersen",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Erik Pedersen's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3349006,
              "address_id": 627355,
              "homekeeper_id": 234283,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3349011,
              "address_id": 627356,
              "homekeeper_id": 234283,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3429472,
              "address_id": 627355,
              "homekeeper_id": 234283,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3429473,
              "address_id": 624945,
              "homekeeper_id": 234283,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3429474,
              "address_id": 627354,
              "homekeeper_id": 234283,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3429475,
              "address_id": 627356,
              "homekeeper_id": 234283,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3349001,
              "address_id": 627354,
              "homekeeper_id": 234283,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3398393,
              "address_id": 631197,
              "homekeeper_id": 234283,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3348997,
              "address_id": 624945,
              "homekeeper_id": 234283,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3534939,
              "address_id": 631197,
              "homekeeper_id": 234283,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2119372,
          "date": "2025-03-31",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 624945,
              "address1": "102 Adams Street",
              "address2": null,
              "city": "Hoboken",
              "add_state": "NJ"
          }
      },
      "last_job": {
          "id": 2124113,
          "date": "2025-02-03",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 627355,
              "address1": "1st Street",
              "address2": null,
              "city": "Hoboken",
              "add_state": "NJ"
          }
      }
  },
  {
      "id": 234294,
      "main_team_id": 231285,
      "name": "Aizhan Azhibraimova",
      "first_name": "Aizhan",
      "last_name": "Azhibraimova",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Auzhan A's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3348998,
              "address_id": 624945,
              "homekeeper_id": 234294,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3349002,
              "address_id": 627354,
              "homekeeper_id": 234294,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3349007,
              "address_id": 627355,
              "homekeeper_id": 234294,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3349012,
              "address_id": 627356,
              "homekeeper_id": 234294,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 234295,
      "main_team_id": 231286,
      "name": "Danish Shaik",
      "first_name": "Danish",
      "last_name": "Shaik",
      "state": "active",
      "gender": "male",
      "is_private": true,
      "team_name": "Danish Danish's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3371075,
              "address_id": 624945,
              "homekeeper_id": 234295,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3349003,
              "address_id": 627354,
              "homekeeper_id": 234295,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3347261,
              "address_id": 627354,
              "homekeeper_id": 234295,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538938,
              "address_id": 627356,
              "homekeeper_id": 234295,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538937,
              "address_id": 627355,
              "homekeeper_id": 234295,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3538943,
              "address_id": 631197,
              "homekeeper_id": 234295,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3518659,
              "address_id": 644042,
              "homekeeper_id": 234295,
              "priority": 3,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2130885,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 624945,
              "address1": "102 Adams Street",
              "address2": null,
              "city": "Hoboken",
              "add_state": "NJ"
          }
      },
      "last_job": {
          "id": 2145740,
          "date": "2025-03-26",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 631197,
              "address1": "1st Street #3",
              "address2": null,
              "city": "Hoboken",
              "add_state": "NJ"
          }
      }
  },
  {
      "id": 234563,
      "main_team_id": 231554,
      "name": "Ryan Donkel Desert Blue Pool Service",
      "first_name": "Ryan",
      "last_name": "Service",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Ryan Donkel's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3351309,
              "address_id": 623547,
              "homekeeper_id": 234563,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556905,
              "address_id": 623555,
              "homekeeper_id": 234563,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 235874,
      "main_team_id": 232864,
      "name": "Anna La Monte",
      "first_name": "Anna",
      "last_name": "Monte",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Anna Monte's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3538851,
              "address_id": 623525,
              "homekeeper_id": 235874,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 141,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          }
      ],
      "next_job": {
          "id": 2146052,
          "date": "2025-04-02",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2144518,
          "date": "2025-03-24",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 244674,
      "main_team_id": 241653,
      "name": "Araceli Andrade",
      "first_name": "Araceli",
      "last_name": "Andrade",
      "state": "active",
      "gender": "female",
      "is_private": true,
      "team_name": "Araceli Clean's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3510806,
              "address_id": 623525,
              "homekeeper_id": 244674,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 277,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3510807,
              "address_id": 623525,
              "homekeeper_id": 244674,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 244720,
      "main_team_id": 241699,
      "name": "Ignacio Ortez",
      "first_name": "Ignacio",
      "last_name": "Ortez",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Jose's Service's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3556032,
              "address_id": 623560,
              "homekeeper_id": 244720,
              "priority": 2,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3556044,
              "address_id": 623562,
              "homekeeper_id": 244720,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3556297,
              "address_id": 623530,
              "homekeeper_id": 244720,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3556897,
              "address_id": 623555,
              "homekeeper_id": 244720,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3511316,
              "address_id": 623564,
              "homekeeper_id": 244720,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546038,
              "address_id": 623550,
              "homekeeper_id": 244720,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3566236,
              "address_id": 623539,
              "homekeeper_id": 244720,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 34,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2123341,
          "date": "2025-03-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623560,
              "address1": "4029 Waldorf Court",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2123295,
          "date": "2025-03-25",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623564,
              "address1": "4291 East Rochelle Avenue",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 244940,
      "main_team_id": 241919,
      "name": "Blueline Enterprises",
      "first_name": "Blueline",
      "last_name": "Enterprises",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Blue Handyman's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3516661,
              "address_id": 623552,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516662,
              "address_id": 623530,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516663,
              "address_id": 623554,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516664,
              "address_id": 623550,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516665,
              "address_id": 623545,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516666,
              "address_id": 623560,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516669,
              "address_id": 623564,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516670,
              "address_id": 623526,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516671,
              "address_id": 623555,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516672,
              "address_id": 623551,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516673,
              "address_id": 623549,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516674,
              "address_id": 623562,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516675,
              "address_id": 623563,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516676,
              "address_id": 627213,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516677,
              "address_id": 623535,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516678,
              "address_id": 629247,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516679,
              "address_id": 623533,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516680,
              "address_id": 623538,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516681,
              "address_id": 623566,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516682,
              "address_id": 623528,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516683,
              "address_id": 623568,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516684,
              "address_id": 623531,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516686,
              "address_id": 623532,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516687,
              "address_id": 623539,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516688,
              "address_id": 623537,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516689,
              "address_id": 623569,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516690,
              "address_id": 632943,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3516691,
              "address_id": 633887,
              "homekeeper_id": 244940,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520966,
              "address_id": 623525,
              "homekeeper_id": 244940,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3522790,
              "address_id": 623525,
              "homekeeper_id": 244940,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527717,
              "address_id": 623544,
              "homekeeper_id": 244940,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2136160,
          "date": "2025-04-01",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2146505,
          "date": "2025-03-26",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623525,
              "address1": "Terracina Way",
              "address2": null,
              "city": "Reno",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 244948,
      "main_team_id": 241927,
      "name": "Laki Siaki",
      "first_name": "Laki",
      "last_name": "Siaki",
      "state": "active",
      "gender": "",
      "is_private": true,
      "team_name": "Laki Laki's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3534220,
              "address_id": 644042,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3540367,
              "address_id": 650608,
              "homekeeper_id": 244948,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3522723,
              "address_id": 623548,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3522891,
              "address_id": 623544,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3522913,
              "address_id": 623547,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3523320,
              "address_id": 623529,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3523359,
              "address_id": 623559,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3523355,
              "address_id": 623526,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527356,
              "address_id": 623530,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527375,
              "address_id": 623558,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527382,
              "address_id": 623560,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527388,
              "address_id": 623563,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527405,
              "address_id": 629247,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527450,
              "address_id": 623564,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527416,
              "address_id": 623545,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          },
          {
              "id": 3527432,
              "address_id": 623556,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527439,
              "address_id": 623562,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527443,
              "address_id": 623551,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527456,
              "address_id": 623555,
              "homekeeper_id": 244948,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 108,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2146977,
          "date": "2025-03-27",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623564,
              "address1": "4291 East Rochelle Avenue",
              "address2": null,
              "city": "Las Vegas",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2146508,
          "date": "2025-03-26",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623556,
              "address1": "3149 Belvedere Drive",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 245263,
      "main_team_id": 242233,
      "name": "Assign Pro",
      "first_name": "Assign",
      "last_name": "Pro",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Assign Pro's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3517172,
              "address_id": 623559,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517173,
              "address_id": 623561,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517174,
              "address_id": 623550,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517175,
              "address_id": 623556,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517176,
              "address_id": 623552,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517177,
              "address_id": 623549,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517178,
              "address_id": 623547,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517179,
              "address_id": 623563,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517180,
              "address_id": 623551,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517182,
              "address_id": 623529,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517183,
              "address_id": 623555,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517184,
              "address_id": 623527,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517185,
              "address_id": 623545,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517186,
              "address_id": 623560,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517188,
              "address_id": 623558,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517189,
              "address_id": 623548,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517190,
              "address_id": 623564,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517191,
              "address_id": 623562,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517194,
              "address_id": 623526,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517195,
              "address_id": 623539,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517196,
              "address_id": 623537,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517197,
              "address_id": 623532,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517198,
              "address_id": 623568,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517200,
              "address_id": 623535,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517202,
              "address_id": 623528,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517203,
              "address_id": 623572,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517206,
              "address_id": 623531,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517207,
              "address_id": 623534,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517208,
              "address_id": 623566,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517209,
              "address_id": 623536,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517210,
              "address_id": 623541,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517211,
              "address_id": 623567,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517212,
              "address_id": 623538,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517214,
              "address_id": 623530,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517215,
              "address_id": 623554,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517216,
              "address_id": 623533,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517217,
              "address_id": 627355,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517218,
              "address_id": 627356,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517219,
              "address_id": 627213,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517220,
              "address_id": 627354,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517221,
              "address_id": 624945,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517223,
              "address_id": 623569,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517224,
              "address_id": 623057,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517225,
              "address_id": 631197,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517226,
              "address_id": 633887,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517227,
              "address_id": 632943,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517229,
              "address_id": 629247,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517230,
              "address_id": 644042,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3517231,
              "address_id": 642999,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3520967,
              "address_id": 623525,
              "homekeeper_id": 245263,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3521483,
              "address_id": 623553,
              "homekeeper_id": 245263,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3527718,
              "address_id": 623544,
              "homekeeper_id": 245263,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 245310,
      "main_team_id": 242280,
      "name": "Assign Pro 2",
      "first_name": "Assign",
      "last_name": "2",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Assign 2's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3517582,
              "address_id": 623561,
              "homekeeper_id": 245310,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 245891,
      "main_team_id": 242861,
      "name": "Ronnie",
      "first_name": "Ronnie",
      "last_name": "Ronnie",
      "state": "pending",
      "gender": null,
      "is_private": true,
      "team_name": "Ronnie Ronnie's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3523893,
              "address_id": 627355,
              "homekeeper_id": 245891,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523894,
              "address_id": 627356,
              "homekeeper_id": 245891,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523895,
              "address_id": 631197,
              "homekeeper_id": 245891,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523896,
              "address_id": 627354,
              "homekeeper_id": 245891,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3523897,
              "address_id": 624945,
              "homekeeper_id": 245891,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 75,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": null,
      "last_job": null
  },
  {
      "id": 247193,
      "main_team_id": 244162,
      "name": "Thierry Mamalis",
      "first_name": "Thierry",
      "last_name": "Mamalis",
      "state": "active",
      "gender": "male",
      "is_private": true,
      "team_name": "Terry Painting's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3542138,
              "address_id": 623529,
              "homekeeper_id": 247193,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 40,
              "created_by": "homekeeper",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3546930,
              "address_id": 623529,
              "homekeeper_id": 247193,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          },
          {
              "id": 3548047,
              "address_id": 623527,
              "homekeeper_id": 247193,
              "priority": null,
              "has_priority": false,
              "type": "homekeeper",
              "service_type_id": 40,
              "created_by": "customer",
              "ignore_homekeeper_availability": false
          }
      ],
      "next_job": {
          "id": 2124156,
          "date": "2025-03-31",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623529,
              "address1": "2432 Greens Avenue",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      },
      "last_job": {
          "id": 2124155,
          "date": "2025-02-28",
          "start_time": "2000-01-01T11:00:00.000Z",
          "address": {
              "id": 623529,
              "address1": "2432 Greens Avenue",
              "address2": null,
              "city": "Henderson",
              "add_state": "NV"
          }
      }
  },
  {
      "id": 251197,
      "main_team_id": 248159,
      "name": "Peak Perfection",
      "first_name": "Peak",
      "last_name": "Perfection",
      "state": "active",
      "gender": null,
      "is_private": true,
      "team_name": "Ricardo Montilla's Team",
      "address_homekeeper_priorities": [
          {
              "id": 3562187,
              "address_id": 623553,
              "homekeeper_id": 251197,
              "priority": 1,
              "has_priority": true,
              "type": "homekeeper",
              "service_type_id": 1,
              "created_by": "customer",
              "ignore_homekeeper_availability": true
          }
      ],
      "next_job": null,
      "last_job": null
  }
]
import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';

import { AddressModel } from 'src/models/address.model';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'property-scanning.html'
})

export class PropertyScanningPage implements OnInit {

  loaded: boolean;
  errorMessage: string;
  address: AddressModel;
  modalFlow: string;
  mapUrl: any;
  lotCoordinates: any;
  requestReview: any;

  constructor(
    private navCtrl: CustomNavController,
    private genomeMaps: GenomeMaps,
    private util: Util
  ) {}

  @Loading('', true)
  ngOnInit() {
    //TODO add right side panel handling if we undeprecate this page
    this.loaded = false;
    this.modalFlow = 'firstModal';
    this.address = this.navCtrl.getParam('address', true);
    this.requestReview = this.navCtrl.getParam('requestReview');
    this.lotCoordinates = this.navCtrl.getParam('lotCoordinates');
    this.mapUrl = this.navCtrl.getParam('mapUrl');
    this.loaded = true;
  }

  async submit(awsUrl: string = ''){
    let data;
    try {
      if(this.requestReview){
        data = {
          lot_outline_points: this.lotCoordinates,
          house_tour_video_s3_url: awsUrl
        }
        await this.genomeMaps.updateMapRequest(this.requestReview.id, data);
        this.navCtrl.navigateForward(`edit-property/${this.address.id}`);
      }
      else {
        data = {
          address_id: this.address.id,
          house_floor_count: 1,
          satellite_image_s3_url: this.mapUrl,
          lot_outline_points: this.lotCoordinates,
          house_tour_video_s3_url: awsUrl
        }
        await this.genomeMaps.submitMapRequest(data);
        const message = 'Scan Complete! This can take 72 hours or more to analyze, depending on factors like how busy the queue. You will get a notification when it is done.'
        this.util.showSuccess(message, 10000)
        this.navCtrl.navigateForward(`edit-property/${this.address.id}`);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  secondModal(){
    this.modalFlow = 'recordVideo';
  }

  videoRecorded(awsUrl){
    this.submit(awsUrl);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore'
})
export class removeUnderscorePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    return value.replace(/_/g, ' ');
  }
}

import { Component, ViewChild, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { AlertController, IonSlides } from '@ionic/angular';

import { debounceTime } from 'rxjs/operators';
import { Loading } from "../../../shared/components/loading/loading";
import { Logger } from "../../../providers/logger";
import { PhotoNotes } from "../../../providers/photo-notes/photo-notes";
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'photo-note',
  templateUrl: 'photo-note.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./photo-note.scss'],
})
export class PhotoNote implements OnInit {

  @ViewChild(IonSlides) slides: IonSlides;
  @Input() photos: any = [];
  @Input() params: any;
  @Input() type: any;
  @Input() noEdit: boolean;
  @Input() tempPhotos = false;
  loading = true;
  photoNotesForms: any;
  photoCaption: any;

  constructor(
    private alertCtrl: AlertController,
    private formBuilder: UntypedFormBuilder,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private photoNotes: PhotoNotes,
  ) { }

  ngOnInit() {
    this.initPhotoNotesForm();
    this.setAutosave();
    this.loading = false;
  }

  async confirmDeletePhoto(photoIndex) {
    const deleteAlertContent = this.photoNotes.deletePhotoAlert();
    deleteAlertContent.buttons[1].handler = () => this.deletePhoto(photoIndex);

    const alert = await this.alertCtrl.create(deleteAlertContent);
    alert.present();
  }

  @Loading('Removing...')
  async deletePhoto(photoIndex) {
    try {
      if (photoIndex === (this.photos.length - 1)) {
        this.slides.slidePrev();
      }
      if (this.tempPhotos) {
        return this.photos.pop(photoIndex);
      }
      await this.photoNotes.deletePhotoNotes(this.photos[photoIndex].id);
      await this.updatePhotos();
    } catch (error) {
      this.logger.error(error, 'delete-photo-note-error');
      this.photoNotes.handleError(this.navCtrl);
    }
  }

  async updatePhotos() {
    this.photos = await this.photoNotes.getPhotos(this.type, this.params);
    this.initPhotoNotesForm();
    this.setAutosave();
  }

  async takeTodoPhoto() {
    const { roomId, taskId }: any = this.getPhotoMetadata();
    await this.photoNotes.taskPhotoNotes(roomId, taskId);
  }

  async takeAddressPhoto() {
    await this.photoNotes.addressPhotoNote(this.type);
  }

  async takeRoomPhoto() {
    await this.photoNotes.roomPhotoNote(this.params.roomId, '');
  }

  @Loading('Loading...')
  async addPhoto() {
    try {
      if (this.tempPhotos) {
        return await this.addTempPhoto();
      }

      const params = this.getPhotoMetadata();
      await this.photoNotes.addPhotoNote(this.type, params);
      await this.updatePhotos();
      this.goToTakenPhoto();
    } catch (error) {
      if (this.noImageSelected(error)) {
        return;
      }
      this.logger.error(error, 'take-photo-note-error')
      this.photoNotes.handleError(this.navCtrl);
    }
  }

  initPhotoNotesForm() {
    const captions = {};
    this.photos.forEach(photoNote => {
      captions[photoNote.id] = photoNote.photo_note_caption;
    });

    this.photoCaption = this.formBuilder.group(captions);
  }

  initTempPhotosForm() {
    const captions = {};
    this.photos.forEach(photoNote => {
      captions[photoNote.key] = photoNote.photo_note_caption;
    });

    this.photoCaption = this.formBuilder.group(captions);
  }

  setAutosave() {
    this.photos.forEach(photoNote => {
      this.photoCaption.controls[photoNote.id].valueChanges.pipe(debounceTime(500)).subscribe(async value => {
        photoNote.photo_note_caption = value;
        await this.photoNotes.photoCaption(photoNote.id, value);
      });
    });
  }

  goToTakenPhoto() {
    setTimeout(async () => {
      const slidesNumber = await this.slides.length();
      this.slides.slideTo(slidesNumber - 1);
    }, 500);
  }

  @Loading('Loading...')
  async fileChosen(imgData) {
    try {
      if (this.tempPhotos) {
        return await this.addTempPhoto(imgData);
      }

      await this.addPhotoNote();
      await this.updatePhotos();
      this.goToTakenPhoto();
    } catch (error) {
      this.logger.error(error, 'file-picker-error');
      this.photoNotes.handleError(this.navCtrl);
    }
  }

  async addPhotoNote() {
    const params = this.getPhotoMetadata();
    return await this.photoNotes.addPhotoNote(this.type, params);
  }

  getPhotoMetadata() {
    if (this.type === 'task') {
      const roomId = this.params.roomId;
      const taskId = this.params.taskId;

      return { roomId, taskId };
    }

    if (this.type === 'room') {
      return { roomId: this.params.roomId };
    }

    return null;
  }

  addRoomPhoto(roomId) {
    this.photoNotes.roomPhotoNote(roomId, '');
  }

  noImageSelected(error): boolean {
    const message = error && error.err ? error.err : "";
    return message.toLowerCase().contains("no image selected");
  }

  async addTempPhoto(picture = null) {
    const resp = await this.photoNotes.addTempPhoto(picture);
    resp.photo_note_caption = null;
    this.photos.push(resp);
    this.initTempPhotosForm();
    this.goToTakenPhoto();
  }

  async assignTempPhotos() {
    await Promise.all(this.photos.map(async photo => {
      const assignedPhoto = await this.addPhotoNote();

      if (photo.photo_note_caption) {
        await this.photoNotes.photoCaption(assignedPhoto.id, photo.photo_note_caption);
      }
    }));
  }

}

import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

@Component({
  templateUrl: 'compliance.html'
})

export class EmployerCompliancePage implements OnInit {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  loaded: boolean;
  rows: any;
  headers: any;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  ngOnInit() {
    this.loaded = false;
    this.rows = this.buildRows();
    this.headers = this.buildHeaders();
    this.loaded = true;
  }

  buildHeaders() {
    return [
      'Employee',
      'Employee Confirmed Workspace',
      'Cleaner Confirmed Cleaning',
      ' '
    ];
  }

  buildRows() {
    return [
      {
        'Employee': {
          value: 'Ana Johnson',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Brad Smith',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'No',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Anapaum Chari',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Pedro Gomez',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Monika Müller',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Chloë Bernard',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      },
      {
        'Employee': {
          value: 'Sacha Martin',
          action: null
        },
        'Employee Confirmed Workspace': {
          value: 'Yes',
          action: null
        },
        'Cleaner Confirmed Cleaning': {
          value: 'Yes',
          action: null
        },
        ' ': {
          value: 'View',
          action: this.noAction.bind(this)
        }
      }
    ]
  }

  noAction() {

  }
}
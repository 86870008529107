<button mat-icon-button [matMenuTriggerFor]="dropmenu">
  <mat-icon class="open-menu-icon" *ngIf="!icon && !initialsIcon">more_horiz</mat-icon>
  <tidy-initials-icon
    *ngIf="initialsIcon"
    [fullName]="initialsIcon">
  </tidy-initials-icon>
  <tidy-image
    *ngIf="icon"
    [src]="icon"
    style="width: 20px; opacity: 30%">
  </tidy-image>
</button>
<mat-menu #dropmenu="matMenu" overlayPanelClass="my-testing-class">
  <ng-content></ng-content>
</mat-menu>

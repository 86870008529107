import { Injectable } from '@angular/core';

@Injectable()
export class StaticCompanyText {
  staticOfficeText: any = {
    home: {
      company: 'Office',
      other: 'Home'
    },
    homekeeper: {
      company: 'Cleaner',
      other: 'Homekeeper'
    },
    homekeepers: {
      company: 'Cleaners',
      other: 'Homekeepers'
    },
    homeAccess: {
      company: 'Access Notes (lockbox, key, etc.)',
      other: 'Home access notes (lockbox, key, etc.)'
    }
  }

  getVariable(variable) {
    if (!variable) {
      return;
    }
    const accountType = (this.isCompany()) ? 'company' : 'other';
    return this.staticOfficeText[variable][accountType];
  }

  isCompany() {
    return (localStorage.getItem('company') === 'true');
  }
}

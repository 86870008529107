import { Component, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { TidyAbbreviateLastName } from './../../../shared/pipes/tidy-abbr-lastname.pipe';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';
import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { AddressModel } from 'src/models/address.model';
import { ItemNode, FlatNode, ChipFilter, SelectedFilters} from 'src/models/schedule.model';

import { debounceTime } from 'rxjs/operators';

import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';

@Component({
  templateUrl: 'pro-settings.html',
  styleUrls: ['./pro-settings.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProSettingsPage implements OnInit {

  addressNames: any;
  desktopWidthContent: string;
  errorMessage: string;
  pageSize: any;
  pageIndex: any;
  headers = [
    'Select',
    'Pro',
    'Property',
    'Service Name',
    'Default Rate',
    'Autopay Limit',
  ];
  form: UntypedFormGroup;
  didLoadTable: boolean;
  shownRows: any;
  searchForm: UntypedFormGroup;
  selectedFilters: Partial<SelectedFilters>;
  selectedRowsErrorMessage: boolean;
  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  totalRows: number;
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  chips: ChipFilter[] = [];
  filterNamesReferences = {
    Service: 'service',
    Address: 'address',
    'Property Name': 'propertyName',
    Pro: 'pro',
  };
  isLoadingTablePage: boolean = false;
  loaded: boolean;
  pros: any[] = [];
  selectedRowsIds: any[] = [];
  addresses: AddressModel[];
  rows: any;
  rowsToShow: any;
  filters: any = {};
  addressFilter: any;
  addressResponse: any;
  proList = [];
  serviceCategoryItems = [];
  @ViewChild('tidyTable') tidyTable: any;

  constructor(
    private client: Client,
    private fb: FormBuilder,
    private navCtrl: CustomNavController,
    private prosProvider: Pros,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private schedule: Schedule,
    public windowService: WindowService,
    private util: Util
  ) {
    this.form = this.fb.group({
      serviceName: '',
      defaultRate: '',
      autopayLimit: '',
      selectedAddress: '',
      selectedPro: '',
      selectedServiceCategory: '',
    });
    this.searchForm = this.fb.group({
      search: ['']
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
  }

  async ngOnInit() {
    this.didLoadTable = true
    this.loaded = false;
    this.desktopWidthContent = scrollContentWidth.NORMAL;
    this.selectedFilters = {
      service: [],
      address: [],
      propertyName: [],
      pro: [],
    };
    await this.getPageData();
    this.loaded = true;
    setTimeout(() => {
      this.tidyTable.paginator.length = this.totalRows;
    }, 500);
  }

  async getPageData() {
    this.getTreeData();
    await this.buildAddressNames();
    this.pageSize = 25;
    this.pageIndex = 1;
    const prosPromise = this.prosProvider.getAllPros();
    const addressDataPromise = this.getAddressData();
    const rowsPromise = this.buildRows();
    const categoriesPromise = this.prosProvider.getServiceCategoriesOrFetch();
    const [pros, categories] = await Promise.all([prosPromise, categoriesPromise, addressDataPromise, rowsPromise]);
    this.serviceCategoryItems = this.getServiceCategoryItems(categories);
    this.proList = this.parseProList(pros);
  }

  parseProList(pros: any[]): any[] {
    return pros.map((pro) => {
      return {
        value: pro.id,
        viewValue: new TidyAbbreviateLastName().transform(pro.name),
        teamId: pro?.main_team_id,
      };
    });
  }

  getServiceCategoryItems(serviceCategories) {
    const array: any = [];
    serviceCategories.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.id
      });
    });
    return array;
  }

  updateSearch({ search }) {
    if (search.length < 2 || search == '') {
      return this.shownRows = this.rows;
    }
    const term = search.toLowerCase();
    this.shownRows = this.rows.filter((item) => {
      const isMatch = item['Pro'].value.toLowerCase().includes(term) ||
      item['Service Name'].value.toLowerCase().includes(term) ||
      item['Property'].value.toLowerCase().includes(term);
      if (isMatch) {
        return item;
      }
    });
  }

  async buildAddressNames() {
    const addresses = await this.client.getAddresses();
    this.addressNames = {};
    addresses.map((address) => {
      this.addressNames[address.id] = address.address_name ? address.address_name : (address.address1 + (address.address2 ? ', ' + address.address2 : ''))
    })
  }

  async buildRows(showLoadingSpinner = true) {
    let loading = null;
    if (this.loaded && showLoadingSpinner) {
      loading = await this.util.showLoading();
    }
    const response = await this.prosProvider.getAllProsSettings(this.pageSize, this.pageIndex, this.filters);
    this.totalRows = Number(response.totalRecords) || 100;
    if (this.tidyTable) {
      setTimeout(() => {
        this.tidyTable.paginator.length = this.totalRows;
        this.tidyTable.paginator.pageIndex = this.pageIndex - 1;
      }, 500);
    }
    let proNames = await this.storage.retrieve('proNames');
    let proIds = await this.storage.retrieve('proIds');
    if (!proNames || proNames?.['undefined'] === undefined) {
      proNames = null;
    }
    if (!proIds || proIds?.['undefined'] === undefined) {
      proIds = null;
    }
    if (!proNames || !proIds) {
      proNames = proNames || {};
      proIds = proIds || {};
      const allPros = await this.prosProvider.getAllPros();
      allPros.map((pro) => {
        proIds[pro?.main_team_id] = pro?.id;
        proNames[pro?.main_team_id] = pro?.name ? new TidyAbbreviateLastName().transform(pro?.name) : '';
      });
      this.storage.save('proNames', proNames);
      this.storage.save('proIds', proIds);
    }
    if (showLoadingSpinner) {
      this.rows = [];
    } else {
      this.rows = [...this.rows];
    }
    response.body.map((item) => {
      const teamId = item.key.split(".")[0];
      this.rows.push({
        'Select': {
          value: false,
          action: () => { },
        },
        'Pro': {
          value: proNames[teamId],
          action: this.goToProPage.bind(this, proIds[teamId])
        },
        'Property': {
          value: this.addressNames[item.address_id],
          action: this.goToEditPropertyPage.bind(this, item.address_id)
        },
        'Service Name': {
          value: item.name,
          action: null,
        },
        'Default Rate': {
          value: '$' + item.prices.base_price / 100,
          action: null,
        },
        'Autopay Limit': {
          value: '$' + item.auto_pay_limit / 100,
          action: null,
        },
        'id': {
          value: item.id,
          action: null,
        }
      });
    });
    this.shownRows = this.rows;
    this.selectedRowsIds = [];
    loading?.dismiss();
  }

  sortChanged(sort) {
    this.rows.sort((a, b) => {
      const valueA = this.parseRowNumberValue(a[sort.active].value);
      const valueB = this.parseRowNumberValue(b[sort.active].value);

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sort.direction === 'asc' || sort.direction === '' ? valueA - valueB : valueB - valueA;
      } else {
        const strA = String(valueA).toLowerCase();
        const strB = String(valueB).toLowerCase();
        if (strA < strB) return sort.direction === 'asc' || sort.direction === '' ? -1 : 1;
        if (strA > strB) return sort.direction === 'asc' || sort.direction === '' ? 1 : -1;
        return 0;
      }
    });
    this.shownRows = this.rows;
  }

  parseRowNumberValue(value: string): string | number {
    if (value.includes('$')) {
      const numberValue = Number(value.replace(/\$|\.|\s/g, ''));
      return isNaN(numberValue) ? value : numberValue;
    }
    return value;
  }

  goToProPage(proId) {
    localStorage.setItem('myProBackPage', 'pro-settings');
    this.goToPage(`my-pro/${proId}`, {proId: proId}, MyProPage);
  }

  goToEditPropertyPage(addressId) {
    localStorage.setItem('editPropertyBackPage', 'pro-settings');
    this.navCtrl.navigateForward(`edit-property/${addressId}`)
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }

  async saveSettings() {
    this.errorMessage = '';
    this.selectedRowsErrorMessage = false;
    if (this.selectedRowsIds.length == 0) {
      return this.selectedRowsErrorMessage = true;
    }
    try {
      if (this.form?.value.serviceName !== '') {
        const payload = {
          ids: this.selectedRowsIds,
          name: this.form?.value.serviceName
        }
        await this.prosProvider.batchUpdateProSettings(payload);
      }
      if (this.form.value.defaultRate !== '') {
        const payload = {
          ids: this.selectedRowsIds,
          price: Number(this.form.value.defaultRate.replace(/\$|\.|\s/g, ''))
        }
        await this.prosProvider.batchUpdateProSettings(payload);
      }
      if (this.form.value.autopayLimit !== '') {
        const payload = {
          ids: this.selectedRowsIds,
          auto_pay_limit: Number(this.form?.value.autopayLimit.replace(/\$|\.|\s/g, ''))
        }
        await this.prosProvider.batchUpdateProSettings(payload);
      }
      const message = 'Success! ' + this.selectedRowsIds.length + ' service' + (this.selectedRowsIds.length == 1 ? '' : 's') + ' updated.'
      this.util.showSuccess(message);
      this.selectedRowsIds = [];
      this.buildRows();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getTreeData(): void {
    const treeData = [
      {
        name: 'Service',
        children: this.getServiceTree(),
      },
      {
        name: 'Address',
        children: this.getAddressTree('address1'),
      },
      {
        name: 'Property Name',
        children: this.getAddressTree('address_name'),
      },
      {
        name: 'Pro',
        children: this.getProsTree(this.pros),
      }
    ];
    treeData.forEach((parent, index) => {
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    const filteredTreeData = treeData.filter((parent) => parent?.children?.length > 0);
    this.dataSource.data = filteredTreeData;
  }

  getServiceTree(): ItemNode[] {
    return Object.keys(this.schedule.categoriesImages).map((key) => {
      return {
        name: key.replace(/_/g, ' '),
        value: key,
        image: this.schedule.categoriesImages[key],
        checked: false,
      };
    });
  }

  getAddressTree(key: string): ItemNode[] {
    return this.addresses
      ?.map((address) => {
        return {
          name: address[key],
          value: address[key],
          checked: false,
        };
      })
      .filter((address) => address?.name);
  }

  getProsTree(pros: any[]): ItemNode[] {
    return pros
      .map((pro) => {
        return {
          name: pro?.first_name + ' ' + pro?.last_name,
          value: pro?.id,
          checked: false,
        };
      })
      .filter((pro) => pro?.name);
  }

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = this.dataSource.data[
      node.data.parentId
    ].children.find((child) => child.name === node.name)?.value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip, foundParentName);
    }
  }

  addChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName].push(chip?.value);
    this.chips.push(chip);
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ].includes(child?.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter, parentName: string): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip?.value);
    this.chips = this.chips.filter(
      (item) => item.parentName !== parentName && item?.value !== chip?.value
    );
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters[matchedParentName].includes(foundNodeValue);
  }

  selectedRowsChange(selectedRows) {
    selectedRows.forEach((row) => {
      if (!this.selectedRowsIds.includes(row.id?.value) && row.id?.value) {
        this.selectedRowsIds.push(row.id?.value);
      }
    });
  }

  unselectedRowsChange(unselectedRows) {
    this.selectedRowsIds = this.selectedRowsIds.filter(id =>
      !unselectedRows.some(row => row.id?.value === id)
    );
  }

  async pageChange(page) {
    if (this.isLoadingTablePage) {
      return;
    }
    if (this.tidyTable) {
      this.tidyTable.isLoading = true;
    }
    this.isLoadingTablePage = true;
    this.pageIndex += 1;
    try {
      await this.buildRows(false);
    } finally {
      if (this.tidyTable) {
        this.tidyTable.isLoading = false;
      }
      this.isLoadingTablePage = false;
    }
  }

  goBack() {
    this.navCtrl.navigateBack('my-pros');
  }

  onFilterValueChange(event: number[] | string, type: string): void {
    try {
      this.pageIndex = 1;
      if (type === 'pro') {
        const proIds = event as number[];
        const foundPros = this.proList.filter((pro) => proIds.includes(pro.value));
        this.filters['homekeeper_ids'] = foundPros.length > 0 ? foundPros.map((pro) => pro.value) : null;
      }
      if (type === 'serviceCategory') {
        const serviceCategoryIds = event as number[];
        this.filters['service_category_ids'] = serviceCategoryIds.length > 0 ? serviceCategoryIds : null;
      }
      if (type === 'address') {
        const addressIds = event as number[];
        this.filters['address_ids'] = addressIds.length > 0 ? addressIds : null;
      }
      this.buildRows();
    } catch (err) {
      console.error(err);
    }
  }

  async getAddressData() {
    this.addressFilter = await this.navCtrl.getParam('addressFilter') || await this.buildAddressFilter();
  }

  async buildAddressFilter() {
    this.addressResponse = await this.client.getAddresses();
    return this.client.parseAddressList(this.addressResponse, false);
  }
}

import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';

import { AddressModel } from 'src/models/address.model';
import { ItemNode, FlatNode, ChipFilter } from 'src/models/schedule.model';

import { snakeCaseToTitleCase } from 'src/shared/util/snake-to-tile-case';

export interface TasksFilter {
  urgency: string[];
  address: string[];
  propertyName: string[];
  type: string[];
}

@Component({
  templateUrl: 'tasks-filter.html',
  styleUrls: ['./tasks-filter.scss'],
  selector: 'tidy-tasks-filter',
  encapsulation: ViewEncapsulation.None,
})
export class TasksFilterComponent implements OnInit, OnChanges {
  isMobileBreakpoint: boolean;
  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    this.isMobileBreakpoint = event.target.innerWidth < 950;
  }
  @Input() showLoadingSpinner: any;
  @Input() addresses: AddressModel[];
  @Input() selectedFilters: TasksFilter;
  @Input() issueReportTypes: any;
  @Output() onSearch = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<{
    selectedFilters: TasksFilter;
    lastSelectedFilter: string;
  }>();
  @Output() onAddIssue = new EventEmitter<void>();
  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  form: UntypedFormGroup;
  chips: ChipFilter[] = [];
  filterNamesReferences = {
    'Urgency': 'urgency',
    'Property': 'propertyName',
    Type: 'type',
  };
  pros: any[] = [];

  constructor(
    private fb: FormBuilder,
    public windowService: WindowService,
    private navCtrl: CustomNavController
  ) {
    this.form = this.fb.group({
      addresses: ['', Validators.required],
      search: [''],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.onSearch.emit(val));
  }

  async ngOnInit(): Promise<void> {
    this.getTreeData();
    this.selectedFilters = {
      urgency: [],
      address: [],
      propertyName: [],
      type: [],
    };
    this.isMobileBreakpoint = window.innerWidth < 950;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addresses) {
      this.getTreeData();
    }
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getTreeData(): void {
    const treeData = [
      {
        name: 'Urgency',
        children: [
          { name: 'Low', value: 'low', checked: false },
          { name: 'Normal', value: 'normal', checked: false },
          { name: 'High', value: 'high', checked: false },
          { name: 'Emergency', value: 'emergency', checked: false },
        ],
      },
      {
        name: 'Type',
        children: this.issueReportTypes.map(type => {
          const name = snakeCaseToTitleCase(type.name)
          return { name, value: type.id.toString(), checked: false };
        }),
      },
      {
        name: 'Property',
        children: this.getAddressTree('address_name', 'address1'),
      },
    ];
    treeData.forEach((parent, index) => {
      if (parent.children?.length === 0) {
        treeData.splice(index, 1);
      }
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    this.dataSource.data = treeData;
  }

  getAddressTree(key: string, keyTwo: string = null): ItemNode[] {
    return this.addresses
      ?.map((address) => {
        return {
          name: address[key] || address[keyTwo],
          value: address.id.toString(),
          checked: false,
        };
      })
      .filter((address) => address?.name);
  }

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = this.dataSource.data[
      node.data.parentId
    ].children.find((child) => child.name === node.name).value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip, foundParentName);
    }
  }

  addChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName].push(chip.value);
    this.chips.push(chip);
    console.log(this.selectedFilters)
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ].includes(child.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter, parentName: string): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip.value);
    this.chips = this.chips.filter(
      (item) => item.parentName !== parentName && item.value !== chip.value
    );
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName ,foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters[matchedParentName].includes(foundNodeValue);
  }

  addIssue(): void {
    this.onAddIssue.emit();
  }

  goToRecurringTasks() {
    this.navCtrl.navigateForward('recurring-tasks');
  }

  goToArchivedTasks() {
    this.navCtrl.navigateForward('archived-tasks');
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="pageTitle"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-card>
      <tidy-row class="extra-bottom-padding" [align]="'center'">
        <tidy-image
        [src]="selectedIntegration?.logo_url"
        style="width:80%; max-height: 200px">
        </tidy-image>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [header]="selectedIntegration?.name">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="selectedIntegration?.short_description">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-top-padding extra-bottom-padding">
        <tidy-divider></tidy-divider>
      </tidy-row>
      <form [formGroup]="form" novalidate action="#">
        <ng-container *ngIf="selectedIntegration?.type !== 'accounting'">
          <tidy-row class="field-top-padding">
           <tidy-textarea
             formControlName="note"
             [form]="form.controls.note"
             [label]="'Instructions for your vendors'"
             [form]="form"
             [icon]="'assets/svg/information-circle-outline.svg'">
           </tidy-textarea>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-input
              formControlName="numberOfProperties"
              [form]="form.controls.numberOfProperties"
              [label]="'Number of properties to use for this'"
              [form]="form"
              [icon]="'assets/svg/home-outline.svg'">
            </tidy-input>
          </tidy-row>
        </ng-container>
        <ng-container *ngIf="selectedIntegration?.type == 'accounting'">
          <tidy-row class="field-top-padding">
           <tidy-textarea
             formControlName="note"
             [form]="form.controls.notesForConcierge"
             [label]="'Any notes for our concierge?'"
             [form]="form"
             [icon]="'assets/svg/information-circle-outline.svg'">
           </tidy-textarea>
          </tidy-row>
        </ng-container>
      </form>
    </tidy-card>

    <tidy-button
      [text]="'Request Integration'"
      [action]="requestIntegration.bind(this)"
      class="primary">
    </tidy-button>

    <ng-container *ngIf="selectedIntegration?.type == 'thermostats'">
      <tidy-row>
        <tidy-text
          [body]="'By integrating with smart thermostats like'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="selectedIntegration?.name + ','">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'you can set up rules for how temperature should work around jobs. Popular examples of uses include:'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Setting min or max temperature during the time of the job. This allows you to ensure your pro is comfortable, while ensuring that energy is conserved.</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Set the temperature after jobs are over, so that you can conserve energy.</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Save energy and maintain comfort by integrating with smart thermostat systems.</li>'">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="selectedIntegration?.type == 'locks'">
      <tidy-row>
        <tidy-text
          [body]="'Integrating TIDY with'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="selectedIntegration?.name">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'allows you to control access to your home with your cleaning and maintenance vendors. Typically, this is used so you can automatically give pros access to the properties only when they need access.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Once integrated, you can:'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Unlock and lock your properties from your interface.</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Automatically create one time passwords to access your property during jobs.</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Automatically unlock properties when pros indicate they have arrived and lock when they leave.</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Use the API to control</li>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<li>Control access to your properties for both cleaning and maintenance.</li>'">
        </tidy-text>
      </tidy-row>
    </ng-container>


  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

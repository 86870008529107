import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../../../custom/http-client';

@Injectable()
export class CleaningsRooms {
  constructor (
    private httpClient: HttpClientCustom
  ) {}

  getRoomsWithTodos (jobId: Number) {
    const url = `/api/v1/customer/cleanings/${jobId}/rooms`;
    return this.httpClient.get(url);
  }
}

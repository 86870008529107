import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { AlertController } from '@ionic/angular';

@Injectable()
export class CreditCard {

  constructor(
    private httpClient: HttpClientCustom,
    private alertCtrl: AlertController
  ) {}

  async confirmRemoveCard(customerId, card) {
    const confirm = await this.alertCtrl.create({
      header: `Remove card?`,
      subHeader: `**** **** **** ${card}`,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'Confirm',
          handler: () => {
            this.removeCard(customerId);
          }
        }
      ]
    });

    confirm.present();
    return confirm.onDidDismiss();
  }

  async removeCard(customerId) {
    try {
      const url = `api/v1/customer/billing/card`
      const data = {
        customer_id: customerId
      }

      await this.httpClient.delete(url, data);
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async errorHandler(error) {
    const errorAlert = await this.alertCtrl.create({
      header: `Error`,
      subHeader: error.message,
      buttons: [
        {
          text: 'Ok',
          handler: () => {}
        }
      ]
    });

    errorAlert.present();
  }
}

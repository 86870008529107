import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class LostItem {
  constructor(private httpClient: HttpClientCustom) {}

  update(jobId: string, homekeeperJobId: string, description: string): Promise<any> {
    const url = `/api/v1/customer/cleanings/${jobId}/homekeeper-jobs/${homekeeperJobId}/lost-item`;

    return this.httpClient.put(url, {description});
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { roomTypeSelect } from 'src/shared/constants/roomTypeSelect';
import { floorSelect } from 'src/shared/constants/floorSelect';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Genome } from 'src/providers/genome/genome';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  templateUrl: 'add-room.html'
})

export class AddRoomPage implements OnInit {

  addressId: number;
  estimatedTimes: any;
  form: UntypedFormGroup;
  floorSelectItems = floorSelect
  loaded = false;
  rooms: any;
  roomTypeSelectItems = roomTypeSelect;
  submitted: boolean;
  errorMessage: string;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      floor: [1, Validators.required],
      category: ['bedroom', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Room');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
    this.loaded = true;
    const floor = this.navCtrl.getParam('floor') || this.dialogParams.floor;
    if (floor) {
      this.form.patchValue({floor: floor});
    }
  }

  async addRoom() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const data = {
      address_rooms : [{
        name: this.form.value.name,
        floor: this.form.value.floor,
        category: this.form.value.category,
        internal_priority: 1,
        order: 1
      }]
    }
    try {
      await this.genome.addRooms(this.addressId, data);
      this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
}

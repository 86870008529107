import {Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ElementRef, AfterViewInit} from '@angular/core';
import translate from "translate";
import { TranslationService } from '../../providers/translation.service';

@Component({
  selector: 'tidy-text',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./text.component.scss'],
  template: `
    <ng-container *ngIf="loaded">
      <span class="divider-line">
        <span class="divider-line__left"></span>
        <span class="divider-line__center"></span>
        <span class="divider-line__right"></span>
      </span>

      <ng-container *ngIf="action.observers.length > 0">
        <a
          *ngIf="rippleEffect && !clickableBody && !clickableHeader"
          mat-button
          class="link action-body-inline"
          href="#"
          [class.extra-padding]="currentText?.length < 30"
          (click)="executeAction($event)">
            {{currentText | parseText:checkLineBreaks:linkify}}
        </a>
        <span
          *ngIf="!rippleEffect && !clickableBody && !clickableHeader"
          class="body-text link"
          [innerHTML]="currentText | parseText:checkLineBreaks:linkify"
          (click)="executeAction($event)"
          class="clickable-body-inline">
        </span>
        <span
          *ngIf="clickableBody"
          [innerHTML]="currentText | parseText:checkLineBreaks:linkify"
          (click)="executeAction($event)"
          class="clickable-body-inline">
        </span>
        <span
          *ngIf="clickableHeader"
          [innerHTML]="currentText | parseText:checkLineBreaks:linkify"
          (click)="executeAction($event)"
          class="clickable-header-inline">
        </span>
      </ng-container>

      <span *ngIf="!h3">
        <ng-container *ngTemplateOutlet="textTemplateRef">
        </ng-container>
      </span>

      <h3 *ngIf="h3">
        <ng-container *ngTemplateOutlet="textTemplateRef">
        </ng-container>
      </h3>

      <ng-template #textTemplateRef>
        <span
          *ngIf="action.observers.length === 0"
          class="body-text"
          [innerHTML]="currentText | parseText:checkLineBreaks:linkify"
          [className]="'body-text'"
          [ngClass]="cssClass"
        >
        </span>
      </ng-template>
    </ng-container>
  `
})
export class TextComponent implements OnInit, AfterViewInit, OnChanges {
  
  @Input() actionBody: string;
  @Input() body: string;
  @Input() helper: string;
  @Input() header: string;
  @Input() title: string;
  @Input() text: string;
  @Input() largeBody: string;
  @Input() smallBody: string;
  @Input() h3: string;
  @Input() rippleEffect = true;
  @Input() translate: boolean = true;
  @Input() checkLineBreaks = false;
  @Input() linkify = false;
  @Input() headerTitle: string;
  @Input() headerButton: string;
  @Input() clickableBody: string;
  @Input() clickableHeader: string;
  @Input() heroBody: string;

  cooldown = false;
  loaded: boolean;

  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private translationService: TranslationService
  ) {}

  currentText = '';
  items = {
    actionBody: 'action-body-inline',
    body: 'body-inline',
    header: 'header-inline',
    largeBody: 'large-body-inline',
    smallBody: 'small-body-inline',
    title: 'title-inline',
    helper: 'helper-inline',
    h3: 'h3-inline',
    headerTitle: 'header-title-inline',
    headerButton: 'header-button-inline',
    clickableBody: 'clickable-body-inline',
    clickableHeader: 'clickable-header-inline',
    heroBody: 'hero-body-inline'
  };
  cssClass: { [key: string]: boolean };

  executeAction(e: Event) {
    e.preventDefault();

    if (this.cooldown) return;
    this.cooldown = true;
    setTimeout(() => this.cooldown = false, 500);
    this.action.emit();
  }

  async ngOnInit() {
    translate.engine = "google";
    translate.key = '2e06be14780df1d2aff3bad979ccd9e25182d888';
    await this.setContent();
  }

  async ngOnChanges() {
    await this.setContent();
  }

  ngAfterViewInit() {
    const htmlElementRef = this.elementRef.nativeElement as HTMLElement;

    const bodyText = htmlElementRef.querySelector('.body-text') as HTMLElement;
    const centerDivider = htmlElementRef.querySelector('.divider-line__center') as HTMLElement;

    if (!(bodyText && centerDivider)) { return; }

    const widthValue = `${bodyText.offsetWidth + 10}px`;
    centerDivider.style.minWidth = widthValue;
    centerDivider.style.width = widthValue;
  }

  async setContent() {
    try {
      this.loaded = false;
      const activeSourceFound = Object.keys(this).filter(i => this.items[i.toString()]);
      if (activeSourceFound.length === 1) {
        const activeSource = activeSourceFound[0];
        this.cssClass =  {[this.items[activeSource]]: true};
        this.currentText = this[activeSource];
        const language = localStorage.getItem('language');
        if (language && language !== 'en') {
          if (this.translationService.translations[this.currentText]) {
            this.currentText =  this.translationService.translations[this.currentText][language];
          }
        }
      }
    } catch(err) {}
    this.loaded = true;
  }

}

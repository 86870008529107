
  <ion-header
    id="primary-header">
    <tidy-wrapper
      [ngClass]="menu.showSecondaryMenu ?
        'primary-menu only-icons-wrapper-width' :
        'primary-menu'
      "
      id="primary-menu"
      class="overflow-container">

      <br>

      <div *ngIf="menuItems" style="margin-top: 10px">
        <tidy-card-button
          [hideArrow]="true"
          *ngFor="let item of menuItems; let i = index"
          [class.first-element]="i === 0"
          class="fix-button-padding"
          [matTooltip]="item.label | translate"
          [matTooltipPosition]="'right'"
          matTooltipClass="menu-item-tooltip right"
          (click)="goToLink(item.target)"
          button
          style="height: 52px; display: flex; justify-content: center; align-items: center;">
          <div style="padding: 10px" class="hover-effect" [class.current-item]="isCurrentItem(item)">
            <tidy-image
              [src]="isCurrentItem(item) ? item.activeIcon : item.icon"
              style="width: 25px; margin-right:10px; display: block; margin-left: auto; margin-right: auto;">
            </tidy-image>
          </div>
        </tidy-card-button>
      </div>
    </tidy-wrapper>
  </ion-header>

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Integrations } from 'src/providers/integrations/integrations';

import { AddressModel } from 'src/models/address.model';
import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { EditAddressLockPage } from '../edit-address-lock/edit-address-lock';

@Component({
  templateUrl: 'remote-lock.html'
})

export class RemoteLockPage extends TimeoutableComponent implements OnInit {

  addresses: AddressModel[];
  addressResponse: AddressModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  remoteAccessAddresses: any;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private integrations: Integrations,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('RemoteLock');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      const integrations = await this.integrations.getIntegrations();
      this.addressResponse = await this.client.getMoreDetailAddresses(true);
      this.remoteAccessAddresses = await this.getParam('remoteAccessAddresses') || await this.integrations.getRemoteAccessAddresses();
      this.addRemoteAccessAddressesToAddresses();
      this.addresses = this.addressResponse;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  addRemoteAccessAddressesToAddresses() {
    this.addressResponse.map((address) => {
      this.remoteAccessAddresses.map((remoteAccessAddress) => {
        if (remoteAccessAddress?.address?.id == address.id) {
          address['remote_access_address'] = remoteAccessAddress;
        }
      });
    });
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.addresses = this.addressResponse;
    }
    const term = search.toLowerCase();
    this.addresses = this.addressResponse.filter((option) => {
      if (option.address1.toLowerCase().indexOf(term) > -1 || option.address_name?.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
  }

  goToEditAddressLockPage(address) {
    const params = {
      address: address,
      remoteAccessAddresses: this.remoteAccessAddresses,
      nextPage: 'remotelock'
    };
    this.rightSidePanelService.navigateTo('edit-address-lock', params, EditAddressLockPage);
  }

  goToLearnMore() {
    this.util.openUrl('https://help.tidy.com/remotelock');
  }

  getInUseDevices(devices) {
    let array = [];
    devices?.map((device) => {
      if (device?.in_use) {
        array.push((device));
      }
    });
    return array;
  }

}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Messages & Updates'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-card-button (action)="goToJobPage()">
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image [src]="categoryImage" style="width: 30px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text [header]="serviceName | titlecase"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(startDate | parseDate: 'ddd') +
                  ' ' +
                  (startDate | parseDate: 'M/D')">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'at'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="startTime | parseDate: 'h:mma'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card class="no-padding" style="padding-bottom: 15px">
        <tidy-row
          class="extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding"
          [alignLastItemRight]="isPrivateJob || !isPastJob"
          [ngClass]="(isPrivateJob || !isPastJob) ? '' : 'vertical-align-center'">
          <tidy-image [src]="'assets/img/account.svg'" class="title-size">
          </tidy-image>
          <tidy-text [header]="proName" [translate]="false"> </tidy-text>
          <tidy-text
            [body]="'Call'"
            class="link"
            (action)="callPro(pro)"
            *ngIf="isPrivateJob || !isPastJob">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="(pro?.text == pro?.first_name && isAllowedToCall) || isPrivateJob">
          <tidy-row
            class="extra-left-padding extra-right-padding"
            style="padding-bottom: 0px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-select
                style="width: 35%; font-size: 8px"
                [form]="form.controls.messageType"
                formControlName="messageType"
                [items]="messageSelectItems"
                [smallText]="true"
                [selectedItem]="selectedMessageType"
                (optionChange)="updateIsInternalComment($event)">
              </tidy-select>
            </form>
          </tidy-row>
          <tidy-row
            class="field-top-padding extra-bottom-padding extra-left-padding extra-right-padding">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="sendMessage.bind(this)"
                [leftIconAction]="sendAttachment.bind(this)"
                [showBlackButtons]="isInternalComment">
              </tidy-textarea>
            </form>
          </tidy-row>
        </ng-container>
        <tidy-row
          *ngIf="messages?.length == 0"
          [align]="'center'"
          class="extra-left-padding extra-right-padding">
          <tidy-text [helper]="'No Messages'"> </tidy-text>
        </tidy-row>
        <ng-container
          *ngFor="let message of messages; let last = last; let index = index">
          <message
            [message]="message"
            [last]="last"
            [showJobLink]="false"
            [proId]="pro?.id">
          </message>
        </ng-container>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <ion-skeleton-text
                animated
                style="width: 35px; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 180px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 160px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>
      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <ion-skeleton-text
                animated
                style="width: 35px; height: 35px; border-radius: 100%;"></ion-skeleton-text>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 180px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 160px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
        <div>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
        </div>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpClientCustom } from 'src/providers/custom/http-client';
import { Addresses } from 'src/providers/customer/addresses';
import { Me } from 'src/providers/me/me';
import { Events } from 'src/providers/events/events';

import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { TidySession } from '../tidy-session/tidy-session';
import { privateProSignUpDataModel } from 'src/models/private-pro-signup';

@Injectable()
export class Auth {

  credentials: any;
  private apiUrl: string = environment.api_secure;

  constructor(
    private httpClient: HttpClientCustom,
    private addresses: Addresses,
    private me: Me,
    private cookieService: CookieService,
    private events: Events,
    private tidySession: TidySession,
    private http: HttpClient
  ) {}

  cleanStorageAndPreserveUuid() {
    const uuid = localStorage.getItem('uuid');
    localStorage.clear();
    if (uuid !== null) {
      localStorage.setItem('uuid', uuid);
    }
  }

  getTidyToken(): Promise<any> {
    const credentials = this.addCredentials({}, 'signUp');
    const url = 'api/v1/token';

    return this.httpClient.post(url, credentials);
  }

  async login(identity, type, privateProSignUpData: privateProSignUpDataModel = null): Promise<any> {
    const credentials = this.addCredentials(identity, type, privateProSignUpData?.hkId);
    const url = 'api/v1/token';
    const authResponse = await this.httpClient.post(url, credentials);
    localStorage.setItem('tidy_token', authResponse.access_token);
    localStorage.setItem('tidy_token_type', authResponse.token_type);
    localStorage.setItem('utm_source', identity.utm_source);
    if (privateProSignUpData && privateProSignUpData.hkId !== null) {
      localStorage.setItem('hkId', privateProSignUpData.hkId);
      localStorage.setItem('proName', privateProSignUpData.proName);
      localStorage.setItem('proTeamName', privateProSignUpData.proTeamName);
    }
  }

  private addCredentials(identity, type, hkId = null): any {
    if (type === 'login' || type === 'signUpPassword') {
      const tidySessionCookie = this.cookieService.get('TidySession');

      return Object.assign({}, identity, {
        client_id: environment.client_id_login,
        client_secret: environment.client_secret_login,
        scope: 'customer',
        grant_type: 'password',
        track_data: {
          cookie: tidySessionCookie,
          referrer:            this.tidySession.firstUrlViewed?.referrer,
          first_url_viewed:    this.tidySession.firstUrlViewed?.value,
          first_url_viewed_at: this.tidySession.firstUrlViewed?.viewedAt,
          onboarding_flow: this.tidySession.onboardingFlow
        },
        homekeeper_id: hkId
      });
    } else if (type === 'signUp') {
      return Object.assign({}, identity, {
        client_id: environment.client_id_signup,
        client_secret: environment.client_secret_signup,
        scope: 'customerflow',
        grant_type: 'client_credentials',
        homekeeper_id: hkId
      });
    }
  }

  setAccountTypeStorage (account) {
    localStorage.removeItem('airbnb');
    localStorage.removeItem('company');
    if (account === 'airbnb' || account === 'rental' || account === 'realtor') {
      localStorage.setItem('airbnb', 'true');
    } else if (account === 'company') {
      localStorage.setItem('company', 'true');
    }
    this.events.publish('updateBackground');
  }

  getAccountType(account) {
    let typeAccount = account;
    if (account !== 'airbnb' && account !== 'company') {
      typeAccount = 'regular';
    }
    return typeAccount;
  }

  getAccountTypeOfFlow(account) {
    const typeAccount = this.getAccountType(account);
    this.setAccountTypeStorage(typeAccount);
    const data = {
      airbnb: {
        titleHeader: 'AirBnB / Rental Cleanings',
        accountType: 'rental'
      },
      regular: {
        titleHeader: 'Sign Up',
        accountType: 'regular'
      },
      company: {
        titleHeader: 'Sign Up',
        accountType: 'company'
      }
    }
    return data[typeAccount];
  }

  async setSession() {
    let result;
    try {
      const customerId = parseInt(localStorage.getItem('customer_id'), 10);
      const data = {
        record_type: 'customer',
        record_id: customerId,
        session_id: this.cookieService.get('TidySession'),
      };
      const url = 'https://us5bejpbfi.execute-api.us-west-2.amazonaws.com/dev/';
      result = await this.httpClient.post(url, data)
    } catch (e) {
      result = {};
    }
    return result;
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('tidy_token') != null && localStorage.getItem('tidy_token_type') != null;
  }

  createPreBooking() {
    localStorage.setItem('preBooking', 'true');
  }

  removePreBooking() {
    localStorage.removeItem('preBooking');
  }

  getPreBooking() {
    return (localStorage.getItem('preBooking') === 'true');
  }

  isPreBooking(page) {
    return this.getPreBooking() || page === 'SignUpPage';
  }

  async autoLogin(params) {
    const {addressId, authToken} = params;
    await this.login({ 'auth_token': authToken }, 'login')
    const address = await this.addresses.getAddress(addressId);
    await this.addresses.setCurrentAddress(address);
    await this.me.load();
    await this.setSession();
  }

  recoverPassword(requestData) {
    return this.getTidyToken().then(response => {
      const res: any = response;

      const headers = new HttpHeaders({
        'Authorization': `bearer ${res.access_token}`,
        'Content-Type': 'application/json'
      });

      return new Promise((resolve, reject) => {
        this.http.post(
          `${this.apiUrl}api/v1/customers/recover-password`,
          JSON.stringify(requestData),
         { headers: headers }
        )
          .subscribe(
          data => resolve(data),
          err => reject(err)
          );
      })
    });
  }

  changePassword(data) {
    return this.getTidyToken().then(response => {
      const res: any = response;

      const headers = new HttpHeaders({
        'Authorization': `bearer ${res.access_token}`,
        'Content-Type': 'application/json'
      });

      return this.http.post(`${this.apiUrl}api/v1/customers/change-password`,
        JSON.stringify(data), { headers: headers })
        .toPromise();
    });
  }

}

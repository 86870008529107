import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'pending-proposal.html'
})

export class PendingProposalPage implements OnInit {

  cameFromClientHub: boolean;
  dialogParams: any;
  loaded: boolean;
  errorMessage: string;
  isRightSideContent: boolean;
  proposal: any;
  proposalFrequency: string;
  proposalUuId: any;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private route: ActivatedRoute,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util,
    private windowService: WindowService
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Pending Proposal');
      }
      this.cameFromClientHub = this.navCtrl.getParam('cameFromClientHub');
      this.proposalUuId = this.route.snapshot.paramMap.get('proposalUuid') || await this.storage.retrieve('proposalUuId');
      this.proposal = this.navCtrl.getParam('proposal') || this.dialogParams.proposal || await this.proposals.getProposal(this.proposalUuId);
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      if (err) this.goToNoLongerValidPage();
    }
  }

  async rejectProposal() {
    const params: ConfirmPageParamsModel = {
      title: 'Reject Proposal?',
      body: ``,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmRejectProposal.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmRejectProposal() {
    try {
      await this.proposals.rejectProposal(this.proposalUuId);
      this.util.showSuccess('Proposal Rejected');
      const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
      this.navCtrl.navigateForward(url);
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async markAccepted() {
    try {
      await this.proposals.acceptProposal(this.proposalUuId);
      const clientHubUuId = this.navCtrl.getParam('clientHubUuId');
      const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
      const buttonRoute = this.cameFromClientHub ? `client/${clientHubUuId}` : url;
      const modalParams = {
        header: 'Proposal Accepted',
        body: `Would you like to schedule a ${this.proposal.service_details.service_name}?`,
        buttonText: 'Schedule Later',
        buttonRoute: buttonRoute,
        secondButtonText: 'Schedule Now',
        secondCustomAction: this.bookJob.bind(this)
      }
      const modal = await this.modalCtrl.create({
        component: SuccessPage,
        componentProps: { modalParams },
        animated: false
      });
      modal.present();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  bookJob() {
    const clientHubUuId = this.navCtrl.getParam('clientHubUuId');
    const clientHasAccount = this.navCtrl.getParam('clientHasAccount');
    if (this.proposal.customer.address_ids.length > 1) {
      const params = {
        clientHasAccount: clientHasAccount,
        proposal: this.proposal,
        clientHubUuId: clientHubUuId
      }
      this.navCtrl.navigateForward('choose-property-to-book', params);
    } else {
      this.proposals.bookJobForProposal(clientHasAccount, this.proposal);
    }
    this.modalCtrl.dismiss();
  }

  goToNoLongerValidPage() {
    this.util.showError('Proposal No Longer Valid');
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
    this.navCtrl.navigateForward(url);
  }

}

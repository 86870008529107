import { Component, Input } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';

import { AddressModel } from 'src/models/address.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';
import { ToDosCreateFirstListPage } from 'src/pages/to-dos/create-first-list/create-first-list';

@Component({
  selector: 'to-dos-onboarding',
  templateUrl: 'to-dos-onboarding.html'
})

export class ToDosOnboardingComponent {

  @Input() addressResponse: AddressModel[];
  @Input() addressId: number;
  @Input() isNormalBookingFlow: boolean;
  @Input() buttonsDisabled: boolean;
  errorMessage: string;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private toDos: ToDos,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService
  ) {}

  @Loading('', true)
  async handleOnboardingSelection(selection) {
    this.storage.delete('addresses');
    switch(selection) {

      case 'create_list':
        const address = this.addressResponse.find(item => {
          return item.id == this.addressId;
        });
        const params = {
          addressId: this.addressId,
          addressName: this.client.buildAddressName(address),
        };
        if (address?.plans?.length > 0) {
          params['plan_id'] = address?.plans[0].id;
        }
        const route = this.isNormalBookingFlow ? 'add-room-detail' : 'create-list';
        if (this.windowService.isDesktopRes && !this.isNormalBookingFlow) {
          this.storage.save('dialog-right-side-open', true);
          this.storage.save('dialog-params', params);
          this.rightSidePanelService.openRightSidePanel(ToDosCreateFirstListPage);
        } else {
          this.navCtrl.navigateForward(route, params);
        }
        break;
      case 'most_popular':
        try {
          const roomData = {
            address_id: this.addressId,
            address_instructions: {
              beds: 2,
              baths: 2,
              floors_amount: 2
            }
          }
          await this.client.saveHomeData(roomData);
          await this.toDos.updateDoNotUseToDos('active');
          if (this.isNormalBookingFlow) {
            if (this.windowService.isDesktopRes) {
              this.navCtrl.navigateForward('all-to-do-lists');
            } else {
              this.navCtrl.navigateForward('to-dos');
            }
          } else {
            localStorage.removeItem('taskListId');
            location.reload();
          }
        } catch (err) {
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        }
        break;

      case 'skip':
        try {
          await this.toDos.updateDoNotUseToDos('inactive');
          if (this.isNormalBookingFlow) {
            this.navCtrl.navigateForward('dashboard');
          } else {
            location.reload();
          }
        } catch (err) {
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        }
         break;

      default: null
    }
  }

  goToAddConciergeTask() {
    const params = {
      title: 'Concierge Action',
      type: 'support.concierge_task',
      isAddingToDoList: true
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

}

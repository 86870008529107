import { Component, OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Addresses } from 'src/providers/customer/addresses';
import { AddressComponentsModel, AddressForm } from 'src/providers/address-form/address-form';
import { Client } from 'src/providers/client/client';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { GoogleGeocode } from 'src/providers/google-geocode/google-geocode';

import { Util } from "src/shared/util/util";

import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'import-properties-csv.html'
})

export class ImportPropertiesCSVPage implements OnInit {

  alreadyAddedProperties: any;
  alreadyUploadedProperties: any;
  backButton: boolean;
  errorMessage: string;
  errorProperties: any;
  isLoadingProperties: boolean;
  loaded: boolean;
  loadedProperties: boolean;
  properties: any;
  rawProperties: any;
  showNotCSVError: boolean;
  showAddPropertiesButton: boolean;
  isRightSideContent = true;

  constructor(
    private addressForm: AddressForm,
    private addressesProvider: Addresses,
    private client: Client,
    private filePicker: FilePicker,
    private googleGeocode: GoogleGeocode,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Import Properties');
    this.backButton = this.navCtrl.getParam('backButton') || 'add-property';
    this.loadedProperties = false;
    this.properties = [];
    this.errorProperties = [];
    this.alreadyAddedProperties = [];
    this.alreadyUploadedProperties = [];
  }

  async openFilePicker() {
    this.showNotCSVError = false;
    const file = await this.filePicker.pickFile();
    if (file.format.includes('csv')) {
      this.parseIntoProperties(file);
    } else {
      return this.showNotCSVError = true;
    }
  }

  async parseIntoProperties(file) {
    this.isLoadingProperties = true;
    const base64String = file.dataUrl.split("data:text/csv;base64,").pop();
    let csv = atob(base64String);
    let csvArray = csv.split('\r\n');
    this.rawProperties = [];
    csvArray.map((row) => this.rawProperties.push(row.split(',')));
    this.rawProperties.shift(); //Removes header row
    const addresses = await this.client.getAddresses();
    for (let i = 0; i < this.rawProperties.length; i++) {
      setTimeout(async () => {
        await this.getLocationData(this.rawProperties[i], addresses);
        if (i == this.rawProperties.length - 1) {
          this.loadedProperties = true;
          this.isLoadingProperties = false;
        }
      }, i*2000);
    }
  }

  async getLocationData (property, addresses) {
    try {
      const predictions: any[] = await this.addressForm.updateSearch(`${property[0]} ${property[2]} ${property[3]}`, property[5]);
      if (!predictions || predictions?.length == 0) {
        this.errorProperties.push(property);
      } else if (this.propertyAlreadyAdded(property)) {
        this.alreadyAddedProperties.push(property);
      } else if (this.propertyAlreadyUploaded(property, addresses)) {
        this.alreadyUploadedProperties.push(property);
      } else {
        const address: AddressComponentsModel = await this.addressForm.getAdditonalAddressInfo(predictions[0]?.placeId);
        const latLngData: any = await this.googleGeocode.getLatLong(predictions[0]?.placeId);
        if (address?.state && latLngData?.latitude && latLngData?.longitude) {
          this.properties.push([
            property[0],
            property[1],
            property[2],
            property[3],
            property[4],
            property[5],
            address?.state,
            latLngData?.latitude,
            latLngData?.longitude
          ]);
        } else {
          this.errorProperties.push(property);
        }
      }
    } catch (err) {
      this.errorProperties.push(property);
    }
  }

  propertyAlreadyAdded(property) {
    let alreadyAdded = false;
    const hasUnit = property[1] !== '';
    this.properties.map((addedProperty) => {
      if (!alreadyAdded) {
        alreadyAdded = addedProperty[0] == property[0] && addedProperty[2] == property[2] && addedProperty[3] == property[3];
        if (hasUnit) {
          alreadyAdded = addedProperty[0] == property[0] && addedProperty[1] == property[1] && addedProperty[2] == property[2] && addedProperty[3] == property[3];
        }
      }
    });
    return alreadyAdded;
  }

  propertyAlreadyUploaded(property, addresses) {
    let alreadyAdded = false;
    const hasUnit = property[1] !== '';
    addresses.map((address) => {
      if (!alreadyAdded) {
        alreadyAdded = address.address1 == property[0] && address.city == property[2] && address.zip == property[3];
        if (hasUnit) {
          alreadyAdded = address.address1 == property[0] && address.address2 == property[1] && address.city == property[2] && address.zip == property[3];
        }
      }
    });
    return alreadyAdded;
  }

  async addProperties() {
    for (let i = 0; i < this.properties.length; i++) {
      const data: any = {
        address1: this.properties[i][0],
        address2: this.properties[i][1],
        city: this.properties[i][2],
        zip: this.properties[i][3],
        address_name: this.properties[i][4],
        country_code: this.properties[i][5],
        add_state: this.properties[i][6],
        latitude: this.properties[i][7],
        longitude: this.properties[i][8]
      }
      const response = await this.addressesProvider.createAddress(data);
    }
    await this.client.getAddresses(true);
    this.navCtrl.navigateForward('properties');
  }

  downloadCSVTemplate() {
    const url = "https://d30thx9uw6scot.cloudfront.net/web-images/property-upload-example.csv";
    this.util.openUrl(url);
  }

  removeProperty(index) {
    this.properties.splice(index, 1);
    this.rawProperties.splice(index, 1);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Card Info'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>

    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <form [formGroup]="form">
          <tidy-card>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-input
                [label]="'Card Name'"
                formControlName="name"
                [form]="form.controls.name"
                [icon]="'assets/svg/list-circle-outline.svg'"
                [activeIcon]="'assets/svg/list-circle-outline.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter the name of the card'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-bottom-padding" *ngIf="room?.type !== 'group'">
              <tidy-select
                [icon]="'assets/svg/home-outline.svg'"
                [label]="'Floor'"
                [form]="form.controls.floor"
                formControlName="floor"
                [items]="floorSelectItems"
                [submitted]="submitted"
                [errorMessage]="'Please enter the floor the card is on'">
              </tidy-select>
            </tidy-row>
            <tidy-row *ngIf="room?.type !== 'group'">
              <tidy-select
                [icon]="'assets/svg/ellipsis-horizontal-circle-outline.svg'"
                [label]="'Room Type'"
                [form]="form.controls.category"
                formControlName="category"
                [items]="roomTypeSelectItems"
                [submitted]="submitted"
                [errorMessage]="'Please select the room type'">
              </tidy-select>
            </tidy-row>
          </tidy-card>

          <ng-container *ngFor="let doNot of room?.notes; let i = index">
            <tidy-card *ngIf="doNot?.state === 'active'">
              <tidy-row class="field-top-padding">
                <tidy-input
                  [label]="'What should they NOT do?'"
                  [formControlName]="'doNot' + i"
                  [form]="form.controls['doNot' + i]"
                  [icon]="'assets/svg/alert-circle-outline.svg'"
                  [activeIcon]="'assets/svg/alert-circle-outline.svg'"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter the name of the do not'">
                </tidy-input>
              </tidy-row>
              <tidy-row [align]="'center'" class="extra-top-padding">
                <tidy-text
                  [body]="'Delete Do Not'"
                  (action)="deleteDoNot(doNot, i)"
                  class="link red">
                </tidy-text>
              </tidy-row>
            </tidy-card>
          </ng-container>

          <photo-caption
            [photoUrls]="photoNoteUrls"
            (deletePhoto)="confirmDelete($event)"
            [type]="'closing'"
          >
          </photo-caption>

          <tidy-button
            [text]="'Save Changes'"
            [action]="updateRoom.bind(this)"
            class="primary">
          </tidy-button>
        </form>
      </ng-container>

    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

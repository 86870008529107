import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from "@ionic/angular";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ConfirmPage, ConfirmPageParamsModel } from "src/pages/confirm/confirm.component";
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'rename-list.html'
})

export class ToDosRenameListPage implements OnInit {

  selectedList: any;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  loaded: boolean;
  submitted: boolean;
  blockCompleteOnMissingFields: boolean = false;
  addressId: string;
  constructor(
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos,
    private util: Util
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('List Settings');
    this.selectedList = this.navCtrl.getParam('selectedList') || dialogParams.selectedList;
    this.blockCompleteOnMissingFields = this.selectedList.block_complete_on_missing_fields;
    this.addressId = this.navCtrl.getParam('addressId') || dialogParams.addressId;
    this.form.patchValue({
      title: this.selectedList.title
    });
    this.loaded = true;
  }

  async renameList() {
    try {
      this.storage.delete('addresses');
      this.submitted = true;
      if (!this.form.valid) {
        return;
      }
      await this.toDos.updateListTitle(this.addressId, parseInt(this.selectedList.id), this.form.value.title);
      await this.toDos.saveBlockCompleteOnMissingFields(this.selectedList.id, this.blockCompleteOnMissingFields);
      await this.storage.save('didMakeToDoUpdates', true);
      this.rightSidePanelService.navigateTo('to-dos');
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  async deleteList() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete List?',
      body: 'You will not be able to undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete List',
      confirmAction: this.confirmDeleteList.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDeleteList() {
    localStorage.removeItem('taskListId');
    try {
      await this.toDos.deleteList(this.addressId, this.selectedList.id);
      await this.storage.save('didMakeToDoUpdates', true);
      if (this.isRightSideContent) {
        this.navCtrl.navigateForward('all-to-do-lists');
      } else {
        this.navCtrl.navigateForward('to-dos');
      }
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
    this.modalCtrl.dismiss();
  }

}

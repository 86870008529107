import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

import { GuestReservationSourceType } from 'src/models/guest-reservations';

import { InputComponent } from 'src/tidy-ui-components/components/form/input/input.component';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'tidy-direct-integration-onboarding',
  templateUrl: './direct-integration-onboarding.component.html',
  styleUrls: ['./direct-integration-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DirectIntegrationOnboardingComponent extends TimeoutableComponent {

  @ViewChild('searchInput', {static: false}) searchInput: InputComponent;
  backButton: string;
  form: UntypedFormGroup;
  integrationOptions: any[];
  allIntegrations: any[];
  integrationOptionsResponse: any[] = [];
  isRightSideContent = true;

  constructor(
    public onboardingProvider: OnboardingProvider,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Add Integration');
      this.loaded = false;
      this.allIntegrations = await this.guestReservations.getSourceTypes();
      this.backButton = localStorage.getItem('integrationOptionsBackButton') || 'integrations';
      await this.filterAllIntegrations();
      this.integrationOptions = this.integrationOptionsResponse;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async filterAllIntegrations() {
    const cameFromPropertyOnboarding = await this.storage.retrieve('cameFromPropertyOnboarding') || false;
    const cameFromIntegrateOnboarding = await this.storage.retrieve('cameFromIntegrateOnboarding') || false;
    if (cameFromPropertyOnboarding) {
      this.filterOnlyDirectItems();
    } else if (cameFromIntegrateOnboarding) {
      this.filterToShowOneIntegrationPerType();
    }
  }

  filterOnlyDirectItems() {
    this.allIntegrations.map((item) => {
      if (item.format == 'api') {
        item['formats'] = ['api'];
        this.integrationOptionsResponse.push(item);
      }
    });
  }

  filterToShowOneIntegrationPerType() {
    this.allIntegrations.map((integration) => {
      const alreadyAddedIndex = this.integrationOptionsResponse.findIndex(item => item.name === integration.name);
      if (alreadyAddedIndex !== -1) {
        this.integrationOptionsResponse[alreadyAddedIndex]['formats'].push(integration.format);
        this.integrationOptionsResponse[alreadyAddedIndex]['integrations'].push(integration);
        integration.active_details.map((detail) => {
          this.integrationOptionsResponse[alreadyAddedIndex]['active_details'].push(detail);
        });
      } else {
        this.integrationOptionsResponse.push({
          formats: [integration.format],
          integrations: [integration],
          ...integration
        });
      }
    });
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.integrationOptions = this.integrationOptionsResponse;
    }
    const term = search.toLowerCase();
    this.integrationOptions = this.integrationOptions.filter((option) => option.name.toLowerCase().indexOf(term) > -1);
  }

  async goToAddIntegration(reservationSrcType: GuestReservationSourceType) {
    await this.storage.save('allIntegrationOptionsResponse', this.allIntegrations);
    await this.storage.save('reservationSrcType', reservationSrcType);
    this.onboardingProvider.priorPages.push('directIntegration');
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'addDirectIntegration';
  }

}

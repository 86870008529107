import { Injectable } from '@angular/core';

@Injectable()
export class MyHouse {

  alreadyCreated(): boolean {
    return localStorage.getItem('myHouseCreated') === 'created';
  }

  caroselContent() {
    const carosel = {
      content: [],
      current: 0
    };

    carosel.content = [
      {
        img: 'assets/img/slide01.png',
        text: 'Add To Dos for what you want done. Do Nots for what you don’t want.'
      },
      {
        img: 'assets/img/slide02.png',
        text: 'Add photos to better show what you want.'
      },
      {
        img: 'assets/img/slide03.png',
        text: 'Mark things as Important for your cleaner.'
      },
      {
        img: 'assets/img/slide04.png',
        text: 'Use Before & After Photos to see your results!'
      }
    ];

    return carosel;
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ContactConciergePage } from '../../contact-concierge/contact-concierge';
import { LoveItPage } from 'src/pages/feedback/love-it/love-it';
import { DamageClaimPage } from '../../damage-claim/damage-claim';
import { MissingItemPage } from 'src/pages/missing-item/missing-item';
import { ProDidNotShowUpPage } from 'src/pages/pro-did-not-show-up/pro-did-not-show-up';
import { JobIssueNegativeReviewPage } from '../job-issue-negative-review/job-issue-negative-review';
import { NeedsWorkWhatHappenedPage } from 'src/pages/feedback/needs-work/what-happened/what-happened';

@Component({
  templateUrl: 'job-issue-detail.html',
  encapsulation: ViewEncapsulation.None,
})
export class JobIssueDetailPage implements OnInit {
  job: any;
  jobStatus: string;
  privateJob: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private concierge: Concierge,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {}

  async ngOnInit() {
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.rightSidePanelService.setDialogPageTitle('Job Issue');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.job = await this.getParam('job');
    this.privateJob = await this.getParam('isPrivate');
    const jobStatus = await this.getParam('jobStatus');
    this.jobStatus = this.buildJobStatus(jobStatus);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  buildJobStatus(jobStatus) {
    if (jobStatus === 'Completed') {
      return (
        ' was marked as complete by ' + this.job.homekeepers[0].first_name + '.'
      );
    } else {
      return (
        ' was marked as complete by ' +
        this.job.homekeepers[0].first_name +
        ", but the other Pro wasn't able to make it so you were billed 50% the cost of the job"
      );
    }
  }

  selectIssue(issue) {
    const pro = this.job.homekeepers[0];
    const hkJob = this.job.homekeeper_jobs[0];
    const jobId = this.job.job.id;
    let params = {};

    switch (issue) {
      case 'bad_quality':
        if (!hkJob.feedback_type) {
          params = {
            firstName: pro.first_name,
            pro,
          };
          this.rightSidePanelService.navigateTo(
            `needs-work-what-happened/${jobId}/${hkJob.id}`,
            params,
            NeedsWorkWhatHappenedPage
          );
          return;
        } else {
          params = {
            job: this.job,
            privateJob: this.privateJob,
          };
          this.rightSidePanelService.navigateTo(
            'job-issue-negative-review',
            params,
            JobIssueNegativeReviewPage
          );
          return;
        }

      case 'no_show':
        params = {
          proName: pro.first_name,
          hkId: pro.id,
          jobId: jobId,
        };
        this.rightSidePanelService.navigateTo(
          'pro-did-not-show-up',
          params,
          ProDidNotShowUpPage
        );
        return;

      case 'missing_item':
        params = {
          jobId: jobId,
          hkJobId: hkJob.id,
          proName: this.job.homekeepers[0].first_name,
          job: this.job,
          privateJob: this.privateJob,
        };
        this.rightSidePanelService.navigateTo(
          'missing-item',
          params,
          MissingItemPage
        );
        return;

      case 'damage_claim':
        params = {
          job: this.job,
          privateJob: this.privateJob,
        };
        this.rightSidePanelService.navigateTo(
          'damage-claim',
          params,
          DamageClaimPage
        );
        return;

      case 'tip':
        params = {
          cleaningId: jobId,
          hkJobId: hkJob.id
        };
        this.rightSidePanelService.navigateTo(
          `love-it/${jobId}/${hkJob.id}`,
          params,
          LoveItPage
        );
        return;

      case 'other_issue':
        params = {
          title: 'Other Job Issue',
          type: 'support.job_issue',
          metadata: {
            suptype: 'other_Issue',
            job_id: this.job.job.id,
            job_datetime: this.job.job.start_datetime,
            privateJob: this.privateJob,
          },
        };
        this.rightSidePanelService.navigateTo(
          'contact-concierge',
          params,
          ContactConciergePage
        );
        return;
    }
  }
  
}

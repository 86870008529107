<div class="card-container" style="margin-bottom: 2rem">
  <div class="card-header">
    <div class="card-header-content">
      <tidy-row>
        <tidy-text [header]="'Find New Pros'"> </tidy-text>
      </tidy-row>
      <tidy-toggle
        [toggled]="enableFindNewPros"
        (toggleChanged)="enableFindNewPros = !enableFindNewPros">
      </tidy-toggle>
    </div>
  </div>
  <tidy-divider></tidy-divider>
  <div class="card-body">
    <tidy-row>
      <tidy-text
        [body]="'Do you want to find new pros, if yours are not available? (You can customize these per property later) pick now or tell us what you want and we can set it up'">
      </tidy-text>
    </tidy-row>
  </div>
</div>

<tidy-row class="extra-bottom-padding">
  <tidy-text [helper]="'or'" class="divider"> </tidy-text>
</tidy-row>

<tidy-card>
  <tidy-card-button (action)="goToConciergeAction()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <tidy-image [src]="'assets/img/csv.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Tell us what you want'"> </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-button
  [text]="'Save Settings'"
  [action]="submit.bind(this)"
  [class]="'primary'">
</tidy-button>

import { Component, ViewChild, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DateTime as LuxonDateTime } from 'luxon';

import { BookJob } from 'src/providers/book-job/book-job';
import { CalendarDay } from 'src/providers/calendar/calendar-day';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/providers/logger';
import { Me } from 'src/providers/me/me';
import { PlanData } from 'src/providers/plans/plan-data';
import { Pros } from 'src/providers/pros/pros';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';
import { Util } from 'src/shared/util/util';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { TimeRanges } from 'src/providers/time-ranges/time-ranges';
import { ConfirmBookingPage } from '../booking/confirm-booking/confirm-booking';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { AddProPage } from '../my-pros/add-pro/add-pro';
import { ConfirmBookingPaymentPage } from '../confirm-booking-payment/confirm-booking-payment';

@Component({
  templateUrl: 'request-job.html',
})

export class RequestJobPage implements OnInit {

  address;
  allTimesOpts: TidySelectStringValueModel[];
  attachments: any;
  bookingExperiment: any;
  bookingId: string;
  bookingType: string;
  cameFromBookOrRequest: any;
  customBackPage: string;
  currentDateTime: any;
  customFlowName: string;
  customFlowSmallImage: string;
  customFlowDiscount: string;
  dateLabel;
  didChooseService: boolean = true;
  didSelectAllTimes: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  forms: any;
  isRentalClient: boolean;
  isPrivateFlow: any;
  hasPrivatePros: boolean;
  hasPros: boolean;
  hasNonTrialPaidSubscription: boolean;
  noLaterTimes: TidySelectStringValueModel[];
  noEarlierTimes: TidySelectStringValueModel[];
  priceExperiment: any;
  proId: any;
  priorityListSentence: string;
  allowTimesToday: any;
  hasActiveSubscription: boolean;
  hasImageUploadError: boolean;
  probabilityData: any;
  planId: any;
  showTimesError: boolean;
  service: any;
  services: any;
  serviceTypeId: any;
  showMoreServices: boolean;
  showSameDayTimeAlert: boolean;
  selectTimesTitle;
  selectedCheckboxes: any;
  submitted: boolean;
  tooFarStartDateError: boolean;
  understandExtraCost: boolean;
  waterfallSettings: any;
  dialogParams: any;
  isRightSideContent = true;
  providedStartDate: string;
  @ViewChild('noEarlierDate') noEarlierDate: any;
  loaded: boolean;

  constructor(
    public bookJobService: BookJob,
    private calendarDay: CalendarDay,
    private card: Card,
    private client: Client,
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private me: Me,
    private navCtrl: CustomNavController,
    private logger: Logger,
    private pros: Pros,
    private planData: PlanData,
    private storage: TidyStorage,
    private timeRanges: TimeRanges,
    private util: Util,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      service: ['', Validators.required],
      noEarlierDate: ['', Validators.required],
      noEarlierTime: ['', Validators.required],
      noLaterDate: ['', Validators.required],
      noLaterTime: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Request Job');
    this.loadDataAndParams();
  }

  @Loading("", true)
  async loadDataAndParams() {
    try {
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.customBackPage = this.windowService.isDesktopRes ? null : (shouldShowAllToDosPage ? 'schedule' : 'schedule-list');
      this.rightSidePanelService.setDialogLoading(true);
      await this.getPageParams();
      await this.loadPageParams();
      this.showSameDayTimeAlert = LuxonDateTime.fromJSDate(this.form.controls.noEarlierDate.value).hasSame(this.currentDateTime, 'day');
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
    }
  }

  async getPageParams() {
    this.hasPrivatePros = await this.pros.checkIfHasPrivatePro();
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    this.customFlowName = localStorage.getItem('customFlowName');
    this.customFlowSmallImage = localStorage.getItem('customFlowSmallImage');
    this.customFlowDiscount = localStorage.getItem('customFlowDiscount');
    this.cameFromBookOrRequest = await this.getParam('cameFromBookOrRequest');
    this.hasActiveSubscription = await this.getParam('hasActiveSubscription');
    this.bookingType = await this.getParam('bookingType');
    this.planId = await this.getParam('planId');
    this.hasNonTrialPaidSubscription = await this.getParam('hasNonTrialPaidSubscription') || await this.checkIfhasNonTrialPaidSubscription;
    this.priceExperiment = await this.getParam('priceExperiment');
    this.bookingExperiment = await this.getParam('bookingExperiment');
    this.services = await this.getParam('services') || await this.getServices();
    this.service = await this.getParam('selectedService') || this.services[0];
    this.proId = await this.getParam('proId');
    this.address = await this.getParam('address');
    this.serviceTypeId = await this.getParam('serviceTypeId') || 1;
    this.isPrivateFlow = await this.getParam('isPrivateFlow') ?? false;
    this.bookingId = await this.getParam('bookingId');
    const today = LuxonDateTime.local().toFormat('MM-dd-yyyy');
    this.providedStartDate = await this.getParam('providedStartDate') || today;
    const parsedDate = LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy').set({
      hour: LuxonDateTime.now().hour,
      minute: LuxonDateTime.now().minute,
      second: LuxonDateTime.now().second
    }).toJSDate();
    this.form.patchValue({
      noEarlierDate: parsedDate,
      noLaterDate: parsedDate
    });
  }

  async getParam(paramName): Promise<any> {
    const param = this.dialogParams?.[paramName] || this.navCtrl.getParam(paramName) || await this.storage.retrieve(paramName);
    this.storage.save(paramName, param);
    return param;
  }

  async loadPageParams() {
    this.loaded = false;
    if (this.cameFromBookOrRequest) {
      this.showMoreServices = true;
    }
    this.buildTimes();
    this.form.patchValue({
      service: this.service.key
    });
    this.currentDateTime = LuxonDateTime.fromObject({}, {
      zone: this.address.timezone,
    });
    this.allowTimesToday = await this.calendarDay.checkIfAllowTimesToday(this.currentDateTime);
    const requestDate = this.allowTimesToday ? this.currentDateTime : this.currentDateTime.plus({days: 1});
    this.dateLabel = requestDate.toFormat('M/d');
    this.selectTimesTitle = `What Times Work ${this.allowTimesToday ? 'Today' : 'Tomorrow'} ${this.dateLabel}?`;
    this.attachments = [];
    this.serviceChange();
    let prosData = await this.pros.getPros(this.serviceTypeId);
    prosData = this.pros.parseProsIntoGroups(prosData);
    this.priorityListSentence = this.pros.updatePriorityListSentence(prosData);
    this.loaded = true;
  }

  async checkIfhasNonTrialPaidSubscription() {
    const subscriptionTypes = await this.me.getSubscriptionTypes();
    this.hasNonTrialPaidSubscription = await this.me.checkIfHasNonTrialPaidSubscription(subscriptionTypes);
  }

  async getServices() {
    try {
      const client = await this.client.getClientInfo();
      return await this.bookJobService.fetchRequiredTeamServices('all', false, this.serviceTypeId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  handleServiceClick(service) {
    this.showMoreServices = false;
    if (this.service.key === service.key) {
      return;
    }
    this.service = service;
    this.form.patchValue({ service: service.value});
    this.didChooseService = true;
  }

  async submit() {
    const isProRequest = false;
    const isJobRequest = true;
    const { showSameDayTimeAlert } = this;
    const bookingId = this.bookingId;
    const viewData = this.buildViewData();
    const address = this.address;
    const selectedServiceData = this.service;
    let bookData = this.getBookData();
    if (this.forms?.length) {
      bookData = this.addBookingFormData(bookData);
    }
    this.submitted = true;
    if (!this.form.valid || this.hasImageUploadError) {
      return;
    }
    const requestDates = {
      startDate: this.form.value.noEarlierDate,
      endDate: this.form.value.noLaterDate
    }
    try {
      const route = await this.getTargetPage();
      const params = { selectedServiceData, address, bookData, viewData, isJobRequest, showSameDayTimeAlert, bookingId, requestDates, customBackRoute: 'request-job', isProRequest };
      const url = route;
      const component = route === 'confirm-booking-payment' ? ConfirmBookingPaymentPage : ConfirmBookingPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'request-job-get-confirm-page');
    }
  }

  addBookingFormData(bookData) {
    bookData['booking_form'] = {
      input_answers: []
    }
    this.forms[0].inputs.map((form, i) => {
      if (this.form.value[form.id] || form.data?.type == 'image' || form.data?.type == 'images') {
        const value = this.getFormValue(form, i);
        if (value) {
          bookData.booking_form.input_answers.push({
            input_id: form.id,
            value: value
          });
        }
      }
    });
    this.selectedCheckboxes.map((checkbox) => {
      checkbox.value.map((value, i) => {
        const isOtherField = Object.values(value).includes(null);
        if (isOtherField) {
          let object = {};
          object[Object.keys(value)[0]] = this.form.value[checkbox.input_id + 'textArea'];
          checkbox.value[i] = object;
        }
      });
      bookData.booking_form.input_answers.push(checkbox);
    });
    return bookData;
  }

  getFormValue(form, i) {
    if (form.type == 'select' && (form?.data?.type !== 'image' || form?.data?.type !== 'images')) {
      const item = form.items.find((item) => item.value == this.form.value[form.id]);
      let object = {};
      object[this.form.value[form.id]] = item.responseValue || this.form.value[form.id + 'textArea'];
      return object;
    } else if (this.form.value[form.id]) {
      return this.form.value[form.id];
    } else {
      const attachment = this.attachments.find((item) => item.input_id == form.id);
      if (!attachment && form.is_required) {
        this.forms[0].inputs[i]['imageUploadError'] = true;
        this.hasImageUploadError = true;
      } else {
        this.forms[0].inputs[i]['imageUploadError'] = false;
        this.hasImageUploadError = false;
      }
      return attachment?.value;
    }
  }

  getBookData() {
    return {
      address_id: this.address.id,
      booking: {
        service_type_key: this.service.key,
        start_date_no_earlier_than: this.formatDate(this.form.value.noEarlierDate, 'YYYY-MM-DD'),
        start_time_no_earlier_than: this.form.value.noEarlierTime,
        end_date_no_later_than: this.formatDate(this.form.value.noLaterDate, 'YYYY-MM-DD'),
        end_time_no_later_than: this.form.value.noLaterTime,
        request_type: 'waterfall'
      },
      rating_options: {hk_rating: 'same_day', rating_chosen: false}
    };
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

  async getTargetPage(): Promise<string> {
    const hasValidCC = await this.card.hasValidCard();
    const shouldRequestCC = !(this.isPrivateFlow || hasValidCC);
    return shouldRequestCC ? 'confirm-booking-payment' : 'confirm-booking';
  }

  buildViewData() {
    return {
      service: this.service.title,
      frequency: 'One Time',
      price: this.service.price,
      basePrice: this.service.price,
      duration: this.service.duration,
      image: this.service.image
    };
  }

  buildTimes() {
    let numberOfOptions = 10;
    let noLaterStart = 9;
    if (this.isRentalClient || this.hasPrivatePros) {
      this.allTimesOpts = this.timeRanges.timeRangesOpts();
      this.noEarlierTimes = this.timeRanges.timeRangesOpts();
      this.noLaterTimes = this.timeRanges.timeRangesOpts();
    } else {
      if (this.service.duration == 150) {
        numberOfOptions = 9;
        noLaterStart = 11;
      } else if (this.service.duration == 240) {
        numberOfOptions = 8;
        noLaterStart = 13;
      }
      this.allTimesOpts = this.buildTimesArray(8, 11);
      this.noEarlierTimes = this.buildTimesArray(8, numberOfOptions);
      this.noLaterTimes = this.timeRanges.timeRangesOpts().filter(timeOpt => {
        return !(timeOpt.value < noLaterStart?.toString()
                || timeOpt.value > '23:00');
      });
    }
    this.form.patchValue({
      noEarlierTime: '08:00',
      noLaterTime: '18:30'
    });
  }

  buildTimesArray(startHour, numberOfOptions) {
    const minutesBetweenEachOption = 90;
    return Array.from({ length: numberOfOptions }, (_, index) => {
      const momentDateTimeObject = moment({ hours: startHour }).add(index * minutesBetweenEachOption, 'minutes');
      const hour12Format = momentDateTimeObject.format('h:mma');
      const militaryHourFormat = momentDateTimeObject.format('HH:mm');
      return { viewValue: hour12Format , value: militaryHourFormat };
    });
  }

  @Loading("", true)
  async serviceChange() {
    try {
      this.forms = await this.bookJobService.getBookingFormTemplate(this.form.value.service);
      if (this.forms.length && !this.service.key.includes('regular_cleaning')) {
        this.buildForm();
        this.selectedCheckboxes = [];
      }
      this.updateNoLaterOpts();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  buildForm() {
    this.forms.map((form) => {
      form.inputs.map((field) => {
        if (field?.data?.type !== 'image' && field?.data?.type !== 'images' && field?.type !== 'multi_select') {
          this.form.addControl(field.id.toString(), this.fb.control(undefined));
          if (field.type == 'select') {
             field['items'] = this.buildSelectItems(field)
          }
          if (field.is_required) {
            this.form.controls[field.id.toString()].setValidators([Validators.required]);
          }
        }
      });
    });
  }

  buildSelectItems(field) {
    let array = [];
    field.data.options.map((option) => {
      array.push({
        value: option.key,
        viewValue: option.label,
        responseValue: option.value,
        requiresTextArea: !option.value
      });
    });
    return array;
  }

  selectCheckbox(selected, checkbox, field, index) {
    if (selected) {
      let valueAlreadyAdded = false;
      let inputAlreadyAdded = false;
      this.selectedCheckboxes.map((selections) => {
        if (selections.input_id == field.id) {
          inputAlreadyAdded = true;
          selections.value.map((value) => {
            if (Object.keys(value)[0] == checkbox.key) {
              valueAlreadyAdded = true;
            }
          });
          if (inputAlreadyAdded && !valueAlreadyAdded) {
            selections.value.push({
              [checkbox.key]: checkbox.value
            });
          }
        }
      });
      if (!valueAlreadyAdded && !inputAlreadyAdded) {
        this.selectedCheckboxes.push({
          input_id: field.id,
          value: [{
            [checkbox.key]: checkbox.value
          }]
        });
      }
      if (!checkbox.value) {
        this.changeSelectValue({}, index);
      }
    }
    if (!selected) {
      this.selectedCheckboxes.map((item, i) => {
        if (item.input_id == field.id) {
          let array = [];
          item.value.map((value) => {
            if (Object.keys(value)[0] !== checkbox.key) {
              array.push(value);
            }
          });
          item.value = array;
          if (item.value.length == 0) {
            this.selectedCheckboxes.splice(i, 1);
          }
        }
      });
      if (!checkbox.value) {
        this.changeSelectValue({responseValue: 'hasValue'}, index);
      }
    }
  }

  changeSelectValue(value, index) {
    if (!value.responseValue) {
      this.form.addControl(this.forms[0].inputs[index].id.toString() + 'textArea', this.fb.control(undefined));
      this.form.controls[this.forms[0].inputs[index].id.toString() + 'textArea'].setValidators([Validators.required]);
      this.forms[0].inputs[index].requiresTextArea = true;
    } else {
      this.forms[0].inputs[index].requiresTextArea = false;
      this.form.controls[this.forms[0].inputs[index].id.toString() + 'textArea'].clearValidators();
      this.form.controls[this.forms[0].inputs[index].id.toString() + 'textArea'].updateValueAndValidity();
    }
  }

  @Loading('', true)
  async addAttachment(field) {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      if (attachment.filename !== '') {
        this.attachments.map((attachment, i) => {
          if (attachment.input_id == field.input_id) {
            this.attachments.splice(i, 1);
          }
        });

        let value;
        if (field.type == 'files') {
          const attachmentRef = (this.attachments.find((attachment) => attachment.input_id == field.id))
          if (attachmentRef) {
            return attachmentRef.value.push(attachment.url);
          }
          value = [attachment.url];
        } else {
          value = attachment.url;
        }

        this.attachments.push({
          input_id: field.id,
          value,
          fileName: attachment.filename
        });
        this.hasImageUploadError = false;
      } else {
        this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  getAttachment(field) {
    return this.attachments.find((attachment) => attachment.input_id == field.id);
  }

  getAttachmentName(field) {
    this.getAttachment(field)?.fileName;
  }

  removeAttachment(field, imageUrl = null) {
    if (imageUrl) {
      const attachmentRef = this.getAttachment(field);
      attachmentRef.value = attachmentRef.value.filter((url) => url !== imageUrl);
      if (attachmentRef.value.length > 0) {
        return;
      }
    }

    const attachmentIndex = this.attachments.findIndex((attachment) => attachment.input_id == field.id);
    this.attachments.splice(attachmentIndex, 1);
  }

  selectNoEarlierTime(time) {
    if (this.form.value.noLaterDate < this.form.value.noEarlierDate
        || (this.form.value.noLaterDate <= this.form.value.noEarlierDate && time > this.form.value.noLaterTime)) {
      this.form.patchValue({noLaterTime: null});
    }
    this.showSameDayTimeAlert = LuxonDateTime.fromJSDate(this.form.controls.noEarlierDate.value).hasSame(this.currentDateTime, 'day');
    this.updateNoLaterOpts();
    this.markAllTimesSelected();
  }

  selectNoLaterTime(time) {
    this.showTimesError = false;
    if (this.isRentalClient || this.hasPrivatePros) {
      if (this.isIssueWithSelectedTimes()) {
        return this.showTimesError = true;
      } else {
        return;
      }
    }
    if (this.form.value.noLaterDate < this.form.value.noEarlierDate || (this.form.value.noLaterDate <= this.form.value.noEarlierDate && time < this.form.value.noEarlierTime)) {
      this.form.patchValue({noEarlierTime: null});
    }
    this.showSameDayTimeAlert = LuxonDateTime.fromJSDate(this.form.controls.noEarlierDate.value).hasSame(this.currentDateTime, 'day');
    this.markAllTimesSelected();
  }

  updateNoLaterOpts() {
    this.showTimesError = false;
    const noEarlierDate = this.form.value.noEarlierDate;
    const noLaterDate = this.form.value.noLaterDate;
    this.markAllTimesSelected();
    if (noEarlierDate && noLaterDate) {
      if (this.isRentalClient || this.hasPrivatePros) {
        if (this.isIssueWithSelectedTimes()) {
          return this.showTimesError = true;
        } else {
          return;
        }
      }
      if ((noEarlierDate.toString() === noLaterDate.toString())) {
        const serviceEnd = moment(this.form.value.noEarlierTime, 'HH:mm').add(this.service?.duration, 'minutes');
        this.noLaterTimes = this.timeRanges.timeRangesOpts().filter(timeOpt => {
          const optionMoment = moment(timeOpt.value, 'HH:mm');
          return !(optionMoment.diff(serviceEnd) < 0
                  || timeOpt.value > '23:00');
        });
      } else {
        this.noLaterTimes = this.buildTimesArray(7, 33);
      }
    } else {
      return;
    }
  }

  isIssueWithSelectedTimes() {
    const startIndex = this.timeRanges.timeRangesOpts().findIndex(item => item.value === this.form.value.noEarlierTime);
    const endIndex = this.timeRanges.timeRangesOpts().findIndex(item => item.value === this.form.value.noLaterTime);
    const numberOfItemsBetween = endIndex - startIndex - 1;
    if (startIndex < 16 || endIndex > 46) {
      return true;
    } else if (this.service.duration == 240 && (numberOfItemsBetween < 10)) {
      return true;
    } else if (this.service.duration == 150 && (numberOfItemsBetween < 7)) {
      return true;
    } else if (numberOfItemsBetween < 4) {
      return true;
    } else {
      return false;
    }
  }

  earlierDateChange(event) {
    if (!event || event === '') {
      return;
    }
    // TODO: All the current behavior should keep working, less the bug
    // TODO: Fix form is not updating it value
    this.tooFarStartDateError = false;
    this.showSameDayTimeAlert = LuxonDateTime.fromJSDate(this.form.value.noEarlierDate).hasSame(this.currentDateTime, 'day');
    if (this.showSameDayTimeAlert) {
      this.noEarlierTimes = this.allTimesOpts.filter(timeOpt => {
        const timeOptOnZone = LuxonDateTime
          .fromFormat(timeOpt.value, 'HH:mm', {zone: this.address.timezone});

        const minutesDiff = timeOptOnZone
          .diff(this.currentDateTime, 'minutes')
          .toObject().minutes;

        return minutesDiff > 10;
      });
    } else {
      this.noEarlierTimes = this.allTimesOpts;
    }
    this.noEarlierTimes = this.noEarlierTimes.filter(timeOpt => {
      const serviceEndTime = moment(timeOpt.value, 'HH:mm').add(this.service.duration, 'minutes');
      const lastTimeOpt = moment('23:00', 'HH:mm');
      return serviceEndTime.diff(lastTimeOpt, 'minutes') <= 0;
    });
    this.markAllTimesSelected();
  }

  @Loading("", true)
  async markAllTimesSelected() {
    this.didSelectAllTimes = this.form.value.noEarlierDate && this.form.value.noEarlierTime && this.form.value.noLaterDate && this.form.value.noLaterTime;
    if (this.didSelectAllTimes) {
      const getBookData = this.getBookData();
      this.probabilityData = await this.bookJobService.getRequestProbability(getBookData, this.address.id);
    }
  }

  showService(service) {
    const isTheOneSelected = this.service.key === service.key;
    return !this.didChooseService
            || this.showMoreServices
            || isTheOneSelected;
  }

  showServices() {
    this.showMoreServices = true;
  }

  async turnOnTidyFindNewPros() {
    const data = {
      service_type_id: 1,
      key: 'substitutes_on'
    };
    try {
      this.waterfallSettings = await this.client.updateWaterfallSettings(data);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToAddPro() {
    const params = {
      categoryId: 1
    }
    const url = 'add-pro';
    const component = AddProPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToLearnMoreProbability() {
    const url = 'https://help.tidy.com/acceptance-probability';
    this.util.openUrl(url);
  }

  getTimesErrorText() {
    if (this.service.duration == 240) {
      return '5.5 hours of time for a 4 hour job.';
    } else if (this.service.duration == 150) {
      return '4 hours of time for a 2.5 hour job.';
    } else {
      return '2.5 hours of time for a 1 hour job.';
    }
  }

}

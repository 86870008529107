<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="pageTitle"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
        <form [formGroup]="form" novalidate action="#">
          <div style="display: flex; justify-content: space-between; margin-bottom: 10px" *ngIf="windowService.isDesktopRes">
            <tidy-select
              [label]="'Property'"
              [items]="propertyToAddItems"
              [form]="form.controls.address"
              formControlName="address"
              (optionChange)="changeAddress()"
              [icon]="'assets/svg/home-outline.svg'"
              style="flex: 1; padding-right: 5px;"
              class="select-property-input">
            </tidy-select>
            <tidy-select
              [label]="'Service Type'"
              [items]="serviceTypeItems"
              [form]="form.controls.serviceType"
              [selectedItem]="selectedServiceType"
              formControlName="serviceType"
              [icon]="'assets/svg/briefcase-outline.svg'"
              style="flex: 1; padding-left: 5px;"
              class="select-property-input"
              (optionChange)="changeServiceType()">
            </tidy-select>
          </div>
          <div *ngIf="!windowService.isDesktopRes">
            <tidy-select
              [label]="'Property'"
              [items]="propertyToAddItems"
              [form]="form.controls.address"
              formControlName="address"
              (optionChange)="changeAddress()"
              [icon]="'assets/svg/home-outline.svg'"
              class="select-property-input">
            </tidy-select>
            <tidy-select
              [label]="'Service Type'"
              [items]="serviceTypeItems"
              [form]="form.controls.serviceType"
              formControlName="serviceType"
              [icon]="'assets/svg/briefcase-outline.svg'"
              class="select-property-input"
              (optionChange)="changeServiceType()">
            </tidy-select>
          </div>
          <tidy-radio-button
            *ngIf="hasPrivatePros || isRentalClient"
            [items]="howToBookItems"
            [form]="form.controls.howToBook"
            formControlName="howToBook"
            class="select-property-input"
            (optionChange)="changeServiceType()">
          </tidy-radio-button>

        </form>

        <tidy-loader *ngIf="showLoader"></tidy-loader>

      <ng-container *ngIf="form?.value?.howToBook == 'book' && !showLoader && !isFindingPro">
        <ng-container *ngIf="scenario == 'notHasCleaningPros' || scenario == 'notHasNonCleaningPros'">
          <tidy-card *ngIf="scenario == 'notHasNonCleaningPros'">
            <tidy-card-button (action)="goToRequestJob()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/icon/searching.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Request'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [header]="params?.categoryHeader">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="priorityListSentence">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="scenario !== 'notHasNonCleaningPros'">
            <tidy-card-button (action)="goToBookJobwithNewPro()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/book-job.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Book'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [header]="params?.categoryHeader">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToBrowsePros()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/icon/thumbtack_icon.png'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Browse Pros'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToAddPro()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/priority.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Add Pro'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>

        <ng-container *ngIf="scenario == 'hasCleaningPros' || scenario == 'hasNonCleaningPros'">
          <tidy-card>
            <tidy-card-button (action)="goToRequestJob()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/icon/searching.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Request'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [header]="params?.categoryHeader">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [body]="priorityListSentence">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-row>
            <tidy-text
              [body]="'Want a specific pro?'">
            </tidy-text>
          </tidy-row>
          <tidy-card *ngFor="let pro of shownPros">
            <tidy-card-button (action)="goToBookJobWithPro(pro)">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/account.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="pro?.object?.first_name">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-row [align]="'center'" *ngIf="pros?.length > 2 && pros?.length !== shownPros?.length">
            <tidy-text
              [body]="'View More'"
              class="link"
              (action)="showAllPros()">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Want a new pro?'">
            </tidy-text>
          </tidy-row>
          <tidy-card *ngIf="scenario == 'hasCleaningPros'">
            <tidy-card-button (action)="goToBookJobwithNewPro()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/book-job.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Instantly Book a New Pro'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button (action)="goToBrowsePros()">
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/browse-pros.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Browse Pros'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-card *ngIf="!isAddingPro && form?.value?.howToBook == 'assign' && proItems?.length && !addressDetailLoading && !showLoader">
        <form [formGroup]="form" novalidate action="#">
          <tidy-row>
            <tidy-select
              [label]="params?.categoryHeader + ' Pro'"
              [icon]="'assets/svg/person-outline.svg'"
              [form]="form.controls.pro"
              formControlName="pro"
              [items]="proItems"
              (optionChange)="selectPro($event)">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="proServiceItems?.length">
            <tidy-row>
              <tidy-select
                [label]="'Service'"
                [icon]="'assets/svg/time-outline.svg'"
                [form]="form.controls.service"
                formControlName="service"
                [items]="proServiceItems"
                trackBy="trackByFn">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-datepicker
                label="Job Date"
                [icon]="'assets/svg/calendar-outline.svg'"
                formControlName="jobDate"
                [form]="form.controls.jobDate"
                errorMessage="Please enter a valid date."
                [submitted]="submitted">
              </tidy-datepicker>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [label]="'Job Time'"
                [icon]="'assets/svg/time-outline.svg'"
                [form]="form.controls.jobTime"
                formControlName="jobTime"
                [items]="logJobTimeItems">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="!proServiceItems?.length" class="extra-bottom-padding">
            <tidy-text
              [body]="'This pro doesn\'t offer any services in this category. Please ask them to add services in this category.'"
              class="red">
            </tidy-text>
          </tidy-row>
        </form>
        <tidy-button
          *ngIf="form?.value?.howToBook == 'assign'"
          [action]="logJob.bind(this)"
          [text]="getButtonText()"
          class="primary"
          [disabled]="!proItems?.length || !proServiceItems?.length">
        </tidy-button>
      </tidy-card>

      <tidy-card *ngIf="form?.value?.howToBook == 'assign' && !proItems?.length && !showLoader">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'You haven\'t added any'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="params?.categoryHeader.toLowerCase()">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'pros yet. Add someone you work with to send them requests to do your jobs.'">
          </tidy-text>
          <tidy-text
            class="link"
            [body]="'Learn More'"
            (action)="learnMoreAddPros()">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="addProForm" novalidate action="#">
          <tidy-row>
            <tidy-input
              formControlName="name"
              [label]="'Their Name'"
              [form]="addProForm.controls.name"
              [submitted]="submitted"
              [errorMessage]="'Please enter a name.'"
              [icon]="'assets/svg/person-outline.svg'"
              [activeIcon]="'assets/svg/person-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="email"
              [label]="'Their Email'"
              [form]="addProForm.controls.email"
              type="email"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid email.'"
              [icon]="'assets/svg/mail-outline.svg'"
              [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input-international-phone
              formControlName="phone"
              [label]="'Their Phone'"
              [form]="addProForm.controls.phone"
              [inputMode]="'numeric'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
            </tidy-input-international-phone>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-select
              #tidySelect
              [label]="'Services Offered'"
              [items]="serviceTypeItems"
              [form]="addProForm.controls.serviceCategories"
              formControlName="serviceCategories"
              [multiple]="true"
              [submitted]="submitted"
              [errorMessage]="'Please select at least one service.'">
            </tidy-select>
          </tidy-row>
        </form>
        <tidy-button
          [action]="addPro.bind(this)"
          [text]="'Add Pro'"
          class="secondary">
        </tidy-button>
      </tidy-card>

      <tidy-button
        *ngIf="isFindingPro"
        [action]="goToBrowsePros.bind(this)"
        [text]="'Find New Pro'"
        class="primary">
      </tidy-button>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <div style="display: flex; justify-content: space-between; padding: 5px;">
        <ion-skeleton-text animated="true" style="flex: 1; margin-right: 5px; height: 40px; border-radius: 4px;"></ion-skeleton-text>
        <ion-skeleton-text animated="true" style="flex: 1; margin-left: 5px; height: 40px; border-radius: 4px;"></ion-skeleton-text>
      </div>
      <ion-skeleton-text animated="true" style="height: 420px; border-radius: 4px;"></ion-skeleton-text>
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

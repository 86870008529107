import { Component, ViewChild, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { TidyStorage } from './../../../shared/providers/tidy-storage';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';
import { Subscription } from 'rxjs';

import { Communication } from 'src/providers/communication/communication';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DynamicComponentProvider } from 'src/shared/providers/dynamic-component/dynamic-component';
import { Invoices } from 'src/providers/invoices/invoices';
import { Me } from 'src/providers/me/me';
import { ModalController } from '@ionic/angular';
import { Pros } from 'src/providers/pros/pros';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Schedule } from 'src/providers/schedule/schedule';
import { ToDos } from 'src/providers/to-dos/to-dos';

import { Loading } from 'src/shared/components/loading/loading';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';

import { MapPopup } from 'src/pages/dashboard/map-popup/map-popup';
import { popupOptions } from 'src/pages/dashboard/map-popup/popup-opts';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { PastJobPdfParams, PastJobPdfProvider } from '../past-job-pdf/past-job-pdf';
import { PendingInvoicePage } from 'src/pages/invoices/pending-invoice/pending-invoice';
import { PaidInvoicePage } from 'src/pages/invoices/paid-invoice/paid-invoice';
import { JobActivityPage } from 'src/pages/schedule/job-activity/job-activity';
import { MarkJobCompletePage } from 'src/pages/schedule/mark-job-complete/mark-job-complete';
import { InputToDosPage } from 'src/pages/more/input-to-dos/input-to-dos';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { Capacitor } from '@capacitor/core';
import { CapitalizePipe } from 'src/shared/pipes/capitalize.pipe';
import { ShareJobPage } from 'src/pages/share-job/share-job';

declare const mapboxgl: any;

@Component({
  selector: 'past-private-job',
  templateUrl: 'past-private-job.html',
  styleUrls: ['past-private-job.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PastPrivateJobPage implements OnInit {

  activity: any;
  address: any;
  addressResponse: any;
  backPage: string;
  bookingNote: any;
  bookingFormAnswers: any;
  clientName: string;
  displayAddress: any;
  errorMessage: string;
  form: UntypedFormGroup;
  fromStartDuration: string;
  isInternalComment: boolean;
  isFullWidth: boolean;
  job: any;
  jobId: any;
  invoice: any;
  proReportedDuration: string;
  pro: any;
  rooms: any;
  timezone: string;
  loggedIssuesResponse: any;
  messageSelectItems: any;
  selectedMessageType: any;
  submitted: boolean;
  unresolvedLoggedIssues: any;
  messages: any;
  mapInstance: any;
  @ViewChild('map', {static: false}) mapElement: ElementRef;
  dialogParams: any;
  isRightSideContent = true;
  beforePhotos: any[] = [];
  afterPhotos: any[] = [];
  isNativeMobile = Capacitor.isNativePlatform();
  mediaTypes = [
    {
      value: 'photo',
      viewValue: 'Photo'
    },
    {
      value: 'video',
      viewValue: 'Video'
    }
  ];
  jobMediaForm: UntypedFormGroup;
  isAddBeforePhotos = false;
  isAddAfterPhotos = false;
  panelClosedSubscription: Subscription;
  loaded: boolean;
  jobEvents: any[];

  constructor(
    public communication: Communication,
    private client: Client,
    private dynamicComponentService: DynamicComponentProvider,
    private fb: UntypedFormBuilder,
    private invoices: Invoices,
    public me: Me,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private reportIssue: ReportIssue,
    private route: ActivatedRoute,
    private schedule: Schedule,
    public toDos: ToDos,
    private util: Util,
    public windowService: WindowService,
    private storage: TidyStorage,
    public rightSidePanelService: RightSidePanelService,
    private pastJobPdfProvider: PastJobPdfProvider,
    private jobMediasProvider: JobMediasProvider,
    private cameraProvider: CameraProvider,
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
      messageType: ['public_reply']
    });
    this.jobMediaForm = this.fb.group({
      mediaType: ['photo'],
    });
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (!this.isRightSideContent) {
      this.isFullWidth = window?.innerWidth > 1000;
    }
  }

  async ngOnInit() {
    this.jobMediaForm.patchValue({ mediaType: 'photo' });
    this.beforePhotos = [];
    this.afterPhotos = [];
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.isFullWidth = false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Past Job');
    this.loadData();
  }

  addBeforePhotos() {
    this.isAddBeforePhotos = true;
  }

  addAfterPhotos() {
    this.isAddAfterPhotos = true;
  }

  @Loading('', true)
  async loadData() {
    try {
      this.initializeComponentState();
      await this.loadJobDetails();
      this.loaded = true;
      if (!this.job.homekeepers.length) {
        return;
      }
      this.loadRooms();
      this.loadLoggedIssues();
      this.loadProDetails();
      this.loadClientName();
      this.loadActivity();
      this.loadAddressAndTimezone();
      this.loadInvoices();
      this.loadBookingDetails();
      this.prepareMessageForm();
      this.getJobEvents();
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
    } catch (err) {
      console.error(err);
    }
  }

  async getJobEvents() {
    this.jobEvents = await this.schedule.getJobEvents(this.job.booking.id);
  }

  async loadJobMedias(): Promise<any> {
    const jobMedias = await this.jobMediasProvider.getJobMedias([this.jobId]);
    const beforePhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'before_job');
    const afterPhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'after_job');
    return {
      beforePhotos,
      afterPhotos,
    };
  }

  async getParsedJobMediasWithMetadata(jobMedias: any[]) {
    const homekeepers = this.job.homekeepers;
    jobMedias = await this.toDos.processFilesAndReturn(jobMedias);
    const parsedJobMedias = jobMedias.map((jobMedia) => {
      const homekeeper = homekeepers.find((homekeeper) => homekeeper.id === jobMedia.uploaded_by_id);
      return {
        ...jobMedia,
        mediaType: jobMedia.media_format,
        url: jobMedia.media_url,
        uploadedBy: jobMedia.uploaded_by_type === 'User' ? homekeeper?.name : null,
      };
    });
    return parsedJobMedias;
  }

  initializeComponentState(): void {
    this.loaded = false;
    this.jobId = +this.route.snapshot.paramMap.get('cleaningId') || this.dialogParams?.jobId;
    this.backPage = this.navCtrl.getParam('backPage') || localStorage.getItem('pastJobBackPage') || 'past-jobs';
  }

  async loadJobDetails(): Promise<void> {
    this.job = this.navCtrl.getParam('job') || await this.schedule.getJobDetail(this.jobId);
    this.displayAddress = this.job.address?.address_name || (this.job.address.address1 + ' ' + (this.job.address.address2 || ''));
  }

  async loadRooms(): Promise<void> {
    this.rooms = await this.toDos.getToDosForPastJob(this.jobId);
    this.rooms = this.toDos.buildRoomIcons(this.rooms);
    this.rooms = await this.toDos.getMetadataFromFiles(this.rooms);
  }

  async loadLoggedIssues(): Promise<void> {
    this.loggedIssuesResponse = await this.reportIssue.getIssueReportsByAddress([this.job.job.address_id]);
    this.unresolvedLoggedIssues = this.parseUnresolvedLoggedIssues();
  }

  async loadProDetails(): Promise<void> {
    this.pro = await this.pros.getProDetail(this.job.homekeepers[0].id, this.job.job.address_id);
  }

  async loadClientName(): Promise<void> {
    this.clientName = await this.getClientName();
  }

  async loadActivity(): Promise<void> {
    this.activity = await this.buildActivityArrays();
    await this.markMessagesAsRead();
  }

  async loadAddressAndTimezone() {
    this.addressResponse = await this.client.getAddresses();
    this.address = this.getAddress(this.addressResponse);
    this.timezone = this.address.timezone;
    this.buildMap();
  }

  async loadInvoices(): Promise<void> {
    const invoices = await this.invoices.getInvoices({
      teamId: this.job.team_id,
      status: null,
      jobId: this.jobId
    });
    this.getInvoiceForJob(invoices);
  }

  async loadBookingDetails(): Promise<void> {
    this.bookingNote = await this.schedule.getBookingNotesForJob(this.job.booking.id);
    this.bookingFormAnswers = await this.schedule.getBookingFormAnswers(this.job.booking.id);
    this.proReportedDuration = this.pros.parseDurationIntoHoursAndMintes(this.job.homekeeper_jobs[0].job_durations.reported_by_homekeeper, 'proReportedDuration');
    this.fromStartDuration = this.pros.parseDurationIntoHoursAndMintes(this.job.homekeeper_jobs[0].job_durations.from_start_to_end_moment, 'fromStartDuration');
  }

  prepareMessageForm(): void {
    this.messageSelectItems = this.getMessageSelectItems();
    this.form.patchValue({messageType: 'public_reply'});
    this.selectedMessageType = this.messageSelectItems[1];
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('past-private-job')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public reply',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  async buildMap() {
    try {
      if (!this.mapElement) {
        return setTimeout(() => this.buildMap(), 0);
      }
      this.mapInstance = this.createMap();
      const moments = this.getMoments();
      this.addMomentsToMap(moments, this.mapInstance);
      this.addAddressToMap(this.mapInstance);
      this.setMapBounds(this.mapInstance, moments);
      setTimeout(() => {
        this.mapInstance.resize();
      }, 100);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  createMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrdHVoNHc1bTIwYjYycHE1NmhjdzVtdzMifQ.OIqKrdlDh4fJH0g0MO1zLg';
    return new mapboxgl.Map({
      container: `map${this.jobId}`,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      maxZoom: 11,
      preserveDrawingBuffer: true,
    });
  }

  getMoments() {
    const moments: any = [];
    this.job.activity_timelines.map((activityTimeline) => {
      activityTimeline.activity_timeline_items.map((moment) => {
        if (moment.type == 'cleaning_update') {
          moments.push({
            latitude: moment.data.latitude,
            longitude: moment.data.longitude,
            body: '',
            image: this.schedule.getMomentImage(moment.data.type),
            mapIcon: this.schedule.getMomentImage(moment.data.type),
            header: moment.data.text
          });
        }
      });
    });
    return moments;
  }

  addMomentsToMap(moments, map) {
    moments.forEach((moment, i) => {
      if (moment?.latitude) {
        const popupContent = this.dynamicComponentService.injectComponent(
          MapPopup, x => x.data = {
            image: moment.image,
            body: moment.body,
            header: moment.header,
            learnMore: ''
          }
        );
        this.createPopupAndMarker(map, popupContent, moment.mapIcon, moment);
      }
    });
  }

  addAddressToMap(map) {
    const popupContent = this.dynamicComponentService.injectComponent(
      MapPopup, x => x.data = {
        image: 'assets/img/house.svg',
        body: '',
        header: this.address?.address_name || this.address.address1,
        learnMore: ''
      }
    );
    const addressIcon = 'assets/img/map-home-blue.svg';
    this.createPopupAndMarker(map, popupContent, addressIcon, this.address);
  }

  createPopupAndMarker(map, popupContent, mapIcon, location, customOpts = null) {
    const popup = new mapboxgl.Popup({...popupOptions, ...customOpts}).setDOMContent(popupContent);
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="${mapIcon}" style="width: 40px; height: 40px; padding-top: 10px;"></image>`;
    this.createMarkerInstance({map, el, longLat: [ location.longitude, location.latitude ]}, popup);
  }

  createMarkerInstance(obj, popup) {
    const {el, longLat, map} = obj;
    const marker = new mapboxgl.Marker(el);
    marker.getElement().addEventListener('click', () => {
      const flyToCoords = [...longLat];
      const getZoom = map.getZoom() ;
      const zoomOut = getZoom < 0;
      const lgnSum = zoomOut ? 200 / (5 * getZoom) : 50 / (2 ** getZoom);
      if (flyToCoords[1] + lgnSum > 90) {
        flyToCoords[1] = 90;
      } else if ((flyToCoords[1] + lgnSum < -90)) {
        flyToCoords[1] = -90;
      } else {
        flyToCoords[1] += lgnSum;
      }
      map.flyTo({
        center: flyToCoords
      })
    });
    marker.setLngLat(longLat).setPopup(popup).addTo(map);
  }

  setMapBounds(map, moments) {
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([this.address.longitude, this.address.latitude]);
    moments.forEach((moment) => {
      if (moment.latitude) {
        bounds.extend([moment.longitude, moment.latitude]);
      }
    });
    map.fitBounds(bounds, { padding: 50, duration: 0 });
  }

  getInvoiceForJob(invoices) {
    invoices.map((invoice) => {
      invoice.items.map((item) => {
        if (item.item_id == this.jobId) {
          this.invoice = invoice;
        }
      });
    });
  }

  getAddress(addressResponse) {
    return addressResponse.find((address) => address.id == this.job.address.id);
  }

  async buildActivityArrays() {
    let activity = [];
    await this.job.homekeeper_jobs?.map(async (homekeeperJob) => {
      const AISuggestionsPayload = {
        feature: 'chat_suggestion',
        data: {
          chat_room_key: `homekeeper_job_id-${homekeeperJob}`
        }
      }
      await this.communication.generateAISuggestions(AISuggestionsPayload);
    });
    this.messages = await this.communication.getJobMessages([this.jobId]);
    this.job.homekeeper_jobs?.map((homekeeperJob) => {
      activity.push({
        pro: this.job.homekeepers.find((homekeeper) => homekeeper.id == homekeeperJob.user_id),
        messages: this.parseActivity(this.messages, homekeeperJob.id)
      });
    });
    return activity;
  }

  parseActivity(messages, homekeeperJobId) {
    let activity = [];
    this.job.activity_timelines.map((timeline) => {
      if (timeline.homekeeper_job_id == homekeeperJobId) {
        const homekeeperJobMessages = messages.filter(
          (message) =>
            message.metadata?.homekeeper_job_id == homekeeperJobId
        );
        let updates = timeline.activity_timeline_items.filter(
          (item) => item.type === 'cleaning_update'
        );
        activity = this.concatenateMessagesAndUpdates(
          updates,
          homekeeperJobMessages
        );
      }
    });
    return activity;
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates.map((update) => {
      update['sent_at'] = update.data.created_at;
    });
    if (updates.length) {
      const activity = updates.concat(messages);
      activity.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return activity;
    } else {
      messages.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return messages;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.activity.map((pro) => {
      pro.messages.map((message, index) => {
        if (index < 3 && !message?.is_read && message?.from_member?.type !== 'CustomerMember' && message?.type !== 'cleaning_update' && message?.chat_room_key) {
          array.push((message?.chat_room_key));
        }
      })
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async getClientName() {
    const me = await this.me.load();
    return me.customer_account.name;
  }

  contactPro(phone) {
    return window.location.href = `tel:${phone}`;
  }

  goToProHomepage() {
    this.util.openUrl(`https://www.tidy.com/${this.pro.team.url_suffix}`);
  }

  goToLoggedIssues() {
    localStorage.setItem('loggedIssuesBackPage', `past-private-job/${this.job.job.id}`)
    const params = {
      loggedIssuesResponse: this.loggedIssuesResponse,
      unresolvedLoggedIssues: this.unresolvedLoggedIssues,
      address: this.job.address
    }
    this.navCtrl.navigateForward(`tasks/${this.job.job.address_id}`, params);
  }

  parseUnresolvedLoggedIssues() {
    return this.loggedIssuesResponse.filter((item) => item.status && item.status !== 'done');
  }

  async goToJobActivityPage(activity) {
    const isPastJob = true;
    const isPrivateJob = true;
    const params = {
      messages: activity.messages,
      job: this.job,
      pro: activity.pro,
      card: this.job,
      categoryImage: this.job.booking.bookable_service.icon_url,
      address: this.job.address,
      showSendMessage: false,
      isPastJob,
      isPrivateJob,
      addressId: this.job.address.id,
      jobId: this.job.job.id,
      proId: activity.pro.id
    }
    const url = `job-activity/${this.job.address.id}/${this.job.job.id}/${activity.pro.id}/${isPastJob}/${isPrivateJob}`
    this.goToPage(url, params, JobActivityPage);
  }

  getDate(date) {
    if (!date.includes('UTC')) {
      date += ' UTC';
    }
    return LuxonDateTime.fromSQL(date).setZone(this.job.address.timezone).toRelative();
  }

  goToInvoicePage() {
    if (!this.invoice) {
      return;
    } else if (this.invoice.full_paid) {
      this.goToPaidInvoicePage();
    } else {
      this.goToPendingInvoicePage();
    }
  }

  async goToPendingInvoicePage() {
    try {
      const settings = await this.client.getClientSettings();
      const params = {
        invoice: this.invoice,
        hasAccount: true,
        proName: this.pro.homekeeper.first_name,
        proEmail: this.pro.homekeeper.email,
        proPhone: this.pro.homekeeper.phone,
        proTeamId: this.job.team_id,
        proId: this.pro.homekeeper.id,
        clientName: settings.profile.first_name,
        clientEmail: settings.profile.email,
        job: this.job,
        routePath: 'bill/:invoice_id',
        customBack: 'past-private-job/' + this.jobId,
        invoiceId: this.invoice.id
      }
      this.goToPage(`bill/${this.invoice.id}`, params, PendingInvoicePage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToPaidInvoicePage() {
    const params = {
      invoice: this.invoice,
      isLoggedIntoClientApp: true,
      proName: this.pro.homekeeper.first_name,
      proEmail: this.pro.homekeeper.email,
      proPhone: this.pro.phone,
      clientName: this.clientName,
      job: this.job
    }
    this.goToPage(`paid-bill/${this.invoice.id}`, params, PaidInvoicePage);
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.job.homekeeper_jobs[0].id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachments,
        is_internal: this.isInternalComment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.activity = await this.buildActivityArrays();
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  hasBeforeAfterPhotos(array) {
    return array.some(item => item.media_format == 'photo');
  }

  ionViewDidLeave() {
    this.mapInstance?.remove();
  }

  goToMarkJobComplete() {
    const price = (this.job.booking.bookable_service.price / 100).toString();
    const params = {
      bookingId: this.job.booking.id,
      jobId: this.job.job.id,
      price: price == '0' ? null : price,
      duration: null
    }
    this.goToPage('mark-job-complete', params, MarkJobCompletePage);
  }

  goBack() {
    this.navCtrl.navigateRoot(this.backPage);
  }

  printPdf(): void {
    const params: PastJobPdfParams = {
      job: this.job,
      pro: this.pro,
      rooms: this.rooms,
      invoice: this.invoice,
      proReportedDuration: this.proReportedDuration,
      fromStartDuration: this.fromStartDuration,
      tips: null,
      parkingReimbursements: null,
      billingInfo: null,
      activities: this.activity,
      messages: this.messages,
      jobId: this.jobId,
      totalTipsValue: null,
      address: this.address,
      beforePhotos: this.beforePhotos,
      afterPhotos: this.afterPhotos
    }
    this.pastJobPdfProvider.exportToPDF(params);
  }

  async takeVideo(categoryType: string) {
    this.errorMessage = '';
    try {
      const parsedJobMedia = await this.jobMediasProvider.takeBeforeAfterVideo(categoryType, this.jobId);
      await this.jobMediasProvider.saveJobMedias(this.jobId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      this.util.showError('Error taking video. Please try again.', err);
      console.error('error on trying to take/upload file:', err);
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  async takePhoto(categoryType: string) {
    this.cameraProvider.getImg().then(async (photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto = photo?.dataUrl && !photo?.base64String ? photo?.dataUrl : `data:image/jpeg;base64,${photo.base64String}`;
      const newPhoto = { url: parsedPhoto, mediaType: 'photo' };
      const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(newPhoto.url, this.jobId, categoryType);
      await this.jobMediasProvider.saveJobMedias(this.jobId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    });
  }

  async uploadVideo(videos: any[], categoryType: string) {
    try {
      const parsedJobMedias = [];
      await Promise.all(
        videos.map(async (video) => {
          const parsedJobMedia = await this.jobMediasProvider.uploadAndSaveVideo(
            video.file,
            this.jobId,
            categoryType,
            video.file
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.jobId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading video. Please try again.', err);
    }
  }

  async uploadPhoto(photos: any[], categoryType: string) {
    const parsedJobMedias = [];
    try {
      await Promise.all(
        photos.map(async (photo) => {
          const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(
            photo.url,
            this.jobId,
            categoryType,
            photo?.file,
            photo?.exif
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.jobId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading photo. Please try again.', err);
    }
  }

  async removePhoto(image, index, categoryType) {
    const params: ConfirmPageParamsModel = {
      title: image?.mediaType == 'photo' ? 'Remove photo?' : 'Remove video?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove ',
      confirmAction: this.confirmRemovePhoto.bind(this, image, index, categoryType)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmRemovePhoto(image, index, categoryType) {
    try {
      await this.jobMediasProvider.deleteJobMedia(image.id);
      if (categoryType == 'before_job') {
        this.beforePhotos.splice(index, 1);
      } else {
        this.afterPhotos.splice(index, 1);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error removing photo. Please try again.', err);
    }
    this.modalCtrl.dismiss();
  }

  goToEditToDos() {
    const params = {
      rooms: this.rooms,
      nextPage: `past-private-job/${this.jobId}`,
      jobId: this.jobId
    }
    this.goToPage('input-to-dos', params, InputToDosPage);
  }

  goToShareJobPage() {
    const params = {
      job: {
        template_data: this.job
      },
      addressName: this.address.address_name,
      isPastJobLink: true
    };
    const url = 'share-job';
    const component = ShareJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);

    this.goToPage(url, params, component);
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.loadData();
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

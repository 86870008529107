<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Edit Property'"
    [class]="extraClass"
    [canGoBack]="true"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteAddress()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngIf="loaded">
      <form [formGroup]="form" novalidate action="#">
        <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-row [align]="'center'">
          <tidy-row>
            <tidy-text
              [header]="address?.address1 + ' '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="address?.address2 !== null"
              [header]="address?.address2 + ' '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [header]="address?.city +
              ', ' +
              address?.zip"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-row>

          <tidy-row [alignLastItemRight]="true">
            <tidy-row [alignLastItemRight]="true" class="full-width">
              <tidy-text
                [body]="'Edit Order'">
              </tidy-text>
            </tidy-row>
            <tidy-toggle
              [toggled]="isReordering"
              (toggleChanged)="isReordering = !isReordering"
              class="extra-left-margin">
            </tidy-toggle>
          </tidy-row>

        <ng-container *ngIf="isReordering">
          <div cdkDropListGroup>
            <ion-row class="drag-header">
              <tidy-text [header]="'Edit Order'" class="custom-title-size"> </tidy-text>
            </ion-row>
            <ion-row>
              <ion-col>
                <div
                  cdkDropList
                  [cdkDropListData]="columns[0].items"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let item of columns[0].items">
                    <div cdkDrag class="drag-item">
                      <tidy-card>
                        <tidy-row [alignLastItemRight]="true">
                          <tidy-image [src]="item.icon" class="title-size">
                          </tidy-image>
                          <tidy-text [header]="item.name"> </tidy-text>
                          <div></div>
                        </tidy-row>
                      </tidy-card>
                    </div>
                  </ng-container>
                </div>
              </ion-col>
              <ion-col>
                <div
                  cdkDropList
                  [cdkDropListData]="columns[1].items"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let item of columns[1].items">
                    <div cdkDrag class="drag-item">
                      <tidy-card>
                        <tidy-row [alignLastItemRight]="true">
                          <tidy-image [src]="item.icon" class="title-size">
                          </tidy-image>
                          <tidy-text [header]="item.name"> </tidy-text>
                          <div></div>
                        </tidy-row>
                      </tidy-card>
                    </div>
                  </ng-container>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </ng-container>

        <ng-container *ngIf="!isReordering">
          <tidy-card>
            <tidy-row class="field-top-padding">
              <tidy-input
                formControlName="addressNickname"
                [form]="form.controls.addressNickname"
                [label]="'Property Nickname (optional)'"
                [icon]="'assets/svg/information-circle-outline.svg'">
              </tidy-input>
            </tidy-row>
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/home-outline.svg'"
                [label]="'Build Type'"
                [items]="buildTypeItems"
                [form]="form.controls.buildType"
                formControlName="buildType"
                [errorMessage]="'Please enter a build type.'"
                [submitted]="submitted">
              </tidy-select>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'Roof Type'"
                [items]="roofTypeItems"
                [form]="form.controls.roofType"
                formControlName="roofType"
                [errorMessage]="'Please enter a roof type.'"
                [submitted]="submitted">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-input
                [label]="'Square Feet'"
                formControlName="squareFeet"
                [form]="form.controls.squareFeet"
                [icon]="'assets/svg/expand-outline.svg'"
                [inputMode]="'numeric'"
                [submitted]="submitted"
                [errorMessage]="'Please enter the square feet.'">
              </tidy-input>
            </tidy-row>
          </tidy-card>

          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [text]="'Save'"
            class="primary"
            [action]="save.bind(this)">
          </tidy-button>

          <tidy-row *ngIf="isRightSideContent" [align]="'center'" class="extra-top-padding">
            <tidy-text
              class="link red"
              [body]="'Delete Property'"
              (action)="deleteAddress()">
            </tidy-text>
          </tidy-row>

        </ng-container>
      </form>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
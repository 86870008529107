import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';
import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';

import { AddressModel } from 'src/models/address.model';
import { TidySelectBooleanValueModel, TidySelectStringValueModel, TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Logger } from 'src/providers/logger';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';
import { Analytics } from 'src/providers/analytics/analytics';
import { Addresses } from 'src/providers/customer/addresses';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { ClientSettingsModel } from 'src/models/client.model';

import { validateEmail } from 'src/shared/validator/validateEmail';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { HubspotFieldIds, HubspotFormIds } from 'src/shared/enums/hubspot.enum';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { UseToDosPage } from 'src/pages/to-dos/use-to-dos/use-to-dos';
import { SuccessPage } from 'src/shared/pages/success/success';
import { AddAddressPage } from 'src/pages/add-address/add-address';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'property-options.html',
  encapsulation: ViewEncapsulation.None,
  providers: [PhotoCaptionService]
})
export class PropertyOptionsPage extends TimeoutableComponent {

  alertHeader: string;
  accessPhotoUrls: PhotoNoteUrlModel[] = [];
  address: AddressModel;
  addressResponse: AddressModel[];
  form: UntypedFormGroup;
  formIds: any = HubspotFormIds;
  freeParkingSpots: TidySelectStringValueModel[];
  hasLockDevicesIntegrated: boolean;
  isNormalBookingFlow: boolean;
  inputIds: any = HubspotFieldIds;
  maxCostOptions:TidySelectNumberValueModel[];
  page: any;
  parkingTypes: TidySelectBooleanValueModel[];
  paidParkingSpots: TidySelectStringValueModel[];
  payOptions:TidySelectStringValueModel[];
  parkingPhotoUrls: PhotoNoteUrlModel[] = [];
  showPhoneEmailFields: boolean;
  closingPhotoUrls: PhotoNoteUrlModel[] = [];
  viewData: any;
  showAlert: boolean;
  settings: ClientSettingsModel;
  submitted: boolean;
  errorMessage: string;
  updatedSettings: {profile: {
    phone: string,
    email: string}} = {profile:
      {phone: '',
      email: ''}};
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private addresses: Addresses,
    private analytics: Analytics,
    private client: Client,
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private logger: Logger,
    private photoCaptionService: PhotoCaptionService,
    private photoNotes: PhotoNotes,
    private util: Util,
    private cameraProvider: CameraProvider,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private windowService: WindowService
  ) {
    super();
    this.form = this.fb.group({
      paid_parking: ['', Validators.required],
      parking_notes: ['', Validators.required],
      home_access: ['', Validators.required],
      home_close: [''],
      parking_spot: ['', Validators.required],
      parking_pay_with: [''],
      max_parking_cost: [''],
      email: [''],
      phone: [''],
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.loaded = false;
      this.isNormalBookingFlow = await this.getParam('isNormalBookingFlow');
      const isJobRequest = await this.getParam('isJobRequest');
      if (this.isNormalBookingFlow) {
        this.settings = await this.client.getClientSettings();
        const isCustomFlow = localStorage.getItem('customFlowName');
        if (isCustomFlow) {
          this.showPhoneEmailFields = false;
          localStorage.removeItem('customFlowName');
        } else {
          this.showPhoneEmailFields = true;
          this.form.controls.phone.setValidators([Validators.required]);
          this.form.controls.email.setValidators([Validators.required, validateEmail]);
        }
        this.form.patchValue({email: this.settings.profile.email, phone: this.settings.profile.phone});
        this.addressResponse = await this.client.getMoreDetailAddresses(true);
        this.address = this.addressResponse[0];
        this.viewData = await this.getParam('viewData');
        this.setupAnalytics();
      } else {
        this.address = await this.getParam('address');
        this.parkingPhotoUrls = this.photoCaptionService.mountPhotoUrls(this.address.photo_notes, 'parking');
        this.accessPhotoUrls = this.photoCaptionService.mountPhotoUrls(this.address.photo_notes, 'access');
        this.closingPhotoUrls = this.photoCaptionService.mountPhotoUrls(this.address.photo_notes, 'closing');
        this.form.patchValue({
          paid_parking: this.address.paid_parking,
          parking_notes: this.address.parking_notes,
          parking_spot: this.address.parking_spot,
          parking_pay_with: this.address.parking_pay_with,
          max_parking_cost: this.address.max_parking_cost,
          home_access: this.address.home_access,
          home_close: this.address.home_close,
        });
        this.showAlert = true;
        this.alertHeader = this.getAlertHeader();
        this.hasLockDevicesIntegrated = await this.getParam('hasLockDevicesIntegrated');
        const howToGetInParams = await this.getParam('howToGetInParams');
        this.handleHowToGetInParams(howToGetInParams);
      }
      this.page = this.getPageData(isJobRequest);
      this.rightSidePanelService.setDialogPageTitle(this.page.title);
      this.setFormOptions();
      this.setValidators();
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    console.log('param', param);
    return param;
  }

  async handleHowToGetInParams(howToGetInParams: {
    message: string;
    files: any[];
  }): Promise<void> {
    if (!howToGetInParams) {
      return;
    }
    const alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
      },
      {
        text: 'Yes',
        role: 'confirm',
      },
    ]
    const alert = await this.util.showAlert(
      'Alert',
      'We will paste your message to "How to Get In" input, this action will overwrite the current message. Should we proceed?',
      false,
      alertButtons
    );
    alert.onDidDismiss().then(async (res) => {
      if (res.role === 'confirm') {
        this.form.patchValue({
          home_access: howToGetInParams.message,
        });
        if (howToGetInParams.files?.length > 0) {
          const squaredImage = await this.cameraProvider.getSquaredImage(howToGetInParams.files[0].file);
          const imgParams = {
            url: squaredImage.dataUrl,
            type: 'access',
            id: -1,
            photoNoteCaption: '',
            captionChanged: false,
            deleted: false
          }
          this.accessPhotoUrls.push(imgParams);
        }
      }
    });
  }

  getPageData(isJobRequest) {
    if (this.isNormalBookingFlow) {
      if (isJobRequest) {
        return {
          title: 'Job Requested!',
          button: 'Next',
        }
      } else {
        return {
          title: 'You\'re Scheduled!',
          button: 'Next',
        }
      }
    } else {
      return {
        title: 'Edit Property',
        button: 'Save Changes',
      }
    }
  }

  getAlertHeader() {
    if (!this.form.controls.home_access.valid && !this.form.controls.parking_notes.valid) {
      return 'Parking & Access Info Helps!';
    } else if (!this.form.controls.home_access.valid) {
      return 'Access Info Helps!';
    } else {
      return 'Parking Info Helps!';
    }
  }

  setValidators() {
    if (this.form.controls.paid_parking.value) {
      const requiredParkingFields = ['parking_pay_with', 'max_parking_cost'];
      requiredParkingFields.forEach(field => this.form.controls[field].setValidators([Validators.required]));
    }
  }

  clearFields() {
    if (this.form.valid) {
      this.showAlert = false;
    }
    this.form.patchValue({
      parking_spot: null,
      parking_pay_with: null,
      max_parking_cost: null
    });
    this.setValidators();
  }

  async save() {
    try {
      this.errorMessage = '';
      this.submitted = true;
      if (this.isNormalBookingFlow && !this.form.controls.email.valid || !this.form.controls.phone.valid) {
        return;
      }
      await this.uploadPhotos();
      const addressFields = ['paid_parking', 'parking_spot', 'parking_pay_with', 'max_parking_cost', 'parking_notes', 'home_access', 'home_close'];
      addressFields.forEach(field => this.address[field] = this.form.controls[field].value);
      await this.addresses.update(this.address, this.address.id);
      if (this.isNormalBookingFlow) {
        this.updatedSettings.profile.phone = this.form.value.phone;
        this.updatedSettings.profile.email = this.form.value.email;
        try {
          await this.client.saveClientSettings(this.updatedSettings);
          const params = {
            addressResponse: this.addressResponse,
            addressId: this.address.id
          }
          this.rightSidePanelService.navigateTo('use-to-dos', params, UseToDosPage);
        } catch (err) {
        }
      } else {
        await this.client.getMoreDetailAddresses(true);
        this.navCtrl.navigateForward(`edit-property/${this.address.id}`);
      }
    } catch(err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async uploadPhotos() {
    try {
      await this.photoCaptionService.uploadPhotosGeneric(
        this.accessPhotoUrls,
        'access',
        this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'access'),
        this.photoNotes.photoCaption.bind(this.photoNotes),
        'room'
      );
      await this.photoCaptionService.uploadPhotosGeneric(
        this.parkingPhotoUrls,
        'parking',
        this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'parking'),
        this.photoNotes.photoCaption.bind(this.photoNotes),
        'room'
      );
      await this.photoCaptionService.uploadPhotosGeneric(
        this.closingPhotoUrls,
        'closing',
        this.photoNotes.saveAddressPhotoNote.bind(this.photoNotes, 'closing'),
        this.photoNotes.photoCaption.bind(this.photoNotes),
        'room'
      );
    } catch(err) {
      this.logger.error(err, 'upload image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async deleteAddress() {
    if (this.address.plans.length) {
      const params = {
        header: 'Cannot Delete Property',
        body: 'This address has scheduled jobs. Please cancel the jobs for this property before deleting this property.',
        buttonText: 'Ok',
        buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
      };
      return this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    }
    const params: ConfirmPageParamsModel = {
      title: 'Delete Property?',
      body: 'Are you sure you want to delete this property?',
      backText: 'Go Back',
      confirmText: 'Delete Property',
      confirmAction: this.confirmDeleteAddress.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteAddress() {
    try {
      await this.addresses.deleteAddress(this.address.id);
      const addresses = await this.client.getAddresses(true);
      localStorage.removeItem('addressId');
      if (addresses.length === 0) {
        const params = {
          hasNoAddresses: true
        }
        this.rightSidePanelService.navigateTo('add-property', params, AddAddressPage);
      } else {
        this.navCtrl.navigateForward('more');
      }
      this.modalController.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  setFormOptions() {
    this.parkingTypes = [
      {
        viewValue: 'Free',
        value: false
      },
      {
        viewValue: 'Paid',
        value: true
      }
    ];
    this.paidParkingSpots = [
      {
        viewValue: 'Meter',
        value: 'meter'
      },
      {
        viewValue: 'Paid Lot',
        value: 'paidlot'
      }
    ];
    this.freeParkingSpots = [
      {
        viewValue: 'Street',
        value: 'street'
      },
      {
        viewValue: 'My Spot / Driveway',
        value: 'myspot'
      },
      {
        viewValue: 'Guest Parking',
        value: 'guest'
      }
    ];
    this.payOptions = [
      {
        viewValue: 'Cash',
        value: 'cash'
      },
      {
        viewValue: 'Card',
        value: 'card'
      },
    ];
    let maxCostOptions = [];
    for (let i = 1; i <= 30; i++) {
      maxCostOptions.push({
        viewValue: 'Max $' + i,
        value: i*100
      });
    }
    this.maxCostOptions = maxCostOptions;
  }

  async getImg(name: string) {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg(name);

      switch(name) {
        case 'parking':
          this.parkingPhotoUrls.push(newPhotoNote);
          break;
        case 'access':
          this.accessPhotoUrls.push(newPhotoNote);
          break;
        case 'closing':
          this.closingPhotoUrls.push(newPhotoNote);
          break;
        default: null
      }
    } catch (err) {
      this.logger.error(err, 'upload address image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async confirmDelete(photoId: number) {
    try {
      await this.photoCaptionService.confirmDelete(
        photoId,
        [...this.accessPhotoUrls, ...this.parkingPhotoUrls, ...this.closingPhotoUrls]
      );
    } catch (err) {
      this.logger.error(err, 'delete image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  setupAnalytics() {
    this.analytics.configureBing('first_purchase', 'first_purchase', 'first_purchase', '1');
    this.analytics.configureGoogleGtag('first_purchase', 'pWYoCID9v78BEIfH5s0D', { transaction_id: '' });
  }
}

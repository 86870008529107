import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';

import { ClientSettingsModel } from 'src/models/client.model';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'tidy-find-new-pro.html'
})
export class TidyFindNewProPage implements OnInit {

  categoryId: number;
  cameFromMyProsOnboardingPage: boolean;
  errorMessage: string;
  allowsFindNewPros: boolean;
  form: UntypedFormGroup;
  genderOptions: TidySelectStringValueModel[];
  hasPrivatePro: boolean;
  initialFindNewProsValue: boolean;
  serviceCategoryItems: any;
  settings: ClientSettingsModel;
  supportsFindNewPros = true;
  updatedSettings: {homekeeper_preferences: {gender: string}} = {homekeeper_preferences: {gender: ''}};
  loaded: boolean;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private fb: UntypedFormBuilder,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Find New Pro Settings');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.settings = await this.client.getClientSettings();
      this.initialFindNewProsValue = this.navCtrl.getParam('allowsFindNewPros') || this.dialogParams?.allowsFindNewPros;
      this.allowsFindNewPros = this.initialFindNewProsValue;
      this.populateFields();
      this.cameFromMyProsOnboardingPage = this.navCtrl.getParam('cameFromMyProsOnboardingPage') || this.dialogParams?.cameFromMyProsOnboardingPage;
      this.serviceCategoryItems = this.navCtrl.getParam('serviceCategoryItems') || this.dialogParams?.serviceCategoryItems;
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  changeServiceCategory(id) {
    this.categoryId = id;
    this.supportsFindNewPros = id == 1;
  }

  async save() {
    this.errorMessage = '';
    try {
      if (this.allowsFindNewPros !== this.initialFindNewProsValue) {
        const hasPrivatePro = this.navCtrl.getParam('hasPrivatePro') || this.dialogParams?.hasPrivatePro;
         if (!hasPrivatePro && !this.allowsFindNewPros) {
          return this.goToUnableToBlock();
        }
        await this.moveProToCollection();
      }
      this.updatedSettings.homekeeper_preferences.gender = this.form.value.gender;
      await this.client.saveClientSettings(this.updatedSettings);
      this.rightSidePanelService.navigateTo('job-request-workflows');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToUnableToBlock() {
    const message = 'You must have at least 1 private pro on your list to stop allowing Find New Pro.';
    this.util.showError(message, 10000);
    this.rightSidePanelService.navigateTo('job-request-workflows');
  }

  async moveProToCollection() {
    const prosData = this.navCtrl.getParam('prosData') || this.dialogParams?.prosData;

    // Parse grouped pros to flat array
    prosData.blocked = prosData.blocked.flat();
    prosData.approved = prosData.approved.flat();
    prosData.favorited = prosData.favorited.flat().filter(pro => pro.priority);

    const approvedIndex = prosData.approved.findIndex(pro => pro.object_type == 'dynamic_sa');
    const favoritedIndex = prosData.favorited.findIndex(pro => pro.object_type == 'dynamic_sa');
    const blockedIndex = prosData.blocked.findIndex(pro => pro.object_type == 'dynamic_sa');
    let removedElement: any[];
    if (approvedIndex >= 0) {
      removedElement = prosData.approved.splice(approvedIndex, 1);
    }
    if (favoritedIndex >= 0) {
      removedElement = prosData.favorited.splice(favoritedIndex, 1);
    }
    if (blockedIndex >= 0) {
      removedElement = prosData.blocked.splice(blockedIndex, 1);
    }
    if (removedElement) {
      const indexToAdd = 0;
      if (this.initialFindNewProsValue) {
        prosData.blocked.splice(indexToAdd, 0, ...removedElement);
      } else {
        prosData.approved.splice(indexToAdd, 0, ...removedElement);
      }
    }
    try {
      const serviceTypeId = 1;
      await this.pros.updateProsPriorities(prosData, serviceTypeId);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  populateFields() {
    this.genderOptions = [
      {
        viewValue: 'Best Available',
        value: 'any'
      },
      {
        viewValue: 'Male Only',
        value: 'male'
      },
      {
        viewValue: 'Female Only',
        value: 'female'
      }
    ];
    this.form = this.fb.group({
      gender: [this.settings.homekeeper_preferences.gender, Validators.required]
    });
  }

  async requestCategory() {
    try {
      const payload = {
        key: 'find-new-pro/' + this.categoryId
      }
      await this.client.requestUpcomingFeature(payload);
      const message = 'Feature requested! TIDY will let you know when this feature is available for you.';
      this.util.showSuccess(message)
      this.rightSidePanelService.navigateTo('job-request-workflows');
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const message = 'Feature alredy requested. TIDY will let you know when this feature is available for you.';
        this.util.showSuccess(message)
        this.rightSidePanelService.navigateTo('job-request-workflows');
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  goToBookDemo() {
    this.util.openUrl('https://www.tidy.com/get-demo');
  }

}

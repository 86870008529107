<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="!type ? 'Log Issue' : getTitle()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="!addressId">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Where do you want to log the issue?'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/home-outline.svg'"
                [label]="'Select property'"
                [form]="form.controls.address"
                formControlName="address"
                errorMessage="Please select a property."
                [submitted]="addressSubmitted"
                [items]="addressFilter">
              </tidy-select>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-button
          [text]="'Next'"
          [action]="selectAddress.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>

      <ng-container *ngIf="!type && addressId">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'What do you want to log?'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-card-button (action)="selectType('damage')">
            <tidy-row>
              <tidy-text
                [header]="'Damage'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectType('low_inventory')">
            <tidy-row>
              <tidy-text
                [header]="'Low Inventory'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectType('pest_report')">
            <tidy-row>
              <tidy-text
                [header]="'Pest Report'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectType('utility_issue')">
            <tidy-row>
              <tidy-text
                [header]="'Utility Issue'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectType('other')">
            <tidy-row>
              <tidy-text
                [header]="'Other'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="type && addressId">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'After logging an issue you can mark it as resolved.'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <form [formGroup]="form">
            <tidy-row class="field-top-padding">
              <tidy-input
                [label]="'Description'"
                [icon]="'assets/svg/information-circle-outline.svg'"
                formControlName="description"
                [form]="form.controls.description"
                [submitted]="submitted"
                [errorMessage]="'Please enter a description.'">
              </tidy-input>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-row>
          <tidy-text
            [actionBody]="'Add Photo'"
            (action)="addAttachment()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="attachments?.length" class="field-top-padding">
          <tidy-row>
            <tidy-text
              [body]="'Photos:'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let attachment of attachments; let i = index">
            <tidy-text
              [body]="'Photo'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(i + 1) + ' '">
            </tidy-text>
            <tidy-text
              [actionBody]="'remove'"
              (action)="removeAttachment(i)"
              class="link red">
            </tidy-text>
          </tidy-row>
        </tidy-row>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Log Issue'"
          [action]="send.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

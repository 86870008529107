<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="'Schedule'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper
    [hasBottomNav]="true"
    (contentHeight)="contentHeight = $event"
    [ngClass]="{'fab-page': loaded && cards?.length > 0 && !hasOnlyReservations && !hasOnlyCrafRequests && !isAllAddressesSelected}">

    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-schedule-weekly-filter
      [loaded]="loaded"
      [addresses]="addressResponse"
      [selectedPeriodRange]="selectedPeriodRange"
      (onFilter)="onFilterChanges($event)"
      (onSearch)="onSearchChanges($event)"
      (onGoToPreviousPeriod)="goToPreviousPeriod()"
      (onGoToNextPeriod)="goToNextPeriod()"
      (onChangeScheduleViewMode)="changeScheduleViewMode($event)"
      [showLoadingSpinner]="showLoadingSpinner">
    </tidy-schedule-weekly-filter>

    <div></div>

    <ng-container *ngIf="scheduleViewMode === 'MONTHLY'">
      <tidy-schedule-monthly-view
        [properties]="schedule.properties"
        [selectedPeriodRange]="selectedPeriodRange"
        [isLoading]="isLoadingSchedule"
        (onClickAction)="handleClickActionMonthlyView($event)">
      </tidy-schedule-monthly-view>
    </ng-container>
    <ng-container *ngIf="isScheduleDataLoaded">
      <ng-container *ngIf="scheduleViewMode === 'SEVEN_DAYS' || scheduleViewMode === 'WEEK_MON_SUN'">
        <div class="remove-wrapper-margin">
          <tidy-schedule-weekly-view
            [isMobileResolution]="!windowService?.isDesktopRes"
            [properties]="schedule.properties"
            [daysOfWeek]="daysOfWeek"
            [selectedPeriodRange]="selectedPeriodRange"
            [viewMode]="scheduleViewMode"
            (onClickAction)="handleClickAction($event)"
            (onAssignedPro)="handleAssignedPro($event)">
          </tidy-schedule-weekly-view>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isScheduleDataLoaded && (scheduleViewMode === 'SEVEN_DAYS' || scheduleViewMode === 'WEEK_MON_SUN')">
      <ion-skeleton-text animated="true" style="width: calc(100% + 60px); height: 100vh; margin-left: -20px"></ion-skeleton-text>
    </ng-container>

    <!--<div style="margin-bottom: 100px" [ngStyle]="!windowService?.isDesktopRes ? {'margin-top': '-80px'} : {}"  *ngIf="addressResponse?.length == 1 && !addressResponse[0]?.integration?.name && !hasJobsAtOnlyAddress">
      <tidy-row class="extra-top-padding extra-bottom-padding">
        <tidy-text
          [header]="'Start Using Your Calendar:'">
        </tidy-text>
      </tidy-row>

      <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px">
        <tidy-card-button (action)="bookJobSelectAddress()">
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/book-job.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Instantly Book'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Book or manage your pros instantly, or find a new one.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card class="vertical-align-center" style="padding: 15px 15px 15px 15px" *ngIf="isRentalClient">
        <tidy-card-button (action)="goToAllIntegrations()">
          <tidy-row>
            <ion-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration.svg'"
                  style="width:40px;height:50px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Sync Guest/Tenant Calendar'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Integrate Airbnb or a property management tool to get guest check-ins or tenant move outs synced to your calendar, to make automation easy.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </div>-->


  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<tidy-fab class="bottom-nav-padding" [isRightSidePanelButton]="false" [top]="contentHeight" [disableToggle]="true" *ngIf="!windowService?.isDesktopRes">
  <tidy-fab-button class="main-btn" (click)="onFABButtonClick()">
    <tidy-image src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text largeBody="Add Job" class="white"></tidy-text>
  </tidy-fab-button>
</tidy-fab>

<tidy-bottom-nav activeRoute="/schedule">
</tidy-bottom-nav>

<tidy-onboarding-modal
  *ngIf="didCheckOnboarding && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'property'">
</tidy-onboarding-modal>


<tidy-row class="extra-bottom-padding">
  <tidy-text
    [body]="'Smart maintenace helps you track and maintain your property\'s assets. Add your appliances and other important items to get started:'">
  </tidy-text>
</tidy-row>

<div *ngFor="let item of items; last as isLast; let i = index">
  <select
    [(ngModel)]="item.item"
    [ngModelOptions]="{standalone: true}"
    style="padding: 8px; border: 1px solid #E4E6E9; border-radius: 4px; font-family: 'Inter',Arial,sans-serif; text-align: left;">
    <option *ngFor="let category of categoriesItems" [value]="category.value">{{category.viewValue}}</option>
  </select>
</div>

<tidy-row [align]="'center'" class="extra-bottom-padding">
  <tidy-text
    [actionBody]="'+ Add Item'"
    (action)="addItem()"
    class="link">
  </tidy-text>
</tidy-row>

<tidy-button
  [text]="'Save Items'"
  (click)="saveItems()"
  class="primary">
</tidy-button>

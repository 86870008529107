<tidy-card>
  <tidy-row class="vertical-align-center extra-bottom-padding">
    <tidy-image
      [src]="'assets/img/complete.svg'"
      class="title-size">
    </tidy-image>
    <tidy-text
      [header]="'Maintenance Plans'">
    </tidy-text>
  </tidy-row>
  <ng-container *ngFor="let maintenance of itemMaintenances | slice:0:showLimiter['itemMaintenances']">
    <tidy-row>
      <tidy-text
        [body]="maintenance?.name">
      </tidy-text><br>
      <tidy-text
        [helper]="(maintenance?.next_do_date  | customdate: 'MMM YYYY')">
      </tidy-text>
    </tidy-row>
  </ng-container>
  <tidy-card-button *ngIf="itemMaintenances.length > 3" (action)="showLimit('itemMaintenances')">
    <tidy-row>
      <tidy-text
        [body]="'Show ' + (showLimiter['itemMaintenances'] == 3? 'more' : 'less') + ' maintenances'">
      </tidy-text>
    </tidy-row>
  </tidy-card-button>
  <tidy-row  *ngIf="itemMaintenances.length === 0">
    <tidy-text
      [helper]="'No maintenance plans'">
    </tidy-text>
  </tidy-row>
</tidy-card>

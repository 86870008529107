<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'more'"
    [title]="'Tasks'"
    [linkIcon]="'create'"
    [showLinkIcon]="true"
    (linkAction)="addIssueReport()"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="!loaded && errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-desktop-header *ngIf="!loaded && windowService.isDesktopRes">
      <tidy-text
        [title]="'Tasks'">
      </tidy-text>
      <ng-container>
        <ion-skeleton-text
          animated="true"
          style="
            width: 625px;
            height: 27px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <tidy-tasks-filter
      *ngIf="loaded"
      [addresses]="addressResponse"
      [issueReportTypes]="issueReportTypes"
      (onFilter)="onFilterChanges($event)"
      (onSearch)="onSearchChanges($event)"
      (onAddIssue)="addIssueReport()"
      [showLoadingSpinner]="showLoadingSpinner">
    </tidy-tasks-filter>

    <ng-container *ngIf="loaded">

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <div class="issue-columns" cdkDropListGroup *ngIf="windowService.isDesktopRes">
        <ng-container *ngFor="let column of columns">
          <div [id]="column" class="issue-columns_column" cdkDropList (cdkDropListDropped)="dropped($event)">
            <div class="issue-columns_column_column-title">
              <tidy-text
                header="{{columnTitlesByKeys[column]}}">
              </tidy-text>
            </div>
            <cdk-virtual-scroll-viewport itemSize="70" class="example-viewport" *ngIf="loaded">
              <tidy-card
                (click)="goToTask(issue)"
                id="issue-card-{{issue?.id}}"
                cdkDrag
                [cdkDragData]="issue"
                [ngClass]="{'dragging-preview': isDragging}"
                (cdkDragStarted)="isDragging = true"
                (cdkDragEnded)="isDragging = false"
                class="issue-card"
                *cdkVirtualFor="let issue of issuesColumns[column]; trackBy: trackByIssue">
                <tidy-row [alignLastItemRight]="true">
                  <tidy-text
                    [ngStyle]="issue?.checkedUsers?.length > 1 ? {'max-width': '15ch'} : {'max-width': '50ch'}"
                    [header]="issue?.title">
                  </tidy-text>
                  <div style="display: flex; align-items: center; justify-content: flex-end;">
                    <tidy-image
                      *ngIf="!issue?.checkedUsers?.length"
                      mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" #desktopUserMenuTrigger="matMenuTrigger"
                      [src]="'assets/img/assign.svg'"
                      class="header-size thirty-percent-opacity button-hover no-right-margin">
                    </tidy-image>
                    <div *ngIf="getUserIcon(issue?.checkedUsers) == 'user'" class="tasks-initials" [ngClass]="!issue?.checkedUsers?.length ? 'thirty-percent-opacity button-hover' : 'button-hover'" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" #desktopUserMenuTrigger="matMenuTrigger">
                      {{(issue?.checkedUsers[0]?.name | slice:0:1).toUpperCase()}}
                    </div>
                    <tidy-image
                      *ngIf="getUserIcon(issue?.checkedUsers) == 'concierge'"
                      class="button-hover no-right-margin" style="width: 18px" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" #desktopUserMenuTrigger="matMenuTrigger"
                      [src]="'assets/img/concierge.svg'">
                    </tidy-image>
                    <tidy-text
                      style="margin-left: 3px"
                      *ngIf="issue?.checkedUsers?.length > 1"
                      [body]="'+' + (issue?.checkedUsers?.length - 1)">
                    </tidy-text>
                    <mat-menu #menu="matMenu">
                      <ng-container *ngFor="let user of issue?.allUsers">
                        <button mat-menu-item>
                          <mat-checkbox
                            class="checkbox"
                            [checked]="user?.checked"
                            (click)="$event.stopPropagation()"
                            (change)="checkOrUncheckPerson(issue, user, $event)">
                            {{user?.name}}
                          </mat-checkbox>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </tidy-row>
                <tidy-row class="no-padding" [alignLastItemRight]="true">
                  <tidy-image
                    class="button-hover" mat-icon-button [matMenuTriggerFor]="urgencyMenu" #urgencyMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()"
                    [src]="getIssueHeaderIcon(issue)"
                    class="urgency-icon button-hover">
                  </tidy-image>
                  <mat-menu #urgencyMenu="matMenu">
                    <ng-container *ngFor="let urgency of urgencies">
                      <button mat-menu-item class="tree-node-padding" (click)="selectUrgency(issue, urgency, $event)">
                        <tidy-image
                          [src]="urgency.icon"
                          class="header-size">
                        </tidy-image>
                        {{urgency?.viewValue}}
                      </button>
                    </ng-container>
                  </mat-menu>
                  <tidy-text
                    mat-icon-button class="no-ripple button-hover" [matMenuTriggerFor]="addressMenu" #addressMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()"
                    [body]="getAddressName(issue)">
                  </tidy-text>
                  <mat-menu #addressMenu="matMenu">
                    <ng-container *ngFor="let address of addressFilter">
                      <button mat-menu-item class="tree-node-padding" (click)="selectAddress(issue, address, $event)">
                        {{address?.viewValue}}
                      </button>
                    </ng-container>
                  </mat-menu>
                  <div>
                    <tidy-image
                      *ngIf="!issue?.checkedJobs?.length"
                      mat-icon-button [matMenuTriggerFor]="jobMenu" (click)="showJobMenu($event, issue)"
                      [src]="'assets/img/calendar-plus.svg'"
                      [ngClass]="!issue?.checkedJobs?.length ? 'thirty-percent-opacity header-size no-right-margin button-hover' : 'header-size no-right-margin button-hover'">
                    </tidy-image>
                    <ion-chip class="job-chip"*ngIf="issue?.checkedJobs?.length" mat-icon-button [matMenuTriggerFor]="jobMenu" (click)="showJobMenu($event, issue)">
                      <tidy-image
                        [src]="issue?.checkedJobs[0]?.icon_url"
                        style="height: 25px">
                      </tidy-image>
                      <tidy-image
                        *ngIf="issue?.checkedJobs[0]?.status?.icon_type == 'image'"
                        [src]="issue?.checkedJobs[0]?.status?.icon"
                        [ngClass]="issue?.checkedJobs[0]?.status?.class ? issue?.checkedJobs[0]?.status?.class : ''"
                        style="height: 25px">
                      </tidy-image>
                      <div
                        *ngIf="issue?.checkedJobs[0]?.status?.icon_type == 'initials'"
                        [ngClass]="issue?.checkedJobs[0]?.status?.class">
                        {{(issue?.checkedJobs[0]?.status?.initials).toUpperCase()}}
                      </div>
                    </ion-chip>
                    <mat-menu #jobMenu="matMenu">
                      <ng-container *ngFor="let job of issue?.allJobs">
                        <button mat-menu-item>
                          <mat-checkbox
                            class="checkbox"
                            (click)="$event.stopPropagation()"
                            [checked]="job?.checked"
                            (change)="checkOrUncheckJob(issue, job, $event)">
                            {{job?.name}}
                          </mat-checkbox>
                        </button>
                      </ng-container>
                      <div *ngIf="!issue?.allJobs?.length && issue?.allJobs" style="margin-top: 10px; margin-left: 10px; margin-right: 10px !important">
                        <tidy-text
                          [helper]="'<i>No Jobs Booked</i>'">
                        </tidy-text>
                      </div>
                      <div *ngIf="!issue?.allJobs" style="padding-bottom: 10px; margin-top: 10px; margin-left: 10px; margin-right: 10px !important">
                        <tidy-text
                          [helper]="'<i>Loading...</i>'">
                        </tidy-text>
                      </div>
                    </mat-menu>
                  </div>
                </tidy-row>
              </tidy-card>

            </cdk-virtual-scroll-viewport>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="!windowService.isDesktopRes">
        <div style="display: flex; justify-content: space-between; margin-top: 10px; gap: 5px">
          <div class="desktop-nav-button">
            <tidy-button
              [action]="updatedStatusFilter.bind(this, 'reported')"
              text="Reported ({{issuesColumns['reported']?.length || 0}})"
              [smallBody]="true">
            </tidy-button>
          </div>
          <div class="desktop-nav-button">
            <tidy-button
              [action]="updatedStatusFilter.bind(this, 'approved')"
              text="Approved ({{issuesColumns['approved']?.length || 0}})"
              [smallBody]="true">
            </tidy-button>
          </div>
          <div class="desktop-nav-button">
            <tidy-button
              [action]="updatedStatusFilter.bind(this, 'in_progress')"
              text="In Progress ({{issuesColumns['in_progress']?.length || 0}})"
              [smallBody]="true">
            </tidy-button>
          </div>
          <div class="desktop-nav-button">
            <tidy-button
              [action]="updatedStatusFilter.bind(this, 'done')"
              text="Done ({{issuesColumns['done']?.length || 0}})"
              [smallBody]="true">
            </tidy-button>
          </div>
        </div>

        <cdk-virtual-scroll-viewport itemSize="70" class="example-viewport" *ngIf="loaded">
          <ng-container
            id="issue-card-{{issue?.id}}"
            class="drag-item issue-card"
            *cdkVirtualFor="let issue of issuesColumns[selectedStatus]">
            <tidy-card
              (click)="goToTask(issue)"
              class="drag-item issue-card-mobile">
              <div class="task-title-row" [ngStyle]="issue?.title?.length > 50 ? {'margin-bottom': '0px'} : {}">
                <tidy-text
                  [ngStyle]="issue?.checkedUsers?.length <= 1 ? {'max-width': '50ch'} : {'max-width': '20ch'}"
                  [header]="issue?.title">
                </tidy-text>

                <!--Assigning Users Mobile-->
                <div class="task-assign-user" [ngStyle]="issue?.checkedUsers?.length > 1 ? {'margin-left': '-60px'} : {'margin-right': '-7px'}">
                  <button [ngClass]="!issue?.checkedUsers?.length ? 'thirty-percent-opacity button-hover' : 'button-hover'" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" #mobileUserMenuTrigger="matMenuTrigger">
                    <tidy-image
                      *ngIf="!issue?.checkedUsers?.length"
                      [src]="'assets/img/assign.svg'"
                      class="header-size">
                    </tidy-image>
                    <div *ngIf="getUserIcon(issue?.checkedUsers) == 'user'" class="tasks-initials">
                      {{(issue?.checkedUsers[0]?.name | slice:0:1).toUpperCase()}}
                    </div>
                    <tidy-image
                      *ngIf="getUserIcon(issue?.checkedUsers) == 'concierge'"
                      [src]="'assets/img/concierge.svg'"
                      style="width: 18px">
                    </tidy-image>
                  </button>
                  <tidy-text
                    style="margin-left: -5px"
                    *ngIf="issue?.checkedUsers?.length > 1"
                    [body]="'+' + (issue?.checkedUsers?.length - 1)">
                  </tidy-text>
                  <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let user of issue?.allUsers">
                      <button mat-menu-item>
                        <mat-checkbox
                          class="checkbox"
                          [checked]="user?.checked"
                          (click)="$event.stopPropagation()"
                          (change)="checkOrUncheckPerson(issue, user, $event)">
                          {{user?.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>

              <tidy-row [alignLastItemRight]="true" [ngStyle]="showInitialsIcon ? {'margin-top': '-5px'} : {}">

                <div class="task-urgency-address-row vertical-align-center" style="padding-left: 15px">
                  <tidy-image
                    [src]="getIssueHeaderIcon(issue)"
                    class="urgency-icon">
                  </tidy-image>

                  <tidy-text
                    [body]="getAddressName(issue)">
                  </tidy-text>
                </div>

                <div *ngIf="!issue?.allJobs?.length"></div>

                <!--Assigning Jobs-->
                <div [ngStyle]="issue?.checkedJobs?.length ? (issue?.checkedJobs?.length > 1 ? {'margin-right': '5px'} : {'margin-right': '10px'}) : {'margin-right': '-4px'}">
                  <button [ngClass]="!issue?.checkedJobs?.length ? 'thirty-percent-opacity button-hover' : 'button-hover'" mat-icon-button [matMenuTriggerFor]="jobMenu" (click)="showJobMenu($event, issue)">
                    <tidy-image
                      *ngIf="!issue?.checkedJobs?.length"
                      [src]="'assets/img/calendar-plus.svg'"
                      class="header-size">
                    </tidy-image>
                    <ion-chip *ngIf="issue?.checkedJobs?.length" [ngStyle]="issue?.checkedJobs[0]?.status?.icon_type == 'image' ? {'margin-left': '-12px'} : {'margin-left': '-20px'}">
                      <tidy-image
                        [src]="issue?.checkedJobs[0]?.icon_url"
                        style="height: 18px">
                      </tidy-image>
                      <tidy-image
                        *ngIf="issue?.checkedJobs[0]?.status?.icon_type == 'image'"
                        [src]="issue?.checkedJobs[0]?.status?.icon"
                        [ngClass]="issue?.checkedJobs[0]?.status?.class ? issue?.checkedJobs[0]?.status?.class : ''"
                        style="height: 18px">
                      </tidy-image>
                      <div
                        *ngIf="issue?.checkedJobs[0]?.status?.icon_type == 'initials'"
                        [ngClass]="issue?.checkedJobs[0]?.status?.class">
                        {{(issue?.checkedJobs[0]?.status?.initials).toUpperCase()}}
                      </div>
                    </ion-chip>
                  </button>
                  <tidy-text
                    style="margin-left: 10px"
                    *ngIf="issue?.checkedJobs?.length > 1"
                    [body]="'+' + (issue?.checkedJobs?.length - 1)">
                  </tidy-text>
                  <mat-menu #jobMenu="matMenu">
                    <ng-container *ngFor="let job of issue?.allJobs">
                      <button mat-menu-item>
                        <mat-checkbox
                          class="checkbox"
                          (click)="$event.stopPropagation()"
                          [checked]="job?.checked"
                          (change)="checkOrUncheckJob(issue, job, $event)">
                          {{job?.name}}
                        </mat-checkbox>
                      </button>
                    </ng-container>
                    <div *ngIf="!issue?.allJobs?.length && issue?.allJobs" style="margin-top: 10px; margin-left: 10px; margin-right: 10px !important">
                      <tidy-text
                        [helper]="'<i>No Jobs Booked</i>'">
                      </tidy-text>
                    </div>
                    <div *ngIf="!issue?.allJobs" style="padding-bottom: 10px; margin-top: 10px; margin-left: 10px; margin-right: 10px !important">
                      <tidy-text
                        [helper]="'<i>Loading...</i>'">
                      </tidy-text>
                    </div>
                  </mat-menu>
                </div>

              </tidy-row>
              <tidy-row *ngIf="issue?.due_date" class="vertical-align-center" style="margin-left: 5px; margin-bottom: 5px">
                <tidy-image
                  [src]="'assets/svg/calendar.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [smallBody]="getIssueDueDate(issue?.due_date)"
                  [ngClass]="{'red': isDueDateInPast(issue?.due_date)}">
                </tidy-text>
              </tidy-row>
            </tidy-card>
          </ng-container>
        </cdk-virtual-scroll-viewport>

        <tidy-alert *ngIf="!issuesColumns[selectedStatus].length">
          <tidy-row>
            <tidy-text
              [body]="'No'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="selectedStatus | removeUnderscore">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'tasks'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded && windowService.isDesktopRes">
      <div class="issue-columns">
        <ng-container *ngFor="let column of ['Reported','Approved','In Progress','Done']">
          <div class="issue-columns_column">
            <div class="issue-columns_column_column-title">
              <tidy-text
                [header]="column">
              </tidy-text>
            </div>
            <cdk-virtual-scroll-viewport itemSize="70" class="example-viewport">
              <tidy-card
                class="drag-item issue-card"
                *ngFor="let issue of
                column == 'Reported' ?
                [1,2,3,4] :
                (
                  column == 'Approved' ?
                  [1,2,3,4,5,6] :
                  (
                    column == 'In Progress' ?
                    [1,2,3] :
                    [1,2,3,4,5,6,7,8,9,10,11,12,13,14]
                  )
                )"
                style="padding: 0px; height: 74px; padding: 0px;">
                <ion-skeleton-text animated="true" style="width: 100%; height: 74px; border-radius: 6px; margin: 0;"></ion-skeleton-text>
              </tidy-card>
            </cdk-virtual-scroll-viewport>
          </div>
        </ng-container>
      </div>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="!isMobileResolution" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

<!--<tidy-onboarding-modal
  *ngIf="loaded && showOnboarding"
  #onboardingModal
  [hasProperties]="hasProperties"
  [page]="'tasks'">
</tidy-onboarding-modal>-->
import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class SubstituteResquest {
  constructor(
    private httpClient: HttpClientCustom,
  ) { }


  async getSubstituteTimes(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/substitute-times`;
    const times = await this.httpClient.get(url);
    return this.groupDates(times);
  }

  groupDates(times) {
    const groupedDates = [];

    let lastDate;
    let groupIndex = -1;

    times.map((elem) => {
      if ( lastDate === elem.cleaning.date) {
        groupedDates[groupIndex].times.push(elem);
      } else {
        lastDate = elem.cleaning.date;
        groupIndex++;
        groupedDates[groupIndex] = {'date': lastDate, times: []} ;
        groupedDates[groupIndex].times.push(elem);
      }
    });

    return groupedDates;
  }

}

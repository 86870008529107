import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'request-integration.html'
})

export class RequestIntegrationPage extends TimeoutableComponent implements OnInit {

  addressId: any;
  errorMessage: string;
  form: UntypedFormGroup;
  pageTitle: string;
  selectedIntegration: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
    this.form = this.fb.group({
      note: ['', Validators.required],
      numberOfProperties: ['', Validators.required],
      notesForConcierge: ['']
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.addressId = await this.getParam('addressId');
    this.selectedIntegration = await this.getParam('reservationSrcType');
    this.getPageText();
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    if (this.selectedIntegration.type == 'accounting') {
      this.setAccountingForm();
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  getPageText() {
    if (this.selectedIntegration.type == 'locks') {
      this.pageTitle = 'Add Lock Integration';
    } else if (this.selectedIntegration.type == 'thermostats') {
      this.pageTitle = 'Add Thermostat Integration';
    } else if (this.selectedIntegration.type == 'accounting') {
      this.pageTitle = 'Add Accounting Integration';
    }
  }

  setAccountingForm() {
    this.form.controls.notesForConcierge.setValidators([Validators.required]);
    this.form.controls.notesForConcierge.updateValueAndValidity();
    this.form.controls.note.clearValidators();
    this.form.controls.note.updateValueAndValidity();
    this.form.controls.numberOfProperties.clearValidators();
    this.form.controls.numberOfProperties.updateValueAndValidity();
  }

  async requestIntegration() {
    try {
      const payload = {
        key: this.selectedIntegration.type,
        note: `Address ID: ${ this.addressId || 'No Address Selected'} / Selected integration: ${this.selectedIntegration} / Instructions for vendors: ${this.form.value.notes} / # of properties: ${this.form.value.numberOfProperties}`
      }
      await this.client.requestUpcomingFeature(payload);
      const params = await this.mountSuccessPage();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch(err) {
      if (err.message === 'You have already voted on this feature!') {
        const params = await this.mountAlreadyRequestedSuccessPage();
        this.rightSidePanelService.navigateTo('success', params, SuccessPage);
      } else {
        this.timeoutHandler(err);
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  async mountSuccessPage() {
    return {
      header: 'Integration Requested',
      body: 'TIDY Concierge will reach out in the next 1 business day to help set up your integration.',
      buttonText: 'Ok',
      buttonRoute: await this.getParam('nextPagePath')
    };
  }

  async mountAlreadyRequestedSuccessPage() {
    return {
      header: 'Integration Already Requested',
      body: 'You already requested to add this integration so our Concierge will reach out to you. Please reach out with any questions.',
      buttonText: 'Ok',
      buttonRoute: await this.getParam('nextPagePath')
    };
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Apply List to Jobs'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Use'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="listName">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'To-Dos List for:'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let job of jobs; let i = index">
        <tidy-card *ngIf="showJob(i)">
          <tidy-row [alignLastItemRight]="true">
            <tidy-checkbox
              [items]="[{viewValue: job?.template_data?.booking?.bookable_service?.name, value: '' }]"
              [initValue]="job?.template_data?.job?.task_list?.title === listName"
              (checkedChange)="selectJob(job?.template_data?.job?.id)">
            </tidy-checkbox>
            <div>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                ' ' +
                (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
              </tidy-text><br>
              <tidy-text
                [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
              </tidy-text>
            </div>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <tidy-row [align]="'center'" *ngIf="numberJobsToShow === 5 && jobs?.length > 5">
        <tidy-text
          [body]="'Show More Jobs'"
          (action)="showMoreJobs()"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngIf="hasTwoOrMoreLists && toDoState === 'active'">
        <tidy-row>
          <tidy-checkbox
            [items]="[{viewValue: 'Use as default list for future jobs', value: '' }]"
            [initValue]="listIsDefault"
            (checkedChange)="changeDefault()">
          </tidy-checkbox>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [text]="'Save Changes'"
        [action]="saveChanges.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'" *ngIf="toDoState === 'inactive'">
        <tidy-text
          [body]="'You are currently not using To-Dos by default at this address.  Changes here will not affect that setting.'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

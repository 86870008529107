export const desktopNavItems = [
  {
    icon: 'assets/img/menu-dashboard-white.svg',
    activeIcon: 'assets/img/menu-dashboard-green.svg',
    label: 'Dashboard',
    target: '/dashboard',
  },
  {
    icon: 'assets/img/menu-schedule-white.svg',
    activeIcon: 'assets/img/menu-schedule-green.svg',
    label: 'Schedule',
    target: '/schedule',
  },
  {
    icon: 'assets/img/menu-messages-white.svg',
    activeIcon: 'assets/img/menu-messages-green.svg',
    label: 'Messages',
    target: '/messages',
  },
  {
    icon: 'assets/img/menu-to-dos-white.svg',
    activeIcon: 'assets/img/menu-to-dos-green.svg',
    label: 'To-Dos',
    target: '/all-to-do-lists'
  },
  {
    icon: 'assets/img/menu-my-pros-white.svg',
    activeIcon: 'assets/img/menu-my-pros-green.svg',
    label: 'My Pros',
    target: '/my-pros',
  },
  {
    icon: 'assets/img/menu-more-white.svg',
    activeIcon: 'assets/img/menu-more-green.svg',
    label: 'More',
    target: '/more-desktop'
  }
];

export const integrationSectionItems = [
  {
    activeIcon: 'assets/img/integration-images/all-integrations-green.svg',
    icon: 'assets/img/integration-images/all-integrations-white.svg',
    label: 'All Integrations',
    target: 'integrations/all'
  },
  {
    activeIcon: 'assets/img/integration-images/property-management-green.svg',
    icon: 'assets/img/integration-images/property-management-white.svg',
    label: 'Property Management',
    target: 'integrations/property-management'
  },
  {
    activeIcon: 'assets/img/integration-images/booking-channel-green.svg',
    icon: 'assets/img/integration-images/booking-channel-white.svg',
    label: 'Booking Channel',
    target: 'integrations/booking-channel'
  },
  {
    activeIcon: 'assets/img/integration-images/notifications-green.svg',
    icon: 'assets/img/integration-images/notifications-white.svg',
    label: 'Notifications',
    target: 'integrations/notifications'
  },
  {
    activeIcon: 'assets/img/integration-images/accounting-integration-green.svg',
    icon: 'assets/img/integration-images/accounting-integration-white.svg',
    label: 'Accounting',
    target: 'integrations/accounting'
  },
  {
    activeIcon: 'assets/img/integration-images/lock-integration-green.svg',
    icon: 'assets/img/integration-images/lock-integration-white.svg',
    label: 'Locks',
    target: 'integrations/locks'
  },
  {
    activeIcon: 'assets/img/integration-images/thermostat-green.svg',
    icon: 'assets/img/integration-images/thermostat-white.svg',
    label: 'Thermostats',
    target: 'integrations/thermostats'
  }
]

export const automationsSectionItems = [
  {
    icon: 'assets/img/robot-white.svg',
    activeIcon: 'assets/img/robot-green.svg',
    label: 'Automations',
    target: '/automations/summary'
  },
  {
    icon: 'assets/img/workflow-white.svg',
    activeIcon: 'assets/img/workflow-green.svg',
    label: 'Workflows',
    target: '/automations/workflows'
  },
  {
    icon: 'assets/img/history-outline-white.svg',
    activeIcon: 'assets/img/history-outline-green.svg',
    label: 'Workflow History',
    target: '/automations/workflow-history'
  },
  {
    icon: 'assets/img/briefcase-white.svg',
    activeIcon: 'assets/img/briefcase-green.svg',
    label: 'Automatic Booking',
    target: 'automations/automatic-booking',
  },
  {
    activeIcon: 'assets/img/integration-images/notifications-green.svg',
    icon: 'assets/img/integration-images/notifications-white.svg',
    label: 'Notifications',
    target: 'automations/account-notifications'
  }
];

export const tasksItem = {
  icon: 'assets/img/menu-tasks-white.svg',
  activeIcon: 'assets/img/menu-tasks-green.svg',
  label: 'Tasks',
  target: '/tasks',
};

export const automationsItem = {
  icon: 'assets/img/menu-automations-white.svg',
  activeIcon: 'assets/img/menu-automations-green.svg',
  label: 'Automations',
  target: '/automations/summary'
};

export const billsItem = {
  icon: 'assets/img/menu-bills-white.svg',
  activeIcon: 'assets/img/menu-bills-green.svg',
  label: 'Bills',
  target: '/bills',
};

export const inventoryItem = {
  icon: 'assets/img/menu-inventory-white.svg',
  activeIcon: 'assets/img/menu-inventory-green.svg',
  label: 'Restocking',
  target: '/inventory',
};

export const maintenanceItem = {
  icon: 'assets/img/maintenance-header-white.svg',
  activeIcon: 'assets/img/maintenance-header-green.svg',
  label: 'Maintenance',
  target: '/maintenance',
};

export const upsellItem = {
  icon: 'assets/img/upsell-header-white.svg',
  activeIcon: 'assets/img/upsell-header-green.svg',
  label: 'Upsell',
  target: '/upsell',
};

export const moreAutomaticBookingItem = {
  icon: 'assets/img/briefcase-white.svg',
  activeIcon: 'assets/img/briefcase-green.svg',
  label: 'Automatic Booking',
  target: '/automatic-booking',
};

export const vendorComplianceItem = {
  icon: 'assets/img/vendor-compliance-white.svg',
  activeIcon: 'assets/img/vendor-compliance-green.svg',
  label: 'Vendor Compliance',
  target: '/vendor-compliance',
};

export const moreAccountingItem = {
  activeIcon: 'assets/img/integration-images/accounting-integration-green.svg',
  icon: 'assets/img/integration-images/accounting-integration-white.svg',
  label: 'Accounting',
  target: '/integrations/accounting'
};

export const yourBrandingItem = {
  icon: 'assets/img/color-palette.svg',
  activeIcon: 'assets/img/color-palette-green.svg',
  label: 'Your Branding',
  target: '/app-branding',
}

export const auditLogsItem = {
  icon: 'assets/img/history-outline-white.svg',
  activeIcon: 'assets/img/history-outline-green.svg',
  label: 'Audit Logs',
  target: '/audit-logs',
}

export const teamsItem = {
  icon: 'assets/svg/people-outline-white.svg',
  activeIcon: 'assets/svg/people-green.svg',
  label: 'Teams',
  target: '/teams'
}

export const dashboardReportItem = {
  target: 'dashboard/report'
};

export const employerItemsMock = [
  {
    icon: 'assets/img/play-white.svg',
    activeIcon: 'assets/img/play-green.svg',
    label: 'Get Started',
    target: '/employer-get-started',
  },
  {
    icon: 'assets/img/integrate-white.svg',
    activeIcon: 'assets/img/integrate-green.svg',
    label: 'Integrations',
    target: '/employer-integrations',
  },
  {
    icon: 'assets/img/people-white.svg',
    activeIcon: 'assets/img/people-green.svg',
    label: 'Employees',
    target: '/employer-employees',
  },
  {
    icon: 'assets/img/campaign-white.svg',
    activeIcon: 'assets/img/campaign-green.svg',
    label: 'Campaigns',
    target: '/employer-campaign',
  },
  {
    icon: 'assets/img/shield-white.svg',
    activeIcon: 'assets/img/shield-green.svg',
    label: 'Compliance',
    target: '/employer-compliance',
  },
];

export const employerItems = [
  {
    icon: 'assets/img/menu-dashboard-white.svg',
    activeIcon: 'assets/img/menu-dashboard-green.svg',
    label: 'Dashboard',
    target: '/employer-dashboard/employer-dashboard',
  },
  {
    icon: 'assets/img/menu-bills-white.svg',
    activeIcon: 'assets/img/menu-bills-green.svg',
    label: 'Campaigns',
    target: '/employer-campaign/employer-campaign',
  },
  {
    icon: 'assets/img/menu-my-pros-white.svg',
    activeIcon: 'assets/img/menu-my-pros-green.svg',
    label: 'Admins',
    target: '/employer-admins/employer-admins',
  },
  {
    icon: 'assets/img/settings-white.svg',
    activeIcon: 'assets/img/settings-green.svg',
    label: 'Settings',
    target: '/employer-settings',
  },
];

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Logs'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-alert *ngIf="logHistory.length === 0">
        <tidy-row>
          <tidy-text
            [body]="'No Logs Avaliable.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card *ngFor="let log of logHistory">
        <tidy-card-button (action)="goToLogDetails(log)">
          <tidy-row>
            <tidy-text
              [header]="log?.response.status_code + ' '">
            </tidy-text>

            <tidy-text
              [body]="log?.request.method + ' '">
            </tidy-text>

            <tidy-text
              [body]="log?.request.path + ' - '">
            </tidy-text>

            <tidy-text
              [helper]="log?.created_at | customdate:'M/D/YY h:mm a'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

import { GuestReservationSourceType } from 'src/models/guest-reservations';

import { InputComponent } from 'src/tidy-ui-components/components/form/input/input.component';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AddIntegrationPage } from '../add-integration/add-integration';

@Component({
  templateUrl: 'integration-options.html',
  encapsulation: ViewEncapsulation.None
})

export class IntegrationOptionsPage extends TimeoutableComponent {

  @ViewChild('searchInput', {static: false}) searchInput: InputComponent;
  backButton: string;
  form: UntypedFormGroup;
  integrationOptions: Array<GuestReservationSourceType> = [];
  allIntegrationOptionsResponse: Array<GuestReservationSourceType>;
  integrationOptionsResponse: Array<GuestReservationSourceType> = [];
  isRightSideContent = true;

  constructor(
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Add Integration');
      this.loaded = false;
      this.allIntegrationOptionsResponse = await this.guestReservations.getSourceTypes();
      this.backButton = localStorage.getItem('integrationOptionsBackButton') || 'integrations';
      if (this.backButton == 'add-first-property/rental' || this.backButton == 'add-property') {
        this.integrationOptionsResponse = this.filterOnlyDirectItems();
      } else {
        this.integrationOptionsResponse = this.filterOnlyIcalItems();
      }
      this.integrationOptions = this.integrationOptionsResponse;
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  filterOnlyIcalItems() {
    const array: any = [];
    this.allIntegrationOptionsResponse.map((item) => {
      if (item.format == 'icalendar') {
        array.push(item);
      }
    });
    return array;
  }

  filterOnlyDirectItems() {
    const array: any = [];
    this.allIntegrationOptionsResponse.map((item) => {
      if (item.format == 'api') {
        item['formats'] = ['api'];
        array.push(item);
      }
    });
    return array;
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.integrationOptions = this.integrationOptionsResponse;
    }
    const term = search.toLowerCase();
    this.integrationOptions = this.integrationOptions.filter((option) => option.name.toLowerCase().indexOf(term) > -1);
  }

  goToAddIntegration(reservationSrcType: GuestReservationSourceType) {
    const params = {
      allIntegrationOptionsResponse: this.allIntegrationOptionsResponse,
      reservationSrcType: reservationSrcType
    }
    const path = this.backButton == 'integrations' ? 'add-integration' : 'add-direct-integration';
    this.rightSidePanelService.navigateTo(path, params, AddIntegrationPage);
  }

}

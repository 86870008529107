<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Subscription'"
    [canGoBack]="true"
    [customBack]="windowService.isDesktopRes ? 'more/payment-options' : 'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'You are currently on the following subscription:'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="currentSubscription?.plan?.description">
          <tidy-text
            [header]="'Current Subscription'">
          </tidy-text>
          <tidy-text
            *ngIf="currentSubscription?.plan?.description"
            [body]="'Edit'"
            (action)="goToChangeSubscriptionPage()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="currentSubscription?.plan?.description || 'Free Subscription Plan'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="nextSubscription">
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'You changed to a'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="nextSubscription?.plan?.description">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'. This change will take effect on'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(nextSubscription?.next_charge_on | customdate:'M/D/YY') + '.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-two-column-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="(currentSubscription?.plan?.renewal_frequency == 'monthly' ? 'Monthly' : 'Yearly') + ' Bill'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="bill ? (bill | tcurrency) : '$0'">
            </tidy-text>
          </tidy-row>
        </tidy-card>
        <tidy-card item-right>
          <tidy-row class="vertical-align-center">
            <tidy-text
              [header]="'Next Due Date'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="bill == 0 || !bill">
            <tidy-text
              [body]="'N/A'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="bill !== 0">
            <tidy-row>
              <tidy-text
                [body]="currentSubscription?.next_charge_on | customdate:'M/D'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>
      </tidy-two-column-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Please'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'contact'"
          (action)="goToContactConcierge()"
          class="link">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'our concierge for any questions or changes. We are happy to help get it right.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Subscription Billing History'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let invoice of subscriptionInvoices">
        <tidy-row>
          <tidy-text
            [header]="invoice?.subscription?.plan?.description">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/clock.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="'Period:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(invoice?.start_date | customdate:'M/D/YY') + ' - ' + (invoice?.end_date | customdate:'M/D/YY')">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/credit-card.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="(invoice?.paid_amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'paid of'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="invoice?.total_amount | tcurrency">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-alert *ngIf="subscriptionInvoices?.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'No history'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'Compliance'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'We help ensure that if you are taking advantage of tax benefits for remote/hybrid workers, your dollars are being used well.'">
        </tidy-text>
      </tidy-row>

      <tidy-grid>
        <ion-row>
          <ion-col size="4">
            <tidy-card style="height:140px; margin-right:10px">
              <tidy-row>
                <tidy-image
                  [src]="'assets/img/clock-black.svg'"
                  style="width:50px">
                </tidy-image>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [header]="'Jan 6, 2020'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Next Campaign Cycle'">
                </tidy-text>
              </tidy-row>
            </tidy-card>
          </ion-col>
          <ion-col>
            <tidy-card style="height:140px">
              <tidy-grid>
                <ion-row>
                  <ion-col size="5">
                    <tidy-image
                      [src]="'assets/img/99-complete.png'"
                      style="width:110px">
                    </tidy-image>
                  </ion-col>
                  <ion-col>
                    <tidy-row class="vertical-align-center extra-bottom-padding" style="padding-top:10px">
                      <tidy-image
                        [src]="'assets/img/enrolled.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Used Benefit: <b>63</b'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/in-progress.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Pending Use: <b>1</b>'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/img/not-started.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Declined'">
                      </tidy-text>
                    </tidy-row>
                  </ion-col>
                </ion-row>
              </tidy-grid>
            </tidy-card>
          </ion-col>
        </ion-row>
      </tidy-grid>

      <tidy-row [align]="'center'">
        <tidy-text
          [header]="'Per-Employee Compliance'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-table
          [dataSource]="rows"
          [displayedColumns]="headers"
          [columnslabels]="headers">
        </tidy-table>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

import { Injectable } from '@angular/core';
import * as LDClient from 'ldclient-js';
import { GenerateUuid } from '../uuid/uuid';
import { FeatureExperiments } from '../abtest/feature-experiments';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class Launchdarkly {

  private abTestKey = environment.launchdarkly_key;
  ldClient: any;
  flags: any;
  flagChange: Subject<Object> = new Subject<Object>();

  constructor(
    private generateUuid: GenerateUuid,
    private featureExperiments: FeatureExperiments
  ) {
    this.startTest();
  }

  startTest (): void {
    this.generateUuid.createNewHash();
    this.ldClient = LDClient.initialize(this.abTestKey, this.userData());
    this.ldClient.on('ready', this.onReady.bind(this));
    this.ldClient.on('change', this.onChange.bind(this));
  }

  onChange(flags): void {
    this.setActiveFlag(flags);
    this.flagChange.next(this.flags);
  }

  setActiveFlag(flags): void {
    for (const key in this.flags) {
      const item = flags[key];
      if (typeof item !== 'undefined') {
        this.flags[key] = item.current;
      }
    }
  }

  onReady(): void {
    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }

  userData () {
    return {
      'anonymous': true,
      key: localStorage.getItem('uuid')
    };
  }

  async saveTest (data) {
    const authenticated = (localStorage.getItem('customer_id') !== null);
    await this.checkDataToSave(authenticated, data);
  }

  checkDataToSave (authenticated, data) {
    if (authenticated) {
      return this.featureExperiments.saveTest(data);
    } else {
      return this.featureExperiments.saveTestAnonymousUser(data);
    }
  }

}

import { Component, ViewEncapsulation } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ContactInfoPage } from '../more/contact-info/contact-info';

@Component({
  templateUrl: 'call-pro.html',
  encapsulation: ViewEncapsulation.None
})

export class CallProPage {

  clientPhone: any;
  isPrivate: any;
  pro: any;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Call Pro');
    this.loadData();
  }

  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.pro = await this.getParam('pro');
    this.rightSidePanelService.setDialogPageTitle('Call ' + this.pro?.first_name);
    this.isPrivate = await this.getParam('isPrivate');
    this.clientPhone = await this.getParam('clientPhone');
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  callPro() {
    if (this.isPrivate) {
      window.location.href = `tel:${this.pro.phone}`;
    } else {
      window.location.href = `tel:${this.pro.masked_phone_number}`;
    }
  }

  goToAddPhoneNumber() {
    const url = 'contact-info';
    const component = ContactInfoPage;
    this.rightSidePanelService.navigateTo(url, {}, component);
  }

}

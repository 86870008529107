import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { FormGroup, FormControl } from '@angular/forms';
import { WindowService } from 'src/shared/providers/window.service';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { ConfirmRequestPage } from '../confirm-request/confirm-request';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  selector: 'job-request-bids',
  templateUrl: 'job-request-bids.html',
  styleUrls: ['./job-request-bids.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobRequestBidsComponent implements OnInit, OnDestroy {
  isRightSideContent = true;
  dialogParams: any;
  quotes = [];
  topNavHeight: number = 0;
  loaded = false;
  selectedPrice: string;
  selectedDate: string;
  selectedTime: string;
  selectedHomekeeper: string;
  selectedInstantBooking = true;
  dateLabel = 'Select Time';
  service: string;
  bookingDetail: any;
  form = new FormGroup({
    time: new FormControl(null),
  });
  private resizeObserver: ResizeObserver;

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private changeDetectorRef: ChangeDetectorRef,
    public windowService: WindowService
  ) {}

  async ngOnInit() {
    try {
      this.dateLabel = (new TranslationPipe()).transform('Select Time');
      this.isRightSideContent =
        (await this.storage.retrieve('dialog-right-side-open')) || false;
      this.dialogParams =
        (await this.storage.retrieve('dialog-params')) ||
        this.navCtrl.getParams();
      const potentialHomekeepers =
        this.dialogParams.cardDetail?.template_data?.potential_homekeepers || [];
      this.quotes = this.getParsedQuotes(potentialHomekeepers);
      this.rightSidePanelService.setDialogPageTitle(
        (new TranslationPipe()).transform('Job Quotes')
      );
      this.service = this.dialogParams.bookingDetail?.service_type_details?.name || this.dialogParams.cardDetail?.template_data?.job?.service_type_details?.name;
      this.bookingDetail = this.dialogParams.bookingDetail || this.dialogParams.cardDetail?.template_data?.job;
      this.observeTopNavHeight();
      this.loaded = true;
    } catch (error) {
      console.error(error);
    }
  }

  private observeTopNavHeight() {
    const element = document.getElementById('top-nav-height');
    if (element) {
      this.topNavHeight = element.offsetHeight;
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          this.topNavHeight = entry.contentRect.height;
          this.changeDetectorRef.detectChanges();
        }
      });
      this.resizeObserver.observe(element);
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  getParsedQuotes(potentialHomekeepers: any[]) {
    return potentialHomekeepers.map((quote, index) => ({
      ...quote,
      name: quote.first_name + ' ' + quote.last_name,
      amount: quote.amount,
      dates: this.getDatesWithTimes(quote.times, index),
    }));
  }

  getDatesWithTimes(times: any[], quoteIndex: number) {
    const dateMap = new Map<
      string,
      Set<{ viewValue: string; value: string }>
    >();

    times.forEach((time, index) => {
      const [date, timeRange] = time.split('T');
      const startTime = timeRange.split('-')[0].slice(0, 5);

      const [hour, minute] = startTime.split(':').map(Number);
      const period = hour >= 12 ? 'pm' : 'am';
      const hour12 = hour % 12 || 12;
      const formattedTime = `${hour12}:${minute
        .toString()
        .padStart(2, '0')}${period}`;

      if (!dateMap.has(date)) {
        dateMap.set(date, new Set());
      }
      dateMap.get(date)?.add({
        viewValue: formattedTime,
        value: `${startTime}-${quoteIndex}-${index}`,
      });
    });

    return Array.from(dateMap.entries()).map(([date, timesSet]) => ({
      date,
      times: Array.from(timesSet),
    }));
  }

  selectTime(event: any, quote: any, date: any) {
    this.selectedPrice = quote.amount;
    this.selectedDate = date.date;
    this.selectedTime = event.value;
    this.selectedHomekeeper = quote.id;
    this.selectedInstantBooking = quote.instant_booking;
    this.dateLabel =
      quote.name +
      ' - ' +
      new CustomDatePipe().transform(date.date, 'dddd M/D', '') +
      ' ' +
      event.viewValue;
  }

  confirmBooking() {
    const params = {
      ...this.dialogParams,
      viewData: {
        billingType: this.bookingDetail?.service_type_details?.billing_type,
        duration: this.dateLabel,
        frequency: this.bookingDetail?.service_type_details?.frequency,
        image: this.dialogParams.icon,
        price: this.selectedPrice,
        service: this.service,
        homekeeper_id: this.selectedHomekeeper,
        date: this.selectedDate,
        time: this.selectedTime.split('-')[0],
        booking_id: this.bookingDetail.id,
        instant_booking: this.selectedInstantBooking,
      },
    };
    this.rightSidePanelService.navigateTo(
      'confirm-request',
      params,
      ConfirmRequestPage
    );
  }
}

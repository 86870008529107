import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TidyStorage } from './../../../shared/providers/tidy-storage';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Booking } from 'src/providers/booking/booking';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { WindowService } from 'src/shared/providers/window.service';

import validationUtils from 'src/shared/util/validation-utils';
import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  templateUrl: 'add-pro.html',
  encapsulation: ViewEncapsulation.None
})

export class AddProPage implements OnInit {

  addingNewPro: boolean;
  addTypeItems: TidySelectStringValueModel[];
  address: any;
  cameFromGetStartedPage: boolean;
  cameFromAssignPro: boolean;
  categoryId: any;
  creditBalance: number;
  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  hasOtherPros: boolean;
  noAddressSelected: boolean;
  proItems = [];
  propertiesToAddItems: any;
  submitted: boolean;
  salesCards: any;
  selectedProError: boolean;
  serviceCategoryItems: any;
  selectedCategories: any;
  whereToAddItems: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private booking: Booking,
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private windowService: WindowService,
    private pros: Pros,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      phone: ['', [Validators.minLength(10)]],
      addType: [''],
      whereToAdd: [''],
      propertiesToAdd: [''],
      serviceCategories: [''],
      prosToAdd: ['']
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    try {
      this.loaded = false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        const title = this.cameFromAssignPro ? 'Add & Assign Pro' : 'Add Pro';
        this.rightSidePanelService.setDialogPageTitle(title);
      }
      this.noAddressSelected = this.navCtrl.getParam('noAddressSelected') || this.dialogParams?.noAddressSelected;
      this.cameFromGetStartedPage = this.navCtrl.getParam('cameFromGetStartedPage') || this.dialogParams?.cameFromGetStartedPage;
      this.serviceCategoryItems = this.navCtrl.getParam('serviceCategoryItems') || this.dialogParams?.serviceCategoryItems || await this.getServiceCategoryItems();
      this.categoryId = this.navCtrl.getParam('categoryId') || this.dialogParams?.categoryId || 1;
      this.cameFromAssignPro = this.navCtrl.getParam('cameFromAssignPro') || this.dialogParams?.cameFromAssignPro;
      this.selectedCategories = [this.categoryId];
      this.salesCards = this.getSalesCardes();
      this.creditBalance = parseInt(localStorage.getItem('creditBalance'));
      const addresses = await this.client.getAddresses();
      const addressId = await this.client.getSelectedAddressId(addresses);
      this.address = addresses.find(ad => ad.id == addressId)
      if (this.noAddressSelected) {
        this.form.controls.propertiesToAdd.setValidators([Validators.required]);
        this.form.controls.serviceCategories.setValidators([Validators.required]);
        this.form.controls.propertiesToAdd.updateValueAndValidity();
        this.form.controls.serviceCategories.updateValueAndValidity();
        this.getWhereToAddItems(addresses);
      } else {
        this.form.patchValue({ serviceCategories: [this.categoryId]});
      }
      this.addTypeItems = [
        {viewValue: 'New Pro', value: 'new'},
        {viewValue: 'Pro(s) from my other address', value: 'other'}
      ];
      const prosData = await this.pros.getProsExcludingAddress();
      this.hasOtherPros = prosData.length > 0;
      this.addingNewPro = !this.hasOtherPros;
      if (this.hasOtherPros && !this.noAddressSelected) {
        this.updateRequiredFields(this.addTypeItems[1]);
        this.form.patchValue({addType: 'other'});
        prosData.map((pro) => {
          this.proItems.push({viewValue: pro.name, value: pro.id});
        });
      }
      this.loaded = true;
    } catch (err) {
    }
  }

  getWhereToAddItems(addresses) {
    this.whereToAddItems = [
      {
        value: 'all',
        viewValue: 'All Properties'
      },
      {
        value: 'select',
        viewValue: 'Select Properties'
      }
    ];
    this.propertiesToAddItems = [];
    addresses.map((address) => {
      this.propertiesToAddItems.push({
        viewValue: address.address_name ? address.address_name : address.address1,
        value: address.id
      });
    });
    if (addresses.length == 1) {
      this.form.patchValue({ propertiesToAdd : [addresses[0].id] });
    }
    this.form.patchValue({ whereToAdd: 'select' })
  }

  async getServiceCategoryItems() {
    const serviceCategories = await this.pros.getServiceCategories();
    const array: any = [];
    serviceCategories.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.id
      });
    });
    return array;
  }

  async submit() {
    this.errorMessage = '';
    this.submitted = true;
    if (this.form.value.whereToAdd == 'all') {
      this.form.controls.propertiesToAdd.clearValidators();
      this.form.controls.propertiesToAdd.updateValueAndValidity();
      this.form.value.propertiesToAdd = this.getAllProperties();
    }
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.prosToAdd.length > 0) {
      await this.addProsFromOtherAddress();
    } else {
      await this.addNewPro();
    }
  }

  getAllProperties() {
    return this.propertiesToAddItems.map((item) => {
      return item.value;
    });
  }

  updateRequiredFields(type) {
    const fields =  ['name', 'email'];
    if (type.value == 'new') {
      this.addingNewPro = true;
      fields.forEach(field => {
        this.form.controls[field].setValidators(Validators.required);
        this.form.controls[field].updateValueAndValidity();
      });
    } else {
      this.addingNewPro = false;
      fields.forEach(field => {
        this.form.controls[field].clearValidators();
        this.form.controls[field].updateValueAndValidity();
      });
    }
  }

  async addNewPro() {
    try {
      const params = {
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
        add_to_address_homekeeper_list: {
          address_ids: this.noAddressSelected ? this.form.value.propertiesToAdd : [this.address.id],
          service_type_ids: this.form.value.serviceCategories
        }
      };
      if(!this.form.value.phone) {
        delete params.phone;
      }
      const pro = await this.pros.addNewPro(params);
      this.storage.save('didAddPro', true);
      this.storage.save('shouldFetchPros', true);
      localStorage.setItem('hasPrivatePro', 'true');
      if (this.cameFromAssignPro) {
        const proDetails = await this.pros.getProDetail(pro.id);
        const bookingId = await this.navCtrl.getParam('bookingId') || this.dialogParams.bookingId;
        await this.booking.assignPrivatePro(bookingId, proDetails.team.id);
      }
      const addressId = this.noAddressSelected ? this.form.value.propertiesToAdd[0] : this.address.id;
      if (this.cameFromGetStartedPage) {
        this.rightSidePanelService.navigateTo('get-started-pro-added', {pro: pro, address: addressId});
      } else {
        this.rightSidePanelService.navigateTo('pro-added', {pro: pro, address: addressId});
      }
    } catch ( err ) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async addProsFromOtherAddress() {
    try {
      const payload = {
        service_type_id: this.categoryId,
        homekeeper_ids: this.form.value.prosToAdd,
      }
      await this.pros.addExistingPros(payload, this.address.id);
      this.storage.save('shouldFetchPros', true);
      const addedMultiple = this.form.value.prosToAdd.length > 1;
      if (addedMultiple) {
        this.showSuccessAddedPage();
      } else {
        const proData = this.proItems.find((pro) => pro.value == this.form.value.prosToAdd[0]);
        const pro = {
          first_name: proData.viewValue,
          id: proData.value
        }
        if (this.cameFromGetStartedPage) {
          this.rightSidePanelService.navigateTo('get-started-pro-added', {pro: pro, address: this.address});
        } else {
          this.rightSidePanelService.navigateTo('pro-added', {pro: pro, address: this.address});
        }
      }
    } catch ( err ) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  showSuccessAddedPage() {
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows'
    const params = {
      header: 'Pros Added',
      body: '',
      buttonText: 'View Pros',
      buttonRoute: url
    };
    this.rightSidePanelService.navigateTo('success', params);
  }

  learnMoreAddPros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  getSalesCardes() {
    if (this.cameFromGetStartedPage) {
      return [
        {
          title: 'Pro is Added to Your Priorities',
          text: 'Book jobs, send requests, or send them payment for a completed job.',
          image: 'assets/img/priority.svg'
        },
        {
          title: 'No Sign Up Necessary',
          text: 'Send a payment to your pro and they\'ll receive an email to accept it.',
          image: 'assets/img/send.svg'
        },
        {
          title: 'Send Payment Now or Later',
          text: 'Send a payment for a past job or book one for the future.',
          image: 'assets/img/payment-method.svg'
        }
      ];
    } else {
      return [
      {
        title: 'Pro is Added to Your Priorities',
        text: 'When you have a job that needs a pro, we\'ll send them a job request per your priorities.',
        image: 'assets/img/priority.svg'
      },
      {
        title: 'No Sign Up Necessary',
        text: 'Pros will start getting notifications to accept your jobs by email or SMS. They don\'t need to sign up first or download anything.',
        image: 'assets/img/send.svg'
      },
      {
        title: 'Pros Easily Accept',
        text: 'Pros accept with a tap, like an eSignature. You get notified when they accept, and can see it in TIDY.',
        image: 'assets/img/checkmark-calendar.svg'
      },
      {
        title: 'We Can Help',
        text: 'We can make your pro comfortable with your process. Just schedule a 5-minute call with us to set this up, it\'s free.',
        image: 'assets/img/help.svg'
      }
    ];
    }
  }

  goToBookCall() {
    const url = 'https://calendly.com/tidy/tidy-adding-pro-help';
    this.util.openUrl(url);
  }

  goToAddConciergeTask() {
    const params = {
      title: 'Concierge Action',
      type: 'support.concierge_task',
      isAddingPro: true
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

}

<tidy-card>
  <tidy-card-button (action)="goToUploadAccessNotes()">
    <ion-row>
      <tidy-row class="vertical-align-center">
        <!--TODO add a better icon-->
        <tidy-image [src]="'assets/img/integrate.svg'" style="width: 20px">
        </tidy-image>
      </tidy-row>
      <ion-col>
        <tidy-row>
          <tidy-text [header]="'Upload Access Notes'"> </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Upload a CSV file with access notes for your property.'">
          </tidy-text>
        </tidy-row>
      </ion-col>
    </ion-row>
  </tidy-card-button>
</tidy-card>

<tidy-row class="extra-bottom-padding">
  <tidy-text [helper]="'or'" class="divider"> </tidy-text>
</tidy-row>

<tidy-alert>
  <tidy-row class="vertical-align-center">
    <tidy-image
      [src]="'assets/img/alert.svg'">
    </tidy-image>
    <tidy-text
      [header]="'Parking & Access Info Helps!'">
    </tidy-text>
  </tidy-row>
  <tidy-row>
    <tidy-text
      [body]="'Details help jobs happen smoothly.'">
    </tidy-text>
  </tidy-row>
</tidy-alert>

<ng-container *ngFor="let form of forms.controls">
  <tidy-row class="extra-top-padding">
    <tidy-text [header]="form.value.address_name"> </tidy-text>
  </tidy-row>

  <form [formGroup]="form">
    <tidy-card class="extra-top-padding">
      <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
        <tidy-image [src]="'assets/img/car.svg'" class="title-size"> </tidy-image>
        <tidy-text [header]="'How to Park'"> </tidy-text>
        <tidy-text [body]="'Add Photo'" (action)="getImg(form, 'parking')" class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row class="field-top-padding field-bottom-padding">
        <tidy-select
          [label]="'What kind of parking?'"
          [form]="form.controls.paid_parking"
          formControlName="paid_parking"
          [items]="parkingTypes"
          (optionChange)="clearFields(form)">
        </tidy-select>
      </tidy-row>
      <tidy-row class="field-top-padding field-bottom-padding">
        <tidy-select
          [label]="'What kind of spot?'"
          [form]="form.controls.parking_spot"
          formControlName="parking_spot"
          [items]="form.controls.paid_parking.value ? paidParkingSpots : freeParkingSpots">
        </tidy-select>
      </tidy-row>
      <ng-container *ngIf="form.controls.paid_parking.value">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-select
            [label]="'How will you pay?'"
            [form]="form.controls.parking_pay_with"
            formControlName="parking_pay_with"
            [items]="payOptions">
          </tidy-select>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-select
            [label]="'How much will you reimburse?'"
            [form]="form.controls.max_parking_cost"
            formControlName="max_parking_cost"
            [items]="maxCostOptions">
          </tidy-select>
        </tidy-row>
      </ng-container>
      <tidy-row class="field-top-padding">
        <tidy-input
          [label]="'Any notes to help park?'"
          formControlName="parking_notes"
          [form]="form.controls.parking_notes">
        </tidy-input>
      </tidy-row>
      <photo-caption
        [photoUrls]="form.value.parkingPhotoUrls"
        (deletePhoto)="confirmDelete($event, form)"
        [type]="'parking'">
      </photo-caption>

      <tidy-row class="extra-bottom-padding extra-top-padding" [alignLastItemRight]="true">
        <tidy-image [src]="'assets/img/unlock.svg'" class="title-size">
        </tidy-image>
        <tidy-text [header]="'How to Get In'"> </tidy-text>
        <tidy-text [body]="'Add Photo'" (action)="getImg(form, 'access')" class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row class="field-top-padding">
        <tidy-input
          [label]="'How do they access the property?'"
          formControlName="home_access"
          [form]="form.controls.home_access">
        </tidy-input>
      </tidy-row>
      <photo-caption
        [photoUrls]="form.value.accessPhotoUrls"
        (deletePhoto)="confirmDelete($event, form)"
        [type]="'access'">
      </photo-caption>

      <tidy-row class="extra-bottom-padding extra-top-padding" [alignLastItemRight]="true">
        <tidy-image [src]="'assets/img/lock.svg'" class="title-size"> </tidy-image>
        <tidy-text [header]="'How to Close Up'"> </tidy-text>
        <tidy-text class="link" [body]="'Add Photo'" (action)="getImg(form, 'closing')">
        </tidy-text>
      </tidy-row>
      <tidy-row class="field-top-padding">
        <tidy-input
          [label]="'What should they do as they leave?'"
          formControlName="home_close"
          [form]="form.controls.home_close">
        </tidy-input>
      </tidy-row>
      <photo-caption
        [photoUrls]="form.value.closingPhotoUrls"
        (deletePhoto)="confirmDelete($event, form)"
        [type]="'closing'">
      </photo-caption>
    </tidy-card>
  </form>
</ng-container>

<tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
</tidy-error-message>

<tidy-button [action]="save.bind(this)" [text]="'Save'" class="primary">
</tidy-button>

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';
import { Component, ElementRef, ChangeDetectorRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Auth } from 'src/providers/auth/auth';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { ClientHub } from 'src/providers/client-hub/client-hub';
import { Invoices } from 'src/providers/invoices/invoices';
import { Loading } from 'src/shared/components/loading/loading';
import { Me } from 'src/providers/me/me';
import { Pros } from 'src/providers/pros/pros';
import { StripeProvider } from 'src/providers/stripe/stripe-provider';
import { Schedule } from 'src/providers/schedule/schedule';
import { WindowService } from 'src/shared/providers/window.service';

import { validateEmail } from 'src/shared/validator/validateEmail';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'pending-invoice.html'
})

export class PendingInvoicePage implements OnDestroy, OnInit {

  allowsCreditCard: boolean;
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  clientHubInfo: any;
  cardEl: any = null;
  cardHandler = this.onChange.bind(this);
  clientEmail: string;
  clientName: string;
  copied: boolean;
  creditWithPro: number;
  creditWithTIDY: number;
  companyName: string;
  errorMessage: string;
  form: UntypedFormGroup;
  fromStartDuration: string;
  hasAccount: boolean;
  howToPayItems: any;
  isInternalComment: boolean;
  isAuthenticated: boolean;
  isLoggedIntoClientApp: boolean;
  lastFourCC: string;
  loaded: boolean;
  invoice: any;
  invoiceNotes: any;
  messageSelectItems: any;
  otherOptionItems: any;
  proReportedDuration: string;
  proEmail: string;
  proPhone: string;
  proId: number;
  paymentOptions: any;
  prefersCreditCard: boolean;
  proName: string;
  selectedMessageType: any;
  selectedOtherOption: any;
  saveCardForFuture = true;
  submitted: boolean;
  showAllInvoiceNotes: boolean;
  totalOwed: number;
  termsError: boolean;
  hasValidCaptcha: string | null = null;
  captchaErrorMessage: boolean;
  dialogParams: any;
  isRightSideContent: boolean;
  customBack: string;
  useTidyCredit: boolean;
  howToPayLabel: string;
  showPaymentMethodOptions: boolean;
  showTooMuchCreditWarning: boolean;
  showNotEnoughCreditWarning: boolean;
  showACHDebitMessage: boolean;

  constructor(
    private auth: Auth,
    private card: Card,
    private cd: ChangeDetectorRef,
    private client: Client,
    private clientHub: ClientHub,
    private fb: UntypedFormBuilder,
    private invoices: Invoices,
    private me: Me,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private route: ActivatedRoute,
    private stripeProvider: StripeProvider,
    private schedule: Schedule,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService
  ) {
    this.form = this.fb.group({
      howToPay: ['', Validators.required],
      firstName: '',
      lastName: '',
      email: [''],
      password: [''],
      terms: [true],
      otherOptions: [''],
      message: [''],
      messageType: ['public_reply'],
      amountOfTidyCreditToUse: ['']
    });
    this.form.controls.howToPay.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      this.mountStripeForm();
    });
  }

  async ngOnInit() {
    try {      
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Bill');
      }
      this.clientHubInfo = this.navCtrl.getParam('clientHubInfo');
      this.isLoggedIntoClientApp = !this.clientHubInfo;
      if (!this.clientHubInfo && this.windowService.isDesktopRes && !this.isRightSideContent) {
        await this.storage.save('shouldOpenBillPageID', this.route.snapshot.paramMap.get('invoice_id'))
        return this.navCtrl.navigateForward('bills');
      }
      /* this.invoice = this.dialogParams?.invoice || this.navCtrl.getParam('invoice'); */
      if (!this.invoice) {
        const invoiceId = this.dialogParams?.invoiceId || this.route.snapshot.paramMap.get('invoice_id');
        const invoiceUUID = this.route.snapshot.paramMap.get('uuid');
        this.invoice = invoiceId ? await this.invoices.getInvoiceById(invoiceId) : await this.invoices.getInvoice(invoiceUUID);
      }
      const [isAuthenticated, clientName, lastFourCC, paymentOptions, invoiceNotes, job] = await Promise.all([
        this.checkIfIsAuthenticated(),
        this.getClientName(),
        this.getLastFourCC(),
        this.invoices.getPaymentOptions(this.invoice.uuid),
        this.getInvoiceNotes(),
        this.getJob()
      ]);
      this.isAuthenticated = isAuthenticated;
      this.clientName = clientName;
      this.lastFourCC = lastFourCC;
      this.paymentOptions = paymentOptions;
      this.invoiceNotes = invoiceNotes;
      this.customBack = await this.getParam('customBack');
      this.clientEmail = this.clientHubInfo?.client_hub_details.customer.email;
      this.companyName = localStorage.getItem('companyName');
      this.proEmail = this.invoice?.team?.owner?.email || await this.getParam('proEmail') || this.invoice?.homekeeper_data?.email;
      this.proPhone = this.invoice?.team?.owner?.phone || await this.getParam('proPhone') || this.invoice?.homekeeper_data?.phone;
      this.proId = await this.getParam('proId');
      this.hasAccount = this.clientHubInfo?.client_hub_details.customer.state == 'active' || await this.getParam('hasAccount');
      this.proName = this.invoice?.team?.owner?.name || await this.getParam('proName') || this.invoice?.homekeeper_data?.name;
      this.form.patchValue({email: this.clientEmail});
      this.otherOptionItems = this.buildOtherOptionItems();
      this.howToPayItems = await this.getHowToPayItems();
      this.creditWithPro = this.invoice.amount - this.invoice.amount_due;
      this.creditWithTIDY = this.invoice?.tidy_credit_amount;
      this.totalOwed = Math.max(0, this.invoice.amount - this.creditWithPro );
      this.useTidyCredit = this.invoice?.tidy_credit_amount > 0 && this.totalOwed > 0;
      if (this.useTidyCredit) {
        this.form.patchValue({
          amountOfTidyCreditToUse: (this.creditWithTIDY > this.totalOwed ? this.totalOwed / 100 : this.creditWithTIDY / 100).toString()
        });
      }
      this.checkIfShowPaymentMethodOptions();
      if (this.isLoggedIntoClientApp) {
        this.proReportedDuration = this.pros.parseDurationIntoHoursAndMintes(job.homekeeper_jobs[0].job_durations.reported_by_homekeeper, 'proReportedDuration');
        this.fromStartDuration = this.pros.parseDurationIntoHoursAndMintes(job.homekeeper_jobs[0].job_durations.from_start_to_end_moment, 'fromStartDuration');
      }
      this.messageSelectItems = this.getMessageSelectItems();
      this.loaded = true;
    } catch (err) {
      if (this.clientHubInfo) return this.clientHub.goToErrorPage();
      this.util.showError(`${new TranslationPipe().transform('Error fetching invoice, please log in to view the bill if not logged in.')} ${this.errorMessage}`);
    }
  }

  async getJob() {
    if (this.isLoggedIntoClientApp) {
      const job = this.navCtrl.getParam('job') || await this.schedule.getJobDetail(this.invoice.items[0].item_id);
      return job;
    } else {
      return null;
    }
  }

  async getInvoiceNotes() {
    if (this.isLoggedIntoClientApp) {
      return await this.invoices.getInvoiceNotes(this.invoice.id);
    }
    return [];
  }

  async getClientName() {
    if (this.invoice?.customer?.name) {
      return this.invoice?.customer?.name;
    } else {
      const settings = await this.client.getClientSettings();
      return settings.profile.first_name;
    }
  }

  async getLastFourCC(): Promise<any> {
    if (this.isLoggedIntoClientApp) {
      const cards = await this.card.getCreditCards();
      let lastFourCC = null;
      cards.map((card) => {
        if (this.invoicedAddressIsInClientsTeam(card)) {
          lastFourCC = card.metadata.last4;
        }
      });
      if (!lastFourCC) {  
        cards.map((card) => {
          if (card.is_primary) {
            lastFourCC = card.metadata.last4;
          }
        });
      }
      return lastFourCC;
    } else {
      return null
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    return param;
  }

  invoicedAddressIsInClientsTeam(card) {
    let addressIsInTeam = false;
    card.account_teams.map((team) => {
      team.address_ids.map((id) => {
        if (id == this.invoice.items[0].service_details.address.id) {
          addressIsInTeam = true;
        }
      });
    });
    return addressIsInTeam;
  }

  buildOtherOptionItems() {
    let array = [];
    this.paymentOptions.map((option) => {
      const name = option.team_payment_option_type.name;
      if (option.team_payment_option_type.state == 'active') {
        if (name == 'Card') {
          this.allowsCreditCard = true;
          this.prefersCreditCard = option.is_preferred;
        } else {
          array.push({
            viewValue: option.is_preferred ? name + new TranslationPipe().transform(' (preferred)') : name,
            value: option.team_payment_option_type.id
          });
        }
      }
    });
    array.push({
      viewValue: 'ACH Debit',
      value: 'achDebit'
    })
    return array;
  }

  async checkIfIsAuthenticated() {
    if (!this.clientHubInfo) {
      return true;
    }
    if (!this.auth.isAuthenticated()) {
      return false;
    }
    const client = await this.me.load();
    const isSameAccountAsInvoice = client.customer_account.email === this.clientEmail;
    return isSameAccountAsInvoice;
  }

  mountStripeForm() {
    this.cardEl = this.stripeProvider.getCardElement();
    if (this.cardInfo) {
      this.cardEl.mount(this.cardInfo.nativeElement);
      this.cardEl.addEventListener('change', this.cardHandler);
    }
  }

  ngOnDestroy() {
    if(this.cardEl && this.cardInfo){
      this.cardEl.unmount(this.cardInfo.nativeElement);
      this.cardEl.removeEventListener('change', this.cardHandler);
    }
    this.stripeProvider.destroyCard();
  }

  onChange(err: any) {
    this.errorMessage = err.error ? err.error.message : err.message;
    this.cd.detectChanges();
  }

  async getHowToPayItems() {
    let options = [];
    if (this.allowsCreditCard) {
      if (this.hasAccount && this.lastFourCC) {
        options.push({
          viewValue: this.prefersCreditCard ? `${new TranslationPipe().transform('Use my primary credit card on file (ends in')} ${this.lastFourCC}) ${new TranslationPipe().transform('(preferred)')}` : `${new TranslationPipe().transform('Use my primary credit card on file (ends in')} ${this.lastFourCC})`,
          value: 'currentCard'
        });
      }
      options.push({
        viewValue: this.prefersCreditCard && !(this.hasAccount && this.lastFourCC) ? 'Use new card (preferred)' : 'Use new card',
        value: 'newCard'
      });
    }
    if (this.otherOptionItems.length > 0) {
      options.push({
        viewValue: 'Other options',
        value: 'alreadyPaid'
      });
    }
    return options;
  }

  captchaEvents(captchaResult){
    this.hasValidCaptcha = captchaResult;
  }

  async payInvoice() {
    this.submitted = true;
    this.termsError = false;
    this.storage.save('didMakeBillChanges', true);
    this.updateValidation();
    if (!this.form.valid) {
      if (this.form.value.howToPay === '') {
        this.errorMessage = 'Please select a payment method.';
      }
      if (!this.form.controls.terms.valid) {
        this.termsError = true;
      }
      return;
    } else if(this.form.value.howToPay ==='newCard' && this.hasValidCaptcha === null){
      this.captchaErrorMessage = true;
      return;
    }
    const credentials = {
      username: this.form.value.email,
      password: this.form.value.password,
    };
    try {
      switch(this.form.value.howToPay) {
        case 'alreadyPaid':
          this.form.controls.otherOptions.setValidators(Validators.required);
          this.form.controls.otherOptions.updateValueAndValidity();
          if (!this.form.valid) {
            return;
          }
          await this.invoices.recordDirectPayment(this.getDirectPaymentPayload());
          break;
        case 'newCard':
          if (this.saveCardForFuture) {
            if (this.hasAccount && !this.isAuthenticated) {
              await this.auth.login(credentials, 'login');
            }
            if (!this.hasAccount) {
              await this.auth.login(credentials, 'signUp');
              await this.client.getCustomers(this.getNewCustomerPayload());
              await this.auth.login(credentials, 'signUpPassword');
              await this.client.getClientInfo();
            }
            const cardData: any = await this.getStripeCardData();
            const paymentMethod: any = await this.card.saveCard(cardData);
            await this.invoices.payInvoiceCardOnFile(this.invoice.id, await this.getCardOnFilePayload(paymentMethod.id));
          } else {
            const cardData = await this.getStripeCardData();
            await this.invoices.payInvoiceOneTimeCard(this.getNewCardPayload(cardData));
          }
          break;
        default:
          if (!this.isAuthenticated) {
            if (this.clientEmail !== this.form.value.email) {
              return this.errorMessage = 'Please log into the account on file for this invoice.';
            }
            await this.auth.login(credentials, 'login');
          }
          await this.invoices.payInvoiceCardOnFile(this.invoice.id, await this.getCardOnFilePayload());
          break;
      }
      await this.invoices.getInvoiceById(this.invoice.id)
      this.goToSuccessPage();
    } catch(err) {
      if (err?.error === 'invalid_grant') {
        return this.errorMessage = 'The email and password you entered did not match our records. Please double-check and try again.';
      }
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  getNewCustomerPayload() {
    return {
      customer: {
        booked_via: 'Self Booking',
        first_name: this.form.value.firstName || 'mock',
        last_name: this.form.value.lastName || 'mock',
        email: this.form.value.email,
        password: this.form.value.password,
        account_type: 'regular',
      }
    };
  }

  getDirectPaymentPayload() {
    return {
      invoice_uuid: this.invoice.uuid,
      payment_type_id: this.form.value.otherOptions,
      amount: this.invoice.amount_due,
      invoice_id: this.invoice.id
    }
  }

  getNewCardPayload(cardData) {
    return {
      card_token: cardData.card_token,
      invoice_uuid: this.invoice.uuid
    };
  }

  async getCardOnFilePayload(paymentMethodId = null) {
    if (!this.showPaymentMethodOptions) {
      return {
        payment_method: {
          type: 'current_credits'
        }
      };
    }
    let payload = {
      payment_method: {
        type: 'default_payment_method'
      }
    };
    if (paymentMethodId) {
      payload.payment_method['id'] = paymentMethodId;
      payload.payment_method['type'] = 'payment_method';
    }
    if (this.useTidyCredit) {
      payload['use_current_credits'] = true;
      payload['use_current_credits_max_amount'] = parseInt(this.form.value.amountOfTidyCreditToUse.replace('$', '')) * 100;
    }
    return payload;
  }

  async goToSuccessPage() {
    const clientHubUuId = await this.getParam('clientHubUuId');
    const redirectPath = await this.getParam('successRoute');
    const sucessPath = clientHubUuId ? 'success-no-auth' : 'success';
    if (clientHubUuId) {
      const params = {
        header: 'Invoice Paid',
        body: '',
        buttonText: 'Ok',
        buttonRoute: redirectPath
      };
      this.rightSidePanelService.navigateTo(sucessPath, params, SuccessPage);
    } else {
      this.storage.save('didMakeBillChanges', true);
      this.storage.save('paidInvoiceId', this.invoice.id);
      this.util.showSuccess('Invoice Paid');
      if (this.windowService.isDesktopRes) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateRoot('bills');
      }
    }
  }

  updateValidation() {
    this.form.controls.otherOptions.clearValidators();
    if (!this.saveCardForFuture) {
      const formFields = ['firstName', 'lastName', 'email', 'password'];
      formFields.forEach(field => {
        this.form.controls[field].clearValidators();
        this.form.controls[field].updateValueAndValidity();
      });
    } else {
      let requiredFields;
      if (!this.hasAccount && this.form.value.howToPay !== 'alreadyPaid') {
        this.form.controls.terms.setValidators(Validators.compose([Validators.requiredTrue]));
        requiredFields = ['firstName', 'lastName', 'email', 'password'];
      } else if (!this.isAuthenticated && this.form.value.howToPay !== 'alreadyPaid') {
        requiredFields = ['email', 'password'];
      } else {
        requiredFields = [];
      }
      if ((!this.hasAccount || !this.isAuthenticated) && this.form.value.howToPay !== 'alreadyPaid') {
        this.form.controls.email.setValidators(Validators.compose([Validators.required, validateEmail]));
        this.form.controls.email.updateValueAndValidity();
      }
      requiredFields.forEach(field => {
        this.form.controls[field].setValidators(Validators.required);
        this.form.controls[field].updateValueAndValidity();
      });
    }
  }

  getCardTitle() {
    if (this.hasAccount) {
      if (this.lastFourCC) {
        if (this.form.value.howToPay == 'currentCard') {
          return 'Log In to Use Card';
        } else {
          return 'Log In to Save Card';
        }
      } else {
        return 'Log In to Add Card';
      }
    } else {
      return 'Create Account to Save Card';
    }
  }

  async getStripeCardData() {
    const stripeData = await this.stripeProvider.getInstance().createToken(this.cardEl);
    if (stripeData?.error) {
      throw stripeData.error;
    }
    return {
      type: 'card',
      stripe_card_token: stripeData.token.id,
      primary_payment_method: true,
    };
  }

  updateHowToPay(selection) {
    console.log(selection)
    this.showACHDebitMessage = false;
    this.form.patchValue({
      otherOptions: null
    });
    if (selection == 'alreadyPaid') {
      this.form.controls.otherOptions.setValidators(Validators.required);
      this.form.controls.otherOptions.updateValueAndValidity();
    } else {
      this.form.controls.otherOptions.clearValidators();
      this.form.controls.otherOptions.updateValueAndValidity();
    }
  }

  getSelectedOtherOption(selection) {
    if (selection == 'achDebit') {
      return this.showACHDebitMessage = true;
    }
    this.showACHDebitMessage = false;
    this.paymentOptions.map((option) => {
      if (option.team_payment_option_type.id == selection) {
        this.selectedOtherOption = option;
      }
    });
  }

  copyToClipBoard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public note',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  @Loading('', true)
  async sendMessage() {
    this.errorMessage = '';
    try {
      const payload = {
        invoice_id: this.invoice.id,
        type: 'text',
        is_internal: this.isInternalComment,
        text: this.form.value.message
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.invoices.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      const payload = {
        invoice_id: this.invoice.id,
        type: 'photo',
        is_internal: this.isInternalComment,
        media_url: attachment
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async createInvoiceNote(payload) {
    await this.invoices.createInvoiceNote(payload);
    this.form.patchValue({
      message: ''
    });
    this.invoiceNotes = await this.invoices.getInvoiceNotes(this.invoice.id);
  }

  toggleShowAllInvoiceNotes() {
    this.showAllInvoiceNotes = true;
  }

  async copyBillLink() {
    const link = `pending-bill/${this.invoice.id}`
    this.invoices.copyBillLink(link);
    this.util.showSuccess('Bill link copied')
  }

  toggleUseTidyCredit() {
    this.useTidyCredit = !this.useTidyCredit; 
    if (this.useTidyCredit) {
      this.form.controls.amountOfTidyCreditToUse.setValidators(Validators.required);
      this.form.controls.amountOfTidyCreditToUse.updateValueAndValidity();
    } else {
      this.form.controls.amountOfTidyCreditToUse.clearValidators();
      this.form.controls.amountOfTidyCreditToUse.updateValueAndValidity();
    }
    this.checkIfShowPaymentMethodOptions();
  }

  checkIfShowPaymentMethodOptions() {
    let amountOfTidyCreditToUse = this.useTidyCredit ? this.form?.value?.amountOfTidyCreditToUse : 0;
    if (this.useTidyCredit && amountOfTidyCreditToUse.includes('$')) {
      amountOfTidyCreditToUse = amountOfTidyCreditToUse.replace('$', '');
      amountOfTidyCreditToUse = amountOfTidyCreditToUse.replace(',', '');
    }
    amountOfTidyCreditToUse = parseFloat(amountOfTidyCreditToUse) * 100;
    const amountCreditDoesntCover = this.totalOwed - amountOfTidyCreditToUse;
    this.howToPayLabel = `${new TranslationPipe().transform('How do you want to pay the remaining')} ${new TidyCurrencyPipe().transform(amountCreditDoesntCover)} ${new TranslationPipe().transform('balance?')}`;
    this.showTooMuchCreditWarning = amountOfTidyCreditToUse > this.totalOwed;
    this.showNotEnoughCreditWarning = amountOfTidyCreditToUse > (this.creditWithTIDY);
    this.showPaymentMethodOptions = amountCreditDoesntCover > 0;
    if (this.showPaymentMethodOptions) {
      this.form.controls.howToPay.setValidators(Validators.required);
      this.form.controls.howToPay.updateValueAndValidity();
    } else {
      this.form.controls.howToPay.clearValidators();
      this.form.controls.howToPay.updateValueAndValidity();
    }
  }

  goToLoadCredit() {
    this.rightSidePanelService.navigateTo('payment-methods/load-credit', {});
  }

}

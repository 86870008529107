import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  templateUrl: 'add-room-photo.html',
  providers: [PhotoCaptionService]
})

export class ToDosAddRoomPhotoPage implements OnInit, OnDestroy {

  loaded = false;
  isRightSideContent: boolean;
  form: UntypedFormGroup;
  photoUrl: string;
  caption: string;
  formControl = new UntypedFormControl();
  card: any;
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private camera: CameraProvider,
    private photoNotes: PhotoNotes,
    private todos: ToDos,
    private route: ActivatedRoute,
    private navCtrl: CustomNavController,
    private photoCaptionService: PhotoCaptionService
  ) {
    this.form = this.fb.group({
      addresses: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = true;
    try {
      this.photoUrl = await this.storage.retrieve('roomPhotoNote');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Add Card Photo');
    this.card = await this.navCtrl.getParam('card') || dialogParams.card;
  }

  async retakeImage() {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg('room');
      this.photoUrl = newPhotoNote.url;
      await this.storage.delete('roomPhotoNote');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async save () {
    try {
      const awsResp = await this.photoNotes.uploadPictureToS3(this.photoUrl, this.card.id);
      const photo = this.card.type == 'group' ?
        await this.todos.addGroupPhotoNote(this.card.id, awsResp.Location) :
        await this.todos.roomPhotoNote(this.card.id, awsResp.Key);
      if (this.caption) {
        await this.photoNotes.photoCaption(photo.id, this.caption);
      }
      this.rightSidePanelService.navigateTo('to-dos');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async ngOnDestroy() {
    await this.storage.delete('roomPhotoNote');
  }
}

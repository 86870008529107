<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="pageTitle || 'Reservations of Day'"
    [canGoBack]="true"
    [customBack]="jobBackPage">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngFor="let property of propertiesWithEvents">
      <tidy-row>
        <tidy-text
          [header]="property?.name || property?.address">
        </tidy-text>
      </tidy-row>
      <ng-container *ngFor="let item of property?.items">
        <tidy-alert *ngIf="!item?.booking">
          <tidy-card-button (action)="goToReservation(item)" class="no-below-stroke no-padding">
            <ion-row>
              <tidy-row class="vertical-align-center no-padding">
                <tidy-image
                  [src]="item?.icon" style="width: 30px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="(item?.isCheckIn ? 'Check In' : 'Check Out') | titlecase">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding">
                  <tidy-text
                    [body]="'Check in:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(item?.startDate | parseDate: 'ddd') +
                    ' ' +
                    (item?.startDate | parseDate: 'M/D')">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'at'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="item?.checkInTime">
                  </tidy-text>
                </tidy-row>
                <tidy-row class="no-padding">
                  <tidy-text
                    [body]="'Check out:'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(item?.endDate | parseDate: 'ddd') +
                    ' ' +
                    (item?.endDate | parseDate: 'M/D')">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'at'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="item?.checkOutTime">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-card-button>
        </tidy-alert>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { EventEmitter } from '@angular/core';
import intlTelInput from 'intl-tel-input';

export function buildIntlTelInput(input: HTMLElement, countryCode: string) {
  const intlTel = intlTelInput(input, {
    initialCountry: countryCode,
    separateDialCode: true,
    nationalMode: false,
    formatOnDisplay: false,
    utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
  });

  return intlTel;
}

export function configureIntlTelInputListener(input, intlTel, countryCodeChange: EventEmitter<string>, maskRef) {
  input.addEventListener('countrychange', (_) => {
    const countryCodeValue = getCountryCodeValue(intlTel);

    countryCodeChange.emit(countryCodeValue);

    updateMask(intlTel, maskRef);
  });
}

export function updateMask(intlTel, maskRef) {
  const countryCodeValue = getCountryCodeValue(intlTel);
  
  maskRef.updateValue();
  maskRef.updateOptions(selectRightMask(countryCodeValue));
}

export function getCountryCodeValue(intlTel) {
  return `+${intlTel?.getSelectedCountryData()?.dialCode}`
}

function selectRightMask(phoneCountryCode) {
  const usDialCode = '+1';
  const usMask = '(000) 000-0000';

  return phoneCountryCode === usDialCode ?
    { mask: usMask } :
    { mask: `[00000000000000000000000]` }
}

export function checkFocused(flagsContainer: HTMLElement) {
  if (!!flagsContainer) {
    toggleFlagContainerVisibility('block', flagsContainer);
  }
}

export function checkFocusedout(e, flagsContainer: HTMLElement, isInputEmpty: boolean) {
  const inputEvent = e.relatedTarget;
  const isClickOnFlag = checkClass(inputEvent, 'iti__selected-flag') || checkClass(inputEvent, 'iti__country');

  if (isInputEmpty && !isClickOnFlag && !!flagsContainer) {
    toggleFlagContainerVisibility('none', flagsContainer);
  }
}

function checkClass(element: HTMLElement, klass: string) {
  return element?.classList.contains(klass);
}

export function toggleFlagContainerVisibility(display: string, flagsContainer: HTMLElement) {
  flagsContainer.style.display = display;
}

export function shouldValidate(intlTel) {
  return !intlTel?.isValidNumber();
}

export function destroyInput(intlTel) {
  return intlTel?.destroy();
}

export function setInputValue(intlTel, value) {
  intlTel.setNumber(value);
}

<tidy-auto-complete
  [showGoogleIcon]="true"
  label="Address"
  [options]="autocompleteAddress"
  [icon]="'assets/svg/home-outline.svg'"
  [activeIcon]="'assets/svg/home-outline-tidy-green.svg'"
  [form]="addressFormControl"
  [formControl]="addressFormControl"
  [errorMessage]="'Please enter a valid address'"
  [submitted]="submitted"
  (keyup)="updateSearch()"
  (action)="chooseItem($event)">
</tidy-auto-complete>

<ng-template #noAddressPickedTemplate>
  <tidy-card *ngIf="radioButtonAddress.length > 0 && submitted && (typedOnAddress || !didChooseAddress)">
    <tidy-row>
      <tidy-text
        [header]="'Please confirm your address:'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-radio-button
        [helper]="'If you do not see your address please retype it above'"
        [items]="radioButtonAddress"
        (optionChange)="chooseItem($event); setAddressFormControl($event)">
      </tidy-radio-button>
    </tidy-row>
  </tidy-card>
</ng-template>

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private rightSidePanelService: RightSidePanelService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const methodsToWatch = ['POST', 'PUT', 'DELETE'];
    if (methodsToWatch.includes(req.method) && req.url.includes(environment.api_url)) {
      this.rightSidePanelService.setRequestMadeInsideRightSidePanel(true);
    } else {
      this.rightSidePanelService.setRequestMadeInsideRightSidePanel(false);
    }
    return next.handle(req);
  }
}

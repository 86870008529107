import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { PlanData } from 'src/providers/plans/plan-data';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';

@Component({
  templateUrl: 'get-refund.html',
  encapsulation: ViewEncapsulation.None
})

export class GetRefundPage implements OnInit {

  dialogParams: any;
  refundable: any;
  refundReasons: TidySelectStringValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  answers: any;
  isRightSideContent: boolean;
  loaded: boolean;
  submitted: boolean;
  nextCleaningDate: any;
  nextCleaningTime: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private client: Client,
    private planData: PlanData,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      reason: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.rightSidePanelService.setDialogPageTitle('Billing History');
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.refundReasons = this.getRefundReasons();
    this.refundable = this.navCtrl.getParam('refundable') || this.dialogParams.refundable;
    if (!this.refundable) {
      this.goToPage('billing-history', {}, BillingHistoryPage);
    }
    this.loaded = true;
  }

  async submit () {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const data = {
      refund_reason: this.form.value.reason,
      charge_token: this.refundable.charge_token,
      currency: this.refundable.currency,
      amount_to_refund: this.refundable.amount_to_refund,
      cleaning_id: (this.refundable.cleaning_id === 'null') ? null : this.refundable.cleaning_id
    }
    try {
      await this.client.billingRefund(data);
      this.showSuccessRefunded();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.showCantRefund();
    }
  }

  showCantRefund() {
    const params = {
      header: 'Unable to Refund',
      body: `${this.errorMessage}`,
      buttonText: 'Ok',
      buttonRoute: 'more-desktop'
    };
    this.goToPage('success', params);
  }

  async showSuccessRefunded() {
    let body = '';
    if (this.form.value.reason === 'bad_cleaning' || this.form.value.reason === 'no_show') {
      body = 'Your refund has been issued, and someone will reach out shortly to address your issue.  We would love to make things right.';
    } else {
      body = 'Refund successful. A refund will appear on your original payment method in 2-10 business days.';
    }
    const hasCleanings = await this.checkIfHasCleanings();
    if (hasCleanings) {
      body += '<br><br><b>Note:</b>You still have cleanings scheduled. Your next cleaning is on ' + this.nextCleaningDate + ' at ' + this.nextCleaningTime;
    }
    const params = {
      header: 'Charge Refunded',
      body: body,
      buttonText: 'Ok',
      buttonRoute: 'billing-history'
    };
    this.goToPage('success', params);
  }

  async checkIfHasCleanings() {
    try {
      const nextCleaning = await this.planData.getNextCleaning();
      this.nextCleaningDate = moment.utc(nextCleaning.date).format('M/D');
      this.nextCleaningTime = moment.utc(nextCleaning.start_time).format('h:mma');
      return true;
    } catch (e) {
      return false;
    }
  }

  getRefundReasons(): TidySelectStringValueModel[] {
    return [
      {
        viewValue: 'No availability I want',
        value: 'no_availability'
      },
      {
        viewValue: 'Moving/traveling',
        value: 'moving'
      },
      {
        viewValue: 'Don’t need cleaning right now',
        value: 'do_not_need'
      },
      {
        viewValue: 'Unhappy with cleaning',
        value: 'bad_cleaning'
      },
      {
        viewValue: 'Pro canceled on me',
        value: 'no_show'
      },
      {
        viewValue: 'I prefer to work directly with my Pro',
        value: 'work_directly'
      }
    ];
  }

  async goToPage(url, params, component = null) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }
  
}

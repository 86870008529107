import { Pipe, PipeTransform } from '@angular/core';
import { translations } from 'src/shared/constants/language-translations';

@Pipe({
  name: 'translate'
})

export class TranslationPipe implements PipeTransform {

  transform(value: string): string {
    const language = localStorage.getItem('language');
    if (language && language !== 'en') {
      if (translations[value] && translations[value][language]) {
        value = translations[value][language];
      }
    }
    return value;
  }

}
<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add To-Do'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <tidy-card>
          <form [formGroup]="form">
            <tidy-row class="field-bottom-padding">
              <tidy-auto-complete
                [label]="'To-Do'"
                [showGoogleIcon]="false"
                formControlName="title"
                [options]="toDoAutoComplete"
                [form]="form.controls.title"
                [icon]="'assets/svg/list-circle-outline.svg'"
                [isToSearchBetweenOptions]="true"
                [submitted]="submitted"
                [errorMessage]="'Please enter the name of the to-do'">
              </tidy-auto-complete>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-textarea
                [label]="'Details (Optional)'"
                formControlName="note"
                [form]="form.controls.note"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [activeIcon]="'assets/svg/information-circle-outline.svg'">
              </tidy-textarea>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/stopwatch-outline.svg'"
                [label]="'Estimated Time'"
                [form]="form.controls.time"
                formControlName="time"
                [items]="estimatedTimes">
              </tidy-select>
            </tidy-row>
            <tidy-row [alignLastItemRight]="true">
              <tidy-checkbox
                [(checked)]="important"
                [items]="[{viewValue: 'Mark as Important', value: false}]"
                [initValue]="important">
              </tidy-checkbox>
              <tidy-text
                class="link"
                [body]="'Add Photo'"
                (action)="takePhoto()">
              </tidy-text>
            </tidy-row>
            <photo-caption
              [type]="'room'"
              [photoUrls]="photoUrls">
            </photo-caption>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-text
                [header]="'Add to Cards'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let room of rooms; let even = even; let i = index">
              <tidy-two-column-row *ngIf="even">
                <tidy-checkbox
                  item-left
                  [items]="[room]"
                  [initValue]="room?.value === preSelectedRoom"
                  (checkedChange)="selectRoom(room?.value)">
                </tidy-checkbox>
                <ng-container *ngIf="rooms[i+1] !== undefined">
                  <tidy-checkbox
                    item-right
                    [items]="[rooms[i+1]]"
                    [initValue]="rooms[i+1]?.value === preSelectedRoom"
                    (checkedChange)="selectRoom(rooms[i+1]?.value)">
                  </tidy-checkbox>
                </ng-container>
              </tidy-two-column-row>
            </ng-container>
            <tidy-row *ngIf="roomError">
              <tidy-error-message
                [text]="'Please select cards to add To-Do.'">
              </tidy-error-message>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-text
                [header]="'Associate Item (Optional)'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="roomObjectsItems?.length == 2">
              <tidy-row *ngIf="numberOfRequiredObjects == 0">
                <tidy-text
                  [helper]="'No items added.'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="numberOfRequiredObjects > 0">
                <tidy-text
                  [helper]="'No items added. Please add an item before adding this custom field.'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="!showAddItemForm">
                <tidy-text
                  [actionBody]="'+ Add Item'"
                  (action)="toggleShowAddItemForm()"
                  class="link">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="showAddItemForm">
                <tidy-text
                  [actionBody]="'- Stop Adding item'"
                  (action)="toggleShowAddItemForm()"
                  class="link">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="roomObjectsItems?.length > 2">
              <tidy-row>
                <tidy-radio-button
                  formControlName="roomObject"
                  [form]="form.controls.roomObject"
                  [items]="roomObjectsItems"
                  (optionChange)="selectRoomObject($event)">
                </tidy-radio-button>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="showAddItemForm">
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [header]="'Add New Item'">
                </tidy-text>
              </tidy-row>
              <tidy-row [ngClass]="{'extra-top-padding': selectedRooms?.length == 1}" class="extra-top-padding">
                <tidy-select
                  [icon]="'assets/svg/information-circle-outline.svg'"
                  [label]="'Category'"
                  [items]="itemCategoryItems"
                  [form]="form.controls.itemCategory"
                  formControlName="itemCategory"
                  (optionChange)="selectNewItemCategory($event)">
                </tidy-select>
              </tidy-row>
              <ng-container *ngFor="let characteristic of itemCharacteristics">
                <tidy-row>
                  <tidy-select
                    [label]="'Select ' + characteristic.name"
                    [icon]="'assets/svg/information-circle-outline.svg'"
                    [form]="form.controls.itemCharacteristics"
                    formControlName="itemCharacteristics"
                    (optionChange)="selectCharacteristic(characteristic, $event)"
                    [items]="characteristic.formData">
                  </tidy-select>
                </tidy-row>
              </ng-container>
              <tidy-row>
                <tidy-select
                  [label]="'Name / Model #'"
                  [icon]="'assets/svg/information-circle-outline.svg'"
                  [form]="form.controls.itemModel"
                  (optionChange)="selectModel($event)"
                  formControlName="itemModel"
                  [items]="itemModelItems">
                </tidy-select>
              </tidy-row>
              <tidy-row *ngIf="createNewModel">
                <tidy-input
                  [label]="'Model Name / Number'"
                  formControlName="itemNewModel"
                  [form]="form.controls.itemNewModel"
                  [icon]="'assets/svg/information-circle-outline.svg'"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter the number of the new model'">
                </tidy-input>
              </tidy-row>
              <tidy-row>
                <tidy-select
                  [label]="'Year Installed'"
                  [form]="form.controls.itemYearInstalled"
                  [icon]="'assets/svg/information-circle-outline.svg'"
                  formControlName="itemYearInstalled"
                  [items]="itemInstallationDateItems">
                </tidy-select>
              </tidy-row>
              <tidy-row>
                <tidy-checkbox
                  [items]="[{viewValue: 'Track Inventory for this Item', value: true}]"
                  [initValue]="false"
                  (checkedChange)="selectGetInventoryAlerts()">
                </tidy-checkbox>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Get alerted when the inventory for this item goes below your minimum quantity.'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="getInventoryAlertsForNewItem">
                <tidy-row>
                  <tidy-input
                    [label]="'Minimum Quantity'"
                    formControlName="itemMinQuantity"
                    [form]="form.controls.itemMinQuantity"
                    [icon]="'assets/svg/information-circle-outline.svg'"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter the minimum quantity.'">
                  </tidy-input>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Current Quantity'"
                    formControlName="itemCurrentQuantity"
                    [form]="form.controls.itemCurrentQuantity"
                    [icon]="'assets/svg/information-circle-outline.svg'"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter the current quantity'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedRoomObject && !showAddItemForm">
              <tidy-row class="extra-top-padding">
                <tidy-text
                  [header]="'Track Inventory (Optional)'">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="selectedRoomObject?.min_quantity">
                <tidy-text
                  [body]="'We create low stock alerts if the current quantity goes below'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="selectedRoomObject?.min_quantity">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'. To have pros update the current inventory,'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [actionBody]="'add custom field'"
                  (action)="addCustomCountField()"
                  class="link">
                </tidy-text>
                <tidy-text
                  [body]="'.'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="!selectedRoomObject?.min_quantity" >
                <tidy-row class="extra-top-padding">
                  <tidy-checkbox
                    [items]="[{viewValue: 'Track Inventory for this Item', value: true}]"
                    [initValue]="false"
                    (checkedChange)="selectGetInventoryAlerts()">
                  </tidy-checkbox>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Get alerted when the inventory for this item goes below your minimum quantity.'">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngIf="getInventoryAlertsForNewItem">
                  <tidy-row>
                    <tidy-input
                      [label]="'Minimum Quantity'"
                      formControlName="itemMinQuantity"
                      [form]="form.controls.itemMinQuantity"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter the minimum quantity'">
                    </tidy-input>
                  </tidy-row>
                  <tidy-row>
                    <tidy-input
                      [label]="'Current Quantity'"
                      formControlName="itemCurrentQuantity"
                      [form]="form.controls.itemCurrentQuantity"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter the current quantity'">
                    </tidy-input>
                  </tidy-row>
                </ng-container>
              </ng-container>
            </ng-container>
          </form>
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="!isAddingCustomFields">
          <tidy-text
            [actionBody]="'Add Custom Field'"
            class="link"
            (action)="expandFields()">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="isAddingCustomFields">
          <tidy-row [alignLastItemRight]="true" style="margin-bottom: 5px">
            <tidy-text
              [header]="'Custom Fields'">
            </tidy-text>
            <tidy-text
              [actionBody]="'Add Field'"
              class="link"
              (action)="addCustomField()">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="customFieldForm?.length">
            <tidy-text
              [body]="'Your pro will be asked to fill out the following fields. Need help?'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'Contact Concierge'"
              class="link"
              (action)="goToContactConciergePage()">
            </tidy-text>
          </tidy-row>

          <tidy-card>
            <tidy-row>
              <tidy-text
                [body]="'Type: Select'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Options: Done / Not Done'">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>

        <tidy-card *ngFor="let customForm of customFieldForm; let i = index">
          <form [formGroup]="customFieldForm[i].form">
            <tidy-row class="extra-bottom-padding">
              <tidy-input
                [label]="'Field Label (what should the pro do?)'"
                formControlName="label"
                [form]="customForm.form.controls.label"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [activeIcon]="'assets/svg/information-circle-outline.svg'"
                [submitted]="submitted"
                [customMask]="{ maxLenghth: 40 }"
                [errorMessage]="'Please enter a label.'">
              </tidy-input>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="'Field Type'"
                [form]="customForm.form.controls.type"
                formControlName="type"
                [items]="customFieldTypeItems"
                (optionChange)="selectFieldType($event, i)"
                [submitted]="submitted"
                [errorMessage]="'Please select a field type.'">
              </tidy-select>
            </tidy-row>
            <tidy-row *ngIf="selectedFieldType">
              <tidy-checkbox
                [items]="[{viewValue: 'Make this required for the pro', value: true}]"
                (click)="selectRequired(customForm, customForm.form.value.required)"
                [initValue]="true">
              </tidy-checkbox>
            </tidy-row>
            <ng-container *ngIf="customForm.numberOfRequiredOptions > 0">
              <tidy-row [alignLastItemRight]="true">
                <tidy-text
                  [header]="'Options'"
                  style="margin-right: 5px">
                </tidy-text>
                <tidy-text
                  [actionBody]="'Add Option'"
                  class="link"
                  (action)="addOption(i)">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let option of customForm.options; let index = index">
                <tidy-row>
                  <tidy-text
                    [body]="'Option'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(index + 1)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [actionBody]="'X'"
                    class="link"
                    (action)="removeOption(i, index)">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <form [formGroup]="customFieldForm[i].options[index]">
                    <tidy-input
                      [label]="'Option ' + (index + 1) + ' Label'"
                      formControlName="option"
                      [form]="customFieldForm[i].options[index].controls.option"
                      [icon]="'assets/svg/information-circle-outline.svg'"
                      [activeIcon]="'assets/svg/information-circle-outline.svg'"
                      [customMask]="{ maxLenghth: 40 }"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter a label.'">
                    </tidy-input>
                  </form>
                </tidy-row>
              </ng-container>
              <tidy-row *ngIf="customForm.options.length == 0" class="extra-bottom-padding">
                <tidy-text
                  [helper]="'None Added - Add at Least'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="customForm.numberOfRequiredOptions">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="submitted && (customForm.options.length < customForm.numberOfRequiredOptions)" class="extra-bottom-padding">
                <tidy-error-message
                  [text]="'Please add at least ' + customForm.numberOfRequiredOptions + ' option' + (customForm.numberOfRequiredOptions == 1 ? '' : 's')">
                </tidy-error-message>
              </tidy-row>
            </ng-container>
          </form>
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [actionBody]="'X'"
              class="link"
              (action)="removeField(i)">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-row [align]="'center'" *ngIf="isAddingCustomFields" class="extra-bottom-padding">
          <tidy-text
            [actionBody]="'Add Field'"
            class="link"
            (action)="addCustomField()">
          </tidy-text>
        </tidy-row>

        <tidy-error-message
          *ngIf="errorMessage?.length"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add To-Do'"
          [action]="addToDo.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

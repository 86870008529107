import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import * as moment from 'moment';

@Injectable()
export class CleaningCards {

  constructor(private httpClient: HttpClientCustom) { }

  getCardType(cleaningId: Number) {
    const url = `api/v1/me/jobs/cards/${cleaningId}`;
    return this.httpClient.get(url)
  }

  getCleaning(cleaningId) {
    const url = `api/v1/me/jobs/cards/${cleaningId}`;

    return this.httpClient.get(url)
      .then(data => this.formatCards(data));
  }

  getCards() {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/cards`;
    return this.httpClient.get(url).then(
      data => {
        const hasTodayCard: any = {};
        const objectData: any = {};
        data.map(
          item => {
            if (item.is_today) {
              hasTodayCard.isToday = true;
              hasTodayCard.card = item;
            }
            return this.formatCards(item)
          }

        );
        objectData.cards = data;
        objectData.hasTodayCard = hasTodayCard;
        return objectData;
      }
    );
  }

  formatCards(item) {
    item.card_type_name = this.formatCardsName(item);
    item.labelService = this.formatService(item);
    item.job.startHumanTime = moment(item.job.start_time).utc().format('h:mma');
    item.job.weekDay = moment(item.job.date).format('dddd').substr(0, 3).toUpperCase();
    item.job.wholeWeekDay = moment(item.job.date).format('dddd');
    const lastHks = item.homekeepers.concat(item.last_inactive_homekeepers);
    item.lastHks = this.formatHkData(lastHks, item.homekeeper_jobs);
    item.homekeepers = item.homekeepers.length ? this.formatHkData(item.homekeepers, item.homekeeper_jobs) : item.homekeepers;
    item.lastHksId = this.getHksId(item.lastHks);
    item.hkNumber = this.getHkNumber(item.lastHks);
    item.showPhone = this.needsToShowPhone(item);
    item.feedbackDone = this.feedbackIsDone(item);
    if (item.timeline) {
      item.timeline = this.formatTimeline(item.timeline);
    }
    return item;
  }

  feedbackIsDone(item) {
    let contFeedbackDone = 0;
    item.homekeeper_jobs.map((hk) => {
      if (hk.feedback_type !== null) {
        contFeedbackDone++;
      }
    });
    return (contFeedbackDone === item.homekeeper_jobs.length);
  }

  needsToShowPhone(card) {
    return ((card.card_type === 'in_progress' || card.card_type === 'upcoming' || card.card_type === 'completed')
      && card.homekeepers.length > 0 && card.is_today === true)
  }

  getHksId(hkArray) {
    const arr = [];
    hkArray.map(item => arr.push(item.id));
    return arr;
  }

  getHkdataByHkJobId(cleaningData, hkJobId) {
    return cleaningData.lastHks.find(item => {
      return item.hkJobId == hkJobId;
    });
  }

  formatService(item) {
    let service;

    if (item && item.job.cleaning_service_type) {
      service = item.job.cleaning_service_type;
      service = this.getFormatedServiceName(service);
    }
    return service;
  }

  formatHkData(data, hkJob) {
    if (data && data.length > 0) {
      return data.map(item => this.adjustHk(item, hkJob));
    }

    const hkArray = [];
    hkArray.push(this.adjustHk(data, hkJob));
    return hkArray;
  }

  getFormatedServiceName(service) {
    const serviceList = {
      'tidy': 'TIDY',
      'plus': 'TIDY+',
      'turnover_tidy': 'TIDY',
      'turnover_plus': 'TIDY+',
      'mighty': 'MIGHTY',
      'tidy_xl': 'TIDY XL',
      'half_mighty': 'MIGHTY'
    }
    return serviceList[service];
  }

  adjustHk(item, hkJob) {
    item.hkJobId = this.findHkJob(item, hkJob);
    item.firstName = this.adjustFirstName(item);
    return item
  }

  findHkJob(item, hkJob) {
    item.hkJobId = null;
    if (hkJob) {
      hkJob.map((job) => {
        if (job.user_id === item.id) {
          item.hkJobId = job.id;
        }
      });
    }
    return item.hkJobId;
  }

  adjustFirstName(item) {
    let firstName;
    if (item && item.name) {
      const nameSplit = item.name.split(' ');
      firstName = nameSplit[0];
    }
    return firstName;
  }

  formatCardsName(card) {
    switch (card.card_type) {
      case 'homekeeper_reported_client_cancellation':
        return 'You cancelled';

      case 'none_on_time_homekeepers_assigned':
        return ' ';

      case 'homekeeper_unavailable':
        return ' ';

      case 'homekeeper_called_out':
        return ' ';

      case 'no_show':
        return ' ';

      case 'post_cleaning_could_not_find_home':
        return 'Can\'t find home';

      case 'could_not_find_home':
        return 'Can\'t find home';

      case 'post_cleaning_could_not_access_your_home':
        return 'Can\'t access home';

      case 'could_not_access_your_home':
        return 'Can\'t access home';

      default:
        return card.card_type_name;
    }
  }

  formatTimeline(timeline) {
    timeline.map(item => {
      const time = item.moment.hk_date + ' ' + item.moment.hk_time;
      item.moment.timeFormated = moment(time).format('h:mma');
    });
    return timeline;
  }

  getHkNumber(hks) {
    let hkNumber = 1;
    if (hks.length > 1) {
      hkNumber = 2;
    }
    return hkNumber;
  }

  getHkData(cleaningData, hkId) {
    let hkData;
    cleaningData.lastHks.find(item => {
      if (item.id === hkId) {
        hkData = item;
      }
    });
    return hkData;
  }

  isBefore24Hours(card) {
    const now: any = moment();
    const startTime: any = moment(card.job.start_time).utc().format('HH:mm:ss');
    const begin: any = moment(card.job.date + ' ' + startTime);
    return (begin.diff(now, 'hours') < 24);
  }

  isRegularCleaningType(card) {
    if (!card.card_type) {
      return;
    }
    const types: Array<string> = ['half_cleaning_emergency_request_success', 'completed', 'upcoming', 'in_progress'];
    const result = types.find((item) => item === card.card_type);
    return (result !== undefined)
  }

  needsToShowHelp(card) {

    return (this.isBefore24Hours(card) && this.isRegularCleaningType(card));
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Integrations } from 'src/providers/integrations/integrations';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RemoteLockPage } from '../remote-lock/remote-lock';

@Component({
  templateUrl: 'edit-address-lock.html'
})

export class EditAddressLockPage extends TimeoutableComponent implements OnInit {

  address: any;
  deviceOptions: any;
  didSelectLocation: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  isAssociatedWithAddress: boolean;
  locationItems: any;
  remoteAccessAddresses: any;
  remoteAccessAddressName: string;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private integrations: Integrations,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      location: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Integrate Device(s)');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    try {
      this.address = await this.getParam('address');
      this.isAssociatedWithAddress = this.address.remote_access_address;
      this.remoteAccessAddresses = await this.getParam('remoteAccessAddresses');
      if (this.address.remote_access_address) {
        this.form.patchValue({location: this.address.remote_access_address.id});
        this.selectLocation(this.address.remote_access_address.id);
      } else {
        this.form.patchValue({location: 'none'});
      }
      this.getLocationItems();
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  selectLocation(remoteAccessAddressId) {
    this.isAssociatedWithAddress = remoteAccessAddressId !== 'none';
    this.getRemoteAccessAddressName(remoteAccessAddressId);
    this.deviceOptions = [];
    this.remoteAccessAddresses.map((address) => {
      if (address.id == remoteAccessAddressId) {
        address.remote_access_devices.map((device) => {
          this.deviceOptions.push({
            value: device?.in_use || false,
            id: device.id,
            viewValue: device.device_name
          });
        });
      }
    });
    this.didSelectLocation = true;
  }

  getRemoteAccessAddressName(remoteAccessAddressId) {
    this.remoteAccessAddressName = '';
    this.remoteAccessAddresses.map((address) => {
      if (address.id == remoteAccessAddressId) {
        this.remoteAccessAddressName = address.address1;
      }
    });
  }

  getLocationItems() {
    this.locationItems = [];
    this.remoteAccessAddresses.map((address) => {
      const remoteAccessAddressIsNotAssociatedWithOtherAddress = address?.address?.id == this.address.id || !address?.address?.id;
      if (remoteAccessAddressIsNotAssociatedWithOtherAddress) {
        this.locationItems.push(({
          value: address.id,
          viewValue: address.address1 + (address.address2 ? (' ' + address.address2) : '')
        }));
      }
    });
    this.locationItems.push(({
      value: 'none',
      viewValue: 'None'
    }));
  }

  @Loading('', true)
  async saveChanges() {
    try {
      if (this.form.value.location == 'none' && this.address.remote_access_address) {
        const payload = {
          address_id: null
        }
        await this.integrations.associateRemoteAccessAddress(payload, this.address.remote_access_address.id);
        for (let i = 0; i < this.deviceOptions.length; i++) {
          const payload = {
            display_name: this.deviceOptions[i].viewValue,
            in_use: false
          }
          await this.integrations.updateRemoteAccessDevices(payload, this.deviceOptions[i].id);
        }
      } else {
        if (this.address.remote_access_address) {
          const payload = {
            address_id: null
          }
          await this.integrations.associateRemoteAccessAddress(payload, this.address.remote_access_address.id);
        }
        const payload = {
          address_id: this.address.id
        }
        await this.integrations.associateRemoteAccessAddress(payload, this.form.value.location);
        for (let i = 0; i < this.deviceOptions.length; i++) {
          const payload = {
            display_name: this.deviceOptions[i].viewValue,
            in_use: this.deviceOptions[i].value
          }
          await this.integrations.updateRemoteAccessDevices(payload, this.deviceOptions[i].id);
        }
      }
      this.rightSidePanelService.navigateTo('remotelock', {}, RemoteLockPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Pro Did Not Show Up'"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [body]="'Oh no! Please confirm that'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="proName">
      </tidy-text>
      <tidy-text
        [body]="'did not clean at all and should not be paid for this cleaning.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row>
        <tidy-checkbox
          [(checked)]="getCredit"
          [items]="[
            {
              viewValue: 'Add $10 credit to my account', value: true
            }
          ]"
          [initValue]="getCredit">
        </tidy-checkbox>
      </tidy-row>
      <tidy-row>
        <tidy-checkbox
          [(checked)]="blockPro"
          [items]="[
            {
              viewValue: 'Block Pro ' + proName, value: true
            }
          ]"
          [initValue]="blockPro">
        </tidy-checkbox>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-two-column-row>
      <tidy-button
        [text]="'Go Back'"
        [action]="goBack.bind(this)"
        class="secondary">
      </tidy-button>
      <tidy-button
        [text]="'Confirm'"
        [action]="submit.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-two-column-row>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

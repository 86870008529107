<mat-form-field
  [class.invalid-field]="!form.valid && submitted"
  (click)="toggleMatPicker()"
  appearance="outline"
>
  <mat-label *ngIf="label">
    <tidy-image class="icon" [src]="icon" *ngIf="icon" class="outline-field-label-icon"></tidy-image>
    <tidy-text [body]="label"></tidy-text>
  </mat-label>

  <input
    matInput
    [matDatepicker]="picker"
    (dateChange)="changeDate($event)"
    [value]="value"
    [min]="minDate"
  />

  <mat-datepicker-toggle matSuffix [for]="picker">
    <div class="mat-select-arrow-wrapper" matDatepickerToggleIcon>
      <div class="mat-select-arrow"></div>
    </div>
  </mat-datepicker-toggle>

  <mat-datepicker
    #picker
    [opened]="calendarOpened"
    (closed)="calendarClosed()"
  ></mat-datepicker>
</mat-form-field>

<tidy-error-message *ngIf="!form.valid && submitted" [text]="errorMessage">
</tidy-error-message>

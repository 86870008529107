import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DragDropItemDirective } from '../../directives/tidy-drag-drop-item';

@Component({
  selector: 'tidy-drag-drop',
  templateUrl: 'drag-drop-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./drag-drop-list.component.scss']
})
export class DragDropListComponent implements AfterContentInit {

  @ContentChildren(DragDropItemDirective, {read: TemplateRef}) dragDropItems: TemplateRef<any>[];
  @Input() connectedDragDropsIds: Array<string>;
  @Input() dataList: Array<any>;
  @Input() disabled: boolean;
  @Input() dragDropId: string;
  @Input() emptyListMessage: string;
  @Input() emptyDisabledListMessage: string;
  @Input() listCondition = null;
  @Input() itemCondition = null;
  @Output() listDropped: EventEmitter<any> = new EventEmitter<any>();
  contentChecked: boolean = false;

  allowDrop(dragItem: CdkDrag) {
    if (this.listCondition) {
      return this.listCondition(dragItem.data);
    }

    return true;
  }

  async itemDropped(dropped: CdkDragDrop<any>) {
    const srcContainer: CdkDropList = dropped.previousContainer;
    const targetContainer: CdkDropList = dropped.container;

    const targetList = targetContainer.data;
    if (srcContainer === targetContainer) {
      moveItemInArray(targetList, dropped.previousIndex, dropped.currentIndex);
    } else {
      const allowTransfer = await this.allowItemTransfer(dropped);
      if (!allowTransfer) return;
      transferArrayItem(srcContainer.data, targetList, dropped.previousIndex, dropped.currentIndex);
    }
    this.listDropped.emit(targetList);
  }

  async allowItemTransfer(dropped: CdkDragDrop<any>) {
    if (this.itemCondition !== null) {
      return await this.itemCondition(dropped);
    } else {
      return true;
    }
  }

  ngAfterContentInit()	{
    this.contentChecked = true;
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Contact Pro'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-card *ngFor="let pro of pros">
        <tidy-row>
           <tidy-grid>
             <ion-row>
               <ion-col size="6" class="vertical-align-center">
                 <tidy-image
                   [src]="'assets/svg/person-circle-outline.svg'"
                   class="body-size">
                 </tidy-image>
                  <tidy-text
                    [body]="pro?.first_name">
                  </tidy-text>
               </ion-col>
                <!--TODO Replace this with a class-->
               <ion-col size="3" style="text-align: right" *ngIf="pro?.show_contact_buttons && !windowService.isDesktopRes" class="vertical-align-center">
                 <tidy-image
                   [src]="'assets/svg/call-outline.svg'"
                   class="body-size">
                 </tidy-image>
                 <tidy-text
                   (action)="openTell(pro)"
                   [body]="'Call'"
                   class="link">
                 </tidy-text>
               </ion-col>
               <ion-col size="3" style="text-align: right" *ngIf="pro?.show_contact_buttons && !windowService.isDesktopRes" class="vertical-align-center">
                 <tidy-image
                   [src]="'assets/svg/chatbox-ellipses-outline.svg'"
                   class="body-size">
                 </tidy-image>
                 <tidy-text
                   (action)="openSms(pro)"
                   [body]="'Text'"
                   class="link">
                 </tidy-text>
               </ion-col>
               <ion-col size="6" *ngIf="pro?.show_contact_buttons && windowService.isDesktopRes">
                 <tidy-text
                   [body]="pro?.masked_phone_number">
                 </tidy-text>
               </ion-col>
             </ion-row>
           </tidy-grid>
        </tidy-row>
        <ng-container *ngIf="pro?.show_contact_buttons">
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
          <tidy-row>
            <tidy-text
              (action)="markNoShow(pro)"
              [body]="'Click here'"
              class="link">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'if'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="pro?.first_name">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'is not responding and did not show up.'">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-row *ngIf="!pro?.show_contact_buttons" [align]="'center'">
        <tidy-text
          [body]="'You can call / text your Pro once it is within 30 minutes of the job.'">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'To protect your privacy, we generate a temporary phone number for you to use for each job.'">
        </tidy-text>
      </tidy-row>
      <tidy-row [align]="'center'">
        <tidy-text
         [body]="'Please use your phone number on file:'">
        </tidy-text><br>
        <tidy-text
         [body]="(settings?.profile?.phone | telephone: 'format') + ' '">
        </tidy-text>
        <tidy-text
         [body]="'Edit'"
         (action)="goToEditContactInfo()"
         class="link">
        </tidy-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-text [body]="inputTitle"></tidy-text>
<div
  [id]="'customMessage-' + id"
  contenteditable="true"
  class="custom-message"
  [class.invalid]="control.invalid && control.touched"
  [class.readonly]="readonly">
</div>
<tidy-error-message
  *ngIf="control.invalid && control.touched"
  [text]="inputTitle + ' is required.'"
  class="error-message">
</tidy-error-message>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="pageTitle">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-alert *ngIf="currentProposals?.length === 0">
        <tidy-text
          [body]="'No ' + status + ' proposals'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngFor="let proposal of currentProposals">
        <tidy-card-button (action)="goToProposalPage(proposal)">
          <tidy-row>
            <tidy-image
              [src]="'assets/img/proposals-black.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="proposal?.proposable?.team_service?.name | titlecase"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Sent'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(proposal?.created_at | customdate:'M/D')">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'by'">
            </tidy-text>
            <tidy-text
              [body]="proposal?.team?.owner?.first_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from "src/providers/logger";

import { ConciergeItemModel } from 'src/models/concierge.model';
import { environment } from 'src/environments/environment';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'concierge-message.html',
  encapsulation: ViewEncapsulation.None
})

export class ConciergeMessagePage implements OnInit {

  email: string;
  errorMessage: string;
  item: ConciergeItemModel;
  pageTitle: string;
  replyTime: string;
  status: string;
  createdAt: Date;
  jobData: any;
  isJobIssue: boolean;
  loaded: boolean;

  constructor(
    private concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private util: Util
  ) {}

  async ngOnInit() {
    try {
      this.loaded = false;
      this.item = this.navCtrl.getParam('item');
      if (!this.item) {
        const conciergeItemId = this.route.snapshot.paramMap.get('conciergeItemId');
        try {
          this.item = await this.concierge.getConciergeItemById(conciergeItemId);
        } catch (err) {
          this.logger.error(err);
          this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        }
      }
      this.email = localStorage.getItem('clientEmail');
      if (!this.email) {
        await this.concierge.setClientInfo();
        this.email = localStorage.getItem('clientEmail');
      }
      this.isJobIssue = this.item.type == 'support.job_issue' && this.item?.metadata?.custom?.job_id != null;
      this.pageTitle = this.getPageTitle();
      this.status = this.item.components[0].value.status;
      this.replyTime = this.concierge.getReplyTime(this.item.type);
      this.loaded = true;
    } catch (err) {
    }
  }

  getPageTitle() {
    switch (this.item.type) {
      case 'support.other':
        return 'Other Question';
      case 'support.how_to':
        return 'How to Use TIDY';
      case 'support.job_issue':
        return 'Job Issue';
      case 'support.billing':
        return 'Billing Issue';
      case 'support.developer':
        return 'Developer Question';
      case 'support.integration':
        return 'Marked Completed';
      case 'support.concierge_task':
        return 'Concierge Action';
    }
  }

  goToJobDetails() {
    if (this.item.metadata.custom.privateJob) {
      this.navCtrl.navigateForward(`past-private-job/${this.item.metadata.custom.job_id}`);
    } else {
      this.navCtrl.navigateForward(`past-job/${this.item.metadata.custom.job_id}`);
    }
  }

  async reply() {
    const params = {
      item: this.item
    };
    this.navCtrl.navigateForward('concierge-reply', params);
  }

  openAttachment(attachmentKey: string) {
    const fileUrl = `${environment.aws_s3_bucket_url}${attachmentKey}`;
    this.util.openUrl(fileUrl);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Proposals } from 'src/providers/proposals/proposals';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';

@Component({
  templateUrl: 'thumbnail-proposal.html'
})

export class ThumbnailProposalPage implements OnInit {

  proposal: any;
  proposalFrequency: string;
  loaded: boolean;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private proposals: Proposals,
    private route: ActivatedRoute
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      const proposalUuId = this.route.snapshot.paramMap.get('proposalUuid');
      this.proposal = await this.proposals.getProposal(proposalUuId);
      this.proposalFrequency = this.getProposalFrequency();
      this.loaded = true;
    } catch (err) {
      if (err) this.goToErrorPage();
    }
  }

  getProposalFrequency() {
    const frequencies = {
      on_time: 'Valid for one time job',
      recurring: 'Valid for recurring job',
      one_time_or_recurring: null
    };
    return frequencies[this.proposal.service_details.frequency_options];
  }

  goToErrorPage() {
    const params = {
      header: 'Proposal No Longer Valid',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'my-pros'
    };
    this.navCtrl.navigateForward('success-no-auth', params);
  }
}

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";

import { EmployerService } from "src/providers/employer/employer";
import { Client } from 'src/providers/client/client';
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { RemoveEmployeeComponent } from "src/pages/employer/edit-employee/confirm-remove-employee/confirm-remove-employee";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';

export interface Employee {
  deleted_at: string;
  source_user_email: string;
  employee_customer_id: number;
  employer_customer_id: number;
  source_user_first_name: string;
  id: number;
  source_user_last_name: string;
  source: string;
}

@Component({
  templateUrl: 'edit-employee.html'
})

export class EmployerEditEmployeePage implements OnInit {

  companyCredit: any;
  errorMessage: string;
  editingEmployee: Employee;
  employeeBalance: any;
  loaded: boolean;

  constructor(
    private client: Client,
    private employer: EmployerService,
    private navCtrl: CustomNavController,
    private modalCtrl: ModalController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private tidyCurrencyPipe: TidyCurrencyPipe
  ) {}

  async ngOnInit() {
    this.rightSidePanelService.setDialogLoading(true);
    this.rightSidePanelService.setDialogPageTitle('Employee');
    this.loaded = false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.companyCredit = dialogParams?.companyCredit || await this.getCompanyCredit();
    this.editingEmployee = dialogParams.employee;
    const shouldGetEmployeeBalance = dialogParams?.shouldGetEmployeeBalance;
    if (shouldGetEmployeeBalance) {
      this.employeeBalance = await this.getEmployeeBalance(this.editingEmployee.employee_customer_id);
    } else {
      this.employeeBalance = dialogParams.employeeBalance;
    }
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getCompanyCredit() {
    const billingData = await this.client.getBillingData();
    return billingData.balance;
  }

  async getEmployeeBalance(clientId) {
    const employeeCreditBalance = await this.employer.getEmployeeCreditBalance();
    const balance = employeeCreditBalance.find(client => clientId == client.customer.id);
    return balance;
  }

  async deleteEmployee() {
    const confirmSubject = new Subject<Boolean>();
    const modal = await this.modalCtrl.create({
      component: RemoveEmployeeComponent,
      componentProps: { confirmSubject },
      cssClass: 'tidy-transparent-modal'
    });
    confirmSubject.subscribe(async confirm => {
      if (confirm) {
        try {
          await this.employer.deleteEmployee(this.editingEmployee.id);
          await this.employer.listEmployees(true);
        } catch (err) {
          this.errorMessage = err?.error?.message ?? err?.message;
        } finally {
          this.rightSidePanelService.closeRightSidePanel();
          modal.dismiss();
        }
      } else {
        modal.dismiss();
      }
    });
    modal.present();
  }

  goToEditEmployeeDetailsPage() {
    const params = {
      employee: this.editingEmployee,
      companyCredit: this.companyCredit,
      employeeBalance: this.employeeBalance
    }
    this.rightSidePanelService.navigateTo('employer-edit-employee-details', params);
  }

  goToAddCredit() {
    this.rightSidePanelService.navigateTo('employer-add-credit');
  }

  goToAddEmployeeCreditPage() {
    const params = {
      employee: this.editingEmployee,
      companyCredit: this.companyCredit
    }
    this.rightSidePanelService.navigateTo('employer-add-employee-credit', params);
  }

  async removeCredit(credit) {
    const amount = this.tidyCurrencyPipe.transform(credit.amount_sent - credit.amount_used);
    const params: ConfirmPageParamsModel = {
      title: 'Remove Credit',
      body: 'Remove ' + amount + ' credit? This will allow you to add this credit for a different employee.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: await this.confirmRemoveCredit.bind(this, credit),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmRemoveCredit(credit) {
    try {
      const payload = {
        employee_customer_id: this.editingEmployee.employee_customer_id,
        credit_ids: [credit.credit_id]
      }
      await this.employer.removeEmployeeCredit(payload);
      this.employeeBalance = await this.getEmployeeBalance(this.editingEmployee.employee_customer_id);
      await this.employer.listEmployees(true);
      this.rightSidePanelService.closeRightSidePanel();
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

}

import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { clearStack } from 'src/shared/components/loading/loading';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarHandler implements OnDestroy{

  private routerSub: Subscription;
  constructor(
    private router: Router
  ) {
  }

  setup() {
    this.routerSub = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe(() => {
      clearStack();
    });
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }
}
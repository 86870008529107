export const messagesMockData = [
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519219",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113200,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2519219,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 727992,
                  "type": "text",
                  "content": {
                      "text": "Confirming we are on track for this back to back",
                      "files": []
                  },
                  "sent_at": "2025-03-26T19:16:17Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113200,
          "state": "completed",
          "address_id": 623549,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-26T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2562011",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2145739,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2562011,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 727809,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at [str] FirstStreet-452 #3."
                  },
                  "sent_at": "2025-03-26T16:46:43Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2145739,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-26T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2530783",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2123308,
                  "team_id": 227479,
                  "homekeeper_id": 230481,
                  "homekeeper_job_id": 2530783,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 727542,
                  "type": "text",
                  "content": {
                      "text": "Was this service completed? Shannon was there yesterday and reported the spa was very dirty. It smelled like spoiled eggs. ",
                      "files": []
                  },
                  "sent_at": "2025-03-26T14:49:32Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230481,
                      "type": "Homekeeper",
                      "first_name": "Tiffany",
                      "last_name": "Humphrey"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              ]
          }
      ],
      "job": {
          "id": 2123308,
          "state": "completed",
          "address_id": 623545,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-25T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2561411",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2146299,
                  "team_id": 227476,
                  "homekeeper_id": 230478,
                  "homekeeper_job_id": 2561411,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 727121,
                  "type": "text",
                  "content": {
                      "text": "Hi Lisa, there is no dirty laundry at Bookbinder. The owner took care of it.",
                      "files": []
                  },
                  "sent_at": "2025-03-25T19:04:27Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230478,
                      "type": "Homekeeper",
                      "first_name": "Lisa",
                      "last_name": "Daniels"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              ]
          }
      ],
      "job": {
          "id": 2146299,
          "state": "cancelled_once",
          "address_id": 623531,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-25T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2562321",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2146554,
                  "team_id": 230555,
                  "homekeeper_id": 233559,
                  "homekeeper_job_id": 2562321,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 726875,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-25T17:05:39Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 233559,
                      "type": "Homekeeper",
                      "first_name": "Emily",
                      "last_name": "Naranjo"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 233559,
                      "type": "Homekeeper",
                      "first_name": "Emily",
                      "last_name": "Naranjo"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2146554,
          "state": "completed",
          "address_id": 623561,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-25T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556541",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2130884,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2556541,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 726399,
                  "type": "text",
                  "content": {
                      "text": "For Jenn put bottle of soap in bathroom thanks it’s in supply closet-Erik",
                      "files": []
                  },
                  "sent_at": "2025-03-24T21:13:35Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2130884,
          "state": "scheduled",
          "address_id": 624945,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-27T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556540",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2137676,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2556540,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 725735,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-24T15:14:36Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2137676,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-24T14:30:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519519",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113593,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2519519,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 725154,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-23T20:04:05Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113593,
          "state": "completed",
          "address_id": 623555,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-23T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541745",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2132573,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2541745,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 725149,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-23T20:02:26Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2132573,
          "state": "completed",
          "address_id": 623544,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-23T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519662",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113941,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2519662,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 725062,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-23T19:08:13Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113941,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-23T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2555462",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2138205,
                  "team_id": 248168,
                  "homekeeper_id": 251206,
                  "homekeeper_job_id": 2555462,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-04-05T20:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 724272,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-22T19:46:20Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 251206,
                      "type": "Homekeeper",
                      "first_name": "Romni",
                      "last_name": "Portocarrero"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 251206,
                      "type": "Homekeeper",
                      "first_name": "Romni",
                      "last_name": "Portocarrero"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2138205,
          "state": "completed",
          "address_id": 623553,
          "is_private": true,
          "timezone_name": "America/Phoenix",
          "start_datetime": "2025-03-22T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2539729",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2130873,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2539729,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 724235,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-22T19:29:34Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2130873,
          "state": "completed",
          "address_id": 623569,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-22T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2533564",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2125509,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2533564,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 724135,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-22T18:20:26Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2125509,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-22T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2554997",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140558,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2554997,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-04-06T20:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 723507,
                  "type": "text",
                  "content": {
                      "text": "Notes from the owner: Housekeeping Notes:\nConsider placing extra blankets in the primary bedroom armoire and guest bedroom dresser for accessibility.\nExtra towels should not be stored on the floor of the guest closet, as it feels unsanitary.\nHousekeepers are doing a great job, but:\nTop of the kitchen cabinets need attention (greasy).\nSome pots were put away wet and dirty.",
                      "files": []
                  },
                  "sent_at": "2025-03-21T21:52:32Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140558,
          "state": "cancelled_once",
          "address_id": 623531,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-23T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519181",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113154,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2519181,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-04-04T20:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 723265,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-21T18:30:32Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113154,
          "state": "completed",
          "address_id": 623552,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-21T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540442",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113465,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2540442,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 723243,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-21T18:11:09Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113465,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-22T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2538408",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2129647,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2538408,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 722294,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-20T20:00:46Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2129647,
          "state": "completed",
          "address_id": 623544,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-20T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2559769",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2144958,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2559769,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 2,
              "last_chat_room_message": {
                  "id": 722277,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-20T19:40:19Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2144958,
          "state": "completed",
          "address_id": 623564,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-20T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2543141",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2133732,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2543141,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 720982,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-19T18:46:16Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2133732,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-19T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519605",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113802,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2519605,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-04-02T20:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 2,
              "last_chat_room_message": {
                  "id": 719747,
                  "type": "text",
                  "content": {
                      "text": "Pickleball balls and paddles will arrive 3/19",
                      "files": []
                  },
                  "sent_at": "2025-03-18T15:17:11Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544010,
                      "type": "CustomerMember",
                      "first_name": "Felix",
                      "last_name": "Campos"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113802,
          "state": "completed",
          "address_id": 623560,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-19T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2558317",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2124483,
                  "team_id": 230555,
                  "homekeeper_id": 233559,
                  "homekeeper_job_id": 2558317,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 719384,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-17T19:49:15Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 233559,
                      "type": "Homekeeper",
                      "first_name": "Emily",
                      "last_name": "Naranjo"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 233559,
                      "type": "Homekeeper",
                      "first_name": "Emily",
                      "last_name": "Naranjo"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2124483,
          "state": "completed",
          "address_id": 623564,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-17T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519148",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2112861,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2519148,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 719382,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-17T19:48:55Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2112861,
          "state": "completed",
          "address_id": 623561,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-17T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2538042",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2129262,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2538042,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 719348,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-17T19:27:03Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2129262,
          "state": "completed",
          "address_id": 623569,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-17T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2555553",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140990,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2555553,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 719310,
                  "type": "text",
                  "content": {
                      "text": "",
                      "files": [
                          "https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/production/message/fbcttvv6g8fe-1742235949003-45xx02gszwll/IMG_0041.jpeg"
                      ]
                  },
                  "sent_at": "2025-03-17T18:26:01Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140990,
          "state": "completed",
          "address_id": 623530,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-16T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556539",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2135941,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2556539,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 719016,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-17T15:43:58Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              ]
          }
      ],
      "job": {
          "id": 2135941,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-17T14:30:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556486",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2141868,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2556486,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718681,
                  "type": "text",
                  "content": {
                      "text": "King duvet covers 6, queen duvet covers 12, twin duvet cover 27, in need of dishwasher pods paper towels (low) pool towels make up towels and hand towels lastly kitchen towels ",
                      "files": []
                  },
                  "sent_at": "2025-03-17T02:12:42Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2141868,
          "state": "completed",
          "address_id": 623545,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-16T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2539966",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2131082,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2539966,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718562,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-16T20:06:46Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2131082,
          "state": "completed",
          "address_id": 623559,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-16T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2555600",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2141046,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2555600,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718516,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-16T18:39:35Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2141046,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-16T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519179",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113151,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2519179,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718515,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-16T18:32:58Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113151,
          "state": "completed",
          "address_id": 623552,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-16T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541641",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2114277,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541641,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718327,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-16T15:17:37Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2114277,
          "state": "completed",
          "address_id": 627354,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-16T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556485",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2141867,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2556485,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 718144,
                  "type": "text",
                  "content": {
                      "text": "Also duvet inserts \nThere was not an input box \nKing duvet inserts: 4\nQueen duvet inserts: 11\nTwin duvet inserts: 6\nNo sponges or laundry soap ",
                      "files": []
                  },
                  "sent_at": "2025-03-15T21:25:57Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2141867,
          "state": "completed",
          "address_id": 623555,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-15T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556918",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2142281,
                  "team_id": 231274,
                  "homekeeper_id": 234283,
                  "homekeeper_job_id": 2556918,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 2,
              "last_chat_room_message": {
                  "id": 717931,
                  "type": "text",
                  "content": {
                      "text": "Also purchased $70 worth of supplies batteries, garbage bags, and paper towels. Will show in March invoice thanks",
                      "files": []
                  },
                  "sent_at": "2025-03-15T18:05:09Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 234283,
                      "type": "Homekeeper",
                      "first_name": "Erik",
                      "last_name": "Pedersen"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 234283,
                      "type": "Homekeeper",
                      "first_name": "Erik",
                      "last_name": "Pedersen"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2142281,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-15T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2542769",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113393,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2542769,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 717652,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-15T15:15:01Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113393,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-15T14:30:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556686",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2134633,
                  "team_id": 240747,
                  "homekeeper_id": 243768,
                  "homekeeper_job_id": 2556686,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 717310,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-14T20:02:06Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 243768,
                      "type": "Homekeeper",
                      "first_name": "Alexia",
                      "last_name": "Corral"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 243768,
                      "type": "Homekeeper",
                      "first_name": "Alexia",
                      "last_name": "Corral"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2134633,
          "state": "completed",
          "address_id": 623533,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-14T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2556505",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140928,
                  "team_id": 248168,
                  "homekeeper_id": 251206,
                  "homekeeper_job_id": 2556505,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 3,
              "last_chat_room_message": {
                  "id": 717293,
                  "type": "text",
                  "content": {
                      "text": "$300 total, $200 for the cleaning and $100 for the vent cleanings and the pain touch up ",
                      "files": []
                  },
                  "sent_at": "2025-03-14T19:47:31Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 251206,
                      "type": "Homekeeper",
                      "first_name": "Romni",
                      "last_name": "Portocarrero"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 251206,
                      "type": "Homekeeper",
                      "first_name": "Romni",
                      "last_name": "Portocarrero"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          },
          {
              "key": "homekeeper_job_id-2555489",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140928,
                  "team_id": 248159,
                  "homekeeper_id": 251197,
                  "homekeeper_job_id": 2555489,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 717282,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-14T19:40:50Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 251197,
                      "type": "Homekeeper",
                      "first_name": "Peak",
                      "last_name": "Perfection"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 251197,
                      "type": "Homekeeper",
                      "first_name": "Peak",
                      "last_name": "Perfection"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140928,
          "state": "completed",
          "address_id": 623553,
          "is_private": true,
          "timezone_name": "America/Phoenix",
          "start_datetime": "2025-03-14T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2555547",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140983,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2555547,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 3,
              "last_chat_room_message": {
                  "id": 716474,
                  "type": "text",
                  "content": {
                      "text": "This is the inventory information since there some stuff that is not listed on the task",
                      "files": []
                  },
                  "sent_at": "2025-03-13T23:06:42Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140983,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-13T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2522575",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2116504,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2522575,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 716289,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-13T19:35:04Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2116504,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-13T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519644",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113870,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2519644,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 716201,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-13T18:35:14Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113870,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-13T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541718",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113435,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2541718,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 715896,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-13T15:21:02Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113435,
          "state": "completed",
          "address_id": 624945,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-13T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541640",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113464,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541640,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-03-26T17:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 714990,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-12T15:06:33Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113464,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-12T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2555513",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140950,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2555513,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 2,
              "last_chat_room_message": {
                  "id": 714756,
                  "type": "text",
                  "content": {
                      "text": "Very low on inventory ",
                      "files": []
                  },
                  "sent_at": "2025-03-11T23:20:45Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140950,
          "state": "completed",
          "address_id": 623529,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-13T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2554990",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2140547,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2554990,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 4,
              "last_chat_room_message": {
                  "id": 714724,
                  "type": "text",
                  "content": {
                      "text": "",
                      "files": [
                          "https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/production/message/6w58fqcfh6f8-1741728634811-iin8qebx0kny/image.jpg"
                      ]
                  },
                  "sent_at": "2025-03-11T21:31:02Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2140547,
          "state": "completed",
          "address_id": 623530,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-11T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541639",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113413,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541639,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-03-25T17:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 714237,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-11T15:15:57Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113413,
          "state": "completed",
          "address_id": 627354,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-11T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519823",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2114176,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2519823,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 713097,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-09T19:12:28Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2114176,
          "state": "completed",
          "address_id": 623562,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-09T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519643",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113869,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2519643,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 713035,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-09T18:07:51Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113869,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-09T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541198",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2120743,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541198,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-03-23T17:30:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 712913,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-09T15:16:19Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2120743,
          "state": "completed",
          "address_id": 627356,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-09T15:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2529649",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2122398,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2529649,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 712644,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-08T19:55:03Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2122398,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-08T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541197",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113463,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541197,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 712405,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-08T16:57:46Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113463,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-08T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519642",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113868,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2519642,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 712029,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-07T20:25:56Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113868,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-07T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519660",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113939,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2519660,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 3,
              "last_chat_room_message": {
                  "id": 711295,
                  "type": "text",
                  "content": {
                      "text": "I found this shoes in the garage.",
                      "files": []
                  },
                  "sent_at": "2025-03-06T22:45:35Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113939,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-06T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2529977",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2122667,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2529977,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 711217,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-06T21:05:19Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2122667,
          "state": "completed",
          "address_id": 623530,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-06T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540443",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2130759,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2540443,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 711000,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-06T17:36:38Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2130759,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-06T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540445",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2126079,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2540445,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 710993,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-06T17:28:41Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2126079,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-06T15:30:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519989",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2114344,
                  "team_id": 228019,
                  "homekeeper_id": 231022,
                  "homekeeper_job_id": 2519989,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 710338,
                  "type": "text",
                  "content": {
                      "text": "Harmon property is originally assigned to Lisa @ Busy Bubblez laundromat ",
                      "files": []
                  },
                  "sent_at": "2025-03-05T21:11:17Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231022,
                      "type": "Homekeeper",
                      "first_name": "Ricky",
                      "last_name": "Salazar"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231022,
                      "type": "Homekeeper",
                      "first_name": "Ricky",
                      "last_name": "Salazar"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2114344,
          "state": "cancelled",
          "address_id": 623549,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-05-03T18:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519839",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2114192,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2519839,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 709346,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-04T20:40:15Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2114192,
          "state": "completed",
          "address_id": 623544,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-04T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540926",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113396,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2540926,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 708917,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-04T16:18:15Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113396,
          "state": "completed",
          "address_id": 627356,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-04T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519641",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113866,
                  "team_id": 228010,
                  "homekeeper_id": 231013,
                  "homekeeper_job_id": 2519641,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 708515,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-03T21:15:11Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 231013,
                      "type": "Homekeeper",
                      "first_name": "Maria",
                      "last_name": "Mendoza"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113866,
          "state": "completed",
          "address_id": 623558,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-03T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2532591",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2124772,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2532591,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 708423,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-03T20:09:36Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2124772,
          "state": "completed",
          "address_id": 623531,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-03T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540925",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2124402,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2540925,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 708064,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-03T16:52:18Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2124402,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-03T15:30:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2530947",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2123453,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2530947,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 707426,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-02T19:41:23Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2123453,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-03-02T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2540924",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113462,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2540924,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 707342,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-02T18:23:01Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113462,
          "state": "completed",
          "address_id": 631197,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-02T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2545304",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2135687,
                  "team_id": 231286,
                  "homekeeper_id": 234295,
                  "homekeeper_job_id": 2545304,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": "2025-03-16T17:00:00Z",
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 707097,
                  "type": "text",
                  "content": {
                      "text": "Guest checks out at 1",
                      "files": []
                  },
                  "sent_at": "2025-03-02T14:49:29Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544235,
                      "type": "CustomerMember",
                      "first_name": "Shawn",
                      "last_name": "Cunningham"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 234295,
                      "type": "Homekeeper",
                      "first_name": "Danish",
                      "last_name": "Shaik"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544235,
                      "type": "CustomerMember",
                      "first_name": "Shawn",
                      "last_name": "Cunningham"
                  }
              ]
          }
      ],
      "job": {
          "id": 2135687,
          "state": "completed",
          "address_id": 627354,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-02T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2545791",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2135686,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2545791,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 707096,
                  "type": "text",
                  "content": {
                      "text": "Guest checks out at 1pm today ",
                      "files": []
                  },
                  "sent_at": "2025-03-02T14:48:38Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 544235,
                      "type": "CustomerMember",
                      "first_name": "Shawn",
                      "last_name": "Cunningham"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 544235,
                      "type": "CustomerMember",
                      "first_name": "Shawn",
                      "last_name": "Cunningham"
                  }
              ]
          }
      ],
      "job": {
          "id": 2135686,
          "state": "completed",
          "address_id": 627354,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-02T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2543264",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2133743,
                  "team_id": 241339,
                  "homekeeper_id": 244360,
                  "homekeeper_job_id": 2543264,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 706386,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-03-01T17:41:09Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 244360,
                      "type": "Homekeeper",
                      "first_name": "Arif",
                      "last_name": "Yaqoobi"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2133743,
          "state": "completed",
          "address_id": 624945,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-03-01T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2526938",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2120284,
                  "team_id": 227480,
                  "homekeeper_id": 230482,
                  "homekeeper_job_id": 2526938,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 705526,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-28T19:20:01Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230482,
                      "type": "Homekeeper",
                      "first_name": "SnF",
                      "last_name": "LLC"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2120284,
          "state": "completed",
          "address_id": 623548,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-02-28T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2542786",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2133474,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2542786,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 703348,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-26T20:39:38Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2133474,
          "state": "completed",
          "address_id": 623559,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-02-26T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2542477",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2133182,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2542477,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 2,
              "last_chat_room_message": {
                  "id": 702825,
                  "type": "text",
                  "content": {
                      "text": "No paper towels or toilet paper ",
                      "files": []
                  },
                  "sent_at": "2025-02-25T21:10:01Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              ]
          }
      ],
      "job": {
          "id": 2133182,
          "state": "completed",
          "address_id": 623545,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-02-25T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2537810",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2127185,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2537810,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 702328,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-25T16:07:07Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2127185,
          "state": "completed",
          "address_id": 627356,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-02-25T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519678",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113980,
                  "team_id": 227475,
                  "homekeeper_id": 230477,
                  "homekeeper_job_id": 2519678,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 702029,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-24T20:14:02Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 230477,
                      "type": "Homekeeper",
                      "first_name": "Harold",
                      "last_name": "Lesure"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113980,
          "state": "completed",
          "address_id": 623564,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-02-24T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2519837",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2114190,
                  "team_id": 218880,
                  "homekeeper_id": 221879,
                  "homekeeper_job_id": 2519837,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 702023,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-24T19:49:27Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  },
                  {
                      "id": 221879,
                      "type": "Homekeeper",
                      "first_name": "Aurelia",
                      "last_name": "Ili"
                  }
              ]
          }
      ],
      "job": {
          "id": 2114190,
          "state": "completed",
          "address_id": 623544,
          "is_private": true,
          "timezone_name": "America/Los_Angeles",
          "start_datetime": "2025-02-24T19:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2536215",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2113412,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2536215,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 701783,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-24T16:34:48Z",
                  "is_read": false,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2113412,
          "state": "completed",
          "address_id": 627354,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-02-24T16:00:00Z"
      }
  },
  {
      "type": "homekeeper_job",
      "chat_rooms": [
          {
              "key": "homekeeper_job_id-2541637",
              "type": "homekeeper_job",
              "data": {
                  "job_id": 2132039,
                  "team_id": 235230,
                  "homekeeper_id": 238245,
                  "homekeeper_job_id": 2541637,
                  "customer_account_id": 543457
              },
              "proxy_type": null,
              "expires_at": null,
              "chat_phone_number": null,
              "count_chat_room_messages": 1,
              "last_chat_room_message": {
                  "id": 701767,
                  "type": "text",
                  "content": {
                      "text": "I have just arrived at your home."
                  },
                  "sent_at": "2025-02-24T16:28:06Z",
                  "is_read": true,
                  "is_internal": false,
                  "from_member": {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  }
              },
              "all_chat_room_members": [
                  {
                      "id": 238245,
                      "type": "Homekeeper",
                      "first_name": "Jennifer",
                      "last_name": "Hume"
                  },
                  {
                      "id": 541222,
                      "type": "CustomerMember",
                      "first_name": "Riley",
                      "last_name": "Frederickson"
                  }
              ]
          }
      ],
      "job": {
          "id": 2132039,
          "state": "completed",
          "address_id": 627355,
          "is_private": true,
          "timezone_name": "US/Eastern",
          "start_datetime": "2025-02-24T15:30:00Z"
      }
  }
]
import { Component } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

@Component({
  templateUrl: 'get-started.html'
})

export class EmployerGetStartedPage {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;

  constructor(
    private navCtrl: CustomNavController
  ) {}

  goToPage(page) {
    this.navCtrl.navigateForward(page);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header>
      <tidy-text
        [title]="'Dashboard'">
      </tidy-text>
    </tidy-desktop-header>

    <ion-skeleton-text *ngIf="!loaded" animated="true" style="width: calc(100% + 60px); height: 100vh; margin-left: -20px"></ion-skeleton-text>
    
    <ng-container *ngIf="loaded">

      <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
        <tidy-text
          [header]="'Company Credit: ' + (companyCredit | tcurrency)">
        </tidy-text>
        <tidy-text
          [body]="'Add Credit'"
          class="link padding-left"
          (action)="goToAddCredit()">
        </tidy-text>
      </tidy-row>

      <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
        <tidy-text
          [header]="'Employees'">
        </tidy-text>
        <tidy-text
          class="link"
          (action)="addEmployee()"
          [body]="'Add Employee'">
        </tidy-text>
      </tidy-row>

      <div class="desktop-nav-search full-width">
        <form [formGroup]="form" novalidate action="#">
          <tidy-input
            #searchInput
            class="search-input"
            [icon]="'assets/svg/search-outline.svg'"
            [activeIcon]="'assets/svg/search-tidy-green.svg'"
            [noBorder]="true"
            type="search"
            formControlName="search"
            [label]="'Search'"
            [form]="form">
          </tidy-input>
        </form>
      </div>
      
      <tidy-row>
        <tidy-table
          [dataSource]="rows"
          [displayedColumns]="headers"
          [columnslabels]="headers"
          (sortChange)="sortBy($event)">
        </tidy-table>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Contact sales to learn more.'">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';

import { ClientSettingsModel } from 'src/models/client.model';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'account-type.html'
})

export class AccountTypePage implements OnInit {

  accountTypes: TidySelectStringValueModel[];
  simpleMode: boolean;
  form: UntypedFormGroup;
  loaded: boolean;
  settings: ClientSettingsModel;
  updatedSettings: {profile: {account_type: string}; feature_flag: {advanced_mode: boolean}};
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private me: Me,
    private navCtrl: CustomNavController,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Account Type');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.settings = await this.getParam('settings');
    this.simpleMode = !this.settings.feature_flag.advanced_mode;
    this.accountTypes = [
      {viewValue: 'For my Home', value: 'regular'},
      {viewValue: 'I Manage an Airbnb/Rental', value: 'rental'},
      {viewValue: 'For an Office', value: 'company'}
    ];
    this.form.patchValue({
      type: this.settings.profile.account_type
    });
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async save() {
    const type = this.form.value.type;
    this.updatedSettings = {
      profile: {
        account_type: !this.simpleMode ? 'rental' : type
      },
      feature_flag: {
        advanced_mode: !this.simpleMode
      }
    }
    localStorage.setItem('accountType', type);
    localStorage.setItem('isAdvancedMode', this.simpleMode ? 'false' : 'true');
    await this.client.saveClientSettings(this.updatedSettings);
    await this.me.load(true);
    const params = this.mountSuccessPageParams();
    localStorage.setItem('shouldReloadLocation', 'true');
    this.rightSidePanelService.navigateTo('success', params, SuccessPage);
  }

  mountSuccessPageParams() {
    return {
      header: 'Account Type Updated',
      body: '',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Welcome to TIDY!'"
    [class]="extraClass"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card style="padding:0px">
      <tidy-row>
        <tidy-video
          #rentalVideo
          src="https://d30thx9uw6scot.cloudfront.net/video/Rental%20Overview/rental%20overview%20after%20signup%20preview3.mp4">
        </tidy-video>
      </tidy-row>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToRentalDemoPage()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/calendar.svg'"
                    [ngStyle]="{'width': '35px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Schedule a Set Up Call (Recommended)'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Getting your cleaning & maintenance set up right is hard. Our team can do it for you, free!'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToViewApp()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/app.svg'"
                    [ngStyle]="{'width': '45px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'View App'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Book cleanings & inspections, create To-Dos, integrate AirBnB + more.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToBookJob()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/book-job.svg'"
                    [ngStyle]="{'width': '30px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Book Instantly'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Find a pro online. We recommend talking to sales first but you can book now.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <ng-container *ngIf="!windowService?.isDesktopRes">
      <tidy-row [align]="'center'" class="extra-bottom-padding" style="padding-top: 10px">
        <tidy-text
          [title]="'Why TIDY?'">
        </tidy-text>
      </tidy-row>
      <tidy-alert>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/search-calendar-enabled.svg'"
                style="width:20px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Make Cleaning & Maintenance Easy'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Use automations to book cleanings, inspections, handymen etc. on demand or around your guest schedule.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-alert>
      <tidy-alert>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/user-red.svg'"
                style="width:20px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Use Your Pros or Find New Ones'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Set your preferences, and have us do the scheduling. We can even help find new pros.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-alert>
      <tidy-alert>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/concierge.svg'"
                style="width:20px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Human-in-the-loop Tasks'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'TIDY Concierge can perform tasks automatically based on your workflows. Less expensive than hiring.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-alert>
      <tidy-alert>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/integrate.svg'"
                style="width:20px">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="'Integrate Your Tools'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'TIDY supports all popular property management tools.'">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-alert>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper *ngIf="windowService?.isDesktopRes">
    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text
        [title]="'Why TIDY?'">
      </tidy-text>
    </tidy-row>
    <tidy-row class="extra-top-padding">
      <ion-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/search-calendar-enabled.svg'"
            style="width:20px">
          </tidy-image>
        </tidy-row>
        <ion-col>
          <tidy-row>
            <tidy-text
              style="line-height:2;"
              [header]="'Make Cleaning & Maintenance Easy'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Use automations to book cleanings, inspections, handymen etc. on demand or around your guest schedule.'">
            </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-row>
    <tidy-row>
      <ion-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/user-red.svg'"
            style="width:20px">
          </tidy-image>
        </tidy-row>
        <ion-col>
          <tidy-row>
            <tidy-text
              style="line-height:2;"
              [header]="'Use Your Pros or Find New Ones'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Set your preferences, and have us do the scheduling. We can even help find new pros.'">
            </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-row>
    <tidy-row>
      <ion-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/concierge.svg'"
            style="width:20px">
          </tidy-image>
        </tidy-row>
        <ion-col>
          <tidy-row>
            <tidy-text
              style="line-height:2;"
              [header]="'Human-in-the-loop Tasks'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY Concierge can perform tasks automatically based on your workflows. Less expensive than hiring.'">
            </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-row>
    <tidy-row>
      <ion-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/integrate.svg'"
            style="width:20px">
          </tidy-image>
        </tidy-row>
        <ion-col>
          <tidy-row>
            <tidy-text
              style="line-height:2;"
              [header]="'Integrate Your Tools'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY supports all popular property management tools.'">
            </tidy-text>
          </tidy-row>
        </ion-col>
      </ion-row>
    </tidy-row>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [widerRightColumn]="true" [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Reply to Concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <ng-container *ngIf="windowService.isDesktopRes">
        <tidy-alert>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
              class="header-size">
            </tidy-image>
            <tidy-text
              *ngIf="item?.type !== 'support.concierge_task'"
              [header]="item?.header_text">
            </tidy-text>
            <tidy-text
              *ngIf="item?.type == 'support.concierge_task'"
              [header]="item?.components[0]?.value?.subject">
            </tidy-text>
          </tidy-row>

          <ng-container *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
            <tidy-row>
              <tidy-text
                *ngIf="item?.components[0]?.value?.status === 'waiting_on_tidy'"
                [body]="'<i>Waiting on TIDY</i>'">
              </tidy-text>
              <tidy-text
                *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'"
                [body]="'<b><i>Waiting on You</i><b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                *ngIf="item?.metadata?.request_data?.scheduled == 'Immediate' || item?.metadata?.request_data?.scheduled == 'immediate'"
                [body]="'<i>Scheduled to do immediately</i>'">
              </tidy-text>
              <ng-container *ngIf="item?.metadata?.request_data?.scheduled !== 'Immediate' && item?.metadata?.request_data?.scheduled !== 'immediate'">
                <tidy-text
                  [body]="'<i>Scheduled to do on'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="(item?.metadata?.request_data?.scheduled | customdate: 'M/D/Y') + '</i>'">
                </tidy-text>
              </ng-container>
            </tidy-row>
          </ng-container>
        </tidy-alert>

        <tidy-card *ngFor="let comment of item?.components?.[0]?.value?.comments">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [header]="((comment?.user_type === 'Customer' || comment?.user_type === 'customer') ? 'You' : 'TIDY Concierge')">
            </tidy-text>
            <tidy-text
              [helper]="comment?.created_at | customdate:'M/D h:mma'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [checkLineBreaks]="true"
              [linkify]="true"
              [body]="comment?.content">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="comment?.attachments?.length > 0">
            <tidy-row>
              <tidy-text body="Attachments:"> </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let attachment of comment?.attachments">
              <tidy-text
                [body]="attachment.split('/').pop()"
                (action)="openAttachment(attachment)"
                class="link">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-top-padding">
              <tidy-textarea
                [label]="'Reply'"
                formControlName="content"
                [form]="form.controls.content"
                [submitted]="submitted"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [errorMessage]="'Please enter a reply.'">
              </tidy-textarea>
            </tidy-row>
          </form>
          <tidy-row class="field-top-padding">
            <tidy-text
              [actionBody]="'Add Attachment'"
              (action)="addAttachment()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="attachments.length" class="field-top-padding">
            <tidy-row>
              <tidy-text
                [body]="'Attachments:'">
              </tidy-text>
            </tidy-row>
            <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
              <tidy-text
                [body]="attachment.filename">
              </tidy-text>
              <tidy-text
                [actionBody]="'remove'"
                (action)="removeAttachment(i)"
                class="link red">
              </tidy-text>
            </tidy-two-column-row>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Send Reply'"
          [action]="send.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="!windowService.isDesktopRes">
        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="concierge.getStatusIcon(item?.components[0]?.value?.status)"
              class="header-size">
            </tidy-image>
            <tidy-text
              *ngIf="item?.type !== 'support.concierge_task'"
              [header]="item?.header_text">
            </tidy-text>
            <tidy-text
              *ngIf="item?.type == 'support.concierge_task'"
              [header]="item?.components[0]?.value?.subject">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngIf="item?.components[0]?.value?.status !== 'completed' && item?.components[0]?.value?.status !== 'closed'">
            <tidy-text
              *ngIf="item?.components[0]?.value?.status === 'waiting_on_tidy'"
              [body]="'<i>Waiting on TIDY</i>'">
            </tidy-text>
            <tidy-text
              *ngIf="item?.components[0]?.value?.status === 'waiting_on_customer'"
              [body]="'<b><i>Waiting on You</i><b>'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding extra-top-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>

          <ng-container *ngIf="comments?.length > 0">
            <div class="scrollable-comments-container">
              <ng-container *ngFor="let comment of comments">
                <tidy-card>
                  <tidy-row [alignLastItemRight]="true">
                    <tidy-text
                      [header]="((comment?.user_type === 'Customer' || comment?.user_type === 'customer') ? 'You' : 'TIDY Concierge')">
                    </tidy-text>
                    <tidy-text
                      [helper]="comment?.created_at | customdate:'M/D h:mma'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [checkLineBreaks]="true"
                      [linkify]="true"
                      [body]="comment?.content">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngIf="comment?.attachments?.length > 0">
                    <tidy-row>
                      <tidy-text body="Attachments:"> </tidy-text>
                    </tidy-row>
                    <tidy-row *ngFor="let attachment of comment?.attachments">
                      <tidy-text
                        [body]="attachment.split('/').pop()"
                        (action)="openAttachment(attachment)"
                        class="link">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                </tidy-card>
              </ng-container>
            </div>
          </ng-container>

          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="field-top-padding">
              <tidy-textarea
                [label]="'Reply'"
                formControlName="content"
                [form]="form.controls.content"
                [submitted]="submitted"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [errorMessage]="'Please enter a reply.'">
              </tidy-textarea>
            </tidy-row>
          </form>
          <tidy-row class="field-top-padding">
            <tidy-text
              [actionBody]="'Add Attachment'"
              (action)="addAttachment()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="attachments.length" class="field-top-padding">
            <tidy-row>
              <tidy-text
                [body]="'Attachments:'"
              >
              </tidy-text>
            </tidy-row>
            <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
              <tidy-text
                [body]="attachment.filename">
              </tidy-text>
              <tidy-text
                [actionBody]="'remove'"
                (action)="removeAttachment(i)"
                class="link red">
              </tidy-text>
            </tidy-two-column-row>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Send Reply'"
          [action]="send.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!windowService.isDesktopRes">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="windowService.isDesktopRes">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { Schedule } from 'src/providers/schedule/schedule';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';

import { ScheduleCardModel } from 'src/models/schedule.model';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';

import { Util } from 'src/shared/util/util';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'mark-job-complete.html'
})

export class MarkJobCompletePage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  serviceDurationItems: any;
  submitted: boolean;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      price: ['', Validators.required],
      duration: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Mark Job Complete');
    this.loadData();
  }

  async loadData() {
    this.loaded = false;
    this.populateForm();
    this.loaded = true;
  }

  async populateForm() {
    this.rightSidePanelService.setDialogLoading(true);
    this.serviceDurationItems = this.getServiceDurationItems();
    const price = await this.getParam('price');
    const duration = await this.getParam('duration');
    this.form.patchValue({
      price: price,
      duration: duration
    });
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  getServiceDurationItems() {
    let array = [];
    for (let i = 1; i < 21; i++) {
      array.push({
        viewValue: i == 2 ? `${i/2} hour` : `${i/2} hours`,
        value: i/2*60
      });
    }
    return array;
  }

  async markComplete() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload: any = {
        job_amount: parseInt(this.util.removeCurrencyMask(this.form.value.price), 10),
        job_duration: this.form.value.duration
      };
      const bookingId = await this.getParam('bookingId');
      const jobId = await this.getParam('jobId');
      await this.schedule.markJobComplete(payload, bookingId);
      const successMessage = 'Job marked complete';
      this.util.showSuccess(successMessage);
      localStorage.setItem('madeUpdateToSchedule', 'true');
      this.storage.delete('moreDetailAddresses');
      const url = `past-private-job/${jobId}`;
      const component = PastPrivateJobPage;
      this.rightSidePanelService.navigateTo(url, {}, component);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

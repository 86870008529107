<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'My Prices'"
    [canGoBack]="true"
    [customBack]="windowService.isDesktopRes ? 'more/payment-options' : 'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="filtersForm">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Prices for Pros You Added'">
            </tidy-text>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'View price settings for pros you added'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              (action)="goToProSettingsPage()"
              [body]="'here'"
              class="link">
            </tidy-text>
            <tidy-text
            [body]="'.'">
          </tidy-text>
          </tidy-row>
        </tidy-card>

        <!--<tidy-row class="extra-bottom-padding">
          <tidy-filter
            formControlName="jobType"
            [items]="jobTypes"
            [fitContent]="true">
          </tidy-filter>
        </tidy-row>-->

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Prices for Find New Pros'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'These prices apply to job requests made to pros that you have not added, using the Find New Pro option. Higher prices tends to attract more/better pros for improved acceptance rates, quality, and reliability.'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-select
              [label]="'Select Property'"
              [items]="addressList"
              [form]="filtersForm.controls.addressId"
              formControlName="addressId"
              (optionChange)="changeProperty()">
            </tidy-select>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'Select Frequency'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-radio-button
              formControlName="frequency"
              [form]="filtersForm.controls.frequency"
              [items]="frequencies">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="'Select Billing Preference'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-radio-button
              formControlName="chargeType"
              [form]="filtersForm.controls.chargeType"
              [items]="billingPreference">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>

          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'<b>Your Current Selected Prices</b>'">
            </tidy-text>
          </tidy-row>

          <tidy-row *ngFor="let service of services" [alignLastItemRight]="true">
            <tidy-text
              [body]="service?.name">
            </tidy-text>
            <tidy-text
              [body]="getPrice(service)">
            </tidy-text>
          </tidy-row>

          <tidy-row [align]="'center'">
            <tidy-text
              (action)="goToEditPricesPage()"
              [body]="'Edit Prices'"
              class="link">
            </tidy-text>
          </tidy-row>

          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>
        </tidy-card>

        <tidy-row [align]="'center'">
          <tidy-text
            [helper]="'You have auto-bid enabled. These prices use auto-bid, which dynamically allocate your funds across cleaners and standbys, to improve reliability. You can set your price for anything. Just reach out to an account manager with any questions.'">
          </tidy-text>
        </tidy-row>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [noBow]="true"
    [canGoBack]="!cameFromReportAdded"
    [title]="report?.name == 'Overview' ? 'Dashboard Settings' : 'Report Settings'"
    [customBack]="report?.name == 'Overview' ? 'dashboard' : ('reports/' + report?.id)">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row *ngIf="report?.name !== 'Overview'" [align]="'center'">
      <tidy-text
        [body]="'This is a'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'<u>' + reportType + '</u>.'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'This means'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="reportType">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'will appear here. You can sort or filter'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="reportType">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'by a given column.'">
      </tidy-text>
    </tidy-row>

    <tidy-card *ngIf="report?.name !== 'Overview'">
      <tidy-row [alignLastItemRight]="true">
        <tidy-image
          [src]="'assets/img/document.svg'"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="'Report Name'">
        </tidy-text>
        <tidy-text
          [body]="'Edit'"
          class="link"
          (action)="goToEditReportName()">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="report?.name">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-row>
      <tidy-text
        [body]="'Columns'">
      </tidy-text>
    </tidy-row>

    <!--TODO add this for drag & drop
    <tidy-row [alignLastItemRight]="true">
      <tidy-row [alignLastItemRight]="true" class="full-width">
        <tidy-text
          [header]="'Columns'">
        </tidy-text>
        <tidy-text
          [body]="'Edit Order'">
        </tidy-text>
      </tidy-row>
      <tidy-toggle [toggled]="editOrder" (toggleChanged)="editOrder = !editOrder" class="extra-left-margin"></tidy-toggle>
    </tidy-row>-->

    <ng-container *ngFor="let field of report?.settings?.fields">
      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-image
            *ngIf="editOrder"
            class="header-size"
            [src]="'assets/svg/menu-tidy-green.svg'">
          </tidy-image>
          <tidy-text
            [header]="field?.field_name">
          </tidy-text>
          <tidy-text
            *ngIf="!field?.is_field_visible"
            style="margin-left:5px"
            [body]="'(hidden)'">
          </tidy-text>
          <tidy-text
            [body]="'Edit'"
            class="link"
            (action)="goToEditReportColumn(field)">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="!editOrder">
          <ng-container *ngIf="report?.settings?.sort_by_field_keys[0]?.field_key == field?.field_key">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [helper]="'Sort column:'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                 [src]="report?.settings?.sort_by_field_keys[0]?.sort == 'asc' ? 'assets/svg/chevron-up-circle-outline.svg' : 'assets/svg/chevron-down-circle-outline.svg'"
                 class="body-size">
               </tidy-image>
              <tidy-text
                [body]="(report?.settings?.sort_by_field_keys[0]?.sort == 'asc' ? 'Ascending' : 'Descending') +
                ' order'">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngFor="let filter of report?.settings?.filters">
            <tidy-row *ngIf="filter?.dashboard_report_type_field_id == field?.dashboard_report_type_field_id" class="extra-top-padding">
              <tidy-text
                [helper]="'Filter column:'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="filter?.dashboard_report_type_field_id == field?.dashboard_report_type_field_id" class="vertical-align-center">
              <tidy-image
                [src]="'assets/svg/funnel-outline.svg'"
                class="body-size">
               </tidy-image>
              <tidy-text
                *ngIf="field?.type !== 'date'"
                [body]="(filter?.operator | titlecase) + ': ' + (filter?.value | titlecase)">
              </tidy-text>
              <tidy-text
                *ngIf="field?.type == 'date'"
                [body]="'Date filter applied'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </ng-container>
      </tidy-card>
    </ng-container>

    <tidy-button
      *ngIf="cameFromReportAdded"
      [action]="goToReport.bind(this)"
      [text]="'View Report'"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="clientHubUuId == undefined"
    [noBow]="clientHubUuId !== undefined"
    [title]="'Book Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-alert>
        <tidy-row [alignLastItemRight]="true">
          <div style="width:85%">
            <tidy-text
              [header]="(proposal?.service_details?.service_name | titlecase)">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [body]="(proposal?.service_details?.price | tcurrency)">
            </tidy-text>
          </div>
        </tidy-row>

        <tidy-row style="width:85%">
          <tidy-text
            [body]="proposal?.description">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-card>
        <tidy-row>
          <tidy-text
            [header]="'Choose Property to Book'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <form [formGroup]="form">
            <tidy-radio-button
              formControlName="address"
              [form]="form.controls.address"
              [items]="addressItems"
              [submitted]="submitted"
              [errorMessage]="'Please select a property.'">
            </tidy-radio-button>
          </form>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [action]="chooseAddress.bind(this)"
        [text]="'Next'"
        class="primary">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Nevermind, Book Later'"
          (action)="goToClientHub()"
          class="link">
        </tidy-text>
      </tidy-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>

export const environment = {
  "production": true,
  "api_secure": "https://api-production.gotidy.com/",
  "api_url": "https://api-production.gotidy.com/",
  "aws_access_key_id": "AKIA236WTUK5WKWKVQGQ",
  "aws_secret_access_key": "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",
  "aws_s3_bucket": "samantha-temp-file-uploads/production",
  "aws_s3_bucket_url": "https://samantha-temp-file-uploads.s3-us-west-2.amazonaws.com/production/",
  "aws_s3_region_bucket": "us-west-2",
  "client_id_login": "8dhtjturhf878de9fd627395520cd6115914259b9690e4c949d0e13180cd15d6",
  "client_id_signup": "8dd3ef2594cc297aa25566d41f499c19158fa9739911cffb4eecdc4d4cc2f2e2",
  "client_secret_login": "833gefurhf878de9fd627395520cd6115914259b9690e4c949d0e13180cd15d6",
  "client_secret_signup": "bf16a3b7019f7d0e1878b37a511480878c8dc55031e91f68a0f5f32b3ccf32e0",
  "customer_portal": "https://app.tidy.com/",
  "facebook_app_key": "2034786710085832",
  "facebook_pixel_id": "1708273092789855",
  "firebase_project_number": "195044422876",
  "google_analytics_id": "AW-968467335",
  "google_geocode_api": "https://maps.googleapis.com/maps/api/geocode/json?place_id=",
  "google_geocode_key": "&key=AIzaSyA_mFB5HA2_m7rlRSg4tYuLA-Jy9TbLFtQ",
  "launchdarkly_key": "591c4d4a4c9bf909f5a8887c",
  "loggly_tag": "fe-ca-production",
  "one_signal_app_id": "c7727571-87f5-4620-b586-365a36923134",
  "segment_key": "zJAXNWVPF1aTrqD0TNNIRKe0IqQvNtpt",
  "stripe_key": "pk_live_YQkaA3G3gyUtAloQWy3FAnny",
  "version": "",
  "thumbtack_auth_token": "VGlkeTpOa29XQWUzX1ZTQktZR3hUVnN4ZzhtVkJPX2drbUZUcmJqWDFrRkpmUDRnPQ==",
  "sentry_auth_token": "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",
  "environment": "production"
}

import 'zone.js/plugins/zone-error';

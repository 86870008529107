import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import validationUtils from 'src/shared/util/validation-utils';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';

import { Loading } from 'src/shared/components/loading/loading';
import { Booking, BookingResponse } from 'src/providers/booking/booking';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { JobCard } from 'src/models/schedule.model';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'assign-pro.html',
  encapsulation: ViewEncapsulation.None
})

export class AssignProPage {

  addressId: any;
  addNewProTrigger: boolean = false;
  errorMessage: string;
  existingOrNewItems: any[];
  headerText: string;
  job: JobCard;
  booking: BookingResponse;
  loaded: boolean;
  newProForm: UntypedFormGroup;
  form: UntypedFormGroup;
  proName: string;
  privateProItems: any[];
  privatePros: any;
  submitButtonText: string;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private modalController: ModalController,
    private pros: Pros,
    private fb: UntypedFormBuilder,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    private windowService: WindowService,
    private bookings: Booking
  ) {
    this.form = this.fb.group({
      privatePro: [''],
      name: [''],
      email: [''],
      phone: [''],
      addType: [''],
      existingOrNew: ['']
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadData();
  }

  @Loading('', true)
  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.loaded = false;
    this.job = await this.getParam('job');
    this.booking = await this.getParam('booking');
    this.addressId = await this.getParam('addressId');
    const pros = await this.getParam('privateProsList');
    this.privateProItems = this.getPrivateProItems(pros);
    this.existingOrNewItems = this.getExistingOrNewItems();
    const type = this.privateProItems.length ? 'existingPro' : 'newPro';
    this.setTexts(type);
    this.form.patchValue({
      existingOrNew: type
    });
    this.setFormValidation(type);
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  setFormValidation(type) {
    if (type == 'existingPro') {
      this.form.controls.privatePro.setValidators([Validators.required]);
      this.form.controls.name.clearValidators();
      this.form.controls.name.updateValueAndValidity();
      this.form.controls.email.clearValidators();
      this.form.controls.email.updateValueAndValidity();
      this.form.controls.phone.clearValidators();
      this.form.controls.phone.updateValueAndValidity();
    } else {
      this.form.controls.name.setValidators([Validators.required]);
      this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
      this.form.controls.phone.setValidators([Validators.minLength(10)]);
      this.form.controls.privatePro.clearValidators();
      this.form.controls.privatePro.updateValueAndValidity();
    }
  }

  setTexts(type) {
    let hasProsAssigned = false;
    if (this.job.template_data?.homekeepers?.length > 0) {
      hasProsAssigned = this.job.template_data?.homekeepers[0]?.text !== 'Best Available Pro';
    }
    this.headerText = hasProsAssigned ? 'Change': 'Assign';
    this.rightSidePanelService.setDialogPageTitle(this.headerText + ' Pro');
    this.submitButtonText = this.getSubmissionButtonText(type);
  }

  getSubmissionButtonText(type) {
    if (type == 'newPro') {
      return this.headerText == 'Change' ? 'Add & Change Pro' : 'Add & Assign Pro';
    } else if (type == 'existingPro') {
      return this.headerText == 'Change' ? 'Change Pro' : 'Assign Pro';
    }
  }

  getPrivateProItems(pros) {
    const privateProItems = [];
    pros.map((pro) => {
      privateProItems.push({viewValue: pro.name, value: pro.id})
    });
    return privateProItems;
  }

  getExistingOrNewItems() {
    return [
      {
        viewValue: (this.headerText == 'Change' ? 'Change to' : 'Assign') + ' an existing pro' ,
        value: 'existingPro'
      },
      {
        viewValue: 'Add & ' + (this.headerText == 'Change' ? 'change to' : 'assign') + ' a new pro',
        value: 'newPro'
      }
    ];
  }

  selectPro(proId) {
    this.proName = proId.viewValue;
  }

  @Loading('', true)
  async confirm() {
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.privatePro == 0) {
      const newPro = await this.addNewPro();
      await this.assignToPro(newPro);
    } else {
      await this.assignToPro({
        name: this.proName,
        id: this.form.value.privatePro
      });
    }
  }

  async addNewPro() {
    const servicetypes = [1];
    if (!servicetypes.includes(+this.booking.service_type_details.id)) {
      servicetypes.push(+this.booking.service_type_details.id);
    }
    try {
      const params = {
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
        add_to_address_homekeeper_list: {
          address_id: this.addressId,
          service_type_ids: servicetypes
        }
      };
      if(!this.form.value.phone){
        delete params.phone;
      }
      const pro = await this.pros.addNewPro(params);
      return pro;
    } catch ( err ) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async assignToPro(pro) {
    try{
      const proDetails = await this.pros.getProDetail(pro.id);
      await this.bookings.assignPrivatePro(this.booking.id, proDetails.team.id);
      this.closePage(proDetails.homekeeper.name);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally{
      this.modalController.dismiss();
    }
  }

  closePage(proName) {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    if (this.isRightSideContent) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
    }
    const successMessage = new TranslationPipe().transform('Successfully assigned ') + proName + '!';
    this.util.showSuccess(successMessage);
  }

  selectExistingOrNew(selection) {
    this.setFormValidation(selection.value);
    this.submitButtonText = this.getSubmissionButtonText(selection.value);
  }

}

export const billsMockData = [
  {
      "id": 66328,
      "uuid": "00-01894392",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-01894392/thumbnail.png",
      "team_id": 227475,
      "amount": 8500,
      "full_paid": false,
      "amount_due": 8500,
      "created_at": "2024-08-11T01:08:12Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 66299,
              "item_type": "Job",
              "item_id": 2035554,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-10",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623552,
                      "address_name": "[str] Valley View 4200 #3047",
                      "address": "4200 South Valley View Boulevard",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 66855,
      "uuid": "00-4e85746b",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-4e85746b/thumbnail.png",
      "team_id": 228010,
      "amount": 6000,
      "full_paid": false,
      "amount_due": 6000,
      "created_at": "2024-08-14T23:29:31Z",
      "homekeeper_data": {
          "id": 231013,
          "name": "Maria Mendoza",
          "email": "lvmarymencleaningservices@gmail.com",
          "phone": "+17022459693",
          "first_name": "Maria"
      },
      "items": [
          {
              "id": 66826,
              "item_type": "Job",
              "item_id": 2038326,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-14",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623558,
                      "address_name": "[str] Silent Siesta 531",
                      "address": "531 Silent Siesta Drive",
                      "unit": null,
                      "zip": "89015",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 66896,
      "uuid": "00-c21125b2",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-c21125b2/thumbnail.png",
      "team_id": 218880,
      "amount": 10500,
      "full_paid": false,
      "amount_due": 10500,
      "created_at": "2024-08-15T20:50:19Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 66867,
              "item_type": "Job",
              "item_id": 2036139,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-15",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623549,
                      "address_name": "[str] Harmon 135 #1615",
                      "address": "The Signature at MGM Grand - Tower B",
                      "unit": null,
                      "zip": "89109",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 66897,
      "uuid": "00-f00680c5",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-f00680c5/thumbnail.png",
      "team_id": 218880,
      "amount": 20000,
      "full_paid": false,
      "amount_due": 20000,
      "created_at": "2024-08-15T23:04:50Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 66868,
              "item_type": "Job",
              "item_id": 2036149,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-15",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623544,
                      "address_name": "[str] Tacoma 4011",
                      "address": "4011 Tacoma Avenue",
                      "unit": null,
                      "zip": "89121",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67004,
      "uuid": "00-bcc08a27",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-bcc08a27/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-16T16:14:40Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 66975,
              "item_type": "Job",
              "item_id": 2036036,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-15",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623529,
                      "address_name": "[str] Greens 2432",
                      "address": "2432 Greens Avenue",
                      "unit": null,
                      "zip": "89014",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67016,
      "uuid": "00-f49601cf",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-f49601cf/thumbnail.png",
      "team_id": 218880,
      "amount": 22500,
      "full_paid": false,
      "amount_due": 22500,
      "created_at": "2024-08-16T22:18:44Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 66987,
              "item_type": "Job",
              "item_id": 2036250,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-16",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623547,
                      "address_name": "(INACTIVE) [str] Highland 120",
                      "address": "120 West Highland Drive",
                      "unit": null,
                      "zip": "89015",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67095,
      "uuid": "00-5a11bf5c",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-5a11bf5c/thumbnail.png",
      "team_id": 218880,
      "amount": 20000,
      "full_paid": false,
      "amount_due": 20000,
      "created_at": "2024-08-17T21:29:26Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 67066,
              "item_type": "Job",
              "item_id": 2036283,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-17",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623544,
                      "address_name": "[str] Tacoma 4011",
                      "address": "4011 Tacoma Avenue",
                      "unit": null,
                      "zip": "89121",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67237,
      "uuid": "00-de107a73",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-de107a73/thumbnail.png",
      "team_id": 228010,
      "amount": 6000,
      "full_paid": false,
      "amount_due": 6000,
      "created_at": "2024-08-18T20:03:47Z",
      "homekeeper_data": {
          "id": 231013,
          "name": "Maria Mendoza",
          "email": "lvmarymencleaningservices@gmail.com",
          "phone": "+17022459693",
          "first_name": "Maria"
      },
      "items": [
          {
              "id": 67208,
              "item_type": "Job",
              "item_id": 2038328,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-18",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623558,
                      "address_name": "[str] Silent Siesta 531",
                      "address": "531 Silent Siesta Drive",
                      "unit": null,
                      "zip": "89015",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67322,
      "uuid": "00-652737c4",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-652737c4/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-19T20:23:31Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 67293,
              "item_type": "Job",
              "item_id": 2039780,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-19",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623552,
                      "address_name": "[str] Valley View 4200 #3047",
                      "address": "4200 South Valley View Boulevard",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67325,
      "uuid": "00-53a890c8",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-53a890c8/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-19T22:50:56Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 67296,
              "item_type": "Job",
              "item_id": 2036005,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-19",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623555,
                      "address_name": "[str] Mardon 3322",
                      "address": "3322 West Mardon Avenue",
                      "unit": null,
                      "zip": "89139",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67326,
      "uuid": "00-a6052fc2",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-a6052fc2/thumbnail.png",
      "team_id": 218880,
      "amount": 20000,
      "full_paid": false,
      "amount_due": 20000,
      "created_at": "2024-08-19T23:11:23Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 67297,
              "item_type": "Job",
              "item_id": 2036299,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-19",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623560,
                      "address_name": "[str] Waldorf 4029",
                      "address": "4029 Waldorf Court",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67360,
      "uuid": "00-3ffd7d8e",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-3ffd7d8e/thumbnail.png",
      "team_id": 218880,
      "amount": 10500,
      "full_paid": false,
      "amount_due": 10500,
      "created_at": "2024-08-20T07:22:43Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 67331,
              "item_type": "Job",
              "item_id": 2038465,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-19",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623549,
                      "address_name": "[str] Harmon 135 #1615",
                      "address": "The Signature at MGM Grand - Tower B",
                      "unit": null,
                      "zip": "89109",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67581,
      "uuid": "00-12d34cbe",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-12d34cbe/thumbnail.png",
      "team_id": 228010,
      "amount": 6000,
      "full_paid": false,
      "amount_due": 6000,
      "created_at": "2024-08-21T20:32:22Z",
      "homekeeper_data": {
          "id": 231013,
          "name": "Maria Mendoza",
          "email": "lvmarymencleaningservices@gmail.com",
          "phone": "+17022459693",
          "first_name": "Maria"
      },
      "items": [
          {
              "id": 67552,
              "item_type": "Job",
              "item_id": 2042372,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-21",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623558,
                      "address_name": "[str] Silent Siesta 531",
                      "address": "531 Silent Siesta Drive",
                      "unit": null,
                      "zip": "89015",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67591,
      "uuid": "00-58fc9067",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-58fc9067/thumbnail.png",
      "team_id": 227479,
      "amount": 6500,
      "full_paid": false,
      "amount_due": 6500,
      "created_at": "2024-08-22T01:13:28Z",
      "homekeeper_data": {
          "id": 230481,
          "name": "Tiffany Humphrey",
          "email": "tranquilpoolslv@gmail.com",
          "phone": "+16193390781",
          "first_name": "Tiffany"
      },
      "items": [
          {
              "id": 67562,
              "item_type": "Job",
              "item_id": 2043277,
              "service_details": {
                  "name": "Pool Cleaning",
                  "type": "Pool Cleaning",
                  "date": "2024-08-21",
                  "duration_in_minutes": 60,
                  "start_time": "14:00",
                  "address": {
                      "id": 623560,
                      "address_name": "[str] Waldorf 4029",
                      "address": "4029 Waldorf Court",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67670,
      "uuid": "00-cc1a9942",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-cc1a9942/thumbnail.png",
      "team_id": 227479,
      "amount": 8500,
      "full_paid": false,
      "amount_due": 8500,
      "created_at": "2024-08-22T18:00:44Z",
      "homekeeper_data": {
          "id": 230481,
          "name": "Tiffany Humphrey",
          "email": "tranquilpoolslv@gmail.com",
          "phone": "+16193390781",
          "first_name": "Tiffany"
      },
      "items": [
          {
              "id": 67641,
              "item_type": "Job",
              "item_id": 2043094,
              "service_details": {
                  "name": "Pool Cleaning",
                  "type": "Pool Cleaning",
                  "date": "2024-08-21",
                  "duration_in_minutes": 60,
                  "start_time": "12:00",
                  "address": {
                      "id": 623548,
                      "address_name": "[str] Babylon Mill 2031",
                      "address": "2031 Babylon Mill Street",
                      "unit": null,
                      "zip": "89002",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67674,
      "uuid": "00-58edf3f8",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-58edf3f8/thumbnail.png",
      "team_id": 218880,
      "amount": 22500,
      "full_paid": false,
      "amount_due": 22500,
      "created_at": "2024-08-22T18:43:08Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 67645,
              "item_type": "Job",
              "item_id": 2036251,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-21",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623547,
                      "address_name": "(INACTIVE) [str] Highland 120",
                      "address": "120 West Highland Drive",
                      "unit": null,
                      "zip": "89015",
                      "city": "Henderson",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67678,
      "uuid": "00-875f4a01",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-875f4a01/thumbnail.png",
      "team_id": 218880,
      "amount": 20000,
      "full_paid": false,
      "amount_due": 20000,
      "created_at": "2024-08-22T23:14:00Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 67649,
              "item_type": "Job",
              "item_id": 2043282,
              "service_details": {
                  "name": "1 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-22",
                  "duration_in_minutes": 60,
                  "start_time": "11:00",
                  "address": {
                      "id": 623560,
                      "address_name": "[str] Waldorf 4029",
                      "address": "4029 Waldorf Court",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67679,
      "uuid": "00-7ae95fd8",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-7ae95fd8/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-22T23:28:36Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 67650,
              "item_type": "Job",
              "item_id": 2036006,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-22",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623555,
                      "address_name": "[str] Mardon 3322",
                      "address": "3322 West Mardon Avenue",
                      "unit": null,
                      "zip": "89139",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67680,
      "uuid": "00-2ef4bb84",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-2ef4bb84/thumbnail.png",
      "team_id": 227479,
      "amount": 8500,
      "full_paid": false,
      "amount_due": 8500,
      "created_at": "2024-08-23T02:32:51Z",
      "homekeeper_data": {
          "id": 230481,
          "name": "Tiffany Humphrey",
          "email": "tranquilpoolslv@gmail.com",
          "phone": "+16193390781",
          "first_name": "Tiffany"
      },
      "items": [
          {
              "id": 67651,
              "item_type": "Job",
              "item_id": 2043671,
              "service_details": {
                  "name": "Pool Cleaning",
                  "type": "Pool Cleaning",
                  "date": "2024-08-22",
                  "duration_in_minutes": 60,
                  "start_time": "08:00",
                  "address": {
                      "id": 623560,
                      "address_name": "[str] Waldorf 4029",
                      "address": "4029 Waldorf Court",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 67933,
      "uuid": "00-2a35d0c4",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-2a35d0c4/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-24T19:04:14Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 67904,
              "item_type": "Job",
              "item_id": 2043906,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-24",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623549,
                      "address_name": "[str] Harmon 135 #1615",
                      "address": "The Signature at MGM Grand - Tower B",
                      "unit": null,
                      "zip": "89109",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 68081,
      "uuid": "00-04d17c90",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-04d17c90/thumbnail.png",
      "team_id": 231274,
      "amount": 11000,
      "full_paid": false,
      "amount_due": 11000,
      "created_at": "2024-08-25T18:47:06Z",
      "homekeeper_data": {
          "id": 234283,
          "name": "Erik Pedersen",
          "email": "erik@shineoncleans.com",
          "phone": "+19737551828",
          "first_name": "Erik"
      },
      "items": [
          {
              "id": 68052,
              "item_type": "Job",
              "item_id": 2042434,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-25",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 624945,
                      "address_name": "[str] Adams-102 #1",
                      "address": "102 Adams Street",
                      "unit": null,
                      "zip": "07030",
                      "city": "Hoboken",
                      "state": "NJ"
                  }
              }
          }
      ]
  },
  {
      "id": 68090,
      "uuid": "00-0ecccf51",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-0ecccf51/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-25T21:20:57Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 68061,
              "item_type": "Job",
              "item_id": 2042517,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-25",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623564,
                      "address_name": "[str] Rochelle 4291",
                      "address": "4291 East Rochelle Avenue",
                      "unit": null,
                      "zip": "89121",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 68099,
      "uuid": "00-b9914013",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-b9914013/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-25T22:52:15Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 68070,
              "item_type": "Job",
              "item_id": 2036056,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-25",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623552,
                      "address_name": "[str] Valley View 4200 #3047",
                      "address": "4200 South Valley View Boulevard",
                      "unit": null,
                      "zip": "89103",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 68247,
      "uuid": "00-9a2c26da",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-9a2c26da/thumbnail.png",
      "team_id": 218880,
      "amount": 10500,
      "full_paid": false,
      "amount_due": 10500,
      "created_at": "2024-08-26T22:07:53Z",
      "homekeeper_data": {
          "id": 221879,
          "name": "Aurelia Ili",
          "email": "aurelia@niceandneatcleaningco.com",
          "phone": "+15103723402",
          "first_name": "Aurelia"
      },
      "items": [
          {
              "id": 68218,
              "item_type": "Job",
              "item_id": 2042508,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-26",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623549,
                      "address_name": "[str] Harmon 135 #1615",
                      "address": "The Signature at MGM Grand - Tower B",
                      "unit": null,
                      "zip": "89109",
                      "city": "Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  },
  {
      "id": 68475,
      "uuid": "00-a427923d",
      "state": "active",
      "note": null,
      "thumbnail_url": "https://samantha-file-uploads.s3.us-west-2.amazonaws.com/production/invoices/00-a427923d/thumbnail.png",
      "team_id": 227475,
      "amount": 100,
      "full_paid": false,
      "amount_due": 100,
      "created_at": "2024-08-28T22:52:40Z",
      "homekeeper_data": {
          "id": 230477,
          "name": "Harold Lesure",
          "email": "reliablecleanerslv1@gmail.com",
          "phone": "+17023576183",
          "first_name": "Harold"
      },
      "items": [
          {
              "id": 68446,
              "item_type": "Job",
              "item_id": 2035939,
              "service_details": {
                  "name": "2.5 hour cleaning",
                  "type": "Regular Cleaning",
                  "date": "2024-08-28",
                  "duration_in_minutes": 150,
                  "start_time": "11:00",
                  "address": {
                      "id": 623561,
                      "address_name": "[str] Pintura 1918",
                      "address": "1918 Pintura Circle",
                      "unit": null,
                      "zip": "89031",
                      "city": "North Las Vegas",
                      "state": "NV"
                  }
              }
          }
      ]
  }
]
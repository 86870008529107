import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class Communication {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  async createProxyNumber(numberType: string) {
    const url = 'api/v1/customer/shared-inbox/create-proxy-number';
    const payload = {
      number_type: numberType,
      type: numberType
    }
    return await this.httpClient.post(url, payload);
  }

  async getProxyNumbers() {
    const url = 'api/v1/customer/shared-inbox/list-proxy-numbers?includes=chat_rooms';
    return await this.httpClient.get(url);
  }

  async getAllMessages(filters?: any) {
    let url = `api/v1/customer/shared-inbox/latest-messages`;
    if (filters && filters?.fromDate && filters?.untilDate) {
      url += `?filters[from_date]=${filters.fromDate}&filters[until_date]=${filters.untilDate}`;
    }
    return await this.httpClient.get(url);
  }

  async getJobMessages(jobIdsArray) {
    let url = `api/v1/customer/shared-inbox/messages?filters[job_ids]=${jobIdsArray}&includes=metadata,chat_room_key&add_chat_suggestions=true`;
    return await this.httpClient.get(url);
  }

  async getMessages(homekeeperJobId) {
    const url = `api/v1/customer/communication/${homekeeperJobId}/messages`;
    const response = await this.httpClient.get(url);
    return response.reverse();
  }

  getCallMinutes(duration) {
    const length = Math.round(duration / 60);
    const minutes = length == 1 ? ' minute' : ' minutes';
    return ' (' + length + minutes + ')'
  }

  sendSharedInboxMessage(payload) {
    const url = 'api/v1/customer/shared-inbox/send-message';
    return this.httpClient.post(url, payload);
  }

  getProxySharedInbox(chatRoomKey) {
    const url = `api/v1/customer/shared-inbox/messages?filters[chat_room_key]=${chatRoomKey}`;
    return this.httpClient.get(url);
  }

  assignChat(chatRoomKey: string, id: number, type: string) {
    const params = {
      chat_room_key: chatRoomKey,
      assign_to: {
        id,
        type
      }
    }
    const url = 'api/v1/customer/shared-inbox/assign-chat';
    return this.httpClient.post(url, params);
  }

  getSharedInbox(proTeamId) {
    if (!proTeamId) {
      return;
    }
    const url = `api/v1/customer/shared-inbox/messages?filters[team_id]=${proTeamId}&includes=metadata,chat_room_key&add_chat_suggestions=true`;
    return this.httpClient.get(url);
  }

  getSharedInboxWithChatRoomKey(chatRoomKey: string) {
    if (!chatRoomKey) {
      return;
    }
    const url = `api/v1/customer/shared-inbox/messages?filters[chat_room_key]=${chatRoomKey}&includes=metadata,chat_room_key&add_chat_suggestions=true`;
    return this.httpClient.get(url);
  }

  generateAISuggestions(payload) {
    const url = 'api/v1/customer/ai/trigger-feature';
    return this.httpClient.post(url, payload);
  }

  performAISuggestion(payload, chatSuggestionId) {
    const url = `api/v1/customer/chat-suggestions/${chatSuggestionId}/perform`;
    return this.httpClient.post(url, payload);
  }

  dismissAISuggestion(chatSuggestionId) {
    const payload = {
      reason: 'wrong_suggestion'
    };
    const url = `api/v1/customer/chat-suggestions/${chatSuggestionId}/hide`;
    return this.httpClient.post(url, payload);
  }

  sendSharedMessage(payload) {
    const url = 'api/v1/customer/shared-inbox/send-message';
    return this.httpClient.post(url, payload);
  }

  markMessagesAsRead(messageKeys) {
    const payload = {
      chat_room_keys: messageKeys
    }
    const url = 'api/v1/customer/shared-inbox/mark-chat-as-read';
    return this.httpClient.post(url, payload);
  }

}

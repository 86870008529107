import { Util } from 'src/shared/util/util';
import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Invoices } from 'src/providers/invoices/invoices';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';

import { Loading } from 'src/shared/components/loading/loading';
import { ActivatedRoute } from '@angular/router';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { PendingInvoicePage } from '../pending-invoice/pending-invoice';
import { WindowService } from 'src/shared/providers/window.service';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'paid-invoice.html'
})

export class PaidInvoicePage implements OnInit {

  cameFromClientHub: boolean;
  clientName: string;
  creditsApplied: number;
  errorMessage: string;
  form: UntypedFormGroup;
  fromStartDuration: string;
  invoice: any;
  isInternalComment: boolean;
  isLoggedIntoClientApp: boolean;
  invoiceNotes: any;
  loaded: boolean;
  messageSelectItems: any;
  proReportedDuration: string;
  proName: string;
  proEmail: string;
  proPhone: string;
  selectedMessageType: any;
  dialogParams: any;
  isRightSideContent: boolean;
  isPaidBill: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private invoices: Invoices,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private schedule: Schedule,
    private route: ActivatedRoute,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
      messageType: ['public_reply']
    });
  }

  async ngOnInit() {
    this.errorMessage = '';
    this.cameFromClientHub = this.navCtrl.getParam('cameFromClientHub');
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (!this.cameFromClientHub && this.windowService.isDesktopRes && !this.isRightSideContent) {
      await this.storage.save('shouldOpenBillPageID', this.route.snapshot.paramMap.get('invoice_id'))
      return this.navCtrl.navigateForward('bills');
    }
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Bill');
    }
    const routePath = this.dialogParams?.routePath || this.route?.snapshot?.routeConfig?.path;
    const invoiceId = this.dialogParams?.invoice?.id || this.route?.snapshot?.paramMap?.get('invoice_id');
    const uuid = this.dialogParams?.invoice?.uuid || this.route?.snapshot?.paramMap?.get('uuid');
    const redirectUrls = ['customer/invoices/:invoice_id', 'bill/:invoice_id']
    if (redirectUrls.includes(routePath)) {
      this.handleRedirectToInvoicePage(invoiceId, uuid);
      return;
    }
    this.isPaidBill = true;
    this.rightSidePanelService.setDialogPageTitle('Paid Bill');
    try {
      this.loaded = false;
      this.invoice = await this.getParam('invoice') || await this.getInvoice(invoiceId, uuid);
      this.isInternalComment = false;
      this.isLoggedIntoClientApp = await this.getParam('isLoggedIntoClientApp');
      if (this.isLoggedIntoClientApp) {
        this.proName = await this.getParam('proName');
        this.proEmail = await this.getParam('proEmail');
        this.proPhone = await this.getParam('proPhone');
        this.clientName = await this.getParam('clientName');
        this.invoiceNotes = await this.invoices.getInvoiceNotes(this.invoice.id);
        this.messageSelectItems = this.getMessageSelectItems();
      } else {
        const homekeeperData = this.invoice?.homekeeper_data;
        this.proName = this.invoice?.team?.owner?.name || homekeeperData?.name || homekeeperData?.first_name;
        this.proEmail = this.invoice?.team?.owner?.email || homekeeperData?.email;
        this.proPhone = this.invoice?.team?.owner?.phone || homekeeperData?.phone;
        this.clientName = this.invoice?.customer?.name || localStorage.getItem('companyName');
      }
      if (!this.cameFromClientHub) {
        const job = await this.getParam('job') || await this.schedule.getJobDetail(this.invoice?.items?.[0]?.item_id);
        this.proReportedDuration = this.pros.parseDurationIntoHoursAndMintes(job?.homekeeper_jobs?.[0]?.job_durations?.reported_by_homekeeper, 'proReportedDuration');
        this.fromStartDuration = this.pros.parseDurationIntoHoursAndMintes(job?.homekeeper_jobs?.[0]?.job_durations?.from_start_to_end_moment, 'fromStartDuration');
      }
      this.creditsApplied = (this.invoice?.amount - this.invoice?.amount_due);
      this.loaded = true;
    } catch (err) {
      console.error('Error fetching invoice', err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(`Error fetching invoice ${this.errorMessage}`);
    }
  }

  async getInvoice(invoiceId: string, uuid: string): Promise<any> {
    const loading = await this.util.showLoading();
    try {
      let invoice = null;
      if (invoiceId) {
        invoice = await this.invoices.getInvoiceById(invoiceId);
      } else {
        invoice = await this.invoices.getInvoice(uuid);
      }
      loading.dismiss();
      return invoice;
    } catch (err) {
      loading.dismiss();
      console.error('Error fetching invoice', err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(`${new TranslationPipe().transform('Error fetching invoice, please log in to view the bill if not logged in.')} ${this.errorMessage}`);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    return param;
  }

  async handleRedirectToInvoicePage(invoiceId: string, uuid: string): Promise<void> {
    const invoice = await this.getInvoice(invoiceId, uuid);
    const params = {
      invoice,
      invoiceId,
      customBack: await this.getParam('customBack'),
    }
    const redirectRoute = invoice.full_paid ? `paid-bill/${invoiceId}` : `pending-bill/${invoiceId}`;
    const component = invoice.full_paid ? PaidInvoicePage : PendingInvoicePage;
    this.rightSidePanelService.navigateTo(redirectRoute, params, component);
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public note',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  @Loading('', true)
  async sendMessage() {
    this.errorMessage = '';
    try {
      const payload = {
        invoice_id: this.invoice.id,
        type: 'text',
        is_internal: this.isInternalComment,
        text: this.form.value.message
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.invoices.addAttachment();
      if (attachment == '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      const payload = {
        invoice_id: this.invoice.id,
        type: 'photo',
        is_internal: this.isInternalComment,
        media_url: attachment
      }
      await this.createInvoiceNote(payload);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async createInvoiceNote(payload) {
    await this.invoices.createInvoiceNote(payload);
    this.form.patchValue({
      message: ''
    });
    this.invoiceNotes = await this.invoices.getInvoiceNotes(this.invoice.id);
  }

  async copyBillLink() {
    const link = `paid-bill/${this.invoice.id}`
    this.invoices.copyBillLink(link);
    this.util.showSuccess('Bill link copied')
  }

}

import { Component, OnInit, ViewEncapsulation, Renderer2, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as confetti from 'canvas-confetti';
import { ModalController } from '@ionic/angular';
import { AdvancedSetupComponent } from './advanced-setup/advanced-setup';
import { VideoTutorialComponent } from './video-tutorial/video-tutorial';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.html',
  styleUrls: ['./onboarding.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OnboardingComponent implements OnInit, OnDestroy {
  
  unchosenGoals: any[];
  goals: any[];
  checkedItems: Set<string> = new Set();
  progress: number = 0;
  selectedTask: any | null = null;
  completedCount: number = 0;
  expandedGoal: string | null = null;
  videoModalOpen: boolean = false;
  currentVideo: string = '';
  showSuggestions: boolean = false;
  form: FormGroup = new FormGroup({
    task: new FormControl(''),
    tasks: new FormControl([]),
  });
  @ViewChild('confettiContainer') rootRef!: ElementRef;

  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    private modalController: ModalController,
    private storage: TidyStorage,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private onboardingProvider: OnboardingProvider,
  ) {}

  async ngOnInit(): Promise<void> {
    this.goals = await this.storage.retrieve('onboardingGoals');
    this.unchosenGoals = await this.storage.retrieve('unchosenOnboardingGoals');
  }

  async goToStepDetails(step) {
    console.log(step)
    try {
      if (step.state == 'done') {
        return;
      }
      await this.storage.save('cameFromOnboarding', true);
      if (step?.openMode === 'modal') {
        const isMobileResolution = window.innerWidth <= 870;
        await this.storage.save('dialog-right-side-open', !isMobileResolution);
        this.rightSidePanelService.navigateTo(step.page, {}, null, false);
        this.modalController.dismiss();
        return;
      }
      await this.storage.save('onboardingFlow', step.flow);
      this.navCtrl.navigateForward(step.page);
      this.modalController.dismiss();
    } catch (error) {
      console.error(error);
    }
  }

  async addGoal(goal) {
    await this.onboardingProvider.saveChosenGoals({user_goal_type_id: goal.id});
    //TODO
  }

  addStep() {
    //TODO
    console.log(this.form.value);
    if (this.form.value.task) {
      const task = {
        title: this.form.value.task,
        completed: false,
      };
      this.form.patchValue({
        tasks: [...this.form.value.tasks, task],
        task: '',
      });
    }
  }

  async openAdvancedSetup() {
    const backdrop = document.querySelector('.backdrop');
    if (backdrop) {
      (backdrop as HTMLElement).style.display = 'block';
    }
    const modal = await this.modalController.create({
      component: AdvancedSetupComponent,
      cssClass: 'advanced-setup-modal',
      showBackdrop: true,
      id: 'advanced-setup-modal',
    });
    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (backdrop) {
        (backdrop as HTMLElement).style.display = 'none';
      }
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async openVideo(goal) {
    await this.storage.save('goal', goal);
    const backdrop = document.querySelector('.backdrop');
    if (backdrop) {
      (backdrop as HTMLElement).style.display = 'block';
    }
    const modal = await this.modalController.create({
      component: VideoTutorialComponent,
      cssClass: 'video-tutorial-modal',
      showBackdrop: true,
      id: 'video-tutorial-modal',
    });
    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (backdrop) {
        (backdrop as HTMLElement).style.display = 'none';
      }
    });
  }

  ngOnDestroy(): void {
    this.storage.delete('cameFromOnboarding');
  }

}

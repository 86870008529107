import { Injectable } from "@angular/core";
import { HttpClientCustom } from "../custom/http-client";

@Injectable()
export class MaintenancePlans {
  constructor(
    private httpClient: HttpClientCustom
  ) {}

  getMaintenancePlans(filters = { property: [] }) {
    const addressFilter = filters.property.length > 0 ? `?filters[address_ids]=${filters.property.join(',')}` : '';

    const url = `api/v1/customer/maintenance-plans${addressFilter}`;
    return this.httpClient.get(url);
  }

  createMaintenancePlan(data) {
    const url = `api/v1/customer/maintenance-plans`;
    return this.httpClient.post(url, data);
  }

  deleteMaintenancePlan(id) {
    const url = `api/v1/customer/maintenance-plans/${id}`;
    return this.httpClient.delete(url);
  }

    /**
   * Updates an existing maintenance plan.
   * 
   * @param id - The unique identifier (string | number) of the maintenance plan to be updated.
   * @param data - An object containing the updated information for the maintenance plan. Expected structure:
   *               {
   *                 name: string;               // The name of the maintenance plan.
   *                 description: string;        // A brief description of the maintenance plan.
   *                 address_id: number;         // The ID of the address associated with the plan.
   *                 room_object_id: number;     // The ID of the room object associated with the plan.
   *                 frequency: string;          // The frequency of the maintenance task (e.g., "week").
   *                 next_do_date: string;            // The ISO 8601 date string for the next maintenance date.
   *                 file_urls: array;           // An array of file URLs associated with the maintenance plan.
   *               }
   *               All fields are optional.
   **/
  updateMaintenancePlan(id, data) {
    const url = `api/v1/customer/maintenance-plans/${id}`;
    return this.httpClient.put(url, data);
  }
}

import { Component, Input, ViewEncapsulation } from "@angular/core";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'maintenances-cards',
  templateUrl: 'maintenances-cards.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MaintenancesCardsComponent {

  @Input() addressId: any;
  @Input() upcomingMaintenances: any;
  @Input() pastMaintenances: any;
  showLimiter: { [id: string]: number } = {
    'upcomingMaintenances': 3,
    'pastMaintenances': 3
  };
  @Input() itemMaintenances: any = [
    {
      name:      'Clean coils',
      address:   { 
        address1: '1 Main',
        address_name: '1 Main',
        id: 1
      },
      item:      {
        name: 'Fridge',
        id: 1
      },
      next_do_date:   new Date(),
      frequency: 'weekly',
      data:      {}
    },
    {
      name:      'Change filters',
      address:   { 
        address1: '1 Main st',
        address_name: null,
        id: 2
      },
      item:     null,
      next_do_date:   new Date(),
      frequency: 'monthly',
      data:      {}
    },
    {
      name:      'Change filters',
      address:   { 
        address1: '1 Main st',
        address_name: null,
        id: 2
      },
      item:     null,
      next_do_date:   new Date(),
      frequency: 'monthly',
      data:      {}
    },
    {
      name:      'Change filters',
      address:   { 
        address1: '1 Main st',
        address_name: null,
        id: 2
      },
      item:     null,
      next_do_date:   new Date(),
      frequency: 'monthly',
      data:      {}
    },
  ];

  constructor(
    private navCtrl: CustomNavController
  ) { }

  goToMaintenance(maintenance) {
    const params = {
      maintenance: maintenance,
      addressId: this.addressId
    };
    this.navCtrl.navigateForward('item-maintenance', params);
  }

  showLimit(key) {
    if (this.showLimiter[key] != 3) {
      return this.showLimiter[key] = 3;
    }

    this.showLimiter[key] = this[key].length;
  }
}

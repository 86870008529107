import { ulid } from 'ulid'
import { Injectable } from '@angular/core';

@Injectable()
export class GenerateUuid {

  checkIfNeedCreate() {
    return (localStorage.getItem('uuid') === null)
  }

  createNewHash() {
    if (this.checkIfNeedCreate()) {
      const uuid =  `${ulid()}-${ulid()}-${ulid()}`;
      localStorage.setItem('uuid', uuid);
    }
  }

}

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Auth } from 'src/providers/auth/auth';
import { Booking } from 'src/providers/booking/booking';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { JobPage } from 'src/pages/schedule/job/job';

@Injectable({
  providedIn: 'root'
})
export class BookingRedirectGuard implements CanActivate {

  private auth = inject(Auth);
  private booking = inject(Booking);
  private navCtrl = inject(CustomNavController);
  private rightSidePanelService = inject(RightSidePanelService);
  private windowService = inject(WindowService);
  private storage = inject(TidyStorage);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const bookingId = route.params.bookingId.toString();
    if (!this.auth.isAuthenticated()) {
      const params = {
        bookingId: bookingId
      }
      this.navCtrl.navigateRoot('login', params);
      return false;
    }
    localStorage.setItem('cameFromBookingLink', 'true');
    localStorage.setItem('bookingId', bookingId);
    const path = await this.booking.getBookingPath(bookingId);
    if (this.windowService.isDesktopRes) {
      const route = path.split('/')[0];
      localStorage.setItem('bookingPath', route);
      localStorage.setItem('shouldOpenBookingPage', 'true');
      this.navCtrl.navigateRoot('schedule');
      this.storage.save('dialog-right-side-open', true);
      const components = {
        'job': JobPage,
        'past-job': PastJobPage,
        'past-private-job': PastPrivateJobPage
      }
      this.rightSidePanelService.openRightSidePanel(components[route]);
    } else {
      this.navCtrl.navigateRoot(path);
    }
    return false;
  }
}

export const bookingRedirectGuardFactory = () => {
  return new BookingRedirectGuard();
};

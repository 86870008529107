import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateTime as LuxonDateTime } from 'luxon';
import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';
import validationUtils from 'src/shared/util/validation-utils';

import { AvailableTimesForRange } from 'src/providers/calendar/available-times-for-range';
import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { ChangeDetectorRef } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { PlansPage } from 'src/pages/plans/plans';
import { Pros } from 'src/providers/pros/pros';
import { RequestJobPage } from '../request-job/request-job';
import { Schedule } from 'src/providers/schedule/schedule';
import { Me } from 'src/providers/me/me';

import { AddProPage } from 'src/pages/my-pros/add-pro/add-pro';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';

import { Loading } from 'src/shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { BrowseProsPage } from '../browse-pros/browse-pros';
import { debounceTime } from 'rxjs/operators';

@Component({
  templateUrl: 'select-category.html',
  styleUrls: ['./select-category.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SelectCategoryPage implements OnInit {

  addresses: any;
  categories: any;
  allCategories: any;
  errorMessage: string;
  form: UntypedFormGroup;
  addProForm: UntypedFormGroup;
  isRentalClient: boolean;
  isFindingPro: boolean;
  isAddingPro: boolean;
  isSelectingAddressToBook: boolean;
  loaded: boolean;
  propertyToAddItems: any;
  pageTitle: string;
  priorityListSentence: any;
  params: any;
  pros: any;
  shownPros: any;
  prosData: any;
  dialogParams: any;
  isRightSideContent = true;
  searchForm: UntypedFormGroup;
  scenario: any;
  didSelectProperty: boolean;
  didSelectCategory: boolean;
  hasPrivatePros: boolean;
  howToBookItems: any;
  serviceTypeItems: any;
  logJobTimeItems: any;
  proItems: any;
  proServiceItems: any;
  submitted: boolean;
  showLoader: boolean;
  selectedServiceType: any;

  constructor(
    private availableTimesForRange: AvailableTimesForRange,
    private bookJobService: BookJob,
    private client: Client,
    private changeDetectorRef: ChangeDetectorRef,
    private currentAddress: CurrentAddress,
    private fb: UntypedFormBuilder,
    private prosService: Pros,
    private schedule: Schedule,
    private me: Me,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private util: Util
  ) {
    this.form = this.fb.group({
      address: ['', Validators.required],
      howToBook: [''],
      serviceType: [''],
      jobDate: [''],
      jobTime: [''],
      pro: [''],
      service: ['']
    });
    this.addProForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      phone: ['', [Validators.minLength(10)]],
      serviceCategories: ['']
    });
    this.searchForm = this.fb.group({
      search: ['']
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.searchCategories(val.search));
  }

  searchCategories(search: string): void {
    this.categories = this.allCategories.filter((category) => category.header.toLowerCase().includes(search.toLowerCase()));
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    localStorage.setItem('select-category-opened', 'true');
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    this.loaded = false;
    this.bookJobService.clearBookJobStorage();
    this.isFindingPro = this.navCtrl.getParam('isFindingPro') || this.dialogParams?.isFindingPro;
    this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const categories = await this.prosService.getServiceCategories();
    this.serviceTypeItems = this.buildServiceTypeItems(categories);
    this.params = {
      address: this.navCtrl.getParam('address') || this.dialogParams?.address,
      bookingType: this.navCtrl.getParam('bookingType') || this.dialogParams?.bookingType,
      categoryId: this.navCtrl.getParam('categoryId') || this.dialogParams?.categoryId,
      categoryHeader: this.navCtrl.getParam('categoryHeader') || this.dialogParams?.categoryHeader,
      filterByPro: this.navCtrl.getParam('filterByPro') || this.dialogParams?.filterByPro,
      isPrivatePro: this.navCtrl.getParam('isPrivatePro') || this.dialogParams?.isPrivatePro,
      proFirstName: this.navCtrl.getParam('proFirstName') || this.dialogParams?.proFirstName,
      jobId: this.navCtrl.getParam('jobId') || this.dialogParams?.jobId,
      bookingId: this.navCtrl.getParam('bookingId') || this.dialogParams?.bookingId,
      bookingKey: this.navCtrl.getParam('bookingKey') || this.dialogParams?.bookingKey,
      shouldInsteadAddJob: this.navCtrl.getParam('shouldInsteadAddJob') || this.dialogParams?.shouldInsteadAddJob,
      planId: this.navCtrl.getParam('planId') || this.dialogParams?.planId,
      date: this.navCtrl.getParam('date') || this.dialogParams?.date,
    };
    if (this.params.categoryId) {}
    if (this.params.filterByPro) {
      this.params['hkId'] = this.navCtrl.getParam('hkId') || this.dialogParams?.hkId;
    }
    this.isSelectingAddressToBook = this.navCtrl.getParam('isSelectingAddressToBook') || this.dialogParams?.isSelectingAddressToBook;
    this.didSelectProperty = this.isFindingPro || !this.isSelectingAddressToBook;
    this.addresses = await this.client.getMoreDetailAddresses();
    this.propertyToAddItems = [];
    this.addresses.map((address) => {
      this.propertyToAddItems.push({
        viewValue: address.address_name ? address.address_name : address.address1,
        value: address.id
      });
    });
    if (this.didSelectProperty) {
      this.form.patchValue({address: this.params.address.id});
    } else {
      const addressId = await this.client.getSelectedAddressId(this.addresses);
      this.params.address = this.addresses.find((address) => address.id == addressId);
      this.form.patchValue({address: addressId});
    }
    if (this.params.categoryId) {
      this.form.patchValue({serviceType: this.params.categoryId});
    } else {
      this.form.patchValue({serviceType: this.serviceTypeItems[0].value});
    }
    const serviceType = this.serviceTypeItems.find((serviceType) => serviceType.value == this.form.value.serviceType);
    this.selectedServiceType = serviceType;
    await this.selectCategory(this.selectedServiceType);
    this.pageTitle = this.getPageTitle();
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.howToBookItems = this.getHowToBookItems();
    this.logJobTimeItems = this.bookJobService.getJobTimeItems();
    const showBookOrRequest = localStorage.getItem('showBookOrRequest') == 'true';
    if (this.hasPrivatePros && this.proItems.length && !showBookOrRequest && !this.isFindingPro) {
      const selectedPro = this.navCtrl.getParam('selectedPro') || this.dialogParams?.selectedPro;
      if (selectedPro) {
        this.form.patchValue({howToBook: selectedPro.is_private ? 'assign' : 'book'});
        await this.selectPro(selectedPro.homekeeper.id);
        this.dialogParams.selectedPro = null;
        this.storage.save('dialog-params', this.dialogParams);
      } else {
        this.form.patchValue({howToBook: 'assign'});
        await this.selectPro(this.proItems[0].value);
      }
    } else {
      this.form.patchValue({howToBook: 'book'});   
    }
    localStorage.setItem('showBookOrRequest', 'false');
    this.loaded = true;
  }

  @Loading('', true)
  async selectPro(proId) {
    this.proServiceItems = [];
    const services = await this.bookJobService.fetchTeamServices(proId, this.form.value.serviceType);
    services.map((service) => {
      this.proServiceItems.push({
        value: service.key,
        viewValue: service.title
      });
    });
    this.changeDetectorRef.detectChanges();
    const now = LuxonDateTime.now();
    this.form.patchValue({
      jobDate: this.params.date ? LuxonDateTime.fromFormat(this.params.date, "MM-dd-yyyy").toISO() : now.toISO(),
      jobTime: '11:00',
      service: this.proServiceItems[0]?.value,
      pro: proId
    });
  }

  trackByFn(index, item) {
    return item.value;
  }

  getHowToBookItems() {
    return [
      {
        viewValue: 'Assign pro',
        value: 'assign'
      },
      {
        viewValue: 'Book or request',
        value: 'book'
      }      
    ]
  }

  getPageTitle() {
    if (this.params.bookingType == 'reschedule_job') {
      return 'Reschedule';
    } else if (this.params.bookingType == 'reschedule_plan') {
      return 'Reschedule Plan';
    } else if (this.isFindingPro) {
      return 'Find New Pro';
    } else {
      return 'Book Job(s)';
    }
  }

  buildServiceTypeItems(categories) {
    return categories.map((category) => {
      return {
        icon: category.icon_url,
        viewValue: category.name == 'Regular Cleaning' ? 'Cleaning' : category.name,
        value: category.id
      }
    })
  }

  toggleSelectCategory() {
    this.didSelectCategory = !this.didSelectCategory;
  }

  @Loading('', true)
  async selectCategory(category) {
    const isMobileResolution = window.innerWidth <= 870;
    this.params['categoryId'] = category.value;
    this.params['categoryHeader'] = category.viewValue;
    this.params['categoryImage']= category.icon;
    this.params.bookingType = this.navCtrl.getParam('bookingType') || this.dialogParams?.bookingType;
    this.scenario = await this.getScenario();
    if (this.hasPrivatePros) {
      this.form.patchValue({pro: this.proItems[0]?.value || null});
    }
    this.getPriorityListSentence();
    this.didSelectCategory = true;
  }

  async getPriorityListSentence() {
    const parsedProsData = this.prosService.parseProsIntoGroups(this.prosData);
    this.priorityListSentence = this.prosService.updatePriorityListSentence(parsedProsData);
  }

  async getScenario() {
    this.proItems = null;
    this.form.patchValue({pro: null});
    this.prosData = await this.prosService.getPros(this.form.value.serviceType, this.form.value.address);
    const hasPros = await this.prosService.checkIfHasProsInCategory(this.prosData);
    this.hasPrivatePros = await this.prosService.checkIfHasPrivatePro(this.prosData);
    await this.getProItems(this.prosData);
    this.pros = this.parsePros(this.prosData);
    this.shownPros = [];
    this.pros.map((pro, index) => {
    if (index < 2) {
        this.shownPros.push(pro);
      }
    });
    if (this.form.value.serviceType == 1) {
      if (hasPros) {
        return 'hasCleaningPros';
      } else {
        return 'notHasCleaningPros';
      }
    } else {
      if (hasPros) {
        return 'hasNonCleaningPros';
      } else {
        return 'notHasNonCleaningPros';
      }
    }
  }

  async getProItems(prosData) {
    this.proItems = [];
    
    // First add recommended pros from prosData
    prosData.favorited.map((pro) => {
      if (pro.object?.is_private) {
        this.proItems.push({
          value: pro.object.id,
          viewValue: pro.object.first_name + ' ' + pro.object.last_name
        });
      }
    });
    prosData.approved.map((pro) => {
      if (pro.object?.is_private) {
        this.proItems.push({
          value: pro.object.id,
          viewValue: pro.object.first_name + ' ' + pro.object.last_name
        });
      }
    });

    // Add divider if we have recommended pros
    if (this.proItems.length > 0) {
      this.proItems.push({
        value: 'divider',
        viewValue: '──────────'
      });
    }

    // Then add all private pros
    const allPrivatePros = await this.prosService.getAllPrivatePros();
    allPrivatePros.forEach(pro => {
      // Only add if not already in the list
      if (!this.proItems.some(existingPro => existingPro.value === pro.id)) {
        this.proItems.push({
          value: pro.id,
          viewValue: pro.first_name + ' ' + pro.last_name
        });
      }
    });
  }

  parsePros(pros) {
    let prosArray = [];
    pros.approved.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        prosArray.push((pro));
      }
    });
    pros.favorited.map((pro) => {
      if (pro.object_type !== 'dynamic_sa') {
        prosArray.push((pro));
      }
    });
    return prosArray;
  }

  @Loading('', true)
  async goToPlansPage() {
    localStorage.setItem('showBookOrRequest', 'true');
    const params = {
      address: this.params.address,
      plans: this.params.address.plans,
      addressFilter: this.propertyToAddItems,
      addressResponse: this.addresses,
      bookJobParams: this.params,
      categoryId: this.params.categoryId,
      categoryHeader: this.params.categoryHeader,
      cameFromBookingFlow: true
    }
    const url = 'plans';
    const component = PlansPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  @Loading('', true)
  async goToRequestJob() {
    this.bookJobService.clearBookJobStorage();
    localStorage.setItem('showBookOrRequest', 'true');
    try {
      /*if (this.params.categoryId == 1 && !this.params.filterByPro) {
        const substituteData = await this.availableTimesForRange.checkIfCanRequestSubstitute();
        const substituteJobId = substituteData.substitute_available_for_job_id;
        if (substituteJobId !== null) {
          return this.navCtrl.navigateForward(`request-substitute/${substituteJobId}`);
        }
      }*/
      let hasNonTrialPaidSubscription = false;
      if (this.isRentalClient) {
        const subscriptionTypes = await this.me.getSubscriptionTypes();
        hasNonTrialPaidSubscription = await this.me.checkIfHasNonTrialPaidSubscription(subscriptionTypes);
      }
      const priceExperiment = this.bookJobService.getPriceExperiment();
      const bookingExperiment = localStorage.getItem('bookingExperiment');
      if (this.params.filterByPro) {
        const params = {
          addingOneTimeJob: this.params.bookingType === 'add_one_time_job',
          address: this.params.address,
          pro: {
            first_name: this.params.proFirstName,
            id: this.params.hkId,
            private: this.params.isPrivatePro
          },
          hasNonTrialPaidSubscription: hasNonTrialPaidSubscription,
          priceExperiment: priceExperiment,
          bookingExperiment: bookingExperiment
        };
        const url = 'request-job';
        const component = RequestJobPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        const services = await this.bookJobService.fetchRequiredTeamServices('all', false, this.params.categoryId);
        const params: any = {
          cameFromBookOrRequest: true,
          address: this.params.address,
          bookingType: this.params.bookingType,
          services: services,
          bookingId: this.params.bookingId,
          hasNonTrialPaidSubscription: hasNonTrialPaidSubscription,
          priceExperiment: priceExperiment,
          bookingExperiment: bookingExperiment,
          serviceTypeId: this.params.categoryId
        };
        const url = 'request-job';
        const component = RequestJobPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBookJobWithPro(pro) {
    localStorage.setItem('showBookOrRequest', 'true');
    this.params['shouldNotDefaultToLastPro'] = false;
    this.params.filterByPro = true;
    this.params['hkId'] = pro.object.id;
    const plansWithPro = this.params.address.plans.find((plan) => plan?.team?.owner?.id == pro.object.id);
    if (plansWithPro && this.params.bookingType !== 'add_one_time_job' && this.params.bookingType !== 'reschedule_job' && this.params.bookingType !== 'reschedule_plan') {
      this.goToPlansPage();
    } else {
      this.goToBookJob();
    }
  }

  goToBookJobwithNewPro() {
    localStorage.setItem('showBookOrRequest', 'true');
    this.params['shouldNotDefaultToLastPro'] = true;
    if (this.params.address.plans.length && this.params.bookingType !== 'add_one_time_job' && this.params.bookingType !== 'reschedule_job' && this.params.bookingType !== 'reschedule_plan') {
      this.goToPlansPage();
    } else {
      this.goToBookJob();
    }
  }

  async goToBookJob() {
    this.bookJobService.clearBookJobStorage();
    localStorage.setItem('showBookOrRequest', 'true');
    try {
      this.params.pros = await this.bookJobService.fetchPros(this.params.categoryId);
      if (this.params.categoryId !== 1) {
        this.removeNonProFilters();
      }
      const url = 'book-job';
      const component = BookJobPage;
      this.rightSidePanelService.navigateTo(url, this.params, component);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  removeNonProFilters() {
    this.params.pros.splice(0, 4);
  }

  showAllPros() {
    this.shownPros = this.pros;
  }

  goToBrowsePros() {
    const url = 'browse-pros';
    const component = BrowseProsPage;
    this.rightSidePanelService.navigateTo(url, this.params, component);
  }

  async logJob() {
    const date = LuxonDateTime.fromISO(this.form.value.jobDate).toISO();
    this.schedule.addSkeletonJobToCalendar(date, this.form.value.jobTime, this.form.value.address);
    try {
      const payload = {
        address_id: this.form.value.address,
        booking: {
            service_type_key: this.form.value.service,
            preferred_start_date: this.form.value.jobDate,
            preferred_start_time: this.form.value.jobTime,
        },
        rating_options:{
          hk_rating: 'specific_hk',
          rating_chosen: false
        },
        homekeeper_ids: [
          this.form.value.pro
        ],
        backup_times_blocks: []
      }
      if (this.params.bookingType == 'reschedule_job') {
        await this.schedule.cancelJob(this.params.bookingId);
      } else if (this.params.bookingType == 'reschedule_plan') {
        await this.schedule.cancelPlan(this.params.planId);
      }
      await this.bookJobService.saveJob('add_one_time_job', payload, null, null);
      this.storage.delete('moreDetailAddresses');
      this.closePage();
      const successMessage = 'Job Added';
      this.util.showSuccess(successMessage);
    } catch (err) {
      this.schedule.removeSkeletonJobFromCalendar();
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async changeAddress() {
    this.showLoader = true;
    localStorage.setItem('addressId', this.form.value.address);
    this.didSelectProperty = !this.didSelectProperty;
    if (!this.didSelectProperty) {
      this.didSelectCategory = false;
    }
    const address = this.addresses.find((address) => address.id == this.form.value.address);
    this.params.address = address;
    this.scenario = await this.getScenario();
    if (this.hasPrivatePros && this.proItems.length) {
      this.form.patchValue({howToBook: 'assign'});
      this.form.patchValue({pro: this.proItems[0]?.value || null});
      await this.selectPro(this.proItems[0].value);
    } else {
      this.form.patchValue({howToBook: 'book'});   
    }
    this.showLoader = false;
  }

  async changeServiceType() {
    this.showLoader = true;
    this.params.categoryId = this.form.value.serviceType;
    const serviceType = this.serviceTypeItems.find((serviceType) => serviceType.value == this.form.value.serviceType);
    this.selectedServiceType = serviceType;
    this.params.categoryHeader = serviceType.viewValue == 'Regular Cleaning' ? 'Cleaning' : serviceType.viewValue;
    this.scenario = await this.getScenario();
    this.getPriorityListSentence();
    if (this.hasPrivatePros && this.proItems.length) {
      await this.selectPro(this.proItems[0].value);
    }
    this.addProForm.patchValue({
      serviceCategories: [this.form.value.serviceType]
    });
    this.showLoader = false;
  }

  goToAddPro() {
    const url = 'add-pro';
    const component = AddProPage;
    this.rightSidePanelService.navigateTo(url, {}, component);
  }

  closePage() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
    }
  }

  getButtonText() {
    if (this.params.bookingType == 'reschedule_job') {
      return 'Reschedule';
    } else if (this.params.bookingType == 'reschedule_plan') {
      return 'Reschedule Plan';
    } else {
      return 'Add Job';
    }
  }

  learnMoreAddPros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  async addPro() {
    this.isAddingPro = true;
    this.submitted = true;
    if (!this.addProForm.valid) {
      return;
    }
    try {
      const params = {
        name: this.addProForm.value.name,
        email: this.addProForm.value.email,
        phone: this.addProForm.value.phone,
        add_to_address_homekeeper_list: {
          address_ids: [this.form.value.address],
          service_type_ids: this.addProForm.value.serviceCategories
        }
      };
      if (!this.addProForm.value.phone) {
        delete params.phone;
      }
      await this.prosService.addNewPro(params);
      localStorage.setItem('hasPrivatePro', 'true');
      this.prosData = await this.prosService.getPros(this.form.value.serviceType, this.form.value.address);
      await this.getProItems(this.prosData);
      await this.selectPro(this.proItems[0].value);
      this.isAddingPro = false;
      this.submitted = false;
    } catch ( err ) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

}
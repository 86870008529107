import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Integrations } from 'src/providers/integrations/integrations';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { WindowService } from 'src/shared/providers/window.service';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { Loading } from 'src/shared/components/loading/loading';

import { AddressModel } from 'src/models/address.model';

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';
import { AddIntegrationPage } from '../add-integration/add-integration';
import { AddAccountNotificationPage } from 'src/pages/more/add-account-notification/add-account-notification';
import { ActiveIntegrationsPage } from 'src/pages/integrations/active-integrations/active-integrations';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'integrations',
  templateUrl: 'integrations.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['integrations.scss']
})

export class IntegrationsPage extends TimeoutableComponent implements OnInit {

  allIntegrationsResponse: any;
  activeIntegrations = [];
  activeIntegrationsResponse = [];
  addresses: Array<AddressModel>;
  allIntegrations = [];
  allFeaturedIntegrations = [];
  backPage: string;
  errorMessage: string;
  form: UntypedFormGroup;
  integrationTypeForm: UntypedFormGroup;
  integratedDevicesCount: number;
  integrationItems: any;
  hasShownAllIntegrationsDiscounts: boolean;
  hasShownAllFeaturedIntegrationsDiscounts: boolean;
  pendingIntegrations: any;
  pageDescription: string;
  panelClosedSubscription: Subscription;
  remoteAccessAddresses: any;
  showDiscounts: boolean;
  selectedIntegrationTypeName: string;
  shownAllIntegrations: any;
  shownAllFeaturedIntegrations: any;
  selectedIntegrations: any = [];
  showBackButton: boolean;
  webhooks: any;
  isRightSideContent: boolean;
  dialogParams: any;
  showOnboarding: boolean;
  hasProperties: boolean;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding: boolean;

  constructor(
    private client: Client,
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private integrations: Integrations,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private webhooksService: Webhooks,
    private windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    public onboardingProvider: OnboardingProvider
  ) {
    super();
    this.integrationTypeForm = this.fb.group({
      type: [''],
    });
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  async ngOnInit() {
    this.checkIfShouldShowOnboarding();
    this.getPageData();
  }

  async getPageData() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.errorMessage = '';
    this.activeIntegrations = [];
    this.allIntegrations = [];
    try {
      this.backPage = localStorage.getItem('integrationsBackPage') || 'more';
      this.showBackButton = await this.getParam('showBackButton');
      this.addresses = await this.client.getAddresses();
      await this.getIntegrations();
      this.integrationTypeForm.patchValue({type: 'all'});
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async checkIfShouldShowOnboarding() {
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding('selectIntegration');
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.getPageData();
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getIntegrations() {
    this.remoteAccessAddresses = await this.integrations.getRemoteAccessAddresses();
    await this.getActiveIntegrations();
    await this.getAllIntegrations();
    this.getIntegrationItems();
  }

  async getActiveIntegrations() {
    await this.getActiveGuestReservationSourceIntegrations();
    await this.getActiveLockIntegrations();
    await this.getActiveWebhookIntegrations();
  }

  async getActiveGuestReservationSourceIntegrations() {
    const activeIntegrationsResponse = await this.guestReservations.getReservationSource();
    this.activeIntegrationsResponse = this.guestReservations.addIntegrationsAddressInfo(activeIntegrationsResponse, this.addresses);
    this.pendingIntegrations = this.activeIntegrationsResponse.filter(integration => this.showPendingOrIssueAlert(integration));
    this.activeIntegrations = this.activeIntegrationsResponse.filter(integration => !this.showPendingOrIssueAlert(integration));
  }

  async getActiveLockIntegrations() {
    const lockIntegrations = await this.integrations.getIntegrations();
    lockIntegrations.map((integration) => {
      if (integration.type == 'remote_property_access' && integration.key == 'remote_lock') {
        this.activeIntegrations.push({
          format: 'lock',
          integration: integration
        });
      }
    });
  }
  
  async getActiveWebhookIntegrations() {
    this.webhooks = await this.getParam('webhooks') || await this.webhooksService.getWebhooks();
    this.webhooks.map((integration) => {
      this.activeIntegrations.push({
        format: 'notification',
        integration: integration
      });
    });
  }

  async getAllIntegrations() {
    this.allIntegrations = [];
    this.allFeaturedIntegrations = [];
    //NOTE: BE returns lock integrations in the guest-reservation-source-types response
    const allIntegrationsResponse = await this.guestReservations.getSourceTypes();
    this.allIntegrationsResponse = allIntegrationsResponse.filter(integration => integration.format !== 'csv');
    const allIntegrations: any = [
      ...this.allIntegrationsResponse,
      {
        format: 'notification',
        logo_url: 'assets/img/integration-images/webhook.svg',
        name: 'Webhook',
        short_description: 'Add a webhook to get notified in your application.',
        active_details: []
      },
      {
        format: 'notification',
        logo_url: 'assets/img/integration-images/slack.svg',
        name: 'Slack',
        short_description: 'Integrate with Slack so your team can communicate the way you want.',
        active_details: [],
        long_description: 'Add a Slack webhook to get notified on Slack.'
      }
    ];
    allIntegrations.map((integration) => {
      const alreadyAddedIndex = this.allIntegrations.findIndex(item => item.name === integration.name);
      if (alreadyAddedIndex !== -1) {
        this.allIntegrations[alreadyAddedIndex]['formats'].push(integration.format);
        this.allIntegrations[alreadyAddedIndex]['integrations'].push(integration);
        integration.active_details.map((detail) => {
          this.allIntegrations[alreadyAddedIndex]['active_details'].push(detail);
        });
      } else {
        this.allIntegrations.push({
          formats: [integration.format],
          integrations: [integration],
          ...integration
        });
      }
    });
    this.allIntegrations.map((integration) => {
      if (integration.active_details.some((detail) => detail.key == 'featured')) {
        this.allFeaturedIntegrations.push(integration);
        const index = this.allIntegrations.findIndex(item => item.name === integration.name);
        this.allIntegrations.splice(index, 1);
      }
      const index = integration.active_details.findIndex(detail => detail.key == 'discount');
      if (index !== -1) {
        integration['discount'] = integration.active_details[index].value;
      }
    });
    this.rearrangePopularItems();
    this.shownAllFeaturedIntegrations = this.allFeaturedIntegrations;
    this.shownAllIntegrations = this.allIntegrations;
  }

  rearrangePopularItems() {
    const slack = this.allIntegrations.find((integration) => integration.name == 'Slack');
    const airbnb = this.allIntegrations.find((integration) => integration.name == 'Airbnb');
    const hostaway = this.allIntegrations.find((integration) => integration.name == 'Hostaway');
    const guesty = this.allIntegrations.find((integration) => integration.name == 'Guesty');
    this.allIntegrations = this.allIntegrations.filter(item => {
      return item.name !== 'Slack' && item.name !== 'Airbnb' && item.name !== 'Hostaway' && item.name !== 'Guesty';
    });
    if (guesty) this.allIntegrations.unshift(guesty);
    if (hostaway) this.allIntegrations.unshift(hostaway);
    if (slack) this.allIntegrations.unshift(slack);
    if (airbnb) this.allIntegrations.unshift(airbnb);
  }

  getIntegrationItems() {
    this.integrationItems = [{
      viewValue: 'All Integrations',
      value: 'All Integrations',
      show: true
    }];
    this.allIntegrations.map((integration) => {
      integration.active_details.map((detail) => {
        const alreadyAddedIndex = this.integrationItems.findIndex(item => item.value === detail.value);
        if (alreadyAddedIndex == -1 && detail.key == 'category') {
          this.integrationItems.push({
            viewValue: detail.value,
            value: detail.value,
            show: false
          });
        }
      });
    });
  }

  changeSelectedIntegrationType(selectedValue) {
    this.integrationItems.map((item) => {
      item.show = selectedValue == item.value
    });
    const item = this.integrationItems.find((item) => item.value == selectedValue);
    this.selectedIntegrationTypeName = item.viewValue;
    if (selectedValue == 'All Integrations') {
      this.shownAllIntegrations = this.allIntegrations;
      this.shownAllFeaturedIntegrations = this.allFeaturedIntegrations;
    } else {
      this.shownAllIntegrations = this.allIntegrations.filter((item) => 
        item.active_details.some((detail) => detail.value === selectedValue)
      );
      this.shownAllFeaturedIntegrations = this.allFeaturedIntegrations.filter((item) => 
        item.active_details.some((detail) => detail.value === selectedValue)
      );
    }
  }

  @Loading('', true)
  async goToContactConcierge() {
    try{
      await this.concierge.setClientInfo();
      const params = {
        title: 'Integrations Help',
        type: 'support.integration'
      };
      this.goToPage('contact-concierge', params, ContactConciergePage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  updateSearch({search}) {
    if (search.length < 2) {
      this.shownAllFeaturedIntegrations = this.allFeaturedIntegrations;
      return this.shownAllIntegrations = this.allIntegrations;
    }
    const term = search.toLowerCase();
    this.shownAllIntegrations = this.allIntegrations.filter((option) => {
      const matchForName = option.name.toLowerCase().indexOf(term) > -1;
      if (matchForName) {
        return option;
      }
    });
    this.shownAllFeaturedIntegrations = this.allFeaturedIntegrations.filter((option) => {
      const matchForName = option.name.toLowerCase().indexOf(term) > -1;
      if (matchForName) {
        return option;
      }
    });
  }

  goToUpdateIntegration(integration) {
    if (integration.state !== 'paused') {
      return;
    }
    const params = {
      reservationSrcType: {
        name: integration.guest_reservation_source_type.name,
        id: integration.guest_reservation_source_type.id,
        logo_url: integration.guest_reservation_source_type.logo_url,
        formats: [integration.guest_reservation_source_type.format]
      },
      sourceType: integration.guest_reservation_source_type.format,
      isUpdatingIntegration: true
    }
    this.goToPage('add-integration', params, AddIntegrationPage);
  }

  goToAddIntegration(reservationSrcType) {
    let params: any = {
      allIntegrationsResponse: this.allIntegrationsResponse,
      reservationSrcType: reservationSrcType,
      isUpdatingIntegration: false
    }
    if (reservationSrcType.name == 'Webhook') {
      params['selectedChannel'] = reservationSrcType.name.toLowerCase();
      params['nextPagePath'] = 'integrations/all';
      return this.goToPage('add-account-notification', params, AddAccountNotificationPage);
    }
    this.goToPage('add-integration', params, AddIntegrationPage);
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didMakeIntegrationChanges = await this.storage.retrieve('didMakeIntegrationChanges');
        if (didMakeIntegrationChanges) {
          await this.storage.save('didMakeIntegrationChanges', false);
          this.getPageData();
        }
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  goBack() {
    if (this.windowService.isDesktopRes) {
      this.navCtrl.navigateBack('more-desktop');
    } else {
      this.navCtrl.navigateBack('more');
    }
  }

  goToActiveIntegrations() {
    if (this.activeIntegrations?.length == 0) {
      return;
    }
    const params = {
      webhooks: this.webhooks,
      remoteAccessAddresses: this.remoteAccessAddresses,
      activeIntegrations: this.activeIntegrations,
      addresses: this.addresses
    }
    this.goToPage('active-integrations', params, ActiveIntegrationsPage);
  }

  showPendingOrIssueAlert(integration) {
    if (integration?.guest_reservation_source_type?.format !== 'concierge_integration') {
      if (!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length <= 0) {
        return true;
      }
      if (!integration.last_sync_at && integration?.guest_reservation_fetch_status == 'pending' && integration.addresses.length > 0) {
        return true;
      }
    }
    if (integration.state == 'paused') {
      return true;
    }
    return false;
  }

  toggleShowDiscounts() {
    this.showDiscounts = !this.showDiscounts;
    this.hasShownAllIntegrationsDiscounts = this.shownAllIntegrations.some((integration) => integration.discount);
    this.hasShownAllFeaturedIntegrationsDiscounts = this.shownAllFeaturedIntegrations.some((integration) => integration.discount);
  }
  
}
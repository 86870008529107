import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'tidy-video',
  template: `
  <ng-container class="tidy-video-container">
    <div class="tidy-video-container__unmute-popover" *ngIf="muted" (click)="play()">
      <tidy-image
        class="tidy-video-container__unmute-popover__volume-icon header-size"
        src="assets/svg/volume-high-outline-white.svg">
      </tidy-image>
      Play with Sound
    </div>
    <video
      #videoElem
      class="tidy-video-container__video-elem"
      preload="true"
      playsinline
      autoplay
      loop
      [muted]="muted"
      [src]="src"
      poster
      [controls]="controls"
      async>
    </video>
  </ng-container>
  `,
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  @Input() muted: boolean = true;
  @Input() src: string | any;
  @Input() controls: boolean = true;
  @ViewChild('videoElem') videoElem: ElementRef<HTMLVideoElement>;

  pause() {
    this.videoElem.nativeElement.pause();
  }

  play() {
    this.muted = false;
    this.videoElem.nativeElement.play();
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { Genome } from 'src/providers/genome/genome';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { RoomModel } from 'src/models/to-do.model';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Logger } from 'src/providers/logger';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { routes } from 'src/app/aggregators/pages';

@Component({
  templateUrl: 'room.html',
  providers: [PhotoCaptionService]
})

export class ToDosRoomPage implements OnInit {

  addressId: any;
  editPrio: boolean;
  form: UntypedFormGroup;
  isRightSideContent = true;
  room: RoomModel;
  rooms: RoomModel[];
  errorMessage: string;
  contentHeight: number;
  taskListId: any;
  loaded: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    public genome: Genome,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    public toDos: ToDos,
    private storage: TidyStorage,
    private logger: Logger,
    private photoCaptionService: PhotoCaptionService,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      addresses: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.getPageData();
  }

  async getPageData() {
    try {
      this.loaded = false;
      await this.getRoomFromParams();
      const id = this.toDos.getStorageIds();
      this.addressId = id.addressId;
      this.taskListId = id.taskListId;
      if (!this.room) {
        await this.getRooms(id);
      }
      this.rightSidePanelService.setDialogPageTitle(this.room?.name);
      this.loaded = true;
    } catch (err) {
    }
  }

  async getRoomFromParams() {
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.room = this.navCtrl.getParam('room') || dialogParams?.room;
    this.rooms = this.navCtrl.getParam('rooms') || dialogParams?.rooms;
  }

  async getRooms(id) {
    const rooms = await this.toDos.getListDetail(this.taskListId);
    this.rooms = this.toDos.buildRoomIcons(rooms);
    this.room = this.rooms.find((room) => {
      if (room.id === parseFloat(id.roomId)) {
        return room;
      }
    });
  }

  goToAddToDo() {
    const params = {
      rooms: this.toDos.parseRoomCheckboxItems(this.rooms),
      roomsResponse: this.rooms,
      selectedListId: this.taskListId,
      addressId: this.addressId
    }
    this.rightSidePanelService.navigateTo('add-to-do', params);
  }

  async deleteRoom() {
    if (this.room.type == 'group') {
      return this.deleteGroup();
    }
    const params = {
      room: this.room
    }
    this.rightSidePanelService.navigateTo('remove-room', params);
  }

  async deleteGroup() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Card?',
      body: 'This will delete the card from all To-Do lists. You will not be able to undo this action.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmDeleteGroup.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDeleteGroup() {
    try {
      await this.toDos.deleteGroup(this.room.id, this.taskListId);
      if (this.isRightSideContent) {
        this.rightSidePanelService.navigateTo('to-dos');
      } else {
        this.navCtrl.navigateForward('to-dos');
      }
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  goToEditToDo(toDo) {
    if (toDo.task_type === 'take_before_photo' || toDo.task_type === 'take_after_photo') {
      return;
    }
    const params = {
      toDo: toDo,
      roomType: this.room.type,
      roomId: this.room.id
    }
    this.rightSidePanelService.navigateTo('edit-to-do', params);
  }

  goToAddDoNot() {
    const rooms = this.rooms.filter(room => room.type !== 'group');
    const params = {
      rooms: this.toDos.parseRoomCheckboxItems(rooms)
    }
    this.rightSidePanelService.navigateTo('add-do-not', params);
  }

  goToAddPhoto() {
    this.rightSidePanelService.navigateTo('add-room-photo', {});
  }

  goToEditRoomInfo() {
    const params = {
      room: this.room
    }
    this.rightSidePanelService.navigateTo('edit-room-info', params);
  }

  @Loading('', true)
  async takeRoomPhoto() {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg('room');
      await this.storage.save('roomPhotoNote', newPhotoNote.url);
      const params = {
        card: this.room
      }
      this.rightSidePanelService.navigateTo('add-room-photo', params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.logger.error(err, 'room-photo-error');
    }
  }

  @Loading('', true)
  async updateToDoOrder(orderedToDos) {
    try {
      let tasksInOrderArray = [];
      orderedToDos.map((toDo) => {
        tasksInOrderArray.push(toDo.id);
      });
      let payload = {
        address_id: 123,
        address_task_list_id: 456,
        order_data: {
          type: this.room.type == 'room' ? 'room' : 'task_group',
          type_id: this.room.id,
          task_ids_in_order: tasksInOrderArray
        }
      }
      await this.toDos.updateToDoOrder(this.addressId, this.taskListId, payload)
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

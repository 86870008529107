import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Loading } from 'src/shared/components/loading/loading';
import { Logger } from 'src/providers/logger';
import { ParkingReimbursement } from 'src/providers/parking-reimbursement/parking-reimbursement';

import { ParkingReimbursementRequest } from 'src/shared/models/parking-reimbursement-request';

@Component({
  selector: 'parking-reimbursement',
  templateUrl: './parking-reimbursement.component.html',
  styleUrls: ['./parking-reimbursement.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ParkingReimbursementComponent implements OnInit {

  @Input() cleaningId: number;
  @Output() approvedParkingReimbursement = new EventEmitter();

  parkingReimbursements: ParkingReimbursementRequest[] = [];

  constructor(
    private parkingReimbursement: ParkingReimbursement,
    private logger: Logger
  ){}

  ngOnInit() {
    this.getParkingReimbursements(this.cleaningId);
  }

  @Loading('', true)
  async getParkingReimbursements(cleaningId: number) {
    try {
      const allparkingReimbursements: ParkingReimbursementRequest[] = await this.parkingReimbursement.listRequests(cleaningId);
      this.parkingReimbursements = this.filterByPendingStatus(allparkingReimbursements);
    } catch (e) {
      this.logger.error(e);
    }
  }

  @Loading('', true)
  async responseRequest(cleaningId: number, parkingReimbursementRequestId: number, status: string) {
    try {
      await this.parkingReimbursement.responseRequest(cleaningId, parkingReimbursementRequestId, status);
      this.getParkingReimbursements(cleaningId);
      this.approvedParkingReimbursement.emit();
    } catch (e) {
      this.logger.error(e);
    }
  }

  filterByPendingStatus(parkingReimbursements: ParkingReimbursementRequest[]) {
    return parkingReimbursements.filter((parking: ParkingReimbursementRequest) => parking.status === 'pending');
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Maintenance'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row *ngIf="maintenance?.maintenance?.photo_url">
          <div style="margin-top:-15px; margin-left:-15px; margin-right:-15px">
            <img [src]="maintenance?.maintenance?.photo_url">
          </div>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-text
            [actionBody]="maintenance?.room_object?.model?.category?.name"
            (action)="goToItemDetails()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [title]="maintenance?.maintenance?.name">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/calendar-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Do next:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="maintenance?.next_due_date  | customdate: 'MMM D, YY'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" *ngIf="maintenance?.last_maintenance_at">
          <tidy-image
            [src]="'assets/svg/checkmark-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Last completed:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="maintenance?.last_maintenance_at  | customdate: 'MMM D, YY'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" *ngIf="!maintenance?.last_maintenance_at">
          <tidy-image
            [src]="'assets/svg/close-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Never completed before'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/refresh-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Frequency:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="maintenance?.maintenance?.frequency + ' ' +
            maintenance?.maintenance?.frequency_unit + (maintenance?.maintenance?.frequency > 1 ? '(s)' : '')">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngFor="let step of maintenance?.maintenance?.steps; let index = index">
        <tidy-row style="background:#4BA7B7; margin-top:-15px; margin-left:-15px; margin-right:-15px; padding-top: 5px; padding-bottom:10px">
          <span class="item-menu" style="margin-left:15px; color: white;
          font-family: Neuton, serif;
          font-size: 13px;
          font-weight: bold;">
            STEP {{index+1}}
          </span><br>
          <tidy-text
            style="margin-left:15px; color:white"
            [header]="step?.title">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding">
          <tidy-text
            [body]="step?.description">
          </tidy-text>
        </tidy-row>
        <div style="margin-bottom:-30px; margin-left:-15px; margin-right:-15px" *ngIf="step?.medias?.length">
          <tidy-carrousel [scaleImages]="true">
            <ng-container *ngFor="let photo of step?.medias">
              <tidy-photo-note
                [src]="photo?.url"
                class="full-width there-is-carrousel no-margin"
                *tidyCarrousel>
              </tidy-photo-note>
            </ng-container>
          </tidy-carrousel>
        </div>
      </tidy-card>

      <tidy-button
        [text]="'Mark Complete'"
        class="primary"
        [action]="submitStatus.bind(this, 'completed')">
      </tidy-button>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Skip Maintenance'"
          (action)="submitStatus('skipped')"
          class="link">
        </tidy-text>
      </tidy-row>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Injectable } from '@angular/core';
import { AvailableTimesModel } from 'src/models/book-job.model';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class AvailableTimesForRange {

  constructor(private httpClient: HttpClientCustom) {}

  getAvailableTimes(request): Promise<AvailableTimesModel> {
    const address_id = localStorage.getItem('addressId');

    let url: String = `api/v1/calendar/available-times-for-range?address_id=${address_id}&region_id=${request.region_id}&frequency=${request.frequency}&start_date=${request.start_date}&end_date=${request.end_date}`;

    if (request.start_time && request.end_time) {
      url += `&start_time=${request.start_time}&end_time=${request.end_time}`;
    }
    if (request.isOpc === true) {
      url += `&off_plan_cleaning=true`;
    }
    if (request.filter) {
      if (!request.filter?.includes(':') && !request.filter?.includes('all')) {
        url += `&filter=homekeeper:${request.filter}`;
      } else {
        url += `&filter=${request.filter}`;
      }
    }
    if (request.reschedule_job_id) {
      url += `&reschedule_job_id=${request.reschedule_job_id}`;
    }
    if (!request.reschedule_job_id && request.typeOfPlan === 'reschedule-plan' && request.planId) {
      url += `&reschedule_plan_id=${request.planId}`;
    }
    if (request.service_type) {
      url += `&service_type=${request.service_type}`;
    }
    if (request.service_type_key) {
      url += `&service_type_key=${request.service_type_key}`;
    }

    return this.httpClient.get(url);
  }

  getAvailableTimesPrivatePro(request) {
    const url = `api/v1/calendar/available-times-for-service?address_id=${request.address_id}&service_type_key=${request.service_type_key}&frequency=${request.frequency}&date=${request.start_date}`;
    return this.httpClient.get(url);
  }

  getPrioritezedData(data): Promise<any> {
    const regionId: string = localStorage.getItem('region_id');
    const url = `api/v1/calendar/filters?cleaning_type=${data.cleaning_type}&region_id=${regionId}`;

    return this.httpClient.get(url);
  }

  checkIfCanRequestSubstitute(): Promise<any> {
    const address_id = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${address_id}/substitute-available`;
    return this.httpClient.get(url);
  }

}

import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'tidy-grid',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./grid.component.scss'],
  template: `
    <ion-grid>
      <ng-content></ng-content>
    </ion-grid>
  `
})

export  class GridComponent {
  @Input() verticalAlign = false;
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [noBow]="true"
    [canGoBack]="true"
    [title]="'Edit Report Name'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row class="field-top-padding">
            <tidy-input
              [label]="'Report Name'"
              [icon]="'assets/svg/information-circle-outline.svg'"
              [form]="form.controls.name"
              formControlName="name"
              [submitted]="submitted"
              [errorMessage]="'Please enter a name.'">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [action]="saveName.bind(this)"
        [text]="'Save Name'"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

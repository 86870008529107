import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/providers/logger';
import { Tips } from 'src/providers/tips/tips';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'edit-tip.html',
})
export class EditTipPage implements OnInit {
  
  cleaningId: string;
  homekeeperJobId: string;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  proName: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private activatedRoute: ActivatedRoute,
    private logger: Logger,
    private tipsProvider: Tips,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      tipValue: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Tip');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.cleaningId = this.activatedRoute.snapshot.paramMap.get('cleaningId') || this.dialogParams.cleaningId;
    this.homekeeperJobId = this.activatedRoute.snapshot.paramMap.get('homekeeperJobId') || this.dialogParams.homekeeperJobId;
    this.proName = this.navCtrl.getParam('proName') || this.dialogParams.proName;
  }

  async submit() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    try {
      const tip = this.form.get('tipValue').value * 100;
      await this.tipsProvider.addTip(this.cleaningId, this.homekeeperJobId, tip);
      const params = this.mountSuccessPageParams();
      this.rightSidePanelService.navigateTo('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err);
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  mountSuccessPageParams() {
    return {
      header: `Tip for ${this.proName} Updated`,
      body: '',
      buttonText: 'Ok',
      buttonRoute: `past-job/${this.cleaningId}`
    };
  }

}

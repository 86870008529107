import { Component, Input, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { add } from "lodash";
import { AttachmentModel } from "src/models/concierge.model";
import { Client } from "src/providers/client/client";
import { Concierge } from "src/providers/concierge/concierge";
import { Schedule } from "src/providers/schedule/schedule";
import { CustomDatePipe } from "src/shared/pipes/custom-date.pipe";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { humanize } from "src/shared/util/humanize";
import { Util } from "src/shared/util/util";
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { DisputeResolutionPage } from 'src/pages/concierge/dispute-resolution/dispute-resolution';
import { environment } from "src/environments/environment";

import { NeedsWorkWhatHappenedPage } from 'src/pages/feedback/needs-work/what-happened/what-happened';
import { LoveItPage } from 'src/pages/feedback/love-it/love-it';

enum ConciergeModalStates {
  LIST = 'LIST',
  ADD = 'ADD',
  EDIT = 'EDIT',
  REPLY = 'REPLY',
  SELECT_TYPE = 'SELECT_TYPE',
  SELECT_JOB = 'SELECT_JOB',
  SELECT_JOB_SUBTYPE = 'SELECT_JOB_SUBTYPE',
  BILLING_ISSUE = 'BILLING_ISSUE'
};

@Component({
  templateUrl: 'concierge-items.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./concierge-items.scss']
})
export class ConciergeItemsComponent {

  items: any;
  pageTitle: string;
  wasTableLoaded = false;
  currentRows = [];
  headers = [];
  isRightSideContent: boolean;
  isAddingItem: boolean;
  addressResponse: any[];
  errorMessage: string;
  editingItem: any;
  itemForm: FormGroup;
  replyForm: FormGroup;
  submitted = false;
  modalState: ConciergeModalStates = ConciergeModalStates.LIST;
  attachments: AttachmentModel[] = [];
  displayedAttachments: any[] = [];
  loaded: boolean;
  newItemType: string;
  pastJobsResponse: any;
  itemMetadata: any = {};
  email: string;
  totalItems: string;
  filters: string;
  constructor(
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private client: Client,
    private fb: FormBuilder,
    private concierge: Concierge,
    private schedule: Schedule,
    private util: Util,
    private navCtrl: CustomNavController
  ) {
    this.itemForm = this.fb.group({
      subject: ['', Validators.required],
      body: ''
    });

    this.replyForm = this.fb.group({
      content: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.buildHeaders();
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.items = this.getParam('items', dialogParams);
    this.buildRows(this.items);
    this.addressResponse = this.getParam('addressResponse', dialogParams);
    this.getEmail().then(email => this.email = email);
    this.wasTableLoaded = true;
    this.newItemType = this.getParam('type', dialogParams);
    this.pageTitle = this.getParam('title', dialogParams);
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.isAddingItem = this.getParam('isAddingItem', dialogParams);
    this.totalItems = this.getParam('totalItems', dialogParams);
    if (this.isAddingItem) {
      this.addItem();
    }
  }

  getParam(param, dialogParams) {
    return dialogParams[param];
  }

  buildHeaders(): void {
    this.headers = [
      'Subject',
      'Status',
      'Last Updated',
      'last-column'
    ];
  }

  buildRows(items: any[] = []) {
    this.currentRows = items.map((item) => {
      if (item.type.includes('dispute')) {
        return {
          'Subject': {
            value: item.header_text,
            action: null
          },
          'Status': {
            value: item.type,
            action: null
          },
          'Last Updated': {
            value: '',
            action: null
          },
          'last-column': {
            value: item.id,
            action: null,
          }
        };
      } else {
        return {
          'Subject': {
            value: item.components[0].value.subject,
            action: null
          },
          'Status': {
            value: humanize(item.components[0].value.status),
            action: null
          },
          'Last Updated': {
            value: this.parseDate(item.components[0].value.last_update_at),
            action: null
          },
          'last-column': {
            value: item.id,
            action: null,
          },
        };
      }
    });
  }

  parseDate(dateStr) {
    return new CustomDatePipe().transform(dateStr, 'M/D/YY h:mma', '')
  }

  onSearchChanges(event) {
    const search = event.search;
    const items = this.items.filter(item => {
      return item.components[0].value.subject.toLowerCase().includes(search.toLowerCase());
    });
    this.buildRows(items);
  }

  async onFilterChanges(event) {
    // INFO: BE does not support status filter for concierge items so I commented it out
    /* const statusFilter = event.selectedFilters.status; */
    const addressFilter = event.selectedFilters.propertyName;
    const dateRange = event.selectedFilters.dateRange;
    const loading = await this.util.showLoading();
    try {
      let filters = '';
      const addressIDFilter = addressFilter?.length ? `&address_id=${addressFilter[0]}` : '';
      const createdAfterFilter = dateRange?.start ? `&created_after=${dateRange.start}` : '';
      const createdBeforeFilter = dateRange?.end ? `&created_before=${dateRange.end}` : '';
      filters = addressIDFilter + createdAfterFilter + createdBeforeFilter;
      this.filters = filters;
      const items = await this.fetchItems({ pageIndex: 1, pageSize: 5 }, filters);
      let filteredItems = items;
      /* if (statusFilter.length) {
        filteredItems = items.filter(item => {
          return statusFilter.includes(item.components[0].value.status);
        });
      } */
      this.buildRows(filteredItems);
      this.items = filteredItems;
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
      loading.dismiss();
    }
    loading?.dismiss();
  }

  async onTableColumnClick(event) {
    if (event['Status']?.value?.includes('dispute')) {
      const dispute = this.items.find((item) => item.id == event['last-column'].value);
      const url = `dispute-resolution/${dispute.id}`;
      const params = {
        dispute: dispute,
        addEditPageTitle: 'Edit Rule'
      }
      const component = DisputeResolutionPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    }
    const itemId = event['last-column'].value;
    this.editMode(itemId);
  }

  reply() {
    this.modalState = ConciergeModalStates.REPLY;
  }

  async editMode(itemId) {
    try {
      this.editingItem = await this.concierge.getConciergeItemById(itemId);

      const commentAttachments = this.editingItem?.components[0]?.value?.comments
        .map(comment => comment?.attachments)
        .flat();

      const attachments = commentAttachments.map(file => {
        const fileUrl = file?.includes('https://') ? file : `${environment.aws_s3_bucket_url}${file}`;
        return {
          fileUrl,
          filename: file.split('/').pop(),
        };
      });
      this.displayedAttachments = attachments || [];

      this.modalState = ConciergeModalStates.EDIT;
    } catch (error) {
      this.errorMessage = error?.error?.message ? error.error.message : error.message;
    }
  }

  async sendReply() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.replyForm.valid) {
      return;
    }
    let data = {
    	support_ticket_id: this.editingItem.components[0].value.id,
      content: this.replyForm.value.content,
      attachments: this.attachments.map(item => item.fileKey)
    };
    try {
      await this.concierge.addConciergeTicketComment(data);
      this.editMode(this.editingItem.id);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      this.attachments.push(attachment);
    } catch (err) {
      console.error(err);
      this.errorMessage = err;
    }
  }

  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }

  addItem() {
    const diagTitle = 'Add ' + this.pageTitle.slice(0, -1);
    this.rightSidePanelService.setDialogPageTitle(diagTitle);
    if (this.newItemType) {
      return this.modalState = ConciergeModalStates.ADD;
    }
    this.modalState = ConciergeModalStates.SELECT_TYPE;
  }

  selectType(type) {
    this.newItemType = type;
    this.modalState = ConciergeModalStates.ADD;
  }

  selectJobIssueSubtype(subtype) {
    const pro = this.itemMetadata.job.homekeepers[0];
    const hkJob = this.itemMetadata.job.homekeeper_jobs[0];
    const jobId = this.itemMetadata.job.job.id;
    let params = {};

    switch (subtype) {

      case 'bad_quality':
        if (!hkJob.feedback_type) {
          params = {
            firstName: pro.first_name,
            pro,
            cleaningId: jobId,
            hkJobId: hkJob.id
          };
          return this.rightSidePanelService.navigateTo(`needs-work-what-happened/${jobId}/${hkJob.id}`, params, NeedsWorkWhatHappenedPage);
        } else {
          params = {
            job: this.itemMetadata.job,
            privateJob: this.itemMetadata.metadata.privateJob
          };
          return this.rightSidePanelService.navigateTo('job-issue-negative-review', params);
        }

      case 'no_show':
        params = {
          proName: pro.first_name,
          hkId: pro.id,
          jobId: jobId
        };
        return this.rightSidePanelService.navigateTo('pro-did-not-show-up', params);

      case 'missing_item':
        params = {
          jobId: jobId,
          hkJobId: hkJob.id,
          proName: this.itemMetadata.job.homekeepers[0].first_name,
          job: this.itemMetadata.job,
          privateJob: this.itemMetadata.metadata.privateJob
        };
        return this.rightSidePanelService.navigateTo('missing-item', params);

      case 'damage_claim':
      params = {
        job: this.itemMetadata.job,
        privateJob: this.itemMetadata.metadata.privateJob
      };
        return this.rightSidePanelService.navigateTo('damage-claim', params);

      case 'tip':
        params = {
          cleaningId: jobId,
          hkJobId: hkJob.id
        };
        return this.rightSidePanelService.navigateTo(`love-it/${jobId}/${hkJob.id}`, params, LoveItPage);

      case 'other_issue':
        this.modalState = ConciergeModalStates.ADD;
    }
  }

  async selectJob(job = null) {
    if (!job) {
      this.modalState = ConciergeModalStates.ADD;
      return;
    }

    const loading = await this.util.showLoading();
    try {
      const jobDetail = await this.schedule.getJobDetail(job.cleaning_id);
      const subtype = job.status === 'Cancelled by you' ? 'client_cancelled' : '2_pro_job';
      this.itemMetadata= {
        job: jobDetail,
        jobStatus: job.status,
        metadata : {
          job_id: jobDetail.job.id,
          job_datetime: jobDetail.job.start_datetime,
          privateJob: job.is_private,
          subtype
        }
      };
      if (jobDetail.booking.bookable_service.key.includes('turnover') || job.status === 'Cancelled by you') {
        this.modalState = ConciergeModalStates.ADD;
      } else {
        this.modalState = ConciergeModalStates.SELECT_JOB_SUBTYPE;
      }
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    } finally {
      loading.dismiss();
    }
  }

  goToJobIssueDetail(jobStatus, job, isPrivate) {
    const params = {
      job,
      jobStatus,
      isPrivate
    };
    this.rightSidePanelService.navigateTo('job-issue-detail', params);
  }

  billingIssue() {
    this.modalState = ConciergeModalStates.BILLING_ISSUE;
  }

  billingHistory() {
    this.rightSidePanelService.navigateTo('billing-history');
  }

  async saveItem() {
    console.log('1')
    const data = {
      ...this.itemForm.value,
      attachments: this.attachments.map(item => item.fileKey),
      emails: []
    }
    const item = {
      data,
      type: this.newItemType,
    }
    if (this.newItemType === 'support.job_issue') {
      item['metadata'] = this.itemMetadata?.metadata;
    }
    const loading = await this.util.showLoading();
    try {
      await this.concierge.addConciergeItem(item);
      const successMessage = this.pageTitle.includes('Actions') ? 'Action added' : 'Support requested';
      this.util.showSuccess(successMessage);
      if (this.isAddingItem) {
        loading.dismiss();
        return this.rightSidePanelService.navigateTo('concierge');
      }
      const items = await this.fetchItems({ pageIndex: 1, pageSize: 5 });
      this.buildRows(items);
      this.modalState = ConciergeModalStates.LIST;
      loading.dismiss();
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    }
  }

  async jobIssue() {
    const loading = await this.util.showLoading();
    this.newItemType = 'support.job_issue';
    try {
      this.pastJobsResponse = null;
      this.pastJobsResponse = await this.client.getJobHistory();
      const someJob = this.pastJobsResponse.some((job) => job?.service && job?.start_datetime_local);
      if (someJob) {
        if (this.pastJobsResponse.length > 5) {
          this.pastJobsResponse.length = 5;
        }
        this.modalState = ConciergeModalStates.SELECT_JOB;
      } else {
        this.modalState = ConciergeModalStates.ADD;
      }
    } catch (err) {
      this.errorMessage = err?.error?.message ? err.error.message : err.message;
    } finally {
      loading.dismiss();
    }
  }

  async getEmail() {
    let email = localStorage.getItem('clientEmail');
    if (email) {
      return email;
    } else {
      try {
        const customerId = localStorage.getItem('customer_id');
        const clientInfo = await this.client.getCustomerInfo(customerId);
        localStorage.setItem('clientEmail', clientInfo.email);
        return clientInfo.email;
      } catch (err) {
        this.errorMessage = err.error ? err.error.message : err.message;
      }
    }
  }

  itemTypeHeader(type) {
    switch (type) {
      case 'support.other':
        return 'Other Question';
      case 'support.how_to':
        return 'How to Use TIDY';
      case 'support.job_issue':
        return 'Job Issue';
      case 'support.billing':
        return 'Billing Issue';
      case 'support.developer':
        return 'Developer Question';
      case 'support.integration':
        return 'Marked Completed';
      case 'support.concierge_task':
        return 'Concierge Action';
    }
  }

  openAttachment(url) {
    this.util.openUrl(url);
  }

  async selectPage(event: { pageIndex: number, pageSize: number }): Promise<void> {
    const items = await this.fetchItems({ pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    this.buildRows(items);
  }

  async fetchItems(filterParams: { pageIndex: number, pageSize: number }, params?: any): Promise<any> {
    if (this.filters) {
      params = this.filters;
    }
    const conciergeAddressStatus = localStorage.getItem('isAllAddressesSelected');
    const addressId = conciergeAddressStatus === 'true' ? 0 : localStorage.getItem('addressId');
    const addressIdFilter = addressId === 0 ? '' : `&address_id=${addressId}`;
    const dialogParams = await this.storage.retrieve('dialog-params');
    const type = dialogParams.type;
    const isConciergeActions = type === 'support.concierge_task';
    const typeFilter = isConciergeActions ? '&concierge_item_type_key=concierge_task' : '&not_concierge_item_type_key=concierge_task';
    const isPending = false;
    const itemsResponse = await this.concierge.getConciergeItems(`${typeFilter}${addressIdFilter}&page=${filterParams.pageIndex}&per_page=${filterParams.pageSize}&is_marked_done=${isPending}${params ? `${params}` : ''}`);
    this.totalItems = itemsResponse.totalRecords;
    return itemsResponse.body;
  }
}

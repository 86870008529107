<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="!loaded ? '' : ('Messages With ' + pro?.homekeeper?.first_name)"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-image [src]="'assets/img/account.svg'" class="title-size">
          </tidy-image>
          <tidy-text [header]="pro?.homekeeper?.first_name" [translate]="false">
          </tidy-text>
          <tidy-text [body]="'Call'" class="link" (action)="callPro(pro)">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding extra-bottom-padding">
          <form [formGroup]="form" novalidate action="#">
            <tidy-textarea
              [label]="'Send Message'"
              formControlName="message"
              [form]="form.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)">
            </tidy-textarea>
          </form>
        </tidy-row>
        <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
          <tidy-text [helper]="'No Messages'"> </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let message of messages; let last = last">
          <message [message]="message" [last]="last" [proId]="pro?.id"> </message>
        </ng-container>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

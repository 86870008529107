import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { Auth } from 'src/providers/auth/auth';
import { BookJob } from 'src/providers/book-job/book-job';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';

@Injectable()
export class Proposals {

  constructor(
    private auth: Auth,
    private bookJobService: BookJob,
    private client: Client,
    private httpClient: HttpClientCustom,
    private me: Me,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService
  ) {}

  getProposals(status) {
    const url = `api/v1/customer/proposals?status=${status}`;
    return this.httpClient.get(url);
  }

  getProposalsByTeam(teamId) {
    const url = `api/v1/customer/proposals?team_id=${teamId}`;
    return this.httpClient.get(url);
  }

  getProposalsByTeamAndStatus(teamId, status) {
    const url = `api/v1/customer/proposals?status=${status}&team_id=${teamId}`;
    return this.httpClient.get(url);
  }

  getProposal(id) {
    const url = `api/v1/tidy-website/proposals/${id}`;
    return this.httpClient.get(url);
  }

  acceptProposal(id) {
    const url = `api/v1/tidy-website/proposals/${id}/accept`;
    return this.httpClient.put(url, {});
  }

  rejectProposal(id) {
    const url = `api/v1/tidy-website/proposals/${id}/reject`;
    return this.httpClient.put(url, {});
  }

  async bookJobForProposal(clientHasAccount, proposal, clientHubUuId = null) {
    try {
      if (!this.auth.isAuthenticated()) {
        return this.goToLogInToBookPage(clientHasAccount, proposal, clientHubUuId);
      }
      await this.me.load();
      const loggedInEmail = localStorage.getItem('customer_email');
      const isLoggedIntoCorrectAccount = (loggedInEmail == proposal?.customer.email) || !proposal?.customer.email;
      if (isLoggedIntoCorrectAccount) {
        this.goToBookJob(proposal);
      } else {
        this.goToLogInToBookPage(clientHasAccount, proposal, clientHubUuId);
      }
    } catch(err) {
      this.goToLogInToBookPage(clientHasAccount, proposal, clientHubUuId);
    }
  }

  async goToBookJob(proposal) {
    this.bookJobService.clearBookJobStorage();
    const addressResponse = await this.client.getMoreDetailAddresses();
    const addressId = proposal.customer?.address_ids[0] || await this.client.getSelectedAddressId(addressResponse);
    const address = addressResponse.find(ad => ad.id === addressId);
    let hasPlan = false;
    if (address.plans.length) {
      address.plans.map((plan) => {
        if (plan.is_private) {
          hasPlan = true;
        }
      });
    }
    if (hasPlan) {
      this.rightSidePanelService.navigateTo('book-with-pro', { address, proName: proposal.team.owner.name, proId: proposal.team.owner.id, bookingKey: proposal.service_details.team_service_key});
    } else {
      this.rightSidePanelService.navigateTo('book-job', { address, bookingType: 'add_job', hkId: proposal.team.owner.id, filterByPro: true, bookingKey: proposal.service_details.team_service_key});
    }
  }

  goToLogInToBookPage(clientHasAccount, proposal, clientHubUuId) {
    if (clientHasAccount) {
      this.navCtrl.navigateForward('log-in-to-book', { proposal: proposal, clientHubUuId: clientHubUuId });
    } else {
      this.navCtrl.navigateForward('create-account-to-book', { proposal: proposal, clientHubUuId: clientHubUuId });
    }
  }
  
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

import { GuestReservationSource } from 'src/models/guest-reservations';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { Util } from 'src/shared/util/util';
import validationUtils from 'src/shared/util/validation-utils';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { environment } from 'src/environments/environment';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { SuccessPage } from 'src/shared/pages/success/success';
import { AddAccountNotificationPage } from 'src/pages/more/add-account-notification/add-account-notification';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'add-integration-form',
  templateUrl: './add-integration-form.component.html',
  styleUrls: ['./add-integration-form.component.scss']
})

export class AddIntegrationFormComponent extends TimeoutableComponent {

  @Input() isUpdatingIntegration: boolean;
  @Input() isRightSideContent: boolean;
  @Input() dialogParams: any;
  addresses: any;
  addressesSelectItems: Array<TidySelectNumberValueModel>;
  allIntegrationsResponse: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isBaseURLRequired: boolean;
  isSelfBookingFlow: boolean;
  isDirectFormRequired: boolean;
  isAccountIdRequired: boolean;
  integrationSrcType: any;
  integrationOptionItems: any;
  longDescription: string;
  onlyShowDirectIntegration: boolean;
  reservationSrc: GuestReservationSource;
  submitted = false;
  supportsDirectIntegration: boolean;
  hasIntegrations: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    public route: ActivatedRoute,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    public onboardingProvider: OnboardingProvider
  ) {
    super();
    this.form = this.fb.group({
      apiKey: [''],
      accountId: [''],
      importUrl: [''],
      addressId: [''],
      integrationOption: [''],
      phone: [''],
      baseURL: [''],
      conciergeLogin: [''],
      conciergePassword: [''],
      conciergeNotes: ['']
    });
  }

  async ngOnInit() {
    try {
      this.rightSidePanelService.setDialogPageTitle(this.isUpdatingIntegration ? 'Update Integration' : 'Add Integration');
      this.allIntegrationsResponse = await this.getParam('allIntegrationsResponse') || await this.guestReservations.getSourceTypes();
      this.integrationSrcType = await this.getParam('reservationSrcType');
      this.isBaseURLRequired = this.checkIfIsBaseURLRequired();
      this.isDirectFormRequired = this.checkIfIsDirectFormRequired();
      if (this.isDirectFormRequired) {
        this.isAccountIdRequired = this.checkIfIsAccountIdRequired();
      }
      this.addresses = await this.client.getAddresses();
      this.addressesSelectItems = this.client.parseAddressList(this.addresses);
      this.isSelfBookingFlow = this.route?.routeConfig?.path.includes('direct');
      this.onlyShowDirectIntegration = this.isSelfBookingFlow;
      this.integrationOptionItems = await this.getIntegrationOptionItems();
      this.updateFormValidation({value: this.form.value.integrationOption});
      if (this.addressesSelectItems.length > 0 && (this.form.value.integrationOption == 'icalendar' || !this.supportsDirectIntegration)) {
        const addressId = await this.client.getSelectedAddressId(this.addresses);
        this.form.controls.addressId.setValue(addressId);
      }
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  showInstructionsLink() {
    const names = [
      'Hostaway',
      'OwnerRez',
      'RemoteLock',
      'Guesty',
      'Host Tools',
      'Hostify',
      'Beds24',
      'Hospitable',
      'Buildium',
      'Doorloop'
    ];
    return names.includes(this.integrationSrcType.name);
  }

  getSelectedFormatHeader() {
    const headers = {
      notification: 'Direct Integration',
      api: 'Direct Integration',
      icalendar: 'iCal Integration',
      concierge_integration: 'Concierge Integration',
      zapier: 'Zapier Integration'
    }
    return headers[this.form.value.integrationOption];
  }

  getSelectedFormatIcon() {
    const headers = {
      notification: 'assets/img/gold-link.svg',
      api: 'assets/img/gold-link.svg',
      icalendar: 'assets/img/ical.png',
      concierge_integration: 'assets/img/concierge.svg',
      zapier: 'assets/img/zapier.svg'
    }
    return headers[this.form.value.integrationOption];
  }

  checkIfIsBaseURLRequired() {
    const requiredIntegrations = [
      'Buildium'
    ];
    return requiredIntegrations.includes(this.integrationSrcType.name);
  }

  checkIfIsDirectFormRequired() {
    const requiredIntegrations = [
      'Hostaway',
      'Guesty',
      'Host Tools',
      'Hostify',
      'Beds24',
      'Lodgify',
      'Buildium',
      'Doorloop'
    ];
    return requiredIntegrations.includes(this.integrationSrcType.name);
  }

  checkIfIsAccountIdRequired() {
    const requiredIntegrations = [
      'Hostaway',
      'Guesty',
      'Buildium'
    ];
    return requiredIntegrations.includes(this.integrationSrcType.name);
  }

  updateFormValidation(selection) {
    console.log(selection.value)
    console.log(this.supportsDirectIntegration)
    this.longDescription = this.getLongDescription(selection);
    this.form.get('conciergeLogin').clearValidators();
    this.form.get('conciergeLogin').updateValueAndValidity();
    this.form.get('conciergePassword').clearValidators();
    this.form.get('conciergePassword').updateValueAndValidity();
    this.form.get('addressId').clearValidators();
    this.form.get('addressId').updateValueAndValidity();
    this.form.get('importUrl').clearValidators();
    this.form.get('importUrl').updateValueAndValidity();
    this.form.get('apiKey').clearValidators();
    this.form.get('apiKey').updateValueAndValidity();
    this.form.get('accountId').clearValidators();
    this.form.get('accountId').updateValueAndValidity();
    this.form.get('baseURL').clearValidators();
    this.form.get('baseURL').updateValueAndValidity();
    if (selection.value == 'api' && this.supportsDirectIntegration) {
      this.form.controls.apiKey.setValidators([Validators.required]);
      this.form.controls.apiKey.updateValueAndValidity();
      if (this.isAccountIdRequired) {
        this.form.controls.accountId.setValidators([Validators.required]);
        this.form.controls.accountId.updateValueAndValidity();
      }
      if (this.isSelfBookingFlow) {
        this.form.controls.phone.setValidators([Validators.required]);
        this.form.controls.phone.updateValueAndValidity();
      }
      if (this.isBaseURLRequired) {
        this.form.controls.baseURL.setValidators([Validators.required]);
        this.form.controls.baseURL.updateValueAndValidity();
      }
    }
    if (selection.value == 'concierge_integration') {
      this.form.controls.conciergeLogin.setValidators([Validators.required]);
      this.form.controls.conciergePassword.setValidators([Validators.required]);
    } else {
      console.log('setting validators')
      if (this.form.value.integrationOption == 'icalendar') {
        this.form.controls.addressId.setValidators([Validators.required]);
        this.form.controls.importUrl.setValidators([Validators.required, validationUtils.validateUrl]);
      } else {
        this.form.controls.addressId.clearValidators();
        this.form.controls.addressId.updateValueAndValidity();
        this.form.controls.importUrl.clearValidators();
        this.form.controls.importUrl.updateValueAndValidity();
      }
    }
  }

  getLongDescription(selection) {
    const integration = this.isSelfBookingFlow ? this.integrationSrcType : this.integrationSrcType.integrations.find(integration => integration.format == selection.value);
    return integration?.long_description;
  }

  checkPreviousIntegrations(): boolean{
    let hasPreviousIntegrations: boolean = false;
    this.addresses.map(address => {
      if(address?.integration?.state === 'inactive'){
        hasPreviousIntegrations = true
      }
    });
    return hasPreviousIntegrations;
  }

  async addReservationSource() {
    this.errorMessage = '';
    this.submitted = true;
    console.log('addReservationSource', this.form)
    Object.keys(this.form.controls).forEach((field, index) => {
      const control = this.form.get(field);
      if (!control.valid && control.errors) {
        const message = `Field ${field} is invalid: ${JSON.stringify(control.errors)}`;
        console.log(message);
        if (index == 0) {
          this.util.showWarning(message);
        }
      }
    });

    if (!this.form.valid) {
      return;
    }

    try {
      if (this.isSelfBookingFlow) {
        await this.addPhone();
      }
      if (this.integrationSrcType.name == 'Slack' && this.form.value.integrationOption == 'notification') {
        return this.goToAddAccountNotifications();
      }
      if (this.form.value.integrationOption == 'api' && !this.supportsDirectIntegration) {
        return this.requestIntegration();
      }
      if (this.form.value.integrationOption == 'api' && this.integrationSrcType.name == 'OwnerRez') {
        return this.goToAddOwnerRezIntegration();
      }
      if (this.integrationSrcType.name == 'RemoteLock') {
        return this.goToAddRemoteLockIntegration();
      }
      if (this.form.value.integrationOption == 'api' && this.integrationSrcType.name == 'Smoobu'){
        return this.goToAddSmoobuIntegration();
      }
      if (this.form.value.integrationOption == 'api' && this.integrationSrcType.name == 'Hospitable') {
        return this.goToAddHospitableIntegration();
      }
      const integration = await this.addIntegration();
      this.addresses = await this.client.getAddresses(true);
      this.goToSuccessPage(integration);
      await this.finishOnboarding();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async finishOnboarding() {
    await this.onboardingProvider.markGoalAsCompleted('selectIntegration');
    this.onboardingProvider.setShowOnboardingOnPage(false);
    this.onboardingProvider.setShowOnboardingInHeader(true);
  }

  async addPhone() {
    const payload = {
      phone: this.form.value.phone
    }
    await this.client.saveClientSettings(payload);
  }

  async addIntegration() {
    if (this.form.value.integrationOption == 'api') {
      const payload = this.getDirectIntegrationPayload()
      return await this.guestReservations.addDirectReservationSource(payload);
    } else if (this.form.value.integrationOption == 'concierge_integration') {
      const payload = this.getConciergeIntegrationPayload();
      return await this.guestReservations.addConciergeReservationSource(payload);
    } else {
      return await this.guestReservations.addiCalReservationSource(this.form.value, this.getIntegrationId('icalendar'));
    }
  }

  getConciergeIntegrationPayload() {
    return {
      guest_reservation_source_type_id: this.getIntegrationId('concierge_integration'),
      data: {
        username: this.form.value.conciergeLogin,
        password: this.form.value.conciergePassword,
        notes: this.form.value.conciergeNotes
      }
    }
  }

  getDirectIntegrationPayload() {
    const payloadApiKeyIntegrations = [
      'Hostify',
      'Beds24',
      'Lodgify',
      'Doorloop'
    ];
    const payload: any = {
      guest_reservation_source_type_id: this.getIntegrationId('api')
    };
    if (this.integrationSrcType.name == 'Buildium') {
      payload['data'] = {
        base_url: this.form.value.baseURL,
        credentials: {
          clientId: this.form.value.accountId,
          secret: this.form.value.apiKey
        }
      }
      return payload;
    }
    const isApiKeyPayload = payloadApiKeyIntegrations.includes(this.integrationSrcType.name);
    if (this.integrationSrcType.name == 'Guesty') {
      payload['data'] = {
        integration_token: this.form.value.apiKey
      }
      return payload;
    }
    if (this.integrationSrcType.name == 'Hostaway') {
      payload['data'] = {
        client_id: this.form.value.accountId,
        client_secret: this.form.value.apiKey
      };
    } else if (isApiKeyPayload) {
      payload['data'] = {
        api_key: this.form.value.apiKey
      }
    } else if (this.integrationSrcType.name == 'Host Tools') {
      payload['data'] = {
        api_access_token: this.form.value.apiKey
      }
    }
    return payload;
  }

  getIntegrationId(format) {
    let id = 0;
    this.allIntegrationsResponse.find((item) => {
      if (this.integrationSrcType.name == item.name && item.format == format) {
        id = item.id;
      }
    });
    return id;
  }

  async getDeDuplicationWarning(){
    const integrations = await this.guestReservations.getReservationSource(['id']);
    const hasIntegrations = integrations.length > 0;
    if(hasIntegrations || this.checkPreviousIntegrations()){
      if(this.integrationSrcType.allow_deduplication){
        return `When importing addresses, we will attempt to match your existing addresses. If we can't find a match, we will make a new one.`;
      } else {
        return `When importing, we will add all addresses from your integration. If you already have an address, a duplicate may be made and you will need to remove them manually.`
      }
    }
    else{
      return '';
    }
  }

  async goToSuccessPage(integration) {
    let deDuplicationNotice: string = '';
    await this.storage.save('didMakeIntegrationChanges', true);
    if (this.form.value.integrationOption == 'icalendar') {
      const address = this.addresses.length == 1 ?
        this.addresses[0] :
        this.addresses.find((address) => {
          if (this.form.value.addressId == address.id) {
            return address;
          }
        })
      const params = {
        integration,
        address: address,
        cameFromAddIntegration: true
      };
      this.rightSidePanelService.navigateTo(`automatic-booking-property/${address.id}`, params, AutomaticBookingAddressPage);
    } else if (this.form.value.integrationOption == 'concierge_integration') {
      const successParams = {
        header: 'Concierge Integration Syncing',
        body: `Our Concierge will log into your systems to get the data you want and reach out with any questions.`,
        buttonText: 'Ok',
        buttonRoute: 'integrations/all'
      };
      this.rightSidePanelService.navigateTo('success-no-auth', successParams, SuccessPage);
    } else {
      deDuplicationNotice = await this.getDeDuplicationWarning();
      const successParams = {
        header: this.integrationSrcType.name + ' Integration Syncing',
        body: `It can take up to 15 minutes for the first sync. You can view all your synced guest reservations in the "Schedule" section. ${deDuplicationNotice}`,
        buttonText: 'Ok',
        buttonRoute: 'integrations/all'
      };
      this.rightSidePanelService.navigateTo('success-no-auth', successParams, SuccessPage);
    }
  }

  async deactivateIntegration() {
    const params: ConfirmPageParamsModel = {
      title: 'Disable Integration?',
      body: 'Are you sure you want to disable this integration?  This will remove any reservations from TIDY.',
      backText: 'Go Back',
      confirmText: 'Disable',
      confirmAction: await this.confirmDeactivateIntegration.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDeactivateIntegration() {
    try {
      await this.storage.save('didMakeIntegrationChanges', true);
      await this.guestReservations.deactivateIntegration(this.reservationSrc.id);
      this.modalCtrl.dismiss();
      if (this.isRightSideContent) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateForward('integrations/all');
      }
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async getIntegrationOptionItems() {
    const array: any = [];
    let includesiCal = false;
    const formatNames = {
      'icalendar': 'iCal',
      'concierge_integration': 'Concierge',
      'notification': 'Direct',
      'api': 'Direct',
      'zapier': 'Zapier'
    }
    this.integrationSrcType.formats.map((format) => {
      if (format == 'api' || format == 'notification' || format == 'locks') {
        this.supportsDirectIntegration = true;
      }
      if ((format == 'api' || format == 'notification') || !this.onlyShowDirectIntegration) {
        array.push({
          value: format,
          viewValue: formatNames[format]
        });
      }
      if (format == 'icalendar') {
        includesiCal = true;
      }
    });
    if (this.supportsDirectIntegration) {
      this.form.controls.integrationOption.setValue(this.integrationSrcType.name == 'Slack' ? 'notification' : 'api');
    } else if (includesiCal) {
      this.form.controls.integrationOption.setValue('icalendar');
    } else {
      this.form.controls.integrationOption.setValue(array[0].value);
    }
    return array;
  }

  async goToAddOwnerRezIntegration() {
    let deDuplicationNotice: string = '';
    let code = '';
    if (environment.api_secure == 'https://api-sandbox3.tidy.com/') {
      code = 'c_57h7ur1g34dibalznzm7f0vhw0a0ud0j';
    }
    if (environment.api_secure == 'https://api-staging.tidy.com/') {
      code = 'c_37espnv3oh3jz0pgstnw9mxlomccndmp';
    }
    if (environment.api_secure == 'https://api-production.gotidy.com/') {
      code = 'c_4nlq1g8lyrxmob3ogph353016h68z7ug';
    }
    this.util.openUrl(`https://secure.ownerreservations.com/oauth/authorize?response_type=code&client_id=${code}`);
    deDuplicationNotice = await this.getDeDuplicationWarning();
    const successParams = {
      header: 'Adding OwnerRez Integration',
      body: `Please complete the steps at OwnerRez.com to finish integrating. ${deDuplicationNotice}`,
      buttonText: 'Ok',
      buttonRoute: this.isSelfBookingFlow ? 'direct-integration-options' : 'integrations/all'
    };
    const successPath = this.isSelfBookingFlow ? 'success-no-auth' : 'success'
    await this.storage.save('didMakeIntegrationChanges', true);
    this.rightSidePanelService.navigateTo(successPath, successParams, SuccessPage);
  }

  async goToAddRemoteLockIntegration() {
    let redirectURI = 'https://ca-v2-sandbox3.tidy.com/?integrationType=remotelock';
    if (environment.api_secure == 'https://api-staging.tidy.com/') {
      redirectURI = 'https://ca-v2-staging.tidy.com/?integrationType=remotelock';
    }
    if (environment.api_secure == 'https://api-production.gotidy.com/') {
      redirectURI = 'https://app.tidy.com/?integrationType=remotelock';
    }
    this.util.openUrl(`https://connect.remotelock.com/oauth/authorize?client_id=eabb631208b4d5d6eaa5427560547982a7ee6c33ca77af811d5dfa849d702f04&redirect_uri=${redirectURI}&response_type=code`);
    let deDuplicationNotice: string = '';
    deDuplicationNotice = await this.getDeDuplicationWarning();
    const successParams = {
      header: 'Adding RemoteLock Integration',
      body: `Please complete the steps at RemoteLock.com to finish integrating. ${deDuplicationNotice}`,
      buttonText: 'Ok',
      buttonRoute: 'integrations/all'
    };
    await this.storage.save('didMakeIntegrationChanges', true);
    this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
  }

  async goToAddSmoobuIntegration() {
    let deDuplicationNotice: string = '';
    let code = '53';
    let state = (Math.random() + 1).toString(36).substring(2);
    this.util.openUrl(`https://login.smoobu.com/en/oauth/authorize?client_id=${code}&response_type=code&state=${state}`);
    deDuplicationNotice = await this.getDeDuplicationWarning();
    const successParams = {
      header: 'Adding Smoobu Integration',
      body: `Please complete the steps at smoobu.com to finish integrating. ${deDuplicationNotice}`,
      buttonText: 'Ok',
      buttonRoute: this.isSelfBookingFlow ? 'direct-integration-options' : 'integrations/all'
    };
    const successPath = this.isSelfBookingFlow ? 'success-no-auth' : 'success'
    await this.storage.save('didMakeIntegrationChanges', true);
    this.rightSidePanelService.navigateTo(successPath, successParams, SuccessPage);
  }

  async goToAddHospitableIntegration() {
    this.util.openUrl(`https://auth.hospitable.com/oauth/authorize?client_id=9c81922c-44f5-4a91-ac9d-b967ad2eab6a&response_type=code`);
    const deDuplicationNotice = await this.getDeDuplicationWarning();
    const successParams = {
      header: 'Adding Hospitable Integration',
      body: `Please complete the steps at hospitable.com to finish integrating. ${deDuplicationNotice}`,
      buttonText: 'Ok',
      buttonRoute: this.isSelfBookingFlow ? 'direct-integration-options' : 'integrations/all'
    };
    const successPath = this.isSelfBookingFlow ? 'success-no-auth' : 'success'
    await this.storage.save('didMakeIntegrationChanges', true);
    this.rightSidePanelService.navigateTo(successPath, successParams, SuccessPage);
  }

  goToHelp() {
    this.util.openUrl(this.integrationSrcType.tidy_help_link);
  }

  goToZapier() {
    this.util.openUrl('https://zapier.com/apps/tidy/integrations');
  }

  getClientIdFieldHeader() {
    if (this.integrationSrcType.name == 'Buildium') {
      return 'Client ID';
    } else {
      return 'Account ID';
    }
  }

  getApiKeyFieldHeader() {
    if (this.integrationSrcType.name == 'Buildium') {
      return 'Secret';
    } else if (this.integrationSrcType.name == 'Host Tools') {
      return 'API Access Token';
    } else if (this.integrationSrcType.name == 'Guesty') {
      return 'Client Secret';
    } else {
      return 'API Key';
    }
  }

  getPrimaryButtonTitle() {
    if (this.isUpdatingIntegration) {
      return 'Update Integration'
    } else if (this.form.value.integrationOption == 'api' && !this.supportsDirectIntegration) {
      return 'Request Integration';
    } else {
      return 'Add Integration';
    }
  }

  async requestIntegration() {
    try {
      const payload = {
        key: `${this.integrationSrcType.name} + integration`,
        note: ''
      }
      await this.client.requestUpcomingFeature(payload);
      const successParams = {
        header: 'Integration Requested',
        body: 'Our Concierge will reach out in the next 1-2 business days.',
        buttonText: 'Ok',
        buttonRoute: 'integrations/all'
      };
      this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
    } catch (err) {
      if (err.message === 'You have already voted on this feature!') {
        const successParams = {
          header: 'Integration Already Requested',
          body: 'Our Concierge will reach out in the next 1-2 business days from when you requested this integration. Thank you for your patience.',
          buttonText: 'Ok',
          buttonRoute: 'integrations/all'
        };
        await this.storage.save('didMakeIntegrationChanges', true);
        this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
      } else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  goToAddAccountNotifications() {
    const params: any = {
      reservationSrcType: this.integrationSrcType,
      isUpdatingIntegration: false
    }
    this.rightSidePanelService.navigateTo('add-account-notification', params, AddAccountNotificationPage)
  }

  goToAddProperty() {
    this.onboardingProvider.priorPages.push('addIntegration');
    this.onboardingProvider.page = 'property';
  }

}

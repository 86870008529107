import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamPage } from '../team/team';

@Component({
  templateUrl: 'edit-team-name.html'
})

export class EditTeamNamePage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  team: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private teamService: TeamService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      teamName: ['', Validators.required],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Team Name');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    try {
      this.team = await this.getParam('team');
      this.form.patchValue({teamName: this.team.name});
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    return param;
  }

  @Loading('', true)
  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const payload = {
      name: this.form.value.teamName,
      parent_team_id: this.team.parent_team_id
    }
    try {
      await this.teamService.editTeam(this.team.id, payload);
      if (this.dialogParams?.team) this.dialogParams.team = null;
      if (this.dialogParams?.teams) this.dialogParams.teams = null;
      await this.storage.save('dialog-params', this.dialogParams);
      const nextPage = await this.getParam('nextPage');
      this.rightSidePanelService.navigateTo(nextPage, {}, TeamPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Webhooks } from 'src/providers/webhooks/webhooks';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'edit-webhook.html',
  encapsulation: ViewEncapsulation.None
})

export class EditWebhookPage implements OnInit {

  channelItems: any;
  eventItems: any;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  showSelectedEventsError: boolean;
  loaded: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private webhook: Webhooks,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private util: Util
  ) {
    this.form = this.fb.group({
      webhookUrl: ['', Validators.required],
      description: '',
      channel: ['', Validators.required],
      events: [''],
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Edit Webhook');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.errorMessage = '';
      this.loaded = false;
      const webhookEvents = await this.getParam('webhookEvents');
      const test = webhookEvents.split(',');
      this.form.patchValue({
        channel: await this.getParam('webhookChannel'),
        description: await this.getParam('webhookDescription'),
        webhookUrl: await this.getParam('webhookUrl'),
      });
      this.channelItems = this.buildChannelItems();
      this.eventItems = await this.buildEventItems();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async buildEventItems() {
    const alreadyAddedEvents = await this.getParam('webhookEvents');
    const allEvents = await this.webhook.getWebhookEvents();
    const array: any = [];
    allEvents.map((event) => {
      array.push({
        value: event.key,
        viewValue: event.display_name,
        description: event.description,
        checked: false
      })
    });
    array.map((item) => {
      if (alreadyAddedEvents.includes(item.viewValue)) {
        item.checked = true;
      }
    });
    return array;
  }

  buildChannelItems() {
    return [
      {
        value: 'webhook',
        viewValue: 'Standard Webhook'
      },
      {
        value: 'slack',
        viewValue: 'Slack'
      }
    ];
  }

  async saveChanges() {
    this.errorMessage = '';
    this.submitted = true;
    const selectedEvents: any = [];
    this.eventItems.map((item) => {
      if (item.checked) {
        selectedEvents.push(item.value);
      }
    });
    if (!this.form.valid || selectedEvents.length == 0) {
      if (selectedEvents.length == 0) {
        this.showSelectedEventsError = true;
      }
      return;
    }
    try {
      const webhookId = await this.getParam('webhookId');
      const payload = {
        webhook_url: this.form.value.webhookUrl,
        description: this.form.value.description,
        channel: this.form.value.channel,
        events: selectedEvents
      }
      await this.webhook.editWebhook(webhookId, payload);
      this.util.showSuccess('Changes Saved');
      this.rightSidePanelService.navigateTo('webhooks');
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

}

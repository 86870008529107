import { Injectable } from "@angular/core";
import { HttpClientCustom } from "../custom/http-client";
import { Addresses } from "../customer/addresses";
import { Aws } from "../aws/aws";
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { CustomNavParams } from 'src/shared/providers/custom-nav-params/custom-nav-params';
import { Instructions } from "../instructions/instructions";

@Injectable()
export class PhotoNotes {
  addressId: string;

  constructor(
    private addresses: Addresses,
    private aws: Aws,
    private camera: CameraProvider,
    private customNavParams: CustomNavParams,
    private httpClient: HttpClientCustom,
    private instructions: Instructions,
  ) {
  }

  async captureUploadPhoto(photoKey) {
    const picture = await this.getImg();
    return await this.uploadPictureToS3(picture.dataUrl, photoKey);
  }

  async addPhotoNote(type, params) {
    if (type === 'task') {
      return await this.taskPhotoNotes(params.roomId, params.taskId);
    }

    if (type === 'room') {
      return await this.roomPhotoNote(params.roomId, '');
    }

    return await this.addressPhotoNote(type);
  }

  async addressPhotoNote(type) {
    const awsResp = await this.captureUploadPhoto(`${type}`);
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const url = `api/v1/customer/addresses/${addressId}/photo-notes`;
    return this.httpClient.post(url, {s3_photo_url_key: awsResp.Key, type});
  }

  async saveAddressPhotoNote(type: string, awsRespKey: string) {
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const url = `api/v1/customer/addresses/${addressId}/photo-notes`;
    return this.httpClient.post(url, {s3_photo_url_key: awsRespKey, type});
  }

  async roomPhotoNote(card, awsKey: string) {
    if (card.type == 'group') {
      const url = `api/v1/customer/address-task-groups/${card.id}/photo-notes`;
      return this.httpClient.post(url, { s3_photo_url_key: awsKey });
    } else {
      const addressId = localStorage.getItem('addressId') || this.addressId ;
      const url = `api/v1/customer/addresses/${addressId}/rooms/${card.id}/photo-notes`;
      return this.httpClient.post(url, { s3_photo_url_key: awsKey, type: card.type });
    }
  }

  async taskPhotoNotes(roomId, taskId) {
    const photoKey = `${roomId}/${taskId}`;
    const awsResp = await this.captureUploadPhoto(photoKey);
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const url = `api/v1/customer/addresses/${addressId}/rooms/${roomId}/tasks/${taskId}/photo-notes`;
    return this.httpClient.post(url, {s3_photo_url_key: awsResp.Key, type: 'task'});
  }

  async photoCaption(photoNoteId, caption) {
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const url = `api/v1/customer/addresses/${addressId}/photo-notes/${photoNoteId}`;
    return this.httpClient.put(url, {caption});
  }

  async deletePhotoNotes(photoNoteId) {
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const url = `api/v1/customer/addresses/${addressId}/photo-notes/${photoNoteId}`;
    return this.httpClient.delete(url);
  }

  splitAddressPhotos(addressPhotos) {
    const splitedPhotos = {
      parking: [],
      access: [],
      closing: []
    }
    addressPhotos.forEach(item => {
      splitedPhotos[item.type].push(item);
    });

    return splitedPhotos;
  }

  async getImg() {
    return await this.camera.getImg();
  }

  async uploadPictureToS3(picture: string, key: string) {
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    const uniqueKey = `${addressId}/${key}/${Date.now()}`;
    return await this.aws.uploadImageToS3(picture, uniqueKey);
  }

  async getPhotos(type, params = null) {
    if (type === 'task') {
      return await this.getTodosPhotos(params);
    }

    if (type === 'room') {
      return await this.getRoomPhotos(params);
    }

    return await this.getAddressPhotos(type);
  }

  async getTodosPhotos(taskParams) {
    const room = await this.instructions.getRoom(taskParams);
    const updatedTask = room.tasks.find(item => {
      return item.id === taskParams.taskId;
    });

    return updatedTask.photo_notes;
  }

  async getRoomPhotos(params) {
    const room = await this.instructions.getRoom(params);
    return room.room.photo_notes;
  }

  async getAddressPhotos(type) {
    const addressInfo = await this.addresses.getAddress();
    const addressPhotos = this.splitAddressPhotos(addressInfo.photo_notes);
    return addressPhotos[type];
  }

  handleDesktop(navCtrl) {
    const desktopPageContent = {
      title: 'Photo Note',
      description: 'Please use the iOS or Android app on your mobile phone to add a photo note.',
      buttonText: 'Go Back'
    };

    this.customNavParams.setParams('info-page', desktopPageContent)
    navCtrl.navigateForward('info-page');
  }

  handleError(navCtrl) {
    const errorPageContent = {
      title: 'Photo Note',
      description: 'Something went wrong. Please Try again',
      buttonText: 'Go Back'
    };

    this.customNavParams.setParams('info-page', errorPageContent)
    navCtrl.navigateForward('info-page');
  }

  deletePhotoAlert() {
    const deletePhotoAlert = {
      header: 'Delete Photo?',
      message: '',
      buttons: [
        {
          text: 'Go back',
          role: 'cancel',
          handler: null
        },
        {
          text: 'Delete'
        }
      ]
    };

    return deletePhotoAlert;
  }

  async addTempPhoto(picture = null) {
    const addressId = localStorage.getItem('addressId') || this.addressId ;
    picture = await this.getImg();
    const awsResponse = await this.uploadPictureToS3(picture, addressId);

    const data = {...awsResponse, photo_url: picture};
    return data;
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Item'"
    [canGoBack]="true"
    [customBack]="customBack"
    [showLinkIcon]="true"
    [linkIcon]="'trash'"
    (linkAction)="deleteItem()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="item?.current_quantity < item?.min_quantity">
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/action-needed.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Low Inventory'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'The inventory of this item is currently low. We created a task for the operations team to restock per your settings.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <img *ngIf="item?.photo_url" [src]="item?.photo_url">

      <tidy-card>
        <tidy-card-button class="arrow-to-top" (action)="goToEditItem()">
          <tidy-row class="no-padding extra-bottom-padding">
            <ion-row>
              <tidy-row class="vertical-align-center" *ngIf="item?.model?.category?.default_icon_url">
                <tidy-image
                  [src]="item?.model?.category?.default_icon_url"
                  style="width:40px">
                </tidy-image>
              </tidy-row>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="item?.name">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    *ngIf="item?.model?.model && item?.model?.model !== item?.name"
                    [body]="item?.model?.model">
                  </tidy-text>
                </tidy-row>
                <tidy-row *ngIf="item?.current_quantity">
                  <tidy-text
                    [body]="('Current Quantity: ' | translate) + item?.current_quantity">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
          <tidy-row class="extra-bottom-padding"></tidy-row>
          <tidy-row *ngIf="item?.model?.characteristics[0]?.name" class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/information.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="getCharacteristics(item?.model?.characteristics)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/bell.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [body]="('Low Inventory Alert: ' | translate) + (item?.min_quantity ? ('ON' | translate) : ('OFF' | translate))">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="item?.min_quantity">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/min-amount.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Min Amount: ' | translate) + item?.min_quantity">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/max-amount.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Max Amount: ' | translate) + (item?.max_quantity ? item?.max_quantity : ('no max' | translate))">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding" *ngIf="item?.opportunistic_quantity">
              <tidy-image
                [src]="'assets/img/packages.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Opportunistic Order Level: ' | translate) + (item?.opportunistic_quantity) + (' min (restock if other items are being restocked)' | translate)">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/best-price.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Restock Price: $' | translate) + (item?.min_restocking_price || 0) + (' min / ' | translate) + (item?.max_restocking_price ? '$' + item?.max_restocking_price : ('no max' | translate))">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle extra-bottom-padding" *ngIf="item?.where_to_order">
              <tidy-image
                [src]="'assets/img/store.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Where to Order: ' | translate) + item.where_to_order">
              </tidy-text>
            </tidy-row>
            <!--<tidy-row class="vertical-align-middle extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/credit-card.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="'Payment Method: Card on file (9285)'">
              </tidy-text>
            </tidy-row>-->
            <tidy-row class="vertical-align-middle extra-bottom-padding" *ngIf="item?.restocking_notes">
              <tidy-image
                [src]="'assets/img/delivery-man.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Notes: ' | translate) + item.restocking_notes">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-middle" *ngIf="item?.delivery_timing_preference">
              <tidy-image
                [src]="'assets/img/clock.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [body]="('Delivery Timing Preferences: ' | translate) + item?.delivery_timing_preference">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card-button>
      </tidy-card>

	    <!--<maintenances-cards [upcomingMaintenances]="upcomingMaintenances" [pastMaintenances]="pastMaintenances" [addressId]="addressId"></maintenances-cards>-->

      <ng-container *ngIf="item?.change_history?.length > 0">
        <tidy-row>
          <tidy-text
            [header]="'Change History'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let history of item?.change_history">
          <tidy-card *ngIf="history?.data?.current_quantity && hasQuantityChange">
            <tidy-row>
              <tidy-text
                [header]="'Quantity Change From'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="history?.data?.current_quantity[0]">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="'to'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="history?.data?.current_quantity[1]">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Made by'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="history?.recorded_by?.first_name">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'on'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="formatDate(history?.local_change_date_and_time?.date)">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>
        <tidy-alert *ngIf="!hasQuantityChange">
          <tidy-row>
            <tidy-text
              [body]="'No quantity changes'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>
      </ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row *ngIf="isRightSideContent" [align]="'center'">
        <tidy-text
          class="link red"
          [body]="'Delete Item'"
          (action)="deleteItem()">
        </tidy-text>
      </tidy-row>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Contact Info'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" [id]="formIds.CONTACT_INFO_FORM">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <tidy-card>
          <tidy-row *ngIf="showCompanyName">
            <tidy-input
              formControlName="companyName"
              [form]="form.controls.companyName"
              [label]="'Company Name'"
              [submitted]="submitted"
              [icon]="'assets/svg/information-circle-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="firstName"
              [form]="form.controls.firstName"
              [label]="'First Name'"
              [errorMessage]="'Please enter your first name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
              formControlName="lastName"
              [form]="form.controls.lastName"
              [label]="'Last Name'"
              [errorMessage]="'Please enter your last name.'"
              [submitted]="submitted"
              [icon]="'assets/svg/person-outline.svg'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-input
             formControlName="email"
             [label]="'Email'"
             [form]="form.controls.email"
             type="email"
             [submitted]="submitted"
             [errorMessage]="'Please enter a valid email.'"
             [icon]="'assets/svg/mail-outline.svg'"
             [inputId]="inputIds.EMAIL_FIELD"
             [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
           </tidy-input>
         </tidy-row>
         <tidy-row>
          <tidy-input
             formControlName="billEmail"
             [label]="'Email to send bills to'"
             [form]="form.controls.billEmail"
             type="email"
             [submitted]="submitted"
             [errorMessage]="'Please enter a valid email.'"
             [icon]="'assets/svg/mail-outline.svg'"
             [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
           </tidy-input>
         </tidy-row>
         <tidy-row>
           <tidy-input-international-phone
            formControlName="phone"
            [label]="'Phone'"
            [form]="form.controls.phone"
            [inputMode]="'numeric'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid phone.'"
            [icon]="'assets/svg/phone-portrait-outline.svg'"
            [inputId]="inputIds.PHONE_FIELD"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
           </tidy-input-international-phone>
         </tidy-row>
       </tidy-card>

        <tidy-button
          [text]="'Save Changes'"
          [action]="save.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-error-message [text]="errorMessage"></tidy-error-message>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Loading } from 'src/shared/components/loading/loading';
import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/providers/logger';

@Component({
  selector: 'genome-items',
  templateUrl: './genome-items.component.html',
  styleUrls: ['./genome-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GenomeItemsComponent {

  @Input() genomeItems: any[] = [];
  form: UntypedFormGroup;
  checkboxValues: String[] = [];
  errorMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController
  ) {
    this.form = this.fb.group({
      action: ['', Validators.required]
    });
  }

  setCheckboxValue(key) {
    const index = this.checkboxValues.indexOf(key.value);
    if (index === -1) {
      this.checkboxValues.push(key.value);
    } else {
      this.checkboxValues.splice(index, 1);
    }
    this.form.value.action = this.checkboxValues;
  }

  @Loading('', true)
  async submitAction(item, component, componentValue?) {
    const data = {
      action_name: component.value.input_action,
      params: {
        value: this.form.value.action
      }
    }
    if(component.key === 'grouped_action_buttons'){
      data.action_name = componentValue.action;
      data.params.value = null;
    }
    try {
      await this.concierge.submitConciergeAction(item.id, data);
      location.reload();
    }
    catch (err) {
      this.handleError(err);
    }
  }

  genomeRedirect(url: string) {
    try {
      this.navCtrl.navigateForward(url);
    }
    catch (err) {
      this.handleError(err);
    }
  }

  handleError(err) {
    this.logger.error(err);
    this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
  }
}

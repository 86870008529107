<div
  class="accordion-header"
  (click)="toggleGoalExpansion(goal)"
  [id]="'goal-' + goal.user_goal_type.title">
  <div class="accordion-header-content">
    <div class="goal-icon-container">
      <img [src]="goal.icon" class="goal-icon" />
    </div>
    <tidy-text
      [body]="'<strong>' + goal.user_goal_type.title + '</strong>' + ' (' + goal.completedSteps + '/' + goal.user_goal_steps.length + ' Completed)'">
    </tidy-text>
  </div>

  <div class="accordion-icon-container">
    <!--<button
      mat-icon-button
      fill="clear"
      class="green-button"
      (click)="$event.stopPropagation(); openVideo.emit(goal)">
      <img src="assets/svg/circle-play.svg" alt="play" class="play-icon" />
    </button>-->
    <button mat-icon-button fill="clear" class="green-button">
      <img
        src="assets/svg/chevron-right.svg"
        class="chevron-icon"
        [style.transform]="goal.isOpened ? 'rotate(-90deg)' : 'rotate(90deg)'" />
    </button>
  </div>
</div>

<div *ngIf="goal.isOpened" class="accordion-content">
  <div *ngFor="let step of sortSteps(goal.user_goal_steps)">
    <div
      class="accordion-task-container"
      [ngClass]="{'completed-task': step.state == 'done'}">
      <div class="content" (click)="goToTaskDetails.emit(step)">
        <div style="display: flex; width: 100%">
          <div style="display: flex; align-items: center">
            <tidy-checkbox
              [disabled]="true"
              (click)="$event.stopPropagation()"
              class="checkbox-container"
              [items]="[{value: true, viewValue: ''}]"
              [initValue]="step.state == 'done'">
            </tidy-checkbox>
            <tidy-text
              style="margin-left: 10px"
              [body]="'<strong>' + step.title + '</strong>'"></tidy-text>
            <div *ngIf="step?.tag" [class]="step?.tag?.class">
              <tidy-text
                [body]="'<strong>' + step?.tag?.text + '</strong>'"></tidy-text>
            </div>
          </div>
        </div>
        <div class="chevron-icon-container" *ngIf="step.state !== 'done'">
          <button mat-icon-button fill="clear">
            <img src="assets/svg/chevron-right.svg" class="chevron-icon" />
          </button>
        </div>
      </div>
      <tidy-row
        style="margin-top: -0.5rem; margin-left: 25px"
        *ngIf="step.state !== 'done'">
        <tidy-text [body]="step.description"></tidy-text>
      </tidy-row>
    </div>
  </div>
  <!--<tidy-row
    [align]="'center'"
    style="margin-top: 1rem"
    *ngIf="!isUnchosenGoal">
    <button mat-button fill="clear" class="green-button" (click)="skipGoal.emit(goal)">
      <tidy-text
        [body]="'Skip this goal for now'"></tidy-text>
    </button>
  </tidy-row>-->
  <tidy-row
    [align]="'center'"
    style="margin-top: 1rem"
    *ngIf="isUnchosenGoal">
    <button mat-button fill="clear" class="green-button" (click)="addGoal.emit(goal)">
      <tidy-text
        [body]="'Add to Your Goals'"></tidy-text>
    </button>
  </tidy-row>
</div>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Edit Parent Team'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-top-padding">
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Parent Team'"
              [items]="parentTeamItems"
              [form]="form.controls.parentTeam"
              formControlName="parentTeam">
            </tidy-select>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Save'"
          [action]="save.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

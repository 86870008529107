import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ItemNode, FlatNode, ChipFilter } from 'src/models/schedule.model';

import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { WindowService } from 'src/shared/providers/window.service';

import { Util } from 'src/shared/util/util';

import { ToDosPage } from 'src/pages/to-dos/to-dos';
import { ToDosAddListPage } from 'src/pages/to-dos/add-list/add-list';
import { ToDosListSettingsPage } from 'src/pages/to-dos/list-settings/list-settings';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { toDosMockData } from 'src/shared/constants/onboarding/to-dos';

@Component({
  templateUrl: 'all-to-do-lists.html',
  styleUrls: ['all-to-do-lists.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AllToDoListsPage implements OnInit, OnDestroy {

  addressId: any;
  addressResponse: any;
  addressItems: any;
  addressFilter: any;
  currentRows: any;
  errorMessage: string;
  form: UntypedFormGroup;
  isSearching: boolean;
  isGettingLists: boolean;
  loaded: boolean;
  panelClosedSubscription: Subscription;
  pageSize: any;
  pageIndex: any;
  headers: any;
  routeReused: boolean;
  rows: any = [];
  showLoadingSpinner: boolean;
  services: any;
  searchForm: UntypedFormGroup;
  totalRows: any;
  toDoLists: any;
  showOnboarding: boolean;
  showAddInspectionsOnboarding: boolean;
  hasProperties: boolean;
  showOnboardingSubscription$: Subscription;
  onboardingFlow: string;
  @ViewChild('tidyTable') tidyTable: any;
  didCheckOnboarding: boolean;

  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  chips: ChipFilter[] = [];
  selectedFilters: {
    propertyName: any[];
  } = {
    propertyName: []
  };
  filterNamesReferences = {
    'Property': 'propertyName'
  };

  constructor(
    private bookJob: BookJob,
    private client: Client,
    private fb: FormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public toDos: ToDos,
    private util: Util,
    public windowService: WindowService,
    public onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      property: ['', Validators.required],
    });
    this.searchForm = this.fb.group({
      search: [''],
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.updateSearch(val));
  }

  async ngOnInit() {
    try {
      this.checkIfShouldShowOnboarding();
      this.loadPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    this.pageSize = 25;
    this.pageIndex = 1;
    await this.getPropertyItems();
    this.services = await this.bookJob.fetchAllRequiredTeamServices();
    this.buildHeaders();
    this.form.patchValue({property: 'all'});
    await this.loadTable();
    if (this.toDoLists.length == 1) {
      if (this.rightSidePanelService.isClosingPanel) {
        setTimeout(() => {
          this.goToToDoList(this.toDoLists[0]);
        }, 301);
      } else {
        this.goToToDoList(this.toDoLists[0]);
      }
    }
    this.loaded = true;
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/all-to-do-lists').then(async () => {
        this.routeReused = true;
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.onboardingFlow = await this.storage.retrieve('onboardingFlow');
    if (!this.onboardingFlow) {
      this.onboardingFlow = 'toDos';
    }
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding(this.onboardingFlow);
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
      await this.storage.delete('onboardingFlow');
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        if (this.onboardingFlow === 'addInspections') {
          this.storage.save('onboardingFlow', 'toDos');
          window.location.reload();
        } else {
          this.loadPage(true);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async getPropertyItems() {
    this.addressResponse = await this.client.getAddresses();
    this.addressId = this.client.getSelectedAddressId(this.addressResponse);
    this.addressItems = this.client.parseAddressList(this.addressResponse, true);
    this.addressItems.unshift({
      value: 'all',
      viewValue: 'All Properties'
    })
    this.addressItems.pop();
    this.getTreeData();
  }

  getTreeData(): void {
    const treeData = [
      {
        name: 'Property',
        children: this.addressItems.filter(item => item.value !== 'all').map(address => ({
          name: address.viewValue,
          value: address.value,
          checked: false,
          parentId: 0
        }))
      }
    ];
    treeData[0].children.forEach((child) => {
      child.parentId = 0;
    });
    this.dataSource.data = treeData;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = node.data.value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!node?.data?.parentId && node?.data?.parentId !== 0) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip);
    }
  }

  addChip(chip: ChipFilter): void {
    if (!this.selectedFilters) {
      this.selectedFilters = {
        propertyName: []
      };
    }
    this.selectedFilters[chip.parentName].push(chip.value);
    this.chips.push(chip);
    this.loadTable();
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ]?.includes(child.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip.value);
    this.chips = this.chips.filter(
      (item) => item.value !== chip.value
    );
    this.loadTable();
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!node?.data?.parentId && node?.data?.parentId !== 0) {
      return false;
    }
    const { foundParentName, foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters?.[matchedParentName]?.includes(foundNodeValue);
  }

  async getToDoLists() {
    const property = this.selectedFilters.propertyName.length > 0 ? this.selectedFilters.propertyName : null;
    const toDoLists = this.showOnboarding ? {body: toDosMockData, totalRecords: 25} : await this.toDos.getAllToDoLists(this.pageSize, this.pageIndex, property);
    this.toDoLists = toDoLists.body;
    this.totalRows = toDoLists.totalRecords;
  }

  buildHeaders(): void {
    this.headers = [
      'List Name',
      'Property',
      'Default Settings',
      'Remote Inspection',
      'Property Specific',
      'last-column'
    ];
  }

  async loadTable(showLoadingSpinner = true) {
    let loading = null;
    if (this.loaded && !this.routeReused && showLoadingSpinner) {
      loading = await this.util.showLoading();
    }
    await this.getToDoLists();
    this.buildRows(showLoadingSpinner);
    loading?.dismiss();
  }

  buildRows(resetRows = true) {
    if (resetRows) {
      this.rows = [];
    } else {
      this.rows = [...this.rows];
    }
    this.toDoLists.map((list) => {
      this.rows.push({
        id: list.id,
        addressId: list.address_id,
        'List Name': {
          value: list.title,
          action: null,
        },
        'Property': {
          value: this.getAddressName(list.address_id),
          action: null,
        },
        'Default Settings': {
          value: this.getListDefaultSettings(list),
          action: null,
        },
        'Remote Inspection': {
          value: this.toDos.getRemoteInspectionStatus(list.before_after_photos_state),
          action: null,
        },
        'Property Specific': {
          value: list.is_customer_list ? new TranslationPipe().transform('No') : new TranslationPipe().transform('Yes'),
          action: null,
        },
        'last-column': {
          value: '',
          action: null,
        }
      });
    });
    this.currentRows = this.rows;
  }

  async changeProperty() {
    this.pageIndex = 1;
    await this.loadTable();
  }

  getAddressName(addressId) {
    const address = this.addressItems.find((address => address.value == addressId));
    return address?.viewValue;
  }

  getListDefaultSettings(list) {
    let settings = '';
    list.default_list_settings.map((setting) => {
      const key = setting.key;
      if (key.includes('account')) {
        settings += 'Account, ';
      } else if (!key.includes('address')) {
        const serviceKey = key.split('.').slice(1).join('.');
        settings += this.getServiceNameFromKey(serviceKey) + ', ';
      } else if (key.split('.').length === 5) {
        const addressId = key.split('.')[2];
        const serviceKey = key.split('.').slice(3).join('.');
        const addressName = this.getAddressName(addressId);
        if (addressName) {
          settings += addressName + ' (' + this.getServiceNameFromKey(serviceKey) + '), ';
        }
      } else {
        const addressId = key.split('.')[2];
        const addressName = this.getAddressName(addressId);
        if (addressName) {
          settings += addressName + ', ';
        }
      }
    });
    return settings.slice(0, -2).split(', ').map(s => s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(' ')).join(', ');
  }

  getServiceNameFromKey(serviceKey) {
    const service = this.services.find((service => service.key == serviceKey));
    return service?.name;
  }

  sortChanged(sort) {
    if (sort.direction == 'asc' || sort.direction == '') {
      this.currentRows.sort((a, b) =>
        a[sort.active].value > b[sort.active].value ? 1 : -1
      );
      this.currentRows = this.rows;
    } else {
      this.currentRows.sort((a, b) =>
        a[sort.active].value < b[sort.active].value ? 1 : -1
      );
      this.currentRows = this.rows;
    }
  }

  goToToDoList(list) {
    try {
      localStorage.setItem('addressId', list?.element?.addressId || list.address_id);
      localStorage.setItem('taskListId', list?.element?.id || list.id);
      this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(ToDosPage);
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didMakeToDoUpdates = await this.storage.retrieve('didMakeToDoUpdates');
        if (didMakeToDoUpdates) {
          await this.storage.save('didMakeToDoUpdates', false);
          this.loadTable();
        }
      });
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  async goToDefaultSettingsPage() {
    if (this.showOnboarding || this.showAddInspectionsOnboarding) {
      return;
    }
    try {
      const params = {
        addressItems: this.addressItems
      }
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(ToDosListSettingsPage);
      this.rightSidePanelService.setDialogPageTitle('Default To-Do Lists');
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didMakeToDoUpdates = await this.storage.retrieve('didMakeToDoUpdates'); //TODO
        if (didMakeToDoUpdates) {
          await this.storage.save('didMakeToDoUpdates', false);
          this.loadTable();
        }
      });
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  goToAddToDoList() {
    if (this.showOnboarding || this.showAddInspectionsOnboarding) {
      return;
    }
    try {
      const params = {
        toDoLists: this.toDoLists,
        addressFilter: this.addressItems.filter((address) => address.value !== 'all'),
        hasToDoLists: true
      }
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(ToDosAddListPage);
      this.rightSidePanelService.setDialogPageTitle('Add To-Do List');
      this.panelClosedSubscription?.unsubscribe();
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        const didMakeToDoUpdates = await this.storage.retrieve('didMakeToDoUpdates');
        if (didMakeToDoUpdates) {
          await this.storage.save('didMakeToDoUpdates', false);
          this.loadTable();
        }
      });
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  updateSearch({ search }) {
    if (search.length < 2 || search == '') {
      this.isSearching = false;
      this.currentRows = this.rows;
      return;
    }
    this.isSearching = true;
    const term = search.toLowerCase();
    this.currentRows = [];
    this.rows.map((list) => {
      const isTextMatch = list['List Name'].value.toLowerCase().includes(term) || list['Property'].value.toLowerCase().includes(term) || list['Default Settings'].value.toLowerCase().includes(term);
      if (isTextMatch && !this.currentRows.includes(list)) {
        this.currentRows.push(list);
      }
    });
  }

  async pageChange(page) {
    if (this.isGettingLists) {
      return;
    }
    if (this.tidyTable) {
      this.tidyTable.isLoading = true;
    }
    this.isGettingLists = true;
    this.pageIndex += 1;
    try {
      await this.loadTable(false);
    } finally {
      if (this.tidyTable) {
        this.tidyTable.isLoading = false;
      }
      this.isGettingLists = false;
    }
  }

}

import { Component, Input, ViewEncapsulation } from '@angular/core';

import { bottomMenuItems } from 'src/shared/components/bottom-nav/bottom-nav-items';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'tidy-bottom-nav',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bottom-nav.component.scss'],
  template: `
    <nav>
      <ul class="menu">
        <li
          class="menu-item"
          [attr.data-cy]="item.label"
          *ngFor="let item of menuItems"
          (click)="navigateTo(item.target)">
          <tidy-image
            [src]="isActiveItem(item) ? item.activeIcon : item.icon"
            class="title-size no-right-margin">
          </tidy-image>
          <span class="item-menu" [class.active]="isActiveItem(item)">
            {{item.label}}
          </span>
        </li>
      </ul>
    </nav>
  `
})
export class BottomNavComponent {

  @Input() activeRoute: string;
  menuItems = bottomMenuItems;

  constructor(
    private customNavController: NavController
  ) {}

  async navigateTo(url) {
    if (url == '/schedule') {
      const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      const hasMoreThanOneAddress = localStorage.getItem('hasMoreThanOneAddress') == 'true';
      const shouldShowAllToDosPage = isRentalClient || hasMoreThanOneAddress;
      localStorage.setItem('shouldShowAllToDosPage', shouldShowAllToDosPage ? 'true' : 'false');
      url = shouldShowAllToDosPage ? '/schedule' : '/schedule-list';
    }
    this.customNavController.navigateForward(url);
  }

  isActiveItem(item) {
    return this.activeRoute === item.target;
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Mark Job Complete'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-input
              formControlName="price"
              [label]="'Job price'"
              [form]="form.controls.price"
              [icon]="'assets/svg/cash-outline.svg'"
              [errorMessage]="'Please select a rate.'"
              [submitted]="submitted"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Job duration'"
              [form]="form.controls.duration"
              formControlName="duration"
              [items]="serviceDurationItems"
              [errorMessage]="'Please select a duration.'"
              [submitted]="submitted">
            </tidy-select>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Mark as Complete'"
          [action]="markComplete.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

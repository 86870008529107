import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Concierge } from 'src/providers/concierge/concierge';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Schedule } from 'src/providers/schedule/schedule';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ContactConciergePage } from '../contact-concierge/contact-concierge';
import { JobIssueDetailPage } from './job-issue-detail/job-issue-detail';

@Component({
  templateUrl: 'job-issue.html',
  encapsulation: ViewEncapsulation.None
})

export class JobIssuePage extends TimeoutableComponent implements OnInit {

  form: UntypedFormGroup;
  pastJobsResponse: any;
  showLimiter: number = 10;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Job Issue');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
        this.pastJobsResponse = await this.client.getJobHistory();
        this.pastJobsResponse = this.pastJobsResponse.filter((job) => {
          if (!job.is_private) {
            return job;
          }
      })
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async next(selectedJob?: any) {
    if (!selectedJob) {
      return this.goToContactConciergeNoJob();
    }
    const jobStatus = selectedJob.status;
    const isPrivateJob = selectedJob.is_private;
    const job = await this.schedule.getJobDetail(selectedJob.cleaning_id);
    if (job.booking.bookable_service.key.includes('turnover') || jobStatus === 'Cancelled by you') {
      this.goToContactConcierge(jobStatus, job);
    } else {
      this.goToJobIssueDetail(jobStatus, job, isPrivateJob);
    }
  }

  goToContactConciergeNoJob() {
    const params = {
      title: 'Job Issue',
      type: 'support.job_issue'
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

  goToContactConcierge(jobStatus, job) {
    const params = {
      title: 'Job Issue',
      type: 'support.job_issue',
      metadata: {
        suptype: jobStatus === 'Cancelled by you' ? 'client_cancelled' : '2_pro_job',
        job,
        job_id: job.job.id,
        job_datetime: job.job.start_datetime
      }
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

  goToJobIssueDetail(jobStatus, job, isPrivate) {
    const params = {
      job,
      jobStatus,
      isPrivate
    };
    this.rightSidePanelService.navigateTo('job-issue-detail', params, JobIssueDetailPage);
  }

  showLimit() {
    this.showLimiter = this.showLimiter === 10 ? this.pastJobsResponse.length : 10;
  }
}

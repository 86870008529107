<ng-template #content>
  <div
    class="desktop-content-padding"
    [class.mobile-content-padding]="isMobileResolution">
    <tidy-row [align]="'center'">
      <tidy-text [title]="'Unable to Message Yet'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'" class="title-row-padding">
      <tidy-text
        [body]="'Your pro is not online to see this yet. They are typically available 30 minutes before the job.'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <form [formGroup]="form" novalidate action="#">
        <tidy-textarea
          class="message-textarea"
          [label]="'Send Message'"
          formControlName="message"
          [form]="form.controls.message"
          [errorMessage]="'Please enter a message to send.'"
          [leftIconAction]="sendAttachment.bind(this)">
        </tidy-textarea>
      </form>

      <div
        class="image-preview"
        [class.mobile-image-preview]="isMobileResolution"
        *ngIf="imagePreview !== ''">
        <img [src]="imagePreview" />
      </div>
    </tidy-row>
    <tidy-row [align]="'center'" class="first-option-row">
      <tidy-text [header]="'What Would You Like to Do?'"> </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'" class="first-option-content">
      <tidy-text
        [body]="'Add this as a note for them to see when reviewing the job'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-button
        [text]="'Add Job Note'"
        class="secondary add-access-note-button"
        [action]="addJobNote.bind(this)">
      </tidy-button>
    </tidy-row>
    <tidy-row [align]="'center'" class="first-option-content">
      <tidy-text
        [body]="'Add this for them to see when accessing the property'">
      </tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'">
      <tidy-button
        [text]="'Add Access Note'"
        class="secondary add-access-note-button"
        [action]="addAccessNote.bind(this)">
      </tidy-button>
    </tidy-row>

    <tidy-row [align]="'center'" class="extra-top-padding">
      <tidy-text
        [body]="'Or take common actions like'"
        class="common-actions-text">
      </tidy-text>
      <tidy-text
        *ngIf="!isMobileResolution"
        [body]="'Cancel or Reschedule'"
        class="link"
        (action)="goToCancelOrReschedule()"></tidy-text>
    </tidy-row>
    <tidy-row [align]="'center'" *ngIf="isMobileResolution">
      <tidy-text
        [body]="'Cancel or Reschedule'"
        class="link"
        (action)="goToCancelOrReschedule()"></tidy-text>
    </tidy-row>
  </div>
</ng-template>

<ion-content *ngIf="isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-container *ngIf="!isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

import { Component, Input, TemplateRef, ViewEncapsulation, HostBinding, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-scroll-wrapper',
  template: `
    <ion-content>
      <ng-container *ngIf="isfullWidthContent">
        <ng-container *ngTemplateOutlet="templateContent"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isfullWidthContent">
        <div class="content-container">
          <div class="container-item left-column">
            <ng-container *ngTemplateOutlet="templateLeftColumn">
            </ng-container>
          </div>
          <div class="container-item tidy-content" [ngClass]="desktopWidthContent">
            <ng-container *ngTemplateOutlet="templateContent">
            </ng-container>
          </div>
          <div class="container-item right-column">
            <div [ngClass]="{'wider-right-column': widerRightColumn, 'scroll-wrapper-grey-content': !showCardDesign, 'scroll-wrapper-card-content': showCardDesign}">
              <ng-container *ngTemplateOutlet="templateRightColumn">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ion-content>
  `,
  styleUrls: ['./scroll-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollWrapperComponent {

  @Input() desktopWidthContent: string = scrollContentWidth.PORTRAIT;
  @Input() templateLeftColumn: TemplateRef<any>;
  @Input() templateContent: TemplateRef<any>;
  @Input() templateRightColumn: TemplateRef<any>;
  @Input() showCardDesign: boolean;
  @Input() widerRightColumn: boolean;
  @Input() isfullWidthContent = false
  @HostBinding('class.ion-page') get addClass() { return true };
  @ViewChild(IonContent, {read: IonContent}) ionContent: IonContent;

  constructor() { }
}

export enum scrollContentWidth {
  LARGE = 'content-column-large max-width-large',
  NORMAL = 'content-column-normal max-width-normal',
  PORTRAIT = 'content-column max-width'
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { AddressModel } from 'src/models/address.model';
import { Client } from 'src/providers/client/client';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { Util } from 'src/shared/util/util';

@Component({
  selector: 'popular-list-modal-onboarding',
  templateUrl: './popular-list.component.html',
  styleUrls: ['./popular-list.component.scss'],
})
export class PopularListModalOnboardingComponent implements OnInit {
  forms: FormArray;
  roomOptions: TidySelectNumberValueModel[];
  floorOptions: TidySelectNumberValueModel[];
  addresses: AddressModel[];

  constructor(
    private onboardingProvider: OnboardingProvider,
    private client: Client,
    private formBuilder: FormBuilder,
    private storage: TidyStorage,
    private toDos: ToDos,
    private util: Util
  ) {}

  async ngOnInit() {
    this.forms = new FormArray([]);
    this.roomOptions = this.buildOptions(10);
    this.floorOptions = this.buildOptions(5);
    this.addresses = await this.client.getAddresses();
    this.initializeForms();
  }

  initializeForms() {
    this.addresses.forEach((address) => {
      this.forms.push(
        this.formBuilder.group({
          addressId: [address.id],
          addressName: [address.address_name],
          beds: [2],
          baths: [2],
          floors: [2],
        })
      );
    });
  }

  buildOptions(maxNumber: number): TidySelectNumberValueModel[] {
    return Array.from({ length: maxNumber }, (_, i) => ({
      viewValue: `${i + 1}`,
      value: i + 1,
    }));
  }

  async save() {
    this.storage.delete('addresses');
    try {
      await Promise.all(
        this.forms.controls.map(async (form) => {
          const formValue = form.value;
          const roomData = {
            address_id: form.value.addressId,
            address_instructions: {
              beds: formValue.beds,
              baths: formValue.baths,
              floors_amount: formValue.floors,
            },
          };
          await this.client.saveHomeData(roomData);
          await this.toDos.updateDoNotUseToDosForAddress(
            form.value.addressId,
            'active'
          );
        })
      );
      this.onboardingProvider.markGoalAsCompleted('toDos');
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Popular list saved succesfully');
    } catch (error) {
      console.error(error);
    }
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Published Workflow'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="!publishedVersionIsLatestVersion">
        <tidy-text
          [body]="'You have a newer unpublished version.'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'View'"
          class="link"
          (action)="goToEditWorkflow()">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-image
              [src]="'assets/img/workflow-diagram.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="workflowForm.get('name').value"
              class="workflow-name">
            </tidy-text>
            <div></div>
          </tidy-row>
          <tidy-toggle
            [toggled]="!workflow.paused_at"
            (toggleChanged)="pauseUnpauseWorkflow(workflow)">
          </tidy-toggle>
        </tidy-row>
        <tidy-card-button (click)="goToEditWorkflow()">
          <tidy-row class="vertical-align-center">
            <tidy-text
              [body]="'Edit Workflow'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (click)="deleteWorkflow()">
          <tidy-row class="vertical-align-center">
            <tidy-text
              [body]="'Delete Workflow'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/play.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Trigger'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="selectedTrigger.icon_url"
            class="title-size">
          </tidy-image>
          <tidy-text
            [body]="selectedTrigger.name">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="selectedTrigger.description">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="selectedTrigger?.inputs?.length">
          <ng-container *ngFor="let input of selectedTrigger?.inputs">
            <tidy-row class="extra-top-padding">
              <tidy-text
                [body]="'<b>' + getFilterNameInSingular(input.form.label) + ' Filter:</b>'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="input?.displayValue">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </ng-container>
      </tidy-card>

      <ng-container *ngIf="selectedTrigger">
        <ng-container *ngFor="let action of selectedActions; let actionIndex = index">
          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/img/check.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Action'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="(actionIndex + 1)">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="action.icon_url"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="action.name">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="action.description">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let input of action?.inputs">
              <tidy-row class="extra-top-padding">
                <tidy-text [body]="'<b>' + input.form.label + '</b>'">
                </tidy-text
                ><br />
                <ng-container *ngIf="input.form.type === 'filter'">
                  <ng-container
                    *ngFor="let filter of action?.filterInputs; let filterIndex = index">
                    <ion-row style="padding: 1rem 0px; padding-bottom: 0.5rem;">
                      <ion-col size="12" style="padding: 0px;">
                        <ng-container *ngIf="filterIndex === 0">
                          <div style="padding-top: 1rem;">
                            <tidy-text [body]="'<b>Only continue if</b>'"> </tidy-text>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="filterIndex > 0">
                          <tidy-text [body]="'<b>Or continue if</b>'"> </tidy-text>
                        </ng-container>
                        <ng-container
                          *ngFor="let filterItem of filter; let filterItemIndex = index">
                          <ion-row>
                            <ion-col size="12" style="padding-right: 0px; padding-left: 0px;">
                              <ng-container *ngIf="filterItemIndex > 0">
                                <tidy-text [body]="'<b>And</b>'"> </tidy-text>
                              </ng-container>
                            </ion-col>
                          </ion-row>
                          <ion-row>
                            <ion-col size="12" style="padding-left: 0px; padding-right: 0px;">
                              <tidy-text
                                *ngIf="filterItem?.left_value"
                                [body]="filterItem?.left_value + ' '">
                              </tidy-text>
                              <tidy-text
                                *ngIf="filterItem?.operator"
                                [body]="filterItem?.operator + ' '">
                              </tidy-text>
                              <tidy-text
                                *ngIf="filterItem?.right_value"
                                [body]="filterItem?.right_value + ' '">
                              </tidy-text>
                              <tidy-text
                                *ngIf="filterItem?.timezone"
                                [body]="filterItem?.timezone">
                              </tidy-text>
                            </ion-col>
                          </ion-row>
                        </ng-container>
                      </ion-col>
                    </ion-row>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="input.form.type !== 'filter'">
                  <ng-container *ngIf="input.form.type !== 'files'; else filesTemplate">
                    <tidy-chip-text-area
                      [id]="getActionForm(actionIndex).get('formId').value"
                      [inputKey]="input.key"
                      [readonly]="true">
                    </tidy-chip-text-area>
                  </ng-container>

                  <ng-template #filesTemplate>
                    <ion-row *ngIf="action?.input_values?.attachments?.length > 0">
                      <ion-col size="12" sizeMd="6" sizeLg="4" sizeXl="3" *ngFor="let attachment of action?.input_values?.attachments; let i = index">
                        <div
                          style="width: 100%"
                          class="image-preview">
                          <img [src]="attachment" />
                        </div>
                      </ion-col>
                    </ion-row>
                  </ng-template>
                </ng-container>
              </tidy-row>
            </ng-container>
          </tidy-card>
        </ng-container>
      </ng-container>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

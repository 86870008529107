<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Custom Field Requirements'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [header]="list?.title">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-image
              [src]="'assets/img/checklist.svg'"
              class='header-size'>
            </tidy-image>
            <tidy-text
              header="Require Custom Fields">
            </tidy-text>
            <div></div>
          </tidy-row>
          <tidy-toggle
            [toggled]="blockCompleteOnMissingFields"
            (toggleChanged)="blockCompleteOnMissingFields = !blockCompleteOnMissingFields">
          </tidy-toggle>
        </tidy-row>
        <tidy-row>
          <tidy-text
            body="Require the pro to enter inputs into all your custom fields to complete the job. If disabled the pro will not be required to enter any inputs but will see an alert if they are skipping any fields you indicated are required.">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [text]="'Save Settings'"
        [action]="confirmChange.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-container *ngIf="loaded">
  <form [formGroup]="form">

    <tidy-row [align]="'center'" *ngIf="!cameFromOnboarding">
      <tidy-text
        [title]="!address?.address_name ? (address?.address1 + (address?.address2 == null ? '' : address?.address2)) : address?.address_name"
        [translate]="false">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="cameFromAddIntegration">
      <tidy-text
        [body]="integrationMessage">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row [alignLastItemRight]="true">
        <tidy-row [alignLastItemRight]="true" class="full-width">
          <tidy-image
            [src]="automaticBooking ? 'assets/img/automatic-booking.svg' : 'assets/img/automatic-booking-off.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Automatic Booking'">
          </tidy-text>
          <div></div>
        </tidy-row>
        <tidy-toggle
          [toggled]="automaticBooking"
          (toggleChanged)="automaticBooking = !automaticBooking">
        </tidy-toggle>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'When a reservation is synced, automatically add a cleaning for your pros. Cancel anytime. '">
        </tidy-text>
        <tidy-text
          [actionBody]="'Learn More'"
          (action)="learnMoreAutomaticBooking()"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <ng-container *ngIf="automaticBooking">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Set up Automatic Booking:'">
        </tidy-text>
      </tidy-row>

      <form [formGroup]="form">
        <tidy-card *ngIf="!cameFromOnboarding">
          <tidy-card-button (action)="goToIntegrations()" style="border-bottom:0px" class="arrow-to-top">
            <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/integration.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Integration(s)'">
              </tidy-text>
              <tidy-text
                style="margin-right:5px"
                [body]="address?.address_automatic_booking_setting ? '1 Added' : '0 Added'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Add at least 1 integration for this property.'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/suitcase.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Job(s) to Book'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let controlName of jobControls; let i = index">
            <tidy-row [align]="'center'" [ngClass]="{'field-top-padding': true, 'extra-bottom-padding': i === jobControls.length - 1 && jobControls.length > 1}">
              <tidy-select
                [icon]="'assets/svg/information-circle-outline.svg'"
                [label]="windowService.isDesktopRes ? 'Default job name*' : 'Default job name'"
                [formControlName]="controlName"
                [items]="jobItems"
                [form]="form.controls[controlName]">
              </tidy-select>
              <ng-container *ngIf="i > 0">
                <tidy-text
                  [actionBody]="'Remove Job'"
                  (action)="removeJob(controlName)"
                  class="red link">
                </tidy-text>
              </ng-container>
            </tidy-row>
          </ng-container>
          <tidy-row [align]="'center'">
            <tidy-text
              [actionBody]="'Add Another Job'"
              (action)="addAnotherJob()"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-top-padding" *ngIf="windowService.isDesktopRes">
            <tidy-text
              [body]="'*You can customize the job name per pro/property '">
            </tidy-text>
            <tidy-text
              [actionBody]="'here'"
              class="link"
              (action)="goToMyProSettings()">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/clock-black.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Job(s) Time Range'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Earliest start time (typically the check out time)'"
              [form]="form.controls.startTime"
              formControlName="startTime"
              (optionChange)="updateTimeOptions()"
              [items]="times.start">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/time-outline.svg'"
              [label]="'Latest finish time (typically the check in time)'"
              [form]="form.controls.endTime"
              formControlName="endTime"
              (optionChange)="updateTimeOptions()"
              [items]="times.end">
            </tidy-select>
          </tidy-row>
          <tidy-row class="extra-bottom-padding" *ngIf="timeRangeErrorMessage">
            <tidy-error-message
              [text]="timeRangeErrorMessage">
            </tidy-error-message>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'<b>Example:</b>'">
            </tidy-text>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [body]="'Next synced reservation:'">
            </tidy-text>
            <tidy-text
              [body]="'Mar 1 - Mar 3'">
            </tidy-text>
          </tidy-row>
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [body]="'Reservation after that:'">
            </tidy-text>
            <tidy-text
              [body]="'Mar 8 - Mar 10'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-top-padding">
            <tidy-text
              [body]="'These settings would add a'">
            </tidy-text><span><span>&nbsp;</span></span>
            <tidy-text
              [body]="jobsList">
            </tidy-text><span><span>&nbsp;</span></span>
            <tidy-text
              [body]="'to start no earlier than March 3,'">
            </tidy-text><span><span>&nbsp;</span></span>
            <tidy-text
              [body]="guestReservations.displayDate(form?.value?.startTime)">
            </tidy-text><span><span>&nbsp;</span></span>
            <tidy-text
              [body]="'& be completed before March 8,'">
            </tidy-text><span><span>&nbsp;</span></span>
            <tidy-text
              [body]="guestReservations.displayDate(form?.value?.endTime)">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/search-calendar-enabled.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Job(s) Date Settings'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Create job requests no earlier than'"
              [form]="form.controls.numberDaysPriorToCheckIn"
              formControlName="numberDaysPriorToCheckIn"
              [items]="numberDaysPriorToCheckInItems">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Schedule jobs no later than'"
              [form]="form.controls.maxDays"
              formControlName="maxDays"
              [items]="maxDaysItems">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="form?.value?.maxDays == 0">
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'<b>Warning:</b> This setting makes it more difficult to fill jobs.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'This means pros are only allowed to accept a job same day as checkout. If they can\'t do it then, your job request will go to other pros following your priorities. This is recommended only if you commonly get last minute bookings within 1 day of a prior booking, and are ok with not getting the pro you prefer. '">
              </tidy-text>
              <tidy-text
                (action)="goToLearnMoreMaxDays()"
                class="link"
                [actionBody]="'Learn More'">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="form?.value?.maxDays == 1 || form?.value?.maxDays == 2 || form?.value?.maxDays == 3">
            <tidy-text
              [body]="'Pros are notified that a job same day as checkout is preferred, but are allowed to accept jobs up to'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="form?.value?.maxDays">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'days later or before check in, whatever is first. For most people, we recommend allowing up to 3 days before checkout, as it helps you get your favorite pros while still getting jobs done before check in.'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              (action)="goToLearnMoreMaxDays()"
              class="link"
              [actionBody]="'Learn More'">
            </tidy-text>
            </tidy-row>
        </tidy-card>

        <tidy-card *ngIf="!cameFromOnboarding">
          <tidy-card-button (action)="goToMyPros()" style="border-bottom:0px" class="arrow-to-top">
            <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/pros.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Your Pros'">
              </tidy-text>
              <div style="margin-right:5px">
                <tidy-text
                  [body]="proCount">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'Approved'">
                </tidy-text>
              </div>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Job requests will be sent to pros in the order listed in the My Pros section.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Pros you add will invoice you after each job at your agreed rates (no additional cost). You can opt to autopay each pro or not.'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>

        <!--<tidy-card>
          <tidy-row [alignLastItemRight]="true">
            <tidy-row [alignLastItemRight]="true" class="full-width">
              <tidy-image
                [src]="'assets/img/search.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Find New Pros'">
              </tidy-text>
              <div></div>
            </tidy-row>
            <tidy-toggle
              [toggled]="findNewPro"
              (toggleChanged)="updateFindNewPro()">
            </tidy-toggle>
          </tidy-row>
          <tidy-row [ngClass]="findNewPro ? 'extra-bottom-padding' : ''">
            <tidy-text
              [body]="'New pros cost $' + serviceCost + ' for a ' + cleaningViewValue + '.'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="findNewPro">
            <tidy-radio-button
              [label]="'How do you want to pay new pros?'"
              formControlName="payNewPros"
              [form]="form.controls.payNewPros"
              [items]="payNewProsOptions"
              class="no-margin-first">
            </tidy-radio-button>
          </tidy-row>
        </tidy-card>-->
      </form>
    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Save Settings'"
      [action]="save.bind(this)"
      class="primary">
    </tidy-button>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Note: You can disable automatic booking for individual reservations & cancel jobs anytime.'">
      </tidy-text>
    </tidy-row>

  </form>
</ng-container>
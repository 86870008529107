<ng-container *ngIf="loaded">
  <tidy-row>
    <tidy-text
      [header]="'Request Workflow(s)'">
    </tidy-text>
  </tidy-row>

  <form [formGroup]="form" novalidate action="#">
    <tidy-row class="extra-bottom-padding">
      <tidy-textarea
        label="Describe any workflows you'd like to automate"
        [icon]="'assets/svg/information-circle-outline.svg'"
        [form]="form.get('workflowRequest')"
        formControlName="workflowRequest">
      </tidy-textarea>
    </tidy-row>
  </form>

  <tidy-row class="extra-bottom-padding">
    <tidy-text
      [header]="'Common Templates'">
    </tidy-text>
  </tidy-row>

  <ng-container *ngFor="let template of workflowTemplates">
    <tidy-card class="card-checkbox" [ngClass]="{'selected-card-checkbox': template.isSelected}" (click)="selectTemplate(template)">
      <div style="display: flex; align-items: center;">
        <tidy-text
          [header]="template?.name">
        </tidy-text>
      </div>
      <tidy-row>
        <tidy-text
          [body]="template?.description">
        </tidy-text>
      </tidy-row>
    </tidy-card>
  </ng-container>
  <tidy-button
    [text]="'Save'"
    [action]="saveWorkflows.bind(this)"
    class="primary">
  </tidy-button>
</ng-container>

<ng-container *ngIf="!loaded">
  <ion-skeleton-text animated="true" style="width: 100%; height: 550px"></ion-skeleton-text>
</ng-container>
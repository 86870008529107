import { Component, OnInit } from '@angular/core';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'automatic-booking-address.html'
})
export class AutomaticBookingAddressPage implements OnInit {

  loaded: boolean;
  isRightSideContent = false;
  dialogParams: any;

  constructor(
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.rightSidePanelService.setDialogLoading(true);
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = true;
  }

}
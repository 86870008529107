<ion-item>
  <ion-label position="stacked">What best describes you? </ion-label>

</ion-item>

<div class="buttons-container">

  <span class="type-account-type tidy-button" [ngClass]="{'selected' : item.accType == accType}" *ngFor="let item of accTypeArray" tappable (click)="updateAccType(item.accType)">
    <input type="radio" name="item.accType" [attr.id]="item.accType"/>
    <label [attr.for]="item.accType">
      {{item.label}}
    </label>
  </span>

</div>

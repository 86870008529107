<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header>
      <tidy-text
        [title]="'Settings'">
      </tidy-text>
    </tidy-desktop-header>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ion-skeleton-text *ngIf="!loaded" animated="true" style="width: calc(100% + 60px); height: 100vh; margin-left: -20px"></ion-skeleton-text>

    <ng-container *ngIf="loaded">

      <tidy-card *ngIf="integrations?.length">
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/gold-link.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Integration'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let integration of integrations">
          <tidy-text
            [body]="integration?.integration_data?.name + ' '">
          </tidy-text>
          <tidy-text
            class="link"
            [actionBody]="'Edit'"
            (action)="showMergeWidget()">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="!integrations?.length">
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/img/gold-link.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Integration'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [helper]="'No Integration Added '">
          </tidy-text>
          <tidy-text
            class="link"
            [actionBody]="'Add Integration'"
            (action)="showMergeWidget()">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Connect your HR system and we will start syncing employees from this list. Talk to your success representative for help setting up campaigns. You will only be charged in accordance with your campaign settings.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Please contact your account manager to change your account settings.'">
        </tidy-text>
      </tidy-row>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>

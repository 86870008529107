import { Util } from 'src/shared/util/util';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Genome } from 'src/providers/genome/genome';
import { Client } from 'src/providers/client/client';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

interface RestockItem {
  item: number;
  current: number;
  min: number;
  max: number;
  addToChecklist: boolean;
}

@Component({
  selector: 'tidy-restocking-onboarding',
  templateUrl: './restocking-onboarding.component.html',
  styleUrls: ['./restocking-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RestockingOnboardingComponent implements OnInit {
  signUpForm: UntypedFormGroup;
  items: RestockItem[] = [
    { item: 212, current: 2, min: 2, max: 5, addToChecklist: true },
    { item: 211, current: 2, min: 2, max: 5, addToChecklist: true },
    { item: 210, current: 2, min: 2, max: 5, addToChecklist: true },
    { item: 209, current: 2, min: 2, max: 5, addToChecklist: true },
    { item: 208, current: 2, min: 2, max: 5, addToChecklist: true },
  ];
  categories: any[] = [];
  categoriesItems: any[] = [];
  addresses: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private genome: Genome,
    private client: Client,
    private util: Util,
    private onboardingProvider: OnboardingProvider
  ) {}

  async ngOnInit() {
    this.initForm();
    await this.getCategories();
    this.items.forEach((item, index) => {
      item.item = this.categoriesItems[index].value;
    });
    this.addresses = await this.client.getAddresses();
  }

  private initForm(): void {
    this.signUpForm = this.formBuilder.group({
      whereToOrder: [''],
      whereToShip: [''],
    });
  }

  async getCategories() {
    this.categories = await this.genome.getCategories();
    this.categoriesItems = this.categories.map((category) => ({
      viewValue: category.name,
      value: category.id,
      icon: category?.default_icon_url,
    }));
  }

  addItem(): void {
    this.items.push({
      item: this.categoriesItems[0].value,
      current: 1,
      min: 1,
      max: 1,
      addToChecklist: true,
    });
  }

  removeItem(index: number): void {
    this.items.splice(index, 1);
  }

  updateItem(index: number, field: keyof RestockItem, value: any): void {
    this.items[index] = {
      ...this.items[index],
      [field]: value,
    };
  }

  async saveItems(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      const payload = {
        address_id: this.addresses[0].id,
        room_objects: this.items.map((item) => ({
          category_id: item.item,
          where_to_order: this.signUpForm.value.whereToOrder,
          restocking_notes: this.signUpForm.value.whereToShip,
          current_quantity: item.current,
          min_quantity: item.min,
          max_quantity: item.max,
          add_to_cleaning_checklist: item.addToChecklist,
        })),
      };
      await Promise.all([
        this.genome.createManyRoomObjects(payload),
        this.onboardingProvider.markGoalAsCompleted('inventoryAutomation'),
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Items saved succesfully');
    } catch (error) {
      console.error(error);
      const errorMessage = error?.err?.message || 'Error saving items';
      this.util.showError(errorMessage);
    } finally {
      loading.dismiss();
    }
  }
}

<ng-template #content>
  <tidy-wrapper>
    <tidy-card>
      <tidy-row [align]="'center'" class="extra-bottom-padding" *ngIf="!isRightSideContent">
        <tidy-text
          [header]="'Pro Added'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-bottom-padding">
        <tidy-text
          [body]="'Would you like to send a job request to'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="pro?.first_name + '?'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'They can use the app to accept.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="'Send Job Request'"
          [action]="sendJobRequest.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="'Go to My Pros'"
          [action]="goToMyPros.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>
    </tidy-card>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
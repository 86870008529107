<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Loved It'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form" novalidate action="#">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'We\'re glad you loved it!'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Leave'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="feedbackData.homekeeper.firstName">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [header]="'a Review'">
          </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding">
            <tidy-textarea
              [label]="'Review'"
              [icon]="'assets/svg/chatbubble-ellipses-outline.svg'"
              formControlName="feedback"
              [form]="form.controls.feedback"
              [submitted]="submitted">
            </tidy-textarea>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="'Leave'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="feedbackData.homekeeper.firstName">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="'a Tip'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding" *ngIf="cleaningData?.plan_frequency === 'once'">
            <tidy-text
              [body]="'Tips are common for one time cleanings, but not for recurring plans.'">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-bottom-padding" *ngIf="cleaningData?.plan_frequency !== 'once'">
            <tidy-text
              [body]="'Pros make more than average and are aware no tip is required.'">
            </tidy-text>
          </tidy-row>

          <tidy-row [ngClass]="customTip ? 'field-bottom-padding' : ''">
            <tidy-radio-button
              formControlName="tip"
              [form]="form.controls.tip"
              [items]="tipOptions"
              [submitted]="submitted"
              (optionChange)="customTipping($event)"
              [errorMessage]="'Please select an option.'">
            </tidy-radio-button>
          </tidy-row>

          <tidy-row *ngIf="customTip">
            <tidy-input
              [label]="'Enter custom tip'"
              [icon]="'assets/svg/cash-outline.svg'"
              formControlName="tip"
              [form]="form.controls.tip"
              inputMode="numeric"
              [mask]="'currencyMask'">
            </tidy-input>
          </tidy-row>
        </tidy-card>

        <tidy-button
          [text]="'Submit Review'"
          [action]="submitFeedback.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

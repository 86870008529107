<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent && !isEmployerPortal"
    [class]="extraClass"
    [title]="'Users'"
    [canGoBack]="!windowService.isDesktopRes"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="isEmployerPortal">
      <tidy-text
        [title]="'Admins'">
      </tidy-text>
    </tidy-desktop-header>

    <ion-skeleton-text *ngIf="!loaded && isEmployerPortal" animated="true" style="width: calc(100% + 60px); height: 100vh; margin-left: -20px"></ion-skeleton-text>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="isEmployerPortal ? '' : 'center'">
        <tidy-text
          *ngIf="isEmployerPortal"
          [body]="'Admins can log into your account with their own credentials. As the account owner, you can revoke access at any time.'">
        </tidy-text>
        <tidy-text
          *ngIf="!isEmployerPortal"
          [body]="'Users can log into your account with their own credentials and see the properties you assign to them. As the account owner, you can revoke access at any time.'">
        </tidy-text>
      </tidy-row>

      <tidy-button
        [action]="goToAddNewUserPage.bind(this)"
        [text]="isEmployerPortal ? 'Add Admin' : 'Add User'"
        class="primary">
      </tidy-button>

      <div class="desktop-nav-search full-width">
        <form [formGroup]="form" novalidate action="#">
          <tidy-input
            #searchInput
            class="search-input"
            [icon]="'assets/svg/search-outline.svg'"
            [activeIcon]="'assets/svg/search-tidy-green.svg'"
            [noBorder]="true"
            type="search"
            formControlName="search"
            [label]="'Search'"
            [form]="form">
          </tidy-input>
        </form>
      </div>

      <tidy-card *ngFor="let user of usersList">
	      <tidy-card-button (action)="goToUserPage(user)">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/account.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="user.first_name + ' ' + user.last_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="clientInfo?.customer_account?.account_type !== 'regular'">
            <tidy-image
              [src]="'assets/img/information-circle-grey.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="getUserType(user?.role)">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="user?.role !== 'owner' && user?.role !== 'admin'">
            <tidy-row *ngIf="getUserAddresses(user)?.length" class="vertical-align-middle">
              <tidy-image
                [src]="'assets/img/location.svg'"
                class="body-size">
              </tidy-image>
              <ng-container *ngFor="let address of getUserAddresses(user); let i = index;">
                <tidy-text
                  *ngIf="i == 2 || i == 1"
                  [body]="', '">
                </tidy-text>
                <tidy-text
                  *ngIf="i == 0 || i == 1 || i == 2"
                  [body]="address">
                </tidy-text>
                <tidy-text
                  *ngIf="i == 3"
                  [body]="' +' + (getUserAddresses(user)?.length - 3) + ' More'">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-row *ngIf="!user?.team_addresses_permissions?.length" class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/location.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'No Properties Assigned'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isEmployerPortal">
  <ng-container *ngIf="!isRightSideContent">
    <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
  </ng-container>

  <ng-container *ngIf="isRightSideContent">
    <ion-content class="ion-content-right-column">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ion-content>
  </ng-container>
</ng-container>

<app-scroll-wrapper *ngIf="isEmployerPortal" [isfullWidthContent]="true" [templateContent]="content" [desktopWidthContent]="desktopWidthContent"></app-scroll-wrapper>


<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="'concierge'"
    [title]="loaded ? dispute.header_text : ''">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-alert>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="concierge.getDisputeIcon(dispute?.type)"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="dispute?.header_text">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let comment of dispute?.components[0]?.value; let last = last">
          <tidy-row [ngClass]="!last ? 'extra-bottom-padding' : ''">
            <tidy-row>
              <tidy-text
                [body]="comment?.html">
              </tidy-text>
            </tidy-row>
          </tidy-row>
        </ng-container>
      </tidy-alert>

      <ng-container *ngIf="dispute?.type === 'dispute.customer_dispute_counter_answer'">
        <tidy-two-column-row>
          <tidy-button
            [text]="'Reject Proposal'"
            [action]="replyCounter.bind(this, 'reject_dispute_counter_proposal')"
            class="secondary">
          </tidy-button>

          <tidy-button
            [text]="'Accept Proposal'"
            [action]="replyCounter.bind(this, 'accept_dispute_counter_proposal')"
            class="primary">
          </tidy-button>
        </tidy-two-column-row>
      </ng-container>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Log Details'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="request?.method + ' ' + request?.path">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [helper]="'Status: '">
            </tidy-text>
            <tidy-text
              [body]="response?.status_code">
            </tidy-text>
            <tidy-row>
              <tidy-text
                [helper]="'ID: '">
              </tidy-text>
              <tidy-text
                [body]="id">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="'Time: '">
              </tidy-text>
              <tidy-text
                [body]="timestamp | customdate:'dddd M/D/YY h:mm a'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="'Origin: '">
              </tidy-text>
              <tidy-text
                [body]="'https://' + request?.headers?.Host">
              </tidy-text>
            </tidy-row>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Response body'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-json-formatter [dataJson]="responseBodyJSON"></tidy-json-formatter>
          </tidy-row>
        </tidy-card>

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Request ' + request?.method + ' body'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-json-formatter [dataJson]="requestBodyJSON"></tidy-json-formatter>
          </tidy-row>
        </tidy-card>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="!loaded ? '' : ('Messages With ' + proxyUser?.first_name + ' ' + proxyUser?.last_name)"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <form [formGroup]="messageForm">
          <ion-row>
            <tidy-select
              class="select-custom-input"
              [label]="assignLabel"
              [items]="assignList"
              (optionChange)="onSelectAssign($event)"
              [form]="messageForm.get('assignedTo')"
              formControlName="assignedTo">
            </tidy-select>
          </ion-row>

          <tidy-row class="extra-bottom-padding extra-top-padding" [alignLastItemRight]="true">
            <tidy-image [src]="'assets/img/account.svg'" class="title-size">
            </tidy-image>
            <tidy-text
              [header]="proxyUser?.first_name + ' ' + proxyUser?.last_name"
              [translate]="false">
            </tidy-text>
            <tidy-text [body]="'Call'" class="link" (action)="callPro()">
            </tidy-text>
          </tidy-row>
          <tidy-row class="field-top-padding extra-bottom-padding">
            <tidy-textarea
              [label]="'Send Message'"
              formControlName="message"
              [form]="messageForm.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)">
            </tidy-textarea>
          </tidy-row>
          <tidy-row *ngIf="messages?.length == 0" [align]="'center'">
            <tidy-text [helper]="'No Messages'"> </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let message of messages; let last = last">
            <message [message]="message" [last]="last"> </message>
          </ng-container>
        </form>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(hours: number): string {
    const duration = hours / 60
    const hoursLabel = duration === 1 ? 'hour' : 'hours';

    return `${duration} ${hoursLabel}`
  }
}

import { Routes } from '@angular/router';
import { ActivateMenuGuard } from 'src/providers/guard/activate-menu-guard.guard';
import { AuthGuard } from 'src/providers/guard/auth-guard.guard';
import { DisableMenuGuard } from 'src/providers/guard/disable-menu-guard.guard';
import { AlreadyAuthenticatedGuard } from 'src/providers/guard/already-authenticated-guard.guard';
import { BookingRedirectGuard } from 'src/providers/guard/booking-redirect.guard';
import { IntegrationAddedRedirectGuard } from 'src/providers/guard/integration-added-redirect.guard';
import { AccountTypeGuard } from 'src/providers/guard/account-type.guard';
import { OnboardingFlow } from 'src/shared/enums/onboarding-flow';

import { AutomaticBookingPage } from 'src/pages/more/automatic-booking/automatic-booking';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { AddFirstAddressPage } from "src/pages/booking/add-first-address/add-first-address.page";
import { AssignProPage } from 'src/pages/assign-pro/assign-pro';
import { BackupTimesPage } from 'src/pages/more/backup-times/backup-times';
import { BookingNotePage } from 'src/pages/booking-note/booking-note';
import { RemoteInspectionPage } from 'src/pages/to-dos/remote-inspection/remote-inspection';
import { SchedulePage } from 'src/pages/schedule/schedule';
import { ScheduleListPage } from 'src/pages/schedule/schedule-list/schedule-list';
import { EditPlanPage } from 'src/pages/schedule/edit-plan/edit-plan';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { BookOrRequestPage } from 'src/pages/book-or-request/book-or-request';
import { JobBackupTimesPage } from 'src/pages/job-backup-times/job-backup-times';
import { LoveItPage } from 'src/pages/feedback/love-it/love-it';
import { NeedsWorkWhatHappenedPage } from 'src/pages/feedback/needs-work/what-happened/what-happened';
import { NeedsWorkMoreInfoPage } from 'src/pages/feedback/needs-work/more-info/more-info';
import { RequestSubstitutePage } from 'src/pages/request-substitute/request-substitute';
import { MyProsPage } from 'src/pages/my-pros/my-pros';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { MyProsEditProPage } from 'src/pages/my-pros/edit-pro/edit-pro';
import { TidyFindNewProPage } from 'src/pages/my-pros/tidy-find-new-pro/tidy-find-new-pro';
import { AddProxyNumberComponent } from 'src/pages/messages/add-proxy-number/add-proxy-number';
import { AddProPage } from 'src/pages/my-pros/add-pro/add-pro';
import { ProAddedPage } from 'src/pages/my-pros/pro-added/pro-added';
import { UsersPage } from 'src/pages/users/users';
import { UserPage } from 'src/pages/users/user/user';
import { TeamsPage } from 'src/pages/team/teams/teams';
import { TeamPage } from 'src/pages/team/team/team';
import { AddTeamPage } from 'src/pages/team/add-team/add-team';
import { AddAddressToTeamPage } from 'src/pages/team/add-address-to-team/add-address-to-team';
import { EditTeamNamePage } from 'src/pages/team/edit-team-name/edit-team-name';
import { EditTeamParentPage } from 'src/pages/team/edit-team-parent/edit-team-parent';
import { AddNewUserPage } from 'src/pages/users/add-new-user/add-new-user'
import { AddUserToTeamPage } from 'src/pages/team/add-user-to-team/add-user-to-team'
import { EditUserDetailsPage } from 'src/pages/users/edit-user-details/edit-user-details'
import { ToDosPage } from 'src/pages/to-dos/to-dos';
import { AllToDoListsPage } from 'src/pages/to-dos/all-to-do-lists/all-to-do-lists';
import { ToDosAddListPage } from 'src/pages/to-dos/add-list/add-list';
import { ToDosEditToDoPage } from 'src/pages/to-dos/edit-to-do/edit-to-do';
import { ToDosListSettingsPage } from 'src/pages/to-dos/list-settings/list-settings';
import { ToDosRenameListPage } from 'src/pages/to-dos/rename-list/rename-list';
import { ToDosRoomPage } from 'src/pages/to-dos/room/room';
import { ToDosRemoveRoomPage } from 'src/pages/to-dos/remove-room/remove-room';
import { ToDosDoNotCleanRoomPage } from 'src/pages/to-dos/do-not-clean-room/do-not-clean-room';
import { ToDosSelectDefaultListPage } from 'src/pages/to-dos/select-default-list/select-default-list';
import { ToDosAddRoomPage } from 'src/pages/to-dos/add-room/add-room';
import { ToDosAddToDoPage } from 'src/pages/to-dos/add-to-do/add-to-do';
import { ToDosAddDoNotPage } from 'src/pages/to-dos/add-do-not/add-do-not';
import { ToDosAddRoomPhotoPage } from 'src/pages/to-dos/add-room-photo/add-room-photo';
import { ToDosApplyToJobPage } from 'src/pages/to-dos/apply-to-job/apply-to-job';
import { ToDosApplyToJobsPage } from 'src/pages/to-dos/apply-to-jobs/apply-to-jobs';
import { ToDosCreateFirstListPage } from 'src/pages/to-dos/create-first-list/create-first-list';
import { ToDosEditRoomInfoPage } from 'src/pages/to-dos/edit-room-info/edit-room-info';
import { AccountTypePage } from 'src/pages/more/account-type/account-type';
import { AccountSettingsPage } from 'src/pages/more/account-settings/account-settings';
import { EditLanguagePage } from 'src/pages/more/edit-language/edit-language';
import { BulkUploadPage } from 'src/pages/more/bulk-upload/bulk-upload';
import { AddressesPage } from 'src/pages/more/addresses/addresses';
import { AddAddressPage } from 'src/pages/add-address/add-address';
import { BillSettingsPage } from 'src/pages/bills/bills-settings/bills-settings';
import { EditAddressDetailPage } from 'src/pages/edit-address-detail/edit-address-detail';
import { BuyBundlePage } from 'src/pages/more/buy-bundle/buy-bundle';
import { PaymentMethodsPage } from 'src/pages/more/payment-methods/payment-methods';
import { PaymentMethodFormPage } from 'src/pages/more/payment-methods/payment-method-form/payment-method-form';
import { ApiKeysPage } from 'src/pages/more/api-keys/api-keys';
import { WebhooksPage } from 'src/pages/more/webhooks/webhooks';
import { WebhookDetailsPage } from 'src/pages/more/webhooks/webhook-details/webhook-details';
import { WebhookAttemptPage } from "src/pages/more/webhooks/webhook-attempt/webhook-attempt";
import { AddWebhookPage } from 'src/pages/more/webhooks/add-webhook/add-webhook';
import { EditWebhookPage } from 'src/pages/more/webhooks/edit-webhook/edit-webhook';
import { ContactInfoPage } from 'src/pages/more/contact-info/contact-info';
import { ContactProPage } from 'src/pages/schedule/contact-pro/contact-pro';
import { ActiveIntegrationsPage } from 'src/pages/integrations/active-integrations/active-integrations';
import { IntegrationsPage } from 'src/pages/integrations/integrations/integrations';
import { AddIntegrationPage } from 'src/pages/integrations/add-integration/add-integration';
import { IntegrationOptionsPage } from 'src/pages/integrations/integration-options/integration-options';
import { RequestIntegrationPage } from 'src/pages/integrations/request-integration/request-integration';
import { EditPricesPage } from 'src/pages/more/edit-prices/edit-prices';
import { MyPricesPage } from 'src/pages/more/my-prices/my-prices';
import { PastJobsPage } from 'src/pages/more/past-jobs/past-jobs';
import { PaymentSettingsPage } from 'src/pages/more/payment-settings/payment-settings';
import { PaymentOptionsPage } from 'src/pages/more/payment-options/payment-options';
import { UseGiftCardPage } from 'src/pages/more/use-gift-card/use-gift-card';
import { MorePage } from 'src/pages/more/more';
import { IntroductionPage } from 'src/pages/introduction/introduction';
import { IntroductionGuard } from 'src/providers/guard/introduction-guard';
import { ResetPasswordPage } from 'src/pages/introduction/reset-password/reset-password';
import { RentalGetStartedPage } from 'src/pages/rental-get-started/rental-get-started';
import { SuccessPage } from 'src/shared/pages/success/success';
import { ShareJobPage } from 'src/pages/share-job/share-job';
import { SubscriptionsPage } from 'src/pages/subscription/subscription';
import { ChangeSubscriptionPage } from 'src/pages/subscription/change-subscription/change-subscription';
import { ShareListPage } from 'src/pages/share-list/share-list';
import { PlansPage } from 'src/pages/plans/plans';
import { RemoteLockPage } from 'src/pages/integrations/remote-lock/remote-lock';
import { EditAddressLockPage } from 'src/pages/integrations/edit-address-lock/edit-address-lock';
import { DefaultListsPage } from 'src/pages/to-dos/default-lists/default-lists';
import { EditDefaultListPage } from 'src/pages/to-dos/edit-default-list/edit-default-list';
import { EmployerCampaignPage } from 'src/pages/employer/campaign/campaign';
import { EmployerCompliancePage } from 'src/pages/employer/compliance/compliance';
import { EmployerDashboardPage } from 'src/pages/employer/dashboard/dashboard';
import { EmployerGetStartedPage } from 'src/pages/employer/get-started/get-started';
import { EmployerIntegrationsPage } from 'src/pages/employer/integrations/integrations';
import { EmployerAddEmployeePage } from 'src/pages/employer/add-employee/add-employee';
import { EmployerEditEmployeePage } from 'src/pages/employer/edit-employee/edit-employee';
import { EmployerEditEmployeeDetailsPage } from 'src/pages/employer/edit-employee/edit-employee-details/edit-employee-details';
import { EmployerAddEmployeeCreditPage } from 'src/pages/employer/edit-employee/add-employee-credit/add-employee-credit';
import { EmployerAddCreditPage } from 'src/pages/employer/add-credit/add-credit';
import { EmployerSettingsPage } from 'src/pages/employer/settings/settings';
import { JobRequestWorkflows } from 'src/pages/job-request-workflows/job-request-workflows';

// TODO Remove when employer feature finished
import { EmployerCampaignMockPage } from 'src/pages/employer/mock/employer/campaign/campaign';
import { EmployerComplianceMockPage } from 'src/pages/employer/mock/employer/compliance/compliance';
import { EmployerEmployeesMockPage } from 'src/pages/employer/mock/employer/employees/employees';
import { EmployerGetStartedMockPage } from 'src/pages/employer/mock/employer/get-started/get-started';
import { EmployerIntegrationsMockPage } from 'src/pages/employer/mock/employer/integrations/integrations';

import { ConfirmPage } from 'src/pages/confirm/confirm.component';
import { ConfirmBookingPage } from 'src/pages/booking/confirm-booking/confirm-booking';
import { RequestJobPage } from 'src/pages/request-job/request-job';
import { RequestProPage } from 'src/pages/request-pro/request-pro';
import { RequestProCounterPage } from 'src/pages/request-pro-counter/request-pro-counter';
import { BookWithProPage } from 'src/pages/my-pros/book-with-pro/book-with-pro';
import { ModalMoreTimesPage } from 'src/pages/booking/modal-more-times/modal-more-times';
import { ChangeToDoStatePage } from 'src/pages/to-dos/change-to-do-state/change-to-do-state';
import { ReservationPage } from 'src/pages/reservation/reservation';
import { ConfirmBookingPaymentPage } from 'src/pages/confirm-booking-payment/confirm-booking-payment';
import { CreateAccountPage } from 'src/pages/create-account/create-account';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { DevelopersPage } from 'src/pages/more/developers/developers';
import { LogsPage } from 'src/pages/more/logs/logs';
import { logDetailsPage } from 'src/pages/more/logs/log-details/log-details';
import { LoginPage } from 'src/pages/introduction/log-in/log-in';
import { ForgotUsernamePage } from 'src/pages/introduction/forgot-username/forgot-username';
import { UseToDosPage } from 'src/pages/to-dos/use-to-dos/use-to-dos';
import { ProDidNotShowUpPage } from 'src/pages/pro-did-not-show-up/pro-did-not-show-up';
import { MissingItemPage } from 'src/pages/missing-item/missing-item';
import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';
import { BillingItemHelpPage } from 'src/pages/billing-history/billing-item-help/billing-item-help';
import { GetRefundPage } from 'src/pages/billing-history/get-refund/get-refund';
import { CancellationOptionsPage } from 'src/pages/more/cancellation-options/cancellation-options';
import { JsonFormatterComponent } from 'src/shared/components/json-formatter/json-formatter.component';
import { ProposalsPage } from 'src/pages/proposals/proposals';
import { PendingProposalPage } from 'src/pages/proposals/pending-proposal/pending-proposal';
import { AcceptedProposalPage } from 'src/pages/proposals/accepted-proposal/accepted-proposal';
import { ClientHubPage } from 'src/pages/client-hub/client-hub';
import { LostItemPage } from 'src/pages/more/past-job/lost-item/lost-item';
import { EditTipPage } from 'src/pages/more/past-job/edit-tip/edit-tip';
import { PasswordRecoveryPage } from 'src/pages/password-recovery/password-recovery';
import { ReloadComponent } from 'src/pages/reload/reload-component';
import { PaidInvoicePage } from 'src/pages/invoices/paid-invoice/paid-invoice';
import { PendingInvoicePage } from 'src/pages/invoices/pending-invoice/pending-invoice';
import { ThumbnailInvoicePage } from 'src/pages/thumbnails/invoice/thumbnail-invoice';
import { ThumbnailProposalPage } from 'src/pages/thumbnails/proposal/thumbnail-proposal';
import { ChooseAddressToBookPage } from 'src/pages/proposals/choose-address-to-book/choose-address-to-book';
import { LogInToBookPage } from 'src/pages/proposals/log-in-to-book/log-in-to-book';
import { CreateAccountToBookPage } from 'src/pages/proposals/create-account-to-book/create-account-to-book';
import { ReportsPage } from 'src/pages/reports/reports';
import { TasksPage } from 'src/pages/tasks/tasks';
import { TaskPage } from 'src/pages/tasks/task/task';
import { LogIssuePage } from 'src/pages/log-issue/log-issue';
import { NotificationDetailPage } from 'src/pages/schedule/notification-details/notification-details';
import { JobPage } from 'src/pages/schedule/job/job';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { JobActivityPage } from 'src/pages/schedule/job-activity/job-activity';
import { MarkJobCompletePage } from 'src/pages/schedule/mark-job-complete/mark-job-complete';
import { WhyAddPrivateProsPage } from 'src/pages/why-add-private-pros/why-add-private-pros';
import { CallProPage } from 'src/pages/call-pro/call-pro';
import { SendPaymentPage } from 'src/pages/send-payment/send-payment';
import { ClientGetStartedPage } from 'src/pages/client-get-started/client-get-started';
import { ClientGetStartedProAddedPage } from 'src/pages/client-get-started/pro-added/pro-added';
import { InvoiceAutopayPage } from 'src/pages/invoices/invoice-autopay/invoice-autopay';
import { SendPaymentOrPayInvoicePage } from 'src/pages/invoices/send-payment-or-pay-invoice/send-payment-or-pay-invoice';
import { MessagesPage } from 'src/pages/messages/messages';
import { AccountNotificationSettingsPage } from 'src/pages/more/account-notification-settings/account-notification-settings';
import { AddAccountNotificationPage } from 'src/pages/more/add-account-notification/add-account-notification';
import { BillsPage } from 'src/pages/bills/bills';
import { VendorCompliancePage } from 'src/pages/vendor-compliance/vendor-compliance';
import { ImportPropertiesCSVPage } from 'src/pages/import-properties-csv/import-properties-csv';
import { RentalClosingPage } from 'src/pages/rental-closing/rental-closing';

// Dashboard
import { DashboardPage } from 'src/pages/dashboard/dashboard';
import { EditReportColumnPage } from 'src/pages/dashboard/edit-report-column/edit-report-column';
import { AddReportPage } from 'src/pages/dashboard/add-report/add-report';
import { ReportSettingsPage } from 'src/pages/dashboard/report-settings/report-settings';
import { EditReportNamePage } from 'src/pages/dashboard/edit-report-name/edit-report-name';

// Concierge
import { ConciergePage } from 'src/pages/concierge/concierge';
import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';
import { ConciergeMessagePage } from 'src/pages/concierge/concierge-message/concierge-message';
import { ConciergeReplyPage } from 'src/pages/concierge/concierge-message/concierge-reply/concierge-reply';
import { GetHelpPage } from 'src/pages/concierge/get-help/get-help';
import { JobIssuePage } from 'src/pages/concierge/job-issue/job-issue';
import { JobIssueDetailPage } from 'src/pages/concierge/job-issue/job-issue-detail/job-issue-detail';
import { JobIssueNegativeReviewPage } from 'src/pages/concierge/job-issue/job-issue-negative-review/job-issue-negative-review';
import { DamageClaimPage } from 'src/pages/concierge/damage-claim/damage-claim';
import { OpenDisputePage } from 'src/pages/concierge/advanced-dispute/advanced-dispute';
import { DisputeResolutionPage } from 'src/pages/concierge/dispute-resolution/dispute-resolution';

import { CheckUserAddressCountGuard } from 'src/app/check-user-address-count.guard';

// Genome
import { EditAddressPage } from 'src/pages/more/edit-address/edit-address';
import { PropertyOptionsPage } from 'src/pages/more/edit-address/property-options/property-options';
import { EditRoomsPage } from 'src/pages/more/edit-address/edit-rooms/edit-rooms';
import { AddRoomPage } from 'src/pages/more/edit-address/edit-rooms/add-room/add-room';
import { EditRoomItemPage } from 'src/pages/more/edit-address/room-item-details/edit-room-item/edit-room-item';
import { EditRoomDetailsPage } from 'src/pages/more/edit-address/edit-rooms/edit-room-details/edit-room-details';
import { AddRoomItemPage } from 'src/pages/more/edit-address/add-room-item/add-room-item';
import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { MapsIntroductionPage } from 'src/pages/more/edit-address/maps-introduction/maps-introduction';
import { PropertyMappingPage } from 'src/pages/more/edit-address/property-mapping/property-mapping';
import { PropertyScanningPage } from 'src/pages/more/edit-address/property-mapping/property-scanning/property-scanning';
import { ItemMaintenancePage } from 'src/pages/more/edit-address/item-maintenance/item-maintenance'
import { MaintenancesInstancePage } from 'src/pages/more/edit-address/add-room-item/add-item-maintenance/add-item-maintenance'

import { AppBrandingPage } from 'src/pages/more/app-branding/app-branding'
import { InventoryPage } from 'src/pages/inventory/inventory'
import { RecurringTasksPage } from 'src/pages/tasks/recurring-tasks/recurring-tasks';
import { ArchivedTasksPage } from 'src/pages/tasks/archived-tasks/archived-tasks';
import { WorkflowsPage } from 'src/pages/workflows/workflows';
import { WorkflowPage } from 'src/pages/workflows/workflow/workflow';
import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { WorkflowHistoryPage } from 'src/pages/workflows/workflow-history/workflow-history';
import { WorkflowRunPage } from 'src/pages/workflows/workflow-run/workflow-run';
import { WorkflowFeatureRequestPage } from 'src/pages/workflows/workflow-feature-request/workflow-feature-request';
import { AutomationsSummaryPage } from 'src/pages/workflows/automations-summary/automations-summary';
import { AddWorkflowPage } from 'src/pages/workflows/add-workflow/add-workflow';
import { BrowseProsPage } from 'src/pages/browse-pros/browse-pros';
import { ProCardComponent } from 'src/pages/browse-pros/pro-card-component/pro-card.component';
import { NextStepsModalComponent } from 'src/pages/browse-pros/next-steps-modal-component/next-steps-modal-component';
import { ProSettingsPage } from 'src/pages/my-pros/pro-settings/pro-settings';
import { NewMessageModalComponent } from 'src/pages/messages/new-message-modal/new-message-modal';
import { ProMessagesPage } from 'src/pages/pro-messages/pro-messages';
import { UnableToMessageModalComponent } from 'src/pages/schedule/job/unable-to-message-modal/unable-to-message';
import { ProxyMessagesPage } from 'src/pages/proxy-messages/proxy-messages';
import { TaskCardComponent } from 'src/pages/tasks/task-card/task-card';
import { RecurringTaskComponent } from 'src/pages/tasks/recurring-tasks/recurring-task/recurring-task';
import { ConciergeItemsComponent } from 'src/pages/concierge/concierge-items/concierge-items';
import { ConciergeRulesComponent } from 'src/pages/concierge/concierge-rules/concierge-rules';
import { ConciergeRuleComponent } from 'src/pages/concierge/concierge-rule/concierge-rule';
import { CreateActionPlanPage } from 'src/pages/tasks/create-action-plan/create-action-plan';
import { InputToDosPage } from 'src/pages/more/input-to-dos/input-to-dos';
import { ReservationsPage } from 'src/pages/reservations/reservations';
import { AddReservationPage } from 'src/pages/add-reservation/add-reservation';
import { SummaryOfDayPage } from 'src/pages/schedule/summary-of-day/summary-of-day';
import { ReservationsOfDayPage } from 'src/pages/schedule/reservations-of-day/reservations-of-day';
import { IntegrationAuditLogsPage } from 'src/pages/integrations/audit-logs/audit-logs';
import { IntegrationAuditLogItemPage } from 'src/pages/integrations/audit-logs/audit-log-item/audit-log-item';
import { MoreDesktopPage } from 'src/pages/more-desktop/more-desktop';
import { BlockOnMissingFieldsPage } from 'src/pages/to-dos/block-on-missing-fields/block-on-missing-fields';
import { LoadCreditPage } from 'src/pages/more/payment-methods/load-credit/load-credit';
import { PastSharedJobPage } from 'src/pages/more/past-shared-job/past-shared-job';
import { MaintenancePage } from 'src/pages/maintenance/maintenance';
import { UpsellPage } from 'src/pages/upsell/upsell';
import { JobRequestBidsComponent } from 'src/pages/schedule/job/job-request-bids/job-request-bids';
import { ConfirmRequestPage } from 'src/pages/schedule/job/confirm-request/confirm-request';
import { IntegrationSettingsPage } from 'src/pages/integrations/integration-settings/integration-settings';
import { AddCustomFieldPage } from 'src/pages/integrations/integration-settings/add-custom-field/add-custom-field';

export const pages = [
  ConciergeItemsComponent,
  ConciergeRulesComponent,
  ConciergeRuleComponent,
  NewMessageModalComponent,
  ProMessagesPage,
  UnableToMessageModalComponent,
  AddWorkflowPage,
  NextStepsModalComponent,
  ProCardComponent,
  BrowseProsPage,
  MaintenancePage,
  UpsellPage,
  AutomaticBookingPage,
  AutomaticBookingAddressPage,
  AccountTypePage,
  AccountSettingsPage,
  EditLanguagePage,
  BulkUploadPage,
  AddAddressPage,
  BillSettingsPage,
  AssignProPage,
  EditAddressDetailPage,
  AddProxyNumberComponent,
  AddProPage,
  AddNewUserPage,
  AddUserToTeamPage,
  EditUserDetailsPage,
  ProAddedPage,
  AddressesPage,
  AddFirstAddressPage,
  PaymentMethodsPage,
  PaymentMethodFormPage,
  ApiKeysPage,
  BackupTimesPage,
  BookingNotePage,
  RemoteInspectionPage,
  BlockOnMissingFieldsPage,
  BookJobPage,
  BookOrRequestPage,
  BookWithProPage,
  BuyBundlePage,
  ChangeToDoStatePage,
  ConfirmBookingPage,
  ConfirmBookingPaymentPage,
  ConfirmPage,
  ContactInfoPage,
  EmployerCampaignPage,
  EmployerCompliancePage,
  EmployerDashboardPage,
  EmployerGetStartedPage,
  EmployerIntegrationsPage,
  EmployerAddEmployeePage,
  EmployerEditEmployeePage,
  EmployerEditEmployeeDetailsPage,
  EmployerAddEmployeeCreditPage,
  EmployerAddCreditPage,
  EmployerSettingsPage,
  JobRequestWorkflows,
  ApiKeysPage,
  WebhooksPage,
  WebhookDetailsPage,
  WebhookAttemptPage,
  AddWebhookPage,
  EditWebhookPage,
  ContactProPage,
  CreateAccountPage,
  AddIntegrationPage,
  EditPlanPage,
  IntegrationsPage,
  ActiveIntegrationsPage,
  IntegrationOptionsPage,
  RequestIntegrationPage,
  IntegrationAuditLogsPage,
  IntegrationAuditLogItemPage,
  IntroductionPage,
  JobBackupTimesPage,
  LoginPage,
  LoveItPage,
  ModalMoreTimesPage,
  MorePage,
  EditPricesPage,
  MyPricesPage,
  MyProPage,
  MyProsPage,
  MyProsEditProPage,
  NeedsWorkMoreInfoPage,
  NeedsWorkWhatHappenedPage,
  PastJobPage,
  PastJobsPage,
  PastPrivateJobPage,
  PaymentSettingsPage,
  PaymentOptionsPage,
  RequestJobPage,
  RequestProCounterPage,
  RequestProPage,
  RequestSubstitutePage,
  ResetPasswordPage,
  RentalGetStartedPage,
  SchedulePage,
  ScheduleListPage,
  UsersPage,
  UserPage,
  TeamsPage,
  TeamPage,
  AddTeamPage,
  AddAddressToTeamPage,
  EditTeamNamePage,
  EditTeamParentPage,
  ToDosAddDoNotPage,
  ToDosAddListPage,
  ToDosAddRoomPage,
  ToDosDoNotCleanRoomPage,
  ToDosAddRoomPhotoPage,
  ToDosAddToDoPage,
  ToDosApplyToJobPage,
  ToDosApplyToJobsPage,
  ToDosCreateFirstListPage,
  ToDosEditRoomInfoPage,
  ToDosEditToDoPage,
  TidyFindNewProPage,
  ToDosListSettingsPage,
  AllToDoListsPage,
  ToDosPage,
  ToDosRenameListPage,
  ToDosRoomPage,
  ToDosSelectDefaultListPage,
  UseGiftCardPage,
  ToDosRemoveRoomPage,
  ReservationPage,
  ForgotUsernamePage,
  DevelopersPage,
  LogsPage,
  logDetailsPage,
  ProDidNotShowUpPage,
  MissingItemPage,
  BillingHistoryPage,
  BillingItemHelpPage,
  GetRefundPage,
  CancellationOptionsPage,
  JsonFormatterComponent,
  ProposalsPage,
  PendingProposalPage,
  AcceptedProposalPage,
  ClientHubPage,
  LostItemPage,
  EditTipPage,
  PasswordRecoveryPage,
  ReloadComponent,
  PaidInvoicePage,
  PendingInvoicePage,
  ThumbnailInvoicePage,
  ThumbnailProposalPage,
  ChooseAddressToBookPage,
  LogInToBookPage,
  CreateAccountToBookPage,
  ReportsPage,
  ShareJobPage,
  SubscriptionsPage,
  ChangeSubscriptionPage,
  ShareListPage,
  PlansPage,
  RemoteLockPage,
  EditAddressLockPage,
  DefaultListsPage,
  EditDefaultListPage,
  NotificationDetailPage,
  JobPage,
  SelectCategoryPage,
  JobActivityPage,
  MarkJobCompletePage,
  WhyAddPrivateProsPage,
  CallProPage,
  SendPaymentPage,
  ClientGetStartedPage,
  ClientGetStartedProAddedPage,
  InvoiceAutopayPage,
  SendPaymentOrPayInvoicePage,
  MessagesPage,
  AccountNotificationSettingsPage,
  AddAccountNotificationPage,
  BillsPage,
  VendorCompliancePage,
  ImportPropertiesCSVPage,
  RentalClosingPage,

  // TODO Remove mock when employer feature finished
  EmployerCampaignMockPage,
  EmployerComplianceMockPage,
  EmployerEmployeesMockPage,
  EmployerGetStartedMockPage,
  EmployerIntegrationsMockPage,

  //Dashboard
  DashboardPage,
  EditReportColumnPage,
  AddReportPage,
  ReportSettingsPage,
  EditReportNamePage,

  // Concierge
  ConciergePage,
  ContactConciergePage,
  ConciergeMessagePage,
  ConciergeReplyPage,
  GetHelpPage,
  JobIssuePage,
  JobIssueDetailPage,
  JobIssueNegativeReviewPage,
  UseToDosPage,
  DamageClaimPage,
  UseToDosPage,
  OpenDisputePage,
  DisputeResolutionPage,

  //Genome
  EditAddressPage,
  PropertyOptionsPage,
  EditRoomsPage,
  AddRoomPage,
  EditRoomDetailsPage,
  AddRoomItemPage,
  EditRoomItemPage,
  ItemDetailsPage,
  MapsIntroductionPage,
  PropertyMappingPage,
  PropertyScanningPage,
  ItemMaintenancePage,
  MaintenancesInstancePage,
  AppBrandingPage,
  InventoryPage,
  RecurringTasksPage,
  ArchivedTasksPage,

  // Workflows
  WorkflowsPage,
  WorkflowPage,
  EditWorkflowPage,
  WorkflowHistoryPage,
  WorkflowRunPage,
  WorkflowFeatureRequestPage,
  AutomationsSummaryPage,

  // Issues
  LogIssuePage,
  TasksPage,
  TaskPage,
  TaskCardComponent,
  CreateActionPlanPage,

  ProSettingsPage,
  ProxyMessagesPage,

  RecurringTaskComponent,
  InputToDosPage,
  ReservationsPage,
  AddReservationPage,
  SummaryOfDayPage,
  ReservationsOfDayPage,
  MoreDesktopPage,
  LoadCreditPage,
  PastSharedJobPage,
  JobRequestBidsComponent,
  ConfirmRequestPage,
  IntegrationSettingsPage,
  AddCustomFieldPage,
];

export const routes: Routes = [
  { component: ConfirmRequestPage, path: 'confirm-request', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Confirm Request"} },
  { component: JobRequestBidsComponent, path: 'job-request-bids', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job Request Bids"} },
  { component: IntegrationSettingsPage, path: 'integration-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Integration Settings"} },
  { component: AddCustomFieldPage, path: 'integrations/add-custom-field', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Custom Field"} },
  { component: MoreDesktopPage, path: 'more-desktop', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "More"} },
  { component: ReservationsOfDayPage, path: 'reservations-of-day', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Reservations of Day"} },
  { component: SummaryOfDayPage, path: 'summary-of-day', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Summary of Day"} },
  { component: AddReservationPage, path: 'add-reservation', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Reservation"} },
  { component: ReservationsPage, path: 'more/reservations', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Reservations"} },
  { component: ConciergeItemsComponent, path: 'concierge-items', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge Requests"} },
  { component: ConciergeRulesComponent, path: 'concierge-rules', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge Rules"} },
  { component: ConciergeRuleComponent, path: 'concierge-rule', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge Rule"} },
  { component: BrowseProsPage, path: 'browse-pros', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Browse Pros"} },
  { component: AutomaticBookingPage, path: 'automatic-booking', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Automatic Booking"} },
  { component: AutomaticBookingPage, path: 'automations/automatic-booking', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Automatic Booking"} },
  { component: AutomaticBookingAddressPage, path: 'automatic-booking-property', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Automatic Booking"} },
  { component: AutomaticBookingAddressPage, path: 'automatic-booking-property/:addressId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Automatic Booking"} },
  { component: AddFirstAddressPage, path: 'add-first-property/:flowType', data: { title: "Add Property"} },
  { component: BillSettingsPage, path: 'bill-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bill-settings"} },
  { component: AssignProPage, path: 'assign-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Assign Pro"} },
  { component: SchedulePage, path: 'schedule', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Schedule"} },
  { component: ScheduleListPage, path: 'schedule-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Schedule"} },
  { component: EditPlanPage, path: 'edit-plan', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Plan"} },
  { component: JobRequestWorkflows, path: 'job-request-workflows', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job Request Workflows"} },

  { component: EmployerCampaignPage, path: 'employer-campaign/employer-campaign', canActivate: [ActivateMenuGuard], data: { title: "Campaign", isEmployerPortal: true} },
  { component: EmployerCompliancePage, path: 'employer-compliance/employer-compliance', canActivate: [ActivateMenuGuard], data: { title: "Compliance", isEmployerPortal: true} },
  { component: EmployerDashboardPage, path: 'employer-dashboard/employer-dashboard', canActivate: [ActivateMenuGuard], data: { title: "Dashboard", isEmployerPortal: true} },
  { component: EmployerGetStartedPage, path: 'employer-get-started/employer-get-started', canActivate: [ActivateMenuGuard], data: { title: "Get Started", isEmployerPortal: true} },
  { component: EmployerIntegrationsPage, path: 'employer-integrations/employer-integrations', canActivate: [ActivateMenuGuard], data: { title: "Integrations", isEmployerPortal: true} },
  { component: EmployerAddEmployeePage, path: 'employer-add-employee', canActivate: [ActivateMenuGuard], data: { title: "Add Employee", isEmployerPortal: true} },
  { component: EmployerEditEmployeePage, path: 'employer-edit-employee', canActivate: [ActivateMenuGuard], data: { title: "Edit Employee"} },
  { component: EmployerEditEmployeeDetailsPage, path: 'employer-edit-employee-details', canActivate: [ActivateMenuGuard], data: { title: "Edit Employee"} },
  { component: EmployerAddEmployeeCreditPage, path: 'employer-add-employee-credit', canActivate: [ActivateMenuGuard], data: { title: "Add Credit"} },
  { component: EmployerAddCreditPage, path: 'employer-add-credit', canActivate: [ActivateMenuGuard], data: { title: "Add Credit"} },
  { component: EmployerSettingsPage, path: 'employer-settings', canActivate: [ActivateMenuGuard], data: { title: "settings"} },

  { component: EmployerCampaignMockPage, path: 'employer-campaign', canActivate: [ActivateMenuGuard], data: { title: "Campaign", isEmployerPortal: true} },
  { component: EmployerComplianceMockPage, path: 'employer-compliance', canActivate: [ActivateMenuGuard], data: { title: "Compliance", isEmployerPortal: true} },
  { component: EmployerEmployeesMockPage, path: 'employer-employees', canActivate: [ActivateMenuGuard], data: { title: "Employees", isEmployerPortal: true} },
  { component: EmployerGetStartedMockPage, path: 'employer-get-started', canActivate: [ActivateMenuGuard], data: { title: "Get Started", isEmployerPortal: true} },
  { component: EmployerIntegrationsMockPage, path: 'employer-integrations', canActivate: [ActivateMenuGuard], data: { title: "Integrations", isEmployerPortal: true} },

  { component: BackupTimesPage, path: 'backup-times', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Backup Times"} },
  { component: BookingNotePage, path: 'booking-note', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Booking Note"} },

  { component: RemoteInspectionPage, path: 'remote-inspection', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Remote Inspection"} },
  { component: BlockOnMissingFieldsPage, path: 'block-on-missing-fields', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Block on Missing Fields"} },
  { component: BookJobPage, path: 'book-job', canActivate: [DisableMenuGuard, AuthGuard] , data: { title: "Book Job"} },
  { component: BookOrRequestPage, path: 'book-or-request', canActivate: [ActivateMenuGuard, AuthGuard] , data: { title: "Book or Request"} },
  { component: ModalMoreTimesPage, path: 'more-times-modal', canActivate: [DisableMenuGuard, AuthGuard] , data: { title: "Select Times"} },
  { component: ConfirmBookingPage, path: 'confirm-booking', canActivate: [DisableMenuGuard, AuthGuard] , data: { title: "Confirm Booking"} },
  { component: ConfirmPage, path: 'confirm-page', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Confirm"} },
  { component: JobBackupTimesPage, path: 'job-backup-times', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job Backup Times"} },
  { component: LoveItPage, path: 'love-it/:jobId/:hkJobId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Love It"} },
  { component: NeedsWorkWhatHappenedPage, path: 'needs-work-what-happened/:cleaningId/:hkJobId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Needs Work"} },
  { component: NeedsWorkMoreInfoPage, path: 'needs-work-more-info/:cleaningId/:hkJobId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Needs Work"} },
  { component: RequestSubstitutePage, path: 'request-substitute/:jobId', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Request Substitute"} },
  { component: RequestProCounterPage, path: 'request-pro-counter', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Request Pro Counter"} },
  { component: MyProsPage, path: 'my-pros', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "My Pros", cantGoBack: true} },
  { component: ProSettingsPage, path: 'pro-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Pro Settings", cantGoBack: false} },
  { component: MyProPage, path: 'my-pro/:proId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "My Pro"} },
  { component: MyProsEditProPage, path: 'edit-pro/:proId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: 'Edit Pro'}},
  { component: TidyFindNewProPage, path: 'tidy-find-new-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "TIDY Find New Pro"} },
  { component: AddProxyNumberComponent, path: 'add-number', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Number"} },
  { component: AddProPage, path: 'add-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Pro"} },
  { component: AddProPage, path: 'get-started-add-pro', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Add Pro"} },
  { component: ProAddedPage, path: 'pro-added', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Pro Added"} },
  { component: AddNewUserPage, path: 'add-new-user', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add New User"} },
  { component: AddNewUserPage, path: 'employer-add-new-admin/employer-add-new-admin', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add New Admin"} },
  { component: AddUserToTeamPage, path: 'add-user-to-team', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add User to Team"} },
  { component: EditUserDetailsPage, path: 'edit-user', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit User"} },
  { component: EditUserDetailsPage, path: 'employer-edit-admin/employer-edit-admin', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Admin"} },
  { component: UsersPage, path: 'more/users', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Users"} },
  { component: UsersPage, path: 'users', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Users"} },
  { component: UsersPage, path: 'employer-admins/employer-admins', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Admins", isEmployerPortal: true} },
  { component: UserPage, path: 'more/user', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "User"} },
  { component: UserPage, path: 'user/:userId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "User"} },
  { component: TeamPage, path: 'team/:teamId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Team"} },
  { component: AddTeamPage, path: 'add-team', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Team"} },
  { component: AddAddressToTeamPage, path: 'add-property-to-team', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Property to Team"} },
  { component: EditTeamNamePage, path: 'edit-team-name', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Team Name"} },
  { component: EditTeamParentPage, path: 'edit-parent-team', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Parent Team Name"} },
  { component: TeamsPage, path: 'teams', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Teams"} },
  { component: AllToDoListsPage, path: 'all-to-do-lists', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "To-Dos"} },
  { component: ToDosPage, path: 'to-dos', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "To-Dos"} },
  { component: ToDosAddListPage, path: 'add-to-dos-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add To-Do List"} },
  { component: ToDosEditToDoPage, path: 'edit-to-do', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit To-Do"} },
  { component: ToDosListSettingsPage, path: 'list-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "To-Do List Settings"} },
  { component: ToDosRenameListPage, path: 'rename-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Rename To-Do List"} },
  { component: ToDosRoomPage, path: 'card', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Card"} },
  { component: ToDosSelectDefaultListPage, path: 'select-default-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Select Default List"} },
  { component: ToDosAddRoomPage, path: 'add-card', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Card"} },
  { component: ToDosDoNotCleanRoomPage, path: 'do-not-clean-room', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Room"} },
  { component: ToDosAddToDoPage, path: 'add-to-do', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add To-Do"} },
  { component: ToDosAddDoNotPage, path: 'add-do-not', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Do-Not"} },
  { component: ToDosAddRoomPhotoPage, path: 'add-room-photo', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Room Photo"} },
  { component: ToDosApplyToJobPage, path: 'apply-to-job', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Apply To-Do List To Job"} },
  { component: ToDosApplyToJobsPage, path: 'apply-to-jobs', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Apply To-Do List To Jobs"} },
  { component: ToDosCreateFirstListPage, path: 'add-room-detail', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Create First List"} },
  { component: ToDosCreateFirstListPage, path: 'create-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Create First List"} },
  { component: ToDosEditRoomInfoPage, path: 'edit-room-info', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Room Info"} },
  { component: AccountTypePage, path: 'account-type', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Account Type"} },
  { component: AccountSettingsPage, path: 'account-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: {title: "Account Settings"}},
  { component: AccountSettingsPage, path: 'more/account-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: {title: "Account Settings", cantGoBack: true}},
  { component: EditLanguagePage, path: 'edit-language', canActivate: [ActivateMenuGuard, AuthGuard], data: {title: "Edit Language"}},
  { component: BulkUploadPage, path: 'more/bulk-upload', canActivate: [ActivateMenuGuard, AuthGuard], data: {title: "Bulk Upload", cantGoBack: true}},
  { component: AddressesPage, path: 'properties', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Properties"} },
  { component: AddAddressPage, path: 'add-property', canActivate: [DisableMenuGuard], data: { title: "Add Property"} },
  { component: AddAddressPage, path: 'property', canActivate: [ActivateMenuGuard], data: { title: "Add Property"} },
  { component: EditAddressDetailPage, path: 'edit-property-detail', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Property"} },
  { component: AddressesPage, path: 'more/properties', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Properties", cantGoBack: true} },
  { component: AddAddressPage, path: 'add-first-property', canActivate: [DisableMenuGuard], data: { title: "Add Property"} },
  { component: PaymentMethodsPage, path: 'payment-methods', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Payment Methods"} },
  { component: PaymentMethodFormPage, path: 'payment-methods/payment-method-form', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Payment Method"} },
  { component: LoadCreditPage, path: 'payment-methods/load-credit', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Load Credit"} },
  { component: PaymentMethodFormPage, path: 'get-started-card-on-file/card', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Payment Method"} },
  { component: BuyBundlePage, path: 'buy-bundle', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Buy Bundle"} },
  { component: ContactInfoPage, path: 'contact-info', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Contact Info"} },
  { component: ApiKeysPage, path: 'api-keys', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "API Keys"} },
  { component: WebhooksPage, path: 'webhooks', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Webhooks"} },
  { component: WebhookDetailsPage, path: 'webhook-details', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Webhook Details"} },
  { component: WebhookAttemptPage, path: 'webhook-attempt', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Webhook Attempt"} },
  { component: AddWebhookPage, path: 'add-webhook', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Webhook"} },
  { component: EditWebhookPage, path: 'edit-webhook', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Webhook"} },
  { component: ContactProPage, path: 'contact-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Contact Pro"} },
  { component: ActiveIntegrationsPage, path: 'active-integrations', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Active Integrations"} },
  { component: IntegrationsPage, path: 'integrations/:type', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Integrations"} },
  { component: AddIntegrationPage, path: 'add-integration', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Integration"} },
  { component: AddIntegrationPage, path: 'add-direct-integration', canActivate: [DisableMenuGuard], data: { title: "Add Integration"} },
  { component: IntegrationOptionsPage, path: 'integration-options', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Integration Options"} },
  { component: IntegrationOptionsPage, path: 'direct-integration-options', canActivate: [DisableMenuGuard], data: { title: "Integration Options"} },
  { component: RequestIntegrationPage, path: 'request-integration', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Request-Integration"} },
  { component: IntegrationAuditLogsPage, path: 'audit-logs', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Audit Logs"} },
  { component: IntegrationAuditLogItemPage, path: 'audit-log', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Audit Log"} },
  { component: EditPricesPage, path: 'edit-prices', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Prices"} },
  { component: MyPricesPage, path: 'my-prices', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "My Prices"} },
  { component: PastJobsPage, path: 'past-jobs', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Past Jobs"} },
  { component: PastJobsPage, path: 'more/past-jobs', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Past Jobs", cantGoBack: true} },
  { component: PaymentSettingsPage, path: 'payment-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Payment Settings"} },
  { component: PaymentSettingsPage, path: 'more/payment-settings', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Payment Settings", cantGoBack: true} },
  { component: PaymentOptionsPage, path: 'more/payment-options', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Payment Settings", cantGoBack: true} },
  { component: UseGiftCardPage, path: 'use-gift-card', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Use Gift Card"} },
  { component: MorePage, path: 'more', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "More"} },
  { component: ResetPasswordPage, path: 'reset-password', canActivate: [DisableMenuGuard, AlreadyAuthenticatedGuard], data: { title: "Reset Password"} },
  { component: RentalGetStartedPage, path: 'rental-get-started', canActivate: [DisableMenuGuard], data: { title: "Get Started"} },
  { component: SuccessPage, path: 'success', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Success"} },
  { component: SuccessPage, path: 'success-no-auth', canActivate: [DisableMenuGuard], data: { title: "Success"} },
  { component: ShareJobPage, path: 'share-job', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Share Job"} },
  { component: SubscriptionsPage, path: 'subscription', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Subscription"} },
  { component: ChangeSubscriptionPage, path: 'change-subscription', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Change Subscription"} },
  { component: ChangeSubscriptionPage, path: 'select-subscription', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Select Subscription" } },
  { component: ShareListPage, path: 'share-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Share List"} },
  { component: PlansPage, path: 'plans', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Plans"} },
  { component: RemoteLockPage, path: 'remotelock', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "RemoteLock"} },
  { component: EditAddressLockPage, path: 'edit-address-lock', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Address Lock"} },
  { component: DefaultListsPage, path: 'default-lists', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Default Lists"} },
  { component: EditDefaultListPage, path: 'edit-default-list', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Default List"} },
  { component: RequestJobPage, path: 'request-job', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Request Job"} },
  { component: RequestProPage, path: 'request-pro', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Request Pro"} },
  { component: BookWithProPage, path: 'book-with-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Book With Pro"} },
  { component: ChangeToDoStatePage, path: 'change-to-do-state', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Change To Do State"} },
  { component: ReservationPage, path: 'reservation', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Reservation"} },
  { component: ConfirmBookingPaymentPage, path: 'confirm-booking-payment', canActivate: [DisableMenuGuard], data: { title: "Confirm Booking Payment"} },
  { component: CreateAccountPage, path: 'create-account', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.NORMAL} },
  { component: CreateAccountPage, path: 'create-account/:hkId/:proName/:proTeamName', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.PRIVATE} },
  { component: CreateAccountPage, path: 'create-account/company', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.COMPANY} },
  { component: CreateAccountPage, path: 'create-account/developers', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.DEVELOPER} },
  { component: CreateAccountPage, path: 'create-account/rental', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.RENTAL} },
  { component: CreateAccountPage, path: 'create-account/maintenance', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Create Account", flowType: OnboardingFlow.MAINTENANCE} },
  { component: PastJobPage, path: 'past-job/:cleaningId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Past Job"} },
  { component: EditTipPage, path: 'edit-tip/:cleaningId/:homekeeperJobId', canActivate: [ActivateMenuGuard], data: { title: "New Tip"} },
  { component: PastPrivateJobPage, path: 'past-private-job/:cleaningId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Past Private Job"} },
  { component: DevelopersPage, path: 'more/developers', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Developers"} },
  { component: LogsPage, path: 'logs', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Logs"} },
  { component: logDetailsPage, path: 'log-details', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Log Details"} },
  { component: LoginPage, path: 'login', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Login"} },
  { component: LoginPage, path: 'login/:token', canActivate: [DisableMenuGuard], data: { title: "Login"} },
  { component: LoginPage, path: 'log-in/:hkId/:proName/:proTeamName', canActivate: [AlreadyAuthenticatedGuard], data: { title: "Log In"} },
  { component: ForgotUsernamePage, path: 'forgot-username', canActivate: [DisableMenuGuard], data: { title: "Find Your Account"} },
  { component: ToDosRemoveRoomPage, path: 'remove-room', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Remove Room"} },
  { component: ProDidNotShowUpPage, path: 'pro-did-not-show-up', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Pro Did Not Show Up"} },
  { component: MissingItemPage, path: 'missing-item', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Missing Item"} },
  { component: BillingHistoryPage, path: 'billing-history', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Billing History"} },
  { component: BillingHistoryPage, path: 'more/billing-history', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Billing History", cantGoBack: true} },
  { component: GetRefundPage, path: 'get-refund', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Confirm Refund"} },
  { component: BillingItemHelpPage, path: 'billing-item-help', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Billing Item Help"} },
  { component: CancellationOptionsPage, path: 'cancellation-options', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Cancellation Options"} },
  { component: CancellationOptionsPage, path: 'more/cancellation-options', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Cancellation Options", cantGoBack: true} },
  { component: ProposalsPage, path: 'proposals/:proposalStatus', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Proposals"} },
  { component: ReloadComponent, path: 'reload', canActivate: [ActivateMenuGuard]},
  { component: PendingProposalPage, path: 'proposal/:proposalUuid', canActivate: [ActivateMenuGuard], data: { title: "Proposal"} },
  { component: PendingProposalPage, path: 'pending-proposal/:proposalUuid', canActivate: [DisableMenuGuard], data: { title: "Proposal"} },
  { component: AcceptedProposalPage, path: 'accepted-proposal', canActivate: [DisableMenuGuard], data: { title: "Proposal"} },
  { component: ClientHubPage, path: 'client/:client_hub_uuid', canActivate: [DisableMenuGuard], data: { title: "Client Hub"} },
  { component: LostItemPage, path: 'lost-item/:cleaningId', canActivate: [ActivateMenuGuard], data: { title: "Lost Item"} },
  { component: PasswordRecoveryPage, path: 'password-recovery/:token', canActivate: [DisableMenuGuard], data: { title: "Password Recovery"} },
  { component: PaidInvoicePage, path: 'client-paid-bill/:uuid', canActivate: [DisableMenuGuard], data: { title: "Bill"} },
  { component: PaidInvoicePage, path: 'paid-bill/:invoice_id', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bill"} },
  { component: PendingInvoicePage, path: 'client-pending-bill/:uuid', canActivate: [DisableMenuGuard], data: { title: "Bill"} },
  { component: PendingInvoicePage, path: 'pending-bill/:invoice_id', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bill"} },
  { component: PaidInvoicePage, path: 'bill/:invoice_id', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bill"} },
  { component: PaidInvoicePage, path: 'customer/invoices/:invoice_id', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bill"} },
  { component: ThumbnailInvoicePage, path: 'thumbnail/invoice/:invoiceUuId', canActivate: [DisableMenuGuard], data: { title: "Bill"} },
  { component: ThumbnailProposalPage, path: 'thumbnail/proposal/:proposalUuid', canActivate: [DisableMenuGuard], data: { title: "Proposal"} },
  { component: ChooseAddressToBookPage, path: 'choose-property-to-book', canActivate: [DisableMenuGuard], data: { title: "Choose Property"} },
  { component: LogInToBookPage, path: 'log-in-to-book', canActivate: [DisableMenuGuard], data: { title: "Log In"} },
  { component: CreateAccountToBookPage, path: 'create-account-to-book', canActivate: [DisableMenuGuard], data: { title: "Create Account"} },
  { component: ReportsPage, path: 'reports/:reportId', canActivate: [ActivateMenuGuard], data: { title: "Reports"} },
  { component: ReportsPage, path: 'reports', canActivate: [ActivateMenuGuard], data: { title: "Reports"} },
  { component: LogIssuePage, path: 'log-issue', canActivate: [ActivateMenuGuard], data: { title: "Log Issues"} },
  { component: TasksPage, path: 'tasks/:addressId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Tasks"} },
  { component: TasksPage, path: 'tasks', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Tasks"} },
  { component: TaskPage, path: 'task', canActivate: [ActivateMenuGuard], data: { title: "task"} },
  { component: CreateActionPlanPage, path: 'create-action-plan', canActivate: [ActivateMenuGuard], data: { title: "Create Action Plan"} },
  { component: NotificationDetailPage, path: 'notification-details', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Notification Details"} },
  { component: JobPage, path: 'job/:addressId/:jobId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job"} },
  { component: SelectCategoryPage, path: 'select-category', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Select Category"} },
  { component: JobPage, path: 'job', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job"} },
  { component: JobActivityPage, path: 'job-activity/:addressId/:jobId/:proId/:isPastJob/:isPrivateJob', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Job Activity"} },
  { component: MarkJobCompletePage, path: 'mark-job-complete', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Mark Job Complete"} },
  { component: WhyAddPrivateProsPage, path: 'why-add-private-pros', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Why Add Private Pros?"} },
  { component: CallProPage, path: 'call-pro', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Call Pro"} },
  { component: SendPaymentPage, path: 'send-payment', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Send Payment"} },
  { component: SendPaymentPage, path: 'get-started-send-payment', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Send Payment"} },
  { component: ClientGetStartedPage, path: 'get-started', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Get Started"} },
  { component: ClientGetStartedProAddedPage, path: 'get-started-pro-added', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Pro Added"} },
  { component: InvoiceAutopayPage, path: 'invoice-autopay', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Invoice Autopay"} },
  { component: SendPaymentOrPayInvoicePage, path: 'send-payment-or-pay-invoice', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Send Payment"} },
  { component: MessagesPage, path: 'messages', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Messages"} },
  { component: AccountNotificationSettingsPage, path: 'account-notifications', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Account Notifications"} },
  { component: AccountNotificationSettingsPage, path: 'automations/account-notifications', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Account Notifications"} },
  { component: AddAccountNotificationPage, path: 'add-account-notification', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Account Notification"} },
  { component: BillsPage, path: 'bills', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Bills"} },
  { component: VendorCompliancePage, path: 'vendor-compliance', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Vendor Compliance"} },
  { component: ImportPropertiesCSVPage, path: 'import-properties', canActivate: [DisableMenuGuard], data: { title: "Import Properties"} },
  { component: ImportPropertiesCSVPage, path: 'import-properties-csv', canActivate: [ActivateMenuGuard], data: { title: "Import Properties"} },
  { component: RentalClosingPage, path: 'payment', canActivate: [DisableMenuGuard], data: { title: "Import Properties" } },
  { component: RecurringTaskComponent, path: 'recurring-task', canActivate: [ActivateMenuGuard], data: { title: "Recurring Task" } },
  { component: PastSharedJobPage, path: 'past-shared-job/:uuid', data: { title: "Past Shared Job"} },
  { component: MaintenancePage, path: 'maintenance', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Maintenance"} },
  { component: UpsellPage, path: 'upsell', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Upsell"} },

  //Dashboard
  { component: DashboardPage, path: 'dashboard', canActivate: [ActivateMenuGuard, AuthGuard, AccountTypeGuard], data: { title: "Dashboard"} },
  { component: EditReportColumnPage, path: 'dashboard/edit-report-column', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Column"} },
  { component: AddReportPage, path: 'dashboard/add-report', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Report"} },
  { component: ReportSettingsPage, path: 'dashboard/report-settings/:dashboard_customer_report_id', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Report Settings"} },
  { component: EditReportNamePage, path: 'dashboard/edit-report-name', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Report Name"} },

  // Concierge
  { component: ConciergePage, path: 'concierge', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: ContactConciergePage, path: 'contact-concierge', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: ConciergeMessagePage, path: 'concierge-message/:conciergeItemId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: ConciergeReplyPage, path: 'concierge-reply', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: GetHelpPage, path: 'get-help', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: GetHelpPage, path: 'more/get-help', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge", cantGoBack: true} },
  { component: JobIssuePage, path: 'job-issue', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: JobIssueDetailPage, path: 'job-issue-detail', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: JobIssueNegativeReviewPage, path: 'job-issue-negative-review', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Concierge"} },
  { component: UseToDosPage, path: 'use-to-dos', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "To-Dos"} },
  { component: DamageClaimPage, path: 'damage-claim', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Damage Claim"} },
  { component: OpenDisputePage, path: 'open-dispute', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Open Dispute"} },
  { component: DisputeResolutionPage, path: 'dispute-resolution/:disputeItemId', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Dispute"} },
  { component: ProMessagesPage, path: 'pro-messages/:proId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Pro Messages"} },

  //Genome
  { component: EditAddressPage, path: 'edit-property/:addressId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Property"} },
  { component: PropertyOptionsPage, path: 'access-notes', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Property" } },
  { component: PropertyOptionsPage, path: 'property-options', canActivate: [DisableMenuGuard, AuthGuard], data: { title: "Edit Property"} },
  { component: EditRoomsPage, path: 'edit-property/:addressId/edit-rooms', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Property"} },
  { component: AddRoomPage, path: 'add-new-room', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Room"} },
  { component: EditRoomDetailsPage, path: 'edit-room-details', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Room Details"} },
  { component: AddRoomItemPage, path: 'add-item', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Item"} },
  { component: AddRoomItemPage, path: 'add-item/address', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Add Item"} },
  { component: EditRoomItemPage, path: 'edit-room-item', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Edit Item"} },
  { component: ItemDetailsPage, path: 'item-details', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Item Details"} },
  { component: ItemDetailsPage, path: 'item-details/:itemId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Item Details"} },
  { component: MapsIntroductionPage, path: 'map-introduction', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Map Introduction"} },
  { component: PropertyMappingPage, path: 'map-building', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Map Bulding"} },
  { component: PropertyScanningPage, path: 'property-scanning', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Property Scanning"} },
  { component: ItemMaintenancePage, path: 'item-maintenance', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Item Maintenance"} },
  { component: MaintenancesInstancePage, path: 'item-maintenances', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: " Item Maintenance"} },
  { component: AppBrandingPage, path: 'app-branding', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Your Branding"} },
  { component: InventoryPage, path: 'inventory', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Inventory"} },
  { component: RecurringTasksPage, path: 'recurring-tasks', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Recurring Tasks"} },
  { component: ArchivedTasksPage, path: 'archived-tasks', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Archived Tasks"} },

  //WorkFlows
  { component: WorkflowsPage, path: 'automations/workflows', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflows"} },
  { component: WorkflowPage, path: 'automations/workflow/:workflowId', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflow"} },
  { component: EditWorkflowPage, path: 'automations/workflow/:workflowId/edit', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflow" } },
  { component: AddWorkflowPage, path: 'automations/workflow/:workflowId/start', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflow" } },
  { component: WorkflowHistoryPage, path: 'automations/workflow-history', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflow History"} },
  { component: WorkflowRunPage, path: 'automations/workflow-history/workflow-run', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflow Run" } },
  { component: WorkflowFeatureRequestPage, path: 'automations/feature-request', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Workflows"} },
  { component: AutomationsSummaryPage, path: 'automations/summary', canActivate: [ActivateMenuGuard, AuthGuard], data: { title: "Summary"} },

  //Booking Redirect
  { path: 'booking/:bookingId', canActivate: [BookingRedirectGuard], data: { title: "Booking"} },
  { path: 'authorization/:integration', canActivate: [IntegrationAddedRedirectGuard], data: { title: "Integration Added"} },

  { component: ProxyMessagesPage, path: 'smart-messages', canActivate: [AuthGuard], data: { title: "Smart Messages" } },
  { component: InputToDosPage, path: 'input-to-dos', canActivate: [AuthGuard], data: { title: "Input To-Dos" } },

  { component: IntroductionPage, path: '', pathMatch: 'full', canActivate: [DisableMenuGuard, IntroductionGuard], data: { title: "Introduction"} },

];


import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from "rxjs/operators";

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Menu } from 'src/providers/menu/menu';
import { Workflows } from 'src/providers/workflows/workflows';

import { DateTime as LuxonDateTime } from 'luxon';

import { WorkflowRunPage } from 'src/pages/workflows/workflow-run/workflow-run';

@Component({
  templateUrl: 'workflow-history.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./workflow-history.scss"]
})

export class WorkflowHistoryPage implements OnInit {

  allTriggers: any;
  allActions: any;
  allHistoryLength: any;
  dateRangeForm: UntypedFormGroup;
  errorMessage: string;
  history: any;
  loaded: boolean;
  isRightSideContent: boolean;
  pageNumber: any;
  pageSize: any;
  workflowItems: any;
  workflowsLoaded: boolean;
  searchTerm: string;
  searchForm: UntypedFormGroup;
  selectOptions: any;
  selectedWorkflowIds: any;
  startDate: any;
  endDate: any;
  workflows: any;

  constructor(
    fb: UntypedFormBuilder,
    private menu: Menu,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private workflowsProvider: Workflows,
  ) {
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: ''
    });
    this.searchForm = fb.group({
      search: [''],
    });
    this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => this.updateSearch(value));
  }

  async ngOnInit() {
    try {
      this.loaded = false;
      this.rightSidePanelService.setDialogPageTitle('Workflow');
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.pageNumber = 1;
      this.pageSize = 10;
      this.selectOptions = [
        { viewValue: 'Custom', value: 'custom' },
        { viewValue: 'Today', value: 'today' },
        { viewValue: 'Last Week', value: 'last_week' },
        { viewValue: 'Last Month', value: 'last_month' },
        { viewValue: 'All Time', value: 'all_time' }
      ];
      this.menu.selectedAutomation = 'workflow-history';
      this.allTriggers = await this.workflowsProvider.getWorkflowTriggerTypes();
      this.allActions = await this.workflowsProvider.getWorkflowActionTypes();
      await this.buildWorkflowFilter();
      const today = LuxonDateTime.local();
      this.endDate = today.toISODate();
      this.startDate = today.minus({ days: 7 }).toISODate();
      if (this.workflowItems.length > 0) {
        await this.selectWorkflow(0);
      }
      this.dateRangeForm.patchValue({ customStringSelect: 'last_week' });
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async updateSearch({ search }) {
    if (!search || search.length < 2) {
      this.searchTerm = null;
      return this.getWorkflowsVersionRunHistory();
    }
    this.searchTerm = search.toLowerCase();
    this.getWorkflowsVersionRunHistory();
  }

  async buildWorkflowFilter() {
    this.workflowItems = [];
    this.workflows = await this.workflowsProvider.getWorkflows();
    this.workflows.map((workflow) => {
      this.workflowItems.push({
        viewValue: workflow.name,
        value: workflow.id,
      });
    });
    this.workflowItems.unshift({
      viewValue: 'All Workflows',
      value: 0,
    });
  }

  selectCustomDateRange(selection) {
    this.workflowsLoaded = false;
    const start = new Date(selection.start);
    const end = new Date(selection.end);
    this.startDate = LuxonDateTime.fromJSDate(start).toISODate();
    this.endDate = LuxonDateTime.fromJSDate(end).toISODate();
    this.getWorkflowsVersionRunHistory();
  }

  selectStringDateRange(selection) {
    this.workflowsLoaded = false;
    const today = LuxonDateTime.local();
    this.endDate = today.toISODate();
    if (selection == 'last_week') {
      this.startDate = today.minus({ days: 7 }).toISODate();
    } else if (selection == 'last_month') {
      this.startDate = today.minus({ months: 1 }).toISODate();
    } else if (selection == 'all_time') {
      this.startDate = '2023-8-10';
    } else if (selection == 'today') {
      this.startDate = today.toISODate();
    }
    this.getWorkflowsVersionRunHistory();
  }

  selectPage(page) {
    this.workflowsLoaded = false;
    this.pageNumber = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.getWorkflowsVersionRunHistory();
  }

  async selectWorkflow(workflowIds) {
    try {
      this.workflowsLoaded = false;
      const didSelectAllWorkflows = workflowIds == 0;
      if (didSelectAllWorkflows) {
        workflowIds = this.workflows.map((workflow) => workflow.id);
      } else {
        workflowIds = [workflowIds];
      }
      this.selectedWorkflowIds = workflowIds;
      await this.getWorkflowsVersionRunHistory();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async getWorkflowsVersionRunHistory() {
    const response = await this.workflowsProvider.getWorkflowVersionRuns(this.selectedWorkflowIds, this.startDate, this.endDate, this.pageNumber, this.pageSize, this.searchTerm);
    this.history = response.body;
    this.allHistoryLength = response.totalRecords || 100;
    this.workflowsLoaded = true;
  }

  goToWorkflowRunPage(run) {
    const params = {
      run: run,
      selectedWorkflow: '' //this.selectedWorkflow
    }
    this.goToPage('automations/workflow-history/workflow-run', params, WorkflowRunPage);
  }

  getDate(date) {
    const dt = LuxonDateTime.fromISO(date);
    return dt.toFormat('h:mm a MMM d, yyyy');
  }

  async goToPage(url, params, component) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }

}

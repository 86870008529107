import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Addresses } from "src/providers/customer/addresses";
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';
import { Util } from "src/shared/util/util";

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'edit-prices.html',
  encapsulation: ViewEncapsulation.None
})

export class EditPricesPage extends TimeoutableComponent implements OnInit {

  addressForm: UntypedFormGroup;
  addressId: any;
  addressList: any;
  errorMessage: string;
  form: UntypedFormGroup;
  services: any;
  submitted: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private addresses: Addresses,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService,
  ) {
    super();
    this.form = this.formBuilder.group({});
    this.addressForm = this.formBuilder.group({
      address: ['', Validators.required],
    })
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Prices');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.services = await this.getParam('services');
    this.addressList = await this.getParam('addressList');
    this.addressId = await this.getParam('addressId');
    this.addressForm.patchValue({address: this.addressId});
    this.createFormFields();
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  createFormFields() {
    this.services.forEach(service => {
      if (service.billing_type !== 'price_later') {
        this.form.addControl(service.id.toString(), this.formBuilder.control(service.prices.base_price ? (service.prices.base_price / 100).toString() : '0', Validators.required));
      }
    });
  }

  @Loading('', true)
  async changeProperty() {
    try {
      this.services = await this.addresses.getPriceTable(this.addressForm.value.address);
      Object.keys(this.form.controls).forEach(key => {
        this.form.removeControl(key);
      });
      this.createFormFields();
    } catch (err) {
      const unhandledError = await this.timeoutHandler(err);
      this.errorMessage = unhandledError?.error?.message ?? unhandledError?.message;
    }
  }

  @Loading('', true)
  async saveChanges() {
    this.submitted = true;
    if (!this.form.value) {
      return;
    }
    try {
      const payload = {
        new_prices: []
      }
      Object.keys(this.form.controls).forEach(key => {
        const priceWithoutCommas = this.form.value[key].replace(/,/g, '');
        payload.new_prices.push({
          no_team_address_service_id: key,
          price: (parseInt(priceWithoutCommas.substring(1)) * 100).toString()
        })
      });
      await this.addresses.changePrices(this.addressForm.value.address, payload);
      if (this.windowService.isDesktopRes) {
        this.rightSidePanelService.goBack();
      } else {
        this.navCtrl.navigateBack('my-prices');
      }
    } catch (err) {
      await this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(this.errorMessage, 10000);
    }
  }

}

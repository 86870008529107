import { Component, ViewChild, ElementRef, HostListener, Renderer2, ViewEncapsulation, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalController } from '@ionic/angular';
import { DateTime as LuxonDateTime } from 'luxon';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import * as confetti from 'canvas-confetti';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Router } from '@angular/router';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Events } from 'src/providers/events/events';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { UserService } from 'src/providers/user/user';
import { HttpClientCustom } from 'src/providers/custom/http-client';

import { Loading } from 'src/shared/components/loading/loading';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { BREAK_LARGE_RESOLUTION } from 'android/app/src/constants/app.contants';
import { TasksFilter } from 'src/pages/tasks/tasks-filter/tasks-filter';

import { TaskPage } from 'src/pages/tasks/task/task';
import { CreateActionPlanPage } from './create-action-plan/create-action-plan';

import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  templateUrl: 'tasks.html',
  styleUrls: ['./tasks.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TasksPage implements OnInit {

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @ViewChild('urgencyMenuTrigger') urgencyMenuTrigger: MatMenuTrigger;
  @ViewChild('addressMenuTrigger') addressMenuTrigger: MatMenuTrigger;
  @ViewChild('mobileUserMenuTrigger') mobileUserMenuTrigger: MatMenuTrigger;
  @ViewChild('desktopUserMenuTrigger') desktopUserMenuTrigger: MatMenuTrigger;
  addressResponse: any;
  addressesIndexedById: any;
  addressesMenu: any;
  addressId: any;
  addressFilter: any;
  cards: any;
  categories: any;
  backPage: string;
  dontGoToIssueDetail: boolean;
  errorMessage: string;
  isDragging: boolean;
  issues: any;
  issuesColumns: any;
  issueReportTypes: any;
  isAllAddressesSelected: boolean;
  initAddressFilterValue: any;
  loggedIssuesResponse: any;
  selectedStatus: any;
  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  columns: string[] = ['reported', 'approved', 'in_progress', 'done'];
  readonly columnTitlesByKeys: any = {
    reported: 'Reported',
    approved: 'Approved',
    in_progress: 'In Progress',
    done: 'Done'
  };
  isMobileResolution: boolean;
  selectedFilters: TasksFilter;
  urgencyFilter = [{viewValue: 'All Urgencies', value: -1}, {viewValue: 'Low', value: 'low'}, {viewValue: 'Normal', value: 'normal'}, {viewValue: 'Emergency', value: 'emergency'}];
  typeFilter;
  showLoadingSpinner: boolean;
  selectedUrgency: any;
  selectedType: any;
  rootRef: Promise<ElementRef>;
  panelClosedSubscription: Subscription;
  members: any;
  urgencies: any;
  form: UntypedFormGroup;
  loadingActionSuggestions: boolean;
  conciergeActionSuggestions: { issue_report_id: number, suggestions: any[] };
  loaded: boolean;
  searchTerm: string;
  filteredIssues: any[];
  showOnboarding: boolean;
  hasProperties: boolean;

  constructor(
    private client: Client,
    private currentAddress: CurrentAddress,
    private events: Events,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private reportIssue: ReportIssue,
    private storage: TidyStorage,
    public windowService: WindowService,
    private fb: FormBuilder,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    public httpClient: HttpClientCustom,
    private router: Router,
    private rightSidePanelService: RightSidePanelService,
    private schedule: Schedule,
    private userService: UserService,
    public onboardingProvider: OnboardingProvider
  ) {
    this.form = this.fb.group({
      search: [''],
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isMobileResolution = event.target.innerWidth <= BREAK_LARGE_RESOLUTION;
  }

  async ngOnInit() {
    try {
      await this.loadPage();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.getData();
    this.loaded = true
    /*setTimeout(() => {
      this.loaded = true; //Delay setting loaded to TRUE to ensure cdk-virtual-scroll-viewport works with hundreds of tasks
    }, 100);*/
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/tasks').then(async () => {
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async getData() {
    this.selectedStatus = 'reported';
    this.addressResponse = await this.client.getMoreDetailAddresses();
    this.addressId = await this.client.getSelectedAddressId(this.addressResponse);
    this.backPage = localStorage.getItem('loggedIssuesBackPage');
    this.categories = await this.pros.getServiceCategories();
    this.reportIssue.getIssueReportTypes().then((res) => {
      this.issueReportTypes = res;
      this.typeFilter = res.map((type) => {
        return {
          viewValue: type.name,
          value: type.id
        }
      });
    });
    this.addressFilter = await this.navCtrl.getParam('addressFilter') || await this.buildAddressFilter();
    this.urgencies = this.getUrgencies();
    this.isAllAddressesSelected = localStorage.getItem('isAllAddressesSelected') == 'true';
    await this.getLoggedIssues();
    await this.parseUsersAndJobs();
  }

  getUrgencies() {
    return [
      {
        value: 'low', 
        viewValue: 'Low', 
        icon: 'assets/img/low_priority.svg'
      },
      {
        value: 'normal', 
        viewValue: 'Normal', 
        icon: 'assets/img/normal_priority.svg'
      },
      {
        value: 'high', 
        viewValue: 'High',
        icon: 'assets/img/high_priority.svg'
      },
      {
        value: 'emergency', 
        viewValue: 'Emergency',
        icon: 'assets/img/emergency_priority.svg'
      }
    ];
  }

  async parseUsersAndJobs() {
    let allUsersResponse = await this.userService.getUsersList();
    let allUsers = [];
    allUsersResponse.map((user) => {
      allUsers.push({
        name: user.first_name + ' ' + user.last_name,
        id: user.id
      });
    });
    this.storage.save('allUsers', allUsers);
    this.columns.forEach((column) => {
      if (!Array.isArray(this.issuesColumns[column])) {
        return;
      }
      this.issuesColumns[column].forEach((issue) => {
        issue['checkedJobs'] = this.getCheckedJobs(issue);
        issue['allUsers'] = [];
        allUsers.map((user) => {
          const addedUser = issue.members.find((member) => member.id == user.id);
          let userObject = {
            checked: !!addedUser,
            name: user.name,
            id: user.id
          }
          if (addedUser) {
            userObject['attachmentId'] = addedUser.attachment_id;
          }
          issue['allUsers'].push(userObject);
        });
        issue['allUsers'].unshift({
          name: 'Concierge',
          id: 0,
          checked: issue.assigned_to_concierge
        });
        issue['checkedUsers'] = issue['allUsers'].filter((user) => user.checked);
      });
    });
  }

  async checkOrUncheckPerson(task, user, event) {
    if (this.windowService.isDesktopRes) {
      this.desktopUserMenuTrigger.closeMenu();
    } else {
      this.mobileUserMenuTrigger.closeMenu();
    }
    if (user.name == 'Concierge') {
      if (!event.checked) {
        this.reportIssue.updateIssueReport(task.id, {assigned_to_concierge: false});
      } else {
        this.goToCreateActionPlan(task);
      }
    } else {
      if (event.checked) {
        const payload = {
          issue_report_id: task.id,
          attachable_id: user.id,
          attachable_type: 'CustomerMember'
        }
        const attachmentResponse = await this.reportIssue.addIssueReportAttachment(payload);
        user['attachmentId'] = attachmentResponse.attachment_id;
      } else {
        await this.reportIssue.deleteIssueReportAttachment(user['attachmentId']);
      }
    }
    const userIndex = task['checkedUsers'].findIndex((checkedUser) => checkedUser.id == user.id);
    if (userIndex !== -1) {
      task['checkedUsers'].splice(userIndex, 1);
    } else {
      let checkedUser = {
        id: user.id,
        name: user.name,
        checked: event.checked
      }
      task['checkedUsers'].push(checkedUser);
    }
    this.storage.delete('tasksLastSavedAt');
  }

  getCheckedJobs(issue) {
    let checkedJobs = [];
    issue.bookings.map((booking) => {
      let jobObject = {
        ...booking,
        id: booking?.id,
        job_id: booking?.job_data?.id,
        is_private: booking?.job_data?.is_private,
        service_name: booking.job_data.service_name,
        name: LuxonDateTime.fromISO(booking.job_data.start_datetime_local).toFormat('M/d h:mma').toLowerCase(),
        checked: true,
        icon_url: booking.job_data.service_icon_url,
        status: this.reportIssue.getJobStatusData(booking.job_data.homekeeper_jobs, booking.job_data.job_progress),
        attachmentId: booking.attachment_id,
        start_datetime_local: booking.start_datetime_local 
      }
      checkedJobs.push((jobObject));
    });
    return checkedJobs;
  }

  @Loading('', true)
  async showJobMenu(event, issue) {
    event.stopPropagation();
    if (!issue['allJobs']) {
      const cards = await this.schedule.getCards(issue.address_id);
      const jobCards = cards.filter((card) => card?.template_data?.job);
      const timezone = this.getTimezoneForAddress(issue.address_id)
      issue['allJobs'] = jobCards.map((job) => {
        const addedJob = issue.bookings.find((booking) => booking.id == job?.template_data?.booking?.id);
        const dateTime = LuxonDateTime.fromISO(job?.template_data?.job?.start_datetime_local);
        const formattedTime = dateTime.setZone(timezone).toFormat('M/d/yy h:mma').toLowerCase();
        return {
          id: job?.template_data?.booking?.id,
          job_id: job?.template_data?.job?.id,
          is_private: job?.template_data?.job?.is_private,
          service_name: job?.template_data?.job?.service_type_details?.name,
          name: formattedTime + ' ' + job.template_data.booking.bookable_service.name,
          status: null,
          start_datetime_local: job?.template_data?.job?.start_datetime_local,
          checked: !!addedJob,
          attachmentId: addedJob ? addedJob.attachment_id : null,
          icon_url: job?.template_data?.job?.service_type_details?.service_category?.icon_url
        };
      });
      issue.bookings.map((booking) => {
        const job = issue['allJobs'].find((job) => job.id == booking?.id);
        const jobIsScheduled = !!job;
        if (!jobIsScheduled) {
          let checkedJob = issue['checkedJobs'].find((job) => job.id == booking?.id);
          checkedJob['isPastJob'] = true;
          const dateTime = LuxonDateTime.fromISO(booking.job_data.start_datetime_local);
          const formattedTime = dateTime.setZone(timezone).toFormat('M/d h:mma').toLowerCase();
          issue['allJobs'].unshift(({
            isPastJob: true,
            id: booking?.id,
            job_id: booking?.job_data?.id,
            is_private: booking?.job_data?.is_private,
            service_name: booking.job_data.service_name,
            name: formattedTime,
            checked: true,
            icon_url: booking.job_data.service_icon_url,
            status: this.reportIssue.getJobStatusData(booking.job_data.homekeeper_jobs, booking.job_data.job_progress),
            attachmentId: booking.attachment_id,
            start_datetime_local: booking.start_datetime_local 
          }));
        }
      });
    }
  }

  getTimezoneForAddress(addressId) {
    const address = this.addressResponse.find((address) => address.id == addressId);
    return address.timezone;
  }

  async checkOrUncheckJob(task, job, event) {
    if (event.checked) {
      const payload = {
        issue_report_id: task.id,
        attachable_id: job.id,
        attachable_type: 'Booking'
      }
      const attachmentResponse = await this.reportIssue.addIssueReportAttachment(payload);
      job['attachmentId'] = attachmentResponse.attachment_id;
      job['status'] = this.reportIssue.getJobStatusData(attachmentResponse.job_data.homekeeper_jobs, attachmentResponse.job_data.job_progress);
    } else {
      await this.reportIssue.deleteIssueReportAttachment(job['attachmentId']);
    }
    const jobIndex = task['checkedJobs'].findIndex((checkedJob) => checkedJob.id == job.id);
    if (jobIndex !== -1) {
      task['checkedJobs'].splice(jobIndex, 1);
    } else {
      let checkedJob = {
        ...job
      }
      task['checkedJobs'].push(checkedJob);
    }
    this.storage.delete('tasksLastSavedAt');
  }

  selectUrgency(task, urgency) {
    this.urgencyMenuTrigger.closeMenu();
    task['urgency'] = urgency.value;
    this.updateTask(task);
  }

  async selectAddress(task, address) {
    this.addressMenuTrigger.closeMenu();
    if (task.address_id !== address.value && task.checkedJobs.length > 0) {
      await Promise.all(task.checkedJobs.map(async (job) => {
        if (job['attachmentId']) {
          await this.reportIssue.deleteIssueReportAttachment(job['attachmentId']);
        }
      }));
    }
    task['allJobs'] = null;
    task['checkedJobs'] = [];
    task['address_id'] = address.value;
    this.updateTask(task);
  }

  @Loading('', true)
  async updateTask(task) {
    const payload = {
      title: task.title,
      description: task.description,
      urgency: task.urgency,
      address_id: task.address_id,
      status: task.status,
      issue_report_type_id: task.issue_report_type.id
    }
    task = await this.reportIssue.updateIssueReport(task.id, payload);
    this.storage.delete('tasksLastSavedAt');
  }

  async buildAddressFilter() {
    this.addressResponse = await this.client.getAddresses();
    this.storage.save('addressResponse', this.addressResponse);
    this.addressesIndexedById = this.addressResponse?.reduce((acc, address) => {
      acc[address.id] = address;
      return acc;
    }, {});
    this.storage.save('addressesIndexedById', this.addressesIndexedById)
    return this.client.parseAddressList(this.addressResponse, false);
  }

  async getLoggedIssues() {
    try {
      this.loggedIssuesResponse = (await this.reportIssue.getIssueReports()).sort((a, b) => a.display_order - b.display_order);
      this.loggedIssuesResponse = this.loggedIssuesResponse.filter((task) => !task.archived_at);
      this.onFilterChanges({
        selectedFilters: this.selectedFilters,
        lastSelectedFilter: null
      });
    } catch (err) {
      console.error('err', err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  loadIssueColumns(search = '', filteredIssues = this.loggedIssuesResponse) {
    search = search.toLowerCase()
    if (search.length > 0) {
      filteredIssues = filteredIssues.filter((issue) => {
        return issue.description.toLowerCase().includes(search) ||
               issue.issue_report_type.name.toLowerCase().includes(search) ||
               issue.title.toLowerCase().includes(search);
      });
    }
    const issueCards = filteredIssues.slice(0, 30);
    this.issuesColumns = {
      reported: [],
      approved: [],
      in_progress: [],
      done: []
    };
    this.columns.forEach((column) => {
      this.issuesColumns[column] = issueCards.filter((issue) => issue.status == column).sort((a, b) => a.display_order - b.display_order);
    });
    setTimeout(() => {
      filteredIssues.slice(30).forEach((issue) => {
        this.issuesColumns[issue.status].push(issue);
      });
    }, 100);
    let showTestData = false;
    this.issuesColumns.reported.map((issue) => {
      if (issue.description == 'test123') {
        localStorage.setItem('showTestData', 'true');
        localStorage.setItem('taskIndex', '0');
        showTestData = true;
      }
    });
    if (!showTestData) {
      localStorage.setItem('showTestData', 'false');
    }
  }

  logIssue() {
    const params: any = {
      addressFilter: this.addressFilter
    }
    if (this.addressId !== -1 && !this.isAllAddressesSelected) {
      params['addressId'] = this.addressId;
    }
    this.navCtrl.navigateForward('log-issue', params);
  }

  updatedStatusFilter(status) {
    this.selectedStatus = status;
    this.issues = this.loggedIssuesResponse.filter((item) => item.status == status);
  }

  async markIssueAsResolved(issue) {
    const params: ConfirmPageParamsModel = {
      title: 'Mark Resolved?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Resolve',
      confirmAction: this.confirmMarkIssueResolved.bind(this),
      confirmActionParams: issue
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmMarkIssueResolved(issue) {
    try {
      await this.reportIssue.resolveReportedIssue(issue?.id);
      await this.getLoggedIssues();
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async deleteIssue(issue) {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Issue?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete',
      confirmAction: this.confirmDeleteIssue.bind(this),
      confirmActionParams: issue
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  getUserInitials(user) {
    return user.name.split(' ').map(part => part[0]).join('').toUpperCase();
  }

  async goToTask(issue) {
    try {
      if (this.dontGoToIssueDetail) {
        return;
      }
      this.checkIfCheckedJobsInPast(issue);
      const params = {
        issue,
        addressResponse: this.addressResponse,
        issueReportTypes: this.issueReportTypes,
        categories: this.categories
      }
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      if (this.windowService.isDesktopRes) {
        this.rightSidePanelService.openRightSidePanel(TaskPage);
        this.rightSidePanelService.setDialogPageTitle('Task');
        this.panelClosedSubscription?.unsubscribe();
        this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
          const tasksLastSavedAt = await this.storage.retrieve('tasksLastSavedAt');
          if (!tasksLastSavedAt) {
            this.getData();
          }
        });
      } else {
        this.navCtrl.navigateForward('task')
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async goToCreateActionPlan(task) {
    try {
      const params = {
        issue: task,
        issueAddress: this.addressesIndexedById[task.address_id],
      }
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      if (this.windowService.isDesktopRes) {
        this.rightSidePanelService.openRightSidePanel(CreateActionPlanPage);
        this.panelClosedSubscription?.unsubscribe();
        this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(() => {
          this.getData();
        });
      } else {
        this.navCtrl.navigateForward('create-action-plan', params)
      }
    } catch (err) {
    }
  }

  checkIfCheckedJobsInPast(issue) {
    issue?.bookings?.map((booking) => {
      const job = issue['allJobs']?.find((job) => job?.id == booking?.id);
      const jobIsScheduled = !!job;
      if (!jobIsScheduled) {
        let checkedJob = issue['checkedJobs']?.find((job) => job?.id == booking?.id);
        checkedJob['isPastJob'] = true;
      }
    });
  }

  async confirmDeleteIssue(issue) {
    try {
      await this.reportIssue.deleteReportedIssue(issue?.id);
      await this.getLoggedIssues();
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  getIssueHeaderIcon(issue) {
    if (!issue) return '';
    const icons = {
      'low': 'assets/img/low_priority.svg',
      'normal': 'assets/img/normal_priority.svg',
      'high': 'assets/img/high_priority.svg',
      'emergency': 'assets/img/emergency_priority.svg'
    };
    return icons[issue.urgency];
  }

  @Loading('', true)
  async changeAddress(selectedAddressId) {
    this.addressId = selectedAddressId;
    if (selectedAddressId == 0) {
      return this.navCtrl.navigateForward('add-property');
    }
    try {
      if (selectedAddressId !== -1) {
        this.isAllAddressesSelected = true;
        this.currentAddress.addressId = selectedAddressId.toString();
      }
      await this.getLoggedIssues();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async changeUrgency(selectedUrgency) {
    this.selectedUrgency = selectedUrgency;
    await this.getLoggedIssues();
  }

  getRelativeDateFromISO(date, addressId) {
    const address = this.addressResponse?.find((address) => address?.id == addressId);
    if (!address) return '';
    const relative = LuxonDateTime.fromISO(date, {zone: 'utc'}).setZone(address?.timezone).toRelative();
    if (!relative) return '';
    const matches = relative.match(/^(\d+)\s+(\w+)\s+ago$/);
    if (matches) {
      const [_, number, unit] = matches;
      return `${number} ${new TranslationPipe().transform(unit)} ${new TranslationPipe().transform('ago')}`;
    }
    return relative;
  }

  getAddressName(task) {
    if (!task.address_id) return '';
    const address = this.addressesIndexedById[task.address_id];
    let addressName = address?.address_name || `${address?.address1}${address?.address2 ? ', ' + address.address2 : ''}`;

    const isDesktopRes = this.windowService.windowSize > 1535;
    if (isDesktopRes) {
      return addressName.length > 20 ? addressName.slice(0, 20) + '..' : addressName;
    }

    let maxLength;
    if (task.checkedJobs?.length > 1) {
      maxLength = 10;
    } else if (task.checkedJobs?.length === 1) {
      maxLength = 12;
    } else {
      maxLength = 20;
    }

    return addressName.length > maxLength ? addressName.slice(0, maxLength) + '..' : addressName;
  }

  goToAddressPage(addressId) {
    localStorage.setItem('addressId', addressId);
    this.storage.save('rootSection', '/more');
    this.events.publish('updateMenu');
    this.navCtrl.navigateForward(`edit-property/${addressId}`);
  }

  onFilterChanges(value) {
    if (!value.selectedFilters) {
      this.selectedFilters = {
        propertyName: [],
        type: [],
        urgency: [],
        address: []
      };
      this.loadIssueColumns(this.searchTerm, this.loggedIssuesResponse);
      return;
    }
    const hasNoFilter = !value.selectedFilters.propertyName.length && !value.selectedFilters.type.length && !value.selectedFilters.urgency.length;
    this.selectedFilters = value.selectedFilters;
    let filteredIssues = [];
    if (!hasNoFilter) {
      this.loggedIssuesResponse.filter((task) => {
        let isCorrectAddress = this.selectedFilters.propertyName.length == 0;
        let isCorrectType = this.selectedFilters.type.length == 0;
        let isCorrectUrgency = this.selectedFilters.urgency.length == 0;
        this.selectedFilters.propertyName.map((addressId) => {
          if (task.address_id == addressId) {
            isCorrectAddress = true;
          }
        });
        this.selectedFilters.type.map((typeId) => {
          if (task.issue_report_type.id == typeId) {
            isCorrectType = true;
          }
        });
        this.selectedFilters.urgency.map((urgency) => {
          if (task.urgency == urgency) {
            isCorrectUrgency = true;
          }
        });
        if (isCorrectAddress && isCorrectType && isCorrectUrgency) {
          filteredIssues.push(task);
        }
      });
    } else {
      filteredIssues = this.loggedIssuesResponse;
    }
    this.filteredIssues = filteredIssues;
    this.loadIssueColumns(this.searchTerm, filteredIssues);
  }

  onSearchChanges(value) {
    this.searchTerm = value.search;
    this.loadIssueColumns(this.searchTerm, this.filteredIssues);
  }

  async addIssueReport() {
    const params = {
      addressResponse: this.addressResponse,
      issueReportTypes: this.issueReportTypes
    }
    this.storage.save('dialog-right-side-open', true);
    this.storage.save('dialog-params', params);
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.openRightSidePanel(TaskPage);
    this.rightSidePanelService.setDialogPageTitle('Add Task');
    this.panelClosedSubscription?.unsubscribe();
    this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
      const tasksLastSavedAt = await this.storage.retrieve('tasksLastSavedAt');
      if (!tasksLastSavedAt) {
        this.getData();
      }
    });
    } else {
      this.navCtrl.navigateForward('task')
    }
  }

  async dropped(dropped: CdkDragDrop<any>) {
    const issueData = dropped.item.data;
    const newStatus = dropped.container.id;
    const srcColumn = this.issuesColumns[dropped.item.data.status]
    const targetColumn = this.issuesColumns[newStatus];
    let newOrder = targetColumn[dropped.currentIndex]?.display_order
    if (targetColumn == srcColumn) {
      newOrder += (dropped.currentIndex < dropped.previousIndex ? -1 : 1);
    }
    if (!newOrder && newOrder !== 0) {
      newOrder = targetColumn[targetColumn?.length - 1]?.display_order + 1;
    }
    let indexOf = srcColumn.findIndex((issue) => issue.id === issueData.id);
    const removedItem = srcColumn.splice(indexOf, 1);
    targetColumn.splice(dropped.currentIndex, 0, removedItem[0]);
    try {
      const payload = {
        status: newStatus,
        display_order: newOrder
      }
      await this.updateIssueStatus(issueData.id, payload);
      issueData.status = newStatus;
      if(newStatus === 'done') {
        setTimeout(() => this.throwConfetti(issueData.id), 100);
      }
    } catch (err) {
      // Revert the changes
      indexOf = targetColumn.findIndex((issue) => issue.id === issueData.id);
      const movedItem = targetColumn.splice(indexOf, 1);
      srcColumn.push(movedItem[0]);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.storage.delete('tasksLastSavedAt');
    this.issuesColumns[dropped.previousContainer.id] = [...this.issuesColumns[dropped.previousContainer.id]];
    this.issuesColumns[dropped.container.id] = [...this.issuesColumns[dropped.container.id]];
  }

  async updateIssueStatus(issueId, payload) {
    await this.reportIssue.updateIssueReport(issueId, payload);
    this.storage.delete('tasksLastSavedAt');
  }

  async throwConfetti(issueiD) {
    const element = this.elementRef.nativeElement.querySelector(`#issue-card-${issueiD}`);
    const rect = element.getBoundingClientRect();
    const xPercent = ((rect.right - 200 ) / window.innerWidth);
    const yPercent = (rect.top / window.innerHeight);
    const canvas = this.renderer2.createElement('canvas');
    canvas.addEventListener('click', () => canvas.style.display = 'none');
    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);
    const confettiAnimation = confetti.create(canvas, { resize: true });
    await confettiAnimation({
      particleCount: 25,
      origin: { x: xPercent, y: yPercent },
    })
    this.renderer2.removeChild(this.elementRef.nativeElement, canvas);
  }

  getUserIcon(assignedUsers) {
    if (!assignedUsers?.length) {
      return '';
    }
    const conciergeUser = assignedUsers.find((user) => user.id == 0);
    if (conciergeUser) {
      return 'concierge';
    } else {
      return 'user';
    }
  }

  getIssueDueDate(dueDate) {
    return LuxonDateTime.fromISO(dueDate).toFormat('M/d/yyyy');
  }

  isDueDateInPast(dueDate) {
    const now = LuxonDateTime.local();
    return LuxonDateTime.fromISO(dueDate) < now;
  }

  trackByFn(index, item) {
    return item.id; // or any unique property
  }
  
  trackByIssue(index, issue) {
    return issue.id; // ensures issues are only re-rendered if their id changes
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Job Issue'"
    [canGoBack]="true"
    [customBack]="'job-issue-detail'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card *ngIf="loaded">
      <tidy-row>
        <tidy-text
          [body]="'This job on'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="(job?.job?.date | parseDate: 'ddd') +
          ' ' +
          (job?.job?.date | parseDate: 'M/D')">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'at'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="(job?.job?.start_time | parseDate: 'h:mma')">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'was marked as complete by the pro'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="job?.homekeepers[0].first_name">
        </tidy-text>
        <tidy-text
          [body]="'. What is your issue?'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'You left them a negative review. ' +
          negativeReviewSentence +
          'What is your issue?'">
        </tidy-text>
      </tidy-row>
      <tidy-card-button (action)="selectIssue('negative_review.additional_protection', 'Additional Protection Purchased')">
        <tidy-row>
          <tidy-text
            [body]="'I purchased additional protection, and this was not reflected.'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="advancedDispute('I\'m Not Satisfied')">
        <tidy-row>
          <tidy-text
            [body]="'I\’m not satisfied and want to dispute this directly with ' + job?.homekeepers[0].first_name + '.'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


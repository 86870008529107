import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Auth } from '../auth/auth';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';

@Injectable({
  providedIn: 'root'
})
export class AlreadyAuthenticatedGuard implements CanActivate {
  constructor(
    private auth: Auth,
    private events: Events,
    private navCtrl: CustomNavController
  ) {}

  async canActivate() {
    if (this.auth.isAuthenticated()) {
      this.events.publish('menu:open');
      this.navCtrl.navigateRoot('');
      return false;
    }
    this.events.publish('menu:close');
    return true;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Default To-Do Lists'"
    [canGoBack]="true"
    [customBack]="'to-dos'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Set your default To-Do lists. First we use property service default, then account service default, then property default, then account default.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-filter
          [initValue]="selectedAddress?.value"
          [items]="addressItems"
          [fitContent]="true"
          (optionChange)="changeAddress($event)">
        </tidy-filter>
      </tidy-row>

      <tidy-row>
        <tidy-filter
          [initValue]="serviceItems[0].value"
          [items]="serviceItems"
          [fitContent]="true"
          (optionChange)="changeSelectedService($event)">
        </tidy-filter>
      </tidy-row>

      <tidy-loader *ngIf="isLoadingLists"></tidy-loader>

      <ng-container *ngIf="!isLoadingLists">

        <ng-container *ngIf="!toDoState">
          <tidy-alert>
            <tidy-text
              [body]="'You have not set up To-Dos for this property yet.'">
            </tidy-text>
          </tidy-alert>
          <tidy-button
            [text]="'Set Up To-Dos'"
            class="primary"
            [action]="goToToDosPage.bind(this)">
          </tidy-button>
        </ng-container>

        <tidy-row [align]="'center'" *ngIf="toDoState">
          <tidy-checkbox
            (checkedChange)="updatePropertyToDoState()"
            [items]="[
              {
                viewValue: ('Use To-Dos at ' | translate) + selectedAddress.viewValue, value: 'active'
              }
            ]"
            [initValue]="toDoState == 'active'">
          </tidy-checkbox>
        </tidy-row>

        <ng-container *ngIf="toDoState == 'inactive'">
          <tidy-alert>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/block.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Not Using To-Dos'">
              </tidy-text>
            </tidy-row>
            <tidy-text
              [body]="'You are not using To-Dos by default at this property. You can still use To-Dos for a job - just tap To-Dos on the job in your Schedule.'">
            </tidy-text>
          </tidy-alert>
        </ng-container>

        <ng-container *ngIf="toDoState == 'active'">
          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="selectedService?.service_type?.icon_url"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Property Service Default'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <form [formGroup]="form">
                <tidy-select
                  [label]="('Default to-do list for ' | translate) + selectedService.name"
                  [items]="addressToDoListItems"
                  [icon]="'assets/svg/list-circle-outline.svg'"
                  formControlName="addressServiceDefaultList"
                  [form]="form.controls.addressServiceDefaultList"
                  (optionChange)="updateAddressServiceDefaultList($event)">
                </tidy-select>
              </form>
            </tidy-row>
          </tidy-card>

          <tidy-row [align]="'center'">
            <tidy-image
              [src]="'assets/img/big-down-arrow.svg'"
              class="title-size">
            </tidy-image>
          </tidy-row>

          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="selectedService?.service_type?.icon_url"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Account Service Default'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="allToDoListItems?.length > 0">
              <tidy-row>
                <form [formGroup]="form">
                  <tidy-select
                    [label]="'Default list for ' + selectedService.name"
                    [items]="allToDoListItems"
                    [icon]="'assets/svg/list-circle-outline.svg'"
                    formControlName="accountServiceDefaultList"
                    [form]="form.controls.accountServiceDefaultList"
                    (optionChange)="updateAccountServiceDefaultList($event)">
                  </tidy-select>
                </form>
              </tidy-row>
              <tidy-row class="extra-top-padding" [align]="'center'">
                <tidy-text
                  [helper]="'Only To-Do lists with no rooms specific to a property can be made an account service default.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="allToDoListItems?.length == 0">
              <tidy-row class="extra-top-padding" [align]="'center'">
                <tidy-text
                  [body]="'All your To-Do lists have rooms specific to a property so cannot be made an account service default. Create a To-Do list with no property specific rooms to set an account service default.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card>

          <tidy-row [align]="'center'">
            <tidy-image
              [src]="'assets/img/big-down-arrow.svg'"
              class="title-size">
            </tidy-image>
          </tidy-row>

          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/img/home.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Property Default'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <form [formGroup]="form">
                <tidy-select
                  [label]="'Default to-do list for ' + selectedAddress.viewValue"
                  [items]="addressToDoListItems"
                  [icon]="'assets/svg/list-circle-outline.svg'"
                  formControlName="addressDefaultList"
                  [form]="form.controls.addressDefaultList"
                  (optionChange)="updateAddressDefaultList($event)">
                </tidy-select>
               </form>
            </tidy-row>
          </tidy-card>

          <tidy-row [align]="'center'">
            <tidy-image
              [src]="'assets/img/big-down-arrow.svg'"
              class="title-size">
            </tidy-image>
          </tidy-row>

          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image
                [src]="'assets/img/account.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Account Default'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="allToDoListItems?.length > 0">
              <tidy-row>
                <form [formGroup]="form">
                  <tidy-select
                    [label]="'Default list'"
                    [items]="allToDoListItems"
                    [icon]="'assets/svg/list-circle-outline.svg'"
                    formControlName="accountDefaultList"
                    [form]="form.controls.accountDefaultList"
                    (optionChange)="updateAccountDefaultList($event)">
                  </tidy-select>
                </form>
              </tidy-row>
              <tidy-row class="extra-top-padding" [align]="'center'">
                <tidy-text
                  [helper]="'Only To-Do lists with no rooms specific to a property can be made an account default.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="allToDoListItems?.length == 0">
              <tidy-row class="extra-top-padding" [align]="'center'">
                <tidy-text
                  [body]="'All your To-Do lists have rooms specific to a property so cannot be made an account default. Create a To-Do list with no property specific rooms to set an account default.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </tidy-card>

          <tidy-row [align]="'center'" *ngIf="isRentalClient">
            <tidy-text
              [body]="'Pro service defaults are in beta. If you want to use defaults for a service unique to your pro or have Concierge add To-Do lists for you please'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'Contact Concierge'"
              class="link"
              (action)="goToConcierge()">
            </tidy-text>
          </tidy-row>
        </ng-container>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <ion-skeleton-text animated="true" style="height: 1000px"></ion-skeleton-text>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


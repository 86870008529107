import { Util } from 'src/shared/util/util';
import { Component, OnInit } from '@angular/core';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { BookJob } from 'src/providers/book-job/book-job';
@Component({
  selector: 'confirm-request-page',
  styleUrls: ['./confirm-request.scss'],
  templateUrl: './confirm-request.html',
})
export class ConfirmRequestPage implements OnInit {
  address: any;
  displayPrice: any;
  flowType: string;
  jobId: any;
  loaded: boolean;
  bookingType;
  viewData;
  errorMessage;
  params: any;
  proName: string;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private bookJob: BookJob,
    private util: Util
  ) {}

  async ngOnInit() {
    try {
      this.isRightSideContent =
        (await this.storage.retrieve('dialog-right-side-open')) || false;
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.loadDataAndParams();
      this.loaded = true;
    } catch (error) {
      console.error(error);
    }
  }

  async loadDataAndParams() {
    this.viewData = await this.getParam('viewData');
    this.params = this.dialogParams || (await this.navCtrl.getParams());
    this.address = this.params?.address || this.params?.bookingDetail?.address || this.params?.cardDetail?.template_data?.booking?.address;
  }

  goBack() {
    if (this.isRightSideContent) {
      this.rightSidePanelService.goBack();
      return;
    }
    const customBack = this.navCtrl.getParam('customBack', false);
    const redirectPage = customBack ? customBack : 'book-job';
    this.navCtrl.navigateBack(redirectPage);
  }

  async confirmBooking() {
    const loading = await this.util.showLoading();
    try {
      const params = {
        booking_id: this.viewData.booking_id,
        homekeeper_id: this.viewData.homekeeper_id,
        amount: this.viewData.price,
        date: this.viewData.date,
        time: this.viewData.time,
        instant_booking: this.viewData?.instant_booking === null ? true : this.viewData?.instant_booking,
      };
      await this.bookJob.acceptBid(params);
      loading.dismiss();
      if (this.isRightSideContent) {
        this.rightSidePanelService.goBackInLayers(2);
      } else {
        this.navCtrl.navigateRoot('schedule');
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.error?.message || error?.err?.error?.message || 'Something went wrong';
      this.util.showError(errorMessage);
      loading.dismiss();
    }
  }

  async getParam(param) {
    const navParam = this.navCtrl.getParam(param, false);
    if (navParam) {
      return navParam;
    } else {
      const storedParam =
        this.dialogParams ||
        (await this.storage.retrieve('confirmBookingPaymentParams'));
      return storedParam?.[param];
    }
  }
}

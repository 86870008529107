import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Webhooks } from 'src/providers/webhooks/webhooks';

import { ClientModel, ClientSettingsModel } from 'src/models/client.model';
import { ScheduleAssuranceModel } from 'src/models/schedule-assurance.model';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'account-settings.html'
})
export class AccountSettingsPage implements OnInit {

  clientInfo: ClientModel;
  errorMessage: string;
  settings: ClientSettingsModel;
  scheduleAssurance: ScheduleAssuranceModel;
  cantGoBack: boolean;
  accountType: string;
  hasBackupTimes: boolean;
  language: any;
  notificationSettings: any;
  userRole: any;
  webhooks: any;
  loaded: boolean;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private webhooksService: Webhooks
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      const didUpdateLanguage = localStorage.getItem('didUpdateLanguage') == 'true';
      if (didUpdateLanguage) {
        localStorage.removeItem('didUpdateLanguage');
        window.location.reload();
      }
      this.language = this.getLanguage();
      const shouldReloadLocation = localStorage.getItem('shouldReloadLocation') == 'true';
      if (shouldReloadLocation) {
        localStorage.setItem('shouldReloadLocation', 'false');
        location.reload();
      }
      this.userRole = localStorage.getItem('user_role');
      this.cantGoBack = this.route.snapshot.data.cantGoBack;
      this.clientInfo = await this.client.getClientInfo();
      this.settings = await this.client.getClientSettings();
      if (this.userRole !== 'member') {
        const withUrl = false;
        this.webhooks = await this.webhooksService.getWebhooks();
        this.webhooks = this.webhooks.filter((webhook) => !webhook?.customer_account_team);
        this.notificationSettings = await this.webhooksService.buildNotificationSettingsArray(this.webhooks, withUrl);
        this.scheduleAssurance = await this.client.getScheduleAssurance();
        this.checkForBackupTimes();
      }
      this.accountType = this.getAccountType();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getLanguage() {
    const language = localStorage.getItem('language') || 'en';
    const languages = {
      'en': 'English',
      'pt': 'Portuguese',
      'es': 'Spanish',
      'ru': 'Russian',
      'fr': 'French',
      'de': 'Dutch',
    }
    return languages[language];
  }

  getAccountType() {
    const types = {
      regular: 'Residential',
      realtor: 'Realtor',
      rental: 'Rental',
      company: 'Office',
    };
    return types[this.clientInfo.customer_account.account_type];
  }

  goToEditAccountType() {
    const params = {
      settings: this.settings
    };
    this.navCtrl.navigateForward('account-type', params);
  }

  goToEditContactInfo() {
    const params = {
      settings: this.settings
    };
    this.navCtrl.navigateForward('contact-info', params);
  }

  goToEditBackupTimes() {
    this.navCtrl.navigateForward('backup-times');
  }

  checkForBackupTimes() {
    this.scheduleAssurance.customerAddress.times.forEach((time) => {
      if (time.start_time !== null) {
        return this.hasBackupTimes = true;
      }
    })
  }

  goToAccountNotificationsPage() {
    const params = {
      webhooks: this.webhooks
    }
    this.navCtrl.navigateForward('account-notifications', params);
  }

  goToEditLanguage() {
    this.navCtrl.navigateForward('edit-language');
  }

}

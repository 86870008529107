import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Dashboard } from 'src/providers/dashboard/dashboard';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Menu } from 'src/providers/menu/menu';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: './add-report.html',
})

export class AddReportPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  loaded: boolean;
  reportTypes: any;
  reportTypeItems: TidySelectNumberValueModel[];
  submitted: boolean;

  constructor(
    private dashboard: Dashboard,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private menu: Menu,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      reportType: ['', Validators.required],
      reportName: ['', Validators.required],
    });
  }

  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Add Report');
      const reports = await this.dashboard.getDashboardSectionItems();
      const hasOverviewReport = this.checkIfHasOverviewReport(reports);
      this.reportTypes = await this.dashboard.getDashboardReportTypeTemplates();
      this.reportTypeItems = this.buildReportTypeItems(hasOverviewReport);
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  checkIfHasOverviewReport(reports) {
    let hasReport = false;
    reports.map((report) => {
      if (report.default_date_key == '') {
        hasReport = true;
      }
    });
    return hasReport;
  }

  buildReportTypeItems(hasOverviewReport) {
    const array: any = [];
    this.reportTypes.map((type) => {
      if (type.key == 'overview') {
        if (!hasOverviewReport) {
          array.push({
            value: type.id,
            viewValue: type.display_name,
          });
        }
      } else {
        array.push({
          value: type.id,
          viewValue: type.display_name,
        });
      }
    });
    return array;
  }

  async addReport() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = {
        dashboard_report_type_id: this.form.value.reportType,
        name: this.form.value.reportName
      };
      const response = await this.dashboard.addDashboardReport(payload);
      localStorage.setItem('selectedReport', response.id.toString())
      localStorage.setItem('cameFromReportAdded', 'true');
      const params = {
        report: await this.dashboard.getDashboardReportData(response.id)
      }
      await this.dashboard.updateDashboardSectionItems(true);
      this.navCtrl.navigateForward(`reports/${response.id}`, params);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  populateReportName(selection) {
    const reportType = this.reportTypeItems.find((item) => item.value == selection);
    this.form.patchValue({reportName: reportType.viewValue});
  }
}

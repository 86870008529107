import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Events } from 'src/providers/events/events';

@Injectable()
export class DisableMenuGuard implements CanActivate {

  constructor(
    private events: Events
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.events.publish('menu:close');
    return true;
  }

}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true"
    [customBack]="'concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <concierge-filter
        *ngIf="modalState == 'LIST'"
        [addresses]="addressResponse"
        [buttonTitle]="pageTitle == 'Support Requests' ? 'Add Request' : 'Add Action'"
        (onFilter)="onFilterChanges($event)"
        (onSearch)="onSearchChanges($event)"
        (onAddItem)="addItem()">
      </concierge-filter>

      <ng-container *ngIf="wasTableLoaded && modalState == 'LIST'">
        <tidy-card *ngFor="let item of currentRows">
          <tidy-card-button (action)="onTableColumnClick(item)">
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="item['Status']?.value?.includes('dispute') ? concierge.getDisputeIcon(item['Status']?.value) : 'assets/img/support-request.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="item['Subject']?.value">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="!item['Status']?.value?.includes('dispute')" class="extra-top-padding">
              <tidy-text
                [smallBody]="item['Status'].value + ' (updated ' + item['Last Updated'].value + ')'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
        <mat-paginator
          style="background: none"
          [length]="totalItems"
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="selectPage($event)">
        </mat-paginator>
      </ng-container>

      <ng-container *ngIf="modalState == 'ADD'">
        <tidy-row class="extra-bottom-padding" *ngIf="newItemType !== 'support.concierge_task'">
          <tidy-text
            [header]="itemTypeHeader(newItemType)">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="newItemType == 'support.concierge_task'">
          <tidy-row>
            <tidy-text
              [body]="'Concierge Actions are where you can specify anything you need from TIDY. Our AI and concierge teams will figure out how to get it done for you. We accept anything relating to coordinate cleaning and maintenance at your properties.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'<b>Examples:</b>'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Can you add this to-do list to my account?'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Can you set up 3 vendors to come and clean the carpets, complete a regular cleaning, and complete an inspection?'">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="newItemType == 'support.how_to'">
          <tidy-row>
            <tidy-text
              [body]="'We expect to reply within 2 business hours. We will reply both in the app and to your email'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="email">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-card>
          <form [formGroup]="itemForm" novalidate action="#">
            <tidy-row>
              <tidy-textarea
                label="Subject"
                [icon]="'assets/svg/checkbox-outline.svg'"
                [submitted]="submitted"
                [errorMessage]="'Please enter a subject'"
                [form]="itemForm.get('subject')"
                formControlName="subject">
              </tidy-textarea>
            </tidy-row>
            <tidy-row class="extra-bottom-padding">
              <tidy-textarea
              label="Description"
              [icon]="'assets/svg/create-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a description'"
              [form]="itemForm.get('body')"
              formControlName="body">
            </tidy-textarea>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [actionBody]="'Add Attachment'"
                (action)="addAttachment()"
                class="link">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngIf="attachments.length" class="field-top-padding">
              <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
                <tidy-text
                  [body]="attachment.filename">
                </tidy-text>
                <tidy-text
                  [actionBody]="'remove'"
                  (action)="removeAttachment(i)"
                  class="link red">
                </tidy-text>
              </tidy-two-column-row>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-button
          [text]="'Save'"
          [action]="saveItem.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="modalState == 'EDIT'">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="concierge.getStatusIcon(editingItem.components[0].value.status)"
              class="header-size">
            </tidy-image>
            <tidy-text
              *ngIf="editingItem.type !== 'support.concierge_task'"
              [header]="editingItem?.header_text">
            </tidy-text>
            <tidy-text
              *ngIf="editingItem.type == 'support.concierge_task'"
              [header]="editingItem?.components[0]?.value?.subject">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="status !== 'completed' && status !== 'closed'">
            <tidy-text
              *ngIf="editingItem?.components[0]?.value?.status === 'waiting_on_tidy'"
              [body]="'<i>Waiting on TIDY</i>'">
            </tidy-text>
            <tidy-text
              *ngIf="editingItem?.components[0]?.value?.status === 'waiting_on_customer'"
              [body]="'<b><i>Waiting on You</i><b>'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="status === 'waiting_on_tidy' && editingItem.type !== 'support.concierge_task'" class="extra-top-padding">
            <tidy-text
              [body]="'We expect to reply within'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="replyTime">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'. We will reply both in the app and to your email'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="email + '.'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="status === 'waiting_on_tidy' && editingItem.type == 'support.concierge_task'" class="extra-top-padding">
            <tidy-text
              [body]="'Our concierge will complete the task or reach out with any questions.'">
            </tidy-text>
          </tidy-row>

        <tidy-button
          [text]="'Reply'"
          [action]="reply.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-card *ngFor="let comment of editingItem?.components[0]?.value?.comments">
          <tidy-row [alignLastItemRight]="true">
            <tidy-text
              [header]="((comment?.user_type === 'Customer' || comment?.user_type === 'customer') ? 'You' : 'TIDY Concierge')">
            </tidy-text>
            <tidy-text
              [helper]="comment?.created_at | customdate:'M/D h:mma'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [checkLineBreaks]="true"
              [linkify]="true"
              [body]="comment?.content">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <ng-container *ngIf="displayedAttachments?.length > 0">
          <tidy-row>
            <tidy-text
              [header]="'Attachments:'">
            </tidy-text>
          </tidy-row>
          <div class="attachment-container">
            <tidy-row *ngFor="let attachment of displayedAttachments">
              <img
                [src]="attachment.fileUrl"
                (click)="openAttachment(attachment.fileUrl)"
                class="attachment-image">
            </tidy-row>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="modalState == 'REPLY'">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="concierge.getStatusIcon(editingItem?.components[0]?.value?.status)"
            class="header-size">
          </tidy-image>
          <tidy-text
            *ngIf="editingItem?.type !== 'support.concierge_task'"
            [header]="editingItem?.header_text">
          </tidy-text>
          <tidy-text
            *ngIf="editingItem?.type == 'support.concierge_task'"
            [header]="editingItem?.components[0]?.value?.subject">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="editingItem?.components[0]?.value?.status !== 'completed' && editingItem?.components[0]?.value?.status !== 'closed'">
          <tidy-text
            *ngIf="editingItem?.components[0]?.value?.status === 'waiting_on_tidy'"
            [body]="'<i>Waiting on TIDY</i>'">
          </tidy-text>
          <tidy-text
            *ngIf="editingItem?.components[0]?.value?.status === 'waiting_on_customer'"
            [body]="'<b><i>Waiting on You</i><b>'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-bottom-padding extra-top-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <ng-container *ngIf="editingItem.components?.[0]?.value?.comments?.length > 0">
          <div class="scrollable-comments-container">
            <ng-container *ngFor="let comment of editingItem.components?.[0]?.value?.comments">
              <tidy-card>
                <tidy-row [alignLastItemRight]="true">
                  <tidy-text
                    [header]="((comment?.user_type === 'Customer' || comment?.user_type === 'customer') ? 'You' : 'TIDY Concierge')">
                  </tidy-text>
                  <tidy-text
                    [helper]="comment?.created_at | customdate:'M/D h:mma'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [checkLineBreaks]="true"
                    [linkify]="true"
                    [body]="comment?.content">
                  </tidy-text>
                </tidy-row>
              </tidy-card>
            </ng-container>
          </div>
        </ng-container>

        <form [formGroup]="replyForm" novalidate action="#">
            <tidy-row class="field-top-padding">
            <tidy-textarea
              [label]="'Reply'"
              formControlName="content"
              [form]="replyForm.controls.content"
              [submitted]="submitted"
              [icon]="'assets/svg/information-circle-outline.svg'"
              [errorMessage]="'Please enter a reply.'">
            </tidy-textarea>
          </tidy-row>
        </form>
        <tidy-row class="field-top-padding">
          <tidy-text
            [actionBody]="'Add Attachment'"
            (action)="addAttachment()"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="attachments.length" class="field-top-padding">
          <tidy-row>
            <tidy-text
              [body]="'Attachments:'"
            >
            </tidy-text>
          </tidy-row>
          <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
            <tidy-text
              [body]="attachment.filename">
            </tidy-text>
            <tidy-text
              [actionBody]="'remove'"
              (action)="removeAttachment(i)"
              class="link red">
            </tidy-text>
          </tidy-two-column-row>
        </tidy-row>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Send Reply'"
          [action]="sendReply.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

      <ng-container *ngIf="modalState == 'SELECT_TYPE'">
        <tidy-card-button (action)="selectType('support.how_to')">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="'assets/img/home-heart.svg'"
                      style="width:25px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'How to Use TIDY'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Ask a question about TIDY.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button (action)="jobIssue()">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="'assets/img/repair-tools.svg'"
                      style="width:25px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Issue with a Job'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Notify us of an issue with a job/pro.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button (action)="billingIssue()">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="'assets/img/billing-issue.svg'"
                      style="width:25px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Issue with Billing'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Ask us a question about charges or credits.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
        <tidy-card-button (action)="selectType('support.other')">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2" class="vertical-align-center">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="'assets/img/help.svg'"
                      style="width:25px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Other'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Note: The slowest option'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-card-button>
      </ng-container>

      <ng-container *ngIf="modalState == 'SELECT_JOB'">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Which job did you have an issue with?'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <ng-container *ngFor="let job of pastJobsResponse | slice:0:showLimiter">
            <tidy-card-button (action)="selectJob(job)">
              <tidy-row>
                <tidy-text
                  [body]="job?.service">
                </tidy-text>
                <tidy-text
                  [body]="' on ' + (job?.start_datetime_local | parseDate: 'ddd M/D h:mma')">
                </tidy-text>
                <br>
                <tidy-text
                  [helper]="job?.display_address">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </ng-container>
          <tidy-card-button (action)="selectJob()">
            <tidy-row>
              <tidy-text
                [body]="'Not Listed'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button *ngIf="pastJobsResponse.length > 10" (action)="showLimit()">
            <tidy-row>
              <tidy-text
                *ngIf="showLimiter == 10"
                [body]="'Show more jobs'">
              </tidy-text>
              <tidy-text
                *ngIf="showLimiter !== 10"
                [body]="'Show less jobs'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="modalState == 'SELECT_JOB_SUBTYPE'">
        <tidy-card>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [body]="'This job on'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(itemMetadata?.job?.job?.date | parseDate: 'ddd') +
              ' ' +
              (itemMetadata?.job?.job?.date | parseDate: 'M/D')">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'at'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(itemMetadata?.job?.job?.start_time | parseDate: 'h:mma')">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'is marked as'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="itemMetadata.jobStatus">
            </tidy-text>
            <tidy-text
              [body]="'. What is your issue?'">
            </tidy-text>
          </tidy-row>
          <tidy-card-button (action)="selectJobIssueSubtype('bad_quality')">
            <tidy-row>
              <tidy-text
                [body]="'The service quality was bad'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectJobIssueSubtype('no_show')">
            <tidy-row>
              <tidy-text
                [body]="'The pro never showed up'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectJobIssueSubtype('missing_item')">
            <tidy-row>
              <tidy-text
                [body]="'Something is missing'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectJobIssueSubtype('damage_claim')">
            <tidy-row>
              <tidy-text
                [body]="'Something is damaged'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectJobIssueSubtype('tip')">
            <tidy-row>
              <tidy-text
                [body]="'I want to leave a tip'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-card-button (action)="selectJobIssueSubtype('other_issue')">
            <tidy-row>
              <tidy-text
                [body]="'Other service issue (slowest)'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="modalState == 'BILLING_ISSUE'">
        <tidy-card>
          <tidy-row>
            <tidy-text
              header="Billing Issue">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              body="We are happy to help with any billing issues. Please tap “Need help?” on the billing item you have questions on, and we typically help within 4 business hours.">
            </tidy-text>
          </tidy-row>
          <tidy-button
            [text]="'View Billing History'"
            [action]="billingHistory.bind(this)"
            class="secondary">
          </tidy-button>
        </tidy-card>
      </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

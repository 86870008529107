import { Component, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Dashboard } from 'src/providers/dashboard/dashboard';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { ReportSettingsPage } from '../report-settings/report-settings';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: './edit-report-column.html',
})

export class EditReportColumnPage implements OnInit {

  column: any;
  errorMessage: string;
  dialogParams: any;
  filters: any;
  form: UntypedFormGroup;
  initialFilters: any;
  initialSortByThisColumn: boolean;
  initialShowColumn: boolean;
  isRightSideContent: boolean;
  loaded: boolean;
  newFilters: any = [];
  operatorItems: any;
  report: any;
  showColumn: boolean;
  sortTypeItems: TidySelectStringValueModel[];
  sortByThisColumn: boolean;
  submitted: boolean;

  constructor(
    private dashboard: Dashboard,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {
    this.form = this.fb.group({
      operator: [''],
      value: [''],
      startDate: [''],
      endDate: [''],
      sortType: ['asc']
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.column = this.navCtrl.getParam('field') || this.dialogParams.field;
    this.showColumn = this.column.is_field_visible;
    this.report = this.navCtrl.getParam('report') || this.dialogParams.report;
    this.sortByThisColumn = this.report.settings.sort_by_field_keys[0].field_key == this.column.field_key;
    if (this.sortByThisColumn) {
      this.form.patchValue({sortType: this.report.settings.sort_by_field_keys[0].sort});
    }
    this.initialShowColumn = this.showColumn;
    this.initialSortByThisColumn = this.sortByThisColumn;
    this.sortTypeItems = this.buildSortTypeItems();
    if (this.column.type == 'string' || this.column.type == 'number') {
      this.operatorItems = this.getOperatorItems();
    }
    this.filters = this.parseFilters();
    this.initialFilters = this.filters;
    if (this.column.type == 'string') {
      this.form.patchValue({operator: 'contains'});
    } else if (this.column.type == 'number') {
      this.form.patchValue({operator: 'equals'});
    }
    this.loaded = true;
  }

  buildSortTypeItems() {
    return [
      {
        viewValue: "Ascending",
        value: 'asc'
      },
      {
        viewValue: "Descending",
        value: 'desc'
      }
    ];
  }

  parseFilters() {
    const array: any = [];
    this.report.settings.filters.map((filter) => {
      if (filter.field_id == this.column.id) {
        array.push(filter);
      }
    });
    return array;
  }

  addFilter() {
    //TODO in a future ticket: add support for multiple filtlers here
    this.newFilters.push({});
  }

  removeNewFilter() {
    this.newFilters = [];
  }

  removeFilter(filter) {
    this.filters = this.filters.filter((item) => {
      return item !== filter;
    });
  }

  async saveChanges() {
    this.submitted = true;
    if (this.newFilters.length) {
      this.form.controls.value.setValidators([Validators.required]);
      this.form.controls.value.updateValueAndValidity();
      this.submitted = true;
      if (!this.form.valid) {
        return;
      }
    } else {
      this.form.controls.value.clearValidators();
    }
    try {
      const changedSort = this.sortByThisColumn !== this.initialSortByThisColumn;
      const changedSortValue = this.form.value.sortType !== this.report.settings.sort_by_field_keys[0].sort;
      if (changedSort || changedSortValue) {
        const payload = this.getUpdateReportPayload();
        await this.dashboard.updateDashboardReport(payload, this.report.id);
      }
      const changedShowColumn = this.showColumn !== this.initialShowColumn;
      if (changedShowColumn) {
        const payload = this.getChangeShowColumnPayload();
        await this.dashboard.updateDashboardReportFields(payload, this.report.id);
      }
      if (this.initialFilters !== this.filters || this.newFilters.length) {
        let payload: any = {
          filters: [
            {
              dashboard_customer_report_field_id: this.column.id,
              operator: this.form.value.operator,
              value: this.form.value.value
            }
          ]
        };
        if (this.filters.length == 0 && this.newFilters.length == 0) {
          payload = {
            filters: []
          };
          await this.dashboard.updateDashboardReportFilters(payload, this.report.id);
        }
        this.report.settings.filters.map((filter) =>  {
          if (filter.dashboard_report_type_field_id !== this.column.dashboard_report_type_field_id) {
            payload.filters.push({
              dashboard_customer_report_field_id: filter.field_id,
              operator: filter.operator,
              value: filter.value
            });
          }
        });
        await this.dashboard.updateDashboardReportFilters(payload, this.report.id);
      }
      this.util.showSuccess('Report updated successfully');
      if (this.isRightSideContent) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateRoot('dashboard')
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(this.errorMessage);
    }
  }

  getChangeShowColumnPayload() {
    this.report.settings.fields.map((field, index) => {
      if (field == this.column) {
        this.report.settings.fields[index].is_field_visible = this.showColumn;
      }
    });
    return {
      fields: this.report.settings.fields.map((field) => {
        return {
          dashboard_report_type_field_id: field.dashboard_report_type_field_id,
          field_name: field.field_name,
          field_order: field.field_order,
          is_field_visible: field.is_field_visible
        }
      })
    }
  }

  getUpdateReportPayload() {
    if (!this.sortByThisColumn) {
      // INFO: Instead of sending an empty array, send another field key that is not the current one because BE doesn't support empty array
      const firstVisibleField = this.report.settings.fields.find(
        (field) =>
          field.is_field_visible && field.field_key !== this.column.field_key
      );
      return {
        sort_by_field_keys: [
          {
            field_key: firstVisibleField.field_key,
            sort: firstVisibleField.sort,
          },
        ],
      };
    }
    return {
      sort_by_field_keys: [
        {
          field_key: this.column.field_key,
          sort: this.form.value.sortType,
        },
      ],
    };
  }

  getOperatorItems() {
    if (this.column.type == 'string') {
      return [{
        viewValue: 'Contains',
        value: 'contains'
      },
      {
        viewValue: 'Equals',
        value: 'equals'
      },
      {
        viewValue: 'Starts with',
        value: 'begins with'
      },
      {
        viewValue: 'Ends with',
        value: 'ends with'
      },
      {
        viewValue: 'Does not contain',
        value: 'not contains'
      },
      {
        viewValue: 'Does not equal',
        value: 'not equals'
      }];
    } else {
      return [{
        viewValue: '>',
        value: '>'
      },
      {
        viewValue: '>=',
        value: '>='
      },
      {
        viewValue: '=',
        value: 'equals'
      },
      {
        viewValue: '<',
        value: '<'
      },
      {
        viewValue: '<=',
        value: '<='
      }];
    }
  }
}

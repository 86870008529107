<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="isUpdatingIntegration ? 'Update Integration' : 'Add Integration'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }">
</ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <add-integration-form
      [isUpdatingIntegration]="isUpdatingIntegration"
      [isRightSideContent]="isRightSideContent"
      [dialogParams]="dialogParams">
    </add-integration-form>
  </tidy-wrapper>
</ng-template>

<ng-container [ngSwitch]="isRightSideContent">
  <ng-container *ngSwitchCase="true">
    <ion-content class="ion-content-right-column">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ion-content>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
  </ng-container>
</ng-container>

import { Injectable } from '@angular/core';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';
import { ModalController } from '@ionic/angular';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { ConfirmPage } from 'src/pages/confirm/confirm.component';
import { Observable, Subject } from 'rxjs';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';
import { PhotoNoteRoomModel } from 'src/models/to-do.model';

@Injectable({
  providedIn: 'root'
})
export class PhotoCaptionService {
  constructor(
    private camera: CameraProvider,
    private photoNotes: PhotoNotes,
    private modalCtrl: ModalController
  ) {}

  private deleteImage: Subject<void> = new Subject<void>();
  deleteImage$: Observable<void> = this.deleteImage.asObservable();

  mountPhotoUrls(collection: PhotoNoteRoomModel[], type) : PhotoNoteUrlModel[] {
    if (!collection) {
      return [];
    }
    return collection.filter(photoNote => photoNote.type === type).map(photoNote => ({
      url: photoNote.photo_url,
      id: photoNote.id,
      type: photoNote.type,
      photoNoteCaption: photoNote.photo_note_caption,
      captionChanged: false,
      deleted: false
    }));
  }

  async uploadPhotosGeneric(photoNoteUrls: PhotoNoteUrlModel[], type: string, savePhotoNoteFunction, saveCaptionFunction, roomType) {
    const localPhotoNotes = photoNoteUrls.filter((photoNote) => photoNote.id === -1);

    await Promise.all(localPhotoNotes.map(async (photoNote) => {
      const photo = await this.photoNotes.uploadPictureToS3(photoNote.url, type);
      const photoNoteUploaded = roomType == 'room' ? await savePhotoNoteFunction(photo.Key) : await savePhotoNoteFunction(photo.Location);

      photoNote.id = photoNoteUploaded.id;

      if (photoNote.captionChanged) {
        await saveCaptionFunction(photoNote.id, photoNote.photoNoteCaption);
      }
    }));

    const remotePhotoNotes = photoNoteUrls.filter((photoNote) => photoNote.id > -1);

    await Promise.all(remotePhotoNotes.map(async (photoNote) => {
      if (photoNote.captionChanged) {
        await saveCaptionFunction(photoNote.id, photoNote.photoNoteCaption);
      }
    }));
  }

  async getImg(name: string): Promise<PhotoNoteUrlModel> {
    const photoUrl = await this.camera.getImg(true);

    return {
      url: photoUrl.dataUrl,
      type: name,
      id: -1,
      photoNoteCaption: '',
      captionChanged: false,
      deleted: false
    }
  }

  async confirmDelete(photoId: number, photoNoteUrls: PhotoNoteUrlModel[]) {
    await Promise.all(photoNoteUrls.map(async (photoNote, index) => {
      if (photoNote.id === photoId) {
        const params: ConfirmPageParamsModel = {
          title: 'Delete Photo?',
          body: 'Delete Photo?',
          backText: 'Go Back',
          confirmText: 'Delete',
          confirmAction: this.deletePhotoNote.bind(this, photoId, index, photoNoteUrls)
        }
        const confirmationModal = await this.modalCtrl.create({
          component: ConfirmPage,
          componentProps: params,
          animated: false,
          cssClass: 'confirm-modal'
        });
        await confirmationModal.present();
      }
    }));
  }

  async deletePhotoNote(photoId: number) {
    try {
      await this.photoNotes.deletePhotoNotes(photoId);
      this.announceDeleteImage();
      this.modalCtrl.dismiss();
    } catch(err) {
      throw err;
    }
  }

  announceDeleteImage() {
    this.deleteImage.next();
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { UserService } from 'src/providers/user/user';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamPage } from '../team/team';

@Component({
  templateUrl: 'add-address-to-team.html'
})

export class AddAddressToTeamPage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  propertyItems: any;
  submitted: boolean;
  teamItems: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private team: TeamService,
    private userService: UserService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      property: ['', Validators.required],
      team: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Team');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    try {
      this.loaded = false;
      await this.getTeamItems();
      await this.getPropertyItems();
      const team = this.navCtrl.getParam('teamId') || await this.dialogParams.teamId;
      if (team) {
        this.form.patchValue({team: team})
      }
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getTeamItems() {
    this.teamItems = [];
    const teams = this.navCtrl.getParam('teams') || await this.dialogParams.teams  || await this.team.getTeams();
    teams.map((team) => {
      this.teamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
  }

  async getPropertyItems() {
    this.propertyItems = [];
    const addresses = await this.client.getAddresses();
    addresses.map((address) => {
      this.propertyItems.push({
        viewValue: address?.address_name || (address.address1 + ((address.address2 == null || address.address2 == '') ? '' : (' ' + address.address2))),
        value: address.id
      });
    });
  }

  @Loading('', true)
  async addPropertyToTeam() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const payload = {
      account_team_id: this.form.value.team,
      address_ids: this.form.value.property.toString()
    }
    try {
      await this.team.addAddressesToTeam(payload);
      const nextPage = this.navCtrl.getParam('nextPage') || await this.dialogParams.nextPage;
      this.dialogParams.team = null;
      this.dialogParams.teams = null;
      await this.storage.save('dialog-params', this.dialogParams);
      this.rightSidePanelService.navigateTo(nextPage, {}, TeamPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'To-Do Lists'"
    [showLinkIcon]="loaded && !neverBookedJobs && !toDosOnboarding"
    [linkIcon]="'settings-outline'"
    (linkAction)="goToListSettings()">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true" (contentHeight)="contentHeight = $event" [ngClass]="{'fab-page': !toDosOnboarding}">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-row *ngIf="!windowService.isDesktopRes" [ngStyle]="toDosOnboarding ? {'padding-bottom': '10px'} : {}">
        <tidy-filter
          [initValue]="addressId"
          [items]="addressFilter"
          [fitContent]="true"
          (optionChange)="changeAddress($event, true)">
        </tidy-filter>
      </tidy-row>

      <ng-container *ngIf="addressLoaded && !toDosOnboarding">

        <ng-container *ngIf="neverBookedJobs">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'No jobs booked at this property. Please book a job to use To-Dos.'">
            </tidy-text>
          </tidy-row>
         <tidy-button
           [text]="'Book Job(s)'"
           [action]="bookJob.bind(this, 'add_job')"
           class="primary">
         </tidy-button>
        </ng-container>

        <ng-container *ngIf="!neverBookedJobs">

          <ng-container *ngIf="!usingToDos()">
            <tidy-alert>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/img/block.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Not Using To-Dos'">
                </tidy-text>
              </tidy-row>
              <tidy-text
                [body]="'You are not using To-Dos by default at this property. You can still use To-Dos for a job - just tap To-Dos on the job in your Schedule.'">
              </tidy-text>
            </tidy-alert>
          </ng-container>

          <tidy-row *ngIf="hasToDoLists && !isRightSideContent">
            <tidy-filter
              [initValue]="taskListId"
              [items]="toDoFilter"
              [fitContent]="true"
              (optionChange)="changeList($event)">
            </tidy-filter>
          </tidy-row>

          <ng-container *ngIf="listLoaded">

            <ng-container *ngIf="!hasToDoLists && usingToDos()">
              <tidy-row [align]="'center'">
                <tidy-text
                  [body]="'No lists added for this property.'">
                </tidy-text>
              </tidy-row>
              <tidy-button
                [text]="'Add To-Do List'"
                [action]="goToAddList.bind(this)"
                class="primary">
              </tidy-button>
            </ng-container>

            <ng-container *ngIf="listLoaded && hasToDoLists">
              <tidy-row [align]="'center'" *ngIf="listSelected() && !hasJobsIsLoading" class="vertical-align-center">
                <ng-container *ngIf="hasJobs">
                  <tidy-image
                    [src]="'assets/img/information-circle-blue.svg'"
                    class="header-size">
                  </tidy-image>
                  <tidy-text
                    [body]="jobsAppliedSentence"
                    (action)="editJobsApplied()"
                    class="link"
                    style="margin-right:10px">
                  </tidy-text>
                </ng-container>
                <tidy-image
                  [src]="'assets/img/share.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  style="margin-right:10px"
                  (action)="goToShareList()"
                  [body]="'Share'"
                  class="link"
                  style="margin-right:10px">
                </tidy-text>
                <tidy-image
                  [src]="'assets/img/settings-blue.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  style="margin-right:10px"
                  (action)="goToEditList()"
                  [body]="'Settings'"
                  class="link">
                </tidy-text>
              </tidy-row>

              <tidy-row *ngIf="hasJobsIsLoading">
                <ion-skeleton-text animated style="width: 70%; margin: auto"></ion-skeleton-text>
              </tidy-row>

              <ng-container *ngIf="listSelected()">

                <tidy-card>
                  <tidy-card-button (action)="goToRemoteInspectionPage(list)" class="arrow-to-top">
                    <tidy-row class="vertical-align-center extra-bottom-padding">
                      <tidy-image
                        [src]="'assets/img/camera.svg'"
                        class='title-size'>
                      </tidy-image>
                      <tidy-text
                        [header]="'Remote Inspection'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row>
                      <tidy-text
                        [body]="toDos.getRemoteInspectionStatus(selectedList?.before_after_photos_state)">
                      </tidy-text>
                    </tidy-row>
                  </tidy-card-button>
                </tidy-card>

                <tidy-row [alignLastItemRight]="true" class="extra-top-padding" style="margin-bottom: 0px;">
                  <tidy-row [alignLastItemRight]="true" class="full-width">
                    <tidy-text
                      [header]="'Cards'">
                    </tidy-text>
                    <tidy-text
                      [body]="'Edit Priorities'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-toggle [toggled]="editPrio" (toggleChanged)="editPrio = !editPrio" class="extra-left-margin"></tidy-toggle>
                </tidy-row>

                <tidy-drag-drop
                  dragDropId="favorites-dd"
                  [dataList]="rooms"
                  emptyListColor="green"
                  emptyDisabledListMessage="No Cards Added"
                  [disabled]="!editPrio"
                  (listDropped)="updatePriorities($event)"
                >
                  <ng-container *ngFor="let room of rooms">
                    <ng-container *ngIf="!room?.do_not_clean">
                      <ng-container *tidyDragDropItem>
                        <tidy-card class="no-padding">
                          <tidy-card-button
                            class="arrow-to-top room-card-button"
                            [customArrowPositon]="{ top: '18px', right: '15px' }"
                            [hideArrow]="editPrio"
                            (action)="editPrio? '': goToRoom(room, 'doClean')"
                          >
                            <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding extra-left-padding extra-right-padding">
                              <tidy-image
                                *ngIf="room?.type !== 'group' || editPrio"
                                [src]="getCardIcon(room)"
                                class="title-size">
                              </tidy-image>
                              <tidy-text
                                [title]="room?.name"
                                [translate]="false">
                              </tidy-text>
                            </tidy-row>
                            <ng-container *ngIf="!editPrio">
                              <ng-container *ngFor="let doNot of room?.notes">
                                <tidy-row  *ngIf="doNot?.body !== null" class="extra-bottom-padding extra-left-padding extra-right-padding">
                                  <tidy-text
                                    class="red"
                                    [body]="'<b>Do not</b>'">
                                  </tidy-text><span>&nbsp;</span>
                                  <tidy-text
                                    class="red"
                                    [body]="doNot?.body">
                                  </tidy-text>
                                </tidy-row>
                              </ng-container>
                              <ng-container *ngFor="let toDo of room?.tasks; let last = last" class="todo-row">
                                <ng-container *ngIf="!toDo?.task_type?.includes('video')">
                                  <tidy-row class="extra-left-padding extra-right-padding" [ngClass]="{'extra-bottom-padding': !last}">
                                    <div [ngStyle]="toDos.important(toDo?.is_important)" class="vertical-align-center todo-text-img" style="width: fit-content">
                                      <tidy-image
                                        *ngIf="toDo?.is_important"
                                        [src]="'assets/img/star.svg'"
                                        class="body-size position-absolute">
                                      </tidy-image>
                                      <tidy-text
                                        [class.padding-left-20]="toDo?.is_important"
                                        [body]="toDo?.title"
                                        [translate]="false">
                                      </tidy-text>
                                    </div>

                                    <tidy-text
                                      *ngIf="toDo?.note !== '' && toDo?.note !== null"
                                      [helper]="toDo?.note"
                                      [translate]="false">
                                    </tidy-text>
                                  </tidy-row>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </tidy-card-button>
                          <ng-container *ngIf="!editPrio">
                            <div class="slide-container">
                              <tidy-carrousel
                                *ngIf="room?.photos?.length > 0"
                                class="photo-notes-carrousel only-padding-bottom"
                                [scaleImages]="true"
                              >
                                <ng-container *ngFor="let photoNote of room?.photos">
                                  <tidy-photo-note
                                    class="full-width there-is-carrousel no-margin"
                                    *tidyCarrousel
                                    [caption]="photoNote.photo_note_caption"
                                    [src]="photoNote.photo_url"
                                    ></tidy-photo-note>
                                </ng-container>
                              </tidy-carrousel>
                            </div>
                            <tidy-carrousel
                              *ngIf="toDo?.photo_notes?.length > 0"
                              class="photo-notes-carrousel only-padding-bottom"
                              [scaleImages]="true"
                            >
                              <ng-container *ngFor="let photoNote of toDo?.photo_notes">
                                <tidy-photo-note
                                  class="full-width there-is-carrousel no-margin"
                                  *tidyCarrousel
                                  [caption]="photoNote.photo_note_caption"
                                  [src]="photoNote.photo_url"
                                  ></tidy-photo-note>
                              </ng-container>
                            </tidy-carrousel>
                          </ng-container>
                          <div></div>
                        </tidy-card>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tidy-drag-drop>

                <ng-container *ngFor="let room of rooms">
                  <tidy-card class="no-padding" *ngIf="room?.do_not_clean">
                    <tidy-card-button
                      class="arrow-to-top room-card-button no-padding"
                      [customArrowPositon]="{ top: '18px', right: '15px' }"
                      (action)="goToRoom(room, 'doNotClean')">
                      <tidy-row class="vertical-align-center extra-top-padding extra-bottom-padding extra-left-padding extra-right-padding">
                        <tidy-image
                          [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                          class="title-size">
                        </tidy-image>
                        <tidy-text
                          [title]="room?.name"
                          [translate]="false">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row class="extra-bottom-padding extra-left-padding extra-right-padding">
                        <tidy-text
                          class="red"
                          [body]="'<b>Do not</b> clean this room'">
                        </tidy-text>
                      </tidy-row>
                    </tidy-card-button>
                  </tidy-card>
                </ng-container>

              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <to-dos-onboarding
      *ngIf="loaded && toDosOnboarding"
      [addressResponse]="addressResponse"
      [addressId]="addressId"
      [isNormalBookingFlow]="false">
    </to-dos-onboarding>

    <ng-container *ngIf="!loaded">
      <tidy-row *ngIf="!windowService.isDesktopRes">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 188px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-row class="extra-bottom-padding">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated style="width: 125px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-row>
        <ion-skeleton-text animated style="width: 70%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 52%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 53%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 74%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 44%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 44%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 72%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 84%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="loaded && !addressLoaded">
      <tidy-row *ngIf="!windowService.isDesktopRes">
        <div style="
        background: white;
        border-radius: 25px;
        padding: 7px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: fit-content;
        margin: auto;
        max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 188px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-row>
        <ion-skeleton-text animated style="width: 70%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 52%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 53%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 74%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 44%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 44%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 72%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 84%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

    <ng-container *ngIf="loaded && (!listLoaded && !toDosOnboarding)">
      <tidy-row>
        <ion-skeleton-text animated style="width: 70%; margin: auto"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 52%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 53%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 74%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 44%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 44%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 72%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 80%; height: 16px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 84%"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>

</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<tidy-fab [isRightSidePanelButton]="isRightSideContent" class="bottom-nav-padding" *ngIf="!toDosOnboarding && loaded && listSelected() && hasToDoLists">
  <tidy-fab-button class="main-btn">
    <tidy-image src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text largeBody="Add" class="white"></tidy-text>
  </tidy-fab-button>
  <tidy-fab-list>
    <tidy-fab-button *ngIf="rooms?.length !== 0"
      (click)="goToAddToDo()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="To-Do" class="white"></tidy-text>
    </tidy-fab-button>
    <tidy-fab-button *ngIf="rooms?.length !== 0"
      (click)="goToAddDoNot()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="Do Not" class="white"></tidy-text>
    </tidy-fab-button>
    <tidy-fab-button
      (click)="goToAddRoom()">
      <tidy-image [src]="'assets/svg/add-heavy.svg'" class="large-body-size"></tidy-image>
      <tidy-text largeBody="Card" class="white"></tidy-text>
    </tidy-fab-button>
  </tidy-fab-list>
</tidy-fab>

<tidy-bottom-nav activeRoute="/to-dos">
</tidy-bottom-nav>

<tidy-row>
  <tidy-text
    [header]="'Where do you typically like to order things?'">
  </tidy-text>
</tidy-row>
<tidy-row>
  <form [formGroup]="signUpForm">
    <tidy-input
      [label]="'eg: Amazon, Walmart, etc.'"
      formControlName="whereToOrder"
      [form]="signUpForm.controls.whereToOrder">
    </tidy-input>
  </form>
</tidy-row>
<tidy-row class="extra-top-padding">
  <tidy-text
    [header]="'Where do you want them to be shipped?'">
  </tidy-text>
</tidy-row>
<tidy-row>
  <form [formGroup]="signUpForm">
    <tidy-input
      [label]="'eg: My central office, the property.'"
      formControlName="whereToShip"
      [form]="signUpForm.controls.whereToShip">
    </tidy-input>
  </form>
</tidy-row>
<tidy-row class="extra-top-padding">
  <tidy-text
    [header]="'Add Items to Restock'">
  </tidy-text>
</tidy-row>
<tidy-row>
  <tidy-text
    [body]="'Select from a list of popular items, or add your own.'">
  </tidy-text>
</tidy-row>

<tidy-row>
  <div style="width: 100%">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="border-bottom: 1px solid #E4E6E9;">
          <th style="text-align: center; padding: 8px;">
            <tidy-text [helper]="'Item'"></tidy-text>
          </th>
          <th style="text-align: center; padding: 8px;">
            <tidy-text [helper]="'Current Est Level'"></tidy-text>
          </th>
          <th style="text-align: center; padding: 8px;">
            <tidy-text [helper]="'Min Level'"></tidy-text>
          </th>
          <th style="text-align: center; padding: 8px;">
            <tidy-text [helper]="'Max Level'"></tidy-text>
          </th>
          <th style="text-align: center; padding: 8px;">
            <tidy-text [helper]="'Add to Cleaning Checklist?'"></tidy-text>
          </th>
          <th style="text-align: center; padding: 12px 16px;">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items; last as isLast; let i = index" [style.border-bottom]="isLast ? 'none' : '1px solid #E4E6E9'">
          <td style="padding: 8px; text-align: center;">
            <select
              [(ngModel)]="item.item"
              [ngModelOptions]="{standalone: true}"
              style="width: 160px; padding: 8px; border: 1px solid #E4E6E9; border-radius: 4px; font-family: 'Inter',Arial,sans-serif; text-align: left;">
              <option *ngFor="let category of categoriesItems" [value]="category.value">{{category.viewValue}}</option>
            </select>
          </td>
          <td style="padding: 8px; text-align: center;">
            <input
              type="number"
              [value]="item.current"
              style="width: 40px; padding: 8px; border: 1px solid #E4E6E9; border-radius: 4px; font-family: 'Inter',Arial,sans-serif; text-align: center;"
            >
          </td>
          <td style="padding: 8px; text-align: center;">
            <input
              type="number"
              [value]="item.min"
              style="width: 40px; padding: 8px; border: 1px solid #E4E6E9; border-radius: 4px; font-family: 'Inter',Arial,sans-serif; text-align: center;"
            >
          </td>
          <td style="padding: 8px; text-align: center;">
            <input
              type="number"
              [value]="item.max"
              style="width: 40px; padding: 8px; border: 1px solid #E4E6E9; border-radius: 4px; font-family: 'Inter',Arial,sans-serif; text-align: center;"
            >
          </td>
          <td style="padding: 12px 16px; text-align: center;">
            <tidy-checkbox
              [items]="[{value: item.addToChecklist, viewValue: ''}]"
              [initValue]="item.addToChecklist"
              (click)="$event.stopPropagation(); updateItem(i, 'addToChecklist', !item.addToChecklist)"
              style="display: inline-block;">
            </tidy-checkbox>
          </td>
          <td style="text-align: center;" *ngIf="i > 0">
            <tidy-text
              [actionBody]="'X'"
              class="link"
              (action)="removeItem(i)"
              >
            </tidy-text>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</tidy-row>

<tidy-row [align]="'center'" class="extra-bottom-padding">
  <tidy-text
    [actionBody]="'+ Add Item'"
    (action)="addItem()"
    class="link">
  </tidy-text>
</tidy-row>

<tidy-button
  [text]="'Save Items'"
  (click)="saveItems()"
  class="primary">
</tidy-button>

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from './../../shared/providers/tidy-storage';
import { Component } from '@angular/core';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { GuestReservationResponse } from 'src/models/schedule-weekly-view.model';
import { Util } from 'src/shared/util/util';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";

@Component({
  templateUrl: 'add-reservation.html',
})
export class AddReservationPage extends TimeoutableComponent {
  address: any;
  guestReservation: GuestReservationResponse;
  detail: any;
  reservationDetails: any;
  hasReservationDetails = false;
  dialogParams: any;
  isRightSideContent = true;
  form: UntypedFormGroup;
  timesItems = this.getAllTimeItems();
  addressResponse: any;
  addressFilter: any;
  submitted = false;

  constructor(
    private guestReservations: GuestReservations,
    private storage: TidyStorage,
    private utils: Util,
    private rightSidePanelService: RightSidePanelService,
    private client: Client,
    private navCtrl: CustomNavController
  ) {
    super();
    this.form = new UntypedFormGroup({
      property: new UntypedFormControl(null, Validators.required),
      checkInDate: new UntypedFormControl(null, Validators.required),
      checkInTime: new UntypedFormControl(null),
      checkOutDate: new UntypedFormControl(null, Validators.required),
      checkOutTime: new UntypedFormControl(null),
      guests: new UntypedFormControl(null),
      adults: new UntypedFormControl(null),
      children: new UntypedFormControl(null),
      pets: new UntypedFormControl(null),
    });
  }

  async ngOnInit() {
    this.isRightSideContent = (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    await this.getAddressData();
    this.loaded = true;
  }

  async getAddressData() {
    this.addressFilter = await this.buildAddressFilter();
    const storedAddress = await this.storage.retrieve('address');
    if (storedAddress) {
      this.form.patchValue({
        property: storedAddress,
      });
    }
  }

  async buildAddressFilter() {
    this.addressResponse = await this.client.getAddresses();
    return this.client.parseAddressList(this.addressResponse, false);
  }

  getAllTimeItems() {
    return Array.from({ length: 48 }, (_, index) => {
      const momentDateTimeObject = moment()
        .startOf('day')
        .add(index * 30, 'minutes');
      const hour12Format = momentDateTimeObject.format('h:mma');
      const militaryHourFormat = momentDateTimeObject.format('HH:mm');
      return { viewValue: hour12Format, value: militaryHourFormat };
    });
  }

  async addReservation() {
    this.submitted = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const payload = {
      address_id: this.form.value.property,
      check_in_date: this.form.value.checkInDate,
      check_in_time: this.form.value.checkInTime,
      check_out_date: this.form.value.checkOutDate,
      check_out_time: this.form.value.checkOutTime,
      details: {
        total_guests: this.form.value.guests,
        total_adults: this.form.value.adults,
        total_children: this.form.value.children,
        total_pets: this.form.value.pets,
      },
    };
    const loading = await this.utils.showLoading();
    try {
      await this.guestReservations.create(payload);
      loading.dismiss();
      this.utils.showSuccess('Reservation created successfully');
      if (this.isRightSideContent) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateBack('more/reservations')
      }
    } catch (error) {
      this.submitted = false;
      console.error(error);
      loading.dismiss();
      this.utils.showError('Error creating reservation, please try again.');
    }
  }

}

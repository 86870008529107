<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Job Quotes' | translate"
    [canGoBack]="true"
    id="top-nav-height">
    <tidy-wrapper *ngIf="loaded">
      <tidy-row>
        <tidy-divider> </tidy-divider>
      </tidy-row>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        ">
        <div>
          <tidy-row class="vertical-align-center">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_1_white.svg'">
            </tidy-image>
            <tidy-text [actionBody]="service" class="white"> </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_2_white.svg'">
            </tidy-image>
            <tidy-text [actionBody]="dateLabel" class="white"> </tidy-text>
          </tidy-row>
        </div>
        <div>
          <tidy-row class="extra-left-padding" *ngIf="selectedPrice">
            <tidy-text
              [title]="selectedPrice | tcurrency"
              class="text-price custom-text-price">
            </tidy-text>
          </tidy-row>
        </div>
      </div>

      <tidy-button
        [text]="'Review Booking' | translate"
        [action]="confirmBooking.bind(this)"
        [disabled]="!selectedPrice"
        class="primary">
      </tidy-button>
    </tidy-wrapper>
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper
    [class]="isRightSideContent ? 'right-side-extra-bottom-padding' : ''">
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngFor="let quote of quotes; let i = index">
      <tidy-row [class.quote-row-margin]="i > 0" style="margin-bottom: 1rem">
        <tidy-text [title]="quote.name + ' - ' + (quote.amount | tcurrency)">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let date of quote.dates">
        <tidy-card>
          <tidy-row>
            <tidy-text [header]="date.date | customdate:'dddd M/D':''">
            </tidy-text>
          </tidy-row>
          <form [formGroup]="form">
            <tidy-radio-button
              chunkSize="3"
              formControlName="time"
              (optionChange)="selectTime($event, quote, date)"
              [form]="form.controls.time"
              [(ngModel)]="form.value.time"
              [items]="date.times">
            </tidy-radio-button>
          </form>
        </tidy-card>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper
    [style.padding-top.px]="topNavHeight"
    [showCardDesign]="true"
    [templateRightColumn]="rightColumn"
    [templateContent]="content">
  </app-scroll-wrapper>
</ng-container>

<ng-template #reviewBooking>
  <tidy-wrapper class="custom-right-column black-column">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      ">
      <div>
        <tidy-row class="vertical-align-center">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_1_white.svg'">
          </tidy-image>
          <tidy-text [actionBody]="service" class="white"> </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_2_white.svg'">
          </tidy-image>
          <tidy-text [actionBody]="dateLabel" class="white"> </tidy-text>
        </tidy-row>
      </div>
      <div>
        <tidy-row class="extra-left-padding" *ngIf="selectedPrice">
          <tidy-text
            [title]="selectedPrice | tcurrency"
            class="text-price custom-text-price">
          </tidy-text>
        </tidy-row>
      </div>
    </div>

    <tidy-button
      [text]="'Review Booking' | translate"
      [action]="confirmBooking.bind(this)"
      [disabled]="!selectedPrice"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="isRightSideContent">
  <div class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="reviewBooking"></ng-container>
  </div>
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewChild, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Pros } from 'src/providers/pros/pros';
import { PastJobPdfParams, PastJobPdfProvider } from '../past-job-pdf/past-job-pdf';
import { ClientHub } from 'src/providers/client-hub/client-hub';
import { ToDos } from 'src/providers/to-dos/to-dos';

@Component({
  selector: 'past-shared-job',
  template: `
    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>
  `,
  encapsulation: ViewEncapsulation.None
})

export class PastSharedJobPage implements OnInit {

  errorMessage: string = '';

  constructor(
    private pros: Pros,
    private route: ActivatedRoute,
    private pastJobPdfProvider: PastJobPdfProvider,
    private clientHub: ClientHub,
    private toDos: ToDos
  ) {
  }
  async ngOnInit() {
    const uuid = this.route.snapshot.paramMap.get('uuid');

    try {
      const sharedLink = await this.clientHub.getSharedLink(uuid)
      this.printPdfFromSharedLink(sharedLink.shared_link_data);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  parseSharedJobMedias(jobMedias = []) {
    return {
      beforePhotos: jobMedias.filter((media) => media.category == 'before_job' && media.media_format == 'photo'),
      afterPhotos: jobMedias.filter((media) => media.category == 'after_job' && media.media_format == 'photo')
    }
  }

  async printPdfFromSharedLink(sharedLinkData) {

      const { beforePhotos, afterPhotos } = this.parseSharedJobMedias(sharedLinkData.job_medias);

      const tips = sharedLinkData.homekeeper_job.tips;

      let rooms = sharedLinkData.rooms;
      rooms = this.toDos.buildRoomIcons(rooms);
      rooms = await this.toDos.getMetadataFromFiles(rooms);

      const params: PastJobPdfParams = {
        job: {
          ...sharedLinkData.job,
          homekeepers: sharedLinkData.homekeepers,
          booking: {
            bookable_service: {
              name: sharedLinkData.service_type_details.name,
            }
          }
        },
        invoice: null,
        pro: {
          homekeeper: sharedLinkData.homekeepers?.[0].name?.split(' ')[0]
        },
        tips,
        parkingReimbursements: tips.filter((tip) => tip.category == 'parking_reimbursement'),
        billingInfo: null,
        activities: null,
        messages: null,
        rooms,
        jobId: sharedLinkData.job.id,
        totalTipsValue: tips.reduce((acc, tip) => acc + tip.amount, 0),
        address: {
          address1: sharedLinkData.address.address,
          address2: sharedLinkData.address.unit
        },
        proReportedDuration: this.pros.parseDurationIntoHoursAndMintes(sharedLinkData.homekeeper_job.job_durations.reported_by_homekeeper, 'proReportedDuration'),
        fromStartDuration: this.pros.parseDurationIntoHoursAndMintes(sharedLinkData.homekeeper_job.job_durations.from_start_to_end_moment, 'fromStartDuration'),

        beforePhotos,
        afterPhotos,
      };
      try {
        this.pastJobPdfProvider.exportToPDF(params);
    } catch (err) {
      console.error(err);
    }
  }
}

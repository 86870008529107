<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add to Team'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="field-top-padding field-bottom-padding">
            <tidy-select
              [icon]="'assets/svg/person-circle-outline.svg'"
              [label]="'User'"
              [items]="userItems"
              [form]="form.controls.user"
              formControlName="user"
              [submitted]="submitted"
              [errorMessage]="'Please select a user'">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              [icon]="'assets/svg/people-circle-outline.svg'"
              [label]="'Add to Team'"
              [items]="teamItems"
              [form]="form.controls.team"
              formControlName="team"
              [submitted]="submitted"
              [errorMessage]="'Please select a team'">
            </tidy-select>
          </tidy-row>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add to Team'"
          [action]="addUserToTeam.bind(this)"
          class="primary">
        </tidy-button>

        <tidy-alert *ngIf="isConvertingAdminToMember()">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Adding an admin to a team will change their user type to team member. This means they will only be able to see users and properties in their team.'">
            </tidy-text>
          </tidy-row>
        </tidy-alert>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewChild } from '@angular/core';
import { VideoComponent } from 'src/tidy-ui-components/components/video/video.component';

import { BookJob } from 'src/providers/book-job/book-job';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'rental-get-started.html'
})

export class RentalGetStartedPage {

  @ViewChild('rentalVideo') rentalVideo: VideoComponent;

  constructor(
    private bookJobService: BookJob,
    private navCtrl: CustomNavController,
    public windowService: WindowService,
    private util: Util
  ) {}

  goToBookJob() {
    this.rentalVideo.pause();
    localStorage.setItem('isRentalClient', 'true');
    this.bookJobService.clearBookJobStorage();
    this.navCtrl.navigateForward('add-first-property/rental');
  }

  goToViewApp() {
    this.rentalVideo.pause();
    localStorage.setItem('isRentalClient', 'true');
    const params = {
      cameFromRentalOnboardingViewApp: true
    }
    this.navCtrl.navigateForward('add-first-property/rental', params);
  }

  goToRentalDemoPage() {
    this.util.openUrl('https://www.tidy.com/get-demo');
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        body="We accept ACH or Wire Transfer for payments over $500. When possible, please include your customer ID ({{customerId}}) on payments for faster processing.">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/send-money.svg'"
          class="title-size">
        </tidy-image>
        <tidy-text
          [title]="'ACH Instructions'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Please remit ACH payments to:'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Routing Number: 084106768'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Account Number: 9800063165'">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-card>
      <tidy-row class="vertical-align-center">
        <tidy-image
          [src]="'assets/img/wire.svg'"
          class="title-size">
        </tidy-image>
        <tidy-text
          [title]="'Wire Instructions'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Please remit Wire payments to:'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Routing Number: 084106768'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'Account Number: 9800063165'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [header]="'Beneficiary Info'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Tidy Services, Inc.'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'2093 Philadelphia Pike, #1446 Claymont, DE 19703'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [header]="'Bank Info'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Evolve Bank & Trust'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'6070 Poplar Ave, Suite 200 Memphis, TN 38119'">
        </tidy-text>
      </tidy-row>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<ion-content class="ion-content-right-column">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>
import { Directive, HostListener, Input, AfterViewChecked} from '@angular/core';
import { IonTextarea } from '@ionic/angular';

@Directive({
  selector: '[customTextarea]'
})

export class CustomTextareaDirective {
  @Input() text: string;
  textareaElement: HTMLTextAreaElement;
  disableAfterViewCheckedTime: number;
  
  @Input()
  runNgAfterViewChecked: boolean = false;

  constructor(
    private ionTextarea: IonTextarea
  ) {
    this.ionTextarea.getInputElement().then((textareaEl) => {
      this.textareaElement = textareaEl;
      this.calcSize();
    })

    const timeOut = 10000;

    this.disableAfterViewCheckedTime = window.setTimeout(() => {
      this.runNgAfterViewChecked = false;
    }, timeOut);
  }

  ngOnDestroy() {
    window.clearTimeout(this.disableAfterViewCheckedTime);
  }

  ngAfterViewChecked () {
    if(!this.runNgAfterViewChecked) {
      return;
    }

    this.calcSize();
  }

  calcSize() {
    if (this.textareaElement){
      this.textareaElement.style.height = '1px';
      this.textareaElement.style.height = `${this.textareaElement.scrollHeight}px`;
    }
  }

  @HostListener('keyup', ['$event']) onKeyDown(e) {
    this.calcSize();
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from 'src/providers/genome/genome';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { RoomModel } from 'src/models/to-do.model';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  templateUrl: 'remove-room.html'
})

export class ToDosRemoveRoomPage implements OnInit {

  room: RoomModel;
  errorMessage: string;
  isRemovingRoomFromAddress: boolean;
  isRightSideContent: boolean;

  constructor(
    private genome: Genome,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Remove Room');
    this.room = this.navCtrl.getParam('room') || dialogParams.room;
    this.isRemovingRoomFromAddress = this.navCtrl.getParam('isRemovingRoomFromAddress');
  }

  async markRoomAsDoNotClean() {
    let body = 'The Pro will see that you do not want them to clean this room. ';
    body += this.isRemovingRoomFromAddress ? 'This will affect all To-Do list.' : 'This only affects this To-Do list.'
    const params: ConfirmPageParamsModel = {
      title: 'Mark as Do Not Clean?',
      body: body,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmMarkRoomAsDoNotClean.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmMarkRoomAsDoNotClean() {
    try {
      this.storage.delete('addresses');
      if (this.isRemovingRoomFromAddress) {
        const addressId = localStorage.getItem('addressId');
        await this.genome.markRoomAsDoNotClean(addressId, this.room.id);
        this.navCtrl.navigateForward(`edit-property/${addressId}/edit-rooms`);
      } else {
        await this.toDos.markRoomAsDoNotClean();
        this.rightSidePanelService.navigateTo('to-dos');
      }
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async deleteRoom() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete Room?',
      body: 'This will delete the room from all To-Do lists. You will not be able to undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete Room',
      confirmAction: this.confirmDeleteRoom.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDeleteRoom() {
    try {
      this.storage.delete('addresses');
      if (this.isRemovingRoomFromAddress) {
        const addressId = localStorage.getItem('addressId');
        await this.genome.deleteRoom(addressId, this.room.id);
        this.navCtrl.navigateForward(`edit-property/${addressId}/edit-rooms`);
      } else {
        await this.toDos.deleteRoom();
        await this.storage.save('didMakeToDoUpdates', true);
        this.rightSidePanelService.navigateTo('to-dos');
      }
      this.modalCtrl.dismiss();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }
}

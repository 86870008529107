<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!windowService.isDesktopRes"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="isUpdatingIssue ? 'Task' : 'Add Task'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 15px;">
        <!-- Members Section -->
        <div>
          <tidy-text [smallBody]="'Members'" style="display: block; margin-bottom: 5px;"></tidy-text>
          <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 5px;">
            <ng-container *ngFor="let user of issue?.checkedUsers">
              <div style="display: inline-flex; align-items: center;" *ngIf="user?.name == 'Concierge'">
                <tidy-image [src]="'assets/img/concierge.svg'" style="width: 26px"></tidy-image>
              </div>
              <div *ngIf="user?.name !== 'Concierge'">
                <tidy-initials-icon [fullName]="(user?.name | slice:0:1).toUpperCase()"></tidy-initials-icon>
              </div>
            </ng-container>
            <tidy-image
              mat-icon-button [matMenuTriggerFor]="personMenu" (click)="$event.stopPropagation()"
              [src]="'assets/img/add-light-grey.svg'"
              [hoverSrc]="'assets/img/add-dark-grey.svg'"
              style="width:26px;">
            </tidy-image>
          </div>
        </div>

        <!-- Jobs Section -->
        <div>
          <tidy-text [smallBody]="'Jobs'" style="display: block; margin-bottom: 5px;"></tidy-text>
          <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 5px;">
            <ng-container *ngFor="let job of issue?.checkedJobs">
              <div style="display: inline-flex; align-items: center; background: #E4E6EA; border-radius: 6px; border: 1px solid #dddddd; padding: 5px;">
                <tidy-image
                  [src]="job?.icon_url"
                  style="width: 14px; margin-right: 4px;">
                </tidy-image>
                <tidy-text [body]="job?.name"></tidy-text>
              </div>
            </ng-container>
            <tidy-image
              mat-icon-button [matMenuTriggerFor]="jobMenu" (click)="$event.stopPropagation()"
              [src]="'assets/img/add-square-light-grey.svg'"
              [hoverSrc]="'assets/img/add-square-dark-grey.svg'"
              style="width:26px;">
            </tidy-image>
          </div>
        </div>

        <!-- Items Section -->
        <div>
          <tidy-text [smallBody]="'Items'" style="display: block; margin-bottom: 5px;"></tidy-text>
          <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 5px;">
            <ng-container *ngFor="let item of issue?.checkedItems">
              <div style="display: inline-flex; align-items: center; background: #E4E6EA; border-radius: 6px; border: 1px solid #dddddd; padding: 5px;">
                <tidy-image
                  *ngIf="item?.model?.default_icon_url"
                  [src]="item?.model?.default_icon_url"
                  style="width: 14px; margin-right: 4px;">
                </tidy-image>
                <tidy-text [body]="item?.name"></tidy-text>
              </div>
            </ng-container>
            <tidy-image
              mat-icon-button [matMenuTriggerFor]="itemMenu" (click)="$event.stopPropagation()"
              [src]="'assets/img/add-square-light-grey.svg'"
              [hoverSrc]="'assets/img/add-square-dark-grey.svg'"
              style="width:26px;">
            </tidy-image>
          </div>
        </div>
      </div>

      <tidy-alert *ngIf="issue?.concierge_items?.length">
        <tidy-row class="vertical-align-center">
          <tidy-image
            src="assets/img/concierge.svg"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Concierge Action Plan'">
          </tidy-text>
        </tidy-row>
        <ng-container  *ngFor="let item of issue?.concierge_items; let last = last">
          <tidy-card-button (action)="goToConciergeMessage(item)" [class.no-below-stroke]="last">
            <tidy-row class="vertical-align-center">
              <tidy-text
                [body]="item?.metadata?.request_data?.subject">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-text
                [helper]="item?.metadata?.request_data?.body">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center no-padding">
              <tidy-text
                *ngIf="item?.metadata?.request_data?.scheduled == 'Immediate' || item?.metadata?.request_data?.scheduled == 'immediate'"
                [helper]="'Scheduled to do immediately'">
              </tidy-text>
              <ng-container *ngIf="item?.metadata?.request_data?.scheduled !== 'Immediate' && item?.metadata?.request_data?.scheduled !== 'immediate'">
                <tidy-text
                  [helper]="'Scheduled to do on'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [helper]="(item?.metadata?.request_data?.scheduled | customdate: 'M/D/Y')">
                </tidy-text>
              </ng-container>
            </tidy-row>
          </tidy-card-button>
        </ng-container>
      </tidy-alert>

      <tidy-card style="margin-top: 5px">
        <form [formGroup]="issueForm" novalidate action="#">
          <tidy-row>
            <tidy-textarea
              label="Title"
              [icon]="'assets/svg/checkbox-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a title'"
              class="title-input"
              [form]="issueForm.get('title')"
              formControlName="title">
            </tidy-textarea>
          </tidy-row>
          <tidy-row>
            <tidy-datepicker
              label="Due Date"
              [icon]="'assets/svg/calendar-outline.svg'"
              formControlName="due_date"
              [form]="issueForm.controls.due_date">
            </tidy-datepicker>
          </tidy-row>
          <tidy-row>
            <tidy-select
              label="Status"
              [icon]="'assets/svg/help-circle-outline.svg'"
              [items]="statusOptions"
              [submitted]="submitted"
              [errorMessage]="'Please select a status'"
              [form]="issueForm.get('status')"
              formControlName="status">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              label="Type"
              [icon]="'assets/svg/information-circle-outline.svg'"
              [items]="issueTypeOptions"
              [submitted]="submitted"
              [form]="issueForm.get('issue_report_type_id')"
              formControlName="issue_report_type_id">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              label="Property"
              [icon]="'assets/svg/home-outline.svg'"
              [items]="propertyOptions"
              [submitted]="submitted"
              [form]="issueForm.get('address_id')"
              formControlName="address_id"
              (optionChange)="getAllJobsForAddress()">
            </tidy-select>
          </tidy-row>
          <tidy-row>
            <tidy-select
              label="Urgency"
              [icon]="'assets/svg/alert-circle-outline.svg'"
              [items]="urgencyOptions"
              [submitted]="submitted"
              [form]="issueForm.get('urgency')"
              formControlName="urgency">
            </tidy-select>
          </tidy-row>
          <tidy-row class="no-padding">
            <tidy-textarea
            label="Description"
            [icon]="'assets/svg/create-outline.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a description'"
            [form]="issueForm.get('description')"
            formControlName="description">
          </tidy-textarea>
          </tidy-row>
        </form>

        <mat-menu #personMenu="matMenu" class="filter-menu">
          <ng-container *ngFor="let user of issue?.allUsers">
            <button mat-menu-item class="tree-node-padding">
              <mat-checkbox
                class="checkbox"
                [checked]="user?.checked"
                (click)="$event.stopPropagation()"
                (change)="checkOrUncheckPerson(issue, user, $event)">
                {{user?.name}}
              </mat-checkbox>
            </button>
          </ng-container>
        </mat-menu>

        <mat-menu #jobMenu="matMenu" class="filter-menu">
          <ng-container *ngFor="let job of issue?.allJobs">
            <button mat-menu-item class="tree-node-padding">
              <mat-checkbox
                class="checkbox"
                (click)="$event.stopPropagation()"
                [checked]="job?.checked"
                (change)="checkOrUncheckJob(job, $event)">
                {{job?.name}}
              </mat-checkbox>
            </button>
          </ng-container>
          <div *ngIf="issue?.allJobs && !issue?.allJobs?.length" style="margin: 10px">
            <tidy-text
              [helper]="'<i>No Jobs Booked</i>'">
            </tidy-text>
          </div>
          <div *ngIf="!issue?.allJobs" style="margin: 10px;">
            <tidy-text
              [helper]="'<i>Loading...</i>'">
            </tidy-text>
          </div>
        </mat-menu>

        <mat-menu #itemMenu="matMenu" class="filter-menu">
          <ng-container *ngFor="let item of allItems">
            <button mat-menu-item class="tree-node-padding">
              <mat-checkbox
                class="checkbox"
                (click)="$event.stopPropagation()"
                [checked]="item?.checked"
                (change)="checkOrUncheckItem(item, $event)">
                {{item?.name}}
              </mat-checkbox>
            </button>
          </ng-container>
          <div *ngIf="allItems && !allItems?.length" style="margin: 10px">
            <tidy-text
              [helper]="'<i>No Items Added</i>'">
            </tidy-text>
          </div>
          <div *ngIf="!allItems" style="margin: 10px;">
            <tidy-text
              [helper]="'<i>Loading...</i>'">
            </tidy-text>
          </div>
        </mat-menu>

        <tidy-row *ngIf="reportPhotos?.length > 0" [align]="'center'">
          <tidy-text
            body="Add Photo"
            class="link"
            (click)="$event.stopPropagation()"
            (action)="addAttachment(issue)">
          </tidy-text>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true" *ngIf="reportPhotos?.length > 0">
          <tidy-text
            header="Photos"
            style="margin-right: 5px">
          </tidy-text>
          <tidy-text
            body="Add Photo"
            class="link"
            (click)="$event.stopPropagation()"
            (action)="addAttachment(issue)">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let attachment of reportPhotos; let i = index" class="field-top-padding">
          <tidy-row>
            <tidy-image
              [src]="attachment.photo_url.includes('http') ? attachment.photo_url : attachment.full_photo_url"
              style="width: 100%">
            </tidy-image>
          </tidy-row>
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [actionBody]="'remove'"
              (action)="removeAttachment(i, attachment)"
              class="link red">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-button *ngIf="!isUpdatingIssue"
        [action]="saveIssue.bind(this)"
        class="primary save-issue-button"
        [text]="'Save'">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row class="extra-bottom-padding" [align]="'center'" *ngIf="isUpdatingIssue">
        <tidy-text
          [helper]="'Reported by'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [helper]="issue.reporter_name">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [helper]="'at'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [helper]="issue.created_at | customdate:'h:mma'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [helper]="'on'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [helper]="issue.created_at | customdate:'M/D/YY'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'">
        <tidy-text *ngIf="isUpdatingIssue && !issue?.archived_at"
          [body]="'Archive Task'"
          class="link red"
          (action)="archiveTask()">
        </tidy-text>
        <tidy-text *ngIf="isUpdatingIssue && issue?.archived_at"
          [body]="'Unarchive Task'"
          class="link green"
          (action)="unarchiveTask()">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" *ngIf="isUpdatingIssue && issue?.archived_at">
        <tidy-text
          [body]="'Delete Task'"
          class="link red"
          (action)="deleteTask()">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="issue?.id">
        <tidy-row>
          <tidy-text
            header="Activity">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="messageForm">
          <tidy-row>
            <tidy-textarea
              class="select-custom-input"
              [label]="'Write a Note'"
              formControlName="message"
              [form]="messageForm.controls.message"
              [submitted]="messageSubmitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)">
            </tidy-textarea>
          </tidy-row>
        </form>
        <ng-container *ngIf="!activities?.length">
          <tidy-alert>
            <tidy-text
              [helper]="'No Activity'">
            </tidy-text>
          </tidy-alert>
        </ng-container>
        <ng-container *ngFor="let activity of activities">
          <div style="margin-bottom: 10px">
            <ng-container *ngIf="activity?.activity_type === 'comment'">
              <div class="activity-header">
                <div class="flex-content">
                  <div style="margin-right: 5px; margin-top: 2px">
                    <tidy-initials-icon class="activity-initials" [fullName]="activity?.userName">
                    </tidy-initials-icon>
                  </div>
                  <div style="margin-right: 5px">
                    <tidy-text [header]="activity?.userName"></tidy-text>
                  </div>
                  <div>
                    <tidy-text
                      [matTooltip]="!activity?.pastMoreThanOneDay ? activity?.createdAt : null"
                      [helper]="!activity?.pastMoreThanOneDay ? activity?.textDate : activity?.createdAt"
                      class="small-text"></tidy-text>
                  </div>
                </div>
              </div>
              <div style="margin-left: 35px">
                <tidy-alert style="margin-top: 5px; margin-bottom: 5px">
                  <tidy-text [body]="activity?.description"></tidy-text>
                </tidy-alert>
                <tidy-text
                  *ngIf="activity?.by_user?.id == issue?.reporter_id"
                  [body]="'Delete'"
                  (action)="deleteActivity(activity)"
                  class="link red">
                </tidy-text>
              </div>
            </ng-container>
            <ng-container *ngIf="activity?.activity_type !== 'comment'">
              <div class="activity-header">
                <div class="flex-content">
                  <div style="margin-right: 5px; margin-top: 2px">
                    <tidy-initials-icon class="activity-initials" [fullName]="activity?.userName">
                    </tidy-initials-icon>
                  </div>
                  <div>
                    <tidy-text [body]="activity?.description"></tidy-text>
                  </div>
                </div>
              </div>
              <div class="activity-photo" *ngIf="activity?.photo">
                <img [src]="activity?.photo" (click)="openPhoto(activity?.photo)">
              </div>
              <div style="margin-left: 40px; margin-top: 5px">
                <tidy-text
                  [matTooltip]="!activity?.pastMoreThanOneDay ? activity?.createdAt : null"
                  [helper]="!activity?.pastMoreThanOneDay ? activity?.textDate : activity?.createdAt"
                  class="small-text"></tidy-text>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div style="height: 100px"></div>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!windowService.isDesktopRes">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="windowService.isDesktopRes">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>






import { Component, OnDestroy, OnInit } from '@angular/core';
import { Integrations } from 'src/providers/integrations/integrations';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { WindowService } from 'src/shared/providers/window.service';
import { debounceTime } from 'rxjs/operators';
import { AddCustomFieldPage } from './add-custom-field/add-custom-field';
import { Subscription } from 'rxjs';
import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.html',
  styleUrls: ['./integration-settings.scss'],
})
export class IntegrationSettingsPage implements OnInit, OnDestroy {
  integration: any;
  dialogParams: any;
  isRightSideContent: boolean;
  loaded: boolean = false;
  form: FormArray;
  selectedChipTextAreaId: string;
  variables: any;
  parsedVariables: any;
  savingText: string = '';
  panelClosedSubscription$: Subscription;
  addressName: string;
  customFields: any;
  guestReservation: any;
  hoveredFieldName: string | null = null;

  constructor(
    private integrations: Integrations,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private navCtrl: CustomNavController,
    private formBuilder: FormBuilder,
    public windowService: WindowService
  ) {}

  async ngOnInit() {
    try {
      this.createGuestReservation();
      await this.initializeRightSideContent();
      await this.getData();
      this.watchPanelClosed();
    } catch (error) {
      console.error('error', error);
    }
  }

  createGuestReservation() {
    const twoDaysBefore = LuxonDateTime.now().minus({ days: 2 }).toISO();
    const twoDaysAfter = LuxonDateTime.now().plus({ days: 2 }).toISO();
    this.guestReservation = {
      check_in_date: twoDaysBefore,
      check_in_time: '10:00',
      check_out_date: twoDaysAfter,
      check_out_time: '10:00',
    };
  }
  ngOnDestroy() {
    this.panelClosedSubscription$.unsubscribe();
  }

  watchPanelClosed() {
    this.panelClosedSubscription$ = this.rightSidePanelService
      .afterPanelClosed()
      .subscribe(() => {
        this.getData();
      });
  }

  async getData() {
    try {
      this.loaded = false;
      await this.loadIntegrationData();
      this.addressName = this.getAddressName(this.integration?.address);
      const [reservationSourceMappings, overridableFields, customFields] =
        await this.fetchMappingsAndFields();
      const notMappedFields = this.getNotMappedFields(
        reservationSourceMappings,
        overridableFields,
        customFields
      );
      await this.saveNotMappedFields(notMappedFields);
      await this.populateForm(
        reservationSourceMappings,
        notMappedFields,
        customFields
      );
      await this.loadVariables();
      this.watchFormChanges();
      this.loaded = true;
    } catch (error) {
      console.error('error', error);
    }
  }

  private async initializeRightSideContent() {
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Integration Settings');
    }
  }

  private async loadIntegrationData() {
    this.integration =
      this.navCtrl.getParam('integration') ||
      this.dialogParams?.integration ||
      (await this.storage.retrieve('integration'));
    this.storage.save('integration', this.integration);
  }

  private async fetchMappingsAndFields() {
    return Promise.all([
      this.integrations.getReservationSourceMappings(this.integration.id),
      this.integrations.getReservationSourceMappingsOverridableFields(
        this.integration.id
      ),
      this.integrations.getCustomFields(),
    ]);
  }

  private getNotMappedFields(
    reservationSourceMappings,
    overridableFields,
    customFields
  ) {
    return [...overridableFields, ...customFields].filter(
      (field) =>
        !reservationSourceMappings.some(
          (mapping) => mapping.field?.field_name === field.field_name
        )
    );
  }

  private async saveNotMappedFields(notMappedFields) {
    await Promise.all(
      notMappedFields.map(async (field) => {
        await this.integrations.saveNewReservationSourceMapping({
          field_id: field.id,
          field_type: field.type,
          guest_reservation_source_id: this.integration.id,
          source_data: null,
        });
      })
    );
  }

  private async populateForm(
    reservationSourceMappings,
    notMappedFields,
    customFields
  ) {
    this.form = this.formBuilder.array([]);
    const addedFieldNames = new Set();
    const allData =
      notMappedFields.length > 0
        ? await this.integrations.getReservationSourceMappings(
            this.integration.id
          )
        : reservationSourceMappings;

    allData.forEach((mapping) => {
      const fieldName = mapping?.field?.field_name || mapping?.field_name;
      if (addedFieldNames.has(fieldName)) {
        return;
      }
      const customField = customFields.find(
        (field) => mapping?.field?.id === field.id
      );
      this.form.push(
        this.formBuilder.group({
          id: [mapping.id, Validators.required],
          field_name: [fieldName, Validators.required],
          source_data: [mapping?.source_data || 'None', Validators.required],
          type: [mapping?.field?.type || mapping?.type, Validators.required],
          is_visible_to_homekeeper: [
            customField?.is_visible_to_homekeeper,
            Validators.required,
          ],
          custom_field_id: [customField?.id, Validators.required],
        })
      );
      addedFieldNames.add(fieldName);
    });
  }

  private async loadVariables() {
    this.variables =
      await this.integrations.getReservationSourceMappingsVariables(
        this.integration.id
      );
    this.parsedVariables = this.getParsedVariables();
  }

  getCustomFields(formValue: any) {
    this.customFields = formValue
      .filter((field) => field.type === 'UserCustomField')
      .map((mapping) => ({
        name: mapping.field_name,
        value: mapping.source_data.replace('{{', '').replace('}}', ''),
      }));
  }

  watchFormChanges() {
    this.form.valueChanges.pipe(debounceTime(1000)).subscribe(async (value) => {
      try {
        this.getCustomFields(value);
        this.savingText = 'Saving...';
        const payload = {
          guest_reservation_source_id: this.integration.id,
          mappings: value.map((mapping) => ({
            guest_reservation_source_mapping_id: mapping.id,
            source_data:
              mapping.source_data === 'None' ? null : mapping.source_data,
          })),
        };
        await this.integrations.updateIntegrationFields(payload);
        this.savingText = 'Saved Successfully';
        setTimeout(() => {
          this.savingText = '';
        }, 3000);
      } catch (error) {
        console.error('error', error);
        this.savingText = 'Error Saving';
      }
    });
  }

  updateCustomField(field: any) {
    const value = field.get('is_visible_to_homekeeper')?.value;
    const id = field.get('custom_field_id')?.value;
    this.integrations.updateCustomField(id, {
      is_visible_to_homekeeper: value,
    });
  }

  getParsedVariables() {
    const variables = this.variables;
    variables.unshift({
      variable: '{{None}}',
      value: 'None',
    });
    return variables.map((variable) => ({
      viewValue:
        variable.variable.replace(/\{\{|\}\}/g, '').trim() +
        (variable?.example ? ' (e.g. ' + variable?.example + ')' : ''),
      value: variable.value || variable.variable,
    }));
  }

  setSelectedChipTextArea(id: string): void {
    this.selectedChipTextAreaId = id;
  }

  goBack(): void {
    this.navCtrl.navigateBack('integrations/all');
  }

  addCustomField(): void {
    try {
      if (this.windowService.isDesktopRes) {
        this.storage.save('dialog-right-side-open', true);
        this.rightSidePanelService.openRightSidePanel(AddCustomFieldPage);
      } else {
        this.storage.save('dialog-right-side-open', false);
        this.navCtrl.navigateForward('integrations/add-custom-field');
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  goToIntegrations() {
    this.navCtrl.navigateForward('integrations/all');
  }

  getAddressName(address) {
    let addressName = '';
    if (address.address_name) {
      addressName = address.address_name;
    } else if (address.address2) {
      addressName = address.address1 + ', ' + address.address2;
    } else {
      addressName = address.address1;
    }
    return addressName;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [noBow]="true"
    [title]="'Book Job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-alert>
      <tidy-row [alignLastItemRight]="true">
        <div style="width:85%">
          <tidy-text
            [header]="(proposal?.service_details?.service_name | titlecase)">
          </tidy-text>
        </div>
        <div>
          <tidy-text
            [body]="(proposal?.service_details?.price | tcurrency)">
          </tidy-text>
        </div>
      </tidy-row>

      <tidy-row style="width:85%">
        <tidy-text
          [body]="proposal?.description">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Create Account to Book'">
        </tidy-text>
      </tidy-row>

      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            formControlName="firstName"
            [form]="form.controls.firstName"
            [label]="'First Name'"
            [errorMessage]="'Please enter your first name.'"
            [submitted]="submitted"
            [icon]="'assets/svg/person-outline.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-input
            formControlName="lastName"
            [form]="form.controls.lastName"
            [label]="'Last Name'"
            [errorMessage]="'Please enter your last name.'"
            [submitted]="submitted"
            [icon]="'assets/svg/person-outline.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="field-bottom-padding">
          <tidy-input
            formControlName="email"
            [form]="form.controls.email"
            [label]="'Email'"
            type="email"
            [errorMessage]="'Please enter a valid email'"
            [submitted]="submitted"
            [icon]="'assets/svg/mail-outline.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="extra-bottom-padding">
          <tidy-input
            formControlName="password"
            [form]="form.controls.password"
            [label]="'Password'"
            [errorMessage]="'Password enter a password.'"
            [submitted]="submitted"
            type="password"
            [icon]="'assets/svg/lock-closed-outline.svg'">
          </tidy-input>
        </tidy-row>

        <tidy-row class="extra-top-padding vertical-align-center">
          <!-- TODO address inline styles: https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-->
          <tidy-checkbox
            style="margin-right: 5px"
            formControlName="terms"
            [form]="form.controls.terms"
            [items]="[{viewValue: '', value: true}]">
          </tidy-checkbox>
          <tidy-text
            [body]="'I agree to the '">
          </tidy-text>
          <tidy-text
            (action)="openTerms()"
            [body]="'terms'"
            class="link">
          </tidy-text>
        </tidy-row>
      </form>

    <tidy-row *ngIf="termsError">
      <tidy-error-message
        [text]="'You must agree to the terms to continue.'">
      </tidy-error-message>
    </tidy-row>
  </tidy-card>

  <tidy-error-message
    [text]="errorMessage">
  </tidy-error-message>

  <tidy-button
    [text]="'Next'"
    class="primary"
    [action]="createAccount.bind(this)">
  </tidy-button>

  <tidy-row [align]="'center'">
    <tidy-text
      [body]="'Nevermind, Book Later'"
      (action)="goToClientHub()"
      class="link">
    </tidy-text>
  </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper><ng-template #content>

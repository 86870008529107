import { Injectable } from '@angular/core';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Geolocation, PositionOptions } from '@capacitor/geolocation';
import { DateTime as LuxonDateTime } from 'luxon';

interface PositionModel {
  latitude: number;
  longitude: number;
  error?: string;
}

const defaultOptions: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 50000,
};

@Injectable({
  providedIn: 'root',
})
export class LocationProvider {
  constructor(private storage: TidyStorage) {
    this.get(true);
    setInterval(() => {
      this.get(true);
    }, 60000);
  }

  public async get(trueLocation: boolean = false): Promise<PositionModel> {
    try {
      const locationPermission = await Geolocation.checkPermissions();
      if (locationPermission.location === 'prompt' && navigator.geolocation) {
        return await new Promise<PositionModel>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              await this.storage.save('locationTimeStamp', LuxonDateTime.now());
              await this.storage.save('location', location);
              resolve(location);
            },
            (error) => {
              console.error('Error getting location', error);
              reject(this.returnNoLocation({ message: 'Location permission not granted' }));
            },
            {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 50000
            }
          );
        });
      }
      if (locationPermission.location !== 'granted') {
        return this.returnNoLocation({
          message: 'Location permission not granted',
        });
      } else {
        return await new Promise<PositionModel>(async (resolve, reject) => {
          const failTimeout = setTimeout(() => {
            reject({});
          }, 15000);
          let location = await this.storage.retrieve('location');
          const secondsSinceLastCache = await this.getSecondsSinceLastCache();
          if (trueLocation || !location || secondsSinceLastCache > 180) {
            location = await this.getLocationFromCapacitor(defaultOptions);
            await this.storage.save('locationTimeStamp', LuxonDateTime.now());
            await this.storage.save('location', location);
          }
          resolve(location);
        });
      }
    } catch (err) {
      return this.getLowAccuracy();
    }
  }

  public async getLowAccuracy(): Promise<PositionModel> {
    try {
      return await new Promise<PositionModel>(async (resolve, reject) => {
        const failTimeout = setTimeout(() => {
          reject({ message: 'fail safe timeout triggered' });
        }, 15000);
        const options = {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 50000,
        };
        const location = await this.getLocationFromCapacitor(options);
        await this.storage.save('locationTimeStamp', LuxonDateTime.now());
        await this.storage.save('location', location);
        resolve(location);
      });
    } catch (err) {
      return this.returnNoLocation(err);
    }
  }

  async getLocationFromCapacitor(options) {
    const currentLocation = await Geolocation.getCurrentPosition(options);
    return {
      latitude: currentLocation.coords.latitude,
      longitude: currentLocation.coords.longitude,
    };
  }

  async getSecondsSinceLastCache() {
    const locationTimeStamp = await this.storage.retrieve('locationTimeStamp');
    const luxonLocationTimeStamp = LuxonDateTime.fromISO(locationTimeStamp);
    const luxonCurrentTime = LuxonDateTime.now();
    const timeSinceLastCache = luxonCurrentTime.diff(luxonLocationTimeStamp, [
      'seconds',
    ]);
    const timeSinceLastCacheObject = timeSinceLastCache.toObject();
    return timeSinceLastCacheObject.seconds;
  }

  private async returnNoLocation(err) {
    const error = err.message ? err.message : 'Error acquiring user location';
    console.error(err, `Get position error: ${error}`);
    const location: any = {
      latitude: 0,
      longitude: 0,
    };
    await this.storage.save('locationTimeStamp', LuxonDateTime.now());
    await this.storage.save('location', location);
    location['error'] = error;
    return location;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customdate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string, format: string, type: string, parseMask: string = null): string {
    if (type === 'utc') {
      return moment(value, parseMask).utc().format(format)
    } else {
      return moment(value, parseMask).format(format);
    }
  }
}

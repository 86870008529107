import { Component, HostBinding, Input, ViewEncapsulation, ContentChildren, QueryList } from '@angular/core';
import { FabListComponent } from './fab-list/fab-list.component'

@Component({
  selector: 'tidy-fab',
  template: `
  <ion-fab
    vertical="bottom"
    horizontal="end"
    slot="fixed"
    (click)="toggle()"
    [class.fab-open]="open"
    >
    <ng-content></ng-content>
  </ion-fab>
  `,
  styleUrls: ['fab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FabComponent {

  @ContentChildren(FabListComponent) components : QueryList<FabListComponent>;
  @Input() text: string;
  @Input() icon = 'assets/svg/add-heavy.svg';
  @Input() size = 115;
  @Input() top;
  @Input() disableToggle = false;
  @Input() isRightSidePanelButton = false;
  open = false;

  @HostBinding('style.--content-right-corner') get contentRightCorner() {
    if (this.isRightSidePanelButton) {
      return '25px';
    }
  }

  @HostBinding('style.--fab-btn-size') get buttonSize() {
    return `${this.size}px`;
  }

  @HostBinding('style.--fab-list-left') get listLeftPosition() {
    return `-${this.size/2 - 15}px`;
  }

  @HostBinding('style.--fab-btn-top') get listTopPosition() {
    if (window.innerHeight < this.top) {
      return `${window.innerHeight - 60}px`
    }
    return `${this.top - 20}px`;
  }

  toggle() {
    if (this.disableToggle) {
      return;
    }
    this.open = !this.open;
    this.components.toArray().forEach(component => {
      component['openfab'] = this.open;
    });
  }
}

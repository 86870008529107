import { Injectable } from "@angular/core";
import { HttpClientCustom } from "src/providers/custom/http-client";

@Injectable({
  providedIn: "root",
})
export class OpenAIService {
  constructor(private httpClient: HttpClientCustom) {}

  tidyAI(payload) {
    const url = "api/v1/customer/ai";
    const timeout = 60000;
    return this.httpClient.post(url, payload, timeout);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { UserService } from 'src/providers/user/user';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';

import validationUtils from 'src/shared/util/validation-utils';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { UserPage } from '../user/user';
import { UsersPage } from '../users';

@Component({
  templateUrl: 'edit-user-details.html'
})

export class EditUserDetailsPage extends TimeoutableComponent implements OnInit {

  addressId: number;
  clientInfo: any;
  errorMessage: string;
  form: UntypedFormGroup;
  member: any;
  roleItems: any;
  submitted: boolean;
  teamItems: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private teamService: TeamService,
    private userService: UserService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.minLength(2)])],
      email: ['', [validationUtils.validateEmail]],
      password: [''],
      phone: [''],
      role: ['', Validators.required],
      team: ['', Validators.required],
      teamName: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    this.member = await this.getParam('member');
    this.rightSidePanelService.setDialogPageTitle('Edit ' + this.member?.first_name);
    this.form.patchValue({
      firstName: this.member?.first_name,
      lastName: this.member?.last_name,
      email: this.member?.email,
      phone: this.member.phone !== null ? this.member.phone : ''
    });
    const userRole = localStorage.getItem('user_role');
    if (userRole == 'member') {
      this.navCtrl.navigateRoot('dashboard');
    }
    this.getRoleItems();
    if (this.member.role !== 'owner') {
      this.form.patchValue({role: this.member.role});
    }
    this.clientInfo = await this.getParam('clientInfo');
    if (this.clientInfo?.customer_account.account_type == 'regular') {
      this.form.controls.role.clearValidators();
      this.form.controls.role.updateValueAndValidity();
    }
    await this.getTeamItems();
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  getRoleItems() {
    this.roleItems = [
      {
        value: 'admin',
        viewValue: 'Account Admin'
      },
      {
        value: 'member',
        viewValue: 'Team Manager'
      }
    ]
  }

  async getTeamItems() {
    this.teamItems = [];
    const teams = await this.teamService.getTeams();
    teams.map((team) => {
      this.teamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
  }

  @Loading('', true)
  async updateMember() {
    if (this.form.value.role == 'member' && this.member.role == 'admin') {
      if (this.teamItems.length > 0) {
        this.form.controls.team.setValidators([Validators.required]);
        this.form.controls.teamName.clearValidators();
        this.form.controls.teamName.updateValueAndValidity();
      } else {
        this.form.controls.teamName.setValidators([Validators.required]);
        this.form.controls.team.clearValidators();
        this.form.controls.team.updateValueAndValidity();
      }
    } else {
      this.form.controls.team.clearValidators();
      this.form.controls.team.updateValueAndValidity();
      this.form.controls.teamName.clearValidators();
      this.form.controls.teamName.updateValueAndValidity();
      this.form.controls.role.clearValidators();
      this.form.controls.role.updateValueAndValidity();
    }
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const data: any = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email
    }
    if (this.form.value.password !== '') {
      data['password'] = this.form.value.password;
    }
    if (this.form.value.phone !== '') {
      data['phone'] = this.form.value.phone;
    }
    try {
      if (this.form.value.role == 'member' && this.member.role == 'admin') {
        let customerAccountTeamId = this.form.value.team;
        if (this.teamItems.length == 0) {
          const teamPayload = {
            name: this.form.value.teamName
          }
          const team = await this.teamService.createTeam(teamPayload);
          customerAccountTeamId = team.id;
        }
        const userPayload = {
          customer_member_id: this.member.id,
          customer_account_team_id: customerAccountTeamId,
          role: 'manage'
        }
        await this.teamService.addUserToTeam(userPayload);
        await this.userService.changeUserRole(this.member.id, {role: 'member'});
      } else if (this.form.value.role == 'admin' && this.member.role == 'member') {
        await this.removeMemberFromAllTeams();
        await this.userService.changeUserRole(this.member.id, {role: 'admin'});
      }
      await this.userService.updateUserInfo(data, this.member.id);
      await this.storage.save('userId', this.member.id);
      const isEmployerPortal = await this.getParam('isEmployerPortal');
      if (isEmployerPortal) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.rightSidePanelService.navigateTo(`user/${this.member.id}`, {}, UserPage);
      }
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async removeMemberFromAllTeams() {
    const teamsUserIsOn = await this.getParam('teamsUserIsOn');
    for (let i = 0; i < teamsUserIsOn.length; i++) {
      await this.teamService.removeUserFromTeam(teamsUserIsOn[i].team_member_id);
    }
  }

  async deleteMember() {
    const params: ConfirmPageParamsModel = {
      title: `Delete ${this.member.first_name}`,
      body: `Are you sure you want to remove ${this.member.first_name} account?`,
      backText: 'Go Back',
      confirmText: 'Delete Account',
      confirmAction: this.confirmDelete.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDelete() {
    try {
      await this.userService.deleteUser(this.member.id);
      this.rightSidePanelService.navigateTo('users', {}, UsersPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }
}

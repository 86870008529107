import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Pros } from 'src/providers/pros/pros';
import { Schedule } from 'src/providers/schedule/schedule';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'pro-messages.html',
})
export class ProMessagesPage implements OnInit {

  backPage: string;
  dialogParams: any;
  loaded: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  isRightSideContent: boolean;
  messages: any;
  pro: any;
  submitted: boolean;

  constructor(
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private pros: Pros,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private schedule: Schedule,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      const proId = this.route.snapshot.paramMap.get('proId') || this.dialogParams.proId;
      this.pro = this.navCtrl.getParam('pro') || this.dialogParams?.pro || (await this.pros.getProDetail(proId));
      const clientId = localStorage.getItem('customer_id');
      const AISuggestionsPayload = {
        feature: 'chat_suggestion',
        data: {
          chat_room_key: `customer_account_id-${clientId},team_id-${this.pro.team.id}`
        }
      }
      await this.communication.generateAISuggestions(AISuggestionsPayload);
      const messages = await this.communication.getSharedInbox(
        this.pro.team.id
      );
      this.messages = await messages.reverse();
      this.backPage = this.navCtrl.getParam('backPage') || this.dialogParams?.backPage || 'messages';
      await this.markMessagesAsRead();
      this.rightSidePanelService.setDialogPageTitle('Messages With ' + this.pro?.homekeeper?.first_name);
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (
        !message.is_read &&
        message.from_member.type !== 'CustomerMember' &&
        message?.chat_room_key
      ) {
        array.push(message?.chat_room_key);
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'account',
        data: {
          team_id: this.pro.team.id,
        },
      },
      message: {
        text: this.form.value.message,
        files: attachments,
      },
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (
        response?.data?.message ==
        "We couldn't send your message, please try again later!"
      ) {
        return (this.errorMessage = response.data.message);
      }
      this.form.patchValue({
        message: '',
      });
      const messages = await this.communication.getSharedInbox(
        this.pro.team.id
      );
      this.messages = await messages.reverse();
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  callPro() {
    window.location.href = `tel:${this.pro.homekeeper.phone}`;
  }

  getDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return LuxonDateTime.fromISO(date).setZone(timezone).toRelative();
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }
  
}

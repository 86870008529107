<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Send Payment'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Do you want to pay a pending bill or send payment for a new job?'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Send Payment for New Job'">
        </tidy-text>
      </tidy-row>

      <tidy-alert *ngIf="pendingInvoices?.length">
        <tidy-text
          [body]="'Please pay all outstanding bills before sending payment for a new job.'">
        </tidy-text>
      </tidy-alert>

      <tidy-card *ngIf="!pendingInvoices?.length">
        <tidy-card-button (action)="goToSendPayment()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/salary.svg'"
              [class]="body-size">
            </tidy-image>
            <tidy-text
              [body]="'New Job'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-row>
        <tidy-text
          [header]="'Send Payment for Pending Bills'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let invoice of pendingInvoices">
        <tidy-card-button (action)="goToInvoice(invoice)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              [class]="body-size">
            </tidy-image>
            <tidy-text
              [body]="(invoice?.amount | tcurrency)">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'total for'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="invoice?.items[0]?.service_details?.date | parseDate: 'M/D'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'job'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="pendingInvoices?.length >= 10">
        <tidy-card-button (action)="goToBillsPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              [class]="body-size">
            </tidy-image>
            <tidy-text
              body="View More">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-container *ngIf="showUploadButton">
  <div [ngStyle]="{ 'margin-top': showTextLink ? '0' : '0.5rem' }">
    <ng-container *ngIf="!isMobile">
      <ng-container *ngIf="!showTextLink">
        <input
          class="file-input"
          [id]="id"
          type="file"
          [multiple]="multiple"
          (change)="onFileChange($event)" />
        <label [for]="id" [innerHTML]="label"></label>
      </ng-container>
      <ng-container *ngIf="showTextLink">
        <input
          class="text-file-input"
          [id]="id"
          type="file"
          [multiple]="multiple"
          (change)="onFileChange($event)" />
        <label [for]="id" [innerHTML]="label"></label>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <div
        *ngIf="!showTextLink"
        (click)="pickPhotos()"
        class="div-button">
      </div>
      <tidy-text
        *ngIf="showTextLink"
        [actionBody]="label"
        (action)="pickPhotos()"
        [class]="'link'">
      </tidy-text>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="showPreview">
  <div style="margin-top: 1rem">
    <ng-container *ngIf="images.length > 0">
      <tidy-carrousel [scaleImages]="false" [slidesPerView]="slidesPerView">
        <ng-container *ngFor="let image of images; let i = index">
          <ng-container *tidyCarrousel>
            <div class="image-preview-container">
              <ng-container *ngIf="image?.mediaType === 'photo'">
                <img
                  [src]="image?.url"
                  alt="image"
                  class="file-image-preview"
                  [style.border-radius]="
                    image?.category ||
                    image?.uploadedBy ||
                    image?.uploadedAt ||
                    image?.location
                      ? '6px 6px 0px 0px'
                      : '6px'
                  "
                  [style.width]="
                    images.length === 1 ? '100% !important' : '200px'
                  " />
              </ng-container>
              <ng-container *ngIf="image?.mediaType === 'video'">
                <video
                  alt="video"
                  class="file-image-preview video-preview"
                  [style.border-radius]="
                    image?.category ||
                    image?.uploadedBy ||
                    image?.uploadedAt ||
                    image?.location
                      ? '6px 6px 0px 0px'
                      : '6px'
                  "
                  [style.width]="
                    images.length === 1 ? '100% !important' : '200px'
                  "
                  controls>
                  <source [src]="image?.url + '#t=0.001'" type="video/mp4">
                </video>
              </ng-container>
              <div
                class="file-caption"
                *ngIf="
                  image?.category ||
                  image?.uploadedBy ||
                  image?.uploadedAt ||
                  image?.location
                ">
                <ng-container *ngIf="image?.category">
                  <ion-row class="file-caption-row">
                    <tidy-text
                      [body]="'Category:' + ' ' + image?.category"></tidy-text>
                  </ion-row>
                </ng-container>
                <ng-container *ngIf="image?.uploadedBy">
                  <ion-row class="file-caption-row">
                    <tidy-text
                      [body]="
                        'Uploaded by:' + ' ' + image?.uploadedBy
                      "></tidy-text>
                  </ion-row>
                </ng-container>
                <ng-container *ngIf="image?.uploadedAt">
                  <ion-row class="file-caption-row">
                    <tidy-text
                      [body]="
                        'Timestamp:' + ' ' + image?.uploadedAt
                      "></tidy-text>
                  </ion-row>
                </ng-container>
                <ng-container *ngIf="image?.location">
                  <ion-row class="file-caption-row">
                    <tidy-text
                      [body]="'Location:' + ' ' + image?.location"></tidy-text>
                  </ion-row>
                </ng-container>
              </div>
              <div
                class="remove-image-button"
                *ngIf="showDeleteButton"
                (click)="removeImage(i, image)">
                <button matSuffix mat-icon-button mat-mini-fab>
                  <img
                    src="../../../assets/svg/delete.svg"
                    alt="close icon"
                    class="action-icon" />
                </button>
              </div>
              <div
                class="download-image-button"
                *ngIf="showDownloadButton"
                (click)="downloadImage(image)">
                <button matSuffix mat-icon-button mat-mini-fab>
                  <img
                    src="../../../assets/svg/download-icon.svg"
                    alt="download icon"
                    class="action-icon" />
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </tidy-carrousel>
    </ng-container>
  </div>
</ng-container>

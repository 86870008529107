<tidy-wrapper>
  <div class="onboarding-modal-overlay">
    <div class="onboarding-modal-container">

      <!-- Invisible click catcher that covers the modal content area -->
      <div
        *ngIf="showPrimaryModal"
        (click)="onOverlayClick($event)"
        style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; pointer-events: auto;">
      </div>

      <div style="position: relative; pointer-events: auto;">

        <!-- Main onboarding content -->
        <div *ngIf="showPrimaryModal">

          <div class="onboarding-modal-header">
            <tidy-row [alignLastItemRight]="true">
              <tidy-icon
                *ngIf="onboardingProvider.showBackButton"
                class="onboarding-back-button"
                [size]="23"
                (click)="goBack()"
                name="arrow-back-black">
              </tidy-icon>
              <tidy-text
                [title]="onboardingProvider.title">
              </tidy-text>
              <tidy-text
                [actionBody]="'Free Setup Call'"
                class="link"
                (action)="openScheduleCallModal()">
              </tidy-text>
            </tidy-row>
            <tidy-row [ngStyle]="onboardingProvider.showBackButton ? {'margin-left': '25px'} : {}">
              <tidy-text
                [body]="onboardingProvider.subTitle">
              </tidy-text>
            </tidy-row>
          </div>

          <div> <!--TODO make only this area scrollable-->
            <!--Used In Multiple Flows-->
            <tidy-direct-integration-onboarding *ngIf="onboardingProvider.page === 'directIntegration'"></tidy-direct-integration-onboarding>
            <tidy-add-direct-integration-onboarding *ngIf="onboardingProvider.page === 'addDirectIntegration'"></tidy-add-direct-integration-onboarding>
            <create-concierge-action-onboarding *ngIf="onboardingProvider.page === 'createConciergeAction'"></create-concierge-action-onboarding>

            <!--Property-->
            <tidy-property-onboarding *ngIf="onboardingProvider.page === 'property'"></tidy-property-onboarding>
            <tidy-import-csv-onboarding *ngIf="onboardingProvider.page === 'importCSV'"></tidy-import-csv-onboarding>
            <!--tidy-direct-integration-onboarding-->
            <!--tidy-add-direct-integration-onboarding-->

            <!--Schedule-->
            <!-- <schedule-onboarding *ngIf="onboardingProvider.page === 'schedule'"></schedule-onboarding> -->

            <!--Message Automation-->
            <enable-concierge-messaging-onboarding *ngIf="onboardingProvider.page === 'messageAutomation'"></enable-concierge-messaging-onboarding>

            <!--Tasks-->
            <tasks-onboarding *ngIf="onboardingProvider.page === 'tasks'"></tasks-onboarding>

            <!--To-Do List-->
            <to-dos-modal-onboarding *ngIf="onboardingProvider.page === 'toDos'"></to-dos-modal-onboarding>
            <popular-list-modal-onboarding *ngIf="onboardingProvider.page === 'popularList'"></popular-list-modal-onboarding>

            <!--Inventory Automation-->
            <select-restocking-option *ngIf="onboardingProvider.page === 'inventoryAutomation'"></select-restocking-option>
            <tidy-restocking-onboarding *ngIf="onboardingProvider.page === 'restocking'"></tidy-restocking-onboarding>
            <!--create-concierge-action-onboarding-->

            <!--Bills-->
            <bills-onboarding *ngIf="onboardingProvider.page === 'bills'"></bills-onboarding>

            <!--Add Pros-->
            <tidy-add-pros-onboarding *ngIf="onboardingProvider.page === 'addPros'"></tidy-add-pros-onboarding>
            <!--create-concierge-action-onboarding-->

            <!--Automatic Booking-->
            <enable-automatic-booking-onboarding *ngIf="onboardingProvider.page === 'enableAutomaticBooking'"></enable-automatic-booking-onboarding>
            <!--create-concierge-action-onboarding-->

            <!--Integrate-->
            <select-integration-onboarding *ngIf="onboardingProvider.page === 'selectIntegration'"></select-integration-onboarding>
            <!--tidy-direct-integration-onboarding-->
            <!--tidy-add-direct-integration-onboarding-->

            <!--Access Notes-->
            <select-access-notes-option-onboarding *ngIf="onboardingProvider.page === 'selectAccessNotes'"></select-access-notes-option-onboarding>
            <input-access-notes-onboarding *ngIf="onboardingProvider.page === 'inputAccessNotes'"></input-access-notes-onboarding>

            <!--Find New Pros-->
            <select-find-new-pros-option-onboarding *ngIf="onboardingProvider.page === 'selectFindNewPros'"></select-find-new-pros-option-onboarding>

            <!--Add Appliance-->
            <select-maintenance-option-onboarding *ngIf="onboardingProvider.page === 'selectMaintenance'"></select-maintenance-option-onboarding>
            <add-maintenance-items-onboarding *ngIf="onboardingProvider.page === 'addMaintenanceItems'"></add-maintenance-items-onboarding>

            <!--Guest Smart Number-->
            <enable-guest-smart-number-onboarding *ngIf="onboardingProvider.page === 'enableGuestSmartNumber'"></enable-guest-smart-number-onboarding>

            <!--Customize Your Workflows-->
            <automate-workflows-onboarding *ngIf="onboardingProvider.page === 'automateWorkflows'"></automate-workflows-onboarding>

            <!--Create Rules-->
            <create-concierge-rules-onboarding *ngIf="onboardingProvider.page === 'createConciergeRules'"></create-concierge-rules-onboarding>

            <!--Integrate Upsell Provider-->
            <integrate-upsell-provider *ngIf="onboardingProvider.page === 'upsell'"></integrate-upsell-provider>

            <!--Add Inspections-->
            <add-inspections-onboarding *ngIf="onboardingProvider.page === 'addInspections'"></add-inspections-onboarding>

          </div>

        </div>

        <!-- Secondary content shown when user lands on the page -->
        <div *ngIf="!showPrimaryModal" class="secondary-content">
          <tidy-row [align]="'center'" style="margin-bottom: 30px">
            <tidy-text
              [heroBody]="'<b>' + onboardingProvider.description + '</b>'"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-button
            [text]="onboardingProvider.buttonText"
            (click)="goToPrimaryModal()"
            class="primary">
          </tidy-button>
        </div>
      </div>

    </div>
  </div>
</tidy-wrapper>

<ng-container *ngFor="let form of forms.controls">
  <form [formGroup]="form">
    <tidy-row [align]="'center'">
      <tidy-text [body]="'Please tell us more about:'"> </tidy-text
      ><span>&nbsp;</span>
      <tidy-text [body]="form.get('addressName').value"> </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-select
            [label]="'Number of Bedrooms'"
            [form]="form.controls.beds"
            formControlName="beds"
            [items]="roomOptions">
          </tidy-select>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-select
            [label]="'Number of Bathrooms'"
            [form]="form.controls.baths"
            formControlName="baths"
            [items]="roomOptions">
          </tidy-select>
        </tidy-row>
        <tidy-row>
          <tidy-select
            [label]="'Number of Floors'"
            [form]="form.controls.floors"
            formControlName="floors"
            [items]="floorOptions">
          </tidy-select>
        </tidy-row>
      </form>
    </tidy-card>
  </form>
</ng-container>

<tidy-button
  [text]="'Create Popular To-Do Lists'"
  [action]="save.bind(this)"
  class="primary">
</tidy-button>

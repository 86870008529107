import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';

@Component({
  templateUrl: 'client-get-started.html'
})

export class ClientGetStartedPage implements OnInit {

  creditBalance: number;
  employerName: string;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController
  ) {}

  ngOnInit() {
    this.creditBalance = parseInt(localStorage.getItem('creditBalance'));
    this.employerName = localStorage.getItem('employerName');
  }

  goToAddPro() {
    const params = {
      cameFromGetStartedPage: true
    }
    this.navCtrl.navigateForward('get-started-add-pro', params);
  }

  async goToFindNewPro() {
    let address = this.navCtrl.getParam('address');
    if (!address) {
      const addressResponse = await this.client.getMoreDetailAddresses(true);
      address = addressResponse[0];
    }
    this.navCtrl.navigateForward('book-job', { address });
  }

  goToViewApp() {
    this.navCtrl.navigateForward('dashboard');
  }

}

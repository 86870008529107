import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';

import { AddressModel } from 'src/models/address.model';
import { ItemNode, FlatNode, ChipFilter } from 'src/models/schedule.model';
import { DateTime as LuxonDateTime } from 'luxon';

export interface ConciergeItemFilter {
  status: string[];
  /*propertyName: string[];*/
  dateRange: {
    start: string;
    end: string;
  };
}

@Component({
  templateUrl: 'concierge-filter.html',
  styleUrls: ['./concierge-filter.scss'],
  selector: 'concierge-filter',
  encapsulation: ViewEncapsulation.None,
})
export class ConciergeFilterComponent implements OnInit, OnChanges {

  @Input() buttonTitle: string;
  @Input() addresses: AddressModel[];
  @Input() selectedFilters: ConciergeItemFilter;
  @Output() onSearch = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<{
    selectedFilters: ConciergeItemFilter;
    lastSelectedFilter: string;
  }>();
  @Output() onAddItem = new EventEmitter<void>();
  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  form: UntypedFormGroup;
  chips: ChipFilter[] = [];
  filterNamesReferences = {
    'Status': 'status',
    /*'Property': 'propertyName',*/
  };
  pros: any[] = [];
  dateRangeForm: UntypedFormGroup;
  selectOptions = [
    { viewValue: 'Custom', value: 'custom' },
    { viewValue: 'Today', value: 'today' },
    { viewValue: 'Last Week', value: 'last_week' },
    { viewValue: 'Last Month', value: 'last_month' },
    { viewValue: 'All Time', value: 'all_time' },
  ];
  @ViewChild('daterangefilter') daterangefilter: ElementRef;

  constructor(
    private fb: FormBuilder,
    public windowService: WindowService,
    private navCtrl: CustomNavController,
  ) {
    this.form = this.fb.group({
      addresses: ['', Validators.required],
      search: [''],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.onSearch.emit(val));
    this.dateRangeForm = fb.group({
      start: '',
      end: '',
      customStringSelect: ''
    });
  }

  async ngOnInit(): Promise<void> {
    this.getTreeData();
    this.selectedFilters = {
      status: [],
      /*propertyName: [],*/
      dateRange: {
        start: '',
        end: '',
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addresses) {
      this.getTreeData();
    }
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getTreeData(): void {
    const treeData = [
      {
        name: 'Status',
        children: [
          { name: 'Waiting on TIDY', value: 'waiting_on_tidy', checked: false, parentId: null },
          { name: 'Waiting on Customer', value: 'waiting_on_customer', checked: false, parentId: null },
        ],
      },
      /*{
        name: 'Property',
        children: this.getAddressTree('address_name', 'address1'),
      },*/
    ];
    treeData.forEach((parent, index) => {
      if (parent.children?.length === 0) {
        treeData.splice(index, 1);
      }
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    this.dataSource.data = treeData;
  }

  getAddressTree(key: string, keyTwo: string = null): ItemNode[] {
    return this.addresses
      ?.map((address) => {
        return {
          name: address[key] || address[keyTwo],
          value: address.id.toString(),
          checked: false,
        };
      })
      .filter((address) => address?.name);
  }

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = this.dataSource.data[
      node.data.parentId
    ].children.find((child) => child.name === node.name).value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }

    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip, foundParentName);
    }
  }

  addChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName].push(chip.value);
    this.chips.push(chip);
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ].includes(child.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter, parentName: string): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip.value);
    this.chips = this.chips.filter(
      (item) => item.parentName !== parentName && item.value !== chip.value
    );
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName ,foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters[matchedParentName].includes(foundNodeValue);
  }

  addItem(): void {
    this.onAddItem.emit();
  }

  selectCustomDateRange(e): void {
    const parseTime = (objTime) => {
      return LuxonDateTime.fromJSDate(objTime);
    }

    const customTime = {
      start: parseTime(e.start).startOf('day').toISO(),
      end: parseTime(e.end).endOf('day').toISO(),
    };

    this.selectedFilters.dateRange = customTime;
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: 'dateRange',
    });
  }

  selectStringDateRange(selection) {
    const today = LuxonDateTime.local();
    let startDate = today;
    let endDate = today;
    if (selection === 'last_week') {
      startDate = today.minus({ days: 7 });
    }
    if (selection === 'last_month') {
      startDate = today.minus({ months: 1 });
    }
    if (selection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    if (selection === 'today') {
      startDate = today;
      endDate = today;
    }

    this.selectedFilters.dateRange = {
      start: startDate?.startOf('day').toISO(),
      end: endDate?.endOf('day').toISO(),
    };
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: 'dateRange',
    });
  }

  openDateRange() {
    const dateRangeElem = document.getElementById('daterangefilter');
    (dateRangeElem.children[0] as HTMLElement).click();
  }
  
}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent && !windowService?.isDesktopRes"
    [class]="extraClass"
    [title]="('Send Reservation Data from' | translate) + ' ' + integration?.guest_reservation_source_type?.name + ' ' + ('to TIDY' | translate)"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper
    [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''"
    [style]="!windowService?.isDesktopRes ? 'margin-top: 5rem;' : ''">
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container>
      <tidy-desktop-header *ngIf="windowService?.isDesktopRes">
        <tidy-image
          [src]="'assets/svg/arrow-back-black.svg'"
          (action)="goBack()"
          class="desktop-back-icon">
        </tidy-image>
        <tidy-text
          [title]="('Send Reservation Data from' | translate) + ' ' + integration?.guest_reservation_source_type?.name + ' ' + ('to TIDY' | translate)">
        </tidy-text>
      </tidy-desktop-header>
      <tidy-row *ngIf="savingText.length > 0">
        <tidy-text [body]="savingText"></tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [helper]="'We will show this data to pros or customers who have access to it. Match the data from your PMS to TIDY, or use the defaults.'">
        </tidy-text>
      </tidy-row>
      <tidy-card>
        <div style="display: flex; gap: 10px; align-items: center">
          <div style="flex: 1">
            <tidy-row style="margin-bottom: 1rem">
              <tidy-text
                [header]="integration?.guest_reservation_source_type?.name"></tidy-text>
            </tidy-row>
          </div>
          <div style="flex: 0.1; margin-left: 10px"></div>
          <div style="flex: 1">
            <tidy-row style="margin-bottom: 1rem">
              <tidy-text [header]="'TIDY'"></tidy-text>
            </tidy-row>
          </div>
          <div style="flex: 0.2; margin-left: 10px">
            <tidy-text [header]="'Visible to Pros'"></tidy-text>
          </div>
        </div>
        <ng-container *ngIf="!loaded">
          <ng-container *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
            <div style="display: flex; gap: 10px; align-items: center">
              <ion-skeleton-text
                animated="true"
                style="
                  width: 100%;
                  height: 64px;
                  border-radius: 8px;
                "></ion-skeleton-text>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="loaded">
          <form [formGroup]="form">
            <div *ngFor="let field of form.controls; let i = index">
              <form
                [formGroup]="field"
                [class.highlighted]="hoveredFieldName === field.get('field_name')?.value"
                (mouseenter)="hoveredFieldName = field.get('field_name')?.value"
                (mouseleave)="hoveredFieldName = null"
                style="display: flex; gap: 10px; align-items: center">
                <div style="flex: 1">
                  <tidy-select
                    [label]="''"
                    [items]="parsedVariables"
                    [multiple]="false"
                    [form]="field.get('source_data')"
                    formControlName="source_data">
                  </tidy-select>
                </div>
                <div style="flex: 0.1; margin-left: 10px">
                  <mat-icon>chevron_right</mat-icon>
                </div>
                <div style="flex: 1">
                  <div>
                    <tidy-text
                      [body]="field?.get('field_name')?.value || ''"></tidy-text>
                  </div>
                </div>
                <div style="flex: 0.2; margin-left: 10px">
                  <tidy-checkbox
                    [items]="[{value: true, viewValue: ''}]"
                    [initValue]="field?.get('is_visible_to_homekeeper')?.value"
                    [formControlName]="'is_visible_to_homekeeper'"
                    [form]="field?.controls.is_visible_to_homekeeper"
                    (checkedChange)="updateCustomField(field)">
                  </tidy-checkbox>
                </div>
              </form>
            </div>
          </form>
          <div style="display: flex; gap: 10px; align-items: center">
            <div style="flex: 1"></div>
            <div style="flex: 0.1; margin-left: 10px"></div>
            <div style="flex: 1">
              <tidy-row style="margin-bottom: 1rem">
                <tidy-text
                  [actionBody]="'+ Create Custom Field'"
                  class="link"
                  (action)="addCustomField()">
                </tidy-text>
              </tidy-row>
            </div>
          </div>
        </ng-container>
      </tidy-card>

      <tidy-row>
        <tidy-text [header]="'Reservation Card Example'"></tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/calendar-link.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="(guestReservation?.check_in_date | customdate:'M/DD/YY') +
            ' - ' +
            (guestReservation?.check_out_date | customdate:'M/DD/YY')">
          </tidy-text
          ><span>&nbsp;</span>
          <tidy-text [header]="'Reservation'"> </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image [src]="'assets/svg/home-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="addressName"> </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/svg/time-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="'Check In:'"> </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_in_time | customdate:'h:mma':'local':'HH:mm'">
          </tidy-text
          ><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_in_date | customdate:'M/DD/YY'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image [src]="'assets/svg/time-outline.svg'" class="body-size">
          </tidy-image>
          <tidy-text [body]="'Check Out:'"> </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_out_time | customdate:'h:mma':'local':'HH:mm'">
          </tidy-text
          ><span>&nbsp;</span>
          <tidy-text
            [body]="guestReservation?.check_out_date | customdate:'M/DD/YY'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/information-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text [body]="'Synced from'"> </tidy-text><span>&nbsp;</span>
          <tidy-text [body]="integration?.guest_reservation_source_type?.name">
          </tidy-text
          ><span>&nbsp;</span>
          <tidy-text
            [body]="'integration'"
            (action)="goToIntegrations()"
            class="link">
          </tidy-text>
        </tidy-row>

        <ng-container>
          <tidy-row class="vertical-align-center">
            <tidy-image [src]="'assets/icon/group.png'" class="body-size">
            </tidy-image>
            <tidy-text [body]="'2 guests'"></tidy-text>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="customFields?.length > 0">
          <tidy-row
            class="vertical-align-center"
            *ngFor="let customField of customFields"
            [class.highlighted]="hoveredFieldName === customField.name"
            (mouseenter)="hoveredFieldName = customField.name"
            (mouseleave)="hoveredFieldName = null">
            <tidy-image
              [src]="'assets/svg/information-circle-outline.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="customField?.name + ': ' + ' ' + (customField?.value || 'N/A')"></tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper
    [templateContent]="content"
    [isfullWidthContent]="true"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';
import { Menu } from 'src/providers/menu/menu';
import { Workflows } from "src/providers/workflows/workflows";

import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  templateUrl: 'workflow-run.html',
  encapsulation: ViewEncapsulation.None
})

export class WorkflowRunPage implements OnInit {

  isRightSideContent: boolean;
  run: any;
  triggerOutputs: any;

  constructor(
    private menu: Menu,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private workflowsProvider: Workflows
  ) {}

  @Loading('', true)
  async ngOnInit() {
    this.rightSidePanelService.setDialogPageTitle('Workflow');
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.run = this.navCtrl.getParam('run') || dialogParams.run;
    this.triggerOutputs = JSON.stringify(this.run.trigger_outputs, null, 2);
  }

  getDate(date) {
    const dt = LuxonDateTime.fromISO(date);
    return dt.toFormat('h:mm a MMM d, yyyy');
  }

}

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card *ngIf="rule">
      <form [formGroup]="form">
        <tidy-row>
          <tidy-textarea
            label="Rule"
            formControlName="rule"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [form]="form.controls.rule"
            [submitted]="submitted"
            [errorMessage]="'Please enter the rule.'">
          </tidy-textarea>
        </tidy-row>
      </form>
    </tidy-card>

    <ng-container *ngIf="!rule && cameFromOnboarding">
      <tidy-row>
        <tidy-text [header]="'Popular Rules'"> </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ng-container *ngFor="let rule of popularRules">
            <ion-chip (click)="addPopularRule(rule)" [class.added]="rule.added">
              <tidy-text [body]="rule.name"> </tidy-text>
            </ion-chip>
          </ng-container>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <tidy-card *ngIf="!rule">
      <form [formArray]="forms">
        <tidy-row
          *ngFor="let item of forms.controls; let i = index; let first = first">
          <form [formGroup]="item">
            <tidy-textarea
              label="Rule"
              formControlName="rule"
              [icon]="'assets/svg/information-circle-outline.svg'"
              [form]="item"
              [submitted]="submitted"
              [errorMessage]="'Please enter the rule.'">
            </tidy-textarea>
            <tidy-row [align]="'end'" *ngIf="!first">
              <tidy-text
                [actionBody]="'Remove'"
                (action)="removeRule(i, item.value.rule)"
                class="link red">
              </tidy-text>
            </tidy-row>
          </form>
        </tidy-row>
      </form>
      <tidy-row [align]="'center'">
        <tidy-text
          [actionBody]="'+ Add Another Rule'"
          (action)="addRule()"
          class="link">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-button
      [action]="saveRule.bind(this)"
      class="primary"
      [text]="rule ? 'Save Change' : 'Add Rule(s)'">
    </tidy-button>

    <tidy-row [align]="'center'" *ngIf="rule">
      <tidy-text
        [actionBody]="'Delete Rule'"
        (action)="deleteRule()"
        class="link red">
      </tidy-text>
    </tidy-row>

    <tidy-error-message *ngIf="errorMessage" [text]="errorMessage">
    </tidy-error-message>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

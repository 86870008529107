import { Component, ElementRef, EventEmitter, Output, Input, ViewEncapsulation, Renderer2} from '@angular/core';

import { fromEvent, ReplaySubject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

const BREAK_LARGE_RESOLUTION = 884;

@Component({
  selector: 'tidy-wrapper',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./wrapper.component.scss'],
  template: `
    <ng-content></ng-content>
  `
})

export  class WrapperComponent {
  _contentHeight;
  @Output() contentHeight: EventEmitter<number> = new EventEmitter<number>();
  private windowSize: number = window?.innerWidth;
  @Input() hasBottomNav: boolean = false;
  private destroyed$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(
    private el : ElementRef,
    private renderer : Renderer2
  ) {
    fromEvent(window, 'resize')
    .pipe(
      debounceTime(300),
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
        this.windowSize = window?.innerWidth ?? this.windowSize;
        this.setPlatformClass();
    });
  }

  ngAfterContentChecked() {
    const elementHeight = this.el.nativeElement.offsetHeight;
    if(this._contentHeight !== elementHeight) {
      this._contentHeight = elementHeight;
      this.contentHeight.emit(this._contentHeight);
      this.setPlatformClass();
    }
  }

  setPlatformClass(){
    if(this.isDesktopRes || !this.hasBottomNav){
      this.renderer.addClass(this.el.nativeElement, 'desktop');
      this.renderer.removeClass(this.el.nativeElement, 'mobile');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'mobile');
      this.renderer.removeClass(this.el.nativeElement, 'desktop');
    }
  }

  public get isDesktopRes(): boolean {
    return this.windowSize > BREAK_LARGE_RESOLUTION;
  }

  ngOnDestroy() {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }
}

import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { EmployerService } from "src/providers/employer/employer";
import validationUtils from "src/shared/util/validation-utils";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'add-employee.html'
})
export class EmployerAddEmployeePage implements OnInit {

  form: UntypedFormGroup;
  editingEmployee?: Partial<Employee>;
  errorMessage: string | any;

  constructor(
    formBuilder: UntypedFormBuilder,
    private employer: EmployerService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([validationUtils.validateEmail])]
    });
  }

  async ngOnInit() {
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.editingEmployee = dialogParams?.employee;
    this.rightSidePanelService.setDialogPageTitle(this.editingEmployee ? 'Edit Employee' : 'Add Employee');
    if (this.editingEmployee) {
      this.form.patchValue({
        firstName: this.editingEmployee.source_user_first_name,
        lastName: this.editingEmployee.source_user_last_name,
        email: this.editingEmployee.source_user_email
      });
    }
  }

  async confirm() {
    try {
      if (this.editingEmployee) {
        const res = await this.editEmployee();
      } else {
        await this.addEmployee();
      }
      await this.employer.listEmployees(true);
      this.rightSidePanelService.closeRightSidePanel();
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  editEmployee(): Promise<Partial<Employee>> {
    const formValue = this.form.value;
    const employeeData = {
      id: this.editingEmployee.id,
      first_name: formValue.firstName,
      last_name: formValue.lastName,
      email: formValue.email
    };
    return this.employer.editEmployee(employeeData);
  }

  addEmployee() {
    const formValue = this.form.value;
    const newEmployee = {
      first_name: formValue.firstName,
      last_name: formValue.lastName,
      email: formValue.email
    };

    return this.employer.addEmployee(newEmployee);
  }
  
}

export interface Employee {
  deleted_at: string;
  source_user_email: string;
  employee_customer_id: number;
  employer_customer_id: number;
  source_user_first_name: string;
  id: number;
  source_user_last_name: string;
  source: string;
}

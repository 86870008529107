<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="title">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" *ngIf="type !== 'support.concierge_task'">
      <tidy-text
        *ngIf="type === 'support.other' && !dontShowOtherText"
        [body]="otherText">
      </tidy-text>
      <tidy-text
        [body]="'We expect to reply within'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="replyTime">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'. We will reply both in the app and to your email'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="email + '.'">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="isAddingPro">
      <tidy-row>
        <tidy-text
          [body]="'Let us know:'">
        </tidy-text>
        <tidy-text
          [body]="'<li>Contact info of pro(s) (name, email, phone, etc)</li>'">
        </tidy-text>
        <tidy-text
          [body]="'<li>Which properties/services to add to</li>'">
        </tidy-text>
        <tidy-text
          [body]="'<li>Anything else we should know!</li>'">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="isAddingRoomItem">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Send us a list of all your current inventory, either with current stock levels or not. We will load it for you, typically within 1 business day. We email you when done!'">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="isAddingToDoList">
      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Send us your current checklists and instructions. We will load it for you, typically within 1 business day. We email you when done! Edit as needed from there.'">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="type == 'support.concierge_task' && !isAddingPro && !isAddingRoomItem && !isAddingToDoList && !isWorkflow">
      <tidy-row>
        <tidy-text
          [body]="'Tasks are where you can specify anything you need from TIDY. Our AI and concierge teams will figure out how to get it done for you. We accept anything relating to coordinate cleaning and maintenance at your properties.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'<b>Examples:</b>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Can you add this to-do list to my account?'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Can you set up 3 vendors to come and clean the carpets, complete a regular cleaning, and complete an inspection?'">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <tidy-row [align]="'center'" *ngIf="isAutoAssign">
      <tidy-text
        [body]="'Please add to your message below which services you want to auto assign to this pro.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'" *ngIf="isWorkflow">
      <tidy-text
        [body]="'Please let us know what workflow you want to create. For example: when a job is completed, create a concierge action.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="type == 'support.concierge_task' ? 'Task name' : 'Subject'"
            [icon]="'assets/svg/mail-outline.svg'"
            formControlName="subject"
            [form]="form.controls.subject"
            [submitted]="submitted"
            [errorMessage]="'Please enter your first name.'">
          </tidy-input>
         </tidy-row>
         <tidy-row class="field-bottom-padding">
          <tidy-textarea
            [label]="getTaskDescriptionText()"
            formControlName="message"
            [form]="form.controls.message"
            [submitted]="submitted"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [errorMessage]="'Please enter a message'">
          </tidy-textarea>
        </tidy-row>
        <tidy-row *ngIf="addingEmailCC">
          <tidy-input
            [label]="'Email CC'"
            [icon]="'assets/svg/person-outline.svg'"
            formControlName="email"
            [form]="form.controls.email"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email'">
          </tidy-input>
        </tidy-row>
      </form>
      <tidy-row *ngIf="!addingEmailCC && type !== 'support.concierge_task'">
        <tidy-text
          [actionBody]="'Add Email CC'"
          (action)="addEmailCC()"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [actionBody]="'Add Attachment'"
          (action)="addAttachment()"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="attachments.length" class="field-top-padding">
        <tidy-row>
          <tidy-text
            [body]="'Attachments:'">
          </tidy-text>
        </tidy-row>
        <tidy-two-column-row *ngFor="let attachment of attachments; let i = index">
          <tidy-text
            [body]="attachment.filename">
          </tidy-text>
          <tidy-text
            [actionBody]="'remove'"
            (action)="removeAttachment(i)"
            class="link red">
          </tidy-text>
        </tidy-two-column-row>
      </tidy-row>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="type == 'support.concierge_task' ? 'Create Task' : 'Send to Concierge'"
      [action]="send.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

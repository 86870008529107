import { Injectable } from "@angular/core";

import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';
import { ConciergePage } from 'src/pages/concierge/concierge';
import { TeamsPage } from 'src/pages/team/teams/teams';
import { UsersPage } from 'src/pages/users/users';
import { AppBrandingPage } from 'src/pages/more/app-branding/app-branding'
import { ContactInfoPage } from 'src/pages/more/contact-info/contact-info';
import { AccountTypePage } from 'src/pages/more/account-type/account-type';
import { BackupTimesPage } from 'src/pages/more/backup-times/backup-times';
import { AccountNotificationSettingsPage } from 'src/pages/more/account-notification-settings/account-notification-settings';
import { BulkUploadPage } from 'src/pages/more/bulk-upload/bulk-upload';
import { VendorCompliancePage } from 'src/pages/vendor-compliance/vendor-compliance';
import { DevelopersPage } from 'src/pages/more/developers/developers';
import { PaymentMethodsPage } from 'src/pages/more/payment-methods/payment-methods';
import { PaymentSettingsPage } from 'src/pages/more/payment-settings/payment-settings';
import { UseGiftCardPage } from 'src/pages/more/use-gift-card/use-gift-card';
import { MyPricesPage } from 'src/pages/more/my-prices/my-prices';
import { SubscriptionsPage } from 'src/pages/subscription/subscription';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { RecurringTasksPage } from 'src/pages/tasks/recurring-tasks/recurring-tasks';
import { ArchivedTasksPage } from 'src/pages/tasks/archived-tasks/archived-tasks';
import { ToDosListSettingsPage } from 'src/pages/to-dos/list-settings/list-settings';
import { ProSettingsPage } from 'src/pages/my-pros/pro-settings/pro-settings';
import { WorkflowsPage } from 'src/pages/workflows/workflows';
import { WorkflowHistoryPage } from 'src/pages/workflows/workflow-history/workflow-history';
import { JobRequestWorkflows } from 'src/pages/job-request-workflows/job-request-workflows';
import { AutomaticBookingPage } from 'src/pages/more/automatic-booking/automatic-booking';

@Injectable()
export class HeaderProvider {

  showConciergePage: any;

  helpArticlesItems = [
    {
      title: 'Getting Started',
      link: 'https://help.tidy.com',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Dashboard',
      link: 'https://help.tidy.com/dashboard',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'TIDY Concierge',
      link: 'https://help.tidy.com/tidy-concierge',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'TIDY AI',
      link: 'https://help.tidy.com/tidy-ai',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Teams & Users',
      link: 'https://help.tidy.com/teams-and-users',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Properties/Addresses',
      link: 'https://help.tidy.com/propertiesaddresses',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Schedule',
      link: 'https://help.tidy.com/schedule',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Jobs',
      link: 'https://help.tidy.com/jobs',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Workflows',
      link: 'https://help.tidy.com/workflows',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Account Settings',
      link: 'https://help.tidy.com/account-settings',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Messages',
      link: 'https://help.tidy.com/messages',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Notifications',
      link: 'https://help.tidy.com/notifications',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Plans',
      link: 'https://help.tidy.com/plans',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Access Control / Smartlocks',
      link: 'https://help.tidy.com/access-control-smartlocks',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'My Pros',
      link: 'https://help.tidy.com/my-pros',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'To-Do Lists',
      searchTerms: [
        'todo',
        'to do'
      ],
      link: 'https://help.tidy.com/to-do-lists',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Integrations',
      link: 'https://help.tidy.com/integrations',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Automatic Booking',
      link: 'https://help.tidy.com/automatic-booking',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Billing, Pricing, & Payments',
      link: 'https://help.tidy.com/billing-pricing-and-payments',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Compliance, Ratings, Safety, Insurance, & Protection',
      link: 'https://help.tidy.com/compliance-ratings-safety-insurance-and-protection',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Property Maps',
      link: 'https://help.tidy.com/property-maps',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Maintenance',
      link: 'https://help.tidy.com/maintenance',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Inventory Tracking',
      link: 'https://help.tidy.com/inventory-tracking',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Inspections',
      link: 'https://help.tidy.com/inspections',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Smart Thermostats',
      link: 'https://help.tidy.com/smart-thermostats',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Tasks',
      link: 'https://help.tidy.com/tasks',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Automatic Translation',
      link: 'https://help.tidy.com/automatic-translation',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Other Questions',
      link: 'https://help.tidy.com/other-questions',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Reports',
      link: 'https://help.tidy.com/reports',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Accounting',
      link: 'https://help.tidy.com/accounting',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Developers',
      link: 'https://help.tidy.com/developers',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Best Practices',
      link: 'https://help.tidy.com/best-practices',
      image: 'assets/img/help-fill.svg'
    },
    {
      title: 'Changelog',
      link: 'https://help.tidy.com/er5X-changelog',
      image: 'assets/img/help-fill.svg'
    },
  ];

  sectionsItems = [
    {
      title: 'Dashboard',
      link: '/dashboard',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Schedule',
      link: '/schedule',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Bills',
      link: '/bills',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Restocking',
      link: '/inventory',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Automations',
      link: '/automations/summary',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'To-Dos',
      searchTerms: [
        'todo',
        'to do'
      ],
      link: '/all-to-do-lists',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'My Pros',
      link: '/my-pros',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'More',
      link: '/more-desktop',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Properties',
      link: '/properties',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Reservations',
      link: '/more/reservations',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Job History',
      link: '/past-jobs',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Billing History',
      link: '/billing-history',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: BillingHistoryPage
    },
    {
      title: 'Reports',
      link: '/reports',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Integrations',
      link: '/integrations/all',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Audit Logs',
      link: '/audit-logs',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Messages',
      link: '/messages',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Tasks',
      link: '/tasks',
      image: 'assets/img/globe-white.svg'
    },
    {
      title: 'Concierge',
      link: '/concierge',
      searchTerms: [
        'contact support',
        'help'
      ],
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: ConciergePage
    },
    {
      title: 'Teams',
      link: '/teams',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: TeamsPage
    },
    {
      title: 'Users',
      link: '/users',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: UsersPage
    },
    {
      title: 'App Branding',
      link: '/app-branding',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: AppBrandingPage
    },
    {
      title: 'Contact Info',
      link: '/contact-info',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: ContactInfoPage
    },
    {
      title: 'Account Type',
      link: '/account-type',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: AccountTypePage
    },
    {
      title: 'Default Backup Times',
      link: '/backup-times',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: BackupTimesPage
    },
    {
      title: 'Account Notifications',
      link: '/account-notifications',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: AccountNotificationSettingsPage
    },
    {
      title: 'Bulk Upload',
      link: '/more/bulk-upload',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: BulkUploadPage
    },
    {
      title: 'Vendor Compliance',
      link: '/vendor-compliance',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: VendorCompliancePage
    },
    {
      title: 'Developers',
      link: '/developers',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: DevelopersPage
    },
    {
      title: 'Payment Methods',
      link: '/payment-methods',
      searchTerms: [
        'payment',
        'credit card',
        'billing'
      ],
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: PaymentMethodsPage
    },
    {
      title: 'Payment Settings',
      link: '/payment-settings',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: PaymentSettingsPage
    },
    {
      title: 'Gift Card',
      link: '/use-gift-card',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: UseGiftCardPage
    },
    {
      title: 'My Prices',
      link: '/my-prices',
      searchTerms: [
        'cost',
      ],
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: MyPricesPage
    },
    {
      title: 'Subscription',
      link: '/my-prices',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: SubscriptionsPage
    },
    {
      title: 'Book Job(s)',
      searchTerms: [
        'schedule job',
      ],
      link: '/select-category',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: SelectCategoryPage
    },
    {
      title: 'Recurring Tasks',
      link: '/recurring-tasks',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: RecurringTasksPage
    },
    {
      title: 'Archived Tasks',
      link: '/archived-tasks',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: ArchivedTasksPage
    },
    {
      title: 'Default To-Do Lists',
      link: '/list-settings',
      searchTerms: [
        'todo',
        'to do'
      ],
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: ToDosListSettingsPage
    },
    {
      title: 'My Pro Settings',
      link: '/pro-settings',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: ProSettingsPage
    },
    {
      title: 'Workflows',
      link: '/automations/workflows',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: WorkflowsPage
    },
    {
      title: 'Workflow Run History',
      link: '/automations/workflow-history',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: WorkflowHistoryPage
    },
    {
      title: 'Job Request Workflows',
      link: '/job-request-workflows',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: JobRequestWorkflows
    },
    {
      title: 'Automatic Booking',
      link: '/automatic-booking',
      image: 'assets/img/globe-white.svg',
      isRightSidePanel: true,
      component: AutomaticBookingPage
    },
  ];
}


import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import moment from 'moment-timezone';
import { debounceTime } from 'rxjs/operators';

import { Client } from 'src/providers/client/client';
import { Loading } from 'src/shared/components/loading/loading';
import { Genome } from 'src/providers/genome/genome';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { floorSelect } from 'src/shared/constants/floorSelect';
import { Capacitor } from '@capacitor/core';

@Component({
  templateUrl: 'add-room-item.html'
})

export class AddRoomItemPage implements OnInit {

  addressItems: any;
  category: any;
  dialogParams: any;
  installationDateItems: any;
  form: UntypedFormGroup;
  floorSelectItems = floorSelect;
  submitted: boolean;
  categories: any;
  categoryChosen: boolean;
  categoriesResponse: any;
  characteristics: { name: String, formData: { value: number, viewValue: String } }[];
  characteristicsChosen: { [id: string]: number } = {};
  hasNoItemsOrMap: boolean;
  loaded: boolean;
  models: any = [];
  isRightSideContent: boolean;
  getInventoryAlerts: boolean;
  createNewModel: boolean;
  characteristicsArray: any = [];
  errorMessage: string;
  salesCards: any;
  selectedModelID: number;
  showAddressSelect: boolean;
  showConsumables: boolean;
  deliveryTimingPreferenceItems = [
    { value: 'Between guest stays', viewValue: 'Between guest stays' },
    { value: 'As soon as possible', viewValue: 'As soon as possible' }
  ];
  
  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      search: [''],
      characteristic: [''],
      model: [''],
      installation_date: [''],
      minQuantity: [''],
      maxQuantity: [''],
      currentQuantity: [''],
      opportunisticOrderLevel: [''],
      minRestockingPrice: [''],
      maxRestockingPrice: [''],
      whereToOrder: [''],
      deliveryTimingPreferences: [''],
      restockingNotes: [''],
      address: ['']
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
    this.form.get('model').valueChanges.pipe(
      debounceTime(100)
    ).subscribe(val => {
      if (val === '' || val === null) {
        this.selectedModelID = null;
      } else if (val && typeof val === 'string') {
        const matchingOption = this.models.find(option => option.display === val);
        if (!matchingOption) {
          this.selectedModelID = null;
        }
      }
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.categoryChosen = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Add Item');
      }
      this.categoriesResponse = await this.genome.getCategories();
      this.showConsumables = this.navCtrl.getParam('showConsumables') || this.dialogParams?.showConsumables;
      this.categoriesResponse = this.categoriesResponse.filter((category) => category.consumable == this.showConsumables);
      this.createAddressForm();
      this.categories = this.categoriesResponse;
      this.installationDateItems = this.buildInstallationDateItems();
      this.salesCards = this.getSalesCards();
      this.hasNoItemsOrMap = this.navCtrl.getParam('hasNoItemsOrMap') || this.dialogParams?.hasNoItemsOrMap;
      this.showAddressSelect = this.navCtrl.getParam('showAddressSelect') || this.dialogParams?.showAddressSelect;
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async selectCategory(category) {
    this.category = category;
    this.models = [];
    this.characteristicsChosen = {};
    const characteristics = await this.genome.getCharacteristics(category.id);
    this.characteristics = characteristics.map((characteristic, index) => {
      const forms = characteristic.characteristics.map((section) => {
        const formData = {
          value: section.id,
          viewValue: section.name
        };
        return formData;
      });
      this.characteristicsChosen[characteristic.name] = -1;
      const data = {
        name: characteristic.name,
        formData: forms
      };
      this.form.addControl('characteristic_' + index, new FormControl(''));
      return data;
    });
    await this.getModels('');
    this.categoryChosen = true;
  }

  async createAddressForm() {
    try {
      const getAddressesFromBackend = false;
      const addresses = await this.client.getMoreDetailAddresses(getAddressesFromBackend);
      const selectedAddressId = await this.client.getSelectedAddressId(addresses)
      const includeAddAddress = false;
      this.addressItems = this.client.parseAddressList(addresses, includeAddAddress);
      this.form.patchValue({
        address: selectedAddressId
      });
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  selectCharacteristic(characteristic, id) {
    try {
      this.characteristicsChosen[characteristic.name] = id;
      let query = this.buildQueryString(this.characteristicsChosen);
      this.getModels(query);
    } catch (err) {
      console.error('err', err);
    }
  }

  @Loading('', true)
  async getModels(query) {
    const models = await this.genome.getObjectModel(this.category.id, query);
    this.models = models.map((model) => {
      const formData = {
        value: model.id,
        display: model.model
      };
      return formData;
    });
  }

  selectModel(model) {
    this.selectedModelID = model.value;
  }

  buildQueryString(obj) {
    let finalQuery = '';
    let finalArray = [];
    for (var key in obj) {
      if (obj[key] != -1) {
        finalQuery += `&filters[characteristic_ids][]=${obj[key]}`;
        finalArray.push(obj[key]);
      }
    }
    this.characteristicsArray = finalArray;
    return finalQuery;
  }

  async addItem() {
    this.submitted = true;
    this.errorMessage = '';
    let data;
    if (!this.form.valid) {
      return;
    }
    data = {
      category_id: this.category.id,
      address_id: this.form.value.address,
      installation_date: `01/01/${this.form.value.installation_date}`,
      model_id: this.form.value.model
    }
    if (this.getInventoryAlerts) {
      data['min_quantity'] = this.form.value.minQuantity;
      data['current_quantity'] = this.form.value.currentQuantity ? this.form.value.currentQuantity : null;
      data['max_quantity'] = this.form.value.maxQuantity ? this.form.value.maxQuantity : null;
      data['opportunistic_quantity'] = this.form.value.opportunisticOrderLevel ? this.form.value.opportunisticOrderLevel : null;
      data['delivery_timing_preference'] = this.form.value.deliveryTimingPreferences ? this.form.value.deliveryTimingPreferences : null;
      data['restocking_notes'] = this.form.value.restockingNotes ? this.form.value.restockingNotes : null;
      data['where_to_order'] = this.form.value.whereToOrder ? this.form.value.whereToOrder : null;
      data['min_restocking_price'] = this.form.value.minRestockingPrice ? parseFloat(this.form.value.minRestockingPrice.replace('$', '')) : null;
      data['max_restocking_price'] = this.form.value.maxRestockingPrice ? parseFloat(this.form.value.maxRestockingPrice.replace('$', '')) : null;
    } else {
      data['min_quantity'] = null;
      data['current_quantity'] = null;
      data['max_quantity'] = null;
      data['delivery_timing_preference'] = null;
      data['restocking_notes'] = null;
      data['where_to_order'] = null;
      data['min_restocking_price'] = null;
      data['max_restocking_price'] = null;
      data['opportunistic_quantity'] = null;
    }
    try {
      if (!this.selectedModelID) {
        const modelData = {
          category_id: this.category.id,
          model: this.form.value.model,
          name: this.form.value.model,
          characteristic_ids: this.characteristicsArray
        }
        const newModel = await this.genome.addNewModel(modelData);
        data.model_id = newModel.id;
      }
      await this.genome.addRoomObject(data);
      if (Capacitor.isNativePlatform()) {
        this.navCtrl.back();
      } else {
        if (this.showConsumables) {
          this.navCtrl.navigateForward('inventory');
        } else {
          this.navCtrl.navigateForward('maintenance');
        }
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  buildInstallationDateItems() {
    const array: any = [];
    let year = moment().year();
    for (let i = year; i > (year - 232); i--) {
      array.push({
        value: i,
        viewValue: i
      });
    }
    return array;
  }

  getSalesCards() {
    return [
      {
        title: 'Get Maintenance Tips',
        text: 'When you add an item, we will tell you what the manufacturer recommends for that.',
        image: 'assets/img/tool-box.svg'
      },
      {
        title: 'Clean Smarter',
        text: 'Knowing what is in your home helps you and any pros you use follow best practices for that exact thing.',
        image: 'assets/img/light-bulb.svg'
      },
      {
        title: 'Track Maintenance',
        text: 'Tracking what was done makes it easier for future maintenance and repairs. It\'s like a medical record of your home!',
        image: 'assets/img/task-list.svg'
      }
    ];
  }

  dontScanHome() {
    this.hasNoItemsOrMap = false;
  }

  beginMapping() {
    const params = {
      address: this.navCtrl.getParam('address') || this.dialogParams?.address,
      floors: this.navCtrl.getParam('floors') || this.dialogParams?.floors,
      addressName: this.navCtrl.getParam('addressName') || this.dialogParams?.addressName
    };
    this.navCtrl.navigateForward(`map-introduction`, params);
  }

  selectGetInventoryAlerts() {
    this.getInventoryAlerts = !this.getInventoryAlerts;
    if (this.getInventoryAlerts) {
      this.form.controls.minQuantity.setValidators([Validators.required]);
    } else {
      this.form.controls.minQuantity.clearValidators();
      this.form.controls.minQuantity.updateValueAndValidity();
      this.form.patchValue({
        deliveryTimingPreferences: null,
        opportunisticOrderLevel: null,
        whereToOrder: null,
        minRestockingPrice: null,
        maxRestockingPrice: null,
        restockingNotes: null,
        minQuantity: null,
        maxQuantity: null,
        currentQuantity: null
      });
    }
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.categories = this.categoriesResponse;
    }
    const term = search.toLowerCase();
    this.categories = this.categoriesResponse.filter((option) => {
      if (option.name.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
  }

  goToAddressPage(addressId) {
    this.navCtrl.navigateForward(`edit-property/${addressId}`)
  }

  goToAddConciergeTask() {
    const params = {
      title: 'Concierge Action',
      type: 'support.concierge_task',
      isAddingRoomItem: true
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

}

import { Component, QueryList, ViewChildren, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { ViewportScroller } from '@angular/common';

import { Workflows } from "src/providers/workflows/workflows";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ChipPlaceholder, ChipTextArea } from "src/shared/components/chip-text-area/chip-text-area";
import { Loading } from 'src/shared/components/loading/loading';
import { FormValues, OperatorInput } from "../filter-nested-form/filter-nested-form.interface";
const ALL_OPTIONS_VALUE = '';

import { WorkflowsPage } from 'src/pages/workflows/workflows';
import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { ConfirmPage, ConfirmPageParamsModel } from "src/pages/confirm/confirm.component";

@Component({
  templateUrl: 'workflow.html',
  styleUrls: ['workflow.scss']
})

export class WorkflowPage implements OnInit {

  actionsForms: FormArray = new FormArray([]);
  allActions: any;
  allActionsData: any;
  allTriggers: any;
  allTriggersData: any;
  allOutputs: any;
  dontUpdateOutputs: boolean;
  didPublishWorkflow: boolean;
  dialogParams: any;
  errorMessage: string;
  filteredActions: any;
  filteredTriggers: any;
  isRightSideContent: boolean;
  isAddingWorkflow: boolean;
  isSavingDraft: boolean;
  isUpdatingWorkflow: boolean;
  isCreatingWorkflowVersion: boolean;
  latestVersion: any;
  loaded: boolean;
  paramForms: any = {};
  publishedVersionIsLatestVersion: boolean;
  searchForm: FormGroup;
  selectedTrigger: any;
  selectedActions: any[] = [];
  submitted: boolean;
  triggerInputsLoaded: boolean;
  workflow: any;
  workflowForm: FormGroup;
  workflowVersionForm: FormGroup;
  workflowId: string;
  radioItems: any;
  selectedChipTextAreaId: string;

  @ViewChildren(ChipTextArea) chipTextAreaComponents!: QueryList<ChipTextArea>
  @ViewChildren('tidySelect') tidySelectComponents!: QueryList<any>

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private workflowProvider: Workflows,
    private viewScroller: ViewportScroller
  ) {
    this.workflowForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.workflowVersionForm = this.formBuilder.group({
      trigger_data: this.formBuilder.group({
        id: ['', Validators.required],
        name: [''],
        description: [''],
        input_values: this.formBuilder.group({}),
        data: this.formBuilder.group({})
      }),
      actions_data: this.formBuilder.array([])
    });
    this.searchForm = this.formBuilder.group({
      title: ''
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.rightSidePanelService.setDialogPageTitle('Workflow');
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      await this.getAllTriggersAndActions();
      this.workflowId = this.route.snapshot.paramMap.get('workflowId') || await this.storage.retrieve('workflowId');
      await this.patchWorkflowForm();
      this.loaded = true;
    } catch (err) {
      console.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async getAllTriggersAndActions() {
    this.allTriggers = await this.workflowProvider.getWorkflowTriggerTypes();
    let triggerResult = this.allTriggers.reduce((acc, obj) => {
      let key = obj['category'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.allTriggers = Object.keys(triggerResult).map(key => ({ category: key, data: triggerResult[key] }));
    this.allTriggersData = [];
    this.allTriggers.map((trigger) => {
      trigger.data.map((data) => {
        this.allTriggersData.push(data);
      });
    });
    this.filteredTriggers = this.allTriggers;
    this.allActions = await this.workflowProvider.getWorkflowActionTypes();
    let actionResult = this.allActions.reduce((acc, obj) => {
      let key = obj['category'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.allActions = Object.keys(actionResult).map(key => ({ category: key, data: actionResult[key] }));
    this.allActionsData = [];
    this.allActions.map((trigger) => {
      trigger.data.map((data) => {
        this.allActionsData.push(data);
      });
    });
    this.filteredActions = this.allActions;
  }

  async createNewWorkflow() {
    this.isAddingWorkflow = true;
    const payload = {
      'name': 'Workflow ' + this.navCtrl.getParam('numberOfWorkflows') || this.dialogParams.numberOfWorkflows,
      'description': '',
      'tags' :[]
    };
    const workflow = await this.workflowProvider.createWorkflow(payload);
    await this.workflowProvider.pauseWorkflow(workflow.id);
    this.workflowId = workflow.id;
    localStorage.setItem('newWorkflowId', this.workflowId);
  }

  async patchWorkflowForm() {
    this.workflow = this.navCtrl.getParam('workflow') || this.dialogParams.workflow || await this.workflowProvider.getWorkflowWithVersions(this.workflowId);
    this.publishedVersionIsLatestVersion = this.workflow?.draft_version ? (this.workflow.draft_version.id < this.workflow.published_version.id) : true;
    this.workflowForm.patchValue({
      name: this.workflow.name
    });
    this.latestVersion = this.workflow.published_version;
    this.didPublishWorkflow = this.workflow.published_version;
    if (this.latestVersion?.workflow_version_trigger) {
      this.patchTriggerForm(this.latestVersion.workflow_version_trigger);
    }
    if (this.latestVersion?.workflow_version_actions) {
      await this.patchActionsForms(this.latestVersion.workflow_version_actions);
    }
  }

  async patchTriggerForm(trigger) {
    this.triggerInputsLoaded = false;
    const triggerType = this.allTriggersData.find((item) => item.id == trigger.trigger_id);
    const triggerData = {
      id: triggerType.id,
      name: trigger.name,
      icon_url: triggerType.icon_url,
      description: triggerType.description,
      inputs: triggerType.inputs
    };
    this.workflowVersionForm.patchValue({
      trigger_data: triggerData
    });
    this.selectedTrigger = triggerData;
    await this.getInputValues();
    const triggerInputsForm = this.workflowVersionForm.get('trigger_data').get('input_values') as FormGroup;
    triggerType?.inputs?.forEach((input) => {
      let value = this.latestVersion?.workflow_version_trigger?.input_values?.[input.key];
      const isAllValue = value == undefined || value == null || value?.length == 0;
      value = isAllValue ? ALL_OPTIONS_VALUE : value;
      triggerInputsForm.addControl(input.key, this.formBuilder.control(value, input.required ? Validators.required : null));
      const label = input.form.label;
      triggerInputsForm.addControl(input.key + '-radio', this.formBuilder.control(isAllValue ? '' : 'Trigger only on some ' + (label.toLowerCase().includes('address') ? 'properties' : label.toLowerCase()), null));
    });
    this.triggerInputsLoaded = true;
    this.forceUpdateForm(triggerType);
    this.selectedTrigger.inputs = this.getTriggerViewValues(this.selectedTrigger.inputs, this.workflowVersionForm.get('trigger_data').get('input_values').value);
  }

  forceUpdateForm(triggerType) {
    setTimeout(() => {
      const selectComponentsToArray = this.tidySelectComponents.toArray();
      selectComponentsToArray.forEach((selectComponent) => {
        triggerType?.inputs?.forEach((input) => {
          const label = input.form.label.toLowerCase().includes('address') ? 'Properties' : input.form.label;
          if (label === selectComponent.label) {
            selectComponent.items = input.items
              .map((item) => {
                return {
                  viewValue: item.viewValue,
                  value: item.value
                }
              });
            selectComponent.listedItems = selectComponent.items;
            if (this.latestVersion?.workflow_version_trigger?.input_values?.[input.key]) {
              const responseValue = this.latestVersion?.workflow_version_trigger?.input_values?.[input.key];
              selectComponent.form.patchValue(responseValue);
            }
          }
        })
      })
    }, 200)
  }

  async patchActionsForms(actions) {
    this.actionsForms = this.formBuilder.array([]);
    this.selectedActions = [];
    const loadInputs = (actionData, actionType) => {
      const inputs = Object.entries(actionData.input_values)
      const inputsForms = this.formBuilder.group({})
      inputs.forEach((input) => {
        const key = input[0];
        const value = input[1];
        const validator = actionType.required ? Validators.required : null;
        const control = this.formBuilder.control(value, validator)
        inputsForms.addControl(key, control)
      })
      return inputsForms;
    }
    const triggerOutputs = this.removeSomeTextFromResponse(this.latestVersion.workflow_version_trigger.output_variables);
    this.allOutputs = [
      ...triggerOutputs
    ];
    for (const [index, action] of actions.entries()) {
      const actionType = this.allActionsData.find((item) => item.id == action.action_id);
      const formId = Math.random().toString(36).substring(7);
      const actionForm = {
        formId: [formId],
        id: [actionType.id, Validators.required],
        name: [action.name, Validators.required],
        description: [action.description],
        input_values: loadInputs(action, actionType),
        icon_url: actionType.icon_url
      };
      const actionFormGroup = this.formBuilder.group(actionForm);
      this.actionsForms.push(actionFormGroup);
      const inputData = await this.workflowProvider.getInputData(actionType.id, 'Action');
      const inputOptions = this.getInputOptions(inputData);
      this.selectedActions.push({
        ...actionFormGroup.value,
        inputs: actionType.inputs,
        inputData,
        inputOptions,
        filterInputs: this.getParsedFilterGroups(inputOptions, index)
      });
    }
    setTimeout(() => {
      this.selectedActions.forEach((action, index) => {
        this.patchChipTextArea(
          action?.formId,
          this.actionsForms?.value?.[index]?.input_values
        )
      })
    }, 1000);
    this.addOutputsToActions();
  }

  getInputOptions(inputData: any[]) {
    if (!inputData) {
      return [];
    }
    const inputOptions = inputData.map((data) => data?.input_options).flat();
    return inputOptions || [];
  }

  patchChipTextArea(formId: string, input_values: object): void {
    const chipTextAreaComponents = this.chipTextAreaComponents
      .filter((component) => component.id === formId)
    if (!formId || !input_values) {
      return;
    }

    chipTextAreaComponents.forEach(chipTextAreaComponent => {
    chipTextAreaComponent.body = {
      content: this.parseBody(input_values[chipTextAreaComponent.inputKey], 'key'),
      chips: this.parseOutputs()
    }
    chipTextAreaComponent.readonly = true;
    chipTextAreaComponent.parseAndSetBody()
    })
  }

  parseOutputs(): ChipPlaceholder[] {
    return this.allOutputs.map((output) => {
      return {
        text: output.plain_title ? output.plain_title : output.title,
        value: output.key
      }
    })
  }

  parseBody(body: string, type: string): string {
    if (!body) {
      return '';
    }
    if (type === 'money') {
      return `$${Number(body)/100}`
    }
    const regex = /\{\{[^}]+\}\}/g;
    const matches = body.match(regex);
    if (!matches) {
      return body;
    }
    const parsedBody = matches.reduce((acc, match) => {
      const foundOutput = this.allOutputs.find((output) => output.key === match);
      if (foundOutput) {
        const value = type === 'plain_title' ? foundOutput?.plain_title || foundOutput?.title : foundOutput?.[type];
        return acc.replace(match, value);
      }
      return acc;
    }, body);
    return parsedBody;
  }

  addOutputsToActions() {
    if (this.dontUpdateOutputs) {
      return;
    }
    const triggerOutputs = this.removeSomeTextFromResponse(this.latestVersion.workflow_version_trigger.output_variables);
    this.dontUpdateOutputs = false;
    this.latestVersion.workflow_version_actions.map((action, versionIndex) => {
      this.selectedActions[versionIndex]['outputs'] = [{
        array: triggerOutputs,
        title: '<i>Add outputs from the <u>' + this.latestVersion.workflow_version_trigger.name + '</u> trigger</i>',
        showOptions: false
      }];
      if (versionIndex !== 0) {
        this.latestVersion.workflow_version_actions.map((action, actionIndex) => {
          if (versionIndex > actionIndex && action.output_variables.length > 0) {
            const actionOutputs = this.removeSomeTextFromResponse(action.output_variables);
            this.allOutputs.concat(actionOutputs);
            this.selectedActions[versionIndex]['outputs'].push({
              array: actionOutputs,
              title: '<i>Add outputs from the <u>' + action.name + '</u> action</i>',
              showOptions: false
            });
          }
        });
      }
    });
  }

  removeSomeTextFromResponse(outputs) {
    outputs.map((output) => {
      if (output.title?.includes('Event')) {
        output.title = output.title.replace('Event.', '');
      }
      if (output.title?.includes('Model: Job')) {
        output.title = output.title.replace('Model: Job.', '');
      }
      if (output.title?.includes('object')) {
        output.title = null;
      }
    });
    const filteredOutputs = outputs.filter((output) => output.title !== null)
    return filteredOutputs;
  }

  parseActions(actions: any[]): any[] {
    return actions.map((action, index) => {
      const { formId, ...rest } = action
      let payload = {
        ...rest,
        input_values: {}
      }
      if (this.latestVersion?.workflow_version_actions[index]) {
        payload['step_id'] = this.latestVersion.workflow_version_actions[index]?.step_id;
      }
      const foundChipTextArea = this.chipTextAreaComponents.filter((component) => component.id === action.formId);
      foundChipTextArea.forEach(input => {
        if (input.innerHtml) {
          payload['input_values'][input.inputKey] = input.innerHtml
        }
      });
      return payload;
    });
  }

  async publishWorkflowVersion() {
    this.submitted = true;
    if (!this.workflowForm.valid) {
      return;
    }
    try {
      if (this.hasNotFilledRequiredChipTextArea()) {
        this.handleNotFilledRequiredChipTextArea();
        return;
      }
      await this.workflowProvider.publishWorkflow(this.latestVersion.id);
      this.goToPage('automations/workflows', {}, WorkflowsPage);
    } catch (err) {
      console.error(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  hasNotFilledRequiredChipTextArea(): boolean {
    const chipTextAreaComponents = this.chipTextAreaComponents?.toArray();
    if (!chipTextAreaComponents) {
      return false;
    }
    const notFilledRequiredChipTextArea = chipTextAreaComponents.filter((component) => {
      return component.isRequired && (!component.innerHtml || component.innerHtml === '');
    })
    return notFilledRequiredChipTextArea.length > 0;
  }

  handleNotFilledRequiredChipTextArea(): void {
    const chipTextAreaComponents = this.chipTextAreaComponents?.toArray();
    if (!chipTextAreaComponents) {
      return;
    }
    const notFilledRequiredChipTextArea = chipTextAreaComponents.filter((component) => {
      return component.isRequired && (!component.innerHtml || component.innerHtml === '');
    })
    notFilledRequiredChipTextArea.forEach((component) => {
      component.control.markAsTouched();
    })
    const anchorId = notFilledRequiredChipTextArea[0]?.id;
    if (anchorId) {
      this.viewScroller.scrollToAnchor(anchorId);
    }
  }

  get actionsData(): FormArray {
    return this.workflowVersionForm.get('actions_data') as FormArray;
  }

  getActionForm(index: number): FormGroup {
    return this.actionsForms.at(index) as FormGroup;
  }

  getDefaultInputValue(input) {
    if (input.key == 'timezone') {
      return 'America/Los_Angeles';
    } else if (input.key == 'trigger_on_weekends') {
      return true;
    } else if (input.key == 'time_of_day') {
      return 12;
    } else if (input.key == 'day_of_the_week') {
      return 0;
    } else if (input.key == 'date_of_the_month') {
      return 1;
    }
  }

  async getInputValues() {
    const hasSelect = this.selectedTrigger.inputs.find((input) => input.form.type == 'select' || input.form.type == 'multi_select');
    if (hasSelect) {
      const inputData = await this.workflowProvider.getInputData(this.selectedTrigger.id, 'Trigger');
      inputData.map((data) => {
        this.selectedTrigger.inputs.map((input) => {
          if (data.input_key == input.key) {
            input['items'] = data.input_options.map((option) => ({
              value: option.value,
              viewValue: option.label
            }));

            const allOptionsLabel = (label) => {
              const foundLabel = {
                'Categories': 'Trigger on all categories',
                'Addresses': 'Trigger on all properties',
                'Address': 'Trigger on all properties',
                'Service Types': 'Trigger on all services',
                'Service Type': 'Trigger on all services',
                'Issue Type': 'Trigger on all issues'
              }[label];

              return foundLabel ?? `All ${label}`;
            }
            this.addRadioItems(input.form.label, allOptionsLabel(input.form.label))

            if (!input.required && input.form.type !== 'multi_select') {
              input['items'].unshift({
                value: ALL_OPTIONS_VALUE,
                viewValue: allOptionsLabel(input.form.label)
              });
            }
          }
        })
      });
    }
  }

  addRadioItems(label: string, allOptionsLabel: string): void {
    if (this.radioItems?.[label]) {
      return;
    }
    this.radioItems = {
      ...this.radioItems,
      [label]: [
        {
          value: ALL_OPTIONS_VALUE,
          viewValue: allOptionsLabel
        },
        {
          value: 'Trigger only on some ' + (label.toLowerCase().includes('address') ? 'properties' : label.toLowerCase()),
          viewValue: 'Trigger only on some ' + (label.toLowerCase().includes('address') ? 'properties' : label.toLowerCase()),
        },
      ]
    }
  }

  async goToEditWorkflow() {
    this.storage.save('workflowId', this.workflowId);
    this.goToPage(`automations/workflow/${this.workflowId}/edit`, {}, EditWorkflowPage);
  }

  getFilterNameInSingular(label: string): string {
    const filterNames = {
      'Categories': 'Category',
      'Addresses': 'Property',
      'Address': 'Property',
      'Service Types': 'Service',
      'Service Type': 'Service',
      'Issue Type': 'Issue',
      'Assigned to Concierge?': 'Concierge'
    }
    return filterNames[label] || label
  }

  getTriggerViewValues(inputs: any[], inputValues: any[]) {
    const parsedInputs = Object.keys(inputValues).map((key) => {
      const value = inputValues[key];
      const foundInput = inputs.find((item) => item.key === key);
      if (!foundInput) {
        return;
      }
      foundInput.displayValue = value;
      if (value === ALL_OPTIONS_VALUE || value === null) {
        foundInput.displayValue = 'All ' + this.getFilterNameInSingular(foundInput?.form?.label);
      }
      if (foundInput.key == 'assigned_to_concierge') {
        if (foundInput.displayValue == true) {
          foundInput.displayValue = 'Assigned to Concierge';
        } else if (foundInput.displayValue == false) {
          foundInput.displayValue = 'Not Assigned to Concierge';
        } else {
          foundInput.displayValue = 'No filter';
        }
      }
      if (Array.isArray(value)) {
        let string = '';
        value.map((value) => {
          const item = foundInput.items.find((item) => item.value == value);
          string += item.viewValue + ', ';
        });
        string = string.slice(0, -2);
        foundInput.displayValue = string;
      }
      return foundInput;
    }).filter((item) => item);
    return parsedInputs;
  }

  @Loading('', true)
  async pauseUnpauseWorkflow() {
    try {
      if (this.workflow.paused_at) {
        await this.workflowProvider.unpauseWorkflow(this.workflow.id);
        this.workflow.paused_at = null;
      } else {
        await this.workflowProvider.pauseWorkflow(this.workflow.id);
        this.workflow.paused_at = new Date();
      }
    } catch (err) {
      console.error(err);
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  getParsedFilterGroups(inputOptions: OperatorInput[], actionIndex: number): any[] {
    const inputValues = this.getActionForm(actionIndex)?.value?.input_values;
    const filterGroups = inputValues?.filter_groups;
    if (!filterGroups) {
      return [];
    }
    const parsedFilterGroups = filterGroups.map((filterGroup) => {
      return filterGroup.map((filter) => {
        const operatorLabel = inputOptions?.find(
          (option) => option.operator_key === filter.operator
        )?.operator_label;
        const leftValueOutput = this.allOutputs.find((output) => output?.key === filter?.left_value);
        const type = filter?.operator.includes('money') ? 'money' : 'plain_title';
        const parsedRightValue = this.parseBody(filter?.right_value, type);
        return {
          ...filter,
          operator: operatorLabel,
          left_value: leftValueOutput?.plain_title || leftValueOutput?.title || filter.left_value,
          right_value: parsedRightValue
        };
      });
    });
    return parsedFilterGroups;
  }

  async deleteWorkflow() {
    const confirmDelete: ConfirmPageParamsModel = {
      title: 'Delete Workflow?',
      body: 'You cannot undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete',
      confirmAction: async () => {
        this.confirmDeleteWorkflow();
        this.modalCtrl.dismiss();
      }
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmDelete,
      animated: false,
      cssClass: 'tidy-transparent-modal',
    });
    confirmationModal.present();
  }

  async confirmDeleteWorkflow() {
    try {
      await this.workflowProvider.deleteWorkflow(this.workflowId);
      this.goToPage('automations/workflows', {}, WorkflowsPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async goToPage(url, params, component) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Auth } from '../auth/auth';
import { Client } from 'src/providers/client/client';
import { Me } from 'src/providers/me/me';
import { Events } from "src/providers/events/events";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor (
    private auth: Auth,
    private client: Client,
    private events: Events,
    private me: Me,
    private router: Router
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }
    const addresses = await this.client.getAddresses(false);
    if (addresses.length == 0) {
      const accType = await this.me.getAccType();
      if (accType == 'employer' || accType == 'rental') {
        return true;
      } else {
        console.info('[AuthGuard] No addresses found, redirecting to add first address page');
        const page = this.client.getAddFirstAddressRedirectPage(accType);
        this.router.navigate([page]);
        this.events.publish('menu:close');
        return false;
      }
    }
    return true;
  }

}

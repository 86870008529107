import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { Client } from 'src/providers/client/client';
import { Pros } from 'src/providers/pros/pros';
import { Util } from 'src/shared/util/util';
import validationUtils from 'src/shared/util/validation-utils';

@Component({
  selector: 'tidy-add-pros-onboarding',
  templateUrl: './add-pros.component.html',
  styleUrls: ['./add-pros.component.scss'],
})
export class AddProsOnboardingComponent implements OnInit {
  
  submitted: boolean;
  forms: UntypedFormArray;
  serviceCategoryItems: any[] = [];
  whereToAddItems: any[] = [];
  propertiesToAddItems: any[] = [];
  loaded: boolean;
  addresses: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private onboardingProvider: OnboardingProvider,
    private pros: Pros,
    private util: Util,
    private client: Client
  ) {
    this.forms = fb.array([]);
  }

  async ngOnInit() {
    try {
      this.loaded = false;
      this.onboardingProvider.priorPages.push('addPros');
      const [addresses, serviceCategories] = await Promise.all([
        this.client.getAddresses(),
        this.getServiceCategoryItems(),
      ]);
      this.getWhereToAddItems(addresses);
      this.serviceCategoryItems = serviceCategories;
      this.pushEmptyForm();
      this.loaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  pushEmptyForm() {
    this.forms.push(
      this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, validationUtils.validateEmail]],
        phone: ['', [Validators.minLength(10)]],
        whereToAdd: ['all'],
        propertiesToAdd: [this.addresses.length == 1 ? this.addresses[0].id : ''],
        serviceCategories: [''],
      })
    );
    console.log(this.forms);
  }

  removeForm(index: number) {
    this.forms.removeAt(index);
  }

  goToUploadPros() {
    this.onboardingProvider.priorPages.push('addPros');
    this.onboardingProvider.showBackButton = true;
    this.onboardingProvider.page = 'createConciergeAction';
  }

  async addPros() {
    //TODO
    await this.onboardingProvider.markGoalAsCompleted('addPros');
    this.onboardingProvider.setShowOnboardingOnPage(false);
    this.onboardingProvider.setShowOnboardingInHeader(true);
  }

  async getServiceCategoryItems() {
    const serviceCategories = await this.pros.getServiceCategories();
    const array: any = [];
    serviceCategories.map((service) => {
      array.push({
        viewValue: service.name,
        value: service.id,
      });
    });
    return array;
  }

  learnMoreAddPros() {
    const url = 'https://help.tidy.com/my-pros';
    this.util.openUrl(url);
  }

  getWhereToAddItems(addresses) {
    this.whereToAddItems = [
      {
        value: 'all',
        viewValue: 'All Properties',
      },
      {
        value: 'select',
        viewValue: 'Select Properties',
      },
    ];
    this.propertiesToAddItems = [];
    addresses.map((address) => {
      this.propertiesToAddItems.push({
        viewValue: address.address_name
          ? address.address_name
          : address.address1,
        value: address.id,
      });
    });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent && !windowService.isDesktopRes"
    [title]="'Past Job'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper class="past-job-padding">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-desktop-header *ngIf="!isRightSideContent && windowService.isDesktopRes">
      <tidy-image
        [src]="'assets/svg/arrow-back-black.svg'"
        (action)="goBack()"
        class="desktop-back-icon">
      </tidy-image>
      <tidy-text
        [title]="'Past Job'">
      </tidy-text>
      <ng-container *ngIf="loaded">
        <div class="desktop-nav-button align-right">
          <tidy-button
            [action]="printPdf.bind(this)"
            [text]="'Print PDF'"
            [smallBody]="true">
          </tidy-button>
        </div>
      </ng-container>
      <ng-container *ngIf="!loaded">
        <ion-skeleton-text
          animated="true"
          style="
            width: 198px;
            height: 40px;
            border-radius: 6px;">
        </ion-skeleton-text>
      </ng-container>
    </tidy-desktop-header>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <ion-row>
        <ion-col
          [sizeLg]="!isRightSideContent && windowService.isDesktopRes && (hasPro || activity?.length > 0) ? 8 : 12"
          [sizeSm]="!isRightSideContent && windowService.isDesktopRes && (hasPro || activity?.length > 0) ? 6 : 12">

      <ng-container *ngIf="isRightSideContent">
        <tidy-row [align]="'center'" style="margin-bottom: 1rem;">
          <tidy-text
            [body]="'Print PDF'"
            class="link"
            (action)="printPdf()">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <div>
            <tidy-text
              [header]="job?.booking?.bookable_service?.name | titlecase">
            </tidy-text><br>
            <tidy-text
              [body]="displayAddress"
              [translate]="false">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [header]="(job?.job?.date | parseDate: 'ddd M/D')">
            </tidy-text><br>
            <tidy-text
              [body]="(job?.job?.start_time | parseDate: 'h:mma')">
            </tidy-text>
          </div>
        </tidy-row>
        <ng-container *ngIf="jobEvents?.length">
          <div style="margin-bottom: 2rem;">
            <ng-container *ngFor="let event of jobEvents">
              <tidy-row *ngIf="event?.event?.name === 'samantha_service.booking.created'">
                <tidy-text
                  [body]="event?.text + ' at ' + (event?.happened_at | customdate:'M/D h:mma')">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </div>
        </ng-container>

        <tidy-row *ngIf="hasPro">
          <tidy-row *ngFor="let pro of job?.homekeepers" class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/account.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="pro?.first_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-row>

        <tidy-row class="vertical-align-center" *ngIf="!hasPro">
          <tidy-image
            [src]="'assets/svg/person-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Cleaning canceled by you'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card style="padding: 0px" *ngIf="hasPro && isRightSideContent || !windowService.isDesktopRes">
        <div attr.id="map{{job?.job?.id}}" #map  style="width: 100%; height: 300px; border-radius: 6px;">
        </div>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToTasks()" class="arrow-to-top">
          <tidy-row [alignLastItemRight]="true">
            <tidy-image
              [src]="'assets/svg/alert-circle.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Tasks'">
            </tidy-text>
            <tidy-text
              [helper]="unresolvedLoggedIssues?.length">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [helper]="'unresolved'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToShareJobPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/paper-plane.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Share Job Link'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <feedback
        *ngIf="loaded && hasPro && job?.job?.state !== 'cancelled'"
        [cleaningId]="cleaningId"
        [pros]="job?.homekeepers"
        [feedback]="feedback"
        [isCleaningJob]="job?.booking?.bookable_service?.key?.includes('regular_cleaning')"
        (panelClosed)="onFeedbackPanelClosed()">
      </feedback>

      <parking-reimbursement (approvedParkingReimbursement)="reloadBilling()" [cleaningId]="cleaningId"></parking-reimbursement>

      <ng-container *ngIf="isRightSideContent || !windowService.isDesktopRes">
        <ng-container *ngFor="let item of activity">
          <tidy-card class="no-padding">
            <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding extra-right-padding extra-left-padding">
              <tidy-image
                [src]="'assets/img/account.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="item?.pro?.text ? item?.pro?.text : item?.pro?.first_name"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <ng-container
              *ngIf="!hasPassed14DaysFromJobStartDate(); else cantContactTemplate">
                <tidy-row class="extra-left-padding extra-right-padding" style="padding-bottom: 0px">
                  <form [formGroup]="form" novalidate action="#">
                    <tidy-select
                      style="width:35%; font-size: 8px"
                      [form]="form.controls.messageType"
                      formControlName="messageType"
                      [items]="messageSelectItems"
                      [smallText]="true"
                      [selectedItem]="selectedMessageType"
                      (optionChange)="updateIsInternalComment($event)">
                    </tidy-select>
                  </form>
                </tidy-row>
                <tidy-row class="field-top-padding extra-left-padding extra-right-padding" style="padding-top: 3px">
                  <form [formGroup]="form" novalidate action="#">
                    <tidy-textarea
                      [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                      formControlName="message"
                      [form]="form.controls.message"
                      [submitted]="submitted"
                      [errorMessage]="'Please enter a message to send.'"
                      [rightIconAction]="sendMessage.bind(this)"
                      [leftIconAction]="sendAttachment.bind(this)"
                      [showBlackButtons]="isInternalComment">
                  </tidy-textarea>
                </form>
              </tidy-row>
            </ng-container>
            <ng-template #cantContactTemplate>
              <tidy-row [align]="'center'" class="extra-bottom-padding" style="padding-left: 15px; padding-right: 15px;">
                <tidy-alert>
                  <tidy-text
                    [helper]="'You can\'t contact this pro because it has been more than 14 days since the job has been completed.'">
                  </tidy-text>
                </tidy-alert>
              </tidy-row>
            </ng-template>
            <tidy-row *ngIf="item?.messages?.length == 0" [align]="'center'" class="extra-right-padding extra-left-padding extra-bottom-padding">
              <tidy-text
                [helper]="'No Messages'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let activity of item?.messages; let last = last; let index = index">
              <message
                *ngIf="index < 3"
                [message]="activity"
                [last]="last"
                [showJobLink]="false"
                [proId]="item?.pro?.id">
              </message>
            </ng-container>
            <tidy-row [align]="'center'" *ngIf="item?.messages?.length > 3" class="extra-right-padding extra-left-padding extra-bottom-padding">
              <tidy-text
                class="link"
                [actionBody]="'View'"
                (action)="goToJobActivityPage(item)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                class="link"
                [actionBody]="item?.messages?.length - 3"
                (action)="goToJobActivityPage(item)">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                class="link"
                [actionBody]="'More Update' + ((item?.messages?.length - 3 > 1) ? 's' : '')"
                (action)="goToJobActivityPage(item)">
              </tidy-text>
            </tidy-row>
          </tidy-card>
        </ng-container>
      </ng-container>

      <tidy-card *ngIf="hasPro">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/question.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Need Help?'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let pro of job?.homekeepers">
          <tidy-card-button (action)="markNoShow(pro?.first_name, pro?.id)">
            <tidy-row class="vertical-align-center">
              <tidy-text
                [body]="pro?.first_name"
                [translate]="false">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="'Never Arrived'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </ng-container>

        <tidy-card-button (action)="missingItem()">
          <tidy-row class="vertical-align-center">
            <tidy-text
              [body]="'Missing an Item?'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>

        <tidy-card-button (action)="printPdf()">
          <tidy-row class="vertical-align-center">
            <tidy-text
              [body]="'View / Print Invoice'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/credit-card.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Billing Breakdown'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [helper]="'What you were billed for this.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="(billingInfo.ammountUsedSum | tcurrency) + ' '">
          </tidy-text>
          <tidy-text
            [body]="'Cost'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text *ngIf="billingInfo?.full_paid === true"
            [body]="'(paid in full)'">
          </tidy-text>
          <tidy-text *ngIf="billingInfo?.full_paid === false && job?.job?.state === 'cancelled'"
            [body]="'(cancellation fee)'">
          </tidy-text>
          <tidy-text *ngIf="billingInfo?.full_paid === false && job?.job?.status === 'incomplete'"
            [body]="'(paid in half)'">
          </tidy-text>
          <ng-container *ngIf="(billingInfo?.cleaning_state == 'cancelled' || billingInfo?.cleaning_state == 'cancelled_once') && billingInfo?.booking_id !== null">
            <tidy-text
              [body]="' '">
            </tidy-text>
            <tidy-text
              [actionBody]="'Waive Fee'"
              (action)="waiveFee(billingInfo?.booking_id)"
              class="link">
            </tidy-text>
          </ng-container>
        </tidy-row>
        <tidy-row *ngFor="let tip of tips">
          <tidy-text
            [body]="'+ ' + (tip?.amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'Tip'">
          </tidy-text><span>&nbsp;</span>
          <ng-container *ngIf="tip?.proName">
            <tidy-text
              [body]="'for'"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="tip?.proName | shortName"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
          </ng-container>
          <tidy-text *ngIf="tip?.status === 'pending'"
            [body]="'Edit Tip'"
            (action)="newTip(tip?.homekeeper_job_id, tip?.proName)"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let parkingReimbursement of parkingReimbursements">
          <tidy-text
            [body]="'+ ' + (parkingReimbursement?.amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'Parking Reimbursement for'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="parkingReimbursement?.proName"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'= ' + ((billingInfo?.ammountUsedSum + totalTipsValue) | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'Total'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/bank.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Payment Breakdown'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [helper]="'What you already paid for this.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let item of billingInfo?.details" [alignLastItemRight]="true">
          <tidy-text
            [body]="(item?.amount_used | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'of'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(item?.amount | tcurrency)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'charge'">
          </tidy-text>
          <tidy-text
            [helper]="(item?.date | parseDate: 'M/D/YY')">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <ng-container *ngIf="rooms?.length > 0">
        <tidy-row class="extra-bottom-padding" [alignLastItemRight]="true">
          <tidy-text
            [header]="'To-Dos'">
          </tidy-text>
          <tidy-text
            [actionBody]="'Edit'"
            (action)="goToEditToDos()"
            class="link">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngIf="bookingNote?.length || bookingFormAnswers?.length">
          <tidy-card-button class="arrow-to-top">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/information.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Job Note'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let note of bookingNote">
              <ng-container *ngIf="note?.type == 'text'">
                <tidy-text
                  [body]="note?.text">
                </tidy-text>
              </ng-container>
              <ng-container *ngIf="note?.type == 'image'">
                <tidy-image
                  [src]="note?.media_url">
                </tidy-image>
              </ng-container>
              <ng-container *ngIf="note?.type == 'video'">
                <tidy-image
                  [src]="note?.media_url">
                </tidy-image>
              </ng-container>
            </tidy-row>
            <ng-container *ngFor="let answer of bookingFormAnswers">
              <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'select'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                  </tidy-text>
                </tidy-row>
                <tidy-photo-note
                  *ngIf="answer?.template_input?.image_url"
                  [src]="answer?.template_input?.image_url">
                </tidy-photo-note>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label">
                  </tidy-text>
                </tidy-row>
                <tidy-carrousel
                  class="photo-notes-carrousel only-padding-bottom"
                  [scaleImages]="true">
                  <tidy-photo-note
                    *tidyCarrousel
                    [src]="answer?.data?.value">
                  </tidy-photo-note>
                </tidy-carrousel>
              </ng-container>
              <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
                <tidy-row>
                  <tidy-text
                    [body]="answer?.template_input?.label">
                  </tidy-text>
                </tidy-row>
                <tidy-carrousel
                  class="photo-notes-carrousel only-padding-bottom"
                  [scaleImages]="true">
                  <ng-container *ngFor="let imageUrl of answer?.data?.value">
                    <tidy-photo-note
                      *tidyCarrousel
                      [src]="imageUrl">
                    </tidy-photo-note>
                  </ng-container>
                </tidy-carrousel>
              </ng-container>
            </ng-container>
          </tidy-card-button>
        </tidy-card>

        <tidy-card>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <div style="display: flex; align-items: center;">
              <tidy-image
                [src]="'assets/img/camera.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="'Before Photos / Videos'">
              </tidy-text>
            </div>
            <div style="display: flex; align-items: center;" *ngIf="isFullWidth">
              <tidy-file-upload
                [accept]="'image/*'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                (addedImagesChange)="uploadPhoto($event, 'before_job')"
                [showTextLink]="true"
                style="margin-right: 10px">
              </tidy-file-upload>
              <tidy-file-upload
                [accept]="'video/mp4'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                (addedImagesChange)="uploadVideo($event, 'before_job')"
                [showTextLink]="true">
              </tidy-file-upload>
            </div>
          </div>
          <div *ngIf="!isFullWidth" style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
            <tidy-text
              *ngIf="isNativeMobile"
              [actionBody]="'Take Photo'"
              (action)="takePhoto('before_job')"
              class="link"
              style="margin-right: 10px">
            </tidy-text>
            <tidy-file-upload
              [accept]="'image/*'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
              (addedImagesChange)="uploadPhoto($event, 'before_job')"
              [showTextLink]="true"
              style="margin-right: 10px">
            </tidy-file-upload>
            <tidy-text
              *ngIf="isNativeMobile"
              [actionBody]="'Take Video'"
              (action)="takeVideo('before_job')"
              class="link"
              style="margin-right: 10px">
            </tidy-text>
            <tidy-file-upload
              [accept]="'video/mp4'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
              (addedImagesChange)="uploadVideo($event, 'before_job')"
              [showTextLink]="true">
            </tidy-file-upload>
          </div>
          <tidy-row *ngIf="beforePhotos?.length == 0" class="extra-top-padding">
            <tidy-text
              [helper]="'None Added'">
            </tidy-text>
          </tidy-row>
          <ion-row>
            <ng-container *ngFor="let item of beforePhotos; let i = index">
              <ion-col [size]="isFullWidth ? 6 : 12">
                <ng-container *ngIf="item.mediaType === 'video'">
                  <tidy-row>
                    <tidy-video
                      class="before-after-video"
                      [src]="item?.url"
                      style="width: 100%; height: auto; max-width: 100%;">
                    </tidy-video>
                  </tidy-row>
                </ng-container>
                <ng-container *ngIf="item.mediaType === 'photo'">
                  <tidy-photo-note
                    *ngIf="item?.url"
                    class="full-width no-margin photo-note-job"
                    [src]="item?.url"
                    style="width: 100%; height: auto; max-width: 100%;">
                  </tidy-photo-note>
                </ng-container>
                <div *ngIf="item?.metadata || item?.uploadedBy" class="timestamp-caption">
                  <tidy-row [alignLastItemRight]="true">
                    <tidy-text
                      [smallBody]="'Uploaded by:'"
                      class="white">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [smallBody]="item?.uploadedBy ? item?.uploadedBy : 'You'"
                      class="white">
                    </tidy-text>
                    <div>
                      <tidy-image
                        [src]="'assets/img/trash-outline-white.svg'"
                        (action)="removePhoto(item, i, 'before_job')"
                        class="body-size"
                        style="cursor: pointer">
                      </tidy-image>
                    </div>
                  </tidy-row>
                  <tidy-row *ngIf="item?.metadata?.location">
                    <tidy-text
                      [smallBody]="'Location:'"
                      class="white">
                    </tidy-text>
                    <tidy-text
                      [smallBody]="item?.metadata?.location"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="item?.metadata?.displayDate">
                    <tidy-text
                      [smallBody]="'Timestamp:'"
                      class="white">
                    </tidy-text>
                    <tidy-text
                      [smallBody]="(item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                </div>
              </ion-col>
            </ng-container>
          </ion-row>
        </tidy-card>

        <tidy-card *ngFor="let room of rooms">
          <tidy-row class="vertical-align-center">
            <tidy-image
              *ngIf="room?.icon"
              [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="room?.name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let toDo of room?.todos; let lastToDo = last">
            <tidy-row *ngIf="toDo?.title !== 'Take Before Photo' && toDo?.title !== 'Take After Photo' && toDo?.title !== 'Take Before Video' && toDo?.title !== 'Take After Video'" [ngClass]="!lastToDo ? 'extra-bottom-padding vertical-align-middle' : 'vertical-align-middle'">
              <tidy-image
                [matTooltip]="(toDos.getToDoTooltip(toDo?.customer_performance || toDo?.performance)) | translate"
                [src]="toDos.getToDoIcon(toDo?.customer_performance || toDo?.performance)"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="toDo?.title"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <ng-container *ngFor="let option of toDo?.options; let last = lastOption">
              <tidy-row *ngIf="option?.value_type !== 'photo' && option?.value_type !== 'photos'" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                <ng-container *ngIf="option?.notes && (option?.customer_value || option?.json_value || option?.value)">
                  <tidy-text
                    [body]="option?.name + ': ' + option?.notes"
                    [translate]="false">
                  </tidy-text><br>
                  <tidy-text
                    [body]="'Input: ' + (option?.customer_value || option?.json_value || option?.value)"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="!option?.notes && (option?.customer_value || option?.json_value || option?.value)">
                  <tidy-text
                    [body]="option?.name"
                    [translate]="false">
                  </tidy-text><br>
                  <tidy-text
                    [body]="'Input: ' + (option?.customer_value || option?.json_value || option?.value)"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="option?.notes && !option?.customer_value && !option?.value && !option?.json_value">
                  <tidy-text
                    [body]="option?.name + ': ' + option?.notes"
                    [translate]="false">
                  </tidy-text><br>
                  <tidy-text
                    [body]="'No value added by pro'"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="!option?.notes && !option?.customer_value && !option?.value && !option?.json_value">
                  <tidy-text
                    [body]="option?.name"
                    [translate]="false">
                  </tidy-text><br>
                  <tidy-text
                    [helper]="'No value added by pro'"
                    [translate]="false">
                  </tidy-text>
                </ng-container>
              </tidy-row>
              <tidy-row *ngIf="option?.value_type?.includes('photo')" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                <tidy-text
                  *ngIf="option?.notes"
                  [body]="option?.name + ': ' + option?.notes"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  *ngIf="!option?.notes"
                  [body]="option?.name"
                  [translate]="false">
                </tidy-text>
                <tidy-photo-note
                  *ngIf="option?.value"
                  class="full-width no-margin photo-note-job"
                  [src]="option?.value">
                </tidy-photo-note>
                <div *ngIf="option?.metadata" class="timestamp-caption">
                  <tidy-row *ngIf="option?.metadata?.location">
                    <tidy-text
                      [smallBody]="'Location:'"
                      class="white">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [smallBody]="option?.metadata?.location"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="option?.metadata?.displayDate">
                    <tidy-text
                      [smallBody]="'Timestamp:'"
                      class="white">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [smallBody]="(option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                      class="white">
                    </tidy-text>
                  </tidy-row>
                </div>
                <ng-container *ngFor="let option of option?.jsonValues">
                  <tidy-photo-note
                    *ngIf="option?.value"
                    class="full-width no-margin photo-note-job"
                    [src]="option?.value">
                  </tidy-photo-note>
                  <div *ngIf="option?.metadata" class="timestamp-caption">
                    <tidy-row *ngIf="option?.metadata?.location">
                      <tidy-text
                        [smallBody]="'Location:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="option?.metadata?.location"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="option?.metadata?.displayDate">
                      <tidy-text
                        [smallBody]="'Timestamp:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="(option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                  </div>
                </ng-container>
                <ng-container *ngIf="!option?.value && !option?.jsonValues">
                  <br>
                  <tidy-text
                    [helper]="'No photo taken by pro'">
                  </tidy-text>
                </ng-container>
              </tidy-row>
            </ng-container>
          </ng-container>
        </tidy-card>
      </ng-container>

      <tidy-card>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div style="display: flex; align-items: center;">
            <tidy-image
              [src]="'assets/img/camera.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'After Photos / Videos'">
            </tidy-text>
          </div>
          <div style="display: flex; align-items: center;" *ngIf="isFullWidth">
            <tidy-file-upload
              [accept]="'image/*'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
              (addedImagesChange)="uploadPhoto($event, 'after_job')"
              [showTextLink]="true"
              style="margin-right: 10px">
            </tidy-file-upload>
            <tidy-file-upload
              [accept]="'video/mp4'"
              [showPreview]="false"
              [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
              (addedImagesChange)="uploadVideo($event, 'after_job')"
              [showTextLink]="true">
            </tidy-file-upload>
          </div>
        </div>
        <div *ngIf="!isFullWidth" style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
          <tidy-text
            *ngIf="isNativeMobile"
            [actionBody]="'Take Photo'"
            (action)="takePhoto('after_job')"
            class="link"
            style="margin-right: 10px">
          </tidy-text>
          <tidy-file-upload
            [accept]="'image/*'"
            [showPreview]="false"
            [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
            (addedImagesChange)="uploadPhoto($event, 'after_job')"
            [showTextLink]="true"
            style="margin-right: 10px">
          </tidy-file-upload>
          <tidy-text
            *ngIf="isNativeMobile"
            [actionBody]="'Take Video'"
            (action)="takeVideo('after_job')"
            class="link"
            style="margin-right: 10px">
          </tidy-text>
          <tidy-file-upload
            [accept]="'video/mp4'"
            [showPreview]="false"
            [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
            (addedImagesChange)="uploadVideo($event, 'after_job')"
            [showTextLink]="true">
          </tidy-file-upload>
        </div>
        <tidy-row *ngIf="afterPhotos?.length == 0" class="extra-top-padding">
          <tidy-text
            [helper]="'None Added'">
          </tidy-text>
        </tidy-row>
        <ion-row>
          <ng-container *ngFor="let item of afterPhotos; let i = index">
            <ion-col [size]="isFullWidth ? 6 : 12">
              <ng-container *ngIf="item.mediaType === 'video'">
                <tidy-row>
                  <tidy-video
                    class="before-after-video"
                    [src]="item?.url"
                    style="width: 100%; height: auto; max-width: 100%;">
                  </tidy-video>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="item.mediaType === 'photo'">
                <tidy-photo-note
                  *ngIf="item?.url"
                  class="full-width no-margin photo-note-job"
                  [src]="item?.url"
                  style="width: 100%; height: auto; max-width: 100%;">
                </tidy-photo-note>
              </ng-container>
              <div *ngIf="item?.metadata || item?.uploadedBy" class="timestamp-caption">
                <tidy-row [alignLastItemRight]="true">
                  <tidy-text
                    [smallBody]="'Uploaded by:'"
                    class="white">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [smallBody]="(item?.uploadedBy ? item?.uploadedBy : 'You')"
                    class="white">
                  </tidy-text>
                  <div>
                    <tidy-image
                      [src]="'assets/img/trash-outline-white.svg'"
                      (action)="removePhoto(item, i, 'after_job')"
                      class="body-size"
                      style="cursor: pointer">
                    </tidy-image>
                  </div>
                </tidy-row>
                <tidy-row *ngIf="item?.metadata?.location">
                  <tidy-text
                    [smallBody]="'Location:'"
                    class="white">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [smallBody]="item?.metadata?.location"
                    class="white">
                  </tidy-text>
                </tidy-row>
                <tidy-row *ngIf="item?.metadata?.displayDate">
                  <tidy-text
                    [smallBody]="'Timestamp:'"
                    class="white">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [smallBody]="(item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                    class="white">
                  </tidy-text>
                </tidy-row>
              </div>
            </ion-col>
          </ng-container>
        </ion-row>
      </tidy-card>

      </ion-col>

      <ng-container *ngIf="!isRightSideContent && windowService.isDesktopRes && (hasPro || activity?.length > 0)">
        <ion-col [sizeLg]="4" [sizeSm]="6">
          <tidy-card style="padding: 0px" *ngIf="hasPro">
            <div attr.id="map{{job?.job?.id}}" #map  style="width: 100%; height: 300px; border-radius: 6px;">
            </div>
          </tidy-card>

          <ng-container *ngFor="let item of activity">
            <tidy-card class="no-padding">
              <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding extra-right-padding extra-left-padding">
                <tidy-image
                  [src]="'assets/img/account.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="item?.pro?.text ? item?.pro?.text : item?.pro?.first_name"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
              <ng-container
                  *ngIf="!hasPassed14DaysFromJobStartDate(); else cantContactTemplate">
                  <tidy-row
                    class="extra-left-padding extra-right-padding"
                    style="padding-bottom: 0px">
                    <form [formGroup]="form" novalidate action="#">
                      <tidy-select
                        style="width: 35%; font-size: 8px"
                        [form]="form.controls.messageType"
                        formControlName="messageType"
                        [items]="messageSelectItems"
                        [smallText]="true"
                        [selectedItem]="selectedMessageType"
                        (optionChange)="updateIsInternalComment($event)">
                      </tidy-select>
                    </form>
                  </tidy-row>
                  <tidy-row
                    class="field-top-padding extra-left-padding extra-right-padding"
                    style="padding-top: 3px">
                    <form [formGroup]="form" novalidate action="#">
                      <tidy-textarea
                        [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                        formControlName="message"
                        [form]="form.controls.message"
                        [submitted]="submitted"
                        [errorMessage]="'Please enter a message to send.'"
                        [rightIconAction]="sendMessage.bind(this)"
                        [leftIconAction]="sendAttachment.bind(this)"
                        [showBlackButtons]="isInternalComment">
                      </tidy-textarea>
                    </form>
                  </tidy-row>
                </ng-container>
                <ng-template #cantContactTemplate>
                  <tidy-row [align]="'center'" class="extra-bottom-padding" style="padding-left: 15px; padding-right: 15px;">
                    <tidy-alert>
                      <tidy-text
                        [helper]="'You can\'t contact this pro because it has been more than 14 days since the job has been completed.'">
                      </tidy-text>
                    </tidy-alert>
                  </tidy-row>
                </ng-template>
              <tidy-row *ngIf="item?.messages?.length == 0" [align]="'center'" class="extra-right-padding extra-left-padding extra-bottom-padding">
                <tidy-text
                  [helper]="'No Messages'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let activity of item?.messages; let last = last; let index = index">
                <message
                  *ngIf="index < 3"
                  [message]="activity"
                  [last]="last"
                  [showJobLink]="false">
                </message>
              </ng-container>
              <tidy-row [align]="'center'" *ngIf="item?.messages?.length > 3" class="extra-right-padding extra-left-padding extra-bottom-padding">
                <tidy-text
                  class="link"
                  [actionBody]="'View'"
                  (action)="goToJobActivityPage(item)">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  class="link"
                  [actionBody]="item?.messages?.length - 3"
                  (action)="goToJobActivityPage(item)">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  class="link"
                  [actionBody]="'More Update' + ((item?.messages?.length - 3 > 1) ? 's' : '')"
                  (action)="goToJobActivityPage(item)">
                </tidy-text>
              </tidy-row>
            </tidy-card>
          </ng-container>
        </ion-col>
      </ng-container>

      </ion-row>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [isfullWidthContent]="true" [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

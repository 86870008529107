<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="cameFromEditAddress ? 'Add Rooms' : 'Create First List'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-row [align]="'center'" *ngIf="!cameFromEditAddress">
          <tidy-text
            [body]="'Please let us know some detail about the address for this list:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="addressName">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'" *ngIf="cameFromEditAddress">
          <tidy-text
            [body]="'Please tell us more about:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="addressName">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <form [formGroup]="form">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-select
                [label]="'Number of Bedrooms'"
                [form]="form.controls.beds"
                formControlName="beds"
                [items]="roomOptions">
              </tidy-select>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [label]="'Number of Bathrooms'"
                [form]="form.controls.baths"
                formControlName="baths"
                [items]="roomOptions">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [label]="'Number of Floors'"
                [form]="form.controls.floors"
                formControlName="floors"
                [items]="floorOptions">
              </tidy-select>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-button
          [text]="cameFromEditAddress ? 'Add Rooms' : 'Create To-Do List'"
          [action]="save.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

export enum HubspotFieldIds {
    EMAIL_FIELD = 'tidy-email-field',
    PHONE_FIELD = 'tidy-phone-field',
  }

  export enum HubspotFormIds {
    LOGIN_FORM = 'log-in-form',
    CREATE_ACCOUNT_FORM = 'create-account-form',
    CONTACT_INFO_FORM = 'contact-info-form',
    PROPERTY_INFO_FORM = 'propertry-info-form',
  }

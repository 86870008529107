import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Util } from 'src/shared/util/util';
import { DateTime } from 'luxon';
import { Invoices } from './../../../providers/invoices/invoices';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AddressChangeService } from 'src/providers/address-change/address-change';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';

import { AddressModel } from 'src/models/address.model';
import { Logger } from 'src/providers/logger';
import { Integrations } from 'src/providers/integrations/integrations';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Client } from 'src/providers/client/client';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { ClientSettingsModel } from 'src/models/client.model';
import { Genome } from 'src/providers/genome/genome';
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';
import { Loading } from 'src/shared/components/loading/loading';
import { ToDos } from 'src/providers/to-dos/to-dos';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { Pros } from 'src/providers/pros/pros';

import { ScheduleListPage } from 'src/pages/schedule/schedule-list/schedule-list';
import { AddRoomItemPage } from 'src/pages/more/edit-address/add-room-item/add-room-item';
import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { AddRoomPage } from 'src/pages/more/edit-address/edit-rooms/add-room/add-room';
import { EditAddressDetailPage } from 'src/pages/edit-address-detail/edit-address-detail';
import { EditAddressLockPage } from 'src/pages/integrations/edit-address-lock/edit-address-lock';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { PropertyMappingPage } from 'src/pages/more/edit-address/property-mapping/property-mapping';
import { PropertyScanningPage } from 'src/pages/more/edit-address/property-mapping/property-scanning/property-scanning';
import { AddAddressPage } from 'src/pages/add-address/add-address';
import { PropertyOptionsPage } from 'src/pages/more/edit-address/property-options/property-options';
import { EditRoomsPage } from 'src/pages/more/edit-address/edit-rooms/edit-rooms';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'edit-address.html',
  styleUrls: ['edit-address.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PhotoCaptionService],
})
export class EditAddressPage implements OnInit {

  private addressChangeSubscription: Subscription;
  alertHeader: string;
  addressItems: any;
  addressDetail: any;
  address: AddressModel;
  addressFilter: TidySelectNumberValueModel[];
  addressName: string;
  addressResponse: any;
  customBackPage: string;
  form: UntypedFormGroup;
  hasLockIntegration: boolean;
  settings: ClientSettingsModel;
  errorMessage: string;
  hasAddressDetail: boolean;
  loggedIssuesResponse: any;
  panelClosedSubscription: Subscription;
  parkingSpots: { [id: string]: string} = {
    'meter' : 'at a meter',
    'paidlot' : 'in a paid lot',
    'street' : 'on the street',
    'myspot' : 'in my spot/driveway',
    'guest' : 'in guest parking'
  };
  remoteAccessAddresses: any;
  rooms: any;
  roomObjects: any = [];
  categories: any;
  characteristics: any;
  didRequestMap: boolean;
  objectModels: any;
  floors: any;
  mapItems: any;
  mapRequests: any;
  mapResponse: any;
  map: any;
  mapUrl: SafeUrl;
  nextJob: any;
  showCards = true;
  upcomingMaintenances: any;
  unresolvedLoggedIssues: any;
  userRole: string;
  invoices: any[];
  pros: any[];
  toDosList: any[];
  pastJobs: any[];
  addressId: number;
  isAddressItemsLoaded: boolean;
  isRoomsLoaded: boolean;
  isAccessNotesLoaded: boolean;
  isPropertyDetailsLoaded: boolean;
  isMaintenancesLoaded: boolean;
  isToDosLoaded: boolean;
  isProsLoaded: boolean;
  isBillsLoaded: boolean;
  isLoggedIssuesLoaded: boolean;
  isJobsLoaded: boolean;
  isMapRequestsLoaded: boolean;
  isPastJobsLoaded: boolean;
  isReordering = false;
  loaded: boolean;

  columns = [
    {
      items: [
        {
          name: 'Scheduled Jobs',
          value: 'scheduled-jobs',
          icon: 'assets/img/book.svg',
        },
        {
          name: 'Past Jobs',
          value: 'past-jobs',
          icon: 'assets/img/checkmark-calendar.svg',
        },
        {
          name: 'Tasks',
          value: 'tasks',
          icon: 'assets/img/alert.svg',
        },
        {
          name: 'Bills',
          value: 'bills',
          icon: 'assets/img/invoice.svg',
        },
        {
          name: 'Pros',
          value: 'pros',
          icon: 'assets/img/pros.svg',
        },
        {
          name: 'To-Dos',
          value: 'to-dos',
          icon: 'assets/img/inventory.svg',
        },
        {
          name: 'Next Suggested Maintenances',
          value: 'upcoming-maintenances',
          icon: 'assets/img/tool-box.svg',
        },
        {
          name: 'Smart Thermostat Integration',
          value: 'thermostat-integration',
          icon: 'assets/img/temperature.svg',
        },
      ],
    },
    {
      items: [
        {
          name: 'Access Notes',
          value: 'access-notes',
          icon: 'assets/img/notes.svg',
        },
        {
          name: 'Map',
          value: 'map',
          icon: 'assets/img/house-sketch.svg',
        },
        {
          name: 'Rooms',
          value: 'rooms',
          icon: 'assets/img/home.svg',
        },
        {
          name: 'Items',
          value: 'addressItems',
          icon: 'assets/img/task-list.svg',
        },
      ],
    },
  ];
  imutableColumns = this.columns;

  constructor(
    private addressChangeService: AddressChangeService,
    private client: Client,
    private currentAddress: CurrentAddress,
    private fb: UntypedFormBuilder,
    private integrations: Integrations,
    private navCtrl: CustomNavController,
    private genome: Genome,
    private route: ActivatedRoute,
    private schedule: Schedule,
    private logger: Logger,
    private toDos: ToDos,
    private genomeMaps: GenomeMaps,
    private modalCtrl: ModalController,
    private reportIssue: ReportIssue,
    private sanitazer: DomSanitizer,
    public windowService: WindowService,
    private invoicesProvider: Invoices,
    private prosProvider: Pros,
    private util: Util,
    private router: Router,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      map: [''],
      property: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.addressId = Number(this.route.snapshot.paramMap.get('addressId'));
    this.loadInitialData();
    this.addressChangeSubscription = this.addressChangeService.addressChange$.subscribe(
      (addressId: number) => {
        if (addressId !== this.addressId) {
          this.changeAddress(addressId);
        }
      }
    );
    this.loaded = true;
  }

  ngOnDestroy() {
    if (this.addressChangeSubscription) {
      this.addressChangeSubscription.unsubscribe();
    }
  }

  async loadInitialData(): Promise<void> {
    this.currentAddress.addressId = this.addressId.toString();
    localStorage.setItem('addressId', this.addressId.toString());
    this.didRequestMap = localStorage.getItem(`didRequestMap/${this.addressId}`) == 'true';
    this.customBackPage = localStorage.getItem('editPropertyBackPage') || 'properties';
    await this.retrieveColumnsOrder();
    this.getAddressPageData();
    this.getCleaningHistory();
    this.getLoggedIssues();
    this.fetchAddressMap();
    this.getIntegrations();
    this.getJobs();
    this.getInvoices();
    this.getCategoriesAndPros();
    this.getToDosList();
    this.form.patchValue({property: this.addressId})
  }

  async getCleaningHistory(): Promise<void> {
    try {
      this.isPastJobsLoaded = false;
      const cleaningHistory = await this.client.getCleaningsHistory();
      this.pastJobs = this.client.getCleaningHistByAddress(cleaningHistory, this.addressId);
      this.isPastJobsLoaded = true;
    } catch (err) {
      this.isPastJobsLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getLoggedIssues(): Promise<void> {
    try {
      this.isLoggedIssuesLoaded = false;
      this.loggedIssuesResponse = await this.reportIssue.getIssueReportsByAddress([this.addressId]);
      this.unresolvedLoggedIssues = this.parseUnresolvedLoggedIssues();
      this.isLoggedIssuesLoaded = true;
    } catch (err) {
      this.isLoggedIssuesLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getIntegrations(): Promise<void> {
    try {
      this.userRole = localStorage.getItem('user_role');
      if (this.userRole !== 'member') {
        const integrations = await this.integrations.getIntegrations();
        integrations.map((integration) => {
          if (integration.type == 'remote_property_access' && integration.key == 'remote_lock') {
            this.hasLockIntegration = true;
          }
        });
        if (this.hasLockIntegration) {
          this.remoteAccessAddresses = await this.integrations.getRemoteAccessAddresses();
          this.addRemoteAccessAddresses();
          this.pushIntegrationCard('remotelock-integrations');
        }
        this.pushIntegrationCard('lock-integrations');
      }
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  pushIntegrationCard(value: string): void {
    let integrationItem = {
      name: 'Lock Integrations',
      value: 'lock-integrations',
      icon: 'assets/img/smart-door.svg',
    }
    if (value === 'remotelock-integrations') {
      integrationItem = {
        name: 'RemoteLock Integrations',
        value: 'remotelock-integrations',
        icon: 'assets/img/smart-door.svg',
      }
    }
    const foundItemInColumn = this.columns.find((column) => column.items.find((item) => item.value === value));
    if (!foundItemInColumn) {
      this.columns[0].items.push(integrationItem);
      return;
    }
  }

  async getJobs(): Promise<void> {
    try {
      this.isJobsLoaded = false;
      const jobs = await this.schedule.getCards(this.addressId);
      this.nextJob = jobs.find((job) => job.template == 'cleaning'); //BE always returns 'cleaning' even for other service types
      this.isJobsLoaded = true;
    } catch (err) {
      this.isJobsLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getInvoices(): Promise<void> {
    try {
      this.isBillsLoaded = false;
      this.invoices = await this.invoicesProvider.getPendingInvoices(this.addressId);
      this.invoices = this.parseInvoices(this.invoices);
      this.isBillsLoaded = true;
    } catch (err) {
      this.isBillsLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getCategoriesAndPros(): Promise<void> {
    try {
      this.isProsLoaded = false;
      const categories = await this.prosProvider.getServiceCategoriesOrFetch();
      this.pros = await this.prosProvider.getAllProsByAddress();
      this.pros = this.parsePros(this.pros, categories);
      this.isProsLoaded = true;
    } catch (err) {
      this.isProsLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  async getToDosList(): Promise<void> {
    try {
      this.isToDosLoaded = false;
      this.toDosList = await this.toDos.getAllToDoListsForAddress(this.addressId);
      this.isToDosLoaded = true;
    } catch (err) {
      this.isToDosLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  parsePros(pros: any[], categories: any[]): any[] {
    return pros.map((pro) => {
      const serviceNames = pro.address_homekeeper_priorities?.map((service) => {
        const category = categories.find((category) => category.id == service?.service_type_id);
        const categoryName = category?.name === 'Regular Cleaning' ? 'Cleaning' : category?.name;
        return categoryName;
      }).join(', ');
      return {
        ...pro,
        serviceNames,
        name: pro?.first_name + (' ' + pro?.last_name ?? ''),
      }
    });
  }

  parseInvoices(invoices: any[]): any[] {
    return invoices.map((invoice) => {
      const serviceNames = invoice?.items?.map((item) => item?.service_details?.name)?.join(', ');
      const dates = invoice?.items?.map((item) => {
        const date = new Date(item?.service_details?.date);
        return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
      })?.join(', ');
      return {
        ...invoice,
        pro: invoice?.homekeeper_data?.name,
        serviceNames,
        dates
      }
    });
  }

  addRemoteAccessAddresses() {
    this.remoteAccessAddresses.map((remoteAccessAddress) => {
      if (remoteAccessAddress?.address?.id == this.addressId) {
        this.address['remote_access_address'] = remoteAccessAddress;
      }
    });
  }

  async getAddressPageData() {
    try {
      this.isRoomsLoaded = false;
      this.isAccessNotesLoaded = false;
      this.isPropertyDetailsLoaded = false;
      this.isMaintenancesLoaded = false;
      this.isMapRequestsLoaded = false;
      this.address = this.navCtrl.getParam('address');
      if (!this.address) {
        this.address = await this.genome.getAddress(this.addressId);
      }
      this.isAccessNotesLoaded = true;
      this.addressResponse = await this.client.getAddresses(true);
      this.addressFilter = this.client.parseAddressList(this.addressResponse, false);
      const [addressDetail, rooms, mapRequests, upcomingMaintenances, addressItems] = await this.getAddressInfo();
      this.addressItems = this.parseAddressItems(addressItems);
      this.isAddressItemsLoaded = true;
      this.upcomingMaintenances = upcomingMaintenances;
      this.isMaintenancesLoaded = true;
      this.addressDetail = addressDetail;
      this.isPropertyDetailsLoaded = true;
      this.addressName = this.buildAddressName();
      this.hasAddressDetail = this.checkIfHasAddressDetail();
      this.sortByfloor(this.toDos.buildRoomIcons(rooms));
      this.isRoomsLoaded = true;
      this.mapRequests = mapRequests;
      this.isMapRequestsLoaded = true;
    } catch (err) {
      this.isRoomsLoaded = true;
      this.isAccessNotesLoaded = true;
      this.isPropertyDetailsLoaded = true;
      this.isMaintenancesLoaded = true;
      this.isMapRequestsLoaded = true;
      this.handleLoadErrors(err);
    }
  }

  parseAddressItems(addressItems) {
    addressItems = addressItems.filter((item) => item?.consumable);
    return addressItems.map((item) => {
      return {
        ...item,
        name: (!item.model.model || item.model.model === '') ? `${item.model.category.name}` : item.model.name
      }
    });
  }

  handleLoadErrors(error: any): void {
    this.logger.error(error);
    const route = window.location.pathname;
    if (!route.includes('edit-property')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  reviewMapRequest(request){
    const params = {
      address: this.address,
      requestReview: request,
    }
    if(request?.redo_requests.includes('lot_outline')){
      this.goToPage(`map-building`, params, PropertyMappingPage);
    } else {
      this.goToPage('property-scanning', params, PropertyScanningPage);
    }
  }

  async getAddressInfo() {
    const addressDetail = this.genome.getAddressDetails(this.addressId);
    const rooms = this.genome.getRooms(this.addressId);
    const mapRequests = this.genomeMaps.checkMapRequests(this.addressId);
    const upcomingMaintenances = this.genome.getUpcomingMaintenances(this.addressId);
    const addressItems = this.genome.getRoomsObjects(this.addressId);
    return await Promise.all([addressDetail, rooms, mapRequests, upcomingMaintenances, addressItems])
  }

  @Loading('', true)
  async changeAddress(addressId) {
    if (addressId === 0) {
      return this.goToPage('add-property', {}, AddAddressPage);
    }
    this.addressId = addressId;
    this.loadInitialData();
    this.router.navigate(['/edit-property', this.addressId], { replaceUrl: true });
  }

  async fetchAddressMap() {
    try {
      this.mapResponse = await this.genomeMaps.getMapRender(this.addressId);
      if (this.mapResponse.length > 0) {
        this.map = JSON.parse(this.mapResponse[0]?.render_data);
        this.mapUrl = this.sanitazer.bypassSecurityTrustResourceUrl(this.map?.exterior?.url);
        this.mapItems = this.getMapItems();
        this.form.patchValue({map: this.mapItems[0]?.value});
      }
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  getMapItems() {
    return [
      {viewValue: 'Exterior', value: this.map?.exterior?.url},
       ...this.map.interior.floors.urls.map((url, i) =>
       {
          return { viewValue: `Level ${i + 1}`, value: url };
      })];
  }

  changeMap(newUrl) {
    this.mapUrl = this.sanitazer.bypassSecurityTrustResourceUrl(newUrl);
  }

  sortByfloor(rooms) {
    const floors = [];
    rooms.forEach(room => {
      floors[room.floor || 0] = floors[room.floor || 0] ?? [];
      floors[room.floor || 0].push(room);
    });
    this.floors = floors;
  }

  goToEditAccess() {
    const params = {
      address: this.address,
      hasLockDevicesIntegrated: this.getInUseDevices(this.address?.remote_access_address?.remote_access_devices)?.length > 0
    };
    this.goToPage('access-notes', params, PropertyOptionsPage);
  }

  goToEditRooms() {
    const params = {
      addressName: this.addressName,
      addressId: this.addressId
    }
    this.goToPage(`edit-property/${this.addressId}/edit-rooms`, params, EditRoomsPage);
  }

  goToAddItem() {
    const params = {
      hasNoItemsOrMap: this.addressItems?.length == 0 && this.mapRequests.length === 0 && !this.map,
      address: this.address,
      floors: this.floors,
      addressName: this.addressName
    };
    this.goToPage('add-item/address', params, AddRoomItemPage);
  }

  goToItemDetails(item) {
    const params = {
      addressId: this.addressId,
      itemId: item.id,
      cameFromEditItem: false
    };
    localStorage.setItem('roomItemBackPage', `edit-property/${this.addressId}`);
    this.goToPage(`item-details/${item.id}`, params, ItemDetailsPage);
  }

  async requestMap() {
    const payload = {
      key: 'property-map',
      note: 'Address ID: ' + this.addressId + ', Address Name: ' + this.addressName
    }
    this.client.requestUpcomingFeature(payload);
    const params = {
      header: 'Map & Maintenance Plan Requested',
      body: 'TIDY Concierge will reach out to help set these up with you.',
      buttonText: 'Ok',
      buttonRoute: `edit-property/${this.addressId}`
    };
    localStorage.setItem(`didRequestMap/${this.addressId}`, 'true');
    this.goToPage('success', params, SuccessPage);
  }

  async cancelMapRequestModal() {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Map Request?',
      body: `Do you wish to cancel your current map request? You'll still be able to create a new one later`,
      backText: 'Go Back',
      confirmText: 'Cancel ',
      confirmAction: this.cancelMapRequest.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async cancelMapRequest() {
    try {
      await this.genomeMaps.cancelMapRequest(this.mapRequests[0]?.id);
      const params = this.mountSuccessPageParams();
      this.modalCtrl.dismiss();
      this.goToPage('success', params, SuccessPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Map Request Canceled',
      body: '',
      buttonText: 'Ok',
      buttonRoute: 'more'
    };
  }

  checkIfHasAddressDetail() {
    let hasDetail = false;
    this.addressDetail.map((detail) => {
      if (detail.value) {
        hasDetail = true;
      }
    });
    return hasDetail;
  }

  goToAddRoomsFlow() {
    const params = {
      addressId: this.address.id,
      floor: 1
    };
    this.goToPage('add-new-room', params, AddRoomPage);
  }

  buildAddressName() {
    return this.address.address1 + ' ' + (this.address.address2 !== null ? (this.address.address2 + ' ') : '') + (this.address.city !== null ? (this.address.city + ' ') : '') + (this.address.zip !== null ? this.address.zip : '');
  }

  goToLoggedIssues() {
    localStorage.setItem('isAllAddressesSelected', 'false');
    localStorage.setItem('loggedIssuesBackPage', `edit-property/${this.addressId}`)
    const params = {
      loggedIssuesResponse: this.loggedIssuesResponse,
      unresolvedLoggedIssues: this.unresolvedLoggedIssues,
      address: this.address,
      addressFilter: this.addressFilter,
      addressResponse: this.addressResponse
    }
    this.navCtrl.navigateForward(`tasks/${this.addressId}`, params);
  }

  goToIntegrations() {
    this.navCtrl.navigateForward('integrations');
  }

  editAddress() {
    const params = {
      address: this.address,
      addressDetail: this.addressDetail,
      columns: this.columns,
    }
    this.goToPage('edit-property-detail', params, EditAddressDetailPage);
  }

  parseUnresolvedLoggedIssues() {
    return this.loggedIssuesResponse.filter((item) => item.status && item.status !== 'done');
  }

  goToLockIntegrationsPage() {
    const params = {
      addressId: this.addressId,
      showBackButton: true
    }
    localStorage.setItem('integrationsBackPage', `edit-property/${this.addressId}`);
    this.navCtrl.navigateForward('integrations/all', params);
  }

  goToSmartThermostatPage() {
    const params = {
      addressId: this.addressId,
      showBackButton: true
    }
    localStorage.setItem('integrationsBackPage', `edit-property/${this.addressId}`);
    this.navCtrl.navigateForward('integrations/all', params);
  }

  goToEditAddressLockPage(address) {
    const params = {
      address: address,
      remoteAccessAddresses: this.remoteAccessAddresses,
      nextPage: `edit-property/${this.addressId}`
    };
    this.goToPage('edit-address-lock', params, EditAddressLockPage);
  }

  getInUseDevices(devices) {
    let array = [];
    devices?.map((device) => {
      if (device?.in_use) {
        array.push((device));
      }
    });
    return array;
  }

  goToJobsPage() {
    this.currentAddress.addressId = this.addressId.toString();
    localStorage.setItem('isAllAddressesSelected', 'false');
    this.goToPage('schedule-list', {}, ScheduleListPage);
  }

  goToBillsPage() {
    const params = {
      addressId: this.addressId,
      addressName: this.addressName
    };
    this.navCtrl.navigateForward('bills', params);
  }

  goToProsPage() {
    const params = {
      addressId: this.addressId,
      pros: this.pros
    };
    localStorage.setItem('isAllAddressesSelected', 'true');
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows'
    this.navCtrl.navigateForward(url, params);
  }

  async goToPro(id: number) {
    const categories = await this.prosProvider.getServiceCategoriesOrFetch();
    const pro = this.pros.find((pro) => pro.id == id);
    let categoryId;
    pro.address_homekeeper_priorities.map((item) => {
      if (item.address_id == this.addressId) {
        categoryId = item.service_type_id;
      }
    });
    pro.address_homekeeper_priorities.map((item) => {
      const serviceName = categories.find((category) => category.id == item.service_type_id)?.name;
      if (serviceName == 'Regular Cleaning' && item.address_id == this.addressId) categoryId = item.service_type_id;
    });
    const params = {
      addressId: this.addressId,
      proId: id,
      categoryId: categoryId
    };
    this.goToPage(`my-pro/${id}`, params, MyProPage);
  }

  goToToDosPage() {
    const params = {
      addressId: this.addressId,
      toDosList: this.toDosList
    };
    this.navCtrl.navigateForward('all-to-do-lists', params);
  }

  goToPastJobsPage() {
    const params = {
      addressId: this.addressId,
      addressName: this.addressName
    };
    this.navCtrl.navigateForward('past-jobs', params);
  }

  toggleContent(room) {
    room.showContent = !room.showContent;
  }

  goBack() {
    this.navCtrl.navigateBack(this.customBackPage);
  }

  async retrieveColumnsOrder(): Promise<void> {
    try {
      const order = await this.client.getMetadata([`external.edit_address_${this.addressId}_order`]);
      if (order?.[0]?.value) {
        this.columns = JSON.parse(order[0].value);
        return;
      }
      this.columns = this.imutableColumns;
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  async goToPage(url, params, component = null) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.loadInitialData();
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';
import { Genome } from 'src/providers/genome/genome';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { EditRoomItemPage } from 'src/pages/more/edit-address/room-item-details/edit-room-item/edit-room-item';

@Component({
  templateUrl: 'room-item-details.html'
})

export class ItemDetailsPage implements OnInit {

  item: any;
  isRightSideContent: boolean;
  dialogParams: any;
  errorMessage: string;
  addressId: number;
  customBack: string;
  hasQuantityChange: boolean;
  upcomingMaintenances: any;
  loaded: boolean;
  pastMaintenances: any;

  constructor(
    private currentAddress: CurrentAddress,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private genome: Genome,
    private modalController: ModalController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Item');
      }
      this.addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
      const itemId = this.route.snapshot.paramMap.get('itemId') || this.dialogParams.itemId;
      this.item = await this.genome.getRoomObject(itemId);
      this.checkIfHasQuantityChangeHistory();
      this.customBack = localStorage.getItem('roomItemBackPage') || `edit-property/${this.addressId}`;
      const [upcomingMaintenances, pastMaintenances] = await this.getMaintenances(this.addressId);
      this.upcomingMaintenances = upcomingMaintenances;
      this.pastMaintenances = pastMaintenances;
      this.buildItemNames();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  checkIfHasQuantityChangeHistory() {
    this.item.change_history?.map((history) => {
      if (history?.data?.current_quantity) {
        this.hasQuantityChange = true;
      }
    });
  }

  async getMaintenances(addressId) {
    const upcomingMaintenances = this.genome.getUpcomingMaintenancesByRoomObject(addressId, this.item.id);
    const pastMaintenances = this.genome.getPastMaintenancesByRoomObject(addressId, this.item.id);
    return await Promise.all([upcomingMaintenances, pastMaintenances])
  }

  buildItemNames() {
    this.item.model.model = this.item.model.model === null ? 'No Model Specified' : this.item.model.model;
    this.item.model.name = this.item.model.name === null ? 'No Model Specified' : this.item.model.name;
  }

  goToEditItem() {
    const params = {
      item: this.item,
      addressId: this.addressId
    };
    this.goToPage(`edit-room-item`, params, EditRoomItemPage);
  }

  @Loading('', true)
  async deleteItem() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete item?',
      body: `Are you sure you want to delete ${this.item.model.name}?`,
      backText: 'Go Back',
      confirmText: 'Delete Item',
      confirmAction: this.confirmDeleteItem.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteItem() {
    try {
      await this.genome.inactivateRoomObject(this.item.id);
      if (this.isRightSideContent) {
        this.rightSidePanelService.closeRightSidePanel();
      } else {
        this.navCtrl.navigateForward('inventory');
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    } finally {
      this.modalController.dismiss();
    }
  }

  formatDate(date) {
    return LuxonDateTime.fromISO(date).toFormat('M/dd/yy');
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  getCharacteristics(characteristics) {
    return characteristics.map((characteristic) => {
      return characteristic.name;
    }).join(' / ');
  }

}

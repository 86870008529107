import { Injectable } from '@angular/core';

@Injectable()
export class Localstorage {

  getSingleData(type) {
    return localStorage.getItem(type);
  }

  getMultipleData(type) {
    return JSON.parse(localStorage.getItem(type));
  }

  setSingleData(name, data) {
    return localStorage.setItem(name, data);
  }

  setMultipleData(name, object) {
    return localStorage.setItem(name, JSON.stringify(object));
  }
}

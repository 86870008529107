import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { GenerateUuid } from '../uuid/uuid';
import { Platform } from '@ionic/angular';
import { Util } from '../../shared/util/util';

import * as Bowser from 'bowser';

@Injectable()
export class FeatureExperiments {

  constructor(
    private generateUuid: GenerateUuid,
    private httpClient: HttpClientCustom,
    private platform: Platform,
    private util: Util,
  ) {}

  getBrowserInfo(data) {
    data.resolution_length = window.screen.width;
    data.resolution_height = window.screen.height;
    data.operation_system = this.util.getOs();
    data.browser = Bowser.name;
    data.native = (this.platform.is('cordova')) ? true : false;
    data.page_name = window.location.hash;
    return data;
  }

  saveTestAnonymousUser(data) {
    const url = `api/v1/feature_experiments`;
    this.setUuid();
    data.session_token = localStorage.getItem('uuid');
    data = this.getBrowserInfo(data);
    return this.httpClient.post(url, data);
  }

  saveTest(data) {
    const url = `api/v1/customer/feature_experiments`;
    this.setUuid();
    data.session_token = localStorage.getItem('uuid');
    data = this.getBrowserInfo(data);
    return this.httpClient.post(url, data);
  }

  setUuid() {
    this.generateUuid.createNewHash();
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="isPaidBill ? 'Paid Bill' : 'Bill'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row [alignLastItemRight]="isLoggedIntoClientApp">
          <tidy-text
            [title]="'Bill From'">
          </tidy-text>
          <div *ngIf="isLoggedIntoClientApp" style="margin-right: -5px">
            <tidy-text
              [body]="'Copy Link'"
              (action)="copyBillLink()"
              class="link">
            </tidy-text>
          </div>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proName | titlecase"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proEmail"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proPhone | telephone">
          </tidy-text>
        </tidy-row>

        <div style="border-left: 1px solid #dddddd; border-right: 1px solid #dddddd; position: relative; background: #F0F6FA; margin-left: -11px; margin-right: -11px; padding: 10px;">
          <div style="position: absolute; top: 0; left: 0; right: 0; height: 7px; background: #275C7C;"></div>
          <div style="padding-top: 7px">
            <tidy-row [alignLastItemRight]="true">
              <div>
                <tidy-text
                  [body]="'To: '">
                </tidy-text>
                <tidy-text
                  [body]="((companyName || clientName) | titlecase)"
                  [translate]="false">
                </tidy-text>
              </div>
              <div style="align-self: end; margin-right: -5px">
                <tidy-text
                  [body]="('Sent ' | translate) +
                  (invoice?.created_at | customdate:'M/D/YY')">
                </tidy-text>
              </div>
            </tidy-row>
          </div>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Owed'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <ng-container *ngFor="let service of invoice?.items; let last = last">
          <tidy-row [alignLastItemRight]="true">
            <div style="width:85%">
              <tidy-text
                [header]="service?.service_details?.name | titlecase">
              </tidy-text>
            </div>
            <div>
              <tidy-text
                *ngIf="service?.amount"
                [body]="service?.amount | tcurrency">
              </tidy-text>
              <tidy-text
                *ngIf="!service?.amount"
                [body]="invoice?.amount | tcurrency">
              </tidy-text>
            </div>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="(service?.service_details?.date | customdate:'M/D') +
              (' at ' | translate) +
              service?.service_details?.address?.address +
              ', ' +
              (service?.service_details?.address?.unit ? (('Unit ' | translate) + service?.service_details?.address?.unit + ', ') : '') +
              service?.service_details?.address?.zip"
              [translate]="false">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-top-padding vertical-align-center" *ngIf="!cameFromClientHub">
            <tidy-image
              [src]="'assets/img/clock-black.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Job Duration'">
            </tidy-text>
            <tidy-text
              *ngIf="!proReportedDuration && !fromStartDuration"
              [body]="': N/A'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="proReportedDuration">
            <tidy-text
              [body]="'Reported by Pro:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="proReportedDuration">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="fromStartDuration">
            <tidy-text
              [body]="'Using Realtime Updates:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="fromStartDuration">
            </tidy-text>
          </tidy-row>

          <tidy-row [ngClass] = "last ? '' : 'extra-bottom-padding'">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
        </ng-container>

        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="'Total'">
          </tidy-text>
          <tidy-text
            [body]="'<b>' + (invoice?.amount | tcurrency) + '</b>'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'We thank you for your business!'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="proName"
            class="signature"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>

        <ng-container class="no-padding" *ngIf="isLoggedIntoClientApp">
          <tidy-row class="extra-top-padding">
            <tidy-text
              [header]="'Bill Notes'">
            </tidy-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-select
                style="width:35%; font-size: 8px"
                [form]="form.controls.messageType"
                formControlName="messageType"
                [items]="messageSelectItems"
                [smallText]="true"
                [selectedItem]="selectedMessageType"
                (optionChange)="updateIsInternalComment($event)">
              </tidy-select>
            </form>
          </tidy-row>
          <tidy-row style="padding-top: 3px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-textarea
                [label]="isInternalComment ? 'Add Internal Note' : 'Add Note'"
                formControlName="message"
                [form]="form.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="sendMessage.bind(this)"
                [leftIconAction]="sendAttachment.bind(this)"
                [showBlackButtons]="isInternalComment">
              </tidy-textarea>
            </form>
          </tidy-row>
          <tidy-row *ngIf="invoiceNotes?.length == 0" [align]="'center'">
            <tidy-text
              [helper]="'No Bill Notes'">
            </tidy-text>
          </tidy-row>
          <tidy-row></tidy-row>
          <ng-container *ngFor="let note of invoiceNotes">
            <message
              [message]="note"
              [last]="last"
              [isInvoiceNote]="true">
            </message>
          </ng-container>
        </ng-container>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

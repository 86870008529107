<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Job Backup Times'"
    [canGoBack]="true"
    [class]="extraClass">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="categoryImage"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="job?.template_data?.booking?.bookable_service?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                  ' ' +
                  (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D') +
                  ' at ' +
                  (job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'If my Pro can\'t make this time, automatically find a new time for my job at one of these times:'">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let date of backupTimes">
        <tidy-row *ngIf="!date.checked" [alignLastItemRight]="true">
          <tidy-text
            [header]="date?.date | customdate:'dddd M/D':''">
          </tidy-text>
          <tidy-text
            [body]="'Add Backup Times'"
            (action)="addTimesToDate(date)"
            class="link">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="date?.checked">
          <tidy-row>
            <tidy-text
              [header]="date?.date | customdate:'dddd M/D':''">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let time of date?.times; let i = index">
            <tidy-two-column-row *ngIf="i % 3 === 0">
              <tidy-checkbox
                [items]="[time]"
                [(checked)]="time.checked"
                [initValue]="time.checked">
              </tidy-checkbox>
              <ng-container *ngIf="date?.times[i+1] !== undefined">
                <tidy-checkbox
                  [items]="[date?.times[i+1]]"
                  [(checked)]="date?.times[i+1].checked"
                  [initValue]="date?.times[i+1].checked">
                </tidy-checkbox>
              </ng-container>
              <ng-container *ngIf="date?.times[i+2] !== undefined">
                <tidy-checkbox
                  [items]="[date?.times[i+2]]"
                  [(checked)]="date?.times[i+2].checked"
                  [initValue]="date?.times[i+2].checked">
                </tidy-checkbox>
              </ng-container>
            </tidy-two-column-row>
          </ng-container>
        </ng-container>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save Changes'"
        [action]="saveJobBackupTimes.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav
>
  <tidy-top-nav [class]="extraClass" [noBow]="true" [title]="title"> </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav; context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'" class="extra-bottom-padding">
      <tidy-text [body]="body"> </tidy-text>
    </tidy-row>

    <tidy-alert *ngIf="showAutomaticBookingQuestion">
      <tidy-row class="extra-bottom-padding">
        <tidy-image
          [src]="'assets/img/automatic-booking.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [body]="'<b>Automatic Booking</b>'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'You had a guest check out on'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="reservationDateTime">
        </tidy-text>
        <tidy-text
          [body]="'. Do you want to keep automatic booking on for this reservation?'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-checkbox
          [items]="[{value: true, viewValue: 'Keep Automatic Booking On'}]"
          [initValue]="true"
          (checkedChange)="updateAutomaticBooking($event)">
        </tidy-checkbox>
      </tidy-row>
    </tidy-alert>

    <tidy-two-column-row *ngIf="sameRowButtons">
      <tidy-button
        [text]="backText"
        [action]="backAction.bind(this)"
        [class]="backButtonClass"
      >
      </tidy-button>
      <tidy-button
        [text]="confirmText"
        [action]="confirm.bind(this)"
        [class]="confirmButtonClass"
      >
      </tidy-button>
    </tidy-two-column-row>

    <ng-container *ngIf="!sameRowButtons">
      <tidy-row>
        <tidy-button
          [text]="backText"
          [action]="backAction.bind(this)"
          [class]="backButtonClass"
        >
        </tidy-button>
      </tidy-row>
      <tidy-row>
        <tidy-button
          [text]="confirmText"
          [action]="confirm.bind(this)"
          [class]="confirmButtonClass"
        >
        </tidy-button>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="blockAction">
      <tidy-row [align]="'center'" class="extra-top-padding extra-bottom-padding">
        <tidy-text
          [body]="'Never want them again?'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-button
          [text]="'Block Pro From All Properties'"
          [action]="triggerBlockAction.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>
    </ng-container>

    <tidy-row [align]="'center'" *ngIf="errorMessage"> 
        <tidy-error-message [text]="errorMessage"></tidy-error-message>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

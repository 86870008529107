<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Share To-Do List'"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="!loaded && errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/svg/list-circle.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="selectedListName"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngFor="let room of rooms" class="vertical-align-center">
          <tidy-image
            *ngIf="room?.type !== 'group'"
            [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="room?.name"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding extra-top-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Share your To-Do List with anyone and they can mark what was done:'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="listLink">
          </tidy-text>
          <tidy-text
            *ngIf="copied"
            style="margin-left:5px"
            [helper]="'Copied'">
          </tidy-text>
        </tidy-row>
        <tidy-button
          [action]="copyLink.bind(this)"
          [text]="'Copy To-Do List Link'"
          class="primary">
        </tidy-button>
        <tidy-row class="extra-top-padding" [align]="'center'">
          <tidy-text
            class="link"
            [actionBody]="'Learn More'"
            (action)="learnMore()">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'about To-Do List links'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

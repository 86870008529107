import { Component } from '@angular/core';
import { Concierge } from 'src/providers/concierge/concierge';
import { Util } from 'src/shared/util/util';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

@Component({
  selector: 'integrate-upsell-provider',
  templateUrl: './integrate-upsell-provider.component.html',
  styleUrls: ['./integrate-upsell-provider.component.scss']
})

export class IntegrateUpsellProviderComponent {

  whatToOfferItems = [
    {
      name: 'Early check in',
      description: 'Enable automated handling of early check-in requests.',
      isSelected: false
    },
    {
      name: 'Late check out',
      description: 'Enable automated handling of late check-out requests.',
      isSelected: false
    },
    {
      name: 'Mid-stay clean',
      description: 'Enable automated handling of mid-stay clean requests.',
      isSelected: false
    }
  ]

  howToRequestItems = [
    {
      name: 'A digital guidebook',
      description: 'Placeholder text',
      isSelected: false
    },
    {
      name: 'A PMS',
      description: 'Placeholder text',
      isSelected: false
    },
    {
      name: 'Directly on platform (Airbnb, etc)',
      description: 'Placeholder text',
      isSelected: false
    }
  ]

  constructor(
    private concierge: Concierge,
    private util: Util,
    private onboardingProvider: OnboardingProvider
  ) {}

  selectWhatToOffer(item) {
    item.isSelected = !item.isSelected;
  }

  selectHowToRequestUpsells(item) {
    item.isSelected = !item.isSelected;
  }

  async save() {
    try { 
      await Promise.all([
        this.concierge.addConciergeItem({
          data: {
            whatToOfferItems: this.whatToOfferItems.filter(item => item.isSelected),
            howToRequestUpsellsItems: this.howToRequestItems.filter(item => item.isSelected)
          },
          type: 'support.concierge_task',
        }),
        this.onboardingProvider.markGoalAsCompleted('upsell')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Upsell settings saved');
    } catch (err) {
      this.util.showError(err?.error?.message ? err.error.message : err.message);
    }
  }
  
} 
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { LostItem } from 'src/providers/lost-item/lost-item'
import { Logger } from 'src/providers/logger';

@Component({
  templateUrl: 'lost-item.html',
})
export class LostItemPage implements OnInit {

  cleaningId: string;
  homekeeperJobId: string;
  errorMessage: string;
  description: string;
  form: UntypedFormGroup;
  submitted: boolean;
  jobIds: any;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private activatedRoute: ActivatedRoute,
    private lostItem: LostItem,
    private logger: Logger,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      items: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Lost Item');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.cleaningId = this.activatedRoute.snapshot.paramMap.get('cleaningId') || this.dialogParams.cleanignId;
    this.jobIds = this.navCtrl.getParam('jobIds') || this.dialogParams.jobIds;
  }

  async confirm() {
    this.submitted = true;
    this.errorMessage = '';
    if (!this.form.valid) {
      return;
    }
    try {
      const items = this.form.get('items').value
      await Promise.all(this.jobIds.map(async id => {
        await this.lostItem.update(this.cleaningId, id, items);
      }));
      const params = this.mountSuccessPageParams();
      this.rightSidePanelService.navigateTo('success', params);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err);
    }
  }

  backToPastJob() {
    this.navCtrl.back();
  }

  mountSuccessPageParams() {
    return {
      header: 'Pro Notified',
      body: 'We have emailed the other party requesting they call you to help coordinate a pickup. This temporary session will automatically close whenever both parties stop communicating for 48 hours.',
      buttonText: 'Ok',
      buttonRoute: `past-job/${this.cleaningId}`
    };
  }

}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { WindowService } from 'src/shared/providers/window.service';
import { Pros } from 'src/providers/pros/pros';
import { DateTime as LuxonDateTime, Duration } from 'luxon';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Addresses } from "src/providers/customer/addresses";
import { BookJob } from 'src/providers/book-job/book-job';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Requests } from 'src/providers/requests/requests';
import { PlanData } from 'src/providers/plans/plan-data';
import { TimeRanges } from 'src/providers/time-ranges/time-ranges';
import { Loading } from 'src/shared/components/loading/loading';
import { AddressModel } from 'src/models/address.model';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ConfirmBookingPage } from '../booking/confirm-booking/confirm-booking';
import { ConfirmBookingPaymentPage } from '../confirm-booking-payment/confirm-booking-payment';
import { ContactConciergePage } from '../concierge/contact-concierge/contact-concierge';
import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'request-pro.html'
})

export class RequestProPage implements OnInit, OnDestroy {

  address: AddressModel;
  addingOneTimeJob: boolean;
  availabilityRequestId: any;
  bookingExperiment: any;
  bookingType: any;
  cameFromBookJob: boolean;
  cameFromEditRequest: boolean;
  didChooseFrequency: boolean;
  didChooseService: boolean;
  errorMessage: string;
  frequency: any;
  frequencyValue: any;
  form: UntypedFormGroup;
  frequencyName: string;
  hasNonTrialPaidSubscription: any;
  loaded: boolean;
  priceExperiment: any;
  planId: any;
  service: any;
  isLoadingTimes: boolean;
  notSelectedTimesError: boolean;
  pro: any;
  requestableDays: any;
  serviceType: any;
  serviceValue: any;
  selectedTimes = [];
  services: any;
  showMoreServices: boolean;
  timeRangeForms: Array<Array<UntypedFormGroup>> = Array(28).fill(null).map(() => []);
  weekDayCheckBox: Array<UntypedFormGroup>;
  timeOpts = [];
  todayStartOpts = [];
  dialogParams: any;
  isRightSideContent = true;
  serviceTypeId: any;
  providedStartDate: string;
  topNavHeight: number = 0;
  private resizeObserver: ResizeObserver;

  constructor(
    private addresses: Addresses,
    private bookJobService: BookJob,
    private card: Card,
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private timeRanges: TimeRanges,
    private planData: PlanData,
    public requests: Requests,
    private pros: Pros,
    private storage: TidyStorage,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      service: ['', Validators.required],
      frequency: ['', Validators.required]
    });
    this.weekDayCheckBox = Array(28).fill(null).map(() => this.fb.group({
      checked: false
    }));
  }

  @Loading("", true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Request');
      try {
        this.rightSidePanelService.setDialogLoading(true);
        await this.getPageParams();
        await this.loadPageParams();
        this.rightSidePanelService.setDialogLoading(false);
        this.observeTopNavHeight();
      } catch (e) {
        this.navCtrl.back();
      }
    } else {
      try {
        await this.getPageParams();
        await this.loadPageParams();
        this.observeTopNavHeight();
      } catch (e) {
        this.navCtrl.back();
      }
    }
  }

  private observeTopNavHeight() {
    const element = document.getElementById('top-nav-height');
    if (element) {
      const tidyContentMarginTop = 55;
      this.topNavHeight = element.offsetHeight;
      this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.topNavHeight = entry.contentRect.height - tidyContentMarginTop;
        this.changeDetectorRef.detectChanges();
       }
      });
      this.resizeObserver.observe(element);
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  async getPageParams() {
    await this.client.getClientInfo();
    this.planId = await this.getParam('planId');
    this.bookingType = await this.getParam('bookingType');
    this.hasNonTrialPaidSubscription = await this.getParam('hasNonTrialPaidSubscription');
    this.bookingExperiment = await this.getParam('bookingExperiment');
    this.priceExperiment = await this.getParam('priceExperiment');
    this.address = await this.getParam('address');
    this.availabilityRequestId = await this.getParam('availabilityRequestId');
    this.cameFromBookJob = await this.getParam('cameFromBookJob');
    this.cameFromEditRequest = await this.getParam('cameFromEditRequest');
    this.pro = await this.getParam('pro');
    this.rightSidePanelService.setDialogPageTitle('Request ' + this.pro?.first_name);
    this.serviceTypeId = await this.getParam('serviceTypeId') || 1;
    this.addingOneTimeJob = await this.getParam('addingOneTimeJob');
    const service = await this.getParam('service');
    this.serviceValue = service?.value;
    const frequency = await this.getParam('frequency');
    this.frequencyValue = frequency?.value;
    this.frequency = await this.getParam('frequency');
    this.serviceType = await this.getParam('serviceType');
    const today = LuxonDateTime.local().toFormat('dd-MM-yyyy');
    this.providedStartDate = await this.getParam('providedStartDate') || today;
  }

  async getParam(paramName): Promise<any> {
    const param = this.dialogParams?.[paramName] || this.navCtrl.getParam(paramName) || await this.storage.retrieve(paramName);
    this.storage.save(paramName, param);
    return param;
  }

  async loadPageParams() {
    this.loaded = false;
    const proId = !isNaN(this.pro.id) ? this.pro.id : this.pro.id.replace("homekeeper:", "");
    this.loadTimeOpts(this.pro);
    this.pro.private = await this.isPrivatePro(proId);
    if (this.pro.private) {
      this.services = await this.getParam('services') || await this.bookJobService.fetchTeamServices(proId);
    } else {
      this.services = await this.getParam('services') || await this.bookJobService.fetchRequiredTeamServices(proId, true);
    }
    if (this.cameFromEditRequest) {
      await this.prePopulateFormFromEditRequest();
    }
    if (this.cameFromBookJob) {
      await this.prePopulateFormFromBookJob();
    }
    if (!this.pro.private) {
      await this.addPriceDataToServices(proId)
    }
    this.loaded = true;
  }

  async addPriceDataToServices(proId) {
    try {
      const teamServices = await this.bookJobService.fetchTeamServices(proId);
      teamServices.map((item) => {
        this.services.map((service) => {
          if (item.key.includes(service.key)) {
            service['isBasePriceAllowedToBook'] = item.isBasePriceAllowedToBook;
            service['prices'] = item.prices;
            service['serviceTypeId'] = item.serviceType.id;
          }
        })
      })
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async loadTimeOpts(proData) {
    const timeRangeOpts = this.timeRanges.timeRangesOpts();
    if (proData.private) {
      this.timeOpts = timeRangeOpts;
    } else {
      this.timeOpts = this.timeRanges.tidyJobRangesOpts();
    }
    const currentTime = LuxonDateTime.fromObject({}, {
      zone: this.address.timezone,
    });
    const _30minAhead = currentTime.plus({minutes: 30}).toFormat('HH:mm');
    this.todayStartOpts = this.timeOpts.filter(opt => opt.value > _30minAhead);
  }

  async prePopulateFormFromBookJob() {
    this.didChooseService = true;
    this.form.patchValue({service: this.serviceValue});
    this.form.patchValue({frequency: this.frequencyValue});
    await this.selectFrequency(this.frequency);
  }

  async prePopulateFormFromEditRequest() {
    const service = this.services.find(serviceOpt => serviceOpt?.key.includes(this.serviceType));
    if (!service) {
      return;
    }
    this.didChooseService = true;
    this.serviceValue = service.key;
    this.form.patchValue({service: this.serviceValue});
    this.form.patchValue({frequency: this.frequency.value});
    const frequencyViewValue = service.frequencies.find(item => item.value == this.frequency.value)
    await this.selectFrequency({value: this.frequency.value, viewValue: frequencyViewValue.viewValue});
    this.updateValuesValidity();
  }

  async isPrivatePro(proId) {
    const foundPro = await this.pros.getProDetail(proId);
    return foundPro?.is_private;
  }

  @Loading("", true)
  async selectFrequency(frequency) {
    this.service = this.services.find(service => service.key == this.form.value.service);
    this.frequencyName = frequency.viewValue;
    this.isLoadingTimes = true;
    this.requestableDays = this.client.requestableDates();
    if (this.providedStartDate) {
      const formatedStartDate = LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy').toFormat('yyyy-MM-dd');
      const index = this.requestableDays.findIndex((day) => day.date === formatedStartDate);
      this.markCheckbox(true, index);
    }
    this.didChooseFrequency = true;
    this.showMoreServices = false;
    this.isLoadingTimes = false;
  }

  parseRangesForms() {
    return this.timeRangeForms.map((fgArray, index) => {
      const times = fgArray.map(formGroup => {
        return formGroup.value;
      });
      if (times?.length) {
        return {
          date: this.requestableDays[index].date,
          times
        };
      }
    }).filter(elem => elem);
  }

  validForms() {
    return this.timeRangeForms.every((fgArray) => {
      return fgArray.every(formGroup => {
        return formGroup.valid;
      });
    });
  }

  async submit() {
    if (!this.validForms()) {
      return;
    }

    const requestTimeBlocks = this.parseRangesForms();
    if (requestTimeBlocks.length === 0) {
      return (this.notSelectedTimesError = true);
    }
    try {
      const isMobileResolution = window.innerWidth <= 870;
      const params = {
        isJobRequest: false,
        isProRequest: true,
        availabilityRequestId: this.availabilityRequestId,
        proName: this.pro.first_name,
        addressId: this.address.id,
        address: this.address,
        selectedServiceData: this.service,
        selectedPrivatePro: this.pro.private,
        payload: {
          address_id: this.address.id,
          homekeeper_id: !isNaN(this.pro.id)
            ? this.pro.id
            : this.pro.id.replace('homekeeper:', ''),
          service_type_key: this.form.value.service,
          frequency: this.form.value.frequency,
          request_time_blocks: requestTimeBlocks,
        },
        viewData: {
          billingType: this.service.billingType,
          duration: this.service.duration,
          frequency: this.frequencyName,
          image: this.service.image,
          price: this.service.price,
          service: this.service.title,
        },
      };
      this.dialogParams = { ...this.dialogParams, ...params };
      const route = await this.getTargetPage();
      const url = route;
      const component = route === 'confirm-booking-payment' ? ConfirmBookingPaymentPage : ConfirmBookingPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async getTargetPage(): Promise<string> {
    const isPrivatePro = this.pro.private;
    const hasValidCard = await this.card.hasValidCard();
    const shouldRequestCC = !(isPrivatePro || hasValidCard);
    return shouldRequestCC ? 'confirm-booking-payment' : 'confirm-booking';
  }

  showService(service) {
    const isTheOneSelected = this.serviceValue === service.key;
    return !this.didChooseService
            || this.showMoreServices
            || isTheOneSelected;
  }

  showServices() {
    this.showMoreServices = true;
  }

  handleServiceClick(service) {
    if (this.serviceValue === service.key) {
      return;
    }
    this.serviceValue = service.key;
    this.form.patchValue({ service: service.value});
    this.didChooseService = true;
  }

  goToMyPros() {
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const url = isRentalClient && this.windowService.isDesktopRes ? 'my-pros' : 'job-request-workflows';
    this.navCtrl.navigateForward(url);
  }

  getFrequencyItems(frequencies) {
    const hasMultipleDayFrequencies = frequencies.find(frequency => frequency.value == 'twice_a_week');
    if (hasMultipleDayFrequencies) {
      frequencies.splice(frequencies.length - 2);
    }
    return frequencies;
  }

  isTimeChecked(day, time) {
    let isChecked = false;
    this.selectedTimes.map((item) => {
      if (item.date == day.date && item.time == time) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  selectService() {
    this.service = this.services.find(service => service.key == this.form.value.service);
    this.selectedTimes = [];
    if (!this.addingOneTimeJob) {
      this.didChooseFrequency = false;
      this.form.patchValue({frequency: null});
    }
    this.weekDayCheckBox = Array(28).fill(null).map(() => this.fb.group({
      checked: false
    }));
    this.timeRangeForms = Array(28).fill(null).map(() => []);
    this.loadTimeOpts(this.pro);
  }

  getMobileTimeTitle() {
    const length = this.selectedTimes?.length;
    if (length > 0) {
      const text = length == 1 ? ' Day Requested' : ' Days Requested';
      return this.selectedTimes.length + text;
    } else {
      return 'Select Day(s)';
    }
  }

  serviceHasPrice() {
    return this.service?.price !== 0 && this.service?.price !== '' && this.service?.price !== null;
  }

  markCheckbox(value: boolean, dayIndex: number) {
    if (value) {
      this.addNewTimeRange(dayIndex);
    } else {
      this.timeRangeForms[dayIndex] = [];
    }
    this.updateValuesValidity();
  }

  addNewTimeRange(weekDay: number, index: number = 0) {
    if (!this.timeRangeForms[weekDay]) {
      this.timeRangeForms[weekDay] = [];
    }
    const weekDayForms = this.timeRangeForms[weekDay];
    if (weekDay > 0) {
      this.timeRanges.addNewTimeRange(index, weekDayForms, this.service.duration);
    } else {
      this.timeRanges.addNewTimeRange(index, weekDayForms, this.service.duration, this.todayStartOpts[0].value);
    }
    this.updateValuesValidity();
  }

  removeTimeRange(dayIndex: number, timeRangeIndex: number) {
    this.timeRangeForms[dayIndex].splice(timeRangeIndex, 1);
    this.updateValuesValidity();
  }

  updateValuesValidity() {
    this.selectedTimes = this.parseRangesForms();
    this.timeRangeForms.forEach((dayForms, index) => {
      this.weekDayCheckBox[index].patchValue({checked: dayForms.length > 0});
    })
  }

  endTimes(startTime: string) {
    const serviceData = this.services.find(service => service.key == this.form.value.service);

    if (!startTime || !serviceData) return [];
    const lastTime = this.timeOpts[this.timeOpts.length - 1];
    if (this.pro.private) {
      return this.timeOpts.filter(opt => opt.value > startTime);
    }
    return this.timeOpts.filter(opt => {
      const serviceEndDiff = LuxonDateTime.fromFormat(lastTime.value, 'HH:mm')
      .diff(LuxonDateTime.fromFormat(opt.value, 'HH:mm')
      .plus(Duration.fromObject({minutes: serviceData.duration})));

    const serviceEnd = LuxonDateTime.fromFormat(startTime, 'HH:mm')
      .plus(Duration.fromObject({minutes: serviceData.duration}))
      .toFormat('HH:mm');
      return opt.value >= serviceEnd &&
             serviceEndDiff.minutes >= 0;
    });
  }

  startTimeChange(formGrp: UntypedFormGroup) {
    const start = formGrp.value.start_time;
    const nextEnd = LuxonDateTime.fromFormat(start, 'HH:mm')
      .plus(Duration.fromObject({minutes: this.service.duration}))
      .toFormat('HH:mm');


    formGrp.patchValue({
      end_time: nextEnd
    })
  }

  async changeServiceRates() {
    try {
      const payload = await this.buildRateChangePayload();
      await this.addresses.changePrices(this.address.id, payload);
      const successMessage = 'Rates updated!';
      this.util.showSuccess(successMessage);
      const proId = !isNaN(this.pro.id) ? this.pro.id : this.pro.id.replace("homekeeper:", "");
      this.services = await this.bookJobService.fetchRequiredTeamServices(proId, true);
      await this.addPriceDataToServices(proId)
      this.selectService();
      if (this.dialogParams) {
        this.dialogParams.services = this.services;
        await this.storage.save('dialog-params', this.dialogParams);
      }
      await this.storage.save('services', this.services);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async buildRateChangePayload() {
    let noTeamAddressServices = await this.addresses.getPriceTable(this.address.id);
    noTeamAddressServices = noTeamAddressServices.filter(service => service.billing_type !== 'price_later');
    let payload = {
      new_prices: []
    };
    const selectedNoTeamAddressService = noTeamAddressServices.find((service) => service.name == this.service.title);
    noTeamAddressServices.map((service) => {
      const isTheServiceBeingChanged = service.id == selectedNoTeamAddressService.id;
      if (isTheServiceBeingChanged) {
        payload.new_prices.push({
          no_team_address_service_id: selectedNoTeamAddressService.id,
          price: this.service.prices.lowestPossibleBasePrice
        });
      } else {
        payload.new_prices.push({
          no_team_address_service_id: service.id,
          price: service.prices.base_price
        });
      }
    });
    return payload;
  }

}

<tidy-loading-bar class="js-tidy-loading desktop"></tidy-loading-bar>

<div class="container-top-nav">
    <div class="first-item">
    <img
        decoding="async"
        class="bowtie"
        *ngIf="!canGoBack && !showCloseItem() && !noBow"
        [src]="logo"
    />
    <tidy-icon *ngIf="canGoBack" class="back-button desktop" [size]="27" (click)="goBack()" name="arrow-back-black"></tidy-icon>
    <tidy-icon *ngIf="canGoBack" class="back-button mobile" [size]="27" (click)="goBack()" name="arrow-back-white"></tidy-icon>

    <tidy-icon class="close-icon desktop" *ngIf="showCloseItem() && !canGoBack" (click)="executeAction(closeItemAction)" [size]="25" [name]="closeIconDesktop"></tidy-icon>
    <tidy-icon class="close-icon mobile" *ngIf="showCloseItem() && !canGoBack" (click)="executeAction(closeItemAction)" [size]="25" [name]="closeIconMobile"></tidy-icon>
    </div>
    <tidy-text
    *ngIf="title"
    class="title-text"
    [title]="title"
    ></tidy-text>

    <ng-container *ngIf="!title && countdownTarget">
    <div>
        <tidy-text [title]="'Time Left: '"></tidy-text>
        <countdown [isTopNav]="true" [target]="countdownTarget"></countdown>
    </div>
    </ng-container>

    <div class="last-item">
        <span *ngIf="linkIcon && showLinkIcon" class="link-icon">
            <ng-container *ngIf="linkIcon !== 'edit'">
                <tidy-icon class="link-icon-element mobile" (click)="executeAction(linkAction)" [size]="25" [name]="linkIcon + '-white'"></tidy-icon>
                <tidy-icon class="link-icon-element desktop" (click)="executeAction(linkAction)" [size]="25" [name]="linkIcon + '-black'"></tidy-icon>
            </ng-container>
            <button
                *ngIf="linkIcon == 'edit'"
                expand="block"
                class="mat-raised-button"
                mat-button
                tappable
                (click)="executeAction(linkAction)"
                type="submit" block
                >
                <tidy-text [smallBody]="'Edit'" class="white"></tidy-text>
            </button>
            <span class="bubble" *ngIf="linkIcon === 'star'">
            5
            </span>
        </span>
    </div>
</div>

<div class="tidy-top-nav-content">
    <ng-content></ng-content>
</div>

<tidy-loading-bar class="js-tidy-loading mobile"></tidy-loading-bar>

<tidy-wrapper style="background:#FFFFFF" class="full-btm-padding">
  <ng-container *ngIf="loaded">

    <tidy-card>
      <tidy-row class="extra-bottom-padding">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="6">
                <tidy-row>
                  <tidy-text
                    [title]="'Invoice From'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="invoice?.team?.owner?.name | titlecase">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="invoice?.team?.owner?.email">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="invoice?.team?.owner?.phone | telephone">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-row>

      <tidy-row class="extra-bottom-padding">
        <!--TODO divider to have [color] input and add dynamic color-->
        <tidy-divider
          class="full-card-width thick"
          style="background: #275C7C">
        </tidy-divider>
      </tidy-row>

      <div style="margin-top: -15px; background: #F4F4F4; margin-left: -15px; margin-right: -15px; padding: 15px; padding-top:10px ">
        <tidy-row [alignLastItemRight]="true">
          <div>
            <tidy-text
              [body]="'To: ' +
              (invoice?.customer?.name | titlecase)">
            </tidy-text>
          </div>
          <div style="align-self: start">
            <tidy-text
              [body]="'Sent ' +
              (invoice?.created_at | customdate:'M/D/YY')">
            </tidy-text>
          </div>
        </tidy-row>
      </div>

      <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
        <tidy-text
          [body]="'Service'">
        </tidy-text>
        <tidy-text
          [body]="'Owed'">
        </tidy-text>
      </tidy-row>

      <tidy-row class="extra-bottom-padding">
        <tidy-divider>
        </tidy-divider>
      </tidy-row>

      <ng-container *ngFor="let service of invoice?.items; let last = last">
        <tidy-row [alignLastItemRight]="true">
          <div style="width:85%">
            <tidy-text
              [header]="service?.service_details?.name | titlecase">
            </tidy-text>
          </div>
          <div>
            <tidy-text
              [body]="service?.amount | tcurrency">
            </tidy-text>
          </div>
        </tidy-row>

        <tidy-row>
          <tidy-text
            [body]="(service?.service_details?.date | customdate:'M/D') +
            ' at ' +
            service?.service_details?.address?.address +
            ', ' +
            (service?.service_details?.address?.unit ? ('Unit ' + service?.service_details?.address?.unit + ', ') : '') +
            service?.service_details?.address?.zip">
          </tidy-text>
        </tidy-row>

        <tidy-row class="extra-top-padding" [ngClass] = "last ? '' : 'extra-bottom-padding'">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
      </ng-container>

      <tidy-row [alignLastItemRight]="true" class="extra-top-padding" *ngIf="creditWithPro > 0">
        <tidy-text
          [header]="'Credit with Pro'">
        </tidy-text>
        <tidy-text
          [body]="creditWithPro | tcurrency">
        </tidy-text>
      </tidy-row>

      <tidy-row [alignLastItemRight]="true" class="extra-top-padding" *ngIf="creditWithTIDY > 0">
        <tidy-text
          [header]="'TIDY Credit'">
        </tidy-text>
        <tidy-text
          [body]="creditWithTIDY | tcurrency">
        </tidy-text>
      </tidy-row>

      <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
        <tidy-text
          [header]="'Total'">
        </tidy-text>
        <tidy-text
          [body]="'<b>' + (totalOwed | tcurrency) + '</b>'">
        </tidy-text>
      </tidy-row>

      <tidy-row [align]="'center'" class="extra-top-padding">
        <tidy-text
          [body]="'We thank you for your business!'">
        </tidy-text>
      </tidy-row>

      <tidy-row class="no-padding" [align]="'center'">
        <tidy-text
          [body]="invoice?.team?.owner?.name"
          class="signature">
        </tidy-text>
      </tidy-row>

      <tidy-divider
        style="margin-top:-15px">
      </tidy-divider>
    </tidy-card>

  </ng-container>
</tidy-wrapper>

<tidy-card>
  <form [formGroup]="form" novalidate action="#">
    <tidy-row class="field-top-padding">
      <tidy-input
        #searchInput
        [icon]="'assets/svg/search-outline.svg'"
        [activeIcon]="'assets/svg/search-tidy-green.svg'"
        [noBorder]="true"
        type="search"
        formControlName="search"
        [label]="'Search team'"
        [form]="form">
      </tidy-input>
    </tidy-row>
  </form>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <div class="vertical-align-center">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
        <button mat-icon-button disabled></button>
        <tidy-link item-right tappable (click)="goToAddSubteam(node)">{{node.name}}</tidy-link>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <tidy-link item-right tappable (click)="goToTeamPage(node)">{{node.name}}</tidy-link>
        <tidy-image
          class="body-size"
          style="margin-left: 10px; filter: invert(42%) sepia(10%) saturate(7%) hue-rotate(315deg) brightness(93%) contrast(93%)"
          [src]="'assets/svg/home-outline.svg'">
        </tidy-image>
        <tidy-text
          [helper]="node.addresses"
          style="margin-right:5px"
          [translate]="false">
        </tidy-text>
        <tidy-image
          class="body-size"
          style="filter: invert(42%) sepia(10%) saturate(7%) hue-rotate(315deg) brightness(93%) contrast(93%)"
          [src]="'assets/svg/person-circle-outline.svg'">
        </tidy-image>
        <tidy-text
          [helper]="node.members"
          [translate]="false">
        </tidy-text>
      </mat-tree-node>
    </div>
  </mat-tree>
</tidy-card>

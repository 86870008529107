import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { ClientHub } from 'src/providers/client-hub/client-hub';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Invoices } from 'src/providers/invoices/invoices';

import { Loading } from 'src/shared/components/loading/loading';

@Component({
  templateUrl: 'thumbnail-invoice.html'
})

export class ThumbnailInvoicePage implements OnInit{

  creditWithPro: number;
  creditWithTIDY: number;
  totalOwed: number;
  loaded: boolean;
  invoice: any;

  constructor(
    private clientHub: ClientHub,
    private invoices: Invoices,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      const invoiceUuId = this.route.snapshot.paramMap.get('invoiceUuId');
      this.invoice = await this.invoices.getInvoice(invoiceUuId);
      this.creditWithPro = this.invoice.amount - this.invoice.amount_due;
      this.creditWithTIDY = this.invoice?.tidy_credit_amount;
      this.totalOwed = Math.max(0, this.invoice.amount - this.creditWithPro - this.creditWithTIDY);
      this.loaded = true;
    } catch(err) {
      this.clientHub.goToErrorPage();
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger';
import { Observable } from 'rxjs/internal/Observable';
declare const google: any;

@Injectable()
export class GoogleGeocode {

  private mapsBaseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  private mapsBaseJsonUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
  private url = environment.google_geocode_api;
  private tidyKey = environment.google_geocode_key;
  googleGeocoder;

  constructor(
    private http: HttpClient,
    private logger: Logger
  ) {
    this.googleGeocoder = new google.maps.Geocoder();
  }

  getGeocode(placeId) {
    return this.http.get(`${this.url}${placeId}${this.tidyKey}`)
      .toPromise()
  }

  async getLatLong(placeId) {
    try {
      return await new Promise((resolve, reject) => {
        this.googleGeocoder.geocode({placeId}, (geocoderResult) => {
          if (geocoderResult == null) {
            reject({
              latitude: '',
              longitude: ''
            })
          } else {
            resolve({
              latitude: geocoderResult[0]?.geometry?.location?.lat(),
              longitude: geocoderResult[0]?.geometry?.location?.lng()
            });
          }
        });
      });
    } catch (err) {
      this.logger.error(err, 'error getting latitude and longitude');

      return { latitude: '', longitude: '' };
    }
  }

  getAddressImage(address): Observable<Blob> {
    return this.http.get(`${this.mapsBaseUrl}?center=${address.address1}+,${address.add_state}+${address.zip}&zoom=20&size=640x640&scale=2&maptype=satellite${this.tidyKey}`, { responseType: 'blob' });
  }

  getLocationByCoordinates(latitude: string, longitude: string): Promise<any> {
    return this.http.get(`${this.mapsBaseJsonUrl}?latlng=${latitude},${longitude}${this.tidyKey}`)
    .toPromise()
  }
}

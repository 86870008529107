import { UntypedFormGroup, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import moment from 'moment';

class ValidationUtils {
  validateEqual(field, confirmField) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const password = group.controls[field];
      const confirmPassword = group.controls[confirmField];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }

  validateEmail(c: UntypedFormControl) {
    const EMAIL_REGEXP = /^$|(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

    return EMAIL_REGEXP.test(c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  buildZipcodeValidation() {
    return Validators.compose([
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern('[0-9]*')
    ]);
  }

  validateUrl(c: UntypedFormControl): ValidationErrors {
    try {
      const validUrl = new URL(c.value);
      return null;
    } catch {
      return {valid: false};
    }
  }

  timeRangesValidation(formsArray: Array<UntypedFormGroup>, intervalValidation = 30) {
    return (formGroup: UntypedFormGroup) => {
      const startControl = formGroup.controls.start_time;
      const endControl = formGroup.controls.end_time;

      const indexOfFormGp = formsArray.indexOf(formGroup);
      let startErrors = null;
      let endErrors = null;
      if (indexOfFormGp > 0) {
        const valid = startControl.value > formsArray[indexOfFormGp - 1].controls.end_time.value;
        startErrors = valid ? null : {invalidStartTime: true};
      }

      if (indexOfFormGp < formsArray.length - 1 && indexOfFormGp !== -1) {
        const valid = endControl.value < formsArray[indexOfFormGp + 1].controls.start_time.value;
        endErrors = valid ? null : {invalidEndTime: true};
      }
      if (startControl.value >= endControl.value) {
        const error = {invalidTimeRange: true};
        startErrors = {...startErrors, ...error};
        endErrors = {...endErrors, ...error};
      }
      if (startControl.value && endControl.value) {
        const intervalDiff = moment(endControl.value, 'HH:mm').diff(moment(startControl.value, 'HH:mm'), 'minutes');

        if (intervalDiff < intervalValidation) {
          const error = {intervalError: true};
          endErrors = {...endErrors, ...error};
        }
      }
      startControl.setErrors(startErrors);
      endControl.setErrors(endErrors);
    };
  }
}

export default new ValidationUtils();

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessorBase } from '../default-class/value-accessor';

import { SelectModel } from './select.model';

@Component({
  selector: 'tidy-select',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends ValueAccessorBase<any> implements OnInit, OnChanges {

  @Input() disableElement: boolean;
  @Input() items: SelectModel[];
  @Input() listedItems: SelectModel[];
  @Input() label: string;
  @Input() icon: string;
  @Input() form: FormControl = new FormControl();
  @Input() submitted: boolean;
  @Input() smallText: boolean;
  @Input() hideSearch: boolean;
  @Input() searchPlaceholder: string = '';
  @Input() public errorMessage: string;
  @Input() multiple = false;
  @Output() optionChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() selectedItem: any;
  showSearch: boolean;
  touchUpdate = false;
  searchControl: FormControl = new FormControl('');

  ngOnInit() {
    this.listedItems = this.items || this.listedItems;
    if (this.hideSearch) {
      this.showSearch = false;
    } else {
      this.showSearch = this.listedItems.length > 5;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.listedItems = this.items || this.listedItems;
      this.selectedItem = this.listedItems.find((item) => this.value == item.value);
    }
    if (changes.selectedItem) {
      this.selectedItem = changes.selectedItem.currentValue;
    }
    if (changes.form) {
      this.form.patchValue(changes.form.currentValue.value);
    }
  }

  onChange(event: any) {
    this.touchUpdate = true;
    if (this.multiple) {
      this.value = event.value;
      this.form.patchValue(event.value);
      this.optionChange.emit(event.value);
    } else {
      this.optionChange.emit(event.value);
      this.listedItems = this.items || this.listedItems;
      this.form.patchValue(event.value);
      this.selectedItem = this.listedItems.find((item) => event.value == item.value);
    }
  }

  searchItems() {
    try {
      const value = this.searchControl.value;
      this.listedItems = this.items.filter((item) => {
        return item.viewValue && item.viewValue.toLowerCase().includes(value.toLowerCase());
      });
    } catch (error) {
      console.error(error);
    }
  }
}

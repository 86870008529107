import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { CleaningTypes } from '../cleaning-types/cleaning-types';

@Injectable()
export class PlanData {
  plansAirbnb: any = [
    {'type': 'tidy', data: {}},
    {'type': 'plus', data: {}},
    {'type': 'tidy_xl', data: {}},
    {'type': 'turnover_tidy', data: {}},
    {'type': 'turnover_plus', data: {}},
    {'type': 'mighty', data: {}},
  ];

  plansRegular: any = [
    {'type': 'tidy', data: {}},
    {'type': 'plus', data: {}},
    {'type': 'tidy_xl', data: {}},
    {'type': 'mighty', data: {}}
  ];

  constructor(
    private httpClient: HttpClientCustom,
    private cleaningTypes: CleaningTypes,
  ) {}

  getNextCleaning(): Promise<any> {
    const planId = localStorage.getItem('plan_id');
    const url = `api/v1/plans/${planId}/next-cleaning`;

    return this.httpClient.get(url).then( data => {
      if (data) {
        localStorage.setItem('job_id', data.id);
        localStorage.setItem('plan_type', data.cleaning_service_type);
      }
      return data;
    });
  }

  getPlanType(): any {
    return localStorage.getItem('plan_type');
  }

  async updatePlansPrice() {
    const response = await this.cleaningTypes.getCleaningTypes();
    const data = (localStorage.getItem('airbnb') === 'true') ? this.plansAirbnb : this.plansRegular;
    return data.map((item, key) => {
      return response.find((cleaningType) => cleaningType.type === item.type);
    })
  }

  cancelPlan(plan_id, costumer_feeedback): Promise<any> {
    const url = `api/v1/me/plans/${plan_id}/cancel`;
    const data = { feedback: costumer_feeedback };

    return this.httpClient.put(url, data).then( () => {
      localStorage.setItem('no_plan', 'true');
      localStorage.removeItem('plan');
      localStorage.removeItem('plan_id');
    });
  }

  getStripeInfo (): Promise<{status: string}> {
    const url = `api/v1/me/stripe-info`;

    return this.httpClient.get(url);
  }

  getPlanTitle(typeOfPlan) {
    const scheduleList = {
      'reschedule-cleaning': 'Reschedule Cleaning',
      'add-cleaning-airbnb': 'Schedule Cleaning',
      'add-cleaning': 'Add Cleaning',
      'reschedule-plan': 'Reschedule Plan',
      'schedule-plan': 'Schedule Cleaning',
      'schedule-airbnb': 'Schedule Cleaning',
      'same-day-request': 'Submit Request'
    }
    const selectedItem = scheduleList[typeOfPlan];
    return selectedItem;
  }

  getScheduledText(typeOfPlan) {
    let text;
    if (typeOfPlan === 'reschedule-cleaning') {
      text = 'Cleaning Rescheduled';
    } else if (typeOfPlan === 'reschedule-plan') {
      text = 'Plan Rescheduled';
    } else if (typeOfPlan === 'add-cleaning') {
      text = 'Cleaning Added';
    } else if (typeOfPlan === 'schedule-plan') {
      text = 'Plan Scheduled';
    } else if (typeOfPlan === 'same-day-request') {
      text = 'Request Submitted!';
    }
    return text;
  }

  setCleaningData(plan, hkRating, ratingChosen) {
    return {
      'cleaning': {
        'cleaning_type': plan.type,
        'date': plan.time.date,
        'start_time': plan.time.time,
        'homekeeper_ids': plan.time.homekeepers,
        'hk_rating' : hkRating,
        'rating_chosen' : ratingChosen
      },
      'reschedule_times': plan.rescheduleTimes || []
    };
  }

  getNameKeyFormated(obj) {
    const objKeys = Object.keys(obj);
    const objFormated = objKeys.map(plan => {
      return plan;
    })
    return objFormated;
  }

  getAmountFrequency(frequency) {
    let frequencyNumber = 1;
    if (frequency === 'twice_a_week') {
      frequencyNumber = 2;
    } else if (frequency === 'thrice_a_week') {
      frequencyNumber = 3;
    }
    return frequencyNumber;
  }

  async mountPlanData(cleaningData) {
    const regionId = localStorage.getItem('region_id');
    const frequency = cleaningData.plan_frequency;
    const services = await this.cleaningTypes.getCleaningTypes();
    const plan = this.findPlan(services, cleaningData.job.cleaning_service_type);
    const priceObject = this.mountPrice(plan);
    const planData: any = {
      duration: plan.duration,
      region_id: regionId,
      timeSelected: '',
      price: priceObject.price,
      priceObject: priceObject,
      hkNumber: 1,
      oneMoreDays: this.isMoreDays(cleaningData),
      oneMoreTimeDays: this.isMoreDays(cleaningData),
      frequency: frequency,
      name: cleaningData.labelService,
      type: cleaningData.job.cleaning_service_type,
      amountFrequency: this.getAmountFrequency(frequency)
    }
    planData.plan = {...planData};
    return planData;
  }

  findPlan(services, frequency) {
    return services.find(item => item.type === frequency);
  }

  isMoreDays(cleaningData) {
    return (cleaningData.plan_frequency === 'twice_a_week' || cleaningData.plan_frequency === 'thrice_a_week');
  }

  mountPrice(cleaning) {
    return {price: cleaning.price / 100, otcPrice: cleaning.otc_price / 100, sdcPrice: cleaning.sdc_price / 100}
  }

  getPrice(data) {
    const {isOtc, typeRequest, priceObject} = data;
    const isSameDayRequest = (typeRequest === 'same-day-request');
    let newPrice: number = priceObject.price;
    if (isOtc && !isSameDayRequest) {
      newPrice = priceObject.otcPrice;
    } else if (isSameDayRequest) {
      newPrice = priceObject.sdcPrice;
    }
    return Math.round(newPrice);
  }

  getDurationText(plan) {
    const duration = plan.duration / 60;
    const cleaningDuration = (duration > 1) ? 'hours' : 'hour';

    const airbnbHk = (plan.hkNumber === 1) ? 'Homekeeper' : 'Homekeepers';
    const airbnbDuration = `${plan.hkNumber} ${airbnbHk} for ${duration} ${cleaningDuration}`;

    const residentialHk = (plan.type === 'mighty') ? `2 Homekeepers` : `1 Homekeeper`;
    const residentialDuration = `${duration} ${cleaningDuration}`;
    const residentialDurationHomekeepers = `${residentialHk} for ${duration} ${cleaningDuration}`;

    return {airbnbDuration, residentialDuration, residentialDurationHomekeepers };
  }

  getHkRatingText(rating) {
    const array = [
      {
        'rating' : 0.1,
        'text': 'same_day'
      },
      {
        'rating' : 0.5,
        'text': 'more_times_skipped'
      },
      {
        'rating' : 0,
        'text': '4_6_rating'
      },
      {
        'rating' : 1,
        'text': '4_2_rating'
      },
      {
        'rating' : 2,
        'text': '4_0_rating'
      },
      {
        'rating' : 3,
        'text': 'any_rating'
      },
      {
        'rating' : 4,
        'text': 'specific_hk'
      }
    ];
    const arrayRating = (rating < 4) ? rating : 4;
    const arrayText = array.find((item) => arrayRating === item.rating);
    return arrayText.text;
  }
}

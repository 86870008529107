const googleClosureUuid = () => {
  const x = 2147483648;
  return Math.floor(Math.random() * x).toString(36)
    + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
};

const generateTimeStamp = () => {
  return Date.now();
};


export function generateUuid() {
  return `${googleClosureUuid()}-${generateTimeStamp()}-${googleClosureUuid()}`;
}

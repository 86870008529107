import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { debounceTime } from 'rxjs/operators';
import { AddTeamPage } from 'src/pages/team/add-team/add-team';
import { TeamPage } from 'src/pages/team/team/team';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';


@Component({
  selector: 'tidy-team-tree',
  templateUrl: 'tree.component.html',
  styleUrls: ['tree.component.scss'],
})

export class TeamTreeComponent implements OnInit{

  @Input() teamsList: any;
  testupdate: any[];
  form: UntypedFormGroup;
  leanTree: any;
  isRightSideContent = true;

  constructor(
    private fb: UntypedFormBuilder,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    // Starts the Children as an empty array
    this.teamsList.map((team, index) => {
      this.teamsList[index].children = []
    })
    // Builds the children nodes recursively
    this.buildRecursiveTree(this.teamsList.length-1, this.teamsList.length-1)
    // flatMap removes the children teams from the original array
    this.leanTree = this.teamsList.flatMap(team => {
      if(!team.parent_team_id){
        return team;
      } else {
        return [];
      }
    });
    this.leanTree = this.leanTree.map((team) =>{
      return this.populateEmptyNodes(team);
    });
    this.dataSource.data = this.leanTree;
  }

  populateEmptyNodes(team){
    if(team.children.length == 0) {
      let childlessNode = {
        name: 'Add Sub-Team',
        addresses: [],
        team_members: [],
        id: team.id
      }
      team.children.push(childlessNode);
      return team;
    } else {
      team.children = team.children.map(team => {
        return this.populateEmptyNodes(team);
      })
    }
    return team;
  }

  buildRecursiveTree(leftTeamIndex, rightTeamIndex){
    if(leftTeamIndex < 0){
      return;
    }
    else if(rightTeamIndex < 0){
      this.buildRecursiveTree(leftTeamIndex-1, this.teamsList.length-1);
      return;
    }
    else if(this.teamsList[leftTeamIndex].id === this.teamsList[rightTeamIndex].parent_team_id){
      this.teamsList[leftTeamIndex].children.push(this.teamsList[rightTeamIndex]);
    }
    this.buildRecursiveTree(leftTeamIndex, rightTeamIndex-1);
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.dataSource.data = this.leanTree;
    }
    const term = search.toLowerCase();
    this.dataSource.data = this.teamsList.filter((option) => {
      if (option.name.toLowerCase().indexOf(term) > -1 || option.name?.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
  }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      addresses: node.addresses.length,
      members: node.team_members.length,
      team: node
    };
  };

  goToTeamPage(node) {
    const params = {
      team: node.team
    }
    this.rightSidePanelService.navigateTo(`team/${node.team.id}`, params, TeamPage);
  }

  goToAddSubteam(node){
    const params = {
      parentTeamId: node.id
    }
    this.rightSidePanelService.navigateTo('add-team', params, AddTeamPage);
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any) => node.expandable;
}

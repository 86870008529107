<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Billing History'"
    [class]="extraClass"
    [canGoBack]="!cantGoBack"
    [customBack]="backPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
  <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

  <tidy-error-message
    *ngIf="errorMessage"
    [text]="errorMessage">
  </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="hasPrivatePro">
        <tidy-filter
          [initValue]="proFilterValue"
          [items]="proFilterItems"
          [fitContent]="true"
          [newValue]="proFilterValue"
          (optionChange)="changePro($event)">
        </tidy-filter>
      </tidy-row>

      <ng-container *ngIf="billingLoaded">

        <tidy-row *ngIf="showBilling && !hasProSelected" [align]="windowService.isDesktopRes ? 'left' : 'center'">
          <tidy-text
            [body]="'Note: Your payment settings may require credit to reserve appointments, check the Account section for details. '">
          </tidy-text>
          <tidy-text
            *ngIf="billingData.total_refundable === 0"
            [body]="'You have no refundable credit.'">
          </tidy-text>
          <ng-container *ngIf="billingData.total_refundable !== 0">
            <tidy-text
              [body]="'<b>' + (billingData?.total_refundable | tcurrency) + '</b>'">
            </tidy-text>
            <tidy-text
              [body]="' of your credit is refundable if you do not use it.'">
            </tidy-text>
          </ng-container>
        </tidy-row>

        <tidy-row *ngIf="hasProSelected" [align]="'center'">
          <tidy-text
            [body]="'This is '">
          </tidy-text>
          <tidy-text
            [body]="selectedProName"
            [translate]="false">
          </tidy-text>
          <tidy-text
            [body]="'\'s billing history with you. It only includes billing items that relate to them.'">
          </tidy-text>
        </tidy-row>

        <tidy-card *ngIf="userRole !== 'member'">
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [header]="'Current Credit: '">
            </tidy-text>
            <ng-container *ngIf="!hasProSelected">
              <tidy-text
                [header]="billingData?.balance | tcurrency">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="hasProSelected">
              <tidy-text
                [header]="billingData?.credit_balance | tcurrency">
              </tidy-text>
            </ng-container>
          </tidy-row>
          <tidy-row *ngFor="let detail of billingData?.balance_details" class="vertical-align-middle">
            <tidy-image
              *ngIf="detail?.credit_type === 'credit'"
              [src]="'assets/img/coupon.svg'"
              class="header-size">
            </tidy-image>
            <tidy-image
              *ngIf="detail?.credit_type === null || detail?.credit_type === 'charge'"
              [src]="'assets/img/credit-card.svg'"
              class="header-size">
            </tidy-image>
            <ng-container *ngIf="!detail?.transferred_from">
              <tidy-text
                [body]="detail?.label">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="detail?.transferred_from">
              <tidy-text
                [body]="(detail?.amount_left | tcurrency) +
                (' left from ' | translate) +
                (detail?.amount | tcurrency) +
                (' credit added by ' | translate)">
              </tidy-text>
              <tidy-text
                [body]="detail?.transferred_from?.account_name"
                [translate]="false">
              </tidy-text>
            </ng-container>
          </tidy-row>
          <ng-container *ngIf="totalPendingCharges !== 0">
            <tidy-row class="extra-bottom-padding" [class.extra-top-padding]="billingData?.balance_details?.length > 0">
              <tidy-divider></tidy-divider>
            </tidy-row>
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-text
                [header]="('Pending Credit: ' | translate) + (totalPendingCharges | tcurrency)">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'The credit you loaded will be added to your account once the charges are completed.'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-row *ngIf="showBilling">
          <tidy-filter
            [initValue]="0"
            [items]="addressFilter"
            [fitContent]="true"
            (optionChange)="changeAddress($event)">
          </tidy-filter>
        </tidy-row>

        <div style="display: flex; justify-content: center; width: 100%;" *ngIf="!chargesLoaded">
          <tidy-loader>
          </tidy-loader>
        </div>

        <ng-container *ngIf="chargesLoaded">

          <tidy-card *ngFor="let item of billingHistory; let index = index">
            <ng-container *ngIf="item?.isPendingClientCharge">
              <ion-chip [class]="item?.class">
                <ion-icon [name]="item?.icon"></ion-icon>
                <ion-label>{{ item?.status | titlecase }}</ion-label>
              </ion-chip>
              <tidy-row [alignLastItemRight]="true">
                <tidy-text
                  style="margin-right:5px"
                  [header]="(item?.amount | tcurrency) + ' ' + (item?.payment_method?.type == 'card' ? (' charge' | translate) : (' debit' | translate))">
                </tidy-text>
                <tidy-image
                  style="margin-left: 5px; cursor: pointer"
                  (action)="goToBillingItemHelp(item)"
                  [src]="'assets/svg/help-circle-outline.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="item?.formattedDate">
                </tidy-text>
              </tidy-row>
              <tidy-row class="vertical-align-center">
                <ng-container *ngIf="item?.payment_method?.type == 'card'">
                  <tidy-text
                    [body]="('Card ending in ' | translate) + item?.payment_method?.metadata?.last4">
                  </tidy-text>
                  <tidy-text *ngIf="item?.payment_method?.is_primary"
                    [header]="'(primary)'"
                    style="margin-left: 5px">
                  </tidy-text>
                </ng-container>
                <tidy-text
                  *ngIf="item?.payment_method?.type == 'us_bank_account'"
                  [body]="item?.payment_method?.metadata?.last4 ? (('Bank account ending in ' | translate) + item?.payment_method?.metadata?.last4) : ('Bank account' | translate)">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.payment_method?.metadata?.bank_name">
                <tidy-text
                  [body]="item?.payment_method?.metadata?.bank_name + (item?.payment_method?.metadata?.account_type ? ' | ' + item?.payment_method?.metadata?.account_type : '')">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="!item?.isPendingClientCharge">
              <tidy-row [alignLastItemRight]="true" [ngClass]="item?.refund_type ? '' : 'extra-bottom-padding'">
                <tidy-text
                  style="margin-right:5px"
                  [header]="(item?.amount | tcurrency) + ' '">
                </tidy-text>
                <tidy-text
                  *ngIf="item?.credit_type == 'credit'"
                  [body]="'credit'">
                </tidy-text>
                <tidy-text
                  *ngIf="item?.payment_method?.type == 'card'"
                  [body]="('charge on card ' | translate) + item?.payment_method?.metadata?.last4">
                </tidy-text>
                <tidy-text
                  *ngIf="item?.payment_method?.type == 'us_bank_account'"
                  [body]="('debit from account ' | translate) + (item?.payment_method?.metadata?.last4 ? item?.payment_method?.metadata?.last4 : '')">
                </tidy-text>
                <tidy-image
                  *ngIf="!hasProSelected && item?.credit_type !== 'credit'"
                  style="margin-left: 5px; cursor: pointer"
                  (action)="goToBillingItemHelp(item)"
                  [src]="'assets/svg/help-circle-outline.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [body]="(item?.date | customdate: 'M/D/YY')">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding" *ngIf="item?.refund_type">
                <tidy-text
                  [actionBody]="item?.refund_type"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.added_by === 'cleaning' && item?.credit_type === 'credit'" [ngClass]="{'extra-bottom-padding': item?.details?.length > 0}">
                <tidy-text
                  [body]="'Added from job on '">
                </tidy-text>
                <tidy-text
                  [actionBody]="item?.source_cleaning_date | customdate: 'M/D/YY'"
                  (action)="goToCleaningDetails(item?.source_cleaning_id)"
                  class="link">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="item?.added_by === 'concierge' && item?.credit_type === 'credit'" [ngClass]="{'extra-bottom-padding': item?.details?.length > 0}">
                <tidy-text
                  [body]="'Added by '">
                </tidy-text>
                <tidy-text
                  *ngIf="item?.transferred_from"
                  [body]="item?.transferred_from?.account_name"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  *ngIf="!item?.transferred_from"
                  [body]="'Concierge'">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let detail of item?.details; let i = index; let last = last">
                <tidy-row [ngClass]="{'extra-bottom-padding': !last}">
                  <tidy-text
                    *ngIf="detail?.debit_type !== 'refund'"
                    [body]="(detail?.amount_used | tcurrency)">
                  </tidy-text>
                  <tidy-text
                    *ngIf="detail?.debit_type !== 'refund'"
                    [body]="' used'">
                  </tidy-text>
                  <ng-container *ngIf="detail?.debit_type !== 'refund' && detail?.debit_source_billable_type !== 'Infrastructure::Models::Plan::SubscriptionInvoice'">
                    <ng-container *ngIf="!detail?.transferred_to">
                      <tidy-text
                        [body]="' by a '">
                      </tidy-text>
                      <tidy-text
                        [body]="detail?.cleaning_state | formatState">
                      </tidy-text>
                      <tidy-text
                        [body]="' job on '">
                      </tidy-text>
                      <tidy-text
                        [actionBody]="(detail?.cleaning_date | customdate: 'M/D/YY')"
                        (action)="goToCleaningDetails(detail?.cleaning_id)"
                        class="link">
                      </tidy-text>
                    </ng-container>
                    <ng-container *ngIf="detail?.transferred_to">
                      <tidy-text
                        [body]="' by'">
                      </tidy-text>
                      <tidy-text
                        [body]="detail?.transferred_to?.team?.name"
                        (action)="changePro(detail?.transferred_to?.team?.owner_id)"
                        class="link"
                        [translate]="false">
                      </tidy-text>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="detail?.debit_source_billable_type == 'Infrastructure::Models::Plan::SubscriptionInvoice'">
                    <tidy-text
                      [body]="' for '">
                    </tidy-text>
                    <tidy-text
                      [actionBody]="'your subscription'"
                      (action)="goToSubscriptionPage()"
                      class="link">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="detail?.debit_type == 'debit' && (detail?.cleaning_state == 'cancelled' || detail?.cleaning_state == 'cancelled_once') && detail?.booking_id !== null">
                    <tidy-text
                      [body]="' '">
                    </tidy-text>
                    <tidy-text
                      [actionBody]="'Waive Fee'"
                      (action)="waiveFee(detail?.booking_id)"
                      class="link">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="detail?.debit_type === 'refund'">
                    <tidy-text
                      [body]="detail?.amount_used | tcurrency">
                    </tidy-text>
                    <tidy-text
                    [body]="' refunded. '">
                  </tidy-text>
                    <tidy-text
                      [actionBody]="'Where is my refund?'"
                      (action)="learnAboutRefund()"
                      class="link">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-card>

          <tidy-row [align]="'center'">
            <tidy-text
              *ngIf="!hasProSelected"
              [body]="'More history unavailable - Please contact us in the Concierge section for support.'">
            </tidy-text>
            <tidy-text
              *ngIf="hasProSelected"
              [body]="'More history unavailable - Please contact your pro for support.'">
            </tidy-text>
          </tidy-row>

        </ng-container>

      </ng-container>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ImageViewerComponent } from '../image-viewer.component';
import { ImageViewerDirective } from '../directive/image-viewer.directive';

@NgModule({
  declarations: [
    ImageViewerComponent,
    ImageViewerDirective
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    ImageViewerDirective
  ]
})
export class ImageViewerModule {}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamsPage } from '../teams/teams';

@Component({
  templateUrl: 'add-team.html'
})

export class AddTeamPage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  parentTeamItems: any;
  submitted: boolean;
  parentTeamId: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private team: TeamService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      teamName: ['', Validators.required],
      parentTeam: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Team');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    try {
      const parentTeamId = await this.getParam('parentTeamId');
      await this.getTeamItems();
      if(parentTeamId){
        this.form.patchValue({parentTeam: parentTeamId});
      }
    } catch(err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getTeamItems() {
    this.parentTeamItems = [];
    const teams = await this.getParam('teams')  || await this.team.getTeams();
    teams.map((team) => {
      this.parentTeamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
  }

  @Loading('', true)
  async addTeam() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const payload: any = {
      name: this.form.value.teamName
    }
    if (this.form.value.parentTeam) {
      payload['parent_team_id'] = this.form.value.parentTeam;
    }
    try {
      await this.team.createTeam(payload);
      this.rightSidePanelService.navigateTo('teams', {}, TeamsPage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Integrate Device(s)'"
    [canGoBack]="true"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
      <ng-container *ngIf="loaded">

        <tidy-card>
          <tidy-row class="vertical-align-center">
            <tidy-image
              *ngIf="isAssociatedWithAddress"
              [src]="'assets/img/checkmark.svg'"
              class="header-size">
            </tidy-image>
            <tidy-image
              *ngIf="!isAssociatedWithAddress"
              [src]="'assets/img/block.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              *ngIf="!address?.address_name"
              [body]="address?.address1 + ' '"
              [translate]="false">
            </tidy-text>
            <tidy-text
              *ngIf="address?.address_name"
              [body]="address?.address_name"
              [translate]="false">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="address?.address2 !== null && address?.address2 !== '' && !address?.address_name">
            <tidy-text
              [body]="address?.address2">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="address?.city !== null && address?.city !== ''"
              [helper]="address?.city">
            </tidy-text>
            <tidy-text
              *ngIf="address?.zip !== null && address?.zip !== ''"
              [helper]="', ' + address?.zip">>
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'Select which RemoteLock location/device(s) to associate with above TIDY property:'">
            </tidy-text>
          </tidy-row>
          <tidy-row [ngClass]="(didSelectLocation && form.value.location !== 'none') ? 'extra-bottom-padding' : ''">
            <tidy-select
              [icon]="'assets/svg/information-circle-outline.svg'"
              [label]="'Select Location'"
              [items]="locationItems"
              [form]="form.controls.location"
              (optionChange)="selectLocation($event)"
              formControlName="location">
            </tidy-select>
          </tidy-row>
          <ng-container *ngIf="didSelectLocation && form.value.location !== 'none'">
            <tidy-row>
              <tidy-text
                [body]="'Select device(s) at'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="remoteAccessAddressName + ':'">
              </tidy-text>
            </tidy-row>
            <tidy-row *ngFor="let device of deviceOptions">
              <tidy-checkbox
                [items]="[device]"
                [initValue]="device.value"
                (checkedChange)="device.value = !device.value">
              </tidy-checkbox>
            </tidy-row>
            <tidy-row *ngIf="deviceOptions?.length == 0">
              <tidy-text
                [helper]="'No devices added for this address.'">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>

          <tidy-error-message
            *ngIf="errorMessage"
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-button
            [text]="'Save Changes'"
            [action]="saveChanges.bind(this)"
            class="primary">
          </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

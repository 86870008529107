import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import moment from 'moment-timezone';
import { DateTime as LuxonDateTime } from 'luxon';
import { BookJob } from 'src/providers/book-job/book-job';
import { Communication } from 'src/providers/communication/communication';
import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Me } from 'src/providers/me/me';
import { Pros } from 'src/providers/pros/pros';
import { Requests } from 'src/providers/requests/requests';
import { Schedule } from 'src/providers/schedule/schedule';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Loading } from 'src/shared/components/loading/loading';
import { AddressModel, PlanModel } from 'src/models/address.model';
import { ScheduleCardModel, SelectedFilters } from 'src/models/schedule.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { GuestReservationSource } from 'src/models/guest-reservations';
import { Util } from 'src/shared/util/util';
import { Logger } from 'src/providers/logger';
import { WindowService } from 'src/shared/providers/window.service';
import { ClientSettingsModel } from 'src/models/client.model';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { AvailabilityRequestResponse } from 'src/models/schedule-weekly-view.model';
import { Menu } from 'src/providers/menu/menu';
import { Events } from 'src/providers/events/events';
import { RequestProPage } from 'src/pages/request-pro/request-pro';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Subscription } from 'rxjs';
import { AutomaticBookingAddressPage } from 'src/pages/more/automatic-booking/automatic-booking-address/automatic-booking-address';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { JobPage } from 'src/pages/schedule/job/job';
import { ReservationPage } from 'src/pages/reservation/reservation';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { CallProPage } from 'src/pages/call-pro/call-pro';
import { PlansPage } from 'src/pages/plans/plans';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { NeedsWorkWhatHappenedPage } from 'src/pages/feedback/needs-work/what-happened/what-happened';
import { LoveItPage } from 'src/pages/feedback/love-it/love-it';
import { RequestSubstitutePage } from 'src/pages/request-substitute/request-substitute';

@Component({
  templateUrl: 'schedule-list.html',
  styleUrls: ['./schedule-list.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ScheduleListPage implements OnDestroy, OnInit {

  allTableStartEndDays: any;
  addressFilter: TidySelectNumberValueModel[];
  activeIntegrations: Array<GuestReservationSource> = [];
  addressFilterLoaded: boolean;
  addressId = null;
  addressName: string;
  addressResponse: AddressModel[];
  addressRegionId: number;
  cards: ScheduleCardModel[];
  cardsLoaded: boolean;
  clientName: string;
  contentHeight: number;
  errorMessage: string;
  showContactConcierge = false;
  shouldShowAllToDosPage: boolean;
  settings: ClientSettingsModel;
  hasJobs: boolean;
  hasPhone: boolean;
  hasPrivatePro: boolean;
  hasRequests: boolean;
  hasOnlyCrafRequests: boolean;
  hasOnlyReservations: boolean;
  hasJobsAtOnlyAddress: boolean;
  hasAllOneTimePlans: boolean;
  isAllAddressesSelected: boolean;
  isRightSideContent: boolean;
  isRentalClient: boolean;
  automaticBookingEnabled: boolean;
  loadingNotifications: boolean;
  hasCreditCard: boolean;
  nextAvailableDate: string;
  plans: PlanModel[];
  waterfallSettings: any;
  toDoState: string;
  twoCoops = false;
  notificationHistory: any;
  hasAddresses: boolean;
  hasReservations: boolean;
  desktopWidthContent: string = scrollContentWidth.PORTRAIT;
  daysOfWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  selectedPeriodRange = {
    startDate: LuxonDateTime.local().toISODate(),
    endDate: LuxonDateTime.local().plus({ days: 6 }).toISODate()
  }
  isScheduleDataLoaded = false;
  propertiesFilters: SelectedFilters = {
    status: [],
    type: [],
    service: [],
    address: [],
    propertyName: [],
    pro: [],
  }
  propertiesSearch = '';
  phoneNumber: any;
  selectedProsIds: number[] = [];
  panelClosedSubscription: Subscription;
  scheduleViewMode: 'WEEKLY' | 'MONTHLY' = 'WEEKLY';
  form: UntypedFormGroup;
  isLoadingSchedule = true;
  loaded: boolean;
  numberOfDaysToBookWithin: number;

  constructor(
    private card: Card,
    public communication: Communication,
    private client: Client,
    private me: Me,
    public schedule: Schedule,
    private navCtrl: CustomNavController,
    private util: Util,
    private modalCtrl: ModalController,
    private pros: Pros,
    public  requests: Requests,
    public windowService: WindowService,
    private currentAddress: CurrentAddress,
    private logger: Logger,
    private storage: TidyStorage,
    public menu: Menu,
    private events: Events,
    private rightSidePanelService: RightSidePanelService,
    private guestReservations: GuestReservations
  ) {
    this.shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    this.storage.delete('proId');
    this.storage.delete('chatRoomKey');
    this.panelClosedSubscription = this.rightSidePanelService.afterPanelClosed().subscribe(() => {
      const selectCategoryOpened = localStorage.getItem('select-category-opened');
      if (selectCategoryOpened !== 'true' && this.scheduleViewMode === 'MONTHLY') {
        return;
      }
      localStorage.setItem('select-category-opened', 'false');
    });
    this.form = new UntypedFormGroup({
      scheduleViewMode: new UntypedFormControl('WEEKLY'),
    });
  }

  async ngOnInit() {
    localStorage.setItem('jobPageBackPath', 'schedule-list');
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.loaded = false;
    this.errorMessage = '';
    await this.getInitialData();
    await this.getAditionalData();
  }

  ngOnDestroy(): void {
    this.panelClosedSubscription.unsubscribe();
  }

  async getInitialData(): Promise<void> {
    try {
      this.hasRequests = false;
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      const getAddressesFromBackend = this.navCtrl.getParam('getAddressesFromBackend');
      await this.getPageData(getAddressesFromBackend);
      this.loaded = true;
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  async getAditionalData(): Promise<void> {
    try {
      if (this.hasAddresses && this.cards?.length) {
        this.checkForCards();
        this.checkForTwoCoops();
        this.checkForRequests();
        this.checkIfOnlyReservations();
        this.checkIfOnlyCrafRequests();
        if (this.hasJobs) {
          const messages = await this.getMessages();
          this.buildActivityTimelines(messages);
        }
      }
      const me = await this.me.load();
      this.showContactConciergeCheck(me);
    } catch (error) {
      this.handleLoadErrors(error);
    }
  }

  handleLoadErrors(error: any): void {
    const route = window.location.pathname;
    if (!route.includes('schedule-list')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }

  async getPageData(getAddressesFromBackend) {
    this.hasAddresses = await this.getAddresses(getAddressesFromBackend);
    this.getHasCreditCardInfo();
    if (this.hasAddresses) {
      this.desktopWidthContent = scrollContentWidth.PORTRAIT;
      this.getPlans();
      this.getClientSettings();
      await this.getCards();
    }
  }

  goToPropertyPage(addressId) {
    this.currentAddress.addressId = addressId.toString();
    location.reload();
  }

  async getClientSettings() {
    this.phoneNumber = await this.storage.retrieve('phoneNumber');
    if (this.phoneNumber) {
      this.hasPhone = this.phoneNumber !== null && this.phoneNumber !== '';
    } else {
      this.settings = await this.client.getClientSettings();
      this.phoneNumber = this.settings?.profile?.phone;
      this.hasPhone = this.settings?.profile?.phone === null || this.settings?.profile?.phone === '';
    }
    this.hasPrivatePro = await this.pros.checkIfHasPrivatePro();
  }

  async getAddresses(getAddressesFromBackend) {
    this.addressResponse = await this.client.getMoreDetailAddresses(getAddressesFromBackend);
    if(this.addressResponse.length === 0){
      localStorage.setItem('getAddressesFromBackend', 'true');
      return false;
    }
    else {
      this.addressId = await this.client.getSelectedAddressId(this.addressResponse);
      this.addressFilter = this.client.parseAddressList(this.addressResponse, true);
      this.addressFilterLoaded = true;
      this.getSelectedAddressInfo();
      return true;
    }
  }

  getSelectedAddressInfo() {
    const selectedAddress = this.selectedAddress();
    this.toDoState = selectedAddress?.default_address_task_list_state;
    localStorage.setItem('timezone', selectedAddress?.timezone);
    localStorage.setItem('toDoState', this.toDoState);
    this.addressRegionId = selectedAddress?.region_id;
    this.addressName = this.client.buildAddressName(selectedAddress);
    this.rightSidePanelService.setDialogPageTitle(this.addressName);
  }

  async getHasCreditCardInfo() {
    const address = this.selectedAddress();
    this.automaticBookingEnabled = address?.address_automatic_booking_setting?.automatic_booking_enabled;
    this.numberOfDaysToBookWithin = address?.address_automatic_booking_setting?.book_when_days_left_to_check_in_date_is_within;
    if (this.automaticBookingEnabled) {
      this.hasCreditCard = await this.card.hasValidCard();
    }
  }

  getPlans() {
    let plans;
    this.addressResponse.map((address) => {
      if (address.id === this.addressId) {
        plans = address.plans;
      };
    });
    this.plans = plans == null ? [] : plans;
    this.checkIfHasAllOneTimePlans();
  }

  checkIfHasAllOneTimePlans() {
    this.hasAllOneTimePlans = true;
    this.plans.map((plan) => {
      if (plan?.plan_frequency !== 'once') {
        this.hasAllOneTimePlans = false;
      }
    });
  }

  async getCards() {
    this.cardsLoaded = false;
    let cards = await this.schedule.getCards(this.addressId);
    //TODO https://trello.com/c/70YyrgSM/7701-b-list-endpoint-add-unassigned-data-to-the-list-response
    //Have BE return the data from getUpdates for each card instead of FE running this method
    cards.map(async (card) => {
      if (card?.template_data?.scenario_type === 'unassigned' || card?.template_data?.scenario_type === 'manual_request') {
        let updateId;
        card?.template_data?.updates.map(async (update) => {
          let updateHasAction;
          update?.text?.map((text) => {
            updateHasAction = text?.action === 'cleaning_update_page';
          });
          if (updateHasAction) {
            card.template_data['unassigned'] = await this.schedule.getUpdates(update.id);
          };
        });
      };
    });
    if (this.automaticBookingEnabled) {
      const today = moment();
      let addedFourWeekAlert = false;
      cards.map(card => {
        if (card?.template === 'guest_reservations') {
          const reservationDate = moment(card?.template_data?.check_out_date);
          const isFourWeeksOut = reservationDate.subtract(4, 'weeks').isSameOrAfter(today);
          if (isFourWeeksOut && !addedFourWeekAlert) {
            card['fourWeekAlert'] = true;
            addedFourWeekAlert = true;
          }
        }
      });
    }
    cards.map((card) => {
      if (card.template_data?.notification_history) {
        card.template_data.notification_history = this.schedule.getFilteredWaterfallHistory(card.template_data.notification_history);
      }
    })
    this.cards = cards;
    this.updateJobRequestStatus();
    this.hasJobs = !!this.cards?.find((card) => {
      return card?.template !== 'guest_reservations';
    });
    this.cardsLoaded = true;
  }

  async getMessages() {
    let jobIdsArray = [];
    this.cards?.map((card) => {
      if (card?.template == 'cleaning') {
        jobIdsArray.push(card?.template_data?.job?.id);
      }
    })
    const messages = await this.communication.getJobMessages(jobIdsArray);
    return messages;
  }

  buildActivityTimelines(messages) {
    this.cards?.map((card) => {
      if (card?.template == 'cleaning') {
        const homekeeperJobId = card?.template_data?.activity_timelines?.[0]?.homekeeper_job_id;
        const homekeeperJobMessages = messages?.filter((message) => message?.metadata?.homekeeper_job_id == homekeeperJobId);
        let updates = card?.template_data?.activity_timelines[0]?.activity_timeline_items?.filter((item) => item.type == 'cleaning_update');
        if (homekeeperJobMessages?.length > 0) {
          if (card?.template_data?.activity_timelines[0]) {
            card.template_data.activity_timelines[0]['activity_timeline_items'] = this.concatenateMessagesAndUpdates(updates, homekeeperJobMessages);
          } else {
            card?.template_data?.activity_timelines?.push({
              activity_timeline_items: homekeeperJobMessages
            });
          }
        }
      }
    });
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates?.map((update) => {
      update['sent_at'] = update?.data?.created_at;
    });
    if (updates?.length) {
      const activity = updates?.concat(messages);
      activity?.sort((a, b) => a?.sent_at < b?.sent_at ? 1 : -1);
      return activity;
    } else {
      return messages;
    }
  }

  checkForCards() {
    if (!this.cards?.length || this.cards?.length === 0) {
      localStorage.setItem('no_plan', 'true');
    }
  }

  checkForTwoCoops() {
    if (this.cards?.length > 1) {
      const numberOfPendingCoops = this.cards?.filter((card) => card?.template === 'job_request');
      this.twoCoops = (numberOfPendingCoops?.length > 1) ? true : false;
    }
  }

  checkForRequests() {
    this.cards?.map((card) => {
      if (card?.template === 'pending_availability_request') {
        this.hasRequests = true;
      }
    })
  }

  checkIfOnlyReservations() {
    this.hasReservations = !!this.cards?.find((card) => {
      return card?.template === 'guest_reservations';
    });
    this.hasOnlyReservations = !this.hasJobs && this.hasReservations;
  }

  checkIfOnlyCrafRequests() {
    const hasJobs = !!this.cards?.find((card) => {
      return card?.template !== 'pending_availability_request';
    });
    const hasCrafRequests = !!this.cards?.find((card) => {
      return card?.template === 'pending_availability_request';
    });
    this.hasOnlyCrafRequests = !hasJobs && hasCrafRequests;
  }

  async showContactConciergeCheck(me) {
    try {
      this.clientName = me?.customer_account?.name;
      if (!this.isRentalClient) {
        return;
      }
      const hasPastJobsStorage = localStorage.getItem('hasPastJobs');
      if (hasPastJobsStorage !== null) {
        this.showContactConcierge = hasPastJobsStorage === 'false';
      } else {
        const pastJobs = await this.client.getCleaningsHistory();
        const hasPastJobs = pastJobs?.length > 0;
        localStorage.setItem('hasPastJobs', hasPastJobs ? 'true' : 'false');
        this.showContactConcierge = !hasPastJobs;
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async changeAddress(addressId) {
    try {
      this.errorMessage = '';
      this.loaded = false;
      this.cardsLoaded = false;
      this.hasRequests = false;
      if (addressId === 0) {
        return this.rightSidePanelService.navigateTo('add-property');
      }
      if (this.addressId === -1) {
        this.goToPropertyPage(addressId);
        return;
      }
      this.addressId = addressId;
      this.currentAddress.addressId = addressId.toString();
      localStorage.removeItem('taskListId');
      this.getSelectedAddressInfo()
      await this.getPlans();
      await this.getCards();
      await this.getHasCreditCardInfo();
      this.checkForRequests();
      this.checkForCards();
      this.checkIfOnlyReservations();
      this.checkIfOnlyCrafRequests();
      this.cardsLoaded = true;
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  updateJobRequestStatus() {
    this.cards.map((card) => {
      if (card?.template_data?.notification_history) {
        if (card?.template_data?.notification_history[0]?.text == 'All pros on your priority list have declined.') {
          card.template_data.job_request_status = 'failed';
        }
      }
    });
  }

  learnMoreSdc() {
    this.util.openUrl('https://help.tidy.com/add-or-request-a-job');
  }

  async bookJob(bookingType) {
    await this.storage.save('bookJobBackPage', 'schedule-list');
    await this.client.getClientInfo();
    localStorage.setItem('region_id', this.addressRegionId.toString());
    const address = this.selectedAddress() || this.addressResponse[0];
    const isMobileResolution = window.innerWidth <= 870;
    const params = {
      address,
      bookingType,
    }
    if (this.hasPrivatePro || this.isRentalClient) {
      this.rightSidePanelService.navigateTo('select-category', params, SelectCategoryPage);
    } else {
      this.rightSidePanelService.navigateTo('book-job', params, BookJobPage);
    }
  }

  async goToPlansPage() {
    const params = {
      address: this.selectedAddress(),
      plans: this.plans,
      addressFilter: this.addressFilter,
      addressResponse: this.addressResponse,
      cards: this.cards
    }
    this.addressId = null;
    this.rightSidePanelService.navigateTo('plans', params, PlansPage);
  }

  async editProRequest(availabilityRequest: AvailabilityRequestResponse) {
    this.addressId = parseInt(localStorage.getItem('addressId'));
    try {
      const isMobileResolution = window.innerWidth <= 870;
      const params = {
        cameFromEditRequest: true,
        availabilityRequestId: availabilityRequest.id,
        requestTimes: availabilityRequest.request_times,
        serviceType: availabilityRequest.service_type_details.key,
        times: availabilityRequest.request_times,
        frequency: {
          viewValue: availabilityRequest.frequency,
          value: availabilityRequest.frequency
        },
        pro: {
          first_name: availabilityRequest.homekeeper?.name.split(' ')[0],
          id: availabilityRequest.homekeeper?.id
        },
        address: this.selectedAddress()
      };
      this.rightSidePanelService.navigateTo('request-pro', params, RequestProPage);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async cancelProRequest(availabilityRequest) {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: 'You can always create a new request if you\'d like.',
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelProRequest.bind(this, availabilityRequest)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelProRequest(availabilityRequest) {
    try {
      await this.schedule.cancelCrafRequest(availabilityRequest.id);
      await this.getCards();
      this.checkForRequests();
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  editWaterfallRequest(card) {
    const params: any = {
      selectedService: {
        key: card.template_data.booking.bookable_service.key,
        title: card.template_data.booking.bookable_service.name,
        price: card.template_data.booking.price,
        duration: card.template_data.booking.bookable_service.duration_to_block_off
      },
      address: {
        id: this.addressId,
        region_id: this.addressRegionId,
      },
      planId: card.template_data.booking.plan_id,
      bookingId: card.template_data.booking.id,
      serviceTypeId: card.template_data.booking.bookable_service.service_type_id
    };
    this.rightSidePanelService.navigateTo('request-job', params);
  }

  @Loading('', true)
  async cancelWaterfallRequest(bookingId, jobId) {
    const { reservationId, showAutomaticBookingQuestion, reservationDateTime } = await this.guestReservations.getLastReservationDetails(this.addressId, jobId);
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: 'You can always create a new request if you\'d like.',
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelWaterfallRequest.bind(this, bookingId, showAutomaticBookingQuestion, reservationId),
      showAutomaticBookingQuestion: showAutomaticBookingQuestion,
      reservationDateTime: reservationDateTime
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelWaterfallRequest(bookingId, showAutomaticBookingQuestion, reservationId) {
    try {
      if (showAutomaticBookingQuestion) {
        const keepAutomaticBookingOn = localStorage.getItem('keepAutomaticBookingOn') == 'true';
        localStorage.removeItem('keepAutomaticBookingOn');
        if (!keepAutomaticBookingOn) {
          await this.guestReservations.toggleAutomaticBookingForReservation(reservationId, false);
        }
      }
      await this.schedule.cancelJob(bookingId);
      const getAddressesFromBackend = true;
      await this.getPageData(getAddressesFromBackend);
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  goToRequestProCounter(availabilityRequest: AvailabilityRequestResponse) {
    const params = {
      planFrequency: availabilityRequest.frequency,
      service: availabilityRequest.service_type_details,
      proposedTimes: availabilityRequest.request_times,
      proName: availabilityRequest.homekeeper?.name.split(' ')[0],
      hkId: availabilityRequest.homekeeper?.id,
      availabilityRequestId: availabilityRequest.id,
      addressId: this.addressId,
      addressName: this.addressName
    };
    this.rightSidePanelService.navigateTo('request-pro-counter', params);
  }

  contactPro(card) {
    const params = {
      pro: card?.template_data?.homekeepers[0],
      isPrivate: card.template_data.job.is_private,
      clientPhone: this.phoneNumber
    }
    const url = 'call-pro';
    const component = CallProPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToProPage(proId) {
    localStorage.setItem('myProBackPage', 'schedule-list');
    const params = {
      proId: proId
    }
    this.rightSidePanelService.navigateTo(`my-pro/${proId}`, params, MyProPage);
  }

  @Loading('', true)
  async checkWaterfallHistory(selectionChange, card){
    const notification = card?.template_data?.notification_history[selectionChange.selectedIndex];
    this.notificationHistory = '';
    if(notification.type === 'history' && (notification?.data?.id && notification?.data?.unassigned_job_id)){
      try {
        this.notificationHistory = await this.client.getWaterfallNotificationHistory(notification?.data?.id);
      } catch (err) {
        this.notificationHistory = '';
        this.logger.error(err);
      }
    } else {
      this.notificationHistory = '';
    }
  }

  goToNotificationDetailsPage(notification) {
    const params = {
      notification
    }
    this.rightSidePanelService.navigateTo('notification-details', params);
  }

  goToEditContactInfo() {
    const params = {
      settings: this.settings
    };
    this.rightSidePanelService.navigateTo('contact-info', params);
  }

  async goToJobPage(card) {
    const address = this.selectedAddress();
    const params = {
      jobId: card.id,
      addressId: address.id,
      ...card,
      object: {
        address_id: address.id,
        id: card.template_data.job.id,
        booking: {
          id: card.template_data.booking.id
        }
      },
      cameFromSchedulePage: true,
      clientName: this.clientName,
      settings: this.settings,
      addressName: this.addressName
    }
    this.storage.save('dialog-params', params);
    this.rightSidePanelService.navigateTo(`job/${address.id}/${card.template_data.job.id}`, params, JobPage);
  }

  needsWork(job, pro) {
    const params = {
      firstName: pro.first_name,
      pro,
      isCleaningJob: job.template_data.job.service_type_details.service_category == 'Regular Cleaning',
      cleaningId: job.template_data.job.id,
      hkJobId: pro.homekeeper_job_id
    }
    this.rightSidePanelService.navigateTo(`needs-work-what-happened/${job.template_data.job.id}/${pro.homekeeper_job_id}`, params, NeedsWorkWhatHappenedPage);
  }

  loveIt(job, pro) {
    const params = {
      cleaningId: job.template_data.job.id,
      hkJobId: pro.homekeeper_job_id
    }
    this.rightSidePanelService.navigateTo(`love-it/${job.template_data.job.id}/${pro.homekeeper_job_id}`, params, LoveItPage);
  }

  reportNoShow(proName, jobId, hkId) {
    const params = {
      proName: proName,
      hkId: hkId,
      jobId: jobId
    }
    this.rightSidePanelService.navigateTo('pro-did-not-show-up', params);
  }

  @Loading('', true)
  async skipJob(bookingId, jobId) {
    const { reservationId, showAutomaticBookingQuestion, reservationDateTime } = await this.guestReservations.getLastReservationDetails(this.addressId, jobId);
    const params: ConfirmPageParamsModel = {
      title: 'Skip Job?',
      body: 'This will cancel the job (does not affect other jobs).',
      backText: 'Go Back',
      confirmText: 'Skip Job',
      confirmAction: this.skipJobAction.bind(this, bookingId, showAutomaticBookingQuestion, reservationId),
      showAutomaticBookingQuestion: showAutomaticBookingQuestion,
      reservationDateTime: reservationDateTime
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: showAutomaticBookingQuestion ? 'tall-confirm-modal' : 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async skipJobAction(bookingId, showAutomaticBookingQuestion, reservationId) {
    try {
      if (showAutomaticBookingQuestion) {
        const keepAutomaticBookingOn = localStorage.getItem('keepAutomaticBookingOn') == 'true';
        localStorage.removeItem('keepAutomaticBookingOn');
        if (!keepAutomaticBookingOn) {
          await this.guestReservations.toggleAutomaticBookingForReservation(reservationId, false);
        }
      }
      await this.schedule.cancelJob(bookingId);
      const successMessage = 'Job skipped';
      this.util.showSuccess(successMessage);
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async rescheduleJob(card) {
    const address = this.selectedAddress();
    const bookingType = card.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
    const planId = card.template_data.booking.plan_id;
    const jobId = card.template_data.job.id;
    const bookingId = card.template_data.booking.id;
    this.rightSidePanelService.navigateTo('book-job', { address, bookingType, jobId, bookingId, planId });
  }

  requestSubstitute(jobId) {
    const params = {
      jobId
    };
    this.rightSidePanelService.navigateTo(`request-substitute/${jobId}`, params, RequestSubstitutePage);
  }

  unassignedAction(action, card) {
    const pages = {
      'skip_cleaning': 'skip-cleaning',
      'reschedule_cleaning': `reschedule-skip/reschedule/`,
      'request_substitute': `request-substitute/${card.template_data.job.id}`,
      'cancel_substitute': 'cleaning-detail',
      'keep_one_homekeeper': 'cleaning-detail',
      'same_day_cleaning_page_v1': 'same-day-cleaning',
    }
    if (action == 'keep_one_homekeeper') {
      return this.keepOnlyOnePro(card.template_data.job.id);
    }
    if (action === 'reschedule_cleaning') {
      const bookingType = card.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
      const planId = card.template_data.booking.plan_id;
      const address = this.selectedAddress();
      const jobId = card.template_data.job.id;
      const bookingId = card.template_data.booking.id;
      return this.rightSidePanelService.navigateTo('book-job', { address, bookingType, jobId, bookingId, planId });
    }
    if (action === 'skip_cleaning' || action === 'cancel_substitute') {
      this.skipJob(card.template_data.booking.id, card.template_data.job.id);
    } else if (action === 'same_day_cleaning_page_v1') {
      const service = card.template_data.booking;
      const params = {
        selectedService: {
          title: service.bookable_service.name,
          image: service.bookable_service?.icon_url || 'assets/img/plans/' + service.bookable_service.key + '.svg',
          description: service.bookable_service.description,
          key: service.bookable_service.key,
          price: service.price,
          duration: service.bookable_service.duration_to_block_off
        },
        address: this.selectedAddress(),
        planId: service.plan_id,
        bookingId: card.template_data.booking.id,
        bookingType: 'reschedule_job',
        priceIsPlusFiftyPercent: true
      };
      this.rightSidePanelService.navigateTo('request-job', params);
    } else if (action === 'request_substitute'){
      this.requestSubstitute(card.template_data.job.id);
    } else {
      const link = pages[action] ? pages[action] : {};
      const param = {
        jobId: card.template_data.job.id
      };
      this.rightSidePanelService.navigateTo(link, param);
    }
  }

  async keepOnlyOnePro(jobId) {
    const params: ConfirmPageParamsModel = {
      title: 'Keep Only 1 Pro?',
      body: 'You\’ll be billed the prorated rate.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmKeepOnePro.bind(this, jobId)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmKeepOnePro(jobId) {
    try {
      await this.schedule.keepOneProForTwoProJob(jobId);
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      const successParams = {
        header: 'Kept One Pro',
        body: 'You were billed the prorated rate. Please contact the concierge with any questions.',
        buttonText: 'Ok',
        buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
      };
      this.rightSidePanelService.navigateTo('success', successParams);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  selectedAddress() {
    return this.addressResponse.find(ad => ad.id === this.addressId);
  }

  goToReviews() {
    this.util.openUrl('https://www.tidy.com/reviews');
  }

  goToReservation(event) {
    let guestReservation = event?.event?.object
    const didClickReservationFromAddressView = !guestReservation;
    this.addressId = event?.property?.id;
    this.addressName = event?.property?.name;
    if (didClickReservationFromAddressView) {
      guestReservation = event?.template_data;
      guestReservation['id'] = event.id.replace('guest_reservation-', '');
      this.addressId = event?.template_data?.address?.id;
      this.addressName = event?.template_data?.address?.address1;
    }
    const params = {
      guestReservation,
      address: this.addressName,
      addressId: this.addressId
    };
    this.rightSidePanelService.navigateTo('reservation', params, ReservationPage);
  }

  goToTerms() {
    this.util.openUrl('https://www.tidy.com/legal/property-managers-customer');
  }

  learnMoreReservations() {
    this.util.openUrl('https://help.tidy.com/integrations');
   }

  goToScheduleCallPage() {
    this.util.openUrl('https://www.tidy.com/schedule-integration-support');
  }

  goToIntegrations() {
    const address = this.addressResponse.find((address) => {
      return address.id == this.addressId;
    });
    const params = {
      address
    }
    localStorage.setItem('automaticBookingAddressBackPage', 'schedule-list');
    this.rightSidePanelService.navigateTo('automatic-booking-property', params, AutomaticBookingAddressPage);
  }

  goToAddCreditCard() {
    const params = {
      cameFromAutomaticBooking: true
    };
    const url = 'payment-methods/payment-method-form';
    this.rightSidePanelService.navigateTo(url, params);
  }

  showUpdates(card) {
    return card?.template_data?.activity_timelines[0]?.activity_timeline_items?.length && !card?.template_data?.unassigned;
  }

  async goToChangePro(card) {
    try {
      const privateProsList = await this.pros.getAllPrivatePros();
      const params = {
        job: card,
        addressId: this.addressId,
        privateProsList: privateProsList,
        booking: card.template_data.booking
      }
      const url = privateProsList?.length ? 'assign-pro' : 'why-add-private-pros';
      this.rightSidePanelService.navigateTo(url, params);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToAddAddress(){
    this.rightSidePanelService.navigateTo('add-property');
  }

  onFABButtonClick() {
    this.bookJob('add_one_time_job');
  }

  bookJobSelectAddress() {
    this.storage.save('bookJobBackPage', 'schedule-list');
    const isMobileResolution = window.innerWidth <= 870;
    const params = {
      isSelectingAddressToBook: true
    }
    this.rightSidePanelService.navigateTo('select-category', params, SelectCategoryPage);
  }

  getSmallestQuote(potentialHomekeepers) {
    return potentialHomekeepers?.reduce((min, homekeeper) => Math.min(min, homekeeper.amount), Infinity);
  }

  goToBidsPage(cardDetail, bookingDetail) {
    this.rightSidePanelService.navigateTo('job-request-bids', { cardDetail, bookingDetail, address: this.selectedAddress() });
  }
}

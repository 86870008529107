<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Account Service Defaults'"
    [canGoBack]="true"
    [customBack]="'list-settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      [text]="errorMessage"
      *ngIf="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'TIDY will use any account service default before your address default.'">
        </tidy-text>
      </tidy-row>

      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [header]="'Account Service Defaults'">
        </tidy-text>
        <tidy-text
          [body]="'Add'"
          (action)="goToEditDefaultList('account', 'add')"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let list of accountServiceDefaultLists">
        <tidy-card-button (action)="goToEditDefaultList('account', 'edit', list)">
          <tidy-row>
            <tidy-text
              [body]="list?.title">
            </tidy-text><br>
            <tidy-text
              [helper]="list?.default_list_setting?.service_name">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-alert *ngIf="accountServiceDefaultLists?.length == 0 && allToDoLists?.length !== 0">
        <tidy-row>
          <tidy-text
            [body]="'None added'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-alert *ngIf="allToDoLists?.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'You haven\'t added any templates to make into account service defaults. Please go to the'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [actionBody]="'list settings'"
            class="link"
            (action)="goToListSettings()">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'page and make one of your lists a template to make it an account service default. The list cannot have rooms specific to an address to make it a template.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <!--<tidy-row>
        <tidy-filter
          [initValue]="addressId"
          [items]="addressFilter"
          [fitContent]="true"
          (optionChange)="changeAddress($event)">
        </tidy-filter>
      </tidy-row>

      <tidy-row [alignLastItemRight]="addressDefaultLists?.length == 0">
        <tidy-text
          [header]="'Address Default'">
        </tidy-text>
        <tidy-text
          *ngIf="addressDefaultLists?.length == 0"
          [body]="'Add'"
          (action)="goToEditDefaultList('address', 'add')"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card *ngFor="let list of addressDefaultLists">
        <tidy-card-button (action)="goToEditDefaultList('address', 'edit', list)">
          <tidy-row>
            <tidy-text
              [body]="list?.title">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-alert *ngIf="addressDefaultLists?.length == 0">
        <tidy-row>
          <tidy-text
            [body]="'None added for this address'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>-->

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

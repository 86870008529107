<div class="pick-your-goals-header">
  <div class="onboarding-header-title">
    <tidy-text [title]="'Pick Your Goals'"></tidy-text>
  </div>
</div>
<tidy-divider></tidy-divider>

<div class="onboarding-container-padding">
  <tidy-row>
    <tidy-checkbox
      [items]="[{value: true, viewValue: 'Pick All Goals'}]"
      (checkedChange)="pickAllGoals()">
    </tidy-checkbox>
  </tidy-row>

  <ng-container *ngFor="let goal of goals">
    <tidy-card class="card-checkbox" [ngClass]="{'selected-card-checkbox': chosenGoals.includes(goal)}" (click)="selectGoal(goal)">
      <tidy-row class="card-checkbox-row">
        <tidy-image
          [src]="goal.image"
          class="header-size">
        </tidy-image>
        <tidy-text
          [header]="goal.title">
        </tidy-text>
      </tidy-row>
    </tidy-card>
  </ng-container>

  <tidy-row class="extra-top-padding">
    <tidy-button
      [text]="'Next'"
      class="primary"
      [action]="saveGoals.bind(this)">
    </tidy-button>
  </tidy-row>
</div>

<div class="backdrop"></div>

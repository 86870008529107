import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../providers/translation.service';

@Component({
  selector: 'tidy-translations',
  encapsulation: ViewEncapsulation.None,
  template: `<div></div>`
})

export class TranslationsComponent implements OnInit {

  @Input() translations: any;

  constructor(
    private translationService: TranslationService
  ) {}

  async ngOnInit() {
    this.translationService.translations = this.translations;
  }

}
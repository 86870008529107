export const propertiesMockData = [
    {
        "id": 623559,
        "address1": "1822 Nuevo Road",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89014",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Nuevo 1822",
        "latitude": 36.050533,
        "longitude": -115.067474,
        "address_automatic_booking_setting": {
            "id": 60214,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623561,
        "address1": "1918 Pintura Circle",
        "address2": null,
        "city": "North Las Vegas",
        "add_state": "NV",
        "zip": "89031",
        "state": "active",
        "region_id": 151,
        "address_name": "[str] Pintura 1918",
        "latitude": 36.2512335,
        "longitude": -115.1693707,
        "address_automatic_booking_setting": {
            "id": 60216,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623550,
        "address1": "8157 Creek Water Lane",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89123",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Creekwater 8157",
        "latitude": 36.0411866,
        "longitude": -115.1420048,
        "address_automatic_booking_setting": {
            "id": 60218,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623556,
        "address1": "3149 Belvedere Drive",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89014",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Belvedere 3149",
        "latitude": 36.063164,
        "longitude": -115.089806,
        "address_automatic_booking_setting": {
            "id": 60001,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623552,
        "address1": "4200 South Valley View Boulevard",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89103",
        "state": "active",
        "region_id": 154,
        "address_name": "[str] Valley View 4200 #3047",
        "latitude": 36.1126358,
        "longitude": -115.187581,
        "address_automatic_booking_setting": {
            "id": 60003,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623549,
        "address1": "The Signature at MGM Grand - Tower B",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89109",
        "state": "active",
        "region_id": 154,
        "address_name": "[str] Harmon 135 #1615",
        "latitude": 36.1067937,
        "longitude": -115.1665441,
        "address_automatic_booking_setting": {
            "id": 60220,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623547,
        "address1": "120 West Highland Drive",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89015",
        "state": "active",
        "region_id": 152,
        "address_name": "(INACTIVE) [str] Highland 120",
        "latitude": 36.0209711,
        "longitude": -114.9885103,
        "address_automatic_booking_setting": {
            "id": 59461,
            "automatic_booking_enabled": false,
            "required_team_service": {
                "name": "2.5 hour cleaning",
                "description": "Most popular cleaning, good for general cleanings of most homes.",
                "id": 2,
                "key": "regular_cleaning.two_and_a_half_hours"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623563,
        "address1": "Gabriel Drive",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89119",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Gabriel 1857",
        "latitude": 36.1045715,
        "longitude": -115.1255341,
        "address_automatic_booking_setting": {
            "id": 60007,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623551,
        "address1": "2070 Irwin Circle",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89119",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Irwin 2070",
        "latitude": 36.1090045,
        "longitude": -115.1241957,
        "address_automatic_booking_setting": {
            "id": 60009,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623529,
        "address1": "2432 Greens Avenue",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89014",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Greens 2432",
        "latitude": 36.06381226,
        "longitude": -115.07437897,
        "address_automatic_booking_setting": {
            "id": 60011,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623555,
        "address1": "3322 West Mardon Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89139",
        "state": "active",
        "region_id": 154,
        "address_name": "[str] Mardon 3322",
        "latitude": 36.0557865,
        "longitude": -115.1851082,
        "address_automatic_booking_setting": {
            "id": 60030,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623527,
        "address1": "350 East Longacres Drive",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89015",
        "state": "active",
        "region_id": 152,
        "address_name": "[str] Longacres 350",
        "latitude": 36.01828766,
        "longitude": -114.97850037,
        "address_automatic_booking_setting": {
            "id": 60032,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623545,
        "address1": "3953 Waterford Lane",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89119",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Waterford 3953",
        "latitude": 36.1176341,
        "longitude": -115.1241816,
        "address_automatic_booking_setting": {
            "id": 60034,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623560,
        "address1": "4029 Waldorf Court",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89103",
        "state": "active",
        "region_id": 154,
        "address_name": "[str] Waldorf 4029",
        "latitude": 36.1160036,
        "longitude": -115.2194604,
        "address_automatic_booking_setting": {
            "id": 60036,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623558,
        "address1": "531 Silent Siesta Drive",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89015",
        "state": "active",
        "region_id": 152,
        "address_name": "[str] Silent Siesta 531",
        "latitude": 36.024487,
        "longitude": -114.955811,
        "address_automatic_booking_setting": {
            "id": 60038,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623548,
        "address1": "2031 Babylon Mill Street",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89002",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Babylon Mill 2031",
        "latitude": 36.006287,
        "longitude": -114.936317,
        "address_automatic_booking_setting": {
            "id": 60040,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623564,
        "address1": "4291 East Rochelle Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89121",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Rochelle 4291",
        "latitude": 36.1110863,
        "longitude": -115.080589,
        "address_automatic_booking_setting": {
            "id": 60042,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623562,
        "address1": "6419 Gunderson Boulevard",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89103",
        "state": "active",
        "region_id": 154,
        "address_name": "[str] Gunderson 6419",
        "latitude": 36.1089123,
        "longitude": -115.2321034,
        "address_automatic_booking_setting": {
            "id": 60044,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623525,
        "address1": "Terracina Way",
        "address2": null,
        "city": "Reno",
        "add_state": "NV",
        "zip": "89521",
        "state": "active",
        "region_id": 1230,
        "address_name": "[str] Terracina 455",
        "latitude": 39.4080603,
        "longitude": -119.7375355,
        "address_automatic_booking_setting": {
            "id": 60046,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623544,
        "address1": "4011 Tacoma Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89121",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Tacoma 4011",
        "latitude": 36.102802,
        "longitude": -115.0845239,
        "address_automatic_booking_setting": {
            "id": 60048,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623526,
        "address1": "4686 Mystic Quartz Court",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89141",
        "state": "active",
        "region_id": 154,
        "address_name": "(INACTIVE) [str] Mystic Quartz 4686",
        "latitude": 36.005152,
        "longitude": -115.204291,
        "address_automatic_booking_setting": {
            "id": 62923,
            "automatic_booking_enabled": false,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623539,
        "address1": "1704 South 7th Street",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89104",
        "state": "active",
        "region_id": 152,
        "address_name": "[mtr] 7th Ave 1704",
        "latitude": 36.1509783,
        "longitude": -115.1456763,
        "address_automatic_booking_setting": {
            "id": 60052,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623537,
        "address1": "2994 Gramsci Avenue",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89044",
        "state": "active",
        "region_id": 1225,
        "address_name": "[mtr]  Gramsci 2994",
        "latitude": 35.9333786,
        "longitude": -115.1186456,
        "address_automatic_booking_setting": {
            "id": 61647,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623532,
        "address1": "2945 Azure Bay Street",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89117",
        "state": "active",
        "region_id": 150,
        "address_name": "[mtr] Azure Bay 2945",
        "latitude": 36.1357481,
        "longitude": -115.2619491,
        "address_automatic_booking_setting": {
            "id": 61649,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623568,
        "address1": "Dancing Breeze Court",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89178",
        "state": "active",
        "region_id": 154,
        "address_name": "[mtr] Dancing Breeze 7514",
        "latitude": 36.0008682,
        "longitude": -115.2557719,
        "address_automatic_booking_setting": {
            "id": 61651,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623535,
        "address1": "6931 Wedgewood Way",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89147",
        "state": "active",
        "region_id": 150,
        "address_name": "[mtr] Wedgewood 6931",
        "latitude": 36.119901,
        "longitude": -115.2438891,
        "address_automatic_booking_setting": {
            "id": 61653,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623553,
        "address1": "6684 Purple Poppy Lane",
        "address2": null,
        "city": "Park City",
        "add_state": "UT",
        "zip": "84098",
        "state": "active",
        "region_id": 1175,
        "address_name": "[str] Purple Poppy 6684",
        "latitude": 40.72688,
        "longitude": -111.487798,
        "address_automatic_booking_setting": {
            "id": 61655,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Phoenix"
    },
    {
        "id": 623528,
        "address1": "6764 Firewood Drive",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89148",
        "state": "active",
        "region_id": 154,
        "address_name": "[mtr] Firewood 6764",
        "latitude": 36.0653945,
        "longitude": -115.2989083,
        "address_automatic_booking_setting": {
            "id": 61657,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623572,
        "address1": "Seven Hills Drive #apt 1521",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89052",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Seven Hills",
        "latitude": 35.9954208,
        "longitude": -115.1206849,
        "address_automatic_booking_setting": {
            "id": 61659,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623531,
        "address1": "1900 Bookbinder Drive",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89108",
        "state": "active",
        "region_id": 150,
        "address_name": "[mtr] Bookbinder 1900",
        "latitude": 36.1931312,
        "longitude": -115.2351592,
        "address_automatic_booking_setting": {
            "id": 61661,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623534,
        "address1": "1906 Old Mill Lane",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89014",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Old Mill 1906",
        "latitude": 36.0542939,
        "longitude": -115.0699986,
        "address_automatic_booking_setting": {
            "id": 61663,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623566,
        "address1": "105 East Opulent Rose Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89183",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Opulent Rose 105",
        "latitude": 35.9813729,
        "longitude": -115.1677342,
        "address_automatic_booking_setting": {
            "id": 61665,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623536,
        "address1": "2542 Strathmill Ave",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89044",
        "state": "active",
        "region_id": 1225,
        "address_name": "[mtr] Strathmill 2542",
        "latitude": 35.9358123,
        "longitude": -115.1367126,
        "address_automatic_booking_setting": {
            "id": 61667,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623541,
        "address1": "2402 Legacy Island Circle",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89074",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Legacy Island 2402",
        "latitude": 36.0333698,
        "longitude": -115.0926782,
        "address_automatic_booking_setting": {
            "id": 61669,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623567,
        "address1": "249 Timber Hollow Street",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89012",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Timber Hollow 249",
        "latitude": 36.0208048,
        "longitude": -115.0621406,
        "address_automatic_booking_setting": {
            "id": 61671,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623538,
        "address1": "Paintedhills Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89120",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Paintedhills 3179",
        "latitude": 36.0852781,
        "longitude": -115.1068884,
        "address_automatic_booking_setting": {
            "id": 61673,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623530,
        "address1": "Champions Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89142",
        "state": "active",
        "region_id": 152,
        "address_name": "[mtr]  Champions 5048",
        "latitude": 36.1441714,
        "longitude": -115.0610809,
        "address_automatic_booking_setting": {
            "id": 61675,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623554,
        "address1": "5198 Hibbetts Drive",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89103",
        "state": "active",
        "region_id": 154,
        "address_name": "[mtr] Hibbets 5198",
        "latitude": 36.1220967,
        "longitude": -115.2123282,
        "address_automatic_booking_setting": {
            "id": 61677,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623533,
        "address1": "825 Radiant Star Street",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89145",
        "state": "active",
        "region_id": 150,
        "address_name": "[mtr] Radiant Star 825",
        "latitude": 36.1618713,
        "longitude": -115.2711011,
        "address_automatic_booking_setting": {
            "id": 61679,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 627355,
        "address1": "1st Street",
        "address2": null,
        "city": "Hoboken",
        "add_state": "NJ",
        "zip": "07030",
        "state": "active",
        "region_id": 405,
        "address_name": "[str] FirstStreet-452 #2",
        "latitude": 40.7382625,
        "longitude": -74.0354463,
        "address_automatic_booking_setting": {
            "id": 60015,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "10:30",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "US/Eastern"
    },
    {
        "id": 627356,
        "address1": "1st Street",
        "address2": null,
        "city": "Hoboken",
        "add_state": "NJ",
        "zip": "07030",
        "state": "active",
        "region_id": 405,
        "address_name": "[str] FirstStreet-452 #4",
        "latitude": 40.7382625,
        "longitude": -74.0354463,
        "address_automatic_booking_setting": {
            "id": 60017,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "US/Eastern"
    },
    {
        "id": 627213,
        "address1": "Spencer Street",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89169",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Spencer 3474",
        "latitude": 36.1265153,
        "longitude": -115.1272986,
        "address_automatic_booking_setting": {
            "id": 61681,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "10:30",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 627354,
        "address1": "Adams Street",
        "address2": null,
        "city": "Hoboken",
        "add_state": "NJ",
        "zip": "07030",
        "state": "active",
        "region_id": 405,
        "address_name": "[str] Adams-102 #4",
        "latitude": 40.7387812,
        "longitude": -74.0373887,
        "address_automatic_booking_setting": {
            "id": 60223,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "US/Eastern"
    },
    {
        "id": 624945,
        "address1": "102 Adams Street",
        "address2": null,
        "city": "Hoboken",
        "add_state": "NJ",
        "zip": "07030",
        "state": "active",
        "region_id": 405,
        "address_name": "[str] Adams-102 #1",
        "latitude": 40.7387812,
        "longitude": -74.0373887,
        "address_automatic_booking_setting": {
            "id": 60027,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "US/Eastern"
    },
    {
        "id": 623569,
        "address1": "Aztec Way",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89169",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Aztec 1444",
        "latitude": 36.1280555,
        "longitude": -115.1331032,
        "address_automatic_booking_setting": {
            "id": 61645,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 623057,
        "address1": "6571 Altura Boulevard",
        "address2": "",
        "city": "Buena Park",
        "add_state": "CA",
        "zip": "90620",
        "state": "active",
        "region_id": 4,
        "address_name": "All the To-do Checklist",
        "latitude": 33.8724457,
        "longitude": -118.0188148,
        "address_automatic_booking_setting": null,
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 631197,
        "address1": "1st Street #3",
        "address2": null,
        "city": "Hoboken",
        "add_state": "NJ",
        "zip": "07030",
        "state": "active",
        "region_id": 405,
        "address_name": "[str] FirstStreet-452 #3",
        "latitude": 40.7382625,
        "longitude": -74.0354463,
        "address_automatic_booking_setting": {
            "id": 60023,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 180,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "US/Eastern"
    },
    {
        "id": 633887,
        "address1": "North Starling Mesa Street",
        "address2": null,
        "city": "North Las Vegas",
        "add_state": "NV",
        "zip": "89086",
        "state": "active",
        "region_id": 151,
        "address_name": "[mtr] Cozy 5BR Home w/ Modern Comfort \u0026 Prime Location",
        "latitude": 36.2782967,
        "longitude": -115.1266403,
        "address_automatic_booking_setting": {
            "id": 61683,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 632943,
        "address1": "Pampas Place",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89146",
        "state": "active",
        "region_id": 150,
        "address_name": "(INACTIVE) [mtr] 3172 Pampas Pl.",
        "latitude": 36.1319771,
        "longitude": -115.2153937,
        "address_automatic_booking_setting": {
            "id": 60221,
            "automatic_booking_enabled": false,
            "required_team_service": {
                "name": "2.5 hour cleaning",
                "description": "Most popular cleaning, good for general cleanings of most homes.",
                "id": 2,
                "key": "regular_cleaning.two_and_a_half_hours"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 629247,
        "address1": "3953 Waterford Lane",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89119",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Waterford 3953",
        "latitude": 36.1176341,
        "longitude": -115.1241816,
        "address_automatic_booking_setting": {
            "id": 61685,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 644042,
        "address1": "4142 Victoria Street",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89121",
        "state": "active",
        "region_id": 153,
        "address_name": "[str] Victoria 4142",
        "latitude": 36.11406708,
        "longitude": -115.07621002,
        "address_automatic_booking_setting": {
            "id": 61516,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 650608,
        "address1": "Pampas Place",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89146",
        "state": "active",
        "region_id": 150,
        "address_name": "[str] Pampas 3172",
        "latitude": 36.1319771,
        "longitude": -115.2153937,
        "address_automatic_booking_setting": {
            "id": 61687,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 649779,
        "address1": "Copper View Street",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89052",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Stunning Green Valley Ranch Home | Furnished Mid-Term Rental",
        "latitude": 36.0073944,
        "longitude": -115.0917154,
        "address_automatic_booking_setting": {
            "id": 61689,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 650786,
        "address1": "Brigadier Street",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89002",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Brigadier 549",
        "latitude": 36.0039923,
        "longitude": -114.9694552,
        "address_automatic_booking_setting": {
            "id": 60274,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 656512,
        "address1": "East Harmon Avenue",
        "address2": null,
        "city": "Las Vegas",
        "add_state": "NV",
        "zip": "89119",
        "state": "active",
        "region_id": 153,
        "address_name": "[mtr] Gabriel 1857",
        "latitude": 36.10688853,
        "longitude": -115.12129942,
        "address_automatic_booking_setting": {
            "id": 62924,
            "automatic_booking_enabled": false,
            "required_team_service": {
                "name": "1 hour cleaning",
                "description": "Quick clean, best for focused needs or small homes.",
                "id": 1,
                "key": "regular_cleaning.one_hour"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 3,
            "book_when_days_left_to_check_in_date_is_within": 28,
            "booking_start_time_no_earlier_than": null,
            "booking_end_time_no_later_than": null
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": "Hostaway",
            "state": "active"
        },
        "timezone": "America/Los_Angeles"
    },
    {
        "id": 642999,
        "address1": "North Water Street",
        "address2": null,
        "city": "Henderson",
        "add_state": "NV",
        "zip": "89015",
        "state": "active",
        "region_id": 152,
        "address_name": "[mtr] Charming Henderson Home: Comfort \u0026 Style Await!",
        "latitude": 36.0514704,
        "longitude": -114.9806476,
        "address_automatic_booking_setting": {
            "id": 60285,
            "automatic_booking_enabled": true,
            "required_team_service": {
                "name": "Laundry Pickup",
                "description": "Laundry professionally picked up for cleaning.",
                "id": 154,
                "key": "laundry.laundry_pickup"
            },
            "booking_cancellation_strategy": null,
            "max_days_to_perform_job_after_checkout": 0,
            "book_when_days_left_to_check_in_date_is_within": 30,
            "booking_start_time_no_earlier_than": "11:00",
            "booking_end_time_no_later_than": "16:00"
        },
        "default_address_task_list_state": "active",
        "integration": {
            "name": null,
            "state": null
        },
        "timezone": "America/Los_Angeles"
    }
]
import { Component } from '@angular/core';
import { Concierge } from 'src/providers/concierge/concierge';
import { Util } from 'src/shared/util/util';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  selector: 'add-inspections-onboarding',
  templateUrl: './add-inspections-onboarding.html',
  styleUrls: ['./add-inspections-onboarding.scss']
})
export class AddInspectionsOnboardingComponent {
  inspectionTemplates = [
    {
      name: '',
      description: 'In-Person Inspection',
      isSelected: false
    },
    {
      name: '',
      description: 'Virtual Inspection',
      isSelected: false
    },
    {
      name: '',
      description: 'No Inspection',
      isSelected: false
    },
  ];

  constructor(
    private concierge: Concierge,
    private util: Util,
    public onboardingProvider: OnboardingProvider,
    private storage: TidyStorage,
  ) {}

  async save() {
    const selectedTemplate = this.inspectionTemplates.find(t => t.isSelected);
    const data = {
      subject: 'Inspection Settings',
      body: selectedTemplate.description,
      emails: [],
      attachments: [],
    }
    const item = {
      data,
      type: 'support.concierge_task',
    }
    try {
      await Promise.all([
        this.concierge.addConciergeItem(item),
        this.onboardingProvider.markGoalAsCompleted('addInspections')
      ]);
      this.onboardingProvider.setShowOnboardingOnPage(false);
      this.onboardingProvider.setShowOnboardingInHeader(true);
      this.util.showSuccess('Inspection settings saved');
    } catch (err) {
      const errorMessage = err?.error?.message ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  selectTemplate(template) {
    template.isSelected = !template.isSelected;
    this.inspectionTemplates.forEach(t => {
      if (t !== template) {
        t.isSelected = false;
      }
    });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="loaded? maintenances[0].room_object.model.category.name + ' Added' : ''"
    [noBow]="true"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <div [formGroup]="form">
    <ng-container formArrayName="maintenaceDates">
      <tidy-wrapper>
        <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <ng-container *ngIf="loaded">
          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              [body]="'Please let us know when you last completed these maintenances on this item:'">
            </tidy-text>
          </tidy-row>

          <ng-container *ngFor="let maintenaceDate of maintenaceDates.controls; let i = index">
            <div [formGroup]="maintenaceDate" @fadeOut>
              <tidy-card>
                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                    [header]="maintenances[i].maintenance?.name">
                  </tidy-text>
                </tidy-row>
                <ng-container *ngFor="let formValue of maintenances[i].formValues">
                  <tidy-row class="extra-bottom-padding">
                    <tidy-button
                      [action]="addItem.bind(this, maintenances[i], maintenaceDate, i, formValue.value)"
                      [text]="formValue.viewValue"
                      [smallText]="true"
                      class="no-padding secondary">
                    </tidy-button>
                  </tidy-row>
                </ng-container>
              </tidy-card>
            </div>
          </ng-container>

          <tidy-row [align]="'center'" class="extra-bottom-padding">
            <tidy-text
              (action)="toMainCrudPage()"
              [body]="'Skip'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

      </tidy-wrapper>
    </ng-container>
  </div>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Injectable } from "@angular/core";
import { Employee } from "src/pages/employer/add-employee/add-employee";
import { HttpClientCustom } from "../custom/http-client";
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {

  constructor(
    private httpClient: HttpClientCustom,
    private storage: TidyStorage
  ) {}

  async listEmployees(getFromBackend = false): Promise<any> {
    const url = 'api/v1/customer/customer-employees';
    const storedData = await this.storage.retrieve('listEmployees');
    const currentTime = new Date().getTime();
    const threeMinutesInMs = 3 * 60 * 1000;
    if (getFromBackend || !storedData || !storedData.timestamp || (currentTime - storedData.timestamp > threeMinutesInMs)) {
      const response = await this.httpClient.get(url);
      const dataToStore = {
        employees: response,
        timestamp: currentTime
      };
      await this.storage.save('listEmployees', dataToStore);
      return response;
    } else {
      return storedData.employees;
    }
  }
  
  addEmployee(employeeData): Promise<Employee> {
    const url = 'api/v1/customer/customer-employees';
    return this.httpClient.post(url, employeeData);
  }

  editEmployee(employee: Partial<Employee>): Promise<Employee> {
    const url = `api/v1/customer/customer-employees/${employee.id}`
    return this.httpClient.put(url, employee);
  }

  deleteEmployee(employeeId: number): Promise<Employee> {
    const url = `api/v1/customer/customer-employees/${employeeId}`
    return this.httpClient.delete(url);
  }

  listCampaigns(): Promise<any> {
    const url = 'api/v1/customer/customer-employee-campaigns';
    return this.httpClient.get(url);
  }

  getEmployeeCreditBalance() {
    const url = 'api/v1/customer/employer/employee-credit-balance';
    return this.httpClient.get(url);
  }

  addEmployeeCredit(payload) {
    const url = 'api/v1/customer/employer/add-employee-credit';
    return this.httpClient.post(url, payload);
  }

  removeEmployeeCredit(payload) {
    const url = 'api/v1/customer/employer/cancel-employee-credit';
    return this.httpClient.post(url, payload);
  }

}

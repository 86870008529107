<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="!cantGoBack"
    [title]="'Account Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-card-button (action)="goToEditContactInfo()" style="padding-top:0px">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/smartphone.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Contact Info'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <ng-container *ngIf="clientInfo?.customer_account?.account_name">
              <tidy-text
                [helper]="clientInfo?.customer_account?.account_name"
                [translate]="false">
              </tidy-text><br>
            </ng-container>
            <tidy-text
              [helper]="clientInfo?.current_user?.name"
              [translate]="false">
            </tidy-text><br>
            <tidy-text
              [helper]="clientInfo?.current_user?.email"
              [translate]="false">
            </tidy-text><br>
            <tidy-text
              [helper]="settings?.profile?.phone | telephone:'format'">
            </tidy-text>
            <tidy-row class="vertical-align-center" *ngIf="settings?.profile?.phone === null || settings?.profile?.phone === ''">
              <tidy-image
                [src]="'assets/icon/alert-circle-red.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                class="red"
                [actionBody]="'Missing Phone number'">
              </tidy-text>
            </tidy-row>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToAccountNotificationsPage()" *ngIf="userRole !== 'member'">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Account Notifications'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="notificationSettings?.length == 0">
            <tidy-text
              [helper]="'None'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let setting of notificationSettings">
            <tidy-text
              [body]="setting?.display_name + ': '">
            </tidy-text>
            <ng-container *ngFor="let channel of setting.channels; let last = last">
              <tidy-text
                [helper]="channel?.name?.substring(0,37) + (channel?.name?.length > 37 ? '...' : '')"
                [translate]="false">
              </tidy-text>
              <tidy-text
                *ngIf="!last"
                [helper]="', '">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditAccountType()">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/information-circle-yellow.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Account Type'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [helper]="accountType">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditBackupTimes()" *ngIf="userRole !== 'member'">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/clock.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Default Backup Times'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              *ngIf="!hasBackupTimes"
              [helper]="'None'">
            </tidy-text>
            <ng-container *ngFor="let time of scheduleAssurance?.customerAddress?.times; let last = last">
              <ng-container *ngIf="time.start_time !== '00:00' && time.start_time !== null">
                <tidy-text
                  [helper]="(time.week_day | tweekday: true) + ' '">
                </tidy-text>
                <tidy-text
                  [helper]="(time.start_time | customtime: 'h:mma') + ' - ' + (time.end_time | customtime: 'h:mma') + ' '">
                </tidy-text>
                <ng-container *ngIf="last">
                  <br>
                </ng-container>
              </ng-container>
            </ng-container>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditLanguage()">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/planet-earth.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Display Language'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [helper]="language">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true" *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/salary.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Referral Code'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="clientInfo?.customer_account?.referral_code">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [helper]="'Friends who use this get $10 off their first job, then you get $10 off your next.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<ion-header mode="ios">
  <div class="top-nav-container" (click)="onContainerClick($event)">
    <div class="top-nav-left">
      <img class="bowtie" decoding="async" [src]="logo" />
      <div class="search-container">
        <form [formGroup]="form">
          <tidy-search-input
            class="header-search"
            formControlName="search"
            [placeholder]="'Search'"
            [icon]="'assets/img/search-header.svg'">
          </tidy-search-input>
        </form>
      </div>
      <div
        class="autocomplete-container"
        *ngIf="
          form.value.search.length > 2
        ">
        <div class="autocomplete-list">
          <ng-container
            *ngFor="let searchItem of searchItems; let first = first">
            <div>
              <div class="section-header" [ngStyle]="{'margin-top': !first ? '10px' : '0'}">
                <tidy-text [body]="searchItem.list"></tidy-text>
              </div>
              <ng-container *ngFor="let item of searchItem.items; let last = last">
                <div class="autocomplete-item" (click)="handleClickItem(item)">
                  <tidy-image [src]="item.image"></tidy-image>
                  <tidy-text [body]="item.title"></tidy-text>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="searchItems.length == 0 && form.value.search.length > 2">
            <div class="autocomplete-item no-hover-effect">
              <tidy-text
                [body]="('No results for: ' | translate) + form.value.search"></tidy-text>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="top-nav-right">
      <!--<div style="margin-right: 10px;">
        <tidy-toggle
          [toggled]="showOnboarding"
          (toggleChanged)="toggleShowOnboarding()">
        </tidy-toggle>
      </div>-->
      <div class="onboarding-container" (click)="openOnboarding()" *ngIf="showOnboarding && totalGoals && totalGoals !== 0" [ngClass]="{'glow-animation': showGlowAnimation}">
        <tidy-image [src]="'assets/img/goal.svg'"></tidy-image>
        <tidy-text [body]="completedGoals + ' / ' + totalGoals + ' Goals Completed'" class="white"></tidy-text>
      </div>
      <div
        *ngIf="!isEmployerAccount"
        [class.current-item]="headerProvider.showConciergePage"
        (click)="navigateToConcierge()"
        class="header-icon hover-effect"
        [matTooltip]="('Concierge' | translate)"
        [matTooltipPosition]="'below'"
        matTooltipClass="menu-item-tooltip below">
        <img style="height: 25px" [src]="headerProvider.showConciergePage ? 'assets/img/menu-concierge-green.svg' : 'assets/img/menu-concierge-white.svg'" />
      </div>
      <div
        [class.current-item]="showHelpDropdown"
        class="header-icon hover-effect"
        (click)="toggleHelpDropdown($event)"
        #helpIconRef>
        <img style="height: 25px" [src]="showHelpDropdown ? 'assets/img/menu-help-green.svg' : 'assets/img/menu-help-white.svg'" />
      </div>
      <div #helpDropdownRef class="help-dropdown" *ngIf="showHelpDropdown">
        <div class="help-dropdown-list">
          <div class="help-dropdown-item" (click)="navigateToWhatsNew()">
            <tidy-text [body]="'What\'s New?'"></tidy-text>
          </div>
          <div class="help-dropdown-item" (click)="navigateToDocuments()">
            <tidy-text [body]="'Documentation'"></tidy-text>
          </div>
          <div class="help-dropdown-item" (click)="goToAddSupportRequest()" *ngIf="!isEmployerAccount">
            <tidy-text [body]="'Contact Support'"></tidy-text>
          </div>
        </div>
      </div>
      <div
        class="initials-div hover-effect"
        [class.current-item]="showInitialsDropdown"
        (click)="toggleInitialsDropdown($event)"
        #initialsIconRef>
        <div>
        <div class="initials" [class.green]="showInitialsDropdown">{{initials}}</div>
        </div>
      </div>
      <div
        #initialsDropdownRef
        class="help-dropdown"
        style="right: 20px"
        *ngIf="showInitialsDropdown">
        <div class="help-dropdown-list">
          <div class="help-dropdown-item" (click)="goToContactInfo()">
            <tidy-text [body]="'Contact Info'"></tidy-text>
          </div>
          <div class="help-dropdown-item" (click)="logout()">
            <tidy-text [body]="'Logout'"></tidy-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-header>

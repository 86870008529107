import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tidy-card-list',
  encapsulation: ViewEncapsulation.None,
  template:
    `
      <div class="tidy-container">
        <div class="tidy-list-item" *ngIf="!hideIcon">
          <ng-content select="[item-left]"></ng-content>
        </div>
        <div class="tidy-list-item-middle">
          <ng-content select="[item-label]"></ng-content>
        </div>
        <div class="tidy-list-item">
          <ng-content select="[item-right]"></ng-content>
        </div>
      </div>
    `
})
export class TidyCardList {

  @Input() hideIcon: boolean;

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Workflow History'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-alert *ngIf="workflowItems?.length == 0">
        <tidy-row>
          <tidy-text [body]="'No workflows added yet. Add workflows to view the run history here.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row *ngIf="workflowItems?.length > 0">
        <tidy-filter
          [initValue]="0"
          [items]="workflowItems"
          [fitContent]="true"
          (optionChange)="selectWorkflow($event)">
        </tidy-filter>
      </tidy-row>

      <tidy-row class="daterange-card">
        <div style="
        background: white;
        border-radius: 100px;
        padding: 5px 20px 5px 20px;
        display: block;
        box-shadow: 0 1px 6px #0000003d;
        font-size: 16px;
        width: 250px;
        margin: auto;
        max-width: 80%">
          <tidy-daterange
            [label]="'Date Range'"
            (customDateOption)="selectCustomDateRange($event)"
            (stringDateOption)="selectStringDateRange($event)"
            [(dateRangeForm)]="dateRangeForm"
            [selectOptions]="selectOptions"
            [rangeOption]="true">
          </tidy-daterange>
        </div>
      </tidy-row>

      <div class="first-item">
        <div class="form-div">
          <form [formGroup]="searchForm" novalidate action="#">
            <tidy-row class="field-top-padding" style="padding-bottom: 0px">
              <tidy-input
                #searchInput
                class="search-input"
                [icon]="'assets/svg/search-outline.svg'"
                [activeIcon]="'assets/svg/search-tidy-green.svg'"
                [noBorder]="true"
                type="search"
                formControlName="search"
                [label]="'Search'"
                [form]="searchForm">
              </tidy-input>
            </tidy-row>
          </form>
        </div>
      </div>

      <tidy-alert *ngIf="history?.length == 0 && workflowsLoaded">
        <tidy-row>
          <tidy-text
            [body]="'No history in this range.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <ng-container *ngIf="workflowsLoaded">
        <tidy-card *ngFor="let run of history">
          <tidy-card-button (action)="goToWorkflowRunPage(run)">
            <tidy-row class="vertical-align-middle">
              <tidy-text
                [header]="run?.workflow_name">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="run?.status == 'failed' ? 'assets/img/block.svg' : 'assets/img/checkmark.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="run?.status | titlecase">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [helper]="getDate(run?.status_updated_at)">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="!workflowsLoaded && history?.length > 0">
        <tidy-card *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
          <tidy-card-button (action)="goToWorkflowRunPage(run)">
            <tidy-row class="vertical-align-middle" [alignLastItemRight]="true">
              <div style="width:20%">
                <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
              </div>
              <div style="width:15%; display: flex">
                <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
              </div>
              <div style="width:25%; margin-left:30px">
                <ion-skeleton-text animated style="width: 80%; height: 20px"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 20px"></ion-skeleton-text>
              </div>
              <div>
              </div>
            </tidy-row>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row class="extra-bottom-padding">
        <div style="
              background: white;
              border-radius: 25px;
              padding: 7px;
              display: block;
              box-shadow: 0 1px 6px #0000003d;
              font-size: 16px;
              width: fit-content;
              margin: auto;
              max-width: 80%">
          <ion-skeleton-text animated="true" style="width: 130px; margin: 10px"></ion-skeleton-text>
        </div>
      </tidy-row>

      <tidy-card *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
        <tidy-card-button (action)="goToWorkflowRunPage(run)">
          <tidy-row class="vertical-align-middle" [alignLastItemRight]="true">
            <div style="width:20%">
              <ion-skeleton-text animated style="width: 70%; height: 20px"></ion-skeleton-text>
            </div>
            <div style="width:15%; display: flex">
              <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 40px"></ion-skeleton-text>
            </div>
            <div style="width:25%; margin-left:30px">
              <ion-skeleton-text animated style="width: 80%; height: 20px"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 20px"></ion-skeleton-text>
            </div>
            <div>
            </div>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

    <mat-paginator
      style="background: none"
      *ngIf="history?.length > 0"
      [length]="allHistoryLength"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="selectPage($event)">
    </mat-paginator>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

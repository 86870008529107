import { Injectable, OnDestroy } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { AddressModel } from 'src/models/address.model';
import { ClientModel, ClientSettingsModel } from 'src/models/client.model';
import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { unitSynonyms } from 'src/shared/constants/unitSynonyms';
import { Addresses } from '../customer/addresses';
import { CurrentAddress } from '../addresses/current-address';
import { DateTime } from 'luxon'

@Injectable({
  providedIn: 'root'
})
export class Client implements OnDestroy {

  _customerInfo;
  intervalId;

  constructor(
    private httpClient: HttpClientCustom,
    private addresses: Addresses,
    private currentAddress: CurrentAddress,
    private storage: TidyStorage
  ) {
    const halfHour = 1800000;
    this.intervalId = setInterval(() => this.clearServiceInfo(), halfHour);
  }

  getCustomers(credentials) {
    const url = 'api/v1/customers';
    return this.httpClient.post(url, credentials);
  }

  updateCustomer(data, id) {
    this.clearServiceInfo();

    const url = `api/v1/customers/${id}`;
    return this.httpClient.put(url, data);
  }

  clearServiceInfo() {
    this._customerInfo = null;
  }

  async getClientInfo(): Promise<ClientModel> {
    const storedData = await this.storage.retrieve('me');
    if (storedData) {
      return storedData;
    }
    const url = 'api/v1/customer/me';
    const data = await this.httpClient.get(url);
    this.storage.save('me', data);
    localStorage.setItem('user_role', data.current_user.role);
    localStorage.setItem('customer_id', data.customer_account.id);
    if (data.customer_account.account_type === 'rental') {
      localStorage.setItem('airbnb', 'true');
    }
    return data;
  }

  async getCustomerInfo(id) {
    if (this._customerInfo) {
      return this._customerInfo;
    }
    const token = localStorage.getItem('tidy_token');
    const url = `api/v1/customers/${id}?access_token=${token}`;
    return this.httpClient.get(url).then(data => this._customerInfo = data);
  }

  async getAddresses(getFromBackend = false) {
    let getAddressesFromBackend = getFromBackend;
    if (!getAddressesFromBackend) {
      getAddressesFromBackend = localStorage.getItem('getAddressesFromBackend') == 'true';
      if (getAddressesFromBackend) {
        localStorage.setItem('getAddressesFromBackend', 'false');
      }
    }
    let response = await this.storage.retrieve('addresses');
    const lastRefreshOfCache = await this.storage.retrieve('lastRefreshOfAddressCache');
    const cacheTime = DateTime.fromISO(lastRefreshOfCache);
    const now = DateTime.now();
    const minutesSinceLastCache = now.diff(cacheTime, 'minutes').minutes;
    if (getAddressesFromBackend || !response || minutesSinceLastCache > 3) {
      response = await this.getAddressesFromBackend();
    }
    const hasMoreThanOneAddress = response?.length > 1 ? 'true' : 'false';
    localStorage.setItem('hasMoreThanOneAddress', hasMoreThanOneAddress);
    return response;
  }

  async getAddressesFromBackend() {
    const url = 'api/v1/customer/addresses?per_page=5000&fields=add_state,address1,address2,address_name,city,default_address_task_list_state,id,integration,latitude,longitude,region_id,state,timezone,zip,automatic_booking_enabled,key,name,booking_cancellation_strategy,booking_end_time_no_later_than,booking_start_time_no_earlier_than,required_team_service,description,id&includes=address_automatic_booking_setting';
    const response = await this.httpClient.get(url);
    if (response.length) {
      await this.storage.save('addresses', response);
      const lastRefreshOfCache = DateTime.now().toISO();
      await this.storage.save('lastRefreshOfAddressCache', lastRefreshOfCache);
    } else {
      localStorage.setItem('getAddressesFromBackend', 'true');
    }
    return response;
  }
  async getMoreDetailAddresses(getFromBackend = false) {
    let getAddressesFromBackend = getFromBackend;
    if (!getAddressesFromBackend) {
      getAddressesFromBackend = localStorage.getItem('getMoreDetailAddressesFromBackend') == 'true';
      if (getAddressesFromBackend) {
        localStorage.setItem('getMoreDetailAddressesFromBackend', 'false');
      }
    }
    let response = await this.storage.retrieve('moreDetailAddresses');
    const lastRefreshOfCache = await this.storage.retrieve('lastRefreshOfMoreDetailAddressCache');
    const cacheTime = DateTime.fromISO(lastRefreshOfCache);
    const now = DateTime.now();
    const minutesSinceLastCache = now.diff(cacheTime, 'minutes').minutes;
    if (getAddressesFromBackend || !response || minutesSinceLastCache > 3) {
      response = await this.getMoreDetailAddressesFromBackend();
    }
    let addresses = await this.storage.retrieve('addresses');
    if (!addresses) {
      addresses = await this.getAddressesFromBackend();
    }
    //combine the addresses array + moreDetailAddresses array
    let array = [];
    addresses = response.map((address) => {
      const index = addresses.map(item => item.id).indexOf(address.id);
      array.push({
        ...addresses[index],
        ...address
      });
    });
    this.storage.save('addresses', array);
    return array;
  }

  async getMoreDetailAddressesFromBackend() {
    const url = 'api/v1/customer/addresses?per_page=5000&fields=id,formatted_plan_with_weekdays_and_times,is_private,plan_frequency,plan_type,service_type,state,team,home_access,home_close,max_parking_cost,paid_parking,parking_notes,parking_pay_with,parking_spot,photo_notes&includes=plans';
    const response = await this.httpClient.get(url);
    if (response.length) {
      await this.storage.save('moreDetailAddresses', response);
      const lastRefreshOfCache = DateTime.now().toISO();
      await this.storage.save('lastRefreshOfMoreDetailAddressCache', lastRefreshOfCache);
    } else {
      localStorage.setItem('getMoreDetailAddressesFromBackend', 'true')
    }
    return response;
  }

  getJobHistory(hkId = null) {
    const params = hkId ? `?homekeeper_id=${hkId}` : '';
    const url = `api/v1/customer/cleanings/history${params}`;
    return this.httpClient.get(url);
  }

  async getClientSettings(): Promise<ClientSettingsModel> {
    const url = 'api/v1/customer/settings';
    const response = await this.httpClient.get(url);
    const phoneNumber = response?.profile?.phone;
    await this.storage.save('phoneNumber', phoneNumber);
    await this.storage.save('billingType', response?.billing?.charge?.type);
    await this.storage.save('showBilling', (response?.billing && response?.billing?.hide_billing_history === 'false'));
    return response;
  }

  async saveClientSettings(data) {
    this.clearServiceInfo();
    const url = 'api/v1/customer/settings';
    return await this.httpClient.put(url, data);
  }

  getWaterfallSettings() {
    const url = 'api/v1/customer/waterfall-settings?service_type_id=1';
    return this.httpClient.get(url);
  }

  updateWaterfallSettings(data) {
    const url = 'api/v1/customer/waterfall-settings';
    return this.httpClient.post(url, data);
  }

  getWaterfallNotificationHistory(notificationId) {
    const url = `api/v1/customer/waterfall-notification-histories/${notificationId}`;
    return this.httpClient.get(url);
  }

  getScheduleAssurance() {
    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/settings/reschedule`;
    return this.httpClient.get(url).then(data => {
      return { customerAddress: data };
    });
  }

  saveBackupTimes(times, saData) {
    saData.customerAddress.times = times;
    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/settings/reschedule`;
    return this.httpClient.put(url, {...saData.customerAddress});
  }

  getJobBackupTimes(bookingId) {
    const url = `api/v1/customer/bookings/${bookingId}/backup-times`;
    return this.httpClient.get(url);
  }

  saveJobBackupTimes(bookingId, times) {
    const params = {
      times: times
    }
    const url = `api/v1/customer/bookings/${bookingId}/backup-times`;
    return this.httpClient.post(url, params);
  }

  scheduleAssuranceDataFormat(newState, scheduleAssurance) {
    const data: any = {
      state: newState,
      plan: 'change_plan',
      times: scheduleAssurance.customerAddress.times
    };
    let backupTimes;
    if (scheduleAssurance.cleaning === 'any_homekeeper_customized_times' && newState === 'active') {
      backupTimes = true;
    } else if (scheduleAssurance.cleaning === 'any_homekeeper_same_cleaning' && newState === 'active') {
      backupTimes = false;
    } else if (scheduleAssurance.cleaning === 'preferred_homekeeper_customized_times' && newState === 'active') {
      backupTimes = true;
    } else if (scheduleAssurance === 'inactive') {
      backupTimes = false;
    }
    if (newState === 'active' && backupTimes) {
      data.cleaning = 'any_homekeeper_customized_times';
    } else if (newState === 'active' && !backupTimes) {
      data.cleaning = 'any_homekeeper_same_cleaning';
    } else if (newState === 'inactive' && backupTimes) {
      data.cleaning = 'preferred_homekeeper_customized_times';
    }
    return data
  }

  parseAddressList(addressList, includeAddAddress: boolean = false): TidySelectNumberValueModel[] {
    const parsedList = addressList.map(address => {
      return {
        viewValue: this.buildAddressName(address),
        value: address.id
      };
    });
    if (includeAddAddress) {
      parsedList.push({
        viewValue: 'Add Property',
        value: 0
      });
    }
    return parsedList;
  }

  buildAddressName(address) {
    if (!address) {
      return '';
    }
    if (address?.address_name) {
      return address.address_name;
    }
    const addressTwo = address.address2;
    const hasAddressTwo = addressTwo !== '' && addressTwo !== null;
    let hasUnitInName = false;
    if (hasAddressTwo) {
      unitSynonyms.map((synonym) => {
        if (addressTwo?.includes(synonym)) {
          hasUnitInName = true;
        }
      });
    }
    let formattedAddressTwo;
    if (hasAddressTwo && hasUnitInName) {
      formattedAddressTwo = addressTwo + ' ';
    } else if (hasAddressTwo && !hasUnitInName) {
      formattedAddressTwo = 'Unit ' + addressTwo + ' ';
    } else {
      formattedAddressTwo = '';
    }
    return (formattedAddressTwo + address.address1).slice(0, 28);
  }

  getSelectedAddressId(addresses): number {
    if (addresses.length === 0) {
      return null;
    }
    let selectedAddressId;
    const storageAddressId = localStorage.getItem('addressId');
    //TODO remove "parseFloat(storageAddressId) !== 0" once we refactor add-property page
    if (storageAddressId !== null && parseFloat(storageAddressId) !== 0 && !Number.isNaN(parseFloat(storageAddressId))) {
      selectedAddressId = parseFloat(storageAddressId);
    } else {
      selectedAddressId = addresses[0].id;
    }
    this.currentAddress.addressId = selectedAddressId;
    return selectedAddressId;
  }

  buyBundle() {
    const customerId = localStorage.getItem('customer_id');
    const data = {
      customer_id: customerId,
      amount: 50000,
      currency: 'usd',
      obs: 'bundle credit'
    }
    const url = `api/v1/customer/billing/buy-bundle`;
    return this.httpClient.post(url, data);
  }

  getApiKeys() {
    const url = `api/v1/customer/api-keys`;
    return this.httpClient.get(url);
  }

  addApiKey() {
    const url = `api/v1/customer/api-keys`;
    return this.httpClient.post(url, {});
  }

  deleteApiKey(id) {
    const url = `api/v1/customer/api-keys/${id}`;
    return this.httpClient.delete(url, {});
  }

  saveHomeData(data) {
    const url = 'api/v1/customer/signup/steps/home-size';
    return this.httpClient.post(url, data);
  }

  markNoShow(data): Promise<any> {
    const customerId = localStorage.getItem('customer_id');
    const url = `api/v1/customers/${customerId}/homekeeper-did-not-show-up`;
    return this.httpClient.post(url, data);
  }

  getBillingCredits(cleaningId) {
    const url = `api/v1/customer/cleanings/${cleaningId}/billing/credits-added`;
    return this.httpClient.get(url);
  }

  async getCustomerCharges(paymentMethodIds?: string[], statuses?: string[]) {
    let url = 'api/v1/customer/charges';
    // TODO: add filters in FE
    if (paymentMethodIds?.length > 0) {
      url += `?payment_method_ids=${paymentMethodIds.join(',')}`;
    }
    if (statuses?.length > 0) {
      url += `&status=${statuses.join(',')}`;
    }
    return this.httpClient.get(url);
  }

  getBillingData() {
    const url = 'api/v1/customer/billing/balance-history';
    return this.httpClient.get(url);
  }

  getBillingDataForCustomerTeam(customerAccountTeamId) {
    const url = `api/v1/customer/billing/balance-history?customer_account_team_id=${customerAccountTeamId}`;
    return this.httpClient.get(url);
  }

  getBillingDataFromJobId(jobId) {
    const url = `api/v1/customer/billing/balance-history?billing_account_from_job_id=${jobId}`;
    return this.httpClient.get(url);
  }

  billingRefund(data) {
    const url = `api/v1/customer/billing/refund`;
    return this.httpClient.post(url, data);
  }

  async getBillingHistory(addressId, paymentMethods) {
    const url = `api/v1/customer/billing/history?filters[address_ids]=${addressId}`;
    let result = await this.httpClient.get(url);
    result = this.mountRefundStatusAndPaymentMethod(result, paymentMethods);
    return result;
  }

  async getAllBillingHistory(paymentMethods) {
    let url = 'api/v1/customer/billing/history';
    let result = await this.httpClient.get(url);
    result = this.mountRefundStatusAndPaymentMethod(result, paymentMethods);
    return result;
  }

  mountRefundStatusAndPaymentMethod(result, paymentMethods) {
    return result.map((item) => {
      const total = 0;
      if (item.refunded === true) {
        item.refund_type = '(Refunded)';
      }
      item.details.forEach((detail) => {
        if (item.refunded === false && detail.debit_type === 'refund') {
          item.refund_type = '(Partially Refunded)';
        }
      });
      item.payment_method = paymentMethods.find((paymentMethod) => paymentMethod.id === item.payment_method?.id);
      return item;
    });
  }

  cancelAccount(){
    const url = 'api/v1/customer/cancel-account';
    return this.httpClient.delete(url);
  }

  cancelAccountCheck(){
    const url = '/api/v1/customer/cancel-account-check';
    return this.httpClient.get(url);
  }

  cancelAllCleanings(){
    const url = 'api/v1/customer/plans/cancel-all';
    return this.httpClient.delete(url);
  }

  getCleaningsHistory(hkId = null) {
    const params = hkId ? `?homekeeper_id=${hkId}` : '';
    const url = `api/v1/customer/cleanings/history${params}`;
    return this.httpClient.get(url);
  }

  async getCleaningsHistoryWithTotalRecords(
    page: number = 1,
    perPage: number = 10,
    filters: {
      from_date?: string;
      until_date?: string;
      address_ids?: number[];
    } = {}
  ): Promise<any> {
    const searchParams = new URLSearchParams();

    searchParams.append('page', page.toString());
    searchParams.append('per_page', perPage.toString());

    if (filters.address_ids && filters.address_ids.length > 0) {
      searchParams.append('filters[address_ids]', filters.address_ids.join(','));
    }
    if (filters.from_date && filters.from_date !== '') {
      searchParams.append('filters[from_date]', filters.from_date);
    }
    if (filters.until_date && filters.until_date !== '') {
      searchParams.append('filters[until_date]', filters.until_date);
    }

    const url = `api/v1/customer/cleanings/history?${searchParams.toString()}`;

    return this.httpClient.getFullRequest(url).then((response) => {
      return {
        body: response.body,
        totalRecords: response.headers.get('X-Total-Records')
      };
    });
  }

  getCleaningHistByAddress(cleaningsHistory, selection) {
    let resultData = cleaningsHistory;
    if (selection) {
      resultData = cleaningsHistory.filter(item => {
        return item.address_id === selection;
      });
    }

    return resultData;
  }

  async getCleaningBilling(jobId){
    const url = `api/v1/customer/cleanings/${jobId}/billing/info`;
    const result = await this.httpClient.get(url);
    result.details = result.details || [];
    result.ammountUsedSum = 0;
    result.ammountUsedSum = result.details.reduce((prev, initial) => {
      return prev + initial.amount_used
    }, 0);
    return result;
  }

  async getComunicationContacts(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/communication/contents`;
    const result = await  this.httpClient.get(url);
    return result.reverse();
  }

  requestWaiveFee(bookingId) {
    const url = `/api/v1/customer/bookings/${bookingId}/waive-cancellation-fee`;
    return this.httpClient.put(url, {});
  }

  getSelectableDatesAndTimes(addressId, data: { frequency: string, service_type_key: string, homekeeper_id: string }) {
    const urlSearchParams = new URLSearchParams();
    const allKeys = Object.keys(data);
    allKeys.filter(key => data[key]).forEach(key => {
      urlSearchParams.append(key, data[key])
    });
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/selectable-dates-and-times?${urlSearchParams}`;
    return this.httpClient.get(url);
  }

  requestableDates() {
    const now = DateTime.now();
    const dates = Array(28).fill(null).map((value, index) => {
      const daysSum = {days: index};
      const date = now.plus(daysSum);
      return {
        displayFormat: date.toFormat('EEEE M/d'),
        date: date.toFormat('yyyy-MM-dd')
      }
    });
    return dates;
  }

  // Creates a new metadata when the key doesn't exist
  // Updates the metadata when the key exists
  addMetadata(payload) {
    const url = 'api/v1/customer/metadata';
    return this.httpClient.post(url, payload);
  }

  getMetadata(keys: string[]) {
    const url = `api/v1/customer/metadata?keys[]=${keys.join(',')}`;
    return this.httpClient.get(url);
  }

  requestUpcomingFeature(payload) {
    const url = `api/v1/customer/feature-requests`;
    return this.httpClient.post(url, payload);
  }

  validateReferralCode(referralCode: string) {
    const payload = {
      referral_code: referralCode
    }
    const url = 'api/v1/customer/referral-code/check';
    return this.httpClient.post(url, payload);
  }

  validateGiftCode(giftCode: string, customerId?: string | number) {
    const payload = {
      gift_code: giftCode,
      customer_id: customerId
    }
    const url = `api/v1/customer/billing/gift/validate`;
    return this.httpClient.post(url, payload);
  }

  getCustomSBFlowData(friendlyId) {
    const url = `api/v1/customer/signup/gift-codes/${friendlyId}`;
    return this.httpClient.get(url);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  async saveNewAddressesOrder(addressesIds: string[]) {
    const url = `api/v1/customer/addresses/set-addresses-display-order`;
    return await this.httpClient.put(url, { address_ids_in_order: addressesIds });
  }

  checkIfAllowsNewPros(prosData) {
    let allowsNewPros = false;
    prosData.approved.map((item) => {
      if (item.object_type == 'dynamic_sa') {
        allowsNewPros = true;
      }
    });
    prosData.favorited.map((item) => {
      if (item.object_type == 'dynamic_sa') {
        allowsNewPros = true;
      }
    });
    return allowsNewPros;
  }

  redeemGiftCode(giftCode) {
    const payload = {
      gift_code: giftCode,
    }
    const url = 'api/v1/customer/billing/gift/redeem';
    return this.httpClient.post(url, payload);
  }

  getAddFirstAddressRedirectPage(accType): string {
    if (accType == 'rental') {
      return 'dashboard';
    } else if (accType == 'company') {
      return 'add-first-property/company';
    } else {
      return 'add-first-property/normal';
    }
  }

  bulkImportItem(payload) {
    const url = 'api/v1/customer/import-data';
    return this.httpClient.post(url, payload, 60000);
  }

  bulkExportItem(payload) {
    const url = `api/v1/customer/export-data?scope=${payload.scope}&file_format=csv`;
    return this.httpClient.get(url, {}, 120000);
  }

}

import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Me } from 'src/providers/me/me';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

@Component({
  selector: 'subscription-modal',
  templateUrl: 'subscription-modal.html',
})

export class SubscriptionModalComponent {
  isMobileResolution = false;

  constructor(
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    private me: Me,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    ) {
    this.isMobileResolution = window.innerWidth <= 870;
  }

  async addTidyPlus() {
    const tidyPlusSubscription = await this.storage.retrieve('tidyPlusSubscription');
    this.storage.save('currentSubscription', tidyPlusSubscription);
    localStorage.setItem('hasNonTrialPaidSubscription', 'true');
    localStorage.setItem('isUpdatingSubscription', 'true');
    this.dismissModal();
  }

  async noThanks() {
    this.storage.delete('currentSubscription');
    localStorage.setItem('hasNonTrialPaidSubscription', 'false');
    localStorage.setItem('isUpdatingSubscription', 'false');
    this.dismissModal();
  }

  goToViewSubscriptions() {
    const params = {
      isBookingJob: true,
      nextBookingPage: 'book-job'
    }
    this.navCtrl.navigateForward('select-subscription', params);
    this.dismissModal();
  }

  dismissModal(): void {
    this.dialog?.closeAll();
    this.modalCtrl.getTop().then((res) => {
      if (res) {
        this.modalCtrl.dismiss();
      }
    });
  }

}
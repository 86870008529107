<form [formGroup]="form" novalidate action="#">
  <div class="sidebar-search-row" style="margin-bottom: 10px">
    <tidy-search-input
      formControlName="search"
      [placeholder]="'Search'"
      [icon]="'assets/svg/search-outline.svg'">
    </tidy-search-input>
    <div class="header-button primary" (click)="addItem()">
      <tidy-image
        [src]="'assets/img/add-button-white.svg'"
        style="height: 12px;">
      </tidy-image>
      <tidy-text
        [headerButton]="buttonTitle"
        class="white">
      </tidy-text>
    </div>
  </div>
</form>

<tidy-button
  class="secondary desktop-nav-button"
  [image]="'assets/icon/filter-white.png'"
  [action]="openDateRange.bind(this)"
  [text]="'Date'"
  [smallBody]="true">
</tidy-button>

<!-- NOT DISPLAYED TRIGGRED BY BUTTON fn openDateRange -->
<div class="concierge-daterange-container">
  <tidy-daterange
    #daterangefilter
    id="daterangefilter"
    class="daterange-custom-input"
    [label]="'Date'"
    (customDateOption)="selectCustomDateRange($event)"
    (stringDateOption)="selectStringDateRange($event)"
    [(dateRangeForm)]="dateRangeForm"
    [selectOptions]="selectOptions"
    [rangeOption]="true"
    style="width: 10px;"
    [appearance]="'outline'">
  </tidy-daterange>
</div>

<div style="margin-bottom: 5px">
  <ng-container *ngFor="let chip of chips">
    <ion-chip class="filter-chip">
      <ion-label>{{ chip.displayParentName | titlecase }}: {{ chip.name | titlecase }}</ion-label>
      <ion-icon name="close-circle" (click)="removeChip(chip)"></ion-icon>
    </ion-chip>
  </ng-container>
</div>

<mat-menu #menu="matMenu" class="filter-menu">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
        <mat-checkbox
          class="checkbox"
          [checked]="getNodeChecked(node)"
          (click)="$event.stopPropagation()"
          (change)="checkNode(node, $event)">
          {{ node.name | titlecase }}
        </mat-checkbox>
      </button>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <button
        class="node-button-padding"
        mat-menu-item
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
        {{ node.name | titlecase }}
      </button>
    </mat-tree-node>
  </mat-tree>
  <mat-tree >
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-menu-item matTreeNodeToggle class="tree-node-padding">
        <mat-checkbox
          class="checkbox"
          [checked]="getNodeChecked(node)"
          (click)="$event.stopPropagation()"
          (change)="checkNode(node, $event)">
          {{ node.name | titlecase }}
        </mat-checkbox>
      </button>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <button
        class="node-button-padding"
        mat-menu-item
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
        {{ node.name | titlecase }}
      </button>
    </mat-tree-node>
  </mat-tree>
</mat-menu>


import { Util } from 'src/shared/util/util';
import { Capacitor } from '@capacitor/core';
import { HttpClientCustom } from '../custom/http-client';
import { Injectable } from '@angular/core';
import {
  MediaCapture,
  MediaFile,
  CaptureError,
  CaptureVideoOptions,
} from '@awesome-cordova-plugins/media-capture/ngx';
import { Aws, S3UploadResponse } from '../aws/aws';
import { Platform } from '@ionic/angular';
import { Logger } from '../logger';
import { Photo } from '@capacitor/camera';
import { CameraProvider } from 'src/shared/providers/camera/camera';

interface MediaData {
  media_url: string;
  media_format: 'image' | 'video';
  description?: string;
  room_or_group?: {
    id: string;
    type: 'room' | 'group';
  };
}

@Injectable({
  providedIn: 'root',
})
export class JobMediasProvider {
  constructor(
    private http: HttpClientCustom,
    private mediaCapture: MediaCapture,
    private aws: Aws,
    private platform: Platform,
    private logger: Logger,
    private util: Util,
    private camera: CameraProvider,
  ) {}

  saveJobMedias(jobId: number, mediasData: MediaData[]) {
    const payload = {
      job_id: jobId,
      job_medias: mediasData,
    };
    return this.http.post('/api/v1/customer/job-medias', payload);
  }

  deleteJobMedia(mediaId: string) {
    return this.http.delete(`/api/v1/customer/job-medias/${mediaId}`);
  }

  getJobMedias(jobIds: number[]) {
    return this.http.get(
      `api/v1/customer/job-medias/?job_ids=${jobIds.join(',')}`
    );
  }

  async takeBeforeAfterVideo(videoType: string, jobId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let options: CaptureVideoOptions = { limit: 1, duration: 240 };
      await this.mediaCapture.captureVideo(options).then(
        (data: MediaFile[]) =>
          this.dispatchNativeVideo(data, videoType, jobId)
            .then((data) => resolve(data))
            .catch((err) => reject(err)),
        (err: CaptureError) => reject(err)
      );
    });
  }

  async dispatchNativeVideo(
    data: MediaFile[],
    videoType: string,
    jobId: number
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const filePath = this.platform.is('ios')
          ? `file://${data[0].fullPath}`
          : data[0].fullPath;
        const mediaData = await this.fetchNativeVideoFileData(filePath);
        const parsedJobMedia = await this.uploadAndSaveVideo(
          mediaData,
          jobId,
          videoType
        );
        resolve(parsedJobMedia);
      } catch (err) {
        this.logger.log('dispatch-video', err);
        reject(err);
      }
    });
  }

  async uploadAndSaveVideo(
    mediaData: any,
    jobId: number,
    videoType?: string,
    file?: any,
    exif?: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.util.showInfo('Your video(s) are being uploaded. This may take a while.');
        const randomHash =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        const s3ObjectUrl = `jobs/${jobId}/job-medias/${randomHash}.mp4`;
        const mediaDataToBlob = mediaData?.path
          ? await this.fetchNativeVideoFileData(mediaData?.path)
          : mediaData;
        const s3Object = await this.aws.saveJobMediaToAws(
          mediaDataToBlob,
          'video',
          s3ObjectUrl,
          file,
          exif
        );
        const parsedJobMedia = this.getParsedJobMedia(
          s3Object,
          'video',
          videoType
        );
        this.util.showSuccess('Your video(s) have been uploaded.');
        resolve(parsedJobMedia);
      } catch (err) {
        this.util.showError('An error occurred while uploading your video(s).');
        console.error('error on trying to take/upload file:', err);
        reject(err);
      }
    });
  }

  async saveBeforeAfterPhoto(
    base64Photo: string,
    jobId: number,
    photoType?: string,
    file?: any,
    exif?: any
  ): Promise<any> {
    this.util.showInfo('Your photo(s) are being uploaded. This may take a while.');
    return new Promise(async (resolve, reject) => {
      try {
        if (base64Photo.includes('heic')) {
          base64Photo = base64Photo.replace('heic', 'jpeg');
        }
        base64Photo = await this.camera.resizeImage(base64Photo);
        const photo: any = { base64String: base64Photo };
        const randomHash =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        const objKey = `jobs/${jobId}/job-medias/${randomHash}.jpeg`;
        const s3Object = await this.awsPhotoUpload(photo, objKey, file, exif);

        const parsedJobMedia = this.getParsedJobMedia(
          s3Object,
          'photo',
          photoType
        );
        this.util.showSuccess('Your photo(s) have been uploaded.');
        resolve(parsedJobMedia);
      } catch (error) {
        this.util.showError('An error occurred while uploading your photo(s).');
        reject(error);
      }
    });
  }

  getParsedJobMedia(
    s3Object: S3UploadResponse,
    mediaFormat: string,
    mediaType?: string
  ) {
    const parsedJobMedia = {
      media_url: s3Object.Location,
      media_format: mediaFormat,
      category: mediaType,
    };
    return parsedJobMedia;
  }

  private awsPhotoUpload(
    cameraPhoto: Photo,
    objKey: string,
    file?: any,
    exif?: any
  ): Promise<S3UploadResponse> {
    return this.aws.uploadImageToS3(cameraPhoto.base64String, objKey, file, exif);
  }

  private async fetchNativeVideoFileData(filePath: string): Promise<Blob> {
    const videoPath = Capacitor.convertFileSrc(filePath);
    return fetch(videoPath).then((r) => r.blob());
  }
}

import { Component, ViewChild, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Util } from './../../../shared/util/util';
import { ActivatedRoute } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { DynamicComponentProvider } from 'src/shared/providers/dynamic-component/dynamic-component';
import { Me } from 'src/providers/me/me';
import { Pros } from 'src/providers/pros/pros';
import { ReportIssue } from 'src/providers/report-issue/report-issue';
import { Schedule } from 'src/providers/schedule/schedule';
import { ToDos } from 'src/providers/to-dos/to-dos';

import { Loading } from 'src/shared/components/loading/loading';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { FeedbackCleaning } from 'src/providers/feedback/feedback';
import { Client } from 'src/providers/client/client';
import { Tips } from 'src/providers/tips/tips';

import { MapPopup } from 'src/pages/dashboard/map-popup/map-popup';
import { popupOptions } from 'src/pages/dashboard/map-popup/popup-opts';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { WindowService } from 'src/shared/providers/window.service';
import { PastJobPdfParams, PastJobPdfProvider } from '../past-job-pdf/past-job-pdf';
import { JobActivityPage } from 'src/pages/schedule/job-activity/job-activity';
import { InputToDosPage } from 'src/pages/more/input-to-dos/input-to-dos';
import { Capacitor } from '@capacitor/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CapitalizePipe } from 'src/shared/pipes/capitalize.pipe';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { CameraProvider } from 'src/shared/providers/camera/camera';

declare const mapboxgl: any;

import { EditTipPage } from 'src/pages/more/past-job/edit-tip/edit-tip';
import { ProDidNotShowUpPage } from 'src/pages/pro-did-not-show-up/pro-did-not-show-up';
import { LostItemPage } from 'src/pages/more/past-job/lost-item/lost-item';
import { ShareJobPage } from 'src/pages/share-job/share-job';
import moment from 'moment';

@Component({
  selector: 'past-job',
  templateUrl: 'past-job.html',
  styleUrls: ['past-job.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PastJobPage implements OnInit {

  activity: any;
  address: any;
  addressResponse: any;
  bookingNote: any;
  bookingFormAnswers: any;
  backPage: string;
  clientName: string;
  displayAddress: any;
  errorMessage: string;
  isFullWidth: boolean;
  job: any;
  rooms: any;
  isPrivate: boolean;
  cleaningId: number;
  feedback: any;
  homekeeperJobIds: number;
  billingInfo: any;
  hasPro: boolean;
  totalTipsValue: number = 0;
  timezone: any;
  allTips: any;
  tips: any;
  parkingReimbursements: any;
  loggedIssuesResponse: any;
  unresolvedLoggedIssues: any;
  mapInstance: any;
  messages: any;
  dialogParams: any;
  isRightSideContent = true;
  beforePhotos: any[] = [];
  afterPhotos: any[] = [];
  isNativeMobile = Capacitor.isNativePlatform();
  mediaTypes = [
    {
      value: 'photo',
      viewValue: 'Photo'
    },
    {
      value: 'video',
      viewValue: 'Video'
    }
  ];
  jobMediaForm: UntypedFormGroup;
  isAddBeforePhotos = false;
  isAddAfterPhotos = false;
  @ViewChild('map', {static: false}) mapElement: ElementRef;
  panelClosedSubscription: Subscription;
  loaded: boolean;
  jobEvents: any[];
  form: UntypedFormGroup;
  isInternalComment = false;
  selectedMessageType: any;
  messageSelectItems: any[] = [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public reply',
        icon: 'assets/svg/arrow-redo-outline.svg'
    }
  ];
  submitted = false;

  constructor(
    public communication: Communication,
    private dynamicComponentService: DynamicComponentProvider,
    private modalCtrl: ModalController,
    public me: Me,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private route: ActivatedRoute,
    private schedule: Schedule,
    public toDos: ToDos,
    private client: Client,
    private feedbackCleaning: FeedbackCleaning,
    private reportIssue: ReportIssue,
    private tipsProvider: Tips,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService,
    private pastJobPdfProvider: PastJobPdfProvider,
    private fb: UntypedFormBuilder,
    private jobMediasProvider: JobMediasProvider,
    private util: Util,
    private cameraProvider: CameraProvider,
  ) {
    this.jobMediaForm = this.fb.group({
      mediaType: ['photo'],
    });
    this.form = this.fb.group({
      message: ['', Validators.required],
      messageType: ['public_reply']
    });
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (!this.isRightSideContent) {
      this.isFullWidth = window?.innerWidth > 1000;
    }
  }

  async ngOnInit() {
    this.jobMediaForm.patchValue({ mediaType: 'photo' });
    this.beforePhotos = [];
    this.afterPhotos = [];
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.isFullWidth = false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Past Job');
    this.loadData();
    this.isFullWidth = window?.innerWidth > 1000;
  }

  addBeforePhotos() {
    this.isAddBeforePhotos = true;
  }

  addAfterPhotos() {
    this.isAddAfterPhotos = true;
  }

  @Loading('', true)
  async loadData() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.initializeComponentState();
      await this.loadInitialJobData();
      this.setProStatus();
      if (this.hasPro) {
        await this.loadProRelatedData();
      }
      this.loadLoggedIssues();
      this.loadRoomsData();
      this.loadClientAndAddressData();
      this.loadBookingDetails();
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
    } catch (err) {
      console.error(err);
    }
  }

  async loadJobMedias(): Promise<any> {
    const jobMedias = await this.jobMediasProvider.getJobMedias([this.cleaningId]);
    const beforePhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'before_job');
    const afterPhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'after_job');
    return {
      beforePhotos,
      afterPhotos,
    };
  }

  async getParsedJobMediasWithMetadata(jobMedias: any[]) {
    const homekeepers = this.job.homekeepers;
    jobMedias = await this.toDos.processFilesAndReturn(jobMedias);
    return jobMedias.map((jobMedia) => {
      const homekeeper = homekeepers.find((homekeeper) => homekeeper.id === jobMedia.uploaded_by_id);
      return {
        ...jobMedia,
        mediaType: jobMedia.media_format,
        url: jobMedia.media_url,
        uploadedBy: jobMedia.uploaded_by_type === 'User' ? homekeeper?.name : null,
      };
    });
  }

  initializeComponentState() {
    this.loaded = false;
    this.cleaningId = +this.route.snapshot.paramMap.get('cleaningId') || this.dialogParams?.jobId;
    console.log(this.cleaningId)
    this.backPage = this.navCtrl.getParam('backPage') || localStorage.getItem('pastJobBackPage') || 'past-jobs';
  }

  async loadInitialJobData() {
    const [job, rooms, billingInfo] = await this.getJobInfo();
    this.job = job;
    this.rooms = rooms;
    this.billingInfo = billingInfo;
    this.loadDisplayAddress();
    this.getJobEvents();
  }

  async getJobEvents() {
    this.jobEvents = await this.schedule.getJobEvents(this.job.booking.id);
  }

  async loadProRelatedData() {
    this.feedback = await this.getFeedback();
    this.setNeedHelpData();
    await this.refreshTips();
    this.filterTips();
  }

  async loadLoggedIssues() {
    this.loggedIssuesResponse = await this.reportIssue.getIssueReportsByAddress([this.job.job.address_id]);
    this.unresolvedLoggedIssues = this.parseUnresolvedLoggedIssues();
  }

  async loadDisplayAddress() {
    this.displayAddress = this.job.address?.address_name || (this.job.address.address1 + ' ' + this.job.address.address2);
  }

  async loadRoomsData() {
    this.rooms = this.toDos.buildRoomIcons(this.rooms);
    this.rooms = await this.toDos.getMetadataFromFiles(this.rooms);
    this.activity = await this.buildActivityArrays();
    await this.markMessagesAsRead();
  }

  async loadClientAndAddressData() {
    this.clientName = await this.getClientName();
    this.addressResponse = await this.client.getAddresses();
    this.address = this.getAddress(this.addressResponse);
    this.timezone = this.address.timezone;
    this.buildMap();
  }

  async loadBookingDetails() {
    this.bookingNote = await this.schedule.getBookingNotesForJob(this.job.booking.id);
    this.bookingFormAnswers = await this.schedule.getBookingFormAnswers(this.job.booking.id);
  }

  async buildMap() {
    try {
      if (!this.mapElement) {
        return setTimeout(() => this.buildMap(), 0);
      }
      this.mapInstance = this.createMap();
      const moments = this.getMoments();
      this.addMomentsToMap(moments, this.mapInstance);
      this.addAddressToMap(this.mapInstance);
      this.setMapBounds(this.mapInstance, moments);
      setTimeout(() => {
        this.mapInstance.resize();
      }, 100);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  createMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrdHVoNHc1bTIwYjYycHE1NmhjdzVtdzMifQ.OIqKrdlDh4fJH0g0MO1zLg';
    return new mapboxgl.Map({
      container: `map${this.job.job.id}`,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      maxZoom: 16,
      preserveDrawingBuffer: true,
    });
  }

  getMoments() {
    const moments: any = [];
    this.job.activity_timelines.map((activityTimeline) => {
      activityTimeline.activity_timeline_items.map((moment) => {
        if (moment.type == 'cleaning_update') {
          moments.push({
            latitude: moment.data.latitude,
            longitude: moment.data.longitude,
            body: '',
            image: this.schedule.getMomentImage(moment.data.data.type),
            mapIcon: this.schedule.getMomentImage(moment.data.data.type),
            header: moment.data.text
          });
        }
      });
    });
    return moments;
  }

  addMomentsToMap(moments, map) {
    moments.forEach((moment, i) => {
      if (moment?.latitude) {
        const popupContent = this.dynamicComponentService.injectComponent(
          MapPopup, x => x.data = {
            image: moment.image,
            body: moment.body,
            header: moment.header,
            learnMore: ''
          }
        );
        this.createPopupAndMarker(map, popupContent, moment.mapIcon, moment);
      }
    });
  }

  addAddressToMap(map) {
    const popupContent = this.dynamicComponentService.injectComponent(
      MapPopup, x => x.data = {
        image: 'assets/img/house.svg',
        body: '',
        header: this.address?.address_name || this.address.address1,
        learnMore: ''
      }
    );
    const addressIcon = 'assets/img/map-home-blue.svg';
    this.createPopupAndMarker(map, popupContent, addressIcon, this.address);
  }

  createPopupAndMarker(map, popupContent, mapIcon, location, customOpts = null) {
    const popup = new mapboxgl.Popup({...popupOptions, ...customOpts}).setDOMContent(popupContent);
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="${mapIcon}" style="width: 40px; height: 40px; padding-top: 10px;"></image>`;
    this.createMarkerInstance({map, el, longLat: [ location.longitude, location.latitude ]}, popup);
  }

  createMarkerInstance(obj, popup) {
    const {el, longLat, map} = obj;
    const marker = new mapboxgl.Marker(el);
    marker.getElement().addEventListener('click', () => {
      const flyToCoords = [...longLat];
      const getZoom = map.getZoom() ;
      const zoomOut = getZoom < 0;
      const lgnSum = zoomOut ? 200 / (5 * getZoom) : 50 / (2 ** getZoom);
      if (flyToCoords[1] + lgnSum > 90) {
        flyToCoords[1] = 90;
      } else if ((flyToCoords[1] + lgnSum < -90)) {
        flyToCoords[1] = -90;
      } else {
        flyToCoords[1] += lgnSum;
      }
      map.flyTo({
        center: flyToCoords
      })
    });
    marker.setLngLat(longLat).setPopup(popup).addTo(map);
  }

  setMapBounds(map, moments) {
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([this.address.longitude, this.address.latitude]);
    moments.forEach((moment) => {
      if (moment.latitude) {
        bounds.extend([moment.longitude, moment.latitude]);
      }
    });
    map.fitBounds(bounds, { padding: 50, duration: 0 });
  }

  async getClientName() {
    const me = await this.me.load();
    return me.customer_account.name;
  }

  getAddress(addressResponse) {
    return addressResponse.find((address) => address.id == this.job.address.id);
  }

  async buildActivityArrays() {
    let activity = [];
    this.messages = await this.communication.getJobMessages([this.cleaningId]);
    this.job.homekeeper_jobs?.map((homekeeperJob) => {
      activity.push({
        pro: this.job.homekeepers.find((homekeeper) => homekeeper.id == homekeeperJob.user_id),
        messages: this.parseActivity(this.messages, homekeeperJob.id)
      });
    });
    return activity;
  }

  parseActivity(messages, homekeeperJobId) {
    let activity = [];
    this.job.activity_timelines.map((timeline) => {
      if (timeline.homekeeper_job_id == homekeeperJobId) {
        const homekeeperJobMessages = messages.filter(
          (message) =>
            message.metadata?.homekeeper_job_id == homekeeperJobId
        );
        let updates = timeline.activity_timeline_items.filter(
          (item) => item.type === 'cleaning_update'
        );
        activity = this.concatenateMessagesAndUpdates(
          updates,
          homekeeperJobMessages
        );
      }
    });
    return activity;
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates.map((update) => {
      update['sent_at'] = update.data.created_at;
    });
    if (updates.length) {
      const activity = updates.concat(messages);
      activity.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return activity;
    } else {
      messages.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return messages;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.activity.map((pro) => {
      pro.messages.map((message, index) => {
        if (index < 3 && !message?.is_read && message?.from_member?.type !== 'CustomerMember' && message?.type !== 'cleaning_update' && message?.chat_room_key) {
          array.push((message?.chat_room_key));
        }
      })
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  getHomekeeperJob(homekeeperId) {
    return this.job.homekeeper_jobs.find(pro => pro.user_id == homekeeperId);
  }

  async getJobInfo(){
    const job = this.navCtrl.getParam('job') || this.schedule.getJobDetail(this.cleaningId);
    const rooms = this.toDos.getToDosForPastJob(this.cleaningId);
    const billingInfo = this.client.getCleaningBilling(this.cleaningId);
    return await Promise.all([ job, rooms, billingInfo ])
  }

  async refreshTips() {
    this.totalTipsValue = 0;
    this.allTips = await this.tipsProvider.getCleaningTips(this.cleaningId);
    this.allTips.forEach(tip => {
      this.totalTipsValue += tip.amount;
      tip.proName = this.getProName(tip.homekeeper_job_id);
    });
  }

  getProName(ProJobId) {
    const proJobData = this.job.homekeeper_jobs.find(item => {
      return item.id == ProJobId;
    });
    const proData = this.job.homekeepers.find(item => {
      return item.id == proJobData?.user_id;
    });
    if (!proData) {
      return '';
    }
    return `${proData.first_name} ${proData.last_name[0]}.`;
  }

  async filterTips() {
    this.tips = this.allTips.filter(e => e.category === 'tip');
    this.parkingReimbursements = this.allTips.filter(e => e.category === 'parking_reimbursement');
  }

  newTip(homekeeperJobId, proName){
    const params = {
      proName,
      cleaningId: this.cleaningId,
      homekeeperJobId: homekeeperJobId
    }
    this.goToPage(`edit-tip/${this.cleaningId}/${homekeeperJobId}`, params, EditTipPage);
  }

  setProStatus(){
    if(this.job.homekeepers.length == 0 && this.job.last_inactive_homekeepers.length == 0){
      this.hasPro = false;
    } else {
      if (this.job.homekeepers.length == 0 && this.job.last_inactive_homekeepers.length != 0){
        this.job.homekeepers = this.job.last_inactive_homekeepers;
        this.job.homekeeper_jobs = [this.job.last_inactive_homekeeper_job];
      }
      this.hasPro = true;
    }
  }

  @Loading('', true)
  async reloadBilling() {
    await this.refreshTips();
    location.reload();
  }

  async getFeedback() {
    const feedback = [
      {
        data: await this.feedbackCleaning.getFeedbackForCleaning(this.cleaningId, this.job.homekeeper_jobs[0].id),
        homekeeperJobId: this.job.homekeeper_jobs[0].id
      }
    ];
    if (this.job.homekeeper_jobs.length > 1) {
      feedback.push({
        data: await this.feedbackCleaning.getFeedbackForCleaning(this.cleaningId, this.job.homekeeper_jobs[1].id),
        homekeeperJobId: this.job.homekeeper_jobs[1].id
      });
    }
    return feedback;
  }

  setNeedHelpData () {
    this.homekeeperJobIds = this.job.homekeeper_jobs.map(item => {
      return item.id;
    });
  }

  async waiveFee(bookingId) {
    try {
      await this.client.requestWaiveFee(bookingId);
      this.waiveFeeResults('Fee Waived');
    } catch (err) {
      this.waiveFeeResults('Unable to Waive Fee');
    }
  }

  waiveFeeResults(results) {
    const params = {
      header: results,
      body: 'You can waive up to 2 cancellation fees every 10 completed cleanings. Must be within 90 days of the fee. Please click "Get Help" on the charge if you have any questions.',
      buttonText: 'Ok',
      buttonRoute: `past-job/${this.cleaningId}`
    };
    this.rightSidePanelService.navigateTo('success', params);
  }

  markNoShow(proName, hkId) {
    const params = {
      proName,
      hkId,
      jobId: this.cleaningId
    }
    this.goToPage('pro-did-not-show-up', params, ProDidNotShowUpPage);
  }

  missingItem() {
    const params = {
      address: this.displayAddress,
      jobIds: this.homekeeperJobIds,
      cleaningId: this.cleaningId
    }
    this.goToPage(`lost-item/${this.cleaningId}`, params, LostItemPage);
  }

  goToTasks() {
    this.navCtrl.navigateForward('tasks');
  }

  parseUnresolvedLoggedIssues() {
    return this.loggedIssuesResponse.filter((item) => item.status && item.status !== 'done')
  }

  async goToJobActivityPage(activity) {
    const isPastJob = true;
    const isPrivateJob = false;
    const params = {
      messages: activity.messages,
      job: this.job,
      pro: activity.pro,
      card: this.job,
      categoryImage: this.job.booking.bookable_service.icon_url,
      address: this.job.address,
      showSendMessage: false,
      isPastJob,
      isPrivateJob,
      addressId: this.job.address.id,
      jobId: this.job.job.id,
      proId: activity.pro.id
    }
    const url = `job-activity/${this.job.address.id}/${this.job.job.id}/${activity.pro.id}/${isPastJob}/${isPrivateJob}`
    this.goToPage(url, params, JobActivityPage);
  }

  getDate(date) {
    if (!date.includes('UTC')) {
      date += ' UTC';
    }
    return LuxonDateTime.fromSQL(date).setZone(this.job.address.timezone).toRelative();
  }

  hasBeforeAfterPhotos(array) {
    return array.some(item => item.media_format == 'photo');
  }

  ionViewDidLeave() {
    this.mapInstance?.remove();
  }

  goBack() {
    this.navCtrl.navigateRoot(this.backPage);
  }

  printPdf(): void {
    const params: PastJobPdfParams = {
      job: this.job,
      pro: this.job?.homekeepers,
      rooms: this.rooms,
      invoice: null,
      proReportedDuration: null,
      fromStartDuration: null,
      tips: this.tips || [],
      parkingReimbursements: this.parkingReimbursements || [],
      billingInfo: this.billingInfo,
      activities: this.activity,
      messages: this.messages,
      jobId: this.cleaningId,
      totalTipsValue: this.totalTipsValue,
      address: this.address,
      beforePhotos: this.beforePhotos,
      afterPhotos: this.afterPhotos
    }
    this.pastJobPdfProvider.exportToPDF(params);
  }

  async takeVideo(categoryType: string) {
    this.errorMessage = '';
    try {
      const parsedJobMedia = await this.jobMediasProvider.takeBeforeAfterVideo(categoryType, this.cleaningId);
      await this.jobMediasProvider.saveJobMedias(this.cleaningId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      this.util.showError('Error taking video. Please try again.', err);
      console.error('error on trying to take/upload file:', err);
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  async takePhoto(categoryType: string) {
    this.cameraProvider.getImg().then(async (photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto = photo?.dataUrl && !photo?.base64String ? photo?.dataUrl : `data:image/jpeg;base64,${photo.base64String}`;
      const newPhoto = { url: parsedPhoto, mediaType: 'photo' };
      const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(newPhoto.url, this.cleaningId, categoryType);
      await this.jobMediasProvider.saveJobMedias(this.cleaningId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    });
  }

  async uploadVideo(videos: any[], categoryType: string) {
    try {
      const parsedJobMedias = [];
      await Promise.all(
        videos.map(async (video) => {
          const parsedJobMedia = await this.jobMediasProvider.uploadAndSaveVideo(
            video.file,
            this.cleaningId,
            categoryType,
            video.file
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.cleaningId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading video. Please try again.', err);
    }
  }

  async uploadPhoto(photos: any[], categoryType: string) {
    const parsedJobMedias = [];
    try {
      await Promise.all(
        photos.map(async (photo) => {
          const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(
            photo.url,
            this.cleaningId,
            categoryType,
            photo?.file,
            photo?.exif
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.cleaningId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading photo. Please try again.', err);
    }
  }

  async removePhoto(image, index, categoryType) {
    const params: ConfirmPageParamsModel = {
      title: image?.mediaType == 'photo' ? 'Remove photo?' : 'Remove video?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove ',
      confirmAction: this.confirmRemovePhoto.bind(this, image, index, categoryType)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmRemovePhoto(image, index, categoryType) {
    try {
      await this.jobMediasProvider.deleteJobMedia(image.id);
      if (categoryType == 'before_job') {
        this.beforePhotos.splice(index, 1);
      } else {
        this.afterPhotos.splice(index, 1);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error removing photo. Please try again.', err);
    }
    this.modalCtrl.dismiss();
  }

  goToEditToDos() {
    const params = {
      rooms: this.rooms,
      nextPage: `past-job/${this.job.job.id}`,
      jobId: this.job.job.id
    }
    this.goToPage('input-to-dos', params, InputToDosPage);
  }

  goToShareJobPage() {
    const params = {
      job: {
        template_data: this.job
      },
      addressName: this.address.address_name,
      isPastJobLink: true
    };
    const url = 'share-job';
    const component = ShareJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);

    this.goToPage(url, params, component);
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.loadData();
      });
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }

  onFeedbackPanelClosed() {
    this.loadData();
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.job.homekeeper_jobs[0].id
        }
      },
      message: {
        text: this.form.value.message,
        files: attachments,
        is_internal: this.isInternalComment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.activity = await this.buildActivityArrays();
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  hasPassed14DaysFromJobStartDate() {
    const diff = moment().diff(moment(this.job.job.date));
    const duration = moment.duration(diff);
    return (duration.days() > 14);
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { HttpClientCustom } from '../custom/http-client';

import { GuestReservationSource, GuestReservationSourceType, GuestReservation, NewGuestReservationSource, CreateGuestReservation, UpdateGuestReservation } from 'src/models/guest-reservations';
import { AddressModel } from 'src/models/address.model';
import { TidySelectStringValueModel } from 'src/models/tidy-select-item.model';
import { StaticData } from '../static-data/static-data';
import { Schedule } from 'src/providers/schedule/schedule';

import { DateTime as LuxonDateTime } from 'luxon';
import { stringify } from 'querystring';

@Injectable()
export class GuestReservations {

  constructor(
    private httpClient: HttpClientCustom,
    private staticData: StaticData,
    private schedule: Schedule
  ) {}

  async getSourceTypes(includeActiveDetails: boolean = true): Promise<Array<GuestReservationSourceType>> {
    const url = `api/v1/customer/guest-reservation-source-types${includeActiveDetails? '?includes=active_details':''}`;
    return await this.httpClient.get(url);
  }

  getReservationSource(fields?: string[]): Promise<Array<GuestReservationSource>> {
    let url = 'api/v1/customer/guest-reservation-sources?includes=addresses';
    if (fields) {
      url = `${url}&fields=${fields.join(',')}`;
    }
    return this.httpClient.get(url);
  }

  getReservationSourceById(sourceId: number): Promise<GuestReservationSource> {
    const url = `api/v1/customer/guest-reservation-sources/${sourceId}`;
    return this.httpClient.get(url);
  }

  addiCalReservationSource(params: {importUrl: string, addressId: number}, guest_reservation_source_type_id: number): Promise<NewGuestReservationSource> {
    const url = 'api/v1/customer/guest-reservation-sources';
    const payload = {
      guest_reservation_source_type_id,
      data: {
        address_id: params.addressId,
        url: params.importUrl,
      }
    };
    return this.httpClient.post(url, payload);
  }

  addDirectReservationSource(payload): Promise<NewGuestReservationSource> {
    const url = 'api/v1/customer/guest-reservation-sources';
    return this.httpClient.post(url, payload);
  }

  addConciergeReservationSource(payload): Promise<NewGuestReservationSource> {
    const url = 'api/v1/customer/guest-reservation-sources';
    return this.httpClient.post(url, payload);
  }

  getReservations(params?: {
    page?: number;
    per_page?: number;
    address_id?: number | string;
  }): Promise<{
    body: Array<GuestReservation>;
    totalRecords: number;
  }> { 

    const stringifiedParams = this.stringifyParams(params);
    const url = `api/v1/customer/guest-reservations?${stringifiedParams}`;

    return this.httpClient.getFullRequest(url)
    .then(response => {
      return {
        body: response.body,
        totalRecords: response.headers.get('X-Total-Records')
      };
    });
  }

  create(payload: CreateGuestReservation): Promise<any> {
    const url = `api/v1/customer/guest-reservations`;
    return this.httpClient.post(url, payload);
  }

  update(id: number, payload: UpdateGuestReservation): Promise<any> {
    const url = `api/v1/customer/guest-reservations/${id}`;
    return this.httpClient.put(url, payload);
  }

  delete(id: number): Promise<any> {
    const url = `api/v1/customer/guest-reservations/${id}`;
    return this.httpClient.delete(url);
  }

  getReservationById(guestReservationId): Promise<GuestReservation> {
    const url = `api/v1/customer/guest-reservations/${guestReservationId}/details`;
    return this.httpClient.get(url);
  }

  deactivateIntegration(id) {
    const url = `api/v1/customer/guest-reservation-sources/${id}/deactivate`;
    return this.httpClient.put(url, {});
  }

  updateAddressAutomaticBooking(data, id) {
    const url = `api/v1/customer/automatic-booking-settings/${id}`;
    return this.httpClient.put(url, data);
  }

  updateReservationAutomaticBooking(data, id) {
    const url = `api/v1/customer/guest-reservations/${id}`;
    return this.httpClient.put(url, data);
  }

  getAutomaticBookingSettings(addressId) {
    const url = `api/v1/customer/automatic-booking-settings?address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  getAuditLog(id: number) {
    const url = `api/v1/customer/guest-reservation-source-sync-runs/${id}`;
    return this.httpClient.get(url);
  }

  async getAuditLogs(
    page: number = 1,
    perPage: number = 5,
    filters: {
      status?: string;
      address_ids?: number[];
    } = {}
  ): Promise<any> {
    const searchParams = new URLSearchParams();

    searchParams.append('page', page.toString());
    searchParams.append('per_page', perPage.toString());

    if (filters.status && filters.status !== '') {
      searchParams.append('filters[status]', filters.status);
    }

    if (filters.address_ids && filters.address_ids.length) {
      searchParams.append('filters[address_ids]', filters.address_ids.join(','));
    }

    const url = `api/v1/customer/guest-reservation-source-sync-runs?${searchParams.toString()}`;

    return this.httpClient.getFullRequest(url).then((response) => {
      return {
        body: response.body,
        totalRecords: response.headers.get('X-Total-Records')
      };
    });
  }

  getAutomaticBookingTimes(startTime: string = null, endTime: string = null): { start: TidySelectStringValueModel[], end: TidySelectStringValueModel[]} {
    const timeOpts = this.staticData.selectTimeOpts('07:00', '22:00');
    const start = endTime ? timeOpts.filter(timeOpt => timeOpt.value < endTime) : timeOpts;
    const end = startTime ? timeOpts.filter(timeOpt => timeOpt.value > startTime) : timeOpts;
    return {start, end};
  }

  addIntegrationsAddressInfo(integrations: GuestReservationSource[], addresses: AddressModel[]): GuestReservationSource[] {
    integrations.map((integration) => {
      integration['address'] = addresses.find(address => {
        return address.id === integration.address_id;
      });
    });
    return integrations;
  }

  getCleaningOptions(): TidySelectStringValueModel[] {
    return [
      {
        viewValue: '1 Hour Cleaning',
        value: 'regular_cleaning.one_hour'
      },
      {
        viewValue: '2.5 Hour Cleaning',
        value: 'regular_cleaning.two_and_a_half_hours'
      },
      {
        viewValue: '4 Hour Cleaning',
        value: 'regular_cleaning.four_hours'
      },
      {
        viewValue: 'Handyman',
        value: 'handyman.one_hour'
      },
      {
        viewValue: 'Trash Service',
        value: 'trash_service.one_hour'
      },
      {
        viewValue: 'Pool Cleaning',
        value: 'pool_cleaning.one_hour'
      },
      {
        viewValue: 'Carpet Cleaning',
        value: 'carpet_cleaning.one_hour'
      },
      {
        viewValue: 'Window Washing',
        value: 'window_washing.one_hour'
      },
      {
        viewValue: 'Lawn & Garden Care',
        value: 'lawn_garden_care.one_hour"'
      },
      {
        viewValue: 'Junk Removal',
        value: 'junk_removal.one_hour'
      },
      {
        viewValue: 'Pest Control',
        value: 'pest_control.one_hour'
      },
      {
        viewValue: 'Pressure Washing',
        value: 'pressure_washing.one_hour'
      },
      {
        viewValue: 'Tree Trimming',
        value: 'tree_trimming.one_hour'
      },
      {
        viewValue: 'Inspection',
        value: 'inspection.one_hour'
      },
      {
        viewValue: 'Laundry',
        value: 'laundry.one_hour'
      }
    ]
  }

  getCleaningHours(cleaningType){
    if(cleaningType === 'regular_cleaning.one_hour'){
      return '1:00'
    } else if (cleaningType === 'regular_cleaning.two_and_a_half_hours') {
      return '2:30'
    } else {
      return '4:00'
    }
  }

  convertStringToMinutes(timeString){
    let totalTime = 0;
    const timeArray = timeString.split(':');
    timeArray.map((time, index) => {
      (index === 0)? totalTime += parseInt(time)*60: totalTime += parseInt(time);
    });
    return(totalTime);
  }

  displayDate(time) {
    return moment(time, 'HH:mm').format('h:mma');
  }

  async getLastReservationDetails(addressId, jobId) {
    console.log('getLastReservationDetails', addressId, jobId)
    const lastReservation = await this.getLastReservation(addressId, jobId);
    console.log('lastReservation', lastReservation)
    const reservationId = lastReservation?.id.replace('guest_reservation-', '');
    console.log('reservationId', reservationId)
    const lastReservationDetail = lastReservation ? await this.getReservationById(reservationId) : null;
    console.log('lastReservationDetail', lastReservationDetail)
    const showAutomaticBookingQuestion = lastReservationDetail?.automatic_booking_settings[0]?.is_automatic_booking_enabled;
    const reservationDateTime = LuxonDateTime.fromISO(lastReservation?.template_data?.check_out_date).toFormat('LLL dd') + ' at ' + LuxonDateTime.fromISO(lastReservation?.template_data?.check_out_time).toFormat('hh:mma').toLowerCase();
    console.log('reservationDateTime', reservationDateTime)
    return { reservationId, showAutomaticBookingQuestion, reservationDateTime };
  }

  async getLastReservation(addressId, jobId) {
    const cards = await this.schedule.getCards(addressId);
    console.log('cards', cards)
    let priorCard = null;
    const jobCardIndex = cards.findIndex((card) => card.template_data?.job?.id == jobId);
    if (jobCardIndex > 0) {
      priorCard = cards[jobCardIndex - 1];
    }
    console.log('priorCard', priorCard)
    if (priorCard?.template === 'guest_reservations') {
      return priorCard;
    } else {
      return null;
    }
  }

  async toggleAutomaticBookingForReservation(reservationId, isAutomaticBookingEnabled) {
    const settings = await this.getReservationById(reservationId);
    const payload = {
      automatic_booking_settings: settings.automatic_booking_settings.map(setting => ({
        bookable_service_key: setting.bookable_service_key,
        is_automatic_booking_enabled: isAutomaticBookingEnabled
      }))
    };
    await this.updateAutomaticBookingForReservation(reservationId, payload);
  }

  updateAutomaticBookingForReservation(reservationId, payload) {
    const url = `api/v1/customer/guest-reservations/${reservationId}/automatic-booking-settings`;
    return this.httpClient.put(url, payload);
  }

  stringifyParams(params) {
    for (let key in params) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return stringify(params);
  }
}

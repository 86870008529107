import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { CameraProvider } from 'src/shared/providers/camera/camera';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from 'src/providers/genome/genome';
import { Logger } from 'src/providers/logger';
import { PhotoNotes } from "src/providers/photo-notes/photo-notes";
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Loading } from 'src/shared/components/loading/loading';
import { PhotoNoteUrlModel } from 'src/shared/components/photo-caption/photo-caption.component';

import { toDoAutocomplete } from 'src/shared/constants/toDoAutocomplete';

@Component({
  templateUrl: 'edit-to-do.html',
  providers: [PhotoCaptionService]
})

export class ToDosEditToDoPage implements OnInit {

  addressId: any;
  customFieldTypeItems = [];
  customFieldForm: CustomFieldFormModel[] = [];
  estimatedTimes: TidySelectNumberValueModel[];
  errorMessage: string;
  form: UntypedFormGroup;
  hasCountFieldAdded: boolean;
  isAddingCustomFields: boolean;
  important = false;
  isRightSideContent = true;
  loaded = false;
  photoNotesUrls: PhotoNoteUrlModel[] = [];
  roomId: any;
  roomObjects: any;
  roomObjectsItems: any = [];
  roomType: string;
  selectedFieldType: boolean;
  selectedRoomObject: any;
  submitted: boolean;
  toDo: any;
  toDoAutoComplete = toDoAutocomplete;
  taskCategoryOptions: any;

  constructor(
    private camera: CameraProvider,
    private client: Client,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private logger: Logger,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private photoNotes: PhotoNotes,
    private photoCaptionService: PhotoCaptionService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private toDos: ToDos
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      note: [''],
      time: ['', Validators.required],
      roomObject: ['']
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Edit To-Do');
    this.toDo = this.navCtrl.getParam('toDo') || dialogParams.toDo;
    this.photoNotesUrls = this.photoCaptionService.mountPhotoUrls(this.toDo.photo_notes, 'task');
    this.important = this.toDo.is_important;
    this.roomType = this.navCtrl.getParam('roomType') || dialogParams.roomType;
    this.roomId = this.navCtrl.getParam('roomId') || dialogParams.roomId;
    this.form.patchValue({
      title: this.toDo.title,
      note: this.toDo.note,
      time: this.toDo.estimated_time
    });
    await this.buildEstimatedTimes();
    await this.buildCustomFieldTypeItems();
    const addressResponse = await this.client.getMoreDetailAddresses();
    this.addressId = this.client.getSelectedAddressId(addressResponse);
    await this.getRoomObjectItems();
    if (this.toDo.options.length > 0) {
      this.patchCustomFieldForms();
    }
    if (this.toDo.room_object) {
      this.form.patchValue({
        roomObject: this.toDo.room_object.id
      });
      this.selectRoomObject({value: this.toDo.room_object.id});
    } else {
      this.form.patchValue({roomObject: 'none'});
    }
    this.loaded = true;
  }

  async getRoomObjectItems() {
    this.roomObjects = await this.genome.getRoomsObjects(this.addressId);
    this.roomObjectsItems = [];
    this.roomObjects.map((item) => {
      const viewValue = item.model.name ? item.model.name : item.model.category.name;
      this.roomObjectsItems.push({
        value: item.id,
        viewValue,
      });
    });
    this.roomObjectsItems.push({
      value: 'none',
      viewValue: 'None'
    });
  }

  patchCustomFieldForms() {
    this.isAddingCustomFields = true;
    this.toDo.options.map((option) => {
      if (option.name == 'Take Inventory') {
        this.hasCountFieldAdded = true;
      }
      this.addCustomField(option.notes);
      const taskCategoryOptionId = this.getTaskCategoryOptionId(option.name);
      this.customFieldForm[this.customFieldForm.length - 1].form.patchValue({
        type: taskCategoryOptionId,
        required: option.required
      });
      this.selectFieldType(taskCategoryOptionId, this.customFieldForm.length - 1);
      option?.allowed_values?.map((value, i) => {
        this.addOption(this.customFieldForm.length - 1);
        this.customFieldForm[this.customFieldForm.length - 1].options[i].patchValue({option: value});
      });
    });
  }

  getTaskCategoryOptionId(name) {
    const option = this.taskCategoryOptions.find((option) => option.name == name);
    return option.id;
  }

  expandFields() {
    this.addCustomField();
    this.isAddingCustomFields = true;
  }

  addCustomField(label = '') {
    const formGroup = this.fb.group({
      type: ['', Validators.required],
      label: [label, Validators.required],
      required: [true, Validators.required],
      options: this.fb.array([])
    });
    this.customFieldForm.push({
      form: formGroup,
      options: []
    });
  }

  buildEstimatedTimes()  {
    let times = [];
    for (let i = 1; i < 32; i++) {
      times.push({
        viewValue: i + ' minutes',
        value: i
      });
    }
    this.estimatedTimes = times;
  }

  async buildCustomFieldTypeItems() {
    this.taskCategoryOptions = await this.toDos.getTaskCategoryOptions();
    this.taskCategoryOptions.map((item) => {
      if (item.name !== 'Vacuum' && item.name !== 'Mop') {
        this.customFieldTypeItems.push(({
          viewValue: item.name,
          value: item.id
        }));
      }
    });
  }

  async saveChanges() {
    this.submitted = true;
    let hasFieldOptionError = false;
    this.customFieldForm.map((field) => {
      if (field.options.length < field.numberOfRequiredOptions) {
        hasFieldOptionError = true;
      }
    });
    if (!this.form.valid || hasFieldOptionError) {
      return;
    }
    try {
      let payload = {
        title:                 this.form.value.title,
        note:                  this.form.value.note,
        estimated_time:        this.form.value.time,
        is_important:          this.important,
        task_category_options: []
      }
      if (this.form.value.roomObject !== 'none') {
        payload['room_object_id'] = this.form.value.roomObject;
      }
      this.customFieldForm.map((field) => {
        let item = {
          id: field.form.value.type,
          notes: field.form.value.label,
          required: field.form.value.required
        }
        if (field.options.length > 0) {
          item['allowed_values'] = '';
          field.options.map((option) => {
            item['allowed_values'] += option.controls.option.value;
            item['allowed_values'] += ', ';
          });
        }
        if (item['allowed_values']) {
          item['allowed_values'] = item['allowed_values'].substring(0, item['allowed_values'].length - 2);
        }
        payload.task_category_options.push(item);
      });
      await this.toDos.updateToDo(this.toDo.id, payload);
      await this.uploadPhotos();
      await this.deleteImages();
      let dialogParams = await this.storage.retrieve('dialog-params');
      if (dialogParams?.room) dialogParams.room = null;
      await this.storage.save('dialog-params', dialogParams);
      this.rightSidePanelService.navigateTo('card');
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async maskImageAsDeleted(photoId: number) {
    this.photoNotesUrls.forEach((photoNote) => {
      if (photoNote.id !== -1 && photoNote.id === photoId) {
        photoNote.deleted = true;
      }
    });
  }

  async deleteToDo() {
    const params: ConfirmPageParamsModel = {
      title: 'Delete To-Do?',
      body: 'You will not be able to undo this action.',
      backText: 'Go Back',
      confirmText: 'Delete To-Do',
      confirmAction: this.confirmDeleteToDo.bind(this)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmDeleteToDo() {
    try {
      await this.toDos.deleteToDo(this.toDo.id);
      let dialogParams = await this.storage.retrieve('dialog-params');
      if (dialogParams?.room) dialogParams.room = null;
      await this.storage.save('dialog-params', dialogParams);
      this.rightSidePanelService.navigateTo('card');
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async takePhoto() {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg('task');
      this.photoNotesUrls.push(newPhotoNote);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async uploadPhotos() {
    try {
      const photoNote = this.roomType == 'group' ?
        this.toDos.addGroupTaskPhotoNote.bind(this.toDos, this.roomId, this.toDo.id) :
        this.toDos.taskPhotoNotes.bind(this.toDos, this.roomId, this.toDo.id);
      await this.photoCaptionService.uploadPhotosGeneric(
        this.photoNotesUrls,
        'task',
        photoNote,
        this.photoNotes.photoCaption.bind(this.photoNotes),
        this.roomType
      );
    } catch(err) {
      this.logger.error(err, 'upload image error');
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async deleteImages() {
    Promise.all(this.photoNotesUrls.map(async (photoNote) => {
      if (photoNote.deleted) {
        await this.photoCaptionService.deletePhotoNote(photoNote.id);
      }
    }));
  }

  removeField(fieldIndex) {
    this.customFieldForm.splice(fieldIndex, 1);
    if (this.customFieldForm.length == 0) {
      this.isAddingCustomFields = false;
    }
  }

  selectFieldType(taskCategoryOptionId, i) {
    this.submitted = false;
    const selectedOption = this.taskCategoryOptions.find((option) => option.id == taskCategoryOptionId);
    this.customFieldForm[i]['numberOfRequiredOptions'] = 0;
    this.customFieldForm[i]['numberOfRequiredObjects'] = 0;
    if (selectedOption.name == 'Checkbox(es)') {
      this.customFieldForm[i]['numberOfRequiredOptions'] = 1;
    }
    if (selectedOption.name == 'Select') {
      this.customFieldForm[i]['numberOfRequiredOptions'] = 2;
    }
    if (selectedOption.name == 'Take Inventory') {
      this.customFieldForm[i]['numberOfRequiredObjects'] = 1;
    }
    if (selectedOption.name !== 'Checkbox(es)') {
      this.selectedFieldType = true;
    }
  }

  addOption(index) {
    this.submitted = false;
    const formGroup = this.fb.group({
      option: ['', Validators.required]
    });
    this.customFieldForm[index].options.push(formGroup);
  }

  removeOption(customFieldFormIndex, optionIndex) {
    this.customFieldForm[customFieldFormIndex].options.splice(optionIndex, 1);
  }

  goToContactConciergePage() {
    const params = {
      title: 'To-Do Help',
      type: 'support.to_do_help',
      metadata: {
        suptype: 'to_do_help'
      }
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  goToAddressPage() {
    this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
  }

  selectRoomObject(selection) {
    this.roomObjects.map((item) => {
      if (item.id == selection.value) {
        this.selectedRoomObject = item;
      }
    });
  }

  addCustomCountField() {
    const formGroup = this.fb.group({
      type: ['', Validators.required],
      label: ['', Validators.required],
      required: [true, Validators.required],
      options: this.fb.array([])
    });
    this.customFieldForm.push({
      form: formGroup,
      options: []
    });
    const taskCategory = this.taskCategoryOptions.find((category) => category.name == 'Take Inventory');
    this.selectFieldType(taskCategory.id, this.customFieldForm.length - 1);
    this.customFieldForm[this.customFieldForm.length - 1].form.patchValue({
      type: taskCategory.id,
      label: 'Count # of ' + this.selectedRoomObject.model.name + 's'
    });
    this.isAddingCustomFields = true;
  }

  selectRequired(form, selection) {
    form.form.patchValue({required: !selection});
  }

}

interface CustomFieldFormModel {
  form: UntypedFormGroup;
  options: any;
  optionsError?: boolean;
  numberOfRequiredOptions?: number;
  numberOfRequiredObjects?: number;
}

import { Routes } from '@angular/router';

export const redirects: Routes = [
  { path: 'manage-addresses', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'no-plan', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'my-cleanings', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'home', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'account', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'homekeeper-options', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'homekeeper', redirectTo: 'dashboard', pathMatch: 'full'}
]

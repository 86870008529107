<tidy-row class="extra-bottom-padding">
  <tidy-text [header]="'How do you want to perform inspections?'"> </tidy-text>
</tidy-row>

<ng-container *ngFor="let template of inspectionTemplates">
  <tidy-card
    class="card-checkbox"
    [ngClass]="{'selected-card-checkbox': template.isSelected}"
    (click)="selectTemplate(template)">
    <div style="display: flex; align-items: center">
      <tidy-text [header]="template?.name"> </tidy-text>
    </div>
    <tidy-row>
      <tidy-text [body]="template?.description"> </tidy-text>
    </tidy-row>
  </tidy-card>
</ng-container>
<tidy-button
  [text]="'Save Inspection Settings'"
  [action]="save.bind(this)"
  class="primary">
</tidy-button>

import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'tidy-loader',
  template: `
    <div @inOutAnimation>
      <svg width="150" height="100">
        <circle #cLeft cx="50" cy="50" r="6" />
        <circle #cCenter cx="70" cy="50" r="6" />
        <circle #cRight cx="90" cy="50" r="6" />
      </svg>
    </div>
  `,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('100ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [
          animate('100ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {
  @ViewChild('cLeft', {static: false}) cLeft: ElementRef;
  @ViewChild('cCenter', {static: false}) cCenter: ElementRef;
  @ViewChild('cRight', {static: false}) cRight: ElementRef;

  constructor() { }
  animationId: number;

  ngAfterViewInit() {
    let cLeft = this.cLeft.nativeElement,
    cCenter = this.cCenter.nativeElement,
    cRight = this.cRight.nativeElement;

    let currentAnimationTime = 0;
    const centreY = 50;
    const amplitude = 20;

    const animate = () => {
      cLeft.setAttribute('cy', `${centreY + (amplitude * (Math.sin(currentAnimationTime)))}`);
      cCenter.setAttribute('cy', `${centreY + (amplitude * (Math.sin(currentAnimationTime - 1)))}`);
      cRight.setAttribute('cy', `${centreY + (amplitude * (Math.sin(currentAnimationTime - 2)))}`);
      currentAnimationTime += 0.15;
      this.animationId = requestAnimationFrame(animate);
    }

    animate();
  }

  ngOnDestroy() {
    cancelAnimationFrame(this.animationId);
  }
}

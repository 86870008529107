import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Loading } from 'src/shared/components/loading/loading';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TeamService } from 'src/providers/team/team';
import { UserService } from 'src/providers/user/user';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamPage } from '../team/team';

@Component({
  templateUrl: 'add-user-to-team.html'
})

export class AddUserToTeamPage extends TimeoutableComponent implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;
  teamItems: any;
  userItems: any;
  usersList: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private teamService: TeamService,
    private userService: UserService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
    this.form = this.fb.group({
      user: ['', Validators.required],
      team: ['', Validators.required]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add to Team');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    try {
      await this.getUserItems();
      await this.getTeamItems();
      const user = this.navCtrl.getParam('user') || await this.dialogParams.user;
      if (user) {
        this.form.patchValue({user: user.id})
      }
      const team = this.navCtrl.getParam('teamId') || await this.dialogParams.teamId;
      if (team) {
        this.form.patchValue({team: team})
      }
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.loaded = true;
  }

  async getUserItems() {
    this.userItems = [];
    this.usersList = this.navCtrl.getParam('usersList') || await this.dialogParams.usersList || await this.userService.getUsersList();
    console.log(this.usersList)
    this.usersList.map((user) => {
      if (user.role !== 'owner') {
        this.userItems.push({
          viewValue: user.first_name + ' ' + user.last_name + ' ' + this.getRole(user.role),
          value: user.id
        });
      }
    });
  }

  async getTeamItems() {
    this.teamItems = [];
    const teams = this.navCtrl.getParam('teams') || await this.dialogParams.teams  || await this.teamService.getTeams();
    teams.map((team) => {
      this.teamItems.push({
        viewValue: team.name,
        value: team.id
      });
    });
  }

  @Loading('', true)
  async addUserToTeam() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      if (this.isConvertingAdminToMember()) {
        const userPayload = {
          role: 'member'
        }
        await this.userService.changeUserRole(this.form.value.user, userPayload);
      }
      const payload = {
        customer_member_id: this.form.value.user,
        customer_account_team_id: this.form.value.team,
        role: 'manage'
      }
      await this.teamService.addUserToTeam(payload);
      this.storage.save('teamId', this.form.value.team);
      this.dialogParams.team = null;
      this.dialogParams.teams = null;
      await this.storage.save('dialog-params', this.dialogParams);
      this.rightSidePanelService.navigateTo(`team${this.form.value.team}`, {}, TeamPage);
    } catch (err) {
      this.timeoutHandler(err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  isConvertingAdminToMember() {
    let isAdmin = false;
    this.usersList.map((user) => {
      if (user.role == 'admin' && this.form.value.user == user.id) {
        isAdmin = true;
      }
    });
    return isAdmin;
  }

  getRole(role) {
    if (role == 'owner') {
      return '(Owner)'
    } else if (role == 'admin') {
      return '(Admin)'
    } else {
      return '(Team Member)'
    }
  }

}

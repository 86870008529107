<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Find New Pro Settings'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row *ngIf="cameFromMyProsOnboardingPage">
        <tidy-filter
          [initValue]="1"
          [items]="serviceCategoryItems"
          [fitContent]="true"
          (optionChange)="changeServiceCategory($event)">
        </tidy-filter>
      </tidy-row>

      <ng-container *ngIf="supportsFindNewPros">
        <tidy-card>
          <form [formGroup]="form">
            <tidy-row>
              <tidy-text
                [header]="'Find New Pro'">
              </tidy-text>
              <div></div>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'When a higher priority Pro is unavailable, TIDY can help find the best available Pro from our high quality network.'">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-checkbox
                [(checked)]="allowsFindNewPros"
                [items]="[
                  {
                    viewValue: 'Allow Find New Pros', value: true
                  }
                ]"
                [initValue]="allowsFindNewPros">
              </tidy-checkbox>
            </tidy-row>
            <ng-container *ngIf="allowsFindNewPros">
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider>
                </tidy-divider>
              </tidy-row>
              <tidy-row class="field-top-padding extra-bottom-padding">
                <tidy-select
                  [label]="'Gender Preferences'"
                  [form]="form.controls.gender"
                  formControlName="gender"
                  [items]="genderOptions">
                </tidy-select>
              </tidy-row>
            </ng-container>
          </form>
        </tidy-card>
      </ng-container>

      <tidy-alert *ngIf="!supportsFindNewPros">
        <tidy-row>
          <tidy-text
            [body]="'TIDY does not support finding new pros for this service type yet.'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'Click here'"
            class="link"
            (action)="requestCategory()">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'to request this feature.'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="'Save Setting'"
        [action]="save.bind(this)"
        class="primary"
        [disabled]="!supportsFindNewPros">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
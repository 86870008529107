<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Payment Methods'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="!loaded">

      <div class="flex-row">
        <div class="flex-row-item">
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 50px; border-radius: 6px">
          </ion-skeleton-text>
        </div>
        <div class="flex-row-item">
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 50px; border-radius: 6px">
          </ion-skeleton-text>
        </div>
      </div>

      <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
        <tidy-card>
          <ion-skeleton-text
            animated="true"
            style="width: 120px; height: 30px; border-radius: 6px">
          </ion-skeleton-text>
          <div style="margin-top: 1rem"></div>
          <ion-skeleton-text
            animated="true"
            style="
              width: 100%;
              height: 40px;
              border-radius: 6px;
              margin-bottom: 2rem;
            ">
          </ion-skeleton-text>
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 35px; border-radius: 6px">
          </ion-skeleton-text>
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 35px; border-radius: 6px">
          </ion-skeleton-text>
          <ion-skeleton-text
            animated="true"
            style="width: 100%; height: 35px; border-radius: 6px">
          </ion-skeleton-text>
        </tidy-card>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="loaded">

      <form [formGroup]="form" novalidate action="#">
        <div class="flex-row">
          <div class="flex-row-item">
            <tidy-select
              [multiple]="true"
              [label]="'Payment Method'"
              [items]="paymentMethodsItems"
              [form]="form.controls.paymentMethod"
              formControlName="paymentMethod"
              class="select-custom-input"
              (optionChange)="changePaymentMethod($event)">
            </tidy-select>
          </div>
          <div class="flex-row-item">
            <tidy-select
              [multiple]="true"
              [label]="'Status'"
              [items]="statusItems"
              [form]="form.controls.status"
              formControlName="status"
              class="select-custom-input"
              (optionChange)="changeStatus($event)">
            </tidy-select>
          </div>
        </div>
      </form>

      <tidy-alert *ngIf="paymentMethods?.length == 0">
        <tidy-text [body]="'No payment methods on file.'"> </tidy-text>
      </tidy-alert>

      <ng-container *ngFor="let paymentMethod of filteredPaymentMethods">
        <tidy-payment-method-card
          [teamId]="null"
          [paymentMethod]="paymentMethod"
          [paymentMethods]="paymentMethods"
          (action)="handleAction($event)">
        </tidy-payment-method-card>
      </ng-container>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<tidy-fab [isRightSidePanelButton]="isRightSideContent" class="bottom-nav-padding" *ngIf="loaded">
  <tidy-fab-button class="main-btn"  (click)="goToAddCard()">
    <tidy-image style="margin-left: 10px" src="assets/svg/add-heavy.svg" class="large-body-size"></tidy-image>
    <tidy-text style="margin-right: 10px" largeBody="Add Payment Method" class="white"></tidy-text>
  </tidy-fab-button>
</tidy-fab>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tidy-image',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./image.component.scss'],
  template: `
    <ion-img 
      [style.width]="width" 
      [src]="src" 
      [class.hoverable]="hoverSrc"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
      (ionImgDidLoad)="imageLoaded()" 
      (click)="executeAction()">
    </ion-img>
  `
})

export class ImageComponent {
  @Input() src: string;
  @Input() hoverSrc: string;
  @Input() color: string;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() imageDidLoad: EventEmitter<void> = new EventEmitter<void>();
  @Input() width: string;

  private originalSrc: string;

  ngOnInit() {
    this.originalSrc = this.src;
  }

  onMouseEnter() {
    if (this.hoverSrc) {
      this.src = this.hoverSrc;
    }
  }

  onMouseLeave() {
    if (this.hoverSrc) {
      this.src = this.originalSrc;
    }
  }

  executeAction() {
    this.action.emit();
  }

  imageLoaded() {
    this.imageDidLoad.emit();
  }
}

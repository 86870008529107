import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { NavigationExtras, UrlTree, UrlSerializer, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CustomNavController extends NavController {

  constructor(
    platform: Platform,
    location: Location,
    serializer: UrlSerializer,
    router: Router
  ) {
    super(platform, location, serializer, router);
  }

  navigateBack(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavigationExtras(params);
    return super.navigateBack(url, navigationExtras);
  }

  navigateForward(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavigationExtras(params);
    return super.navigateForward(url, navigationExtras);
  }

  navigateRoot(url: string | any[] | UrlTree, params?: any) {
    const navigationExtras = this.mountNavigationExtras(params);
    return super.navigateRoot(url, navigationExtras);
  }

  mountNavigationExtras(params) {
    const navigationExtras: NavigationExtras = {
      state: params
    };

    return navigationExtras;
  }

  getParam(key: string, redirectOnNull: boolean = false, redirectUrl: string = '') {
    if (!history.state || !key || (history.state[key] == undefined)) {
      if (redirectOnNull) {
        this.navigateRoot(redirectUrl);
      }
      return null;
    }
    return history.state[key];
  }

  getParams(redirectOnNull: boolean = true) {
    const historyState = history.state;
    const noParams = Object.keys(historyState).length < 2;
    if (noParams) {
      if (redirectOnNull) {
        this.navigateRoot('');
      }
      return null;
    }
    return historyState;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Bill Autopay'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'When'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="pro?.homekeeper?.name">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'finishes your jobs they can send you a bill using their TIDY app.'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-image
              [src]="allowsInvoiceAutopay ? 'assets/img/checkmark.svg' : 'assets/img/block.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [header]="'Autopay'">
            </tidy-text>
            <div></div>
          </tidy-row>
          <tidy-toggle
            [toggled]="allowsInvoiceAutopay"
            (toggleChanged)="allowsInvoiceAutopay = !allowsInvoiceAutopay">
          </tidy-toggle>
        </tidy-row>
        <ng-container *ngIf="allowsInvoiceAutopay">
          <form [formGroup]="form">
            <tidy-row class="field-top-padding">
              <tidy-input
                formControlName="autoPayMaxAmount"
                [label]="'Maximum amount to pay'"
                [form]="form.controls.autoPayMaxAmount"
                [submitted]="submitted"
                [errorMessage]="'Please enter a maximum amount to pay.'"
                inputMode="numeric"
                [mask]="'currencyMask'">
              </tidy-input>
            </tidy-row>
          </form>
        </ng-container>
      </tidy-card>

      <tidy-button
        [text]="'Save Settings'"
        class="primary"
        [action]="save.bind(this)">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild, ViewEncapsulation, ElementRef, HostListener } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import csvDownload from 'json-to-csv-export'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'table.component.html',
  selector: 'tidy-table',
  styleUrls: ['table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class TableComponent implements AfterViewInit, OnChanges {

  @Input() rowsToShow: number;
  @Input() totalRows: any;
  @Input() displayedColumns: string[] = [];
  @Input() columnslabels: string[] = [];
  @Input() minColumnWidth: boolean;
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() selectedRowsChange = new EventEmitter<any>();
  @Output() unselectedRowsChange = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();
  @Output() cellClick = new EventEmitter<{column: string, element: any}>();
  @Input() showPaginator: boolean = true;
  @Input() showColumnLines: boolean = true;
  @Input() allRowIsClickable: boolean = false;
  @Input() dataSource: any[];
  @Input() infiniteScroll: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() showArrowIcon: boolean = true;
  @Input() scrollWithPage: boolean = false;
  @Output() loadMore = new EventEmitter<void>();
  tabledataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  editingCell: { row: any; column: string } | null = null;
  editValue: string = '';
  @ViewChild('editInput') editInput: ElementRef;
  private originalValue: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableWrapper') tableWrapper: ElementRef;
  private scrollThreshold = 100; // pixels from bottom to trigger load more
  private ionContent: HTMLElement;

  @ViewChild(IonInfiniteScroll) infiniteScrollEl: IonInfiniteScroll;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    public windowService: WindowService
  ) {}

  @HostListener('window:resize')
  onResize() {
    if (!this.scrollWithPage) {
      this.setTableHeight();
    }
    this.setupTableForMobile();
  }

  private setupTableForMobile() {
    if (!this.tableWrapper) return;
    
    // Add a class to the table wrapper based on mobile/desktop view
    const isMobile = !this.windowService.isDesktopRes;
    const element = this.tableWrapper.nativeElement;
    
    if (isMobile) {
      element.classList.add('mobile-view');
    } else {
      element.classList.remove('mobile-view');
    }
    
    setTimeout(() => {
      if (this.tableWrapper) {
        const table = this.tableWrapper.nativeElement.querySelector('table');
        if (table) {
          // Ensure table has proper width
          table.style.tableLayout = 'auto';
          table.style.width = 'auto';
          table.style.minWidth = '100%';
          
          // Ensure all cells have minimum width
          const cells = table.querySelectorAll('th, td');
          cells.forEach((cell: HTMLElement) => {
            cell.style.minWidth = '100px';
            cell.style.whiteSpace = 'nowrap';
          });
          
          // Ensure text doesn't wrap
          const textElements = table.querySelectorAll('.cell-text, span');
          textElements.forEach((el: HTMLElement) => {
            el.style.whiteSpace = 'nowrap';
            el.style.overflow = 'visible';
            el.style.textOverflow = 'clip';
          });
          
          // Force wrapper to be scrollable
          const wrapper = this.tableWrapper.nativeElement;
          wrapper.style.overflowX = 'auto';
          wrapper.style.display = 'block';
          wrapper.style.width = '100%';
        }
      }
    }, 0);
  }

  private findIonContent() {
    if (!this.scrollWithPage) return;
    
    let element = this.tableWrapper.nativeElement;
    while (element && !element.classList.contains('ion-content')) {
      element = element.parentElement;
    }
    this.ionContent = element;
    
    if (this.ionContent) {
      this.ionContent.addEventListener('ionScroll', this.onIonScroll.bind(this));
    }
  }

  private onIonScroll(event) {
    if (!this.infiniteScroll || !this.scrollWithPage || this.isLoading) return;
    
    // Check if we've loaded all rows
    if (this.dataSource && this.totalRows && this.dataSource.length >= this.totalRows) {
      return;
    }

    const scrollElement = event.target;
    const scrollPosition = scrollElement.scrollTop;
    const scrollHeight = scrollElement.scrollHeight;
    const clientHeight = scrollElement.clientHeight;
    
    if (scrollHeight - (scrollPosition + clientHeight) <= this.scrollThreshold) {
      this.pageChange.emit();
    }
  }

  onIonInfinite(event) {
    if (!this.infiniteScroll || this.isLoading) {
      event.target.complete();
      return;
    }
    
    // Check if we've loaded all rows
    if (this.dataSource && this.totalRows && this.dataSource.length >= this.totalRows) {
      event.target.complete();
      return;
    }

    this.pageChange.emit();
    event.target.complete();
  }

  private setTableHeight() {
    if (!this.tableWrapper) return;
    
    const element = this.tableWrapper.nativeElement;
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const bottomPadding = 20; // Add some padding at the bottom
    
    // Calculate available height (viewport height minus distance from top of table to top of viewport)
    const availableHeight = viewportHeight - rect.top - bottomPadding;
    
    // Set the max-height
    element.style.maxHeight = `${availableHeight}px`;
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.tabledataSource = new MatTableDataSource(this.dataSource);
      this.tabledataSource.sort = this.sort;
      if (!this.infiniteScroll) {
        this.tabledataSource.paginator = this.paginator;
      }

      // Setup table for mobile view
      setTimeout(() => {
        this.setupTableForMobile();
        
        // Force table to be scrollable on mobile
        if (!this.windowService.isDesktopRes && this.tableWrapper) {
          const wrapper = this.tableWrapper.nativeElement;
          const table = wrapper.querySelector('table');
          
          if (table) {
            // Ensure table has proper width
            table.style.tableLayout = 'auto';
            table.style.width = 'auto';
            table.style.minWidth = '100%';
            
            // Ensure all cells have minimum width
            const cells = table.querySelectorAll('th, td');
            cells.forEach((cell: HTMLElement) => {
              cell.style.minWidth = '100px';
              cell.style.whiteSpace = 'nowrap';
            });
            
            // Ensure text doesn't wrap
            const textElements = table.querySelectorAll('.cell-text, span');
            textElements.forEach((el: HTMLElement) => {
              el.style.whiteSpace = 'nowrap';
              el.style.overflow = 'visible';
              el.style.textOverflow = 'clip';
            });
            
            // Force wrapper to be scrollable
            wrapper.style.overflowX = 'auto';
            wrapper.style.display = 'block';
            wrapper.style.width = '100%';
            
            // Ensure first column is sticky with proper background
            const firstHeaderCells = table.querySelectorAll('th:first-child');
            const firstDataCells = table.querySelectorAll('td:first-child');
            
            [...firstHeaderCells, ...firstDataCells].forEach((cell: HTMLElement) => {
              cell.style.position = 'sticky';
              cell.style.left = '0';
              cell.style.backgroundColor = 'white';
              cell.style.zIndex = '2';
              cell.style.boxShadow = '2px 0 4px rgba(0, 0, 0, 0.1)';
            });
          }
        }
      });

      // Calculate and set initial height only if not scrolling with page
      if (!this.scrollWithPage) {
        requestAnimationFrame(() => {
          this.setTableHeight();
          
          // Lock column widths after initial render
          const table = this.tableWrapper?.nativeElement?.querySelector('table');
          if (table) {
            const headerCells = table.querySelectorAll('th');
            headerCells.forEach((cell: HTMLElement) => {
              const width = cell.offsetWidth;
              cell.style.width = `${width}px`;
              cell.style.minWidth = `${width}px`;
            });

            // Also set widths on all td cells to match
            this.displayedColumns.forEach((_, index) => {
              const cells = table.querySelectorAll(`td:nth-child(${index + 1})`);
              cells.forEach((cell: HTMLElement) => {
                cell.style.width = `${headerCells[index].offsetWidth}px`;
                cell.style.minWidth = `${headerCells[index].offsetWidth}px`;
              });
            });
          }
        });
      }
    }
    if (this.paginator && !this.infiniteScroll) {
      this.paginator.length = this.totalRows;
    }
    this.showColumnLines = false;
    if (this.showColumnLines) {
      this.document.documentElement.style.setProperty('--table-border-right', '1px solid rgba(0, 0, 0, .2)');
    } else {
      this.document.documentElement.style.setProperty('--table-border-right', 'none');
    }
    if (this.infiniteScroll && this.tableWrapper && !this.scrollWithPage) {
      this.tableWrapper.nativeElement.addEventListener('scroll', (event: Event) => {
        if (this.isLoading) return;
        
        // Check if we've loaded all rows
        if (this.dataSource && this.totalRows && this.dataSource.length >= this.totalRows) {
          return;
        }

        const element = event.target as HTMLElement;
        const scrollPosition = element.scrollTop + element.clientHeight;
        const scrollHeight = element.scrollHeight;
        
        if (scrollHeight - scrollPosition <= this.scrollThreshold) {
          this.pageChange.emit();
        }
      });
    }

    // Find and setup ion-content scroll listener
    if (this.scrollWithPage) {
      setTimeout(() => this.findIonContent(), 0);
    }
  }

  ngOnDestroy() {
    if (this.ionContent) {
      this.ionContent.removeEventListener('ionScroll', this.onIonScroll.bind(this));
    }
  }

  ngOnChanges(changes) {
    if (changes.dataSource && this.tabledataSource) {
      const wrapper = this.tableWrapper?.nativeElement;
      const isAppendOperation = changes.dataSource.previousValue && 
                               changes.dataSource.currentValue?.length > changes.dataSource.previousValue.length;
      
      if (isAppendOperation && wrapper) {
        // Store the current first visible row's position
        const firstVisibleRowIndex = Math.floor(wrapper.scrollTop / 40); // Assuming 40px row height
        const currentScrollTop = wrapper.scrollTop;
        
        // Update data
        this.tabledataSource = new MatTableDataSource(changes.dataSource.currentValue);
        this.tabledataSource.sort = this.sort;
        this.tabledataSource.paginator = this.paginator;
        this.selection.clear();
        this.emitSelectionChange();

        // Restore scroll to keep the same content visible
        requestAnimationFrame(() => {
          wrapper.scrollTop = currentScrollTop;
        });
      } else {
        // Normal update without scroll preservation
        this.tabledataSource = new MatTableDataSource(changes.dataSource.currentValue);
        this.tabledataSource.sort = this.sort;
        this.tabledataSource.paginator = this.paginator;
        this.selection.clear();
        this.emitSelectionChange();
      }
    }
    if (changes.totalRows && this.paginator) {
      this.paginator.length = this.totalRows;
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.rowsToShow;
      this.paginator.length = this.totalRows;
      this.tabledataSource.paginator = this.paginator;
    }
  }

  emitSortChange(sort) {
    this.sortChange.emit(sort);
  }

  onCellClick(column: string, element: any): void {
    if (element[column]?.editable) {
      this.editingCell = { row: element, column };
      this.originalValue = element[column].value?.toString() || '';
      this.editValue = '';  
      setTimeout(() => {
        if (this.editInput?.nativeElement) {
          this.editInput.nativeElement.focus();
        }
      });
    } else if (element[column]?.action) {
      element[column]?.action();
    }
    this.cellClick.emit({column, element});
  }

  onEditKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.saveEdit();
    } else if (event.key === 'Escape') {
      this.cancelEdit();
    }
    if (
      ['Backspace', 'Delete', 'Tab', 'Enter', 'Escape', '.', 'ArrowLeft', 'ArrowRight'].indexOf(event.key) !== -1 ||
      (event.ctrlKey === true && ['a', 'c', 'v', 'x'].indexOf(event.key.toLowerCase()) !== -1)
    ) {
      return;
    }
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  saveEdit() {
    if (this.editingCell) {
      const { row, column } = this.editingCell;
      if (this.editValue !== '' && this.editValue !== this.originalValue && !isNaN(Number(this.editValue))) {
        localStorage.setItem('tableInput', this.editValue);
        row[column].value = this.editValue;

        // Check for quantity issues after edit
        const currentQuantity = column === 'Current #' ? Number(this.editValue) : Number(row['Current #'].value);
        const minQuantity = column === 'Min #' ? Number(this.editValue) : Number(row['Min #'].value);

        // Update icon if current quantity is less than min quantity
        if (currentQuantity < minQuantity) {
          row['Current #'].icon = 'assets/img/request-failed.svg';
        } else {
          delete row['Current #'].icon;
        }

        if (row[column].onEdit) {
          row[column].onEdit(Number(this.editValue));
        }
      } else {
        row[column].value = this.originalValue;
      }
      this.editingCell = null;
      this.editValue = '';
      this.originalValue = '';
    }
  }

  cancelEdit() {
    this.editingCell = null;
    this.editValue = '';
  }

  isEditing(row: any, column: string): boolean {
    return this.editingCell?.row === row && this.editingCell?.column === column;
  }

  treatJsonData(rawData, headers) {
    const csvJson = rawData.map((row) => {
      const object: any = {};
      headers.map((field_name) => {
        if(row[field_name].value) {
          object[field_name] = row[field_name].value;
        } else {
          object[field_name] = '';
        }
      })
      return object;
    });
    return csvJson;
  }

  exportCsv(tabledataSource){
    const dataToConvert = {
      data: this.treatJsonData(tabledataSource.filteredData, this.displayedColumns),
      filename: 'TIDY-export',
      delimiter: ',',
      headers: this.displayedColumns
    }
    csvDownload(dataToConvert);
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.tabledataSource?.data?.length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.tabledataSource?.data);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  selectPage(page) {
    this.pageChange.emit(page);
    this.selection.clear();
    this.emitSelectionChange();
  }

  emitSelectionChange() {
    this.selectedRowsChange.emit(this.selection?.selected)
    const unselectedRows = this.tabledataSource?.data?.filter((row) => !this.selection?.selected?.includes(row))
    this.unselectedRowsChange.emit(unselectedRows)
  }

  handleCellClick(column: string, element: any, event: Event) {
    if (element[column]?.action) {
      element[column].action();
      event.stopPropagation();
    } else {
      this.onCellClick(column, element);
    }
  }
}


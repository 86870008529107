<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="configuration?.title"
    [canGoBack]="true"
    [customBack]="'schedule'"
    id="top-nav-height"
>
    <tidy-wrapper *ngIf="loaded">
      <tidy-row>
        <tidy-divider>
        </tidy-divider>
      </tidy-row>

      <div style="display: flex; justify-content: space-between; align-items: flex-start;" *ngIf="loaded">
        <div>
          <tidy-row class="vertical-align-center">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_1_white.svg'">
            </tidy-image>
            <tidy-text
              [actionBody]="serviceFrequencyLabel() | titlecase"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_2_white.svg'">
            </tidy-image>
            <tidy-text
              [actionBody]="dateLabel"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="(form?.value?.frequency === 'twice_a_week' || form?.value?.frequency === 'thrice_a_week') && secondDateLabel">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_3_white.svg'">
            </tidy-image>
            <tidy-text
              [actionBody]="secondDateLabel"
              class="white">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="form?.value?.frequency === 'thrice_a_week' && thirdDateLabel">
            <tidy-image
              class="body-size"
              [src]="'assets/img/icons/number_4_white.svg'">
            </tidy-image>
            <tidy-text
              [actionBody]="thirdDateLabel"
              class="white">
            </tidy-text>
          </tidy-row>
        </div>
        <div>
          <tidy-row class="extra-left-padding" *ngIf="selectedDuration && selectedServiceData">
            <tidy-text
              *ngIf="selectedServiceData?.billingType !== 'price_later' && selectedServiceData?.billingType !== 'hourly'"
              [title]="bookJobService.getDisplayPriceForJob(planId, selectedServiceData, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)"
              class="text-price custom-text-price">
            </tidy-text>
            <tidy-text
              *ngIf="selectedServiceData?.billingType == 'hourly' || selectedServiceData?.billingType == 'price_later'"
              [header]="bookJobService.getDisplayPriceForJob(planId, selectedServiceData, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)"
              class="text-price custom-text-price">
            </tidy-text>
          </tidy-row>
        </div>
      </div>

      <tidy-button
        [text]="configuration?.confirm_button_text"
        [action]="confirmBooking.bind(this)"
        [disabled]="form.invalid"
        class="primary">
      </tidy-button>

      <tidy-row style="margin-top: -5px" [align]="'center'" *ngIf="showSavingsText">
        <tidy-text
          [smallBody]="getSavingsText()"
          class="white">
        </tidy-text>
      </tidy-row>

    </tidy-wrapper>
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-extra-bottom-padding' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-error-message
        *ngIf="!loaded && errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="loaded">

          <tidy-row *ngIf="pros && flowType !== 'private'">
            <form [formGroup]="form" novalidate action="#">
              <tidy-filter
                formControlName="proOption"
                [items]="prosRatingsOptions"
                [fitContent]="true"
                [newValue]="newValue"
                (optionChange)="changeFilter($event)">
              </tidy-filter>
            </form>
          </tidy-row>

          <ng-container *ngIf="prosRatingsOptions?.length == 4 && waterfallSettings?.key == 'never_use_tidy'">
            <tidy-alert>
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Unable to Book'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding">
                <tidy-text
                  [body]="'You don\'t allow new pros and haven\'t added any of your new pros. Please enable TIDY Find New Pros or add your own pros to book.'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-button
                  [text]="'Allow TIDY Find New Pros'"
                  [action]="turnOnTidyFindNewPros.bind(this)"
                  class="primary">
                </tidy-button>
              </tidy-row>
              <tidy-row>
                <tidy-button
                  [text]="'Add Pros'"
                  [action]="goToAddPro.bind(this)"
                  class="secondary">
                </tidy-button>
              </tidy-row>
            </tidy-alert>
          </ng-container>

          <ng-container *ngIf="prosRatingsOptions?.length > 4 && !proSelected && waterfallSettings?.key == 'never_use_tidy'">
            <tidy-alert>
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/alert.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Unable to Book'">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding">
                <tidy-text
                  [body]="'You don\'t allow new pros. Please enable TIDY Find New Pros to book a new pro.'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-button
                  [text]="'Allow TIDY Find New Pros'"
                  [action]="turnOnTidyFindNewPros.bind(this)"
                  class="primary">
                </tidy-button>
              </tidy-row>
            </tidy-alert>
          </ng-container>

          <ng-container *ngIf="!(prosRatingsOptions?.length == 4 && waterfallSettings?.key == 'never_use_tidy') && !(prosRatingsOptions?.length > 4 && !proSelected && waterfallSettings?.key == 'never_use_tidy')">

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [title]="'Select Service'">
              </tidy-text><br>
              <tidy-error-message
                [text]="errorMessage">
              </tidy-error-message>
              <ng-container *ngIf="!address?.address_name">
                <tidy-text
                  [body]="'For'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="address?.address1"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  [body]="address?.address2 !== null ? ' ' + address?.address2 : ''"
                  [translate]="false">
                </tidy-text>
              </ng-container>
              <ng-container *ngIf="address?.address_name">
                <tidy-text
                  [body]="'For'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="address?.address_name"
                  [translate]="false">
                </tidy-text>
              </ng-container>
              <ng-container *ngIf="flowType === 'private'">
                <tidy-text
                  [body]="' with '">
                </tidy-text>
                <tidy-text
                  [body]="proName | titlecase"
                  [translate]="false">
                </tidy-text>
                <tidy-text
                  [body]="' from '">
                </tidy-text>
                <tidy-text
                  [body]="proTeamName | titlecase"
                  [translate]="false">
                </tidy-text>
              </ng-container>
            </tidy-row>

            <tidy-alert style="padding: 15px 15px 15px 5px" *ngIf="customFlowName">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin:auto">
                        <tidy-image
                          [src]="customFlowSmallImage"
                          style="width: 50px; margin: auto">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <div style="margin-left: 5px; margin-top: 5px">
                        <tidy-text
                          [body]="customFlowName">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="'offers a'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="customFlowDiscount">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="'% discount on all cleanings.'">
                        </tidy-text>
                      </div>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-alert>

            <ng-container *ngFor="let service of services; let i = index">
              <tidy-card *ngIf="showService(service)">
                <tidy-card-button (action)="handleServiceClick(service)" [hideArrow]="true">
                  <tidy-grid>
                    <tidy-row>
                      <ion-row>
                        <ion-col size="10">
                          <tidy-row>
                            <form [formGroup]="form" novalidate action="#">
                              <tidy-radio-button
                                formControlName="service"
                                class="radio-card-title"
                                [form]="form.controls.service"
                                [items]="[{value: service.key, viewValue: service.title | titlecase}]">
                              </tidy-radio-button>
                            </form>
                          </tidy-row>
                          <tidy-row>
                            <tidy-text
                              [body]="bookJobService.getDisplayPriceForJob(planId, service, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)">
                            </tidy-text>
                            <tidy-text
                              [body]="service.description">
                            </tidy-text>
                          </tidy-row>
                        </ion-col>
                        <ion-col size="2" class="vertical-align-center">
                          <div style="margin:auto">
                            <tidy-image
                              [src]="service.image"
                              style="width:60px">
                            </tidy-image>
                          </div>
                        </ion-col>
                      </ion-row>
                    </tidy-row>
                  </tidy-grid>
                  <ng-container *ngIf="form.value.service === service.key && !configuration?.hide_frequency && !isLoggingJob">
                    <tidy-row>
                      <tidy-divider>
                      </tidy-divider>
                    </tidy-row>
                    <tidy-row class="no-padding">
                      <form [formGroup]="form" novalidate action="#">
                        <tidy-radio-button
                          *ngIf="!selectedPrivatePro"
                          [hideItemValues]="['thrice_a_week', 'twice_a_week']"
                          chunkSize="2"
                          formControlName="frequency"
                          (optionChange)="selectFrequency($event)"
                          [form]="form.controls.frequency"
                          [items]="service.frequencies"
                          (showAllOptions)="showAllFrequencies()"
                          [ordenation]="frequencyOrder">
                        </tidy-radio-button>
                        <tidy-radio-button
                          *ngIf="selectedPrivatePro"
                          chunkSize="2"
                          formControlName="frequency"
                          (optionChange)="selectFrequency($event)"
                          [form]="form.controls.frequency"
                          [items]="service.frequencies">
                        </tidy-radio-button>
                      </form>
                    </tidy-row>
                  </ng-container>
                  <ng-container *ngIf="selectedServiceData == service && !selectedPrivatePro">
                    <div *ngIf="showSavingsText" style="background: rgba(0, 170, 186, 0.8); margin-top: 10px; padding: 10px 5px 10px 5px; margin-left: -10px; margin-right: -10px; margin-bottom: -10px; border-radius: 0 0 5px 5px;">
                      <tidy-row [align]="'center'" class="vertical-align-center">
                        <ng-container *ngIf="getSavingsText()?.includes('coupon'); else notAddedAddressName">
                          <tidy-image
                            [src]="'assets/img/discount.svg'"
                            class="body-size">
                          </tidy-image>
                          <tidy-text
                            [actionBody]="'Coupon: checkout today to save $10!'"
                            class="white">
                          </tidy-text>
                        </ng-container>
                        <ng-template #notAddedAddressName>
                          <tidy-text
                          [actionBody]="getSavingsText()"
                          class="white">
                        </tidy-text>
                        </ng-template>
                      </tidy-row>
                      <tidy-row [align]="'center'" *ngIf="isUpdatingSubscription">
                        <tidy-text
                          (action)="updateSubscription(this)"
                          [actionBody]="'Remove Subscription'"
                          class="white link">
                        </tidy-text>
                      </tidy-row>
                    </div>
                    <div *ngIf="selectedFrequency == 'One Time' && choseFrequency && subscriptionExperiment == 'showInlineSubscriptionSales' && !hasNonTrialPaidSubscription" style="background: rgba(0, 170, 186, 0.8); margin-top: 10px; padding: 10px 5px 10px 5px; margin-left: -10px; margin-right: -10px; margin-bottom: -10px; border-radius: 0 0 5px 5px;">
                      <tidy-row [align]="'center'" class="extra-bottom-padding">
                        <tidy-text
                          [header]="'Upgrade to TIDY+ & Save 20%'"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row [align]="'center'" class="extra-bottom-padding">
                        <tidy-text
                          [actionBody]="'TIDY+ lets you save 20% booking per property per year, cancel anytime.'"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row [align]="'center'">
                        <tidy-text
                          [actionBody]="(service.price | tcurrency)"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [actionBody]="'for job (save 20%)'"
                          class="white">
                        </tidy-text><br>
                        <tidy-text
                          [actionBody]="'$36 for 1 year TIDY+'"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-divider style="background: white; width: 150px; margin: auto"></tidy-divider>
                      </tidy-row>
                      <tidy-row [align]="'center'" class="extra-bottom-padding">
                        <tidy-text
                          [actionBody]="((service.price + tidyPlusSubscription?.amount) | tcurrency)"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [actionBody]="'total today'"
                          class="white">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row [align]="'center'" class="extra-top-padding vertical-align-center">
                        <tidy-image
                          [src]="'assets/img/discount.svg'"
                          class="header-size">
                        </tidy-image>
                        <tidy-text
                          (click)="updateSubscription(this)"
                          [largeBody]="isUpdatingSubscription ? 'Remove TIDY+' : 'Add TIDY+'"
                          class="white link"
                          style="cursor: pointer">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row [align]="'center'" class="extra-top-padding">
                        <tidy-text
                          (click)="goToViewSubscriptions(this)"
                          [actionBody]="'View Other Subscription Types'"
                          class="white link"
                          style="cursor: pointer">
                        </tidy-text>
                      </tidy-row>
                    </div>
                  </ng-container>
                </tidy-card-button>
              </tidy-card>
            </ng-container>

            <tidy-row [align]="'center'" class="extra-bottom-padding" *ngIf="choseService && !showMoreServices">
              <tidy-text
                (action)="showServices()"
                [body]="'Show More Services'"
                class="link">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-text
                [title]="moreTimesPerWeekSelected ? 'Select First Time' : 'Select Time'">
              </tidy-text>
            </tidy-row>

            <ng-container *ngIf="!isLoggingJob">

              <tidy-loader *ngIf="isLoadingTimes"></tidy-loader>

              <tidy-alert *ngIf="!isLoadingTimes && !choseFrequency">
                <tidy-row [align]="'center'">
                  <tidy-text
                    [body]="notChoseServiceSentence">
                  </tidy-text>
                </tidy-row>
              </tidy-alert>

              <ng-container *ngIf="!isLoadingTimes && choseService && choseFrequency">

                <tidy-row *ngIf="showRequestPro()" class="extra-bottom-padding">
                  <tidy-text
                    [body]="(selectedPro.viewValue | titlecase) + ' not available? '">
                  </tidy-text>
                  <tidy-text
                    [body]="'Send them a request'"
                    class = "link"
                    (action)="goToRequestPro()">
                  </tidy-text>
                </tidy-row>

                <tidy-row *ngIf="showRequestJob()">
                  <tidy-text
                    [body]="'Don\'t see a time you like?'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'Request time'"
                    class = "link"
                    (action)="goToRequestJob()">
                  </tidy-text>
                </tidy-row>

                <tidy-alert *ngIf="!isLoadingTimes && noAvailableTimes && !selectedPrivatePro">
                  <tidy-row [align]="'center'">
                    <tidy-text
                      [header]="getNotBookableHeader(proNotBookableReason?.text)">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="proSelected" [align]="'center'" [class.extra-bottom-padding]="!minRateToBookWithPro">
                    <tidy-text
                      [body]="proNotBookableReason?.text">
                    </tidy-text>
                    <ng-container *ngIf="proNotBookableReason?.request_eligible && !minRateToBookWithPro">
                      <tidy-text
                        [body]="' '">
                      </tidy-text>
                      <tidy-text
                        [body]="'Request Job with'"
                        class="link"
                        (action)="goToRequestPro()">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="selectedPro.viewValue"
                        class="link"
                        (action)="goToRequestPro()">
                      </tidy-text>
                    </ng-container>
                  </tidy-row>
                  <ng-container *ngIf="minRateToBookWithPro">
                    <tidy-row>
                      <tidy-button
                        *ngIf="minRateToBookWithPro"
                        [text]="('Change Rates to ' | translate) + (minRateToBookWithPro | tcurrency)"
                        [action]="changeServiceRates.bind(this)"
                        class="primary">
                      </tidy-button>
                    </tidy-row>
                    <tidy-row [align]="'center'" class="extra-bottom-padding">
                      <tidy-text
                        [body]="'You can also '">
                      </tidy-text>
                      <tidy-text
                        [body]="('request a job with ' | translate) + selectedPro.viewValue"
                        class="link"
                        (action)="goToRequestPro()">
                      </tidy-text>
                      <tidy-text
                        [body]="' and they may still accept at your current rates.'">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                  <tidy-row *ngIf="selectedPro?.value === 'all'" [align]="'center'">
                    <tidy-text
                      [body]="'Unfortunately there is no availability in your area for this plan type.'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row *ngIf="selectedPro?.value !== 'all' && categoryId == 1" [align]="'center'">
                    <tidy-text
                      (action)="showAnyRatingFilter()"
                      [body]="'Click here'"
                      class="link">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'to view all of our availability.'">
                    </tidy-text>
                  </tidy-row>
                </tidy-alert>

                <tidy-card *ngFor="let item of dateTimes; index as idx">
                  <tidy-row *ngIf="!selectedPrivatePro">
                    <tidy-text
                      [header]="item.date | customdate:'dddd M/D':''">
                    </tidy-text>
                  </tidy-row>
                  <ng-container *ngIf="selectedPrivatePro">
                    <tidy-row class="field-top-padding extra-bottom-padding">
                      <form [formGroup]="form" novalidate action="#">
                        <tidy-select
                          [label]="'Select Date'"
                          [form]="form.controls.privateDate"
                          formControlName="privateDate"
                          [items]="privateJobDates"
                          (optionChange)="changeDay($event)">
                        </tidy-select>
                      </form>
                    </tidy-row>
                    <tidy-loader *ngIf="isLoadingPrivateTimes"></tidy-loader>
                    <tidy-row *ngIf="!isLoadingTimes && !isLoadingPrivateTimes && item.times.length === 0" [align]="'center'">
                      <tidy-text
                        [body]="'No Times Available'">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                  <tidy-row *ngIf="!isLoadingTimes && !isLoadingPrivateTimes && !noAvailableTimes">
                    <form [formGroup]="form" novalidate action="#">
                      <tidy-radio-button
                        chunkSize="3"
                        formControlName="time"
                        (optionChange)="selectTime($event, idx)"
                        [form]="form.controls.time"
                        [(ngModel)]="form.value.time"
                        [items]="item.times">
                      </tidy-radio-button>
                    </form>
                  </tidy-row>
                </tidy-card>

                <ng-container *ngIf="!isLoadingTimes && !noAvailableTimes && dateTimes?.length && flowType !== 'private' && prosRatingsOptions?.length !== 1">
                  <ng-container *ngIf="categoryId == 1 && selectedPro?.viewValue !== '4.0+ Star Rating' && selectedPro?.viewValue !== 'Any Rating'; else seeingAllAvailability">
                    <tidy-row [align]="'center'">
                      <tidy-text
                        [body]="'Don\'t see a time you like?'">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row [align]="'center'">
                      <tidy-text
                        (action)="showAnyRatingFilter()"
                        [body]="'Click here'"
                        class="link">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="'to view all of availability.'">
                      </tidy-text>
                    </tidy-row>
                  </ng-container>
                  <ng-template #seeingAllAvailability>
                    <tidy-row [align]="'center'">
                      <tidy-text
                        [body]="'Don\'t see a time you like?'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        *ngIf="showRequestJob(); else messageConcierge"
                        [body]="'Request time'"
                        class = "link"
                        (action)="goToRequestJob()">
                      </tidy-text>
                      <ng-template #messageConcierge>
                        <tidy-text
                          [body]="'Message Concierge'"
                          class = "link"
                          (action)="goToContactConcierge()">
                        </tidy-text>
                      </ng-template>
                    </tidy-row>
                  </ng-template>
                </ng-container>

              </ng-container>
            </ng-container>
          </ng-container>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper class="custom-right-column" *ngIf="loaded && choseService && windowService.isDesktopRes">

    <tidy-row [align]="'center'" class="extra-top-padding">
      <tidy-text
        [title]="serviceLabel() | titlecase">
      </tidy-text>
    </tidy-row>

    <tidy-row class="vertical-align-center" *ngIf="!configuration?.hide_frequency && selectedFrequency">
      <tidy-image
        [src]="'assets/svg/refresh-circle-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="selectedFrequency | titlecase">
      </tidy-text>
    </tidy-row>

    <ng-container *ngIf="selectedDuration">
      <tidy-row class="vertical-align-center" *ngIf="choseFrequency || bookingExperiment == 'consumer-control' || bookingExperiment == 'rental-control'">
        <tidy-image
          [src]="'assets/svg/card-outline.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          [body]="bookJobService.getDisplayPriceForJob(planId, selectedServiceData, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)">
        </tidy-text>
      </tidy-row>
      <tidy-row class="vertical-align-center" *ngIf="!serviceLabel().includes('hour')">
        <tidy-image
          [src]="'assets/svg/time-outline.svg'"
          class="body-size">
        </tidy-image>
        <tidy-text
          *ngIf="selectedBillingType == 'hourly'"
          [body]="'Minimum ' + (selectedDuration | duration)">
        </tidy-text>
        <tidy-text
          *ngIf="selectedBillingType !== 'hourly'"
          [body]="selectedDuration | duration">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <tidy-row class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/calendar-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="dateLabel">
      </tidy-text>
    </tidy-row>

    <tidy-row *ngIf="(form?.value?.frequency === 'twice_a_week' || form?.value?.frequency === 'thrice_a_week') && secondDateLabel" class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/calendar-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="secondDateLabel">
      </tidy-text>
    </tidy-row>

    <tidy-row *ngIf="form?.value?.frequency === 'thrice_a_week' && thirdDateLabel" class="vertical-align-center">
      <tidy-image
        [src]="'assets/svg/calendar-outline.svg'"
        class="body-size">
      </tidy-image>
      <tidy-text
        [body]="thirdDateLabel">
      </tidy-text>
    </tidy-row>

    <tidy-button
      [text]="configuration?.confirm_button_text"
      [action]="confirmBooking.bind(this)"
      [disabled]="form.invalid"
      class="primary">
    </tidy-button>

    <tidy-row style="margin-top: -5px" [align]="'center'" *ngIf="showSavingsText">
      <tidy-text
        [smallBody]="getSavingsText()">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <!-- <div class="wrapper-containing-block"> -->
    <app-scroll-wrapper [style.padding-top.px]="topNavHeight" [showCardDesign]="true" [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>
  <!-- </div> -->
</ng-container>

<ng-template #reviewBooking>
  <tidy-wrapper class="custom-right-column black-column">

    <div style="display: flex; justify-content: space-between; align-items: flex-start;" *ngIf="loaded">
      <div>
        <tidy-row class="vertical-align-center">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_1_white.svg'">
          </tidy-image>
          <tidy-text
            [actionBody]="serviceFrequencyLabel() | titlecase"
            class="white">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_2_white.svg'">
          </tidy-image>
          <tidy-text
            [actionBody]="dateLabel"
            class="white">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" *ngIf="(form?.value?.frequency === 'twice_a_week' || form?.value?.frequency === 'thrice_a_week') && secondDateLabel">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_3_white.svg'">
          </tidy-image>
          <tidy-text
            [actionBody]="secondDateLabel"
            class="white">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center" *ngIf="form?.value?.frequency === 'thrice_a_week' && thirdDateLabel">
          <tidy-image
            class="body-size"
            [src]="'assets/img/icons/number_4_white.svg'">
          </tidy-image>
          <tidy-text
            [actionBody]="thirdDateLabel"
            class="white">
          </tidy-text>
        </tidy-row>
      </div>
      <div>
        <tidy-row class="extra-left-padding" *ngIf="selectedDuration && selectedServiceData">
          <tidy-text
            *ngIf="selectedServiceData?.billingType !== 'price_later' && selectedServiceData?.billingType !== 'hourly'"
            [title]="bookJobService.getDisplayPriceForJob(planId, selectedServiceData, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)"
            class="text-price custom-text-price">
          </tidy-text>
          <tidy-text
            *ngIf="selectedServiceData?.billingType == 'hourly' || selectedServiceData?.billingType == 'price_later'"
            [header]="bookJobService.getDisplayPriceForJob(planId, selectedServiceData, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, selectedPrivatePro)"
            class="text-price custom-text-price">
          </tidy-text>
        </tidy-row>
      </div>
    </div>


    <tidy-button
      [text]="configuration?.confirm_button_text"
      [action]="confirmBooking.bind(this)"
      [disabled]="form.invalid"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="isRightSideContent">
  <div class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="reviewBooking"></ng-container>
  </div>
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

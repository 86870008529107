<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Welcome to TIDY!'"
    [class]="extraClass"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'You have'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'<b>' + (creditBalance | tcurrency) + '<b>'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'credit'">
      </tidy-text>
      <ng-container *ngIf="employerName && employerName !== 'undefined'">
        <span>&nbsp;</span>
        <tidy-text
          [body]="'from'">
        </tidy-text>
        <tidy-text
          [body]="employerName"
          [translate]="false">
        </tidy-text>
      </ng-container>
      <tidy-text
        [body]="'. Add a pro to send them payment from your credit.'">
      </tidy-text>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'TIDY works best with your own pros. If you don\'t have one, ask a neighbor or friend for a referral as a first step.'">
      </tidy-text>
    </tidy-row>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToAddPro()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/user-red.svg'"
                    [ngStyle]="{'width': '30px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Add Pro'"
                    style="margin-right:5px">
                  </tidy-text>
                  <tidy-text
                    [helper]="'(recommended)'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Pros receive an email when you send a payment. For them it\'s similar to accepting credit card or Venmo.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToFindNewPro()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/icon/searching.svg'"
                    [ngStyle]="{'width': '35px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Find New Pro'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'If you don\'t have anyone with a referral, use our network to find a pro to help you.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

    <tidy-card style="padding: 15px 15px 15px 5px">
      <tidy-card-button (action)="goToViewApp()">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin:auto">
                  <tidy-image
                    [src]="'assets/img/app.svg'"
                    [ngStyle]="{'width': '45px'}">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'View the App'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Setup To-Dos, get maintenance suggestions & more.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

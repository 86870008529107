<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Billing History'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <ng-container *ngIf="item?.isPendingClientCharge">
        <ion-chip [class]="item?.class">
          <ion-icon [name]="item?.icon"></ion-icon>
          <ion-label>{{ item?.status | titlecase }}</ion-label>
        </ion-chip>
        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            style="margin-right:5px"
            [header]="(item?.amount | tcurrency) + (' charge' | translate)">
          </tidy-text>
          <tidy-image
            style="margin-left: 5px; cursor: pointer"
            (action)="goToBillingItemHelp(item)"
            [src]="'assets/svg/help-circle-outline.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="item?.formattedDate">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <ng-container *ngIf="item?.payment_method?.type == 'card'">
            <tidy-text
              [body]="('Card ending in ' | translate) + item?.payment_method?.metadata?.last4">
            </tidy-text>
            <tidy-text *ngIf="item?.payment_method?.is_primary"
              [header]="'(primary)'"
              style="margin-left: 5px">
            </tidy-text>
          </ng-container>
          <tidy-text
            *ngIf="item?.payment_method?.type == 'us_bank_account'"
            [body]="'Bank Account'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="item?.payment_method?.metadata?.bank_name">
          <tidy-text
            [body]="item?.payment_method?.metadata?.bank_name + (item?.payment_method?.metadata?.account_type ? ' | ' + item?.payment_method?.metadata?.account_type : '')">
          </tidy-text>
        </tidy-row>
      </ng-container>
      <ng-container *ngIf="!item?.isPendingClientCharge">
        <tidy-row [alignLastItemRight]="true" [ngClass]="item?.refund_type ? '' : 'extra-bottom-padding'">
          <tidy-text
            style="margin-right:5px"
            [header]="(item?.amount | tcurrency) + ' ' + (item?.credit_type !== null ? item?.credit_type : '')">
          </tidy-text>
          <tidy-text
            *ngIf="item?.payment_method?.type == 'card'"
            [body]="('on card ' | translate) + item?.payment_method?.metadata?.last4">
          </tidy-text>
          <tidy-text
            *ngIf="item?.payment_method?.type == 'us_bank_account'"
            [body]="('on bank ' | translate) + (item?.payment_method?.metadata?.last4 ? item?.payment_method?.metadata?.last4 : '')">
          </tidy-text>
          <tidy-image
            *ngIf="!hasProSelected && item?.credit_type !== 'credit'"
            style="margin-left: 5px; cursor: pointer"
            (action)="goToBillingItemHelp(item)"
            [src]="'assets/svg/help-circle-outline.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="(item?.date | customdate: 'M/D/YY')">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding" *ngIf="item?.refund_type">
          <tidy-text
            [actionBody]="item?.refund_type"
            class="green">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="item?.added_by === 'cleaning' && item?.credit_type === 'credit'" [ngClass]="{'extra-bottom-padding': item?.details?.length > 0}">
          <tidy-text
            [body]="'Added from job on '">
          </tidy-text>
          <tidy-text
            [actionBody]="item?.source_cleaning_date | customdate: 'M/D/YY'"
            (action)="goToCleaningDetails(item?.source_cleaning_id)"
            class="link">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="item?.added_by === 'concierge' && item?.credit_type === 'credit'" [ngClass]="{'extra-bottom-padding': item?.details?.length > 0}">
          <tidy-text
            [body]="'Added by '">
          </tidy-text>
          <tidy-text
            *ngIf="item?.transferred_from"
            [body]="item?.transferred_from?.account_name"
            [translate]="false">
          </tidy-text>
          <tidy-text
            *ngIf="!item?.transferred_from"
            [body]="'Concierge'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let detail of item?.details; let i = index; let last = last">
          <tidy-row [ngClass]="{'extra-bottom-padding': !last}">
            <tidy-text
              *ngIf="detail?.debit_type !== 'refund'"
              [body]="(detail?.amount_used | tcurrency)">
            </tidy-text>
            <tidy-text
              *ngIf="detail?.debit_type !== 'refund'"
              [body]="' used'">
            </tidy-text>
            <ng-container *ngIf="detail?.debit_type !== 'refund' && detail?.debit_source_billable_type !== 'Infrastructure::Models::Plan::SubscriptionInvoice'">
              <ng-container *ngIf="!detail?.transferred_to">
                <tidy-text
                  [body]="' by a '">
                </tidy-text>
                <tidy-text
                  [body]="detail?.cleaning_state | formatState">
                </tidy-text>
                <tidy-text
                  [body]="' job on '">
                </tidy-text>
                <tidy-text
                  [actionBody]="(detail?.cleaning_date | customdate: 'M/D/YY')"
                  (action)="goToCleaningDetails(detail?.cleaning_id)"
                  class="link">
                </tidy-text>
              </ng-container>
              <ng-container *ngIf="detail?.transferred_to">
                <tidy-text
                  [body]="' by'">
                </tidy-text>
                <tidy-text
                  [body]="detail?.transferred_to?.team?.name"
                  (action)="changePro(detail?.transferred_to?.team?.owner_id)"
                  class="link"
                  [translate]="false">
                </tidy-text>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="detail?.debit_source_billable_type == 'Infrastructure::Models::Plan::SubscriptionInvoice'">
              <tidy-text
                [body]="' for '">
              </tidy-text>
              <tidy-text
                [actionBody]="'your subscription'"
                (action)="goToSubscriptionPage()"
                class="link">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="detail?.debit_type == 'debit' && (detail?.cleaning_state == 'cancelled' || detail?.cleaning_state == 'cancelled_once') && detail?.booking_id !== null">
              <tidy-text
                [body]="' '">
              </tidy-text>
              <tidy-text
                [actionBody]="'Waive Fee'"
                (action)="waiveFee(detail?.booking_id)"
                class="link">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="detail?.debit_type === 'refund'">
              <tidy-text
                [body]="detail?.amount_used | tcurrency">
              </tidy-text>
              <tidy-text
              [body]="' refunded. '">
            </tidy-text>
              <tidy-text
                [actionBody]="'Where is my refund?'"
                (action)="learnAboutRefund()"
                class="link">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </ng-container>
      </ng-container>
      <tidy-card-button *ngIf="item?.credit_type === 'charge'" (action)="getRefund(item?.refundable)">
        <tidy-row class="vertical-align-center extra-top-padding">
          <tidy-image
            [src]="'assets/img/refund.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="'Get Refund'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
      <tidy-card-button (action)="getHelp(item)">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/question.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [body]="'Get Help'">
          </tidy-text>
        </tidy-row>
      </tidy-card-button>
    </tidy-card>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

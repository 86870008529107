import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Loading } from 'src/shared/components/loading/loading';
import { Genome } from 'src/providers/genome/genome';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { PhotoCaptionService } from 'src/shared/components/photo-caption/photo-caption.service';
import { Util } from 'src/shared/util/util';
import { PhotoNotes } from 'src/providers/photo-notes/photo-notes';

import { ItemDetailsPage } from 'src/pages/more/edit-address/room-item-details/room-item-details';
import { Capacitor } from '@capacitor/core';

@Component({
  templateUrl: 'edit-room-item.html'
})

export class EditRoomItemPage implements OnInit {

  addressId: number;
  categoryChosen: boolean;
  characteristics: {name: String, formData: {value: number, viewValue: String}}[];
  characteristicsChosen: { [id: string] : number} = {};
  characteristicsArray: any = [];
  dialogParams: any;
  errorMessage: string;
  form: UntypedFormGroup;
  getInventoryAlerts: boolean;
  isRightSideContent: boolean;
  itemCategory: any;
  item: any;
  loaded: boolean;
  models: any = [];
  modelForm: UntypedFormGroup;
  submitted: boolean;
  deliveryTimingPreferenceItems = [
    { value: 'Between guest stays', viewValue: 'Between guest stays' },
    { value: 'As soon as possible', viewValue: 'As soon as possible' }
  ];
  isNativeMobile = Capacitor.isNativePlatform();
  selectedModelID: number;
  isAddingNewModel: boolean;
  isInitialFormSetup: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util,
    private photoCaptionService: PhotoCaptionService,
    private photoNotes: PhotoNotes
  ) {
    this.form = this.fb.group({
      characteristic: ['',],
      model: ['',],
      minQuantity: [''],
      maxQuantity: [''],
      currentQuantity: [''],
      opportunisticOrderLevel: [''],
      minRestockingPrice: [''],
      maxRestockingPrice: [''],
      whereToOrder: [''],
      deliveryTimingPreferences: [''],
      restockingNotes: ['']
    });
    this.modelForm = this.fb.group({
      newModel: ['', Validators.required]
    });
    this.form.get('model').valueChanges.pipe(
      debounceTime(100)
    ).subscribe(val => {
      if (this.isInitialFormSetup) {
        return;
      }
      if (val === '' || val === null) {
        this.selectedModelID = null;
        this.isAddingNewModel = true;
      } else if (val && typeof val === 'string') {
        const matchingOption = this.models.find(option => option.display === val);
        if (matchingOption) {
          this.selectedModelID = matchingOption.value;
          this.isAddingNewModel = false;
        } else {
          this.isAddingNewModel = true;
        }
      }
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.categoryChosen = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.item = this.navCtrl.getParam('item') || this.dialogParams.item || await this.genome.getRoomObject(this.dialogParams.itemId);
      this.selectedModelID = this.item.model.id;
      this.rightSidePanelService.setDialogPageTitle(this.item.model.category.name);
      this.addressId = this.navCtrl.getParam('addressId') || this.dialogParams.addressId;
      this.itemCategory = this.item.model.category.id;
      this.selectCategory(this.itemCategory);
      if (this.item.min_quantity) {
        this.selectGetInventoryAlerts();
        this.form.patchValue({
          minQuantity: this.item?.min_quantity ? this.item?.min_quantity.toString() : null,
          currentQuantity: this.item?.current_quantity ? this.item?.current_quantity.toString() : null,
          maxQuantity: this.item?.max_quantity ? this.item?.max_quantity.toString() : null,
          minRestockingPrice: this.item?.min_restocking_price ? this.item?.min_restocking_price.toString() : null,
          maxRestockingPrice: this.item?.max_restocking_price ? this.item?.max_restocking_price.toString() : null,
          whereToOrder: this.item?.where_to_order,
          deliveryTimingPreferences: this.item?.delivery_timing_preference,
          restockingNotes: this.item?.restocking_notes,
          opportunisticOrderLevel: this.item?.opportunistic_quantity ? this.item?.opportunistic_quantity.toString() : null
        });
      }
      this.selectedModelID = this.item.model.id;
      this.isInitialFormSetup = true;
      this.form.patchValue({
        model: this.item?.name || '',
      });
      this.loaded = true;
      setTimeout(() => {
        this.isInitialFormSetup = false;
      }, 200);
    } catch (err) {
      console.log('err', err);
      this.util.showError('Error loading item. Please try again.', err);
    }
  }
  @Loading('', true)
  async selectCategory(categoryId) {
    this.models = [];
    this.characteristicsChosen = {};
    const characteristics = await this.genome.getCharacteristics(categoryId);
    this.characteristics = characteristics.map((characteristic, index) => {
      const forms = characteristic.characteristics.map((section) => {
        const formData = { value: section.id, viewValue: section.name };
        return formData;
      });
      this.characteristicsChosen[characteristic.name] = -1;
      const data = {
        name: characteristic.name,
        formData: forms,
      };
      this.form.addControl('characteristic_' + index, new FormControl(null));
      return data;
    });
    await this.getModels('');
    this.categoryChosen = true;
  }

  @Loading('', true)
  selectCharacteristic(characteristic, id){
    try {
      this.characteristicsChosen[characteristic.name] = id;
      let query = this.buildQueryString(this.characteristicsChosen);
      this.getModels(query);
    } catch (err) {
      console.error('err', err);
    }
  }

  @Loading('', true)
  async getModels(query){
    const models = await this.genome.getObjectModel(this.itemCategory, query);
    this.models = models.map((model) => {
      const formData = {
        value: model.id,
        display: model.model
      };
      return formData;
    });
  }

  selectModel(model) {
    this.selectedModelID = model.value;
    this.isAddingNewModel = false;
  }

  buildQueryString(obj) {
    let finalQuery = '';
    let finalArray = [];
    for (var key in obj) {
      if(obj[key] != -1){
        finalQuery += `&filters[characteristic_id][]=${obj[key]}`;
        finalArray.push(obj[key]);
      }
    }
    this.characteristicsArray = finalArray;
    return finalQuery;
  }

  async updateItem() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.errorMessage = '';
    let data;
    data = {
      characteristic_ids: this.characteristicsArray,
      model_id: this.selectedModelID,
      photo_url: this.item.photo_url
    }
    if (this.getInventoryAlerts) {
      data['min_quantity'] = this.form.value.minQuantity;
      data['current_quantity'] = this.form.value.currentQuantity ? this.form.value.currentQuantity : null;
      data['max_quantity'] = this.form.value.maxQuantity ? this.form.value.maxQuantity : null;
      data['opportunistic_quantity'] = this.form.value.opportunisticOrderLevel ? this.form.value.opportunisticOrderLevel : null;
      data['delivery_timing_preference'] = this.form.value.deliveryTimingPreferences ? this.form.value.deliveryTimingPreferences : null;
      data['restocking_notes'] = this.form.value.restockingNotes ? this.form.value.restockingNotes : null;
      data['where_to_order'] = this.form.value.whereToOrder ? this.form.value.whereToOrder : null;
      data['min_restocking_price'] = this.form.value.minRestockingPrice ? parseFloat(this.form.value.minRestockingPrice.replace('$', '')) : null;
      data['max_restocking_price'] = this.form.value.maxRestockingPrice ? parseFloat(this.form.value.maxRestockingPrice.replace('$', '')) : null;
    } else {
      data['min_quantity'] = null;
      data['current_quantity'] = null;
      data['max_quantity'] = null;
      data['delivery_timing_preference'] = null;
      data['restocking_notes'] = null;
      data['where_to_order'] = null;
      data['min_restocking_price'] = null;
      data['max_restocking_price'] = null;
      data['opportunistic_quantity'] = null;
    }
    try {
      if (this.isAddingNewModel) {
        const modelData = {
          category_id: this.itemCategory,
          model: this.form.value.model,
          name: this.form.value.model,
          characteristic_ids: this.characteristicsArray
        }
        const newModel = await this.genome.addNewModel(modelData);
        data.model_id = newModel.id;
        data.name = this.form.value.model;
      }
      if (this.item.photo_url && !this.item.photo_url.includes('https://')) {
        data['photo_url'] = await this.uploadPhoto();
      }
      await this.genome.updateRoomObject(this.item.id, data);
      const params = {
        addressId: this.addressId,
        itemId: this.item.id,
        cameFromEditItem: true
      };
      this.rightSidePanelService.navigateTo(`item-details/${this.item.id}`, params, ItemDetailsPage);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async uploadPhoto() {
    const photo = await this.photoNotes.uploadPictureToS3(this.item.photo_url , `item/${this.item.id}`);
    return photo.Location;
  }

  selectGetInventoryAlerts() {
    this.getInventoryAlerts = !this.getInventoryAlerts;
    if (this.getInventoryAlerts) {
      this.form.controls.minQuantity.setValidators([Validators.required]);
    } else {
      this.form.controls.minQuantity.clearValidators();
      this.form.controls.minQuantity.updateValueAndValidity();
      this.form.patchValue({
        deliveryTimingPreferences: null,
        opportunisticOrderLevel: null,
        whereToOrder: null,
        minRestockingPrice: null,
        maxRestockingPrice: null,
        restockingNotes: null,
        minQuantity: null,
        maxQuantity: null,
        currentQuantity: null
      });
    }
  }

  async addImage() {
    try {
      const newPhotoNote = await this.photoCaptionService.getImg('task');
      this.item.photo_url = newPhotoNote.url;
    } catch (err) {
      this.util.showError('Error uploading photo. Please try again.', err);
    }
  }

  removeImage(event) {
    this.item.photo_url = null;
  }

}

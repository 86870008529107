import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from 'src/shared/providers/window.service';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Client } from 'src/providers/client/client';

import { ClientSettingsModel } from 'src/models/client.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';

@Component({
  templateUrl: 'payment-settings.html',
  encapsulation: ViewEncapsulation.None
})

export class PaymentSettingsPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  settings: ClientSettingsModel;
  cantGoBack: boolean;
  updatedSettings: {billing: {charge: {type: string}}} = {billing: {charge: {type: ''}}};
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      setting: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Payment Settings');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.cantGoBack = this.route.snapshot.data.cantGoBack;
    this.settings = await this.getParam('setting');
    this.form.patchValue({
      setting: this.settings.billing.charge.type
    });
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async save() {
    this.updatedSettings.billing.charge.type = this.form.value.setting;
    try {
      const response = await this.client.saveClientSettings(this.updatedSettings);
      this.storage.save('billingType', response?.billing?.charge?.type);
    } catch (err) {
      return this.errorMessage = err.message === 'You are not allowed to change to charge after' ? 'You can change your payment settings only after booking a job.' : err.message;
    }
    const successParams = {
      header: 'Payment Settings Updated',
      body: '',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
    this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
  }
}

import { debounceTime } from 'rxjs/operators';
import { WindowService } from 'src/shared/providers/window.service';
import { Component, ViewEncapsulation, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DateTime as LuxonDateTime } from 'luxon';

import { Addresses } from "src/providers/customer/addresses";
import { AvailableTimesForRange } from 'src/providers/calendar/available-times-for-range';
import { BookJob } from 'src/providers/book-job/book-job';
import { CalendarDay } from 'src/providers/calendar/calendar-day';
import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Me } from 'src/providers/me/me';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

import { Homekeeper } from 'src/providers/homekeeper/homekeeper'

import { Loading } from 'src/shared/components/loading/loading';

import { AddressModel } from 'src/models/address.model';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';

import { bookJobConfigurations } from 'src/pages/booking/book-job/book-job-configurations';
import { ModalController } from '@ionic/angular';
import { ModalMoreTimesPage } from 'src/pages/booking/modal-more-times/modal-more-times';
import { AvailableTimesModel, ModalMoreTimeDataModel, ProModel, ProTeamServices, SelectedTimesModel } from 'src/models/book-job.model';
import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import { Subscription } from 'rxjs';
import { Logger } from 'src/providers/logger';
import { OnboardingFlow } from 'src/shared/enums/onboarding-flow';
import { PlanData } from 'src/providers/plans/plan-data';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from 'src/shared/components/subscription-modal/subscription-modal';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { ConfirmBookingPage } from '../confirm-booking/confirm-booking';
import { ConfirmBookingPaymentPage } from 'src/pages/confirm-booking-payment/confirm-booking-payment';
import { RequestProPage } from 'src/pages/request-pro/request-pro';
import { RequestJobPage } from 'src/pages/request-job/request-job';
import { RequestSubstitutePage } from 'src/pages/request-substitute/request-substitute';

import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'book-job.html',
  styleUrls: ['./book-job.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'book-job'
})

export class BookJobPage implements OnDestroy {

  bookingType;
  bookingExperiment: string;
  categoryId: any;
  configuration;
  providedStartDateIsBeforeTomorrow: boolean;
  customFlowName: string;
  customFlowSmallImage: string;
  customFlowDiscount: number;
  isUpdatingSubscription: boolean;
  priceExperiment: any;
  jobId;
  address: AddressModel;
  bookingId;
  backPage: string;
  flowType: string;
  choseFrequency = false;
  currentSubscription: any;
  endDay: any;
  frequencyValue: any;
  hasValidCC: boolean;
  moreTimesPerWeekSelected = false;
  ratingChosen = false;
  defaultRatings = []
  prosRatingsOptions = [];
  proName: string;
  proTeamName: string;
  startDay: any;
  dateTimes = [];
  defaultBookingKey: any;
  privateJobDates: any;
  isRentalClient: boolean;
  isSelfBooking: boolean;
  choseService = false
  errorMessage: string;
  filterByPro: any;
  shouldNotDefaultToLastPro: boolean;
  subscriptions: Array<Subscription> = [];
  frequencyOrder: number[] = [0,1,2,3,4,5];
  hkId: any;
  loaded: boolean;
  notChoseServiceSentence: string;
  hasNonTrialPaidSubscription: boolean;
  minRateToBookWithPro: any;
  providedStartDate: any;
  planId;
  proNotBookableReason: any;
  pros: ProModel[] = [];
  proSelected = false;
  selectedDate;
  selectedDuration;
  selectedFrequency = 'Frequency'
  selectedPrice;
  selectedPro: any;
  selectedService = 'Service';
  selectedBillingType: string;
  selectedServiceImage: string;
  selectedPrivatePro: boolean;
  selectedServiceData: ProTeamServices;
  selectedTime;
  services: ProTeamServices[] = [];
  servicesResponse: ProTeamServices[] = [];
  shouldInsteadAddJob: any;
  showSavingsText: boolean;
  showMoreServices: boolean;
  subscriptionType: any;
  subscriptionExperiment: any;
  tidyPlusSubscription: any;
  noAvailableTimes = true;
  isLoadingTimes = false;
  isLoadingPrivateTimes = false;
  isLoggingJob: boolean;
  logJobTimeItems: any = [];
  dateLabel: string;
  secondDateLabel: string;
  thirdDateLabel: string;
  selectedTimeValue: any;
  waterfallSettings: any;

  form: UntypedFormGroup;
  allTimesSelected = [];
  newValue: string;
  dialogParams: any;
  isRightSideContent = true;
  topNavHeight: number = 0;
  private resizeObserver: ResizeObserver;

  constructor(
    private addresses: Addresses,
    private availableTimesForRange: AvailableTimesForRange,
    public bookJobService: BookJob,
    private calendarDay: CalendarDay,
    private card: Card,
    private client: Client,
    private homekeeper: Homekeeper,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    public me: Me,
    private modalCtrl: ModalController,
    private logger: Logger,
    private planData: PlanData,
    private storage: TidyStorage,
    private dialog: MatDialog,
    private rightSidePanelService: RightSidePanelService,
    private util: Util,
    public windowService: WindowService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      proOption: ['', Validators.required],
      pros: ['', Validators.minLength(0)],
      service: ['', Validators.required],
      frequency: [''],
      time: ['', Validators.required],
      privateDate: [''],
      serviceCategories: ['']
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    if (this.dialogParams) this.dialogParams['showSameDayTimeAlert'] = null;
    await this.storage.save('dialog-params', this.dialogParams);
    await this.loadDataAndParams();
    await this.watchFormChangesAndSaveCartMetadata();
    this.observeTopNavHeight();
  }

  private observeTopNavHeight() {
    const element = document.getElementById('top-nav-height');
      if (element) {
        this.topNavHeight = element.offsetHeight;
        this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.topNavHeight = entry.contentRect.height;
          this.changeDetectorRef.detectChanges();
        }
      });
      this.resizeObserver.observe(element);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  async watchFormChangesAndSaveCartMetadata() {
    const storedCartMetadata = await this.storage.retrieve('cartMetadata') || {};
    const isFirstBooking = await this.storage.retrieve('isFirstBooking');
    if (isFirstBooking) {
      this.form.valueChanges.pipe(debounceTime(1000)).subscribe(async (value) => {
        const updatedCartMetadata = {
          ...storedCartMetadata,
          bookingForm: value,
          addressName: this.address?.address_name || (this.address?.address1 + ' ' + this.address?.address2 + ', ' + this.address?.zip + ', ' + this.address?.add_state),
        }
        await this.storage.save('cartMetadata', updatedCartMetadata);
        const payload = { key: 'cartMetadata', value: JSON.stringify(updatedCartMetadata) };
        await this.client.addMetadata(payload);
      });
    }
  }

  @Loading('', true)
  async loadDataAndParams() {
    try {
      this.rightSidePanelService.setDialogLoading(true);
      this.errorMessage = '';
      this.loaded = false;
      await this.getPageParams();
      await this.initPageData();
      this.configuration = bookJobConfigurations[this.bookingType]
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.backPage = await this.storage.retrieve('bookJobBackPage') || (shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
      if (this.isRentalClient) {
        this.isUpdatingSubscription = localStorage.getItem('isUpdatingSubscription') == 'true';
        const subscriptionTypes = await this.me.getSubscriptionTypes();
        this.hasNonTrialPaidSubscription = await this.me.checkIfHasNonTrialPaidSubscription(subscriptionTypes);
        this.tidyPlusSubscription = subscriptionTypes.find((type) => type.key == 'tidy_plus_yearly_subscription_not_trial');
        this.storage.save('tidyPlusSubscription', this.tidyPlusSubscription);
        this.currentSubscription = await this.storage.retrieve('currentSubscription');
        this.subscriptionType = this.me.getSubscriptionType(this.currentSubscription?.key);
        if (this.subscriptionType == 'Free') {
          this.isUpdatingSubscription = false;
        }
      }
      this.showSavingsText = this.checkIfShouldShowSavingsText();
      this.rightSidePanelService.setDialogPageTitle(this.configuration.title);
      this.loaded = true;
      this.rightSidePanelService.setDialogLoading(false);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async getPageParams() {
    this.address = await this.getParam('address');
    if (this.address === null || this.address === undefined) {
      return this.showNoAddressAlert();
    }
    this.bookingType = await this.getParam('bookingType') || 'add_job';
    this.isLoggingJob = await this.getParam('isLoggingJob');
    this.jobId = await this.getParam('jobId');
    this.flowType = await this.getParam('flowType');
    this.planId = await this.getParam('planId');
    this.bookingId = await this.getParam('bookingId');
    this.defaultBookingKey = await this.getParam('defaultBookingKey');
    this.categoryId = await this.getParam('categoryId') || 1;
    this.isSelfBooking = await this.getParam('isSelfBooking');
    this.shouldNotDefaultToLastPro = await this.getParam('shouldNotDefaultToLastPro');
    this.hkId = await this.getParam('hkId');
    this.filterByPro = await this.getParam('filterByPro');
    this.pros = await this.getParam('pros');
    const today = LuxonDateTime.local().toFormat('MM-dd-yyyy');
    this.providedStartDate = await this.getParam('providedStartDate') || today;
    const providedDate = LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy');
    const currentDate = LuxonDateTime.local();
    if (providedDate <= currentDate) {
      this.providedStartDate = this.startDay;
      this.providedStartDateIsBeforeTomorrow = true;
    }
    this.shouldInsteadAddJob = await this.getParam('shouldInsteadAddJob');
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async initPageData() {
    try {
      this.hasValidCC = await this.card.hasValidCard();
      const client = await this.client.getClientInfo();
      //this.waterfallSettings = await this.client.getWaterfallSettings();
      this.isRentalClient = client.customer_account.account_type === 'rental';
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
      this.logger.error(err);
      throw err;
    }
    this.bookingExperiment = localStorage.getItem('bookingExperiment');
    this.priceExperiment = this.bookJobService.getPriceExperiment();
    this.subscriptionExperiment = this.bookJobService.getSubscriptionExperiment();
    this.customFlowName = localStorage.getItem('customFlowName');
    this.customFlowSmallImage = localStorage.getItem('customFlowSmallImage');
    this.customFlowDiscount = parseInt(localStorage.getItem('customFlowDiscount'));
    if (this.customFlowName) {
      this.bookingExperiment = 'consumer-control';
      localStorage.setItem('bookingExperiment', this.bookingExperiment);
    }
    this.logJobTimeItems = this.bookJobService.getJobTimeItems();
    if (this.flowType === 'private') {
      this.proName = localStorage.getItem('proName');
      this.proTeamName = localStorage.getItem('proTeamName');
    }
    this.notChoseServiceSentence =  this.bookingType === 'add_one_time_job' ? 'Select service to see times' : 'Select service & frequency to see times';
    this.configuration = bookJobConfigurations[this.bookingType];
    this.startDay = await this.calendarDay.getBookJobStartDate(this.address.timezone);
    this.endDay = this.calendarDay.addDays(this.startDay, 28);
    await this.fetchPros();
    const defaultProOption: any = await this.determineDefaultProOption();
    this.form.patchValue({ proOption: defaultProOption });
    this.form.patchValue({ service: this.defaultBookingKey });
    await this.fetchServices(defaultProOption);
    this.dateLabel = this.getDateLabel();
    this.form.get('privateDate').clearValidators();
    this.form.get('privateDate').updateValueAndValidity();
  }

  async determineDefaultProOption() {
    if (this.isLoggingJob || this.shouldNotDefaultToLastPro) {
      return this.prosRatingsOptions[0].value;
    }
    const request = {
      frequency: 'once',
      start_date: this.startDay,
      end_date: this.endDay,
      region_id: this.address.region_id,
      address_id: this.address.id,
      service_type_key: 'regular_cleaning.one_hour',
      filter: ''
    };

    if (this.hkId && this.filterByPro) {
      return request.filter = `homekeeper:${this.hkId}`;
    }

    try {
      if (this.flowType === OnboardingFlow.PRIVATE) {
        request.filter = `homekeeper:${this.hkId}`;
      } else {
        const lastPro = this.pros.find(pro => pro.default);
        if (lastPro.private) {
          return lastPro.filter;
        }
        const filtersToTry = [
          lastPro.filter,
          'prioritized_v2:4_6',
          'all'
        ];
        for (let i = 0; i < filtersToTry.length; i++) {
          request.filter = filtersToTry[i];
          const response = await this.availableTimesForRange.getAvailableTimes(request);
          this.handleTimeResponse(response);
          if (!this.noAvailableTimes) {
            break;
          }
        }
      }
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.logger.error(err, 'book-job-trying-get-calendar');
    }
    return request.filter;
  }

  async fetchPros() {
    try {
      if (!this.pros) {
        this.pros = await this.bookJobService.fetchPros(this.categoryId);
      }
      this.prosRatingsOptions = this.parseProsForFilter();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err);
    }
    this.form.patchValue({
      pros: null,
      service: null,
      frequency: null,
      privateDate: null,
      time: null
    });
  }

  parseProsForFilter() {
    const array: any = [];
    this.pros.map(pro => {
      const rating4 = pro.name === '4+ Star Rating';
      const filter = rating4 ? '4' : pro.filter;
      if ((this.isLoggingJob && pro.private) || !this.isLoggingJob) {
        array.push({
          viewValue: pro.name,
          value: filter
        })
      }
    });
    return array;
  }

  changeFilter(pro) {
    this.isLoggingJob = false;
    this.ratingChosen = true;
    this.newValue = pro.value;
    this.choseFrequency = false;
    this.fetchServices(pro);
  }

  async fetchServices(proFilter: string, showAll: boolean = false) {
    if (proFilter === '4') {
      proFilter = 'all';
    }
    this.selectedPro = this.prosRatingsOptions.find((option) => {
      return proFilter === option.value;
    }) || this.prosRatingsOptions[0];
    const selectedProResponseData = this.pros.find((pro) => {
      return pro.name === this.selectedPro.viewValue;
    });
    this.form.patchValue({ proOption: selectedProResponseData.filter });
    this.selectedPrivatePro = selectedProResponseData.private;
    if (this.selectedPrivatePro) {
      const proId = parseInt(proFilter.replace('homekeeper:', ''), 10);
      try {
        this.servicesResponse = await this.bookJobService.fetchTeamServices(proId, this.categoryId);
        this.services = this.servicesResponse;
        this.proSelected = true;
      } catch (err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        this.logger.error(err);
      }
    } else {
      const defaultRatings = [
        'prioritized_v2:4_6',
        'prioritized_v2:4_2',
        'all'
      ];
      this.proSelected = !defaultRatings.includes(selectedProResponseData.filter);
      try {
        this.services = await this.bookJobService.fetchRequiredTeamServices(selectedProResponseData.filter, this.proSelected, this.categoryId);
      } catch (err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        this.logger.error(err);
      }
    }
    this.setSelectedService(showAll);
  }

  setSelectedService(showAll: boolean = false) {
    const formService = this.form.get('service').value;
    const selectedService = this.services.find(service => service.key === formService);
    if (selectedService) {
      this.selectService(selectedService, showAll);
    } else {
      this.showMoreServices = true;
      this.choseService = false;
      this.selectedServiceData = null;
      this.choseFrequency = false;
      this.form.patchValue({
        service: null,
        frequency: null,
      });
    }
  }

  handleServiceClick(service: ProTeamServices) {
    if (this.selectedServiceData?.key === service.key) {
      return;
    }

    this.selectService(service);
  }

  selectService(service: ProTeamServices, showAll: boolean = false) {
    this.showSavingsText = false;
    this.choseService = true;

    this.selectedServiceData = service;
    this.selectedService = service.title;
    this.selectedBillingType = service.billingType;
    this.selectedServiceImage = service.image;
    this.selectedDuration = service.duration;
    //this.selectedPrice = this.hasNonTrialPaidSubscription ? this.bookJobService.getOneTimePrice(service.price) : service.price;
    const selectedFrequency = this.form.value.frequency;
    this.form.patchValue({ service: service.key, time: null });
    if(showAll){
      this.form.patchValue({frequency: selectedFrequency });
      return ;
    } else {
      this.form.patchValue({frequency: null });
      this.selectedFrequency = null;
    }
    this.selectedDate = null;
    this.selectedTime = null;
    this.dateTimes = [];

    if (this.configuration.hide_frequency || this.isLoggingJob) {
      this.selectFrequency(this.configuration.frequency_value || { viewValue: 'One Time', value: 'once' });
    }
  }

  async selectFrequency(frequency: RadioButtonModel) {
    this.showSavingsText = false;
    this.selectedPrice = this.selectedServiceData.price;
    this.moreTimesPerWeekSelected = ['twice_a_week', 'thrice_a_week'].includes(frequency.value);
    this.showMoreServices = false;
    this.choseFrequency = true;
    this.selectedFrequency = frequency.viewValue;
    const isMobileResolution = window.innerWidth <= 870;
    if (!this.configuration.hide_frequency && !this.selectedPrivatePro && this.selectedFrequency === 'One Time' && this.bookingExperiment == 'rental-v3' && !this.isUpdatingSubscription && !this.hasNonTrialPaidSubscription) {
      if (!isMobileResolution) {
        const dialog = this.dialog.open(SubscriptionModalComponent, {
          panelClass: 'subcription-modal',
        })
        dialog.afterClosed().subscribe(async () => {
          await this.onDismissSubscriptionModal();
        })
      } else {
        this.modalCtrl.create({
          component: SubscriptionModalComponent,
          cssClass: 'subcription-modal',
          mode: 'ios',
          backdropDismiss: true,
          showBackdrop: true,
          swipeToClose: true,
          breakpoints: [0, 0.4, 0.7, 0.8, 1],
          initialBreakpoint: 0.8,
          presentingElement: await this.modalCtrl.getTop(),
          canDismiss: true,
        }).then(modal => {
          modal.present();
          modal.onDidDismiss().then(async () => {
            await this.onDismissSubscriptionModal();
          })
        });
      }
    }
    this.frequencyValue = frequency.value;
    this.dateTimes = [];
    const startDay = (this.selectedPrivatePro && !this.providedStartDateIsBeforeTomorrow) ? LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy').toFormat('yyyy-MM-dd') : this.startDay;
    const request = this.buildTimeRequestPayload(frequency.value, startDay, this.endDay);
    try {
      this.isLoadingTimes = true;
      const times = this.selectedPrivatePro ?
        await this.availableTimesForRange.getAvailableTimesPrivatePro(request) :
        await this.availableTimesForRange.getAvailableTimes(request);
      await this.handleTimeResponse(times);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.logger.error(err, 'book-job-trying-get-calendar');
    } finally {
      this.isLoadingTimes = false;
    }
    if (this.selectedPrivatePro && !this.isLoggingJob) {
      this.buildDateSelect();
      this.form.get('privateDate').clearValidators();
      this.form.get('privateDate').updateValueAndValidity();
    }
    if (this.isLoggingJob) {
      const privateDate = LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy');
      const reformattedDate = privateDate.toFormat('yyyy-MM-dd');
      this.form.patchValue({ privateDate: reformattedDate });
      this.form.get('privateDate').setValidators(Validators.required);
      this.form.get('privateDate').updateValueAndValidity();
    }
    this.showSavingsText = this.checkIfShouldShowSavingsText();
    this.resetDateLabel();
  }

  async onDismissSubscriptionModal(): Promise<void> {
    this.hasNonTrialPaidSubscription = localStorage.getItem('hasNonTrialPaidSubscription') == 'true';
    this.isUpdatingSubscription = localStorage.getItem('isUpdatingSubscription') == 'true';
    this.currentSubscription = await this.storage.retrieve('currentSubscription');
    this.subscriptionType = this.me.getSubscriptionType(this.currentSubscription?.key);
    this.showSavingsText = this.checkIfShouldShowSavingsText();
  }

  resetDateLabel() {
    if(this.selectedTimeValue == null){
      return;
    }
    let timeIsAvailable = false;
    const selectedDateTime = this.selectedTimeValue.split(" ");
    this.dateTimes.map((day) => {
      if (day.date === selectedDateTime[0]) {
        day.times.map((time) => {
          if (time.value === this.selectedTimeValue) {
            timeIsAvailable = true;
          }
        });
      }
    });
    if (timeIsAvailable) {
      this.dateLabel = this.getDateLabel();
    } else {
      this.form.value.time = '';
      this.dateLabel = 'Select Date & Time'
    }
  }

  buildTimeRequestPayload(frequency, startDay, endDay) {
    return {
      frequency: frequency,
      start_date: startDay,
      end_date: endDay,
      region_id: this.address.region_id,
      address_id: this.address.id,
      service_type_key: this.form.value.service,
      filter: this.selectedPro.value === '4' ? 'all' : this.selectedPro.value
    };
  }

  async handleTimeResponse(response: AvailableTimesModel) {
    this.noAvailableTimes = !response.available_times.length;
    if (this.noAvailableTimes && this.proSelected && !this.selectedPrivatePro) {
      try {
        const hkId = this.form.value.proOption.replace('homekeeper:', '');
        this.proNotBookableReason = await this.homekeeper.getHkNotAvailableReason(hkId, this.form.value.service);
        this.minRateToBookWithPro = this.getMinRateToBookWithPro();
      } catch (err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        this.logger.error(err);
      }
    }
    if (!this.noAvailableTimes) {
      this.dateTimes = response.available_times.map( at => ({
        date: at.date,
        times: this.formatTimes(at)
      }));
    }
  }

  getMinRateToBookWithPro() {
    if (this.proNotBookableReason.not_bookable_reason.includes('rate is set at a minimum')) {
      return this.extractNumberFromString(this.proNotBookableReason.not_bookable_reason);
    } else {
      return null;
    }
  }

  extractNumberFromString(string) {
    const regex = /minimum of \$([\d,]+(?:\.\d{1,2})?)/;
    const match = string.match(regex);
    return match ? parseFloat(match[1].replace(/,/g, '')) * 100 : null;
  }

  async buildDateSelect() {
    const luxonDate = LuxonDateTime.fromISO(this.startDay);
    this.privateJobDates = [
      {
        value: this.startDay,
        viewValue: luxonDate.toFormat('EEEE M/d')
      }
    ];
    for (let i = 1; i <= 27; i++) {
      const date = luxonDate.plus({ days: i });
      this.privateJobDates.push({
        value: date.toISODate(),
        viewValue: date.toFormat('EEEE M/d')
      });
    }
    if (this.providedStartDate) {
      const date = LuxonDateTime.fromFormat(this.providedStartDate, 'MM-dd-yyyy');
      const today = LuxonDateTime.local();
      const privateDate = this.isDateWithinThisWeek(date) ? date : today;
      const reformattedDate = privateDate.toFormat('yyyy-MM-dd');
      const dateIsDisplaying = this.privateJobDates.find((item) => item.value == reformattedDate);
      if (dateIsDisplaying) {
        this.form.patchValue({ privateDate: reformattedDate });
      } else {
        this.form.patchValue({ privateDate: this.privateJobDates[0].value });
      }
    } else {
      this.form.patchValue({ privateDate: this.startDay });
    }
  }

  isDateWithinThisWeek(date: LuxonDateTime): boolean {
    const today = LuxonDateTime.local();
    const todayMoreSeven = today.plus({ days: 7 });
    return date >= today && date <= todayMoreSeven;
  }

  async changeDay(day) {
    this.isLoadingPrivateTimes = true;
    if (this.isLoggingJob) {
      this.selectedDate = this.formatLoggingJobDate();
      this.dateLabel = this.getDateLabel();
    }
    try {
      const request = this.buildTimeRequestPayload(this.frequencyValue, day, this.endDay);
      const times = await this.availableTimesForRange.getAvailableTimesPrivatePro(request);
      this.handleTimeResponse(times);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.logger.error(err);
    }
    this.isLoadingPrivateTimes = false;
  }

  formatTimes(at) {
    if (this.selectedPrivatePro) {
      const idArray = [];
      const selectedProId = this.selectedPro.value.replace('homekeeper:', '');
      idArray.push(selectedProId);
      return at.times.map( t => ({
        value: `${at.date} ${t}`,
        viewValue: this.formatDate(`${at.date} ${t}`, 'h:mma'),
        pros: idArray
      }));
    } else {
      return at.times.map( t => ({
        value: `${at.date} ${t.time}`,
        viewValue: this.formatDate(`${at.date} ${t.time}`, 'h:mma'),
        pros: t.hk_ids
      }));
    }
  }

  selectTime(time: { value: string, viewValue: string, pros: number[]}, idx) {
    this.allTimesSelected = [time];
    if (this.isLoggingJob) {
      if (this.form.value.privateDate) {
        this.selectedDate = this.formatLoggingJobDate();
      }
      this.selectedTime = this.formatLoggingJobTime(time);
      this.selectedTimeValue = time;
    } else {
      this.selectedDate = this.formatDate(time.value, 'ddd M/D');
      this.selectedTime = time.viewValue;
      this.selectedTimeValue = time.value;
    }
    this.dateLabel = this.getDateLabel();
    this.secondDateLabel = this.getSecondDateLabel();
    this.thirdDateLabel = this.getThirdDateLabel();
    if (this.moreTimesPerWeekSelected) {
      this.form.patchValue({ time: null });
      this.openMoreTimesModal(time, idx);
    } else {
      this.form.patchValue({ pros: time.pros })
    }
  }

  formatLoggingJobDate() {
    const luxonDate = LuxonDateTime.fromJSDate(new Date(this.form.value.privateDate));
    return luxonDate.toFormat('M/d');
  }

  formatLoggingJobTime(time) {
    const luxonTime = LuxonDateTime.fromFormat(time, 'HH:mm');
    return luxonTime.toFormat('h:mma').toLowerCase();
  }

  async openMoreTimesModal(time, idx) {
    const changedEvent: EventEmitter<ModalMoreTimeDataModel> = new EventEmitter<ModalMoreTimeDataModel>();
    const sixDaysAfterFirstTime = this.dateTimes.slice(idx + 1, idx + 7);

    const params = {
      firstTime: [this.formatDate(time.value, 'dddd M/D'), time.viewValue].filter(Boolean).join(' at '),
      dateTimes: sixDaysAfterFirstTime,
      thriceAWeek: this.form.value.frequency === 'thrice_a_week',
      frequencyLabel: this.serviceFrequencyLabel(),
      selectedPrice: this.selectedPrice,
      eventEmitter: changedEvent
    }
    const modal = await this.modalCtrl.create({
      component: ModalMoreTimesPage,
      componentProps: params,
      showBackdrop: false,
      cssClass: 'tidy-custom-modal'
    });

    this.subscriptions.push(changedEvent.subscribe((data: ModalMoreTimeDataModel) => {
      this.setSelectedTimes(time, data);
    }));

    modal.onDidDismiss().then((result) => {
      this.setSelectedTimes(time, result?.data);
    });

    await modal.present();
  }

  setSelectedTimes(firstTime, data: ModalMoreTimeDataModel): void {
    if (data?.completed) {
      this.allTimesSelected = [
        firstTime,
        ...data.timesSelected
      ]
      this.selectedDate = this.formatDate(firstTime.value, 'dddd M/D');
      this.selectedTime = firstTime.viewValue;
      this.form.patchValue({ time: firstTime.value, pros: firstTime.pros })
      this.secondDateLabel = this.getSecondDateLabel();
      this.thirdDateLabel = this.getThirdDateLabel();
    }
  }

  async confirmBooking() {
    const isJobRequest = false;
    const selectedPrivatePro = this.selectedPrivatePro
    const selectedServiceData = this.selectedServiceData;
    const isUpdatingSubscription = this.isUpdatingSubscription;
    const hasNonTrialPaidSubscription = this.hasNonTrialPaidSubscription;
    const didSelectPro = this.selectedPro.value.includes('homekeeper:');
    const address = this.address;
    const proName = didSelectPro ? this.selectedPro.viewValue : null;
    const planId = this.planId;
    const values = this.form.value;
    const bookingType = this.bookingType;
    const flowType = this.flowType;
    const viewData = this.buildViewData();
    const { bookingId, jobId, allTimesSelected, isLoggingJob } = this;
    const dates = this.buildSelectedDates(allTimesSelected);
    const bookData = this.buildBookData(values, dates);
    const addressId = this.address.id;
    const shouldInsteadAddJob = this.shouldInsteadAddJob;
    const isProRequest = false;
    if (this.shouldInsteadAddJob) {
      bookData.address_id = this.address.id;
    }
    const serviceTypeKey = values.service;
    const homekeeperId = dates[0]?.homekeeper_ids[0];
    try {
      if (!this.hasValidCC && !this.selectedPrivatePro) {
        const params = { isProRequest, selectedPrivatePro, selectedServiceData, hasNonTrialPaidSubscription, isUpdatingSubscription, planId, bookingType, flowType, bookData, viewData, bookingId, jobId, isJobRequest }
        const url = 'confirm-booking-payment';
        const component = ConfirmBookingPaymentPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        const params = { isProRequest, selectedPrivatePro, selectedServiceData, hasNonTrialPaidSubscription, isUpdatingSubscription, address, addressId, proName, planId, bookingType, flowType, bookData, viewData, bookingId, jobId, isLoggingJob, shouldInsteadAddJob, serviceTypeKey, homekeeperId, isJobRequest }
        const url = 'confirm-booking';
        const component = ConfirmBookingPage;
        this.rightSidePanelService.navigateTo(url, params, component);
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
      this.logger.error(err, 'confirm-booking-redirect');
    }
  }

  buildBookData(values, dates) {
    if ((this.configuration.title !== 'Reschedule' && values.frequency == 'once') || this.isLoggingJob) {
      this.configuration = bookJobConfigurations['add_one_time_job'];
    }
    return this.configuration.buildBookingData({
      addressId: this.address.id,
      frequency: values.frequency === null ? 'once' : values.frequency,
      planId: this.planId,
      serviceTypeKey: values.service,
      dates: dates,
      ratingChosen: this.ratingChosen,
      hkRating: this.bookJobService.parseFilterToRating(values.proOption)
    });
  }

  buildSelectedDates(allTimesSelected) {
    if (this.isLoggingJob) {
      return [
        {
          date: this.form.value.privateDate,
          time: this.form.value.time,
          homekeeper_ids: [this.selectedPro.value.replace('homekeeper:', '')]
        }
      ];
    } else {
      return allTimesSelected.map(dateTime => ({
        date: dateTime.value.split(' ')[0],
        time: dateTime.value.split(' ')[1],
        homekeeper_ids: dateTime.pros,
      }));
    }
  }

  formatDate(dateTime, format) {
    return new CustomDatePipe().transform(dateTime, format, '')
  }

  buildViewData() {
    const data = {
      service: this.selectedService,
      frequency: this.selectedFrequency,
      image: this.selectedServiceImage,
      dateTime: this.getDateLabel(),
      price: this.selectedPrice,
      duration: this.selectedDuration,
      billingType: this.selectedBillingType
    };
    if (this.form.value.frequency === 'twice_a_week' || this.form.value.frequency === 'thrice_a_week') {
      data['dateTimeTwo'] = this.getSecondDateLabel();
    }
    if (this.form.value.frequency === 'thrice_a_week') {
      data['dateTimeThree'] = this.getThirdDateLabel();
    }
    return data;
  }

  showService(service) {
    const isTheOneSelected = this.form.value.service === service.key;
    return !this.choseService
            || this.showMoreServices
            || isTheOneSelected;
  }

  showServices() {
    this.showMoreServices = true;
  }

  showRequestPro() {
    const isOneTimeJob = this.form.value.frequency === 'once' || this.bookingType === 'add_one_time_job';
    const isFreqTwiceThrice = this.frequencyValue != 'thrice_a_week' && this.frequencyValue != 'twice_a_week';
    if (this.bookingType !== 'reschedule_plan' && this.bookingType !== 'reschedule_job' && !this.noAvailableTimes && (this.selectedPrivatePro || this.proSelected) && (this.choseFrequency || (this.choseService && isOneTimeJob)) && isFreqTwiceThrice) {
      return true;
    }
    else {
      return false;
    }
  }

  showRequestJob() {
    const isOneProJob = !this.form.value.service.includes('turnover');
    const isOneTimeJob = this.form.value.frequency === 'once' || this.bookingType === 'add_one_time_job';
    const isReschedule = this.bookingType === 'reschedule_plan' || this.bookingType === 'reschedule_job';
    const hasAvailableTimes = this.dateTimes.length > 0;
    return isOneProJob && isOneTimeJob && !this.proSelected && hasAvailableTimes && !isReschedule;
  }

  async goToRequestJob() {
    const params: any = {
      isPrivateFlow: this.selectedPrivatePro,
      selectedService: this.selectedServiceData,
      address: this.address,
      planId: this.planId,
      bookingId: this.bookingId,
      bookingType: this.bookingType,
      services: this.services,
      hasNonTrialPaidSubscription: this.hasNonTrialPaidSubscription,
      priceExperiment: this.priceExperiment,
      bookingExperiment: this.bookingExperiment
    }
    const substituteData = await this.availableTimesForRange.checkIfCanRequestSubstitute();
    const substituteJobId = substituteData.substitute_available_for_job_id;
    if (substituteJobId !== null) {
      const url = `request-substitute/${substituteJobId}`;
      params.jobId = substituteJobId;
      this.rightSidePanelService.navigateTo(url, params, RequestSubstitutePage);
      return;
    }
    const url = 'request-job';
    const component = RequestJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToRequestPro() {
    const pro = this.pros.find(pro => pro.filter === this.form.value.proOption);
    const params = {
      addingOneTimeJob: this.bookingType === 'add_one_time_job',
      cameFromBookJob: true,
      services: this.services,
      address: this.address,
      planId: this.planId,
      bookingType: this.bookingType,
      hasNonTrialPaidSubscription: this.hasNonTrialPaidSubscription,
      priceExperiment: this.priceExperiment,
      bookingExperiment: this.bookingExperiment,
      service: {
        viewValue: this.selectedService,
        value: this.form.value.service
      },
      frequency: {
        viewValue: this.selectedFrequency,
        value: this.frequencyValue
      },
      pro: {
        first_name: this.selectedPro.viewValue,
        id: this.form.value.proOption,
        private: pro.private
      }
    };
    const url = 'request-pro';
    const component = RequestProPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  //TODO The purpose of the 3 following methods is to convert new string values into older string values.
  //We can remove these 3 method when we refactor SDC / CRAF pages to use newer string values.

  formatTypeOfPlan() {
    const newType = this.bookingType;
    const oldTypes = {
      'reschedule_job': 'reschedule-cleaning',
      'add_one_time_job': 'add-cleaning',
      'reschedule_plan': 'reschedule-plan',
      'add_job': 'schedule-plan',
    }
    return oldTypes[newType];
  }

  formatPlanFrequency() {
    const newType = this.selectedFrequency;
    const oldTypes = {
      'One Time': 'once',
      'One Time (+25%)': 'once',
      'Every 4 Weeks': 'every_four_weeks',
      'Every 2 Weeks': 'every_other_week',
      'Weekly': 'every_week',
      '2x Per Week': 'twice_a_week',
      '3x Per Week': 'thrice_a_week'
    }
    return oldTypes[newType];
  }

  formatPlanType() {
    const newType = this.form.value.service;
    if (newType.includes('regular_cleaning.one_hour')) {
      return 'tidy';
    } else if (newType.includes('regular_cleaning.two_and_a_half_hours')) {
      return 'plus';
    } else if (newType.includes('regular_cleaning.four_hours')) {
      return 'tidy_xl';
    }
  }

  displayTodayLabel() {
    return moment().isBefore(moment('19:50', 'HH:mm'));
  }

  showAnyRatingFilter() {
    this.newValue = 'all';
    this.form.patchValue({ proOption: 'all' });
    this.selectedPro = {
      value: 'all',
      viewValue: 'Any Rating'
    };
    this.proSelected = false;
    if (!this.selectedPrivatePro) {
      this.selectFrequency({ value: this.frequencyValue, viewValue: this.selectedFrequency });
    } else {
      this.fetchServices('all', true);
    }
  }

  serviceFrequencyLabel() {
    const service = this.serviceLabel();
    if (!this.choseService) {
      return 'Select Service';
    } else if ((this.choseService && ! this.choseFrequency) || this.configuration.hide_frequency || this.selectedFrequency == null) {
      return `${service}`;
    } else {
      const frequency = this.selectedFrequency;
      return `${service} - ${frequency}`;
    }
  }

  serviceLabel() {
    let service = this.selectedService;
    if (this.selectedService.includes('2 Pros')) {
      const services = {
        '1 hour cleaning (2 Pros)': '1 Hour (2 Pros)',
        '2.5 hour cleaning (2 Pros)': '2.5 Hours (2 Pros)',
        '4 hour cleaning (2 Pros)': '4 Hours (2 Pros)'
      };
      service = services[service];
    }
    return service;
  }

  getDateLabel() {
    if ((!this.selectedDate || !this.selectedTime) && !this.moreTimesPerWeekSelected) {
      return 'Select Date & Time';
    } else if ((!this.selectedDate || !this.selectedTime) && this.moreTimesPerWeekSelected) {
      return 'Select First Time';
    }
    const dateLabel = [this.selectedDate, this.selectedTime].filter(Boolean).join(' ');
    if (this.frequencyValue === 'once') {
      return dateLabel;
    } else {
      return (new TranslationPipe()).transform('Starts ') + dateLabel;
    }
  }

  getSecondDateLabel() {
    if (this.allTimesSelected.length < 2) {
      return 'Select Second Time';
    }
    const date = this.formatDate(this.allTimesSelected[1].value, 'dddd M/D');
    const dateLabel = [date, this.allTimesSelected[1].viewValue].filter(Boolean).join(' ');
    if (this.frequencyValue === 'once') {
      return dateLabel;
    } else {
      return (new TranslationPipe()).transform('Starts ') + dateLabel;
    }
  }

  getThirdDateLabel() {
    if (this.allTimesSelected.length < 3) {
      return 'Select Third Time';
    }
    const date = this.formatDate(this.allTimesSelected[2].value, 'dddd M/D');
    const dateLabel = [date, this.allTimesSelected[2].viewValue].filter(Boolean).join(' ');
    if (this.frequencyValue === 'once') {
      return dateLabel;
    } else {
      return (new TranslationPipe()).transform('Starts ') + dateLabel;
    }
  }

  showAllFrequencies() {
    this.frequencyOrder = [0,1,4,2,3,5];
  }

  showNoAddressAlert() {
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    const params = {
      header: 'Issue Accessing Page',
      body: 'Please try again from your Schedule and the issue will be resolved.  Thanks for your patience.',
      buttonText: 'Go to Schedule',
      buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list'
    };
    this.rightSidePanelService.navigateTo('success', params);
  }

  async turnOnTidyFindNewPros() {
    const data = {
      service_type_id: 1,
      key: 'substitutes_on'
    };
    try {
      this.waterfallSettings = await this.client.updateWaterfallSettings(data);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToAddPro() {
    const params = {
      categoryId: 1
    }
    this.rightSidePanelService.navigateTo('add-pro', params);
  }

  checkIfShouldShowSavingsText() {
    const isOneTimeJobPlan = this.selectedFrequency == 'One Time' || this.selectedFrequency == 'One Time (+25%)';
    const savingsText = this.getSavingsText();
    if (!savingsText) {
      return false;
    } else if (this.selectedFrequency == 'One Time' && this.choseFrequency && this.subscriptionExperiment == 'showInlineSubscriptionSales' && !this.hasNonTrialPaidSubscription) {
      return false;
    } else if (!this.hasNonTrialPaidSubscription && this.priceExperiment !== 'showCrossedOutDiscount') {
      return false;
    } else if (this.bookingType !== 'add_one_time_job' && !this.selectedPrivatePro) {
      if (this.priceExperiment !== 'showCrossedOutDiscount' && !isOneTimeJobPlan) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getSavingsText() {
    const isOneTimeJobPlan = this.selectedFrequency == 'One Time' || this.selectedFrequency == 'One Time (+25%)';
    if (isOneTimeJobPlan && this.hasNonTrialPaidSubscription && this.choseFrequency) {
      return new TranslationPipe().transform('Saving ') + (this.selectedFrequency == 'One Time (+25%)' ? '25%' : '20%') + new TranslationPipe().transform(' with your ') + this.subscriptionType + new TranslationPipe().transform(' subscription!');
    } else if (isOneTimeJobPlan) {
      if (this.bookingExperiment == 'consumer-test2' && !this.hasValidCC) {
        return '$10 coupon applied!';
      } else if (this.subscriptionExperiment == 'showInlineSubscriptionSales') {
        return 'Upgrade to TIDY+ to save 20%';
      } else if (this.bookingExperiment == 'rental-v1a' || this.bookingExperiment == 'rental-v1b' || this.bookingExperiment == 'consumer-test1') {
        return 'Select a recurring plan to save 20%';
      }
    } else if (this.priceExperiment == 'showCrossedOutDiscount') {
      return 'Saving 20% with a recurring plan!';
    } else {
      return null;
    }
  }

  @Loading('', true)
  async updateSubscription() {
    this.isUpdatingSubscription = !this.isUpdatingSubscription;
    localStorage.setItem('isUpdatingSubscription', this.isUpdatingSubscription ? 'true' : ' false');
    this.hasNonTrialPaidSubscription = !this.hasNonTrialPaidSubscription;
    if (this.isUpdatingSubscription) {
      this.subscriptionType = 'TIDY+';
      localStorage.setItem('hasNonTrialPaidSubscription', 'true');
      this.currentSubscription = this.tidyPlusSubscription;
      this.storage.save('currentSubscription', this.tidyPlusSubscription);
    } else {
      localStorage.setItem('hasNonTrialPaidSubscription', 'false');
      this.storage.delete('currentSubscription');
    }
    this.showSavingsText = this.checkIfShouldShowSavingsText();
  }

  goToViewSubscriptions() {
    const params = {
      isBookingJob: true,
      nextBookingPage: 'book-job'
    }
    this.rightSidePanelService.navigateTo('select-subscription', params);
  }

  async logJob() {
    this.isLoggingJob = !this.isLoggingJob;
    if (this.isLoggingJob) {
      this.form.get('privateDate').setValidators(Validators.required);
    } else {
      this.form.get('privateDate').clearValidators();
    }
    this.form.get('privateDate').updateValueAndValidity();
    const today = LuxonDateTime.local().toFormat('MM-dd-yyyy');
    const parsedDate = LuxonDateTime.fromFormat(this.providedStartDate || today, 'MM-dd-yyyy').plus({ days: 1 }).toISODate();
    this.form.patchValue({ privateDate: parsedDate });
  }

  goToContactConcierge() {
    const params = {
      title: 'Booking Question',
      type: 'support.other'
    };
    return this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  async changeServiceRates() {
    try {
      const currentPrices =  await this.addresses.getPriceTable(this.address.id);
      let payload = {new_prices: []};
      currentPrices.map((service) => {
        if (service.key == this.form.value.service) {
          payload.new_prices.push({
            no_team_address_service_id: service.id,
            price: this.minRateToBookWithPro
          });
        } else if (service.billing_type !== 'price_later') {
          payload.new_prices.push({
            no_team_address_service_id: service.id,
            price: service.prices.base_price
          });
        }
      });
      await this.addresses.changePrices(this.address.id, payload);
      await this.changeFilter(this.form.value.proOption);
      await this.selectFrequency({ value: this.frequencyValue, viewValue: this.selectedFrequency });
      this.form.patchValue({
        frequency: this.frequencyValue
      });
      this.choseFrequency = true;
      this.selectedFrequency = this.form.value.frequency;
      this.frequencyValue = this.form.value.frequency;
      const successMessage = 'Rates updated!';
      this.util.showSuccess(successMessage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  getNotBookableHeader(text) {
    if (text?.includes('completely booked')) {
      return 'Fully Booked'
    } else if (this.minRateToBookWithPro) {
      return 'Raise Rates to Book'
    } else {
      return 'No Times Available';
    }
  }

  onDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}

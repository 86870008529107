import { Injectable } from '@angular/core';
import { DateTime as LuxonDateTime } from 'luxon';
import moment from 'moment';

@Injectable()
export class CalendarDay {

  constructor(
  ) {}

  async getBookJobStartDate(zone): Promise<string> {
    const timeNow = LuxonDateTime.fromObject({}, {
      zone,
    });

    const allowTimesToday = await this.checkIfAllowTimesToday(timeNow);

    if (allowTimesToday) {
      return timeNow.plus({days: 1}).toFormat('yyyy-MM-dd');
    } else {
      return timeNow.plus({days: 2}).toFormat('yyyy-MM-dd');
    }
  }

  async checkIfAllowTimesToday(timeNow): Promise<any> {
    const checkDate = timeNow.set({'hour': 19, 'minute': 50});
    const minutesUntilLimit = checkDate.diff(timeNow, 'minutes').toObject().minutes;

    return minutesUntilLimit > 0;
  }

  addDays(date, days) {
    return moment(date, 'YYYY-MM-DD').add('days', days).format('YYYY-MM-DD');
  }

  getEndDay(startDate) {
    return this.addDays(startDate, 28);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'frequencyFormat'})
export class FrequencyFormatPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value
    }

    const isAirbnb = (localStorage.getItem('airbnb') === 'true');

    const frequency = {
      'every_week': 'Every Week',
      'every_other_week': 'Every Other Week',
      'twice_a_week': '2x Per Week',
      'thrice_a_week': '3x Per Week',
      'every_four_weeks': 'Every Four Weeks',
      'once': 'One Time (+10%)'
    }
    if (isAirbnb) {
      frequency.once = 'One Time';
    }

    return frequency[value];
  }
}

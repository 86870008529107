import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Loading } from 'src/shared/components/loading/loading';

import { TeamService } from 'src/providers/team/team';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { TeamPage } from '../team/team';

@Component({
  templateUrl: 'edit-team-parent.html'
})

export class EditTeamParentPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  submitted: boolean;
  parentTeamItems: any;
  team: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private teamService: TeamService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      parentTeam: ['', Validators.required],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Parent Team');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.errorMessage = '';
    this.loaded = false;
    this.team = await this.getParam('team');
    const teams = await this.getParam('teams');
    this.getParentTeamItems(teams);
    this.form.patchValue({parentTeam: this.team.parent_team_id});
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    return param;
  }

  getParentTeamItems(teams) {
    this.parentTeamItems = [];
    teams.map((team) => {
      if (team.id !== this.team.id) {
        this.parentTeamItems.push({
          value: team.id,
          viewValue: team.name
        });
      }
    });
  }

  @Loading('', true)
  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const payload = {
      name: this.team.name,
      parent_team_id: this.form.value.parentTeam
    }
    try {
      await this.teamService.editTeam(this.team.id, payload);
      this.dialogParams.team = null;
      this.dialogParams.teams = null;
      await this.storage.save('dialog-params', this.dialogParams);
      const nextPage = await this.getParam('nextPage');
      this.rightSidePanelService.navigateTo(nextPage, {}, TeamPage);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [title]="'Select New Default List'"
    [canGoBack]="!cameFromApplyListToJobs && !cameFromDeleteList"
    [noBow]="cameFromApplyListToJobs || cameFromDeleteList">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">

        <ng-container *ngIf="cameFromDeleteList">
          <tidy-row [align]="'center'" >
            <tidy-text
              [body]="'To-Do list deleted.'">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" >
            <tidy-text
              [body]="'Please select a new default list.'">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <ng-container *ngIf="cameFromApplyListToJobs">
          <tidy-row [align]="'center'" >
            <tidy-text
              [body]="'Updates saved.'">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'" >
            <tidy-text
              [body]="'Please select a new default list.'">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-card>
          <tidy-row>
            <tidy-radio-button
              [label]="'Select the default list at this address.'"
              formControlName="defaultList"
              [form]="form.controls.defaultList"
              [submitted]="submitted"
              [errorMessage]="'Please select an option'"
              [items]="toDoLists">
            </tidy-radio-button>
          </tidy-row>
        </tidy-card>

        <tidy-button
          [text]="'Set New Default List'"
          [action]="updateDefaultList.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie';
import { generateUuid } from './session-uuid';
import { Util } from '../../shared/util/util';
import { HttpClientCustom } from '../custom/http-client';
import { TrackParams } from './track-params.interface';
import { Logger } from '../logger';
import * as Bowser from 'bowser';
import { UrlSegment } from "@angular/router";
import { OnboardingFlow } from "src/shared/enums/onboarding-flow";

declare const window: any;
declare const navigator: any;

@Injectable({
  providedIn: 'root'
})
export class TidySession {


  firstUrlViewed: {
    value: string;
    viewedAt: string;
    referrer?: string;
  };
  onboardingFlow: OnboardingFlow = null;

  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClientCustom,
    private util: Util,
    private logger: Logger
  ) {}

  configureTidySessionCookie() {
    let tidySessionCookie = this.cookieService.get('TidySession');
    if (!tidySessionCookie) {
      tidySessionCookie = this.generateTidySession();
    }
    return tidySessionCookie;
  }

  generateTidySession () {
    const cookieDate = new Date;
    cookieDate.setFullYear(cookieDate.getFullYear() + 10);
    const cookieValue = generateUuid();
    this.cookieService.put('TidySession', cookieValue, {expires: cookieDate});
    return cookieValue;
  }

  setfirstUrlViewed(value: Array<UrlSegment>) {
    this.firstUrlViewed = this.getFirstUrlViewedFromCookie();

    if (!this.firstUrlViewed) {
      const url = this.util.encodeUrlSegment(value);
      this.firstUrlViewed = {
        value: url,
        viewedAt: (new Date).toString(),
        referrer: document.referrer
      }
    }
  }

  getFirstUrlViewedFromCookie(): { value: string; viewedAt: string} {
    const firstUrlCookie = this.cookieService.get('TidySessionFirstUrl');
    if (firstUrlCookie) {
      return JSON.parse(firstUrlCookie);
    }
    return undefined;
  }

  getfirstUrlViewed(): { value: string; viewedAt: string; referrer?: string } {
    return this.firstUrlViewed;
  }

  trackEvent ( data: TrackParams ) {
    try {
      const formatedData = this.prepareData(data);
      this.httpClient.post('https://v12huonu75.execute-api.us-west-2.amazonaws.com/api/sessions', {
        Data: formatedData
      });
    } catch (e) {
      this.logger.log(e);
    }
  }

  prepareData ( data: TrackParams ) {
    const { tidySessionId, eventType, metaData, pageName } = data;
    const obj = {
      meta_data: metaData,
      utm: this.cookieService.get('__utmztidy'),
      tidy_session_id: tidySessionId,
      event_type: eventType,
      url: window.location.href,
      page_name: pageName,
      language: navigator.language,
      app_type: this.util.getAppType(),
      screen_resolution: `${window.innerWidth}x${window.innerHeight}`,
      browser: Bowser.name,
      os: this.util.getOs(),
      user_agent: navigator.userAgent
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === '' || !obj[key])) {
        delete obj[key];
      }
    }


    return obj;
  }
}

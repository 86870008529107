<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Edit Pro'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card *ngIf="loaded">
      <form [formGroup]="form">
        <tidy-row class="field-bottom-padding field-top-padding">
          <tidy-input
            [label]="'Name'"
            formControlName="name"
            [form]="form.controls.name"
            [icon]="'assets/svg/person-circle-outline.svg'"
            [activeIcon]="'assets/svg/person-circle-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a name.'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-input
            [label]="'Email'"
            formControlName="email"
            type="email"
            [form]="form.controls.email"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email.'">
          </tidy-input>
        </tidy-row>
        <tidy-row>
          <tidy-input-international-phone
            formControlName="phone"
            [label]="'Phone Number'"
            [form]="form.controls.phone"
            [inputMode]="'numeric'"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid phone number.'"
            [icon]="'assets/svg/phone-portrait-outline.svg'"
            [defaultCountryCode]="proCountryCode"
            [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
          </tidy-input-international-phone>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Save Changes'"
      [action]="update.bind(this)"
      class="primary">
    </tidy-button>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

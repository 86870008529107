import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { WindowService } from 'src/shared/providers/window.service';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Util } from 'src/shared/util/util';

import { AddressModel } from 'src/models/address.model';
import { ClientModel, ClientSettingsModel } from 'src/models/client.model';
import { ScheduleAssuranceModel } from 'src/models/schedule-assurance.model'

import { UsersPage } from 'src/pages/users/users';
import { TeamsPage } from '../team/teams/teams';
import { AddAddressPage } from '../add-address/add-address';
import { ContactInfoPage } from '../more/contact-info/contact-info';
import { AccountTypePage } from '../more/account-type/account-type';
import { BackupTimesPage } from '../more/backup-times/backup-times';
import { AccountNotificationSettingsPage } from '../more/account-notification-settings/account-notification-settings';
import { BulkUploadPage } from '../more/bulk-upload/bulk-upload';
import { AppBrandingPage } from '../more/app-branding/app-branding';
import { VendorCompliancePage } from '../vendor-compliance/vendor-compliance';
import { IntegrationsPage } from '../integrations/integrations/integrations';
import { DevelopersPage } from '../more/developers/developers';
import { CancellationOptionsPage } from '../more/cancellation-options/cancellation-options';
import { SubscriptionsPage } from '../subscription/subscription';
import { MyPricesPage } from '../more/my-prices/my-prices';
import { UseGiftCardPage } from '../more/use-gift-card/use-gift-card';
import { PaymentSettingsPage } from '../more/payment-settings/payment-settings';
import { BuyBundlePage } from '../more/buy-bundle/buy-bundle';
import { BillingHistoryPage } from 'src/pages/billing-history/billing-history';
import { ConciergePage } from 'src/pages/concierge/concierge';
import { EditLanguagePage } from 'src/pages/more/edit-language/edit-language';
import { PaymentMethodsPage } from '../more/payment-methods/payment-methods';
import { LoadCreditPage } from '../more/payment-methods/load-credit/load-credit';

@Component({
  templateUrl: 'more-desktop.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['more-desktop.scss'],
})

export class MoreDesktopPage implements OnInit {

  accountType: string;
  addressResponse: AddressModel[];
  addressResponseFiltered: AddressModel[];
  card: any;
  clientInfo: ClientModel;
  hasThreePlusAddresses: boolean;
  hasBackupTimes: boolean;
  hasPrivatePro: boolean;
  isRentalClient: boolean;
  isAdvancedMode: boolean;
  language: string;
  panelClosedSubscription: Subscription;
  showLoadingSpinner: boolean;
  settings: ClientSettingsModel;
  scheduleAssurance: ScheduleAssuranceModel;
  notificationSettings: any;
  userRole: string;
  webhooks: any;
  UsersPage = UsersPage;
  TeamsPage = TeamsPage;
  AddAddressPage = AddAddressPage;
  ContactInfoPage = ContactInfoPage;
  AccountTypePage = AccountTypePage;
  BackupTimesPage = BackupTimesPage;
  AccountNotificationsPage = AccountNotificationSettingsPage;
  BulkUploadPage = BulkUploadPage;
  AppBrandingPage = AppBrandingPage;
  VendorCompliancePage = VendorCompliancePage;
  IntegrationsPage = IntegrationsPage;
  DevelopersPage = DevelopersPage;
  PaymentMethodsPage = PaymentMethodsPage;
  CancellationOptionsPage = CancellationOptionsPage;
  SubscriptionsPage = SubscriptionsPage;
  MyPricesPage = MyPricesPage;
  UseGiftCardPage = UseGiftCardPage;
  PaymentSettingsPage = PaymentSettingsPage;
  BuyBundlePage = BuyBundlePage;
  EditLanguagePage = EditLanguagePage;
  LoadCreditPage = LoadCreditPage;
  loaded: boolean;

  constructor(
    private client: Client,
    private events: Events,
    private navCtrl: CustomNavController,
    private util: Util,
    private currentAddress: CurrentAddress,
    private webhooksService: Webhooks,
    public windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    try {
      this.loadPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    await this.getPageData();
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/more-desktop').then(async () => {
        await this.loadPage(true);
        this.showLoadingSpinner = false;
      });
    }
  }

  async getPageData() {
    try {
      this.language = this.getLanguage();
      const shouldReloadLocation = localStorage.getItem('shouldReloadLocation') == 'true';
      if (shouldReloadLocation) {
        localStorage.setItem('shouldReloadLocation', 'false');
        location.reload();
      }
      this.addressResponse = await this.client.getAddresses();
      if (this.addressResponse.length > 2) {
        this.hasThreePlusAddresses = true;
      }
      this.userRole = localStorage.getItem('user_role');
      this.addressResponseFiltered = this.addressResponse.filter((address, i) => i < 2 );
      this.clientInfo = await this.client.getClientInfo();
      this.settings = await this.client.getClientSettings();
      if (this.userRole !== 'member') {
        const withUrl = false;
        this.webhooks = await this.webhooksService.getWebhooks();
        this.notificationSettings = await this.webhooksService.buildNotificationSettingsArray(this.webhooks, withUrl);
        this.scheduleAssurance = await this.client.getScheduleAssurance();
        this.checkForBackupTimes();
      }
      this.hasPrivatePro = localStorage.getItem('hasPrivatePro') == 'true';
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      this.isAdvancedMode = localStorage.getItem('isAdvancedMode') == 'true';
      this.accountType = this.getAccountType();
      this.loaded = true;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  getLanguage() {
    const language = localStorage.getItem('language') || 'en';
    const languages = {
      'en': 'English',
      'pt': 'Portuguese',
      'es': 'Spanish',
      'ru': 'Russian',
      'fr': 'French',
      'de': 'Dutch'
    }
    return languages[language];
  }

  getAccountType() {
    const types = {
      regular: 'Residential',
      realtor: 'Realtor',
      rental: 'Rental',
      company: 'Office',
    };
    return types[this.clientInfo.customer_account.account_type];
  }

  async openRightSidePanel(url: string, component: any, params: any): Promise<void> {
    localStorage.setItem('integrationsBackPage', 'more')
    localStorage.setItem('automaticBookingBackPage', 'more');
    params = {
      ...params,
      successRoute: 'more',
      settings: this.settings,
      webhooks: this.webhooks,
      backPage: 'more',
      setting: this.util.deepClone(this.settings)
    };
    await this.storage.save('dialog-params', params);
    if (this.windowService.isDesktopRes) {
      await this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(component);
      this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
        this.getPageData();
      });
    } else {
      await this.storage.save('dialog-right-side-open', false);
      this.navCtrl.navigateForward(url)
    }
  }

  goToAddresses() {
    this.navCtrl.navigateForward('properties');
  }

  goToAddAddress() {
    this.navCtrl.navigateForward('add-property');
  }

  goToBuyBundle() {
    this.navCtrl.navigateForward('buy-bundle');
  }

  goToPastJobs() {
    this.navCtrl.navigateForward('past-jobs');
  }

  goToBillingHistory() {
    localStorage.setItem('billingHistoryBackPage', 'more');
    this.openRightSidePanel('billing-history', BillingHistoryPage, {});
  }

  goToApiDocumentation() {
    this.util.openUrl('https://tidy.stoplight.io/docs/tidy-api-spec/docs/1.%20Introduction.md');
  }

  goToAuditLogs() {
    this.navCtrl.navigateForward('audit-logs');
  }

  logout() {
    this.events.publish('logout');
    localStorage.clear();
  }

  goToEditAddress(address) {
    this.currentAddress.addressId = address.id;
    let params = {};
    const addressHasMoreDetail = address.home_access;
    if (addressHasMoreDetail) {
      params['address'] = address;
    }
    localStorage.setItem('editPropertyBackPage', 'more-desktop');
    this.navCtrl.navigateForward(`edit-property/${address.id}`, params);
  }

  goToConcierge() {
    this.openRightSidePanel('concierge', ConciergePage, {});
  }

  goToDocumentation() {
    const url = 'https://help.tidy.com/';
    this.util.openUrl(url);
  }

  checkForBackupTimes() {
    this.scheduleAssurance.customerAddress.times.forEach((time) => {
      if (time.start_time !== null) {
        return this.hasBackupTimes = true;
      }
    })
  }

  goToGuestReservations() {
    this.navCtrl.navigateForward('more/reservations');
  }

  goToIntegrations() {
    localStorage.setItem('integrationsBackPage', 'more')
    this.navCtrl.navigateForward('integrations/all');
  }

  goToReports() {
    this.navCtrl.navigateForward('reports');
  }
}

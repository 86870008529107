import { Component, OnInit } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Concierge } from "src/providers/concierge/concierge";
import { ReportIssue } from "src/providers/report-issue/report-issue";
import { Loading } from "src/shared/components/loading/loading";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { TidyStorage } from "src/shared/providers/tidy-storage";
import { WindowService } from "src/shared/providers/window.service";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import { Util } from "src/shared/util/util";
import { DateTime as LuxonDateTime } from 'luxon';

@Component({
  selector: 'create-action-plan',
  templateUrl: 'create-action-plan.html',
  styleUrls: ['./create-action-plan.scss']
})

export class CreateActionPlanPage implements OnInit {

  isAddingAction: boolean;
  isRightSideContent: boolean;
  dialogParams: any;
  editSuggestionForm: UntypedFormGroup;
  editingSuggestion: any;
  loaded: boolean;
  pageTitle: string;
  conciergeActionSuggestions: any;
  loadingActionSuggestions: boolean;
  errorMessage: string;
  task: any;
  submitted: boolean;
  issueAddress: any;

  constructor(
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private reportIssue: ReportIssue,
    private concierge: Concierge,
    private fb: FormBuilder,
    private windowService: WindowService,
    private navCtrl: CustomNavController,
    private util: Util
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.windowService.isDesktopRes) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.pageTitle = this.dialogParams.isAddingIssue ? 'Task Added' : 'Create Action Plan';
      this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
      this.task = this.dialogParams.issue;
      this.issueAddress = this.dialogParams.issueAddress;
      this.loadData();
    } else {
      this.task = this.navCtrl.getParam('issue');
      const isAddingIssue = this.navCtrl.getParam('isAddingIssue');
      this.pageTitle = isAddingIssue ? 'Task Added' : 'Create Action Plan';
      this.issueAddress = this.navCtrl.getParam('issueAddress');
      this.loadData();
    }
  }

  @Loading('', true)
  async loadData() {
    this.rightSidePanelService.setDialogLoading(true);
    this.loaded = false;
    await this.loadConciergeActionSuggestions(this.task)
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
  }

  loadConciergeActionSuggestions(issue) {
    try {
      this.loadingActionSuggestions = true;
      this.conciergeActionSuggestions = null;
      this.reportIssue.getActionSuggestions(issue.id).then((res) => {
        this.loadingActionSuggestions = false;
        this.conciergeActionSuggestions = {
          issue_report_id: issue.id,
          suggestions: res.data.concierge_action_suggestions
        }
      })
    } catch (err) {
      this.loadingActionSuggestions = false;
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async createConciergeActionPlan() {
    const conciergeTasks = this.conciergeActionSuggestions.suggestions.map(suggestion => {
      return {
        type: 'support.concierge_task',
        data: {
          subject: suggestion.title,
          body: suggestion.description,
          scheduled: suggestion.scheduled,
          emails: [],
          attachments: []
        }
      }
    });
    try {
      await this.concierge.addManyConciergeItems(conciergeTasks, this.task.id);
      await this.assignConciergeToTask();
      this.util.showSuccess('Concierge action plan created!');
      if (this.windowService.isDesktopRes) {
        return this.rightSidePanelService.closeRightSidePanel();
      }
      this.navCtrl.navigateRoot('tasks');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  addActionPlanStep() {
    this.isAddingAction = true;
    this.editSuggestionForm = this.fb.group({
      title: '',
      description: '',
      scheduled: 'Immediate',
      date: null
    });
  }

  deleteSuggestion(suggestionIndex) {
    this.conciergeActionSuggestions.suggestions.splice(suggestionIndex, 1);
  }

  editSuggestion(suggestion) {
    this.submitted = false;
    this.editingSuggestion = suggestion;
    if (suggestion.scheduled == 'Immediate' || suggestion.scheduled == 'immediate') {
      this.editSuggestionForm = this.fb.group({
        title: suggestion.title,
        description: suggestion.description,
        scheduled: 'Immediate',
        date: null
      });
      this.editSuggestionForm.get('date').clearValidators();
      this.editSuggestionForm.get('date').updateValueAndValidity();
    } else {
      this.editSuggestionForm = this.fb.group({
        title: suggestion.title,
        description: suggestion.description,
        scheduled: 'Scheduled',
        date: null //Ideally we would patch the date but there is a bug where patching datepicker sets the date 1 day before it should
      });
      this.editSuggestionForm.get('date').setValidators([Validators.required]);
      this.editSuggestionForm.get('date').updateValueAndValidity();
    }
  }

  cancelEditSuggestion() {
    this.isAddingAction = false;
    this.editingSuggestion = null;
    this.editSuggestionForm = null;
  }

  confirmEditSuggestion() {
    this.submitted = true;
    if (!this.editSuggestionForm.valid) {
      return;
    }
    this.isAddingAction = false;
    if (!this.editingSuggestion) {
      this.conciergeActionSuggestions.suggestions.push(this.editSuggestionForm.value);
      this.editSuggestionForm = null;
      return;
    }
    this.editingSuggestion.title = this.editSuggestionForm.value.title;
    this.editingSuggestion.description = this.editSuggestionForm.value.description;
    this.editingSuggestion.scheduled = this.getScheduledDate(); 
    this.editingSuggestion = null;
    this.editSuggestionForm = null;
  }

  getScheduledDate() {
    const scheduled = this.editSuggestionForm.value.scheduled;
    const dateIsAlreadyCorrectFormat = scheduled.includes('-');
    if (scheduled == 'Immediate' || scheduled == 'immediate' || dateIsAlreadyCorrectFormat) {
      return scheduled;
    } else {
      const date = this.editSuggestionForm.value.date;
      return LuxonDateTime.fromJSDate(date).toISODate();
    }
  }

  getUserIcon(assignedUsers) {
    if (!assignedUsers?.length) {
      return '';
    }
    const conciergeUser = assignedUsers.find((user) => user.id == 0);
    if (conciergeUser) {
      return 'concierge';
    } else {
      return 'user';
    }
  }

  getIssueHeaderIcon(issue) {
    if (!issue) return '';
    const icons = {
      'low': 'assets/img/low_priority.svg',
      'normal': 'assets/img/normal_priority.svg',
      'high': 'assets/img/high_priority.svg',
      'emergency': 'assets/img/emergency_priority.svg'
    };
    return icons[issue.urgency];
  }

  async assignConciergeToTask() {
    await this.reportIssue.updateIssueReport(this.task.id, {assigned_to_concierge: true});
  }

  async skipActionPlan() {
    try {
      await this.assignConciergeToTask();
      if (this.windowService.isDesktopRes) {
        return this.rightSidePanelService.closeRightSidePanel();
      }
      this.navCtrl.navigateRoot('tasks');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
  
  selectWhenConciergeShouldDo(selection, suggestion) {
    if (selection.value == 'Immediate') {
      suggestion.scheduled = 'Immediate';
      this.editSuggestionForm.get('date').clearValidators();
      this.editSuggestionForm.get('date').updateValueAndValidity();
    } else {
      suggestion.scheduled = 'Scheduled';
      this.editSuggestionForm.get('date').setValidators([Validators.required]);
      this.editSuggestionForm.get('date').updateValueAndValidity();
    }
  }
  
}

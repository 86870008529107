import { Component, ViewEncapsulation, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'tidy-icon',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./icon.component.scss'],
  template: `
    <div
      *ngIf="name && rippleEffect"
      [desktopAndMobileColor]="true"
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      tidyRipple
    >
      <img
        decoding="async"
        [src]="'assets/svg/' + name + '.svg'"
        (click)="executeAction()"
        [ngStyle]="myStyles"
      >
    </div>

    <img
      decoding="async"
      *ngIf="name && !rippleEffect"
      [src]="'assets/svg/' + name + '.svg'"
      (click)="executeAction()"
      [ngStyle]="myStyles"
    >
  `
})

export  class IconComponent implements OnInit {
  @Input() name: string;
  @Input() size: number;
  @Input() color: string;
  @Input() rippleEffect = false;
  cooldown = false;

  @Output() action: EventEmitter<any> = new EventEmitter();

  myStyles = {};
  ngOnInit() {
    if (this.size) {
      this.myStyles = {
        'max-height': `${this.size}px`
      };
    }
  }

  executeAction() {
    if (this.cooldown) return;
    this.cooldown = true;
    setTimeout(() => this.cooldown = false, 500);
    this.action.emit();
  }
}

<mat-form-field
  [class.invalid-field]="!disableElement && !form.valid && submitted"
  [class.no-border]="noBorder"
  appearance="outline"
>
  <input
    appBlockCopyPaste
    [imask]="initialMask"
    [unmask]="true"
    matInput
    #phoneInput
    [disabled]="disableElement"
    [value]="phoneValue"
    (keydown)="changeTouch()"
    formnovalidate="formnovalidate"
    [type]="type"
    [attr.inputMode]="inputMode"
    (focus)="setFocused(true); checkFocused()"
    (focusout)="setFocused(false); checkFocusedout($event)"
    [autocomplete]="autoComplete"
  />

  <mat-label *ngIf="label">
    <tidy-image [src]="icon" *ngIf="icon" class="outline-field-label-icon"></tidy-image>
    <tidy-text [body]="label"></tidy-text>
  </mat-label>
</mat-form-field>
<tidy-error-message
  *ngIf="submitted && !disableElement && !form.valid"
  [text]="errorMessage"
>
</tidy-error-message>

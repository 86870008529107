import { Component, Output, EventEmitter, OnInit, ViewEncapsulation } from "@angular/core";
import { Loading } from "../../../shared/components/loading/loading";
import { Customer } from "../../../providers/customer/customer";
import { Logger } from "../../../providers/logger";

@Component({
  selector: 'account-type-selection',
  templateUrl: 'account-type-selection.html',
  styleUrls: ['./account-type-selection.scss'],
  encapsulation: ViewEncapsulation.None
})



export class AccountTypeSelection implements OnInit {
  accType: string;
  @Output() accChange = new EventEmitter<string>();
  accTypeArray = [{
    accType: 'regular',
    label: 'Residential'
  }, {
    accType: 'rental',
    label: 'Rental / AirBnB'
  }, {
    accType: 'company',
    label: 'Office'
  }, {
    accType: 'realtor',
    label: 'Realtor'
  }];

  constructor(
    private customer: Customer,
    private logger: Logger
  ) {
  }

  async ngOnInit() {
    const settings = await this.customer.settings();
    this.accType = settings.profile.account_type;
  }

  @Loading('Loading...')
  async updateAccType(newType) {
    if (newType === this.accType) {
      return;
    }

    try {
      await this.customer.changeAccountType(newType);
      this.accType = newType;
      this.accChange.emit(newType);
    } catch (err) {
      this.logger.error(err);
    }
  }

}

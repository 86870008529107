import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { EmployerService } from "src/providers/employer/employer";
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Util } from 'src/shared/util/util';

@Component({
  templateUrl: 'add-employee-credit.html'
})

export class EmployerAddEmployeeCreditPage implements OnInit {

  companyCredit: any;
  employee: any;
  errorMessage: string;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private employer: EmployerService,
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = formBuilder.group({
      amount: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.rightSidePanelService.setDialogPageTitle('Add Employee Credit');
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.employee = dialogParams.employee;
    this.companyCredit = dialogParams.companyCredit;
  }

  async addCredit() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const amount = parseInt(this.util.removeCurrencyMask(this.form.value.amount), 10);
      const payload = await this.buildPayload(amount);
      await this.employer.addEmployeeCredit(payload);
      const params = {
        employee: this.employee,
        companyCredit: this.companyCredit - amount,
        shouldGetEmployeeBalance: true
      }
      await this.employer.listEmployees(true);
      this.rightSidePanelService.navigateTo('employer-edit-employee', params);
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

  async buildPayload(amount) {
    const campaigns = await this.employer.listCampaigns();
    return {
      transfer_from: {
        type: 'Campaign',
        id: campaigns[0].id
      },
      transfer_to: [
        {
          customer_id: this.employee.employee_customer_id,
          amount: amount
        }
      ]
    };
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [class]="extraClass"
    [canGoBack]="false"
    [title]="'More'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [hasBottomNav]="true">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">
      <tidy-card>
        <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
          <div class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/home.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Properties'">
            </tidy-text>
          </div>
          <tidy-text
            (action)="goToAddAddress()"
            [body]="'Add Property'"
            class="link">
          </tidy-text>
        </tidy-row>
        <div *ngFor="let address of addressResponseFiltered; let last = last">
          <tidy-card-button (action)="goToEditAddress(address)" class="arrow-to-top">
            <ng-container *ngIf="address?.address_name">
              <tidy-row>
                <tidy-text
                  [body]="address?.address_name">
                </tidy-text>
              </tidy-row>
            </ng-container>
            <ng-container *ngIf="!address?.address_name">
              <tidy-row>
                <tidy-text
                  [body]="address?.address1 + ' '">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="address?.address2 !== null && address?.address2 !== ''">
                <tidy-text
                  [body]="address?.address2">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  *ngIf="address?.city !== null && address?.city !== ''"
                  [helper]="address?.city">
                </tidy-text>
                <tidy-text
                  *ngIf="address?.zip !== null && address?.zip !== ''"
                  [helper]="', ' + address?.zip">>
                </tidy-text>
              </tidy-row>
            </ng-container>
            <tidy-row class="vertical-align-center" *ngIf="address?.home_access === null || address?.home_access === ''">
              <tidy-image
                [src]="'assets/img/red-alert.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                class="red"
                [actionBody]="'Missing access info'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="vertical-align-center" *ngIf="address?.home_access !== null && address?.home_access !== '' && (address?.paid_parking === null || address?.parking_spot === null || address?.parking_notes === null || address?.parking_notes === '')">
              <tidy-image
                [src]="'assets/img/red-alert.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                class="red"
                [actionBody]="'Missing parking info'">
              </tidy-text>
            </tidy-row>
          </tidy-card-button>
          <tidy-row *ngIf="!last" class="extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
        </div>
      </tidy-card>

      <tidy-row *ngIf="hasThreePlusAddresses" [align]="'center'">
        <tidy-text
          (action)="goToAddresses()"
          [body]="'View More Properties'"
          class="link">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-card-button (action)="goToMyPros()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/pros.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'My Pros'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Set your pro priority list.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="userRole !== 'member'">
        <tidy-card-button (action)="goToUsersPage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/team.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Users'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Users can log into your account with their own credentials. As the account owner, you can revoke access at any time.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="clientInfo?.customer_account?.account_type !== 'regular'">
        <tidy-card-button (action)="goToTeamsPage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/organization.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Teams'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Organize your users and properties into teams to control access, notifications, billing, and reporting.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToMessages()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/message.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Messages'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'View messages from your pros.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="accountType !== 'Residential'">
        <tidy-card-button (action)="goToInventoryPage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/inventory.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Restocking'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Get alerts when items go below your minimum quantity.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="accountType !== 'Residential'">
        <tidy-card-button (action)="goToMaintenancePage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/maintenance-more.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Maintenance'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Smart maintenance suggestions custom to you. What to do, what to skip, & how to save.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
      
      <tidy-card *ngIf="accountType === 'Rental'">
        <tidy-card-button (action)="goToGuestReservations()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/add-green.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Reservations'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Manage your guest reservations.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToLoggedIssuesPage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Tasks'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Keep track of damage, low inventory and other tasks.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="isRentalClient || hasPrivatePro">
        <tidy-card-button (action)="goToBillsPage()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/invoice.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Bills'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Pay & manage bills from your private pros.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="userRole !== 'member'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/settings.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Account Settings'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToEditContactInfo()">
          <tidy-row>
            <tidy-text
              [body]="'Contact Info'">
            </tidy-text><br>
            <ng-container *ngIf="clientInfo?.customer_account?.account_name">
              <tidy-text
                [helper]="clientInfo?.customer_account?.account_name">
              </tidy-text><br>
            </ng-container>
            <tidy-text
              [helper]="clientInfo?.current_user?.name">
            </tidy-text><br>
            <tidy-text
              [helper]="clientInfo?.current_user?.email">
            </tidy-text><br>
            <tidy-text
              [helper]="settings?.profile?.phone | telephone:'format'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center" *ngIf="settings?.profile?.phone === null || settings?.profile?.phone === ''">
            <tidy-image
              [src]="'assets/icon/alert-circle-red.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              class="red"
              [actionBody]="'Missing phone number'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditAccountType()">
          <tidy-row>
            <tidy-text
              [body]="'Account Type'">
            </tidy-text>
            <br>
            <tidy-text
              [helper]="accountType">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditBackupTimes()" *ngIf="userRole !== 'member'">
          <tidy-row>
            <tidy-text
              [body]="'Default Backup Times'">
            </tidy-text>
            <br>
            <tidy-text
              *ngIf="!hasBackupTimes"
              [helper]="'None'">
            </tidy-text>
            <ng-container *ngFor="let time of scheduleAssurance?.customerAddress?.times">
              <ng-container *ngIf="time.start_time !== '00:00' && time.start_time !== null">
                <tidy-text
                  [helper]="(time.week_day | tweekday: true) + ' '">
                </tidy-text>
                <tidy-text
                  [helper]="(time.start_time | customtime: 'h:mma') + ' - ' + (time.end_time | customtime: 'h:mma') + ' '">
                </tidy-text>
                <br>
              </ng-container>
            </ng-container>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToAccountNotificationsPage()" *ngIf="userRole !== 'member'">
          <tidy-row>
            <tidy-text
              [body]="'Account Notifications'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="notificationSettings?.length == 0">
            <tidy-text
              [helper]="'None'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let setting of notificationSettings">
            <tidy-text
              [helper]="setting?.display_name + ': '">
            </tidy-text>
            <ng-container *ngFor="let channel of setting.channels; let last = last">
              <tidy-text
                [helper]="channel?.name?.substring(0,37) + (channel?.name?.length > 37 ? '...' : '')">
              </tidy-text>
              <tidy-text
                *ngIf="!last"
                [helper]="', '">
              </tidy-text>
            </ng-container>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditLanguage()">
          <tidy-row>
            <tidy-text [body]="'Display Language'"> </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text [helper]="language"> </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/history.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Account History'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToPastJobs()">
          <tidy-row>
            <tidy-text
              [body]="'Job History'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBillingHistory()" *ngIf="userRole !== 'member'">
          <tidy-row>
            <tidy-text
              [body]="'Billing History'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="userRole !== 'member' && clientInfo?.customer_account?.account_type !== 'regular'">
        <tidy-card-button (action)="goToBranding()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/palette.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Branding'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Customize the TIDY app with a unique background and logo.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="(accountType == 'Rental') && userRole !== 'member'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/integration.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="isAdvancedMode ? 'Advanced Features' : 'Rental Property Features'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToIntegrations()">
          <tidy-row>
            <tidy-text
              [body]="'Integrations'">
            </tidy-text><br>
            <tidy-text
              [helper]="'Integrate TIDY with AirBnb, VRBO, property management tools, Zapier, & more.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToAutomaticBooking()">
          <tidy-row>
            <tidy-text
              [body]="'Automatic Booking Settings'">
            </tidy-text><br>
            <tidy-text
              [helper]="'Automatically book jobs for synced reservations.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToVendorCompliance()">
          <tidy-row>
            <tidy-text
              [body]="'Vendor Compliance'">
            </tidy-text><br>
            <tidy-text
              [helper]="'Get your pros compliant with your terms.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="isRentalClient">
        <tidy-card-button (action)="goToReports()">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/document.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Reports'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'View reports for jobs, maintenances and reservations.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-card-button (action)="goToAccounting()">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/accounts.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Accounting'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'Sync your transaction data including payments by teams into QuickBooks for easier accounting.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="userRole !== 'member'">
        <tidy-card-button (action)="goToDevelopers()" class="arrow-to-top">
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/web-programming.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="'Developers'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="'TIDY API & webhooks.'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/salary.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Referral Code'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="clientInfo?.customer_account?.referral_code">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Friends who use this get $10 off their first job, then you get $10 off your next.'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <tidy-card *ngIf="userRole !== 'member'">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/credit-card.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Payment Options'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToPaymentMethods()">
          <tidy-row>
            <tidy-text [body]="'Payment Methods'"> </tidy-text><br />
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToLoadCredit()">
          <tidy-row>
            <tidy-text [body]="'Load Credit'"> </tidy-text><br />
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToPaymentSettings()">
          <tidy-row>
            <tidy-text
              [body]="'Payment Settings'">
            </tidy-text><br>
            <tidy-text
              *ngIf="settings?.billing?.charge?.type === 'before_cleaning'"
              [helper]="'Charge in advance (save 10%)'">
            </tidy-text>
            <tidy-text
              *ngIf="settings?.billing?.charge?.type !== 'before_cleaning'"
              [helper]="'Charge after jobs are done'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBuyBundle()" *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
          <tidy-row>
            <tidy-text
              [body]="'Buy a Bundle (save 10%)'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToUseGiftCard()">
          <tidy-row>
            <tidy-text
              [body]="'Use a Gift Card'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToViewPrices()">
          <tidy-row>
            <tidy-text
              [body]="'View Your Current Prices'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToSubscriptions()">
          <tidy-row>
            <tidy-text
              [body]="'Subscription'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row class="extra-bottom-padding vertical-align-center">
          <tidy-image
            [src]="'assets/img/question.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'Help'">
          </tidy-text>
        </tidy-row>
        <tidy-card-button (action)="goToDocumentation()">
          <tidy-row>
            <tidy-text
              [body]="'Documentation'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToConcierge()">
          <tidy-row>
            <tidy-text
              [body]="'Contact Support'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
        <tidy-text
          [body]="'Cancellation Options'"
          (action)="cancellationOptions()"
          class="link red">
        </tidy-text>
        <tidy-text
          [body]="'Logout'"
          (action)="logout()"
          class="link">
        </tidy-text>
      </tidy-row>
    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-card>
        <tidy-row>
          <ion-item style="--min-height: 0">
            <ion-skeleton-text animated slot="start" style="width: 35%; height: 16px"></ion-skeleton-text>
            <ion-skeleton-text animated slot="end" style="width: 47%; height: 16px"></ion-skeleton-text>
          </ion-item>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 48%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 38%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 23%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 34%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>

<tidy-bottom-nav activeRoute="/more">
</tidy-bottom-nav>

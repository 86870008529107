export const floorSelect = [
  {
    viewValue: 'Floor 1',
    value: 1
  },
  {
    viewValue: 'Floor 2',
    value: 2
  },
  {
    viewValue: 'Floor 3',
    value: 3
  },
  {
    viewValue: 'Floor 4',
    value: 4
  }
]

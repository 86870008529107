<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    [title]="'Add Property'"
    [class]="extraClass"
    [canGoBack]="flowType == 'rental'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngIf="loaded">

        <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

        <tidy-alert style="padding: 15px 15px 15px 5px" *ngIf="!windowService?.isDesktopRes && customFlowName">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="3" class="vertical-align-center">
                  <div style="margin:auto">
                    <tidy-image
                      [src]="customFlowSmallImage"
                      style="width: 50px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [body]="customFlowName">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'offers a'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="customFlowDiscount">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="'% discount on all cleanings.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-alert>

        <ng-container *ngIf="customFlowName">
          <tidy-row [align]="'center'">
            <tidy-text
              [body]="'Already have an account?'">
            </tidy-text>
            <tidy-text
              [body]="'Log In'"
              class="link"
              (action)="goToLoginPage()">
            </tidy-text>
          </tidy-row>
          <tidy-row [align]="'center'">
            <tidy-text
              [helper]="'or'"
              class="divider">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-row *ngIf="creditBalance > 0" [align]="'center'">
          <tidy-text
            [body]="'You have'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'<b>' + (creditBalance | tcurrency) + '</b>'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'in credit'">
          </tidy-text><span>&nbsp;</span>
          <ng-container *ngIf="employerName && employerName !== 'undefined'">
            <tidy-text
              [body]="'from'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="employerName"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
          </ng-container>
          <tidy-text
            [body]="'. Add a property to use your credit to pay a pro or book a job.'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!cameFromRentalOnboardingViewApp && (flowType == 'rental' || flowType == OnboardingFlow.NORMAL || flowType == OnboardingFlow.PRIVATE) && creditBalance == 0" [align]="'center'">
          <tidy-text
            [body]="'Enter the address for instant pricing & availability.'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="!cameFromRentalOnboardingViewApp && flowType !== 'rental' && flowType !== OnboardingFlow.NORMAL && flowType !== OnboardingFlow.PRIVATE && creditBalance == 0" [align]="'center'">
          <tidy-text
            [body]="'All TIDY features require a property. Add your first one today.'">
          </tidy-text>
        </tidy-row>

        <tidy-row *ngIf="cameFromRentalOnboardingViewApp" [align]="'center'">
          <tidy-text
          [body]="'All TIDY features require a property. Please add a property to view the app.'">
        </tidy-text>
        </tidy-row>   

        <tidy-row *ngIf="customFlowName" [align]="'center'">
          <tidy-text
            [body]="'Enter the address for instant pricing & availability for cleanings in your area.'">
          </tidy-text>
        </tidy-row>

        <ng-container *ngIf="flowType == OnboardingFlow.RENTAL">
          <tidy-card style="padding: 15px 15px 15px 5px">
            <tidy-card-button (action)="goToAddDirectIntegration()">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin:auto">
                        <tidy-image
                          [src]="'assets/img/integrate.svg'"
                          style="width:40px">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="'Import All Properties'">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="'If you use a PMS system you can integrate to import all properties.'">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-card-button>
            <tidy-card-button (action)="goToImportCSV()">
              <tidy-grid>
                <tidy-row>
                  <ion-row>
                    <ion-col size="2" class="vertical-align-center">
                      <div style="margin:auto">
                        <tidy-image
                          [src]="'assets/img/csv.svg'"
                          style="width:30px">
                        </tidy-image>
                      </div>
                    </ion-col>
                    <ion-col>
                      <tidy-row>
                        <tidy-text
                          [header]="'Import CSV'">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row>
                        <tidy-text
                          [body]="'Import all properties by importing a CSV.'">
                        </tidy-text>
                      </tidy-row>
                    </ion-col>
                  </ion-row>
                </tidy-row>
              </tidy-grid>
            </tidy-card-button>
          </tidy-card>

          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [helper]="'or'"
              class="divider">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <tidy-card>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row *ngIf="flowType == OnboardingFlow.RENTAL" class="vertical-align-center extra-bottom-padding">
              <tidy-image
                [src]="'assets/img/home.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [header]="'Add One Property'">
              </tidy-text>
            </tidy-row>

            <tidy-row *ngIf="flowType == OnboardingFlow.RENTAL" class="field-top-padding field-bottom-padding">
              <tidy-input-international-phone
              formControlName="phone"
              [label]="'Phone'"
              [form]="form.controls.phone"
              [inputMode]="'numeric'"
              [submitted]="submitted"
              [errorMessage]="'Please enter a valid phone.'"
              [icon]="'assets/svg/phone-portrait-outline.svg'"
              [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
              </tidy-input-international-phone>
            </tidy-row>

            <tidy-row class="field-bottom-padding" [ngClass]="flowType == OnboardingFlow.RENTAL ? '' : 'field-top-padding'">
              <tidy-select
                [icon]="'assets/svg/location-outline.svg'"
                [label]="'Country'"
                [items]="countryList"
                [form]="form.get('countryCode')"
                formControlName="countryCode"
                (optionChange)="changeCountrySelected($event)">
              </tidy-select>
            </tidy-row>

            <tidy-row class="field-bottom-padding">
              <tidy-auto-complete
                [showGoogleIcon]="true"
                label="Address"
                formControlName="address"
                [options]="autocompleteAddress"
                [icon]="'assets/svg/home-outline.svg'"
                [form]="form.controls.address"
                [errorMessage]="'Please enter a valid address'"
                [submitted]="submitted"
                (keyup)="updateSearch()"
                (action)="chooseItem($event)">
              </tidy-auto-complete>
            </tidy-row>

            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="address2"
                [form]="form.controls.address2"
                label="Unit / Apartment"
                [errorMessage]="'Please enter a valid unit'"
                [submitted]="submitted"
                [icon]="'assets/img/door.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row class="field-bottom-padding">
              <tidy-input
                formControlName="zipcode"
                [customMask]="zipCodeMask"
                [form]="form.controls.zipcode"
                [errorMessage]="'Please enter a valid zipcode'"
                [submitted]="submitted"
                [inputMode]="'numeric'"
                label="Postal Code"
                [icon]="'assets/img/mailbox.svg'">
              </tidy-input>
            </tidy-row>

            <tidy-row>
              <tidy-input
                formControlName="addressNickname"
                [form]="form.controls.addressNickname"
                [label]="'Property Nickname (optional)'"
                [icon]="'assets/svg/information-circle-outline.svg'">
              </tidy-input>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-card *ngIf="radioButtonAddress.length > 0 && submitted && (typedOnAddress || !didChooseAddress)">
          <form [formGroup]="form" novalidate action="#">
            <tidy-row>
              <tidy-text
                [header]="'Please confirm your address:'">
              </tidy-text>
            </tidy-row>

            <tidy-row>
              <tidy-radio-button
                [helper]="'If you do not see your address please retype it above'"
                [items]="radioButtonAddress"
                (optionChange)="chooseItem($event); setAddressFormControl($event)">
              </tidy-radio-button>
            </tidy-row>
          </form>
        </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Add Property'"
          class="primary"
          [action]="addAddress.bind(this)">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
</ng-template>

<ng-template #rightColumn>
  <tidy-wrapper *ngIf="customFlowName">

    <tidy-row>
      <tidy-image
        style="margin: auto; width: 100%"
        [src]="customFlowImage">
      </tidy-image>
    </tidy-row>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="customFlowName">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'offers a'">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="customFlowDiscount">
      </tidy-text><span>&nbsp;</span>
      <tidy-text
        [body]="'% discount on all cleanings.'">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper [templateRightColumn]="rightColumn" [templateContent]="content"></app-scroll-wrapper>

<ng-template #content>
  <tidy-wrapper>

    <tidy-desktop-header>
      <tidy-text
        [title]="'More'">
      </tidy-text>
      <ion-spinner *ngIf="showLoadingSpinner" name="lines" class="header-loading-spinner"></ion-spinner>
    </tidy-desktop-header>

    <ng-container *ngIf="loaded">
      <div class="two-column-layout">
        <div class="column">
          <tidy-card>
            <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
              <div class="vertical-align-center">
                <tidy-image [src]="'assets/img/home.svg'" class="title-size">
                </tidy-image>
                <tidy-text [header]="'Properties'"> </tidy-text>
              </div>
              <tidy-text
                (action)="openRightSidePanel('add-property', AddAddressPage, {})"
                [body]="'Add Property'"
                class="link">
              </tidy-text>
            </tidy-row>
            <div
              *ngFor="let address of addressResponseFiltered; let last = last">
              <tidy-card-button
                (action)="goToEditAddress(address)"
                class="arrow-to-top">
                <ng-container *ngIf="address?.address_name">
                  <tidy-row>
                    <tidy-text [body]="address?.address_name"> </tidy-text>
                  </tidy-row>
                </ng-container>
                <ng-container *ngIf="!address?.address_name">
                  <tidy-row>
                    <tidy-text [body]="address?.address1 + ' '"> </tidy-text>
                  </tidy-row>
                  <tidy-row
                    *ngIf="address?.address2 !== null && address?.address2 !== ''">
                    <tidy-text [body]="address?.address2"> </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      *ngIf="address?.city !== null && address?.city !== ''"
                      [helper]="address?.city">
                    </tidy-text>
                    <tidy-text
                      *ngIf="address?.zip !== null && address?.zip !== ''"
                      [helper]="', ' + address?.zip"
                      >>
                    </tidy-text>
                  </tidy-row>
                </ng-container>
                <tidy-row
                  class="vertical-align-center"
                  *ngIf="address?.home_access === null || address?.home_access === ''">
                  <tidy-image
                    [src]="'assets/img/red-alert.svg'"
                    class="header-size">
                  </tidy-image>
                  <tidy-text class="red" [actionBody]="'Missing access info'">
                  </tidy-text>
                </tidy-row>
                <tidy-row
                  class="vertical-align-center"
                  *ngIf="address?.home_access !== null && address?.home_access !== '' && (address?.paid_parking === null || address?.parking_spot === null || address?.parking_notes === null || address?.parking_notes === '')">
                  <tidy-image
                    [src]="'assets/img/red-alert.svg'"
                    class="header-size">
                  </tidy-image>
                  <tidy-text class="red" [actionBody]="'Missing parking info'">
                  </tidy-text>
                </tidy-row>
              </tidy-card-button>
              <tidy-row *ngIf="!last" class="extra-bottom-padding">
                <tidy-divider> </tidy-divider>
              </tidy-row>
            </div>
            <tidy-row
              *ngIf="hasThreePlusAddresses"
              [align]="'center'"
              class="extra-top-padding">
              <tidy-text
                (action)="goToAddresses()"
                [body]="'View More Properties'"
                class="link">
              </tidy-text>
            </tidy-row>
          </tidy-card>

          <tidy-card>
            <tidy-row class="vertical-align-center">
              <tidy-image [src]="'assets/img/history.svg'" class="title-size">
              </tidy-image>
              <tidy-text [header]="'Account History'"> </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="goToPastJobs()">
              <tidy-row>
                <tidy-text [body]="'Job History'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button
              (action)="goToBillingHistory()"
              *ngIf="userRole !== 'member'">
              <tidy-row>
                <tidy-text [body]="'Billing History'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card *ngIf="userRole !== 'member'">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/img/credit-card.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text [header]="'Payment Options'"> </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="openRightSidePanel('payment-methods', PaymentMethodsPage, {})">
              <tidy-row>
                <tidy-text [body]="'Payment Methods'"> </tidy-text><br />
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('load-credit', LoadCreditPage, {})">
              <tidy-row>
                <tidy-text [body]="'Load Credit'"> </tidy-text><br />
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('payment-settings', PaymentSettingsPage, {})">
              <tidy-row>
                <tidy-text [body]="'Payment Settings'"> </tidy-text><br />
                <tidy-text
                  *ngIf="settings?.billing?.charge?.type === 'before_cleaning'"
                  [helper]="'Charge in advance (save 10%)'">
                </tidy-text>
                <tidy-text
                  *ngIf="settings?.billing?.charge?.type !== 'before_cleaning'"
                  [helper]="'Charge after jobs are done'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button
              (action)="openRightSidePanel('buy-bundle', BuyBundlePage, {})"
              *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
              <tidy-row>
                <tidy-text [body]="'Buy a Bundle (save 10%)'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('use-gift-card', UseGiftCardPage, {})">
              <tidy-row>
                <tidy-text [body]="'Use a Gift Card'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('my-prices', MyPricesPage, {})">
              <tidy-row>
                <tidy-text [body]="'View Your Current Prices'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('subscription', SubscriptionsPage, {})">
              <tidy-row>
                <tidy-text [body]="'Subscription'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card *ngIf="userRole !== 'member'">
            <tidy-card-button (action)="openRightSidePanel('users', UsersPage, {})" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image [src]="'assets/img/team.svg'" class="title-size">
                </tidy-image>
                <tidy-text [header]="'Users'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Users can log into your account with their own credentials. As the account owner, you can revoke access at any time.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card
            *ngIf="clientInfo?.customer_account?.account_type !== 'regular'">
            <tidy-card-button (action)="openRightSidePanel('teams', TeamsPage, {})" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/organization.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text [header]="'Teams'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Organize your users and properties into teams to control access, notifications, billing, and reporting.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card *ngIf="isRentalClient">
            <tidy-card-button (action)="goToReports()">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/document.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Reports'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'View reports for jobs, maintenances and reservations.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="(accountType == 'Rental') && userRole !== 'member'">
            <tidy-card-button (action)="openRightSidePanel('vendor-compliance', VendorCompliancePage, {})" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/compliant.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Vendor Compliance'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Get your pros compliant with your terms.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card
            *ngIf="userRole !== 'member' && clientInfo?.customer_account?.account_type !== 'regular'">
            <tidy-card-button (action)="openRightSidePanel('app-branding', AppBrandingPage, {})" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image [src]="'assets/img/palette.svg'" class="title-size">
                </tidy-image>
                <tidy-text [header]="'Branding'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Customize the TIDY app with a unique background and logo.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </div>

        <div class="column">
          <tidy-card *ngIf="(accountType == 'Rental') && userRole !== 'member'">
            <tidy-card-button (action)="goToIntegrations()" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/integration.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [header]="'Integrations'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Integrate TIDY with AirBnb, VRBO, property management tools, Zapier, & more.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="accountType === 'Rental'">
            <tidy-card-button
              (action)="goToGuestReservations()"
              class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/book.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text [header]="'Reservations'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text [body]="'Manage your guest reservations.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button
              (action)="goToAuditLogs()"
              class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image [src]="'assets/icon/clock.png'" class="title-size">
                </tidy-image>
                <tidy-text [header]="'Audit Logs'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Audit logs for all integrations in your account.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card *ngIf="userRole !== 'member'">
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image [src]="'assets/img/settings.svg'" class="title-size">
              </tidy-image>
              <tidy-text [header]="'Account Settings'"> </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="openRightSidePanel('contact-info', ContactInfoPage, {})">
              <tidy-row>
                <tidy-text [body]="'Contact Info'"> </tidy-text><br />
                <ng-container
                  *ngIf="clientInfo?.customer_account?.account_name">
                  <tidy-text
                    [helper]="clientInfo?.customer_account?.account_name">
                  </tidy-text
                  ><br />
                </ng-container>
                <tidy-text [helper]="clientInfo?.current_user?.name">
                </tidy-text
                ><br />
                <tidy-text [helper]="clientInfo?.current_user?.email">
                </tidy-text
                ><br />
                <tidy-text
                  [helper]="settings?.profile?.phone | telephone:'format'">
                </tidy-text>
              </tidy-row>
              <tidy-row
                class="vertical-align-center"
                *ngIf="settings?.profile?.phone === null || settings?.profile?.phone === ''">
                <tidy-image
                  [src]="'assets/icon/alert-circle-red.svg'"
                  class="body-size">
                </tidy-image>
                <tidy-text class="red" [actionBody]="'Missing Phone number'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('account-type', AccountTypePage, {})">
              <tidy-row>
                <tidy-text [body]="'Account Type'"> </tidy-text>
                <br />
                <tidy-text [helper]="accountType"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button
              (action)="openRightSidePanel('backup-times', BackupTimesPage, {})"
              *ngIf="userRole !== 'member'">
              <tidy-row>
                <tidy-text [body]="'Default Backup Times'"> </tidy-text>
                <br />
                <tidy-text *ngIf="!hasBackupTimes" [helper]="'None'">
                </tidy-text>
                <ng-container
                  *ngFor="let time of scheduleAssurance?.customerAddress?.times">
                  <ng-container
                    *ngIf="time.start_time !== '00:00' && time.start_time !== null">
                    <tidy-text
                      [helper]="(time.week_day | tweekday: true) + ' '">
                    </tidy-text>
                    <tidy-text
                      [helper]="(time.start_time | customtime: 'h:mma') + ' - ' + (time.end_time | customtime: 'h:mma') + ' '">
                    </tidy-text>
                    <br />
                  </ng-container>
                </ng-container>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button
              (action)="openRightSidePanel('account-notifications', AccountNotificationsPage, {})"
              *ngIf="userRole !== 'member'">
              <tidy-row>
                <tidy-text [body]="'Account Notifications'"> </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="notificationSettings?.length == 0">
                <tidy-text [helper]="'None'"> </tidy-text>
              </tidy-row>
              <tidy-row *ngFor="let setting of notificationSettings">
                <tidy-text [helper]="setting?.display_name + ': '"> </tidy-text>
                <ng-container
                  *ngFor="let channel of setting.channels; let last = last">
                  <tidy-text
                    [helper]="channel?.name?.substring(0,37) + (channel?.name?.length > 37 ? '...' : '')">
                  </tidy-text>
                  <tidy-text *ngIf="!last" [helper]="', '"> </tidy-text>
                </ng-container>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="openRightSidePanel('edit-language', EditLanguagePage, {})">
              <tidy-row>
                <tidy-text [body]="'Display Language'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text [helper]="language"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
          <tidy-card>
            <tidy-card-button
              (action)="openRightSidePanel('bulk-upload', BulkUploadPage, {})"
              class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/icon/invoice.png'"
                  class="title-size">
                </tidy-image>
                <tidy-text [header]="'Bulk Upload'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'Replace all to-do lists, reservations, or inventory by exporting or importing.'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card *ngIf="userRole !== 'member'">
            <tidy-card-button (action)="openRightSidePanel('more/developers', DevelopersPage, {})" class="arrow-to-top">
              <tidy-row class="extra-bottom-padding vertical-align-center">
                <tidy-image
                  [src]="'assets/img/web-programming.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text [header]="'Developers'"> </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text [body]="'TIDY API & webhooks.'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card
            *ngIf="clientInfo?.customer_account?.account_type !== 'rental'">
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image [src]="'assets/img/salary.svg'" class="title-size">
              </tidy-image>
              <tidy-text [header]="'Referral Code'"> </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text [body]="clientInfo?.customer_account?.referral_code">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-text
                [body]="'Friends who use this get $10 off their first job, then you get $10 off your next.'">
              </tidy-text>
            </tidy-row>
          </tidy-card>

          <tidy-card>
            <tidy-row class="extra-bottom-padding vertical-align-center">
              <tidy-image [src]="'assets/img/question.svg'" class="header-size">
              </tidy-image>
              <tidy-text [header]="'Help'"> </tidy-text>
            </tidy-row>
            <tidy-card-button (action)="goToDocumentation()">
              <tidy-row>
                <tidy-text [body]="'Documentation'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button (action)="goToConcierge()">
              <tidy-row>
                <tidy-text [body]="'Contact Support'"> </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-row [alignLastItemRight]="true" class="extra-bottom-padding">
            <tidy-text
              [body]="'Cancellation Options'"
              (action)="openRightSidePanel('cancellation-options', CancellationOptionsPage, {})"
              class="link red">
            </tidy-text>
            <tidy-text [body]="'Logout'" (action)="logout()" class="link">
            </tidy-text>
          </tidy-row>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loaded">
      <div class="two-column-layout">
        <div class="column">
          <!-- First column skeletons -->
          <tidy-card>
            <tidy-row>
              <ion-item style="--min-height: 0">
                <ion-skeleton-text animated slot="start" style="width: 35%; height: 16px"></ion-skeleton-text>
                <ion-skeleton-text animated slot="end" style="width: 47%; height: 16px"></ion-skeleton-text>
              </ion-item>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 48%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 38%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 48%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 38%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 48%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 38%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 48%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 38%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </div>

        <div class="column">
          <!-- Second column skeletons -->
          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 27%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 23%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 34%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>

          <tidy-card>
            <tidy-row>
              <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            </tidy-row>
            <tidy-card-button [hideArrow]="true">
              <tidy-row>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 37%"></ion-skeleton-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </div>
      </div>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<app-scroll-wrapper
  [isfullWidthContent]="true"
  [templateContent]="content">
</app-scroll-wrapper>
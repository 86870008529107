<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Edit Account Type'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="loaded">
        <tidy-card>
          <tidy-row>
            <tidy-text
              [body]="'What are you using TIDY for?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-radio-button
              formControlName="type"
              [form]="form.controls.type"
              [items]="accountTypes">
            </tidy-radio-button>
          </tidy-row>
          <tidy-checkbox
            [(checked)]="simpleMode"
            [items]="[
              {
                viewValue: 'Simple Mode', value: true
              }
            ]"
            [initValue]="simpleMode">
          </tidy-checkbox>
        </tidy-card>

        <tidy-button
          [text]="'Save Account Type'"
          [action]="save.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

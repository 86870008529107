<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [title]="'Confirm'"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="customBackRoute ? customBackRoute : 'book-job'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-extra-bottom-padding' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-alert style="padding: 15px 15px 5px 10px">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="2">
                  <div style="margin: auto">
                    <tidy-image
                      [src]="'assets/img/social-1.png'"
                      style="width:35px">
                    </tidy-image>
                  </div>
                </ion-col>
                <ion-col>
                  <tidy-row>
                    <tidy-text
                      [header]="'Top Ranked on Google!'">
                    </tidy-text>
                  </tidy-row>
                  <tidy-row>
                    <tidy-text
                      [body]="'Amazing job! Her thoroughness was superb. The little things made it all the better (like refilling my keurig pod stand!) I am very impressed! - Dawn R.'">
                    </tidy-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>

          <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
            <tidy-image
              [src]="'assets/img/google.svg'"
              style="width:100px">
            </tidy-image>
            <div>
              <tidy-text
                [body]="'1,000+ Verified Reviews'">
              </tidy-text><br>
              <tidy-image
                [src]="'assets/img/4_5_stars.svg'"
                style="width:85px">
              </tidy-image>
              <tidy-text
                [body]="'4.5 Stars'">
              </tidy-text>
            </div>
          </tidy-row>
      </tidy-alert>

      <tidy-alert *ngIf="loaded && !selectedPrivatePro && !isUpdatingSubscription && (bookingExperiment == 'rental-v1b' || bookingExperiment == 'rental-v2b') && viewData?.frequency == 'One Time' && !hasNonTrialPaidSubscription">
        <div style="background: rgba(0, 170, 186, 0.8); padding: 10px 5px 10px 5px; margin: -10px; border-radius: 5px;">
          <ng-container *ngIf="!isUpdatingSubscription">
            <tidy-row [align]="'center'" class="extra-bottom-padding">
              <tidy-text
                [header]="'Upgrade to TIDY+ & Save 20%'"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'" class="extra-bottom-padding">
              <tidy-text
                [actionBody]="'TIDY+ lets you save 20% booking per property per year, cancel anytime.'"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'">
              <tidy-text
                [actionBody]="(viewData.price | tcurrency)"
                class="white">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [actionBody]="'for job (save 20%)'"
                class="white">
              </tidy-text><br>
              <tidy-text
                [actionBody]="'$36 for 1 year TIDY+'"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row>
              <tidy-divider style="background: white; width: 150px; margin: auto"></tidy-divider>
            </tidy-row>
            <tidy-row [align]="'center'" class="extra-bottom-padding">
              <tidy-text
                [actionBody]="(viewData.price + 3600 | tcurrency)"
                class="white">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [actionBody]="'total today'"
                class="white">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'" class="extra-top-padding vertical-align-center">
              <tidy-image
                [src]="'assets/img/discount.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                (click)="updateSubscription(this)"
                [largeBody]="isUpdatingSubscription ? 'Remove TIDY+' : 'Add TIDY+'"
                class="white link"
                style="cursor: pointer">
              </tidy-text>
            </tidy-row>
            <tidy-row [align]="'center'" class="extra-top-padding">
              <tidy-text
                (click)="goToViewSubscriptions(this)"
                [actionBody]="'View Other Subscription Types'"
                class="white link"
                style="cursor: pointer">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </div>
      </tidy-alert>

      <tidy-alert *ngIf="loaded && !selectedPrivatePro && hasNonTrialPaidSubscription && (viewData?.frequency == 'One Time' || viewData?.frequency == 'One Time (+25%)')">
        <div style="background: rgba(0, 170, 186, 0.8); padding: 10px 5px 10px 5px; margin: -10px; border-radius: 5px;">
          <tidy-row [align]="'center'">
            <tidy-text
              [actionBody]="'Saving'"
              class="white">
            </tidy-text>
            <tidy-text
              [actionBody]="(viewData?.frequency == 'One Time' ? '20%' : '25%')"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'with your'"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="me.getSubscriptionType(currentSubscription?.key)"
              class="white">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [actionBody]="'subscription!'"
              class="white">
            </tidy-text>
          </tidy-row>
        </div>
      </tidy-alert>

      <tidy-card>
        <ng-container *ngIf="loaded">
            <tidy-grid>
              <tidy-row>
                <ion-row>
                  <ion-col size="4">
                    <div style="margin: auto">
                      <tidy-image
                        *ngIf="!customFlowSmallImage"
                        [src]="viewData?.image"
                        style="width:70px">
                      </tidy-image>
                      <tidy-image
                        *ngIf="customFlowSmallImage"
                        [src]="customFlowSmallImage"
                        style="width:95px">
                      </tidy-image>
                    </div>
                  </ion-col>
                  <ion-col>
                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/time-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="viewData?.service | titlecase">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/refresh-circle-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="viewData?.frequency">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row class="vertical-align-center" *ngIf="!isProRequest && !isJobRequest">
                      <tidy-image
                        [src]="'assets/svg/calendar-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="viewData?.dateTime">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="viewData?.dateTimeTwo" class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/calendar-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="viewData?.dateTimeTwo">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="viewData?.dateTimeThree" class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/calendar-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="viewData?.dateTimeThree">
                      </tidy-text>
                    </tidy-row>

                    <ng-container *ngIf="isJobRequest">
                      <tidy-row class="vertical-align-center">
                        <tidy-image
                          [src]="'assets/svg/calendar-outline.svg'"
                          class="body-size">
                        </tidy-image>
                        <tidy-text
                          [body]="'Earliest:'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="(requestDates?.startDate | customdate:'ddd M/D') + ' ' + (bookData?.booking?.start_time_no_earlier_than | customtime:'h:mma')">
                        </tidy-text>
                      </tidy-row>
                      <tidy-row class="vertical-align-center">
                        <tidy-image
                          [src]="'assets/svg/calendar-outline.svg'"
                          class="body-size">
                        </tidy-image>
                        <tidy-text
                          [body]="'Latest:'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [body]="(requestDates?.endDate | customdate:'ddd M/D') + ' ' + (bookData?.booking?.end_time_no_later_than | customtime:'h:mma')">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>

                    <tidy-row *ngIf="(viewData?.price !== 0 && viewData?.price !== '')" class="vertical-align-center">
                      <tidy-image
                        [src]="'assets/svg/card-outline.svg'"
                        class="body-size">
                      </tidy-image>
                      <tidy-text
                        [body]="'Total:'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="displayPrice">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="!selectedPrivatePro && isUpdatingSubscription && viewData?.frequency == 'One Time'" style="margin-left: 21px">
                      <ng-container *ngIf="!giftCode">
                        <tidy-text
                          [helper]="(viewData?.price | tcurrency)">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [helper]="'for job (saving 20%)'">
                        </tidy-text>
                      </ng-container>
                      <ng-container *ngIf="giftCode">
                        <tidy-text
                          [helper]="((viewData?.price - giftCode?.amount) | tcurrency)">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [helper]="'for job (saving 20%)'">
                        </tidy-text>
                      </ng-container><br>
                      <tidy-text
                        [helper]="(currentSubscription?.amount | tcurrency)">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [helper]="'for'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [helper]="me.getSubscriptionType(currentSubscription?.key)">
                      </tidy-text>
                      <tidy-text
                        [body]="'remove'"
                        class="link"
                        (action)="updateSubscription()">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="viewData?.frequency == 'One Time' && bookingExperiment == 'consumer-test2'" style="margin-left: 21px">
                      <tidy-text
                        [helper]="'$10 coupon applied!'">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="!selectedPrivatePro && priceExperiment == 'showCrossedOutDiscount' && viewData?.frequency !== 'One Time'" style="margin-left: 21px">
                      <tidy-text
                        [helper]="'Saving 20% with recurring plan'">
                      </tidy-text>
                    </tidy-row>

                    <tidy-row *ngIf="customFlowName" class="extra-bottom-padding" style="margin-left: 21px">
                      <tidy-text
                        [body]="customFlowName">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="'offers a'">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="customFlowDiscount">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [body]="'% discount on all cleanings.'">
                      </tidy-text>
                    </tidy-row>

                    <ng-container *ngIf="(viewData?.price !== 0 && viewData?.price !== '') && giftCode && bookingExperiment !== 'consumer-test2'">
                      <ng-container *ngIf="giftCode?.amount">
                        <tidy-row class="vertical-align-center" style="margin-left: 21px">
                          <tidy-text
                            [helper]="(giftCode?.amount | tcurrency)">
                          </tidy-text><span>&nbsp;</span>
                          <tidy-text
                            [helper]="'gift code applied'">
                          </tidy-text><span>&nbsp;</span>
                          <ng-container *ngIf="giftCode?.giver_name">
                            <tidy-text
                              [helper]="'from'">
                            </tidy-text>
                            <tidy-text
                              [helper]="giftCode?.giver_name"
                              [translate]="false">
                            </tidy-text>
                          </ng-container>
                        </tidy-row>
                      </ng-container>
                      <tidy-row *ngIf="giftCode?.gift_instructions" class="vertical-align-center" style="margin-left: 21px">
                        <tidy-text
                          [helper]="'Instructions:'">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [helper]="giftCode?.gift_instructions">
                        </tidy-text>
                      </tidy-row>
                    </ng-container>

                    <ng-container *ngIf="isProRequest">
                      <tidy-row class="vertical-align-center">
                        <tidy-image
                          [src]="'assets/svg/time-outline.svg'"
                          class="body-size">
                        </tidy-image>
                        <tidy-text
                          [body]="'Times Requested:'">
                        </tidy-text>
                      </tidy-row>
                      <ng-container *ngFor="let time of requests.buildRequestTimeRangeList(payload?.request_time_blocks); let last = last">
                        <tidy-row>
                          <tidy-text
                            [body]="time">
                          </tidy-text>
                        </tidy-row>
                      </ng-container>
                    </ng-container>
                  </ion-col>
                </ion-row>
              </tidy-row>
            </tidy-grid>

            <ng-container *ngIf="creditBalance > 0">
              <tidy-row class="extra-top-padding extra-bottom-padding">
                <tidy-divider>
                </tidy-divider>
              </tidy-row>
              <tidy-row class="vertical-align-center">
                <tidy-image
                  [src]="'assets/svg/checkmark-circle.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Credit Applied'">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <ng-container *ngIf="creditBalance < viewData?.price">
                  <tidy-text
                    [body]="'You have'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(creditBalance | tcurrency)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'in credit'">
                  </tidy-text><span>&nbsp;</span>
                  <ng-container *ngIf="employerName && employerName !== 'undefined'">
                    <tidy-text
                      [body]="'from'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="employerName"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                  </ng-container>
                  <tidy-text
                    [body]="'that will automatically be used for this job.'">
                  </tidy-text>
                </ng-container>
                <ng-container *ngIf="creditBalance >= viewData?.price">
                  <tidy-text
                    [body]="'You have'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="(creditBalance | tcurrency)">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    [body]="'in credit'">
                  </tidy-text><span>&nbsp;</span>
                  <ng-container *ngIf="employerName && employerName !== 'undefined'">
                    <tidy-text
                      [body]="'from'">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="employerName"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                  </ng-container>
                  <tidy-text
                    [body]="', enough to pay for this job in full. We still require a card on file but you will not be charged.'">
                  </tidy-text>
                </ng-container>
              </tidy-row>
            </ng-container>

            <tidy-row class="extra-top-padding">
              <form [formGroup]="form">
                <tidy-radio-button
                  [label]="'How do you want to pay?'"
                  formControlName="chargeType"
                  [form]="form.controls.chargeType"
                  [items]="[{viewValue: 'Charge in advance (save additional 10%)', value: 'before_cleaning'},{viewValue: 'Charge after job', value: 'after_cleaning'}]"
                  class="no-margin-first"
                  (optionChange)="changePrice($event)">
                </tidy-radio-button>
              </form>
            </tidy-row>

            <tidy-row *ngIf="showSameDayTimeAlert" class="extra-top-padding">
              <tidy-text
                [body]="'<b>*Note:</b> You selected times today. If a Pro accepts a time today the job price will be'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [body]="(sameDayPrice | tcurrency) + '.'">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-top-padding extra-bottom-padding">
              <tidy-divider>
              </tidy-divider>
            </tidy-row>

          </ng-container>
          <tidy-row>
            <form class="checkout">
              <tidy-row [alignLastItemRight]="true">
                <tidy-text
                  [header]="'Enter Card Information'">
                </tidy-text>
                <div>
                <tidy-image
                  [src]="'assets/img/norton_av_logo.png'"
                  style="width:70px">
                </tidy-image>
              </div>
              </tidy-row>
              <div class="form-row">
                <div id="card-info" #cardInfo></div>
              </div>
            </form>
          </tidy-row>
          <ng-container *ngIf="!(bookingExperiment == 'consumer-test2' && viewData?.frequency == 'One Time')">
            <tidy-row [align]="'center'" *ngIf="!giftCode && !customFlowName">
              <tidy-text
                [actionBody]="'Add Referral / Gift Code'"
                (action)="giftCodeForm = true"
                class="link">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="giftCodeForm">
              <tidy-row class="field-top-padding">
                <form [formGroup]="giftForm">
                  <tidy-input
                    [label]="'Referral/Gift Code'"
                    [icon]="'assets/svg/gift-outline.svg'"
                    formControlName="giftCode"
                    [form]="giftForm.controls.giftCode">
                  </tidy-input>
                </form>
              </tidy-row>
              <tidy-row class="extra-bottom-padding" *ngIf="giftCodeErrorMessage && giftForm?.value?.giftCode">
                <tidy-text
                  [body]="giftCodeErrorMessage"
                  class="red">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding" *ngIf="giftCode && !giftCodeErrorMessage">
                <tidy-text
                  [body]="'Code successfully applied!'"
                  class="green">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="'We apply credit from your referral/gift code first before charging. If your code covers the full cleaning, you won\'t be charged at all. For the safety of your booked cleaning person, we do require a card on file.'">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="customFlowName">
            <tidy-row>
              <tidy-text
                [header]="'Enter Contact Detail'">
              </tidy-text>
            </tidy-row>
            <form [formGroup]="form">
              <tidy-row class="field-top-padding field-bottom-padding">
                <tidy-input
                  formControlName="firstName"
                  [form]="form.controls.firstName"
                  [label]="'First Name'"
                  [errorMessage]="'Please enter your first name.'"
                  [submitted]="submitted"
                  [icon]="'assets/svg/person-outline.svg'">
                </tidy-input>
              </tidy-row>
              <tidy-row class="field-bottom-padding">
                <tidy-input
                  formControlName="lastName"
                  [form]="form.controls.lastName"
                  [label]="'Last Name'"
                  [errorMessage]="'Please enter your last name.'"
                  [submitted]="submitted"
                  [icon]="'assets/svg/person-outline.svg'">
                </tidy-input>
              </tidy-row>
              <tidy-row class="field-bottom-padding">
                <tidy-input
                  formControlName="email"
                  [form]="form.controls.email"
                  [label]="'Email'"
                  type="email"
                  [errorMessage]="'Please enter a valid email.'"
                  [submitted]="submitted"
                  [icon]="'assets/svg/mail-outline.svg'">
                </tidy-input>
              </tidy-row>
              <tidy-row>
                <tidy-input-international-phone
                 formControlName="phone"
                 [label]="'Phone'"
                 [form]="form.controls.phone"
                 [inputMode]="'numeric'"
                 [submitted]="submitted"
                 [errorMessage]="'Please enter a valid phone.'"
                 [icon]="'assets/svg/phone-portrait-outline.svg'"
                 [activeIcon]="'assets/svg/phone-portrait-outline-tidy-green.svg'">
                </tidy-input-international-phone>
              </tidy-row>
            </form>
          </ng-container>
          <tidy-row class="extra-top-padding vertical-align-center">
            <tidy-checkbox
              style="margin-right: 10px; margin-top: -2px"
              [(checked)]="termsChecked"
              [items]="[
                {
                  viewValue: '', value: true
                }
              ]"
              [initValue]="termsChecked">
            </tidy-checkbox>
            <tidy-text
              style="margin-right: 4px"
              [body]="'I still agree to the'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              (action)="openTerms()"
              [body]="'terms'"
              class="link">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="termsError">
            <tidy-error-message
              [text]="'You must agree to the terms to continue.'">
            </tidy-error-message>
          </tidy-row>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-button
        [text]="buttonTitle"
        [action]="confirmBooking.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-row *ngIf="!selectedPrivatePro">
        <tidy-text
          [header]="'Satisfaction Guarantee'">
        </tidy-text><br>
        <tidy-text
          [body]="'If you are unhappy with your first cleaning for any reason, get a new cleaning for free.'">
        </tidy-text>
      </tidy-row>

      <tidy-row>
        <tidy-text
          [header]="'Cancel Any Time'">
        </tidy-text><br>
        <tidy-text
          [body]="'Most pros ask 24 hours notice.'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="!selectedPrivatePro">
        <tidy-row>
          <tidy-text
            [header]="'What\'s Included?'">
          </tidy-text><br>
          <tidy-text
            [body]="'All cleanings are completely customized by you, they can include anything you like!  You selected a'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="viewData?.service">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            *ngIf="bookData?.service_type_key === 'regular_cleaning.one_hour'"
            [body]="'which is great for light or very focused cleanings.'">
          </tidy-text>
          <tidy-text
            *ngIf="bookData?.service_type_key === 'regular_cleaning.two_and_a_half_hours'"
            [body]="'which is great for a deeper cleaning of a small home, or a reasonable cleaning in most rooms a 2 bedroom home.'">
          </tidy-text>
          <tidy-text
            *ngIf="bookData?.service_type_key === 'regular_cleaning.four_hours'"
            [body]="'which is great for most homes, if you need a load or 2 of laundry done, and gives enough time to get to most To Dos in a 3 bedroom home.'">
          </tidy-text>
          <tidy-text
            *ngIf="bookData?.service_type_key === 'regular_cleaning.turnover_four_hours'"
            [body]="'which is great for deep cleanings, move in / move out cleanings, and also can be great to jump start a clean home and then follow it up with a different recurring cleaning.'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-text
            [body]="'People commonly request things like the following, but it can really be anything! Examples of what you can pick:'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-text
            [body]="'<strong>Bedroom, Living Room & Common Areas</strong>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Dust accessible surfaces</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Wipe down mirrors and glass fixtures</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Wipe down interior windows</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Run laundry & dryer</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Mop & vacuum floors</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Take out garbage</li>'">
          </tidy-text><br>
          <tidy-text
            [body]="'<strong>Bathroom Cleaning</strong>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Wash and sanitize the toilet, shower, tub and sink</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Dust accessible surfaces</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Wipe down mirrors and glass fixtures</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Clean inside cabinets</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Mop & vacuum floors</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Take out garbage</li>'">
          </tidy-text><br>
          <tidy-text
            [body]="'<strong>Kitchen Cleaning</strong>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Dust accessible surfaces</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Empty sink and load up dishwasher</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Wipe down exterior of stove, oven and fridge</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Clean inside fridge</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Clean inside oven</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Clean inside cabinets</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Mop & vacuum floors</li>'">
          </tidy-text>
          <tidy-text
            [body]="'<li>Take out garbage and recycling</li>'">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-row>
        <tidy-text
          [header]="'Need Alternate Agreement?'">
        </tidy-text><br>
        <tidy-text
          [body]="'If you need an alternate agreement or custom compliance steps, contact sales@tidy.com instead.'">
        </tidy-text>
      </tidy-row>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Workflows } from 'src/providers/workflows/workflows';

import { RecommendedWorkflow } from 'src/models/workflow.model';

import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { ContactConciergePage } from 'src/pages/concierge/contact-concierge/contact-concierge';

@Component({
  templateUrl: 'add-workflow.html',
  styleUrls: ['add-workflow.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddWorkflowPage implements OnInit {

  form: UntypedFormGroup;
  recommendedWorkflows: RecommendedWorkflow[] = [];
  imutableRecommendedWorkflows: RecommendedWorkflow[] = [];
  isRightSideContent: boolean;
  triggerIcons = [
    {
      category: 'Guest Reservations',
      icon: 'https://retool-uploads.s3.amazonaws.com/booking (2) (1).svg',
      backgroundColor: '#ff5b5b',
    },
    {
      category: 'Jobs',
      icon: 'https://retool-uploads.s3.amazonaws.com/service (1).svg',
      backgroundColor: '#9cbfe6',
    },
    {
      category: 'Messages',
      icon: 'https://retool-uploads.s3.amazonaws.com/chat (1).svg',
      backgroundColor: '#95d6a4',
    },
    {
      category: 'Scheduled',
      icon: 'https://d30thx9uw6scot.cloudfront.net/date-calendar (1).svg',
      backgroundColor: '#f99188',
    },
    {
      category: 'Issues',
      icon: 'https://retool-uploads.s3.amazonaws.com/warning-sign (1).svg',
      backgroundColor: '#f47544',
    },
  ];

  constructor(
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private fb: FormBuilder,
    workflowProvider: Workflows
  ) {
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.filterWorkflows(val.search));
    this.recommendedWorkflows = workflowProvider.getRecommendedWorkflows();
    this.imutableRecommendedWorkflows = workflowProvider.getRecommendedWorkflows();
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Add Workflow');
  }

  goToAddConciergeTask(): void {
    const params = {
      title: 'Add Automation',
      type: 'support.concierge_task',
      isWorkflow: true,
    };
    this.goToPage('contact-concierge', params, ContactConciergePage);
  }

  goToBuildFromSketch(): void {
    this.storage.save('workflowId', 'new');
    this.goToPage('automations/workflow/new/edit', {}, EditWorkflowPage);
  }

  goToEditByRecommendedWorkflow(index: number): void {
    this.storage.save('workflowId', 'new');
    const params = {
      recommendedWorkflowIndex: index,
    };
    this.goToPage('automations/workflow/new/edit', params, EditWorkflowPage);
  }

  getCategoryIcon(category: string): string {
    return this.triggerIcons.find((icon) => icon.category === category)?.icon;
  }

  getCategoryBackgroundColor(category: string): string {
    return this.triggerIcons.find((icon) => icon.category === category)
      ?.backgroundColor;
  }

  filterWorkflows(search: string): void {
    this.recommendedWorkflows = this.imutableRecommendedWorkflows.filter(
      (workflow) =>
      workflow.description.toLowerCase().includes(search.toLowerCase()) ||
      workflow.trigger.category.toLowerCase().includes(search.toLowerCase())
    );
  }

  async goToPage(url, params, component) {
    if (this.isRightSideContent) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    } 
  }
}

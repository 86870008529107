import { Component, OnInit } from '@angular/core';

import { Loading } from 'src/shared/components/loading/loading';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { Util } from 'src/shared/util/util';
import { GetRefundPage } from '../get-refund/get-refund';

@Component({
  templateUrl: 'billing-item-help.html'
})

export class BillingItemHelpPage implements OnInit {

  item: any;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private storage: TidyStorage,
    private util: Util,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit() {
    this.rightSidePanelService.setDialogPageTitle('Billing History');
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.item = this.navCtrl.getParam('item') || this.dialogParams?.item;
  }

  goToCleaningDetails(cleaningId) {
    this.rightSidePanelService.navigateTo('cleaning-details', {jobId: cleaningId}, PastJobPage);
  }

  getRefund(refundable) {
    if (!refundable) {
      this.showCantRefund();
    } else {
      this.rightSidePanelService.navigateTo('get-refund', {refundable}, GetRefundPage);
    }
  }

  showCantRefund() {
    const message = 'Unable to refund. Please contact us in the Concierge section for support.';
    this.util.showError(message, 10000);
  }

  @Loading('', true)
  getHelp(jobDetail) {
    const params = {
      title: 'Billing Help',
      type: 'support.billing',
      metadata: jobDetail
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

}
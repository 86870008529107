import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { AttachmentModel, ConciergeCommentModel, ConciergeItemModel } from 'src/models/concierge.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';

import { TaskPage } from 'src/pages/tasks/task/task';

@Component({
  templateUrl: 'concierge-reply.html',
  styleUrls: ['./concierge-reply.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ConciergeReplyPage implements OnInit {

  errorMessage: string;
  email: string;
  form: UntypedFormGroup;
  item: ConciergeItemModel;
  submitted: boolean;
  attachments: AttachmentModel[] = [];
  comments: ConciergeCommentModel[] = [];
  isRightSideContent: boolean = true;
  dialogParams: any;
  loaded: boolean = false;

  constructor(
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanel: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util,
    public windowService: WindowService
  ) {
    this.form = this.fb.group({
      content: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanel.setDialogPageTitle('Create Action Plan');
    if (this.windowService.isDesktopRes) {
      this.rightSidePanel.setDialogPageTitle('Concierge Action')
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.item = await this.concierge.getConciergeItemById(this.dialogParams.itemId);
      this.comments = this.item?.components?.[0]?.value?.comments || [];
    } else {
      this.item = this.navCtrl.getParam('item');
      const itemId = this.navCtrl.getParam('itemId');
      this.item = await this.concierge.getConciergeItemById(itemId);
      this.comments = this.item?.components?.[0]?.value?.comments || [];
    }
    this.loaded = true;
  }

  async send() {
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    let data = {
    	support_ticket_id: this.item.metadata.support_ticket_id,
      content: this.form.value.content,
      attachments: this.attachments.map(item => item.fileKey)
    };
    try {
      await this.concierge.addConciergeTicketComment(data);
      const successMessage = 'Reply sent';
      this.util.showSuccess(successMessage);
      if (this.windowService.isDesktopRes) {
        this.rightSidePanel.openRightSidePanel(TaskPage);
      } else {
        this.navCtrl.navigateForward('task')
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async addAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.concierge.addAttachment();
      this.attachments.push(attachment);
    } catch (err) {
      this.errorMessage = err;
    }
  }

  removeAttachment(attachIndex: number) {
    this.attachments.splice(attachIndex, 1);
  }

}

<mat-form-field
  [class.invalid-field]="!form.valid && !loadingState.loading && submitted"
  appearance="outline">

  <mat-label *ngIf="label" [ngClass]="leftIconAction ? 'margin-left' : ''">
    <tidy-image *ngIf="icon" [src]="icon" class="outline-field-label-icon"> </tidy-image>
    <tidy-text [body]="label"> </tidy-text>
  </mat-label>

  <textarea
    [ngClass]="leftIconAction ? 'padding-left' : ''"
    cdkTextareaAutosize
    matInput
    [(ngModel)]="value"
    (focus)="setFocused(true)"
    (keyup)="keyUpHandle($event)"
    (focusout)="setFocused(false)">
  </textarea>

  <button
    *ngIf="leftIconAction"
    matPrefix
    mat-icon-button
    [disabled]="loadingState.loading"
    mat-mini-fab
    (click)="executeLeftIconAction()"
    [ngClass]="{ 'black-background': showBlackButtons }">
    <mat-icon>camera_alt</mat-icon>
  </button>

  <button
    *ngIf="rightIconAction"
    matSuffix
    mat-icon-button
    [ngClass]="{ 'empty': isInputEmpty, 'black-background': showBlackButtons }"
    [disabled]="loadingState.loading || isInputEmpty"
    mat-mini-fab
    (click)="executeRightIconAction()">
    <mat-icon>chevron_right</mat-icon>
  </button>

</mat-form-field>

<tidy-error-message *ngIf="!form.valid && submitted" [text]="errorMessage">
</tidy-error-message>

import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';
import { Validators } from "@angular/forms";
import * as moment from "moment";

@Injectable()
export class FeedbackCleaning {

  constructor(
    private httpClient: HttpClientCustom
  ) { }

  startFeedback(jobId, hkJobId, type) {
    const data = {
      job_id: jobId,
      homekeeper_job_id: hkJobId,
      type: type
    }
    return this.sendFeedback(data);
  }

  sendFeedback(data) {
    const { job_id, homekeeper_job_id } = data;
    const url = `api/v1/customer/cleanings/${job_id}/feedbacks/${homekeeper_job_id}`;
    return this.httpClient.post(url, data);
  }

  async getFeedback(data) {
    const { jobId, hkJobId } = data;
    const url = `api/v1/customer/cleanings/${jobId}/feedbacks/${hkJobId}`;
    const result = await this.httpClient.get(url);
    result.other_homekeepers.map(this.formatHk.bind(this, result));
    this.adjustName(result.homekeeper);
    return result;
  }

  submitMoreNegativeFeedback(params, answers = null, completed: boolean = null) {
    const data = {
      homekeeper_job_id: params.hkJobId,
      feedback: { type: params.type },
      answers,
      completed
    };
    const url = `api/v1/customer/cleanings/${params.jobId}/feedbacks/${params.hkJobId}`;
    return this.httpClient.put(url, data);
  }

  submitMorePositiveFeedback(data) {
    const { job_id, homekeeper_job_id } = data;
    const url = `api/v1/customer/cleanings/${job_id}/feedbacks/${homekeeper_job_id}`;
    return this.httpClient.put(url, data);
  }

  getFeedbackForCleaning(jobId, hkJobId) {
    const url = `api/v1/customer/cleanings/${jobId}/feedbacks/${hkJobId}`;
    return this.httpClient.get(url);
  }

  adjustName(hk) {
    hk.firstName = hk.name.split(' ')[0];
  };

  formatHk(result, hk) {
    this.adjustName(hk);
    this.formatHkJob(result, hk);
  }

  formatHkJob(result, hk) {
    result.homekeeper_jobs.map((item) => {
      if (hk.id === item.user_id) {
        hk.hkJobId = item.id;
        hk.feedbackDone = (item.feedback_type !== null);
      }
    });
  }

  getFormData(mountedData, formData) {
    const { cleaningId, hkJobId, typeFeedback, todos } = mountedData
    if (typeFeedback === 'positive') {
      formData.bkHk = false;
    }
    return {
      type: typeFeedback,
      job_id: cleaningId,
      homekeeper_job_id: hkJobId,
      to_dos: (todos) ? todos : [],
      feedback: { type: typeFeedback, text: formData.feedback },
      blacklist_homekeeper: formData.bkHk,
      replace_preferred_homekeeper: formData.replaceHk
    }
  }

  mountTodoData(formResults) {
    const formData = [];
    formResults.rooms.forEach((items) => {
      items.todos.forEach((item) => {
        const row = { task_id: item.job_task_id, performance: item.performance }
        formData.push(row);
      });
    });
    return formData;
  }

  getAppReviewData(platform) {
    const url = `api/v1/customer/app-reviews/information?platform=${platform}`;
    return this.httpClient.get(url);
  }

  recordSawAppReviewLink(app_review_page_id, source, prompted_at) {
    const data = {
      app_review_page_id: app_review_page_id,
      source: source,
      prompted_at: prompted_at,
    }
    const url = `api/v1/customer/app-reviews`;
    return this.httpClient.post(url, data);
  }

  recordClickedAppReviewLink(app_review_attempt_id, review_at) {
    const data = {
      app_review_attempt_id: app_review_attempt_id,
      review_at: review_at,
    }
    const url = `api/v1/customer/app-reviews/${app_review_attempt_id}`;
    return this.httpClient.put(url, data);
  }

  getWhatHappenedControls() {
    return {
      "what_happened.didnt_finish": false,
      "what_happened.bad_ability": false,
      "what_happened.bad_supplies": false,
      "what_happened.bad_attitude": false,
      "what_happened.serious_issue": false
    };
  }

  getWhatHappenedQuestions(hkName) {
    const questions = {
      "what_happened.didnt_finish": `${hkName} didn’t finish To Dos`,
      "what_happened.bad_ability": `${hkName} had poor abilities`,
      "what_happened.bad_supplies": `${hkName} didn’t have good supplies`,
      "what_happened.bad_attitude": `${hkName} was unprofessional`,
      "what_happened.serious_issue": `Other safety issue`
    };
    return questions;
  }

  parseAnswers(feedbackData) {
    let answers = {};
    feedbackData.homekeeper_job_feedback.answers.forEach(answer => {
      answers[answer.key] = answer.value;
    });

    return answers;
  }

  calcTimes(feedbackData) {
    const arrival = feedbackData.homekeeper_job_feedback.homekeeper_job.homekeeper_arrival;
    const end = feedbackData.homekeeper_job_feedback.homekeeper_job.homekeeper_end;

    const arrivedLast = moment(arrival).add(2, "hour");
    const leftLast = moment(end).add(2, "hour");


    let current =  moment(arrival).subtract(30, "minute");
    const arrivalTimes = this.fillTimes(current, arrivedLast);

    current =  moment(arrival);
    const leftTimes = this.fillTimes(current, leftLast);


    return {
      arrivalTimes,
      leftTimes
    };
  }

  getLearnMoreKeys() {
    const keys = [
      "didnt_finish.cleaned_slowly",
      "didnt_finish.didnt_follow",
      "didnt_finish.needed_more_time",
      "didnt_finish.didnt_stay.arrived_at",
      "didnt_finish.didnt_stay.left_at",
      "bad_ability.star_rating",
      "bad_supplies.vacuum",
      "bad_supplies.mop",
      "bad_supplies.towels_and_sponges",
      "bad_supplies.apc",
      "bad_supplies.glass_cleaner",
      "bad_supplies.toilet_shower_cleaner",
      "bad_supplies.not_strong_enough",
      "bad_supplies.dirty_smelled_bad",
      "bad_supplies.not_green",
      "bad_attitude.refused_to_listen",
      "bad_attitude.negative_vibe",
      "bad_attitude.unprofessional_clothing",
      "bad_attitude.distracted_on_phone",
      "bad_attitude.talked_too_much",
      "bad_attitude.didnt_try_hard",
      "bad_attitude.bad_hygiene",
      "blacklist_hk",
      "constructive_feedback",
      "serious_issue",
      "disputed"
    ];

    let controls = {};
    keys.forEach(key => {
      controls[key] = null;
    });

    controls['blacklist_hk'] = [null, Validators.required]

    return controls;
  }

  getBadAttitudeKeys() {
    return [
      "bad_attitude.refused_to_listen",
      "bad_attitude.negative_vibe",
      "bad_attitude.unprofessional_clothing",
      "bad_attitude.distracted_on_phone",
      "bad_attitude.talked_too_much",
      "bad_attitude.didnt_try_hard",
      "bad_attitude.bad_hygiene"
    ];
  }

  getBadAttitudeQuestions() {
    return {
      "bad_attitude.refused_to_listen": `Refused to listen`,
      "bad_attitude.negative_vibe": `Negative or complaining attitude`,
      "bad_attitude.unprofessional_clothing": `Unprofessional`,
      "bad_attitude.distracted_on_phone": `Distracted on phone`,
      "bad_attitude.talked_too_much": `Talked too much`,
      "bad_attitude.didnt_try_hard": `Didn’t try hard`,
      "bad_attitude.bad_hygiene": 'Had bad hygiene'
    };
  }

  getBadSuppliesKeys() {
    return [
      [
        "bad_supplies.vacuum",
        "bad_supplies.mop",
        "bad_supplies.towels_and_sponges",
        "bad_supplies.apc",
        "bad_supplies.glass_cleaner",
        "bad_supplies.toilet_shower_cleaner",
      ],
      [
        "bad_supplies.not_strong_enough",
        "bad_supplies.dirty_smelled_bad",
        "bad_supplies.not_green"
      ]
    ];
  }

  getBadSuppliesQuestions() {
    return {
      "bad_supplies.vacuum": `Vacuum`,
      "bad_supplies.mop": `Mop`,
      "bad_supplies.towels_and_sponges": `Towels & Sponges`,
      "bad_supplies.apc": `All Purpose Cleaner`,
      "bad_supplies.glass_cleaner": `Glass Cleaner`,
      "bad_supplies.toilet_shower_cleaner": `Toilet & Shower Cleaner`,
      "bad_supplies.not_strong_enough": `Not Strong Enough`,
      "bad_supplies.dirty_smelled_bad": `Dirty or Smelled Bad`,
      "bad_supplies.not_green": `Weren’t Green`
    };
  }

  format(time) {
    return time.utc().format("h:mma");
  }

  fillTimes(current, last) {
    let times = [];
    while (current <= last) {
      times.push(this.format(current));
      current  =  current.add(5, "minute");
    }

    return times;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="detailsType === 'sms'">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'TIDY successfully sent this sms to'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="notification?.homekeeper?.first_name + ':'">
          </tidy-text>
        </tidy-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [body]="notification?.notifications?.sms?.content?.message">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="detailsType === 'email'">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'TIDY successfully sent this email to'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="notification?.homekeeper?.first_name + ':'">
          </tidy-text>
        </tidy-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="notification?.notifications?.email?.content?.subject">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="notification?.notifications?.email?.content?.body">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>
      <ng-container *ngIf="detailsType === 'app'">
        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'TIDY successfully sent this in app notification to'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="notification?.homekeeper?.first_name + ':'">
          </tidy-text>
        </tidy-row>
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="notification?.notifications?.app?.content?.title">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="notification?.notifications?.app?.content?.message">
            </tidy-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

    </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

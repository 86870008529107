import { Injectable } from "@angular/core";
import { HttpClientCustom } from "../custom/http-client";

@Injectable()
export class Tips {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  addTip(jobId, hkJobId, amount) {
    const url = `api/v1/customer/cleanings/${jobId}/homekeeper_jobs/${hkJobId}/tips`;
    return this.httpClient.put(url, {amount});
  }

  getCleaningTips(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/tips`;
    return this.httpClient.get(url);
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="title"
    [canGoBack]="!cantGoBack">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">

    <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <ng-container *ngIf="!cancellingAccount">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'There are several ways to cancel to accomodate your needs:'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-row [align]="'center'">
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Cancel All Jobs'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Use this if you want to make sure you have nothing future scheduled.'">
              </tidy-text>
            </tidy-row>
          </tidy-row>

          <tidy-row>
            <tidy-button
              [text]="'Cancel All Jobs'"
              [action]="confirmcancelAllJobs.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </tidy-card>

        <tidy-card *ngIf="isAccountOwner">
          <tidy-row [align]="'center'">
            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [header]="'Cancel Account & Delete Data'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Use this if you are looking to delete as much information as possible.'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'We do not recommend doing this if you need any job or billing history in the future or are looking for a refund, as that information can be relevant to you.'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'Please resolve any job or billing issue first before deleting your data.'">
              </tidy-text>
            </tidy-row>

            <tidy-row class="extra-bottom-padding">
              <tidy-text
                [body]="'You cannot do this if you have a pending charge or refund that has not fully processed. If you need billing help, remember to contact the TIDY Concierge.'">
              </tidy-text>
            </tidy-row>
          </tidy-row>

          <tidy-row>
            <tidy-button
              [text]="'Cancel Jobs & Delete Data'"
              [action]="cancelAccount.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngIf="cancellingAccount">
        <tidy-card>
          <tidy-text
            [body]="'As your account data is important to resolve any job or billing related issues, please confirm the following:'">
          </tidy-text>
          <tidy-checkbox
            formControlName="firstCheck"
            [form]="form.controls.firstCheck"
            [items]="[{viewValue: 'I have no outstanding job related issues.', value: true}]">
          </tidy-checkbox>
          <tidy-checkbox
            formControlName="secondCheck"
            [form]="form.controls.secondCheck"
            [items]="[{viewValue: 'I have no outstanding billing related issues.', value: true}]">
          </tidy-checkbox>
          <tidy-checkbox
            formControlName="thirdCheck"
            [form]="form.controls.thirdCheck"
            [items]="[{viewValue: 'I understand that deleting my account cannot be un-done.', value: true}]">
          </tidy-checkbox>
        </tidy-card>

        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-two-column-row>
          <tidy-button
            [text]="'Go Back'"
            [action]="stopCancellingAccount.bind(this)"
            class="secondary">
          </tidy-button>

          <tidy-button
            [text]="'Cancel Account'"
            [action]="confirmAccountCancellation.bind(this)"
            class="primary">
          </tidy-button>
        </tidy-two-column-row>
      </ng-container>

    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

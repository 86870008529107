import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { WindowService } from './../../shared/providers/window.service';

import { Addresses } from 'src/providers/customer/addresses';
import { Card } from 'src/providers/card/card';
import { Client } from 'src/providers/client/client';
import { Me } from 'src/providers/me/me';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Events } from 'src/providers/events/events';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';

import { AddressModel } from 'src/models/address.model';
import { ClientModel, ClientSettingsModel } from 'src/models/client.model';
import { ScheduleAssuranceModel } from 'src/models/schedule-assurance.model'
import { CurrentAddress } from 'src/providers/addresses/current-address';

@Component({
  templateUrl: 'more.html',
  encapsulation: ViewEncapsulation.None
})

export class MorePage implements OnInit {

  accountType: string;
  addressResponse: AddressModel[];
  addressResponseFiltered: AddressModel[];
  card: any;
  clientInfo: ClientModel;
  hasThreePlusAddresses: boolean;
  hasBackupTimes: boolean;
  hasPrivatePro: boolean;
  isRentalClient: boolean;
  isAdvancedMode: boolean;
  language: string;
  settings: ClientSettingsModel;
  scheduleAssurance: ScheduleAssuranceModel;
  notificationSettings: any;
  userRole: string;
  webhooks: any;
  loaded: boolean;

  constructor(
    private addresses: Addresses,
    private cardService: Card,
    private client: Client,
    private events: Events,
    private me: Me,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private util: Util,
    private currentAddress: CurrentAddress,
    private webhooksService: Webhooks,
    private windowService: WindowService
  ) {
    this.card = null;
  }

  async ngOnInit() {
    try {
      if (this.windowService.isDesktopRes) {
        this.navCtrl.navigateForward('more-desktop');
        return;
      }
      this.loaded = false;
      this.language = this.getLanguage();
      const shouldReloadLocation = localStorage.getItem('shouldReloadLocation') == 'true';
      if (shouldReloadLocation) {
        localStorage.setItem('shouldReloadLocation', 'false');
        location.reload();
      }
      this.addressResponse = await this.client.getAddresses();
      if (this.addressResponse.length > 2) {
        this.hasThreePlusAddresses = true;
      }
      this.userRole = localStorage.getItem('user_role');
      this.addressResponseFiltered = this.addressResponse.filter((address, i) => i < 2 );
      this.clientInfo = await this.client.getClientInfo();
      this.settings = await this.client.getClientSettings();
      if (this.userRole !== 'member') {
        const withUrl = false;
        this.webhooks = await this.webhooksService.getWebhooks();
        this.notificationSettings = await this.webhooksService.buildNotificationSettingsArray(this.webhooks, withUrl);
        const cards = await this.cardService.getCreditCards();
        cards.map((card) => {
          if (card.is_primary) {
            this.card = card;
          }
        });
        this.scheduleAssurance = await this.client.getScheduleAssurance();
        this.checkForBackupTimes();
      }
      this.hasPrivatePro = localStorage.getItem('hasPrivatePro') == 'true';
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      this.isAdvancedMode = localStorage.getItem('isAdvancedMode') == 'true';
      this.accountType = this.getAccountType();
      this.loaded = true;
    } catch (err) {
    }
  }

  getLanguage() {
    const language = localStorage.getItem('language') || 'en';
    const languages = {
      'en': 'English',
      'pt': 'Portuguese',
      'es': 'Spanish',
      'ru': 'Russian',
      'fr': 'French',
      'de': 'Dutch'
    }
    return languages[language];
  }

  getAccountType() {
    const types = {
      regular: 'Residential',
      realtor: 'Realtor',
      rental: 'Rental',
      company: 'Office',
    };
    return types[this.clientInfo.customer_account.account_type];
  }

  goToVendorCompliance() {
    const params = {
      successRoute: 'more'
    }
    this.navCtrl.navigateForward('vendor-compliance', params);
  }

  goToAddresses() {
    this.navCtrl.navigateForward('properties');
  }

  goToUsersPage() {
    this.navCtrl.navigateForward('users');
  }

  goToTeamsPage() {
    this.navCtrl.navigateForward('teams');
  }

  goToBranding() {
    this.navCtrl.navigateForward('app-branding');
  }

  goToAddAddress() {
    this.navCtrl.navigateForward('add-property');
  }

  goToPaymentSettings() {
    const params = {
      setting: this.util.deepClone(this.settings)
    };
    this.navCtrl.navigateForward('payment-settings', params);
  }

  goToBuyBundle() {
    this.navCtrl.navigateForward('buy-bundle');
  }

  goToUseGiftCard() {
    this.navCtrl.navigateForward('use-gift-card');
  }

  goToViewPrices() {
    this.navCtrl.navigateForward('my-prices');
  }

  goToEditCard() {
    this.navCtrl.navigateForward('payment-methods');
  }

  goToPastJobs() {
    this.navCtrl.navigateForward('past-jobs');
  }

  goToBillingHistory() {
    localStorage.setItem('billingHistoryBackPage', 'more');
    this.navCtrl.navigateForward('billing-history');
  }

  goToApiDocumentation() {
    this.util.openUrl('https://tidy.stoplight.io/docs/tidy-api-spec/docs/1.%20Introduction.md');
  }

  goToApiKeys() {
    this.navCtrl.navigateForward('api-keys');
  }

  goToWebhooks() {
    this.navCtrl.navigateForward('webhooks');
  }

  cancellationOptions() {
    this.navCtrl.navigateForward('cancellation-options');
  }

  goToDevelopers() {
    this.navCtrl.navigateForward('more/developers');
  }

  goToAccountNotificationsPage() {
    const params = {
      webhooks: this.webhooks,
      backPage: 'more'
    }
    this.navCtrl.navigateForward('account-notifications', params);
  }

  logout() {
    this.events.publish('logout');
    localStorage.clear();
  }

  goToEditAddress(address) {
    this.currentAddress.addressId = address.id;
    let params = {};
    const addressHasMoreDetail = address.home_access;
    if (addressHasMoreDetail) {
      params['address'] = address;
    }
    this.navCtrl.navigateForward(`edit-property/${address.id}`, params);
  }

  goToEditAccountType() {
    const params = {
      settings: this.settings
    };
    this.navCtrl.navigateForward('account-type', params);
  }

  goToEditContactInfo() {
    const params = {
      settings: this.settings
    };
    this.navCtrl.navigateForward('contact-info', params);
  }

  goToEditBackupTimes() {
    this.navCtrl.navigateForward('backup-times');
  }

  goToBillsPage() {
    this.navCtrl.navigateForward('bills');
  }

  goToIntegrations() {
    localStorage.setItem('integrationsBackPage', 'more')
    this.navCtrl.navigateForward('integrations/all');
  }

  goToAccounting() {
    localStorage.setItem('integrationsBackPage', 'more')
    this.navCtrl.navigateForward('integrations/all');
  }

  async goToAutomaticBooking() {
    localStorage.setItem('automaticBookingBackPage', 'more');
    this.navCtrl.navigateForward('automatic-booking');
  }

  goToConcierge() {
    this.navCtrl.navigateForward('concierge');
  }

  goToMyPros() {
    this.navCtrl.navigateForward('job-request-workflows');
  }

  goToMessages() {
    this.navCtrl.navigateForward('messages');
  }

  goToPaymentMethods() {
    this.navCtrl.navigateForward('payment-methods');
  }

  goToLoadCredit() {
    this.navCtrl.navigateForward('payment-methods/load-credit');
  }

  goToLoggedIssuesPage() {
    localStorage.setItem('loggedIssuesBackPage', 'more');
    this.navCtrl.navigateForward('tasks');
  }

  goToInventoryPage() {
    this.navCtrl.navigateForward('inventory');
  }

  goToMaintenancePage() {
    this.navCtrl.navigateForward('maintenance');
  }

  goToSubscriptions() {
    const params = {
      addressResponse: this.addressResponse,
      clientInfo: this.clientInfo
    }
    this.navCtrl.navigateForward('subscription', params);
  }

  goToDocumentation() {
    const url = 'https://help.tidy.com/';
    this.util.openUrl(url);
  }

  checkForBackupTimes() {
    this.scheduleAssurance.customerAddress.times.forEach((time) => {
      if (time.start_time !== null) {
        return this.hasBackupTimes = true;
      }
    })
  }

  goToReports() {
    this.navCtrl.navigateForward('reports');
  }

  goToGuestReservations() {
    this.navCtrl.navigateForward('more/reservations');
  }

  goToEditLanguage() {
    this.navCtrl.navigateForward('edit-language');
  }
}

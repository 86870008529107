<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="isMobileResolution"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="false">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container
      *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <div class="desktop-content-padding">
        <form [formGroup]="messageForm">
            <tidy-row *ngIf="showSendToField">
              <tidy-select
                class="select-custom-input"
                [label]="'Send to'"
                [items]="proList"
                (optionChange)="onSelectPro($event)"
                [form]="messageForm.get('pro')"
                formControlName="pro">
              </tidy-select>
            </tidy-row>
            <ng-container *ngIf="showAssign">
              <tidy-row *ngIf="!showAssignField && messageForm.get('assignedTo')?.value">
                <tidy-text 
                  *ngIf="assignLabel == 'Assign number to pro'"
                  [body]="'<i>Number assigned to pro:</i>'">
                </tidy-text>
                <tidy-text 
                  *ngIf="assignLabel !== 'Assign number to pro'"
                  [body]="'<i>Number assigned to property:</i>'">
                </tidy-text>
                <tidy-text
                  [body]="'<i>' + getAssignedToViewValue(messageForm.get('assignedTo')?.value) + '</i>'" [translate]="false">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'Edit'"
                  (action)="toggleShowAssignField()"
                  class="link">
                </tidy-text>
              </tidy-row>
              <tidy-row *ngIf="!showAssignField && !messageForm.get('assignedTo')?.value">
                <tidy-text
                  *ngIf="assignLabel == 'Assign number to property'"
                  [body]="'<i>No property assigned to this number<i>'">
                </tidy-text>
                <tidy-text 
                  *ngIf="assignLabel == 'Assign number to pro'"
                  [body]="'<i>No property assigned to this number<i>'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text 
                  [body]="'Assign Number'"
                  (action)="toggleShowAssignField()"
                  class="link">
                </tidy-text>
              </tidy-row>
              <ng-container *ngIf="showAssignField">
                <tidy-row>
                  <tidy-select
                    class="select-custom-input"
                    [label]="assignLabel"
                    [items]="assignList"
                    [form]="messageForm.get('assignedTo')"
                    formControlName="assignedTo">
                  </tidy-select>
                </tidy-row>
                <tidy-row>
                  <tidy-button
                    [text]="'Assign Number to ' + (assignLabel == 'Assign number to pro' ? 'Pro' : 'Property')"
                    [action]="assignNumber.bind(this)"
                    class="primary">
                  </tidy-button>
                </tidy-row>
              </ng-container>
            </ng-container>

          <ng-container *ngIf="isProSelected">
            <tidy-card>
              <tidy-row
                [alignLastItemRight]="true">
                <tidy-image [src]="'assets/img/account.svg'" class="title-size">
                </tidy-image>
                <tidy-text [header]="pro?.first_name" [translate]="false">
                </tidy-text>
                <tidy-text [body]="'Call'" class="link" (action)="callPro(pro)">
                </tidy-text>
              </tidy-row>
              <tidy-textarea
                [label]="'Send Message'"
                formControlName="message"
                [form]="messageForm.controls.message"
                [submitted]="submitted"
                [errorMessage]="'Please enter a message to send.'"
                [rightIconAction]="sendMessage.bind(this)"
                [leftIconAction]="sendAttachment.bind(this)">
              </tidy-textarea>
              <tidy-row></tidy-row>
              <ion-row *ngIf="imagePreview !== ''">
                <div
                  style="width: 100%"
                  class="image-preview"
                  [class.mobile-image-preview]="isMobileResolution">
                  <img [src]="imagePreview" />
                  <div class="send-message-button">
                    <button
                      matSuffix
                      mat-icon-button
                      mat-mini-fab
                      (click)="sendMessage()">
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>
                  <div class="remove-image-button">
                    <button
                      matSuffix
                      mat-icon-button
                      mat-mini-fab
                      (click)="removeImage()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </ion-row>
              <ng-container *ngIf="!isMessagesLoaded">
                <div>
                  <tidy-row
                    class="vertical-align-center"
                    style="padding-bottom: 0px; margin-bottom: -2px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-icon"></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-subject"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row style="padding-bottom: 0px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-body"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row
                    class="vertical-align-center"
                    style="padding-bottom: 0px; margin-bottom: -2px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-icon"></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-subject"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row style="padding-bottom: 0px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-body"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row
                    class="vertical-align-center"
                    style="padding-bottom: 0px; margin-bottom: -2px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-icon"></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-subject"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row style="padding-bottom: 0px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-body"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row
                    class="vertical-align-center"
                    style="padding-bottom: 0px; margin-bottom: -2px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-icon"></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-subject"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row style="padding-bottom: 0px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-body"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row
                    class="vertical-align-center"
                    style="padding-bottom: 0px; margin-bottom: -2px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-icon"></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-subject"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row style="padding-bottom: 0px">
                    <ion-skeleton-text
                      animated
                      class="message-skeleton-body"></ion-skeleton-text>
                  </tidy-row>
                </div>
              </ng-container>
              <ng-container *ngIf="isMessagesLoaded">
                <tidy-row *ngIf="messages?.length == 0" [align]="'center'" class="no-padding">
                  <tidy-text [helper]="'No Messages'"> </tidy-text>
                </tidy-row>
                <ng-container *ngFor="let message of messages; let last = last">
                  <message class="new-messages" [message]="message" [last]="last"> </message>
                </ng-container>
              </ng-container>
            </tidy-card>
          </ng-container>
        </form>
    </div>
  </tidy-wrapper>
</ng-template>

<ion-content *ngIf="isMobileResolution">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-container *ngIf="!isMobileResolution">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

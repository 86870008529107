<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Teams'"
    [canGoBack]="!windowService.isDesktopRes"
    [customBack]="'more'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Organize your users and properties into teams to control access, notifications, billing, and reporting.'">
        </tidy-text>
      </tidy-row>

      <tidy-button
        [action]="goToAddTeamPage.bind(this)"
        [text]="'Add Team'"
        class="primary">
      </tidy-button>

      <tidy-team-tree
        *ngIf="teamsList?.length"
        [teamsList]="teamsList">
      </tidy-team-tree>

      <tidy-alert *ngIf="!teamsList?.length">
        <tidy-row>
          <tidy-text
            [body]="'No teams added'">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row [align]="'center'">
        <tidy-text
          [body]="'Need help setting up teams?'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          class="link"
          [body]="'Learn More'"
          (action)="goToTeamsHelpUrl()">
        </tidy-text>
      </tidy-row>

    </ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>


<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

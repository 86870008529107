import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { EmployerService } from 'src/providers/employer/employer';

import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';

import { EmployerEditEmployeePage } from 'src/pages/employer/edit-employee/edit-employee';
import { EmployerAddEmployeePage } from 'src/pages/employer/add-employee/add-employee';
import { EmployerAddCreditPage } from 'src/pages/employer/add-credit/add-credit';

@Component({
  templateUrl: 'dashboard.html',
  styleUrls: ['./dashboard.scss']
})

export class EmployerDashboardPage implements OnInit {

  companyCredit: number;
  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  employees: any;
  employeesResponse: any;
  employeeCreditBalance: any;
  form: UntypedFormGroup;
  rows: any;
  headers: any;
  showAll: boolean = false;
  loaded: boolean;
  panelClosedSubscription: Subscription;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private employerService: EmployerService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private tidyCurrencyPipe: TidyCurrencyPipe
  ) {
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  async ngOnInit() {
    this.getData();
  }

  async getData() {
    this.form.patchValue({search: ''});
    this.employeesResponse = await this.employerService.listEmployees();
    this.employeeCreditBalance = await this.employerService.getEmployeeCreditBalance();
    this.employees = this.employeesResponse;
    console.log(this.employees)
    this.rows = this.buildRows(this.employees);
    this.headers = this.buildHeaders();
    const billingData = await this.client.getBillingData();
    this.companyCredit = billingData.balance;
    this.loaded = true;
  }

  hideShowAll() {
    this.showAll = !this.showAll;
    this.rows = this.buildRows(this.employees);
  }

  buildHeaders() {
    return [
      'Employee',
      'Email',
      'Credit Balance',
      'Credit Used',
      'Status',
      ' '
    ];
  }

  buildRows(employees, sort = null, sortDirectionAsc = true) {

    if (!employees?.length) {
      return [{
        'Employee': {},
        'Email': {},
        'Credit Balance': {},
        'Credit Used': {},
        'Status': {},
        ' ': {}
      }]
    }

    const rows = employees.filter(employee => {
      return this.showAll ? true : !employee.inactivated_at;
    })
      .map(employee => {
      return {
        'Employee': {
          value: `${employee.source_user_first_name} ${employee.source_user_last_name}`,
          action: null
        },
        'Email': {
          value: employee.source_user_email,
          action: null
        },
        'Credit Balance': {
          value: this.getCreditBalance(employee.employee_customer_id),
          action: null
        },
        'Credit Used': {
          value: this.getCreditUsed(employee.employee_customer_id),
          action: null
        },
        'Status': {
          value: employee.inactivated_at ? 'Inactive' : 'Active',
          action: null
        },
        ' ': {
          value: 'Edit',
          action: this.editEmployee.bind(this, employee, this.getEmployeeBalance(employee.employee_customer_id))
        }
      };
    });

    if (sort) {
      const directions = sortDirectionAsc ? [-1, 1] : [1, -1];
      return rows.sort((itemA, itemB) => {
        return itemA[sort].value < itemB[sort].value ? directions[0] : directions [1];
      });
    }

    return rows;
  }

  getCreditBalance(clientId) {
    const balance = this.employeeCreditBalance?.find(client => clientId == client.customer.id);
    return this.tidyCurrencyPipe.transform(balance?.lifetime_amount_sent - balance?.lifetime_amount_used - balance?.lifetime_amount_cancelled);
  }

  getCreditUsed(clientId) {
    const balance = this.employeeCreditBalance?.find(client => clientId == client.customer.id);
    return this.tidyCurrencyPipe.transform(balance?.lifetime_amount_used);
  }

  getEmployeeBalance(clientId) {
    const balance = this.employeeCreditBalance?.find(client => clientId == client.customer.id);
    return balance;
  }

  editEmployee(employee, employeeBalance) {
    console.log(employee)
    const companyCredit = this.companyCredit;
    this.goToPage(EmployerEditEmployeePage, { employee, employeeBalance, companyCredit });
  }

  addEmployee() {
    this.goToPage(EmployerAddEmployeePage);
  }

  goToAddCredit() {
    this.goToPage(EmployerAddCreditPage);
  }

  goToPage(component, params = null) {
    this.storage.save('dialog-right-side-open', true);
    this.storage.save('dialog-params', params);
    this.rightSidePanelService.openRightSidePanel(component);
    this.panelClosedSubscription?.unsubscribe();
    this.panelClosedSubscription = this.rightSidePanelService.panelClosed().subscribe(async () => {
      this.getData();
    });
  }

  sortBy(field) {
    const directionAsc = field.direction === 'asc';
    this.rows = this.buildRows(this.employees, field.active, directionAsc);
  }

  updateSearch({search}) {
    if (search.length < 2) {
      this.employees = this.employeesResponse;
      return this.rows = this.buildRows(this.employees);
    }
    const term = search.toLowerCase();
    this.employees = this.employeesResponse.filter((option) => {
      const name = option.source_user_first_name + ' ' + option.source_user_last_name;
      if (name.toLowerCase().indexOf(term) > -1 || option.source_user_email.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
    this.rows = this.buildRows(this.employees);
  }

}

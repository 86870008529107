import { Logger } from 'src/providers/logger';
import { Util } from 'src/shared/util/util';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Addresses } from 'src/providers/customer/addresses';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { Genome } from 'src/providers/genome/genome';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'edit-address-detail.html',
  styleUrls: ['edit-address-detail.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class EditAddressDetailPage implements OnInit {

  address: any;
  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  submitted: boolean;
  addressDetail: any;
  buildTypeItems: any;
  roofTypeItems: any;
  isReordering = false;
  isRightSideContent = true;
  dialogParams: any;
  columns: any[];

  constructor(
    private addresses: Addresses,
    private client: Client,
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private navCtrl: CustomNavController,
    private genome: Genome,
    private util: Util,
    private logger: Logger,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {
    this.form = this.fb.group({
      addressNickname: [''],
      buildType: [''],
      roofType: [''],
      squareFeet: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Property');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.address = this.navCtrl.getParam('address') || this.dialogParams.address;
    const addressResponse = await this.client.getMoreDetailAddresses(true);
    this.address = addressResponse.find((address) => address.id == this.address.id);
    this.addressDetail = this.navCtrl.getParam('addressDetail') || this.dialogParams.addressDetail;
    this.columns = this.navCtrl.getParam('columns') || this.dialogParams.columns;
    this.buildTypeItems = this.getFormItems('build_type');
    this.roofTypeItems = this.getFormItems('roof_type');
    this.patchForm();
    this.loaded = true;
  }

  getFormItems(section: string): any[] {
    const objectType = this.addressDetail.find(address => address.key == section);
    const array: any = [];
    objectType.value_options.map((option) => {
      array.push({
        viewValue: option.label,
        value: option.key
      })
    });
    return array;
  }

  patchForm(): void {
    const buildType = this.addressDetail.find(address => address.key == 'build_type');
    const squareFeet = this.addressDetail.find(address => address.key == 'square_feet');
    const roofType = this.addressDetail.find(address => address.key == 'roof_type');
    this.form.patchValue({
      buildType: buildType.value,
      roofType: roofType.value,
      squareFeet: squareFeet.value,
      addressNickname: this.address.address_name
    })
  }

  async save(): Promise<void> {
    const loading = await this.util.showLoading();
    this.submitted = true;
    if (!this.form.valid) {
      loading.dismiss();
      return;
    }
    const data = {
      address_name: this.form.value.addressNickname
    }
    try {
      await Promise.all([
        this.updateAddressDetails(),
        this.addresses.updateAddress(data, this.address.id),
        this.client.getAddresses(true)
      ]);
      this.util.showSuccess('Property updated successfully');
      this.navCtrl.navigateForward(`edit-property/${this.address.id}`);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    } finally {
      loading.dismiss();
    }
  }

  async updateAddressDetails(): Promise<void> {
    try {
      const payload = {
        address_id: this.address.id,
        address_details: [
          {
            key: 'build_type',
            value: this.form.value.buildType
          },
          {
            key: 'roof_type',
            value: this.form.value.roofType
          },
          {
            key: 'square_feet',
            value: this.form.value.squareFeet
          }
        ]
      }
      await this.genome.saveAddressDetails(payload);
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async deleteAddress() {
    if (this.address.plans?.length) {
      const message = 'This property has scheduled jobs. Please cancel the jobs for this property before deleting this property.';
      return this.util.showError(message, 10000);
    }
    const confirmParams: ConfirmPageParamsModel = {
      title: 'Delete Property?',
      body: 'Are you sure you want to delete this property?',
      backText: 'Go Back',
      confirmText: 'Delete Property',
      confirmAction: this.confirmDeleteAddress.bind(this)
    };
    const confirmationModal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  async confirmDeleteAddress() {
    try {
      await this.addresses.deleteAddress(this.address.id);
      const addresses = await this.client.getAddresses(true);
      localStorage.removeItem('addressId');
      if (addresses.length === 0) {
        const params = {
          hasNoAddresses: true
        }
        this.navCtrl.navigateForward('add-property', params);
      } else {
        this.navCtrl.navigateForward('properties');
      }
      this.modalController.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.saveOrder();
  }

  async saveOrder(): Promise<void> {
    try {
      const stringifiedOrder = JSON.stringify(this.columns);
      await this.client.addMetadata({ key: `edit_address_${this.address.id}_order`, value: stringifiedOrder });
    } catch (err) {
      this.handleLoadErrors(err);
    }
  }

  handleLoadErrors(error: any): void {
    console.error(error);
    this.logger.error(error);
    const route = window.location.pathname;
    if (!route.includes('edit-property')) {
      return;
    }
    const errorMessage = (error.error && error.error.message) ? error.error.message : error.message;
    this.util.showError(errorMessage, 10000);
  }
}

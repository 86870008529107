import { Injectable } from '@angular/core';
import { HttpClientCustom } from '../custom/http-client';

@Injectable()
export class Integrations {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  addIntegration(payload) {
    const url = 'api/v1/customer/customer-integrations';
    return this.httpClient.post(url, payload);
  }

  getIntegrations() {
    const url = 'api/v1/customer/customer-integrations';
    return this.httpClient.get(url);
  }

  associateRemoteAccessAddresses(payload) {
    const url = 'api/v1/customer/remote-access-addresses/update-all';
    return this.httpClient.put(url, payload);
  }

  associateRemoteAccessAddress(payload, remoteAccessAddressId) {
    const url = `api/v1/customer/remote-access-addresses/${remoteAccessAddressId}`;
    return this.httpClient.put(url, payload);
  }

  getRemoteAccessAddresses() {
    const url = 'api/v1/customer/remote-access-addresses';
    return this.httpClient.get(url);
  }

  getRemoteAccessAddress(remoteAccessAddressId) {
    const url = `api/v1/customer/remote-access-addresses/${remoteAccessAddressId}`;
    return this.httpClient.get(url);
  }

  deactivateRemoteAccessAddress(remoteAccessAddressId) {
    const url = `api/v1/customer/remote-access-addresses/${remoteAccessAddressId}/deactivate`;
    return this.httpClient.put(url, {});
  }

  getRemoteAccessDevices() {
    const url = 'api/v1/customer/remote-access-devices';
    return this.httpClient.get(url);
  }

  updateRemoteAccessDevices(payload, remoteAccessDeviceId) {
    const url = `api/v1/customer/remote-access-devices/${remoteAccessDeviceId}`;
    return this.httpClient.put(url, payload);
  }

  deactivateRemoteAccessDevices(remoteAccessDeviceId) {
    const url = `api/v1/customer/remote-access-devices/${remoteAccessDeviceId}/deactivate`;
    return this.httpClient.put(url, {});
  }

  deactivateIntegration(integrationId) {
    const url = `api/v1/customer/customer-integrations/${integrationId}/deactivate`;
    return this.httpClient.put(url, {});
  }

  getReservationSourceMappingsVariables(guestReservationSourceTypeId) {
    const url = `api/v1/customer/guest-reservation-source-mappings/variables?model_type=GuestReservation&guest_reservation_source_id=${guestReservationSourceTypeId}`;
    return this.httpClient.get(url);
  }

  getReservationSourceMappings(guestReservationSourceTypeId) {
    const url = `api/v1/customer/guest-reservation-source-mappings?model_type=GuestReservation&guest_reservation_source_id=${guestReservationSourceTypeId}`;
    return this.httpClient.get(url);
  }

  getCustomFields() {
    const url = `api/v1/customer/custom-fields?model_type=GuestReservation`;
    return this.httpClient.get(url);
  }

  getReservationSourceMappingsOverridableFields(guestReservationSourceTypeId) {
    const url = `api/v1/customer/guest-reservation-source-mappings/overridable-fields?model_type=GuestReservation&guest_reservation_source_id=${guestReservationSourceTypeId}`;
    return this.httpClient.get(url);
  }

  saveNewReservationSourceMapping(payload) {
    const url = `api/v1/customer/guest-reservation-source-mappings`;
    return this.httpClient.post(url, payload);
  }

  updateIntegrationFields(payload) {
    const url = `api/v1/customer/guest-reservation-source-mappings`;
    return this.httpClient.put(url, payload);
  }

  addCustomField(payload) {
    const url = `api/v1/customer/custom-fields`;
    return this.httpClient.post(url, payload);
  }

  updateCustomField(customFieldId, payload) {
    const url = `api/v1/customer/custom-fields/${customFieldId}`;
    return this.httpClient.put(url, payload);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class SessionSteps {

  bookingComponents: Array<{}>;
  introPages: Array<{}> = ['SignUpPage', 'IntroductionPage', 'LoginPage'];

  constructor () {}

  storeBookingStep(componentName): void {
    localStorage.setItem('lastStep', componentName);
  }

  isBooking(): boolean {
    return (localStorage.getItem('booking') === 'customer');
  }

  isNewRelease(componentName): boolean {
    const cmRelease = localStorage.getItem('cm_release');

    return this.introPages.indexOf(componentName) === -1
              && cmRelease !== 'newSb'
  }

  getLastStep() {
    return localStorage.getItem('lastStep');
  }

  isActiveUserWithoutBooking(): boolean {
    const tidyToken = localStorage.getItem('tidy_token');
    let isActive = false;
    if (tidyToken && !this.isBooking()) {
      isActive = true;
    }
    return isActive;
  }

  isAllowedPage(page): boolean {
    return page !== 'SignUpPage';
  }

  clearBookingStep(): void {
    localStorage.removeItem('myHouseCreated');
    localStorage.removeItem('lastStep');
    localStorage.removeItem('booking');
  }

  setBookingProcess(): void {
    localStorage.removeItem('no_plan');
    localStorage.setItem('booking', 'customer');
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { Loading } from 'src/shared/components/loading/loading';
import { SuccessPage } from 'src/shared/pages/success/success';
import { Util } from 'src/shared/util/util';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { WindowService } from "src/shared/providers/window.service";

import { Client } from 'src/providers/client/client';
import { Tips } from 'src/providers/tips/tips';
import { Logger } from 'src/providers/logger';
import { CleaningCards } from 'src/providers/cleaning-cards/cleaning-cards';
import { FeedbackCleaning } from 'src/providers/feedback/feedback';
import { Auth } from 'src/providers/auth/auth';

import * as Bowser from 'bowser';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

@Component({
  templateUrl: 'love-it.html',
  encapsulation: ViewEncapsulation.None
})

export class LoveItPage extends TimeoutableComponent implements OnInit {

  cleaningData: any;
  customTip: boolean = false;
  errorMessage: string;
  form: UntypedFormGroup;
  feedbackData: any;
  hkJobId: any;
  jobId: any;
  os: string;
  platformName: string;
  reviewGetData: any;
  reviewPostData: any;
  submitted = false;
  tipOptions: ({ viewValue: string; value: number; } | { viewValue: string; value: string; })[];
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private cleaningCards: CleaningCards,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private feedbackCleaning: FeedbackCleaning,
    private auth: Auth,
    private logger: Logger,
    public tips: Tips,
    private modalCtrl: ModalController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    super();
    this.form = this.fb.group({
      feedback: [''],
      replaceHk: [false, Validators.required],
      tip: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Loved It');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    this.jobId = this.route.snapshot.paramMap.get('jobId') || this.dialogParams.jobId || this.dialogParams.cleaningId;
    this.hkJobId = this.route.snapshot.paramMap.get('hkJobId') || this.dialogParams.hkJobId;
    const authToken = this.route.snapshot.paramMap.get('authToken');
    const addressId = this.route.snapshot.paramMap.get('addressId');
    try {
      if (authToken && addressId) {
        const authData = {authToken: authToken, addressId: addressId}
        await this.auth.autoLogin(authData);
        await this.preSaveFeedbackType();
        await this.getData();
      } else {
        await this.getData();
      }
      this.setTipOptions();
      this.startFeedback();
      this.form.patchValue({ tip: '0' });
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
      this.logger.error(err, 'feedback-init');
      this.errorMessage = err.message;
    }
  }

  async startFeedback() {
    try {
      await this.feedbackCleaning.startFeedback(this.jobId, this.hkJobId, 'positive');
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  async getData() {
    this.cleaningData = await this.cleaningCards.getCleaning(this.jobId);
    this.feedbackData = await this.feedbackCleaning.getFeedback({jobId: this.jobId, hkJobId: this.hkJobId});
    this.getPlatformData();
  }

  @Loading('', true)
  async submitFeedback() {
    if (!this.form.valid) {
      this.submitted = true;
      return;
    }
    try {
      const data = this.mountFeedbackData(this.form.value);
      await this.feedbackCleaning.submitMorePositiveFeedback(data);
      await this.sendTip();
      this.reviewGetData = await this.feedbackCleaning.getAppReviewData(this.os);
      if (this.reviewGetData?.ask_for_review) {
        this.reviewPostData = await this.feedbackCleaning.recordSawAppReviewLink(this.reviewGetData.review_page.id, 'client_app_loved_it', new Date())
      }
      await this.client.getMoreDetailAddresses(true);
      this.successfulFeedback();
    } catch (err) {
      console.error(err);
      this.errorMessage = err.message;
    }
  }

  async successfulFeedback() {
    let params: any = {};
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    if (this.reviewGetData?.ask_for_review) {
      const alert = await this.util.showAlert(
        'Post a Review',
        'Would you like to post a review on ' + this.platformName + '?',
        false,
        [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              this.util.showSuccess('Thanks For Your Feedback!');
              if (this.windowService.isDesktopRes) {
                this.rightSidePanelService.closeRightSidePanel();
              } else {
                this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
              }
            }
          },
          {
            text: 'Post',
            handler: () => {
              this.openAppReviewPage();
            }
          }
        ]
      );
      await alert.present();
    } else {
      params = {
        header: 'Thanks For Your Feedback!',
        body: 'We\'re glad you loved your cleaning!',
        buttonText: 'Go to My Cleanings',
        buttonRoute: shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list',
      };
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    }
  }

  async openAppReviewPage() {
    await this.feedbackCleaning.recordClickedAppReviewLink(this.reviewPostData.id, new Date());
    const url = this.reviewGetData.review_page.url;
    this.util.openUrl(url);
    this.modalCtrl.dismiss();
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
    }
  }

  async preSaveFeedbackType() {
    const data = this.mountFeedbackData({});
    await this.feedbackCleaning.sendFeedback(data);
  }

  mountFeedbackData(formResults) {
    const staticData = {jobId: this.jobId, hkJobId: this.hkJobId, typeFeedback: 'positive'};
    return this.getFormData(staticData, formResults);
  }

  getFormData(mountedData, formData) {
    const { jobId, hkJobId, typeFeedback, todos } = mountedData
    if (typeFeedback === 'positive') {
      formData.bkHk = false;
    }
    return {
      type: typeFeedback,
      job_id: jobId,
      homekeeper_job_id: hkJobId,
      to_dos: (todos) ? todos : [],
      feedback: { type: typeFeedback, text: formData.feedback },
      blacklist_homekeeper: false,
      replace_preferred_homekeeper: formData.replaceHk
    }
  }

  setTipOptions(){
    this.tipOptions = [
      {viewValue: 'No Tip', value: '0'},
      {viewValue: '$5', value: '500'},
      {viewValue: '$10', value: '1000'},
      {viewValue: '$15', value: '1500'},
      {viewValue: '$20', value: '2000'},
      {viewValue: 'Custom Tip', value: "customTip"}]
  }

  customTipping(tip){
    this.customTip = tip.value === "customTip";
  }

  async sendTip() {
    const amount = parseInt(this.util.removeCurrencyMask(this.form.value.tip), 10);
    if (amount) {
      return await this.tips.addTip(this.jobId, this.hkJobId, amount);
    }
  }

  getPlatformData() {
    this.os = 'web';
    this.platformName = 'Google Places'
    if (Bowser.android) {
      this.platformName = 'Google Play'
      this.os = 'android';
    } else if (Bowser.ios) {
      this.platformName = 'The App Store'
      this.os = 'ios';
    }
  }
}

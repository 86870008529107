import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { DateTime as LuxonDateTime } from 'luxon';

import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Client } from 'src/providers/client/client';
import { Genome } from 'src/providers/genome/genome';
import { Schedule } from 'src/providers/schedule/schedule';
import { ModalController } from '@ionic/angular';
import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { PastPrivateJobPage } from "src/pages/more/past-private-job/past-private-job";
import { PastJobPage } from "src/pages/more/past-job/past-job";
import { JobPage } from "src/pages/schedule/job/job";
import { BookJobPage } from 'src/pages/booking/book-job/book-job';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

@Component({
  selector: 'message',
  templateUrl: 'message.component.html',
  encapsulation: ViewEncapsulation.None
})

export class MessageComponent {

  errorMessage: string;
  @Input() addExtraPadding: boolean = true;
  @Input() last: boolean = false;
  @Input() message: any;
  @Input() showJobLink: boolean = true;
  @Input() isInvoiceNote: boolean;
  @Input() proId: any;

  constructor(
    private client: Client,
    public communication: Communication,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private genome: Genome,
    public route: ActivatedRoute,
    public schedule: Schedule,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {}

  getJobDate(date, timezone) {
    return LuxonDateTime.fromISO(date).setZone(timezone).toFormat('L/d');
  }

  getDateTime(message) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const relativeDate = LuxonDateTime.fromISO(message.sent_at || message.data.created_at).setZone(timezone).toRelative();
    if (relativeDate.includes('day') || relativeDate.includes('month') || relativeDate.includes('year')) {
      return LuxonDateTime.fromISO(message.sent_at || message.data.created_at).setZone(timezone).toFormat('EEE LLL d h:mm a');
    } else {
      return relativeDate;
    }
  }

  @Loading('', true)
  async goToJobPage(jobMetadata: {
    id?: number | null,
    state?: string | null,
    address_id?: number | null,
    is_private?: boolean | null,
    timezone_name?: string | null,
    start_datetime?: string | null,
    [key: string]: any;
  }) {
    try {
      const jobIsScheduled = jobMetadata?.state === 'scheduled';
      const isRightSide = await this.storage.retrieve('dialog-right-side-open');
      let currentPath = this.route.snapshot.url.join('/');
      console.log(currentPath)
      const params = {
        jobId: jobMetadata?.id,
        addressId: jobMetadata?.address_id,
        backPage: currentPath,
        proId: this.proId
      }
      if (isRightSide) {
        this.storage.save('dialog-params', params);
        const isPastJob = jobIsScheduled ? false : true;
        this.navigateToJobPageInsideDialog(isPastJob, jobMetadata?.is_private);
        return;
      }
      localStorage.setItem('pastJobBackPage', currentPath);
      localStorage.setItem('jobBackPage', currentPath);
      let path = `job/${jobMetadata?.address_id}/${jobMetadata?.id}`;
      if (!jobIsScheduled && jobMetadata?.is_private) {
        path = `past-private-job/${jobMetadata?.id}`
      } else if (!jobIsScheduled && !jobMetadata?.is_private) {
        path = `past-job/${jobMetadata?.id}`;
      }
      this.navCtrl.navigateForward(path, params);
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        modal.dismiss();
      }
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  navigateToJobPageInsideDialog(isPastJob: boolean, isPrivateJob: boolean): void {
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastPrivateJobPage);
    } else if (isPastJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastJobPage);
    } else {
      this.rightSidePanelService.replaceComponentInDialog(JobPage);
    }
  }

  internalNoteStyle(isInternalNote) {
    if (isInternalNote) {
      return {
        'background-color': '#F0F0F0',
        'padding-top' : '5px',
        'padding-bottom' : '5px'
      };
    };
  }

  errorSendingStyle(isError) {
    if (isError) {
      return {
        'background-color': 'rgba(255, 108, 120, 0.1)',
        'padding-top' : '5px',
        'padding-bottom' : '5px'
      };
    };
  }

  async cancelJob() {
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Job?',
      body: 'Are you sure you want to cancel this job?', //Add job details
      backText: 'Go Back',
      confirmText: 'Cancel',
      confirmAction: await this.confirmCancelJob.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelJob() {
    try {
      const payload = {
        user_data: {
          booking_id: this.message?.content?.chat_suggestion?.data?.booking_id
        }
      }
      await this.communication.performAISuggestion(payload, this.message?.content?.chat_suggestion?.id);
      const successMessage = 'Job Cancelled';
      this.util.showSuccess(successMessage);
      this.modalCtrl.dismiss();
      this.message.content.chat_suggestion.status = 'performed';
      this.rightSidePanelService.closeRightSidePanel();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async rescheduleJob() {
    const params: ConfirmPageParamsModel = {
      title: 'Reschedule Job?',
      body: 'Are you sure you want to reschedule this job?', //Add job details
      backText: 'Go Back',
      confirmText: 'Reschedule',
      confirmAction: await this.confirmRescheduleJob.bind(this),
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmRescheduleJob() {
    try {
      const payload = {
        user_data: {
          booking_id: this.message?.content?.chat_suggestion?.data?.booking_id,
          new_date: this.message?.content?.chat_suggestion?.data?.new_date,
          new_start_time: this.message?.content?.chat_suggestion?.data?.new_start_time
        }
      }
      await this.communication.performAISuggestion(payload, this.message?.content?.chat_suggestion?.id);
      const successMessage = 'Job Rescheduled';
      this.util.showSuccess(successMessage);
      this.modalCtrl.dismiss();
      this.message.content.chat_suggestion.status = 'performed';
      this.rightSidePanelService.closeRightSidePanel();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  async goToBookingViewRescheduleJob() {
    const address = await this.genome.getAddress(this.message.metadata.address.id);
    const bookingType = 'reschedule_job'
    const jobId = this.message.metadata.job.id;
    const bookingId = this.message.metadata.booking.id;
    let params = {
      address,
      bookingType,
      jobId,
      bookingId
    }
    params['hkId'] = this.proId;
    params['filterByPro'] = true;
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async dismissSuggestion() {
    await this.communication.dismissAISuggestion(this.message?.content?.chat_suggestion?.id);
    const successMessage = 'Suggestion Hidden';
    this.util.showSuccess(successMessage);
    this.message.content.chat_suggestion.status = '';
  }

  getSuggestionJobTime(time) {
    return LuxonDateTime.fromFormat(time, "HH:mm").toFormat("h:mma").toLowerCase();
  }

  getSuggestionJobDate(date) {
    return LuxonDateTime.fromISO(date).toFormat('M/d/yyyy');
  }

}

import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Util } from 'src/shared/util/util';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPage } from 'src/shared/pages/success/success';

const BREAK_LARGE_RESOLUTION = 1105;

@Component({
  templateUrl: 'change-subscription.html',
  styleUrls: ['./change-subscription.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChangeSubscriptionPage extends TimeoutableComponent implements OnInit {

  cost: any;
  currentSubscription: string;
  currentCostPerUnit: number;
  customSubscription: boolean;
  changedCustomSubscription: boolean;
  numberOfUnits: any;
  form: UntypedFormGroup;
  errorMessage: string;
  isBookingJob: boolean;
  isMobileResolution = window.innerWidth <= BREAK_LARGE_RESOLUTION;
  selectedSubscription: any;
  selectedCost: number;
  selectedCostPerUnit: number;
  subscriptionItems: any;
  subscriptionTypes: any;
  isRightSideContent = true;
  dialogParams: any;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isMobileResolution = event.target.innerWidth <= BREAK_LARGE_RESOLUTION;
  }

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private me: Me,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService
  ) {
    super();
    this.form = this.fb.group({
      subscription: ['', Validators.required],
      yearlyMonthly: [''],
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      const subscription = await this.getSubscription();
      this.numberOfUnits = subscription?.customer_address_count - subscription?.plan?.free_address_count;
      this.subscriptionTypes = await this.me.getSubscriptionTypes();
      if (subscription) {
        this.getSelectedSubscription(subscription);
      } else {
        this.patchFreeSubscription();
      }
      this.getCosts();
      this.isBookingJob = await this.getParam('isBookingJob');
      this.rightSidePanelService.setDialogPageTitle(this.isBookingJob ? 'Select Subscription:' : 'Change Subscription');
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  async getSubscription() {
    let nextSubscription = await this.getParam('nextSubscription');
    if (nextSubscription) {
      return nextSubscription;
    }
    let currentSubscription = await this.getParam('currentSubscription');
    if (currentSubscription) {
      return currentSubscription;
    }
    const subscriptions = await this.me.getSubscriptions();
    if (subscriptions.length > 1) {
      subscriptions.map((subscription) => {
        if (subscription.is_activated_in_next_cycle) {
          nextSubscription = subscription;
        } else {
          currentSubscription = subscription;
        }
      });
    } else {
      currentSubscription = subscriptions[0];
    }
    if (nextSubscription) {
      return nextSubscription;
    }
    if (currentSubscription) {
      return currentSubscription;
    }
  }

  getSelectedSubscription(subscription) {
    this.form.patchValue({ yearlyMonthly: subscription?.plan.renewal_frequency == 'yearly' ? 'yearly' : 'monthly' });
    this.selectedSubscription = this.me.getSubscriptionType(subscription?.plan.key);
    if (this.selectedSubscription == 'custom') {
      this.customSubscription = subscription;
      this.selectedSubscription = subscription;
      return;
    }
    this.currentSubscription = this.selectedSubscription;
    this.selectedCostPerUnit = subscription?.plan?.amount || 0;
    this.selectedCost = this.selectedCostPerUnit * this.numberOfUnits;
    this.currentCostPerUnit = this.selectedCostPerUnit;
  }

  patchFreeSubscription() {
    this.selectedSubscription = 'Free';
    this.form.patchValue({ yearlyMonthly: 'yearly' });
    this.selectedCost = 0;
    this.selectedCostPerUnit = 0;
    this.selectedCostPerUnit = 0;
    this.currentCostPerUnit = 0;
  }

  getCosts() {
    this.cost = {
      'TIDY+': {
        'monthly': this.subscriptionTypes.find((type) => type.key == 'tidy_plus_monthly_subscription_not_trial').amount,
        'yearly': this.subscriptionTypes.find((type) => type.key == 'tidy_plus_yearly_subscription_not_trial').amount,
      },
      'Standard': {
        'monthly': this.subscriptionTypes.find((type) => type.key == 'standard_monthly_subscription_not_trial').amount,
        'yearly': this.subscriptionTypes.find((type) => type.key == 'standard_yearly_subscription_not_trial').amount,
      },
      'Advanced': {
        'monthly': this.subscriptionTypes.find((type) => type.key == 'advanced_monthly_subscription_not_trial').amount,
        'yearly': this.subscriptionTypes.find((type) => type.key == 'advanced_yearly_subscription_not_trial').amount,
      }
    }
  }

  selectSubscription(type) {
    this.changedCustomSubscription = true;
    this.selectedSubscription = type;
    this.selectedCostPerUnit = this.cost[type] ? this.cost[type][this.form.value.yearlyMonthly] : 0;
    this.selectedCost = this.selectedCostPerUnit * this.numberOfUnits;
  }

  selectCustomSubscription() {
    this.changedCustomSubscription = false;
    this.selectedSubscription = this.customSubscription;
    this.selectedCostPerUnit = null;
  }

  async save() {
    try {
      const keys = {
        'Free': {
          'monthly': 'free_tier_yearly_subscription_not_trial',
          'yearly': 'free_tier_yearly_subscription_not_trial',
        },
        'TIDY+': {
          'monthly': 'tidy_plus_monthly_subscription_not_trial',
          'yearly': 'tidy_plus_yearly_subscription_not_trial',
        },
        'Standard': {
          'monthly': 'standard_monthly_subscription_not_trial',
          'yearly': 'standard_yearly_subscription_not_trial',
        },
        'Advanced': {
          'monthly': 'advanced_monthly_subscription_not_trial',
          'yearly': 'advanced_yearly_subscription_not_trial',
        }
      }
      const key = keys[this.selectedSubscription][this.form.value.yearlyMonthly];
      const currentSubscription = this.subscriptionTypes.find((type) => type.key == key);
      this.storage.save('currentSubscription', currentSubscription);
      if (!currentSubscription.key.includes('free')) {
        localStorage.setItem('hasNonTrialPaidSubscription', 'true');
      } else {
        localStorage.setItem('hasNonTrialPaidSubscription', 'false');
      }
      if (this.isBookingJob) {
        localStorage.setItem('isUpdatingSubscription', 'true');
        const nextBookingPage = await this.getParam('nextBookingPage');
        this.navCtrl.navigateBack(nextBookingPage);
      } else {
        localStorage.setItem('isUpdatingSubscription', 'false')
        const payload = {
          plan_type_key: key
        }
        await this.me.changeSubscription(payload);
        const successParams = this.mountSuccessPageParams();
        this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Subscription Changed',
      body: '',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

  goToLearnMoreAdvanced() {
    this.util.openUrl('https://www.tidy.com/get-demo');
  }

  goToLearnMorePlans() {
    this.util.openUrl('https://www.tidy.com/pricing');
  }

}

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Component, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime } from "rxjs/operators";

import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { BookJob } from 'src/providers/book-job/book-job';
import { Me } from 'src/providers/me/me';
import { Requests } from 'src/providers/requests/requests';
import { StripeProvider } from 'src/providers/stripe/stripe-provider';
import { Util } from 'src/shared/util/util';
import { WindowService } from "src/shared/providers/window.service";

import { Loading } from 'src/shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { CookieService } from 'ngx-cookie';
import { Client } from 'src/providers/client/client';

import validationUtils from 'src/shared/util/validation-utils';
import { PropertyOptionsPage } from '../more/edit-address/property-options/property-options';

@Component({
  templateUrl: './confirm-booking-payment.html'
})

export class ConfirmBookingPaymentPage implements OnInit, OnDestroy {

  availabilityRequestId: any;
  addressId: any;
  alreadyHasChargeAfterSetting: boolean;
  bookingExperiment: string;
  buttonTitle: string;
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  cardEl: any;
  cardError: string;
  cardHandler = this.onChange.bind(this);
  creditBalance: number;
  chargeSettings: {billing: {charge: {type: string}}} = {billing: {charge: {type: ''}}};
  chargeBeforePrice: number;
  customFlowName: string;
  customFlowSmallImage: string;
  customFlowDiscount: string;
  currentSubscription: any;
  bookData;
  bookingId;
  displayPrice: any;
  employerName: string;
  form: UntypedFormGroup;
  giftForm: UntypedFormGroup;
  giftCode: any;
  giftCodeErrorMessage: string;
  hasNonTrialPaidSubscription: any;
  isProRequest: boolean;
  isJobRequest: boolean;
  loaded: boolean;
  payload: any;
  params: any;
  planId;
  priceExperiment: string;
  referralCode: boolean;
  requestDates: any;
  selectedPrivatePro: any;
  termsError: boolean;
  termsChecked: boolean = true;
  bookingType;
  viewData;
  errorMessage: string;
  selectedServiceData: any;
  isUpdatingSubscription: boolean;
  showSameDayTimeAlert: boolean;
  sameDayPrice: any;
  startedNotUpdatingSubscription: boolean;
  submitted: boolean;
  subscriptionExperiment: string;
  giftCodeForm: boolean;
  customBackRoute: string;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    public bookJob: BookJob,
    private card: Card,
    private client: Client,
    private cd: ChangeDetectorRef,
    public me: Me,
    private navCtrl: CustomNavController,
    private fb: UntypedFormBuilder,
    private requests: Requests,
    private stripeProvider: StripeProvider,
    private storage: TidyStorage,
    private cookieService: CookieService,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      chargeType: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: ['']
    });
    this.giftForm = this.fb.group({
      giftCode: ['']
    });
    this.giftForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((val) => this.submitGiftCode());
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.rightSidePanelService.setDialogPageTitle('Confirm');
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    this.loaded = false;
    this.rightSidePanelService.setDialogLoading(true);
    await this.getPageParams();
    if (this.bookingType !== 'add_one_time_job') {
      this.bookingExperiment = localStorage.getItem('bookingExperiment');
      this.priceExperiment = this.bookJob.getPriceExperiment();
      this.subscriptionExperiment = this.bookJob.getSubscriptionExperiment();
      this.startedNotUpdatingSubscription = !this.isUpdatingSubscription;
    } else {
      this.startedNotUpdatingSubscription = true;
    }
    this.currentSubscription = await this.storage.retrieve('currentSubscription');
    this.customFlowName = localStorage.getItem('customFlowName');
    this.customFlowSmallImage = localStorage.getItem('customFlowSmallImage');
    this.customFlowDiscount = localStorage.getItem('customFlowDiscount');
    if (this.customFlowName) {
      this.setRequiredCustomFlowFields();
    }
    console.log('1')
    this.giftCode = false;
    this.giftCodeForm = false;
    let giftCodeParam = this.cookieService.get('giftCodeParam');
    if (giftCodeParam) {
      this.giftCode = await this.client.validateGiftCode(giftCodeParam);
      this.giftCodeForm = true;
      this.giftForm.patchValue({giftCode: this.giftCode.code});
    }
    if (this.bookingExperiment == 'consumer-test2' && this.viewData.frequency == 'One Time') {
      this.giftCodeForm = true;
      this.giftCode = await this.client.validateGiftCode('NEWCLIENT1');
      this.giftForm.patchValue({ giftCode: this.giftCode.code });
    }
    console.log('1')
    this.alreadyHasChargeAfterSetting = await this.storage.retrieve('billingType') == 'after_cleaning';
    if (this.alreadyHasChargeAfterSetting) {
      this.chargeBeforePrice = Math.round(this.viewData.price * .909090901 / 100) * 100;
      this.form.patchValue({chargeType: 'after_cleaning'})
    } else {
      this.chargeBeforePrice = this.viewData.price;
      this.form.patchValue({chargeType: 'before_cleaning'});
    }
    console.log('1')
    const userRole = localStorage.getItem('user_role');
    if (userRole !== 'member') {
      const creditBalance = parseInt(localStorage.getItem('creditBalance'));
      if (creditBalance) {
        this.creditBalance = creditBalance;
        this.employerName = localStorage.getItem('employerName');
      } else {
        const billingData = await this.client.getBillingData();
        this.creditBalance = billingData?.balance;
        this.employerName = billingData?.balance_details[0]?.transferred_from?.account_name;
      }
    }
    console.log('1')
    if (this.showSameDayTimeAlert) {
      if (this.customFlowName) {
        this.sameDayPrice = this.bookJob.getCustomDiscountedPrice(this.viewData.price * 1.5);
      } else {
        this.sameDayPrice = Math.round(this.viewData.basePrice * 1.5 / 100) * 100;
      }
    }
    console.log('1')
    this.buttonTitle =  this.isJobRequest ? 'Request Now' : 'Book Now';
    this.displayPrice = this.getDisplayPrice();
    this.loaded = true;
    this.rightSidePanelService.setDialogLoading(false);
    if (!this.cardEl) {
      this.cardEl = this.stripeProvider.getCardElement();
      this.cardEl.mount(this.cardInfo.nativeElement);
      this.cardEl.addEventListener('change', this.cardHandler);
    }
  }

  async getPageParams() {
    this.selectedServiceData = await this.getParam('selectedServiceData');
    this.bookingType = await this.getParam('bookingType');
    this.isUpdatingSubscription = await this.getParam('isUpdatingSubscription') || localStorage.getItem('isUpdatingSubscription') == 'true';
    this.hasNonTrialPaidSubscription = await this.getParam('hasNonTrialPaidSubscription') || localStorage.getItem('hasNonTrialPaidSubscription') == 'true';
    this.viewData = await this.getParam('viewData');
    this.bookData = await this.getParam('bookData');
    this.requestDates = await this.getParam('requestDates');
    this.showSameDayTimeAlert = await this.getParam('showSameDayTimeAlert');
    this.planId = await this.getParam('planId');
    this.bookingId = await this.getParam('bookingId');
    this.isProRequest = await this.getParam('isProRequest');
    this.isJobRequest = await this.getParam('isJobRequest');
    this.selectedPrivatePro = await this.getParam('selectedPrivatePro');
    this.addressId = await this.getParam('addressId');
    this.payload = await this.getParam('payload');
    this.availabilityRequestId = await this.getParam('availabilityRequestId');
    this.customBackRoute = await this.getParam('customBackRoute');
    console.log(this.isProRequest)
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName);
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  getDisplayPrice() {
    const giftCode = this.bookingExperiment == 'consumer-test2' ? 0 : this.giftCode?.amount;
    const subscriptionAmount = this.isUpdatingSubscription ? this.currentSubscription.amount : 0;
    return this.bookJob.getDisplayPriceForJob(
      this.planId,
      this.selectedServiceData,
      this.viewData?.frequency,
      true,
      this.bookingType,
      this.hasNonTrialPaidSubscription,
      this.priceExperiment,
      this.bookingExperiment,
      this.customFlowName,
      this.selectedPrivatePro,
      giftCode,
      subscriptionAmount);
  }

  setRequiredCustomFlowFields() {
    this.form.controls.firstName.setValidators([Validators.required, Validators.minLength(2)]);
    this.form.controls.lastName.setValidators([Validators.required, Validators.minLength(2)]);
    this.form.controls.email.setValidators([Validators.required, validationUtils.validateEmail]);
    this.form.controls.phone.setValidators([Validators.required]);
  }

  onChange({ error }) {
    this.errorMessage = error ? error.message : null;
    this.cd.detectChanges();
  }

  @Loading('', true)
  async submitGiftCode() {
    this.giftCodeErrorMessage = null;
    this.giftCode = false;
    this.displayPrice = this.getDisplayPrice();
    if (this.giftForm.value.giftCode == '' || this.giftForm.value.giftCode == null) {
      return;
    }
    try {
      const referralCode = await this.client.validateReferralCode(this.giftForm.value.giftCode);
      if (referralCode.valid) {
        this.giftCode = true;
        this.referralCode = true;
      } else {
        this.giftCode = await this.client.validateGiftCode(this.giftForm.value.giftCode);
        this.form.patchValue({giftCode: this.giftCode.code});
      }
      this.displayPrice = this.getDisplayPrice();
    } catch (err) {
      this.giftCodeErrorMessage = (err.error && err.error.message) ? err.error.message : err.message ;
    }
  }

  async requestPro() {
    await this.requests.addProRequest(this.addressId, this.payload);
    if (this.availabilityRequestId) {
      await this.requests.deleteProRequest(this.addressId, this.availabilityRequestId);
    }
  }

  @Loading('', true)
  async confirmBooking() {
    this.errorMessage = '';
    this.submitted = true;
    this.termsError = false;
    if (!this.form.valid || !this.termsChecked) {
      if (!this.termsChecked) {
        this.termsError = true;
      }
      return;
    }
    this.storage.delete('addresses');
    const { token, error } = await this.stripeProvider.getInstance().createToken(this.cardEl);
    if (error) {
      return this.errorMessage = 'Your card was declined again. Please try another card.';
    }
    try {
      if (this.isUpdatingSubscription) {
        const payload = {
          plan_type_key: this.currentSubscription.key
        }
        await this.me.changeSubscription(payload);
      }
      if (this.customFlowName) {
        const payload = {
          profile: {
            last_name: this.form.value.lastName.trim(),
            first_name: this.form.value.firstName.trim(),
            email: this.form.value.email,
            phone: this.form.value.phone
          }
        }
        await this.client.saveClientSettings(payload);
      }
      if (this.form.value.chargeType == 'after_cleaning' || (this.form.value.chargeType == 'before_cleaning' && this.alreadyHasChargeAfterSetting)) {
        this.chargeSettings.billing.charge.type = this.form.value.chargeType;
        await this.client.saveClientSettings(this.chargeSettings);
      }
      await this.card.saveCard({
        type: 'card',
        stripe_card_token: token.id,
        primary_payment_method: true,
      });
      if (this.isProRequest) {
        await this.requestPro();
      } else {
        if (this.giftCode) {
          if (this.referralCode) {
            this.bookData['referral_code'] = this.giftForm.value.giftCode;
          } else {
            await this.client.redeemGiftCode(this.giftForm.value.giftCode)
          }
        }
        if (this.customFlowName) {
          const code = localStorage.getItem('customFlowReferralCode');
          await this.client.redeemGiftCode(code)
        }
        if (this.isJobRequest) {
          await this.requests.addJobRequest(this.addressId, this.bookData);
        } else {
          const isBookingOneTimePlan = this.bookingType == 'add_job' && !this.bookData.frequency;
          if (isBookingOneTimePlan) {
            this.bookingType = 'add_one_time_job';
          }
          await this.bookJob.saveJob(this.bookingType, this.bookData, this.planId, this.bookingId);
        }
      }
      localStorage.removeItem('customFlowReferralCode');
      localStorage.removeItem('customFlowSmallImage');
      const params = {
        isNormalBookingFlow: true,
        viewData: this.viewData,
        isJobRequest: this.isJobRequest
      }
      localStorage.setItem('firstBooking', 'false');
      localStorage.setItem('isUpdatingSubscription', 'false');
      const userRole = localStorage.getItem('user_role');
      if (userRole == 'member') {
        const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
        this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
      } else {
        this.rightSidePanelService.navigateTo('property-options', params, PropertyOptionsPage);
      }
      await this.storage.save('isFirstBooking', false);
      await this.storage.save('cartMetadata', null);
    } catch(err) {
      if (err.code === 402) {
        this.errorMessage = 'Your card was declined again. Please try another card.';
      } else {
        this.errorMessage = err.error ? err.error.message : err.message;
      }
    }
  }

  changePrice(chargeType) {
    if (chargeType.value == 'before_cleaning') {
      this.viewData.price = this.chargeBeforePrice;
    } else {
      this.viewData.price = Math.round((this.chargeBeforePrice + this.chargeBeforePrice * .1)/100)*100;
    }
    this.selectedServiceData.price = this.viewData.price;
    this.displayPrice = this.getDisplayPrice();
  }

  async updateSubscription() {
    this.isUpdatingSubscription = !this.isUpdatingSubscription;
    localStorage.setItem('isUpdatingSubscription', this.isUpdatingSubscription ? 'true' : 'false');
    this.hasNonTrialPaidSubscription = !this.hasNonTrialPaidSubscription;
    if (this.isUpdatingSubscription) {
      localStorage.setItem('hasNonTrialPaidSubscription', 'true');
      const tidyPlusSubscription = await this.storage.retrieve('tidyPlusSubscription');
      this.currentSubscription = tidyPlusSubscription;
      this.storage.save('currentSubscription', tidyPlusSubscription);
    } else {
      localStorage.setItem('hasNonTrialPaidSubscription', 'false');
      this.storage.delete('currentSubscription');
      this.currentSubscription.amount = 0;
    }
    this.displayPrice = this.getDisplayPrice();
  }

  async goToViewSubscriptions() {
    const params = {
      isBookingJob: true,
      nextBookingPage: 'confirm-booking-payment'
    }
    this.navCtrl.navigateForward('select-subscription', params);
  }

  ngOnDestroy() {
    this.cardEl?.removeEventListener('change', this.cardHandler);
    this.cardEl?.destroy();
  }

  openTerms() {
    this.util.openUrl('https://www.tidy.com/terms');
  }

}

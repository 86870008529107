<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Buy Bundle'"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <tidy-row [align]="'center'">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Save $50 on TIDY!'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Want to save money on your Home Cleanings?  Purchase $550 in TIDY Credit for only $500.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Try this risk-free - you can still cancel anytime and are eligible for our Satisfaction Guarantee.'">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'By Tapping “Pay $500 for Bundle Now” we will charge your card on file $500, and add $550 credit to your account.'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            (action)="gotToLearnMore()"
            [body]="'Learn More'"
            class="link">
          </tidy-text>
        </tidy-row>
      </tidy-row>
    </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-row>
        <tidy-button
          [text]="'Go Back'"
          [action]="goBack.bind(this)"
          class="secondary">
        </tidy-button>
      </tidy-row>

      <tidy-row>
        <tidy-button
          [text]="'Pay $500 for Bundle Now'"
          [action]="confirm.bind(this)"
          class="primary">
        </tidy-button>
      </tidy-row>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Remote Inspection'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-row [align]="'center'">
        <tidy-text
          [header]="list?.title">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <tidy-row [alignLastItemRight]="true">
          <tidy-row [alignLastItemRight]="true" class="full-width">
            <tidy-image
              [src]="'assets/img/camera.svg'"
              class='header-size'>
            </tidy-image>
            <tidy-text
              [header]="'Remote Inspection'">
            </tidy-text>
            <div></div>
          </tidy-row>
          <tidy-toggle
            [toggled]="remoteInspection"
            (toggleChanged)="remoteInspection = !remoteInspection">
          </tidy-toggle>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Require pros to provide photos or videos so that you can remotely inspect each job.'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="remoteInspection">
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
          <form [formGroup]="form" novalidate action="#">
            <tidy-row class="extra-bottom-padding">
              <tidy-radio-button
                formControlName="type"
                [form]="form.controls.type"
                [label]="'How would you like your pro to visually inspect?'"
                [items]="typeItems"
                [submitted]="submitted"
                [errorMessage]="'Please select how to inspect.'">
              </tidy-radio-button>
            </tidy-row>
            <tidy-row>
              <tidy-radio-button
                formControlName="when"
                [form]="form.controls.when"
                [label]="'When would you like them to inspect?'"
                [items]="whenItems"
                [submitted]="submitted"
                [errorMessage]="'Please select when to inspect.'">
              </tidy-radio-button>
            </tidy-row>
          </form>
        </ng-container>
      </tidy-card>

      <tidy-button
        [text]="'Save Settings'"
        [action]="confirmChange.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

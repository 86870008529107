import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { validateEqual } from 'src/shared/validator/validateEqual';
import { navRootOptions } from 'src/shared/constants/rootNavigation';
import { Events } from 'src/providers/events/events';
import { OneSignalNotification } from 'src/shared/providers/one-signal-notification';
import { Schedule } from 'src/providers/schedule/schedule';

import { Auth } from 'src/providers/auth/auth';
import { Me } from 'src/providers/me/me';

@Component({
  templateUrl: 'password-recovery.html',
  encapsulation: ViewEncapsulation.None
})

export class PasswordRecoveryPage implements AfterViewInit {

  customerEmail: string;
  recoverPasswordForm: UntypedFormGroup;
  errorMessage: String;
  changePasswordToken: String;
  submitted: Boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private auth: Auth,
    private events: Events,
    private me: Me,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private schedule: Schedule,
    private oneSignalNotification: OneSignalNotification

  ) {
    this.recoverPasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    }, { validator: validateEqual('password', 'confirm_password') });
  }

  ngAfterViewInit() {
    this.submitted = false;
    this.errorMessage = '';
    this.changePasswordToken = this.route.snapshot.paramMap.get('token');
  }

  async onSubmit() {
    try {
      const data = this.buildData();
      if (this.recoverPasswordForm.valid) {
        const response: any = await this.auth.changePassword(data);
        const credentials = this.buildCredentials(response.email);
        await this.auth.login(credentials, 'login');
        await this.loadAccount();
        this.goToNextPage();
      } else if (data.password !== data.confirm_password){
        this.errorMessage = 'The passwords you entered do not match.';
      } else {
        this.errorMessage = 'Invalid Password.';
      }
    } catch (err) {
      if (err.status === 404) {
        this.errorMessage = 'This link is expired, please try again';
      }
    }
  }

  buildData(): any {
    return {
      password: this.recoverPasswordForm.value.password,
      confirm_password: this.recoverPasswordForm.value.confirm_password,
      reset_password_token: this.changePasswordToken
    };
  }

  buildCredentials(email): any {
    return {
      username: email,
      password: this.recoverPasswordForm.value.password
    };
  }

  async loadAccount() {
    return this.me.load().then(async account => {
      const customerObj: any = account;
      this.auth.setAccountTypeStorage(customerObj.account_type);
      const bookingId = this.navCtrl.getParam('bookingId');
      await this.auth.setSession();
      this.oneSignalNotification.setUpNotificationUser(account.current_user.id);
    });
  }

  async goToNextPage() {
    const billingData = await this.client.getBillingData();
    const creditBalance = billingData?.balance;
    const employerName = billingData?.balance_details[0]?.transferred_from?.account_name;
    localStorage.setItem('creditBalance', creditBalance.toString());
    localStorage.setItem('employerName', employerName);
    if (employerName && employerName !== 'undefined') {
      const addressResponse = await this.client.getAddresses(true);
      const address = addressResponse[0];
      if (!address) {
        this.navCtrl.navigateForward('add-first-property/employee');
      } else {
        const cards = await this.schedule.getCards(address.id);
        const cleaningHistory = await this.client.getCleaningsHistory();
        if (!cards.length && !cleaningHistory.length) {
          this.navCtrl.navigateForward('get-started', { address });
        } else {
          this.navCtrl.navigateForward('dashboard');
        }
      }
    } else {
      this.navCtrl.navigateForward('dashboard');
    }
  }

}

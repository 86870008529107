<mat-form-field [ngClass]="activeClass">
  <mat-label *ngIf="label" class="vertical-align-center">
    <ion-icon [name]="icon" *ngIf="icon"></ion-icon>
    <tidy-text [body]="label"> </tidy-text>
  </mat-label>
  <mat-select
    [(value)]="selected"
    [(ngModel)]="value"
    (selectionChange)="onChange($event)"
    [disableOptionCentering]="true"
  >
    <mat-option *ngIf="items?.length > ENABLE_SEARCH_MIN_ITEMS">
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="searchItems($event)"
        [placeholderLabel]="searchPlaceholder"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of listedItems" [value]="item.value">
      {{ item.viewValue | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>

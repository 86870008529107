import { AvailabilityRequest, Job, Properties, Property, Reservation } from './../../models/schedule-weekly-view.model';
import { Injectable } from '@angular/core';
import { DateTime, DateTime as LuxonDateTime } from 'luxon';

import { Aws } from 'src/providers/aws/aws';
import { Client } from 'src/providers/client/client';
import { HttpClientCustom } from 'src/providers/custom/http-client';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { WindowService } from 'src/shared/providers/window.service';

import { JobCard, SelectedFilters } from 'src/models/schedule.model';

import { generateUuid } from 'src/providers/tidy-session/session-uuid';
import { AddressModel } from 'src/models/address.model';
import { Util } from 'src/shared/util/util';
import { ReportIssue } from '../report-issue/report-issue';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Injectable()
export class Schedule {

  updateIcons = {
    warning: 'assets/svg/alert-circle.svg',
    confirmation: 'assets/svg/checkmark-circle.svg',
    searching: 'assets/svg/search-circle.svg',
  };

  properties: Properties = [];
  imutableProperties: Properties = [];
  weeklyViewContainer: any;
  weeklyViewScrollPosition: any;

  categoriesImages = {
    regular_cleaning: 'assets/img/service-categories/regular_cleaning.svg',
    pool_cleaning: 'assets/img/service-categories/pool_cleaning.svg',
    carpet_cleaning: 'assets/img/service-categories/carpet_cleaning.svg',
    window_washing: 'assets/img/service-categories/window_washing.svg',
    lawn_garden_care: 'assets/img/service-categories/lawn_garden_care.svg',
    junk_removal: 'assets/img/service-categories/junk_removal.svg',
    pest_control: 'assets/img/service-categories/pest_control.svg',
    pressure_washing: 'assets/img/service-categories/pressure_washing.svg',
    tree_trimming: 'assets/img/service-categories/tree_trimming.svg',
    inspection: 'assets/img/service-categories/inspection.svg',
    trash_service: 'assets/img/service-categories/trash_service.svg',
    handyman: 'assets/img/service-categories/handyman.svg',
    laundry: 'assets/img/service-categories/laundry.svg',
    locksmith: 'assets/img/service-categories/locksmith.svg',
    painting: 'assets/img/service-categories/painting.svg',
    grill_maintenance: 'assets/img/service-categories/grill_maintenance.svg',
    biohazard_cleaning: 'assets/img/service-categories/biohazard_cleaning.svg',
    plumbing: 'assets/img/service-categories/plumbing.svg',
    appliance_repair: 'assets/img/service-categories/appliance_repair.svg',
  };

  constructor(
    private aws: Aws,
    private client: Client,
    private httpClient: HttpClientCustom,
    private filePicker: FilePicker,
    public windowService: WindowService,
    private util: Util,
    private reportIssue: ReportIssue
  ) {}

  getCards(addressId) {
    const url = `api/v1/customer/addresses/${addressId}/cards/list`;
    return this.httpClient.get(url);
  }

  async getJobCard(addressId, jobId): Promise<JobCard> {
    const url = `api/v1/customer/addresses/${addressId}/cards/list?job_ids=${jobId}`;
    const response = await this.httpClient.get(url);
    if (response?.[0]?.template == 'pending_coops') {
      return response[1];
    } else {
      return response[0];
    }
  }

  async getJobDetail(cleaningId): Promise<any> {
    const url = `api/v1/me/jobs/cards/${cleaningId}?includes=job_durations`;
    return await this.httpClient.get(url);
  }

  async getJobEvents(bookingId: number): Promise<any> {
    const url = `api/v1/customer/customer-data-events?booking_id=${bookingId}`;
    return await this.httpClient.get(url);
  }

  async getUpdates(cleaningUpdateId) {
    const url = `api/v1/customer/cleaning-updates/${cleaningUpdateId}/page`;
    const icons = {
      skip: 'close',
      keep: 'contact',
      reschedule: 'play-forward',
      request: 'play-forward',
      loved_it: 'tidy-love-it',
      phone_call: 'call',
      phone_text: 'text',
      cancel: 'close',
      needs_work: 'tidy-needs-work',
    };
    const result = await this.httpClient.get(url);
    result.buttons = result.buttons.map((item) => {
      item.formatedIcon = icons[item.icon];
      return item;
    });
    return result;
  }

  cancelJob(bookingId) {
    const url = `api/v1/customer/bookings/${bookingId}/cancel`;
    return this.httpClient.post(url, {});
  }

  cancelPlan(planId) {
    const url = `api/v1/customer/plans/${planId}/cancel`;
    return this.httpClient.post(url, {});
  }

  cancelCrafRequest(availabilityRequestId: string) {
    const addressId = localStorage.getItem('addressId');
    const data = {
      availability_request_id: availabilityRequestId,
    };
    const url = `api/v1/customer/addresses/${addressId}/availability-requests/${availabilityRequestId}`;
    return this.httpClient.delete(url, data);
  }

  parseJobs(scheduleCards) {
    let jobs = [];
    scheduleCards.map((card) => {
      //TODO Add handling for other service types
      if (
        card.template === 'cleaning' ||
        card.template === 'cleaning_options_only'
      ) {
        jobs.push(card);
      }
    });
    return jobs;
  }

  getContactData(cardId) {
    const addressId = localStorage.getItem('addressId');
    const url = `api/v1/customer/addresses/${addressId}/cards/${cardId}/contact`;
    return this.httpClient.get(url);
  }

  createJobLink(payload) {
    const url = 'api/v1/customer/shared-links';
    return this.httpClient.post(url, payload);
  }

  didNotRequestSubstitute(scenario): boolean {
    const dontShowSenarios = [
      'same_day_cleaning_substitute_requested',
      'same_day_cleaning_substitute_failed',
    ];
    if (dontShowSenarios?.includes(scenario)) {
      return false;
    }
    return true;
  }

  showToDoButton(scenario): boolean {
    const dontShowSenarios = [
      'hk_confirmed_no_access',
      'hk_reported_client_cancellation',
      'same_day_cleaning_substitute_requested',
      'same_day_cleaning_substitute_failed',
      null,
      undefined
    ];
    if (dontShowSenarios?.includes(scenario)) {
      return false;
    }
    return true;
  }

  showRescheduleButton(scenario): boolean {
    const dontShowSenarios = [
      'hk_confirmed_no_access',
      'hk_reported_client_cancellation',
      'same_day_cleaning_substitute_requested',
      'same_day_cleaning_substitute_failed',
      'customer_reported_hk_no_showed',
      'unassigned_notification_sent',
      'hk_initiated_no_access',
    ];
    if (dontShowSenarios?.includes(scenario)) {
      return false;
    }
    return true;
  }

  showBackupTimesButton(scenario): boolean {
    const dontShowSenarios = [
      'hk_confirmed_no_access',
      'hk_reported_client_cancellation',
      'same_day_cleaning_substitute_requested',
      'same_day_cleaning_substitute_failed',
    ];
    if (dontShowSenarios?.includes(scenario)) {
      return false;
    }
    return true;
  }

  showCallText(proDisplayName, isPrivateJob): boolean {
    const backendReturnedString = proDisplayName !== null;
    const stringIsAssignedPro = !/Available|Found/.test(proDisplayName);
    return backendReturnedString && stringIsAssignedPro && !isPrivateJob;
  }

  callToActionText(callToAction) {
    if (callToAction === 'Optional Actions (No Action Needed)') {
      return 'Optional Actions';
    } else {
      return callToAction;
    }
  }

  showNotificationHistory(history) {
    if (history) {
      const hasHistory =
        history.length > 0 &&
        history[0]?.text !==
          'We are starting to contact pros in order of your priority list. Please check back soon for a full history.';
      const hasOnlyTidyBestAvailable =
        history.length == 2 &&
        history[0]?.text == 'Next: waiting for pros' &&
        history[1].text == 'Contacting new pros';
      return hasHistory && !hasOnlyTidyBestAvailable;
    } else {
      return false;
    }
  }

  markJobComplete(payload, bookingId) {
    const url = `api/v1/customer/bookings/${bookingId}/complete`;
    return this.httpClient.post(url, payload);
  }

  getCategoryImage(serviceKey) {
    if (!serviceKey) {
      return 'assets/img/service-categories/regular_cleaning.svg';
    }
    if (serviceKey?.includes('.')) {
      const array = serviceKey?.split('.');
      serviceKey = array?.length == 2 ? array?.[0] : array?.[1];
    }
    return this.categoriesImages?.[serviceKey];
  }

  async addAttachment() {
    try {
      const uuid = generateUuid();
      const file = await this.filePicker.pickFile();
      const fileKey = `message/${uuid}/${file.filename}`;
      if (file.format.includes('jpeg') || file.format.includes('png')) {
        const response = await this.aws.uploadFileToS3(
          file.dataUrl,
          fileKey,
          file.format
        );
        return { file, location: response.Location };
      } else {
        return {
          file: null,
          location: '',
        }
      }
    } catch (error) {
      console.error('Error uploading file', error);
      return {
        file: null,
        location: '',
      }
    }
  }

  keepOneProForTwoProJob(jobId) {
    const url = `api/v1/customer/cleanings/${jobId}/mark-half-cleaning-as-ok`;
    return this.httpClient.post(url, {});
  }

  getScheduleCalendarAllDays() {
    const url = 'api/v1/customer/calendar-view';
    return this.httpClient.get(url);
  }

  getScheduleCalendar(startDate, endDate, filters, scope?) {
    const searchParams = new URLSearchParams();
    searchParams.append('calendar_start_date', startDate);
    searchParams.append('calendar_end_date', endDate);
    if (scope) {
      searchParams.append('scope', scope);
    }
    if (filters.homekeeper_ids && filters.homekeeper_ids.length > 0) {
      searchParams.append('filters[homekeeper_ids]', filters.homekeeper_ids.join(','));
    }
    if (filters.address_ids && filters.address_ids.length > 0) {
      searchParams.append('filters[address_ids]', filters.address_ids.join(','));
    }
    const url = `api/v1/customer/calendar-view?${searchParams.toString()}`;
    return this.httpClient.get(url);
  }

  getBookingNotesForJob(bookingId) {
    const url = `api/v1/customer/booking-notes?booking_id=${bookingId}`;
    return this.httpClient.get(url);
  }

  createJobNote(payload) {
    const url = `api/v1/customer/booking-notes`;
    return this.httpClient.post(url, payload);
  }

  updateJobNote(bookingNoteId, payload) {
    const url = `api/v1/customer/booking-notes/${bookingNoteId}`;
    return this.httpClient.put(url, payload);
  }

  deleteJobNote(bookingNoteId) {
    const url = `api/v1/customer/booking-notes/${bookingNoteId}`;
    return this.httpClient.delete(url);
  }

  getMomentImage(momentType) {
    const momentStyleArray = {
      hk_on_the_way: 'assets/img/map-black-car.svg',
      hk_arrived_at_home: 'assets/img/map-light-green-check.svg',
      hk_started_cleaning: 'assets/img/map-green-check.svg',
      hk_initiated_cant_find_home: 'assets/img/map-red-alert.svg',
      hk_found_home: 'assets/img/map-happy-face.svg',
      hk_confirmed_cant_find_home: 'assets/img/map-red-alert.svg',
      hk_initiated_no_access: 'assets/img/map-red-alert.svg',
      hk_got_access: 'assets/img/map-happy-face.svg',
      hk_confirmed_no_access: 'assets/img/map-red-alert.svg',
      hk_reported_client_cancellation: 'assets/img/map-red-alert.svg',
      'same_day_cleaning_substitute_requested.svg': null,
      same_day_cleaning_substitute_accepted: null,
      customer_reported_hk_no_showed: null,
      unassigned_notification_sent: null,
      cleaning_completed_without_hk_confirmation: null,
      hk_completed_cleaning: 'assets/img/map-dark-green-check.svg',
      hk_checked_in: 'assets/img/map-black-check.svg',
      concierge_reported_hk_no_showed: null,
      manual_substitute_requested: null,
      manual_substitute_accepted: null,
    };
    return momentStyleArray[momentType] || 'assets/img/map-black-check.svg';
  }

  getBookingFormAnswers(bookingId) {
    const url = `api/v1/customer/customer-booking-form-answers?booking_id=${bookingId}`;
    return this.httpClient.get(url);
  }

  updateTableSearch(properties: Properties, search: string): Properties {
    const lowerCaseSearch = search.toLowerCase();
    return properties.filter((property) => {
      const matchInProperty = property?.name?.toLowerCase()?.includes(lowerCaseSearch) ||
        property?.address?.toLowerCase()?.includes(lowerCaseSearch);

      const matchInJobs = property?.jobs?.some((job) =>
        job?.serviceCategory?.toLowerCase()?.includes(lowerCaseSearch) ||
        job?.status?.name?.toLowerCase()?.includes(lowerCaseSearch)
      );

      return matchInProperty || matchInJobs;
    });
  }

  updateTableFilter(properties: Properties, filters: SelectedFilters): Properties {
    const { status, type, service, address, propertyName } = filters;
    const filteredProperties = properties.filter((property) => {
      if (Object.keys(filters).every(key => filters[key].length === 0)) {
        property.filteredJobs = property.jobs;
        property.filteredAvailabilityRequests = property.availabilityRequests;
        property.filteredReservations = property.reservations;
        return true;
      }
      // Initialize filtered arrays
      let filteredJobs = property.jobs;
      let filteredAvailabilityRequests = property.availabilityRequests;
      let filteredReservations = property.reservations;

      // Status filter
      if (status.length > 0) {
        if (status.includes('request_waiting_reply')) {
          filteredAvailabilityRequests = property.availabilityRequests;
        } else {
          filteredAvailabilityRequests = [];
        }

        filteredJobs = filteredJobs.filter((job) => {
          if (job?.status?.status === 'cancelled') {
            return status.includes('client_cancelled') || status.includes('request_cancelled');
          } else if (job?.status?.status === 'scheduled') {
            return status.includes('scheduled') || status.includes('checked_in');
          } else {
            return status.includes(job?.status?.status);
          }
        });
      }

      // Type filter
      if (type.length > 0) {
        if (!type.includes('reservation')) {
          filteredReservations = [];
        }
        if (!type.includes('job')) {
          filteredJobs = [];
        }
      }

      // Service filter
      if (service.length > 0) {
        filteredJobs = filteredJobs.filter((job) =>
          service.includes(job?.serviceCategory)
        );
      }

      // Set the filtered results back to the property
      property.filteredJobs = filteredJobs;
      property.filteredAvailabilityRequests = filteredAvailabilityRequests;
      property.filteredReservations = filteredReservations;

      if ((service.length > 0 || status.length > 0) && !type.includes('reservation')) {
        return (filteredJobs.length > 0 || filteredAvailabilityRequests.length > 0);
      }

      if (address.includes(property.id) || propertyName.includes(property.id)) {
        return true;
      }

      // Return true if any of the filtered arrays have items and address and property name match
      return (filteredJobs.length > 0 || filteredAvailabilityRequests.length > 0 || filteredReservations.length > 0);
    });
    return filteredProperties;
  }

  addSkeletonJobToCalendar(jobDate, jobTime, addressId) {
    localStorage.setItem('skipSortingProperties', 'true');
    this.properties.map((property) => {
      if (property.id == addressId) {
        let foundIndex = 0;
        property.jobs.map((job, index) => {
          const time = LuxonDateTime.fromFormat(job.time, 'h:mma');
          const formJobTime = LuxonDateTime.fromFormat(jobTime, 'H:mm');
          if (time < formJobTime) {
            foundIndex = index + 1;
          }
        });
        const luxonJobDate = LuxonDateTime.fromISO(jobDate)
        const startWeekday = luxonJobDate.toFormat('cccc');
        property.jobs.splice(foundIndex, 0, {
          startDate: luxonJobDate.toISODate(),
          endDate: '',
          skeleton: true,
          startWeekday: startWeekday,
          homekeeperName: '',
          id: 0,
          title: '',
          endWeekday: '',
          time: '',
          status: '',
          isPrivate: true,
          isJobRequest: false,
          serviceCategory: '',
          period: {
            startDate: '',
            endDate: '',
          },
          booking: {
            startDateNoEarlierThan: '',
            startTimeNoEarlierThan: '',
            endDateNoLaterThan: '',
            endTimeNoLaterThan: '',
            id: 0
          },
          icon: '',
          hasUnreadMessages: false
        });
      }
    });
  }

  removeSkeletonJobFromCalendar() {
    this.properties.forEach((property) => {
      property.jobs = property.jobs.filter(job => !job.skeleton);
    });
  }

  getFilteredWaterfallHistory(history) {
    let dontIncludeList = [
      'Starting to notify pros',
      'All Pros on your priority',
      'We are starting to contact',
      'declined'
    ];
    const historyTexts = new Set();

    history.map((notification) => {
      if (notification.data.type == 'declined') {
        history.map((item) => {
          if (item.data.type !== 'declined' && item.data.homekeeper_id == notification.data.homekeeper_id) {
            item.data.type = 'declined';
            item.data['declined_show_at'] = notification.data.show_at;
            item.data['declined_reason'] = notification.text;
          }
        });
      }
    });

    history = history.filter((notification) => {
      const isDuplicate = historyTexts.has(notification.text);
      if (!isDuplicate) {
        historyTexts.add(notification.text);
      }
      return !isDuplicate && !dontIncludeList.some(item => notification.text.includes(item));
    });

    history.map((notification) => {
      if (notification.text == 'Searching via Tidy Find New Pro' || notification.text == 'Contacting new pros' || notification.text == 'No pros found') {
        notification.text = 'New Pros';
      } else if (notification.text.includes('contacted')) {
        notification.text = notification.text.replace(/\s+contacted$/, '');
      }
      if (!notification.data.happened) {
        const contactTime = parseFloat(notification.data.hours_until_next_contact);
        if (notification.data.hours_until_next_contact == 0) {
          notification.text += ' (sending)';
        } else if (notification.data.hours_until_next_contact) {
          notification.text += ' (will contact ';
          if (contactTime < 24) {
            const totalMinutes = Math.round(contactTime * 60);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            let timeText = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
            timeText += minutes > 0 ? `${timeText ? ' ' : ''}${minutes} minute${minutes > 1 ? 's' : ''}` : '';
            notification.text += 'in ' + timeText + ')';
          } else {
            const contactDateTime = LuxonDateTime.now().plus({ hours: contactTime });
            notification.text += 'on ' + contactDateTime.toFormat('ccc M/d') + ' ' + contactDateTime.toFormat('h:mma').toLowerCase() + ')';
          }
        }
      }
    });

    return history;
  }

  async getDefaultScheduleViewMode() {
    const addressResponse = await this.client.getMoreDetailAddresses();
    return addressResponse.length > 3 || !this.windowService.isDesktopRes ? 'WEEKLY' : 'MONTHLY';
  }

  parseCalendarJobs(jobs: any[], address: AddressModel): Job[] {
    const parsedJobs = jobs.map((job) => {
      let parsedStartDate = LuxonDateTime.fromFormat(job?.booking?.start_date_no_earlier_than, 'MM/dd/yyyy').toISODate();
      let parsedEndDate = LuxonDateTime.fromFormat(job?.booking?.end_date_no_later_than, 'MM/dd/yyyy').toISODate();
      if (!job?.is_job_request) {
        parsedStartDate = LuxonDateTime.fromFormat(job?.date, 'MMM d, yyyy').toISODate();
        parsedEndDate = LuxonDateTime.fromFormat(job?.date, 'MMM d, yyyy').toISODate();
      }
      const startWeekday = this.util.getWeekdayFromDay(parsedStartDate);
      const endWeekday = this.util.getWeekdayFromDay(parsedEndDate);
      const timezone = job.timezone
      const dateTime = DateTime.fromFormat(job.start_time, 'HH:mm', { zone: timezone }).setLocale('en-US');
      const formattedTime = (dateTime.toLocaleString(DateTime.TIME_SIMPLE)).replace(' AM', 'am').replace(' PM', 'pm')
      const jobIcon = this.getJobIcon(job?.is_job_request, job?.service_icon_url, job?.job_progress)
      const homekeeperNames = job?.homekeeper_jobs?.map((homekeeper) => homekeeper?.homekeeper?.name).join(', ');
      const status = this.reportIssue.getJobStatusData(job?.homekeeper_jobs, job?.job_progress, job);
      const jobDate = LuxonDateTime.fromISO(parsedStartDate);
      const monthName = jobDate.toFormat('MMMM');
      const translatedMonth = new TranslationPipe().transform(monthName);
      const truncatedMonth = translatedMonth.slice(0, 3);
      const dayNumber = parseInt(jobDate.toFormat('d')); // Remove leading zero
      const formattedDate = `${truncatedMonth} ${dayNumber}, ${jobDate.toFormat('yyyy')}`;
      return {
        ...job,
        id: job.id,
        address,
        title: homekeeperNames ? homekeeperNames : job?.service_name,
        startWeekday,
        homekeeperName: job?.homekeeper_jobs[0]?.homekeeper?.name.split(' ').map(name => name[0]).join(''),
        homekeeperNames,
        startDate: parsedStartDate,
        endWeekday,
        endDate: parsedEndDate,
        time: formattedTime,
        icon: jobIcon,
        status: status,
        isPrivate: job?.is_private,
        isJobRequest: job?.is_job_request,
        serviceCategory: job?.service_category,
        period: {
          startDate: parsedStartDate,
          endDate: parsedEndDate,
        },
        booking: {
          startDateNoEarlierThan: job?.booking?.start_date_no_earlier_than,
          startTimeNoEarlierThan: job?.booking?.start_time_no_earlier_than,
          endDateNoLaterThan: job?.booking?.end_date_no_later_than,
          endTimeNoLaterThan: job?.booking?.end_time_no_later_than,
          id: job?.booking?.id
        },
        hasUnreadMessages: job?.chat_rooms_with_unread_messages?.length > 0,
        object: job,
        date: formattedDate // Use the new formatted date
      }
    })
    return parsedJobs;
  }

  parseCalendarReservations(reservations: any[], address: AddressModel, selectedPeriodRange: any, daysOfWeek: string[]): Reservation[] {
    const parsedReservations = reservations.map((reservation) => {
      const parsedStartDate = LuxonDateTime.fromFormat(reservation?.check_in_date, 'MMM d, yyyy').toISODate();
      const parsedEndDate = LuxonDateTime.fromFormat(reservation?.check_out_date, 'MMM d, yyyy').toISODate();
      const isStartDateInsidePeriod = this.util.isDateInsidePeriod(parsedStartDate, selectedPeriodRange);
      const isEndDateInsidePeriod = this.util.isDateInsidePeriod(parsedEndDate, selectedPeriodRange);
      const startWeekday = isStartDateInsidePeriod ? this.util.getWeekdayFromDay(parsedStartDate) : daysOfWeek[0];
      const endWeekday = isEndDateInsidePeriod ? this.util.getWeekdayFromDay(parsedEndDate) : daysOfWeek[6];
      const checkInDateTime = DateTime.fromFormat(reservation?.check_in_time, 'HH:mm', { zone: reservation.timezone }).setLocale('en-US');
      const checkOutDateTime = DateTime.fromFormat(reservation?.check_out_time, 'HH:mm', { zone: reservation.timezone }).setLocale('en-US');
      const formattedCheckInTime = (checkInDateTime.toLocaleString(DateTime.TIME_SIMPLE)).replace(' AM', 'am').replace(' PM', 'pm')
      const formattedCheckOutTime = (checkOutDateTime.toLocaleString(DateTime.TIME_SIMPLE)).replace(' AM', 'am').replace(' PM', 'pm')
      return {
        id: reservation.id,
        address,
        title: this.util.parseDateToFormat(parsedStartDate, 'MMM d') + ' - ' + this.util.parseDateToFormat(parsedEndDate, 'MMM d'),
        startWeekday,
        startDate: isStartDateInsidePeriod ? parsedStartDate : selectedPeriodRange.startDate,
        endWeekday,
        endDate: isEndDateInsidePeriod ? parsedEndDate : selectedPeriodRange.endDate,
        icon: 'assets/img/calendar-link.svg',
        period: {
          startDate: parsedStartDate,
          endDate: parsedEndDate,
        },
        object: reservation,
        checkInTime: formattedCheckInTime,
        checkOutTime: formattedCheckOutTime
      }
    })
    const removedDuplicateds = parsedReservations.filter((reservation, index, self) =>
      index === self.findIndex((t) => (
        t.startDate === reservation.startDate && t.endDate === reservation.endDate
      ))
    )
    return removedDuplicateds;
  }

  parseCalendarAvailabilityRequests(requests: any[], address: AddressModel, selectedPeriodRange: any, daysOfWeek: string[]): AvailabilityRequest[] {
    const requestsWithoutDuplicates = requests.filter((request, index, self) =>
      index === self.findIndex((t) => (
        t.id === request.id
      ))
    )
    const parsedRequests = requestsWithoutDuplicates.map((request) => {
      const firstRequestDate = request?.request_times?.find((requestTime) => {
        const today = LuxonDateTime.local().toJSDate().setHours(0, 0, 0, 0);
        const parsedDate = LuxonDateTime.fromISO(requestTime?.date).toJSDate().setHours(0, 0, 0, 0);
        return parsedDate >= today;
      })?.date;
      const parsedStartDate = LuxonDateTime.fromISO(firstRequestDate).toISODate();
      const parsedEndDate = parsedStartDate;
      const startWeekday = this.util.getWeekdayFromDay(parsedStartDate);
      const endWeekday = this.util.getWeekdayFromDay(parsedEndDate);
      const jobIcon = this.getJobIcon(true, null, request?.status)
      const status = this.reportIssue.getJobStatusData(null, request?.job_progress);
      const homekeeperName = request?.homekeeper?.name;
      let parsedRequestTimes = '';
      request?.request_times.map((time, index) => {
        const startTime = LuxonDateTime.fromFormat(time.requested_times[0], 'HH:mm').toFormat('h:mma').toLowerCase();
        const endTime = LuxonDateTime.fromFormat(time.requested_times[time.requested_times.length - 1], 'HH:mm').toFormat('h:mma').toLowerCase();
        parsedRequestTimes += LuxonDateTime.fromISO(time?.date).toFormat('M/d') + '<br>' + startTime + ' - ' + endTime;
        if (index !== request?.request_times.length - 1) {
          parsedRequestTimes += '<br><br>';
        }
      });
      const initiatedBy = request?.initiated_by;
      return {
        id: request?.id,
        address,
        title: homekeeperName ? homekeeperName : request?.service_type_details?.name,
        startWeekday,
        startDate: parsedStartDate,
        endWeekday,
        endDate: parsedEndDate,
        icon: jobIcon,
        status: status,
        isPrivate: true,
        isJobRequest: true,
        serviceCategory: request?.service_type_details?.name,
        period: {
          startDate: parsedStartDate,
          endDate: parsedEndDate,
        },
        parsedRequestTimes,
        initiatedBy,
        object: request
      }
    })
    return parsedRequests;
  }

  getJobIcon(isJobRequest: boolean, serviceIconUrl: string, jobProgress: string): string {
    return (serviceIconUrl || 'assets/img/mop.png');
    /*const warningStatuses = ['request_failed', 'client_cancelled', 'homekeeper_cancelled', 'having_issues'];
    if (isJobRequest) {
      if (jobProgress === 'request_waiting_reply' || jobProgress === 'pending') {
        return 'assets/icon/searching.svg';
      }
    }
    return warningStatuses?.includes(jobProgress) ? 'assets/img/warning.png' : (serviceIconUrl || 'assets/img/mop.png');*/
  }
}

export interface CalendarViewJobResponse {
  id?: number | null;
  date?: string | null;
  address_id?: number | null;
  service_name?: string | null;
  service_category?: string | null;
  service_icon_url?: string | null;
  service_map_icon_url?: string | null;
  start_time?: string | null;
  timezone?: string | null;
  is_private?: boolean | null;
  job_progress?: string | null;
  chat_rooms_with_unread_messages?: any[] | null;
  is_job_request?: boolean | null;
  booking?: {
    id?: number | null;
    start_date_no_earlier_than?: string | null;
    start_time_no_earlier_than?: string | null;
    end_date_no_later_than?: string | null;
    end_time_no_later_than?: string | null;
  } | null;
  homekeeper_jobs?: {
    id?: number | null;
    homekeeper?: {
      id?: number | null;
      name?: string | null;
    } | null;
  }[] | null;
  [key: string]: any;
}

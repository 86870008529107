import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

const d = 5000; // fade timer
const o = 0.7; //opacity
const minbub = 2;
const maxbub = 6;

@Component({
  templateUrl: 'scanner-component.html',
  selector: 'scanner-component',
  styleUrls: ['./scanner-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoScanner {

  @Input() text;
  active: boolean;
  brd: any = document.createElement("div");
  bubbles: any[] = [];
  before: any;
  createBubbleInterval;
  id: NodeJS.Timeout;
  @ViewChild('scanner') scanner;
  @Output() videoUrl: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private el:ElementRef
  ) {}

  ngAfterViewInit() {
    this.active = true;
    this.scanner.nativeElement.insertBefore(this.brd, document.getElementById("board"));

    this.before = Date.now();
    this.id = setInterval(() => {
      this.frame()}, 5);
  }

  set statusChange(status) {
    if(status === 'recording') {
      this.createBubbleInterval = setInterval(() => {
        let x = Math.random() * (this.el.nativeElement.offsetWidth - 13) + 7;
        let y = Math.random() * (this.el.nativeElement.offsetWidth - 13) + 7;
        this.generateBubbles(x, y);
      }, Math.random() * 300 + 120);
    } else {
      clearInterval(this.createBubbleInterval);
    }
  }

  newBubble(x, y, size, color){
    let bubble: any = document.createElement("div");
    bubble.setAttribute('class', 'bubble');
    bubble.style = "background-color : " + color + ";";
    bubble.bubbleSize = size;
    bubble.style.height = bubble.bubbleSize * 2 + "px";
    bubble.style.width = bubble.bubbleSize * 2 + "px";
    bubble.time = d;
    bubble.velocity = [];
    bubble.velocity.x = 0;
    bubble.velocity.y = 0;
    bubble.position = [];
    bubble.position.x = x;
    bubble.position.y = y;
    bubble.style.left = bubble.position.x - bubble.bubbleSize + 'px';
    bubble.style.top = bubble.position.y - bubble.bubbleSize + 'px';    
    this.brd.appendChild(bubble);
    if(this.bubbles == null){
      this.bubbles = [];
    }
    this.bubbles.push(bubble);
    return bubble;
  }

  frame(){
    let current = Date.now();
    let deltaTime = current - this.before;
    this.before = current;
    let i = 0;
    this.bubbles.map((bubble) => {
      bubble.time -= deltaTime;

      if(bubble.time > 0){
        bubble.velocity.y += 1 * deltaTime;
        bubble.velocity.x -= 1;
        bubble.velocity.y -= 1;
        bubble.position.x += bubble.velocity.x;
        bubble.position.y -= bubble.velocity.y;
        bubble.style.left = bubble.position.x;
        bubble.style.top = bubble.position.y;
        bubble.style.opacity = o * (bubble.time / d);
      }
      else{
        bubble.parentNode.removeChild(bubble);
        this.bubbles.splice(i, 1);
      }
      i += 1;
    })
  }

  generateBubbles(x, y){
    let size = minbub + (maxbub - minbub) * Math.random();
    let colors = ['#88E194', '#53AD53', '#9CF7A4'];
    let colorIndex = Math.round(Math.random() * 2 + 0);
    this.newBubble(x, y, size, colors[colorIndex]);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  videoRecorded(awsInfo){
    this.videoUrl.emit(awsInfo);
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="job ? 'To-Dos for ' + (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D') : ''"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="categoryImage"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="job?.template_data?.booking?.bookable_service?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'ddd') +
                  ' ' +
                  (job?.template_data?.job?.start_datetime_local | parseDate: 'M/D')">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'at'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="(job?.template_data?.job?.start_datetime_local | parseDate: 'h:mma')">
                </tidy-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-divider>
          </tidy-divider>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <form [formGroup]="form">
            <tidy-select
              [icon]="'assets/svg/list-outline.svg'"
              [label]="'To-Do List'"
              [form]="form.controls.list"
              formControlName="list"
              [items]="toDoFilter"
              (optionChange)="saveList()">
            </tidy-select>
          </form>
        </tidy-row>
        <tidy-row [align]="'center'">
          <tidy-text
            *ngIf="usingToDos && !saving"
            [helper]="'List saved to job.'">
          </tidy-text>
          <tidy-text
            *ngIf="!usingToDos && !saving"
            [helper]="'Changed saved.'">
          </tidy-text>
          <tidy-text
            *ngIf="saving"
            [helper]="'Saving...'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <ng-container *ngIf="usingToDos">

        <tidy-card *ngIf="videoInspection == 'Before Videos' || videoInspection == 'Before & After Videos'">
          <tidy-row class="vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/img/camera.svg'"
              class='title-size'>
            </tidy-image>
            <tidy-text
              [header]="'Remote Inspection'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-text
              [body]="videoInspection">
            </tidy-text>
          </tidy-row>
        </tidy-card>

        <ng-container *ngFor="let room of rooms">
          <tidy-card class="no-padding" *ngIf="!room?.do_not_clean">
            <tidy-card-button [hideArrow]="true" class="no-padding">
              <tidy-row class="vertical-align-center extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding">
                <tidy-image
                  *ngIf="room?.type !== 'group'"
                  [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [title]="room?.name">
                </tidy-text>
              </tidy-row>

              <div class="slide-container">
                <tidy-carrousel
                  *ngIf="room?.photos?.length > 0"
                  class="photo-notes-carrousel only-padding-bottom">
                  <ng-container *ngFor="let photoNote of room?.photos">
                    <tidy-photo-note
                      class="full-width there-is-carrousel no-margin"
                      *tidyCarrousel
                      [caption]="photoNote.photo_note_caption"
                      [src]="photoNote.photo_url"
                      ></tidy-photo-note>
                  </ng-container>
                </tidy-carrousel>
              </div>

              <ng-container *ngFor="let toDo of room?.tasks">
                <ng-container *ngIf="!toDo?.task_type?.includes('video')">
                  <tidy-row class="extra-left-padding extra-right-padding extra-bottom-padding">
                    <span [ngStyle]="toDos.important(toDo?.is_important)" class="vertical-align-center">
                      <tidy-text
                        [body]="toDo?.title">
                      </tidy-text>
                      <!--TODO replace this inline style with a class margin-left-->
                      <tidy-image
                        *ngIf="toDo?.is_important"
                        style="margin-left: 5px"
                        [src]="'assets/svg/star.svg'">
                      </tidy-image>
                    </span>
                    <ng-container *ngIf="toDo?.note !== ''">
                      <tidy-text
                        [helper]="toDo?.note">
                      </tidy-text>
                    </ng-container>
                  </tidy-row>
                  <div class="slide-container">
                    <tidy-carrousel
                      *ngIf="toDo?.photo_notes?.length > 0"
                      class="photo-notes-carrousel only-padding-bottom">
                      <ng-container *ngFor="let photoNote of toDo?.photo_notes">
                        <tidy-photo-note
                          class="full-width there-is-carrousel no-margin"
                          *tidyCarrousel
                          [caption]="photoNote.photo_note_caption"
                          [src]="photoNote.photo_url"
                          ></tidy-photo-note>
                      </ng-container>
                    </tidy-carrousel>
                  </div>
                </ng-container>
              </ng-container>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
        <ng-container *ngFor="let room of rooms">
          <tidy-card class="no-padding" *ngIf="room?.do_not_clean">
            <tidy-card-button
              [hideArrow]="true">
              <tidy-row class="vertical-align-center extra-top-padding extra-bottom-padding extra-left-padding extra-right-padding">
                <tidy-image
                  [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                  class="title-size">
                </tidy-image>
                <tidy-text
                  [title]="room?.name"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
              <tidy-row class="extra-bottom-padding extra-left-padding extra-right-padding">
                <tidy-text
                  class="red"
                  [body]="'<b>Do not</b> clean this room'">
                </tidy-text>
              </tidy-row>
            </tidy-card-button>
          </tidy-card>
        </ng-container>
      </ng-container>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template let-extraClass="extraClass" #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="pageTitle"
    [canGoBack]="true"
    [customBack]="'concierge'">
  </tidy-top-nav>
</ng-template>

<ng-container
  *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <div class="sidebar-search-row">
      <form [formGroup]="searchForm" novalidate action="#">
        <tidy-search-input
          formControlName="search"
          [placeholder]="'Search'"
          [icon]="'assets/svg/search-outline.svg'">
        </tidy-search-input>
      </form>
      <div class="header-button primary" (click)="goToAddRulePage()" *ngIf="type == 'rules'">
        <tidy-image
          [src]="'assets/img/add-white.svg'"
          style="height: 16px;">
        </tidy-image>
        <tidy-text
          [headerButton]="'Add Rule'"
          class="white">
        </tidy-text>
      </div>
    </div>

    <tidy-card *ngFor="let item of shownItems">
      <tidy-row *ngIf="type == 'rules'" [alignLastItemRight]="true">
        <tidy-text
          [body]="item.description">
        </tidy-text>
        <tidy-text
          class="link"
          [actionBody]="'Edit'"
          (action)="goToEditRulePage(item)">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="type == 'suggestions'" [alignLastItemRight]="true">
        <tidy-text
          [body]="item.description">
        </tidy-text>
        <tidy-text
          [actionBody]="'Dismiss'"
          (action)="dismissSuggestion(item)"
          class="link red">
        </tidy-text>
      </tidy-row>
    </tidy-card>

    <tidy-alert *ngIf="items?.length == 0">
      <tidy-text
        body="None">
      </tidy-text>
    </tidy-alert>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>


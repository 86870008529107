import {Component, ViewEncapsulation, Input, OnChanges, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../default-class/value-accessor';
import { AutocompleteModel } from './autocomplete.model';

@Component({
  selector: 'tidy-auto-complete',
  templateUrl: 'auto-complete.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auto-complete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AutocompleteComponent,
    multi: true,
  }],
})
export class AutocompleteComponent extends ValueAccessorBase<any> implements OnChanges, AfterViewInit {
  @Input() label: string;
  @Input() form: UntypedFormControl;
  @Input() icon: string;
  @Input() public formControl: any;
  @Input() showOnFocus = false;
  @Input() options: Array<AutocompleteModel>;
  @Input() public submitted: boolean;
  @Input() public errorMessage: string;
  @Input() public isToSearchBetweenOptions = false;
  @Input() selected: AutocompleteModel;
  @Input() showGoogleIcon: boolean;

  @Output() public action: EventEmitter<any> = new EventEmitter<any>();

  filteredOptions: Observable<AutocompleteModel[]>;
  isFocused  = false;
  touchUpdate = false;
  isListeningChangeSearchInput = false;

  ngAfterViewInit() {
    if(!this.selected || Object.keys(this.selected).length === 0) {
      return;
    }

    this.selectADefaultOption();
  }

  selectADefaultOption() {
    this.choseItem(this.selected);

    this.form.setValue(this.selected.value);
  }

  onFocusOut() {
    setTimeout(() => {
      this.isFocused = false;
    }, 1500);
  }

  choseItem(item: AutocompleteModel) {
    this.value = item.value;

    this.action.emit(item);
  }

  onKeyUp(value: string) {
    this.value = value;
  }

  onKeyDown() {
    this.isFocused = true;
    this.touchUpdate = true;
  }

  ngOnChanges() {
    if(!this.isToSearchBetweenOptions || this.isListeningChangeSearchInput) {
      return;
    }

    this.filteredOptions = this.form.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.isListeningChangeSearchInput = true;
  }

  private _filter(value: string = '') {
    if (!this.options || value.length === 0) {
      return;
    }

    const filterValue = value.toLowerCase();

    const result = this.options.filter(option => {
      const lowerCaseValue = option.display.toLocaleLowerCase().replace(' ', '');
      const matchWord = lowerCaseValue.includes(filterValue);

      return matchWord;
    });

    return result;
  }

  getValue() {
    if (!this.options?.length) {
      return this.value;
    }

    return this.options.find((op) => op.value === this.value)?.display || this.value;
  }
}

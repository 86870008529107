import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BookJob } from 'src/providers/book-job/book-job';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Util } from 'src/shared/util/util';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel, TidySelectStringValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'list-settings.html',
  encapsulation: ViewEncapsulation.None
})

export class ToDosListSettingsPage implements OnInit {

  addressItems: TidySelectNumberValueModel[];
  addressToDoListItems: any;
  allToDoListItems: any;
  errorMessage: string;
  form: UntypedFormGroup;
  didUpdateDefaultSetting: boolean;
  hasNoDefaultList: boolean;
  isRentalClient: boolean;
  isLoadingLists: boolean;
  isRightSideContent: boolean;
  loaded: boolean;
  services: any;
  serviceItems: TidySelectStringValueModel[];
  selectedService: any;
  selectedAddress: any;
  toDoState: any;

  constructor(
    private bookJob: BookJob,
    private client: Client,
    private currentAddress: CurrentAddress,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    public toDos: ToDos,
    private util: Util
  ) {
    this.form = this.fb.group({
      address: [''],
      addressServiceDefaultList: [''],
      addressDefaultList: [''],
      accountServiceDefaultList: [''],
      accountDefaultList: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRentalClient = localStorage.getItem('isRentalClient') == 'true';
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      const addressResponse = await this.client.getAddresses();
      const addressId = this.client.getSelectedAddressId(addressResponse);
      this.toDoState = await this.toDos.getToDoState(addressId);
      await this.getServiceItems();
      await this.getAllToDoListItems();
      await this.getAddressItems(addressId);
      await this.getSelectedAddressToDoListItems();
      this.patchForms();
      this.loaded = true;
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  async getAllToDoListItems() {
    this.allToDoListItems = [];
    const allToDoLists = await this.toDos.getAllIsCustomerToDoLists();
    this.allToDoListItems = allToDoLists.map((list) => {
      return {
        value: list.id,
        viewValue: list?.title,
        defaultListSettings: list?.default_list_settings
      }
    });
    this.allToDoListItems.push({
      value: 'No Default',
      viewValue: 'No Default',
      defaultListSettings: []
    });
  }

  async getSelectedAddressToDoListItems() {
    let addressToDoListItems = [];
    const addressToDoLists = await this.toDos.getAllToDoListsForAddress(this.selectedAddress.value);
    addressToDoListItems = addressToDoLists.map((list) => {
      return {
        value: list.id,
        viewValue: list?.title,
        defaultListSettings: list?.default_list_settings
      }
    });
    const allToDoListItems = this.allToDoListItems.slice(0, -1);
    const uniqueAllToDoListItems = allToDoListItems.filter(item => 
      !addressToDoListItems.some(addressItem => addressItem.value === item.value)
    );
    this.addressToDoListItems = [
      ...addressToDoListItems, 
      ...uniqueAllToDoListItems
    ];
    this.addressToDoListItems.push({
      value: 'No Default',
      viewValue: 'No Default',
      defaultListSettings: []
    });
  }

  async getAddressItems(addressId) {
    const addresses = await this.client.getAddresses();
    this.addressItems = this.client.parseAddressList(addresses, true);
    this.selectedAddress = this.addressItems.find(address => address.value == addressId);
    this.addressItems.pop();
  }

  async getServiceItems() {
    this.services = await this.bookJob.fetchAllRequiredTeamServices();
    this.selectedService = this.services[0];
    this.serviceItems = [];
    this.serviceItems = this.services.map((service) => {
      return {
        viewValue: service.name,
        value: service.key
      };
    });
  }

  patchForms() {
    let addressId = parseFloat(localStorage.getItem('addressId'));
    this.form.patchValue({
      address: addressId,
      addressServiceDefaultList: this.getAddressServiceDefaultList(addressId) || 'No Default',
      addressDefaultList: this.getAddressDefaultList(addressId) || 'No Default',
      accountServiceDefaultList: this.getAccountServiceDefaultList() || 'No Default',
      accountDefaultList: this.getAccountDefaultList() || 'No Default'
    });
  }

  getAddressServiceDefaultList(addressId) {
    let defaultList = null;
    this.addressToDoListItems.map((list) => {
      list.defaultListSettings.map((setting) => {
        if (setting.key ==`default_list.address.${addressId}.${this.selectedService.key}`) {
          defaultList = list;
        }
      });
    });
    return defaultList?.value;
  }

  getAccountServiceDefaultList() {
    let defaultList = null;
    this.allToDoListItems.map((list) => {
      list.defaultListSettings.map((setting) => {
        if (setting.key ==`default_list.${this.selectedService.key}`) {
          defaultList = list;
        }
      });
    });
    return defaultList?.value;
  }

  getAddressDefaultList(addressId) {
    let defaultList = null;
    this.addressToDoListItems.map((list) => {
      list.defaultListSettings.map((setting) => {
        if (setting.key ==`default_list.address.${addressId}`) {
          defaultList = list;
        }
      });
    });
    return defaultList?.value;
  }

  getAccountDefaultList() {
    let defaultList = null;
    this.allToDoListItems.map((list) => {
      list.defaultListSettings.map((setting) => {
        if (setting.key =='default_list.account') {
          defaultList = list;
        }
      });
    });
    return defaultList?.value;
  }

  async updateAddressServiceDefaultList(listId) {
    const key = `default_list.address.${this.form.value.address}.${this.selectedService.key}`;
    this.updateDefault(listId, key);
  }

  async updateAddressDefaultList(listId) {
    const key = `default_list.address.${this.form.value.address}`;
    this.updateDefault(listId, key);
  }

  async updateAccountServiceDefaultList(listId) {
    const key = `default_list.${this.selectedService.key}`;
    this.updateDefault(listId, key);
  }

  async updateAccountDefaultList(listId) {
    const key = 'default_list.account';
    this.updateDefault(listId, key);
  }

  async updateDefault(listId, defaultListSettingsKey) {
    const isSettingNoDefault = listId == 'No Default';
    if (isSettingNoDefault) {
      listId = this.getCurrentDefaultListId(defaultListSettingsKey);
    }
    let payload = {
      address_task_list_id: listId,
      address_id: this.form.value.address,
      settings: [{
        key: defaultListSettingsKey,
        value: !isSettingNoDefault
      }]
    }
    try {
      await this.toDos.saveDefaultList(listId, payload);
      await this.storage.save('didMakeToDoUpdates', true);
      this.didUpdateDefaultSetting = true;
      this.showSuccessMessage();
    } catch(err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  getCurrentDefaultListId(key) {
    let defaultListId = null;
    this.allToDoListItems.map((item) => {
      item.defaultListSettings.map((setting) => {
        if (setting.key == key) {
          defaultListId = item.value;
        }
      });
    });
    this.addressToDoListItems.map((item) => {
      item.defaultListSettings.map((setting) => {
        if (setting.key == key) {
          defaultListId = item.value;
        }
      });
    });
    return defaultListId;
  }

  async updatePropertyToDoState() {
    try {
      this.toDoState = this.toDoState == 'active' ? 'inactive' : 'active';
      await this.toDos.updateDoNotUseToDos(this.toDoState);
      await this.storage.save('didMakeToDoUpdates', true);
      this.showSuccessMessage();
    } catch(err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  showSuccessMessage() {
    const successMessage = 'Successfully updated To-Do settings';
    this.util.showSuccess(successMessage);
  }

  async changeAddress(addressId) {
    this.toDoState = await this.toDos.getToDoState(addressId);
    this.currentAddress.addressId = addressId.toString();
    try {
      this.isLoadingLists = true;
      if (this.didUpdateDefaultSetting) {
        await this.getAllToDoListItems();
      } 
      this.didUpdateDefaultSetting = false;
      this.selectedAddress = this.addressItems.find((address) => address.value == addressId); 
      await this.getSelectedAddressToDoListItems();
      this.patchForms();
      this.isLoadingLists = false;
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  async changeSelectedService(serviceKey) {
    try {
      this.isLoadingLists = true;
      if (this.didUpdateDefaultSetting) {
        await this.getAllToDoListItems();
        await this.getSelectedAddressToDoListItems();
      } 
      this.didUpdateDefaultSetting = false;
      this.selectedService = this.services.find(service => service.key == serviceKey);
      this.patchForms();
      this.isLoadingLists = false;
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  goToConcierge() {
    const params = {
      title: 'Concierge Action',
      type: 'support.concierge_task',
      isAddingToDoList: true
    }
    this.rightSidePanelService.navigateTo('contact-concierge', params);
  }

  goToToDosPage() {
    this.navCtrl.navigateForward('all-to-dos');
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ContactConciergePage } from '../../contact-concierge/contact-concierge';
import { OpenDisputePage } from '../../advanced-dispute/advanced-dispute';

@Component({
  templateUrl: 'job-issue-negative-review.html',
  encapsulation: ViewEncapsulation.None
})

export class JobIssueNegativeReviewPage extends TimeoutableComponent implements OnInit {

  job: any;
  negativeReviewSentence: string;
  privateJob: boolean;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private concierge: Concierge,
    private client: Client,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    super();
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Job Issue');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.job = await this.getParam('job');
      this.privateJob = await this.getParam('privateJob');
      const creditAdded = await this.client.getBillingCredits(this.job.job.id);
      this.negativeReviewSentence = this.buildNegativeReviewSentence(creditAdded);
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  buildNegativeReviewSentence(creditAdded): string {
    if (creditAdded.length === 0) {
      return ' ';
    } else {
      let creditAmount = 0;
      creditAdded.map((credit) => {
        creditAmount += credit.amount / 100;
      });
      const enoughCreditForFreeJob = creditAmount > 45;
      if (enoughCreditForFreeJob) {
        return `You had the Satisfaction Guarantee coverage, and recieved $${creditAmount} credit, enough for a complimentary cleaning, depending on your settings. `;
      } else {
        return `You recieved $${creditAmount} credit. `;
      }
    }
  }

  selectIssue(issue, title) {
    const params = {
      title: title,
      type: 'support.job_issue',
      metadata: {
        suptype: issue,
        job_id: this.job.job.id,
        job_datetime: this.job.job.start_datetime,
      }
    };
    this.rightSidePanelService.navigateTo('contact-concierge', params, ContactConciergePage);
  }

  advancedDispute(title) {
    const params = {
      title: title,
      job: this.job
    };
    this.rightSidePanelService.navigateTo('open-dispute', params, OpenDisputePage);
  }
}

import { Injectable } from '@angular/core';
import { FirstAvailableTimes } from '..//calendar/first-available-times'

@Injectable()
export class PlanFrequency {
  frequency: Array<string> = []
  constructor(
    private firstAvailableTimes: FirstAvailableTimes,
  ) {
    this.frequency = this.firstAvailableTimes.getFrequencyByPlan();
  }

  getFrequency() {
    return [this.frequency[1], this.frequency[2], this.frequency[3]];
  }
  
  getAllFrequenciesOneTimeFirst() {
    // one time +10%, every 4 week, every other week, every week, 2x per week
    return [
      this.frequency[5],
      this.frequency[4],
      this.frequency[3],
      this.frequency[2],
      this.frequency[1]
    ];
  }
  
  getAllFrequenciesOneTimeLast() {
    // 2x per week, every week, every other week, every 4 week, one time +10%
    return [
      this.frequency[1],
      this.frequency[2],
      this.frequency[3],
      this.frequency[4],
      this.frequency[5]
    ];
  }
}

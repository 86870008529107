import { v4 as uuidv4 } from 'uuid';
/**
 * Generates a random RFC 4122 version 4 UUID.
 * The UUID is generated using a cryptographic pseudorandom number generator.
 */
export function randomUUID() {
  if (crypto?.randomUUID) {
    return crypto.randomUUID();
  }
  return uuidv4();
}

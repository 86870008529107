<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="title"
    [canGoBack]="!deletingKey"
    [noBow]="deletingKey"
    [customBack]="'more/developers'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">

    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-error-message
        *ngIf="!loaded && errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="loaded">

        <ng-container *ngIf="!deletingKey">
          <tidy-card>
            <ng-container *ngIf="apiKeys?.length !== 0">

              <tidy-two-column-row>
                <tidy-text
                  [body]="'Viewing live API keys'">
                </tidy-text>
                <tidy-text
                  [actionBody]="'View Documentation'"
                  (action)="goToApiDocumentation()"
                  class="link">
                </tidy-text>
              </tidy-two-column-row>

              <tidy-row *ngFor="let key of apiKeys" class="extra-top-padding">
                <tidy-text
                  [header]="'Token'">
                </tidy-text>

                <tidy-row class="extra-bottom-padding">
                  <tidy-text
                  [body]="key?.token">
                  </tidy-text>
                </tidy-row>

                <tidy-text
                  [header]="'Environment'">
                </tidy-text>
                <tidy-row [alignLastItemRight]="true">
                  <tidy-text
                    [body]="'Production'">
                  </tidy-text>
                  <tidy-text
                    [body]="'Delete Key'"
                    (action)="deleteApiKey(key?.id)"
                    class="link">
                  </tidy-text>
                </tidy-row>
              </tidy-row>
            </ng-container>

            <ng-container *ngIf="apiKeys?.length === 0">
              <tidy-row [align]="'center'" class="extra-bottom-padding">
                <tidy-text
                  [body]="'You have no API keys. Generate one to use the TIDY API or Zapier.'">
                </tidy-text>
              </tidy-row>

              <tidy-button
                [text]="'Create New API Key'"
                [action]="addApiKey.bind(this)"
                class="primary">
              </tidy-button>
            </ng-container>

          </tidy-card>
        </ng-container>

        <ng-container *ngIf="deletingKey">
          <tidy-card>
            <tidy-text
              [body]="'You will be unable to perform any more actions with this key.'">
            </tidy-text>
            <tidy-checkbox
              formControlName="checkbox"
              [form]="form.controls.checkbox"
              [items]="[{viewValue: 'Yes I want to delete this key.', value: true}]">
            </tidy-checkbox>
          </tidy-card>

          <tidy-error-message
            [text]="errorMessage">
          </tidy-error-message>

          <tidy-two-column-row>
            <tidy-button
              [text]="'Go Back'"
              [action]="stopDeletingApiKey.bind(this)"
              class="secondary">
            </tidy-button>

            <tidy-button
              [text]="'Delete Key'"
              [action]="confirmDeleteApiKey.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-two-column-row>
        </ng-container>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  templateUrl: 'confirm-remove-employee.html'
})
export class RemoveEmployeeComponent {

  @Input() confirmSubject: Subject<boolean>;

  confirm() {
    this.confirmSubject.next(true);
  }

  cancel() {
    this.confirmSubject.next(false);
  }
}

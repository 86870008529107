import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { ToDoListRoomModel } from 'src/models/to-do.model';

import { CurrentAddress } from 'src/providers/addresses/current-address';

@Component({
  templateUrl: 'default-lists.html',
  encapsulation: ViewEncapsulation.None
})

export class DefaultListsPage implements OnInit {

  allToDoLists: any;
  addressId: number;
  addressFilter: TidySelectNumberValueModel[];
  addressDefaultLists: ToDoListRoomModel[];
  accountServiceDefaultLists: ToDoListRoomModel[];
  errorMessage: string;
  toDoState: string;
  loaded: boolean;

  constructor(
    private client: Client,
    private currentAddress: CurrentAddress,
    public toDos: ToDos,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private storage: TidyStorage
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.getAddressId();
      this.allToDoLists = await this.toDos.getAllIsCustomerToDoLists();
      await this.parseToDoLists();
      await this.populateAddressFilter();
      this.loaded = true;
    } catch (err) {
    }
  }

  getAddressId() {
    const addressId = localStorage.getItem('addressId');
    this.addressId = parseFloat(addressId);
  }

  parseToDoLists() {
    this.addressDefaultLists = [];
    this.accountServiceDefaultLists = [];
    this.allToDoLists.map((list) => {
      list.default_list_settings.map((key) => {
        const item = {
          default_list_setting: key,
          ...list
        }
        if (key.key.includes('address') && list.address_id == this.addressId) {
          this.addressDefaultLists.push(item);
        } else if (!key.key.includes('address')) {
          this.accountServiceDefaultLists.push(item);
        }
      });
    });
  }

  async populateAddressFilter() {
    this.addressFilter = this.navCtrl.getParam('addressFilter');
    if (this.addressFilter === null) {
      const addresses = await this.client.getAddresses();
      this.addressFilter = await this.client.parseAddressList(addresses, true);
    }
    this.addressFilter.pop();
  }

  @Loading('', true)
  async changeAddress(addressId) {
    this.addressId = addressId;
    this.currentAddress.addressId = addressId.toString();
    await this.parseToDoLists();
  }

  goToEditDefaultList(type, action, list = null) {
    const params = {
      selectedList: list,
      type: type,
      action: action,
      allToDoLists: this.allToDoLists,
      address: this.addressFilter.find((address) => address.value == this.addressId)
    }
    this.navCtrl.navigateForward('edit-default-list', params);
  }

  goToListSettings() {
    this.navCtrl.navigateForward('list-settings');
  }

}

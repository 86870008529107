<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [customBack]="customBack"
    [title]="'Bill'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      
      <tidy-card>
        <tidy-row [alignLastItemRight]="isLoggedIntoClientApp">
          <tidy-text
            [title]="'Bill From'">
          </tidy-text>
          <div *ngIf="isLoggedIntoClientApp" style="margin-right: -5px">
            <tidy-text
              [body]="'Copy Link'"
              (action)="copyBillLink()"
              class="link">
            </tidy-text>
          </div>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proName | titlecase"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proEmail"
            [translate]="false">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="proPhone | telephone">
          </tidy-text>
        </tidy-row>

        <div style="border-left: 1px solid #dddddd; border-right: 1px solid #dddddd; position: relative; background: #F0F6FA; margin-left: -11px; margin-right: -11px; padding: 10px;">
          <div style="position: absolute; top: 0; left: 0; right: 0; height: 7px; background: #275C7C;"></div>
          <div style="padding-top: 7px">
            <tidy-row [alignLastItemRight]="true">
              <div>
                <tidy-text
                  [body]="'To: '">
                </tidy-text>
                <tidy-text
                  [body]="((companyName || clientName) | titlecase)"
                  [translate]="false">
                </tidy-text>
              </div>
              <div style="align-self: end; margin-right: -5px">
                <tidy-text
                  [body]="('Sent ' | translate) +
                  (invoice?.created_at | customdate:'M/D/YY')">
                </tidy-text>
              </div>
            </tidy-row>
          </div>
        </div>

        <tidy-row class="extra-top-padding" [alignLastItemRight]="true">
          <tidy-text
            [body]="'Service'">
          </tidy-text>
          <tidy-text
            [body]="'Owed'">
          </tidy-text>
        </tidy-row>

        <tidy-row>
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <ng-container *ngFor="let service of invoice?.items; let last = last">
          <tidy-row [alignLastItemRight]="true">
            <div style="width:85%">
              <tidy-text
                [header]="service?.service_details?.name | titlecase">
              </tidy-text>
            </div>
            <div>
              <tidy-text
                *ngIf="service?.amount"
                [body]="service?.amount | tcurrency">
              </tidy-text>
              <tidy-text
                *ngIf="!service?.amount"
                [body]="invoice?.amount | tcurrency">
              </tidy-text>
            </div>
          </tidy-row>

          <tidy-row>
            <tidy-text
              [body]="(service?.service_details?.date | customdate:'M/D') +
              ' at ' +
              service?.service_details?.address?.address +
              ', ' +
              (service?.service_details?.address?.unit ? ('Unit ' + service?.service_details?.address?.unit + ', ') : '') +
              service?.service_details?.address?.zip">
            </tidy-text>
          </tidy-row>

          <tidy-row class="extra-top-padding vertical-align-center" *ngIf="!clientHubInfo">
            <tidy-image
              [src]="'assets/img/clock-black.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Job Duration'">
            </tidy-text>
            <tidy-text
              *ngIf="!proReportedDuration && !fromStartDuration"
              [body]="': N/A'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="proReportedDuration">
            <tidy-text
              [body]="'Reported by Pro:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="proReportedDuration">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="fromStartDuration">
            <tidy-text
              [body]="'Using Realtime Updates:'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="fromStartDuration">
            </tidy-text>
          </tidy-row>

          <tidy-row [ngClass] = "last ? '' : 'extra-bottom-padding'">
            <tidy-divider>
            </tidy-divider>
          </tidy-row>
        </ng-container>

        <tidy-row [alignLastItemRight]="true" *ngIf="creditWithPro > 0">
          <tidy-text
            [header]="'Credit with Pro'">
          </tidy-text>
          <tidy-text
            [body]="creditWithPro | tcurrency">
          </tidy-text>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true">
          <tidy-text
            [header]="'Total'">
          </tidy-text>
          <tidy-text
            [body]="'<b>' + ((totalOwed) | tcurrency) + '</b>'">
          </tidy-text>
        </tidy-row>

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'We thank you for your business!'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="no-padding" [align]="'center'">
          <tidy-text
            [body]="proName"
            class="signature"
            [translate]="false">
          </tidy-text>
        </tidy-row>

        <tidy-divider
          style="margin-top:-15px">
        </tidy-divider>

        <ng-container class="no-padding" *ngIf="isLoggedIntoClientApp">
          <tidy-row class="extra-top-padding">
            <tidy-text
              [header]="'Bill Notes'">
            </tidy-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <form [formGroup]="form" novalidate action="#">
              <tidy-select
                style="width:35%; font-size: 8px"
                [form]="form.controls.messageType"
                formControlName="messageType"
                [items]="messageSelectItems"
                [smallText]="true"
                [selectedItem]="selectedMessageType"
                (optionChange)="updateIsInternalComment($event)">
              </tidy-select>
            </form>
          </tidy-row>
          <form [formGroup]="form" novalidate action="#">
            <tidy-textarea
              [label]="isInternalComment ? 'Add Internal Note' : 'Add Note'"
              formControlName="message"
              [form]="form.controls.message"
              [submitted]="submitted"
              [errorMessage]="'Please enter a message to send.'"
              [rightIconAction]="sendMessage.bind(this)"
              [leftIconAction]="sendAttachment.bind(this)"
              [showBlackButtons]="isInternalComment">
            </tidy-textarea>
          </form>
          <tidy-row *ngIf="invoiceNotes?.length == 0" [align]="'center'">
            <tidy-text
              [helper]="'No Bill Notes'">
            </tidy-text>
          </tidy-row>
          <tidy-row></tidy-row>
          <ng-container *ngIf="!showAllInvoiceNotes">
            <ng-container *ngFor="let note of invoiceNotes | slice:0:3; let last = last">
              <message
                [message]="note"
                [last]="last"
                [isInvoiceNote]="true">
              </message>
            </ng-container>
            <tidy-row [align]="'center'" *ngIf="invoiceNotes?.length > 3">
              <tidy-text
                [actionBody]="'Show All Notes'"
                (action)="toggleShowAllInvoiceNotes()"
                class="link">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container  *ngIf="showAllInvoiceNotes">
            <ng-container *ngFor="let note of invoiceNotes; let last = last">
              <message
                [message]="note"
                [last]="last"
                [isInvoiceNote]="true">
              </message>
            </ng-container>
          </ng-container>
        </ng-container>
      </tidy-card>

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
        <tidy-row>
          <tidy-text
            [title]="'Pay Bill'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="creditWithTIDY > 0">
          <tidy-checkbox
            (checkedChange)="toggleUseTidyCredit()"
            [items]="[
              {
                viewValue: 'Use my credit with TIDY', value: true
              }
            ]"
            [initValue]="useTidyCredit">
          </tidy-checkbox>
        </tidy-row>
        <div *ngIf="useTidyCredit" style="display: flex; align-items: center;">
          <tidy-input
            formControlName="amountOfTidyCreditToUse"
            [form]="form.controls.amountOfTidyCreditToUse"
            [mask]="'currencyMask'"
            [errorMessage]="'Please enter an amount.'"
            [submitted]="submitted"
            [icon]="'assets/svg/person-outline.svg'"
            style="margin-right: 10px;"
            (keyup)="checkIfShowPaymentMethodOptions()">
          </tidy-input>
          <tidy-text
            [helper]="'of'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [helper]="creditWithTIDY | tcurrency">
          </tidy-text>
        </div>
        <tidy-row *ngIf="showTooMuchCreditWarning && !showNotEnoughCreditWarning">
          <tidy-text
            [helper]="'You entered more credit than the amount owed. We will only use credit for the amount owed.'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="showNotEnoughCreditWarning">
          <tidy-text
            [body]="'You entered more credit than you have. Please enter up to the amount you have.'"
            class="red">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="showPaymentMethodOptions && !showNotEnoughCreditWarning">
          <tidy-row class="extra-top-padding" [ngClass]="{'extra-bottom-padding': form?.value?.howToPay == 'newCard'}">
            <tidy-radio-button
              *ngIf="useTidyCredit"
              [label]="howToPayLabel"
              formControlName="howToPay"
              [form]="form.controls.howToPay"
              [items]="howToPayItems"
              (optionChange)="updateHowToPay($event)"
              [submitted]="submitted"
              [errorMessage]="'Please select a payment method.'">
            </tidy-radio-button>
            <tidy-radio-button
              *ngIf="!useTidyCredit"
              formControlName="howToPay"
              [form]="form.controls.howToPay"
              [items]="howToPayItems"
              (optionChange)="updateHowToPay($event)"
              [submitted]="submitted"
              [errorMessage]="'Please select a payment method.'">
            </tidy-radio-button>
          </tidy-row>
          <ng-container *ngIf="form?.value?.howToPay == 'newCard'">
            <div class="form-row">
              <div id="card-info" #cardInfo></div>
            </div>
            <tidy-checkbox
              *ngIf="!lastFourCC"
              [(checked)]="saveCardForFuture"
              [items]="[
                {
                  viewValue: 'Save Card For Future Use', value: true
                }
              ]"
              [initValue]="saveCardForFuture">
            </tidy-checkbox>
          </ng-container>
          <ng-container *ngIf="!isAuthenticated && (form?.value?.howToPay == 'currentCard' || (form?.value?.howToPay == 'newCard' && saveCardForFuture))">
            <tidy-row class="extra-top-padding extra-bottom-padding" [ngClass]="form?.value?.howToPay == 'newCard' ? 'extra-top-padding' : ''">
              <tidy-text
                [header]="getCardTitle()">
              </tidy-text>
            </tidy-row>
              <ng-container *ngIf="!hasAccount">
                <tidy-row class="field-top-padding field-bottom-padding">
                  <tidy-input
                    formControlName="firstName"
                    [form]="form.controls.firstName"
                    [label]="'First Name'"
                    [errorMessage]="'Please enter your first name.'"
                    [submitted]="submitted"
                    [icon]="'assets/svg/person-outline.svg'">
                  </tidy-input>
                </tidy-row>
                <tidy-row class="field-bottom-padding">
                  <tidy-input
                    formControlName="lastName"
                    [form]="form.controls.lastName"
                    [label]="'Last Name'"
                    [errorMessage]="'Please enter your last name.'"
                    [submitted]="submitted"
                    [icon]="'assets/svg/person-outline.svg'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
              <tidy-row class="field-bottom-padding" [ngClass]="hasAccount ? 'field-top-padding' : ''">
                <tidy-input
                  formControlName="email"
                  [form]="form.controls.email"
                  [label]="'Email'"
                  [icon]="'assets/svg/mail-outline.svg'"
                  [errorMessage]="'Please enter a valid email.'"
                  [submitted]="submitted"
                  type="email">
                </tidy-input>
              </tidy-row>
              <tidy-row>
                <tidy-input
                  formControlName="password"
                  [form]="form.controls.password"
                  [label]="'Password'"
                  [icon]="'assets/svg/lock-closed-outline.svg'"
                  [type]="'password'"
                  [errorMessage]="'Please enter a password.'"
                  [submitted]="submitted">
                </tidy-input>
              </tidy-row>
              <ng-container *ngIf="!hasAccount">
                <tidy-row class="extra-top-padding vertical-align-center">
                  <!-- TODO address inline styles: https://trello.com/c/XpKJZqE7/9010-f-ca-address-inline-styles-->
                  <tidy-checkbox
                    style="margin-right: 5px"
                    formControlName="terms"
                    [form]="form.controls.terms"
                    [items]="[{viewValue: '', value: true}]">
                  </tidy-checkbox>
                  <tidy-text
                    [body]="'I agree to the'">
                  </tidy-text><span>&nbsp;</span>
                  <tidy-text
                    (action)="openTerms()"
                    [body]="'terms'"
                    class="link">
                  </tidy-text>
                </tidy-row>
              </ng-container>
              <tidy-row *ngIf="termsError">
                <tidy-error-message
                  [text]="'You must agree to the terms to continue.'">
                </tidy-error-message>
              </tidy-row>
          </ng-container>
          <ng-container *ngIf="form?.value?.howToPay === 'alreadyPaid'">
            <tidy-row class="field-top-padding">
              <tidy-select
                [label]="'Select payment method'"
                [form]="form.controls.otherOptions"
                formControlName="otherOptions"
                [items]="otherOptionItems"
                (optionChange)="getSelectedOtherOption($event)"
                [errorMessage]="'Please select a payment method.'"
                [submitted]="submitted">
              </tidy-select>
            </tidy-row>
            <tidy-row class="extra-top-padding" *ngIf="selectedOtherOption?.third_party_account_name || selectedOtherOption?.notes">
              <tidy-text
                [header]="'Pay With'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [header]="selectedOtherOption?.team_payment_option_type?.name">
              </tidy-text>
            </tidy-row>
            <tidy-row class="extra-bottom-padding" *ngIf="selectedOtherOption?.third_party_account_name">
              <tidy-text
                [body]="'Username:'">
              </tidy-text>
              <tidy-text
                [body]="selectedOtherOption?.third_party_account_name +  ' '"
                [translate]="false">
              </tidy-text>
              <tidy-text
                [body]="'Copy'"
                class="link"
                (action)="copyToClipBoard(selectedOtherOption?.third_party_account_name)">
              </tidy-text>
              <ng-container *ngIf="copied">
                <span>&nbsp;</span>
                <tidy-text
                  [helper]="'Copied'">
                </tidy-text>
              </ng-container>
            </tidy-row>
            <tidy-row *ngIf="selectedOtherOption?.notes">
              <tidy-text
                [body]="selectedOtherOption?.notes">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <tidy-row *ngIf="showACHDebitMessage">
            <tidy-text
              [body]="'You can load credit via ACH debit or credit card '">
            </tidy-text>
            <tidy-text
              [actionBody]="'here'"
              class="link"
              (action)="goToLoadCredit()">
            </tidy-text>
          </tidy-row>
        </ng-container>
        </form>
      </tidy-card>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="form.value.howToPay ==='newCard'">
        <div style="display: flex; align-content: center; justify-content: center;">
          <re-captcha (resolved)="captchaEvents($event)"></re-captcha>
        </div>
        <tidy-error-message
          *ngIf="captchaErrorMessage"
          [text]="'Please check that you are not a robot.'">
        </tidy-error-message>
      </ng-container>

      <tidy-button
        [disabled]="showNotEnoughCreditWarning || showACHDebitMessage"
        [action]="payInvoice.bind(this)"
        [text]="form?.value?.howToPay === 'alreadyPaid' ? 'Mark as Paid' : 'Pay Bill'"
        class="primary">
      </tidy-button>

    </ng-container>

    <ng-container *ngIf="!loaded">
      <tidy-card>
        <tidy-row class="extra-bottom-padding">
          <tidy-grid>
            <tidy-row>
              <ion-row>
                <ion-col size="6">
                  <tidy-row>
                    <ion-skeleton-text animated="true" style="width: 150px; height: 20px"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row>
                    <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
                  </tidy-row>
                  <tidy-row>
                    <ion-skeleton-text animated style="width: 105px"></ion-skeleton-text>
                  </tidy-row>
                </ion-col>
              </ion-row>
            </tidy-row>
          </tidy-grid>
        </tidy-row>

        <div style="height: 50px; border-left: 1px solid #dddddd; border-right: 1px solid #dddddd; position: relative; background: #F0F6FA; margin-left: -11px; margin-right: -11px; padding: 10px;">
          <div style="position: absolute; top: 0; left: 0; right: 0; height: 7px; background: #275C7C;"></div>
        </div>

        <tidy-row>
          <ion-skeleton-text animated style="width: 60px"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 90px"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 80px; height: 15px"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 110px"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <tidy-divider>
          </tidy-divider>
        </tidy-row>

        <!--TODO
        <tidy-row [alignLastItemRight]="true" class="extra-top-padding">
          <tidy-text
            [header]="'Credit Applied'">
          </tidy-text>
          <tidy-text
            [body]="'-$20'">
          </tidy-text>
        </tidy-row>

        <tidy-row [alignLastItemRight]="true" class="extra-top-padding extra-bottom-padding">
          <tidy-text
            [header]="'Payment Recorded'">
          </tidy-text>
          <tidy-text
            [body]="'-$10'">
          </tidy-text>
        </tidy-row>-->

        <tidy-row>
          <ion-skeleton-text animated style="width: 60px; height: 15px"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 180px; margin: auto"></ion-skeleton-text>
        </tidy-row>

        <tidy-row>
          <ion-skeleton-text animated style="width: 270px; height: 35px; margin: auto"></ion-skeleton-text>
        </tidy-row>

        <tidy-divider>
        </tidy-divider>
      </tidy-card>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text animated style="width: 115px; height: 20px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 150px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 180px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 120px"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text animated style="width: 115px"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text animated style="height: 50px"></ion-skeleton-text>
      </tidy-row>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
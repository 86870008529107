import { EventEmitter, Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SuccessPage, SuccessPageParamsModel } from 'src/shared/pages/success/success';

@Injectable({
  providedIn: 'root'
})
export class TimeoutErrorHandler {

  private readonly defaultModalOpts: ModalOptions = {
    component: SuccessPage,
    backdropDismiss: false,
    cssClass: 'tidy-transparent-modal'
  };

  readonly offlineParams: SuccessPageParamsModel = {
    header: 'Connect to the Internet to Continue',
    body: 'The app works offline for some things, but not everything.  Connect to the internet to take the next steps.',
    buttonText: 'Try Again - I\'m Online'
  };

  readonly timeoutParams: SuccessPageParamsModel = {
    header: 'Timeout Error',
    body: 'There was a &quottimeout error&quot. Most of the time this means a bad internet connection so please try again.\
           Contact the TIDY Concierge if you think it is a different issue.',
    buttonText: 'Try Again'
  };

  constructor(
    private modalCtrl: ModalController
  ) {}

  async showOfflineAlert(dismiss: EventEmitter<boolean>, retryPage: string, retrySubject: Subject<void> = null) {
    const successParams: SuccessPageParamsModel = {
      ...this.offlineParams,
      buttonRoute: retryPage,
      dismiss,
      actionSubject: retrySubject
    };
    const modal = await this.modalCtrl.create({
      ...this.defaultModalOpts,
      componentProps: {modalParams: successParams},
    });
    modal.present();
  }

  async showTimeoutAlert(dismiss: EventEmitter<boolean>, retryAction: () => Promise<any>, retrySubject: Subject<void> = null) {

    const successParams: SuccessPageParamsModel = {
      ...this.timeoutParams,
      customAction: retryAction,
      dismiss,
      actionSubject: retrySubject
    };
    const modal = await this.modalCtrl.create({
      ...this.defaultModalOpts,
      componentProps: {modalParams: successParams}
    });

    modal.present();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Events } from 'src/providers/events/events';
import { Menu } from 'src/providers/menu/menu';

@Injectable()
export class ActivateMenuGuard implements CanActivate {

  constructor(
    private events: Events,
    private menu: Menu
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.events.publish('menu:open');
    return true;
  }

}

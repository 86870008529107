import { Component, OnInit } from "@angular/core";

import { EmployerIntegrationsProvider } from 'src/providers/employer-integrations/employer-integrations';
import { Events } from 'src/providers/events/events';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { Loading } from 'src/shared/components/loading/loading';

declare const MergeLink: any;

@Component({
  templateUrl: 'settings.html'
})

export class EmployerSettingsPage implements OnInit {

  readonly desktopWidthContent: string = scrollContentWidth.LARGE;
  errorMessage: string;
  isLoadingMerge: boolean;
  integrations: any;
  loaded: boolean;

  constructor(
    private employerIntegrationsProvider: EmployerIntegrationsProvider,
    private events: Events,
    private navCtrl: CustomNavController,
  ) {}

  async ngOnInit() {
    try {
      this.loaded = false;
      this.integrations = await this.employerIntegrationsProvider.getIntegrations();
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async showMergeWidget() {
    if (this.isLoadingMerge) {
      return;
    }
    this.isLoadingMerge = true;
    try {
      const linkToken = await this.employerIntegrationsProvider.getMergeLinkToken();
      MergeLink.initialize({
        linkToken: linkToken.link_token,
        onSuccess: (publicToken) => (this.onSelectedIntegration(publicToken))
      });
      MergeLink.openLink();
      this.isLoadingMerge = false;
    } catch (err) {
      this.isLoadingMerge = false;
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async onSelectedIntegration(publicToken) {
    try {
      await this.employerIntegrationsProvider.createMergeIntegration(publicToken);
      this.goToSuccessIntegratedAdded();
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  };

  goToSuccessIntegratedAdded() {
    return {
      header: 'Integration Added',
      body: 'It may take up to 5 minutes before employees appear in your dashboard. Please reach out to your success representative with any questions.',
      buttonText: 'Ok',
      buttonRoute: 'employer-dashboard/employer-dashboard'
    };
  }

}

import { Pros } from 'src/providers/pros/pros';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from '@angular/material/tree';
import { Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { AddressModel } from 'src/models/address.model';
import {
  Filter,
  ItemNode,
  FlatNode,
  ChipFilter,
  SelectedFilters,
} from 'src/models/schedule.model';
import { SelectCategoryPage } from 'src/pages/select-category/select-category';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { BookJob } from 'src/providers/book-job/book-job';
import { DateTime } from 'luxon';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';

@Component({
  templateUrl: 'schedule-weekly-filter.html',
  styleUrls: ['./schedule-weekly-filter.scss'],
  selector: 'tidy-schedule-weekly-filter',
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleWeeklyFilterComponent implements OnInit, OnChanges {

  @Input() loaded: boolean;
  @Input() addresses: AddressModel[];
  @Input() showLoadingSpinner: any;
  @Input() selectedFilters: SelectedFilters;
  @Input() selectedPeriodRange: any;
  @Output() onSearch = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<Filter>();
  @Output() onGoToPreviousPeriod = new EventEmitter<any>();
  @Output() onGoToNextPeriod = new EventEmitter<any>();
  @Output() onChangeScheduleViewMode = new EventEmitter<any>();
  private _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
    };
  };
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  form: UntypedFormGroup;
  chips: ChipFilter[] = [];
  filterNamesReferences = {
    Status: 'status',
    Type: 'type',
    Service: 'service',
    Address: 'address',
    'Property Name': 'propertyName',
    Pro: 'pro',
  };
  scheduleViewModeList = [
    { viewValue: '7 Days', value: 'SEVEN_DAYS' },
    { viewValue: 'Week', value: 'WEEK_MON_SUN' },
    { viewValue: 'Month', value: 'MONTHLY' },
  ];
  pros: any[] = [];
  language: string;
  scheduleViewMode: string;
  
  constructor(
    private bookJobService: BookJob,
    private fb: FormBuilder,
    private navCtrl: CustomNavController,
    private schedule: Schedule,
    private storage: TidyStorage,
    private prosProvider: Pros,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      addresses: ['', Validators.required],
      search: [''],
      scheduleViewMode: [''],
    });
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.onSearch.emit(val));
  }

  async ngOnInit(): Promise<void> {
    this.language = localStorage.getItem('language');
    await this.getPros();
    this.getTreeData();
    this.scheduleViewMode = await this.storage.retrieve('scheduleViewMode') || 'SEVEN_DAYS';
    this.form.get('scheduleViewMode').setValue(this.scheduleViewMode);
    this.selectedFilters = {
      status: [],
      type: [],
      service: [],
      address: [],
      propertyName: [],
      pro: [],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addresses) {
      this.getTreeData();
    }
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  async getPros(): Promise<void> {
    this.pros = await this.prosProvider.getAllPros();
  }

  getTreeData(): void {
    const treeData = [
      {
        name: 'Status',
        children: [
          {
            name: 'Scheduled',
            value: 'scheduled',
            checked: false
          },
          {
            name: 'In Progress',
            value: 'in_progress',
            checked: false
          },
          {
            name: 'Having Issues',
            value: 'having_issues',
            checked: false
          },
          {
            name: 'Requested',
            value: 'request_waiting_reply',
            checked: false,
          },
          {
            name: 'Not Accepted',
            value: 'request_failed',
            checked: false
          },
          {
            name: 'Completed',
            value: 'completed',
            checked: false
          },
          {
            name: 'Cancelled',
            value: 'cancelled',
            checked: false,
          },
          {
            name: 'Pro Cancelled',
            value: 'homekeeper_cancelled',
            checked: false,
          }
        ],
      },
      {
        name: 'Type',
        children: [
          {
            name: 'Job',
            value: 'job',
            checked: false,
          },
          {
            name: 'Reservation',
            value: 'reservation',
            checked: false,
          },
        ],
      },
      {
        name: 'Service',
        children: this.getServiceTree(),
      },
      {
        name: 'Address',
        children: this.getAddressTree('address1'),
      },
      {
        name: 'Property Name',
        children: this.getAddressTree('address_name'),
      },
      {
        name: 'Pro',
        children: this.getProsTree(this.pros),
      },
    ];
    treeData.forEach((parent, index) => {
      parent.children?.forEach((child) => {
        child.parentId = index;
      });
    });
    const filteredTreeData = treeData.filter((parent) => parent.children?.length > 0);
    this.dataSource.data = filteredTreeData;
  }

  getServiceTree(): ItemNode[] {
    return Object.keys(this.schedule.categoriesImages).map((key) => {
      return {
        name: key.replace(/_/g, ' '),
        value: key,
        image: this.schedule.categoriesImages[key],
        checked: false,
      };
    });
  }

  getAddressTree(key: string): ItemNode[] {
    return this.addresses
      ?.map((address) => {
        return {
          name: address[key],
          value: key === 'address1' || key === 'address_name' ? address.id : address[key],
          checked: false,
        };
      })
      .filter((address) => address?.name);
  }

  getProsTree(pros: any[]): ItemNode[] {
    return pros
      .map((pro) => {
        return {
          name: pro?.first_name + ' ' + pro?.last_name,
          value: pro?.id,
          checked: false,
        };
      })
      .filter((pro) => pro?.name);
  }

  checkNode(node: ItemNode, event: MatCheckboxChange): void {
    node.checked = event.checked;
    this.updateFilters(node, event.checked);
  }

  getNodeVariables(node: ItemNode) {
    const foundParentName = this.dataSource.data[node.data.parentId].name;
    const foundNodeValue = this.dataSource.data[
      node.data.parentId
    ].children.find((child) => child.name === node.name).value;
    const matchedParentName = this.filterNamesReferences[foundParentName];
    return { foundParentName, foundNodeValue, matchedParentName };
  }

  updateFilters(node: ItemNode, isEnabled: boolean): void {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundParentName, foundNodeValue, matchedParentName } =
      this.getNodeVariables(node);
    const chip = {
      displayParentName: foundParentName,
      parentName: matchedParentName,
      name: node.name,
      value: foundNodeValue,
    };
    if (isEnabled) {
      this.addChip(chip);
    } else {
      this.removeChip(chip, foundParentName);
    }
  }

  addChip(chip: ChipFilter): void {
    this.selectedFilters[chip.parentName].push(chip.value);
    this.chips.push(chip);
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  updateTree(): void {
    this.dataSource.data.forEach((parent) => {
      parent.children.forEach((child) => {
        if (
          this.selectedFilters[
            this.filterNamesReferences[parent.name]
          ].includes(child.value)
        ) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    });
  }

  removeChip(chip: ChipFilter, parentName: string): void {
    this.selectedFilters[chip.parentName] = this.selectedFilters[
      chip.parentName
    ].filter((value) => value !== chip.value);
    this.chips = this.chips.filter(
      (item) => item.parentName !== parentName && item.value !== chip.value
    );
    this.onFilter.emit({
      selectedFilters: this.selectedFilters,
      lastSelectedFilter: chip.parentName,
    });
    this.updateTree();
  }

  getNodeChecked(node: ItemNode): boolean {
    if (!this.dataSource?.data?.[node?.data?.parentId]?.name) {
      return;
    }
    const { foundNodeValue, matchedParentName } = this.getNodeVariables(node);
    return this.selectedFilters[matchedParentName].includes(foundNodeValue);
  }

  bookJobSelectAddress() {

    if (this.addresses.length == 1) {
      return this.goToBookJob();
    }
    this.storage.save('bookJobBackPage', 'schedule');
    const params = {
      isSelectingAddressToBook: true,
      bookingType: 'add_job'
    }
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      this.storage.save('dialog-right-side-open', true);
      this.storage.save('dialog-params', params);
      this.rightSidePanelService.openRightSidePanel(SelectCategoryPage);
    } else {
      this.navCtrl.navigateForward('select-category', params);
    }
  }

  async goToBookJob() {
    this.bookJobService.clearBookJobStorage();
    this.storage.save('bookJobBackPage', 'schedule');
    const address = this.addresses[0];
    const hasPrivatePro = await this.prosProvider.checkIfHasPrivatePro();
    const isRentalClient = localStorage.getItem('isRentalClient') == 'true';
    const bookingType = 'add_job';
    const isMobileResolution = window.innerWidth <= 870;
    const params = {
      address,
      bookingType,
    };
    if (hasPrivatePro || isRentalClient) {
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(SelectCategoryPage);
      } else {
        this.navCtrl.navigateForward('select-category', params);
      }
    } else {
      if (!isMobileResolution) {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(BookJobPage);
      } else {
        this.navCtrl.navigateForward('book-job', { address, bookingType });
      }
    }
  }

  goToPreviousPeriod() {
    this.onGoToPreviousPeriod.emit();
  }

  goToNextPeriod() {
    this.onGoToNextPeriod.emit();
  }

  changeScheduleViewMode(event: any): void {
    const selectedValue = event?.value || event;
    this.scheduleViewMode = selectedValue;
    this.onChangeScheduleViewMode.emit(selectedValue);
  }
  
  getPeriodRangeText() {
    const format = this.language && this.language !== 'en' ? 'MMMM d' : 'MMM d';
    let startDate = DateTime.fromFormat(this.selectedPeriodRange.startDate, 'yyyy-MM-dd').toFormat(format);
    let endDate = DateTime.fromFormat(this.selectedPeriodRange.endDate, 'yyyy-MM-dd').toFormat(format);
    
    // Format the display based on view mode
    if (this.scheduleViewMode === 'WEEK_MON_SUN') {
      // Format for Mon-Sun week view
      if (this.language && this.language !== 'en') {
        const startMonth = startDate.split(' ')[0];
        const startDay = startDate.split(' ')[1];
        const endMonth = endDate.split(' ')[0]; 
        const endDay = endDate.split(' ')[1];
        
        // If start and end are in the same month
        if (startMonth === endMonth) {
          return `${new TranslationPipe().transform(startMonth)} ${startDay}-${endDay}`;
        } else {
          return `${new TranslationPipe().transform(startMonth)} ${startDay} - ${new TranslationPipe().transform(endMonth)} ${endDay}`;
        }
      } else {
        const startObj = DateTime.fromFormat(this.selectedPeriodRange.startDate, 'yyyy-MM-dd');
        const endObj = DateTime.fromFormat(this.selectedPeriodRange.endDate, 'yyyy-MM-dd');
        
        // If start and end are in the same month
        if (startObj.month === endObj.month) {
          return `${startObj.toFormat('MMM')} ${startObj.day}-${endObj.day}, ${startObj.year}`;
        } else {
          return `${startDate} - ${endDate}`;
        }
      }
    }
    
    if (this.language && this.language !== 'en') {
      const startMonth = startDate.split(' ')[0];
      const startDay = startDate.split(' ')[1];
      const endMonth = endDate.split(' ')[0]; 
      const endDay = endDate.split(' ')[1];
      return `${new TranslationPipe().transform(startMonth).slice(0,3)} ${startDay} - ${new TranslationPipe().transform(endMonth).slice(0,3)} ${endDay}`;
    } else {
      return `${startDate} - ${endDate}`;
    }
  }
  
}

import { Component, OnInit } from '@angular/core';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { PendingInvoicePage } from 'src/pages/invoices/pending-invoice/pending-invoice';

@Component({
  templateUrl: 'send-payment-or-pay-invoice.html'
})

export class SendPaymentOrPayInvoicePage implements OnInit {

  dialogParams: any;
  errorMessage: string;
  isRightSideContent: boolean;
  pendingInvoices: any;
  loaded: boolean;
  pro: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage
  ) {}

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Send Payment');
    }
    this.pendingInvoices = this.navCtrl.getParam('pendingInvoices') || this.dialogParams.pendingInvoices;
    this.pro = this.navCtrl.getParam('pro') || this.dialogParams.pro;
    this.loaded = true;
  }

  async goToSendPayment() {
    const params = {
      pro: this.pro,
      allowsInvoiceAutopay: this.navCtrl.getParam('allowsInvoiceAutopay') || this.dialogParams.allowsInvoiceAutopay,
      privatePlanId: this.navCtrl.getParam('privatePlanId') || this.dialogParams.privatePlanId,
      address: this.navCtrl.getParam('address') || this.dialogParams.address
    }
    this.rightSidePanelService.navigateTo('send-payment', params);
  }

  async goToInvoice(invoice) {
    try {
      const settings = await this.client.getClientSettings();
      const params = {
        invoice: invoice,
        hasAccount: true,
        proName: this.pro.homekeeper.first_name,
        proEmail: this.pro.homekeeper.email,
        proPhone: this.pro.homekeeper.phone,
        proTeamId: this.pro.team.id,
        proId: this.pro.homekeeper.id,
        clientName: settings.profile.first_name,
        clientEmail: settings.profile.email,
        invoiceId: invoice.id
      }
      this.rightSidePanelService.navigateTo(`pending-bill/${invoice.id}`, params, PendingInvoicePage);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBillsPage() {
    this.navCtrl.navigateRoot('bills');
  }

}

<mat-form-field
  [class.invalid-field]="!disableElement && !form.valid && submitted"
  appearance="outline"
  style="border-color: transparent !important; background-color: transparent !important">
  <mat-label *ngIf="label">
    <tidy-image [src]="icon" *ngIf="icon" class="outline-field-label-icon"></tidy-image>
    <tidy-text [body]="label"> </tidy-text>
  </mat-label>
  <mat-select
    [ngClass]="smallText ? 'small-text' : 'big-text'"
    [disabled]="disableElement"
    [formControl]="form"
    [value]="value"
    (valueChange)="value = $event"
    (selectionChange)="onChange($event)"
    [multiple]="multiple">
    <mat-select-trigger *ngIf="selectedItem?.icon">
      <tidy-image [src]="selectedItem?.icon" style="width:10px"></tidy-image>{{ selectedItem?.viewValue | translate }}
    </mat-select-trigger>
    <mat-option *ngIf="showSearch">
      <ngx-mat-select-search
        [formControl]="searchControl"
        (ngModelChange)="searchItems()"
        [placeholderLabel]="searchPlaceholder">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of listedItems" [value]="item.value" [disabled]="item.value === 'divider'">
      <tidy-image *ngIf="item?.icon" [src]="item?.icon" style="width:10px"></tidy-image>
      {{ item.viewValue | translate }}
      <span *ngIf="item.isRecommended" style="color: #4CAF50; font-size: 12px; margin-left: 8px;">(Recommended)</span>
    </mat-option>
  </mat-select>
</mat-form-field>
<tidy-error-message
  *ngIf="!disableElement && !form.valid && submitted"
  [text]="errorMessage">
</tidy-error-message>

import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';
import { Subscription } from 'rxjs';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';

import { Loading } from 'src/shared/components/loading/loading';
import { Menu } from 'src/providers/menu/menu';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Webhooks } from 'src/providers/webhooks/webhooks';
import { Workflows } from "src/providers/workflows/workflows";
import { WindowService } from 'src/shared/providers/window.service';
import { Util } from 'src/shared/util/util';

import { TidyDateFormatPipe } from 'src/shared/pipes/tidy-date.pipe';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';  // Add this import

import { DateTime as LuxonDateTime } from 'luxon';

import { WorkflowModel } from "src/models/workflow.model";

import { WorkflowsPage } from 'src/pages/workflows/workflows';
import { WorkflowPage } from 'src/pages/workflows/workflow/workflow';
import { AutomaticBookingPage } from 'src/pages/more/automatic-booking/automatic-booking';
import { AccountNotificationSettingsPage } from 'src/pages/more/account-notification-settings/account-notification-settings';
import { EditWorkflowPage } from 'src/pages/workflows/edit-workflow/edit-workflow';
import { AddWorkflowPage } from 'src/pages/workflows/add-workflow/add-workflow';
import { WorkflowHistoryPage } from 'src/pages/workflows/workflow-history/workflow-history';
import { JobRequestWorkflows } from 'src/pages/job-request-workflows/job-request-workflows';
import { OnboardingProvider } from 'src/providers/onboarding/onboarding.provider';

interface chartDataInterface {
  total_run_count: any;
  total_action_run_count: any;
  breakdown_by_days: dayBreakdownInterface;
}

interface dayBreakdownInterface {
  [date: string]: {
    run_count: number;
    action_run_data: {
      cost: number;
      count: number;
      automation: {
        cost: number;
        count: number;
      },
      concierge: {
        cost: number;
        count: number;
      },
      free: {
        cost: number;
        count: number;
      }
    }
  }
}

@Component({
  selector: 'automations-summary',
  templateUrl: 'automations-summary.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['automations-summary.scss']
})

export class AutomationsSummaryPage implements OnInit, OnDestroy {

  activeWorkflows: any;
  automaticBookingAddresses: any;
  errorMessage: string;
  isPausingUnpausingWorkflow: boolean;
  loaded: boolean;
  notificationSettings: any;
  routeReused: boolean;
  salesText: any;
  showLoadingSpinner: boolean;
  readonly desktopWidthContent: string = scrollContentWidth.NORMAL;
  public chartOptions: any;
  workflowHistory: any;
  workflowHistoryLength: any;
  workflows: any;
  chartData: chartDataInterface;
  panelClosedSubscription: Subscription;
  showOnboarding: boolean;
  hasProperties: boolean;
  onboardingFlow: string;
  showOnboardingSubscription$: Subscription;
  didCheckOnboarding: boolean;

  constructor(
    private client: Client,
    private menu: Menu,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private translationPipe: TranslationPipe,
    private webhooksService: Webhooks,
    private workflowsProvider: Workflows,
    public windowService: WindowService,
    private tidyDateFormatPipe: TidyDateFormatPipe,
    private util: Util,
    private onboardingProvider: OnboardingProvider
  ) {}

  async ngOnInit() {
    try {
      this.salesText = this.getSalesText();
      await this.checkIfShouldShowOnboarding();
      await this.loadPage();
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadPage(showLoadingSpinner = false) {
    if (showLoadingSpinner) this.showLoadingSpinner = true;
    if (this.routeReused) {
      await Promise.all([
        this.loadAccountNotifications(),
        this.loadAutomaticBookingAddresses(),
        this.loadWorkflows(),
        this.getInitialChart()
      ]);
    } else {
      this.loadAccountNotifications();
      this.loadAutomaticBookingAddresses();
      this.loadWorkflows();
      await this.getInitialChart();
    }
    this.generateChart();
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
    this.showLoadingSpinner = false;
    if (this.windowService.isDesktopRes) {
      this.storage.setStoredRoute('/automations/summary').then(async () => {
        this.routeReused = true;
        await this.loadPage(true);
      });
    }
  }

  async checkIfShouldShowOnboarding() {
    this.onboardingFlow = await this.storage.retrieve('onboardingFlow');
    if (!this.onboardingFlow) {
      return this.showOnboarding = false;
    }
    this.showOnboarding = await this.onboardingProvider.checkIfShouldShowOnboarding(this.onboardingFlow);
    if (this.showOnboarding) {
      this.onboardingProvider.setShowOnboardingOnPage(true);
      this.hasProperties = await this.onboardingProvider.checkIfHasProperties();
      this.watchShowOnboarding();
      await this.storage.delete('onboardingFlow');
    }
    this.didCheckOnboarding = true;
  }

  watchShowOnboarding() {
    this.showOnboardingSubscription$ = this.onboardingProvider.getShowOnboardingOnPage().subscribe((show) => {
      this.showOnboarding = show;
      if (!this.showOnboarding) {
        this.loadPage(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.showOnboardingSubscription$) {
      this.showOnboardingSubscription$.unsubscribe();
    }
  }

  async loadAccountNotifications() {
    try {
      const withUrl = false;
      let webhooks = await this.webhooksService.getWebhooks();
      webhooks = webhooks.filter((webhook) => !webhook?.customer_account_team);
      this.notificationSettings = await this.webhooksService.buildNotificationSettingsArray(webhooks, withUrl);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadAutomaticBookingAddresses() {
    try {
      const addressResponse = await this.client.getMoreDetailAddresses();
      this.automaticBookingAddresses = addressResponse.filter((address) => address?.address_automatic_booking_setting?.automatic_booking_enabled);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async loadWorkflows() {
    try {
      this.workflows = await this.workflowsProvider.getWorkflows();
      this.activeWorkflows = this.workflows.filter((workflow) => workflow.paused_at == null)
      const workflowIds = this.workflows.map((workflow) => workflow.id);
      const today = LuxonDateTime.local();
      const endDate = today.toISODate();
      const startDate = today.minus({ days: 7 }).toISODate();
      const pageNumber = 1;
      const pageSize = 3;
      const searchTerm = null;
      const response = await this.workflowsProvider.getWorkflowVersionRuns(workflowIds, startDate, endDate, pageNumber, pageSize, searchTerm);
      this.workflowHistory = response.body;
      this.workflowHistoryLength = response.totalRecords || 100;
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

  async getInitialChart() {
    const endDate = LuxonDateTime.now();
    const startDate = LuxonDateTime.now().minus({ days: 7 });
    this.chartData = await this.workflowsProvider.getUsageSummary(startDate.toISODate(), endDate.toISODate());
  }

  getSalesText() {
    return {
      workflows: [
        {
          header: 'Automate with Software',
          description: 'Trigger workflows based on any event.',
          example: 'Example: \"When a job is completed, prepare an invoice in a particular format.\"',
          image: 'assets/img/workflow-robot.svg'
        },
        {
          header: 'Automate with Our Humans',
          description: 'Our concierge can complete things for you. A \"human-in-the loop\" means we can solve workflows other\'s can\'t.',
          example: 'Example: \"Review before/after photos to see if they match my criteria.\"',
          image: 'assets/img/workflow-team.svg'
        },
        {
          header: 'Any Complex Workflow',
          description: 'Really, we can automate almost any cleaning and maintenance workflow.',
          example: 'Example: \"Schedule inspection, then based on the result of the inspection book a series of jobs to resolve the issues in the inspection and wrap it up with a cleaning.\"',
          image: 'assets/img/workflow-complexity.svg'
        }
      ],
      automaticBooking: [
        {
          header: 'Automate Turnovers',
          description: 'Automatically create job requests in between guests/tenants to get a turnover done.',
          example: 'Example: Schedule a cleaning after a guest checks out before the next one checks in.',
          image: 'assets/img/automatic-booking.svg'
        }
      ],
      automaticNotifications: [
        {
          header: 'Get Notified',
          description: 'Get notifications based on events in TIDY.',
          example: 'Example: Get notified by webhook when a job is complete.',
          image: 'assets/img/bell.svg'
        }
      ]
    };
  }

  goToAddConciergeTask() {
    const params = {
      title: 'Add Automation',
      type: 'support.concierge_task',
      isWorkflow: true
    }
    this.navCtrl.navigateForward('contact-concierge', params);
  }

  generateChart(): void {
    this.chartOptions = {
      series: [
        {
          name: this.translationPipe.transform('Total Workflows Triggered'),
          data: Object.values(this.chartData.breakdown_by_days).map(
            (day) => day.run_count
          ),
        },
        {
          name: this.translationPipe.transform('Total Automated Tasks'),
          data: Object.values(this.chartData.breakdown_by_days).map(
            (day) => day.action_run_data.automation.count
          ),
        },
        {
          name: this.translationPipe.transform('Total Concierge Actions'),
          data: Object.values(this.chartData.breakdown_by_days).map(
            (day) => day.action_run_data.concierge.count
          ),
        },
      ],
      chart: {
        type: 'bar',
        height: 274,
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: Object.keys(this.chartData.breakdown_by_days).map(
          (dateStr) => {
            return this.tidyDateFormatPipe.transform(dateStr, 'LLL dd');
          }
        ),
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      }
    };
  }

  @Loading('', true)
  async pauseUnpauseWorkflow(workflow) {
    this.isPausingUnpausingWorkflow = true;
    try {
      if (!workflow.published_version) {
        this.util.showError('Please publish the workflow to unpause it.', 10000);
      }
      if (workflow.paused_at) {
        await this.workflowsProvider.unpauseWorkflow(workflow.id);
        workflow.paused_at = null;
      } else {
        await this.workflowsProvider.pauseWorkflow(workflow.id);
        workflow.paused_at = new Date();
      }
      setTimeout(() => {
        this.isPausingUnpausingWorkflow = false;
      }, 200);
    } catch (err) {
      this.util.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
      this.loadWorkflows();
    }
  }

  async goToWorkFlow(workflow: WorkflowModel) {
    setTimeout(async () => {
      if (this.isPausingUnpausingWorkflow) {
        return this.isPausingUnpausingWorkflow = false;
      }
      const workflowWithVersions = await this.workflowsProvider.getWorkflowWithVersions(workflow.id);
      const params = {
        workflow: workflowWithVersions
      }
      if (workflowWithVersions.published_version) {
        this.goToPage(`/automations/workflow/${workflow.id}`, params, WorkflowPage);
      } else {
        this.goToPage(`/automations/workflow/${workflow.id}/edit`, params, EditWorkflowPage);
      }
    }, 200);
  }

  goToAddWorkflow() {
    localStorage.removeItem('newWorkflowId');
    const params = {
      numberOfWorkflows: this.workflows.length
    }
    this.goToPage(`/automations/workflow/new/start`, params, AddWorkflowPage);
  }

  goToWorkflows() {
    this.goToPage(`/automations/workflows`, {}, WorkflowsPage);
  }

  goToAutomaticBooking() {
    this.goToPage(`/automations/automatic-booking`, {}, AutomaticBookingPage);
  }

  goToNotifications() {
    this.goToPage(`/automations/account-notifications`, { backPage: 'automations/summary' }, AccountNotificationSettingsPage);
  }

  goToJobRequestWorkflows() {
    this.goToPage(`/job-request-workflows`, {}, JobRequestWorkflows);
  }

  goToWorkflowHistory() {
    this.goToPage('automations/workflow-history', {}, WorkflowHistoryPage);
  }

  getDate(date) {
    const dt = LuxonDateTime.fromISO(date);
    return dt.toFormat('h:mm a MMM d, yyyy');
  }

  async goToPage(url, params, component) {
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const isRightSidePanelAlreadyOpen = await this.storage.retrieve('dialog-right-side-open');
      if (isRightSidePanelAlreadyOpen) {
        return this.rightSidePanelService.navigateTo(url, params, component);
      } else {
        this.storage.save('dialog-right-side-open', true);
        this.storage.save('dialog-params', params);
        this.rightSidePanelService.openRightSidePanel(component);
      }
    } else {
      this.navCtrl.navigateForward(url, params);
    }
  }
}


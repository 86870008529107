import { Injectable } from "@angular/core";
import { HttpClientCustom } from "src/providers/custom/http-client";

@Injectable({
  providedIn: 'root'
})

export class TeamService {

  constructor(
    private httpClient: HttpClientCustom
  ) {}

  getTeams() {
    const url = 'api/v1/customer/account-teams?includes=team_members,addresses,account_team_members';
    return this.httpClient.get(url);
  }

  getTeamMembersForTeam(teamId) {
    const url = `api/v1/customer/account-team-members?account_team_id=${teamId}`;
    return this.httpClient.get(url);
  }

  createTeam(payload) {
    const url = 'api/v1/customer/account-teams';
    return this.httpClient.post(url, payload);
  }

  addUserToTeam(payload) {
    const url = 'api/v1/customer/account-team-members';
    return this.httpClient.post(url, payload);
  }

  addAddressesToTeam(payload) {
    const url = `api/v1/customer/account-team-addresses/add`;
    return this.httpClient.post(url, payload);
  }

  removeAddressesFromTeam(payload) {
    const url = `api/v1/customer/account-team-addresses/remove`;
    return this.httpClient.post(url, payload);
  }

  editTeamMember(teamMemberId, payload) {
    const url = `api/v1/customer/account-team-members/${teamMemberId}`;
    return this.httpClient.put(url, payload);
  }

  removeUserFromTeam(teamMemberId) {
    const url = `api/v1/customer/account-team-members/${teamMemberId}`;
    return this.httpClient.delete(url);
  }

  editTeam(teamId, payload) {
    const url = `api/v1/customer/account-teams/${teamId}`;
    return this.httpClient.put(url, payload);
  }

  deleteTeam(teamId) {
    const url = `api/v1/customer/account-teams/${teamId}`;
    return this.httpClient.delete(url);
  }

}

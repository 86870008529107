import { Injectable } from "@angular/core";
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { Auth } from "src/providers/auth/auth";
import { Me } from "src/providers/me/me";

@Injectable({
  providedIn: 'root'
})
export class OneSignalNotification {

  constructor(
    private auth: Auth,
    private me: Me
  ) {}

  initOneSignal() {
    OneSignal.setAppId(environment.one_signal_app_id);
    OneSignal.setNotificationOpenedHandler(data => {
      // OPENS APP
    });

    OneSignal.promptForPushNotificationsWithUserResponse();

    if (this.auth.isAuthenticated()) {
      this.alreadyAuthenticatedInit();
    }
  }

  async alreadyAuthenticatedInit() {
    const meData = await this.me.load();
    this.setUpNotificationUser(meData.current_user.id);
  }

  setUpNotificationUser(userId, retry = 0) {
    try {
      OneSignal.setExternalUserId(`${userId}`);
    } catch (err) {
      const tenMinutes = 600000;
      if (retry < 10) {
        setTimeout(() => {
          this.setUpNotificationUser(userId, (retry + 1));
        }, tenMinutes);
      }
    }
  }
}

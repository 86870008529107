import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { Client } from 'src/providers/client/client';
import { Dashboard } from 'src/providers/dashboard/dashboard';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Me } from 'src/providers/me/me';
import { WindowService } from 'src/shared/providers/window.service';

import { Util } from 'src/shared/util/util';

import { desktopNavItems, automationsSectionItems, billsItem, tasksItem, automationsItem, inventoryItem, employerItems, employerItemsMock, integrationSectionItems, maintenanceItem, upsellItem } from 'src/shared/components/desktop-nav/desktop-nav-items';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Menu } from 'src/providers/menu/menu';
import { Events } from 'src/providers/events/events';
import { CurrentAddress } from 'src/providers/addresses/current-address';
import { Subscription } from 'rxjs';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
@Component({
  selector: 'app-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DesktopNavComponent implements OnInit, AfterViewInit, OnDestroy {

  accType: string;
  automationOptions: any;
  accountName: string;
  currentItem: any;
  userName: string;
  userRole: string;
  menuItems;
  menuAddresses: any;
  currentAddress: number;
  dashboardOpts: any;
  loading: boolean;
  filteredAddresses: any;
  form: UntypedFormGroup
  integrationOptions: any;
  addAddressSubs: Subscription;
  addAddressRoute: boolean;
  addReportRoute: boolean;
  showSecondaryMenu = false;
  isAllAddressesSelected: boolean;
  logo: string;
  isMenuCollapsed = false;

  constructor(
    private storage: TidyStorage,
    private client: Client,
    private dashboard: Dashboard,
    public router: Router,
    private navCtrl: CustomNavController,
    private platform: Platform,
    private util: Util,
    private formBuilder: UntypedFormBuilder,
    private me: Me,
    public menu: Menu,
    private events: Events,
    private currentAddressService: CurrentAddress,
    private windowService: WindowService
  ) {
    this.form = this.formBuilder.group({
      search: ''
    });
    this.events.subscribe('updateMenu', () => this.updateMenuData());
    this.events.subscribe('add:address', () => this.updateMenuData());
    this.events.subscribe('address:changed', () => this.updateMenuData());
    this.events.subscribe('update:addressId', (addressId) => {
      this.currentAddress = parseInt(addressId, 10);
      this.updateMenuData();
    })
    this.dashboard.newReportAdded.pipe().subscribe(value => {
      this.menu.dashboardSection = `/reports/${value.id}`;
      this.updateDashboardSection(true);
    });

    this.addAddressSubs = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMenuData()
        this.addAddressRoute = event.url === '/add-property';
        this.addReportRoute = event.url === '/dashboard/add-report';
      }
    });
  }

  ngOnInit() {
    if (!this.menu.showSecondaryMenu) {
      document.documentElement.style.setProperty('--secondary-menu-width', '420px');
      document.documentElement.style.setProperty('--skeleton-menu-width', '60px');
      document.documentElement.style.setProperty('--skeleton-menu-margin-left', '0px');
    } else {
      document.documentElement.style.setProperty('--secondary-menu-width', '260px');
      document.documentElement.style.setProperty('--skeleton-menu-width', '60px');
      document.documentElement.style.setProperty('--skeleton-menu-margin-left', '-4px');
    }
    this.updateMenuData()
    this.listenToPrimaryMenuHoverAction();
  }

  ngAfterViewInit() {
    this.loading = true;
    this.currentAddress = parseInt(localStorage.getItem('addressId'), 10);
    this.loading = false;
  }

  expandSidenav(): void {
    const primaryMenuElement = document.getElementById('primary-menu');
    const primaryHeaderElement = document.getElementById('primary-header');
    this.togglePrimaryItem(false, primaryMenuElement);
    this.togglePrimaryItem(false, primaryHeaderElement);
    const customLogo = localStorage.getItem('custom.logo');
    this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
    setTimeout(() => {
      this.isMenuCollapsed = false;
    }, 200);
    this.menuItems.forEach((item) => {
      const itemElement = document.getElementById(item.target);
      if (itemElement) {
        itemElement.classList.remove('not-display');
      }
    })
  }

  collapseSidenav(): void {
    const primaryMenuElement = document.getElementById('primary-menu');
    const primaryHeaderElement = document.getElementById('primary-header');
    this.togglePrimaryItem(true, primaryMenuElement);
    this.togglePrimaryItem(true, primaryHeaderElement);
    document.documentElement.style.setProperty('--secondary-menu-width', '260px');
    const customLogo = localStorage.getItem('custom.logo');
    this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
    setTimeout(() => {
      this.isMenuCollapsed = true;
    }, 200);
      this.menuItems.forEach((item) => {
        const itemElement = document.getElementById(item.target);
        if (itemElement) {
          itemElement.classList.add('not-display');
        }
      })
  }

  listenToPrimaryMenuHoverAction(): void {
    const primaryMenuElement = document.getElementById('primary-menu');

    primaryMenuElement.addEventListener('mouseenter', () => {
      if (this.menu.showSecondaryMenu) {
        this.expandSidenav();
      }
    });

    primaryMenuElement.addEventListener('mouseleave', () => {
      if (this.menu.showSecondaryMenu) {
        this.collapseSidenav();
      }
    });

    primaryMenuElement.addEventListener('click', () => {
      if (this.menu.showSecondaryMenu) {
        this.collapseSidenav();
      }
    });
  }

  togglePrimaryItem(shouldCollapse: boolean, element: HTMLElement): void {
    if (!element) {
      return;
    }
    if (shouldCollapse) {
      element.classList.remove('default-wrapper-width');
      element.classList.add('only-icons-wrapper-width');
    } else {
      element.classList.remove('only-icons-wrapper-width');
      element.classList.add('default-wrapper-width');
    }
  }

  updateSearch() {
    this.filteredAddresses = this.menuAddresses.filter(address => {
      const fieldsCheck = ['address_name', 'address1', 'address2', 'city'];
      return fieldsCheck.some(field => {
        const search = this.form.value.search?.toLowerCase();
        return address[field]?.toLowerCase()?.includes(search);
      })
    });
  }

  changeAddress(address) {
    localStorage.removeItem('taskListId');
    this.currentAddressService.addressId = address.id.toString();
    const toDoState = address.default_address_task_list_state;
    localStorage.setItem('isAllAddressesSelected', 'false');
    localStorage.setItem('timezone', address.timezone);
    localStorage.setItem('toDoState', toDoState);
    this.currentAddress = address.id;
    this.isAllAddressesSelected = false;
    this.menu.goToRootSection();
  }

  addAddress() {
    const hasNoAddresses = this.menuAddresses.length > 0;
    const params = {
      hasNoAddresses
    };
    this.navCtrl.navigateForward('add-property', params);
  }

  async updateDashboardSection(fetchReports = false) {
    let dashboardSectionItems = await this.dashboard.buildDashboardSectionItems(fetchReports);
    dashboardSectionItems = dashboardSectionItems.filter((section) => {
      return section.label !== "Overview"
    });
    this.dashboardOpts = [...dashboardSectionItems];
  }

  async updateMenuData() {
    this.isAllAddressesSelected = localStorage.getItem('isAllAddressesSelected') === 'true';
    const isEmployerPortal = this.checkIfIsEmployerMockPortal();
    if (isEmployerPortal) {
      this.userName = 'Catrina Ellis';
      this.menuItems = employerItemsMock;
      return;
    }
    this.currentAddress = parseInt(localStorage.getItem('addressId'), 10);
    this.accType = await this.me.getAccType();
    const clientInfo = await this.client.getClientInfo();
    this.menuAddresses = await this.client.getAddresses();
    if (this.router.url.includes('schedule') && this.isAllAddressesSelected && this.menuAddresses.length > 1) {
      this.isMenuCollapsed = false;
    }
    if (this.router.url.includes('schedule-list') && (!this.isAllAddressesSelected || this.menuAddresses.length <= 1)) {
      this.isMenuCollapsed = true;
    }
    this.updateSearch();
    this.loadLogo();

    this.userRole = localStorage.getItem('user_role');
    const conciergeAddressStatus = localStorage.getItem('isAllAddressesSelected');
    this.isAllAddressesSelected = conciergeAddressStatus === 'true';

    localStorage.setItem('shouldShowAllToDosPage', 'true');
    let menuItems = [...desktopNavItems];

    if (clientInfo.customer_account.account_type !== 'regular') {
      menuItems.splice(menuItems.length - 2, 0, tasksItem);
      menuItems.splice(menuItems.length - 2, 0, inventoryItem);
      menuItems.splice(menuItems.length - 2, 0, maintenanceItem);
      //menuItems.splice(menuItems.length - 2, 0, upsellItem);
    }

    const userRole = localStorage.getItem('user_role');
    if ((clientInfo.customer_account.account_type === 'rental') && userRole !== 'member') {
      menuItems.splice(menuItems.length - 1, 0, automationsItem);
      menuItems.splice(menuItems.length - 3, 0, billsItem);
    }

    if (clientInfo.customer_account.account_type !== 'rental') {
      menuItems = menuItems.map(item => {
        if (item.label === 'My Pros') {
          item.target = '/job-request-workflows';
        }
        return item;
      });
    }

    if (clientInfo.customer_account.account_type === 'employer') {
      menuItems = employerItems;
    }

    if (clientInfo.customer_account.account_name) {
      this.accountName = clientInfo.customer_account.account_name;
    }

    if (!this.sameMenuContent(menuItems)) {
      this.menuItems = menuItems;
    }

    this.integrationOptions = integrationSectionItems;
    this.automationOptions = automationsSectionItems;
    await this.updateDashboardSection();
    this.userName = clientInfo.current_user.name;
    if (this.router.url.includes('edit-property')) {
      this.menu.rootSection = '/more';
    }
    if (userRole !== 'owner' && this.checkIfIsEmployerPortal()) {
      this.menuItems.pop();
    }

    this.isMenuCollapsed = this.menu.showSecondaryMenu;

    if (!this.menu.showSecondaryMenu) {
      document.documentElement.style.setProperty('--secondary-menu-width', '420px');
      document.documentElement.style.setProperty('--skeleton-menu-width', '60px');
      document.documentElement.style.setProperty('--skeleton-menu-margin-left', '0px');
      const customLogo = localStorage.getItem('custom.logo');
      this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
      this.expandSidenav()
    } else {
      document.documentElement.style.setProperty('--secondary-menu-width', '260px');
      document.documentElement.style.setProperty('--skeleton-menu-width', '60px');
      document.documentElement.style.setProperty('--skeleton-menu-margin-left', '-4px');
      const customLogo = localStorage.getItem('custom.logo');
      this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
      this.collapseSidenav()
    }
  }

  loadLogo(){
    const customLogo = localStorage.getItem('custom.logo');
    this.logo = (customLogo && customLogo != 'nil' && customLogo != 'undefined') ? customLogo : "/assets/img/bowtie-white.svg";
  }

  checkIfIsEmployerMockPortal() {
    const urls = [
      '/employer-campaign',
      '/employer-compliance',
      '/employer-employees',
      '/employer-get-started',
      '/employer-integrations'
    ];
    return urls.includes(this.router.url);
  }

  checkIfIsEmployerPortal() {
    const urls = [
      '/employer-campaign/employer-campaign',
      '/employer-compliance/employer-compliance',
      '/employer-dashboard/employer-dashboard',
      '/employer-get-started/employer-get-started',
      '/employer-integrations/employer-integrations',
      '/employer-settings',
      '/employer-admins/employer-admins',
      '/employer-add-new-admin/employer-add-new-admin',
      '/employer-edit-admin/employer-edit-admin'
    ];
    return urls.includes(this.router.url);
  }

  isCurrentItem(item: any): boolean {
    return this.router.url === item.target || 
           (item.target !== '/employer-settings' && this.router.url.startsWith(item.target));
  }

  async goToLink(url) {
    this.storage.delete('onboardingFlow');
    this.currentItem = url;
    if (url === '/help') {
      this.goToHelp()
      return
    }
    localStorage.setItem('loggedIssuesBackPage', '');
    if (url == '/integrations') {
      localStorage.setItem('integrationsBackPage', '')
    }
    if (url == '/my-pros') {
      localStorage.setItem('myProsBackPage', '')
    }
    if (url !== '/concierge' && url !== '/messages' && url !== '/tasks' && url !== '/bills' && url !== '/my-pros') {
      localStorage.setItem('isAllAddressesSelected', 'false');
    } else if (url === '/concierge' || url == '/messages' || url == '/tasks' || url == '/bills') {
      localStorage.setItem('isAllAddressesSelected', 'true');
      this.isAllAddressesSelected = true;
      this.updateMenuData();
    }
    if (url == '/my-pros' && this.menuAddresses.length <= 1) {
      localStorage.setItem('isAllAddressesSelected', 'false');
    } else {
      localStorage.setItem('isAllAddressesSelected', 'true');
    }
    if (url == '/inventory') {
      this.updateMenuData();
    }
    if (url == '/schedule' && this.menuAddresses.length > 1) {
      localStorage.setItem('isAllAddressesSelected', 'true');
      this.isAllAddressesSelected = true;
    }
    this.navCtrl.navigateForward(url);
  }

  goToHelp() {
    const isEmployerPortal = this.checkIfIsEmployerPortal();
    const url = isEmployerPortal ? 'https://help.tidy.com/employers' : 'https://help.tidy.com/';
    this.util.openUrl(url);
  }

  goToSettings() {
    this.navCtrl.navigateForward('employer-settings');
  }

  async moreOptsAction(option) {
    const external = option.target?.includes('https://')
    if (external) {
      return this.util.openUrl(option.target);
    }

    if (option.target === 'logout') {
      return this.events.publish('logout');
    }
    await this.navCtrl.navigateForward(option.target);
  }

  dashboardOptsAction(option) {
    this.navCtrl.navigateForward(option.target);
  }

  addReport() {
    this.navCtrl.navigateForward('dashboard/add-report');
  }

  selectAllAddresses() {
    localStorage.setItem('isAllAddressesSelected', 'true');
    this.isAllAddressesSelected = true;
    this.menu.goToRootSection();
  }

  sameMenuContent(newItems): boolean {
    if (this.menuItems) {
      return newItems
        .every((item, index) => {
          return Object.keys(item)
            .every(itemKey => {
              return item[itemKey] === this.menuItems[index][itemKey];
            });
        });
    }
    return false;
  }

  showAllAddressesButton() {
    if (this.router.url === '/schedule' && this.accType !== 'rental' && this.menuAddresses.length == 1) {
      this.isAllAddressesSelected = false;
      localStorage.setItem('isAllAddressesSelected', 'false');
      return false;
    } else {
      return (this.router.url === '/my-pros' ||
      this.router.url === '/schedule' ||
      this.router.url === '/concierge' ||
      this.router.url === '/messages' ||
      this.router.url === '/tasks' ||
      this.router.url.includes('log-issue') ||
      this.router.url.includes('get-help') ||
      this.router.url.includes('bill') ||
      this.router.url.includes('contact-concierge') &&
      this.filteredAddresses.length === this.menuAddresses.length);
    }

  }

  ngOnDestroy() {
    if (this.addAddressSubs) {
      this.addAddressSubs.unsubscribe();
    }
  }

}

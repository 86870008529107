import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Client } from "src/providers/client/client";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";

import { Concierge } from "src/providers/concierge/concierge";

import {
  AttachmentModel,
  ConciergeItemModel,
} from "src/models/concierge.model";
import { environment } from "src/environments/environment";
import { Events } from "src/providers/events/events";
import { Loading } from "src/shared/components/loading/loading";
import { Me } from "src/providers/me/me";
import { RightSidePanelService } from "src/shared/providers/providers/right-side-panel";
import { TidyStorage } from "src/shared/providers/tidy-storage";

@Component({
  templateUrl: "app-branding.html",
  encapsulation: ViewEncapsulation.None,
})
export class AppBrandingPage implements OnInit {

  email: string;
  item: ConciergeItemModel;
  submitted: boolean;
  currentBackground : any;
  backgroundAttachment: AttachmentModel;
  backgroundUrl: string = null;
  backgroundOption: string;
  backgroundErrorMessage: string;
  currentlogo: any;
  errorMessage: string;
  loaded: boolean;
  logoAttachment: AttachmentModel;
  logoUrl: string = null;
  logoOption: string;
  logoErrorMessage: string;
  form: UntypedFormGroup;
  radioForm: UntypedFormGroup;
  backgroundOptions = [
    {viewValue: 'Default TIDY background', value: 'nil'},
    {viewValue: 'Solid color', value: 'color'},
    //{viewValue: 'Upload image', value: 'image'}
  ]
  logoOptions = [
    {viewValue: 'Default logo', value: 'nil'},
    {viewValue: 'Upload image', value: 'image'}
  ]
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private concierge: Concierge,
    private fb: UntypedFormBuilder,
    private client: Client,
    private events: Events,
    private me: Me,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      color: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(7),
          Validators.minLength(7),
        ]),
      ],
    });
    this.radioForm = this.fb.group({
      background: [""],
      logo: [""]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Your Branding');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
    this.loaded = false;
    const settings = ((await this.client.getClientSettings())).white_label;

    if(settings.background == 'nil' || !settings.background){
      this.backgroundOption = 'nil';
    } else{
      if(settings.background[0] === '#'){
        this.backgroundOption = 'color';
        this.form.patchValue({
          color: settings.background
        });
      }
      else{
        this.backgroundOption = 'image';
        this.backgroundUrl = settings.background
      }
    }
    if(settings.logo == 'nil' || !settings.background){
      this.logoOption = 'nil';
    } else{
      this.logoOption = 'image';
      this.logoUrl = settings.logo;
    }

    this.radioForm.patchValue({
      background: this.backgroundOption,
      logo: this.logoOption
    });
    this.loaded = true;
  }

  @Loading('', true)
  async addBackgroundAttachment() {
    this.backgroundErrorMessage = "";
    try {
      const attachment = await this.concierge.addAttachment();
      this.backgroundAttachment = attachment;
      this.backgroundUrl = `${environment.aws_s3_bucket_url}${this.backgroundAttachment.fileKey}`;
    } catch (err) {
      this.backgroundErrorMessage = err.error ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async addLogoAttachment() {
    this.backgroundErrorMessage = "";
    try {
      const attachment = await this.concierge.addAttachment();
      this.logoAttachment = attachment;
      this.logoUrl = `${environment.aws_s3_bucket_url}${this.logoAttachment.fileKey}`;
    } catch (err) {
      this.logoErrorMessage = err.error ? err.error.message : err.message;
    }
  }

  @Loading("", true)
  async submit(){
    this.errorMessage = ""
    let settingsPayload = {
      white_label : {
        //background: this.formatBackground(),
        logo: this.formatLogo()
      }
    }
    try {
      const newSettings = (await this.client.saveClientSettings(settingsPayload)).white_label;
      //this.setCustomerSetting("custom.logo",newSettings.logo);
      //this.setCustomerSetting("custom.background", newSettings.background);
      this.events.publish("updateMenu");
      this.events.publish("updateBackground");
      console.log(newSettings)
      localStorage.setItem('custom.logo', newSettings.logo);
      window.location.reload();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
    this.submitted = true;
  }

  private setCustomerSetting(key: string, value: any) {
    if (!value) return localStorage.removeItem(key);

    localStorage.setItem(key, value);
  }


  formatBackground(): string {
    if (this.backgroundAttachment && this.backgroundOption === 'image') {
      const filepath: string = this.backgroundAttachment.fileKey.replace(/\s+/g, '%20');
      return `${environment.aws_s3_bucket_url}${filepath}`
    } else if (this.form.valid && this.backgroundOption === 'color') {
      return this.form.value.color;
    } else if(this.backgroundOption == 'nil'){
      return 'nil';
    } else if (this.backgroundUrl){
      return this.backgroundUrl;
    }
  }

  formatLogo(): string {
    if (this.logoAttachment && this.logoOption === 'image') {
      const filepath: string = this.logoAttachment.fileKey.replace(/\s+/g, '%20')
      return `${environment.aws_s3_bucket_url}${filepath}`
    } else if(this.logoOption == 'nil'){
      return 'nil';
    } else if(this.logoUrl){
      return this.logoUrl;
    }
  }

  removeLogo() {
    this.logoErrorMessage = "";
    this.logoUrl = null;
    this.logoAttachment = null;
  }

  removeBackground() {
    this.backgroundErrorMessage = "";
    this.backgroundUrl = null;
    this.backgroundAttachment = null;
  }

  backgroundSelection(selection){
    this.backgroundErrorMessage = "";
    this.backgroundOption = selection.value;
  }

  logoSelection(selection){
    this.logoErrorMessage = "";
    this.logoOption = selection.value;
  }

}

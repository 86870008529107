import { Component, OnInit } from "@angular/core";

import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  templateUrl: 'add-credit.html'
})
export class EmployerAddCreditPage implements OnInit {

  customerId: string;

  constructor(
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.customerId = localStorage.getItem('customer_id');
  }

  async ngOnInit() {
    this.rightSidePanelService.setDialogPageTitle('Add Credit');
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

import { Aws } from 'src/providers/aws/aws';
import { Client } from 'src/providers/client/client';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';

import { Util } from 'src/shared/util/util';

import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

@Component({
  templateUrl: 'bulk-upload.html'
})

export class BulkUploadPage implements OnInit {

  errorMessage: string;
  form: UntypedFormGroup;
  reservationImportForm: UntypedFormGroup;
  loaded: boolean;
  showNotCSVError: boolean;
  typeItems: any;
  isRightSideContent: boolean;
  dialogParams: any;
  guestReservationCsvSourceList: any[] = [];
  replaceAllReservations: boolean;

  constructor(
    private aws: Aws,
    private client: Client,
    private fb: UntypedFormBuilder,
    private filePicker: FilePicker,
    private modalCtrl: ModalController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private guestReservations: GuestReservations
  ) {
    this.form = this.fb.group({
      type: ['']
    });
    this.reservationImportForm = this.fb.group({
      sourceTypeId: ['']
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Bulk Upload');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.typeItems = this.getTypeItems();
      this.form.patchValue({type: 'address_tasks'});
      await this.loadGuestReservationCsvSourceList();
      this.loaded = true;
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  getTypeItems() {
    return [
      {
        value: 'address_tasks',
        viewValue: 'To-Dos'
      },
      {
        value: 'room_objects',
        viewValue: 'Inventory'
      },
      {
        value: 'guest_reservations',
        viewValue: 'Reservations'
      }
    ];
  }

  async loadGuestReservationCsvSourceList() {
    const sourceTypes = await this.guestReservations.getSourceTypes(false);
    const csvTypes = sourceTypes.filter(sourceType => sourceType.format === 'csv');
    this.guestReservationCsvSourceList = csvTypes.map(type => ({
      value: type.id,
      viewValue: type.name
    }));
  }

  getSelectedTypeName() {
    const type = this.typeItems.find((type) => type.value == this.form.value.type);
    return type.viewValue;
  }

  async exportItem() {
    try {
      const payload = {
        scope: this.form.value.type
      }
      const jsonData = await this.client.bulkExportItem(payload);
      this.downloadJSONAsCSV(jsonData.csv_data);
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  downloadJSONAsCSV(jsonData) {
    const csvRows = [];
    jsonData.map((row) => {
      csvRows.push(row);
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.getSelectedTypeName() + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async importItem() {
    try {
      const file = await this.filePicker.pickFile();
      if (file.format.includes('csv')) {
        this.confirmImportItem(file);
      } else {
        return this.showNotCSVError = true;
      }
    } catch (err) {
      const errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      this.util.showError(errorMessage, 10000);
    }
  }

  async confirmImportItem(file) {
    const selectedTypeName = this.getSelectedTypeName();
    let reservationTypeName = ''
    if (selectedTypeName == 'Reservations') {
      reservationTypeName = this.getReservationTypeName();
    }

    const confirmParams: ConfirmPageParamsModel = {
      title: 'Import CSV?',
      body: `Importing this CSV will replace all ${reservationTypeName} ${selectedTypeName}. You cannot undo this change.`,
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmedImportItem.bind(this, file)
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: confirmParams,
      animated: false,
      cssClass: 'confirm-modal'
    });
    confirmationModal.present();
  }

  getReservationTypeName() {
    if (this.replaceAllReservations) {
      return '';
    }
    const name = this.guestReservationCsvSourceList.find(item => item.value == this.reservationImportForm.value.sourceTypeId).viewValue;

    return `imported ${name}`;
  }

  async confirmedImportItem(file) {
    try {
      const key = uuidv4() + '.csv';
      const awsResponse = await this.aws.uploadFileToS3(file.dataUrl, key, 'text/csv');
      const payload = {
        scope: this.form.value.type,
        url: awsResponse.Location,
        file_format: 'csv',
        options: {}
      }

      if (payload.scope == 'guest_reservations') {
        payload.options['guest_reservation_source_type_id'] = this.reservationImportForm.value.sourceTypeId;
        payload.options['replace_reservation_from_all_sources'] = this.replaceAllReservations;
      }

      await this.client.bulkImportItem(payload);
      this.util.showSuccess(this.getSelectedTypeName() + ' imported successfuly!');
    } catch (err) {
      const errorMessage = 'Import failed. We recommend that you export first, then make changes, then import. Contact Concierge for help.';
      this.util.showError(errorMessage, 10000);
    }
    this.modalCtrl.dismiss();
  }

  replaceAllReservationsChanged(event) {
    this.replaceAllReservations = event;
  }
}

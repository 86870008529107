<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Job'"
    [canGoBack]="true"
    [customBack]="jobBackPage">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-loading-bar *ngIf="isRightSideContent" class="js-tidy-loading"></tidy-loading-bar>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="!cardDetail || !bookingDetail">
      <tidy-card>
        <tidy-row>
          <ion-row>
            <tidy-row class="vertical-align-center">
              <ion-skeleton-text
                animated
                style="width: 35px; height: 35px; border-radius: 6px;"></ion-skeleton-text>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 180px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
              <tidy-row>
                <ion-skeleton-text
                  animated
                  style="width: 160px; border-radius: 6px;"></ion-skeleton-text>
              </tidy-row>
            </ion-col>
          </ion-row>
        </tidy-row>
        <tidy-card-button [hideArrow]="true">
          <tidy-row class="vertical-align-center">
            <ion-skeleton-text
              animated
              style="width: 180px; border-radius: 6px;"></ion-skeleton-text>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text
          animated
          slot="start"
          style="width: 140px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-item style="--min-height: 0;">
            <ion-skeleton-text
              animated
              slot="start"
              style="width: 150px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              slot="end"
              style="width: 40px; border-radius: 6px;"></ion-skeleton-text>
          </ion-item>
        </tidy-row>

        <tidy-row class="field-top-padding">
          <ion-skeleton-text
            animated
            style="width: 130px; border-radius: 6px;"></ion-skeleton-text>
          <tidy-divider></tidy-divider>
        </tidy-row>

        <tidy-row class="extra-top-padding">
          <ion-skeleton-text
            animated
            style="width: 110px; margin: auto; border-radius: 6px;"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>

      <tidy-row>
        <ion-skeleton-text
          animated
          style="width: 100%; margin: auto; margin-bottom: 3px; border-radius: 6px;"></ion-skeleton-text>
        <ion-skeleton-text
          animated
          style="width: 130px; margin: auto; border-radius: 6px;"></ion-skeleton-text>
      </tidy-row>

      <tidy-row>
        <ion-skeleton-text
          animated
          slot="start"
          style="width: 140px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
        <ion-skeleton-text
          animated
          slot="end"
          style="width: 70px; border-radius: 6px;"></ion-skeleton-text>
      </tidy-row>

      <tidy-card>
        <tidy-row>
          <ion-skeleton-text
            animated
            slot="start"
            style="width: 100px; height: 16px; border-radius: 6px;"></ion-skeleton-text>
        </tidy-row>
        <tidy-row>
          <ion-skeleton-text
            animated
            slot="start"
            style="width: 150px; border-radius: 6px;"></ion-skeleton-text>
        </tidy-row>
      </tidy-card>
    </ng-container>

    <ng-container *ngIf="cardDetail && bookingDetail">
      <!--Job Request Pending-->
      <tidy-card *ngIf="cardDetail?.template == 'job_request' && cardDetail?.template_data?.job_request_status == 'pending'">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/icon/searching.svg'"
            class="header-size wiggle-animation">
          </tidy-image>
          <tidy-text
            [header]="'Request Pending'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Your'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(bookingDetail?.service_type_details?.name)">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'request is searching for a pro matching your preferences. When scheduled, we will message you.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/play-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Earliest Start:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(bookingDetail?.start_date_no_earlier_than | customdate:'ddd M/D') + ' ' + (bookingDetail?.start_time_no_earlier_than | customtime:'h:mma')">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center extra-bottom-padding">
          <tidy-image
            [src]="'assets/svg/stop-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Latest Finish:'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="(bookingDetail?.end_date_no_later_than | customdate:'ddd M/D') + ' ' + (bookingDetail?.end_time_no_later_than | customtime:'h:mma')">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="hasQuotes">
          <tidy-alert class="light-green-background">
            <tidy-row class="vertical-align-center">
              <tidy-image
                [src]="'assets/svg/alert-circle-outline.svg'"
                class="body-size">
              </tidy-image>
              <tidy-text
                [body]="'Pros have also bid as low as <b>' + (smallestQuote | tcurrency) + '</b> for this job.'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [actionBody]="'View Bids'"
                class="link"
                (action)="goToBidsPage(this)">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
        </tidy-row>
        <tidy-row *ngIf="schedule.showNotificationHistory(cardDetail?.template_data?.notification_history)" style="margin-left: -10px; margin-right: -10px">
          <tidy-alert *ngIf="cardDetail?.template_data?.notification_history?.length == 0">
            <tidy-row>
              <tidy-text
                [body]="'Starting to contact pros. Check back soon for a notification history.'">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
          <tidy-notification-history
            *ngIf="cardDetail?.template_data?.notification_history?.length > 0"
            [items]="cardDetail?.template_data?.notification_history"
            (viewProAction)="goToProPage($event)"
            (viewHistoryDetails)="goToNotificationDetailsPage($event)"
            [notificationHistory]="notificationHistory"
            (selectionChange)="checkWaterfallHistory($event)">
          </tidy-notification-history>
        </tidy-row>
        <ng-container *ngIf="(hasPrivatePro || bookingDetail?.current_job?.is_private) && !bookingDetail?.service_type_details?.name?.includes('2 Pros')">
          <tidy-row class="extra-bottom-padding">
            <tidy-button
              [text]="'Assign Pro'"
              [action]="goToChangePro.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-row>
        </ng-container>
        <tidy-row>
          <tidy-button
            [text]="'Change Request'"
            [action]="editWaterfallRequest.bind(this)"
            class="secondary">
          </tidy-button>
        </tidy-row>
        <tidy-row [align]="'center'" [class.extra-bottom-padding]="jobEvents?.length > 0">
          <tidy-text
            (action)="cancelWaterfallRequest(bookingDetail?.id)"
            [body]="'Cancel Request'"
            class="link">
          </tidy-text>
        </tidy-row>
        <ng-container *ngFor="let event of jobEvents">
          <tidy-row *ngIf="event?.event?.name === 'samantha_service.booking.created'" [align]="'center'">
            <tidy-text
              [body]="event?.text + (' at ' | translate) + (event?.happened_at | customdate:'M/D h:mma')">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <!--Job Request Template / Failed Status-->
      <tidy-card *ngIf="cardDetail?.template == 'job_request' && cardDetail?.template_data?.job_request_status == 'failed'">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/alert.svg'"
            class="header-size">
          </tidy-image>
          <tidy-text
            [header]="'No Pro Accepted Your Request'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Your ' +
            (bookingDetail?.service_type_details?.name) +
            ' request was not accepted.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/play-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
            [body]="'Earliest Start: ' + (bookingDetail?.start_date_no_earlier_than | customdate:'ddd M/D') + ' ' + (bookingDetail?.start_time_no_earlier_than | customtime:'h:mma')">
          </tidy-text>
        </tidy-row>
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/svg/stop-circle-outline.svg'"
            class="body-size">
          </tidy-image>
          <tidy-text
          [body]="'Latest Finish: ' + (bookingDetail?.end_date_no_later_than | customdate:'ddd M/D') + ' ' + (bookingDetail?.end_time_no_later_than | customtime:'h:mma')">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding extra-top-padding" *ngIf="schedule.showNotificationHistory(cardDetail?.template_data?.notification_history)" style="margin-left: -10px; margin-right: -10px">
          <tidy-alert *ngIf="cardDetail?.template_data?.notification_history?.length == 0">
            <tidy-row>
              <tidy-text
                [body]="'Starting to contact pros. Check back soon for a notification history.'">
              </tidy-text>
            </tidy-row>
          </tidy-alert>
          <tidy-notification-history
            *ngIf="cardDetail?.template_data?.notification_history?.length > 0"
            [items]="cardDetail?.template_data?.notification_history"
            (viewProAction)="goToProPage($event)"
            (viewHistoryDetails)="goToNotificationDetailsPage($event)"
            [notificationHistory]="notificationHistory"
            (selectionChange)="checkWaterfallHistory($event)">
          </tidy-notification-history>
        </tidy-row>
        <tidy-row>
          <tidy-button
            [text]="'Request or Book Different Times'"
            [action]="editWaterfallRequest.bind(this)"
            class="primary">
          </tidy-button>
        </tidy-row>
        <tidy-row>
          <tidy-button
            [text]="'Schedule Later'"
            [action]="cancelWaterfallRequest.bind(this, bookingDetail?.id)"
            class="secondary">
          </tidy-button>
        </tidy-row>
        <ng-container *ngFor="let event of jobEvents">
          <tidy-row *ngIf="event?.event?.name === 'samantha_service.booking.created'" [align]="'center'">
            <tidy-text
              [body]="event?.text + (' at ' | translate) + (event?.happened_at | customdate:'M/D h:mma')">
            </tidy-text>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <!--Cleaning Template-->
      <tidy-card *ngIf="cardDetail?.template == 'cleaning' && bookingDetail">

        <!--Service & Date/Time Header-->
        <tidy-row>
          <ion-row>
            <tidy-row style="margin-top:10px">
              <tidy-image
                [src]="bookingDetail?.service_type_details?.service_category?.icon_url"
                style="width:30px;">
              </tidy-image>
            </tidy-row>
            <ion-col>
              <tidy-row>
                <tidy-text
                  [header]="bookingDetail?.service_type_details?.name | titlecase">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="(bookingDetail?.current_job?.start_datetime_local | parseDate: 'ddd') +
                  ' ' +
                  (bookingDetail?.current_job?.start_datetime_local | parseDate: 'M/D')">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="'at'">
                </tidy-text><span>&nbsp;</span>
                <tidy-text
                  [body]="(bookingDetail?.current_job?.start_datetime_local | parseDate: 'h:mma')">
                </tidy-text>
              </tidy-row>
              <tidy-row>
                <tidy-text
                  [body]="addressName">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let event of jobEvents">
                <tidy-row *ngIf="event?.event?.name === 'samantha_service.booking.created'">
                  <tidy-text
                    [body]="event?.text + (' at ' | translate) + (event?.happened_at | customdate:'M/D h:mma')">
                  </tidy-text>
                </tidy-row>
              </ng-container>
            </ion-col>
          </ion-row>
        </tidy-row>

        <tidy-card-button (action)="rescheduleJob(cardDetail)" *ngIf="schedule.showRescheduleButton(cardDetail?.template_data?.scenario_name)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/arrow-forward.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Reschedule'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="skipJob(cardDetail.template_data.booking.id)" *ngIf="schedule.showRescheduleButton(cardDetail?.template_data?.scenario_name)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/canceled.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [body]="'Skip'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button *ngIf="bookingDetail?.current_job?.is_private" (action)="goToShareJobPage()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/paper-plane.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Send Link to'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="(cardDetail?.template_data?.homekeepers?.[0]?.first_name | titlecase)"
              [translate]="false">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button *ngIf="bookingDetail?.current_job?.is_private" (action)="goToMarkJobComplete()">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/checkmark.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Mark Job as Complete'">
            </tidy-text>
          </tidy-row>
        </tidy-card-button>
        <tidy-card-button (action)="goToBookingNote()" *ngIf="schedule.showToDoButton(cardDetail?.template_data?.scenario_name)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/information.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Job Note'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="!bookingNote?.length && !bookingFormAnswers?.length">
            <tidy-text
              [body]="'<i>None Added</i>'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngFor="let note of bookingNote">
            <ng-container *ngIf="note?.type == 'text'">
              <tidy-text
                [body]="note?.text">
              </tidy-text>
            </ng-container>
            <ng-container *ngIf="note?.type == 'image'">
              <tidy-image
                [src]="note?.media_url">
              </tidy-image>
            </ng-container>
            <ng-container *ngIf="note?.type == 'video'">
              <tidy-image
                [src]="note?.media_url">
              </tidy-image>
            </ng-container>
          </tidy-row>
          <ng-container *ngFor="let answer of bookingFormAnswers">
            <ng-container *ngIf="answer?.template_input?.type == 'text' || answer?.template_input?.type == 'text_area'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + answer?.data?.value">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'select'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormSelectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'multiselect'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label + ': ' + me.getBookingFormMultiselectValue(answer?.data?.value)">
                </tidy-text>
              </tidy-row>
              <tidy-photo-note
                *ngIf="answer?.template_input?.image_url"
                [src]="answer?.template_input?.image_url">
              </tidy-photo-note>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'file' && answer?.template_input?.data?.type == 'image'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <tidy-photo-note
                  *tidyCarrousel
                  [src]="answer?.data?.value">
                </tidy-photo-note>
              </tidy-carrousel>
            </ng-container>
            <ng-container *ngIf="answer?.template_input?.type == 'files'  && answer?.template_input?.data?.type == 'images'">
              <tidy-row>
                <tidy-text
                  [body]="answer?.template_input?.label">
                </tidy-text>
              </tidy-row>
              <tidy-carrousel
                class="photo-notes-carrousel only-padding-bottom"
                [scaleImages]="true">
                <ng-container *ngFor="let imageUrl of answer?.data?.value">
                  <tidy-photo-note
                    *tidyCarrousel
                    [src]="imageUrl">
                  </tidy-photo-note>
                </ng-container>
              </tidy-carrousel>
            </ng-container>
          </ng-container>
        </tidy-card-button>
        <tidy-card-button (action)="goToEditBackupTimes()" *ngIf="schedule.showBackupTimesButton(cardDetail?.template_data?.scenario_name)">
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/clock.svg'"
              class="header-size">
            </tidy-image>
            <tidy-text
              [body]="'Backup Times'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="jobBackupTimesLoaded">
            <tidy-text
              [body]="jobBackupTimesCount">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="jobBackupTimesCount == 1 ? 'time added' : 'times added'">
            </tidy-text>
          </ng-container>
        </tidy-card-button>

        <!--Cant Access Scenario-->
        <ng-container *ngIf="cardDetail?.template_data?.scenario_name === 'hk_initiated_no_access'">
          <tidy-row class="extra-bottom-padding">
            <tidy-divider></tidy-divider>
          </tidy-row>
          <tidy-row class="extra-bottom-padding vertical-align-center">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              class="red"
              [title]="cardDetail?.template_data?.homekeepers[0]?.first_name">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              class="red"
              [title]="'Needs Help'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'is having trouble accessing your property.  Please reach out to help.'">
            </tidy-text>
          </tidy-row>
          <tidy-button
            [text]="'Call '
            + cardDetail?.template_data?.homekeepers[0]?.first_name"
            [action]="callPro.bind(this, cardDetail?.template_data?.homekeepers[0])"
            class="primary">
          </tidy-button>
        </ng-container>

        <!--Cant Access Confirmed Scenario-->
        <ng-container *ngIf="cardDetail?.template_data?.scenario_name === 'hk_confirmed_no_access'">
          <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="'Action Needed'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'confirmed they could not access your property.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Reschedule Job'"
              [action]="rescheduleJob.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Skip Job'"
              [action]="skipJob.bind(this, bookingDetail?.id)"
              class="secondary">
            </tidy-button>
          </tidy-row>
          <tidy-row class="extra-top-padding" [align]="'center'">
            <tidy-text
              [body]="'If you believe'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'was the one who cancelled, please'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              (action)="reportNoShow(cardDetail?.template_data?.homekeepers[0]?.first_name, bookingDetail?.current_job?.id, cardDetail?.template_data?.homekeepers[0]?.id)"
              [body]="'click here'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <!--Client Refused Service Scenario-->
        <ng-container *ngIf="cardDetail?.template_data?.scenario_name === 'hk_reported_client_cancellation'">
          <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="'Action Needed'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'reported that you cancelled the job.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Book New Time'"
              [action]="rescheduleJob.bind(this)"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row class="extra-top-padding" [align]="'center'">
            <tidy-text
              [body]="'If you believe'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name"
              [translate]="false">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'was the one who cancelled, please '">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              (action)="reportNoShow(cardDetail?.template_data?.homekeepers[0]?.first_name, bookingDetail?.current_job?.id, cardDetail?.template_data?.homekeepers[0]?.id)"
              [body]="'click here'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <!--Marked No Show Scenario-->
        <ng-container *ngIf="cardDetail?.template_data?.scenario_name === 'customer_reported_hk_no_showed' || cardDetail?.template_data?.scenario_name === 'concierge_reported_hk_no_showed'">
          <tidy-row class="extra-bottom-padding vertical-align-center extra-top-padding">
            <tidy-image
              [src]="'assets/img/alert.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [title]="'Action Needed'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'You reported that'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[0]?.first_name">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="'did not show up to the job.'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'We\'re so sorry that they did that!  We are reaching out to find out what happened.'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="'Of course you will not be billed for this, which means any credit will remain in your account.'">
            </tidy-text>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Request Substitute'"
              [action]="requestSubstitute.bind(this, bookingDetail?.current_job?.id)"
              class="primary">
            </tidy-button>
          </tidy-row>
          <tidy-row>
            <tidy-button
              [text]="'Assign a Pro'"
              [action]="goToChangePro.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-button
              [text]="'Reschedule Job'"
              [action]="rescheduleJob.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
          <tidy-row [align]="'center'">
            <tidy-text
              (action)="skipJob(bookingDetail?.id)"
              [body]="'Skip Job'"
              class="link">
            </tidy-text>
          </tidy-row>
        </ng-container>

        <!--Unassigned Scenario-->
        <ng-container *ngIf="cardDetail?.unassigned">
          <tidy-row *ngIf="cardDetail?.template_data?.scenario_type === 'unassigned'" class="extra-top-padding vertical-align-center extra-bottom-padding">
            <ng-container *ngIf="cardDetail?.unassigned?.call_to_action === 'Other Actions (No Action Needed)'; else actionNeeded">
              <tidy-image
                [src]="'assets/icon/searching.svg'"
                class="title-size wiggle-animation">
              </tidy-image>
              <tidy-text
                [title]="'Searching for Substitute'">
              </tidy-text>
            </ng-container>
            <ng-template #actionNeeded>
              <tidy-image
                [src]="'assets/img/alert.svg'"
                class="title-size">
              </tidy-image>
              <tidy-text
                [title]="'Action Needed'">
              </tidy-text>
            </ng-template>
          </tidy-row>
          <tidy-row *ngIf="cardDetail?.template_data?.scenario_type === 'manual_request'" class="extra-top-padding vertical-align-center extra-bottom-padding">
            <tidy-image
              [src]="'assets/icon/searching.svg'"
              class="title-size wiggle-animation">
            </tidy-image>
            <tidy-text
              [title]="cardDetail?.template_data?.scenario_name === 'same_day_cleaning_substitute_requested' ?
              'Searching for Pro' :
              'Searching for Substitute'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="cardDetail?.unassigned?.homekeeper" class="extra-bottom-padding">
            <tidy-text
              [header]="'Message from'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [header]="cardDetail?.unassigned?.homekeeper?.first_name + ':'"
              [translate]="false">
            </tidy-text><br>
            <ng-container *ngFor="let text of cardDetail?.unassigned?.homekeeper?.text">
              <tidy-text
                *ngIf="text?.type === 'string'"
                [body]="text?.value">
              </tidy-text>
              <br *ngIf="text?.type === 'br'">
            </ng-container>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <ng-container *ngIf="cardDetail?.unassigned?.homekeeper !== undefined">
              <tidy-text
                [header]="'Message from Concierge:'">
              </tidy-text><br>
            </ng-container>
            <ng-container *ngFor="let text of cardDetail?.unassigned?.concierge?.text">
              <tidy-text
                *ngIf="text?.type === 'string'"
                [body]="text?.value">
              </tidy-text>
              <ng-container *ngIf="text?.type === 'list'">
                <ng-container *ngFor="let item of text?.value">
                  <tidy-text
                    *ngIf="item?.type === 'string'"
                    [body]="item?.value">
                  </tidy-text>
                  <br *ngIf="item?.type === 'br'">
                </ng-container>
              </ng-container>
              <br *ngIf="text?.type === 'br'">
            </ng-container>
          </tidy-row>
          <tidy-row class="extra-bottom-padding" [alignLastItemRight]="cardDetail?.template_data?.scenario_type === 'manual_request'">
            <tidy-text
              [header]="schedule.callToActionText(cardDetail?.unassigned?.call_to_action)">
            </tidy-text>
            <tidy-text
              *ngIf="cardDetail?.template_data?.scenario_type === 'manual_request'"
              (action)="learnMoreSdc()"
              [body]="'Learn More'"
              class="link">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let button of cardDetail?.unassigned?.buttons">
            <tidy-row>
              <tidy-button
                [text]="button?.text"
                [action]="unassignedAction.bind(this, button?.action)"
                [ngClass]="button?.color === 'green' ? 'primary' : 'secondary'">
              </tidy-button>
            </tidy-row>
          </ng-container>
          <tidy-row>
            <tidy-button
              [text]="'Assign to Your Own Pro'"
              [action]="goToChangePro.bind(this)"
              class="secondary">
            </tidy-button>
          </tidy-row>
        </ng-container>
      </tidy-card>

      <tidy-row [alignLastItemRight]="true" *ngIf="cardDetail?.template_data?.job?.is_private && cardDetail?.template_data?.homekeepers?.length">
        <tidy-text
          *ngIf="cardDetail?.template_data?.homekeepers?.length"
          [header]="'Assigned Pro'">
        </tidy-text>
        <tidy-text
          *ngIf="cardDetail?.template_data?.homekeepers?.length == 1 && bookingDetail?.current_job?.is_private"
          [actionBody]="(cardDetail?.template_data?.homekeepers?.length > 0 && cardDetail?.template_data?.homekeepers[0]?.text !== 'Best Available Pro' && cardDetail?.template_data?.homekeepers[0]?.first_name !== 'Best Available Pro') ? 'Change Pro': 'Assign Pro'"
          class="link"
          (action)="goToChangePro()">
        </tidy-text>
      </tidy-row>

      <ng-container *ngIf="!isMessagesLoaded">
        <tidy-card>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
          <tidy-row
            class="vertical-align-center"
            style="padding-bottom: 0px; margin-bottom: -2px">
            <ion-skeleton-text
              animated
              class="message-skeleton-icon"></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="message-skeleton-subject"></ion-skeleton-text>
          </tidy-row>
          <tidy-row style="padding-bottom: 0px">
            <ion-skeleton-text
              animated
              class="message-skeleton-body"></ion-skeleton-text>
          </tidy-row>
        </tidy-card>
      </ng-container>

      <ng-container *ngFor="let item of activity">
        <tidy-card class="no-padding" style="padding-bottom: 15px;">
          <tidy-row class="extra-bottom-padding extra-top-padding extra-left-padding extra-right-padding" [ngClass]="!item?.pro?.first_name ? 'vertical-align-center' : ''" [alignLastItemRight]="item?.pro?.first_name">
            <tidy-image
              [src]="'assets/img/account.svg'"
              class="title-size">
            </tidy-image>
            <tidy-text
              [header]="item?.pro?.text ? item?.pro?.text : (item?.pro?.first_name + ' ' + (item?.pro?.last_name ? item?.pro?.last_name : ''))"
              [translate]="false">
            </tidy-text>
            <tidy-text
              [body]="'Call'"
              class="link"
              (action)="callPro(item?.pro)"
              *ngIf="item?.pro?.first_name">
            </tidy-text>
          </tidy-row>
          <ng-container *ngIf="item?.pro?.text !== 'No Pro Found' && item?.pro?.text !== 'Best Available Pro'">
            <tidy-row class="extra-left-padding extra-right-padding" style="padding-bottom: 0px">
              <form [formGroup]="form" novalidate action="#">
                <tidy-select
                  style="width:35%; font-size: 8px"
                  [form]="form.controls.messageType"
                  formControlName="messageType"
                  [items]="messageSelectItems"
                  [smallText]="true"
                  [selectedItem]="selectedMessageType"
                  (optionChange)="updateIsInternalComment($event)">
                </tidy-select>
              </form>
            </tidy-row>
            <tidy-row class="field-top-padding extra-left-padding extra-right-padding" style="padding-top: 3px">
              <form [formGroup]="form" novalidate action="#">
                <tidy-textarea
                  [label]="isInternalComment ? 'Add Internal Note' : 'Send Message'"
                  formControlName="message"
                  [form]="form.controls.message"
                  [submitted]="submitted"
                  [errorMessage]="'Please enter a message to send.'"
                  [rightIconAction]="sendMessage.bind(this, item?.pro)"
                  [leftIconAction]="sendAttachment.bind(this, item?.pro)"
                  [showBlackButtons]="isInternalComment">
                </tidy-textarea>
              </form>
            </tidy-row>
            <tidy-row *ngIf="item?.messages?.length == 0" [align]="'center'" class="extra-left-padding extra-right-padding extra-bottom-padding">
              <tidy-text
                [helper]="'No Messages'">
              </tidy-text>
            </tidy-row>
          </ng-container>
          <ng-container *ngFor="let activity of item?.messages; let last = last; let index = index">
            <message
              *ngIf="index < 3"
              [message]="activity"
              [last]="last"
              [showJobLink]="false"
              [proId]="item?.pro?.id">
            </message>
          </ng-container>
          <tidy-row [align]="'center'" *ngIf="item?.messages?.length > 3" class="extra-left-padding extra-right-padding extra-bottom-padding">
            <tidy-text
              class="link"
              [actionBody]="'View'"
              (action)="goToJobActivityPage(item)">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              class="link"
              [actionBody]="(item?.messages?.length - 3)"
              (action)="goToJobActivityPage(item)">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              class="link"
              [actionBody]="'More Update' + ((item?.messages?.length - 3 > 1) ? 's' : '')"
              (action)="goToJobActivityPage(item)">
            </tidy-text>
          </tidy-row>
        </tidy-card>
        <tidy-row *ngIf="showMarkNoShowText(item?.pro)" [align]="'center'" class="extra-left-padding extra-right-padding">
          <tidy-text
            (action)="markNoShow(item?.pro)"
            [body]="'Click here'"
            class="link">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            body="if">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            body="{{item?.pro?.first_name}}"
            [translate]="false">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            body="is not responding and did not show up.">
          </tidy-text>
        </tidy-row>
      </ng-container>

      <tidy-card *ngIf="cardDetail?.template_data?.homekeepers?.length == 0">
        <tidy-row class="vertical-align-center">
          <tidy-image
            [src]="'assets/img/account.svg'"
            class="title-size">
          </tidy-image>
          <tidy-text
            [header]="'Best Available Pro'">
          </tidy-text>
        </tidy-row>
      </tidy-card>

      <ng-container *ngIf="schedule.showToDoButton(cardDetail?.template_data?.scenario_name) && isToDosLoaded">
        <tidy-row>
          <tidy-text
            [header]="'To-Dos'">
          </tidy-text>
        </tidy-row>
        <form [formGroup]="form">
          <tidy-select
            class="white-select"
            [icon]="'assets/svg/list-outline.svg'"
            [label]="'To-Do List'"
            [form]="form.controls.list"
            formControlName="list"
            [items]="toDoFilter"
            (optionChange)="saveList()"
            [disableElement]="didProStartToDos">
          </tidy-select>
        </form>
        <tidy-row [align]="'center'" style="margin-top: -10px" *ngIf="!didProStartToDos">
          <tidy-text
            *ngIf="isUsingToDos && !isSavingToDoList"
            [helper]="'List saved to job.'">
          </tidy-text>
          <tidy-text
            *ngIf="!isUsingToDos && !isSavingToDoList"
            [helper]="'Change saved.'">
          </tidy-text>
          <tidy-text
            *ngIf="isSavingToDoList"
            [helper]="'Saving...'">
          </tidy-text>
        </tidy-row>
        <tidy-row *ngIf="didProStartToDos" [align]="'center'">
          <tidy-text
            [body]="(cardDetail?.template_data?.homekeepers[0]?.first_name | titlecase)">
          </tidy-text><span>&nbsp;</span>
          <ng-container *ngIf="cardDetail?.template_data?.homekeepers[1]">
            <tidy-text
              [body]="'and'">
            </tidy-text><span>&nbsp;</span>
            <tidy-text
              [body]="cardDetail?.template_data?.homekeepers[1]?.first_name | titlecase">
            </tidy-text><span>&nbsp;</span>
          </ng-container>
          <tidy-text
            [body]="cardDetail?.template_data?.homekeepers[1] ? 'are' : 'is'">
          </tidy-text><span>&nbsp;</span>
          <tidy-text
            [body]="'working on your To-Do list now:'">
          </tidy-text>
        </tidy-row>
        <ng-container *ngIf="isUsingToDos">
          <tidy-card>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <tidy-image
                  [src]="'assets/img/camera.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'Before Photos / Videos'">
                </tidy-text>
              </div>
            </div>
            <div style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Photo'"
                (action)="takePhoto('before_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'image/*'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                (addedImagesChange)="uploadPhoto($event, 'before_job')"
                [showTextLink]="true"
                style="margin-right: 10px">
              </tidy-file-upload>
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Video'"
                (action)="takeVideo('before_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'video/mp4'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                (addedImagesChange)="uploadVideo($event, 'before_job')"
                [showTextLink]="true">
              </tidy-file-upload>
            </div>
            <tidy-row *ngIf="beforePhotos?.length == 0" class="extra-top-padding">
              <tidy-text
                [helper]="'None Added'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="beforeAfterState.before">
              </tidy-text>
            </tidy-row>
            <ion-row>
              <ng-container *ngFor="let item of beforePhotos; let i = index">
                <ion-col [size]="12">
                  <ng-container *ngIf="item.mediaType === 'video'">
                    <tidy-row>
                      <tidy-video
                        class="before-after-video"
                        [src]="item?.url"
                        style="width: 100%; height: auto; max-width: 100%;">
                      </tidy-video>
                    </tidy-row>
                  </ng-container>
                  <ng-container *ngIf="item.mediaType === 'photo'">
                    <tidy-photo-note
                      *ngIf="item?.url"
                      class="full-width no-margin photo-note-job"
                      [src]="item?.url"
                      style="width: 100%; height: auto; max-width: 100%;">
                    </tidy-photo-note>
                  </ng-container>
                  <div *ngIf="item?.metadata || item?.uploaded_by_id" class="timestamp-caption">
                    <tidy-row [alignLastItemRight]="true">
                      <tidy-text
                        [smallBody]="'Uploaded By:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="item?.uploaded_by_type == 'CustomerMember' ? 'You' : 'Pro'"
                        class="white">
                      </tidy-text>
                      <div>
                        <tidy-image
                          [src]="'assets/img/trash-outline-white.svg'"
                          (action)="removePhoto(item, i, 'before_job')"
                          class="body-size"
                          style="cursor: pointer">
                        </tidy-image>
                      </div>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.location">
                      <tidy-text
                        [smallBody]="'Location:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="item?.metadata?.location"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.displayDate">
                      <tidy-text
                        [smallBody]="'Timestamp:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma'"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                  </div>
                </ion-col>
              </ng-container>
            </ion-row>
          </tidy-card>
          <tidy-card *ngFor="let room of rooms">
            <tidy-row class="vertical-align-center extra-bottom-padding">
              <tidy-image
                *ngIf="room?.icon"
                [src]="'assets/img/icons/rooms/' + room?.icon + '.svg'"
                class="header-size">
              </tidy-image>
              <tidy-text
                [header]="room?.name"
                [translate]="false">
              </tidy-text>
            </tidy-row>
            <!--To-Dos for a job that hasn't been started:-->
            <ng-container *ngFor="let toDo of room?.tasks; let lastToDo = last">
              <ng-container *ngIf="!toDo?.task_type?.includes('video')">
                <tidy-row [ngClass]="lastToDo ? '' : 'extra-bottom-padding'">
                  <span [ngStyle]="toDos.important(toDo?.is_important)" class="vertical-align-center">
                    <tidy-text
                      [body]="toDo?.title">
                    </tidy-text>
                    <!--TODO replace this inline style with a class margin-left-->
                    <tidy-image
                      *ngIf="toDo?.is_important"
                      style="margin-left: 5px"
                      [src]="'assets/svg/star.svg'">
                    </tidy-image>
                  </span>
                  <ng-container *ngIf="toDo?.note !== ''">
                    <tidy-text
                      [helper]="toDo?.note">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
                <div class="slide-container">
                  <tidy-carrousel
                    *ngIf="toDo?.photo_notes?.length > 0"
                    class="photo-notes-carrousel only-padding-bottom">
                    <ng-container *ngFor="let photoNote of toDo?.photo_notes">
                      <tidy-photo-note
                        class="full-width there-is-carrousel no-margin"
                        *tidyCarrousel
                        [caption]="photoNote.photo_note_caption"
                        [src]="photoNote.photo_url"
                        ></tidy-photo-note>
                    </ng-container>
                  </tidy-carrousel>
                </div>
              </ng-container>
            </ng-container>
            <!--To-Dos for an ongoing job:-->
            <ng-container *ngFor="let toDo of room?.todos; let lastToDo = last">
              <tidy-row class="extra-bottom-padding" *ngIf="toDo?.title !== 'Take Before Photo' && toDo?.title !== 'Take After Photo' && toDo?.title !== 'Take Before Video' && toDo?.title !== 'Take After Video'" [ngClass]="!lastToDo ? 'extra-bottom-padding vertical-align-middle' : 'vertical-align-middle'">
                <tidy-image
                  [matTooltip]="(toDos.getToDoTooltip(toDo?.performance)) | translate"
                  [src]="toDos.getToDoIcon(toDo?.performance)"
                  class="body-size">
                </tidy-image>
                <tidy-text
                  [body]="toDo?.title"
                  [translate]="false">
                </tidy-text>
              </tidy-row>
              <ng-container *ngFor="let option of toDo?.options; let last = lastOption">
                <tidy-row *ngIf="option?.value_type !== 'photo' && option?.value_type !== 'photos'" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                  <ng-container *ngIf="option?.notes && (option?.json_value || option?.value)">
                    <tidy-text
                      [body]="option?.name + ': ' + option?.notes"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'Input: ' + (option?.json_value || option?.value)"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="!option?.notes && (option?.json_value || option?.value)">
                    <tidy-text
                      [body]="option?.name"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'Input:'"
                      [translate]="false">
                    </tidy-text><span>&nbsp;</span>
                    <tidy-text
                      [body]="option?.json_value || option?.value"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="option?.notes && !option?.value && !option?.json_value">
                    <tidy-text
                      [body]="option?.name + ': ' + option?.notes"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [body]="'No value added by pro'"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                  <ng-container *ngIf="!option?.notes && !option?.value && !option?.json_value">
                    <tidy-text
                      [body]="option?.name"
                      [translate]="false">
                    </tidy-text><br>
                    <tidy-text
                      [helper]="'No value added by pro'"
                      [translate]="false">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
                <tidy-row *ngIf="option?.value_type?.includes('photo')" [ngClass]="(!lastOption && !lastToDo) ? 'extra-bottom-padding' : ''">
                  <tidy-text
                    *ngIf="option?.notes"
                    [body]="option?.name + ': ' + option?.notes"
                    [translate]="false">
                  </tidy-text>
                  <tidy-text
                    *ngIf="!option?.notes"
                    [body]="option?.name"
                    [translate]="false">
                  </tidy-text>
                  <tidy-photo-note
                    *ngIf="option?.value"
                    class="full-width no-margin photo-note-job"
                    [src]="option?.value">
                  </tidy-photo-note>
                  <div *ngIf="option?.metadata">
                    <tidy-row>
                      <div class="timestamp-caption" *ngIf="option?.metadata?.location">
                        <tidy-text
                          [smallBody]="'Location:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="option?.metadata?.location"
                          class="white">
                        </tidy-text>
                      </div>
                      <div class="timestamp-caption" *ngIf="option?.metadata?.displayDate">
                        <tidy-text
                          [smallBody]="'Timestamp:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma'"
                          class="white">
                        </tidy-text>
                      </div>
                    </tidy-row>
                  </div>
                  <ng-container *ngFor="let option of option?.json_value">
                    <tidy-photo-note
                      *ngIf="option"
                      class="full-width no-margin photo-note-job"
                      [src]="option">
                    </tidy-photo-note>
                  <div *ngIf="option?.metadata">
                    <tidy-row>
                      <div class="timestamp-caption" *ngIf="option?.metadata?.location">
                        <tidy-text
                          [smallBody]="'Location:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="option?.metadata?.location"
                          class="white">
                        </tidy-text>
                      </div>
                      <div class="timestamp-caption" *ngIf="option?.metadata?.displayDate">
                        <tidy-text
                          [smallBody]="'Timestamp:'"
                          class="white">
                        </tidy-text><span>&nbsp;</span>
                        <tidy-text
                          [smallBody]="option?.metadata?.displayDate | parseDate: 'ddd M/D h:mma'"
                          class="white">
                        </tidy-text>
                      </div>
                    </tidy-row>
                  </div>
                  </ng-container>
                  <ng-container *ngIf="!option?.value && !option?.json_value">
                    <br>
                    <tidy-text
                      [helper]="'No photo taken by pro'">
                    </tidy-text>
                  </ng-container>
                </tidy-row>
              </ng-container>
            </ng-container>
          </tidy-card>
          <tidy-card>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <tidy-image
                  [src]="'assets/img/camera.svg'"
                  class="header-size">
                </tidy-image>
                <tidy-text
                  [header]="'After Photos / Videos'">
                </tidy-text>
              </div>
            </div>
            <div style="margin-top: 10px; display: flex; align-items: center; align-items: center;">
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Photo'"
                (action)="takePhoto('after_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'image/*'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Photo' : 'Add Photo'"
                (addedImagesChange)="uploadPhoto($event, 'after_job')"
                [showTextLink]="true"
                style="margin-right: 10px">
              </tidy-file-upload>
              <tidy-text
                *ngIf="isNativeMobile"
                [actionBody]="'Take Video'"
                (action)="takeVideo('after_job')"
                class="link"
                style="margin-right: 10px">
              </tidy-text>
              <tidy-file-upload
                [accept]="'video/mp4'"
                [showPreview]="false"
                [label]="isNativeMobile ? 'Upload Video' : 'Add Video'"
                (addedImagesChange)="uploadVideo($event, 'after_job')"
                [showTextLink]="true">
              </tidy-file-upload>
            </div>
            <tidy-row *ngIf="afterPhotos?.length == 0" class="extra-top-padding">
              <tidy-text
                [helper]="'None Added'">
              </tidy-text><span>&nbsp;</span>
              <tidy-text
                [helper]="beforeAfterState.after">
              </tidy-text>
            </tidy-row>
            <ion-row>
              <ng-container *ngFor="let item of afterPhotos; let i = index">
                <ion-col [size]="12">
                  <ng-container *ngIf="item.mediaType === 'video'">
                    <tidy-row>
                      <tidy-video
                        class="before-after-video"
                        [src]="item?.url"
                        style="width: 100%; height: auto; max-width: 100%;">
                      </tidy-video>
                    </tidy-row>
                  </ng-container>
                  <ng-container *ngIf="item.mediaType === 'photo'">
                    <tidy-photo-note
                      *ngIf="item?.url"
                      class="full-width no-margin photo-note-job"
                      [src]="item?.url"
                      style="width: 100%; height: auto; max-width: 100%;">
                    </tidy-photo-note>
                  </ng-container>
                  <div *ngIf="item?.metadata || item?.uploaded_by_id" class="timestamp-caption">
                    <tidy-row [alignLastItemRight]="true">
                      <tidy-text
                        [smallBody]="'Uploaded By:' +  (item?.uploaded_by_type == 'CustomerMember' ? ' You' : 'Pro')"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="(item?.uploaded_by_type == 'CustomerMember' ? ' You' : 'Pro')"
                        class="white">
                      </tidy-text>
                      <div>
                        <tidy-image
                          [src]="'assets/img/trash-outline-white.svg'"
                          (action)="removePhoto(item, i, 'after_job')"
                          class="body-size"
                          style="cursor: pointer">
                        </tidy-image>
                      </div>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.location">
                      <tidy-text
                        [smallBody]="'Location:' +  item?.metadata?.location"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="item?.metadata?.location"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                    <tidy-row *ngIf="item?.metadata?.displayDate">
                      <tidy-text
                        [smallBody]="'Timestamp:'"
                        class="white">
                      </tidy-text><span>&nbsp;</span>
                      <tidy-text
                        [smallBody]="(item?.metadata?.displayDate | parseDate: 'ddd M/D h:mma')"
                        class="white">
                      </tidy-text>
                    </tidy-row>
                  </div>
                </ion-col>
              </ng-container>
            </ion-row>
          </tidy-card>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!isMapLoaded">
      <tidy-row>
        <ion-skeleton-text
          animated
          style="width: 100%; height: 300px; border-radius: 6px;">
        </ion-skeleton-text>
      </tidy-row>
    </ng-container>

    <tidy-card [style]="!mapInstance ? 'padding: 0px; display: none;' : 'padding: 0px'">
      <div attr.id="map{{jobId}}" #map  style="width: 100%; height: 300px">
      </div>
    </tidy-card>

    <ng-container *ngIf="!isToDosLoaded">
      <tidy-row>
        <ion-skeleton-text
          animated
          slot="start"
          style="width: 140px; height: 16px; border-radius: 6px;">
        </ion-skeleton-text>
      </tidy-row>
      <tidy-card style="padding: 0px; height: 200px; padding: 0px;">
        <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px;"></ion-skeleton-text>
      </tidy-card>
      <tidy-card style="padding: 0px; height: 200px; padding: 0px;">
        <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px;"></ion-skeleton-text>
      </tidy-card>
      <tidy-card style="padding: 0px; height: 200px; padding: 0px;">
        <ion-skeleton-text animated style="width: 100%; height: 200px; border-radius: 6px;"></ion-skeleton-text>
      </tidy-card>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [canGoBack]="true"
    [title]="'Request Substitute'">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="!loaded && errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-row [align]="'center'">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [body]="'Select times you\'re available, we\'ll notify all pros near you, and send updates.'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [helper]="'or'"
            class="divider">
          </tidy-text>
        </tidy-row>
        <tidy-row>
          <tidy-text
            [body]="'Select a confirmed time & we\'ll instantly book you with the available pro.'">
          </tidy-text>
        </tidy-row>
      </tidy-row>

      <ng-container *ngFor="let weekday of substituteTimes; let i = index">
        <tidy-card>
          <tidy-row>
            <tidy-text
              [header]="(weekday.date | customdate: 'ddd') + ' ' + (weekday.date | customdate: 'MM/DD')">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let time of weekday.times; let dayIndex = index">
            <tidy-checkbox
              [items]="[{viewValue: (time.homekeepers.length > 0?
                ((weekday.date + ' ' + time.cleaning.start_time | customdate: 'h:mm a') + ' (confirmed)') :
                (weekday.date + ' ' + time.cleaning.start_time | customdate: 'h:mm a')), value: true}]"
              [(ngModel)]="time.selected"
              (checkedChange)="selectTime(time, i, dayIndex)"
              [checked]="substituteTimes[i].times[dayIndex].selected"
              [initValue]="false">
            </tidy-checkbox>
          </ng-container>
        </tidy-card>
      </ng-container>

      <tidy-row>
        <tidy-text
          [body]="'Click here'"
          (action)="rescheduleCleaning()"
          class="link">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="'to book later than'">
        </tidy-text><span>&nbsp;</span>
        <tidy-text
          [body]="(substituteTimes[substituteTimes.length -1].date | customdate: 'MM/DD') + '.'">
        </tidy-text>
      </tidy-row>

      <tidy-row *ngIf="errorMessage">
        <tidy-error-message
          [text]="errorMessage">
        </tidy-error-message>
      </tidy-row>

      <tidy-button
        style="margin-bottom: 30px"
        [text]="'Review Request'"
        [action]="goToConfirmRequest.bind(this)"
        class="primary">
      </tidy-button>
    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

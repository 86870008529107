import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Menu } from 'src/providers/menu/menu';

import { AddressModel } from 'src/models/address.model';
import { Util } from 'src/shared/util/util';

import { Loading } from 'src/shared/components/loading/loading';
import { WindowService } from 'src/shared/providers/window.service';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { GuestReservationSource } from 'src/models/guest-reservations';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { AutomaticBookingAddressPage } from './automatic-booking-address/automatic-booking-address';

@Component({
  templateUrl: 'automatic-booking.html'
})
export class AutomaticBookingPage extends TimeoutableComponent implements OnInit {

  allowNewPros: boolean;
  addresses: AddressModel[];
  addressResponse: AddressModel[];
  backPage: string;
  form: UntypedFormGroup;
  hasSyncPending: boolean;
  showBackButton: boolean;
  activeIntegrations: GuestReservationSource[];
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private guestReservations: GuestReservations,
    private menu: Menu,
    private navCtrl: CustomNavController,
    private util: Util,
    private windowService: WindowService,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    super();
    this.form = this.fb.group({
      search: [''],
    });
    this.form.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(val => this.updateSearch(val));
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Automatic Booking');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.menu.selectedAutomation = 'automatic-booking';
      if (!this.windowService.isDesktopRes) {
        this.backPage = localStorage.getItem('automaticBookingBackPage') || 'more';
      }
      this.addressResponse = await this.client.getMoreDetailAddresses();
      this.addresses = this.addressResponse;
      this.activeIntegrations = await this.guestReservations.getReservationSource();
      this.hasSyncPending = this.checkIfHasSyncPending(this.activeIntegrations);
      this.showBackButton = await this.getParam('showBackButton');
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  checkIfHasSyncPending(activeIntegrations) {
    let hasSyncPending = false;
    activeIntegrations.some((integration) => {
      if (integration.guest_reservation_fetch_status == 'pending' && integration.guest_reservation_source_type?.format !== 'concierge_integration') {
        hasSyncPending = true;
      }
    });
    return hasSyncPending;
  }

  goToAutomaticBookingAddress(address) {
    localStorage.setItem('automaticBookingAddressBackPage', 'automatic-booking');
    const foundIntegration = this.activeIntegrations.find((integration) => {
      return integration.address_id === address.id;
    })
    const params = {
      address: address,
      integration: foundIntegration,
      cameFromAddIntegration: false
    }
    this.rightSidePanelService.navigateTo(`automatic-booking-property/${address.id}`, params, AutomaticBookingAddressPage);
  }

  learnMoreAutomaticBooking() {
    this.util.openUrl('https://help.tidy.com/integrations');
  }

  goToAddIntegration() {
    this.navCtrl.navigateForward('integrations/all');
  }

  updateSearch({search}) {
    if (search.length < 2) {
      return this.addresses = this.addressResponse;
    }
    const term = search.toLowerCase();
    this.addresses = this.addressResponse.filter((option) => {
      if (option.address1.toLowerCase().indexOf(term) > -1 || option.address_name?.toLowerCase().indexOf(term) > -1) {
        return option;
      }
    });
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Bill Settings'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card>
        <tidy-row class="field-bottom-padding">
        <form [formGroup]="form">
          <tidy-input
            formControlName="email"
            [label]="'Email to send bills to'"
            [form]="form.controls.email"
            type="email"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid email.'"
            [icon]="'assets/svg/mail-outline.svg'"
            [activeIcon]="'assets/svg/mail-outline-tidy-green.svg'">
          </tidy-input>
        </form>
        </tidy-row>
      </tidy-card>

      <tidy-button
        [text]="'Save Changes'"
        [action]="save.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>

    <tidy-error-message
      [text]="errorMessage">
    </tidy-error-message>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

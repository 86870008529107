import { Component, ViewEncapsulation, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'tidy-card-button',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card-button.component.scss'],
  template: `
    <ng-container >
      <div
        (click)="executeAction()"
      >
        <ng-content></ng-content>
        <ion-icon
          *ngIf="!hideArrow"
          [ngStyle]="customArrowPositon"
          name="chevron-forward-outline"
          class="right-item chevron-icon">
        </ion-icon>
        <tidy-image
          *ngIf="customIcon"
          [ngStyle]="customArrowPositon"
          matRipple
          [matRippleRadius]="10"
          [matRippleCentered]="true"
          class="right-item custom-icon header-size"
          [src]="customIcon">
        </tidy-image>
      </div>
    </ng-container>
  `
})
export class CardButtonComponent {

  @Input() target: string;
  @Input() hideArrow: boolean;
  @Input() customIcon: string;
  @Input() customArrowPositon: { top?: string, right?: string, left?: string, bottom?: string };
  @HostBinding('class.normal-cursor') isNormalCursor: boolean;

  ngOnInit() {
    this.isNormalCursor = this.hideArrow;
  }

  @HostBinding('style.padding-right') get getPadding () {
    return (this.hideArrow && !this.customIcon) ? '0px' : '';
  }

  cooldown = false;

  @Output() action: EventEmitter<any> = new EventEmitter();

  executeAction() {
    if (this.cooldown) return;
    this.cooldown = true;
    setTimeout(() => this.cooldown = false, 500);
    this.action.emit();
  }
}

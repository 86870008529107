import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Aws } from "src/providers/aws/aws";

declare var MediaRecorder: any;
declare const navigator: any;

@Component({
  templateUrl: 'camera-preview.component.html',
  selector: 'tidy-camera-preview',
  styleUrls: ['./camera-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CameraPreviewComponent implements OnInit {

  logdebug: any;
  erroMsg: any;
  mediaRecorder: any;
  recordedChunks = [];
  videoElem: HTMLMediaElement;
  hasRecorded: boolean;
  awsUrl: any;
  cameraStatus: string;
  @Output() videoUrl: EventEmitter<string> = new EventEmitter<string>();
  @Output() status: EventEmitter<string> = new EventEmitter<string>();
  videoIsUploading: boolean;

  constructor(
    private aws: Aws
  ) {}

  async ngOnInit() {
    let error: string;
    try {
      await this.setStream();
    } catch (err) {
      let retryCnt = 0;
      this.erroMsg = JSON.stringify(err);
      while (retryCnt < 4) {
        retryCnt++;
        this.setStream().catch(err => {
          error  = (err.error && err.error.message) ? err.error.message : err.message;
        });
      }
      this.erroMsg = error;
    }
  }

   getDevices(deviceInfos) {
     let videoSrc;
     for (let i = 0; i !== deviceInfos.length; ++i) {
       const deviceInfo = deviceInfos[i];
       if (deviceInfo.kind === 'videoinput') {
         videoSrc = deviceInfo.deviceId;
         break;
       }
     }
   }

   getdeviceScr() {
     navigator.mediaDevices.enumerateDevices()
     .then((info) => {
       this.getDevices(info);
     }).then(() => this.setStream()).catch((e) => {
       this.logdebug = e;
     });
   }

  hasGetUserMedia(){
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  async setStream() {
    this.hasRecorded = false;
    this.cameraStatus = 'standby';
    this.recordedChunks = [];

    navigator.getMedia = ( navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.mediaDevices?.getUserMedia);

    let stream = null;
    const constraints: MediaStreamConstraints = { audio: false, video: { width: 300, height: 300 } };

    if (this.hasGetUserMedia()) {
    } else {
      return alert("getUserMedia() is not supported by your browser");
    }
      this.logdebug = JSON.stringify(navigator.mediaDevices?.getUserMedia);

      stream = await navigator.mediaDevices?.getUserMedia(constraints)
      .then((mediaStream) => {
        const video = document.querySelector('video');

        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');

        video.srcObject = mediaStream;
        video.onloadedmetadata = (e) => {
          video.play();
        };

        this.mediaRecorder = new MediaRecorder(mediaStream);

        this.mediaRecorder.onstop = (err) => {
          this.erroMsg = (err.error && err.error.message) ? err.error.message : err.message;
        }

        this.mediaRecorder.ondataavailable = (e) => {
          this.handleDataAvailable(e);
        };
      }).catch((err) => {
        this.erroMsg = (err.error && err.error.message) ? err.error.message : err.message;
      });
  }

  handleDataAvailable(event) {
    if (event.data.size > 0) {
      this.recordedChunks.push(event.data);
      this.download();
    }
  }

  async download() {
    this.videoIsUploading = true;
    const blob = new Blob(this.recordedChunks, {
      type: "video/webm"
    });
    try {
    const timeStr = new Date().getTime().toString();
    const awsInfo = await this.aws.uploadFileToS3(blob, `property-recording/${timeStr}`, 'video/webm');
    this.awsUrl = awsInfo?.Location;
    this.hasRecorded = true;
    } catch (err) {
      this.erroMsg = (err.error && err.error.message) ? err.error.message : err.message;
    }
    this.videoIsUploading = false;
  }

  record() {
    this.mediaRecorder.start();
    this.cameraStatus = 'recording'
    this.status.emit(this.mediaRecorder.state);
  }

  stop() {
    this.mediaRecorder.stop();
    this.status.emit(this.mediaRecorder.state);
  }

  captureSuccess = function(mediaFiles) {
    let i, path, len;
    for (i = 0, len = mediaFiles.length; i < len; i += 1) {
        path = mediaFiles[i].fullPath;
    }
  };

  captureError = function(error) {
    navigator.notification.alert('Error code: ' + error.code, null, 'Capture Error');
  };

  nativeVideoCapture() {
    navigator.device.capture.captureVideo(this.captureSuccess, this.captureError, {limit: 2});
  }

  submitVideo(){
    this.videoUrl.emit(this.awsUrl);
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Card'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

      <ng-container *ngIf="loaded">

        <tidy-row [align]="'center'">
          <tidy-text
            [body]="'Use cards to group To-Dos together for your pro.'">
          </tidy-text>
        </tidy-row>

        <tidy-card>
          <tidy-row class="extra-bottom-padding">
            <tidy-radio-button
              [label]="'Does this card relate to a room in your home?'"
              formControlName="relation"
              [form]="form.controls.relation"
              [items]="relateItems">
            </tidy-radio-button>
          </tidy-row>
          <ng-container *ngIf="form.value.relation == 'yes'">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-auto-complete
                [label]="'Room'"
                [showGoogleIcon]="false"
                formControlName="name"
                [options]="rooms"
                [form]="form.controls.name"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [isToSearchBetweenOptions]="true"
                [submitted]="submitted"
                [errorMessage]="'Please enter the name of the room.'">
              </tidy-auto-complete>
            </tidy-row>
            <tidy-row class="field-bottom-padding">
              <tidy-select
                [icon]="'assets/svg/home-outline.svg'"
                [label]="'Floor'"
                [form]="form.controls.floor"
                formControlName="floor"
                [items]="floorSelectItems">
              </tidy-select>
            </tidy-row>
            <tidy-row>
              <tidy-select
                [icon]="'assets/svg/ellipsis-horizontal-circle-outline.svg'"
                [label]="'Room Type'"
                [form]="form.controls.category"
                formControlName="category"
                [items]="roomTypeSelectItems">
              </tidy-select>
            </tidy-row>
          </ng-container>
          <ng-container *ngIf="form.value.relation == 'no'">
            <tidy-row class="field-top-padding field-bottom-padding">
              <tidy-auto-complete
                [label]="'Card Name'"
                [showGoogleIcon]="false"
                formControlName="name"
                [options]="rooms"
                [form]="form.controls.name"
                [icon]="'assets/svg/information-circle-outline.svg'"
                [isToSearchBetweenOptions]="true"
                [submitted]="submitted"
                [errorMessage]="'Please enter the name of the card.'">
              </tidy-auto-complete>
            </tidy-row>
          </ng-container>
        </tidy-card>

        <tidy-button
          [text]="'Add Card'"
          [action]="addRoom.bind(this)"
          class="primary">
        </tidy-button>

      </ng-container>
    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

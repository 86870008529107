import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkifyjs/html';

@Pipe({
  name: 'parseText'
})
export class ParseTextPipe implements PipeTransform {
  transform(value: string, checkLineBreaks: boolean, linkify: boolean): string {
    let transformedText = value;
    
    if(checkLineBreaks) {
      transformedText = transformedText.replace(/(↵|\n|\u21b5)/g, '<br/>');
    }

    if(linkify) {
      transformedText = linkifyHtml(transformedText, {target: '_blank'});
    }
    
    return transformedText;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Room'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-card>
      <form [formGroup]="form">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-auto-complete
            [label]="'Room'"
            [showGoogleIcon]="false"
            formControlName="name"
            [options]="rooms"
            [form]="form.controls.name"
            [icon]="'assets/svg/information-circle-outline.svg'"
            [isToSearchBetweenOptions]="true"
            [submitted]="submitted"
            [errorMessage]="'Please enter the name of the room.'">
          </tidy-auto-complete>
        </tidy-row>
        <tidy-row class="field-bottom-padding">
          <tidy-select
            [icon]="'assets/svg/home-outline.svg'"
            [label]="'Floor'"
            [form]="form.controls.floor"
            formControlName="floor"
            [items]="floorSelectItems">
          </tidy-select>
        </tidy-row>
        <tidy-row>
          <tidy-select
            [icon]="'assets/svg/ellipsis-horizontal-circle-outline.svg'"
            [label]="'Room Type'"
            [form]="form.controls.category"
            formControlName="category"
            [items]="roomTypeSelectItems">
          </tidy-select>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [text]="'Add Room'"
      [action]="addRoom.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Pros } from "src/providers/pros/pros";
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { SuccessPageParamsModel } from "src/shared/pages/success/success";
import { CustomNavController } from "src/shared/providers/navigation/custom-nav-controller";
import validationUtils from "src/shared/util/validation-utils";
import { Util } from 'src/shared/util/util';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';

@Component({
  templateUrl: 'edit-pro.html'
})
export class MyProsEditProPage {

  private pro: Partial<{
    id: string;
    name: string;
    email: string;
    phone: string;
  }>;
  private proId: number;
  public form: FormGroup;
  public submitted: boolean;
  public errorMessage: string;
  loaded: boolean;
  isRightSideContent: boolean;
  dialogParams: any;

  constructor(
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private pros: Pros,
    private fb: FormBuilder,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, validationUtils.validateEmail]],
      phone: ''
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.rightSidePanelService.setDialogPageTitle('Edit Pro');
    }
    this.pro = this.navCtrl.getParam('pro') || this.dialogParams.pro;
    this.proId = +this.route.snapshot.paramMap.get('proId') || this.dialogParams.proId;
    if (!this.pro) {
      try {
        await this.retrieveProDetail();
      } catch (err) {
        this.navCtrl.back();
      }
    }
    this.fillForm();
    this.loaded = true;
  }

  async retrieveProDetail() {
    const proDetail = await this.pros.getProDetail(this.proId);
    this.pro = proDetail.homekeeper;
  }

  fillForm() {
    this.form.patchValue(this.pro);
  }

  async update() {
    this.submitted = true;
    if (this.form.invalid) return;
    try {
      const proInfo = this.form.value;
      await this.pros.updateProInfo(this.proId, proInfo);
      this.util.showSuccess('Payment Sent');
      const params = {
        proId: this.proId
      }
      this.rightSidePanelService.navigateTo(`my-pro/${this.proId}`, params, MyProPage);
    } catch (err) {
      this.errorMessage = err?.error?.message ?? err?.message;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from './../../shared/providers/tidy-storage';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';
import { Loading } from 'src/shared/components/loading/loading';
import {
  GuestReservationResponse,
  Job,
} from 'src/models/schedule-weekly-view.model';
import { Util } from 'src/shared/util/util';
import { AlertOptions } from '@ionic/core';
import { PastJobPage } from '../more/past-job/past-job';
import { PastPrivateJobPage } from '../more/past-private-job/past-private-job';
import { JobPage } from '../schedule/job/job';
import { Schedule } from 'src/providers/schedule/schedule';
import { SuccessPage } from 'src/shared/pages/success/success';
import { TranslationPipe } from 'src/shared/pipes/translation.pipe';
import { Integrations } from 'src/providers/integrations/integrations';

@Component({
  templateUrl: 'reservation.html',
})
export class ReservationPage implements OnInit {

  address: any;
  guestReservation: GuestReservationResponse;
  detail: any;
  reservationDetails: any;
  hasReservationDetails = false;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;
  blockingJobs: any[] = [];
  calendarReservations: any[] = [];
  isLoadingBlockedData = false;
  automaticBookingSettings: any;
  customFields: any[] = [];

  constructor(
    private guestReservations: GuestReservations,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private utils: Util,
    private rightSidePanelService: RightSidePanelService,
    private schedule: Schedule,
    private integrations: Integrations
  ) {}

  async ngOnInit() {
    this.isRightSideContent =
      (await this.storage.retrieve('dialog-right-side-open')) || false;
    this.rightSidePanelService.setDialogPageTitle('Reservation');
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loadDataAndParams();
  }

  @Loading('', true)
  async loadDataAndParams() {
    try {
      this.loaded = false;
      this.guestReservation =
        this.dialogParams?.guestReservation ||
        (await this.navCtrl.getParam('guestReservation', true));
      this.reservationDetails =
        this.guestReservation?.details ||
        this.guestReservation?.object?.details;
      this.hasReservationDetails =
        !!this.reservationDetails &&
        Object.keys(this.reservationDetails).length > 0;
      const id = this.guestReservation.id;
      this.address =
        this.dialogParams?.address ||
        (await this.navCtrl.getParam('address', true));
      this.detail = await this.guestReservations.getReservationById(id);
      this.customFields = await this.getCustomFields(this.detail);
      this.checkForOverlappedReservations();
      this.checkForBlockedJobs();
      this.loaded = true;
      this.automaticBookingSettings = await this.guestReservations.getReservationSource();
    } catch (err) {
      console.error('Error loading data and params:', err);
    }
  }

  async getCustomFields(detail: any) {
    try {
      const userCustomFields = await this.integrations.getCustomFields();
      const customFields = detail?.custom_fields;
      return Object.keys(customFields).map((key) => {
        const userCustomField = userCustomFields.find((field) => field.field_key == key);
        return {
          name: userCustomField.field_name,
          value: customFields[key],
        };
      });
    } catch (err) {
      console.error('Error getting custom fields:', err);
      return [];
    }
  }

  async checkForOverlappedReservations(): Promise<void> {
    try {
      const foundOverlappedReservations: any[] =
        this.detail?.automatic_booking_statuses?.filter(
          (status) => status?.metadata?.overlapped_by_guest_reservation
        ).map(
          (status) => status?.metadata?.overlapped_by_guest_reservation
        ).flat();
      if (foundOverlappedReservations.length > 0) {
        this.isLoadingBlockedData = true;
        const reservationPromises = foundOverlappedReservations.map(async (overlappedData) => {
          const checkInDate = overlappedData.check_in_date;
          const checkOutDate = overlappedData.check_out_date;
          const calendarResponse = await this.schedule.getScheduleCalendar(
            checkInDate,
            checkOutDate,
            {},
            'reservations'
          );
          const calendarReservations = calendarResponse
            .map((items) => items.reservations)
            .flat();
          return this.schedule.parseCalendarReservations(
            calendarReservations,
            this.address,
            { startDate: checkInDate, endDate: checkOutDate },
            []
          ).filter(
            (reservation) =>
              overlappedData?.id === reservation?.id
          );
        });
        const allParsedReservations = await Promise.all(reservationPromises);
        this.calendarReservations = allParsedReservations.flat();
        this.isLoadingBlockedData = false;
      }
    } catch (err) {
      this.isLoadingBlockedData = false;
      this.utils.showError('Error checking for overlapped reservations ' + err);
      console.error('Error checking for overlapped reservations: ', err);
    }
  }

  async checkForBlockedJobs(): Promise<void> {
    try {
      const foundBlockedByOtherJob =
      this.detail?.automatic_booking_statuses?.find(
        (status) => status?.key === 'blocked_by_other_job'
      );
      if (foundBlockedByOtherJob) {
        this.isLoadingBlockedData = true;
        const checkInDate = this.guestReservation.check_in_date;
        const checkOutDate = this.guestReservation.check_out_date;
        const calendarResponse = await this.schedule.getScheduleCalendar(
          checkInDate,
          checkOutDate,
          {},
          'jobs'
        );
        const calendarJobs = calendarResponse.map((items) => items.jobs).flat();
        const parsedJobs = this.schedule.parseCalendarJobs(
          calendarJobs,
          this.address
        );
        this.blockingJobs = parsedJobs.filter((job) =>
          foundBlockedByOtherJob?.metadata?.booking_ids?.includes(
            job?.booking?.id
          )
        );
      }
      this.isLoadingBlockedData = false;
    } catch (err) {
      this.isLoadingBlockedData = false;
      this.utils.showError(new TranslationPipe().transform('Error checking for blocked jobs ' + err));
    }
  }

  goToJobPage(cleaning: any) {
    const addressId = cleaning?.address_id;
    const isMobileResolution = window.innerWidth <= 870;
    const isPastJob =
      cleaning.status.name === 'cancelled' ||
      cleaning.status.name === 'completed';
    const isPrivateJob = cleaning.isPrivate;
    if (!isMobileResolution) {
      this.navigateToJobPageInsideDialog(
        cleaning,
        addressId,
        isPastJob,
        isPrivateJob
      );
      return;
    }
    localStorage.setItem('pastJobBackPage', 'schedule');
    if (isPastJob) {
      if (isPrivateJob) {
        this.navCtrl.navigateForward([`past-private-job/${cleaning.id}`]);
      } else {
        this.navCtrl.navigateForward([`past-job/${cleaning.id}`]);
      }
    } else {
      this.navCtrl.navigateForward([`job/${addressId}/${cleaning.id}`], {
        cleaning,
        object: cleaning.object,
      });
    }
  }

  navigateToJobPageInsideDialog(
    cleaning: Job,
    addressId: number,
    isPastJob: boolean,
    isPrivateJob: boolean
  ): void {
    const params = {
      jobId: cleaning.id,
      addressId: addressId,
      ...cleaning,
    };
    this.storage.save('dialog-params', params);
    this.storage.save('dialog-right-side-open', true);
    if (isPastJob && isPrivateJob) {
      this.rightSidePanelService.navigateTo(
        `past-private-job/${cleaning.id}`,
        params,
        PastPrivateJobPage
      );
    } else if (isPastJob) {
      this.rightSidePanelService.navigateTo(
        `past-job/${cleaning.id}`,
        params,
        PastJobPage
      );
    } else {
      this.rightSidePanelService.navigateTo(
        `job/${addressId}/${cleaning.id}`,
        params,
        JobPage
      );
    }
  }

  async goToReservation(reservation: any) {
    try {
      const guestReservation = {
        ...reservation,
        check_in_date: reservation?.object?.check_in_date,
        check_out_date: reservation?.object?.check_out_date,
        check_in_time: reservation?.object?.check_in_time,
        check_out_time: reservation?.object?.check_out_time,
      };
      const params = {
        guestReservation,
        address: this.address,
      };
      // INFO: This is a workaround to navigate to the reservation page on mobile
      // because the navigation between same pages is not working as expected
      const currentParams = await this.storage.retrieve('dialog-params') || await this.navCtrl.getParams();
      const dialogParams = {
        ...currentParams,
        ...params,
      };
      this.storage.save('dialog-params', dialogParams);
      this.ngOnInit();
    } catch (err) {
      console.error('Error going to reservation page: ', err);
    }
  }

  goToIntegrations() {
    this.navCtrl.navigateForward('integrations/all');
  }

  async deleteReservation(): Promise<void> {
    const params: AlertOptions = {
      header: new TranslationPipe().transform('Delete Reservation?'),
      message: new TranslationPipe().transform('Are you sure you want to delete this reservation?'),
    };
    const alert = await this.utils.showConfirmAlert(params);
    alert.onDidDismiss().then(async (res) => {
      if (res.role === 'confirm') {
        await this.guestReservations.delete(this.guestReservation.id);
        this.utils.showSuccess('Reservation deleted successfully');
        this.rightSidePanelService.closeRightSidePanel();
      }
    });
  }

  getGuestInfoString(): string {
    const parts: string[] = [];
    if (this.reservationDetails?.total_guests) {
      parts.push(`${this.reservationDetails.total_guests} guests`);
    }
    if (this.reservationDetails?.total_adults) {
      parts.push(`${this.reservationDetails.total_adults} adults`);
    }
    if (this.reservationDetails?.total_children) {
      parts.push(`${this.reservationDetails.total_children} children`);
    }
    if (this.reservationDetails?.total_pets) {
      parts.push(`${this.reservationDetails.total_pets} pets`);
    }
    return 'Guests: ' + parts.join(' | ');
  }

  async updateAutomaticBooking() {
    try {
      while (!this.automaticBookingSettings) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      const addressId = this.dialogParams?.addressId || this.navCtrl.getParam('addressId');
      const automaticBookingSetting = this.automaticBookingSettings.find((setting) => setting.address_id == addressId)
      if (!automaticBookingSetting) {
        const successParams = {
          header: 'Please Enable Automatic Booking for This Property',
          body: 'Please enable automatic booking for this property to turn it on for this reservation.',
          buttonText: 'View Automations',
          buttonRoute: 'automations/summary'
        };
        return this.rightSidePanelService.navigateTo('success', successParams, SuccessPage);
      }
      const value = !this.detail?.automatic_booking_settings[0]?.is_automatic_booking_enabled;
      await this.guestReservations.toggleAutomaticBookingForReservation(this.guestReservation.id, value);
      this.utils.showSuccess('Automatic booking updated successfully');
    } catch (err) {
      this.utils.showError((err.error && err.error.message) ? err.error.message : err.message, 10000);
    }
  }

}

import { Component, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { scrollContentWidth } from 'src/tidy-ui-components/components/scroll-wrapper/scroll-wrapper.component';

import { Auth } from 'src/providers/auth/auth';
import { Card } from 'src/providers/card/card';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { OneSignalNotification } from 'src/shared/providers/one-signal-notification';
import { Me } from 'src/providers/me/me';
import { StripeProvider } from 'src/providers/stripe/stripe-provider';
import { Util } from "src/shared/util/util";

import { Loading } from 'src/shared/components/loading/loading';

import { cardOptions } from 'src/old-pages/booking/confirm/confirm-card-options';

@Component({
  templateUrl: 'rental-closing.html',
  encapsulation: ViewEncapsulation.None
})

export class RentalClosingPage implements AfterViewInit, OnInit {

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  cardEl: any;
  cardHandler = this.onChange.bind(this);
  deadline: string;
  desktopWidthContent: string = scrollContentWidth.NORMAL;
  errorMessage: String;
  getStartedText: string;
  hasValidCaptcha: string | null = null;
  name: string;
  message: string;
  onboardingCost: string;
  plan: string;
  planCost: string;
  planCostDescription: string;
  showResponsibilities: boolean;
  token: string;
  loaded: boolean;

  constructor(
    private auth: Auth,
    private cd: ChangeDetectorRef,
    private cardService: Card,
    private me: Me,
    private navCtrl: CustomNavController,
    private oneSignalNotification: OneSignalNotification,
    private route: ActivatedRoute,
    private stripeProvider: StripeProvider,
    private util: Util
  ) {}

  @Loading('', true)
  async ngOnInit() {
    try {
      this.route.queryParams.subscribe(params => {
        this.name = params['name'];
        this.message = params['message'];
        this.plan = params['plan'];
        this.planCost = params['plan_cost'];
        this.planCostDescription = params['plan_cost_description'];
        this.onboardingCost = params['onboarding_costs'];
        this.deadline = params['deadline'];
        this.token = params['token'];
        this.showResponsibilities = params['r'] !== 'false';
      });
      this.getStartedText = 'After you agree, you can use your account right away, but we recommend having our team set it up.';
      if (this.token) {
        try {
          await this.auth.login({ auth_token: this.token }, 'login');
          await this.loadAccount();
          const cards = await this.cardService.getCreditCards();
          if (cards.length) {
            return this.navCtrl.navigateForward('dashboard');
          }
        } catch (err) {
          if (err.status === 404) {
            this.errorMessage = 'This link is expired, please try again';
          }
        }
      }
      this.loaded = true;
    } catch (err) {
    }
  }

  async loadAccount() {
    return this.me.load().then(async account => {
      const customerObj: any = account;
      this.auth.setAccountTypeStorage(customerObj.account_type);
      await this.auth.setSession();
      this.oneSignalNotification.setUpNotificationUser(account.current_user.id);
    });
  }

  ngAfterViewInit() {
    if (!this.cardEl) {
      this.cardEl = this.stripeProvider.getElements().create('card', cardOptions);
      this.cardEl.mount(this.cardInfo.nativeElement);
      this.cardEl.addEventListener('change', this.cardHandler);
    }
  }

  async submitCard() {
    this.errorMessage = '';
    const { token, error } = await this.stripeProvider.getInstance().createToken(this.cardEl);
    if (this.hasValidCaptcha === null) {
      this.errorMessage = 'Please check that you are not a robot.';
      return;
    }
    if (error) {
      this.setErrorCard(error);
    } else {
      await this.checkCard(token);
    }
  }

  async checkCard(token) {
    try {
      await this.cardService.saveCard({
        type: 'card',
        stripe_card_token: token.id,
        primary_payment_method: true,
      });
      this.goToSuccessPage();
    } catch (err) {
      this.setErrorCard(err);
    }
  }

  goToSuccessPage() {
    const params = {
      header: 'Card Added',
      body: 'Your plan is now set up! Please reach out to the TIDY Concierge with any questions.',
      buttonText: 'Ok',
      buttonRoute: 'dashboard'
    };
    this.navCtrl.navigateForward('success', params);
  }

  setErrorCard(err) {
    if (err.code === 402) {
      this.errorMessage = 'Your credit card was declined. Please double-check and try again.'
    } else {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToTermsAndConditions() {
    this.util.openUrl('tidy.com/terms');
  }

  captchaEvents(captchaResult) {
    this.hasValidCaptcha = captchaResult;
  }

  onChange(err: any) {
    this.errorMessage = err.error ? err.error.message : err.message;
    this.cd.detectChanges();
  }

  onDestroy() {
    this.cardEl.removeEventListener('change', this.cardHandler);
    this.cardEl.destroy();
  }

}

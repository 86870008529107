<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Use Gift Card'"
    [canGoBack]="false"
    [noBow]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <form [formGroup]="form">
    <tidy-wrapper>
      <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

      <tidy-card>
        <tidy-row [align]="'center'" class="extra-bottom-padding">
          <tidy-text
            [body]="'Enter your gift code, and we will apply it to your account. Credit from gifts are always used before charging your card'">
          </tidy-text>
        </tidy-row>

        <tidy-row class="field-top-padding">
          <tidy-input
            formControlName="code"
            [label]="'Gift Card Code'"
            [form]="form.controls.code"
            [submitted]="submitted"
            [errorMessage]="'Please enter a valid gift code.'"
            [icon]="'assets/svg/gift-outline.svg'">
          </tidy-input>
        </tidy-row>
      </tidy-card>

      <tidy-error-message
        [text]="errorMessage">
      </tidy-error-message>

      <tidy-two-column-row>
        <tidy-button
          [text]="'Go Back'"
          [action]="goBack.bind(this)"
          class="secondary">
        </tidy-button>
        <tidy-button
          [text]="'Apply Code'"
          class="primary"
          [action]="checkGiftCode.bind(this)">
        </tidy-button>
      </tidy-two-column-row>

    </tidy-wrapper>
  </form>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';

import { CurrentAddress } from 'src/providers/addresses/current-address';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { Loading } from 'src/shared/components/loading/loading';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';

@Component({
  templateUrl: 'add-list.html',
  encapsulation: ViewEncapsulation.None
})

export class ToDosAddListPage implements OnInit {

  addressId: number;
  addressFilter: TidySelectNumberValueModel[];
  allToDolists: any;
  default = false;
  errorMessage: string;
  form: UntypedFormGroup;
  hasToDoLists: boolean;
  isRightSideContent: boolean;
  listToCopyItems: any;
  loaded: boolean;
  submitted: boolean;
  toDoLists: any;
  remoteInspection: boolean = false;
  readonly typeItems = [
    {
      viewValue: 'Photos',
      value: 'photo'
    },
    {
      viewValue: 'Videos',
      value: 'video'
    }
  ];
  readonly whenItems = [
    {
      viewValue: 'Before and after the job',
      value: 'before_and_after'
    },
    {
      viewValue: 'Before the job',
      value: 'before'
    },
    {
      viewValue: 'After the job',
      value: 'after'
    }
  ];
  readonly whatToDoItems = [
    {
      viewValue: 'Add new list',
      value: 'new'
    },
    {
      viewValue: 'Copy list from another address',
      value: 'copy'
    }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private toDos: ToDos,
    private currentAddress: CurrentAddress
  ) {
    this.form = this.fb.group({
      listName: ['', Validators.required],
      address: ['', Validators.required],
      whatToDo: ['', Validators.required],
      type: ['', this.remoteInspectionValidator.bind(this)],
      when: ['', this.remoteInspectionValidator.bind(this)],
      listToCopy: [''],
    });
  }

  @Loading('', true)
  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    await this.getParams();
    this.form.patchValue({
      address: this.addressId,
      whatToDo: 'new'
    });
    this.allToDolists = await this.toDos.getAllIsCustomerToDoLists();
    this.listToCopyItems = this.buildListToCopyItems();
    this.loaded = true;
  }

  changeAddress() {
    this.listToCopyItems = this.buildListToCopyItems();
    this.form.patchValue({listToCopy: null});
  }

  buildListToCopyItems() {
    const array: any = [];
    this.allToDolists.map((list) => {
      if (this.form.value.address !== list.address_id) {
        array.push(({
          value: list.id,
          viewValue: list.title
        }));
      }
    });
    return array;
  }

  async getParams() {
    const dialogParams = await this.storage.retrieve('dialog-params');
    this.hasToDoLists = this.navCtrl.getParam('hasToDoLists') || dialogParams?.hasToDoLists;
    const addressId = localStorage.getItem('addressId') ;
    this.addressId = parseFloat(addressId);
    this.addressFilter = this.navCtrl.getParam('addressFilter') || dialogParams?.addressFilter;
    this.toDoLists = this.navCtrl.getParam('toDoLists') || dialogParams?.toDoLists;
  }

  async addList() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.whatToDo == 'copy') {
      return this.copyListToAddress();
    }
    const beforeAfter = this.remoteInspectionSetting();
    const response = await this.toDos.createList(this.form.value.address, this.form.value.listName, beforeAfter);
    if (this.default || !this.hasToDoLists) {
      const payload = {
        address_task_list_id: response.id,
        address_id: this.form.value.address,
        settings: [{
          key: `default_list.address.${this.form.value.address}`,
          value: true
        }]
      }
      await this.toDos.saveDefaultList(response.id, payload);
    }
    localStorage.setItem('taskListId', response.id);
    this.currentAddress.addressId = this.form.value.address;
    await this.storage.save('didMakeToDoUpdates', true);
    if (this.toDoLists) {
      const toDosListsForAddress = this.toDoLists.filter(list => list.address_id == this.form.value.address);
      if (toDosListsForAddress.length == 0) {
        await this.toDos.updateDoNotUseToDos('active');
      }
    }
    this.navCtrl.navigateForward(this.isRightSideContent ? 'all-to-do-lists' : 'to-dos');
  }

  async copyListToAddress() {
    try {
      const payload = {
        target_address_id: this.form.value.address
      }
      await this.toDos.copyListToAddress(this.addressId, this.form.value.listToCopy, payload);
      localStorage.setItem('addressId', this.form.value.address);
      const toDoState = await this.toDos.getToDoState(this.form.value.address);
      if (toDoState == null) {
        await this.toDos.updateDoNotUseToDos('active');
      }
      await this.storage.save('didMakeToDoUpdates', true);
      this.navCtrl.navigateForward(this.isRightSideContent ? 'all-to-do-lists' : 'to-dos');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      if (this.errorMessage == 'Action not allowed. The Task List has rooms associated!') {
        this.errorMessage = 'This list has rooms unique to the address in it, so it cannot be copied. Please remove the rooms or create a new list with no address specific rooms in it.'
      }
    }
  }

  updateWhatToDo(selection) {
    if (selection.value == 'new') {
      this.form.controls.listToCopy.clearValidators();
      this.form.controls.listToCopy.updateValueAndValidity();
      this.form.controls.listName.setValidators([Validators.required]);
    } else {
      this.form.controls.listToCopy.setValidators([Validators.required]);
      this.form.controls.listName.clearValidators();
      this.form.controls.listName.updateValueAndValidity();
    }
  }

  remoteInspectionSetting() {
    if (!this.remoteInspection) {
      return 'inactive';
    }

    const when = this.form.value.when;
    const type = this.form.value.type;

    if (when === 'before_and_after') {
      return type;
    }

    return `${when}_${type}_only`;
  }

  remoteInspectionValidator(formControl: AbstractControl) {
    if (this.remoteInspection) {
      return Validators.required(formControl);
    }

    return null;
  }

  goToListSettings() {
    this.navCtrl.navigateForward('list-settings');
  }
}

import { Injectable } from '@angular/core';
import { RadioButtonModel } from 'src/tidy-ui-components/components/form/radio-button/radio-button.model';
import * as moment from 'moment';

import { Aws } from 'src/providers/aws/aws';
import { CurrentAddress } from '../addresses/current-address';
import { FilePicker } from 'src/shared/providers/file-picker/file-picker';
import { HttpClientCustom } from '../custom/http-client';
import { generateUuid } from 'src/providers/tidy-session/session-uuid';
import { TidyCurrencyPipe } from 'src/shared/pipes/tidy-currency.pipe';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { ProModel, ProTeamServices } from 'src/models/book-job.model';
import { AttachmentModel } from 'src/models/concierge.model';

@Injectable()
export class BookJob {

  isAssigningPrivatePro: string;

  constructor(
    private aws: Aws,
    private httpClient: HttpClientCustom,
    private currentAddress: CurrentAddress,
    private filePicker: FilePicker,
    private storage: TidyStorage
  ) {}

  acceptBid(params) {
    const url = `api/v1/customer/bookings/${params.booking_id}/accept-potential-homekeeper`;
    return this.httpClient.post(url, params);
  }

  fetchPros(serviceTypeId = 1): Promise<ProModel[]> {
    serviceTypeId = serviceTypeId == null ? 1 : serviceTypeId;
    const addressId = this.currentAddress.addressId;
    const url = `api/v2/common/calendar/filters?service_type_id=${serviceTypeId}&address_id=${addressId}`;
    return this.httpClient.get(url);
  }

  async fetchRequiredTeamServices(filter, proSelected, serviceTypeId = 1): Promise<ProTeamServices[]> {
    serviceTypeId = serviceTypeId == null ? 1 : serviceTypeId;
    const isPrivatePro = false;
    const addressId = this.currentAddress.addressId;
    let url = `api/v1/customer/required-team-services?address_id=${addressId}&service_type_id=${serviceTypeId}`;
    if (proSelected) {
      const id = filter.toString().includes('homekeeper') ? filter.replace("homekeeper:","") : filter;
      url += `&homekeeper_id=${id}`;
    }
    let response = await this.httpClient.get(url);
    response = response.map( s => ({
      key: s.key,
      title: s.name,
      description: s.description,
      image: s.service_type.id == 1 ? ('assets/img/plans/' + s.key + '.svg') : s.service_type.icon_url,
      duration: s.duration_to_block_off,
      price: s.price || '',
      billingType: s.billing_type,
      frequencies: this.parseFrequencies(s.frequency_options, isPrivatePro),
      planId: s.plan_id,
      id: s.id
    }));
    const accountType = localStorage.getItem('accountType');
    if (accountType == 'regular') {
      return response.filter(service => service.key !== 'regular_cleaning.turnover_one_hour' && service.key !== 'regular_cleaning.turnover_two_and_a_half_hours');
    } else {
      return response;
    }
  }

  fetchAllRequiredTeamServices() {
    const url = 'api/v1/customer/required-team-services';
    return this.httpClient.get(url);
  }

  async fetchTeamServices(homekeeperId: number, serviceTypeId = null): Promise<ProTeamServices[]> {
    const isPrivatePro = true;
    const addressId = this.currentAddress.addressId;
    const url = `api/v1/customer/team-address-services?filters[homekeeper_ids]=${homekeeperId}&filters[address_ids]=${addressId}&includes=proposal,plan,service_category`;
    let response = await this.httpClient.get(url);
    response = response.map( s => ({
      key: s.key,
      title: s.name,
      description: s.proposal === null ? s.description : (s?.proposal?.description),
      image: s.service_category.icon_url,
      duration: s.duration_to_block_off,
      price: s.proposal === null ? (s.price || 0) : (s?.proposal?.proposable?.client_price),
      billingType: s.billing_type,
      frequencies: this.parseFrequencies(s.frequency_options, isPrivatePro),
      planId: s.plan?.id,
      isBasePriceAllowedToBook: s.is_base_price_allowed_to_book,
      id: s.id,
      prices: {
        basePrice: s.prices.base_price,
        lowestPossibleBasePrice: s.prices.lowest_possible_base_price
      },
      serviceType: {
        key: s.service_category.key,
        name: s.service_category.name,
        id: s.service_category.id
      }
    }));
    if (serviceTypeId) {
      return response.filter((item) => {
        return item.serviceType.id == serviceTypeId;
      });
    } else {
      return response;
    }
  }

  convertNameToKey(name): string {
    const nameKeys = {
      '1 hour cleaning' : 'regular_cleaning.one_hour',
      '1 hour cleaning (2 Pros)' : 'regular_cleaning.turnover_one_hour',
      '2.5 hour cleaning' : 'regular_cleaning.two_and_a_half_hours',
      '2.5 hour cleaning (2 Pros)' : 'regular_cleaning.turnover_two_and_a_half_hours',
      '4 hour cleaning' : 'regular_cleaning.four_hours',
      '4 hour cleaning (2 Pros)' : 'regular_cleaning.turnover_four_hours',
    }
    return nameKeys[name];
  }

  async saveJob(bookingType, bookData, planId, bookingId = 0) {
    let url = 'api/v1/customer/'
    switch (bookingType) {
      case 'add_job':
        url += 'plans';
        return this.httpClient.post(url, bookData);
      case 'add_one_time_job':
        url += 'bookings';
        return this.httpClient.post(url, bookData);
      case 'reschedule_plan':
        url += `plans/${planId}/reschedule`;
        return this.httpClient.post(url, bookData);
      case 'reschedule_job':
        url += `bookings/${bookingId}/reschedule`;
        return this.httpClient.post(url, bookData);
    }
  }

  parseFrequencies(frequencyOptions, isPrivatePro): RadioButtonModel[] {
    const once = [{ viewValue: this.getOneTimeViewValue(isPrivatePro), value: 'once' }];
    const recurring = isPrivatePro ? [
      { viewValue: 'Weekly', value: 'every_week' },
      { viewValue: 'Every 4 Weeks', value: 'every_four_weeks' },
      { viewValue: 'Every 2 Weeks', value: 'every_other_week' },
    ] : [
      { viewValue: 'Weekly', value: 'every_week' },
      { viewValue: 'Every 4 Weeks', value: 'every_four_weeks' },
      { viewValue: '3x Per Week', value: 'thrice_a_week' },
      { viewValue: 'Every 2 Weeks', value: 'every_other_week' },
      { viewValue: '2x Per Week', value: 'twice_a_week' },
    ];
    const recurringAndOnce = isPrivatePro ? [
      { viewValue: 'Weekly', value: 'every_week' },
      { viewValue: 'Every 4 Weeks', value: 'every_four_weeks' },
      { viewValue: 'Every 2 Weeks', value: 'every_other_week' },
      ...once,
    ] : [
      { viewValue: 'Weekly', value: 'every_week' },
      { viewValue: 'Every 4 Weeks', value: 'every_four_weeks' },
      { viewValue: 'Every 2 Weeks', value: 'every_other_week' },
      ...once,
      { viewValue: '2x Per Week', value: 'twice_a_week' },
      { viewValue: '3x Per Week', value: 'thrice_a_week' }
    ];
    switch (frequencyOptions) {
      case 'once':
        return once;
      case 'recurring':
        return recurring;
      default:
        return recurringAndOnce;
    }
  }

  getOneTimeViewValue(isPrivatePro) {
    const bookingExperiment = localStorage.getItem('bookingExperiment');
    if (isPrivatePro || bookingExperiment == 'consumer-test1' || bookingExperiment == 'consumer-test2' || bookingExperiment == 'rental-v1a' || bookingExperiment == 'rental-v1b' || bookingExperiment == 'rental-v2a' || bookingExperiment == 'rental-v2b' || bookingExperiment == 'rental-v3') {
      return 'One Time';
    } else {
      return 'One Time (+25%)'
    }
  }

  getDefaultRatings() {
    return [{
      viewValue: '4.6+ Star Rating',
      value: '4_6_rating'
    }, {
      viewValue: '4.2+ Star Rating',
      value: '4_2_rating'
    }, {
      viewValue: '4.0+ Star Rating',
      value: '4_0_rating'
    }, {
      viewValue: 'Any Rating',
      value: 'any_rating'
    }];
  }

  parseFilterToRating(filter: string) {
    switch (filter) {
      case 'prioritized_v2:4_6':
        return '4_6_rating';
      case 'prioritized_v2:4_2':
        return '4_2_rating';
      case '4':
          return '4_rating'
      case 'all':
        return 'any_rating';
      default:
        return 'specific_hk';
    }
  }

  checkIfSameDayJob(bookingType, bookData) {
    if (bookingType === 'add_one_time_job' || bookingType === 'reschedule_job') {
      return bookData?.rating_options?.hk_rating === 'same_day';
    } else {
      return bookData?.hk_rating === 'same_day';
    }
  }

  getJobTimeItems() {
    const startHour = 5;
    return Array.from({ length: 37 }, (_, index) => {
      const momentDateTimeObject = moment({ hours: startHour }).add(index * 30, 'minutes');
      const hour12Format = momentDateTimeObject.format('h:mma');
      const isOneHourAndAHalfIncrement = index % 3 === 0;
      const isToAddSuggestedSuffix = (momentDateTimeObject.hours() >= 8 && momentDateTimeObject.hours() <= 21) && isOneHourAndAHalfIncrement;
      const militaryHourFormat = momentDateTimeObject.format('HH:mm');
      return { viewHour: hour12Format, valueHour: militaryHourFormat };
    }).map(({viewHour, valueHour}) => ({viewValue: viewHour, value: valueHour }));
  }

  getCustomDiscountedPrice(price) {
    const customFlowDiscount = localStorage.getItem('customFlowDiscount');
    const roundedPrice = Math.round((price - price * parseInt(customFlowDiscount)/100) / 100);
    return roundedPrice * 100;
  }

  getRequestProbability(bookData, addressId) {
    const url = `api/v1/customer/bookings/acceptance-probability-preview?address_id=${addressId}&booking[service_type_key]=${bookData.booking.service_type_key}&booking[start_date_no_earlier_than]=${bookData.booking.start_date_no_earlier_than}&booking[start_time_no_earlier_than]=${bookData.booking.start_time_no_earlier_than}&booking[end_date_no_later_than]=${bookData.booking.end_date_no_later_than}&booking[end_time_no_later_than]=${bookData.booking.end_time_no_later_than}`;
    return this.httpClient.get(url);
  }

  getBookingFormTemplate(serviceKey) {
    const url = `api/v1/customer/booking-form-templates?service_type_key=${serviceKey}`;
    return this.httpClient.get(url);
  }

  async addAttachment(): Promise<AttachmentModel> {
    const uuid = generateUuid();
    const file = await this.filePicker.pickFile();
    const fileKey = `request-job-attachment/${uuid}/${file.filename}`;
    if (file.format.includes('jpeg') || file.format.includes('png') || file.format.includes('svg')) {
      await this.aws.uploadFileToS3(file.dataUrl, fileKey, file.format);
      return {
        filename: file.filename,
        fileKey,
        url: file.dataUrl
      };
    } else {
      return {
        filename: '',
        fileKey: '',
        url: ''
      };
    }
  }

  getOneTimePrice(price) {
    return Math.round((price * 1.25) / 100) * 100;
  }

  getDiscountedPrice(price) {
    return Math.round((price * 0.8) / 100) * 100;
  }

  getPriceExperiment() {
    const bookingExperiment = localStorage.getItem('bookingExperiment');
    if (bookingExperiment == 'consumer-test1' || bookingExperiment == 'consumer-test2' || bookingExperiment == 'rental-v1a' || bookingExperiment == 'rental-v1b' || bookingExperiment == 'rental-v2a' || bookingExperiment == 'rental-v2b' || bookingExperiment == 'rental-v3') {
      return 'showCrossedOutDiscount';
    } else {
      return null;
    }
  }

  getSubscriptionExperiment() {
    const bookingExperiment = localStorage.getItem('bookingExperiment');
    if (bookingExperiment == 'rental-v2a' || bookingExperiment == 'rental-v2b') {
      return 'showInlineSubscriptionSales';
    } else if (bookingExperiment == 'rental-v3') {
      return 'showPopupSubscriptionSales';
    } else {
      return null;
    }
  }

  getDisplayPriceForJob(planId, service, selectedFrequency, choseFrequency, bookingType, hasNonTrialPaidSubscription, priceExperiment, bookingExperiment, customFlowName, isPrivatePro, giftCode = 0, subscriptionAmount = 0) {
    /*console.log(planId)
    console.log(service)
    console.log(selectedFrequency)
    console.log(choseFrequency)
    console.log(bookingType)
    console.log(hasNonTrialPaidSubscription)
    console.log(priceExperiment)
    console.log(bookingExperiment)
    console.log(customFlowName)
    console.log(isPrivatePro)
    console.log(giftCode)
    console.log(subscriptionAmount)*/

    if (!service) {
      return '';
    }
    let basePrice = new TidyCurrencyPipe().transform(service.price - giftCode + subscriptionAmount);
    const plusTwentyFivePercentPrice = new TidyCurrencyPipe().transform(this.getOneTimePrice(service.price) + subscriptionAmount - giftCode);
    if (!isPrivatePro && !choseFrequency && bookingExperiment !== 'consumer-control' && bookingExperiment !== 'rental-control' && bookingType !== 'add_one_time_job' && bookingType !== 'reschedule_job') {
      return '';
    } else if (service.billingType == 'free') {
      return 'Free ';
    } else if (service.billingType == 'hourly') {
      return new TidyCurrencyPipe().transform(service.prices.basePrice) + '/hr ';
    } else if (service.billingType == 'price_later') {
      return 'Priced later ';
    } else if (isPrivatePro) {
      return new TidyCurrencyPipe().transform(service.prices.basePrice) + ' ';
    } else if (bookingType == 'add_one_time_job' || bookingType == 'reschedule_job') {
      return basePrice + ' ';
    } else if (customFlowName && service.billingType == 'flat_rate' && service.price) {
      const customFlowDiscount = parseInt(localStorage.getItem('customFlowDiscount'));
      let price = Math.round((service.price * 100/(100-customFlowDiscount)) / 100) * 100;
      if (selectedFrequency == 'One Time (+25%)') {
        price = price*1.25;
      }
      basePrice = new TidyCurrencyPipe().transform(Math.round((price - giftCode + subscriptionAmount)/100)*100);
      const customFlowDiscountPrice = new TidyCurrencyPipe().transform(this.getCustomDiscountedPrice(price) - giftCode + subscriptionAmount);
      return '<del>' + basePrice + '</del> ' + customFlowDiscountPrice + ' ';
    } else if (hasNonTrialPaidSubscription) {
      if (selectedFrequency == 'One Time' || selectedFrequency == 'One Time (+25%)' || priceExperiment == 'showCrossedOutDiscount') {
        return '<del>' + plusTwentyFivePercentPrice + '</del>' + ' ' + basePrice + ' ';
      } else {
        return basePrice + ' ';
      }
    } else if (bookingExperiment == 'consumer-test2') {
      const hasCard = localStorage.getItem('hasCard') == 'true';
      if (!choseFrequency) {
        return plusTwentyFivePercentPrice + ' ';
      } else if (selectedFrequency == 'One Time') {
        if (hasCard) {
          return plusTwentyFivePercentPrice + ' ';
        } else {
          const plusTwentyFivePriceMinusTen = new TidyCurrencyPipe().transform(this.getOneTimePrice(service.price) - 1000 + subscriptionAmount);
          return '<del>' + plusTwentyFivePercentPrice + '</del>' + ' ' + plusTwentyFivePriceMinusTen + ' ';
        }
      } else {
        return '<del>' + plusTwentyFivePercentPrice + '</del>' + ' ' + basePrice + ' '
      }
    } else if (priceExperiment !== 'showCrossedOutDiscount') {
      if (!choseFrequency || (selectedFrequency !== 'One Time' && selectedFrequency !== 'One Time (+25%)')) {
        return basePrice + ' ';
      } else {
        return plusTwentyFivePercentPrice + ' ';
      }
    } else if (priceExperiment == 'showCrossedOutDiscount') {
      if (!choseFrequency || selectedFrequency == 'One Time') {
        return plusTwentyFivePercentPrice + ' ';
      } else {
        return '<del>' + plusTwentyFivePercentPrice + '</del>' + ' ' + basePrice + ' ';
      }
    }
  }

  clearBookJobStorage() {
    const keys = [
      'address',
      'bookingType',
      'isLoggingJob',
      'jobId',
      'flowType',
      'planId',
      'bookingId',
      'defaultBookingKey',
      'categoryId',
      'isSelfBooking',
      'shouldNotDefaultToLastPro',
      'hkId',
      'filterByPro',
      'pros',
      'providedStartDate',
      'shouldInsteadAddJob',
      'cameFromBookJob',
      'cameFromEditRequest',
      'availabilityRequestId',
      'payload',
      'addressId',
      'serviceTypeKey',
      'homekeeperId',
      'proName',
      'customBack',
      'bookData',
      'requestDates',
      'requestTimes',
      'isRequestSubstitute',
      'viewData',
      'isProRequest',
      'isJobRequest',
      'isLoggingJob',
      'showSameDayTimeAlert',
      'selectedServiceData',
      'selectedService',
      'selectedPrivatePro'
    ];
    keys.forEach(key => {
      this.storage.delete(key);
    });
  }

}

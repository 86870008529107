import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tidy-round-button',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./round-button.component.scss'],
  template: `
  <div (click)="executeAction()">
    <div *ngIf="buttonWidth" class="wrapper-button" tidyRipple [matRippleColor]="rippleColor" [style.width]="buttonWidth + 'px'" [style.height]="buttonWidth + 'px'">
      <tidy-image class="no-right-margin" [src]="image" *ngIf="image" [style.width]="imageWidth + 'px'"></tidy-image>
    </div>
    <div *ngIf="!buttonWidth" class="wrapper-button" tidyRipple [matRippleColor]="rippleColor">
      <tidy-image class="round-btn-size no-right-margin" [src]="icon" *ngIf="icon"></tidy-image>
      <tidy-image class="round-btn-size no-right-margin" [src]="image" *ngIf="image"></tidy-image>
    </div>
    <tidy-text
      *ngIf="label"
      class="label"
      [body]="label"
     ></tidy-text>
  </div>
  `
})

export class RoundButtonComponent {

  @Input() buttonWidth: number;
  @Input() imageWidth: number;
  @Input() image: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() rippleColor = 'rgba(251, 251, 251, 0.1)';
  cooldown = false;

  @Output() action: EventEmitter<any> = new EventEmitter();

  executeAction() {
    if (this.cooldown) return;
    this.cooldown = true;
    setTimeout(() => this.cooldown = false, 500);
    this.action.emit();
  }

}

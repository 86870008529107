import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { WindowService } from 'src/shared/providers/window.service';

import { Util } from 'src/shared/util/util';

import { Client } from 'src/providers/client/client';

@Component({
  templateUrl: 'edit-language.html'
})

export class EditLanguagePage implements OnInit {

  isRightSideContent: boolean;
  language: any;
  languageItems: any;
  form: UntypedFormGroup;
  loaded: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {
    this.form = this.fb.group({
      language: [''],
    });
  }

  async ngOnInit() {
    this.loaded = false;
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Edit Display Language');
    this.languageItems = this.getLanguageItems();
    this.form.patchValue({
      language: localStorage.getItem('language') || 'en'
    });
    this.loaded = true;
  }

  getLanguageItems() {
    return [
      {
        value: 'en',
        viewValue: 'English'
      },
      {
        value: 'pt',
        viewValue: 'Portuguese'
      },
      {
        value: 'es',
        viewValue: 'Spanish'
      },
      {
        value: 'ru',
        viewValue: 'Russian'
      },
      {
        value: 'fr',
        viewValue: 'French'
      },
      {
        value: 'de',
        viewValue: 'Dutch'
      }
    ]
  }

  async save() {
    await this.client.addMetadata({ key: 'language', value: this.form.value.language });
    localStorage.setItem('didUpdateLanguage', 'true');
    localStorage.setItem('language', this.form.value.language);
    const successMessage = 'Language updated!';
    this.util.showSuccess(successMessage)
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.navigateForward('more');
    }
    window.location.reload();
  }

}

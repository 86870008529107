import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { MatDialogModule } from '@angular/material/dialog';
import { providers } from './aggregators/providers';
import { oldPages } from './aggregators/old-pages';
import { pages, routes } from './aggregators/pages';
import { redirects } from 'src/app/aggregators/redirects';

import { SharedModule } from '../shared/shared.module';
import { TidyHomekeepingApp } from './app.component';
import { RouteReuseStrategy, RouterModule, PreloadAllModules, Router, ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TimeoutErrorHandler } from 'src/shared/providers/timeout-error-handler';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CustomRouteReuseStrategy } from 'src/custom-route-reuse-strategy';
import { BookingRedirectGuard, bookingRedirectGuardFactory } from 'src/providers/guard/booking-redirect.guard';
import { HttpInterceptorService } from './interceptors/http.service';
import * as Sentry from '@sentry/angular';

@NgModule({
    declarations: [
        ...oldPages,
        ...pages
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        FormsModule,
        MatTooltipModule,
        MatButtonModule,
        MatPaginatorModule,
        MatMenuModule,
        MatTreeModule,
        MatIconModule,
        MatCheckboxModule,
        SharedModule,
        BrowserAnimationsModule,
        MatDialogModule,
        RecaptchaModule,
        DragDropModule,
        RecaptchaFormsModule,
        IonicModule.forRoot(),
        CookieModule.forRoot(),
        ScrollingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        RouterModule.forRoot([...routes, ...redirects], { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', useHash: true, enableTracing: false, relativeLinkResolution: 'legacy' })
    ],
    bootstrap: [TidyHomekeepingApp],
    providers: [
        ...providers,
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
        { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LfvZVIaAAAAAILNe1Pjh1hUjtdJelRKeBh1UOXR' } as RecaptchaSettings },
        { provide: MatDialogRef, useValue: {} },
        { provide: BookingRedirectGuard, useFactory: bookingRedirectGuardFactory },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    timeoutErrorHandler: TimeoutErrorHandler,
    trace: Sentry.TraceService
    ) {
    AppRouter = router;
    AppActivatedRoute = activatedRoute;
    AppTimeoutHandler = timeoutErrorHandler;
  }
}
export let AppRouter: Router;
export let AppActivatedRoute: ActivatedRoute;
export let AppTimeoutHandler: TimeoutErrorHandler

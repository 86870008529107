<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    title="Request {{featureType | titlecase}}"
    [class]="extraClass"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-card>
      <tidy-row class="extra-bottom-padding" [align]="'center'">
        <tidy-image

        [src]="selectedFeature ? selectedFeature?.icon_url : 'assets/img/question.svg'"
        style="width:80%; max-height: 200px">
        </tidy-image>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [header]="selectedFeature?.name">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="selectedFeature?.description">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-top-padding extra-bottom-padding">
        <tidy-divider></tidy-divider>
      </tidy-row>
      <form [formGroup]="form" novalidate action="#">
        <ng-container>
          <tidy-row class="field-top-padding">
           <tidy-textarea
             formControlName="note"
             [form]="form.controls.notes"
             formControlName="notes"
             [label]="'Any notes for our concierge?'"
             [icon]="'assets/svg/information-circle-outline.svg'">
           </tidy-textarea>
          </tidy-row>
        </ng-container>
      </form>
    </tidy-card>

    <tidy-button
      text="Request {{featureType | titlecase}}"
      [action]="requestFeature.bind(this)"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

<ng-template
  let-extraClass="extraClass"
  #topNav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'Add credit for ' + employee?.source_user_first_name + ' ' + employee?.source_user_last_name + ':'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'Amount to add'"
            formControlName="amount"
            [form]="form.controls.amount"
            [icon]="'assets/svg/card-outline.svg'"
            [submitted]="submitted"
            inputMode="numeric"
            [mask]="'currencyMask'"
            [errorMessage]="'Please enter an amount'">
          </tidy-input>
        </tidy-row>
      </form>
    </tidy-card>

    <tidy-row>
      <tidy-button
        [text]="'Add Credit'"
        [action]="addCredit.bind(this)"
        class="primary">
      </tidy-button>
    </tidy-row>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-row [align]="'center'">
      <tidy-text
        [body]="'You currently have ' + (companyCredit | tcurrency) + ' credit you can add for employees.'">
      </tidy-text>
    </tidy-row>

  </tidy-wrapper>
</ng-template>

<ion-content class="ion-content-right-column">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>
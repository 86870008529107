import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Client } from 'src/providers/client/client';
import { SuccessPage } from 'src/shared/pages/success/success';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Util } from 'src/shared/util/util';
import { WindowService } from 'src/shared/providers/window.service';

@Component({
  templateUrl: 'buy-bundle.html',
  encapsulation: ViewEncapsulation.None
})

export class BuyBundlePage implements OnInit {

  errorMessage: String;
  isRightSideContent = true;
  dialogParams: any;

  constructor(
    private client: Client,
    private navCtrl: CustomNavController,
    private util: Util,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
    private windowService: WindowService
  ) {}

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.rightSidePanelService.setDialogPageTitle('Buy Bundle');
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
    }
  }

  goBack() {
    if (this.isRightSideContent) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.back();
    }
  }

  gotToLearnMore() {
    this.util.openUrl('https://help.tidy.com/payment-methods-and-settings#2u-bundles');
  }

  async confirm() {
    this.errorMessage = "";
    try {
      await this.client.buyBundle();
      const params = this.mountSuccessPageParams();
      this.rightSidePanelService.navigateTo('success', params, SuccessPage);
    } catch (err) {
      if (err.code == 500) {
        this.errorMessage = "Your card was declined. Please update your card";
      }
      else {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
  }

  mountSuccessPageParams() {
    return {
      header: 'Bundle Bought',
      body: 'Your card was charged $500 and you received $550 credit ($50 savings).  You can view this credit in your billing history now.',
      buttonText: 'Ok',
      buttonRoute: this.windowService.isDesktopRes ? 'more-desktop' : 'more'
    };
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { TidyStorage } from 'src/shared/providers/tidy-storage';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

@Component({
  templateUrl: 'create-first-list.html'
})

export class ToDosCreateFirstListPage implements OnInit {

  addressId: number;
  addressName: string;
  cameFromEditAddress: boolean;
  errorMessage: string;
  form: UntypedFormGroup;
  loaded: boolean;
  roomOptions: TidySelectNumberValueModel[];
  floorOptions: TidySelectNumberValueModel[];
  pageTitle: string;
  planId: number;
  dialogParams: any;
  isRightSideContent = true;

  constructor(
    private client: Client,
    private formBuilder: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private toDos: ToDos,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.formBuilder.group({
      beds: [2],
      baths: [2],
      floors: [2],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    this.dialogParams = await this.storage.retrieve('dialog-params');
    this.loaded = false;
    this.addressId = await this.getParam('addressId');
    this.addressName = await this.getParam('addressName');
    this.planId = await this.getParam('planId');
    this.roomOptions = this.buildOptions(10);
    this.floorOptions = this.buildOptions(5);
    this.cameFromEditAddress = await this.getParam('cameFromEditAddress');
    this.rightSidePanelService.setDialogPageTitle(this.cameFromEditAddress ? 'Add Rooms' : 'Create First List');
    this.loaded = true;
  }

  async getParam(paramName): Promise<any> {
    let param = this.dialogParams?.[paramName];
    if (param === null || param === undefined) {
      param = this.navCtrl.getParam(paramName)
    }
    if (param === null || param === undefined) {
      param = await this.storage.retrieve(paramName);
    }
    this.storage.save(paramName, param);
    return param;
  }

  buildOptions(maxNumber): TidySelectNumberValueModel[] {
   return Array.from({ length: maxNumber },(_, i) => ({ viewValue: `${i + 1}`, value: i + 1 }));
  }

  async save() {
    this.storage.delete('addresses');
    const roomData = {
      address_id: this.addressId,
      address_instructions: {
        beds: this.form.value.beds,
        baths: this.form.value.baths,
        floors_amount: this.form.value.floors
      }
    }
    if (this.planId !== undefined) {
      roomData['plan_id'] = this.planId;
    }
    try {
      await this.client.saveHomeData(roomData);
      await this.toDos.updateDoNotUseToDos('active');
      localStorage.removeItem('taskListId');
      if (this.cameFromEditAddress) {
        this.navCtrl.navigateForward(`edit-property/${this.addressId}`);
      } else {
        if (this.isRightSideContent) {
          this.navCtrl.navigateForward('all-to-do-lists');
        } else {
          this.navCtrl.navigateRoot('to-dos');
        }
      }
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }
}

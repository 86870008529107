<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Add Do Not'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="loaded">

      <tidy-card>
        <form [formGroup]="form">
          <tidy-row class="field-top-padding extra-bottom-padding">
            <tidy-input
              [label]="'What should they NOT do?'"
              formControlName="body"
              [form]="form.controls.body"
              [icon]="'assets/svg/alert-circle-outline.svg'"
              [submitted]="submitted"
              [errorMessage]="'Please enter the name of the do not'">
            </tidy-input>
          </tidy-row>
          <tidy-row class="extra-top-padding extra-bottom-padding">
            <tidy-text
              [header]="'Add to Rooms:'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let room of rooms; let even = even; let i = index">
            <tidy-two-column-row *ngIf="even">
              <tidy-checkbox
                item-left
                [items]="[room]"
                [initValue]="room?.value === preSelectedRoom"
                (checkedChange)="selectRoom(room?.value)">
              </tidy-checkbox>
              <ng-container *ngIf="rooms[i+1] !== undefined">
                <tidy-checkbox
                  item-right
                  [items]="[rooms[i+1]]"
                  [initValue]="rooms[i+1]?.value === preSelectedRoom"
                  (checkedChange)="selectRoom(rooms[i+1]?.value)">
                </tidy-checkbox>
              </ng-container>
            </tidy-two-column-row>
          </ng-container>
          <tidy-row *ngIf="rooms?.length == 0">
            <tidy-text
              [body]="'You haven\'t added any cards specific to this property. Please add cards specific to this property to add a Do Not.'">
            </tidy-text>
          </tidy-row>
          <tidy-row *ngIf="roomError">
            <tidy-error-message
              [text]="'Please select rooms to add to.'">
            </tidy-error-message>
          </tidy-row>
        </form>
      </tidy-card>

      <tidy-button
        [text]="'Add Do Not'"
        [action]="addDoNot.bind(this)"
        class="primary">
      </tidy-button>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

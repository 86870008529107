import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Loading } from 'src/shared/components/loading/loading';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';

import { ClientSettingsModel } from 'src/models/client.model';

import validationUtils from 'src/shared/util/validation-utils';

import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';

@Component({
  templateUrl: 'bill-settings.html'
})

export class BillSettingsPage implements OnInit {

  form: UntypedFormGroup;
  errorMessage: string;
  isRightSideContent: boolean;
  loaded: boolean;
  settings: ClientSettingsModel;
  submitted: boolean;

  constructor(
    private client: Client,
    private fb: UntypedFormBuilder,
    private navCtrl: CustomNavController,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, validationUtils.validateEmail]]
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.loaded = false;
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Bill');
      this.settings = await this.client.getClientSettings();
      this.form.patchValue({
        email: this.settings.invoices.alt_email || this.settings.profile.email
      });
      this.loaded = true;
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async save() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    try {
      const payload = {
        invoices: {
          alt_email: this.form.value.email == '' ? null : this.form.value.email,
        }
      }
      await this.client.saveClientSettings(payload);
      this.navCtrl.navigateForward('bills');
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

}

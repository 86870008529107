<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Workflows'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <ng-container *ngIf="loaded">
      <tidy-row [alignLastItemRight]="true">
        <tidy-text
          [body]="isSavingChange ? 'Saving Changes..' : 'All Changes Saved.'">
        </tidy-text>
        <tidy-text
          [actionBody]="'Add Workflow'"
          class="link"
          (action)="addWorkflow()">
        </tidy-text>
      </tidy-row>

      <!--<tidy-alert>
        <form [formGroup]="triggerForm" novalidate action="#">
          <tidy-row class="extra-bottom-padding" style="padding-bottom:0px">
            <tidy-input
              #searchInput
              label="Search"
              icon="assets/ionic-icon-v3/ios-search-outline.svg"
              activeIcon="src/assets/ionic-icon-v3/ios-search-outline.svg"
              [form]="triggerForm.get('search')"
              (keyup)="searchWorkflows(searchInput.value)"
              formControlName="search"
              class="title-input">
            </tidy-input>
          </tidy-row>
        </form>
      </tidy-alert>-->

      <ng-container *ngFor="let workflow of filteredWorkflows; let i = index;">
        <tidy-card>
          <tidy-card-button (click)="goToWorkFlow(workflow)" class="no-below-stroke">
            <tidy-row class="vertical-align-center">
              <tidy-toggle
                style="margin-right: 20px"
                [toggled]="!workflow.paused_at"
                (toggleChanged)="pauseUnpauseWorkflow(workflow)"
                (click)="$event.stopPropagation()">
              </tidy-toggle>
              <div style="margin-right: 20px" *ngIf="workflow?.published_version?.workflow_version_trigger?.icon_url">
                <tidy-image
                  *ngIf="workflow?.published_version?.workflow_version_trigger?.icon_url"
                  [src]="workflow?.published_version?.workflow_version_trigger?.icon_url"
                  style="width:25px">
                </tidy-image>
                <ng-container *ngFor="let action of workflow?.published_version?.workflow_version_actions">
                  <tidy-image
                    [src]="action?.icon_url"
                    style="width:25px">
                  </tidy-image>
                </ng-container>
              </div>
              <div style="margin-right: 20px" *ngIf="workflow?.draft_version?.workflow_version_trigger?.icon_url && !workflow?.published_version?.workflow_version_trigger?.icon_url">
                <tidy-image
                  *ngIf="workflow?.draft_version?.workflow_version_trigger?.icon_url"
                  [src]="workflow?.draft_version?.workflow_version_trigger?.icon_url"
                  style="width:25px">
                </tidy-image>
                <ng-container *ngFor="let action of workflow?.draft_version?.workflow_version_actions">
                  <tidy-image
                    [src]="action?.icon_url"
                    style="width:25px">
                  </tidy-image>
                </ng-container>
              </div>
              <tidy-text
                [body]="workflow.name">
              </tidy-text>
            </tidy-row>
            <div>
            </div>
          </tidy-card-button>
        </tidy-card>
      </ng-container>

      <tidy-alert class="empty-list-alert" *ngIf="filteredWorkflows.length === 0">
        <tidy-row [align]="'center'">
          <tidy-text
            helper="No Workflows Added">
          </tidy-text>
        </tidy-row>
      </tidy-alert>

      <tidy-row>
        <tidy-text
          [header]="'Templates'">
        </tidy-text>
      </tidy-row>

      <ng-container *ngFor="let template of workflowTemplates">
        <tidy-card>
          <tidy-row class="no-padding" [alignLastItemRight]="true">
            <tidy-text
              [header]="template?.name">
            </tidy-text>
            <tidy-text
              [actionBody]="'Add'"
              class="link"
              (action)="addTemplate(template?.id)">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <tidy-text
              [body]="template?.description">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/play.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Trigger:'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-middle">
            <tidy-image
              [src]="template?.trigger?.icon_url">
            </tidy-image>
            <tidy-text
              [body]="template?.trigger?.name + ' - ' + template?.trigger?.description">
            </tidy-text>
          </tidy-row>
          <tidy-row class="vertical-align-center">
            <tidy-image
              [src]="'assets/img/check.svg'"
              class="body-size">
            </tidy-image>
            <tidy-text
              [header]="'Actions:'">
            </tidy-text>
          </tidy-row>
          <ng-container *ngFor="let action of template?.workflow_template_actions">
            <tidy-row class="vertical-align-middle">
              <tidy-image
                [src]="action?.action?.icon_url">
              </tidy-image>
              <tidy-text
                [body]="action?.action?.name + ' - ' + action?.action?.description">
              </tidy-text>
            </tidy-row>
          </ng-container>
        </tidy-card>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!loaded">

      <tidy-row>
        <ion-item>
          <ion-skeleton-text animated slot="start" style="width: 19%; height: 16px"></ion-skeleton-text>
          <ion-skeleton-text animated slot="end" style="width: 17%; height: 16px"></ion-skeleton-text>
        </ion-item>
      </tidy-row>

      <tidy-card *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
        <tidy-card-button (action)="goToWorkflowRunPage(run)">
          <tidy-row class="vertical-align-middle" [alignLastItemRight]="true">
            <div style="width:35%">
              <ion-skeleton-text animated style="height: 20px"></ion-skeleton-text>
            </div>
            <div>
            </div>
          </tidy-row>
        </tidy-card-button>
      </tidy-card>

    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

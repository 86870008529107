import { Pipe, PipeTransform } from '@angular/core';
import { Localstorage } from '../../providers/localstorage/localstorage'

@Pipe({ name: 'typePlanFormat' })
export class TypePlanFormatPipe implements PipeTransform {
  constructor (private wrapperLocalStorage: Localstorage) {}

  transform(value: string): any {
    if (!value) {
      return value;
    }

    const isAirbnb = (this.wrapperLocalStorage.getSingleData('airbnb') === 'true');

    const typeOfPlan = {
      'tidy': '1 Hour Cleaning',
      'plus': '2.5 Hour Cleaning',
      'turnover_tidy': '1 Hour Cleaning (2 Pros)',
      'turnover_plus': '2.5 Hour Cleaning (2 Pros)',
      'mighty': '4 Hour Cleaning (2 Pros)',
      'half_mighty': '4 Hour Cleaning',
      'tidy_xl': '4 Hour Cleaning',
    }

    return typeOfPlan[value];
  }
}

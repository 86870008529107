import { Communication } from './../../../providers/communication/communication';
import { TidyStorage } from './../../../shared/providers/tidy-storage';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Schedule } from 'src/providers/schedule/schedule';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { Util } from 'src/shared/util/util';

@Component({
  selector: 'new-message-modal',
  templateUrl: 'new-message-modal.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./new-message-modal.scss'],
})
export class NewMessageModalComponent implements OnInit {

  isMobileResolution = false;
  messageForm: UntypedFormGroup;
  proList: any[];
  pros: any[];
  imagePreview: string;
  imageFile: any;
  isProSelected = false;
  pro: any;
  messages: any[];
  isMessagesLoaded = false;
  proId: number;
  chatRoomKey: string;
  pageTitle = 'Messages';
  assignLabel = '';
  assignList = [];
  showAssign = false;
  showSendToField: boolean;
  showAssignField: boolean;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private storage: TidyStorage,
    private util: Util,
    private schedule: Schedule,
    private communication: Communication,
    private rightSidePanelService: RightSidePanelService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.rightSidePanelService.setDialogPageTitle('Messages');
    this.imagePreview = '';
    this.isMobileResolution = window.innerWidth <= 870;
    this.proList = await this.storage.retrieve('proList');
    this.pros = await this.storage.retrieve('pros');
    this.messageForm = this.fb.group({
      pro: ['', Validators.required],
      message: [''],
      assignedTo: [''],
    });
    this.proId = await this.storage.retrieve('proId');
    this.chatRoomKey = await this.storage.retrieve('chatRoomKey');
    this.showSendToField = true;
    if (this.proId || this.chatRoomKey) {
      this.showSendToField = false;
      this.messageForm.get('pro').setValue(this.proId || this.chatRoomKey);
      this.onSelectPro(this.proId || this.chatRoomKey);
    }
  }

  async sendAttachment(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        loading.dismiss();
        const errorMessage =
          'Unable to attach photo. Please upload a PNG or JPEG file.';
        this.util.showError(errorMessage);
        return;
      }
      this.modalCtrl.getTop().then((res) => {
        if (res) {
          res.setCurrentBreakpoint(0.65);
        }
      });
      this.imagePreview = attachment.location;
      this.imageFile = attachment.file;
      loading.dismiss();
    } catch (err) {
      loading.dismiss();
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  removeImage(): void {
    this.imagePreview = '';
    this.imageFile = null;
  }

  async sendMessage(): Promise<void> {
    this.util.closeLastOpenedToast();
    if (this.messageForm.invalid) {
      this.util.showWarning('Please select a pro to continue.');
      return;
    }
    if (
      this.messageForm.get('message').value === '' &&
      (!this.imagePreview || this.imagePreview === '')
    ) {
      this.util.showWarning('Please add a message or photo to continue.');
      return;
    }
    const foundPro = this.proList.find(
      (pro) => pro.value === this.messageForm.get('pro').value
    );

    let payload: any = {
      chat: {
        type: 'account',
        data: {
          team_id: foundPro.teamId,
        },
      },
      message: {
        text: this.messageForm.value.message,
        files: this.imagePreview !== '' ? [this.imagePreview] : [],
      },
    };
    if (this.pro?.chat_room_key) {
      payload = {
        ...payload,
        chat: {
          type: 'proxy',
          key: this.pro.chat_room_key,
        },
      };
    }
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (
        response?.data?.message ==
        "We couldn't send your message, please try again later!"
      ) {
        const errorMessage =
          'Error sending message. Please try again later. Error message: ' +
          response.data.message;
        this.util.showError(errorMessage);
        return;
      }
      this.getMessages();
      this.imagePreview = '';
      this.imageFile = null;
      this.messageForm.controls.message.reset();
    } catch (err) {
      const errorMessage =
        'Error sending message. Please try again later. Error message: ' +
        (err.error ? err.error.message : err.message);
      this.util.showError(errorMessage);
    }
  }

  async onSelectPro(id: number | string): Promise<void> {
    this.isProSelected = true;
    this.pro = this.pros.find((pro) => pro.id === id || pro?.chat_room_key === id);
    this.pageTitle = 'Messages with ' + this.pro.first_name + ' ' + this.pro.last_name;
    this.pageTitle = this.pageTitle.length > 40 ? this.pageTitle.substring(0, 40) + '..' : this.pageTitle;
    this.rightSidePanelService.setDialogPageTitle(this.pageTitle);
    this.messageForm.get('assignedTo').reset();
    await this.getMessages();
    this.markMessagesAsRead();
  }

  async markMessagesAsRead() {
    const firstUnreadMessage = this.messages.find((message) =>
      !message.is_read && (message?.chat_room_key || this.pro?.chat_room_key)
    );

    if (firstUnreadMessage) {
      await this.communication.markMessagesAsRead([firstUnreadMessage?.chat_room_key || this.pro?.chat_room_key]);
    }
  }

  async getMessages(): Promise<void> {
    this.showAssign = false;
    this.isMessagesLoaded = false;
    let messages = [];
    if (this.pro?.chat_room_key) {
      this.handleAssignInput();
      messages = await this.communication.getProxySharedInbox(this.pro.chat_room_key);
    } else {
      messages = await this.communication.getSharedInbox(
        this.pro.main_team_id
      );
    }
    this.messages = messages.reverse();
    this.isMessagesLoaded = true;
  }

  async handleAssignInput(): Promise<void> {
    this.showAssign = true;
    this.showAssignField = false;
    this.messageForm.get('assignedTo').setValue(this.pro?.data?.assigned_to?.id);
    if (this.pro.first_name.toLowerCase() === 'pro') {
      this.assignLabel = 'Assign number to pro';
      const proList = await this.storage.retrieve('proList');
      this.assignList = this.getFilteredProListWithoutProxyNumbers(proList);
    } else {
      this.assignLabel = 'Assign number to property';
      const addressFilter = await this.storage.retrieve('addressFilter');
      this.assignList = addressFilter;
    }
  }

  getFilteredProListWithoutProxyNumbers(proList: any[]): any[] {
    return proList.filter((pro) => !pro.chatRoomKey);
  }

  async assignNumber(): Promise<void> {
    const loading = await this.util.showLoading();
    try {
      await this.communication.assignChat(
        this.pro.chat_room_key,
        this.messageForm.get('assignedTo').value,
        this.pro.first_name.toLowerCase() === 'pro' ? 'homekeeper' : 'address'
      );
      loading.dismiss();
      this.showAssignField = false;
      this.util.showSuccess('Chat assigned successfully');
    } catch (err) {
      loading.dismiss();
      const errorMessage = err.error ? err.error.message : err.message;
      this.util.showError(errorMessage);
    }
  }

  toggleShowAssignField() {
    this.showAssignField = !this.showAssignField;
  }

  getAssignedToViewValue(value) {
    return this.assignList.find((item) => item.value == value).viewValue;
  }

}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

import { Annotorious } from '@recogito/annotorious';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { GenomeMaps } from 'src/providers/genome-maps/genome-maps';

import { AddressModel } from 'src/models/address.model';

@Component({
  templateUrl: 'property-mapping.html',
  styleUrls: ['property-mapping.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PropertyMappingPage implements OnInit {

  errorMessage: string;
  address: AddressModel;
  imageSrc: any;
  anno: any;
  createdAnnotation: boolean;
  showButtons: boolean;
  isMobile: boolean;
  selectionJson: any;
  mapToShow: any;
  awsData: any;
  requestReview: any;

  constructor(
    private navCtrl: CustomNavController,
    private genomeMaps: GenomeMaps,
    private platform: Platform,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    //TODO add right side panel handling if we undeprecate this page
    this.address = this.navCtrl.getParam('address', true);
    this.mapToShow = this.requestReview ?
      this.requestReview.satellite_image_s3_url :
      this.sanitizer.bypassSecurityTrustUrl(this.navCtrl.getParam('map', true));    this.awsData = this.navCtrl.getParam('awsData');
    this.isMobile = this.platform.is('mobile');
    this.requestReview = this.navCtrl.getParam('requestReview');
    const config = {
      image: document.getElementById('map'),
      disableEditor: true,
      locale: 'auto'
     };
    this.createdAnnotation = false;
    this.anno = new Annotorious(config);
    await this.setAnnotationListeners();
  }

  async setAnnotationListeners(){
    await this.anno.on('createSelection', async(selection) => {
      selection.body = [{
        type: 'TextualBody',
        purpose: 'tagging',
        value: 'MyTag'
      }];
      if(!this.createdAnnotation){
        await this.anno.updateSelected(selection);
        this.anno.saveSelected();
        this.showButtons = true;
      } else {
        this.anno.cancelSelected();
        this.errorMessage = 'You may only have one lot selection. Use Retry if you think your current selection is inaccurate.'
      }
    });

    this.anno.on('createAnnotation',(annotation) => {
      this.selectionJson = annotation;
      this.createdAnnotation = true;
    });
    this.anno.setDrawingTool("polygon");
  }

  resetAnotations(){
    this.createdAnnotation = false;
    this.showButtons = false;
    this.errorMessage = '';
    this.anno.clearAnnotations();
  }

  parseSelection(){
    let annotations = this.anno.getAnnotations();
    let coordinates = annotations[0].target.selector.value.split(/\"(.*?)\"/g);
    coordinates = coordinates[1].split(" ").map(coordinate => {
      return coordinate
            .split(",")
            .map(point =>{
              return parseInt(point);
            });
    });
    if(coordinates.length <= 2){
      this.errorMessage = 'You may only have one Lot Selection, use Retry if you think your current selection is inaccurate.'
      this.resetAnotations();
    } else {
      return coordinates;
    }
  }

  async submitSelection(){
    const lotCoordinates = this.parseSelection();
    if(this.requestReview && !this.requestReview?.redo_requests.includes('video')){
      const resubmitParams = {
        lot_outline_points: lotCoordinates,
        house_tour_video_s3_url: this.requestReview.house_tour_video_s3_url
      }
      try{
        await this.genomeMaps.updateMapRequest(this.requestReview.id, resubmitParams);
        this.navCtrl.navigateForward(`edit-property/${this.address.id}`);
      } catch(err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      }
    }
    else{
      const params = {
        address: this.address,
        requestReview: this.requestReview,
        mapUrl: this.awsData? this.awsData?.Location: this.requestReview.house_tour_video_s3_url,
        lotCoordinates
      };
      this.anno.destroy();
      this.navCtrl.navigateForward('property-scanning', params);
    }
  }
}

import { Util } from 'src/shared/util/util';
import { MatDialog } from '@angular/material/dialog';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { WindowService } from 'src/shared/providers/window.service';
import { DateTime as LuxonDateTime } from 'luxon';

import { BookJob } from 'src/providers/book-job/book-job';
import { CameraProvider } from 'src/shared/providers/camera/camera';
import { Client } from 'src/providers/client/client';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Communication } from 'src/providers/communication/communication';
import { DynamicComponentProvider } from 'src/shared/providers/dynamic-component/dynamic-component';
import { Genome } from 'src/providers/genome/genome';
import { JobMediasProvider } from 'src/providers/job-medias/job-medias.provider';
import { Logger } from 'src/providers/logger';
import { Me } from 'src/providers/me/me';
import { Pros } from 'src/providers/pros/pros';
import { CalendarViewJobResponse, Schedule } from 'src/providers/schedule/schedule';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { ToDos } from 'src/providers/to-dos/to-dos';
import { GuestReservations } from 'src/providers/guest-reservations/guest-reservations';

import { Loading } from 'src/shared/components/loading/loading';
import { ConfirmPage, ConfirmPageParamsModel } from 'src/pages/confirm/confirm.component';

import { ClientSettingsModel } from 'src/models/client.model';

import { MapPopup } from 'src/pages/dashboard/map-popup/map-popup';
import { popupOptions } from 'src/pages/dashboard/map-popup/popup-opts';
import { UnableToMessageModalComponent } from './unable-to-message-modal/unable-to-message';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { RequestJobPage } from 'src/pages/request-job/request-job';
import { SuccessPage } from 'src/shared/pages/success/success';
import { BookJobPage } from 'src/pages/booking/book-job/book-job';
import { ProDidNotShowUpPage } from 'src/pages/pro-did-not-show-up/pro-did-not-show-up';
import { NotificationDetailPage } from '../notification-details/notification-details';
import { MyProPage } from 'src/pages/my-pros/my-pro/my-pro';
import { RequestSubstitutePage } from 'src/pages/request-substitute/request-substitute';
import { JobBackupTimesPage } from 'src/pages/job-backup-times/job-backup-times';
import { ShareJobPage } from 'src/pages/share-job/share-job';
import { MarkJobCompletePage } from '../mark-job-complete/mark-job-complete';
import { UseToDosPage } from 'src/pages/to-dos/use-to-dos/use-to-dos';
import { ToDosApplyToJobPage } from 'src/pages/to-dos/apply-to-job/apply-to-job';
import { JobActivityPage } from '../job-activity/job-activity';
import { BookingNotePage } from 'src/pages/booking-note/booking-note';
import { CallProPage } from 'src/pages/call-pro/call-pro';
import { AssignProPage } from 'src/pages/assign-pro/assign-pro';
import { WhyAddPrivateProsPage } from 'src/pages/why-add-private-pros/why-add-private-pros';
import { Booking, BookingResponse } from 'src/providers/booking/booking';
import { JobCard } from 'src/models/schedule.model';

declare const mapboxgl: any;

@Component({
  templateUrl: 'job.html'
})
export class JobPage implements OnInit {

  attachments = [];
  addressName: string;
  activity: any;
  beforeAfterState: any;
  beforePhotos: any;
  afterPhotos: any;
  bookingFormAnswers: any;
  bookingNote: any;
  clientName: string;
  contactData: any;
  detailsType: string;
  didProStartToDos: boolean;
  errorMessage: any;
  form: UntypedFormGroup;
  isUsingToDos: boolean;
  isSavingToDoList: boolean;
  isInternalComment: boolean;
  jobBackPage: string;
  jobBackupTimes: any;
  jobBackupTimesCount: number;
  jobBackupTimesLoaded: boolean;
  job: CalendarViewJobResponse;
  bookingDetail: BookingResponse;
  cardDetail: JobCard;
  jobId: number;
  toDoFilter: any;
  addressId: any;
  hasPrivatePro: boolean;
  messages: any;
  messageSelectItems: any;
  notificationHistory: any;
  rooms: any;
  settings: ClientSettingsModel;
  selectedMessageType: any;
  submitted: boolean;
  toDoLists: any;
  mapInstance: any;
  @ViewChild('map', {static: false}) mapElement: ElementRef;
  dialogParams: any;
  isRightSideContent = true;
  jobIsInOneTimePlan: boolean;
  isToDosLoaded = false;
  isCardLoaded = false;
  isMapLoaded = false;
  isMessagesLoaded = false;
  loaded: boolean;
  jobEvents: any[];
  hasQuotes = false;
  smallestQuote = 0;

  constructor(
    private bookJobService: BookJob,
    private cameraProvider: CameraProvider,
    private client: Client,
    public communication: Communication,
    private dynamicComponentService: DynamicComponentProvider,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private jobMediasProvider: JobMediasProvider,
    private logger: Logger,
    public me: Me,
    private modalCtrl: ModalController,
    private navCtrl: CustomNavController,
    private pros: Pros,
    private route: ActivatedRoute,
    public schedule: Schedule,
    private booking: Booking,
    private storage: TidyStorage,
    public toDos: ToDos,
    private dialog: MatDialog,
    private util: Util,
    private rightSidePanelService: RightSidePanelService,
    public windowService: WindowService,
    private guestReservations: GuestReservations
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
      messageType: ['public_reply'],
      list: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
    if (this.isRightSideContent) {
      this.dialogParams = await this.storage.retrieve('dialog-params');
      this.cardDetail = null;
      this.cardDetail = this.dialogParams?.card;
      this.job = this.dialogParams?.object;
      this.rightSidePanelService.setDialogPageTitle('Job');
      this.loadData();
    } else {
      const navParams = this.navCtrl.getParams();
      this.cardDetail = null;
      this.cardDetail = navParams?.card;
      this.job = navParams?.object;
      this.loadData();
    }
  }

  async loadData() {
    try {
      this.addressId = this.job?.address_id || this.dialogParams?.addressId || this.route.snapshot.paramMap.get('addressId');
      this.jobId = this.job?.id || this.dialogParams?.jobId || this.cardDetail?.template_data?.job?.id || parseInt(this.route.snapshot.paramMap.get('jobId'));
      this.hasPrivatePro = await this.pros.checkIfHasPrivatePro();
      this.bookJobService.clearBookJobStorage();
      this.errorMessage = '';
      this.initializeData();
      await this.loadDynamicData();
      const templateData = this.cardDetail?.template_data;
      this.hasQuotes = this.cardDetail?.template == 'job_request' && templateData?.job_request_status == 'pending' && templateData?.potential_homekeepers?.length > 0;
      this.smallestQuote = templateData?.potential_homekeepers?.reduce((min, homekeeper) => Math.min(min, homekeeper.amount), Infinity);
    } catch (err) {
      console.error('err', err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  goToBidsPage() {
    this.rightSidePanelService.navigateTo('job-request-bids', { cardDetail: this.cardDetail, bookingDetail: this.bookingDetail });
  }

  async initializeData() {
    localStorage.setItem('addressId', this.addressId);
    const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
    this.jobBackPage = localStorage.getItem('jobBackPage') || (shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
  }

  async getJobBackupTimes() {
    this.jobBackupTimesLoaded = false;
    this.jobBackupTimes = await this.client.getJobBackupTimes(this.bookingDetail.id);
    this.jobBackupTimesCount = this.getJobBackupTimesCount();
    this.jobBackupTimesLoaded = true;
  }

  async getContactData() {
    this.contactData = await this.schedule.getContactData(this.cardDetail.id);
  }

   getBookingNotes() {
    this.bookingNote = this.bookingDetail?.booking_notes;
  }

  async getBookingFormAnswers() {
    this.bookingFormAnswers = await this.schedule.getBookingFormAnswers(this.bookingDetail.id);
  }

  async loadDynamicData() {
    try {
      const bookingId = this.job?.booking?.id || this.dialogParams?.bookingId || this.cardDetail?.template_data?.booking?.id || localStorage.getItem('bookingId');
      localStorage.removeItem('bookingId');
      const [
        cardDetail,
        bookingDetail,
        settings,
      ] = await Promise.all([
        this.getJobCard(),
        this.booking.getBookingDetail(bookingId),
        this.getClientSettings(),
      ]);
      this.cardDetail = cardDetail;
      if (this.cardDetail?.template_data?.notification_history) {
        this.cardDetail.template_data.notification_history = this.schedule.getFilteredWaterfallHistory(this.cardDetail.template_data.notification_history);
      }
      this.bookingDetail = bookingDetail;
      this.settings = settings;
      this.cardDetail = await this.addUnassignedData(this.cardDetail);
      this.getBookingNotes();
      this.getAddress();
      await Promise.all([
        this.getLists(),
        this.getClientName(),
        this.getContactData(),
        this.getJobBackupTimes(),
        this.getBookingFormAnswers(),
        this.getJobEvents(bookingId)
      ]);
      this.isUsingToDos = !!cardDetail?.template_data?.job?.task_list.id;
      this.form.patchValue({
        list: cardDetail?.template_data?.job?.task_list.id || -2
      });
      if (this.isUsingToDos) {
        const list = this.toDoLists.find((list) => list.id == cardDetail?.template_data?.job?.task_list.id);
        this.beforeAfterState = this.getBeforeAfterState(list?.before_after_photos_state);
      }
      if (this.isUsingToDos) {
        this.isToDosLoaded = false;
        this.loadRooms();
      } else {
        this.isToDosLoaded = true;
      }
      this.activity = await this.buildActivityArrays();
      this.isMessagesLoaded = true;
      this.markMessagesAsRead();
      this.messageSelectItems = this.getMessageSelectItems();
      this.initializeForm();
    } catch (err) {
      console.error('err', err);
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  async getJobEvents(bookingId: number) {
    this.jobEvents = await this.schedule.getJobEvents(bookingId);
  }

  async loadJobMedias(): Promise<any> {
    const jobMedias = await this.jobMediasProvider.getJobMedias([this.jobId]);
    const beforePhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'before_job');
    const afterPhotos = jobMedias.filter((jobMedia) => jobMedia.category === 'after_job');
    return {
      beforePhotos,
      afterPhotos,
    };
  }

  async getParsedJobMediasWithMetadata(jobMedias: any[]) {
    const homekeepers = this.cardDetail.template_data.homekeepers;
    jobMedias = await this.toDos.processFilesAndReturn(jobMedias);
    return jobMedias.map((jobMedia) => {
      const homekeeper = homekeepers.find((homekeeper) => homekeeper.id === jobMedia.uploaded_by_id);
      return {
        ...jobMedia,
        mediaType: jobMedia.media_format,
        url: jobMedia.media_url,
        uploadedBy: jobMedia.uploaded_by_type === 'User' ? homekeeper?.name : null,
      };
    });
  }

  async takeVideo(categoryType: string) {
    this.errorMessage = '';
    let loading;
    try {
      const parsedJobMedia = await this.jobMediasProvider.takeBeforeAfterVideo(categoryType, this.jobId);
      loading = await this.util.showLoading();
      await this.jobMediasProvider.saveJobMedias(this.jobId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
      loading.dismiss();
    } catch (err) {
      loading.dismiss();
      this.util.showError('Error taking video. Please try again.', err);
      console.error('error on trying to take/upload file:', err);
      this.errorMessage =
        err.error && err.error.message ? err.error.message : err.message;
    }
  }

  async takePhoto(categoryType: string) {
    this.cameraProvider.getImg().then(async (photo) => {
      if (!photo?.base64String && !photo?.dataUrl) {
        return;
      }
      const parsedPhoto = photo?.dataUrl && !photo?.base64String ? photo?.dataUrl : `data:image/jpeg;base64,${photo.base64String}`;
      const newPhoto = { url: parsedPhoto, mediaType: 'photo' };
      const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(newPhoto.url, this.jobId, categoryType);
      await this.jobMediasProvider.saveJobMedias(this.jobId, [parsedJobMedia]);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    });
  }

  async uploadVideo(videos: any[], categoryType: string) {
    try {
      const parsedJobMedias = [];
      await Promise.all(
        videos.map(async (video) => {
          const parsedJobMedia = await this.jobMediasProvider.uploadAndSaveVideo(
            video.file,
            this.jobId,
            categoryType,
            video.file
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.jobId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading video. Please try again.', err);
    }
  }

  async uploadPhoto(photos: any[], categoryType: string) {
    const loading = await this.util.showLoading();
    const parsedJobMedias = [];
    try {
      await Promise.all(
        photos.map(async (photo) => {
          const parsedJobMedia = await this.jobMediasProvider.saveBeforeAfterPhoto(
            photo.url,
            this.jobId,
            categoryType,
            photo?.file,
            photo?.exif
          );
          parsedJobMedias.push(parsedJobMedia);
        })
      );
      await this.jobMediasProvider.saveJobMedias(this.jobId, parsedJobMedias);
      const { beforePhotos, afterPhotos } = await this.loadJobMedias();
      if (categoryType == 'before_job') {
        this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
      } else {
        this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error uploading photo. Please try again.', err);
    } finally {
      loading.dismiss();
    }
  }

  async removePhoto(image, index, categoryType) {
    const params: ConfirmPageParamsModel = {
      title: image?.mediaType == 'photo' ? 'Remove photo?' : 'Remove video?',
      body: '',
      backText: 'Go Back',
      confirmText: 'Remove ',
      confirmAction: this.confirmRemovePhoto.bind(this, image, index, categoryType)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmRemovePhoto(image, index, categoryType) {
    const loading = await this.util.showLoading();
    try {
      await this.jobMediasProvider.deleteJobMedia(image.id);
      if (categoryType == 'before_job') {
        this.beforePhotos.splice(index, 1);
      } else {
        this.afterPhotos.splice(index, 1);
      }
    } catch (err) {
      console.error(err);
      this.util.showError('Error removing photo. Please try again.', err);
    } finally {
      loading.dismiss();
    }
    this.modalCtrl.dismiss();
  }

  @Loading('', true)
  async saveList() {
    if (this.didProStartToDos) {
      return
    }
    this.storage.delete('addresses');
    this.isSavingToDoList = true;
    this.isUsingToDos = this.form.value.list !== -2;
    const listID = this.form.value.list === -2 ? null : this.form.value.list;
    await this.toDos.assignListToJob([this.jobId], listID);
    if (this.isUsingToDos) {
      const list = this.toDoLists.find((list) => list.id == this.form.value.list);
      this.beforeAfterState = this.getBeforeAfterState(list?.before_after_photos_state);
      await this.loadRooms();
    }
    this.isSavingToDoList = false;
  }

  async getLists() {
    this.toDoLists = await this.toDos.getAllToDoListsForAddressAndServiceDefaults(this.addressId, this.cardDetail.template_data.job.service_type_details.key);
    this.toDoFilter = await this.toDos.parseToDoFilterItems(this.toDoLists, this.addressId);
    this.toDoFilter.pop();
    this.toDoFilter.push({
      viewValue: 'Don\'t Use To-Dos',
      value: -2
    });
  }

  async loadRooms(): Promise<void> {
    this.rooms = await this.toDos.getToDosForPastJob(this.jobId);
    if (this.rooms.length) {
      this.didProStartToDos = true;
    } else {
      this.didProStartToDos = false;
      this.rooms = await this.toDos.getListDetail(this.form.value.list);
      this.rooms = await this.toDos.getMetadataFromFiles(this.rooms);
    }
    this.rooms = this.toDos.buildRoomIcons(this.rooms);
    const { beforePhotos, afterPhotos } = await this.loadJobMedias();
    this.beforePhotos = await this.getParsedJobMediasWithMetadata(beforePhotos);
    this.afterPhotos = await this.getParsedJobMediasWithMetadata(afterPhotos);
    this.isToDosLoaded = true;
  }

  async getJobCard() {
    if (this.cardDetail) {
      return this.cardDetail;
    }
    return this.schedule.getJobCard(this.addressId, this.jobId);
  }

  initializeForm() {
    this.form.patchValue({messageType: 'public_reply'});
    this.selectedMessageType = this.messageSelectItems[1];
  }

  async getAddress() {
    const address = this.bookingDetail.address;
    this.addressName = this.client.buildAddressName(address);
    this.buildMap();
  }

  async getClientSettings() {
    const settings = this.navCtrl.getParam('settings');
    return settings || await this.client.getClientSettings();
  }

  async buildMap() {
    try {
      if (!this.mapElement) {
        return setTimeout(() => this.buildMap(), 0);
      }
      this.mapInstance = this.createMap();
      setTimeout(() => this.mapInstance.resize(), 100);
      this.isMapLoaded = true;
      const moments = this.getMoments();
      this.addMomentsToMap(moments, this.mapInstance);
      this.addAddressToMap(this.mapInstance);
      this.setMapBounds(this.mapInstance, moments);
    } catch (err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  createMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrdHVoNHc1bTIwYjYycHE1NmhjdzVtdzMifQ.OIqKrdlDh4fJH0g0MO1zLg';
    return new mapboxgl.Map({
      container: `map${this.jobId}`,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      maxZoom: 16,
    });
  }

  getMoments() {
    const moments: any = [];
    this.cardDetail.template_data?.activity_timelines?.map((activityTimeline) => {
      activityTimeline.activity_timeline_items.map((moment) => {
        if (moment.type == 'cleaning_update' && moment.data?.latitude) {
          moments.push({
            latitude: moment.data.latitude,
            longitude: moment.data.longitude,
            body: '',
            image: this.schedule.getMomentImage(moment.data.data.type),
            mapIcon: this.schedule.getMomentImage(moment.data.data.type),
            header: moment.data.text
          });
        }
      });
    });
    return moments;
  }

  addMomentsToMap(moments, map) {
    moments.forEach((moment, i) => {
      if (moment?.latitude) {
        const popupContent = this.dynamicComponentService.injectComponent(
          MapPopup, x => x.data = {
            image: moment.image,
            body: moment.body,
            header: moment.header,
            learnMore: ''
          }
        );
        this.createPopupAndMarker(map, popupContent, moment.mapIcon, moment);
      }
    });
  }

  addAddressToMap(map) {
    const address = this.bookingDetail.address;
    const popupContent = this.dynamicComponentService.injectComponent(
      MapPopup, x => x.data = {
        image: 'assets/img/house.svg',
        body: '',
        header: address.address_name || address.address1,
        learnMore: ''
      }
    );
    const addressIcon = 'assets/img/map-home-blue.svg';
    this.createPopupAndMarker(map, popupContent, addressIcon, address);
  }

  createPopupAndMarker(map, popupContent, mapIcon, location, customOpts = null) {
    const popup = new mapboxgl.Popup({...popupOptions, ...customOpts}).setDOMContent(popupContent);
    const el = document.createElement('div');
    el.className = 'marker';
    el.innerHTML = `<image src="${mapIcon}" style="width: 40px; height: 40px; padding-top: 10px;"></image>`;
    this.createMarkerInstance({map, el, longLat: [ location.longitude, location.latitude ]}, popup);
  }

  createMarkerInstance(obj, popup) {
    const {el, longLat, map} = obj;
    const marker = new mapboxgl.Marker(el);
    marker.getElement().addEventListener('click', () => {
      const flyToCoords = [...longLat];
      const getZoom = map.getZoom() ;
      const zoomOut = getZoom < 0;
      const lgnSum = zoomOut ? 200 / (5 * getZoom) : 50 / (2 ** getZoom);
      if (flyToCoords[1] + lgnSum > 90) {
        flyToCoords[1] = 90;
      } else if ((flyToCoords[1] + lgnSum < -90)) {
        flyToCoords[1] = -90;
      } else {
        flyToCoords[1] += lgnSum;
      }
      map.flyTo({
        center: flyToCoords
      })
    });
    marker.setLngLat(longLat).setPopup(popup).addTo(map);
  }

  setMapBounds(map, moments) {
    const address = this.bookingDetail.address;
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([address.longitude, address.latitude]);
    moments.forEach((moment) => {
      if (moment.latitude) {
        bounds.extend([moment.longitude, moment.latitude]);
      }
    });
    map.fitBounds(bounds, { padding: 50, duration: 0 });
  }

  async getClientName() {
    const me = await this.me.load();
    this.clientName = me.customer_account.name;
  }

  getJobBackupTimesCount() {
    let count = 0;
    this.jobBackupTimes.map((job) => {
      if (job.selected) {
        count += 1;
      }
    });
    return count;
  }

  async buildActivityArrays() {
    let activity = [];
    if (!this.cardDetail.template_data.homekeepers) {
      return [];
    }
    await Promise.all(this.cardDetail.template_data.homekeepers.map(async homekeeper => {
      const chatRoomKey = `homekeeper_job_id-${homekeeper.homekeeper_job_id}`;
      const AISuggestionsPayload = {
        feature: 'chat_suggestion',
        data: {
          chat_room_key: chatRoomKey
        }
      }
      await this.communication.generateAISuggestions(AISuggestionsPayload);
      const messages = await this.communication.getSharedInboxWithChatRoomKey(chatRoomKey);
      activity.push({
        pro: homekeeper,
        messages: this.parseActivity(messages, homekeeper.homekeeper_job_id)
      });
    }));
    return activity;
  }

  parseActivity(messages, homekeeperJobId) {
    let activity = [];
    this.cardDetail.template_data?.activity_timelines?.map((timeline) => {
      if (timeline.homekeeper_job_id == homekeeperJobId) {
        let updates = timeline.activity_timeline_items.filter((item) => item.type == 'cleaning_update');
        activity = this.concatenateMessagesAndUpdates(updates, messages);
      }
    });
    return activity;
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates.map((update) => {
      update['sent_at'] = update?.data?.created_at;
    });
    if (updates.length) {
      const activity = updates.concat(messages);
      activity.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return activity;
    } else {
      messages.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return messages;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.activity.map((pro) => {
      pro.messages.map((message, index) => {
        if (index < 3 && !message?.is_read && message?.from_member?.type !== 'CustomerMember' && message?.type !== 'cleaning_update' && message?.chat_room_key) {
          array.push((message?.chat_room_key));
        }
      })
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async addUnassignedData(card: JobCard) {
    if (card.template_data?.scenario_type === 'unassigned' || card.template_data?.scenario_type === 'manual_request') {
      card.template_data.updates.map(async (update) => {
        let updateHasAction;
        update.text.map(async (text) => {
          updateHasAction = text?.action === 'cleaning_update_page';
        });
        if (updateHasAction) {
          card['unassigned'] = await this.schedule.getUpdates(update.id);
        };
      });
    };
    return card;
  }

  @Loading('', true)
  async checkWaterfallHistory(selectionChange){
    const notification = this.cardDetail.template_data?.notification_history?.[selectionChange.selectedIndex];
    this.notificationHistory = '';
    if(notification.type === 'history' && (notification?.data?.id && notification?.data?.unassigned_job_id)){
      try {
        this.notificationHistory = await this.client.getWaterfallNotificationHistory(notification?.data?.id)
      } catch (err) {
        this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
        this.notificationHistory = '';
        this.logger.error(err);
      }
    } else {
      this.notificationHistory = '';
    }
  }

  goToNotificationDetailsPage(notification) {
    const params = {
      notification
    }
    const url = 'notification-details';
    const component = NotificationDetailPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  editWaterfallRequest() {
    const serviceTypeDetails = this.bookingDetail.service_type_details;
    const params: any = {
      selectedService: {
        key: serviceTypeDetails.key,
        title: serviceTypeDetails.name,
        price: serviceTypeDetails.prices.base_price,
        duration: serviceTypeDetails.duration_in_minutes,
      },
      address: this.bookingDetail.address,
      planId: this.bookingDetail.current_job.plan_id,
      bookingId: this.bookingDetail.id,
      serviceTypeId: this.cardDetail.template_data.booking.bookable_service.service_type_id,
    };
    const url = 'request-job';
    const component = RequestJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  @Loading('', true)
  async cancelWaterfallRequest(bookingId) {
    const { reservationId, showAutomaticBookingQuestion, reservationDateTime } = await this.guestReservations.getLastReservationDetails(this.addressId, this.jobId);
    const params: ConfirmPageParamsModel = {
      title: 'Cancel Request?',
      body: 'You can always create a new request if you\'d like.',
      backText: 'Go Back',
      confirmText: 'Cancel Request',
      confirmAction: this.confirmCancelWaterfallRequest.bind(this, bookingId, showAutomaticBookingQuestion, reservationId),
      showAutomaticBookingQuestion: showAutomaticBookingQuestion,
      reservationDateTime: reservationDateTime
    };
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmCancelWaterfallRequest(bookingId, showAutomaticBookingQuestion, reservationId) {
    try {
      if (showAutomaticBookingQuestion) {
        const keepAutomaticBookingOn = localStorage.getItem('keepAutomaticBookingOn') == 'true';
        localStorage.removeItem('keepAutomaticBookingOn');
        if (!keepAutomaticBookingOn) {
          await this.guestReservations.toggleAutomaticBookingForReservation(reservationId, false);
        }
      }
      await this.schedule.cancelJob(bookingId);
      this.modalCtrl.dismiss();
      this.closePage();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  goToProPage(proId) {
    localStorage.setItem('myProBackPage', `job/${this.bookingDetail.address_id}/${this.jobId}`);
    const url = `my-pro/${proId}`;
    const component = MyProPage;
    const params = { proId };
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  closePage() {
    if (this.windowService.isDesktopRes) {
      this.rightSidePanelService.closeRightSidePanel();
    } else {
      this.navCtrl.back();
    }
  }

  async rescheduleJob() {
    const address = this.bookingDetail.address;
    const bookingType = this.cardDetail.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
    const jobId = this.jobId;
    const bookingId = this.bookingDetail.id;
    const serviceTypeDetails = this.bookingDetail.service_type_details;
    const planId = this.bookingDetail.current_job?.plan_id;
    const categoryId = serviceTypeDetails.service_category.id;
    const params = {
      address,
      bookingType,
      jobId,
      bookingId,
      planId,
      categoryId
    }
    const homekeepers = this.cardDetail.template_data?.homekeepers;
    if (homekeepers?.length == 1 && homekeepers?.[0]?.id) {
      params['hkId'] = homekeepers[0].id;
      params['filterByPro'] = true;
    }
    const url = 'book-job';
    const component = BookJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  @Loading('', true)
  async skipJob(bookingId) {
    const { reservationId, showAutomaticBookingQuestion, reservationDateTime } = await this.guestReservations.getLastReservationDetails(this.addressId, this.jobId);
    const params: ConfirmPageParamsModel = {
      title: 'Skip Job?',
      body: 'This will cancel the job (does not affect other jobs).',
      backText: 'Go Back',
      confirmText: 'Skip Job',
      confirmAction: this.skipJobAction.bind(this, bookingId, showAutomaticBookingQuestion, reservationId),
      showAutomaticBookingQuestion: showAutomaticBookingQuestion,
      reservationDateTime: reservationDateTime
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: showAutomaticBookingQuestion ? 'tall-confirm-modal' : 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async skipJobAction(bookingId, showAutomaticBookingQuestion, reservationId) {
    try {
      if (showAutomaticBookingQuestion) {
        const keepAutomaticBookingOn = localStorage.getItem('keepAutomaticBookingOn') == 'true';
        localStorage.removeItem('keepAutomaticBookingOn');
        if (!keepAutomaticBookingOn) {
          await this.guestReservations.toggleAutomaticBookingForReservation(reservationId, false);
        }
      }
      await this.schedule.cancelJob(bookingId);
      const successMessage = 'Job skipped';
      this.util.showSuccess(successMessage);
      const shouldShowAllToDosPage = localStorage.getItem('shouldShowAllToDosPage') == 'true';
      this.navCtrl.navigateForward(shouldShowAllToDosPage || this.windowService.isDesktopRes ? 'schedule' : 'schedule-list');
      this.modalCtrl.dismiss();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  reportNoShow(proName, jobId, hkId) {
    const params = {
      proName,
      hkId,
      jobId
    }
    const url = 'pro-did-not-show-up';
    const component = ProDidNotShowUpPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  requestSubstitute(jobId) {
    const params = {
      jobId,
    };
    const url = `request-substitute/${jobId}`;
    const component = RequestSubstitutePage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToEditBackupTimes() {
    const params = {
      bookingId: this.bookingDetail.id,
      jobBackupTimes: this.jobBackupTimes,
      addressId: this.bookingDetail.address_id,
      categoryImage: this.bookingDetail.service_type_details.service_category.icon_url,
      params: {
        card: this.cardDetail,
        addressName: this.addressName,
        settings: this.settings,
        clientName: this.clientName
      }
    }
    const url = 'job-backup-times';
    const component = JobBackupTimesPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToShareJobPage() {
    const params = {
      job: this.cardDetail,
      addressName: this.addressName
    };
    const url = 'share-job';
    const component = ShareJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  goToMarkJobComplete() {
    const serviceTypeDetails = this.bookingDetail.service_type_details;
    const params = {
      bookingId: this.bookingDetail.id,
      jobId: this.jobId,
      price: (serviceTypeDetails.prices.base_price / 100).toString(),
      duration: serviceTypeDetails.duration_in_minutes,
    }
    const url = 'mark-job-complete';
    const component = MarkJobCompletePage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  async goToApplyListToJob(job) {
    if (this.bookingDetail?.address?.default_address_task_list_state === null) {
      const addressResponse = await this.client.getAddresses();
      const params = {
        addressResponse: addressResponse,
        addressId: this.bookingDetail.address_id
      }
      const url = 'use-to-dos';
      const component = UseToDosPage;
      this.rightSidePanelService.navigateTo(url, params, component);
      return;
    }
    const params = {
      job,
      categoryImage: this.bookingDetail.service_type_details.service_category.icon_url
    }
    const url = 'apply-to-job';
    const component = ToDosApplyToJobPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  unassignedAction(action) {
    const jobId = this.jobId;
    if (action == 'keep_one_homekeeper') {
      return this.keepOnlyOnePro(jobId);
    }
    if (action === 'reschedule_cleaning') {
      const planId = this.bookingDetail.current_job?.plan_id;
      const bookingType = this.cardDetail.template_data?.job?.allowed_actions?.includes('reschedule_booking') ? 'reschedule_job' : 'add_one_time_job';
      const address = this.bookingDetail.address;
      const bookingId = this.bookingDetail.id;
      const params = { address, bookingType, jobId, bookingId, planId };
      const url = 'book-job';
      const component = BookJobPage;
      this.rightSidePanelService.navigateTo(url, params, component);
      return;
    }
    if (action === 'skip_cleaning' || action === 'cancel_substitute') {
      this.skipJob(this.bookingDetail.id);
    } else if (action === 'same_day_cleaning_page_v1') {
      const service = this.bookingDetail.service_type_details;
      const params = {
        selectedService: {
          title: service.name,
          image: this.bookingDetail.service_type_details.service_category.icon_url || 'assets/img/plans/' + this.bookingDetail.bookable_service_key + '.svg',
          description: service.description,
          key: service.key,
          price: service.prices.base_price,
          duration: service.duration_in_minutes,
        },
        address: this.bookingDetail.address,
        planId: this.bookingDetail.current_job.plan_id,
        bookingId: this.bookingDetail.id,
        bookingType: 'reschedule_job',
        priceIsPlusFiftyPercent: true
      };
      const url = 'request-job';
      const component = RequestJobPage;
      this.rightSidePanelService.navigateTo(url, params, component);
      return;
    } else if (action === 'request_substitute') {
      const param = {
        jobId
      };
      const component = RequestSubstitutePage;
      const url = `request-substitute/${jobId}`;
      this.rightSidePanelService.navigateTo(url, param, component);
    }
  }

  async keepOnlyOnePro(jobId) {
    const params: ConfirmPageParamsModel = {
      title: 'Keep Only 1 Pro?',
      body: 'You\’ll be billed the prorated rate.',
      backText: 'Go Back',
      confirmText: 'Confirm',
      confirmAction: this.confirmKeepOnePro.bind(this, jobId)
    }
    const confirmationModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: params,
      animated: false,
      cssClass: 'confirm-modal'
    });
    await confirmationModal.present();
  }

  async confirmKeepOnePro(jobId) {
    try {
      await this.schedule.keepOneProForTwoProJob(jobId);
      const alertButtons = [
        {
          text: 'Ok',
          role: 'cancel',
        }
      ]
      const alert = await this.util.showAlert(
        'Kept One Pro',
        'You were billed the prorated rate. Please contact the concierge with any questions.',
        false,
        alertButtons
      );
      this.modalCtrl.dismiss();
      this.rightSidePanelService.closeRightSidePanel();
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
      throw err;
    }
  }

  @Loading('', true)
  async goToChangePro() {
    try {
      const privateProsList = await this.pros.getAllPrivatePros();
      const params = {
        job: this.cardDetail,
        booking: this.bookingDetail,
        addressId: this.bookingDetail.address_id,
        privateProsList: privateProsList,
        categoryImage: this.bookingDetail.service_type_details.service_category.icon_url
      }
      const url = privateProsList?.length ? 'assign-pro' : 'why-add-private-pros';
      const component = url === 'assign-pro' ? AssignProPage : WhyAddPrivateProsPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    } catch(err) {
      this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
    }
  }

  @Loading('', true)
  async sendMessage(pro, attachments = []) {
    const contactData = this.contactData.homekeepers.find((item) => item.id == pro.id);
    if (!contactData?.show_contact_buttons && !this.bookingDetail.current_job.is_private && !this.isInternalComment) {
      const params = {
        address: this.bookingDetail.address,
        message: this.form.value.message,
        files: attachments,
        card: this.cardDetail,
        booking: this.bookingDetail,
      }
      await this.showCantMessageProAlert(params);
      return;
    }
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    let payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.cardDetail.template_data.homekeepers?.[0].homekeeper_job_id,
        }
      },
      message: {
        text: this.form.value.message,
        files: attachments.length > 0 ? [attachments?.[0]?.location] : [],
        is_internal: this.isInternalComment
      }
    };
    this.cardDetail.template_data.homekeepers[0].first_name
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      this.activity = await this.buildActivityArrays();
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async showCantMessageProAlert(params: any): Promise<void> {
    await this.storage.delete('unableToMessageParams');
    await this.storage.save('unableToMessageParams', params);
    const isMobileResolution = window.innerWidth <= 870;
    if (!isMobileResolution) {
      const dialog = this.dialog.open(UnableToMessageModalComponent, {
        width: '500px',
      });
      dialog.afterClosed().subscribe(async () => {
        await this.handleCantMessageModalDismiss();
      });
    } else {
      this.modalCtrl
        .create({
          component: UnableToMessageModalComponent,
          mode: 'ios',
          backdropDismiss: true,
          showBackdrop: true,
          breakpoints: [0, 0.58, 0.8, 1],
          initialBreakpoint: 0.8,
          presentingElement: await this.modalCtrl.getTop(),
          canDismiss: true
         })
        .then((modal) => {
          modal.present();
          modal.onDidDismiss().then(async () => {
            await this.handleCantMessageModalDismiss();
          });
        });
      }
    }

  async handleCantMessageModalDismiss(): Promise<void> {
    const selectedSomeAction = await this.storage.retrieve('unable-to-message:selected-some-action');
    if (selectedSomeAction) {
      this.form?.controls?.message?.reset();
    }
  }

  goToJobActivityPage(activity) {
    const params = {
      messages: activity.messages,
      pro: activity.pro,
      categoryImage: this.bookingDetail.service_type_details.service_category.icon_url,
      address: this.bookingDetail.address,
      clientName: this.clientName
    }
    const isPastJob = false;
    const url = `job-activity/${this.bookingDetail.address_id}/${this.jobId}/${activity.pro.id}/${isPastJob}/${this.bookingDetail.current_job.is_private}`;
    const component = JobActivityPage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  markNoShow(pro) {
    let params;
    const now = new Date();
    const twentyMinAfterStartTime = new Date(this.bookingDetail.current_job.start_time);
    twentyMinAfterStartTime.setMinutes(twentyMinAfterStartTime.getMinutes() + 20);
    const isTooEarly = now < twentyMinAfterStartTime;
    if (isTooEarly) {
      return this.showTooEarlyForNoShow();
    } else {
      params = {
        proName: pro.first_name,
        hkId: pro.id,
        jobId: this.jobId
      }
      const url = 'pro-did-not-show-up';
      const component = ProDidNotShowUpPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    }
  }

  async showTooEarlyForNoShow() {
    const alertButtons = [
      {
        text: 'Ok',
        role: 'cancel',
      }
    ]
    const alert = await this.util.showAlert(
      'Please Wait to Mark No Show',
      'Pros typically ask for a 30 minute buffer for unexpected delays. Please wait until 20 minutes after start time before reporting them as a no show.',
      false,
      alertButtons
    );
  }

  showMarkNoShowText(pro) {
    const card = this.cardDetail;
    const scenario_name = card?.template_data?.scenario_name;
    const isPro = pro?.text == pro?.first_name;

    return (
      isPro &&
      !this.bookingDetail.current_job.is_private &&
      !card.unassigned &&
      scenario_name !== 'hk_reported_client_cancellation' &&
      scenario_name !== 'customer_reported_hk_no_showed' &&
      scenario_name !== 'concierge_reported_hk_no_showed'
    )
  }

  callPro(pro) {
    if (!pro?.show_contact_buttons && !this.bookingDetail.current_job.is_private) {
      return this.showCantCallProAlert();
    } else {
      const params = {
        pro,
        isPrivate: this.bookingDetail.current_job.is_private,
        clientPhone: this.settings.profile.phone
      }
      const url = 'call-pro';
      const component = CallProPage;
      this.rightSidePanelService.navigateTo(url, params, component);
    }
  }

  async showCantCallProAlert() {
    const alertButtons = [
      {
        text: 'Ok',
        role: 'cancel',
      }
    ]
    const alert = await this.util.showAlert(
      'Unable to Call',
      'You can call / message your Pro once it is within 30 minutes of the job.',
      false,
      alertButtons
    );
  }

  async sendAttachment(pro) {
    const loading = await this.util.showLoading();
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        loading.dismiss();
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      loading.dismiss();
      await this.sendMessage(pro, [attachment]);
    } catch (err) {
      loading.dismiss();
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public reply',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  goToBookingNote() {
    const params = {
      bookingNote: this.bookingNote,
      bookingId: this.bookingDetail.id,
      backPage: `job/${this.bookingDetail.address_id}/${this.jobId}`,
      params: {
        card: this.cardDetail,
        addressName: this.addressName,
        settings: this.settings,
        clientName: this.clientName
      }
    }
    const url = 'booking-note';
    const component = BookingNotePage;
    this.rightSidePanelService.navigateTo(url, params, component);
  }

  getBeforeAfterState(state) {
    if (state == 'photo') {
      return {
        before: '(Before Photo Requested)',
        after: '(After Photo Requested)'
      }
    } else if (state == 'before_photo_only') {
      return {
        before: '(Before Photo Requested)',
        after: '(None Requested)'
      }
    } else if (state == 'after_photo_only') {
      return {
        before: '(None Requested)',
        after: '(After Photo Requested)'
      }
    } else if (state == 'video') {
      return {
        before: '(Before Video Requested)',
        after: '(After Video Requested)'
      }
    } else if (state == 'before_video_only') {
      return {
        before: '(Before Video Requested)',
        after: '(None Requested)'
      }
    } else if (state == 'after_video_only') {
      return {
        before: '(None Requested)',
        after: '(After Video Requested)'
      }
    } else {
      return {
        before: '(None Requested)',
        after: '(None Requested)'
      }
    }
  }

  ionViewDidLeave() {
    this.mapInstance?.remove();
  }

}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Damage Claim'"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <tidy-row>
      <tidy-text
        [header]="'How Damage Claims Work'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'If you believe your Pro made a mistake and caused damage to your home, use this form to request that your Pro cover the damage.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>In order for claims to be considered, damage must be reported within 72 hours of the appointment start time, a claim must be filed within 14 days of the appointment, and you must be up to date with any payments at the time the claim is processed. Any claims that do not meet these criteria will not be considered.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>All claims will be resolved based on the merits of the claims investigation.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>Proof of damage and original value of the damaged item(s) is required.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>Claims will not be considered without detailed documentation.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'TIDY does not guarantee replacement or reimbursement for the original cost of an item, and will determine the fair market value. If fair market value cannot be determined, TIDY can reimburse 25% of the cost of a similar replacement item. TIDY shall be the sole determiner of fair market value, or the cost of a similar replacement item. For any repair, TIDY shall reimburse for low cost provider for an average version of the repaired object. TIDY shall be the sole determiner of the eligibility of claims.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'All approved claims will be reimbursed with credit towards future Cleanings. If your claim is approved, any previous credits or refunds for that cleaning shall apply towards the reimbursement credit.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [actionBody]="'tidy.com/terms'"
        (action)="goToTerms()"
        class="link">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [header]="'Common Reasons a Claim is Rejected'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'We strive to be completely clear about the claims process. These are the most common reasons a claim is rejected:'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage was not due to a clear error on the Pro\'s part.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage could reasonably be expected to occur in a home cleaning, not due to a mistake.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage was pre-existing, or damage was caused by a pre-existing condition.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage is caused by faulty appliances or items in your home.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage is determined to be standard wear and tear.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>You completed the repairs before an assessment of the damage could be made.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The claim was not made within 72 hours of the appointment time.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage was not made as a direct result of the cleaning or did not occur during the cleaning.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>The damage was caused by cleaning products that you requested the pro to use, or caused by actions taken at your instruction.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [header]="'Additional Terms'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'YOU ARE NOT AN INSURED OR OTHER THIRD PARTY BENEFICIARY UNDER THE TIDY INSURANCE POLICY. TO THE EXTENT YOU DESIRE ADDITIONAL PROTECTION BEYOND THE COVERAGE AFFORDED BY THE TIDY CLIENT GUARANTEE, TIDY STRONGLY ENCOURAGES YOU TO PURCHASE SEPARATE INSURANCE THAT WILL COVER YOU AND YOUR PROPERTY FOR LOSSES CAUSED BY PROS.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'You acknowledge and agree that:'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>These terms are not intended to constitute an offer to insure, do not constitute insurance or an insurance contract, and do not take the place of insurance obtained or obtainable by you.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>You agree to release, defend, indemnify, and hold TIDY and its affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with this agreement.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'<li>You may not assign or transfer these terms or rights, by operation of law or otherwise, without TIDY’s prior written consent.</li>'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'IF YOU CHOOSE TO USE THE SITE, APPLICATION, OR SERVICES AS A CLIENT, YOU DO SO AT YOUR SOLE RISK. THE TIDY SERVICE AND APPLICATION IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, APPLICATION AND SERVICES, REMAINS WITH YOU.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'IN NO WAY DOES OUR POLICY DOES COVER THEFT.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'THIS ONLY COVERS FUNCTIONAL REPLACEMENTS. ANY OBJECT THAT IS EXPENSIVE OR PRICELESS BEYOND ITS FUNCTIONAL USE, WE RECOMMEND GETTING SEPARATE INSURANCE TO COVER THAT OBJECT(S). NEITHER PROS NOR TIDY ARE ASSUMING EXCESS LIABILITY DUE TO THE FACT THAT YOUR GOODS ARE EXPENSIVE. UNDER NO CIRCUMSTANCE SHALL TIDY NOR PRO COVER DAMAGE TO ANY SINGLE MOVABLE OBJECT IN EXCESS OF $1,000. TIDY DOES NOT UNDERWRITE HOMES, AND AS SUCH WILL ONLY REIMBURSE OR REPLACE FOR AN AVERAGE VERSION OF THE DAMAGED ITEM. BY BOOKING A CLEANING FOR A HOUSE WITH EXPENSIVE GOODS, YOU EXPRESSLY AGREE TO ASSUME THE EXCESS RISK FROM SUCH EXPENSIVE GOODS. FOR EXAMPLE, IF YOU HAVE A PIECE OF RARE OR EXPENSIVE PROPERTY THAT IS DAMAGED IN THE COURSE OF A JOB, THIS WILL NOT LIKELY COVER THE VALUE OF THE PROPERTY.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'These TIDY Client Guarantee Terms will be interpreted in accordance with the laws of the State of California and the United States of America, without regard to its conflict-of-law provisions.'">
      </tidy-text>
    </tidy-row>

    <tidy-row>
      <tidy-text
        [body]="'By completing this form, you acknowledge that you have read and accept the policies outlined by TIDY regarding damage claims.'">
      </tidy-text>
    </tidy-row>

    <tidy-card>
      <form [formGroup]="form" novalidate action="#">
        <tidy-row class="extra-bottom-padding">
          <tidy-text
            [header]="'Damage Detail'">
          </tidy-text>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'What did your Pro damage?'"
            [icon]="'assets/svg/information-circle-outline.svg'"
            formControlName="detail"
            [form]="form.controls.detail"
            [submitted]="submitted"
            [errorMessage]="'Please describe what was damaged.'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'How did your Pro damage it?'"
            [icon]="'assets/svg/help-circle-outline.svg'"
            formControlName="how"
            [form]="form.controls.how"
            [submitted]="submitted"
            [errorMessage]="'Please describe how it was damaged.'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-top-padding field-bottom-padding">
          <tidy-input
            [label]="'Link to replacement item or estimate'"
            [icon]="'assets/svg/globe-outline.svg'"
            formControlName="link"
            [form]="form.controls.link"
            [submitted]="submitted"
            [errorMessage]="'Please enter a link or estimate.'">
          </tidy-input>
        </tidy-row>
        <tidy-row class="field-top-padding">
          <tidy-input
            [label]="'How much are you seeking from the Pro?'"
            [icon]="'assets/svg/cash-outline.svg'"
            formControlName="seeking"
            [form]="form.controls.seeking"
            [submitted]="submitted"
            [errorMessage]="'Please enter how much you are seeking.'">
          </tidy-input>
        </tidy-row>
      </form>
      <!--TODO <tidy-row [align]="'center'" class="extra-top-padding">
        <tidy-text
          [actionBody]="'Add Attachment'"
          (action)="addAttachment()"
          class="link">
        </tidy-text>
      </tidy-row>
      <tidy-row *ngIf="attachment">
        <tidy-image
          class="hero-size"
          [src]="attachment">
        </tidy-image>
      </tidy-row>-->
    </tidy-card>

    <tidy-alert>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [header]="'Next Steps'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'We contact the Pro.'">
        </tidy-text>
      </tidy-row>
      <tidy-row class="extra-bottom-padding">
        <tidy-text
          [body]="'We reach out to the Pro on your behalf, and collect more information. Since the damage claim can be a substantial amount of money for the Pro, it is our responsibility to ensure that claims are responsibly considered.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'Your claim is closed.'">
        </tidy-text>
      </tidy-row>
      <tidy-row>
        <tidy-text
          [body]="'If your claim is approved, we will offer TIDY credit while we collect from the Pro on your behalf.'">
        </tidy-text>
      </tidy-row>
    </tidy-alert>

    <tidy-error-message
      *ngIf="errorMessage"
      [text]="errorMessage">
    </tidy-error-message>

    <tidy-button
      [action]="submit.bind(this)"
      [text]="'Submit & Agree To Terms'"
      class="primary">
    </tidy-button>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';

import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Loading } from 'src/shared/components/loading/loading';

import { Genome } from 'src/providers/genome/genome';

@Component({
  templateUrl: 'item-maintenance.html'
})

export class ItemMaintenancePage implements OnInit {

  loaded: boolean;
  errorMessage: string;
  addressId: number;
  maintenance: any;
  maintenanceRef: string;
  stepchecked: boolean;

  constructor(
    private navCtrl: CustomNavController,
    private genome: Genome
  ) { }

  @Loading('', true)
  ngOnInit() {
    this.loaded = false;
    this.maintenance = this.navCtrl.getParam('maintenance', true);
    this.loaded = true;
  }

  async submitStatus(status: String) {
    const data = {
      maintenance_id: this.maintenance.maintenance.id,
      room_object_id: this.maintenance.room_object.id,
      status,
      maintained_at: new Date()
    }
    try {
      await this.genome.updateMaintenanceStatus(data);
      this.navCtrl.back();
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  goToItemDetails() {
    const params = {
      item: this.maintenance.room_object,
      addressId: this.navCtrl.getParam('addressId', true)
    };
    this.navCtrl.navigateForward(`item-details`, params);
  }
}

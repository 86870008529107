import {
  Component,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewChecked,
  forwardRef,
  AfterViewInit
} from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { IMaskDirective  } from 'angular-imask';
import {
  checkFocused,
  checkFocusedout,
  buildIntlTelInput,
  configureIntlTelInputListener,
  toggleFlagContainerVisibility,
  shouldValidate,
  updateMask,
  setInputValue,
  getCountryCodeValue
} from './international-number.utils';

import { ControlValueAccessor } from '@angular/forms';
@Component({
  selector: 'tidy-input-international-phone',
  templateUrl: 'input-international-phone.component.html',
  styleUrls: ['./input-international-phone.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputInternationalPhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputInternationalPhoneComponent),
      multi: true
    }
  ]
})
export class InputInternationalPhoneComponent implements ControlValueAccessor, AfterViewChecked, AfterViewInit, Validator {
  @ViewChild('phoneInput', {static: false, read: IMaskDirective}) maskDirective: IMaskDirective<any>;
  @ViewChild('phoneInput', {static: false}) phoneInputRef: ElementRef;

  @Input() public label: string;
  @Input() public icon: string;
  @Input() public activeIcon: string;
  @Input() public form: UntypedFormControl;
  @Input() public submitted: boolean;
  @Input() public errorMessage: string;
  @Input() public type: string;
  @Input() public noBorder: boolean;
  @Input() public inputMode: string;
  @Input() autoComplete = false;

  focused = false;
  touchedState = false;
  phoneValue: string;
  onChange: (value: string) => void;
  disableElement = false;

  shouldntUpdateCountryInput = false;
  flagsContainer: HTMLElement;
  validateInternationFn = () => { return true };
  initialMask = { mask: '[00000000000000000000000]' };
  @Output() countryCodeChange: EventEmitter<string> = new EventEmitter<string>();
  intlTel: Readonly<any>;
  @Input() defaultCountryCode = '';
  setOnCompleteEvent = false;
  shouldUpdateMask = false;
  writeValueChanged = false;

  ngAfterViewInit() {
    this.initIntLTel();
  }

  initIntLTel() {
    const maskRef = this.maskDirective.maskRef;
    const input = this.phoneInputRef.nativeElement;
    this.intlTel = buildIntlTelInput(input, this.defaultCountryCode);
    configureIntlTelInputListener(input, this.intlTel, this.countryCodeChange, maskRef);
    this.flagsContainer = this.intlTel.flagsContainer;
    this.validateInternationFn = shouldValidate.bind(null, this.intlTel);
    toggleFlagContainerVisibility('none', this.flagsContainer);
    this.countryCodeChange.emit(getCountryCodeValue(this.intlTel));
  }

  ngAfterViewChecked() {
    this.configureCountrySelection();
  }

  configureCountrySelection() {
    const maskRef = this.maskDirective.maskRef;

    if (!this.shouldntUpdateCountryInput && maskRef.unmaskedValue && this.writeValueChanged) {
      toggleFlagContainerVisibility('block', this.flagsContainer);
      setInputValue(this.intlTel, `+${maskRef.unmaskedValue}`);

      this.shouldntUpdateCountryInput = true;
    }

    if (!this.setOnCompleteEvent && maskRef.unmaskedValue) {
      maskRef.on('complete', this.handleMaskCompleteEvent.bind(this));

      this.setOnCompleteEvent = true;
    }

    if (!this.shouldUpdateMask && getCountryCodeValue(this.intlTel)) {
      updateMask(this.intlTel, maskRef);

      this.shouldUpdateMask = true;
    }
  }

  validate(_: UntypedFormControl) {
    const isInValid = this.intlTel && this.intlTel?.getNumber() && shouldValidate(this.intlTel);
    const invalidErrorObject = {
      phone: { valid: false }
    }
    const error = isInValid ? invalidErrorObject : null;

    return error;
  }

  checkFocused() {
    checkFocused(this.flagsContainer);
  }

  checkFocusedout(e) {
    const isEmpty = e.target.value === '';
    
    checkFocusedout(e, this.flagsContainer, isEmpty);
  }

  changeTouch = () => {
    this.touchedState = true;
  }

  setFocused(status) {
    this.focused = status;
  }

  ngOnDestroy() {
    this.intlTel?.destroy();
  }

  writeValue(value: string) {
    if (this.intlTel) {
      setInputValue(this.intlTel, value);
    }

    this.writeValueChanged = !!value?.includes('+');

    this.phoneValue = value;
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  handleMaskCompleteEvent() {
    this.onChange(this.intlTel?.getNumber());
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.disableElement = isDisabled;
  }
}

<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="'Vendor Compliance'"
    [canGoBack]="!windowService.isDesktopRes">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper [class]="isRightSideContent ? 'right-side-scroll-enabled' : ''">
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>
    <ng-container *ngIf="loaded">

      <tidy-card style="padding: 15px 15px 15px 10px">
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/img/compliant.svg'"
                    style="width:40px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'Add your coverage requirements'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'Certs/COIs/ACORD 25s. We also help you manage other vendor requirements (W9s, Attestations, and other contracts).'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/img/automatic.svg'"
                    style="width:40px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'We automatically check coverage'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'We automatically notify vendors of your requirements, collect proof, and alert you of any issues.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
        <tidy-row class="extra-top-padding extra-bottom-padding">
          <tidy-divider></tidy-divider>
        </tidy-row>
        <tidy-grid>
          <tidy-row>
            <ion-row>
              <ion-col size="2" class="vertical-align-center">
                <div style="margin: auto">
                  <tidy-image
                    [src]="'assets/img/free-car.svg'"
                    style="width:40px">
                  </tidy-image>
                </div>
              </ion-col>
              <ion-col>
                <tidy-row>
                  <tidy-text
                    [header]="'It\’s free'">
                  </tidy-text>
                </tidy-row>
                <tidy-row>
                  <tidy-text
                    [body]="'It is just one of the ways we help keep your property clean and maintained.'">
                  </tidy-text>
                </tidy-row>
              </ion-col>
            </ion-row>
          </tidy-row>
        </tidy-grid>
      </tidy-card>

      <tidy-row [align]="'center'">
        <tidy-text
          [header]="'Request to Get Access'">
        </tidy-text>
      </tidy-row>

      <tidy-card>
        <form [formGroup]="form" novalidate action="#">
          <tidy-row>
            <tidy-text
              [body]="'What type of requirements do you want to implement?'">
            </tidy-text>
          </tidy-row>
          <tidy-row class="extra-bottom-padding">
            <div *ngFor="let requirement of requirementItems">
              <tidy-checkbox
                [(checked)]="requirement.checked"
                [items]="[requirement]">
              </tidy-checkbox>
            </div>
            <tidy-error-message
              *ngIf="requirementError"
              [text]="'Please select an option'">
            </tidy-error-message>
          </tidy-row>
          <tidy-row>
           <tidy-textarea
             formControlName="note"
             [form]="form.controls.note"
             [label]="'Any questions about this feature?'"
             [form]="form"
             [icon]="'assets/svg/help-circle-outline.svg'">
           </tidy-textarea>
         </tidy-row>
       </form>
      </tidy-card>

      <tidy-button
        [text]="'Request Access'"
        [action]="requestAccess.bind(this)"
        class="primary">
      </tidy-button>

      <tidy-error-message
        *ngIf="errorMessage"
        [text]="errorMessage">
      </tidy-error-message>

    </ng-container>
  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

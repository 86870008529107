<ng-template
  let-extraClass="extraClass"
  #topNav>
  <tidy-top-nav
    *ngIf="!isRightSideContent"
    [class]="extraClass"
    [title]="loaded ? item.model.category.name : ''"
    [canGoBack]="true">
  </tidy-top-nav>
</ng-template>

<ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'mobile' }"></ng-container>

<ng-template #content>
  <tidy-wrapper>
    <ng-container *ngTemplateOutlet="topNav;context:{ extraClass: 'desktop' }"></ng-container>

    <ng-container *ngIf="categoryChosen">
      <ng-container *ngIf="loaded">
        <tidy-card>
          <form [formGroup]="form">
            <tidy-row *ngIf="!item?.consumable">
              <tidy-auto-complete
                [label]="item?.consumable ? 'Name' : 'Name or Model #'"
                formControlName="model"
                [options]="models"
                [form]="form.controls.model"
                (action)="selectModel($event)">
              </tidy-auto-complete>
            </tidy-row>
            <tidy-row *ngIf="item?.consumable">
              <tidy-input
                [label]="'Name'"
                formControlName="model"
                [form]="form.controls.model"
                [submitted]="submitted">
              </tidy-input>
            </tidy-row>
            <ng-container *ngIf="isAddingNewModel">
              <ng-container *ngFor="let characteristic of characteristics; let index = index">
                <tidy-row>
                  <tidy-select
                    [label]="'Select ' + characteristic.name"
                    [form]="form.controls['characteristic_' + index]"
                    formControlName="{{ 'characteristic_' + index }}"
                    (optionChange)="selectCharacteristic(characteristic, $event)"
                    [items]="characteristic.formData">
                  </tidy-select>
                </tidy-row>
              </ng-container>
            </ng-container>
            <form [formGroup]="modelForm">
              <ng-container *ngIf="createNewModel">
                <tidy-row class="extra-bottom-padding">
                  <tidy-input
                    [label]="'Model Number'"
                    formControlName="newModel"
                    [form]="modelForm.controls.newModel"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter the number of the new model'">
                  </tidy-input>
                </tidy-row>
              </ng-container>
            </form>
            <tidy-row *ngIf="item?.consumable">
              <tidy-checkbox
                [items]="[{viewValue: 'Track Inventory', value: true}]"
                [initValue]="getInventoryAlerts"
                (checkedChange)="selectGetInventoryAlerts()">
              </tidy-checkbox>
            </tidy-row>
            <tidy-row class="extra-bottom-padding" *ngIf="item?.consumable">
              <tidy-text
                [body]="'Get alerted when the inventory for this item goes below your minimum quantity.'">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="getInventoryAlerts">
              <ng-container *ngIf="!isRightSideContent">
                <div class="two-column-form-row padding-bottom-5">
                  <tidy-input
                    [label]="'Min Quantity'"
                    formControlName="minQuantity"
                    [form]="form.controls.minQuantity"
                    inputMode="numeric"
                    [mask]="'number'"
                    [submitted]="submitted">
                  </tidy-input>
                  <tidy-input
                    [label]="'Max Quantity'"
                    formControlName="maxQuantity"
                    [form]="form.controls.maxQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                </div>
                <tidy-row *ngIf="!form.controls.minQuantity.valid && submitted">
                  <tidy-error-message
                    [text]="'Please enter the minimum quantity'">
                  </tidy-error-message>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Current Quantity'"
                    formControlName="currentQuantity"
                    [form]="form.controls.currentQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Min Restocking Price'"
                    formControlName="minRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.minRestockingPrice">
                  </tidy-input>
                </tidy-row>
                <tidy-row>
                  <tidy-input
                    [label]="'Max Restocking Price'"
                    formControlName="maxRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.maxRestockingPrice">
                  </tidy-input>
                </tidy-row>
              </ng-container>
              <ng-container *ngIf="isRightSideContent">
                <div class="three-column-form-row padding-bottom-5">
                  <tidy-input
                    [label]="'Min Quantity'"
                    formControlName="minQuantity"
                    [form]="form.controls.minQuantity"
                    inputMode="numeric"
                    [mask]="'number'"
                    [submitted]="submitted"
                    [errorMessage]="'Please enter the minimum quantity'">
                  </tidy-input>
                  <tidy-input
                    [label]="'Max Quantity'"
                    formControlName="maxQuantity"
                    [form]="form.controls.maxQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                  <tidy-input
                    [label]="'Current Quantity'"
                    formControlName="currentQuantity"
                    [form]="form.controls.currentQuantity"
                    inputMode="numeric"
                    [mask]="'number'">
                  </tidy-input>
                </div>
                <div class="two-column-form-row padding-bottom-5">
                  <tidy-input
                    [label]="'Min Restocking Price'"
                    formControlName="minRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.minRestockingPrice">
                  </tidy-input>
                  <tidy-input
                    [label]="'Max Restocking Price'"
                    formControlName="maxRestockingPrice"
                    inputMode="numeric"
                    [mask]="'currencyMask'"
                    [form]="form.controls.maxRestockingPrice">
                  </tidy-input>
                </div>
              </ng-container>
              <tidy-row>
                <tidy-input
                  [label]="'Opportunistic Order Level'"
                  formControlName="opportunisticOrderLevel"
                  [form]="form.controls.opportunisticOrderLevel"
                  inputMode="numeric"
                  [mask]="'number'">
                </tidy-input>
              </tidy-row>
              <tidy-row>
                <tidy-input
                  [label]="'Where to Order'"
                  formControlName="whereToOrder"
                  [form]="form.controls.whereToOrder">
                </tidy-input>
              </tidy-row>
              <tidy-row>
                <tidy-select
                  [label]="'Delivery Timing Preferences'"
                  [form]="form.controls.deliveryTimingPreferences"
                  formControlName="deliveryTimingPreferences"
                  [items]="deliveryTimingPreferenceItems">
                </tidy-select>
              </tidy-row>
              <tidy-row>
                <tidy-input
                  [label]="'Restocking Notes'"
                  formControlName="restockingNotes"
                  [form]="form.controls.restockingNotes">
                </tidy-input>
              </tidy-row>
            </ng-container>
            <tidy-row [align]="'center'" *ngIf="!item.photo_url">
              <tidy-text
                class="link"
                [body]="'Add Photo'"
                (action)="addImage()">
              </tidy-text>
            </tidy-row>
            <ng-container *ngIf="item.photo_url">
              <tidy-row class="extra-top-padding">
                <img [src]="item.photo_url">
              </tidy-row>
              <tidy-row [align]="'center'">
                <tidy-text
                  class="link red"
                  [body]="'Remove Photo'"
                  (action)="removeImage()">
                </tidy-text>
              </tidy-row>
            </ng-container>
          </form>
        </tidy-card>

        <tidy-error-message
          *ngIf="errorMessage"
          [text]="errorMessage">
        </tidy-error-message>

        <tidy-button
          [text]="'Update Item'"
          [action]="updateItem.bind(this)"
          class="primary">
        </tidy-button>
      </ng-container>
    </ng-container>

  </tidy-wrapper>
</ng-template>

<ng-container *ngIf="!isRightSideContent">
  <app-scroll-wrapper [templateContent]="content"></app-scroll-wrapper>
</ng-container>

<ng-container *ngIf="isRightSideContent">
  <ion-content class="ion-content-right-column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
</ng-container>

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Communication } from 'src/providers/communication/communication';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Genome } from 'src/providers/genome/genome';
import { Me } from 'src/providers/me/me';
import { Schedule } from 'src/providers/schedule/schedule';
import { Loading } from 'src/shared/components/loading/loading';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { JobPage } from '../job/job';
import { PastPrivateJobPage } from 'src/pages/more/past-private-job/past-private-job';
import { PastJobPage } from 'src/pages/more/past-job/past-job';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { JobCard } from 'src/models/schedule.model';

@Component({
  templateUrl: 'job-activity.html'
})

export class JobActivityPage implements OnInit {

  activity: any;
  address: any;
  addressId: any;
  bookingId: any;
  jobId: any;
  categoryImage: string;
  clientName: string;
  errorMessage: string;
  form: UntypedFormGroup;
  homekeeperJobId: any;
  isInternalComment: boolean;
  isAllowedToCall: boolean;
  isPrivateJob: boolean;
  isPastJob: boolean;
  messageSelectItems: any;
  messages: any;
  phoneNumber: any;
  pro: any;
  proName: string;
  submitted: boolean;
  serviceName: any;
  selectedMessageType: any;
  startTime: any;
  startDate: any;
  dialogParams: any;
  isRightSideContent = true;
  loaded: boolean;

  constructor(
    public communication: Communication,
    private fb: UntypedFormBuilder,
    private genome: Genome,
    private me: Me,
    private navCtrl: CustomNavController,
    private route: ActivatedRoute,
    private schedule: Schedule,
    private storage: TidyStorage,
    private rightSidePanelService: RightSidePanelService,
  ) {
    this.form = this.fb.group({
      message: ['', Validators.required],
      messageType: ['public_reply']
    });
  }

  async ngOnInit() {
    try {
      this.isRightSideContent = (await this.storage.retrieve('dialog-right-side-open')) || false;
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
        this.rightSidePanelService.setDialogPageTitle('Messages & Updates');
        const [card, messages] = await Promise.all([
          this.schedule.getJobDetail(this.dialogParams.jobId),
          this.getMessages(this.dialogParams.pro),
        ]);
        const params = {
          ...this.dialogParams,
          messages,
          job: card,
          categoryImage: card.booking.bookable_service.icon_url,
          jobId: card.job.id,
          address: card.address,
          addressId: card.address.id,
        };
        this.dialogParams = params;
        this.storage.save('dialog-params', params);
        this.loadData();
        this.isPrivateJob = card.is_private;
      } else {
        this.loadData();
      }
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async loadData() {
    try {
      this.loaded = false;
      this.getRouteParams();
      this.address = this.navCtrl.getParam('address') || this.dialogParams?.address || await this.genome.getAddress(this.addressId);
      if (this.isPastJob) {
        await this.getPastJobVariables()
      } else {
        await this.getScheduledJobVariables();
      }
      this.clientName = this.navCtrl.getParam('clientName') || await this.getClientName();
      await this.markMessagesAsRead();
      this.messageSelectItems = this.getMessageSelectItems();
      this.form.patchValue({messageType: 'public_reply'});
      this.selectedMessageType = this.messageSelectItems[1];
      this.loaded = true;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  async getMessages(pro: any): Promise<any> {
    const chatRoomKey = await this.storage.retrieve('chatRoomKey');
    let messages = [];
    if (!chatRoomKey || chatRoomKey === null) {
      if (!pro?.main_team_id) {
        return this.dialogParams?.messages || [];
      }
      messages = await this.communication.getSharedInbox(pro?.main_team_id);
    } else {
      messages = await this.communication.getSharedInboxWithChatRoomKey(chatRoomKey);
    }
    if (!messages) {
      return [];
    }
    return messages.reverse();
  }

  getMessageSelectItems() {
    return [
      {
        value: 'is_internal',
        viewValue: 'Internal note',
        icon: 'assets/svg/create-outline.svg'
      },
      {
        value: 'public_reply',
        viewValue: 'Public reply',
        icon: 'assets/svg/arrow-redo-outline.svg'
      }
    ]
  }

  updateIsInternalComment(selection) {
    this.isInternalComment = selection == 'is_internal';
    this.selectedMessageType = this.isInternalComment ? this.messageSelectItems[0] : this.messageSelectItems[1];
  }

  getRouteParams() {
    this.addressId = this.route.snapshot.paramMap.get('addressId') || this.dialogParams?.addressId;
    this.isPastJob = this.route.snapshot.paramMap.get('isPastJob') == 'true' || this.dialogParams?.isPastJob;
    this.isPrivateJob = this.route.snapshot.paramMap.get('isPrivateJob') == 'true' || this.dialogParams?.isPrivateJob;
    this.jobId = this.route.snapshot.paramMap.get('jobId') || this.dialogParams?.jobId;
  }

  async getPastJobVariables() {
    const job = this.navCtrl.getParam('job') || this.dialogParams?.job || await this.schedule.getJobDetail(this.jobId);
    this.bookingId = job?.booking?.id;
    this.startDate = job.job.date;
    this.startTime = job.job.start_time;
    this.serviceName = job.booking.bookable_service.name;
    this.isAllowedToCall = this.isPrivateJob;
    const pro = this.navCtrl.getParam('pro') || this.dialogParams?.pro || await this.getPastPro(job);
    this.pro = pro;
    this.proName = pro.first_name;
    this.homekeeperJobId = this.getPastHomekeeperJobId(job, pro);
    this.messages = await this.buildActivityArray(this.homekeeperJobId, job.activity_timelines);
    this.categoryImage = this.navCtrl.getParam('categoryImage') || job.booking.bookable_service.icon_url;
    if (this.isPrivateJob) {
      this.phoneNumber = pro.phone;
    }
  }

  async getScheduledJobVariables() {
    const card = this.dialogParams?.card || (await this.getScheduledJob())?.template_data;
    this.bookingId = card?.booking?.id;
    this.startDate = card?.job?.start_datetime_local;
    this.startTime = card?.job?.start_datetime_local;
    this.serviceName = card?.booking?.bookable_service?.name;
    const pro = this.navCtrl.getParam('pro') || this.dialogParams?.pro || await this.getScheduledPro(card);
    this.pro = pro;
    this.isAllowedToCall = pro.show_contact_buttons || this.isPrivateJob;
    this.proName = pro.first_name;
    const homekeeperJob = card?.homekeeper_jobs?.find(job => job?.user_id == pro?.id);
    this.homekeeperJobId = pro?.homekeeper_job_id || homekeeperJob?.id;
    this.messages = this.dialogParams?.messages || await this.buildActivityArray(this.homekeeperJobId, card.activity_timelines);
    this.categoryImage = this.dialogParams?.categoryImage || card.job.service_type_details.service_category.icon_url;
    if (this.isPrivateJob) {
      this.phoneNumber = pro.phone;
    } else {
      this.phoneNumber = pro.masked_phone_number;
    }
  }

  async markMessagesAsRead() {
    let array: any = [];
    this.messages.map((message) => {
      if (!message?.is_read && message?.from_member?.type !== 'CustomerMember' && message?.type !== 'cleaning_update' && message?.chat_room_key) {
        array.push((message?.chat_room_key));
      }
    });
    if (array.length > 0) {
      await this.communication.markMessagesAsRead(array);
    }
  }

  async getScheduledJob(): Promise<JobCard> {
    return this.schedule.getJobCard(this.addressId, this.jobId);
  }

  getScheduledPro(card) {
    const proId = this.route.snapshot.paramMap.get('proId') || this.dialogParams?.proId;
    return card?.homekeepers?.find(pro => pro.id == proId) || this.dialogParams?.pro
  }

  getPastPro(job) {
    const proId = this.route.snapshot.paramMap.get('proId') || this.dialogParams?.proId;
    return job.homekeepers.find(pro => pro.id == proId);
  }

  getPastHomekeeperJobId(job, pro) {
    let id = 0;
    const homekeeperJob = job.homekeeper_jobs.map(job => {
      if (job.user_id == pro.id) {
        id = job.id;
      }
    });
    return id;
  }

  async buildActivityArray(homekeeperJobId, activityTimeline) {
    const chatRoomKey = `homekeeper_job_id-${homekeeperJobId}`;
    const AISuggestionsPayload = {
      feature: 'chat_suggestion',
      data: {
        chat_room_key: chatRoomKey
      }
    }
    await this.communication.generateAISuggestions(AISuggestionsPayload);
    let activity = [];
    const messages = await this.communication.getSharedInboxWithChatRoomKey(chatRoomKey);
    activity = this.parseActivity(activityTimeline, messages, homekeeperJobId);
    return activity;
  }

  parseActivity(activityTimeline, messages, homekeeperJobId) {
    let activity = [];
    if (activityTimeline?.length === 0 && homekeeperJobId === 0) {
      return this.getFilteredMessagesByHomekeeperId(messages, this.pro?.id);
    }
    activityTimeline.map((timeline) => {
      if (timeline.homekeeper_job_id == homekeeperJobId) {
        const homekeeperJobMessages = homekeeperJobId === 0 ? this.getFilteredMessagesByHomekeeperId(messages, this.pro?.id) : messages.filter((message) => message.metadata?.homekeeper_job_id == homekeeperJobId);
        let updates = timeline.activity_timeline_items.filter((item) => item.type !== 'message');
        activity = this.concatenateMessagesAndUpdates(updates, homekeeperJobMessages);
      }
    });
    return activity;
  }

  getFilteredMessagesByHomekeeperId(messages: any[], homekeeperId: number): any[] {
    return messages.filter((message) => message.metadata?.homekeeper_id === homekeeperId);
  }

  concatenateMessagesAndUpdates(updates, messages) {
    updates.map((update) => {
      update['sent_at'] = update.data.created_at;
    });
    if (updates.length) {
      const activity = updates.concat(messages);
      activity.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return activity;
    } else {
      messages.sort((a, b) => a.sent_at < b.sent_at ? 1 : -1);
      return messages;
    }
  }

  async getClientName() {
    const me = await this.me.load();
    return me.customer_account.name;
  }

  @Loading('', true)
  async sendMessage(attachments = []) {
    this.errorMessage = '';
    this.submitted = true;
    if (attachments.length == 0 && !this.form.get('message').valid) {
      return;
    }
    const payload = {
      chat: {
        type: 'homekeeper_job',
        data: {
          homekeeper_job_id: this.homekeeperJobId
        }
      },
      message: {
        text: this.form.value.message,
        files: attachments,
        is_internal: this.isInternalComment
      }
    };
    try {
      const response = await this.communication.sendSharedInboxMessage(payload);
      if (response?.data?.message == 'We couldn\'t send your message, please try again later!') {
        return this.errorMessage = response.data.message;
      }
      if (this.isPastJob) {
        const job = await this.schedule.getJobDetail(this.jobId);
        this.messages = await this.buildActivityArray(this.homekeeperJobId, job.activity_timelines);
      } else {
        const card = await this.getScheduledJob();
        this.messages = await this.buildActivityArray(this.homekeeperJobId, card?.template_data?.activity_timelines);
      }
      this.form.patchValue({
        message: ''
      });
      this.submitted = false;
    } catch (err) {
      this.errorMessage = err.error ? err.error.message : err.message;
    }
  }

  callPro() {
    if (!this.isAllowedToCall && !this.isPrivateJob) {
      return this.showCantCallProAlert();
    } else {
      window.location.href = `tel:${this.phoneNumber}`;
    }
  }

  showCantCallProAlert() {
    const successParams = {
      header: 'Unable to Call',
      body: 'You can call / message your Pro once it is within 30 minutes of the job.',
      buttonText: 'Ok',
      buttonRoute: `job/${this.addressId}/${this.jobId}`
    };
    this.rightSidePanelService.navigateTo('success', successParams);
  }

  async sendAttachment() {
    this.errorMessage = '';
    try {
      const attachment = await this.schedule.addAttachment();
      if (attachment.location === '') {
        return this.errorMessage = 'Unable to attach photo. Please upload a PNG or JPEG file.';
      }
      await this.sendMessage([attachment.location]);
    } catch (err) {
      this.errorMessage =  err.error ? err.error.message : err.message;
    }
  }

  goToJobPage() {
    if (this.dialogParams) {
      this.navigateToJobPageInsideDialog();
      return;
    }
    localStorage.setItem('pastJobBackPage', this.route.routeConfig.path);
    localStorage.setItem('jobBackPage', this.route.routeConfig.path);
    if (this.isPastJob && this.isPrivateJob) {
      this.navCtrl.navigateForward(`past-private-job/${this.jobId}`);
    } else if (this.isPastJob) {
      this.navCtrl.navigateForward(`past-job/${this.jobId}`);
    } else {
      this.navCtrl.navigateForward(`job/${this.address.id}/${this.jobId}`);
    }
  }

  async navigateToJobPageInsideDialog() {
    let dialogParams = await this.storage.retrieve('dialog-params');
    dialogParams = {
      ...dialogParams,
      addressId: this.address.id,
      jobId: this.jobId,
      bookingId: this.bookingId
    };
    await this.storage.save('dialog-params', dialogParams);
    if (this.isPastJob && this.isPrivateJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastPrivateJobPage);
    } else if (this.isPastJob) {
      this.rightSidePanelService.replaceComponentInDialog(PastJobPage);
    } else {
      this.rightSidePanelService.replaceComponentInDialog(JobPage);
    }
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Concierge } from 'src/providers/concierge/concierge';
import { CustomNavController } from 'src/shared/providers/navigation/custom-nav-controller';
import { Logger } from 'src/providers/logger';

import { Loading } from 'src/shared/components/loading/loading';

import { ConciergeItemModel } from 'src/models/concierge.model';
import { Client } from 'src/providers/client/client';
import { WindowService } from 'src/shared/providers/window.service';

import { TidySelectNumberValueModel } from 'src/models/tidy-select-item.model';
import { TimeoutableComponent } from 'src/shared/components/timeoutable/timeoutable.component';
import { RightSidePanelService } from 'src/shared/providers/providers/right-side-panel';
import { TidyStorage } from 'src/shared/providers/tidy-storage';
import { Subscription } from 'rxjs';
import { ConciergeItemsComponent } from './concierge-items/concierge-items';
import { CustomDatePipe } from 'src/shared/pipes/custom-date.pipe';
import { Util } from 'src/shared/util/util';
import { ConciergeRulesComponent } from './concierge-rules/concierge-rules';
import { ConciergeRuleComponent } from 'src/pages/concierge/concierge-rule/concierge-rule';
import { humanize } from 'src/shared/util/humanize';
import { GetHelpPage } from './get-help/get-help';

@Component({
  templateUrl: 'concierge.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./concierge.scss'],
  selector: 'concierge-page'
})

export class ConciergePage extends TimeoutableComponent implements OnInit {

  addressResponse: any[];
  addressFilter: TidySelectNumberValueModel[];
  errorMessage: string;
  filteredItems: ConciergeItemModel[];
  contentHeight: number;
  isRental: boolean;
  safeURL: any;
  genomeItems: any[];
  conciergeActions: ConciergeItemModel[];
  conciergeActionTableItems: any[];
  supportRequests: ConciergeItemModel[];
  supportRequestTableItems: any[];
  rulesLoaded: boolean;
  rules: any[];
  rulesTableItems: any[];
  suggestions: any[];
  suggestionsTableItems: any[];
  suggestionsLoaded: boolean;
  actionsLoaded: boolean;
  supportRequestsLoaded: boolean;
  panelClosedSubscription: Subscription;
  selectedCategory: string;
  headers = [
    'Subject',
    'Status',
    'Last Updated',
  ];
  isRightSideContent = true;
  dialogParams: any;
  totalConciergeActions: string;
  totalSupportRequests: string;

  constructor(
    public concierge: Concierge,
    private logger: Logger,
    private navCtrl: CustomNavController,
    private client: Client,
    public windowService: WindowService,
    private rightSidePanelService: RightSidePanelService,
    private storage: TidyStorage,
    private util: Util
  ) {
    super();
    this.panelClosedSubscription = this.rightSidePanelService.afterPanelClosed().subscribe(() => {
      this.loadPageData();
    });
  }

  @Loading('', true)
  async ngOnInit() {
    try {
      this.isRightSideContent = await this.storage.retrieve('dialog-right-side-open') || false;
      this.rightSidePanelService.setDialogPageTitle('Concierge');
      if (this.isRightSideContent) {
        this.dialogParams = await this.storage.retrieve('dialog-params');
      }
      this.loaded = false;
      this.errorMessage = '';
      this.isRental = (await this.client.getClientInfo()).customer_account.account_type == 'rental';
      try {
        await this.loadPageData();
      } catch (err) {
        this.handleError(err);
      }
      this.addressResponse = await this.client.getAddresses();
      this.addressFilter = this.client.parseAddressList(this.addressResponse, false);
      this.addressFilter.unshift({
        viewValue: 'All Properties',
        value: 0
      });
      this.loaded = true;
    } catch (err) {
      this.timeoutHandler(err);
    }
  }

  async loadPageData() {
    this.rulesLoaded = false;
    this.actionsLoaded = false;
    this.supportRequestsLoaded = false;
    this.suggestionsLoaded = false;
    try {
      await Promise.all([
        this.getItems(),
        this.getRules(),
        this.getSuggestions()
      ]);
    } catch (error) {
      this.handleError(error);
    }
  }

  @Loading('', true)
  async goToConciergeMessage(conciergeItem) {
    try {
      await this.concierge.setClientInfo();
      const params = {
        item: conciergeItem
      }
      this.navCtrl.navigateForward(`concierge-message/${conciergeItem.id}`, params);
    } catch (err) {
      this.handleError(err);
    }
  }

  @Loading('', true)
  async getItems() {
    const conciergeAddressStatus = localStorage.getItem('isAllAddressesSelected');
    const addressId = conciergeAddressStatus === 'true' ? 0 : localStorage.getItem('addressId');
    const addressIdFilter = addressId === 0 ? '' : `&address_id=${addressId}`;
    const pageIndex = 1;
    const pageSize = 5;
    const isPending = false;
    const conciergeActionsResponse = await this.concierge.getConciergeItems(`&concierge_item_type_key=concierge_task${addressIdFilter}&page=${pageIndex}&per_page=${pageSize}&is_marked_done=${isPending}`);
    const supportRequestsResponse = await this.concierge.getConciergeItems(`&not_concierge_item_type_key=concierge_task${addressIdFilter}&page=${pageIndex}&per_page=${pageSize}&is_marked_done=${isPending}`);
    this.conciergeActions = conciergeActionsResponse.body;
    this.supportRequests = supportRequestsResponse.body;
    this.totalConciergeActions = conciergeActionsResponse.totalRecords;
    this.totalSupportRequests = supportRequestsResponse.totalRecords;
    this.genomeItems = this.sortGenomeItems();
    this.conciergeActionTableItems = this.buildRows(this.conciergeActions);
    this.actionsLoaded = true;
    this.supportRequestTableItems = this.buildRows(this.supportRequests);
    this.supportRequestsLoaded = true;

  }

  async getRules() {
    try {
      this.rulesTableItems = null;
      this.rules = await this.concierge.getRules();
      this.rulesTableItems = this.buildRulesSuggestions(this.rules, 'Rule');
      this.rulesLoaded = true;
    } catch (err) {
      this.handleError(err);
    }
  }

  async getSuggestions() {
    try {
      this.suggestionsTableItems = null;
      this.suggestions = await this.concierge.getSuggestions();
      this.suggestionsTableItems = this.buildRulesSuggestions(this.suggestions, 'Suggestion');
      this.suggestionsLoaded = true;
    } catch (err) {
      this.handleError(err);
    }
  }

  buildRulesSuggestions(items: any[], header: string) {
    if (items.length > 3) {
      items = items.slice(0, 3);
    }
    return items.map((rule) => {
      return {
        [header]: {
          value: rule.description,
          action: null
        },
      };
    });
  }

  buildRows(items: any[] = []) {
    if (items.length > 3) {
      items = items.slice(0, 3);
    }
    return items.map((item) => {
      if (item.type.includes('dispute')) {
        return {
          'Subject': {
            value: item.header_text,
            action: null
          },
          'Status': {
            value: item.type,
            action: null
          },
          'Last Updated': {
            value: '',
            action: null
          }
        };
      } else {
        return {
          'Subject': {
            value: item.components[0].value.subject,
            action: null
          },
          'Status': {
            value: humanize(item.components[0].value.status),
            action: null
          },
          'Last Updated': {
            value: this.parseDate(item.components[0].value.last_update_at),
            action: null
          }
        };
      }
    });
  }

  parseDate(dateStr) {
    return new CustomDatePipe().transform(dateStr, 'M/D/YY h:mma', '')
  }

  @Loading('', true)
  async getHelp() {
    try {
      await this.concierge.setClientInfo();
      this.rightSidePanelService.navigateTo('get-help', {}, GetHelpPage);
    } catch (err) {
      this.handleError(err);
    }
  }

  sortGenomeItems() {
    let genomeItems = [];
    const items = [...this.conciergeActions, ...this.supportRequests]
    items.forEach(item => {
      if (item.created_by === 'Genome') {
        genomeItems.push(item);
      }
    });
    return genomeItems;
  }

  handleError(err) {
    this.logger.error(err);
    this.errorMessage = (err.error && err.error.message) ? err.error.message : err.message;
  }

  @Loading('', true)
  async goToDispute(dispute){
    try {
      await this.concierge.setClientInfo();
      const params = {
        item: dispute
      }
      this.navCtrl.navigateForward(`dispute-resolution/${dispute.id}`, params);
    } catch (err) {
      this.handleError(err);
    }
  }

  goToSection(section) {
    const title = {
      'conciergeActions': 'Concierge Actions',
      'supportRequests': 'Support Requests',
      'rules': 'My Rules',
      'suggestions': 'Suggestions',
    }[section];
    const type = {
      'conciergeActions': 'support.concierge_task',
      'rules': 'rules',
      'suggestions': 'suggestions',
    }[section];
    const component = {
      'conciergeActions': ConciergeItemsComponent,
      'supportRequests': ConciergeItemsComponent,
      'rules': ConciergeRulesComponent,
      'suggestions': ConciergeRulesComponent,
    }[section];
    const url = {
      'conciergeActions': 'concierge-items',
      'supportRequests': 'concierge-items',
      'rules': 'concierge-rules',
      'suggestions': 'concierge-rules',
    }[section];
    const params = {
      title,
      items: this[section],
      addressResponse: this.addressResponse,
      type,
      cameFromConciergePage: false,
      isAddingItem: false,
      totalItems: this[`total${this.parseTitleForTotal(title)}`]
    }
    this.rightSidePanelService.navigateTo(url, params, component, false);
  }

  parseTitleForTotal(title: string): string {
    return title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  }

  async openRightSidePanel(params, component, url) {
    await this.storage.save('shouldUpdateItems', false);
    await this.storage.save('dialog-params', params);
    if (this.windowService.isDesktopRes) {
      await this.storage.save('dialog-right-side-open', true);
      this.rightSidePanelService.openRightSidePanel(component);
    } else {
      await this.storage.save('dialog-right-side-open', false);
      this.navCtrl.navigateForward(url)
    }
  }

  onSearchChanges(event) {
    const search = event.search;
    this.filteredItems = this[this.selectedCategory].filter(item => {
      return item.metadata.request_data.subject.toLowerCase().includes(search.toLowerCase());
    });
  }

  goToAddRule() {
    const component = ConciergeRuleComponent;
    const url = 'concierge-rule';
    const params = {
      addEditPageTitle: 'Add Rule',
      cameFromConciergePage: true,
      type: 'rules',
      rule: null
    }
    this.rightSidePanelService.navigateTo(url, params, component, false);
  }

  goToAddSupportRequest() {
    const component = ConciergeItemsComponent;
    const url = 'concierge-items';
    const params = {
      title: 'Support Requests',
      type: null,
      isAddingItem: true
    }
    this.rightSidePanelService.navigateTo(url, params, component, false);
  }

  goToAddConciergeAction() {
    const component = ConciergeItemsComponent;
    const url = 'concierge-items';
    const params = {
      title: 'Concierge Actions',
      type: 'support.concierge_task',
      isAddingItem: true
    }
    this.rightSidePanelService.navigateTo(url, params, component, false);
  }

  ngOnDestroy(): void {
    this.panelClosedSubscription.unsubscribe();
  }
}
